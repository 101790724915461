
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ListGroup from 'react-bootstrap/ListGroup';
import Accordion from 'react-bootstrap/Accordion';
import { MyContext } from '_MyContext';
import { Link } from "react-router-dom";

import axios from 'axios';
import ReactStars from 'react-stars';
import Spinner from 'react-spinkit';
import { loadReviewOrderList, loadPendingReviewOrderList, loadReviewOrderDetails, loadReviewBreakFixOrderDetails, loadReviewOrderEngineDetails } from '_Actions/reviews/reviewAction';
import { connect } from 'react-redux';
import { loadImplPlanList } from "_Actions/common/attachmentAction";
import { bindActionCreators } from 'redux';
import { GLOBAL } from '_Globals';
import { browserHistory } from 'react-router';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";

let remarkData = "";
let rating = "";
// let reasonData = "";
let checkedStatus = "";
// import StarRatings from 'react-star-ratings';
import ListLoader from "../common/loaders/ListLoader";
import StarRatingComponent from 'react-star-rating-component';
import _ from 'lodash';
import { PiEyeBold } from 'react-icons/pi';

const Joi = require('joi');
class MySurveyActionsBrk extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    //checkedYes=this.props.flagForReopen;
    super(props);
    this.state = {
      checkedYes: false,
      checkedNo: false,
      commentAreaForReorder: '',
      issueValidationError: '',
      postStatusFlag: 0,
      postErrorMessage: '',
      commentsErrorMessage: '',
      ratingCount: '',
      reOrderIndicator: 0,
      showRemarksToggle: false,
      causedBy: '',
      fixDetails: '',
      reasonData: '',
      ratingHoverCount: null
    };
    this.remarkData = this.remarkData.bind(this);
    this.postRemarkData = this.postRemarkData.bind(this);
    this.checkWorkItemId = this.checkWorkItemId.bind(this);
    //this.props.loadReviewOrderEngineDetails(this.props.quickViewItemId, "", "Breakfix");
    this.onChangeReOrder = this.onChangeReOrder.bind(this);
    this.onChangeReOrderNo = this.onChangeReOrderNo.bind(this);
    this.ratingChanged = this.ratingChanged.bind(this);
    //this.props.loadReviewOrderEngineDetails();
  }

  componentDidMount() {
		this.context.keyDownHandler(this.closeButton,this.editButton,this.globalSearchRef,this.saveObjRef);
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,this.editButton,this.globalSearchRef,this.saveObjRef);
	}


  componentWillReceiveProps(nextProps) {
    if (nextProps.breakFixActivityLogDetails.length > 0) {
      //console.log(" nextProps.breakFixActivityLogDetails[0]", nextProps.breakFixActivityLogDetails[0]);
      let description = nextProps.breakFixActivityLogDetails[0].description;
      //console.log("description", description);
      let d1 = description.split(',Fix Details:');
      this.setState({ fixDetails: d1[1] });
      let d2 = d1[0].split(':');
      this.setState({ causedBy: d2[1] });
    }
    this.setState({
      ratingCount: '',
      checkedYes: false,
      checkedNo: false,
      commentsErrorMessage: '',
      postErrorMessage: '',
      commetValidationError: ''
    });

    if (this.props.reviewDisplayNumber !== nextProps.reviewDisplayNumber) {
      remarkData = '';
      this.setState({
        reasonData: ''
      });
    }
  }
  getReOpenReason(reason) {

    // reasonData = reason.target.value;
    this.setState({ reasonData: reason.target.value.trim().replace(/\n/g, "<br/>")});

  }
  postReOrder() {
    //alert(this.props.quickViewItemId)
    if (this.props.reviewModuleValue == "Breakfix") {
      GLOBAL.reopen = true;
    }
    let ref = this;
    if (this.state.reasonData == "") {
      //alert("true");
      this.setState({
        issueValidationError: "error",
        postErrorMessage: this.props.tr['Please provide reason']
      });
    } else {
      //alert("false");
      this.setState({
        issueValidationError: "",
        postErrorMessage: ""
      });
      ref.setState({
        reOrderIndicator: 1
      });
      //=================post start
      //alert(this.props.reviewModuleValue);
      //alert(this.props.quickViewItemId);
      let itemId = this.props.quickViewItemId;
      let module = this.props.reviewModuleValue;

      axios.patch(GLOBAL.breakFixPatchUrl + this.props.quickViewItemId, {

        "status": "10",
        "statusReopen": true,
        spUserid: '0',
        spUsername: "",
        reopenReason: ref.state.reasonData,
        "modifiedBy": "",
        "modifiedByName": "",
        type: null,
				businessImpact: null,
				probableCause: null,
        "resolutionTime":0
      })
        .then(function (response) {
          //alert(response.statusText);
          //browserHistory.push("/reviews");
          //postActivityStart here
          if (response.status === 200) {
            //alert("hi")
            axios.post(GLOBAL.breakFixActivityLogPostUrl, {

              "itemId": itemId,
              "description": ref.state.reasonData,
              "isInternal": "",
              "createdOn": "",
              "createdBy": "",
              "module": module,
              "createdByName": ""

            })
              .then(function (response) {
                //alert(response.statusText);
                ref.props.loadReviewOrderList("", "Breakfix,Item", ref.props.status, ref.props.sortOnColumn, ref.props.sortOrder, ref.props.listFilter, ref.props.searchKey, ref.props.searchValue, ref.props.pattern, ref.props.listPageNumberValue, ref.props.listSize, ref.props.isSelf);
                // ref.props.loadPendingReviewOrderList("", "Breakfix,Item", "N", "displayNumber", "desc", ref.props.listFilter, "", "", "");
                ref.props.showRightSide(false);
                // ref.props.loadReviewOrderList("", "Breakfix,Item", "75,25", "N", "desc");

                ref.setState({
                  //postStatusFlag:1,
                  ratingCount: '',
                  reOrderIndicator: 0,
                  reasonData: ''
                });
                browserHistory.push("/reviews");

              })
              .catch(function (error) {
                ref.setState({
                  reOrderIndicator: 0
                });
              });
          }
          //postActivityStart here
        })
        .catch(function (error) {

        });
      //=================post end

    }

  }

  onChangeReOrder(event) {
    //alert(event.target.checked)
    //alert(checkedStatus)
    if (event.target.checked == true) {
      //alert("if"+event.target.checked)
      this.setState({
        checkedYes: true,
        checkedNo: false,
        postErrorMessage: '',
        commentsErrorMessage: '',
        issueValidationError: '',
        commetValidationError: ''
      });

      //this.props.flagForReopen=true;
      //alert(this.props.flagForReopen)
    } else {
      //alert("false")
      this.setState({
        checkedYes: false,

      });
    }
  }

  onChangeReOrderNo(event) {
    //alert(event.target.checked)
    //alert(checkedStatus)
    if (event.target.checked == true) {
      //alert("if"+event.target.checked)
      this.setState({
        checkedNo: true,
        checkedYes: false,
        postErrorMessage: '',
        commentsErrorMessage: '',
        issueValidationError: '',
        commetValidationError: ''
      });

      //this.props.flagForReopen=true;
      //alert(this.props.flagForReopen)
    } else {
      //alert("false")
      this.setState({
        checkedNo: false,

      });
    }

    //alert(this.state.checkedYes)
  }
  ratingChanged(newRating) {
    //alert("newRating"+newRating)
    rating = newRating;
    this.setState({
      ratingCount: newRating,
      checkedYes: false,
      checkedNo: false,
      postErrorMessage: '',
      commentsErrorMessage: '',
      issueValidationError: '',
      commetValidationError: ''
    });
    //alert("this.state.ratingValue1"+this.state.ratingValue1)

  }
  remarkData(comments) {
    this.setState({ remarks: comments.target.value });
    remarkData = comments.target.value.trim().replace(/['"]/g, "");
  }

  postRemarkData() {
    //alert(this.state.quickViewOrders.orderNumber);
    //let orderId=this.props.quickViewItemId;
    //alert(this.state.remarks);
    //alert(this.props.quickViewItemId)
    let ref = this;
    let postjson;
    let listFilter = this.props.listFilter;

    //alert(this.state.ratingCount)
    //alert("rae"+rating+this.state.ratingCount)
    if (this.props.myReviewBreakFixOrderDetails.priorityId == 'critical' || this.props.myReviewBreakFixOrderDetails.priorityId == 'Critical') {
      postjson = {
        "feedbackSubmitted": "Y",
        "feedbackRemarks": remarkData,
        "status": "30",
        "feedbackRating": rating
      };
    }
    else {
      if (this.props.isCloseEnabled == true) {
        postjson = {
          "feedbackSubmitted": "Y",
          "feedbackRemarks": remarkData,
          "status": "30",
          "modifiedByName": "",
          "modifiedBy": "",
          "feedbackRating": rating
        };
      }
      else {
        postjson = {
          "feedbackSubmitted": "Y",
          "feedbackRemarks": remarkData,
          "modifiedByName": "",
          "modifiedBy": "",
          "feedbackRating": rating
        };
      }
    }



    if (remarkData == "") {
      this.setState({
        commetValidationError: "error"
      });
    } else {
      this.setState({
        commetValidationError: ""
      });
    }
    if (this.state.ratingCount != "" && remarkData != "") {
      this.setState({
        commentsErrorMessage: ""
      });
      ref.setState({
        reOrderIndicator: 1
      });
      //this.props.fun(false,true,true);
      axios.post(GLOBAL.feedbackPostUrl, {
        "rating": rating,
        "remarks": remarkData,
        "requesterId": "",
        "workItemId": this.props.quickViewItemId,
        "workItemType": this.props.reviewModuleValue,
        "offeringId": this.props.offeringId,
        "requesterName": "",
        "orderNumber": this.props.orderNumber,
        "itemNumber": this.props.itemNumber,
        "offeringName": this.props.offeringName,
        "employeeNumber": "",
        "locationId": 0,
        "locationName": ""
      }).then((response) => {
        //this.props.loadReviewOrderDetails();
        //let refOne=this;
        //alert(response.statusText)
        if (response.status === 201) {
          axios.patch(GLOBAL.breakFixPatchUrl + ref.props.quickViewItemId, postjson)
            .then(function (response) {
              // ref.props.loadReviewOrderList("", "Breakfix,Item", "75,25", "N", "desc",listFilter);
              ref.props.loadReviewOrderList("", "Breakfix,Item", ref.props.status, ref.props.sortOnColumn, ref.props.sortOrder, ref.props.listFilter, ref.props.searchKey, ref.props.searchValue, ref.props.pattern, ref.props.listPageNumberValue, ref.props.listSize, ref.props.isSelf);
              ref.props.showRightSide(false);
              //ref.props.loadPendingReviewOrderList("", "Breakfix,Item", "N", "displayNumber", "desc", listFilter, "", "", "");

              ref.setState({
                ratingCount: '',
                reOrderIndicator: 0
              });
              browserHistory.push("/reviews");
            })
            .catch(function (error) {
              ref.setState({
                reOrderIndicator: 0
              });
            });
        }
        ref.props.showRightSide(false);
        ref.props.loadReviewOrderEngineDetails(ref.props.quickViewItemId, response.data.requesterId, "Breakfix");
        browserHistory.push("/reviews");
        remarkData = "";
      });
    } else {
      this.setState({
        commentsErrorMessage: this.props.tr['Please provide comments !']
      });
    }
  }
  checkWorkItemId(itemId) {
    return itemId == this.props.quickViewItemId;
  }

  getAttachment(itemId, Module) {
    this.props.loadImplPlanList(Module, itemId);
  }

  renderAttachedItems(attachments) {
    if (_.isEmpty(attachments)) {
      return (
        <div>
          <h4>{this.props.tr['No File Found']}</h4>
        </div>
      );
    }

    return attachments.map(attachment => {
      let validated_url = undefined;
      if (attachment && attachment.fileTempURL != "") {
        let namedSchema = Joi.string().regex(/^[^<>}{]+$/);
        let validator_to_add_myQbj = namedSchema.validate(attachment.fileTempURL);
        if (validator_to_add_myQbj.error == null) {
          validated_url = attachment.fileTempURL;
        }
        else {
          validated_url = "\\";
        }
      }
      else
        validated_url = "\\";

      if (attachment.filename !== null && attachment != undefined) {
        let attachfilename = attachment.filename;
        if (attachfilename.indexOf("##") > -1)
          attachfilename = attachfilename.slice(15);
        if (attachment.status == "Y") {
          return null;
        } else {
          return (
            <ListGroup.Item
              className="attachmentList surveyAttchmnt"
              key={attachment.attachmentId}
            >
              <div>
                {attachfilename}
              </div>
              <span className="float-r">
                <a href={validated_url} target="_blank">
                  {/* <a href={attachment.fileTempURL} target="_blank"> */}
                  <PiEyeBold />
                </a>
              </span>
            </ListGroup.Item>
          );
        }
      }
    });
  }

  render() {

    let commentArea, ratingValue, postButton, commentAreaForReorder, reorderArea, submitButton;


    // alert(this.props.myReviewBreakFixOrderDetails.breakfixId)
    if (this.props.myReviewOrdersList == undefined || this.props.myReviewOrdersList.length == 0 || this.props.myReviewOrdersList.length == undefined) {
      return (
        <div>
          {this.props.showLoader ? <ListLoader /> :
            <div>
              <font color="green"><strong>{this.props.tr['No matching data found']}</strong></font>
            </div>
          }
        </div>
      );
    }
    if (this.props.myReviewBreakFixOrderDetails.breakfixId == undefined) {
      return (
        <Spinner spinnerName="three-bounce" />
      );
    }
    if (this.props.checkedStatus != "") {
      return (
        <div>
          <font color="green"><strong>{this.props.checkedStatus}</strong></font>
        </div>
      );
    }
    else
    {
      if (this.props.myReviewOrderDetailsFrmEngine.length > 0) {

        if (this.props.quickViewItemId == this.props.myReviewOrderDetailsFrmEngine[0].workItemId) {

          postButton = <div />;
          ratingValue = <ReactStars edit={false} count={5} size={24} value={this.props.myReviewOrderDetailsFrmEngine[0].rating} color1={'gray'} color2={this.props.appliedThemeData?.secondaryColor} />;
          commentArea = <Form.Control as="textarea" placeholder={this.props.myReviewOrderDetailsFrmEngine[0].remarks} disabled />;
          reorderArea = <div />;
          commentAreaForReorder = <div />;
          submitButton = <div />;
        }
      } 
      else {

        ratingValue = <StarRatingComponent
          name="rate1"
          starCount={5}
          value={this.state.ratingCount}
          starColor={this.props.appliedThemeData?.secondaryColor} /* color of selected icons, default `#ffb400` */
          emptyStarColor={'gray'} /* color of non-selected icons, default `#333` */
          onStarClick={this.ratingChanged}
          onStarHover={(nextValue, prevValue, name) => { this.setState({ ratingHoverCount: nextValue }); }}
          renderStarIcon={() => <span title={this.state.ratingHoverCount + " " + this.props.tr['Stars']} style={{ fontSize: 24 }}>★</span>}
        />

        if (this.state.ratingCount > 3 || this.state.checkedNo == true) {
          commentArea = (
            <div><div className="f-size-15">{this.props.tr['Please provide your comments here']}<span className="rStar" />:
              <Form.Control as="textarea" className={this.state.commetValidationError} rows={4} onChange={this.remarkData.bind(this)} />
              <div className="f-size-11 color-lightgrey margin-b-10">{this.props.tr['Please do not include: HTML, references to suppliers, pricing, personal information, any profane, inflammatory or copyrighted comments.']}</div>
            </div></div>
          );
          postButton = (<ButtonToolbar className="black margin-t-10"><Button bsSize="small" variant="primary" disabled={!this.state.reOrderIndicator == 0} onClick={this.postRemarkData} >
            {!this.state.reOrderIndicator == 0 ? <ImSpinner6 className="icn-spinner"/> : null} {this.props.tr['Submit']}</Button>
          </ButtonToolbar>);
        }

        if (this.state.ratingCount == "") {
          reorderArea = <div />;
        } else if (this.state.ratingCount <= 3 && this.props.isReopenEnabled == true) {
          reorderArea = (<div className="f-size-15">{this.props.tr['Do you want to reopen the order']}:
            <div className="black margin-t-5"><label className="cursorPoint display-inline-block margin-r-10"><input style={{ "position": "relative", "top": "-2px" }} type="checkbox" checked={this.state.checkedYes} onClick={(event) => { this.onChangeReOrder(event); }} />&nbsp;
              {this.props.tr['Yes']}</label><label className="cursorPoint display-inline-block margin-l-10"><input style={{ "position": "relative", "top": "-2px" }} type="checkbox" checked={this.state.checkedNo} onClick={(event) => { this.onChangeReOrderNo(event); }} />&nbsp;{this.props.tr['No']}</label></div>
            <div className="f-size-15 margin-b-10 border-b padding-b-15" /></div>
          );
        }
        else if (this.state.ratingCount >= 1 && this.props.isReopenEnabled == false) {
          commentArea = (
            <div><div className="f-size-15 margin-b-10 border-b" /><div className="f-size-15"><br />{this.props.tr['Please provide your comments here']}<span className="rStar" />:
              <Form.Control as="textarea" className={this.state.commetValidationError} rows={4} onChange={this.remarkData.bind(this)} />
              <div className="f-size-11 color-lightgrey margin-b-10">{this.props.tr['Please do not include: HTML, references to suppliers, pricing, personal information, any profane, inflammatory or copyrighted comments.']}</div>
            </div></div>
          );
          postButton = (<ButtonToolbar className="black margin-t-10"><Button bsSize="small" variant="primary" disabled={!this.state.reOrderIndicator == 0} onClick={this.postRemarkData} >
            {!this.state.reOrderIndicator == 0 ? <ImSpinner6 className="icn-spinner"/> : null} {this.props.tr['Submit']}</Button>
          </ButtonToolbar>);
        }
     }
    }
    if (this.state.checkedYes == true) {
      if (this.state.ratingCount <= 3) {
        commentAreaForReorder = (<div className="f-size-15">{this.props.tr['Reason for reopening']}
          <span className="rStar" />
          <Form.Control as="textarea" className={this.state.issueValidationError} spellCheck="true" onChange={this.getReOpenReason.bind(this)} />
        </div>);
        submitButton = (<ButtonToolbar className="black margin-t-10"><Button bsSize="small" variant="primary" disabled={!this.state.reOrderIndicator == 0} onClick={this.postReOrder.bind(this)} >
          {!this.state.reOrderIndicator == 0 ? <ImSpinner6 className="icn-spinner"/> : null} {this.props.tr['Submit']}</Button>
        </ButtonToolbar>);
      }
    } else {
      commentAreaForReorder = <div />;
      submitButton = <div />;
    }
    return (
      <div>
        {this.props.showLoader ? <ListLoader />
          :
          <div>
            <Row>
              <Col xs={12}>
                <div className="rPageHeadActBtn">
                  <ul>
                    <li>
                    <Link to="javascript:void(0)" className="closeRsBtn" id="closeButton"
											title={this.props.tr["Minimize the details panel"]}
											onClick={() => { this.props.showRightSide(false); }}
											ref={e => this.closeButton = e}
										>
											<IoClose/>
										</Link>
                    </li>
                  </ul>
                </div>
                <div className="rPageHeading">{this.props.orderNumber + '-' + this.props.itemNumber}<div className="offNam">{this.props.serviceName}</div></div>
              </Col>
            </Row>

            {(this.props.isAutoClosed == true || this.props.feedbackSubmitted==='N') && this.props.itemStatus == "Closed" ? <div className="rBoxGap">{this.props.tr['No feedback was captured as this request was automatically closed.']}</div> :
              <div className="rBoxGap">
                {/* <div className="f-size-16 f-bold margin-b-10 border-b padding-b-10"  ><span className="serviceDetailsHeadColor">{this.props.reviewDisplayNumber}</span></div> */}
                <div className="rBoxTabs">
                <Tabs activeKey={this.state.tabKey} onSelect={this.handleSelect} id="tabId">
                  <Tab eventKey={1} title={this.props.tr["Rating & Reviews"]}>
                    <Row className="margin-b-10">
                      <Col xs={12}>
                        <div className="f-size-15 margin-b-10">{this.props.tr['Tell Us About Your Service Experience']}</div>
                        <div className="f-size-13">{this.props.tr['If you have liked the way we served you or have any suggestions for us,  please share it with us here.']}</div>
                      </Col>
                    </Row>

                    <div className="f-size-16 f-bold margin-b-10 border-b " />
                    {this.props.myReviewBreakFixOrderDetails.criticality == "No" ?
                      <Row className="margin-b-10">
                        <Col xs={12}>
                          {
                            this.props.myReviewOrderDetailsFrmEngine.length == undefined ? '' : this.props.myReviewOrderDetailsFrmEngine.length > 0 ?
                              <div className="f-size-15">{this.props.tr['Service Ratings']}</div>
                              : <div className="f-size-15">{this.props.tr['Please rate the quality of service']}<span className="color-red">*</span></div>
                          }
                          <div className="margin-t-5 starSecDv">{ratingValue}</div>
                        </Col>
                      </Row>
                      : null}

                    <div className="f-size-15 margin-b-10 border-b " />
                    <Row className="margin-b-10">
                      <Col xs={12}>
                        {reorderArea}
                        <Form.Group controlId="formControlsTextarea">
                          {commentArea}
                          <font color="red"><strong>{this.state.commentsErrorMessage}</strong></font>
                        </Form.Group>
                        {postButton}
                        <Form.Group controlId="formControlsTextarea">
                          {commentAreaForReorder}
                          <font color="red"><strong>{this.state.postErrorMessage}</strong></font>
                        </Form.Group>
                        {submitButton}
                        </Col>
                        </Row>
                  </Tab>
                  <Tab eventKey={2} title={this.props.tr["Request Details"]}>
                    <ListGroup variant="flush" bsPrefix=' '>
                      <ListGroup.Item bsPrefix=' ' className="rwTable" style={{ "border-top": "none" }}>
                        <span className="rwKey">{this.props.tr["Submit Date"]}</span>
                        <span className="rwVal">{this.props.myReviewBreakFixOrderDetails.createdOn}</span>
                      </ListGroup.Item>
                      <ListGroup.Item bsPrefix=' ' className="rwTable">
                        <span className="rwKey">{this.props.tr["Status"]}</span>
                        <span className="rwVal">{this.props.tr[this.props.myReviewBreakFixOrderDetails.status]}</span>
                      </ListGroup.Item>
                      <ListGroup.Item bsPrefix=' ' className="rwTable">
                        <span className="rwKey">{this.props.tr['Resolution']}</span>
                        <span className="rwVal">{this.props.myReviewBreakFixOrderDetails.fixDetails}</span>
                      </ListGroup.Item>
                      <ListGroup.Item className="margin-t-20" bsPrefix=' '>
                          <Accordion
                            id="rightSidePanel4"
                            accordion
                            className="brkFix brkFixOrder"
                            defaultActiveKey="1"
                          >
                            <Accordion.Item eventKey="2">
                              <Accordion.Header
                                onClick={() => {
                                  this.getAttachment(this.props.quickViewItemId, "Breakfix");
                                }}
                              >{this.props.tr['Attachments']}
                              </Accordion.Header>
                              <Accordion.Body>
                                <ListGroup>{this.renderAttachedItems(this.props.implememtationPlanList)}</ListGroup>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                      </ListGroup.Item>
                    </ListGroup>
                  </Tab>
                </Tabs>
                </div>
              </div>
            }
          </div>
        }
      </div>
    );
  }
}

export function mapStateToProps({ myReviewOrdersList, showLoader, myReviewOrderDetails, myReviewBreakFixOrderDetails, myReviewOrderDetailsFrmEngine, implememtationPlanList, appliedThemeData }) {
  //console.log("as"+myReviewOrderDetailsFrmEngine);
  return { myReviewOrdersList: myReviewOrdersList.data, myReviewOrderDetails, showLoader: showLoader.loading, myReviewBreakFixOrderDetails, myReviewOrderDetailsFrmEngine, implememtationPlanList, appliedThemeData: appliedThemeData.data };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadReviewOrderList, loadPendingReviewOrderList, loadReviewOrderDetails, loadReviewBreakFixOrderDetails, loadReviewOrderEngineDetails, loadImplPlanList }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MySurveyActionsBrk);
//export default XsmReviewsActions;
