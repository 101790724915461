/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import { Field } from "formik";
import { useDispatch, useSelector } from "react-redux";

import CmdbDropdownPlusTypeahead from "../common/CmdbDropdownPlusTypeahead.js";
import {
  InputTextField,
  SelectField,
  StandardTextArea,
} from "../../common/forms/FormFields";
import { GLOBAL } from "../../../components/Globals";

import { loadOnCallSXMenulistData } from "_Actions/onCallSchedule/common/onCallCommonAction";
import { loadProfileTimeZone } from "_Actions/myProfile/profileTimeZoneAction";

import {
  loadScheduleDetailsByScheduleId,
  emptyScheduleDetailsByScheduleId,  
} from "_Actions/onCallSchedule/schedule/onCallScheduleAction";

export default function EditMaintenanceWindow(props) {
  const params = useParams();
  const dispatch = useDispatch();

  const translator = useSelector((state) => state.spcmReducer.tr);

  let timeZoneDetails = useSelector((state) => state.timeZoneDetails);
  let scheduleTypeValues = useSelector(
    (state) => state.on_call_common_reducer.scheduleType
  );

  const maintenanceId = params?.maintenanceWindowId;
  const isAddMode = !maintenanceId;
  const {
    setFieldValue,
    handleChange,
    values,
    isValid,
    submitCount,
    errors,
    touched,
  } = props;

  const [timeZoneSelectedList, setTimeZoneSelectedList] = useState([]);

  let timeZoneTypeaheadOptions = [];
  if (timeZoneDetails && timeZoneDetails.length > 0) {
    timeZoneDetails.forEach((item, i) => {
      let obj = {};
      obj.id = item.VALUE;
      obj.label = item.VALUE;
      timeZoneTypeaheadOptions.push(obj);
    });
  }

  const onCrossClickTimeZone = () => {};

  useEffect(() => {    
    dispatch(loadOnCallSXMenulistData("CMDB", "ScheduleType"));
    dispatch(loadProfileTimeZone());
  }, []);

  return (
    <div className="borderForm bFormDiv">
      <Row className={!isValid && submitCount > 0 ? "" : "d-none"}>
        <Col md={12} sm={12} xs={12}>
          {!isValid && submitCount > 0 && (
            <div className="alert alert-danger">
              {Object.keys(errors).length > 0
                ? errors.error ||
                  "The form has missing required fields, please revalidate the highlighted fields and update the form!"
                : "The form has missing required fields, please revalidate the highlighted fields and update the form!"}
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <InputTextField
            name="scheduleName"
            label="Name"
            required
            disabled={values.status === "Active"}
          />
        </Col>
        <Col md="6">
          <SelectField
            disabled
            name="scheduleType"
            label="Type"
            required
            onChange={() => {
              setFieldValue("scheduleType", event.target.value);
            }}
          >
            {scheduleTypeValues?.map((type, i) => {
              return (
                <option value={type?.field1Key} key={i}>
                  {type?.field1Key}
                </option>
              );
            })}
          </SelectField>
        </Col>
        <Col md="6">
          <Form.Group className="form-group">
            <Form.Label bsClass="">
              <span className="rStar"></span>
              {translator["Time Zone"]}
            </Form.Label>
            <Field
              name="timeZone"
              component={CmdbDropdownPlusTypeahead}
              options={timeZoneTypeaheadOptions || []}
              onSelection={(val) => {
                setFieldValue("timeZone", val?.[0]?.label || "");
              }}
              inputValue={values?.timeZone || ""}
              id="time_zone"
              onInputChange={(val) => {
                console.log(val, "onInputChange");
              }}
              selectedOptions={
                values?.timeZone
                  ? [{ id: values.timeZone, label: values.timeZone }]
                  : []
              }
              errorColor={touched?.timeZone && !values?.timeZone ? "error" : ""}
              onCrossClick={onCrossClickTimeZone}
              disabled={values.status === "Active"}
            />
          </Form.Group>
        </Col>
        <Col md="6">
          <SelectField
            name="status"
            label="Status"
            required
            onChange={() => {
              setFieldValue("status", event.target.value);
            }}
          >
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </SelectField>
        </Col>
      </Row>
    </div>
  );
}
