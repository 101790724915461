
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import { GLOBAL } from "_Globals";
import ListLoader from "../../../common/loaders/ListLoader";
import { loadChangeTotalApprovalDetails } from "../../../../actions/changeManagement/changeTotalApprovalAction";
import { getApprovals } from "../../../../actions/spcmActions";
import { loadApprovalHistory,fetchApprovalsQuesAns } from "../../../../actions/MyApprovals/MyApprovalActions";
import ApprovalHistory from "../../../common/WorkItemBoard/approvalHistory/ApprovalHistory";
import {IoClose} from "react-icons/io5";
import { sanitizeElements } from "../../../common/helper";

const MoreArrow = (
  <i title="More" className="fa fa-angle-double-down f-size-15"></i>
);

class PopoverWorkItemsApprovalStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserData: false,
      displayMessage: "",
      showApprovalLoader: false,
    };
    this.individualPopoverTable = this.individualPopoverTable.bind(this);
    this.checkRenderApproverData = this.checkRenderApproverData.bind(this);
  }
  individualPopoverTable(tabledata) {
    return (
      <tr>
        <td>{tabledata.name}</td>
        <td>{tabledata.reason}</td>
      </tr>
    );
  }
  checkRenderApproverData(offeringId, ciId, ciClassId, itemid) {
    this.setState({ showApprovalLoader: true });
    axios
      .get(
        "/api/approval/ruleCount/offeringId/" +
          offeringId +
          "/moduleId/" +
          "14" +
          "/ciId/" +
          ciId +
          "/ciclass/" +
          ciClassId +
          "/requestId/" +
          itemid
      )
      .then((resp) => {
        if ((resp.status = 200)) {
          this.setState({ showApprovalLoader: false });
          if (resp.data.count >= 1) {
            this.props.loadChangeTotalApprovalDetails(
              this.props.itemId,
              "14",
              "N"
            );
            this.setState({ displayMessage: "" });
          } 
          else if (resp.data.count == -1) {
            this.setState({ showApprovalLoader: false });
            this.setState({
              displayMessage:
              this.props.tr["There are no active approvals as the request is cancelled"],
            });
          }
          else {
            if(this.props.status==25){
              this.setState({ showApprovalLoader: false });
              this.setState({
              displayMessage:
                this.props.tr["This record is currently under review to determine if approval is required. Please check back later for updates"],
            });

            }else{
            this.setState({ showApprovalLoader: false });
            this.setState({
              displayMessage:
                this.props.tr["This CI does not require any approval"],
            });
          }
        }
        }
      });
  }
  componentWillMount() {
    let itemId = this.props.itemId;
    let ciid =
      this.props.editChangeInitialData.ciId == "" ||
      this.props.editChangeInitialData.ciId == "null"
        ? undefined
        : this.props.editChangeInitialData.ciId;
    let ciClass =
      this.props.editChangeInitialData.ciClassId == "" ||
      this.props.editChangeInitialData.ciClassId == "null"
        ? undefined
        : this.props.editChangeInitialData.ciClassId;
    this.checkRenderApproverData(
      this.props.editChangeInitialData.serviceId,
      ciid,
      ciClass,
      this.props.editChangeInitialData.changeId
    );
    this.props.loadApprovalHistory(this.props.editChangeInitialData.changeId, "14");
    this.props.fetchApprovalsQuesAns(this.props.editChangeInitialData.changeNumber, "14");
  }
  renderAttList(relatedAppHistoryDataLog) {
    if (!relatedAppHistoryDataLog) {
      return null;
    }
    return relatedAppHistoryDataLog.map((relatedAppHistoryData, i) => {
      return (
        <tr>
          <td>{relatedAppHistoryData.levelname}</td>
          <td>{relatedAppHistoryData.signatures}</td>
          <td>{relatedAppHistoryData.status}</td>
          <td>{relatedAppHistoryData.modifieddate}</td>
        </tr>
      );
    });
  }
  groupPopoverTable(tabledata) {
    return tabledata.map((data, index) => {
      let statusapp = data.status;
      if (statusapp == "null" || statusapp == "" || statusapp == null)
        statusapp = "Pending";
      else statusapp = data.status;
      return (
        <tr>
          <td>{data.name}</td>
          <td>
            {statusapp}
            {data.approvedby != null ? " (" + data.approvedby + ")" : ""}
          </td>
          <td>{data.date}</td>
          <td
            style={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{
              __html: sanitizeElements(data.reason)
            }}
          />
        </tr>
      );
    });
  }

  renderApproverQuestions(questionsChange) {
    if (!questionsChange) {
      return (
        <tr>  
          <td colspan="4">
            <p>
              <center>{this.props.tr["Data not available."]}</center>
            </p>
          </td>
        </tr>
      );
    } else {
    return questionsChange.map((question) => {
      return (
        <tr>
          <td>{question.levelId}</td>
          <td>{question.approver}</td>
          <td>{question.approvalQuestion}</td>
          <td>{question.approvalAnswer}</td>
        </tr>
      );
    });
  }
  }

  renderAppStatusDataLog(relatedAppHistoryDataLog) {
    if (!relatedAppHistoryDataLog) {
      return (
        <tr>
          <td width="100%">
            <p>
              <center>{this.props.tr["Data not available."]}</center>
            </p>
          </td>
        </tr>
      );
    } else {
      return relatedAppHistoryDataLog.map((relatedAppHistoryData, index) => {
        let statusapp = relatedAppHistoryData.status;
        if (statusapp == "null" || statusapp == "" || statusapp == null)
          statusapp = "Pending";
        else statusapp = relatedAppHistoryData.status;

        let statusappColor = "";
        if (statusapp == "Approved") {
          statusappColor = "greenDot";
        } else if (statusapp == "Rejected") {
          statusappColor = "redDot";
        } else if (statusapp == "Pending") {
          statusappColor = "blueDot";
        } else if (statusapp == "Referred Back") {
          statusappColor = "orangeDot";
        } else if (statusapp == "Cancelled") {
          statusappColor = "redDot";
        }

        let approverDetails = "";
        approverDetails =
          relatedAppHistoryData.approverDetails != null
            ? relatedAppHistoryData.approverDetails
            : "";

        let indData = {
          name: relatedAppHistoryData.signatures,
          reason: relatedAppHistoryData.rejectreason,
        };

        let individualPopoverRej = (
          <Popover id={index + "ind"} className="approvalPopup posCustom">
            <Popover.Header as="h3">Rejection Reason</Popover.Header>
            <Popover.Body>
              <Table
                responsive
                striped
                bordered
                condensed
                hover
                className="f-size-13"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Comments</th>
                  </tr>
                </thead>
                <tbody>{this.individualPopoverTable(indData)}</tbody>
              </Table>
            </Popover.Body>
          </Popover>
        );

        let indApprovalData = { name: relatedAppHistoryData.signatures };
        relatedAppHistoryData.status == "Referred Back"
          ? (indApprovalData.reason = relatedAppHistoryData.referbackComments)
          : (indApprovalData.reason = relatedAppHistoryData.approvalComments);
        let individualPopoverApproval = (
          <Popover
            id={index + "ind" + index}
            className="approvalPopup posCustom"
          >
            <Popover.Header as="h3">
              {relatedAppHistoryData.status == "Referred Back"
                ? "Referred Back Comment"
                : "Approver Comment"}
            </Popover.Header>
            <Popover.Body>
              <Table
                responsive
                striped
                bordered
                condensed
                hover
                className="f-size-13"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Comments</th>
                  </tr>
                </thead>
                <tbody>{this.individualPopoverTable(indApprovalData)}</tbody>
              </Table>
            </Popover.Body>
          </Popover>
        );

        let groupPopoverRej = (
          <Popover id={index + "groupRej"} className="approvalPopup posCustom">
            <Popover.Header as="h3">Rejection Reason</Popover.Header>
            <Popover.Body>
              <Table
                responsive
                striped
                bordered
                condensed
                hover
                className="f-size-13"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Date & Time</th>
                    <th>Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {approverDetails == "" ? (
                    <tr>
                      <td colspan="4">
                        <p>
                          <center>
                            {this.props.tr["Data not available."]}
                          </center>
                        </p>
                      </td>
                    </tr>
                  ) : (
                    this.groupPopoverTable(approverDetails)
                  )}
                </tbody>
              </Table>
            </Popover.Body>
          </Popover>
        );

        let groupPopover = (
          <Popover id={index + "group"} className="approvalPopup posCustom">
            <Popover.Header as="h3">
              {relatedAppHistoryData.adhocApproverFlag == "Y"
                ? this.props.tr["Adhoc Approvals"]
                : relatedAppHistoryData.grpsignatures == 'Parallel Approvers'?["Parallel Approvers"]:this.props.tr["Group Approvals"]}
            </Popover.Header>
            <Popover.Body>
              <Table
                responsive
                striped
                bordered
                condensed
                hover
                className="f-size-13"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Date & Time</th>
                    <th>Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {approverDetails == "" ? (
                    <tr>
                      <td colspan="4">
                        <p>
                          <center>
                            {this.props.tr["Data not available."]}
                          </center>
                        </p>
                      </td>
                    </tr>
                  ) : (
                    this.groupPopoverTable(approverDetails)
                  )}
                </tbody>
              </Table>
            </Popover.Body>
          </Popover>
        );

        return (
          <tr key={index}>
            <td>
              {statusapp == "Rejected" ? (
                relatedAppHistoryData.approverType != "Group" &&
                relatedAppHistoryData.approverType != "GBO" &&
                relatedAppHistoryData.adhocApproverFlag != "Y" ? (
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="bottom"
                    overlay={individualPopoverRej}
                  >
                    <Button
                      className={statusappColor}
                      title={statusapp}
                      variant="success"
                    ></Button>
                  </OverlayTrigger>
                ) : relatedAppHistoryData.approverType == "Group" ||
                  relatedAppHistoryData.adhocApproverFlag == "Y" ? (
                  <span
                    className={statusappColor}
                    title={this.props.tr[statusapp]}
                  ></span>
                ) : (
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="bottom"
                    overlay={groupPopoverRej}
                  >
                    <Button
                      className={statusappColor}
                      title={statusapp}
                      variant="success"
                    ></Button>
                  </OverlayTrigger>
                )
              ) : relatedAppHistoryData.approverType != "Group" &&
                relatedAppHistoryData.approverType != "GBO" &&
                relatedAppHistoryData.adhocApproverFlag != "Y" ? (
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom"
                  overlay={individualPopoverApproval}
                >
                  <Button
                    className={statusappColor}
                    title={statusapp}
                    variant="success"
                  ></Button>
                </OverlayTrigger>
              ) : (
                <span className={statusappColor} title={statusapp}></span>
              )}
            </td>
            <td>{relatedAppHistoryData.levelname}</td>
            <td>
              {relatedAppHistoryData.approverType.trim() != "Group" &&
              relatedAppHistoryData.approverType.trim() != "GBO" &&
              relatedAppHistoryData.adhocApproverFlag != "Y"
                ? relatedAppHistoryData.signatures == "null" ||
                  relatedAppHistoryData.signatures == null
                  ? "Approver does not exist"
                  : relatedAppHistoryData.signatures
                : relatedAppHistoryData.signatures}
              {relatedAppHistoryData.approverType.trim() == "Group" ||
              relatedAppHistoryData.approverType.trim() == "GBO" ||
              relatedAppHistoryData.adhocApproverFlag == "Y" ? (
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom"
                  overlay={groupPopover}
                >
                  <Button className="btninfo">i</Button>
                </OverlayTrigger>
              ) : (
                ""
              )}
            </td>
            <td>{relatedAppHistoryData.lastUpdatedDate}</td>
          </tr>
        );
      });
    }
  }
  render() {
    return this.state.showApprovalLoader ? (
      <div>
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    this.props.changeActionView("");
                    }}
                >
                    <IoClose/>
                </button>
                </li>
            </ul>
        </div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {this.props.translator["Approval"]}
          </div>
        </div>
        <div className="rBoxGap">
          <ListLoader />
        </div>
      </div>
    ) : (
      <div>
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    this.props.changeActionView("");
                    }}
                >
                    <IoClose/>
                </button>
                </li>
            </ul>
        </div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {this.props.translator["Approval"]}
          </div>
        </div>
        <div className="rBoxGap">
          <div className="rBoxTabs">
            <Tabs
              className="rBoxTabs"
              activeKey={this.state.tabKey}
              onSelect={this.handleSelect}
              id="tabId"
            >
              <Tab eventKey={1} title={this.props.translator["Approval"]}>
                {this.state.displayMessage != "" ? (
                  <div>{this.state.displayMessage}</div>
                ) : this.props.showLoader ? (
                  <div>
                    <ListLoader />
                  </div>
                ) : this.props.changeTotalApprovalDetails.length > 0 ? (
                  <Table
                    responsive
                    striped
                    bordered
                    condensed
                    hover
                    className="f-size-13"
                  >
                    <thead>
                      <tr>
                        <th width="10%"></th>
                        <th width="16%">{this.props.tr["Level"]}</th>
                        <th width="44%">{this.props.tr["Approver"]}</th>
                        <th width="30%">{this.props.tr["Date & Time"]}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.renderAppStatusDataLog(
                        this.props.changeTotalApprovalDetails
                      )}
                    </tbody>
                  </Table>
                ) : (
                  <div>
                    {
                      this.props.tr[
                        "Approval is being triggered. Please try later!"
                      ]
                    }
                  </div>
                )}
              </Tab>
              <Tab eventKey={2} title={["Questions"]}>
                <Table
                responsive
                striped
                bordered
                condensed
                hover
                className="f-size-13">
                <thead>
                  <tr>
                        <th width="10%">Level</th>
                        <th width="20%">Approver</th>
                        <th width="35%">Question</th>
                        <th width="35%">Answer</th>
                      </tr>
                  </thead>
                  <tbody>
                  {this.renderApproverQuestions(this.props.fetchApprovalQuestionsDetails)}
                  </tbody>
                </Table>
              </Tab>
              <Tab
                eventKey={6}
                title={`${this.props.translator["Audit Log"]}`}
              >
                <ApprovalHistory
                  approvalAuditLogData={this.props.approvalHistoryData}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}
export function mapStateToProps({
  state,
  spcmReducer,
  changeTotalApprovalDetails,
  rightMenuShowLoader,
  approvalHistoryData,
  fetchApprovalQuestionsDetails
}) {
  return {
    changeTotalApprovalDetails,
    rightMenuShowLoader,
    showLoader: rightMenuShowLoader.loading,
    tr: spcmReducer.tr,
    spcmReducer,
    approvalHistoryData,
    fetchApprovalQuestionsDetails
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { loadChangeTotalApprovalDetails, getApprovals, loadApprovalHistory, fetchApprovalsQuesAns },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopoverWorkItemsApprovalStatus);
