/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
export const PaginationNumber = {
  name: "PaginationNumber",
  traits: [
    {
      type: "text",
      label: "Total item count key",
      name: "totalItemCount",
      placeholder: "",
      changeProp: 1,
    },
    {
      type: "text",
      label: "Current page key",
      name: "currentPage",
      placeholder: "",
      changeProp: 1,
    },
    {
      type: "text",
      label: "Page size key",
      name: "pageSize",
      placeholder: "",
      changeProp: 1,
    },
  ],
};

export const PaginationNumberAdd = {
  model: {
    defaults: {
      tagName: "span",
      draggable: true,
      droppable: true,
      attributes: { class: "" },
      content: "1-10 of 100",
      traits: PaginationNumber.traits,
      editable: true,
    },
    init() {},
  },
};

export const addPaginationNumber = (e) => {
  e.DomComponents.addType(PaginationNumber.name, PaginationNumberAdd);
};

export const PaginationNumberBlockJson = {
  id: "paginationNumber",
  label: "Pagination Number",
  media: `<svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --> <title>ic_fluent_phone_pagination_24_regular</title> <desc>Created with Sketch.</desc> <g id="🔍-Product-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="ic_fluent_phone_pagination_24_regular" fill="#808080" fill-rule="nonzero"> <path d="M15.75,2 C16.9926407,2 18,3.00735931 18,4.25 L18,19.75 C18,20.9926407 16.9926407,22 15.75,22 L8.25,22 C7.00735931,22 6,20.9926407 6,19.75 L6,4.25 C6,3.00735931 7.00735931,2 8.25,2 L15.75,2 Z M15.75,3.5 L8.25,3.5 C7.83578644,3.5 7.5,3.83578644 7.5,4.25 L7.5,19.75 C7.5,20.1642136 7.83578644,20.5 8.25,20.5 L15.75,20.5 C16.1642136,20.5 16.5,20.1642136 16.5,19.75 L16.5,4.25 C16.5,3.83578644 16.1642136,3.5 15.75,3.5 Z M9.49877889,17.7544525 C9.91299245,17.7544525 10.2487789,18.0902389 10.2487789,18.5044525 C10.2487789,18.9186661 9.91299245,19.2544525 9.49877889,19.2544525 C9.08456533,19.2544525 8.74877889,18.9186661 8.74877889,18.5044525 C8.74877889,18.0902389 9.08456533,17.7544525 9.49877889,17.7544525 Z M11.9987789,17.7544525 C12.4129925,17.7544525 12.7487789,18.0902389 12.7487789,18.5044525 C12.7487789,18.9186661 12.4129925,19.2544525 11.9987789,19.2544525 C11.5845653,19.2544525 11.2487789,18.9186661 11.2487789,18.5044525 C11.2487789,18.0902389 11.5845653,17.7544525 11.9987789,17.7544525 Z M14.4987789,17.7544525 C14.9129925,17.7544525 15.2487789,18.0902389 15.2487789,18.5044525 C15.2487789,18.9186661 14.9129925,19.2544525 14.4987789,19.2544525 C14.0845653,19.2544525 13.7487789,18.9186661 13.7487789,18.5044525 C13.7487789,18.0902389 14.0845653,17.7544525 14.4987789,17.7544525 Z" id="🎨Color"> </path> </g> </g> </g></svg>`,
  category: "Extra",
  content: {
    type: PaginationNumber.name,
  },
};
