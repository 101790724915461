/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/

import React, { useEffect, useState, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { IoSearch, IoClose } from "react-icons/io5";
import {
  Row,
  Col,
  Form,
  Table,
  Nav,
  Button,
  ButtonToolbar,
} from "react-bootstrap";
import Cookies from "universal-cookie";

import { FaBarcode } from "react-icons/fa6";
import { LuPrinter } from 'react-icons/lu';
import bwipjs from 'bwip-js';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Field, reduxForm, formValueSelector, change, SubmissionError, submit } from 'redux-form';

const cookies = new Cookies();
let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];

let cookiesData = cookies.get("gph");
if (cookiesData) cookiesData = cookiesData.replace("s:", "");
if (cookiesData)
  cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf("."));
cookiesData = cookiesData.split("~");
let onRoleVisible = cookiesData[33] ? cookiesData[33].split(",") : [];
let assignedGrpId = cookiesData[58] ? cookiesData[58].split(",") : [];

let onContractRoleVisible = cookiesData[33] ? cookiesData[33].split(",") : [];
onContractRoleVisible = onContractRoleVisible.includes("68");

let onConfigManagerRoleVisible = cookiesData[33]
  ? cookiesData[33].split(",")
  : [];
onConfigManagerRoleVisible = onConfigManagerRoleVisible.includes("37");

let onAssetRoleVisible = cookiesData[33] ? cookiesData[33].split(",") : [];
onAssetRoleVisible = onAssetRoleVisible.includes("38");

const barcodePrintProfile = {
  "profileName": "Laptop Profile",
  "isQR": false,
  "header": "HCLSoftware",
  "barcode": "Serial,AssetTag,ASTCICODE",
  "footer": "Asset Tag",
  "isZebraPrinter": true,
  "pageWidth": "50",
  "pageHeight": "500",
  "no_of_rows": 10,
  "no_of_columns": 2,
  "pageMarginTop": "10",
  "pageMarginBottom": "10",
  "pageMarginLeft": "10",
  "pageMarginRight": "10",
  "barcodeHeight": "",
  "headerAppend": "",
  "footerAppend": "",
  "rowSpacing": 5,
  "colSpacing": 5
};

const cmdbAttributes = [
  {
    label: 'Serial Number',
    key: 'serial_number'
  },
  {
    label: 'Asset Tag',
    key: 'asset_tag'
  },
  {
    label: 'Model Name',
    key: 'model_name'
  },
  {
    label: 'Model Number',
    key: 'model_number'
  }
];

const hasSelectedField = (selectedArr, field) => {
  return selectedArr.some((item) => item.key === field);
}

const GenerateBarcode = (props) => {
  const cIEditDetails = useSelector((state) => state.CIEditDetails);
  const translator = useSelector((state) => state.spcmReducer.tr);

  const [isPrinting, setIsPrinting] = useState(false);
  const [barcodeDataURLs, setBarcodeDataURLs] = useState({});

  const [selectedBarcodeFields, setSelectedBarcodeFields] = useState([]);
  const [selectedBarcodeType, setSelectedBarcodeType] = useState('code128');
  const [selectedBarcodePrintProfile, setSelectedBarcodePrintProfile] = useState(barcodePrintProfile);

  const printDiv = useCallback(() => {
    // console.log('printDiv isPrinting==', isPrinting);
    if (!isPrinting) {
      setIsPrinting(true);
      setTimeout(() => {
        const printContents = document.getElementById('printableArea').innerHTML;
        // const canvas = document.getElementById('myCanvas');
        // const imgData = canvas.toDataURL('image/png');
        const printWindow = window.open('', '', 'height=600,width=800');
        printWindow.document.write('<html><head><title>Print</title>');
        printWindow.document.write('</head><body>');
        // printWindow.document.write(`<img src="${imgData}" />`);
        printWindow.document.write(printContents);
        printWindow.document.write('</body></html>');
        // printWindow.document.close();
        printWindow.onafterprint = () => {
          printWindow.close();
          setIsPrinting(false);
        };
        printWindow.addEventListener('beforeunload', () => {
          setIsPrinting(false);
        });
      }, 1000);
    }
  }, [isPrinting]);

  useEffect(() => {
    const handleAfterPrint = () => {
      setIsPrinting(false);
    };
    window.addEventListener('afterprint', handleAfterPrint);
    return () => {
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, []);


  function onBarcodeFieldSelect(selectedOptions) {
    setSelectedBarcodeFields(selectedOptions);
  }

  const generateBarcode = (name, value = '0123456789') => {
    // console.log('generateBarcode', name, value);
    if (value && value != '') {
      try {
        const dataURLs = structuredClone(barcodeDataURLs);
        const canvas = document.createElement('canvas');
        bwipjs.toCanvas(canvas, {
          // bcid: 'code128', // Barcode type
          bcid: selectedBarcodeType, // Barcode type
          text: value, // Text to encode
          scale: 0, // 3x scaling factor
          height: 10, // Bar height, in millimeters
          includetext: true, // Show human-readable text
          textxalign: 'center', // Always good to set this
        });
        dataURLs[name] = canvas.toDataURL('image/png');
        setBarcodeDataURLs(dataURLs);
      } catch (e) {
        console.error(`Error generating barcode for ${name}:`, e);
      }
    } else {
      alert('Please enter valid value to generate barcode');
    }
  }

  // console.log('barcodeDataURLs', barcodeDataURLs)
  return (
    <div className="clDiv Ovrflow StTimeline">
      <Row>
        <Col>
          <div className={"rPageHeading"}>
            <div className="offNam margin-t-5 margin-b-5">Generate Barcode</div>
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Form.Group className="typeaheadInput form-group notifydvTypehd">
              <Form.Label>Select fields</Form.Label>
              <Typeahead
                id="barcodeFields"
                multiple
                options={cmdbAttributes}
                selected={selectedBarcodeFields}
                placeholder={translator["Please choose"] + "..."}
                // onBlur={setErrorColor}
                // onInputChange={onGroupInputChange}
                onChange={onBarcodeFieldSelect}
              />
            </Form.Group>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Form.Group className="typeaheadInput form-group notifydvTypehd">
              <Form.Label>Barcode Type</Form.Label>
              <Field
                component="select"
                name="subStatus"
                className={`form-control`}
                // onBlur={(event) => { validateField('subStatus', event.target.value) }}
                onChange={(event) => { setSelectedBarcodeType(event.target.value) }}
              >
                <option value="code128">Code 128</option>
                <option value="qrcode ">QR code</option>
              </Field>
            </Form.Group>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Form.Group className="typeaheadInput form-group notifydvTypehd">
              <Form.Label>Print profile</Form.Label>
              <Field
                component="select"
                name="subStatus"
                className={`form-control`}
              // onBlur={(event) => { validateField('subStatus', event.target.value) }}
              // onChange={(event) => { dispatch(change('ScanAssetForm', 'subStatusName', event.target[event.target.selectedIndex].text)); }}
              >
                <option value="">Select</option>
                {/* {renderSubStatus(props.subStatusList)} */}
              </Field>
            </Form.Group>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            {hasSelectedField(selectedBarcodeFields, 'serial_number') ? <div>
              Serial Number: {cIEditDetails.SERIAL_NUMBER} &nbsp;
              <Button
                bsSize="xsmall"
                bsStyle="primary"
                className="rgSidrkBtn smallBtn"
                onClick={() => generateBarcode('SERIAL_NUMBER', cIEditDetails.SERIAL_NUMBER)}
              >
                <FaBarcode title="Generate Barcode" />
              </Button>
            </div> : ''}
            {hasSelectedField(selectedBarcodeFields, 'asset_tag') ? <div>
              Asset Tag: {cIEditDetails.ASSET_TAG} &nbsp;
              <Button
                bsSize="xsmall"
                bsStyle="primary"
                className="rgSidrkBtn smallBtn"
                onClick={() => generateBarcode('ASSET_TAG', cIEditDetails.ASSET_TAG)}
              >
                <FaBarcode title="Generate Barcode" />
              </Button>
            </div> : ''}
            {hasSelectedField(selectedBarcodeFields, 'model_name') ? <div>
              Model Name: {cIEditDetails.MODEL_NAME} &nbsp;
              <Button
                bsSize="xsmall"
                bsStyle="primary"
                className="rgSidrkBtn smallBtn"
                onClick={() => generateBarcode('MODEL_NAME', cIEditDetails.MODEL_NAME)}
              >
                <FaBarcode title="Generate Barcode" />
              </Button>
            </div> : ''}
            {hasSelectedField(selectedBarcodeFields, 'model_number') ? <div>
              Model Number: {cIEditDetails.MODEL_NUMBER} &nbsp;
              <Button
                bsSize="xsmall"
                bsStyle="primary"
                className="rgSidrkBtn smallBtn"
                onClick={() => generateBarcode('MODEL_NUMBER', cIEditDetails.MODEL_NUMBER)}
              >
                <FaBarcode title="Generate Barcode" />
              </Button>
            </div> : ''}

            <h1>Printable Area</h1>
            <p>Below is the content that will be printed.</p>
            <div id="printableArea">
              <div style={{ width: selectedBarcodePrintProfile.pageWidth + "px", marginTop: selectedBarcodePrintProfile.pageMarginTop + "px", marginBottom: selectedBarcodePrintProfile.pageMarginBottom + "px", marginLeft: selectedBarcodePrintProfile.pageMarginLeft + "px", marginRight: selectedBarcodePrintProfile.pageMarginRight + "px" }}>
                {Object.keys(barcodeDataURLs).map(barcodeName => (
                  <div key={barcodeName}>
                    <img src={barcodeDataURLs[barcodeName]} alt={barcodeName} />
                  </div>
                ))}
              </div>
            </div>
            {Object.keys(barcodeDataURLs).length > 0 ?
              <Button
                bsSize="xsmall"
                bsStyle="primary"
                className="rgSidrkBtn smallBtn"
                onClick={printDiv}
                disabled={isPrinting}
              >
                <LuPrinter />
              </Button>
              : ''}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default reduxForm({
  form: 'GenerateBarocdeForm',
  destroyOnUnmount: false,
  enableReinitialize: true
})(GenerateBarcode);