
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { navigationHooks } from '../../../../helpers/NavigationHook';
import Breadcrumbs from '../../../common/header/breadcrumbs';


import AiInsightsBar from '../../../common/aiInsightsBar.js';

// import { browserHistory, Link } from 'react-router';
import { Link } from 'react-router-dom';
// import SupportHeader from './xsm-breakFixEdit-header.js';
import SupportHeader from './SupportHeader';
// import EditForm from './xsm-breakFix-formTabs_edit.js';
import EditForm from './EditForm';
// import ProvideFix from './xsm-breakFix-provideFixTab.js';
import ProvideFix from './ProvideFix';
// import Attachment from '../../components/common/attachment.js';
import Attachment from '../../../common/attachment';
// import ProposeCritical from './xsm-breakFix-proposeCriticalTab.js';
import ProposeCritical from './ProposeCritical';
// import ShowCritical from './xsm-breakFix-showCriticalTab.js';
import ShowCritical from './ShowCritical';
// import AcceptCritical from './xsm-breakFix-acceptCriticalTab.js';
import AcceptCritical from './AcceptCritical';
// import RejectCritical from './xsm-breakFix-rejectCriticalTab.js';
import RejectCritical from './RejectCritical';
// import NotifyTab from './xsm-breakFix-notifyTab';
import NotifyTab from './NotifyTab';
// import TabPage from './breakFixEdit-tabPage.js';
import TabPage from './TabPage';
// import Empty from './xsm-breakFix-action0.js';
import Empty from '../../../common/WorkItemBoard/Empty';
// import Footer from "../../components/footerCrp.js";
import Footer from '../../../common/footer/FooterCopyright';
// import SLA from './sla.js';
import SLA from './SLA';
import LoadingIcons from 'react-loading-icons';
// import CreateProblem from '../Investigation/createInvestigation/createBreakFixInvestigation.js'
import CreateProblem from './CreateProblem';
// import CreateChange from '../changeManagement/changeCreate/createBreakFixChange.js'
import CreateChange from './CreateChange';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { loadStatusBasedHamburgerOptions, resetLoadHamburgerOptionsReducer } from '../../../../actions/common/loadStatusBasedHamburgerOptions';
// import PopUpTask from './breakfixTask';
import PopUpTask from '../../../common/WorkItemBoard/breakfixTask';

// import PopUpRelatedWork from './breakfixRelatedWork';
import PopUpRelatedWork from '../../../common/WorkItemBoard/breakfixRelatedWork';
// import PopUpCMDBRelatedWork from './cmdbbreakfixRelatedWork';
import PopUpCMDBRelatedWork from '../../../common/WorkItemBoard/cmdbbreakfixRelatedWork';

import { GLOBAL } from "_Globals";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Swal from "sweetalert2";

import { loadBreakFixEditDetails, loadBreakFixEditDetailsUpdate, loadExternalAttributesDetails } from '../../../../actions/breakFix/breakFixEditDetailsAction';
// import UserDetails from './xsmEditRequestedBy.js';
import UserDetails from '../../../common/WorkItemBoard/xsmEditRequestedBy';
// import CIDetails from "./xsm-ci-editquickView.js";
import CIDetails from '../../../common/WorkItemBoard/xsm-ci-editquickView';
// import ServiceDetails from './xsm-service-editquickView.js';
import ServiceDetails from '../../../common/WorkItemBoard/xsm-service-editquickView';

import { loadBreakFixSLAResponseLogDetails } from '../../../../actions/breakFix/breakFixSLAResponseLogAction';
import { loadBreakFixSLAResolutionLogDetails } from '../../../../actions/breakFix/breakFixSLAResolutionLogAction';
// import IncidentReason from './xsm-breakFix-reason.js';
import IncidentReason from './IncidentReason';
// import CIR from './xsm-CIR.js';
import CIR from './CIR';
// import CasualRelationship from './causalRelationship.js'
import CasualRelationship from '../../../common/WorkItemBoard/causalRelationship';
// import CopyIncident from './xsm-breakFixEdit-CopyIncident';
import CopyIncident from './CopyIncident';

import { getTranslation } from '../../../../actions/spcmActions';
import configureStore from '../../../../store/configureStore';
const store = configureStore();

// import ExternalSystemAttriburte from './xsm-breakFix-externalSystemAttribute.js';
import ExternalSystemAttriburte from '../../../common/WorkItemBoard/xsm-breakFix-externalSystemAttribute';

// import KnowledgeArticles from './knowledgeArticle/index.js';
import KnowledgeArticles from '../../../common/WorkItemBoard/knowledgeArticle/index';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

// import WIBSearch from '../../containers/fulfillment/wibSearch-fulfillment';
import WIBSearch from '../../../common/WorkItemBoard/WIBSearch/WIBSearchEdit';
import { fetchWatcherDetails } from '../../../../actions/fulfillment/watcherActions';
import WatcherDetails from '../../../common/WorkItemBoard/watcher/WatcherDetails';
import TagDetails from '../../../common/tags/TagDetails';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import AvailableIndividuals from '../../../common/WorkItemBoard/availableIndividuals/AvailableIndividuals';

import AiInsights from '../../../common/aiInsights.js';

import { exportDoc } from './helper';
import { getAdminPreferences } from '../../../../actions/adminPreferences/adminPreferencesAction.js';
import "_Css/form/_form.scss";
import "_Css/common/_aiButton.scss";
import { breakFixStatusMapping, opsCategoryKeysMap } from '../../../common/helper.js';

//GLOBAL.serviveName="";
class EditIncidentIndex extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			subMenu: '4',
			provideFix: 0,
			acceptCritical: 0,
			rejectCritical: 0,
			provideAttachment: 0,
			processHierarchy: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:0,
			relateCIFlag:0,
			SLA: 0,
			provideUserDetails: 0,
			service: 0,
			offeringId: 0,
			ciId: 0,
			priorityColor: '',
			showCritical: 0,
			showNotify: 0,
			aiInsights:0,
			provideFixStatus: '',
			breakFixSGAEName: '',
			proposeInvestigation: '',
			proposeChange: '',
			showHideRightNav: false,
			mobileLeftMenuFilter: false,
			putOnHold: 0,
			taskeditFlag:0,
			taskcode:'',
			taskregid: '',
			cancelIssue: 0,
			showExternalSystemAttributesFlag: 0,
			supportPIndividualValue: undefined,
			user_id: '',
			user_name: '',
			role_id: '',
			groupID:'',
		    roleID:'',
			changeImplentationRole:'',
			date_format: '',
			full_name: '',
			proposeCriticalTabStatus: false,
			Reopen: 0,
			CIR: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			consumerColor: '',
			grpReasonErrorColor: '',
			ImpactedErrorColor: '',
			isPriorityCallInProgress: false,
			consumerState: {},
			locationId: '',
			locationName: '',
			unchangedData: null,
			userDetailsLabel: '',
			breakFixEditDetailss: null,
			isFormAPIsTriggered: false,
			consumerValue: '',
			supportIndividualValue: '',
			urgencyListKeyValue:{},
			impactListKeyValue:{},
			showWatcher:0,
			breakfixUrgencyError: '',
			breakfixImpactError: '',
			showTags:0,
			isRightEditPanel: true,
            disableFieldsForGuestRole: false,
            isChangedRolesEnabled: false,
            isProblemRolesEnabled: false,
            isIncidentRolesEnabled: false,
            isFulfillmentRolesEnabled: false,
            isTaskRoleEnabled: false,
			selectedAssignmentGroup:[],
			selectedAssignmentUser:[],
			showAvailableIndividual:0,
			isVisible: true,
			aismValue: null,
			aiInsightFCREnabled: false,
			aiSummaruzationEnabled: false,
			prevSelectedAction: "",
			aiaismIncidentRateEnabled: false,
			isIncidentCalled: false,
			isReopen: false
		};
		this.params = navigationHooks.params;
		// console.log('this.params - ', this.params);
		this.showProvideFix = this.showProvideFix.bind(this);
		this.showOnHold = this.showOnHold.bind(this);
		this.showCancelIssue = this.showCancelIssue.bind(this);
		this.showProposeCritical = this.showProposeCritical.bind(this);
		this.showProvideAttachment = this.showProvideAttachment.bind(this);
		this.showProcessHierarchy = this.showProcessHierarchy.bind(this);
		this.showConsumerDetails = this.showConsumerDetails.bind(this);
		this.showServiceDetails = this.showServiceDetails.bind(this);
		this.acceptCritical = this.acceptCritical.bind(this);
		this.rejectCritical = this.rejectCritical.bind(this);
		this.showCriticality = this.showCriticality.bind(this);
		this.changeProvideFixStatus = this.changeProvideFixStatus.bind(this);
		this.updateIsVisible = this.updateIsVisible.bind(this);
		this.showNotify = this.showNotify.bind(this);
		this.setBreakFixSGAEName = this.setBreakFixSGAEName.bind(this);
		this.showSLA = this.showSLA.bind(this);
		this.showProposeInvestigation = this.showProposeInvestigation.bind(this);
		this.showProposeChange = this.showProposeChange.bind(this);
		this.showHideRightNav = this.showHideRightNav.bind(this);
		this.showCausalRelation = this.showCausalRelation.bind(this);
		this.showExternalSystemAttributes = this.showExternalSystemAttributes.bind(this);
		this.supportPIndividualValueChange = this.supportPIndividualValueChange.bind(this);
		this.showTimeline = this.showTimeline.bind(this);
		this.ChangeproposeCriticalTabStatus = this.ChangeproposeCriticalTabStatus.bind(this)
		this.roleIdentification = this.roleIdentification.bind(this)
		this.showReasonToReopen = this.showReasonToReopen.bind(this)
		this.showCIRform = this.showCIRform.bind(this);
		this.setShowCopyServiceForm = this.setShowCopyServiceForm.bind(this)
		this.setErrorImpactedColor = this.setErrorImpactedColor.bind(this);
		this.showKnowledgeArticles = this.showKnowledgeArticles.bind(this);
		this.setErrorServiceColor = this.setErrorServiceColor.bind(this);
		this.setConsumerColor = this.setConsumerColor.bind(this);
		this.setGroupReasonErrorColor = this.setGroupReasonErrorColor.bind(this);
		this.setPriorityCallFlag = this.setPriorityCallFlag.bind(this);
		this.saveConsumerState = this.saveConsumerState.bind(this);
		this.showCiDetails = this.showCiDetails.bind(this);
		this.showTasks = this.showTasks.bind(this);
		this.clicktaskEdit = this.clicktaskEdit.bind(this);
		this.showRelatedWorkItems = this.showRelatedWorkItems.bind(this);
		this.showRelateCIs = this.showRelateCIs.bind(this);
		this.setUnchangedData = this.setUnchangedData.bind(this);
		this.checkDataChanged = this.checkDataChanged.bind(this);
		this.clearUserDetails = this.clearUserDetails.bind(this);
		this.groupIdentification = this.groupIdentification.bind(this);
		this.setFormAPITriggered = this.setFormAPITriggered.bind(this);
		this.setIncidentDetails = this.setIncidentDetails.bind(this);
        this.setUrgencyImpact = this.setUrgencyImpact.bind(this);
		this.showWatcher = this.showWatcher.bind(this);
		this.setUrgencyError=this.setUrgencyError.bind(this);
		this.setImpactError=this.setImpactError.bind(this);
		this.showTags = this.showTags.bind(this);
		this.rightEditPanel=this.rightEditPanel.bind(this);
		this.RefreshScreen = this.RefreshScreen.bind(this);
		this.roleCalculatorForGuestUser = this.roleCalculatorForGuestUser.bind(this);
		this.setAssignmentGrpUsr = this.setAssignmentGrpUsr.bind(this);
		this.showAvailableIndividual = this.showAvailableIndividual.bind(this);
		this.setAssignmentGrpUsr = this.setAssignmentGrpUsr.bind(this);
		this.exportDocument = this.exportDocument.bind(this);
		this.resetRightSide = this.resetRightSide.bind(this);
		this.aiInsightsShowFun = this.aiInsightsShowFun.bind(this);
		this.changeRightView = this.changeRightView.bind(this);
		this.getAdminParameters = this.getAdminParameters.bind(this);
	}

	createFCRPayload = () => {
		const { breakFixEditDetails } = this.props;
		const payload = {
		  impactedUserid: breakFixEditDetails.impactedUserid || '',
		  createdBy: breakFixEditDetails.createdBy || '',
		  spUserid: breakFixEditDetails.spUserid || '',
		  serviceCriticality: breakFixEditDetails.serviceCriticality || '',
		  priorityId: breakFixEditDetails.priorityId || '',
		  spGroupid: breakFixEditDetails.spGroupid || '',
		  reportedThrough: breakFixEditDetails.reportedThrough || '',
		  urgency: breakFixEditDetails.urgency || '',
		  reasonCode: breakFixEditDetails.reasonCode || '',
		  description: breakFixEditDetails.description || '',
		  companyId: breakFixEditDetails.consumerCompany || '',
		  serviceBased: breakFixEditDetails.serviceBased || '',
		};
	
		return payload;
	  };

	setAssignmentGrpUsr(selectd,type){
		switch(type){
		  case "user":
			this.setState({selectedAssignmentUser:selectd});
			break;
		  case  "group":
			this.setState({selectedAssignmentGroup:selectd});
			break;
		}
	  }

	setUrgencyImpact(list){
	  let urgencyList={},impactList={};
      list.map((item)=>{
          if(item.field2Name==='Impact'){
			impactList[item.field1Value]=item.field1Key;
		  }else{
            urgencyList[item.field1Value]=item.field1Key;
		  }
	  });
	  this.setState({urgencyListKeyValue: urgencyList, impactListKeyValue: impactList});
	}

	rightEditPanel(value) {
		this.setState({ isRightEditPanel: value });
	}

	clicktaskEdit(flag, taskCode, regId) {
		//alert("iclick::::"+i+"j::::"+j)
		this.setState({
			taskeditFlag: flag,
			taskcode: taskCode,
			taskregid: regId
		});
	}

	saveConsumerState(consumerStatevalue) {
		this.setState({ consumerState: consumerStatevalue });
	}
	setPriorityCallFlag(value) {
		this.setState({
			isPriorityCallInProgress: value
		});
	}

	setConsumerColor(value) {
		this.setState({ consumerColor: value })
	}
	setGroupReasonErrorColor(value) {
		this.setState({ grpReasonErrorColor: value })
	}

	showTimeline(flagValue) {
		// console.log('this - ',this);
		this.setState({ showTimelineFlag: flagValue });
	}

	supportPIndividualValueChange(supportPIndividual) {
		this.setState({ supportPIndividualValue: supportPIndividual });
	}

	showHideRightNav(value) {
		this.setState({ showHideRightNav: !this.state.showHideRightNav });
	};

	mobileLeftMenuFilter() {
		this.setState({ mobileLeftMenuFilter: !this.state.mobileLeftMenuFilter });
	};

	setBreakFixSGAEName(breakFixSGAEName) {
		this.setState({ breakFixSGAEName: breakFixSGAEName });
	};
	changeProvideFixStatus(value) {
		this.setState({ provideFixStatus: value });
	};

	componentWillUnmount() {
		this.props.dispatch({ type: 'LOAD_BREAKFIXEDITDETAILS_RESET' })
	}

	updateIsVisible(val){
		this.setState({ isVisible: val });
	};

	componentDidMount() {
		// setTimeout(() => {
		// 	this.setState({testID: {a: 2}});
		// }, 2000);
		this.setState({
			consumerState: {
				'breakFixConsumerName': '',
				'breakFixConsumerID': '',
				'servicelocationid': '',
				'servicelocationname': '',
				'Vip': '',
				'servicedepartmentid': '',
				'servicedepartmentname': '',
				'isVIPuser': ''
			}
		});
		if (GLOBAL.slaHeadEnable){
			GLOBAL.processhierarchy = 0;
			this.setState({
				SLA : 1
			})
			this.showSLA();
			this.rightEditPanel(true);
		}
		// console.log("this.params.breakFixItemId", this.params.breakFixItemId);
		this.props.resetLoadHamburgerOptionsReducer();
		//store.dispatch({type:'BREAKFIX_EDIT_DETAILS_LOAD_STATUS',status:'loading'});
		// let getBreanFixkItemId = (this.params.breakFixItemId) ? (this.params.breakFixItemId).split(":") : ""
		//let BRKItemId = getBreanFixkItemId[0];
		// let breakFixActualItemId = getBreanFixkItemId[1];
		// GLOBAL.breakFixId = breakFixActualItemId
		//alert("parmater"+breakFixActualItemId);
		//alert("this.props.breakFixEditDetails.length:"+this.props.breakFixEditDetails.length)

		// let breakFixActualItemId = this.params.breakFixItemId || "";
		// setTimeout(() => {
			this.props.loadBreakFixEditDetails(this.params.breakFixItemId).then(res => {
				// console.log('response - ', res);
				if(res && res.data){
					let consumerVal = (res.data && res.data.impactedUserid) ? res.data.impactedUserid : "";
					let individualVal = (res.data && res.data.spUserid) ? res.data.spUserid : "";
					this.setState({breakFixEditDetailss: res.data, consumerValue: consumerVal, supportIndividualValue: individualVal});
				}
			}).catch(err => {
				console.log('err - ', err);
			});
		// }, 2000);
		//alert("GLOBAL.serviveName"+this.props.breakFixEditDetails.serviceId)
		//	this.props.loadBreakFixSLAResponseLogDetails("Breakfix",breakFixActualItemId);
		// this.props.loadBreakFixSLAResolutionLogDetails("Breakfix",breakFixActualItemId);
		GLOBAL.processhierarchy = this.props.windowSize < 768 ? 0 : 1;
		GLOBAL.proposeCritical = 0;
		GLOBAL.acceptCritical = 0;
		GLOBAL.rejectCritical = 0;
		GLOBAL.criticalitystatusval = 0;
		if(this.props.breakFixEditDetails && this.props.breakFixEditDetails.spUserid){
			// supportPIndividualValue = this.props.breakFixEditDetails.spUserid;
			this.setState({supportPIndividualValue: this.props.breakFixEditDetails.spUserid});

		}
		let str = {};
		str.user_id = "";
		str = JSON.stringify(str);
		api.get("/api/userInfo", { headers: { 'query': str } }).then((response) => {
			let changeUserid='';
			if(response.data.role_id.split(",").includes("20")&&(!response.data.role_id.split(",").includes("52")))
			{
			  changeUserid="changeUserid";
			}
			else if(response.data.role_id.split(",").includes("52")&&(!response.data.role_id.split(",").includes("20")))
			{
			  changeUserid="impUserid";
			}
			else if(response.data.role_id.split(",").includes("20")&&response.data.role_id.split(",").includes("52")||response.data.role_id.split(",").includes("20")&&response.data.role_id.split(",").includes("51")||response.data.role_id.split(",").includes("52")&&response.data.role_id.split(",").includes("19"))
			{
			  changeUserid="impUserid,changeUserid";
			}
			else if(response.data.role_id.split(",").includes("19")&&(!response.data.role_id.split(",").includes("51")))
			{
			  changeUserid="changeUserid";
			}
			else if(response.data.role_id.split(",").includes("51")&&(!response.data.role_id.split(",").includes("19")))
			{
			  changeUserid="impUserid";
			}
			else if(response.data.role_id.split(",").includes("19")&&response.data.role_id.split(",").includes("51"))
			{
			  changeUserid="impUserid,changeUserid";
			}
			this.setState({
				user_id: response.data.user_id,
				user_name: response.data.user_name,
				role_id: response.data.role_id,
				groupID:response.data.groupId,
			    roleID:response.data.role_id,
				changeImplentationRole: changeUserid,
				date_format: response.data.dateformat,
				full_name: response.data.full_name,
				locationId: response.data['locationId'] || '',
				locationName: response.data['locationName'] || '',
        		isRightEditPanel: this.props.windowSize < 768 ? false : true,
			});
            this.roleCalculatorForGuestUser(response.data.role_id);


		});
		this.props.fetchWatcherDetails('Breakfix',this.params.breakFixItemId);

		if(this.props.breakFixEditDetails && Object.keys(this.props.breakFixEditDetails).length > 0 && this.props.breakFixEditDetails.criticalityStatus == "Proposed"){
			this.showCriticality();
		}
	}

	RefreshScreen(obj){
		Swal.fire({
			title:this.props.tr["Are you sure your want to refresh ?"],
			showDenyButton:true,
			confirmButtonText:this.props.tr['Yes'],
			denyButtonText:this.props.tr['No'],
		  }).then((result) => {
			if (result.isConfirmed) {
				try{
					this.props.resetLoadHamburgerOptionsReducer();
					this.props.loadBreakFixEditDetails(this.params.breakFixItemId).then(res => {
						// console.log('response - ', res);
						if(res && res.data){
							let consumerVal = (res.data && res.data.impactedUserid) ? res.data.impactedUserid : "";
							let individualVal = (res.data && res.data.spUserid) ? res.data.spUserid : "";
							this.setState({breakFixEditDetailss: res.data, consumerValue: consumerVal, supportIndividualValue: individualVal});
						}
					}).catch(err => {
						console.log('err - ', err);
					});
                    this.resetRightSide();
					if(this.props.breakFixEditDetails && this.props.breakFixEditDetails.spUserid){
						// supportPIndividualValue = this.props.breakFixEditDetails.spUserid;
						this.setState({supportPIndividualValue: this.props.breakFixEditDetails.spUserid});

					}
					this.props.fetchWatcherDetails('Breakfix',this.params.breakFixItemId);

					if(this.props.breakFixEditDetails && Object.keys(this.props.breakFixEditDetails).length > 0 && this.props.breakFixEditDetails.criticalityStatus == "Proposed"){
						this.showCriticality();
					}

				} catch(e){
					console.error(e);
				}
			} else if (result.isDenied) {}
		  })
	}

	changeRightView(key) {
		switch (key) {
		  case "proposeCritical":
			this.showProposeCritical();
			break;
		  case "processhierarchy":
			this.showProcessHierarchy();
			break;
		  case "acceptCritical":
			this.acceptCritical();
			break;
		  case "rejectCritical":
			this.rejectCritical();
			break;
		  case "putOnHold":
			this.showOnHold();
			break;
		  case "cancelIssue":
			this.showCancelIssue();
			break;
		  case "provideFix":
			this.showProvideFix('');
			break;
		  case "aiInsights":
			this.aiInsightsShowFun();
			break;
		  case "reopen":
			this.showReasonToReopen();
			break;
		  case "cirView":
			this.showCIRform();
			break;
		  case "provideAttachment":
			this.showProvideAttachment();
			break;
		  case "taskeditFlag":
			this.showTasks();
			break;
		  case "showCritical":
			this.showCriticality();
			break;
		  case "slaView":
			this.showSLA();
			break;
		  case "proposeInvestigation":
			this.showProposeInvestigation();
			break;
		  case "proposeChange":
			this.showProposeChange();
			break;
		  case "showNotify":
			this.showNotify();
			break;
		  case "showCausalRelation":
			this.showCausalRelation();
			break;
		  case "relatedWorkItemsFlag":
			this.showRelatedWorkItems();
			break;
		  case "relateCIFlag":
			this.showRelateCIs();
			break;
		  case "showExternalSystemAttributesFlag":
			this.showExternalSystemAttributes();
			break;
		  case "showCopyServiceForm":
			this.setShowCopyServiceForm(1);
			break;
		  case "showKnowledgeArticleFlag":
			this.showKnowledgeArticles();
			break;
		  case "showWatcher":
			this.showWatcher();
			break;
		  case "showTags":
			this.showTags();
			break;
		  case "showAvailableIndividual":
			this.showAvailableIndividual();
			break;
		  case "allClose":
			GLOBAL.proposeCritical = 0;
			GLOBAL.processhierarchy = 0;
			GLOBAL.acceptCritical = 0;
			GLOBAL.rejectCritical = 0
			this.setState({
				putOnHold: 0,
				cancelIssue: 0,
				provideFix: 0,
				aiInsights:0,
				Reopen: 0,
				CIR: 0,
				provideAttachment: 0,
				provideUserDetails: 0,
				service: 0,
				taskeditFlag:0,
				offeringId: 0,
				ciId: 0,
				showCritical: 0,
				SLA: 0,
				proposeInvestigation: 0,
				proposeChange: 0,
				showNotify: 0,
				showCausalRelation: 0,
				relatedWorkItemsFlag:0,
				relateCIFlag:0,
				showExternalSystemAttributesFlag: 0,
				showCopyServiceForm: 0,
				showKnowledgeArticleFlag: 0,
				showWatcher:0,
				showTags:0,
				showAvailableIndividual:0
			});
			break;
		}
	  }

	getAdminParameters(companyId, module, propertyId, propertyName){
		const queryObj = {companyId,module,propertyId};
        const queryString = JSON.stringify(queryObj);
		axios.get('/api/getAdminPreferences', { headers: { query: queryString } }).then(retrivedFlags => {
			switch(propertyName){
				case 'DSAT': 
				             const retrivedDSAT =  JSON.parse(retrivedFlags.data[0].value)?.aism;
			                 const retrivedDsatFlag = retrivedDSAT === 'true' ? true : false;
							 this.setState({aiaismIncidentRateEnabled: retrivedDsatFlag,});
				             break;
				case 'FCR': 
				             const retrivedFCR =  JSON.parse(retrivedFlags.data[0].value)?.aism;
			                 const retrivedFcrFlag = retrivedFCR === 'true' ? true : false;
				             this.setState({aiInsightFCREnabled: retrivedFcrFlag,});
				            break;
			    case 'Summarization':
					         const retrivedSummarization = retrivedFlags.data[0].value;
			                 const retrivedSummarizationFlag = retrivedSummarization === 'true' || retrivedSummarization === 'True' ? true : false;
				             this.setState({aiSummaruzationEnabled: retrivedSummarizationFlag,});
				             break;
				case 'Reopen':
						const isReopen = JSON.parse(retrivedFlags.data[0].value)?.aism == 'true' ? true : false;
						this.setState({isReopen})
						break;

			}
		});
	}

	componentWillReceiveProps(nextProps) {

		if (this.props.windowSize != nextProps.windowSize) {
			if (nextProps.windowSize < 768) {
				this.setState({ isRightEditPanel: false });
        		this.changeRightView("allClose");
			}
			else {
			  	this.setState({ isRightEditPanel: true });
				this.changeRightView(this.state.prevSelectedAction);
				if (this.state.actionView === "" && this.state.prevSelectedAction === "") {
				this.changeRightView("processhierarchy");
				}
			}
		}
		if(!this.state.isIncidentCalled && nextProps.breakFixEditDetails.consumerCompany){
			this.setState({isIncidentCalled: true})
			this.getAdminParameters(nextProps.breakFixEditDetails.consumerCompany,'Incident','28','DSAT');
			this.getAdminParameters(nextProps.breakFixEditDetails.consumerCompany,'Incident','29','FCR');
			this.getAdminParameters(nextProps.breakFixEditDetails.consumerCompany,'Work Item Board','30','Summarization');
			this.getAdminParameters(nextProps.breakFixEditDetails.consumerCompany,'Incident','45','Reopen');
		}
	  
		// let BreakFixID = (nextProps.params.breakFixItemId).split(":");
		// console.log(BreakFixID[1]);
		// let BreakFixID2 = (this.params.breakFixItemId).split(":");
		// console.log(BreakFixID2[1]);
		// if (BreakFixID[1] != BreakFixID2[1]) {
		// 	console.log("this.params.breakFixItemId++++++++++++++", this.params.breakFixItemId);
		// 	this.props.resetLoadHamburgerOptionsReducer();
		// 	//store.dispatch({type:'BREAKFIX_EDIT_DETAILS_LOAD_STATUS',status:'loading'});
		// 	let getBreanFixkItemId = (nextProps.params.breakFixItemId) ? (nextProps.params.breakFixItemId).split(":") : ""
		// 	//let BRKItemId = getBreanFixkItemId[0];
		// 	let breakFixActualItemId = getBreanFixkItemId[1];
		// 	// GLOBAL.breakFixId = breakFixActualItemId
		// 	//alert("parmater"+breakFixActualItemId);
		// 	//alert("this.props.breakFixEditDetails.length:"+this.props.breakFixEditDetails.length)

		// 	this.props.loadBreakFixEditDetails(breakFixActualItemId);
		// 	//alert("GLOBAL.serviveName"+this.props.breakFixEditDetails.serviceId)
		// 	//	this.props.loadBreakFixSLAResponseLogDetails("Breakfix",breakFixActualItemId);
		// 	// this.props.loadBreakFixSLAResolutionLogDetails("Breakfix",breakFixActualItemId);
		// 	GLOBAL.processhierarchy = 1;
		// 	GLOBAL.proposeCritical = 0;
		// 	GLOBAL.acceptCritical = 0;
		// 	GLOBAL.rejectCritical = 0;
		// 	GLOBAL.criticalitystatusval = 0;
		// 	supportPIndividualValue: this.props.breakFixEditDetails && this.props.breakFixEditDetails.spUserid ? this.props.breakFixEditDetails.spUserid : 0
		// 	let str = {};
		// 	str.user_id = "";
		// 	str = JSON.stringify(str);
		// 	api.get("/api/userInfo", { headers: { 'query': str } }).then((response) => {
		// 		let changeUserid='';
		// 		if(response.data.role_id.split(",").includes("20")&&(!response.data.role_id.split(",").includes("52")))
		// 		{
		// 		  changeUserid="changeUserid";
		// 		}
		// 		else if(response.data.role_id.split(",").includes("52")&&(!response.data.role_id.split(",").includes("20")))
		// 		{
		// 		  changeUserid="impUserid";
		// 		}
		// 		else if(response.data.role_id.split(",").includes("20")&&response.data.role_id.split(",").includes("52")||response.data.role_id.split(",").includes("20")&&response.data.role_id.split(",").includes("51")||response.data.role_id.split(",").includes("52")&&response.data.role_id.split(",").includes("19"))
		// 		{
		// 		  changeUserid="impUserid,changeUserid";
		// 		}
		// 		else if(response.data.role_id.split(",").includes("19")&&(!response.data.role_id.split(",").includes("51")))
		// 		{
		// 		  changeUserid="changeUserid";
		// 		}
		// 		else if(response.data.role_id.split(",").includes("51")&&(!response.data.role_id.split(",").includes("19")))
		// 		{
		// 		  changeUserid="impUserid";
		// 		}
		// 		else if(response.data.role_id.split(",").includes("19")&&response.data.role_id.split(",").includes("51"))
		// 		{
		// 		  changeUserid="impUserid,changeUserid";
		// 		}
		// 		this.setState({
		// 			user_id: response.data.user_id,
		// 			user_name: response.data.user_name,
		// 			role_id: response.data.role_id,
		// 			groupID:response.data.groupId,
		// 	        roleID:response.data.role_id,
		// 			changeImplentationRole: changeUserid,
		// 			date_format: response.data.dateformat,
		// 			full_name: response.data.full_name,
		// 			locationId: response.data['locationId'] || '',
		// 			locationName: response.data['locationName'] || ''
		// 		});


		// 	});
		// 	this.setState({
		// 		subMenu: '4',
		// 		provideFix: 0,
		// 		acceptCritical: 0,
		// 		rejectCritical: 0,
		// 		provideAttachment: 0,
		// 		processHierarchy: 0,
		// 		showCausalRelation: 0,
		// 		relatedWorkItemsFlag:0,
		// 		relateCIFlag:0,
		// 		SLA: 0,
		// 		provideUserDetails: 0,
		// 		service: 0,
		// 		offeringId: 0,
		// 		ciId: 0,
		// 		priorityColor: '',
		// 		showCritical: 0,
		// 		showNotify: 0,
		// 		showNotifyForm: true,
		// 		provideFixStatus: '',
		// 		breakFixSGAEName: '',
		// 		proposeInvestigation: '',
		// 		proposeChange: '',
		// 		showHideRightNav: false,
		// 		mobileLeftMenuFilter: false,
		// 		taskeditFlag:0,
		// 		putOnHold: 0,
		// 		cancelIssue: 0,
		// 		showExternalSystemAttributesFlag: 0,
		// 		supportPIndividualValue: undefined,
		// 		user_id: '',
		// 		role_id: '',
		// 		date_format: '',
		// 		full_name: '',
		// 		proposeCriticalTabStatus: false,
		// 		Reopen: 0,
		// 		CIR: 0,
		// 		ImpactedErrorColor: '',
		// 		serviceErrorColor: '',
		// 		showKnowledgeArticleFlag: 0
		// 	})
		// }
		if (nextProps.loadHamburgerOptions !== this.props.loadHamburgerOptions && nextProps.loadHamburgerOptions === true) {
			// console.log('#########loadStatusBasedHamburgerOptions############');
			// console.log(this.props.breakFixEditDetails);
			this.props.loadStatusBasedHamburgerOptions('Breakfix', nextProps.breakFixEditDetails.status, nextProps.breakFixEditDetails.breakfixId);
		}

		// console.log('1111111111111111111111111111111', nextProps.breakFixEditDetails, this.props.breakFixEditDetails, nextProps.breakFixEditDetails.criticalityStatus);

		if(nextProps.breakFixEditDetails !== this.props.breakFixEditDetails && this.props.breakFixEditDetails && Array.isArray(this.props.breakFixEditDetails) && this.props.breakFixEditDetails.length == 0 && nextProps.breakFixEditDetails && Object.keys(nextProps.breakFixEditDetails).length > 0 && nextProps.breakFixEditDetails.breakfixId && nextProps.breakFixEditDetails.criticalityStatus == "Proposed"){
			this.showCriticality();
		}
		else if (GLOBAL.proposeCritical == 1 && this.state.showCritical == 1)
			GLOBAL.criticalitystatusval = 0;

		// if (nextProps.breakFixEditDetails !== this.props.breakFixEditDetails && nextProps.breakFixEditDetails.criticalityStatus == "Proposed")
		// 	this.showCriticality();
		// else if (GLOBAL.proposeCritical == 1 && this.state.showCritical == 1)
		// 	GLOBAL.criticalitystatusval = 0
	}
	setErrorImpactedColor(value) {

		this.setState({
			ImpactedErrorColor: value
		})
	}
	setErrorServiceColor(value) {
		this.setState({
			serviceErrorColor: value
		})
		console.log("value", value);
	}
	showProposeInvestigation() {

		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0;
		GLOBAL.rejectCritical = 0
		this.setState({
			putOnHold: 0,
			cancelIssue: 0,
			provideFix: 0,
			aiInsights:0,
			Reopen: 0,
			CIR: 0,
			//acceptCritical:0,
			//rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:0,
			provideUserDetails: 0,
			service: 0,
			taskeditFlag:0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 1,
			proposeChange: 0,
			showNotify: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:0,
			relateCIFlag:0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			prevSelectedAction: "proposeInvestigation",
		});

	}
	showProposeChange() {

		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0;
		GLOBAL.rejectCritical = 0
		this.setState({
			putOnHold: 0,
			cancelIssue: 0,
			Reopen: 0,
			CIR: 0,
			provideFix: 0,
			aiInsights:0,
			//acceptCritical:0,
			//rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:0,
			provideUserDetails: 0,
			taskeditFlag:0,
			service: 0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 1,
			showNotify: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:0,
			relateCIFlag:0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			prevSelectedAction: "proposeChange",
		});

	}

	showProvideFix(action) {

		GLOBAL.provideFix = action;
		this.props.loadBreakFixEditDetailsUpdate(action);
		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0
		GLOBAL.rejectCritical = 0
		this.setState({
			putOnHold: 0,
			cancelIssue: 0,
			Reopen: 0,
			CIR: 0,
			provideFix: 1,
			aiInsights:0,
			//acceptCritical:0,
			//	rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:1,
			provideUserDetails: 0,
			service: 0,
			offeringId: 0,
			ciId: 0,
			taskeditFlag:0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "provideFix",
		});


	}


	showCancelIssue() {

		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0
		GLOBAL.rejectCritical = 0
		this.setState({
			putOnHold: 0,
			cancelIssue: 1,
			Reopen: 0,
			CIR: 0,
			provideFix: 0,
			aiInsights:0,
			//acceptCritical:0,
			//	rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:1,
			provideUserDetails: 0,
			taskeditFlag:0,
			service: 0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:0,
			relateCIFlag:0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "cancelIssue",
		});


	}
	showReasonToReopen() {
		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0
		GLOBAL.rejectCritical = 0
		this.setState({
			putOnHold: 0,
			cancelIssue: 0,
			Reopen: 1,
			CIR: 0,
			provideFix: 0,
			aiInsights:0,
			//acceptCritical:0,
			//	rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:1,
			taskeditFlag:0,
			provideUserDetails: 0,
			service: 0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "reopen",
		});

	}
	showCIRform() {
		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0
		GLOBAL.rejectCritical = 0
		this.setState({
			putOnHold: 0,
			cancelIssue: 0,
			Reopen: 0,
			CIR: 1,
			provideFix: 0,
			aiInsights:0,
			//acceptCritical:0,
			//	rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:1,
			provideUserDetails: 0,
			taskeditFlag:0,
			service: 0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "cirView",
		});
	}


	showOnHold() {

		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0
		GLOBAL.rejectCritical = 0
		this.setState({
			putOnHold: 1,
			cancelIssue: 0,
			Reopen: 0,
			CIR: 0,
			provideFix: 0,
			aiInsights:0,
			//acceptCritical:0,
			//	rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:1,
			provideUserDetails: 0,
			service: 0,
			taskeditFlag:0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "putOnHold",
		});


	}

	showProposeCritical() {

		GLOBAL.proposeCritical = 1;
		GLOBAL.processhierarchy = 0;
		GLOBAL.proposemessage = '';
		GLOBAL.acceptCritical = 0
		GLOBAL.rejectCritical = 0

		this.setState({
			putOnHold: 0,
			cancelIssue: 0,
			Reopen: 0,
			CIR: 0,
			provideFix: 0,
			aiInsights:0,
			//acceptCritical:0,
			//rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:0,
			provideUserDetails: 0,
			service: 0,
			taskeditFlag:0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:0,
			relateCIFlag:0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "proposeCritical",
		});

	}

	showConsumerDetails(consumerId, label = '') {
		// console.log('consumerId - ', {consumerId, label});
		if(!( consumerId && !isNaN(consumerId) && !!parseInt(consumerId,10) )){
			return;
		}
		this.setState({provideUserDetails: 0}, () => {
			GLOBAL.proposeCritical = 0;
			GLOBAL.processhierarchy = 0;
			GLOBAL.acceptCritical = 0
			GLOBAL.rejectCritical = 0
			this.setState({
				putOnHold: 0,
				cancelIssue: 0,
				Reopen: 0,
				CIR: 0,
				provideFix: 0,
				aiInsights:0,
				//acceptCritical:0,
				//rejectCritical:0,
				provideAttachment: 0,
				//processhierarchy:0,
				provideUserDetails: consumerId,
				taskeditFlag:0,
				service: 0,
				offeringId: 0,
				ciId: 0,
				showCritical: 0,
				SLA: 0,
				proposeInvestigation: 0,
				proposeChange: 0,
				showNotify: 0,
				showExternalSystemAttributesFlag: 0,
				showCopyServiceForm: 0,
				showKnowledgeArticleFlag: 0,
				userDetailsLabel: label,
				showWatcher:0,
				showTags:0,
				showAvailableIndividual:0
			});
		});

	}

	showServiceDetails(serviceId) {

		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0;
		GLOBAL.rejectCritical = 0
		this.setState({
			service: 1,
			offeringId: serviceId,
			ciId: 0,
			provideFix: 0,
			aiInsights:0,
			putOnHold: 0,
			Reopen: 0,
			CIR: 0,
			cancelIssue: 0,
			taskeditFlag:0,
			//acceptCritical:0,
			//rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:0,
			provideUserDetails: 0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0
		});


	}

	showProvideAttachment() {

		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0;
		GLOBAL.rejectCritical = 0
		this.setState({
			putOnHold: 0,
			cancelIssue: 0,
			Reopen: 0,
			CIR: 0,
			provideFix: 0,
			aiInsights:0,
			//acceptCritical:0,
			//rejectCritical:0,
			provideAttachment: 1,
			//processhierarchy:0,
			taskeditFlag:0,
			provideUserDetails: 0,
			service: 0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "provideAttachment",
		});

	}
	aiInsightsShowFun() {
		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0;
		GLOBAL.rejectCritical = 0
		this.setState({
			putOnHold: 0,
			cancelIssue: 0,
			Reopen: 0,
			CIR: 0,
			provideFix: 0,
			aiInsights:1,
			//acceptCritical:0,
			//rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:0,
			provideUserDetails: 0,
			service: 0,
			taskeditFlag:0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			showNotify: 0,
			showNotifyForm: true,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:0,
			relateCIFlag:0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			isVisible: false,
			prevSelectedAction: "aiInsights",
		});

	}

	showNotify() {

		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0;
		GLOBAL.rejectCritical = 0
		this.setState({
			putOnHold: 0,
			cancelIssue: 0,
			Reopen: 0,
			CIR: 0,
			provideFix: 0,
			aiInsights:0,
			//acceptCritical:0,
			//rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:0,
			provideUserDetails: 0,
			service: 0,
			taskeditFlag:0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			showNotify: 1,
			showNotifyForm: true,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:0,
			relateCIFlag:0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "showNotify",
		});

	}

	showProcessHierarchy() {

		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 1;
		GLOBAL.acceptCritical = 0;
		GLOBAL.rejectCritical = 0
		this.setState({
			putOnHold: 0,
			cancelIssue: 0,
			Reopen: 0,
			CIR: 0,
			provideFix: 0,
			aiInsights:0,
			//acceptCritical:0,
			//rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:1,
			provideUserDetails: 0,
			service: 0,
			offeringId: 0,
			ciId: 0,
			taskeditFlag:0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:0,
			relateCIFlag:0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "processhierarchy",
		});


	}

	showCausalRelation() {

		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0;
		GLOBAL.rejectCritical = 0
		this.setState({
			putOnHold: 0,
			cancelIssue: 0,
			Reopen: 0,
			CIR: 0,
			provideFix: 0,
			aiInsights:0,
			//acceptCritical:0,
			//rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:1,
			provideUserDetails: 0,
			service: 0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			taskeditFlag:0,
			proposeChange: 0,
			showNotify: 0,
			showCausalRelation: 1,
			relatedWorkItemsFlag:0,
			relateCIFlag:0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "showCausalRelation",
		});

	}

	showRelatedWorkItems() {

		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0;
		GLOBAL.rejectCritical = 0
		this.setState({
			putOnHold: 0,
			cancelIssue: 0,
			Reopen: 0,
			CIR: 0,
			provideFix: 0,
			aiInsights:0,
			//acceptCritical:0,
			//rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:1,
			provideUserDetails: 0,
			service: 0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			taskeditFlag:0,
			proposeChange: 0,
			showNotify: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:1,
			relateCIFlag:0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "relatedWorkItemsFlag",
		});

	}

	showRelateCIs() {

		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0;
		GLOBAL.rejectCritical = 0
		this.setState({
			putOnHold: 0,
			cancelIssue: 0,
			Reopen: 0,
			CIR: 0,
			provideFix: 0,
			aiInsights:0,
			//acceptCritical:0,
			//rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:1,
			provideUserDetails: 0,
			service: 0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			taskeditFlag:0,
			proposeChange: 0,
			showNotify: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:0,
			relateCIFlag:1,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "relateCIFlag",
		});

	}

	showSLA() {

		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0;
		GLOBAL.rejectCritical = 0
		this.setState({
			putOnHold: 0,
			cancelIssue: 0,
			provideFix: 0,
			aiInsights:0,
			Reopen: 0,
			CIR: 0,
			//acceptCritical:0,
			//rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:1,
			provideUserDetails: 0,
			service: 0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			taskeditFlag:0,
			SLA: 1,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:0,
			relateCIFlag:0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "slaView",
		});


	}

	showExternalSystemAttributes() {

		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0;
		GLOBAL.rejectCritical = 0;

		if (this.props && this.props.breakFixEditDetails && this.props.breakFixEditDetails.breakfixId) {
			this.props.loadExternalAttributesDetails(this.props.breakFixEditDetails.breakfixId, 'breakfix');
		}
		this.setState({
			putOnHold: 0,
			Reopen: 0,
			CIR: 0,
			cancelIssue: 0,
			provideFix: 0,
			aiInsights:0,
			provideAttachment: 0,
			provideUserDetails: 0,
			service: 0,
			offeringId: 0,
			ciId: 0,
			taskeditFlag:0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:0,
			relateCIFlag:0,
			showExternalSystemAttributesFlag: 1,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "showExternalSystemAttributesFlag",
		});

	}

	acceptCritical() {
		//alert("acceptCritical");
		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 1;
		GLOBAL.acceptmessage = "";
		GLOBAL.rejectCritical = 0
		this.setState({
			urgency: 0,
			CIR: 0,
			//acceptCritical:1,
			//	rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:0,
			provideUserDetails: 0,
			service: 0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			SLA: 0,
			cancelIssue: 0,
			taskeditFlag:0,
			provideFix: 0,
			aiInsights:0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:0,
			relateCIFlag:0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "acceptCritical",
		});

	}

	rejectCritical() {
		//alert("rejectCritical");
		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0;
		GLOBAL.rejectCritical = 1
		GLOBAL.rejectmessage = '';
		this.setState({
			urgency: 0,
			//acceptCritical:0,
			//	rejectCritical:1,
			provideAttachment: 0,
			//processhierarchy:0,
			provideUserDetails: 0,
			service: 0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			CIR: 0,
			SLA: 0,
			taskeditFlag:0,
			cancelIssue: 0,
			provideFix: 0,
			aiInsights:0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:0,
			relateCIFlag:0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "rejectCritical",
		});
	}

	showCriticality() {
		//alert("showCriticality");
		GLOBAL.proposeCritical = 0;
		GLOBAL.criticalitystatusval = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0;
		GLOBAL.rejectCritical = 0
		this.setState({
			urgency: 0,
			CIR: 0,
			Reopen: 0,
			//acceptCritical:0,
			//	rejectCritical:0,
			provideAttachment: 0,
			provideFix: 0,
			aiInsights:0,
			//processhierarchy:0,
			provideUserDetails: 0,
			service: 0,
			offeringId: 0,
			taskeditFlag:0,
			ciId: 0,
			showCritical: 1,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:0,
			relateCIFlag:0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "showCritical",
		});

	}

	showTasks() {
		GLOBAL.proposeCritical = 0;
		GLOBAL.criticalitystatusval = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0;
		GLOBAL.rejectCritical = 0
		this.setState({
			putOnHold: 0,
			cancelIssue: 0,
			urgency: 0,
			Reopen: 0,
			CIR: 0,
			provideFix: 0,
			aiInsights:0,
			//acceptCritical:0,
			//	rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:0,
			provideUserDetails: 0,
			service: 0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:0,
			relateCIFlag:0,
			taskeditFlag:1,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "taskeditFlag",
		});
	}

	setShowCopyServiceForm(value) {
		GLOBAL.proposeCritical = 0;
		GLOBAL.criticalitystatusval = 0;
		GLOBAL.processhierarchy = !value;
		GLOBAL.acceptCritical = 0;
		GLOBAL.rejectCritical = 0
		this.setState({
			putOnHold: 0,
			cancelIssue: 0,
			urgency: 0,
			Reopen: 0,
			CIR: 0,
			provideFix: 0,
			aiInsights:0,
			//acceptCritical:0,
			//	rejectCritical:0,
			provideAttachment: 0,
			taskeditFlag:0,
			//processhierarchy:0,
			provideUserDetails: 0,
			service: 0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:0,
			relateCIFlag:0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: value,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "showCopyServiceForm",
		});
	}

	showKnowledgeArticles() {

		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0;
		GLOBAL.rejectCritical = 0;

		this.setState({
			putOnHold: 0,
			Reopen: 0,
			CIR: 0,
			cancelIssue: 0,
			provideFix: 0,
			aiInsights:0,
			provideAttachment: 0,
			provideUserDetails: 0,
			service: 0,
			offeringId: 0,
			taskeditFlag:0,
			ciId: 0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:0,
			relateCIFlag:0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 1,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "showKnowledgeArticleFlag",
		});

	}

	showWatcher(){
		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0
		GLOBAL.rejectCritical = 0
		this.setState({
			putOnHold: 0,
			cancelIssue: 0,
			Reopen: 0,
			CIR: 0,
			provideFix: 0,
			aiInsights:0,
			//acceptCritical:0,
			//	rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:1,
			provideUserDetails: 0,
			taskeditFlag:0,
			service: 0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:0,
			relateCIFlag:0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:1,
			showTags:0,
			showAvailableIndividual:0,
			prevSelectedAction: "showWatcher",
		});
	}

	showAvailableIndividual(){
		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0
		GLOBAL.rejectCritical = 0
		this.setState({
			putOnHold: 0,
			cancelIssue: 0,
			Reopen: 0,
			CIR: 0,
			provideFix: 0,
			aiInsights:0,
			//acceptCritical:0,
			//	rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:1,
			provideUserDetails: 0,
			taskeditFlag:0,
			service: 0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:0,
			relateCIFlag:0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:1,
			prevSelectedAction: "showAvailableIndividual",
		});
	}

	showTags(){
		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0
		GLOBAL.rejectCritical = 0
		this.setState({
			putOnHold: 0,
			cancelIssue: 0,
			Reopen: 0,
			CIR: 0,
			provideFix: 0,
			aiInsights:0,
			//acceptCritical:0,
			//	rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:1,
			provideUserDetails: 0,
			taskeditFlag:0,
			service: 0,
			offeringId: 0,
			ciId: 0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showCausalRelation: 0,
			relatedWorkItemsFlag:0,
			relateCIFlag:0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:1,
			showAvailableIndividual:0,
			prevSelectedAction: "showTags",
		});
	}

	ChangeproposeCriticalTabStatus(e) {
		this.setState({ proposeCriticalTabStatus: e })
	}

	roleIdentification(Rolevalue) {
		let flag = false
		let roles = this.state.role_id.split(',')
		for (let i = 0; i < roles.length; i++) {
			if (roles[i] == Rolevalue) {
				flag = true
				break
			}
		}
		if (flag)
			return true
		else
			return false
	}

	groupIdentification(groupValue){
		let flag=false;
		let groups=this.state.groupID.split(',');
		for(let i=0;i<groups.length;i++){
			if(groups[i]===groupValue){
              flag=true;
			  break;
			}
		}
		if(flag)
		   return true;
		else
		   return false;
	}
	showCiDetails(ciId) {
		GLOBAL.proposeCritical = 0;
		GLOBAL.processhierarchy = 0;
		GLOBAL.acceptCritical = 0;
		GLOBAL.rejectCritical = 0
		this.setState({
			service: 0,
			ciId: ciId,
			offeringId: 0,
			provideFix: 0,
			aiInsights:0,
			putOnHold: 0,
			Reopen: 0,
			CIR: 0,
			cancelIssue: 0,
			//acceptCritical:0,
			//rejectCritical:0,
			provideAttachment: 0,
			//processhierarchy:0,
			provideUserDetails: 0,
			showCritical: 0,
			SLA: 0,
			proposeInvestigation: 0,
			proposeChange: 0,
			showNotify: 0,
			showExternalSystemAttributesFlag: 0,
			showCopyServiceForm: 0,
			showKnowledgeArticleFlag: 0,
			showWatcher:0,
			showTags:0,
			showAvailableIndividual:0
		});
	}

	setUnchangedData(obj){
		function checkEmptyValue(val){
			if(!val || val == "" || val == "0" || val == "null"){
				return "";
			}
			return val;
		}

		try{

			// console.log('obj - ', obj);
			let tempObj = {};

			// tempObj['company'] = obj['consumerCompany'];
			tempObj['companyName'] = checkEmptyValue(obj['consumerCompany']);
			// tempObj['ciId'] = obj['ciId'];
			tempObj['ciName'] = checkEmptyValue(obj['ciId']);
			tempObj['impactedServiceName'] = checkEmptyValue(obj['serviceId']);
			tempObj['consumer'] = checkEmptyValue(obj['impactedUserid']);
			// tempObj['status'] = obj['status'];
			tempObj['issueDescription'] = checkEmptyValue(obj['description']);
			tempObj['additionalInfo'] = checkEmptyValue(obj['additionalInfo']);
			// tempObj['reportedOn'] = obj['reportedOn'];
			tempObj['reportedThrough'] = checkEmptyValue(obj['reportedThrough']);
			tempObj['supportPGroup'] = checkEmptyValue(obj['spGroupid']);
			tempObj['supportPIndividual'] = checkEmptyValue(obj['spUserid']);
			tempObj['criticality'] = checkEmptyValue(obj['serviceCriticality']);
			tempObj['urgencyMode'] = checkEmptyValue(obj['urgency']);
			// tempObj['priority'] = obj['impactedServiceName'];

			Object.values(opsCategoryKeysMap).reduce((acc, key) => {
				tempObj[key] = checkEmptyValue(obj[key]);
			  }, {})
			this.setState({unchangedData: tempObj});
		}catch(e){
			console.error(e);
		}
	}

	checkDataChanged(obj){
		function checkEmptyValue(val){
				if(!val || val == "" || val == "0" || val == "null"){
					return "";
				}
				return val;
			}
			function isObject(obj) {
				return (Object.prototype.toString.call(obj) === '[object Object]');
			}

			function compareObject(obj1, obj2){
				if(!isObject(obj1) || !isObject(obj2)){
					return false;
				}

				let len = null;
				//check if they're of thesame length
				if(Object.keys(obj1).length != Object.keys(obj2).length){
					return false;
				}else{
					len = Object.keys(obj1).length; //use any length
				}

				let match = 0; //store number of matched properties
				Object.keys(obj1).forEach(i => {
					//check if values with the same keys are equal
					if(obj1[i] == obj2[i]){
						match++; //increment the variable
					}
				})
				//check if object length equals the number of matched properties
				if(match === len){
					return true;
				}

				return false;
			}

		try{
			const { unchangedData } = this.state;
			let tempObj = {};

			tempObj['companyName'] = checkEmptyValue(obj['consumerCompany']);
			tempObj['ciName'] = checkEmptyValue(obj['ciId']);
			tempObj['impactedServiceName'] = checkEmptyValue(obj['serviceId']);
			tempObj['consumer'] = checkEmptyValue(obj['impactedUserid']);
			tempObj['issueDescription'] = checkEmptyValue(obj['description']);
			tempObj['additionalInfo'] = checkEmptyValue(obj['additionalInfo']);
			tempObj['reportedThrough'] = checkEmptyValue(obj['reportedThrough']);
			tempObj['supportPGroup'] = checkEmptyValue(obj['spGroupid']);
			tempObj['supportPIndividual'] = checkEmptyValue(obj['spUserid']);
			tempObj['criticality'] = checkEmptyValue(obj['criticality']);
			tempObj['urgencyMode'] = checkEmptyValue(obj['urgency']);
			// console.log('Data - ', {unchangedData, tempObj});

			Object.values(opsCategoryKeysMap).reduce((acc, key) => {
				tempObj[key] = checkEmptyValue(obj[key]);
			}, {})
			const isEqual = compareObject(unchangedData, tempObj);
			// console.log('isEqual - ', isEqual);
			return !isEqual;
		}catch(e){
			console.error(e);
		}
	}

	clearUserDetails() {
		this.setState({provideUserDetails: 0, userDetailsLabel: ''}, () => {
			this.showProcessHierarchy();
		});
	}

	setFormAPITriggered(val){
		this.setState({isFormAPIsTriggered: val});
	}

	setIncidentDetails(type = "", val = ""){
		switch(type){
			case 'consumer':
				this.setState({consumerValue: val});
				break;
			case 'individual':
				this.setState({supportIndividualValue: val});
				break;
		}
	}
	setUrgencyError(value){
		this.setState({breakfixUrgencyError: value});
	}
	setImpactError(value){
		this.setState({breakfixImpactError: value});
	}
	roleCalculatorForGuestUser(rolesList){
		let roles = rolesList.split(",");
		let isChangedRolesEnabled = roles.includes('19') || roles.includes('20') || roles.includes('51') || roles.includes('52');
		let isProblemRolesEnabled = roles.includes('16') || roles.includes('17') || roles.includes('53');
		let isIncidentRolesEnabled = roles.includes('12') || roles.includes('13') || roles.includes('14');
		let isFulfillmentRolesEnabled = roles.includes('43') || roles.includes('44');
		let isServiceDeskRolesEnabled = roles.includes('48');
		let isD2CRolesEnabled = isChangedRolesEnabled || isProblemRolesEnabled || isIncidentRolesEnabled || isFulfillmentRolesEnabled;
		const isD2cViewerRoleEnabled = roles.includes('65') || roles.includes('64') || roles.includes('63') || roles.includes('62');
		let isGuestUserRoleEnabled = !(isIncidentRolesEnabled || isServiceDeskRolesEnabled) && roles.includes('63');
		this.setState({disableFieldsForGuestRole: isGuestUserRoleEnabled});
		isChangedRolesEnabled=isChangedRolesEnabled|| isServiceDeskRolesEnabled|| roles.includes('65');
		isProblemRolesEnabled=isProblemRolesEnabled|| isServiceDeskRolesEnabled|| roles.includes('64');
		isIncidentRolesEnabled=isIncidentRolesEnabled || isServiceDeskRolesEnabled|| roles.includes('63');
		isFulfillmentRolesEnabled=isFulfillmentRolesEnabled|| isServiceDeskRolesEnabled|| roles.includes('62');
		let isTaskRoleEnabled = isD2CRolesEnabled || isServiceDeskRolesEnabled || roles.includes('61') || isD2cViewerRoleEnabled;
		let isCMDBRoleEnabled = roles.includes('37') || roles.includes('38') || roles.includes('39');
		this.setState({isChangedRolesEnabled, isProblemRolesEnabled, isIncidentRolesEnabled, isFulfillmentRolesEnabled, isTaskRoleEnabled, isCMDBRoleEnabled, isD2CRolesEnabled});
	}

	async exportDocument(type = ''){
		try{
			if(!type){
				throw new Error('Error in exporting document');
			}
			const { breakFixSourceName = [] } = this.props;
			let modeOfReporting = '';
			if(breakFixSourceName && Array.isArray(breakFixSourceName) && breakFixSourceName.length > 0){
				let find = (breakFixSourceName).find(item => item['field1Value'] == this.props?.breakFixEditDetails?.reportedThrough);
				if(find){
					modeOfReporting = find['field1Key'] || '';
				}
			}
			let date_format = this.state.date_format;
			let obj = {...this.props.breakFixEditDetails, ...{ modeOfReporting, date_format }};
			exportDoc(type, obj);
		}catch(e){
			console.error(e);
		}
	}
	resetRightSide(){
		GLOBAL.processhierarchy = 1;
					GLOBAL.proposeCritical = 0;
					GLOBAL.acceptCritical = 0;
					GLOBAL.rejectCritical = 0;
					GLOBAL.criticalitystatusval = 0;
					this.setState({
						putOnHold: 0,
						cancelIssue: 0,
						provideFix: 0,
						aiInsights:0,
						Reopen: 0,
						CIR: 0,
						//acceptCritical:0,
						//rejectCritical:0,
						provideAttachment: 0,
						//processhierarchy:0,
						provideUserDetails: 0,
						service: 0,
						taskeditFlag:0,
						offeringId: 0,
						ciId: 0,
						showCritical: 0,
						SLA: 0,
						proposeInvestigation:0,
						proposeChange: 0,
						showNotify: 0,
						showCausalRelation: 0,
						relatedWorkItemsFlag:0,
						relateCIFlag:0,
						showExternalSystemAttributesFlag: 0,
						showCopyServiceForm: 0,
						showKnowledgeArticleFlag: 0,
						showWatcher:0,
						showTags:0,
						showAvailableIndividual:0,
						isVisible:true,
					});
	}

	render() {
		const payload = this.createFCRPayload();

		let x = 0
		if (GLOBAL.proposeCritical == 0 && this.state.showCritical == 0)
			x = 0
		else if (GLOBAL.proposeCritical == 0 && this.state.showCritical == 1)
			x = 1
		else if (GLOBAL.proposeCritical == 1 && this.state.showCritical == 0)
			x = 2
		else
			x = 4


		if (this.props.isFetchingDetails.isFetching) {
			return (
				<div className='rctLoader'>
					<LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
				</div>
			);
		} else {
			if (GLOBAL.proposeproblemfromimpacted == 0) {
				GLOBAL.ciId = this.props.breakFixEditDetails.ciId;
				console.log("rctLoaderrctLoader", this.props.breakFixEditDetails.ciId);
				console.log("this.props.breakFixEditDetails.ciName", this.props.breakFixEditDetails.ciName);
				GLOBAL.ciName = this.props.breakFixEditDetails.ciName;
			}

		}

		if (GLOBAL.criticalitystatusval == "1")
			this.state.showCritical = 0

		const { impactedUserid, spUserid, serviceCriticality, createdById, description, createdOn, priorityId, cause, reportedThrough, reasonCode, urgency, ciId, serviceId, consumerCompany, spGroupid, serviceBased, ciLocationId, requesterLocationId } = this.props.breakFixEditDetails;
		const payloadDSAT = {
			impactedUserid: impactedUserid,
			spGroupid: spUserid,
			serviceCriticality: serviceCriticality,
			createdBy: createdById,
			description: description,
			createdOn: createdOn,
			priorityId: priorityId,
			cause: cause,
			reportedThrough: reportedThrough,
			reasonCode: reasonCode,
			urgency: urgency,
			ciId: ciId,
			serviceId: serviceId,
			companyId: consumerCompany
		};

		const reOpenPayload = {
			consumerCompany: consumerCompany,
			serviceCriticality: serviceCriticality,
			priorityId: priorityId,
			ciId: ciId,
			serviceId: serviceId,
			spGroupid: spGroupid,
			spUserid: spUserid,
			impactedUserid: impactedUserid,
			description: description,
			companyId: consumerCompany,
			serviceBased: serviceBased,
			ciLocationId: ciLocationId,
			requesterLocationId: requesterLocationId
		  };

		return (
			<div>
				{this.state.role_id!==''?<WIBSearch tr={this.props.tr} roleIdentification={this.roleIdentification} group_id={this.state.groupID} changeImplentationRole={this.state.changeImplentationRole}/>:null}

				<div className="container-fluid margin-t-10 margin-b-15">
					<Breadcrumbs activePageName="Edit" parentPageurl="/quickViewBreakFix" parentPageName="Work Item Board" />
				</div>

				<AiInsightsBar
				 aiInsightsShowFun={this.aiInsightsShowFun}
				 isVisible={this.state.isVisible}
				 payload={payload}
				 aiInsightFCREnabled={this.state.aiInsightFCREnabled}
				 rightEditPanel={this.rightEditPanel}
				 aismDSAT = {this.state.aiaismIncidentRateEnabled}
				 isReopen={this.state.isReopen}
				 />

				<div className='container-fluid minHeightWIB'>
					<Form>
						<SupportHeader criticalitystatusval={GLOBAL.criticalitystatusval} status={this.props.breakFixEditDetails.status} priority={this.props.breakFixEditDetails.priorityValue} criticality={this.props.breakFixEditDetails.criticalityStatus}
							showCriticality={this.showCriticality} showProcessHierarchy={this.showProcessHierarchy}
							showSLA={this.showSLA} showExternalSystemAttributes={this.showExternalSystemAttributes} acceptCritical={this.acceptCritical} rejectCritical={this.rejectCritical}
							showProposeCritical={this.showProposeCritical} showProvideAttachment={this.showProvideAttachment}
							breakFixEditDetails={this.props.breakFixEditDetails} showNotify={this.showNotify}
							showProposeInvestigation={this.showProposeInvestigation} showProposeChange={this.showProposeChange}
							breakFixSLAResolution={this.props.breakFixSLAResolutionLogDetails.slaStatus} showCausalRelation={this.showCausalRelation}
							breakFixSLAResponse={this.props.breakFixSLAResponseLogDetails.slaStatus} translator={this.props.tr} user_id={this.state.user_id} role_id={this.state.role_id} date_format={this.state.date_format} showTimeline={this.showTimeline} showCancelIssue={this.showCancelIssue} showOnHold={this.showOnHold} showProvideFix={this.showProvideFix} roleIdentification={this.roleIdentification} showCIRform={this.showCIRform} showReasonToReopen={this.showReasonToReopen}
							setShowCopyServiceForm={this.setShowCopyServiceForm}
							showCopyServiceForm={this.state.showCopyServiceForm}
							showKnowledgeArticles={this.showKnowledgeArticles}
							setErrorServiceColor={this.setErrorServiceColor}
							setErrorImpactedColor={this.setErrorImpactedColor}
							setConsumerColor={this.setConsumerColor}
							setGroupReasonErrorColor={this.setGroupReasonErrorColor}
							setPriorityCallFlag={this.setPriorityCallFlag}
							isPriorityCallInProgress={this.state.isPriorityCallInProgress}
						    showTasks={this.showTasks}
							showRelatedWorkItems={this.showRelatedWorkItems}
							showRelateCIs={this.showRelateCIs}
							breakFixEditDetailsUpdate={this.props.breakFixEditDetails}
							checkDataChanged={this.checkDataChanged}
							clearUserDetails={this.clearUserDetails}
							showWatcher = {this.showWatcher}
							showTags = {this.showTags}
							setUrgencyError={this.setUrgencyError}
							setImpactError={this.setImpactError}
							rightEditPanel={this.rightEditPanel}
							disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
							RefreshScreen={this.RefreshScreen}
							exportDocument={this.exportDocument}
							processhierarchy= {GLOBAL.processhierarchy}
							isShowTags = {this.state.showTags}
							showKnowledgeArticleFlag= {this.state.showKnowledgeArticleFlag}
							provideAttachment={this.state.provideAttachment}
							taskeditFlag={this.state.taskeditFlag}
							isShowWatcher = {this.state.showWatcher}
							SLA = {this.state.SLA}
							isVisible={this.state.isVisible} 
						    setState={this.setState.bind(this)}
							resetRightSide={this.resetRightSide}
						/>

						<PanelGroup direction="horizontal">
							<Panel id="sidebar" minSize={33} order={1} defaultSize={this.state.isRightEditPanel ? 67 : 100} className={this.state.isRightEditPanel ? "isShowLeftPanel" : ""}>
								<EditForm
									consumerValue={this.state.consumerValue}
									supportIndividualValue={this.state.supportIndividualValue}
									setIncidentDetails={this.setIncidentDetails}
									setFormAPITriggered={this.setFormAPITriggered}
									isFormAPIsTriggered={this.state.isFormAPIsTriggered}
									// testID={this.state.testID}
									showOnHold={this.showOnHold}
									showCancelIssue={this.showCancelIssue}
									setBreakFixSGAEName={this.setBreakFixSGAEName}
									showProvideFix={this.showProvideFix}
									// breakFixEditDetails={this.props.breakFixEditDetails}
									breakFixEditDetailss={this.state.breakFixEditDetailss}
									showConsumerDetails={this.showConsumerDetails}
									showServiceDetails={this.showServiceDetails}
									breakFixItemId={this.params.breakFixItemId}
									provideFixStatus={this.state.provideFixStatus}
									changeProvideFixStatus={this.changeProvideFixStatus}
									showProcessHierarchy={this.showProcessHierarchy}
									cancelIssue={this.state.cancelIssue}
									putOnHold={this.state.putOnHold}
									translator={this.props.tr}
									supportPIndividualValueChange={this.supportPIndividualValueChange}
									user_id={this.state.user_id} role_id={this.state.role_id} date_format={this.state.date_format}
									showTimeline={this.showTimeline}
									showTimelineFlag={this.state.showTimelineFlag}
									roleIdentification={this.roleIdentification}
									setErrorServiceColor={this.setErrorServiceColor}
									setErrorImpactedColor={this.setErrorImpactedColor}
									setConsumerColor={this.setConsumerColor}
									consumerColor={this.state.consumerColor}
									grpReasonErrorColor={this.state.grpReasonErrorColor}
									setGroupReasonErrorColor={this.setGroupReasonErrorColor}
									serviceErrorColor={this.state.serviceErrorColor}
									ImpactedErrorColor={this.state.ImpactedErrorColor}
									setPriorityCallFlag={this.setPriorityCallFlag}
									isPriorityCallInProgress={this.state.isPriorityCallInProgress}
									saveConsumerState={this.saveConsumerState}
									showCiDetails={this.showCiDetails}
									setUnchangedData={this.setUnchangedData}
									groupIdentification={this.groupIdentification}
									setUrgencyImpact={this.setUrgencyImpact}
									setUrgencyError={this.setUrgencyError}
									setImpactError={this.setImpactError}
									breakfixUrgencyError={this.state.breakfixUrgencyError}
									breakfixImpactError={this.state.breakfixImpactError}
									rightEditPanel={this.rightEditPanel}
									disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
									selectedAssignmentGroup={this.state.selectedAssignmentGroup}
									selectedAssignmentUser={this.state.selectedAssignmentUser}
									setAssignmentGrpUsr={this.setAssignmentGrpUsr}
									showAvailableIndividual={this.showAvailableIndividual}
									resetRightSide={this.resetRightSide}
									aiSummaruzationEnabled={this.state.aiSummaruzationEnabled}
								/>
							</Panel>
							{this.state.isRightEditPanel ?
								<>
								<PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
								<Panel minSize={33} order={2} defaultSize={33}>
									<div className="stickyArea rBoxStyle">
										{this.state.provideFix == 0 ? <Empty /> : <ProvideFix setErrorImpactedColor={this.setErrorImpactedColor} ImpactedErrorColor={this.state.ImpactedErrorColor} showProcessHierarchy={this.showProcessHierarchy} changeProvideFixStatus={this.changeProvideFixStatus} breakFixEditDetails={this.props.breakFixEditDetails} supportPIndividualValue={this.state.supportPIndividualValue} translator={this.props.tr} role_id={this.state.role_id} user_id={this.state.user_id} roleIdentification={this.roleIdentification} rightEditPanel={this.rightEditPanel} changeRightView={this.changeRightView} />}
										{x != 2 ? <Empty /> : <ProposeCritical showProcessHierarchy={this.showProcessHierarchy} proposemsg={GLOBAL.proposemessage} offeringId={this.props.breakFixEditDetails.serviceId} breakFixItemId={this.params.breakFixItemId} translator={this.props.tr} ChangeproposeCriticalTabStatus={this.ChangeproposeCriticalTabStatus} roleIdentification={this.roleIdentification} role_id={this.state.role_id} user_id={this.state.user_id} breakFixEditDetails={this.props.breakFixEditDetails} rightEditPanel={this.rightEditPanel} changeRightView={this.changeRightView} />}
										{x != 1 && x != 4 ? <Empty /> : <ShowCritical showProcessHierarchy={this.showProcessHierarchy} criticalitystatusvalue={GLOBAL.commonpriorityval} offeringId={this.props.breakFixEditDetails.serviceId} breakFixItemId={this.params.breakFixItemId} translator={this.props.tr} proposeCriticalTabStatus={this.state.proposeCriticalTabStatus} roleIdentification={this.roleIdentification} role_id={this.state.role_id} user_id={this.state.user_id} breakFixEditDetails={this.props.breakFixEditDetails} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} changeRightView={this.changeRightView}/>}
										{this.state.provideAttachment == 0 ? <Empty /> : <Attachment itemDetails={this.props.breakFixEditDetails} translator={this.props.tr} user_id={this.state.user_id} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} changeRightView={this.changeRightView}/>}
										{this.state.provideUserDetails == 0 ? <Empty /> : <UserDetails userId={this.state.provideUserDetails} userDetailsLabel={this.state.userDetailsLabel} translator={this.props.tr} rightEditPanel={this.rightEditPanel} changeRightView={this.changeRightView}/>}
										{this.state.showNotify == 0 ? <Empty /> : <NotifyTab breakfixD2cNumber={this.props.breakfixD2cNumber} breakFixEditDetails={this.props.breakFixEditDetails} translator={this.props.tr} full_name={this.state.full_name} roleIdentification={this.roleIdentification}
											showNotifyForm={this.state.showNotifyForm}
											setShowNotifyForm={(value) => {
												this.setState({ showNotifyForm: value })
											}}
											rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} changeRightView={this.changeRightView}/>}
										{this.state.service == 0 ? <Empty /> : <ServiceDetails offeringId={this.state.offeringId} translator={this.props.tr} rightEditPanel={this.rightEditPanel} changeRightView={this.changeRightView} />}
										{GLOBAL.acceptCritical == 0 ? <Empty /> : <AcceptCritical showProcessHierarchy={this.showProcessHierarchy} acceptmsg={GLOBAL.acceptmessage} breakFixItemId={this.params.breakFixItemId} translator={this.props.tr} role_id={this.state.role_id} user_id={this.state.user_id} user_name={this.state.user_name} full_name={this.state.full_name} roleIdentification={this.roleIdentification} breakFixEditDetails={this.props.breakFixEditDetails} rightEditPanel={this.rightEditPanel} changeRightView={this.changeRightView} />}
										{GLOBAL.rejectCritical == 0 ? <Empty /> : <RejectCritical showProcessHierarchy={this.showProcessHierarchy} rejectmsg={GLOBAL.rejectmessage} breakFixItemId={this.params.breakFixItemId} translator={this.props.tr} role_id={this.state.role_id} user_id={this.state.user_id} user_name={this.state.user_name} full_name={this.state.full_name} roleIdentification={this.roleIdentification} breakFixEditDetails={this.props.breakFixEditDetails} rightEditPanel={this.rightEditPanel} changeRightView={this.changeRightView} />}
										{GLOBAL.processhierarchy == 0 ? <Empty /> : <TabPage date_format={this.state.date_format} translator={this.props.tr} breakFixItemId={this.params.breakFixItemId} breakFixSGAEName={this.state.breakFixSGAEName} breakFixEditDetails={this.props.breakFixEditDetails} breakFixEditDetailsUpdate={this.props.breakFixDetailsLoadSatatus} fieldStatus={this.props.fieldStatus}  urgencyListKeyValue={this.state.urgencyListKeyValue} impactListKeyValue={this.state.impactListKeyValue} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} changeRightView={this.changeRightView} aiSummaruzationEnabled={this.state.aiSummaruzationEnabled} />}
										{this.state.SLA == 0 ? <Empty /> : <SLA translator={this.props.tr} breakFixEditDetails={this.props.breakFixEditDetails} module="12" breakFixSLAResolution={this.props.breakFixSLAResolutionLogDetails.slaStatus} breakFixSLAResponse={this.props.breakFixSLAResponseLogDetails.slaStatus}  rightEditPanel={this.rightEditPanel} role_id={this.state.role_id} changeRightView={this.changeRightView}/>}
										{this.state.proposeInvestigation == 0 ? <Empty /> : <CreateProblem setErrorImpactedColor={this.setErrorImpactedColor} ImpactedErrorColor={this.state.ImpactedErrorColor} breakFixEditDetails={this.props.breakFixEditDetails} translator={this.props.tr} role_id={this.state.role_id} locationId={this.state.locationId} locationName={this.state.locationName} rightEditPanel={this.rightEditPanel} changeRightView={this.changeRightView} />}
										{this.state.proposeChange == 0 ? <Empty /> : <CreateChange breakFixEditDetails={this.props.breakFixEditDetails} translator={this.props.tr} role_id={this.state.role_id} locationId={this.state.locationId} locationName={this.state.locationName} rightEditPanel={this.rightEditPanel} changeRightView={this.changeRightView} />}


										{this.state.putOnHold == 0 ? <Empty /> : <IncidentReason showProcessHierarchy={this.showProcessHierarchy} putOnHold={this.state.putOnHold} translator={this.props.tr} role_id={this.state.role_id} user_id={this.state.user_id} roleIdentification={this.roleIdentification} breakFixEditDetails={this.props.breakFixEditDetails} rightEditPanel={this.rightEditPanel} changeRightView={this.changeRightView} />}
										{this.state.cancelIssue == 0 ? <Empty /> : <IncidentReason showProcessHierarchy={this.showProcessHierarchy} cancelIssue={this.state.cancelIssue} supportPIndividualValue={this.state.supportPIndividualValue} translator={this.props.tr} role_id={this.state.role_id} user_id={this.state.user_id} roleIdentification={this.roleIdentification} breakFixEditDetails={this.props.breakFixEditDetails} rightEditPanel={this.rightEditPanel} changeRightView={this.changeRightView} />}
										{this.state.Reopen == 0 ? <Empty /> : <IncidentReason showProcessHierarchy={this.showProcessHierarchy} ReOpen={this.state.Reopen} translator={this.props.tr} role_id={this.state.role_id} user_id={this.state.user_id} roleIdentification={this.roleIdentification} rightEditPanel={this.rightEditPanel} changeRightView={this.changeRightView} />}
										{this.state.CIR == 0 ? <Empty /> : <CIR showProcessHierarchy={this.showProcessHierarchy} translator={this.props.tr} role_id={this.state.role_id} user_id={this.state.user_id} roleIdentification={this.roleIdentification} breakFixReportedOn={this.props.breakFixEditDetails.reportedOn} breakFixEditDetails={this.props.breakFixEditDetails} rightEditPanel={this.rightEditPanel} changeRightView={this.changeRightView} />}
										{this.state.showCausalRelation == 0 ? <Empty /> : <CasualRelationship requestId={this.props.breakFixEditDetails.requestId} translator={this.props.tr} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} isChangedRolesEnabled={this.state.isChangedRolesEnabled} isProblemRolesEnabled={this.state.isProblemRolesEnabled} isIncidentRolesEnabled={this.state.isIncidentRolesEnabled} changeRightView={this.changeRightView}/>}

										{this.state.relatedWorkItemsFlag == 0 ? <Empty /> : <PopUpRelatedWork breakFixItemId={this.params.breakFixItemId} fieldStatus={this.props.fieldStatus} breakFixEditDetails={this.props.breakFixEditDetails} translator={this.props.tr} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} isChangedRolesEnabled={this.state.isChangedRolesEnabled} isProblemRolesEnabled={this.state.isProblemRolesEnabled} isIncidentRolesEnabled={this.state.isIncidentRolesEnabled} isFulfillmentRolesEnabled={this.state.isFulfillmentRolesEnabled} full_name={this.state.full_name} user_id={this.state.user_id} changeRightView={this.changeRightView}/>}
										{this.state.relateCIFlag == 0 ? <Empty /> : <PopUpCMDBRelatedWork breakfixNumber={this.props.breakFixEditDetails.breakfixNumber} fieldStatus={this.props.fieldStatus} breakFixReportedOn={this.props.breakFixEditDetails.reportedOn} breakFixEditDetails={this.props.breakFixEditDetails} itemId={this.params.breakFixItemId} editChangeInitialData={this.props.editChangeInitialData} translator={this.props.tr} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} isCMDBRoleEnabled={this.state.isCMDBRoleEnabled} changeRightView={this.changeRightView}/>}

										{this.state.showExternalSystemAttributesFlag == 0 ? <Empty /> : <ExternalSystemAttriburte translator={this.props.tr} rightEditPanel={this.rightEditPanel} changeRightView={this.changeRightView} />}
										{this.state.showCopyServiceForm == 0 ? <Empty /> : <CopyIncident translator={this.props.tr} setShowCopyServiceForm={this.setShowCopyServiceForm} consumerState={this.state.consumerState} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} changeRightView={this.changeRightView}/>}

										{this.state.showKnowledgeArticleFlag == 0 ? <Empty /> : <KnowledgeArticles translator={this.props.tr} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} changeRightView={this.changeRightView}/>}
										{this.state.ciId == 0 ? <Empty /> : <CIDetails ciId={this.state.ciId} translator={this.props.tr} rightEditPanel={this.rightEditPanel} changeRightView={this.changeRightView} />}
										{this.state.taskeditFlag == 0 ? <Empty /> : <PopUpTask clicktaskEdit={this.clicktaskEdit} fieldStatus={this.props.fieldStatus} breakFixItemId={this.params.breakFixItemId} translator={this.props.tr} breakFixEditDetailsUpdate={this.props.breakFixEditDetails} breakFixEditDetails={this.props.breakFixEditDetails} module="Breakfix" rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} isTaskRoleEnabled={this.state.isTaskRoleEnabled} changeRightView={this.changeRightView}/>}
										{this.state.showWatcher == 0 ? <Empty /> : <WatcherDetails   itemId={this.params.breakFixItemId} translator={this.props.tr} itemDetails={this.props.breakFixEditDetails}  module="Breakfix" status={this.props.breakFixEditDetails.status} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} changeRightView={this.changeRightView}/>}
									{this.state.showTags == 0 ? <Empty /> : <TagDetails companyId={this.props.breakFixEditDetails.consumerCompany} itemId={this.params.breakFixItemId} itemDetails={this.props.breakFixEditDetails} module="Incident" businessFunction="" mainModule="BreakFix" rightEditPanel={this.rightEditPanel} userId={this.state.user_id} userName={this.state.full_name} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} showTags={this.showTags} isD2CRolesEnabled={this.state.isD2CRolesEnabled} isTerminalStatus={this.props.breakFixEditDetails.statusId == breakFixStatusMapping['Closed'] || this.props.breakFixEditDetails.statusId == breakFixStatusMapping['Cancelled']} changeRightView={this.changeRightView}/>}
									    {this.state.showAvailableIndividual == 0 ? <Empty /> : <AvailableIndividuals setAssignmentGrpUsr={this.setAssignmentGrpUsr}  selectedAssignmentGroup={this.state.selectedAssignmentGroup} selectedAssignmentUser={this.state.selectedAssignmentUser} companyId={this.props.breakFixEditDetails.consumerCompany} module="Incident" rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}/>}

										{this.state.aiInsights == 0 ? <Empty /> : <AiInsights isReopen={this.state.isReopen} reOpenPayload={reOpenPayload} resetRightSide={this.resetRightSide} payload={payload} aism={this.state.aiInsightFCREnabled} breakFixEditDetails={this.props.breakFixEditDetails} changeRightView={this.changeRightView} aismDSAT={this.state.aiaismIncidentRateEnabled} title="Incident DSAT Score" module="incident" payloadDSAT={payloadDSAT} isVisible={this.state.isVisible} aiInsights={this.state.aiInsights} updateIsVisible={this.updateIsVisible}/>}


									</div>
								</Panel>
								</> : ""
							}
						</PanelGroup>
					</Form>
				</div>
				{/* {homepagelocation == "/unauthorized" || homepagelocation === "/userActivationPending" ? null : <Footer isLogout={GLOBAL.isLogout} />} */}
			</div>
		);


	}
}

EditIncidentIndex = reduxForm({
	form: 'xsmBreakFixEdit'
})(EditIncidentIndex);

export function mapStateToProps({ breakfixD2cNumber, breakFixDetailsLoadSatatus, isFetchingDetails, breakFixEditDetails, breakFixSLAResponseLogDetails, breakFixSLAResolutionLogDetails, spcmReducer, loadHamburgerOptions, fieldStatus, breakFixSourceName, aismProperty, windowSize }) {
	return { breakfixD2cNumber, breakFixDetailsLoadSatatus, isFetchingDetails, breakFixEditDetails, breakFixSLAResponseLogDetails, breakFixSLAResolutionLogDetails, tr: spcmReducer.tr, loadHamburgerOptions, fieldStatus, breakFixSourceName, aismProperty, windowSize: windowSize.width, };
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadBreakFixEditDetailsUpdate, loadBreakFixEditDetails, loadBreakFixSLAResponseLogDetails, loadBreakFixSLAResolutionLogDetails, getTranslation, loadExternalAttributesDetails, loadStatusBasedHamburgerOptions, resetLoadHamburgerOptionsReducer, fetchWatcherDetails, getAdminPreferences }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(EditIncidentIndex);
