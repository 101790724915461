
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import {Form,Row, Col, NavDropdown, Nav } from "react-bootstrap";
import axios from "axios";
import { navigationHooks } from "../../helpers/NavigationHook.js";
import { useDispatch, useSelector } from "react-redux";
import { loadDashboardList } from "../../actions/dashboardConfigration/dashboardConfigAction.js";
import { Scrollbars } from "react-custom-scrollbars";
import ListLoader from '_Commons/loaders/ListLoader';
import { debounce } from "lodash";

import { TbChartHistogram } from "react-icons/tb";
import { VscListFlat } from "react-icons/vsc";
import { BiBarChartAlt2 } from "react-icons/bi";
import { FiPieChart } from "react-icons/fi";
import {IoClose} from "react-icons/io5";
import { HiOutlinePresentationChartBar } from "react-icons/hi2";
import { LuLayoutDashboard } from "react-icons/lu";

import _ from "lodash";
import Cookies from "universal-cookie";
const cookies = new Cookies();
let cookiesData = cookies.get("gph");
if (cookiesData) cookiesData = cookiesData.replace("s:", "");
if (cookiesData)
  cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf("."));
cookiesData = cookiesData.split("~");
let homepageLocation = cookiesData[0];
if (homepageLocation != "/home") homepageLocation = "/" + homepageLocation;

let supersetPath = cookiesData[56];
let userRolesStr = cookiesData[33] ? cookiesData[33] : [];
let associatedCompanyId = cookiesData[57];
let associatedGrpId = cookiesData[58];

const SupersetDashboard = () => {
  const [moduleDetails, setModuleDetails] = useState("");
  const [tokenData, setTokenData] = useState("");
  const [roles, setRoles] = useState([]);
  const [rolesStr, setRolesStr] = useState([]);
  const [dropDownList, setDropDownList] = useState([]);
  const [tokenError, setTokenError] = useState(false);
  const [singleSelections, setSingleSelections] = useState("");

  const translator = useSelector(state => state.spcmReducer.tr);
  const dashboardDataList = useSelector(
    state => state.getDashboardReducerData.dashboardConfigList
  );
  const dispatch = useDispatch();

  const loadHome = () => {
    navigationHooks.navigate(homepageLocation);
  };

  const handleChangeInput = (e) =>{
    setSingleSelections(e.target.value);
  }

  const handleSvgClick = () =>{
    setSingleSelections("");
  }

  useEffect(() => {
    dispatch(loadDashboardList());
    axios.get("/getAllUserRoles", { params: { type: "role" } }).then(res => {
      let rolesData = res.data.map(item => {
        return { value: item.field1Value, label: item.field1Key };
      });
      setRoles(rolesData);
    });
  }, []);

  useEffect(() => {
    if (moduleDetails) {
      let companyIds = associatedCompanyId.split(",");
      let grpIds = associatedGrpId.split(",");
      let companyStr = "";
      companyIds.map((id, index) => {
        if (index === companyIds.length - 1) {
          companyStr += `'${id}'`;
        } else {
          companyStr += `'${id}',`;
        }
      });
      companyStr = companyStr + ",'0'";
      let grpIdStr = "";
      grpIds.map((grp, index) => {
        if (index === grpIds.length - 1) {
          grpIdStr += `'${grp}'`;
        } else {
          grpIdStr += `'${grp}',`;
        }
      })

      let isMasterRole = false;
      if (moduleDetails.masterRoles) {
        moduleDetails.masterRoles.split(",").map(role => {
          if (rolesStr.includes(role)) {
            isMasterRole = true;
          }
        })
      }
      let rlsArr = [];
      let dataset = moduleDetails.compGrpDsId || JSON.stringify([]);
      // console.log('JSON --- 1', moduleDetails.compGrpDsId);
      // console.log('JSON --- 2', JSON.parse(moduleDetails.compGrpDsId));
      JSON.parse(dataset).map(obj => {
        let clause = "";
        if (moduleDetails.reportFilters === "CompanyID and GroupID") {
          if (obj.companyIdAttr) {
            clause = `${obj.companyIdAttr} IN (${companyStr})`;
          }
          if (obj.companyIdAttr && !isMasterRole) {
            clause += ` and ${obj.groupIdAttr} IN (${grpIds.length === 1 && grpIds[0] === "undefined" ? `'0'` : grpIdStr})`;
          }
        } else if (moduleDetails.reportFilters === "CompanyID Only") {
          if (obj.companyIdAttr) {
            clause = `${obj.companyIdAttr} IN (${companyStr})`;
          }
        }
        let rlsObj = {
          clause,
          dataset: obj.dataSetId
        }
        rlsArr.push(rlsObj);
      })
      let data = {
        resources: [
          {
            id: moduleDetails.dashboardResId.toString(),
            type: moduleDetails.widgetType,
          },
        ],
        rls: rlsArr,
        user: {
          first_name: moduleDetails.firstName,
          last_name: moduleDetails.lastName,
          username: moduleDetails.userName,
        },
      };
      const getAccessToken = () => {
        axios
          .post("/getAnalyticsData", data)
          .then(res => {
            setTokenData(res.data.token);
            setTokenError(false);
          }).catch(error => {
            if (error.response.data.message === "EmbeddedDashboard not found.") {
              setTokenError(true);
              let dashboard = document.getElementById("dashboard");
              if (dashboard.hasChildNodes()) {
                dashboard.removeChild(dashboard.firstChild)
              }
            }
          })
      };

      getAccessToken();
      const interval = setInterval(() => {
        getAccessToken();
      }, 1470000);
      return () => clearInterval(interval);
    }
  }, [moduleDetails]);


  // const fetchGuestTokenFromBackend = async module => {
  //   let companyIds = associatedCompanyId.split(",");
  //   let companyStr = "";
  //   companyIds.map((id, index) => {
  //     if (index === companyIds.length - 1) {
  //       companyStr += `'${id}'`;
  //     } else {
  //       companyStr += `'${id}',`;
  //     }
  //   });
  //   let clause = "";
  //   if (module.companyIdAttr) {
  //     clause = `${module.companyIdAttr} IN (${companyStr})`;
  //   }
  //   let data = {
  //     resources: [
  //       { id: module.dashboardResId.toString(), type: module.widgetType },
  //     ],
  //     rls: [{ clause, dataset: module.datasetId }],
  //     user: {
  //       first_name: module.firstName,
  //       last_name: module.lastName,
  //       username: module.userName,
  //     },
  //   };
  //   let resp = await axios.post("/getSuppersetAccessToken", data);
  //   return resp.data.token;
  // };

  useEffect(() => {
    if (tokenData !== "") {
      embedDashboard({
        id: moduleDetails.dashboardUUID,
        supersetDomain: supersetPath,
        mountPoint: document.getElementById("dashboard"),
        fetchGuestToken: () => tokenData,
        dashboardUiConfig: {
          hideTitle: true,
          filters: {
            expanded: true,
            visible: true,
          },
        },
      });
    }
  }, [tokenData])

  useEffect(() => {
    if (dashboardDataList && dashboardDataList.data.length > 0) {
      let userRoles = userRolesStr ? userRolesStr.split(",") : [];
      let rolesArr = [];
      let isAdminOrReportManager = false;
      roles.map(role => {
        if (userRoles.includes(role.value)) {
          rolesArr.push(role.label);
        }
      });
      setRolesStr(rolesArr);
      if (userRoles.includes === "1" || userRoles.includes === "55") {
        isAdminOrReportManager = true;
      }
      let list = _.filter(
        dashboardDataList.data,
        obj =>
          obj.active === true &&
          obj.reportType === "Report" &&
          obj.dashboardId !== null &&
          rolesArr.some(role => {
            return (
              (obj.roles.split(",").includes(role) ||
                obj.masterRoles?.split(",").includes(role)) ||
              isAdminOrReportManager
            );
          })
      );
      // console.log('List ---- ', list)
      setDropDownList(list);
    }
  }, [dashboardDataList, roles]);

  const setModuleName = async eventKey => {
    let module = _.find(dropDownList, {
      label: eventKey,
    });
    setModuleDetails(module);
  };


  const DropList = (singleSelections) => {
    const searchedString=singleSelections.singleSelections.toLowerCase();
    if (dropDownList.length == 0 || dropDownList.length == undefined) {
      return (
        <NavDropdown.Item
          key="noData"
          eventKey="1"
          disabled
          className="tIcextLik hidden-xs hidden-sm hidden-md"
        >
          {translator["No Data Available"]}
        </NavDropdown.Item>
      );
    }
    if(searchedString ==""){
      return dropDownList.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1).map(objectData => {
        if (objectData != "") {
          return (
            <NavDropdown.Item
              key={objectData.id}
              eventKey={objectData.label}
              className="tIcextLik hidden-xs hidden-sm hidden-md"
              onClick={handleSvgClick}
            >
              {objectData.label}
            </NavDropdown.Item>
          );
        } else {
          return <></>;
        }
      });
    }else{
      const filtered = dropDownList.filter(obj => {
        return obj.label.toLowerCase().match(new RegExp(searchedString, 'g'));
      });
      return filtered.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1).map(objectData => {
        if (objectData != "") {
          return (
            <NavDropdown.Item
              key={objectData.id}
              eventKey={objectData.label}
              className="tIcextLik hidden-xs hidden-sm hidden-md"
              onClick={handleSvgClick}
            >
              {objectData.label}
            </NavDropdown.Item>
          );
        } else {
          return <></>;
        }
      });
    }
  };

  const ViewLabels = ({singleSelections}) => {
    let objNamelength=30;
    const searchedString=singleSelections?.toLowerCase();

    const filtered = dropDownList.filter(obj => {
      return obj.label.toLowerCase().match(new RegExp(searchedString, 'g'));
    });

    let newArrayObj = singleSelections=="" ? dropDownList : filtered;

    let reportList = newArrayObj.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1).map(objectData => {
      let objName=objectData.label;
      if (objectData.reportType == "Report" && objectData.active) {
        return (
          <div key={objectData.id} className="repSec"><a title={objName} onClick={() => { setModuleName(objName);handleSvgClick(); }} href={void (0)}>
            <div className="hd"><BiBarChartAlt2 className="list" />{objName.length > objNamelength ? objName.slice(0, objNamelength) : objName}{objName.length > objNamelength ? "..." :null}</div>
          </a></div>
        );
      }
    });
    let dashboardlist = newArrayObj.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1).map(objectData => {
      let objName=objectData.label;
      if (objectData.reportType == "Dashboard" && objectData.active) {
        return (
          <div key={objectData.id} className="repSec"><a title={objName} onClick={() => { setModuleName(objName);handleSvgClick(); }} href={void (0)}>
            <div className="hd"><LuLayoutDashboard className="list" />{objName.length > objNamelength ? objName.slice(0, objNamelength) : objName}{objName.length > objNamelength ? "..." :null}</div>
          </a></div>
        );
      }
    });

    let showDashboardlist = dashboardlist?.every(item => item !==undefined);
    let showReportList = reportList?.every(item => item !==undefined);

    if (dropDownList && dropDownList.length > 0) {
      if (newArrayObj && newArrayObj.length > 0) {
        return <div className="repListDv">
          {showDashboardlist ? <div className="part1 margin-b-40"><div className="hrd">Dashboards</div><div className="flxDv">{dashboardlist}</div></div> : null}
          {showReportList ? <div className="part2"><div className="hrd">Reports</div><div className="flxDv">{reportList}</div></div> : null}
        </div>
      }else{
        return <div className="repListDv text-c clearboth margin-t-10">{translator["There is no data to display"]}</div>
      }
    } else {
      return <div className="container-fluid"><div className="padding-20"><ListLoader /></div></div>;
    }
  }
  
  return (
    <div>
      <div className="minHeightWIB">
        <div className="container-fluid margin-t-10 margin-b-5">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a role="button" onClick={loadHome} title={translator["Home"]}>
                {translator["Home"]}
              </a>
            </li>
            {moduleDetails =="" || moduleDetails.label =="undefined" ? <li className="breadcrumb-item active">{translator["Reports and Dashboards"]}</li>:<>
            <li className="breadcrumb-item">
              <a href="javascript:void(0)" onClick={()=>{setModuleDetails("")}} title={translator["Reports and Dashboards"]}>{translator["Reports and Dashboards"]}</a>
            </li>
            <li className="breadcrumb-item active">{moduleDetails.label}</li>
            </>}
          </ul>
        </div>

        <div className="repoparDv">
          { moduleDetails=="" ?
            <>
              <div className="container-fluid margin-t-15">
              <div style={{top:"-5px"}} className="inputSerDv float-r">{singleSelections !="" ? <IoClose className="viewicn" onClick={handleSvgClick} />:null}<Form.Control value={singleSelections} onChange={(e)=>{handleChangeInput(e)}} type="text" placeholder="Search here" /></div>
                <ViewLabels singleSelections={singleSelections}  />
              </div>
            </> :
            <>
              <div className="container-fluid margin-t-15 margin-b-5 repoparDv" bsClass="">
                <Row className="row-eq-height">
                  <Col md={4} sm={6} xs={12}>
                    <Nav bsPrefix=" " className="display-inline-block" activeKey="1" onSelect={setModuleName}>
                      <NavDropdown
                        title={moduleDetails == "" ? "Select Report" : moduleDetails.label}
                        id="nav-dropdown"
                        className="topNav extLink hidden-xs hidden-sm border border-white text-info nerept"
                      >
                        <div className="inputSerDv">{singleSelections !="" ? <IoClose onClick={handleSvgClick} />:null}<Form.Control value={singleSelections} onChange={handleChangeInput} type="text" placeholder="Search here" /></div>
                        <div className="aTagdv"><DropList singleSelections={singleSelections} className="sPageHeading1" /></div>
                      </NavDropdown>
                    </Nav>
                  </Col>
                </Row>
              </div>
              {tokenData !== "" ? <div id="dashboard">{tokenError==true ? <div className="margin-t-15 margin-b-15">There is no matching data available</div> : null}</div> : <div className="container-fluid"><div className="padding-20"><ListLoader /></div></div>}
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default SupersetDashboard;
