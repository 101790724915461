import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  memo,
  useMemo,
} from "react";
import axios from "axios";
import _ from "lodash";
import {
  DataSource,
  Filter,
  TextFilter,
  NumericFilter,
  BooleanFilter,
  DateFilter,
} from "@progress/kendo-react-data-tools";
import {
  AutoComplete,
  DropDownList,
  MultiSelect,
} from "@progress/kendo-react-dropdowns";
import { DatePicker, DateRangePicker } from "@progress/kendo-react-dateinputs";
import { Input } from "@progress/kendo-react-inputs";

import { replaceVariables } from "../../../common/helper";

const initialFilter = {
  logic: "and",
  filters: [],
};

const headerValues = {
  module: {
    incident: "Breakfix",
    problem: "Investigation",
    CI: "CI",
    CMDB: "CMDB",
  },
  moduleType: {
    fulfillment: "service",
    changemanagement: "change",
    incident: "incident",
  },
  status: "ACTIVE",
  filter: "Status List",
  field1_name: "Metallic",
};

const operatorToValueType = {
  contains: "textbox",
  doesnotcontain: "textbox",
  startswith: "textbox",
  endswith: "textbox",
  eq: "dropdown",
  neq: "dropdown",
  gt: "DateTime",
  lt: "DateTime",
  between: "DateTimeRange",
};

const defaultOperators = {
  string: [
    { text: "Contains", operator: "contains" },
    { text: "Does not contain", operator: "doesnotcontain" },
    { text: "Starts with", operator: "startswith" },
    { text: "Ends with", operator: "endswith" },
    { text: "Is equal to", operator: "eq" },
    { text: "Is not equal to", operator: "neq" },
  ],
  number: [
    { text: "Is equal to", operator: "eq" },
    { text: "Is not equal to", operator: "neq" },
    { text: "Greater than", operator: "gt" },
    { text: "Less than", operator: "lt" },
  ],
  date: [
    { text: "Is equal to", operator: "eq" },
    { text: "Is not equal to", operator: "neq" },
    { text: "Before", operator: "lt" },
    { text: "After", operator: "gt" },
    { text: "Between", operator: "between" },
  ],
  boolean: [
    { text: "Is true", operator: "eq" },
    { text: "Is false", operator: "neq" },
  ],
};

const CustomFilter = memo((props) => {

  const { filter = {}, attributeType, field, context, companyId } = props;
  const [localValue, setLocalValue] = useState(filter?.value || '');
  const [localDdValue, setLocalDdValue] = useState(filter?.value || '');
  const [localDateValue, setLocalDateValue] = useState(null);
  const filterRef = useRef(filter);
  const fetchedFields = useRef(new Set());
  
  const [options, setOptions] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fieldOptions = options?.[field?.name] || [];
  const [currentOperator, setCurrentOperator] = useState(
    field?.operators?.[0]?.operator || "eq"
  );
  const [currentValueType, setCurrentValueType] = useState(
    operatorToValueType[currentOperator] || field?.attributeValueType
  );

  useEffect(() => {
    filterRef.current = filter;
  }, [filter]);

  useEffect(() => {
    if (filter?.value) {
      const dateValue = {
        start: filter.value.start ? new Date(filter.value.start) : null,
        end: filter.value.end ? new Date(filter.value.end) : null
      };
      setLocalDateValue(dateValue);
    } else {
      setLocalDateValue(null);
    }
  }, [filter?.value]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setLocalValue(value);
  };

  const handleInputBlur = () => {
    props.onFilterChange({
      nextFilter: {
        ...filter,
        value: localValue
      }
    });
  };

  const handleDdChange = (e) => {
    const value = e.target.value || "";
    setLocalDdValue(value);
  };

  const handleDdBlur = () => {
    props.onFilterChange({
      nextFilter: {
        ...filter,
        value: localDdValue
      }
    });
  };

  const handleDropChange = (e) => {
    const value = e.target.value?.text || "";
    const updatedFilter = {
      ...filter,
      value,
    };

    props.onFilterChange.call(undefined, {
      nextFilter: updatedFilter,
    });
  };

  const handleDateRangeChange = useCallback((e) => {
    const newValue = e.value ? {
      start: e.value.start instanceof Date ? e.value.start : null,
      end: e.value.end instanceof Date ? e.value.end : null
    } : null;
    
    setLocalDateValue(newValue);
  }, []);

  const handleDateRangeBlur = useCallback(() => {
    if (!localDateValue) {
      props.onFilterChange({
        nextFilter: {
          ...filterRef.current,
          value: null
        }
      });
      return;
    }

    props.onFilterChange({
      nextFilter: {
        ...filterRef.current,
        value: {
          start: localDateValue.start?.toISOString(),
          end: localDateValue.end?.toISOString()
        }
      }
    });
  }, [localDateValue, props.onFilterChange]);

  const fetchOptions = useCallback(
    _.debounce(async (field, query) => {
      if (!field.apiEndPoint) return;
      try {
        setIsLoading(true);

        // Prepare headers
        let headers = {};
        const consumerCompany = context.formValues?.company_id;
        const classId = context.formValues?.class_id;
        const apiAttributes = field?.headers || {};
        if (apiAttributes?.query) {
          const queryArr = apiAttributes.query;
          
          Object.keys(queryArr).forEach((key) => {
            
            if (key === "companyId" || key === "company_id") {
              headers[key] = consumerCompany?.toString();
            } else if (key === "like" && query) {
              headers[key] = query;
            } else if (
              key === "module" ||
              key === "moduleType" ||
              key === "status"
            ) {
              if (headerValues[key]) {
                headers[key] = headerValues[key];
              }
            } else if (key === "filter" || key === "field1_name") {
              if (headerValues[key]) {
                headers[key] = headerValues[key];
              }
            } else if (key === "classId") {
              if (headerValues[key]) {
                headers[key] = classId?.toString();
              }
            }
          });
        }

        // Prepare URL with query params if needed
        let url = field?.apiEndPoint;
        if (apiAttributes?.path) {
          const queryParamsObj = replaceVariables(
            apiAttributes.path,
            context.formValues,
            query
          );
          const queryParams = Object.entries(queryParamsObj)
            .map(
              ([key, value]) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            )
            .join("&");
          url = `${url}?${queryParams}`;
        }
        headers.api = url;

        // Add body and method for POST requests
        if (field?.body && field?.methodType) {
          const { body, methodType } = field;
          if (methodType?.toString()?.toLowerCase() === "post") {
            headers.body = replaceVariables(body, context.formValues);
            headers.method = "POST";
          }
        }

        // Make API call
        const response = await axios.get("/api/rules/getQueryBuilderData", {
          headers: { query: JSON.stringify(headers) },
        });

        // Process response data
        const bindingAttr = JSON.parse(field?.bindingAttributes || "{}");
        if (bindingAttr.label && bindingAttr.id) {
          const optionsData = response.data.map((item) => {
              // Handle multi-field labels
            if (bindingAttr.label.includes("|")) {
              const labelArr = bindingAttr.label.split("|");
              const modifiedLabel = labelArr.map((key) => item[key]).join("|");

              return {
                text: modifiedLabel,
                id: item[bindingAttr.id]?.toString(),
              };
            }
            return {
              text: item[bindingAttr?.label] || "Unknown",
              id:
                item[bindingAttr?.id]?.toString() ||
                item["id"]?.toString() ||
                "N/A",
            };
          });

          setOptions((prev) => ({
            ...prev,
            [field?.name]: optionsData,
          }));
        } else {
          setOptions((prev) => ({
            ...prev,
            [field?.name]: [],
          }));
        }
      } catch (error) {
        console.error(
          `Error fetching options for field ${field?.name}:`,
          error
        );
        setOptions((prev) => ({
          ...prev,
          [field?.name]: [],
        }));
      } finally {
        setIsLoading(false);
      }
    }, 300),
    [context]
  );

  useEffect(() => {
    if (
      (field.attributeValueType === "dropdown" ||
        field.attributeValueType === "dropdown+typeahead" ||
        field.attributeValueType === "typeahead") &&
      !fetchedFields.current.has(field.name)
    ) {
      fetchedFields.current.add(field.name);
      fetchOptions(field, "");
    }
  }, [field.name, field.attributeValueType]);

  switch (field?.attributeValueType) {
    case "dropdown":
      return (
        <DropDownList
          data={fieldOptions}
          value={fieldOptions.find((opt) => opt.text === filter?.value)}
          onChange={handleDropChange}
          textField="text"
          dataItemKey="id"
        />
      );
    case "dropdown+typeahead":
    case "typeahead":
      return (
        <AutoComplete
          data={fieldOptions}
          placeholder="Search..."
          value={localDdValue}
          onChange={handleDdChange}
          onBlur={handleDdBlur}
          loading={isLoading}
          textField="text"
          dataItemKey="id"
        />
      );
    // case "textbox":
    // case "Textbox":
    //    {
    //     const debouncedUpdate = useRef(
    //       _.debounce((value, filter, onFilterChange) => {
    //         onFilterChange({
    //           nextFilter: {
    //             ...filter,
    //             value: value
    //           }
    //         });
    //       }, 300)
    //     ).current;
      
    //     return (
    //       <Input 
    //         value={localValue}
    //         onChange={(e) => {
    //           const value = e.target.value;
    //           setLocalValue(value);
    //           debouncedUpdate(value, filter, props.onFilterChange);
    //         }}
    //         className="k-textbox"
    //         placeholder={`Filter ${field?.label || field?.name}...`}
    //       />
    //     );
    //   }
    // case "DateTime":
    //   switch (filter?.operator) {
    //     case "between":
    //       return (
    //         <DateRangePicker
    //           onChange={handleDateRangeChange}
    //           onBlur={handleDateRangeBlur}
    //           label=""
    //           value={localDateValue}
    //           clearButton={true}
    //           format="dd/MM/yyyy"
    //           popupSettings={{
    //             appendTo: document.body
    //           }}
    //         />
    //       );
    //     default:
    //       return (
    //         <DatePicker 
    //           onChange={(e) => handleDateRangeChange({ 
    //             value: { 
    //               start: e.value, 
    //               end: e.value 
    //             } 
    //           })}
    //           onBlur={handleDateRangeBlur}
    //           value={localDateValue?.start || null}
    //           format="dd/MM/yyyy"
    //           popupSettings={{
    //             appendTo: document.body
    //           }}
    //         />
    //       );
    //   }
    default:
      return <Input onChange={handleInputChange} value={filter?.value} />;
  }
}, (prevProps, nextProps) => {
  return prevProps.filter?.value === nextProps.filter?.value &&
         prevProps.field === nextProps.field &&
         prevProps.onFilterChange === nextProps.onFilterChange;
});

const usePrepareFields11 = (fields, options, filter, context,companyId) => {
  return useMemo(() => {
    return fields?.map((field) => ({
      name: field?.name,
      label: field?.label,
      filter: memo((props) => (
        <CustomFilter
          {...props}
          options={options}
          fields={fields}
          context={context}
          field={field}
          companyId={companyId}
        />
      )),
      operators: field?.operators || defaultOperators[field?.type] || [],
    }));
  }, [fields,context,companyId]);
};

const usePrepareFields = (fields, options, filter, context, companyId) => {
  return useMemo(() => {
    return fields?.map((field) => ({
      name: field?.name,
      label: field?.label,
      filter: (field.attributeValueType?.toLowerCase() === "textbox") ? 
        TextFilter :
        (field.attributeValueType === "DateTime") ?
        (props) => {
          if (props.filter?.operator === 'between') {
            // Handle DateRangePicker for 'between' operator
            const handleDateRangeChange = (event) => {
              const value = event.value ? {
                start: event.value.start?.toISOString(),
                end: event.value.end?.toISOString()
              } : null;
              
              props.onFilterChange({
                nextFilter: {
                  ...props.filter,
                  value
                }
              });
            };

            const value = props.filter?.value ? {
              start: props.filter.value.start ? new Date(props.filter.value.start) : null,
              end: props.filter.value.end ? new Date(props.filter.value.end) : null
            } : null;

            return (
              <DateRangePicker
                onChange={handleDateRangeChange}
                value={value}
                clearButton={true}
                format="dd/MM/yyyy"
                popupSettings={{
                  appendTo: document.body
                }}
              />
            );
          } else {
            // Handle single DatePicker for other operators
            const handleDateChange = (event) => {
              const value = event.value ? event.value.toISOString() : null;
              
              props.onFilterChange({
                nextFilter: {
                  ...props.filter,
                  value
                }
              });
            };

            const value = props.filter?.value ? new Date(props.filter.value) : null;

            return (
              <DatePicker
                onChange={handleDateChange}
                value={value}
                clearButton={true}
                format="dd/MM/yyyy"
                popupSettings={{
                  appendTo: document.body
                }}
              />
            );
          }
        } :
        memo((props) => (
          <CustomFilter
            {...props}
            options={options}
            fields={fields}
            context={context}
            field={{
              ...field,
              operators: field?.operators || defaultOperators[field?.type] || []
            }}
            companyId={companyId}
          />
        )),
        operators: field?.operators || defaultOperators[field?.type] || [],
    }));
  }, [fields, context, companyId]);
};

const updateFilter = (currentFilter, newFilters) => {
  return {
    ...currentFilter,
    filters: [...newFilters.filters],
  };
};
export const QueryBuilderKendo = ({
  fields,
  context,
  setQuery,
  query,
  editFormValues,
  setFieldValue,
  companyId,
  disabledConditionBuilder
}) => {
  const [filter, setFilter] = useState(query);

  const handleFilterChange = useCallback((event) => {
    const newFilter = event.filter;
    setFilter(newFilter);
    setFieldValue("filter", newFilter);
  }, [setFieldValue]);

  const memoizedFields = usePrepareFields(fields, {}, filter, context, companyId);

  useEffect(() => {
    if (query) {
      setFilter(query);
    }
  }, [query]);

  const filterProps = useMemo(() => ({
    value: filter,
    onChange: handleFilterChange,
    fields: memoizedFields
  }), [filter, handleFilterChange, memoizedFields]);

  const filterStyles = disabledConditionBuilder
  ? { pointerEvents: 'none', opacity: 0.5 } 
  : {}; 

  return <div style={filterStyles}><Filter {...filterProps} /></div>
};

export default QueryBuilderKendo;
