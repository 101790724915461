
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState} from "react";
import { Row, Col, Button, Nav } from 'react-bootstrap';
import {useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { reduxForm } from "redux-form";
import { LuActivitySquare } from "react-icons/lu";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {HiOutlineTrash} from "react-icons/hi";
import axios from "axios";
import { navigationHooks } from "../../../../helpers/NavigationHook";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

const HeaderSec = (props) => {
    const navigate = useNavigate();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const dispatch = useDispatch();
    const params = useParams();
    const context = useContext(MyContext);
    const closeButton = useRef(null);
    const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
        context.newTabPrevent();
	}, []);
  
    const submit = async (values) => {
      let id = params.id
      let isValid = true;
        console.log("EditTOTHeaders",values, id)       
       
        if (!values.status || values.status == '') {
            props.setStatusErr("error");
            isValid = false;
        } else {
            props.setStatusErr("");
            // isValid = true;
        }
        // if (props.isActive == true && (!values.methodType || values.methodType == '')) {
        //     props.setMethodErr("error");
        //     isValid = false;
        // } else {
        //     props.setMethodErr("");
        // }
        // if (props.isActive == true && (!values.attributeValueType || values.attributeValueType == '')) {
        //     props.setAttributeValueTypeErr("error");
        //     isValid = false;
        // } else {
        //     props.setAttributeValueTypeErr("");
        // }
        // if (props.isActive == true && (!values.apiHost || values.apiHost == '')) {
        //     props.setAPIHostErr("error");
        //     isValid = false;
        // } else {
        //     props.setAPIHostErr("");
        // }
        // if (props.isActive == true && (!values.attributeKey || values.attributeKey.trim() == '')) {
        //     props.setAttributeKeyErr("error");
        //     isValid = false;
        // } else {
        //     props.setAttributeKeyErr("");
        // }
        // if (props.isActive == true && (!values.attrLength || parseInt(values.attrLength) <= "0" || parseInt(values.attrLength) >= "1000")) {
        //     props.setAttributeLengthErr("error");
        //     isValid = false;
        // } else {
        //     props.setAttributeLengthErr("");
        // }
        // if (props.isActive == true && (!values.apiEndPoint || values.apiEndPoint.trim() == '')) {
        //     props.setEndpointErr("error");
        //     isValid = false;
        // } else {
        //     props.setEndpointErr("");
        // }
        // if (props.isActive == true && (!values.headers || values.headers.trim() == '')) {
        //     props.setHeadersErr("error");
        //     isValid = false;
        // } else {
        //     props.setHeadersErr("");
        // }
        // if (props.isActive == true && (!values.bindAttributes || values.bindAttributes.trim() == '')) {
        //     props.setBindAttriErr("error");
        //     isValid = false;
        // } else {
        //     props.setBindAttriErr("");
        // }
        // if (props.isActive == true && (!values.foreignKey || values.foreignKey == '')) {
        //     props.setForeignKeyErr("error");
        //     isValid = false;
        // } else {
        //     props.setForeignKeyErr("");
        // }
        // if (props.isActive == true && (!values.referenceTable || values.referenceTable.trim() == '')) {
        //     props.setReferenceTableErr("error");
        //     isValid = false;
        // } else {
        //     props.setReferenceTableErr("");
        // }
        // if (props.isActive == true && (!values.referenceColumn || values.referenceColumn.trim() == '')) {
        //     props.setReferenceColumnErr("error");
        //     isValid = false;
        // } else {
        //     props.setReferenceColumnErr("");
        // }
        // if (props.isActive == true && (!values.referenceMicroservice || values.referenceMicroservice.trim() == '')) {
        //     props.setReferenceMSErr("error");
        //     isValid = false;
        // } else {
        //     props.setReferenceMSErr("");
        // }

        if (isValid == true) {
        let postjson = [];
            // let body = {
            //     attributeLabel:values.attributeLabel,
            //     dataType: values.dataType,
            //     universalId:id,
            //     status: values.status,
            //     totLabel: values.totLabel,
            //   };
              values.universalId = id
              postjson.push(values)

        return axios
        .patch(
          "/rules/data/updateAttributeTableDetails" , postjson).then((resp)=>{
            navigationHooks.navigate("/toa");
        })
        }
    };
    let { handleSubmit, submitting } = props;
    return (
      <>
        <Row className="margin-b-15">
          <Col lg={4} md={6} sm={6} xs={7}>
            <h1 bsClass="" className="sPageHeading1">
              {"Table of Attributes"}
            </h1>
          </Col>
          <Col lg={8} md={6} sm={6} xs={5}>
            <div className={`float-r toprigLnk fulfillHeader`}>
              <Nav className="icnlnk riggep float-l" as="ul">
                <Nav.Item as="li" title={tr["Activity Details"]} eventKey={2}>
                  <a
                    className={props.view === "activity" ? "active" : ""}
                    href="javascript:void(0)"
                    onClick={() => {
                      props.setView("activity");
                      props.rightEditPanel(true);
                    }}
                  >
                    <LuActivitySquare />
                  </a>
                </Nav.Item>
              </Nav>

              <Nav as="ul" className="icnlnk">
                <Nav.Item
                  as="li"
                  eventKey={1}
                  disabled={submitting}
                  title={tr["Save"]}
                >
                  <Link
                    bsPrefix=" "
                    id="hdID"
                    to="javascript:void(0)"
                    className="myBt plus ctrlKeyPrevent"
                    disabled={props.loader}
                    onClick={handleSubmit(submit)}
                    title={tr["Save"]}
                    ref={saveObjRef}
                  >
                    <span>
                      {props.loader ? (
                        <ImSpinner6 className="icn-spinner" />
                      ) : (
                        <IoSaveOutline />
                      )}
                    </span>
                  </Link>
                </Nav.Item>

                <Nav.Item
                  as="li"
                  title={tr["Close"]}
                  className="ctrlKeyPrevent"
                  eventKey={3}
                >
                  <Link
                    bsPrefix=" "
                    to="/toa"
                    className="myBt cancel fillBtn ctrlKeyPrevent"
                    title={tr["Cancel"]}
                    ref={closeButton}
                  >
                    <IoClose />
                  </Link>
                </Nav.Item>
              </Nav>
            </div>
          </Col>
        </Row>
      </>
    );
}
export default reduxForm({
    form: "EditTOA",
  })(HeaderSec);