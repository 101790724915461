
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect } from "react";
import moment from "moment";
import parse from "html-react-parser";
import { ImInfo } from "react-icons/im";
import { Popover, Card, Button, OverlayTrigger } from "react-bootstrap";

import {
  useProfileDetailsUrlQuery,
  useProfileTimeZoneUrlQuery,
} from "../../../reducers/governance/meeting/meeting";
import UserDetailsComponent from "../common/userDetails";
import datetimeConvertor from "../../../helpers/ISO8601converter";
import { getCookiesValuesByPositions } from "../Helper";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";
const cookies = new Cookies();
let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];

const AuditLogs = ({ data = [] , setOrderValue}) => {
  const [selectedUser, setSelectedUser] = useState("");
  const [timezoneText, setTimezoneText] = useState("");
  const windowSize = useSelector((state) => state.windowSize.width);

  const cookieValues = getCookiesValuesByPositions([2, 3]);
  const dateformat = cookieValues?.value1;
  const timezone = cookieValues?.value2;

  const jwtdf = dateformat?.slice(0, 10).toUpperCase();

  const actDateTimeFormat = jwtdf + " hh:mm A";

  const { data: userDetails, isFetching: isUserFetching } =
    useProfileDetailsUrlQuery(
      {
        userId: selectedUser,
      },
      {
        skip: !selectedUser,
      }
    );

  const {
    data: timezoneData,
    isFetching: isTimezoneFetching,
    refetch: timezoneFetch,
  } = useProfileTimeZoneUrlQuery({
    skip: false,
  });

  useEffect(() => {
    const matchingBreakFixObj = timezoneData?.find((breakFixObj) => {
      return breakFixObj?.KEY === Number(userDetails?.timezoneid);
    });

    if (matchingBreakFixObj) {
      setTimezoneText(matchingBreakFixObj?.VALUE);
    }
  }, [userDetails, isTimezoneFetching]);

  return (
    <div id="audHistory" className="actCommts">
      <div className="d-flex justify-content-end">
        {data?.length > 0 && (
          <span className="f-size-17">
            <a
              className="black"
              title={"Ascending"}
              onClick={() => {
                setOrderValue("desc");
              }}
            >
              <i className="fa fa-angle-up"></i>
            </a>
            &nbsp;&nbsp;
            <a
              className="black"
              title={"Descending"}
              onClick={() => {
                setOrderValue("asc");
              }}
            >
              <i className="fa fa-angle-down"></i>
            </a>
          </span>
        )}
      </div>
      {data?.map((comment, childKey) => {
        const isoDate = datetimeConvertor(
          comment?.createdOn,
          jwtdf + " HH:mm:ss"
        );
        const myDateObj = moment(isoDate).format(actDateTimeFormat);
        const endDate = moment(myDateObj, actDateTimeFormat);
        const endDateStr = endDate._i;
        const startTime = moment(
          new Date().toLocaleString("en-US", { timeZone: timezone })
        );
        const timeago = moment(endDateStr, actDateTimeFormat).from(startTime);
        const duration = moment.duration(startTime.diff(endDate));
        const hours = duration.asHours();

        const tooltip = (
          <Popover className="userInfoPopup govrncInfoPopup">
            <UserDetailsComponent
              userDetails={userDetails}
              isUserFetching={isUserFetching}
              timezoneText={timezoneText}
            />
          </Popover>
        );

        return (
          <Card className="actlogCrd" bsPrefix=" ">
            <div className="circleTxt">
              <div className="subhed">
                {(comment.createdByFullName !== "System User" || comment.createdByFullName !== "System") ? (
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement={windowSize < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
}
                    overlay={tooltip}
                  >
                    <span
                      className="circleNa"
                      title={comment?.createdByFullName}
                      onClick={() => {
                        setSelectedUser(comment?.createdBy);
                        timezoneFetch();
                      }}
                    >
                      {comment?.createdByFullName} <ImInfo />
                    </span>
                  </OverlayTrigger>
                ) : (
                  <span className="margin-r-20">
                    {comment?.createdByFullName}
                  </span>
                )}

                <span className="margin-l-5 timeao">
                  <span
                    className="margin-l-5 timeao"
                    title={hours >= 24 ? "" : endDateStr}
                  >
                    {hours >= 24 ? endDateStr : timeago}
                  </span>
                </span>
              </div>
              <div className="desFld">
                <div title="Field Name" className="fenme">
                  {parse(comment?.auditField)}
                </div>
                <div title="New Value" className="nwnme commn">
                  {parse((comment?.to || '').replace(/,/g, ', '))}
                </div>
                <div title="Old Value" className="oldnme commn">
                  {parse((comment?.from || '').replace(/,/g, ', '))}
                </div>
              </div>
            </div>
          </Card>
        );
      }) || (
        <Card className="actlogCrd text-center" bsPrefix=" ">
          No Data Available
        </Card>
      )}
    </div>
  );
};

export default AuditLogs;
