/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React, { useEffect, useState } from "react";
import {
  GetInputField,
  CustomDropdown,
  GetCheckBoxField,
  GetDropdownConnectorField,
  GetRadioButtonField,
  GetTextareaField,
  GetTypeaheadField,
  GetUploadDropzoneField,
  ITagComponent,
} from "../../components/common/builderFormFields";
import {
  checkIfHiddenField,
  getFieldsName,
  getTag,
  replaceVariables,
} from "../../../studio/utils";
import { Button } from "react-bootstrap";
import LinkComponent from "../LinkComponent";
import { childernText } from "../../View";

const RepeatativeField = (props) => {
  const [fields, setFields] = useState([]);
  useEffect(() => {
    if (props?.components) {
      const fieldArray = getFieldsName(props?.components) || [];
      let fieldObject = {};
      if (fieldArray.length > 0) {
        fieldArray.forEach((element) => {
          fieldObject[element] = "";
        });
        setFields([...fields, fieldObject]);
      }
    }
  }, [props?.components]);

  const onValueChange = (e, name, index) => {
    let tempField = structuredClone(fields);
    tempField[index][name] = e;
    setFields(tempField);
    props.onChange(tempField, { name: props?.item?.valueBindKey });
  };
  
  const updateValues = (
    name,
    value,
    extraMappedKey = "",
    secondValue = "",
    index
  ) => {
    let tempField = structuredClone(fields);
    if (extraMappedKey && secondValue) {
      tempField[index][extraMappedKey] = secondValue;
    }
    tempField[index][name] = value;
    setFields(tempField);
    props.onChange(tempField, { name: props?.item?.valueBindKey });
  };

  const setDefaultValues = (
    defaultValues,
    index,
    name,
    mappingField,
    extraFields
  ) => {
    let tempField = structuredClone(fields);
    let defaultVal = {};
    if (tempField[index] && Object.keys(tempField[index]).length > 0) {
      Object.keys(tempField[index]).forEach((key) => {
        if (defaultValues.hasOwnProperty(key)) {
          defaultVal[key] = defaultValues[key];
        } else if ([name, mappingField].includes(key)) {
          defaultVal[key] = "";
        } else {
          defaultVal[key] = tempField[index][key];
        }
      });
    }
    tempField[index] = defaultVal;
    setFields(tempField);
    props.onChange(tempField, { name: props?.item?.valueBindKey });
  };

  const addRow = () => {
    const fieldsKeys = Object.keys(fields[0]);
    let objects = {};
    if (fieldsKeys.length > 0) {
      fieldsKeys.forEach((element) => {
        objects[element] = "";
      });
      setFields([...fields, objects]);
    }
  };

  const deleteRow = (index) => {
    let newField = structuredClone(fields);
    if (newField.length !== 1) {
      newField.splice(index, 1);
      setFields(newField);
      props.onChange(newField, { name: props?.item?.valueBindKey });
    }
  };

  const renderComponent = (component, field, index) => {
    const { type, classes, components, text } = component;
    let className;
    if (Array.isArray(classes)) {
      className = classes.join(" ");
    } else {
      className = classes;
    }
    let attributes = {};
    if (component.attributes) {
      attributes = { ...component.attributes };
    }
    if (component.classes) {
      attributes.className = component.classes
        .map((c) => (c.name ? c.name : c))
        .join(" ");
    }
    if (component?.name && component.name == "Row") {
      attributes.className = attributes.className + "";
    }
    if (component?.name && component.name == "Cell") {
      attributes.className = attributes.className + "";
    }
    const children = components
      ? components.map((res) => renderComponent(res, field, index))
      : component?.content?childernText(replaceVariables(component?.content,{...field,...props?.defaultValues})):null;
    
    switch (type) {
      case "input":
        return (
          <GetInputField
            attributes={component?.attributes}
            language={props?.language}
            onChange={(e) =>
              onValueChange(
                e,
                component?.name || component?.attributes?.name,
                index
              )
            }
            defaultValues={
              fields[index][component?.name || component?.attributes?.name]
                ? props?.defaultValues[props?.item?.valueBindKey][index]
                : ""
            }
            hiddenFIelds={[]}
            readOnlyFields={[]}
            errors={
              props?.errors.hasOwnProperty(props?.item?.valueBindKey) &&
              !fields[index][component?.name || component?.attributes?.name]
                ? { [component?.name || component?.attributes?.name]: "error" }
                : {}
            }
            editData={props?.editData}
            atr={{ id: attributes?.id, className: attributes?.className }}
          />
        );
      case "textarea": {
        return (
          <GetTextareaField
            attributes={component?.attributes}
            language={props?.language}
            onChange={(e) =>
              onValueChange(
                e,
                component?.name || component?.attributes?.name,
                index
              )
            }
            defaultValues={
              fields[index][component?.name || component?.attributes?.name]
                ? props?.defaultValues[props?.item?.valueBindKey][index]
                : ""
            }
            hiddenFIelds={[]}
            readOnlyFields={[]}
            errors={
              props?.errors.hasOwnProperty(props?.item?.valueBindKey) &&
              !fields[index][component?.name || component?.attributes?.name]
                ? { [component?.name || component?.attributes?.name]: "error" }
                : {}
            }
            editData={props?.editData}
            atr={{ id: attributes?.id, className: attributes?.className }}
          />
        );
      }
      case "custom-dropdown":
      case "select": {
        return (
          <CustomDropdown
            props={component}
            language={props?.language}
            onChange={(e) =>
              onValueChange(
                e,
                component?.name || component?.attributes?.name,
                index
              )
            }
            defaultValues={
              fields[index][component?.name || component?.attributes?.name]
                ? props?.defaultValues[props?.item?.valueBindKey][index]
                : {}
            }
            hiddenFIelds={[]}
            readOnlyFields={[]}
            setDefaultValues={(defaultValues) =>
              setDefaultValues(
                defaultValues,
                index,
                component?.attributes?.name,
                component?.attributes?.labelKeyMapping
              )
            }
            errors={
              props?.errors.hasOwnProperty(props?.item?.valueBindKey) &&
              !fields[index][component?.name || component?.attributes?.name]
                ? { [component?.name || component?.attributes?.name]: "error" }
                : {}
            }
            editData={props?.editData}
            atr={{ id: attributes?.id, className: attributes?.className }}
          />
        );
      }
      case "typeaheadComponent": {
        return (
          <GetTypeaheadField
            attributes={component}
            language={props?.language}
            setDefaultValues={(defaultValues) =>
              setDefaultValues(
                defaultValues,
                index,
                component?.name,
                component?.mappingField
              )
            }
            defaultValues={
              fields[index][component?.name || component?.attributes?.name]
                ? props?.defaultValues[props?.item?.valueBindKey][index]
                : ""
            }
            hiddenFIelds={[]}
            readOnlyFields={[]}
            errors={
              props?.errors.hasOwnProperty(props?.item?.valueBindKey) &&
              !fields[index][component?.name || component?.attributes?.name]
                ? { [component?.name || component?.attributes?.name]: "error" }
                : {}
            }
            editData={props?.editData}
            atr={{ id: attributes?.id, className: attributes?.className }}
          />
        );
      }
      case "uploadComponent": {
        return (
          <GetUploadDropzoneField
            attributes={component}
            language={props?.language}
            onChange={(e) =>
              onValueChange(
                e,
                component?.name || component?.attributes?.name,
                index
              )
            }
            defaultValues={
              fields[index][component?.name || component?.attributes?.name]
                ? props?.defaultValues[props?.item?.valueBindKey][index]
                : ""
            }
            hiddenFIelds={[]}
            readOnlyFields={[]}
            errors={
              props?.errors.hasOwnProperty(props?.item?.valueBindKey) &&
              !fields[index][component?.name || component?.attributes?.name]
                ? { [component?.name || component?.attributes?.name]: "error" }
                : {}
            }
            editData={props?.editData}
            atr={{ id: attributes?.id, className: attributes?.className }}
          />
        );
      }
      case "custom-checkbox": {
        return (
          <GetCheckBoxField
            attributes={component}
            language={props?.language}
            onChange={(e) =>
              onValueChange(
                e,
                component?.name || component?.attributes?.name,
                index
              )
            }
            defaultValues={
              fields[index][component?.name || component?.attributes?.name]
                ? props?.defaultValues[props?.item?.valueBindKey][index]
                : {}
            }
            hiddenFIelds={[]}
            readOnlyFields={[]}
            errors={
              props?.errors.hasOwnProperty(props?.item?.valueBindKey) &&
              !fields[index][component?.name || component?.attributes?.name]
                ? { [component?.name || component?.attributes?.name]: "error" }
                : {}
            }
            editData={props?.editData}
            atr={{ id: attributes?.id, className: attributes?.className }}
          />
        );
      }
      case "custom-radio": {
        return (
          <GetRadioButtonField
            attributes={component}
            language={props?.language}
            onChange={(e) =>
              onValueChange(
                e,
                component?.name || component?.attributes?.name,
                index
              )
            }
            defaultValues={
              fields[index][component?.name || component?.attributes?.name]
                ? props?.defaultValues[props?.item?.valueBindKey][index]
                : ""
            }
            hiddenFIelds={[]}
            readOnlyFields={[]}
            errors={
              props?.errors.hasOwnProperty(props?.item?.valueBindKey) &&
              !fields[index][component?.name || component?.attributes?.name]
                ? { [component?.name || component?.attributes?.name]: "error" }
                : {}
            }
            editData={props?.editData}
            atr={{ id: attributes?.id, className: attributes?.className }}
          />
        );
      }
      case "dropdownConnector": {
        return (
          <GetDropdownConnectorField
            attributes={component}
            language={props?.language}
            defaultValues={
              fields[index][component?.name || component?.attributes?.name]
                ? props?.defaultValues[props?.item?.valueBindKey][index]
                : ""
            }
            hiddenFIelds={[]}
            readOnlyFields={[]}
            errors={
              props?.errors.hasOwnProperty(props?.item?.valueBindKey) &&
              !fields[index][component?.name || component?.attributes?.name]
                ? { [component?.name || component?.attributes?.name]: "error" }
                : {}
            }
            editData={props?.editData}
            updateValues={(name, value, extraMappedKey, secondValue) =>
              updateValues(name, value, extraMappedKey, secondValue, index)
            }
            atr={{ id: attributes?.id, className: attributes?.className }}
          />
        );
      }
      case "button":
        if (
          component?.buttonType &&
          component?.buttonType.id == "repeatativePlus" &&
          fields.length - 1 == index
        ) {
          return (
            <button
              className={className}
              {...attributes}
              onClick={() => addRow()}
            >
              {component?.iconClass ? (
                <i className={component?.iconClass}></i>
              ) : (
                text
              )}
            </button>
          );
        } else if (
          component?.buttonType &&
          component?.buttonType.id == "repeatativeDelete" &&
          fields.length != 1
        ) {
          return (
            <button
              className={className}
              {...attributes}
              onClick={() => deleteRow(index)}
            >
              {component?.iconClass ? (
                <i className={component?.iconClass}></i>
              ) : (
                text
              )}
            </button>
          );
        }
        return null;
      case "link":
        delete attributes.href;
        delete attributes.target;
        if (
          component?.buttonType &&
          component?.buttonType.id == "repeatativePlus" &&
          fields.length - 1 == index
        ) {
          return (
            <a
              href={void 0}
              className={className}
              {...attributes}
              onClick={() => addRow()}
            >
              {/* {component?.iconClass ? (
                <i className={component?.iconClass}></i>
              ) : (
                text
              )} */}
              {props?.renderComponent(component?.components, false, field)}
            </a>
          );
        } else if (
          component?.buttonType &&
          component?.buttonType.id == "repeatativeDelete" &&
          fields.length != 1
        ) {
          return (
            <a
              href={void 0}
              className={className}
              {...attributes}
              onClick={() => deleteRow(index)}
            >
              {props?.renderComponent(component?.components, false, field)}
            </a>
          );
        }
        return null;
      case "textnode":
        return childernText(replaceVariables(text || component?.content,{...field,...props?.defaultValues}));
      default:
        return React.createElement(
          component.type ? getTag(component) : "div",
          { ...attributes },
          children
        );
    }
  };

  const renderByComponent = (json) => {
    if (fields.length > 0) {
      return fields.map((field, index) =>
        json.map((res) => renderComponent(res, field, index))
      );
    }
  };

  return (
    <div>
      {fields && fields.length > 0 && renderByComponent(props?.components)}
    </div>
  );
};

export default RepeatativeField;
