
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Nav, Button, NavDropdown, Dropdown } from "react-bootstrap";
// import { push } from 'react-router-redux';
let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
import { Link } from "react-router-dom";
import { GLOBAL } from "_Globals";
import axios from "axios";
//let user_id=userDetails.user_id;
import { reduxForm, change, SubmissionError } from "redux-form";
import {
  loadCIEditDetails,
  loadModelName,
} from "../../../actions/cmdb/lovAction";
import { getTimelineData } from "../../../actions/cmdb/cmdbTimelineAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import constanturl from "../../../utils/constants.js";
import {LuFileText, LuFileSearch, LuHistory} from "react-icons/lu";
import {ImAttachment, ImCopy, ImSpinner6} from "react-icons/im";
import { GrHostMaintenance } from "react-icons/gr";
import {FaTimeline} from "react-icons/fa6";
import { FaRegObjectGroup } from "react-icons/fa";
import { BsUpcScan } from "react-icons/bs";
import {TbSubtask, TbAlignBoxLeftMiddle, TbPencil, TbCirclesRelation, TbTextWrap} from "react-icons/tb";
import {PiTagBold} from "react-icons/pi";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {RiStackshareLine} from "react-icons/ri";
import { BiDotsVertical } from "react-icons/bi";
import { MyContext } from '_MyContext';
import Swal from "sweetalert2";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
let cookiesData = cookies.get('gph');
if (cookiesData) cookiesData = cookiesData.replace('s:', '');
if (cookiesData) cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf('.'));
cookiesData = cookiesData.split("~");

let onContractRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onContractRoleVisible = onContractRoleVisible.includes('68')

let onConfigManagerRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onConfigManagerRoleVisible = onConfigManagerRoleVisible.includes('37')

let onAssetRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onAssetRoleVisible = onAssetRoleVisible.includes('38')

let onConfigViewerRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onConfigViewerRoleVisible = onConfigViewerRoleVisible.includes('39')
class CmdbEditHeader extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
      disabledSave: false,
    };
    this.submit = this.submit.bind(this);
  }

  componentWillMount() {
    // this.props.loadCIEditDetails(this.props.ciId);
  }

  componentDidMount() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}

  emptyGlobals() {
    GLOBAL.cinameErrorColor = "";
    GLOBAL.ciclassErrorColor = "";
    GLOBAL.cistatusErrorColor = "";
    GLOBAL.cicompanyErrorColor = "";
    GLOBAL.ciSupportCompanyError = "";
    GLOBAL.ciSupportGroupError = "";
    GLOBAL.cimanufacturerErrorColor = "";
    GLOBAL.cibusinessownerErrorColor = "";
    // GLOBAL.cilocationErrorColor = "";
    GLOBAL.citechownerErrorColor = "";
    GLOBAL.cigroupErrorColor = "";
    GLOBAL.ciSubStatusErrorColor = "";
    GLOBAL.categoryErrorColor = "";
    GLOBAL.subCategoryErrorColor = "";
  }

  submit(values) {    
    if (this.props.formikRef?.current?.isSubmitting) {
      return;
    }
    if (this.props.formikRef?.current) {
      this.props.formikRef.current?.submitForm();
    }
  };

  submitN(values) {
    console.log("XsmCMDBEditFormTab Values");
    console.log(values);
    // alert("submit"+values.ciname)
    // if (GLOBAL.cicompanyname == "")
    //   GLOBAL.cicompanyname = this.props.CIEditDetails.COMPANY_NAME
    // if (GLOBAL.cigroupname == "")
    //   GLOBAL.cigroupname = this.props.CIEditDetails.GROUP_NAME
    // if (GLOBAL.ciindividualname == "")
    //   GLOBAL.ciindividualname = this.props.CIEditDetails.BUSINESS_OWNER_NAME
    // if (GLOBAL.cilocationname == "")
    //   GLOBAL.cilocationname = this.props.CIEditDetails.LOCATION_NAME
    // if (GLOBAL.cisupportcompanyname == "")
    //   GLOBAL.cisupportcompanyname = this.props.CIEditDetails.SUPPORT_COMPANY_NAME
    // if (GLOBAL.citechindividualname == "")
    //   GLOBAL.citechindividualname = this.props.CIEditDetails.TECHNICAL_OWNER_NAME
    if (values.model_name_readonly) {
      delete values.model_name_readonly;
    }

    let ref = this;
    this.setState({ disabledSave: true });

    return sleep(1000) // simulate server latency
      .then(() => {
        //alert(supportPgroupNameValue.length());
        if (`${JSON.stringify(values, null, 2)}` == "{}") {
          this.setState({ disabledSave: false });
          throw new SubmissionError({
            _error:
              "The form has missing required fields, please revalidate the highlighted fields and update the form",
          });
        } else if (
          values.ciname == "" ||
          values.ciclass == "" ||
          values.status == "" ||
          values.status == "0" ||
          values.subStatus == "" ||
          values.subStatus == "0" ||
          values.company == "" ||
          values.company == "0" ||
          values.manufacturer == "" ||
          values.manufacturer == "0" ||
          values.businessowner == "" ||
          values.businessowner == "0" ||
          values.techowner == "" ||
          values.techowner == "0" ||
          // (this.props.isLocationRequired && (values.location == "" || values.location == "0")) ||
          (values.status == "7" && (values.supcompany == "" || values.supcompany == "0")) ||
          (values.status == "7" && (values.group == "" || values.group == "0")) ||
          values.category == "" ||
          values.category == "0" ||
          values.category == null ||
          values.category == "null" ||
          values.subCategory == "" ||
          values.subCategory == "0" ||
          values.subCategory == null ||
          values.subCategory == "null" ||
          values?.serial_number?.toString()?.length > 250 
        ) {
          if (values.ciname == "") {
            GLOBAL.cinameErrorColor = "error";
          } else {
            GLOBAL.cinameErrorColor = "";
          }
          if (values.ciclass == "" || values.ciclass == "0") {
            GLOBAL.ciclassErrorColor = "error";
          } else {
            GLOBAL.ciclassErrorColor = "";
          }

          if (values.status == "" || values.status == "0") {
            GLOBAL.cistatusErrorColor = "error";
          } else {
            GLOBAL.cistatusErrorColor = "";
          }

          if (values.company == "" || values.company == "0") {
            GLOBAL.cicompanyErrorColor = "error";
          } else {
            GLOBAL.cicompanyErrorColor = "";
          }

          if (values.manufacturer == "" || values.manufacturer == "0") {
            GLOBAL.cimanufacturerErrorColor = "error";
          } else {
            GLOBAL.cimanufacturerErrorColor = "";
          }

          if (values.businessowner == "" || values.businessowner == "0") {
            GLOBAL.cibusinessownerErrorColor = "error";
          } else {
            GLOBAL.cibusinessownerErrorColor = "";
          }

          // if (this.props.isLocationRequired && (values.location == "" || values.location == "0")){
          //   GLOBAL.cilocationErrorColor="error";
          // }else{
          //   GLOBAL.cilocationErrorColor="";
          // }

          if ((values.status == "7" || values.status == 7) && (values.supcompany == "" || values.supcompany == "0" || values.supcompany == 0)){
            GLOBAL.ciSupportCompanyError="error";
          }else{
            GLOBAL.ciSupportCompanyError="";
          }

          if ((values.status == "7" || values.status == 7) && (values.group == "" || values.group == "0" || values.group == 0)){
            GLOBAL.ciSupportGroupError="error";
          }else{
            GLOBAL.ciSupportGroupError="";
          }

          if (values.techowner == "" || values.techowner == "0") {
            GLOBAL.citechownerErrorColor = "error";
          } else {
            GLOBAL.citechownerErrorColor = "";
          }


          if (values.subStatus == "" || values.subStatus == "0") {
            GLOBAL.ciSubStatusErrorColor = "error";
          } else {
            GLOBAL.ciSubStatusErrorColor = "";
          }

          if (
            values.category == "" ||
            values.category == "0" ||
            values.category == null ||
            values.category == "null"
          ) {
            GLOBAL.categoryErrorColor = "error";
          } else {
            GLOBAL.categoryErrorColor = "";
          }

          if (
            values.subCategory == "" ||
            values.subCategory == "0" ||
            values.subCategory == null ||
            values.subCategory == "null"
          ) {
            GLOBAL.subCategoryErrorColor = "error";
          } else {
            GLOBAL.subCategoryErrorColor = "";
          }

          this.setState({ disabledSave: false });
          throw new SubmissionError({
            _error:
              this.props.translator[
                "The form has missing required fields, please revalidate the highlighted fields and update the form !"
              ],
          });
        } else {
          // let catid = "";
          let locid = "";
          // console.log("valcat::"+values.category)
          // if (values.subCategory == "" || values.subCategory == "0" || values.subCategory == null || values.subCategory == "null")
          //   catid = "0";
          // else
          //   catid = values.subCategory;
          if (values.location == "") locid = "0";
          else locid = values.location;

          let groupid ="";
          if (values.group == "") groupid = "0";
          else groupid  = values.group;

          let supportCompanyId="";
          if (values.supcompany == "") supportCompanyId = "0";
          else supportCompanyId = values.supcompany;
          return axios
            .patch(GLOBAL.patchCiDataURL + "/" + this.props.ciId, {
              ci_name: values.ciname,
              company_id: values.company,
              company_name: values.companyName,
              status: values.status,
              sub_status: values.subStatus,
              category_id: values.subCategory,
              sub_category_id: 0,
              group_id:groupid,
              group_name:values.groupName ? values.groupName : '',
              location_id: locid,
              location_name: values.locationName,
              metallic: values.metallic,
              environment: values.environment,
              manufacturer: "0",
              dataset: "5",
              description: values.description,
              updated_by: "",
              business_owner_id: values.businessowner,
              business_owner_name: values.businessownerName,
              technical_owner_id: values.techowner,
              technical_owner_name: values.techownerName,
              owner_id: values.assignedto ? values.assignedto : 0,
              owner_name: values.assignedtoName ? values.assignedtoName : "",
              support_company_id:supportCompanyId,
              support_company_name:values.supcompanyName ? values.supcompanyName : '',
              asset_tag: values.assettag,
              updated_by_id: "",
              uddated_by_name: "",
              type: values.citype,
              serial_number: values.serial_number,
              class_name: GLOBAL.cmdbCreateSelectedText.class_name
                ? GLOBAL.cmdbCreateSelectedText.class_name
                : "",
              category_name: GLOBAL.cmdbCreateSelectedText.category_name
                ? GLOBAL.cmdbCreateSelectedText.category_name
                : "",
              type_name: GLOBAL.cmdbCreateSelectedText.type_name
                ? GLOBAL.cmdbCreateSelectedText.type_name
                : "",
              environment_name: GLOBAL.cmdbCreateSelectedText.environment_name
                ? GLOBAL.cmdbCreateSelectedText.environment_name
                : "",
              status_name: GLOBAL.cmdbCreateSelectedText.status_name
                ? GLOBAL.cmdbCreateSelectedText.status_name
                : "",
              sub_category_name: GLOBAL.cmdbCreateSelectedText.sub_category_name
                ? GLOBAL.cmdbCreateSelectedText.sub_category_name
                : ":",
              sub_status_name: GLOBAL.cmdbCreateSelectedText.sub_status_name
                ? GLOBAL.cmdbCreateSelectedText.sub_status_name
                : "",
              is_external:
                values.is_external != ""
                  ? values.is_external.toString().toLowerCase()
                  : "false",
              dataset_name: "5",
              business_owner_email: GLOBAL.cmdbCreateSelectedText
                .business_owner_email
                ? GLOBAL.cmdbCreateSelectedText.business_owner_email
                : "",
              technical_owner_email: GLOBAL.cmdbCreateSelectedText
                .technical_owner_email
                ? GLOBAL.cmdbCreateSelectedText.technical_owner_email
                : "",
              owner_email: GLOBAL.cmdbCreateSelectedText.owner_email
                ? GLOBAL.cmdbCreateSelectedText.owner_email
                : "",
              metallic_name: GLOBAL.cmdbCreateSelectedText.metallic_name
                ? GLOBAL.cmdbCreateSelectedText.metallic_name
                : "",
            })
            .then(function (response) {
              console.log(response, "response000")
              if(response.data[0]?.upsert_status == false || response.data[0]?.upsert_status == "false"){
                Swal.fire(response.data[0]?.message);
                ref.setState({ disabledSave: false });
              } else {
                  ref.setState({ disabledSave: false });
                  ref.props.loadCIEditDetails(ref.props.ciId);
                  ref.props.loadModelName(ref.props.ciId);
                  ref.props.getTimelineData(ref.props.ciId);
                  ref.props.changeActionView("specifyAttributeValues");
              }
            })
            .catch(function (error) {
              console.log(error);
              ref.setState({ disabledSave: false });
              throw new SubmissionError({
                _error: error.response.data[0].message,
              });
            });
        }
      });
  }
  render() {
    let { handleSubmit, pristine, reset, submitting } = this.props;
    return (
      <Row className="margin-b-15">
        <Col lg={6} md={6} sm={5} xs={12}>
          <h1 bsClass="" className="sPageHeading1 ">
            {this.props.translator["Asset/CI"]}
          </h1>
          <div className="">{this.props.CIEditDetails.CI_CODE}</div>
        </Col>
        <Col lg={6} md={6} sm={7} xs={12}>
          <div className="float-r toprigLnk">
            <Nav className="icnlnk riggep float-l" as="ul">              
              <Nav.Item
                title={this.props.translator["Specify Attribute Values"]}
                eventKey={4}
                as="li"
              >
                <a className={this.props.actionView == "specifyAttributeValues" ? "active" : ""}
                  href={void 0}
                  onClick={() => {
                    this.props.changeActionView("specifyAttributeValues");
                    this.props.rightEditPanel(true);
                  }}
                >
                  <TbAlignBoxLeftMiddle/><TbPencil className="cmdbAttrPencil"/>
                </a>
              </Nav.Item>
              <Nav.Item
                title={this.props.translator["Attachment"]}
                eventKey={4}
                as="li"
              >
                <a className={this.props.actionView == "provideAttachments" ? "active" : ""}
                  href={void 0}
                  onClick={() => {
                    this.props.changeActionView("provideAttachments");
                    this.props.rightEditPanel(true);
                  }}
                >
                  <ImAttachment/>
                </a>
              </Nav.Item>
              <Nav.Item
                title={this.props.translator["Asset/CI Details"]}
                eventKey={4}
                as="li"
              >
                <a className={this.props.actionView == "assetCiDetails" ? "active" : ""}
                  href={void 0}
                  onClick={() => {
                    this.props.changeActionView("assetCiDetails");
                    this.props.rightEditPanel(true);
                  }}
                >
                  <LuFileText/>
                </a>
              </Nav.Item>
              <Nav.Item as="li" eventKey={3}>
                <a className={this.props.actionView == "tagDetails" ? "active" : ""}
                  href={void 0}
                  title={this.props.translator["Custom Attribute"]}
                  onClick={() => {
                    this.props.changeActionView("tagDetails");
                    this.props.rightEditPanel(true);
                  }}
                >
                  <PiTagBold className="svgRotate90"/>
                </a>
              </Nav.Item>
              <Nav.Item
                title={this.props.translator["Related Work Items"]}
                eventKey={4}
                as="li"
              >
                <a className={this.props.actionView == "CMDBRelationship" ? "active" : ""}
                  href={void 0}
                  onClick={() => {
                    this.props.changeActionView("CMDBRelationship");
                    this.props.rightEditPanel(true);
                  }}
                >
                  <TbSubtask/>
                </a>
              </Nav.Item>
              <Nav.Item
                title={this.props.translator["Status Timeline"]}
                eventKey={4}
                as="li"
              >
                <a className={this.props.actionView == "CMDBTimeline" ? "active" : ""}
                  href={void 0}
                  onClick={() => {
                    this.props.changeActionView("CMDBTimeline");
                    this.props.rightEditPanel(true);
                  }}
                >
                  <FaTimeline className="svgRotate90"/>
                </a>
              </Nav.Item>

              <NavDropdown
                className="threeDotul"
                eventKey={5}
                as={"ul"}
                title={
                  <span title="More">
                    <BiDotsVertical/>
                  </span>
                }
                id="threeDotdrpdown"
              >
                <NavDropdown.Item
                  bsPrefix=" "
                  as="li"
                  title={this.props.translator["CI Relationships"]}
                  eventKey={1}
                >
                  <Nav.Link
                    onClick={() => {
                      this.props.changeActionView("ciRelationships");
                      this.props.rightEditPanel(true);
                    }}
                    href="#"
                  >
                    <RiStackshareLine/>
                    {this.props.translator["CI Relationships"]}
                  </Nav.Link>
                </NavDropdown.Item>

                <NavDropdown.Item
                  bsPrefix=" "
                  as="li"
                  title={this.props.translator["Activity Details"]}
                  eventKey={3.1}
                >
                  <Nav.Link
                    onClick={() => {
                      this.props.changeActionView("auditLog");
                      this.props.rightEditPanel(true);
                    }}
                    href="#"
                  >
                    <LuFileSearch/>
                    {this.props.translator["Activity Details"]}
                  </Nav.Link>
                </NavDropdown.Item>

                {this.props.CIEditDetails.CLASS_ID == 2 ? (
                  <NavDropdown.Item
                    bsPrefix=" "
                    as="li"
                    title={this.props.translator["Related Offerings"]}
                    eventKey={3.1}
                  >
                    <Nav.Link
                      onClick={() => {
                        this.props.changeActionView("relatedOfferings");
                        this.props.rightEditPanel(true);
                      }}
                      href="#"
                    >
                      <TbCirclesRelation/>
                      {this.props.translator["Related Offerings"]}
                    </Nav.Link>
                  </NavDropdown.Item>
                ) : (
                  ""
                )}

                {/* <NavDropdown.Item
                  bsPrefix=" "
                  as="li"
                  title={this.props.translator["Related CIs"]}
                  eventKey={3.1}
                >
                  <Nav.Link
                    onClick={() => {
                      this.props.changeActionView("relatedCIs");
                    }}
                    href="#"
                  >
                    <TbCirclesRelation/>
                    {this.props.translator["Related CIs"]}
                  </Nav.Link>
                </NavDropdown.Item> */}
                <NavDropdown.Item
                  bsPrefix=" "
                  as="li"
                  title={this.props.translator["External Attributes"]}
                  eventKey={3.2}
                >
                  <Nav.Link
                    onClick={() => {
                      this.props.changeActionView("external");
                      this.props.rightEditPanel(true);
                    }}
                    href="#"
                  >
                    <TbTextWrap />
                    {this.props.translator["External Attributes"]}
                  </Nav.Link>
                </NavDropdown.Item>

                <NavDropdown.Item
                  bsPrefix=" "
                  as="li"
                  title={this.props.translator["Copy CI"]}
                  eventKey={3.3}
                >
                  <Nav.Link
                    onClick={() => {
                      this.props.changeActionView("CopyCI");
                      this.props.rightEditPanel(true);
                    }}
                    href="#"
                  >
                    <ImCopy />
                    {this.props.translator["Copy CI"]}
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item
                  bsPrefix=" "
                  as="li"
                  title={this.props.translator["Maintenance Schedule"]}
                  eventKey={3.4}
                >
                  <Nav.Link
                    onClick={() => {
                      this.props.changeActionView("MaintenanceSchedule");
                      this.props.rightEditPanel(true);
                    }}
                    href="#"
                  >
                    <GrHostMaintenance />
                   Maintenance Schedule
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item
                  bsPrefix=" "
                  as="li"
                  title={this.props.translator["CI Groups"]}
                  eventKey={3.4}
                >
                  <Nav.Link
                    onClick={() => {
                      this.props.changeActionView("CIGroups");
                      this.props.rightEditPanel(true);
                    }}
                    href="#"
                  >
                    <FaRegObjectGroup />
                     CI Groups
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item
                  bsPrefix=" "
                  as="li"
                  title="Barcode"
                  eventKey={3.6}
                >
                  <Nav.Link
                    onClick={() => {
                      this.props.changeActionView("generateBarcode");
                      this.props.rightEditPanel(true);
                    }}
                    href="#"
                  >
                    <BsUpcScan />
                    Barcode
                  </Nav.Link>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>

            <Nav className="icnlnk" as="ul">
            <>
							{(onAssetRoleVisible || onConfigManagerRoleVisible)
							? (
                <Nav.Item bsPrefix=" " as="li" eventKey={1}>
                <Link
                  to="javascript:void(0)"
                  className="anchbtn ctrlKeyPrevent"
                  bsPrefix=" "
                  onClick={handleSubmit(this.submit)}
                  disabled={this.state.disabledSave}
                  title={this.props.translator["Save"]}
                  ref={e => this.saveObjRef = e}
                >
                  {this.state.disabledSave ? (
                    <ImSpinner6 className="icn-spinner" />
                  ) : (
                    <IoSaveOutline />
                  )}
                </Link>
              </Nav.Item>
							) : null}
						</>
              <Nav.Item as="li">
                <Link to="/cmdblist" className="ctrlKeyPrevent"
                ref={e => this.closeButton = e}>
                  <span
                    title={this.props.translator["Close"]}
                    onClick={this.emptyGlobals}><IoClose/></span>
                </Link>
              </Nav.Item>
            </Nav>
          </div>
        </Col>
      </Row>
    );
  }
}

CmdbEditHeader = reduxForm({
  form: "CmdbEditForm",
  destroyOnUnmount: true,
})(CmdbEditHeader);

export function mapStateToProps({ CIEditDetails, spcmReducer }) {
  return {
    CIEditDetails,
    ciChainEntities: spcmReducer.ciChainEntities,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { loadCIEditDetails, loadModelName, getTimelineData },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CmdbEditHeader);
