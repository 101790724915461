
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState} from "react";
import { Row, Col, Button } from 'react-bootstrap';
import {useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { deleteOrganizationData, updateOrganizationAction } from "../../../../../actions/foundation/organizationAction";
import { reduxForm } from "redux-form";
import Swal from "sweetalert2";

import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {HiOutlineTrash} from "react-icons/hi";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';
import { getCookiesValuesByPositions } from "../../../../common/helper";

const HeaderSec = (props) => {
    const navigate = useNavigate();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const dispatch = useDispatch();
    const params = useParams();
    const context = useContext(MyContext);
    const closeButton = useRef(null);
    const saveObjRef = useRef(null);
    const [show, setShow] = useState(false);
    const { value1: user_roleId } = getCookiesValuesByPositions([33]);
    useEffect(() => {
        if(user_roleId && user_roleId !== ''){
        let user_roleIdArr = user_roleId.split(","); 
            if(user_roleIdArr.includes('1')){
            setShow(true);
            } else if(user_roleIdArr?.includes('72') && !user_roleIdArr?.includes('1')){
              setShow(false);
            }
        }
        }, [user_roleId])

    useEffect(() => {
      context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
      context.newTabPrevent();
    }, []);


    const submit = async (values) => {
        let id = params.id;
        console.log("EditHeaderOrg", values);       
        let isValid = true;
        if (!values.name || values.name == ''|| values.name.trim() == '') {
            props.setNameErr("error");
            isValid = false;
            // return;
          } else {
            props.setNameErr("");
            // isValid = true;
        }
        if ( !values.status || values.status == '') {
            props.setStatusErr("error");
            isValid = false;
          } else {
            props.setStatusErr("");
            // isValid = true;
        }
        console.log("TestIsValid", isValid)
        if (isValid == true) {
        let postjson;
            postjson =  {        
               id: id,
               orgn_name: values.name,
               status: values.status,
               company_name: values.company_name,
               pagesize: 0,
               eventNameDefault: null,
               company_id: values.companyId,
               orgn_nameToEdit: values.initialOrgnName,
               orgn_code: values.org_code
        }
        dispatch(updateOrganizationAction(postjson)); 
        }
    };

    const onDeleteClick = () => {
        const id = window.location.href.split("/").pop();
        Swal.fire({
          title: tr["Are you sure you want to delete?"],
          width: 550,
          padding: '15px',
          showDenyButton: true,
          confirmButtonText: tr['Yes'],
          denyButtonText: tr['No'],
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(deleteOrganizationData(id))
          } else if (result.isDenied) {
            
          }
        })
      };

    let { handleSubmit, submitting } = props;
    return (
        <>
        <Row className="margin-b-15">
        <Col lg={4} md={6} sm={6} xs={5}>
                <h1 bsClass="" className="sPageHeading1">{tr["Organization"]}</h1>
            </Col>
            <Col lg={8} md={6} sm={6} xs={7}>
                <div className="paHedFilter">
                    {show && <Button role="button" bsPrefix=' '  className="myBt rclose" title={tr["Delete"]} onClick={onDeleteClick} >
                      <HiOutlineTrash/>
                    </Button>}
                    <Link
                        bsPrefix=' '
                        id="hdID"
                        to="javascript:void(0)"
                        className="myBt plus ctrlKeyPrevent"
                        disabled={props.loader}
                        onClick={handleSubmit(submit)} 
                        title={tr['Save']}
                        ref={saveObjRef} >
                          <span>{props.loader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/> }</span>
                    </Link>
                    <Link
                        bsPrefix=' '
                        to="/organization"
                        className="myBt cancel fillBtn ctrlKeyPrevent"
                        title={tr['Cancel']}
                        ref={closeButton} >
                        <IoClose/>
                    </Link>
                </div>
            </Col>
        </Row>
    </>
    )
}

export default reduxForm({
    form: "EditOrganization",
  })(HeaderSec);