
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { getMultiTenantLogo } from "_Actions/homepage/getMultiTenantLogo";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import { FiUser } from "react-icons/fi";

import keycloakbg from "_Images/keycloak-bg.png";

const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[24];

const required = (value) => (value ? undefined : "Required");

const validate = (values) => {
  let error = {};
  if (!values.user_email) {
    error.user_email = "required";
  }
  if (values.user_email) return error;
};

const user_email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

const renderField = ({
  input,
  label,
  type,
  style,
  meta: { touched, error },
}) => (
  <div>
    <input
      {...input}
      placeholder={label}
      type={type}
      style={style}
      className={touched && error ? "error form-control" : "form-control"}
    />
  </div>
);

let XsmLogin = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let bodybgS = windowWidth <= 690 ? 'initial' : `url(${keycloakbg}) no-repeat`;

  let styles = {
    txt: {
      fontSize: '24px',
      fontWeight: 300,
      color: '#737373',
      textAlign: 'center',
      marginBottom: '30px',
      marginTop: '20px',
    },
    spanSec: {
      border: 'none',
      borderRight: 0,
      color: '#555',
      backgroundColor: '#f3f3f3',
      padding: '0px 2px',
      display: 'table-cell',
      textAlign: 'center',
    },
    inputBox: {
      fontSize: '14px',
      borderColor: '#fff',
      color: '#555',
      backgroundColor: '#fff',
      padding: '6px 12px',
      height: '36px',
      display: 'table-cell',
      borderRadius: 0,
      width: '100%',
    },
    btnBox: {
      fontSize: '14px',
      fontWeight: 500,
      color: '#fff',
      backgroundColor: '#06c',
      border: 'none',
      boxShadow: 'none',
      display: 'block',
      width: '100%',
      marginTop: '20px',
      padding: '10px',
      marginBottom: '10px',
      borderRadius: '2px',
    },
    loginMain: {
      margin: 'auto',
      zIndex: 1,
      display: 'flex',
      height: windowWidth <= 690 ? 'auto' : '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    loginInputStyle: {
      borderTop: windowWidth <= 690 ? 'initial' : '4px solid #06c',
      backgroundColor: '#ffffff',
      borderRadius: '0px',
      width: windowWidth <= 690 ? '100%' : '500px',
      height: 'auto',
      minHeight: windowWidth <= 690 ? 'initial' : '270px',
      textAlign: 'center',
      padding: '30px 40px 40px',
      zIndex: 2,
      margin: '0px',
    },
    loginBgtextStyle: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 300,
      display: 'inline-block',
      padding: '40px 0px 0px',
      zIndex: 1,
    },
    txt1Style: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 300,
      fontSize: '18px',
      color: '#000000',
    },
    txt2Style: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 300,
      fontSize: windowWidth <= 690 ? '22px' : windowWidth <= 1024 ? '23px' : '24px',
      color: '#06c',
    },
    txt2StyleSpan: {
      color: '#06c',
    },
    txt3Style: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 300,
      fontSize: '36px',
      color: '#656565',
      marginTop: '-8px',
    },
  };

  useEffect(() => {
    props.getMultiTenantLogo();
  }, []);

  const { pristine, submitting } = props;
  let multiTenantLogoURL = props.multiTenantLogoReducer.Logo_URL;
  let logoEnabledFlag = props.multiTenantLogoReducer.Logo_Enabled_Flag;

  let bg = require("_Images/hcl-sx-logo-black.png");
  let logoUrl = logoEnabledFlag
    ? logoEnabledFlag == "TRUE"
      ? multiTenantLogoURL
      : bg
    : bg;

  let stylingObject = {
    logoAuth: {
      backgroundImage: `url(${logoUrl})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "contain",
      display: "inline-block",
      width: "130px",
      height: "42px",
      margin: "0px",
      textIndent: "-9999px",
    },
  };
  let acthomepage = "/login" + homepagelocation;

  return (
    <>
      <style>{`
        html, body {
          height: 100%;
          margin: 0;
          padding: 0;
          background: ${bodybgS};
          background-size: cover;
        }
          body #app{height: 100%;}
      `}</style>

      <div style={{height: "100%" }}>
        <form style={styles.loginMain} method="POST" action={acthomepage}>
          <div style={styles.loginInputStyle}>
            <div style={styles.txt}>Sign in to your account</div>
            <div style={{ textAlign: "left" }}>
              <div style={{ display: "inline-block", width: "100%" }}>
                <div style={{ position: "relative", display: "table", width: "100%", border: "1px #e0e0e0 solid", borderRadius: "2px" }}>
                  <Form.Label htmlFor="email" style={styles.spanSec}>
                    <FiUser className="f-size-16" />
                  </Form.Label>
                  <Field
                    id="email"
                    name="user_email"
                    type="email"
                    autocorrect="off"
                    autocapitalize="none"
                    component={renderField}
                    label="Enter your email address"
                    validate={[user_email, required]}
                    style={styles.inputBox}
                  />
                </div>
                <button
                  type="submit"
                  disabled={pristine || submitting}
                  style={styles.btnBox}
                >
                  NEXT
                </button>
              </div>
            </div>
            <div style={styles.loginBgtextStyle}>
              <div style={styles.txt1Style}>Sign in to Order Something,</div>
              <div style={styles.txt2Style}>Explore <span style={styles.txt2StyleSpan}>Knowledge Base</span> or</div>
              <div style={styles.txt3Style}>Report an Issue</div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = ({ multiTenantLogoReducer }) => {
  return {
    multiTenantLogoReducer,
  };
};

XsmLogin = reduxForm({
  form: "xsmLoginForm",
  validate,
})(XsmLogin);


export default connect(mapStateToProps, { getMultiTenantLogo })(XsmLogin);
