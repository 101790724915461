
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {
  _textArea,
  _dropDown,
  _inputField,
  _number,
  _radio,
  TypeaheadExample,
  TypeaheadExampleSingleSelect,
  TypeaheadandDropdown,
  renderTypeahead
} from "../../../components/common/formFields";
import {
  loadSlaCriteriaType,
  loadSlaOperator,
  loadSlaAttributes,
  loadSlaAttributesList,
  editSLA,
  getAdminPrefLevel
} from "_Actions/sla/lovAction";
import {getcompanyMenuListAction} from "_Actions/foundation/groupAction";
import {
  loadSlaDefActiveMandatory,
  loadSlaModuleStatus,
} from "_Actions/sla/quickViewAction";
import { loadBreakFixReasonCode,loadFulfillmentReasonCode } from "_Actions/breakFix/reasonCodeAction";
import { connect } from "react-redux";
import axios from "axios";
import { bindActionCreators } from "redux";
import { GLOBAL } from "_Globals";
import LoadingIcons from "react-loading-icons";
import { HiPlus } from "react-icons/hi";
import {
  Field,
  reduxForm,
  formValueSelector,
  change,
  SubmissionError,
} from "redux-form";
import CloseBtnRightPanel from "../../common/closeBtnRightPanel";
let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

import Cookies from 'universal-cookie';
const cookies = new Cookies();

let companyId = cookies.get('gph');
if (companyId) companyId = companyId.replace('s:', '');
if (companyId) companyId = companyId.substring(0, companyId.lastIndexOf('.'));
companyId = companyId.split("~");
companyId = companyId[48];

let languageSelected = cookies.get("gph");
  languageSelected = languageSelected.split("~");
  languageSelected = languageSelected[20];

class PopUpAttribute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formToggle: false,
      formeditToggle: false,
      formaddToggle: true,
      attname: "",
      attval: "",
      attid: "",
      slaCriteriaTypeId: "",
      slaCriteriaAttribute: "",
      slaCriteriaOperator: "",
      slaCriteriaValue: "",
      showSubmit: false,
      typeValue: "",
      hideInput: "",
      slaCriteriaValueText: "",
      prioritydrpdwn: [],
      selectedAttribute: "",
      ServiceAvailability: [
        { value: "Proposed", text: "Proposed" },
        { value: "Deployed", text: "Deployed" },
        { value: "Suspended", text: "Suspended" },
        { value: "Obsolete", text: "Obsolete" },
      ],
      serviceErrorColor: "",
      ImpactedErrorColor: "",
      RequestedErrorColor: "",
      holdReasonErrorColor: "",
      AssignedGroupErrorColor: [],
      impactedServiceDataDetails: [],
      consumerDataDetails: [],
      selectedConsumers: [],
      operatorSelected: "",
      enableEdit: false,
      editTypeHead: "",
      ANDshowOR: false,
      selectedServicesList: [],
      selectedReasonList: [],
      selectedImpactedCILists: [],
      impactedCICollections: [],
      selectedGroupsList: [],
      GroupList: [],
      slaLovList: {},
      classList: [],
      taskStatusList: [],
      operationalCategoryValues: [],
      opsCatList: [],
      opsCatFirstError: "",
      workItemList: [
        { value: "Incident", text: "Incident" },
        { value: "Problem", text: "Problem" },
        { value: "Change", text: "Change" },
        { value: "Fullfilment", text: "Fullfilment" },
      ],
      taskTypeList: [
        { value: "Implementation", text: "Implementation" },
        { value: "CMDB Update", text: "CMDB Update" },
        { value: "Preventive Task", text: "Preventive Task" },
        { value: "Corrective Task", text: "Corrective Task" },
      ],
      classIsSelectedOrNot: "",
      ciListBasedOnClass: [],
      duplicateExists: false,
      duplicateMessage: this.props.translator["Duplicate entities exists"],
      joinCondition: "AND",
      typedRequestor: "",
      businessCriticalityValue: [],
      companyList: [],
      selectedCompaniesList: [],
      companyError: "",
      templateTaskCodeDataDetails: [],
      selectedTemplateTaskCode: [],
      typedTemplateTaskCode: "",
      templateTaskCodeErrorColor: "",
      impactedCiData: [],
      selectedImpactedCi: [],
      selectedSlaCriticalValue: [],
      taskTypeAttributeValue: ""
    };
    this.formToggleFun = this.formToggleFun.bind(this);
    this.validateField = this.validateField.bind(this);
    this.saveAtt = this.saveAtt.bind(this);
    this.attDelete = this.attDelete.bind(this);
    this.editDetails = this.editDetails.bind(this);
    this.changeTypeValue = this.changeTypeValue.bind(this);
    GLOBAL.slaCriteriaTypeIdErrorColor = "";
    GLOBAL.slaCriteriaAttributeErrorColor = "";
    GLOBAL.slaCriteriaOperatorErrorColor = "";
    this.changeForm = this.changeForm.bind(this);
    this.onServiceSelection = this.onServiceSelection.bind(this);
    this.onHoldReasonSelection = this.onHoldReasonSelection.bind(this);
    this.setErrorHoldreasonColor = this.setErrorHoldreasonColor.bind(this);
    this.setErrorServiceColor = this.setErrorServiceColor.bind(this);
    this.onImpactedSelection = this.onImpactedSelection.bind(this);
    this.setErrorImpactedColor = this.setErrorImpactedColor.bind(this);
    this.setErrorColor = this.setErrorColor.bind(this);
    this.addFieldOR = this.addFieldOR.bind(this);
    this.addFieldAND = this.addFieldAND.bind(this);
    this.onGroupSelection = this.onGroupSelection.bind(this);
    this.setErrorGroupColor = this.setErrorGroupColor.bind(this);
    this.onRequestorSelected = this.onRequestorSelected.bind(this);
    this.onRequestorChange = this.onRequestorChange.bind(this);
    this.onTemplateTaskCodeSelected = this.onTemplateTaskCodeSelected.bind(this);
    this.onTemplateTaskCodeChange = this.onTemplateTaskCodeChange.bind(this);
    this.setErrorColorTemplateTaskCode = this.setErrorColorTemplateTaskCode.bind(this);
    this.renderAttributeLov = this.renderAttributeLov.bind(this);
    this.getCompaniesList = this.getCompaniesList.bind(this);
    this.onCompanySelection = this.onCompanySelection.bind(this);
    this.setCompanyColor = this.setCompanyColor.bind(this);
    this.consumerDetailsApi = this.consumerDetailsApi.bind(this);
    this.onImpactedCiChange  = this.onImpactedCiChange .bind(this);
    this.onOpsCatSelection = this.onOpsCatSelection.bind(this);
    this.setOpsCatColor = this.setOpsCatColor.bind(this);
    this.getCompanyMenulist = this.getCompanyMenulist.bind(this);
  }
  onRequestorChange(value) {
    let url = "";
    this.setState({
      RequestedErrorColor: "",
      consumerDataDetails: [],
      // selectedConsumers: [],
      typedRequestor: value,
    });
    if (this.state.hideInput === "Task Template ID") {
      url = GLOBAL.taskTemplatesDetails;
    } else {
      url = GLOBAL.consumerUsersNewUrl;
    }
    if (value.length > 3) {
      let consumerDataDetails = [],
        consumerData = [],
        myObj = {},
        strObj = {};
      (strObj.like = value),
        (strObj.companyId = this.props.slaDefEditDetails.companyId),
        (strObj.status = "Active"),
        (strObj = JSON.stringify(strObj));
      axios.get(url, { headers: { query: strObj } }).then((response) => {
        consumerData = response.data;
        if (Array.isArray(response.data) && this.state.typedRequestor !== "") {
          for (let i = 0; i < consumerData.length; i++) {
            myObj = { value: "", label: "" };
            myObj.id = consumerData[i].userId;
            myObj.label =
              consumerData[i].fullname +
              "|" +
              consumerData[i].userId +
              "|" +
              consumerData[i].email;
            myObj.name = consumerData[i].fullname;
            consumerDataDetails.push(myObj);
          }
          consumerDataDetails = Array.from(new Set(consumerDataDetails));
        } else {
          consumerDataDetails = [];
        }
        this.setState({
          consumerDataDetails: consumerDataDetails,
        });
      });
    }
  }
  onTemplateTaskCodeChange(value) {
    this.setState({
      templateTaskCodeErrorColor: "",
      templateTaskCodeDataDetails: [],
      selectedTemplateTaskCode: [],
      typedTemplateTaskCode: value,
    });
    if (value.length >= 15) {
      let templateTaskCodeDataDetails = [],
        consumerData = [],
        myObj = {},
        strObj = {};
        (strObj.companyId = companyId),
        (strObj.templateTaskCode = value),
        (strObj = JSON.stringify(strObj));
      axios.get("/api/templateTaskCodeByCompanyId", { headers: { query: strObj } }).then((response) => {
        consumerData = response.data;
        if (Array.isArray(response.data) && this.state.typedTemplateTaskCode !== "") {
          for (let i = 0; i < consumerData.length; i++) {
            myObj = { id: "", value: "", label: "" };
            myObj.id = consumerData[i].template_task_code;
            myObj.value = consumerData[i].template_task_code;
            myObj.label = consumerData[i].template_task_code;
            templateTaskCodeDataDetails.push(myObj);
          }
          templateTaskCodeDataDetails = Array.from(new Set(templateTaskCodeDataDetails));
        } else {
          templateTaskCodeDataDetails = [];
        }
        this.setState({
          templateTaskCodeDataDetails: templateTaskCodeDataDetails,
        });
      });
    }
  }
  formToggleFun(value) {
    this.setState({
      formToggle: value,
      slaCriteriaTypeId: "",
      conditionName: false,
    });
    this.setState({ formeditToggle: false });
    this.setState({
      formaddToggle: false,
      showSubmit: false,
      slaCriteriaAttribute: "",
      slaCriteriaOperator: "",
      serviceErrorColor: "",
      holdReasonErrorColor: "",
      ImpactedErrorColor: "",
      RequestedErrorColor: "",
      companyError: "",
      slaCriteriaValue: "",
      enableEdit: false,
      ANDshowOR: false,
      editTypeHead: "",
      selectedServicesList: [],
      selectedImpactedCILists: [],
      selectedConsumers: [],
      selectedCompaniesList: [],
      selectedGroupsList: [],
      ciListBasedOnClass: [],
      classIsSelectedOrNot: "",
      duplicateExists: false,
      consumerDataDetails: [],
      typedRequestor: "",
      selectedTemplateTaskCode: [],
      templateTaskCodeDataDetails: [],
      typedTemplateTaskCode: "",
      templateTaskCodeErrorColor: "",
      impactedCiData: []
    });
    this.setState({
      hideInput: "",
      operatorSelected: "",
      slaCriteriaValueText: "",
      taskTypeAttributeValue: ""
    });
    (GLOBAL.slaCriteriaTypeIdErrorColor = ""),
      (GLOBAL.slaCriteriaAttributeErrorColor = ""),
      (GLOBAL.slaCriteriaOperatorErrorColor = ""),
      (GLOBAL.slaCriteriaValueErrorColor = "");
    this.props.dispatch(change("popUpAttribute", "slaCriteriaTypeId", ""));
    this.props.dispatch(change("popUpAttribute", "slaCriteriaAttribute", ""));
    this.props.dispatch(change("popUpAttribute", "slaCriteriaOperator", ""));
    this.props.dispatch(change("popUpAttribute", "slaCriteriaValue", ""));
  }

  componentWillMount() {
    this.props.loadSlaAttributesList(this.props.slaDefEditDetails.slaId);
    this.props.loadSlaCriteriaType("SLADEF", "criteriatype");
    this.props.loadSlaOperator("SLADEF", "operator");
    this.props.getAdminPrefLevel(this.props.slaDefEditDetails.companyId);
    this.props.loadSlaDefActiveMandatory(this.props.slaDefEditDetails.slaId);
    if (this.props.moduleId == "5") {
      this.props.loadSlaAttributes("Breakfix", "Attribute");
      this.props.loadSlaModuleStatus("Breakfix");
      this.props.editSLA("Breakfix");
      this.props.loadFulfillmentReasonCode(this.props.slaDefEditDetails.companyId,this.props.slaDefEditDetails.moduleName,"On Hold","1" );
      this.getCompaniesList(
        this.props.slaDefEditDetails.companyId,
        "Service Provider,Service Supporter"
      );
    } else if (this.props.moduleId == "10") {
      this.props.loadSlaAttributes("Investigation", "Attribute");
      this.props.editSLA("Investigation");
      this.props.loadSlaModuleStatus("Investigation");
      this.getCompaniesList(
        this.props.slaDefEditDetails.companyId,
        "Service Provider,Service Supporter"
      );
    } else if (this.props.moduleId == "15") {
      this.props.loadSlaAttributes("Fullfilment", "Attribute");
      this.props.editSLA("Fullfilment");
      this.props.loadSlaModuleStatus("Fullfilment");
      this.props.loadFulfillmentReasonCode(this.props.slaDefEditDetails.companyId,this.props.slaDefEditDetails.moduleName,"On Hold","1" );
      this.getCompaniesList(
        this.props.slaDefEditDetails.companyId,
        "Service Provider,Service Supporter"
      );
    } else if (this.props.moduleId == "20") {
      this.props.loadSlaAttributes("ChangeManagement", "Attribute");
      this.props.editSLA("ChangeManagement");
      this.props.loadSlaModuleStatus("ChangeManagement");
      this.getCompaniesList(
        this.props.slaDefEditDetails.companyId,
        "Service Provider,Service Supporter"
      );
    } else if (this.props.moduleId == "25") {
      this.props.loadSlaAttributes("Task", "Attribute");
      //this.props.loadSlaModuleStatus("Task");
      //this.props.editSLA("Task");
    } else if (this.props.moduleId == "30") {
      this.props.loadSlaAttributes("ServiceAvailability", "Attribute");
      this.props.loadSlaModuleStatus("ServiceAvailability");
    }

    let impactedServiceData = [],
      myObj0 = {},
      impactedServiceDataDetails = [],
      str = {},
      strObj = {},
      groupData = [],
      ciData = [],
      classList = [],
      taskStatusList = [];
    (str.companyId = this.props.slaDefEditDetails.companyId),
      (str = JSON.stringify(str));
    (strObj.like = ""),
      (strObj.companyId = this.props.slaDefEditDetails.companyId),
      (strObj = JSON.stringify(strObj));

    axios
      .get("/api/foundations/serviceListSelfServiceCompanyId/", {
        headers: { query: str },
      })
      .then((response) => {
        impactedServiceData = response.data;
        if (Array.isArray(response.data)) {
          for (let i = 0; i < impactedServiceData.length; i++) {
            myObj0 = {
              value: "",
              label: "",
              companyname: "",
              categoryid: "",
              categoryname: "",
            };
            myObj0.id = impactedServiceData[i].offeringId;
            myObj0.label = impactedServiceData[i].offeringName;
            impactedServiceDataDetails.push(myObj0);
          }
          impactedServiceDataDetails = Array.from(
            new Set(impactedServiceDataDetails)
          );
        }
        this.setState({
          impactedServiceDataDetails: impactedServiceDataDetails,
        });
      });

    axios
      .get(
        "/asset-config/api/getCiSupportAllGroup/id/" +
          this.props.slaDefEditDetails.companyId
      )
      .then((response) => {
        if (Array.isArray(response.data)) {
          response.data.forEach((item, itrNo) => {
            groupData.push({
              id: item.groupId,
              label: item.groupName,
              companyId: item?.companyId || "",
              companyName: item?.companyName || "",
            });
          });
        }
        this.setState({ GroupList: groupData });
      });

    axios
      .get(
        GLOBAL.ciNameDetailsUrl +
          "?searchByList=STATUS&multipleValueList=7&searchValue=&companyId=" +
          this.props.slaDefEditDetails.companyId
      )
      .then((response) => {
        if (Array.isArray(response.data)) {
          response.data.forEach((item) => {
            let serialNumber = item.SERIAL_NUMBER ? ` | ${item.SERIAL_NUMBER}` : ` `
            ciData.push({ id: item.CI_ID, label: `${item.CI_NAME}${serialNumber}` });
          });
        }
        this.setState({ impactedCICollections: ciData });
      });


      let strObjComp = {};
      strObjComp.company_id = this.props.slaDefEditDetails.companyId;
      strObjComp = JSON.stringify(strObjComp);

    axios.get("/api/classes", { headers: { query: strObjComp } }).then((response) => {
      if (Array.isArray(response.data)) {
        response.data.forEach((item) => {
          classList.push({ value: item.CLASS_ID, text: item.CLASS_NAME });
        });
      }
      this.setState({ classList: classList });
    });

    axios.get(GLOBAL.getTaskStatusForEdit).then((response) => {
      if (Array.isArray(response.data)) {
        response.data.forEach((item) => {
          taskStatusList.push({ value: item.id, text: item.status });
        });
      }
      this.setState({ taskStatusList: taskStatusList });
    });

    axios
      .get("/asset-config/api/getCiBilling")
      .then((responseBusinessCriticalityValues) => {
        let businessCriticality = [];
        responseBusinessCriticalityValues.data.forEach((item) => {
          businessCriticality.push({
            value: item.FIELD1_VALUE,
            text: item.FIELD1_KEY,
          });
        });
        this.setState({ businessCriticalityValue: businessCriticality });
      });
  }
  changeForm(e) {
    if (
      (e === "Priority" || e === "Parent.Priority") &&
      (this.props.slaDefEditDetails.moduleName === "Incident" ||
        this.props.slaDefEditDetails.moduleName === "Problem" ||
        this.props.slaDefEditDetails.moduleName === "Task")
    ) {
      this.setState({
        hideInput: false,
        prioritydrpdwn: [
          { value: "P1", text: "P1" },
          { value: "P2", text: "P2" },
          { value: "P3", text: "P3" },
          { value: "P4", text: "P4" },
        ],
      });
      this.props.dispatch(change("popUpAttribute", "slaCriteriaValue", ""));
    } else if (e === "Service") {
      this.setState({ hideInput: "Service", selectedServicesList: [] });
    } else if (e === "Impacted CI") {
      this.setState({ hideInput: "Impacted CI", selectedImpactedCILists: [] });
    } else if (e === "Requestor") {
      this.setState({
        hideInput: "Requestor",
        selectedConsumers: [],
        consumerDataDetails: [],
        typedRequestor: "",
      });
    } else if (e === "Task Template ID") {
      this.setState({
        hideInput: "Task Template ID",
        selectedConsumers: [],
        consumerDataDetails: [],
        typedRequestor: "",
      });
    } else if (e === "Assignment Group") {
      this.setState({ hideInput: "Assignment Group", selectedGroupsList: [] });
    } else if (e === "Class") {
      this.setState({ hideInput: "Class" });
      this.props.dispatch(change("popUpAttribute", "slaCriteriaValue", ""));
    } else if (e === "Work Item") {
      this.setState({ hideInput: "Work Item" });
      this.props.dispatch(change("popUpAttribute", "slaCriteriaValue", ""));
    } else if (e === "Task Type") {
      this.setState({ hideInput: "Task Type" });
      this.props.dispatch(change("popUpAttribute", "slaCriteriaValue", ""));
    } else if (e === "Support Company") {
      this.setState({
        hideInput: "Support Company",
        selectedCompaniesList: [],
      });
    } else if (e === "Status") {
      this.setState({ hideInput: true });
      this.props.dispatch(change("popUpAttribute", "slaCriteriaValue", ""));
    } else if (e === "Business Criticality") {
      this.setState({ hideInput: "Business Criticality" });
      this.props.dispatch(change("popUpAttribute", "slaCriteriaValue", ""));
    } else if (e === "Hold Reason") {
      this.setState({ hideInput: "Hold Reason", selectedReasonList: [] });
    } else if (e === "Template Task Code") {
      this.setState({
        hideInput: "Template Task Code",
        selectedTemplateTaskCode: [],
        templateTaskCodeDataDetails: [],
        typedTemplateTaskCode: ""
      });
    } else if (e === "Category") {
      this.setState({ hideInput: "Category", operationalCategoryValues: [] });
      this.props.dispatch(change("popUpAttribute", "slaCriteriaValue", ""));
      this.props.getcompanyMenuListAction(this.props.slaDefEditDetails.companyId, this.props.slaDefEditDetails.moduleName, "Category", '1', '0');
    } else if (e === "Sub Category") {
      this.setState({ hideInput: "Sub Category", operationalCategoryValues: [] });
      this.props.dispatch(change("popUpAttribute", "slaCriteriaValue", ""));
      this.props.getcompanyMenuListAction(this.props.slaDefEditDetails.companyId, this.props.slaDefEditDetails.moduleName, "Sub Category", '1', '0');
    } else if (e === "Type") {
      this.setState({ hideInput: "Type", operationalCategoryValues: [] });
      this.props.dispatch(change("popUpAttribute", "slaCriteriaValue", ""));
      this.props.getcompanyMenuListAction(this.props.slaDefEditDetails.companyId, this.props.slaDefEditDetails.moduleName, "Type", '1', '0');
    }
  }

  renderTypeLov(typeData) {
    if (typeData.length == "" || typeData.length == undefined) {
      return null;
    }
    return typeData.map((objectData) => {
      return (
        <option value={objectData.field1Value}>{objectData.field1Key}</option>
      );
    });
  }

  renderOperatorLov(operatorData) {
    if (operatorData.length == "" || operatorData.length == undefined) {
      return null;
    }
    return operatorData.map((objectData) => {
      return (
        <option value={objectData.field1Key}>{objectData.field1Key}</option>
      );
    });
  }

  renderAttributeLov(attributeData) {
    let isStatusInPending = false;
    const slaPauseData =
      this.props.slaAttributesList && this.props.slaAttributesList["Pause"];
    if (slaPauseData && slaPauseData.length > 0) {
      slaPauseData.forEach((item) => {
        if (
          item.attributeName === "Status" &&
          (item.attributeDisplayValue === "On Hold" ||
            item.attributeDisplayValue === "Pending")
        ) {
          isStatusInPending = true;
        }
      });
    }

    if (
      this.state.joinCondition === "AND" &&
      this.props.slaSelectedAttributesList !== "" &&
      this.state.typeValue !== "" &&
      typeof this.props.slaSelectedAttributesList[this.state.typeValue] !==
        "undefined"
    ) {
      return attributeData.map((objectData) => {
        if (
          this.props.slaSelectedAttributesList[this.state.typeValue][
            objectData.field1Key
          ] !== objectData.field1Key &&
          objectData.field1Key === "Priority" &&
          (this.props.slaDefEditDetails.moduleName === "Incident" ||
            this.props.slaDefEditDetails.moduleName === "Problem") &&
          ((isStatusInPending && objectData.field1Key !== "Status") ||
            (!isStatusInPending && objectData.field1Key !== "Hold Reason"))
        ) {
          return (
            <option value={objectData.field1Value}>
              {objectData.field1Key}
            </option>
          );
        } else if (
          this.props.slaSelectedAttributesList[this.state.typeValue][
            objectData.field1Key
          ] !== objectData.field1Key &&
          objectData.field1Key !== "Priority" &&
          ((isStatusInPending && objectData.field1Key !== "Status") ||
            (!isStatusInPending && objectData.field1Key !== "Hold Reason")) &&
            ((this.props.opsCategoryLevels == 0 && (objectData.field1Key !== "Category" && objectData.field1Key !== "Sub Category" && objectData.field1Key !== "Type"))
           ||(this.props.opsCategoryLevels == 1 && (objectData.field1Key !== "Sub Category" && objectData.field1Key !== "Type"))
           ||(this.props.opsCategoryLevels == 2 && (objectData.field1Key !== "Type"))
           ||(this.props.opsCategoryLevels == 3))
        ) {
          return (
            <option value={objectData.field1Value}>
              {objectData.field1Key}
            </option>
          );
        }
      });
    } else if (
      this.state.joinCondition === "OR" &&
      this.props.slaSelectedAttributesList !== "" &&
      this.state.typeValue !== "" &&
      typeof this.props.slaSelectedAttributesList[this.state.typeValue] !==
        "undefined"
    ) {
      return attributeData.map((objectData) => {
        if (
          this.props.slaSelectedAttributesList[this.state.typeValue][
            objectData.field1Key
          ] === objectData.field1Key &&
          objectData.field1Key === "Priority" &&
          (this.props.slaDefEditDetails.moduleName === "Incident" ||
            this.props.slaDefEditDetails.moduleName === "Problem") &&
          !isStatusInPending &&
          objectData.field1Key !== "Hold Reason" &&
          ((this.props.opsCategoryLevels == 0 && (objectData.field1Key !== "Category" && objectData.field1Key !== "Sub Category" && objectData.field1Key !== "Type"))
         ||(this.props.opsCategoryLevels == 1 && (objectData.field1Key !== "Sub Category" && objectData.field1Key !== "Type"))
         ||(this.props.opsCategoryLevels == 2 && (objectData.field1Key !== "Type"))
         ||(this.props.opsCategoryLevels == 3))
        ) {
          return (
            <option value={objectData.field1Value}>
              {objectData.field1Key}
            </option>
          );
        } else if (
          this.props.slaSelectedAttributesList[this.state.typeValue][
            objectData.field1Key
          ] === objectData.field1Key &&
          objectData.field1Key !== "Priority" &&
          (isStatusInPending /* && objectData.field1Key !== "Status" */ ||
            (!isStatusInPending && objectData.field1Key !== "Hold Reason")&&
            ((this.props.opsCategoryLevels == 0 && (objectData.field1Key !== "Category" && objectData.field1Key !== "Sub Category" && objectData.field1Key !== "Type"))
           ||(this.props.opsCategoryLevels == 1 && (objectData.field1Key !== "Sub Category" && objectData.field1Key !== "Type"))
           ||(this.props.opsCategoryLevels == 2 && (objectData.field1Key !== "Type"))
           ||(this.props.opsCategoryLevels == 3))
          )
        ) {
          return (
            <option value={objectData.field1Value}>
              {objectData.field1Key}
            </option>
          );
        }
      });
    } else {
      return attributeData.map((objectData) => {
        if (objectData.field1Key !== "Hold Reason") {
          if (
            objectData.field1Key === "Priority" &&
            (this.props.slaDefEditDetails.moduleName === "Incident" ||
              this.props.slaDefEditDetails.moduleName === "Problem") &&
              ((this.props.opsCategoryLevels == 0 && (objectData.field1Key !== "Category" && objectData.field1Key !== "Sub Category" && objectData.field1Key !== "Type"))
             ||(this.props.opsCategoryLevels == 1 && (objectData.field1Key !== "Sub Category" && objectData.field1Key !== "Type"))
             ||(this.props.opsCategoryLevels == 2 && (objectData.field1Key !== "Type"))
             ||(this.props.opsCategoryLevels == 3))
          ) {
            return (
              <option value={objectData.field1Value}>
                {objectData.field1Key}
              </option>
            );
          } else if (objectData.field1Key !== "Priority" &&
            ((this.props.opsCategoryLevels == 0 && (objectData.field1Key !== "Category" && objectData.field1Key !== "Sub Category" && objectData.field1Key !== "Type"))
           ||(this.props.opsCategoryLevels == 1 && (objectData.field1Key !== "Sub Category" && objectData.field1Key !== "Type"))
           ||(this.props.opsCategoryLevels == 2 && (objectData.field1Key !== "Type"))
           ||(this.props.opsCategoryLevels == 3))) {
            return (
              <option value={objectData.field1Value}>
                {objectData.field1Key}
              </option>
            );
          }
        }
      });
    }
  }

  changeTypeValue(val) {
    this.setState({ typeValue: val === "select.." ? "" : val });
  }

  renderAttList(attributeData) {
    if (this.props.slaCriteriaType) {
      return this.props.slaCriteriaType.map((item) => {
        return attributeData[item.field1Key].map((subItem, itr) => {
          let attributes = subItem.attributeDisplayValue?.split(",");
          if (attributes) {
            let len = attributes.length;
          if (subItem.length === 0) return;
          else
            return (
              <tr>
                <td>{itr === 0 ? subItem.conditionName : ""}</td>
                <td>{subItem.attributeName}</td>
                <td>{subItem.operator}</td>
                {subItem.attributeName === "Impacted CI" ? (
                  <td>
                 {attributes.map((attr, index) => {
                    let attribute = attr.split("|");
                    let attLen=attribute.length
                    return attribute.map((value, i) => {
                      if (i == 1) {
                        return index == len-1 ? <div className="tbSerialTxt">Serial Number - {(value=="" || value==null) ? 'NA': value}</div> :(<div className="tbSerialTxt">Serial Number - {(value=="" || value==null) ? 'NA': value},</div>);
                      } else {
                        return  index == len-1 || attLen > 1 ? <div>{value}</div> : <div>{value},</div>;
                      }
                    })
                  })}
                </td>
                ) : (
                  <td>{subItem.attributeDisplayValue}</td>
                )}
                <td>{subItem.joinCondition}</td>
                {/* <td className="text-c f-size-13">
                          <i onClick={() => {
                            this.state.showSubmit || this.state.ANDshowOR || this.state.enableEdit ? '' : this.editDetails(subItem);
                          }}
                            className="fa fa fa-pencil"
                          ></i>
                        </td> */}
                <td className="text-c f-size-13">
                  <i
                    onClick={() => {
                      if (
                        !(
                          this.state.showSubmit ||
                          this.state.ANDshowOR ||
                          this.state.enableEdit
                        )
                      )
                        this.attDelete(subItem.slaRuleId, subItem.slaId);
                    }}
                    className="fa fa-trash-o cursorPoint"
                  />
                </td>
              </tr>
            );
          }
        });
      });
    }
  }

  saveAtt(values) {
    const { reset, showProcess } = this.props;
    let ref = this;
    console.log("values1", values);
    return sleep(1000).then(() => {
      if (`${JSON.stringify(values, null, 2)}` == "{}") {
        throw new SubmissionError({
          _error:
            "The form has missing required fields, please revalidate the highlighted fields and update the form",
        });
      } else if (
        values.slaCriteriaTypeId == "" ||
        values.slaCriteriaAttribute == "" ||
        values.slaCriteriaOperator == "" ||
        (values.slaCriteriaValue === "" &&
          (this.state.hideInput == true ||
            this.state.hideInput == false ||
            this.state.hideInput === "Class" ||
            this.state.hideInput === "Task Type" ||
            this.state.hideInput === "Work Item" ||
            this.state.hideInput === "Business Criticality")) ||
        (this.state.selectedImpactedCILists.length === 0 &&
          this.state.hideInput === "Impacted CI") ||
        (this.state.selectedConsumers.length === 0 &&
          this.state.hideInput === "Requestor") ||
        (this.state.selectedConsumers.length === 0 &&
          this.state.hideInput === "Task Template ID") ||
        (this.state.slaCriteriaValueText === "" && 
          this.state.hideInput === "Task Type") ||
        (this.state.selectedTemplateTaskCode.length === 0 &&
          this.state.hideInput === "Template Task Code") ||
        (this.state.selectedServicesList.length === 0 &&
          this.state.hideInput === "Service") ||
        (this.state.selectedGroupsList.length === 0 &&
          this.state.hideInput === "Assignment Group") ||
        (this.state.selectedReasonList.length === 0 &&
          this.state.hideInput === "Hold Reason") ||
        (this.state.operationalCategoryValues.length === 0 &&
          this.state.hideInput === "Category") ||
        (this.state.operationalCategoryValues.length === 0 &&
          this.state.hideInput === "Sub Category") ||
       (this.state.operationalCategoryValues.length === 0 &&
            this.state.hideInput === "Type") ||
        (this.state.selectedCompaniesList.length === 0 &&
          this.state.hideInput === "Support Company")
      ) {
        if (values.slaCriteriaTypeId == "") {
          GLOBAL.slaCriteriaTypeIdErrorColor = "error";
        } else {
          GLOBAL.slaCriteriaTypeIdErrorColor = "";
        }
        if (values.slaCriteriaAttribute == "") {
          GLOBAL.slaCriteriaAttributeErrorColor = "error";
        } else {
          GLOBAL.slaCriteriaAttributeErrorColor = "";
        }
        if (values.slaCriteriaOperator == "") {
          GLOBAL.slaCriteriaOperatorErrorColor = "error";
        } else {
          GLOBAL.slaCriteriaOperatorErrorColor = "";
        }
        if (values.slaCriteriaValue == "") {
          GLOBAL.slaCriteriaValueErrorColor = "error";
        } else {
          GLOBAL.slaCriteriaValueErrorColor = "";
        }

        if (this.state.selectedImpactedCILists.length === 0) {
          this.setState({ ImpactedErrorColor: "error" });
        } else {
          this.setState({ ImpactedErrorColor: "" });
        }
        if (this.state.selectedConsumers.length === 0) {
          this.setState({ RequestedErrorColor: "error" });
        } else {
          this.setState({ RequestedErrorColor: "" });
        }
        if (this.state.selectedTemplateTaskCode.length === 0) {
          this.setState({ templateTaskCodeErrorColor: "error" });
        } else {
          this.setState({ templateTaskCodeErrorColor: "" });
        }
        if (this.state.selectedServicesList.length === 0) {
          this.setState({ serviceErrorColor: "error" });
        } else {
          this.setState({ serviceErrorColor: "" });
        }
        if (this.state.selectedGroupsList.length === 0) {
          this.setState({ AssignedGroupErrorColor: "error" });
        } else {
          this.setState({ AssignedGroupErrorColor: "" });
        }
        if (this.state.selectedReasonList.length === 0) {
          this.setState({ holdReasonErrorColor: "error" });
        } else {
          this.setState({ holdReasonErrorColor: "" });
        }
        if (this.state.selectedCompaniesList.length === 0) {
          this.setState({ companyError: "error" });
        } else {
          this.setState({ companyError: "" });
        }
        throw new SubmissionError({
          _error:
            "The form has missing required fields, please revalidate the highlighted fields and update the form !",
        });
      } else {
        let multiValueCollections = { id: "", value: "" };
        let opsCatValueCollections = { id: "", value: "" };
        if (this.state.hideInput === "Support Company") {
          this.state.selectedCompaniesList.forEach((item) => {
            (multiValueCollections.id += "," + item.id),
              (multiValueCollections.value += "," + item.label);
          });
        } else if (this.state.hideInput === "Service") {
          this.state.selectedServicesList.forEach((item) => {
            (multiValueCollections.id += "," + item.id),
              (multiValueCollections.value += "," + item.label);
          });
        } else if (this.state.hideInput === "Hold Reason") {
          this.state.selectedReasonList.forEach((item) => {
            (multiValueCollections.id += "," + item.id),
              (multiValueCollections.value += "," + item.label);
          });
        }
        else if (this.state.hideInput === "Impacted CI") {
           this.state.selectedImpactedCILists.forEach((item) => {
            (multiValueCollections.id += "," + item?.ciId),
            (multiValueCollections.value += "," + item?.ciName)
          })
        }
        else if (this.state.hideInput === "Requestor") {
          this.state.selectedConsumers.forEach((item) => {
            (multiValueCollections.id += "," + item.id),
              (multiValueCollections.value += "," + item.name);
          });
        } else if (this.state.hideInput === "Task Template ID") {
          this.state.selectedConsumers.forEach((item) => {
            (multiValueCollections.id += "," + item.id),
              (multiValueCollections.value += "," + item.name);
          });
        } else if (this.state.hideInput === "Assignment Group") {
          this.state.selectedGroupsList.forEach((item) => {
            (multiValueCollections.id += "," + item.id),
              (multiValueCollections.value += "," + item.label);
          });
        } else if (this.state.hideInput == "Category" ||
          this.state.hideInput == "Sub Category" || 
          this.state.hideInput == "Type"){
          this.state.operationalCategoryValues.forEach((item) => {
            opsCatValueCollections.id =   item.id
            opsCatValueCollections.value =  item.label;
         });
       }
        (multiValueCollections.id = multiValueCollections.id.substr(
          1,
          multiValueCollections.id.length - 1
        )),
          (multiValueCollections.value = multiValueCollections.value.substr(
            1,
            multiValueCollections.value.length - 1
          ));
        if (this.state.hideInput === "Class") {
          this.state.classList.forEach((item) => {
            if (this.state.slaCriteriaValueText == item.value)
              this.state.slaCriteriaValueText = item.text;
          });
        }
        if (this.state.hideInput == true && this.props.moduleId == "25") {
          this.state.taskStatusList.forEach((item) => {
            if (this.state.slaCriteriaValueText == item.value)
              this.state.slaCriteriaValueText = item.text;
          });
        }
        if (this.state.hideInput === "Work Item") {
          this.state.workItemList.forEach((item) => {
            if (this.state.slaCriteriaValueText == item.value)
              this.state.slaCriteriaValueText = item.text;
          });
        }
        if (this.state.hideInput === "Task Type") {
          this.state.taskTypeList.forEach((item) => {
            if (this.state.slaCriteriaValueText == item.value)
              this.state.slaCriteriaValueText = item.text;
          });
        } else if (this.state.hideInput === "Business Criticality") {
          this.state.businessCriticalityValue.forEach((item) => {
            if (this.state.slaCriteriaValueText == item.value)
              this.state.slaCriteriaValueText = item.text;
          });
        } 

         else if (this.state.hideInput == true) {
          this.state.slaLovList.forEach((item) => {
            if (this.state.slaCriteriaValueText == item.value)
              this.state.slaCriteriaValueText = item.text;
          });
        }
        let slaId = "";
        slaId = values.slaDefId;
        let postjson = {
          slaId: values.slaDefId,
          conditionName: values.slaCriteriaTypeName,
          conditionId: values.slaCriteriaTypeId,
          attributeName: values.slaCriteriaAttributeName,
          attributeId: values.slaCriteriaAttribute,
          operator: values.slaCriteriaOperator,
          attributeValue:
            this.state.hideInput === "Service" ||
            this.state.hideInput === "Hold Reason" ||
            this.state.hideInput === "Impacted CI" ||
            this.state.hideInput === "Requestor" ||
            this.state.hideInput === "Task Template ID" ||
            this.state.hideInput === "Assignment Group" ||
            this.state.hideInput === "Support Company"
              ? multiValueCollections.id
              : this.state.hideInput === "Template Task Code" ?  this.state.selectedTemplateTaskCode[0].id : 
              (this.state.hideInput === "Category"
                || this.state.hideInput ==="Sub Category"
                || this.state.hideInput ==="Type") ?  
                opsCatValueCollections.id : this.state.hideInput == "Task Type" ? this.state.taskTypeAttributeValue : values.slaCriteriaValue,
          createdBy: "",
          createdByName: "",
          attributeDisplayValue:
            this.state.hideInput === "Service" ||
            this.state.hideInput === "Hold Reason" ||
            this.state.hideInput === "Impacted CI" ||
            this.state.hideInput === "Requestor" ||
            this.state.hideInput === "Task Template ID" ||
            this.state.hideInput === "Assignment Group" ||
            this.state.hideInput === "Support Company"
              ? multiValueCollections.value
              : this.state.hideInput === "Template Task Code" ?  this.state.selectedTemplateTaskCode[0].value : 
              (this.state.hideInput === "Category"
                || this.state.hideInput ==="Sub Category"
                || this.state.hideInput ==="Type") ?  
                opsCatValueCollections.value : this.state.slaCriteriaValueText,
          joinCondition:
            this.state.enableEdit == false ? "AND" : values.slaJoinCondition,
        };
        let flag = false;
        this.props.slaAttributesList[postjson.conditionName].forEach((iter) => {
          if (
            iter.attributeName === postjson.attributeName &&
            iter.operator === postjson.operator &&
            iter.attributeValue == postjson.attributeValue
          )
            flag = true;
        });
        if (flag == true) {
          this.setState({ duplicateExists: true });
          return;
        }
        this.setState({ showSubmit: true });
        if (this.state.editTypeHead) {
          axios
            .patch(GLOBAL.slaAttrUpdate + values.slaRuleID, postjson)
            .then(function (response) {
              if (response.status == 200 || response.status == 201) {
                ref.setState({
                  formToggle: false,
                  slaCriteriaTypeId: "",
                  slaCriteriaAttribute: "",
                  slaCriteriaOperator: "",
                  slaCriteriaValue: "",
                  attributeDisplayValue: "",
                  enableEdit: false,
                });
                GLOBAL.slaCriteriaTypeIdErrorColor = "";
                GLOBAL.slaCriteriaAttributeErrorColor = "";
                GLOBAL.slaCriteriaOperatorErrorColor = "";
                GLOBAL.slaCriteriaValueErrorColor = "";
                ref.setState({ formeditToggle: false });
                ref.setState({ formaddToggle: true });
                ref.props.loadSlaAttributesList(ref.props.slaDefId);
                ref.props.loadSlaDefActiveMandatory(ref.props.slaDefId);
                reset();
              }
              ref.setState({
                showSubmit: false,
                hideInput: "",
                operatorSelected: "",
                slaCriteriaValueText: "",
                taskTypeAttributeValue: "",
                editTypeHead: "",
                selectedServicesList: [],
                selectedReasonList: [],
                duplicateExists: false,
                joinCondition: "AND",
                typeValue: "",
                selectedImpactedCILists: [],
                selectedConsumers: [],
                selectedCompaniesList: [],
                consumerDataDetails: [],
                selectedGroupsList: [],
                typedRequestor: "",
                selectedTemplateTaskCode: [],
                templateTaskCodeDataDetails: [],
                typedTemplateTaskCode: ""
              });
              (GLOBAL.slaCriteriaTypeIdErrorColor = ""),
                (GLOBAL.slaCriteriaAttributeErrorColor = ""),
                (GLOBAL.slaCriteriaOperatorErrorColor = ""),
                (GLOBAL.slaCriteriaValueErrorColor = "");
              ref.props.dispatch(
                change("popUpAttribute", "slaCriteriaTypeId", "")
              );
              ref.props.dispatch(
                change("popUpAttribute", "slaCriteriaAttribute", "")
              );
              ref.props.dispatch(
                change("popUpAttribute", "slaCriteriaOperator", "")
              );
              ref.props.dispatch(
                change("popUpAttribute", "slaCriteriaValue", "")
              );
            })
            .catch((err) => {
              ref.setState({ ANDshowOR: false });
            });
        } else {
          axios
            .post(GLOBAL.slaCriteriaPostUrl, postjson)
            .then(function (response) {
              if (response.status == 200 || response.status == 201) {
                ref.setState({
                  formToggle: false,
                  slaCriteriaTypeId: "",
                  slaCriteriaAttribute: "",
                  slaCriteriaOperator: "",
                  slaCriteriaValue: "",
                  attributeDisplayValue: "",
                  enableEdit: false,
                  selectedSlaCriticalValue: []
                });
                GLOBAL.slaCriteriaTypeIdErrorColor = "";
                GLOBAL.slaCriteriaAttributeErrorColor = "";
                GLOBAL.slaCriteriaOperatorErrorColor = "";
                GLOBAL.slaCriteriaValueErrorColor = "";
                ref.setState({ formeditToggle: false });
                ref.setState({ formaddToggle: true });
                ref.props.loadSlaAttributesList(ref.props.slaDefId);
                ref.props.loadSlaDefActiveMandatory(ref.props.slaDefId);
                reset();
              }
              ref.setState({
                showSubmit: false,
                hideInput: "",
                operatorSelected: "",
                slaCriteriaValueText: "",
                taskTypeAttributeValue: "",
                editTypeHead: "",
                selectedServicesList: [],
                selectedReasonList: [],
                duplicateExists: false,
                joinCondition: "AND",
                typeValue: "",
                selectedImpactedCILists: [],
                selectedConsumers: [],
                selectedCompaniesList: [],
                consumerDataDetails: [],
                selectedGroupsList: [],
                typedRequestor: "",
                selectedTemplateTaskCode: [],
                templateTaskCodeDataDetails: [],
                typedTemplateTaskCode: ""
              });
              (GLOBAL.slaCriteriaTypeIdErrorColor = ""),
                (GLOBAL.slaCriteriaAttributeErrorColor = ""),
                (GLOBAL.slaCriteriaOperatorErrorColor = ""),
                (GLOBAL.slaCriteriaValueErrorColor = "");
              ref.props.dispatch(
                change("popUpAttribute", "slaCriteriaTypeId", "")
              );
              ref.props.dispatch(
                change("popUpAttribute", "slaCriteriaAttribute", "")
              );
              ref.props.dispatch(
                change("popUpAttribute", "slaCriteriaOperator", "")
              );
              ref.props.dispatch(
                change("popUpAttribute", "slaCriteriaValue", "")
              );
            })
            .catch(function (error) {
              ref.setState({ showSubmit: false });
            });
        }
      }
    });
  }
  attDelete(attid, slaId) {
    let ref = this;
    axios.delete(GLOBAL.slaCriteriaDeletetUrl + attid, {}).then((response) => {
      ref.setState({ formeditToggle: false, formToggle: false, typeValue: "" });
      ref.props.loadSlaAttributesList(slaId);
      ref.props.loadSlaDefActiveMandatory(slaId);
      ref.props.dispatch(
        change("xsmSlaDefEditForm", "slaStatusActualName", "")
      );
      ref.props.dispatch(change("xsmSlaDefEditForm", "slaStatusValue", ""));
    });
  }

  editDetails(details) {
    this.setState({
      conditionName: true,
      formToggle: true,
      slaCriteriaTypeId: "",
      slaCriteriaAttribute: "",
      slaCriteriaOperator: "",
      serviceErrorColor: "",
      ImpactedErrorColor: "",
      RequestedErrorColor: "",
      companyError: "",
      slaCriteriaValue: "",
      typeValue: details,
      enableEdit: true,
      operatorSelected: details.operator,
      slaCriteriaValueText:
        details.attributeName === "Class" ||
        details.attributeName === "Task Type" ||
        details.attributeName === "Work Item" ||
        details.attributeName === "Priority" ||
        details.attributeName === "Status"
          ? details.attributeDisplayValue
          : "",
      editTypeHead: true,
      hideInput:
        details.attributeName === "Service"
          ? "Service"
          : details.attributeName === "Impacted CI"
          ? "Impacted CI"
          : details.attributeName === "Requestor"
          ? "Requestor"
          : details.attributeName === "Task Template ID"
          ? "Task Template ID"
          : details.attributeName === "Assignment Group"
          ? "Assignment Group"
          : details.attributeName === "Class"
          ? "Class"
          : details.attributeName === "Business Criticality"
          ? "Business Criticality"
          : details.attributeName === "Hold Reason"
          ? "Hold Reason"
          : details.attributeName === "Priority"
          ? false
          : true,
      consumerDataDetails: [],
      templateTaskCodeDataDetails: [],
      templateTaskCodeErrorColor: ""
    });
    (GLOBAL.slaCriteriaTypeIdErrorColor = ""),
      (GLOBAL.slaCriteriaOperatorErrorColor = ""),
      (GLOBAL.slaCriteriaValueErrorColor = ""),
      (GLOBAL.slaCriteriaAttributeErrorColor = "");
    this.props.dispatch(
      change(
        "popUpAttribute",
        "slaCriteriaTypeId",
        details.conditionId === "1"
          ? "5"
          : details.conditionId === "2"
          ? "10"
          : "20"
      )
    );
    this.props.dispatch(
      change("popUpAttribute", "slaCriteriaTypeName", details.conditionName)
    );
    this.props.dispatch(change("popUpAttribute", "slaDefId", details.slaId));
    this.props.dispatch(
      change(
        "popUpAttribute",
        "slaCriteriaAttributeName",
        details.attributeName
      )
    );
    this.props.dispatch(
      change("popUpAttribute", "slaCriteriaAttribute", details.attributeId)
    );
    this.props.dispatch(
      change("popUpAttribute", "slaCriteriaOperator", details.operator)
    );
    this.props.dispatch(
      change("popUpAttribute", "slaRuleID", details.slaRuleId)
    );
    this.props.dispatch(
      change("popUpAttribute", "slaJoinCondition", details.joinCondition)
    );
    let multiValue = [];
    let idCollection = details.attributeValue.split(",");
    let valueCollection = details.attributeDisplayValue.split(",");
    if (
      details.attributeName === "Service" ||
      details.attributeName === "Impacted CI" ||
      details.attributeName === "Assignment Group"
    ) {
      for (let i = 0; i < idCollection.length; i++) {
        multiValue.push({ id: idCollection[i], label: valueCollection[i] });
      }
    } else if (details.attributeName === "Requestor") {
      for (let i = 0; i < valueCollection.length; i++) {
        for (let j = 0; j < this.state.consumerDataDetails.length; j++) {
          if (this.state.consumerDataDetails[i].name === valueCollection[i]) {
            multiValue.push(this.state.consumerDataDetails[i]);
            break;
          }
        }
      }
    } else if (details.attributeName === "Task Template ID") {
      for (let i = 0; i < valueCollection.length; i++) {
        for (let j = 0; j < this.state.consumerDataDetails.length; j++) {
          if (this.state.consumerDataDetails[i].name === valueCollection[i]) {
            multiValue.push(this.state.consumerDataDetails[i]);
            break;
          }
        }
      }
    }

    if (details.attributeName === "Service") {
      this.setState({ selectedServicesList: multiValue });
    } else if (details.attributeName === "Impacted CI") {
      this.setState({ selectedImpactedCILists: multiValue });
    } else if (details.attributeName === "Requestor") {
      this.setState({ selectedConsumers: multiValue });
    } else if (details.attributeName === "Task Template ID") {
      this.setState({ selectedConsumers: multiValue });
    } else if (details.attributeName === "Assignment Group") {
      this.setState({ selectedGroupsList: multiValue });
    } else {
      this.props.dispatch(
        change("popUpAttribute", "slaCriteriaValue", details.attributeValue)
      );
    }
  }

  validateField(fieldType, event) {
    switch (fieldType) {
      case "slaCriteriaTypeId":
        if (event.target.value == "") {
          this.setState({ slaCriteriaTypeId: "error" });
          GLOBAL.slaCriteriaTypeIdErrorColor = "";
        } else {
          this.setState({ slaCriteriaTypeId: "" });
          GLOBAL.slaCriteriaTypeIdErrorColor = "";
        }
        break;
      case "slaCriteriaAttribute":
        if (event.target.value == "") {
          this.setState({ slaCriteriaAttribute: "error" });
          GLOBAL.slaCriteriaAttributeErrorColor = "";
        } else {
          this.setState({ slaCriteriaAttribute: "" });
          GLOBAL.slaCriteriaAttributeErrorColor = "";
        }
        break;
      case "slaCriteriaOperator":
        if (event.target.value == 0 || event.target.value == "") {
          this.setState({ slaCriteriaOperator: "error" });
          GLOBAL.slaCriteriaOperatorErrorColor = "";
        } else {
          this.setState({ slaCriteriaOperator: "" });
          GLOBAL.slaCriteriaOperatorErrorColor = "";
        }
        break;
      case "slaCriteriaValue":
        if (event.target.value == 0 || event.target.value == "" || event.target.value == undefined) {
          this.setState({ slaCriteriaValue: "error" });
          GLOBAL.slaCriteriaValueErrorColor = "";
        } else {
          this.setState({ slaCriteriaValue: "" });
          GLOBAL.slaCriteriaValueErrorColor = "";
        }
        break;
      default:
    }
  }
  filterDropDownArray(input, module) {
    if (Object.keys(input).length === 0) return;
    let slaIntermed = [];
    input.forEach((item) => {
      slaIntermed.push({ value: item.field1Value, text: item.field1Key });
    });
    this.setState({ slaLovList: slaIntermed });
  }

  onServiceSelection(selectedServices) {
    if (this.state.serviceErrorColor === "error")
      this.setState({
        serviceErrorColor: "",
        selectedServicesList: selectedServices,
      });
    else this.setState({ selectedServicesList: selectedServices });
  }
  onHoldReasonSelection(selectedReasons) {
    if (this.state.holdReasonErrorColor === "error")
      this.setState({
        holdReasonErrorColor: "",
        selectedReasonList: selectedReasons,
      });
    else this.setState({ selectedReasonList: selectedReasons });
  }
  setErrorHoldreasonColor() {
    if (this.state.selectedReasonList.length === 0)
      this.setState({ holdReasonErrorColor: "error" });
    else this.setState({ holdReasonErrorColor: "" });
  }
  setErrorServiceColor() {
    if (this.state.selectedServicesList.length === 0)
      this.setState({ serviceErrorColor: "error" });
    else this.setState({ serviceErrorColor: "" });
  }
  onImpactedSelection(selectedCIs) {
    if (this.state.ImpactedErrorColor === "error")
      this.setState({
        ImpactedErrorColor: "",
        selectedImpactedCILists: selectedCIs,
        impactedCiData: []
      });
    else this.setState({ 
      selectedImpactedCILists: selectedCIs,
      impactedCiData: []
     });
  }

  onImpactedCiChange(value) {
    if (value.length >= 3) {
      this.consumerDetailsApi(value);
    } else {
      this.setState({ impactedCiData: [] });
    }
  };

  onValueChange = (value) => {
    this.onImpactedCiChange(value);
  };

   consumerDetailsApi = (value) => {

    let impactedCiDataDetails = [],
     ciNameData = [],
     ciNameObject = {};
    if (value !== "" && value.length >= 3) {
      axios
        .get(GLOBAL.ciNameDetailsUrl +
          "?searchByList=STATUS,SUB_STATUS&multipleValueList=7|12&searchValue=" +
          value +
          "&companyId=" +
          this.props.slaDefEditDetails.companyId)
        .then((response) => {
          ciNameData = response.data;
          if (Array.isArray(response.data)) {
          for (var i = 0; i < ciNameData.length; i++) {
            ciNameObject = {
              ciId: "",
              label: "",
              ciName: "",
              ciClass: "",
              ciCompany: "",
              ciIdNumber: "",
              serialNumber: "",
            };
            ciNameObject.ciId = ciNameData[i].CI_ID;
            ciNameObject.label =
              ciNameData[i].CI_NAME +
              "|" +
              ciNameData[i].CLASS_NAME +
              "|" +
              ciNameData[i].COMPANY_NAME;
            ciNameObject.ciName = ciNameData[i].CI_NAME;
            ciNameObject.ciClass = ciNameData[i].CLASS_NAME;
            ciNameObject.ciCompany = ciNameData[i].COMPANY_NAME;
            ciNameObject.ciIdNumber = ciNameData[i].CI_CODE;
            ciNameObject.categoryName = ciNameData[i].CATEGORY_NAME;
            ciNameObject.environmentName = ciNameData[i].ENVIRONMENT_NAME;
            ciNameObject.serialNumber= ciNameData[i].SERIAL_NUMBER;
            ciNameObject.ciConsumerCompany = ciNameData[i].COMPANY_ID;
            ciNameObject.ciSupportCompany = ciNameData[i].SUPPORT_COMPANY_ID;
            ciNameObject.ciClassId = ciNameData[i].CLASS_ID;
            ciNameObject.ciLocationId = ciNameData[i].LOCATION_ID;
            ciNameObject.ciLocationName = ciNameData[i].LOCATION_NAME;
            ciNameObject.businessCriticalityId = ciNameData[i].METALLIC;
            ciNameObject.businessCriticality = ciNameData[i].METALLIC_NAME;
            impactedCiDataDetails.push(ciNameObject);
          }
          impactedCiDataDetails = Array.from(new Set(impactedCiDataDetails));
          } else {
            impactedCiDataDetails = [];
          }
          this.setState({ impactedCiData: impactedCiDataDetails })
        });
    }
  }
  setErrorImpactedColor() {
    if (this.state.selectedImpactedCILists.length === 0)
      this.setState({ ImpactedErrorColor: "error" });
    else this.setState({ ImpactedErrorColor: "" });
  }
  setErrorColor() {
    if (this.state.selectedConsumers.length === 0)
      this.setState({ RequestedErrorColor: "error" });
    else this.setState({ RequestedErrorColor: "" });
  }
  onRequestorSelected(selectedUsers) {
    if (this.state.RequestedErrorColor === "error")
      this.setState({
        RequestedErrorColor: "",
        selectedConsumers: selectedUsers,
        consumerDataDetails:[]
      });
    else this.setState({ 
                         selectedConsumers: selectedUsers,
                         consumerDataDetails:[]
                       });
  }
  setErrorColorTemplateTaskCode() {
    if (this.state.selectedTemplateTaskCode.length === 0)
      this.setState({ templateTaskCodeErrorColor: "error" });
    else this.setState({ templateTaskCodeErrorColor: "" });
  }
  onTemplateTaskCodeSelected(selected) {
    if (this.state.templateTaskCodeErrorColor === "error")
      this.setState({
        templateTaskCodeErrorColor: "",
        selectedTemplateTaskCode: selected,
      });
    else this.setState({ selectedTemplateTaskCode: selected });
  }
  onCompanySelection(selectedCompanies) {
    if (this.state.companyError === "error") {
      this.setState({
        companyError: "",
        selectedCompaniesList: selectedCompanies,
      });
    } else {
      this.setState({ selectedCompaniesList: selectedCompanies });
    }
  }
  setCompanyColor() {
    if (this.state.selectedCompaniesList.length === 0) {
      this.setState({ companyError: "error" });
    } else {
      this.setState({ companyError: "" });
    }
  }

  onOpsCatSelection(selectedCatagory) {
    if (this.state.opsCatFirstError === "error") {
      this.setState({
        opsCatFirstError: "",
        operationalCategoryValues: selectedCatagory,
      });
    } else {
      this.setState({ operationalCategoryValues: selectedCatagory });
    }
  }

  setOpsCatColor() {
    if (this.state.operationalCategoryValues.length === 0) {
      this.setState({ opsCatFirstError: "error" });
    } else {
      this.setState({ opsCatFirstError: "" });
    }
  }

  getOpsCatOptions(catdata) {
    if ( catdata.length == 0) {
      return null;
    }
    return catdata.map((obj) => {
      // const newObj = { id: "", label: "" };
      // newObj.id = obj.id;
      // newObj.label = obj.label;
      return obj;
    });
  }
  addFieldAND(values) {    
    let multiValueCollections = { id: "", value: "" };
    let opsCatValueCollections = { id: "", value: "" };
    if (this.state.hideInput === "Support Company") {
      this.state.selectedCompaniesList.forEach((item) => {
        (multiValueCollections.id += "," + item.id),
          (multiValueCollections.value += "," + item.label);
      });
    } else if (this.state.hideInput === "Service") {
      this.state.selectedServicesList.forEach((item) => {
        (multiValueCollections.id += "," + item.id),
          (multiValueCollections.value += "," + item.label);
      });
    } else if (this.state.hideInput === "Hold Reason") {
      this.state.selectedReasonList.forEach((item) => {
        (multiValueCollections.id += "," + item.id),
          (multiValueCollections.value += "," + item.label);
      });
    } else if (this.state.hideInput === "Impacted CI") {
      this.state.selectedImpactedCILists.forEach((item) =>  {
        (multiValueCollections.id += "," + item?.ciId),
        (multiValueCollections.value += "," + item?.ciName);
      });
    } else if (this.state.hideInput === "Requestor") {
      this.state.selectedConsumers.forEach((item) => {
        (multiValueCollections.id += "," + item.id),
          (multiValueCollections.value += "," + item.name);
      });
    } else if (this.state.hideInput === "Task Template ID") {
      this.state.selectedConsumers.forEach((item) => {
        (multiValueCollections.id += "," + item.id),
          (multiValueCollections.value += "," + item.name);
      });
    } else if (this.state.hideInput === "Assignment Group") {
      this.state.selectedGroupsList.forEach((item) => {
        (multiValueCollections.id += "," + item.id),
          (multiValueCollections.value += "," + item.label);
      });
    }
    (multiValueCollections.id = multiValueCollections.id.substr(
      1,
      multiValueCollections.id.length - 1
    )),
      (multiValueCollections.value = multiValueCollections.value.substr(
        1,
        multiValueCollections.value.length - 1
      ));
    let classIDtoPass = this.state.slaCriteriaValueText;
    if (this.state.hideInput === "Class") {
      this.state.classList.forEach((item) => {
        if (this.state.slaCriteriaValueText == item.value)
          this.state.slaCriteriaValueText = item.text;
      });
    }
    if (this.state.hideInput == true && this.props.moduleId == "25") {
      this.state.taskStatusList.forEach((item) => {
        if (this.state.slaCriteriaValueText == item.value)
          this.state.slaCriteriaValueText = item.text;
      });
    }
    if (this.state.hideInput === "Work Item") {
      this.state.workItemList.forEach((item) => {
        if (this.state.slaCriteriaValueText == item.value)
          this.state.slaCriteriaValueText = item.text;
      });
    }
    if (this.state.hideInput === "Task Type") {
      this.state.taskTypeList.forEach((item) => {
        if (this.state.slaCriteriaValueText == item.value)
          this.state.slaCriteriaValueText = item.text;
      });
    } else if (this.state.hideInput === "Business Criticality") {
      this.state.businessCriticalityValue.forEach((item) => {
        if (this.state.slaCriteriaValueText == item.value)
          this.state.slaCriteriaValueText = item.text;
      });
    } else if (this.state.hideInput == "Category"  || 
      this.state.hideInput == "Sub Category" || 
      this.state.hideInput == "Type"){
      this.state.operationalCategoryValues.forEach((item) => {
        opsCatValueCollections.id =   item.id;
        opsCatValueCollections.value =  item.label;
      }); 
    } 
    else if (this.state.hideInput == true) {
      this.state.slaLovList.forEach((item) => {
        if (this.state.slaCriteriaValueText == item.value)
          this.state.slaCriteriaValueText = item.text;
      });
    }

    let postjson = {
        slaId: values.slaDefId,
        conditionName: values.slaCriteriaTypeName,
        conditionId: values.slaCriteriaTypeId,
        attributeName: values.slaCriteriaAttributeName,
        attributeId: values.slaCriteriaAttribute,
        operator: values.slaCriteriaOperator,
        attributeValue:
          this.state.hideInput === "Service" ||
          this.state.hideInput === "Hold Reason" ||
          this.state.hideInput === "Impacted CI" ||
          this.state.hideInput === "Requestor" ||
          this.state.hideInput === "Task Template ID" ||
          this.state.hideInput === "Assignment Group" ||
          this.state.hideInput === "Support Company"
            ? multiValueCollections.id
            : this.state.hideInput === "Template Task Code" ?  this.state.selectedTemplateTaskCode[0].id :
            (this.state.hideInput === "Category"
            || this.state.hideInput ==="Sub Category"
            || this.state.hideInput ==="Type") ?  
            opsCatValueCollections.id : this.state.hideInput == "Task Type" ? this.state.taskTypeAttributeValue : values.slaCriteriaValue,
        createdBy: "",
        createdByName: "",
        attributeDisplayValue:
          this.state.hideInput === "Service" ||
          this.state.hideInput === "Hold Reason" ||
          this.state.hideInput === "Impacted CI" ||
          this.state.hideInput === "Requestor" ||
          this.state.hideInput === "Task Template ID" ||
          this.state.hideInput === "Assignment Group" ||
          this.state.hideInput === "Support Company"
            ? multiValueCollections.value
            : this.state.hideInput === "Template Task Code" ?  this.state.selectedTemplateTaskCode[0].id : 
            (this.state.hideInput === "Category"
            || this.state.hideInput ==="Sub Category"
            || this.state.hideInput ==="Type") ?  
            opsCatValueCollections.value :this.state.slaCriteriaValueText,
        joinCondition: "AND",
      },
      ref = this;
    let flag = false;
    this.props.slaAttributesList[postjson.conditionName].forEach((iter) => {
      if (
        iter.attributeName === postjson.attributeName &&
        iter.operator === postjson.operator &&
        iter.attributeValue == postjson.attributeValue
      )
        flag = true;
    });
    if (flag == true) {
      this.setState({ duplicateExists: true });
      return;
    }
    ref.setState({ ANDshowOR: true });

    if (this.state.editTypeHead) {
      axios
        .patch(GLOBAL.slaAttrUpdate + values.slaRuleID, postjson)
        .then(function (response) {
          ref.props.loadSlaAttributesList(ref.props.slaDefId);
          if (ref.state.editTypeHead == true) {
            ref.formToggleFun(false);
          } else {
            if (ref.state.hideInput === "Class") {
              ref.state.classIsSelectedOrNot = true;
              axios
                .get(
                  "/api/config_items/ciDetailsByClassId?class_id=" +
                    classIDtoPass
                )
                .then((response) => {
                  response.data.forEach((item) => {
                    ref.state.ciListBasedOnClass.push({
                      id: item.CI_ID,
                      label: item.CI_NAME,
                    });
                  });
                });
            }
            ref.props.dispatch(
              change("popUpAttribute", "slaCriteriaAttribute", "")
            );
            ref.props.dispatch(
              change("popUpAttribute", "slaCriteriaOperator", "")
            );
            ref.props.dispatch(
              change("popUpAttribute", "slaCriteriaValue", "")
            );
            ref.setState({ conditionName: true });
            ref.setState({
              hideInput: "",
              operatorSelected: "",
              slaCriteriaValueText: "",
              taskTypeAttributeValue: "",
              editTypeHead: false,
              selectedServicesList: [],
              duplicateExists: false,
              joinCondition: "AND",
              selectedImpactedCILists: [],
              selectedConsumers: [],
              selectedCompaniesList: [],
              consumerDataDetails: [],
              selectedGroupsList: [],
              typedRequestor: "",
              selectedTemplateTaskCode: [],
              templateTaskCodeDataDetails: [],
              typedTemplateTaskCode: ""
            });
            ref.setState({ ANDshowOR: false });
          }
        })
        .catch((err) => {
          ref.setState({ ANDshowOR: false });
        });
    } else {
      axios
        .post(GLOBAL.slaCriteriaPostUrl, postjson)
        .then(function (response) {
          ref.props.loadSlaAttributesList(ref.props.slaDefId);
          if (ref.state.editTypeHead == true) {
            ref.formToggleFun(false);
          } else {
            if (ref.state.hideInput === "Class") {
              ref.state.classIsSelectedOrNot = true;
              axios
                .get(
                  "/api/config_items/ciDetailsByClassId?class_id=" +
                    classIDtoPass
                )
                .then((response) => {
                  response.data.forEach((item) => {
                    ref.state.ciListBasedOnClass.push({
                      id: item.CI_ID,
                      label: item.CI_NAME,
                    });
                  });
                });
            }
            ref.props.dispatch(
              change("popUpAttribute", "slaCriteriaAttribute", "")
            );
            ref.props.dispatch(
              change("popUpAttribute", "slaCriteriaOperator", "")
            );
            ref.props.dispatch(
              change("popUpAttribute", "slaCriteriaValue", "")
            );
            ref.setState({ conditionName: true });
            ref.setState({
              hideInput: "",
              operatorSelected: "",
              slaCriteriaValueText: "",
              taskTypeAttributeValue: "",
              editTypeHead: false,
              selectedServicesList: [],
              duplicateExists: false,
              joinCondition: "AND",
              selectedImpactedCILists: [],
              selectedConsumers: [],
              selectedCompaniesList: [],
              consumerDataDetails: [],
              selectedGroupsList: [],
              typedRequestor: "",
              selectedTemplateTaskCode: [],
              templateTaskCodeDataDetails: [],
              typedTemplateTaskCode: "",
              selectedSlaCriticalValue: []
            });
            ref.setState({ ANDshowOR: false });
          }
        })
        .catch((err) => {
          ref.setState({ ANDshowOR: false });
        });
    }
  }
  addFieldOR(values) {
    let multiValueCollections = { id: "", value: "" };
    let opsCatValueCollections = { id: "", value: "" };
    if (this.state.hideInput === "Support Company") {
      this.state.selectedCompaniesList.forEach((item) => {
        (multiValueCollections.id += "," + item.id),
          (multiValueCollections.value += "," + item.label);
      });
    } else if (this.state.hideInput === "Service") {
      this.state.selectedServicesList.forEach((item) => {
        (multiValueCollections.id += "," + item.id),
          (multiValueCollections.value += "," + item.label);
      });
    } else if (this.state.hideInput === "Hold Reason") {
      this.state.selectedReasonList.forEach((item) => {
        (multiValueCollections.id += "," + item.id),
          (multiValueCollections.value += "," + item.label);
      });
    } else if (this.state.hideInput === "Impacted CI") {
      this.state.selectedImpactedCILists.forEach((item) => {
        (multiValueCollections.id += "," + item?.ciId),
        (multiValueCollections.value += "," + item?.ciName);
      });
    } else if (this.state.hideInput === "Requestor") {
      this.state.selectedConsumers.forEach((item) => {
        (multiValueCollections.id += "," + item.id),
          (multiValueCollections.value += "," + item.name);
      });
    } else if (this.state.hideInput === "Task Template ID") {
      this.state.selectedConsumers.forEach((item) => {
        (multiValueCollections.id += "," + item.id),
          (multiValueCollections.value += "," + item.name);
      });
    } else if (this.state.hideInput === "Assignment Group") {
      this.state.selectedGroupsList.forEach((item) => {
        (multiValueCollections.id += "," + item.id),
          (multiValueCollections.value += "," + item.label);
      });
    } else if (this.state.hideInput == "Category" || 
      this.state.hideInput == "Sub Category" || 
      this.state.hideInput == "Type"){
      this.state.operationalCategoryValues.forEach((item) => {
        opsCatValueCollections.id =   item.id;
        opsCatValueCollections.value =  item.label;
      });
    } 
    (multiValueCollections.id = multiValueCollections.id.substr(
      1,
      multiValueCollections.id.length - 1
    )),
      (multiValueCollections.value = multiValueCollections.value.substr(
        1,
        multiValueCollections.value.length - 1
      ));
    let classIDtoPass = this.state.slaCriteriaValueText;
    if (this.state.hideInput === "Class") {
      this.state.classList.forEach((item) => {
        if (this.state.slaCriteriaValueText == item.value)
          this.state.slaCriteriaValueText = item.text;
      });
    }
    if (this.state.hideInput == true && this.props.moduleId == "25") {
      this.state.taskStatusList.forEach((item) => {
        if (this.state.slaCriteriaValueText == item.value)
          this.state.slaCriteriaValueText = item.text;
      });
    }
    if (this.state.hideInput === "Work Item") {
      this.state.workItemList.forEach((item) => {
        if (this.state.slaCriteriaValueText == item.value)
          this.state.slaCriteriaValueText = item.text;
      });
    }
    if (this.state.hideInput === "Task Type") {
      this.state.taskTypeList.forEach((item) => {
        if (this.state.slaCriteriaValueText == item.value)
          this.state.slaCriteriaValueText = item.text;
      });
    } else if (this.state.hideInput === "Business Criticality") {
      this.state.businessCriticalityValue.forEach((item) => {
        if (this.state.slaCriteriaValueText == item.value)
          this.state.slaCriteriaValueText = item.text;
      });
    } else if (this.state.hideInput == true) {
      this.state.slaLovList.forEach((item) => {
        if (this.state.slaCriteriaValueText == item.value)
          this.state.slaCriteriaValueText = item.text;
      });
    }
    let postjson = {
        slaId: values.slaDefId,
        conditionName: values.slaCriteriaTypeName,
        conditionId: values.slaCriteriaTypeId,
        attributeName: values.slaCriteriaAttributeName,
        attributeId: values.slaCriteriaAttribute,
        operator: values.slaCriteriaOperator,
        attributeValue:
          this.state.hideInput === "Service" ||
          this.state.hideInput === "Hold Reason" ||
          this.state.hideInput === "Impacted CI" ||
          this.state.hideInput === "Requestor" ||
          this.state.hideInput === "Task Template ID" ||
          this.state.hideInput === "Assignment Group" ||
          this.state.hideInput === "Support Company"
            ? multiValueCollections.id
            : this.state.hideInput === "Template Task Code" ?  this.state.selectedTemplateTaskCode[0].id : 
            (this.state.hideInput === "Category"
              || this.state.hideInput ==="Sub Category"
              || this.state.hideInput ==="Type") ?  
              opsCatValueCollections.id : this.state.hideInput == "Task Type" ? this.state.taskTypeAttributeValue : values.slaCriteriaValue,
        createdBy: "",
        createdByName: "",
        attributeDisplayValue:
          this.state.hideInput === "Service" ||
          this.state.hideInput === "Hold Reason" ||
          this.state.hideInput === "Impacted CI" ||
          this.state.hideInput === "Requestor" ||
          this.state.hideInput === "Task Template ID" ||
          this.state.hideInput === "Assignment Group" ||
          this.state.hideInput === "Support Company"
            ? multiValueCollections.value
            : this.state.hideInput === "Template Task Code" ?  this.state.selectedTemplateTaskCode[0].id : 
            (this.state.hideInput === "Category"
              || this.state.hideInput ==="Sub Category"
              || this.state.hideInput ==="Type") ?  
              opsCatValueCollections.value : this.state.slaCriteriaValueText,
        joinCondition: "OR",
      },
      ref = this;
    let flag = false;
    this.props.slaAttributesList[postjson.conditionName].forEach((iter) => {
      if (
        iter.attributeName === postjson.attributeName &&
        iter.operator === postjson.operator &&
        iter.attributeValue == postjson.attributeValue
      )
        flag = true;
    });
    if (flag == true) {
      this.setState({ duplicateExists: true });
      return;
    }
    ref.setState({ ANDshowOR: true });

    if (this.state.editTypeHead) {
      axios
        .patch(GLOBAL.slaAttrUpdate + values.slaRuleID, postjson)
        .then(function (response) {
          ref.props.loadSlaAttributesList(ref.props.slaDefId);
          if (ref.state.editTypeHead == true) {
            ref.formToggleFun(false);
          } else {
            if (ref.state.hideInput === "Class") {
              ref.state.classIsSelectedOrNot = true;
              axios
                .get(
                  "/api/config_items/ciDetailsByClassId?class_id=" +
                    classIDtoPass
                )
                .then((response) => {
                  response.data.forEach((item) => {
                    ref.state.ciListBasedOnClass.push({
                      id: item.CI_ID,
                      label: item.CI_NAME,
                    });
                  });
                });
            }
            ref.props.dispatch(
              change("popUpAttribute", "slaCriteriaAttribute", "")
            );
            ref.props.dispatch(
              change("popUpAttribute", "slaCriteriaOperator", "")
            );
            ref.props.dispatch(
              change("popUpAttribute", "slaCriteriaValue", "")
            );
            ref.setState({ conditionName: true });
            ref.setState({
              hideInput: "",
              operatorSelected: "",
              slaCriteriaValueText: "",
              taskTypeAttributeValue: "",
              editTypeHead: false,
              selectedServicesList: [],
              duplicateExists: false,
              joinCondition: "OR",
              selectedImpactedCILists: [],
              selectedConsumers: [],
              selectedCompaniesList: [],
              selectedReasonList: [],
              consumerDataDetails: [],
              selectedGroupsList: [],
              typedRequestor: "",
              selectedTemplateTaskCode: [],
              templateTaskCodeDataDetails: [],
              typedTemplateTaskCode: ""
            });
            ref.setState({ ANDshowOR: false });
          }
        })
        .catch((err) => {
          ref.setState({ ANDshowOR: false });
        });
    } else {
      axios
        .post(GLOBAL.slaCriteriaPostUrl, postjson)
        .then(function (response) {
          ref.props.loadSlaAttributesList(ref.props.slaDefId);
          if (ref.state.editTypeHead == true) {
            ref.formToggleFun(false);
          } else {
            if (ref.state.hideInput === "Class") {
              ref.state.classIsSelectedOrNot = true;
              axios
                .get(
                  "/api/config_items/ciDetailsByClassId?class_id=" +
                    classIDtoPass
                )
                .then((response) => {
                  response.data.forEach((item) => {
                    ref.state.ciListBasedOnClass.push({
                      id: item.CI_ID,
                      label: item.CI_NAME,
                    });
                  });
                });
            }
            ref.props.dispatch(
              change("popUpAttribute", "slaCriteriaAttribute", "")
            );
            ref.props.dispatch(
              change("popUpAttribute", "slaCriteriaOperator", "")
            );
            ref.props.dispatch(
              change("popUpAttribute", "slaCriteriaValue", "")
            );
            ref.setState({ conditionName: true });
            ref.setState({
              hideInput: "",
              operatorSelected: "",
              slaCriteriaValueText: "",
              taskTypeAttributeValue: "",
              editTypeHead: false,
              selectedServicesList: [],
              duplicateExists: false,
              joinCondition: "OR",
              selectedImpactedCILists: [],
              selectedConsumers: [],
              selectedCompaniesList: [],
              selectedReasonList: [],
              consumerDataDetails: [],
              selectedGroupsList: [],
              typedRequestor: "",
              selectedTemplateTaskCode: [],
              templateTaskCodeDataDetails: [],
              typedTemplateTaskCode: "",
              selectedSlaCriticalValue: []
            });
            ref.setState({ ANDshowOR: false });
          }
        })
        .catch((err) => {
          ref.setState({ ANDshowOR: false });
        });
    }
  }

  onGroupSelection(GroupsSelected) {
    if (this.state.AssignedGroupErrorColor == "error")
      this.setState({
        AssignedGroupErrorColor: "",
        selectedGroupsList: GroupsSelected,
      });
    else this.setState({ selectedGroupsList: GroupsSelected });
  }

  setErrorGroupColor() {
    if (this.state.selectedGroupsList.length === 0)
      this.setState({ AssignedGroupErrorColor: "error" });
    else this.setState({ AssignedGroupErrorColor: "" });
  }
  getReasonOptions(reasondata) {
    if (!(reasondata && reasondata.length)) {
      return [];
    }
    return reasondata.map((obj, i) => {
      const newObj = { id: "", label: "" };
      newObj.id = obj.field1Value;
      newObj.label = obj.field1Key;
      return newObj;
    });
  }
  getCompaniesList(consumerCompanyId, roleValues) {
    axios
      .get(
        "/service/api/chainEntities/getChainCompanyList?companyId=" +
          consumerCompanyId +
          "&role=" +
          roleValues
      )
      .then((responseCompany) => {
        let impactedServiceData = this.state.companyList;
        responseCompany.data.forEach((item) => {
          impactedServiceData.push({
            label: item.field1Key,
            id: item.field1Value,
          });
        });
        this.setState({ companyList: impactedServiceData });
      });
  }

  getCompanyMenulist = (field, status) => {
    const compId = this.props.slaDefEditDetails.companyId;

    axios.get(`/api/company_menulist?companyId=${compId}&module=&field=${field}&language=${languageSelected}&status=${status}`)
    .then((resp) => {
      if (Array.isArray(resp.data)) {
          const compMenuList = [];
          resp.data && resp.data.length > 0 && resp.data.forEach(itm => {
              compMenuList.push({
                  text: itm.field1Key,
                  value: itm.field1Value,
                  description: itm.module
              });
          });
          this.setState({ taskTypeList: compMenuList});
      } else {
          console.warn("Response data is not an array:", resp.data);
          this.setState({ taskTypeList: []});
          this.setState({ slaCriteriaValueText: '' });
      }
  });
};

  render() {
    if (
      typeof this.state.slaLovList !== "undefined" &&
      Object.keys(this.state.slaLovList).length === 0
    )
      this.filterDropDownArray(
        this.props.slaDefEditDetails.moduleName === "Task"
          ? this.state.taskStatusList
          : this.props.slaModuleStatusValues,
        this.props.slaDefEditDetails.moduleName
      );
    if (
      this.props.slaCriteriaType.length == 0 ||
      this.props.slaCriteriaType.length == undefined ||
      this.props.slaOperatorType.length == 0 ||
      this.props.slaOperatorType.length == undefined ||
      this.props.slaDefEditDetails.moduleId == "" ||
      this.props.slaDefEditDetails.moduleId == undefined ||
      this.props.slaAttributes.length == undefined ||
      this.props.slaDefId == "" ||
      this.props.slaDefId == undefined ||
      this.state.slaLovList === undefined ||
      this.state.slaLovList.length === 0 ||
      this.state.businessCriticalityValue.length === 0 
      // this.state.operationalCategoryValues.length === 0
    ) {
      /**
       * Removed IF Conditions:
       * this.state.impactedServiceDataDetails === undefined || this.state.impactedServiceDataDetails.length===0 ||
       * this.state.consumerDataDetails===undefined || this.state.consumerDataDetails.length===0 ||
       * this.state.GroupList === undefined || this.state.GroupList.length===0 ||
       * this.state.impactedCICollections === undefined || this.state.impactedCICollections.length ===0 ||
       * this.state.classList === undefined || this.state.classList.length===0
       */
      return (
        <LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
      );
    }
    let { error, handleSubmit } = this.props;
    if (
      (GLOBAL.slaCriteriaTypeIdErrorColor === "" &&
        GLOBAL.slaCriteriaAttributeErrorColor === "" &&
        GLOBAL.slaCriteriaOperatorErrorColor === "" &&
        GLOBAL.slaCriteriaValueErrorColor === "") ||
      this.state.duplicateExists == false
    ) {
      error = null;
    }
    if (this.state.duplicateExists) error = this.state.duplicateMessage;
    return (
      <div>
        <CloseBtnRightPanel showRightSide={this.props.rightEditPanel} />
        <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
          <ul>
            <li>
            <Button
            disabled={
              this.state.showSubmit ||
              this.state.ANDshowOR ||
              this.state.enableEdit
            }
            onClick={() => {
              this.state.typeValue = "";
              this.state.joinCondition = "AND";
              this.formToggleFun(true);
            }}
            bsPrefix=" "
            bsClass=""
            bsStyle=""
            title={this.props.translator["Add"]}
          >
            <HiPlus />
          </Button>
            </li>
          </ul>
        </div>
      <div className="rPageHeading">
        <div className="offNam margin-t-5 margin-b-5">          
          {this.props.translator["Specify Attribute Values"]}
        </div>
      </div>

      <div className="rBoxGap">
        {this.state.formaddToggle ? (
          <div>
            <Table
              responsive
              striped
              bordered
              condensed
              hover
              className="tableWithOutPointer f-size-13"
            >
              <thead>
                <tr>
                  <th width="15%">{this.props.translator["Type"]}</th>
                  <th width="15%">{this.props.translator["Attribute"]}</th>
                  <th width="15%">{this.props.translator["Operator"]}</th>
                  <th width="40%">{this.props.translator["Value"]}</th>
                  <th width="15%">{this.props.translator["Join Condition"]}</th>
                  {/* <th width="40%">{this.props.translator['Edit']}</th> */}
                  <th width="15%" className="text-c">
                    {this.props.translator["Action"]}
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderAttList(this.props.slaAttributesList)}</tbody>
            </Table>
          </div>
        ) : null}

        {this.state.formToggle ? (
          <div>
            {error == undefined || error == null || error == "" ? null : (
              <Alert variant="danger" className="errorMessageSec">
                <p>{error}</p>
              </Alert>
            )}
            <Table className="slaAttrTable">
              <tbody>
                <tr>
                  <td>
                    <Form.Label bsClass="">
                      <span className="rStar" />
                      {this.props.translator["Type"]}
                    </Form.Label>
                  </td>
                  <td>
                    <Form.Group>
                      <Field
                        name="slaCriteriaTypeId"
                        className={
                          this.state.slaCriteriaTypeId === "error" ||
                          GLOBAL.slaCriteriaTypeIdErrorColor === "error"
                            ? "form-control error"
                            : "form-control"
                        }
                        component="select"
                        onBlur={(event) => {
                          this.validateField("slaCriteriaTypeId", event);
                        }}
                        onChange={(event) => {
                          this.setState({selectedSlaCriticalValue: []})
                          this.props.dispatch(
                            change("popUpAttribute", "slaCriteriaAttribute", "")
                          );
                          this.props.dispatch(
                            change(
                              "popUpAttribute",
                              "slaCriteriaAttributeName",
                              ""
                            )
                          );
                          this.props.dispatch(
                            change(
                              "popUpAttribute",
                              "slaCriteriaTypeName",
                              event.target[event.target.selectedIndex].text
                            )
                          );
                          this.props.dispatch(
                            change(
                              "popUpAttribute",
                              "slaDefId",
                              this.props.slaDefEditDetails.slaId
                            )
                          );
                          this.changeTypeValue(
                            event.target[event.target.selectedIndex].text
                          );
                        }}
                        onSelect={(event) => {
                          this.props.dispatch(
                            change(
                              "popUpAttribute",
                              "slaCriteriaTypeName",
                              event.target[event.target.selectedIndex].text
                            )
                          );
                          this.props.dispatch(
                            change(
                              "popUpAttribute",
                              "slaDefId",
                              this.props.slaDefEditDetails.slaId
                            )
                          );
                          this.changeTypeValue(
                            event.target[event.target.selectedIndex].text
                          );
                        }}
                        disabled={this.state.conditionName}
                      >
                        <option value="">select..</option>
                        {this.renderTypeLov(this.props.slaCriteriaType)}
                      </Field>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Label bsClass="">
                      <span className="rStar" />
                      {this.props.translator["Attribute"]}
                    </Form.Label>
                  </td>
                  <td>
                    <Form.Group>
                      <Field
                        name="slaCriteriaAttribute"
                        className={
                          this.state.slaCriteriaAttribute === "error" ||
                          GLOBAL.slaCriteriaAttributeErrorColor === "error"
                            ? "form-control error"
                            : "form-control"
                        }
                        component="select"
                        onBlur={(event) => {
                          this.validateField("slaCriteriaAttribute", event);
                        }}
                        onChange={(event) => {
                         if(event.target[event.target.selectedIndex].text === "Task Type") {
                          this.setState({ slaCriteriaValueText: '', taskTypeAttributeValue: "" });
                          this.getCompanyMenulist("Task Type", "1");
                          };
                          if(!event.target.value || event.target[event.target.selectedIndex].text === "Task Type"){
                            this.setState({selectedSlaCriticalValue: []})
                            this.props.dispatch(
                              change(
                                "popUpAttribute",
                                "slaCriteriaValue",
                                ""
                              )
                            );
                          }
                          if(!event.target.value || event.target.value == ""){
                            this.setState({ hideInput: ""});
                          }
                          this.props.dispatch(
                            change(
                              "popUpAttribute",
                              "slaCriteriaAttributeName",
                              event.target[event.target.selectedIndex].text
                            )
                          );
                          this.changeForm(
                            event.target[event.target.selectedIndex].text
                          );
                          this.setState({ selectedAttribute: event.target[event.target.selectedIndex].text});
                        }}
                      >
                        <option value="">select..</option>
                        {this.renderAttributeLov(this.props.slaAttributes)}
                      </Field>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Label bsClass="">
                      <span className="rStar" />
                      {this.props.translator["Operator"]}
                    </Form.Label>
                  </td>
                  <td>
                    <Form.Group>
                      <Field
                        name="slaCriteriaOperator"
                        className={
                          this.state.slaCriteriaOperator === "error" ||
                          GLOBAL.slaCriteriaOperatorErrorColor === "error"
                            ? "form-control error"
                            : "form-control"
                        }
                        component="select"
                        onBlur={(event) => {
                          this.validateField("slaCriteriaOperator", event);
                        }}
                        onChange={(event) => {
                          this.setState({
                            operatorSelected:
                              event.target[event.target.selectedIndex].text ===
                              "select.."
                                ? ""
                                : event.target[event.target.selectedIndex].text,
                          });
                          this.props.dispatch(
                            change(
                              "popUpAttribute",
                              "slaCriteriaOperatorName",
                              event.target[event.target.selectedIndex].text
                            )
                          );
                        }}
                      >
                        <option value="">select..</option>
                        {this.renderOperatorLov(this.props.slaOperatorType)}
                      </Field>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Label bsClass="">
                      <span className="rStar" />
                      {this.props.translator["Value"]}
                    </Form.Label>
                  </td>
                  {this.state.hideInput === "Service" ? (
                    <td>
                      <Form.Group>
                      <div className="attrvaldv">
                        <Field
                          component={TypeaheadExample}
                          onSelection={this.onServiceSelection}
                          setErrorColor={this.setErrorServiceColor}
                          name="slaCriteriaValue"
                          className="form-control"
                          errorClass={
                            this.state.serviceErrorColor === "error"
                              ? "error"
                              : ""
                          }
                          options={this.state.impactedServiceDataDetails}
                          selectedOptions={this.state.selectedServicesList}
                          editEnable={this.state.enableEdit}
                        ></Field>
                        </div>
                      </Form.Group>
                    </td>
                  ) : this.state.hideInput === "Impacted CI" ? (
                    <td>
                      <Form.Group>
                      <div className="attrvaldv">
                        <Field
                        component={TypeaheadandDropdown}
                        onSelection={this.onImpactedSelection}
                        onInputChange={this.onValueChange}
                        name="slaCriteriaValue"
                        className="form-control"
                        errorClass={this.state.ImpactedErrorColor === "error"
                              ? "error"
                              : ""}
                        options={this.state.impactedCiData}
                        selectedOptions={this.state.selectedImpactedCILists}
                        onCrossClick={() => {
                        this.setState({ selectedImpactedCILists: [] });
                        this.setState({ impactedCiData: [] });
                        }}
                        multiple={true}
                        ></Field>
                        </div>
                      </Form.Group>
                    </td>
                  ) : this.state.hideInput === "Requestor" ? (
                    <td>
                      <Form.Group>
                      <div className="attrvaldv">
                        <Field
                          name="slaCriteriaValue"
                          className="form-control"
                          errorClass={
                            this.state.RequestedErrorColor === "error"
                              ? "error"
                              : ""
                          }
                          options={this.state.consumerDataDetails}
                          component={TypeaheadExample}
                          selectedOptions={this.state.selectedConsumers}
                          onSelection={this.onRequestorSelected}
                          setErrorColor={this.setErrorColor}
                          editEnable={this.state.enableEdit}
                          onInputChange={this.onRequestorChange}
                        ></Field>
                        </div>
                      </Form.Group>
                    </td>
                  ) : this.state.hideInput === "Task Template ID" ? (
                    <td>
                      <Form.Group>
                      <div className="attrvaldv">
                        <Field
                          name="slaCriteriaValue"
                          className="form-control"
                          errorClass={
                            this.state.RequestedErrorColor === "error"
                              ? "error"
                              : ""
                          }
                          options={this.state.consumerDataDetails}
                          component={TypeaheadExample}
                          selectedOptions={this.state.selectedConsumers}
                          onSelection={this.onRequestorSelected}
                          setErrorColor={this.setErrorColor}
                          editEnable={this.state.enableEdit}
                          onInputChange={this.onRequestorChange}
                        ></Field>
                        </div>
                      </Form.Group>
                    </td>
                  ) : this.state.hideInput === "Assignment Group" ? (
                    <td>
                      <Form.Group>
                      <div className="attrvaldv">
                        <Field
                          name="slaAssignmentGroup"
                          className="form-control"
                          errorClass={
                            this.state.AssignedGroupErrorColor == "error"
                              ? "error"
                              : ""
                          }
                          options={this.state.GroupList}
                          selectedOptions={this.state.selectedGroupsList}
                          onSelection={this.onGroupSelection}
                          setErrorColor={this.setErrorGroupColor}
                          component={TypeaheadExample}
                          editEnable={this.state.enableEdit}
                        ></Field>
                        </div>
                      </Form.Group>
                    </td>
                  ) : this.state.hideInput === "Class" ? (
                    <td>
                      <Form.Group>
                        <Field
                          name="slaCriteriaValue"
                          className={
                            this.state.slaCriteriaValue === "error" ||
                            GLOBAL.slaCriteriaValueErrorColor === "error"
                              ? "form-control error"
                              : "form-control"
                          }
                          component={_dropDown}
                          options={this.state.classList}
                          onChange={(event) => {
                            this.setState({
                              slaCriteriaValueText: event.target.value,
                            });
                          }}
                          onBlur={(event) => {
                            this.validateField("slaCriteriaValue", event);
                          }}
                        />
                      </Form.Group>
                    </td>
                  ) : this.state.hideInput === "Task Type" ? (
                    <td>
                      <Form.Group>
                        <div className="attrvaldv">
                          <Field
                            name="slaCriteriaValue"
                            className={
                            this.state.slaCriteriaValue === "error" ||
                            GLOBAL.slaCriteriaValueErrorColor === "error"
                              ? "error"
                              : ""
                            }
                            options={this.state.taskTypeList}
                            component={renderTypeahead}
                            onBlur={(event) => {
                              this.validateField("slaCriteriaValue", event)
                            }}
                            placeholder={this.props.translator['Please choose']}
                            labelKey="text"
                            onChange={(event) => {
                              this.setState({
                                slaCriteriaValueText: event[0]?.value,
                                taskTypeAttributeValue: event[0]?.value,
                                selectedSlaCriticalValue: event
                              });
                            }}
                            selectedOptions={this.state.selectedSlaCriticalValue}
                          />
                          </div>
                      </Form.Group>
                    </td>
                  ) : this.state.hideInput === "Work Item" ? (
                    <td>
                      <Form.Group>
                        <Field
                          name="slaCriteriaValue"
                          className={
                            this.state.slaCriteriaValue === "error" ||
                            GLOBAL.slaCriteriaValueErrorColor === "error"
                              ? "form-control error"
                              : "form-control"
                          }
                          component={_dropDown}
                          options={this.state.workItemList}
                          onChange={(event) => {
                            this.setState({
                              slaCriteriaValueText: event.target.value,
                            });
                          }}
                          onBlur={(event) => {
                            this.validateField("slaCriteriaValue", event);
                          }}
                        />
                      </Form.Group>
                    </td>
                  ) : this.state.hideInput === "Business Criticality" ? (
                    <td>
                      <Form.Group>
                        <Field
                          name="slaCriteriaValue"
                          className={
                            this.state.slaCriteriaValue === "error" ||
                            GLOBAL.slaCriteriaValueErrorColor === "error"
                              ? "form-control error"
                              : "form-control"
                          }
                          component={_dropDown}
                          options={this.state.businessCriticalityValue}
                          onChange={(event) => {
                            this.setState({
                              slaCriteriaValueText: event.target.value,
                            });
                          }}
                          onBlur={(event) => {
                            this.validateField("slaCriteriaValue", event);
                          }}
                        />
                      </Form.Group>
                    </td>
                  ) : this.state.hideInput === "Support Company" ? (
                    <td>
                      <div className="attrvaldv">
                      <Field
                        component={TypeaheadExample}
                        onSelection={this.onCompanySelection}
                        setErrorColor={this.setCompanyColor}
                        name="slaCriteriaValue"
                        className="form-control"
                        errorClass={
                          this.state.companyError === "error" ? "error" : ""
                        }
                        options={this.state.companyList}
                        selectedOptions={this.state.selectedCompaniesList}
                        editEnable={this.state.enableEdit}
                      />
                      </div>
                    </td>
                  ) : this.state.hideInput === "Hold Reason" ? (
                    <td>
                      <Form.Group>
                      <div className="attrvaldv">
                        <Field
                          component={TypeaheadExample}
                          onSelection={this.onHoldReasonSelection}
                          setErrorColor={this.setErrorHoldreasonColor}
                          name="slaCriteriaValue"
                          className="form-control"
                          errorClass={
                            this.state.holdReasonErrorColor === "error"
                              ? "error"
                              : ""
                          }
                          options={this.getReasonOptions(this.props.reasonCode)}
                          selectedOptions={this.state.selectedReasonList}
                          editEnable={this.state.enableEdit}
                        ></Field>
                        </div>
                      </Form.Group>
                    </td>
                  ) : this.state.hideInput == true &&
                    this.props.moduleId == "25" ? (
                    <td>
                      <Form.Group>
                        <Field
                          name="slaCriteriaValue"
                          className={
                            this.state.slaCriteriaValue === "error" ||
                            GLOBAL.slaCriteriaValueErrorColor === "error"
                              ? "form-control error"
                              : "form-control"
                          }
                          component={_dropDown}
                          options={this.state.taskStatusList}
                          onChange={(event) => {
                            this.setState({
                              slaCriteriaValueText: event.target.value,
                            });
                          }}
                          onBlur={(event) => {
                            this.validateField("slaCriteriaValue", event);
                          }}
                        />
                      </Form.Group>
                    </td>
                  ) : this.state.hideInput == false ? (
                    <td>
                      <Form.Group>
                        <Field
                          name="slaCriteriaValue"
                          className={
                            this.state.slaCriteriaValue === "error" ||
                            GLOBAL.slaCriteriaValueErrorColor === "error"
                              ? "form-control error"
                              : "form-control"
                          }
                          component={_dropDown}
                          options={this.state.prioritydrpdwn}
                          onChange={(event) => {
                            this.setState({
                              slaCriteriaValueText: event.target.value,
                            });
                          }}
                          onBlur={(event) => {
                            this.validateField("slaCriteriaValue", event);
                          }}
                        />
                      </Form.Group>
                    </td>
                  ) : this.state.hideInput === "Template Task Code" ? (
                    <td>
                      <Form.Group>
                        <Field
                          name="slaCriteriaValue"
                          className="form-control"
                          component={TypeaheadExampleSingleSelect}
                          errorClass={
                            this.state.templateTaskCodeErrorColor === "error"
                              ? "error"
                              : ""
                          }
                          options={this.state.templateTaskCodeDataDetails}
                          selectedOptions={this.state.selectedTemplateTaskCode}
                          onSelection={this.onTemplateTaskCodeSelected}
                          setErrorColor={this.setErrorColorTemplateTaskCode}
                          // editEnable={this.state.enableEdit}
                          onInputChange={this.onTemplateTaskCodeChange}
                        ></Field>
                      </Form.Group>
                    </td>
                  ): this.state.hideInput === "Category" ||
                   this.state.hideInput === "Sub Category" || 
                   this.state.hideInput === "Type" ? (
                  <td>
                    <Form.Group>
                      <Field
                        component={TypeaheadExample}
                        onSelection={this.onOpsCatSelection}
                        setErrorColor={this.setCompanyColor}
                        name="slaCriteriaValue"
                        className="form-control"
                        errorClass={
                          this.state.opsCatFirstError === "error" ? "error" : ""
                        }
                        options={this.getOpsCatOptions(this.props.companyMenuListValues)}
                        selectedOptions={this.state.operationalCategoryValues}
                        multiple={false}
                      />
                    </Form.Group>
                  </td>
                ) :
                (<td>
                      <Form.Group>
                        <Field
                          name="slaCriteriaValue"
                          className={
                            this.state.slaCriteriaValue === "error" ||
                            GLOBAL.slaCriteriaValueErrorColor === "error"
                              ? "form-control error"
                              : "form-control"
                          }
                          component={_dropDown}
                          options={this.state.slaLovList}
                          onChange={(event) => {
                            this.setState({
                              slaCriteriaValueText: event.target.value,
                            });
                          }}
                          onBlur={(event) => {
                            this.validateField("slaCriteriaValue", event);
                          }}
                        ></Field>
                      </Form.Group>
                    </td>
                  )}
                </tr>

                <tr>
                  <td>
                    {this.state.enableEdit == true ? "Join Condition" : null}
                  </td>
                  <td>
                    {this.state.showSubmit == false &&
                    this.state.enableEdit == false ? (
                      <ButtonGroup
                        bsPrefix=" "
                        className="notificationcss btn-toolbar"
                      >
                        <Button
                          disabled={
                            this.state.typeValue === "" ||
                            this.state.operatorSelected === "" ||
                            (this.state.hideInput === "Support Company" &&
                              this.state.selectedCompaniesList.length === 0) ||
                            (this.state.hideInput === "Service" &&
                              this.state.selectedServicesList.length === 0) ||
                            (this.state.hideInput === "Hold Reason" &&
                              this.state.selectedReasonList.length === 0) ||
                            (this.state.hideInput === "Impacted CI" &&
                              this.state.selectedImpactedCILists.length ===
                                0) ||
                            (this.state.hideInput === "Assignment Group" &&
                              this.state.selectedGroupsList.length === 0) ||
                            (this.state.hideInput === "Requestor" &&
                              this.state.selectedConsumers.length === 0) ||
                            (this.state.hideInput === "Template Task Code" &&
                              this.state.selectedTemplateTaskCode.length === 0) ||
                            (this.state.hideInput === "Task Type" && 
                              this.state.slaCriteriaValueText === "") ||
                            (this.state.hideInput === "Class" &&
                              this.state.slaCriteriaValueText === "") ||
                            (this.state.hideInput === "Business Criticality" &&
                              this.state.slaCriteriaValueText === "") ||
                            (this.state.hideInput === "Category" &&
                              this.state.operationalCategoryValues.length === 0) ||
                            (this.state.hideInput === "Sub Category" &&
                              this.state.operationalCategoryValues.length === 0) ||
                            (this.state.hideInput === "Type" &&
                              this.state.operationalCategoryValues.length === 0) ||
                            (this.state.hideInput == true &&
                              this.state.slaCriteriaValueText === "") ||
                            (this.state.hideInput == false &&
                              this.state.slaCriteriaValueText === "") ||
                            this.state.ANDshowOR
                          }
                          size="xs"
                          variant="primary"
                          onClick={handleSubmit(this.addFieldAND)}
                        >
                          AND
                        </Button>
                        <Button
                          disabled={
                            this.state.typeValue === "" ||
                            this.state.operatorSelected === "" ||
                            (this.state.hideInput === "Support Company" &&
                              this.state.selectedCompaniesList.length === 0) ||
                            (this.state.hideInput === "Service" &&
                              this.state.selectedServicesList.length === 0) ||
                            (this.state.hideInput === "Hold Reason" &&
                              this.state.selectedReasonList.length === 0) ||
                            (this.state.hideInput === "Impacted CI" &&
                              this.state.selectedImpactedCILists.length ===
                                0) ||
                            (this.state.hideInput === "Assignment Group" &&
                              this.state.selectedGroupsList.length === 0) ||
                            (this.state.hideInput === "Requestor" &&
                              this.state.selectedConsumers.length === 0) ||
                            (this.state.hideInput === "Template Task Code" &&
                              this.state.selectedTemplateTaskCode.length === 0) ||
                            (this.state.hideInput === "Task Type" && 
                              this.state.slaCriteriaValueText === "") ||
                            (this.state.hideInput === "Class" &&
                              this.state.slaCriteriaValueText === "") ||
                            (this.state.hideInput === "Business Criticality" &&
                              this.state.slaCriteriaValueText === "") ||
                            (this.state.hideInput === "Category" &&
                              this.state.operationalCategoryValues.length === 0) ||
                            (this.state.hideInput === "Sub Category" &&
                              this.state.operationalCategoryValues.length === 0) ||
                            (this.state.hideInput === "Type" &&
                              this.state.operationalCategoryValues.length === 0) ||
                            (this.state.hideInput == true &&
                              this.state.slaCriteriaValueText === "") ||
                            (this.state.hideInput == false &&
                              this.state.slaCriteriaValueText === "") ||
                            this.state.ANDshowOR
                          }
                          size="xs"
                          variant="primary"
                          onClick={handleSubmit(this.addFieldOR)}
                        >
                          OR
                        </Button>
                      </ButtonGroup>
                    ) : null}
                    {this.state.enableEdit == true ? (
                      <Field
                        name="slaJoinCondition"
                        className="form-control"
                        component="select"
                      >
                        <option value="AND">AND</option>
                        <option value="OR">OR</option>
                      </Field>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    {this.state.showSubmit == false ? (
                      <ButtonGroup bsPrefix=" " className="btn-toolbar">
                        <Button onClick={handleSubmit(this.saveAtt)}
                        bsSize="xsmall"
                        className="rgSidrkBtn smallBtn" >
                          {this.props.translator["Save"]}
                        </Button>
                        <Button
                          onClick={() => {
                            this.state.typeValue = "";
                            this.state.joinCondition = "AND";
                            this.formToggleFun(false);
                            this.setState({formaddToggle : true});
                          }}
                          bsSize="xsmall"
                          className="rgSiLigBtn smallBtn" >
                          {this.props.translator["Cancel"]}
                        </Button>
                      </ButtonGroup>
                    ) : (
                      <ButtonGroup bsPrefix=" " className="btn-toolbar">
                        <Button disabled={this.state.showSubmit} 
                        bsSize="xsmall"
                        className="rgSidrkBtn smallBtn" >
                          {this.props.translator["Save"]}
                        </Button>
                        <Button
                          disabled={this.state.showSubmit}
                          onClick={() => {
                            this.state.typeValue = "";
                            this.state.joinCondition = "AND";
                            this.formToggleFun(false);
                            this.setState({formaddToggle : true});
                          }}
                          bsSize="xsmall"
                          className="rgSiLigBtn smallBtn" >
                          {this.props.translator["Cancel"]}
                        </Button>
                      </ButtonGroup>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        ) : null}

      </div>
    </div>
    );
  }
}

PopUpAttribute = reduxForm({
  form: "popUpAttribute",
  fields: [
    "slaCriteriaTypeId",
    "slaCriteriaAttribute",
    "slaCriteriaOperator",
    "slaCriteriaValue",
  ],
  initialValues: {
    slaCriteriaTypeId: "",
    slaCriteriaAttribute: "",
    slaCriteriaOperator: "",
    slaCriteriaValue: "",
  },
})(PopUpAttribute);

let selector = formValueSelector("popUpAttribute");
PopUpAttribute = connect()(PopUpAttribute);

export function mapStateToProps({
  slaCriteriaType,
  slaOperatorType,
  slaAttributes,
  slaAttributesList,
  slaDefActiveMandatory,
  slaModuleStatusValues,
  slaEditLov,
  slaSelectedAttributesList,
  reasonCode,
  opsCategoryLevels,
  companyMenuListValues
}) {
  return {
    slaCriteriaType,
    slaOperatorType,
    slaAttributes,
    slaAttributesList,
    slaDefActiveMandatory,
    slaModuleStatusValues,
    slaEditLov,
    slaSelectedAttributesList,
    reasonCode,
    opsCategoryLevels,
    companyMenuListValues
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadSlaDefActiveMandatory,
      loadSlaCriteriaType,
      loadSlaOperator,
      loadSlaAttributes,
      loadSlaAttributesList,
      loadSlaModuleStatus,
      editSLA,
      getAdminPrefLevel,
      getcompanyMenuListAction,
      loadBreakFixReasonCode,
      loadFulfillmentReasonCode
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUpAttribute);
