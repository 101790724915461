
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Form, Alert, ButtonToolbar, Button,Table} from "react-bootstrap";
import {
  _textArea,
  _dropDown,
  _inputField,
  _number,
  _radio,
  TypeaheadandDropdown,
} from "../../common/formFields";
// import { TypeaheadandDropdown } from '../../ams/formFields's
import { Field, reduxForm, SubmissionError, change} from "redux-form";
import { connect } from "react-redux";
import axios from "axios";
import _ from "lodash";
import {
  spcmLoader,
  getChainEntitiesCompanyList,
  setLeftEditId,
  getServiceDetailCounts,
  getFulfilmentPlans,
  getConsumptionItems,
  getLOVFullfilmentPlan,
  getComponentName,
  getGroupList,
  getContextualRules,
  resetGroupList
} from "../../../actions/spcmActions";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import {ImSpinner6} from "react-icons/im";
import Cookies from "universal-cookie";

import { RuleAmsDdropDownContextual } from "../../../components/common/forms/ruleAmsDdropDownContextual";
const cookies = new Cookies();
let languageSelected = cookies.get("gph");
languageSelected = languageSelected.split("~");
languageSelected = languageSelected[20];
import {HiPlus, HiOutlineTrash} from "react-icons/hi";
import { TbExternalLink } from "react-icons/tb";
import { GLOBAL } from "../../Globals";
import Swal from "sweetalert2";

let userCompanyID = cookies.get("gph");
if (userCompanyID) userCompanyID = userCompanyID.replace("s:", "");
if (userCompanyID)
  userCompanyID = userCompanyID.substring(
    0,
    userCompanyID.lastIndexOf(".")
  );
userCompanyID = userCompanyID.split("~");
const userCompanyId = userCompanyID[48];

const Joi = require("joi");

const removeDuplicates = (originalArray, prop) => {
  var newArray = [];
  var lookupObject = {};

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
};

const optionalComp = (components) => {
  var newArray = [];
  for (var i in components) {
    if (components[i].COMPONENT_TYPE == "optional") {
      newArray.push(components[i]);
    }
  }
  return newArray;
};

const validate = (values) => {
  let errors = {};

  if (!values.sequenceNumber) {
    errors.sequenceNumber = "Enter Title";
  }
  if (
    values.sequenceNumber == "" ||
    parseInt(values.sequenceNumber, 10) < 1 ||
    parseInt(values.sequenceNumber, 10) > 500
  ) {
    errors.sequenceNumber = "Please enter values between 1 and 500";
  }
  if (values.description) {
    if (values.description.trim() == "") {
      errors.description = "Enter Title";
    }
  } else if (!values.description) {
    errors.description = "Enter Title";
  }

  if (!values.fplan_desc) {
    errors.fplan_desc = "Enter Desc";
  }
  if (!values.status || values.status == "Select") {
    errors.status = "Enter Status";
  }

  if (!values.companyId) {
    errors.companyId = "Select Company";
  }
  
  if (!values.taskTypeId || values.taskTypeId == "" || values.taskTypeId == 0 || values.taskTypeId == "0") {
    errors.taskTypeId = "Select Task Type";
  }
  if (!values.assignedToGroupId) {
    errors.assignedToGroupId = "Select Group";
  }
  // if (!values.comp_based_task) {
  // 	errors.comp_based_task = 'Select an option';
  // }

  if (!values.componentId) {
    errors.componentId = "Select an option";
  }

  if (
    values.task_assignment_type === "Contextual" ||
    values.task_assignment_type === 2
  ) {
    if (
      typeof values.assignment_rule_id === "undefined" ||
      values.assignment_rule_id === "" ||
      values.assignment_rule_id == null
    ) {
      errors.AMSRuleName = "Select an option";
    }
  }

  return errors;
};

class ComponentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // AssociatedToDropdown:"",
      // sequenceNo:false,
      supportGroupsList: [],
      providerGroupList: [],
      AssociatedToComp: 0,
      isStatus: true,
      isExternal: "",
      isContexual: 1,
      contexualAMSRule: "",
      selectedAMSRule: [],
      selectedCompany:[],
      selectedGroup:[],
      typedAMSRule: "",
      typedCompany: "",
      typedGroup:"",
      errorAMSRule: false,
      errorCompany: false,
      errorTaskType: false,
      selectedTaskType: 0,
      errorGroup:false,
      showRedirectionLink: false,
      role_id: "",
      templateName:'',
      oldUrgencyList: [],
      taskType: []
     };

    //this.onAssociatedToChange=this.onAssociatedToChange.bind(this);
    this.onAssociatedToComp = this.onAssociatedToComp.bind(this);
    this.submitForm = this.submitForm.bind(this);
    // this.sequenceNoFun = this.sequenceNoFun.bind(this);
    this.onCompanySelect = this.onCompanySelect.bind(this);
    this.setIsContexual = this.setIsContexual.bind(this);
    this.onAMSRuleSelect = this.onAMSRuleSelect.bind(this);
    this.onCrossClickAMSRule = this.onCrossClickAMSRule.bind(this);
    this.setAMSRuleError = this.setAMSRuleError.bind(this);
    this.onAMSRuleChange = this.onAMSRuleChange.bind(this);
    this.onCompanyFieldSelect = this.onCompanyFieldSelect.bind(this);
    this.onCrossClickCompany = this.onCrossClickCompany.bind(this);
    this.setCompanyError = this.setCompanyError.bind(this);
    this.setTaskTypeError = this.setTaskTypeError.bind(this);
    this.onCompanyChange = this.onCompanyChange.bind(this);
    this.onCrossClickGroup = this.onCrossClickGroup.bind(this);
    this.setGroupError = this.setGroupError.bind(this);
    this.onGroupChange = this.onGroupChange.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.onGroupSelect = this.onGroupSelect.bind(this);
    this.getData = this.getData.bind(this);
  }

  setIsContexual(value) {
    if (value === "Predefined") {
      this.setState({ isContexual: 1, selectedAMSRule: [], typedAMSRule: "" });
      this.resetFields();
      this.setState({ errorAMSRule: false });
    } else {
      this.setState({ isContexual: 2, selectedAMSRule: [], typedAMSRule: "" });
      this.resetFields();
      this.setState({ errorAMSRule: false });
    }
  }

  resetFields() {
    let { change } = this.props;
    change("assignment_rule_name", "");
    change("assignment_rule_id", null);
    change("assignedToGroup", "");
    change("assignedToGroupId", null);
    change("companyName", "");
    change("companyId", null);
    change("taskType", "");
    change("taskTypeId", null);
  }

  onAMSRuleSelect(selectedAMSRuleOption) {
    if (selectedAMSRuleOption.length > 0) {
      const { change } = this.props;
      change("assignment_rule_name", selectedAMSRuleOption[0].label);
      change("assignment_rule_id", selectedAMSRuleOption[0].value);
    }
    this.setState({ selectedAMSRule: selectedAMSRuleOption });
  }
  onCompanyFieldSelect(options) {
    console.log("options====++",options);
    const { change } = this.props;

    if (options.length > 0) {
      change("companyName", options[0].label);
      change("companyId", options[0].value);
      this.props.getGroupList(options[0].value);
    }else{
      change("companyName", "");
      change("companyId", "");
      change("assignedToGroup", "");
      change("assignedToGroupId", ""); 
    this.props.getGroupList('','RESET_GROUPS');
    this.setState({ selectedGroup: [], typedGroup: "" });
    }
    this.setState({ selectedCompany: options });
  }

  onCrossClickAMSRule() {
    const { change } = this.props;
    change("assignment_rule_name", "");
    change("assignment_rule_id", "");
    this.setState({ selectedAMSRule: [], typedAMSRule: "" });
  }
  onCrossClickCompany() {
    const { change } = this.props;
    change("companyName", "");
    change("companyId", "");
    change("assignedToGroup", "");
    change("assignedToGroupId", "");    
    this.setState({ selectedCompany: [], typedCompany: "" });
    this.setState({ selectedGroup: [], typedGroup: "" });
    this.props.resetGroupList();
    this.props.getGroupList('',"RESET_GROUPS");
  }

  onCrossClickGroup() {
    const { change } = this.props;
    change("assignedToGroup", "");
    change("assignedToGroupId", "");
    this.setState({ selectedGroup: [], typedGroup: "" });
  }

  setAMSRuleError() {
    if (this.state.selectedAMSRule.length === 0) {
      this.setState({ errorAMSRule: true });
    } else {
      this.setState({ errorAMSRule: false });
    }
  }
  setCompanyError() {
    if (this.state.selectedCompany.length === 0) {
      this.setState({ errorCompany: true });
    } else {
      this.setState({ errorCompany: false });
    }
  }
  setGroupError() {
    if (this.state.selectedGroup.length === 0) {
      this.setState({ errorGroup: true });
    } else {
      this.setState({ errorGroup: false });
    }
  }
  setTaskTypeError() {
    if (this.state.selectedTaskType === 0) {
      this.setState({ errorTaskType: true });
    } else {
      this.setState({ errorTaskType: false });
    }
  }
  onAMSRuleChange(value) {
    const { change } = this.props;
    change("assignment_rule_name", "");
    change("assignment_rule_id", "");
    this.setState({ typedAMSRule: value, selectedAMSRule: [] });
  }
  onCompanyChange(value) {

    const { change } = this.props;
    change("companyName", "");
    change("companyId", "");
    this.setState({ typedCompany: value, selectedCompany: [] });
    this.props.resetGroupList()
  }
  onGroupChange(value) {
    const { change } = this.props;
    change("assignedToGroup", "");
    change("assignedToGroupId", "");
    this.setState({ typedGroup: value, selectedGroup: [] });
  }

  /* 	sequenceNoFun(event){
			if(event.target.checked){
				this.setState({sequenceNo: true})
			}else{
				this.setState({sequenceNo: false})
			}
		} */
  onGroupSelect(options) {
    if (options.length > 0) {
      const { change } = this.props;
      change("assignedToGroup", options[0].label);
      change("assignedToGroupId", options[0].value);
      this.setState({ selectedGroup: options });
      let isExternal = "";
      let groupId = options[0].value;
      if (groupId !== "") {
        const filterGroups = this.props.spcmReducer.groups.filter(
          (item) => item.field1Value == groupId
        );
        const filterSupportGroups =
          this.props.spcmReducer.serviceSupportCompanyGroupsList.filter(
            (item) => item.GROUPID == groupId
          );
        const filterProviderGroups =
          this.props.spcmReducer.serviceProviderCompanyGroupsList.filter(
            (item) => item.GROUPID == groupId
          );
        console.log("filterSupportGroups[0]");
        console.log(filterSupportGroups[0]);
        console.log("filterProviderGroups[0]");
        console.log(filterProviderGroups[0]);
        if (filterSupportGroups.length > 0) {
          if (
            filterSupportGroups[0].isExternal == "0" ||
            filterSupportGroups[0].isExternal == "" ||
            filterSupportGroups[0].isExternal == null ||
            filterSupportGroups[0].isExternal == undefined
          ) {
            isExternal = "false";
          } else {
            isExternal = filterSupportGroups[0].isExternal;
          }
        } else if (filterProviderGroups.length > 0) {
          if (
            filterProviderGroups[0].isExternal == "0" ||
            filterProviderGroups[0].isExternal == "" ||
            filterProviderGroups[0].isExternal == null ||
            filterProviderGroups[0].isExternal == undefined
          ) {
            isExternal = "false";
          } else {
            isExternal = filterProviderGroups[0].isExternal;
          }
        } else if (filterGroups.length > 0) {
          if (
            filterGroups[0].isExternal == "0" ||
            filterGroups[0].isExternal == "" ||
            filterGroups[0].isExternal == null ||
            filterGroups[0].isExternal == undefined
          ) {
            isExternal = "false";
          } else {
            isExternal = filterGroups[0].isExternal;
          }
        } else {
          isExternal = "false";
        }
        this.setState({ isExternal: isExternal });
      }
    } else {
      this.setState({ selectedGroup: options });
      
    }
  }
  onCompanySelect(companyId) {
    if (companyId !== "") this.props.getGroupList(companyId);
  }

  componentWillMount() {
    const { change } = this.props;
    change(
      "serviceName",
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_NAME
    );
    change(
      "serviceId",
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
    );
    change("templateNames", this.props.spcmReducer.selectedTaskTemplate);
    this.setState({
      templateName : this.props.spcmReducer.selectedTaskTemplate
    })
    this.props.spcmReducer.selectedTaskTemplate !== "" &&
    this.props.spcmReducer.selectedTaskTemplate == "Fullfilment_Template"
      ? this.props.getChainEntitiesCompanyList(
          this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID,
          "Provider"
        )
      : this.props.getChainEntitiesCompanyList(
          this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID,
          "Supporter"
        );
    this.props.getConsumptionItems(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
    );
    this.props.getLOVFullfilmentPlan();
    // this.props.getContextualRules(
    //   this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID,
    //   20
    // );
    let str = {};
    str.user_id = "";
    str = JSON.stringify(str);
    axios.get("/api/userInfo", { headers: { query: str } }).then((response) => {
      this.setState({ role_id: response.data.role_id });
      let roleArray = response.data.role_id.split(",");
      let amsRole = roleArray.includes("1") || roleArray.includes("76");
      this.setState({ showRedirectionLink: amsRole });
    });
    if (!this.props.spcmReducer.editLeftId > 0) {
      change("task_assignment_type", 1);
    }
    if (this.props.spcmReducer.editLeftId > 0) {
      console.log("this.props.initialValues", this.props.initialValues);
      // let AssociatedToDropdown=0,sequenceNo=true;

      /* if(this.props.initialValues.fplan_def_type =="Component"){
				AssociatedToDropdown=1;
			}
			if(this.props.initialValues.fplan_exetype =="Parallel"){
				sequenceNo=false;
			} */

      // this.setState({AssociatedToDropdown,sequenceNo});

      let AssociatedToComp = 0;

      if (this.props.initialValues.componentId) {
        AssociatedToComp = 1;
      }

      this.setState({ AssociatedToComp });

      // Status active or inactive

      let isStatus = true;

      if (this.props.initialValues.status == "Inactive") {
        isStatus = false;
      }

      this.setState({ isStatus });
      if (
        this.props.initialValues.isExternal == "0" ||
        this.props.initialValues.isExternal == "" ||
        this.props.initialValues.isExternal == null ||
        this.props.initialValues.isExternal == undefined
      ) {
        this.setState({ isExternal: "false" });
      } else {
        this.setState({ isExternal: this.props.initialValues.isExternal });
      }
      this.setState({
        selectedAMSRule: [
          {
            label: this.props.initialValues.assignment_rule_name
              ? this.props.initialValues.assignment_rule_name
              : null,
            value: this.props.initialValues.assignment_rule_id
              ? this.props.initialValues.assignment_rule_id
              : null,
          },
        ],
        isContexual: this.props.initialValues.task_assignment_type,
      });
      if(this.props.initialValues.task_assignment_type === 1) {
        this.onCompanySelect(this.props.initialValues.companyId);
        this.setState({
          selectedCompany: [
            {
              label: this.props.initialValues.companyName
                ? this.props.initialValues.companyName
                : null,
              value: this.props.initialValues.companyId
                ? this.props.initialValues.companyId
                : null,
            },
          ],
          selectedGroup:[
            {
              label: this.props.initialValues.assignedToGroup
                ? this.props.initialValues.assignedToGroup
                : null,
              value: this.props.initialValues.assignedToGroupId
                ? this.props.initialValues.assignedToGroupId
                : null,
            }
          ]
        });
      }
      if (this.props.initialValues.taskType && this.props.initialValues.task_type_id ){
        this.setState({
          taskType: [{
            id: this.props.initialValues.task_type_id,
            label: this.props.initialValues.taskType
          }]
        });
      }
    }

    const module =
      this.props.spcmReducer.selectedTaskTemplate == "Fullfilment_Template"
        ? "Fulfillment"
        : this.props.spcmReducer.selectedTaskTemplate ==
          "ChangeManagement_Template"
        ? "Change"
        : "Incident";
    axios
      .get(
        "/api/company_menulist?companyId=" +
          this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID +
          "&module=" +
          module +
          "&field=Task Type&language=" +
          languageSelected +
          "&status=1"
      )
      .then((responseDropdownValues) => {
        const oldUrgencyList1 = [];
        responseDropdownValues.data.map((item) => {
          oldUrgencyList1.push(item);
          this.setState({ oldUrgencyList: oldUrgencyList1 });
        });
      });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.spcmReducer.editLeftId > 0) {
      if (
        nextProps &&
        nextProps.spcmReducer.serviceSupportCompanyGroupsList !=
          this.props.spcmReducer.serviceSupportCompanyGroupsList
      ) {
        let supportGroups = [];
        if (this.props.initialValues.companyId !== "") {
          const filterSupportGroups =
            nextProps.spcmReducer.serviceSupportCompanyGroupsList.filter(
              (item) => item.COMPANYID == this.props.initialValues.companyId
            );
          supportGroups = filterSupportGroups.map((item) => {
            return { value: item.GROUPID, text: item.GROUPNAME };
          });
        }
        supportGroups = removeDuplicates(supportGroups, "value");
        this.setState({ supportGroupsList: supportGroups });
      }
      if (
        nextProps &&
        nextProps.spcmReducer.serviceProviderCompanyGroupsList !=
          this.props.spcmReducer.serviceProviderCompanyGroupsList
      ) {
        let providerGroups = [];
        if (this.props.initialValues.companyId !== "") {
          const filterProviderGroups =
            nextProps.spcmReducer.serviceProviderCompanyGroupsList.filter(
              (item) => item.COMPANYID == this.props.initialValues.companyId
            );
          providerGroups = filterProviderGroups.map((item) => {
            return { value: item.GROUPID, text: item.GROUPNAME };
          });
        }
        providerGroups = removeDuplicates(providerGroups, "value");
        this.setState({ providerGroupList: providerGroups });
      }
      if (
        nextProps &&
        nextProps.spcmReducer.isExternal != this.props.spcmReducer.isExternal
      ) {
        if (
          nextProps.spcmReducer.isExternal == "0" ||
          nextProps.spcmReducer.isExternal == "" ||
          nextProps.spcmReducer.isExternal == null ||
          nextProps.spcmReducer.isExternal == undefined
        ) {
          this.setState({ isExternal: "false" });
        } else {
          this.setState({ isExternal: nextProps.spcmReducer.isExternal });
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.spcmLoader("attribute", "success");
  }

  onAssociatedToComp(e) {
    const { change } = this.props;
    if (e.target.value == "Yes") {
      this.setState({ AssociatedToComp: 1 });
      change(
        "fplan_entityid",
        this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_NAME
      );
    } else if (e.target.value == "No") {
      this.setState({ AssociatedToComp: 0 });
    }
  }

  /* onAssociatedToChange(e){
		const { change } = this.props
			if(e.target.value=="Service"){
					this.setState({AssociatedToDropdown:0});
					change('fplan_entityid',this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_NAME);
			}
			else if(e.target.value=="Component"){
					this.setState({AssociatedToDropdown:1});
			}

	} */
      renderTasktype(dropdownOptions) {
        if (!dropdownOptions) {
          return <option></option>;
        }
        return dropdownOptions.map((option) => {
          return (
            <option
              selected={this.props.initialValues.taskType === option.field1Key}
              value={option.field1Value}
            >
              {option.field1Key}
            </option>
          );
        });
      }
  submitForm(values) {
    const spcmReducer = this.props.spcmReducer;
    const { currentlySelectedServiceDetails } = spcmReducer;
    if (this.props.serviceStatusState == "Deployed") {
      swal({
        text: "changes are not allowed in deployed status.",
        button: this.props.tr["OK"],
      });
    } else {
      if (this.props.spcmReducer.editLeftId > 0) {
        //values.modifiedBy_id=userDetails.user_id;
        values.modifiedBy = "";
      } else {
        //values.createdby_name=userDetails.user_name;
        values.createdBy = "";
        values.modifiedBy = "";
      }

      values.status = this.state.isStatus ? "Active" : "Inactive";

      if (
        values.task_assignment_type === "Contextual" ||
        values.task_assignment_type === 2
      ) {
        console.log("AMS-- Submit If1");
        values.task_assignment_type = 2;
      } else if (
        values.task_assignment_type === "Predefined" ||
        values.task_assignment_type === 1
      ) {
        console.log("AMS-- Submit If2");
        values.task_assignment_type = 1;
      }

      if(values.taskTypeId == 0 || !values.taskTypeId){
        this.setState({ errorTaskType: true });
      } 
      let taskOutputs = [];
      if (values.taskOutputs) {
        let outputs = values.taskOutputs.split(";");

        outputs.forEach((item, i) => {
          let obj = {
            output_key: item,
          };
          taskOutputs.push(obj);
        });
      }

      if (values.taskTypeId === 0 || !values.taskTypeId || values.taskTypeId === "Select") {
        Swal.fire("Please select Task Type");
        return; 
        }

      let templtInfo = {};
      templtInfo.name = values.templateNames;
      templtInfo.description = values.templateNames;
      templtInfo.status = "Active";
      templtInfo.serviceId = Number(values.serviceId);
      templtInfo.author = values.createdBy;
      templtInfo.serviceName = values.serviceName;
      templtInfo.createdBy = values.createdBy;
      templtInfo.modifiedBy = values.createdBy;
      templtInfo.processName = values.processName;
      templtInfo.comp_based_task = values.comp_based_task;
      templtInfo.companyId = values.companyId;
      templtInfo.companyName = values.companyName;
      templtInfo.userCompanyId = userCompanyId;
      templtInfo.taskType = values.taskType;
      templtInfo.taskTypeId = GLOBAL.taskTypeId;
      /* if(values.fplan_def_type =="Service")
				formData.fplan_entityid=this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
	
				formData.offering_id=this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
	
				formData.fplan_exetype=this.state.sequenceNo?"Sequence":"Parallel";
				formData.sequenceNumber =formData.sequenceNumber||1;
	
			formData.fplan_company_name=spcmReducer.serviceSupportCompanyGroupsList.filter((item)=>item.COMPANYID==formData.companyId)[0].COMPANYNAME;
	
			formData.fplan_group_name=spcmReducer.serviceSupportCompanyGroupsList.filter((item)=>item.GROUPID==formData.assignedToGroupId)[0].GROUPNAME; */

      if (this.props.spcmReducer.editLeftId > 0) {
        console.log(
          "this.props.initialValues.comp_based_task",
          this.props.initialValues.comp_based_task
        );
        // Blank componentId if component based task is changed to no
        // if (this.props.initialValues.comp_based_task && (this.props.initialValues.comp_based_task == 'No')) {
        // 	console.log("edava problem");
        // 	values.componentId = '';
        // }
        if (values.comp_based_task && values.comp_based_task == "No") {
          console.log("edava problem");
          values.componentId = "";
        }

        console.log("values");
        console.log(values);
        values.templateTaskId = this.props.spcmReducer.editLeftId;
        let formData = Object.assign({}, _.omit(values, ["templateNames"]));
        formData.taskOutputs = taskOutputs;

        formData.isExternal = this.state.isExternal;
        formData.userCompanyId = userCompanyId;

        console.log("formDataedit");
        console.log(formData);
        console.log("this.state====");
        console.log(this.state);
        //return axios.patch(constants.SERVER+constants.SERVICE_FULFILMENT_PLANS+"/"+this.props.spcmReducer.editLeftId,formData).then((response) => {
        formData.taskTypeId = GLOBAL.taskTypeId;
        this.props.spcmLoader("attribute", "posting");
        return axios
          .patch(
            "/service/api/patchFulfilmentPlan/" +
              this.props.spcmReducer.editLeftId,
            formData
          )
          .then((response) => {
            this.props.getFulfilmentPlans(
              this.props.spcmReducer.currentlySelectedServiceDetails
                .OFFERING_ID,
              values.name
            );
            this.props.setSortingArrows('customArrow dropup','customArrow dropdown')

            this.props.componentFormFun4(false);
            if (values.componentId) {
              this.props.getComponentName(values.componentId);
            }

            this.props.spcmLoader("attribute", "success");
            this.props.getGroupList('',"RESET_GROUPS");
          })
          .catch((error) => {
            // alert('Oops! Something went wrong.');
            this.props.spcmLoader("attribute", "success");
            throw new SubmissionError({
              _error: error.response.data[0].message,
            });
          });
      } else {
        let formData = Object.assign({}, _.omit(values, ["templateNames"]));
        formData.taskOutputs = taskOutputs;
        formData.isExternal = this.state.isExternal;
        templtInfo.tasks = formData;
        templtInfo.isExternal = this.state.isExternal;
        formData.taskTypeId = GLOBAL.taskTypeId;
        console.log("this.state====");
        console.log(this.state);
        console.log("templtInfo====");
        console.log(templtInfo);
        this.props.spcmLoader("attribute", "posting");
        return axios
          .post("/service/api/saveFulfilmentPlan", templtInfo)
          .then((response) => {
            this.props.getFulfilmentPlans(
              this.props.spcmReducer.currentlySelectedServiceDetails
                .OFFERING_ID,
              values.templateNames
            );
            this.props.setSortingArrows('customArrow dropup','customArrow dropdown')

            this.props.getServiceDetailCounts(
              this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
            );
            this.props.componentFormFun4(false);
            this.props.spcmLoader("attribute", "success");
            this.props.getGroupList('',"RESET_GROUPS");
          })
          .catch((error) => {
            // alert('Oops! Something went wrong.');
            this.props.spcmLoader("attribute", "success");
            throw new SubmissionError({
              _error: error.response.data[0].message,
            });
          });
      }
    }
  }

  getData(e) {
		if(e.target.id == "taskTypeId"){
		GLOBAL.taskTypeId = e.target.value
		this.setState({ taskType: e.target[e.target.selectedIndex].text });
	}
  this.props.dispatch(change("serviceFulfilmentPlanForm", "taskType", e.target[e.target.selectedIndex].text));
  this.props.dispatch(change("serviceFulfilmentPlanForm", "taskTypeId", e.target[e.target.selectedIndex].text));
	}

  
  render() {
    const tr = this.props.tr;
    const { change } = this.props;
    const spcmReducer = this.props.spcmReducer;
    const { error, handleSubmit } = this.props;

    console.log("spcmReducer>>>>", spcmReducer);

    let components = spcmReducer.consumptionItems;
    console.log("components", components);
    // components = optionalComp(components);
    console.log("comp", components);

    let companyList = [];
    this.props.spcmReducer.chain_entities_company.map((company) => {
      let companyObj = {};
      companyObj.label = company.field1Key;
      companyObj.value = company.field1Value;
      companyList.push(companyObj);
    });
    let groupList = [];
    this.props.spcmReducer.groups.map((group) => {
      let groupObj = {};
      groupObj.label = group.field1Key;
      groupObj.value = group.field1Value;
      groupList.push(groupObj);
    });
    console.log("companyList===", companyList);
    console.log("spcmReducer.task_contextual_rules===", spcmReducer.task_contextual_rules);


    return (
      <div className="rBoxGap">
        <form onSubmit={handleSubmit(this.submitForm)}>
          {error == undefined || error == null ? null : (
            <Alert
              style={{
                marginBottom: "15px",
                fontSize: "13px",
                padding: "8px 30px 8px 8px",
              }}
              variant="danger"
            >
              <h4>{error}</h4>
            </Alert>
          )}

          <Row>
            {this.props.spcmReducer.editLeftId > 0?
                      <Col md={14}>
                      <Form.Group className="form-group">
                        <Form.Label bsClass="" className="float-l margin-r-20" >
                        {tr['Template Task Code']}
                        </Form.Label>
                        <div className="radioInlineBox float-l" style={{position:"relative",top:"-7px"}}>
                        <Field
                          name="templateTaskCode"
                          component={_inputField}
                          className="form-control"
                          disabled={true}
                          
                        ></Field>
                        </div>
                      </Form.Group>
                    </Col> 
                    : null
            }


            {this.props.spcmReducer.selectedTaskTemplate ==
            "Fullfilment_Template" ? (
              <Col md={12}>
                <Form.Group className="radioInlineBox form-group">
                  <Form.Label bsClass="" className="float-l margin-r-20">
                    <span className="rStar"></span>
                    {tr["Is this component based task?"]}
                  </Form.Label>
                  <div className="radioInlineBox float-l">
                    <Field
                      component={_radio}
                      className="form-control"
                      type="radio"
                      name="comp_based_task"
                      checked={this.state.AssociatedToComp === 1}
                      value="Yes"
                      onChange={this.onAssociatedToComp}
                    />
                    <Field
                      component={_radio}
                      className="form-control"
                      type="radio"
                      name="comp_based_task"
                      checked={this.state.AssociatedToComp === 0}
                      value="No"
                      onChange={this.onAssociatedToComp}
                    />
                  </div>
                  {this.state.AssociatedToComp == 1 ? (
                    <Field
                      component={_dropDown}
                      name="componentId"
                      options={components}
                      className="form-control"
                    />
                  ) : null}
                </Form.Group>
              </Col>
            ) : null}

            <Col md={12}>
              <Form.Group className="form-group">
                <Form.Label bsClass="">
                  <span className="rStar"></span> {tr["Fulfillment Plan For"]}
                </Form.Label>
                <Field
                  name="fulfillmentfor"
                  component={_inputField}
                  className="form-control"
                  placeholder={
                    this.props.spcmReducer.selectedTaskTemplate ==
                    "Fullfilment_Template"
                      ? "Service Orders"
                      : this.props.spcmReducer.selectedTaskTemplate ==
                        "ChangeManagement_Template"
                      ? "Change Requests"
                      : "Critical Incident"
                  }
                  disabled={true}
                ></Field>
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group className="form-group">
                <Form.Label bsClass="">
                  <span className="rStar"></span>
                  {tr["Task"]}
                </Form.Label>
                <Field
                  name="description"
                  component={_textArea}
                  placeholder="Specify"
                  rows={2}
                  className="form-control"
                />
              </Form.Group>
            </Col>

            <Col md={12}>
              <Row>
                <Col md={4}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span>
                      {tr["Sequence"]}{" "}
                    </Form.Label>
                    <Field
                      component={_number}
                      name="sequenceNumber"
                      className="form-control"
                      min={1}
                      max={100}
                      step="1"
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span> {tr["Status"]}
                    </Form.Label>
                    <Form.Check
                      type="checkbox"
                      className="dCheckbox chkboxpow"
                      id={`default-check`}
                      label={`${this.state.isStatus ? "Active" : "In-Active"}`}
                      checked={this.state.isStatus}
                      onClick={() => {
                        this.setState({ isStatus: !this.state.isStatus });
                      }}
                    ></Form.Check>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <Form.Group className="margin-b-5">
                <Form.Label bsClass="">
                <span className="rStar" />{" "} {tr["Task Type"]}
                </Form.Label>
                <Form.Select
                  name="tasktype"
                  id="taskTypeId"
                  as="select"
                  inputRef={(input) => (this.inputGroup = input)}
                  onChange={(event) => {
                    this.getData(event);
                  }}
                  >
                  <option value="0">Select</option>
                  {this.renderTasktype(this.state.oldUrgencyList)}
                  </Form.Select>
                      </Form.Group>
                    </Col>

            <Col md={12}>
              <Form.Group className="form-group">
                <Form.Label bsClass="">
                  <span className="rStar"></span> {"Task Assignment"}
                </Form.Label>
                <Form.Group className="fulfillmepln margin-t-5">
                  <Field
                    component={_radio}
                    className="form-control"
                    type="radio"
                    name="task_assignment_type"
                    checked={this.state.isContexual === 1}
                    value="Predefined"
                    onChange={(e) => this.setIsContexual(e.target.value)}
                  />
                  <Field
                    component={_radio}
                    className="form-control"
                    type="radio"
                    name="task_assignment_type"
                    checked={this.state.isContexual === 2}
                    value="Contextual"
                    onChange={(e) => this.setIsContexual(e.target.value)}
                  />
                </Form.Group>
              </Form.Group>
            </Col>

            {this.state.isContexual === 2 ? (
              <Col md={12}>
                <Form.Group
                  validationState={this.state.errorAMSRule ? "error" : ""}
                  className="form-group"
                >
                  <Form.Label bsClass="">
                    <span className="rStar" />
                    {"Select Assignment Rule"}
                    {(this.state.showRedirectionLink == true) ? (
                    <span
                      style={{ position: "initial" }}
                      className="exterLnkicn"
                    >
                      <Link
                        className="gotoRuleBtnIcn"
                        role="button"
                        to="/rules"
                        title="Go to Rules"
                      >
                        <TbExternalLink />
                      </Link>
                    </span>
                  ) : null}
                  </Form.Label>
                  <RuleAmsDdropDownContextual
                    name="AMSRuleName"
                    component={TypeaheadandDropdown}
                    onSelection={this.onAMSRuleSelect}
                    selectedOptions={this.state.selectedAMSRule}
                    setErrorColor={this.setAMSRuleError}
                    diableStatus={false}
                    onCrossClick={this.onCrossClickAMSRule}
                    onInputChange={this.onAMSRuleChange}
                    typedValue={this.state.typedAMSRule}
                    className="form-control"
                    moduleName="task"
                    ruleType="assignment"
                    companyId={this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID}
                  />
                  {/* <Field
                    name="AMSRuleName"
                    component={TypeaheadandDropdown}
                    options={spcmReducer.task_contextual_rules}
                    onSelection={this.onAMSRuleSelect}
                    selectedOptions={this.state.selectedAMSRule}
                    setErrorColor={this.setAMSRuleError}
                    diableStatus={false}
                    onCrossClick={this.onCrossClickAMSRule}
                    onInputChange={this.onAMSRuleChange}
                    typedValue={this.state.typedAMSRule}
                    className="form-control"
                  /> */}
                </Form.Group>
              </Col>
            ) : (
              <div>
                <Col md={12}>
                  
                  <Form.Group  validationState={this.state.errorCompany ? "error" : ""} className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span> {tr["Fulfilment Company"]}
                    </Form.Label>
                    {/* <Field
                      component={_dropDown}
                      options={companyList}
                      name="companyId"
                      onChange={(e) => {
                        this.onCompanySelect(e.target.value);
                        change(
                          "companyName",
                          e.target[e.target.selectedIndex].text
                        );
                      }}
                      className="form-control"
                    ></Field> */}
                     <Field
                    name="companyId"
                    component={TypeaheadandDropdown}
                    options={companyList}                  
                    onSelection={this.onCompanyFieldSelect}
                    selectedOptions={this.state.selectedCompany}
                    setErrorColor={this.setCompanyError}
                    diableStatus={false}
                    onCrossClick={this.onCrossClickCompany}
                    onInputChange={this.onCompanyChange}
                    typedValue={this.state.typedCompany}
                    className="form-control"
                  />
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group validationState={this.state.errorGroup ? "error" : ""} className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span>{" "}
                      {tr["Default Fulfilment Group"]}
                    </Form.Label>
                    {/* <Field
                      component={_dropDown}
                      options={groupList}
                      name="assignedToGroupId"
                      className="form-control"
                      onChange={(event) => {
                        this.onGroupSelect(event);
                        change(
                          "assignedToGroup",
                          event.target[event.target.selectedIndex].text
                        );
                      }}
                    ></Field> */}
                     <Field
                    name="assignedToGroupId"
                    component={TypeaheadandDropdown}
                    options={groupList}                  
                    onSelection={this.onGroupSelect}
                    selectedOptions={this.state.selectedGroup}
                    setErrorColor={this.setGroupError}
                    diableStatus={false}
                    onCrossClick={this.onCrossClickGroup}
                    onInputChange={this.onGroupChange}
                    typedValue={this.state.typedGroup}
                    className="form-control"
                  />
                  </Form.Group>
                </Col>
              </div>
            )}

            <Col md={12}>
              <Form.Group className="form-group">
                <Form.Label bsClass="">{tr["Output Variables"]} </Form.Label>
                <Field
                  component={_textArea}
                  name="taskOutputs"
                  className="form-control"
                  rows="1"
                />
                <span className="f-size-10">
                  Separated by a semicolon (Ex. Variable 1 ; Variable 2 ;
                  Variable 3)
                </span>
              </Form.Group>
            </Col>

            {this.props.spcmReducer.selectedTaskTemplate ==
            "Fullfilment_Template" ? (
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Label bsClass="">{tr["Process Name"]} </Form.Label>
                  <Field
                    component={_inputField}
                    name="processName"
                    className="form-control"
                    rows="1"
                  />
                </Form.Group>
              </Col>
            ) : null}
          </Row>
          <Row>
            <Col md={12}>
              <ButtonToolbar className="black">
                <Button
                  className="rgSidrkBtn"
                  disabled={this.props.spcmReducer.spcmPostingAttribute}
                  type="submit"
                  size="sm"
                  bsStyle="primary"
                >
                  {this.props.spcmReducer.spcmPostingAttribute ? (
                    <ImSpinner6 className="icn-spinner"/>
                  ) : null}{" "}
                  {tr["Save"]}
                </Button>
                <Button
                  className="rgSiLigBtn"
                  onClick={() => {
                    this.props.componentFormFun4(false);
                  this.props.getGroupList('',"RESET_GROUPS");
            this.props.setSortingArrows('customArrow dropup','customArrow dropdown')
            this.props.getFulfilmentPlans(
              this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.templateName
            );

                  }}
                  bsSize="small"
                  bsStyle="text"
                >
                  {tr["Close"]}
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ spcmReducer }) => {
  let initialValues = {};

  if (spcmReducer.editLeftId > 0) {
    initialValues = {
      ...spcmReducer.editLeftService,
      taskType: spcmReducer.editLeftService.taskType,
      taskTypeId: spcmReducer.editLeftService.task_type_id,
      sequenceNumber: spcmReducer.editLeftService.sequenceNumber || "1",
    };
  } else {
    initialValues = {
      sequenceNumber: "1",
    };
  }

  return {
    spcmReducer,
    initialValues,
  };
};

ComponentForm = reduxForm({
  form: "serviceFulfilmentPlanForm",
  validate,
  enableReinitialize: true,
})(ComponentForm);

export default connect(mapStateToProps, {
  spcmLoader,
  getChainEntitiesCompanyList,
  setLeftEditId,
  getServiceDetailCounts,
  getFulfilmentPlans,
  getConsumptionItems,
  getLOVFullfilmentPlan,
  getComponentName,
  getGroupList,
  getContextualRules,
  resetGroupList
})(ComponentForm);
