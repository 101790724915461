
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Nav from 'react-bootstrap/Nav';
import ListGroup from 'react-bootstrap/ListGroup';
import OnClickOutside from 'react-onclickoutside';
import {connect} from 'react-redux';
import axios from 'axios';

import swal from 'sweetalert';
import { bindActionCreators } from 'redux';
import {checkRelatedTaskForItem} from '_Actions/task/TaskDataAction';
import {loadRelatedBreakFixDataToProblem} from '_Actions/investigation/invRelatedDataAction';
import { loadStatusBasedHamburgerOptions } from '_Actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '_Actions/common/commonApisActions.js';
import Swal from 'sweetalert2';
import { problemStatusMapping } from '../../../common/helper';

class BreakFixHamburger extends React.Component {

	constructor(props) {
		super(props);
		this.state={
			isRCA : false
		};
		this.currentAction = this.currentAction.bind(this);
	}

	componentWillMount() {
		this.props.checkRelatedTaskForItem(this.props.investigationItemDetails.investigationId,"Investigation");
		this.props.loadRelatedBreakFixDataToProblem(this.props.investigationItemDetails.investigationId,"breakfix","Investigation");

		if(this.props.investigationItemDetails.status_id == problemStatusMapping['Closed']){
			axios.get('/serviceManagement/rest/rcaTechnique/'+this.props.investigationItemDetails.investigationId).then((res)=>{
				// console.log('isRCA - ', {res});
					this.setState({isRCA: (res && res.data) ? true : false});
			}).catch((error)=>{
			   // console.log("failed to call Root Cause API check. ",error);
			});
		 }

	}

	currentAction(enableButton) {
		// alert(enableButton +' called');
		switch(enableButton) {
			case 'Provide Attachments':
				if(this.props.investigationItemDetails.status_id == problemStatusMapping['Under Corrective Action']) {
					// if(this.props.realatedTaskCount == 0)
						this.props.changeActionView('attachment');
					// else
					// 	alert('Tasks related to the item are not completed yet');
				}
				else
					this.props.changeActionView('attachment');
				break;
			case 'Notify':
				this.props.changeActionView('notify');
				break;
			case 'Qualify as Problem':
				if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident'))
				swal({
					text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
					button: this.props.translator['OK'],
				  });
                else if((this.props.investigationItemDetails.status_id == problemStatusMapping['Under Review']) && (this.props.investigationItemDetails.asgPmGroupid==null||this.props.investigationItemDetails.asgPmGroupid===''||this.props.investigationItemDetails.asgPmGroupid==='0'||this.props.investigationItemDetails.asgPmGroupid===0))
				swal({
					text: this.props.translator["Please select problem management group"],
					button: this.props.translator['OK'],
				  })
				  
				else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') ) && (this.props.investigationItemDetails.status_id != problemStatusMapping['Under Review']))
				   swal({
					  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
					  button: this.props.translator['OK'],
				    });
					else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId)
				  {
					swal({
						text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
						button: this.props.translator['OK'],
					  });
				  }
				else
				  this.props.changeActionView('qualifyAsProblem');
				break;
			case 'Disqualify as Problem':
				// if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident'))
				// swal({
				// 	text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
				// 	button: this.props.translator['OK'],
				//   });
                // else
				if((this.props.investigationItemDetails.status_id == problemStatusMapping['Under Review']) && (this.props.investigationItemDetails.asgPmGroupid==null||this.props.investigationItemDetails.asgPmGroupid===''||this.props.investigationItemDetails.asgPmGroupid==='0'||this.props.investigationItemDetails.asgPmGroupid===0))
				swal({
					text: this.props.translator["Please select problem management group"],
					button: this.props.translator['OK'],
				})
				
				else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') ) && (this.props.investigationItemDetails.status_id != problemStatusMapping['Under Review']))
				   swal({
					  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
					  button: this.props.translator['OK'],
				    });
					else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId)
				{
				  swal({
					  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
					  button: this.props.translator['OK'],
					});
				}
				else
				  this.props.changeActionView('disqualifyAsProblem');
				break;
			case 'Cancel':
				if(this.props.realatedTaskCount == 0)
				// if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident'))
				// swal({
				// 	text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
				// 	button: this.props.translator['OK'],
				//   });
                // else 
				if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') ))
				    swal({
				      text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
				      button: this.props.translator['OK'],
				    });
			      else
				    this.props.changeActionView('cancel');
				else
					Swal.fire('Tasks related to the item are not completed yet');
				break;
			case 'View Investigation Team':
				this.props.changeActionView('problemTeam');
				break;	
			case 'Form Investigation Team':
			case 'Investigation Team':
				// if (this.props.user_id !== this.props.investigationItemDetails.asgPmId) {
				// 	swal({
				// 		text: this.props.translator["Please assign the ticket to yourself before creating problem team"],
				// 		button: this.props.translator['OK'],
				// 	});
				// } else {
					this.props.changeActionView('investigationTeam');
				
				//}
				break;
			case 'Specify Vendor Tickets':
			case 'Specify Vendor Details':
					this.props.changeActionView("VendorTickets");
					break;
			case 'Record Problem Findings':
				 this.props.changeActionView('recordinvestigationFindings');
				 break;   	 
			case 'View Problem Findings':
				 this.props.changeActionView('viewinvestigationFindings');
				 break;
			case 'View Specify Root Cause':
			case 'Specify Root Cause':
				if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident'))
				swal({
					text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
					button: this.props.translator['OK'],
				  });
                // else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') ))
				//     swal({
				//       text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
				//       button: this.props.translator['OK'],
				//     });
			    else
				  this.props.changeActionView('rootCause');
				break;
			case 'Corrective Action Required':
				if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident'))
				swal({
					text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
					button: this.props.translator['OK'],
				  });
                else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') ))
				   swal({
					  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
					  button: this.props.translator['OK'],
				    });
				else
				  this.props.changeActionView('correctiveActionRequired');
				break;
			case 'Corrective Action Not Required':
				if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident'))
				swal({
					text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
					button: this.props.translator['OK'],
				  });
                else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') ))
				    swal({
				      text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
				      button: this.props.translator['OK'],
				    });
			    else
				  this.props.changeActionView('correctiveActionNotRequired');
				break;
			case 'Propose Change':
				  this.props.changeActionView('change');
				break;
			case 'Close Problem':
				if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident'))
				swal({
					text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
					button: this.props.translator['OK'],
				});
				else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId)
				{
				  swal({
					  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
					  button: this.props.translator['OK'],
					});
				}
                else if(this.props.realatedTaskCount == 0)
				//    if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') ))
				//       swal({
				//         text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
				//         button: this.props.translator['OK'],
				//       });
			    //    else
					 this.props.changeActionView('close');
				else
					Swal.fire('Tasks related to the item are not completed yet');
				break;
			case 'Root Cause Details':
				if( ['45', '50', '55', '60', '65', '70'].includes(this.props?.investigationItemDetails?.status_id) ){
					this.props.changeActionView('rootCauseDetails_view');
				}
				else{
					if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident')){
						swal({
							text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
							button: this.props.translator['OK'],
						  });
						  return;
					}
						this.props.changeActionView('rootCauseDetails');
				}
				break;
		}
	}

	handleClickOutside() {
		this.props.fun(false);
	}

	renderHamburgerGOptions(statusBasedHamburgerOptions) {
		// console.log('---------statusBasedHamburgerOptions---------');
		// console.log(statusBasedHamburgerOptions);
        const { 
			investigationItemDetails: {
				investigationFinding = null
			}
		} = this.props;
		if(statusBasedHamburgerOptions.length == 0){
			return(
					null
			);
		}

		function labelMapping(label){
			try{
				let labelMapping = {
					'Specify Vendor Tickets': 'Specify Vendor Details'
				};
				return ((labelMapping[label]) ? labelMapping[label] : label);
			}catch(e){
				return label;
			}
		}

		// console.log('*****', {state: this.state, props: this.props});
		const { investigationItemDetails : { status_id = "" } = {}, investigationTeamList } = this.props;
		const { isRCA } = this.state;

		// console.log('******* ', {statusBasedHamburgerOptions, isRCA, investigationTeamList});


		return statusBasedHamburgerOptions.map((item,index)=>{
			
			// console.log('hamburger item',item);
			if(item.enableButton==='View Investigation Team' && this.props.investigationTeamList && this.props.investigationTeamList.length===0 || this.props.investigationItemDetails.status_id == problemStatusMapping['Under Review']){
				return null;
			}
			if(item.action !== 'L'){
				if(item.action == 'G'){		
					// if (item.enableButton === 'Record Problem Findings' && investigationFinding === 'Y') {
					// 	item.enableButton = 'View Problem Findings';
					// }
					if(this.props.fieldStatus.notify==false&&item.enableButton=="Notify")
					{
						item.enableButton="blank";
					}
					if(this.props.investigationItemDetails.status_id != problemStatusMapping["Cancelled"]&&this.props.investigationItemDetails.status_id != problemStatusMapping["Closed"]&&this.props.investigationItemDetails.status != "Rejected"&&this.props.fieldStatus.provideAttachments == false && item.enableButton == "Provide Attachments" )
					{
						item.enableButton = "blank";

					}	
					
					if(status_id == problemStatusMapping['Closed'] && ( (item.enableButton == 'View Problem Findings' && !isRCA) || (item.enableButton == 'Investigation Team' && this.props.investigationTeamList && this.props.investigationTeamList.length===0) )){
						// item.enableButton = "blank";
						return null;
					}		
					return(
						(item.enableButton == "Provide Attachments" || item.enableButton =="Notify" ? "" :
						<ListGroup.Item bsClass="" key={index}><Nav.Item title={item.enableButton} onClick={()=>{this.currentAction(item.enableButton); this.handleClickOutside(); this.props.rightEditPanel(true);}}>{item.enableButton=="blank"?null:<i className="fa fa-angle-double-right" ></i>}{item.enableButton=="blank"?null:labelMapping(item.enableButton)}</Nav.Item></ListGroup.Item>)
					);
				}
			}
		});
	}
	
	render(){

		// console.log('**********', {state: this.state, props: this.props});

		// console.log("render() of BreakFixHamburger has been called")
		// console.log("this.props.investigationItemDetails : ",this.props.investigationItemDetails)
		// console.log("this.props.user_id                  : ",this.props.user_id)
		// console.log('=============',this.props.statusBasedHamburgerOptions);

		return(
				<div className="breakFixHamburger">
					<ListGroup bsClass="" className="myLinks" style={{"top":"12px"}}>
						{this.renderHamburgerGOptions(this.props.statusBasedHamburgerOptions)}
					</ListGroup>
				</div>
		);
	}

}

BreakFixHamburger = OnClickOutside(BreakFixHamburger);

function mapStateToProps({investigationItemDetails,fieldStatus, invRelatedDataDetailsCount, statusBasedHamburgerOptions}) {
	return {
		investigationItemDetails:(investigationItemDetails && investigationItemDetails.data ? investigationItemDetails.data[0]:investigationItemDetails),
		invRelatedDataDetailsCount,
        statusBasedHamburgerOptions,
		fieldStatus
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ checkRelatedTaskForItem, loadStatusBasedHamburgerOptions, loadTimelineData, loadRelatedBreakFixDataToProblem }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BreakFixHamburger);
