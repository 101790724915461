
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row,Col, Form, ButtonToolbar, Button } from 'react-bootstrap';
import { connect, useSelector } from "react-redux";
import { change, Field, reduxForm } from 'redux-form';
import { _inputField, _dropDown, _textArea, TypeaheadandDropdown, TypeaheadExampleSingleSelect } from '../../../../common/ReduxFormFields/CategoryBoard/formFields';

let UserInfoForm = (props) => {
    const { userData : { status } = {} } = props;
    const tr = useSelector((state) => state.spcmReducer.tr);
    const authenticationRedData = useSelector((state) => state.authenticationRed);
    const dateListREdData = useSelector((state) => state.dateListREd);
    const languageListRedData = useSelector((state) => state.languageListRed);
    const userTypeRedData = useSelector((state) => state.userTypeRed);
    const timeZoneData = useSelector((state) => state.timezoneRed);
    const currencyRedData = useSelector((state) => state.currencyRed);
    const EDITUSERData = useSelector((state) => state.editDetailsRed);
    const countryListRedData = useSelector((state) => state.countryRed);
   
    const  validateGroupsField = (fieldType, event)=> {
    switch (fieldType) {
      case "userTypeId":
        if (event.target.value == "") {
          props.setUserTypeErr("error");
        } else {
          props.setUserTypeErr("");
        }
        break;
      case "dateformat":
        if (event.target.value == "") {
          props.setDateFormErr("error");
        } else {
          props.setDateFormErr("");
        }
        break;
      case "baseCurrency":
        if (event.target.value == "") {
          props.setCurrencyErr("error");
        } else {
          props.setCurrencyErr("");
        }
        break;
      case "timeZone":
        if (event.target.value == "") {
          props.setTimeZoneErr("error");
        } else {
          props.setTimeZoneErr("");
        }
        break;
      case "language":
        if (event.target.value == "") {
          props.setLanguageErr("error");
        } else {
          props.setLanguageErr("");
        }
        break;
      case "authenticationSource":
          if (event.target.value == "") {
            props.setAuthSource("error");
          } else {
            props.setAuthSource("");
          }
          break;
      default:
    }
}

    return (
      <Row className="padding-10 tabFormDv">
        <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
            User Type
            </Form.Label>
            <Field
              component={_dropDown}
              options={userTypeRedData || []}
              name="userTypeId"
              className={"form-control "} 
              // + props.userTypeErr}
              // onBlur={(event) => {
              //   validateGroupsField("userTypeId", event);
              // }}
            ></Field>
          </Form.Group>
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
            Date Format
            </Form.Label>
            <Field
              component={_dropDown}
              options={dateListREdData || []}
              name="dateformat"
              className={"form-control " } 
              // +props.dateFormErr
              // onBlur={(event) => {
              //   validateGroupsField("dateformat", event);
              // }}
            ></Field>
          </Form.Group>
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
            Time Zone
            </Form.Label>
            <Field
              component={_dropDown}
              options={timeZoneData || []}
              name="timeZone"              
              className={"form-control "}>
                {/* + props.timeZoneErr
              onBlur={(event) => {
                validateGroupsField("timeZone", event);
              }} */}
            
            </Field>
          </Form.Group>
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
            Preferred Currency
            </Form.Label>
            <Field
              component={_dropDown}
              options={currencyRedData || []}
              name="baseCurrency"
              className={"form-control "}/>
               {/* + props.currencyErr
              onBlur={(event) => {
                validateGroupsField("baseCurrency", event);
              }} */}
          </Form.Group>
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label><span className="rStar"></span>
            Authentication Source
            </Form.Label>
            <Field
              component={_dropDown}
              options={authenticationRedData || []}
              name="authenticationSource"
              className={"form-control " + props.authSource}
              disabled={EDITUSERData && EDITUSERData.selfSignFlag==="false"?false:true}
              onBlur={(event) => {
                validateGroupsField("authenticationSource", event);
              }}
            ></Field>
          </Form.Group>
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>Data Source</Form.Label>
            <Field
              name="datasource"
              component="select"
              className="form-control"
            >
              <option value="null">{tr["Select"]}</option>
              <option value="ActiveDirectory">ActiveDirectory</option>
              <option value="Automated">Automated</option>
              <option value="Manual">Manual</option>
              <option value="OtherApplication">OtherApplication</option>
            </Field>
          </Form.Group>
        </Col>
        <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
            Language Preferences
            </Form.Label>
            <Field
              component={_dropDown}
              options={languageListRedData || []}
              name="language"
              className={"form-control " + props.languageErr}
              // onBlur={(event) => {
              //   validateGroupsField("language", event);
              // }}
            ></Field>
          </Form.Group>
        </Col>
        <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>Nationality</Form.Label>
                <Field
                        name="nationality"
                        className={"form-control " }
                        component={_inputField}
                        maxLength={200}
                    />
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>Building</Form.Label>
                <Field
                        name="building"
                        className={"form-control " }
                        component={_inputField}
                    />
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>Cubicle</Form.Label>
                <Field
                        name="cubicle"
                        className={"form-control " }
                        component={_inputField}
                    />
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>Country</Form.Label>
                <Field
                        name="country"
                        className={"form-control"}
                        component={_dropDown}
                        options={countryListRedData || []}
                    />
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>State</Form.Label>
                <Field
                        name="state"
                        className={"form-control " }
                        component={_inputField}
                        maxLength={100}
                    />
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>City</Form.Label>
                <Field
                        name="city"
                        className={"form-control " }
                        component={_inputField}
                        maxLength={100}
                    />
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>Street</Form.Label>
                <Field
                        name="street"
                        className={"form-control " }
                        component={_inputField}
                        maxLength={1000}
                    />
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>Zip</Form.Label>
                <Field
                        name="zip"
                        className={"form-control " }
                        component={_inputField}
                        maxLength={10}
                    />
            </Form.Group>
            </Col>
      </Row>
    );
}

// export default UserInfoForm;
UserInfoForm = reduxForm({
    form: "EditUserform",
    enableReinitialize: true,
  })(UserInfoForm);
  UserInfoForm = connect(({ editDetailsRed }) => ({
    // initialValues: {
    //   language: editDetailsRed.language,
    //   datasource: editDetailsRed?.datasource,
    //   authenticationSource: editDetailsRed?.authenticationSource,
    //   status: editDetailsRed?.status,      
    //   baseCurrency: editDetailsRed?.baseCurrency,      
    //   timeZone: editDetailsRed?.timeZone,      
    //   dateformat: editDetailsRed?.dateformat,      
    //   userTypeId: editDetailsRed?.userTypeId,      
    // },
  }))(UserInfoForm);


export default UserInfoForm;