
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL }  from '../../components/Globals';
import Cookies from "universal-cookie";
const cookie = new Cookies();
let dateformat = cookie.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];

export const loadTaskAuditLogDetails = (taskCode,sortType="desc", isLoaderRequired="Yes",requestType="") => {
    return dispatch => {
        // dispatch({type:'LOADER_IN_PROGRESS'});
        if(isLoaderRequired=="Yes"){
          dispatch({type:'LOADER_IN_PROGRESS'});
        }
  
        // alert(GLOBAL.getTaskAuditDataURL + taskCode + "/" + sortType);
        axios.get(GLOBAL.getTaskAuditDataURL + taskCode + "/" + sortType+ "/" + requestType)
            .then(response => {
                if(response.status==200){
                    console.log('response in action');
                    console.log(response);
                    response.data.map(item=>{
                        if(item.fieldName==="TAG"){
                           
                            }
                    });
                    dispatch({type:'TASK_AUDIT_DATA_FETCHED',payload:response.data});
                }
                dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
            })
            .catch((err) => {
                console.log('err in action');
                console.log(err);
                dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
            })
    };
};