
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import moment from "moment";
import momenttz from "moment-timezone";
import {
  Field,
  reduxForm,
  change as changeForm,
  formValueSelector,
  getFormValues,
} from "redux-form";
import {
  _inputField,
  _textArea,
  _dropDown,
  _dateField,
  _textAreaMaxlength,
} from "../../../common/ReduxFormFields/formFields";
import { Row, Col, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { GLOBAL } from "../../../Globals";
import { ImInfo } from "react-icons/im";
import { validateTaskField } from "../../../../utils/common";
import {
  loadRequestorAndServiceData,
  loadTaskItemToEdit,
  loadTaskGroup,
  loadAsignedTo,
  flushGroupList,
  flushAssigntoList,
  getConfigurableTaskTypes,
} from "../../../../actions/task/TaskDataAction";

import LoadingIcons from "react-loading-icons";
import Loader from "../../../../components/common/loaders/loader.js";

import _ from "lodash";

import { _dateTimeField } from "../../../common/ReduxFormFields/formFields";
import Cookies from "universal-cookie";
import { getAllgroup } from "../../../../actions/amsActions/amsActions";
import { loadNewBreakFixSGAEEdit } from "../../../../actions/breakFix/serviceGroupAEAction";
import { TypeaheadExampleSingleSelect } from "../../../common/formFields";

import { RiUserSearchLine } from "react-icons/ri";
import { RuleAmsDdropDown } from "../../../common/forms/ruleDropDown";
import { getAdminPreferences, emptyAdminPreferencesListData } from '_Actions/adminPreferences/adminPreferencesAction';
import axios from 'axios';
import swal from "sweetalert";
import { taskStatusMapping } from "../../../common/helper.js";

const cookies = new Cookies();
let languageSelected = cookies.get("gph");
languageSelected = languageSelected.split("~");
languageSelected = languageSelected[20];

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
//console.log("homepage::::"+homepagelocation);
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
//console.log("homepage::::"+homepagelocation);
timezone = timezone.split("~");
timezone = timezone[3];
let jwtdf = dateformat.slice(0, 10).toUpperCase();
let timeZone = timezone ? timezone : "";
let regex = /^[0-9\s]*$/;

const validate = (values, props) => {
  console.log("values===================");
  console.log(values);
  console.log("props===================");
  console.log(props);

  let errors = {};
  if (!values.description) {
    errors.description = "enter description.";
  }
  if (values.description) {
    if (values.description.trim().length == 0)
      errors.description = "enter description.";
  }
  if (
    values.taskTypeId == 0 ||
    values.taskTypeId == "" ||
    values.taskTypeId == null ||
    values.taskTypeId == undefined
  ) {
    errors.taskTypeId = "Select Task Type.";
  }

  let seqValue = values.sequenceNumber;
  let min = 1;
  let max = 100;
  if (parseInt(seqValue, 10) < min || isNaN(parseInt(seqValue, 10))) {
    errors.sequenceNumber = "Minimum value must be 1.";
  } else if (parseInt(seqValue, 10) > max) {
    errors.sequenceNumber = "Maximum value must be 100.";
  }
  if (!regex.test(values.sequenceNumber)) {
    errors.sequenceNumber = "please enter numbers only.";
  }
  if (!values.sequenceNumber || values.sequenceNumber == "") {
    errors.sequenceNumber = " please enter the sequence number.";
  }

  if (!values.statusId || values.statusId == "") {
    errors.statusId = "select status.";
  }
  if (!values.assignedToGroupId) {
  }

  if (!values.expectedStartDate) {
    errors.expectedStartDate = " ";
  }
  if (!values.expectedDueDate) {
    errors.expectedDueDate = " ";
  }
  if (typeof values.expectedStartDate !== "string") {
    if (!moment(values.expectedStartDate, jwtdf + " hh:mm A", true).isValid()) {
      errors.expectedStartDate = "Enter a valid date.";
    }
  }
  if (typeof values.expectedDueDate !== "string") {
    if (!moment(values.expectedDueDate, jwtdf + " hh:mm A", true).isValid()) {
      errors.expectedDueDate = "Enter a valid date.";
    }
  }

  if (values.expectedStartDate || values.expectedDueDate) {
    let esd='',edd='';
    if((values.workItemType == "Change" || values.workItemType == "changeManagement")){
      esd = moment(values.expectedStartDate,jwtdf + " HH:mm:ss");
      edd = moment(values.expectedDueDate,jwtdf + " HH:mm:ss");
    }else{
      esd = momenttz(values.expectedStartDate, jwtdf + " hh:mm A");
      edd = momenttz(values.expectedDueDate, jwtdf + " hh:mm A");
    }
    if((values.workItemType == "Change" || values.workItemType == "changeManagement") && esd.isAfter(edd)) {
      errors.expectedStartDate = "start date should fall before due date.";
      errors.expectedDueDate = "start date should fall before due date.";
    } else if (
      (values.workItemType == "Change" ||
        values.workItemType == "changeManagement") 
    ) {
      let scheduleStartDate = moment(props.editChangeInitialData.expectedStartDate,jwtdf + " HH:mm:ss");
      let scheduleEndDate = moment(props.editChangeInitialData.expectedEndDate,jwtdf + " HH:mm:ss");
      let isScheduledStartSame = esd.format(jwtdf + " HH:mm") === scheduleStartDate.format(jwtdf + " HH:mm");
      let isScheduledEndSame = edd.format(jwtdf + " HH:mm") === scheduleEndDate.format(jwtdf + " HH:mm");
      if ( !isScheduledStartSame &&
        !(
          esd.isAfter(scheduleStartDate) &&
          esd.isBefore(scheduleEndDate)
        )
      ) {
        errors.expectedStartDate =
          "Expected Start Date should fall within Change Schedule.";
      }
      if ( !isScheduledEndSame &&
        !(
          edd.isAfter(scheduleStartDate) &&
          edd.isBefore(scheduleEndDate)
        )
      ) {
        errors.expectedDueDate =
          "Expected End Date should fall within Change Schedule.";
      }
    }else if(momenttz(esd).isAfter(edd)){
      errors.expectedStartDate = "start date should fall before due date.";
      errors.expectedDueDate = "start date should fall before due date.";
    }
  }
  return errors;
};
const removeDuplicates = (originalArray, prop) => {
  var newArray = [];
  var lookupObject = {};

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
    if (lookupObject.hasOwnProperty(i)) {
      newArray.push(lookupObject[i]);
    }
  }
  return newArray;
};
let EditTaskForm = class EditTaskForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      AllAMSGrps: false,
      ShowAllAMSGRP: true,
      allGroupData: "",
      selectedAssignmentGrpList: [],
      selectedAssignToList: [],
      assignmentGrpListErrorColor: "",
      initialStatus: "",
      additionalcharleft: 500,
      additionalSummaaryCharLeft: 4000,
      requestorValue: "",
      showRequestorInfo: true,
      assignedToValue: "",
      showAssignedToInfo: true,
      isCiNameInfoIcn: true,
      isServiceInfoIcn: true,
      rulesAttribute: {},
      selectedReviewerList: [],
      employeeList: [],
      reviewerValue: '', 
      reviewerInfo: false,
      typedGroupValue: '',
      typedAssignedToValue: '',
      typedReviewerValue: '',
      eventList: [],
      isFetchedEventList: false,
			eventId: "0"
      // assignToListErrorColor: ''
      // selectedAssignToOptions:[]
    };
    this.AllGrps = this.AllGrps.bind(this);
    this.AllAMSGrps = this.AllAMSGrps.bind(this);
    //this.ShowAMSGroup = this.ShowAMSGroup.bind(this);
    this.onAssignmentGrpSelection = this.onAssignmentGrpSelection.bind(this);
    this.setAssignmentGrpErrorColor =
      this.setAssignmentGrpErrorColor.bind(this);
    this.onAssignToSelection = this.onAssignToSelection.bind(this);
    this.clearAssignedTo = this.clearAssignedTo.bind(this);
    this.clearAssignedToGroupId = this.clearAssignedToGroupId.bind(this);
    this.setAssignToErrorColor = this.setAssignToErrorColor.bind(this);
    this.onAssignmentGroupChange = this.onAssignmentGroupChange.bind(this);
    this.onAssignedToChange = this.onAssignedToChange.bind(this);
    this.isCiNameFun = this.isCiNameFun.bind(this);
    this.isServiceFun = this.isServiceFun.bind(this);
    this.summaryCharacter = this.summaryCharacter.bind(this);
    this.onReviewerSelection = this.onReviewerSelection.bind(this);
    this.setReviewerError = this.setReviewerError.bind(this);
    this.onReviewerChange = this.onReviewerChange.bind(this);
    this.clearReviewer = this.clearReviewer.bind(this);
    this.retriveEmployees = this.retriveEmployees.bind(this);
  }
  isServiceFun(e, oldVal) {
    console.log("66666666", e.target.value, "-----", oldVal);
    if (e.target.value === oldVal) {
      this.setState({ isServiceInfoIcn: true });
    } else {
      this.setState({ isServiceInfoIcn: false });
    }
  }
  isCiNameFun(e, oldVal) {
    console.log("66666666", e.target.value, "-----", oldVal);
    if (e.target.value === oldVal) {
      this.setState({ isCiNameInfoIcn: true });
    } else {
      this.setState({ isCiNameInfoIcn: false });
    }
  }

  componentWillMount() {
    const modules = {
			Incident: "Incident",
			Fullfilment: "Fulfillment",
			Problem: "Problem",
			Change: "Change"
		}
		const getModule = modules[this.props.initialValues.workItemType];

    if(!this.state.isFetchedEventList){
      let config = {
        headers: { 
          'query' : JSON.stringify({module: getModule, field1_name: "event"})
        }
        }
      axios.get('/api/getmenulist', config).then((resp) => {
        this.setState({isFetchedEventList: true});
        const options = resp?.data.map((option) => {
          return {
            label: option.field1_key,
            value: option.field1_value
          }
        })
        this.setState({ eventList: options})
      })
    }
  }
  componentWillReceiveProps(nextProps) {
    let amsAttribute = {
      Status: nextProps.editFormValues.statusId,
      Priority: "",
      Service: nextProps.editFormValues.serviceId,
      "Impacted CI": nextProps.editFormValues.ciId,
      Requestor: nextProps.editFormValues.requesterId,
      Class: 0,
      "User.Location": 0,
      "CI.Location": 0,
      serviceCompanyId: nextProps.editFormValues.serviceCompanyId,
      serviceCompanyName: nextProps.editFormValues.serviceCompanyName,
    };

    let rulesAttribute = {
      status: nextProps.editFormValues.statusId,
      priorityId: undefined,
      serviceName: nextProps?.editFormValues?.serviceName,
      impactedCi: nextProps?.editFormValues?.ciName,
      impactedUsername: nextProps?.editFormValues?.requesterName,
      ciClassName: undefined,
      userLocation: 0,
      ciLocation: 0,
      serviceId: nextProps.editFormValues.serviceId,
      impactedCiId: nextProps.editFormValues.ciId,
      requestorId: nextProps.editFormValues.requesterId,
      serviceCompId: nextProps.editFormValues.serviceCompanyId,
    };
    this.setState({ rulesAttribute });


    if (
      nextProps.editFormValues.isExternalFulfilled !=
      this.props.editFormValues.isExternalFulfilled
    ) {
      GLOBAL.isExternalTaskEdit = nextProps.editFormValues.isExternalFulfilled;
    }
    if (
      nextProps.editFormValues.statusId != this.props.editFormValues.statusId
    ) {
      this.setState({ initialStatus: nextProps.editFormValues.statusId });
    }

    if (
      (nextProps.taskDetails.workItemType === "Change" || nextProps.taskDetails.workItemType === "ChangeManagement") &&
      (nextProps.taskDetails.eventId == '1')
    ) {
      // alert("Alert")
      let changeId = nextProps.taskDetails.workItemId;
      console.log("nextProps", nextProps.taskDetails.workItemType);
    }

    if (
      this.props.selectedAssignmentUser !== nextProps.selectedAssignmentUser &&
      nextProps.selectedAssignmentUser.length > 0
    ) {
      this.onAssignToSelection(nextProps.selectedAssignmentUser);
    }
  }
  componentDidMount() {
    let amsAttribute = {
      Status: this.props.initialValues.statusId,
      Priority: "",
      Service: this.props.initialValues.serviceId,
      "Impacted CI": this.props.initialValues.ciId,
      Requestor: this.props.initialValues.requesterId,
      Class: 0,
      "User.Location": 0,
      "CI.Location": 0,
      serviceCompanyId: this.props.initialValues.serviceCompanyId,
      serviceCompanyName: this.props.initialValues.serviceCompanyName,
    };
    let isChangeImplementation = (this.props.initialValues.workItemType==='Change' || this.props.initialValues.workItemType==='ChangeManagement') &&  this.props.initialValues.eventId == '1';
    if(isChangeImplementation){
      let review_statuses = this.props.initialValues.statusId==taskStatusMapping['In Review'] || this.props.initialValues.statusId==taskStatusMapping['Reviewed'];
      let after_statuses = this.props.initialValues.statusId==taskStatusMapping['Completed'] || review_statuses;
      let status_for_review = this.props.initialValues.statusId==taskStatusMapping['In Progress'] || after_statuses;
      let isLoggedIn_as_Reviewer = this.props.user_id==this.props.editFormValues.reviewerId;
      let disableForReviewer = isLoggedIn_as_Reviewer && after_statuses;
      this.setState({disableForReviewer,isLoggedIn_as_Reviewer});
      if(this.props.initialValues.reviewerId!=null && this.props.initialValues.reviewerId!=='' && this.props.initialValues.reviewerId!=0){
        let reviewerSelected = [];
        reviewerSelected.push({id: this.props.initialValues.reviewerId, label: this.props.initialValues.reviewerName});
        this.setState({reviewerInfo: true});
        this.setState({selectedReviewerList: reviewerSelected});
      } 
      this.setState({areReviewStatuses: status_for_review, disabledForStatuses: after_statuses});
      this.setState({isChangeImplementation});
      this.props.emptyAdminPreferencesListData();
      this.props.getAdminPreferences(this.props.initialValues.companyId,'change','8');
    }

    let rulesAttribute = {
      status: this.props.initialValues.statusId,
      priorityId: undefined,
      serviceName: this.props?.initialValues?.serviceName,
      impactedCi: this.props?.initialValues?.ciName,
      impactedUsername: this.props?.initialValues?.requesterName,
      ciClassName: undefined,
      userLocation: 0,
      ciLocation: 0,
      serviceId: this.props.initialValues.serviceId,
      impactedCiId: this.props.initialValues.ciId,
      requestorId: this.props.initialValues.requesterId,
      serviceCompId: this.props.initialValues.serviceCompanyId,
    };
    this.setState({ rulesAttribute });

    if (
      this.props.initialValues.assignedToGroupId &&
      this.props.initialValues.assignedToGroupId > 0
    ) {
      let selectedAssignGroup = [
        {
          id: this.props.initialValues.assignedToGroupId,
          label: this.props.initialValues.assignedToGroup,
        },
      ];
      this.setState({
        selectedAssignmentGrpList: selectedAssignGroup,
      });
      this.props.setAssignmentGrpUsr(selectedAssignGroup, "group");
    }
    if (
      this.props.initialValues.assignedToId &&
      this.props.initialValues.assignedToId > 0
    ) {
      this.setState({
        selectedAssignToList: [
          {
            id: this.props.initialValues.assignedToId,
            label: this.props.initialValues.assignedTo,
          },
        ],
      });
    }
    if (
      this.props.initialValues.statusId &&
      this.props.initialValues.statusId > 0
    ) {
      this.setState({ initialStatus: this.props.initialValues.statusId });
    }
    if (
      this.props.initialValues.additionalParams &&
      this.props.initialValues.additionalParams.length > 0
    ) {
      this.setState({
        additionalcharleft:
          500 - this.props.initialValues.additionalParams.length,
      });
    }
    if (
      this.props.initialValues.description &&
      this.props.initialValues.description.length > 0
    ) {
      this.setState({
        additionalSummaaryCharLeft:
          4000 - this.props.initialValues.description.length,
      });
    }
    GLOBAL.isExternalTaskEdit =
      this.props.initialValues.isExternalFulfilled == null ||
      this.props.initialValues.isExternalFulfilled == "" ||
      this.props.initialValues.isExternalFulfilled == undefined
        ? false
        : this.props.initialValues.isExternalFulfilled;

    if (
      this.props.initialValues.taskType == null ||
      this.props.initialValues.taskType == "" ||
      this.props.initialValues.taskType == undefined
    ) {
      if (this.props.initialValues.workItemType == "Change") {
        this.props.getConfigurableTaskTypes(this.props.initialValues?.companyId,'Change','Task Type',languageSelected,'1');
      } else if (this.props.initialValues.workItemType == "Problem") {
        this.props.getConfigurableTaskTypes(this.props.initialValues?.companyId,'Problem','Task Type',languageSelected,'1');
      } else if (this.props.initialValues.workItemType == "Fullfilment") {
        this.props.getConfigurableTaskTypes(this.props.initialValues?.companyId,'Fulfillment','Task Type',languageSelected,'1');
      } else if (this.props.initialValues.workItemType == "Incident") {
        this.props.getConfigurableTaskTypes(this.props.initialValues?.companyId,'Incident','Task Type',languageSelected,'1');
      }
    // }

  }
  if (
    this.props &&
    this.props.editFormValues &&
    this.props.editFormValues.requesterId &&
    !isNaN(this.props.editFormValues.requesterId) &&
    !!parseInt(this.props.editFormValues.requesterId, 10)
  ) {
    this.setState({
      requestorValue: this.props.editFormValues.requesterId,
      showRequestorInfo: true,
    });
  } else {
    this.setState({ requestorValue: "", showRequestorInfo: false });
  }

  if (
    this.props &&
    this.props.editFormValues &&
    this.props.editFormValues.assignedToId &&
    !isNaN(this.props.editFormValues.assignedToId) &&
    !!parseInt(this.props.editFormValues.assignedToId, 10)
  ) {
    this.setState({
      assignedToValue: this.props.editFormValues.assignedToId,
      showAssignedToInfo: true,
    });
  } else {
    this.setState({ assignedToValue: "", showAssignedToInfo: false, typedAssignedToValue: ''});
  }
  }
  AllGrps(e) {
    this.props.flushGroupList();
    this.props.flushAssigntoList();
    this.props.getAllgroup(this.props.taskDetails.clientId);
    this.props.setAssignmentGrpUsr([], "group");
    this.setState({
      AllAMSGrps: true,
      ShowAllAMSGRP: false,
      assignmentGrpFlag: false,
      selectedAssignmentGrpList: [],
      assignmentGrpListErrorColor: "error",
      selectedAssignToList: [],
      assignedToValue: "",
      showAssignedToInfo: false,
      typedAssignedToValue: ''
    });
    this.props.dispatch({
      type: "TASK_ASSIGNED_TO_DATA_LOADED",
      taskAssignedToData: [],
    });
    console.log("this.state.allGroupData==============");
    console.log(this.state.allGroupData);
    this.props.change("assignedToGroupId", "");
    this.props.change("assignedToGroup", "");
    this.props.change("supportCompanyID", "");
    this.props.change("supportCompanyName", "");
  }
  AllAMSGrps(e) {
    this.props.flushGroupList();
    this.props.flushAssigntoList();
    e.preventDefault();
    let amsAttribute = {
      Status: this.props.initialValues.statusId,
      Priority: "0",
      Service: this.props.initialValues.serviceId,
      "Impacted CI": this.props.initialValues.ciId,
      Requestor: this.props.initialValues.requesterId,
      Class: 0,
      "User.Location": 0,
      "CI.Location": 0,
      serviceCompanyId: this.props.initialValues.serviceCompanyId,
      serviceCompanyName: this.props.initialValues.serviceCompanyName,
    };

    let rulesAttribute = {
      status: this.props.initialValues.statusId,
      priorityId: undefined,
      serviceName: this.props?.initialValues?.serviceName,
      impactedCi: this.props?.initialValues?.ciName,
      impactedUsername: this.props?.initialValues?.requesterName,
      ciClassName: undefined,
      userLocation: 0,
      ciLocation: 0,
      serviceId: this.props.initialValues.serviceId,
      impactedCiId: this.props.initialValues.ciId,
      requestorId: this.props.initialValues.requesterId,
      serviceCompId: this.props.initialValues.serviceCompanyId,
    };
    this.setState({
      ShowAllAMSGRP: true,
      AllAMSGrps: false,
      assignedToValue: "",
      showAssignedToInfo: false,
      typedAssignedToValue: '',
      rulesAttribute: rulesAttribute
    });
    if (
      this.props.initialValues.assignedToGroupId &&
      this.props.initialValues.assignedToGroupId > 0
    ) {
      this.props.setAssignmentGrpUsr([], "group");
      this.setState({
        assignmentGrpListErrorColor: "",
        selectedAssignmentGrpList: [],
      });
      this.props.setAssignmentGrpListErrorColor("");
      this.props.change(
        "assignedToGroupId",
        this.props.initialValues.assignedToGroupId
      );
      this.props.change(
        "assignedToGroup",
        this.props.initialValues.assignedToGroup
      );
      GLOBAL.isExternalTaskEdit = this.props.initialValues.isExternalFulfilled;
    }
    if (
      this.props.initialValues.assignedToId &&
      this.props.initialValues.assignedToId > 0
    ) {
      this.props.assignToListErrorColorParentFunc("");
      this.setState({ selectedAssignToList: [] });
      this.props.setAssignmentGrpUsr([], "user");
      this.props.dispatch({
        type: "TASK_ASSIGNED_TO_DATA_LOADED",
        taskAssignedToData: [],
      });
      this.props.setAssignToListErrorColor("");
      this.props.change("assignedToId", -this.props.initialValues.assignedToId);
      this.props.change("assignedTo", this.props.initialValues.assignedTo);
    }
  }

  renderAssignedGroup(groupData) {
    console.log("groupData==========");
    console.log(groupData);

    return groupData.map((groupObj) => {
      return <option value={groupObj.field1Value}>{groupObj.field1Key}</option>;
    });
  }
  ShowAMSGroup(groupData) {
    console.log("groupData=================");
    console.log(groupData);
    const returnObj = groupData.map(
      ({
        groupId: field1Value,
        groupName: field1Key,
        isExternal: isExternal,
        supportCompanyName: supportCompanyName,
        supportCompanyID: supportCompanyID,
      }) => ({
        field1Value,
        field1Key,
        isExternal,
        supportCompanyName,
        supportCompanyID,
      })
    );
    console.log("returnObj========");
    console.log(returnObj);
    return returnObj;
  }
  setAssignmentGrpErrorColor() {
    if (this.state.selectedAssignmentGrpList.length === 0) {
      this.setState({ assignmentGrpListErrorColor: "error" });
    } else {
      this.setState({ assignmentGrpListErrorColor: "" });
    }
    this.props.setAssignmentGrpListErrorColor("");
  }
  setAssignToErrorColor() {
    console.log("selectedAssignToList=================ll88l");
    console.log(this.state);
    if (
      this.props.statusId == taskStatusMapping["Completed"] ||
      this.props.statusId == taskStatusMapping["Cancelled"] ||
      this.props.statusId == taskStatusMapping["In Progress"] ||
      this.props.statusId == taskStatusMapping["On Hold"]
    ) {
      if (this.state.selectedAssignToList.length === 0) {
        this.props.assignToListErrorColorParentFunc("error");
      } else {
        this.props.assignToListErrorColorParentFunc("");
      }
    } else {
      this.props.assignToListErrorColorParentFunc("");
    }
    this.props.setAssignToListErrorColor("");
  }
  onAssignmentGrpSelection(selectedAssignmentGrp) {
    console.log("selectedAssignmentGrp=========");
    console.log(selectedAssignmentGrp);
    this.props.setAssignmentGrpUsr([], "user");
    this.setState({
      assignmentGrpFlag: true,
      assignmentGrpListErrorColor: "",
      selectedAssignToList: [],
      assignedToValue: "",
      showAssignedToInfo: false,
      typedAssignedToValue: ''
    });
    this.props.dispatch({
      type: "TASK_ASSIGNED_TO_DATA_LOADED",
      taskAssignedToData: [],
    });
    this.props.setAssignmentGrpListErrorColor("");
    this.props.setAssignToListErrorColor("");
    this.props.change("assignedToId", "");
    this.props.change("assignedTo", "");
    if (selectedAssignmentGrp.length > 0) {
      this.setState({
        selectedAssignmentGrpList: [
          {
            id: selectedAssignmentGrp[0].id,
            label: selectedAssignmentGrp[0].label,
          },
        ],
      });
      this.props.loadAsignedTo(selectedAssignmentGrp[0].id);
      this.props.change("assignedToGroupId", selectedAssignmentGrp[0].id);
      this.props.change("assignedToGroup", selectedAssignmentGrp[0].label);
      this.props.change(
        "supportCompanyID",
        selectedAssignmentGrp[0].supportCompanyID
      );
      this.props.change(
        "supportCompanyName",
        selectedAssignmentGrp[0].supportCompanyName
      );
      GLOBAL.isExternalTaskEdit = selectedAssignmentGrp[0].isExternal;
      this.props.editFormValues.isExternalFulfilled =
        selectedAssignmentGrp[0].isExternal;
      this.props.setAssignmentGrpUsr(
        [
          {
            id: selectedAssignmentGrp[0].id,
            label: selectedAssignmentGrp[0].label,
          },
        ],
        "group"
      );
    } else {
      this.props.change("assignedToGroupId", "");
      this.props.change("assignedToGroup", "");
      this.props.change("supportCompanyID", "");
      this.props.change("supportCompanyName", "");
      this.setState({
        selectedAssignmentGrpList: [],
      });
      GLOBAL.isExternalTaskEdit = "";
      this.props.setAssignmentGrpUsr([], "group");
    }
  }
  clearAssignedTo() {
    this.props.change("assignedToId", "");
    this.props.change("assignedTo", "");
    this.props.setAssignmentGrpUsr([], "user");
    this.setState({
      selectedAssignToList: [],
      assignedToValue: "",
      showAssignedToInfo: false,
      typedAssignedToValue: ''
    });
    if (
      this.props.statusId == taskStatusMapping["Completed"] ||
      this.props.statusId == taskStatusMapping["Cancelled"] ||
      this.props.statusId == taskStatusMapping["In Progress"] ||
      this.props.statusId == taskStatusMapping["On Hold"]
    ) {
      this.props.assignToListErrorColorParentFunc("error");
    }
  }
  clearAssignedToGroupId() {
    this.props.change("assignedToGroupId", "");
    this.props.change("assignedToGroup", "");
    this.props.change("supportCompanyID", "");
    this.props.change("supportCompanyName", "");
    this.props.setAssignmentGrpUsr([], "group");
    this.setState({
      selectedAssignmentGrpList: [],
      assignedToValue: "",
      showAssignedToInfo: false,
      typedGroupValue: '',
      selectedAssignToList: []
    });
    GLOBAL.isExternalTaskEdit = "";
    this.setState({ assignmentGrpListErrorColor: "error", typedAssignedToValue: ''});
  }
  onAssignToSelection(selectedAssignTo) {
    console.log("selectedAssignTo========");
    console.log(selectedAssignTo);
    this.setState({ assignmentGrpFlag: false });
    this.props.assignToListErrorColorParentFunc("");
    this.props.setAssignToListErrorColor("");
    let val = "";
    if (selectedAssignTo.length > 0) {
      const { isChangeImplementation, areReviewStatuses } = this.state;
      const { adminPreferencesListData } = this.props;
      if(isChangeImplementation && areReviewStatuses && adminPreferencesListData && this.props.ItemList.reviewerId==selectedAssignTo[0].id){
        swal({
          text: this.props.translator['Please assign other user'],
          button: this.props.translator["OK"]
        });
        return;
      }
      this.props.change("assignedToId", selectedAssignTo[0].id);
      this.props.change("assignedTo", selectedAssignTo[0].label);
      this.setState({ selectedAssignToList: selectedAssignTo });
      val = selectedAssignTo[0].id;
    } else {
      this.props.change("assignedToId", "");
      this.props.change("assignedTo", "");
      this.props.setAssignmentGrpUsr([], "user");
      this.setState({ selectedAssignToList: [] });
      this.props.dispatch({
        type: "TASK_ASSIGNED_TO_DATA_LOADED",
        taskAssignedToData: [],
      });
    }
    this.setState({ assignedToValue: val, showAssignedToInfo: true });
  }
  descriptionCharacter(e) {
    let input = e.target.value.length;
    let additionalchar = 500 - input;
    this.setState({
      additionalcharleft: additionalchar,
    });
    console.log("e.target.value", input);
  }
  summaryCharacter(e) {
    this.setState({ additionalSummaaryCharLeft: 4000 - e.target.value.length });
  }
  onAssignmentGroupChange(value) {
    this.setState({ assignedToValue: "", showAssignedToInfo: false, typedGroupValue: value, typedAssignedToValue: '', selectedAssignToList: [] });
  }
  onAssignedToChange(value) {
    this.setState({ assignedToValue: "", showAssignedToInfo: false, typedAssignedToValue: value });
  }
  onReviewerSelection(reviewerDetails){
    if(reviewerDetails.length){
      if(this.props.userId==reviewerDetails[0].id || this.props.ItemList.assignedToId==reviewerDetails[0].id){
        swal({
          text: this.props.translator['Please assign other user as Reviewer'],
          button: this.props.translator["OK"]
        });
        return;
      }
      this.props.change("reviewerId", reviewerDetails[0].id);
      this.props.change("reviewerName", reviewerDetails[0].label);
      this.setState({ selectedReviewerList: reviewerDetails });
      this.setState({reviewerValue: '', reviewerInfo: true});
      this.props.setReviewerErrorParent('');
    }else{
      this.props.change("reviewerId", '');
      this.props.change("reviewerName", '');
      this.setState({ selectedReviewerList: [] });
    }
  }
  setReviewerError(){
    if(!this.state.selectedReviewerList.length){
      this.props.setReviewerErrorParent('error');
    }else{
      this.props.setReviewerErrorParent('');
    }
  }
  onReviewerChange(value){
    if(value.length>3){
      this.retriveEmployees(this.props.initialValues.companyId,value,true);  
    }
    this.setState({ reviewerValue: value, reviewerInfo: false, typedReviewerValue: value});
  }  
  retriveEmployees(companyId,typedValue,isReviewer){
      let strObj = {};
      strObj.like = typedValue;
      strObj.companyId = companyId;
      strObj.isReviewer = isReviewer;
      strObj = JSON.stringify(strObj);
      axios.get(GLOBAL.consumerUsersNewUrl,{ headers: { 'query': strObj } }).then(retrivedList => {
        let extractedList = [];
        retrivedList.data.map(item=>extractedList.push({id: item.userId+'', label: item.fullname}));
          this.setState({employeeList: extractedList});
      });
  }
  clearReviewer(){
    this.props.change("reviewerId", '');
    this.props.change("reviewerName", '');
    this.setState({ selectedReviewerList: [] });
    this.props.setReviewerErrorParent('');
    this.setState({ reviewerValue: "", reviewerInfo: false, typedReviewerValue: '' });
  }
  render() {
    const { isChangeImplementation, areReviewStatuses, disabledForStatuses } = this.state;
    const { employeeList, selectedReviewerList, reviewerInfo, disableForReviewer } = this.state;
    const { showReviewerDetails, rightEditPanel, adminPreferencesListData, disableFieldsForGuestRole, reviewError } = this.props;
    console.log("this.state.initialStatus", this.state.initialStatus);
    const class_ShowAll =
      this.state.initialStatus == 3 || this.state.initialStatus == 4
        ? "showwAllGrps disabled_showAll"
        : "showwAllGrps";

    const {
      isFetching,
      isError,
      assignedToData,
      assignmentGroupData,
      errorMessage,
      requestorData,
      serviceData,
      taskStatusToEdit,
      change,
    } = this.props;
    console.log(taskStatusToEdit);

    let taskTypeDropDownList = [];

    this.props.taskTypeList.map((taskObj1) => {
      let taskObj = {};
      taskObj.label = taskObj1.field1Key;
      taskObj.value = taskObj1.field1Value;
      taskTypeDropDownList.push(taskObj);
    });
    if (isFetching) {
      return (
        <div className="rctLoade">
          <Loader />
        </div>
      );
    }
    if (isError) {
      return (
        <div className="rctLoade">
          <LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
        </div>
      );
    }

    let strServiceMandatory = "";
    let strCIMandatory = "";

    if (this.props.initialValues.workItemType == "Change") {
      strCIMandatory = <span className="rStar" />;
    } else if (this.props.initialValues.workItemType == "Problem") {
      strCIMandatory = <span className="rStar" />;
    } else if (this.props.initialValues.workItemType == "Fullfilment") {
      strServiceMandatory = <span className="rStar" />;
    } else if (
      this.props.initialValues.workItemType == "Incident" ||
      this.props.initialValues.workItemType == "Breakfix"
    ) {
      if (
        this.props.initialValues.serviceBased == "true" ||
        this.props.initialValues.serviceBased == true
      ) {
        strServiceMandatory = <span className="rStar" />;
      } else {
        strCIMandatory = <span className="rStar" />;
      }
    }
    let assignmentGroupArray = [];
    let assignmentGrpTypeaheadOptions = [];
    if (this.state.AllAMSGrps == false) {
      if (
        Array.isArray(this.props.breakFixSGAEEditName) &&
        this.props.breakFixSGAEEditName.length > 0
      ) {
        assignmentGroupArray = this.props.breakFixSGAEEditName;
        assignmentGrpTypeaheadOptions = assignmentGroupArray.map((item) => {
          return {
            id: item.assignmentGroupId,
            label: item.assignmentGroupName,
            isExternal: item.isExternal,
            supportCompanyName: item["assignmentCompanyName"] || "",
            supportCompanyID: item["assignmentCompanyId"] || "",
          };
        });
      }
    } else {
      assignmentGroupArray = this.ShowAMSGroup(this.props.allgroupdata);
      assignmentGrpTypeaheadOptions = assignmentGroupArray.map((item) => {
        return {
          id: item.field1Value,
          label: item.field1Key,
          isExternal: item.isExternal,
          supportCompanyName: item["supportCompanyName"] || "",
          supportCompanyID: item["supportCompanyID"] || "",
        };
      });
    }

    assignmentGrpTypeaheadOptions = removeDuplicates(
      assignmentGrpTypeaheadOptions,
      "id"
    );

    let selectedAssignToOptions = [];
    if (this.state.selectedAssignToList.length > 0) {
      selectedAssignToOptions = [
        {
          id: this.state.selectedAssignToList[0].id,
          label: this.state.selectedAssignToList[0].label,
        },
      ];
    } else if (this.props.initialValues.assignedToId > 0) {
      selectedAssignToOptions = [
        {
          id: this.props.initialValues.assignedToId,
          label: this.props.initialValues.assignedTo,
        },
      ];
    }
    let assignToTypeaheadOptions = assignedToData.map((item) => {
      return { id: item.field1Value, label: item.field1Key };
    });
    assignToTypeaheadOptions = removeDuplicates(assignToTypeaheadOptions, "id");
    let readOnlyResponse = {};
    if (
      this.props.role_id &&
      this.state.initialStatus &&
      this.state.initialStatus > 0
    ) {
      readOnlyResponse = validateTaskField(
        this.props.role_id,
        this.state.initialStatus,
      );
    }
    return (
      <form>
        <div>
          <div className="borderForm bFormDiv">
            <Row>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>
                    <span className="rStar" />{" "}
                    {this.props.translator["Requestor"]}
                  </Form.Label>
                  <div className="infoBtnDiv">
                    <Field
                      name="requesterName"
                      component="input"
                      readOnly={
                        readOnlyResponse.requesterName ||
                        this.props.disableFieldsForGuestRole
                      }
                      className="form-control"
                      value={this.props.initialValues.requesterName}
                      onChange={(e) => {
                        if (e && e.target && e.target.value) {
                          this.setState({
                            requestorValue: e.target.value,
                            showRequestorInfo: false,
                          });
                        }
                      }}
                    />
                    {this.state.showRequestorInfo ? (
                      <Button
                        title="Info"
                        bsPrefix=" "
                        className="infoicn"
                        onClick={() => {
                          this.props.showConsumerDetails(
                            "consumer",
                            this.state.requestorValue,
                            "Requestor Details"
                          );
                          this.props.rightEditPanel(true);
                        }}
                      >
                        <ImInfo />
                      </Button>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>{this.props.translator["Company"]}</Form.Label>
                  <Field
                    name="companyName"
                    component="input"
                    readOnly={
                      readOnlyResponse.companyName ||
                      this.props.disableFieldsForGuestRole
                    }
                    value={this.props.initialValues.companyName}
                    className="form-control"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="form-group">
                  <span className="rStar" />{" "}
                  <Form.Label>{this.props.translator["Work Item"]}</Form.Label>
                  <Field
                    disabled={true}
                    name="workItemType"
                    component="input"
                    readOnly={readOnlyResponse.WorkItemType}
                    value={this.props.initialValues.workItemType}
                    className="form-control"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>
                    <span className="rStar"></span>{" "}
                    {this.props.translator["Task Type"]}
                  </Form.Label>
                  {this.props.initialValues.taskType == null ||
                  this.props.initialValues.taskType == "" ? (
                    <Field
                      component={_dropDown}
                      options={taskTypeDropDownList}
                      name="taskTypeId"
                      className="form-control"
                      inputRef={(input) => (this.inputGroup = input)}
                      onChange={(event) => {
                        const selectedTaskType = event.target[event.target.selectedIndex].text;
                        this.props.dispatch(changeForm("editTaskForm", "taskType", selectedTaskType));
                      }}
                      disabledValue={this.props.disableFieldsForGuestRole}
                    ></Field>
                  ) : (
                    <Field
                      disabled={true}
                      name="taskType"
                      component="input"
                      readOnly={readOnlyResponse.taskTypeId}
                      value={this.props.initialValues.taskType}
                      className="form-control"
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>
                    {strServiceMandatory}
                    {this.props.translator["Service"]}
                  </Form.Label>
                  <div className="infoBtnDiv">
                    <Field
                      name="serviceName"
                      component="input"
                      readOnly={
                        readOnlyResponse.serviceName ||
                        this.props.disableFieldsForGuestRole
                      }
                      className="form-control"
                      value={this.props.initialValues.serviceName}
                      onChange={(e) => {
                        this.isServiceFun(
                          e,
                          this.props.initialValues.serviceName
                        );
                      }}
                    />
                    {this.props.initialValues.serviceId !== 0 &&
                    this.state.isServiceInfoIcn ? (
                      <Button
                        title="Info"
                        bsPrefix=" "
                        className="infoicn"
                        onClick={() => {
                          this.props.showServiceDetails(
                            this.props.initialValues.serviceId
                          );
                          this.props.rightEditPanel(true);
                        }}
                      >
                        <ImInfo />
                      </Button>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>
                    {strCIMandatory}
                    {this.props.translator["Impacted CI"]}
                  </Form.Label>
                  <div className="infoBtnDiv">
                    <Field
                      name="ciName"
                      component="input"
                      readOnly={
                        readOnlyResponse.impactedCi ||
                        this.props.disableFieldsForGuestRole
                      }
                      className="form-control"
                      value={this.props.initialValues.ciName}
                      onChange={(e) => {
                        this.isCiNameFun(e, this.props.initialValues.ciName);
                      }}
                    />
                    {this.props.initialValues.ciId !== 0 &&
                    this.state.isCiNameInfoIcn ? (
                      <Button
                        title="Info"
                        bsPrefix=" "
                        className="infoicn"
                        onClick={() => {
                          this.props.showCiDetails(
                            this.props.initialValues.ciId
                          );
                          this.props.rightEditPanel(true);
                        }}
                      >
                        <ImInfo />
                      </Button>
                    ) : null}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>{this.props.translator["Sequence"]}</Form.Label>
                  <Field
                    name="sequenceNumber"
                    component={_inputField}
                    max="100"
                    readOnly={
                      readOnlyResponse.sequenceNumber ||
                      this.props.disableFieldsForGuestRole
                    }
                    min="1"
                    className="form-control"
                  ></Field>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>{this.props.translator["Hop Count"]}</Form.Label>
                  <Field
                    name="hopCount"
                    component="input"
                    readOnly={
                      readOnlyResponse.hopCount ||
                      this.props.disableFieldsForGuestRole
                    }
                    value={this.props.initialValues.hopCount}
                    className="form-control"
                  />
                </Form.Group>
              </Col>
            </Row>
              <Row>
              {this.props.initialValues.priorityValue ? (
                <Col md={6}>
                  <Form.Group className="form-group">
                    <Form.Label>
                      <span className="rStar" />{" "}
                      {this.props.translator["Priority"]}
                    </Form.Label>
                    <div className="infoBtnDiv">
                      <Field
                        name="priorityValue"
                        component="input"
                        readOnly={true}
                        className="form-control"
                        value={this.props.initialValues.priorityValue}
                      />
                    </div>
                  </Form.Group>
                </Col>
              ) : (
                ""
              )}
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>
                    <span className="rStar"></span>
                    {this.props.translator["Task Model"]}
                  </Form.Label>
                  {(this.props.initialValues.eventId == null || this.props.initialValues.eventName == null || this.props.initialValues.eventId == "0" || this.props.initialValues.eventName == "") ? (
                    <Field
                      component="select"
                      type="select"
                      name="eventId"
                      className={`form-control ${GLOBAL.taskModifierError}`}
                      value={this.state.eventId}
                      onChange={(e) => {
                        if(!e.target.value || e.target.value == "0"){
                          GLOBAL.taskModifierError = "error"
                        } else {
                          GLOBAL.taskModifierError = ""
                        }
                        this.setState({eventId: e.target.value});
                        this.props.change("eventName", e.target.options[e.target.selectedIndex].text);
                      }}
                      disabled={this.props.disableFieldsForGuestRole}
                    >
                      <option value="0">Select</option>
                      {this.state.eventList.map((opt, idx) => 
                        <option key={idx} value={opt?.value}>
                          {opt?.label}
                        </option>
                      )}
                    </Field>
                  ) : (
                    <Field
                      disabled={true}
                      name="eventName"
                      component="input"
                      value={this.props.initialValues.eventName}
                      readOnly={readOnlyResponse.eventName}
                      className="form-control"
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Label>
                    <span className="rStar" />{" "}
                    {this.props.translator["Summary"]}
                  </Form.Label>
                  <div className="position-re">
                    {!this.props.disableFieldsForGuestRole &&
                    readOnlyResponse.summary == false ? (
                      <Field
                        name="description"
                        type="textarea"
                        component={_textArea}
                        label=""
                        className="form-control"
                        rows="2"
                        maxlength="4000"
                        disabled={readOnlyResponse.summary}
                        onChange={(event) => {
                          this.summaryCharacter(event);
                        }}
                      />
                    ) : (
                      <Field
                        name="description"
                        type="textarea"
                        component="textarea"
                        label=""
                        disabled={
                          readOnlyResponse.summary ||
                          this.props.disableFieldsForGuestRole
                        }
                        className="form-control"
                        rows="2"
                        maxLength="4000"
                        onChange={(event) => {
                          this.summaryCharacter(event);
                        }}
                      />
                    )}
                    <p
                      className="charLeft"
                      style={{ textAlign: "right", fontSize: "11px" }}
                    >
                      ({this.props.translator["Characters Remaining"]}:{" "}
                      {this.state.additionalSummaaryCharLeft}/4000)
                    </p>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
              <div className="bgGray padding-5 inciSupProviderDv">
                <span>
                <span className="rStar" /> {this.props.translator["Group Info"]}
                </span>
                <span>
                {this.props.role_id &&
                (this.props.role_id.indexOf("13") >= 0 ||
                  this.props.role_id.indexOf("14") >= 0 ||
                  this.props.role_id.indexOf("17") >= 0 ||
                  this.props.role_id.indexOf("20") >= 0 ||
                  this.props.role_id.indexOf("44") >= 0 ||
                  this.props.role_id.indexOf("52") >= 0 ||
                  this.props.role_id.indexOf("48") >= 0) ? (
                  <span>
                    {" "}
                    {this.props.disableFieldsForGuestRole? (
                      ""
                    ) : this.state.AllAMSGrps == false ? (
                      <a
                        className={class_ShowAll}
                        href="javascript:void(0)"
                        onClick={this.AllGrps}
                      >
                        Show ALL Groups
                      </a>
                    ) : (
                      <a
                        className={class_ShowAll}
                        href="javascript:void(0)"
                        onClick={this.AllAMSGrps}
                      >
                        Show AMS Groups
                      </a>
                    )}
                  </span>
                ) : (
                  ""
                )}
                </span>
              </div>
              <Row>
                <Col md={6}>
                  <Form.Group className="form-group">
                    <Form.Label>
                      {this.props.translator["Assignment Group"]}
                    </Form.Label>
                    <div className="dvTypehdropdown">
                      <RuleAmsDdropDown
                        rulesAttribute={this.state.rulesAttribute}
                        component={TypeaheadExampleSingleSelect}
                        onSelection={this.onAssignmentGrpSelection}
                        setErrorColor={this.setAssignmentGrpErrorColor}
                        name="assignedToGroupId"
                        errorClass={
                          this.state.assignmentGrpListErrorColor == "error" ||
                          this.props.setAssignmentGrpListErrorColorState ==
                            "error"
                            ? "error"
                            : ""
                        }
                        className={`form-control`}
                        selectedOptions={this.state.selectedAssignmentGrpList}
                        onInputChange={this.onAssignmentGroupChange}
                        onCrossClick={this.clearAssignedToGroupId}
                        disabled={
                          readOnlyResponse.assignedToGroupId ||
                          this.props.disableFieldsForGuestRole
                        }
                        formData={this.props}
                        moduleName="task"
                        showAms={this.state.AllAMSGrps}
                        groupData={assignmentGrpTypeaheadOptions}
                        ruleFor="fulfiller"
                        typedValue={this.state.typedGroupValue}
                      />

                      {/* <Field
                        component={TypeaheadExampleSingleSelect}
                        onSelection={this.onAssignmentGrpSelection}
                        setErrorColor={this.setAssignmentGrpErrorColor}
                        name="assignedToGroupId"
                        errorClass={
                          this.state.assignmentGrpListErrorColor == "error" ||
                          this.props.setAssignmentGrpListErrorColorState ==
                            "error"
                            ? "error"
                            : ""
                        }
                        className={`form-control`}
                        options={assignmentGrpTypeaheadOptions}
                        selectedOptions={this.state.selectedAssignmentGrpList}
                        onInputChange={this.onAssignmentGroupChange}
                        onCrossClick={this.clearAssignedToGroupId}
                        disabled={
                          readOnlyResponse.assignedToGroupId ||
                          this.props.disableFieldsForGuestRole
                        }
                      /> */}
                    </div>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className={`${
                      this.state.showAssignedToInfo || this.state.selectedAssignmentGrpList.length > 0
                        ? "prblmBtnDv infoBtnDiv typeHeadicn downinfobtn"
                        : "chngBtnDv infoBtnDiv typeHeadicn downinfobtn"
                    }`}
                  >
                    <Form.Label>
                      {this.props.translator["Assigned To"]}
                    </Form.Label>
                    <div className="dvTypehdropdown">
                      <Field
                        component={TypeaheadExampleSingleSelect}
                        onSelection={this.onAssignToSelection}
                        setErrorColor={this.setAssignToErrorColor}
                        name="assignedToId"
                        errorClass={
                          this.props.assignToListErrorColorParent == "error" ||
                          this.props.setAssignToListErrorColorState == "error"
                            ? "error"
                            : ""
                        }
                        className={`form-control`}
                        options={assignToTypeaheadOptions}
                        selectedOptions={this.state.selectedAssignToList}
                        onInputChange={this.onAssignedToChange}
                        onCrossClick={this.clearAssignedTo}
                        disabled={
                          readOnlyResponse.assignedToId ||
                          this.props.disableFieldsForGuestRole
                        }
                        typedValue={this.state.typedAssignedToValue}
                      />
                      <div
                        className={
                          this.state.showAssignedToInfo ||
                          this.state.selectedAssignmentGrpList.length > 0
                            ? `indiIcnDv`
                            : ""
                        }
                      >
                        {this.state.selectedAssignmentGrpList.length > 0 ? (
                          <Button
                            title={
                              this.props.translator[
                                "Show available individuals"
                              ]
                            }
                            bsPrefix=" "
                            className="asignicn"
                            disabled={
                              readOnlyResponse.assignedToId ||
                              this.props.disableFieldsForGuestRole
                            }
                            onClick={() => {
                              this.props.changeActionView(
                                "showAvailableIndividuals"
                              );
                              this.props.rightEditPanel(true);
                            }}
                          >
                            <RiUserSearchLine />
                          </Button>
                        ) : (
                          ""
                        )}
                        {this.state.showAssignedToInfo ? (
                          <Button
                            title="Info"
                            bsPrefix=" "
                            className="asignicn"
                            onClick={() => {
                              this.props.showConsumerDetails(
                                "individual",
                                this.state.assignedToValue,
                                "Individual Details"
                              );
                              this.props.rightEditPanel(true);
                            }}
                          >
                            <ImInfo />
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              {isChangeImplementation && areReviewStatuses && adminPreferencesListData && 
              <Row>
                <Col md={6}>
                  <Form.Group className="chngBtnDv infoBtnDiv typeHeadicn downinfobtn">
                    <Form.Label>{this.props.translator['Reviewer']}</Form.Label>
                    <div className="dvTypehdropdown">
                    <Field
                        name="reviewer"
                        component={TypeaheadExampleSingleSelect}
                        onSelection={this.onReviewerSelection}
                        setErrorColor={this.setReviewerError}
                        errorClass={reviewError}
                        className={`form-control`}
                        options={employeeList}
                        selectedOptions={selectedReviewerList}
                        onInputChange={this.onReviewerChange}
                        onCrossClick={this.clearReviewer}
                        disabled={disableForReviewer || disableFieldsForGuestRole || disabledForStatuses}
                        typedValue={this.state.typedReviewerValue}
                    />
                    {reviewerInfo && <div className='indiIcnDv'>
                      <Button title="Info"
                              bsPrefix=" "
                              className="asignicn"
                              onClick={() => {
                                showReviewerDetails('reviewer',selectedReviewerList[0].id,'Reviewer Details');
                                rightEditPanel(true);
                              }}>
                            <ImInfo />
                      </Button>
                    </div>}  
                    </div>
                    </Form.Group>     
                </Col>
              </Row>}
            </div>

            <Row>
              <Col md={12}>
                <div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
                  <div className="bgGray padding-5">
                    <span className="rStar" />{" "}
                    {this.props.translator["Schedule"]}
                  </div>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          {this.props.translator["Expected Start Date"]}
                        </Form.Label>
                        <div className="dateTimeSdiv dateTimePosTop">
                          <Field
                            name="expectedStartDate"
                            component={_dateTimeField}
                            className="form-control dateTimePickerField"
                            formType="edit"
                            disabledValue={
                              readOnlyResponse.expectedStartDate ||
                              this.props.disableFieldsForGuestRole
                            }
                            defaultValue={
                              this.props.initialValues.expectedStartDate
                                ? this.props.initialValues.expectedStartDate
                                : moment()
                            }
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          {this.props.translator["Expected End Date"]}
                        </Form.Label>
                        <div className="dateTimeSdiv dateTimePosTop dateTimeRightPos">
                          <Field
                            name="expectedDueDate"
                            component={_dateTimeField}
                            className="form-control dateTimePickerField"
                            formType="edit"
                            disabledValue={
                              readOnlyResponse.expectedDueDate ||
                              this.props.disableFieldsForGuestRole
                            }
                            defaultValue={
                              this.props.initialValues.expectedDueDate
                                ? this.props.initialValues.expectedDueDate
                                : moment()
                            }
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
                  <Row>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          {this.props.translator["Actual Start Date"]}
                        </Form.Label>
                        <div className="dateTimeSdiv dateTimePosTop">
                          <Field
                            name="actualStartDate"
                            component={_dateTimeField}
                            className="form-control dateTimePickerField"
                            formType="edit"
                            disabledValue={true}
                            defaultValue={
                              this.props.initialValues.actualStartDate
                                ? this.props.initialValues.actualStartDate
                                : ""
                            }
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          {this.props.translator["Actual End Date"]}
                        </Form.Label>
                        <div className="dateTimeSdiv dateTimePosTop">
                          <Field
                            name="actualEndDate"
                            component={_dateTimeField}
                            className="form-control dateTimePickerField"
                            formType="edit"
                            disabledValue={true}
                            defaultValue={
                              this.props.initialValues.actualEndDate
                                ? this.props.initialValues.actualEndDate
                                : ""
                            }
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            {/* )} */}
            <Row>
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Label>
                    {" "}
                    {this.props.translator["Additional Information"]}
                  </Form.Label>
                  {this.props.disableFieldsForGuestRole ? (
                    <Field
                      name="additionalParams"
                      component="input"
                      readOnly={this.props.disableFieldsForGuestRole}
                      value={this.props.initialValues.additionalParams}
                      className="form-control"
                    />
                  ) : (
                    <Field
                      name="additionalParams"
                      type="textarea"
                      component="textarea"
                      label=""
                      disabled={readOnlyResponse.additionalParams}
                      className="form-control"
                      rows="2"
                      maxLength="500"
                      onChange={(event) => {
                        this.descriptionCharacter(event);
                      }}
                    />
                  )}
                  <p
                    className="charLeft"
                    style={{ textAlign: "right", fontSize: "11px" }}
                  >
                    ({this.props.translator["Characters Remaining"]}:{" "}
                    {this.state.additionalcharleft}/500)
                  </p>
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
      </form>
    );
  }
};

const mapStateToProps = ({
  createTaskFormValues,
  taskAssignedToData,
  taskGroupData,
  taskStatusToEdit,
  allgroupdata,
  breakFixSGAEEditName,
  taskTypeList,
  editChangeInitialData,
  ItemList,
  admin_preferences_list_data_reducer
}) => {
  let isFetching = createTaskFormValues.isFetching;
  let isError = createTaskFormValues.isError;
  let errorMessage = createTaskFormValues.errorMessage;
  let requestorData = createTaskFormValues.requestorData;
  let serviceData = createTaskFormValues.serviceData;
  let formData = createTaskFormValues.editFormValues;
  if (formData.workItemType == "Investigation") {
    formData.workItemType = "Problem";
  } else if (formData.workItemType == "ChangeManagement") {
    formData.workItemType = "Change";
  } else if (formData.workItemType == "Breakfix") {
    formData.workItemType = "Incident";
  } else if (formData.workItemType == "Fullfilment") {
    formData.workItemType = "Fullfilment";
  }

  let assignedToData =
    taskAssignedToData == "undefined" || taskAssignedToData.length == 0
      ? []
      : taskAssignedToData;
  let assignmentGroupData =
    taskGroupData == "undefined" || taskGroupData.length == 0
      ? []
      : taskGroupData;
  const adminPreferencesListData = admin_preferences_list_data_reducer.adminPreferencesListData.value?admin_preferences_list_data_reducer.adminPreferencesListData.value==='True':false;
  return {
    isFetching,
    isError,
    assignedToData,
    assignmentGroupData,
    errorMessage,
    requestorData,
    serviceData,
    initialValues: formData,
    editFormValues: formData,
    taskStatusToEdit,
    allgroupdata,
    breakFixSGAEEditName,
    taskTypeList,
    editChangeInitialData,
    ItemList,
    adminPreferencesListData
  };
};

const selector = formValueSelector("editTaskForm");
EditTaskForm = connect((state) => {
  let statusName = selector(state, "statusName");
  let ItemList = getFormValues("editTaskForm")(state);
  return {
    statusName,
    ItemList,
  };
})(EditTaskForm);

EditTaskForm = reduxForm({
  form: "editTaskForm",
  validate,
  enableReinitialize: true,
})(EditTaskForm);

export default connect(mapStateToProps, {
  //loadBreakFixSGAETask,
  loadRequestorAndServiceData,
  loadTaskItemToEdit,
  loadTaskGroup,
  loadAsignedTo,
  getAllgroup,
  loadNewBreakFixSGAEEdit,
  flushGroupList,
  flushAssigntoList,
  getConfigurableTaskTypes,
  getAdminPreferences, 
  emptyAdminPreferencesListData
})(EditTaskForm);
