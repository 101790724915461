
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Row, Col, Button } from "react-bootstrap";

import { StandardTextArea } from "./FormFields";

import GridLayout from "./GridLayout";
import CloseBtnRightPanel from "_Commons/closeBtnRightPanel";

function ReasonComponent({
  isHemStatus,
  module,
  onSubmitReason,
  toggle,
  activeTab,
  rightEditPanel,
}) {
  const validationSchema = Yup.object({
    description: Yup.string().max(1000, "Must be 1000 characters or less"),
  });
  const validationSchemaActionItem = Yup.object({
    description: Yup.string()
      .required("Required")
      .max(1000, "Must be 1000 characters or less"),
  });

  const validationSchemaOccurenceItem = Yup.object({
    description: Yup.string()
      .required("Required")
      .max(1000, "Must be 1000 characters or less"),
  });
  let validation = {
    meeting: validationSchema,
    actionItem: validationSchemaActionItem,
    occurrences: validationSchemaOccurenceItem,
  };
  let requiredFiled = {
    meeting: false,
    actionItem: true,
    occurrences: true,
  };
  let placeholder = {
    Cancel: "Reason",
    "Re-open": "Reason",
    Complete: "Close Notes",
    Close: "Close Notes",
    "Cancel Meeting": "Reason",
  };

  const [initVals, setInitVals] = useState({
    description: "",
  });
  let initialValues = {
    ...initVals,
  };
  const onSubmitHoldingReason = (fields, { setStatus, setSubmitting }) => {
    onSubmitReason(fields, setSubmitting);
  };
  return (
    <div className="panel-body attachment-body">
      <div className="custom-container-fluid">
        <div className="stickyArea rBoxStyle">
        <CloseBtnRightPanel showRightSide={rightEditPanel} />
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">Cancel</div>
          </div>

          <div className="p-3 occurnance">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validation[module]}
              onSubmit={onSubmitHoldingReason}
            >
              {({
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values,
                handleChange,
                setValues,
              }) => {
                return (
                  <Form noValidate>
                    <GridLayout cols={1}>
                      <div>
                        <StandardTextArea
                          required
                          name="description"
                          label="Reason"
                          autoComplete="off"
                        />
                      </div>
                    </GridLayout>

                    <Row className="mt-4">
                      <Col sm="12" className="text-end">
                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          title={"Submit"}
                          className="rgSidrkBtn smallBtn"
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ReasonComponent;
