
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import {useSelector } from "react-redux";

import PindCategories from "../../../../common/header/pind-categories";
import Breadcrumbs from "../../../../common/header/breadcrumbs";
import Header from "./header";
import FormComponent from "./form";
import TabsComponent from "./tabs";
import ListLoader from "../../../../common/loaders/ListLoader";
import { useParams } from "react-router";
import { getGroupApprovalHistory } from "../../../../../actions/foundation/groupAction";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/form/_form.scss";

const Index = () => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const loader = useSelector((state) => state.commonLoader);
  const [forumErr, setForumErr] = useState('');
  const [forumValue, setForumValue] = useState([]);
  const [categoryErr, setCategoryErr] = useState('');
  const [categoryValue, setCategoryValue] = useState([]);
  const [nameErr,setNameErr] = useState('');
  const [statusErr, setStatusErr] = useState('');
  const [memberValue, setMembersValue] = useState([]);
  const [autoAlgoErr, setAutoAlgoErr] = useState('');
  const [showAuditLog, setShowAuditLog] = useState(false);
  const [isRightSideVisible, setRightSideVisible] = useState(true);
  const [panelResize,setPanelResize] = useState();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const refObj = useRef(null);

  useEffect(() => {
    const updateDimensions = () => {
      if (refObj.current) {
        const { width, height } = refObj.current.getBoundingClientRect();
        setDimensions({ width, height });
      }
    };

    const resizeObserver = new ResizeObserver(updateDimensions);
    if (refObj.current) {
      resizeObserver.observe(refObj.current);
    }

    window.addEventListener('resize', updateDimensions);

    return () => {
      if (refObj.current) {
        resizeObserver.unobserve(refObj.current);
      }
      window.removeEventListener('resize', updateDimensions);
    };
  }, [refObj.current]);



  const memberTypeFun = (list) =>{
    setMembersValue(list);
  };
  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };
  const setForumErrorColor = () => {
    if (forumValue.length > 0) {
      setForumErr("");
    } else {
      setForumErr("error");
    }
  };
  const setCategoryErrorColor = () => {
    if (categoryValue.length > 0) {
      setCategoryErr("");
    } else {
      setCategoryErr("error");
    }
  };
  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName={tr["Edit"]} parentPageurl='/renderedView/674997f27a024fa4ee118a46' parentPageName={tr["Group"]} />
      </Container>

      <Container fluid>
        <Header
          setForumErrorColor={setForumErrorColor}
          setCategoryErrorColor={setCategoryErrorColor}
          setNameErr={setNameErr}
          setStatusErr={setStatusErr}
          setAutoAlgoErr={setAutoAlgoErr}
          setShowAuditLog={setShowAuditLog}
          showAuditLog={showAuditLog}
          showRightSideFun={showRightSideFun} />

        <PanelGroup direction="horizontal">
          <Panel id="sidebar" minSize={33} order={1} defaultSize={isRightSideVisible ? 67 : 100} onResize={(size) => setPanelResize(size)} className={isRightSideVisible ? "isShowLeftPanel rwLeftPart" : "rwLeftPart"}>
            <FormComponent
              memberTypeFun={memberTypeFun}
              forumErr={forumErr}
              setForumValue={setForumValue}
              setForumErrorColor={setForumErrorColor}
              categoryErr={categoryErr}
              setCategoryValue={setCategoryValue}
              setCategoryErrorColor={setCategoryErrorColor}
              nameErr={nameErr}
              setNameErr={setNameErr}
              statusErr={statusErr}
              setStatusErr={setStatusErr}
              setAutoAlgoErr={setAutoAlgoErr}
              autoAlgoErr={autoAlgoErr}
              showRightSideFun={showRightSideFun}/>
          </Panel>
          {isRightSideVisible ?
              <>
                <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                <Panel minSize={33} order={2} defaultSize={33} className={dimensions.width < 380 ? "toSmall":null}>
                  <div ref={refObj} className="stickyArea rBoxStyle">
                    {loader.isLoading ? (
                      <ListLoader />
                    ) : (
                        <TabsComponent
                          memberValue={memberValue}
                          showAuditLog={showAuditLog}
                          showRightSideFun={showRightSideFun}/>
                    )}
                  </div>
                </Panel>
              </> : ""
          }
        </PanelGroup>
      </Container>
    </main>
  )
}

export default Index;
