
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

const paginationComponentOptions = {
  rowsPerPageText: 'Rows',
};

const BasicTable = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handlePageChange = (page) => {
    setCurrentPage(page); 
    if (props.paginationServer && props.onPageChange) {
      props.onPageChange(page, rowsPerPage);
    }
  };
  
  const handlePerRowsChange = (newRowsPerPage, page) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(page);    
    if (props.paginationServer && props.onRowsPerPageChange) {
      props.onRowsPerPageChange(newRowsPerPage, page);
    }
  };

  return (
    <div className="dtaTableDv integrateHubTable">
      <DataTable
        className="rdt_Table"
        columns={props.tableColumns}
        data={props.data}
        pagination
        paginationServer={props.paginationServer}
        paginationTotalRows={props.paginationServer ? props.totalRows : undefined}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        striped
        highlightOnHover
        pointerOnHover={props.noPointer ? false : true}
        paginationRowsPerPageOptions={props.rowsPerPageOptions || [10, 25, 50, 100]}
        paginationComponentOptions={paginationComponentOptions}
        progressPending={props.loading}
        onRowClicked={(row) => {
          if (props.onRowSelect) {
            props.onRowSelect(row);
          }
        }}
        {...(props.contextActions && { contextActions: props.contextActions })}
        {...(props.selectableRows && { selectableRows: props.selectableRows })}
        {...(props.persistTableHead && { persistTableHead: props.persistTableHead })}
        {...(props.clearSelectedRows && { clearSelectedRows: props.clearSelectedRows })}
        {...(props.onSelectedRowsChange && { onSelectedRowsChange: props.onSelectedRowsChange })}
      />
    </div>
  );
};

export default BasicTable;