
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';

import { loadBreakFixSupportCIMGroup } from '../../../../actions/breakFix/cimGroupAction';
import { loadBreakFixCIMDetails } from '../../../../actions/breakFix/cimDetailsAction';
import { loadBreakFixEditDetails, loadBreakFixEditDetailsUpdate } from '../../../../actions/breakFix/breakFixEditDetailsAction';
import { loadBreakFixSGAE } from '../../../../actions/breakFix/serviceGroupAEAction';
import { GLOBAL } from "_Globals";
import { loadStatusBasedHamburgerOptions } from '../../../../actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '../../../../actions/common/commonApisActions.js';
// import { browserHistory } from 'react-router';
import { navigationHooks } from '../../../../helpers/NavigationHook';

import swal from 'sweetalert'
import {_dateTimeField} from '../../../common/ReduxFormFields/formFields'
import moment from 'moment';
import Cookies from 'universal-cookie';
import datetimeConvertor from '../../../../ISO8601converter';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
const cookies = new Cookies();
let dateformat = cookies.get('gph');
if (dateformat) dateformat = dateformat.replace('s:', '');
if (dateformat) dateformat = dateformat.substring(0, dateformat.lastIndexOf('.'));

dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();
var criticalityAction;
class XsmBreakFixCIR extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checkButtonStatus: false,
			probableCause: '',
			businessImpact: '',
			describeIssue: '',
			businessImpactIssue: 250,
			probableCauseIssue: 250,
			expectedStartDate: null,
			expectedEndDate: null,
			outage: 0,
			impactArea: '',
			value: '',
			valuesImpactedArea: [],
			valuesValue: [],
			impactAreaList: [{
				"breakfixId": null,
		        "impactArea": "",
				"value": "",
				"status": 1
			}]
		}

		this.submit = this.submit.bind(this);
		this.validateField = this.validateField.bind(this);
		this.onBusinessImpactChange = this.onBusinessImpactChange.bind(this);
		this.onProbableCauseChange = this.onProbableCauseChange.bind(this);
		this.setFormProperties = this.setFormProperties.bind(this);
		this.validateImpactAreaList = this.validateImpactAreaList.bind(this);
		this.calculateOutage = this.calculateOutage.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.validateDateFormat = this.validateDateFormat.bind(this);
		this.validateNonMandatoryFields = this.validateNonMandatoryFields.bind(this);
		this.validateEmptyImpactArea = this.validateEmptyImpactArea.bind(this);
		GLOBAL.validateImpactArea = true;
		GLOBAL.errormsg = "";
		GLOBAL.cancelreasonCharLeft = 250,
		GLOBAL.cancelreasonCharMax= 250
		
	}

	componentWillMount() {

		let getBreanFixkItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") : ""
		let breakFixActualItemId = this.props.breakFixItemId || ""; //getBreanFixkItemId[1];
		GLOBAL.breakFixId = breakFixActualItemId;

	}

	onBusinessImpactChange(e) {
		let input = e.target.value.length;
		GLOBAL.cancelreasonCharLeft = GLOBAL.cancelreasonCharMax - input;
		this.setState({ businessImpactIssue:  GLOBAL.cancelreasonCharLeft})
	}
	onProbableCauseChange(e) {
		let input = e.target.value.length;
		GLOBAL.cancelreasonCharLeft = GLOBAL.cancelreasonCharMax - input;
		this.setState({ probableCauseIssue:  GLOBAL.cancelreasonCharLeft})
	}

	validateField(fieldType, event) {
		switch (fieldType) {
			case 'probableCause':
				if (event.target.value == "") {
					this.setState({ probableCause: 'error' });
					GLOBAL.probableCauseErrorColor = "";
				} else {
					this.setState({ probableCause: '' });
					GLOBAL.probableCauseErrorColor = "";
				}
				break;
			case 'businessImpact':
				if (event.target.value == "") {
					this.setState({ businessImpact: 'error' });
					GLOBAL.businessImpactErrorColor = "";
				} else {
					this.setState({ businessImpact: '' });
					GLOBAL.businessImpactErrorColor = "";
				}
				break;

			default:

		}
	}
	validateDateFormat() {
		if (this.state.expectedStartDate !== null && this.state.expectedEndDate !== null) {
			let reportedOnDate = datetimeConvertor(this.props.breakFixReportedOn, jwtdf + " HH:mm:ss");
			let validateStartDate = this.state.expectedStartDate;
			let valdateEndDate = this.state.expectedEndDate;
			if (moment(validateStartDate).isAfter(moment(valdateEndDate))) {
				swal({
					text: this.props.translator["Service Impact Start Time should be same or less than Service Impact End Time"],
					button: this.props.translator['OK'],
				});
				return false;
			}
	     else if (moment(valdateEndDate).isBefore(moment(reportedOnDate))) {
				swal({
					text: this.props.translator["Service Impact End time should be greater than Reported On date"],
					button: this.props.translator['OK'],
				});
				return false;
			} else {
				return true;
				
			}
		}
	}
	calculateOutage(startDate,endDate) {
		if (startDate !== null && endDate !== null) {
			let duration = moment.duration(endDate.diff(startDate));
			if (duration >= 0) {
				this.props.dispatch(change("acceptCriticalTab", "outage", duration.asMinutes()));
				this.setState({ outage: duration });	
			}
			
		}
	}
	setFormProperties(value, setPropertyOf) {
		let date = moment(value);
		switch (setPropertyOf) {
			case 'serviceImpactStartTime':
				this.setState({ expectedStartDate: date }, ()=>{this.validateDateFormat()});
			    this.calculateOutage(date,this.state.expectedEndDate);
				break;
			case 'serviceImpactEndTime':
				this.setState({ expectedEndDate: date }, ()=>{this.validateDateFormat()});
			    this.calculateOutage(this.state.expectedStartDate, date);
				break;
		}
	}

	handleChange(i, event, type) {
		let ref = this;
		this.state.impactAreaList.forEach((element, index) => {
			if (index == i) {
				if (type == "impactArea") {
					this.state.impactAreaList[i].impactArea = event.target.value,
						this.state.impactAreaList[i].breakfixId = GLOBAL.breakFixId ,
						this.state.impactAreaList[i].status = 1
					// if (i == 0) {
					// 	console.log("Yellow");
					// 	this.state.updatedVendorList[i].vendor = event.target.value;
					// }
					let valuesImpactedArea = [...this.state.valuesImpactedArea];
					valuesImpactedArea[i] = event.target.value;
					this.setState({ valuesImpactedArea});
				}
				else if (type == "value") {
					this.state.impactAreaList[i].value = event.target.value,
						this.state.impactAreaList[i].breakfixId = GLOBAL.breakFixId ,
						this.state.impactAreaList[i].status = 1
					// if (i == 0) {
					// 	this.state.updatedVendorList[i].ticket = event.target.value
					// }
					let valuesValue = [...this.state.valuesValue];
					valuesValue[i] = event.target.value;
					this.setState({ valuesValue });
				}
			}
		});
	}

	validateImpactAreaList(i)
	{
		this.state.impactAreaList.forEach((element, index) => {
			if (index == i) {
				if (this.state.impactAreaList[i].impactArea == "" || this.state.impactAreaList[i].value == "" || this.state.impactAreaList[i].impactArea.trim() == "" || this.state.impactAreaList[i].value.trim() == "") {
					swal({
						text: this.props.translator['Please fill out the required fields'],
						buttons: this.props.translator['OK']
					})
					GLOBAL.validateImpactArea = false;
					return (0);
				}
				else {
					GLOBAL.validateImpactArea = true;
			    }
			}
		})	
    }
	
	addImpactAreaItem(i) {
		this.validateImpactAreaList(i);
		if (GLOBAL.validateImpactArea == true) {
			this.setState(prevState => {
				return {
					impactAreaList: [...prevState.impactAreaList, {
						"breakfixId": null,
						"impactArea": "",
						"value": "",
						"status": 1
					}],
				};
			})
		}
	}
	deleteImpactAreaItem(i){
		if(i===this.state.impactAreaList.length){
			this.state.valuesImpactedArea.pop();
			this.state.valuesValue.pop();
			this.state.impactAreaList.pop();
		}else if(i<this.state.impactAreaList.length){
          this.state.valuesImpactedArea.splice(i,1);
		  this.state.valuesValue.splice(i,1);
		  this.state.impactAreaList.splice(i,1); 
		}
		this.setState({impactAreaList: this.state.impactAreaList});
	}
	renderImpactAreaList() {
		const { impactAreaList = [] } = this.state;
		return impactAreaList.map((item, i) => {
			return (
				<div>
					<Row>
					<Col className='padding-r-5 margin-l-5' md={4}>
						<Form.Group className="form-group">
							<Form.Control name={"impactArea"} className="form-control" type="text" component="input" onChange={(event) => { this.handleChange(i, event, "impactArea") }} autoComplete="off" value={this.state.impactAreaList[i].impactArea} />
						</Form.Group>
					</Col>
					<Col className='padding-l-5 padding-r-5 margin-r-10 margin-l-10' md={4}>
						<Form.Group className="form-group">
                            <Form.Control name={"value" } className="form-control" type="text"  component="input" onChange={(event) => { this.handleChange(i, event, "value") }} autoComplete="off" value={this.state.impactAreaList[i].value}/>
						</Form.Group>
						</Col>
						{
						this.state.impactAreaList.length == i + 1 ?
							<Col>
							<ButtonToolbar className="black">
                                {i>0?<Button className='smallBtn' style={{padding:"2px 8px"}} onClick={(e) => { this.deleteImpactAreaItem(i) }}><i className="fa fa-trash" title={this.props.translator['Delete']}></i></Button>:null} 
								<Button className='rgSidrkBtn smallBtn' style={{padding:"2px 8px"}} onClick={(e) => { this.addImpactAreaItem(i) }} ><i className="fa fa-plus" title={this.props.translator['Add']}></i></Button>
							</ButtonToolbar>
							</Col>
							: 
						i < this.state.impactAreaList.length ?
						    <Col>
							<ButtonToolbar className="black">
							    <Button className='smallBtn' style={{padding:"2px 8px"}} bsSize="small" onClick={(e) => { this.deleteImpactAreaItem(i) }}><i className="fa fa-trash" title={this.props.translator['Delete']}></i></Button>
						    </ButtonToolbar>
							</Col>
						    : null 
					}
					</Row>
					</div>
			);
		})
	}
	
	validateEmptyImpactArea() {
		let count = 0
		this.state.impactAreaList.forEach((element, i) => {
			if (this.state.impactAreaList[i].impactArea == "" || this.state.impactAreaList[i].value == "" || this.state.impactAreaList[i].impactArea.trim() == "" || this.state.impactAreaList[i].value.trim() == "") {
				GLOBAL.errormsg = "The form has missing required fields, please revalidate the fields and update the form";
				count++; 
			}
			else {
				GLOBAL.errormsg = "";
			}
		})
		if (count > 0) {
			return false
		} else {
			return true;
		}
	}
	validateNonMandatoryFields() {
		let impactAreaCheck = false;
		this.state.impactAreaList.forEach((element, i) => {
			if (this.state.impactAreaList[i].impactArea.length === 0 && this.state.impactAreaList[i].value.length === 0) {
				impactAreaCheck = true;
			}
		 })
		if (this.state.expectedStartDate == null && this.state.expectedEndDate == null && impactAreaCheck === true) {
			return true;
		} else {
			if (this.state.expectedStartDate != null && this.state.expectedEndDate == null) {
				swal({
					text: this.props.translator['Please fill out the Service Impact End Time'],
					buttons: this.props.translator['OK']
				})
				return false;
			}
			if (this.state.expectedStartDate == null && this.state.expectedEndDate != null) {
				swal({
					text: this.props.translator['Please fill out the Service Impact Start Time'],
					buttons: this.props.translator['OK']
				})
				return false;
			}
			if (this.state.expectedStartDate != null && this.state.expectedEndDate != null) {
			  
				let check = this.validateDateFormat();
				if (check === false) {
					return false;	
				}
			}
			let checkValue = true;
			this.state.impactAreaList.forEach((element, i) => {
				if (this.state.impactAreaList[i].impactArea.length !== 0 || this.state.impactAreaList[i].value.length !== 0) {
					 checkValue = this.validateEmptyImpactArea();
				}
			})
			return checkValue;
		}
	}


	submit(values) {
		let serviceStartTime = values.serviceImpactStartTime == null ? "": moment(values.serviceImpactStartTime).format("DD-MM-YYYY hh:mm:ss");
		let serviceEndTime = values.serviceImpactEndTime == null ? "" : moment(values.serviceImpactEndTime).format("DD-MM-YYYY hh:mm:ss");
        let serviceTime = serviceStartTime=="" || serviceEndTime=="" ? "": "Service Impact Start Time: " + serviceStartTime + ", " + "Service Impact End Time: " + serviceEndTime + ", " + "Outage(Minutes): " + values.outage + "  ";
		
		let validateFields = this.validateNonMandatoryFields();
		this.setState({ expectedStartDate: this.state.expectedStartDate });
		if (validateFields == true) {
			if (values.cimGroup === '') {
				swal({
					text: this.props.translator["Please select CIM group"],
					button: this.props.translator['OK'],
				});
				return false;

		}
		else if (values.cimUserDeails == '') {
			swal({
				text: this.props.translator["Please select CIM user"],
				button: this.props.translator['OK'],
			})
			return false;

		}
		else if (values.businessImpact == undefined && values.probableCause == undefined) {
			this.setState({ businessImpact: 'error' });
			this.setState({ probableCause: 'error' });
			return false;

		}
		else if (values.businessImpact == undefined || values.businessImpact.trim() == "") {
			this.setState({ businessImpact: 'error' });

			return false;

			}
			else if (values.probableCause == undefined || values.probableCause.trim() == "") {
				this.setState({ probableCause: 'error' });
				return false;
			}
			else if (values.type == undefined || values.type == "0") {
				swal({
					text: this.props.translator["Please select Type"],
					button: this.props.translator['OK'],
				})
				return false;
			}
			else {
				let sepratingValue = serviceTime == ""?" ":", "
				let descriptionValue = "BusinessImpact: " + values.businessImpact + ", " + "ProbableCause: " + values.probableCause + ", " + "Type: " + values.type + sepratingValue + serviceTime + "  "
				let impactAreaValues = "";
				let impactAreaArray = [];
				this.state.impactAreaList.forEach((impactObj, i) => {
					if (impactObj.impactArea == "" || impactObj.value == "" || impactObj.impactArea.trim() == "" || impactObj.value.trim() == "") {
						impactAreaValues = "";

					}
					else {
					let impactValue = "";
					impactValue = "Impact Area " + (i + 1) + ": " + impactObj.impactArea.trim();
					impactValue = impactValue + ", Value " + (i + 1) + ": " + impactObj.value.trim();
					impactAreaArray.push(impactValue);
					}
					
				})
					impactAreaValues = impactAreaArray.join(", ");
			
       				if (serviceStartTime == "" || serviceEndTime == "") {
					serviceStartTime = "";
					serviceEndTime = "";
				} 
				let separationValue = impactAreaValues == ""? " ":", "
				let postjsonactivity = {
					"itemId": GLOBAL.breakFixId,
					"description": descriptionValue + separationValue + impactAreaValues,
					"isInternal": "",
					"createdOn": "",
					"createdBy": "",
					"module": "Breakfix",
					"createdByName": "",
					"isResolvingStep": "true"
				};
				let patchPayload = {
					"cimGroup": this.props.breakFixEditDetails.cimGroup,
					"cimUser": this.props.breakFixEditDetails.cimUser,
					"cimGroupname": this.props.breakFixEditDetails.cimGroupname,
					"cimUsername": this.props.breakFixEditDetails.cimUsername,
					"businessImpact": values.businessImpact,
					"probableCause": values.probableCause,
					"type": values.type,
					"modifiedBy": "",
					"modifiedByName": "",
					"serviceImpactStartTime": serviceStartTime,
					"serviceImpactEndTime": serviceEndTime,
					"outage": values.outage
			    };
				if(this.props.breakFixEditDetails.criticality == 'Yes'){
					patchPayload.status = '30';
				}
				this.setState({ checkButtonStatus: true });
				let ref = this;
						axios.patch(GLOBAL.breakFixEditUrl + GLOBAL.breakFixId, patchPayload)
						.then(function (response) {
							axios.post('/serviceManagement/rest/impactedArea', ref.state.impactAreaList).then((response) => {console.log(response)}).catch((error) => {
								console.log(error);
							});
							ref.props.loadBreakFixEditDetails(response.data.breakfixId);
							ref.props.loadStatusBasedHamburgerOptions('Breakfix', response.data.status, response.data.breakfixId);
							axios.post(GLOBAL.breakFixActivityLogPostUrl, postjsonactivity, {
							}).then(function (response) {
								console.log("response" + response)

							})
							// browserHistory.push("/editBreakFix/breakFixItemId:" + GLOBAL.breakFixId);
							navigationHooks.navigate("/editBreakFix/" + GLOBAL.breakFixId);
							ref.props.showProcessHierarchy();

						})
						.catch(function (error) {
							console.log(error);
							ref.setState({ checkButtonStatus: false })

						});
					ref.props.showProcessHierarchy();

						//===================
					
			}
		}
	}

	render()
	{
		let { error, handleSubmit, pristine, reset, submitting, justification, cimGroup, cimUserDeails } = this.props;
		criticalityAction = <ButtonToolbar ><Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary" type="button" onClick={handleSubmit(this.submit)} >{this.state.checkButtonStatus ? <ImSpinner6 className="icn-spinner"/> : null}{this.props.translator['Save']}</Button></ButtonToolbar>
		if (this.props.breakFixEditDetails.criticality ===    'Yes') {
			return (
				<div className="hambTab">
					<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
						<ul>
							<li>
							<button
							    type='button'
								title="Minimize the right panel"
								bsClass=""
								bsStyle=""
								className="closerightPanelBtn"
								onClick={() => {
								this.props.rightEditPanel(false);
								this.props.changeRightView("allClose");
								}}
							>
								<IoClose />
							</button>
							</li>
						</ul>
					</div>
					<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Critical Incident Review']}</div></div>

					<div className='rBoxGap'>
						<font color="red">{error && <strong>{error}</strong>}</font>
						<Form.Group className="form-group" >
							<Form.Label><span className="rStar"></span>{this.props.translator['CIM Group']}</Form.Label>
							<Field name="cimGroup" component="input" type="text" className="form-control" readOnly>
							</Field>
						</Form.Group>
						<Form.Group className="form-group" >
							<Form.Label><span className="rStar"></span>{this.props.translator['CIM']}</Form.Label>
							<Field name="cimUserDeails" component="input" type="text" className="form-control" readOnly>

							</Field>
						</Form.Group>
						<Form.Group className="form-group" >
							<Form.Label><span className="rStar"></span>{this.props.translator['Type']}</Form.Label>
							<Field name="type" component="select" type="select" className="form-control">
								<option value="0">Select..</option>
								<option value="Service Unavailable">Service Unavailable</option>
								<option value="Location Unavailable">Location Unavailable</option>
								<option value="Production Server Risk">Production Server Risk</option>
								<option value="Other Justified Risk">Other Justified Risk</option>


							</Field>
						</Form.Group>
						<Form.Group className="form-group" 
						// validationState={this.state.businessImpact == '' ? GLOBAL.businessImpactErrorColor : this.state.businessImpact}
						>
							<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Business Impact']}</Form.Label>
							<Field name="businessImpact" component="textarea" className={"form-control " + (this.state.businessImpact == '' ? GLOBAL.businessImpactErrorColor : this.state.businessImpact)} maxLength="250" rows={2} onBlur={(event) => { this.validateField('businessImpact', event) }} onChange={(e) => { this.onBusinessImpactChange(e) }} />
							<p className="charLeft" style={{ textAlign: "right", "fontSize": "11px" }}>({this.props.translator['Characters Remaining']}: {this.state.businessImpactIssue}/250)</p>
						</Form.Group>
						<Form.Group className="form-group" 
						// validationState={this.state.probableCause == '' ? GLOBAL.probableCauseErrorColor : this.state.probableCause}
						>
							<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Probable Cause']}</Form.Label>
							<Field name="probableCause" component="textarea" className={"form-control " + (this.state.probableCause == '' ? GLOBAL.probableCauseErrorColor : this.state.probableCause)} maxLength="250" rows={2} onBlur={(event) => { this.validateField('probableCause', event) }} onChange={(e) => { this.onProbableCauseChange(e) }} />
							<p className="charLeft" style={{ textAlign: "right", "fontSize": "11px" }}>({this.props.translator['Characters Remaining']}: {this.state.probableCauseIssue}/250)</p>
						</Form.Group>
						<Form.Group className="form-group">
							<Row>
								<Col md={12}>
									<Form.Group className="form-group">
										<Form.Label bsClass="">{this.props.translator['Service Impact Start Time']}</Form.Label>
										<div className="dateTimeSdiv">
											<Field name="serviceImpactStartTime" component={_dateTimeField} className="form-control dateTimePickerField" formType="editChange"
												onChange={(event) => { this.setFormProperties(event, "serviceImpactStartTime"); }} defaultValue={this.state.expectedStartDate} />
										</div>
									</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="form-group">
										<Form.Label bsClass="">{this.props.translator['Service Impact End  Time']}</Form.Label>
										<div className="dateTimeSdiv">
										
											<Field name="serviceImpactEndTime" component={_dateTimeField} className="form-control dateTimePickerField" formType="editChange"
												onChange={(event) => { this.setFormProperties(event, "serviceImpactEndTime"); }} defaultValue={this.state.expectedEndDate} />
										</div>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col md={12}>
									<Form.Group className="form-group">
										<Form.Label bsClass="">{this.props.translator['Outage (Minutes)']}</Form.Label>
										<div className="dateTimeSdiv">
											<Field name="outage" component="input" className="form-control" formType="editChange" readOnly />
										</div>
									</Form.Group>
								</Col>
							</Row>
						</Form.Group>
						<Form.Group className="form-group">
							<div>{this.props.translator['Impact Area']}</div>
							<br />
							{GLOBAL.errormsg == "" ? null : <Alert variant="danger" className="errorMessageSec"><p>{GLOBAL.errormsg}</p></Alert>}
							<div className='f-size-12'>
								<span className='vendor'>{this.props.translator['Impact Area']}</span>
								<span className='ticket'>{this.props.translator['Value']}</span>
								<div className='borderBottom margin-t-10'></div>
							</div>
						</Form.Group>
						{this.renderImpactAreaList()}
					
						{criticalityAction}
					</div>
				</div>
			);
		} else {
			return (
				<div className="hambTab">
					<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
						<ul>
							<li>
							<button
							    type='button'
								title="Minimize the right panel"
								bsClass=""
								bsStyle=""
								className="closerightPanelBtn"
								onClick={() => {
								this.props.rightEditPanel(false);
								this.props.changeRightView("allClose");
								}}
							>
								<IoClose />
							</button>
							</li>
						</ul>
					</div>
					<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Incident Review']}</div></div>

					<div className='rBoxGap'>
						<font color="red">{error && <strong>{error}</strong>}</font>
						{/* <Form.Group className="form-group" >
							<Form.Label><span className="rStar"></span>{this.props.translator['CIM Group']}</Form.Label>
							<Field name="cimGroup" component="input" type="text" className="form-control" readOnly>
							</Field>
						</Form.Group>
						<Form.Group className="form-group" >
							<Form.Label><span className="rStar"></span>{this.props.translator['CIM']}</Form.Label>
							<Field name="cimUserDeails" component="input" type="text" className="form-control" readOnly>

							</Field>
						</Form.Group> */}
						<Form.Group className="form-group" >
							<Form.Label><span className="rStar"></span>{this.props.translator['Type']}</Form.Label>
							<Field name="type" component="select" type="select" className="form-control">
								<option value="0">Select..</option>
								<option value="Service Unavailable">Service Unavailable</option>
								<option value="Location Unavailable">Location Unavailable</option>
								<option value="Production Server Risk">Production Server Risk</option>
								<option value="Other Justified Risk">Other Justified Risk</option>


							</Field>
						</Form.Group>
						<Form.Group className="form-group" 
						// validationState={this.state.businessImpact == '' ? GLOBAL.businessImpactErrorColor : this.state.businessImpact}
						>
							<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Business Impact']}</Form.Label>
							<Field name="businessImpact" component="textarea" className={"form-control " + (this.state.businessImpact == '' ? GLOBAL.businessImpactErrorColor : this.state.businessImpact)} maxLength="250" rows={2} onBlur={(event) => { this.validateField('businessImpact', event) }} onChange={(e) => { this.onBusinessImpactChange(e) }} />
							<p className="charLeft" style={{ textAlign: "right", "fontSize": "11px" }}>({this.props.translator['Characters Remaining']}: {this.state.businessImpactIssue}/250)</p>
						</Form.Group>
						<Form.Group className="form-group" 
						// validationState={this.state.probableCause == '' ? GLOBAL.probableCauseErrorColor : this.state.probableCause}
						>
							<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Probable Cause']}</Form.Label>
							<Field name="probableCause" component="textarea" className={"form-control " + (this.state.probableCause == '' ? GLOBAL.probableCauseErrorColor : this.state.probableCause)} maxLength="250" rows={2} onBlur={(event) => { this.validateField('probableCause', event) }} onChange={(e) => { this.onProbableCauseChange(e) }} />
							<p className="charLeft" style={{ textAlign: "right", "fontSize": "11px" }}>({this.props.translator['Characters Remaining']}: {this.state.probableCauseIssue}/250)</p>
						</Form.Group>
						<Form.Group className="form-group">
							<Row>
								<Col md={12}>
									<Form.Group className="form-group">
										<Form.Label bsClass="">{this.props.translator['Service Impact Start Time']}</Form.Label>
										<div className="dateTimeSdiv">
											<Field name="serviceImpactStartTime" component={_dateTimeField} className="form-control dateTimePickerField" formType="editChange"
												onChange={(event) => { this.setFormProperties(event, "serviceImpactStartTime"); }} defaultValue={this.state.expectedStartDate} />
										</div>
									</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="form-group">
										<Form.Label bsClass="">{this.props.translator['Service Impact End  Time']}</Form.Label>
										<div className="dateTimeSdiv">
										
											<Field name="serviceImpactEndTime" component={_dateTimeField} className="form-control dateTimePickerField" formType="editChange"
												onChange={(event) => { this.setFormProperties(event, "serviceImpactEndTime"); }} defaultValue={this.state.expectedEndDate} />
										</div>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col md={12}>
									<Form.Group className="form-group">
										<Form.Label bsClass="">{this.props.translator['Outage (Minutes)']}</Form.Label>
										<div className="dateTimeSdiv">
											<Field name="outage" component="input" className="form-control" formType="editChange" readOnly />
										</div>
									</Form.Group>
								</Col>
							</Row>
						</Form.Group>
						<Form.Group className="form-group">
							<div>{this.props.translator['Impact Area']}</div>
							<br />
							{GLOBAL.errormsg == "" ? null : <Alert variant="danger" className="errorMessageSec"><p>{GLOBAL.errormsg}</p></Alert>}
							<div className='f-size-12'>
								<span className='vendor'>{this.props.translator['Impact Area']}</span>
								<span className='ticket'>{this.props.translator['Value']}</span>
								<div className='borderBottom margin-t-10'></div>
							</div>
						</Form.Group>
						{this.renderImpactAreaList()}
					
						{criticalityAction}
					</div>
				</div>
			);
		}
	}
}

XsmBreakFixCIR = reduxForm({
	form: 'acceptCriticalTab',
	fields: ['justification', 'cimGroup', 'cimUserDeails'],
	//destroyOnUnmount: false
	destroyOnUnmount: false,
	enableReinitialize: true

})(XsmBreakFixCIR);

let selector = formValueSelector('acceptCriticalTab') // <-- same as form name
XsmBreakFixCIR = connect(
	state => {
		// can select values individually
		let cimGroup = selector(state, 'cimGroup')
		let cimUserDeails = selector(state, 'cimUserDeails')
		let serviceImpactStartTimeVal = selector(state, 'serviceImpactStartTime');
		let serviceImpactEndTimeVal = selector(state, 'serviceImpactEndTime');
		let outageVal = selector(state,'outage')

		return {
			cimGroup,
			cimUserDeails,
			serviceImpactStartTimeVal,
			serviceImpactEndTimeVal,
			outageVal
		}
	}
)(XsmBreakFixCIR)

export function mapStateToProps({ cimGroupDetails, cimUserDetails, breakFixEditDetails, breakFixSGAEName }) {
	return {
		initialValues: {
			"cimGroup": breakFixEditDetails.cimGroupname,
			"cimUserDeails": breakFixEditDetails.cimUsername
		},
		cimGroupDetails, cimUserDetails, breakFixEditDetails, breakFixSGAEName
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadBreakFixSupportCIMGroup, loadBreakFixCIMDetails, loadBreakFixEditDetails, loadBreakFixEditDetailsUpdate, loadBreakFixSGAE, loadStatusBasedHamburgerOptions, loadTimelineData }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(XsmBreakFixCIR);
