
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Button, ButtonGroup, Col, Form, ListGroup, Row } from "react-bootstrap";
import { MyContext } from '_MyContext';
import { connect } from "react-redux";
import { fetchOfferingDetails, loadOfferingAavailability, loadOfferingDeliveryType, loadOfferingSlaDetails } from "_Actions/offeringDetails/offeringDetailsActions";
import { getAttachmentsPaginationAction, getAttachmentsAction } from '../../../../actions/spcmActions';
import { loadComponentPackage } from "_Actions/categorylist/componentPackageAction";
import { loadRatingCalcOffferingFromRE } from "_Actions/myServices/myServicesActions";
import { loadCartCount } from "_Actions/common/cartCountAction";
import { postAttributeBasedSlaAction } from "../../../../actions/sla/lovAction";
import ProductDetails from "../common/ProductDetails";
import SLADetails from "../common/SlaDetails";
import ServiceReview from "../common/ServiceReview";
import axios from "axios";
import _ from "lodash";
import Cookies from "universal-cookie";
import swal from "sweetalert";
import { LiaHeartSolid } from 'react-icons/lia';
import ListLoader from "_Commons/loaders/ListLoader";
const Joi = require("joi");
import sanitizeHtml from 'sanitize-html';
import Variants from "./variants";
import KgArticles from "./kgArticles";
import { SlArrowLeft,SlArrowRight } from "react-icons/sl";
import { loadDiscourseArticlesForSearch, loadDiscourseArticlesForSearchByTopicId } from "../../../../actions/knowledge/knowledgeAction";
import ReactStars from 'react-stars';

import { GLOBAL } from '_Globals';
import { Link } from "react-router-dom";
import { navigationHooks } from "../../../../helpers/NavigationHook";
import Swal from "sweetalert2";
import { openLinkInNewTab } from "../../../common/helper";

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});
const cookies = new Cookies();
let cart_limit = cookies.get("gph");

if (cart_limit) cart_limit = cart_limit.replace("s:", "");
if (cart_limit)
  cart_limit = cart_limit.substring(0, cart_limit.lastIndexOf("."));
cart_limit = cart_limit.split("~");

cart_limit = cart_limit[19];
if (cart_limit === "" || cart_limit === "undefined" || cart_limit === undefined)
  cart_limit = 5;

let homepagelocation = cookies.get("gph");

if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];

if (homepagelocation != "/home")
  homepagelocation = "/" + homepagelocation;

let discourseUrl = cookies.get("gph");
if (discourseUrl) discourseUrl = discourseUrl.replace("s:", "");
if (discourseUrl)
  discourseUrl = discourseUrl.substring(0, discourseUrl.lastIndexOf("."));
discourseUrl = discourseUrl.split("~");
discourseUrl = discourseUrl[50];

let gph = cookies.get("gph");
if (gph) gph = gph.replace("s:", "");
if (gph) gph = gph.substring(0, gph.lastIndexOf("."));
gph = gph.split("~");
const envCompanyId = gph[48];
class OfferingDetails extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
      addCardButtonDisableStatus: false,
      checkoutButtonDisableStatus: false,
      selfCheckoutButtonStatus: false,
      favoriteval: "",
      compareval: "",
      productDetails: true,
      sLADetails: false,
      serviceReview: false,
      askToCommunity: false,
      rightSectionDetails: {},
      isShortdesLessMore: false,
      isShowKgArticles:true,
      searchArticle: "",
      showArticleTable: false,
      articleDetails: null,
    };
    this.saveOfferingAndComponentToCart = this.saveOfferingAndComponentToCart.bind(this);
    this.onAddFavourite = this.onAddFavourite.bind(this);
    this.askToCommunity = this.askToCommunity.bind(this);
    this.otherActionbtnFun = this.otherActionbtnFun.bind(this);
    this.renderOptionalList = this.renderOptionalList.bind(this);
    this.renderCoreList = this.renderCoreList.bind(this);
    this.renderAttachmentsList = this.renderAttachmentsList.bind(this);
    this.handleClickStartChange = this.handleClickStartChange.bind(this);
    this.shortdesLessMoreFun = this.shortdesLessMoreFun.bind(this);
    this.showKgArticles=this.showKgArticles.bind(this);
    this.renderLargeTextEllipse = this.renderLargeTextEllipse.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.setStateV1 = this.setStateV1.bind(this);
    this.submitSearchArticle = this.submitSearchArticle.bind(this);
    this.getSharedCompanyIds = this.getSharedCompanyIds.bind(this);
  }
  showKgArticles(){
    this.setState({ isShowKgArticles: !this.state.isShowKgArticles });
  }
  shortdesLessMoreFun() {
    this.setState({ isShortdesLessMore: !this.state.isShortdesLessMore });
  }
  componentDidMount() {
    this.context.keyDownHandler(this.closeButton, this.editButton, this.globalSearchRef, this.saveObjRef);
  }
  componentDidUpdate() {
    this.context.keyDownHandler(this.closeButton, this.editButton, this.globalSearchRef, this.saveObjRef);
  }
  componentWillMount() {
    this.props.fetchOfferingDetails(this.props.offeringId);
    this.props.loadComponentPackage(this.props.offeringId);
    this.props.loadRatingCalcOffferingFromRE(this.props.offeringId);
    // this.props.getAttachmentsPaginationAction(this.props.offeringId,"desc",1,10)
    this.props.getAttachmentsAction(this.props.offeringId);


    // this.props.loadOfferingSlaDetails(this.props.offeringId);
    // this.props.loadOfferingAavailability(this.props.offeringId);
    // this.props.loadOfferingDeliveryType(this.props.offeringId);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.offeringId !== nextProps.offeringId) {
      this.setState({
        productDetails: true,
        sLADetails: false,
        serviceReview: false,
      });
      this.props.fetchOfferingDetails(nextProps.offeringId);
      this.props.loadComponentPackage(nextProps.offeringId);
      this.props.loadRatingCalcOffferingFromRE(nextProps.offeringId);
      // this.props.getAttachmentsPaginationAction(nextProps.offeringId,"desc",1,10)
      this.props.getAttachmentsAction(nextProps.offeringId);


      // this.props.loadOfferingSlaDetails(nextProps.offeringId);
      // this.props.loadOfferingAavailability(nextProps.offeringId);
      // this.props.loadOfferingDeliveryType(nextProps.offeringId);
    }

    if (this.props.seachDiscription !== nextProps.seachDiscription) {
      if (nextProps.seachDiscription == '') {
        this.props.emptyInsidedReducer();
        if (spcmKnowledgeCall == 'true')
          this.props.loadArticleResultsForSearchString('');

        this.setState({ articleDetails: null });
        this.setState({ showLoaderState: false })
        this.setState({ showSearchMsg: false })
      }
      this.setState({ searchString: nextProps.seachDiscription });
      this.setState({ searchStringPlaceHolder: nextProps.seachDiscription })
      this.setState({ showSerachlence: true })
    }
  }

  renderLargeTextEllipse(text, limit = 21) {
    try {
      return (
        <span title={text}>
          {text.slice(0, limit)}
          {text.length > limit ? "..." : ""}
        </span>
      );
    } catch (e) {
      return null;
    }
  }

  downloadFile(type, slug, id, path) {
    if (type === "SPCM") {
      let link = document.createElement("a");
      link.href = path;
      link.id = "temp_downloadfile";
      link.target = "_self";
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        link.remove();
      }, 1000000);
    } else {
      const ssoUrl = `${discourseUrl}/t/${slug}`;
      openLinkInNewTab(ssoUrl, "New Tab");
    }
  }

  setStateV1(obj) {
    this.setState((state) => {
      let updatedState = Object.assign({}, state, obj);
      return updatedState;
    });
  }

  submitSearchArticle(searchText) {
    const { searchFilter, searchBy } = this.state;
    if (isKnowledgeEnabled == true || isKnowledgeEnabled == "true") {
      let tempText = searchText
        .replace("?", "")
        .replace("%", "")
        .replace(/#/g, "");
      if (searchBy == "Keyword")
        this.props.loadDiscourseArticlesForSearch(searchText, username);
      else if (searchBy == "Topic ID")
        this.props.loadDiscourseArticlesForSearchByTopicId(
          searchText,
          username
        );
    }
  }

  onSendEmail(a) {
    //alert(a)
    if (a == null || a === "null") a = "";
    let to = a;

    if (to != null) {
      let namedSchema = Joi.string().regex(
        /^[0-9a-zA-Z!#$%&'*+-/=?^_`|}{~@][^<>)(}{}]+$/
      );
      let validator_to_add_myQbj_1 = namedSchema.validate(to);
      if (validator_to_add_myQbj_1.error == null) {
        //window.location.href = "mailto:" + to;
      }
    } else {
      swal({
        text: "Not able to send mail!",
        button: this.props.translator["OK"],
      });
    }
  }

  onCompareClick(object) {
    let ref = this;
    let anchorid = document.getElementById("anchortitle");
    let anchortitle = anchorid.title;

    let offeringidlocalstorage = "";

    let item_attr_to_get_value = "offeringidlistfrcompare";
    let namedSchema = Joi.string().regex(/^[^!@#$%^&*}{_+=|\:;\'\",<.>?~-]+$/);
    let validator_to_add_myQbj = namedSchema.validate(item_attr_to_get_value);
    if (validator_to_add_myQbj.error == null) {
      if (localStorage.getItem(item_attr_to_get_value) != null) {
        let intermediate_value = localStorage
          .getItem(item_attr_to_get_value)
          .split(",").length;
        namedSchema = Joi.string().regex(/^[^!@#$%^&*}{_+=|\:;\'\",<.>?~-]+$/);
        validator_to_add_myQbj = namedSchema.validate(intermediate_value);
        if (intermediate_value.error == null) {
          offeringidlocalstorage = intermediate_value;
        } else {

          offeringidlocalstorage = "";
        }
      }
    } else {
      offeringidlocalstorage = "";
    }


    // if (localStorage.getItem("offeringidlistfrcompare") != null) {
    //   offeringidlocalstorage = localStorage.getItem("offeringidlistfrcompare").split(',').length;
    // }
    if (GLOBAL.offeringidtotal.length >= 30 || offeringidlocalstorage >= 30) {
      swal({
        text: this.props.tr["You cannot add more than 3 offerings in compare."],
        button: this.props.tr["OK"],
      });

      // alert(this.props.translator["You cannot add more than 3 offerings in compare."]);
      return false;
    } else {
      GLOBAL.offeringidtotal.push(object.offeringId);
    }

    if (GLOBAL.offeringidtotal != null) {
      let field_value = "offeringidlistfrcompare";
      namedSchema = Joi.string().regex(/^[^!@#$%^&*}{_+=|\:;\'\",<.>?~-]+$/);
      validator_to_add_myQbj = namedSchema.validate(GLOBAL.offeringidtotal);
      let validator_to_add_myQbj_2 = namedSchema.validate(field_value);
      if (
        validator_to_add_myQbj.error == null &&
        validator_to_add_myQbj_2.error == null
      ) {
        localStorage.setItem(field_value, GLOBAL.offeringidtotal.toString());
        localStorage.setItem(
          field_value,
          JSON.stringify(GLOBAL.offeringidtotal)
        );
      }
    }
    // localStorage.setItem("offeringidlistfrcompare", GLOBAL.offeringidtotal.toString());
    // localStorage.setItem("offeringidtotalfrremove", JSON.stringify(GLOBAL.offeringidtotal));
    ref.setState({ compareval: "1" });
  }

  insertaddfavouriteSelfService(object) {
    let ref = this;

    let storage_field = "offeringidtotalfrremove";
    let namedSchema = Joi.string().regex(/^[^!@#$%^&*}{_+=|\:;\'\",<.>?~-]+$/);
    let validator_to_add_myQbj = namedSchema.validate(storage_field);
    if (validator_to_add_myQbj.error == null) {
      GLOBAL.offeringidtotal = JSON.parse(
        localStorage.getItem(storage_field).replace('"', "").replace('"', "")
      );
    } else {
      GLOBAL.offeringidtotal = 0;
    }

    // GLOBAL.offeringidtotal = JSON.parse(localStorage.getItem("offeringidtotalfrremove").replace('"', '').replace('"', ''));

    let index = GLOBAL.offeringidtotal.indexOf(object.offeringId);

    if (index > -1) {
      GLOBAL.offeringidtotal.splice(index, 1);
    }

    if (GLOBAL.offeringidtotal != null) {
      let field_value = "offeringidlistfrcompare";
      namedSchema = Joi.string().regex(/^[^!@#$%^&*}{_+=|\:;\'\",<.>?~-]+$/);
      validator_to_add_myQbj = namedSchema.validate(GLOBAL.offeringidtotal);
      let validator_to_add_myQbj_2 = namedSchema.validate(field_value);
      if (
        validator_to_add_myQbj.error == null ||
        validator_to_add_myQbj_2.error == null
      ) {
        localStorage.setItem(field_value, GLOBAL.offeringidtotal.toString());
        localStorage.setItem(
          field_value,
          JSON.stringify(GLOBAL.offeringidtotal)
        );
      } else {
        GLOBAL.offeringidtotal = 0;
      }
    } else GLOBAL.offeringidtotal = 0;
    // localStorage.setItem("offeringidlistfrcompare", GLOBAL.offeringidtotal.toString());
    // localStorage.setItem("offeringidtotalfrremove", JSON.stringify(GLOBAL.offeringidtotal));
    ref.setState({ compareval: "0" });
  }

  otherActionbtnFun(propsValue) {
    switch (propsValue) {
      case "Product Details": {
        this.setState({
          productDetails: true,
          sLADetails: false,
          serviceReview: false,
          askToCommunity: false,
        });
        break;
      }
      case "SLA Details": {
        this.setState({
          productDetails: false,
          sLADetails: true,
          serviceReview: false,
          askToCommunity: false,
        });
        this.props.postAttributeBasedSlaAction('', this.props.offeringId, this.props.offeringDetails[0].company, '', '', '', '', '', '')
        break;
      }
      case "Service Review": {
        this.setState({
          productDetails: false,
          sLADetails: false,
          serviceReview: true,
          askToCommunity: false,
        });
        // this.props.loadRatingCalcOffferingFromRE(this.props.offeringId);
        break;
      }
      case "Ask to community": {
        this.setState({
          productDetails: false,
          sLADetails: false,
          serviceReview: false,
          askToCommunity: true,
        });
        break;
      }
      default: {
        this.setState({
          productDetails: false,
          sLADetails: false,
          serviceReview: false,
          askToCommunity: false,
        });
        break;
      }
    }
  }

  askToCommunity(val) {
    this.setState({
      askToCommunity: val,
    });
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.askToCommunity === true) {
      document
        .querySelector("#askToCommunity")
        .scrollIntoView({ behavior: "smooth" });
    }
  }

  onAddFavourite(object) {
    let ref = this;

    axios
      .post(GLOBAL.postOfferingFavUrl, {
        entitledUser: "",
        offeringId: object.offeringId,
      })
      .then(function (response) {
        if (response.status === 200) {
          ref.setState({ favoriteval: "1" });
        }
      })
      .catch(function (error) {
      });
  }

  onRemoveFavourite(object) {
    let ref = this;
    let UID = "";
    axios
      .post(GLOBAL.postOfferingFavRemoveUrl, {
        entitledUser: "",
        offeringId: object.offeringId,
      })
      .then(function (response) {
        if (response.status === 200) {
          ref.setState({ favoriteval: "0" });
        }
      })
      .catch(function (error) {
      });
  }

  saveOfferingAndComponentToCart(offeringId, offeringComponentsForCart) {
    let ref = this;
    ref.setState({ addCardButtonDisableStatus: true });
    ref.setState({ checkoutButtonDisableStatus: true });
    ref.setState({ selfCheckoutButtonStatus: true });
    let str = {};
    str.userId = "";
    str = JSON.stringify(str);
    const offeringsInCartResponse = api.get(GLOBAL.cartOfferingsUrl, {
      headers: { query: str },
    });
    offeringsInCartResponse.then((allOfferingsInCart) => {
      if (allOfferingsInCart.data.length >= cart_limit) {
        swal({
          text: this.props.tr[
            "Maximum number of offerings are already added in cart."
          ],
          button: this.props.tr["OK"],
        });

        // alert(this.props.translator["Maximum number of offerings are already added in cart."]);
        ref.setState({
          checkoutButtonDisableStatus: false,
          selfCheckoutButtonStatus: false,
          addCardButtonDisableStatus: false,
        });
      } else {
        if (
          allOfferingsInCart.data.length === 0 ||
          !_.some(allOfferingsInCart.data, {
            offeringId: offeringId.toString(),
          })
        ) {
          api
            .post(GLOBAL.addOfferingToCartUrl, {
              offeringId: offeringId,
              userId: "",
            })
            .then((response) => {
              if (response.status == 200) {
                if (offeringComponentsForCart.length !== 0) {
                  for (let i = 0; i < offeringComponentsForCart.length; i++) {
                    api
                      .post(GLOBAL.addComponentsToCart, {
                        offeringId: offeringId,
                        componentId: offeringComponentsForCart[i].id,
                        componentType:
                          offeringComponentsForCart[i].componentType,
                      })
                      .then((response) => {
                        if (response.status === 200) {
                          // ref.setState({ addCardButtonDisableStatus: true });
                          // browserHistory.push("/cart");
                          //  swal({
                          //   text: "Added to cart",
                          //   buttons: ["Go to Home", "Checkout Now"]

                          // })
                          // .then((willDelete) => {
                          //   if (willDelete) {
                          //     //TODO when pressed no
                          //     browserHistory.push("/cart");
                          //   } else {
                          //     //TODO when pressed yes
                          //     browserHistory.push(homepagelocation);
                          //   }
                          // });
                        } else {
                          ref.setState({
                            addCardButtonDisableStatus: false,
                            checkoutButtonDisableStatus: false,
                            selfCheckoutButtonStatus: false,
                          });
                          swal({
                            text: this.props.tr[
                              "Attached components could not be added, please try again."
                            ],
                            button: this.props.tr["OK"],
                          });

                          // alert(this.props.translator["Attached components could not be added, please try again."])
                        }
                      });
                  }
                }
                this.props.loadCartCount();
                ref.setState({ addCardButtonDisableStatus: true });
                //browserHistory.push("/cart");
                Swal.fire({
                  title: this.props.translator["Item is added to cart"],
                  showCancelButton: true,
                  confirmButtonText: this.props.translator["Cart"],
                  cancelButtonText: this.props.translator["Proceed to Home"]
                })
                  .then((result) => {
                    if (result.isDismissed && result.dismiss === "cancel") {
                      navigationHooks.navigate(homepagelocation);
                    } else if (result.isConfirmed) {
                      navigationHooks.navigate("/cart");
                    }
                  });
                ref.setState({
                  addCardButtonDisableStatus: false,
                  checkoutButtonDisableStatus: false,
                  selfCheckoutButtonStatus: false,
                });
              } else {
                ref.setState({
                  addCardButtonDisableStatus: false,
                  checkoutButtonDisableStatus: false,
                  selfCheckoutButtonStatus: false,
                });
                swal({
                  text: this.props.tr[
                    "There is a problem adding your recent offering to the cart try again."
                  ],
                  button: this.props.tr["OK"],
                });

                // alert(this.props.translator["There is a problem adding your recent offering to the cart try again."]);
              }
            });
        } else {
          swal({
            text: this.props.tr["Offering already added in cart."],
            button: this.props.tr["OK"],
          });

          // alert(this.props.translator["Offering already added in cart."]);
          ref.setState({
            checkoutButtonDisableStatus: false,
            selfCheckoutButtonStatus: false,
            addCardButtonDisableStatus: false,
          });
        }
      }
    });
  }

  handleClickStartChange() {
    swal({
      text: this.props.tr["Minimum one optional component is required"],
      button: this.props.tr["OK"],
    });
  }

  renderCoreList(componentPackage, selectedComponentForOffering) {
    return componentPackage.map((component) => {
      if (component.componentType === "Core") {
        if (!_.some(selectedComponentForOffering, ["id", component.id])) {
          this.props.setOfferingComponentsForCart(component, "core_component");
        }
        return (
          <div className="margin-t-10 gray f-size-14 fw-400" key={component.id}>
            <Row>
              <Col lg={3} md={4} sm={6} xs={6}>
                <div>{component.componentName}</div>
              </Col>
              <Col lg={9} md={8} sm={6} xs={6}>
                <div>{component.descriptionForComponent ? component.descriptionForComponent : ""}</div>
              </Col>
              {/* <Col xs={1} sm={1}><Checkbox className="hidden dCheckbox float-r readOnlyChk" checked readOnly><span /></Checkbox></Col> */}
            </Row>
          </div>
        );

      }
    });
  }
  renderAttachmentsList(listData) {

    return listData.map((value) => {
      let validated_url = undefined;
      // if (value != null || value.ATTACHMENT_URL != undefined) {
      if (value && value.ATTACHMENT_URL != undefined) {
        let namedSchema = Joi.string().regex(/^[^<>}{]+$/);
        let validator_to_add_myQbj = namedSchema.validate(
          value.ATTACHMENT_URL
        );
        if (validator_to_add_myQbj.error == null) {
          validated_url = value.ATTACHMENT_URL;
        } else {
          validated_url = "\\";
        }
      } else validated_url = "\\";
      return (
        <div className="margin-t-10 gray f-size-14 fw-400">
          <Row>
            <Col lg={4} md={4} sm={6} xs={6}>
              <div>{value.ORIGINAL_ATTACHMENT_NAME}</div>
            </Col>
            <Col lg={8} md={8} sm={6} xs={6}>
              {/* <div className="text-c"> */}
              <a className="margin-r-10" href={validated_url}>
                <b>Download</b>
              </a>
              {/* </div> */}
            </Col>
          </Row>
        </div>
      );


    });
  }

  renderOptionalList(componentPackage, selectedComponentForOffering) {
    // if (!_.some(componentPackage, ["componentType", "Optional"])) {
    //   return <div className="gray">{this.props.translator['No optional component exists']}</div>;
    // }
    return componentPackage.map((component) => {
      if (component.componentType === "Optional") {
        return (
          <div className="margin-t-10 gray f-size-14 fw-400" key={component.id}>
            <Row>
              <Col lg={2} md={1} sm={2} xs={2} className="">
                <Form>
                  {_.some(selectedComponentForOffering, ['id', component.id]) ?
                    <Form.Check id={"Optional_" + component.id} bsClass="" className="simpCheckbox" defaultChecked onClick={(event) => {
                      this.props.setOfferingComponentsForCart(component, event);
                    }}></Form.Check>
                    :
                    <Form.Check id={"Optional_" + component.id} bsClass="" className="simpCheckbox" onClick={(event) => {
                      this.props.setOfferingComponentsForCart(component, event);
                    }}></Form.Check>
                  }
                </Form>
              </Col>
              {this.props.offeringDetails[0].hidePrice === "true" ? <Col lg={10} md={11} sm={10} xs={10}>
                <div>{component.componentName ? component.componentName : ""}</div>
              </Col>
                : <Col lg={5} md={6} sm={5} xs={4}>
                  <div>{component.componentName ? component.componentName : ""}</div>
                </Col>}
              {this.props.offeringDetails[0].hidePrice === "true" ? null :
                <Col lg={3} md={3} sm={3} xs={3} className="">
                  <div>{component.billing}</div></Col>
              }
              {this.props.offeringDetails[0].hidePrice === "true" ? null :
                <Col lg={2} md={2} sm={2} xs={3} className="">
                  <div><span
                    className="priceValue2">{component.userPreferredCurrency + "  " + Number(component.actualPrice).toFixed(2)}</span>
                  </div>
                </Col>
              }
            </Row>
          </div>
        );
      }
    });
  }

  renderOfferingDetails(
    offeringDetails,
    selectedComponentForOffering,
    favorite,
    optionalComponentSelected,
    optionalComponentAvailable,
    attachmentReducerData
  ) {
    return offeringDetails.map((offering) => {
      let namedSchema = undefined;
      namedSchema = Joi.string().regex(/^[^}{]+$/);
      let validator_to_add_myQbj = undefined;
      let validate_summary = undefined;
      let isOfferingDesTxt = "";
      let isOfferingDesTxtCount = "";

      let displayText = "";
      let displayStr = "";
      displayStr = offering.offeringName != null && offering.offeringName != undefined ? offering.offeringName : "";
      displayText = displayStr != "" ? displayStr.replace(/(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/ig, '').trim() : "";
      displayText = displayText != "" ? displayText.replace(/\s+/g, ' ') : "";
      // if (offering != null || offering.description != "") {
      if (offering && offering.description != "") {
        validator_to_add_myQbj = namedSchema.validate(offering.description);
        if (validator_to_add_myQbj.error == null) {
          validate_summary = offering.description;
          let offeringDescriptionCount = validate_summary;
          isOfferingDesTxt = offeringDescriptionCount.replace(/(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/ig, '').trim();
          isOfferingDesTxt = isOfferingDesTxt.replace(/\s+/g, ' ');
          isOfferingDesTxtCount = isOfferingDesTxt.length;
        } else {
          validate_summary = "";
        }
      } else validate_summary = "";
      validate_summary = sanitizeHtml(validate_summary, {
        allowedAttributes: {
          '*': ['style', 'class', 'dir', 'bgcolor'],
          'table': ['border', 'cellpadding', 'cellspacing'],
          'th': ['scope'],
          'a': ['href', 'target']
        }
      })

      let appflag = "";
      if (offering.approvalRequired === "Error" && offering.workItemType === "serviceRequest") {
        appflag = (
          <div className="reqTxtDv"><span title={this.props.translator["Approvals were not fetched due to some issue."]} className="reqTxt color-red"><i className="fa fa-thumbs-up"></i><i className="infoIcn fa fa-info-circle"></i></span></div>
        );
      }
      else if (offering.approvalRequired === "yes") {
        appflag = (
          <div className="reqTxtDv"><span title={this.props.translator["Requires Approval"]} className="reqTxt"><i className="fa fa-thumbs-up"></i><i className="infoIcn fa fa-info-circle"></i></span></div>
        );
      }

      const mystyle = {
        height: "60px",
        overflow: "hidden"
      };

      let ratingForChange = "";
      ratingForChange = "(0/5 based on 0 reviews)";

      let getInnerWidth = (innerWidth) => {
        if (innerWidth > 1535) return 170;
        if (innerWidth > 1367) return 138;
        if (innerWidth > 1279) return 125;
        if (innerWidth > 1023) return 90;
        if (innerWidth > 767) return 78;
        if (innerWidth > 640) return 52;
        if (innerWidth > 539) return 42;
        if (innerWidth > 411) return 62;
        if (innerWidth > 279) return 38;
        return 90;
      }

      return (
        <div key={offering.offeringId}>
          <Row>
            <Col xs={12}>
              <h1 className="sPageHeading1" title={offering.offeringName}>{displayText.slice(0, getInnerWidth(this.props.windowSize.width))}{displayText.length > getInnerWidth(this.props.windowSize.width) ? "..." : ''}</h1>
            </Col>
          </Row>

          <Row key={offering.offeringId}>
            <Col lg={6} md={6} xs={12}>
              <div className="proStarView">
              <ReactStars value={this.props.offeringDetails[0].rating == null ? 0 : this.props.offeringDetails[0].rating} edit={false} count={5} size={24} color1={'grey'} color2={this.props.appliedThemeData?.secondaryColor} />
                {this.props.ratingCalcReviewFromRE.data && <>                  
                  {this.props.offeringDetails[0].workItemType == 'standardChange' ?
                    <p className="display-inline-block">(0/5 {this.props.translator["based on"]} 0 {this.props.translator["reviews"]})</p>
                    :
                    <a title={this.props.translator["Ratings & Reviews"]} role="button" onClick={() => { this.otherActionbtnFun("Service Review"); }} href="#serviceReviewTab">
                      ({this.props.offeringDetails[0].rating == null ? 0 : this.props.offeringDetails[0].rating}/5 {this.props.translator["based on"]} {this.props.ratingCalcReviewFromRE.data.totalRatings} {this.props.translator["reviews"]})
                    </a>}
                </>
                }
              </div>

              {this.props.offeringDetails[0].hidePrice === "true" ? null : <div className="offpriceSec">
                <div className="pbSec">
                  <div>
                    {this.props.translator["Price:"]}{" "}
                    <span className="fw-600">
                      {this.props.offeringDetails[0].userPreferredCurrency +
                        " " +
                        Number(
                          this.props.offeringDetails[0].actualPrice
                        ).toFixed(2)}
                    </span>
                  </div>
                </div>
                <div className="pbSec">
                  <div>
                    {this.props.translator["Billing Method:"]}{" "}
                    <span className="fw-600">
                      {this.props.offeringDetails[0].billingMethod}
                    </span>
                  </div>
                </div>
              </div>}
              <Variants />
            </Col>
            <Col lg={6} md={6} xs={12} className="text-r">
              {this.props.offeringDetails[0].isEntitled == false ?
                <div className="text-r margin-t-5">
                  <span className="fw-600 red">
                    {this.props.translator["You are not entitled to order this service"]}
                  </span>
                </div>
                : this.props.offeringDetails[0].external_offering == "true" ?
                  <ButtonGroup bsClass="" bsStyle="" className="toolbarBtnStyle display-inline-block">
                    <Button
                      title="Navigate To"
                      bsStyle="primary"
                      className="rgSidrkBtn margin-l-10"
                      id="navigateCkout"
                      onClick={() => { 
                        openLinkInNewTab(this.props.offeringDetails[0].url, "New Tab");
                      }}
                      ref={e => this.saveObjRef = e}
                    >
                      {this.props.translator["Navigate To"]}
                    </Button>
                  </ButtonGroup>
                  :



                  <div className="text-r margin-t-5">


                    <ButtonGroup className="proIconSet">
                      {appflag}
                      <a id="proShar" title="Share" role="button" href="javascript:void(0)"><i className="fa fa-share-alt"></i></a>
                      {favorite}
                    </ButtonGroup>

                    <ButtonGroup bsClass="" bsStyle="" className="toolbarBtnStyle display-inline-block">
                      {(this.props.offeringDetails[0].workItemType ===
                        "incidentRequest" ||
                        this.props.offeringDetails[0].workItemType ===
                        "standardChange" ||
                        this.props.offeringDetails[0].status === "Proposed" ||
                        this.props.offeringDetails[0].deliveryMode ==
                        "Other") || this.props.requestType === 'OnBehalf' ? null : (
                        <Button
                          title={this.props.translator["Add to Cart"]}
                          bsStyle="link"
                          id="proAddCart"
                          className="rgSiLigBtn"
                          disabled={this.state.addCardButtonDisableStatus}
                          onClick={() => {
                            this.saveOfferingAndComponentToCart(
                              this.props.offeringDetails[0].offeringId,
                              selectedComponentForOffering
                            );
                          }}
                        >
                          {this.props.translator["Add to Cart"]}
                        </Button>
                      )}
                      {!optionalComponentSelected &&
                        optionalComponentAvailable &&
                        offering.minimumOptionalComponent == "true" ? (
                        <Link className="margin-l-10">
                          <Button
                            bsStyle="primary"
                            className="rgSidrkBtn"
                            id="proCheckout"
                            title={this.props.translator["Proceed"]}
                            disabled={this.state.checkoutButtonDisableStatus}
                            onClick={this.handleClickStartChange}
                            ref={e => this.saveObjRef = e}
                          >
                            {this.props.translator["Proceed"]}
                          </Button>
                        </Link>
                      ) : this.props.requestType === 'OnBehalf' ? (
                        <Link
                          className="margin-l-10"
                          id="proCheckout"
                          to="/cart/orderNowOnBehalf"
                          state={{
                            selectedComponentForOffering: selectedComponentForOffering,
                            offeringsForOrder: this.props.offeringDetails,
                            offeringId: this.props.offeringDetails[0].offeringId.toString(),
                            onBehalfDetails: this.props.onBehalfDetails,
                            smartFormId: this.props.offeringDetails[0].smartFormId,
                            sharedCompanyId: this.getSharedCompanyIds(this.props.offeringDetails[0],envCompanyId)
                            
                          }}
                        >
                          {this.props.offeringDetails[0].status === "Proposed" ? null :
                            <Button bsStyle="primary"
                              className="rgSidrkBtn"
                              id="proCheckout"
                              title={this.props.translator['Proceed']}
                              ref={e => this.saveObjRef = e}
                              disabled={this.state.checkoutButtonDisableStatus}>{this.props.translator['Proceed']}
                            </Button>
                          }
                        </Link>
                      ) :
                        (
                          <Link
                            className="margin-l-10"
                            id="proCheckout"
                            to="/cart/orderNow/"
                            state={{
                              selectedComponentForOffering: selectedComponentForOffering,
                              offeringsForOrder: this.props.offeringDetails,
                              offeringId: this.props.offeringDetails[0].offeringId.toString(),
                              smartFormId: this.props.offeringDetails[0].smartFormId,
                              sharedCompanyId: this.getSharedCompanyIds(this.props.offeringDetails[0],envCompanyId),
                            }}
                          >
                            {this.props.offeringDetails[0].status === "Proposed" ? null :
                              <Button bsStyle="primary"
                                className="rgSidrkBtn"
                                id="proCheckout"
                                title={this.props.translator['Proceed']}
                                ref={e => this.saveObjRef = e}
                                disabled={this.state.checkoutButtonDisableStatus}>{this.props.translator['Proceed']}
                              </Button>
                            }
                          </Link>
                        )}
                    </ButtonGroup>
                  </div>
              }
            </Col>
          </Row>

          <div className="partWithKA">
            <div className={this.state.isShowKgArticles ? "prt1" : "fullprt"}>
              <a href="javascript:void(0)" role="button" onClick={()=>{this.showKgArticles()}} className="float-r f-size-13 margin-t-15 padding-5">{this.state.isShowKgArticles ? <SlArrowRight title="Hide Articles" /> :<SlArrowLeft title="Show Articles" />}</a>
              <div style={{ marginTop: "20px" }} className="offH2">{this.props.translator['Product Description']}</div>
              <div className="offgsBorHad margin-t-10 position-re"><div style={this.state.isShortdesLessMore ? null : (isOfferingDesTxtCount > 200 ? mystyle : null)} className="offTxt" dangerouslySetInnerHTML={{ __html: validate_summary }}></div>{isOfferingDesTxtCount > 200 ? <Button bsPrefix=" " title={this.state.isShortdesLessMore ? this.props.translator["collapse"] : this.props.translator["expand"]} className="lessMoreBtn" onClick={this.shortdesLessMoreFun} bsClass="" >{this.state.isShortdesLessMore ? <span><i className='fa fa-angle-up'></i>{this.props.translator["collapse"]}</span> : <span><i className='fa fa-angle-down'></i>{this.props.translator["expand"]}</span>}</Button> : null}</div>
              <div className="clearboth margin-t-5 blue1Anchor txtLowerCa"><a onClick={() => { this.otherActionbtnFun("Product Details") }} href="#productDetailsTab" role="button">{this.props.translator["see"]} {this.props.translator["Product Details"]}</a></div>


              <div className="offH2">{this.props.translator['Package Contains']}</div>
              <div className="offgsBorHad">
                <Row>
                  <Col lg={3} md={4} sm={6} xs={6}>
                    <div title={this.props.translator["Component"]} className="fw-600 nowraptxt">{this.props.translator['Component']}</div>
                  </Col>
                  <Col lg={9} md={8} sm={6} xs={6}>
                    <div className="fw-600">{this.props.translator['Description']}</div>
                  </Col>
                </Row>
                {this.renderCoreList(
                  this.props.componentPackage,
                  selectedComponentForOffering
                )}
              </div>
              {(_.some(this.props.componentPackage, ['componentType', 'Optional'])) ?
                <div className="offH2">{this.props.translator['Optional Components']}</div>
                : ''}

              {(_.some(this.props.componentPackage, ['componentType', 'Optional'])) ?
                <div className="offgsBorHad">
                  <Row>
                    <Col lg={2} md={1} sm={2} xs={2}><div className="fw-600">{this.props.translator["Add"]}</div></Col>
                    {this.props.offeringDetails[0].hidePrice === "true" ?
                      <Col lg={10} md={11} sm={10} xs={10}>
                        <div className="fw-600">{this.props.translator['Component']}</div>
                      </Col> :
                      <Col lg={5} md={6} sm={5} xs={4}>
                        <div className="fw-600">{this.props.translator['Component']}</div>
                      </Col>
                    }
                    {this.props.offeringDetails[0].hidePrice === "true" ? null :
                      <Col lg={3} md={3} sm={3} xs={3}>
                        <div className="fw-600">{this.props.tr['Billing Frequency']}</div>
                      </Col>
                    }
                    {this.props.offeringDetails[0].hidePrice === "true" ? null :
                      <Col lg={2} md={2} sm={2} xs={3}>
                        <div className="fw-600">{this.props.tr['Price']}</div>
                      </Col>
                    }
                  </Row>
                  {this.renderOptionalList(this.props.componentPackage, selectedComponentForOffering)}
                </div>
                : ''}

              <div className=""><div><a id="productDetailsTab">&nbsp;</a></div><div><a id="serviceReviewTab">&nbsp;</a><a id="sLADetailsTab"></a></div></div>

              <div className="OfferingInlineButtons">
                <ListGroup bsPrefix=" " bsClass="" as="ul">
                  <ListGroup.Item bsPrefix=" " bsClass="" as="li">
                    <Button
                      disabled={this.state.productDetails ? true : false}
                      id="produDetails"
                      title={this.props.translator["Product Details"]}
                      onClick={() => {
                        this.otherActionbtnFun("Product Details");
                      }}
                    >
                      {this.props.translator["Product Details"]}
                    </Button>
                  </ListGroup.Item>
                  {this.props.offeringDetails[0].workItemType == 'standardChange' || this.props.offeringDetails[0].workItemType == 'incidentRequest' || this.props.offeringDetails[0].workItemType == 'serviceRequest' || this.props.offeringDetails[0].hideSla === "true" ? null : (
                    <ListGroup.Item bsPrefix=" " bsClass="" as="li">
                      <Button
                        disabled={this.state.sLADetails ? true : false}
                        id="proSlaDetails"
                        title={this.props.translator["SLA Details"]}
                        onClick={() => {
                          this.otherActionbtnFun("SLA Details");
                        }}
                      >
                        {this.props.translator["SLA Details"]}
                      </Button>
                    </ListGroup.Item>
                  )}
                  {this.props.offeringDetails[0].workItemType == 'standardChange' ? null :
                    <ListGroup.Item bsPrefix=" " bsClass="" as="li">
                      <Button
                        disabled={this.state.serviceReview ? true : false}
                        id="proRatReviews"
                        title={this.props.translator["Ratings & Reviews"]}
                        onClick={() => {
                          this.otherActionbtnFun("Service Review");
                        }}
                      >{this.props.translator["Ratings & Reviews"]}</Button>
                    </ListGroup.Item>}
                </ListGroup>
              </div>

              {this.state.sLADetails ? (
                <div className="closeSpanShadow offgsBorHad position-re">
                  {/* <span
                      onClick={() => {
                        this.setState({ sLADetails: false });
                      }}
                      title={this.props.translator["Close"]}
                      className="closeSpan"
                    >
                      X
                    </span> */}
                  <SLADetails
                    slaDetails={this.props.postAttributeBasedSlaReducer}
                    translatoroffering={this.props.translator}
                    offering={this.props.offeringDetails[0]}
                  />
                </div>
              ) : null}

              {this.state.productDetails ? (
                <div className="closeSpanShadow offgsBorHad position-re">
                  {/* <span
                      onClick={() => {
                        this.setState({ productDetails: false });
                      }}
                      title={this.props.translator["Close"]}
                      className="closeSpan"
                    >
                      X
                    </span> */}
                  <ProductDetails
                    offering={this.props.offeringDetails[0]}
                    translatoroffering={this.props.translator}
                  />
                </div>
              ) : null}

              {this.state.serviceReview ? (
                <div className="closeSpanShadow offgsBorHad position-re">
                  {/* <span
                    onClick={() => {
                      this.setState({ serviceReview: false });
                    }}
                    title={this.props.translator["Close"]}
                    className="closeSpan"
                  >
                    X
                  </span> */}

                  <ServiceReview
                    offeringReview={this.props.ratingCalcReviewFromRE.data}
                    offeringId={this.props.offeringDetails[0].offeringId.toString()}
                    translatoroffering={this.props.translator}
                    rating={this.props.offeringDetails[0].rating}
                  />
                </div>
              ) : null}


              {attachmentReducerData.length > 0 ? (
                <div>
                  <div className="offH2">Download Template</div>
                  <div className="offgsBorHad">
                    {this.renderAttachmentsList(
                      attachmentReducerData
                    )}
                  </div>
                </div>
              ) : null}

            </div>

            {this.state.articleDetails || this.state.isShowKgArticles ? (
              <div className="prt2">
                <KgArticles
                  articleDetails={this.state.articleDetails}
                  offeringId={this.props.offeringDetails[0].offeringId.toString()}
                  renderLargeTextEllipse={this.renderLargeTextEllipse}
                  downloadFile={this.downloadFile}
                  setStateV1={this.setStateV1}
                  submitSearchArticle={this.submitSearchArticle}
                />
              </div>
            ) : null}
          </div>
 {/* <div className="offQueSec">
              <Row>
                <Col lg={4} md={4} sm={6} xs={12}>
                  <div className="havQus">Have a Question?</div>
                  <div>Find answers in product info, Q&As, Reviews</div>
                </Col>
                <Col lg={8} md={8} sm={6} xs={12}>
                  <FormControl type="text" placeholder="Have a Question?" />
                  <Button title="Search"><i className="fa fa-search"></i></Button>
                </Col>
              </Row>
            </div>

            <h2 className="offH2">Customer Reviews</h2>
            <div className="offgsBorHad">
              <Row>
                <Col lg={9} md={7} sm={7} xs={6}><select style={{ width:"160px",height:"initial",padding:"2px 6px"}} className="form-control"><option>TOP REVIEWS</option></select></Col>
                <Col lg={3} md={3} sm={3} xs={6} className="text-r"><Button className="btn-secondary btn-sm" title="Review this Product">Review this Product <i className="fa fa-pencil"></i></Button></Col>
              </Row>
              <Row className="margin-t-10">
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="reviewUser">
                    <div className="userImg" title="Varun Nagpal"><img src={UserImg} /></div>
                    <div className="txtUser">
                      <div className="">Wow a lot of one sided negative reviews from disgruntled freshers up-voted many times by fellow freshers so I had to tell you from my perspective as a senior 4.2 level employee of HCL Tech.</div>
                      <div className="revStardv">June 12, 2020 <div className="proStar margin-l-20 display-inline-block"><ReactStars count={5} size={28} color1={'#ffd700'} color2={'#ffd700'} /></div></div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="margin-t-10">
                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className="reviewUser">
                    <div className="userImg" title="Amit Singh"><img src={UserAmit} /></div>
                    <div className="txtUser">
                      <div className="">Every organisation has its own pros and cons and so did HCl got. It certainly has awesome brand value, work atmosphere, no pressure ( it is referred as government company by few, reason being no work load), they have good number of projects good average and awesome as well. </div>
                      <div className="revStardv">June 12, 2020 <div className="proStar margin-l-20 display-inline-block"><ReactStars count={5} size={28} color1={'#ffd700'} color2={'#ffd700'} /></div></div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div> */}
          



        </div>
      );


    });
  }

  getSharedCompanyIds(offeringDetailsData, companyId) {
    let companyIds;
    if (offeringDetailsData.isShared == "Yes") {
      if (offeringDetailsData.company == companyId) {
        companyIds = companyId + "," + offeringDetailsData.sharedCompanyId.toString();
      }
      if (offeringDetailsData.company != companyId) {
        companyIds = companyId;
      }
    }
    else {
      companyIds = offeringDetailsData.sharedCompanyId.toString();
    }

    return companyIds;
  }

  render() {
    var attachmentReducerData;
    if (this.props.paginationAttachmentsReducer.length > 0) {
      attachmentReducerData = this.props.paginationAttachmentsReducer;
    }
    else {
      attachmentReducerData = [];
    }
    const {
      offeringDetails,
      selectedComponentForOffering,
      translator,
    } = this.props;

    const optionalComponentSelected = _.some(selectedComponentForOffering, [
      "componentType",
      "Optional",
    ]);
    const optionalComponentAvailable = _.some(this.props.componentPackage, [
      "componentType",
      "Optional",
    ]);

    // const minimumOptionalComponent = this.props.offeringDetails[0]
    //   .minimumOptionalComponent;

    if (offeringDetails.errorCode) {
      return (
        <h2>
          {offeringDetails.errorMessage + " " + offeringDetails.errorCode}
        </h2>
      );
    }
    if (offeringDetails instanceof Error) {
      return (
        <h2>
          {
            this.props.translator[
            "Network Error Occured...offering details will be here soon...."
            ]
          }
        </h2>
      );
    }
    if (offeringDetails.length === 0) {
      return null;
    }
    let offeringidlistcompare = "";
    let favouriteexist = offeringDetails[0].isFavourite;

    let compare, favorite;

    let item_attr_to_get_value = "offeringidlistfrcompare";
    let namedSchema = Joi.string().regex(/^[^!@#$%^&*}{_+=|\:;\'\",<.>?~-]+$/);
    let validator_to_add_myQbj = namedSchema.validate(item_attr_to_get_value);
    if (validator_to_add_myQbj.error == null) {
      if (localStorage.getItem(item_attr_to_get_value) != null) {
        let intermediate_value = localStorage
          .getItem(item_attr_to_get_value)
          .split(",").length;
        namedSchema = Joi.string().regex(/^[^!@#$%^&*}{_+=|\:;\'\",<.>?~-]+$/);
        validator_to_add_myQbj = namedSchema.validate(intermediate_value);
        if (intermediate_value.error == null) {
          offeringidlistcompare = intermediate_value;
        } else {
          offeringidlistcompare = "";
        }
      }
    } else {
      offeringidlistcompare = "";
    }

    // if (localStorage.getItem("offeringidlistfrcompare") != null) {
    //   offeringidlistcompare = localStorage.getItem("offeringidlistfrcompare");
    // }

    let offeringidcompareexist = offeringidlistcompare.indexOf(
      offeringDetails[0].offeringId
    );
    if (offeringidcompareexist < 0) {
      if (this.state.compareval === "0" || this.state.compareval == "")
        compare = (
          <a
            id="anchortitle"
            onClick={this.onCompareClick.bind(this, offeringDetails[0])}
            className="spCommon compareIcon"
            title={translator["Add to Compare"]}
          />
        );
      else
        compare = (
          <a
            id="anchortitle"
            onClick={this.onRemoveCompareClick.bind(this, offeringDetails[0])}
            className="spCommon compareIcon"
            title={this.props.translator["Remove from Compare"]}
          />
        );
    } else {
      if (this.state.compareval === "0")
        compare = (
          <a
            id="anchortitle"
            onClick={this.onCompareClick.bind(this, offeringDetails[0])}
            className="spCommon compareIcon"
            title={translator["Add to Compare"]}
          />
        );
      else
        compare = (
          <a
            id="anchortitle"
            onClick={this.onRemoveCompareClick.bind(this, offeringDetails[0])}
            className="spCommon compareIcon"
            title={translator["Remove from Compare"]}
          />
        );
    }
    if (favouriteexist === 0) {
      if (this.state.favoriteval === "0" || this.state.favoriteval == "")
        favorite = (
          <a
            href="javascript:void(0)"
            role="button"
            id="proFavtes"
            onClick={this.onAddFavourite.bind(this, offeringDetails[0])}
            title={this.props.tr["Add to Favorites"]}><LiaHeartSolid /></a>
        );
      else {
        favorite = (
          <a
            href="javascript:void(0)"
            role="button"
            id="proFavtes"
            className="active"
            onClick={this.onRemoveFavourite.bind(this, offeringDetails[0])}
            title={this.props.tr["Remove from Favorites"]}><LiaHeartSolid /></a>
        );
      }
    } else {
      if (this.state.favoriteval === "0")
        favorite = (
          <a
            href="javascript:void(0)"
            id="proFavtes"
            role="button"
            onClick={this.onAddFavourite.bind(this, offeringDetails[0])}
            title={this.props.tr["Add to Favorites"]}><LiaHeartSolid /></a>
        );
      else {
        favorite = (
          <a
            href="javascript:void(0)"
            role="button"
            id="proFavtes"
            className="active"
            onClick={this.onRemoveFavourite.bind(this, offeringDetails[0])}
            title={this.props.tr["Remove from Favorites"]}><LiaHeartSolid /></a>
        );
      }
    }
    return (
      <div id="OfferingDetailId">
        {this.props.serviceReviewShowLoader ?
          <ListLoader /> :
          this.renderOfferingDetails(
            offeringDetails,
            selectedComponentForOffering,
            favorite,
            optionalComponentSelected,
            optionalComponentAvailable,
            attachmentReducerData
          )}

        <div id="askToCommunity">
          {this.state.askToCommunity ? (
            <div className="margin-t-30">
              {/* <AskToCommunity
                translatoroffering={translator}
                offeringId={offeringDetails[0].offeringId}
                offeringName={offeringDetails[0].offeringName}
              /> */}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  offeringDetails,
  componentPackage,
  ratingCalcReviewFromRE,
  postAttributeBasedSlaReducer,
  spcmReducer, serviceReviewShowLoader,
  windowSize,
  attachedKnowledgeArticlesList,
  appliedThemeData
}) => {
  return {
    windowSize,
    offeringDetails,
    componentPackage,
    ratingCalcReviewFromRE,
    postAttributeBasedSlaReducer,
    tr: spcmReducer.tr,
    serviceReviewShowLoader: serviceReviewShowLoader.loading,
    paginationAttachmentsReducer: spcmReducer.attachmentsReducer,
    attachedKnowledgeArticlesList,
    appliedThemeData: appliedThemeData.data
  };
};
export default connect(mapStateToProps, {
  fetchOfferingDetails,
  loadComponentPackage,
  loadRatingCalcOffferingFromRE,
  loadOfferingSlaDetails,
  loadOfferingAavailability,
  loadOfferingDeliveryType,
  getAttachmentsPaginationAction,
  getAttachmentsAction,
  loadCartCount,
  postAttributeBasedSlaAction,
  loadDiscourseArticlesForSearch,
  loadDiscourseArticlesForSearchByTopicId
})(OfferingDetails);
