/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
export const LeftRightSectionComponent = {
  name: "LeftRightSection",
  traits: [
    {
      type: "text",
      label: "Title",
      name: "iconTitle",
      placeholder: "Title",
      changeProp: 1,
    },
  ],
};

export const LeftRightSectionAdd = {
  model: {
    defaults: {
      tagName: "div",
      draggable: true,
      attributes: { class: "row" },
      components: `<div class="col-md-8" data-gjs-type="left-section"><span>Left Section</span></div><div class="col-md-4" data-gjs-type="right-section"><span>Right Section</span></div>`,
    //   components: [
    //     {
    //       tagName: "div",
    //       attributes: { class: "col-md-8","data-gjs-type":"left-section" },
    //       components: [{ tagName: "span", content: "Lect Section" }],
    //       draggable: true,
    //       droppable: true,
    //       editable: true,
    //     },
    //     {
    //       tagName: "div",
    //       attributes: { class: "col-md-4","data-gjs-type":"right-section" },
    //       components: [{ tagName: "span", content: "Right Section" }],
    //       draggable: true,
    //       droppable: true,
    //       editable: true,
    //     },
    //   ],
      traits: LeftRightSectionComponent.traits,
      editable: true,
    },
    init() {},
  },
};

export const addLeftRightSectionComponent = (e) => {
  e.DomComponents.addType(LeftRightSectionComponent.name, LeftRightSectionAdd);
};

export const LeftRightSectionBlockJson = {
  id: "LeftRightSection",
  label: "Left Right Section",
  media: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve" fill="#808080"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g id="move_x5F_horizontal"> <g> <polygon style="fill:#808080;" points="12,18 6,18 6,22 0,16 6,10 6,14 12,14 "></polygon> <polygon style="fill:#808080;" points="20,14 26,14 26,10 32,16 26,22 26,18 20,18 "></polygon> </g> </g> </g> </g></svg>`,
  category: "Extra",
  content: {
    type: LeftRightSectionComponent.name,
  },
};
