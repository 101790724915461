/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Nav,
  Button,
  ButtonToolbar,
} from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { connect, useDispatch, useSelector } from "react-redux";
import { IoSearch, IoClose } from "react-icons/io5";
import { TbArrowBackUp } from "react-icons/tb";
import AutoSuggest from "react-autosuggest";
import Swal from "sweetalert2";

import { GLOBAL } from "_Globals";
import { Get } from "../../../../utils/axiosUtils";

import {
  loadAllScheduleList,
  emptyScheduleLists,
  loadScheduleAssociationDataByScheduleId,
  deleteScheduleAssociationData,
} from "../../../../actions/onCallSchedule/schedule/onCallScheduleAction";
import {
  loadCIEditDetails,
  loadModelName,  
} from "../../../../actions/cmdb/lovAction.js";
import { getTimelineData } from "../../../../actions/cmdb/cmdbTimelineAction.js";
import BasicTable from "../../../common/ReactTable/BasicTable";
import { useFetchData } from "../../add-edit/formfield/hooks";

const cookies = new Cookies();
let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];

let cookiesData = cookies.get("gph");
if (cookiesData) cookiesData = cookiesData.replace("s:", "");
if (cookiesData)
  cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf("."));
cookiesData = cookiesData.split("~");
let dateformat = cookiesData[2];
let onRoleVisible = cookiesData[33] ? cookiesData[33].split(",") : [];
let assignedGrpId = cookiesData[58] ? cookiesData[58].split(",") : [];

let onContractRoleVisible = cookiesData[33] ? cookiesData[33].split(",") : [];
onContractRoleVisible = onContractRoleVisible.includes("68");

let onConfigManagerRoleVisible = cookiesData[33]
  ? cookiesData[33].split(",")
  : [];
onConfigManagerRoleVisible = onConfigManagerRoleVisible.includes("37");

let onAssetRoleVisible = cookiesData[33] ? cookiesData[33].split(",") : [];
onAssetRoleVisible = onAssetRoleVisible.includes("38");

export default function ScheduleMaintenance(props) {
  const dispatch = useDispatch();
  const { ciDetails, ciId, formikRef } = props;
  const translator = useSelector((state) => state.spcmReducer.tr);
  const allScheduleList = useSelector(
    (state) => state.on_call_schedule_reducer.allScheduleList
  );
  const scheduleDetailsByScheduleId = useSelector(
    (state) =>
      state.on_call_schedule_association_reducer.scheduleAssociationList
  );

    const [pageNum, setPageNum] = useState(1);
    const [pgSize, setPgSize] = useState(10);
    const [totalRows, setTotalRows] = useState(0);

  const { data: attachedCiData, loading, error } = useFetchData(
     GLOBAL.maintanenceWindowGet,
    { page: pageNum, limit: pgSize },
    { ci_id: ciId },
    [pageNum, pgSize, ciId] 
  );

  const [isScheduleAssociated, setIsScheduleAssociated] = useState(false);
  const [isList, setIsList] = useState(true);
  const [scheduleType, setScheduleType] = useState(
    "Maintenance Schedule"
  );
  const [scheduleName, setScheduleName] = useState("");

  const handlePageChange = (page, perPage) => {
    setPageNum(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPgSize(newPerPage);
    setPageNum(page);
  };

   const tableColumns = [
    {
      name: "Name",
     // selector: (row) => row?.maintenance_window_name || "",
     cell: (row) => (
      <div className="truncated-cell" title={row?.schedule_name || ""}>
          {row.schedule_name || ""}
      </div>
  ),
      sortable: true,
    },
    {
      name: "Type",
      //selector: (row) => row?.maintenance_window_type || "",
      cell: (row) => (
        <div className="truncated-cell" title={row?.schedule_type || ""}>
            {row.schedule_type || ""}
        </div>
        ),
      sortable: true,
    },
    {
      name: "Time Zone",
      selector: (row) => row?.schedule_timezone || "",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.schedule_status || "",
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <i
          className="fa fa-trash-o cursorPoint"
          onClick={() => onDeleteClick(row?.id)}
          style={{ color: "red", cursor: "pointer" }}
          title="Delete"
        ></i>
      ),
      ignoreRowClick: true, 
      allowOverflow: true,
      button: true,
    },
  ];

  const tableColumnSearch = [
    {
      name: "Name",
      selector: (row) => row?.scheduleName || "",
      sortable: true,
   
    },
    {
      name: "Type",
      selector: (row) => row?.scheduleType || "",
      sortable: true,
  
    },
    {
      name: "Time Zone",
      selector: (row) => row?.timeZone || "",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.status || "",
      sortable: true,
  
    },
    {
      name: "Actions",
    
      cell: (row) => (
        <i
          className="fa fa-chevron-circle-right f-size-16 theme1"
          onClick={() => {
            checkScheduleAssociation(row, row?.scheduleId);
          }}
          style={{ cursor: "pointer" }}
          title="Relate"
        ></i>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const toggleFormAndList = () => {    
    dispatch(emptyScheduleLists());
    setIsList(!isList);
    setScheduleType("Maintenance Schedule");
  };

  const changeModule = (e) => {};

  const onDeleteClick = async (saId) => {
    try {
      if (!attachedCiData.data) return;
  
      // Show confirmation dialog before deleting
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to delete this maintenance schedule?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it',
        cancelButtonText: 'No, keep it'
      });
  
      if (!result.isConfirmed) {
        return;
      }

      const updatedMaintenanceWindow = attachedCiData.data
      .filter(item => String(item.id) === String(saId))
      .map(item => ({
        ...item,
        status: 'Inactive'
      }));
  
      const response = await axios.patch(GLOBAL.maintanenceWindowPatch(saId), updatedMaintenanceWindow[0]);
   
      if (response?.status === 200) {
        setPageNum(2);
        await Swal.fire({
          icon: 'success',
          text: "The maintenance schedule has been deleted.",
          confirmButtonText: "OK",
        });
      
        setPageNum(1); 

      }       
      
    } catch (error) {
      console.error('Error deleting maintenance window:', error);
      
      // Show error message
      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.message || 'Failed to delete maintenance schedule',
        confirmButtonText: 'OK'
      });
    }
  };

  const searchData = (e) => {
    let filter = {
      scheduleType: scheduleType.trim(),
    };
    if (scheduleName.trim()) {
      filter.scheduleName = scheduleName.trim();
    }

    dispatch(loadAllScheduleList(filter));
  };

  const checkScheduleAssociation = (obj, scheduleId) => {
    if (!scheduleId) {
      console.error("Schedule ID is missing.");
      return false;
    }

    Get(
      `${GLOBAL?.loadScheduleAssociationDataUrl}?scheduleId=${scheduleId}&sort=createdOn,desc`
    )
      .then((response) => {
        const scheduleData = response?.data?.data?.content;
        if (!Array.isArray(scheduleData) || scheduleData.length === 0) {
          Swal.fire({
            text: "Schedule details are unavailable for this maintenance window.",
            confirmButtonText: "OK",
          });

          return false;
        }

        postRelatedData(obj,scheduleData); 
      })
      .catch((error) => {
        console.error("Error fetching schedule association:", error.message);
        Swal.fire({
          text: `Failed to check schedule association. Please try again. Error: ${error.message}`,
          confirmButtonText: "OK",
        });
      });
  };


  const postRelatedData = async (obj, scheduleData) => {
    try {
      // Early validation
      if (!obj || !scheduleData || !formikRef.current) {
        console.error('Missing required data');
        return;
      }
  
      // Ensure attachedCiData.data is always an array
      const currentData = Array.isArray(attachedCiData?.data) ? attachedCiData.data : [];
  
      // More robust duplicate check
      const isDuplicate = currentData.some(item => 
        Number(item.schedule_id) === Number(obj?.scheduleId) ||
        (item.schedule_name === obj.scheduleName && 
         item.schedule_type === obj.scheduleType)
      );
  
      if (isDuplicate) {
        await Swal.fire({
          text: "Maintenance schedule already attached.",
          confirmButtonText: "OK",
        });
        return;
      }
  
      const newScheduleEntry = {
        ci_id: ciDetails?.CI_ID,
        group_id: ciDetails?.GROUP_ID,
        schedule_id: obj?.scheduleId,
        schedule_name: obj?.scheduleName,
        schedule_type: obj?.scheduleType,
        schedule_timezone: obj?.timeZone,
        schedule_status: obj?.status,
        schedule_start_date: scheduleData?.[0]?.scheduleStartDate,
        schedule_end_date: scheduleData?.[0]?.scheduleEndDate,
        schedule_repeats: scheduleData?.[0]?.scheduleRepeatType,
      };
  
      const requiredFields = ['schedule_id', 'schedule_name', 'schedule_type'];
      const missingFields = requiredFields.filter(field => !newScheduleEntry[field]);
      
      if (missingFields.length > 0) {
        await Swal.fire({
          text: `Missing required fields: ${missingFields.join(', ')}`,
          confirmButtonText: "OK",
        });
        return;
      }
  
      // Update maintenance window with new entry
      const updatedMaintenanceWindow = [...currentData, newScheduleEntry];
  
      // Post the data
      const response = await axios.post(GLOBAL.maintanenceWindow, updatedMaintenanceWindow);
      const responseData = response.data[0];
  
      if (responseData?.upsert_status === false || responseData?.upsert_status === "false") {
        await Swal.fire(responseData.message);
      } else {
        await Swal.fire({
          text: "Schedule attached successfully",
          confirmButtonText: "OK",
        });
        
        // Update the UI
        dispatch(loadCIEditDetails(responseData.CI_ID));
        dispatch(loadModelName(responseData.CI_ID));
        dispatch(getTimelineData(responseData.CI_ID));
        
        // Toggle back to list view
        setIsList(true);      
      
        setPageNum(prevPage => {       
          if (prevPage === 1) {
            setTimeout(() => setPageNum(1), 0);
            return 2;
          }         
          return 1;
        });
      }
    } catch (error) {
      console.error('Error posting maintenance window:', error);
      await Swal.fire({
        text: error?.response?.data?.[0]?.message || "An error occurred while attaching the schedule",
        confirmButtonText: "OK",
      });
    }
  };



  useEffect(() => {
    if (attachedCiData && attachedCiData.meta) {     
      setTotalRows(attachedCiData.meta.rowCount);      
    }
  }, [attachedCiData]);

  return (
    <div className="clDiv Ovrflow StTimeline">
      <Row>
        <Col>
          <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
            <ul>
              <li>
                {onAssetRoleVisible || onConfigManagerRoleVisible ? (
                  <Button
                    bsPrefix=" "
                    onClick={toggleFormAndList}
                    bsClass=""
                    bsStyle=""
                  >
                    <i
                      className={
                        isList ? "fa fa-plus f-size-16" : "fa fa-list f-size-16"
                      }
                    ></i>
                  </Button>
                ) : null}
              </li>
              <li className="d-md-none">
                <button
                  type="button"
                  title="Minimize the right panel"
                  bsClass=""
                  bsStyle=""
                  className="closerightPanelBtn"
                  onClick={() => {
                    props.rightEditPanel(false);
                  }}
                >
                  <IoClose />
                </button>
              </li>
            </ul>
          </div>
          <div
            className={props?.treeview ? "rPageHeading d-flex" : "rPageHeading"}
          >
            {/* <div className="rPageHeading"> */}
            <div className="offNam margin-t-5 margin-b-5">
              Maintenance Schedule
            </div>
            {props?.treeview && (
              <div className="toprigLnk mt-0 ms-auto">
                <Nav className="icnlnk" as="ul">
                  <Nav.Item as="li">
                    <a className="border-none" onClick={props?.handleShowhide}>
                      <i className="fa fa-close text-white" />
                    </a>
                  </Nav.Item>
                </Nav>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        {isList ? (
          <Row>
            <Col md={12}>
              <BasicTable
                persistTableHead
                paginationServer
                totalRows={totalRows}
                tableColumns={tableColumns}
                data={
                  attachedCiData?.data &&
                  attachedCiData?.data?.length > 0
                    ? attachedCiData?.data
                    : []
                }
                loading={loading}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handlePerRowsChange}
              />
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>{translator["Type"]}</Form.Label>
                  <Form.Select
                    componentClass="select"
                    onChange={(e) => setScheduleType(e.target.value)}
                  >                  
                    <option value="Maintenance Schedule">
                      Maintenance Schedule
                    </option>
                    <option value="Change Freeze Schedule">
                      Change Freeze Schedule
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="form-group cmdbdvTypehd">
                  <Form.Label>{translator["Name"]}</Form.Label>
                  <div className="dvTypehd">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here"
                      value={scheduleName}
                      onChange={(e) => setScheduleName(e.target.value)}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="margin-t-15">
              <Col md={12}>
                <ButtonToolbar className="black">
                  <Button
                    type="button"
                    bsSize="small"
                    bsStyle="primary"
                    className="rgSiLigBtn smallBtn"
                    onClick={() => setIsList(!isList)}
                  >
                    {translator["Back to List"]}
                  </Button>
                  <Button
                    bsSize="small"
                    bsStyle="text"
                    className="rgSidrkBtn smallBtn"
                    onClick={searchData}
                  >
                    <IoSearch />
                    {translator["Search"]}
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
            <div>
              {allScheduleList?.length > 0 ? (
                <Row className="margin-t-15">
                  <Col md={12}>
                    <h5>{translator["Search Results"]}</h5>
                    <BasicTable
                      persistTableHead
                      tableColumns={tableColumnSearch}
                      data={allScheduleList || []}
                      pagination={true}
                    />
                  </Col>
                </Row>
              ) : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
