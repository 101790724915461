import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, useFormikContext } from 'formik';
import { Button, ButtonToolbar, ListGroup, NavDropdown, Table } from "react-bootstrap";
import axios from 'axios';
import Dropzone, { useDropzone } from 'react-dropzone';
import { IoClose, IoCopyOutline, IoLanguage, IoSearch } from "react-icons/io5";
import AiIcon from '../../../common/aiIcon';
import { TbDownload, TbUpload } from 'react-icons/tb';
import { CiExport, CiImport } from 'react-icons/ci';
import { ImSpinner6 } from 'react-icons/im';
import Swal from 'sweetalert2';
const LanguageEditor = ({ formikRef, trData, formId, targetlanguage, companyId }) => {

  const tr = useSelector(state => state.spcmReducer.tr)
  const initialValues = { ...trData };
  const attachmentSize = 120;
  const [aiDropzone, setAiDropzone] = useState(false);
  const [expBtnDisable, setExpBtnDisable] = useState(false);
  const [filesPreview, setFilesPreview] = useState([]);
  const [impBtnDisable, setImpBtnDisable] = useState(false);
  const [filesToBeSent, setFilesToBeSent] = useState([]);
  const [printcount,setPrintcount] = useState(1);
  const [trjson,setTrJson]=useState({});
  const onSubmit = (values, actions) => {
    actions.setSubmitting(false);
    axios.request({
      method: 'post',
      url: `/api/upsert/labels/${formId}`,
      data: values,
      headers: {
        query: JSON.stringify({
          companyId: companyId,
          SL: 'en',
          TL: targetlanguage
        })
      }
    }).then((response) => {
     
      Swal.fire({
        text: "Form labels updated successfully.",
        button: "OK",
        width: 400,
      })
     
    }).catch((error) => {
     
      console.log(error?.message)
    })
  };


  const removeFile = ()=> {
    setFilesToBeSent([])
    setFilesPreview([])
  }

  return (
    <div>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ isSubmitting, handleSubmit, setFieldValue, values }) => {
          const translateAll = () => {
            let body = {
              ai_tool: "TRANSLATOR",
              message: `sourceLanguage: en, targetLanguage: ${targetlanguage}, text:${Object.keys(initialValues).toString()}`,
            };

            axios
              .post("/api/aiTools", body)
              .then((response) => {
                const data = response.data;
                let trValue = data?.response.replace(/<pre>/, "").replace(/<\/pre>/, "") || data?.response;
          
                let trValueArray = trValue.split(",")
              
                Object.keys(initialValues).forEach((key, index) => {
                  setFieldValue(key, trValueArray[index]); // Update the field value
                })

              })
              .catch((error) => {
                console.log(error?.message);
              });
          }

          const handleDownload = () => {
            // Your JSON data
            setExpBtnDisable(true);

            // Convert JSON to string
            const jsonString = JSON.stringify(values, null, 2);

            // Create Blob
            const blob = new Blob([jsonString], { type: "application/json" });

            // Create download link
            const url = URL.createObjectURL(blob);

            // Create temporary anchor element
            const link = document.createElement('a');
            link.href = url;
            link.download = `${formId}_${targetlanguage}.json`;

            // Append to body, click, and remove
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setExpBtnDisable(false);
            // Clean up the URL
            URL.revokeObjectURL(url);
          };
          // const handleImportClick=()=>{
          //   console.log("TR JSON", trjson);
          //   if(trjson){
          //     Object.keys(trjson).forEach((key) => {
          //       setFieldValue(key, trjson[key]);
          //     });

          //   }
       

          // }

        const handleImportClick=()=>{
            
          setImpBtnDisable(true);
            const file = filesToBeSent[0][0];
            if (file) {
              const reader = new FileReader();
              reader.onload = (e) => {
                try {
                  const jsonData = JSON.parse(e.target.result);
                  Object.keys(jsonData).forEach((key) => {
                    setFieldValue(key, jsonData[key]);
                  });
                  // Update the form values with the imported JSON data
                  setTrJson(jsonData);
                  setImpBtnDisable(false);
                } catch (error) {
                  console.error("Invalid JSON file:", error);
                  Swal.fire({
                    text: "Invalid JSON file. Please upload a valid JSON.",
                    button: "OK",
                    width: 400,
                  })
                  
                }
              };
              reader.readAsText(file);
            }
       

          }
          // const onDrop = (acceptedFiles) => {
          //   setImpBtnDisable(true);
          //   const file = acceptedFiles[0];
          //   if (file) {
          //     const reader = new FileReader();
          //     reader.onload = (e) => {
          //       try {
          //         const jsonData = JSON.parse(e.target.result);
          //         console.log("Imported JSON Data:", jsonData);
          //         // Update the form values with the imported JSON data
          //         setTrJson(jsonData);
          //         setImpBtnDisable(false);
          //       } catch (error) {
          //         console.error("Invalid JSON file:", error);
          //         Swal.fire({
          //           text: "Invalid JSON file. Please upload a valid JSON.",
          //           button: "OK",
          //           width: 400,
          //         })
                  
          //       }
          //     };
          //     reader.readAsText(file);
          //   }
          // }

          const onDrop=(acceptedFiles,rejectedFiles)=>{
               if (acceptedFiles.length > 0) {
                
                  if (filesToBeSent.length < printcount) {
                    let toBeSentFiles = [];
                    toBeSentFiles.push(acceptedFiles);
                    let previewFiles = [];
                    for (let i in toBeSentFiles) {
                      if (toBeSentFiles.hasOwnProperty(i)) {
                        previewFiles.push(
                        <ListGroup.Item bsPrefix=" ">
                          <span className="float-r" style={{ cursor: 'pointer' }}
                              onClick={() => {
                                removeFile();
                              }}
                            ><IoClose />
                          </span>
                          {toBeSentFiles[i][0].name}
                        </ListGroup.Item>
                      );
                    }
                  }
                    setFilesPreview(previewFiles);
                    setFilesToBeSent(toBeSentFiles);
                  } else {
                    Swal.fire({
                      text: "File already attached.",
                      button: "OK",
                      width: 400,
                    });
                  }
                } else {
                }
          }
          const onDropRejected = (rejectedFiles) => {
            if (rejectedFiles && rejectedFiles[0] && rejectedFiles[0].file && rejectedFiles[0].file.size && rejectedFiles[0].file.size > 1048576 * attachmentSize) {
              Swal.fire({
                text: "Please upload File up to " + attachmentSize + " MB size.",
                button: "OK",
                width: 400,
              });
            } else {
              Swal.fire({
                text: "File not supported.",
                button: "OK",
                width: 400,
              });
            }
          }
          return (
            <form onSubmit={handleSubmit}>
                        <div className="paHedFilter">
                        <Button
                  type="button"
                  onClick={handleDownload}
                  bsPrefix=" "
                  id="addTheme"
                  className="myBt plus    -primary"
                  disabled={expBtnDisable}
                  title={"Export Form labels"}
                >
                  <span>
                    {expBtnDisable ? (
                      <ImSpinner6 className="icn-spinner" />
                    ) : (
                      <CiExport />
                    )}
                  </span>
                </Button>
                <NavDropdown
                  className="myBt noArrow mybtImprt"
                  bsPrefix=" "
                  as="div"
                  title={
                    <CiImport title={"Import"} style={{ strokeWidth: "1.2px" }} />
                  }
                >
                  <Dropzone
                    onDrop={(files) => onDrop(files)}
                    onDropRejected={(files) => onDropRejected(files)}
                    maxSize={1048576 * attachmentSize}
                    accept={{ "application/json": [".json"] }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzoneAreacss">
                        <div
                          className="form-control"
                          {...getRootProps()}
                          title={tr["Drop files or click here to upload."]}
                        >
                          <input {...getInputProps()} />
                          {tr["Drop files or click here to upload."]}
                        </div>

                        {filesPreview.length > 0 && (
                          <ListGroup bsPrefix=" " className="attachment-break">
                            {filesPreview}
                          </ListGroup>
                        )}

                        <div className="dropzoneAreacssSiz">
                          <span>Supported format: JSON</span>
                          {/* <span className="chld2">{"Max size"}: 2MB</span> */}
                        </div>
                        <div className="margin-t-5">
                          <button
                            type="button"
                            bsPrefix=" "
                            title="Import Forms"
                            onClick={() => { handleImportClick() }}
                            disabled={impBtnDisable}
                          >
                            <span>
                              {impBtnDisable ? (
                                <ImSpinner6 className="icn-spinner" />
                              ) : (
                                "Import"
                              )}
                            </span>
                          </button>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </NavDropdown>

                <Button
                  type='button'
                  title="AI Translate"
                  id="addTheme"
                  className="myBt plus    -primary"
                  style={{ backgroundColor: "var(--aiButtonBG) !important" }}
                  onClick={translateAll}
                >
                  <AiIcon />
                </Button>

              </div>
              <div>

              </div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Labels</th>
                    <th>Values</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(initialValues).map((key, index) => (
                    <tr key={index}>
                      <td>
                        <label>{key}</label>
                      </td>
                      <td>
                        <Field
                          className="form-control"
                          type="text"
                          name={key}
                          placeholder={initialValues[key]}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default LanguageEditor;
