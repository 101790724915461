
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Nav from 'react-bootstrap/Nav';
import ListGroup from 'react-bootstrap/ListGroup';
import OnClickOutside from 'react-onclickoutside';
import {connect} from 'react-redux';
import axios from 'axios';
import {GLOBAL} from '_Globals';
import swal from 'sweetalert';
import { bindActionCreators } from 'redux';

import {checkRelatedTaskForItem} from '_Actions/task/TaskDataAction';
import {loadRelatedBreakFixDataToProblem} from '_Actions/investigation/invRelatedDataAction';
import {changeFieldStatusData} from '_Actions/investigation/investigationAction.js';
import { loadStatusBasedHamburgerOptions } from '_Actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '_Actions/common/commonApisActions.js';
import {getInvestigationTeamList} from "_Actions/investigation/investigationAction";
import { problemStatusMapping } from '../../../common/helper';

class StatusHamburger extends React.Component {

	constructor(props) {
		super(props);
		this.state={
			rootcauseIdentified: false
		};
		if(this.props.investigationItemDetails.status_id == problemStatusMapping["Under Corrective Action"]){
			this.state.iscompletedchangeAttached=false;
		}
		this.currentAction = this.currentAction.bind(this);
		this.renderHamburgerSOptions = this.renderHamburgerSOptions.bind(this);
		this.closeProblem = this.closeProblem.bind(this);
		this.closeProblemTicket = this.closeProblemTicket.bind(this);
	}
	closeProblem(){
                if(this.props.investigationItemDetails.status_id == problemStatusMapping["Under Corrective Action"] && (this.props.user_id!==this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17'))))
				{
					swal({
						text: this.props.translator['Please assign the ticket to yourself before changing the status of the ticket'],
						button: this.props.translator['OK'],
					  });
				}
				if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident'))
				swal({
					text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
					button: this.props.translator['OK'],
				});
				else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId)
				{
				  swal({
					  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
					  button: this.props.translator['OK'],
					});
				}
                else if(this.props.realatedTaskCount == 0)
				//    if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') ))
				//       swal({
				//         text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
				//         button: this.props.translator['OK'],
				//       });
			    //    else
					 this.props.changeActionView('close');
				else
				    swal({
						text: 'Tasks related to the item are not completed yet',
						button: this.props.translator['OK']
					});
	}

	componentWillMount() {
		if(this.props.investigationItemDetails.status_id == problemStatusMapping['Under Investigation']){
		   this.props.getInvestigationTeamList(this.props.investigationItemDetails.investigationId);
           axios.get('/serviceManagement/rest/rcaTechnique/'+this.props.investigationItemDetails.investigationId).then((responseRootCause)=>{
                   this.setState({rootcauseIdentified: responseRootCause.data});
		   }).catch((error)=>{
              // console.log("failed to call Root Cause API check. ",error);
		   });
		}
		this.props.checkRelatedTaskForItem(this.props.investigationItemDetails.investigationId,"Investigation");
		this.props.loadRelatedBreakFixDataToProblem(this.props.investigationItemDetails.investigationId,"breakfix","Investigation");
		if(this.props.investigationItemDetails.status_id == problemStatusMapping["Under Corrective Action"]){
			axios.get("/serviceManagement/rest/relationsActionCheck/"+this.props.investigationItemDetails.investigationId).then((proposedChange)=>{
                  this.setState({iscompletedchangeAttached: proposedChange.data});
			});
		}
	}

	currentAction(enableButton) {
		console.log("Specific Vendor Tickets",enableButton);
		if(enableButton=="Close Problem"&&this.props.investigationItemDetails.status_id == problemStatusMapping["Under Corrective Action"])
		{
			enableButton='Closed';
		}
		switch(enableButton) {
			case 'Provide Attachments':
				if(this.props.investigationItemDetails.status_id == problemStatusMapping['Under Corrective Action']) {
					// if(this.props.realatedTaskCount == 0)
						this.props.changeActionView('attachment');
					// else
					// 	alert('Tasks related to the item are not completed yet');
				}
				else
					this.props.changeActionView('attachment');
				break;
			case 'Notify':
				this.props.changeActionView('notify');
				break;
			case 'Qualify as Problem':
				if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident'))
				swal({
					text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
					button: this.props.translator['OK'],
				  });
                else if((this.props.investigationItemDetails.status_id == problemStatusMapping['Under Review']) && (this.props.investigationItemDetails.asgPmGroupid==null||this.props.investigationItemDetails.asgPmGroupid===''||this.props.investigationItemDetails.asgPmGroupid==='0'||this.props.investigationItemDetails.asgPmGroupid===0))
				swal({
					text: this.props.translator["Please select problem management group"],
					button: this.props.translator['OK'],
				  })
				 
				else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') ) && (this.props.investigationItemDetails.status_id != problemStatusMapping['Under Review']))
				   swal({
					  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
					  button: this.props.translator['OK'],
				    });
					else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId)
					{
					  swal({
						  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
						  button: this.props.translator['OK'],
						});
					}
				else
				  this.props.changeActionView('qualifyAsProblem');
				break;
			case 'Disqualify as Problem':
				if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident'))
				swal({
					text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],					
					button: this.props.translator['OK'],
				  });
                else
				if((this.props.investigationItemDetails.status_id == problemStatusMapping['Under Review']) && (this.props.investigationItemDetails.asgPmGroupid==null||this.props.investigationItemDetails.asgPmGroupid===''||this.props.investigationItemDetails.asgPmGroupid==='0'||this.props.investigationItemDetails.asgPmGroupid===0))
				swal({
					text: this.props.translator["Please select problem management group"],
					button: this.props.translator['OK'],
				})
			
				else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') ) && (this.props.investigationItemDetails.status_id != problemStatusMapping['Under Review']))
				   swal({
					  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
					  button: this.props.translator['OK'],
				    });
					else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId)
					{
					  swal({
						  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
						  button: this.props.translator['OK'],
						});
					}
				else if(this.props.realatedTaskCount == 0){
					  this.props.changeActionView('disqualifyAsProblem');
					// this.props.statusChange();
				}
				else 
				swal({
					text: 'Tasks related to the item are not completed yet',
					button: this.props.translator['OK']
				});	
				break;
				case 'Cancel Problem':				
				if((this.props.investigationItemDetails.status_id == problemStatusMapping['Under Review']) && (this.props.investigationItemDetails.asgPmGroupid==null||this.props.investigationItemDetails.asgPmGroupid===''||this.props.investigationItemDetails.asgPmGroupid==='0'||this.props.investigationItemDetails.asgPmGroupid===0))
				swal({
					text: this.props.translator["Please select problem management group"],
					button: this.props.translator['OK'],
				})
				
				else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') ) && (this.props.investigationItemDetails.status_id != problemStatusMapping['Under Review']))
				   swal({
					  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
					  button: this.props.translator['OK'],
				    });
					else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId)
				{
				  swal({
					  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
					  button: this.props.translator['OK'],
					});
				}
				else
				  this.props.changeActionView('cancelProblem');
				break;
			case 'Cancel':
				if(this.props.realatedTaskCount == 0)
				// if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident'))
				// swal({
				// 	text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
				// 	button: this.props.translator['OK'],
				//   });
                // else 
				if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') ))
				    swal({
				      text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
				      button: this.props.translator['OK'],
				    });
			      else
				    this.props.changeActionView('cancel');
				else
					alert('Tasks related to the item are not completed yet');
				break;
			case 'View Investigation Team':
				this.props.changeActionView('problemTeam');
				break;
			case 'Form Investigation Team':
				if (this.props.user_id !== this.props.investigationItemDetails.asgPmId) {
					swal({
						text: this.props.translator["Please assign the ticket to yourself before creating problem team"],
						button: this.props.translator['OK'],
					});
				} else {
					this.props.changeActionView('investigationTeam');
				
				}
				break;
			case 'Specify Vendor Tickets':
					this.props.changeActionView("VendorTickets");
					break;
			case 'Record Problem Findings':
				  this.props.changeActionView('recordinvestigationFindings');
				  break;   
			case 'View Problem Findings':
				  this.props.changeActionView('viewinvestigationFindings');
				  break;
			case 'Root Cause Identified':
				if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident')){
				   swal({
					   text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
					    button: this.props.translator['OK'],
				   });
				}else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') )){
				   swal({
					  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
					  button: this.props.translator['OK'],
				    });
				}else if(this.props.investigationTeamList.length===0){
					swal({
						text: this.props.translator["Form Investigation Team First"],
						button: this.props.translator['OK'],
					  });
			    }else if(this.props.investigationItemDetails.rootCause){

					this.props.createKnowledgeArticle();

					let postJson={	
									"status":"45",
									"modifiedBy":"",
									"modifiedByName":""
								};
                    axios.patch(GLOBAL.investigationEditUrl+this.props.investigationItemDetails.investigationId, postJson).then((response) =>{
					  this.props.getInvestigationItemDetailsById(this.props.investigationItemDetails.investigationId);
					  this.props.loadStatusBasedHamburgerOptions('Investigation',enableButton,this.props.investigationItemDetails.investigationId);
					  this.props.changeFieldStatusData(postJson.status);
					  this.props.changeActionView('processHierarchy');
					}).catch(function (error) {
						// console.log("Problem status api changed failed. ",error);
					  });
				  }else{
					swal({
						text: 'Please enter "Specify Root Cause" to proceed further.',
						// text: this.props.translator['Please enter \"Specify Root Cause\" and \"Record Problem Findings\" to proceed further.'],
						button: this.props.translator['OK'],
					});
				  }
				  break;
			case 'Specify Root Cause':
				if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident'))
				swal({
					text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
					button: this.props.translator['OK'],
				  });
                // else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') ))
				//     swal({
				//       text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
				//       button: this.props.translator['OK'],
				//     });
			    else
				  this.props.changeActionView('rootCause');
				break;
			case 'Corrective Action Required':
			case 'Corrective Action - With Change':
				if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident'))
				swal({
					text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
					button: this.props.translator['OK'],
				  });
                else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') ))
				   swal({
					  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
					  button: this.props.translator['OK'],
				    });
				else
				  this.props.changeActionView('correctiveActionRequired');
				break;
			case 'Corrective Action Not Required':
			case 'Corrective Action - Without Change':
				if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident'))
				swal({
					text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
					button: this.props.translator['OK'],
				  });
                else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') ))
				    swal({
				      text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
				      button: this.props.translator['OK'],
				    });
			    else
				  this.props.changeActionView('correctiveActionNotRequired');
				break;
			case 'Propose Change':
				  this.props.changeActionView('change');
				break;
			case 'Closed':
				    if(this.props.investigationItemDetails.status_id == problemStatusMapping["Under Corrective Action"] && this.state.iscompletedchangeAttached){
						this.closeProblem();
					}else{
						swal({
							text: this.props.translator['To close this problem ticket, please relate at least one change which is Completed'],
							button: this.props.translator['OK'],
						});
					}
					break;
				
			case 'Close Problem':
				this.closeProblem();
				break;

			case 'Submit For Review':

				let postJson={
								"status":"35",
								"modifiedBy":"",
								"modifiedByName":""
							};
				axios.patch(GLOBAL.investigationEditUrl+this.props.investigationItemDetails.investigationId, postJson).then((response) =>{
					this.props.getInvestigationItemDetailsById(this.props.investigationItemDetails.investigationId);
					this.props.loadStatusBasedHamburgerOptions('Investigation','Under Review',this.props.investigationItemDetails.investigationId);
					this.props.changeFieldStatusData(postJson.status);
					this.props.changeActionView('processHierarchy');
				}).catch(function (error) {
					// console.log("Problem status api changed failed. ",error);
					});
				break;
			case 'Corrective Action - Not Implemented':
				this.closeProblemTicket("correctiveActionNotImplemented");
				// this.props.changeActionView('correctiveActionNotImplemented');
				break;
			case 'Withdraw Investigation':
				this.closeProblemTicket("withdrawInvestigation");
				// this.props.changeActionView('withdrawInvestigation');
				break;
		}
	}

	handleClickOutside() {
		this.props.fun(false);
	}

	renderHamburgerSOptions(statusBasedHamburgerOptions) {
		// console.log('---------statusBasedHamburgerOptions---------');
		// console.log(statusBasedHamburgerOptions);
		// console.log('renderHamburgerSOptions 1', {statusBasedHamburgerOptions});
		// console.log('********1', this.props);
		const { 
			investigationItemDetails: {
				investigationFinding = null
			}
		 } = this.props;
		if(statusBasedHamburgerOptions.length == 0){
			return(
					null
			);
		}
		let that = this;

		function labelMapping(label){
			try{
				let labelMapping = {
					'Corrective Action - With Change': 'Corrective Action with RFC',
					'Corrective Action - Without Change': 'Corrective Action without RFC'
				};
				return ((labelMapping[label]) ? labelMapping[label] : label);
			}catch(e){
				return label;
			}
		}

		return statusBasedHamburgerOptions.map((item,index)=>{
			// console.log('hamburger item',item);
			if(item.action !== 'L'){
				if(item.action == 'S'){
					// if(item.enableButton === 'Record Problem Findings' && investigationFinding === 'Y'){
					// 	item.enableButton = 'View Problem Findings';
					// }
					return(
						<ListGroup.Item bsClass="" key={index}><Nav.Item title={labelMapping(item.enableButton)} onClick={()=>{this.currentAction(item.enableButton); this.handleClickOutside(); this.props.rightEditPanel(true);}}>{item.enableButton=="blank"?null:<i className="fa fa-angle-double-right" ></i>}{item.enableButton=="blank"?null: labelMapping(item.enableButton) }</Nav.Item></ListGroup.Item>
					);
				}
			}
		});
	}

	closeProblemTicket(type = ""){
		try{
			if(this.props.user_id != this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') )){
				swal({
					text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
					button: this.props.translator['OK'],
				  });
				return;
			}
			else if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident')){
				swal({
					text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
					button: this.props.translator['OK'],
				});
				return;
			}
			else if(this.props.realatedTaskCount != 0){
				swal({
					text: 'Tasks related to the item are not completed yet',
					button: this.props.translator['OK']
				});
				return;
			}
			// this.props.statusChange(type);
			this.props.changeActionView(type);
		}catch(e){
			console.error(e);
		}
	}
	
	render(){
		// console.log('**********', {state: this.state, props: this.props});
		// console.log('*****', {props: this.props});
		// console.log("render() of BreakFixHamburger has been called")
		// console.log("this.props.investigationItemDetails : ",this.props.investigationItemDetails)
		// console.log("this.props.user_id                  : ",this.props.user_id)
		// console.log('=============',this.props.statusBasedHamburgerOptions);

		return(
				<div className="breakFixHamburger statusHamburger">
					<ListGroup bsClass="" className="myLinks">
						{this.renderHamburgerSOptions(this.props.statusBasedHamburgerOptions)}
					</ListGroup>
				</div>
		);
	}

}

StatusHamburger = OnClickOutside(StatusHamburger);


function mapStateToProps({ statusBasedHamburgerOptions, realatedTaskCount, investigationItemDetails }) {
	return {
        investigationItemDetails:(investigationItemDetails && investigationItemDetails.data ? investigationItemDetails.data[0]:investigationItemDetails),
		statusBasedHamburgerOptions,
		realatedTaskCount
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ checkRelatedTaskForItem, loadStatusBasedHamburgerOptions, loadTimelineData, loadRelatedBreakFixDataToProblem, changeFieldStatusData, getInvestigationTeamList }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusHamburger);
