/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React, { useEffect, useState } from "react";
import { QueryBuilder } from "react-querybuilder";

import CloseBtnRightPanel from "../../common/closeBtnRightPanel";
import { ValueEditor } from "../common/ValueEditor";
import { Form, Row, Col, Container, Accordion, Button, Table } from "react-bootstrap";
import { DropDownTree,DropDownList} from "@progress/kendo-react-dropdowns";
import "@progress/kendo-theme-default/dist/all.css";
import {
  findChildElement,
  getCookiesValuesByPositions,
} from "../../common/helper";
import { useSelector } from "react-redux";
import { SelectField } from "../../common/forms/FormFields";
import { HiOutlineTrash, HiPlus } from "react-icons/hi";
import { TextBox } from "@progress/kendo-react-inputs";

const QueryBuilderComponent = ({
  fields,
  query,
  values,
  setQuery,
  editFormValues,
  rightEditPanel,
  setFields,
  history,
  rows,
  setRows,
  setServiceList
}) => {
  const [dropdownOptions, setDropdownOptions] = useState([]);

  const operators = [
    { value: "=", label: "Equal" },
    { value: "%like%", label: "Contains" },
  ];

  const selectField = "selected";
  const expandField = "expanded";
  const dataItemKey = "name";
  const textField = "label";
  const subItemsField = "attributes";
  const { value1: langDir } = getCookiesValuesByPositions([66]);

  const rtlPopupSettings = {
    height: "auto",
    popupClass: "rulesDropdown",
    popupAlign: { horizontal: "right", vertical: "top" },
    anchorAlign: { horizontal: "right", vertical: "bottom" },
  };
  const ltrPopupSettings = { height: "auto", popupClass: "rulesDropdown" };

  const disabledConditionBuilder =
    !(
      values.consumerCompany &&
      values.module &&
      values.assignmentType === "predefined"
    ) || editFormValues?.status === "active";
  const translator = useSelector((state) => state.spcmReducer.tr);
  const FieldValueSelector = ({ handleOnChange, options, value, ...props }) => {
    const [fieldArr, setFieldArr] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [treeValue, setTreeValue] = useState();

    const expandedState = (item, dataItemKey, expanded) => {
      const nextExpanded = expanded.slice();
      const itemKey = item[dataItemKey];
      const index = expanded.indexOf(itemKey);
      index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);
      return nextExpanded;
    };

    const groupTwoWordsByDot = (originalString) => {
      let parts = originalString.split(".");
      let result = [];

      for (let i = 0; i < parts.length; i += 2) {
        let pattern = parts.slice(0, i + 2).join(".");
        result.push(pattern);
      }

      return result;
    };

    const mapDataTree = (data) => {
      function addKeys(obj) {
        let newObj = {
          ...obj,
          [expandField]: expanded.includes(obj?.[dataItemKey]),
          [selectField]:
            (treeValue && obj?.[dataItemKey] === treeValue?.[dataItemKey]) ||
            false,
        };

        if (Array.isArray(obj?.[subItemsField])) {
          newObj[subItemsField] = obj[subItemsField].map((child) =>
            addKeys(child)
          );
        }
        return newObj;
      }
      return data.map((obj) => addKeys(obj));
    };

    useEffect(() => {
      const arr = mapDataTree(fieldArr);
      setTreeData(arr);
    }, [expanded, treeValue, fieldArr]);

    useEffect(() => {
      if (options.length > 0 && options?.[0]?.id !== "~") {
        if (value) {
          const selectedField = findChildElement(
            options,
            "name",
            value,
            subItemsField
          );
          setTreeValue(selectedField);
          setExpanded(groupTwoWordsByDot(value));
        }
        setFieldArr(options);
      }
    }, [options, value]);

    const onChange = (event) => {
      setTreeValue(event.value);
      handleOnChange(event?.value?.name || "");
    };

    const onExpandChange = (event) => {
      setExpanded(expandedState(event.item, dataItemKey, expanded));
    };

    return (
      <Form.Group className="optrSelect">
        <DropDownTree
          data={treeData || []}
          value={treeValue}
          onChange={onChange}
          placeholder="Please select ..."
          textField={textField}
          dataItemKey={dataItemKey}
          subItemsField={subItemsField}
          selectField={selectField}
          expandField={expandField}
          onExpandChange={onExpandChange}
          disabled={props.disabled}
          popupSettings={
            langDir === "rtl" ? rtlPopupSettings : ltrPopupSettings
          }
        />
      </Form.Group>
    );
  };

  const OperatorValueSelector = ({
    className,
    handleOnChange,
    options,
    value,
    field,
    ...ValueSelectorProps
  }) => {
    const { fields } = ValueSelectorProps?.schema ?? {};
    const operators =
      findChildElement(fields, "name", field, subItemsField)?.operators || [];
    return (
      <Form.Group className="optrSelect">
        <select
          value={value}
          onChange={(v) => {
            handleOnChange(v.target.value);
          }}
          disabled={ValueSelectorProps.disabled}
          className="form-control"
        >
          <option value="">Select</option>
          {ValueSelectorProps.fieldData.label !== "" &&
            operators.map((item) => {
              const key = `key-${item?.name}`;
              return (
                <option
                  key={key}
                  value={item?.value}
                  data-id={item?.controlType}
                >
                  {item?.label}
                </option>
              );
            })}
        </select>
      </Form.Group>
    );
  };

  const handleQueryChange = (newQuery) => {
    let allRules = [];
    const accumulateAllRules = (rules) => {
      rules?.forEach((rule) => {
        if (rule.rules) {
          return accumulateAllRules(rule.rules);
        } else {
          allRules.push(rule);
        }
      });
    };
    accumulateAllRules(query?.rules);

    const resetValuesIfNeeded = (rules) => {
      return rules.map((rule) => {
        if (rule.rules) {
          return { ...rule, rules: resetValuesIfNeeded(rule.rules) };
        } else {
          // Find the existing corresponding rule by ID
          const existingRule = allRules?.find((r) => r.id === rule.id);
          if (existingRule?.field !== rule.field) {
            return { ...rule, operator: "", value: "" };
          }
          if (existingRule && existingRule.operator !== rule.operator) {
            return { ...rule, value: "" };
          }
          return rule;
        }
      });
    };

    const updatedRules = resetValuesIfNeeded(newQuery?.rules);
    setQuery({ ...newQuery, rules: updatedRules });
  };

  const fieldSelectorCustom = {
    fieldSelector: FieldValueSelector,
    valueEditor: ValueEditor,
    operatorSelector: OperatorValueSelector,
  };
  useEffect(() => {
    if (disabledConditionBuilder) {
      setQuery("");
    }
  }, [disabledConditionBuilder]);

  useEffect(() => {
    setQuery(query);
  }, [query, setQuery]);

  const handleChange = (id, index, field, value, ques='') => {
    let existingRows = structuredClone(rows);
    let updatedRows = existingRows.get(id);
    updatedRows[index][field] = value;
    if (field === "quesId") {
      updatedRows[index].ques = ques;
      updatedRows[index].value = "";
    }
    existingRows.set(id, updatedRows)
    setRows(existingRows);
  };

  const addRow = (id) => {
    let existingRows = structuredClone(rows);
    let updatedRows = existingRows.get(id);
    existingRows.set(id, [...updatedRows, { quesId: "", operator: "=", value: "" }])
    setRows(existingRows);
  };


  const deleteRow = (id, index) => {
    let existingRows = structuredClone(rows);
    let updatedRows = existingRows.get(id);
    existingRows.set(id, updatedRows.filter((_, i) => i !== index))
    setRows(existingRows);
  };

  const [offQuestions, setOffQuestions] = useState({
    value: { text: 'Select', id:"" }
  });

  const handleChangeQuestions = (event) => {
    setOffQuestions({
        value: event.target.value
    });
};


  return (
    (
      <div className="stickyArea rBoxStyle">
        <CloseBtnRightPanel showRightSide={rightEditPanel} />
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {translator["Conditions"]}
          </div>
        </div>
        <div className="rBoxGap p-2 rule-query qBuilderCtrl rulesInptDv">
          <QueryBuilder
            disabled={disabledConditionBuilder}
            fields={fields}
            setFields={setFields}
            query={query}
            onQueryChange={handleQueryChange}
            controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
            controlElements={fieldSelectorCustom}
            context={{ formValues: values, setServiceList: setServiceList}}
          />
          <div className="brkFix brkFixOrder margin-t-20">
            {history?.size > 0 && (
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Service Question</Accordion.Header>
                  <Accordion.Body>
                    <Accordion defaultActiveKey="0">
                      {Array.from(history.entries()).map(
                        ([key, serviceOrderBatch], batchIndex) => (
                          <Accordion.Item
                            eventKey={batchIndex.toString()}
                            key={batchIndex}
                          >
                            <Accordion.Header>
                              {key[1]}
                            </Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <Table borderless>
                                  <tbody>
                                  {rows.get(key[0])?.map((row, index) => (
                                    <tr key={index+"1"}>
                                      <td width="32%">
                                      <DropDownList
                                        className="kendoDropDownList"
                                        disabled={disabledConditionBuilder}
                                        data={serviceOrderBatch}
                                        value={serviceOrderBatch.find(
                                          (ques) => ques.QUESTION_ID.toString() === row?.quesId?.toString()
                                        ) || null}
                                        title={row.ques || "Select"}
                                        textField="QUESTION_TEXT"
                                        dataItemKey="QUESTION_ID"
                                        defaultItem={{ QUESTION_TEXT: "Select", QUESTION_ID: "" }}
                                        onChange={(e) => {
                                          const selectedQuestion = serviceOrderBatch.find(
                                            (ques) => ques.QUESTION_ID.toString() === e.target.value?.QUESTION_ID?.toString()
                                          );
                                          handleChange(
                                            key[0],
                                            index,
                                            "quesId",
                                            e.target.value?.QUESTION_ID,
                                            selectedQuestion?.QUESTION_TEXT || ""
                                          );
                                        }}
                                        popupSettings={{appendTo: document.getElementById("skipdv"), popupClass: "kendoDropdownPopup"}}
                                      >
                                      </DropDownList>

                                        {/* <select
                                          className="form-control"
                                          value={row.quesId}
                                          disabled={disabledConditionBuilder}
                                          onChange={(e) => {
                                            const selectedQuestion = serviceOrderBatch.find(
                                              (ques) => ques.QUESTION_ID.toString() === e.target.value.toString()
                                            );
                                            handleChange(
                                              key[0],
                                              index,
                                              "quesId",
                                              e.target.value,
                                              selectedQuestion?.QUESTION_TEXT || ""
                                            );
                                          }}
                                        >
                                          <option value="">Select</option>
                                          {serviceOrderBatch.map((ques) => (
                                          <option key={ques.QUESTION_ID} value={ques.QUESTION_ID}>
                                          {ques.QUESTION_TEXT}
                                        </option>
                                        ))}
                                        </select> */}
                                      </td>
                                      <td width="20%">
                                        <DropDownList
                                          className="kendoDropDownList"
                                          value={operators.find((op) => op.value === row.operator)}
                                          disabled={disabledConditionBuilder}
                                          onChange={(e) =>
                                            handleChange(
                                              key[0],
                                              index,
                                              "operator",
                                              e.target.value?.value
                                            )
                                          }
                                          data={operators}
                                          textField="label"
                                          dataItemKey="value"
                                          defaultValue={operators[0]}
                                          popupSettings={{appendTo: document.getElementById("skipdv"), popupClass: "kendoDropdownPopup"}}     
                                        >
                                        </DropDownList>
                                      </td>
                                      <td width="32%">
                                        <TextBox
                                          disabled={disabledConditionBuilder}
                                          value={row.value}
                                          className="kendoTextBox"
                                          onChange={(e) =>
                                            handleChange(
                                              key[0],
                                              index,
                                              "value",
                                              e.target.value
                                            )
                                          }
                                          placeholder="Enter value"
                                        />
                                      </td>
                                      <td width="16%">
                                        {(rows.get(key[0]).length > 1 && !disabledConditionBuilder) && (
                                          <Button
                                            onClick={() => deleteRow(key[0], index)}
                                            className="ruleSerBtn"
                                          >
                                            <HiOutlineTrash/>
                                          </Button>
                                        )}
                                        {(index === rows.get(key[0]).length - 1 && !disabledConditionBuilder) && (
                                          <Button
                                            onClick={() => addRow(key[0])}
                                            className="ruleSerBtn"
                                          >
                                            <HiPlus/>
                                          </Button>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                  </tbody>
                                </Table>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        )
                      )}
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default QueryBuilderComponent;
