
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import { _inputField, _textArea, _dropDown, _dateField, _inputField1 } from '../../../common/ReduxFormFields/formFields';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { connect } from 'react-redux';
import axios from 'axios';
import { GLOBAL } from "_Globals";
import moment from 'moment-timezone';

import { getLovValues, getRiskCalculated } from '../../../../actions/changeManagement/changeManagementAction';

import CIDetailsService from '../../../common/WorkItemBoard/ciNameSelect'
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
import {ImInfo} from 'react-icons/im';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import XsmUserDetails from '_Commons/WorkItemBoard/xsmEditRequestedBy.js';
import ServiceDetails from "../../../common/WorkItemBoard/xsm-service-editquickView";
import CIDEtails from "../../../common/WorkItemBoard/xsm-ci-editquickView";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
let timezone = cookies.get('gph');
if (timezone) timezone = timezone.replace('s:', '');
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf('.'));
timezone = timezone.split("~");
timezone = timezone[3];

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];

let impactedServiceData = [];
let impactedServiceDataDetails = [];
let myObj0 = [];

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

//let user_id=userDetails.user_id;

// const validate = values => {
// 	//alert(values.type)
// 	console.log("values::: ",values)

// 	const errors = {};
// 	if (!values.type) {
// 		errors.type = 'select type.';
// 	}
// 	if (!values.summary) {
// 		errors.summary = 'enter summary.';
// 	}
// 	if (values.summary) {
// 		if (values.summary.length < 5)
// 			errors.summary = 'summary is too short.';
// 	}
// 	if (!values.description) {
// 		errors.description = 'enter description.';
// 	}
// 	if (values.description) {
// 		if (values.description.length < 5)
// 			errors.description = 'description is too short.';
// 	}
// 	if (!values.changeTiming) {
// 		errors.changeTiming = 'select changeTiming.';
// 	}
// 	if (!values.urgency || values.urgency == "0") {
// 		errors.urgency = 'select urgency.';
// 	}
// 	if (!values.reasonForChange ||values.reasonForChange == "0") {
// 		errors.reasonForChange = 'select reason for change.';
// 	}
// 	if (!values.cmdbUpdateNeeded) {
// 		errors.cmdbUpdateNeeded = 'select cmdb update needed.';
// 	}
// 	if (!values.riskOccurrenceProbability || values.riskOccurrenceProbability == "null") {
// 		errors.riskOccurrenceProbability = 'select risk occurrence probability.';
// 	}
// 	if (!values.riskImpact || values.riskImpact == "null") {
// 		errors.riskImpact = 'select risk impact.';
// 	}
// 	if (!values.controlEffectiveness || values.controlEffectiveness == "null") {
// 		errors.controlEffectiveness = 'select control effectiveness.';
// 	}
// 	if (!values.overallTechnicalRisk) {
// 		errors.overallTechnicalRisk = 'select overall technical risk.';
// 	}
// 	if (!values.overallBusinessRisk) {
// 		errors.overallBusinessRisk = 'select overall business risk.';
// 	}
// 	if (!values.expectedStartDate) {
// 		errors.expectedStartDate = 'select select expected start date.';
// 	}
// 	if (!values.expectedEndDate) {
// 		errors.expectedEndDate = 'select expected end date.';
// 	}
// 	if (!values.startDate) {
// 		errors.startDate = 'select downtime start date.';
// 	}
// 	if (!values.endDate) {
// 		errors.endDate = 'select downtime end date.';
// 	}
// 	if (!values.implementationGroup) {
// 		errors.implementationGroup = 'select implementation group.';
// 	}
// 	if (!values.implementationIndividual) {
// 		errors.implementationIndividual = 'select implementation individual.';
// 	}
// 	if (!values.changeManagementGroup) {
// 		errors.changeManagementGroup = 'select change management group';
// 	}
// 	if (!values.changeManagementIndividual) {
// 		errors.changeManagementIndividual = 'select select change management individual';
// 	}
// 	return errors;
// };

let ChangeForm = class ChangeForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showRadioBtn: false,
			successMessage:'',
			errorImpactedCI:'',
			errorDescription:'',
			errorCMDBUpdateNeeded:'',
			errorChangeTiming:'',
			// errorControlEffectiveness:'',
			errorReasonChange:'',
			// errorRiskImpact:'',
			// errorRiskOccuranceProbability:'',
			errorSummary:'',
			errorType:'',
			errorTypeUrgency:'',
			oldbusinessCriticalityId: GLOBAL.businessCriticalityId,
			oldbusinessCriticality: GLOBAL.businessCriticality,
			newbusinessCriticalityId: '',
			newbusinessCriticality: '',
			impIconshow:false

		};
		this.onServiceSelection = this.onServiceSelection.bind(this);
		this.saveChange = this.saveChange.bind(this);
		this.setErrorImpactedColor = this.setErrorImpactedColor.bind(this)
		this.onImpactedSelection = this.onImpactedSelection.bind(this) 
		this.setErrorImpactedColor =this.setErrorImpactedColor.bind(this)
		this.typeChange = this.typeChange.bind(this)
		this.showImpactedCIInfoIcon = this.showImpactedCIInfoIcon.bind(this);
		GLOBAL.breakFixServiceID = this.props.breakFixEditDetails.serviceId;
		GLOBAL.breakFixServiceName = this.props.breakFixEditDetails.serviceName;
		GLOBAL.breakFixConsumerID = this.props.breakFixEditDetails.impactedUserid;
		GLOBAL.breakFixConsumerName = this.props.breakFixEditDetails.impactedUsername;
		GLOBAL.consumerCompanyId = this.props.breakFixEditDetails.consumerCompany;
	}
	componentWillMount() {
	 GLOBAL.breakFixServiceID='',
     GLOBAL.breakFixServiceName='',
     GLOBAL.breakFixConsumerID='',
     GLOBAL.breakFixConsumerName='',
     GLOBAL.consumerCompanyId='',
     GLOBAL.consumerCompanybreakfix='',
     GLOBAL.ciNameRfc='',
     GLOBAL.ciIdRfc='',
     GLOBAL.environmentName='',
     GLOBAL.ciConsumerCompany='',
     GLOBAL.ciSupportCompany='',
     GLOBAL.ciClassId='',
     GLOBAL.ciLocationId='',
     GLOBAL.ciLocationName='',
     GLOBAL.ciClassName=''
		//alert(this.props.breakFixEditDetails.requestId)
		// let strObj = {};
		// strObj.userId = "";
		// strObj = JSON.stringify(strObj);

		// api.get(GLOBAL.breakFixConsumerUrl,{headers:{'query':strObj}}).then((response) => {
		// 	consumerData = response.data;
		// 	for (let i = 0; i < consumerData.length; i++) {
		// 		myObj = { value: '', label: '' };
		// 		myObj.value = consumerData[i].userId;
		// 		myObj.label = consumerData[i].fullname +  "|" + consumerData[i].email + "|" + consumerData[i].userId + "|" +consumerData[i].companyId;
		// 		GLOBAL.consumerCompanybreakfix = consumerData[i].companyId;
		// 		consumerDataDetails.push(myObj);
		// 	}
		// 	consumerDataDetails = Array.from(new Set(consumerDataDetails));
		// });

		var str={};
		str.userId="";
		str.companyId="";
		str.associatedCompanyId="";
		str = JSON.stringify(str);

		api.get(GLOBAL.breakFixServiceWithoutDomainUrl,{headers:{'query':str}}).then((response) => {
			impactedServiceData = response.data;
			for (let i = 0; i < impactedServiceData.length; i++) {
				myObj0 = { value: '', label: '', companyname: '' };
				myObj0.value = impactedServiceData[i].offeringId;
				myObj0.label = impactedServiceData[i].offeringName + "|" + impactedServiceData[i].categoryName + "|" + impactedServiceData[i].subCategoryName;
				myObj0.companyname = impactedServiceData[i].company;
				impactedServiceDataDetails.push(myObj0);
			}
			impactedServiceDataDetails = Array.from(new Set(impactedServiceDataDetails));
		});

		// let amsAttribute={
		// 	"Status":this.props.breakFixEditDetails.statusId,
		// 	"Priority":this.props.breakFixEditDetails.priorityId,
		// 	"Service":this.props.breakFixEditDetails.serviceId,
		// 	"Impacted CI":this.props.breakFixEditDetails.ciId,
		// 	"Requestor":this.props.breakFixEditDetails.impactedUserid,
		// 	"Class":0,
		// 	"User.Location":this.props.breakFixEditDetails.locationId,
		// 	"CI.Location":this.props.breakFixEditDetails.ciLocationId
		// }
		this.props.getLovValues("ChangeManagement", "Type");
		this.props.getLovValues("ChangeManagement", "ChangeTiming");
		this.props.getLovValues("ChangeManagement", "TechnicalRisk");
		this.props.getLovValues("ChangeManagement", "BusinessRisk");
		this.props.getLovValues("ChangeManagement", "PlanType");
		this.props.getLovValues("ChangeManagement", "Urgency");
        
        // this.props.loadNewBreakFixSGAE(this.props.breakFixEditDetails.serviceBased?this.props.breakFixEditDetails.serviceId:this.props.breakFixEditDetails.ciId,
		// this.props.breakFixEditDetails.consumerCompany, "ChangeManagement",this.props.breakFixEditDetails.serviceBased?"service":"impactedCI",amsAttribute);
		// this.props.loadNewBreakFixSGAEChange(this.props.breakFixEditDetails.serviceBased?this.props.breakFixEditDetails.serviceId:this.props.breakFixEditDetails.ciId,
		// this.props.breakFixEditDetails.consumerCompany, "ChangeManagement",this.props.breakFixEditDetails.serviceBased?"service":"impactedCI",amsAttribute);
		this.props.getLovValues("ChangeManagement", "ReasonForChange");
		// this.props.getLovValues("ChangeManagement", "RiskOccurrenceProbability");
		// this.props.getLovValues("ChangeManagement", "RiskImpact");
		// this.props.getLovValues("ChangeManagement", "ControlEffectiveness");
		
		//this.props.loadBreakFixSourceName();
		if(!this.props.breakFixEditDetails.serviceBased){
            let queryPayload={};
            queryPayload.searchByName="ci_id";
            queryPayload.searchByValue=this.props.breakFixEditDetails.ciId+"";
            queryPayload.companyId=this.props.breakFixEditDetails.consumerCompany;
            queryPayload=JSON.stringify(queryPayload);
            axios.get('/api/config_items/V2?limit=1&page=1',{headers: {query: queryPayload}}).then((respondedCIDetails)=>{
                this.setState({newbusinessCriticalityId: respondedCIDetails.data.data[0].METALLIC===""||respondedCIDetails.data.data[0].METALLIC===null?0:respondedCIDetails.data.data[0].METALLIC,
					           newbusinessCriticality: respondedCIDetails.data.data[0].METALLIC_NAME});
                });
        }
	}
	setRadioVal(event) {
		if (event.target.value == 'yes') {
			this.setState({ showRadioBtn: true });
		}
		else { this.setState({ showRadioBtn: false }); }
	}

	onServiceSelection(serviceId) {
		// let amsAttribute={
		// 	"Status":this.props.breakFixEditDetails.statusId,
		// 	"Priority":this.props.breakFixEditDetails.priorityId,
		// 	"Service":this.props.breakFixEditDetails.serviceId,
		// 	"Impacted CI":this.props.breakFixEditDetails.ciId,
		// 	"Requestor":this.props.breakFixEditDetails.impactedUserid,
		// 	"Class":0,
		// 	"User.Location":this.props.breakFixEditDetails.locationId,
		// 	"CI.Location":this.props.breakFixEditDetails.ciLocationId
		// }
		// if (serviceId != "") {
		// 	this.props.loadNewBreakFixSGAE(this.props.breakFixEditDetails.serviceBased?this.props.breakFixEditDetails.serviceId:this.props.breakFixEditDetails.ciId, GLOBAL.breakFixCompanyName, "ChangeManagement",this.props.breakFixEditDetails.serviceBased?"service":"impactedCI",amsAttribute);
		// 	this.props.loadNewBreakFixSGAEChange(this.props.breakFixEditDetails.serviceBased?this.props.breakFixEditDetails.serviceId:this.props.breakFixEditDetails.ciId, GLOBAL.breakFixCompanyName, "ChangeManagement",this.props.breakFixEditDetails.serviceBased?"service":"impactedCI",amsAttribute);
		// }
	}

	saveChange(values) {
		console.log("valuess ",values);
		const jwtdf = dateformat.slice(0, 10).toUpperCase();
		const currentDateTime = moment().tz(timezone).format(jwtdf+" HH:mm:ss");
		console.log("this.props.breakFixEditDetails",this.props.breakFixEditDetails);
	    let count=0
		if(!values.type||values.type==''||values.type==null||values.type==undefined)
		{
			count++
			this.state.errorType="error"
		}
		if(!values.summary||values.summary==''){
			count++
			this.state.errorSummary="error"
		}
		if((!values.urgency || values.urgency=='') && (this.props.selectedType == "25" || this.props.selectedType == "35"))
		{
			count++
			this.state.errorTypeUrgency="error"
		}
		if(!values.reasonForChange||values.reasonForChange=='')
		{
			count++
			this.state.errorReasonChange="error"
		}
		if(!values.cmdbUpdateNeeded||values.cmdbUpdateNeeded=='')
		{
			count++
			this.state.errorCMDBUpdateNeeded="error"
		}
		// if(values.type && values.type == '35'){
		// 	if((values.riskOccurrenceProbability && values.riskOccurrenceProbability != '0') || (values.riskImpact && values.riskImpact != '0') || (values.controlEffectiveness && values.controlEffectiveness != '0')){
		// 		if(!values.riskOccurrenceProbability||values.riskOccurrenceProbability=='0')
		// 		{
		// 			count++
		// 			this.state.errorRiskOccuranceProbability="error"
		// 		}
		// 		if(!values.riskImpact||values.riskImpact=='0')
		// 		{
		// 			count++
		// 			this.state.errorRiskImpact="error"
		// 		}
		// 		if(!values.controlEffectiveness||values.controlEffectiveness=='0')
		// 		{
		// 			count++
		// 			this.state.errorControlEffectiveness="error"
		// 		}
		// 	}
		// 	else{
		// 		this.setState({
		// 			errorRiskOccuranceProbability: '',
		// 			errorRiskImpact: '',
		// 			errorControlEffectiveness: ''
		// 		});
		// 	}
		// }
		// else{
		// 	if(!values.riskOccurrenceProbability||values.riskOccurrenceProbability=='0')
		// 	{
		// 		count++
		// 		this.state.errorRiskOccuranceProbability="error"
		// 	}
		// 	else{
		// 		this.setState({
		// 			errorRiskOccuranceProbability: ''
		// 		});
		// 	}
		// 	if(!values.riskImpact||values.riskImpact=='0')
		// 	{
		// 		count++
		// 		this.state.errorRiskImpact="error"
		// 	}
		// 	else{
		// 		this.setState({
		// 			errorRiskImpact: ''
		// 		});
		// 	}
		// 	if(!values.controlEffectiveness||values.controlEffectiveness=='0')
		// 	{
		// 		count++
		// 		this.state.errorControlEffectiveness="error"
		// 	}
		// 	else{
		// 		this.setState({
		// 			errorControlEffectiveness: ''
		// 		});
		// 	}
		// }
		// if(!values.riskOccurrenceProbability||values.riskOccurrenceProbability=='')
		// {
		// 	count++
		// 	this.state.errorRiskOccuranceProbability="error"
		// }
        // if(!values.riskImpact||values.riskImpact=='')
		// {
        //     count++
		// 	this.state.errorRiskImpact="error"
		// }
		// if(!values.controlEffectiveness||values.controlEffectiveness=='')
		// {
		// 	count++
		// 	this.state.errorControlEffectiveness="error"
		// }
		if(!values.description||values.description.trim().length===0)
		{
			count++
			this.state.errorDescription="error"
		}
		if(this.props.breakFixEditDetails.serviceBased){
		if(GLOBAL.ciNameRfc===''||GLOBAL.ciNameRfc==null ||GLOBAL.ciNameRfc==undefined)
		{
			count++
		    this.state.errorImpactedCI="error"
		}
	    }
		if((!values.changeTiming || values.changeTiming=='') && (values.type !="25" && values.type !="30" && values.type !="35"))
		{
			count++
			this.state.errorChangeTiming="error"
		}
		if(count!=0)
		{
           this.setState({errorImpactedCI: this.state.errorImpactedCI})
		}
		else{
		if(this.props.loading) return false;

		let breakFixSGAENamee=[];
		let breakFixSGAEChangeNamee=[];
		let self=this;
		//let changeGroupName = "";
		let changeGroupIndividualId = "";
		let changeGroupIndividualName = "";

		//alert(self.props.breakFixEditDetails.requestId)
		breakFixSGAENamee=this.props.implementationAndChangeManagementGroup;
		console.log(breakFixSGAENamee)
		breakFixSGAEChangeNamee=this.props.ChangeManagementGroup;
		//let changeGroupId = ""
		if (breakFixSGAEChangeNamee != null && breakFixSGAEChangeNamee != 'undefined' && breakFixSGAEChangeNamee.length != 0) {
			//if ((typeof breakFixSGAEChangeNamee[0].assignmentGroupId) != 'undefined')
				//changeGroupId = breakFixSGAEChangeNamee[0].assignmentGroupId;
				
				//if ((typeof breakFixSGAEChangeNamee[0].assignmentGroupName) != 'undefined')
				//changeGroupName = breakFixSGAEChangeNamee[0].assignmentGroupName;

				if ((typeof breakFixSGAEChangeNamee[0].assignmentMemberId) != 'undefined')
				changeGroupIndividualId = breakFixSGAEChangeNamee[0].assignmentMemberId;

				if ((typeof breakFixSGAEChangeNamee[0].assignmentMemberName) != 'undefined')
				changeGroupIndividualName = breakFixSGAEChangeNamee[0].assignmentMemberName;
		}
		//alert(changeGroupId)


		//let changeImplementorGroupId = "";
		//let changeImplementorGroupName = "";
		let changeImplementorIndividualId = "";
		let changeImplementorIndividual = "";

		if (breakFixSGAENamee != null && breakFixSGAENamee != 'undefined' && breakFixSGAENamee.length != 0) {
			// if ((typeof breakFixSGAENamee[0].assignmentGroupId) != 'undefined')
			// 	changeImplementorGroupId = breakFixSGAENamee[0].assignmentGroupId;

				// if ((typeof breakFixSGAENamee[0].assignmentGroupName) != 'undefined')
				// changeImplementorGroupName = breakFixSGAENamee[0].assignmentGroupName;

				if ((typeof breakFixSGAENamee[0].assignmentMemberId) != 'undefined')
				changeImplementorIndividualId = breakFixSGAENamee[0].assignmentMemberId;

				if ((typeof breakFixSGAENamee[0].assignmentMemberName) != 'undefined')
				changeImplementorIndividual = breakFixSGAENamee[0].assignmentMemberName;
		}


		if(changeImplementorIndividualId==null)
		changeImplementorIndividualId=0;
		if(changeImplementorIndividual==null)
		changeImplementorIndividual=0;
		if(changeGroupIndividualId==null)
		changeGroupIndividualId=0;
		let ref=this
		let newChangeObject = {
			"changeType": values.type,
			"summary": values.summary,
			//"requesterId": GLOBAL.breakFixConsumerID,
			//"requesterName": GLOBAL.breakFixConsumerName,
			"serviceName": '',
			"serviceId": 0,
			"ciName":this.props.breakFixEditDetails.serviceBased?GLOBAL.ciNameRfc:this.props.breakFixEditDetails.ciName,
			"ciId":this.props.breakFixEditDetails.serviceBased?GLOBAL.ciIdRfc:this.props.breakFixEditDetails.ciId,
			"environment":this.props.breakFixEditDetails.serviceBased?GLOBAL.environmentNameRfc:this.props.breakFixEditDetails.environment,
            "ciConsumerCompany":this.props.breakFixEditDetails.serviceBased?GLOBAL.ciConsumerCompanyRfc:this.props.breakFixEditDetails.ciConsumerCompany,
            "ciSupportCompany":this.props.breakFixEditDetails.serviceBased?GLOBAL.ciSupportCompanyRfc:this.props.breakFixEditDetails.ciSupportCompany,
            "ciClassId":this.props.breakFixEditDetails.serviceBased?GLOBAL.ciClassIdRfc:this.props.breakFixEditDetails.ciClassId,
            "ciLocationId":this.props.breakFixEditDetails.serviceBased?GLOBAL.ciLocationIdRfc:this.props.breakFixEditDetails.ciLocationId,
            "ciLocationName":this.props.breakFixEditDetails.serviceBased?GLOBAL.ciLocationNameRfc:this.props.breakFixEditDetails.ciLocationName,
			"ciClassName": this.props.breakFixEditDetails.serviceBased?GLOBAL.ciClassNameRfc:this.props.breakFixEditDetails.ciClassName,
			"cimGroup":this.props.breakFixEditDetails.cimGroup,
			"cimGroupname":this.props.breakFixEditDetails.cimGroupname,
			"cimUser":this.props.breakFixEditDetails.cimUser,
			"cimUsername":this.props.breakFixEditDetails.cimUsername,
			"serviceBased":false,
			"description": values.description,
			"changeTiming": (values.type =="25"  || values.type =="30" || values.type =="35"? "0" : values.changeTiming),
			"technicalRisk": 0,
			"businessRisk": 0,
			"expEndDate": currentDateTime,
			"expStartDate": currentDateTime,
			"dStartDate": 0,
			"dEndDate": 0,
			"actStartDate": 0,
			"actEndDate": 0,
			"changeGroupid": 0,
			"changeGroupname": '',
			"changeUserid": changeGroupIndividualId == "" ? "0" : changeGroupIndividualId,
			"changeUsername": changeGroupIndividualName,
			"impGroupid":  0,
			// "impGroupname": changeImplementorGroupName == "" ? "0" : changeImplementorGroupName,
			"impGroupname": '',
			"impUserid": changeImplementorIndividualId == "" ? "0" : changeImplementorIndividualId,
			// "impUsername":changeImplementorIndividual == "" ? "0" : changeImplementorIndividual,
			"impUsername":changeImplementorIndividual || "",
			"createdBy": "",
			"createdOn": 0,
			"modifiedBy": "",
			"modifiedOn": 0,
			"plan": "0",
			"planType": "20",
			"requestId": self.props.breakFixEditDetails.requestId,
			"reportedThrough":self.props.breakFixEditDetails.reportedThrough,
			"status": "30",
			"type": "0",
			"consumerCompany": this.props.breakFixEditDetails.consumerCompany,
			"createdByName":"",
			"requesterId":"",
			"requesterName":"",
			"consumerCompanyName":this.props.breakFixEditDetails.consumerCompanyName,
			"urgency":(values.type =="25" || values.type =="35"?values.urgency:"0"),
			"reasonForChange":(values.type =="25" || values.type =="30" || values.type =="35" ?values.reasonForChange:"0"),
			// "riskImpact":(values.type =="25" || values.type =="30" || values.type =="35"?values.riskImpact:"null"),
			// "riskOccurrenceProbability":(values.type =="25" || values.type =="30" || values.type =="35"?values.riskOccurrenceProbability:"null"),
			// "controlEffectiveness":(values.type =="25" || values.type =="30" || values.type =="35"?values.controlEffectiveness:"null"),
			// "risk":(values.type =="25" || values.type =="30" || values.type =="35"?this.props.riskAssessmentValue.overallRisk:"null"),
			"cmdbUpdateNeeded":(values.type =="25"  || values.type =="30" || values.type =="35" ?values.cmdbUpdateNeeded:"No"),
			// "requesterLocationId":this.props.breakFixEditDetails.requesterLocationId,
			// "requesterLocationName":this.props.breakFixEditDetails.requesterLocationName
			"requesterLocationId":this.props['locationId'],
			"requesterLocationName":this.props['locationName'],
	        "businessCriticalityId": this.state.newbusinessCriticalityId,
			"businessCriticality": this.state.newbusinessCriticality,
			"supportCompanyId": '',
			"supportCompanyName": '',
			"proposed": true,
			"proposedWorkItemId": this.props.breakFixEditDetails.breakfixId,
			"proposedModule": "breakfix"
		};


		return axios.post(GLOBAL.postChangeUrl,
			newChangeObject
		).then((response) => {
			if(response.status==200 || response.status==201){
				let activityDetails ={
					"itemId": ref.props.breakFixEditDetails.breakfixId,
					"description":`Added a Related Work Item - ${ref.props.breakFixEditDetails.breakfixNumber} Has Caused ${response.data.changeNumber}`,
					"isInternal":"Y",
					"createdOn":"",
					"createdBy":"",
					"module":"Breakfix",
					"createdByName":"",
					"sendNotification":"true",
					"isExternal":""
				};
				axios.post("/serviceManagement/rest/activity/", activityDetails);

				if(response && response?.data && typeof response.data == 'object'){
					let obj = response.data;
					let payloadObj ={
					  "ci_id": obj?.ciId,
					  "wi_id_related": obj?.changeId,
					  "wi_id_number": obj?.changeNumber,
					  "ci_id_reported": obj?.createdOn,
					  "created_by": "",
					  "relationship_type": "Y",
					  "module": "Change",
					  "consumerCompany": obj?.consumerCompany,
					  "consumerCompanyName": obj?.consumerCompanyName
					  }
					axios.post(GLOBAL.cmdbRelatedLogDeleteUrl, payloadObj).then((res1) =>{
					  // console.log('res1 - ', res1);
					})
					.catch((err1) => {
					  console.error(err1);
					});
				}

				axios.post(GLOBAL.breakFixRelatedLogPostUrl, {
					"itemId": ref.props.breakFixEditDetails.breakfixId,
					"status": "A",
					"createdOn": "",
					"createdBy": "",
					"modifiedOn": "",
					"modifiedBy": "",
					"module":"Breakfix",
					"workItemId":response.data.changeId,
					"workModule":"ChangeManagement",
					"workItemNumber":response.data.changeNumber,
					"createdByName": "",
					"relationType":"p2c",
					"itemNumber":ref.props.breakFixEditDetails.breakfixNumber
				});

				// axios.post(GLOBAL.breakFixRelatedLogPostUrl, {
				// 	"itemId": response.data.changeId,
				// 	"status": "A",
				// 	"createdOn": "",
				// 	"createdBy": "",
				// 	"modifiedOn": "",
				// 	"modifiedBy": "",
				// 	"module":"ChangeManagement",
				// 	"workItemId":ref.props.breakFixEditDetails.breakfixId,
				// 	"workModule":"Breakfix",
				// 	"workItemNumber":ref.props.breakFixEditDetails.breakfixNumber,
				// 	"createdByName": "",
				// 	"relationType":"p2p"
				// })



				ref.setState({
					successMessage:response.data.changeNumber+" has been successfully created. Please review your Request for change, perform any updates necessary and then submit for approval"

	})

			}else{
				ref.setState({
					successMessage:"Change not created"
				});
			}
		})
			.catch((err) => {
				console.log(err);
				ref.setState({
					successMessage:"Change not created"
				});
			});
		}		
	}

	// getRiskCalculated(field,e){
	// 	let fieldVal = e.target.value;
	// 	let riskOccurrenceVal = this.props.riskOccurrenceVal;
	// 	let riskImpactVal = this.props.riskImpactVal;
	// 	let controlEffectivenesVal = this.props.controlEffectivenesVal;

	// 	switch (field) {
	// 		case "riskOccurrenceProbability":
	// 			riskOccurrenceVal = fieldVal;
	// 			break;
	// 		case "riskImpact":
	// 			riskImpactVal = fieldVal;
	// 			break;
	// 		case "controlEffectiveness":
	// 			controlEffectivenesVal = fieldVal;
	// 			break;
	// 	}
	// 	if(riskOccurrenceVal != "" && riskOccurrenceVal != undefined && riskImpactVal != "" && riskImpactVal != undefined &&
	// 		controlEffectivenesVal != "" && controlEffectivenesVal != undefined)
	// 	this.props.getRiskCalculated(riskOccurrenceVal,riskImpactVal,controlEffectivenesVal);
	// }

	onImpactedSelection(){
		// console.log("GLOBAL.priority",GLOBAL.priority);
		// // let amsAttribute={
		// // 	"Status":this.props.breakFixEditDetails.statusId,
		// // 	"Priority":this.props.breakFixEditDetails.priorityId,
		// // 	"Service":this.props.breakFixEditDetails.serviceId,
		// // 	"Impacted CI":GLOBAL.ciId,
		// // 	"Requestor":this.props.breakFixEditDetails.impactedUserid,
		// // 	"Class":0,
		// // 	"User.Location":this.props.breakFixEditDetails.locationId,
		// // 	"CI.Location":this.props.breakFixEditDetails.ciLocationId
		// // }
		// if(GLOBAL.ciId!='') {
		// 	this.props.loadNewBreakFixSGAE(GLOBAL.ciId, GLOBAL.ciConsumerCompany, "ChangeManagement","impactedCI",amsAttribute);
		//     this.props.loadNewBreakFixSGAEChange(GLOBAL.ciId, GLOBAL.ciConsumerCompany, "ChangeManagement","impactedCI",amsAttribute);
		// }
		this.setState({newbusinessCriticalityId: GLOBAL.businessCriticalityId, newbusinessCriticality: GLOBAL.businessCriticality});
		GLOBAL.businessCriticalityId=this.state.oldbusinessCriticalityId;
		GLOBAL.businessCriticality=this.state.oldbusinessCriticality;
		this.showImpactedCIInfoIcon(true);
	}
	setErrorImpactedColor(value){
		this.setState({errorImpactedCI: value})
	}
	typeChange(e){
		if(e.target.name=='type' && this.state.errorType!='')
		   this.setState({errorType:''})
		else if(e.target.name=='summary' && this.state.errorSummary!='')
		   this.setState({errorSummary:''})
		else if(e.target.name=='urgency' && this.state.errorTypeUrgency!='' && (this.props.selectedType == "25" || this.props.selectedType == "35"))
		   this.setState({errorTypeUrgency:''})
		else if(e.target.name=='changeTiming' && this.state.errorChangeTiming!='') 
		   this.setState({errorChangeTiming:''})
		else if(e.target.name=='reasonForChange' && this.state.errorReasonChange!='')
		   this.setState({errorReasonChange:''})
		else if(e.target.name=='cmdbUpdateNeeded' && this.state.errorCMDBUpdateNeeded!='')
		   this.setState({errorCMDBUpdateNeeded:''})
		else if(e.target.name=='description' && this.state.errorDescription!='')
		   this.setState({errorDescription:''});

		// else if(e.target.name=='riskOccurrenceProbability' && this.state.errorRiskOccuranceProbability!='')
		//    this.setState({errorRiskOccuranceProbability:''})
		// else if(e.target.name=='riskImpact' && this.state.errorRiskImpact!='')
		//    this.setState({errorRiskImpact:''})
		// else if(e.target.name=='controlEffectiveness' && this.state.errorControlEffectiveness!='')
		//    this.setState({errorControlEffectiveness:''})
	}
    componentWillUnmount(){
     GLOBAL.breakFixServiceID='',
     GLOBAL.breakFixServiceName='',
     GLOBAL.breakFixConsumerID='',
     GLOBAL.breakFixConsumerName='',
     GLOBAL.consumerCompanyId='',
     GLOBAL.consumerCompanybreakfix='',
     GLOBAL.ciNameRfc='',
     GLOBAL.ciIdRfc='',
     GLOBAL.environmentName='',
     GLOBAL.ciConsumerCompany='',
     GLOBAL.ciSupportCompany='',
     GLOBAL.ciClassId='',
     GLOBAL.ciLocationId='',
     GLOBAL.ciLocationName='',
     GLOBAL.ciClassName='',
	 GLOBAL.businessCriticalityId=this.state.oldbusinessCriticalityId;
	 GLOBAL.businessCriticality=this.state.oldbusinessCriticality
	}
	showImpactedCIInfoIcon(value){
		this.setState({impIconshow: value});
	}
	render() {
		const imptCI = (
            <Popover className="userInfoPopup">
            <CIDEtails ciId={GLOBAL.ciIdRfc} translator={this.props.tr}/>
            </Popover>
        );
		const {
				type,
				summary,
				changeTiming,
				description,
				handleSubmit,
				pristine,
				reset,
				submitting,
				urgency,
				reasonForChange,
				// riskOccurrenceProbability,
				// riskImpact,
				// controlEffectiveness,
				cmdbUpdateNeeded
			} = this.props;
			// riskOccurrenceProbability.map((a)=>{
			// 	a.field1Value=parseInt(a.field1Value);
			
			// })
			// riskImpact.map((a)=>{
			// 	a.field1Value=parseInt(a.field1Value);
			// })
			// controlEffectiveness.map((a)=>{
			// 	a.field1Value=parseInt(a.field1Value);
			// })
			// riskOccurrenceProbability.sort((a, b) => (a.field1Value > b.field1Value) ? 1 : -1);
			// riskImpact.sort((a, b) => (a.field1Value > b.field1Value) ? 1 : -1);
			// controlEffectiveness.sort((a, b) => (a.field1Value < b.field1Value) ? 1 : -1);
			reasonForChange.sort((a, b) => a.field1Key.localeCompare(b.field1Key));
			if(this.state.successMessage==""){

		return (
			<form>
				<div>
				<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                    <ul>
                        <li>
                        <button
						    type='button'
                            title="Minimize the right panel"
                            bsClass=""
                            bsStyle=""
                            className="closerightPanelBtn"
                            onClick={() => {
                            this.props.rightEditPanel(false);
							this.props.changeRightView("allClose");
                            }}
                        >
                            <IoClose />
                        </button>
                        </li>
                    </ul>
                </div>
				<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Request For Change']}</div></div>
				<div className='rBoxGap'>
					
					<div className="borderForm bFormDiv">
						<Row>
							<Col md={12}>
								<Form.Group className="form-group" 
								// validationState={this.state.errorType}
								>
									<Form.Label bsClass=""><span className="rStar" />{this.props.translator['Type']} </Form.Label>
									<Field name="type" component={_dropDown} options={type} className={"form-control " + (this.state.errorType)} onChange={(e)=>this.typeChange(e)}
									/>
								</Form.Group>
							</Col>
							</Row>
							<Row>
							   <Col md={12}>
								   {this.props.breakFixEditDetails.serviceBased?
							       <Form.Group className="form-group" 
								//    validationState={this.state.errorImpactedCI}
								   >
								   <Form.Label bsClass=""><span className="rStar"/>{this.props.translator['Impacted CI']}</Form.Label>
								   <div className='position-re infoBtnDiv'>
								   <Field name="ciName"
											  companyId={this.props.breakFixEditDetails.consumerCompany}
											  component={CIDetailsService}
											  onImpactedSelection={this.onImpactedSelection}
											  setErrorImpactedColor={this.setErrorImpactedColor}
											  view={"CreateRfc"}
											  errorColor={this.state.errorImpactedCI}
											  showImpactedCIInfoIcon={this.showImpactedCIInfoIcon}
											  onType={this.showImpactedCIInfoIcon}
									    />
									{this.state.impIconshow?<OverlayTrigger trigger="click" rootClose placement="left" overlay={imptCI}><span className="infoBtnDiv infoicn"><ImInfo/></span></OverlayTrigger>:null}
									</div>
								   </Form.Group>
								   :
								   null
			                       }
							   </Col>
							</Row>
							<Row>
							<Col md={12}>
								<Form.Group className="form-group" 
								// validationState={this.state.errorSummary}
								>
									<Form.Label bsClass=""><span className="rStar" />{this.props.translator['Summary']} </Form.Label>
									<Field name="summary" type="textarea" component={_textArea} label="" className={"form-control " + (this.state.errorSummary)} maxlength="250" rows="1" onChange={(e)=>this.typeChange(e)}/>
								</Form.Group>
							</Col>
							</Row>
						{/*	<Col md={4}>
								<Form.Group className="form-group">
									<Form.Label bsClass=""><span className="rStar" /> Requestor</Form.Label>
									<div className="position-re">
										<Field name="requestor"
											options={consumerDataDetails}
											component={RFReactConsumer} />
									</div>
								</Form.Group>
							</Col>
						</Row>*/}
						{/*<Row>
							<Col md={12}>
								<Form.Group className="form-group">
									<Form.Label bsClass=""><span className="rStar" /> Service</Form.Label>
									<Field name="impactedServiceName"
										options={impactedServiceDataDetails}
										component={RFReactService} onServiceSelection={this.onServiceSelection} />
								</Form.Group>
							</Col>
						</Row>*/}
						<Row>
							{(this.props.selectedType == "25" || this.props.selectedType == "35") ?
								<Col md={12}>
									<Form.Group className="form-group" 
									// validationState={this.state.errorTypeUrgency}
									>
										<Form.Label bsClass="">{this.props.translator['Urgency']}</Form.Label>
										<Field name="urgency" component={_dropDown} options={urgency} className={"form-control " + (this.state.errorTypeUrgency)} onChange={(e)=>this.typeChange(e)}
										/>
									</Form.Group>
								</Col>
								:
								(
									(this.props.selectedType == "30" || this.props.selectedType == "35")?"":
								<Col md={12}>
									<Form.Group className="form-group" 
									// validationState={this.state.errorChangeTiming}
									>
										<Form.Label bsClass="">{this.props.translator['Change Timing']}</Form.Label>
										<Field name="changeTiming" component={_dropDown} options={changeTiming} className={"form-control " + (this.state.errorChangeTiming)} onChange={(e)=>this.typeChange(e)}
										/>
									</Form.Group>
								</Col>)
							}
						</Row>
						{this.props.selectedType == "25" || this.props.selectedType == "30" || this.props.selectedType == "35" ?
							<div>
								<Row>
									<Col md={12}>
										<Form.Group className="form-group" 
										// validationState={this.state.errorReasonChange}
										>
											<Form.Label bsClass=""><span className="rStar" />{this.props.translator['Reason for Change']}</Form.Label>
											<Field name="reasonForChange" component={_dropDown} options={reasonForChange} className={"form-control " + (this.state.errorReasonChange)} onChange={(e)=>this.typeChange(e)}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
								<Col md={12}>
										<Form.Group className="form-group" 
										// validationState={this.state.errorCMDBUpdateNeeded}
										>
											<Form.Label bsClass=""><span className="rStar" />{this.props.translator['CMDB Update Needed']}</Form.Label>
											<Field name="cmdbUpdateNeeded"  component={_dropDown} options={cmdbUpdateNeeded} className={"form-control " + (this.state.errorCMDBUpdateNeeded)} onChange={(e)=>this.typeChange(e)}
											/>
										</Form.Group>
								</Col>
								</Row>
								{/* {this.props.selectedType == "25" || this.props.selectedType == "30" || this.props.selectedType == "35"?
								<div>
								<Row>
									<Col md={12}>
										<Form.Group className="form-group" 
										// validationState={this.state.errorRiskOccuranceProbability}
										>
											<Form.Label bsClass="">{this.props.translator['Risk Occurrence Probability']}</Form.Label>
											<Field name="riskOccurrenceProbability" component={_dropDown} options={riskOccurrenceProbability}
												onChange={(e) => {this.getRiskCalculated("riskOccurrenceProbability",e),this.typeChange(e)}}
												className={"form-control " + (this.state.errorRiskOccuranceProbability)} 
												/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col md={12}>
										<Form.Group className="form-group" 
										// validationState={this.state.errorRiskImpact}
										>
											<Form.Label bsClass="">{this.props.translator['Impact if Risk is realized']}</Form.Label>
											<Field name="riskImpact" component={_dropDown} options={riskImpact}
												onChange={(e) => {this.getRiskCalculated("riskImpact",e),this.typeChange(e)}}
												className={"form-control " + (this.state.errorRiskImpact)} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col md={12}>
										<Form.Group className="form-group" 
										// validationState={this.state.errorControlEffectiveness}
										>
											<Form.Label bsClass="">{this.props.translator['Control Effectiveness']}</Form.Label>
											<Field name="controlEffectiveness" component={_dropDown} options={controlEffectiveness}
												onChange={(e) => {this.getRiskCalculated("controlEffectiveness",e),this.typeChange(e)}}
												className={"form-control " + (this.state.errorControlEffectiveness)} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col md={12}>
										<Form.Group className="form-group">
											<Form.Label bsClass="">{this.props.translator['Overall Risk']} {this.props.loading ? <i className="f-size-13 fa fa-spinner fa-spin"></i>
												: null}</Form.Label>
											<Form.Control readOnly type="text" value={this.props.riskAssessmentValue && this.props.riskAssessmentValue.overallRisk ? this.props.riskAssessmentValue.overallRisk : ""} />
										</Form.Group>
									</Col>
								</Row>
								</div>
							:""} */}
							</div>
							: ""}
						<Row>
							<Col md={12}>
								<Form.Group className="form-group" 
								// validationState={this.state.errorDescription}
								>
									<Form.Label bsClass=""><span className="rStar" />{this.props.translator['Description']} </Form.Label>
									<Field name="description" type="textarea" component={_textArea} label="" className={"form-control " + (this.state.errorDescription)} rows="2" onChange={(e)=>this.typeChange(e)} maxlength="16000"/>
								</Form.Group>
							</Col>
						</Row>

						{/*<Row>
							<Col md={12}>
							<Form.Group className="form-group">
								<Form.Label bsClass=""><span className="rStar"></span>Through</Form.Label>
								<Field name="reportedThrough" component={_dropDown} options={breakFixSource} className="form-control" />
							</Form.Group>
							</Col>
						</Row>*/}



						{/*<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
							<div className="bgGray padding-5"><span className="rStar" /> Change Classification</div>
							<Row>
								<Col md={4}>
									<Form.Group className="form-group">
										<Form.Label bsClass="">Change Timing</Form.Label>
										<Field name="changeTiming" component={_dropDown} options={changeTiming} className="form-control" />
									</Form.Group>
								</Col>
								<Col md={4}>
									<Form.Group className="form-group">
										<Form.Label bsClass="">Overall Technical Risk</Form.Label>
										<Field name="overallTechnicalRisk" component={_dropDown} options={technicalRisk} className="form-control" />
									</Form.Group>
								</Col>
								<Col md={4}>
									<Form.Group className="form-group">
										<Form.Label bsClass="">Overall Business Risk</Form.Label>
										<Field name="overallBusinessRisk" component={_dropDown} options={businessRisk} className="form-control" />
									</Form.Group>
								</Col>
							</Row>
						</div>*/}

					{/*	<Row>
							<Col md={6}>
								<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
									<div className="bgGray padding-5"><span className="rStar" /> Schedule</div>
									<Row>
										<Col md={6}>
											<Form.Group className="form-group">
												<Form.Label bsClass="">Expected Start Date</Form.Label>
												<div className="dateTimeSdiv">
													<Field name="expectedStartDate" component={_dateField} className="form-control" formType="create" />
												</div>
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group className="form-group">
												<Form.Label bsClass="">Expected End Date</Form.Label>
												<div className="dateTimeSdiv">
													<Field name="expectedEndDate" component={_dateField} className="form-control" formType="create" />
												</div>
											</Form.Group>
										</Col>
									</Row>
								</div>
							</Col>
							<Col md={6}>
								<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
									<div className="bgGray padding-5"><div className="float-r"><Form.Group className="form-group" className="margin-0" onChange={this.setRadioVal.bind(this)} ><Radio name="downtimeRad" inline value="yes">Yes</Radio>{' '}<Radio defaultChecked name="downtimeRad" inline value="no">NO</Radio></Form.Group></div><span className="rStar"></span> Downtime Required? </div>
									<Row>
										<Col md={6}>
											<Form.Group className="form-group">
												<Form.Label bsClass="">Start Date</Form.Label>
												<div className="dateTimeSdiv">
													<Field name="startDate" component={_dateField} className="form-control" formType="create" />
												</div>
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group className="form-group">
												<Form.Label bsClass="">End Date</Form.Label>
												<div className="dateTimeSdiv">
													<Field name="endDate" component={_dateField} className="form-control" formType="create" />
												</div>
											</Form.Group>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>*/}

					{/*	<Row>
							<Col md={6}>
								<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
									<div className="bgGray padding-5"><span className="rStar" /> Implementation</div>
									<Row>
										<Col md={6}>
											<Form.Group className="form-group">
												<Form.Label bsClass="">Group</Form.Label>
												<Field name="implementationGroup" component={_dropDown} options={implementationAndChangeManagementGroup} className="form-control" onChange = {(event)=>{this.props.dispatch(change("changeForm","impGroupname",event.target[event.target.selectedIndex].text));}}/>
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group className="form-group">
												<Form.Label bsClass="">Individual</Form.Label>
												<Field name="implementationIndividual" component={_dropDown} options={implementationAndChangeManagementGroup} className="form-control" onChange = {(event)=>{this.props.dispatch(change("changeForm","impUsername",event.target[event.target.selectedIndex].text));}}/>
											</Form.Group>
										</Col>
									</Row>
								</div>
							</Col>
							<Col md={6}>
								<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
									<div className="bgGray padding-5"><span className="rStar"></span> Change Management</div>
									<Row>
										<Col md={6}>
											<Form.Group className="form-group">
												<Form.Label bsClass="">Group</Form.Label>
												<Field name="changeManagementGroup" component={_dropDown} options={ChangeManagementGroup} className="form-control" onChange = {(event)=>{this.props.dispatch(change("changeForm","changeGroupname",event.target[event.target.selectedIndex].text));}}/>
											</Form.Group>
										</Col>
										<Col md={6}>
											<Form.Group className="form-group">
												<Form.Label bsClass="">Individual</Form.Label>
												<Field name="changeManagementIndividual" component={_dropDown} options={ChangeManagementGroup} className="form-control" onChange = {(event)=>{this.props.dispatch(change("changeForm","changeUsername",event.target[event.target.selectedIndex].text));}}/>
											</Form.Group>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>*/}
					</div>

					<ButtonToolbar className="black">
						<Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary"
						disabled={submitting} onClick={handleSubmit(this.saveChange)}>
						{submitting ? <ImSpinner6 className="icn-spinner"/>
							: null} {this.props.translator['Submit']}</Button>
					</ButtonToolbar>

					{/* <Button bsStyle="primary" onClick={handleSubmit(this.saveChange)} >
					{this.props.translator['Submit']}</Button> */}
				</div>
				
				</div>
			</form>
		);
	}else{
		return (
			<div>
				<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
					<ul>
						<li>
						<button
						    type='button'
							title="Minimize the right panel"
							bsClass=""
							bsStyle=""
							className="closerightPanelBtn"
							onClick={() => {
							this.props.rightEditPanel(false);
							this.props.changeRightView("allClose");
							}}
						>
							<IoClose />
						</button>
						</li>
					</ul>
				</div>
				<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Request For Change']}</div></div>
				<div className='rBoxGap'>
					{this.state.successMessage}
				</div>
			</div>
		);
	}
	}
};

const mapStateToProps = ({
	lovForType,
	lovForChangeTiming,
	lovForBusinessRisk,
	lovForTechnicalRisk,
	lovForPlanType,
	breakFixSGAEName,
	breakFixSGAEChangeName,
	lovForUrgency,
	lovForReasonForChange,
	lovForRiskOccurrence, lovForRiskImpact, lovForControlEffectiveness, riskAssessmentValue,showLoader3

}) => {
	// console.log('lovForType - ', lovForType);
	// let lovForTypeUpd = [];
	// if(lovForType && lovForType.length){
	// 	lovForTypeUpd = [...lovForType];
	// 	lovForTypeUpd.push({
	// 		"menulistId": "",
	// 		"module": "ChangeManagement",
	// 		"field1Name": "Type",
	// 		"field1Key": "Latent Change",
	// 		"field1Value": "35"
	// 	});
	// }
	let type = lovForType == "undefined" || lovForType.length == 0 ? [] : lovForType;
	// let type = lovForTypeUpd;
	let changeTiming = lovForChangeTiming == "undefined" || lovForChangeTiming.length == 0 ? [] : lovForChangeTiming;
	let businessRisk = lovForBusinessRisk == "undefined" || lovForBusinessRisk.length == 0 ? [] : lovForBusinessRisk;
	let technicalRisk = lovForTechnicalRisk == "undefined" || lovForTechnicalRisk.length == 0 ? [] : lovForTechnicalRisk;
	let planType = lovForPlanType == "undefined" || lovForPlanType.length == 0 ? [] : lovForPlanType;
	let implementationAndChangeManagementGroup = breakFixSGAEName == "undefined" || breakFixSGAEName.length == 0 ? [] : breakFixSGAEName;
	let ChangeManagementGroup = breakFixSGAEChangeName == "undefined" || breakFixSGAEChangeName.length == 0 ? [] : breakFixSGAEChangeName;
	let urgency =  lovForUrgency == "undefined" || lovForUrgency.length == 0 ? [] : lovForUrgency;
	let reasonForChange = lovForReasonForChange == "undefined" || lovForReasonForChange.length == 0 ? [] : lovForReasonForChange;
	// let riskOccurrenceProbability = lovForRiskOccurrence == "undefined" || lovForRiskOccurrence.length == 0 ? [] : lovForRiskOccurrence;
	// let riskImpact = lovForRiskImpact == "undefined" || lovForRiskImpact.length == 0 ? [] : lovForRiskImpact;
	// let controlEffectiveness = lovForControlEffectiveness == "undefined" || lovForControlEffectiveness.length == 0 ? [] : lovForControlEffectiveness;
	let cmdbUpdateNeeded = [{objkey:"Yes", objval:"Yes"},{objkey:"No", objval:"No"}];

	if(type.length != 0){
		type = type.filter((itm) => {
			return itm.field1Key != "Standard"
		})
	}

	return {
		type: type,
		changeTiming: changeTiming,
		businessRisk: businessRisk,
		technicalRisk: technicalRisk,
		planType: planType,
		implementationAndChangeManagementGroup: implementationAndChangeManagementGroup,
		ChangeManagementGroup:ChangeManagementGroup,
		urgency,
		reasonForChange,
		// riskOccurrenceProbability,
		// riskImpact,
		// controlEffectiveness,
		riskAssessmentValue, loading:showLoader3.loading,
		cmdbUpdateNeeded
	};
};

ChangeForm = reduxForm({
	form: 'changeForm',
	//validate,
	enableReinitialize: true
})(ChangeForm);

const selector = formValueSelector('changeForm');
ChangeForm = connect((state)=> {
	let selectedType = selector(state,'type');
	// let riskOccurrenceVal = selector(state,'riskOccurrenceProbability');
	// let riskImpactVal = selector(state,'riskImpact');
	// let controlEffectivenesVal = selector(state,'controlEffectiveness');
	return{
		selectedType,
		// riskOccurrenceVal, riskImpactVal, controlEffectivenesVal,
		initialValues: {'type':25},
	}
})(ChangeForm);

export default connect(mapStateToProps, {
	getLovValues,
	// loadBreakFixSGAE,
	// loadBreakFixSGAEChange, 
	getRiskCalculated
})(ChangeForm);
