
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React,{createContext,useEffect, useState,useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";

export const MyContext = createContext("");

const MyContextProvider = (props) => {
    const dispatch = useDispatch();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const [closeBtnRef] = useState("closeButton");
    const [isOpen, setIsOpen] = useState(false);

    const keyDownHandler = (closeRef, editRef, globalSearchRef, saveObjRef) => {
      const keyRefMap = {
        m: closeRef,
        i: editRef,
        b: globalSearchRef,
        q: saveObjRef,
      };
    
      window.addEventListener("keydown", function (event) {
        if (event.ctrlKey) {
          const ref = keyRefMap[event.key];
          if (ref != null) {
            ref.focus();
          }
        }
      });
    }

    const onClickHandler = (hamburgerRef) => {
      const keyRefMap = {
        y: hamburgerRef,
      };
    
      window.addEventListener("keydown", function (event) {
        if (event.ctrlKey) {
          const ref = keyRefMap[event.key];
          if (ref != null) {
            ref.click();
          }
        }
      });
    };

    const modalOpen = (value) => {
      setIsOpen(value);
      const root = document.documentElement;
      root?.style.setProperty("--modalBackDrop", 0.5);
    };

    const shortcutKeyPopupHandler = (event) => {
      if (event && (event.ctrlKey && event.key) === '\\') {
        modalOpen(true);
      }
    };

    useEffect(() => {
      const handleKeyDown = (event) => {
        shortcutKeyPopupHandler(event);
      };  
      window.addEventListener("keydown", handleKeyDown);
  
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, []);

    const newTabPrevent = (event) => {
      if (event?.target?.classList.contains("ctrlKeyPrevent") && event?.ctrlKey && event?.key === "Enter"){
        event.preventDefault();
      }
      else {
        return false;
      }
    };

    useEffect(() => {
      window.addEventListener("keydown", newTabPrevent);
      return () => {
        window.removeEventListener("keydown", newTabPrevent);
      }
    }, [isOpen]);
  
  
    return (
      <MyContext.Provider value={{keyDownHandler, onClickHandler, newTabPrevent, shortcutKeyPopupHandler, modalOpen, isOpen}}>
        {props.children}
      </MyContext.Provider>
    );
  };

  export default MyContextProvider;