
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { IoClose, IoSearch } from "react-icons/io5";
import Pagination from "react-js-pagination";
import { Accordion, ButtonToolbar, Button, Container } from "react-bootstrap";
const Joi = require("joi");
import { getKnowledgeArticles } from "../../../../actions/spcmActions";
import { loadArticleResultsForSearchString } from "../../../../actions/article/articleAction";
import ListLoader from "../../../common/loaders/ListLoader";
import ArticleDetails from "../../checkout/rightSide/formFields/ArticleDetails";
import sanitizeHtml from "sanitize-html";
import {
  loadInsidedArticlesForSearch,
  emptyInsidedReducer,
  loadDiscourseArticlesForSearch,
  getArticleById,
} from "../../../../actions/knowledge/knowledgeAction";
import Cookies from "universal-cookie";
import { navigationHooks } from "../../../../helpers/NavigationHook";
import { openLinkInNewTab } from "../../../common/helper";
const cookies = new Cookies();
let username = cookies.get("gph");
if (username) username = username.replace("s:", "");
if (username) username = username.substring(0, username.lastIndexOf("."));
username = username.split("~");
username = username[32];
let isKnowledgeEnabled = cookies.get("gph");
if (isKnowledgeEnabled)
  isKnowledgeEnabled = isKnowledgeEnabled.replace("s:", "");
if (isKnowledgeEnabled)
  isKnowledgeEnabled = isKnowledgeEnabled.substring(
    0,
    isKnowledgeEnabled.lastIndexOf(".")
  );
let cookiesArray = isKnowledgeEnabled.split("~");
isKnowledgeEnabled = cookiesArray[30];
let discourseUrl = cookies.get("gph");
if (discourseUrl) discourseUrl = discourseUrl.replace("s:", "");
if (discourseUrl)
  discourseUrl = discourseUrl.substring(0, discourseUrl.lastIndexOf("."));
discourseUrl = discourseUrl.split("~");
discourseUrl = discourseUrl[50];
const spcmKnowledgeCall = process.env.SPCM_KNOWLEDGE_SEARCH;

class KgArticles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10,
      currentPage: 1,
      offering_id: null,
      searchString: "",
      searchStringPlaceHolder: "",
      articleDetails: null,
      insided_access_token: "",
      insidedError: false,
      showSerachlence: true,
      showLoaderState: false,
      showSearchMsg: false,
      requestForVar: "",
      activeIndex: null,
      activePage: 1,
      pageSize2: 10,
    };
    this.renderArticleHTML = this.renderArticleHTML.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.onPageSizeChange2 = this.onPageSizeChange2.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.getItemsBasedOnPagination = this.getItemsBasedOnPagination.bind(this);
    this.changeSearchTextField = this.changeSearchTextField.bind(this);
    this.changeSearchicone = this.changeSearchicone.bind(this);
    this.renderKnowledgeArticles = this.renderKnowledgeArticles.bind(this);
    this.showDescription = this.showDescription.bind(this);
    this.closeDescription = this.closeDescription.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.onTypeaheadClick = this.onTypeaheadClick.bind(this);
    this.dispatch = navigationHooks.dispatch;
  }

  componentDidMount() {
    this.props.getKnowledgeArticles(this.props.offeringId);
    this.setState({ offering_id: this.props.offeringId });
  }

  componentWillUnmount() {
    this.dispatch({ type: "RESET_DATA" });
    this.setState({ activeIndex: null });
  }

  componentWillMount() {
    const { articleDetails, getArticleById } = this.props;
    if (articleDetails && articleDetails.articleID) {
      getArticleById(articleDetails.articleID, username);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.seachDiscription !== nextProps.seachDiscription) {
      if (nextProps.seachDiscription == "") {
        this.props.emptyInsidedReducer();
        if (spcmKnowledgeCall == "true")
          this.props.loadArticleResultsForSearchString("");

        this.setState({ articleDetails: null });
        this.setState({ showLoaderState: false });
        this.setState({ showSearchMsg: false });
      }
      this.setState({ searchString: nextProps.seachDiscription });
      this.setState({ searchStringPlaceHolder: nextProps.seachDiscription });
      this.setState({ showSerachlence: true });
    }

    if (
      nextProps.isFetchingInsidedDetails &&
      nextProps.isFetchingInsidedDetails.isFetching !==
        this.props.isFetchingInsidedDetails.isFetching &&
      nextProps.isFetchingInsidedDetails.isFetching == false &&
      nextProps.isFetchingDetails &&
      nextProps.isFetchingDetails.isFetching !==
        this.props.isFetchingDetails.isFetching &&
      nextProps.isFetchingDetails.isFetching == false
    ) {
      this.setState({ showLoaderState: false });
    }
  }

  handleAccordionClick(index, knowledgeId, username) {
    this.setState({
      activeIndex: this.state.activeIndex === index ? null : index,
    });
    this.props.getArticleById(knowledgeId, username);
  }

  renderArticleHTML() {
    const { activeIndex } = this.state;
    const { attachedKnowledgeArticlesList } = this.props;
    const that = this;
    const allArticlesList = this.getItemsBasedOnPagination(
      attachedKnowledgeArticlesList
    );
    if (allArticlesList.length == 0) {
      return (
        <tr>
          <td colSpan={2} className="article-table-td-align-center">
            {that.props.translator["No Attached Knowledge References Available"]}
          </td>
        </tr>
      );
    }

    return (
      <Accordion defaultActiveKey={""} activeKey={this.state.activeIndex}>
        {allArticlesList.map((item, i) => {
          return (
            <Accordion.Item eventKey={i}>
              <Accordion.Header
                onClick={() =>
                  this.handleAccordionClick(i, item.knowledge_id, username)
                }
              >
                {this.props.renderLargeTextEllipse(item.knowledge_article)}
              </Accordion.Header>
              <Accordion.Body>
                {this.props.isFetchingGlobalKnowledgeDetails.isFetching ==
                false ? (
                  <div
                    className="KA-description"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(this.props.articleDetailsById, {
                        allowedAttributes: {
                          "*": ["style", "class", "dir", "bgcolor"],
                          table: ["border", "cellpadding", "cellspacing"],
                          th: ["scope"],
                        },
                      }),
                    }}
                  />
                ) : (
                  <ListLoader />
                )}
                <ButtonToolbar className="justify-content-between margin-t-10">
                  <Button
                    size="xs"
                    onClick={() => {
                      this.props.downloadFile(
                        item.type,
                        item["article_url"],
                        item["id"]
                      );
                    }}
                  >
                    <i className="fa fa-location-arrow margin-r-5"></i>
                    Read full article
                  </Button>
                </ButtonToolbar>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    );
  }

  renderKnowledgeArticles(event) {
    let searchArtical = this.state.searchString.trim();
    if (searchArtical) {
      //this.setState({showSerachlence:false})
      this.setState({ showLoaderState: true });
      this.setState({ showSearchMsg: true });
      if (this.state.searchString) {
        this.setState({ showSerachlence: false });
      } else {
        this.setState({ showSerachlence: true });
      }

      if (
        this.state.searchString &&
        (isKnowledgeEnabled == true || isKnowledgeEnabled == "true")
      ) {
        this.props.loadDiscourseArticlesForSearch(
          this.state.searchString,
          username
        );
        if (spcmKnowledgeCall == "true")
          this.props.loadArticleResultsForSearchString(this.state.searchString);
      } else if (
        this.state.searchString &&
        (isKnowledgeEnabled == true || isKnowledgeEnabled == "false")
      ) {
        if (spcmKnowledgeCall == "true")
          this.props.loadArticleResultsForSearchString(this.state.searchString);
      } else {
        this.props.emptyInsidedReducer();
        if (spcmKnowledgeCall == "true")
          this.props.loadArticleResultsForSearchString("");
      }
    } else {
      //this.setState({showErrorMessahe:'Show err'});
    }
  }

  getLeftSideListHTML(state) {
    let newInsidedArray = this.props.insidedResultsList.topics;
    let combinedArticlesArray =
      this.props.articleResultsList.concat(newInsidedArray);

    combinedArticlesArray.sort((article1, article2) =>
      article1.title.localeCompare(article2.title)
    );

    if (combinedArticlesArray.length === 0) {
      return (
        <div className="f-size-12">
          <Table
            bordered
            condensed
            hover
            responsive
            className="tableView sortImg"
          >
            <tbody>
              <tr>
                <td colSpan={4} className="article-table-td-align-center">
                  {
                    this.props.translator[
                      "No Published Knowledge References Available..."
                    ]
                  }
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      );
    }

    const { activePage, pageSize2 } = this.state;
    const indexOfLastItem = activePage * pageSize2;
    const indexOfFirstItem = indexOfLastItem - pageSize2;
    const currentItems = combinedArticlesArray.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    return currentItems.map((item, index) => {
      let type = "";
      if (item.KNOW_ID) {
        type = "SPCM";
        let description =
          item.EXTRACT_With_DOC_ID &&
          item.EXTRACT_With_DOC_ID.extract_array &&
          Array.isArray(item.EXTRACT_With_DOC_ID.extract_array) &&
          item.EXTRACT_With_DOC_ID.extract_array[0]
            ? item.EXTRACT_With_DOC_ID.extract_array[0]
            : "-";
        return (
          <li
            className={state && index === state.countNo ? "active" : ""}
            key={index}
          >
            <a
              role="button"
              href="javascript:void(0)"
              onClick={() =>
                this.showDescription(
                  item.title,
                  description,
                  item.slug + "/" + item.id,
                  type,
                  "",
                  ""
                )
              }
            >
              <span className="comInsd interLnk" />
              {item.title}
            </a>
          </li>
        );
      } else {
        let insidedClass;
        if (item.contentType === "article") {
          type = "Knowledge";
          insidedClass = "comInsd exterLnk";
        } else if (item.contentType === "conversation") {
          type = "conversation";
          insidedClass = "comInsd exterLnk";
        } else if (item.contentType === "question") {
          type = "Community";
          insidedClass = "comInsd community";
        }
        return (
          <li
            className={state && index === state.countNo ? "active" : ""}
            key={index}
          >
            <a
              role="button"
              href="javascript:void(0)"
              onClick={() => {
                this.showDescription(
                  item.title,
                  item.content,
                  item.slug + "/" + item.id,
                  type,
                  item.id,
                  item.slug
                );
              }}
            >
              <span
                className={"KA-icon exterLnk1"}
                style={{ backgroundPosition: "-2px 0px" }}
              />
              {item.title}
            </a>
          </li>
        );
      }
    });
  }

  showDescription(title, description, path, type, KA_ID, contentType) {
    let articleDetails = {
      knowledgeArticle: title,
      type,
      knowledgeDescription: description,
      articleUrl: path,
      articleID: KA_ID,
      contentType: contentType,
    };
    this.setState({ articleDetails: articleDetails });
  }

  closeDescription() {
    this.setState({ articleDetails: null });
  }

  changeSearchicone() {
    this.setState({ showSerachlence: true });
  }

  onPageSizeChange(e) {
    this.setState({ pageSize: e.target.value, currentPage: 1 });
  }

  onPageChange(value) {
    this.setState({ currentPage: value });
    this.setState({ activeIndex: null });
  }

  getItemsBasedOnPagination(totalItems) {
    try {
      const { currentPage, pageSize } = this.state;
      const startIndex =
        Number(currentPage) * Number(pageSize) - Number(pageSize);
      return totalItems.slice(startIndex, startIndex + Number(pageSize));
    } catch (e) {
      return totalItems || [];
    }
  }
  changeSearchTextField(e) {
    this.setState({ showSearchMsg: false });
    if (e.target.value == "") {
      this.props.emptyInsidedReducer();
      if (spcmKnowledgeCall == "true")
        this.props.loadArticleResultsForSearchString("");
    }
    this.setState({ showSerachlence: true });
    this.setState({ showLoaderState: false });
    this.setState({ searchString: e.target.value });
  }

  onTypeaheadClick() {
    this.setState({ showSearchMsg: false });
    this.setState({ showLoaderState: false });
    this.setState({activePage : 1})
    this.setState({pageSize2 : 10})
    this.setState({currentPage : 1})
    this.setState({pageSize : 10})
    if (this.state.searchString) {
      this.props.emptyInsidedReducer();
      if (spcmKnowledgeCall == "true")
        this.props.loadArticleResultsForSearchString("");
      this.setState({ showSerachlence: true });
      this.setState({ searchString: "" });
    }
  }

  downloadFile(slug) {
    if (this.state.articleDetails.type === "SPCM") {
      if (!this.state.articleDetails.articleUrl) {
        return;
      }
      let link = document.createElement("a");
      link.href = this.state.articleDetails.articleUrl; //'http://www.africau.edu/images/default/sample.pdf';
      link.id = "temp_downloadfile";
      link.target = "_self";
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        link.remove();
      }, 1000000);
    } else {
      const ssoUrl = `${discourseUrl}/t/${slug}`;
      openLinkInNewTab(ssoUrl, "New Tab");
    }
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
  }

  onPageSizeChange2(e) {
    const newSize = parseInt(e.target.value, 10);
    this.setState({ pageSize2: newSize, activePage: 1 }); 
  }

  render() {
    const { pageSize, pageSize2, currentPage } = this.state;
    if (this.state.articleDetails) {
      return (
        <div className="kaArticlesDetails">
          <ArticleDetails
            articleDetails={this.state.articleDetails}
            closeDescription={this.closeDescription}
            downloadFile={this.downloadFile}
            translator={this.props.translator}
          />
        </div>
      );
    }
    return (
      <div className="margin-t-20 knowledge-search-section attached-articles-section">
        <div className="margin-t-20 offH2">{this.props.translator["Knowledge Articles"]}</div>

        <div className="inpbtnBox">
          <input
            type="text"
            name="title"
            className="form-control hide36"
            value={this.state.searchString}
            placeholder={this.props.translator['What are you looking for...']}
            onChange={this.changeSearchTextField}
            onKeyPress={this.changeSearchicone}
            disabled={
              this.props.isFetchingDetails.isFetching ||
              this.props.isFetchingInsidedDetails.isFetching
            }
          />
          {this.state.showSerachlence ? (
            this.state.searchString.trim().length == 0 ||
            this.state.searchString.trim().length >= 3 ? (
              <Button
                type="button"
                className="myBtn"
                onClick={(event) => {
                  this.renderKnowledgeArticles(event);
                }}
                disabled={
                  this.props.isFetchingDetails.isFetching ||
                  this.props.isFetchingInsidedDetails.isFetching
                }
              >
                <IoSearch />
              </Button>
            ) : (
              <span onClick={this.onTypeaheadClick}>
                <Button
                  type="button"
                  className="myBtn"
                  disabled={
                    this.props.isFetchingDetails.isFetching ||
                    this.props.isFetchingInsidedDetails.isFetching
                  }
                >
                  <i className="fa fa-close"></i>
                </Button>
              </span>
            )
          ) : (
            <span onClick={this.onTypeaheadClick}>
              <Button
                type="button"
                className="myBtn"
                disabled={
                  this.props.isFetchingDetails.isFetching ||
                  this.props.isFetchingInsidedDetails.isFetching
                }
              >
                <i className="fa fa-close"></i>
              </Button>
            </span>
          )}
        </div>
        <div className="margin-t-10 margin-b-10">
          {(this.props.articleResultsList &&
            this.props.articleResultsList.length) ||
          (this.props.insidedResultsList.topics &&
            this.props.insidedResultsList.topics.length) ? (
            <div className="kaArticles">
              <ul className="knowledgeDiv knledbox margin-t-5">
                {this.getLeftSideListHTML(this.state)}
              </ul>
                <div className="margin-t-20 margin-b-5 padding-r-10 padding-l-10 nBotPagina">
                    <div className="nShow">
                      <Form.Label className="margin-r-5 display-inline-block widthAuto">
                        {"Show"}
                      </Form.Label>
                      <Form.Select
                        className="display-inline-block widthAuto"
                        aria-label="select"
                        onChange={(e) => this.onPageSizeChange2(e)}
                        value={pageSize2}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={9999}>All</option>
                      </Form.Select>
                    </div>
                    <nav aria-label="Pagination" className="display-inline-block">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.pageSize2}
                        totalItemsCount={
                          this.props.articleResultsList.length +
                          this.props.insidedResultsList.topics.length
                        }
                        pageRangeDisplayed={this.props.windowSize <= 992 ? 5 : 1}
                        onChange={this.handlePageChange.bind(this)}
                      />
                    </nav>
                </div>
            </div>
          ) : this.state.showSearchMsg &&
            !(
              (this.props.isFetchingDetails &&
                this.props.isFetchingDetails.isFetching) ||
              (this.props.isFetchingInsidedDetails &&
                this.props.isFetchingInsidedDetails.isFetching)
            ) ? (
            <div className="f-size-12">
              <Table
                bordered
                condensed
                hover
                responsive
                className="tableView sortImg"
              >
                <tbody>
                  <tr>
                    <td colSpan={4} className="article-table-td-align-center">
                      {"Your search did not match any articles"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : null}
        </div>

        <div>
          <div>
            {((this.props.isFetchingDetails &&
              this.props.isFetchingDetails.isFetching) ||
              (this.props.isFetchingInsidedDetails &&
                this.props.isFetchingInsidedDetails.isFetching)) &&
            this.state.showLoaderState ? (
              <div className="margin-t-0 margin-b-20">
                <ListLoader />
              </div>
            ) : (
              ""
            )}
          </div>
          {!this.props.insidedResultsList?.topics?.length && (
            <div>
              <Accordion className="margin-t-20" defaultActiveKey="0">
                {this.renderArticleHTML()}
              </Accordion>
              {this.props.attachedKnowledgeArticlesList.length > 0 && (
                  <div className="margin-t-20 margin-b-5 padding-r-10 padding-l-10 nBotPagina">
                      <div className="nShow">
                        <Form.Label className="margin-r-5 display-inline-block widthAuto">
                      {["Show"]}
                        </Form.Label>
                        <Form.Select
                          componentClass="select"
                          aria-label="select"
                          className="display-inline-block widthAuto"
                          onChange={(e) => this.onPageSizeChange(e)}
                          value={pageSize}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={9999}>All</option>
                        </Form.Select>
                      </div>
                    <nav aria-label="Pagination" className="display-inline-block">
                        <Pagination
                          activePage={currentPage}
                          itemsCountPerPage={pageSize}
                          totalItemsCount={
                            this.props.attachedKnowledgeArticlesList.length
                          }
                          onChange={this.onPageChange}
                          pageRangeDisplayed={1}
                        />
                    </nav>
                  </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  attachedKnowledgeArticlesList,
  spcmReducer,
  isFetchingDetails,
  isFetchingDetails_KA,
  isFetchingInsidedDetails,
  insidedResultsList,
  articleResultsList,
  articleDetails,
  isFetchingGlobalKnowledgeDetails,
  windowSize,
}) => {
  if (articleResultsList && Array.isArray(articleResultsList))
    for (let i = 0; i < articleResultsList.length; i++) {
      let j = i + 1;
      articleResultsList[i].KNOW_ID = j;
    }
  return {
    attachedKnowledgeArticlesList,
    translator: spcmReducer.tr,
    isFetchingDetails,
    isFetchingDetails_KA,
    isFetchingInsidedDetails,
    insidedResultsList,
    loadDiscourseArticlesForSearch,
    articleResultsList,
    articleDetailsById: articleDetails,
    isFetchingGlobalKnowledgeDetails,
    windowSize : windowSize.width,
  };
};

export default connect(mapStateToProps, {
  getKnowledgeArticles,
  loadArticleResultsForSearchString,
  emptyInsidedReducer,
  loadInsidedArticlesForSearch,
  loadDiscourseArticlesForSearch,
  getArticleById,
})(KgArticles);
