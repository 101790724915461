
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Table from 'react-bootstrap/Table';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isUndefined } from "lodash";

import ListLoader from '../../../common/loaders/ListLoader';
import {IoClose} from "react-icons/io5";
import { loadPirLogDetails } from '../../../../actions/changeManagement/changeManagementAction.js';

class popoverPIRLog extends React.Component {
    componentWillMount() {
        let changeId = this.props.changeId;
        this.props.loadPirLogDetails(changeId);
    }
    renderPIRDataLog(data){
		if(data.length == 0){
 		return (
 				<div>{this.props.translator['Data not available.']}</div>
 			);
		 } 
		else{
			return(
				<Table responsive striped bordered condensed hover className="main-menu">
					<thead>
						<tr>
							<th width="50%">{this.props.translator['PIR Form Questions']}</th>
							<th>{this.props.translator['Response']}</th>
						</tr>
					</thead>
					<tbody>
						{this.renderList(data)}
					</tbody>
				</Table>
		  	);
		}
    }
    renderList(data){
        let arrData = [];
        if(!isUndefined(data[0])){
            arrData = Object.entries(data[0]);
        }
        if(!data){
			return(
					null
			);
		}
		return arrData.map((item,i)=>{
           let questionId = item[0];
           let ques = '';
            switch (questionId) {
                case 'incRaised':
                    ques = 'Any Incidents raised due to Change Implementation ?';
                    break;
                case 'chgOnSchedule':
                    ques = 'Was the Change implemented on Schedule?';
                    break;
                case 'impact':
                    ques = 'Did the Change impact any other services or Cis which was not in the plan?';
                    break;
                case 'adqMitigated':
                    ques = 'Were realized risks adequately mitigated?';
                    break;
                case 'newRisks':
                    ques = 'Any new risks observed?';
                    break;
                case 'detailsOfRisks':
                        ques = 'Please provide the details of the new risks observed';
                        ques = data && data[0].newRisks == "Yes" ? ques : "";  
                    break;
                case 'newChangeReq':
                    ques = 'Any new change were required during implementation?';
                    break;
                case 'closureCodes':
                    ques = 'Closure Code';
                    break;
                default:                    
                    break;
            }    
        if(ques){
			return(
				<tr>
					<td>{this.props.translator[ques]}</td>
					<td>{item[1]}</td>
				</tr>
			);}
		});
    }
    render(){
        return(
            <div> 
            {this.props.loader ? 
            <div>
                <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                    <ul>
                        <li>
                        <button
                            type='button'
                            title="Minimize the right panel"
                            bsClass=""
                            bsStyle=""
                            className="closerightPanelBtn"
                            onClick={() => {
                            this.props.rightEditPanel(false);
                            this.props.changeActionView("");
                            }}
                        >
                            <IoClose/>
                        </button>
                        </li>
                    </ul>
                </div>
                <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['PIR Details']}</div></div>
                <div className='rBoxGap'>
                    <ListLoader /> 
                </div>
            </div>
            :
            <div>
                <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                    <ul>
                        <li>
                        <button
                            type='button'
                            title="Minimize the right panel"
                            bsClass=""
                            bsStyle=""
                            className="closerightPanelBtn"
                            onClick={() => {
                            this.props.rightEditPanel(false);
                            this.props.changeActionView("");
                            }}
                        >
                            <IoClose/>
                        </button>
                        </li>
                    </ul>
                </div>
                <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['PIR Details']}</div></div>
                <div className='rBoxGap'>
                    <div>{this.renderPIRDataLog(this.props.loadPirData)}</div>
                </div>
            </div>
            }
            </div>
        )
    }
}
export function mapStateToProps({loadPirData, showLoader2}) {
    return {loadPirData, loader:showLoader2.loading};
} 
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ loadPirLogDetails }, dispatch);
}
export default connect( mapStateToProps,mapDispatchToProps)(popoverPIRLog);