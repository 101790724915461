
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import axios from 'axios';
import {connect} from 'react-redux';

import { GLOBAL } from "_Globals";

import {loadChangeEditData} from '../../../../actions/changeManagement/changeManagementAction.js';
import {loadBreakFixAuditLogDetails} from '../../../../actions/breakFix/breakFixAuditLogAction';
import { loadStatusBasedHamburgerOptions,loadHamburger  } from '../../../../actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '../../../../actions/common/commonApisActions.js';
import { loadBreakFixActivityLogDetails } from '../../../../actions/breakFix/breakFixActivityLogAction';

import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
import configureStore from '../../../../store/configureStore';
import Swal from 'sweetalert2';
import { changeStatusMapping } from '../../../common/helper.js';

const store = configureStore();
const api = axios.create({
  headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

class ReSubmitReview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          referBackTextValue: '',
          onSuccessMessage:'',
          submitLoader:false
        }
        this.updateReferBackTextField = this.updateReferBackTextField.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }
    updateReferBackTextField(value) {
        this.setState({ referBackTextValue: value });
    }
    submitForm(editChangeInitialData) {
        this.state.referBackTextValue=this.state.referBackTextValue.trim().replace(/['"]/g, "");
        if (this.state.referBackTextValue == "" ) {
          Swal.fire("Remarks field cannot be empty");
        }
        else {
          let ref = this;
          this.setState({ submitLoader: true });
          ref.props.loadHamburger();
          axios.patch(GLOBAL.changeEditUrl + editChangeInitialData.changeId, {
            "status": "35",
            "systemStartDateRequired": "N",
            "systemEndDateRequired": "N",
            "modifiedBy":"",
            "modifiedByName":""
          }).then(function (response) {
            store.dispatch({ type: 'CHANGE_EDIT_DATA_LOAD_SUCCESS', editChangeInitialData: {} });
            ref.props.loadChangeEditData(editChangeInitialData.changeId);
            ref.props.loadStatusBasedHamburgerOptions('ChangeManagement', response.data.status, response.data.changeId);
            axios.post(GLOBAL.breakFixActivityLogPostUrl, {
              "itemId": editChangeInitialData.changeId,
              "description": ref.state.referBackTextValue.replace(/\n/g, "<br/>"),
              "isInternal": "N",
              "createdOn": "",
              "createdBy": "",
              "module": "ChangeManagement",
              "createdByName": "",
              "isReorder": "Y"
            }).then((response) => {
                if (response.status = '201') {
                  ref.setState({ onSuccessMessage: "Re-submit for Review done Successfully.", submitLoader: false });
                }
              });
          }).catch(function (error) {
            ref.props.loadHamburger();
          });
        }
    }
    render(){
        return (
            this.props.editChangeInitialData.statusId == changeStatusMapping['Referred Back'] ?
              <div>
                <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                    <ul>
                        <li>
                        <button
                            type='button'
                            title="Minimize the right panel"
                            bsClass=""
                            bsStyle=""
                            className="closerightPanelBtn"
                            onClick={() => {
                            this.props.rightEditPanel(false);
                            this.props.changeActionView("");
                            }}
                        >
                            <IoClose/>
                        </button>
                        </li>
                    </ul>
                </div>
                <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Remarks']}</div></div>
                <div className='rBoxGap'>
                <Form.Group className='form-group'>
                  <Form.Control as="textarea" value={this.state.referBackTextValue} rows="3" maxLength="1000" onChange={event => { this.updateReferBackTextField(event.target.value); }} />
                </Form.Group>
                <ButtonToolbar>
                  <Button bsSize="small" bsStyle="primary" disabled={this.state.submitLoader} onClick={() => { this.submitForm(this.props.editChangeInitialData); }}>Submit {this.state.submitLoader?<ImSpinner6 className="icn-spinner"/>:''}</Button>
                </ButtonToolbar>
                </div>
              </div> 
              :
              <div>
                {this.props.changeActionView('processHierarchy')}
              </div>
          );
    }
}
const mapStateToProps = ({editChangeInitialData}) => {
    return {editChangeInitialData};
};
export default connect(mapStateToProps, {loadChangeEditData,loadBreakFixAuditLogDetails,loadStatusBasedHamburgerOptions,loadTimelineData,loadHamburger,loadBreakFixActivityLogDetails})(ReSubmitReview);