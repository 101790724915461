
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import _ from 'lodash';
// import {GLOBAL} from '../../components/Globals';
import {GLOBAL} from '_Globals';
import moment from 'moment';
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';

import { updateWIBSearchData as updatePayload } from '../../utils/common';

const GROUP_LIST_URL = GLOBAL.breakFixGroupListURL;
const FLFLMT_GROUP_LIST_URL = GLOBAL.itemGroupListURL;
const TASK_GROUP_LIST_URL = GLOBAL.taskGroupListURL;
const PRIORITY_LIST_URL = GLOBAL.breakFixPriorityListURL;
const INV_GROUP_LIST_URL = GLOBAL.investigationGroupListURL;
const CHANGE_GROUP_LIST_URL = GLOBAL.changeGroupListURL;

import Cookies from 'universal-cookie';
const cookies = new Cookies();
let defaultDateRangeValue = cookies.get('gph');
if (defaultDateRangeValue) defaultDateRangeValue = defaultDateRangeValue.replace('s:', '');
if (defaultDateRangeValue) defaultDateRangeValue = defaultDateRangeValue.substring(0, defaultDateRangeValue.lastIndexOf('.'));
defaultDateRangeValue = defaultDateRangeValue.split("~");
defaultDateRangeValue = defaultDateRangeValue[53];

let companyId = cookies.get('gph');
if (companyId) companyId = companyId.replace('s:', '');
if (companyId) companyId = companyId.substring(0, companyId.lastIndexOf('.'));
companyId = companyId.split("~");
companyId = companyId[48];


const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

//load modules from permissionmatrix
export function loadModuleSuccess(moduleList) {
	return {
		type: 'LOAD_MODULES_SUCCESS',
		moduleList
	};
}

export function loadModuleList() {
	//console.log(searchBy);
	return (dispatch) => {
		api.get(GLOBAL.permittedModulesGetAction)
			.then((moduleList) => {
				if (!moduleList) {
					throw Error(moduleList.statusText);
				}
				return moduleList;
			})
			.then((moduleList) => {
				dispatch(loadModuleSuccess(moduleList))
			})
			.catch((err) => {
				console.log(err);
			});
	};
}
//
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
export function loadBreakFixQuickViewSuccess(quickViewDetails) {
	return {
		type: 'LOAD_BREAKFIXQUICKVIEW_SUCCESS',
		quickViewDetails
	};
}
export function clearBreakfixData(){
	const data = {data: [], meta: {}};
	return dispatch => dispatch(loadBreakFixQuickViewSuccess(data));
}
export function clearFulfillmentData(){
	const data = {data: [], meta: {}};
	return dispatch => dispatch(loadFulfillmentListSuccess(data));
}

export function loadBreakFixSlaData(payload) {
	return {
		type: 'LOAD_BREAKFIX_GRAPH_SLA',
		payload
	};
}

export function loadBreakFixQuickViewFailure(errorResponse) {
	return {
		type: 'LOAD_BREAKFIXQUICKVIEW_FAILURE',
		errorResponse
	};
}

function setWibListFilterCriteria(obj = {}){
	// console.log('setWibListFilterCriteria obj - ', obj);
	return {
		type: 'LOAD_WIB_LIST_PAYLOAD',
		payload: obj['payload']
	}
}

export function loadBreakFixQuickView(searchParams,statusInput,priorityInput,spGroupInput, spUserInput = [], criticalityInput=[], cimgroupsInput=[],isWIBGlobalSearchEnabled=false, isBacked=false) {
	  console.log('****************************', {searchParams,statusInput,priorityInput,spGroupInput, spUserInput});
	let status=statusInput.toString();
	let priority=priorityInput.toString();
	let spGroup=spGroupInput.toString();
	let spUser=spUserInput.toString();
	let criticality=criticalityInput.toString();
	let cimGroups=cimgroupsInput.toString();
	let searchValue=[];
	let searchKey=[];
	let newSearchPara = {searchParams, statusInput, priorityInput, spGroupInput, spUserInput , criticalityInput, cimgroupsInput, isWIBGlobalSearchEnabled};

	// if(searchParams.searchByList=="" && searchParams.multipleValueList==""){
	// 	searchParams.searchByList="status";
	// 	searchParams.multipleValueList='10,20,15';
	// 	searchParams.patternList="equal";
	// }
	if (status != "") {
		searchValue.push(status);
		searchKey.push("status");
	}
	if (priority != "") {
		searchValue.push(priority);
		searchKey.push("priorityId");
	}
	if (spGroup != "") {
		searchValue.push(spGroup);
		searchKey.push("spGroupid");
	}
	if (spUser != "") {
		searchValue.push(spUser);
		searchKey.push("spUserid");
	}
	if(criticality !== ""){
		searchValue.push(criticality);
		searchKey.push("criticality");
	}
	if(cimGroups !== ""){
        searchValue.push(cimGroups);
		searchKey.push("cimGroup");
	}
	if (searchKey.length != 0 && !isBacked) {
		searchParams.searchByList = searchParams.searchByList != "" ? searchParams.searchByList + "," + searchKey.join(",") : searchKey.join(",");
		searchParams.multipleValueList = searchParams.multipleValueList != "" ? searchParams.multipleValueList + "|" + searchValue.join("|") : searchValue.join("|");
		searchParams.patternList = searchParams.patternList != "" ? searchParams.patternList : 'in';
	}

	if(!isWIBGlobalSearchEnabled && searchParams){
		let searchByList = searchParams.searchByList.split(',');
		// let multipleValueList = searchParams.multipleValueList.split('|');
		// let patternList = searchParams.patternList.split(',');
		if(!searchByList.includes('createdOn')){
			// let days = 29;
			let days = (defaultDateRangeValue && !isNaN(defaultDateRangeValue)) ? (parseInt(defaultDateRangeValue,10) - 1) : 29;
			searchByList.unshift('createdOn');
			searchParams.searchByList = searchByList.join(",");
			let value = [];
			value.push(moment().subtract(days, 'days').format("MM-DD-YYYY"), moment().format("MM-DD-YYYY"));
			searchParams.multipleValueList = (searchParams.multipleValueList) ? (value.join("&") + "|" + searchParams.multipleValueList) : value.join("&");
			searchParams.patternList = (searchParams.patternList) ? ("between" + "," + searchParams.patternList) : "between";
		}
	}

	return (dispatch) => {

		let listPayloadArr = [ (searchParams), (statusInput || ''), (priorityInput || ''), (spGroupInput || ''), (spUserInput || []), (criticalityInput || []), (cimgroupsInput || []), (isWIBGlobalSearchEnabled || false), (isBacked || false) ];
		let listPayloadObj = { module: 'incident', payload: listPayloadArr };
		dispatch({ type: 'RESET_WIB_LIST_PAYLOAD' });
		setTimeout(() => {
			dispatch(setWibListFilterCriteria(listPayloadObj));
		}, 500);


		dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });
		// searchParams.sortBy = 'reportedOn';
		// searchParams.orderBy = 'desc';
		dispatch({type:"WIB_SEARCH_PARAMS", searchParams:{module:"incident",searchParams:newSearchPara}});
		const payload = updatePayload(searchParams, 'incident', 'wib');

		//let params = {};
		// if(!_.isEmpty(searchParams)) {params = {params:searchParams};} //lodash isEmpty checks for nulls too

		axios.post(GLOBAL.breakFixQuickViewURL, payload)
			.then((quickViewDetails) => {
				if (!quickViewDetails) {
					//dispatch(loadBreakFixFailure(breakFix.statusText))
					throw Error(quickViewDetails.statusText);
				}
				return quickViewDetails;
			})
			.then((quickViewDetails) => {
				dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
				dispatch(loadBreakFixQuickViewSuccess(quickViewDetails.data));
				dispatch(loadBreakFixSlaData(quickViewDetails.data.SlaData));
			})
			.catch((err) => {
			});
		//console.log("not calling loadBreakFixQuickView");
	};
}

export function loadBreakFixQuickViewSort(searchParams) {
	return (dispatch) => {
		dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });
		//searchParams.sortBy = 'reportedOn';
		//searchParams.orderBy = 'desc';
		// let params = {};
		// if(!_.isEmpty(searchParams)) {params = {params:searchParams};} //lodash isEmpty checks for nulls too

		axios.post(GLOBAL.breakFixQuickViewURL, searchParams)
			.then((quickViewDetails) => {
				if (!quickViewDetails) {
					//dispatch(loadBreakFixFailure(breakFix.statusText))
					throw Error(quickViewDetails.statusText);
				}
				return quickViewDetails;
			})
			.then((quickViewDetails) => {
				dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
				dispatch(loadBreakFixQuickViewSuccess(quickViewDetails.data));
				dispatch(loadBreakFixSlaData(quickViewDetails.data.SlaData));
			})
			.catch((err) => {
				console.log(err);
			});
		//console.log("not calling loadBreakFixQuickView");
	};
}


export function loadBreakFixQuickViewByOptions(sortBy) {
	return (dispatch) => {
		//alert("edit"+breakFixUrl+breakFixId);
		let params = {};
		//   console.log(sortBy);
		//  console.log(sortBy !== 'breakfixId');
		if (sortBy !== 'breakfixId') { params = { params: { sortBy: sortBy } }; }
		axios.get(GLOBAL.breakfixQuickViewActionSortByOrderByURL, params)
			.then((quickViewDetails) => {
				if (!quickViewDetails) {
					//dispatch(loadBreakFixFailure(breakFix.statusText))
					throw Error(quickViewDetails.statusText);
				}
				return quickViewDetails;
			})
			.then((quickViewDetails) => {
				dispatch(loadBreakFixQuickViewSuccess(quickViewDetails));
			})
			.catch((err) => {
				console.log(err);
			});
		//console.log("not calling loadBreakFixQuickView");

	};
}

export function loadBreakFixQuickViewByGroupFilter(selectedgroups) {
	return (dispatch) => {
		let params = {};
		//  console.log(selectedgroups);
		if (!_.isEmpty(selectedgroups)) { params = { params: { spGroupid: selectedgroups.join() } }; }
		axios.get(GLOBAL.breakfixQuickViewActionForGroupURL, params)
			.then((quickViewDetails) => {
				if (!quickViewDetails) {
					//dispatch(loadBreakFixFailure(breakFix.statusText))
					throw Error(quickViewDetails.statusText);
				}
				return quickViewDetails;
			})
			.then((quickViewDetails) => {
				dispatch(loadBreakFixQuickViewSuccess(quickViewDetails));
			})
			.catch((err) => {
				console.log(err);
			});
		//console.log("not calling loadBreakFixQuickView");

	};
}

export function loadFulfillmentListSuccess(fulFillmentList) {
	return {
		type: 'LOAD_FULFILLMENTLIST_SUCCESS',
		fulFillmentList
	};
}

export function loadFulfillmentListFailure(errorResponse) {
	return {
		type: 'LOAD_FULFILLMENTLIST_FAILURE',
		errorResponse
	};
}
export function loadFullfilmentSlaData(payload) {
	return {
		type: 'LOAD_FULLFILMENT_GRAPH_SLA',
		payload
	};
}
export function loadFulfillmentList(searchParams,status,fulGroup,fulUser,isWIBGlobalSearchEnabled=false, isBacked=false) {
	let newSearchPara = {searchParams,status,fulGroup,fulUser,isWIBGlobalSearchEnabled};
	let statusValue = status!=undefined?status.toString():status;
    let groupValue = fulGroup!=undefined?fulGroup.toString():fulGroup;
	let userValue  = fulUser!=undefined?fulUser.toString():fulUser;
    let searchKey=[];
    let searchValue=[];
	// if(searchParams.searchByList=="" && searchParams.multipleValueList==""){
	// 	searchParams.searchByList="itemStatus";
	// 	searchParams.multipleValueList="70,100";
	// 	searchParams.patternList="equal";
	// }
    if (status != "" && status !=undefined) {
        searchKey.push("itemStatus");
        searchValue.push(statusValue);
		// searchParams.patternList=searchParams.patternList!=""?searchParams.patternList+","+"in":"in";
    }
    if (fulGroup != "" && fulGroup !=undefined) {
        searchKey.push("asgGroupid");
        searchValue.push(groupValue);
		// searchParams.patternList=searchParams.patternList!=""?searchParams.patternList:"";
    }
	if(fulUser!="" && fulUser !=undefined)
	{
		searchKey.push("asgUserid");
        searchValue.push(userValue);
	}
	if (searchKey.length != 0) {
		searchParams.searchByList = searchParams.searchByList != "" ? searchParams.searchByList + "," + searchKey.join(",") : searchKey.join(",");
		searchParams.multipleValueList = searchParams.multipleValueList != "" ? searchParams.multipleValueList + "|" + searchValue.join("|") : searchValue.join("|");
		searchParams.patternList=searchParams.patternList!="" ? searchParams.patternList:"in";
	}

	if(!isWIBGlobalSearchEnabled && searchParams){
		let searchByList = searchParams.searchByList.split(',');
		if(!searchByList.includes('createdOn')){
			let days = (defaultDateRangeValue && !isNaN(defaultDateRangeValue)) ? (parseInt(defaultDateRangeValue,10) - 1) : 29;
			searchByList.unshift('createdOn');
			searchParams.searchByList = searchByList.join(",");
			let value = [];
			value.push(moment().subtract(days, 'days').format("MM-DD-YYYY"), moment().format("MM-DD-YYYY"));
			searchParams.multipleValueList = (searchParams.multipleValueList) ? (value.join("&") + "|" + searchParams.multipleValueList) : value.join("&");
			searchParams.patternList = (searchParams.patternList) ? ("between" + "," + searchParams.patternList) : "between";
		}
	}

	// console.log('searchParams - ', {searchParams});

	// debugger
	return (dispatch) => {

		searchParams,status,fulGroup,fulUser,isWIBGlobalSearchEnabled=false
		let listPayloadArr = [ (searchParams), (status || ''), (fulGroup || ''), (fulUser || ''), (isWIBGlobalSearchEnabled), (isBacked) ];
		let listPayloadObj = { module: 'fulfillment', payload: listPayloadArr };
		dispatch({ type: 'RESET_WIB_LIST_PAYLOAD' });
		setTimeout(() => {
			dispatch(setWibListFilterCriteria(listPayloadObj));
		}, 500);

		dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });
		// searchParams.sortBy = 'createdOn';
		// searchParams.orderBy = 'desc';
		searchParams.isNestedRequired = "N";

		const payload = updatePayload(searchParams, 'fulfillment', 'wib');
		dispatch({type:"WIB_SEARCH_PARAMS", searchParams:{module:"fulfillment",searchParams:newSearchPara}});
		// let params = {};
		// if(!_.isEmpty(searchParams)) {params = {params:searchParams};} //lodash isEmpty checks for nulls too

		api.post(GLOBAL.fulfillmentListURL, payload)
			.then((fulFillmentList) => {
				if (!fulFillmentList) {
					//dispatch(loadBreakFixFailure(breakFix.statusText))
					throw Error(fulFillmentList.statusText);
				}
				return fulFillmentList;
			})
			.then((fulFillmentList) => {
				dispatch(loadFulfillmentListSuccess(fulFillmentList.data));
				dispatch(loadFullfilmentSlaData(fulFillmentList.data.SlaData));
				dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
			})
			.catch((err) => {
				console.log(err);
			});
		//console.log("not calling loadBreakFixQuickView");

	};
}
///  Code
export function loadFulfillmentListComponent(FullFillmentId) {
	// debugger
	let EmptyComponent = ["No COmponent is Available"];
	var str = {};
	str.searchByList = "itemId";
	str.valueList = FullFillmentId + "";
	str = JSON.stringify(str);
	return (dispatch) => {
		api.post(GLOBAL.fulfillmentListURL, str)
			.then((data) => {
				dispatch(loadFulfillmentListcomponet(data.data.data[0].components));
				//dispatch(loadBreakFixFailure(breakFix.statusText))
			})
			.catch((err) => {
				console.log('Error in fetchGroupList');
				console.log(err);
			});
	};

}
export function loadFulfillmentListcomponet(payload) {
	return {
		type: 'SET_SELECTED_COMPONENT',
		payload
	};
}
////End
export function loadFulfillmentListSort(searchParams) {
	// debugger
	return (dispatch) => {
		console.log("searchParams");
		console.log(searchParams);
		dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });
		//searchParams.sortBy = 'createdOn';
		//searchParams.orderBy = 'desc';
		if (!_.isEmpty(searchParams)) {
			console.log("true")
		}
		//  let params = {};
		// if(!_.isEmpty(searchParams)) {params = {params:searchParams};} //lodash isEmpty checks for nulls too

		api.post(GLOBAL.fulfillmentListURL, searchParams)
			.then((fulFillmentList) => {
				if (!fulFillmentList) {
					//dispatch(loadBreakFixFailure(breakFix.statusText))
					throw Error(fulFillmentList.statusText);
				}
				return fulFillmentList;
			})
			.then((fulFillmentList) => {
				dispatch(loadFulfillmentListSuccess(fulFillmentList.data));
				dispatch(loadFullfilmentSlaData(fulFillmentList.data.SlaData));
				dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
			})
			.catch((err) => {
				console.log(err);
			});
		//console.log("not calling loadBreakFixQuickView");

	};
}


export function fetchGroupList() {
	return (dispatch) => {
		axios.get(`${GROUP_LIST_URL}`)
			.then((data) => {
				dispatch(fetchGroupListSuccess(data))
			})
			.catch((err) => {
				console.log('Error in fetchGroupList');
				console.log(err);
			});
	};
}

export function fetchFulfillmentGroupList() {
	return (dispatch) => {
		axios.get(`${FLFLMT_GROUP_LIST_URL}`)
			.then((data) => {
				dispatch(fetchFulfillmentGroupListSuccess(data));
			})
			.catch((err) => {
				console.log('Error in fetchFulfillmentGroupList');
				console.log(err);
			});
	};
}

export function fetchTaskGroupList() {
	return (dispatch) => {
		axios.get(`${TASK_GROUP_LIST_URL}`)
			.then((data) => {
				dispatch(fetchTaskGroupListSuccess(data));
			})
			.catch((err) => {
				console.log('Error in fetchTaskGroupList');
				console.log(err);
			});
	};
}

export function fetchALLTaskGroup(){
	return (dispatch) => {
		axios.get("/asset-config/api/getCiSupportAllGroup/id/"+companyId).then((companiesList)=>{
			let allTaskGroupList=[]
			companiesList.data.map(item=>allTaskGroupList.push({field1Value: item.groupName, field1Key: item.groupId}));
			companiesList.data=allTaskGroupList;
		    dispatch(fetchTaskGroupListSuccess(companiesList));
		});
	}
}


export function fetchPriorityListSuccess(data) {
	return {
		type: 'FETCH_PRIORITY_LIST',
		payload: data
	};
}

export function fetchPriorityList(moduleName) {
	return (dispatch) => {
		axios.get(`${PRIORITY_LIST_URL + "/" + moduleName}`)
			.then((data) => {
				dispatch(fetchPriorityListSuccess(data))
			})
			.catch((err) => {
				console.log('Error in fetchPriorityList');
				console.log(err);
			});
	};
}

export function fetchGroupListSuccess(data) {
	return {
		type: 'FETCH_GROUP_LIST',
		payload: data
	};
}

export function fetchFulfillmentGroupListSuccess(data) {
	return {
		type: 'FETCH_FLFLMT_GROUP_LIST',
		payload: data
	};
}

export function fetchTaskGroupListSuccess(data) {
	return {
		type: 'FETCH_TASK_GROUP_LIST',
		payload: data
	};
}

export function addGroupToSelectedGroupsList(groupid) {
	//console.log(groupid);
	return {
		type: 'ADD_TO_SELECTED_GROUP_LIST',
		payload: groupid
	};
}

export function addGroupToSelectedFulfillmentGroupsList(groupid) {
	//  console.log(groupid);
	return {
		type: 'ADD_TO_SELECTED_FLFLMT_GROUP_LIST',
		payload: groupid
	};
}

export function removeGroupFromSelectedGroupsList(groupid) {
	return {
		type: 'REMOVE_FROM_SELECTED_GROUP_LIST',
		payload: groupid
	};
}

export function removeGroupFromSelectedFulfillmentGroupsList(groupid) {
	return {
		type: 'REMOVE_FROM_SELECTED_FLFLMT_GROUP_LIST',
		payload: groupid
	};
}

export function addPriorityToSelectedPrioritiesList(priorityid) {
	return {
		type: 'ADD_TO_SELECTED_PRIORITY_LIST',
		payload: priorityid
	};
}

export function addStatusToSelectedStatusList(status) {
	return {
		type: 'ADD_TO_SELECTED_STATUS_LIST',
		payload: status
	};
}

export function addStatusToSelectedFulfilmentStatusList(status) {
	return {
		type: 'ADD_TO_SELECTED_FLFLMT_STATUS_LIST',
		payload: status
	};
}

export function addTaggedToSelectedTaggedList(taggedid) {
	return {
		type: 'ADD_TO_SELECTED_TAGGED_LIST',
		payload: taggedid
	};
}

export function addTaggedToSelectedFulfilmentTaggedList(taggedid) {
	return {
		type: 'ADD_TO_SELECTED_FLFLMT_TAGGED_LIST',
		payload: taggedid
	};
}

export function removePriorityFromSelectedPrioritiesList(priorityid) {
	return {
		type: 'REMOVE_FROM_SELECTED_PRIORITY_LIST',
		payload: priorityid
	};
}

export function removeTaggedFromSelectedTaggedList(taggedid) {
	return {
		type: 'REMOVE_FROM_SELECTED_TAGGED_LIST',
		payload: taggedid
	};
}

export function removeTaggedFromSelectedFulfilmentTaggedList(taggedid) {
	return {
		type: 'REMOVE_FROM_SELECTED_FLFLMT_TAGGED_LIST',
		payload: taggedid
	};
}

export function removeStatusFromSelectedStatusList(status) {
	return {
		type: 'REMOVE_FROM_SELECTED_STATUS_LIST',
		payload: status
	};
}

export function removeStatusFromSelectedFulfilmentStatusList(status) {
	return {
		type: 'REMOVE_FROM_SELECTED_FLFLMT_STATUS_LIST',
		payload: status
	};
}

export function markAllGroupsSelected(groups) {
	return {
		type: 'MARK_ALL_GROUPS_SELECTED',
		payload: groups
	};
}

export function markAllGroupsUnSelected(groups) {
	return {
		type: 'MARK_ALL_GROUPS_UNSELECTED',
		payload: groups
	};
}

export function markAllFulfillmentGroupsSelected(groups) {
	return {
		type: 'MARK_ALL_FLFLMT_GROUPS_SELECTED',
		payload: groups
	};
}

export function markAllFulfillmentGroupsUnSelected(groups) {
	return {
		type: 'MARK_ALL_FLFLMT_GROUPS_UNSELECTED',
		payload: groups
	};
}


export function markAllPrioritiesSelected(priorities) {
	return {
		type: 'MARK_ALL_PRIORITIES_SELECTED',
		payload: priorities
	};
}

export function markAllPrioritiesUnSelected() {
	return {
		type: 'MARK_ALL_PRIORITIES_UNSELECTED'
	};
}

export function setBreakfixQuickViewSearchOption(searchOption) {
	return {
		type: 'SET_BF_QV_SEARCH_OPTION',
		payload: searchOption
	};
}

export function setBreakfixQuickViewSearchText(searchText) {
	return {
		type: 'SET_BF_QV_SEARCH_TEXT',
		payload: searchText
	};
}

export function setBreakfixQuickViewOrderByOption(orderBy) {
	return {
		type: 'SET_BF_QV_ORDER_BY',
		payload: orderBy
	};
}

export function setBreakfixQuickViewSortByOption(sortBy) {
	return {
		type: 'SET_BF_QV_SORT_BY',
		payload: sortBy
	};
}

export function setFFMTViewSearchOption(searchOption) {
	return {
		type: 'SET_FFMT_QV_SEARCH_OPTION',
		payload: searchOption
	};
}

export function setFFMTQuickViewSearchText(searchText) {
	return {
		type: 'SET_FFMT_QV_SEARCH_TEXT',
		payload: searchText
	};
}

export function setFFMTQuickViewOrderByOption(orderBy) {
	return {
		type: 'SET_FFMT_QV_ORDER_BY',
		payload: orderBy
	};
}

export function setFFMTQuickViewSortByOption(sortBy) {
	return {
		type: 'SET_FFMT_QV_SORT_BY',
		payload: sortBy
	};
}

export function setSelectedFulfillmentItem(selectedFulfillmentItem) {
	if (selectedFulfillmentItem === undefined) {
		return {
			type: 'SET_SLCTD_FFMT_ITEM',
			payload: null
		};
	} else {
		return {
			type: 'SET_SLCTD_FFMT_ITEM',
			payload: selectedFulfillmentItem
		};
	}
}

export function setBreakfixQuickViewTableCarouselIndex(index) {
	return {
		type: 'SET_BF_QV_TABLE_CAROUSEL_INDEX',
		payload: index
	};
}

export function resetBFQVFilters() {
	return {
		type: 'RESET_BF_QV_FILTERS',
		payload: null
	};
}

//investigation start
export function addToSelectedInvestigationStatusList(status) {
	//alert("addStatusToSelectedInvestigationStatusList"+status)
	return {
		type: 'ADD_TO_SELECTEDINV_STATUS_LIST',
		payload: status
	};
}

export function removeFromSelectedInvestigationStatusList(status) {
	return {
		type: 'REMOVE_FROM_SELECTEDINV_STATUS_LIST',
		payload: status
	};
}

export function addTaggedToSelectedInvTaggedList(taggedid) {
	return {
		type: 'ADD_TO_SELECTEDINV_TAGGED_LIST',
		payload: taggedid
	};
}

export function removeTaggedFromSelectedInvTaggedList(taggedid) {
	return {
		type: 'REMOVE_FROM_SELECTEDINV_TAGGED_LIST',
		payload: taggedid
	};
}

export function fetchInvestigationGroupList() {
	return (dispatch) => {
		axios.get(`${INV_GROUP_LIST_URL}`)
			.then((data) => {
				dispatch(fetchInvestigationGroupListSuccess(data))
			})
			.catch((err) => {
				console.log('Error in fetchInvestigationGroupList');
				console.log(err);
			});
	};
}

export function fetchInvestigationGroupListSuccess(data) {

	/*let filterArr = [];
	for(let i = 0; i<list.length; i++) {
		filterArr.push({"spGroupid":list[i].spGroupid,"spGroupname":list[i].spGroupname});
	}
	//console.log("filterArr")
	//console.log(filterArr);
	return{
		type: 'FETCH_INVST_GROUP_LIST',
		filterArr
	};*/
	return {
		type: 'FETCH_INVST_GROUP_LIST',
		payload: data
	};
}

export function markAllInvestigationGroupsSelected(groups) {
	return {
		type: 'MARK_ALL_INV_GROUPS_SELECTED',
		payload: groups
	};
}

export function markAllInvestigationGroupsUnSelected(groups) {
	return {
		type: 'MARK_ALL_INV_GROUPS_UNSELECTED',
		payload: groups
	};
}

export function addGroupToSelectedInvestigationGroupsList(groupid) {
	// console.log(groupid);
	//alert("addGroupToSelectedInvestigationGroupsList"+groupid)
	return {
		type: 'ADD_TO_SELECTED_INV_GROUP_LIST',
		payload: groupid
	};
}

export function removeGroupFromSelectedInvestigationGroupsList(groupid) {
	return {
		type: 'REMOVE_FROM_SELECTED_INV_GROUP_LIST',
		payload: groupid
	};
}
// investigation end

//change start
export function addToSelectedChangeStatusList(status) {
	//alert("addStatusToSelectedInvestigationStatusList"+status)
	return {
		type: 'ADD_TO_SELECTEDCHG_STATUS_LIST',
		payload: status
	};
}

export function removeFromSelectedChangeStatusList(status) {
	return {
		type: 'REMOVE_FROM_SELECTEDCHG_STATUS_LIST',
		payload: status
	};
}

export function addTaggedToSelectedChangeTaggedList(taggedid) {
	return {
		type: 'ADD_TO_SELECTEDCHG_TAGGED_LIST',
		payload: taggedid
	};
}

export function removeTaggedFromSelectedChangeTaggedList(taggedid) {
	return {
		type: 'REMOVE_FROM_SELECTEDCHG_TAGGED_LIST',
		payload: taggedid
	};
}

export function fetchChangeGroupList() {
	return (dispatch) => {
		axios.get(`${CHANGE_GROUP_LIST_URL}`)
			.then((data) => {
				dispatch(fetchChangeGroupListSuccess(data))
			})
			.catch((err) => {
				console.log('Error in fetchChangeGroupListSuccess');
				console.log(err);
			});
	};
}

export function fetchChangeGroupListSuccess(data) {
	return {
		type: 'FETCH_CHANGE_GROUP_LIST',
		payload: data
	};
}
/*

export function markAllInvestigationGroupsSelected(groups){
	return{
		type: 'MARK_ALL_INV_GROUPS_SELECTED',
		payload: groups
	};
}

export function markAllInvestigationGroupsUnSelected(groups){
	return{
		type: 'MARK_ALL_INV_GROUPS_UNSELECTED',
		payload: groups
	};
}
*/
export function addGroupToSelectedChangeGroupsList(groupid) {
	// console.log(groupid);
	//alert("addGroupToSelectedInvestigationGroupsList"+groupid)
	return {
		type: 'ADD_TO_SELECTED_CHG_GROUP_LIST',
		payload: groupid
	};
}

export function removeGroupToSelectedChangeGroupsList(groupid) {
	return {
		type: 'REMOVE_FROM_SELECTED_CHG_GROUP_LIST',
		payload: groupid
	};
}

//change end

//Task start
export function addToSelectedTaskStatusList(status) {
	//alert("addStatusToSelectedInvestigationStatusList"+status)
	return {
		type: 'ADD_TO_SELECTEDTASK_STATUS_LIST',
		payload: status
	};
}

export function removeFromSelectedTaskStatusList(status) {
	return {
		type: 'REMOVE_FROM_SELECTEDTASK_STATUS_LIST',
		payload: status
	};
}

export function addToSelectedTaskTypeList(status) {
	//alert("addStatusToSelectedInvestigationStatusList"+status)
	return {
		type: 'ADD_TO_SELECTEDTASK_TYPE_LIST',
		payload: status
	};
}

export function removeFromSelectedTaskTypeList(status) {
	return {
		type: 'REMOVE_FROM_SELECTEDTASK_TYPE_LIST',
		payload: status
	};
}

export const setFulfillmentData = (fulfillmentData) => {
	return (dispatch) => {
		dispatch(loadFulfillmentListSuccess(fulfillmentData.data));
		dispatch(loadFullfilmentSlaData(fulfillmentData.data.SlaData));
		dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
	};
};
export const setIncidentData = (incidentData) => {
    return (dispatch) => {
		dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
		dispatch(loadBreakFixQuickViewSuccess(incidentData.data));
		dispatch(loadBreakFixSlaData(incidentData.data.SlaData));
	};
};
export const setProblemData = (problemData) => {
    return (dispatch) => {
		dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
		dispatch({type: 'LOAD_INVESTIGATION_SUCCESS',
			investigationList:problemData
		 });
	};
};
export const setChangeData = (changeData) => {
    return (dispatch) => {
		dispatch({
            type: 'LOAD_CMList_SUCCESS',
            changeManagementList:changeData
		});
		dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});
	};
};

export const setMultiSelectDropDownWithOk = (value) => {
    return (dispatch) => {
		dispatch({
            type: 'MULTI_SELECT_DROPDOWN_WITH_OK',
            payload: value
		});
		// dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});
	};
};
//Task end
export function loadIncDetails(payload , flag="noreset") {
//   console.log("payload", payload);
  return (dispatch) => {
	if(flag == "reset") return dispatch({type:"RESET_STATE"})
    dispatch({ type: "LOADER_IN_PROGRESS_2" });
    api
      .post(GLOBAL.breakFixQuickViewURL, payload)
      .then((reponse) => {
        console.log("reponse :: ", reponse);
        if (!reponse) {
          dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY_2" });

          throw Error(reponse.statusText);
        }
		dispatch({ type: "LOAD_Inc_DETAILS", payload: reponse.data.data });
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY_2" });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}