
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useDispatch } from "react-redux";
import { change, reduxForm } from "redux-form";
import useDebounce from "../../../../helpers/useDebounce";
import axios from "axios";
import useDataQueryWithApi from "../../hooks/useDataQueryWithApi";
import { GetDataQuery } from "../../studio/connectors/graphQl";
import { generateFilterOptions } from "../../studio/utils";
import { replaceVariables as repVar } from "../../studio/utils";
import { sanitizeElements } from "../../../common/helper";
const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

const FormBuilderTypeahead = (props) => {
  let { onCrossClick, defaultValues, errors, readOnly, nonEditable, id } = props;
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isError, setIsError] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [replaceVariables, setReplaceVariables] = useState({});
  const [responseData, loading, setDataSourceId, dataQUeryError, setRefreshed] =
    useDataQueryWithApi(replaceVariables, props?.attributes);
  const disabledKey = props?.attributes?.disabledKey && defaultValues[props?.attributes?.disabledKey]?true:false;

  const typeaheadRef = useRef(null);
  useEffect(() => {
    if (responseData) {
      const generatedOptions = responseData.map((res) => {
        return {
          ...res,
          label: props?.attributes?.labelTransform?repVar(props?.attributes?.labelTransform,res):res.hasOwnProperty(props?.attributes?.labelKey?.id)?`${res[props?.attributes?.labelKey?.id]?res[props?.attributes?.labelKey?.id].toString():""}`:"",
          value: res.hasOwnProperty(props?.attributes?.valueKey?.id)?res[props?.attributes?.valueKey?.id]:"",
        };
      });
      // generateFilterOptions(
      //   responseData,
      //   props?.attributes?.labelKey?.id,
      //   props?.attributes?.valueKey?.id,
      //   "label",
      //   "value"
      // );
      setOptions(generatedOptions);
    } else {
      setOptions([]);
    }
  }, [responseData]);

  useEffect(() => {
    if (props?.attributes.data_source && !props?.attributes?.populateDataTypeahead) {
      setDataSourceId(props?.attributes.data_source.id);
    }else if(props?.attributes?.populateDataTypeahead && defaultValues[props?.attributes?.populateDataTypeahead] && !responseData){
      setDataSourceId(props?.attributes.data_source.id);
    }else if(props?.attributes?.populateDataTypeahead && defaultValues[props?.attributes?.populateDataTypeahead] && responseData){
      apiCall();
    }else if(props?.attributes?.populateDataTypeahead && !defaultValues[props?.attributes?.populateDataTypeahead]){
      setOptions([]);
      setTimeout(() => {
        onCrossClickFun();
      }, 100);
    }
    setReplaceVariables(props?.defaultValues);
  }, [defaultValues[props?.attributes?.populateDataTypeahead]]);

  const onCrossClickFun = () => {
    setSelectedOptions([]);
    setInputValue("");
    deleteDefaultValue();
    typeaheadRef.current.clear();
  };

  useEffect(() => {
    if (props?.resetForm) {
      onCrossClickFun();
    }
  }, [props?.resetForm]);

  useEffect(() => {
    if(props?.attributes?.populateDataTypeahead && !defaultValues[props?.attributes?.populateDataTypeahead]) return;
    if (defaultValues && selectedOptions.length == 0) {
      if (
        defaultValues.hasOwnProperty(props?.attributes?.mappingField) &&
        defaultValues.hasOwnProperty(props?.attributes?.name) &&
        defaultValues[props?.attributes?.mappingField] &&
        defaultValues[props?.attributes?.name]
      ) {
        if (
          defaultValues[props?.attributes?.mappingField] !== "null" &&
          defaultValues[props?.attributes?.name] !== "null"
        ) {
          setSelectedOptions([
            {
              label: defaultValues[props?.attributes?.mappingField],
              value: defaultValues[props?.attributes?.name],
              [props?.attributes?.labelKey?.id]: defaultValues[props?.attributes?.mappingField],

            },
          ]);
        }
      }
    }
  }, [props?.isEditForm, defaultValues]);

  const inputProps = {
    value: inputValue ? inputValue : "",
  };

  const onSelection = (selected) => {
    if (selected.length > 0) {
      let selectedObject = {}
      if (props?.attributes?.mappingField && !props?.attributes?.multiSelect) {
        selectedObject[props?.attributes?.mappingField] = selected[0][props?.attributes?.labelKey?.id];
      }
      if (props?.attributes?.name) {
        if(!props?.attributes?.multiSelect){
          selectedObject[props?.attributes?.name] = selected[0].value;
        }else{
          selectedObject[props?.attributes?.name]=selected;
        }
      }
      if (props?.attributes?.extraKeysForVariable && selected.length === 1) {
        const extraKeys = props?.attributes?.extraKeysForVariable.split(",");
        extraKeys.forEach((key) => {
          if(selected[0].hasOwnProperty(key)){
            selectedObject[key] = selected[0][key];
          }
        });
      }
      props.setDefaultValues({ ...props?.defaultValues, ...selectedObject });
      setIsError("");
    } else {
      deleteDefaultValue();
      if(props?.attributes?.required){
        setIsError("error");
      }
    }
    if(selected.length>0 && props?.attributes?.labelTransform){
      selected = selected.map((item) => {
        return {
          label: props?.attributes?.labelKey?.id?item[props?.attributes?.labelKey?.id]:"",
          value: item.value,
        };
      });
    }
    setSelectedOptions(selected);
  };

  const onInputChange = (value) => {
    setInputValue(value);
    setReplaceVariables({
      ...props?.defaultValues,
      [props?.attributes?.name]: value,
    });
    if (value.length > 1) {
      apiCall();
    }
  };

  const apiCall = useDebounce(() => {
    if (props?.attributes.data_source && !props?.attributes?.populateDataTypeahead && inputValue.length > 1) {
      setRefreshed(true);
    }else if(props?.attributes?.populateDataTypeahead && defaultValues[props?.attributes?.populateDataTypeahead] && inputValue.length > 1){
      setRefreshed(true);
    }
  });

  const setErrorColor = () => {
    if (selectedOptions.length > 0) {
      setIsError("");
    } else {
      if(props?.attributes?.required){
        setIsError("error");
      }
      deleteDefaultValue();
    }
  };

  const deleteDefaultValue = () => {
    let keysToDeleteFromParent = [];
    let defaultValues = structuredClone(props?.defaultValues);
    if (props?.attributes?.mappingField) {
      delete defaultValues[props?.attributes?.mappingField];
      keysToDeleteFromParent.push(props?.attributes?.mappingField);
    }

    if (props?.attributes?.name) {
      delete defaultValues[props?.attributes?.name];
      keysToDeleteFromParent.push(props?.attributes?.name);
    }
    if (props?.attributes?.extraKeysForVariable) {
      const extraKeys = props?.attributes?.extraKeysForVariable.split(",");
      extraKeys.forEach((key) => {
        delete defaultValues[key];
        keysToDeleteFromParent.push(key);
      });
    }
    if(typeof props?.deleteFromChildPage === "function"){
      props?.deleteFromChildPage(keysToDeleteFromParent);
    }
    props?.setDefaultValues({...defaultValues});
  };

  return (
    <div className="dvTypehd" id={`${id}`}>
      {!props?.attributes?.disabled &&
      ((selectedOptions && selectedOptions.length > 0) ||
        (inputValue && inputValue.length > 0)) && !(props?.attributes?.disabled || readOnly || nonEditable || disabledKey) ? (
        <span
          title={"Clear"}
          role="img"
          aria-label="Clear"
          className="css-hakgx8 icn"
          onClick={onCrossClickFun}
        >
          <svg width="18" height="18" viewBox="0 0 24 24" role="presentation">
            <path
              d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
              fill="gray"
            ></path>
          </svg>
        </span>
      ) : null}
      <Typeahead
        id={"typeahead"}
        labelKey={props?.attributes?.labelTransform?"label":props?.attributes?.labelKey?.id}
        ref={typeaheadRef}
        onChange={onSelection}
        onInputChange={onInputChange}
        options={options}
        selected={selectedOptions}
        placeholder="Please choose..."
        onBlur={setErrorColor}
        disabled={props?.attributes?.disabled || readOnly || nonEditable || disabledKey}
        inputProps={inputProps}
        renderMenuItemChildren={(option, menuProps) => {
          return <span dangerouslySetInnerHTML={{ __html: sanitizeElements(option.label) }}></span>;
        }}
        //clearButton
        className={
          isError ||
          `${errors && errors[props?.attributes?.name] ? "error" : null}`
        }
        multiple={props?.attributes?.multiSelect}
      />
    </div>
  );
};

// export default reduxForm({
//   form: "FormView",
// })(FormBuilderTypeahead);

export default FormBuilderTypeahead;
