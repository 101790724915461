
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Button } from "react-bootstrap";
import TabPage from "../edit/TabPage";
import {
  _textArea,
  _dropDown,
  _inputField,
  _number,
  _radio,
} from "../../../../components/common/formFields";
import { getFormValues } from 'redux-form';

import axios from "axios";
import { GLOBAL } from "../../../Globals";
let dateformat = "";
const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

import { connect } from "react-redux";
import { Row, Col, Form, ButtonToolbar } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { loadRegionList } from "../../../../actions/task/TaskDataAction";
import swal from "sweetalert";

import Cookies from "universal-cookie";
import {IoClose} from "react-icons/io5";
const cookies = new Cookies();

let full_name = cookies.get("gph");
if (full_name) full_name = full_name.replace("s:", "");
if (full_name) full_name = full_name.substring(0, full_name.lastIndexOf("."));
full_name = full_name.split("~");
full_name = full_name[42];

let user_id = cookies.get("gph");
if (user_id) user_id = user_id.replace("s:", "");
if (user_id) user_id = user_id.substring(0, user_id.lastIndexOf("."));
user_id = user_id.split("~");
user_id = user_id[43];

const validate = (values) => {
  let errors = {};

  if (!values.selectRegion) {
    errors.sequenceNumber = "Enter Title";
  }

  if (!values.regionDesc) {
    errors.description = "Enter Title";
  }

  return errors;
};
const required = (value) => (value ? undefined : "Required");

class OnHoldTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayRight: false,
      disableSubmit: false,
    };
    this.submitForm = this.submitForm.bind(this);
  }
  componentDidMount() {
    // this.
  }
  componentWillMount() {
    // alert("Test");
    this.props.loadRegionList(this.props.taskDetails.companyId);
  }

  componentWillReceiveProps(nextProps) {
    dateformat = this.props.date_format;
  }

  submitForm(values) {
    const { reset, showProcess } = this.props;
    let ref = this;
    console.log(this.props.taskDetails);
    console.log(this.props);
    console.log(values);
    const assignedToId = this.props.formValues?.assignedToId;
    const assignedToLabel = this.props.formValues?.assignedTo;
    if (this.props.user_id != assignedToId) {
      swal({
        text: this.props.translator[
          "Please assign the ticket to yourself before changing the status of the ticket"
        ],
        button: this.props.translator["OK"],
      });
      return false;
    }

    ////ApiCall
    let jwtdf = dateformat.slice(0, 10).toUpperCase();
    // jwtdf + " HH:mm:ss";
    //console.log("moment")
    //moment('2018-07-17 19:00:00', 'YYYY-MM-DD HH:mm:ss').tz('UTC').format()
    let expectedStartDate = "";
    let expectedDueDate = "";
    expectedStartDate = moment(values.expectedStartDate, [
      jwtdf + " HH:mm:ss",
      "MM-DD-YYYYY HH:mm:ss",
    ])
      .utc()
      .format();
    //expectedStartDate=moment.utc(values.expectedStartDate)
    //expectedDueDate=moment.utc(values.expectedDueDate)
    expectedDueDate = moment(values.expectedDueDate, [
      jwtdf + " HH:mm:ss",
      "MM-DD-YYYYY HH:mm:ss",
    ])
      .utc()
      .format();
    //console.log("expectedStartDate")
    //console.log(expectedStartDate);
    //console.log(expectedDueDate);
    //console.log(moment.tz(values.expectedStartDate))
    //console.log(moment.utc(values.expectedStartDate));
    ref.setState({ disableSubmit: true });
    axios
      .patch(GLOBAL.editTaskUrl + this.props.taskDetails.taskCode, {
        holdingRegion: values.regionDesc,
        field1Value: values.field1Value,
        field1Key: values.field1Key,
        clientId: this.props.taskDetails.clientId,
        applicationName: "XSM",
        moduleName: "Breakfix",
        statusId: "7",
        statusName: "On Hold",
        taskCode: this.props.taskDetails.taskCode,
        assignedTo: assignedToLabel,
        assignedToId: assignedToId,
        reviewerId: 0,
        reviewer: ''
      })
      // .then(function(response) {
      //   if ((response.status = 200)) {
      //     ref.setState({ displayRight: true });
      //   }
      //   // alert("Done");
      //   reset();
      // })
      .then((response) => {
        if (response.status == 200) {
          // return api.post(GLOBAL.breakFixActivityLogPostUrl, {
          //   "itemId": this.props.taskDetails.taskidforactivity,
          //   "description": "Reason of holding:"+values.regionDesc,
          //   "isInternal": 'N',
          //   "createdOn": "",
          //   "createdBy": "",
          //   "module": "Task",
          //   "createdByName": ""
          // });
          let isExternal = "";
          console.log("this.props.taskDetails onhold task");
          console.log(this.props.taskDetails);
          if (
            this.props.taskDetails.isExternal == null ||
            this.props.taskDetails.isExternal == "" ||
            this.props.taskDetails.isExternal == undefined
          ) {
            isExternal = "false";
          } else {
            isExternal = this.props.taskDetails.isExternal;
          }
          return api.post(GLOBAL.TMSActivityLogUrl, {
            workitemId: this.props.taskDetails.taskidforactivity,
            taskCode: this.props.taskDetails.taskCode,
            module: "Task",
            description: "Reason of holding: " + values.regionDesc,
            isInternal: "N",
            createdBy: user_id,
            createdbyName: full_name,
            isExternal: isExternal,
          });
        }
        ref.setState({ disableSubmit: false });
      })
      .then((response) => {
        ref.setState({ displayRight: true, disableSubmit: false });
        reset();
        // browserHistory.push("/quickViewBreakFix");
        ref.props.flushReducer();
        ref.props.loadTaskItemToEdit(ref.props.taskId);
        ref.props.loadTaskGroup(ref.props.cId);
        ref.props.loadAsignedTo(ref.props.gId);
        ref.props.getEditTaskStatus(ref.props.taskId);
        ref.props.changeActionView("processHierarchy");
      })
      .catch(function (error) {
        ref.setState({ disableSubmit: false });
        console.log(error);
      });
  }

  render() {
    const { error, handleSubmit, change, submitting } = this.props;
    const displayRight = this.state.displayRight;
    const rList = this.props.regionListData;
    // alert(rList);
    console.log(rList);
    let regionList = rList.map((item) => {
      return { value: item.field1Value, text: item.field1Key };
    });

    console.log(this.state);
    if (displayRight === true) {
      return (
        <div>
          {/* <XsmProcessHierarchy translator={this.props.translator} />{" "} */}
          <TabPage
            translator={this.props.translator}
            taskIdactivity={this.props.taskIdactivity}
            taskCode={this.props.taskCode}
            taskDetails={this.props.taskDetails}
            date_format={this.props.date_format}
            role_id={this.props.role_id}
          />
        </div>
      );
    } else {
      return (
        <div className="borderForm margin-b-10">
          <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                  <li>
                  <button
                      type='button'
                      title="Minimize the right panel"
                      bsClass=""
                      bsStyle=""
                      className="closerightPanelBtn"
                      onClick={() => {
                      this.props.rightEditPanel(false);
                      this.props.changeActionView("");
                      }}
                  >
                      <IoClose />
                  </button>
                  </li>
              </ul>
          </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {this.props.translator["Put on hold"]}
            </div>
          </div>
          <div className="rBoxGap">
            <form onSubmit={handleSubmit(this.submitForm)}>
              <Row>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label bsClass="">
                      <span className="rStar" />
                      <span>{this.props.translator["Hold Status"]}</span>
                    </Form.Label>
                    <Field
                      component={_dropDown}
                      options={regionList}
                      name="field1Value"
                      className="form-control"
                      onChange={(event) => {
                        change(
                          "field1Key",
                          event.target[event.target.selectedIndex].text
                        );
                      }}
                      validate={[required]}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group>
                    <span className="rStar" />
                    <span>{this.props.translator["Reason"]} </span>
                    <Field
                      component={_textArea}
                      name="regionDesc"
                      className="form-control"
                      rows="2"
                      validate={[required]}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <ButtonToolbar className="black">
                    <Button
                      type="submit"
                      bsSize="small"
                      bsStyle="primary"
                      disabled={this.state.disableSubmit}
                    >
                      {this.props.translator["Submit"]}
                    </Button>
                    {/* <Button bsSize="small" bsStyle="text">
                  Button
                </Button> */}
                  </ButtonToolbar>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      );
    }
    //regionList = removeDuplicates(supportCompaniesList, "value");
  }
}
OnHoldTask = reduxForm({
  form: "onHoldForm",
  validate,
})(OnHoldTask);
// function mapDispatchToProps(dispatch) {
//   return bindActionCreators(
//     {
//       loadTaskItemToEdit
//     },
//     dispatch
//   );
// }
const mapStateToProps = (state) => {
  const regionListData = state.regionListData;
  let formValues = getFormValues("editTaskForm")(state);
  console.log(regionListData);
  return {
    regionListData,
    formValues,
  };
};
export default connect(mapStateToProps, { loadRegionList })(OnHoldTask);
// export default OnHoldTask;
