
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { ImSpinner6 } from "react-icons/im";
import { IoSaveOutline, IoClose } from "react-icons/io5";
import { LuActivitySquare, LuFileText } from "react-icons/lu";
import { MyContext } from '_MyContext';

const Header = (props) => {
  const dispatch = useDispatch();
  const params = useParams();

  const { formikref, isSubmitting, dirty, errors, submitForm } = props;

  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

  useEffect(() => {
      context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
      context.newTabPrevent();
    }, []);

  const forumId = params?.forumId;
  const isAddMode = !forumId;

  const handleSubmit = () => {
    formikref.current?.submitForm();
  };

  return (
    <Row className="margin-b-15">
      <Col md={7} sm={7} xs={12}>
        <h2 bsClass="" className="sPageHeading1">
          {tr["Rule"]}
        </h2>
      </Col>
      <Col md={5} sm={5} xs={12} className="hidden-xs serviceHedRFltrMargn">
        <div className="paHedFilter border-r margin-r-10 padding-r-10">
          <Button
            bsPrefix=" "
            role="button"
            className={
              props.view === "details" ? "active myBt fillBtn" : "myBt fillBtn"
            }
            title={tr["Conditions"]}
            onClick={() => {
              props.setView("details");
              props.rightEditPanel(true);
            }}
          >
            <LuFileText />
          </Button>
          <Link
            className={
              props.view === "activity" ? "active myBt fillBtn" : "myBt fillBtn"
            }
            bsPrefix=" "
            href="javascript:void(0)"
            title={tr["Activity Details"]}
            onClick={() => {
              props.setView("activity");
              props.rightEditPanel(true);
            }}
          >
            <LuActivitySquare />
          </Link>
        </div>
        <div className="paHedFilter">
          <Link
            to="javascript:void(0)"
            bsPrefix=" "
            id="creDeleSav"
            className="myBt plus ctrlKeyPrevent"
            title={tr["Save"]}
            onClick={handleSubmit}
            ref={saveObjRef}
          >
            <IoSaveOutline />
          </Link>
          <Link
            to={"/rules"}
            bsPrefix=" "
            className="myBt cancel fillBtn ctrlKeyPrevent"
            title={tr["Cancel"]}
            ref={closeButton}
          >
            <IoClose />
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default Header;
