
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GLOBAL } from "../../Globals";
import axios from "axios";
import { loadBreakFixAuditLogDetails } from "../../../actions/breakFix/breakFixAuditLogAction";
import { loadFulfillmentDetails } from "../../../actions/fulfillment/fulfillmentDataAction";
import { loadStatusBasedHamburgerOptions } from "../../../actions/common/loadStatusBasedHamburgerOptions";
import { loadTimelineData } from "../../../actions/common/commonApisActions.js";
import swal from "sweetalert";
import Cookies from "universal-cookie";
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";

const cookies = new Cookies();
let languageSelected = cookies.get("gph");
languageSelected = languageSelected.split("~");
languageSelected = languageSelected[20];
class FulfillmentPutOnHoldForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: "",
      canSavedMessage: "",
      holdSavedMessage: "",
      canErrorMessage: "",
      holdErrorMessage: "",
      clickOnCalSubmit: 0,
      clickOnHoldSubmit: 0,
      reasonCode: "",
      reasonValue: "",
      reasonLength: 1000,
      reasonHold: []
    };
    this.setReasonCode = this.setReasonCode.bind(this);
    this.activityData = this.activityData.bind(this);
    this.postHoldActivityData = this.postHoldActivityData.bind(this);
    this.fetchHoldReason = this.fetchHoldReason.bind(this);
    GLOBAL.cancelreasonCharLeft = 1000;
    GLOBAL.cancelreasonCharMax = 1000;
  }

  componentWillMount() {
    this.fetchHoldReason(this.props.fulfillmentdetails.companyId,'Fulfillment','On Hold','1');
  }

  setReasonCode(event) {
    this.setState({
      reasonCode: event.target.value,
      reasonValue: event.target[event.target.selectedIndex].text,
    });
  }

  renderFixReasonName(breakFixData) {
    if (breakFixData.length == 0) {
      return null;
    }
    return breakFixData.map((obj, i) => <option key={i} value={obj.field1Value}>{obj.field1Key}</option>);
  }

  activityData(comnts) {
    this.setState({ comments: comnts.target.value });
    let input = comnts.target.value.length;
    GLOBAL.cancelreasonCharLeft = GLOBAL.cancelreasonCharMax - input;
    this.setState({ reasonLength: GLOBAL.cancelreasonCharLeft });
  }

  postHoldActivityData() {
    if (
      this.props.user_id != this.props.fulfillmentdetails.asgUserid &&
      (this.props.roleIdentification("43") ||
        this.props.roleIdentification("44")) &&
      this.props.roleIdentification("13")
    ) {
      swal({
        text: this.props.translator[
          "Please assign Fulfillment ticket to yourself before you Submit"
        ],
        button: this.props.translator["OK"],
      });
    } else {
      let self = this;
      let actualComments = this.state.comments.trim();

      let itemID =
        this.props.params && this.props.params.itemId
          ? this.props.params.itemId
          : null;
      if (
        actualComments != "" &&
        this.state.reasonCode != undefined &&
        this.state.reasonCode != "select" &&
        this.state.reasonCode != ""
      ) {
        self.setState({
          holdErrorMessage: "",
          clickOnHoldSubmit: 1,
          clickOnCalSubmit: 1,
        });
        let postjson = {
          itemId: itemID,
          description: `Hold Type: ${this.state.reasonValue}, Reason of holding: ${this.state.comments}`,
          isInternal: "N",
          createdOn: "",
          createdBy: "",
          module: "Fullfilment",
          createdByName: "",
        };

        axios
          .patch(GLOBAL.fulfillmentHoldingPatchUrl + "/" + itemID, {
            itemStatus: "60",
            modifiedBy: "",
            modifiedByName: "",
            holdingReasonCode: this.state.reasonCode,
            holdingReasonValue: this.state.reasonValue,
            holdingComments: this.state.comments,
          })
          .then(function (response) {
            if (
              response.statusText == "Created" ||
              response.status === 200 ||
              response.status === 201
            ) {
              self.props.loadStatusBasedHamburgerOptions(
                "Item",
                response.data.status,
                response.data.itemId
              );
              axios
                .post(GLOBAL.breakFixActivityLogPostUrl, postjson)
                .then((response) => {
                  if (response.status == 201) {
                    self.props.loadBreakFixAuditLogDetails(
                      itemID,
                      "dsc",
                      "Fullfilment"
                    );
                    self.props.loadFulfillmentDetails(itemID);

                    self.setState({
                      holdSavedMessage:
                        "Comments have beed saved in activity journal",
                      holdErrorMessage: "",
                      clickOnHoldSubmit: 0,
                      clickOnCalSubmit: 1,
                    });
                    self.props.processHierarchy();
                  }
                });
            }
          })
          .catch(function (error) {
          });
      } else {
        if (actualComments == "") {
          self.setState({ holdErrorMessage: "Please provide reason" });
        }

        if (
          this.state.reasonCode == "select" ||
          this.state.reasonCode == "" ||
          this.state.reasonCode == undefined
        ) {
          self.setState({ holdErrorMessage: "Please provide Hold Type." });
        }

      }
    }
  }
  fetchHoldReason(companyId,modules,fields,status){
    axios.get(`/api/company_menulist?companyId=${companyId}&module=${modules}&field=${fields}&language=${languageSelected}&status=${status}`).then(reterivedReasons => {
      this.setState({reasonHold: reterivedReasons.data});
    });
  }

  render() {
    return (
      <div className="hambTab">
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
          <ul>
            <li>
            <button
                type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                this.props.changeRightView("allClose");
                }}
            >
                <IoClose/>
            </button>
            </li>
          </ul>
        </div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {this.props.translator["Put on hold"]}
          </div>
        </div>
        <div className="rBoxGap">
          <Form.Group className="form-group">
            <Form.Label bsClass="">
              <span className="rStar"></span>
              {this.props.translator["Hold Status"]}{" "}
            </Form.Label>
            <Form.Select
              required="true"
              componentClass="select"
              onChange={(event) => {
                this.setReasonCode(event);
              }}
            >
              <option value="select">Select</option>
              {this.renderFixReasonName(this.state.reasonHold)}
            </Form.Select>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label bsClass="">
              <span className="rStar"></span>
              {this.props.translator["Reason"]}{" "}
            </Form.Label>
            <Form.Control
              required="true"
              as="textarea"
              rows="2"
              maxLength="1000"
              inputRef={(input) => (this.activityDescription = input)}
              onChange={this.activityData.bind(this)}
            />
          </Form.Group>
          <p
            className="charLeft"
            style={{ textAlign: "right", fontSize: "11px" }}
          >
            ({this.props.translator["Characters Remaining"]}:{" "}
            {this.state.reasonLength}/1000)
          </p>
          <div>
            <font color="red">
              <strong>{this.state.holdErrorMessage}</strong>
            </font>
          </div>

          <div>
            <ButtonToolbar className="black">
              <Button
                className="rgSidrkBtn smallBtn"
                bsSize="small"
                bsStyle="primary"
                onClick={() => {
                  this.postHoldActivityData();
                }}
                disabled={this.state.clickOnCalSubmit}
              >
                {" "}
                {this.state.clickOnCalSubmit ? (
                  <ImSpinner6 className="icn-spinner"/>
                ) : null}{" "}
                {this.props.translator["Submit"]}
              </Button>
            </ButtonToolbar>
          </div>
        </div>
      </div>
    );
  }
}
export function mapStateToProps({ reasonCode }) {
  return { reasonCode };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadBreakFixAuditLogDetails,
      loadFulfillmentDetails,
      loadStatusBasedHamburgerOptions,
      loadTimelineData,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FulfillmentPutOnHoldForm);
