/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const TextAreaComponent = ({ className, handleOnChange, options, value, context, fieldData, preDefinedMaxLength }) => {
    const [inputValue, setInputValue] = useState('');
    const [maxLength, setMaxLength] = useState(preDefinedMaxLength);
    const [lengthRemaning, setLengthRemaning] = useState(0);
    const translator = useSelector(state => state.spcmReducer.tr);
    const [fieldName, setFieldName] = useState('');

    const handleValueChange = (value) => { 
      setInputValue(value);
      handleOnChange(value);
      setLengthRemaning(maxLength - value.length);
    }

    useEffect(() => { 
      if(value !== '' && value !== null && value !== undefined && value != inputValue){
        handleValueChange(Array.isArray(value) ? value[0] : value);
      }
      setFieldName(fieldData?.name);
    }, []);

    useEffect(() => {
      if(fieldName !== fieldData.name && (value === '' || value?.length === 0)){
        handleValueChange('');
        setFieldName(fieldData?.name);
      }
    }, [fieldData]);
    
    const onChange = (e) => {
        handleValueChange(e.target.value);
    };

    return <>
        <Form.Control as="textarea" rows={3} maxLength={maxLength} value={inputValue} onChange={onChange} placeholder={translator['Enter Some Text']} className='width100'/>
        <p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({translator['Characters Remaining']}: {lengthRemaning}/{maxLength})</p>
    </>
}

export default TextAreaComponent;
