
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ReactReadMoreLess = (props) => {
  const [toggle, setToggle] = useState("more");
  const [text, setText] = useState("more");
  const [showMoreLess, setShowMoreLess] = useState(false);

  const tr = useSelector(state => state.spcmReducer.tr)

  const charLimit = props.charLimit;
  let content = props.content;
  const readMoreText = props.readMoreText || tr["more"];
  const readLessText = props.readLessText || tr["less"];

  useEffect(() => {
    if (content?.length > charLimit) {
      if(props.isHtml && props.content.split(/\n/).length > 1) {
        setText(
          props.content.split(/\n/).map((description, i) => {
            if(toggle === "more") {
              if(i<3)
              return (
                <div>{description}</div>
              )
            } else {
              return (
                <div>{description}</div>
              )
            }
          })
        );
        setShowMoreLess(true);
      } else {
        setShowMoreLess(true);
        setText(toggle === "more"
            ? props.content.slice(0, charLimit)
            : props.content);
      }
    } else {
      setText(props.content);
      setShowMoreLess(false);
    }
  }, [toggle,props.content]);

  return (
    <>
      {props?.separator && toggle === "less" ? text.split(',').map(item => (
        <p>{item},</p>
      )) :  text}
      {showMoreLess && <a
        href="javascript:void(0)"
        onClick={() => setToggle(toggle === "more" ? "less" : "more")}
        className="readMoreText"
      >
        {toggle === "less" ? readLessText : "..."}
        {toggle !== "less" && readMoreText}
      </a>}
    </>
  );
};

export default ReactReadMoreLess;
