
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Alert, Form, Table, ButtonToolbar, Button } from "react-bootstrap";
import { loadCIAtt, loadModelName } from "../../../../actions/cmdb/lovAction";
import { connect } from "react-redux";
import axios from "axios";
import { bindActionCreators } from "redux";
import { GLOBAL } from "_Globals";
import constanturl from "../../../../utils/constants.js";
import Datetime from "react-datetime";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import moment from "moment";
import Cookies from "universal-cookie";
import {HiPlus, HiOutlineTrash} from 'react-icons/hi';
import {IoClose} from "react-icons/io5";
import Swal from "sweetalert2";

const cookies = new Cookies();
let languageSelected = cookies.get("gph");
languageSelected = languageSelected.split("~");
languageSelected = languageSelected[20];

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];

class CmdbAttribute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formToggle: false,
      formeditToggle: false,
      formaddToggle: true,
      attname: "",
      attval: "",
      // attkey: "",
      attid: "",
      attributeInputType: "Textbox",
      dropdownOptions: [],
      ciAttMapId: "",
      selectedValueMultiselect: [],
			selectedValueTextbox: "",
			selectedValueDropdown: "",
			selectedValueDatetime: "",
      attributes: [{ ATTRIBUTE_ID: "", VALUE: "", CI_ATT_MAP_ID: "", INPUT_TYPE: "Textbox" }],
      newRows: [],
    };
		// GLOBAL.editattval = "";
    GLOBAL.attid = "";
    GLOBAL.attval = "";
    GLOBAL.attkey = "";
		// GLOBAL.editattkey = "";
    this.formToggleFun = this.formToggleFun.bind(this);
    this.formCancelFun = this.formCancelFun.bind(this);
    this.formeditToggleFun = this.formeditToggleFun.bind(this);
  }

  addRow = () => {
    this.setState((prevState) => ({
      attributes: [
        ...prevState.attributes,
        {
          ATTRIBUTE_ID: "",
          VALUE: "",
          CI_ATT_MAP_ID: "",
          INPUT_TYPE: "Textbox", 
          dropdownOptions: [], 
        },
      ],
    }));
  };
  
  
  


  

  removeRow = (index) => {
    this.setState((prevState) => {
      if (prevState.attributes.length === 1) {
        return { attributes: [...prevState.attributes] }; 
      }
      
      const updatedAttributes = prevState.attributes.filter((_, i) => i !== index);
      console.log("Updated attributes after deletion:", updatedAttributes);
  
      return { attributes: updatedAttributes };
    });
  };


  formToggleFun(value) {
    this.setState({ formToggle: value });
    this.setState({ formeditToggle: false });
    this.setState({ formaddToggle: false });
  }

  formCancelFun(value1) {
    this.setState({ formToggle: false });
    this.setState({ formeditToggle: false });
    this.setState({ formaddToggle: value1 });
    this.setState({ selectedValueMultiselect: [] });
    this.setState({ dropdownOptions: [] });
		this.setState({ selectedValueTextbox: "" });
		this.setState({ selectedValueDropdown: "" });
		this.setState({ selectedValueDatetime: "" });
		this.setState({ showError: false, showErrorText: "" });
    GLOBAL.attid = "";
    GLOBAL.attval = "";
		// GLOBAL.editattval = "";
    GLOBAL.attkey = "";
		// GLOBAL.editattkey = "";
    this.setState({
      attributes: [{ ATTRIBUTE_ID: "", VALUE: "", CI_ATT_MAP_ID: "", INPUT_TYPE: "Textbox" }],
    });
  }

  formeditToggleFun(value, attrObj) {
    this.setState({ formeditToggle: value });
    this.setState({ formToggle: false });
    this.setState({ formaddToggle: false });
		this.setState({ attname: attrObj.DISPLAY_NAME });
    this.setState({ attval: attrObj.VALUE });
    // this.setState({ attkey: attrObj.KEY });
    this.setState({ attid: attrObj.ATTRIBUTE_ID });
    this.setState({ ciAttMapId: attrObj.CI_ATT_MAP_ID });
    this.setState({ attributeInputType: attrObj.INPUT_TYPE });
		GLOBAL.attval = attrObj.VALUE;
		GLOBAL.attkey = attrObj.KEY;
    if (attrObj.INPUT_TYPE == "Dropdown") {
      this.setState({ selectedValueDropdown: attrObj.KEY });
      let apiInfo = attrObj.API_INFORMATION;
      axios
        .get(GLOBAL.getCiAttributeValues + "/" + this.props.COMPANY_ID, {
          headers: { query: JSON.stringify(apiInfo) }
        })
        .then((response) => {
          this.setState({ dropdownOptions: response.data });
        });
    } else if (attrObj.INPUT_TYPE == "Multiselect List") {
      let apiInfo = attrObj.API_INFORMATION;
      axios
        .get(GLOBAL.getCiAttributeValues + "/" + this.props.COMPANY_ID, {
          headers: { query: JSON.stringify(apiInfo) }
        })
        .then((response) => {
          let options = [];
          response.data.forEach((element) => {
            let obj = {};
            obj.value = element.field1Value;
            obj.label = element.field1Key;
            options.push(obj);
          });
          let arr = attrObj.KEY.split(";");
          let newArr = [];
          arr.forEach((value) => {
            let obj = options.find((item) => item.value == value);
            newArr.push(obj);
          });
          // console.log('newArr==',newArr);
          this.setState({ selectedValueMultiselect: newArr });
          this.setState({ dropdownOptions: options });
        });
    }
  }

  componentWillMount() {
    this.props.loadCIAtt(this.props.ciId);
  }

  renderAtt(attData) {
    if (!attData) {
      return <option></option>;
    }
    return attData.map((attObj) => {
      if (
        attObj.VALUE != null &&
        attObj.VALUE != "" &&
        attObj.IS_DELETED == 1
      ) {
        return (
          <tr>
						<td>{attObj.DISPLAY_NAME}</td>
            <td>{attObj.VALUE}</td>
            {this.props.isRoleDisabled 
							? (
                <td className="text-c f-size-13">
                <i
                  onClick={() => {
                    this.formeditToggleFun(true, attObj);
                  }}
                  className="fa fa-edit margin-r-10 cursorPoint"
                ></i>
                <i
                  onClick={() => {
                    this.attdelete(attObj.CI_ATT_MAP_ID);
                  }}
                  className="fa fa-trash-o cursorPoint"
                ></i>
              </td>
							) : null}
          </tr>
        );
      } else return null;
    });
  }

  renderAttList(attlistData) {
    if (!attlistData) {
      return <option></option>;
    }
    return attlistData.map((attObj) => {
      if (
        (attObj.VALUE == null || attObj.VALUE == "") &&
        attObj.IS_DELETED != 1
      )
        return (
					<option value={attObj.ATTRIBUTE_ID}>{attObj.DISPLAY_NAME}</option>
        );
    });
  }

  checkDuplicates = (attributes) => {
    let attributeMap = {};
    let duplicateAttributes = [];

    attributes.forEach((attr) => {
      if (attributeMap[attr.ATTRIBUTE_ID]) {
        duplicateAttributes.push(attr.ATTRIBUTE_ID);
      } else {
        attributeMap[attr.ATTRIBUTE_ID] = true;
      }
    });

    return duplicateAttributes;
  };
  
  



  saveatt() {
    const hasEmptyFields = this.state.attributes.some(
      (attr) => attr.ATTRIBUTE_ID.trim() === "" || attr.VALUE.trim() === ""
    );
  
    if (hasEmptyFields) {
      this.setState({
        showError: true,
        showErrorText: this.props.translator["Please select Attribute Name/Value"],
      });
      return;
    } else {
      this.setState({ showError: false, showErrorText: "" });
    }
  
    const duplicateAttributes = this.checkDuplicates(this.state.attributes);
  
    if (duplicateAttributes.length > 0) {
      const duplicateNames = duplicateAttributes.map((id) => {
        const attr = this.props.ciAtt.find((attr) => attr.ATTRIBUTE_ID == id);
        return attr ? attr.DISPLAY_NAME : id;
      });
      console.log("Duplicate attribute names:", duplicateNames);
  
      Swal.fire({
        text: `Please remove duplicate attributes: ${duplicateNames.join(", ")}`,
      });
      return;
    }

    let ref = this;
  
    let payload = this.state.attributes.map((attr) => {
      if (attr.INPUT_TYPE === "Dropdown") {
        return {
          attr_id: attr.ATTRIBUTE_ID,
          value: attr.CI_ATT_MAP_ID || "", 
          key: attr.VALUE,
        };
      }
      return {
        attr_id: attr.ATTRIBUTE_ID,
        value: attr.VALUE,
        key: attr.CI_ATT_MAP_ID || "", 
      };
    });
  
    axios
      .post(GLOBAL.postCiArrtibuteUrl + "/" + this.props.ciId, payload)
      .then((response) => {
        ref.setState({
          formToggle: false,
          formeditToggle: false,
          formaddToggle: true,
        });
  

        this.setState({
          attributeInputType: "Textbox",
          selectedValueMultiselect: [],
          dropdownOptions: [],
          selectedValueTextbox: "",
          selectedValueDropdown: "",
          selectedValueDatetime: "",
          attributes: [{ ATTRIBUTE_ID: "", VALUE: "", CI_ATT_MAP_ID: "", INPUT_TYPE: "Textbox" }],
        });
  
        this.props.loadCIAtt(this.props.ciId);
        this.props.loadModelName(this.props.ciId);
      })
      .catch((error) => {
        console.error("Error saving attributes:", error);
      });
  }
  
  

submitatt() {
  let ref = this;

  if (this.state.attributeInputType == "DateTime" && this.state.showErrorText == this.props.translator["Please Provide Valid Date Format."]) {
    return false;
  }

  if (GLOBAL.attval.trim() == "") {
    this.setState({ showError: true, showErrorText: this.props.translator["Value cannot be blank"] });
    return false;
  } else {
    this.setState({ showError: false, showErrorText: "" });
  }

  let updatedValue = this.state.attval; 
  let updatedKey = this.state.ciAttMapId.toString(); 

  if (this.state.attributeInputType === "Dropdown") {
    let selectedOption = this.state.dropdownOptions.find(
      (opt) => opt.field1Value == this.state.selectedValueDropdown
    );
    if (selectedOption) {
      updatedValue = selectedOption.field1Key; 
      updatedKey = selectedOption.field1Value.toString(); 
    }
  }


  axios
    .patch(GLOBAL.patchCiArrtibuteUrl + "/" + this.state.ciAttMapId, {
      value: updatedValue,
      key: updatedKey
    })
    .then((response) => {
      GLOBAL.attid = "";
      GLOBAL.attval = "";
      GLOBAL.attkey = "";

      ref.setState({ formToggle: false, formeditToggle: false });
      this.setState({ 
        formaddToggle: true, 
        attributeInputType: "Textbox", 
        selectedValueMultiselect: [], 
        dropdownOptions: [], 
        selectedValueTextbox: "", 
        selectedValueDropdown: "", 
        selectedValueDatetime: "" 
      });

      this.props.loadCIAtt(this.props.ciId);
      this.props.loadModelName(this.props.ciId);
    });
}



  attdelete(ciAttMapId) {
    let ref = this;

    axios
      .patch(GLOBAL.deleteCiArrtibuteUrl + "/" + ciAttMapId, {
        isDeleted: "0",
      })
      .then((response) => {
        ref.setState({ formToggle: false });
        ref.setState({ formeditToggle: false });
        this.props.loadCIAtt(this.props.ciId);
        this.props.loadModelName(this.props.ciId);
      });
  }

  setAttId(e, index) {
    const selectedAttributeId = e.target.value;
  
  
    console.log(`setAttId Called: Index = ${index}, Selected Attribute ID = ${selectedAttributeId}`);
    
    console.log(`setAttId Called: Index = ${index}, Selected Attribute ID = ${selectedAttributeId}`);
  
    if (index === undefined) {
      console.error("Error: Row index is undefined in setAttId!");
      return;
    }
  
    const selectedAttribute = this.props.ciAtt.find(attr => attr.ATTRIBUTE_ID == selectedAttributeId);
  
    if (!selectedAttribute) {
      console.error(`Error: No attribute found for ID ${selectedAttributeId}`);
      return;
    }
  
    const newAttributes = [...this.state.attributes];
  
    newAttributes[index] = {
      ...newAttributes[index],
      ATTRIBUTE_ID: selectedAttributeId,
      VALUE: "",
      INPUT_TYPE: selectedAttribute.INPUT_TYPE,
      dropdownOptions: selectedAttribute.INPUT_TYPE === "Dropdown" || selectedAttribute.INPUT_TYPE === "Multiselect List" ? [] : null,
    };
  
    if (selectedAttribute.INPUT_TYPE === "Dropdown" || selectedAttribute.INPUT_TYPE === "Multiselect List") {
      axios.get(GLOBAL.getCiAttributeValues + "/" + this.props.COMPANY_ID, {
        headers: { query: JSON.stringify(selectedAttribute.API_INFORMATION) },
      }).then(response => {
        newAttributes[index].dropdownOptions = response.data;
        console.log(`Row ${index}: Dropdown Options Fetched ->`, response.data);
        this.setState({ attributes: newAttributes });  // Update state after fetching data
      });
    } else {
      this.setState({ attributes: newAttributes });  // Update state immediately
    }
  }
  
  

  setAttVal(e, index, type) {
    const newAttributes = [...this.state.attributes];
  
    if (type === "DateTime") {
      const dateValue = moment(e).isValid() ? moment(e).format("YYYY-MM-DD HH:mm:ss") : "";
      newAttributes[index].VALUE = dateValue;
    } else if (type === "Multiselect List") {
      const selectedValues = e.map(item => item.value).join(";");
      newAttributes[index].VALUE = selectedValues;
    } else if (type === "Dropdown") {
      newAttributes[index].VALUE = e.target.value;
      newAttributes[index].CI_ATT_MAP_ID = e.target[e.target.selectedIndex].text;
    } else {
      newAttributes[index].VALUE = e.target.value;
    }
  
    this.setState({ attributes: newAttributes });
  }
  
  handleEditChange = (event, type) => {
    let newValue;
    
    if (type === "DateTime") {
        newValue = moment(event).isValid() ? moment(event).format("YYYY-MM-DD HH:mm:ss") : "";
        this.setState({ selectedValueDatetime: newValue, attval: newValue });
    } else if (type === "Dropdown") {
        newValue = event.target.value;
        this.setState({ selectedValueDropdown: newValue, attval: newValue });
    } else {
        newValue = event.target.value;
        this.setState({ attval: newValue });
    }
};

  renderDropdownList(dropdownOptions) {
    if (!dropdownOptions) {
      return <option></option>;
    }
    return dropdownOptions.map((option) => {
      return <option value={option.field1Value}>{option.field1Key}</option>;
    });
  }

  render() {
    let successMessage = "";
    let failureMessage = "";
    let msg = "";
    let header = "";
    for (let i = 0; i <= this.props.ciAtt.length - 1; i++) {
      if (
        this.props.ciAtt[i].VALUE != null &&
        this.props.ciAtt[i].VALUE != ""
      ) {
        successMessage = this.props.translator["Data AVailable"];
      } else {
        failureMessage = this.props.translator["Data not available"];
      }
    }
    if (successMessage != "") {
      msg = "";
      header = (
        <thead>
          <tr>
            <th>{this.props.translator["Name"]}</th>
            <th>{this.props.translator["Value"]}</th>
            {this.props.isRoleDisabled 
							? (
                <th className="text-c">{this.props.translator["Action"]}</th>
							) : null}
          </tr>
        </thead>
      );
    } else {
      msg = this.props.translator["Data not available"];
      header = null;
    }


    let attributeValueInputTypeEdit;
    if (this.state.formeditToggle == true) {
      if (this.state.attributeInputType == "Textbox") {
        attributeValueInputTypeEdit = (
          <Form.Control
            type="text"
            defaultValue={this.state.attval}
            onChange={(event) => this.handleEditChange(event, "Textbox")}
          />
        );
      } else if (this.state.attributeInputType == "Dropdown") {
        attributeValueInputTypeEdit = (
          <Form.Select
            // defaultValue={this.state.attkey}
            value={this.state.selectedValueDropdown}
            componentClass="select"
            onChange={(event) => this.handleEditChange(event, "Dropdown")}
          >
            <option value="">select</option>
            {this.renderDropdownList(this.state.dropdownOptions)}
          </Form.Select>
        );
      } else if (this.state.attributeInputType == "DateTime") {
          attributeValueInputTypeEdit = (
          <Datetime
            defaultValue={this.state.attval}
            onChange={(event) => this.handleEditChange(event, "DateTime")}
            locale="en"
            dateFormat={dateformat.slice(0, 10).toUpperCase()}
            timeFormat={true}
            value={moment(this.state.attval)}
           
          />
        );
      } else if (this.state.attributeInputType == "Multiselect List") {
        attributeValueInputTypeEdit = (
          <div>
            <div className="colSearDv multiSelDv">
              <ReactMultiSelectCheckboxes
                placeholderButtonLabel={this.props.translator["Select"]}
                value={this.state.selectedValueMultiselect}
                rightAligned={false}
                options={this.state.dropdownOptions}
                onChange={(event) => {
                  this.setAttVal(event, "Multiselect List");
                }}
              />
            </div>
          </div>
        );
      }
    }

    return (
      <div>
        <Row>
          <Col xs={12}>
            <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                  {this.props.ciStatusName == "In stock" ||
                  this.props.ciStatusName == "Decommissioned" ||
                  this.props.ciStatusName == "Disposed" ||
                  this.props.ciStatusName == "Deleted" ? (
                    ""
                  ) : this.props.isRoleDisabled ? (
                    <Button
                      onClick={() => {
                        this.formToggleFun(true);
                      }}
                      bsPrefix=" "
                      bsClass=""
                      bsStyle=""
                      title={this.props.translator["Add"]}
                    >
                      <HiPlus />
                    </Button>
                  ) : null}
                </li>
                <li className="d-md-none">
                  <button
                    type="button"
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                      this.props.rightEditPanel(false);
                    }}
                  >
                    <IoClose />
                  </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["Specify Attribute Values"]}
              </div>
            </div>
          </Col>
        </Row>

        <div className="rBoxGap">
          {this.state.formaddToggle ? (
            <div>
              <Table responsive striped bordered condensed hover>
                {header}
                <tbody>{this.renderAtt(this.props.ciAtt)}</tbody>
              </Table>
            </div>
          ) : null}

          {this.state.formToggle ? (
            <div>
              {this.state.showError ? (
                <Alert
                  style={{
                    marginBottom: "15px",
                    fontSize: "13px",
                    padding: "8px 30px 8px 8px",
                  }}
                  variant="danger"
                >
                  <h4>{this.state.showErrorText}</h4>
                </Alert>
              ) : (
                ""
              )}
              <Table bordered>
                <thead>
                  <tr>
                    <th>Attribute Name</th>
                    <th>Value</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.attributes.map((attr, index) => (
                      <tr key={`${attr.CI_ATT_MAP_ID}-${index}`}>
                      <td>
                        <Form.Select
                          componentClass="select"
                          onChange={(event) => this.setAttId(event, index)}
                          value={this.state.attributes[index].ATTRIBUTE_ID}
                        >
                          <option value="">Select</option>
                          {this.renderAttList(this.props.ciAtt)}
                        </Form.Select>
                      </td>

                      <td className="rdtDiv">
                        {console.log(
                          `Row ${index}: Rendering INPUT_TYPE =`,
                          attr.INPUT_TYPE
                        )}

                        {attr.INPUT_TYPE === "Textbox" && (
                          <Form.Control
                            type="text"
                            value={attr.VALUE}
                            onChange={(event) =>
                              this.setAttVal(event, index, "Textbox")
                            }
                          />
                        )}

                        {attr.INPUT_TYPE === "Dropdown" &&
                          attr.dropdownOptions &&
                          attr.dropdownOptions.length > 0 && (
                            <Form.Select
                              value={attr.VALUE}
                              onChange={(event) =>
                                this.setAttVal(event, index, "Dropdown")
                              }
                            >
                              <option value="">Select</option>
                              {attr.dropdownOptions.map((option) => (
                                <option
                                  key={option.field1Value}
                                  value={option.field1Value}
                                >
                                  {option.field1Key}
                                </option>
                              ))}
                            </Form.Select>
                          )}

                        {attr.INPUT_TYPE === "DateTime" && (
                          <Datetime
                            value={moment(attr.VALUE)}
                            onChange={(event) =>
                              this.setAttVal(event, index, "DateTime")
                            }
                            locale="en"
                            dateFormat={dateformat.slice(0, 10).toUpperCase()}
                            timeFormat={true}
                          />
                        )}

                        {attr.INPUT_TYPE === "Multiselect List" &&
                          attr.dropdownOptions &&
                          attr.dropdownOptions.length > 0 && (
                            <ReactMultiSelectCheckboxes
                              placeholderButtonLabel={
                                this.props.translator["Select"]
                              }
                              value={attr.VALUE}
                              options={attr.dropdownOptions}
                              onChange={(event) =>
                                this.setAttVal(event, index, "Multiselect List")
                              }
                            />
                          )}
                      </td>

                      <td style={{ whiteSpace: "nowrap" }}>
                        {index === 0 && this.state.attributes.length === 1 ? (
                          <Button variant="primary" onClick={this.addRow}>
                            <HiPlus />
                          </Button>
                        ) : (
                          <Button
                            variant="danger"
                            onClick={() => this.removeRow(index)} // Ensure index is passed here
                          >
                            <HiOutlineTrash />
                          </Button>
                        )}
                        {index === this.state.attributes.length - 1 &&
                          this.state.attributes.length > 1 && (
                            <Button
                              variant="primary"
                              onClick={this.addRow}
                              className="ms-2"
                            >
                              <HiPlus />
                            </Button>
                          )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Button
                onClick={() => {
                  this.saveatt();
                }}
                bsSize="xsmall"
                className="rgSidrkBtn smallBtn"
              >
                {this.props.translator["Save"]}
              </Button>
              <Button
                onClick={() => {
                  this.formCancelFun(true);
                }}
                bsSize="xsmall"
                className="rgSiLigBtn smallBtn"
              >
                {this.props.translator["Cancel"]}
              </Button>
            </div>
          ) : (
            <div>{msg}</div>
          )}

          {this.state.formeditToggle ? (
            <div>
              {this.state.showError ? (
                <Alert
                  style={{
                    marginBottom: "15px",
                    fontSize: "13px",
                    padding: "8px 30px 8px 8px",
                  }}
                  variant="danger"
                >
                  <h4>{this.state.showErrorText}</h4>
                </Alert>
              ) : (
                ""
              )}
              <div className="f-size-14 margin-b-5 border-b">
                {this.props.translator["Edit Attribute"]}
              </div>
              <Table className="simTable">
                <tbody>
                  <tr>
                    <td>{this.props.translator["Name"]}</td>
                    <td>
                      <Form.Control
                        type="text"
                        value={this.state.attname}
                        readOnly="true"
                      ></Form.Control>
                    </td>
                  </tr>
                  <tr>
                    <td>{this.props.translator["Value"]}</td>
                    <td className="rdtDiv">{attributeValueInputTypeEdit}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <ButtonToolbar>
                        <Button
                          onClick={() => {
                            this.submitatt();
                          }}
                          bsSize="xsmall"
                          className="rgSidrkBtn smallBtn"
                        >
                          {this.props.translator["Update"]}
                        </Button>
                        <Button
                          onClick={() => {
                            this.formCancelFun(true);
                          }}
                          bsSize="xsmall"
                          className="rgSiLigBtn smallBtn"
                        >
                          {this.props.translator["Cancel"]}
                        </Button>
                      </ButtonToolbar>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export function mapStateToProps({ ciAtt }) {
  return {
    ciAtt,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadCIAtt, loadModelName }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CmdbAttribute);
