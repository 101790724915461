
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from "react";
import { connect } from "react-redux";
import ListLoader from "../../../../components/common/loaders/ListLoader";
import { loadImplPlanList } from "../../../../actions/common/attachmentAction";
import { loadFulfillmentListComponent } from "../../../../actions/breakFix/quickViewAction";
import { loadBreakFixDataBackground } from "../../../../actions/breakFix/breakFixEditDetailsAction";
import { loadFulfillmentDataBackground } from "../../../../actions/fulfillment/fulfillmentDataAction";
import { loadChangeDataBackground } from "../../../../actions/changeManagement/changeManagementAction.js";
import { loadInvestigationDataBackground } from "../../../../actions/investigation/investigationAction.js";
import { bindActionCreators } from "redux";
import _ from "lodash";
import configureStore from "../../../../store/configureStore";
const store = configureStore();
import moment from "moment";
import { ListGroup, Table, Accordion } from "react-bootstrap";
import {IoClose} from "react-icons/io5";
const Joi = require("joi");
class UploadScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filesPreview: [],
      filesToBeSent: [],
      printcount: 1,
      clickAttachButton: 0,
    };
  }
  componentWillMount() {
    if (
      this.props.taskDetails.workItemType == "Breakfix" || this.props.taskDetails.workItemType == "Incident" ||
      this.props.taskDetails.workItemType == "Break Fix"
    ) {
      this.props.loadBreakFixDataBackground(this.props.taskDetails.workItemId);
    } else if (this.props.taskDetails.workItemType == "Fullfilment") {
      this.props.loadFulfillmentDataBackground(this.props.taskDetails.workItemId);
      this.props.loadFulfillmentListComponent(
        this.props.taskDetails.workItemId
      );
    } else if (this.props.taskDetails.workItemType == "ChangeManagement" || this.props.taskDetails.workItemType==="Change") {
      this.props.loadChangeDataBackground(this.props.taskDetails.workItemId, "");
    } else if (this.props.taskDetails.workItemType == "Investigation" || this.props.taskDetails.workItemType==="Problem") {
      this.props.loadInvestigationDataBackground(this.props.taskDetails.workItemId);
    }
  }
  getAttachment(module, itemId) {
    //let itemId="";
    let moduleName = "";
    //alert("==="+module);
    if (module == "Breakfix" || module == "Break Fix" || module == "Incident") {
      moduleName = "Breakfix";
      //itemId = itemId;
    } else if (module == "Fullfilment" || module == "Fulfillment") {
      moduleName = "Item";
      //this.props.loadFulfillmentListComponent(itemId)
      //itemId = this.props.taskDetails.workItemId;
    } else if (module == "ChangeManagement" || module == "Change") {
      moduleName = "ChangeManagement";
      //itemId = this.props.taskDetails.workItemId;
    } else if (module == "Investigation" || module == "Problem") {
      moduleName = "Investigation";
      //itemId = this.props.taskDetails.workItemId;
    }
    this.props.loadImplPlanList(moduleName, itemId);
  }

  renderComponents(componentList) {
    // debugger
    if (componentList.length == 0) {
      return <div>No Component Is Available</div>;
    }
    //alert(componentList)
    let componentsMarkup = componentList.map((item) => {
      return (
        <ListGroup.Item bsClass="">
          <div className="details">
            <div className="float-l">
              {" "}
              <i className="fa fa-check bullets"></i>
            </div>
            <div className="margin-l-30">{item.componentNameSx}</div>
          </div>
        </ListGroup.Item>
      );
    });
    return componentsMarkup;
  }

  renderAttachedItems(attachments) {
    //console.log("attachments");
    //console.log(attachments);
    //alert(attachments.length)
    if (attachments.length == undefined) {
      return (
        <tr>
          <td colSpan="2">
            <ListLoader />
          </td>
        </tr>
      );
    } else if(attachments.length == 0){
      return (
        <tr>
          <td colSpan="2">{this.props.translator["loading attachments..."]}</td>
        </tr>
      );
    } else {
      return attachments.map((attachment) => {
        let validated_url = undefined;
        if(attachment.filename === null && (attachment.planType === "Implementation" || attachment.planType === "Backout")){
          return null;
        }
        // if (attachment != null || attachment.fileTempURL != undefined) {
        if (attachment && attachment.fileTempURL != undefined) {
          let namedSchema = Joi.string().regex(/^[^<>}{]+$/);
          let validator_to_add_myQbj = namedSchema.validate(
            attachment.fileTempURL
          );
          if (validator_to_add_myQbj.error == null) {
            validated_url = attachment.fileTempURL;
          } else {
            console.log("Validation failed : ", validator_to_add_myQbj);
            validated_url = "\\";
          }
        } else validated_url = "\\";
        if (attachment.filename === undefined || attachment.filename === null) {
          attachment.filename = "";
        } else if (attachment.filename.indexOf("##") > -1) {
          attachment.filename = attachment.filename.slice(15);
        }

        if (attachment.status == "Y" || attachment.planType!==null) {
          return null;
        } else {
          return (
            <tr bsClass="" key={attachment.attachmentId}>
              <td width="85%">{attachment.filename}</td>
              <td width="15%" className="text-c">
                {console.log("attachment.fileTempURL : ", validated_url)}
                <a href={validated_url}>
                  <i className="fa fa-eye"></i>
                </a>
                &nbsp;&nbsp;
                {/* <a href={attachment.fileTempURL} ><i className="fa fa-eye"></i></a>&nbsp;&nbsp; */}
                {/*<i className="fa fa-times" style={{ cursor: "pointer" }} onClick={() => { this.deleteFileAction(attachment.attachmentId, attachment.module, attachment.itemId);}}></i>*/}
              </td>
            </tr>
          );
        }
      });
    }
  }
  render() {
    //alert(this.props.breakFixEditDetails.length);
    //console.log(this.props.breakFixEditDetails)
    if (
      this.props.taskDetails.workItemType == "Breakfix" || this.props.taskDetails.workItemType == "Incident" ||
      this.props.taskDetails.workItemType == "Break Fix"
    ) {
      if (this.props.isFetchingDetails) {
        return (
          <div className="App">
            <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    this.props.changeActionView("");
                    }}
                >
                    <IoClose />
                </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["Task Item Details"]}
              </div>
            </div>
            <div className="rBoxGap">
              <div className="text-c">
                <ListLoader />
              </div>
            </div>
          </div>
        );
      } else if (!this.props.breakFixEditDetails) {
        return (
          <div className="App">
            <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    this.props.changeActionView("");
                    }}
                >
                    <IoClose />
                </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["Task Item Details"]}
              </div>
            </div>
            <div className="rBoxGap">
              <div className="text-c">There is no matching data available</div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="App">
            <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    this.props.changeActionView("");
                    }}
                >
                    <IoClose />
                </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["Task Item Details"]}
              </div>
            </div>
            <div className="rBoxGap">
            <div className="rwTable" style={{borderTop:"none"}}>
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Parent Workitem Id"]}
              </div>
              <div>{this.props.breakFixEditDetails.breakfixNumber}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Description"]}
              </div>
              <div>{this.props.breakFixEditDetails.description}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Reported On"]}
              </div>
              <div>{this.props.breakFixEditDetails.createdOn}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Service"]}
              </div>
              <div>{this.props.breakFixEditDetails.serviceName}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["CI Name"]}
              </div>
              <div> {this.props.breakFixEditDetails.ciName}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Support Group"]}
              </div>
              <div>{this.props.breakFixEditDetails.spGroupname}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Support Individual"]}
              </div>
              <div>{this.props.breakFixEditDetails.spUsername}</div>
            </div>

            <div className="margin-t-10">
              <Accordion
                id="rightSidePanel4"
                className="brkFix brkFixOrder"
                defaultActiveKey="1"
              >
                <Accordion.Item eventKey="2" className="collapsed">
                  <Accordion.Header
                    onClick={() => {
                      this.getAttachment(
                        this.props.taskDetails.workItemType,
                        this.props.taskDetails.workItemId
                      );
                    }}
                  >
                    <h4>Attachment</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Table
                      responsive
                      striped
                      condensed
                      hover
                      className="f-size-13 wrpAttTab"
                      style={{ marginBottom: "0px" }}
                    >
                      {this.renderAttachedItems(this.props.attachmentDetails)}
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            </div>
          </div>
        );
      }
    } else if (
      this.props.taskDetails.workItemType == "Fullfilment" ||
      this.props.taskDetails.workItemType == "Fulfillment"
    ) {
      if (this.props.isFetchingDetails) {
        return (
          <div className="App">
            <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    this.props.changeActionView("");
                    }}
                >
                    <IoClose />
                </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["Task Item Details"]}
              </div>
            </div>
            <div className="rBoxGap">
              <div className="text-c">
                <ListLoader />
              </div>
            </div>
          </div>
        );
      } else if (!this.props.fulfillmentDetails) {
        return (
          <div className="App">
            <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    this.props.changeActionView("");
                    }}
                >
                    <IoClose />
                </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["Task Item Details"]}
              </div>
            </div>
            <div className="rBoxGap">
              <div className="text-c">There is no matching data available</div>
            </div>
          </div>
        );
      } else {
        let asgusername = "";
        if (
          this.props.fulfillmentDetails.asgUsername == "0" ||
          this.props.fulfillmentDetails.asgUsername == "Select"
        )
          asgusername = "";
        else asgusername = this.props.fulfillmentDetails.asgUsername;
        return (
          <div className="App">
            <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    this.props.changeActionView("");
                    }}
                >
                    <IoClose />
                </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["Task Item Details"]}
              </div>
            </div>
            <div className="rBoxGap">
            <div className="rwTable" style={{borderTop:"none"}}>
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Parent Workitem Id"]}
              </div>
              <div>{this.props.taskDetails.parentWorkItemId}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Fulfiller Group"]}
              </div>
              <div>{this.props.fulfillmentDetails.asgGroupname}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Fulfiller"]}
              </div>
              <div>{asgusername}</div>
            </div>
            <div className="rwTable">
            <div className="ListDetails margin-b-5">
                  <span className="rwKey">
                    {this.props.translator["Components"]}
                  </span>
                </div>
                {this.renderComponents(
                  this.props.selectedFulfillmentItemComponent
                )}
              </div>

              <div className="margin-t-10">
              <Accordion
                id="rightSidePanel4"
                className="brkFix brkFixOrder brkFullAccordion"
                defaultActiveKey="1"
              >
                <Accordion.Item eventKey="2" className="collapsed">
                  <Accordion.Header
                    onClick={() => {
                      this.getAttachment(
                        this.props.taskDetails.workItemType,
                        this.props.taskDetails.workItemId
                      );
                    }}
                  >
                    <h4>Attachment</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Table
                      responsive
                      striped
                      condensed
                      hover
                      className="f-size-13 wrpAttTab"
                      style={{ marginBottom: "0px" }}
                    >
                      {this.renderAttachedItems(this.props.attachmentDetails)}
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              </div>
              </div>
            </div>
        );
      }
    } else if (this.props.taskDetails.workItemType == "ChangeManagement" || this.props.taskDetails.workItemType==="Change") {
      if (this.props.isFetchingDetails) {
        return (
          <div className="App">
            <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                      this.props.changeActionView("");
                    this.props.rightEditPanel(false);
                    }}
                >
                    <IoClose />
                </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["Task Item Details"]}
              </div>
            </div>
            <div className="rBoxGap">
              <div className="text-c">
                <ListLoader />
              </div>
            </div>
          </div>
        );
      } else if (!this.props.editChangeInitialData) {
        return (
          <div className="App">
            <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    this.props.changeActionView("");
                    }}
                >
                    <IoClose />
                </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["Task Item Details"]}
              </div>
            </div>
            <div className="rBoxGap">
              <div className="text-c">There is no matching data available</div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="App">
            <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    this.props.changeActionView("");
                    }}
                >
                    <IoClose />
                </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["Task Item Details"]}
              </div>
            </div>
            <div className="rBoxGap">
            <div className="rwTable" style={{borderTop:"none"}}>
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Parent Workitem Id"]}
              </div>
              <div> {this.props.taskDetails.parentWorkItemId}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Service Name"]}
              </div>
              <div> {this.props.editChangeInitialData.serviceName}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["CI Name"]}
              </div>
              <div> {this.props.editChangeInitialData.ciName}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Change Management Group"]}
              </div>
              <div> {this.props.editChangeInitialData.impGroupname}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Change Manager"]}
              </div>
              <div>{this.props.editChangeInitialData.changeUsername}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Summary"]}
              </div>
              <div>{this.props.editChangeInitialData.description}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Change Timing"]}
              </div>
              <div> {this.props.editChangeInitialData.changeTiming}</div>
            </div>

            <div className="margin-t-10">
              <Accordion
                id="rightSidePanel4"
                className="brkFix brkFixOrder"
                defaultActiveKey="1"
              >
                <Accordion.Item eventKey="2" className="collapsed">
                  <Accordion.Header
                    onClick={() => {
                      this.getAttachment(
                        this.props.taskDetails.workItemType,
                        this.props.taskDetails.workItemId
                      );
                    }}
                  >
                    <h4>Attachment</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Table
                      responsive
                      striped
                      condensed
                      hover
                      className="f-size-13 wrpAttTab"
                      style={{ marginBottom: "0px" }}
                    >
                      {this.renderAttachedItems(this.props.attachmentDetails)}
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            </div>
          </div>
        );
      }
    } else if (this.props.taskDetails.workItemType == "Investigation" || this.props.taskDetails.workItemType==="Problem") {
      if (this.props.isFetchingDetails) {
        return (
          <div className="App">
            <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    this.props.changeActionView("");
                    }}
                >
                    <IoClose />
                </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["Task Item Details"]}
              </div>
            </div>
            <div className="rBoxGap">
              <div className="text-c">
                <ListLoader />
              </div>
            </div>
          </div>
        );
      } else if (!this.props.investigationItemDetails) {
        return (
          <div className="App">
            <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    this.props.changeActionView("");
                    }}
                >
                    <IoClose />
                </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["Task Item Details"]}
                <font color="green">
                  <b>There is no matching data available</b>
                </font>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="App">
            <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                  <li>
                  <button
                      type='button'
                      title="Minimize the right panel"
                      bsClass=""
                      bsStyle=""
                      className="closerightPanelBtn"
                      onClick={() => {
                      this.props.rightEditPanel(false);
                      this.props.changeActionView("");
                      }}
                  >
                      <IoClose />
                  </button>
                  </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                {this.props.translator["Task Item Details"]}
              </div>
            </div>
            <div className="rBoxGap">
            <div className="rwTable" style={{borderTop:"none"}}>
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Parent Workitem Id"]}
              </div>
              <div> {this.props.taskDetails.parentWorkItemId}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Problem Statement"]}
              </div>
              <div style={{ "word-break": "break-word" }} > {this.props.investigationItemDetails?.data?.[0]?.description}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Reported On"]}
              </div>
              <div>  {this.props.investigationItemDetails?.data?.[0]?.createdOn}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Service Name"]}
              </div>
              <div>  {this.props.investigationItemDetails?.data?.[0]?.serviceName}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["CI Name"]}
              </div>
              <div> {this.props.investigationItemDetails?.data?.[0]?.ciName}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Service Criticality"]}
              </div>
              <div>  {this.props.investigationItemDetails?.data?.[0]?.serviceCriticality}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Problem Management Group"]}
              </div>
              <div>   {this.props.investigationItemDetails?.data?.[0]?.asgPmGroupname}</div>
            </div>
            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
              {this.props.translator["Problem Manager"]}
              </div>
              <div>   {this.props.investigationItemDetails?.data?.[0]?.asgPmName}</div>
            </div>


            <div className="margin-t-10">
              <Accordion
                id="rightSidePanel4"
                className="brkFix brkFixOrder"
                defaultActiveKey="1"
              >
                <Accordion.Item eventKey="2" className="collapsed">
                  <Accordion.Header
                    onClick={() => {
                      this.getAttachment(
                        this.props.taskDetails.workItemType,
                        this.props.taskDetails.workItemId
                      );
                    }}
                  >
                    <h4>Attachment</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Table
                      responsive
                      striped
                      condensed
                      hover
                      className="f-size-13 wrpAttTab"
                      style={{ marginBottom: "0px" }}
                    >
                      {this.renderAttachedItems(this.props.attachmentDetails)}
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="App">
            <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                  <li>
                  <button
                      type='button'
                      title="Minimize the right panel"
                      bsClass=""
                      bsStyle=""
                      className="closerightPanelBtn"
                      onClick={() => {
                      this.props.rightEditPanel(false);
                      this.props.changeActionView("");
                      }}
                  >
                      <IoClose />
                  </button>
                  </li>
              </ul>
          </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {this.props.translator["Task Item Details"]}
            </div>
          </div>
          <div className="rBoxGap">
            <div>No Details found</div>
          </div>
        </div>
      );
    }
  }
}
const style = {
  margin: 15,
};

export function mapStateToProps(state) {
  return {
    //spcmReducer:state.spcmReducer,
    attachmentDetails: state.implememtationPlanList,
    breakFixEditDetails: state.breakFixEditDetails,
    selectedFulfillmentItemComponent: state.selectedFulfillmentItemComponent,
    fulfillmentDetails: state.fulfillmentDetails,
    isFetchingDetails: state.isFetchingDetails.isFetching,
    editChangeInitialData: state.editChangeInitialData,
    investigationItemDetails: state.investigationItemDetails,
    //isFetchingInvestigation:state.isFetchingDetails.isFetching
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadImplPlanList,
      loadBreakFixDataBackground,
      loadFulfillmentListComponent,
      loadFulfillmentDataBackground,
      loadChangeDataBackground,
      loadInvestigationDataBackground,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadScreen);
//export default connect(mapStateToProps, { loadImplPlanList,loadBreakFixEditDetails })(UploadScreen);
