
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from 'react';
import { Table, Form } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import ListLoader from '../../common/loaders/ListLoader';
import Pagination from "react-js-pagination";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import ReactDOM from "react-dom";
import { IoSearch } from "react-icons/io5";
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import { usePreviousValue } from '../../../helpers/usePreviousValue';

import Cookies from 'universal-cookie';
import { getTOTList } from '../../../actions/spcmActions';
const cookies = new Cookies();

let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
let dateformatCookie = homepagelocation[2];
let result = dateformatCookie.substr(0, 10);

const List = (props) => {
  const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false);
  const [tableNameSearchFilter, setTableNameSearchFilter] = useState("");
  const [tableLabelSearchFilter, setTableLabelSearchFilter] = useState("");
  const [modulenameSearchFilter, setModulenameSearchFilter] = useState("");
  const [microserviceSearchFilter, setMicroserviceSearchFilter] = useState("");
  const [lastModifiedBySearchFilter, setLastModifiedBySearchFilter] = useState("");
  const windowSize = useSelector((state) => state.windowSize.width);
  const previousValue = usePreviousValue(props);

    const dispatch = useDispatch();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const totList = useSelector((state) => state.spcmReducer.totList);
    const commonLoader = useSelector((state) => state.commonLoader.isLoading);
    const multiSelectDropdownRef = useRef(null);

    let usedByrulesOptions = [
      {label: 'Yes', value: 'YES'},
      {label: 'No', value: 'NO'}
    ];

    useEffect(() => {    
      document.addEventListener('mousedown', clickOutside);
      if (multiSelectDropdownRef != undefined || multiSelectDropdownRef != null) {
        multiSelectDropdownRef.current.childNodes[0].className = "multiSelectRef";
        let myRef = multiSelectDropdownRef;
        if (props.isUsedRuleOpen != previousValue?.isUsedRuleOpen) {
          let obj = ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1];        
          if (obj != undefined || obj != null) {
            ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1].className = "multiSelectPopup";
          }
        }
      }
    }, [props.isUsedRuleOpen])
  
    useEffect(() => {
      return () => {
        document.removeEventListener('mousedown', clickOutside);
      }
    }, [])

    const clickOutside = (event) =>  {
      const rulesDiv = document.getElementById("rulesDiv");
  
      if (multiSelectDropdownRef.current != undefined || multiSelectDropdownRef.current != null) {
          document.addEventListener("mousedown", (event) => {
            if (rulesDiv.contains(event.target)) {
            setIsMultiSelectOpen(true);
            props.showRightSideFun(false);
          }
          else {
            setIsMultiSelectOpen(false);
          }
          props.multiSelectOpenFun("dropdownClose");
        });
      }
    }

  const handleSearch = (e,picker,val) => {
    let searchByVar = [];
    let searchByValue = [];
    let dateArr = [];
    let pattern = [];
    if (props.tableNameSearchInput != "") {
      searchByValue.push(props.tableNameSearchInput.trim());
      searchByVar.push(tableNameSearchFilter);
      pattern.push("like");
    }
    if (props.tableLabelSearchInput != "") {
      searchByValue.push(props.tableLabelSearchInput.trim());
      searchByVar.push(tableLabelSearchFilter);
      pattern.push("like");
    }
    if (props.modulenameSearchInput != "") {
      searchByValue.push(props.modulenameSearchInput.trim());
      searchByVar.push(modulenameSearchFilter);
      pattern.push("like");
    }
    if (props.microserviceSearchInput != "") {
      searchByValue.push(props.microserviceSearchInput.trim());
      searchByVar.push(microserviceSearchFilter);
      pattern.push("like");
    }
    if (props.lastModifiedBySearchInput != "") {
      searchByValue.push(props.lastModifiedBySearchInput.trim());
      searchByVar.push(lastModifiedBySearchFilter);
      pattern.push("like");
    }
    
    
    if (picker && val == "lastModifiedOn") {
      console.log("BOKKA 1")
      props.setModifiedDateRef(picker);
      dateArr.push(moment(picker.startDate).format("MM-DD-YYYY"), moment(picker.endDate).format("MM-DD-YYYY"));
      searchByValue.push(dateArr.join("&"));
      searchByVar.push("lastModifiedOn");
      pattern.push("between");

    }else if ( picker == undefined && !_.isEmpty(props.modifiedStartDate) && !_.isEmpty(props.modifiedEndDate)) {
      console.log("BOKKA 2")

      dateArr.push(moment(props.modifiedStartDate).format("MM-DD-YYYY"), moment(props.modifiedEndDate).format("MM-DD-YYYY"));
      searchByValue.push(dateArr.join("&"));
      searchByVar.push("lastModifiedOn");
      pattern.push("between");

    }


    console.log("BOKKA 5",searchByValue)
    console.log("BOKKA 6",searchByVar)
    
    props.setClearAllFilterToggle(true);

    props.setsearchValue(searchByValue.join("|"));
    props.setsearchKey(searchByVar.join(","));
    props.setPatternList(pattern.join(","));
    props.showRightSideFun(false);

    
    dispatch(getTOTList(searchByVar.join(","),searchByValue.join("|"),props.usedByRulesOpt,props.page,props.size,pattern.join(",")));


  };

  const handleChange = (e, searchValue) => {
    switch (searchValue) {
      case "tableName":
        props.setTableNameSearchInput(e.target.value);
        setTableNameSearchFilter(searchValue);
        break;
      case "tableLabel":
        props.setTableLabelSearchInput(e.target.value);
        setTableLabelSearchFilter(searchValue);
        break;
      case "moduleName":
        props.setModulenameLabelSearchInput(e.target.value);
        setModulenameSearchFilter(searchValue);
        break;
      case "microservice":
        props.setMicroserviceSearchInput(e.target.value);
        setMicroserviceSearchFilter(searchValue);
        break;
      case "lastModifiedBy":
        props.setLastModifiedBySearchInput(e.target.value);
        setLastModifiedBySearchFilter(searchValue);
        break;
    }
  };


  const onPageSizeChange = (e) => {
    props.showRightSideFun(false);
    props.setLimit(e.target.value);
    dispatch(getTOTList(props.searchKey,props.searchValue,props.usedByRulesOpt,props.page,e.target.value,props.patternList));

  }

  const onPageChange = (e) => {
    props.showRightSideFun(false);
    props.setPage(e-1);
    dispatch(getTOTList(props.searchKey,props.searchValue,props.usedByRulesOpt,(e-1),props.size,props.patternList));

  }

  const onRowSelect = (e, row, index) => {
    props.setRightSidePanel('shortDesc');
    props.totListHandler(row);
    props.showRightSideFun(true);
    props.showActiveRow(index);

}
  const renderTable = (data) => {

    return data.map((data, index) => {
      return(
        <tr key={index} className={props.isRightSideVisible && index == props.rowIndex ? "myActive" : ""} onClick={(e) => onRowSelect(e, data, index)}>
        <td>{data.tableName}</td>
        <td>{data.tableLabel}</td>
        <td>{data.moduleName}</td>
        <td>{data.microservice}</td>
        <td>{data.usedByRule}</td>
        <td>{data.lastModifiedBy}</td>
        <td>{data.lastModifiedOn}</td>
        </tr>
      )
  })
  }

  const handleCallback = (filter, start, end) => {
    let modifiedDateFilterValue = [];
    switch (filter) {
      case 'lastModifiedOn':
        modifiedDateFilterValue.push(start.format("YYYY-MM-DD HH:mm:ss"), end.format("YYYY-MM-DD HH:mm:ss"));
        props.setModifiedStartAndEndDate(start.format('MM/DD/YYYY HH:mm:ss'), end.format('MM/DD/YYYY HH:mm:ss'), modifiedDateFilterValue);
        break;
    }
  }

  const handleRulesdropChange = (value) =>  {
    let searchOnUsedbyrulesArr = [];
    props.setSelectedUsedbyRuleValue(value);
    (props.searchKey.length === 0) ?
        value.length == 0 ?
          props.setClearAllFilterToggle(false) :
          props.setClearAllFilterToggle(true) :
      props.setClearAllFilterToggle(true);

    props.showRightSideFun(false);
    value.map((checkBoxValue) => {
      return (
        searchOnUsedbyrulesArr.push(checkBoxValue.value)
      );
    }
    );
    props.setMultiSelectParams(searchOnUsedbyrulesArr);
  
    dispatch(getTOTList(props.searchKey,props.searchValue,searchOnUsedbyrulesArr,props.page,props.size,props.patternList));

    
  }

  let totDataList = totList ? totList.records : { data: [] };
  let meta = !_.isEmpty(totList) ? totList.metaInfo : {}; 
  let panelColSize = props.panelResize;
  let myModifiedStartDate;
  let myModifiedEndDate;
  if (result == "yyyy-MM-dd") {
    myModifiedStartDate = !_.isEmpty(props.modifiedStartDate) ? moment(props.modifiedStartDate).format("YYYY/MM/DD") : "";
    myModifiedEndDate = !_.isEmpty(props.modifiedEndDate) ? moment(props.modifiedEndDate).format("YYYY/MM/DD") : "";
  }
  else if (result == "dd-MM-yyyy") {
    myModifiedStartDate = !_.isEmpty(props.modifiedStartDate) ? moment(props.modifiedStartDate).format("DD/MM/YYYY") : "";
    myModifiedEndDate = !_.isEmpty(props.modifiedEndDate) ? moment(props.modifiedEndDate).format("DD/MM/YYYY") : "";
  }
  else {
    myModifiedStartDate = !_.isEmpty(props.modifiedStartDate) ? moment(props.modifiedStartDate).format("MM/DD/YYYY") : "";
    myModifiedEndDate = !_.isEmpty(props.modifiedEndDate) ? moment(props.modifiedEndDate).format("MM/DD/YYYY") : "";
  }
  let myModifiedNewDate = myModifiedStartDate == "" && myModifiedEndDate == "" ? "" : myModifiedStartDate + "-" + myModifiedEndDate;
  let caseResponsiveState = props.isUsedRuleOpen && (totDataList == undefined || totDataList.length === 0 || totDataList.length <=6 || commonLoader)
  let caseResponsive = caseResponsiveState ? "overFlowClp" : "table-responsive";
 
  const handleKeyPress = (event) => { };
    return(
        <>
            <div aria-label="Table" role="contentinfo" className={caseResponsiveState ? "respondv responDvMinH" : "respondv"}>
                <div className={"tableRgtBor " + caseResponsive}>
                    <Table striped bordered condensed hover className="tableView nowrapWhright" style={{ marginTop: "0px" }}>
                     <thead>
                            <tr>
                            <th>
                                    <div className="sortParArr">{tr["Table Name"]}</div>
                                    <div className="colSearDv ">
                                        <Form.Control
                                            className="colSearInp"
                                            type="text"
                                            value={props.tableNameSearchInput != "" ? props.tableNameSearchInput : ""}
                                            placeholder={tr["Search here"]}
                                            onKeyDown={(e) => {
                                                if (e.code == "Enter") {
                                                    if (
                                                        props.tableNameSearchInput != "" &&
                                                        props.tableNameSearchInput.trim().length > 3
                                                    )
                                                        handleSearch();
                                                }
                                            }}
                                            onChange={(event) => {
                                                handleChange(event, "tableName");
                                            }}
                                            onClick={() => props.multiSelectOpenFun("dropdownClose")}
                                        />
                                        {props.tableNameSearchInput.trim().length > 3 ?
                                            <a title="search" className="faicn" href={void (0)} onClick={(event) => handleSearch()}>
                                                <IoSearch />
                                            </a> : ""}
                                    </div>
                                </th>
                                <th>
                                    <div className="sortParArr">{tr["Table Label"]}</div>
                                    <div className="colSearDv ">
                                        <Form.Control
                                            className="colSearInp"
                                            type="text"
                                            value={props.tableLabelSearchInput != "" ? props.tableLabelSearchInput : ""}
                                            placeholder={tr["Search here"]}
                                            onKeyDown={(e) => {
                                                if (e.code == "Enter") {
                                                    if (
                                                        props.tableLabelSearchInput != "" &&
                                                        props.tableLabelSearchInput.trim().length > 3
                                                    )
                                                        handleSearch();
                                                }
                                            }}
                                            onChange={(event) => {
                                                handleChange(event, "tableLabel");
                                            }}
                                            onClick={() => props.multiSelectOpenFun("dropdownClose")}
                                        />
                                        {props.tableLabelSearchInput.trim().length > 3 ?
                                            <a title="search" className="faicn" href={void (0)} onClick={(event) => handleSearch()}>
                                                <IoSearch />
                                            </a> : ""}
                                    </div>
                                </th>
                                <th>
                                    <div className="sortParArr">{tr["Module Name"]}</div>
                                    <div className="colSearDv ">
                                        <Form.Control
                                            className="colSearInp"
                                            type="text"
                                            value={props.modulenameSearchInput != "" ? props.modulenameSearchInput : ""}
                                            placeholder={tr["Search here"]}
                                            onKeyDown={(e) => {
                                                if (e.code == "Enter") {
                                                    if (
                                                        props.modulenameSearchInput != "" &&
                                                        props.modulenameSearchInput.trim().length > 3
                                                    )
                                                        handleSearch();
                                                }
                                            }}
                                            onChange={(event) => {
                                                handleChange(event, "moduleName");
                                            }}
                                            onClick={() => props.multiSelectOpenFun("dropdownClose")}
                                        />
                                        {props.modulenameSearchInput.trim().length > 3 ?
                                            <a title="search" className="faicn" href={void (0)} onClick={(event) => handleSearch()}>
                                                <IoSearch />
                                            </a> : ""}
                                    </div>
                                </th>
                                <th>
                                    <div className="sortParArr">{tr["Microservice"]}</div>
                                    <div className="colSearDv ">
                                        <Form.Control
                                            className="colSearInp"
                                            type="text"
                                            value={props.microserviceSearchInput != "" ? props.microserviceSearchInput : ""}
                                            placeholder={tr["Search here"]}
                                            onKeyDown={(e) => {
                                                if (e.code == "Enter") {
                                                    if (
                                                        props.microserviceSearchInput != "" &&
                                                        props.microserviceSearchInput.trim().length > 2
                                                    )
                                                        handleSearch();
                                                }
                                            }}
                                            onChange={(event) => {
                                                handleChange(event, "microservice");
                                            }}
                                            onClick={() => props.multiSelectOpenFun("dropdownClose")}
                                        />
                                        {props.microserviceSearchInput.trim().length > 2 ?
                                            <a title="search" className="faicn" href={void (0)} onClick={(event) => handleSearch()}>
                                                <IoSearch />
                                            </a> : ""}
                                    </div>
                                </th>
                                <th>
                                    <div className="sortParArr">{tr["Used by rules"]}</div>
                                    <div
                                        id="rulesDiv"
                                        className="colSearDv"
                                        ref={multiSelectDropdownRef}
                                        onClick={() => props.multiSelectOpenFun("userRuled")}
                                    >
                                        <ReactMultiSelectCheckboxes
                                            placeholderButtonLabel={tr["Select"]}
                                            rightAligned={true}
                                            options={usedByrulesOptions}
                                            onChange={handleRulesdropChange}
                                            value={props.selectedUsedbyRuleValue}
                                        />
                                    </div>
                                </th>
                                <th>
                                    <div className="sortParArr">{tr["Last Modified By"]}</div>
                                    <div className="colSearDv ">
                                        <Form.Control
                                            className="colSearInp"
                                            type="text"
                                            value={props.lastModifiedBySearchInput != "" ? props.lastModifiedBySearchInput : ""}
                                            placeholder={tr["Search here"]}
                                            onKeyDown={(e) => {
                                                if (e.code == "Enter") {
                                                    if (
                                                        props.lastModifiedBySearchInput != "" &&
                                                        props.lastModifiedBySearchInput.trim().length > 3
                                                    )
                                                        handleSearch();
                                                }
                                            }}
                                            onChange={(event) => {
                                                handleChange(event, "lastModifiedBy");
                                            }}
                                            onClick={() => props.multiSelectOpenFun("dropdownClose")}
                                        />
                                        {props.lastModifiedBySearchInput.trim().length > 3 ?
                                            <a title="search" className="faicn" href={void (0)} onClick={(event) => handleSearch()}>
                                                <IoSearch />
                                            </a> : ""}
                                    </div>
                                </th>
                                <th>
                                    <div className="sortParArr">{tr["Last Modified On"]}</div>
                                      <div className="colSearDv" >
                                        <DateRangePicker
                                          initialSettings={{
                                            autoUpdateInput: false,
                                            maxDate: moment(),
                                            autoApply: true,
                                            parentEl:"#skipdv"
                                          }}
                                          onApply={(e, picker) => handleSearch(e, picker,"lastModifiedOn")}
                                          onCallback={(start, end) =>
                                            handleCallback("lastModifiedOn", start, end)
                                          }
                                        >
                                          <input
                                            type="text"
                                            value={myModifiedNewDate}
                                            placeholder={tr["Search here"]}
                                            readOnly
                                            className="form-control col-4"
                                            onPaste={handleKeyPress}
                                            onKeyDown={handleKeyPress}
                                          />
                                        </DateRangePicker>
                                      </div>
                                </th>                               
                                
                            </tr>
                        </thead>
                        <tbody>
                            {totDataList == undefined || commonLoader ?
                                <tr>
                                    <td colSpan="9" style={{ "textAlign": "center" }}><ListLoader /></td>
                                </tr> :
                                totDataList.length === 0 ?
                                    <tr>
                                        <td colSpan="9" style={{ "textAlign": "center" }}>{tr['There is no matching data available']}</td>
                                    </tr> :
                                    renderTable(totDataList)
                            }
                        </tbody>
                    </Table>
                </div>
                <div className="nBotPagina">
                <div className="nShow">
                    <div className="margin-r-10">{tr['Show']}:</div>
                    <Form.Select value={props.size} onChange={onPageSizeChange}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    </Form.Select>
                </div>

          <nav aria-label='Pagination' className='display-inline-block'>
            <Pagination
              prevPageText={tr['Prev']}
              nextPageText={tr['Next']}
              firstPageText={<i className='glyphicon glyphicon-menu-left' />}
              lastPageText={<i className='glyphicon glyphicon-menu-right' />}
              activePage={meta ? (meta.currentPage + 1) : 1}
              itemsCountPerPage={props.size}
              // totalItemsCount={meta ? meta.recordCount ? meta.recordCount : 1 : 1}
              totalItemsCount={meta ? meta.totalPageCount ? meta.totalPageCount : 1 : 1}
              pageRangeDisplayed={`${windowSize < 576 ? 3 : (props.panelResize <= 42 ? 2 : 5)}`}
              onChange={(e) => onPageChange(e)}
            /></nav>
        </div>
            </div>
        </>
    )
}
export default List;