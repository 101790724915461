
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from 'react';
import { Table, Form } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import ListLoader from '../../common/loaders/ListLoader';
import Pagination from "react-js-pagination";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import ReactDOM from "react-dom";
import { IoSearch } from "react-icons/io5";
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import { usePreviousValue } from '../../../helpers/usePreviousValue';

import Cookies from 'universal-cookie';
import { getTOAList } from '../../../actions/spcmActions';
const cookies = new Cookies();

let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
let dateformatCookie = homepagelocation[2];
let result = dateformatCookie.substr(0, 10);

const List = (props) => {
  const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false);
  const [tableNameSearchFilter, setTableNameSearchFilter] = useState("");
  const [attributeSearchFilter, setAttributeSearchFilter] = useState("");
  const [attributeLabelSearchFilter, setAttributeLabelSearchFilter] = useState("");
  const [lastModifiedBySearchFilter, setLastModifiedBySearchFilter] = useState("");
  const windowSize = useSelector((state) => state.windowSize.width);
  const previousValue = usePreviousValue(props);

  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const toaList = useSelector((state) => state.spcmReducer.toaList);
  const commonLoader = useSelector((state) => state.commonLoader.isLoading);
  const multiSelectStatusDropdownRef = useRef(null);
  const multiSelectDataTypeDropdownRef = useRef(null);
  
  let statusOptions = [
    {label: 'Active', value: 'active'},
    {label: 'Inactive', value: 'inactive'}
  ];
  let datatypeOptions = [
    {label: 'Jsonb', value: 'jsonb'},
    {label: 'Double precision', value: 'double precision'},
    {label: 'Character', value: 'character'},
    {label: 'Boolean', value: 'boolean'},
    {label: 'Timestamp', value: 'timestamp'},
    {label: 'Text', value: 'text'},
    {label: 'Bigint', value: 'bigint'},
    {label: 'Integer', value: 'integer'},
    {label: 'Json', value: 'json'},
    {label: 'Real', value: 'real'}
  ];

  useEffect(() => {    
    document.addEventListener('mousedown', clickOutside);
    if (multiSelectStatusDropdownRef != undefined || multiSelectStatusDropdownRef != null) {
      multiSelectStatusDropdownRef.current.childNodes[0].className = "multiSelectRef";
      let myRef = multiSelectStatusDropdownRef;
      if (props.isStatusOpen != previousValue?.isStatusOpen) {
        let obj = ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1];        
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1].className = "multiSelectPopup";
        }
      }
    }
    if (multiSelectDataTypeDropdownRef != undefined || multiSelectDataTypeDropdownRef != null) {
      multiSelectDataTypeDropdownRef.current.childNodes[0].className = "multiSelectRef";
      let myRef = multiSelectDataTypeDropdownRef;
      if (props.isDataTypeOpen != previousValue?.isDataTypeOpen) {
        let obj = ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1];        
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1].className = "multiSelectPopup";
        }
      }
    }
  }, [props.isStatusOpen,props.isDataTypeOpen])

  useEffect(() => {
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    }
  }, [])
  const clickOutside = (event) =>  {
    const statusDiv = document.getElementById("statusDiv");
    const datatypeDiv = document.getElementById("datatypeDiv");

    if (multiSelectStatusDropdownRef.current != undefined || multiSelectStatusDropdownRef.current != null) {
        document.addEventListener("mousedown", (event) => {
          if (datatypeDiv.contains(event.target)) {
            setIsMultiSelectOpen(true);
            props.showRightSideFun(false);
          }
          if (statusDiv.contains(event.target)) {
          setIsMultiSelectOpen(true);
          props.showRightSideFun(false);
        }
        else {
          setIsMultiSelectOpen(false);
        }
        props.multiSelectOpenFun("dropdownClose");
      });
    }
  }

  const handleSearch = (e,picker,val) => {
    let searchByVar = [];
    let searchByValue = [];
    let pattern = [];
    let dateArr = [];
    let refreshedDateArr = [];
    if (props.tableNameSearchInput != "") {
      searchByValue.push(props.tableNameSearchInput.trim());
      searchByVar.push(tableNameSearchFilter);
      pattern.push("like");

    }
    if (props.attributeSearchInput != "") {
      searchByValue.push(props.attributeSearchInput.trim());
      searchByVar.push(attributeSearchFilter);
      pattern.push("like");

    }
    if (props.attributeLabelSearchInput != "") {
      searchByValue.push(props.attributeLabelSearchInput.trim());
      searchByVar.push(attributeLabelSearchFilter);
      pattern.push("like");

    }
    if (props.lastModifiedBySearchInput != "") {
      searchByValue.push(props.lastModifiedBySearchInput.trim());
      searchByVar.push(lastModifiedBySearchFilter);
      pattern.push("like");

    }
    
    
    if (picker && val == "lastModifiedOn") {
      props.setModifiedDateRef(picker);
      dateArr.push(moment(picker.startDate).format("MM-DD-YYYY"), moment(picker.endDate).format("MM-DD-YYYY"));
      searchByValue.push(dateArr.join("&"));
      searchByVar.push("lastModifiedOn");
      pattern.push("between");

    }else if ( !_.isEmpty(props.modifiedStartDate) && !_.isEmpty(props.modifiedEndDate)) {
      

      dateArr.push(moment(props.modifiedStartDate).format("MM-DD-YYYY"), moment(props.modifiedEndDate).format("MM-DD-YYYY"));
      searchByValue.push(dateArr.join("&"));
      searchByVar.push("lastModifiedOn");
      pattern.push("between");
    }

    if (picker && val == "lastRefreshedOn") {
     

      props.setRefreshedonDateRef(picker);
      refreshedDateArr.push(moment(picker.startDate).format("MM-DD-YYYY"), moment(picker.endDate).format("MM-DD-YYYY"));
      searchByValue.push(refreshedDateArr.join("&"));
      searchByVar.push("lastRefreshedOn");
      pattern.push("between");
    }else if ( !_.isEmpty(props.refreshedonStartDate) && !_.isEmpty(props.refreshedonEndDate)) {
      

      refreshedDateArr.push(moment(props.refreshedonStartDate).format("MM-DD-YYYY"), moment(props.refreshedonEndDate).format("MM-DD-YYYY"));
      searchByValue.push(refreshedDateArr.join("&"));
      searchByVar.push("lastRefreshedOn");
      pattern.push("between");
    }

   
    
    props.setClearAllFilterToggle(true);

    props.setsearchValue(searchByValue.join("|"));
    props.setsearchKey(searchByVar.join(","));
    props.setPatternList(pattern.join(","));
    props.showRightSideFun(false);

    
    dispatch(getTOAList(searchByVar.join(","),searchByValue.join("|"),props.dataType,props.status,props.page,props.size,pattern.join(",")));

  };

  const handleChange = (e, searchValue) => {
    switch (searchValue) {
      case "totLabel":
        props.setTableNameSearchInput(e.target.value);
        setTableNameSearchFilter(searchValue);
        break;
      case "attribute":
        props.setAttributeSearchInput(e.target.value);
        setAttributeSearchFilter(searchValue);
        break;
      case "attributeLabel":
        props.setAttributeLabelSearchInput(e.target.value);
        setAttributeLabelSearchFilter(searchValue);
        break;
      case "lastModifiedBy":
        props.setLastModifiedBySearchInput(e.target.value);
        setLastModifiedBySearchFilter(searchValue);
        break;
    }
  };
  const handleStatusChange = (value) =>  {
    let searchOnStatusArr = [];
    props.setSelectedStatusValue(value);
    (props.searchKey.length === 0) ?
      props.dataType.length == 0 ?
        value.length == 0 ?
          props.setClearAllFilterToggle(false) :
          props.setClearAllFilterToggle(true) :
        props.setClearAllFilterToggle(true) :
      props.setClearAllFilterToggle(true);

    props.showRightSideFun(false);
    value.map((checkBoxValue) => {
      return (
        searchOnStatusArr.push(checkBoxValue.value)
      );
    }
    );
    props.setMultiSelectParams(props.dataType,searchOnStatusArr);
  

    dispatch(getTOAList(props.searchKey,props.searchValue,props.dataType,searchOnStatusArr,props.page,props.size,props.patternList));
    
  }
  const handleDatatypeChange = (value) =>  {
    let searchOnDatatypeArr = [];
    props.setSelectedDatatypeValue(value);
    (props.searchKey.length === 0) ?
      props.dataType.length == 0 ?
        value.length == 0 ?
          props.setClearAllFilterToggle(false) :
          props.setClearAllFilterToggle(true) :
        props.setClearAllFilterToggle(true) :
      props.setClearAllFilterToggle(true);

    props.showRightSideFun(false);
    value.map((checkBoxValue) => {
      return (
        searchOnDatatypeArr.push(checkBoxValue.value)
      );
    }
    );

    props.setMultiSelectParams(searchOnDatatypeArr,props.status);
    dispatch(getTOAList(props.searchKey,props.searchValue,searchOnDatatypeArr,props.status,props.page,props.size,props.patternList));
    
  }

  const onPageSizeChange = (e) => {
    props.showRightSideFun(false);
    props.setLimit(e.target.value);
    dispatch(getTOAList(props.searchKey,props.searchValue,props.dataType,props.status,props.page,e.target.value,props.patternList));

  }

  const onPageChange = (e) => {
    props.showRightSideFun(false);
    props.setPage(e-1);
    dispatch(getTOAList(props.searchKey,props.searchValue,props.dataType,props.status,(e-1),props.size,props.patternList));

  }

  const onRowSelect = (e, row, index) => {
    props.toaListHandler(row);
    props.showRightSideFun(true);
    props.showActiveRow(index);

}
  const renderTable = (data) => {

    return data.map((data, index) => {
      return(
        <tr key={index} className={props.isRightSideVisible && index == props.rowIndex ? "myActive" : ""} onClick={(e) => onRowSelect(e, data, index)}>
        <td>{data.totLabel}</td>
        <td>{data.attribute}</td>
        <td>{data.attributeLabel}</td>
        <td>{data.dataType}</td>
        <td>{data.status == "active" ? "Active" : "Inactive"}</td>
        <td>{data.lastModifiedBy}</td>
        <td>{data.lastModifiedOn}</td>
        <td>{data.lastRefreshedOn}</td>
        {/* <td>{(rowDateHandler(data.CREATED_ON))}</td> */}
        </tr>
      )
  })
  }

  const handleCallback = (filter, start, end) => {
    let modifiedDateFilterValue = [];
    switch (filter) {
      case 'lastModifiedOn':
        modifiedDateFilterValue.push(start.format("YYYY-MM-DD HH:mm:ss"), end.format("YYYY-MM-DD HH:mm:ss"));
        props.setModifiedStartAndEndDate(start.format('MM/DD/YYYY HH:mm:ss'), end.format('MM/DD/YYYY HH:mm:ss'), modifiedDateFilterValue);
        break;
      case 'lastRefreshedOn':
        modifiedDateFilterValue.push(start.format("YYYY-MM-DD HH:mm:ss"), end.format("YYYY-MM-DD HH:mm:ss"));
        props.setRefreshedonStartAndEndDate(start.format('MM/DD/YYYY HH:mm:ss'), end.format('MM/DD/YYYY HH:mm:ss'), modifiedDateFilterValue);
        break;
    }
  }

 

  let toaDataList = toaList ? toaList.records : { data: [] };
  let meta = !_.isEmpty(toaList) ? toaList.metaInfo : {}; 
  let myModifiedStartDate;
  let myModifiedEndDate;
  if (result == "yyyy-MM-dd") {
    myModifiedStartDate = !_.isEmpty(props.modifiedStartDate) ? moment(props.modifiedStartDate).format("YYYY/MM/DD") : "";
    myModifiedEndDate = !_.isEmpty(props.modifiedEndDate) ? moment(props.modifiedEndDate).format("YYYY/MM/DD") : "";
  }
  else if (result == "dd-MM-yyyy") {
    myModifiedStartDate = !_.isEmpty(props.modifiedStartDate) ? moment(props.modifiedStartDate).format("DD/MM/YYYY") : "";
    myModifiedEndDate = !_.isEmpty(props.modifiedEndDate) ? moment(props.modifiedEndDate).format("DD/MM/YYYY") : "";
  }
  else {
    myModifiedStartDate = !_.isEmpty(props.modifiedStartDate) ? moment(props.modifiedStartDate).format("MM/DD/YYYY") : "";
    myModifiedEndDate = !_.isEmpty(props.modifiedEndDate) ? moment(props.modifiedEndDate).format("MM/DD/YYYY") : "";
  }
  let myModifiedNewDate = myModifiedStartDate == "" && myModifiedEndDate == "" ? "" : myModifiedStartDate + "-" + myModifiedEndDate;

  let myRefreshedOnStartDate;
  let myRefreshedOnEndDate;
  if (result == "yyyy-MM-dd") {
    myRefreshedOnStartDate = !_.isEmpty(props.refreshedonStartDate) ? moment(props.refreshedonStartDate).format("YYYY/MM/DD") : "";
    myRefreshedOnEndDate = !_.isEmpty(props.refreshedonEndDate) ? moment(props.refreshedonEndDate).format("YYYY/MM/DD") : "";
  }
  else if (result == "dd-MM-yyyy") {
    myRefreshedOnStartDate = !_.isEmpty(props.refreshedonStartDate) ? moment(props.refreshedonStartDate).format("DD/MM/YYYY") : "";
    myRefreshedOnEndDate = !_.isEmpty(props.refreshedonEndDate) ? moment(props.refreshedonEndDate).format("DD/MM/YYYY") : "";
  }
  else {
    myRefreshedOnStartDate = !_.isEmpty(props.refreshedonStartDate) ? moment(props.refreshedonStartDate).format("MM/DD/YYYY") : "";
    myRefreshedOnEndDate = !_.isEmpty(props.refreshedonEndDate) ? moment(props.refreshedonEndDate).format("MM/DD/YYYY") : "";
  }
  let myRefreshedOnNewDate = myRefreshedOnStartDate == "" && myRefreshedOnEndDate == "" ? "" : myRefreshedOnStartDate + "-" + myRefreshedOnEndDate;
  const handleKeyPress = (event) => { };

  let caseResponsiveState = (props.isStatusOpen || props.isDataTypeOpen) && (toaDataList == undefined || toaDataList.length === 0 || toaDataList.length <=6 || commonLoader)
  let caseResponsive = caseResponsiveState ? "overFlowClp" : "table-responsive";

console.log("PROPS>PAGE",props.page)

    return(
        <>
            <div aria-label="Table" role="contentinfo" className={caseResponsiveState ? "respondv responDvMinH" : "respondv"}>
              <div className={"tableRgtBor " + caseResponsive}>
                    <Table striped bordered condensed hover className="tableView nowrapWhright" style={{ marginTop: "0px" }}>
                     <thead>
                            <tr>
                            <th>
                                    <div className="sortParArr">{tr["Table Name"]}</div>
                                    <div className="colSearDv ">
                                        <Form.Control
                                            className="colSearInp"
                                            type="text"
                                            value={props.tableNameSearchInput != "" ? props.tableNameSearchInput : ""}
                                            placeholder={tr["Search here"]}
                                            onKeyDown={(e) => {
                                                if (e.code == "Enter") {
                                                    if (
                                                        props.tableNameSearchInput != "" &&
                                                        props.tableNameSearchInput.trim().length > 3
                                                    )
                                                        handleSearch();
                                                }
                                            }}
                                            onChange={(event) => {
                                                handleChange(event, "totLabel");
                                            }}
                                            onClick={() => props.multiSelectOpenFun("dropdownClose")}
                                        />
                                        {props.tableNameSearchInput.trim().length > 3 ?
                                            <a title="search" className="faicn" href={void (0)} onClick={(event) => handleSearch()}>
                                                <IoSearch />
                                            </a> : ""}
                                    </div>
                                </th>
                                <th>
                                    <div className="sortParArr">{tr["Attribute"]}</div>
                                    <div className="colSearDv ">
                                        <Form.Control
                                            className="colSearInp"
                                            type="text"
                                            value={props.attributeSearchInput != "" ? props.attributeSearchInput : ""}
                                            placeholder={tr["Search here"]}
                                            onKeyDown={(e) => {
                                                if (e.code == "Enter") {
                                                    if (
                                                        props.attributeSearchInput != "" &&
                                                        props.attributeSearchInput.trim().length > 3
                                                    )
                                                        handleSearch();
                                                }
                                            }}
                                            onChange={(event) => {
                                                handleChange(event, "attribute");
                                            }}
                                            onClick={() => props.multiSelectOpenFun("dropdownClose")}
                                        />
                                        {props.attributeSearchInput.trim().length > 3 ?
                                            <a title="search" className="faicn" href={void (0)} onClick={(event) => handleSearch()}>
                                                <IoSearch />
                                            </a> : ""}
                                    </div>
                                </th>
                                <th>
                                    <div className="sortParArr">{tr["Attribute Label"]}</div>
                                    <div className="colSearDv ">
                                        <Form.Control
                                            className="colSearInp"
                                            type="text"
                                            value={props.attributeLabelSearchInput != "" ? props.attributeLabelSearchInput : ""}
                                            placeholder={tr["Search here"]}
                                            onKeyDown={(e) => {
                                                if (e.code == "Enter") {
                                                    if (
                                                        props.attributeLabelSearchInput != "" &&
                                                        props.attributeLabelSearchInput.trim().length > 3
                                                    )
                                                        handleSearch();
                                                }
                                            }}
                                            onChange={(event) => {
                                                handleChange(event, "attributeLabel");
                                            }}
                                            onClick={() => props.multiSelectOpenFun("dropdownClose")}
                                        />
                                        {props.attributeLabelSearchInput.trim().length > 3 ?
                                            <a title="search" className="faicn" href={void (0)} onClick={(event) => handleSearch()}>
                                                <IoSearch />
                                            </a> : ""}
                                    </div>
                                </th>
                                <th>
                                    <div className="sortParArr">{tr["Data Type"]}</div>
                                    <div
                                        id="datatypeDiv"
                                        className="colSearDv"
                                        ref={multiSelectDataTypeDropdownRef}
                                        onClick={() => props.multiSelectOpenFun("dataType")}
                                    >
                                        <ReactMultiSelectCheckboxes
                                            placeholderButtonLabel={tr["Select"]}
                                            rightAligned={true}
                                            options={datatypeOptions}
                                            onChange={handleDatatypeChange}
                                            value={props.selectedDatatypeValue}
                                        />
                                    </div>
                                </th>
                                <th>
                                    <div className="sortParArr">{tr["Status"]}</div>
                                    <div
                                        id="statusDiv"
                                        className="colSearDv"
                                        ref={multiSelectStatusDropdownRef}
                                        onClick={() => props.multiSelectOpenFun("status")}
                                    >
                                        <ReactMultiSelectCheckboxes
                                            placeholderButtonLabel={tr["Select"]}
                                            rightAligned={true}
                                            options={statusOptions}
                                            onChange={handleStatusChange}
                                            value={props.selectedStatusValue}
                                        />
                                    </div>
                                </th>
                                <th>
                                    <div className="sortParArr">{tr["Last Modified By"]}</div>
                                    <div className="colSearDv ">
                                        <Form.Control
                                            className="colSearInp"
                                            type="text"
                                            value={props.lastModifiedBySearchInput != "" ? props.lastModifiedBySearchInput : ""}
                                            placeholder={tr["Search here"]}
                                            onKeyDown={(e) => {
                                                if (e.code == "Enter") {
                                                    if (
                                                        props.lastModifiedBySearchInput != "" &&
                                                        props.lastModifiedBySearchInput.trim().length > 3
                                                    )
                                                        handleSearch();
                                                }
                                            }}
                                            onChange={(event) => {
                                                handleChange(event, "lastModifiedBy");
                                            }}
                                            onClick={() => props.multiSelectOpenFun("dropdownClose")}
                                        />
                                        {props.lastModifiedBySearchInput.trim().length > 3 ?
                                            <a title="search" className="faicn" href={void (0)} onClick={(event) => handleSearch()}>
                                                <IoSearch />
                                            </a> : ""}
                                    </div>
                                </th>
                                <th>
                                    <div className="sortParArr">{tr["Last Modified On"]}</div>
                                      <div className="colSearDv" >
                                        <DateRangePicker
                                          initialSettings={{
                                            autoUpdateInput: false,
                                            maxDate: moment(),
                                            autoApply: true,
                                            parentEl:"#skipdv"
                                          }}
                                          onApply={(e, picker) => handleSearch(e, picker,"lastModifiedOn")}
                                          onCallback={(start, end) =>
                                            handleCallback("lastModifiedOn", start, end)
                                          }
                                        >
                                          <input
                                            type="text"
                                            value={myModifiedNewDate}
                                            placeholder={tr["Search here"]}
                                            readOnly
                                            className="form-control col-4"
                                            onPaste={handleKeyPress}
                                            onKeyDown={handleKeyPress}
                                          />
                                        </DateRangePicker>
                                      </div>
                                </th>
                                <th>
                                    <div className="sortParArr">{tr["Refreshed On"]}</div>
                                    <div className="colSearDv" >
                                        <DateRangePicker
                                          initialSettings={{
                                            autoUpdateInput: false,
                                            maxDate: moment(),
                                            autoApply: true,
                                            parentEl:"#skipdv"
                                          }}
                                          onApply={(e, picker) => handleSearch(e, picker,"lastRefreshedOn")}
                                          onCallback={(start, end) =>
                                            handleCallback("lastRefreshedOn", start, end)
                                          }
                                        >
                                          <input
                                            type="text"
                                            value={myRefreshedOnNewDate}
                                            placeholder={tr["Search here"]}
                                            readOnly
                                            className="form-control col-4"
                                            onPaste={handleKeyPress}
                                            onKeyDown={handleKeyPress}
                                          />
                                        </DateRangePicker>
                                      </div>
                                </th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {toaDataList == undefined || commonLoader ?
                                <tr>
                                    <td colSpan="9" style={{ "textAlign": "center" }}><ListLoader /></td>
                                </tr> :
                                toaDataList.length === 0 ?
                                    <tr>
                                        <td colSpan="9" style={{ "textAlign": "center" }}>{tr['There is no matching data available']}</td>
                                    </tr> :
                                    renderTable(toaDataList)
                            }
                        </tbody>
                    </Table>
                </div>
                <div className="nBotPagina">
                <div className="nShow">
                    <div className="margin-r-10">{tr['Show']}:</div>
                    <Form.Select value={props.size} onChange={onPageSizeChange}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    </Form.Select>
                </div>

          <nav aria-label='Pagination' className='display-inline-block'>
            <Pagination
              prevPageText={tr['Prev']}
              nextPageText={tr['Next']}
              firstPageText={<i className='glyphicon glyphicon-menu-left' />}
              lastPageText={<i className='glyphicon glyphicon-menu-right' />}
              activePage={meta ? (meta.currentPage + 1)  : 1}
              itemsCountPerPage={props.size}
              // totalItemsCount={meta ? meta.recordCount ? meta.recordCount : 1 : 1}
              totalItemsCount={meta ? meta.totalPageCount ? meta.totalPageCount : 1 : 1}
              pageRangeDisplayed={`${windowSize < 576 ? 3 : (props.panelResize <= 42 ? 2 : 5)}`}
              onChange={(e) => onPageChange(e)}
            /></nav>
        </div>
            </div>
        </>
    )
}
export default List;