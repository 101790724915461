
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState} from "react";
import { Row, Col, Button } from 'react-bootstrap';
import {useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { deleteCostCenterList, updateCostCenterAction } from "../../../../../actions/foundation/costCenterAction";
import { reduxForm } from "redux-form";
import Swal from "sweetalert2";

import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {HiOutlineTrash} from "react-icons/hi";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';
import { getCookiesValuesByPositions } from "../../../../common/helper";

const HeaderSec = (props) => {
    const navigate = useNavigate();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const dispatch = useDispatch();
    const context = useContext(MyContext);
    const closeButton = useRef(null);
    const saveObjRef = useRef(null);
    const [show, setShow] = useState(false);
    const { value1: user_roleId } = getCookiesValuesByPositions([33]);

    useEffect(() => {
        if(user_roleId && user_roleId !== ''){
        let user_roleIdArr = user_roleId.split(","); 
            if(user_roleIdArr.includes('1')){
            setShow(true);
            } else if(user_roleIdArr?.includes('72') && !user_roleIdArr?.includes('1')){
              setShow(false);
            }
        }
        }, [user_roleId]);

    useEffect(() => {
      context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
      context.newTabPrevent();
    }, []);

    const submit = async (values) => {
        console.log("EditHeaderCC",values, props.id)       
        let isValid = true;   
        if ( !values.costcenter_name || values.costcenter_name == ''|| values.costcenter_name.trim() == '') {
            props.setNameErr("error");
            isValid = false;
          } else {
            props.setNameErr("");
            // isValid = true;
        }
        if (!values.ccStatus || values.ccStatus == '') {
            props.setStatusErr("error");
            isValid = false;
          } else {
            props.setStatusErr("");
            // isValid = true;
        }
    
        if (isValid == true) {
        let postjson;
            postjson = {
                    id:props.id,
                    costcenter_name: values.costcenter_name,
                    status: values.ccStatus,
                    company_id: values.company_id,
                    company_name: values.company_name,
                    pagesize: 0,
                    filterby: null,
                    filterbytext: null,
                    eventNameDefault: null,
                    businessFunctionId: 0,
                    costCenterToEdit: values.costCenterToEdit
        };
        dispatch(updateCostCenterAction(postjson)); 
        }
    };

    const onDeleteClick = () => {
        const id = window.location.href.split("/").pop();
        Swal.fire({
          title: tr["Are you sure you want to delete?"],
          width: 550,
          padding: '15px',
          showDenyButton: true,
          confirmButtonText: tr['Yes'],
          denyButtonText: tr['No'],
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(deleteCostCenterList(id))
          } else if (result.isDenied) {
            
          }
        })
      };
    let { handleSubmit, submitting } = props;
    return (
        <>
            <Row className="margin-b-15">
                <Col sm={8} xs={7}>
                    <h1 bsClass="" className="sPageHeading1">{tr["Cost Center"]}</h1>
                </Col>
                <Col sm={4} xs={5}>
                    <div className="paHedFilter">
                        {show && <Button role="button" bsPrefix=' '  className="myBt rclose" title={tr["Delete"]} onClick={onDeleteClick} >
                          <HiOutlineTrash/>
                        </Button>}
                        <Link
                            bsPrefix=' '
                            id="hdID"
                            to="javascript:void(0)"
                            className="myBt plus ctrlKeyPrevent"
                            disabled={props.loader}
                            onClick={handleSubmit(submit)} 
                            title={tr['Save']}
                            ref={saveObjRef} >
                            <span>{props.loader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/> }</span>
                        </Link>
                        <Link
                            bsPrefix=' '
                            to="/costCenter"
                            className="myBt cancel fillBtn ctrlKeyPrevent"
                            title={tr['Cancel']}
                            ref={closeButton} >
                            <IoClose/>
                        </Link>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default reduxForm({
    form: "EditCostCenter",
  })(HeaderSec);