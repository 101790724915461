
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  ButtonToolbar
} from "react-bootstrap";
import { Field, change, reduxForm, reset } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { _inputField, _passwordField } from "../../../common/formFields";
import axios from "axios";
import { IoClose } from "react-icons/io5";
import { PiPlusSquareBold } from "react-icons/pi";

const required = (value) => {
  if (!value || (value && value.trim().length === 0)) {
    return "required";
  }
};
const passwordValidate = (value) => {
  if (!value || (value && value.trim().length === 0) || (value && value.trim().length < 4)) {
  return "Please Enter minimum 4 characters";
}
};

const CredentialForm = (props) => {
  const [showForm, setShowForm] = useState('list');
  const [credentialData, setCredentialData] = useState([]);
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const [tenatGmailPasswordFieldType, setTenatGmailPasswordFieldType] = useState('password');
  const [secretKeyGmail, setSecretKeyGmail] = useState('password');
  const [secretKeyOutlook, setSecretKeyOutlook] = useState('password');
  const [objIdOutlook, setObjIdOutlook] = useState('password');
  const [tenantIdOutlook, setTenantIdOutlook] = useState('password');
  const [tenantIdDataOutlook, setTenantIdDataOutlook] = useState('password');
  const [objectIdDataOutlook, setObjectIdDataOutlook] = useState('password');
  const [secretKeyDataOutlook, setSecretKeyDataOutlook] = useState('password');
  const [tenantIdDataGmail, setTenantIdDataGmail] = useState('password');
  const [secretKeyDataGmail, setSecretKeyDataGmail] = useState('password');

  const tr = useSelector((state) => state.spcmReducer.tr);
  const dispatch = useDispatch();
  useEffect(() => {
    axios.get("/mailBoxConfig/getMailBoxCredentialData/" + props.id)
          .then((resp) => {
            setCredentialData(resp.data.Data);
          })
  },[])

  const saveCredential = (values) => {
    if (showForm === 'create') {
      const payload = {
        mailbox_uuid: props.id,
        cronjob_time: "No Time",
        created_by: 1244,
        status: 1,
        credentials_json: values,
      };
      axios
        .post("/mailBoxConfig/saveMailBoxCredentialData", payload)
        .then((response) => {
          console.log(response.data);
          axios.get("/mailBoxConfig/getMailBoxCredentialData/" + props.id)
            .then((resp) => {
              setCredentialData(resp.data.Data);
              setShowForm('list');
            })
        })
    } else if (showForm === 'edit') {
      const payload = {
        cronjob_time: "No Time",
        updated_by: '1244',
        status: 1,
        credentials: values,
      };
      axios
        .patch("/mailBoxConfig/editMailBoxCredentialData/" + credentialData[0].CREDENTIAL_ID, payload)
        .then((response) => {
          console.log(response.data);
          axios.get("/mailBoxConfig/getMailBoxCredentialData/" + props.id)
            .then((resp) => {
              setCredentialData(resp.data.Data);
              setShowForm('list');
            })
        })
    }
  };
  const setEditCredentialDetails = () => {
    setShowForm('edit');
    dispatch(change('credentialForm', 'APPLICATION_ID', credentialData[0].CREDENTIALS.APPLICATION_ID));
    dispatch(change('credentialForm', 'DISPLAY_NAME', credentialData[0].CREDENTIALS.DISPLAY_NAME));
    dispatch(change('credentialForm', 'OBJECT_ID', credentialData[0].CREDENTIALS.OBJECT_ID));
    dispatch(change('credentialForm', 'SECRET_KEY', credentialData[0].CREDENTIALS.SECRET_KEY));
    dispatch(change('credentialForm', 'TENANT_ID', credentialData[0].CREDENTIALS.TENANT_ID));
    dispatch(change('credentialForm', 'SX_INBOUND', credentialData[0].CREDENTIALS.SX_INBOUND));
  }
  const renderCredentialData = (credentialDataValues) => {
    if (props.mailBoxType == 'Gmail') {
      if (credentialDataValues.length === 0) {
        return (<tr>
          <td colSpan={2}>There is no data available</td>
        </tr>)
      } else {
        return credentialDataValues.map((item, index) =>
        (
          <>
            <tr>
              <td>{tr["SX Inbound"]}</td>
              <td>{item.CREDENTIALS.SX_INBOUND}</td>
            </tr>
            <tr>
              <td>{tr["Application Id"]}</td>
              <td>{item.CREDENTIALS.APPLICATION_ID}</td>
            </tr>
            <tr>
              <td>{tr["Tenant Id"]}</td>
              <td className="position-re">
              <div className="padding-r-30">{tenantIdDataGmail === 'password' ? "*****" : item.CREDENTIALS.TENANT_ID}</div>
              <a className="eyeicn" role='button'
                title={tenantIdDataGmail === 'password' ? "Show Password" : "Hide Password"}
                href='#'
                onClick={() => { setTenantIdDataGmail(tenantIdDataGmail === 'password' ? 'text' : 'password') }} >
                <i className={tenantIdDataGmail === 'password' ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true">
                </i>
              </a>
              </td>
            </tr>
            <tr>
              <td> {tr["Secret Key"]}</td>
              <td className="position-re">
              <div className="padding-r-30">{secretKeyDataGmail === 'password' ? "*****" :item.CREDENTIALS.SECRET_KEY}</div>
              <a className="eyeicn" role='button'
                title={secretKeyDataGmail === 'password' ? "Show Password" : "Hide Password"}
                href='#'
                onClick={() => { setSecretKeyDataGmail(secretKeyDataGmail === 'password' ? 'text' : 'password') }} >
                <i className={secretKeyDataGmail === 'password' ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true">
                </i>
              </a></td>
            </tr>
          </>
        )
      
        );
      }
    } 
    if (props.mailBoxType == 'Outlook') {
      if (credentialDataValues.length === 0) {
        return (<tr>
          <td colSpan={2}>There is no data available</td>
        </tr>)
      } else {

        return credentialDataValues.map((item, index) =>
        (
          <>
            <tr>
              <td>{tr["Display Name"]}</td>
              <td>{item.CREDENTIALS.DISPLAY_NAME}</td>
            </tr>
            <tr>
              <td>{tr["Application Id"]}</td>
              <td>{item.CREDENTIALS.APPLICATION_ID}</td>
            </tr>
            <tr>
              <td>{tr["Tenant Id"]}</td>
              <td className="position-re">
                <div className="padding-r-30">{tenantIdDataOutlook === 'password' ? "*****" : item.CREDENTIALS.TENANT_ID }</div>
              <a className="eyeicn" role='button'
                title={tenantIdDataOutlook === 'password' ? "Show Password" : "Hide Password"}
                href='#'
                onClick={() => { setTenantIdDataOutlook(tenantIdDataOutlook === 'password' ? 'text' : 'password') }} >
                <i className={tenantIdDataOutlook === 'password' ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true">
                </i>
              </a></td>
            </tr>
            <tr>
              <td>{tr["Object Id"]}</td>
              <td className="position-re">
              <div className="padding-r-30">{objectIdDataOutlook === 'password' ? "*****" :  item.CREDENTIALS.OBJECT_ID}</div>
              <a className="eyeicn" role='button'
                title={objectIdDataOutlook === 'password' ? "Show Password" : "Hide Password"}
                href='#'
                onClick={() => { setObjectIdDataOutlook(objectIdDataOutlook === 'password' ? 'text' : 'password') }} >
                <i className={objectIdDataOutlook === 'password' ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true">
                </i>
              </a></td>
            </tr>
            <tr>
              <td>{tr["Secret Key"]}</td>
              <td className="position-re">
              <div className="padding-r-30">{secretKeyDataOutlook === 'password' ? "*****" :item.CREDENTIALS.SECRET_KEY}</div>
                <a className="eyeicn" role='button'
                title={secretKeyDataOutlook === 'password' ? "Show Password" : "Hide Password"}
                href='#'
                onClick={() => { setSecretKeyDataOutlook(secretKeyDataOutlook === 'password' ? 'text' : 'password') }} >
                <i className={secretKeyDataOutlook === 'password' ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true">
                </i>
              </a></td>
            </tr>
          </>
        )
        );
      }
    }
  }

  let { handleSubmit } = props;
  const renderCredentialDataTable = (credData) => {
    return(
     <Table
          responsive
          striped
          bordered
          hover
          className="tableWithOutPointer"
        >
          <thead>
            <tr>
              <th width="40%">{tr["Key"]}</th>
              <th width="40%">{tr["Value"]}</th>
            </tr>
          </thead>
          <tbody>
            { renderCredentialData(credData)}
          </tbody>
      </Table> 
    )
  }
  return (
    <>
      <Row>
        <Col xs={12}>
        <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
          <ul>
            <li>
             { credentialData.length == 0 ?
             
                <Button                 
                  title={tr["Add Credentials"]}
                  bsPrefix=" "
                  className="rPlusIcn"
                  size="xs"
                  variant="primary"
                  bsClass=""
                  onClick={() => {
                    setShowForm('create');
                  }}
                  disabled={props.mailBoxType === ""}
                >
                  <PiPlusSquareBold/>
                </Button>
                : <Button
                  title={tr["Edit"]}
                  bsPrefix=" "
                  className="rPlusIcn"
                  size="xs"
                  variant="primary"
                  bsClass=""
                  onClick={() => { setEditCredentialDetails()}}>
                <i className="fa fa-pencil-square-o cursorPoint"></i> 
                </Button>
              }
            </li>
            <li className="d-md-none">
              <button
                  type="button"
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                props.rightEditPanel(false);
                }}
                >
                <IoClose/>
                </button>
              </li>
            </ul>
          </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {tr["Add Credentials"]}
            </div>
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
           { showForm == 'list' ?
           renderCredentialDataTable(credentialData): null
           }
        <div className="rBoxTabs">
          {props.mailBoxType === "Outlook" && showForm !== 'list' ? (
            <>
              <Table>
                <thead>
                  <tr>
                    <th width="43%">{tr["Key"]}</th>
                    <th width="43%">{tr["Value"]}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="rStar"></span>
                      {tr["Display Name"]}
                    </td>
                    <td>
                      <Field
                        component={_inputField}
                        name={"DISPLAY_NAME"}
                        className="form-control"
                        validate={[required]}
                        maxLength={128}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="rStar"></span> 
                      {tr["Application Id"]}
                    </td>
                    <td>
                      <Field
                        component={_inputField}
                        name={"APPLICATION_ID"}
                        className="form-control"
                        validate={[required]}
                        maxLength={128}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="rStar"></span>
                      {tr["Tenant Id"]}
                    </td>
                    <td>
                    <div className="position-re"> { tenantIdOutlook === 'password' ? <Field name="TENANT_ID" className="form-control padding-r-25" component={_passwordField} validate={passwordValidate} maxLength={128}>
                  </Field> :
                  <Field name="TENANT_ID" className="form-control padding-r-25" component={_inputField} validate={passwordValidate} maxLength={128}>
                        </Field>}
                        <a className="eyeicn" role='button' title={tenantIdOutlook === 'password' ? "Show Password" : "Hide Password"} href='#' onClick={() => { setTenantIdOutlook(tenantIdOutlook === 'password' ? 'text' : 'password') }} ><i className={tenantIdOutlook === 'password' ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true"></i></a>
                        </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="rStar"></span>
                      {tr["Object Id"]}
                    </td>
                    <td>
                    <div className="position-re"> { objIdOutlook === 'password' ? <Field name="OBJECT_ID" className="form-control padding-r-25" component={_passwordField} validate={passwordValidate} maxLength={128}>
                  </Field> :
                  <Field name="OBJECT_ID" className="form-control padding-r-25" component={_inputField} validate={passwordValidate} maxLength={128}>
                        </Field>}
                        <a className="eyeicn" role='button' title={objIdOutlook === 'password' ? "Show Password" : "Hide Password"} href='#' onClick={() => { setObjIdOutlook(objIdOutlook === 'password' ? 'text' : 'password') }} ><i className={objIdOutlook === 'password' ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true"></i></a>
                        </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="rStar"></span>
                      {tr["Secret Key"]}
                    </td>
                    <td>
                    <div className="position-re">{ secretKeyOutlook === 'password' ? <Field name="SECRET_KEY" className="form-control padding-r-25" component={_passwordField} validate={passwordValidate} maxLength={128} minLength={6}>
                  </Field> :
                  <Field name="SECRET_KEY" className="form-control padding-r-25" component={_inputField} validate={passwordValidate} minLength={6} maxLength={128}>
                        </Field>}
                        <a className="eyeicn" role='button' title={secretKeyOutlook === 'password' ? "Show Password" : "Hide Password"} href='#' onClick={() => { setSecretKeyOutlook(secretKeyOutlook === 'password' ? 'text' : 'password') }} ><i className={secretKeyOutlook === 'password' ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true"></i></a>
                        </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <ButtonToolbar>
              <Button
                className="rgSidrkBtn"
                variant="primary"
                onClick={handleSubmit(saveCredential)}
              >
                {tr["Save"]}
              </Button>
                <Button
                  className="rgSiLigBtn"
                  variant="primary"
                  onClick={() => { setShowForm('list'); dispatch(reset('credentialForm'));}}>
                {tr["Cancel"]}
              </Button>
              </ButtonToolbar>
            </>
          ) : props.mailBoxType === "Gmail" && showForm !== 'list' ? (
            <>
              <Table>
                <thead>
                  <tr>
                    <th width="43%">{tr["Key"]}</th>
                    <th width="43%">{tr["Value"]}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="rStar"></span>
                      {tr["SX Inbound"]}
                    </td>
                    <td>
                      <Field
                        component={_inputField}
                        name={"SX_INBOUND"}
                        className="form-control"
                        validate={[required]}
                        maxLength={128}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="rStar"></span>
                      {tr["Application Id"]}
                    </td>
                    <td>
                      <Field
                        component={_inputField}
                        name={"APPLICATION_ID"}
                        className="form-control"
                        validate={[required]}
                        maxLength={128}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="rStar"></span>
                        {tr["Tenant Id"]}
                    </td>
                    <td>
                    <div className="position-re">{ tenatGmailPasswordFieldType === 'password' ? <Field name="TENANT_ID" className="form-control padding-r-25" component={_passwordField} validate={passwordValidate} maxLength={128}>
                  </Field> :
                  <Field name="TENANT_ID" className="form-control padding-r-25" component={_inputField} validate={passwordValidate} maxLength={128}>
                          </Field>}
                          <a className="eyeicn" role='button' title={tenatGmailPasswordFieldType === 'password' ? "Show Password" : "Hide Password"} href='#' onClick={() => {setTenatGmailPasswordFieldType(tenatGmailPasswordFieldType === 'password' ? 'text' : 'password')}}><i className={tenatGmailPasswordFieldType === 'password' ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true"></i></a></div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="rStar"></span>
                        {tr["Secret Key"]}
                        
                    </td>
                      <td>
                      <div className="position-re"> 
                    { secretKeyGmail === 'password' ? <Field name="SECRET_KEY" className="form-control padding-r-25" component={_passwordField} validate={passwordValidate} maxLength={128} >
                  </Field> :
                  <Field name="SECRET_KEY" className="form-control padding-r-25" component={_inputField} validate={passwordValidate} maxLength={128}>
                          </Field>}
                          <a className="eyeicn" role='button' title={secretKeyGmail === 'password' ? "Show Password" : "Hide Password"} href='#' onClick={() => { setSecretKeyGmail(secretKeyGmail === 'password' ? 'text' : 'password') }} ><i className={secretKeyGmail === 'password' ? "fa fa-eye" : "fa fa-eye-slash"} aria-hidden="true"></i></a>
                          </div>
                    </td>
                  </tr>
                </tbody>
                </Table>
                <ButtonToolbar>
              <Button
                className="rgSidrkBtn"
                variant="primary"
                onClick={handleSubmit(saveCredential)}
              >
                {tr["Save"]}
              </Button>
                  <Button className="rgSiLigBtn" variant="primary"
                    onClick={() => { setShowForm('list');dispatch(reset('credentialForm'))}}
                  >
                {tr["Cancel"]}
                  </Button>
                  </ButtonToolbar>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: "credentialForm",
  enableReinitialize: false,
})(CredentialForm);
