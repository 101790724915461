
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
import { ApolloClient, ApolloLink, gql, InMemoryCache, HttpLink, from } from '@apollo/client';
import { sanitizeElements } from "../../../common/helper";
export const GQLARRAY = ['gqlQuery','gqlMutation'];

export function validateRestRequest(request) {
    const headerValues = request.headers ? convertToObject(request.headers) : null;
    let url = addParamsToUrl(request.url, request.queries);
    url = request.module==='other'
        ? url
        : request.host + url;   
    if (headerValues && headerValues['x-user-payload']) {
        delete headerValues['x-user-payload'];
    }
    let data = {
        url: url,
        method: request.verb,
        headers: headerValues,
        body: request.body.type === 'raw' ? request.body.value : null,
        connectorId: request.connectorId
    };
    let connectorURL = '/connectorApis';
    if(!data?.connectorId){
        connectorURL = '/connectorApisInternal';
    }
    let headers = null;
    switch (request.body.type) {
        case 'form-data':
            connectorURL = '/connectorApis/multipart';
            data = new FormData();
            data.append('connector_url', url);
            data.append('connector_method', request.verb);
            data.append('connector_headers', JSON.stringify(headerValues));
            let bodyKeys = []
            if (request.body.value) {
                for (let item of request.body.value) {
                    if (item.type === 'file') {
                        data.append('files', item.value);
                    }
                    else
                        data.append(item.key, item.value);
                    bodyKeys.push({ type: item.type, key: item.key });
                }
            }
            data.append('bodyKeys', JSON.stringify(bodyKeys));
            headers = { "Content-Type": `multipart/form-data; boundary=${data._boundary}` };
            break;
        case 'raw':
            data.body = request.body.value;
            break;
    }
    return axios({
        url: connectorURL,
        method: "POST",
        data: data,
        headers: headers
    })
}


export function validateGraphQlRequest(request) {
  const headerValues = request.headers ? convertToObject(request.headers) : null;
  let url = addParamsToUrl(request.url, request.queries);
  url = request.module==='other'
      ? url
      : request.host + url;   
  if (headerValues && headerValues['x-user-payload']) {
      delete headerValues['x-user-payload'];
  }
  let data = {
      url: url,
      method: request.verb,
      headers: headerValues,
      body: {value:request.body.type === 'raw' ? request.body.value : null, variables:request.body.variables},
      connectorId: request.connectorId
  };
  let connectorURL = '/graphQlApi';
  if(!request?.connectorId){
        connectorURL = '/graphQlApiInternal';
  }
  let headers = null;
  return axios({
      url: connectorURL,
      method: "POST",
      data: data,
      headers: headers
  })
}

const convertToObject = (arrayValue) => {
    let obj = {};
    if(Array.isArray(arrayValue)) {
        arrayValue.forEach(item => {
            obj[item.key] = item.value
        });
        return obj
    }
}

const addParamsToUrl = (url, params) => {
    if (params && params.length) {
        let queryParamter = null;
        params.forEach(param => {
            if(queryParamter && queryParamter.includes(`${param.key}=`)) {
                let endIndex = queryParamter.indexOf('&', queryParamter.indexOf(`${param.key}=`));
                let value = queryParamter.substring(queryParamter.indexOf(`${param.key}=`) + param.key.length + 1, endIndex);
                queryParamter = queryParamter.replace(`${param.key}=${value}`, `${param.key}=${param.value}`);
            } else {
                let obj = param.key + '=' + param.value;
                queryParamter = queryParamter ? queryParamter + '&' + obj : obj;
            }
        })
        if (queryParamter) {
            let index = url.indexOf('?');
            if (index !== -1) {
                url = url.substring(0, index);
            }
            return url + '?' + queryParamter;
        }
    }
    return url;
}

export const buildQueryParams = (queryParams) => {
    if(queryParams && Object.keys(queryParams).length > 0) {
        let query = [];
        for (let [key, value] of Object.entries(queryParams)) {
            query.push({ key, value });
        }
        return query;
    }
}

export function getInitialValues(isDataQuery, hosts, connector = null) {
    let initialValues = {
        authentication: {
            type: 'none',
            value: null
        },
        body: {
            type: "none",
            subtype: "text",
            value: ""
        },
        headers: [],
        queries: [],
        title: "",
        host: "",
        url: "",
        verb: "get",
    };
    if (connector) {
        initialValues = {
            authentication: connector.authentication,
            headers: JSON.parse(connector.headers),
            verb: connector.verb,
            url: connector.url,
            queries: JSON.parse(connector.query_string),
            body: JSON.parse(connector.body),
        }
        let host = '';
        if(connector.module !== 'other'){
            host = `{{${connector.module}}}`;
        }
        initialValues.host = host;
        initialValues.module = connector.module;
        if (isDataQuery) {
            initialValues.headers = initialValues.headers ? addSampleValue(initialValues.headers) : []
            initialValues.queries = initialValues.queries ? addSampleValue(initialValues.queries) : []
            if (connector.body.type === 'form-data')
                initialValues.body.value = initialValues.body.value ? addSampleValue(initialValues.body.value) : []
            else initialValues.body.sample = initialValues.body.value;
        }
        else {
            initialValues.title = connector.title;
        }
    }
    else {
        if (isDataQuery) {
            initialValues.type = "";
            initialValues.connector_id = "";
            initialValues.transform_response = "";
        }
        else {
            initialValues.module = "other";
        }
    }
    return initialValues;
}

const addSampleValue = (arrayValues) => {
    return arrayValues.map(item => {
        item.sample = item.value;
        return item;
    })
}

export function transformFunction(body, data) {
    try {
        const transform = new Function('data', `return ${body}`);
        return transform(data);
    } catch (error) {
        console.log('error --- ', error);
    }
}


export const getVariables = (value) => {
  if (typeof value === "string") {
    let dependent = value
      .split("{{")
      .map((elem) => elem.substring(0, elem.indexOf("}}")));
    dependent = dependent.slice(1, dependent.length);
    return dependent;
  }
  return null;
};

export const updateDataQueryValues = (dqParam, dependent, formValues) => {
    if (Array.isArray(dqParam)) {
        return dqParam.map((item) => {
            item.value = replaceVariable(item.value, dependent, formValues, item?.type);
            return item;
        })
    }
    else {
        return replaceVariable(dqParam, dependent, formValues);
    }
}

const replaceVariable = (param, dependent, formValues, type = false) => {
  let value = param;
  dependent.forEach((dependent) => {
    if (type == "file") {
      return (value = formValues[dependent]);
    }
    value = value.replace(
      "{{" + dependent + "}}",
      formValues.hasOwnProperty(dependent) && formValues[dependent] !== "null"
        ? formValues[dependent]
        : ""
    );
  });
  return value;
};