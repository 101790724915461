/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Field } from "formik";
import Cookies from "universal-cookie";

import CmdbDropdownPlusTypeahead from "../common/CmdbDropdownPlusTypeahead.js";
import { loadCICompany } from "../../../actions/cmdb/lovAction";
import {
  loadClassByCompanyId,
  resetCiClass,
} from "../../../actions/ham/hamAction";

import {
  InputTextField,
  SelectField,
  StandardTextArea,
} from "../../common/forms/FormFields";
import { GLOBAL } from "../../../components/Globals";
import { queryData } from "./query-builder/data.js";
import {
  useMenuListQueryOptions,
  useFetchData,
} from "../add-edit/formfield/hooks.js";
import CiList from "./ciList.js";

const cookies = new Cookies();
let cookiesArray = cookies.get("gph");
if (cookiesArray) cookiesArray = cookiesArray.replace("s:", "");
if (cookiesArray)
  cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf("."));
cookiesArray = cookiesArray.split("~");
let cmdbHost = cookiesArray[71];

const AddEditCiGroup = (props) => {
  const dispatch = useDispatch();

  const params = useParams();

  const ciGgroupId = params?.groupId;
  const isAddMode = !ciGgroupId;

  const translator = useSelector((state) => state.spcmReducer.tr);
  const ciCompany = useSelector((state) => state.ciCompany);
  const classOptions = useSelector((state) => state.compClass);
  const hosts = useSelector((state) => state?.hosts);

  const queryOptions = useMenuListQueryOptions("queryOptions");

  // const { data: attachedCiData, loading } = useFetchData(
  //   isAddMode ? null : GLOBAL.attachedCIGroup(ciGgroupId),
  //   {page:1,limit:10}
  //   // isAddMode ? {} : { group_id: params?.ciGgroupId }
  // );

  const {
    setFieldValue,
    handleChange,
    values,
    isValid,
    submitCount,
    errors,
    touched,
  } = props;

  const [companyId, setCompanyId] = useState("");
  const [companyTypeaheadOptions, setCompanyTypeaheadOptions] = useState([]);

  const onCrossClickCompany = () => {
    dispatch(resetCiClass());

    setFieldValue("company_id", "");
    setFieldValue("company_name", "");

    setFieldValue("class_id", "");
    setFieldValue("class_name", "");
  };

  const handleChangeClass = (event) => {
    const selectedOption = event.target["selectedIndex"];
    const className = event.target.options[selectedOption].dataset?.name;
    setFieldValue("class_id", event.target.value);
    setFieldValue("class_name", className);
  };

  useEffect(() => {
    if (ciCompany && ciCompany.length > 0) {
      const options = ciCompany.map((item) => ({
        id: item.field1Value,
        label: item.field1Key,
      }));
      setCompanyTypeaheadOptions(options);
    }
  }, [ciCompany]);

  useEffect(() => {
    dispatch(loadCICompany("0"));
  }, [dispatch]);

  useEffect(() => {
    if (values?.company_id) {
      dispatch(loadClassByCompanyId(values?.company_id, false, "ACTIVE"));
    }
  }, [dispatch, values?.company_id]);

  useEffect(() => {
    const kendoFields = queryOptions.map((field) => {
          
      // Initialize parsedOrder and handle different types of ORDER
      let parsedOrder = {};
    
      try {
        if (typeof field.ORDER === "string") {
          parsedOrder = JSON.parse(field.ORDER); // Parse the string
        } else if (typeof field.ORDER === "object") {
          parsedOrder = field.ORDER; // Use object as-is
        } else {
          throw new Error("Invalid ORDER type"); // Handle invalid types
        }
    
        // Return the transformed field in KendoReact-compatible format
        return {
          name: parsedOrder.value || "", // Use empty string as fallback
          label: parsedOrder.label || "Unknown Field", // Default label
          type: parsedOrder?.attributeValueType === "DateTime" ? "date" : "string", // Determine type
          attributeValueType : parsedOrder.attributeValueType || "dropdown",
          operators: (parsedOrder.operators || []).map((operator) => ({
            text: operator.label || "Unknown Operator", // Default operator label
            operator: operator.value || "", // Default operator value
          })),
          api: `${parsedOrder.apiEndPoint || ""}`,
          headers: parsedOrder.headers || null,
          apiHost: hosts[parsedOrder.apiHost] || null,
          bindingAttributes: JSON.stringify(
            parsedOrder.bindingAttributes || {}
          ),
          body: parsedOrder.body || null,
          params: parsedOrder.params || null,
          methodType: parsedOrder.methodType || "GET",
          apiEndPoint: `${hosts[parsedOrder.apiHost] || ""}${
            parsedOrder?.apiEndPoint || ""
          }`,      
          attributes: null, // Default value
        };
      } catch (error) {
        console.error("Error processing field:", field, error.message);
        return null; // Return null for invalid fields
      }
    });

    const formattedOrders = queryOptions?.reduce((acc, item) => {
      try {
        let parsedOrder = {};
        let parsedHeaders = {};

        if (typeof item.ORDER === "string") {
          parsedOrder = JSON.parse(item.ORDER); // Parse the string
        } else if (typeof item.ORDER === "object") {
          parsedOrder = item.ORDER; // Use object as-is
        } else {
          parsedOrder = {}; // Fallback for invalid types
        }

        // if (parsedOrder.headers && typeof parsedOrder.headers === "string") {
        //   parsedHeaders = JSON.parse(parsedOrder.headers);
        // } else if (typeof parsedOrder.headers === "object") {
        //   parsedHeaders = parsedOrder.headers;
        // }

        // if (parsedOrder.headers && typeof parsedOrder.headers === "string") {
        //   parsedHeaders = JSON.parse(parsedOrder.headers);
        // } else {
        //   parsedHeaders = { path: {} }; // Default structure
        // }

        // console.log("parsedOrder===>",parsedOrder,item);
        // parsedHeaders.path = {
        //   ...parsedHeaders.path, // Existing path keys
        //   ...(parsedOrder.params || {}), // Add params if present
        // }

        // Transform parsedOrder to match the desired format
        const formattedOrder = {
          name: parsedOrder.name || null,
          value: parsedOrder.value || null,
          label: parsedOrder.label || null,
          foreignTableExists: false, // Default value
          foriegnKey: null, // Default value
          dataType: "character varying", // Default value
          attributeValueType : parsedOrder.attributeValueType || "dropdown",
          api: `${parsedOrder.apiEndPoint || ""}`,
          headers: parsedOrder.headers || null,
          apiHost: hosts[parsedOrder.apiHost] || null,
          bindingAttributes: JSON.stringify(
            parsedOrder.bindingAttributes || {}
          ),
          body: parsedOrder.body || null,
          params: parsedOrder.params || null,
          methodType: parsedOrder.methodType || "GET",
          apiEndPoint: `${hosts[parsedOrder.apiHost] || ""}${
            parsedOrder?.apiEndPoint || ""
          }`,
          operators: parsedOrder.operators || [],
          attributes: null, // Default value
        };

        acc.push(formattedOrder);
      } catch (error) {
        console.error(`Error processing item.ORDER: ${item.ORDER}`, error);
        acc.push({}); // Add a blank object for failed cases
      }

      return acc;
    }, []);


    props.setFields(formattedOrders || []);

    props.setFieldsKendo(kendoFields || []);
  }, [queryOptions]);

  return (
    <div className="borderForm bFormDiv">
      <Row className={!isValid && submitCount > 0 ? "" : "d-none"}>
        <Col md={12} sm={12} xs={12}>
          {!isValid && submitCount > 0 && (
            <div className="alert alert-danger">
              {Object.keys(errors).length > 0
                ? errors.error ||
                  "The form has missing required fields, please revalidate the highlighted fields and update the form!"
                : "The form has missing required fields, please revalidate the highlighted fields and update the form!"}
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <InputTextField
            name="group_name"
            label="Group Name"
            required
            disabled={!isAddMode}
          />
        </Col>
        <Col md="6">
          <Form.Group className="form-group">
            <Form.Label bsClass="">
              <span className="rStar"></span>
              {translator["Company"]}
            </Form.Label>
            <Field
              name="company_id"
              component={CmdbDropdownPlusTypeahead}
              options={companyTypeaheadOptions || []}
              onSelection={(val) => {
                setCompanyId(val?.[0]?.id);
                setFieldValue("company_id", val?.[0]?.id || "0");
                setFieldValue("company_name", val?.[0]?.label || "");
              }}
              inputValue={values?.company_name || ""}
              id="company"
              onInputChange={(val) => {
                console.log(val, "onInputChange");
              }}
              selectedOptions={
                values?.company_name
                  ? [{ id: values.company_id, label: values.company_name }]
                  : []
              }
              errorColor={
                touched?.company_id && !values?.company_id ? "error" : ""
              }
              disabled={!isAddMode}
              onCrossClick={onCrossClickCompany}
            />
          </Form.Group>
        </Col>
        {/* <Col md="6">
          <SelectField
            required
            name="class_id"
            label={translator["Class"]}
            onChange={(event) => {
              handleChangeClass(event);
            }}
          >
            {classOptions?.map((classObj, i) => {
              return (
                <option
                  value={classObj.CLASS_ID}
                  key={i}
                  data-name={classObj.CLASS_NAME}
                >
                  {classObj.CLASS_NAME}
                </option>
              );
            })}
          </SelectField>
        </Col>
        <Col md="6">
          <Form.Group className="form-group">
            <Form.Label bsClass="">
              <span className="rStar"></span>
              {"Is External"}
            </Form.Label>
            <div className="radioInlineBox margin-t-5">
              <label className="radio-inline">
                <Field
                  type="radio"
                  name="is_external"
                  value="true"
                  checked={values?.is_external === true}
                  onChange={() => setFieldValue("is_external", true)}
                />
                True
              </label>
              <label className="radio-inline">
                <Field
                  type="radio"
                  name="is_external"
                  value="false"
                  checked={values?.is_external === false}
                  onChange={() => setFieldValue("is_external", false)}
                />
                False
              </label>
            </div>
          </Form.Group>
        </Col> */}
      </Row>
      <Row>
        <Col md={12}>
          <StandardTextArea name="description" label="Description" rows="3" />
        </Col>
      </Row>
      {!isAddMode && (
        <Row>
          <Col md={12}>
            <h2 className="sPageHeading1 py-2">Attached CI's</h2>
            <CiList isAddMode={isAddMode} ciGgroupId={ciGgroupId}/>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default AddEditCiGroup;
