
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState} from "react";
import {Tab,Tabs} from 'react-bootstrap';
import {useSelector } from "react-redux";

import AssociateCompanies from "./associate-companies";
import AssociateGroups from "./associate-groups";
import AssociateRoles from "./associate-roles";
import RestrictAccess from "./restrict-access";
import CloseBtnRightPanel from "../../../../common/closeBtnRightPanel";
import { getCookiesValuesByPositions } from "../../../../common/helper";

const TabsComponent = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const [key, setKey] = useState(1);
    const [show, setShow] = useState(false);
    const { value1: user_roleId } = getCookiesValuesByPositions([33]);
    useEffect(() => {
        if(user_roleId && user_roleId !== ''){
        let user_roleIdArr = user_roleId.split(","); 
            if(user_roleIdArr.includes('1')){
            setShow(true);
            } else if(user_roleIdArr?.includes('72') && !user_roleIdArr?.includes('1')){
              setShow(false);
            }
        }
        }, [user_roleId])
    return (
        <div className="hambTab userInfoPopupParent">
            <CloseBtnRightPanel showRightSide={props.showRightSideFun} />
            <div className="rPageHeading">
                <div className="offNam margin-t-5 margin-b-5">
                {tr["User Details"]}
                </div>
            </div>
            <div className="rBoxGap">
                <div className="rBoxTabs hideTabNav">
                    <Tabs activeKey={key} onSelect={(k) => setKey(k)} id="tabId">
                        <Tab eventKey={1} title={tr["Groups"]}><AssociateGroups 
           companyType ={props.companyType}        
           /></Tab>
                        <Tab eventKey={2} title={tr["Roles"]}><AssociateRoles show={show}/></Tab>
                        <Tab eventKey={3} title="Companies"><AssociateCompanies
           companyType ={props.companyType} show={show}       
           /></Tab>
                        <Tab eventKey={4} title="Restrict Access"><RestrictAccess show={show}/></Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default TabsComponent;