
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import CreateMailboxConfigHeader from "./CreateMailboxConfigHeader";
import CreatMailboxConfigForm from "./CreateMailboxConfigForm";
import CreateSurveyConfigForm from "./CreateSurveyConfigForm.js";
import CreateApprovalConfigForm from "./CreateApprovalConfigForm.js";
import CreateInstanceMailboxConfig from "./CreateInstanceMailboxConfig.js"
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import Breadcrumbs from "../../common/header/breadcrumbs.js";
import { Container } from "react-bootstrap";
import "_Css/form/_form.scss";
const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

const CreateMailboxConfigIndex = (props) => {
  const [mailBoxType, setMailBoxType] = useState('');
  const [companyError, setCompanyError] = useState("");
  const [offeringError, setOfferingError] = useState('');
  const [requestorError, setRequestorError] = useState('');
  const [selectedCompanyList, setSelectedCompanyList] = useState([]);
  const [selectedRequestor, setSelectedRequestor] = useState({});
  const [selectedOffering, setSelectedOffering] = useState({});

  const [mailboxIdErrorColor, setMailboxIdErrorColor] = useState("");
  const [mailboxProviderErrorColor, setMailboxProviderErrorColor] = useState("");
  const [whitelistedDomainErrorColor, setWhitelistedDomainErrorColor] = useState("");
  const [urgencyErrorColor, setUrgencyErrorColor] = useState("");
  const [impactErrorColor, setImpactErrorColor] = useState("");
  const [actionsErrorColor, setActionsErrorColor] = useState("");
  const [statusErrorColor, setStatusErrorColor] = useState("");
  const [isFulfilled, setIsFulfilled] = useState("");
  const translator = useSelector((state) => state.spcmReducer.tr);
  const location = useLocation();
  const [filterTabActive, setFilterTabActive] = useState(1); 
  const [authErrorColor, setAuthErrorColor] = useState("");

  useEffect(() => {
    const activeTabFromState = location.state?.activeTab || 1;
    setFilterTabActive(activeTabFromState);
  }, [location.state]); // Re-run this effect if location changes
  
  const clearValidationErrors = () => {
    setCompanyError("");
    setOfferingError("");
    setRequestorErrorColor();
    setMailboxIdErrorColor("");
    setMailboxProviderErrorColor("");
    setWhitelistedDomainErrorColor("");
    setImpactErrorColor("");
    setUrgencyErrorColor("");
    setActionsErrorColor("");
    setStatusErrorColor("");
    setSelectedOffering([]);
    setSelectedCompanyList([]);
    setAuthErrorColor("");

  };
//console.log("Tab filterTabActive is selected", filterTabActive);
useEffect(() => { 
  if (filterTabActive === 1) { 
   // console.log("Tab 1 is selected"); 
  }
  if (filterTabActive === 2) { 
   // console.log("Tab 2 is selected"); 
  }
}, [filterTabActive]); 


  const setCompanyErrorColor = () => {
    if (selectedCompanyList.length === 1) {
      setCompanyError("");
    } else {
      setCompanyError("error");
    }
  };

  const setOfferingErrorColor = () => {
    if (Object.keys(selectedOffering).length > 0) {
      setOfferingError("");
    } else {
      setOfferingError("error");
    }
  };
  const setRequestorErrorColor = () => {
    if (Object.keys(selectedRequestor).length > 0) {
      setRequestorError('');
    } else {
      setRequestorError('error');
    }
  };
  const isFulfilledSet = (value) => {
    setIsFulfilled(value)
  }

  return (
    <div>
      <div>
        <div className="container-fluid padding-t-10 margin-b-15">
          <Breadcrumbs
            activePageName={"Create"}
            parentPageurl="/systemConfig"
            parentPageName={translator["Mailbox Configuration"]}
          />
        </div>
        <div className="container-fluid" bsClass="">
        
          <Form>
            <CreateMailboxConfigHeader
              translator={translator}
              setRequestorErrorColor={setRequestorErrorColor}
              setOfferingErrorColor={setOfferingErrorColor}
              setCompanyErrorColor={setCompanyErrorColor}
              setActionsErrorColor={setActionsErrorColor}
              setImpactErrorColor={setImpactErrorColor}
              setUrgencyErrorColor={setUrgencyErrorColor}
              setWhitelistedDomainErrorColor={setWhitelistedDomainErrorColor}
              setMailboxProviderErrorColor={setMailboxProviderErrorColor}
              setMailboxIdErrorColor={setMailboxIdErrorColor}
              setStatusErrorColor={setStatusErrorColor}
              setAuthErrorColor={setAuthErrorColor}
              isFulfilled={isFulfilled}
              setFilterTabActive={setFilterTabActive}
              filterTabActive={filterTabActive}              
              clearValidationErrors={clearValidationErrors}
            />
            <Row className="row-eq-height">
              <Col md={8}>
              {filterTabActive === 1 ? (
                <CreatMailboxConfigForm translator={translator}
                  setMailBoxType={setMailBoxType} 
                  offeringError={offeringError}
                  requestorError={requestorError}
                  companyError={companyError}
                  setRequestorErrorColor={setRequestorErrorColor}
                  setOfferingErrorColor={setOfferingErrorColor}
                  setCompanyErrorColor={setCompanyErrorColor}
                  setSelectedRequestor={setSelectedRequestor}
                  setSelectedCompanyList={setSelectedCompanyList}
                  setSelectedOffering={setSelectedOffering}
                  selectedRequestor={selectedRequestor}
                  selectedCompanyList={selectedCompanyList}
                  actionsErrorColor={actionsErrorColor}
                  impactErrorColor={impactErrorColor}
                  urgencyErrorColor={urgencyErrorColor}
                  whitelistedDomainErrorColor={whitelistedDomainErrorColor}
                  mailboxProviderErrorColor={mailboxProviderErrorColor}
                  mailboxIdErrorColor={mailboxIdErrorColor}
                  setActionsErrorColor={setActionsErrorColor}
                  setImpactErrorColor={setImpactErrorColor}
                  setUrgencyErrorColor={setUrgencyErrorColor}
                  setWhitelistedDomainErrorColor={setWhitelistedDomainErrorColor}
                  setMailboxProviderErrorColor={setMailboxProviderErrorColor}
                  setMailboxIdErrorColor={setMailboxIdErrorColor}
                  isFulfilledSet={isFulfilledSet}
                  setStatusErrorColor={setStatusErrorColor}
                  statusErrorColor={statusErrorColor}
                />): filterTabActive === 2 ? (
                <CreateSurveyConfigForm translator={translator}
                  setMailBoxType={setMailBoxType} 
                  offeringError={offeringError}
                  requestorError={requestorError}
                  companyError={companyError}
                  setRequestorErrorColor={setRequestorErrorColor}
                  setOfferingErrorColor={setOfferingErrorColor}
                  setCompanyErrorColor={setCompanyErrorColor}
                  setSelectedRequestor={setSelectedRequestor}
                  setSelectedCompanyList={setSelectedCompanyList}
                  setSelectedOffering={setSelectedOffering}
                  selectedRequestor={selectedRequestor}
                  selectedCompanyList={selectedCompanyList}
                  actionsErrorColor={actionsErrorColor}
                  impactErrorColor={impactErrorColor}
                  urgencyErrorColor={urgencyErrorColor}
                  whitelistedDomainErrorColor={whitelistedDomainErrorColor}
                  mailboxProviderErrorColor={mailboxProviderErrorColor}
                  mailboxIdErrorColor={mailboxIdErrorColor}
                  setActionsErrorColor={setActionsErrorColor}
                  setImpactErrorColor={setImpactErrorColor}
                  setUrgencyErrorColor={setUrgencyErrorColor}
                  setWhitelistedDomainErrorColor={setWhitelistedDomainErrorColor}
                  setMailboxProviderErrorColor={setMailboxProviderErrorColor}
                  setMailboxIdErrorColor={setMailboxIdErrorColor}
                  isFulfilledSet={isFulfilledSet}
                  setStatusErrorColor={setStatusErrorColor}
                  statusErrorColor={statusErrorColor}
                  />
                ):filterTabActive === 3 ? 
                (<CreateApprovalConfigForm translator={translator}
                  setMailBoxType={setMailBoxType} 
                  offeringError={offeringError}
                  requestorError={requestorError}
                  companyError={companyError}
                  setRequestorErrorColor={setRequestorErrorColor}
                  setOfferingErrorColor={setOfferingErrorColor}
                  setCompanyErrorColor={setCompanyErrorColor}
                  setSelectedRequestor={setSelectedRequestor}
                  setSelectedCompanyList={setSelectedCompanyList}
                  setSelectedOffering={setSelectedOffering}
                  selectedRequestor={selectedRequestor}
                  selectedCompanyList={selectedCompanyList}
                  actionsErrorColor={actionsErrorColor}
                  impactErrorColor={impactErrorColor}
                  urgencyErrorColor={urgencyErrorColor}
                  whitelistedDomainErrorColor={whitelistedDomainErrorColor}
                  mailboxProviderErrorColor={mailboxProviderErrorColor}
                  mailboxIdErrorColor={mailboxIdErrorColor}
                  setActionsErrorColor={setActionsErrorColor}
                  setImpactErrorColor={setImpactErrorColor}
                  setUrgencyErrorColor={setUrgencyErrorColor}
                  setWhitelistedDomainErrorColor={setWhitelistedDomainErrorColor}
                  setMailboxProviderErrorColor={setMailboxProviderErrorColor}
                  setMailboxIdErrorColor={setMailboxIdErrorColor}
                  isFulfilledSet={isFulfilledSet}
                  setStatusErrorColor={setStatusErrorColor}
                  statusErrorColor={statusErrorColor}
                  />
                ): (<CreateInstanceMailboxConfig translator={translator}
                  setMailBoxType={setMailBoxType} 
                  offeringError={offeringError}
                  requestorError={requestorError}
                  companyError={companyError}
                  setRequestorErrorColor={setRequestorErrorColor}
                  setOfferingErrorColor={setOfferingErrorColor}
                  setCompanyErrorColor={setCompanyErrorColor}
                  setSelectedRequestor={setSelectedRequestor}
                  setSelectedCompanyList={setSelectedCompanyList}
                  setSelectedOffering={setSelectedOffering}
                  selectedRequestor={selectedRequestor}
                  selectedCompanyList={selectedCompanyList}
                  actionsErrorColor={actionsErrorColor}
                  impactErrorColor={impactErrorColor}
                  urgencyErrorColor={urgencyErrorColor}
                  whitelistedDomainErrorColor={whitelistedDomainErrorColor}
                  mailboxProviderErrorColor={mailboxProviderErrorColor}
                  authErrorColor={authErrorColor}
                  mailboxIdErrorColor={mailboxIdErrorColor}
                  setActionsErrorColor={setActionsErrorColor}
                  setImpactErrorColor={setImpactErrorColor}
                  setUrgencyErrorColor={setUrgencyErrorColor}
                  setWhitelistedDomainErrorColor={setWhitelistedDomainErrorColor}
                  setMailboxProviderErrorColor={setMailboxProviderErrorColor}
                  setAuthErrorColor={setAuthErrorColor}
                  setMailboxIdErrorColor={setMailboxIdErrorColor}
                  isFulfilledSet={isFulfilledSet}
                  setStatusErrorColor={setStatusErrorColor}
                  statusErrorColor={statusErrorColor}
                  />
                )}
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CreateMailboxConfigIndex;
