
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import PopUpAuditLog from "_Commons/WorkItemBoard/breakfixAuditLog";
import PopUpActivityLog from "_Commons/WorkItemBoard/breakfixActivityLog";
import { IoClose } from "react-icons/io5"; 
import WIBNotifcation from '../../WIBNotification';
const MoreArrow = (
  <i title="More" className="fa fa-angle-double-down f-size-15"></i>
);

class TabPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabKey: 1,
      isOpen: true,
      message: "",
      messageactivity: "",
      taskeditFlag: "0",
      taskcode: "",
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.changeCompMessage = this.changeCompMessage.bind(this);
    this.changeCompMessageactivity = this.changeCompMessageactivity.bind(this);
    this.clicktaskEdit = this.clicktaskEdit.bind(this);
  }
  clicktaskEdit(i, j, k) {
    this.setState({
      taskeditFlag: i,
      taskcode: j,
      taskregid: k,
    });
  }
  changeCompMessage(message) {
    this.setState({ message });
  }
  changeCompMessageactivity(messageactivity) {
    this.setState({ messageactivity });
  }
  overlayExit() {
    document.body.style.overflow = "visible";
  }
  handleSelect(tabKey) {
    this.setState({ tabKey });
  }
  render() {
    return (
      <div>
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    this.props.changeActionView("");
                    }}
                >
                    <IoClose />
                </button>
                </li>
            </ul>
        </div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {this.props.translator["Activity Details"]}
          </div>
        </div>
        <div className="rBoxGap">
          <div className="rBoxTabs">
            <Tabs
              activeKey={this.state.tabKey}
              onSelect={this.handleSelect}
              id="tabId"
            >
              <Tab eventKey={1} title={this.props.translator["Comments"]}>
                <PopUpActivityLog
                  fieldStatus={this.props.fieldStatus}
                  itemId={this.props.investigationId}
                  investigationdetail={this.props.invdetails}
                  module="Investigation"
                  translator={this.props.translator}
                  date_format={this.props.date_format}
                  disableFieldsForGuestRole={this.props.disableFieldsForGuestRole}
                  aiSummaruzationEnabled={this.props.aiSummaruzationEnabled}
                />
              </Tab>
              <Tab eventKey={6} title={this.props.translator["Audit Log"]}>
                <PopUpAuditLog
                  message={this.state.message}
                  changeCompMessage={this.changeCompMessage}
                  investigationdetail={this.props.invdetails}
                  itemId={this.props.investigationId}
                  module="Investigation"
                  translator={this.props.translator}
                  date_format={this.props.date_format}
                  urgencyListKeyValue={this.props.urgencyListKeyValue}
			            impactListKeyValue={this.props.impactListKeyValue}	
                />
              </Tab>
              <Tab eventKey={3} title={"Notifications"}>
                <WIBNotifcation
                  requestNumber={this.props.invdetails.investigationNumber}
                  orderNumber={""}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

export default TabPage;
