/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "universal-cookie";
import { Field, Formik, Form } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { GLOBAL } from "_Globals";
import { useUserInfo } from "../add-edit/formfield/hooks";
import Breadcrumbs from "../../common/header/breadcrumbs";
import CmdbProcessHierarchy from "../create/CmdbProcessHierarchy";
import { getTimelineData } from "../../../actions/cmdb/cmdbTimelineAction";
import { navigationHooks } from "../../../helpers/NavigationHook";
import {
  loadCIEditDetails,
  loadModelName,
} from "../../../actions/cmdb/lovAction";
import AddEditCmdb from "./addEditCmdb";

const cookies = new Cookies();

let cookiesData = cookies.get("gph");
if (cookiesData) cookiesData = cookiesData.replace("s:", "");
if (cookiesData)
  cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf("."));
cookiesData = cookiesData.split("~");
let dateformat = cookiesData[2];
let full_name = cookiesData[42];

const CmdbEditIndex = (props) => {
  const dispatch = useDispatch();
  // const formikRef = useRef(null);
  const params = useParams();
  const userInfo = useUserInfo();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const cIEditDetails = useSelector((state) => state.CIEditDetails);

  const ciId = params?.ciId;
  const isAddMode = !ciId;

  const [isRightEditPanel, setrightEditPanel] = useState(true);
  const [initVals, setInitVals] = useState({
    ci_name: "",
    company_id: "",
    company_name: "",
    class_id: "",
    class_name: "",
    type: "",
    category_id: "",
    category_name: "",
    sub_category_id: 0,
    sub_category_name: "",
    ci_id: "",
    asset_tag: "",
    serial_number: "",
    status: "",
    status_name: "",
    sub_status: "",
    description: "",
    manufacturer: "",
    model_name: "",
    model_number: "",
    metallic: "",
    compliance: null,
    location_id: "0",
    location_name: null,
    environment: "",
    keyword: "",
    is_external: "False",
    dataset: "",
    business_owner_id: 0,
    business_owner_name: "",
    technical_owner_id: 0,
    technical_owner_name: "",
    owner_id: 0,
    owner_name: null,
    department_id: null,
    department_name: null,
    change_group_id: null,
    change_group_name: null,
    availaibility_percentage: "",
    support_company_id: "0",
    support_company_name: "",
    group_id: "0",
    group_name: "",
    owned_by_id: "0",
    owned_by: "",
    ownership_type_id: "0",
    order_date: undefined,
    acquisition_cost: null,
    cost_center_id: "0",
    cost_center: "",
    purchase_order_number: "",
    invoice_number: "",
    cost_currency_id: "",
    general_ledger_account: null,
    net_book_value: "",
    depreciation_charged_id: "",
    depreciation_charged_name: "",
    depreciation_schedule: "",
    vendor_id: "0",
    vendor: "",
    currency_id: "0",
    charge_category_id: "0",
    charge_type_id: "0",
    charge_frequency: "",
    charge_to_cc_id: null,
    charge_to_cc_name: null,
    charge_to_company_id: "0",
    charge_to_company_name: "",
    charge_to_project_id: "0",
    charge_to_project_name: null,
    vendor_seller_id: "0",
    vendor_seller: "",
    host_name: "",
    ip_address: "",
    mac_address: "",
    backup_retention: "",
    monitored: "",
    monitored_id: "0",
    domain_name: "",
    allocated_capacity: "",
    maximum_capacity: "",
    capacity_unit: "",
    capacity_utilization: "",
    capacity_thresholds: "",
    disaster_recovery_enabled: false,
    disaster_recovery_location_id: "0",
    disaster_recovery_location_name: "",
    disaster_tier_id: "0",
    recovery_point_objective: "",
    recovery_time_objective: "",
    discovery_source_name: "",
    patch_hold: "",
    patch_hold_reason: "",
    patch_schedule: "",
    patch_level: "",
    charge_category_name: null,
    charge_currency: "",
    charge_currency_id: "0",
    charge_type_name: "",
    cost_currency: null,
    created_by: "",

    dataset_name: "",
    depreciation_charged_name: "",
    disaster_tier_name: null,
    discovery_source_id: "0",
    domain_name: "",
    environment_name: "",

    manufacturer_id: "0",
    metallic_name: "",
    model_id: "0",
    owner_email: null,
    ownership_type: null,
    sub_status_name: "",
    technical_owner_email: "",
    type_name: "",
    business_owner_email: "",
    warranty_expiration_date: undefined,
    charge_start_date: undefined,
    scheduled_return_date: undefined,
    first_discovered_at: undefined,
    allocation_date: undefined,

    business_owner_group_id: "0",
    business_owner_group_name: "",

    technical_owner_group_id: "0",
    technical_owner_group_name: "",

    cab_group_id: "0",
    cab_group_name: "",
    project: ""
  });

  let initialValues = {
    ...initVals,
  };

  const validationSchema = Yup.object().shape({
    ci_name: Yup.string().required("Required"),
    company_id: Yup.string().required("Required"),
    class_id: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
    category_name: Yup.string().required("Required"),
    sub_category_name: Yup.string().required("Required"),
    status: Yup.string().required("Required"),
    status_name: Yup.string(),
    sub_status: Yup.string().required("Required"),
    // allocation_date: Yup.string().required("Required"),
    // business_owner_name: Yup.string().required("Required"),
    // owner_name: Yup.string().required("Required"),
    // technical_owner_name: Yup.string().required("Required"),
    //model_number: Yup.number().typeError("Please enter a valid number.").notRequired(),
    // net_book_value: Yup.number().typeError("Please enter a valid number.").notRequired(),
    // availaibility_percentage: Yup.number().typeError("Please enter a valid number.")
    // .notRequired()
    // .min(0, 'Percentage cannot be less than 0')
    // .max(100, 'Percentage cannot be more than 100'),
    purchase_order_number: Yup.number()
      .typeError("Please enter a valid number.")
      .notRequired(),
    //maximum_capacity: Yup.number().typeError("Please enter a valid number.").notRequired(),
   // Conditional validation based on status_name
   support_company_name: Yup.string().when('status_name', {
    is: (val) => val?.includes("Deployed"), 
    then: () => Yup.string().required("Required"),
    otherwise: () => Yup.string(),
  }),
  group_name: Yup.string().when('status_name', {
    is: (val) => val?.includes("Deployed"), 
    then: () => Yup.string().required("Required"),
    otherwise: () => Yup.string(),
  }),
  
  });

  const onSubmit = async (fields, { resetForm, setSubmitting, errors }) => {
    setSubmitting(true);

    const finalDateFormat = dateformat.slice(0, 10)?.toUpperCase() + " HH:mm:ss";

    let changedValues = {};
    if(fields?.ci_name && fields.ci_name != cIEditDetails.CI_NAME){
      changedValues.ci_name = fields.ci_name;
    }
    if(fields?.company_id != cIEditDetails.COMPANY_ID){
      changedValues.company_id = fields.company_id;
      changedValues.company_name = fields.company_name;
    }
    if(fields?.class_id != cIEditDetails.CLASS_ID){
      changedValues.class_id = fields.class_id;
      changedValues.class_name = fields.class_name;
    }
    if(fields?.type && fields.type != cIEditDetails.TYPE){
      changedValues.type = fields.type;
    }
    if(fields?.category_id != cIEditDetails.CATEGORY_ID){
      changedValues.category_id = fields.category_id;
      changedValues.category_name = fields.category_name;
    }
    if(fields?.sub_category_id != cIEditDetails?.SUB_CATEGORY_ID){
      changedValues.sub_category_id = fields.sub_category_id;
      changedValues.sub_category_name = fields.sub_category_name;
    }
    if(fields?.ci_id != cIEditDetails.CI_ID){
      changedValues.ci_id = fields.ci_id;
    }
    if(fields?.asset_tag && fields.asset_tag != cIEditDetails.ASSET_TAG){
      changedValues.asset_tag = fields.asset_tag;
    }
    if(fields?.serial_number && fields.serial_number != cIEditDetails.SERIAL_NUMBER){
      changedValues.serial_number = fields.serial_number;
    }
    if(fields?.status != cIEditDetails.STATUS){
      changedValues.status = fields.status;
      changedValues.status_name = fields.status_name;
    }
    if(fields?.sub_status != cIEditDetails.SUB_STATUS){
      changedValues.sub_status = fields.sub_status;
      changedValues.sub_status_name = fields.sub_status_name;
    }
    if(fields?.allocation_date && fields.allocation_date != cIEditDetails.ALLOCATION_DATE){
      changedValues.allocation_date = moment(fields.allocation_date,finalDateFormat).format("YYYY-MM-DD HH:mm:ss");
    }
    if(fields?.description && fields.description != cIEditDetails.DESCRIPTION){
      changedValues.description = fields.description;
    }
    if(fields?.manufacturer && fields.manufacturer != cIEditDetails.MANUFACTURER){
      changedValues.manufacturer = fields.manufacturer;
    }
    if(fields?.model_name && fields.model_name != cIEditDetails.MODEL_NAME){
      changedValues.model_name = fields.model_name;
    }
    if(fields?.model_number && fields.model_number != cIEditDetails.MODEL_NUMBER){
      changedValues.model_number = fields.model_number;
    }
    if(fields?.metallic != cIEditDetails.METALLIC){
      changedValues.metallic = fields.metallic;
      changedValues.metallic_name = fields.metallic_name;
    }
    if(fields?.warranty_expiration_date && fields.warranty_expiration_date != cIEditDetails.WARRANTY_EXPIRATION_DATE){
      changedValues.warranty_expiration_date = moment(fields.warranty_expiration_date,finalDateFormat).format("YYYY-MM-DD HH:mm:ss");
    }
    if(fields?.compliance && cIEditDetails.COMPLIANCE != null && fields.compliance.length != cIEditDetails.COMPLIANCE.length){
      changedValues.compliance = JSON.stringify(fields.compliance);
    }else{
      if(fields?.compliance && fields.compliance.length > 0 && cIEditDetails.COMPLIANCE === null){
        changedValues.compliance = JSON.stringify(fields.compliance);
      }else if(fields?.compliance && fields.compliance.length > 0 && fields.compliance.length === cIEditDetails.COMPLIANCE.length){
        const fieldsComplianceRef = fields.compliance;
        const editDetailsComplianceRef = cIEditDetails.COMPLIANCE;
        let disimilarityCounter = 0;
        for(let i=0; i<fieldsComplianceRef.length; i++){
               if(fieldsComplianceRef[i]?.value != editDetailsComplianceRef[i]?.value){
                disimilarityCounter++;
               }
        }
        if(disimilarityCounter > 0){
          changedValues.compliance = JSON.stringify(fields.compliance);
        }
      }
    }
    if(fields?.location_id != cIEditDetails.LOCATION_ID){
      changedValues.location_id = fields.location_id;
      changedValues.location_name = fields.location_name;
    }
    if(fields?.environment != cIEditDetails.ENVIRONMENT){
      changedValues.environment = fields.environment;
      changedValues.environment_name = fields.environment_name;
    }
    if(fields?.keyword && fields.keyword != cIEditDetails.KEYWORD){
      changedValues.keyword = fields.keyword;
    }
    if(fields?.is_external && fields.is_external != cIEditDetails.IS_EXTERNAL+''){
      changedValues.is_external = fields.is_external;
    }
    if(fields?.deployement_date && fields.deployement_date != cIEditDetails.DEPLOYEMENT_DATE){
      changedValues.deployement_date = moment(fields.deployement_date,finalDateFormat).format("YYYY-MM-DD HH:mm:ss");
    }
    if(fields?.decommission_date && fields.decommission_date != cIEditDetails.DECOMMISSION_DATE){
      changedValues.decommission_date = moment(fields.decommission_date,finalDateFormat).format("YYYY-MM-DD HH:mm:ss");
    }
    if(fields?.support_end_date && fields.support_end_date != cIEditDetails.SUPPORT_END_DATE){
      changedValues.support_end_date = moment(fields.support_end_date,finalDateFormat).format("YYYY-MM-DD HH:mm:ss");
    }
    if(fields?.first_deployed_date && fields.first_deployed_date != cIEditDetails.FIRST_DEPLOYED_DATE){
      changedValues.first_deployed_date = moment(fields.first_deployed_date,finalDateFormat).format("YYYY-MM-DD HH:mm:ss");
    }
    if(fields?.installation_date && fields.installation_date != cIEditDetails.INSTALLATION_DATE){
      changedValues.installation_date = moment(fields.installation_date,finalDateFormat).format("YYYY-MM-DD HH:mm:ss");
    }
    if(fields?.dataset != cIEditDetails.DATASET){
      changedValues.dataset = fields.dataset;
      changedValues.dataset_name = fields.dataset_name;
    }
    if(fields?.business_owner_group_id != cIEditDetails.BUSINESS_OWNER_GROUP_ID){
      changedValues.business_owner_group_id = fields.business_owner_group_id;
      changedValues.business_owner_group_name = fields.business_owner_group_name;
    }
    if(fields?.business_owner_id != cIEditDetails.BUSINESS_OWNER_ID){
      changedValues.business_owner_id = fields.business_owner_id;
      changedValues.business_owner_name = fields.business_owner_name;
    }
    if(fields?.owner_id != cIEditDetails.OWNER_ID){
      changedValues.owner_id = fields.owner_id;
      changedValues.owner_name = fields.owner_name;
      changedValues.allocation_date = null;
    }
    if(fields?.technical_owner_group_id != cIEditDetails.TECHNICAL_OWNER_GROUP_ID){
      changedValues.technical_owner_group_id = fields.technical_owner_group_id;
      changedValues.technical_owner_group_name = fields.technical_owner_group_name;  
    }
    if(fields?.technical_owner_id != cIEditDetails.TECHNICAL_OWNER_ID){
      changedValues.technical_owner_id = fields.technical_owner_id;
      changedValues.technical_owner_name = fields.technical_owner_name;
    }
    const validateAssignedUser =  cIEditDetails.ASSIGNED_USER === null || 
                                  (typeof cIEditDetails.ASSIGNED_USER === 'object' && Object.keys(cIEditDetails.ASSIGNED_USER).length === 0 && !Array.isArray(cIEditDetails.ASSIGNED_USER)) ||
                                  (Array.isArray(cIEditDetails.ASSIGNED_USER) && cIEditDetails.ASSIGNED_USER.length === 0) ||
                                  (typeof cIEditDetails.ASSIGNED_USER === 'string' && cIEditDetails.ASSIGNED_USER.includes("[]")); 
    const intermediateAssignedUser = validateAssignedUser ? [] : typeof cIEditDetails.ASSIGNED_USER === 'string' ? JSON.parse(cIEditDetails.ASSIGNED_USER) : cIEditDetails.ASSIGNED_USER;
    if(fields?.assigned_user && intermediateAssignedUser != null && fields.assigned_user.length != intermediateAssignedUser.length){
      changedValues.assigned_user = fields.assigned_user;
    }else{
      if(fields?.assigned_user && fields.assigned_user.length > 0 && intermediateAssignedUser === null){
        changedValues.assigned_user = fields.assigned_user;
      }else if(fields?.assigned_user && fields.assigned_user.length > 0 && fields.assigned_user.length === intermediateAssignedUser.length){
        const fieldsAssignedeRef = fields.assigned_user;
        let disimilarityCounter = 0;
        for(let i=0; i<fieldsAssignedeRef.length; i++){
               if(fieldsAssignedeRef[i]?.userId != intermediateAssignedUser[i]?.userId){
                disimilarityCounter++;
               }
        }
        if(disimilarityCounter > 0){
          changedValues.assigned_user = fields.assigned_user;
        }
      }
    }
    if(fields?.change_group_id != cIEditDetails.CHANGE_GROUP_ID){
      changedValues.change_group_id = fields.change_group_id;
      changedValues.change_group_name = fields.change_group_name;
    }
    if(fields?.cab_group_id != cIEditDetails.CAB_GROUP_ID){
      changedValues.cab_group_id = fields.cab_group_id;
      changedValues.cab_group_name = fields.cab_group_name;
    }
    if(fields?.project && fields.project != cIEditDetails.PROJECT){
      changedValues.project = fields.project;
    }
    if(fields?.assigned_date && fields.assigned_date != cIEditDetails.ASSIGNED_DATE){
      changedValues.assigned_date = moment(fields.assigned_date,finalDateFormat).format("YYYY-MM-DD HH:mm:ss");
    }
    if(fields?.department_id != cIEditDetails.DEPARTMENT_ID){
      changedValues.department_id = fields.department_id;
      changedValues.department_name = fields.department_name;
    }
    if(fields?.support_company_id != cIEditDetails.SUPPORT_COMPANY_ID){
      changedValues.support_company_id = fields.support_company_id;
      changedValues.support_company_name = fields.support_company_name;
    }
    if(fields?.group_id != cIEditDetails.GROUP_ID){
      changedValues.group_id = fields.group_id;
      changedValues.group_name = fields.group_name;
    }
    if(fields?.owned_by_id != cIEditDetails.OWNED_BY_ID){
      changedValues.owned_by_id = fields.owned_by_id;
      changedValues.owned_by = fields.owned_by; 
    }
    if(fields?.ownership_type_id != cIEditDetails.OWNERSHIP_TYPE_ID){
      changedValues.ownership_type_id = fields.ownership_type_id;
      changedValues.ownership_type = fields.ownership_type;
    }
    if(fields?.order_date && fields.order_date != cIEditDetails.ORDER_DATE){
      changedValues.order_date = moment(fields.order_date,finalDateFormat).format("YYYY-MM-DD HH:mm:ss");
    }
    if(fields?.order_received_date && fields.order_received_date != cIEditDetails.ORDER_RECEIVED_DATE){
      changedValues.order_received_date = moment(fields.order_received_date,finalDateFormat).format("YYYY-MM-DD HH:mm:ss");
    }
    if(fields?.acquisition_cost && fields.acquisition_cost != cIEditDetails.ACQUISITION_COST){
      changedValues.acquisition_cost = fields.acquisition_cost;
    }
    if(fields?.cost_center_id != cIEditDetails.COST_CENTER_ID){
      changedValues.cost_center_id = fields.cost_center_id;
      changedValues.cost_center = fields.cost_center; 
    }
    if(fields?.purchase_order_date && fields.purchase_order_date != cIEditDetails.PURCHASE_ORDER_DATE){
      changedValues.purchase_order_date = moment(fields.purchase_order_date,finalDateFormat).format("YYYY-MM-DD HH:mm:ss");
    }
    if(fields?.purchase_order_number && fields.purchase_order_number != cIEditDetails.PURCHASE_ORDER_NUMBER){
      changedValues.purchase_order_number = fields.purchase_order_number;
    }
    if(fields?.invoice_number && fields.invoice_number != cIEditDetails.INVOICE_NUMBER){
      changedValues.invoice_number = fields.invoice_number;
    }
    if(fields?.general_ledger_account && fields.general_ledger_account != cIEditDetails.GENERAL_LEDGER_ACCOUNT){
      changedValues.general_ledger_account = fields.general_ledger_account;
    }
    if(fields?.net_book_value && fields.net_book_value != cIEditDetails.NET_BOOK_VALUE){
      changedValues.net_book_value = fields.net_book_value;
    }
    if(fields?.depreciation_charged_name && fields.depreciation_charged_name != cIEditDetails.DEPRECIATION_CHARGED_NAME){
      changedValues.depreciation_charged_name = fields.depreciation_charged_name;
    }
    if(fields?.depreciation_schedule && fields.depreciation_schedule != cIEditDetails.DEPRECIATION_SCHEDULE){
      changedValues.depreciation_schedule = fields.depreciation_schedule;
    }
    if(fields?.vendor_seller_id != cIEditDetails.VENDOR_SELLER_ID){
      changedValues.vendor_seller_id = fields.vendor_seller_id;
      changedValues.vendor_seller = fields.vendor_seller;
    }
    if(fields?.currency_id && fields.currency_id != cIEditDetails.CURRENCY_ID){
      changedValues.currency_id = fields.currency_id;
    }
    if(fields?.charge_category_id != cIEditDetails.CHARGE_CATEGORY_ID){
      changedValues.charge_category_id = fields.charge_category_id;
      changedValues.charge_category_name = fields.charge_category_name;
    }
    if(fields?.charge_start_date && fields?.charge_start_date != cIEditDetails.CHARGE_START_DATE){
      changedValues.charge_start_date = moment(fields.charge_start_date,finalDateFormat).format("YYYY-MM-DD HH:mm:ss");
    }
    if(fields?.charge_end_date && fields?.charge_end_date != cIEditDetails.CHARGE_END_DATE){
      changedValues.charge_end_date = moment(fields.charge_end_date,finalDateFormat).format("YYYY-MM-DD HH:mm:ss");
    }
    if(fields?.charge_type_name != cIEditDetails.CHARGE_TYPE_NAME){
      changedValues.charge_type_name = fields.charge_type_name;
      changedValues.charge_type_id = fields.charge_type_id;
    }
    if(fields?.charge_frequency && fields?.charge_frequency != cIEditDetails.CHARGE_FREQUENCY){
      changedValues.charge_frequency = fields.charge_frequency;
    }
    if(fields?.charge_currency && fields?.charge_currency != cIEditDetails.CHARGE_CURRENCY){
      changedValues.charge_currency = fields.charge_currency;
    }
    if(fields?.charge_to_cc_id != cIEditDetails.CHARGE_TO_CC_ID){
      changedValues.charge_to_cc_id = fields.charge_to_cc_id;
    }
    if(fields?.charge_to_company_id != cIEditDetails.CHARGE_TO_COMPANY_ID){
      changedValues.charge_to_company_id = fields.charge_to_company_id;
      changedValues.charge_to_company_name = fields.charge_to_company_name;
    }
    if(fields?.charge_to_project_name && fields.charge_to_project_name != cIEditDetails.CHARGE_TO_PROJECT_NAME){
      changedValues.charge_to_project_name = fields.charge_to_project_name;
    }
    if(fields?.actual_retun_date && fields?.actual_retun_date != cIEditDetails.ACTUAL_RETUN_DATE){
      changedValues.actual_retun_date = moment(fields.actual_retun_date,finalDateFormat).format("YYYY-MM-DD HH:mm:ss");
    }
    if(fields?.scheduled_return_date && fields.scheduled_return_date != cIEditDetails.SCHEDULED_RETURN_DATE){
      changedValues.scheduled_return_date = moment(fields.scheduled_return_date,finalDateFormat).format("YYYY-MM-DD HH:mm:ss");
    }
    if(fields?.vendor_id != cIEditDetails.VENDOR_ID){
      changedValues.vendor_id = fields.vendor_id;
    }
    if(fields?.host_name && fields.host_name != cIEditDetails.HOST_NAME){
      changedValues.host_name = fields.host_name;
    }
    if(fields?.ip_address && fields.ip_address != cIEditDetails.IP_ADDRESS){
      changedValues.ip_address = fields.ip_address;
    }
    if(fields?.mac_address && fields.mac_address != cIEditDetails.MAC_ADDRESS){
      changedValues.mac_address = fields.mac_address; 
    }
    if(fields?.backup_retention && fields.backup_retention != cIEditDetails.BACKUP_RETENTION){
      changedValues.backup_retention = fields.backup_retention;
    }
    if(fields?.backup_schedule && fields.backup_schedule != cIEditDetails.BACKUP_SCHEDULE){
      changedValues.backup_schedule = fields.backup_schedule;
    }
    if(fields?.monitored_id != cIEditDetails.MONITORED_ID){
      changedValues.monitored_id = fields.monitored_id;
      changedValues.monitored = fields.monitored;
    }
    if(fields?.domain_name && fields.domain_name != cIEditDetails.DOMAIN_NAME){
      changedValues.domain_name = fields.domain_name;
    }
    if(fields?.allocated_capacity && fields.allocated_capacity != cIEditDetails.ALLOCATED_CAPACITY){
      changedValues.allocated_capacity = fields.allocated_capacity;
    }
    if(fields?.maximum_capacity && fields.maximum_capacity != cIEditDetails.MAXIMUM_CAPACITY){
      changedValues.maximum_capacity = fields.maximum_capacity;
    }
    if(fields?.capacity_unit && fields.capacity_unit != cIEditDetails.CAPACITY_UNIT){
      changedValues.capacity_unit = fields.capacity_unit;
    }
    if(fields?.capacity_utilization && fields.capacity_utilization != cIEditDetails.CAPACITY_UTILIZATION){
      changedValues.capacity_utilization = fields.capacity_utilization;
    }
    if(fields?.capacity_thresholds && fields.capacity_thresholds != cIEditDetails.CAPACITY_THRESHOLDS){
      changedValues.capacity_thresholds = fields.capacity_thresholds;
    }
    if(fields?.availaibility_percentage && fields.availaibility_percentage != cIEditDetails.AVAILAIBILITY_PERCENTAGE){
      changedValues.availaibility_percentage = fields.availaibility_percentage;
    }
    if(fields?.disaster_recovery_enabled && fields.disaster_recovery_enabled != cIEditDetails.DISASTER_RECOVERY_ENABLED){
      changedValues.disaster_recovery_enabled = fields.disaster_recovery_enabled;
    }
    if(fields?.disaster_recovery_location_id != cIEditDetails.DISASTER_RECOVERY_LOCATION_ID){
      changedValues.disaster_recovery_location_id = fields.disaster_recovery_location_id;
      changedValues.disaster_recovery_location_name = fields.disaster_recovery_location_name;
    }
    if(fields?.disaster_tier_id != cIEditDetails.DISASTER_TIER_ID){
      changedValues.disaster_tier_id = fields.disaster_tier_id;
      changedValues.disaster_tier_name = fields.disaster_tier_name;
    }
    if(fields?.recovery_point_objective && fields.recovery_point_objective != cIEditDetails.RECOVERY_POINT_OBJECTIVE){
      changedValues.recovery_point_objective = fields.recovery_point_objective;
    }
    if(fields?.recovery_time_objective && fields.recovery_time_objective != cIEditDetails.RECOVERY_TIME_OBJECTIVE){
      changedValues.recovery_time_objective = fields.recovery_time_objective;
    }
    if(fields?.discovery_source_id != cIEditDetails.DISCOVERY_SOURCE_ID){
      changedValues.discovery_source_id = fields.discovery_source_id;
      changedValues.discovery_source_name = fields.discovery_source_name;
    }
    if(fields?.first_discovered_at && fields.first_discovered_at != cIEditDetails.FIRST_DISCOVERED_AT){
      changedValues.first_discovered_at = moment(fields.first_discovered_at,finalDateFormat).format("YYYY-MM-DD HH:mm:ss");
    }
    if(fields?.most_recent_discovery_at && fields.most_recent_discovery_at != cIEditDetails.MOST_RECENT_DISCOVERY_AT){
      changedValues.most_recent_discovery_at = fields.most_recent_discovery_at;
    }
    if(fields?.patch_hold && fields.patch_hold != cIEditDetails.PATCH_HOLD){
      changedValues.patch_hold = fields.patch_hold;
    }
    if(fields?.patch_hold_reason && fields.patch_hold_reason != cIEditDetails.PATCH_HOLD_REASON){
      changedValues.patch_hold_reason = fields.patch_hold_reason;
    }
    if(fields?.patch_schedule && fields.patch_schedule != cIEditDetails.PATCH_SCHEDULE){
      changedValues.patch_schedule = fields.patch_schedule;
    }
    if(fields?.patch_level && fields.patch_level != cIEditDetails.PATCH_LEVEL){
      changedValues.patch_level = fields.patch_level;
    }
    if(Object.keys(changedValues).length === 0){
      swal({
        text: translator["There is no change on the form"],
        button: translator["OK"],
      });
      return;
    }
    changedValues.updated_by = full_name;
    changedValues.updated_at = moment().format(dateformat.slice(0, 10)?.toUpperCase() + " HH:mm:ss");
    changedValues.ci_id = cIEditDetails.CI_ID;
    changedValues.ci_name = cIEditDetails.CI_NAME;
    changedValues.company_id = cIEditDetails.COMPANY_ID;
    changedValues.company_name = cIEditDetails.COMPANY_NAME;
    if(!changedValues.serial_number){
      changedValues.serial_number = cIEditDetails.SERIAL_NUMBER;
    }
    try {
      const response = await axios.patch(
        `${GLOBAL.patchCiDataURL}/${ciId}`,
        changedValues
      );
      const responseData = response.data[0];

      if (
        responseData?.upsert_status === false ||
        responseData?.upsert_status === "false"
      ) {
        Swal.fire(responseData.message);
      } else {
        dispatch(loadCIEditDetails(responseData.CI_ID));
        dispatch(loadModelName(responseData.CI_ID));
        dispatch(getTimelineData(responseData.CI_ID));
        // ref.props.changeActionView("specifyAttributeValues");
      }
    } catch (error) {
      console.error(error?.response?.data?.[0]?.message || error.message);
      // Optionally, handle specific error scenarios or set form errors
    } finally {
      setSubmitting(false);
    }
  };

  const parseIfJson = (input) => {
    try {
      return typeof input === "string" ? JSON.parse(input) : input;
    } catch (e) {
      return input;
    }
  };
  // useEffect(() => {
  //   if(!isAddMode && ciId) {
  //     dispatch(loadCIEditDetails(ciId));
  //   }
  // },[ciId, dispatch]);

  useEffect(() => {
    if (!isAddMode && ciId && cIEditDetails) {
      const validateAssignedUser =  cIEditDetails.ASSIGNED_USER === null || 
                                    (typeof cIEditDetails.ASSIGNED_USER === 'object' && Object.keys(cIEditDetails.ASSIGNED_USER).length === 0 && !Array.isArray(cIEditDetails.ASSIGNED_USER)) ||
                                    (Array.isArray(cIEditDetails.ASSIGNED_USER) && cIEditDetails.ASSIGNED_USER.length === 0) ||
                                    (typeof cIEditDetails.ASSIGNED_USER === 'string' && cIEditDetails.ASSIGNED_USER.includes("[]")); 
      const intermediateAssignedUser = validateAssignedUser ? [] : typeof cIEditDetails.ASSIGNED_USER === 'string' ? JSON.parse(cIEditDetails.ASSIGNED_USER) : cIEditDetails.ASSIGNED_USER;
      const extractedUser = intermediateAssignedUser.length > 0 ? 
            intermediateAssignedUser.map(user => {
                return {
                        userId: user.userId ? user.userId : user.assigned_user_id,
                        fullname: user.fullname ? user.fullname : user.assigned_user_name,
                        email: user.email ? user.email : user.assigned_user_email,
                      }
            }) : [];
      const updatedInitVals = {
        ci_name: cIEditDetails.CI_NAME,
        company_id: cIEditDetails.COMPANY_ID,
        company_name: cIEditDetails.COMPANY_NAME,
        class_id: cIEditDetails.CLASS_ID,
        class_name: cIEditDetails.CLASS_NAME,
        type: cIEditDetails.TYPE,
        category_id: cIEditDetails?.CATEGORY_ID || "0",
        category_name: cIEditDetails.CATEGORY_NAME,
        sub_category_id: cIEditDetails.SUB_CATEGORY_ID || 0,
        sub_category_name: cIEditDetails.SUB_CATEGORY_NAME,
        ci_id: cIEditDetails.CI_ID,
        asset_tag: cIEditDetails.ASSET_TAG,
        serial_number: cIEditDetails.SERIAL_NUMBER,
        status: cIEditDetails.STATUS,
        status_name: cIEditDetails.STATUS_NAME,
        sub_status: cIEditDetails.SUB_STATUS,
        sub_status_name: cIEditDetails.SUB_STATUS_NAME,
        description: cIEditDetails.DESCRIPTION,
        manufacturer: cIEditDetails.MANUFACTURER,
        model_name: cIEditDetails.MODEL_NAME,
        model_number: cIEditDetails?.MODEL_NUMBER || "",
        metallic: cIEditDetails.METALLIC,
        compliance: parseIfJson(cIEditDetails?.COMPLIANCE) || null,
        location_id: cIEditDetails.LOCATION_ID || "0",
        location_name: cIEditDetails.LOCATION_NAME,
        environment: cIEditDetails.ENVIRONMENT,
        keyword: cIEditDetails.KEYWORD,
        is_external: String(cIEditDetails.IS_EXTERNAL),
        dataset: cIEditDetails.DATASET,
        business_owner_id: cIEditDetails.BUSINESS_OWNER_ID,
        business_owner_name: cIEditDetails.BUSINESS_OWNER_NAME,
        technical_owner_id: cIEditDetails.TECHNICAL_OWNER_ID,
        technical_owner_name: cIEditDetails.TECHNICAL_OWNER_NAME,
        owner_id: cIEditDetails.OWNER_ID,
        owner_name: cIEditDetails.OWNER_NAME,
        department_id: cIEditDetails.DEPARTMENT_ID,
        department_name: cIEditDetails.DEPARTMENT_NAME,
        change_group_id: cIEditDetails.CHANGE_GROUP_ID,
        change_group_name: cIEditDetails.CHANGE_GROUP_NAME,
        availaibility_percentage: cIEditDetails.AVAILAIBILITY_PERCENTAGE,
        support_company_id: cIEditDetails.SUPPORT_COMPANY_ID,
        support_company_name: cIEditDetails.SUPPORT_COMPANY_NAME,
        group_id: cIEditDetails.GROUP_ID === 0 ? "0" : cIEditDetails.GROUP_ID,
        group_name: cIEditDetails?.GROUP_NAME || "",
        owned_by_id: cIEditDetails.OWNED_BY_ID,
        owned_by: cIEditDetails.OWNED_BY,
        ownership_type_id: cIEditDetails.OWNERSHIP_TYPE_ID,
        ownership_type: cIEditDetails.OWNERSHIP_TYPE,
        // order_date: cIEditDetails.ORDER_DATE,
        acquisition_cost: cIEditDetails.ACQUISITION_COST,
        cost_center_id: cIEditDetails.COST_CENTER_ID,
        cost_center: cIEditDetails.COST_CENTER,
        purchase_order_number:
          cIEditDetails.PURCHASE_ORDER_NUMBER === "0"
            ? ""
            : cIEditDetails.PURCHASE_ORDER_NUMBER,
        invoice_number: cIEditDetails.INVOICE_NUMBER,
        cost_currency_id:
          cIEditDetails?.COST_CURRENCY_ID == "0"
            ? ""
            : cIEditDetails?.COST_CURRENCY_ID,
        general_ledger_account: cIEditDetails.GENERAL_LEDGER_ACCOUNT,
        net_book_value: cIEditDetails.NET_BOOK_VALUE,
        depreciation_charged_id:
          cIEditDetails.DEPRECIATION_CHARGED_ID == "0"
            ? ""
            : cIEditDetails.DEPRECIATION_CHARGED_ID,
        depreciation_charged_name: cIEditDetails.DEPRECIATION_CHARGED_NAME,
        depreciation_schedule: cIEditDetails.DEPRECIATION_SCHEDULE,
        vendor_id: cIEditDetails.VENDOR_ID,
        vendor: cIEditDetails.VENDOR,
        currency_id: cIEditDetails?.CURRENCY_ID || "0",
        charge_category_id: cIEditDetails.CHARGE_CATEGORY_ID,
        charge_type_id: cIEditDetails.CHARGE_TYPE_ID,
        charge_frequency: cIEditDetails?.CHARGE_FREQUENCY || "",
        charge_to_cc_id: cIEditDetails.CHARGE_TO_CC_ID,
        charge_to_cc_name: cIEditDetails.CHARGE_TO_CC_NAME,
        charge_to_company_id: cIEditDetails.CHARGE_TO_COMPANY_ID || "0",
        charge_to_company_name: cIEditDetails.CHARGE_TO_COMPANY_NAME,
        charge_to_project_id: cIEditDetails.CHARGE_TO_PROJECT_ID || "0",
        charge_to_project_name: cIEditDetails.CHARGE_TO_PROJECT_NAME,
        vendor_seller_id: cIEditDetails.VENDOR_SELLER_ID || "0",
        vendor_seller: cIEditDetails.VENDOR_SELLER,
        host_name: cIEditDetails.HOST_NAME,
        ip_address: cIEditDetails.IP_ADDRESS,
        mac_address: cIEditDetails.MAC_ADDRESS,
        backup_retention: cIEditDetails.BACKUP_RETENTION,
        monitored: cIEditDetails.MONITORED,
        monitored_id: cIEditDetails?.MONITORED_ID || "0",
        domain_name: cIEditDetails.DOMAIN_NAME,
        allocated_capacity: cIEditDetails.ALLOCATED_CAPACITY,
        maximum_capacity: cIEditDetails.MAXIMUM_CAPACITY,
        capacity_unit: cIEditDetails.CAPACITY_UNIT,
        capacity_utilization: cIEditDetails.CAPACITY_UTILIZATION,
        capacity_thresholds: cIEditDetails.CAPACITY_THRESHOLDS,
        disaster_recovery_enabled: cIEditDetails.DISASTER_RECOVERY_ENABLED,
        disaster_recovery_location_id:
          cIEditDetails.DISASTER_RECOVERY_LOCATION_ID,
        disaster_recovery_location_name:
          cIEditDetails.DISASTER_RECOVERY_LOCATION_NAME,
        disaster_tier_id: cIEditDetails.DISASTER_TIER_ID || "0",
        recovery_point_objective: cIEditDetails.RECOVERY_POINT_OBJECTIVE,
        recovery_time_objective: cIEditDetails.RECOVERY_TIME_OBJECTIVE,
        discovery_source_name: cIEditDetails.DISCOVERY_SOURCE_NAME,
        patch_hold: cIEditDetails.PATCH_HOLD,
        patch_hold_reason: cIEditDetails.PATCH_HOLD_REASON,
        patch_schedule: cIEditDetails.PATCH_SCHEDULE,
        patch_level: cIEditDetails.PATCH_LEVEL,
        charge_category_name: cIEditDetails.CHARGE_CATEGORY_NAME,
        charge_currency: cIEditDetails.CHARGE_CURRENCY,
        charge_currency_id: cIEditDetails.CHARGE_CURRENCY_ID || "0",
        charge_type_name: cIEditDetails.CHARGE_TYPE_NAME,
        cost_currency: cIEditDetails.COST_CURRENCY,
        created_by: cIEditDetails.CREATED_BY,
        dataset_name: cIEditDetails?.DATASET_NAME,
        disaster_tier_name: cIEditDetails.DISASTER_TIER_NAME,
        discovery_source_id: cIEditDetails.DISCOVERY_SOURCE_ID || "0",
        domain_name: cIEditDetails.DOMAIN_NAME,
        environment_name: cIEditDetails?.ENVIRONMENT_NAME || "",
        manufacturer_id: cIEditDetails.MANUFACTURER_ID || "0",
        metallic_name: cIEditDetails?.METALLIC_NAME || "",
        model_id: cIEditDetails.MODEL_ID || "0",
        owner_email: cIEditDetails.OWNER_EMAIL,
        // sub_status_name: cIEditDetails.SUB_STATUS_NAME,
        technical_owner_email: cIEditDetails.TECHNICAL_OWNER_EMAIL,
        type_name: cIEditDetails.TYPE_NAME,
        business_owner_email: cIEditDetails.BUSINESS_OWNER_EMAIL,
        // warranty_expiration_date: cIEditDetails.WARRANTY_EXPIRATION_DATE,
        // charge_start_date: cIEditDetails.CHARGE_START_DATE,
        // scheduled_return_date: cIEditDetails.SCHEDULED_RETURN_DATE,
        first_discovered_at: cIEditDetails.FIRST_DISCOVERED_AT,
        allocation_date: cIEditDetails.ALLOCATION_DATE,
        updated_by: cIEditDetails.UPDATED_BY || "",
        updated_at: cIEditDetails.UPDATED_AT,
        upsert_userid: cIEditDetails.UPSERT_USERID,
        decommission_date: cIEditDetails?.DECOMMISSION_DATE,
        deployement_date: cIEditDetails?.DEPLOYEMENT_DATE,
        // support_end_date:cIEditDetails?.SUPPORT_END_DATE,
        first_deployed_date: cIEditDetails?.FIRST_DEPLOYED_DATE,
        installation_date: cIEditDetails?.INSTALLATION_DATE,
        order_date: cIEditDetails?.ORDER_DATE,
        order_received_date: cIEditDetails?.ORDER_RECEIVED_DATE,
        purchase_order_date: cIEditDetails?.PURCHASE_ORDER_DATE,
        scheduled_return_date: cIEditDetails?.SCHEDULED_RETURN_DATE,
        support_end_date: cIEditDetails?.SUPPORT_END_DATE,
        warranty_expiration_date: cIEditDetails?.WARRANTY_EXPIRATION_DATE,
        charge_start_date: cIEditDetails?.CHARGE_START_DATE,
        charge_end_date: cIEditDetails?.CHARGE_END_DATE,
        backup_schedule: cIEditDetails?.BACKUP_SCHEDULE,
        actual_retun_date: cIEditDetails?.ACTUAL_RETUN_DATE,
        most_recent_discovery_at: cIEditDetails?.MOST_RECENT_DISCOVERY_AT,
        assigned_date: cIEditDetails?.ASSIGNED_DATE,
        assigned_user: extractedUser,

        business_owner_group_id: cIEditDetails?.BUSINESS_OWNER_GROUP_ID,
        business_owner_group_name: cIEditDetails?.BUSINESS_OWNER_GROUP_NAME,

        technical_owner_group_id: cIEditDetails?.TECHNICAL_OWNER_GROUP_ID,
        technical_owner_group_name: cIEditDetails?.TECHNICAL_OWNER_GROUP_NAME,

        cab_group_id: cIEditDetails?.CAB_GROUP_ID,
        cab_group_name: cIEditDetails?.CAB_GROUP_NAME,
        project: cIEditDetails?.PROJECT,
      };

      setInitVals((prev) => {
        return {
          ...prev,
          ...updatedInitVals,
        };
      });
    }
  }, [ciId, cIEditDetails]);

  return (
    <Container fluid>
      <Row className="margin-b-15">
        <Col xs={12} md={12}>
          <Formik
            innerRef={props?.formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={onSubmit}
          >
            {({
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleBlur,
              values,
              dirty,
              resetForm,
              handleChange,
              isValid,
              submitCount,
            }) => {
              return (
                <Form noValidate className="row-eq-height">
                  <AddEditCmdb
                    setFieldValue={setFieldValue}
                    values={values}
                    isValid={isValid}
                    submitCount={submitCount}
                    errors={errors}
                    touched={touched}
                    isSubmitting={isSubmitting}
                    cIEditDetails={cIEditDetails}
                    panelResize={props.panelResize}
                  />
                </Form>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};

export default CmdbEditIndex;
