
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { reduxForm, SubmissionError } from "redux-form";
import { ImSpinner6 } from "react-icons/im";
import { IoSaveOutline, IoClose } from "react-icons/io5";
import { HiPlus } from "react-icons/hi";
import AddLevelForm from "./addLevelForm";
import Swal from "sweetalert2";
import { loadOnCallSXMenulistData } from "../../../../actions/onCallSchedule/common/onCallCommonAction";
import { MyContext } from '_MyContext';
import { Link } from "react-router-dom";
let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const EscalationLevelHeader = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [disableBtn, setDisableBtn] = useState(false);
  const [viewForm, setViewForm] = useState(false);
  const [isPlusDisabled, setIsPlusDisabled] = useState(true);
  const escalationPlanLevel = useSelector((state) => state.escalationPlanLevel);
  const onCallCommonReducer = useSelector((state) => state.on_call_common_reducer);
  const escalationPlanDetail = useSelector((state) => state.escalationPlanDetail);
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
	}, []);

  const root = document.documentElement;
  viewForm
    ? root?.style.setProperty("--modalBackDrop", 0.5)
    : root?.style.setProperty("--modalBackDrop", 0);

  useEffect(() => {
    dispatch(loadOnCallSXMenulistData("AIOSEM", "EscalationLevel"));
  }, []);

  useEffect(()=>{
    if(escalationPlanLevel.length<onCallCommonReducer.escalationLevel.length){
      setIsPlusDisabled(false);
    }else{
      setIsPlusDisabled(true);
    }
  },[escalationPlanLevel,onCallCommonReducer])
  const submit = async (values) => {
    if (props.selectedCompany.length == 0) {
      props.setCompanyError("error");
      throw new SubmissionError({
        _error:
          "The form has missing required fields, please revalidate the highlighted fields and update the form",
      });
    } else {
      if(values.escalationStatus !== escalationPlanDetail.status){ 
      Swal.fire({
        title: "If an associated escalation rule already exists, please ensure to activate / deactivate it in Rules",
        width: 550,
        padding: '15px',
        showDenyButton: true,
        confirmButtonText: ['Proceed'],
        denyButtonText: tr['Cancel'],
        customClass: {
          title: "justifyText"  
       }
      }).then((result) => {
        if (result.isConfirmed) {
      setDisableBtn(true);
      let postJson = {
        companyId: props.selectedCompany[0].id,
        companyName: props.selectedCompany[0].label,
        status: values.escalationStatus,
        escalationPlan: values.plan,
        moduleName: values.escalationModule,
        escalationPlanName: values.plan,
      };
      axios
        .patch(
          "/rest/aiosem/updateEscalationPlanAction/" + params.planId,
          postJson
        )
        .then((resp) => {
          Swal.fire({ title: "Escalaton plan has been updated successfully!" });
          setDisableBtn(false);
        })
        .catch((error) => {
          Swal.fire({ title: error?.response?.data?.businessMessage || error?.response?.data?.messageCode });
          setDisableBtn(false);
        });
        } else if (result.isDenied) {
          return false;
        }
      })
    } else {
      setDisableBtn(true);
      let postJson = {
        companyId: props.selectedCompany[0].id,
        companyName: props.selectedCompany[0].label,
        status: values.escalationStatus,
        escalationPlan: values.plan,
        moduleName: values.escalationModule,
        escalationPlanName: values.plan,
      };
      axios
        .patch(
          "/rest/aiosem/updateEscalationPlanAction/" + params.planId,
          postJson
        )
        .then((resp) => {
          Swal.fire({ title: "Escalaton plan has been updated successfully!" });
          setDisableBtn(false);
        })
        .catch((error) => {
          Swal.fire({ title: error?.response?.data?.businessMessage || error?.response?.data?.messageCode });
          setDisableBtn(false);
        });
    }
    }
  };

  return (
    <Row className="margin-b-15">
      <Col lg={6} md={6} sm={12} xs={12}>
        <h2 className="sPageHeading1">{"Escalation Plan"}</h2>
      </Col>
      <Col lg={6} md={6} sm={12} xs={12}>
        <div className="paHedFilter">
          <Button
            bsPrefix=" "
            title={tr["Add Level"]}
            role="button"
            className="myBt plus fillBtn"
            onClick={() => {
              setViewForm(true);
            }}
            disabled={isPlusDisabled}
          >
            <HiPlus className="refreshFlip" />
          </Button>
          <Link
            bsPrefix=" "
            id="creTag"
            to="javascript:void(0)"
            className="myBt plus"
            disabled={props.submitting}
            onClick={props.handleSubmit(submit)}
            title={tr["Save"]}
            ref={saveObjRef}
          >
            {disableBtn ? (
              <ImSpinner6 className="icn-spinner" />
            ) : (
              <IoSaveOutline />
            )}
          </Link>
          <Link
            bsPrefix=" "
            to="/escalation"
            className="myBt cancel fillBtn"
            title={tr["Cancel"]}
            ref={closeButton}
          >
            <IoClose />
          </Link>
        </div>
      </Col>
      <Col md={12}>
        {viewForm && (
          <AddLevelForm
            formType={"add"}
            show={viewForm}
            planId={params?.palnId}
            setViewForm={setViewForm}
            selectedCompany={props.selectedCompany}
          />
        )}
      </Col>
    </Row>
  );
};

export default reduxForm({
  form: "EditEscalationForm",
  destroyOnUnmount: true,
  enableReinitialize: true,
})(EscalationLevelHeader);
