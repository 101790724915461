
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import ListGroup from "react-bootstrap/ListGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import Table from "react-bootstrap/Table";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import {IoClose} from "react-icons/io5";
import {
  useSaveActionItemAttachmentMutation,
  useGetAttachmentQuery,
  useDeleteActionAttachmentMutation,
} from "../../../../../reducers/governance/meeting/actionItem";
import CloseBtnRightPanel from "_Commons/closeBtnRightPanel";
function Index({ editActionData, rightEditPanel }) {
  const [saveAttachment] = useSaveActionItemAttachmentMutation();
  const [deleteAttachment] = useDeleteActionAttachmentMutation();
  const actionItemCode = editActionData[0]?.actionitemCode;
  const { data: attachmentData = [], refetch: refetchAttachment } =
    useGetAttachmentQuery(actionItemCode, {
      skip: !actionItemCode,
    });

  const deleteFileAction = (id) => {
    deleteAttachment({ data: { attachmentId: id } });
  };

  const [files, setFiles] = useState([]);
  const maxSize = 2097152;
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length) {
      setFiles(acceptedFiles);
    }
    if (rejectedFiles.length > 0) {
      Swal.fire(`File type not accepted.`);
      setFiles([]);
    }
    if (acceptedFiles[0].size > maxSize) {
      Swal.fire(`Please upload File up to 2 MB size.`);
      setFiles([]);
    }
  }, []);

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    acceptedFiles,
    rejectedFiles,
  } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
      "text/html": [".html", ".htm"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "text/csv": [".csv"],
      "text/plain": [".txt"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xslx",
      ],
      "application/zip": [".zip"],
      "application/vnd.rar": [".rar"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.ms-powerpoint": [".ppt"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [".pptx"],
    },
    minSize: 0,
    multiple: false,
    maxFiles: 1,
  });

  const handleClick = () => {
    if (files.length === 0) {
      Swal.fire("Please upload some files first");
    }

    const data = {
      actionitemCode: actionItemCode,
      fileName: files[0].name,
      mimeType: files[0].type,
      createdOn: undefined,
      createdBy: editActionData[0]?.createdBy,
      createdByName: editActionData[0]?.createdByName,
      modifiedOn: undefined,
      modifiedBy: editActionData[0]?.modifiedBy,
      modifiedByName: editActionData[0]?.modifiedByName,
      encodedString: "",
      status: "Y",
    };

    let actionCode = "";

    actionCode = actionItemCode;

    let formData = new FormData();
    formData.append("file", files[0]);
    formData.append("attachment", JSON.stringify(data));

    formData.append("actionCode", actionCode);

    axios
      .post("/action/api/saveAttachment", formData)
      .then((response) => {
        refetchAttachment();
        setFiles([]);
        Swal.fire("file uploaded successfully.");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const dropzoneStyle = {
    width: "100%",
    height: "60px",
    border: "1px solid #bdc4c9",
    marginTop: 5,
    textAlign: "center",
  };

  function renderAttachedItems(attachments) {
    if (_.isEmpty(attachments)) {
      return (
        <tr>
          <td colSpan={3} className="text-c">
            No File Found
          </td>
        </tr>
      );
    }
    return attachments.map((attachment) => {
      return (
        <tr bsClass="" key={attachment.attachmentId}>
          <td>
            <div>{attachment.fileName}</div>
            <div style={{ color: "rgb(0 0 0 / 50%)" }}>
              {attachment.createdByName}
            </div>
          </td>
          <td>{attachment.createdOn}</td>
          <td className="text-c">
            <a className="margin-r-10" href={attachment.url}>
              <i className="fa fa-eye"></i>
            </a>

            <i
              className="fa fa-trash"
              style={{ cursor: "pointer" }}
              onClick={() => {
                deleteFileAction(attachment.attachmentId);
              }}
            ></i>
          </td>
        </tr>
      );
    });
  }

  return (
    <div className="panel-body attachment-body">
      <div className="custom-container-fluid">
        <div className="stickyArea rBoxStyle">
        <CloseBtnRightPanel showRightSide={rightEditPanel} />
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">Provide Attachments</div>
          </div>
          <div className="rBoxGap">
              <div>
              {editActionData?.[0]?.statusId != 4 && editActionData?.[0]?.statusId != 5 && editActionData?.[0]?.statusId != 8 ? (<>
                <div>
                  <b>Upload your file.</b> (max size 2 MB)
                </div>
                <div {...getRootProps()} style={dropzoneStyle}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <>
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                      <em style={{ fontSize: "12px" }}>
                        (Only csv, doc, docx, gif, ics, jpeg, log, msg, pdf,
                        png, ppt, pptx, txt, xls, xlsx, zip will be accepted)
                      </em>
                    </>
                  )}
                </div>
                <div className="margin-t-20">
                  <div className="padding-b-5 margin-b-0 black f-size-16 fw-400">
                    {files.length > 0 ? "file attached" : "No file attached"}
                  </div>

                  {files.length > 0 && (
                    <ListGroup className="attachment-break">
                      <ListGroup.Item>
                        <span className="float-r"
                            onClick={() => {
                              setFiles([]);
                            }}
                          ><IoClose />
                        </span>
                        {files?.map((elm) => elm.name).toString()}
                      </ListGroup.Item>
                    </ListGroup>
                  )}
                </div>

                <ButtonToolbar className="black margin-t-5">
                  {/* <i className="f-size-13 fa fa-spinner fa-spin"></i> */}
                  <Button
                    className="rgSidrkBtn smallBtn"
                    bsSize="small"
                    bsStyle="primary"
                    onClick={() => handleClick()}
                  >
                    Attach
                  </Button>
                </ButtonToolbar>
                </>) : null}
                <div>
                  <div className="border-b padding-b-5 margin-b-0 black f-size-16 fw-400">
                    Attached Files
                  </div>
                  <Table
                    responsive
                    striped
                    bordered
                    condensed
                    hover
                    className="f-size-12 wrpAttTab"
                  >
                    <thead>
                      <tr>
                        <th width="60%">Name </th>
                        <th width="25%">Date & Time</th>
                        <th width="15%" className="text-c">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>{renderAttachedItems(attachmentData)}</tbody>
                  </Table>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
