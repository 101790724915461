/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React from "react";
import {
  Row,
  Col,
  Form,
  Alert,
  ButtonToolbar,
  Button,
  Table,
} from "react-bootstrap";
import {
  _textArea,
  _dropDown,
  _inputField,
  _number,
  _radio,
  _multiSelect,
  _ckeditor,
  TypeaheadandDropdown,
  _checkbox,
} from "../../common/formFields";
import {
  Field,
  reduxForm,
  SubmissionError,
  change,
  getFormValues,
} from "redux-form";
import { connect } from "react-redux";
import axios from "axios";
import swal from "sweetalert";
import {
  spcmLoader,
  setLeftEditId,
  getServiceDetailCounts,
  getServiceOrderChoices,
  getServiceMyQuestionLists,
  getConsumptionItems,
  getQuestionsCategory,
  getQuestionsActiveCategory,
  getDataSourceTypes,
  getReadOnlyResponseKeyTypesAndKeys,
  resetReducer,
  getCustomDataSources,
} from "../../../actions/spcmActions";
import { ImSpinner6 } from "react-icons/im";
import { itemQuantityField } from "../../../components/common/formFields";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { HiPlus, HiOutlineTrash } from "react-icons/hi";

const validate = (values) => {
  let errors = {};
  if (!values.category_name) {
    errors.category_name = "Please select group";
  }

  if (!values.parent_response_id) {
    errors.parent_response_id = "Select Parent Value";
  }
  if (!values.question_parent_id) {
    errors.question_parent_id = "Select Parent Question";
  }
  if (!values.question_entityid) {
    errors.question_entityid = "Select Component Name";
  }

  if (!values.question_sequence) {
    errors.question_sequence = "Select Question Sequence";
  }
  if (!values.question_text || values.question_text.trim().length === 0) {
    errors.question_text = "Enter Question Text";
  }
  if (!values.response_type) {
    errors.response_type = "Select Response Type";
  }

  if (!values.call_api_after) {
    errors.call_api_after = "Enter min. character limit";
  }

  if (!values.response_mode) {
    errors.response_mode = "Select Response Mode";
  }
  if (!values.response_value || values.response_value.trim().length === 0) {
    errors.response_value = "Enter Response Value";
  }
  if (!values.placeholder || values.placeholder.trim().length === 0) {
    errors.placeholder = "Enter Response Type";
  }
  if (!values.datasource_type) {
    errors.datasource_type = "Enter Response Type";
  }

  if (!values.max_limit) {
    errors.max_limit = "  ";
  }

  if (!values.table_headers || values.table_headers.trim().length === 0) {
    errors.table_headers = "Enter Table Headers";
  } else if (values.max_limit) {
    let reg = /^\d+$/;
    let result = reg.test(values.max_limit);
    if (result == false) {
      errors.max_limit = "Enter numbers only";
    }
    if (values.max_limit == 0) {
      errors.max_limit = "Enter valid number only";
    }
  }

  if (parseInt(values.min_value, 10) >= parseInt(values.max_value, 10)) {
    errors.min_value = "Min Value must be less than Max Value";
  }

  if (parseInt(values.min_value, 10) <= 0) {
    errors.min_value = "Please enter value greater than 1";
  }

  if (parseInt(values.max_value, 10) <= 0) {
    errors.max_value = "Please enter value greater than 1";
  }

  if (parseInt(values.min_value, 10) >= 99999999) {
    errors.min_value = "Please enter value less than 99999999";
  }
  if (parseInt(values.max_value, 10) <= 1) {
    errors.max_value = "Please enter value greater than 1";
  }

  if (!values.CATEGORY_NAME || values.CATEGORY_NAME.trim().length === 0) {
    errors.CATEGORY_NAME = "Please enter group name";
  }

  if (!values.CATEGORY_SEQUENCE) {
    errors.CATEGORY_SEQUENCE = "Please enter group sequence";
  }

  if (
    values.call_api_after_typeahead < 2 ||
    values.call_api_after_typeahead > 15
  ) {
    errors.call_api_after_typeahead = "Enter min. character limit";
  }

  if (!values.call_api_after_typeahead) {
    errors.call_api_after_typeahead = "Enter min. character limit";
  }

  if (!values.days_before && values.days_before !== 0) {
    errors.days_before = "Enter min. character limit";
  }

  if (!values.days_after && values.days_after !== 0) {
    errors.days_after = "Enter min. character limit";
  }

  if (!values.data_source_identifier && values.data_source_identifier !== 0) {
    errors.data_source_identifier = "Enter data source identifier";
  }

  if (
    !values.datasource_search_attributes &&
    values.datasource_search_attributes !== 0
  ) {
    errors.datasource_search_attributes = "Select data source identifier";
  }

  if (!values.datasource_label_key && values.datasource_label_key !== 0) {
    errors.datasource_label_key = "Select data source label";
  }
  return errors;
};

class ComponentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showResponseMode: false,
      DataSourceTypeOpt: "Unset",
      DataSourceOpt: false,
      showMaxLimit: false,
      AssociatedToDropdown: "",
      showAssociatedSelected: false,
      showHideAssociatedSelected: true,
      associatedSelectedValues: 0,
      questionTypeSelected: "No",
      categoryNameInitialStatus: "Active",
      isMandatory: "No",
      parentQuesCatName: "",
      parentQuesCatId: "",
      showStatic: "",
      questionSelectedType: "",
      parentOptional: false,
      selectedComponentsId: "",
      showDisplayTextField: false,
      showTableHeaders: false,
      isTableTypeQuestion: false,
      dynamicDropSelected: false,
      dynamicTextboxSelected: false,
      showDataSourceOption: false,
      showMinMaxLimit: false,
      minItemQuantity: "1",
      maxItemQuantity: "99999999",
      calendarSelected: false,
      calendar_type_id: "10",
      calendar_type_value: "None",
      datasource_type_name: "",
      parent_response_text: "",
      ItamLocationSelected: false,
      searchInvokeField: false,
      selectedValueList: [],
      days_before: "",
      days_after: "",
      include_in_summary: "false",
      show_include_in_summary: false,
      show_data_source_identifier: false,
      showMultiSelectAssociated: false,
      errorMultiValue: "",
      selectedMultiValueList: [],
      errorDSFilterAttr: "",
      selectedDSFilterAttr: [],
      multiSelectValuesUpdated: false,
      customDataSourceId: "",
      criteriaValues: [],
      criteriaErrors: [],
    };
    this.onChange = this.onChange.bind(this);
    this.onResponseModeChange = this.onResponseModeChange.bind(this);
    this.onDataSourceOptChng = this.onDataSourceOptChng.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onAssociatedToChange = this.onAssociatedToChange.bind(this);
    this.onAssociatedSelected = this.onAssociatedSelected.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onComponentChange = this.onComponentChange.bind(this);
    this.onIsMandatoryChange = this.onIsMandatoryChange.bind(this);
    this.countCat = this.countCat.bind(this);
    this.validateTableHeaders = this.validateTableHeaders.bind(this);
    this.onRestrictDateChange = this.onRestrictDateChange.bind(this);
    this.onValueSelection = this.onValueSelection.bind(this);
    this.onDSFilterValueSelection = this.onDSFilterValueSelection.bind(this);
    this.getValuesDropdownOptions = this.getValuesDropdownOptions.bind(this);
    this.onDaysAfterCurrentDateChange =
      this.onDaysAfterCurrentDateChange.bind(this);
    this.onDaysBeforeCurrentDateChange =
      this.onDaysBeforeCurrentDateChange.bind(this);
    this.setIncudeInSummary = this.setIncudeInSummary.bind(this);
    this.onMultiValueSelection = this.onMultiValueSelection.bind(this);
    this.addField = this.addField.bind(this);
    this.removeField = this.removeField.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  setIncudeInSummary(value) {
    this.setState({
      include_in_summary:
        this.state.include_in_summary === "false" ? "true" : "false",
    });
  }

  onDaysBeforeCurrentDateChange(e) {
    this.setState({ days_before: e.target.value });
  }

  onDaysAfterCurrentDateChange(e) {
    this.setState({ days_after: e.target.value });
  }

  onChange(e, changeFunction) {
    const { change } = this.props;
    change("response_mode", "");
    change("response_value", "");
    change("datasource_type", "");
    change("datasource_type_uuid", "");
    change("datasource_search_attributes", "");
    change("datasource_label_key", "");
    change("data_source_identifier", "");
    if (
      e.target.value == "Dropdown List" ||
      e.target.value == "Multiselect List" ||
      e.target.value == "Checkbox" ||
      e.target.value == "Radio button"
    )
      this.setState({
        showMinMaxLimit: false,
        showResponseMode: true,
        showMaxLimit: false,
        showStatic: true,
        DataSourceTypeOpt: "Unset",
        DataSourceOpt: false,
        showDisplayTextField: false,
        isTableTypeQuestion: false,
        showTableHeaders: false,
        dynamicDropSelected: false,
        dynamicTextboxSelected: false,
        calendarSelected: false,
        searchInvokeField: false,
        show_include_in_summary: true,
        show_data_source_identifier: false,
      });
    else if (e.target.value == "Typeahead") {
      change("datasource_type", "");
      change("ro_responses", "");
      change("call_api_after_typeahead", "4");
      this.setState({
        showMinMaxLimit: false,
        showResponseMode: true,
        showMaxLimit: false,
        showStatic: false,
        DataSourceTypeOpt: "Predefined",
        showDisplayTextField: false,
        isTableTypeQuestion: false,
        showTableHeaders: false,
        dynamicDropSelected: false,
        dynamicTextboxSelected: false,
        calendarSelected: false,
        searchInvokeField: true,
        show_include_in_summary: true,
        show_data_source_identifier: false,
      });
    } else if (e.target.value == "multiSelectTypeahead") {
      change("datasource_type", "");
      change("ro_responses", "");
      change("call_api_after_typeahead", "4");
      this.setState({
        showMinMaxLimit: false,
        showResponseMode: true,
        showMaxLimit: false,
        showStatic: false,
        DataSourceTypeOpt: "Predefined",
        showDisplayTextField: false,
        isTableTypeQuestion: false,
        showTableHeaders: false,
        dynamicDropSelected: false,
        dynamicTextboxSelected: false,
        calendarSelected: false,
        searchInvokeField: true,
        show_include_in_summary: true,
        show_data_source_identifier: true,
      });
    } else if (e.target.value == "Dynamic Dropdown") {
      change("datasource_type", "");
      change("ro_responses", "");
      this.setState({
        showMinMaxLimit: false,
        showResponseMode: true,
        showMaxLimit: false,
        showStatic: false,
        DataSourceTypeOpt: "Predefined",
        showDisplayTextField: false,
        isTableTypeQuestion: false,
        showTableHeaders: false,
        dynamicDropSelected: true,
        dynamicTextboxSelected: false,
        calendarSelected: false,
        searchInvokeField: false,
        show_include_in_summary: true,
        show_data_source_identifier: false,
      });
    } else if (e.target.value == "Lookup Textbox") {
      this.setState({
        showMinMaxLimit: false,
        showResponseMode: false,
        showMaxLimit: false,
        DataSourceTypeOpt: "Unset",
        DataSourceOpt: false,
        showDisplayTextField: false,
        showTableHeaders: false,
        isTableTypeQuestion: false,
        dynamicDropSelected: false,
        dynamicTextboxSelected: true,
        calendarSelected: false,
        searchInvokeField: false,
        show_include_in_summary: false,
        show_data_source_identifier: false,
      });
    } else if (
      e.target.value == "Textbox" ||
      e.target.value == "Multiline Textbox"
    )
      this.setState({
        showMinMaxLimit: false,
        showResponseMode: false,
        showMaxLimit: true,
        DataSourceTypeOpt: "Unset",
        DataSourceOpt: false,
        showDisplayTextField: false,
        isTableTypeQuestion: false,
        showTableHeaders: false,
        dynamicDropSelected: false,
        dynamicTextboxSelected: false,
        calendarSelected: false,
        searchInvokeField: false,
        show_include_in_summary: true,
        show_data_source_identifier: false,
      });
    else if (e.target.value == "Display Text")
      this.setState({
        showMinMaxLimit: false,
        showResponseMode: false,
        showMaxLimit: false,
        DataSourceTypeOpt: "Unset",
        DataSourceOpt: false,
        showDisplayTextField: true,
        showTableHeaders: false,
        isTableTypeQuestion: false,
        dynamicDropSelected: false,
        dynamicTextboxSelected: false,
        calendarSelected: false,
        searchInvokeField: false,
        show_include_in_summary: false,
        show_data_source_identifier: false,
      });
    else if (e.target.value == "Table") {
      if (this.state.AssociatedToDropdown == 1) {
        swal({
          text: this.props.tr["Table type question cannot be a dependent."],
          buttons: this.props.tr["OK"],
        }).then((willDelete) => {
          this.setState({
            showMinMaxLimit: false,
            showResponseMode: false,
            showMaxLimit: false,
            DataSourceTypeOpt: "Unset",
            DataSourceOpt: false,
            showDisplayTextField: false,
            AssociatedToDropdown: 0,
            showTableHeaders: true,
            isTableTypeQuestion: true,
            dynamicDropSelected: false,
            dynamicTextboxSelected: false,
            calendarSelected: false,
            searchInvokeField: false,
            show_include_in_summary: true,
            show_data_source_identifier: false,
          });
        });
      } else {
        this.setState({
          showMinMaxLimit: false,
          showResponseMode: false,
          showMaxLimit: false,
          DataSourceTypeOpt: "Unset",
          DataSourceOpt: false,
          showDisplayTextField: false,
          showTableHeaders: true,
          isTableTypeQuestion: true,
          dynamicDropSelected: false,
          dynamicTextboxSelected: false,
          calendarSelected: false,
          searchInvokeField: false,
          show_include_in_summary: true,
          show_data_source_identifier: false,
        });
      }
    } else if (e.target.value == "Numeric Spinner") {
      this.setState({
        showMinMaxLimit: true,
        showResponseMode: false,
        showMaxLimit: false,
        DataSourceTypeOpt: "Unset",
        DataSourceOpt: false,
        showDisplayTextField: false,
        showTableHeaders: false,
        isTableTypeQuestion: false,
        dynamicDropSelected: false,
        dynamicTextboxSelected: false,
        calendarSelected: false,
        searchInvokeField: false,
        show_include_in_summary: true,
        show_data_source_identifier: false,
      });
    } else if (e.target.value == "Calendar" || e.target.value == "Datetime") {
      this.setState({
        showMinMaxLimit: false,
        showResponseMode: false,
        showMaxLimit: false,
        DataSourceTypeOpt: "Unset",
        DataSourceOpt: false,
        showDisplayTextField: false,
        showTableHeaders: false,
        isTableTypeQuestion: false,
        dynamicDropSelected: false,
        dynamicTextboxSelected: false,
        calendarSelected: true,
        searchInvokeField: false,
        show_include_in_summary: true,
        show_data_source_identifier: false,
      });
    } else
      this.setState({
        showMinMaxLimit: false,
        showResponseMode: false,
        showMaxLimit: false,
        DataSourceTypeOpt: "Unset",
        DataSourceOpt: false,
        showDisplayTextField: false,
        showTableHeaders: false,
        isTableTypeQuestion: false,
        dynamicDropSelected: false,
        dynamicTextboxSelected: false,
        calendarSelected: false,
        searchInvokeField: false,
        show_include_in_summary: false,
        show_data_source_identifier: false,
      });

    this.setState({
      selectedValueList: [],
      selectedMultiValueList: [],
      selectedDSFilterAttr: [],
      errorDSFilterAttr: "",
      errorMultiValue: "",
      showDataSourceOption: false,
      criteriaValues: [],
      criteriaErrors: [],
      DataSourceOpt: false,
    });
  }

  onResponseModeChange(e) {
    this.props.dispatch(
      change("serviceOrderChoicesForm", "datasource_type_uuid", "")
    );
    this.props.dispatch(
      change("serviceOrderChoicesForm", "datasource_search_attributes", "")
    );
    this.props.dispatch(
      change("serviceOrderChoicesForm", "datasource_type", "")
    );
    this.props.dispatch(
      change("serviceOrderChoicesForm", "datasource_label_key", "")
    );
    this.props.dispatch(
      change("serviceOrderChoicesForm", "data_source_identifier", "")
    );
    if (e.target.value == "Static") {
      this.setState({ DataSourceTypeOpt: "Static" });
    } else if (e.target.value == "Custom") {
      this.props.getCustomDataSources();
      this.setState({ DataSourceTypeOpt: "Custom" });
    } else if (e.target.value == "Predefined") {
      if (!this.state.dynamicDropSelected) {
        this.props.getDataSourceTypes("Typeahead");
      } else if (this.state.dynamicDropSelected) {
        this.props.getDataSourceTypes("Dynamic");
      }
      this.setState({ DataSourceTypeOpt: "Predefined" });
    } else {
      this.setState({ DataSourceTypeOpt: "" });
    }
    this.setState({
      selectedValueList: [],
      selectedMultiValueList: [],
      selectedDSFilterAttr: [],
      errorDSFilterAttr: "",
      errorMultiValue: "",
      criteriaValues: [],
      criteriaErrors: [],
      showDataSourceOption: false,
      DataSourceOpt: false,
    });
  }

  addField() {
    let existingFields = structuredClone(this.state.criteriaValues);
    let existingErrors = structuredClone(this.state.criteriaErrors);
    this.setState({
      criteriaValues: [
        ...existingFields,
        { field: "", operator: "=", value: "" },
      ],
      criteriaErrors: [
        ...existingErrors,
        { field: "", operator: "=", value: "" },
      ],
    });
  }

  removeField(index) {
    let newFields = this.state.criteriaValues.filter((_, i) => i !== index);
    let newFieldErrors = this.state.criteriaErrors.filter(
      (_, i) => i !== index
    );

    this.setState({
      criteriaValues: newFields,
      criteriaErrors: newFieldErrors,
    });
  }

  handleChange(event, index) {
    const { name, value } = event.target;
    let existingValues = structuredClone(this.state.criteriaValues);
    let existingErrors = structuredClone(this.state.criteriaErrors);
    existingValues[index][name] = value;
    if (value.trim().length === 0) {
      existingErrors[index][name] = "error";
    } else {
      existingErrors[index][name] = "";
    }

    this.setState({
      criteriaValues: existingValues,
      criteriaErrors: existingErrors,
    });
  }

  onRestrictDateChange(e) {
    this.setState({
      calendar_type_id: e.target.value,
      calendar_type_value: e.target[e.target.selectedIndex].text,
    });
  }

  onDataSourceOptChng(e) {
    this.props.dispatch(
      change("serviceOrderChoicesForm", "data_source_identifier", "")
    );
    this.props.dispatch(
      change("serviceOrderChoicesForm", "datasource_type_uuid", "")
    );
    this.props.dispatch(
      change("serviceOrderChoicesForm", "datasource_type", "")
    );
    this.props.dispatch(
      change("serviceOrderChoicesForm", "datasource_search_attributes", "")
    );
    this.props.dispatch(
      change("serviceOrderChoicesForm", "datasource_label_key", "")
    );

    if (
      e.target[e.target.selectedIndex].text === "Project Code" ||
      e.target[e.target.selectedIndex].text === "Laptop Serial Number" ||
      e.target[e.target.selectedIndex].text === "Desktop Serial Number"
    ) {
      this.setState({ showDataSourceOption: true });
    } else {
      this.setState({ showDataSourceOption: false });
    }

    if (e.target[e.target.selectedIndex].text === "ITAM Location") {
      this.setState({ ItamLocationSelected: true });
    } else {
      this.setState({ ItamLocationSelected: false });
    }

    if (e.target.value) {
      this.setState({
        DataSourceOpt: true,
        datasource_type_name: e.target[e.target.selectedIndex].text,
        customDataSourceId: e.target.value,
      });
    } else {
      this.setState({
        DataSourceOpt: false,
        datasource_type_name: null,
      });
    }
    this.props.getReadOnlyResponseKeyTypesAndKeys(
      e.target[e.target.selectedIndex].text
    );
    this.setState({
      selectedValueList: [],
      selectedMultiValueList: [],
      selectedDSFilterAttr: [],
      errorDSFilterAttr: "",
      errorMultiValue: "",
      criteriaErrors: [],
      criteriaValues: [],
    });
  }

  onAssociatedToChange(e) {
    const { change } = this.props;
    if (e.target.value == "No") {
      this.setState({ AssociatedToDropdown: 0 });
    } else if (e.target.value == "Yes") {
      this.setState({ AssociatedToDropdown: 1 });
    }
  }

  onIsMandatoryChange(e) {
    if (e.target.value == "No") {
      this.setState({ isMandatory: "No" });
    } else if (e.target.value == "Yes") {
      this.setState({ isMandatory: "Yes" });
    }
  }

  onAssociatedSelected(e) {
    const { change } = this.props;
    this.setState({
      showAssociatedSelected: true,
      associatedSelectedValues: e.target.value,
    });

    let questionList = this.props.spcmReducer.myQuestionsForService;

    const category_list = this.props.spcmReducer.quesActiveCategories;

    let outerThis = this;

    questionList.forEach(function (questionList) {
      let questionId = questionList.QUESTION_ID;
      if (questionId == e.target.value) {
        if (
          questionList.RESPONSE_TYPE == "Typeahead" ||
          questionList.RESPONSE_TYPE == "multiSelectTypeahead" ||
          questionList.RESPONSE_TYPE == "Lookup Textbox"
        ) {
          outerThis.setState({
            showHideAssociatedSelected: false,
            showMultiSelectAssociated: false,
          });
        } else if (questionList.RESPONSE_TYPE == "Checkbox") {
          outerThis.setState({ showMultiSelectAssociated: true });
        } else {
          outerThis.setState({
            showHideAssociatedSelected: true,
            showMultiSelectAssociated: false,
          });
        }
        outerThis.setState({
          parentQuesCatId: questionList.CATEGORY_ID,
          parentQuesCatName: questionList.CATEGORY_NAME,
          selectedComponentsId: questionList.QUESTION_ENTITYID,
        });

        outerThis.props.dispatch(
          change("category_name", questionList.CATEGORY_ID)
        );
        outerThis.props.dispatch(
          change("question_entityid", questionList.QUESTION_ENTITYID)
        );
      }
    });

    if (e.target.value) {
      let indexSelected = e.target.selectedIndex;
      this.setState(
        {
          questionSelectedType:
            questionList[indexSelected - 1].QUESTIONDEF_TYPE,
        },
        function () {
          if (this.state.questionSelectedType == "optional") {
            this.setState({ isMandatory: "No", parentOptional: true });
          } else {
            this.setState({ parentOptional: false });
          }
        }
      );
    }

    this.props.dispatch(
      change("serviceOrderChoicesForm", "question_parent_value", "")
    );
    this.props.dispatch(
      change("serviceOrderChoicesForm", "parent_response_id", "")
    );
    this.setState({
      selectedValueList: [],
      selectedMultiValueList: [],
      selectedDSFilterAttr: [],
      errorDSFilterAttr: "",
      errorMultiValue: "",
    });
  }

  onCategoryChange(e) {
    this.setState({ parentQuesCatId: e });
  }

  onComponentChange(id) {
    this.setState({ selectedComponentsId: id });
  }

  countCat(e) {
    if (e.target.value.length > 200) {
      e.preventDefault();
    }
  }

  componentWillMount() {
    let value = this.props.initialValues.response_type;
    const parentID = this.props.initialValues.question_parent_id || 0;
    const AssociatedToDropdown = parentID == 0 ? 0 : 1;

    if (this.props.initialValues.questiondef_type == "optional") {
      this.setState({ isMandatory: "No" });
    } else if (this.props.initialValues.questiondef_type == "mandatory") {
      this.setState({ isMandatory: "Yes" });
    }

    if (AssociatedToDropdown == 0) {
      this.setState({ AssociatedToDropdown });
    } else if (AssociatedToDropdown == 1) {
      this.setState({
        AssociatedToDropdown,
        showAssociatedSelected: true,
        associatedSelectedValues: parentID,
      });
    }

    let responseMode = this.props.initialValues.response_mode;
    if (
      this.props.initialValues?.response_mode &&
      this.props.initialValues?.response_mode === "Dynamic"
    ) {
      responseMode = "Predefined";
      this.props.dispatch(
        change("serviceOrderChoicesForm", "response_mode", responseMode)
      );
    }

    if (
      value == "Dropdown List" ||
      value == "Multiselect List" ||
      value == "Checkbox" ||
      value == "Radio button"
    )
      this.setState({
        showResponseMode: true,
        showMaxLimit: false,
        showStatic: true,
        DataSourceTypeOpt: "Static",
        showDisplayTextField: false,
        show_include_in_summary: true,
        show_data_source_identifier: false,
      });
    else if (value == "Typeahead") {
      if (this.props.initialValues.response_mode === "Custom") {
        this.props.getCustomDataSources();
      } else {
        this.props.getDataSourceTypes("Typeahead");
      }
      this.setState({
        showResponseMode: true,
        showMaxLimit: false,
        showStatic: false,
        DataSourceTypeOpt: responseMode,
        DataSourceOpt: true,
        showDisplayTextField: false,
        searchInvokeField: true,
        show_include_in_summary: true,
        show_data_source_identifier: false,
      });
    } else if (value == "multiSelectTypeahead") {
      if (this.props.initialValues.response_mode === "Custom") {
        this.props.getCustomDataSources();
      } else {
        this.props.getDataSourceTypes("Typeahead");
      }
      this.setState({
        showResponseMode: true,
        showMaxLimit: false,
        showStatic: false,
        DataSourceTypeOpt: responseMode,
        DataSourceOpt: true,
        showDisplayTextField: false,
        searchInvokeField: true,
        show_include_in_summary: true,
        show_data_source_identifier: true,
      });
    } else if (value == "Dynamic Dropdown") {
      if (this.props.initialValues.response_mode === "Custom") {
        this.props.getCustomDataSources();
      } else {
        this.props.getDataSourceTypes("Dynamic");
      }
      this.setState({
        showResponseMode: true,
        showMaxLimit: false,
        showStatic: false,
        DataSourceTypeOpt: responseMode,
        DataSourceOpt: true,
        showDisplayTextField: false,
        dynamicDropSelected: true,
        show_include_in_summary: true,
        show_data_source_identifier: false,
      });
    } else if (value == "Lookup Textbox") {
      this.setState({
        dynamicTextboxSelected: true,
        show_include_in_summary: false,
        show_data_source_identifier: false,
      });
    } else if (value == "Textbox" || value == "Multiline Textbox")
      this.setState({
        showResponseMode: false,
        showMaxLimit: true,
        showDisplayTextField: false,
        show_include_in_summary: true,
        show_data_source_identifier: false,
      });
    else if (value == "Display Text")
      this.setState({
        showDisplayTextField: true,
        show_data_source_identifier: false,
      });
    else if (value == "Table")
      this.setState({
        showTableHeaders: true,
        AssociatedToDropdown: 0,
        isTableTypeQuestion: true,
        show_include_in_summary: true,
        show_data_source_identifier: false,
      });
    else if (value == "Numeric Spinner") {
      this.setState({
        showMinMaxLimit: true,
        show_include_in_summary: true,
        show_data_source_identifier: false,
      });
    } else if (value == "Calendar" || value == "Datetime") {
      this.setState({
        calendarSelected: true,
        show_include_in_summary: true,
        show_data_source_identifier: false,
      });
    } else
      this.setState({
        showResponseMode: false,
        showMaxLimit: false,
        showDisplayTextField: false,
        show_include_in_summary: false,
        show_data_source_identifier: false,
      });

    this.props.getQuestionsActiveCategory(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
    );

    this.props.getServiceMyQuestionLists(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
    );

    if (this.props.initialValues.datasource_type_name) {
      this.props.getReadOnlyResponseKeyTypesAndKeys(
        this.props.initialValues.datasource_type_name
      );
    }

    if (this.props.spcmReducer.editLeftId > 0) {
      this.setState({
        parentQuesCatName: this.props.initialValues.category_name_view,
        parentQuesCatId: this.props.initialValues.category_id,
        selectedComponentsId: this.props.initialValues.question_entityid,
        minItemQuantity: this.props.initialValues.min_value,
        maxItemQuantity: this.props.initialValues.max_value,
        calendar_type_id: this.props.initialValues.calendar_type_id,
        calendar_type_value: this.props.initialValues.calendar_type_value,
        datasource_type_name: this.props.initialValues.datasource_type_name,
        days_after: this.props.initialValues.days_after,
        days_before: this.props.initialValues.days_before,
        include_in_summary: this.props.initialValues.include_in_summary
          ? this.props.initialValues.include_in_summary
          : "false",
        data_source_identifier: this.props.initialValues.data_source_identifier,
        customDataSourceId: this.props.initialValues.datasource_type_uuid,
      });

      if (
        this.props.initialValues &&
        (typeof this.props.initialValues.ro_responses !== "undefined" ||
          this.props.initialValues.ro_responses !== null)
      ) {
        if (this.props.initialValues.ro_responses?.length > 0) {
          let selectedValueList = this.props.initialValues.ro_responses.map(
            (val) => {
              return { id: val, label: val };
            }
          );
          this.setState({ selectedValueList: selectedValueList });
        }
      }
      if (
        this.props.initialValues &&
        (typeof this.props.initialValues.datasource_search_attributes !==
          "undefined" ||
          this.props.initialValues.datasource_search_attributes !== null)
      ) {
        if (this.props.initialValues.ro_responses?.length > 0) {
          let selectedDSFilterAttr =
            this.props.initialValues.datasource_search_attributes
              ?.split(",")
              .map((val) => {
                return { id: val, label: val };
              });
          this.setState({ selectedDSFilterAttr: selectedDSFilterAttr });
        }
      }

      try {
        let criteriaValues = JSON.parse(
          this.props.initialValues.datasource_filter_criteria
        );
        let criteriaErrors = [];
        if (Array.isArray(criteriaValues)) {
          criteriaValues.forEach(() => {
            criteriaErrors.push({ field: "", operator: "", value: "" });
          });
        } else {
          criteriaValues = [];
        }
        this.setState({ criteriaValues, criteriaErrors });
      } catch (err) {
        console.log("err -", err);
      }

      this.props.dispatch(
        change("category_name", this.props.initialValues.category_id)
      );
      this.props.dispatch(
        change("question_entityid", this.props.initialValues.question_entityid)
      );
    } else {
      this.props.dispatch(
        change("serviceOrderChoicesForm", "question_approval", "N")
      );
    }
  }

  componentWillReceiveProps(newProps) {
    let datasource_type = this.props.initialValues.datasource_type;

    if (this.props.spcmReducer.editLeftId > 0) {
      if (
        newProps.spcmReducer.myQuestionsForService !=
        this.props.spcmReducer.myQuestionsForService
      ) {
        let questionList = newProps.spcmReducer.myQuestionsForService;
        let outerThis = this;

        questionList.forEach(function (questionList) {
          let parentQuestionId = questionList.QUESTION_ID;
          let parentQuestionMandat = questionList.QUESTIONDEF_TYPE;
          let questionId = outerThis.props.initialValues.question_parent_id;

          if (parentQuestionId == questionId) {
            if (parentQuestionMandat == "optional") {
              outerThis.setState({ parentOptional: true });
            } else {
              outerThis.setState({ parentOptional: false });
            }
            if (
              questionList.RESPONSE_TYPE == "Typeahead" ||
              questionList.RESPONSE_TYPE == "multiSelectTypeahead" ||
              questionList.RESPONSE_TYPE == "Lookup Textbox"
            ) {
              outerThis.setState({
                showHideAssociatedSelected: false,
              });
            }
          }
        });
      }

      if (
        this.props.spcmReducer.dataSourceTypes !==
        newProps.spcmReducer.dataSourceTypes
      ) {
        let outerThis = this;

        newProps.spcmReducer.dataSourceTypes.forEach((dataSourceTypesList) => {
          let sourceTypeId = dataSourceTypesList.ID;
          if (
            sourceTypeId === datasource_type &&
            (dataSourceTypesList.FIELD1_NAME === "Project Code" ||
              dataSourceTypesList.FIELD1_NAME === "Laptop Serial Number" ||
              dataSourceTypesList.FIELD1_NAME === "Desktop Serial Number")
          ) {
            outerThis.setState({
              showDataSourceOption: true,
            });
          }

          if (
            sourceTypeId === datasource_type &&
            dataSourceTypesList.FIELD1_NAME === "ITAM Location"
          ) {
            outerThis.setState({
              ItamLocationSelected: true,
            });
          }
        });
      }

      let questionsAssociated =
        newProps.spcmReducer.myQuestionsForService?.filter((ques) => {
          if (
            ques?.QUESTION_ID?.toString() ===
            this.props?.initialValues?.question_parent_id?.toString()
          ) {
            return ques;
          }
        });
      if (
        questionsAssociated.length > 0 &&
        this.state.multiSelectValuesUpdated === false &&
        questionsAssociated[0].RESPONSE_TYPE == "Checkbox"
      ) {
        let selectedValueList = this.props.initialValues.parent_response_id
          .split(";")
          .map((val, index) => {
            return {
              id: val,
              label:
                this.props.initialValues.question_parent_value.split(";")[
                  index
                ],
            };
          });
        this.setState({
          selectedMultiValueList: selectedValueList,
          showMultiSelectAssociated: true,
          multiSelectValuesUpdated: true,
        });
      }
    }
  }

  componentDidMount() {
    this.props.getConsumptionItems(
      this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
    );
  }

  componentWillUnmount() {
    this.props.resetReducer();
    this.props.spcmLoader("attribute", "success");
  }

  validateTableHeaders(e) {
    let str = e.target.value;
    let semicolonCount = str.split(";").length - 1;
    if (semicolonCount > 2) {
      e.preventDefault();
    }
  }

  onValueSelection(selected) {
    this.setState({ selectedValueList: selected });
  }

  onDSFilterValueSelection(selected) {
    console.log("selected --- ", selected);
    let error = "";
    if (selected.length === 0) {
      error = "error";
    }
    let selectedValue = [];
    selected.map((val) => {
      selectedValue.push(val.label);
    });
    this.props.dispatch(
      change(
        "serviceOrderChoicesForm",
        "datasource_search_attributes",
        selectedValue.join(",")
      )
    );
    this.setState({ errorDSFilterAttr: error, selectedDSFilterAttr: selected });
  }

  onMultiValueSelection(selected) {
    let error = "";
    if (selected.length === 0) {
      error = "error";
    }
    let selectedValue = [];
    let selectedId = [];
    selected.map((val) => {
      selectedValue.push(val.label);
      selectedId.push(val.id);
    });
    this.props.dispatch(
      change(
        "serviceOrderChoicesForm",
        "question_parent_value",
        selectedValue.join(";")
      )
    );
    this.props.dispatch(
      change(
        "serviceOrderChoicesForm",
        "parent_response_id",
        selectedId.join(";")
      )
    );
    this.setState({ errorMultiValue: error, selectedMultiValueList: selected });
  }

  getValuesDropdownOptions(type = "Display Attributes") {
    if (
      this.state.DataSourceTypeOpt === "Custom" &&
      this.state.customDataSourceId !== ""
    ) {
      let object = [];
      let selectedOption = this.props.customDataSources?.data?.filter(
        (dataSource) => dataSource._id === this.state.customDataSourceId
      );

      if (
        selectedOption.length > 0 &&
        Array.isArray(selectedOption[0].columns)
      ) {
        selectedOption[0].columns.forEach((values) => {
          if (type === "Display Attributes") {
            object.push({
              id: values,
              label: values,
            });
          } else if (type === "Filter Attr") {
            object.push({
              id: values?.split("~")[0],
              label: values?.split("~")[0],
            });
          } else {
            object.push({
              value: values?.split("~")[0],
              text: values?.split("~")[1],
            });
          }
        });
      }
      return object;
    } else if (this.state.DataSourceTypeOpt === "Predefined") {
      let object = [];
      const readOnlyTypes =
        this.props.spcmReducer.ReadOnlyResponseKeyTypesAndKeys;
      if (typeof readOnlyTypes !== "undefined" && readOnlyTypes.length > 0) {
        readOnlyTypes.forEach((values) => {
          if (type === "Display Attributes") {
            let val = { id: values, label: values };
            object.push(val);
          } else {
            let option = values?.split("~");
            object.push({
              value: option[0],
              text: option.length > 1 ? option[1] : option[0],
            });
          }
        });
      }
      return object;
    }
  }

  submitForm(values) {
    console.log('this.state', this.state);
    if (
      this.state.DataSourceOpt == true &&
      this.state.ItamLocationSelected == false &&
      this.state.selectedValueList.length > 0
    ) {
      values["ro_responses"] = this.state.selectedValueList.map((values) => {
        return values.id;
      });
    } else {
      values.ro_responses = null;
    }
    if (
      this.state.DataSourceTypeOpt === "Custom" &&
      this.state.selectedDSFilterAttr?.length === 0 &&
      !this.state.dynamicDropSelected
    ) {
      this.setState({ errorDSFilterAttr: "error" });
      return false;
    }
    values.min_value =
      this.state.minItemQuantity == "" ? "1" : this.state.minItemQuantity;
    values.max_value =
      this.state.maxItemQuantity == ""
        ? "99999999"
        : this.state.maxItemQuantity;
    values.datasource_type_name = this.state.datasource_type_name;

    if (values.response_value) {
      values.response_value = values.response_value
        .replace(/\s*;\s*/g, ";")
        .trim();
    }
    if (values.category_name) {
      values.category_id = values.category_name;
    }

    if (this.state.show_include_in_summary) {
      values.include_in_summary = this.state.include_in_summary;
    }
    if (values?.datasource_type === "") {
      values.datasource_type = null;
    }

    values.questiondef_type =
      this.state.isMandatory === "Yes" && this.state.parentOptional == false
        ? "mandatory"
        : "optional";

    let errors = this.state.criteriaErrors;

    let isValidCriteria = true;
    if (this.state.criteriaValues.length > 0) {
      this.state.criteriaValues.forEach((obj, index) => {
        Object.keys(obj).forEach((key) => {
          if (obj[key]?.trim() === "") {
            errors[index][key] = "error";
            isValidCriteria = false;
          }
        });
      });
      values.datasource_filter_criteria = JSON.stringify(
        this.state.criteriaValues
      );
    } else {
      values.datasource_filter_criteria = null;
    }

    if (!isValidCriteria) {
      this.setState({ criteriaErrors: errors });
      return;
    }

    if (values.display_text) {
      values.display_text =
        values.display_text.replace(/<[^>]+>/g, "").trim() == ""
          ? ""
          : values.display_text;
      const actualData = values.display_text.replace(/<[^>]+>/g, "").trim();
      if (actualData.length > 255) {
        throw new SubmissionError({
          _error: "Display text cannot be greater than 255 characters",
        });
      }
      values.questiondef_type = "optional";
    }

    if (this.state.AssociatedToDropdown == 0) {
      values.question_parent_id = 0;
      values.question_parent_value = null;
      values.parent_response_id = null;
    }

    if (values.response_type !== "Table") {
      values.table_headers = null;
    }
    if (values.response_type == "Dynamic Dropdown") {
      if (this.state.selectedValueList.length === 0) {
        values.ro_responses = null;
      }
    }
    if (this.state.showHideAssociatedSelected === false) {
      values.question_parent_value = null;
      values.parent_response_id = null;
    }
    if (
      values.response_type != "Dynamic Dropdown" &&
      values.response_type != "Typeahead" &&
      values.response_type != "multiSelectTypeahead"
    ) {
      values.placeholder = null;
    }

    if (this.props.spcmReducer.editLeftId > 0) {
      values.updatedby_name = "";
      values.updatedby = "";
    } else {
      values.createdby_name = "";
      values.created_by = "";
    }

    if (
      values.response_type == "Calendar" ||
      values.response_type == "Datetime"
    ) {
      values.calendar_type_id = this.state.calendar_type_id;
      values.calendar_type_value = this.state.calendar_type_value;
      values.days_before = parseInt(this.state.days_before, 10);
      values.days_after = parseInt(this.state.days_after, 10);
    } else {
      values.calendar_type_id = null;
      values.calendar_type_value = null;
    }

    if (this.props.spcmReducer.editLeftId > 0) {
      this.props.spcmLoader("attribute", "posting");
      if (this.props.formSelection == "Questions_Form") {
        values.offering_id =
          this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
        let newValues = Object.assign(
          {},
          _.omit(values, ["category_name_view"])
        );
        return axios
          .patch(
            "/service/api/patchOfferingQuestion/" +
              this.props.spcmReducer.editLeftId,
            newValues
          )
          .then((response) => {
            this.props.getServiceOrderChoices(
              this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
            );
            this.props.componentFormFun4(false);
            this.props.spcmLoader("attribute", "success");
          })
          .catch((error) => {
            this.props.spcmLoader("attribute", "success");
            throw new SubmissionError({
              _error: error?.response?.data?.[0]?.message || "",
            });
          });
      } else {
        values.offering_id =
          this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;

        values = _.transform(values, function (result, val, key) {
          result[key.toLowerCase()] = val;
        });

        return axios
          .patch(
            "/service/api/patchQuestionsCategory/" +
              this.props.spcmReducer.editLeftId,
            values
          )
          .then((response) => {
            this.props.getQuestionsCategory(
              this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
            );
            this.props.componentFormFun4(false);
            this.props.spcmLoader("attribute", "success");
          })
          .catch((error) => {
            this.props.spcmLoader("attribute", "success");
            throw new SubmissionError({
              _error: error?.response?.data?.message || "",
            });
          });
      }
    } else {
      this.props.spcmLoader("attribute", "posting");

      if (this.props.formSelection == "Questions_Form") {
        return axios
          .post("/service/api/saveOfferingQuestion", values)
          .then((response) => {
            this.props.getServiceOrderChoices(
              this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
            );
            this.props.getServiceDetailCounts(
              this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
            );
            this.props.componentFormFun4(false);
            this.props.spcmLoader("attribute", "success");
          })
          .catch((error) => {
            this.props.spcmLoader("attribute", "success");
            throw new SubmissionError({
              _error: error?.response?.data?.[0]?.message,
            });
          });
      } else {
        values.offering_id =
          this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID;
        values.CATEGORY_STATUS = this.state.categoryNameInitialStatus;

        values = _.transform(values, function (result, val, key) {
          result[key.toLowerCase()] = val;
        });

        return axios
          .post("/service/api/saveQuestionsCategory", values)
          .then((response) => {
            this.props.getQuestionsCategory(
              this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
            );
            this.props.getServiceDetailCounts(
              this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID
            );
            this.props.componentFormFun4(false);
            this.props.spcmLoader("attribute", "success");
          })
          .catch((error) => {
            this.props.spcmLoader("attribute", "success");
            throw new SubmissionError({
              _error: error?.response?.data?.message || "",
            });
          });
      }
    }
  }

  render() {
    const tr = this.props.tr;
    const { error, handleSubmit, change } = this.props;
    const {
      selectedValueList,
      selectedMultiValueList,
      selectedDSFilterAttr,
      errorMultiValue,
      errorDSFilterAttr,
    } = this.state;
    const components = this.props.spcmReducer.consumptionItems;
    const questions = this.props.spcmReducer.myQuestionsForService;
    let datasource_type = [];
    if (this.state.DataSourceTypeOpt === "Predefined") {
      datasource_type = this.props.spcmReducer.dataSourceTypes;
    } else if (
      this.state.DataSourceTypeOpt === "Custom" &&
      Array.isArray(this.props.customDataSources?.data)
    ) {
      datasource_type = this.props.customDataSources?.data;
    }
    const ReadOnlyResponseKeyTypesAndKeys =
      this.props.spcmReducer.ReadOnlyResponseKeyTypesAndKeys;

    const category_list = this.props.spcmReducer.quesActiveCategories;
    const selectedQuestion = questions.filter(
      (que) => que.QUESTION_ID == this.state.associatedSelectedValues
    );

    let selectedComponents = "";
    let responseArr = [];
    if (selectedQuestion.length > 0) {
      const responseValueArray =
        selectedQuestion[0].RESPONSE_VALUE !== null
          ? selectedQuestion[0].RESPONSE_VALUE.split(";")
          : [];
      const responseValueIdArray =
        selectedQuestion[0].RESPONSE_VALUE_ID !== null
          ? selectedQuestion[0].RESPONSE_VALUE_ID.split(";")
          : [];
      responseArr = responseValueArray.map((i, index) => ({
        label: i,
        id: responseValueIdArray[index],
      }));
    }

    let questionType = [
      { value: "optional", text: "Optional" },
      { value: "mandatory", text: "Mandatory" },
    ];

    let responseType = [
      { value: "Attachment", text: "Attachment" },
      { value: "Calendar", text: "Calendar" },
      { value: "Checkbox", text: "Checkbox" },
      { value: "Datetime", text: "Datetime" },
      { value: "Display Text", text: "Display Text" },
      { value: "Dropdown List", text: "Dropdown List" },
      { value: "Dropzone", text: "Dropzone" },
      { value: "Dynamic Dropdown", text: "Dynamic Dropdown" },
      { value: "Lookup Textbox", text: "Lookup Textbox" },
      { value: "Multiline Textbox", text: "Multiline Textbox" },
      { value: "Multiselect List", text: "Multiselect List" },
      { value: "Numeric Spinner", text: "Numeric Spinner" },
      { value: "Radio button", text: "Radio button" },
      { value: "Table", text: "Table" },
      { value: "Textbox", text: "Textbox" },
      { value: "Typeahead", text: "Typeahead" },
      { value: "multiSelectTypeahead", text: "MultiSelect Typeahead" },
    ];
    let respTypeDynamicDrop = [
      { value: "Dynamic Dropdown", text: "Dynamic Dropdown" },
    ];

    let triggerApprovalOptions = [
      { value: "Y", key: "Yes" },
      { value: "N", key: "No" },
    ];
    let numbers = [];
    for (let i = 1; i <= 10; i++) {
      numbers.push({ value: i, text: i });
    }

    return (
      <div className="rBoxGap">
        <form onSubmit={handleSubmit(this.submitForm)}>
          {error == undefined || error == null ? null : (
            <Alert variant="danger" className="errorMessageSec">
              <p>{error}</p>
            </Alert>
          )}

          {this.props.formSelection == "Questions_Form" ? (
            <div>
              <div className="rSubhdTxt">Questions</div>
              <Row>
                <Col md={12} className="margin-b-15">
                  <Form.Group className="radioInlineBox form-group">
                    <Form.Label bsClass="" className="float-l margin-r-20">
                      <span className="rStar"></span> {tr["Dependent ?"]}
                    </Form.Label>
                    <div className="radioInlineBox float-l">
                      <Field
                        component={_radio}
                        className="form-control"
                        type="radio"
                        checked={this.state.AssociatedToDropdown == 1}
                        name="question_parent_type"
                        value="Yes"
                        onChange={this.onAssociatedToChange}
                        disabled={this.state.isTableTypeQuestion}
                      />

                      <Field
                        component={_radio}
                        className="form-control"
                        type="radio"
                        checked={this.state.AssociatedToDropdown == 0}
                        name="question_parent_type"
                        value="No"
                        onChange={this.onAssociatedToChange}
                      />
                    </div>
                  </Form.Group>
                </Col>

                {this.state.AssociatedToDropdown == 0 ? (
                  <Col md={12}>
                    <Form.Group className="form-group">
                      <Form.Label bsClass="">
                        <span className="rStar"></span> {"Group Name"}
                      </Form.Label>
                      <Field
                        component={_dropDown}
                        name="category_name"
                        options={category_list}
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                ) : null}

                {this.state.AssociatedToDropdown == 1 && (
                  <Col md={12}>
                    <Form.Group className="form-group">
                      <Form.Label bsClass="">
                        <span className="rStar"></span> {"Dependent on/Value"}
                      </Form.Label>
                      <Field
                        component={_dropDown}
                        name="question_parent_id"
                        options={questions}
                        onChange={this.onAssociatedSelected}
                        className="form-control"
                        disabled={this.props.spcmReducer.editLeftId > 0}
                      />
                    </Form.Group>
                  </Col>
                )}

                {this.state.AssociatedToDropdown == 1 &&
                  this.state.showAssociatedSelected &&
                  this.state.showHideAssociatedSelected &&
                  !this.state.showMultiSelectAssociated && (
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Field
                          component={_dropDown}
                          name="parent_response_id"
                          options={responseArr}
                          className="form-control"
                          onChange={(e) => {
                            change(
                              "question_parent_value",
                              e.target[e.target.selectedIndex].text
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                  )}

                {this.state.AssociatedToDropdown == 1 &&
                  this.state.showAssociatedSelected &&
                  this.state.showHideAssociatedSelected &&
                  this.state.showMultiSelectAssociated && (
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Field
                          id="parent_response_id"
                          component={TypeaheadandDropdown}
                          onSelection={this.onMultiValueSelection}
                          name="parent_response_id"
                          className="form-control"
                          options={responseArr}
                          selectedOptions={selectedMultiValueList}
                          onCrossClick={() => this.onMultiValueSelection([])}
                          errorClass={
                            errorMultiValue === "error" ? "error" : ""
                          }
                          multiple={true}
                        />
                      </Form.Group>
                    </Col>
                  )}

                {this.state.AssociatedToDropdown == 1 ? (
                  <Col md={12}>
                    <Form.Group className="form-group">
                      <Form.Label bsClass="">
                        <span className="rStar"></span> {"Group Name"}
                      </Form.Label>
                      <Field
                        component={_dropDown}
                        name="category_name"
                        options={category_list}
                        defaultSelect={this.state.parentQuesCatId}
                        className="form-control"
                        onChange={(e) => {
                          this.onCategoryChange(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                ) : null}

                {this.state.AssociatedToDropdown == 0 ? (
                  <Col md={12}>
                    <Form.Group className="form-group">
                      <Form.Label bsClass="">
                        <span className="rStar"></span>{" "}
                        {tr["Against which component"]}
                      </Form.Label>
                      <Field
                        component={_dropDown}
                        options={components}
                        className="form-control"
                        name="question_entityid"
                      />
                    </Form.Group>
                  </Col>
                ) : (
                  <Col md={12}>
                    <Form.Group className="form-group">
                      <Form.Label bsClass="">
                        <span className="rStar"></span>{" "}
                        {tr["Against which component"]}
                      </Form.Label>
                      <Field
                        component={_dropDown}
                        options={components}
                        className="form-control"
                        name="question_entityid"
                        defaultSelect={this.state.selectedComponentsId}
                        onChange={(e) => {
                          this.onComponentChange(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                )}

                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span> {tr["Information Prompt"]}
                    </Form.Label>
                    <Field
                      component={_textArea}
                      rows="1"
                      name="question_text"
                      className="form-control"
                    />
                  </Form.Group>
                </Col>

                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      {tr["Help Text"]}
                      <span className="margin-l-5 f-size-10">
                        (For example : Comments/Guidance)
                      </span>
                    </Form.Label>
                    <Field
                      component={_inputField}
                      rows="1"
                      name="help_text"
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="margin-b-10">
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="" className="float-l margin-r-20">
                      {" "}
                      <span className=""></span> {tr["Mandatory ?"]}
                    </Form.Label>

                    <div className="radioInlineBox float-l">
                      <Field
                        component={_radio}
                        className="form-control"
                        type="radio"
                        name="questiondef_type"
                        value="Yes"
                        checked={this.state.isMandatory === "Yes"}
                        onChange={this.onIsMandatoryChange}
                        disabled={this.state.parentOptional}
                      />

                      <Field
                        component={_radio}
                        className="form-control"
                        type="radio"
                        name="questiondef_type"
                        value="No"
                        checked={this.state.isMandatory === "No"}
                        onChange={this.onIsMandatoryChange}
                        disabled={
                          this.props.initialValues.have_mandatory_child ==
                          "true"
                        }
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span> {tr["Information Type"]}
                    </Form.Label>
                    <Field
                      component={_dropDown}
                      rows="1"
                      name="response_type"
                      options={
                        this.state.showHideAssociatedSelected == false &&
                        this.state.AssociatedToDropdown == 1
                          ? respTypeDynamicDrop
                          : responseType
                      }
                      className="form-control"
                      disabled={this.props.initialValues.have_child == "true"}
                      onChange={this.onChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {(this.state.DataSourceTypeOpt == "Predefined" ||
                this.state.DataSourceTypeOpt == "Custom" ||
                this.state.DataSourceTypeOpt == "") && (
                <>
                  <Col md={12}>
                    <Form.Group className="form-group">
                      <Form.Label bsClass="">
                        <span className="rStar"></span> Placeholder
                      </Form.Label>
                      <Field
                        component={_inputField}
                        name="placeholder"
                        className="form-control"
                      />
                    </Form.Group>
                  </Col>
                  <Row className="infoTyRow">
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Form.Label bsClass="">
                          <span className="rStar"></span> Data Source Type
                        </Form.Label>
                        <Field
                          component={_dropDown}
                          name="response_mode"
                          options={[
                            { text: "Predefined", value: "Predefined" },
                            { text: "Custom", value: "Custom" },
                          ]}
                          className="form-control"
                          onChange={this.onResponseModeChange}
                        />
                      </Form.Group>
                    </Col>

                    {this.state.DataSourceTypeOpt === "Predefined" && (
                      <Col md={12}>
                        <Form.Group className="form-group">
                          <Form.Label bsClass="">
                            <span className="rStar"></span> Data Source Name
                          </Form.Label>
                          <Field
                            component={_dropDown}
                            onChange={this.onDataSourceOptChng}
                            name={"datasource_type"}
                            options={datasource_type}
                            className="form-control"
                          />
                        </Form.Group>
                      </Col>
                    )}

                    {(this.state.DataSourceTypeOpt === "Custom" ||
                      this.state.DataSourceTypeOpt == "") && (
                      <Col md={12}>
                        <Form.Group className="form-group">
                          <Form.Label bsClass="">
                            <span className="rStar"></span> Data Source Name
                          </Form.Label>
                          <Field
                            component={_dropDown}
                            onChange={this.onDataSourceOptChng}
                            name={"datasource_type_uuid"}
                            options={datasource_type}
                            className="form-control"
                          />
                        </Form.Group>
                      </Col>
                    )}

                    {this.state.searchInvokeField == true && (
                      <Col md={12}>
                        <Form.Group className="form-group">
                          <Form.Label bsClass="">
                            <span className="rStar"></span>{" "}
                            {tr["Search to be invoked after"]}
                          </Form.Label>
                          <Field
                            component={_number}
                            name="call_api_after_typeahead"
                            className="form-control"
                            min={2}
                            max={15}
                          />
                          {this.props.formValues?.call_api_after_typeahead &&
                            parseInt(
                              this.props.formValues?.call_api_after_typeahead,
                              10
                            ) < 4 && (
                              <p
                                className="f-size-11 "
                                style={{ color: "#4b3c4b" }}
                              >
                                (
                                {
                                  tr[
                                    "For optimal response time, please keep this value at 4 characters or more."
                                  ]
                                }
                                )
                              </p>
                            )}
                        </Form.Group>
                      </Col>
                    )}

                    {this.state.DataSourceOpt == true &&
                      this.state.ItamLocationSelected == false && (
                        <Col md={12}>
                          <Form.Group className="form-group">
                            <Form.Label bsClass="">
                              Display Attributes
                            </Form.Label>
                            <Field
                              component={TypeaheadandDropdown}
                              onSelection={this.onValueSelection}
                              name="ro_responses"
                              className="form-control"
                              options={this.getValuesDropdownOptions()}
                              selectedOptions={selectedValueList}
                              onCrossClick={() =>
                                this.setState({
                                  selectedValueList: [],
                                })
                              }
                              errorClass={""}
                              multiple={true}
                            />
                          </Form.Group>
                        </Col>
                      )}

                    {this.state.DataSourceTypeOpt == "Custom" && (
                      <Col md={12}>
                        <Form.Group className="form-group">
                          <Form.Label bsClass="">
                            <span className="rStar"></span> Data Source Label
                          </Form.Label>
                          <Field
                            component={_dropDown}
                            name="datasource_label_key"
                            className="form-control"
                            options={this.getValuesDropdownOptions("Criteria")}
                          />
                        </Form.Group>
                      </Col>
                    )}

                    {this.state.DataSourceTypeOpt == "Custom" &&
                      this.state.DataSourceOpt &&
                      !this.state.dynamicDropSelected && (
                        <Col md={12}>
                          <Form.Group className="form-group">
                            <Form.Label bsClass="">
                              <span className="rStar"></span> Data Source Search
                              Attributes
                            </Form.Label>
                            <Field
                              component={TypeaheadandDropdown}
                              onSelection={this.onDSFilterValueSelection}
                              name="datasource_search_attributes"
                              className="form-control"
                              options={this.getValuesDropdownOptions(
                                "Filter Attr"
                              )}
                              selectedOptions={selectedDSFilterAttr}
                              onCrossClick={() =>
                                this.setState({
                                  errorDSFilterAttr: "",
                                  selectedDSFilterAttr: [],
                                })
                              }
                              errorClass={
                                errorDSFilterAttr === "error" ? "error" : ""
                              }
                              multiple={true}
                            />
                          </Form.Group>
                        </Col>
                      )}

                    {this.state.show_data_source_identifier &&
                      this.state.DataSourceOpt == true &&
                      this.state.ItamLocationSelected == false &&
                      (this.state.dynamicDropSelected == false ||
                        (this.state.dynamicDropSelected == true &&
                          this.state.showDataSourceOption == true)) && (
                        <Col md={12}>
                          <Form.Group className="form-group">
                            <Form.Label bsClass="">
                              <span className="rStar"></span> Unique Identifier
                            </Form.Label>
                            <Field
                              component={_dropDown}
                              name="data_source_identifier"
                              className="form-control"
                              options={this.getValuesDropdownOptions()}
                            />
                          </Form.Group>
                        </Col>
                      )}

                    {(this.state.DataSourceTypeOpt == "Custom" ||
                      this.state.datasource_type_name ===
                        "Users By Nationality" ||
                      this.state.datasource_type_name ===
                        "CI by Classname") && (
                      <Col md={12}>
                        <Form.Group className="form-group">
                          {this.state.criteriaValues.length === 0 && (
                            <Button
                              title="Add"
                              className="ciCrtCatBtn2 float-r addCriteriaBtn"
                              onClick={() => this.addField()}
                            >
                              <HiPlus />
                            </Button>
                          )}
                          <Form.Label bsClass="">Filter Criteria</Form.Label>
                          {this.state.criteriaValues.length === 0 && (
                            <Form.Group className="form-group noCriteriaField">
                              <div>No Filter Criteria</div>
                            </Form.Group>
                          )}
                          {this.state.criteriaValues.length > 0 && (
                            <div className="commonForm">
                              <Table bordered>
                                <thead>
                                  <tr>
                                    <th width="30%">Field</th>
                                    <th width="15%">Condition</th>
                                    <th width="35%">Value</th>
                                    <th width="20%">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.criteriaValues.map(
                                    (criteria, i) => (
                                      <tr key={"cr" + i}>
                                        <td>
                                          <Form.Group>
                                            <Form.Select
                                              name="field"
                                              onChange={(e) =>
                                                this.handleChange(e, i)
                                              }
                                              value={criteria?.field || ""}
                                              className={
                                                this.state.criteriaErrors?.[i]
                                                  ?.field || ""
                                              }
                                            >
                                              <option value="">Select</option>
                                              {this.getValuesDropdownOptions(
                                                "criteria"
                                              ).map((option, index) => (
                                                <option
                                                  key={`opt${index}`}
                                                  value={option.value}
                                                >
                                                  {option.text}
                                                </option>
                                              ))}
                                            </Form.Select>
                                          </Form.Group>
                                        </td>
                                        <td>
                                          <Form.Group>
                                            <Form.Select
                                              name="operator"
                                              onChange={(e) =>
                                                this.handleChange(e, i)
                                              }
                                              value={criteria?.operator || ""}
                                              className={
                                                this.state.criteriaErrors?.[i]
                                                  ?.operator || ""
                                              }
                                            >
                                              <option value="=">=</option>
                                              <option value="!=">!=</option>
                                            </Form.Select>
                                          </Form.Group>
                                        </td>
                                        <td>
                                          <Form.Group>
                                            <Form.Control
                                              type="text"
                                              name="value"
                                              onChange={(e) =>
                                                this.handleChange(e, i)
                                              }
                                              value={criteria?.value || ""}
                                              className={
                                                this.state.criteriaErrors?.[i]
                                                  ?.value || ""
                                              }
                                            />
                                          </Form.Group>
                                        </td>
                                        <td
                                          style={{ whiteSpace: "nowrap" }}
                                          className="rBoxStyle"
                                        >
                                          {i ===
                                            this.state.criteriaValues.length -
                                              1 && (
                                            <Button
                                              title="Add"
                                              className="ciCrtCatBtn2"
                                              onClick={() => this.addField()}
                                            >
                                              <HiPlus />
                                            </Button>
                                          )}
                                          <Button
                                            title="Delete"
                                            className="ciCrtCatBtn2"
                                            onClick={() => this.removeField(i)}
                                          >
                                            <HiOutlineTrash />
                                          </Button>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                    )}
                  </Row>
                </>
              )}

              {this.state.calendarSelected == true && (
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span> {tr["Restrict Date"]}
                    </Form.Label>
                    <Field
                      component={_dropDown}
                      name="calendar_type_id"
                      options={[
                        { text: "None", value: "10" },
                        {
                          text: "Past Dates",
                          value: "20",
                        },
                        {
                          text: "Future Dates",
                          value: "30",
                        },
                        {
                          text: "Date Range",
                          value: "40",
                        },
                      ]}
                      className="form-control"
                      onChange={this.onRestrictDateChange}
                      defaultValue={this.state.calendar_type_id}
                    />
                  </Form.Group>
                </Col>
              )}

              {this.state.calendar_type_id === "40" && (
                <>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Form.Label bsClass="">
                          <span className="rStar"></span>{" "}
                          {tr["Days before Current Date"]}
                        </Form.Label>
                        <Field
                          component={_number}
                          name="days_before"
                          className="form-control"
                          onChange={this.onDaysBeforeCurrentDateChange}
                          defaultValue={this.state.days_before}
                          min={0}
                          max={9999}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Form.Label bsClass="">
                          <span className="rStar"></span>{" "}
                          {tr["Days after Current Date"]}
                        </Form.Label>
                        <Field
                          component={_number}
                          name="days_after"
                          className="form-control"
                          onChange={this.onDaysAfterCurrentDateChange}
                          defaultValue={this.state.days_before}
                          min={0}
                          max={9999}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}

              {this.state.dynamicTextboxSelected == true ? (
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span>{" "}
                      {tr["Min. Character Limit"]}
                    </Form.Label>
                    <Field
                      component={_number}
                      name="call_api_after"
                      className="form-control"
                      min={1}
                      max={20}
                    />
                  </Form.Group>
                </Col>
              ) : (
                ""
              )}

              {this.state.showTableHeaders == true ? (
                <Col md={12}>
                  <Form.Group className="form-group margin-b-0">
                    <Form.Label bsClass="">
                      <span className="rStar"></span> {tr["Table Headers"]}
                    </Form.Label>
                    <Field
                      component={_inputField}
                      name="table_headers"
                      className="form-control"
                      onChange={(e) => this.validateTableHeaders(e)}
                    />
                  </Form.Group>
                  <span
                    style={{
                      lineHeight: "12px",
                      display: "block",
                      margin: "5px 0px",
                    }}
                    className="f-size-10"
                  >
                    Please enter semicolon separated value (Ex. Value 1 ; Value
                    2 ; Value 3). Maximum 3 values can be entered
                  </span>
                </Col>
              ) : (
                ""
              )}

              {this.state.showResponseMode && this.state.showStatic && (
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span> {tr["Response Mode"]}
                    </Form.Label>
                    <Field
                      component={_dropDown}
                      name="response_mode"
                      options={[{ text: "Static", value: "Static" }]}
                      className="form-control"
                      onChange={this.onResponseModeChange}
                    />
                  </Form.Group>
                </Col>
              )}

              {this.state.DataSourceTypeOpt == "Static" && (
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span> {tr["List of values"]}
                    </Form.Label>
                    <Field
                      component={_inputField}
                      name="response_value"
                      className="form-control"
                    />
                  </Form.Group>
                  <span className="f-size-10">
                    Please enter semicolon separated value (Ex. Value 1 ; Value
                    2 ; Value 3)
                  </span>
                </Col>
              )}

              {this.state.showMaxLimit ? (
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span> {tr["Maximum Length"]}
                    </Form.Label>
                    <Field
                      component={_inputField}
                      name="max_limit"
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
              ) : (
                ""
              )}

              {this.state.showDisplayTextField ? (
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      {" "}
                      {tr["Display Text"]}
                      <a
                        href="javascript:void(0)"
                        title={
                          "Display text should not be more than 255 characters"
                        }
                      >
                        <IoIosInformationCircleOutline
                          style={{
                            "font-size": "17px",
                            "margin-left": "5px",
                            "margin-top": "-2px",
                          }}
                          title=""
                        />
                      </a>
                    </Form.Label>
                    <Field
                      name="display_text"
                      component={_ckeditor}
                      className="reactQuillEditor-area"
                      richTextConfig={"minRichTextEditor"}
                    />
                  </Form.Group>
                </Col>
              ) : (
                ""
              )}

              {this.state.showMinMaxLimit ? (
                <Row className="minItemLeftMargin">
                  <Col md={3}>
                    <Form.Group className="form-group margin-b-0 margin-t-5">
                      <Form.Label bsClass="" className="minItemFontSize">
                        {this.props.tr["Min. Value"]}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="form-group">
                      <Field
                        component={itemQuantityField}
                        max={99999999}
                        min={1}
                        name="min_value"
                        className="form-control shotSpin"
                        onChange={(e) => {
                          this.setState({
                            minItemQuantity: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="form-group margin-b-0 margin-t-5">
                      <Form.Label bsClass="" className="minItemFontSize">
                        {this.props.tr["Max. Value"]}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="form-group">
                      <Field
                        component={itemQuantityField}
                        max={99999999}
                        min={1}
                        name="max_value"
                        className="form-control shotSpin"
                        onChange={(e) => {
                          this.setState({
                            maxItemQuantity: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              ) : (
                ""
              )}

              <Row className="margin-t-10">
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">{tr["Trigger Approval"]}</Form.Label>
                    <Field
                      component={_dropDown}
                      rows="1"
                      name="question_approval"
                      options={triggerApprovalOptions}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span>{" "}
                      {tr["Visibility Sequence"]}
                    </Form.Label>
                    <Field
                      component={_number}
                      name="question_sequence"
                      className="form-control"
                      min={1}
                      max={150}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {this.state.show_include_in_summary && (
                <Row>
                  <Col md={6}>
                    <Form.Group className="form-group">
                      <Form.Check
                        name="include_in_summary"
                        type="checkbox"
                        checked={this.state.include_in_summary === "true"}
                        onClick={() => this.setIncudeInSummary()}
                        label={tr["Include In Summary"]}
                      ></Form.Check>
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={12}>
                  <ButtonToolbar className="black">
                    <Button
                      title="Save"
                      disabled={this.props.spcmReducer.spcmPostingAttribute}
                      type="submit"
                      bsSize="small"
                      bsStyle="primary"
                      className="rgSidrkBtn"
                    >
                      {this.props.spcmReducer.spcmPostingAttribute ? (
                        <ImSpinner6 className="icn-spinner" />
                      ) : null}{" "}
                      {tr["Save"]}
                    </Button>
                    <Button
                      title="Close"
                      onClick={() => {
                        this.props.componentFormFun4(false);
                      }}
                      bsSize="small"
                      bsStyle="text"
                      className="rgSiLigBtn"
                    >
                      {tr["Close"]}
                    </Button>
                  </ButtonToolbar>
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <div className="rSubhdTxt">Question Grouping</div>
              <Row>
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">
                      <span className="rStar"></span> {tr["Group Name"]}
                    </Form.Label>
                    <Field
                      component={_inputField}
                      name="CATEGORY_NAME"
                      className="form-control"
                      onpaste={(e) => {
                        this.countCat(e);
                      }}
                      onChange={(e) => {
                        this.countCat(e);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  {this.props.haveActiveQuestionsForCategory &&
                  (this.props.haveActiveQuestionsForCategory == "Yes" ||
                    this.props.haveActiveQuestionsForCategory == "yes") ? (
                    <Form.Group className="form-group">
                      <Form.Label bsClass="">
                        <span className="rStar"></span>{" "}
                        {tr["Visibility Sequence"]}
                      </Form.Label>
                      <Field
                        component={_number}
                        name="CATEGORY_SEQUENCE"
                        className="form-control"
                        min={1}
                        max={50}
                        disabled
                      />
                    </Form.Group>
                  ) : (
                    <Form.Group className="form-group">
                      <Form.Label bsClass="">
                        <span className="rStar"></span>{" "}
                        {tr["Visibility Sequence"]}
                      </Form.Label>
                      <Field
                        component={_number}
                        name="CATEGORY_SEQUENCE"
                        className="form-control"
                        min={1}
                        max={50}
                      />
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">{tr["Status"]}</Form.Label>
                    <Field
                      component={_dropDown}
                      name="CATEGORY_STATUS"
                      className="form-control"
                      onChange={(e) => {
                        this.setState({
                          categoryNameInitialStatus: e.target.value,
                        });
                      }}
                      options={[
                        { value: "Active", text: "Active" },
                        { value: "Inactive", text: "Inactive" },
                      ]}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <ButtonToolbar className="black">
                    <Button
                      title="Save"
                      disabled={this.props.spcmReducer.spcmPostingAttribute}
                      type="submit"
                      bsSize="small"
                      bsStyle="primary"
                      className="rgSidrkBtn"
                    >
                      {this.props.spcmReducer.spcmPostingAttribute ? (
                        <ImSpinner6 className="icn-spinner" />
                      ) : null}{" "}
                      {tr["Save"]}
                    </Button>
                    <Button
                      title="Close"
                      onClick={() => {
                        this.props.componentFormFun4(false);
                      }}
                      bsSize="small"
                      bsStyle="text"
                      className="rgSiLigBtn"
                    >
                      {tr["Close"]}
                    </Button>
                  </ButtonToolbar>
                </Col>
              </Row>
            </div>
          )}
        </form>
      </div>
    );
  }
}

ComponentForm = connect((state) => {
  return {
    formValues: getFormValues("serviceOrderChoicesForm")(state),
  };
})(ComponentForm);

const mapStateToProps = ({ spcmReducer, customDataSources }, state) => {
  if (spcmReducer.editLeftService.component_name) {
    spcmReducer.editLeftService.category_name_view =
      spcmReducer.editLeftService.category_name;
    spcmReducer.editLeftService.category_name =
      spcmReducer.editLeftService.category_id;
  }
  return {
    spcmReducer,
    initialValues:
      spcmReducer.editLeftId > 0 ? spcmReducer.editLeftService : "",
    haveActiveQuestionsForCategory:
      spcmReducer.editLeftId > 0
        ? spcmReducer.editLeftService.component_name
          ? null
          : spcmReducer.editLeftService.HAVE_ACTIVE_QUESTIONS_FOR_CATEGORY
        : null,
    customDataSources,
  };
};
ComponentForm = reduxForm({
  form: "serviceOrderChoicesForm",
  validate,
  enableReinitialize: true,
})(ComponentForm);

export default connect(mapStateToProps, {
  spcmLoader,
  setLeftEditId,
  getServiceDetailCounts,
  getServiceOrderChoices,
  getServiceMyQuestionLists,
  getConsumptionItems,
  getQuestionsCategory,
  getQuestionsActiveCategory,
  getDataSourceTypes,
  getReadOnlyResponseKeyTypesAndKeys,
  resetReducer,
  getCustomDataSources,
})(ComponentForm);
