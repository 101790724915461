/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Accordion,
  Tab,
  Tabs,
  ListGroup,
  ButtonToolbar,
  Button,
  Form,
  Alert,
} from "react-bootstrap";
import {
  Field,
  reduxForm,
  SubmissionError,
  change,
  formValueSelector,
  untouch,
} from "redux-form";
import { useDispatch, useSelector, connect } from "react-redux";
import { useNavigate } from "react-router";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import { ImSpinner6 } from "react-icons/im";

import { GLOBAL } from "_Globals";
import { loadOnCallSXMenulistData} from "_Actions/onCallSchedule/common/onCallCommonAction";
import {
  loadScheduleAssociationDataByScheduleId,
} from "_Actions/onCallSchedule/schedule/onCallScheduleAction";

import ListLoader from "../../../common/loaders/ListLoader.js";
import {
  _dateTimeField,
  _dateTimeFieldGeneral,
} from "../../../common/ReduxFormFields/formFields";
import {
  _textArea,
  _dropDown,
  _inputField,
  _checkbox,
  _number,
  _dynamicNamedropDown,
} from "../../../common/formFields.js";

const cookies = new Cookies();

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

const validate = (values) => {
  const errors = {};
  if (!values.scheduleName) {
    errors.scheduleName = "Please enter schedule name";
  }
  if (!values.scheduleType) {
    errors.scheduleType = "Please select schedule type";
  }
  if (!values.onCallStatus) {
    errors.onCallStatus = "Please select status";
  }
  if (!values.scheduleStartDate) {
    errors.scheduleStartDate = true;
  }
  if (!values.noEndDate && !values.scheduleEndDate) {
    errors.scheduleEndDate = true;
  }
  if (!values.scheduleRepeatType) {
    errors.scheduleRepeatType = "Please select repeat type";
  }
  return errors;
};

const ScheduleAssociationForm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const onCallCommonReducer = useSelector(
    (state) => state.on_call_common_reducer
  );
  const on_call_schedule_loader_reducer = useSelector(
    (state) => state.on_call_schedule_loader_reducer
  );
  const scheduleDetailsByScheduleId = useSelector(
    (state) => state.on_call_schedule_reducer.scheduleDetailsByScheduleId
  );

  const selector = formValueSelector("onCallScheduleAssociationForm");
  const scheduleRepeatTypeParam = useSelector((state) =>
    selector(state, "scheduleRepeatType")
  );

  const [saveLoader, setSaveLoader] = useState(false);
  const [allDaySchedule, setAllDaySchedule] = useState(false);
  const [noEndDate, setNoEndDate] = useState(false);
  const [repeatsOn, setRepeatsOn] = useState({
    SU: false,
    MO: false,
    TU: false,
    WE: false,
    TH: false,
    FR: false,
    SA: false,
  });
  const [repeatsOnError, setRepeatsOnError] = useState(false);

  let scheduleTypeValues = useSelector(
    (state) => state.on_call_common_reducer.scheduleType
  );
  // scheduleTypeValues = scheduleTypeValues.filter((item) => item.field1Key == 'Holiday' || item.field1Key == 'Workday');
  const disableCondition = !(
    props.addEditMode === "add" ||
    (props.addEditMode === "edit" &&
      scheduleDetailsByScheduleId?.onCallStatus === "Inactive")
  );

  useEffect(() => {
    dispatch(loadOnCallSXMenulistData("AIOSEM", "Frequency"));
    if (props.initialValues && props.initialValues.scheduleType) {
      dispatch(
        change(
          "onCallScheduleAssociationForm",
          "scheduleType",
          scheduleDetailsByScheduleId?.scheduleType
        )
      );
    } else {
      dispatch(
        change(
          "onCallScheduleAssociationForm",
          "scheduleType",
          scheduleDetailsByScheduleId.scheduleType
        )
      );
    }
      dispatch(
        change(
          "onCallScheduleAssociationForm",
          "onCallStatus",
          scheduleDetailsByScheduleId?.onCallStatus
        )
      );
    if (props.initialValues && props.initialValues.is24by7) {
      dispatch(change("onCallScheduleAssociationForm", "is24by7", true));
      setAllDaySchedule(true);
    } else {
      dispatch(change("onCallScheduleAssociationForm", "is24by7", false));
      setAllDaySchedule(false);
    }
    if (props.initialValues && props.initialValues.scheduleEndDate) {
      const isNoEndDate =
        moment(props.initialValues.scheduleEndDate).valueOf() === 0;
      if (isNoEndDate) {
        dispatch(change("onCallScheduleAssociationForm", "noEndDate", true));
        setNoEndDate(true);
      }
    }

    if (
      props.initialValues &&
      props.initialValues.byDay &&
      props.initialValues.byDay.length > 0
    ) {
      let arr = props.initialValues.byDay.split(",");
      let obj = { ...repeatsOn };
      arr.forEach((item) => {
        obj[item] = true;
      });
      setRepeatsOn(obj);
    }
  }, [scheduleDetailsByScheduleId]);

  function setRepeatsOnFun(val) {
    let obj = { ...repeatsOn };
    obj[val] = !obj[val];
    setRepeatsOn(obj);
  }

  function postPatchScheduleAssociation(values) {
    let repeatsOnValue = "";

    // Handle Specific Repeat Type
    if (values.scheduleRepeatType === "Specific") {
      for (let key in repeatsOn) {
        if (repeatsOn[key] === true) repeatsOnValue += `${key},`;
      }

      if (repeatsOnValue === "") {
        setRepeatsOnError(true);
        return false;
      } else {
        setRepeatsOnError(false);
        repeatsOnValue = repeatsOnValue.slice(0, -1); // Remove the trailing comma
      }
    } else {
      setRepeatsOnError(false);
    }

    // Validation for start and end dates
    if (
      !values.noEndDate &&
      moment(values.scheduleStartDate).isAfter(moment(values.scheduleEndDate))
    ) {
      Swal.fire(
        "Schedule start date cannot be greater than schedule end date!"
      );
      return false;
    }

    const commonObj = {
      sxScheduleId: props.scheduleId,
      scheduleAssociationName: scheduleDetailsByScheduleId.scheduleName,
      scheduleType: scheduleDetailsByScheduleId?.scheduleType,
      scheduleStatus: scheduleDetailsByScheduleId?.onCallStatus,
      scheduleRepeatType: values.scheduleRepeatType,
      scheduleRepeat: "Yes",
      byDay: repeatsOnValue,
      scheduleStartDate: moment(values.scheduleStartDate).valueOf(),
      repeatUntil: values?.scheduleEndDate
        ? moment(values.scheduleEndDate).valueOf()
        : null,
      is24by7: allDaySchedule,
      scheduleStartTime: allDaySchedule
        ? "00:00:00"
        : moment(values.scheduleStartDate).format("HH:mm:ss"),
      scheduleEndTime: allDaySchedule
        ? "23:59:59"
        : moment(values?.scheduleEndDate).format("HH:mm:ss"),
    };

    if (props.addEditMode === "add") {
      // Add mode specific logic
      const postObj = {
        ...commonObj,
        scheduleEndDate:
          !values.noEndDate && values?.scheduleEndDate
            ? moment(values.scheduleEndDate).valueOf()
            : 0,
      };

      setSaveLoader(true);

      axios
        .post("/rest/aiosem/saveScheduleAssociationAction", postObj)
        .then((response) => {
          if (response.status === 200) {
            const scheduleId=response.data.data.scheduleId;  
            setSaveLoader(false);
            props.setActionViewFun("scheduleAssociationList");
            dispatch(loadScheduleAssociationDataByScheduleId(scheduleId?.scheduleId));
          }
        })
        .catch((error) => {     
          setSaveLoader(false);
          swal({
            text: error?.response?.data?.businessMessage,
            button: translator["OK"],
          });
        });
    } else if (props.addEditMode === "edit") {
      // Edit mode specific logic
      const patchObj = {
        ...commonObj,
        scheduleEndDate:
          !values.noEndDate && values?.scheduleEndDate
            ? moment(values.scheduleEndDate, `${jwtdf} HH:mm:ss.SSS`).valueOf()
            : 0,
      };

      setSaveLoader(true);

      axios
        .patch(
          `/rest/aiosem/updateScheduleAssociationAction/${props.initialValues.saId}`,
          patchObj
        )
        .then((response) => {
          if (response.status === 200) {           
            const updateData = response?.data?.data?.scheduleId;
            const additionalData = {
              status: updateData?.status,
              scheduleId: updateData?.scheduleId,
              scheduleName: updateData?.scheduleName,
              scheduleType: updateData?.scheduleType,
              timeZone: updateData?.timeZone,
            };
            props.setActionViewFun("scheduleAssociationList");            
            Swal.fire(response?.data?.businessMessage || "Update action done successfully");
            return axios.patch(
              GLOBAL.updateMaintenanceWindow(updateData?.scheduleId),
              additionalData
            );            
          }
        })
        .catch((error) => {
          swal({
            text:
              error?.response?.data?.businessMessage || "An error occurred.",
            button: translator["OK"],
          });
        })
        .finally(() => {
          setSaveLoader(false);
        });
    }
  }

  
  if (on_call_schedule_loader_reducer.showScheduleListLoader) {
    return <ListLoader />;
  } else {
    return (
      <div className="stickyArea rBoxStyle position-absolute">
        <Row>
          <Col xs={12}>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                Schedule Duration
              </div>
            </div>
          </Col>
        </Row>
        <div className="rBoxGap">
          {props.error == undefined || props.error == null ? null : (
            <Alert variant="danger" className="errorMessageSec">
              <p>{props.error}</p>
            </Alert>
          )}
          <div>
            <Row>
              <Col md={12} sm={12} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label>
                    <span className="rStar"></span>
                    {translator["Type"]}
                  </Form.Label>
                  <Field
                    component={_dropDown}
                    name="scheduleType"
                    disabled
                    options={scheduleTypeValues}
                    className="form-control"
                  />
                </Form.Group>
              </Col>
              <Col md={12} sm={12} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label>
                    <span className="rStar"></span>
                    {translator["Status"]}
                  </Form.Label>
                  <Field
                    disabled
                    component={_dropDown}
                    name="onCallStatus"
                    options={[
                      {
                        menulistId: 9,
                        module: "CMDB",
                        field1Name: "Status",
                        field1Key: "Active",
                        field1Value: "1",
                        additionalInfo: null,
                        displayName: null,
                      },
                      {
                        menulistId: 10,
                        module: "CMDB",
                        field1Name: "Status",
                        field1Key: "Inactive",
                        field1Value: "0",
                        additionalInfo: null,
                        displayName: null,
                      },
                    ]}
                    className="form-control"
                  />
                </Form.Group>
              </Col>
              <Col md={12} sm={12} xs={12}>
                <div className="d-flex gap-4">
                  <Form.Group className="form-group">
                    <Field
                      name="is24by7"
                      component={_checkbox}
                      className="form-control"
                      label="All day schedule"
                      onChange={() => setAllDaySchedule(!allDaySchedule)}
                      checked={allDaySchedule}
                      disabled={disableCondition}
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Field
                      name="noEndDate"
                      component={_checkbox}
                      className="form-control"
                      label="No End Date"
                      // onChange={() => setNoEndDate(!noEndDate)}
                      onChange={() => {
                        const newValue = !noEndDate;
                        setNoEndDate(newValue);                  
                        if (noEndDate) {
                          dispatch(change("onCallScheduleAssociationForm", "scheduleEndDate", null));
                        } 
                      }}
                      checked={noEndDate}
                      disabled={disableCondition}
                    />
                  </Form.Group>
                </div>
              </Col>

              <Col md={12} sm={12} xs={12}>
                <Form.Group className="form-group">
                  <Form.Label>
                    <span className="rStar"></span>
                    {translator["Start Date"]}
                  </Form.Label>
                  <div className="dateTimeSdiv dateTimePosTop">
                    <Field
                      name="scheduleStartDate"
                      component={
                        allDaySchedule ? _dateTimeFieldGeneral : _dateTimeField
                      }
                      formType={
                        allDaySchedule ? "create" : "onCallScheduleDateTime"
                      }
                      className="form-control dateTimePickerField"
                      disabled={disableCondition}
                    />
                  </div>
                </Form.Group>
              </Col>
              {!noEndDate && (
                <Col md={12} sm={12} xs={12}>
                  <Form.Group className="form-group">
                    <Form.Label>
                      <span className="rStar"></span>
                      {translator["End Date"]}
                    </Form.Label>
                    <div className="dateTimeSdiv dateTimePosTop">
                      <Field
                        name="scheduleEndDate"
                        component={
                          allDaySchedule
                            ? _dateTimeFieldGeneral
                            : _dateTimeField
                        }
                        formType={
                          allDaySchedule ? "create" : "onCallScheduleDateTime"
                        }
                        className="form-control dateTimePickerField"
                        disabled={disableCondition}
                      />
                    </div>
                  </Form.Group>
                </Col>
              )}
              {scheduleDetailsByScheduleId.scheduleType != "Holiday" ? (
                <Col md={12} sm={12} xs={12}>
                  <Form.Group className="form-group">
                    <Form.Label>
                      <span className="rStar"></span>Repeats
                    </Form.Label>
                    <Field
                      component={_dropDown}
                      name="scheduleRepeatType"
                      disabled={disableCondition}
                      options={[
                        {
                          field1Key: "Daily",
                          field1Name: "Frequency",
                          field1Value: "5",
                        },
                        {
                          field1Key: "Weekly",
                          field1Name: "Frequency",
                          field1Value: "6",
                        },
                        {
                          field1Key: "Monthly",
                          field1Name: "Frequency",
                          field1Value: "7",
                        },
                        {
                          field1Key: "Yearly",
                          field1Name: "Frequency",
                          field1Value: "8",
                        },
                        {
                          field1Key: "Specific",
                          field1Name: "Frequency",
                          field1Value: "9",
                        },
                      ]}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
              ) : (
                ""
              )}
              {scheduleDetailsByScheduleId.scheduleType != "Holiday" &&
              scheduleRepeatTypeParam &&
              scheduleRepeatTypeParam == "Specific" ? (
                <Col md={12} sm={12} xs={12}>
                  <Form.Label>
                    <span className="rStar"></span>Repeats On
                  </Form.Label>
                  <div className="form-group btn-week">
                    <Button
                      title="Sunday"
                      bsSize="small"
                      bsStyle="text"
                      disabled={disableCondition}
                      className={
                        repeatsOn.SU ? "week-btn selected" : "week-btn"
                      }
                      onClick={() => {
                        setRepeatsOnFun("SU");
                      }}
                    >
                      SU
                    </Button>
                    <Button
                      title="Monday"
                      bsSize="small"
                      bsStyle="text"
                      disabled={disableCondition}
                      className={
                        repeatsOn.MO ? "week-btn selected" : "week-btn"
                      }
                      onClick={() => {
                        setRepeatsOnFun("MO");
                      }}
                    >
                      MO
                    </Button>
                    <Button
                      title="Tuesday"
                      bsSize="small"
                      bsStyle="text"
                      disabled={disableCondition}
                      className={
                        repeatsOn.TU ? "week-btn selected" : "week-btn"
                      }
                      onClick={() => {
                        setRepeatsOnFun("TU");
                      }}
                    >
                      TU
                    </Button>
                    <Button
                      title="Wednesday"
                      bsSize="small"
                      bsStyle="text"
                      disabled={disableCondition}
                      className={
                        repeatsOn.WE ? "week-btn selected" : "week-btn"
                      }
                      onClick={() => {
                        setRepeatsOnFun("WE");
                      }}
                    >
                      WE
                    </Button>
                    <Button
                      title="Thursday"
                      bsSize="small"
                      bsStyle="text"
                      disabled={disableCondition}
                      className={
                        repeatsOn.TH ? "week-btn selected" : "week-btn"
                      }
                      onClick={() => {
                        setRepeatsOnFun("TH");
                      }}
                    >
                      TH
                    </Button>
                    <Button
                      title="Friday"
                      bsSize="small"
                      bsStyle="text"
                      disabled={disableCondition}
                      className={
                        repeatsOn.FR ? "week-btn selected" : "week-btn"
                      }
                      onClick={() => {
                        setRepeatsOnFun("FR");
                      }}
                    >
                      FR
                    </Button>
                    <Button
                      title="Saturday"
                      bsSize="small"
                      bsStyle="text"
                      disabled={disableCondition}
                      className={
                        repeatsOn.SA ? "week-btn selected" : "week-btn"
                      }
                      onClick={() => {
                        setRepeatsOnFun("SA");
                      }}
                    >
                      SA
                    </Button>
                    <br />
                    {repeatsOnError ? (
                      <span style={{ color: "red", fontSize: "smaller" }}>
                        Please select atleast one day.
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              ) : (
                ""
              )}
            </Row>

            <Row>
              <Col md={12}>
                <ButtonToolbar className="black">
                  <Button
                    title="Save"
                    bsSize="small"
                    bsStyle="primary"
                    disabled={disableCondition}
                    onClick={props.handleSubmit(postPatchScheduleAssociation)}
                    className="rgSidrkBtn smallBtn"
                  >
                    {saveLoader ? <ImSpinner6 className="icn-spinner" /> : null}
                    {translator["Save"]}
                  </Button>
                  <Button
                    title="Close"
                    bsSize="small"
                    bsStyle="text"
                    className="rgSiLigBtn smallBtn"
                    onClick={() => {
                      props.setActionViewFun("scheduleAssociationList");
                    }}
                  >
                    {translator["Close"]}
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    form: "onCallScheduleAssociationForm",
    destroyOnUnmount: true,
    validate,
    enableReinitialize: false,
    // enableReinitialize: true
  })(ScheduleAssociationForm)
);
