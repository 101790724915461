/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/

import React, { useState, useCallback, useMemo } from "react";
import DataTable from "react-data-table-component";
import { Row, Col, Form, Button, ButtonToolbar, Nav } from "react-bootstrap";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { IoSearch, IoClose } from "react-icons/io5";
import Swal from "sweetalert2";
import axios from "axios";

import { GLOBAL } from "_Globals";
import { Get } from "../../../utils/axiosUtils";
import { useFetchData } from "../add-edit/formfield/hooks.js";
import { loadAllScheduleList,emptyScheduleLists } from "../../../actions/onCallSchedule/schedule/onCallScheduleAction";

const cookies = new Cookies();
const app_url = cookies.get("gph")?.replace("s:", "")?.split("~")[18] || "";
const cookiesData = cookies.get("gph")?.replace("s:", "")?.split("~") || [];

const onAssetRoleVisible = cookiesData[33]?.split(",")?.includes("38");
const onConfigManagerRoleVisible = cookiesData[33]?.split(",")?.includes("37");

export default function ScheduleMaintenance({
  values,
  ciGgroupId,
  refetch,
  groupData,
  formikref,
  treeview,
  setFieldValue,
  rightEditPanel,
  handleShowhide,
}) {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const allScheduleList = useSelector(
    (state) => state.on_call_schedule_reducer.allScheduleList
  );

  const [isList, setIsList] = useState(true);
  const [scheduleType, setScheduleType] = useState(
    "Maintenance Schedule"
  );
  const [scheduleName, setScheduleName] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const handleAttach = async (obj) => {
    if (!formikref?.current) {
      console.error("Formik reference is not available.");
      return;
    }
  
    const selectedScheduleId = obj?.scheduleId;
    if (!selectedScheduleId) {
      Swal.fire({
        text: "Invalid schedule selected.",
        confirmButtonText: "OK",
      });
      return;
    }
  
    try {
      // Fetch schedule association data
      const response = await Get(
        `${GLOBAL?.loadScheduleAssociationDataUrl}?scheduleId=${selectedScheduleId}&sort=createdOn,desc`
      );
  
      const scheduleData = response?.data?.data?.content;
  
      if (!Array.isArray(scheduleData) || scheduleData.length === 0) {
        Swal.fire({
          text: "Schedule details are unavailable for this maintenance window.",
          confirmButtonText: "OK",
        });
        return;
      }
  
      // Initialize MAINTENANCE_WINDOW if it doesn't exist
      if (!Array.isArray(groupData?.[0]?.MAINTENANCE_WINDOW?.schedule)) {
        groupData[0].MAINTENANCE_WINDOW = {
          group_id: groupData[0]?.GROUP_ID,
          schedule: [],
        };
      }
  
      // Check for duplicate schedule
      const isDuplicate = groupData[0].MAINTENANCE_WINDOW.schedule.some(
        (existingSchedule) => existingSchedule.scheduleId === selectedScheduleId
      );
  
      if (isDuplicate) {
        Swal.fire({
          text: "Maintenance schedule already attached.",
          confirmButtonText: "OK",
        });
        return;
      }
  
      // Prepare updated maintenance window data
      const updatedMWData = {
        group_name: groupData?.[0]?.GROUP_NAME,
        company_id: groupData?.[0]?.COMPANY_ID,
        company_name: groupData?.[0]?.COMPANY_NAME,
        class_id: groupData?.[0]?.CLASS_ID,
        status: groupData?.[0]?.STATUS,
        description: groupData?.[0]?.DESCRIPTION,
        is_external: groupData?.[0]?.IS_EXTERNAL,
        group_rules: groupData?.[0]?.GROUP_RULES,
        maintenance_window: {
          group_id: groupData[0]?.GROUP_ID,
          schedule: [
            ...groupData[0].MAINTENANCE_WINDOW.schedule,
            {
              status: obj.status,
              scheduleId: obj.scheduleId,
              scheduleName: obj.scheduleName,
              scheduleType: obj.scheduleType,
            },
          ],
        },
      };
  
      // Send the updated data to the server
      const updateResponse = await axios.patch(
        GLOBAL.updateCIGroup(groupData[0]?.GROUP_ID),
        updatedMWData
      );
  
      if (updateResponse?.status === 201) {
        Swal.fire({
          title: "Schedule attached successfully",
          confirmButtonText: "OK",
        });
      } else {
        console.warn("Unexpected status code:", updateResponse?.status);
      }
  
      refetch(); // Refetch data after successful update
      setIsList(true); // Update the UI state
    } catch (error) {
      console.error("Error during schedule attach:", error);
  
      Swal.fire({
        text: "An error occurred while submitting the form. Please try again.",
        confirmButtonText: "OK",
      });
  
      refetch(); // Refetch even in case of failure to reset the UI
      setIsList(true); // Update the UI state
    }
  };
  

  const onDeleteClick = async(saId) => {
    if (!groupData?.[0]?.MAINTENANCE_WINDOW) return;

    try {
      // Filter out the schedule to be deleted
      const updatedSchedule = groupData?.[0]?.MAINTENANCE_WINDOW?.schedule.filter(
        (item) => item.scheduleId !== saId
      );
       
      const updatedMWData = {
        group_name: groupData?.[0]?.GROUP_NAME,
        company_id: groupData?.[0]?.COMPANY_ID,
        company_name: groupData?.[0]?.COMPANY_NAME,
        class_id: groupData?.[0]?.CLASS_ID,
        status: groupData?.[0]?.STATUS,
        description: groupData?.[0]?.DESCRIPTION,
        is_external: groupData?.[0]?.IS_EXTERNAL,
        group_rules: groupData?.[0]?.GROUP_RULES,
        maintenance_window: {
          group_id: groupData[0]?.GROUP_ID,
          schedule: updatedSchedule,
        },
      };
  
      // Send the updated data to the server
      const updateResponse = await axios.patch(
        GLOBAL.updateCIGroup(groupData[0]?.GROUP_ID),
        updatedMWData
      );
  
      if (updateResponse?.status === 201) {
        Swal.fire({
          text: "The maintenance schedule has been deleted successfully.",
          confirmButtonText: "OK",
        });
  
        // Update Formik state
        if (formikref?.current) {
          formikref.current.setFieldValue("maintenance_window", updatedSchedule);
        }
  
        refetch(); // Refetch data after deletion
        setIsList(true); // Update the UI state
      } else {
        console.warn("Unexpected status code:", updateResponse?.status);
      }
    } catch (error) {
      console.error("Error during schedule delete:", error);
  
      Swal.fire({
        text: "An error occurred while deleting the schedule. Please try again.",
        confirmButtonText: "OK",
      });
  
      refetch(); // Ensure UI resets after failure
      setIsList(true); // Update the UI state
    }
  };

  const toggleFormAndList = () => {    
    dispatch(emptyScheduleLists());
    setIsList(!isList);
    setScheduleType("Maintenance Schedule");
  };

  const tableColumns = [
    {
      name: "Name",
      selector: (row) => row?.scheduleName || "",
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row?.scheduleType || "",
      sortable: true,
    },
    {
      name: "Timezone",
      selector: (row) => row?.timeZone || "",
      sortable: true,
    },
    { name: "Status", selector: (row) => row?.status || "", sortable: true },
    {
      name: "Actions",
      cell: (row) => (
        <i
          className="fa fa-chevron-circle-right f-size-16 theme1"
          onClick={() => handleAttach(row)}
          style={{ cursor: "pointer" }}
          title="Relate"
        ></i>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const attachedTableColumns = [
    {
      name: "Name",
      selector: (row) => row?.scheduleName || "",
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row?.scheduleType || "",
      sortable: true,
    },
    { name: "Status", selector: (row) => row?.status || "", sortable: true },
    {
      name: "Actions",
      cell: (row) => (
        <i
          className="fa fa-trash-o cursorPoint"
          onClick={() => onDeleteClick(row?.scheduleId)}
          style={{ color: "red", cursor: "pointer" }}
          title="Delete"
        ></i>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const searchData = () => {
    const filter = { scheduleType: scheduleType.trim() };
    if (scheduleName.trim()) filter.scheduleName = scheduleName.trim();
    dispatch(loadAllScheduleList(filter));
  };

  const customStyles = {
    headCells: {
      style: {
        "&:first-child": {
          width: "48px",
        },
      },
    },
    cells: {
      style: {
        "&:first-child": {
          minWidth: "48px !important",
        },
      },
    },
  };

  return (
    <div className="clDiv Ovrflow StTimeline">
      <Row>
        <Col>
          <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
            <ul>
              <li>
                {(onAssetRoleVisible || onConfigManagerRoleVisible) && (
                  <Button
                  // onClick={() => setIsList(!isList)}
                    onClick={toggleFormAndList}
                  >
                    <i
                      className={
                        isList ? "fa fa-plus f-size-16" : "fa fa-list f-size-16"
                      }
                    ></i>
                  </Button>
                )}
              </li>
              <li className="d-md-none">
                <button
                  type="button"
                  title="Minimize the right panel"
                  className="closerightPanelBtn"
                  onClick={() => rightEditPanel(false)}
                >
                  <IoClose />
                </button>
              </li>
            </ul>
          </div>
          <div className={treeview ? "rPageHeading d-flex" : "rPageHeading"}>
            <div className="offNam margin-t-5 margin-b-5">
              Maintenance Schedule
            </div>
            {treeview && (
              <div className="toprigLnk mt-0 ms-auto">
                <Nav className="icnlnk" as="ul">
                  <Nav.Item as="li">
                    <a className="border-none" onClick={handleShowhide}>
                      <i className="fa fa-close text-white" />
                    </a>
                  </Nav.Item>
                </Nav>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        {isList ? (
          <Row>
            <Col md={12}>
              <DataTable
                striped
                persistTableHead
                highlightOnHover
                className="rdt_Table"
                columns={attachedTableColumns}
                data={groupData?.[0]?.MAINTENANCE_WINDOW?.schedule || []}
                // onSelectedRowsChange={handleRowSelected}
                // clearSelectedRows={toggleCleared}
                pagination
              />
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>{translator["Type"]}</Form.Label>
                  <Form.Select
                    value={scheduleType}
                    onChange={(e) => setScheduleType(e.target.value)}
                  >
                    <option value="">{translator["Select"]}</option>
                    <option value="Maintenance Schedule">
                      Maintenance Schedule
                    </option>
                    <option value="Change Freeze Schedule">
                      Change Freeze Schedule
                    </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>{translator["Name"]}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Search here"
                    value={scheduleName}
                    onChange={(e) => setScheduleName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="margin-t-15">
              <Col md={12}>
                <ButtonToolbar>
                  <Button onClick={() => setIsList(true)}>
                    {translator["Back to List"]}
                  </Button>
                  <Button onClick={searchData}>
                    <IoSearch /> {translator["Search"]}
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
            {allScheduleList?.length > 0 && (
              <Row className="margin-t-15">
                <Col md={12}>
                  <DataTable
                    dense
                    striped
                    persistTableHead
                    highlightOnHover
                    pagination
                    title="Search Results"
                    className="rdt_Table"
                    columns={tableColumns}
                    data={allScheduleList}
                    // selectableRows
                    // contextActions={contextActions}
                    // onSelectedRowsChange={handleRowSelected}
                    // clearSelectedRows={toggleCleared}
                    // selectableRowsNoSelectAll={true}
                    // customStyles={customStyles}
                  />
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    </div>
  );
}
