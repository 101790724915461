
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { navigationHooks } from '../../helpers/NavigationHook';
import Swal from 'sweetalert2';
const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const getGroupList = (param) => {
    return (dispatch) => {
      dispatch({ type: 'FDN_DATA_FETCHING'})
      api.get("/api/Group")
          .then((response) => {
            // console.log("GrpAction1",response.data)
            dispatch({ type: 'GROUP_LIST_FETCHED_SUCCESSFULLY', payload: response.data});
            dispatch({ type: 'FDN_DATA_FETCHED_SUCCESS'})
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: 'FDN_DATA_FETCHED_FAILED'})
        });
    };
};

export const getInitialListGroupAction = () => {
    return (dispatch) => {
        axios.get("/api/getInitiallistGroup").then((resp) => {
        let values = [];
            const menuListoptions = resp.data.groupTypeList;
        if (typeof menuListoptions !== "undefined" && menuListoptions.length > 0) {
            menuListoptions.forEach((value) => {
            let val = { value: value.field1Value, text: value.field1Key };
            values.push(val);
          });
        }
        dispatch({
          type: "GROUP_INITIAL_LIST",
          payload: values,
        });
      });
    };
};


export const getcompanyMenuListAction = (companyId,modules,fields,status,businessFunId) => {
        let languageSelected = cookies.get("gph");
        languageSelected = languageSelected.split("~");
        languageSelected = languageSelected[20];
    return (dispatch) => {
      axios.get(`/api/company_menulist?companyId=${companyId}&module=${modules}&field=${fields}&status=${status}&language=${languageSelected}&businessFunctionId=${businessFunId}`)
        .then((resp) => {
          // console.log("CompanyMenuList", resp.data)
        let object = [];
        const menuListoptions = resp.data;
        if (typeof menuListoptions !== "undefined" && menuListoptions.length > 0) {
          menuListoptions.forEach((values) => {
            let val = { id: values.field1Value, label: values.field1Key };
            object.push(val);
          });
        }
        dispatch({
          type: "COMPANY_MENU_LIST_VALUES",
          payload: object,
        });
      });
    };
};

export function saveGroupAction(data, indivAssign, autoAssignAlgo,escalationPlan) {
    return (dispatch) => {
        api.post("/api/saveGroup", data)
            .then((response) => {
              if (response.status == 201) {
                  if (response.data.nameAlreadyExist === 'Name already exists') {
                    Swal.fire({
                      title: 'Group already exists',
                    })
                  } else {
                    let postAiosemData;
                    postAiosemData = {
                      groupId: response?.data?.group?.id,
                      status: response?.data?.group?.active == 1 ? "Active": "Inactive",
                      indivAsgmtEnbled: indivAssign,
                      companyId: parseInt(response?.data?.group?.companyId, 10),
                      asgmtAlgoType: indivAssign == 'Yes'? autoAssignAlgo : '',
                      escalationEnbled: escalationPlan
                    }
                    dispatch(saveAiosemAction(postAiosemData));
                    Swal.fire({
                      title: 'Group has been created',
                    })
                    navigationHooks.navigate("/editGroup/" + response?.data?.group?.id);
                  }
                } 
            })
            .catch((err) => {
                console.log("GrpErr", err)
            });
    };
}

export function updateGroupAction(data,individualAssignValue,autoAssignAlgo,aiosemApiCall,escalationPlan) {
    return (dispatch) => {
        api.post("/api/updateGroup", data)
            .then((response) => {
              if (response.status == 201) {
                if (response.data.nameAlreadyExist === 'Name already exists') {
                  Swal.fire({
                    title: 'Group already exists',
                  })
                }
                else {
                  if (aiosemApiCall == 'post') {
                    let group_id = response?.data?.group?.id;
                    let postAiosemData;
                    postAiosemData = {
                      groupId: response?.data?.group?.id,
                      status: response?.data?.group?.active == 1 ? "Active" : "Inactive",
                      indivAsgmtEnbled: individualAssignValue,
                      companyId: parseInt(response?.data?.group?.companyId, 10),
                      asgmtAlgoType: individualAssignValue == 'Yes' ? autoAssignAlgo : '',
                      escalationEnbled: escalationPlan
                    }
                    dispatch(saveAiosemAction(postAiosemData,group_id));
                  } else {
                    let group_id = response?.data?.group?.id;
                    let updateAiosemData;
                    updateAiosemData = {
                      status: response?.data?.group?.active == 1 ? "Active" : "Inactive",
                      indivAsgmtEnbled: individualAssignValue,
                      companyId: parseInt(response?.data?.group?.companyId, 10),
                      asgmtAlgoType: individualAssignValue == 'Yes' ? autoAssignAlgo : '',
                      escalationEnbled: escalationPlan
                    }
                    dispatch(updateAiosemAction(group_id, updateAiosemData));
                  }
                   let module = "GROUP";
                   
                   dispatch(getGroupApprovalHistory(response?.data?.group?.id, module));
                   
                  Swal.fire({
                    title: 'Group has been updated',
                  })
                  navigationHooks.navigate(`/editGroup/${response?.data?.group?.id}`);
                  dispatch(getEditGroupPageDetails(response?.data?.group?.id))
                }  
                } 
            })
            .catch((err) => {
                console.log("UpdateGrpErr", err)
            });
    };
  }

export const deleteGroupData = (id) => {
    return (dispatch) => {
      api.delete("/api/deleteGroup/"+id)
        .then((response) => {
          // console.log("DeletedGrop",response)
          Swal.fire({
            title: 'Record deleted successfully',
          }).then(result => {
            if (result.isDismissed || result.isConfirmed) {
              navigationHooks.navigate('/renderedView/674997f27a024fa4ee118a46')
            }
          })
        })
        .catch((err) => {
          console.log(err);
        });
    };
};

export const getEditGroupPageDetails = (id) => {
    return (dispatch) => {
        dispatch({ type: 'FDN_DATA_FETCHING'})
      api.get("/api/editGroup/"+id)
          .then((response) => {
            dispatch({ type: 'FDN_DATA_FETCHED_SUCCESS'})
              // console.log("GroupResponse", response.data.group)
            dispatch({ type: 'GROUP_PAGE_EDIT_DETAILS_FETCHED', payload: response.data.group });
        })
          .catch((err) => {
          dispatch({ type: 'FDN_DATA_FETCHED_FAILED'})
          console.log(err);
        });
    };
};


export const getForumListAction = (companyId) => {
    return (dispatch) => {
      axios.get(`/governance/rest/forumDetails/${companyId}`).then((resp) => {
        // console.log("ForumMenuList", resp)
        let object = [];
        const ForumMenuListoptions = resp.data;
        if (typeof ForumMenuListoptions !== "undefined" && ForumMenuListoptions.length > 0) {
            ForumMenuListoptions.forEach((values) => {
            let val = { id: values.forumId, label: values.forumName };
            object.push(val);
          });
        }
        dispatch({
          type: "FORUM_MENU_LIST_VALUES",
          payload: object,
        });
      });
    };
};


export const getCategoryListAction = () => {
    return (dispatch) => {
        axios.get("/governance/rest/categoryDetails").then((resp) => {
          // console.log("CategoryMenuList", resp)
        let object = [];
        const categoryMenuListoptions = resp.data;
        if (typeof categoryMenuListoptions !== "undefined" && categoryMenuListoptions.length > 0) {
            categoryMenuListoptions.forEach((values) => {
            let val = { id: values.fieldNameValue1, label: values.fieldNameKey1 };
            object.push(val);
          });
        }
        dispatch({
          type: "CATEGORY_MENU_LIST_VALUES",
          payload: object,
        });
      });
    };
};


export const getMembersDropdownValues = (companyId, groupid, searchKey) => {
    
    return (dispatch) => {
        // console.log("TestIDValues", companyId, groupid)
      api.get("/api/getMembersByCompanyIdNotAlreadyPartOfGroupId?companyId="+companyId+"&groupId="+groupid+"&likeKey="+searchKey)
          .then((response) => {
            // console.log("TestResp90",response)
          let groupListoptions = response.data;
          // let object = [];
          // console.log("dropdown123", groupListoptions)
          // if (typeof groupListoptions !== "undefined" && groupListoptions.length > 0) {
          //   groupListoptions.forEach((values) => {
          //     let val = { id: values.user_id, label: values.Full_Name };
          //     object.push(val);
          //   });
          // }
          dispatch({ type: 'GROUP_MEMBERS_FETCHED_SUCCESSFULLY', payload: groupListoptions});
        })
        .catch((err) => {
          console.log(err);
        });
    };
};
  
export const getAssociateRolesValues = (groupid) => {
    
    return (dispatch) => {
    
      api.get("/api/viewGroupRolesNotAlreadyAssociated/"+groupid)
          .then((response) => {
            // console.log("TestRoles50",response)
          let roleListoptions = response.data;
          let object = [];
          // console.log("dropdown123", roleListoptions)
          if (typeof roleListoptions !== "undefined" && roleListoptions.length > 0) {
            roleListoptions.forEach((values) => {
              let val = { id: values.role_id, label: values.name};
              object.push(val);
            });
              }
              // console.log("Roleobject", object)
          dispatch({ type: 'ASSOCIATE_ROLES_FETCHED_SUCCESSFULLY', payload: object});
        })
        .catch((err) => {
          console.log(err);
        });
    };
};

export const disassociateGroupMemberRow = (payload) => {
    return (dispatch) => {
      axios.post("/api/deleteGroupMemberAssociationViaAjax",payload)
        .then((response) => {
          // console.log("DeletedSuccess", response)
          dispatch({ type: 'ASSOCIATED_MEMBER_DELETED', payload: 'Row Deleted'});
          
        })
        .catch((err) => {
          console.log(err);
        });
    };
  };
  
  export const disassociateRolesRow = (data) => {
    return (dispatch) => {
      axios.post("/api/deleteGroupRolesAssociation", data)
        .then((response) => {
          // console.log("DeletedSuccess", response)
          dispatch({ type: 'ASSOCIATED_ROLES_DELETED', payload: 'Role Deleted'});
        })
        .catch((err) => {
          console.log(err);
        });
    };
};
  

export function saveAiosemAction(data,groupId) {
  // console.log("AiosemData",data)
  return (dispatch) => {
      api.post("/rest/aiosem/saveGroupAction", data)
          .then((response) => {
            // console.log("respomseAiosem+++",response);
            dispatch(getAiosemDetails(groupId));
          })
          .catch((err) => {
              console.log("GrpAiosErr", err)
          });
  };
}

export function updateAiosemAction(groupId,data) {
  return (dispatch) => {
      api.patch(`/rest/aiosem/updateGroupAction/${groupId}`, data)
          .then((response) => {
            // console.log("respomse+++",response);
            if (response.status == 201) {
              let moduleData = "AIOSEM";
              dispatch(getAiosemDetails(groupId));
              dispatch(getAiosemDataForAudit(groupId, moduleData));
                  // navigationHooks.navigate("/group");
                
              } 
          })
          .catch((err) => {
              console.log("UpdateGrpErr", err)
          });
  };
}

export const getAiosemDetails = (groupId) => {
  return (dispatch) => {
      dispatch({ type: 'FDN_DATA_FETCHING'})
    api.get(`/rest/aiosem/getGroupAction/${groupId}`)
        .then((response) => {
          dispatch({ type: 'FDN_DATA_FETCHED_SUCCESS'})
            // console.log("GroupResponseAIOSEM", response.data)
          dispatch({ type: 'AIOSEM_DATA_FOR_GROUP_FETCHED_SUCCESSFULLY', payload: response.data });
      })
        .catch((err) => {
        dispatch({ type: 'FDN_DATA_FETCHED_FAILED'})
        console.log(err);
      });
  };
};

export const getGroupApprovalHistory = (itemNumber,module)=>{
	var str={};
	str.item_number= itemNumber;
	str.module = module;
	str = JSON.stringify(str);
	return(dispatch) =>{
		api.get('/api/fetchApprovalHistory',{headers:{'query':str}})
      .then((approvalHistory) => {
        if (Array.isArray(approvalHistory.data)) {
          dispatch({ type: "GROUPS_ACTIVITY_DETAILS_FETCHED_SUCCESSFULLY", payload: approvalHistory.data })
        } else {
          dispatch({ type: "GROUPS_ACTIVITY_DETAILS_FETCHED_SUCCESSFULLY", payload: [] })
        }
			})
			.catch((err) => {
        dispatch({ type: "", failureMessage: err })
        dispatch({ type: "GROUPS_ACTIVITY_DETAILS_FETCHED_SUCCESSFULLY", payload: [] })
				console.log(err);
			});
	};
}
  
export const getAiosemDataForAudit = (recordId,module)=>{
  console.log("CheckInputValues", recordId, module);
  return (dispatch) => {
		api.get(`/rest/aiosem/getAuditAction/${recordId}/${module}`)
      .then((response) => {
        console.log("AiosemRespData", response.data.data);
        dispatch({ type: "AIOSEM_ACTIVITY_DETAILS_FETCHED_SUCCESSFULLY", payload: response.data.data })
			})
			.catch((err) => {
				dispatch({type:"",failureMessage:err})
        console.log(err);
			});
	};
  }