
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { TabContent, TabPane } from "react-bootstrap";

import GovAuditLogs from "./AuditLog";
import Attachment from "./attachment";
import ReasonComponent from "./ReasonComponent";

const RightSide = ({ editActionData, activeTabs,isHemStatus,onSubmitReason, rightEditPanel }) => {
  return (
    <TabContent activeTab={activeTabs}>
      {activeTabs === "1" ?
      <TabPane tabId="1" active={activeTabs === "1"}>
        <GovAuditLogs editActionData={editActionData} rightEditPanel={rightEditPanel} />
      </TabPane>
      : activeTabs === "2" ?
      <TabPane tabId="2" active={activeTabs === "2"}>
        <Attachment editActionData={editActionData} rightEditPanel={rightEditPanel} />
      </TabPane>
      : activeTabs === "10" ?
      <TabPane tabId="10" active={activeTabs === "10"}>
        <ReasonComponent editActionData={editActionData}  isHemStatus={isHemStatus} onSubmitReason={onSubmitReason} rightEditPanel={rightEditPanel}/>
      </TabPane>
      : null}
    </TabContent>
  );
};

export default RightSide;
