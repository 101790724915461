
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect } from "react";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { TimePicker } from '@progress/kendo-react-dateinputs';
import moment from "moment";
import Cookies from "universal-cookie";
import { Popup } from "@progress/kendo-react-popup";

const cookies = new Cookies();
let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));

dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

const DateTimePickerField = (props) => {
  const { attributes, onValueChange, defaultValue, readOnly, nonEditable, className, defaultValues, id } =
    props;
  const parsedDefaultValue = defaultValue
    ? moment(attributes?.type?.id === "timepicker"?`05-05-2025 ${defaultValue}`:defaultValue,`${jwtdf} HH:mm:ss`).toDate()
    : null;
  let format = attributes.format || `dd-MM-yyyy HH:mm:ss`;
  if(jwtdf === 'YYYY-MM-DD'){
    format = attributes.format || "yyyy-MM-dd HH:mm:ss";
  }else if(jwtdf === 'DD-MM-YYYY'){
    format = attributes.format || "dd-MM-yyyy HH:mm:ss";
  }else if(jwtdf === 'MM-DD-YYYY'){
    format = attributes.format || "MM-dd-yyyy HH:mm:ss";
  }
  if (attributes?.type?.id === "timepicker") {
     format = attributes.format || "HH:mm:ss";
  }

  useEffect(() => {
    if (attributes?.dependsOn) {
      if (defaultValues[attributes?.dependsOn]) {
        setTimeout(() => {
          onValueChange({
            value: moment(defaultValues[attributes?.dependsOn], jwtdf).add(1, 'day').format(
              `${jwtdf} HH:mm:ss`
            ),
          });
        }, 100);
      }
    }
  }, [defaultValues[attributes?.dependsOn]]);
  let minMax = {};
  let disableIfDependsOn = false;
  if(attributes?.validationType && !attributes?.dependsOn){
    if(attributes?.validationType?.id === 'disablePrevious'){
      minMax.min = new Date();
    }
  } else if(attributes?.validationType && attributes?.dependsOn){
    if(!defaultValues[attributes?.dependsOn]){
      disableIfDependsOn = true;
    }
    if(attributes?.validationType?.id === 'afterDepensOn'){
      minMax.min = moment(defaultValues[attributes?.dependsOn],jwtdf).add(1, 'day').toDate();
    }
  }
  const CustomPopup = props => {
    return <Popup {...props} 
      appendTo={document.getElementById("skipdv")}
      className={"k_dateTimePopup"}
    />
  };

  if (attributes?.type?.id === "timepicker") {
    return (
      <TimePicker
        value={parsedDefaultValue || null}
        onChange={(e) =>
          onValueChange({ value: moment(e.value).format(format) })
        }
        format={format}
        disabled={attributes.disabled || nonEditable || disableIfDependsOn}
        readOnly={readOnly}
        className={className}
      />
    );
  } else {
    return (
      <DateTimePicker
        value={parsedDefaultValue || null}
        onChange={(e) =>
          onValueChange({
            value: moment(e.value).format(`${jwtdf} HH:mm:ss`),
          })
        }
        format={format}
        disabled={attributes.disabled || nonEditable}
        readOnly={readOnly}
        className={className}
        id={id}
        popup={CustomPopup}
        {...minMax}
      />
    );
  }
};

export default DateTimePickerField;
