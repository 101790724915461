
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef ,useState } from "react";
import { useSelector } from "react-redux";
import Breadcrumbs from "_Commons/header/breadcrumbs";
import ConnectorList from "./list";
import { Container, Row, Col, Button,NavDropdown,ListGroup} from "react-bootstrap";
import { useNavigate } from "react-router";
import { HiPlus, HiOutlineRefresh } from "react-icons/hi";
import { CiImport,CiExport} from "react-icons/ci";
import Cookies from "universal-cookie";
import PindCategories from "_Commons/header/pind-categories";
import Dropzone from 'react-dropzone';
import { validateRestRequest } from "./utils";
import { ImSpinner6 } from "react-icons/im";
import { IoClose } from "react-icons/io5";
import Swal from "sweetalert2";
import axios from "axios";
const cookies = new Cookies();
const gph = cookies.get("gph").split("~");
const username = typeof gph[32] !== "undefined" ? gph[32] : "";
const userId = typeof gph[43] !== "undefined" ? gph[43] : "";
const userFullName = typeof gph[42] !== "undefined" ? gph[42] : "";
const roles = typeof gph[33] !== "undefined" ? gph[33].split(",") : [];
const attachmentSize = 120;
export default function ConnectorIndex() {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const navigate = useNavigate();
    const listRef = useRef();
      const [expBtnDisable, setExpBtnDisable] = useState(false);
  const [impBtnDisable, setImpBtnDisable] = useState(false);
  const [filesPreview, setFilesPreview] = useState([]);
  const [filesToBeSent, setFilesToBeSent] = useState([]);
  const [clickAttachButton, setClickAttachButton] = useState(0);
  const [printcount,setPrintcount] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allConnectorSelected, setAllConnectorSelected] = useState(false);
  const [filteredConnectors, setFilteredConnectors] = useState([]);
  const hosts = useSelector((state)=>state?.hosts);

  useEffect(() => {
    const style = document.createElement("style");
    if (roles.includes("70")) {
      style.innerHTML = `
      .catBordDv .rdt_TableCell:first-child {
        max-width: 40px !important;
        min-width: 40px !important;
      }
      .catBordDv .rdt_Table .rdt_TableCol:first-child {
        max-width: 40px !important;
        min-width: 40px !important;
      }
    `;
    }
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    if (filteredConnectors.length > 0) {
      setSelectedRows([]);
      setAllConnectorSelected(false);
     }
  }, [filteredConnectors]);

  useEffect(() => {
    if (selectedRows.length !== filteredConnectors.length) {
      setAllConnectorSelected(false);
    } else if (selectedRows.length === filteredConnectors.length) {
      setAllConnectorSelected(true);
    }
  }, [selectedRows]);

  const exportFile = () => {
    let requestOptions = {};
    requestOptions.host = hosts?.FORMENGINE_HOST;
    requestOptions.body ={type:"raw",value:{connectorIds:selectedRows.toString()}};
    requestOptions.url = "/export-connector";
    requestOptions.verb = "get";
    setExpBtnDisable(true);

    validateRestRequest(requestOptions)
      .then((res) => {
        const fileName = `connectors_${new Date().getTime()}`;
        const json = JSON.stringify(res?.data, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = href;
        setExpBtnDisable(false);
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.log("error", error);
        setExpBtnDisable(false);
      });
  };

  const removeFile = ()=> {
    setFilesToBeSent([])
    setFilesPreview([])
  }

 const onDrop = (acceptedFiles, rejectedFiles)=> {
    if (acceptedFiles.length > 0) {
    
      if (filesToBeSent.length < printcount) {
        let toBeSentFiles = [];
        toBeSentFiles.push(acceptedFiles);
        let previewFiles = [];
        for (let i in toBeSentFiles) {
          if (toBeSentFiles.hasOwnProperty(i)) {
            previewFiles.push(
            <ListGroup.Item bsPrefix=" ">
              <span className="float-r" style={{ cursor: 'pointer' }}
                  onClick={() => {
                    removeFile();
                  }}
                ><IoClose />
              </span>
              {toBeSentFiles[i][0].name}
            </ListGroup.Item>
          );
        }
      }
        setFilesPreview(previewFiles);
        setFilesToBeSent(toBeSentFiles);
      } else {
        Swal.fire({
          text: "File already attached.",
          button: "OK",
          width: 400,
        });
      }
    } else {
    }
  }

  const onDropRejected = (rejectedFiles)=> {
    if (rejectedFiles && rejectedFiles[0] && rejectedFiles[0].file && rejectedFiles[0].file.size && rejectedFiles[0].file.size > 1048576 * attachmentSize) {
      Swal.fire({
        text: "Please upload File up to " + attachmentSize + " MB size.",
        button: "OK",
        width: 400,
      });
    } else {
      Swal.fire({
        text: "File not supported.",
        button: "OK",
        width: 400,
      });
    }
  }

 const  handleClick = (event)=> {
    if (filesPreview.length > 0) {
      setClickAttachButton(1)
    }
    if (filesToBeSent.length > 0) {
      let formData = new FormData();
      const obj = {username, userFullName,userId}
      formData.append("attachment",JSON.stringify(obj));
      formData.append("file", filesToBeSent[0][0]);
      setImpBtnDisable(true);
      axios
        .post("/api/import-connector", formData)
        .then((response) => {
          if (response.status == 200) {
            Swal.fire({
              text: "File imported successfully.",
              button: "OK",
              width: 400,
            });
            setImpBtnDisable(false);
            setFilesToBeSent([]);
            setFilesPreview([]);
            setClickAttachButton(0);
          } else if (
            response.data.auth == "false" ||
            response.data.auth == false
          ) {
            Swal.fire({
              text: "This user does not have access to upload attachments.",
              button: "OK",
              width: 400,
            });
            setImpBtnDisable(false);
            setFilesToBeSent([]);
            setFilesPreview([]);
            setClickAttachButton(0);
          } else if(response?.statusCode !== 200){
            Swal.fire({
              text: "Some error occurred please check your json file!",
              button: "OK",
              width: 400,
            });
            setImpBtnDisable(false);
            setFilesToBeSent([]);
            setFilesPreview([]);
            setClickAttachButton(0);
          }
        })
        .catch((error) => {
          Swal.fire({
            text:
              tr["Please upload File up to "] +
              attachmentSize +
              tr[" MB size."],
            button: "OK",
            width: 400,
          });
          console.log("error in uploading file.");
          this.setState({ clickAttachButton: 0 });
        });
    } else {
      Swal.fire({
        text: tr["Please upload some files first"],
        width: 400,
        button: tr["OK"],
      });
    }
  }

    return (
        <div>
            <PindCategories />
            <div className="container-fluid margin-t-10 margin-b-15">
                <Breadcrumbs activePageName="Connectors" />
            </div>
            <Container fluid className="margin-t-10 margin-b-15">
                <Row className="margin-b-15">
                    <Col lg={6} md={6} sm={6} xs={12}>
                        <h1 className="sPageHeading1">
                            {"Connectors"}
                        </h1>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={12}>
                        <div className="paHedFilter">
                        <Button
                                bsPrefix=" "
                                type="button"
                                onClick={() => {
                                    navigate('/connectors/create')
                                }}
                                className="myBt plus"
                                title={tr["Create"]}
                            >
                                <HiPlus />
                            </Button>  
                        {roles.includes("70") && (
                <Button
                  type="button"
                  onClick={() => {
                    exportFile();
                  }}
                  bsPrefix=" "
                  id="addTheme"
                  className="myBt plus"
                  disabled={expBtnDisable}
                  title={"Export Connectors"}

                

                >
                  <span>
                    {expBtnDisable ? (
                      <ImSpinner6 className="icn-spinner" />
                    ) : (
                      <CiExport />
                    )}
                  </span>
                </Button>
              )}
              {roles.includes("70") && <NavDropdown
                className="myBt noArrow mybtImprt"
                bsPrefix=" "
                as="div"
                title={
                  <CiImport title={"Import"} style={{ strokeWidth: "1.2px" }} />
                }
              >
                <Dropzone
                  onDrop={(files) => onDrop(files)}
                  onDropRejected={(files) => onDropRejected(files)}
                  maxSize={1048576 * attachmentSize}
                  accept={{ "application/json": [".json"] }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzoneAreacss">
                      <div
                        className="form-control"
                        {...getRootProps()}
                        title={tr["Drop files or click here to upload."]}
                      >
                        <input {...getInputProps()} />
                        {tr["Drop files or click here to upload."]}
                      </div>

                      {filesPreview.length > 0 && (
                        <ListGroup bsPrefix=" " className="attachment-break">
                          {filesPreview}
                        </ListGroup>
                      )}

                      <div className="dropzoneAreacssSiz">
                        <span>Supported format: JSON</span>
                        {/* <span className="chld2">{"Max size"}: 2MB</span> */}
                      </div>
                      <div className="margin-t-5">
                        <button
                          bsPrefix=" "
                          title="Import Forms"
                          onClick={() => handleClick()}
                          disabled={impBtnDisable}
                        >
                          <span>
                            {impBtnDisable ? (
                              <ImSpinner6 className="icn-spinner" />
                            ) : (
                              "Import"
                            )}
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </NavDropdown>}
                            
                                  
                            <Button
                                bsPrefix=" "
                                title="Refresh"
                                type="button"
                                className="myBt"
                                onClick={() => { listRef.current?.refetch() }}
                            >
                                <HiOutlineRefresh className="refreshFlip" />
                            </Button>
                        </div>
                    </Col>
                </Row>
                <div className={roles.includes("70") ? "catBordDv hasRole" : "catBordDv"}>
                    <ConnectorList allConnectorSelected={allConnectorSelected} setAllConnectorSelected={setAllConnectorSelected} selectedRows={selectedRows} setSelectedRows={setSelectedRows} filteredConnectors={filteredConnectors} setFilteredConnectors={setFilteredConnectors} ref={listRef} navigate={navigate} roles={roles}></ConnectorList>
                </div>
            </Container>
        </div>
    )
}