
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListLoader from "../common/loaders/ListLoader";
import TableComponent from "../common/ReactTable/TableComponent";
import {
  loadDashboardList
} from "../../actions/dashboardConfigration/dashboardConfigAction.js";
import axios from "axios";

const CellName = (props) => {
  return (
    <div className="text-c">
      {props.row.active ? 'True' : 'False'}
    </div>
  );
};

const DashboardListView = (props) => {
 
  const dispatch = useDispatch()
  const [listData,setListData] = useState([])
  const showLoader = useSelector((state) => state.showLoader.loading);
  const translator = useSelector((state) => state.spcmReducer.tr);
  const dashboardDataList = useSelector(
    (state) => state.getDashboardReducerData?.dashboardConfigList
  );

  useEffect(()=>{
    dispatch(loadDashboardList())
  },[])
  useEffect(()=>{
    if(dashboardDataList && dashboardDataList.data.length>0){
      setListData(dashboardDataList.data)
    }
  },[dashboardDataList])

  const tableColumns = [
    {
      name: translator["Name"],
      selector: (row) => row.label,
      sortable: true,
    },
    {
      name: translator["Classification"],
      selector: (row) => row.classification,
      sortable: true,
    },
    {
      name: translator["Dashboard ID"],
      selector: (row) => row.dashboardId,
      sortable: true,
    },
    {
      name: translator["Report Type"],
      selector: (row) => row.reportType,
      sortable: true,
    },
   
    {
      name: translator["Is Active"],
      selector: (row) => row.active,
      sortable: true,
      cell: (row) => <CellName row={row} tr={translator} {...props} />,
    },
    {
      name: translator["Roles"],
      selector: (row) => row.roles,
      sortable: true,
    },
    {
      name: translator["Master Roles"],
      selector: (row) => row.masterRoles,
      sortable: true,
    },
    {
      name: translator["Report Filters"],
      selector: (row) => row.reportFilters,
      sortable: true,
    },
    
      {
        name: translator["Created By"],
        selector: (row) => row.createdBy,
        sortable: true,
      },
      {
        name: translator["Created Date"],
        selector: (row) => row.createdDate,
        sortable: true,
      },
      {
        name: translator["Modified By"],
        selector: (row) => row.modifiedBy,
        sortable: true,
      },
      {
        name: translator["Modified Date"],
        selector: (row) => row.modifiedDate,
        sortable: true,
      },
  ];

  const filterColumns = ["label", "classification", "reportType", "dashboardId",'active','roles','masterRoles','reportFilters'];
  const onRowSelect = (row) => {
    props.setShortDescValues(
      row.id,
      row.label,
      row.classification,
      row.dashboardId,
      row.reportType,
      row.active?'True':'False',
      row.roles,
      row.reportFilters,
      row.masterRoles,
      row.modifiedBy,
      row.modifiedDate,
     
    );
    props.showRightSide(true);
  };

  const renderDashboardTableList = () => {
    return (
      <div className="dtaTableDv integrateHubTable">
        <div className={props.rightDisplay ? "leftTrue srchPosTop reportmbSrchPos" : "rightTrue srchPosTop reportmbSrchPos"}>
          <TableComponent
            data={listData}
            columns={tableColumns}
            headerColumns={filterColumns}
            onRowSelect={onRowSelect}
            rightDisplay={props.rightDisplay}
            uniqueKey={'id'}
          />
        </div>
      </div>
    );
  };
  return(
    <div className="myOrderDiv">
        {showLoader? (
          <ListLoader />
         
        ) : (
          renderDashboardTableList()
        )}
             {/* {renderDashboardTableList(dashboardDataList)} */}
        </div>
  )
       
  
 }


export default DashboardListView;
