/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import Swal from "sweetalert2";
import axios from "axios";
import { Field, Formik, Form } from "formik";
import { QueryBuilder } from "react-querybuilder";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useQuery } from '@tanstack/react-query';

import { GLOBAL } from "_Globals";
import CmdbDropdownPlusTypeahead from "../common/CmdbDropdownPlusTypeahead.js";
import Breadcrumbs from "../../common/header/breadcrumbs";
import { customQueryFormatter } from "../../common/helper";
import AddEditCiGroup from "./add-edit";
import Header from "./header";
import QueryBuilderComponent from "./query-builder";
import ScheduleMaintenance from "./maintenanceSchedule.js";
import {toSQLExpression} from "./utils";

const initialQuery = {
  logic: "and",
  filters: [],
};

const Index = () => {
  const formikRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();

  const tr = useSelector((state) => state.spcmReducer.tr);

  const ciGgroupId = params?.groupId;
  const isAddMode = !ciGgroupId;

  const [isRightEditPanel, setIsRightEditPanel] = useState(true);
  const [fieldsKendo, setFieldsKendo] = useState([]);
  const [fields, setFields] = useState([]);
  const [query, setQuery] = useState(initialQuery);
  const [view, setView] = useState("conditions");

  const rightEditPanel = (value) => {
    setIsRightEditPanel(value);
  };


  const fetchGroupData = async () => {  
    const headerQuery = { query: JSON.stringify({ group_id: params?.groupId }) }; 
  
    const response = await axios.get(GLOBAL.getCiGroup, {
      headers: headerQuery, 
    });
  
    return response.data;
  };

  const { data:groupData, refetch } = useQuery(
    ['groupData', ciGgroupId], 
    fetchGroupData,
    {
      enabled: !!ciGgroupId,
      refetchOnWindowFocus:false
    }
  );

  const [initVals, setInitVals] = useState({
    group_name: "",
    company_id: "",
    company_name: "",
    // class_id: 0,
    status: "",
    description: "",
    is_external: false,   
    group_rules: {
      ci_condition: "",
      rules: {
        logic: "and",
        filters: []
      }
    }
  });

  let initialValues = {
    ...initVals,
  };

  const validationSchema = Yup.object().shape({
    group_name: Yup.string().required("Required"),
    // class_id: Yup.string().required("Required"),
    // is_external: Yup.string()
    //   .oneOf(["true", "false"], "Required")
    //   .required("Required"),
  });

  const validateRule = (rule) => {
    const errors = {};

    if ("rules" in rule) {
      rule.rules.forEach((subRule, index) => {
        const subRuleErrors = validateRule(subRule);
        if (Object.keys(subRuleErrors).length > 0) {
          errors[`rules[${index}]`] = subRuleErrors;
        }
      });
    } else {
      if (!rule.field || rule.field === "") {
        errors.field = "Field cannot be blank.";
      }
      if (!rule.operator || rule.operator === "") {
        errors.operator = "Operator cannot be blank.";
      }
      if (rule.operator !== "null" && rule.operator !== "!null") {
        const isValueBlank =
          typeof rule.value === "object"
            ? rule.value?.id === undefined ||
              rule.value?.id === "" ||
              rule.value?.id?.toString()?.trim().length === 0
            : rule.value === undefined ||
              rule.value === "" ||
              rule.value.trim().length === 0;

        if (isValueBlank) {
          errors.value = "Value cannot be blank.";
        }
      }
    }

    return errors;
  };

  const validateAllRules = (rules) => {
    const errors = rules
      .map((rule, index) => {
        const ruleErrors = validateRule(rule);
        return Object.keys(ruleErrors).length > 0
          ? { index, ruleErrors }
          : null;
      })
      .filter((error) => error !== null);

    return errors.length > 0 ? { rules: errors } : {};
  };

  const onSubmit = async (
    fields,
    { resetForm, setSubmitting, errors, setErrors }
  ) => {
    // console.log("sqlQuery====> fields", fields);
  //   const effectiveQuery = fields?.filter && fields?.filter?.filters?.length > 0 ? fields?.filter : null;
  //  const rules = fields?.filter && fields?.filter?.filters?.length > 0 
  // ? (() => {
  //     try {
  //       return toSQLExpression(effectiveQuery);
  //     } catch (error) {
  //       console.error("Error generating SQL expression:", error);
  //       return "";
  //     }
  //   })()
  // : "";
  const effectiveQuery = fields?.filter?.filters?.length > 0 
    ? fields.filter 
    : fields.group_rules?.rules || null;

  // Generate SQL expression or keep existing ci_condition
  const rules = fields?.filter?.filters?.length > 0
    ? (() => {
        try {
          return toSQLExpression(effectiveQuery);
        } catch (error) {
          console.error("Error generating SQL expression:", error);
          return fields.group_rules?.ci_condition || "";
        }
      })()
    : fields.group_rules?.ci_condition || "";
    
    const PostData = {
      ...fields,
    };

    if (isAddMode) {
      try {
        axios
          .post(GLOBAL.postCiGroup, PostData)
          .then(function (response) {
            if (response?.statusText === "OK") {
              Swal.fire({
                title: "CI Group created successfully",
                button: "OK",
              }).then((action) => {
                if (action.isDismissed || action.isConfirmed) {
                  navigate("/add-edit/ci-group/" + response.data.GROUP_ID);
                }
              });
            } else {
              setErrors({
                error: response?.data?.message || "Unknown error occurred",
              });
            }
          })
          .catch(function (error) {
            console.error(error);
            setErrors({
              error: error.response?.data?.message || "Unknown error occurred",
            });
          });
      } catch (err) {
        console.error(err?.data || "Error");
        Swal.fire({
          title:
            err?.data?.message ||
            "Oops! Something went wrong. Please try again.",
          button: "OK",
        });
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        const updateData = {
          ...PostData,
          group_rules: {
            ci_condition: rules,
            rules: effectiveQuery || { logic: "and", filters: [] }
          },
          filter:undefined
        };

        const response = await axios.patch(
          GLOBAL.updateCIGroup(ciGgroupId),
          updateData
        );

        if (response?.status === 201) {
          Swal.fire({
            title: "CI Group updated successfully",
            confirmButtonText: "OK",
          });
        } else {
          console.warn("Unexpected status code:", response?.status);
        }
      } catch (err) {
        console.error(err?.response?.data || "An error occurred");
      } finally {
        setSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (!isAddMode && groupData?.result?.length > 0) {
      console.log("groupdata====>",groupData)
    setInitVals((prev) => {
      return {
        ...prev,
        group_name: groupData?.result?.[0]?.GROUP_NAME,
        company_id: groupData?.result?.[0]?.COMPANY_ID,
        company_name: groupData?.result?.[0]?.COMPANY_NAME,
        class_id: groupData?.result?.[0]?.CLASS_ID,
        status: groupData?.result?.[0]?.STATUS,
        description: groupData?.result?.[0]?.DESCRIPTION,
        is_external: groupData?.result?.[0]?.IS_EXTERNAL,
        maintenance_window: groupData?.result?.[0]?.MAINTENANCE_WINDOW,
        group_rules: groupData?.result?.[0]?.GROUP_RULES || {
          ci_condition: "",
          rules: {
            logic: "and",
            filters: []
          }
        }
      };
    });

    if (groupData?.result?.[0]?.GROUP_RULES?.rules?.logic) {
      setQuery(groupData?.result?.[0]?.GROUP_RULES?.rules || initialQuery);
    }
  }
  }, [groupData, isAddMode]);

  return (
    <main>
      <Container fluid className="margin-b-15 margin-t-10">
        <Breadcrumbs
          activePageName={isAddMode ? "Create" : "Edit"}
          parentPageurl="/cmdblist"
          parentPageName="CI Groups"
        />
      </Container>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <Header
              isSubmitting={formikRef?.current?.isSubmitting}
              dirty={formikRef?.current?.dirty}
              submitForm={formikRef?.current?.handleSubmit}
              formikref={formikRef}
              errors={formikRef?.current?.errors}
              view={view}
              setView={setView}
              rightEditPanel={rightEditPanel}
              isAddMode={isAddMode}
            />
          </Col>
          <Col xs={12} md={12}>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={onSubmit}
            >
              {({
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values,
                dirty,
                resetForm,
                handleChange,
                isValid,
                submitCount,
              }) => {
                console.log("values", values);
                return (
                  <Form noValidate>
                    <PanelGroup direction="horizontal">
                      <Panel
                        id="sidebar"
                        minSize={33}
                        order={1}
                        defaultSize={50}
                        className={
                          isRightEditPanel
                            ? "isShowLeftPanel catBordDv"
                            : "catBordDv"
                        }
                      >
                        <AddEditCiGroup
                          setFieldValue={setFieldValue}
                          values={values}
                          isValid={isValid}
                          submitCount={submitCount}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          setFields={setFields}
                          setFieldsKendo={setFieldsKendo}
                        />
                      </Panel>
                      {isRightEditPanel ? (
                        <>
                          <PanelResizeHandle className="isShowLeftPanel resizeHandle">
                            <div className="outlne">
                              <div className="handIcn">
                                <i
                                  className="fa fa-chevron-left"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          </PanelResizeHandle>
                          <Panel minSize={33} order={2} defaultSize={45}>
                            {view === "conditions" && (
                              <QueryBuilderComponent
                                values={values}
                                fields={fields}
                                setFields={setFields}
                                fieldsKendo={fieldsKendo}
                                setFieldsKendo={setFieldsKendo}
                                setQuery={setQuery}
                                query={query}
                                editFormValues={ciGgroupId}
                                rightEditPanel={rightEditPanel}
                                setFieldValue={setFieldValue}
                                disabledinAddMode={isAddMode}
                                companyId={values.company_id}
                              />
                            )}
                            {view === "maintenance" && (
                              <div className="stickyArea rBoxStyle">
                                <ScheduleMaintenance
                                  values={values}
                                  translator={tr}
                                  module="CIGroup"
                                  rightEditPanel={rightEditPanel}
                                  setFieldValue={setFieldValue}
                                  disabledinAddMode={isAddMode}
                                  ciGgroupId={ciGgroupId}
                                  groupData={groupData?.result}
                                  formikref={formikRef}
                                  refetch={refetch}
                                />
                              </div>
                            )}
                          </Panel>
                        </>
                      ) : (
                        ""
                      )}
                    </PanelGroup>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default Index;
