
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import TaskEditHeader from "./Header.js";
import EditTaskForm from "./EditTaskForm.js";
import { Link } from "react-router-dom";
import ParentWorkItemDetails from "./ParentWorkItemDetails.js";
import TabPage from "./TabPage.js";
import LoadingIcons from "react-loading-icons";
import TaskExternalSystemAttributes from "./TaskExternalSystemAttributes.js";
import { GLOBAL } from "../../../Globals.js";
import Cookies from "universal-cookie";
import { fetchTmsWatcherDetails } from '../../../../actions/task/TaskDataAction';
import WatcherDetails from '../../../common/WorkItemBoard/watcher/WatcherDetails';
import { setWipSearchApply } from "../../../../actions/globalSearch/globalSearchAction.js";
import {loadModuleList} from "../../../../actions/breakFix/quickViewAction";
import { getFormValues } from 'redux-form';
import "_Css/form/_form.scss";

const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;
import {
  loadTaskItemToEdit,
  loadTaskGroup,
  loadAsignedTo,
  getEditTaskStatus,
  flushReducer,
  flushEditTaskDetails,
  flushGroupList,
  flushAssigntoList,
} from "../../../../actions/task/TaskDataAction";

import {loadChangeDetailsForTask,resetLoaderFlag} from '../../../../actions/changeManagement/changeManagementAction.js';
import XSMNotify from "./Notify.js";
import { connect } from "react-redux";
import { getTranslation } from "../../../../actions/spcmActions";
import { bindActionCreators } from "redux";

import XsmServiceDetails from "../../../common/WorkItemBoard/xsm-service-editquickView";
import XsmCiDetails from "../../../common/WorkItemBoard/xsm-ci-editquickView.js";
import XsmUserDetails from "../../../common/WorkItemBoard/xsmEditRequestedBy";
import TaskVariables from "../taskVariables/taskVariables.js";
import OnHoldTask from "../taskVariables/onHoldTask.js";
import CompletedTask from "../taskVariables/completedTask.js";
import axios from "axios";
import AttchmentTabTMS from "./AttachmentTMS.js";
import XSMSLA from "./Sla.js";

import swal from "sweetalert";
import Swal from "sweetalert2";

import { Field, reduxForm, formValueSelector } from "redux-form";
import WibSearchFulfillment from "../../fulfillment/wibSearch-fulfillment";
import { navigationHooks } from "../../../../helpers/NavigationHook.js";
import Breadcrumbs from "../../../common/header/breadcrumbs.js";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import TagDetails from "../../../common/tags/TagDetails.js";
import SLAProgress from './SLAProgress.js';
import AvailableIndividuals from "../../../common/WorkItemBoard/availableIndividuals/AvailableIndividuals.js";
const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});
let taskId;
let taskGroupId;
let taskCompanyId;

class EditTaskIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenu: "4",
      showHideAlfy: false,
      showHideRightNav: false,
      actionView: "",
      mobileLeftMenuFilter: false,
      service: 0,
      provideUserDetails: 0,
      offeringId: 0,
      ciId: 0,
      date_format: "",
      setAssignToListErrorColorState: "",
      setAssignmentGrpListErrorColorState: "",
      assignToListErrorColorParent: "",
      previousStatusId: "",
      userDetailsID: "",
      userDetailsLabel: "",
      groupID: "",
      role_id: "",
      changeImplentationRole: "",
      isRightEditPanel: true,
      full_name:'',
      notify_full_name:'',
      disableFieldsForGuestRole: false,
      selectedAssignmentGroup:[],
      selectedAssignmentUser:[],
      prevSelectedAction: "",
      showNotifyForm: true,
    };
    this.showHideAlfy = this.showHideAlfy.bind(this);
    this.showHideRightNav = this.showHideRightNav.bind(this);
    this.changeActionView = this.changeActionView.bind(this);
    this.props.getTranslation(this.props.lang);
    this.showServiceDetails = this.showServiceDetails.bind(this);
    this.showCiDetails = this.showCiDetails.bind(this);
    this.showConsumerDetails = this.showConsumerDetails.bind(this);
    this.setAssignToListErrorColor = this.setAssignToListErrorColor.bind(this);
    this.setAssignmentGrpListErrorColor =
      this.setAssignmentGrpListErrorColor.bind(this);
    this.assignToListErrorColorParentFunc =
      this.assignToListErrorColorParentFunc.bind(this);
    this.loadHome = this.loadHome.bind(this);
    this.setPreviousStatusId = this.setPreviousStatusId.bind(this);
    this.roleIdentification = this.roleIdentification.bind(this);
    this.params = navigationHooks.params;
    this.rightEditPanel=this.rightEditPanel.bind(this);
    this.roleCalculatorForGuestUser = this.roleCalculatorForGuestUser.bind(this);
    this.RefreshScreen = this.RefreshScreen.bind(this);
    this.setAssignmentGrpUsr = this.setAssignmentGrpUsr.bind(this);
    this.setReviewerErrorParent = this.setReviewerErrorParent.bind(this);
    this.showReviewerDetails = this.showReviewerDetails.bind(this);
    this.refreshAfterNotes = this.refreshAfterNotes.bind(this);
  }
  setPreviousStatusId(value) {
    this.setState({ previousStatusId: value });
  }
  loadHome() {
    navigationHooks.navigate(homepagelocation);
  }

  rightEditPanel(value) {
		this.setState({ isRightEditPanel: value });
	}

  showConsumerDetails(type, userId = "", label = "") {
    // console.log("inside : " + userId);
    if (!(userId && !isNaN(userId) && !!parseInt(userId, 10))) {
      return;
    }
    this.setState({ actionView: "" }, () => {
      GLOBAL.breakFixConsumerID = userId;
      this.setState({
        actionView: type,
        userDetailsID: userId,
        userDetailsLabel: label,
      });
    });
  }
  showReviewerDetails(type, userId = "", label = "") {
    this.setState({actionView: type, userDetailsID: userId, userDetailsLabel: label});  
  }
  assignToListErrorColorParentFunc(value) {
    this.setState({ assignToListErrorColorParent: value });
  }

  showServiceDetails(serviceId) {
    if (serviceId != 0) {
      this.setState({
        actionView: "showServiceDetails",
        offeringId: serviceId,
        user_id: "",
        date_format: "",
      });
    }
  }

  showCiDetails(ciId) {
    if (ciId != 0) {
      this.setState({
        actionView: "showCiDetails",
        ciId: ciId,
        user_id: "",
        date_format: "",
      });
    }
  }

  RefreshScreen(obj){
		Swal.fire({
			title:this.props.tr["Are you sure your want to refresh ?"],
			showDenyButton:true,
			confirmButtonText:this.props.tr['Yes'],
			denyButtonText:this.props.tr['No'],
		  }).then((result) => {
			if (result.isConfirmed) {
				try{
					this.refreshAfterNotes();
				} catch(e){
					console.error(e);
				}
			} else if (result.isDenied) {}
		  })
	}
  refreshAfterNotes(){
    this.props.flushGroupList();
    this.props.flushAssigntoList();
    this.setState({actionView:"processHierarchy"});
    this.props.loadAsignedTo(this.params.gId);
    this.props.getEditTaskStatus(this.params.taskId);
    this.props.loadTaskItemToEdit(this.params.taskId,this.state.isGuestUserRoleEnabled);
  }

  setAssignmentGrpUsr(selectd,type){
    switch(type){
      case "user":
        this.setState({selectedAssignmentUser:selectd});
        break;
      case  "group":
        this.setState({selectedAssignmentGroup:selectd});
        break;
    }
  }

  componentWillMount() {
    console.log('this.props - ', {props: this.props});
    this.props.resetLoaderFlag();
    this.props.flushGroupList();
    this.props.flushAssigntoList();
    this.props.loadModuleList();
    //this.props.loadTaskItemToEdit(this.params.taskId);
    this.props.loadAsignedTo(this.params.gId);

    taskId = this.params.taskId;
    taskCompanyId = this.params.cId;
    taskGroupId = this.params.gId;

    let str = {};
    str.user_id = "";
    str = JSON.stringify(str);
    api.get("/api/userInfo", { headers: { query: str } }).then((response) => {
      let changeUserid = "";
      if (
        response.data.role_id.split(",").includes("20") &&
        !response.data.role_id.split(",").includes("52")
      ) {
        changeUserid = "changeUserid";
      } else if (
        response.data.role_id.split(",").includes("52") &&
        !response.data.role_id.split(",").includes("20")
      ) {
        changeUserid = "impUserid";
      } else if (
        (response.data.role_id.split(",").includes("20") &&
          response.data.role_id.split(",").includes("52")) ||
        (response.data.role_id.split(",").includes("20") &&
          response.data.role_id.split(",").includes("51")) ||
        (response.data.role_id.split(",").includes("52") &&
          response.data.role_id.split(",").includes("19"))
      ) {
        changeUserid = "impUserid,changeUserid";
      } else if (
        response.data.role_id.split(",").includes("19") &&
        !response.data.role_id.split(",").includes("51")
      ) {
        changeUserid = "changeUserid";
      } else if (
        response.data.role_id.split(",").includes("51") &&
        !response.data.role_id.split(",").includes("19")
      ) {
        changeUserid = "impUserid";
      } else if (
        response.data.role_id.split(",").includes("19") &&
        response.data.role_id.split(",").includes("51")
      ) {
        changeUserid = "impUserid,changeUserid";
      }
      this.setState({
        user_id: response.data.user_id,
        role_id: response.data.role_id,
        date_format: response.data.dateformat,
        groupID: response.data.groupId,
        changeImplentationRole: changeUserid,
        full_name: response.data.user_name,
        notify_full_name: response.data.full_name,
        actionView: this.props.windowSize < 768 ? "" : "processHierarchy",
        isRightEditPanel: this.props.windowSize < 768 ? false : true,
      });
      this.props.loadTaskItemToEdit(this.params.taskId,false).then(responseData=>{
        const assineeDetails = {
          rolesList: response.data.role_id,
          groupList: response.data.groupId,
          userId: response.data.user_id,
        };
        if(responseData.workItemType === "Change" || responseData.workItemType === "changeManagement" || responseData.workItemType === "ChangeManagement"){
          this.props.loadChangeDetailsForTask(responseData.workItemId);
        }
        this.roleCalculatorForGuestUser(assineeDetails,responseData);
        this.props.getEditTaskStatus(this.params.taskId);
      }).catch(error => {
        console.log('Error in loadTaskItemToEdit() : ',error);
      });
    });

    if (GLOBAL.slaHeadEnable) {
      this.changeActionView('slaProgress');
      this.setState({
        actionView: 'slaProgress'
      })
      this.rightEditPanel(true);
    }
  }

  showHideAlfy(value) {
    this.setState({ showHideAlfy: value });
  }
  showHideRightNav(value) {
    this.setState({ showHideRightNav: !this.state.showHideRightNav });
  }

  mobileLeftMenuFilter() {
    this.setState({ mobileLeftMenuFilter: !this.state.mobileLeftMenuFilter });
  }

  changeActionView(actionSelected) {
    this.setState({
      actionView: actionSelected,
      prevSelectedAction: actionSelected,
    });

    if(actionSelected === 'notify'){
			this.setState({
				showNotifyForm:true
			})
		}

  }

  componentWillReceiveProps(newProps) {
    if (this.props.windowSize != newProps.windowSize) {
			if (newProps.windowSize < 768) {
				this.setState({ isRightEditPanel: false, actionView: "" });
			}
			else {
			  this.setState({ isRightEditPanel: true });      
        this.changeActionView(this.state.prevSelectedAction);
        if (this.state.actionView === "" && this.state.prevSelectedAction === "") {
          this.changeActionView("processHierarchy");
        }
			}
		}
    // console.log('newProps - ', {newProps, props: this.props});
    if (
      this.props.editTaskStatus.editTaskStatus !=
      newProps.editTaskStatus.editTaskStatus
    ) {
      if (newProps.editTaskStatus.editTaskStatus == false) {
        swal({
          text: this.props.tr["Status cannot be edited."],
          button: this.props.tr["OK"],
        });
      }
    }
    // if (this.params.taskId !== newProps.params.taskId) {
    //   this.props.flushGroupList();
    //   this.props.flushAssigntoList();
    //   this.props.loadTaskItemToEdit(newProps.params.taskId);
    //   //this.props.loadTaskGroup(this.params.cId);
    //   this.props.loadAsignedTo(newProps.params.gId);
    //   this.props.getEditTaskStatus(newProps.params.taskId);

    //   taskId = newProps.params.taskId;
    //   taskCompanyId = newProps.params.cId;
    //   taskGroupId = newProps.params.gId;

    //   let str = {};
    //   str.user_id = "";
    //   str = JSON.stringify(str);
    //   api.get("/api/userInfo", { headers: { query: str } }).then((response) => {
    //     let changeUserid = "";
    //     if (
    //       response.data.role_id.split(",").includes("20") &&
    //       !response.data.role_id.split(",").includes("52")
    //     ) {
    //       changeUserid = "changeUserid";
    //     } else if (
    //       response.data.role_id.split(",").includes("52") &&
    //       !response.data.role_id.split(",").includes("20")
    //     ) {
    //       changeUserid = "impUserid";
    //     } else if (
    //       (response.data.role_id.split(",").includes("20") &&
    //         response.data.role_id.split(",").includes("52")) ||
    //       (response.data.role_id.split(",").includes("20") &&
    //         response.data.role_id.split(",").includes("51")) ||
    //       (response.data.role_id.split(",").includes("52") &&
    //         response.data.role_id.split(",").includes("19"))
    //     ) {
    //       changeUserid = "impUserid,changeUserid";
    //     } else if (
    //       response.data.role_id.split(",").includes("19") &&
    //       !response.data.role_id.split(",").includes("51")
    //     ) {
    //       changeUserid = "changeUserid";
    //     } else if (
    //       response.data.role_id.split(",").includes("51") &&
    //       !response.data.role_id.split(",").includes("19")
    //     ) {
    //       changeUserid = "impUserid";
    //     } else if (
    //       response.data.role_id.split(",").includes("19") &&
    //       response.data.role_id.split(",").includes("51")
    //     ) {
    //       changeUserid = "impUserid,changeUserid";
    //     }
    //     this.setState({
    //       user_id: response.data.user_id,
    //       role_id: response.data.role_id,
    //       date_format: response.data.dateformat,
    //       groupID: response.data.groupId,
    //       changeImplentationRole: changeUserid,
    //     });
    //   });
    // }
    
  if((Object.keys(this.props.taskDetails).length>0 && this.props.taskDetails !== newProps.taskDetails)){
    if(typeof newProps.taskDetails.taskidforactivity !=="undefined"){
        this.props.fetchTmsWatcherDetails('Task',newProps.taskDetails.taskidforactivity);
       }
     }
  }

  componentWillUnmount() {
    this.props.flushReducer();
    this.props.flushEditTaskDetails();
  }

  setAssignToListErrorColor(val) {
    this.setState({ setAssignToListErrorColorState: val });
  }

  setAssignmentGrpListErrorColor(val) {
    this.setState({ setAssignmentGrpListErrorColorState: val });
  }

  showRightPaneAction(actionView) {
    let ref = this;
    const defaultRightPannel =  <TabPage translator={this.props.tr} taskIdactivity={this.props.taskDetails.taskidforactivity} taskCode={this.props.taskDetails.taskCode} taskDetails={this.props.taskDetails}
                                         date_format={this.state.date_format} role_id={this.state.role_id} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} changeActionView={this.changeActionView}/>;
    switch (actionView) {
      case "startImplementation":
        return defaultRightPannel;
      case "endImplementation":
        return defaultRightPannel;
      case "processHierarchy":
        return defaultRightPannel;
      case "viewParentItemDetails":
        return (
          <ParentWorkItemDetails
            taskDetails={this.props.taskDetails}
            role_id={this.state.role_id}
            translator={this.props.tr}
            rightEditPanel={this.rightEditPanel}
            changeActionView={this.changeActionView}
            disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
          />
        );
      case "viewExternalSystemAttributes":
        return (
          <TaskExternalSystemAttributes
            translator={this.props.tr}
            taskDetails={this.props.taskDetails}
            rightEditPanel={this.rightEditPanel}
            changeActionView={this.changeActionView}
          />
        );
      case "notify":
        return (
          <XSMNotify
            translator={this.props.tr}
            taskId={this.params.taskId}
            taskDetails={this.props.taskDetails}
            rightEditPanel={this.rightEditPanel}
            full_name={this.state.notify_full_name}
            disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
            changeActionView={this.changeActionView}
            showNotifyForm={this.state.showNotifyForm}
						setShowNotifyForm={(value) => {
							this.setState({showNotifyForm: value})
						}}
          />
        );
      case "showServiceDetails":
        return (
          <XsmServiceDetails
            offeringId={this.state.offeringId}
            translator={this.props.tr}
            rightEditPanel={this.rightEditPanel}
            changeActionView={this.changeActionView}
          />
        );
      case "showCiDetails":
        return (
          <XsmCiDetails ciId={this.state.ciId} translator={this.props.tr}
          rightEditPanel={this.rightEditPanel} changeActionView={this.changeActionView} />
        );
      case "consumer":
      case "individual":  
      case "reviewer":
        return (
          <XsmUserDetails
            translator={this.props.tr}
            userId={this.state.userDetailsID}
            userDetailsLabel={this.state.userDetailsLabel}
            rightEditPanel={this.rightEditPanel}
            changeActionView={this.changeActionView}
          />
        );
      case "showTaskVariables":
        return (
          <TaskVariables
            taskDetails={this.props.taskDetails}
            role_id={this.state.role_id}
            translator={this.props.tr}
            rightEditPanel={this.rightEditPanel}
            changeActionView={this.changeActionView}
            disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
          />
        );
      case "On Hold":
        if(this.state.previousStatusId==this.props.taskDetails.statusId){
          return defaultRightPannel;
        }
        return (
          <OnHoldTask
            taskDetails={this.props.taskDetails}
            taskId={taskId}
            cId={taskCompanyId}
            gId={taskGroupId}
            translator={this.props.tr}
            date_format={this.state.date_format}
            user_id={this.state.user_id}
            assignedToId={this.props.assignedToId}
            role_id={this.state.role_id}
            taskIdactivity={this.props.taskDetails.taskidforactivity}
            loadTaskItemToEdit={this.props.loadTaskItemToEdit}
            getEditTaskStatus={this.props.getEditTaskStatus}
            loadAsignedTo={this.props.loadAsignedTo}
            loadTaskGroup={this.props.loadTaskGroup}
            flushReducer={this.props.flushReducer}
            changeActionView={this.changeActionView}
            rightEditPanel={this.rightEditPanel}
          />
        );
      case "In Progress":
      case "In Review":
      case "Reviewed":    
      case "Completed":
      case "Cancelled":
        if(this.state.previousStatusId=='1' && this.props.taskDetails.statusId=='2' || this.state.previousStatusId == this.props.taskDetails.statusId){
          return defaultRightPannel;
        }  
        return (
          <CompletedTask
            taskDetails={this.props.taskDetails}
            translator={this.props.tr}
            date_format={this.state.date_format}
            user_id={this.state.user_id}
            assignedToId={this.props.assignedToId}
            previousStatusId={this.state.previousStatusId}
            rightEditPanel={this.rightEditPanel}
            changeActionView={this.changeActionView}
            refreshAfterNotes={this.refreshAfterNotes}
            taskStatusId={this.props.taskDetails.statusId}
          />
        );
      case "provideAttachments":
        return (
          <AttchmentTabTMS
            taskDetails={this.props.taskDetails}
            date_format={this.state.date_format}
            role_id={this.state.role_id}
            translator={this.props.tr}
            rightEditPanel={this.rightEditPanel}
            disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
            user_id={this.state.user_id}
            changeActionView={this.changeActionView}
          />
        );
      case "sla":
        return <XSMSLA translator={this.props.tr} rightEditPanel={this.rightEditPanel} changeActionView={this.changeActionView}/>;
      case "watcher":
        return <WatcherDetails taskCode={this.props.taskDetails.taskCode} itemId={this.props.taskDetails.taskidforactivity} translator={this.props.tr} itemDetails={this.props.taskDetails} status={this.props.editTaskStatus.statusName}  module="Task" rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} changeActionView={this.changeActionView}/>;
      case "tags":
        const isCancelledStatus = this.props.taskDetails.statusId == '4';
        const isTerminalStatus = isCancelledStatus;
        return (<TagDetails companyId={taskCompanyId} itemId={this.props.taskDetails.taskCode} itemDetails={this.props.taskDetails} module="Task" businessFunction="" mainModule="Task" userId={this.state.user_id} userName={this.state.full_name} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} isD2CRolesEnabled={this.state.isD2CRolesEnabled} isTerminalStatus={isTerminalStatus} rightEditPanel={this.rightEditPanel} changeActionView={this.changeActionView}/>);
      case "showAvailableIndividuals":
        return (<AvailableIndividuals setAssignmentGrpUsr={this.setAssignmentGrpUsr}  selectedAssignmentGroup={this.state.selectedAssignmentGroup} selectedAssignmentUser={this.state.selectedAssignmentUser} companyId={taskCompanyId} module="Task"mainModule="Task" disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}/>);
      case 'slaProgress':
        return (<SLAProgress tr={this.props.tr} rightEditPanel={this.rightEditPanel} taskId={this.props.taskDetails.taskCode} role_id={this.state.role_id} changeActionView={this.changeActionView}/>);
      default:
        return defaultRightPannel;
    }
  }
  roleIdentification(Rolevalue) {
    let user_roles = this.state.role_id.split(",");
    console.log("this.state.role_id", this.state.role_id);
    console.log("user_roles", user_roles);
    console.log("rolevalue", Rolevalue);
    let flag = false;

    for (let i = 0; i < user_roles.length; i++) {
      if (user_roles[i] == Rolevalue) {
        flag = true;
        break;
      }
    }
    if (flag) return true;
    else return false;
  }
  roleCalculatorForGuestUser(userDetails,taskDetails){
    const {rolesList,groupList,userId} = userDetails;
    const {workItemType,assignedToGroupId,assignedToId} = taskDetails;
    const roles = rolesList.split(",");
    const groups = groupList.split(",");
    const isAssignedToMyGroup = groups.includes(assignedToGroupId+'');
    const isAssignedToMe = userId==assignedToId;
    const myCircleAllocation = isAssignedToMe || isAssignedToMyGroup;

    const change_user_role_enabled = roles.includes('19');
    const change_manager_role_enabled = roles.includes('20');
    const implementation_user_role_enabled = roles.includes('51');
    const implementation_manager_role_enabled = roles.includes('52');
    const isChangedRolesEnabled = change_user_role_enabled || change_manager_role_enabled || implementation_user_role_enabled || implementation_manager_role_enabled;

    const problem_user_role_enabled = roles.includes('16');
    const problem_manager_role_enabled = roles.includes('17');
    const problem_investigator_role_enabled = roles.includes('53');
    const isProblemRolesEnabled = problem_user_role_enabled || problem_manager_role_enabled || problem_investigator_role_enabled;

    const incident_user_role_enabled = roles.includes('12');
    const incident_manager_role_enabled = roles.includes('13');
    const critical_incident_manager_enabled = roles.includes('14');
    const isIncidentRolesEnabled = incident_user_role_enabled || incident_manager_role_enabled || critical_incident_manager_enabled;

    const fulfillment_user_role_enabled = roles.includes('43');
    const fulfillment_manager_role_enabled = roles.includes('44');
    const isFulfillmentRolesEnabled = fulfillment_user_role_enabled || fulfillment_manager_role_enabled;

    const isServiceDeskRolesEnabled = roles.includes('48');
    const isTaskViewerRoleEnabled = roles.includes('61');

    const isD2CRolesEnabled = isChangedRolesEnabled || isProblemRolesEnabled || isIncidentRolesEnabled || isFulfillmentRolesEnabled;
    const isGuestUserRoleEnabled = !(isD2CRolesEnabled || isServiceDeskRolesEnabled) && isTaskViewerRoleEnabled;
 
    if(isGuestUserRoleEnabled){
      this.setState({disableFieldsForGuestRole: isGuestUserRoleEnabled, isD2CRolesEnabled});
      return;
    }

    const isChangeTask = workItemType === 'ChangeManagement' || workItemType === 'Change';
    const isProblemTask = workItemType === 'Investigation' || workItemType === 'Problem';
    const isIncidentTask = workItemType === 'Breakfix' || workItemType === 'Incident';
    const isFulfillmentTask = workItemType === 'Fullfilment';
    
    const enableChangeTask = (myCircleAllocation || isChangeTask) && (isChangedRolesEnabled);
    const enableProblemTask = (myCircleAllocation || isProblemTask)  && (isProblemRolesEnabled);
    const enableIncidentTask = (myCircleAllocation || isIncidentTask) && (isIncidentRolesEnabled);
    const enableFulfillmentTask = (myCircleAllocation || isFulfillmentTask) && (isFulfillmentRolesEnabled);

    const enableTask = enableChangeTask || enableProblemTask || enableIncidentTask || enableFulfillmentTask;

    const only_change_user_role_enabled = !change_manager_role_enabled && change_user_role_enabled && isChangeTask;
    const only_implementation_user_role_enabled = !implementation_manager_role_enabled && implementation_user_role_enabled && isChangeTask;
    const only_problem_user_role_enabled = !problem_manager_role_enabled && problem_user_role_enabled && isProblemTask;
    const only_incident_user_role_enabled = !incident_manager_role_enabled && !critical_incident_manager_enabled && incident_user_role_enabled && isIncidentTask;
    const only_fulfillment_user_role_enabled = !fulfillment_manager_role_enabled && fulfillment_user_role_enabled && isFulfillmentTask;
    
    const only_users_roles_enabled = only_change_user_role_enabled || only_implementation_user_role_enabled || only_problem_user_role_enabled || only_incident_user_role_enabled || only_fulfillment_user_role_enabled;
    
    const disableForUserWithDifferentGroup = only_users_roles_enabled && !isAssignedToMyGroup; 

    this.setState({disableFieldsForGuestRole: !enableTask || disableForUserWithDifferentGroup, isD2CRolesEnabled});
  }
  setReviewerErrorParent(errorValue){
    this.setState({reviewError: errorValue});
  }
  render() {
    console.log("79taskDetails",this.props.taskDetails);
    if (this.props.isFetching || this.props.isChangeDetailsFetching) {
      return (
        <div className="rctLoader">
          <LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
        </div>
      );
    }
    return (
      <div className="container-fluid margin-t-10">
        {this.state.role_id !== "" ? (
          <WibSearchFulfillment
            tr={this.props.tr}
            roleIdentification={this.roleIdentification}
            group_id={this.state.groupID}
            changeImplentationRole={this.state.changeImplentationRole}
          />
        ) : null}

        <div className="margin-t-10 margin-b-15"><Breadcrumbs activePageName="Edit" parentPageurl='/quickViewBreakFix' parentPageName='Work Item Board'/></div>

        <div bsClass="minHeightWIB">
          {/* Home Page blue bar Section Start*/}
          <TaskEditHeader
            translator={this.props.tr}
            taskDetails={this.props.taskDetails}
            changeActionView={this.changeActionView}
            user_id={this.state.user_id}
            role_id={this.state.role_id}
            date_format={this.state.date_format}
            taskId={this.params.taskId}
            cId={this.params.cId}
            gId={this.params.gId}
            setAssignToListErrorColor={this.setAssignToListErrorColor}
            setAssignmentGrpListErrorColor={this.setAssignmentGrpListErrorColor}
            assignToListErrorColorParentFunc={
              this.assignToListErrorColorParentFunc
            }
            setReviewerErrorParent={this.setReviewerErrorParent}
            userId={this.state.user_id}
            assignToListErrorColorParent={
              this.state.assignToListErrorColorParent
            }
            editTaskStatus={this.props.editTaskStatus}
            setPreviousStatusId={this.setPreviousStatusId}
            rightEditPanel={this.rightEditPanel}
            disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
            RefreshScreen={this.RefreshScreen}
            actionView={this.state.actionView}
          />
          {/* Home Page blue bar Section end*/}
          
          <PanelGroup direction="horizontal">
							<Panel id="sidebar" minSize={30} order={1} defaultSize={this.state.isRightEditPanel ? 67 : 100} className={this.state.isRightEditPanel ? "isShowLeftPanel" : ""}>
                <EditTaskForm
                  userId={this.state.user_id}
                  translator={this.props.tr}
                  taskId={this.params.taskId}
                  taskDetails={this.props.taskDetails}
                  showConsumerDetails={this.showConsumerDetails}
                  showServiceDetails={this.showServiceDetails}
                  showCiDetails={this.showCiDetails}
                  changeActionView={this.changeActionView}
                  editTaskStatus={this.props.editTaskStatus}
                  role_id={this.state.role_id}
                  setAssignToListErrorColorState={
                    this.state.setAssignToListErrorColorState
                  }
                  setAssignToListErrorColor={this.setAssignToListErrorColor}
                  setAssignmentGrpListErrorColorState={
                    this.state.setAssignmentGrpListErrorColorState
                  }
                  setAssignmentGrpListErrorColor={
                    this.setAssignmentGrpListErrorColor
                  }
                  assignToListErrorColorParentFunc={
                    this.assignToListErrorColorParentFunc
                  }
                  setReviewerErrorParent={this.setReviewerErrorParent}
                  reviewError={this.state.reviewError}
                  user_id={this.state.user_id}
                  showReviewerDetails={this.showReviewerDetails}
                  assignToListErrorColorParent={
                    this.state.assignToListErrorColorParent
                  }
                  rightEditPanel={this.rightEditPanel}
                  disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
                  setAssignmentGrpUsr={this.setAssignmentGrpUsr}  
                  selectedAssignmentGroup={this.state.selectedAssignmentGroup} 
                  selectedAssignmentUser={this.state.selectedAssignmentUser}
                />
							</Panel>
							{this.state.isRightEditPanel ?
								<>
								<PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
								<Panel minSize={33} order={2} defaultSize={33}>
                <div className="stickyArea rBoxStyle">
                  {/* Xsm BreakFix Action Section Start*/}
                  {this.showRightPaneAction(this.state.actionView)}
                  {/* Xsm BreakFix Action Section End*/}
                </div>
								</Panel>
								</> : ""
							}
						</PanelGroup>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    taskDetails: state.createTaskFormValues.editFormValues,
    tr: state.spcmReducer.tr,
    lang: state.spcmReducer.lang,
    editTaskStatus: state.editTaskStatus,
    isFetching: state.createTaskFormValues.isFetching,
    watcherDetailsFetched:state.watcherDetailsFetched,
    isChangeDetailsFetching: state.isFetchingDetails.isFetching,
    windowSize: state.windowSize.width,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTranslation,
      loadTaskItemToEdit,
      loadTaskGroup,
      loadAsignedTo,
      getEditTaskStatus,
      flushReducer,
      flushEditTaskDetails,
      flushGroupList,
      flushAssigntoList,
      fetchTmsWatcherDetails,
      setWipSearchApply,
      loadModuleList,
      loadChangeDetailsForTask,
      resetLoaderFlag
    },
    dispatch
  );
}

const selector = formValueSelector("editTaskForm");
EditTaskIndex = connect((state) => {
  let assignedToId = selector(state, "assignedToId");
  const editTaskFormValues = getFormValues('editTaskForm')(state);
  return {
    assignedToId,
    editTaskFormValues
  };
})(EditTaskIndex);

export default connect(mapStateToProps, mapDispatchToProps)(EditTaskIndex);
