
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import "react-querybuilder/dist/query-builder.css";
import { useDispatch, useSelector} from 'react-redux';
import { useGetAllServicesQuery, useGetAllCIsQuery, 
         useGetAllClassesQuery, useGetAllSupportGroupsQuery, 
         useGetAllCompaniesQuery, useGetAllReportedThroughQuery,
         useGetAllUrgencyAndImpantQuery } from "../../reducers/NLP-Related-Query/WorkItemBoardQuerries";
import { incidentFields, fulfillmentFields, initialQuery, incidentStatuses, fulfillmentStatuses } from "../../components/common/helper";
import { getQueryBasedOnUserTextBreakfix, getQueryBasedOnUserTextFulfillment, saveRetrivedBreakfixRecords, saveRetrivedFulfillmentRecords } from "../../components/common/helperForD2CQueryBuilder";

import { displayMessage } from "../../components/common/componentsForQueryBuilder";
import NlpSearchBarComponent from "../../components/common/WorkItemBoard/QueryComponent/NlpSearchBarComponent";
import QueryActionComponent from "../../components/common/WorkItemBoard/QueryComponent/QueryActionComponent";
import CustomQueryBuilder from "../../components/common/WorkItemBoard/QueryComponent/CustomQueryBuilder";
import Cookies from "universal-cookie";
const cookies = new Cookies();

let extractedCookiesValue = cookies.get("gph");
if (extractedCookiesValue) extractedCookiesValue = extractedCookiesValue.replace("s:", "");
if (extractedCookiesValue){
  extractedCookiesValue = extractedCookiesValue.substring(0, extractedCookiesValue.lastIndexOf("."));
}
extractedCookiesValue = extractedCookiesValue.split("~");

const languageSelected = extractedCookiesValue[20];
const companyId = extractedCookiesValue[48];

const ConditionBuilder = (props) => {
  const dispatch = useDispatch();
  const query = useSelector(state => state.queryData);
  const translator = useSelector(state => state.spcmReducer.tr);
  const [userQuery, setUserQuery] = useState('');
  const [fields, setFields] = useState(props.moduleName === 'breakfix' ? incidentFields : fulfillmentFields);
  const [isResult, setResult] = useState(false);
  const [maxLength, setMaxLength] = useState(1024);

  const handleOnUserQueryChange = (query) => {
    setUserQuery(query.target.value);
  }

  const retriveFulfillmentQuerybasedOnUserQuery = async () => {
    if(userQuery.trim().length===0){
       displayMessage(translator['Please enter a valid search string'], translator['OK']);
       return;
    }
    try {
      dispatch({type: 'PRODUCED_QUERY', payload: initialQuery});
      dispatch({type: 'FINAL_QUERY', payload: initialQuery});
      dispatch({ type: 'ON_AI_SEARCH_LOADER' });
      const retrivedQuery = await getQueryBasedOnUserTextFulfillment(props.moduleName, props.companyId, userQuery);
      dispatch({ type: 'OFF_AI_SEARCH_LOADER' });
      if(retrivedQuery){
        dispatch({type: 'PRODUCED_QUERY', payload: retrivedQuery});
        dispatch({type: 'FINAL_QUERY', payload: retrivedQuery});
      } else {
        displayMessage(translator['Failed to retrieve query, Please try again'], translator['OK']);
      }
    }catch(error){
      dispatch({ type: 'OFF_AI_SEARCH_LOADER' });
      console.error('Error fetching query:', error);
    }  
  }

  const retriveBreakfixQuerybasedOnUserQuery = async () => {
    if(userQuery.trim().length===0){
       displayMessage(translator['Please enter a valid search string'], translator['OK']);
       return;
    }
    try {
      dispatch({type: 'PRODUCED_QUERY', payload: initialQuery});
      dispatch({type: 'FINAL_QUERY', payload: initialQuery});
      dispatch({ type: 'ON_AI_SEARCH_LOADER' });
      const retrivedQuery = await getQueryBasedOnUserTextBreakfix(props.moduleName, props.companyId, userQuery);
      dispatch({ type: 'OFF_AI_SEARCH_LOADER' });
      if(retrivedQuery){
        dispatch({type: 'PRODUCED_QUERY', payload: retrivedQuery});
        dispatch({type: 'FINAL_QUERY', payload: retrivedQuery});
      } else {
        displayMessage(translator['Failed to retrieve query, Please try again'], translator['OK']);
      }
    }catch(error){
      dispatch({ type: 'OFF_AI_SEARCH_LOADER' });
      console.error('Error fetching query:', error);
    }  
  }

  const retrieveBreakfixRecordOnQuery = async () => {
    if(query.rules.length === 0){
      displayMessage(translator['Please create some rules to retrieve the records'], translator['OK']);
      return;
    }
    try {
      props.showRightSide(false);
      {props.moduleName === 'breakfix' && await saveRetrivedBreakfixRecords(dispatch, query, 0, 10, 'desc')}
      {props.moduleName === 'fullfilment' && await saveRetrivedFulfillmentRecords(dispatch, query, 0, 10, 'desc')}
    }catch(error){
      console.error('error : ',error.message);
      displayMessage(translator['All fields are required'], translator['OK']);
    } 
  };

  const retriveQuerybasedOnUserQuery = () => {
    if(props.moduleName === 'breakfix'){
      retriveBreakfixQuerybasedOnUserQuery();
    }else{
      retriveFulfillmentQuerybasedOnUserQuery();
    }
  }

  useEffect(() => {
      if(props.moduleName === 'breakfix'){
        dispatch(({type: 'SET_ALL_STATUSES', payload: incidentStatuses}));
      }else{
        dispatch(({type: 'SET_ALL_STATUSES', payload: fulfillmentStatuses}));
      }
  }, []);

  const { data: supportGroups } = useGetAllSupportGroupsQuery(props.companyId);

  useEffect(() => { 
    if(typeof supportGroups === 'undefined' || supportGroups.length === 0 || supportGroups === null){
      dispatch(({ type: 'SET_ALL_CIM_GROUPS', payload: [] }));
      dispatch(({ type: 'SET_ALL_SUPPORT_GROUPS', payload: [] }));
      return;
    }
    const  supportGroupList = supportGroups.map(retrivedSupportGroup => { return {id: retrivedSupportGroup.groupId, label: retrivedSupportGroup.groupName} });
    dispatch({ type: 'SET_ALL_CIM_GROUPS', payload: supportGroupList });
    dispatch({ type: 'SET_ALL_SUPPORT_GROUPS', payload: supportGroupList });
  }, [supportGroups]);

  const { data: allServices } = useGetAllServicesQuery({query: JSON.stringify({ moduleType: props.moduleName === 'breakfix' ? 'incident' : 'serviceRequest', companyId: props.companyId}) }, { skip: !props.moduleName, refetchOnMountOrArgChange: true});

  useEffect(() => {
     if(typeof allServices === 'undefined' || allServices.length === 0 || allServices === null){
       dispatch(({ type: 'SET_ALL_SERVICES', payload: [] }));
       return;
     }
     const serviceList = allServices.map(retrivedService => { return {id: retrivedService.OFFERING_ID, label: retrivedService.OFFERING_NAME} });
     dispatch({ type: 'SET_ALL_SERVICES', payload: serviceList });
  },[allServices])

  const { data: allCIs } = useGetAllCIsQuery({searchList: 'STATUS,SUB_STATUS', multipleValueList: '7|12'});

  useEffect(() => {
      if(typeof allCIs === 'undefined' || allCIs.length === 0 || allCIs === null){
        dispatch(({ type: 'SET_ALL_CIs', payload: [] }));
        return;
      }
      const ciList = allCIs.map(retrieveCI => { return {id: retrieveCI.CI_ID, label: retrieveCI.CI_NAME, companyName: retrieveCI.COMPANY_NAME, className: retrieveCI.CLASS_NAME} });
      dispatch({ type: 'SET_ALL_CIs', payload: ciList});
  }, [allCIs]);

  const { data: allClasses } = useGetAllClassesQuery();

  useEffect(() => {
     if(typeof allClasses === 'undefined' || allClasses.length === 0 || allClasses === null){
       dispatch(({ type: 'SET_ALL_CLASSES', payload: [] }));
       return;
     }
     const classList = allClasses.map(retrivedClass => { return {id: retrivedClass.CLASS_ID, label: retrivedClass.CLASS_NAME} }); 
     dispatch({ type: 'SET_ALL_CLASSES', payload: classList });
  }, [allClasses]); 

  const {data: allCompanies } = useGetAllCompaniesQuery();
  useEffect(() => { 
    if(typeof allCompanies === 'undefined' || allCompanies.length === 0 || allCompanies === null){
       dispatch(({ type: 'SET_ALL_COMPANIES', payload: [] }));
       return;
    }
    const companyList = allCompanies.map(retrivedCompany => { return {id: retrivedCompany.field1Value, label: retrivedCompany.field1Key} });
    dispatch(({ type: 'SET_ALL_COMPANIES', payload: companyList }));
  }, [allCompanies]);

  const { data: allReportedThrough } = useGetAllReportedThroughQuery();

  useEffect(() => { 
    if(typeof allReportedThrough === 'undefined' || allReportedThrough.length === 0 || allReportedThrough === null){
      dispatch(({ type: 'SET_ALL_REPORTED_THROUGH_BREAKFIX', payload: [] }));
      dispatch(({ type: 'SET_ALL_REPORTED_THROUGH_FULFILLMENT', payload: [] }));
      return;
    }
    const reportedThroughListBreakfix = allReportedThrough.filter(retrivedReportedThrough => retrivedReportedThrough.field1Value !== '55' && retrivedReportedThrough.field1Value !== '70' && props.moduleName === 'breakfix').map(retrivedReportedThrough => ({id: retrivedReportedThrough.field1Value, label: retrivedReportedThrough.field1Key}));
    const reportedThroughListFulfillment = allReportedThrough.filter(retrivedReportedThrough => retrivedReportedThrough.field1Value !== '55' && retrivedReportedThrough.field1Value !== '70' && retrivedReportedThrough.field1Value !== '80' && props.moduleName === 'fullfilment').map(retrivedReportedThrough => ({id: retrivedReportedThrough.field1Value, label: retrivedReportedThrough.field1Key}));
    dispatch(({ type: 'SET_ALL_REPORTED_THROUGH_BREAKFIX', payload: reportedThroughListBreakfix }));
    dispatch(({ type: 'SET_ALL_REPORTED_THROUGH_FULFILLMENT', payload: reportedThroughListFulfillment }));
  }, [allReportedThrough]);

  const details = {
    companyId, moduleName: 'Breakfix', fieldName: 'Impact,Urgency', languageSelected, status: '1'
  };
  
  const { data: allUrgencyImpact } = useGetAllUrgencyAndImpantQuery(details); 

  useEffect(() => {
    if(typeof allUrgencyImpact === 'undefined' || allUrgencyImpact.length === 0 || allUrgencyImpact === null){
      return;
    } 
    let urgencyList = [], impactList = [];
    allUrgencyImpact.map((item)=>{
      if(item.field2Name==='Impact'){
        impactList.push({id: item.field1Value, label: item.field1Key});
		  }else{
        urgencyList.push({id: item.field1Value, label: item.field1Key});
		  }
	  });
    dispatch({type: 'SET_ALL_URGENCY', payload: urgencyList});
    dispatch({type: 'SET_ALL_IMPACT', payload: impactList});
  }, [allUrgencyImpact]);
  const isNLPEnabledITM = props.moduleName === 'fullfilment' && props.isAiITMTypeENV;
  const isNLPEnabledINC = props.moduleName === 'breakfix' && props.isAiINCTypeENV;
  return (
    <div>
      {(isNLPEnabledITM || isNLPEnabledINC) && <NlpSearchBarComponent isAiINCTypeENV={props.isAiINCTypeENV} isAiITMTypeENV={props.isAiITMTypeENV} handleOnUserQueryChange={handleOnUserQueryChange} retriveQuerybasedOnUserQuery={retriveQuerybasedOnUserQuery} maxLength={maxLength} moduleName={props.moduleName}/>}
      <div className="position-re">
        <QueryActionComponent retrieveBreakfixRecordOnQuery={retrieveBreakfixRecordOnQuery} showConditionBuilder={props.showConditionBuilder} />
        <CustomQueryBuilder fields={fields} query={query} setResult={setResult} moduleName={props.moduleName}/>
      </div>
    </div>
  );
};

export default ConditionBuilder;
