
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "universal-cookie";
import { Formik, Form } from "formik";
import { useParams } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { GLOBAL } from "_Globals";
import {useUserInfo} from "../add-edit/formfield/hooks";
import Breadcrumbs from "../../common/header/breadcrumbs";
import CmdbProcessHierarchy from "../create/CmdbProcessHierarchy";
import { getTimelineData } from "../../../actions/cmdb/cmdbTimelineAction";
import { navigationHooks } from "../../../helpers/NavigationHook";
import {loadCIEditDetails} from "../../../actions/cmdb/lovAction";
import Header from "./header";
import AddEditCmdb from "./addEditCmdb";
import "_Css/form/_form.scss";

const cookies = new Cookies();

let cookiesData = cookies.get('gph');
if (cookiesData) cookiesData = cookiesData.replace('s:', '');
if (cookiesData) cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf('.'));
cookiesData = cookiesData.split("~");
let dateformat = cookiesData[2];

const Index = () => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const params = useParams();
  const userInfo = useUserInfo();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const cIEditDetails = useSelector((state) => state.CIEditDetails);

  const ciId = params?.ciId;
  const isAddMode = !ciId;

  const [isRightEditPanel, setrightEditPanel] = useState(true);
  const [initVals, setInitVals] = useState({
    ci_name: "",
    company_id: "",
    company_name: "",
    class_id: "",
    class_name: "",
    type: "",
    category_id: "",
    category_name: "",
    sub_category_id: 0,
    sub_category_name: "",
    ci_id: "",
    asset_tag: "",
    serial_number: "",
    status: "",
    status_name: "",
    sub_status: "",
    description: "",
    manufacturer: "",
    model_name: "",
    model_number: "",
    metallic: "",
    compliance: null,
    location_id: "0",
    location_name: "",
    environment: "",
    keyword: "",
    is_external: "false",  
    business_owner_id: 0,
    business_owner_name: "",
    technical_owner_id: 0,
    technical_owner_name: "",
    owner_id: 0,
    owner_name: "",
    department_id: "0",
    department_name: "",
    change_group_id: "0",
    change_group_name: "",
    availaibility_percentage: "",
    support_company_id: "0",
    support_company_name: "",
    group_id: "0",
    group_name: "",
    owned_by_id: "0",
    owned_by: "",
    ownership_type_id: "0",
    order_date: undefined,
    acquisition_cost: "",
    cost_center_id: "0",
    cost_center: "",
    purchase_order_number: "",
    invoice_number: "",
    cost_currency_id: "",
    general_ledger_account: "",
    net_book_value: "",
    depreciation_charged_id: "",
    depreciation_charged_name:"",
    depreciation_schedule: "",
    vendor_id: "0",
    vendor: "",
    currency_id: "0",
    charge_category_id: "0",
    charge_type_id: "0",
    charge_frequency: "",
    charge_to_cc_id: "0",
    charge_to_cc_name: "",
    charge_to_company_id: "0",
    charge_to_company_name: "",
    charge_to_project_id: "0",
    charge_to_project_name: "",
    vendor_seller_id: "0",
    vendor_seller: "",
    host_name: "",
    ip_address: "",
    mac_address: "",
    backup_retention: "",
    monitored: "",
    monitored_id: "0",
    domain_name: "",
    allocated_capacity: "",
    maximum_capacity: "",
    capacity_unit: "",
    capacity_utilization: "",
    capacity_thresholds: "",
    disaster_recovery_enabled: false,
    disaster_recovery_location_id: "0",
    disaster_recovery_location_name: "",
    disaster_tier_id: "0",
    recovery_point_objective: "",
    recovery_time_objective: "",
    discovery_source_name: "",
    patch_hold: "",
    patch_hold_reason: "",
    patch_schedule: "",
    patch_level: "",
    charge_category_name: "",
    charge_currency: "",
    charge_currency_id: "0",
    charge_type_name: "",
    cost_currency: "",
    created_by: "",
    dataset: "",
    dataset_name: "",
    disaster_tier_name: "",
    discovery_source_id: "0",   
    environment_name: "",

    manufacturer_id: "0",
    metallic_name: "",
    model_id: "0",
    owner_email: " ",
    ownership_type: "",
    sub_status_name: "",
    technical_owner_email: "",
    type_name: "",
    business_owner_email: "",
    warranty_expiration_date: undefined,
    charge_start_date: undefined,
    scheduled_return_date: undefined,
    first_discovered_at: undefined,
    allocation_date: undefined,
    assigned_user: [],

    business_owner_group_id: "0",
    business_owner_group_name: "",

    technical_owner_group_id: "0",
    technical_owner_group_name: "",

    cab_group_id: "0",
    cab_group_name: "",
    project: ""
  });

  let initialValues = {
    ...initVals,
  };

  const validationSchema = Yup.object().shape({
    ci_name: Yup.string().required("Required"),
    company_id: Yup.string().required("Required"),
    class_id: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
    category_name: Yup.string().required("Required"),
    sub_category_name: Yup.string().required("Required"),
    status: Yup.string().required("Required"),
    sub_status: Yup.string().required("Required"),
    // allocation_date: Yup.string().required("Required"),
   // business_owner_name: Yup.string().required("Required"),
    // owner_name: Yup.string().required("Required"),
    //technical_owner_name: Yup.string().required("Required"),
    // net_book_value: Yup.number().typeError("Please enter a valid number.").notRequired(),
   // model_number: Yup.number().typeError("Please enter a valid number.").notRequired(),  
    // availaibility_percentage: Yup.number().typeError("Please enter a valid number.")
    // .notRequired()
    // .min(0, 'Percentage cannot be less than 0')
    // .max(100, 'Percentage cannot be more than 100'),
    purchase_order_number: Yup.number().typeError("Please enter a valid number.").notRequired(),
    // maximum_capacity: Yup.number().typeError("Please enter a valid number.").notRequired(),
  });

  const [activeNav, setActiveNav] = useState(true);
  const [activeTabs, setActiveTabs] = useState("1");

  const toggle = (tab) => {
    setActiveNav(!tab);
    if (activeTabs !== tab) setActiveTabs(tab);
  };

  const rightEditPanel = (value) => {
    setrightEditPanel(value);
  };


  const onSubmit = async (fields, { resetForm, setSubmitting, errors }) => {
    const fieldsToCheck = ['ci_id', 'purchase_order_number',"dataset",'cost_currency_id','depreciation_charged_id','maximum_capacity'];

    const dateFields = ["allocation_date","warranty_expiration_date","deployement_date","decommission_date","support_end_date","first_deployed_date","installation_date","assigned_date","order_date","order_received_date","purchase_order_date","charge_start_date","charge_end_date","actual_retun_date","scheduled_return_date","first_discovered_at"]; 

    const formattedFields = { ...fields };

    dateFields.forEach(field => {
      if (fields[field]) {
        formattedFields[field] = moment(fields[field] , dateformat.slice(0, 10)?.toUpperCase() +" HH:mm:ss").format('YYYY-MM-DD HH:mm:ss');
      }
    });

    if (Array.isArray(fields?.assigned_user)) {
      formattedFields.assigned_user = fields?.assigned_user.map(user => ({
        assigned_user_id: user?.userId,
        assigned_user_name: user?.fullname,
        assigned_user_email: user?.email,
      }));
    }

      const PostData={
      ...formattedFields,
      ...fieldsToCheck.reduce((acc, field) => {
        acc[field] = fields[field] === "" ? undefined : fields[field];
        return acc;
    }, {}),
      category_companyId:undefined,
      type:Number(formattedFields?.type)
    }

    axios
      .post(GLOBAL.postCiDataURL, PostData)
      .then(function (response) {
        dispatch(getTimelineData(response.data[0].CI_ID));
        if (
          response?.data[0]?.upsert_status == false ||
          response?.data[0]?.upsert_status == "false"
        ) {
          Swal.fire(response.data[0]?.ci_name);
        } else {
          navigationHooks.navigate("/editcmdb/" + response.data[0]?.CI_ID);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  useEffect(() => {
    if(!isAddMode && ciId) {
      dispatch(loadCIEditDetails(ciId));
    }
  },[ciId, dispatch]);

  useEffect(() => {
    if (!isAddMode && ciId && cIEditDetails) {
 
      const updatedInitVals = {
        ci_name: cIEditDetails.CI_NAME,
        company_id: cIEditDetails.COMPANY_ID,
        company_name: cIEditDetails.COMPANY_NAME,
        class_id: cIEditDetails.CLASS_ID,
        class_name: cIEditDetails.CLASS_NAME,
        type: cIEditDetails.TYPE,
        category_id: cIEditDetails.CATEGORY_ID,
        category_name: cIEditDetails.CATEGORY_NAME,
        sub_category_id: cIEditDetails?.SUB_CATEGORY_ID || 0,
        sub_category_name: cIEditDetails.SUB_CATEGORY_NAME,
        ci_id: cIEditDetails.CI_ID,
        asset_tag: cIEditDetails.ASSET_TAG,
        serial_number: cIEditDetails.SERIAL_NUMBER,
        status: cIEditDetails.STATUS,
        status_name: cIEditDetails.STATUS_NAME,
        sub_status: cIEditDetails.SUB_STATUS,
        sub_status_name: cIEditDetails.SUB_STATUS_NAME,
        description: cIEditDetails.DESCRIPTION,
        manufacturer: cIEditDetails.MANUFACTURER,
        model_name: cIEditDetails.MODEL_NAME,
        model_number: cIEditDetails.MODEL_NUMBER,
        metallic: cIEditDetails.METALLIC,
        compliance: cIEditDetails?.COMPLIANCE || null,
        location_id: cIEditDetails.LOCATION_ID,
        location_name: cIEditDetails.LOCATION_NAME,
        environment: cIEditDetails.ENVIRONMENT,
        keyword: cIEditDetails.KEYWORD,
        is_external: String(cIEditDetails?.IS_EXTERNAL),
        business_owner_id: cIEditDetails.BUSINESS_OWNER_ID,
        business_owner_name: cIEditDetails.BUSINESS_OWNER_NAME,
        technical_owner_id: cIEditDetails.TECHNICAL_OWNER_ID,
        technical_owner_name: cIEditDetails.TECHNICAL_OWNER_NAME,
        owner_id: cIEditDetails.OWNER_ID,
        owner_name: cIEditDetails.OWNER_NAME,
        department_id: cIEditDetails.DEPARTMENT_ID,
        department_name: cIEditDetails.DEPARTMENT_NAME,
        change_group_id: cIEditDetails.CHANGE_GROUP_ID,
        change_group_name: cIEditDetails.CHANGE_GROUP_NAME,
        availaibility_percentage: cIEditDetails.AVAILAIBILITY_PERCENTAGE,
        support_company_id: cIEditDetails.SUPPORT_COMPANY_ID,
        support_company_name: cIEditDetails.SUPPORT_COMPANY_NAME,
        group_id: cIEditDetails.GROUP_ID,
        group_name: cIEditDetails.GROUP_NAME,
        owned_by_id: cIEditDetails.OWNED_BY_ID,
        owned_by: cIEditDetails.OWNED_BY,
        ownership_type_id: cIEditDetails.OWNERSHIP_TYPE_ID,
        ownership_type: cIEditDetails.OWNERSHIP_TYPE,
        order_date: cIEditDetails.ORDER_DATE,
        acquisition_cost: cIEditDetails.ACQUISITION_COST,
        cost_center_id: cIEditDetails.COST_CENTER_ID,
        cost_center: cIEditDetails.COST_CENTER,
        purchase_order_number: cIEditDetails.PURCHASE_ORDER_NUMBER,
        invoice_number: cIEditDetails.INVOICE_NUMBER,
        cost_currency_id: cIEditDetails?.COST_CURRENCY_ID || "0",
        general_ledger_account: cIEditDetails.GENERAL_LEDGER_ACCOUNT,
        net_book_value: cIEditDetails.NET_BOOK_VALUE,
        depreciation_charged_id: cIEditDetails.DEPRECIATION_CHARGED_ID,
        depreciation_schedule: cIEditDetails.DEPRECIATION_SCHEDULE,
        vendor_id: cIEditDetails.VENDOR_ID,
        vendor: cIEditDetails.VENDOR,
        currency_id: cIEditDetails.CURRENCY_ID,
        charge_category_id: cIEditDetails.CHARGE_CATEGORY_ID,
        charge_type_id: cIEditDetails.CHARGE_TYPE_ID,
        charge_frequency: cIEditDetails.CHARGE_FREQUENCY,
        charge_to_cc_id: cIEditDetails.CHARGE_TO_CC_ID || "0",
        charge_to_cc_name: cIEditDetails.CHARGE_TO_CC_NAME,
        charge_to_company_id: cIEditDetails.CHARGE_TO_COMPANY_ID || "0",
        charge_to_company_name: cIEditDetails.CHARGE_TO_COMPANY_NAME,
        charge_to_project_id: cIEditDetails.CHARGE_TO_PROJECT_ID,
        charge_to_project_name: cIEditDetails.CHARGE_TO_PROJECT_NAME,
        vendor_seller_id: cIEditDetails.VENDOR_SELLER_ID,
        vendor_seller: cIEditDetails.VENDOR_SELLER,
        host_name: cIEditDetails.HOST_NAME,
        ip_address: cIEditDetails.IP_ADDRESS,
        mac_address: cIEditDetails.MAC_ADDRESS,
        backup_retention: cIEditDetails.BACKUP_RETENTION,
        monitored: cIEditDetails.MONITORED,
        monitored_id: cIEditDetails?.MONITORED_ID,
        domain_name: cIEditDetails.DOMAIN_NAME,
        allocated_capacity: cIEditDetails.ALLOCATED_CAPACITY,
        maximum_capacity: cIEditDetails.MAXIMUM_CAPACITY,
        capacity_unit: cIEditDetails.CAPACITY_UNIT,
        capacity_utilization: cIEditDetails.CAPACITY_UTILIZATION,
        capacity_thresholds: cIEditDetails.CAPACITY_THRESHOLDS,
        disaster_recovery_enabled: cIEditDetails.DISASTER_RECOVERY_ENABLED,
        disaster_recovery_location_id: cIEditDetails.DISASTER_RECOVERY_LOCATION_ID,
        disaster_recovery_location_name: cIEditDetails.DISASTER_RECOVERY_LOCATION_NAME,
        disaster_tier_id: cIEditDetails.DISASTER_TIER_ID,
        recovery_point_objective: cIEditDetails.RECOVERY_POINT_OBJECTIVE,
        recovery_time_objective: cIEditDetails.RECOVERY_TIME_OBJECTIVE,
        discovery_source_name: cIEditDetails.DISCOVERY_SOURCE_NAME,
        patch_hold: cIEditDetails.PATCH_HOLD,
        patch_hold_reason: cIEditDetails.PATCH_HOLD_REASON,
        patch_schedule: cIEditDetails.PATCH_SCHEDULE,
        patch_level: cIEditDetails.PATCH_LEVEL,
        charge_category_name: cIEditDetails.CHARGE_CATEGORY_NAME,
        charge_currency: cIEditDetails.CHARGE_CURRENCY,
        charge_currency_id: cIEditDetails.CHARGE_CURRENCY_ID,
        charge_type_name: cIEditDetails.CHARGE_TYPE_NAME,
        cost_currency: cIEditDetails.COST_CURRENCY,
        created_by: cIEditDetails.CREATED_BY,
        dataset:cIEditDetails?.DATASET === "0" ? "" : cIEditDetails?.DATASET,
        dataset_name: cIEditDetails?.DATASET_NAME === "0" ? "" : cIEditDetails?.DATASET_NAME,
        depreciation_charged_name: cIEditDetails.DEPRECIATION_CHARGED_NAME,
        disaster_tier_name: cIEditDetails.DISASTER_TIER_NAME,
        discovery_source_id: cIEditDetails.DISCOVERY_SOURCE_ID,
        environment_name: cIEditDetails.ENVIRONMENT_NAME,
        manufacturer_id: cIEditDetails.MANUFACTURER_ID,
        metallic_name: cIEditDetails.METALLIC_NAME,
        model_id: cIEditDetails.MODEL_ID,
        owner_email: cIEditDetails.OWNER_EMAIL,
        // sub_status_name: cIEditDetails.SUB_STATUS_NAME,
        technical_owner_email: cIEditDetails.TECHNICAL_OWNER_EMAIL,
        type_name: cIEditDetails.TYPE_NAME,
        business_owner_email: cIEditDetails.BUSINESS_OWNER_EMAIL,
        warranty_expiration_date: cIEditDetails.WARRANTY_EXPIRATION_DATE,
        charge_start_date: cIEditDetails.CHARGE_START_DATE,
        scheduled_return_date: cIEditDetails.SCHEDULED_RETURN_DATE,
        first_discovered_at: cIEditDetails.FIRST_DISCOVERED_AT,
        allocation_date: cIEditDetails.ALLOCATION_DATE,
        assigned_user:Object.keys(cIEditDetails.ASSIGNED_USER || {}).length !== 0 
        ? (cIEditDetails?.ASSIGNED_USER || []).map(user => ({
            userId: user.assigned_user_id,
            fullname: user.assigned_user_name,
            email: user.assigned_user_email,
          }))
        : []
      };
  
      setInitVals((prev) => {
        return {
          ...prev,
          ...updatedInitVals
        };
      });
    }
  }, [ciId, cIEditDetails]);

  return (
    <main>
      <Container fluid className="margin-b-15 margin-t-10">
        <Breadcrumbs
          activePageName="Create"
          parentPageurl="/cmdblist"
          parentPageName="Asset/Config Board"
        />
      </Container>
      <Container fluid>
        <Row className="margin-b-15">
          <Col xs={12}>
            <Header
              isSubmitting={formikRef?.current?.isSubmitting}
              dirty={formikRef?.current?.dirty}
              submitForm={formikRef?.current?.handleSubmit}
              formikref={formikRef}
              errors={formikRef?.current?.errors}
              toggle={toggle}
              rightEditPanel={rightEditPanel}
              isRightEditPanel={isRightEditPanel}
            />
          </Col>
          <Col xs={12} md={12}>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={onSubmit}
            >
              {({
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values,
                dirty,
                resetForm,
                handleChange,
                isValid,
                submitCount,
              }) => {
               
                return (
                  <Form noValidate className="row-eq-height">
                    <PanelGroup direction="horizontal" className="overflow-visible">
                      <Panel
                        id="sidebar"
                        minSize={67}
                        order={1}
                        defaultSize={50}
                        className={
                          isRightEditPanel
                            ? "isShowLeftPanel catBordDv"
                            : "catBordDv"
                        }
                        style={{overflow: 'visible'}}
                      >
                        <AddEditCmdb
                          setFieldValue={setFieldValue}
                          values={values}
                          isValid={isValid}
                          submitCount={submitCount}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                        />
                      </Panel>
                      {isRightEditPanel ? (
                        <>
                          <PanelResizeHandle className="isShowLeftPanel resizeHandle">
                            <div className="outlne">
                              <div className="handIcn">
                                <i
                                  className="fa fa-chevron-left"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          </PanelResizeHandle>
                          <Panel minSize={33} order={2} defaultSize={50}>
                            <div className="stickyArea rBoxStyle">
                            {isAddMode && <CmdbProcessHierarchy translator={translator} rightEditPanel={rightEditPanel} isRightEditPanel={isRightEditPanel}  />}
                            {/* {!isAddMode &&  <RightSideItems activeTabs={activeTabs} tr={translator} ciId={ciId} CIEditDetails={cIEditDetails} userInfo={userInfo}/>} */}
                            </div>
                          </Panel>
                        </>
                      ) : (
                        ""
                      )}
                    </PanelGroup>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default Index;
