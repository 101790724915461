
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import View from "../../View";
import { useEffect } from "react";
import { filterFromArray } from "../../../../common/helper";
import ListLoader from "../../../../common/loaders/ListLoader";
import useDataQueryWithApi from "../../../hooks/useDataQueryWithApi";
const AccordionRenderer = (props) => {
  const accordions = props?.item?.components;
  const [activePanel, setActivePanel] = useState("unselected");
  const [apiValues, setApiValues] = useState({});
  const { defaultValues, setDefaultValues, atr } = props;
  const [pageId, setPageId] = useState(null);
  
  const [responseData, loading, setDataSourceId, dataQUeryError, setRefreshed] = useDataQueryWithApi(defaultValues, {});
  useEffect(() => {
    if (activePanel >= 0) {
      const activeItem = accordions[activePanel];
      if (activeItem?.data_source) {
        setDataSourceId(activeItem?.data_source?.id);
        setPageId(null);
      } else if (activeItem?.page?.id) {
        setPageId(activeItem?.page?.id);
        setDataSourceId(null);
      } else {
        setDataSourceId(null);
        setPageId(null);
      }
    }
  }, [activePanel]);

  useEffect(() => {
    if (responseData) {
      let newDefaultValues = structuredClone(defaultValues);
      if (Object.keys(apiValues).length > 0) {
        Object.keys(apiValues).forEach((res) => {
          delete newDefaultValues[res];
        });
      }

      setApiValues(responseData);
      setDefaultValues({ ...newDefaultValues, ...responseData });
    }
  }, [responseData]);


 const renderBody = (item,index) => {
   if (loading) {
     return <ListLoader />;
   } else if (activePanel == index && pageId) {
     return <View pageId={pageId} parentValues={defaultValues} isChildPage={true} setFromChildPage={props?.setFromChildPage} deleteFromChildPage={props?.deleteFromChildPage} refreshFromChildPage={props?.refreshFromChildPage} />;
   } else if (activePanel == index) {
     return props.renderComponent(item);
   }
   return null;
 };

  const accordionItems = (item, index) => {
    const title = filterFromArray(item?.components, "type", "accordion-title");
    const body = filterFromArray(item.components, "type", "accordion-body");
    if (item?.defaultOpen && activePanel=="unselected") {
      setActivePanel(index);
    }
    return (
      <Accordion.Item
        eventKey={index}
        key={"accordion_" + index}
        className="collapsed"
      >
        <Accordion.Header>{props.renderComponent(title)}</Accordion.Header>
        <Accordion.Body id={body[0]?.attributes?.id}>{renderBody(body, index)}</Accordion.Body>
      </Accordion.Item>
    );
  };

  return (
    <div className="rBoxGap">
      <Accordion
        onSelect={setActivePanel}
        style={{ marginBottom: "0px" }}
        id={`rightSidePanel6 ${atr?.id}`}
        activeKey={activePanel}
        accordion
        className={`brkFix brkFixOrder ${atr?.className}`}
      >
        {accordions.map(accordionItems)}
      </Accordion>
    </div>
  );
};
export default AccordionRenderer;
