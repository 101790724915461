
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';

import bg from '_Images/hcl-sx-logo-black.png';
import deniedimage from '_Images/accountpending.png';

let stylingObject = {
  logoAuth: { "backgroundImage": `url(${bg})`, "backgroundRepeat": "no-repeat", "backgroundPosition": "center", "backgroundSize": "contain", "display": "inline-block", "width": "130px", "height": "42px", "margin": "0px", "textIndent": "-9999px" }
};

class Logos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  redirectToLogin() {
    window.location.href = "/login";
  }
  render() {
    return (
      <div style={{ marginTop: '-58px' }}>
        <div style={{ backgroundColor: '#eef2f5', height: '70px', borderBottom: '1px #d2d7f2 solid', marginBottom: '20px' }} />
        <div style={{ textAlign: 'center',height:"70px" }}>
          {/* <div style={stylingObject.logoAuth}></div> */}
        </div>
        <div style={{ marginTop: '20px', textAlign: 'center', padding: '0px 20px' }}>
          <div><img height={47} width={52} src={deniedimage} /><br/><br/></div>
          
          <h1 style={{ margin: '0px', color: 'red', fontSize: '17px', fontWeight: 400, display: 'block' }}>User ID not registered for the specified domain.</h1>
         <br/><br/>


          <div style={{ textAlign: 'center', marginBottom: '30px' }}><button onClick={this.redirectToLogin} style={{ cursor: 'pointer', fontSize: '14px', color: '#fff', border: 'none', backgroundColor: '#3884b4', padding: '10px 80px', textTransform: 'uppercase' }}>Go back to login</button></div>
        </div>
      </div>
    );
  }
}

export default Logos;
