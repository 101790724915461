
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from 'react';
import { Table, Form } from 'react-bootstrap';

import Cookies from 'universal-cookie';
import { reduxForm, reset } from 'redux-form';
import { getNotificationDataListApi } from '../../../../actions/spcmActions';
import Pagination from "react-js-pagination";
import _ from 'lodash';
import ListLoader from "../../../../components/common/loaders/ListLoader";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from 'react-redux';
import { IoSearch } from 'react-icons/io5';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import { usePreviousValue } from '../../../../helpers/usePreviousValue';

const cookies = new Cookies();

let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
let dateformatCookie = homepagelocation[2];
let result = dateformatCookie.substr(0, 10);



const NotificationBoardList = (props) => {
  const showLoader_ne = useSelector(state => state.showLoader_ne.loading);

  const [nameSearchFilter, setNameSearchFilter] = useState('');
  const [companySearchFilter, setCompanySearchFilter] = useState('');
  const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false);

  const multiSelectModuleDropdownRef = useRef(null);
  const multiSelectStatusDropdownRef = useRef(null);
  const multiSelectLanguageDropdownRef = useRef(null);
  const previousValue = usePreviousValue(props);

  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside);
    if (multiSelectStatusDropdownRef != undefined || multiSelectStatusDropdownRef != null) {
      multiSelectStatusDropdownRef.current.childNodes[0].className = "multiSelectRef";
      let myRef = multiSelectStatusDropdownRef;
      if (props.isStatusOpen != previousValue?.isStatusOpen) {
        let obj = ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1];        
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1].className = "multiSelectPopup";
          //ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1].style = "right:" + objRightPos + "px";
        }
      }
    }
    if (multiSelectModuleDropdownRef != undefined || multiSelectModuleDropdownRef != null) {
      multiSelectModuleDropdownRef.current.childNodes[0].className = "multiSelectRef";
      let myRef = multiSelectModuleDropdownRef;
      if (props.isModuleOpen != previousValue?.isModuleOpen) {
        let obj = ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1].className = "multiSelectPopup";
          //ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1].style = "left:" + objLeftPos + "px";
        }
      }
    }
    if (multiSelectLanguageDropdownRef != undefined || multiSelectLanguageDropdownRef != null) {
      multiSelectLanguageDropdownRef.current.childNodes[0].className = "multiSelectRef";
      let myRef = multiSelectLanguageDropdownRef;
      if (props.isLangOpen != previousValue?.isLangOpen) {
        let obj = ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1].className = "multiSelectPopup";
          //ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1].style = "left:" + objLeftPos + "px";
        }
      }
    }
  }, [props.isStatusOpen,props.isModuleOpen,props.isLangOpen])

  useEffect(() => {
    dispatch(getNotificationDataListApi([], ['default'], [], [], []));
    props.setModifiedStartAndEndDate({}, {}, []);
    props.setTypeFun('default');
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    }
  }, [])

  const clickOutside = (event) => {
    const statusDiv = document.getElementById("statusDiv");
    const moduleDiv = document.getElementById("moduleDiv");
    const languageDiv = document.getElementById("languageDiv");

    if (multiSelectStatusDropdownRef.current != undefined || multiSelectStatusDropdownRef.current != null ||
      multiSelectModuleDropdownRef.current != undefined || multiSelectModuleDropdownRef.current != null ||
      multiSelectLanguageDropdownRef.current != undefined || multiSelectLanguageDropdownRef.current != null) {
      document.addEventListener("mousedown", (event) => {
        if (moduleDiv.contains(event.target)) {
          setIsMultiSelectOpen(true);
          props.showRightSide(false);
        }
        else if (statusDiv.contains(event.target)) {
          setIsMultiSelectOpen(true);
          props.showRightSide(false);
        }
        else if (languageDiv.contains(event.target)) {
          setIsMultiSelectOpen(true);
          props.showRightSide(false);
        }
        else {
          setIsMultiSelectOpen(false);
        }
        props.multiSelectOpenFun("allFalse", false);
      });
    }
  }

  const onRowSelect = (e, row, index) => {
    props.getNotificationDetails(row.ID, row.COMPANY_NAME, row.MODULE_NAME, row.LOCALE_ID, row.STATUS, row.NOTIFICATION_NAME, row.UPDATED_AT)
    props.showRightSide(true);
    props.showActiveRow(index);
  }

  const onPageChange = (e) => {
    props.showRightSide(false);
    props.setPage(e);
    dispatch(getNotificationDataListApi(props.modifiedDateFilterValues, props.type, props.locale, props.module, props.status, props.searchParams, e, props.size));
  }

  const onPageSizeChange = (e) => {
    props.showRightSide(false);
    props.setLimit(e.target.value);

    dispatch(getNotificationDataListApi(props.modifiedDateFilterValues, props.type, props.locale, props.module, props.status, props.searchParams, props.page, e.target.value));
  }

  const handleSearchNotification = () => {
    let searchParamsObj = {};

    props.showRightSide(false);
    props.isClearAllFilterFunc(true);
    if (props.companySearchInput !== '') {
      searchParamsObj.company_name = props.companySearchInput.trim()
    }
    if (props.nameSearchInput !== '') {
      searchParamsObj.notificationName = props.nameSearchInput.trim()
    }
    props.setSearchParams(searchParamsObj);
    dispatch(getNotificationDataListApi(props.modifiedDateFilterValues, props.type, props.locale, props.module, props.status, searchParamsObj, props.page, props.size));
  }

  const handleLanguageChange = (value) => {
    let searchOnStatusArr = [];
    props.setSelectedLanguageValue(value);
    (_.isEmpty(props.searchParams)) ?
      props.module.length == 0 ?
        value.length == 0 ?
          props.isClearAllFilterFunc(false) :
          props.isClearAllFilterFunc(true) :
        props.isClearAllFilterFunc(true) :
      props.isClearAllFilterFunc(true);

    props.showRightSide(false);
    value.map((checkBoxValue) => {
      return (
        searchOnStatusArr.push(checkBoxValue.value)
      );
    }
    );
    props.setMultiSelectParams(searchOnStatusArr, props.module, props.status);
    dispatch(getNotificationDataListApi(props.modifiedDateFilterValues, props.type, searchOnStatusArr, props.module, props.status, props.searchParams, props.page, props.size));
  }

  const handleStatusChange = (value) => {
    let searchOnStatusArr = [];
    props.setSelectedStatusValue(value);
    (_.isEmpty(props.searchParams)) ?
      props.module.length == 0 ?
        value.length == 0 ?
          props.isClearAllFilterFunc(false) :
          props.isClearAllFilterFunc(true) :
        props.isClearAllFilterFunc(true) :
      props.isClearAllFilterFunc(true);

    props.showRightSide(false);
    value.map((checkBoxValue) => {
      return (
        searchOnStatusArr.push(checkBoxValue.value)
      );
    }
    );
    props.setMultiSelectParams(props.locale, props.module, searchOnStatusArr);
    dispatch(getNotificationDataListApi(props.modifiedDateFilterValues, props.type, props.locale, props.module, searchOnStatusArr, props.searchParams, props.page, props.size));
  }

  const handleModuleChange = (value) => {
    let searchOnModuleArr = [];
    props.setSelectedModuleValue(value);
    (_.isEmpty(props.searchParams)) ?
      props.status.length == 0 ?
        value.length == 0 ?
          props.isClearAllFilterFunc(false) :
          props.isClearAllFilterFunc(true) :
        props.isClearAllFilterFunc(true) :
      props.isClearAllFilterFunc(true);

    props.showRightSide(false);
    value.map((checkBoxValue) => {
      return (
        searchOnModuleArr.push(checkBoxValue.value)
      );
    }
    );
    props.setMultiSelectParams(props.locale, searchOnModuleArr, props.status);
    dispatch(getNotificationDataListApi(props.modifiedDateFilterValues, props.type, props.locale, searchOnModuleArr, props.status, props.searchParams, props.page, props.size));
  }

  const handleChange = (e, searchValue) => {
    switch (searchValue) {
      case "NAME":
        props.setNameSearchInput(e.target.value);
        setNameSearchFilter(searchValue);
        break;
      case "COMPANY_NAME":
        props.setCompanySearchInput(e.target.value);
        setCompanySearchFilter(searchValue);
        break;
    }
  }

  const renderTable = (data) => {

    return data.data.map((data, index) => {
      return (
        <tr key={index} className={props.isRightSideVisible && index == props.rowIndex ? "myActive" : ""} onClick={(e) => onRowSelect(e, data, index)}>
          <td>{data.COMPANY_NAME}</td>
          <td>{data.MODULE_NAME}</td>
          <td>{data.NOTIFICATION_NAME}</td>
          {data.LOCALE_ID == "en" ?<td>{"English"}</td> :
           data.LOCALE_ID == "de" ?<td>{"German"}</td> :
           data.LOCALE_ID == "fr" ?<td>{"French"}</td>:
           data.LOCALE_ID == "fil" ?<td>{"Filipino"}</td>:
           data.LOCALE_ID == "id" ?<td>{"Indonesian"}</td>:
           data.LOCALE_ID == "ta" ?<td>{"Tamil"}</td>:
           data.LOCALE_ID == "th" ?<td>{"Thai"}</td>:
           data.LOCALE_ID == "ms" ?<td>{"Malay"}</td>:
           data.LOCALE_ID == "zhcn" ?<td>{"Chinese Simplified"}</td>:
           data.LOCALE_ID == "ar" ?<td>{"Arabic"}</td>:
           data.LOCALE_ID == "hi" ?<td>{"Hindi"}</td>:
           data.LOCALE_ID == "it" ?<td>{"Italian"}</td>:
           data.LOCALE_ID == "ja" ?<td>{"Japanese"}</td>:
           data.LOCALE_ID == "ko" ?<td>{"Korean"}</td>:
           data.LOCALE_ID == "vi" ?<td>{"Vietnamese"}</td>:
           <td>{"Spanish"}</td>
          }
         
          {/* <td>{ moment(data.UPDATED_AT).format("DD-MM-YYYY HH:mm:ss")} </td> : */}

          {props.type == 'custom' ?
            dateformatCookie == "dd-MM-yyyy HH:mm:ss" ?
              <td>{data.UPDATED_AT != null ? moment(data.UPDATED_AT).format("DD-MM-YYYY HH:mm:ss") : moment(data.CREATED_AT).format("DD-MM-YYYY HH:mm:ss")}</td>
              : dateformatCookie == "yyyy-MM-dd HH:mm:ss" ?
                <td>{data.UPDATED_AT != null ? moment(data.UPDATED_AT).format("YYYY-MM-DD HH:mm:ss") : moment(data.CREATED_AT).format("YYYY-MM-DD HH:mm:ss")}</td>
                :
                <td>{data.UPDATED_AT != null ? moment(data.UPDATED_AT).format("MM-DD-YYYY HH:mm:ss") : moment(data.CREATED_AT).format("MM-DD-YYYY HH:mm:ss")}</td>

            :
            null}
          {data.STATUS == "ACTIVE" ?
            <td>{"Active"}</td> :
            <td>{"Inactive"}</td>
          }

        </tr>
      );
    });
  }


  let notificData = props.spcmReducer.notification_data ? props.spcmReducer.notification_data : { data: [] };
  let meta = !_.isEmpty(props.spcmReducer.notification_data) ? props.spcmReducer.notification_data.meta : {};
  let moduleOptions = [
    { label: 'Fulfillment', value: 'Fulfillment' },
    { label: 'Incident', value: 'Incident' },
    { label: 'Problem', value: 'Problem' },
    { label: 'Change', value: 'Change' },
    { label: 'User', value: 'User' },
    { label: 'Approvals', value: 'Approvals' },
    { label: 'Task', value: 'Task' },
    { label: 'HAM', value:'HAM'},
    { label: 'Governance', value:'Governance'},
    { label: 'KM', value:'KM'}
    
  ];
  let statusOptions = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Inactive', value: 'INACTIVE' }
  ];
  let LanguageOptions = [
    { label: "Arabic", value: "zhcn" },
    { label: "Chinese Simplified", value: "zhcn" },
    { label: "English", value: "en" },
    { label: "Filipino", value: "fil" },
    { label: "French", value: "fr" },
    { label: "German", value: "de" },
    { label: 'Hindi',value: "hi"},
    { label: 'Indonesian', value: "id" },
    { label: 'Italian', value: "it" },
    { label: 'Japanese', value: "ja" },
    { label: 'Korean',value: "ko"},
    { label: 'Malay', value: "ms" },
    { label: 'Spanish', value: "es" }, 
    { label: 'Tamil', value: "ta" },
    { label: 'Thai', value: "th" },
    { label: 'Vietnamese',value: "vi"},
  ];

  let panelColSize = props.panelResize;
  const handleSearch = (e, picker) => {
    // this.props.showRightSide(false);
    // this.props.isClearAllFilterFunc(true);
    let dateArr = [];
    // console.log("picker==", picker)
    if (picker) {
      // this.props.setModifiedRef(picker);      
      props.setModifiedDateRef(picker);
      dateArr.push(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"), moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
      // dateArr.push(moment(picker.startDate).format("YYYY-MM-DD "), moment(picker.endDate).format("YYYY-MM-DD "));
    }

    if (picker == undefined && !_.isEmpty(props.modifiedStartDate) && !_.isEmpty(props.modifiedEndDate)) {
      dateArr.push(moment(props.modifiedStartDate).format("YYYY-MM-DD HH:mm:ss"), moment(props.modifiedEndDate).format("YYYY-MM-DD HH:mm:ss"));
      // dateArr.push(moment(props.modifiedStartDate).format("YYYY-MM-DD "), moment(props.modifiedEndDate).format("YYYY-MM-DD "));
    }
    props.setSelectedDate(dateArr);
    props.isClearAllFilterFunc(true);
    dispatch(getNotificationDataListApi(dateArr, props.type, props.locale, props.module, props.status, props.searchParams, props.page, props.size));

  }
  const handleCallback = (filter, start, end) => {
    let modifiedDateFilterValue = [];
    switch (filter) {
      case 'modifiedOn':
        modifiedDateFilterValue.push(start.format("YYYY-MM-DD HH:mm:ss"), end.format("YYYY-MM-DD HH:mm:ss"));
        props.setModifiedStartAndEndDate(start.format('MM/DD/YYYY HH:mm:ss'), end.format('MM/DD/YYYY HH:mm:ss'), modifiedDateFilterValue);
        break;
    }
  }

  let myModifiedStartDate;
  let myModifiedEndDate;
  if (result == "yyyy-MM-dd") {
    myModifiedStartDate = !_.isEmpty(props.modifiedStartDate) ? moment(props.modifiedStartDate).format("YYYY/MM/DD") : "";
    myModifiedEndDate = !_.isEmpty(props.modifiedEndDate) ? moment(props.modifiedEndDate).format("YYYY/MM/DD") : "";
  }
  else if (result == "dd-MM-yyyy") {
    myModifiedStartDate = !_.isEmpty(props.modifiedStartDate) ? moment(props.modifiedStartDate).format("DD/MM/YYYY") : "";
    myModifiedEndDate = !_.isEmpty(props.modifiedEndDate) ? moment(props.modifiedEndDate).format("DD/MM/YYYY") : "";
  }
  else {
    myModifiedStartDate = !_.isEmpty(props.modifiedStartDate) ? moment(props.modifiedStartDate).format("MM/DD/YYYY") : "";
    myModifiedEndDate = !_.isEmpty(props.modifiedEndDate) ? moment(props.modifiedEndDate).format("MM/DD/YYYY") : "";
  }

  let myModifiedNewDate = myModifiedStartDate == "" && myModifiedEndDate == "" ? "" : myModifiedStartDate + "-" + myModifiedEndDate;
  const handleKeyPress = (event) => { };
  // console.log("myModifiedNewDate===", myModifiedNewDate);
  // console.log("modifiedStartDate===", props.modifiedStartDate);
  // console.log("modifiedEndDate===", props.modifiedEndDate);
  // console.log("modifiedDateFilterValues===", props.modifiedDateFilterValues);
  let caseResponsive =
  showLoader_ne || notificData.data == [] || notificData.data?.length <= 6 ? "overFlowClp" : "table-responsive";
  return (
    console.log("Notif props", props),
    <div>
      <div className={(showLoader_ne || notificData.data == [] || notificData.data?.length <= 6) ?"respondv responDvMinH" : "respondv"}>
        
        <div className={"tableRgtBor " + caseResponsive}>
          <Table striped bordered hover className="tableView nowrapWhright" style={{ "marginTop": "0px" }}>
            <thead>
              <tr>
                <th>
                  <div className="sortParArr">{props.tr["Company"]}</div>
                  <div className="colSearDv ">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={props.companySearchInput != "" ? props.companySearchInput : ""}
                      placeholder={props.tr["Search here"]}
                      onKeyDown={(e) => {
                        if (e.code == "Enter") {
                          if (
                            props.companySearchInput != "" &&
                            props.companySearchInput.trim().length > 3
                          )
                            handleSearchNotification();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "COMPANY_NAME");
                      }}
                      onClick={() => props.multiSelectOpenFun("allFalse", false)}
                    />
                    {props.companySearchInput.trim().length > 3 ?
                      <a title="search" className="faicn" href={void (0)} onClick={(event) => handleSearchNotification()}>
                        <IoSearch />
                      </a> : ""}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{props.tr["Module"]}</div>
                  <div id="moduleDiv" className="colSearDv" ref={multiSelectModuleDropdownRef} onClick={() => props.multiSelectOpenFun("notiBoardModule", true)}>
                    <ReactMultiSelectCheckboxes placeholderButtonLabel={props.tr["Select"]} rightAligned={true} options={moduleOptions} onChange={handleModuleChange} value={props.selectedModuleValue} />
                  </div>
                </th>
                <th>
                  <div className="sortParArr"
                  // onClick={() => onSortAsc(sort, 'displayNumber', ascDscToggle, lastSelectedSearchFilter)}
                  >
                    {props.tr["Name"]}
                  </div>
                  <div className="colSearDv">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={props.nameSearchInput != "" ? props.nameSearchInput : ""}
                      placeholder={props.tr["Search here"]}
                      onKeyDown={(e) => {
                        if (e.code == "Enter") {
                          if (
                            props.nameSearchInput != "" &&
                            props.nameSearchInput.trim().length > 3
                          )
                            handleSearchNotification();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "NAME");
                      }}
                      onClick={() => props.multiSelectOpenFun("allFalse", false)}
                    />
                    {props.nameSearchInput.trim().length > 3 ?
                      <a title="search" className="faicn" href={void (0)}
                        onKeyDown={(e) => {
                          if (e.code == "Enter") {
                            if (props.nameSearchInput != "" && props.nameSearchInput.trim().length > 3)
                              handleSearchNotification();
                          }
                        }}
                        onClick={(event) => handleSearchNotification()}>
                        <IoSearch />
                      </a> : ""}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{props.tr["Language"]}</div>
                  <div id="languageDiv" className="colSearDv" ref={multiSelectLanguageDropdownRef} onClick={() => props.multiSelectOpenFun("notiBoardLang", true)}>
                    <ReactMultiSelectCheckboxes placeholderButtonLabel={props.tr["Select"]} rightAligned={true} options={LanguageOptions} onChange={handleLanguageChange} value={props.selectedLangaugeValue} />
                  </div>
                </th>
                  {props.type == 'custom' ?
                    <th>
                      <div className="sortParArr">{props.tr["Last Modified On"]}</div>
                      <div className="colSearDv" >
                        <DateRangePicker
                          initialSettings={{
                            autoUpdateInput: false,
                            maxDate: moment(),
                            autoApply: true,
                            parentEl:"#skipdv"
                          }}
                          onApply={(e, picker) => handleSearch(e, picker)}
                          onCallback={(start, end) =>
                            handleCallback("modifiedOn", start, end)
                          }
                        >
                          <input
                            type="text"
                            value={myModifiedNewDate}
                            placeholder={props.tr["Search here"]}
                            readOnly
                            className="form-control col-4"
                            onPaste={handleKeyPress}
                            onKeyDown={handleKeyPress}
                          />
                        </DateRangePicker>
                      </div>

                    </th> : null}
                <th>
                  <div className="sortParArr">{props.tr["Status"]}</div>
                  <div id="statusDiv" className="colSearDv" ref={multiSelectStatusDropdownRef} onClick={() => props.multiSelectOpenFun("notiBoardstatus", true)}>
                    <ReactMultiSelectCheckboxes placeholderButtonLabel={props.tr["Select"]} rightAligned={true} options={statusOptions} onChange={handleStatusChange} value={props.selectedStatusValue} />
                  </div>
                </th>
                
              </tr>
            </thead>
            <tbody>

              {notificData.data == undefined || showLoader_ne ?
                <tr>
                  <td colSpan="9" style={{ "textAlign": "center" }}><ListLoader /></td>
                </tr> :
                notificData.data.length === 0 ?
                  <tr>
                    <td colSpan="9" style={{ "textAlign": "center" }}>{props.tr['There is no matching data available']}</td>
                  </tr> :
                  renderTable(props.spcmReducer.notification_data)
              }

            </tbody>
          </Table>
        </div>
        <div className="nBotPagina">
          <div className="nShow">
            <div className="margin-r-10">{props.tr['Show']}:</div>
            <Form.Select value={props.size} onChange={onPageSizeChange}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </Form.Select>
          </div>

          <nav aria-label='Pagination' className='display-inline-block'>
            <Pagination
              prevPageText='Prev'
              nextPageText='Next'
              firstPageText={<i className='glyphicon glyphicon-menu-left' />}
              lastPageText={<i className='glyphicon glyphicon-menu-right' />}
              activePage={meta ? meta.currentPage : 1}
              itemsCountPerPage={props.size}
              totalItemsCount={meta ? meta.rowCount ? meta.rowCount : 1 : 1}
              pageRangeDisplayed={panelColSize <= 42 ? 2 : 5}
              onChange={(e) => onPageChange(e)}
            /></nav>
        </div>
      </div>
    </div>
  );
}

export default reduxForm({
  form: 'notificationList',
  enableReinitialize: true,
})(NotificationBoardList)