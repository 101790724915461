
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect } from "react";
import { useState } from "react";
import { getFilteredFields, getInitialFieldsForRules } from "../../../../common/helper";
import { RuleComponent, OnConditionTrue } from "./RuleComponent";
import { Button, ButtonToolbar, Col, Row } from "react-bootstrap";
import { formatQuery } from "react-querybuilder";
import { isJson } from "../../utils";
import UiBuilderRuleList from "./list";
import { FaArrowLeft } from "react-icons/fa";
import axios from "axios";

const RuleIndex = (props) => {
  const [fields, setFields] = useState([]);
  const [forms, setForms] = useState([]);
  const [query, setQuery] = useState(null);
  const [conditionTrue, setIfConditionTrue] = useState([]);
  const [conditionFalse, setIfConditionFalse] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editRuleIndex, setEditRuleIndex] = useState(null);
  const [ruleList, setRuleList] = useState([]);
  const [ruleName, setRuleName] = useState("");
  const [nameError, setNameError] = useState("");
  const [ruleType, setRuleType] = useState("");
  const [ruleTypeError, setRuleTypeError] = useState("");
  const [pageJson, setPageJson] = useState({});
  const [loading, setLoading] = useState(false);

  const onRuleNameChange = (e) => {
    if (e?.target?.value?.length > 0) {
      setNameError(false);
    } else {
      setNameError(true);
    }
    setRuleName(e.target.value);
  };

  const onRuleTypeChange = (e) => {
    if (e?.target?.value?.length > 0) {
      setRuleTypeError(false);
    } else {
      setRuleTypeError(true);
    }
    setRuleType(e.target.value);
  };

  const addNewRule = () => {
    setIsEditMode(true);
    setQuery(null);
    setEditRuleIndex(null);
    setIfConditionFalse([]);
    setIfConditionTrue([]);
    setRuleType("");
    setRuleName("");
    setNameError("");
    setRuleTypeError("");
  };


  const setRuleToCache = (ruleData) => {
    setLoading(true);
    axios.post(`/api/setCacheForm`, {key:`${props?.sessionPageId}_ruleData`,value:ruleData}).then((response) => {
      setLoading(false);  
    }).catch((error) => {
      setLoading(false);
      console.log("error", error);
    });
  };

  useEffect(() => {
    // if (localStorage.getItem("gjsProject")) {
    //   const data = JSON.parse(localStorage.getItem("gjsProject"));
    //   if (typeof data == "object" && Object.keys(data).length > 0) {
    //     const filteredFields = getFilteredFields(data);
    //     setForms(filteredFields);
    //   }
    // }
    axios.get(`/api/getCacheForm/${props?.sessionPageId}`).then((response) => {
      if (response?.data) {
        setPageJson(response?.data);
        if (typeof response?.data == "object" && Object.keys(response?.data).length > 0) {
           const filteredFields = getFilteredFields(response?.data);
           setForms(filteredFields);
        }
      }
    }).catch((error) => {
      console.log("error", error);
    });

    axios.get(`/api/getCacheForm/${props?.sessionPageId}_ruleData`).then((response) => {
      if (response?.data) {
        if (response?.data) {
          if (Array.isArray(response?.data)) {
            setRuleList(response?.data);
          } else if (
            typeof response?.data == "object" &&
            Object.keys(response?.data).length > 0
          ) {
            const newRule = [];
            newRule.push(response?.data);
            setRuleList(newRule);
          }
        }
      }
    }).catch((error) => {
      console.log("error", error);
    });
    // if (
    //   localStorage.getItem("ruleData") &&
    //   isJson(localStorage.getItem("ruleData"))
    // ) {
    //   const ruleData = JSON.parse(localStorage.getItem("ruleData"));
      
    //   if (Array.isArray(ruleData)) {
    //     setRuleList(ruleData);
    //   } else if (
    //     typeof ruleData == "object" &&
    //     Object.keys(ruleData).length > 0
    //   ) {
    //     const newRule = [];
    //     newRule.push(ruleData);
    //     setRuleList(newRule);
    //   }
    // }

  }, []);

  useEffect(() => {
    if (forms && forms.length > 0) {
      const formFields = getInitialFieldsForRules(forms);
      setFields(formFields);
    }
  }, [forms]);

  const editRule = (index, row) => {
    setEditRuleIndex(index);
    setIsEditMode(true);
    setNameError("");
    setRuleTypeError("");
    if (row?.query) {
      setQuery(row?.query);
    }
    if (row?.name) {
      setRuleName(row?.name);
    }
    if (row?.ruleType) {
      setRuleType(row?.ruleType);
    }
    if (row?.conditionTrue) {
      setIfConditionTrue(row?.conditionTrue);
    }
    if (row?.conditionFalse) {
      setIfConditionFalse(row?.conditionFalse);
    }
  };


  const deleteRule = (index) => {
    let newRules = structuredClone(ruleList);
    newRules.splice(index, 1);
    setRuleList(newRules);
    //localStorage.setItem("ruleData", JSON.stringify(newRules));
    setRuleToCache(newRules);

  };

  const saveData = () => {
    if(!ruleName){
      setNameError("error");
    }
    if(!ruleType){
      setRuleTypeError("error");
    }
    if(!ruleName || !ruleType){
      return;
    }
    let ruleData = {};
    ruleData.name = ruleName;
    ruleData.ruleType = ruleType;
    ruleData.query = query;
    ruleData.conditionTrue = conditionTrue;
    ruleData.conditionFalse = conditionFalse;
    let newRuleList = structuredClone(ruleList);
    if(editRuleIndex !== null){
      newRuleList[editRuleIndex] = ruleData;
    }else{
      newRuleList.push(ruleData);
    }
    setRuleList(newRuleList);
    //localStorage.setItem("ruleData", JSON.stringify(newRuleList));
    setRuleToCache(newRuleList);
    setIsEditMode(false);
    setQuery(null);
    setRuleName("");
    setRuleType("");
    setEditRuleIndex(null);
    setIfConditionFalse([]);
    setIfConditionTrue([]);
  };

  const handleQueryChange = (query) => {
    // if (query.rules.some((rule) => rule.field === "age")) {
    //   setFields([...fields, { name: "retirement", label: "Retirement" }]);
    // }
    let jsonData = formatQuery(query, "json_without_ids");
    setQuery(query);
  };

  if (isEditMode) {
    return (
      <div style={{ background: "#fff" }}>
        <Row className="">
          <Col lg={6} md={6} sm={6} xs={5}></Col>
          <Col lg={6} md={6} sm={6} xs={7}>
            <div className="paHedFilter">
              <Button
                bsPrefix=" "
                type="button"
                onClick={() => {
                  setIsEditMode(false);
                }}
                className="myBt btn-xs plus"
                title={"Back"}
              >
                <FaArrowLeft />
              </Button>
            </div>
          </Col>
        </Row>
        <RuleComponent
          fields={fields}
          handleQueryChange={handleQueryChange}
          query={query}
          setQuery={setQuery}
          onRuleNameChange={onRuleNameChange}
          ruleName={ruleName}
          nameError={nameError}
          ruleTypeError={ruleTypeError}
          ruleType={ruleType}
          onRuleTypeChange={onRuleTypeChange}
        />
        <OnConditionTrue
          fields={fields}
          type="conditionTrue"
          data={conditionTrue}
          onRowsChange={setIfConditionTrue}
        />
        <OnConditionTrue
          fields={fields}
          type="conditionFalse"
          data={conditionFalse}
          onRowsChange={setIfConditionFalse}
        />
        <div className="mt-3 mx-2 mb-1">
          <ButtonToolbar className="black" bsPrefix=" ">
            <Button
              className="rgSidrkBtn smallBtn"
              size="xs"
              onClick={() => {
                saveData();
              }}
            >
              {editRuleIndex !== null?'Update Rule':'Save Rule'}
            </Button>
          </ButtonToolbar>
        </div>
      </div>
    );
  } else {
    return (
      <UiBuilderRuleList
        list={ruleList}
        editRule={editRule}
        addNewRule={addNewRule}
        deleteRule={deleteRule}
      />
    );
  }
};

export default RuleIndex;