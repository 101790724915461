
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Form from 'react-bootstrap/Form';
import W51H from './5W1H';
import Whys5 from './5Whys';
import Convergence_Divergence from './Convergence-Divergence';
import { getfilledRCAtechniques } from '_Actions/investigation/RCATechniquesActions';
import { connect } from 'react-redux';
import {IoClose} from "react-icons/io5";

class ViewInvestigationFindings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRCAMethod: '',
        };
        this.onSelect = this.onSelect.bind(this);
        this.renderTechniques = this.renderTechniques.bind(this);
        this.hideForm = this.hideForm.bind(this);
        this.initialMethodSelected = this.initialMethodSelected.bind(this);
    }
    componentWillMount(){
        this.props.getfilledRCAtechniques(this.props.investigationItemDetails.investigationId,this.initialMethodSelected);
    }
    hideForm(){
        this.setState({selectedRCAMethod: ''});
    }
    initialMethodSelected(methodID){
        this.setState({selectedRCAMethod: methodID}); 
    }
    onSelect(event){
        this.setState({selectedRCAMethod: event.target.value});
    }
    renderTechniques(){
        if(this.props.rcaTechniques.length===0){
           return null;
        }
        return this.props.rcaTechniques.map((item) => {
            return (<option value={item.displayId} selected={this.state.selectedRCAMethod===item.displayId}>{item.displayKey}</option>);
        });
    }
    render(){
        let mountedComponent = '';
        if(this.state.selectedRCAMethod=='120'||this.state.selectedRCAMethod==120){
            mountedComponent = <Whys5 makeDisable={true} noofWhys={5} whysLabel={['First', 'Second', 'Third', 'Fourth', 'Fifth']} hideForm={this.hideForm} techniqueID={this.state.selectedRCAMethod} translator={this.props.translator}/>;
        }else if(this.state.selectedRCAMethod=='125'||this.state.selectedRCAMethod==125){
            mountedComponent = <W51H makeDisable={true} noofWhys={6} whysLabel={['What', 'Where', 'When', 'Who', 'Why','How']} hideForm={this.hideForm} techniqueID={this.state.selectedRCAMethod} translator={this.props.translator}/>;
        }else if(this.state.selectedRCAMethod=='130'||this.state.selectedRCAMethod==130){
            mountedComponent = <Convergence_Divergence makeDisable={true} hideForm={this.hideForm} techniqueID={this.state.selectedRCAMethod} translator={this.props.translator}/>;
        }
        return (<div className="hambTab">
                <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
					<ul>
						<li>
						<button
                            type='button'
							title="Minimize the right panel"
							bsClass=""
							bsStyle=""
							className="closerightPanelBtn"
							onClick={() => {
							this.props.rightEditPanel(false);
                            this.props.changeActionView("");
							}}
						>
							<IoClose />
						</button>
						</li>
					</ul>
				</div>
                  <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{"View Problem Findings"}</div></div>
                  <div className='rBoxGap'>
            <Form.Group className='form-group'>
                   <Form.Label bsClass="">{"RCA Techniques"}</Form.Label>
                   <Form.Select componentClass="select" name="selectRCA"  type="select" className="form-control"  onChange={(e)=>{this.onSelect(e)}} >
                   <option value=''>Select</option>
                   {this.renderTechniques()}
                   </Form.Select>
                </Form.Group>
                <div>
                    {mountedComponent}
                    </div>
            </div>
        </div>

        )
    }
}

const mapStateToProps = ({ rcaTechniques,investigationItemDetails }) => {
	return {
		rcaTechniques,investigationItemDetails
	};
}
export default connect(mapStateToProps, { getfilledRCAtechniques })(ViewInvestigationFindings);
