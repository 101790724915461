
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/

import _ from "lodash";
import Cookies from "universal-cookie";

const cookies = new Cookies();
let userInfo = cookies.get('gph');

if (userInfo) userInfo = userInfo.replace('s:', '');
if (userInfo) userInfo = userInfo.substring(0, userInfo.lastIndexOf('.'));
userInfo = userInfo.split("~");


export const urgencyImpactReducer = (state = [], action) => {
    if (action.type === 'URGENCY_IMPACT_FETCHED_SUCCESSFULLY') {
      return action.payload;
    } else {
      return state;
    }
};

// export const systemCofigList = (state = [], action) => {
//   if (action.type === 'SYSTEM_CONFIG_FETCHED_SUCCESSFULLY') {
//     return action.payload;
//   } else {
//     return state;
//   }
// };
const initialState = {
  systemCofigList: {
    mailboxConfigList: [],  // Default as empty array
    surveyConfigList: [],   // Default as empty array
  },
};
export const systemCofigList = (state = initialState, action) => {
  switch (action.type) {
    case 'SYSTEM_CONFIG_FETCHED_SUCCESSFULLY':
      return {
        ...state,
        systemCofigList: {
          ...state.systemCofigList,
          mailboxConfigList: action.payload, // This should be an array
        },
      };
    case 'SURVEY_CONFIG_FETCHED_SUCCESSFULLY':
      return {
        ...state,
        systemCofigList: {
          ...state.systemCofigList,
          surveyConfigList: action.payload, // This should be an array
        },
      };
    // other cases
    default:
      return state;
  }
};

export const savedSystemCofigList = (state = [], action) => {
  if (action.type === 'SYSTEM_CONFIG_SAVED_SUCCESSFULLY') {
    return action.payload;
  } else {
    return state;
  }
};

export const savedSurveyCofigList = (state = [], action) => {
  if (action.type === 'SURVEY_CONFIG_SAVED_SUCCESSFULLY') {
    return action.payload;
  } else {
    return state;
  }
};

export const editSystemConfigList = (state = [], action) => {
  if (action.type === 'SYSTEM_CONFIG_EDITED_SUCCESSFULLY') {
    return action.payload;
  } else {
    return state;
  }
};

export const deleteSystemConfigList = (state = [], action) => {
  if (action.type === 'SYSTEM_CONFIG_DELETED_SUCCESSFULLY') {
    return action.payload;
  } else {
    return state;
  }
};

export const editSurveyConfigList = (state = [], action) => {
  if (action.type === 'SURVEY_CONFIG_EDITED_SUCCESSFULLY') {
    return action.payload;
  } else {
    return state;
  }
};

export const deleteSurveyConfigList = (state = [], action) => {
  if (action.type === 'SURVEY_CONFIG_DELETED_SUCCESSFULLY') {
    return action.payload;
  } else {
    return state;
  }
};

export const savedApprovalCofigList = (state = [], action) => {
  if (action.type === 'APPROVAL_CONFIG_SAVED_SUCCESSFULLY') {
    return action.payload;
  } else {
    return state;
  }
};
export const editApprovalConfigList = (state = [], action) => {
  if (action.type === 'APPROVAL_CONFIG_EDITED_SUCCESSFULLY') {
    return action.payload;
  } else {
    return state;
  }
};

export const deleteApprovalConfigList = (state = [], action) => {
  if (action.type === 'APPROVAL_CONFIG_DELETED_SUCCESSFULLY') {
    return action.payload;
  } else {
    return state;
  }
};

export const editMailboxConfigList = (state = [], action) => {
  if (action.type === 'MAILBOX_CONFIG_EDITED_SUCCESSFULLY') {
    return action.payload;
  } else {
    return state;
  }
};

export const savedMailboxCofigList = (state = [], action) => {
  if (action.type === 'MAILBOX_CONFIG_SAVED_SUCCESSFULLY') {
    return action.payload;
  } else {
    return state;
  }
};

