
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";

import { useGetAllOccurancesQuery } from "../../../../reducers/governance/meeting/meeting";
import { OccurrencesListView } from "./list";

const Occurrences = ({ meetingDefData, occurrencesdata, rightEditPanel }) => {
  // const [orderby, setOrderby] = useState("asc");
  const meetingId = meetingDefData?.meetingDefinationId;
  const cacheBuster = btoa(new Date().toISOString().slice(0, 16).toString());

  // const {
  //   data: occurrencesdata,
  //   isLoading: occurrencesLoading,
  //   refetch: occurenceRefetch,
  // } = useGetAllOccurancesQuery(
  //   {
  //     definationId: meetingDefData?.meetingDefinationId,
  //     pageNumber: -1,
  //     pageSize: -1,
  //     queryString: `tenantId=${meetingDefData?.companyId}&sortedBy=${orderby}`,
  //   },
  //   {
  //     skip: !meetingId || meetingDefData?.status !== "Cancelled" || cacheBuster,
  //   }
  // );

  return (
    <div className="panel-body attachment-body">
      <div className="custom-container-fluid">
        <OccurrencesListView
          occurrencesdata={occurrencesdata}
          meetingDefData={meetingDefData}
          rightEditPanel={rightEditPanel}
        />
      </div>
    </div>
  );
};

export default Occurrences;
