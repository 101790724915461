import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ButtonToolbar, Col, Nav, Row } from "react-bootstrap";
import { SplitButton, SplitButtonItem } from "@progress/kendo-react-buttons";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  InputTextField,
  SelectField,
  StandardTextArea,
} from "../../../common/forms/FormFields";
import ForumDropdownPlusTypeahead from "../../../Rules-query/common/ForumDropdownPlusTypeahead";
import { useGetGroupsByCompanyIdQuery } from "../../../../reducers/rules";
import { getCookiesValuesByPositions } from "../../../common/helper";
import { useSaveOfferingTemplateMutation } from "../../../../reducers/offeringReducers/OfferingTemplatesApi";

const { value1: loggedInUserCompanyId } = getCookiesValuesByPositions([48]);

const SaveTemplate = (props) => {
  const [selectedMultiValueList, setSelectedMultiValueList] = useState([]);
  const [options, setOptions] = useState([]);
  const translator = useSelector((state) => state.spcmReducer.tr);
  const formikRef = useRef(null);

  const { data: groupList, isFetching: loading } = useGetGroupsByCompanyIdQuery(
    {
      compId: loggedInUserCompanyId,
    },
    {
      skip: !loggedInUserCompanyId,
    }
  );

  const [
    saveTemplate,
    { isError: errorSavingTemplate, isLoading: showLoaderSubmit },
  ] = useSaveOfferingTemplateMutation();

  const selectedModule = {
    serviceRequest: { id: 15, name: "Fulfillment" },
    incidentRequest: { id: 5, name: "Incident" },
    standardChange: { id: 20, name: "Change" },
  }[props?.offeringDetails?.workItemType ?? "serviceRequest"];

  const initialValues = {
    moduleId: selectedModule?.id,
    module: selectedModule?.name,
    companyId: props.offeringDetails?.company,
    companyName: props.offeringDetails?.companyName,
    templateJson: "",
    status: "Active",
    templateName: "",
    description: "",
    accessibility: "self",
    offeringId: props.offeringDetails?.offeringId?.toString(),
    offeringName: props.offeringDetails?.offeringName,
    groupIds: "",
  };

  const validationSchema = Yup.object().shape({
    templateName: Yup.string().trim().required("Required"),
    description: Yup.string().trim().required("Required"),
    accessibility: Yup.string().trim().required("Required"),
    groupIds: Yup.string().when("accessibility", (accessibility, schema) => {
      return accessibility?.[0] === "GROUP"
        ? schema.required("Required")
        : schema;
    }),
  });

  useEffect(() => {
    const groupData = groupList?.message
      ? []
      : groupList?.length !== 0 &&
        groupList?.map((item) => ({
          label: item?.field1Key,
          id: item?.field1Value,
        }));

    setOptions(groupData || []);
  }, [groupList]);

  const onMultiValueSelection = (selected, setFieldValue) => {
    if (selected.length === 0) {
      setFieldValue("groupIds", "");
      setSelectedMultiValueList([]);
    } else {
      let selectedGrps = selected.map((grp) => grp.id).toString();
      setFieldValue("groupIds", selectedGrps);
      setSelectedMultiValueList(selected);
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const payload = {
      ...values,
      templateJson: JSON.stringify(props.templateValues),
    };
    saveTemplate(payload)
      .then((res) => {
        props.handleItemClick(false);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="rBoxGap">
      <div className="orderingInfo-form-container formGpGap">
        <div>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
            innerRef={formikRef}
          >
            {({ errors, setFieldValue, touched, values }) => (
              <Form noValidate>
                <Row>
                  <Col md={12}>
                    <InputTextField
                      label={translator["Name"]}
                      name="templateName"
                      required={true}
                    />
                  </Col>
                  <Col md={12}>
                    <div className="form-group">
                      <StandardTextArea
                        label={translator["Description"]}
                        name="description"
                        required={true}
                        rows={2}
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="form-group">
                      <SelectField
                        label={translator["Accessibility"]}
                        name="accessibility"
                        required={true}
                      >
                        <option value="SELF">{translator["Self"]}</option>
                        <option value="GLOBAL">{translator["Global"]}</option>
                        <option value="GROUP">{translator["Groups"]}</option>
                      </SelectField>
                    </div>
                  </Col>
                  <Col md={12}>
                    {values?.accessibility === "GROUP" && (
                      <div className="form-group">
                        <label>
                          <span className="rStar"></span>
                          {translator["Groups"]}
                        </label>
                        <Field
                          id="groups"
                          component={ForumDropdownPlusTypeahead}
                          onSelection={(selected) =>
                            onMultiValueSelection(selected, setFieldValue)
                          }
                          errorColor={
                            errors?.groupIds && touched?.groupIds ? "error" : ""
                          }
                          name="group"
                          className="form-control"
                          options={options}
                          selected={selectedMultiValueList}
                          labelKey="name"
                          placeHolder={loading ? "Loading..." : "Select Form"}
                          onCrossClick={() =>
                            onMultiValueSelection([], setFieldValue)
                          }
                          multiple={true}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
          <ButtonToolbar bsPrefix=" " className="ordBgtoolbar">
            <div className="ordBgBtn">
              <SplitButton
                text={"Save As Template"}
                size={"large"}
                className="chkSubmitBtn"
                popupSettings={{
                  popupClass: "chkSubmitPopup",
                  popupAlign: { horizontal: "right", vertical: "top" },
                  anchorAlign: { horizontal: "right", vertical: "bottom" },
                }}
                disabled={formikRef.current?.isSubmitting}
                onButtonClick={() => {
                  formikRef.current.submitForm();
                }}
                onItemClick={(e) => {
                  if (e.itemIndex === 0) {
                    props.handleItemClick(false);
                  }
                }}
              >
                <SplitButtonItem text={translator["Submit"]} />
              </SplitButton>
              {props.type == "behalf" ? null : (
                <Nav.Link
                  bsPrefix=" "
                  className="btn btn-link"
                  title={translator["Continue Shopping"]}
                  onClick={props.continueShopping}
                  disabled={props.disableOrderNowButton}
                >
                  {translator["Continue Shopping"]}
                </Nav.Link>
              )}
            </div>
          </ButtonToolbar>
        </div>
      </div>
    </div>
  );
};
export default SaveTemplate;
