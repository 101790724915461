import React, { useEffect, useState } from "react";
import useDataQueryWithApi from "../../hooks/useDataQueryWithApi";
import ListLoader from "../../../common/loaders/ListLoader";
import { ListGroup } from "react-bootstrap";

const Repeatativelevel = (props) => { 
  const [data, setData] = useState([]);
  const { item, components, defaultValues } = props;
 
  const [
    responseData,
    optionsLoading,
    setDataSourceId,
    dataError,
    setRefreshed,
  ] = useDataQueryWithApi({ ...defaultValues }, item, { ...defaultValues });

  useEffect(() => {
    if (item?.data_source) {
      setDataSourceId(item?.data_source.id);
    } 
  }, []);

  useEffect(() => {
    if (item?.defaultValueKey) {
     if (defaultValues && defaultValues.hasOwnProperty(item?.defaultValueKey)) {
       setData(defaultValues[item?.defaultValueKey]);
     }
   }
 }, [defaultValues]);

  useEffect(() => {
    if (responseData) {
      setData(responseData);
    }
  }, [responseData]);


  const renderByComponent = (json) => {
    if (data.length > 0) {
      return data.map((field, index) =>
        props.renderComponent(json, true, { level: index + 1, ...field })
      );
    }
  };


  const renderCheckedJson = (components) => {
    if (item?.view?.id && item?.view?.id == "orderedView") {
      return (
        <ListGroup as={"ol"} bsPrefix=" " className="Escalation-level-list">
          {renderByComponent(components)}
        </ListGroup>
      );
    } else {
      return renderByComponent(components);
    }
  };

  if (optionsLoading) {
    return <ListLoader />;
  } else {
    return !data || data.length == 0 ? (
      <div className="text-c padding-t-10 padding-b-10 border">
        No Data Found
      </div>
    ) : (
      data && data.length > 0 && renderCheckedJson(components)
    );
  }
};

export default Repeatativelevel;
