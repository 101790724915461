
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import TableComponent from "../Common/ReactTable/TableComponent";
import SystemConfigListHeader from "./SystemConfigListHeader";
import SystemConfigListView from "./SystemConfigListView";
import { getCompanyList } from "../../actions/spcmActions.js";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import SystemConfigShortDescription from "./SystemConfigShortDescription";
import Breadcrumbs from "../common/header/breadcrumbs.js";
import { getSystemConfigList,getSurveyConfigList} from "../../actions/systemConfig/systemConfigAction";
import "_Css/common/_dataTable.scss";
import { useLocation } from "react-router-dom";
const SystemConfigIndex = () => {
 
    const dispatch = useDispatch();

    const translator = useSelector((state) => state.spcmReducer.tr);
    const [requestMethod, setRequestMethod] = useState("");
    const [rightDisplay, setRightDisplay] = useState(false);
    const [filterTabActive, setFilterTabActive] = useState(1);
    const [systemConfigData, setSystemConfigData] = useState({});
    const [surveyConfigData, setSurveyConfigData] = useState({});
    const [approvalConfigData, setApprovalConfigData] = useState({});
    const [mailboxConfigData, setMailboxConfigData] = useState({});
    const [type, setType] = useState('default');
    const showRightSide = (val) => {
      setRightDisplay(val);
    };
    const location = useLocation().state?.activeTab;
    //console.log("location", location);

    useEffect(() => {
      if (location) {
        setFilterTabActive(location);
      }
    }, [location]);

    const setShortDescValues =  (data) => {
      if (filterTabActive === 1) {
        setSystemConfigData(data); // For SystemConfig data
      } else if (filterTabActive === 2) {
        setSurveyConfigData(data); // For SurveyConfig data
      }
      else if (filterTabActive === 3) {
        setApprovalConfigData(data); // For Approval data
      }
      else if (filterTabActive === 4) {
        setMailboxConfigData(data); // For mailbox data
      }
    };
  
    useEffect(() => {
      if (filterTabActive ===1){
        dispatch(getSystemConfigList());
        setType('default');
      }
      else if (filterTabActive ===2){          
      dispatch(getSurveyConfigList('survey'));
      setType('survey');
    }
    else if (filterTabActive ===3){          
      dispatch(getSurveyConfigList('approval'));
      setType('approval');
    }
    else if (filterTabActive ===4){          
      dispatch(getSurveyConfigList('mailbox'));
      setType('mailbox');
    }
    setRightDisplay(false); 
      // dispatch(loadIntegrationScenarioList(""));
    }, [dispatch,filterTabActive]);

 
  return (
    <div>
      <div>
        <div className="container-fluid padding-t-10 margin-b-15">
          <Breadcrumbs activePageName={"Mailbox Configuration"} />
        
        </div>
      <div className="container-fluid" bsClass="">
          <SystemConfigListHeader setFilterTabActive={setFilterTabActive} filterTabActive={filterTabActive} translator={translator} />
          <PanelGroup direction="horizontal" className="panelOverflow">
            <Panel id="sidebar" minSize={33} order={1} defaultSize={rightDisplay ? 67 : 100} className={rightDisplay ? "catBordDv isShowLeftPanel rwLeftPart" : "catBordDv rwLeftPart"}>
              <SystemConfigListView
                rightDisplay={rightDisplay}
                setShortDescValues={setShortDescValues}
                translator={translator}
                showRightSide={showRightSide}
                filterTabActive={filterTabActive}
                type={type}
              />
            </Panel>
            {rightDisplay ?
                <>
                  <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                  <Panel minSize={33} order={2} defaultSize={33}>
                    <div className="stickyArea rBoxStyle">
                      <SystemConfigShortDescription
                        showRightSide={showRightSide}
                        systemConfigData={systemConfigData}
                        surveyConfigData={surveyConfigData}
                        approvalConfigData={approvalConfigData}
                        mailboxConfigData={mailboxConfigData}
                        filterTabActive={filterTabActive}
                        translator={translator}
                        type={type}
                      />
                    </div>
                  </Panel>
                </> : ""
            }
          </PanelGroup>
        </div> 
       
      </div>
    </div>
  );
};

export default SystemConfigIndex;
