/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import axios from "axios";
import { Col, Form, Row } from "react-bootstrap";

const generateHtmlContent = (props) => {
  return (
    <Row className="botLineWhite" key={props.key}>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Form.Group>
          <Form.Label>{props.label}</Form.Label>
        </Form.Group>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Form.Group>
          <Form.Control
            componentClass={props.elementType}
            value={props.value}
            style={props.style}
            readOnly
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

class DynamicDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      locations: 0,
      companyId: 0,
      readOnlyInputHtml: [],
      typedInput: "",
      selectedValue: [],
      setInitialValue: '',
    };
    this.onValueSelection = this.onValueSelection.bind(this);
    this.setReadOnlyValues = this.setReadOnlyValues.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onCrossClick = this.onCrossClick.bind(this);
  }
 
  componentDidUpdate() {
    const { questionnaire, templateValuesRed, parentProps, setChildQuestions } = this.props;
    if (
      templateValuesRed?.[questionnaire.questionId] &&
      templateValuesRed[questionnaire.questionId].value.length > 0 &&
      this.state.options.length > 0 &&
      this.state.setInitialValue !== templateValuesRed?.id?.toString()
    ) {
      if (questionnaire.haveChild === "true") {
        setChildQuestions({
          payload: {
            selectedValue: templateValuesRed[questionnaire.questionId].value.at(-1),
            question: questionnaire,
            componentIds: parentProps.componentIds,
          },
          type: "typeahead",
          fetched: false
        })
      }
      this.setState({ setInitialValue: templateValuesRed.id.toString() });
      this.onValueSelection(templateValuesRed[questionnaire.questionId].value);
    }
  }

  componentDidMount() {
    const { questionnaire, dispatch } = this.props;
    let query = {};
    query.api = this.props.questionnaire.api;
    if (questionnaire.dataSourceSearchAttributes) {
      query.filterKeys = questionnaire.dataSourceSearchAttributes;
    }
    if (questionnaire.dataSourceFilterCriteria) {
      try {
        let filterCriteria = JSON.parse(questionnaire.dataSourceFilterCriteria);
        if (filterCriteria && Array.isArray(filterCriteria) && filterCriteria.length > 0) {
          query.criteria = questionnaire.dataSourceFilterCriteria;
        }
      } catch (err) {
        console.log("err -", err);
      }
    }
    if (typeof questionnaire.model === "string") {
      query.Asset_Type = "Desktop";
      query.seatCode = questionnaire.model;
    } else {
      if (questionnaire.model) {
        if (questionnaire.lookupKey === "Employee_Number") {
          query.Employee_Number = questionnaire.model.Employee_Number;
        } else if (
          questionnaire.lookupKey === "userid" &&
          !questionnaire.api.includes("xsmf")
        ) {
          query.userid = questionnaire.model.user_id;
        } else {
          query[questionnaire.lookupKey?.toLowerCase()] =
            questionnaire.model[questionnaire.lookupKey];
        }
      }
      query.companyid = questionnaire.model
        ? questionnaire.model.companyId
        : "";
    }
    this._typeahead.clear();
    axios
      .get("/search/dynamic/dropdown/data/", {
        headers: { query: JSON.stringify(query) },
      })
      .then((response) => {
        if (Array.isArray(response.data)) {
          if (questionnaire.dataSourceLabelKey) {
            this.setState({
              options: response.data
                .filter((option) => option[questionnaire.dataSourceLabelKey])
                .map((option) => {
                  return {
                    ...option,
                    NAME: option[questionnaire.dataSourceLabelKey],
                  };
                }),
            });
          } else {
            this.setState({
              options: response.data,
            });
          }
        } else {
          this.setState({
            options: [],
          });
        }
      })
      .catch((err) => {
        console.log("error occured...");
        console.log(err);
      });
  }

  setReadOnlyValues(roValues, selectedValue) {
    let readOnlyHtml = [];
    if (selectedValue && roValues != null && roValues[0] != "") {
      readOnlyHtml = roValues.map((value, index) => {
        let readOnlyStringValue = roValues[index];
        let objectKey;
        let label;
        if (readOnlyStringValue.includes("~")) {
          let keyAndLabelHolder = readOnlyStringValue.split("~");
          objectKey = keyAndLabelHolder[0];
          label = keyAndLabelHolder[1];
        } else {
          if (readOnlyStringValue.includes("_")) {
            let objCopy = JSON.parse(JSON.stringify(readOnlyStringValue));
            label = objCopy.replace("_", " ").toString();
            objectKey = readOnlyStringValue;
          } else {
            label = readOnlyStringValue;
            objectKey = readOnlyStringValue;
          }
        }
        let isElementTypeAsset = objectKey === "Assets" ? true : false;
        return generateHtmlContent({
          elementType: isElementTypeAsset ? "textarea" : "input",
          value: isElementTypeAsset
            ? selectedValue.Assets.join("\r\n\n")
            : selectedValue[objectKey],
          style: isElementTypeAsset ? { height: 60 } : {},
          label: label,
        });
      });
    }
    return readOnlyHtml;
  }

  onValueSelection(value) {
    const { questionnaire, setAnswer, dispatch } = this.props;
    if (value && value.length > 0) {
      this.props.setTemplateResponses(
        questionnaire.questionId, 
        { type: questionnaire.responseType, value, name: questionnaire.questionText }
      );
    }
    this.setState({ selectedValue: value });
    if (value?.length > 0) {
      let selectedValue = value[0];
      setAnswer(questionnaire, selectedValue, dispatch);
      let errorObj = Object.assign({}, this.props.piSectionFormErrors, {
        [questionnaire.name]: false,
      });
      this.props.setFormErrors(errorObj);
      this.setState({
        readOnlyInputHtml: this.setReadOnlyValues(
          questionnaire.roResponses,
          selectedValue
        ),
      });
    } else {
      if (questionnaire.questionType === "mandatory") {
        let errorObj = Object.assign({}, this.props.piSectionFormErrors, {
          [questionnaire.name]: true,
        });
        this.props.setFormErrors(errorObj);
      } else {
        let errorObj = Object.assign({}, this.props.piSectionFormErrors, {
          [questionnaire.name]: false,
        });
        this.props.setFormErrors(errorObj);
      }
      setAnswer(questionnaire, "", dispatch);
      this.setState({ readOnlyInputHtml: [] });
    }
  }

  onInputChange(event) {
    const {
      questionnaire,
      setAnswer,
      dispatch,
    } = this.props;
    this.setState({
      typedInput: event,
      selectedValue: [],
      readOnlyInputHtml: [],
    });
    this.props.setTemplateResponses(
      questionnaire.questionId, 
      { type: questionnaire.responseType, value: [], name: questionnaire.questionText }
    );
    setAnswer(questionnaire, "", dispatch);
  }

  onCrossClick() {
    const { questionnaire, setAnswer, dispatch } = this.props;
    this.setState({ selectedValue: [], typedInput: "", readOnlyInputHtml: [] });
    this._typeahead.clear();
    setAnswer(questionnaire, "", dispatch);
    this.props.setTemplateResponses(
      questionnaire.questionId, 
      { type: questionnaire.responseType, value: [], name: questionnaire.questionText }
    );
    if (questionnaire.questionType === "mandatory") {
      let errorObj = Object.assign({}, this.props.piSectionFormErrors, {
        [questionnaire.name]: true,
      });
      this.props.setFormErrors(errorObj);
    } else {
      let errorObj = Object.assign({}, this.props.piSectionFormErrors, {
        [questionnaire.name]: false,
      });
      this.props.setFormErrors(errorObj);
    }
  }

  onBlur() {
    const { questionnaire } = this.props;
    if (
      (questionnaire.questionType === "mandatory" &&
        this.state.selectedValue.length === 0) ||
      (this.state.typedInput.length > 0 &&
        this.state.selectedValue.length === 0)
    ) {
      let errorObj = Object.assign({}, this.props.piSectionFormErrors, {
        [questionnaire.name]: true,
      });
      this.props.setFormErrors(errorObj);
    } else {
      let errorObj = Object.assign({}, this.props.piSectionFormErrors, {
        [questionnaire.name]: false,
      });
      this.props.setFormErrors(errorObj);
    }
  }

  render() {
    const { readOnlyInputHtml } = this.state;
    const { questionnaire, parentProps, piSectionFormErrors } = this.props;
    let labelKey = "Name";
    if (this.state.options.length) {
      if (this.state.options[0].hasOwnProperty("NAME")) {
        labelKey = "NAME";
      }
    }
    let fieldClass = `typeahead dynamicDropClass dvTypehd ${
      piSectionFormErrors[questionnaire.name] ? "error" : ""
    }`;
    return (
      <div className={fieldClass}>
        {this.state.typedInput.length > 0 ||
        this.state.selectedValue.length > 0 ? (
          <span
            title={this.props.translator["Clear"]}
            role="img"
            aria-label="Clear"
            className="css-hakgx8 icn"
            onClick={this.onCrossClick}
          >
            <svg width="18" height="18" viewBox="0 0 24 24" role="presentation">
              <path
                d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
                fill="gray"
              ></path>
            </svg>
          </span>
        ) : null}
        <Typeahead
          {...parentProps.input}
          id={questionnaire.qId}
          labelKey={labelKey}
          flip={true}
          positionFixed={true}
          multiple={this.state.multiple}
          options={this.state.options}
          placeholder={
            questionnaire.placeholder !== null
              ? questionnaire.placeholder
              : "Choose Assets"
          }
          onChange={this.onValueSelection}
          ref={(ref) => (this._typeahead = ref)}
          onInputChange={this.onInputChange}
          onBlur={this.onBlur}
          selected={this.state.selectedValue}
        />
        {readOnlyInputHtml.length === 0 ? null : readOnlyInputHtml}
      </div>
    );
  }
}

export default DynamicDropdown;
