
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import axios from 'axios';
import { jsPDF } from "jspdf";
import { sanitizeElements, sanitizeHTMLForDocExport } from '../../../common/helper';

const updateDateFormat = (format = '', value = '') => {
    try {
        if (!value) {
            return '';
        }
        let date_format = (format && typeof format === 'string') 
            ? format.replace('dd', 'DD') 
            : 'DD-MM-YYYY HH:mm:ss';

        let formattedDate = moment(value, date_format).isValid()
            ? moment(value, date_format).format(date_format)
            : value;

        return formattedDate;
    } catch (e) {
        console.error(e);
        return value;
    }
};

const getRelateCIData = (obj) => {
    try{
        return new Promise((resolve, reject) => {
            const { investigationId = '' } = obj || {};
            axios
            .get(`/api/config_items/relationship/${investigationId}/Investigation/Yes`, {timeout: 7000})
            .then(res => {
                // console.log('getRelateCIData res - ', res);
                let result = '';
                if(res?.data && Array.isArray(res.data) && res.data.length > 0){
                    let arr = [];
                    (res.data).forEach(item => {
                        if(item['CI_NAME_RELATED']){
                            arr.push(item['CI_NAME_RELATED']);
                        }
                    });
                    result += arr.join(', ');
                }
                resolve(result);
            })
            .catch(err => {
                console.error(err);
                // return err;
                // reject('error');
                resolve('');
            });
        });
    }catch(e){
        console.error(e);
        // return Promise.reject('error');
        return Promise.resolve('');
    }
}

const getInvestigationTeamData = (obj) => {
    try{
        return new Promise((resolve, reject) => {
            const { investigationId = '' } = obj || {};
            axios
            .get(`/serviceManagement/rest/investigationTeamAction/investigationId/${investigationId}`, {timeout: 7000})
            .then(res => {
                // console.log('getInvestigationTeamData res - ', res);
                let result = '';
                if(res?.data && Array.isArray(res.data) && res.data.length > 0){
                    let arr = [];
                    (res.data).forEach(item => {
                        if(item['memberName']){
                            arr.push(item['memberName']);
                        }
                    });
                    result += arr.join(', ');
                }
                resolve(result);
            })
            .catch(err => {
                console.error(err);
                // return err;
                // reject('error');
                resolve('');
            });
        });
    }catch(e){
        console.error(e);
        // return Promise.reject('error');
        return Promise.resolve('');
    }
}

const getRelatedWorkItemData = (obj) => {
    try{
        return new Promise((resolve, reject) => {
            const { investigationId = '' } = obj || {};
            axios
            .get(`/serviceManagement/rest/investigationRelationsAction?itemId=${investigationId}&module=Investigation`, {timeout: 7000})
            .then(res => {
                // console.log('getRelatedWorkItemData res - ', res);
                let result = '';
                if(res?.data && Array.isArray(res.data) && res.data.length > 0){
                    let arr = [];
                    (res.data).forEach(item => {
                        if(item['workItemNumber']){
                            arr.push(item['workItemNumber']);
                        }
                    });
                    result += arr.join(', ');
                }
                resolve(result);
            })
            .catch(err => {
                console.error(err);
                // return err;
                // reject('error');
                resolve('');
            });
        });
    }catch(e){
        console.error(e);
        // return Promise.reject('error');
        return Promise.resolve('');
    }
}

const getVendorData = (obj) => {
    try{
        return new Promise((resolve, reject) => {
            const { investigationId = '' } = obj || {};
            axios
            .get(`/serviceManagement/rest/sepecificVendorTicketAction/${investigationId}`, {timeout: 7000})
            .then(res => {
                // console.log('getVendorData res - ', res);
                let result = '';
                if(res?.data && Array.isArray(res.data) && res.data.length > 0){
                    let arr = [];
                    (res.data).forEach(item => {
                        if(item['vendor'] && item['ticket']){
                            let str = `(Vendor: ${item['vendor'] || '-'}, Ticket: ${item['ticket'] || '-'})`;
                            arr.push(str);
                        }
                    });
                    result += arr.join(', ');
                }
                resolve(result);
            })
            .catch(err => {
                console.error(err);
                // return err;
                // reject('error');
                resolve('');
            });
        });
    }catch(e){
        console.error(e);
        // return Promise.reject('error');
        return Promise.resolve('');
    }
}

const getRCATechnique = (obj, techniqueId = '') => {
    try{
        return new Promise((resolve, reject) => {
            const { investigationId = '' } = obj || {};
            axios.get(`/serviceManagment/rest/rcaTechnique/${investigationId}/${techniqueId}`, {timeout: 7000})
            .then(res => {
                // console.log('res - ', res);
                let result = '';
                if(res?.data?.data?.keyValueData){
                    result = res?.data?.data?.keyValueData || {};
                }
                resolve(result);
            })
            .catch(err => {
                // console.error(err);
                resolve('');
            });
        });
    }catch(e){
        // console.error(e);
        return Promise.resolve('');
    }
}

const getTaskData = (obj) => {
    try{
        return new Promise((resolve, reject) => {
            const { investigationId = '', consumerCompany = '' } = obj || {};
            let insideParams =  JSON.stringify({"workItemId":[investigationId],"applicationName":"XSM","workItemType":['Investigation'],"moduleName":'Investigation',"clientId":consumerCompany,"orderBY":"sequenceNumber asc , taskId asc","requestType":"viewTask" });
            let config = {
                timeout: 7000,
                headers: {
                'X-IBM-Client-Id': '5a78384c-5316-42ad-a23e-56bc2eb76d7d ',
                'query': insideParams
                }
            };
            axios
            .get('/api/tasks/', config)
            .then(res => {
                // console.log('getTaskData res - ', res);
                let result = '';
                if(res?.data && Array.isArray(res.data) && res.data.length > 0){
                    (res.data).forEach(item => {
                        result += `<tr><td class="wwrap">${item['taskCode'] || ''}</td><td class="wwrap">${item['sequenceNumber'] || ''}</td><td class="wwrap">${item['description'] || ''}</td><td class="wwrap">${item['additionalParams'] || ''}</td><td class="wwrap">${item['statusName'] || ''}</td><td class="wwrap">${item['createdDate'] || ''}</td><td class="wwrap">${item['actualEndDate'] || ''}</td><td class="wwrap">${item['closeNotes'] || ''}</td></tr>`;
                    });
                }
                else{
                    result = '<tr style="text-align: center;"><td colspan="8">No data available.</td></tr>';
                }
                resolve(result);
            })
            .catch(err => {
                console.error(err);
                // return err;
                // reject('error');
                resolve('<tr style="text-align: center;"><td colspan="8">No data available.</td></tr>');
            });
        });
    }catch(e){
        console.error(e);
        // return Promise.reject('error');
        return Promise.resolve('<tr style="text-align: center;"><td colspan="8">No data available.</td></tr>');
    }
}

const getSLAData = (obj) => {
    try{
        return new Promise((resolve, reject) => {
            const { investigationNumber = '' } = obj || {};
            let strObj={};
            strObj.ticketId = investigationNumber;
            strObj.moduleId = '10';
            strObj = JSON.stringify(strObj);
            let config = {
                timeout: 7000,
                headers: { 'query':strObj }
            };
            axios
            .get('/sla-def/getSlaInformation', config)
            .then(res => {
                // console.log('getSLAData res - ', res);
                let result = '';
                if(res?.data && Array.isArray(res.data) && res.data.length > 0){
                    (res.data).forEach(item => {

                        let h = Math.floor(item['allocatedTime'] / 60);
                        if (h < 10 || h == 0){
                            h = "0" + h;
                        }
                        let m = parseInt(item['allocatedTime'] % 60, 10);
                        if (m < 10 || m == 0){
                            m = "0" + m;
                        }
                        let time = h + ":" + m;

                        let slastatus = "";
                        if(item['status']){
                            if(item['status'] == 'IN PROGRESS'){
                                slastatus = "In Progress";
                            }
                            if(item['status'] == 'ALREADY BREACHED'){
                                slastatus = "Breached";
                            }
                            if(item['status'] == 'MET'){
                                slastatus = "Met";
                            }
                            if(item['status'] != 'IN PROGRESS' && item['status'] != 'ALREADY BREACHED' && item['status'] != 'MET'){
                                slastatus = item['status'];
                            }
                        }
                        result += `<tr><td class="wwrap">${item['slaType'] || ''}</td><td class="wwrap">${item['slaName'] || ''}</td><td class="wwrap">${(time) ? (time + ' Hours') : ''}</td><td class="wwrap">${slastatus || ''}</td><td class="wwrap">${item['duedate'] || ''}</td></tr>`;
                    });
                }
                else{
                    result = '<tr style="text-align: center;"><td colspan="5">No data available.</td></tr>';
                }
                resolve(result);
            })
            .catch(err => {
                console.error(err);
                // return err;
                // reject('error');
                resolve('<tr style="text-align: center;"><td colspan="5">No data available.</td></tr>');
            });
        });
    }catch(e){
        console.error(e);
        // return Promise.reject('error');
        return Promise.resolve('<tr style="text-align: center;"><td colspan="5">No data available.</td></tr>');
    }
}

const getAuditLogData = (obj) => {
    try {
        return new Promise((resolve) => {
            const { investigationId = '', date_format = '' } = obj || {};
            let url = `/serviceManagement/rest/auditAction?itemId=${investigationId}&orderBy=desc&module=Investigation`;

            axios
                .get(url, { timeout: 7000 })
                .then((res) => {
                    let result = '';

                    if (res?.data && Array.isArray(res.data) && res.data.length > 0) {
                        res.data.forEach((item) => {
                            let update = '';
                            if (item['auditField'] && typeof item['auditField'] === 'string') {
                                if (item['auditField'] === 'CREATE') {
                                    update = 'Created';
                                } else {
                                    let type = item['auditField']
                                        .split(' ')
                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                        .join(' ');

                                    if (type === 'Tag') {
                                        let fromUpdate = 'NA';
                                        let toUpdate = 'NA';

                                        // Parse `from` and `to` fields
                                        try {
                                            const fromArr = Array.isArray(JSON.parse(item['from'])) ? JSON.parse(item['from']) : [];
                                            fromUpdate = fromArr
                                                .map((x) => `${x.tag_name}: ${x.tag_value}`)
                                                .join(', ') || 'NA';
                                        } catch (e) {
                                            console.warn('Invalid JSON in item[from]:', item['from']);
                                        }

                                        try {
                                            const toArr = Array.isArray(JSON.parse(item['to'])) ? JSON.parse(item['to']) : [];
                                            toUpdate = toArr
                                                .map((x) => `${x.tag_name}: ${x.tag_value}`)
                                                .join(', ') || 'NA';
                                        } catch (e) {
                                            console.warn('Invalid JSON in item[to]:', item['to']);
                                        }

                                        update = `${type} changed from (${fromUpdate}) to (${toUpdate})`;
                                    } else {
                                        update = `${type} changed from ${item['from'] || '-'} to ${item['to'] || '-'}`;
                                    }
                                }
                            }

                            result += `<tr><td class="wwrap">${item['createdByName'] || ''}</td><td class="wwrap">${updateDateFormat(date_format, item['createdOn']) || ''}</td><td class="wwrap">${update}</td></tr>`;
                        });
                    } else {
                        result = '<tr style="text-align: center;"><td colspan="3">No data available.</td></tr>';
                    }

                    resolve(result);
                })
                .catch((err) => {
                    resolve('<tr style="text-align: center;"><td colspan="3">No data available.</td></tr>');
                });
        });
    } catch (e) {
        return Promise.resolve('<tr style="text-align: center;"><td colspan="3">No data available.</td></tr>');
    }
};


const getActivityLogData = (obj) => {
    try{
        return new Promise((resolve, reject) => {
            const { investigationId = '', date_format = '' } = obj || {};
            let url = `/serviceManagement/rest/activityAction?itemId=${investigationId}&orderBy=desc&requireStatusValue=Y&module=Investigation`;
            axios
            .get(url, {timeout: 7000})
            .then(res => {
                // console.log('getActivityLogData res - ', res);
                let result = '';
                if(res?.data && Array.isArray(res.data) && res.data.length > 0){
                    (res.data).forEach(item => {
                        let update = '';
                        if(item['description'] && typeof item['description'] == 'string'){
                            if(item['description'] == 'Changed Status'){
                                update = `${item['description']} from ${item['from'] || '-'} to ${item['to'] || '-'}`;
                            }
                            else{
                                update = item['description'];
                            }
                        }
                        result += `<tr><td class="wwrap">${item['createdByName'] || ''}</td><td class="wwrap">${updateDateFormat(date_format, item['createdOn']) || ''}</td><td class="wwrap">${update}</td></tr>`;
                    });
                }
                else{
                    result = '<tr style="text-align: center;"><td colspan="3">No data available.</td></tr>';
                }
                resolve(result);
            })
            .catch(err => {
                console.error(err);
                // return err;
                // reject('error');
                resolve('<tr style="text-align: center;"><td colspan="3">No data available.</td></tr>');
            });
        });
    }catch(e){
        console.error(e);
        // return Promise.reject('error');
        return Promise.resolve('<tr style="text-align: center;"><td colspan="3">No data available.</td></tr>');
    }
}

const getDependentData_KA = (obj) => {
    try{
        const promises = [ getRelateCIData(obj), getInvestigationTeamData(obj), getRelatedWorkItemData(obj), getVendorData(obj), getRCATechnique(obj, '120'), getRCATechnique(obj, '125'), getRCATechnique(obj, '130'), getTaskData(obj), getSLAData(obj), getAuditLogData(obj), getActivityLogData(obj) ];
        return Promise.allSettled(promises)
        .then(res => {
            // console.log('res - ', res);
            return Promise.resolve(res);
        })
        .catch(err => {
            console.error(err);
            return Promise.resolve('error');
        });
    }catch(e){
        console.error(e);
        return Promise.resolve('error');
    }
}

export const exportDoc = async (type = '', obj = {}) => {
    try{
        if(!type){
            throw new Error('Error in exporting document');
        }

        // console.log('*************', obj);
        const { investigationNumber = '',
            requestNumber = '',
            consumerCompanyName = '',
            impactedUsername = '',
            status = '',
            ciName = '',
            source = '',
            description = '',
            symptoms = '',
            priorityValue = '',
            asgPmGroupname = '',
            asgPmName = '',
            rootCause = '',
            workaroundProvided = '',
            correctiveActionRequired = '',
            correctiveActions = '',
            closureComments = ''
        } = obj || {};
        let relatedCI = '';
        let investigationTeam = '';
        let relatedWorkItem = '';
        let vendorData = '';
        let rcaTechniquesUsed = '';
        let rca1 = {};
        let rca2 = {};
        let rca3 = {};
        let taskData = '';
        let slaData = '';
        let auditLogData = '';
        let activityLogData = '';
        let correctiveActionType = '';
        let correctiveActionComments = '';
        const dataRes = await getDependentData_KA(obj);
        // console.log('dataRes - ', dataRes);

        if(dataRes && Array.isArray(dataRes)){
            let rcaTechnique = [];
            if(dataRes[0] && dataRes[0]?.value){
                relatedCI = dataRes[0]?.value || '';
            }
            if(dataRes[1] && dataRes[1]?.value){
                investigationTeam = dataRes[1]?.value || '';
            }
            if(dataRes[2] && dataRes[2]?.value){
                relatedWorkItem = dataRes[2]?.value || '';
            }
            if(dataRes[3] && dataRes[3]?.value){
                vendorData = dataRes[3]?.value || '';
            }
            if(dataRes[4] && dataRes[4]?.value){
                rcaTechnique.push('5Whys');
                rca1 = dataRes[4]?.value || {};
            }
            if(dataRes[5] && dataRes[5]?.value){
                rcaTechnique.push('5W 1H');
                rca2 = dataRes[5]?.value || {};
            }
            if(dataRes[6] && dataRes[6]?.value){
                rcaTechnique.push('Convergence-Divergence');
                rca3 = dataRes[6]?.value || {};
            }
            if(dataRes[7] && dataRes[7]?.value){
                taskData = dataRes[7]?.value || '';
            }
            if(dataRes[8] && dataRes[8]?.value){
                slaData = dataRes[8]?.value || '';
            }
            if(dataRes[9] && dataRes[9]?.value){
                auditLogData = dataRes[9]?.value || '';
            }
            if(dataRes[10] && dataRes[10]?.value){
                activityLogData = dataRes[10]?.value || '';
            }
            rcaTechniquesUsed = rcaTechnique.join(', ');
        }

        if(closureComments && typeof closureComments == 'string' && closureComments.includes('Corrective Action - Not Implemented') == true){
            correctiveActionType = 'Corrective Action - Not Implemented';
            let arr = closureComments.split('Closure reason : Corrective Action - Not Implemented - ');
            correctiveActionComments = (arr && arr.length == 2) ? arr[1] : '';
        }
        if(correctiveActionRequired && correctiveActionRequired == 'Y'){
            correctiveActionType = 'Corrective Action with RFC';
            correctiveActionComments = correctiveActions;
        }
        if(correctiveActionRequired && correctiveActionRequired == 'N'){
            correctiveActionType = 'Corrective Action without RFC';
            correctiveActionComments = correctiveActions;
        }

        // return false;

        let htmlData = `<head>
            <meta charset='utf-8'>
            <style>
            #exportDocument table {
                font-family: arial, sans-serif;
                border-collapse: collapse;
                width: 100%;
            }

            #exportDocument td,
            #exportDocument th {
                border: 1px solid #000000;
                text-align: left;
                padding: 8px;
            }
            #exportDocument .wwrap {
                word-wrap: break-word;
            }
            #exportDocument .tbStyle {
                margin-left: auto;
                margin-right: auto;
                table-layout: fixed;
            }
            #exportDocument .w40 {
                width: 40%;
            }
            #exportDocument .w50 {
                width: 50%;
            }
            #exportDocument .w60 {
                width: 60%;
            }
            #exportDocument .w70 {
                width: 70%;
            }
            #exportDocument .tbHeading {
                width: 100%;
                text-align: center;
                font-weight: bold;
                background-color: #d0cece;
            }
            #exportDocument .fontBold {
                font-weight: bold;
            }
            </style>
            <style>
            @page WordSection1{size: 841.95pt 595.35pt;mso-page-orientation: landscape;}
            div.WordSection1 {page: WordSection1;}
            table{border-collapse:collapse;}td{border:1px gray solid;width:5em;padding:2px;}
            </style>
        </head>
        <body>
            <div id="exportDocument" class="WordSection1">
                <div style="text-align: center"><h2>Problem Investigation Report</h2></div>

                <br /><br /><br />

                <div>
                    <table class="tbStyle w50">
                    <tr>
                        <td class="wwrap w50">Request ID >> Problem ID</td>
                        <td class="wwrap w50">${requestNumber + ' >> ' + investigationNumber}</td>
                    </tr>
                    <tr>
                        <td class="wwrap w50">Company</td>
                        <td class="wwrap w50">${consumerCompanyName || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap w50">Requestor</td>
                        <td class="wwrap w50">${impactedUsername || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap w50">Status</td>
                        <td class="wwrap w50">${status || ''}</td>
                    </tr>
                    </table>
                </div>

                <br /><br /><br />

                <div>
                    <table class="tbStyle w70">
                    <tr>
                        <td colspan="2" class="tbHeading">Ticket Details</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Impacted CI</td>
                        <td class="wwrap w60">${ciName || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Source</td>
                        <td class="wwrap w60">${source || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Problem Statement</td>
                        <td class="wwrap w60">${description || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Problem Symptoms</td>
                        <td class="wwrap w60">${symptoms || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Priority</td>
                        <td class="wwrap w60">${priorityValue || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Problem Management Group</td>
                        <td class="wwrap w60">${asgPmGroupname || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Problem Manager</td>
                        <td class="wwrap w60">${asgPmName || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Problem Investigation Team</td>
                        <td class="wwrap w60">${investigationTeam || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Related CI</td>
                        <td class="wwrap w60">${relatedCI || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Related Work Items</td>
                        <td class="wwrap w60">${relatedWorkItem || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Vendor Details</td>
                        <td class="wwrap w60">${vendorData || ''}</td>
                    </tr>
                    </table>
                </div>

                <br /><br /><br />

                <div>
                    <table class="tbStyle w50">
                    <tr>
                        <td colspan="2" class="tbHeading">Root Cause Analysis</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Root Cause</td>
                        <td class="wwrap w60">${rootCause || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Workaround Provided</td>
                        <td class="wwrap w60">${workaroundProvided || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">RCA Technique Used</td>
                        <td class="wwrap w60">${rcaTechniquesUsed || ''}</td>
                    </tr>
                    </table>
                </div>

                <br /><br /><br />

                <h4>“Details will only be available for the RCA Technique which is used for this ticket.”</h4>

                <br /><br /><br />

                ${(Object.keys(rca1).length > 0) ? `<div>
                <table class="tbStyle w50">
                    <tr>
                        <td colspan="2" class="tbHeading">RCA - 5 Whys</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">First Why</td>
                        <td class="wwrap w60">${rca1?.First || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Second Why</td>
                        <td class="wwrap w60">${rca1?.Second || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Third Why</td>
                        <td class="wwrap w60">${rca1?.Third || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Fourth Why</td>
                        <td class="wwrap w60">${rca1?.Fourth || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Fifth Why</td>
                        <td class="wwrap w60">${rca1?.Fifth || ''}</td>
                    </tr>
                    </table>
                </div>

                <br /><br /><br />` : ''}

                ${(Object.keys(rca2).length > 0) ? `<div>
                <table class="tbStyle w50">
                    <tr>
                        <td colspan="2" class="tbHeading">RCA - 5W 1H</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">What</td>
                        <td class="wwrap w60">${rca2?.What || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Where</td>
                        <td class="wwrap w60">${rca2?.Where || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">When</td>
                        <td class="wwrap w60">${rca2?.When || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Who</td>
                        <td class="wwrap w60">${rca2?.Who || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Why</td>
                        <td class="wwrap w60">${rca2?.Why || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">How</td>
                        <td class="wwrap w60">${rca2?.How || ''}</td>
                    </tr>
                    </table>
                </div>

                <br /><br /><br />` : ''}

                ${(Object.keys(rca3).length > 0) ? `<div>
                <table class="tbStyle w50">
                    <tr>
                        <td colspan="2" class="tbHeading">RCA - Convergence-Divergence</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Discover</td>
                        <td class="wwrap w60">${rca3?.Discover || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Define</td>
                        <td class="wwrap w60">${rca3?.Define || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Deduce</td>
                        <td class="wwrap w60">${rca3?.Deduce || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Determine</td>
                        <td class="wwrap w60">${rca3?.Determine || ''}</td>
                    </tr>
                    </table>
                </div>

                <br /><br /><br />` : ''}

                ${(correctiveActionType) ? `<div>
                <table class="tbStyle w50">
                    <tr>
                        <td colspan="2" class="tbHeading">Corrective Action</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Corrective Action Type</td>
                        <td class="wwrap w60">${correctiveActionType || ''}</td>
                    </tr>
                    <tr>
                        <td class="wwrap fontBold w40">Comments</td>
                        <td class="wwrap w60">${correctiveActionComments || ''}</td>
                    </tr>
                    </table>
                </div>

                <br /><br /><br />` : ''}

                <div>
                    <table class="tbStyle w70">
                    <tr>
                        <td colspan="8" class="tbHeading">Task Details</td>
                    </tr>
                    <tr>
                        <th class="wwrap">Task Code</th>
                        <th class="wwrap">Task Sequence</th>
                        <th class="wwrap">Summary</th>
                        <th class="wwrap">Additional Information</th>
                        <th class="wwrap">Status</th>
                        <th class="wwrap">Created On</th>
                        <th class="wwrap">Completed On</th>
                        <th class="wwrap">Completion Notes</th>
                    </tr>
                    ${taskData}
                    </table>
                </div>

                <br /><br /><br />

                <div>
                    <table class="tbStyle w70">
                    <tr>
                        <td colspan="5" class="tbHeading">SLA Details</td>
                    </tr>
                    <tr>
                        <th class="wwrap">Type</th>
                        <th class="wwrap">Name</th>
                        <th class="wwrap">SLA</th>
                        <th class="wwrap">Status</th>
                        <th class="wwrap">Due By</th>
                    </tr>
                    ${slaData}
                    </table>
                </div>

                <br /><br /><br />

                <div>
                    <table class="tbStyle w70">
                    <tr>
                        <td colspan="3" class="tbHeading">Audit Log</td>
                    </tr>
                    <tr>
                        <th class="wwrap">Update Done By</th>
                        <th class="wwrap">Date/Time of Update</th>
                        <th class="wwrap">Update</th>
                    </tr>
                    ${auditLogData}
                    </table>
                </div>

                <br /><br /><br />

                <div>
                    <table class="tbStyle w70">
                    <tr>
                        <td colspan="3" class="tbHeading">Comments/Activity Update</td>
                    </tr>
                    <tr>
                        <th class="wwrap">Update Done By</th>
                        <th class="wwrap">Date/Time of Update</th>
                        <th class="wwrap">Comment</th>
                    </tr>
                    ${activityLogData}
                    </table>
                </div>

                <br /><br /><br />
            </div>
        </body>`;
        if(type == 'pdf'){

            let htmlStr = `<!DOCTYPE html><html>${htmlData}</html>`;

            let virtualEl = document.createElement('div');

            virtualEl.innerHTML = sanitizeHTMLForDocExport(htmlStr);

            const doc = new jsPDF();

            doc.html(virtualEl, {

                callback: function(doc) {

                    let filename = `Problem Investigation Report - ${requestNumber}-${investigationNumber}.pdf`;

                    doc.save(filename);

                },

                margin: [10, 10, 10, 10],

                // autoPaging: 'text',

                x: 0,

                y: 0,

                width: 190, //target width in the PDF document

                windowWidth: 675, //window width in CSS pixels

                pagesplit: true

            });

        }
        if(type == 'word'){
            let htmlStr = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>${htmlData}</html>`;
            let blob = new Blob(['\ufeff', htmlStr], {
                type: 'application/msword'
            });
            // let url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(htmlStr);
            let url = URL.createObjectURL(blob);
            let filename = `Problem Investigation Report - ${requestNumber}-${investigationNumber}.doc`;
            let downloadLink = document.createElement("a");
            document.body.appendChild(downloadLink);
            
            if(navigator?.msSaveOrOpenBlob ){
                navigator.msSaveOrOpenBlob(blob, filename);
            }else{
                downloadLink.href = url;
                downloadLink.download = filename;
                downloadLink.click();
            }
            document.body.removeChild(downloadLink);

        }
    }catch(e){
        console.error(e);
    }
}