
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Table } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import axios from "axios";
import Cookies from "universal-cookie";
import Datetime from "react-datetime";
import moment from "moment";
import CmdbDropdownPlusTypeahead from "../../cmdb/common/CmdbDropdownPlusTypeahead";
import { getFdnTagData } from "../../../actions/cmdb/lovAction";
import { GLOBAL } from "../../Globals";
import { loadBreakFixEditDetails } from "../../../actions/breakFix/breakFixEditDetailsAction";
import Swal from "sweetalert2";
import { loadChangeEditData } from "../../../actions/changeManagement/changeManagementAction";
import { loadFulfillmentDetails } from "../../../actions/fulfillment/fulfillmentDataAction";
import { loadTaskItemToEdit } from "../../../actions/task/TaskDataAction";
import ListLoader from "../loaders/ListLoader";
import { HiPlus, HiOutlineTrash } from "react-icons/hi";
import { PiPencilSimpleBold } from "react-icons/pi";
import { IoClose } from "react-icons/io5";

const initialField = {
  tag_key: "",
  tag_name: "",
  tag_type: "Text",
  tag_value: "",
  mainTagType: "",
  selectedTag: "",
  tagChildValues: [],
  attributes_value: [],
  parentTagId: "",
  isError: false,
  isTagError: false,
  charLimit: 0,
  isTagMandatory: true
};

/* cookies use for getting company id */

const cookie = new Cookies();
const gph = cookie.get("gph").split("~");
const companyId = typeof gph[48] !== "undefined" ? gph[48] : "";
let dateformat = cookie.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];

const cookies = new Cookies();
let cookiesData = cookies.get('gph');
if (cookiesData) cookiesData = cookiesData.replace('s:', '');
if (cookiesData) cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf('.'));
cookiesData = cookiesData.split("~");

let onContractRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onContractRoleVisible = onContractRoleVisible.includes('68')

let onConfigManagerRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onConfigManagerRoleVisible = onConfigManagerRoleVisible.includes('37')

let onAssetRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onAssetRoleVisible = onAssetRoleVisible.includes('38')

let onConfigViewerRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onConfigViewerRoleVisible = onConfigViewerRoleVisible.includes('39')

const TagDetails = (props) => {
  const translator = useSelector((state) => state.spcmReducer.tr);
  const dispatch = useDispatch();
  const FdnTagList = useSelector((state) => state.FdnTagList);
  const [disableBtn, setDisableBtn] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showErrorText, setShowErrorText] = useState("");
  const [newAddTagList, setNewAddTagList] = useState([]);
  const [showForm, setShowForm] = useState("");
  const [tagsFormField, setTagsFormField] = useState([initialField]);
  const [exisTagIds, setExistingTagIds] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let reqParams = {};
    reqParams.companyId = props.companyId;
    reqParams.businessFunction = props.businessFunction
      ? props.businessFunction
      : "Default";
    reqParams.tagModule = props.module;
    dispatch(getFdnTagData(reqParams));
    if (props.itemDetails.tag) {
      let tagsDetail = JSON.parse(props.itemDetails.tag);
      setExistingTagAndTagList(tagsDetail);
    } else if (props.mainModule === "Task" || props.mainModule === "CMDB") {
      getTMSCMDBTagDetail(props.itemId);
    }
  }, []);

  // useEffect(() => {
  //   try{
  //     document.addEventListener("scroll", () => {
  //       Array.from(document.getElementsByClassName("rdt")).forEach((item) => {
  //         item.classList.remove('rdtOpen');
  //       })
  //     });
  
  //     return () => {
  //       document.removeEventListener("scroll", () => {
  //         Array.from(document.getElementsByClassName("rdt")).forEach((item) => {
  //           item.classList.remove('rdtOpen');
  //         })
  //       });
  //     }
  //   }catch(e){
  //     console.error(e);
  //   }
  // }, []);

  const typeAheadBackValueChange = (event, index, subIndex, subIndex2) => {
    let fields = structuredClone(tagsFormField);
    if (!event) {
      if (parseInt(index, 10) >= 0 && subIndex === null && subIndex2 === null) {
        if (fields[index].tag_value) {
          fields[index].tag_value = "";
          fields[index].isError = fields[index].isTagMandatory ? true : false;
          setTagsFormField(fields);
        }
      } else if (index >= 0 && subIndex !== null && subIndex2 === null) {
        onCrossClickTag(event, index, subIndex);
      } else if (index >= 0 && subIndex !== null && subIndex2 !== null) {
        onCrossClickTag(event, index, subIndex, subIndex2);
      }
    }
  };

  const setExistingTagAndTagList = (tagsDetail) => {
    if (tagsDetail.length > 0) {
      let existing = [];
      tagsDetail.forEach((tag) => {
        existing.push(tag.tag_key);
      });
      setExistingTagIds(existing);
      setNewAddTagList(tagsDetail);
    }
  };

  const getTMSCMDBTagDetail = (itemId) => {
    if (props.mainModule == "CMDB") {
      setLoading(true);
      axios
        .get("/api/tags/" + itemId, {})
        .then((resp2) => {
          if (
            typeof resp2.data !== "undefined" &&
            typeof resp2.data.tagDetails !== "undefined" &&
            typeof resp2.data.tagDetails?.tag_data !== "undefined" &&
            resp2.data.tagDetails.tag_data.length > 0
          ) {
            setExistingTagAndTagList(resp2.data.tagDetails.tag_data);
          } else {
            setExistingTagIds([]);
            setNewAddTagList([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      setLoading(true);
      axios
        .get("/api/tag/getTags/" + itemId, {})
        .then((resp2) => {
          if (
            typeof resp2.data !== "undefined" &&
            typeof resp2.data.tagDatails !== "undefined" &&
            typeof resp2.data.tagDatails?.tag_data !== "undefined" &&
            resp2.data.tagDatails.tag_data.length > 0
          ) {
            setExistingTagAndTagList(resp2.data.tagDatails.tag_data);
          } else {
            setExistingTagIds([]);
            setNewAddTagList([]);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  //// handleValueChange
  const handleValueChange = (event, index, subIndex = null, subIndex2) => {
    let fields = structuredClone(tagsFormField);
    let value = event?.target?.value;
    const selectedTag = FdnTagList.filter((values) => values.tagId == value)[0];
    if (selectedTag && selectedTag.tagType === "Composite") {
      onCompositeTagSelection(selectedTag, index);
    } else if(selectedTag && selectedTag.tagType === "Checklist") {
      onChecklistTagSelection(selectedTag, index);
    } else if (typeof event.target !== "undefined" && event.target.name === "tag" && event.target.value && (selectedTag.tagValueDataType == "Dropdown" || selectedTag.tagValueDataType == "Typeahead") && selectedTag.tagType == "Simple") {
      onLoadSimpleDropdownTypeAhead(selectedTag, index);
    } else {
      if (typeof event.target !== "undefined" && event.target.name === "tag") {
        if (!event.target.value) {
          fields[index].selectedTag = "";
          fields[index].tag_key = "";
          fields[index].tag_name = "";
          fields[index].tag_type = "Text";
          fields[index].mainTagType = "";
          fields[index].tagChildValues = [];
          fields[index].attributes_value = [];
          fields[index].tag_value = "";
          fields[index].isTagError = true;
          fields[index].charLimit = 0;
          fields[index].isTagMandatory = true;
        } else {
          fields[index].selectedTag = selectedTag.tagId;
          fields[index].tag_key = selectedTag.tagId;
          fields[index].tag_name = selectedTag.tagLabel;
          fields[index].tag_type = selectedTag.tagValueDataType;
          fields[index].mainTagType = selectedTag.tagType;
          fields[index].tagChildValues = [];
          fields[index].attributes_value = [];
          fields[index].tag_value = "";
          fields[index].isTagError = false;
          fields[index].charLimit = selectedTag.tagCharLimit;
          fields[index].isTagMandatory = selectedTag.tagMandatory;
        }
      } else if(fields[index].mainTagType == 'Checklist') {
        if (typeof event.target !== "undefined" && event.target.name == "value") {
          fields[index].tagChildValues[subIndex].selectedTag = value;
          fields[index].tagChildValues[subIndex].tag_value = value;
          fields[index].tagChildValues[subIndex].isError = false;
        } else if (typeof fields[index].tagChildValues[subIndex].tag_type !== "undefined" && fields[index].tagChildValues[subIndex].tag_type == "Date") {
          let tagval = moment(
            event,
            dateformat.slice(0, 10).toUpperCase()
          ).format("YYYY-MM-DD");
          if (tagval !== "Invalid date") {
            fields[index].tagChildValues[subIndex].selectedTag = tagval;
            fields[index].tagChildValues[subIndex].tag_value = tagval;
            fields[index].tagChildValues[subIndex].isError = false;
          } else {
            fields[index].tagChildValues[subIndex].tag_value = "";
            fields[index].tagChildValues[subIndex].isError = fields[index].tagChildValues[subIndex].isTagMandatory ? true : false;
          }
        } else if (typeof fields[index].tagChildValues[subIndex].tag_type !== "undefined" && fields[index].tagChildValues[subIndex].tag_type == "DateTime") {
          let tagval = moment(
            event,
            dateformat.slice(0, 10).toUpperCase() + " HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss");
          if (tagval !== "Invalid date") {
            fields[index].tagChildValues[subIndex].selectedTag = tagval;
            fields[index].tagChildValues[subIndex].tag_value = tagval;
            fields[index].tagChildValues[subIndex].isError = false;
          } else {
            fields[index].tagChildValues[subIndex].tag_value = "";
            fields[index].tagChildValues[subIndex].isError = fields[index].tagChildValues[subIndex].isTagMandatory ? true : false;
          }
        } else if (typeof fields[index].tagChildValues[subIndex].tag_type !== "undefined" && fields[index].tagChildValues[subIndex].mainTagType == "Simple" && fields[index].tagChildValues[subIndex].tag_type == "Typeahead") {
          if (typeof event !== "undefined" && typeof event[0] !== "undefined") {
            fields[index].tagChildValues[subIndex].selectedTag = event[0].id;
            fields[index].tagChildValues[subIndex].tag_value = event[0].id;
            fields[index].tagChildValues[subIndex].isError = false;
            fields[index].isError = false;
          }
        }
      } else {
        if (typeof event.target !== "undefined" && event.target.name == "value") {
          fields[index].tag_value = value;
          fields[index].isError = false;
        } else if (typeof fields[index].tag_type !== "undefined" && fields[index].tag_type == "Date") {
          let tagval = moment(
            event,
            dateformat.slice(0, 10).toUpperCase()
          ).format("YYYY-MM-DD");
          if (tagval !== "Invalid date") {
            fields[index].tag_value = tagval;
            fields[index].isError = false;
          } else {
            fields[index].tag_value = "";
            fields[index].isError = fields[index].isTagMandatory ? true : false;
          }
        } else if (typeof fields[index].tag_type !== "undefined" && fields[index].tag_type == "DateTime") {
          let tagval = moment(
            event,
            dateformat.slice(0, 10).toUpperCase() + " HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss");
          if (tagval !== "Invalid date") {
            fields[index].tag_value = tagval;
            fields[index].isError = false;
          } else {
            fields[index].tag_value = "";
            fields[index].isError = fields[index].isTagMandatory ? true : false;
          }
        } else if (typeof fields[index].tag_type !== "undefined" && fields[index].mainTagType == "Simple" && fields[index].tag_type == "Typeahead") {
          if (typeof event !== "undefined" && typeof event[0] !== "undefined") {
            fields[index].tag_value = event[0].id;
            fields[index].isError = false;
          }
        }
      }
      setTagsFormField(fields);
    }
  };

  const onLoadSimpleDropdownTypeAhead = (selectedTag, index) => {
    let formField = structuredClone(tagsFormField);
    let tagId;
    if (showForm == "editForm") {
      tagId = formField[index].tag_key;
      formField[index].tag_value = "";
    } else {
      formField[index].selectedTag = selectedTag.tagId;
      formField[index].tag_key = selectedTag.tagId;
      formField[index].tag_name = selectedTag.tagLabel;
      formField[index].tag_type = selectedTag.tagValueDataType;
      formField[index].mainTagType = selectedTag.tagType;
      formField[index].tagChildValues = [];
      formField[index].tag_value = "";
      formField[index].isTagError = false;
      formField[index].isTagMandatory = selectedTag.tagMandatory;
      tagId = selectedTag.tagId;
    }

    axios
      .get("/api/getTagMemberDependData", {
        headers: {
          query: JSON.stringify({
            tagId: tagId,
          }),
        },
      })
      .then((resp2) => {
        formField[index].attributes_value = resp2.data.data;
        setTagsFormField(formField);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  
  const onCrossClickChecklistSimpleAttr = (selectedTag, index, subIndex) => {
    let formField = structuredClone(tagsFormField);
    let tagId;
    if (showForm == "editForm") {
      tagId = formField[index]["tagChildValues"][subIndex].tag_key;
      formField[index]["tagChildValues"][subIndex].tag_value = "";
    } else {
      formField[index]["tagChildValues"][subIndex].selectedTag = selectedTag.tagId;
      formField[index]["tagChildValues"][subIndex].tag_key = selectedTag.tagId;
      formField[index]["tagChildValues"][subIndex].tag_name = selectedTag.tagLabel;
      formField[index]["tagChildValues"][subIndex].tag_type = selectedTag.tagValueDataType;
      formField[index]["tagChildValues"][subIndex].mainTagType = selectedTag.tagType;
      formField[index]["tagChildValues"][subIndex].tagChildValues = [];
      formField[index]["tagChildValues"][subIndex].tag_value = "";
      formField[index]["tagChildValues"][subIndex].isTagError = false;
      formField[index]["tagChildValues"][subIndex].isTagMandatory = selectedTag.tagMandatory;
      tagId = selectedTag.tagId;
    }

    axios
      .get("/api/getTagMemberDependData", {
        headers: {
          query: JSON.stringify({
            tagId: tagId,
          }),
        },
      })
      .then((resp2) => {
        formField[index]["tagChildValues"][subIndex].attributes_value = resp2.data.data;
        setTagsFormField(formField);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleMemberValueChange = (value, index, subIndex = null, subIndex2) => {
    const selectedTag = tagsFormField[index];
    let formField = structuredClone(tagsFormField);
    if(formField[index].mainTagType == 'Checklist') {
      if (typeof value[0] !== "undefined" && typeof value[0].id !== "undefined") {
        formField[index]["tagChildValues"][subIndex]["tagChildValues"][subIndex2].selectedTag = value[0].id;
        formField[index]["tagChildValues"][subIndex]["tagChildValues"][subIndex2].tag_value = value[0].id;
        formField[index]["tagChildValues"][subIndex]["tagChildValues"][subIndex2].isError = false;
        formField[index]["tagChildValues"][subIndex].isError = false;
        if (typeof selectedTag["tagChildValues"][subIndex]["tagChildValues"][subIndex2 + 1] !== "undefined") {
          axios
            .get("/api/getTagMemberDependData", {
              headers: {
                query: JSON.stringify({
                  tagId: selectedTag["tagChildValues"][subIndex]["tagChildValues"][subIndex2 + 1].tag_key,
                  parent: value[0].id,
                }),
              },
            })
            .then((resp2) => {
              formField[index]["tagChildValues"][subIndex]["tagChildValues"][subIndex2 + 1].attributes_value =
                resp2.data.data;
              setTagsFormField(formField);
            })
            .catch((error) => {
              console.log("error", error);
            });
        } else {
          setTagsFormField(formField);
        }
      }
    } else {
      if (typeof value[0] !== "undefined" && typeof value[0].id !== "undefined") {
        formField[index]["tagChildValues"][subIndex].selectedTag = value[0].id;
        formField[index]["tagChildValues"][subIndex].tag_value = value[0].id;
        formField[index]["tagChildValues"][subIndex].isError = false;
  
        if (typeof selectedTag["tagChildValues"][subIndex + 1] !== "undefined") {
          axios
            .get("/api/getTagMemberDependData", {
              headers: {
                query: JSON.stringify({
                  tagId: selectedTag["tagChildValues"][subIndex + 1].tag_key,
                  parent: value[0].id,
                }),
              },
            })
            .then((resp2) => {
              formField[index]["tagChildValues"][subIndex + 1].attributes_value =
                resp2.data.data;
              setTagsFormField(formField);
            })
            .catch((error) => {
              console.log("error", error);
            });
        } else {
          setTagsFormField(formField);
        }
      }
    }
  };

  const patchApisCall = (postData) => {
    if (props.mainModule === "BreakFix") {
      axios
        .patch(GLOBAL.breakFixPatchUrl + props.itemId, postData)
        .then(function (response) {
          setDisableBtn(false);
          props.showTags();
          dispatch(loadBreakFixEditDetails(props.itemId));
        })
        .catch(function (error) {
          console.log("error", error);
        });
    } else if (props.mainModule === "Change") {
      axios
        .patch(GLOBAL.changeEditUrl + props.itemId, postData)
        .then(function (response) {
          setDisableBtn(false);
          dispatch(loadChangeEditData(props.itemId, ""));
        })
        .catch(function (error) {});
    } else if (props.mainModule === "Problem") {
      axios
        .patch(GLOBAL.investigationEditUrl + props.itemId, postData)
        .then(function (response) {
          dispatch({ type: "FETCHING_DETAILS_IN_PROGRESS" });
          axios
            .get(
              `${GLOBAL.investigationDetailsUrl}/${response.data.investigationId}`
            )
            .then((investigationDetails) => {
              if (!investigationDetails) {
                throw Error(investigationDetails.statusText);
              }
              return investigationDetails;
            })
            .then((investigationDetails) => {
              setDisableBtn(false);
              dispatch({
                type: "INVESTIGATION_DETAILS_LOAD_SUCCESS",
                investigationDetails: investigationDetails,
              });
              dispatch({ type: "DETAILS_FETCHED_SUCCESSFULLY" });
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (props.mainModule === "Fulfillment") {
      axios
        .patch(GLOBAL.fulfillmentpost + props.itemId, postData)
        .then(function (response) {
          setDisableBtn(false);
          changeView("");
          dispatch(loadFulfillmentDetails(props.itemId));
          if (typeof response.data.tag !== "undefined" && response.data.tag) {
            setExistingTagAndTagList(JSON.parse(response.data.tag));
          } else {
            setExistingTagIds([]);
            setNewAddTagList([]);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (props.mainModule === "Task") {
      if (postData["tag"]) {
        postData = { tag_data: JSON.parse(postData["tag"]) };
      } else {
        postData = {};
      }
      axios
        .post("/api/tag/addTags/" + props.itemId, postData)
        .then(function (response) {
          setDisableBtn(false);
          changeView("");
          getTMSCMDBTagDetail(props.itemId);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (props.mainModule === "CMDB") {
      if (postData["tag"]) {
        postData = { tag_data: JSON.parse(postData["tag"]) };
      } else {
        postData = {};
      }
      axios
        .patch("/api/tags/" + props.itemId, postData)
        .then(function (response) {
          setDisableBtn(false);
          changeView("");
          getTMSCMDBTagDetail(props.itemId);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  // on save button click func

  /// check for duplicates

  const checkDuplicates = (fields) => {
    let newData = {};
    let duplicatedTags = [];
    for (let i in fields) {
      if (newData.hasOwnProperty(fields[i].tag_key)) {
        newData[fields[i].tag_key].push(fields[i].tag_key);
      } else {
        newData[fields[i].tag_key] = [fields[i].tag_key];
      }
    }
    for (let i in newData) {
      if (newData[i].length > 1) {
        let duplicate = fields.filter((res) => res.tag_key == i);
        duplicatedTags.push(duplicate[0].tag_name);
      }
    }
    return duplicatedTags;
  };

  const saveData = () => {
    if (validatePayload()) {
      Swal.fire({
        title: translator["Please fill all the mandatory fields"],
      });
    } else {
      let saveData = [];
      const existingFields = showForm === "editForm" ? [] : editData();
      let tagsFormField1 = [];
      if (existingFields && existingFields.length > 0) {
        tagsFormField1 = [...existingFields, ...tagsFormField];
      } else {
        tagsFormField1 = tagsFormField;
      }
      if (tagsFormField1.length > 0) {
        tagsFormField1.forEach((tagField) => {
          let tag = {
            tag_key: tagField.tag_key,
            tag_name: tagField.tag_name,
            tag_type: tagField.mainTagType,
            tag_data_type: tagField.tag_type,
            tag_value: tagField.tag_value?.trim(),
            tag_mandatory: tagField.isTagMandatory
            // charLimit: tagField.charLimit,
          };
          if (tagField.tagChildValues && tagField.tagChildValues.length > 0) {
            let tagValue = [];
            tagField.tagChildValues.forEach((tag1) => {
              let tag2 = {
                tag_key: tag1.tag_key,
                tag_name: tag1.tag_name,
                tag_type: tag1.mainTagType,
                tag_data_type: tag1.tag_type,
                tag_value: tag1.tag_value?.trim(),
                tag_mandatory: tag1.isTagMandatory
                // charLimit: tag1.charLimit,
              };
              if(tag1.tagChildValues && tag1.tagChildValues.length > 0) {
                let tagValue2 = [];
                tag1.tagChildValues.forEach((tag3) => {
                  tagValue2.push({
                    tag_key: tag3.tag_key,
                    tag_name: tag3.tag_name,
                    tag_type: tag3.mainTagType,
                    tag_data_type: tag3.tag_type,
                    tag_value: tag3.tag_value,
                    tag_mandatory: tag3.isTagMandatory
                  });
                });
                tag2.tag_value = tagValue2;
              }
              tagValue.push(tag2);
              // tagValue.push({
              //   tag_key: tag1.tag_key,
              //   tag_name: tag1.tag_name,
              //   tag_type: tag1.mainTagType,
              //   tag_data_type: tag1.tag_type,
              //   tag_value: tag1.tag_value,
              // });
            });
            tag.tag_value = tagValue;
          }
          saveData.push(tag);
        });
        if (saveData && saveData.length > 0) {
          const duplicatedTags = checkDuplicates(saveData);
          if (duplicatedTags.length > 0) {
            Swal.fire({
              title:
                translator["Please remove the duplicate attributes"] +
                ": " +
                duplicatedTags.join(","),
            });
          } else {
            let postjson = {};
            setDisableBtn(true);
            postjson["tag"] = JSON.stringify(saveData);
            postjson["modifiedBy"] = "";
            postjson["modifiedByName"] = "";
            patchApisCall(postjson);
          }
        }
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        }).then((result) => {
          if (result.isConfirmed) {
            let postjson = {};
            postjson["tag"] = "";
            postjson["modifiedBy"] = "";
            postjson["modifiedByName"] = "";
            patchApisCall(postjson);
          }
        });
      }
    }
  };

  ///
  const editData = () => {
    let formField = [];
    let tagData = newAddTagList;
    if (tagData.length > 0) {
      tagData.forEach((tag) => {
        let tempInitial = structuredClone(initialField);
        tempInitial.tag_key = tag.tag_key;
        tempInitial.tag_name = tag.tag_name;
        tempInitial.tag_type = tag.tag_data_type;
        tempInitial.selectedTag = tag.tag_key;
        tempInitial.mainTagType = tag.tag_type;
        tempInitial.charLimit = tag.charLimit;
        tempInitial.isTagMandatory = tag.tag_mandatory;
        if (Array.isArray(tag.tag_value) && tag.tag_value.length > 0) {
          let memberTags = [];
          tag.tag_value.forEach((memberTag) => {
            let memberInitialTag = structuredClone(initialField);
            memberInitialTag.tag_key = memberTag.tag_key;
            memberInitialTag.tag_name = memberTag.tag_name;
            memberInitialTag.tag_type = memberTag.tag_data_type;
            memberInitialTag.selectedTag = memberTag.tag_value;
            // memberInitialTag.tag_value = memberTag.tag_value;
            memberInitialTag.mainTagType = memberTag.tag_type;
            memberInitialTag.charLimit = memberTag.charLimit;
            memberInitialTag.isTagMandatory = memberTag.tag_mandatory;
            if (Array.isArray(memberTag.tag_value) && memberTag.tag_value.length > 0) {
              let subMemberTags = [];
              memberTag.tag_value.forEach((subMemberTag) => {
                let subMemberInitialTag = structuredClone(initialField);
                subMemberInitialTag.tag_key = subMemberTag.tag_key;
                subMemberInitialTag.tag_name = subMemberTag.tag_name;
                subMemberInitialTag.tag_type = subMemberTag.tag_data_type;
                subMemberInitialTag.selectedTag = subMemberTag.tag_value;
                subMemberInitialTag.tag_value = subMemberTag.tag_value;
                subMemberInitialTag.mainTagType = subMemberTag.tag_type;
                subMemberInitialTag.charLimit = subMemberTag.charLimit;
                subMemberInitialTag.isTagMandatory = subMemberTag.tag_mandatory;
                subMemberTags.push(subMemberInitialTag);
              });
              memberInitialTag.tagChildValues = subMemberTags;
            } else {
              memberInitialTag.tag_value = memberTag.tag_value;
            }
            memberTags.push(memberInitialTag);
          });
          tempInitial.tagChildValues = memberTags;
        } else {
          tempInitial.tag_value = tag.tag_value;
        }
        formField.push(tempInitial);
      });
      return formField;
    }
  };

  /// validate payload
  const validatePayload = () => {
    let fields = structuredClone(tagsFormField);
    let error = false;
    if (fields.length > 0) {
      for (let i = 0; i < fields.length; i++) {
        if (!fields[i].selectedTag || fields[i].isTagError) {
          fields[i].isTagError = true;
          error = true;
          break;
        } else {
          fields[i].isTagError = false;
        }
        if (!fields[i].tag_value.trim() && fields[i].mainTagType != "Composite" && fields[i].mainTagType != "Checklist" && fields[i].isTagMandatory) {
          fields[i].isError = true;
          error = true;
          break;
        } else {
          fields[i].isError = false;
        }
        if(fields[i].mainTagType == "Checklist") {
          if (fields[i]["tagChildValues"].length > 0) {
            for (let j = 0; j < fields[i]["tagChildValues"].length; j++) {
              if (!fields[i]["tagChildValues"][j].selectedTag && fields[i]["tagChildValues"][j].isTagMandatory) {
                fields[i]["tagChildValues"][j].isError = true;
                error = true;
                break;
              } else {
                fields[i]["tagChildValues"][j].isError = false;
              }
              if (!fields[i]["tagChildValues"][j].tag_value.trim() && fields[i]["tagChildValues"][j].mainTagType != "Composite" && fields[i]["tagChildValues"][j].mainTagType != "Checklist" && fields[i]["tagChildValues"][j].isTagMandatory) {
                fields[i]["tagChildValues"][j].isError = true;
                error = true;
                break;
              } else {
                fields[i]["tagChildValues"][j].isError = false;
              }
              if (fields[i]["tagChildValues"][j]["tagChildValues"].length > 0) {
                for (let k = 0; k < fields[i]["tagChildValues"][j]["tagChildValues"].length; k++) {
                  if (!fields[i]["tagChildValues"][j]["tagChildValues"][k].tag_value && fields[i]["tagChildValues"][j]["tagChildValues"][k].isTagMandatory) {
                    fields[i]["tagChildValues"][j]["tagChildValues"][k].isError = true;
                    error = true;
                  } else {
                    fields[i]["tagChildValues"][j]["tagChildValues"][k].isError = false;
                  }
                }
              }
            }
          }
        } else {
          if (fields[i]["tagChildValues"].length > 0) {
            for (let j = 0; j < fields[i]["tagChildValues"].length; j++) {
              if (!fields[i]["tagChildValues"][j].tag_value && fields[i]["tagChildValues"][j].isTagMandatory) {
                fields[i]["tagChildValues"][j].isError = true;
                error = true;
              } else {
                fields[i]["tagChildValues"][j].isError = false;
              }
            }
          }
        }
      }
    }
    setTagsFormField(fields);
    return error;
  };

  /// to control form

  const changeView = async (type) => {
    setShowError(false);
    setShowErrorText("");
    if (type == "" || type == "addForm") {
      setTagsFormField([initialField]);
    }
    if (type == "editForm") {
      const editField = editData();
      if (editField.length > 0) {
        let editFieldWithAttributeValues = await getAttributeValuesForNonMandatoryFields(editField);
        let finalFormField = setMandatoryValuesForChecklistCompositeMembers(editFieldWithAttributeValues);
        setTagsFormField(finalFormField);
      }
    }
    setShowForm(type);
  };

  const getAttributeValuesForNonMandatoryFields = async (formField) => {
    let editFieldWithAttributeValues = structuredClone(formField);
    for (const tag of editFieldWithAttributeValues) {
      if (tag.mainTagType != "Composite" && tag.mainTagType != "Checklist" && tag.tag_value == "" && (tag.tag_type == 'Dropdown' || tag.tag_type == 'Typeahead')) {
        let query = {
          tagId: tag.tag_key
        };
        const resp = await axios.get("/api/getTagMemberDependData", { headers: { query: JSON.stringify(query) } });
        if (resp.status == 204) {
          tag.attributes_value = [];
        } else {
          tag.attributes_value = resp.data.data;
        }
      } else if ((tag.mainTagType == "Composite" || tag.mainTagType == "Checklist") && tag.tagChildValues.length > 0) {
        let count1 = 0;
        for (const subTag of tag.tagChildValues) {
          if (subTag.mainTagType == "Simple" && subTag.tag_value == "" && (subTag.tag_type == 'Dropdown' || subTag.tag_type == 'Typeahead')) {
            let query = {
              tagId: subTag.tag_key
            };
            const resp = await axios.get("/api/getTagMemberDependData", { headers: { query: JSON.stringify(query) } });
            if (resp.status == 204) {
              subTag.attributes_value = [];
            } else {
              subTag.attributes_value = resp.data.data;
            }
          } else if (subTag.mainTagType == "Member" && subTag.tag_value == "" && (subTag.tag_type == 'Dropdown' || subTag.tag_type == 'Typeahead') && count1 == 0) {
            count1++;
            let query = {
              tagId: subTag.tag_key
            };
            const resp = await axios.get("/api/getTagMemberDependData", { headers: { query: JSON.stringify(query) } });
            if (resp.status == 204) {
              subTag.attributes_value = [];
            } else {
              subTag.attributes_value = resp.data.data;
            }
          } else if (subTag.mainTagType == "Composite" && subTag.tagChildValues.length > 0) {
            let count2 = 0;
            for (const subMemberTag of subTag.tagChildValues) {
              if (subMemberTag.tag_value == "" && (subMemberTag.tag_type == 'Dropdown' || subMemberTag.tag_type == 'Typeahead') && count2 == 0) {
                count2++;
                let query = {
                  tagId: subMemberTag.tag_key
                };
                const resp = await axios.get("/api/getTagMemberDependData", { headers: { query: JSON.stringify(query) } });
                if (resp.status == 204) {
                  subMemberTag.attributes_value = [];
                } else {
                  subMemberTag.attributes_value = resp.data.data;
                }
              }
            }
          }
        }
      }
    }
    return editFieldWithAttributeValues;
  }

  ///// render table data

  const renderTagData = (list) => {
    return list.map((data, index) => {
      let id = `tag-list-${data.tag_key}`;
      let jwtdf = dateformat.slice(0, 10).toUpperCase();
      if (data.tag_type == "Composite") {
        let tagNames = [];
        let tagValues = [];
        if (Array.isArray(data.tag_value)) {
          data.tag_value.forEach((value) => {
            tagNames.push(value.tag_name);
            tagValues.push(value.tag_value);
          });
        }
        return (
          <tr key={id}>
            <td>
              <ul className="tagListData">
                {tagNames.map((res) => {
                  return <li title={res}>{res}</li>;
                })}
              </ul>
            </td>
            <td>
              <ul className="tagListDataValue">
                {tagValues.map((res) => {
                  return <li title={res}>{res}</li>;
                })}
              </ul>
            </td>
            {props.disableFieldsForGuestRole || props.isTerminalStatus ?'':<td>
              <div class="text-c">
              <i
                  title="Delete"
                  class="fa fa-trash-o cursorPoint"
                  onClick={() => {
                    deleteRow(index, true);
                  }}
                ></i>
              </div>
            </td>}
          </tr>
        );
      } else if (data.tag_type == "Checklist") {
        let tagNames = [];
        let tagValues = [];
        if (Array.isArray(data.tag_value)) {
          data.tag_value.forEach((data2) => {
            if(data2.tag_type == "Composite") {
              if (Array.isArray(data2.tag_value)) {
                data2.tag_value.forEach((data3) => {
                  tagNames.push(data3.tag_name);
                  tagValues.push(data3.tag_value);
                });
              }
            } else {
              let tagValue;
              if (data2.tag_data_type == "Date") {
                tagValue = moment(data2.tag_value).format(jwtdf);
              } else if (data2.tag_data_type == "DateTime") {
                tagValue =  props.mainModule === "CMDB" ? moment(data2.tag_value).format(jwtdf + " HH:mm:ss ") : moment(data2.tag_value).format(jwtdf + " hh:mm A");
              } else {
                tagValue = data2.tag_value;
              }
              if (tagValue === "Invalid date") {
                tagValue = '';
              }
              tagNames.push(data2.tag_name);
              tagValues.push(tagValue);
            }
          });
        }
        return (
          <tr key={id}>
            <td>
              <ul className="tagListData">
                {tagNames.map((res) => {
                  return <li title={res}>{res}</li>;
                })}
              </ul>
            </td>
            <td>
              <ul className="tagListDataValue">
                {tagValues.map((res) => {
                  if(res == '')
                    return <li>-</li>;
                  else
                    return <li title={res}>{res}</li>;
                })}
              </ul>
            </td>
            {props.disableFieldsForGuestRole || props.isTerminalStatus ?'':<td>
              <div class="text-c">
              <i
                  title="Delete"
                  class="fa fa-trash-o cursorPoint"
                  onClick={() => {
                    deleteRow(index, true);
                  }}
                ></i>
              </div>
            </td>}
          </tr>
        );
      } else {
        let tagValue;
        if (data.tag_data_type == "Date") {
          tagValue = moment(data.tag_value).format(jwtdf);
        } else if (data.tag_data_type == "DateTime") {
          tagValue =  props.mainModule === "CMDB" ? moment(data.tag_value).format(jwtdf + " HH:mm:ss ") : moment(data.tag_value).format(jwtdf + " hh:mm A");
        } else {
          tagValue = data.tag_value;
        }
        return (
          <tr key={id}>
            <td>{data.tag_name}</td>
            <td>{tagValue}</td>
              {props.disableFieldsForGuestRole || props.isTerminalStatus ?null:<td><div class="text-c"><i
                  title="Delete"
                  class="fa fa-trash-o cursorPoint"
                  onClick={() => {
                    deleteRow(index, true);
                  }}
                ></i>
              </div>
            </td>}
          </tr>
        );
      }
    });
  };

  /// add row function
  const addRow = () => {
    let formField = structuredClone(tagsFormField);
    formField.push(initialField);
    setTagsFormField(formField);
  };

  // delete row function

  const deleteRow = (index, isTableRow = false) => {
    if (isTableRow) {
      newAddTagList.splice(index, 1);
      let tag;
      if (newAddTagList.length > 0) {
        tag = JSON.stringify(newAddTagList);
      } else {
        tag = "";
      }
      patchApisCall({ tag: tag,  modifiedBy: "", modifiedByName: ""});
    } else {
      let formFields = structuredClone(tagsFormField);
      formFields.splice(index, 1);
      setTagsFormField(formFields);
    }
  };

  ///// sort functionality started
  function buildTreeWithRelationships(data, parentTagKey = 0) {
    for (const item of data) {
      item.children = data.filter((child) => child.parentTagKey == item.tagId);
    }
    return data.filter((res) => res.parentTagKey == 0);
  }

  const getChildren = (parent, allData = []) => {
    if (parent && parent.children && parent.children.length > 0) {
      for (const child of parent.children) {
        allData.push(child);
        getChildren(child, allData);
      }
    }
    return allData;
  };

  const sorDataWithParameKey = (data) => {
    let sortedData = [];
    const dataWithRelationships = buildTreeWithRelationships(data);
    const returnedData = getChildren(dataWithRelationships[0]);
    if (returnedData && returnedData.length > 0) {
      sortedData = [dataWithRelationships[0], ...returnedData];
    } else if (dataWithRelationships[0]) {
      sortedData = [dataWithRelationships[0]];
    }
    return sortedData;
  };

  /// sort functionality ended here

  /// on cross click function

  const onCrossClickTag = (event, index, subIndex, subIndex2) => {
    let fields = structuredClone(tagsFormField);
    if (showForm === "editForm") {
      let selectedTag = fields[index];
      if (typeof selectedTag["tagChildValues"] !== "undefined" && selectedTag["tagChildValues"].length > 0) {
        let selectedSubTag = selectedTag["tagChildValues"][subIndex]; 
        if(typeof selectedSubTag["tagChildValues"] !== 'undefined' && selectedSubTag["tagChildValues"].length > 0) {
          let query = {
            tagId: selectedSubTag["tagChildValues"][subIndex2].tag_key,
          };
          if (typeof selectedSubTag["tagChildValues"][subIndex2 - 1] !== "undefined" && selectedSubTag["tagChildValues"][subIndex2 - 1].tag_value) {
            query["parent"] = selectedSubTag["tagChildValues"][subIndex2 - 1].tag_value;
          }
          axios
            .get("/api/getTagMemberDependData", {
              headers: {
                query: JSON.stringify(query),
              },
            })
            .then((resp2) => {
              for (let i = subIndex2; i < fields[index]["tagChildValues"][subIndex]["tagChildValues"].length; i++) {
                fields[index]["tagChildValues"][subIndex]["tagChildValues"][i].tag_value = "";
                fields[index]["tagChildValues"][subIndex]["tagChildValues"][i].selectedTag = "";
                fields[index]["tagChildValues"][subIndex]["tagChildValues"][i].isError = true;
                fields[index]["tagChildValues"][subIndex]["tagChildValues"][i].attributes_value = [];
              }
              fields[index]["tagChildValues"][subIndex]["tagChildValues"][subIndex2].attributes_value = resp2.data.data;
              fields[index]["tagChildValues"][subIndex]["tagChildValues"][subIndex2].tag_value = "";
              fields[index]["tagChildValues"][subIndex]["tagChildValues"][subIndex2].selectedTag = "";
              fields[index]["tagChildValues"][subIndex]["tagChildValues"][subIndex2].isError = true;
              setTagsFormField(fields);
            })
            .catch((error) => {
              console.log("error", error);
            });
        } else if (selectedSubTag.mainTagType == "Simple" && selectedSubTag.tag_type == "Typeahead") {
          onCrossClickChecklistSimpleAttr(fields[index]["tagChildValues"][subIndex], index, subIndex);
        } else {
          let query = {
            tagId: selectedTag["tagChildValues"][subIndex].tag_key,
          };
          if (typeof selectedTag["tagChildValues"][subIndex - 1] !== "undefined" && selectedTag["tagChildValues"][subIndex - 1].tag_value) {
            query["parent"] = selectedTag["tagChildValues"][subIndex - 1].tag_value;
          }
          axios
            .get("/api/getTagMemberDependData", {
              headers: {
                query: JSON.stringify(query),
              },
            })
            .then((resp2) => {
              for (let i = subIndex; i < fields[index]["tagChildValues"].length; i++) {
                fields[index]["tagChildValues"][i].tag_value = "";
                fields[index]["tagChildValues"][i].selectedTag = "";
                fields[index]["tagChildValues"][i].isError = true;
                fields[index]["tagChildValues"][i].attributes_value = [];
              }
              fields[index]["tagChildValues"][subIndex].attributes_value = resp2.data.data;
              fields[index]["tagChildValues"][subIndex].tag_value = "";
              fields[index]["tagChildValues"][subIndex].selectedTag = "";
              fields[index]["tagChildValues"][subIndex].isError = true;
              setTagsFormField(fields);
            })
            .catch((error) => {
              console.log("error", error);
            });
        }
      } else if (selectedTag.mainTagType == "Simple" && selectedTag.tag_type == "Typeahead") {
        onLoadSimpleDropdownTypeAhead(fields[index], index);
      }
    } else {
      if (subIndex2 !== null) {
        for (let i = subIndex2; i < fields[index].tagChildValues[subIndex].tagChildValues.length; i++) {
          fields[index]["tagChildValues"][subIndex]["tagChildValues"][i].tag_value = "";
          fields[index]["tagChildValues"][subIndex]["tagChildValues"][i].selectedTag = "";
          fields[index]["tagChildValues"][subIndex]["tagChildValues"][i].isError = true;
          if (subIndex2 !== i) {
            fields[index]["tagChildValues"][subIndex]["tagChildValues"][i].attributes_value = [];
          }
        }
      } else if (subIndex !== null) {
        for (let i = subIndex; i < fields[index].tagChildValues.length; i++) {
          fields[index]["tagChildValues"][i].tag_value = "";
          fields[index]["tagChildValues"][i].selectedTag = "";
          fields[index]["tagChildValues"][i].isError = fields[index]["tagChildValues"][i].isTagMandatory ? true : false;
          if (subIndex !== i) {
            fields[index]["tagChildValues"][i].attributes_value = [];
          }
        }
      } else {
        fields[index].tag_value = "";
        fields[index].isError = fields[index].isTagMandatory ? true : false;
      }
      setTagsFormField(fields);
    }
  };

  // on composite tag selection
  const onCompositeTagSelection = (selectedTag, index) => {
    axios
      .get(`/api/getMemberTagsForComposite/${selectedTag.tagId}/Active`)
      .then((resp) => {
        if (resp.data && resp.data.length > 0) {
          const sortedData = sorDataWithParameKey(resp.data);
          let respData = [];
          if (Array.isArray(sortedData) && sortedData.length > 0) {
            sortedData.forEach((data) => {
              respData.push({
                tag_key: data.tagId,
                tag_name: data.tagLabel,
                tag_type: data.tagValueDataType,
                tag_value: "",
                mainTagType: data.tagType,
                selectedTag: "",
                tagChildValues: [],
                attributes_value: [],
                parentTagId: "",
                isError: false,
                isTagMandatory: data.tagMandatory
              });
            });
            axios
              .get("/api/getTagMemberDependData", {
                headers: {
                  query: JSON.stringify({ tagId: respData[0].tag_key }),
                },
              })
              .then((resp2) => {
                respData[0].attributes_value = resp2.data.data;
                let formField = structuredClone(tagsFormField);
                formField[index].selectedTag = selectedTag.tagId;
                formField[index].tag_key = selectedTag.tagId;
                formField[index].tag_name = selectedTag.tagLabel;
                formField[index].tag_type = selectedTag.tagValueDataType;
                formField[index].mainTagType = selectedTag.tagType;
                formField[index].tagChildValues = respData;
                formField[index].isTagError = false;
                formField[index].isTagMandatory = selectedTag.tagMandatory;
                setTagsFormField(formField);
              });
          } else {
            let formField = structuredClone(tagsFormField);
            formField[index].selectedTag = selectedTag.tagId;
            formField[index].tag_key = selectedTag.tagId;
            formField[index].tag_name = selectedTag.tagLabel;
            formField[index].tag_type = selectedTag.tagValueDataType;
            formField[index].mainTagType = selectedTag.tagType;
            formField[index].tagChildValues = [];
            formField[index].isTagError = true;
            formField[index].isTagMandatory = selectedTag.tagMandatory;
            setTagsFormField(formField);
          }
        }
      });
  };

  const setMandatoryValuesForChecklistCompositeMembers = (formField) => {
    let finalFormField = structuredClone(formField);
    finalFormField.forEach((item) => {
      item.tagChildValues.forEach((item1) => {
        if(item1.mainTagType == 'Composite') {
          item1.tagChildValues.forEach((item2) => {
            item2.isTagMandatory = item1.isTagMandatory;
          });
        }
      });
    });
    return finalFormField;
  }

  // on checklist tag selection
  const onChecklistTagSelection = (selectedTag, index) => {
    axios
      .get(`/api/getMemberTagsForChecklist/${selectedTag.tagId}/Active`)
      .then((resp) => {
        if (resp.data && resp.data.length > 0) {
          let sortedData = resp.data.sort(function (a, b) {
            return parseInt(a.sequence, 10) - parseInt(b.sequence, 10);
          });
          let respData = [];
          if (Array.isArray(sortedData) && sortedData.length > 0) {
            let tagStr = "";
            sortedData.forEach((data, index) => {
              tagStr += data.tagId;
              if (index < sortedData.length - 1)
                tagStr += ',';
              respData.push({
                tag_key: data.tagId,
                tag_name: data.tagLabel,
                tag_type: data.tagValueDataType,
                tag_value: "",
                mainTagType: data.tagType,
                selectedTag: "",
                tagChildValues: [],
                attributes_value: [],
                parentTagId: "",
                isError: false,
                isTagMandatory: data.tagMandatory
              });
            });
            axios.get("/api/getTagMemberDependDataForMultipleIds", {
              headers: {
                query: JSON.stringify({ tagId: tagStr }),
              },
            })
              .then((resp2) => {
                let compTagStr = "";
                let compCount = 0;
                respData.forEach((item, index) => {
                  item.attributes_value = resp2.data[index].data;
                  if (item.mainTagType == 'Composite') {
                    if (compCount > 0) {
                      compTagStr += ',';
                    }
                    item.selectedTag = item.tag_key;
                    compTagStr += item.tag_key;
                    compCount++;
                  }
                });
                if (compTagStr != '') {
                  axios
                    .get(`/api/getMemberTagsForComposite/${compTagStr}/Active`)
                    .then((resp) => {
                      if (resp.data && resp.data.length > 0) {
                        if (compCount > 1) {
                          let tagStr = "";
                          let respData2 = [];
                          resp.data.forEach((compTag,index) => {
                            let sortedData = sorDataWithParameKey(compTag.members);
                            let respData3 = [];
                            let memberObj = {};
                            if (Array.isArray(sortedData) && sortedData.length > 0) {
                              sortedData.forEach((data) => {
                                respData3.push({
                                  tag_key: data.tagId,
                                  tag_name: data.tagLabel,
                                  tag_type: data.tagValueDataType,
                                  tag_value: "",
                                  mainTagType: data.tagType,
                                  selectedTag: "",
                                  tagChildValues: [],
                                  attributes_value: [],
                                  parentTagId: "",
                                  isError: false,
                                  isTagMandatory: data.tagMandatory
                                });
                              });
                              tagStr += respData3[0].tag_key;
                              if (index < resp.data.length - 1)
                                tagStr += ',';
                            }
                            memberObj['input_tag_id']=compTag.input_tag_id;
                            memberObj['tagMembers']=respData3;
                            respData2.push(memberObj);
                          })
                          axios.get("/api/getTagMemberDependDataForMultipleIds", {
                            headers: {
                              query: JSON.stringify({ tagId: tagStr }),
                            },
                          })
                          .then((resp2) => {
                            respData2.forEach((item,index) => {
                              item.tagMembers[0].attributes_value=resp2.data[index].data;
                            });
                            respData.forEach((item) => {
                              respData2.forEach((item2) => {
                                if (item.tag_key == item2.input_tag_id) {
                                  item.tagChildValues = item2.tagMembers;
                                }
                              })
                            });
                            let formField = structuredClone(tagsFormField);
                            formField[index].selectedTag = selectedTag.tagId;
                            formField[index].tag_key = selectedTag.tagId;
                            formField[index].tag_name = selectedTag.tagLabel;
                            formField[index].tag_type = selectedTag.tagValueDataType;
                            formField[index].mainTagType = selectedTag.tagType;
                            formField[index].tagChildValues = respData;
                            formField[index].isTagError = false;
                            formField[index].isTagMandatory = selectedTag.tagMandatory;
                            let finalFormField = setMandatoryValuesForChecklistCompositeMembers(formField);
                            setTagsFormField(finalFormField);
                          })
                        } else {
                          const sortedData = sorDataWithParameKey(resp.data);
                          let respData2 = [];
                          if (Array.isArray(sortedData) && sortedData.length > 0) {
                            sortedData.forEach((data) => {
                              respData2.push({
                                tag_key: data.tagId,
                                tag_name: data.tagLabel,
                                tag_type: data.tagValueDataType,
                                tag_value: "",
                                mainTagType: data.tagType,
                                selectedTag: "",
                                tagChildValues: [],
                                attributes_value: [],
                                parentTagId: "",
                                isError: false,
                                isTagMandatory: data.tagMandatory
                              });
                            });
                            axios
                              .get("/api/getTagMemberDependData", {
                                headers: {
                                  query: JSON.stringify({ tagId: respData2[0].tag_key }),
                                },
                              })
                              .then((resp2) => {
                                respData2[0].attributes_value = resp2.data.data;
                                respData.forEach((item, index) => {
                                  if (item.tag_key == compTagStr) {
                                    item.tagChildValues = respData2;
                                  }
                                });
                                let formField = structuredClone(tagsFormField);
                                formField[index].selectedTag = selectedTag.tagId;
                                formField[index].tag_key = selectedTag.tagId;
                                formField[index].tag_name = selectedTag.tagLabel;
                                formField[index].tag_type = selectedTag.tagValueDataType;
                                formField[index].mainTagType = selectedTag.tagType;
                                formField[index].tagChildValues = respData;
                                formField[index].isTagError = false;
                                formField[index].isTagMandatory = selectedTag.tagMandatory;
                                let finalFormField = setMandatoryValuesForChecklistCompositeMembers(formField);
                                setTagsFormField(finalFormField);
                              })
                          }
                        }
                      }
                    })
                } else {
                  let formField = structuredClone(tagsFormField);
                  formField[index].selectedTag = selectedTag.tagId;
                  formField[index].tag_key = selectedTag.tagId;
                  formField[index].tag_name = selectedTag.tagLabel;
                  formField[index].tag_type = selectedTag.tagValueDataType;
                  formField[index].mainTagType = selectedTag.tagType;
                  formField[index].tagChildValues = respData;
                  formField[index].isTagError = false;
                  formField[index].isTagMandatory = selectedTag.tagMandatory;
                  setTagsFormField(formField);
                }
              })
          } else {
            let formField = structuredClone(tagsFormField);
            formField[index].selectedTag = selectedTag.tagId;
            formField[index].tag_key = selectedTag.tagId;
            formField[index].tag_name = selectedTag.tagLabel;
            formField[index].tag_type = selectedTag.tagValueDataType;
            formField[index].mainTagType = selectedTag.tagType;
            formField[index].tagChildValues = [];
            formField[index].isTagError = true;
            formField[index].isTagMandatory = selectedTag.tagMandatory;
            setTagsFormField(formField);
          }
        }
      });
  };

  const openCalendar = (index, subIndex) => {
    document.getElementsByClassName(`cal_${index}_${subIndex}`)[0].classList.add('rdtOpen');
  }

  const manageCalenderPosition = (index, subIndex) => {
    try{
      let calBottom = document.getElementsByClassName(`cal_${index}_${subIndex}`)[0].getElementsByClassName("rdtPicker")[0].getBoundingClientRect().bottom;
      let footerTop = document.getElementsByClassName("copyCrp")[0].getBoundingClientRect().top;
      if(calBottom > footerTop){
        document.getElementsByClassName(`cal_${index}_${subIndex}`)[0].classList.add('tagDateTimePosTop');
      } 
      else{
        document.getElementsByClassName(`cal_${index}_${subIndex}`)[0].classList.remove('tagDateTimePosTop');
      }
    }catch(e){
      console.error(e);
    }
  }

  const loadDynamicField = (row, index, subIndex = null, subIndex2) => {
    let attributeValueInputTypeEdit;
    if (row.tag_type == "Text") {
      attributeValueInputTypeEdit = (
        <Form.Control
          type="text"
          name="value"
          className={`${row.isError ? "error" : ""}`}
          value={row.tag_value}
          onChange={(event) => {
            handleValueChange(event, index, subIndex, subIndex2);
          }}
          maxLength={row.charLimit}
          onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}
        />
      );
    } else if (row.tag_type == "Dropdown") {
      let options = [];
      if (row.attributes_value && row.attributes_value.length > 0) {
        row.attributes_value.forEach((rowVal) => {
          options.push({
            ...rowVal,
            id: rowVal.tag_value,
            label: rowVal.tag_value,
          });
        });
      } else if (showForm === "editForm") {
        options.push({
          tag_value: row.tag_value,
          id: row.tag_value,
          label: row.tag_value,
        });
      }
      attributeValueInputTypeEdit = (
        <Form.Group className="form-group">
          <Form.Select
            componentClass="select"
            value={row.tag_value}
            name="value"
            className={`${row.isError ? "error form-control" : "form-control"}`}
            onChange={(event) => {
              handleValueChange(event, index, subIndex, subIndex2);
            }}
          >
            <option value="">Select</option>
            {options.map((opt) => {
              return (
                <option
                  selected={opt.tag_value == row.tag_value}
                  value={opt.tag_value}
                  key={"select-options"}
                >
                  {opt.tag_value}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      );
    } else if (row.tag_type == "DateTime") {
      attributeValueInputTypeEdit = (
        <div
          className={`${
            row.isError
              ? "dateTimeSdiv rbt error tagDateTimePos"
              : "dateTimeSdiv tagDateTimePos"
          }`}
        >
          <Datetime
            value={row.tag_value ? moment(row.tag_value) : ""}
            onChange={(event) => {
              handleValueChange(event, index, subIndex, subIndex2);
              event.key === "Enter" && event.preventDefault()
            }}
            locale="en"
            name="value"
            dateFormat={dateformat.slice(0, 10).toUpperCase()}
            timeFormat={true}
            className={`cal_${index}_${subIndex}`}
            closeOnClickOutside={true}
            closeOnSelect={false}
            onOpen={() => {
              manageCalenderPosition(index, subIndex);
            }}
            // renderInput={renderInput}
            inputProps={{
              onClick: () => {
                if(document.getElementsByClassName(`cal_${index}_${subIndex}`)[0]) {
                  openCalendar(index, subIndex);
                }
              }
            }}
          />
        </div>
      );
    } else if (row.tag_type == "Date") {
      attributeValueInputTypeEdit = (
        <div
          className={`${
            row.isError
              ? "dateTimeSdiv rbt error tagDateTimePos"
              : "dateTimeSdiv tagDateTimePos"
          }`}
        >
          <Datetime
            value={row.tag_value ? moment(row.tag_value) : ""}
            name="value"
            onChange={(event) => {
              handleValueChange(event, index, subIndex, subIndex2);
            }}
            locale="en"
            dateFormat={dateformat.slice(0, 10).toUpperCase()}
            timeFormat={false}
            className={`cal_${index}_${subIndex}`}
            closeOnClickOutside={true}
            closeOnSelect={false}
            // renderInput={renderInput}
            onOpen={() => {
              manageCalenderPosition(index, subIndex);
            }}
            inputProps={{
              onClick: () => {
                if(document.getElementsByClassName(`cal_${index}_${subIndex}`)[0]) {
                  openCalendar(index, subIndex);                  
                }
              }
            }}
          />
        </div>
      );
    } else if (row.tag_type == "Typeahead") {
      let options = [];
      if (row.attributes_value && row.attributes_value.length > 0) {
        row.attributes_value.forEach((rowVal) => {
          options.push({
            ...rowVal,
            id: rowVal.tag_value,
            label: rowVal.tag_value,
          });
        });
      }
      let selectedOptions = [];
      if (row.tag_value) {
        selectedOptions.push({ id: row.tag_value, label: row.tag_value });
      }
      attributeValueInputTypeEdit = (
        <Field
          id={row.tag_key + "-typeahead"}
          component={CmdbDropdownPlusTypeahead}
          formType={`${row.mainTagType == "Simple" ? "forCloIcn" : "create"}`}
          onSelection={(event) => {
            row.mainTagType == "Member"
              ? handleMemberValueChange(event, index, subIndex, subIndex2)
              : handleValueChange(event, index, subIndex, subIndex2);
          }}
          name="value"
          options={
            showForm == "editForm" && options.length == 0
              ? selectedOptions
              : options
          }
          className={`form-control`}
          onInputChange={(event) => {
            typeAheadBackValueChange(event, index, subIndex, subIndex2);
          }}
          onCrossClick={(event) => onCrossClickTag(event, index, subIndex, subIndex2)}
          inputValue={row.tag_value}
          defaultInputValue={row.tag_value}
          selectedOptions={showForm == "editForm" ? selectedOptions : ""}
          errorColor={`${row.isError ? "error" : ""}`}
          setErrorColor={() => {}}
        />
      );
    }
    return attributeValueInputTypeEdit;
  };

  const loadCompositeTagChildValues = (index, childTags) => {
    return (
      <Table className="compositeDataField forCloicn">
        {childTags.map((tag, subIndex) => {
          return (
            <tbody>
            <tr key={`child-${index}-${subIndex}`}>
              <td width={"50%"}>
                <Form.Control
                  type="text"
                  name="tag"
                  readOnly
                  value={tag.tag_name}
                  onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}
                ></Form.Control>
              </td>
              <td width={"50%"}>{loadDynamicField(tag, index, subIndex)}</td>
            </tr>
            </tbody>
          );
        })}
      </Table>
    );
  };
  
  const loadChecklistTagChildValues = (index, childTags) => {
    return (
      <Table className="compositeDataField forCloicn">
        {childTags.map((tag, subIndex) => {
          if(tag.mainTagType == 'Composite') {
            return (
              <tbody>
                <tr key={`child-${index}-${subIndex}`} className="position-re">
                  <td width={"50%"} className={tag.isTagMandatory? "mandtryPadding" : ""}>
                  {tag.isTagMandatory?<span className="rStar rStarPos"></span>:''}
                    <Form.Control
                      type="text"
                      name="tag"
                      readOnly
                      value={tag.tag_name}
                      onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}
                    ></Form.Control>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                  <Table className="compositeDataField forCloicn compositeSubField">
                    {tag.tagChildValues.map((tag2, subIndex2) => {
                      return (
                        <tbody>
                          <tr key={`child-${index}-${subIndex}-${subIndex2}`}>
                            <td width={"50%"}>
                              <Form.Control
                                type="text"
                                name="tag"
                                readOnly
                                value={tag2.tag_name}
                                onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}
                              ></Form.Control>
                            </td>
                            <td width={"50%"}>{loadDynamicField(tag2, index, subIndex, subIndex2)}</td>
                          </tr>
                        </tbody>
                      );
                    })}
                    </Table>
                  </td>
                </tr>                
              </tbody>
            );
          } else {
            return (
             <tbody>
              <tr key={`child-${index}-${subIndex}`} className="position-re">
                <td width={"50%"} className={tag.isTagMandatory? "mandtryPadding" : ""}>
                  {tag.isTagMandatory?<span className="rStar rStarPos"></span>:''}              
                  <Form.Control
                    type="text"
                    name="tag"
                    readOnly
                    value={tag.tag_name}
                    onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}
                  ></Form.Control>
                </td>
                <td width={"50%"} className={tag.isTagMandatory? "mandtryPadding" : ""}>{loadDynamicField(tag, index, subIndex)}</td>
              </tr>
              </tbody>
            );
          }
        })}
      </Table>
    );
  };

  /// render add form list

  const renderFormList = (tagsFormField) => {
    let fields = 0;
    return tagsFormField.map((tag, index) => {
      fields++;
      return (
        <>
          <tr key={index}>
            <td width={"47%"}>
              <Form.Group className="form-group">
                <Form.Select componentClass="select" className={`${tag.isTagError ? "error form-control" : "form-control"}`}
                  name="tag"
                  onChange={(event) => {
                    handleValueChange(event, index);
                  }}
                  disabled={showForm == "editForm"}
                >
                  <option value="">select</option>
                  {FdnTagList
                    ? FdnTagList.map((value, key) => {
                        if (!exisTagIds.includes(value.tagId) || showForm === "editForm") {
                          return (
                            <option
                              key={"tag-" + key}
                              value={value.tagId}
                              selected={tag.selectedTag == value.tagId}
                            >
                              {value.tagLabel}
                            </option>
                          );
                        }
                      })
                    : ""}
                </Form.Select>
              </Form.Group>
            </td>
            <td width={"47%"}>
              {tag.mainTagType !== "Composite" && tag.mainTagType !== "Checklist" && loadDynamicField(tag, index)}
            </td>
            {props.disableFieldsForGuestRole || props.isTerminalStatus ? '' : showForm == "editForm" && (
              <td className="text-c">
                <Button
                  title="Delete"
                  onClick={() => {
                    deleteRow(index);
                  }}
                >
                  <HiOutlineTrash />
                </Button>
              </td>
            )}
            {props.disableFieldsForGuestRole || props.isTerminalStatus ? '' : showForm == "addForm" && (
              <td className="text-c tag-btn-container">
                <ButtonToolbar>
                  {tagsFormField.length == fields &&
                  tagsFormField.length > 1 ? (
                    <Button
                      title="Delete"
                      onClick={() => {
                        deleteRow(index);
                      }}
                    >
                      <HiOutlineTrash />
                    </Button>
                  ) : (
                    ""
                  )}
                  {tagsFormField.length != fields ? (
                    <Button
                      disabled={props.disableFieldsForGuestRole || props.isTerminalStatus}
                      title="Delete"
                      onClick={() => {
                        deleteRow(index);
                      }}
                    >
                      <HiOutlineTrash />
                    </Button>
                  ) : (
                    <Button
                      disabled={props.disableFieldsForGuestRole || props.isTerminalStatus}
                      title="Add"
                      onClick={() => {
                        addRow();
                      }}
                    >
                      <HiPlus />
                    </Button>
                  )}
                </ButtonToolbar>
              </td>
            )}
          </tr>
          {tag.mainTagType == "Composite" && tag.tagChildValues.length > 0 && (
            <tr className="compositeBrdrBotm">
              <td colSpan={3}>
                {loadCompositeTagChildValues(index, tag.tagChildValues)}
              </td>
            </tr>
          )}
          {tag.mainTagType == "Checklist" && tag.tagChildValues.length > 0 && (
            <tr className="compositeBrdrBotm">
              <td colSpan={3}>
                {loadChecklistTagChildValues(index, tag.tagChildValues)}
              </td>
            </tr>
          )}
        </>
      );
    });
  };

  ///main render part
  return (
    <div className="hambTab watcher-container">
      <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
        <ul>
          {props.disableFieldsForGuestRole || props.isTerminalStatus || props.isFulfillmentSubmittedStatus ? '' : showForm != "addForm" && (
            <li>
              {(onAssetRoleVisible || onConfigManagerRoleVisible) || props.isD2CRolesEnabled
							? (
							<Button
                bsStyle=""
                bsClass=""
                bsPrefix=" "
                className="myBt"
                onClick={() => {
                  changeView("addForm");
                }}
                title={translator["Add"]}
              >
                <HiPlus />
              </Button>
							) : null}
            </li>
          )}
          {props.disableFieldsForGuestRole || props.isTerminalStatus || props.isFulfillmentSubmittedStatus ? '' : showForm != "editForm" && newAddTagList.length > 0 && (
            <li>
              {(onAssetRoleVisible || onConfigManagerRoleVisible) || props.isD2CRolesEnabled
							? (
                <Button
                bsStyle=""
                bsClass=""
                bsPrefix=" "
                className="myBt"
                onClick={() => {
                  changeView("editForm");
                }}
                title={translator["Edit"]}
              >
                <PiPencilSimpleBold />
              </Button>
							) : null}
            </li>
          )}
          <li className="d-md-none">
            <button
              type='button'
              title="Minimize the right panel"
              bsClass=""
              bsStyle=""
              className="closerightPanelBtn"
              onClick={() => {
                props.rightEditPanel(false);
                props.changeActionView("");
                props.changeRightView("allClose");
              }}
            >
              <IoClose />
            </button>
          </li>
        </ul>
      </div>
      <div className="rPageHeading">
        <div className="offNam margin-t-5 margin-b-5">
          {showForm == "addForm"
            ? translator["Add Custom Attribute"]
            : showForm == "editForm"
            ? translator["Edit Custom Attribute"]
            : translator["Custom Attribute"]}
        </div>
      </div>

      <div className="rBoxGap">
        {showError ? (
          <Alert
            style={{
              marginBottom: "15px",
              fontSize: "13px",
              padding: "8px 30px 8px 8px",
            }}
            variant="danger"
          >
            <h4>{showErrorText}</h4>
          </Alert>
        ) : (
          ""
        )}

        <div>
          {(showForm == "addForm" || showForm == "editForm") && (
            <Row className="borderForm  row">
              <Col md={12}>
                <Table className="attrBtn">
                  <thead>
                    <tr>
                      <th className="sort">{translator["Attribute"]}</th>
                      <th className="sort">{translator["Value"]}</th>
                      {props.disableFieldsForGuestRole || props.isTerminalStatus ?'' : <th className="text-c">{translator["Action"]}</th>}
                    </tr>
                  </thead>
                  <tbody>{renderFormList(tagsFormField)}</tbody>
                </Table>
              </Col>
              <Col md={12}>
                <ButtonToolbar>
                  <Button
                    onClick={() => {
                      saveData();
                    }}
                    bsSize="xsmall"
                    className="rgSidrkBtn smallBtn"
                    title={
                      showForm == "editForm"
                        ? translator["Save"]
                        : translator["Add"]
                    }
                    disabled={disableBtn || props.disableFieldsForGuestRole || props.isTerminalStatus}
                  >
                    {showForm == "editForm"
                      ? translator["Save"]
                      : translator["Add"]}
                  </Button>
                  <Button
                    disabled={props.disableFieldsForGuestRole || props.isTerminalStatus}
                    onClick={() => {
                      changeView("");
                    }}
                    bsSize="xsmall"
                    className="rgSiLigBtn smallBtn"
                    title={translator["Cancel"]}
                  >
                    {translator["Cancel"]}
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          )}

          {showForm == "" && (
            <>
              {/* <div className="f-size-14 margin-b-5 border-b">Added Tags</div> */}
              <Table
                bordered
                condensed
                hover
                responsive
                className="tableView tableWithOutPointer"
              >
                <thead>
                  <tr>
                    <th className="sort">{translator["Attribute"]}</th>
                    <th className="sort">{translator["Value"]}</th>
                    {props.disableFieldsForGuestRole || props.isTerminalStatus ?'' : <th className="text-c">{translator["Action"]}</th>}
                  </tr>
                </thead>
                {loading && (
                  <tbody>
                    <tr>
                      <td colSpan={3}>
                        <ListLoader />
                      </td>
                    </tr>
                  </tbody>
                )}
                {!loading && (
                  <tbody>
                    {newAddTagList && newAddTagList.length > 0 ? (
                      renderTagData(newAddTagList)
                    ) : (
                      <tr>
                        <td colSpan={3} className="text-c">
                          {translator["No Data Available"]}
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </Table>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default reduxForm({
  form: "TagDetailsForm",
})(TagDetails);
