
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState} from "react";
import { Row, Col, Button, Nav } from 'react-bootstrap';
import {useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { reduxForm } from "redux-form";
import { LuActivitySquare } from "react-icons/lu";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {HiOutlineTrash} from "react-icons/hi";
import axios from "axios";
import { navigationHooks } from "../../../../helpers/NavigationHook";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

const HeaderSec = (props) => {
    const navigate = useNavigate();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const dispatch = useDispatch();
    const params = useParams();
    const context = useContext(MyContext);
    const closeButton = useRef(null);
    const saveObjRef = useRef(null);

    useEffect(() => {
      context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
      context.newTabPrevent();
    }, []);
  
    const submit = async (values) => {
      let id = params.id
      let isValid = true;
        console.log("EditTOTHeaders",values, id)       
       
      if (!values.usedByRule || values.usedByRule == '') {
          props.setRuleErr("error");
          isValid = false;
        } else {
          props.setRuleErr("");
      }
      if (!values.tableLabel || values.tableLabel == '') {
          props.setLabelNameErr("error");
          isValid = false;
        } else {
          props.setLabelNameErr("");
      }
        if (isValid == true) {
        let postjson= [];
          let body = {
                tableName:values.tableName,
                tableLabel: values.tableLabel,
                moduleName:values.moduleName,
                microservice:values.microservice,
                usedByRule:values.usedByRule,
                id:id
              };
              postjson.push(body)
              console.log("postjson",postjson);
        return axios
        .patch(
          "/rules/data/updateTableDetails" , postjson).then((resp)=>{
            navigationHooks.navigate("/tot");
        })
        }
    };
    let { handleSubmit, submitting } = props;
    return (
      <>
        <Row className="margin-b-15">
          <Col lg={4} md={6} sm={6} xs={7}>
            <h1 bsClass="" className="sPageHeading1">
              {"Table of Tables"}
            </h1>
          </Col>
          <Col lg={8} md={6} sm={6} xs={5}>
            <div className={`float-r toprigLnk fulfillHeader`}>
              <Nav className="icnlnk riggep float-l" as="ul">
                <Nav.Item as="li" title={tr["Activity Details"]} eventKey={2}>
                  <a
                    className={props.view === "activity" ? "active" : ""}
                    href="javascript:void(0)"
                    onClick={() => {
                      props.setView("activity");
                      props.rightEditPanel(true);
                    }}
                  >
                    <LuActivitySquare />
                  </a>
                </Nav.Item>
              </Nav>

              <Nav as="ul" className="icnlnk">
                <Nav.Item
                  as="li"
                  eventKey={1}
                  disabled={submitting}
                  title={tr["Save"]}
                >
                  <Link
                    bsPrefix=" "
                    id="hdID"
                    to="javascript:void(0)"
                    className="myBt plus ctrlKeyPrevent"
                    disabled={props.loader}
                    onClick={handleSubmit(submit)}
                    title={tr["Save"]}
                    ref={saveObjRef}
                  >
                    <span>
                      {props.loader ? (
                        <ImSpinner6 className="icn-spinner" />
                      ) : (
                        <IoSaveOutline />
                      )}
                    </span>
                  </Link>
                </Nav.Item>

                <Nav.Item
                  as="li"
                  title={tr["Close"]}
                  className="ctrlKeyPrevent"
                  eventKey={3}
                >
                  <Link
                    bsPrefix=" "
                    to="/tot"
                    className="myBt cancel fillBtn ctrlKeyPrevent"
                    title={tr["Cancel"]}
                    ref={closeButton}
                  >
                    <IoClose />
                  </Link>
                </Nav.Item>
              </Nav>
            </div>
          </Col>
        </Row>
      </>
    );
}
export default reduxForm({
    form: "EditTOT",
  })(HeaderSec);