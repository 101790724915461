
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const buttonExtendTraits = (e) => {
  e.DomComponents.addType("button", {
    model: {
      defaults: {
        ...e.DomComponents.getType("button").model.prototype.defaults,
        traits: [
          ...e.DomComponents.getType("button").model.prototype.defaults.traits,
          {
            type: "text",
            label: "Title",
            name: "title",
          },
          {
            type: "select",
            label: "Data Source",
            name: "data_source",
            changeProp: 1,
            options: [],
          },
          {
            type: "text",
            label: "Redirect Url",
            name: "redirectUrl",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Icon Class",
            name: "iconClass",
            changeProp: 1,
          },
          {
            type: "select",
            label: "Button Type",
            name: "buttonType",
            options: [
              {
                id: "link",
                label: "Link",
              },
              {
                id: "cancel",
                label: "Cancel",
              },
              {
                id: "save",
                label: "Save",
              },
              {
                id: "delete",
                label: "Delete",
              },
              {
                id: "refresh",
                label: "Refresh",
              },
              {
                id: "repeatativePlus",
                label: "Repeatative Plus",
              },
              {
                id: "repeatativeDelete",
                label: "Repeatative Delete",
              },
              {
                id: "newPageOpen",
                label: "Open New Page",
              },
              {
                id: "addInDefault",
                label: "Add In Default Page",
              },
              {
                id: "removeFromDefault",
                label: "Remove From Default",
              },
              {
                id: "removeAll",
                label: "Remove All",
              },
              {
                id: "export",
                label: "Export",
              },
              {
                id: "closeRightSide",
                label: "Close Right Section",
              },
            ],
            changeProp: 1,
          },
          {
            type: "select",
            label: "Export Data Format",
            name: "exportFormat",
            options: [
              { id: "excel", label: "Excel" },
              { id: "json", label: "JSON" },
            ],
            changeProp: 1,
          },
          {
            type: "text",
            label: "Confirmation Message",
            name: "confirmationMessage",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Success Message",
            name: "successMessage",
            changeProp: 1,
          },
          {
            type: "select",
            label: "Page (On Click)",
            name: "page",
            changeProp: 1,
            options: [],
          },
          {
            type: "select",
            label: "Open Page In",
            name: "openPageInPopup",
            options: [
              { id: "self", label: "Self" },
              { id: "bootstrapPopup", label: "Bootstrap Popup" },
              { id: "kendoPopup", label: "Kendo Popup" },
              { id: "container", label: "Container" },
            ],
            changeProp: 1,
          },
          {
            type: "select",
            label: "Select Container",
            name: "container_name",
            options: [],
            changeProp: 1,
          },
          {
            type: "text",
            label: "Popup Title",
            name: "popupTitle",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Keys To Pass Value On Next",
            name: "passingKeys",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Button Hide Key",
            name: "buttonHideKey",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Refresh (On Success)",
            name: "refreshOnSuccess",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Skip Validation",
            name: "skipValidation",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Upload File",
            name: "uploadFile",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Activate Shortcut Key",
            name: "shortcutKey",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Close Popup Or Container",
            name: "closePopup",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Enable Toggle",
            name: "setDetaultValue",
            changeProp: 1,
          },
        ],
      },
    },
  });
};
