export const queryData = [
  {
    name: "xsm_cigroup.businessOwner",
    value: "business_owner",
    label: "Business Owner",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "character varying",
    attributeValueType: "textbox",
    api: null,
    headers: null,
    apiHost: null,
    bindingAttributes: null,
    methodType: null,
    apiEndPoint: null,
    operators: [
      { name: "equal", value: "=", label: "Equal", controlType: "text" },
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "text",
      },
    ],
    attributes: null,
  },
  {
    name: "xsm_cigroup.changeGroup",
    value: "change_group",
    label: "Change Group",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "character varying",
    attributeValueType: "dropdown",
    api: "/api/changeGroup",
    headers: null,
    apiHost: "https://xsmdev.dryice-aws.com/fdn",
    bindingAttributes: '{"label": "GROUP_NAME", "id": "GROUP_ID"}',
    methodType: "GET",
    apiEndPoint: "https://xsmdev.dryice-aws.com/fdn/api/changeGroup",
    operators: [
      { name: "equal", value: "=", label: "Equal", controlType: "text" },
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "text",
      },
    ],
    attributes: null,
  },
  {
    name: "xsm_cigroup.supportCompany",
    value: "support_company",
    label: "Support Company",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "character varying",
    attributeValueType: "typeahead",
    api: "/api/supportCompanies",
    headers: '{"query":["companyId", "like"]}',
    apiHost: "https://xsmdev.dryice-aws.com/fdn",
    bindingAttributes: '{"label": "COMPANY_NAME", "id": "COMPANY_ID"}',
    methodType: "GET",
    apiEndPoint: "https://xsmdev.dryice-aws.com/fdn/api/supportCompanies",
    operators: [
      { name: "equal", value: "=", label: "Equal", controlType: "text" },
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "text",
      },
    ],
    attributes: null,
  },
  {
    name: "xsm_cigroup.supportGroup",
    value: "support_group",
    label: "Support Group",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "character varying",
    attributeValueType: "typeahead",
    api: "/api/supportGroups",
    headers: '{"query":["companyId", "like"]}',
    apiHost: "https://xsmdev.dryice-aws.com/fdn",
    bindingAttributes: '{"label": "GROUP_NAME", "id": "GROUP_ID"}',
    methodType: "GET",
    apiEndPoint: "https://xsmdev.dryice-aws.com/fdn/api/supportGroups",
    operators: [
      { name: "equal", value: "=", label: "Equal", controlType: "text" },
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "text",
      },
    ],
    attributes: null,
  },
  {
    name: "xsm_cigroup.technicalOwner",
    value: "technical_owner",
    label: "Technical Owner",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "character varying",
    attributeValueType: "typeahead",
    api: "/api/technicalOwners",
    headers: '{"query":["companyId", "like"]}',
    apiHost: "https://xsmdev.dryice-aws.com/fdn",
    bindingAttributes: '{"label": "OWNER_NAME", "id": "OWNER_ID"}',
    methodType: "GET",
    apiEndPoint: "https://xsmdev.dryice-aws.com/fdn/api/technicalOwners",
    operators: [
      { name: "equal", value: "=", label: "Equal", controlType: "text" },
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "text",
      },
    ],
    attributes: null,
  },
  {
    name: "xsm_cigroup.department",
    value: "department",
    label: "Department",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "character varying",
    attributeValueType: "dropdown",
    api: "/api/getDepartment",
    headers: '{"query": ["company_id", "status"]}',
    apiHost: "https://xsmdev.dryice-aws.com/fdn",
    bindingAttributes: '{"label": "DEPARTMENT_NAME", "id": "DEPARTMENT_ID"}',
    methodType: "GET",
    apiEndPoint: "https://xsmdev.dryice-aws.com/fdn/api/getDepartment",
    operators: [
      { name: "equal", value: "=", label: "Equal", controlType: "text" },
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "text",
      },
    ],
    attributes: null,
  },
  {
    name: "xsm_cigroup.businessCriticality",
    value: "business_criticality",
    label: "Business Criticality",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "character varying",
    attributeValueType: "dropdown",
    api: "/api/businessCriticality",
    headers: null,
    apiHost: "https://xsmdev.dryice-aws.com/fdn",
    bindingAttributes: '{"label": "CRITICALITY_LEVEL", "id": "CRITICALITY_ID"}',
    methodType: "GET",
    apiEndPoint: "https://xsmdev.dryice-aws.com/fdn/api/businessCriticality",
    operators: [
      { name: "equal", value: "=", label: "Equal", controlType: "text" },
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "text",
      },
    ],
    attributes: null,
  },
  {
    name: "xsm_cigroup.category",
    value: "category",
    label: "Category",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "character varying",
    attributeValueType: "dropdown",
    api: "/api/category",
    headers: null,
    apiHost: "https://xsmdev.dryice-aws.com/fdn",
    bindingAttributes: '{"label": "CATEGORY_NAME", "id": "CATEGORY_ID"}',
    methodType: "GET",
    apiEndPoint: "https://xsmdev.dryice-aws.com/fdn/api/category",
    operators: [
      { name: "equal", value: "=", label: "Equal", controlType: "text" },
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "text",
      },
    ],
    attributes: null,
  },
  {
    name: "xsm_cigroup.subCategory",
    value: "subcategory",
    label: "Subcategory",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "character varying",
    attributeValueType: "textbox",
    api: null,
    headers: null,
    apiHost: null,
    bindingAttributes: null,
    methodType: null,
    apiEndPoint: null,
    operators: [
      { name: "equal", value: "=", label: "Equal", controlType: "text" },
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "text",
      },
    ],
    attributes: null,
  },
  {
    name: "xsm_cigroup.environment",
    value: "environment",
    label: "Environment",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "character varying",
    attributeValueType: "dropdown",
    api: "/api/environment",
    headers: null,
    apiHost: "https://xsmdev.dryice-aws.com/fdn",
    bindingAttributes: '{"label": "ENV_NAME", "id": "ENV_ID"}',
    methodType: "GET",
    apiEndPoint: "https://xsmdev.dryice-aws.com/fdn/api/environment",
    operators: [
      { name: "equal", value: "=", label: "Equal", controlType: "text" },
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "text",
      },
    ],
    attributes: null,
  },
  {
    name: "xsm_cigroup.ciLocationId",
    value: "ci_location_id",
    label: "Location",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "integer",
    attributeValueType: "typeahead",
    api: "/api/list/cwlForCMDB",
    headers:
      '{\n  "query": {\n    "companyId": "{{company_id}}",\n    "like": "{{like}}"\n  }\n}\n',
    apiHost: "https://xsmdev.dryice-aws.com/fdn",
    bindingAttributes: '{"label": "LOCATION_NAME", "id": "LOCATION_ID"}',
    methodType: "GET",
    apiEndPoint: "https://xsmdev.dryice-aws.com/fdn/api/list/cwlForCMDB",
    operators: [
      {
        name: "equal",
        value: "=",
        label: "Equal",
        controlType: "number",
      },
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "number",
      },
      {
        name: "in",
        value: "in",
        label: "In",
        controlType: "text",
      },
    ],
    attributes: null,
  },

  {
    name: "xsm_cigroup.updatedBy",
    value: "updated_by",
    label: "Updated By",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "character varying",
    attributeValueType: "textbox",
    api: null,
    headers: null,
    apiHost: null,
    bindingAttributes: null,
    methodType: null,
    apiEndPoint: null,
    operators: [
      { name: "equal", value: "=", label: "Equal", controlType: "text" },
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "text",
      },
    ],
    attributes: null,
  },
  {
    name: "xsm_cigroup.updatedDate",
    value: "updated_date",
    label: "Updated Date",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "date",
    attributeValueType: "textbox",
    api: null,
    headers: null,
    apiHost: null,
    bindingAttributes: null,
    methodType: null,
    apiEndPoint: null,
    operators: [
      { name: "equal", value: "=", label: "Equal", controlType: "date" },
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "date",
      },
    ],
    attributes: null,
  },
  {
    name: "xsm_cigroup.backupRetention",
    value: "backup_retention",
    label: "Backup Retention",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "integer",
    attributeValueType: "textbox",
    api: null,
    headers: null,
    apiHost: null,
    bindingAttributes: null,
    methodType: null,
    apiEndPoint: null,
    operators: [
      { name: "equal", value: "=", label: "Equal", controlType: "number" },
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "number",
      },
    ],
    attributes: null,
  },
];

export const queryData1111 = [
  {
    name: "xsm_change.ciLocationId",
    value: "ci_location_id",
    label: "CI.Location",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "integer",
    attributeValueType: "typeahead",
    api: "/api/list/cwlForCMDB",
    headers: '{"query":["companyId", "like"]}',
    apiHost: "https://xsmdev.dryice-aws.com/fdn",
    bindingAttributes: '{"label": "LOCATION_NAME", "id": "LOCATION_ID"}',
    methodType: "GET",
    apiEndPoint: "https://xsmdev.dryice-aws.com/fdn/api/list/cwlForCMDB",
    operators: [
      {
        name: "equal",
        value: "=",
        label: "Equal",
        controlType: "number",
      },
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "number",
      },
      {
        name: "in",
        value: "in",
        label: "In",
        controlType: "text",
      },
    ],
    attributes: null,
  },
  {
    name: "breakfix.ciClassId",
    value: "ci_class_id",
    label: "Class",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "integer",
    attributeValueType: "dropdown",
    api: "/api/classes",
    headers:
      '{\n  "query": {\n    "company_id": "{{consumerCompanyId}}",\n    "status": "{{status}}"\n  }\n}',
    apiHost: "https://xsmdev.dryice-aws.com/cmdb",
    bindingAttributes: '{"label": "CLASS_NAME", "id": "CLASS_ID"}',
    methodType: "GET",
    apiEndPoint: "https://xsmdev.dryice-aws.com/cmdb/api/classes",
    operators: [
      {
        name: "equal",
        value: "=",
        label: "Equal",
        controlType: "number",
      },
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "number",
      },
    ],
    attributes: null,
  },
  {
    name: "xsm_change.status",
    value: "status",
    label: "Status",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "character varying",
    attributeValueType: "dropdown",
    api: "/menu/getData/ChangeManagement/ViewStatus",
    headers: null,
    apiHost: "https://xsmdev.dryice-aws.com/rule",
    bindingAttributes: '{"label": "fieldValue", "id": "fieldKey"}',
    methodType: "GET",
    apiEndPoint:
      "https://xsmdev.dryice-aws.com/rule/menu/getData/ChangeManagement/ViewStatus",
    operators: [
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "text",
      },
      {
        name: "equal",
        value: "=",
        label: "Equal",
        controlType: "text",
      },
    ],
    attributes: null,
  },
  {
    name: "xsm_change.requesterId",
    value: "requester_id",
    label: "Requester",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "integer",
    attributeValueType: "typeahead",
    api: "/api/company/consumerUsers",
    headers: '{"query": ["companyId", "like"]}',
    apiHost: "https://xsmdev.dryice-aws.com/fdn",
    bindingAttributes:
      '{"label": "fullname|email-companyName", "id": "userId"}',
    methodType: "GET",
    apiEndPoint: "https://xsmdev.dryice-aws.com/fdn/api/company/consumerUsers",
    operators: [
      {
        name: "equal",
        value: "=",
        label: "Equal",
        controlType: "number",
      },
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "number",
      },
      {
        name: "in",
        value: "in",
        label: "In",
        controlType: "text",
      },
    ],
    attributes: null,
  },
  {
    name: "xsm_change.requesterLocationId",
    value: "requester_location_id",
    label: "User.Location",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "integer",
    attributeValueType: "typeahead",
    api: "/api/list/cwlForCMDB",
    headers: '{"query":["companyId", "like"]}',
    apiHost: "https://xsmdev.dryice-aws.com/fdn",
    bindingAttributes: '{"label": "LOCATION_NAME", "id": "LOCATION_ID"}',
    methodType: "GET",
    apiEndPoint: "https://xsmdev.dryice-aws.com/fdn/api/list/cwlForCMDB",
    operators: [
      {
        name: "equal",
        value: "=",
        label: "Equal",
        controlType: "number",
      },
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "number",
      },
      {
        name: "in",
        value: "in",
        label: "In",
        controlType: "text",
      },
    ],
    attributes: null,
  },
  {
    name: "xsm_change.subCategory",
    value: "sub_category",
    label: "Sub category",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "character varying",
    attributeValueType: "textbox",
    api: null,
    headers: null,
    apiHost: null,
    bindingAttributes: null,
    methodType: null,
    apiEndPoint: null,
    operators: [
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "text",
      },
      {
        name: "equal",
        value: "=",
        label: "Equal",
        controlType: "text",
      },
    ],
    attributes: null,
  },
  {
    name: "xsm_change.serviceId",
    value: "service_id",
    label: "Service",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "integer",
    attributeValueType: "dropdown+typeahead",
    api: "/api/serviceDetails/serviceList",
    headers: '{"query": ["companyId", "moduleType"]}',
    apiHost: "https://xsmdev.dryice-aws.com/spcm",
    bindingAttributes: '{"label": "OFFERING_NAME", "id": "OFFERING_ID"}',
    methodType: "GET",
    apiEndPoint:
      "https://xsmdev.dryice-aws.com/spcm/api/serviceDetails/serviceList",
    operators: [
      {
        name: "equal",
        value: "=",
        label: "Equal",
        controlType: "number",
      },
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "number",
      },
      {
        name: "in",
        value: "in",
        label: "In",
        controlType: "text",
      },
    ],
    attributes: null,
  },
  {
    name: "xsm_change.ciId",
    value: "ci_id",
    label: "Impacted CI",
    foreignTableExists: false,
    foriegnKey: null,
    dataType: "integer",
    attributeValueType: "typeahead",
    api: "/api/config_items?searchByList=STATUS,SUB_STATUS&multipleValueList=7|12&",
    headers: '{"path": ["companyId", "searchValue"]}',
    apiHost: "https://xsmdev.dryice-aws.com/cmdb",
    bindingAttributes:
      '{"label": "CI_NAME|SERIAL_NUMBER-CLASS_NAME-COMPANY_NAME", "id": "CI_ID"}',
    methodType: "GET",
    apiEndPoint:
      "https://xsmdev.dryice-aws.com/cmdb/api/config_items?searchByList=STATUS,SUB_STATUS&multipleValueList=7|12&",
    operators: [
      {
        name: "equal",
        value: "=",
        label: "Equal",
        controlType: "number",
      },
      {
        name: "not equal",
        value: "!=",
        label: "Not Equal",
        controlType: "number",
      },
      {
        name: "in",
        value: "in",
        label: "In",
        controlType: "text",
      },
    ],
    attributes: null,
  },
];
