
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { SubmissionError } from 'redux-form';
import { GLOBAL } from '_Globals';
import axios from 'axios';
// import { push } from 'react-router-redux';
let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
import moment from 'moment';
import configureStore from '../../../../store/configureStore';
import investigationDetailsLoadSuccess from "_Actions/investigation/investigationAction"
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { opsCategoryKeysMap, problemStatusMapping } from "../../../common/helper";

const store = configureStore();
function submit(values, _, props) {

  // console.log('*****',{values, props, breakFixConsumerID: GLOBAL.breakFixConsumerID, breakFixConsumerName: GLOBAL.breakFixConsumerName, ciId: GLOBAL.ciId, ciIdEdit: GLOBAL.ciIdEdit });
  const { investigationItemDetails : { status_id = "" } = {} } = props;

  console.log("GLOBAL.priorityActualValue",values);
  GLOBAL.priorityValueType = values.priority;
  let editObj;
  if (!values.issueDescription.replace(/\s/g, '').length) {
    GLOBAL.issueErrorColor = "error";
  }
  let priorityActualValue = '';
  let ciId = ""; let ciName = ""; let ciCode = ""; let flag = "N"; let deletedCiRelationId = ""; let relationRequired = "N";
  if (GLOBAL.priorityValueType == "P2") {
    priorityActualValue = 50;
  } else if (GLOBAL.priorityValueType == "P1") {
    priorityActualValue = 45;
  } else if (GLOBAL.priorityValueType == "P3") {
    priorityActualValue = 55;
  }else if(GLOBAL.priorityValueType=="P4"){
    priorityActualValue = 60;
  }
  if (values.supportPIndividualName == "Select..")
    values.supportPIndividualName = "";
  /*else if(GLOBAL.priorityValueType=="Critical"){
    priorityActualValue=40;
  }*/
  // alert(GLOBAL.ciId)
  if (GLOBAL.ciId == "0" || GLOBAL.ciId == null) {
    //alert("T1==>"+GLOBAL.ciIdEdit)
    if (GLOBAL.ciIdEdit == "") {
      //alert("T3");
      GLOBAL.ciId = 0; GLOBAL.ciName = ""; GLOBAL.ciCode = "";
      flag = "N"; relationRequired = "N"
    } else {
      //alert("T4");
      ciId = GLOBAL.ciIdEdit; ciName = GLOBAL.ciNameEdit; ciCode = GLOBAL.ciIdNumberEdit;
      flag = "N"; relationRequired = "Y";
    }
  } else {
    //alert("T2==>"+GLOBAL.ciIdEdit);
    if (GLOBAL.ciIdEdit == "") {
      //alert("T5");
      ciId = GLOBAL.ciId; ciName = GLOBAL.ciName; ciCode = GLOBAL.ciIdNumber;
      flag = "N"; relationRequired = "N";
    } else {
      if (GLOBAL.ciId != GLOBAL.ciIdEdit) {
        // alert("T6");
        ciId = GLOBAL.ciIdEdit; ciName = GLOBAL.ciNameEdit; ciCode = GLOBAL.ciIdNumberEdit;
        flag = "Y"; deletedCiRelationId = GLOBAL.ciId; relationRequired = "N";
      } else if (GLOBAL.ciId == GLOBAL.ciIdEdit) {
        //alert("T7");
        ciId = GLOBAL.ciIdEdit; ciName = GLOBAL.ciNameEdit; ciCode = GLOBAL.ciIdNumberEdit;
        flag = "N"; relationRequired = "N";
      }
    }
  }
  // if (ciId == "")

  //   ciId = "0";
  // if (ciCode == "")
  //   ciCode = "0";
  let tempIsExternal="";
  console.log("props.investigationItemDetails===");
  console.log(props.investigationItemDetails);
  if(props.investigationItemDetails.isExternal==true || props.investigationItemDetails.isExternal=="true"){
    tempIsExternal=props.investigationItemDetails.isExternal;
  } else if(props.investigationItemDetails.isExternal=="" || props.investigationItemDetails.isExternal==false || props.investigationItemDetails.isExternal==undefined || props.investigationItemDetails.isExternal==null){
    tempIsExternal = GLOBAL.isExternalInvestigationEdit;
  }
  let checkEmptyProblemMgmtGroupZero = values.supportPGroup === "0" || values.supportPGroup === 'Select' || values.supportPGroup === 0;
  let checkEmptyProblemMgmtGroupNull = values.supportPGroup === null || values.supportPGroup === undefined;
  let checkEmptyProblemMgmtGroup = values.supportPGroup === "" || checkEmptyProblemMgmtGroupNull || checkEmptyProblemMgmtGroupZero;
  let isProblemMgmtGroupEmptynotatDraft = props.investigationItemDetails.status_id != problemStatusMapping["Draft"] && checkEmptyProblemMgmtGroup;
  let isProblemMgmtGroupEmptyatDraft = props.investigationItemDetails.status_id == problemStatusMapping["Draft"] && checkEmptyProblemMgmtGroup;
  const categorizationErrors = {};
  for (let i = 1; i <= GLOBAL.categorizationDropdowns; i++) {
    if (values[opsCategoryKeysMap[`opsCategoryId${i}`]] === null || values[opsCategoryKeysMap[`opsCategoryId${i}`]] === undefined || values[opsCategoryKeysMap[`opsCategoryId${i}`]] === '' || values[opsCategoryKeysMap[`opsCategoryId${i}`]] === '0') {
      categorizationErrors[opsCategoryKeysMap[`opsCategoryId${i}`]] = "error";
    }
  }
  
  GLOBAL.categorizationErrors = Object.keys(categorizationErrors).length > 0 ? categorizationErrors : {};
  const categorizationFields = Object.values(opsCategoryKeysMap).reduce((acc, key) => {
    if (key in values) {
      acc[key] = values[key];
    }
    return acc;
  }, {}) || {};

  if (GLOBAL.priorityValueType == undefined) {
    editObj = {
      ...categorizationFields,
      "asgPmId": isProblemMgmtGroupEmptyatDraft? "0" : values.supportPIndividual,
      "asgPmName": isProblemMgmtGroupEmptyatDraft? "" : values.supportPIndividualName,
      "description": values.issueDescription,
      "symptoms": values.additionalInfo,
      "modifiedBy": "",
      "modifiedByName": "",
      "asgPmGroupid": isProblemMgmtGroupEmptyatDraft? "0" : values.supportPGroup,
      "asgPmGroupname": isProblemMgmtGroupEmptyatDraft? "" : values.supportPGroupName,
      "ciId": ciId,
      "ciName": ciName,
      "ciCode": GLOBAL.ciCode ?? '',
      "source":values.source,
      "impactedUsername":GLOBAL.breakFixConsumerName===''? props.investigationItemDetails.impactedUsername : GLOBAL.breakFixConsumerName,
      "impactedUserid":GLOBAL.breakFixConsumerID==='' ? props.investigationItemDetails.impactedUserid : GLOBAL.breakFixConsumerID,
      "consumerCompanyName":props.investigationItemDetails.consumerCompanyName, 
      "consumerCompany":props.investigationItemDetails.consumerCompany,
      "isExternal" : tempIsExternal
    }
  } else {
    editObj = {
      ...categorizationFields,
      "asgPmId": isProblemMgmtGroupEmptyatDraft? "0" : values.supportPIndividual,
      "asgPmName": isProblemMgmtGroupEmptyatDraft? "" : values.supportPIndividualName,
      "description": values.issueDescription,
      "priority": priorityActualValue,
      "priorityValue": GLOBAL.priorityValueType,
      "symptoms": values.additionalInfo,
      "urgency": values.urgencyMode,
      "serviceCriticality":values.criticality,
      "modifiedBy": "",
      "modifiedByName": "",
      "asgPmGroupid": isProblemMgmtGroupEmptyatDraft? "0" : values.supportPGroup,
      "asgPmGroupname": isProblemMgmtGroupEmptyatDraft? "" : values.supportPGroupName,
      "ciId": ciId,
      "ciName": ciName,
      "ciCode": GLOBAL.ciCode ?? '',
      "ciClassName": GLOBAL.ciClassNameSelected===''? props.investigationItemDetails.ciClassName : GLOBAL.ciClassNameSelected,
      "ciClassId": GLOBAL.ciClassIdEdit===''? props.investigationItemDetails.ciClassId : GLOBAL.ciClassIdEdit,
      "source":values.source,
      "impactedUsername":GLOBAL.breakFixConsumerName===''? props.investigationItemDetails.impactedUsername : GLOBAL.breakFixConsumerName,
      "impactedUserid":GLOBAL.breakFixConsumerID==='' ? props.investigationItemDetails.impactedUserid : GLOBAL.breakFixConsumerID,
      "consumerCompanyName":props.investigationItemDetails.consumerCompanyName, 
      "consumerCompany":props.investigationItemDetails.consumerCompany,
      "isExternal" : tempIsExternal
    }
  }

  // if(status_id && status_id.toString() == "30"){
  //   editObj['consumerCompany'] = values['company'] || props.investigationItemDetails.consumerCompany;
  //   editObj['consumerCompanyName'] = values['companyName'] || props.investigationItemDetails.consumerCompanyName;
  // }
  let breakFixConsumer = GLOBAL.breakFixConsumerID;
  let breakFixConsumerNameValue = GLOBAL.breakFixConsumerName;
  let breakFixConsumerCompany = GLOBAL.consumerCompanybreakfix;
  let breakFixService = GLOBAL.breakFixServiceID;
  let supportPgroupValue = GLOBAL.supportPGroupValue;
  let supportPgroupNameValue = GLOBAL.supportPGroupName;
  let supportPIGroupNameValue = GLOBAL.supportPIGroupName;
  let breakFixServiceName = GLOBAL.breakFixServiceName;
  let asgPmId = GLOBAL.breakfixgroupid;
  if (`${JSON.stringify(values, null, 2)}` == "{}") {
    throw new SubmissionError({ _error: 'The form has missing required fields, please revalidate the highlighted fields and update the form' })
  } else if (values.issueDescription == "" || !values.issueDescription.replace(/\s/g, '').length || isProblemMgmtGroupEmptynotatDraft || values.source == "" || values.source == '0' || values.criticality == "" || values.urgencyMode == "" || values.urgencyMode === undefined || Object.keys(categorizationErrors).length > 0) {
    if (values.issueDescription == "" || !values.issueDescription.replace(/\s/g, '').length) {
      GLOBAL.issueErrorColor = "error";
    } else {
      GLOBAL.issueErrorColor = "";
    }
    if (values.source == "") {
      GLOBAL.sourceErrorColor = "error";
    }
    else {
      GLOBAL.sourceErrorColor = "";
    }
    if (values.criticality == "" || values.criticality == undefined || values.criticality == "Select..") {
      GLOBAL.criticalityErrorColor = "error";
    } else {
      GLOBAL.criticalityErrorColor = "";
    }
    if (values.urgencyMode == "" || values.urgencyMode == undefined || values.urgencyMode == "Select..") {
      GLOBAL.urgencyErrorColor = "error";
    } else {
      GLOBAL.urgencyErrorColor = "";
    }
    if (isProblemMgmtGroupEmptynotatDraft){
      GLOBAL.sgErrorColor = "error";
    } else {
      GLOBAL.sgErrorColor = "";
    }
    // (status_id && status_id.toString() == "30" && (!values.company || values.company == "0" || values.company == 'Select'))
    // if(!values.company || values.company == "0" || values.company == 'Select'){
    //   GLOBAL.companyError = "error";
    // }
    // else{
    //   GLOBAL.companyError = "";
    // }
    // if(values.supportPIndividual == "" || values.supportPIndividual == undefined || values.supportPIndividual == 'Select..'){
    //   GLOBAL.sgIErrorColor = "error";
    // }else{
    //     GLOBAL.sgIErrorColor = "";
    // }
    GLOBAL.sgIErrorColor = "";
    throw new SubmissionError({ _error: 'The form has missing required fields, please revalidate the highlighted fields and update the form !' });
  }
  else {
    let itemId = ""; let itemNumber = ""; let reportedOn = ""; let createdBy = "";
    editObj.requesterLocationId = GLOBAL.servicelocationid === ''? props.investigationItemDetails.requesterLocationId : GLOBAL.servicelocationid;
    editObj.requesterLocationName = GLOBAL.servicelocationname === '' ? props.investigationItemDetails.requesterLocationName : GLOBAL.servicelocationname;
    editObj.ciId = GLOBAL.ciIdEdit==='' ? props.investigationItemDetails.ciId : GLOBAL.ciIdEdit ;
    editObj.ciName = GLOBAL.ciNameCopy==='' ? props.investigationItemDetails.ciName : GLOBAL.ciNameCopy ;
    editObj.businessCriticalityId=GLOBAL.businessCriticalityId===''?props.investigationItemDetails.businessCriticalityId:GLOBAL.businessCriticalityId;
    editObj.businessCriticality=GLOBAL.businessCriticality===''?props.investigationItemDetails.businessCriticality:GLOBAL.businessCriticality;
    editObj.supportCompanyId=values.supportCompanyID,
    editObj.supportCompanyName=values.supportCompanyName

    if(!props.checkDataChanged(editObj)){
      // alert('There is no change in the data!');
      swal({
        text: 'There is no change on the form',
        button: 'OK',
      });
      return false;
    }

    return axios.patch(GLOBAL.investigationEditUrl + GLOBAL.investigationId, editObj)
      .then(function (response) {
        console.log('here')
        console.log(response);
        if (response.status == 200) {
          props.clearUserDetails();
       

          //=========
          itemId = response.data.investigationId;
          itemNumber = response.data.investigationNumber;
          reportedOn = response.data.createdOn;
          createdBy = "";
          //alert("itemId:"+itemId)
          console.log("URL====>" + GLOBAL.cmdbRelatedLogDeleteUrl + '?ci_id=' + itemId + '&ci_id_related=' + deletedCiRelationId);
          //alert(relationRequired)
          if (relationRequired == "Y") {
            //alert("relating")

            let payloadObj ={
              "ci_id": ciId,  //==> ID of CI related
              "wi_id_related": itemId, //==> ID of Work Item related
              "wi_id_number": itemNumber,//  ==> Number of work item related
              "ci_id_reported": reportedOn,
              "created_by": createdBy,
              "relationship_type": "Y",
              "module": "Investigation"
            }
            axios.post(GLOBAL.cmdbRelatedLogDeleteUrl, /*{
              "ci_id": itemId,
              "ci_id_related": ciId,
              "ci_id_number": itemNumber,
              "ci_id_reported": reportedOn,
              "created_by": createdBy,
              "relationship_type": "Y",
              "module": "Investigation"
            }*/
            payloadObj
            ).
              then((response) => {
                console.log("Data related successfully submitted");
                //GLOBAL.ciName="";GLOBAL.ciId="";GLOBAL.ciIdNumber="";
              });
          }
          if (flag == "Y") {
            //alert("deleting==>"+deletedCiRelationId)
            axios.delete(GLOBAL.cmdbRelatedLogDeleteUrl + '?ci_id=' + deletedCiRelationId + '&ci_id_related=' + itemId + '&module=Investigation')
              .then((response) => {
                if (!response || response.status != '200') {
                  console.log('Deletion failed.');
                } else {
                  console.log('deleted relation');
                  //console.log(response);
                  //alert("creating new relation"+ciId)

                  let payloadObj ={
                    "ci_id": ciId,  //==> ID of CI related
                    "wi_id_related": itemId, //==> ID of Work Item related
                    "wi_id_number": itemNumber,//  ==> Number of work item related
                    "ci_id_reported": reportedOn,
                    "created_by": createdBy,
                    "module": "Investigation",
                    "relationship_type": "Y"
                  }
                  axios.post(GLOBAL.cmdbRelatedLogDeleteUrl, /*{
                    "ci_id": itemId,
                    "ci_id_related": ciId,
                    "ci_id_number": itemNumber,
                    "ci_id_reported": reportedOn,
                    "created_by": createdBy,
                    "module": "Investigation",
                    "relationship_type": "Y"
                  }*/
                  payloadObj
                  ).then((response) => {
                    console.log("This incident have been related to CMDB");
                    //GLOBAL.ciName="";GLOBAL.ciId="";GLOBAL.ciIdNumber="";
                  });
                }
              });
            //=========
          } else {
            console.log("no action required")
          }

        } else {
          console.log("error in CMDB deletion/relation")
        }
        console.log("error in CMDB deletion/relation22222222222222222222", response.data);

        // browserHistory.push("/investigationEdit/"+response.data.investigationId);
        // investigationDetailsLoadSuccess();

        // props.dispatch({
        //   type: 'INVESTIGATION_DETAILS_LOAD_SUCCESS',
        //   investigationDetails:response
        // })
        props.dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });
        axios.get(`${GLOBAL.investigationDetailsUrl}/${response.data.investigationId}`)
          .then((investigationDetails) => {
            if (!investigationDetails) {
              throw Error(investigationDetails.statusText);
            }
            return investigationDetails;
          })
          .then((investigationDetails) => {
            props.dispatch({
              type: 'INVESTIGATION_DETAILS_LOAD_SUCCESS',
              investigationDetails: investigationDetails
            });

            //dispatch({type:'LOAD_HAMBURGER_OPTIONS',payload:true});
         
        axios.get(GLOBAL.ProbelemFieldStatusData + investigationDetails.data.status_id)
          .then((statusData) => {
            if (!statusData) {
              throw Error(statusData.statusText);
            }
            return statusData;
          })
          .then((statusData) => {
            props.dispatch({
              type: 'CHANGE_FIELD_STATUS_DATA_LOAD_SUCCESS',
              statusData: statusData
            })
            props.dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
            props.dispatch({ type: 'LOAD_HAMBURGER_OPTIONS', payload: true });
          });
       
      })
    })
    // console.log("response",response);

    .catch(function (error) {
      console.log(error);
    });
  }

}
export default submit;

