
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import { GLOBAL } from '_Globals';
import _ from 'lodash';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

export function loadSlaCompanySuccess(slaCompany) {
  return {
    type: 'LOAD_SLACOMPANY_SUCCESS',
    slaCompany
  };
}

export function loadSlaCompanyFailure(errorResponse) {
  return {
    type: 'LOAD_SLACOMPANY_FAILURE',
    errorResponse
  };
}

export function loadSlaModuleSuccess(slaModule) {
  return {
    type: 'LOAD_SLAMODULE_SUCCESS',
    slaModule
  };
}

export function loadSlaModuleFailure(errorResponse) {
  return {
    type: 'LOAD_SLAMODULE_FAILURE',
    errorResponse
  };
}

export function loadSlaStatusSuccess(slaStatus) {
  return {
    type: 'LOAD_SLASTATUS_SUCCESS',
    slaStatus
  };
}

export function loadSlaStatusFailure(errorResponse) {
  return {
    type: 'LOAD_SLASTATUS_FAILURE',
    errorResponse
  };
}

export function loadSlaServiceSuccess(slaService) {
  return {
    type: 'LOAD_SLASERVICE_SUCCESS',
    slaService
  };
}
export function loadSlaImpactedSuccess(slaService) {
  return {
    type: 'LOAD_IMPACTED_SUCCESS',
    slaService
  };
}
export function loadSlaImpactedFailure(errorResponse) {
  return {
    type: 'LOAD_SLAIMPACTED_FAILURE',
    errorResponse
  };
}
export function loadSlaServiceFailure(errorResponse) {
  return {
    type: 'LOAD_SLASERVICE_FAILURE',
    errorResponse
  };
}

export function loadSlaTypeSuccess(slaType) {
  return {
    type: 'LOAD_SLATYPE_SUCCESS',
    slaType
  };
}

export function loadSlaTypeFailure(errorResponse) {
  return {
    type: 'LOAD_SLATYPE_FAILURE',
    errorResponse
  };
}

export function loadSlaTargetSuccess(slaTarget) {
  return {
    type: 'LOAD_SLATARGET_SUCCESS',
    slaTarget
  };
}

export function loadSlaTargetFailure(errorResponse) {
  return {
    type: 'LOAD_SLATARGET_FAILURE',
    errorResponse
  };
}

export function loadSlaWrkSchSuccess(slaWorkdaySchedules) {
  return {
    type: 'LOAD_SLAWRKSCH_SUCCESS',
    slaWorkdaySchedules
  };
}

export function loadSlaWrkSchFailure(errorResponse) {
  return {
    type: 'LOAD_SLAWRKSCH_FAILURE',
    errorResponse
  };
}

export function loadSLaHolSchSuccess(slaHolidaySchedules) {
  return {
    type: 'LOAD_SLAHOLSCH_SUCCESS',
    slaHolidaySchedules
  };
}

export function loadSLaHolSchFailure(errorResponse) {
  return {
    type: 'LOAD_SLAHOLSCH_FAILURE',
    errorResponse
  };
}

export function loadSlaWrkDaySchDaySuccess(slaWorkdaySchedulesDays) {
  return {
    type: 'LOAD_SLAWORKDAYSCHDAY_SUCCESS',
    slaWorkdaySchedulesDays
  };
}

export function loadSlaWrkDaySchDayFailure(errorResponse) {
  return {
    type: 'LOAD_SLAWORKDAYSCHDAY_FAILURE',
    errorResponse
  };
}

export function loadSlaWrkDaySchStatusSuccess(slaWorkdaySchedulesStatus) {
  return {
    type: 'LOAD_SLAWORKDAYSCHSTATUS_SUCCESS',
    slaWorkdaySchedulesStatus
  };
}

export function loadSlaWrkDaySchStatusFailure(errorResponse) {
  return {
    type: 'LOAD_SLAWORKDAYSCHSTATUS_FAILURE',
    errorResponse
  };
}

export function loadSlaDefEditDetailsSuccess(slaDefEditDetails) {
  //alert('in success')
  return {
    type: 'LOAD_SLADEFEDITDETAILS_SUCCESS',
    slaDefEditDetails
  };
}

export function loadSlaDefEditDetailsFailure(errorResponse) {
  return {
    type: 'LOAD_SLADEFEDITDETAILS_FAILURE',
    errorResponse
  };
}
//CriteriaType
export function loadSlaCriteriaTypeSuccess(slaCriteriaType) {
  //alert('in success')
  return {
    type: 'LOAD_CRITERIATYPE_SUCCESS',
    slaCriteriaType
  };
}

export function loadSlaCriteriaTypeFailure(errorResponse) {
  return {
    type: 'LOAD_CRITERIATYPE_FAILURE',
    errorResponse
  };
}

//OperatorType
export function loadSlaOperatorSuccess(slaOperatorType) {
  //alert('in success')
  return {
    type: 'LOAD_OPERATOR_SUCCESS',
    slaOperatorType
  };
}

export function loadSlaOperatorTypeFailure(errorResponse) {
  return {
    type: 'LOAD_OPERATOR_FAILURE',
    errorResponse
  };
}

//slaAttribute
export function loadSlaAttributeSuccess(slaAttributes) {
  //alert('in success')
  return {
    type: 'LOAD_ATTRIBUTE_SUCCESS',
    slaAttributes
  };
}

export function loadSlaAttributeFailure(errorResponse) {
  return {
    type: 'LOAD_ATTRIBUTE_FAILURE',
    errorResponse
  };
}

//slaAttributeList
export function loadSlaAttributeListSuccess(slaAttributesList) {
  //alert('in success')
  return {
    type: 'LOAD_ATTRIBUTELIST_SUCCESS',
    slaAttributesList
  };
}

export function loadSlaAttributeListFailure(errorResponse) {
  return {
    type: 'LOAD_ATTRIBUTELIST_FAILURE',
    errorResponse
  };
}

//slaMilestoneList
export function loadSlaMilestoneListSuccess(slaMilestoneList) {
  //alert('in success')
  return {
    type: 'LOAD_MILESTONELIST_SUCCESS',
    slaMilestoneList
  };
}

export function loadSlaMilestoneListFailure(errorResponse) {
  return {
    type: 'LOAD_MILESTONELIST_FAILURE',
    errorResponse
  };
}

//slaMilestoneAction
export function loadSlaMilestoneActionListSuccess(slaMilestoneActionList) {
  //alert('in success')
  return {
    type: 'LOAD_MILESTONEACTIONLIST_SUCCESS',
    slaMilestoneActionList
  };
}

export function loadSlaMilestoneActionListFailure(errorResponse) {
  return {
    type: 'LOAD_MILESTONEACTIONLIST_FAILURE',
    errorResponse
  };
}

//slaMilestoneTarget
export function loadSlaMilestoneTargetListSuccess(slaMilestoneTargetList) {
  //alert('in success')
  return {
    type: 'LOAD_MILESTONETARGETLIST_SUCCESS',
    slaMilestoneTargetList
  };
}

export function loadSlaMilestoneTargetListFailure(errorResponse) {
  return {
    type: 'LOAD_MILESTONETARGETLIST_FAILURE',
    errorResponse
  };
}




export function loadSlaCompany(userId) {
  return (dispatch) => {
    axios.get(GLOBAL.slaCompanyUrl + userId)
      .then((slaCompany) => {
        if (!slaCompany) {
          throw Error(slaCompany.statusText);
        }
        return slaCompany;
      })
      .then((slaCompany) => {
        dispatch(loadSlaCompanySuccess(slaCompany))
      })
      .catch((err) => {
        console.log(err);
      });

  };
}

export function loadSlaModule(moduleName, moduleType) {

  console.log(GLOBAL.slaLovUrl + "/module/" + moduleName + "/field1Names/" + moduleType)
  return (dispatch) => {
    axios.get(GLOBAL.slaLovUrl + "/module/" + moduleName + "/field1Names/" + moduleType)
      .then((slaModule) => {
        if (!slaModule) {
          throw Error(slaModule.statusText);
        }
        return slaModule;
      })
      .then((slaModule) => {
        console.log('success in slaModule...')
        dispatch(loadSlaModuleSuccess(slaModule))
      })
      .catch((err) => {
        console.log(err);
      });

  };
}

export function loadSlaStatus(moduleName, moduleType) {

  return (dispatch) => {
    axios.get(GLOBAL.slaLovUrl + "/module/" + moduleName + "/field1Names/" + moduleType)
      .then((slaStatus) => {
        if (!slaStatus) {
          throw Error(slaStatus.statusText);
        }
        return slaStatus;
      })
      .then((slaStatus) => {
        console.log('success in slaStatus...')
        dispatch(loadSlaStatusSuccess(slaStatus))
      })
      .catch((err) => {
        console.log(err);
      });

  };
}

export function loadSlaService(serviceName) {
  return (dispatch) => {
    if (serviceName == '0') {
      let slaService = [];
      dispatch(loadSlaServiceSuccess(null));
    } else {
      axios.get(GLOBAL.slaServiceUrl + serviceName)
        .then((slaService) => {
          if (!slaService) {
            throw Error(slaService.statusText);
          }
          return slaService;
        })
        .then((slaService) => {
          console.log('success in slaService...')
          dispatch(loadSlaServiceSuccess(slaService))
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
}
export function loadSlaImpacted(serviceName) {
  return (dispatch) => {
    if (serviceName == '0') {
      let slaService = [];
      dispatch(loadSlaImpactedSuccess(null));
    } else {
      api.get(GLOBAL.ciNameDetailsUrl+'?searchByList=STATUS&multipleValueList=7&companyId='+serviceName)
        .then((slaService) => {
          if (!slaService) {
            throw Error(slaService.statusText);
          }
          return slaService;
        })
        .then((slaService) => {
          console.log('success in slaService...')
          dispatch(loadSlaImpactedSuccess(slaService))
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
}
export function loadSlaType(moduleName, moduleType) {
  return (dispatch) => {
    axios.get(GLOBAL.slaLovUrl + "/module/" + moduleName + "/field1Names/" + moduleType)
      .then((slaType) => {
        if (!slaType) {
          throw Error(slaType.statusText);
        }
        return slaType;
      })
      .then((slaType) => {
        console.log('success in slaType...')
        dispatch(loadSlaTypeSuccess(slaType))
      })
      .catch((err) => {
        console.log(err);
      });

  };
}

export function loadSlaTarget(moduleName, moduleType) {

  return (dispatch) => {
    if (moduleName == '0') {
      dispatch(loadSlaTargetSuccess(null));
    } else {
      axios.get(GLOBAL.slaLovUrl + "/module/" + moduleName + "/field1Names/" + moduleType)
        .then((slaTarget) => {
          if (!slaTarget) {
            throw Error(slaTarget.statusText);
          }
          return slaTarget;
        })
        .then((slaTarget) => {
          console.log('success in slaTarget...')
          dispatch(loadSlaTargetSuccess(slaTarget))
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
}

export function loadSlaWrkSchedule(companyId) {
  //alert("called"+companyId)
  return (dispatch) => {
    if (companyId == '0') {
      dispatch(loadSlaWrkSchSuccess(null));
    } else {
      axios.get(GLOBAL.slaWorkdayScheduleDefUrl + companyId)
        .then((slaWorkdaySchedules) => {
          if (!slaWorkdaySchedules) {
            throw Error(slaWorkdaySchedules.statusText);
          }
          return slaWorkdaySchedules;
        })
        .then((slaWorkdaySchedules) => {
          console.log('success in slaWorkdaySchedules...')
          dispatch(loadSlaWrkSchSuccess(slaWorkdaySchedules))
        })
        .catch((err) => {
          console.log(err);
        });
    }

  };
}

export function loadSlaHolSchedule(companyId) {
  return (dispatch) => {
    if (companyId == '0') {
      dispatch(loadSLaHolSchSuccess(null));
    } else {
      axios.get(GLOBAL.slaHolidayScheduleDefUrl + companyId)
        .then((slaHolidaySchedules) => {
          if (!slaHolidaySchedules) {
            throw Error(slaHolidaySchedules.statusText);
          }
          return slaHolidaySchedules;
        })
        .then((slaHolidaySchedules) => {
          console.log('success in loadSLaHolSchSuccess...')
          dispatch(loadSLaHolSchSuccess(slaHolidaySchedules))
        })
        .catch((err) => {
          console.log(err);
        });
    }

  };
}

export function loadSlaWrkDayScheduleDays(slaWrkScheduleDay) {
  console.log("slaWrkScheduleDay:",slaWrkScheduleDay);
  var str={};
	str.type=slaWrkScheduleDay;
	str = JSON.stringify(str);
  return (dispatch) => {
    axios.get(GLOBAL.slaWorkdayDayAndStatusUrl,{headers:{'query':str}})
      .then((slaWorkdaySchedulesDays) => {
        if (!slaWorkdaySchedulesDays) {
          throw Error(slaWorkdaySchedulesDays.statusText);
        }
        return slaWorkdaySchedulesDays;
      })
      .then((slaWorkdaySchedulesDays) => {
        console.log('success in loadSlaWrkDaySchDaySuccess...')
        dispatch(loadSlaWrkDaySchDaySuccess(slaWorkdaySchedulesDays))
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function loadSlaWrkDayScheduleStatus(slaWrkScheduleStatus) {
  console.log("slaWrkScheduleStatus:",slaWrkScheduleStatus);
  var str={};
	str.type=slaWrkScheduleStatus;
	str = JSON.stringify(str);
  return (dispatch) => {
    axios.get(GLOBAL.slaWorkdayDayAndStatusUrl,{headers:{'query':str}})
      .then((slaWorkdaySchedulesStatus) => {
        if (!slaWorkdaySchedulesStatus) {
          throw Error(slaWorkdaySchedulesStatus.statusText);
        }
        return slaWorkdaySchedulesStatus;
      })
      .then((slaWorkdaySchedulesStatus) => {
        console.log('success in loadSlaWrkDaySchStatusSuccess...')
        dispatch(loadSlaWrkDaySchStatusSuccess(slaWorkdaySchedulesStatus))
      })
      .catch((err) => {
        console.log(err);
      });
  };
}



export function loadSlaDefEditDetails(slaId) {
  //alert('action called and parameter is '+breakFixId)
  return (dispatch) => {
    dispatch({type:'COMING_SOON_LOADER_IN_PROGRESS'});
    //alert("EditGroupIdAction123"+breakFixId);h()
    if (slaId == undefined || slaId == "") {
      //console.log("slaId not found");
    } else {
      //alert("my url is "+GLOBAL.breakFixEditUrl+breakFixId);
      api.get(GLOBAL.slaDefViewUrl + slaId)
        .then((slaDefEditDetails) => {
          if (!slaDefEditDetails) {//alert('not slaDefEditDetails')
            //dispatch(loadBreakFixFailure(slaDefEditDetails.statusText))
            throw Error(slaDefEditDetails.statusText);
          }
          return slaDefEditDetails;
        })
        .then((slaDefEditDetails) => {//alert('yes it is')
          dispatch(loadSlaDefEditDetailsSuccess(slaDefEditDetails))
          dispatch({type:'COMING_SOON_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
        })
        .catch((err) => {
          console.log(err);
        });
      //console.log("not calling loadSlaDefEditDetails");
    }
  };
}

export function loadSlaCriteriaType(moduleName, slaCriteria) {
  let attrOrderArray=new Array(3);
  return (dispatch) => {
    axios.get(GLOBAL.slaLovUrl + "/module/" + moduleName + "/field1Names/" + slaCriteria)
      .then((slaCriteriaType) => {
        if (!slaCriteriaType) {
          throw Error(slaCriteriaType.statusText);
        }
        return slaCriteriaType;
      })
      .then((slaCriteriaType) => {
        console.log('success in loadSlaCriteriaTypeSuccess...')
        slaCriteriaType.data.map((iter)=>{
                  if(iter.field1Key==='Start')
                      attrOrderArray[0]=iter;
                  else if(iter.field1Key==='Stop')
                      attrOrderArray[1]=iter;
                  else if(iter.field1Key==='Pause')
                      attrOrderArray[2]=iter;
        });
        dispatch(loadSlaCriteriaTypeSuccess(attrOrderArray))
      })
      .catch((err) => {
        console.log(err);
      });

  };
}

export function loadSlaOperator(moduleName, slaOperator) {
  return (dispatch) => {
    axios.get(GLOBAL.slaLovUrl + "/module/" + moduleName + "/field1Names/" + slaOperator)
      .then((slaOperatorType) => {
        if (!slaOperatorType) {
          throw Error(slaOperatorType.statusText);
        }
        return slaOperatorType;
      })
      .then((slaOperatorType) => {
        console.log('success in loadSlaOperatorSuccess...')
        dispatch(loadSlaOperatorSuccess(slaOperatorType))
      })
      .catch((err) => {
        console.log(err);
      });

  };
}

//======
export function loadSlaMilestoneAction(moduleName, slaMilestoneAction) {
  return (dispatch) => {
    axios.get(GLOBAL.slaLovUrl + "/module/" + moduleName + "/field1Names/" + slaMilestoneAction)
      .then((slaMilestoneActionList) => {
        if (!slaMilestoneActionList) {
          throw Error(slaMilestoneActionList.statusText);
        }
        return slaMilestoneActionList;
      })
      .then((slaMilestoneActionList) => {
        console.log('success in loadSlaMilestoneActionListSuccess...')
        dispatch(loadSlaMilestoneActionListSuccess(slaMilestoneActionList))
      })
      .catch((err) => {
        console.log(err);
      });

  };
}
//=====

//======
export function loadSlaMilestoneTarget(moduleName, slaMilestoneTarget) {
  return (dispatch) => {
    dispatch({type:'CAUSALRELATIONSHIP_DETAILS_IN_PROGRESS'});
    axios.get(GLOBAL.slaLovUrl + "/module/" + moduleName + "/field1Names/" + slaMilestoneTarget)
      .then((slaMilestoneTargetList) => {
        if (!slaMilestoneTargetList) {
          throw Error(slaMilestoneTargetList.statusText);
        }
        return slaMilestoneTargetList;
      })
      .then((slaMilestoneTargetList) => {
        console.log('success in loadSlaMilestoneTargetListSuccess...')
        dispatch(loadSlaMilestoneTargetListSuccess(slaMilestoneTargetList));
        dispatch({type:'CAUSALRELATIONSHIP_DETAILS_FETCHED_SUCCESSFULLY'});
      })
      .catch((err) => {
        console.log(err);
      });

  };
}
//=====

export function loadSlaAttributes(moduleName, moduleId) {
  let attributeList=new Array(7);
  return (dispatch) => {
    axios.get(GLOBAL.slaLovUrl + "/module/" + moduleName + "/field1Names/" + moduleId)
      .then((slaAttributes) => {
        if (!slaAttributes) {
          throw Error(slaAttributes.statusText);
        }
        return slaAttributes;
      })
      .then((slaAttributes) => {
        console.log("success in loadSlaAttributeSuccess...",slaAttributes);
        slaAttributes.data.map((item) => {
          if (item.field1Key === "Status") attributeList[0] = item;
          else if (item.field1Key === "Assignment Group")
            attributeList[1] = item;
          else if (item.field1Key === "Priority") attributeList[2] = item;
          else if (item.field1Key === "Service") attributeList[3] = item;
          else if (item.field1Key === "Impacted CI") attributeList[4] = item;
          else if (item.field1Key === "Requestor") attributeList[5] = item;
          else if (item.field1Key === "Class") attributeList[6] = item;
          else if (item.field1Key === "Business Criticality")
            attributeList[7] = item;
          else if (item.field1Key === "Hold Reason") attributeList[8] = item;
          else if (item.field1Key === "Support Company")
            attributeList[9] = item;
          else if (item.field1Key === "Task Template ID")
            attributeList[10] = item;
          else if (item.field1Key === "Task Type") attributeList[11] = item;
          else if (item.field1Key === "Work Item") attributeList[12] = item;
          else if (item.field1Key === "Parent.Priority") attributeList[13] = item;
          else if (item.field1Key === "Template Task Code") attributeList[14] = item;
          else if (item.field1Key === "Category") attributeList[15] = item;
          else if (item.field1Key === "Sub Category") attributeList[16] = item;
          else if (item.field1Key === "Type") attributeList[17] = item;
        });
        dispatch(loadSlaAttributeSuccess(attributeList))
      })
      .catch((err) => {
        console.log(err);
      });

  };
}

export function loadSlaAttributesList(slaId) {
  let attributeSelected={}, typeOfSelection={};
  return (dispatch) => {
    axios.get(GLOBAL.slaCriteriaListUrl + slaId)
      .then((slaAttributesList) => {
        if (!slaAttributesList) {
          throw Error(slaAttributesList.statusText);
        }
        return slaAttributesList;
      })
      .then((slaAttributesList) => {
        console.log('success in loadSlaAttributeSuccess...')
          if(slaAttributesList.data.Start.length>0){
            slaAttributesList.data.Start.map((item)=>{
              if(typeof attributeSelected[item.attributeName] === 'undefined')
                        attributeSelected[item.attributeName]=item.attributeName;
           });
           typeOfSelection['Start']=attributeSelected;
           attributeSelected={};
          }
          if(slaAttributesList.data.Stop.length>0){
            slaAttributesList.data.Stop.map((item)=>{
              if(typeof attributeSelected[item.attributeName] === 'undefined')
                        attributeSelected[item.attributeName]=item.attributeName;
           });
           typeOfSelection['Stop']=attributeSelected;
           attributeSelected={};
          }
          if(slaAttributesList.data.Pause.length>0){
            slaAttributesList.data.Pause.map((item)=>{
              if(typeof attributeSelected[item.attributeName] === 'undefined')
                        attributeSelected[item.attributeName]=item.attributeName;
           });
           typeOfSelection['Pause']=attributeSelected;
           attributeSelected={};
          }
          dispatch({type:'LOAD_SELECTEDATTRIBUTES_SUCESS', selectedAttributes: Object.keys(typeOfSelection).length===0?'':typeOfSelection});
          dispatch(loadSlaAttributeListSuccess(slaAttributesList));
        })
      .catch((err) => {
        console.log(err);
      });

  };
}

export function loadSlaMilestoneList(slaId) {
  let typeCollection={}, milestoneCollection={};
  return (dispatch) => {
    dispatch({type:'FETCHING_DETAILS_IN_PROGRESS'});
    axios.get(GLOBAL.slaMilestoneListUrl + slaId)
      .then((slaMilestoneList) => {
        if (!slaMilestoneList) {
          throw Error(slaMilestoneList.statusText);
        }
        return slaMilestoneList;
      })
      .then((slaMilestoneList) => {
        console.log('success in loadSlaMilestoneListSuccess...');
        if(typeof slaMilestoneList.data === 'object'){
          let eventType=''
        slaMilestoneList.data.map((item)=>{
              eventType=item.eventType.split('_')[1];
              if(typeof typeCollection[item.threshold] === 'undefined')
                    typeCollection[item.threshold==='100' && eventType==='Breach'?'Breach':'Milestone']=item.threshold==='100' && eventType==='Breach'?'Breach':'Milestone';
              if(typeof milestoneCollection[item.threshold] === 'undefined' && eventType==='Milestone')
                    milestoneCollection[item.threshold] = item.threshold;
        });
        dispatch({type:'SAVE_EXTRACTED_TYPES',collections:typeCollection});
        dispatch({type:'SAVE_EXTRACTED_MILESTONES',collections:milestoneCollection});
        dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});
       }
       else{
        dispatch({type:'SAVE_EXTRACTED_TYPES',collections:{}});
        dispatch({type:'SAVE_EXTRACTED_MILESTONES',collections:{}});
        dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});
       }
        dispatch(loadSlaMilestoneListSuccess(slaMilestoneList));
      })
      .catch((err) => {
        console.log(err);
      });

  };
}

export const loadWorkdayScheduleData = (scheduleId) => {
	let strObj={};
	strObj.scheduleId=scheduleId;
	strObj = JSON.stringify(strObj);
  return (dispatch) => {
    dispatch({
      type: 'WORKDAY_SCHEDULE_DATA_LOAD_SUCCESS',
      workdayScheduleDetails: {},
      isFetching: true
    })
    api.get(GLOBAL.workdayScheduleItemDetails,{headers:{'query':strObj}})
      .then((workdayDetails) => {
        if (!workdayDetails) {
          throw Error(workdayDetails.statusText);
        }
        return workdayDetails;
      })
      .then((workdayDetails) => {
        workdayDetails.data.companyId+='',
        workdayDetails.data.createdBy+='',
        workdayDetails.data.createdOn+='',
        workdayDetails.data.modifiedOn+='',
        workdayDetails.data.statusId+='',
        workdayDetails.data.timeZoneOffset+='',
        workdayDetails.data.workdayScheduleTimeZone+='';
        dispatch(workdayDetailsLoadSuccess(workdayDetails.data));
        dispatch(newWorkScheduleAssociation(workdayDetails.data));
      })
      .catch((err) => {
        console.log(err);
      })
  }
};

export const newWorkScheduleAssociation = (workdayScheduleData) => {
  return (dispatch) => {
    dispatch({
      'type': 'NEW_WORK_SCHEDULE_LOAD_SUCCESS',
      workdayScheduleData: workdayScheduleData,
      isFetching: false
    });
  }
};
const workdayDetailsLoadSuccess = (workdayDetails) => {
  let workdayScheduleList = workdayDetails.workdayScheduleList;
  let workDayObj = {
    "scheduleName": workdayDetails.scheduleName,
    "scheduleStatus": workdayDetails.statusId,
    "scheduleCompany": workdayDetails.companyId,
    "scheduleId": workdayDetails.scheduleId,
    "scheduleCode": workdayDetails.scheduleCode,
    "scheduleCompanyName": workdayDetails.companyName,
    "scheduleStatusName": workdayDetails.status,
    "workdayScheduleTimeZone": workdayDetails.workdayScheduleTimeZone,
    "workdayScheduleTimeZoneLabel": workdayDetails.workdayScheduleTimeZoneLabel,
    "timeZoneOffset": workdayDetails.timeZoneOffset,
    "SundayStartTime": null,
    "SundayEndTime": null,
    "MondayStartTime": null,
    "MondayEndTime": null,
    "TuesdayStartTime": null,
    "TuesdayEndTime": null,
    "WednesdayStartTime": null,
    "WednesdayEndTime": null,
    "ThursdayStartTime": null,
    "ThursdayEndTime": null,
    "FridayStartTime": null,
    "FridayEndTime": null,
    "SaturdayStartTime": null,
    "SaturdayEndTime": null
  }
  for (let i = 0; i < workdayScheduleList.length; i++) {
    switch (workdayScheduleList[i].day) {
      case 'SUNDAY':
        workDayObj.SundayStartTime = workdayScheduleList[i].startTime;
        workDayObj.SundayEndTime = workdayScheduleList[i].endTime;
        break;
      case 'MONDAY':
        workDayObj.MondayStartTime = workdayScheduleList[i].startTime;
        workDayObj.MondayEndTime = workdayScheduleList[i].endTime;
        break;
      case 'TUESDAY':
        workDayObj.TuesdayStartTime = workdayScheduleList[i].startTime;
        workDayObj.TuesdayEndTime = workdayScheduleList[i].endTime;
        break;
      case 'WEDNESDAY':
        workDayObj.WednesdayStartTime = workdayScheduleList[i].startTime;
        workDayObj.WednesdayEndTime = workdayScheduleList[i].endTime;
        break;
      case 'THURSDAY':
        workDayObj.ThursdayStartTime = workdayScheduleList[i].startTime;
        workDayObj.ThursdayEndTime = workdayScheduleList[i].endTime;
        break;
      case 'FRIDAY':
        workDayObj.FridayStartTime = workdayScheduleList[i].startTime;
        workDayObj.FridayEndTime = workdayScheduleList[i].endTime;
        break;
      case 'SATURDAY':
        workDayObj.SaturdayStartTime = workdayScheduleList[i].startTime;
        workDayObj.SaturdayEndTime = workdayScheduleList[i].endTime;
        break;
    }
  }

  return {
    'type': 'WORKDAY_SCHEDULE_DATA_LOAD_SUCCESS',
    workdayScheduleDetails: workDayObj,
    isFetching: false
  };
}

export const loadHolidayScheduleData = (scheduleId) => {
  return (dispatch) => {
    dispatch({
      'type': 'HOLIDAY_SCHEDULE_DATA_LOAD_SUCCESS',
      holidayScheduleDetails: {},
      isFetching: true
    });
    api.get(GLOBAL.holidayScheduleItemDetails + scheduleId)
      .then((holidayScheduleDetails) => {
        if (!holidayScheduleDetails) {
          throw Error(holidayScheduleDetails.statusText);
        }
        return holidayScheduleDetails;
      })
      .then((holidayScheduleDetails) => {
        dispatch(holidayScheduleDetailsLoadSuccess(holidayScheduleDetails.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
export const loadHolidayRelatedSlaData = (scheduleId) => {
  return (dispatch) => {
    dispatch({
      'type': 'HOLIDAY_RELATEDSLAS_DATA_LOAD_SUCCESS',
      holidayRelatedSla: {}
    });
    api.get(GLOBAL.viewHolidayRelatedSlasUrl + scheduleId)
    .then((holidayScheduleDetails) => {
        if (!holidayScheduleDetails) {
          throw Error(holidayScheduleDetails.statusText);
        }
        return holidayScheduleDetails;
      })
      .then((holidayScheduleDetails) => {
        dispatch({
          'type': 'HOLIDAY_RELATEDSLAS_DATA_LOAD_SUCCESS',
          holidayRelatedSla: holidayScheduleDetails.data
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
export const loadWorkRelatedSlaData = (scheduleId) => {
  return (dispatch) => {
    dispatch({
      'type': 'WORK_RELATEDSLAS_DATA_LOAD_SUCCESS',
      holidayRelatedSla: {},
    });
    api.get(GLOBAL.viewWorkdayRelatedSlasUrl + scheduleId)
      .then((holidayScheduleDetails) => {
        if (!holidayScheduleDetails) {
          throw Error(holidayScheduleDetails.statusText);
        }
        return holidayScheduleDetails;
      })
      .then((holidayScheduleDetails) => {
        dispatch({
          'type': 'WORK_RELATEDSLAS_DATA_LOAD_SUCCESS',
          workRelatedSla: holidayScheduleDetails.data
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

const holidayScheduleDetailsLoadSuccess = (holidayScheduleDetails) => {
  console.log("holidayScheduleDetails================");
  console.log(holidayScheduleDetails.data)
  let holidayScheduleObj = {
    "scheduleName": holidayScheduleDetails.data.scheduleName,
    "scheduleStatus": holidayScheduleDetails.data.statusId,
    "scheduleCompany": holidayScheduleDetails.data.companyId,
    "scheduleCompanyName": holidayScheduleDetails.data.companyName,
    "scheduleId": holidayScheduleDetails.data.scheduleId,
    //"scheduleCode": holidayScheduleDetails.data.scheduleCode,
    "scheduleStatusName": holidayScheduleDetails.data.status,
    //"holidayName": holidayScheduleDetails.data.scheduleList[0] == undefined ? '' : holidayScheduleDetails.scheduleList[0].holidayName,
    //"holidayDate": holidayScheduleDetails.data.scheduleList[0] == undefined ? '' : _.replace(holidayScheduleDetails.scheduleList[0].date, /\//g, "-")
  }

  return {
    'type': 'HOLIDAY_SCHEDULE_DATA_LOAD_SUCCESS',
    holidayScheduleDetails: holidayScheduleObj,
    isFetching: false
  };
}

export const loadHolidayDetails = (holidayId) => {
  // console.log('holidayIdholidayId',holidayId)
  return (dispatch) => {
    dispatch({
      'type': 'HOLIDAY_DETAILS_LOAD_SUCCESS',
      holidayDetails: {},
      isFetching: true
    });

    axios.get(GLOBAL.loadHolidayDetails + "/" + holidayId)
      .then((resultResponse) => {
        if (!resultResponse) {
          throw Error(resultResponse.statusText);
        }
        return resultResponse;
      })
      .then((resultResponse) => {
        // console.log('resultResponseresultResponse',resultResponse)
        dispatch(holidayDetailsLoadSuccess(resultResponse.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

const holidayDetailsLoadSuccess = (resultResponse) => {

  // console.log('resultResponseresultResponseresultResponse',resultResponse)
  return {
    'type': 'HOLIDAY_DETAILS_LOAD_SUCCESS',
    holidayDetails: resultResponse,
    isFetching: false
  };
}


export const loadSearchStringResults = (searchString) => {
  console.log('searchStringsearchString', searchString, GLOBAL.slaHolidatSearchStringUrl)
  return (dispatch) => {
    dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });

    axios.get(GLOBAL.slaHolidatSearchStringUrl + "/" + searchString)
      .then((resultResponse) => {
        if (!resultResponse) {
          throw Error(resultResponse.statusText);
        }
        return resultResponse;
      })
      .then((resultResponse) => {
        // console.log('resultResponseresultResponse',resultResponse)
        dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
        dispatch(loadSearchStringResultsSuccess(resultResponse));
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

const loadSearchStringResultsSuccess = (resultResponse) => {

  console.log('resultResponseresultResponseresultResponse', resultResponse);
  let slaHolidayEntries = resultResponse;
  // resultResponse = [];
  return {
    type: 'LOAD_SLAHOLIDAYENTRIES_SUCCESS',
    slaHolidayEntries
  };
};
export function editSLA(module) {

  let moduleName = module;
  console.log(GLOBAL.breakFixUrgencyUrl);
  if (moduleName !== "Task") {
    return dispatch => {
      api
        .get(GLOBAL.breakFixUrgencyUrl + "/" + moduleName)
        .then(breakFixUrgencyName => {
          if (!breakFixUrgencyName) {
            throw Error(breakFixUrgencyName.statusText);
          }
          return breakFixUrgencyName;
        })
        .then(breakFixUrgencyName => {
          dispatch({
            type: "SLA_LOV_FETCHED_SUCCESSFULLY",
            payload: breakFixUrgencyName.data
          });
        })
        .catch(err => {
          console.log(err);
        });
    };
  } else {
    console.log(GLOBAL.getTaskStatusForEdit);
    const response = axios.get(GLOBAL.getTaskStatusForEdit);
    return dispatch => {
      response.then(data => {
        dispatch({
          type: "SLA_LOV_FETCHED_SUCCESSFULLY",
          payload: data
        });
      });
    };
  }
}


export function postAttributeBasedSlaAction(Status,Service,CompanyId,CI,Priority,Business_Criticality,Requestor,Class) {

let obj = {};
    obj.companyId = CompanyId,
    obj.currentPage = "",
    obj.size = "",
		obj.conditions = [
			{
				"attribute": "Service",
				"value": Service
			}
		]
  return (dispatch) => {

		axios.post(GLOBAL.attributeBasedSlaUrl, obj).then(response => {
				if (response.status == 200 || response.status == 201) {
				
        dispatch({
          type: 'ATTRIBUTE_BASED_SLA',
          attributeBasedSlaData: response.data.data
        })
			}
    })
			.catch(error => {
				console.log("ERROR==>", error);

			});
    }
}

export const getAdminPrefLevel = (companyId) => {
  let str = {};
  str["companyId"] = companyId;
  str["module"] = "Foundation";
  str["propertyId"] = 35;
  str["businessFunctionId"] = "";
  str = JSON.stringify(str);
  return (dispatch) => {
    axios
    .get("/api/getAdminPreferences", { headers: { query: str } })
    .then((response) => {
      const data = response?.data?.[0];
      const value = JSON.parse(data?.value);
      dispatch({ type: "OPS_CAT_LEVELS", level:value  });
    })
    .catch((err) => {
      console.log(err);
    });
  };
  };

