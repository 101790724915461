
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";
import OnClickOutside from 'react-onclickoutside';
import submit from './editInvestigationValidation.js';
import BreakFixHamburger from './BreakFixHamburger.js';
import StatusHamburger from './StatusHamburger.js';
import { reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import {connect} from 'react-redux';
import {LuActivitySquare, LuClipboardCheck,LuCalendarClock} from "react-icons/lu";
import {ImAttachment, ImSpinner6} from "react-icons/im";
import {FaRegEye} from "react-icons/fa6";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {LiaHourglass} from "react-icons/lia";
import {HiOutlineSpeakerphone,HiOutlineRefresh} from "react-icons/hi";
import { BiDotsVertical } from "react-icons/bi";
import {PiTagBold} from "react-icons/pi";
import {TbSubtask, TbCirclesRelation, TbTextWrap} from "react-icons/tb";
import { RiStackshareLine } from "react-icons/ri";
import axios from 'axios';
import {GLOBAL} from '_Globals';
import {LinkContainer} from 'react-router-bootstrap';
let currentStatus="";
let priorityColorType = '';

import ExportTo from '_Commons/WorkItemBoard/exportTo.js';

import {fetchInvestigationGroupList,setBreakfixQuickViewTableCarouselIndex, resetBFQVFilters} from '_Actions/breakFix/quickViewAction';
import { loadInvestigationList } from '_Actions/investigation/investigationAction';

import {checkRelatedTaskForItem} from '_Actions/task/TaskDataAction';
import swal from 'sweetalert';
import {loadRelatedBreakFixDataToProblem} from '_Actions/investigation/invRelatedDataAction';
import { bindActionCreators } from 'redux';
import {getInvestigationItemDetails,getInvestigationItemDetailsById,changeFieldStatusData} from '_Actions/investigation/investigationAction.js';
import { loadStatusBasedHamburgerOptions } from '_Actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '_Actions/common/commonApisActions.js';
import {getInvestigationTeamList} from "../../../../actions/investigation/investigationAction";
import {setInitialwibGlobalSearchFilterData} from '_Actions/globalSearch/globalSearchAction';
import {PiBookOpenText} from "react-icons/pi";
import { MyContext } from '_MyContext';
//let role_id=userDetails.role_id;
class InvestigationEditHeader extends React.Component {
	static contextType = MyContext;
	constructor(props) {
		super(props);
		this.state = {
			hamburgerShowHide: false,
			statusHamburgerShowHide: false,
			priorityColorType: '',
			d2cnumber:'',
			showSubmit:true,
			isServiceDesk: false
		};
		this.callbackFun = this.callbackFun.bind(this);
		this.callBreakFixApi = this.callBreakFixApi.bind(this);
		this.statusChange = this.statusChange.bind(this);
		this.goBack = this.goBack.bind(this);
		this.showKnowledgeArticle = this.showKnowledgeArticle.bind(this);
	}
	
	statusHamburgerShowHide(event) {
		this.setState({ statusHamburgerShowHide: !this.state.statusHamburgerShowHide });
	}

	hamburgerShowHide(event) {
		this.setState({ hamburgerShowHide: !this.state.hamburgerShowHide });
	}
	callbackFun(id) {
		this.setState({ hamburgerShowHide: id });
		this.setState({ statusHamburgerShowHide: id });
	}
	statusChange(type = ""){
		if (this.props.user_id != this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17')))
			swal({
				text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
				button: this.props.translator['OK'],
			});
		else {

			let ref = this;
			let postjson = {
				"qualified": "N",
				// "qualificationComments": "Closure reason : Disqualified",
				"closureComments":"Closure reason :Disqualified",
				"status": '65'
			};
			let postjsonactivity = {
				"itemId": this.props.investigationItemDetails.investigationId,
				"description": "Closure reason : Disqualified",
				"isInternal": "",
				"createdOn": "",
				"createdBy": "",
				"module": "Investigation",
				"createdByName": ""
			};

			if(type){
				let descInitial = "";
                if(type == 'correctiveActionNotImplemented'){
                    descInitial = 'Closure reason : Corrective Action - Not Implemented';
                }
                if(type == 'withdrawInvestigation'){
                    descInitial = 'Closure reason : Investigation Withdrawn';
                }
				postjson = {
					"closureComments": descInitial,
					"status": "65",
					"modifiedBy":"",
					"modifiedByName":""
				};
				postjsonactivity['description'] = descInitial;
			}

				axios.patch(GLOBAL.investigationEditUrl + this.props.investigationItemDetails.investigationId, postjson)
					.then(function (response) {
						if (response.status === 200) {
							ref.setState({
								//savedMessage:"Requested cteated for order "+response.data.breakfixNumber
								savedMessage: "Problem Disqualification successfully done"
							})
							//boc post in activity log
							axios.post(GLOBAL.breakFixActivityLogPostUrl, postjsonactivity)
								.then(function (response) {
									// this.setState({ saveLoader: false });
									// console.log("response.statusText:::" + response.status)
								})
								.catch(function (error) {
									// this.setState({ saveLoader: false });
									// console.log(error);
									//alert("H1"+error);
								});

							ref.props.getInvestigationItemDetailsById(response.data.investigationId.toString());

							// ref.props.loadBreakFixAuditLogDetails(response.data.investigationId.toString(), "dsc", "Investigation",'STATUS');
							ref.props.loadStatusBasedHamburgerOptions('Investigation', response.data.status, response.data.investigationId);
						} else {
							// console.log("response status is not ok:" + response.statusText)
							// this.setState({ saveLoader: false });
						}
						ref.props.changeActionView('');

					})
					.catch(function (error) {
						// console.log(error);
						//alert("H1"+error);
					});
			// }


}
	}
	componentWillReceiveProps(nextProps){
		currentStatus=nextProps.investigationItemDetails.status;
		// console.log("Next Props===",currentStatus);

	}

	componentDidMount() {
	//alert(this.props.itemDetails.serviceId)
//	console.log("header::::"+this.props.investigationItemDetails.serviceId)

	let investigationItemDetails = this.props.investigationItemDetails;
	//alert(""+investigationItemDetails.urgency)
	currentStatus=this.props.investigationItemDetails.status;
	axios.get(GLOBAL.d2cNumberUrl+this.props.investigationItemDetails.requestId+'?module=Investigation').then((response)=>{
	//console.log("getd2c:::"+response.data)

			this.setState({
				d2cnumber:response.data
			}
			)
	 });


	//console.log(""+investigationItemDetails)
	let urgencyValue = this.props.investigationItemDetails.urgency;
	let criticalityValue = "";
	if (this.props.investigationItemDetails.serviceCriticality == "Significant") {
		criticalityValue = 20;
	} else if (this.props.investigationItemDetails.serviceCriticality == "Moderate") {
		criticalityValue = 25;
	} else if (this.props.investigationItemDetails.serviceCriticality == "Low") {
		criticalityValue = 30;
	} else {
		criticalityValue = 0;
	}
	if (urgencyValue == "High") {
		urgencyValue = 5;
	} else if (urgencyValue == "Medium") {
		urgencyValue = 10;
	} else if (urgencyValue == "Low") {
		urgencyValue = 15;
	}else {
		urgencyValue = 0;
	}
	let priorityData = '';
	let priorityActualValue = '';
	let priorityActualColor = '';
	let priorityUrl = `/serviceManagement/rest/priority?module=Investigation&criticality=${criticalityValue}&urgency=${urgencyValue}`;
	axios.get(priorityUrl).then((response) => {
		priorityData = response.data;
	//	priorityActualValue = priorityData.priority;
		//priorityActualColor = priorityData.priorityColor;
	//	priorityColorType = priorityData.priorityColor

		this.setState({
			priorityColorType:priorityData.priorityColor,priorityActualValue:priorityData.priorityValue,priorityActualColor:priorityData.priorityColor
		}
		)

	});
	if(!(this.props.roleIdentification('17')||this.props.roleIdentification('16')||this.props.roleIdentification('53'))){
		this.setState({isServiceDesk: this.props.roleIdentification('48')});
	}

		if (GLOBAL.slaHeadEnable) {
			this.props.changeActionView('sla')
		}

	this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
	this.context.newTabPrevent();
}
componentDidUpdate() {
	this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
	this.context.newTabPrevent();
}

callBreakFixApi(){

	this.props.resetBFQVFilters();
	this.props.fetchInvestigationGroupList();
	this.props.setBreakfixQuickViewTableCarouselIndex("2");
	 
	let searchParams = Object.assign({});
	searchParams.searchByList = 'status';
	searchParams.multipleValueList = 'Under Review,Under Investigation,Root Cause Identified,Under Corrective Action,Corrective Action Not required,Corrected';
	searchParams.size = 10;        
	searchParams.currentPage = 0;

	this.props.loadInvestigationList(searchParams);
}
goBack() {
		this.props.setInitialwibGlobalSearchFilterData();
		let searchParams = Object.assign({});
      searchParams.searchByList = '';
      searchParams.multipleValueList = '';
      searchParams.size = 10;
      searchParams.currentPage = 0;
      searchParams.sortBy = 'createdOn';
      searchParams.orderBy = 'desc';
     // this.props.loadInvestigationList(searchParams, ["Under Review", "Under Investigation", "Root Cause Identified", "Under Corrective Action", "Corrective Action Not required", "Corrected"], [], []); 
		this.props.setBreakfixQuickViewTableCarouselIndex("2");

		
	}

	showKnowledgeArticle(){
		try{
			// console.log('******************', this.props?.investigationItemDetails);
			if( ['45', '50', '55', '60', '65', '70'].includes(this.props?.investigationItemDetails?.status_id) ){
				return true;
			}
			return false;
		}catch(e){
			console.error(e);
			return false;
		}
	}


	render() {
		let { handleSubmit, pristine, reset, submitting } = this.props
		return (
			<Row className="margin-b-15">
				<Col lg={8} xs={12}>
					<Row>
						<Col lg={8} md={7} sm={6} xs={12}>
					<h1 bsPrefix=" " className={"sPageHeading"} >
						{this.props.translator['Investigation']}
						<div className="nowap">
						<Button bsPrefix=" " title="Open Timeline" onClick={()=>{this.props.showTimeline(true)}} className="tinelineicon btn btn-link"><LuCalendarClock /></Button>
						<ExportTo 
							exportDocument={this.props.exportDocument} 
							title="Export Problem Investigation Report" 
							moduleName="investigation" 
						/>
						</div>
						<div className="order position-re">
							<div className="lnk">{this.state.d2cnumber} &gt;&gt; {this.props.investigationItemDetails.investigationNumber}</div>
						</div>
					</h1>
				</Col>
				<Col lg={4} md={5} sm={6} xs={12}>
					<div className="toprigLnk toprigStatusDv">
						<div className="thrlnebtn">
							<Button title="Statuses" onClick={this.statusHamburgerShowHide.bind(this)} className="btnDropMenu btn btn-default" bsPrefix=" " disabled={this.props.disableFieldsForGuestRole}>{currentStatus} <i className="fa fa-angle-down" aria-hidden="true"></i></Button>
							{this.state.statusHamburgerShowHide ? <StatusHamburger statusChange = {this.statusChange} fieldStatus={this.props.fieldStatus} user_id={this.props.user_id} role_id={this.props.role_id} fun={this.callbackFun} changeActionView={this.props.changeActionView} translator={this.props.translator} roleIdentification={this.props.roleIdentification} invRelatedDataDetailsCount={this.props.invRelatedDataDetailsCount} investigationTeamList={this.props.investigationTeamList} getInvestigationItemDetailsById={this.props.getInvestigationItemDetailsById} rightEditPanel={this.props.rightEditPanel} createKnowledgeArticle={this.props.createKnowledgeArticle} /> : null}
						</div>
						<div className="thrlnebtn margin-l-10">
							<Button title="Actions" onClick={this.hamburgerShowHide.bind(this)} className="btnDropMenu bgtran btn btn-default" bsPrefix=" "><BiDotsVertical/></Button>
								{this.state.hamburgerShowHide ? <BreakFixHamburger fieldStatus={this.props.fieldStatus} user_id={this.props.user_id} role_id={this.props.role_id} fun={this.callbackFun} changeActionView={this.props.changeActionView} translator={this.props.translator} roleIdentification={this.props.roleIdentification} investigationTeamList={this.props.investigationTeamList} rightEditPanel={this.props.rightEditPanel} isProblemManagerEnabled={this.props.isProblemManagerEnabled}/> : null}
						</div>
					</div>
				</Col>
				</Row>
				</Col>
				<Col lg={4} xs={12} >
					<div className={`float-r toprigLnk fulfillHeader`}>
					<Nav className="icnlnk riggep float-l" as="ul">
						<Nav.Item
						title={this.props.translator["Activity Details"]}
						eventKey={4}
						as="li"
						>
						<a className={this.props.actionView == "processHierarchy" ? "active" : ""}
						href="javascript:void(0)"
						onClick={() => {
							this.props.changeActionView('processHierarchy');
							this.props.rightEditPanel(true);
						}}
						>
						<LuActivitySquare/>
						</a>
						</Nav.Item>
						<Nav.Item as="li" title={this.props.translator['Custom Attribute']} eventKey={1}><a className={this.props.actionView == "tags" ? "active" : ""} href="javascript:void(0)" onClick={() => {this.props.changeActionView('tags');this.props.rightEditPanel(true);}}><span><PiTagBold className="svgRotate90"/></span></a></Nav.Item>
						<Nav.Item
						title={this.props.translator["Attachment"]}
						eventKey={0}
						as="li"
						>
						<a className={this.props.actionView == "provideAttachments" ? "active" : ""}
						href="javascript:void(0)"
						onClick={() => {
							this.props.changeActionView('provideAttachments');
							this.props.rightEditPanel(true);
						}}
						>
						<ImAttachment/>
						</a>
						</Nav.Item>		
						<Nav.Item
							title={this.props.translator["Tasks"]}
							eventKey={1}
							as="li"
						>
						<a className={this.props.actionView == "task" ? "active" : ""}
						href="javascript:void(0)"
						onClick={() => {
							this.props.changeActionView('task');
							this.props.rightEditPanel(true);
						}}
						>
						<LuClipboardCheck/>
						</a>
						</Nav.Item>	

						{(this.showKnowledgeArticle() == true) ? 
							 <Nav.Item as="li" title={this.props.translator['Knowledge Articles']} eventKey={1}>
								<a className={this.props.actionView == "knowledgeArticles" ? "active" : ""} href="javascript:void(0)" onClick={() => { this.props.changeActionView('knowledgeArticles'); this.props.rightEditPanel(true);}}><PiBookOpenText/></a>
							</Nav.Item> : null }

						{/* <Nav.Item title="Notify" eventKey={1} as="li">
							<a
							href={void 0}
							onClick={() => {
								this.props.changeActionView('notify');
								this.props.rightEditPanel(true);
							}}
							>
							<HiOutlineSpeakerphone/>
							</a>
						</Nav.Item> */}
						<Nav.Item title={this.props.translator['Watcher Details']} eventKey={1} as="li">
							<a href="javascript:void(0)" className={this.props.actionView == "watcher" ? "active" : ""} onClick={() => {this.props.changeActionView('watcher');this.props.rightEditPanel(true);}}>
							<span><FaRegEye/><b className="watcher-count">{this.props.watcherCount}</b></span>
							</a>
						</Nav.Item>
						<Nav.Item
							title={this.props.translator["SLA Progress"]}
							eventKey={2}
							as="li"
						>
							<a className={this.props.actionView == "sla" ? "active" : ""}
							href="javascript:void(0)"
							onClick={() => {
								this.props.changeActionView('sla');
								this.props.rightEditPanel(true);
							}}
							>
							<LiaHourglass className="hourGlsWidth" />
							</a>
						</Nav.Item>			
						<NavDropdown
							as="ul"
							className="threeDotul"
							eventKey={3}
							title={
							<span title="More">
								<BiDotsVertical/>
							</span>
							}
							id="threeDotdrpdown"
						>
							<NavDropdown.Item as="li" bsPrefix=" " title='External Attributes' eventKey={3.1} >
								<Nav.Link href="#" onClick={() => { this.props.changeActionView('showExternalSystemAttributes');this.props.rightEditPanel(true); }}>
								<TbTextWrap />External Attributes
								</Nav.Link>
							</NavDropdown.Item>
							<NavDropdown.Item as="li" bsPrefix=" " title='Related Work Items' eventKey={3.2} >
								<Nav.Link href="#" onClick={() => { this.props.changeActionView('relatedWorkItems');this.props.rightEditPanel(true); }}>
								<TbSubtask/>Related Work Items
								</Nav.Link>
							</NavDropdown.Item>
							<NavDropdown.Item as="li" bsPrefix=" " title='Related CI' eventKey={3.3} >
								<Nav.Link href="#" onClick={() => { this.props.changeActionView('relatedCI');this.props.rightEditPanel(true); }}>
								<TbCirclesRelation />Related CI
								</Nav.Link>
							</NavDropdown.Item>
							<NavDropdown.Item as="li" bsPrefix=" " title={this.props.translator['Causal Relationship']} eventKey={3.4}>
								<Nav.Link href="#" onClick={() => { this.props.changeActionView('causalRelation');this.props.rightEditPanel(true); }}>
								<RiStackshareLine/>{this.props.translator['Causal Relationship']}
								</Nav.Link>
							</NavDropdown.Item>

							<NavDropdown.Item as="li" bsPrefix=" " title="Notify" eventKey={3.5}>
								<Nav.Link href="#" onClick={() => { this.props.changeActionView('notify'); }}>
								<HiOutlineSpeakerphone/>Notify
								</Nav.Link>
							</NavDropdown.Item>
						</NavDropdown> 
					</Nav>
						
						<Nav className="icnlnk" as={"ul"}>
							<Nav.Item bsPrefix=' ' eventKey={1} as="li">
								<a title={this.props.translator["Refresh"]} href="javascript:void(0)" onClick={() => {this.props.RefreshScreen();}}>
									<HiOutlineRefresh className="refreshFlip" />
								</a>
							</Nav.Item>
							<Nav.Item
								title={this.props.translator["Save"]}
								eventKey={2}
								onClick={this.props.disableFieldsForGuestRole?'':handleSubmit(submit)}
								disabled={
								this.props.investigationItemDetails === "Closed"
									? true
									: submitting
								}
								as="li"
							>
								<Link to="javascript:void(0)" ref={(e) => (this.saveObjRef = e)} className="ctrlKeyPrevent">
								<span>{submitting ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}</span>
								</Link>
							</Nav.Item>
							
							<Nav.Item as="li" title={this.props.translator["Close"]} eventKey={3}>
								<Link to="/quickViewBreakFix" ref={e => this.closeButton = e} className="ctrlKeyPrevent" >
									<span onClick={this.goBack}><IoClose/></span>
								</Link>
							</Nav.Item>
						</Nav>
					</div>
				</Col>
			</Row>

		);
	}
}

InvestigationEditHeader = reduxForm({
	form: 'investigationEditForm'
})(InvestigationEditHeader);

const mapStateToProps = ({investigationItemDetails,fieldStatus, invRelatedDataDetailsCount, watcherDetailsFetched}) => {
	let watcherCount = watcherDetailsFetched.length;
	return {
		investigationItemDetails:(investigationItemDetails && investigationItemDetails.data ? investigationItemDetails.data[0]:investigationItemDetails),
		invRelatedDataDetailsCount,
		fieldStatus,
		watcherCount
	};
};
export default connect(mapStateToProps,{checkRelatedTaskForItem,fetchInvestigationGroupList,loadInvestigationList,
	setBreakfixQuickViewTableCarouselIndex, resetBFQVFilters,loadRelatedBreakFixDataToProblem,
	getInvestigationItemDetailsById,changeFieldStatusData,loadStatusBasedHamburgerOptions,loadTimelineData,getInvestigationTeamList,getInvestigationItemDetails,setInitialwibGlobalSearchFilterData})(InvestigationEditHeader);