/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector, connect } from "react-redux";
import { useNavigate } from "react-router";
import { PiPencilSimpleBold } from "react-icons/pi";
import Swal from "sweetalert2";
import { HiPlus } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { NavLink } from "react-router-dom";

import ListLoader from "../../../common/loaders/ListLoader.js";
import {
  loadScheduleAssociationDataByScheduleId,
  deleteScheduleAssociationData,
} from "_Actions/onCallSchedule/schedule/onCallScheduleAction";

const RightSide = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);

  const on_call_schedule_loader_reducer = useSelector(
    (state) => state.on_call_schedule_loader_reducer
  );
  const on_call_schedule_association_reducer = useSelector(
    (state) => state.on_call_schedule_association_reducer
  );
  const scheduleDetailsByScheduleId = useSelector(
    (state) => state.on_call_schedule_reducer.scheduleDetailsByScheduleId
  );

  const [disableAdd, setDisableAdd] = useState(false);

  useEffect(() => {
    dispatch(loadScheduleAssociationDataByScheduleId(props.scheduleId));
  }, []);

  useEffect(() => {
    if (
      on_call_schedule_association_reducer.scheduleAssociationList &&
      on_call_schedule_association_reducer.scheduleAssociationList.length > 0
    ) {
      let workdayCount = 0;
      on_call_schedule_association_reducer.scheduleAssociationList.forEach(
        (element) => {
          if (element.scheduleType == "Workday") {
            workdayCount++;
          }
        }
      );
      if (
        scheduleDetailsByScheduleId &&
        scheduleDetailsByScheduleId.scheduleType == "Workday" &&
        workdayCount > 0
      ) {
        setDisableAdd(true);
      }
    }
  }, [on_call_schedule_association_reducer]);

  function onDeleteClick(saId) {
    dispatch(
      deleteScheduleAssociationData(saId, () => {
        dispatch(loadScheduleAssociationDataByScheduleId(props.scheduleId));
      })
    );
  }

  if (on_call_schedule_loader_reducer.showScheduleListLoader) {
    return <ListLoader />;
  } else {
    return (
      <div className="stickyArea rBoxStyle">
        <Row>
          <Col xs={12}>
            <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
              <ul>
                {!props.hideButtons || disableAdd
                  ? ""
                  : on_call_schedule_association_reducer.scheduleAssociationList
                      .length === 0 && (
                      <li>
                        <Button
                          bsPrefix=" "
                          onClick={() => {                                                  
                            props.setActionViewFun("scheduleAssociationForm");
                            props.setAddEditModeFun("add");
                          }}
                          title={translator["Add"]}
                          bsClass=""
                          bsStyle=""
                        >
                          <HiPlus />
                        </Button>
                      </li>
                    )}
                <li className="d-md-none">
                  <button
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                  >
                    <IoClose />
                  </button>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">
                Schedule Duration
              </div>
            </div>
          </Col>
        </Row>
        <div className="rBoxGap">
          <div className="f-size-12">
            <Table
              bordered
              condensed
              hover
              responsive
              className="tableView  tableWithOutPointer"
            >
              <thead>
                <tr>
                  <th width="20%" className="sort">
                    {translator["Name"]}
                  </th>
                  <th width="20%" className="sort">
                    {translator["Type"]}
                  </th>
                  <th width="20%" className="text-c">
                    {translator["Start Date"]}
                  </th>
                  <th width="20%" className="text-c">
                    {translator["End Date"]}
                  </th>
                  <th width="20%" className="text-c">
                    Repeats
                  </th>
                  <th width="20%" className="text-c">
                    {translator["Edit"]}
                  </th>
                </tr>
              </thead>
              <tbody>
                {on_call_schedule_loader_reducer.showScheduleAssociationLoader ? (
                  <tr>
                    <td colSpan="6">
                      <ListLoader />
                    </td>
                  </tr>
                ) : on_call_schedule_association_reducer.scheduleAssociationList
                    .length > 0 ? (
                  on_call_schedule_association_reducer.scheduleAssociationList.map(
                    (value, index) => {                         
                      const isNoEndDate = moment(value.scheduleEndDate).valueOf() === 0;                 
                      return (
                        <tr key={index}>
                          <td>{value.scheduleAssociationName}</td>
                          <td>{value.scheduleType}</td>
                          <td>{value.scheduleStartDate}</td>
                          <td>{isNoEndDate ? "No End Date" : value.scheduleEndDate}</td>
                          <td>{value?.scheduleRepeatType}</td>
                          <td>
                            <div className="text-c">
                              <i
                                title={translator["Edit"]}
                                className="fa fa-pencil-square-o cursorPoint"
                                onClick={(e) => {
                                  if (
                                    scheduleDetailsByScheduleId &&
                                    scheduleDetailsByScheduleId.onCallStatus === "Inactive"
                                  ) {           
                                    props.setEditFormValuesFun(value);
                                    props.setActionViewFun(
                                      "scheduleAssociationForm"
                                    );
                                    props.setAddEditModeFun("edit");
                                  } else {
                                    Swal.fire(
                                      "The status of the Maintenance Window must be set to “Inactive” in order to edit the Schedule Duration"
                                    );
                                  }

                                  
                                }}
                              ></i>
                              {/* <i
                                title={translator["Delete"]}
                                className="margin-l-10 fa fa-trash-o cursorPoint"
                                onClick={() => {
                                  onDeleteClick(value.saId);
                                }}
                              ></i> */}
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )
                ) : (
                  <tr>
                    <td colSpan={6} className="text-c">
                      {translator["No Data Found"]}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
};

export default RightSide;
