
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
//const getCategoryList = 'https://xsm-f.mybluemix.net/getOfferingsForListRestifySelfService/?categoryId=';

import { GLOBAL } from '../../../components/Globals'

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export function loadOrdersSuccess(ordersList){
	return {
		type: 'LOAD_ORDERS_SUCCESS',
		ordersList
	};
}

export function loadOrdersSuccessGlblSearch(ordersListGblSearch){
	return {
		type: 'LOAD_ORDERS_SUCCESS_GBL_SEARCH',
		ordersListGblSearch
	};
}

export function loadOrdersList(searchBy,type,userId,sorting,ordering,currentPage=0,size=10,listFilter,itemStatus='',email){
	let str={};
	if(listFilter == 'self') {
		if(itemStatus != '') {

			str.searchByList=searchBy+","+type+","+"itemStatus";
			str.multipleValueList=userId+"|"+"Breakfix,Item"+"|"+itemStatus;
		}
		else {

			str.searchByList=searchBy+","+type;
			str.multipleValueList=userId+"|"+"Breakfix,Item";
		}
	}
	else if(listFilter == 'onBehalf') {

		if(itemStatus != '') {

			str.searchByList="createdBy"+","+type+","+"itemStatus";
			str.multipleValueList=userId+"|"+"Breakfix,Item"+"|"+itemStatus;
		}
		else {

			str.searchByList="createdBy"+","+type;
			str.multipleValueList=userId+"|"+"Breakfix,Item";
		}
	}
	else if(listFilter == "watcher"){		
			// str.email = email;
			// str.itemStatus = itemStatus;
			// str.displayNumber ="";
			// str.ciServiceName ="";
			// str.requesterName ="";
			// str.createdOn ="";
			// str.modifiedOn ="";
			// str.sortBy =sorting;
			// str.orderBy =ordering;
			// str.currentPage =currentPage;
			// str.size =size;		
			if(itemStatus != '') {
		
					str.searchByList="createdBy"+","+type+","+"itemStatus"+","+"email";
					str.multipleValueList=userId+"|"+"Breakfix,Item"+"|"+itemStatus+"|"+email;
				}
				else {
		
					str.searchByList="createdBy"+","+type+","+"email";
					str.multipleValueList=userId+"|"+"Breakfix,Item"+"|"+email;
				}
	}
	// str.operatorList="or,and";
	str.sortBy=sorting;
	str.orderBy=ordering;
	// if(currentPage && currentPage != undefined)
		str.currentPage=currentPage;
	// if(size && size != undefined)
		str.size=size;
	if(listFilter==='onBehalf'){
		str.searchByList+=',requesterId';
		str.multipleValueList+='|';
		str.patternList='in,in,in,not in';
	}	
	//str = JSON.stringify(str);
	console.log("searchByList="+searchBy+","+type+"&multipleValueList="+userId+"|"+"Breakfix,Item"+"&sortBy="+sorting+"&orderBy="+ordering);
	return(dispatch) =>{
		dispatch({type: "LOADER_IN_PROGRESS_2"});
		api.post(GLOBAL.ordersGetUrl,str)
			.then((ordersList)=>{
				if(!ordersList){
					throw Error(ordersList.statusText);
				}
				return ordersList;
			})
			.then((ordersList) =>{
				dispatch(loadOrdersSuccess(ordersList))
				dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY_2'});
			})
			.catch((err) => {
				console.log(err);
				dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY_2'});
			});
	};
}

export function loadFilterOrderList(searchParams,currentPage=0,size=10,sortBy,asc_desc,searchFilterDropdown,searchText,pattern,status,filterVal,email){
	
	return(dispatch) =>{
		dispatch({type: "LOADER_IN_PROGRESS_3"});
		let itemStatus="";
        if(status==""){
			itemStatus='20,10,15,65,70,-20,100,60,50';
			if (typeof searchParams.patternList==='undefined') {
				if (pattern!="")
					searchParams.patternList=pattern;
			}
			else {
				searchParams.patternList+=','+pattern;
			}
			// searchParams.patternList=pattern;
		}
		else{
			itemStatus=status;
			typeof searchParams.patternList==='undefined'?searchParams.patternList=pattern!=""?pattern+","+'equal':"equal":searchParams.patternList=pattern!=""?pattern+','+searchParams.patternList+","+'equal':searchParams.patternList+",equal";
		}
		// if(searchFilterDropdown=="itemStatus")
		// {
		// 	if()

		// }
		// if(sortBy && sortBy != undefined)
		// 	searchParams.sortBy=sortBy;
		// else
		// 	searchParams.sortBy="createdOn";

		// if(asc_desc && asc_desc != undefined)
		// 	searchParams.orderBy=asc_desc;
		// else
		// 	searchParams.orderBy="desc";
		//let params = {};
		//str.searchByList=searchBy+","+type;
		//str.multipleValueList=userId+"|"+"Breakfix,Item";
		// searchParams.sortBy="createdOn";
		// searchParams.orderBy="desc";
		// if(currentPage && currentPage != undefined)
		searchParams.sortBy=sortBy;
		searchParams.orderBy=asc_desc;
			searchParams.currentPage=currentPage;
			// searchParams.status=Status;


		// if(size && size != undefined)
			searchParams.size=size;
			if(searchFilterDropdown && searchText && searchFilterDropdown != undefined && searchText != undefined) {
				// searchParams.patternList=pattern;
				if(searchFilterDropdown.length!=0){
			searchParams.searchByList=searchFilterDropdown+','+searchParams.searchByList+','+'itemStatus';
				}else{
					searchParams.searchByList=searchParams.searchByList+','+'itemStatus';
				}
			if(searchText.length!=0){
			searchParams.multipleValueList=searchText+'|'+searchParams.multipleValueList+'|'+itemStatus;
			}else{
				searchParams.multipleValueList=searchParams.multipleValueList+'|'+itemStatus;
	
			}
		}
		if(filterVal == 'watcher'){
			if(searchFilterDropdown && searchText && searchFilterDropdown != undefined && searchText != undefined) {
				
				if(searchFilterDropdown.length!=0){
			searchParams.searchByList=searchFilterDropdown+','+searchParams.searchByList+','+'itemStatus'+'email';
				}else{
					searchParams.searchByList=searchParams.searchByList+','+'itemStatus'+'email';
				}
			if(searchText.length!=0){
			searchParams.multipleValueList=searchText+'|'+searchParams.multipleValueList+'|'+itemStatus+'|'+ email;
			}else{
				searchParams.multipleValueList=searchParams.multipleValueList+'|'+itemStatus+'|'+ email;
	
			}
		}

		}
		console.log("searchParams: in action ytdvjhv m",searchParams);
        //if(!_.isEmpty(searchParams)) {params = {params:searchParams};} //lodash isEmpty checks for nulls too

				api.post(GLOBAL.ordersGetUrl, searchParams)
					.then((ordersList)=>{
						if (!ordersList) {
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							throw Error(ordersList.statusText);
						}
						// return ordersList;
						// })
						// .then((ordersList) =>{
						else {
							dispatch(loadOrdersSuccess(ordersList));
							dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_3' });
						}
					})
					.catch((err) => {
						console.log(err);
						dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY_3'});
					});
					//console.log("not calling loadBreakFixQuickView");

	};
}

export function loadOrdersFilterResultsForSearchString(searchBy,type,searchFilterDropdown,userId,sorting,ordering,currentPage=0,size=10,searchText,listFilter,pattern,email,status){
	let str={};
	// str.searchByList=searchFilterDropdown+","+searchBy;
	// str.multipleValueList=searchText+"|"+userId;
	if(listFilter == 'onBehalf') {
		str.searchByList=searchFilterDropdown+","+searchBy+","+type;
		str.multipleValueList=searchText+"|"+userId+"|"+"Breakfix,Item";
	}
	else if(listFilter == 'self') {
		str.searchByList=searchFilterDropdown+","+"createdBy"+","+type;
		str.multipleValueList=searchText+"|"+userId+"|"+"Breakfix,Item";
	}
	else if(listFilter == 'watcher') {
		str.searchByList=searchFilterDropdown+","+"createdBy"+","+type+","+"itemStatus"+","+"email";
		str.multipleValueList=searchText+"|"+userId+"|"+"Breakfix,Item"+"|"+status+"|"+email;
	}
	// str.operatorList="or,and";
	str.sortBy=sorting;
	str.orderBy=ordering;
	if(searchFilterDropdown==="itemStatus")
	str.patternList="equal";
	else
	str.patternList="like";
	//str.patternList="like";
	// if(currentPage && currentPage != undefined)
		str.currentPage=currentPage;
	// if(size && size != undefined)
		str.size=size;
	//str = JSON.stringify(str);
	if(listFilter==='self'){
		str.searchByList+=',requesterId';
		str.multipleValueList+='|';
		typeof str.patternList==='undefined' ? str.patternList='in,in,in,not in' : str.patternList+=',not in';
	}
	return(dispatch) =>{
		dispatch({type: "LOADER_IN_PROGRESS_1"});
		api.post(GLOBAL.ordersGetUrl,str)
			.then((ordersList)=>{
				if(!ordersList){
					throw Error(ordersList.statusText);
				}
				return ordersList;
			})
			.then((ordersList) =>{
				dispatch(loadOrdersSuccess(ordersList))
				dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY_1'});
			})
			.catch((err) => {
				console.log(err);
				dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY_1'});
			});
	};
}


export function loadOrdersFilterResultsForSearchStringGlobalSearch(searchBy,type,searchFilterDropdown,userId,sorting,ordering,currentPage=0,size=10,searchText,listFilter){
	let str={};
	// str.searchByList=searchFilterDropdown+","+searchBy;
	// str.multipleValueList=searchText+"|"+userId;
	if(listFilter == 'onBehalf') {
		str.searchByList=searchFilterDropdown+","+searchBy+","+type;
		str.multipleValueList=searchText+"|"+userId+"|"+"Breakfix,Item";
	}
	else if(listFilter == 'self') {
		str.searchByList=searchFilterDropdown+","+"createdBy"+","+type;
		str.multipleValueList=searchText+"|"+userId+"|"+"Breakfix,Item";
	}
	// str.operatorList="or,and";
	str.sortBy=sorting;
	str.orderBy=ordering;

	if(searchFilterDropdown==="itemStatus")
	str.patternList="equal";
	else
	str.patternList="like";
	//str.patternList="like";
	// if(currentPage && currentPage != undefined)
		str.currentPage=currentPage;
	// if(size && size != undefined)
		str.size=size;
	//str = JSON.stringify(str);
	return(dispatch) =>{
		dispatch({type: "FETCHING_ORDERS_GBL_SRCH_2"});
		api.post(GLOBAL.ordersGetUrl,str)
			.then((ordersList)=>{
				if(!ordersList){
					throw Error(ordersList.statusText);
				}
				return ordersList;
			})
			.then((ordersList) =>{
				dispatch(loadOrdersSuccessGlblSearch(ordersList))
				dispatch({type:'ORDERS_GBL_SRCH_SUCCESS_2'});
			})
			.catch((err) => {
				console.log(err);
				dispatch({type:'ORDERS_GBL_SRCH_SUCCESS_2'});
			});
	};
}

export const emptyOrdersReducer = () => {
	return (dispatch) => {
		dispatch({
			type: 'EMPTY_ORDERS_REDUCER'
		});
	};
};

export function addToSelectedOrderStatusList(status){
	//alert("addStatusToSelectedInvestigationStatusList"+status)
	return{
		type: 'ADD_TO_SELECTEDORDER_STATUS_LIST',
		payload: status
	};
}

export function removeFromSelectedOrderStatusList(status){
	//alert("removeFromSelectedOrderStatusList"+status)
	return{
		type: 'REMOVE_FROM_SELECTEDORDER_STATUS_LIST',
		payload: status
	};
}

export function addToSelectedOrderTypeList(orderType){
	//alert("addStatusToSelectedInvestigationStatusList"+status)
	return{
		type: 'ADD_TO_SELECTEDORDER_TYPE_LIST',
		payload: orderType
	};
}

export function removeFromSelectedOrderTypeList(orderType){
	return{
		type: 'REMOVE_FROM_SELECTEDORDER_TYPE_LIST',
		payload: orderType
	};
}

export function loadOrderDetails(orderId){
	// alert("loadOrderDetails"+orderId);
	let str={};
	str.orderId=orderId;
	//str.searchByList="itemid";
	str = JSON.stringify(str);

	return(dispatch) =>{
	//	api.get(GLOBAL.quickViewOrdersUrlMyRequest+"searchByList=itemid&valueList="+orderId)
	dispatch({type: "LOADER_IN_PROGRESS_4"});
	api.get(GLOBAL.quickViewOrdersUrlMyRequest,{headers:{'query':str}})
		
			.then((orderDetails)=>{
				if(!orderDetails){
					throw Error(orderDetails.statusText);
				}
				return orderDetails;
			})
			.then((orderDetails) =>{
				dispatch(loadOrdersDetailsSuccess(orderDetails.data))
				dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY_4'});

			})
			.catch((err) => {
				console.log(err);
				dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY_4'});

			});
	};
}
	export function fetchCancellationType(companyId,modules,fields,status){
		let languageSelected = cookies.get("gph");
        languageSelected = languageSelected.split("~");
        languageSelected = languageSelected[20];
		return (dispatch) =>{
			axios.get(`/api/company_menulist?companyId=${companyId}&module=${modules}&field=${fields}&language=${languageSelected}&status=${status}`)
			.then(reterivedReasons =>dispatch({type: "LOAD_CANCELLATION_REASON",reasons: reterivedReasons.data}));
		};
	}

export function loadOrdersDetailsSuccess(orderDetails){
	return {
		type: 'LOAD_ORDER_DETAILS_SUCCESS',
		orderDetails
	};
}

//getting breakfix order details
export function loadBreakFixOrderDetails(orderId){
	// alert("M12");
	console.log("showloaderAction1");
	return(dispatch) =>{
		dispatch({type:'LOADER_IN_PROGRESS_5'});
		axios.get(GLOBAL.quickViewBrkOrdersUrl+orderId+"?requireUrgencyValue=Y")
			.then((breakFixOrderDetails)=>{
				if(!breakFixOrderDetails){
					dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY_5'});
					throw Error(breakFixOrderDetails.statusText);
				}
				return breakFixOrderDetails;
			})
			.then((breakFixOrderDetails) =>{
				dispatch(loadBreakFixOrdersDetailsSuccess(breakFixOrderDetails))
				dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY_5'});
			})
			.catch((err) => {
				dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY_5'});
				console.log(err);
			});
	};
}

export function loadBreakFixOrdersDetailsSuccess(breakFixOrderDetails){
	return {
		type: 'LOAD_BREAKFIXORDER_DETAILS_SUCCESS',
		breakFixOrderDetails
	};
}
//==============
//getting approver list
export function loadApproverList(itemId,offeringId){
	let str={};
	str.itemId=itemId;
	str.offeringId=offeringId;
	str = JSON.stringify(str);
	console.log("?itemId="+itemId+"&offeringId="+offeringId);
		return(dispatch) =>{
		api.get(GLOBAL.approverListURL,{headers:{'query':str}})
			.then((approverList)=>{
				if(!approverList){
					throw Error(approverList.statusText);
				}
				return approverList;
			})
			.then((approverList) =>{
				dispatch(loadApproverListSuccess(approverList))
			})
			.catch((err) => {
				console.log(err);
			});
	};
}
export function loadApproverListSuccess(approverList){
	return {
		type: 'LOAD_APPROVERLIST_SUCCESS',
		approverList
	};
}

export function getQuestionsCategoryWise(itemId){
	return(dispatch) =>{
		dispatch({type:'SERVICE_REVIEW_LOADER_IN_PROGRESS'});
		api.get(GLOBAL.questionsCategoryWiseURL + itemId)
			.then((questionsCategoryWise)=>{
				if(!questionsCategoryWise){
					throw Error(questionsCategoryWise.statusText);
				}
				return questionsCategoryWise;
			})
			.then((questionsCategoryWise) =>{
				dispatch(getQuestionsCategoryWiseSuccess(questionsCategoryWise))
				dispatch({type:'SERVICE_REVIEW_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function getChangeQuestionsCategoryWise(itemId){
	return(dispatch) =>{
		dispatch({type:'SERVICE_REVIEW_LOADER_IN_PROGRESS'});
		api.get(GLOBAL.changeQuestionsCategoryWiseURL + itemId)
			.then((questionsCategoryWise)=>{
				console.log('questionsCategoryWise', questionsCategoryWise);
				if(!questionsCategoryWise){
					throw Error(questionsCategoryWise.statusText);
				}
				return questionsCategoryWise;
			})
			.then((questionsCategoryWise) =>{
				dispatch(getQuestionsCategoryWiseSuccess(questionsCategoryWise))
				dispatch({type:'SERVICE_REVIEW_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function getBreakfixQuestionsCategoryWise(itemId){
	return(dispatch) =>{
		dispatch({type:'SERVICE_REVIEW_LOADER_IN_PROGRESS'});
		api.get(GLOBAL.breakfixQuestionsCategoryWiseURL + itemId)
			.then((questionsCategoryWise)=>{
				console.log('questionsCategoryWise', questionsCategoryWise);
				if(!questionsCategoryWise){
					throw Error(questionsCategoryWise.statusText);
				}
				return questionsCategoryWise;
			})
			.then((questionsCategoryWise) =>{
				dispatch(getQuestionsCategoryWiseSuccess(questionsCategoryWise))
				dispatch({type:'SERVICE_REVIEW_LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function getQuestionsCategoryWiseSuccess(questionsCategoryWise){
	return {
		type: 'LOAD_QUESTIONS_CATEORY_WISE_SUCCESS',
		questionsCategoryWise
	};
}

export function resetMRQVFilters(){
	return{
		type: 'RESET_MR_QV_FILTERS',
		payload: null
	};
}

export function resetSortingArrows(){
	return(dispatch) =>{
		dispatch ({
			type: 'RESET_SORTING_ARROWS',
			reset: true
		});
	};
}

export function sortingArrowsChanged(){
	return(dispatch) =>{
		dispatch ({
			type: 'SORTING_ARROWS_CHANGED',
			reset: false
		});
	};
}