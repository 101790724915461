
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from "react";
import { Row, Col, Container, Modal, Button } from "react-bootstrap";
import { IoClose, IoSaveOutline } from "react-icons/io5";
import { PiPencilSimpleBold } from "react-icons/pi";
import { TbListSearch, TbWorldSearch } from "react-icons/tb";
import { useSelector } from "react-redux";
import "_Css/general/_shortcutKeys.scss";
import { MyContext } from '_MyContext';

const KeyboardShortcuts = (props) => {
  const tr = useSelector((state) => state.spcmReducer.tr);
  const context = useContext(MyContext);
  const closeButtonRef = useRef(null);

  useEffect(() => {
    if (context) {
      context?.keyDownHandler(closeButtonRef.current, "", "", "");
    }
  }, [context]);

  return (

    <Modal
      size="lg"
      show={(props.showModal || context.isOpen)}
      aria-labelledby="keyboard-shortcuts-modal"
      onHide={() => {
        if (context) {
          context?.modalOpen(false);
        } else if (props) {
          props?.showShortCuts(false);
        }
      }}
      centered
      className="skp_popup"
    >
      <Modal.Header>
        <Row className="w-100">
          <Col xs={10}>
            <h1 className="sPageHeading1 f-size-20" id="keyboard-shortcuts-modal">
              {tr["Keyboard Shortcuts"]}
            </h1>
          </Col>
          <Col xs={2} className="p-0">
            <div className="paHedFilter">
              <Button
                bsPrefix=" "
                role="button"
                ref={closeButtonRef}
                className="myBt cancel fillBtn skp_close"
                title={tr["Close"]}
                onClick={() => { 
                  if (context) {
                  context?.modalOpen(false);
                } else if (props) {
                  props?.showShortCuts(false);
                } }}
                >
                <IoClose />
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <div className="skp_keyDv">
          <div className="skp_keyRow">
            <p><TbWorldSearch /> {tr["Global Search"]}</p>
            <p><span>Ctrl</span><span className="skp_keyPlus">+</span><span>B</span></p>
          </div>
          <div className="skp_keyRow">
            <p><PiPencilSimpleBold /> {tr["Edit"]}</p>
            <p><span>Ctrl</span><span className="skp_keyPlus">+</span><span>I</span></p>
          </div>
          <div className="skp_keyRow">
            <p><IoSaveOutline /> {tr["Save"]}</p>
            <p><span>Ctrl</span><span className="skp_keyPlus">+</span><span>Q</span></p>
          </div>
          <div className="skp_keyRow">
            <p><IoClose /> {tr["Close"]}</p>
            <p><span>Ctrl</span><span className="skp_keyPlus">+</span><span>M</span></p>
          </div>
          <div className="skp_keyRow">
            <p><TbListSearch /> {tr["Menu Search"]}</p>
            <p><span>Ctrl</span><span className="skp_keyPlus">+</span><span>Y</span></p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="skp_footer">
          {tr["Press"]} <span>Ctrl</span><span className="skp_plus">+</span><span>\</span> {tr["to explore Shortcuts from anywhere."]}
        </div>
      </Modal.Footer>
    </Modal>

  );
};

export default KeyboardShortcuts;
