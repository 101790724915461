
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../initialState';

export const selectedFulfillmentItem = (state = initialState.selectedFulfillmentItem, action)=>{
  switch (action.type){
    case 'SET_SLCTD_FFMT_ITEM':
      return action.payload;
    default:
      return state;
  }
}

export const selectedFulfillmentItemComponent =(state = [], action)=> {
  // alert("Inside Componet")
  switch (action.type){
    case 'SET_SELECTED_COMPONENT':
      return action.payload;
    default:
      return state;
  }
}
export const fetchApprovalQuestionsDetails = (state = [], action) => {
  if (action.type === 'LOAD_APPROVAL_QUESTIONS_ANSWER') {
    return action.payload;
  } else {
    return state;
  }
};