/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import EditSmartForm from "./EditSmartForm";
import {
  useDeleteFormMutation,
  useGetAllFormsMutation,
  useUpdateFormMutation,
} from "../../../reducers/smartForm/smartFormApi";
import ListLoader from "../../common/loaders/ListLoader";
import { IoSearch } from "react-icons/io5";
import Swal from "sweetalert2";
import {HiOutlineTrash} from "react-icons/hi";
import {PiToggleRightFill, PiToggleLeftFill} from 'react-icons/pi';
import Pagination from "react-js-pagination";
import { getServiceDetailCounts } from "../../../actions/spcmActions";

const SmartCatList = (props) => {
  const [errorMsg, seterrorMsg] = useState("");
  const [getAllForms, { isLoading }] = useGetAllFormsMutation();
  const [listData, setListData] = useState([]);
  const [sortIcon, setSortIcon] = useState(false);
  const [sortUp, setSortUp] = useState("sort-up");
  const [sortDown, setSortDown] = useState("sort");
  const [searchText, setSearchText] = useState("");
  const [searchClicked, setSearchClicked] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [meta, setMeta] = useState({});
  const [page, setPage] = useState(1);

  const [updateForm, { isLoading: updateLoading }] = useUpdateFormMutation();
  const [deleteForm, { isLoading: deleteLoading }] = useDeleteFormMutation();
  const spcmReducer = useSelector((state) => state.spcmReducer);
  const tr = useSelector((state) => state.spcmReducer.tr);
  const dispatch = useDispatch();

  const onDeleteClick = (id) => {
    Swal.fire({
      title: tr["Are you sure you want to delete?"],
      showCancelButton: true,
      confirmButtonText: tr["Yes"],
      cancelButtonText: tr["No"],
    }).then((result) => {
      if (result.isConfirmed) {
        deleteForm({ formId: id, offeringId: spcmReducer.currentlySelectedServiceDetails.OFFERING_ID })
          .unwrap()
          .then((res) => {
            setSearchClicked(true);
            dispatch(getServiceDetailCounts(spcmReducer.currentlySelectedServiceDetails.OFFERING_ID));
          })
          .catch((err) => {
            Swal.fire({
              title: err?.data?.[0]?.message ?? "Something went wrong!",
            });
            seterrorMsg(err.message ?? "Something went wrong!");
          });
      }
    });
  };

  const updateFormStatus = (row, status) => {
    Swal.fire({
      title: tr["Are you sure want to update?"],
      showCancelButton: true,
      confirmButtonText: tr["Yes"],
      cancelButtonText: tr["No"],
    }).then((result) => {
      if (result.isConfirmed) {
        updateForm({
          form_id: row.FORM_ID,
          form_name: row.FORM_NAME,
          form_description: row.FORM_DESCRIPTION,
          status,
          offering_id: spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
        })
          .unwrap()
          .then((res) => {
            setSearchClicked(true);
          })
          .catch((err) => {
            let formName = err?.data?.[0]?.message?.split("-")?.[1]?.split(",")?.[0]?.trim() ?? "";
            if (formName?.length > 0) {
              formName = formName.slice(1, formName.length - 1);
            }
            let errMsg = err?.data?.[0]?.message?.includes("Please disable them before proceeding") ? tr["Smart Form"] + ' - ' + formName + tr[" has been enabled for this offering. Please disable that before proceeding"] : err?.data?.[0]?.message
            Swal.fire({
              title: errMsg,
            });
            seterrorMsg(err.message ?? "Something went wrong!");
          });
      }
    });
  };

  const sortFun = () => {
    setSortIcon(!sortIcon);
    setSortUp(sortIcon ? "sort-up" : "sort");
    setSortDown("sort");
  };

  useEffect(() => {
    if (searchClicked) {
      getAllForms({
        offeringId: spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,
        formName: searchText,
        limit: pageSize,
        page: page,
      })
        .unwrap()
        .then((res) => {
          if (res && Array.isArray(res.data)) {
            setListData(res.data);
            setMeta(res.meta);
          } else {
            seterrorMsg(res.message ?? "Something went wrong!");
          }
        })
        .catch((err) => {
          console.log("Error", err);
          if (err?.data?.data?.[0]?.message === "No data found.") {
            setListData([]);
            setMeta(err?.data?.meta ?? {});
          } else {
            seterrorMsg(err.message ?? "Something went wrong!");
          }
        })
        .finally(() => {
          setSearchClicked(false);
        });
    }
  }, [searchClicked]);

  return (
    <div className="rBoxGap">
      {props.componentFormToggle3 ? (
        <EditSmartForm
          componentFormFun4={props.componentFormFun3}
          setSearchClicked={setSearchClicked}
        />
      ) : (
        <div>
          <Row className="">
            <Col lg={8} md={12} sm={8} xs={12}>
              <div className="form-inline rightSideFields  d-lg-flex d-md-block d-sm-flex d-block">
                <Form.Select className="myControl mySel mySelectWidth">
                  <option value="Name">{tr["Name"]}</option>
                </Form.Select>
                <div className="d-flex">
                  <Form.Control
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        setSearchClicked(true);
                      }
                    }}
                    className="myControl myInput margin-l-5 myInputWidth"
                    type="text"
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <Button
                    type="submit"
                    title="Search"
                    onClick={() => setSearchClicked(true)}
                    className="myBtn"
                  >
                    <IoSearch />
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={4} sm={4} xs={12} className="text-r rightSideFields">
              <Form.Label className="margin-r-5 display-inline-block widthAuto">
                {tr["Show"]}
              </Form.Label>
              <Form.Select
                className="padding-5 display-inline-block widthAuto"
                componentClass="select"
                onChange={(e) => {
                  setPageSize(e.target.value);
                  setPage(1);
                  setSearchClicked(true);
                }}
              >
                <option value="10" selected={pageSize == 10 ? true : false}>
                  10
                </option>
                <option value="25" selected={pageSize == 25 ? true : false}>
                  25
                </option>
                <option value="50" selected={pageSize == 50 ? true : false}>
                  50
                </option>
              </Form.Select>
            </Col>
          </Row>
          <div className="f-size-12">
            <Table
              bordered
              condensed
              hover
              responsive
              className="tableView sortImg tableWithOutPointer"
            >
              <thead>
                <tr>
                  <th width="30%">
                    {tr["Name"]}
                  </th>
                  <th width="10%" className="text-c">
                    {tr["Action"]}
                  </th>
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <td colSpan="2">
                    <ListLoader />
                  </td>
                </tbody>
              ) : (
                <tbody>
                  {listData.map((value, index) => {
                    return (
                      <tr>
                        <td>{value.FORM_NAME}</td>
                        <td>
                          <div className="text-c smartFormIcn">
                            {value.STATUS === "Active" ? (
                              <PiToggleRightFill
                                onClick={() =>
                                  updateFormStatus(value, "Inactive")
                                }
                                title={tr["Enabled"]}
                                className="cursorPoint smrtIcnOn"
                              />
                            ) : (
                              <PiToggleLeftFill
                                onClick={() =>
                                  updateFormStatus(value, "Active")
                                }
                                title={tr["Disabled"]}
                                className="cursorPoint smrtIcnOff"
                              />
                            )}
                            <HiOutlineTrash
                              onClick={() => {
                                onDeleteClick(value.FORM_ID);
                              }}
                              title={tr["Delete"]}
                              className="cursorPoint"
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  {listData.length == 0 ? (
                    <tr>
                      <td colSpan={4} className="text-c">
                        {tr["No Data Found"]}
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              )}
            </Table>
          </div>
          <div className="nBotPagina">
            <nav aria-label="Pagination" className="display-inline-block">
              <Pagination
                activePage={meta ? meta?.currentPage : 1}
                itemsCountPerPage={pageSize}
                totalItemsCount={meta ? meta.rowCount : 1}
                onChange={(e) => {
                  setPage(e);
                  setSearchClicked(true);
                }}
              />
            </nav>
          </div>
        </div>
      )}
    </div>
  );
};

export default SmartCatList;
