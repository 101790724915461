import { Dialog } from "@progress/kendo-react-dialogs";
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { camelToCapitalized } from "../uiBuilder/studio/utils";

const AssetTablePopup = ({ ...props }) => {
  const [visible, setVisible] = useState(false);
  const toggleDialog = async () => {
    setVisible(!visible);
  };

  const renderQuestion = () => {
    const answer = JSON.parse(props?.answer);
    const keys = Object.keys(answer[0]).filter((key) => key !== "index");
    return (
      <div className="table-responsive">
        <Table
          bordered
          condensed
          hover
          responsive
          className="tableView sortImg"
        >
          <thead>
            <tr>
              {keys.map((key) => (
                <th key={key}>{camelToCapitalized(key)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {answer.map((row, index) => (
              <tr key={index}>
                {keys.map((key) => (
                  <td key={key}>{row[key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <>
      {visible && (
        <Dialog
          title={props?.question}
          onClose={toggleDialog}
          height={"75vh"}
          width={"60%"}
        >
          {renderQuestion()}
        </Dialog>
      )}

      <Link
        href={void 0}
        to={void 0}
        onClick={() => setVisible(true)}
        className="font-size-13"
      >
        <i className="fa fa-eye" aria-hidden="true"></i>
      </Link>
    </>
  );
};

export default AssetTablePopup;
