/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
export const ThreeDotComponent = {
  name: "ThreeDotComponent",
  traits: [
    {
      type: "text",
      label: "Component you want to render",
      name: "renderComponent",
      placeholder: "",
      changeProp: 1,
    }
  ],
};

export const ThreeDotComponentAdd = {
  model: {
    defaults: {
      tagName: "div",
      draggable: true,
      droppable: true,
      attributes: { class: "" },
      components:[{
        tagName: "span",
        attributes: { class: "" },
        content: "Three Dot Section",
        draggable: true,
        droppable: true,
        editable: true,
      }],
      traits: ThreeDotComponent.traits,
      editable: true,
    },
    init() {},
  },
};

export const addThreeDotComponent = (e) => {
  e.DomComponents.addType(ThreeDotComponent.name, ThreeDotComponentAdd);
};

export const ThreeDotComponentBlockJson = {
  id: "ThreeDotComponent",
  label: "Three Dots",
  media: `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#000000" class="bi bi-three-dots-vertical"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path> </g></svg>`,
  category: "Extra",
  content: {
    type: ThreeDotComponent.name,
  },
};
