/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { IoClose, IoSearch } from "react-icons/io5";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { generateFilterOptions } from "../../uiBuilder/studio/utils";
import useDataQueryWithApi from "../../uiBuilder/hooks/useDataQueryWithApi";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { usePreviousValue } from "../../../helpers/usePreviousValue";
import ReactDOM from "react-dom";
import { getCookiesValuesByPositions } from "../../common/helper";

const FilterInputField = (props) => {
  const { field, applyFilter, setFilterValues, filterValues, tableProperties } =
    props;
  const handleChange = (event) => {
    setFilterValues({
      ...filterValues,
      [field?.bindingKey]: event?.target?.value,
      [tableProperties?.currPageKey ?? "page"]: 0,
    });
  };
  return (
    <div className="colSearDv">
      <Form.Control
        className="colSearInp"
        type="text"
        value={
          filterValues[field?.bindingKey] &&
          filterValues[field?.bindingKey] != ""
            ? filterValues[field?.bindingKey]
            : ""
        }
        placeholder={"Search here"}
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            if (
              filterValues[field?.bindingKey] != "" &&
              filterValues[field?.bindingKey]?.trim().length > 2
            )
              applyFilter();
          }
        }}
        onChange={(event) => {
          handleChange(event);
        }}
      />
      {filterValues[field?.bindingKey]?.trim().length > 2 ? (
        <a
          title="search"
          className="faicn"
          href={void 0}
          onClick={() => applyFilter()}
        >
          <IoSearch />
        </a>
      ) : (
        ""
      )}
    </div>
  );
};

export const FilterDropDownField = (props) => {
  const { field, applyFilter, setFilterValues, filterValues, tableProperties } =
    props;
  const [options, setOptions] = useState([]);

  const [responseData, setDataSourceId] = useDataQueryWithApi({}, field);

  useEffect(() => {
    if (responseData) {
      const generatedOptions = generateFilterOptions(
        responseData,
        field?.labelKey?.id,
        field?.valueKey?.id
      );
      setOptions(generatedOptions);
    } else {
      setOptions([]);
    }
  }, [responseData]);

  useEffect(() => {
    if (field?.optionsType && field?.optionsType?.id == "staticOption") {
      const fieldOptions = field?.options.split(",");
      let generatedOptions = [];
      fieldOptions.forEach((option) => {
        const splittedOption = option.split("::");
        if (splittedOption[0] && splittedOption[1]) {
          generatedOptions.push({
            id: splittedOption[0],
            label: splittedOption[1],
          });
        }
      });
      setOptions(generatedOptions);
    } else if (
      field?.optionsType &&
      field?.optionsType?.id == "dataSourceOptions"
    ) {
      if (field.data_source) {
        setDataSourceId(field.data_source.id);
      }
    }
  }, []);
  const handleChange = (event) => {
    setFilterValues({
      ...filterValues,
      [field?.bindingKey]: event?.target?.value,
      [tableProperties?.currPageKey ?? "page"]: 0,
    });
    applyFilter({
      ...filterValues,
      [field?.bindingKey]: event?.target?.value,
      [tableProperties?.currPageKey ?? "page"]: 0,
    });
  };

  return (
    <div className="colSearDv">
      <Form.Select
        className="colSearInp"
        name={field?.bindingKey}
        onChange={handleChange}
        value={filterValues[field?.bindingKey] || ""}
      >
        <option value="">Select</option>
        {options &&
          options.map((option, index) => (
            <option key={field?.bindingKey + "-" + index} value={option.id}>
              {option.label}
            </option>
          ))}
      </Form.Select>
    </div>
  );
};

export const MultiSelectCheckbox = (props) => {
  const {
    field,
    applyFilter,
    setFilterValues,
    filterValues,
    tableProperties,
    mutliSelectFilters,
    setMutliSelectFilters,
  } = props;
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  const multiSelectFilterRef = useRef(null);
  const previousValues = usePreviousValue(props);

  const [responseData, loading, setDataSourceId, dataQUeryError] =
    useDataQueryWithApi({}, field);

  useEffect(() => {
    if (responseData) {
      const generatedOptions = generateFilterOptions(
        responseData,
        field?.labelKey?.id,
        field?.valueKey?.id,
        "label",
        "value"
      );
      setOptions(generatedOptions);
    } else {
      setOptions([]);
    }
  }, [responseData]);

  useEffect(() => {
    if (filterValues[field?.bindingKey]) {
      let appliedFilter = filterValues[field?.bindingKey]?.split(",") || [];
      let selectedOptions = [];
      options.map((option) => {
        if (appliedFilter.includes(option?.value)) {
          selectedOptions.push(option);
        }
      });
      setSelected(selectedOptions);
    } else {
      setSelected([]);
    }
  }, [filterValues[field?.bindingKey], options]);

  useEffect(() => {
    if (field?.optionsType && field?.optionsType?.id == "staticOption") {
      const fieldOptions = field?.options.split(",");
      let generatedOptions = [];
      fieldOptions.forEach((option) => {
        const splittedOption = option.split("::");
        if (splittedOption[0] && splittedOption[1]) {
          generatedOptions.push({
            value: splittedOption[0],
            label: splittedOption[1],
          });
        }
      });
      setOptions(generatedOptions);
    } else if (
      field?.optionsType &&
      field?.optionsType?.id == "dataSourceOptions"
    ) {
      if (field.data_source) {
        setDataSourceId(field.data_source.id);
      }
    }
    setMutliSelectFilters({
      ...mutliSelectFilters,
      [field?.bindingKey]: false,
    });
  }, []);

  useEffect(() => {
    if (multiSelectFilterRef) {
      multiSelectFilterRef.current.childNodes[0].className = "multiSelectRef";
      if (
        previousValues?.mutliSelectFilters?.[field.bindingKey] !==
        props.mutliSelectFilters?.[field.bindingKey]
      ) {
        let obj = ReactDOM.findDOMNode(multiSelectFilterRef.current)
          .childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(
            multiSelectFilterRef.current
          ).childNodes[0].childNodes[1].className = "multiSelectPopup";
          ReactDOM.findDOMNode(
            multiSelectFilterRef.current
          ).childNodes[0].childNodes[2].style = 'display:none';
        }
      }
    }
  }, [mutliSelectFilters, multiSelectFilterRef.current]);

  const setSelectAllFun = (value) => {
    if (value === "Select All") {
      setSelected(options);
    } else {
      setSelected([]);
    }
  };

  const onOkFun = () => {
    let optionsStr = selected.map((option) => option?.value).join(",");
    setFilterValues({
      ...filterValues,
      [field?.bindingKey]: optionsStr,
      [tableProperties?.currPageKey ?? "page"]: 0,
    });
    applyFilter({
      ...filterValues,
      [field?.bindingKey]: optionsStr,
      [tableProperties?.currPageKey ?? "page"]: 0,
    });
  };

  const onCancelClick = () => {
    if (filterValues[field?.bindingKey]) {
      let appliedFilter = filterValues[field?.bindingKey]?.split(",") || [];
      let selectedOptions = [];
      options.map((option) => {
        if (appliedFilter.includes(option?.[field?.valueKey?.id ?? "value"])) {
          selectedOptions.push(option);
        }
      });
      setSelected(selectedOptions);
    }
    setMutliSelectFilters({ ...mutliSelectFilters, [field.bindingKey]: false });
  };

  const showCheckBoxOptions = (value, event) => {
    setSelected(value);
  };

  let Control = ({ children, props }) => {
    return (
      <components {...props} className="customReactSelectMenu">
        <div className="btnhed">
          <div>
            <a
              title={
                Array.isArray(selected) && selected.length === options.length
                  ? "Unselect All"
                  : "Select All"
              }
              role="button"
              onMouseDown={() => {
                setSelectAllFun(
                  Array.isArray(selected) && selected.length === options.length
                    ? "Unselect All"
                    : "Select All"
                );
              }}
              href="javascript:void(0)"
            >
              {Array.isArray(selected) && selected.length === options.length
                ? "Unselect All"
                : "Select All"}
            </a>
            <div className="okbtnmulti">
              <a
                title="OK"
                role="button"
                href="javascript:void(0)"
                onMouseDown={() => {
                  onOkFun();
                }}
              >
                OK
              </a>
              <a
                role="button"
                title="close"
                className="clos"
                href="javascript:void(0)"
                onMouseDown={() => {
                  onCancelClick();
                }}
              >
                <IoClose />
              </a>
            </div>
          </div>
        </div>
        {children}
      </components>
    );
  };

  return (
    <div
      className="colSearDv"
      ref={multiSelectFilterRef}
      onClick={() =>
        setMutliSelectFilters({
          ...mutliSelectFilters,
          [field.bindingKey]: true,
        })
      }
    >
      <ReactMultiSelectCheckboxes
        placeholderButtonLabel={"Select"}
        value={selected}
        rightAligned={false}
        options={options}
        menuIsOpen={mutliSelectFilters?.[field?.bindingKey]}
        onChange={(selected, event) => {
          if (event.action === "deselect-option") {
            return showCheckBoxOptions(selected.filter((o) => o.value !== "*"));
          } else if (event.action === "select-option") {
            return showCheckBoxOptions(selected.filter((o) => o.value !== "*"));
          } else if (
            Array.isArray(selected) &&
            selected.length === options.length - 1
          ) {
            return showCheckBoxOptions(options);
          } else {
            return showCheckBoxOptions(selected);
          }
        }}
        components={{ Control }}
      />
    </div>
  );
};

export const SingleSelectCheckbox = (props) => {
  const { field, applyFilter, setFilterValues, filterValues } = props;
  const handleChange = (event, key) => {
    setFilterValues({
      ...filterValues,
      [field?.bindingKey]: event?.target?.value,
    });
  };
  return (
    <div className="colSearDv">
      <Form.Control
        className="colSearInp"
        type="text"
        value={
          filterValues[field?.bindingKey] != ""
            ? filterValues[field?.bindingKey]
            : ""
        }
        placeholder={"Search here"}
        onKeyPress={(e) => {
          if (e.charCode == "13") {
            if (
              filterValues[field?.bindingKey] != "" &&
              filterValues[field?.bindingKey]?.trim().length > 3
            )
              applyFilter();
          }
        }}
        onChange={(event) => {
          handleChange(event, field?.bindingKey);
        }}
      />
      {filterValues[field?.bindingKey]?.trim().length > 3 ? (
        <a
          title="search"
          className="faicn"
          href={void 0}
          onClick={(event) => applyFilter()}
        >
          <IoSearch />
        </a>
      ) : (
        ""
      )}
    </div>
  );
};

export const DateSelector = (props) => {
  const { field, applyFilter, setFilterValues, filterValues, tableProperties } =
    props;
  const [inputValue, setInputValue] = useState("");
  const { value1: dateFormat } = getCookiesValuesByPositions([2]);

  useEffect(() => {
    if (filterValues[field?.bindingKey]) {
      setInputValue(
        moment(filterValues[field?.bindingKey]?.startDate).format(
          dateFormat.slice(0, 10).toUpperCase()
        ) +
          " - " +
          moment(filterValues[field?.bindingKey]?.endDate).format(
            dateFormat.slice(0, 10).toUpperCase()
          )
      );
    } else {
      setInputValue("");
    }
  }, [filterValues[field?.bindingKey]]);

  const handleApply = (event, picker) => {
    const dateRange = `${picker.startDate.format(
      "YYYY-MM-DD"
    )},${picker.endDate.format("YYYY-MM-DD")}`;

    setFilterValues({
      ...filterValues,
      [field?.bindingKey]: {
        startDate: picker.startDate.format("YYYY-MM-DD"),
        endDate: picker.endDate.format("YYYY-MM-DD"),
        dateRange,
        [tableProperties?.currPageKey ?? "page"]: 0,
      },
    });
    applyFilter({
      ...filterValues,
      [field?.bindingKey]: dateRange,
      [tableProperties?.currPageKey ?? "page"]: 0,
    });
  };

  const handleCancel = () => {
    let filters = filterValues;
    delete filterValues[field?.bindingKey];
    setFilterValues(filters);
    setInputValue(undefined);
  };

  const handleCallback = (start, end) => {
    setInputValue(
      start.format(dateFormat.slice(0, 10).toUpperCase()) +
        " - " +
        end.format(dateFormat.slice(0, 10).toUpperCase())
    );
  };

  return (
    <div className="colSearDv">
      <DateRangePicker
        key={filterValues[field?.bindingKey] || Math.random()}
        initialSettings={{
          startDate: moment(filterValues[field?.bindingKey]?.startDate),
          endDate: moment(filterValues[field?.bindingKey]?.endDate),
          maxDate: moment(),
          autoApply: true,
          autoUpdateInput: false,
          locale: {
            cancelLabel: "Clear",
            applyLabel: "Apply",
          },
          parentEl: "#skipdv",
          opens: "left",
        }}
        onApply={handleApply}
        onCallback={(start, end) => handleCallback(start, end)}
        onCancel={handleCancel}
      >
        <input
          type="text"
          value={inputValue}
          readOnly
          placeholder="Select Date Range"
          className="form-control"
        />
      </DateRangePicker>
    </div>
  );
};

export const GetFilterField = (props) => {
  const { field, applyFilter, setFilterValues, filterValues, tableProperties } =
    props;
  const getFields = () => {
    if (field?.fitlerFieldType?.id == "normalInput") {
      return (
        <FilterInputField
          field={field}
          applyFilter={applyFilter}
          setFilterValues={setFilterValues}
          filterValues={filterValues}
          tableProperties={tableProperties}
        />
      );
    } else if (field?.fitlerFieldType?.id == "dropdownField") {
      return (
        <FilterDropDownField
          field={field}
          applyFilter={applyFilter}
          setFilterValues={setFilterValues}
          filterValues={filterValues}
          tableProperties={tableProperties}
        />
      );
    } else if (field?.fitlerFieldType?.id == "multiSelectCheckbox") {
      return (
        <MultiSelectCheckbox
          field={field}
          applyFilter={applyFilter}
          setFilterValues={setFilterValues}
          filterValues={filterValues}
          tableProperties={tableProperties}
          setMutliSelectFilters={props?.setMutliSelectFilters}
          mutliSelectFilters={props?.mutliSelectFilters}
        />
      );
    } else if (field?.fitlerFieldType?.id == "singleSelectCheckbox") {
      return (
        <SingleSelectCheckbox
          field={field}
          applyFilter={applyFilter}
          setFilterValues={setFilterValues}
          filterValues={filterValues}
        />
      );
    } else if (field?.fitlerFieldType?.id == "dateSelector") {
      return (
        <DateSelector
          field={field}
          applyFilter={applyFilter}
          setFilterValues={setFilterValues}
          filterValues={filterValues}
          tableProperties={tableProperties}
        />
      );
    }
  };

  return getFields();
};
