
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { loadBreakFixRelatedWorkDetails, addToRelatedWorkItmList, removeFromRelatedWorkItmList, resetRelatedWorkItmList, addAllToRelatedWorkItmList, clearBreakFixRelatedWorkDetails } from '../../../actions/breakFix/breakFixRelatedWorkAction';
import { loadBreakFixRelatedDataDetails } from '../../../actions/breakFix/breakFixRelatedDataAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GLOBAL } from "_Globals";
import axios from 'axios';
import swal from 'sweetalert';
import { setWipSearchApply } from '../../../actions/globalSearch/globalSearchAction';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
import Pagination from "react-js-pagination";
import { HiPlus } from "react-icons/hi";
import { PiListBulletsBold } from 'react-icons/pi';

const MoreArrow = <i title="More" className="fa fa-angle-double-down f-size-15" ></i>;

class PopoverWorkItemsData extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showUserData: false,
			messagedisplay: true,
			toggleFormAndList: 'list',
			checkRelationVal: 'p2c',
			relationLabel: 'Is a Parent of',
			masterCheck: false,
			moduleVal: "Breakfix",
			disablePostButton: false,
			textEntered: '',
			breakFixActualItemId:'',
			pageSize: 10,
			pageNumber: 0,
			hideLoader: false
		};
		GLOBAL.relatedfilterval = "";
		GLOBAL.checkmoduleval = "Breakfix";
		this.toggleFormAndList = this.toggleFormAndList.bind(this);
		this.breakFixItemIdActual=this.breakFixItemIdActual.bind(this);
		this.setSearchApplyFalse = this.setSearchApplyFalse.bind(this);
		this.create_Activity_Log = this.create_Activity_Log.bind(this);
		this.create_Bulk_Activity_Log = this.create_Bulk_Activity_Log.bind(this);
		this.onPageSizeChange = this.onPageSizeChange.bind(this);
		this.onPageChange = this.onPageChange.bind(this);
		this.parametrizedData = this.parametrizedData.bind(this);
		this.loadDataToRelate = this.loadDataToRelate.bind(this);
	}

	componentWillMount() {
		let breakFixActualItemId="";
		
		if(this.props.breakFixItemId.includes(":"))
		{
		let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") : "";
		let BRKItemId = getBreakFixItemId[0];
		 breakFixActualItemId = getBreakFixItemId[1];
		}
		else
		{
			breakFixActualItemId = this.props.breakFixItemId || "";
		}
		// let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") : "";
		// let BRKItemId = getBreakFixItemId[0];
		// let breakFixActualItemId = getBreakFixItemId[1];
		this.props.loadBreakFixRelatedWorkDetails("breakfixNumber", "-1", "Breakfix", "Y", breakFixActualItemId, "-20,10,15,20",'-1');
		this.props.loadBreakFixRelatedDataDetails(breakFixActualItemId);
		GLOBAL.relatedtextdisplay = <div className="f-size-18 margin-b-5">{this.props.translator['Related Work Items']}</div>
		this.setState({ messagedisplay: true });
	}
	componentWillUnmount() {
		this.props.breakFixRelatedWorkDetails.length = 0;
		this.renderRelatedWorkLog(this.props.breakFixRelatedWorkDetails);

	}
	breakFixItemIdActual()
	{
		// let breakFixActualItemId="";
		
		if(this.props.breakFixItemId.includes(":"))
		{
		let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") : "";
		let BRKItemId = getBreakFixItemId[0];
		this.setState({
			breakFixActualItemId:this.props.breakFixItemId || "" //getBreakFixItemId[1]
		})
		
		}
		else
		{
			this.setState({
				breakFixActualItemId:this.props.breakFixItemId
			})
			
		}
	}
	toggleFormAndList() {
		this.props.resetRelatedWorkItmList();
		GLOBAL.relatedfiltertext = "";
		this.setState({ masterCheck: false });
		if (this.state.toggleFormAndList == 'list')
			this.setState({ toggleFormAndList: 'form',textEntered: '' });
		else
			this.setState({ toggleFormAndList: 'list',textEntered: '' });
		this.setState({moduleVal: 'Breakfix', pageSize: 10, pageNumber: 0, checkRelationVal: 'p2c', relationLabel: 'Is a Parent of', hideLoader: false});
		this.props.clearBreakFixRelatedWorkDetails();
	}

	postRelatedData(brkid, modnumber, modulesearchparam) {
		//alert(brkid)
		let ref = this;
		this.breakFixItemIdActual();
		// let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") : ""
		// let BRKItemId = getBreakFixItemId[0];
		// let breakFixActualItemId = getBreakFixItemId[1];
		this.setState({ disablePostButton: true });
		let relationType = this.state.moduleVal == "Breakfix" ? this.state.checkRelationVal : "p2p";

		axios.post(GLOBAL.breakFixRelatedLogPostUrl, {
			"itemId": this.state.breakFixActualItemId,
			"status": "A",
			"createdOn": "",
			//"createdBy": user_id,
			//"createdByName": userDetails.full_name,
			"createdBy": "",
			"createdByName": ref.props.full_name,
			"modifiedOn": "",
			"modifiedBy": 0,
			"module": "Breakfix",
			"workItemId": brkid,
			"workModule": modulesearchparam,
			"workItemNumber": modnumber,
			"relationType": relationType
		}).
			then((response) => {
			
				if (response.data == "Parent Already Exists.") {
					swal({
						text: ref.props.translator['Parent Already Exists.'],
						button: ref.props.translator['OK'],
					});
					this.setState({ disablePostButton: false });
					return false;
				} else {
					this.breakFixItemIdActual();
					this.setState({ pageNumber: 0, pageSize: 10, hideLoader: false});
					this.create_Activity_Log(this.props.breakFixEditDetails.breakfixId,this.props.breakFixEditDetails.breakfixNumber,this.state.relationLabel,modnumber,'Relate');
					// let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") : "";
					// let breakFixActualItemId = getBreakFixItemId[1];
					// console.log("breakFixActualItemId 1", breakFixActualItemId)
					this.props.loadBreakFixRelatedWorkDetails("breakfixNumber", "-1", "Breakfix", "Y", this.state.breakFixActualItemId, "-20,10,15,20",'-1');
					GLOBAL.relatedtextdisplay = <div className="f-size-18 margin-b-5">{this.props.translator['Related Work Items']}</div>
					this.props.loadBreakFixRelatedDataDetails(this.state.breakFixActualItemId);
					this.props.resetRelatedWorkItmList();
					this.setState({ masterCheck: false });
					this.setState({ messagedisplay: true });
					this.setState({ toggleFormAndList: 'list' });
					this.setState({ disablePostButton: false });
					this.setState({checkRelationVal: 'p2c',relationLabel: 'Is a Parent of',moduleVal: 'Breakfix'});
					this.props.clearBreakFixRelatedWorkDetails();
				}
			});

	}

	postMasterRelatedData() {
		let ref = this;
		let bfCheckList = this.props.bfCheckList;
		let relationType = this.state.moduleVal == "Breakfix" ? this.state.checkRelationVal : "p2p";
		// let breakFixActualItemId="";
		
		this.breakFixItemIdActual();
		// let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") : "";

		this.setState({ disablePostButton: true });
		if (bfCheckList.length == 0) {
			swal({
				text: ref.props.translator['Please select fields'],
				button: ref.props.translator['OK'],
			});
			this.setState({ disablePostButton: false });
			return false;
		}

		let relatedArr = [];
		bfCheckList.forEach(obj => {
			relatedArr.push({
				"itemId": this.state.breakFixActualItemId,
				"module": "Breakfix",
				"workItemId": obj.bfId,
				"workItemNumber": obj.bfNum,
				"workModule": "Breakfix",
				"createdOn": "",
				"createdBy": "",
				"createdByName": ref.props.full_name,
				"modifiedOn": "",
				"modifiedBy": "",
				"modifiedByName": "",
				"relationType": relationType
			});
		});

		axios.post(GLOBAL.breakFixRelateAllWorkItem, relatedArr)
			.then((response) => {
				this.setState({ pageNumber: 0, pageSize: 10, hideLoader: false});
				this.create_Bulk_Activity_Log(this.props.breakFixEditDetails.breakfixNumber,this.state.moduleVal==="Breakfix"?this.state.relationLabel:"Is a Peer of",this.props.breakFixEditDetails.breakfixId);
				this.breakFixItemIdActual();
				// let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") : "";
				console.log("this.state.breakFixActualItemId",this.state.breakFixActualItemId);
				this.props.loadBreakFixRelatedWorkDetails("breakfixNumber", "-1", "Breakfix", "Y", this.state.breakFixActualItemId, "-20,10,15,20",'-1');
				GLOBAL.relatedtextdisplay = <div className="f-size-18 margin-b-5">{this.props.translator['Related Work Items']}</div>
				this.props.loadBreakFixRelatedDataDetails(this.state.breakFixActualItemId);
				this.props.resetRelatedWorkItmList();
				this.setState({ masterCheck: false });
				this.setState({ messagedisplay: true });
				this.setState({ toggleFormAndList: 'list' });
				this.setState({ disablePostButton: false });
				this.setState({checkRelationVal: 'p2c',relationLabel: 'Is a Parent of',moduleVal: 'Breakfix'});
				this.props.clearBreakFixRelatedWorkDetails();

			}).catch((err) => {
				console.log(err);
				ref.props.resetRelatedWorkItmList();
				ref.setState({ masterCheck: false });
				this.setState({ disablePostButton: false });
			});;
	}

	deleteRelation(relationId,workItemNumber) {
		let breakFixActualItemId="";
		
		this.breakFixItemIdActual();
		// let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") : "";
		// let BRKItemId = getBreakFixItemId[0];
		// let breakFixActualItemId = getBreakFixItemId[1];
		axios.delete(GLOBAL.relatedLogDeleteUrl + relationId)
			.then((response) => {
				if (!response || response.status != '200') {
					alert('Deletion failed.');
					// swal({
					// 	text: 'Deletion failed.',
					// 	button: this.props.translator['OK'],
					// });
				}
				else {
					// alert('deleted relation');
                    this.create_Activity_Log(this.props.breakFixEditDetails.breakfixId,'','',workItemNumber,'Delete');
					swal({
						text: this.props.translator["Deleted relation"],
						button: this.props.translator["OK"],
					  });
					console.log(response);
					this.breakFixItemIdActual();
					this.props.loadBreakFixRelatedWorkDetails("breakfixNumber", "-1", "Breakfix", "Y", this.state.breakFixActualItemId, "-20,10,15,20",'-1');
					GLOBAL.relatedtextdisplay = <div className="f-size-18 margin-b-5">{this.props.translator['Related Work Items']}</div>
					this.props.loadBreakFixRelatedDataDetails(this.state.breakFixActualItemId);
					this.setState({ messagedisplay: true });
				}
			});
	}

	renderRelatedWorkLog(relatedLogData) {
		//	alert(relatedLogData.length)
		// if (!(relatedLogData && Array.isArray(relatedLogData) && relatedLogData.length)) {
		// 	return (
		// 		<div></div>
		// 	);
		// }
		if (!(relatedLogData && relatedLogData.data && Array.isArray(relatedLogData.data) && relatedLogData.data.length)) {
			return (
				<tr>
					<td width="100%">
						<div>{this.props.translator["No Data Available..."]}</div>
					</td>
				</tr>
			);
		}

		else {

			if (GLOBAL.checkmoduleval == "Breakfix") {
				return (
					<>
					<Row className='padding-t-10'>
					<Col lg={8} md={12} sm={8} xs={12}>
					    <Form.Label className="margin-r-5 display-inline-block widthAuto">{this.props.translator['Show']}</Form.Label>
						<Form.Select className="display-inline-block widthAuto" onChange={this.onPageSizeChange}>
							<option value="10" selected={this.state.pageSize == 10 ? true : false}>10</option>
							<option value="25" selected={this.state.pageSize == 25 ? true : false}>25</option>
							<option value="50" selected={this.state.pageSize == 50 ? true : false}>50</option>
						  </Form.Select>
					</Col>
					</Row>
					<Table responsive striped bordered condensed hover className='f-size-13'>
						<thead>
							<tr className='f-size-12'>
								{this.state.checkRelationVal == "p2c" || this.state.checkRelationVal == "p2p" ?
									<th width="5%">	<Form.Check type="checkbox" label="" 
									// checked={this.state.masterCheck}
									defaultChecked={this.state.masterCheck}
									onClick={(e) => this.handleAllCheckboxClick(e)} 
									></Form.Check></th> : ""}
								<th width="30%">{this.props.translator['Work Item Number']}</th>
								<th width="35%">{this.props.translator['Created On']}</th>
								<th width="20%">{this.props.translator['Consumer']}</th>
								{this.props.fieldStatus.relateWorkItems == true && !this.props.disableFieldsForGuestRole ? <th width="5%" className="text-c">{this.props.translator['Action']}</th> : null}
							</tr>
						</thead>
						<tbody>
							{this.renderSearchList(relatedLogData.data)}
							{this.state.checkRelationVal == "p2c" || this.state.checkRelationVal == "p2p" ?
								<tr>
									<td colSpan="5"><a className="relatedWorkSubmitBtn" onClick={this.state.disablePostButton ? ((event) => event.preventDefault()) : (() => this.postMasterRelatedData())} postRelatedData title={this.props.translator['Relate']}><i className="fa fa-chevron-circle-right f-size-16 theme1"></i></a></td>
								</tr>
								: ""
							}
						</tbody>
					</Table>
					<Row>
					<div className="nBotPagina">
                        <nav aria-label="Pagination" className="display-inline-block">
                          {relatedLogData.meta ? (
                            <Pagination activePage={relatedLogData.meta ? this.state.pageNumber+1 : 0}
                            itemsCountPerPage={this.state.pageSize}
                            totalItemsCount={relatedLogData.meta ? relatedLogData.meta.recordCount : 0}
                            onChange={this.onPageChange}/>) : null}
                        </nav>
                    </div>
					</Row>
					</>
				);
			} else {

				// return (
				// 	<Table responsive striped bordered condensed hover className='f-size-13'>
				// 		<tbody>
				// 			{this.renderSearchListOtherModules(relatedLogData.data)}
				// 		</tbody>
				// 	</Table>
				// );
				return (
					<>
					<Row>
					<Col lg={8} md={12} sm={8} xs={12}>
					    <Form.Label className="margin-r-5 display-inline-block widthAuto">{this.props.translator['Show']}</Form.Label>
						<Form.Select className="display-inline-block widthAuto" onChange={this.onPageSizeChange}>
							<option value="10" selected={this.state.pageSize == 10 ? true : false}>10</option>
							<option value="25" selected={this.state.pageSize == 25 ? true : false}>25</option>
							<option value="50" selected={this.state.pageSize == 50 ? true : false}>50</option>
						  </Form.Select>
					</Col>
					</Row>
					<Table  responsive striped bordered condensed hover className='f-size-13'> 
					<thead>
						<tr>
						<th width="80%">{this.props.translator['Related Work Items']}</th>
						<th width="60%">{this.props.translator['Created On']}</th>
						<th width="60%">{this.props.translator['Created By']}</th>
						<th width="60%">{this.props.translator['Action']}</th>
						</tr>
					</thead>
					<tbody>
					   {relatedLogData.data.map((relatedObj, index) => {
					      let modnumber = "", modid = "", modRequester = "";
					      let modulesearchparam = GLOBAL.checkmoduleval;
					      if (modulesearchparam == "Breakfix") {
						       modnumber = relatedObj.breakfixNumber
						       modid = relatedObj.breakfixId
						       modRequester = relatedObj.impactedUsername
						  }
					      if (modulesearchparam == "Fullfilment") {
						    modnumber = relatedObj.itemNumber
						    modid = relatedObj.itemId
						    modRequester = relatedObj.requesterName
                          }
					      if (modulesearchparam == "Investigation") {
						    modnumber = relatedObj.investigationNumber
						    modid = relatedObj.investigationId
						    modRequester = relatedObj.impactedUsername
					      }
					      if (modulesearchparam == "ChangeManagement") {
						    modnumber = relatedObj.changeNumber
						    modid = relatedObj.changeId
						    modRequester = relatedObj.requesterName
					      }
					      if(!relatedObj.description){
						   relatedObj.description = "";
					      }      
					      return (<tr>
							        <td>{modnumber}</td>
									<td>{relatedObj.createdOn}</td>
									<td>{modRequester}</td>
									<td className="text-c f-size-13">
										    <a onClick={this.state.disablePostButton?((event=>event.preventDefault())):this.postRelatedData.bind(this, modid, modnumber, modulesearchparam)} title={this.props.translator['Relate']}>
												<i className="fa fa-chevron-circle-right f-size-16 theme1"></i>
											</a>
									</td>
									</tr>)
					   })}
					</tbody>
					</Table>
					<Row>
					<div className="nBotPagina">
                        <nav aria-label="Pagination" className="display-inline-block">
                          {relatedLogData.meta ? (
                            <Pagination activePage={relatedLogData.meta ? this.state.pageNumber+1 : 0}
                            itemsCountPerPage={this.state.pageSize}
                            totalItemsCount={relatedLogData.meta ? relatedLogData.meta.recordCount : 0}
                            onChange={this.onPageChange}/>) : null}
                        </nav>
                    </div>
					</Row>
					</>
				)

			}
		}
	}
   setSearchApplyFalse()
   {
	 this.props.setWipSearchApply(false);
   }
	renderAttList(attributeData) {
		//console.log(attributeData)
		if (!attributeData) {
			return (
				null
			);
		}
		return attributeData.map((objjectData) => {
			// console.log('this.props',this.props)
			let relationVal = '';
			if (objjectData.relationType == "p2c") {
				relationVal = 'Is a Parent of';
			} else if (objjectData.relationType == "c2p") {
				relationVal = 'Is a Child of';
			} else if (objjectData.relationType == "p2p") {
				relationVal = 'Is a Peer of';
			} else if (objjectData.relationType == "h2c") {
				relationVal = 'Caused By';
			} else if (objjectData.relationType == "c2h") {
				relationVal = 'Has Caused';
			} else {
				relationVal = '';
			}

			if (objjectData.itemId != null && objjectData.itemId != "") {
				// // if ((this.props && this.props.breakFixEditDetails && this.props.breakFixEditDetails.status && (this.props.breakFixEditDetails.status == "Fixed" || this.props.breakFixEditDetails.status == "Closed" || this.props.breakFixEditDetails.status == "Completed" || this.props.breakFixEditDetails.status == "Cancelled"))) {
				// // 	return (
				// // 		<tr>
				// // 			<td>{relationVal}</td>
				// // 			<td>{objjectData.workItemNumber}</td>
				// // 			{/* <td><div className="cursorPoint"><Link to={"/editBreakFix/breakFixItemId:"+objjectData.workItemId} target={"_blank"}>{objjectData.workItemNumber}</Link></div></td> */}
				// // 			<td>{objjectData.createdOn}</td>
				// // 			<td>{objjectData.createdByName}</td>
				// // 			<td className="text-c f-size-13"><i onClick={this.deleteRelation.bind(this, objjectData.relationId)} className="fa fa-trash-o cursorPoint"></i></td>
				// // 			<td></td>
				// // 		</tr>
				// // 	);
				// // }
				// else {

				if (objjectData.workModule === 'ChangeManagement') {
					//console.log("working...")
					return (
						<tr>
							<td>{relationVal}</td>
							{/* <td>{objjectData.workItemNumber}</td> */}
							<td>{this.props.isChangedRolesEnabled?<Link to={"/editChange/" + objjectData.workItemId} onClick={()=>this.setSearchApplyFalse()}>{objjectData.workItemNumber}</Link>:objjectData.workItemNumber}</td>
							<td>{objjectData.createdOn}</td>
							<td>{objjectData.createdByName}</td>
							{this.props.fieldStatus.relateWorkItems == true && !this.props.disableFieldsForGuestRole ? <td className="text-c f-size-13"><i onClick={this.deleteRelation.bind(this, objjectData.relationId, objjectData.workItemNumber)} className="fa fa-trash-o cursorPoint"></i></td> : null}
						</tr>
					);
				}
				else if (objjectData.workModule === 'Breakfix') {
					//console.log("working...")
					return (
						<tr>
							<td>{relationVal}</td>
							{/* <td>{objjectData.workItemNumber}</td> */}
							<td>{this.props.isIncidentRolesEnabled?<Link to={"/editBreakFix/" + objjectData.workItemId} onClick={()=>this.setSearchApplyFalse()}>{objjectData.workItemNumber}</Link>:objjectData.workItemNumber}</td>
							<td>{objjectData.createdOn}</td>
							<td>{objjectData.createdByName}</td>
							{this.props.fieldStatus.relateWorkItems == true && !this.props.disableFieldsForGuestRole ? <td className="text-c f-size-13"><i onClick={this.deleteRelation.bind(this, objjectData.relationId, objjectData.workItemNumber)} className="fa fa-trash-o cursorPoint"></i></td> : null}
						</tr>
					)
				}
				else if (objjectData.workModule === 'Investigation') {
					//console.log("working...")
					return (
						<tr>
							<td>{relationVal}</td>
							{/* <td>{objjectData.workItemNumber}</td> */}
							<td>{this.props.isProblemRolesEnabled?<Link to={"/investigationEdit/" + objjectData.workItemId} onClick={()=>this.setSearchApplyFalse()}>{objjectData.workItemNumber}</Link>:objjectData.workItemNumber}</td>
							<td>{objjectData.createdOn}</td>
							<td>{objjectData.createdByName}</td>
							{this.props.fieldStatus.relateWorkItems == true && !this.props.disableFieldsForGuestRole ? <td className="text-c f-size-13"><i onClick={this.deleteRelation.bind(this, objjectData.relationId, objjectData.workItemNumber)} className="fa fa-trash-o cursorPoint"></i></td> : null}
						</tr>
					)
				}
				else if (objjectData.workModule === 'Fullfilment') {
					//console.log("working...")
					return (
						<tr>
							<td>{relationVal}</td>
							{/* <td>{objjectData.workItemNumber}</td> */}
							<td>{this.props.isFulfillmentRolesEnabled?<Link to={"/fulFillment/" + objjectData.workItemId} onClick={()=>this.setSearchApplyFalse()}>{objjectData.workItemNumber}</Link>:objjectData.workItemNumber}</td>
							<td>{objjectData.createdOn}</td>
							<td>{objjectData.createdByName}</td>
							{this.props.fieldStatus.relateWorkItems == true && !this.props.disableFieldsForGuestRole ? <td className="text-c f-size-13"><i onClick={this.deleteRelation.bind(this, objjectData.relationId, objjectData.workItemNumber)} className="fa fa-trash-o cursorPoint"></i></td> : null}
						</tr>
					)
				}
			}
			// }

			else
				return (
					<tr>
					</tr>
				);
		});
	}

	renderRelatedDataLog(relatedDataLog) {
		// console.log('relatedDataLog');
		// console.log(relatedDataLog);
		if (relatedDataLog.length == 0) {
			return (
				<div>{this.props.translator['Data not available.']}</div>
			);
		}

		else {
			return (
				<Table responsive striped bordered condensed hover className='f-size-13'>
					<thead>
						<tr className='f-size-12'>
							<th>{this.props.translator['Relationship']}</th>
							<th>{this.props.translator['Work Item Number']}</th>
							<th>{this.props.translator['Created On']}</th>
							<th>{this.props.translator['Related By']}</th>
							{this.props.fieldStatus.relateWorkItems == true && !this.props.disableFieldsForGuestRole ? <th className="text-c">{this.props.translator['Action']}</th> : null}
						</tr>
					</thead>
					<tbody>
						{this.renderAttList(relatedDataLog)}
					</tbody>
				</Table>
			);
		}
	}

	handleCheckboxClick(e, bfId, bfNum) {
		// console.log("handleCheckboxClick ",bfId,bfNum);
		// console.log("checked :: ",e.target.checked);
		let itmObj = {}
		itmObj.bfId = bfId;
		itmObj.bfNum = bfNum;
		// return false;
		if (e.target.checked) {
			//alert(e.target.checked);
			this.props.addToRelatedWorkItmList(itmObj);
		} else {
			this.props.removeFromRelatedWorkItmList(itmObj);
		}
	}

	handleAllCheckboxClick(e) {
		let data = this.props.breakFixRelatedWorkDetails.data;
		// console.log('data - ', data)
		let allDetails = [];

		if (e.target.checked) {
			for (let i = 0; i < data.length; i++) {
				let itmObj = {}
				itmObj.bfId = data[i].breakfixId;
				itmObj.bfNum = data[i].breakfixNumber;
				allDetails.push(itmObj);
			}
		}
		// console.log('allDetails - ', allDetails);
		if (e.target.checked) {
			this.setState({ masterCheck: true });
			this.props.addAllToRelatedWorkItmList(allDetails);
		} else {
			this.setState({ masterCheck: false });
			this.props.resetRelatedWorkItmList();
		}
	}

	renderSearchList(attributeData) {
		// console.log('attributeData - ', attributeData);
		// console.log('this.props - ', this.props);
		let selectedItms = [];
		selectedItms = this.props.bfCheckList;

		let itmArr = [];
		selectedItms.map((itm) => {
			itmArr.push(itm.bfId);
		})

		if (!(attributeData && attributeData.length)) {
			return (
				null
			);
		}
		// console.log('itmArr - ', itmArr);

		return attributeData.map((objjectData, item) => {
			let modnumber = "", modid = "", modRequester = "";
			let modulesearchparam = GLOBAL.checkmoduleval;

			modnumber = objjectData.breakfixNumber
			modid = objjectData.breakfixId
			modRequester = objjectData.impactedUsername
			let ischecked = false;

			ischecked = itmArr.includes(modid);

			// {
			// 	selectedItms == [] || selectedItms == undefined
			// 		? "" :
			// 		ischecked = (() => { if (_.includes(itmArr, modid)) { return true } else { return false } })();
			// }

			return (
				<tr>
					{this.state.checkRelationVal == "p2c" || this.state.checkRelationVal == "p2p" ?
						<td><Form.Check type="checkbox" label="" 
						// checked={ischecked}
						checked={ischecked} 
						onChange={(e) => this.handleCheckboxClick(e, modid, modnumber)} 
						></Form.Check></td> : ""}
					<td>{modnumber}</td>
					{/* <td><div className="cursorPoint"><Link to={"/editBreakFix/breakFixItemId:"+objjectData.workItemId} target={"_blank"}>{objjectData.workItemNumber}</Link></div></td> */}
					<td>{objjectData.createdOn}</td>
					<td>{objjectData.impactedUsername}</td>
					<td className="text-c f-size-13"><a onClick={this.state.disablePostButton ? ((event) => event.preventDefault()) : (this.postRelatedData.bind(this, modid, modnumber, modulesearchparam))} title={this.props.translator['Relate']}><i className="fa fa-chevron-circle-right f-size-16 theme1"></i></a></td>
				</tr>
			);

		});
	}

	renderSearchListOtherModules(attributeData) {

		return attributeData.map((relatedObj, index) => {
			
				let modnumber = "", modid = "", modRequester = "";
				let modulesearchparam = GLOBAL.checkmoduleval;

				// console.log("modulesearchparam" + modulesearchparam)
				//console.log(relatedLogData)
				if (modulesearchparam == "Breakfix") {
					modnumber = relatedObj.breakfixNumber
					modid = relatedObj.breakfixId
					modRequester = relatedObj.impactedUsername

				}
				if (modulesearchparam == "Fullfilment") {
					modnumber = relatedObj.itemNumber
					modid = relatedObj.itemId
					modRequester = relatedObj.requesterName

				}
				if (modulesearchparam == "Investigation") {
					modnumber = relatedObj.investigationNumber
					modid = relatedObj.investigationId
					modRequester = relatedObj.impactedUsername

				}
				if (modulesearchparam == "ChangeManagement") {
					modnumber = relatedObj.changeNumber
					modid = relatedObj.changeId
					modRequester = relatedObj.requesterName
				}

				if(!relatedObj.description){
					relatedObj.description = "";
				}

			let desc = <span  title={relatedObj.description || "NO NAME"}>
				{ relatedObj.description && relatedObj.description.length<=25 ?relatedObj.description : relatedObj.description.substring(0, 25)+'...'}
			</span>;

			return (
				<tr>
					<td>
						{modnumber} -{relatedObj.createdOn}  - <span className="colorStOrange cursorPoint" onClick={this.showUserDetails}>{modRequester}</span>	
					</td>
					<td>{desc}</td>
					<td className="text-c f-size-13">
						<a onClick={this.state.disablePostButton?((event=>event.preventDefault())):this.postRelatedData.bind(this, modid, modnumber, modulesearchparam)} title={this.props.translator['Relate']}><i className="fa fa-chevron-circle-right f-size-24 colorStBlue"></i></a>
					</td>
				</tr>
			);

		});
	}

	// 	renderRelatedDataLog(relatedDataLog){
	// 		//alert(""+auditLogData)
	// 		if(!relatedDataLog){
	//  		return (
	//  				<tr>
	//  					<td width="100%">
	//  						<div>{this.props.translator['Data not available.']}</div>
	//  					</td>
	//  				</tr>
	//  			);
	//  		}
	// 	else{


	//   return relatedDataLog.map((relatedData,index)=>{


	//   return(
	// 		<Card>
	// 				  <Card.Body>
	// 					<Row>
	// 						<Col md={10} className="padding-r-0"><Card.Heading className="blue margin-0">{relatedData.workItemNumber} - {relatedData.createdOn}  - <span className="colorStOrange cursorPoint" onClick={this.showUserDetails}>Related By {relatedData.createdByName}</span></Card.Heading></Col>

	// 					</Row>
	// 				  </Card.Body>

	// 				</Card>
	//         );
	//       });

	// 	  }
	// 	}

	loaddata() {
        let breakFixActualItemId = "";
        this.props.clearBreakFixRelatedWorkDetails();
        this.breakFixItemIdActual();
        if (this.props.breakFixItemId.includes(":")) {
          let getBreakFixItemId = this.props.breakFixItemId
            ? this.props.breakFixItemId.split(":")
            : "";
          let BRKItemId = getBreakFixItemId[0];
          breakFixActualItemId = this.props.breakFixItemId || "";
        } else {
          breakFixActualItemId = this.props.breakFixItemId;
        }

		let modulesearch = GLOBAL.checkmoduleval;
		this.loadDataToRelate(modulesearch,breakFixActualItemId);
		GLOBAL.relatedtextdisplay = <div className="f-size-18 margin-b-5"></div>
		this.setState({ messagedisplay: false, pageNumber: 0, pageSize: 10, hideLoader: false });
		//this.props.loadBreakFixRelatedDataDetails("0");

	}
	loadrelateddata() {
		
		// let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") : ""
		// let BRKItemId = getBreakFixItemId[0];
		// let breakFixActualItemId = getBreakFixItemId[1];
		this.breakFixItemIdActual();
		this.props.loadBreakFixRelatedWorkDetails("breakfixNumber", "-1", "Breakfix", "Y", this.state.breakFixActualItemId, "-20,10,15,20");
		GLOBAL.relatedtextdisplay = <div className="f-size-18 margin-b-5">{this.props.translator['Related Work Items']}</div>
		this.props.loadBreakFixRelatedDataDetails(this.state.breakFixActualItemId);
		this.setState({ messagedisplay: true });


	}

	checkfilter(i) {
		GLOBAL.relatedfilterval = i.target.value;
		GLOBAL.relatedfiltertext = ""
		this.setState({ textEntered: '' })
	}
	filtertext(i) {
		//alert(i.target.value)
		GLOBAL.relatedfiltertext = i.target.value;
		this.setState({ textEntered: i.target.value })
	}
	checkmodule(i) {
		// alert(i.target.value)
		this.setState({ moduleVal: i.target.value, textEntered: '' });
		if(i.target.value==='Breakfix'){
			this.setState({checkRelationVal: 'p2c',relationLabel: 'Is a Parent of'});
		}else{
			this.setState({checkRelationVal: 'p2p',relationLabel: 'Is a Peer of'});
		}
		GLOBAL.checkmoduleval = i.target.value;
		GLOBAL.relatedfiltertext = "";
		this.setState({ pageNumber: 0, pageSize: 10, hideLoader: false});
		this.props.clearBreakFixRelatedWorkDetails();
	}

	checkRelation(val) {
		this.setState({ messagedisplay: false, pageNumber: 0, pageSize: 10, hideLoader: false});
		this.setState({ masterCheck: false, checkRelationVal: val.target.value, relationLabel: val.target[val.target.selectedIndex].text, textEntered: '' });
		this.props.resetRelatedWorkItmList();
		this.props.clearBreakFixRelatedWorkDetails();
		GLOBAL.relatedfiltertext = ""
	}

	// render(){
	// //console.log(this.state.messagedisplay);
	// 	return(
	// 		<div>
	// 		<div className="f-size-18 margin-b-5">{this.props.translator['Relate Work Item']}</div>
	// 		<Row>
	// 			<Col md={12}>
	// 			<Form.Group className="margin-b-5">
	// 			<Form.Label>{this.props.translator['Type']}</Form.Label>
	// 			<Form.Control componentClass="select" onChange={this.checkmodule.bind(this)}>
	// 			<option value="Select">Select</option>
	// 			<option value="Breakfix">Breakfix</option>
	// 			<option value="Fullfilment">Fullfilment</option>
	// 			<option value="Investigation">Investigation</option>
	// 			<option value="ChangeManagement">Change</option>
	// 			</Form.Control>
	// 			</Form.Group>
	// 			</Col>
	// 		</Row>
	// 		<div className="bgGray padding-10 tbox">
	// 			<Row>
	// 				<Col md={6} className="padding-r-0"><Form.Label>{this.props.translator['Search by']}</Form.Label>
	// 				<Form.Control  componentClass="select" onChange={this.checkfilter.bind(this)} >
	// 				<option value="Select">Select</option>
	// 				{/*<option value="Keyword">Keyword</option>*/}
	// 				<option value="breakfixNumber">Item ID</option>
	// 				<option value="impactedUserid">Same Requestor</option>
	// 				<option value="serviceId">Same Service</option>
	// 				</Form.Control></Col>
	// 				<Col md={6}><Form.Label>&nbsp;</Form.Label><Form.Control type="text" onChange={this.filtertext.bind(this)} placeholder={this.props.translator['Search']} /></Col>
	// 			</Row>
	// 			<div className="btnNew padding-5">{this.state.messagedisplay==false?<a onClick={this.loadrelateddata.bind(this)} className="backList"><i className="fa fa-angle-double-left"></i>{this.props.translator[' Back to List']}</a>:<a></a>}&nbsp;
	// 			<a onClick={this.loaddata.bind(this)} title={this.props.translator['Search']} className="srch"><i className="fa fa-search"></i></a></div>
	// 		{/*	<div className="btnNew padding-5">&nbsp;<a onClick={this.loadrelateddata.bind(this)} title="Back to list" className="srch"><i className="fa fa-search"></i></a></div>
	// 			<div className="btnNew padding-5">&nbsp;<a onClick={this.loaddata.bind(this)} title="Search" className="srch"><i className="fa fa-search"></i></a></div>*/}
	// 		</div>

	// 			{this.renderRelatedWorkLog(this.props.breakFixRelatedWorkDetails)}
	// 			{GLOBAL.relatedtextdisplay}
	// 			{this.renderRelatedDataLog(this.props.breakFixRelatedDataDetails)}



	// 		</div>
	// 	);
	// }
	create_Activity_Log(breakfixID,breakfixNumber,relationName,investigationNumber,relationType){
		let activityDetails ={
			"itemId": breakfixID,
			"description":relationType==='Delete'?`Unlinked a Related Work Item - ${investigationNumber}`:`Added a Related Work Item - ${breakfixNumber} ${relationName} ${investigationNumber}`,
			"isInternal":"Y",
			"createdOn":"",
			"createdBy":"",
			"module":"Breakfix",
			"createdByName":"",
			"sendNotification":"true",
			"isExternal":""
		};
		axios.post("/serviceManagement/rest/activity/", activityDetails);
	}

	create_Bulk_Activity_Log(breakfixNumber,relationName,breakfixID){
		let parentActions=[],childActions=[];
		this.props.bfCheckList.map((item)=>{
			parentActions.push({
				"itemId": breakfixID,
				"description":`Added a Related Work Item - ${breakfixNumber} ${relationName} ${item.bfNum}`,
				"isInternal":"Y",
				"createdOn":"",
				"createdBy": this.props.user_id,
				"module":"Breakfix",
				"createdByName":"",
				"sendNotification":"true",
				"isExternal":""
			});
			childActions.push({
				"itemId": item.bfId,
				"description":`Added a Related Work Item - ${breakfixNumber} ${relationName} ${item.bfNum}`,
				"isInternal":"Y",
				"createdOn":"",
				"createdBy": this.props.user_id,
				"module":"Breakfix",
				"createdByName":"",
				"sendNotification":"true",
				"isExternal":""
			});
		});
		axios.post('/bulkActivites',childActions);
		axios.post('/bulkActivites',parentActions);
	}

	onPageSizeChange(event){
      this.setState({pageSize: event.target.value, pageNumber: 0, hideLoader: true});
      this.parametrizedData(event.target.value,'0');
	}
	onPageChange(number){
      this.setState({pageNumber:number-1, hideLoader: true});
	  this.parametrizedData(this.state.pageSize,number-1);
	}
	parametrizedData(pageSize, pageNumber){
		let breakFixActualItemId='';
		if(this.props.breakFixItemId.includes(":")) {
			breakFixActualItemId = this.props.breakFixItemId || "";
		}else{
			breakFixActualItemId = this.props.breakFixItemId;
		}
		let modulesearch = GLOBAL.checkmoduleval;
		this.props.clearBreakFixRelatedWorkDetails();
        this.loadDataToRelate(modulesearch,breakFixActualItemId,pageSize,pageNumber);
	}
	loadDataToRelate(modulesearch,breakFixActualItemId,pageSize,pageNumber){
		if (modulesearch == "Breakfix") {
			this.props.resetRelatedWorkItmList();
			this.setState({ masterCheck: false });
			this.breakFixItemIdActual();

			if (GLOBAL.relatedfilterval == "breakfixNumber")
				this.props.loadBreakFixRelatedWorkDetails(GLOBAL.relatedfilterval, GLOBAL.relatedfiltertext, "Breakfix", "Y", breakFixActualItemId, "-20,10,15,20",	pageSize, pageNumber);
			if (GLOBAL.relatedfilterval == "impactedUserid")
				this.props.loadBreakFixRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.breakFixEditDetails.impactedUserid, "Breakfix", "Y", breakFixActualItemId, "-20,10,15,20",pageSize, pageNumber);
			if (GLOBAL.relatedfilterval == "serviceId")
				this.props.loadBreakFixRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.breakFixEditDetails.serviceId, "Breakfix", "Y", breakFixActualItemId, "-20,10,15,20",	pageSize, pageNumber);
			if (GLOBAL.relatedfilterval == "ciId")
				this.props.loadBreakFixRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.breakFixEditDetails.ciId, "Breakfix", "Y", breakFixActualItemId, "-20,10,15,20", pageSize, pageNumber);
		}
		if (modulesearch == "Fullfilment") {
			if (GLOBAL.relatedfilterval == "breakfixNumber")
				this.props.loadBreakFixRelatedWorkDetails("itemNumber", GLOBAL.relatedfiltertext, "Fullfilment", "", "", "",pageSize,pageNumber);
			if (GLOBAL.relatedfilterval == "impactedUserid")
				this.props.loadBreakFixRelatedWorkDetails("requesterId", this.props.breakFixEditDetails.impactedUserid, "Fullfilment",	"", "", "",pageSize,pageNumber);
			if (GLOBAL.relatedfilterval == "serviceId")
				this.props.loadBreakFixRelatedWorkDetails("offeringIdSx", this.props.breakFixEditDetails.serviceId, "Fullfilment", "", "", "",pageSize,pageNumber);
			if (GLOBAL.relatedfilterval == "ciId")
				this.props.loadBreakFixRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.breakFixEditDetails.ciId, "Fullfilment", "Y", this.state.breakFixActualItemId, "-20,10,15,20");
		}
		if (modulesearch == "Investigation") {
			if (GLOBAL.relatedfilterval == "breakfixNumber")
				this.props.loadBreakFixRelatedWorkDetails("investigationNumber", GLOBAL.relatedfiltertext, "Investigation", '', '', '',pageSize,pageNumber);
			if (GLOBAL.relatedfilterval == "impactedUserid")
				this.props.loadBreakFixRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.breakFixEditDetails.impactedUserid, "Investigation", '', '', '',pageSize,pageNumber);
			if (GLOBAL.relatedfilterval == "serviceId")
				this.props.loadBreakFixRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.breakFixEditDetails.serviceId, "Investigation",	'', '', '',	pageSize,pageNumber);
			if (GLOBAL.relatedfilterval == "ciId")
				this.props.loadBreakFixRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.breakFixEditDetails.ciId, "Investigation", "Y", this.state.breakFixActualItemId, "-20,10,15,20");
		}

		if (modulesearch == "ChangeManagement") {
			if (GLOBAL.relatedfilterval == "breakfixNumber")
				this.props.loadBreakFixRelatedWorkDetails("changeNumber", GLOBAL.relatedfiltertext, "ChangeManagement",	'', '', '',pageSize,pageNumber);
			if (GLOBAL.relatedfilterval == "impactedUserid")
				this.props.loadBreakFixRelatedWorkDetails("requesterId", this.props.breakFixEditDetails.impactedUserid, "ChangeManagement",	'', '', '',pageSize,pageNumber);
			if (GLOBAL.relatedfilterval == "serviceId")
				this.props.loadBreakFixRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.breakFixEditDetails.serviceId, "ChangeManagement");
			if (GLOBAL.relatedfilterval == "ciId")
				this.props.loadBreakFixRelatedWorkDetails(GLOBAL.relatedfilterval, this.props.breakFixEditDetails.ciId, "ChangeManagement", "Y", this.state.breakFixActualItemId, "-20,10,15,20");
		}
	}
	render() {
		// console.log('**********', {state: this.state, props: this.props});
		// console.log("GLOBAL.checkmoduleval ,,", this.props)
		let formVariable = <div>
			<Row>
				<Col md={12}>
					<Form.Group className="margin-b-5">
						<Form.Label>{this.props.translator['Type']}</Form.Label>
						<Form.Select aria-label="select" onChange={this.checkmodule.bind(this)}>

							<option value="Breakfix">Incident</option>
							<option value="Fullfilment">Fulfillment</option>
							<option value="Investigation">Problem</option>
							<option value="ChangeManagement">Change</option>
						</Form.Select>
					</Form.Group>
				</Col>
			</Row>
			{this.state.moduleVal == "Breakfix"
				?
				<Row>
					<Col md={12}>
						<Form.Group className="margin-b-5">
							<Form.Label>{this.props.translator['Relationship']}</Form.Label>
							<Form.Select aria-label="select" onChange={(e) => this.checkRelation(e)} value={this.state.checkRelationVal}>
								<option value="p2c">{this.props.translator['Is a Parent of']}</option>
								<option value="c2p">{this.props.translator['Is a Child of']}</option>
								<option value="p2p">{this.props.translator['Is a Peer of']}</option>
							</Form.Select>
						</Form.Group>
					</Col>
				</Row> : ""}
			<div className="margin-b-10">
				<Row>
					<Col md={6} className="padding-r-0"><Form.Label>{this.props.translator['Search by']}</Form.Label>
						<Form.Select aria-label="select" onChange={this.checkfilter.bind(this)} >
							<option value="">Select</option>
							{this.props.breakFixEditDetails.serviceId != '0' || this.props.breakFixEditDetails.serviceId != 0 ? GLOBAL.checkmoduleval=="ChangeManagement"?null:<option value="serviceId">Same Service</option> : null}
							{GLOBAL.checkmoduleval=="Fullfilment"?null:(this.props.breakFixEditDetails.ciId && (this.props.breakFixEditDetails.ciId != '0' || this.props.breakFixEditDetails.ciId != 0) ) ? <option value="ciId">Same Impacted CI</option> : null}
							{/*<option value="Keyword">Keyword</option>*/}
							{/* {(this.state.checkRelationVal == "p2p" && this.state.moduleVal == "Breakfix") || this.state.moduleVal != "Breakfix" ? <option value="breakfixNumber">Item ID</option> : null}
							{(this.state.checkRelationVal == "p2p" && this.state.moduleVal == "Breakfix") || this.state.moduleVal != "Breakfix" ? <option value="impactedUserid">Same Requestor</option> : null} */}
							<option value="breakfixNumber">Item ID</option>
							<option value="impactedUserid">Same Requestor</option>

						</Form.Select></Col>
					<Col md={6}><Form.Label>&nbsp;</Form.Label><Form.Control type="text" onChange={this.filtertext.bind(this)} value={this.state.textEntered} placeholder={this.props.translator['Search']} onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}} /></Col>
				</Row>
				<div className="btnNew">
					{this.state.messagedisplay == false ? <a onClick={this.loadrelateddata.bind(this)} className="backList"></a> : null}

					{/*<i className="fa fa-angle-double-left"></i>{this.props.translator[' Back to List']}*/}

					{/* {this.props.showLoader ?
						<div className="workitemFloatRight">
							<Loader type="Oval" color="green" height={20} width={20} />
						</div> : <span></span>
					}
					{this.props.showLoader
					?<a title={this.props.translator['Search']} className="srch"><i className="fa fa-search"></i></a>
					:<a onClick={this.loaddata.bind(this)} title={this.props.translator['Search']} className="srch"><i className="fa fa-search"></i></a>} */}

					<div className="btnNew margin-t-10">
						<Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary"  title={this.props.translator['Search']} disabled={this.props.showLoader && !this.state.hideLoader }
							onClick={this.loaddata.bind(this)}>
							{this.props.translator['Search']} {this.props.showLoader && !this.state.hideLoader ? <ImSpinner6 className="icn-spinner"/> : null}
						</Button>
					</div>

				</div>
				{/*	<div className="btnNew padding-5">&nbsp;<a onClick={this.loadrelateddata.bind(this)} title="Back to list" className="srch"><i className="fa fa-search"></i></a></div>
				<div className="btnNew padding-5">&nbsp;<a onClick={this.loaddata.bind(this)} title="Search" className="srch"><i className="fa fa-search"></i></a></div>*/}
			</div>
			{!this.props.showLoader.loading ? this.renderRelatedWorkLog(this.props.breakFixRelatedWorkDetails) : <div>{""}</div>}
		</div>;
		return (
			<div className="margin-b-10">
				<div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
					<ul>
					{(this.props && this.props.breakFixEditDetails && this.props.breakFixEditDetails.status && (this.props.breakFixEditDetails.status == "Closed" || this.props.breakFixEditDetails.status == "Completed" || this.props.breakFixEditDetails.status == "Cancelled")) ?
					    null :
					<li><Button title={this.state.toggleFormAndList == 'list' ? this.props.translator['Related Work Items'] : this.props.translator['Relate Work Item']} 
					onClick={this.toggleFormAndList} bsStyle="" bsClass="" bsPrefix=" " className="myBt">{this.props.fieldStatus.relateWorkItems == true && !this.props.disableFieldsForGuestRole ? 
					this.state.toggleFormAndList == 'list' ? <HiPlus /> : <PiListBulletsBold /> : null}
					</Button></li>}
					<li className="d-md-none">
					<button
					    type='button'
						title="Minimize the right panel"
						bsClass=""
						bsStyle=""
						className="closerightPanelBtn"
						onClick={() => {
						this.props.rightEditPanel(false);
						this.props.changeRightView("allClose");
						}}
					>
						<IoClose />
					</button>
					</li>
					</ul>
				</div>

				<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.state.toggleFormAndList == 'list' ? this.props.translator['Related Work Items'] : this.props.translator['Relate Work Item']}</div></div>

				<div className='rBoxGap'>
				{this.state.toggleFormAndList == 'list' || this.props.disableFieldsForGuestRole ?
					this.renderRelatedDataLog(this.props.breakFixRelatedDataDetails)
					:
					formVariable
				}
				</div>

			</div>
		);
	}
}

export function mapStateToProps({ breakFixRelatedWorkDetails, breakFixRelatedDataDetails, rightMenuShowLoader, bfRelatedWorkCheckList }) {
	//  alert("breakFixEditDetails:"+breakFixAuditLogDetails);
	// console.log("bfRelatedWorkCheckList ", bfRelatedWorkCheckList)
	return {
		breakFixRelatedWorkDetails, breakFixRelatedDataDetails, showLoader: rightMenuShowLoader.loading,
		bfCheckList: bfRelatedWorkCheckList
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		loadBreakFixRelatedWorkDetails, loadBreakFixRelatedDataDetails,
		addToRelatedWorkItmList, removeFromRelatedWorkItmList, resetRelatedWorkItmList
		, addAllToRelatedWorkItmList, clearBreakFixRelatedWorkDetails, setWipSearchApply
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PopoverWorkItemsData);
