
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {IoClose} from "react-icons/io5";
class ProposeCriticalTab extends React.Component{
	render(){
		return(
		<div className="hambTab">
			<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
				<ul>
					<li>
					<button
					    type='button'
						title="Minimize the right panel"
						bsClass=""
						bsStyle=""
						className="closerightPanelBtn"
						onClick={() => {
						this.props.rightEditPanel(false);
						this.props.changeActionView("");
						}}
					>
						<IoClose />
					</button>
					</li>
				</ul>
			</div>
			<div className="rPageHeading">Propose RFC</div>


		</div>
		);
	}
}

export default ProposeCriticalTab;
