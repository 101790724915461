
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { connect } from "react-redux";
import axios from "axios";
import Dropzone from "react-dropzone";
import _ from "lodash";
import Cookies from "universal-cookie";
import moment from "moment-timezone";
import swal from "sweetalert"
const Joi = require("joi");

import { GLOBAL } from "_Globals";

import {
  loadChangeEditData,
  getLovValues,
} from "../../../../actions/changeManagement/changeManagementAction";
import { loadBreakFixAuditLogDetails } from "../../../../actions/breakFix/breakFixAuditLogAction";
import {
  loadImplPlanList,
  loadImplementationPlan,
} from "../../../../actions/common/attachmentAction";
import { loadStatusBasedHamburgerOptions } from "../../../../actions/common/loadStatusBasedHamburgerOptions";
import { loadTimelineData } from "../../../../actions/common/commonApisActions";

import configureStore from "../../../../store/configureStore";
import constanturl from "../../../../utils/constants";
import { fileobjectGenerator } from "../../../common/MIMEObjGenerator";
import Swal from "sweetalert2";
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
import { changeStatusMapping, openLinkInNewTab } from "../../../common/helper";

const store = configureStore();
const cookies = new Cookies();
let attachmentDetails = cookies.get("gph");
let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
timezone = timezone.split("~");
timezone = timezone[3];
if (attachmentDetails) attachmentDetails = attachmentDetails.replace("s:", "");
if (attachmentDetails)
  attachmentDetails = attachmentDetails.substring(
    0,
    attachmentDetails.lastIndexOf(".")
  );
attachmentDetails = attachmentDetails.split("~");
const attachmentSize = attachmentDetails[21];
const attachmentType = fileobjectGenerator(attachmentDetails[22].split(', '));

class ProvideAttachmentsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      planTypeValidationError: "",
      planDetailsValidationError: "",
      savedImplPlanMessage: "",
      planType: "",
      planDetails: "",
      planDetailsView: "",
      filesPreview: [],
      filesToBeSent: [],
      printcount: 1,
      showfilepreview: true,
      showCreateForm: true,
      showEditIconFlag:
        this.props.role_id.split(",").indexOf("20") >= 0 ||
        this.props.role_id.split(",").indexOf("52") >= 0 ||
        this.props.role_id.split(",").indexOf("19") >= 0 ||
        this.props.role_id.split(",").indexOf("51") >= 0
          ? true
          : false,
      submitButtonDisabled: false,
      isServiceDesk: false,
      showView: false,
      totalChar: 2000,
      additionalcharleft: 2000,
    };
    this.getPlanType = this.getPlanType.bind(this);
    this.getPlan = this.getPlan.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.deleteFileAction = this.deleteFileAction.bind(this);
    this.uploadNewPlan = this.uploadNewPlan.bind(this);
    // this.downloadFileAction = this.downloadFileAction.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.editFileAction = this.editFileAction.bind(this);
    this.viewFileAction = this.viewFileAction.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.getEditPlanType = this.getEditPlanType.bind(this);
    this.getEditPlan = this.getEditPlan.bind(this);
    this.getViewPlanType = this.getViewPlanType.bind(this);
    this.getViewPlan = this.getViewPlan.bind(this);
    this.callmeback = this.callmeback.bind(this);
    this.callmebackView = this.callmebackView.bind(this);
    this.isPlanViewMode = this.isPlanViewMode.bind(this);
  }
  getPlanType(event) {
    if (event.target.value != "") {
      this.setState({
        planType: event.target.value,
        planTypeValidationError: "",
      });
    } else {
      this.setState({
        planType: event.target.value,
        planTypeValidationError: "error",
      });
    }
  }
  getPlan(event) {
    let charLen = event.target.value.length;
    this.setState({ additionalcharleft: this.state.totalChar - charLen });
    if (event.target.value.trim().length !== 0) {
      this.setState({
        planDetails: event.target.value,
        planDetailsValidationError: "",
      });
    } else {
      this.setState({
        planDetails: event.target.value,
        planDetailsValidationError: "error",
      });
    }
  }
  removeFile() {
    this.setState({ filesToBeSent: [], filesPreview: [] });
  }
  deleteFileAction(attachmentId) {
    let ref = this;
    let postjson = {
      modifiedBy: "",
      modifiedByName: "",
      systemStartDateRequired: "N",
      systemEndDateRequired: "N",
    };
    if (attachmentId != "") {
      axios
        .patch(GLOBAL.changeEditUrl + this.props.changeId, postjson)
        .then(function (response) {
          let config = {
            headers: {},
          };
          if (response.status === 200) {
            let changeId = response.data.changeId;
            axios
              .put(
                constanturl.d2curl +
                  "/attachmentStatusUpdateAction/" +
                  attachmentId +
                  "?status=Y",
                {},
                config
              )
              .then(function (response) {
                if (response.status === 200) {
                  store.dispatch({
                    type: "CHANGE_EDIT_DATA_LOAD_SUCCESS",
                    editChangeInitialData: {},
                  });
                  ref.props.loadChangeEditData(changeId);
                  ref.props.loadImplPlanList("ChangeManagement", changeId);
                  ref.props.loadStatusBasedHamburgerOptions(
                    "ChangeManagement",
                    response.data.status,
                    response.data.changeId
                  );
                }
              })
              .catch(function (error) {});
          }
        })
        .catch(function (error) {});
    }
  }
  uploadNewPlan() {
    this.setState({
      savedImplPlanMessage: "",
      planType: "",
      planDetails: "",
      showfilepreview: true,
    });
    this.state.additionalcharleft = 2000;
  }
  // downloadFileAction(attachmentId) {
  //   return axios
  //     .request({
  //       responseType: "arraybuffer",
  //       url: GLOBAL.getAttachedFile + "" + attachmentId,
  //       method: "get",
  //       headers: {},
  //     })
  //     .then((result) => {
  //       const outputFilename = "/tmp/file.mp3";
  //       // fs.writeFileSync(outputFilename, result.data);
  //       return outputFilename;
  //     });
  // }
  handleClick(event) {
    let ref = this;
    let urlToHit = "/change/api/saveImplPlanOnly";
    if (this.state.planType == "" || this.state.planType == undefined) {
      this.setState({
        planTypeValidationError: "error",
      });
    } else {
      this.setState({
        planTypeValidationError: "",
      });
    }
    if (this.state.planDetails.trim().length === 0) {
      this.setState({
        planDetailsValidationError: "error",
      });
    } else {
      this.setState({
        planDetailsValidationError: "",
      });
    }
    if (
      this.state.planType != "" &&
      this.state.planType != undefined &&
      this.state.planDetails.trim().length !== 0
    ) {
      let planType = "";
      if (ref.state.planType == "60") {
        planType = "Implementation";
      } else if (ref.state.planType == "65") {
        planType = "Backout";
      } else if (ref.state.planType == "70") {
        planType = "TestResults";
      } else if (ref.state.planType == "75") {
        planType = "Validation";
      } else {
        planType = "";
      }
      if (
        (_.some(this.props.implememtationPlanList, [
          "planType",
          "Implementation",
        ]) &&
          planType == "Implementation") ||
        (_.some(this.props.implememtationPlanList, ["planType", "Backout"]) &&
          planType == "Backout")
      ) {
        Swal.fire(planType + " plan already submitted.");
      } else {
        let formData = new FormData();
        if (this.state.filesToBeSent.length > 0) {
          urlToHit = "/change/api/saveImplPlan";
          formData.append("file", this.state.filesToBeSent[0][0]);
        }
        let jsonObject = new Object();
        jsonObject.itemId = this.props.changeId;
        jsonObject.module = "ChangeManagement";
        jsonObject.planType = planType;
        jsonObject.planDetail = this.state.planDetails;
        jsonObject.createdOn = Math.round(new Date().getTime() / 1000.0);
        jsonObject.createdBy = "";
        jsonObject.createdByName = "";
        formData.append("attachment", JSON.stringify(jsonObject));
        if (urlToHit == "/change/api/saveImplPlan") {
          this.setState({ submitButtonDisabled: true });
          axios
            .post(urlToHit, formData)
            .then((response) => {
              this.setState({ submitButtonDisabled: false });
              if (response.status == 200) {
                ref.props.loadImplPlanList(
                  "ChangeManagement",
                  ref.props.changeId
                );
                ref.setState({ showfilepreview: true });
                ref.setState({ filesToBeSent: [], filesPreview: [] });
              }
            })
            .catch((error) => {
              this.setState({ submitButtonDisabled: false });
            });
        } else {
          this.setState({ submitButtonDisabled: true });
          axios
            .post(urlToHit, { attachment: JSON.stringify(jsonObject) })
            .then((response) => {
              this.setState({ submitButtonDisabled: false });
              if (response.status == 200) {
                ref.props.loadImplPlanList(
                  "ChangeManagement",
                  ref.props.changeId
                );
                ref.setState({ showfilepreview: true });
                ref.setState({ filesToBeSent: [], filesPreview: [] });
              }
            })
            .catch((error) => {
              this.setState({ submitButtonDisabled: false });
            });
        }
        if (this.state.planType == "" || this.state.planType == undefined) {
          this.setState({
            planTypeValidationError: "error",
          });
        } else {
          this.setState({
            planTypeValidationError: "",
          });
        }
        if (this.state.planDetails.trim().length === 0) {
          this.setState({
            planDetailsValidationError: "error",
          });
        } else {
          this.setState({
            planDetailsValidationError: "",
          });
        }
        let postjson = {
          modifiedBy: "",
          modifiedByName: "",
          systemStartDateRequired: "N",
          systemEndDateRequired: "N",
        };
        if (
          this.state.planType != "" &&
          this.state.planDetails.trim().length !== 0 &&
          this.state.planType != undefined
        ) {
          axios
            .patch(GLOBAL.changeEditUrl + this.props.changeId, postjson)
            .then(function (response) {
              if (response.status === 200) {
                ref.setState({
                  savedImplPlanMessage:
                    ref.props.translator["Plan successfully saved"],
                  showfilepreview: false,
                  filesToBeSent: [],
                  filesPreview: [],
                });
                store.dispatch({
                  type: "CHANGE_EDIT_DATA_LOAD_SUCCESS",
                  editChangeInitialData: {},
                });
                ref.props.loadStatusBasedHamburgerOptions(
                  "ChangeManagement",
                  response.data.status,
                  response.data.changeId
                );
              }
            })
            .catch(function (error) {});
        }
        this.setState({ planDetails: "", planType: "" });
      }
    }
  }
  editFileAction(attachmentId) {
    this.props.loadImplementationPlan(attachmentId, this.callmeback);
    this.removeFile();
    this.setState({
      showCreateForm: false,
      planTypeValidationError: "",
      planDetailsValidationError: "",
      savedImplPlanMessage: "",
      showView: false,
    });
    if (
      this.props.implementationPlanDetails.filename != null ||
      this.props.implementationPlanDetails.filename != "" ||
      this.props.implementationPlanDetails.filename != undefined
    ) {
      this.setState({ showfilepreview: true });
    }
  }
  viewFileAction(attachmentId) {
    this.props.loadImplementationPlan(attachmentId, this.callmebackView);
    this.removeFile();
    this.setState({
      showView: true,
      showCreateForm: false,
      savedImplPlanMessage: "",
      planTypeValidationError: "",
      planDetailsValidationError: ""
    });
  }
  cancelEdit() {
    this.removeFile();
    this.setState({
      showCreateForm: true,
      planType: "",
      planDetails: "",
      planDetailsValidationError: "",
    });
    this.state.additionalcharleft = 2000;
  }
  updateForm(attachmentId) {
    let ref = this;
    if (this.state.planDetails.trim().length === 0) {
      this.setState({ planDetailsValidationError: "error" });
      return;
    } else {
      this.setState({ planDetailsValidationError: "" });
    }
    if (
      this.state.planDetails.trim().length !== 0 ||
      this.props.implementationPlanDetails.planDetail != ""
    ) {
      let planType = "";
      planType = ref.props.implementationPlanDetails.planType;
      let formData = new FormData();
      if (this.state.filesToBeSent.length > 0) {
        formData.append("file", this.state.filesToBeSent[0][0]);
      }
      let jsonObject = new Object();
      jsonObject.itemId = this.props.changeId;
      jsonObject.module = "ChangeManagement";
      jsonObject.planType = planType;
      jsonObject.planDetail =
        this.state.planDetails.trim().length !== 0
          ? this.state.planDetails
          : ref.props.implementationPlanDetails.planDetail;
      jsonObject.createdOn = Math.round(new Date().getTime() / 1000.0);
      jsonObject.createdBy = "";
      jsonObject.createdByName = "";
      formData.append("attachment", JSON.stringify(jsonObject));
      axios
        .post("/change/api/updateImplPlan/" + attachmentId, formData)
        .then((response) => {
          if (response.status == 200) {
            ref.props.loadImplPlanList("ChangeManagement", ref.props.changeId);
            ref.setState({ showfilepreview: true });
            ref.setState({ filesToBeSent: [], filesPreview: [] });
          }
        })
        .catch((error) => {});
    }
    let postjson = {
      modifiedBy: "",
      modifiedByName: "",
      systemStartDateRequired: "N",
      systemEndDateRequired: "N",
    };
    if (
      this.state.planType != "" &&
      this.state.planType != undefined &&
      this.state.planDetails.trim().length !== 0
    ) {
      axios
        .patch(GLOBAL.changeEditUrl + this.props.changeId, postjson)
        .then(function (response) {
          if (response.status === 200) {
            ref.setState({
              savedImplPlanMessage:
                this.props.translator["Plan successfully saved"],
              showfilepreview: false,
              filesToBeSent: [],
              filesPreview: [],
            });
            store.dispatch({
              type: "CHANGE_EDIT_DATA_LOAD_SUCCESS",
              editChangeInitialData: {},
            });
            ref.props.loadStatusBasedHamburgerOptions(
              "ChangeManagement",
              response.data.status,
              response.data.changeId
            );
            ref.props.loadChangeEditData(response.data.changeId);
          }
        })
        .catch(function (error) {});
    }
    this.setState({
      showCreateForm: true,
      showfilepreview: false,
      planDetails: "",
    });
    this.state.additionalcharleft = 2000;
  }
  getEditPlanType(event) {
    this.setState({ planType: event.target.value });
  }
  getEditPlan(event) {
    if (event.target.value.trim().length !== 0) {
      let charLen = event.target.value.length;
      let additionalchar = this.state.totalChar - charLen;
      this.setState({ additionalcharleft: additionalchar });
    }
    this.setState({
      planDetails: event.target.value,
      planDetailsValidationError: "",
    });
  }
  getViewPlanType(event) {
    this.setState({ planType: event.target.value });
  }
  getViewPlan(event) {
    this.setState({ planDetailsView: event.target.value });
  }
  callmeback(value) {
    this.setState({ planDetails: value ? value.data.planDetail : "" });
  }
  callmebackView(value) {
    this.setState({ planDetailsView: value ? value.data.planDetail : "" });
  }
  isPlanViewMode() {
    try {
      const { editChangeData } = this.props;
      if (
        editChangeData.statusId == changeStatusMapping["Draft"] ||
        editChangeData.statusId == changeStatusMapping["Referred Back"]
      ) {
        return false;
      }
      return true;
    } catch (e) {
      return true;
    }
  }
  componentWillMount() {
    if (
      !(
        this.props.roleIdentification("19") ||
        this.props.roleIdentification("20") ||
        this.props.roleIdentification("51") ||
        this.props.roleIdentification("52")
      )
    ) {
      this.setState({ isServiceDesk: this.props.roleIdentification("48") });
    }
  }
  cancelView() {
    this.setState({
      showCreateForm: true,
      planType: "",
      planDetails: "",
      planDetailsValidationError: "",
      showView: false,
    });
    this.state.additionalcharleft = 2000;
  }
  onDrop(acceptedFiles, rejectedFiles) {
    let filesToBeSent = this.state.filesToBeSent;
    if (acceptedFiles.length > 0) {
      if (filesToBeSent.length < this.state.printcount) {
        filesToBeSent.push(acceptedFiles);
        let filesPreview = [];
        for (let i in filesToBeSent) {
          if (filesToBeSent.hasOwnProperty(i)) {
            filesPreview.push(
              <ListGroup.Item bsClass="">
                <span className="float-r"
                    onClick={() => {
                      this.removeFile();
                    }}
                  ><IoClose />
                </span>
                {filesToBeSent[i][0].name}
              </ListGroup.Item>
            );
          }
        }
        this.setState({ filesToBeSent, filesPreview });
      } else {
        Swal.fire(this.props.translator["File already attached"]);
      }
    }
  }
  onDropRejected(rejectedFiles) {
    if (rejectedFiles && rejectedFiles[0] && rejectedFiles[0].file && rejectedFiles[0].file.size && rejectedFiles[0].file.size > 1048576 * attachmentSize) {
      swal({
        text: this.props.translator["Please upload File up to "] + attachmentSize + this.props.translator[" MB size."],
        button: this.props.translator["OK"],
      });
    } else {
      swal({
        text: this.props.translator["File not supported."],
        button: this.props.translator["OK"],
      });
    }
  }
  renderPlanType(planTypeData) {
    if (!planTypeData) {
      return <option></option>;
    }
    if (this.props.editChangeData.type === "20") {
      return planTypeData.map((planTypeDataObj) => {
        if (planTypeDataObj.field1Key === "Implementation") {
          return (
            <option value={planTypeDataObj.field1Value}>
              {planTypeDataObj.field1Key}
            </option>
          );
        }
      });
    }
    return planTypeData.map((planTypeDataObj) => {
      return (
        <option value={planTypeDataObj.field1Value}>
          {planTypeDataObj.field1Key}
        </option>
      );
    });
  }
  renderPlanTypeCreate(planTypeData) {
    if (!planTypeData) {
      return <option></option>;
    }
    if (this.props.editChangeData.type === "20") {
      return planTypeData.map((planTypeDataObj) => {
        if (planTypeDataObj.field1Key === "Implementation") {
          return (
            <option value={planTypeDataObj.field1Value}>
              {planTypeDataObj.field1Key}
            </option>
          );
        }
      });
    }
    let FilterDropdownList = planTypeData.filter((planTypeDataObj) => {
      return !this.props.implememtationPlanList.find((dropList) => {
        return (
          dropList.planType !== "TestResults" &&
          dropList.planType !== "Validation" &&
          planTypeDataObj.field1Key.replace(" ", "") == dropList.planType
        );
      });
    });
    return FilterDropdownList.map((planTypeDataObj) => {
      return (
        <option value={planTypeDataObj.field1Value}>
          {planTypeDataObj.field1Key}
        </option>
      );
    });
  }
  renderImplementationPlan(implememtationPlanData) {
    if (
      implememtationPlanData.length == undefined ||
      implememtationPlanData.length == 0
    ) {
      return (
        <tr className="appTabClas f-size-13 text-c">
          <td width="100%" colSpan={5}>
            <div>{this.props.translator["No plan found"]}</div>
          </td>
        </tr>
      );
    }

    return implememtationPlanData.map((implememtationPlanDataObj) => {
      let validated_url = undefined;
      let ctime = new Date(implememtationPlanDataObj.createdOn * 1000);
      if (
        implememtationPlanDataObj != null &&
        implememtationPlanDataObj.fileTempURL != null
      ) {
        let namedSchema = Joi.string().regex(/^[^<>}{]+$/);
        let validator_to_add_myQbj = namedSchema.validate(
          implememtationPlanDataObj.fileTempURL
        );
        if (validator_to_add_myQbj.error == null) {
          validated_url = implememtationPlanDataObj.fileTempURL;
        } else {
          validated_url = "\\";
        }
      } else validated_url = "\\";
      let linka = implememtationPlanDataObj.attachmentId;
      if (
        implememtationPlanDataObj.planType === null ||
        implememtationPlanDataObj.planType === ""
      ) {
        return;
      }
      if(implememtationPlanDataObj.filename !== null){
        if (implememtationPlanDataObj.filename.indexOf("##") > -1){
          implememtationPlanDataObj.filename = implememtationPlanDataObj.filename.slice(15);
        } 
      }
      return (
        <tr>
          <td>{implememtationPlanDataObj.planType}</td>
          <td>
            <a
              title={implememtationPlanDataObj.filename}
              onClick={() => {
                openLinkInNewTab(validated_url, "New Tab");
              }}
            >
              {implememtationPlanDataObj.filename != null || undefined
                ? implememtationPlanDataObj.filename.slice(0, 12)
                : ""}
              {implememtationPlanDataObj.filename != null &&
                implememtationPlanDataObj.filename.length > 10 && (
                  <span>...</span>
                )}
            </a>
          </td>
          <td>
            {implememtationPlanDataObj.createdByName}
            <p>
              {moment
                .unix(implememtationPlanDataObj.createdOn)
                .tz(timezone)
                .format(this.props.date_format.replace("HH:MM:SS", "hh:mm:ss"))}
            </p>
          </td>
          <td>
            <span className="">
              {
                <a title="View" rel="noopener">
                  <i
                    className="fa fa-eye"
                    onClick={() => {
                      this.viewFileAction(
                        implememtationPlanDataObj.attachmentId
                      );
                    }}
                  ></i>
                </a>
              }
              &nbsp;&nbsp;
              {this.state.showEditIconFlag &&
              this.isPlanViewMode() === false && !this.props.disableFieldsForGuestRole &&
              !this.state.isServiceDesk ? (
                <a title="Edit">
                  <i
                    className="fa fa-pencil"
                    onClick={() => {
                      this.editFileAction(
                        implememtationPlanDataObj.attachmentId
                      );
                    }}
                  ></i>
                </a>
              ) : (
                ""
              )}
            </span>
          </td>
        </tr>
      );
    });
  }
  render() {
    let planType = "";
    let planTypeDetails = "";
    if (this.props.editChangeData.planType == "60") {
      planType = "Implementation";
    } else if (this.props.editChangeData.planType == "65") {
      planType = "Back out";
    } else if (this.props.editChangeData.planType == "70") {
      planType = "Test Results";
    } else if (this.props.editChangeData.planType == "75") {
      planType = "Validation";
    } else {
      planType = "";
    }
    if (this.props.implementationPlanDetails.planType == "Implementation") {
      planTypeDetails = "60";
    } else if (this.props.implementationPlanDetails.planType == "Backout") {
      planTypeDetails = "65";
    } else if (this.props.implementationPlanDetails.planType == "TestResults") {
      planTypeDetails = "70";
    } else if (this.props.implementationPlanDetails.planType == "Validation") {
      planTypeDetails = "75";
    } else {
      planTypeDetails = "";
    }
    if (
      this.state.savedImplPlanMessage == "" &&
      (this.props.editChangeData.statusId == changeStatusMapping["Draft"] ||
        this.props.editChangeData.statusId == changeStatusMapping["Rejected"] ||
        (this.props.editChangeData.statusId == changeStatusMapping["Referred Back"] &&
          this.props.editChangeData.type != "20"))
    ) {
      return (
        <div className="hambTab">
          <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
              <li>
              <button
                  type='button'
                  title="Minimize the right panel"
                  bsClass=""
                  bsStyle=""
                  className="closerightPanelBtn"
                  onClick={() => {
                  this.props.rightEditPanel(false);
                  this.props.changeActionView("");
                  }}
              >
                  <IoClose/>
              </button>
              </li>
            </ul>
          </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {this.props.translator["Plans Listing"]}
            </div>
          </div>
          <div className="rBoxGap">
            {this.state.planTypeValidationError == "error" ||
            this.state.planDetailsValidationError == "error" ? (
              <div>
                <font color="red">
                  <strong>Please revalidate the highlighted fields !</strong>
                </font>
              </div>
            ) : (
              <div></div>
            )}
            {this.isPlanViewMode() === false && (
              <div>
                {this.state.showCreateForm && !this.props.disableFieldsForGuestRole? (
                  <div>
                    {!this.state.isServiceDesk && (
                      <div>
                        <Form.Group className="form-group">
                          <Form.Label>
                            <span className="rStar"></span>{" "}
                            {this.props.translator["Plan Type"]}
                          </Form.Label>
                          <Form.Select
                            className={this.state.planTypeValidationError}
                            as="select"
                            onChange={this.getPlanType.bind(this)}
                          >
                            <option value="">Select</option>
                            {this.renderPlanTypeCreate(this.props.planType)}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group className="form-group">
                          <Form.Label>
                            <span className="rStar"></span>{" "}
                            {this.props.translator["Plan"]}
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows="2"
                            maxLength="2000"
                            value={this.state.planDetails}
                            onChange={this.getPlan.bind(this)}
                            className={this.state.planDetailsValidationError}
                          />
                          <p
                            className="charLeft"
                            style={{ textAlign: "right", fontSize: "11px" }}
                          >
                            ({this.props.translator["Characters Remaining"]}:{" "}
                            {this.state.additionalcharleft}/
                            {this.state.totalChar})
                          </p>
                        </Form.Group>
                        <Dropzone
                          onDrop={(files) => this.onDrop(files)}
                          onDropRejected={(files) => this.onDropRejected(files)}
                          className=""
                          maxSize={1048576 * attachmentSize}
                          accept={attachmentType}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              className="text-c cursorPoint margin-t-5 margin-b-5 grayBg"
                              style={{
                                width: "100%",
                                height: "60px",
                                "padding-top": "20px",
                                border: "1px solid black",
                                "border-top-color": "#bdc4c9",
                                "border-right-color": "#bdc4c9",
                                "border-bottom-color": "#bdc4c9",
                                "border-left-color": "#bdc4c9",
                              }}
                            >
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>
                                  {
                                    this.props.translator[
                                      "Drop files or click here to upload"
                                    ]
                                  }
                                  {this.props.translator["(max size"]} {attachmentSize}
                                  {this.props.translator["MB)"]}
                                </p>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        {this.state.showfilepreview ? (
                          <div className="margin-t-20">
                            <div className="border-b padding-b-5 margin-b-10 black f-size-16 fw-400">
                              {this.state.filesPreview.length > 0
                                ? this.props.translator["file attached"]
                                : this.props.translator["file not attached"]}
                            </div>
                            <ListGroup bsPrefix=" " bsClass="" className="attachment-break">
                              {this.state.filesPreview}
                            </ListGroup>
                          </div>
                        ) : (
                          ""
                        )}
                        <ButtonToolbar className="margin-t-10">
                          <Button
                            className="rgSidrkBtn smallBtn"
                            bsSize="small"
                            bsStyle="primary"
                            disabled={this.state.submitButtonDisabled}
                            onClick={() => {
                              this.handleClick(event);
                            }}
                          >
                            {this.state.submitButtonDisabled ? (
                              <ImSpinner6 className="icn-spinner"/>
                            ) : null}{" "}
                            {this.props.translator["Save"]}
                          </Button>
                        </ButtonToolbar>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    {this.state.showView ? (
                      <div>
                        <Form.Group className="form-group">
                          <Form.Label>
                            {" "}
                            {this.props.translator["Plan Type"]}
                          </Form.Label>
                          <Form.Select
                            as="select"
                            value={planTypeDetails}
                            disabled="true"
                          >
                            {this.renderPlanType(this.props.planType)}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group className="form-group">
                          <Form.Label>
                            {this.props.translator["Plan"]}
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            readOnly
                            rows="2"
                            value={this.state.planDetailsView}
                          ></Form.Control>
                        </Form.Group>
                        {
                          <div className="margin-t-20">
                            <div className="border-b padding-b-5 margin-b-10 black f-size-16 fw-400">
                              <p>{this.props.translator["File Name"]}</p>
                              {this.props.implementationPlanDetails.filename}
                            </div>
                            <ListGroup bsPrefix=" " bsClass="" className="attachment-break">
                              {this.state.filesPreview}
                            </ListGroup>
                            <Button
                              variant="danger"
                              onClick={() => {
                                this.cancelView();
                              }}
                            >
                              {this.props.translator["Cancel"]}
                            </Button>
                          </div>
                        }
                      </div>
                    ) : !this.props.disableFieldsForGuestRole && (
                      <div>
                        <Form.Group className="form-group">
                          <Form.Label>
                            <span className="rStar"></span>{" "}
                            {this.props.translator["Plan Type"]}
                          </Form.Label>
                          <Form.Select
                            className={this.state.planTypeValidationError}
                            onChange={this.getEditPlanType.bind(this)}
                            value={planTypeDetails}
                            disabled="true"
                          >
                            {this.renderPlanType(this.props.planType)}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group className="form-group">
                          <Form.Label>
                            <span className="rStar"></span>{" "}
                            {this.props.translator["Plan"]}
                          </Form.Label>
                          <Form.Control
                            className={this.state.planDetailsValidationError}
                            as="textarea"
                            rows="2"
                            maxLength="2000"
                            onChange={this.getEditPlan.bind(this)}
                            value={this.state.planDetails}
                          ></Form.Control>
                          <p
                            className="charLeft"
                            style={{ textAlign: "right", fontSize: "11px" }}
                          >
                            ({this.props.translator["Characters Remaining"]}:{" "}
                            {this.state.totalChar -
                              this.state.planDetails.length}
                            /{this.state.totalChar})
                          </p>
                        </Form.Group>
                        <Dropzone
                          onDrop={(files) => this.onDrop(files)}
                          onDropRejected={(files) => this.onDropRejected(files)}
                          maxSize={1048576 * attachmentSize}
                          accept={attachmentType}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              className="text-c cursorPoint margin-t-5 margin-b-5 grayBg"
                              style={{
                                width: "100%",
                                height: "60px",
                                "padding-top": "20px",
                                border: "1px solid black",
                                "border-top-color": "#bdc4c9",
                                "border-right-color": "#bdc4c9",
                                "border-bottom-color": "#bdc4c9",
                                "border-left-color": "#bdc4c9",
                              }}
                            >
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>
                                  {
                                    this.props.translator[
                                      "Drop files or click here to upload"
                                    ]
                                  }
                                  {this.props.translator["(max size"]}{" "}
                                  {attachmentSize}
                                  {this.props.translator["MB)"]}
                                </p>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        {this.state.showfilepreview ? (
                          <div className="margin-t-20">
                            <div className="border-b padding-b-5 margin-b-10 black f-size-16 fw-400">
                              <p>{this.props.translator["File Name"]}</p>
                              {this.props.implementationPlanDetails.filename}
                            </div>
                            <ListGroup bsPrefix=" " bsClass="" className="attachment-break">
                              {this.state.filesPreview}
                            </ListGroup>
                          </div>
                        ) : (
                          ""
                        )}
                        <ButtonToolbar className="margin-t-15">
                          <Button
                            className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary"
                            onClick={() => {
                              this.updateForm(
                                this.props.implementationPlanDetails
                                  .attachmentId
                              );
                            }}
                          >
                            {this.props.translator["Update"]}
                          </Button>
                          <Button
                            // variant="danger"
                            className="rgSiLigBtn smallBtn" bsSize="small" bsStyle="primary"
                            onClick={() => {
                              this.cancelEdit();
                            }}
                          >
                            {this.props.translator["Cancel"]}
                          </Button>
                        </ButtonToolbar>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {this.state.showView &&
              this.props.editChangeData.statusId == changeStatusMapping["Rejected"] && (
                <div>
                  <Form.Group className="form-group">
                    <Form.Label>
                      {" "}
                      {this.props.translator["Plan Type"]}
                    </Form.Label>
                    <Form.Select value={planTypeDetails} disabled="true">
                      {this.renderPlanType(this.props.planType)}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>{this.props.translator["Plan"]}</Form.Label>
                    <Form.Control
                      as="textarea"
                      readOnly
                      rows="2"
                      value={this.state.planDetailsView}
                    ></Form.Control>
                  </Form.Group>
                  {
                    <div className="margin-t-20">
                      <div className="border-b padding-b-5 margin-b-10 black f-size-16 fw-400">
                        <p>{this.props.translator["File Name"]}</p>
                        {this.props.implementationPlanDetails.filename}
                      </div>
                      <ListGroup bsPrefix=" " bsClass="" className="attachment-break">
                        {this.state.filesPreview}
                      </ListGroup>
                      <Button
                        variant="danger"
                        onClick={() => {
                          this.cancelView();
                        }}
                      >
                        {this.props.translator["Cancel"]}{" "}
                      </Button>
                    </div>
                  }
                </div>
              )}
            <div className="margin-t-20">
              <div className="border-b padding-b-5 margin-b-10 black f-size-16 fw-400">
                {this.props.translator["Attachments"]}
              </div>
              <Table
                responsive
                striped
                bordered
                condensed
                hover
                className="f-size-13"
              >
                <thead>
                  <tr className="f-size-12">
                    <th>{this.props.translator["Plan Type"]}</th>
                    <th>{this.props.translator["File Name"]}</th>
                    <th>{this.props.translator["Created By"]}</th>
                    <th>{this.props.translator["Action"]}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderImplementationPlan(
                    this.props.implememtationPlanList
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="hambTab">
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {this.props.translator["Plans Listing"]}
            </div>
          </div>
          <div className="rBoxGap">
            {!this.state.showCreateForm &&
            this.isPlanViewMode() === false && !this.props.disableFieldsForGuestRole &&
            !this.state.isServiceDesk ? (
              <div>
                <Form.Group className="form-group">
                  <Form.Label>
                    <span className="rStar"></span>{" "}
                    {this.props.translator["Plan Type"]}
                  </Form.Label>
                  <Form.Select
                    className={this.state.planTypeValidationError}
                    onChange={this.getEditPlanType.bind(this)}
                    value={planTypeDetails}
                    disabled="true"
                  >
                    {this.renderPlanType(this.props.planType)}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>
                    <span className="rStar"></span>{" "}
                    {this.props.translator["Plan"]}
                  </Form.Label>
                  <Form.Control
                    className={this.state.planDetailsValidationError}
                    as="textarea"
                    rows="2"
                    maxLength="2000"
                    onChange={this.getEditPlan.bind(this)}
                    value={this.state.planDetails}
                  ></Form.Control>
                </Form.Group>
                <Dropzone
                  onDrop={(files) => this.onDrop(files)}
                  onDropRejected={(files) => this.onDropRejected(files)}
                  maxSize={1048576 * attachmentSize}
                  accept={attachmentType}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      className="text-c cursorPoint margin-t-5 margin-b-5 grayBg"
                      style={{
                        width: "100%",
                        height: "60px",
                        "padding-top": "20px",
                        border: "1px solid black",
                        "border-top-color": "#bdc4c9",
                        "border-right-color": "#bdc4c9",
                        "border-bottom-color": "#bdc4c9",
                        "border-left-color": "#bdc4c9",
                      }}
                    >
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>
                          {
                            this.props.translator[
                              "Drop files or click here to upload"
                            ]
                          }
                          {this.props.translator["(max size"]} {attachmentSize}
                          {this.props.translator["MB)"]}
                        </p>
                      </div>
                    </div>
                  )}
                </Dropzone>
                {this.state.showfilepreview ? (
                  <div className="margin-t-20">
                    <div className="border-b padding-b-5 margin-b-10 black f-size-16 fw-400">
                      {this.props.implementationPlanDetails.filename}
                    </div>
                    <ListGroup bsPrefix=" " bsClass="" className="attachment-break">
                      {this.state.filesPreview}
                    </ListGroup>
                  </div>
                ) : (
                  ""
                )}
                <ButtonToolbar className="margin-t-10">
                  <Button
                    variant="danger"
                    onClick={() => {
                      this.cancelEdit();
                    }}
                  >
                    {this.props.translator["Cancel"]}
                  </Button>
                  <Button
                    bsStyle="primary"
                    onClick={() => {
                      this.updateForm(
                        this.props.implementationPlanDetails.attachmentId
                      );
                    }}
                  >
                    {this.props.translator["Update"]}
                  </Button>
                </ButtonToolbar>
              </div>
            ) : (
              ""
            )}
            <font color="green">
              <b>{this.state.savedImplPlanMessage}</b>
            </font>
            {this.state.savedImplPlanMessage == "" ||
            (this.isPlanViewMode() === true && this.props.disableFieldsForGuestRole &&
              !this.state.isServiceDesk) ? null : (
              <ButtonToolbar className="margin-t-10">
                <Button
                  bsSize="small"
                  bsStyle="primary"
                  onClick={() => {
                    this.uploadNewPlan();
                  }}
                >
                  {this.props.translator["New Plan"]}
                </Button>
              </ButtonToolbar>
            )}
            <div className="border-b padding-b-5 margin-b-10 black f-size-16 fw-400">
              {this.props.translator["Attachments"]}
            </div>
            {this.state.showView ? (
              <div>
                <Form.Group className="form-group">
                  <Form.Label> {this.props.translator["Plan Type"]}</Form.Label>
                  <Form.Select value={planTypeDetails} disabled="true">
                    {this.renderPlanType(this.props.planType)}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>{this.props.translator["Plan"]}</Form.Label>
                  <Form.Control
                    as="textarea"
                    readOnly
                    rows="2"
                    value={this.state.planDetailsView}
                  ></Form.Control>
                </Form.Group>
                {
                  <div className="margin-t-20">
                    <div className="border-b padding-b-5 margin-b-10 black f-size-16 fw-400">
                      <p>{this.props.translator["File Name"]}</p>
                      {this.props.implementationPlanDetails.filename}
                    </div>
                    <ListGroup bsPrefix=" " bsClass="" className="attachment-break">
                      {this.state.filesPreview}
                    </ListGroup>
                    <Button
                      variant="danger"
                      onClick={() => {
                        this.cancelView();
                      }}
                    >
                      {this.props.translator["Cancel"]}
                    </Button>
                  </div>
                }
              </div>
            ) : (
              ""
            )}
            <Table
              responsive
              striped
              bordered
              condensed
              hover
              className="f-size-12"
            >
              <thead>
                <tr className="f-size-12">
                  <th>{this.props.translator["Plan Type"]}</th>
                  <th>{this.props.translator["File Name"]}</th>
                  <th>{this.props.translator["Created By"]}</th>
                  <th>{this.props.translator["Action"]}</th>
                </tr>
              </thead>
              <tbody>
                {this.renderImplementationPlan(
                  this.props.implememtationPlanList
                )}
              </tbody>
            </Table>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = ({
  lovForPlanType,
  editChangeInitialData,
  implememtationPlanList,
  implementationPlanDetails,
}) => {
  let planType =
    lovForPlanType == "undefined" || lovForPlanType.length == 0
      ? []
      : lovForPlanType;
  let editChangeData = editChangeInitialData;
  return {
    planType: planType,
    editChangeData: editChangeInitialData,
    implememtationPlanList,
    implementationPlanDetails,
  };
};

export default connect(mapStateToProps, {
  getLovValues,
  loadChangeEditData,
  loadBreakFixAuditLogDetails,
  loadImplPlanList,
  loadImplementationPlan,
  loadStatusBasedHamburgerOptions,
  loadTimelineData,
})(ProvideAttachmentsTab);
