
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
/**
@Description: routes for
**/

import axios from 'axios';
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
import Cookies from "universal-cookie";
const cookie = new Cookies();
let dateformat = cookie.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL }  from '../../components/Globals';
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
export function loadBreakFixAuditLogDetailsSuccess(breakFixAuditLogDetails){
	//alert('in success')
	return {
		type: 'LOAD_BREAKFIXAUDITLOGDETAILS_SUCCESS',
		breakFixAuditLogDetails
	};
}

export function loadBreakFixAuditLogDetailsFailure(errorResponse){
	return {
		type: 'LOAD_BREAKFIXAUDITLOGDETAILS_FAILURE',
		errorResponse
	};
}

export function loadBreakFixAuditLogDetails(breakFixId,orderType,module,field){
	//alert('action called and parameter is '+breakFixId)
	let url = '';
	return(dispatch) =>{
		//alert("EditGroupIdAction123"+breakFixId);
		dispatch({type: "LOADER_IN_PROGRESS"});
		dispatch({type:'FETCHING_AUDIT_FOR_TIMELINE'});
		if(breakFixId == undefined || breakFixId == ""){
			//alert("H2 breakFixId not found");
		}else{
				//alert("my url is "+GLOBAL.breakFixEditUrl+breakFixId);
				if(field == 'STATUS') {
					url = GLOBAL.breakFixAuditLogUrl+"?itemId="+breakFixId+"&orderBy="+orderType+"&module="+module+"&auditField=STATUS";
				}else {
					url = GLOBAL.breakFixAuditLogUrl+"?itemId="+breakFixId+"&orderBy="+orderType+"&module="+module;
				}
				api.get(url)
					.then((breakFixAuditLogDetails)=>{
						if(!breakFixAuditLogDetails){//alert('not breakFix')
							//dispatch(loadBreakFixFailure(breakFix.statusText))
							dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
							throw Error(breakFixAuditLogDetails.statusText);
						}
						return breakFixAuditLogDetails;
					})
					.then(breakFixAuditLogDetails=>{
					dispatch({type:'FETCHED_AUDIT_FOR_TIMELINE'});
						dispatch(loadBreakFixAuditLogDetailsSuccess(breakFixAuditLogDetails))
						dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
					})
					.catch(err => {
						console.log(err);
					});
					//console.log("not calling loadBreakFixEditDetails");
		}
	};
}

