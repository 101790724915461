
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react';
import { Table,Form,ButtonToolbar,Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TableComponent from '../../../../common/ReactTable/TableComponent';
import { Field, change, reduxForm } from 'redux-form';
import {TypeaheadandDropdown} from '../../../../common/formFields';
import { useDispatch, useSelector } from 'react-redux';
import { disassociateRolesRow, getAssociateRolesValues } from '../../../../../actions/foundation/groupAction';
import axios from "axios";
import Swal from 'sweetalert2';
import { usePreviousValue } from '../../../../../helpers/usePreviousValue';
import { getCookiesValuesByPositions } from "../../../../common/helper";

const GroupList = (props) => {
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [assoRoleValues, setAssoRoleValues] = useState([]);
    const [typeError, setTypeError] = useState("");
    const [rolesList, setRolesList] = useState([]);
    const [show, setShow] = useState(false);
    const tr = useSelector((state) => state.spcmReducer.tr);
    const assoRoleDropdownList = useSelector((state) => state.associateRoleValues);
    const disassociateRolesList = useSelector((state) => state.disassociatedRoles);
    const { value1: user_roleId } = getCookiesValuesByPositions([33]);
    const previousValue = usePreviousValue(props)
    const dispatch = useDispatch();
    let groupid = props.id;

    useEffect(() => {
            dispatch(getAssociateRolesValues(groupid));
    }, [groupid])
    
  const onRolesSelection = (selectedRoles) => {
        let error = '';
        if (selectedRoles.length === 0) {
            error = 'error';
        }
        let arr = [];
        setSelectedRoles(selectedRoles);
        selectedRoles.map((val) => {
            arr.push(val.label)
        })  
        dispatch(change('AssociateGroupRoles', 'member_name', selectedRoles));
        setTypeError("")
    }

    const onCrossClickRoles = () => {
        setSelectedRoles([]);
  }
  
      useEffect(() => {
      if(user_roleId && user_roleId !== ''){
      let user_roleIdArr = user_roleId.split(","); 
          if(user_roleIdArr.includes('1')){
          setShow(true);
          } else if(user_roleIdArr?.includes('72') && !user_roleIdArr?.includes('1')){
            setShow(false);
          }
      }
      }, [user_roleId])
  useEffect(() =>
  {

    if(assoRoleDropdownList && assoRoleDropdownList.length > 0)
    {
      setRolesList(assoRoleDropdownList);
    }
    
  }, [assoRoleDropdownList])

    useEffect(() => {
      if ((groupid && groupid !== previousValue?.groupid) || (disassociateRolesList == 'Role Deleted')) {
        axios.get("/api/viewGroupRolesAssociated/"+groupid)
          .then((resp) => {
              setAssoRoleValues(resp.data);
            dispatch({ type: 'ASSOCIATED_ROLES_DELETED', payload: '' });
            dispatch(getAssociateRolesValues(groupid));
          })
      }
  }, [groupid, disassociateRolesList])

    const saveAssociateRoles = () => {
        if (selectedRoles.length === 0) {
          setTypeError("error");
          return;
      }
      let roles = []
      let payload = {}
      selectedRoles.forEach((values) => {
        let val = {
          role_id: parseInt(values.id, 10),
          group_id: parseInt(groupid, 10)
        }
        roles.push(val);
      })
      payload = {
        groupRoleList: roles
      }

          axios.post("/api/saveAssociatedGroupRolesViaAjax/", payload)
            .then((response) => {
              // console.log("Success Response",response.data);
                axios.get("/api/viewGroupRolesAssociated/"+groupid)
                  .then((resp) => {
                      if (resp.status == 200) {
                        setSelectedRoles([]);
                      }
                      setAssoRoleValues(resp.data);
              }).catch(err => {
                console.error(err);
              });
              dispatch(getAssociateRolesValues(groupid));
            })
    };
    const CellName = (props) => {
        let item = props.row;
        return (
        <div className="text-c">
            <i title="Disassociate" onClick={()=>{
            disassociateRoles(item)
            }} className="fa fa-chain-broken"></i>
        </div>
        )
    };

    const disassociateRoles = (item) => {
        let roles = []
        let payload = {}
        let val = {
          role_id: parseInt(item.role_id, 10),
          group_id: parseInt(groupid, 10)
        }
        roles.push(val);
    
      payload = {
        groupRoleList: roles
      }
        Swal.fire({
            title: ["Are you sure you want to disassociate this role?"],
            width: 550,
            padding: '15px',
            showDenyButton: true,
            confirmButtonText: tr['Yes'],
            denyButtonText: tr['No'],
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(disassociateRolesRow(payload));
              dispatch(getAssociateRolesValues(groupid));
            } 
          })
    }


    let { handleSubmit } = props;
    const onRowSelect = () => {
        
    }
  const tableColumns = [
        {
            name: tr["ID"],
            selector: (row) => row.role_id,
            sortable: true,
        },
        {
            name: tr["Name"],
            sortable: true,
            cell: (row) => (<div title={row.name}>{row.name}</div>)
         },
        {
            name: tr["Description"],
            sortable: true,
            cell: (row) => (<div title={row.description}>{row.description}</div>)
        },
        {
			name: tr["Disassociate"],
			sortable: false,
			cell: (row) => (
			  <CellName row={row} 
			  />
			),
			ignoreRowClick: true,
		}
    ];
    const filterColumns = ["role_id","name","active"];
    return (
        <>
                 {show && <><Form.Group className="form-group">
                    <Form.Label>{tr["Role Name"]}</Form.Label>
                    <Field
                    name="member_name"
                    className="form-control"
                    component={TypeaheadandDropdown}
                    onSelection={onRolesSelection}
                    options={rolesList}
                    selectedOptions={selectedRoles}
                    onCrossClick={onCrossClickRoles}
                    errorClass={typeError === "error" ? "error" : ""}
                    //   setErrorColor={props.setCompanyErrorColor}
                    multiple={true}
            />
                </Form.Group>

                <ButtonToolbar className="black margin-t-5">
                <Button className="rgSidrkBtn smallBtn"
                onClick={handleSubmit(saveAssociateRoles)}>
                        {["Add"]}
                    </Button>
                </ButtonToolbar></>}

                <div className="catBordDv leftSeardv integrateHubTable">
            {assoRoleValues && <div className='f-size-16 margin-t-20 margin-b-10 border-b padding-b-5'>
                {["Associated Roles"]}
            </div>}
                <TableComponent
                data={Array.isArray(assoRoleValues)? assoRoleValues :[]}
                columns={tableColumns}
                headerColumns={filterColumns}
                onRowSelect={onRowSelect}
                uniqueKey={'role_id'}
                paginationText = {"Show"}
                />
                </div>
        </>
    )
}


export default reduxForm({
    form: 'AssociateGroupRoles',
    destroyOnUnmount: false,
    enableReinitialize: true
})(GroupList);