/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, {useEffect, useState} from 'react';
import { Typeahead } from "react-bootstrap-typeahead";
import { useSelector } from 'react-redux';
import { arraysEqual, areStringsEqual } from '../../../common/helper';

const TypeAheadComponent = ({handleOnChange,value,fieldData,drpoDownData,maximumElemantsSelected}) => {

    const [selectedValue, setSelectedValue] = useState([]);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const translator = useSelector(state => state.spcmReducer.tr);
    const [fieldName, setFieldName] = useState('');

    const handleValueChange = (value) => { 
      const filteredObjectList = value.map(arrayItem => drpoDownData.find(item => item.id == arrayItem || areStringsEqual(item.label.toLowerCase(), arrayItem.toLowerCase())));
      const extractedObjectList = filteredObjectList.filter(item => item !== undefined);
      if(extractedObjectList?.length >0){
        const idList = fieldData?.isTextEnabled ? extractedObjectList.map(item => item.label) : extractedObjectList.map(item => item.id);
        const uniqueIDList = [...new Set(idList)];
        handleOnChange(uniqueIDList);
        setSelectedIDs(uniqueIDList);
        setSelectedValue(extractedObjectList);
      }else{
        resetValue();
      }
    }

    const resetValue = () => { 
      handleOnChange([]);
      setSelectedIDs([]);
      setSelectedValue([]);
    }

    useEffect(() => { 
      if(value != '' && value != null && value != undefined && value.length > 0 && !arraysEqual(value, selectedIDs)){
        handleValueChange(value);
      }
      setFieldName(fieldData.name);
    },[]);

    useEffect(() => { 
      if(fieldName !== fieldData.name && (value === '' || value?.length === 0)){
        setFieldName(fieldData.name);
        resetValue();
      }
    }, [fieldData]);
    
    const onSelectionAction = (selectedValue) => {
        if (selectedValue.length > 0) {
          if(selectedValue.length <= maximumElemantsSelected) {
            const idList = fieldData?.isTextEnabled ? selectedValue.map(item => item.label) : selectedValue.map(item => item.id);
            const uniqueIDList = [...new Set(idList)];
            handleOnChange(uniqueIDList);
            setSelectedIDs(uniqueIDList);
            setSelectedValue(selectedValue);
          }
        } else {
            resetValue();
        }
    };

    const onInputChange = (typedText) =>{
      
    }

    const onCrossClick = () => {
      resetValue();
    }

    return (<div className='typeaheadInput form-group notifydvTypehd'>
        {selectedValue.length > 0 && (
        <span title={translator['Clear']} role="img" aria-label="Clear" className="css-hakgx8 icn top-0" onClick={onCrossClick}>
          <svg width="18" height="18" viewBox="0 0 24 24" role="presentation">
            <path
              d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
              fill="gray"></path>
          </svg>
        </span>
      )}
        <Typeahead id="values" options={drpoDownData} placeholder={translator['Please Select an Option']} selected={selectedValue} onChange={onSelectionAction} onInputChange={onInputChange} multiple emptyLabel={translator['No options available']}/>
      </div>);
}

export default TypeAheadComponent;
