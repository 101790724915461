/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Popover, PopoverActionsBar } from "@progress/kendo-react-tooltip";
import AiIcon from "./aiIcon";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Link } from "react-router-dom";
import { TbAlertTriangle, TbBlockquote, TbBriefcase, TbBulb, TbChevronLeft, TbChevronRight, TbCopy, TbFileTextAi, TbHeartHandshake, TbMicrophone, TbMoodEmpty, TbMoodSmile, TbPencilCog, TbReload, TbTextSpellcheck, TbThumbDown, TbThumbUp } from "react-icons/tb";
import { LuBookMarked, LuCornerDownLeft, LuText, LuUpload } from "react-icons/lu";
import { IoClose, IoCopyOutline, IoLanguage, IoSearch } from "react-icons/io5";
import { MdGTranslate } from "react-icons/md";
import ListLoader from "./loaders/ListLoader";
import { CustomDropdownTree } from "./CustomDropdownTree";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import axios from "axios";
import { getCookiesValuesByPositions, sanitizeElements } from "./helper";
import Swal from "sweetalert2";
import { FaLanguage } from "react-icons/fa";

const AiPopup = (props) => {
    const anchor = useRef(null);
    //const [show, setShow] = useState(false);
    const [listening, setListening] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const [alertShow, setAlertShow] = useState(false);
    const [listName, setListName] = useState("");
    const [activeMenu, setActiveMenu] = useState(null);
    const [value, setValue] = useState({ id: "en", label: "English" });
    const [responseText, setResponseText] = useState("");
    const [loader,setLoader] = useState(false);
    const divRef = useRef(null);
    const popoverRef = useRef(null);
    const tr = useSelector((state) => state.spcmReducer.tr);
    const {value1:defaultLang} = getCookiesValuesByPositions([20])
    const position = props.position || "left";

    useEffect(() => {
      if (props.show) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [props.show]);

    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".genAiPopdv") &&
        !event.target.classList.contains("k-list-item") &&
        !event.target.classList.contains("k-list-item-text")
      ) {
        props.setShow(false);
      }
    };

    const togglePopup = () => {
        props.setShow(true);
        setIsMenuOpen(true);
        setActiveMenu(null);
    };

    const langList = [
        { id: "en", label: "English" },
        { id: "ar", label: "Arabic" },
        { id: "de", label: "German" },
        { id: "es", label: "Spanish" },
        { id: "fil", label: "Filipino" },
        { id: "fr", label: "French" },
        { id: "hi", label: "Hindi" },
        { id: "id", label: "Indonesian" },
        { id: "it", label: "Italian" },
        { id: "ja", label: "Japanese" },
        { id: "ko", label: "Korean" },
        { id: "ms", label: "Malay" },
        { id: "ta", label: "Tamil" },
        { id: "th", label: "Thai" },
        { id: "vi", label: "Vietnamese" },
        { id: "zhcn", label: "Chinese Simplified" },
    ];

    useEffect(()=>{
      const defaultLangArr = langList.filter((item)=>item?.id == defaultLang);
      if(Array.isArray(defaultLangArr) && defaultLangArr.length>0){
        setValue(defaultLangArr[0]);
      }
    },[])

    const list = [
      { icon: <TbBlockquote />, label: tr['Summarize'], actionValue: "SUMMARIZER" },
      { icon: <TbPencilCog />, label: tr["Improve writing"], actionValue: "IMPROVEWRITING" },
      { icon: <TbTextSpellcheck />, label: tr["Fix spelling"], actionValue: "SPELLCHECKER" },
      { icon: <LuText />, label: tr["Provide Solution"], actionValue: "BRAINSTORMER" },
      //{ icon: <LuText />, label: "Make shorter", actionValue: "BRAINSTORMER" },
      {
        icon: <TbFileTextAi />,
        label: tr["Change tone"],
        child: [
          { icon: <TbBriefcase />, label: tr["Professional"], actionValue: "PROFESSIONALTONE" },
          { icon: <TbHeartHandshake />, label: tr["Empathetic"], actionValue: "EMPATHETICTONE" },
          ///{ icon: <TbMoodSmile />, label: "Casual", actionValue: "" },
          { icon: <TbMoodEmpty />, label: tr["Neutral"], actionValue: "NEUTRALTONE" },
          { icon: <LuBookMarked />, label: tr["Educational"], actionValue: "EDUCATIONALTONE" },
        ],
      },
      { icon: <MdGTranslate />, label: tr["Translate"], actionValue:"TRANSLATOR" },
    ];

    const handleChange = (event) => {
        setValue(event.value);
    };

    const copyDivText = () => {
      const text = divRef.current.innerText;
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      Swal.fire({
        position: "top-end",
        title: "The text has been copied successfully!",
        showConfirmButton: false,
        timer: 1500
      });
      togglePopup();
    };

    const apiCall = (tool) => {
      let body = {};
      setLoader(true);
      body.ai_tool = tool;
      if(tool === "TRANSLATOR"){
        body.message = `sourceLanguage: ${defaultLang}, targetLanguage: ${value?.id}, text:${props?.text}`;
      }else{
        body.message = props?.text;
      }
      if (body && Object.keys(body).length > 0) {
        axios
          .post("/api/aiTools",body)
          .then((response) => {
            setLoader(false);
            let data = response.data;
            setResponseText(data?.response);
          })
          .catch((error) => {
            setLoader(false);
            console.log(error?.message);
          });
      }
    };

    const ContentChange = () => {
      return (
        <div>
          {loader ? (
            <div className="aiCntntDvCntent">
              <ListLoader />
            </div>
          ) : (
            <div className="aiCntntDvCntent">
              <p
                dangerouslySetInnerHTML={{
                  __html: sanitizeElements(responseText || props?.text),
                }}
                ref={divRef}
              ></p>
            </div>
          )}
          <div className="aiCntntDvFootr">
              <div className="ftrLeft">
            {activeMenu != null &&
                <Link
                  className="genAiListBack"
                  to="javascript:void(0)"
                  onClick={() => {
                    setActiveMenu(null);
                    setIsMenuOpen(true);
                  }}
                >
                  <TbChevronLeft /> {"Back"}
                </Link>
              }
              </div>
            {!loader && (props?.text || responseText) ? 
              <div className="ftrRight">
                {/* <ListLoader /> */}
                {alertShow ? (
                  <p>
                    <TbAlertTriangle />
                    {tr["Do you want to discard your response?"]}
                  </p>
                ) : (
                  <>
                    <Link
                      to="javascript:void(0)"
                      title={tr["Insert"]}
                      onClick={() => {
                        props?.onChange(
                          responseText
                            .replace(/<pre>/, "")
                            .replace(/<\/pre>/, "") || props?.text
                        );
                        togglePopup();
                      }}
                    >
                      <LuUpload />
                    </Link>
                    {/* <Link to="javascript:void(0)" title="Retry"><TbReload /></Link> */}
                    <Link
                      to="javascript:void(0)"
                      title={tr["Copy"]}
                      onClick={() => copyDivText()}
                    >
                      <TbCopy />
                    </Link>
                  </>
                )}
              </div>
            :
            <div className="ftrRight">
              <Button className="ftrBtn d-none" type="button">
                Cancel<span>Esc</span>
              </Button>
              {alertShow ? (
                <>
                  <Button
                    onClick={() => setAlertShow(false)}
                    className="ftrBtn"
                    type="button"
                  >
                    Continue
                  </Button>
                  <Button
                    onClick={() => {
                      setIsMenuOpen(true);
                      setAlertShow(false);
                      setActiveMenu(null);
                    }}
                    className="ftrBtn"
                    type="button"
                  >
                    Discard
                  </Button>
                </>
              ) : (
                <>
                  {/* <Link to="javascript:void(0)" title="Like">
                    <TbThumbUp />
                  </Link>
                  <Link to="javascript:void(0)" title="Dislike">
                    <TbThumbDown />
                  </Link> */}
                </>
              )}
            </div>
            }
          </div>
        </div>
      );
    };

    return (
      <>
        <Button
          title={tr["Writing Assistant"]}
          className="genAiIcon"
          onClick={togglePopup}
          disabled={props.show || props?.disabled}
          ref={anchor}
          type="button"
        >
          <AiIcon />
        </Button>
        <Popover
          ref={popoverRef}
          appendTo={props.containerRef}
          show={props.show}
          anchor={anchor.current && anchor.current.element}
          position={position}
          className={"genAiPopdv"}
          callout={false}
          animate={false}
          title={
            <>
              <div className="genAiHd">
                <h4>
                  <AiIcon />
                  {tr["Writing Assistant"]}
                </h4>
                <Button
                  className="margin-l-5"
                  onClick={() => {
                    props.setShow(false);
                    setAlertShow(false);
                  }}
                  fillMode={"flat"}
                  title="Close"
                  type="button"
                >
                  <IoClose size={16} />
                </Button>
              </div>
              <div className="genAiHd2">
                <h2>
                  {isMenuOpen ? (
                    tr["How can I assist you?"]
                  ) : (
                    <>
                      {listName}
                      {!alertShow && (
                        <Link
                          to="javascript:void(0)"
                          onClick={() => {
                            setAlertShow(false);
                            setIsMenuOpen(!isMenuOpen);
                            setActiveMenu(null);
                          }}
                          title={tr["Cancel"]}
                        >
                          <IoClose />
                        </Link>
                      )}
                    </>
                  )}
                </h2>
                <ButtonGroup>
                  <Button togglable={false} type="button" disabled selected>
                    {<IoLanguage />}
                  </Button>
                  <DropDownList
                    className="kendoDropDownList"
                    data={langList}
                    onChange={handleChange}
                    value={value}
                    valueRender={() => value.label}
                    dataItemKey="id"
                    textField="label"
                    defaultValue={value.id}
                    popupSettings={{
                      appendTo: document.getElementById("skipdv"),
                      popupClass: "kendoDropdownPopup",
                      popupAlign: { horizontal: "right", vertical: "top" },
                      anchorAlign: { horizontal: "right", vertical: "bottom" },
                    }}
                  />
                </ButtonGroup>
              </div>
            </>
          }
        >
          {!isMenuOpen && <ContentChange />}
          {!alertShow && (
            <div className="genAiList">
              {activeMenu === null ? (
                list.map((item, index) => (
                  <Link
                    key={index}
                    onClick={() => {
                      if (item.child) {
                        setActiveMenu(item);
                        setListName(item.label);
                        setIsMenuOpen(false);
                      } else if (isMenuOpen === true) {
                        setIsMenuOpen(!isMenuOpen);
                        setListName(item.label);
                        apiCall(item?.actionValue);
                      } else {
                        setListName(item.label);
                        //setAlertShow(false)
                        apiCall(item?.actionValue);
                      }
                    }}
                    title={item.label}
                    to="javascript:void(0)"
                  >
                    <span>
                      {item.icon}{" "}
                      <span className="genAiListHd">{item.label}</span>
                      {item.child?.length > 0 ? <TbChevronRight /> : null}
                    </span>
                  </Link>
                ))
              ) : (
                <>
                  {activeMenu.child.map((item, index) => (
                    <Link
                      key={index}
                      onClick={() => {
                        if (isMenuOpen === true) {
                          setIsMenuOpen(!isMenuOpen);
                        } else {
                          // setAlertShow(true)
                        }
                        setListName(activeMenu?.label + " " + (tr["to"]) + " " + item.label);
                        apiCall(item?.actionValue);
                        //setActiveMenu(null)
                      }}
                      to="javascript:void(0)"
                      title={item.label}
                    >
                      <span>
                        {item.icon}{" "}
                        <span className="genAiListHd">{item.label}</span>
                      </span>
                    </Link>
                  ))}
                </>
              )}
            </div>
          )}
          {/* <PopoverActionsBar>
                    <div className="genAiSearch">
                        <Input
                            type="text"
                            placeholder="Provide your instructions here"
                        />
                        <Link to="javascript:void(0)" title="Like"><TbMicrophone /></Link>
                    </div>
                </PopoverActionsBar> */}
        </Popover>
      </>
    );
};

export default AiPopup;
