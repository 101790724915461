
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { useEditor } from "@grapesjs/react";
import Button from "@progress/kendo-react-buttons";
import { ColorPicker, Checkbox, TextBox, TextArea } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { cx, getFormNamesFields, traitInstructions } from '../utils';
import React, { useEffect, useState } from "react";

export default function TraitPropertyField({ trait, updateDataSourceKeys, ...rest }) {
    const editor = useEditor();
    const [error, setError] = useState(false);
    useEffect(() => {
      if (name === trait.getName()) {
        const forms = getFormNamesFields(rest?.pageJson);
        if (forms.filter((res) => res === trait.getValue()).length > 1) {
          setError(true);
        } else {
          setError(false);
        }
      }
    }, [trait.getValue()]);

    const handleChange = (value) => {
        trait.setValue(value);
    };

    const onChange = (ev) => {
        handleChange(ev.target.value);
        if(trait.getName() === 'data_source'){
            updateDataSourceKeys(ev.target.value.id)
        }
    };

    const handleButtonClick = () => {
        const command = trait.get('command');
        if (command) {
            typeof command === 'string' ? editor.runCommand(command) : command(editor, trait);
        }
    }

    const getOptions = (options) => {
        return options.map(option => {
            return {
                id: trait.getOptionId(option),
                text: trait.getOptionLabel(option)
            }
        })
    } 

    const type = trait.getType();
    const defValue = trait.getDefault() || trait.attributes.placeholder;
    const value = trait.getValue();
    const name= trait.getName();

    let inputToRender = (
       <><TextBox className={`${error?"error":""}`} name={name} placeholder={defValue} value={value} onChange={onChange} size="small"></TextBox>{traitInstructions[name]?<div className="small">{traitInstructions[name]}</div>:null}</>
    )
    switch (type) {
        case 'select': {
            inputToRender = (
                <>
                <DropDownList
                    name={name}
                    textField="text"
                    dataItemKey="id" 
                    value={value} 
                    onChange={onChange} 
                    data={[{text:"",id:""},...getOptions(trait.getOptions())]}
                ></DropDownList>
                {traitInstructions[name]?<div className="small">{traitInstructions[name]}</div>:null}
                </>
            )
        } break;
        case 'color': {
            inputToRender = (
                <ColorPicker name={name} value={value} onChange={onChange} view="palette"></ColorPicker>
            )
        } break;
        case 'checkbox': {
            
            inputToRender = (
              <><Checkbox
                name={name}
                checked={value}
                onChange={(ev) => { trait.setValue(ev.target.value)}}
                size="small"
              />
              {traitInstructions[name]?<div className="small">{traitInstructions[name]}</div>:null}
              </>
            );
        } break;
        case 'textarea':
        case 'select-options':{
            inputToRender = (
             <> <TextArea
                name={name}
                value={value}
                onChange={(ev) => { trait.setValue(ev.target.value)}}
                size="small"
              />
              {traitInstructions[name]?<div className="small">{traitInstructions[name]}</div>:null}
              </>
            );
        } break;
        case 'button': {
            inputToRender = (
                <Button onClick={handleButtonClick}>
                    {trait.getLabel()}
                </Button>
            )
        } break;
    }

    return (
        <div {...rest} className="margin-b-10">
            <div className={cx('flex items-center')}>
                <div className="flex-grow capitalize">{ trait.getLabel() }</div>
            </div>
            { inputToRender }
        </div>
    );
}