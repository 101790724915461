/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
export const unorderedListComponent = {
  name: "UnorderedList",
  traits: [
    {
      type: "text",
      label: "Title",
      name: "iconTitle",
      placeholder: "Title",
      changeProp: 1,
    },
  ],
};

export const unorderedListAdd = {
  model: {
    defaults: {
      tagName: "ul",
      draggable: true,
      attributes: { class: "ui-builder-unorderedlist" },
      components: [
        {
          tagName: "li",
          attributes: { class: "" },
          components: [{ tagName: "span", content: "Item 1" }],
          draggable: true,
          droppable: true,
          editable: true,
        },
        {
          tagName: "li",
          attributes: { class: "" },
          components: [{ tagName: "span", content: "Item 2" }],
          draggable: true,
          droppable: true,
          editable: true,
        },
      ],
      traits: unorderedListComponent.traits,
      editable: true,
    },
    init() {},
  },
};

export const addunorderedListComponent = (e) => {
  e.DomComponents.addType(unorderedListComponent.name, unorderedListAdd);
};

export const unorderedListBlockJson = {
  id: "unorderedList",
  label: "Unordered List",
  media: `<svg fill="#808080" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" class="icon"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M912 192H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM104 228a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm0 284a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm0 284a56 56 0 1 0 112 0 56 56 0 1 0-112 0z"></path> </g></svg>`,
  category: "Extra",
  content: {
    type: unorderedListComponent.name,
  },
};
