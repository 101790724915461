
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setWipSearchApply } from "../../../../../actions/globalSearch/globalSearchAction";

import Cookies from "universal-cookie";
import { replaceVariables } from "../../../studio/utils";
const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

const BuilderBreadcrumbs = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setSearchParam = () => {
    dispatch(setWipSearchApply(false));
  };
  const tr = useSelector((state) => state.spcmReducer.tr);
  const breadcrumbs = props.breadcrumbs?props.breadcrumbs.split(","):[];
  const links = props.links?props.links.split(","):[];
  return (
    <Breadcrumb {...props?.attributes}>
      {/* <Breadcrumb.Item href={void(0)} onClick={()=>{navigate(homepagelocation); setSearchParam();}}>{tr["Home"]}</Breadcrumb.Item> */}
      {breadcrumbs.length > 0 &&
        breadcrumbs.map((res, index) => {
          return (
            <Breadcrumb.Item
              href={void 0}
              onClick={() => {
                if (index === 0) {
                  navigate(homepagelocation);
                } else {
                  navigate(links[index]);
                }
              }}
              key={"breadcrumb-" + index}
              active={breadcrumbs.length - 1 == index ? true : false}
            >
              {replaceVariables(res, props?.defaultValues)}
            </Breadcrumb.Item>
          );
        })}
    </Breadcrumb>
  );
};

export default BuilderBreadcrumbs;
