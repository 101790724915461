
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from "react";
import { Table, Form } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { IoSearch } from "react-icons/io5";
import ListLoader from "_Commons/loaders/ListLoader";
import TableComponent from '_Commons/ReactTable/TableComponent';
import moment from 'moment';
import { myViewNotificationAction } from "../../../actions/spcmActions";
import { DateRangePicker } from 'react-bootstrap-daterangepicker';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
let dateformatCookie = homepagelocation[2];
let result = dateformatCookie.substr(0, 10);

const MyList = (props) => {
  const [isOpen, setIsOpen] = useState([]);
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const myViewNotificationList = useSelector((state) => state.spcmReducer.myViewNotificationList);
  const commonLoader = useSelector((state) => state.commonLoader.isLoading);
  const [requestIDSearchFilter, setRequestIDSearchFilter] = useState('');
  const [orderNumberSearchFilter, setOrderNumberSearchFilter] = useState('');
  const [notificationSearchFilter, setNotificationSearchFilter] = useState('');
  const [sort, setSort] = useState(true);
  const [faCustomDesc1, setFaCustomDesc1] = useState("customArrow dropdown");
  const [faCustomAsc1, setFaCustomAsc1] = useState("customArrow dropup");

  useEffect(() => {
    if (myViewNotificationList && myViewNotificationList.length > 0) {
        const updatedList = myViewNotificationList.map(obj => {
            return {
                ...obj,
                status: obj.status === '1' ? "Active" : "Inactive"
            };
        });
        setIsOpen(updatedList);
    }
    if (
      myViewNotificationList &&
      myViewNotificationList.data &&
      props.searchParams &&
      Object.keys(props.searchParams).length > 0
    ) {
      if (props.locationState) {
        let selectedRecord = myViewNotificationList.data
          ?.map((item, index) => ({ item, index }))
          ?.filter(({ item }) => item.MESSAGE_ID === props.locationState.msgId);
        if (selectedRecord.length > 0) {
          onRowSelect("", selectedRecord[0].item, selectedRecord[0].index);
        }
      }
    }
}, [myViewNotificationList]);

const onSortAsc = (sort, sortValue, sortType) => {
  console.log("sort",sort)
  console.log("sortValue",sortValue)
  console.log("sortType",sortType)
  let sortOrder = "";
  
  setSort(!sort);
  sortOrder = sortType;
  // this.props.getServiceDetailsDataList(this.props.searchParams, this.props.status, this.props.category, this.props.featured, this.props.workItemType, this.props.modifiedDateFilterValues, sortOrder, sortValue, this.props.page, this.props.size)

  dispatch(myViewNotificationAction(props.email_address, props.tab, props.modifiedDateFilterValues, props.searchParams, props.page, props.size, sortOrder));

  props.setSortOrderHandler(sortOrder, sortValue);

  switch (sortValue) {
    case "Notification module":
      sortOrder == "ASC" 
      ? setFaCustomDesc1("customArrow dropdown") 
      : (sortOrder == "DESC" 
        ? setFaCustomDesc1("customArrow dropdown isActive") 
        : setFaCustomDesc1(""));


        sortOrder == "ASC" 
        ? setFaCustomAsc1("customArrow dropup isActive") 
        : sortOrder == "DESC" 
          ? setFaCustomAsc1("customArrow dropup") 
          : setFaCustomAsc1("");
      break;
  }
}
  const onRowSelect = (e, row, index) => {
      props.notificationListHandler(row);
      props.showRightSideFun(true);
      props.showActiveRow(index);

  }
  const rowDateHandler = (row) => {
    return (
      props.dateFormat == "dd-MM-yyyy HH:mm:ss" ? moment(row).format("DD-MM-YYYY HH:mm:ss") :      
      props.dateFormat == "yyyy-MM-dd HH:mm:ss" ? moment(row).format("YYYY-MM-DD HH:mm:ss") :
        moment(row).format("MM-DD-YYYY HH:mm:ss")
    )
  }
  
  const onPageChange = (e) => {
    props.showRightSideFun(false);
    props.setPage(e);
   
    dispatch(myViewNotificationAction(props.email_address, props.tab, props.modifiedDateFilterValues, props.searchParams,e,props.size,props.sortOrder,"true"));

    // dispatch(getNotificationDataListApi(props.modifiedDateFilterValues, props.type, props.locale, props.module, props.status, props.searchParams, e, props.size));
  }

  const onPageSizeChange = (e) => {
    props.showRightSideFun(false);
    props.setLimit(e.target.value);

    dispatch(myViewNotificationAction(props.email_address, props.tab, props.modifiedDateFilterValues, props.searchParams,props.page,e.target.value,props.sortOrder,"true"));

    // dispatch(getNotificationDataListApi(props.modifiedDateFilterValues, props.type, props.locale, props.module, props.status, props.searchParams, props.page, e.target.value));
  }



  const handleSearch = (e, picker) => {
    console.log("props.modifiedStartDate",props.modifiedStartDate)
    console.log("props.modifiedEndDate",props.modifiedEndDate)
    let dateArr = [];
    if (picker) {
      props.setModifiedDateRef(picker);
      // dateArr.push(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"), moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
      dateArr.push(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"), moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
    }

    if (picker == undefined && !_.isEmpty(props.modifiedStartDate) && !_.isEmpty(props.modifiedEndDate)) {
      // dateArr.push(moment(props.modifiedStartDate).format("YYYY-MM-DD HH:mm:ss"), moment(props.modifiedEndDate).format("YYYY-MM-DD HH:mm:ss"));
      dateArr.push(moment(props.modifiedStartDate).format("YYYY-MM-DD HH:mm:ss"), moment(props.modifiedEndDate).format("YYYY-MM-DD HH:mm:ss"));
    }
    props.setSelectedDate(dateArr);
    props.isClearAllFilterFunc(true);
    // dispatch(getNotificationDataListApi(dateArr, props.type, props.locale, props.module, props.status, props.searchParams, props.page, props.size));
    dispatch(myViewNotificationAction(props.email_address, props.tab, dateArr, props.searchParams,props.page,props.size));

  }
 
  const handleCallback = (filter, start, end) => {
    let modifiedDateFilterValue = [];
    switch (filter) {
      case 'createdOn':
        modifiedDateFilterValue.push(start.format("YYYY-MM-DD HH:mm:ss"), end.format("YYYY-MM-DD HH:mm:ss"));
        props.setModifiedStartAndEndDate(start.format('MM/DD/YYYY HH:mm:ss'), end.format('MM/DD/YYYY HH:mm:ss'), modifiedDateFilterValue);
        break;
    }
  }
  const handleSearchNotification = () => {
    let searchParamsObj = {};

    props.showRightSideFun(false);
    props.isClearAllFilterFunc(true);
    if (props.companySearchInput !== '') {
      searchParamsObj.requestNumber = props.companySearchInput.trim()
    }
    if (props.orderNumberSearchInput !== '') {
      searchParamsObj.orderNumber = props.orderNumberSearchInput.trim()
    }
    if (props.nameSearchInput !== '') {
      searchParamsObj.module = props.nameSearchInput.trim()
    }
    props.setSearchParams(searchParamsObj);
    // dispatch(myViewNotificationAction(props.email_address, props.tab, props.modifiedDateFilterValues, searchParamsObj,props.page,props.size));
    dispatch(myViewNotificationAction(props.email_address, props.tab, props.modifiedDateFilterValues, searchParamsObj,props.page,props.size));

    // dispatch(getNotificationDataListApi(props.modifiedDateFilterValues, props.type, props.locale, props.module, props.status, searchParamsObj, props.page, props.size));
  }

  const handleChange = (e, searchValue) => {
    switch (searchValue) {
      case "NAME":
        props.setNameSearchInput(e.target.value);
        setNotificationSearchFilter(searchValue);
        break;
      case "ORDER_NUMBER":
        props.setOrderNumberSearchInput(e.target.value);
        setOrderNumberSearchFilter(searchValue);
        break;
      case "REQUEST_ID":
        props.setCompanySearchInput(e.target.value);
        setRequestIDSearchFilter(searchValue);
        break;
    }
  }

  const renderTable = (data) => {
    return data.data.map((data, index) => {
      return(
        <tr key={index} className={props.isRightSideVisible && index == props.rowIndex ? "myActive" : ""} onClick={(e) => onRowSelect(e, data, index)}>
        <td>{data.REQUEST_NUMBER}</td>
        <td>{data.ORDER_NUMBER}</td>
        <td>{data.MODULE_NAME + " - " + data.EVENT_TYPE}</td>
        <td>{(rowDateHandler(data.CREATED_ON))}</td>
        </tr>
      )
  })
  }

  let myNotificatinData = myViewNotificationList ? myViewNotificationList.data : { data: [] };
  let meta = !_.isEmpty(myViewNotificationList) ? myViewNotificationList.meta : {}; 
  let panelColSize = props.panelResize;
  let myModifiedStartDate;
  let myModifiedEndDate;
  if (result == "yyyy-MM-dd") {
    myModifiedStartDate = !_.isEmpty(props.modifiedStartDate) ? moment(props.modifiedStartDate).format("YYYY/MM/DD") : "";
    myModifiedEndDate = !_.isEmpty(props.modifiedEndDate) ? moment(props.modifiedEndDate).format("YYYY/MM/DD") : "";
  }
  else if (result == "dd-MM-yyyy") {
    myModifiedStartDate = !_.isEmpty(props.modifiedStartDate) ? moment(props.modifiedStartDate).format("DD/MM/YYYY") : "";
    myModifiedEndDate = !_.isEmpty(props.modifiedEndDate) ? moment(props.modifiedEndDate).format("DD/MM/YYYY") : "";
  }
  else {
    myModifiedStartDate = !_.isEmpty(props.modifiedStartDate) ? moment(props.modifiedStartDate).format("MM/DD/YYYY") : "";
    myModifiedEndDate = !_.isEmpty(props.modifiedEndDate) ? moment(props.modifiedEndDate).format("MM/DD/YYYY") : "";
  }
  let myModifiedNewDate = myModifiedStartDate == "" && myModifiedEndDate == "" ? "" : myModifiedStartDate + "-" + myModifiedEndDate;
  const handleKeyPress = (event) => { };
  let ascDscToggle = "";
  ascDscToggle = sort ? "ASC" : "DESC";

  console.log("sortOder",props.sortOrder)
  console.log("myViewNotificationList",myViewNotificationList)

  // console.log('***************', {myNotificatinData, commonLoader});

return (
  <>
    <div aria-label="Table" role="contentinfo" className="respondv">
      <div className="tableRgtBor">
        <Table responsive striped bordered condensed hover className="tableView nowrapWhright" style={{ marginTop: "0px" }}>
          <thead>
            <tr>
              <th>
                <div className="sortParArr">{tr["Request ID"]}</div>
                <div className="colSearDv ">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={props.companySearchInput != "" ? props.companySearchInput : ""}
                      placeholder={tr["Search here"]}
                      onKeyDown={(e) => {
                        if (e.code == "Enter") {
                          if (
                            props.companySearchInput != "" &&
                            props.companySearchInput.trim().length > 3
                          )
                            handleSearchNotification();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "REQUEST_ID");
                      }}
                    />
                    {props.companySearchInput.trim().length > 3 ?
                      <a title="search" className="faicn" href={void (0)} onClick={(event) => handleSearchNotification()}>
                        <IoSearch />
                      </a> : ""}
                  </div>
              </th>
              <th>
                <div className="sortParArr">{tr["Work Item ID"]}</div>
                <div className="colSearDv ">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={props.orderNumberSearchInput != "" ? props.orderNumberSearchInput : ""}
                      placeholder={tr["Search here"]}
                      onKeyDown={(e) => {
                        if (e.code == "Enter") {
                          if (
                            props.orderNumberSearchInput != "" &&
                            props.orderNumberSearchInput.trim().length > 3
                          )
                            handleSearchNotification();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "ORDER_NUMBER");
                      }}
                    />
                    {props.orderNumberSearchInput.trim().length > 3 ?
                      <a title="search" className="faicn" href={void (0)} onClick={(event) => handleSearchNotification()}>
                        <IoSearch />
                      </a> : ""}
                  </div>
              </th>
              <th>
                <div className="sortParArr"
                      onClick={() =>
                        onSortAsc(
                          sort,
                          "Notification module",
                          ascDscToggle                          
                        )
                      }>{tr["Notification"]}
                      <div className="sortArr">
                        {props.sortOrder == "ASC" ? (
                          <span
                            className={faCustomAsc1}
                            title={tr["Ascending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        ) : (
                          <span
                            className={faCustomDesc1}
                            title={tr["Descending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        )}
                      </div>
                    </div>
                <div className="colSearDv ">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={props.nameSearchInput != "" ? props.nameSearchInput : ""}
                      placeholder={tr["Search here"]}
                      onKeyDown={(e) => {
                        if (e.code == "Enter") {
                          if (
                            props.nameSearchInput != "" &&
                            props.nameSearchInput.trim().length > 3
                          )
                            handleSearchNotification();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "NAME");
                      }}
                    />
                    {props.nameSearchInput.trim().length > 3 ?
                      <a title="search" className="faicn" href={void (0)} onClick={(event) => handleSearchNotification()}>
                        <IoSearch />
                      </a> : ""}
                  </div>
              </th>
              <th>
                {/* {props.tab == "direct" ?
                  <div className="sortParArr">{tr["Created On"]}</div>
                  : */}
                  <div className="sortParArr">{tr["Sent On"]}</div>
                {/* } */}
                 <div className="colSearDv" >
                        <DateRangePicker
                          initialSettings={{
                            autoUpdateInput: false,
                            maxDate: moment(),
                            autoApply: true,
                            parentEl:"#skipdv",
                            opens: 'left'
                          }}
                          onApply={(e, picker) => handleSearch(e, picker)}
                          onCallback={(start, end) =>
                            handleCallback("createdOn", start, end)
                          }
                        >
                          <input
                            type="text"
                            value={myModifiedNewDate}
                            placeholder={tr["Search here"]}
                            readOnly
                            className="form-control col-4"
                            onPaste={handleKeyPress}
                            onKeyDown={handleKeyPress}
                          />
                        </DateRangePicker>
                      </div>
              </th>
            </tr>
          </thead>
          <tbody>
      

              {(commonLoader == true) ?
                <tr>
                  <td colSpan="9" style={{ "textAlign": "center" }}><ListLoader /></td>
                </tr> :
                (!myViewNotificationList || myViewNotificationList.length === 0) ?
                  <tr>
                    <td colSpan="9" style={{ "textAlign": "center" }}>{tr['There is no matching data available']}</td>
                  </tr> :
                  renderTable(myViewNotificationList)
              }
          </tbody>
        </Table>

      </div>
      <div className="nBotPagina">
          <div className="nShow">
            <div className="margin-r-10">{tr['Show']}:</div>
            <Form.Select value={props.size} onChange={onPageSizeChange}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </Form.Select>
          </div>

          <nav aria-label='Pagination' className='display-inline-block'>
            <Pagination
              prevPageText={tr['Prev']}
              nextPageText={tr['Next']}
              firstPageText={<i className='glyphicon glyphicon-menu-left' />}
              lastPageText={<i className='glyphicon glyphicon-menu-right' />}
              activePage={meta ? meta.currentPage : 1}
              itemsCountPerPage={props.size}
              totalItemsCount={meta ? meta.rowCount ? meta.rowCount : 1 : 1}
              pageRangeDisplayed={panelColSize <= 42 ? 2 : 5}
              onChange={(e) => onPageChange(e)}
            /></nav>
        </div>
    </div>
  </>
)
}


export default MyList;
