
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import initialState from '../../initialState';

export const on_call_schedule_reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOAD_ALL_SCHEDULE_LIST_SUCCESS':
			return Object.assign({}, state, { allScheduleList: action.payload });
		case 'LOAD_ALL_SCHEDULE_LIST_META':
			return Object.assign({}, state, { allScheduleListMeta: action.payload });
		case 'LOAD_SCHEDULE_DETAILS_BY_SCHEDULE_ID_SUCCESS':
			return Object.assign({}, state, { scheduleDetailsByScheduleId: action.payload });
		case 'EMPTY_SCHEDULE_DETAILS_BY_SCHEDULE_ID_SUCCESS':
			return Object.assign({}, state, { scheduleDetailsByScheduleId: null });
		case 'EMPTY_SCHEDULE_LISTS_SUCCESS':
			return {
					...state,
					allScheduleList: []	
		};
		default:
			return state;
	}
}

export const on_call_schedule_association_reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOAD_SCHEDULE_ASSOCIATION_LIST_SUCCESS':
		return {
				...state,
				scheduleAssociationList: action.payload,
		};
		case 'LOAD_RELATED_SCHEDULE_LIST_SUCCESS':
			return {
				...state,
				relatedScheduleList: action.payload,
		};
		default:
			return state;
	}
}

export const on_call_schedule_loader_reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOAD_SCHEDULE_LIST_LOADER_IN_PROGRESS':
			return Object.assign({}, state, { showScheduleListLoader: true });
		case 'LOAD_SCHEDULE_LIST_LOADER_FINISHED':
			return Object.assign({}, state, { showScheduleListLoader: false });
		case 'LOAD_SCHEDULE_ASSOCIATION_LOADER_IN_PROGRESS':
			return Object.assign({}, state, { showScheduleAssociationLoader: true });
		case 'LOAD_SCHEDULE_ASSOCIATION_LOADER_FINISHED':
			return Object.assign({}, state, { showScheduleAssociationLoader: false });
		default:
			return state;
	}
}
