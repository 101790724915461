
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { Row, Col, Button, InputGroup, Spinner, Tab, Tabs, Form } from "react-bootstrap";
import { Formik, FieldArray, Form as FormikForm } from "formik";
import { getInitialValues, validateRestRequest, transformFunction, getVariables, graphQlQuery, validateGraphQlRequest } from "./utils";
import { LuPlus } from "react-icons/lu";
import { MdDelete } from "react-icons/md";
import "../app.scss";
import { useMutation, useQuery, gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { TypeaheadExampleSingleSelect } from "_Commons/formFields";
import { CreateConnector, CreateDataQuery, GetDataQuery, GetConnector, MenuList, FilterConnectorByName, UpdateConnector, UpdateDataQuery } from "./graphQl";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import axios from "axios";
import { GQLARRAY } from "./utils";

const RestApiConnector = forwardRef(({ formId, recordId, requestValidated, setAddMode, type, loading }, ref) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const isDataQuery = type === 'dataQuery';
    const currentMutation = isDataQuery
        ? (recordId ? UpdateDataQuery : CreateDataQuery)
        : (recordId ? UpdateConnector : CreateConnector);
    const [createUpdate, { data: createUpdateData, loading: submittingForm, error }] = useMutation(currentMutation);
    const [connectorId, setConnectorId] = useState(isDataQuery ? null : recordId);
    const [dataQueryId] = useState(isDataQuery ? recordId : null);
    const [searchKey, setSearchKey] = useState(null);
    const { data: connectors, refetch: refetchConnectorOptions } = useQuery(FilterConnectorByName, { variables: { search: searchKey }, skip: !isDataQuery || !searchKey });
    const [defaultValues, setDefaultValues] = useState(getInitialValues(isDataQuery, hostUrls));
    const [errorData, setErrorData] = useState(null);
    const formikRef = useRef();
    const [isAuthentication, setAuthentication] = useState("none");
    const navigate = useNavigate();
    const [responseData, setResponseData] = useState(null);
    const [responseCode, setResponseCode] = useState(null);
    const [tab, setTab] = useState('params');
    const [validatingRequest, setValidatingRequest] = useState(false);
    const { data: connectorDetails, loading: fetchingConnectorDetails, refetch: fetchConnectorDetails } = useQuery(GetConnector, { variables: { id: connectorId }, skip: !connectorId, notifyOnNetworkStatusChange: true });
    const { data: dataQueryDetails, loading: fetchingDataQueryDetails, refetch: fetchDataQuery } = useQuery(GetDataQuery, { variables: { id: dataQueryId }, skip: !dataQueryId });
    const { data: microservices, loading: fetchingMicroservices, refetch: fetchMicroservices } = useQuery(MenuList, { variables: { search: 'Microservices' }});
    const [responseKeys, setResponseKeys] = useState([]);
    const [additionalcharleft, setAdditionalcharleft] = useState(20);
    const [hosts, setHosts] = useState([]);
    const hostUrls = useSelector((state)=>state?.hosts);
    const [userId, setUserId] = useState(null);
    const [fullName, setFullName] = useState(null);
    const [overrideConnector, setOverrideConnector] = useState(!(isDataQuery && recordId))
    const [variablesInGql, setVariablesInGql] = useState([]);
    
    useImperativeHandle(ref, () => {
        return {
            handleSubmit
        }
    })

    useEffect(() => {
        if(recordId){
            if(isDataQuery){
                fetchDataQuery();
            }
            else{
                fetchConnectorDetails();
            }
        }
        let str={};
        str = JSON.stringify(str);
        axios.get('/api/users/myprofile', { headers: { 'query': str } }).then((responseData) => {
            setUserId(responseData.data.userId+'');
            setFullName(responseData.data.fullname);
        });
    }, [])

    useEffect(() => {
        if(microservices && microservices.menuListByName.length){
            let values = JSON.parse(microservices.menuListByName[0].values);
            if(Array.isArray(values)){
                setHosts(values);
            }
        }
    }, [fetchingMicroservices])

    useEffect(() => {
        if (dataQueryDetails) {
            updateDefaultValuesForDataQuery();
        }
    }, [fetchingDataQueryDetails])

    useEffect(() => {
        if(isDataQuery && connectorId){
            fetchConnectorDetails();
        }
    }, [connectorId])

    useEffect(() => {
        if (connectorDetails && overrideConnector) {
            let initialValues = getInitialValues(isDataQuery, hostUrls, connectorDetails.connector);
            if (formikRef.current) {
                initialValues = { ...formikRef.current.values, ...initialValues };
                titleCharacter(initialValues.title);
            }
            setDefaultValues(initialValues);
        }
    }, [fetchingConnectorDetails])

    useEffect(() => {
        loading(submittingForm);
        if (createUpdateData) {
            isDataQuery
                ? setAddMode(false)
                : setTimeout(
                    () => navigate('/connectors'),
                    1000
                );
        }
        else if (error) {
            if (error.message.includes('duplicate key error')) {
                const type = isDataQuery ? 'Data Query' : 'Connector';
                Swal.fire({
                    title: `${type} with the same name already exists. 
                    Please provide a different name.`,
                    width:400,
                    padding: "15px",
                    confirmButtonText: "OK",
                });
            }
        }
    }, [submittingForm])

    const connectorInputChange = (value, setFieldValue) => {
        setFieldValue('connector_id', value);
        setSearchKey(value);
    };

    const handleSubmit = () => {
        formikRef?.current?.handleSubmit();
    }

    const onCrossClickConnector = (setFieldValue) => {
        setConnectorId(null);
        setFieldValue('connector_id', "");
        setFieldValue('authentication', {type: 'none', value: null});
        setFieldValue('body', {type: "none", subtype: "text", value: ""});
        setFieldValue('queries', []);
        setFieldValue('headers', []);
        setFieldValue('host', "");
        setFieldValue('url', "");
        setFieldValue('verb', "get");
        requestValidated(true);
    };

    const onConnectorSelection = (item, setFieldValue) => {
        if (item.length > 0) {
            setOverrideConnector(true);
            setFieldValue('connector_id', item[0].label);
            setConnectorId(item[0].id);
        }
    };

    const removeHeaderContentType = () => {
        if (formikRef.current) {
            const headers = formikRef.current.values.headers;
            let index = headers.findIndex(item => item.key === 'Content-Type');
            if (index !== -1) headers.splice(index, 1);
        }
    }

    const onSubmit = async (values, { setSubmitting }) => {
        let dependent = [];
        if (isDataQuery) {
            values.headers.forEach(header => {
                dependent = [...dependent, ...getVariables(header.value)]
            });
            values.queries.forEach(query => {
                dependent = [...dependent, ...getVariables(query.value)]
            });
            if (values.body.type === 'form-data') {
                values.body.value.forEach(item => {
                    dependent = [...dependent, ...getVariables(item.value)]
                });
            }
            if (values.body.type === 'raw') {
                dependent = [...dependent, ...getVariables(values.body.value)];
            }

            if (values.body.variables && values.body.variables.length>0) {
                values.body.variables.forEach(item => {
                    dependent = [...dependent, ...getVariables(item.value)]
                });
            }
        }
        const input = isDataQuery
            ? {
                title: values.title.trim(),
                type: 'connector',
                data: JSON.stringify({
                    authentication: values.authentication,
                    body: values.body,
                    headers: values.headers,
                    query_string: values.queries,
                    responseKeys: responseKeys,
                    dependent: dependent,
                    transform_response: values.transform_response,
                    varUrl: values.varUrl
                }),
                form: formId,
                connector: connectorId,
                modifiedBy: fullName,
                modifiedById: userId,
            }
            : {
                authentication: JSON.stringify(values.authentication),
                body: JSON.stringify(values.body),
                headers: JSON.stringify(values.headers),
                module: values.module,
                modifiedBy: fullName,
                modifiedById: userId,
                query_string: JSON.stringify(values.queries),
                title: values.title.trim(),
                url: values.url,
                verb: values.verb
            }
        const postObject = {
            variables: {
                input: input
            }
        }
        if (recordId) {
            postObject.variables.id = recordId;
        }
        else {
            postObject.variables.input.createdBy = fullName;
            postObject.variables.input.createdById = userId;

        }
        createUpdate(postObject);
    }

    const titleCharacter = (value) => {
        let input = value.length;
        let additionalchar = 20 - input;
        setAdditionalcharleft(additionalchar);
    }

    const updateDefaultValuesForDataQuery = async () => {
        const data = JSON.parse(dataQueryDetails.dataQuery.data);
        setConnectorId(dataQueryDetails.dataQuery.connector._id);
        setSearchKey(dataQueryDetails.dataQuery.connector.title);
        titleCharacter(dataQueryDetails.dataQuery.title);
        let host = '';
        if(dataQueryDetails.dataQuery.connector.module !== 'other'){
            host = `{{${dataQueryDetails.dataQuery.connector.module}}}`;
        }
        setDefaultValues({
            authentication: data.authentication,
            body: data.body,
            headers: data.headers,
            queries: data.query_string,
            title: dataQueryDetails.dataQuery.title,
            url: dataQueryDetails.dataQuery.connector.url,
            host: host,
            module: dataQueryDetails.dataQuery.connector.module,
            verb: dataQueryDetails.dataQuery.connector.verb,
            type: dataQueryDetails.dataQuery.type,
            connector_id: dataQueryDetails.dataQuery.connector.title,
            transform_response: data.transform_response,
            varUrl: data.varUrl
        });
    }

    const updateHeaderContentType = (value) => {
        if (formikRef.current) {
            const headers = formikRef.current.values.headers;
            let contentType = { key: 'Content-Type', value: 'text/plain' };
            switch (value) {
                case 'text': contentType.value = 'text/plain'; break;
                case 'js': contentType.value = 'application/javascript'; break;
                case 'json': contentType.value = 'application/json'; break;
                case 'html': contentType.value = 'text/html'; break;
                case 'xml': contentType.value = 'application/xml'; break;
                case 'form-data': contentType.value = 'multipart/form-data'; break;
            }
            let index = headers.findIndex(item => item.key === 'Content-Type');
            index === -1
                ? headers.push(contentType)
                : headers[index] = contentType;
        }
    }

    const updateQueriesValues = (value, setFieldValue) => {
        if (formikRef.current) {
            let index = value.indexOf('?');
            if (index === -1) { setFieldValue('queries', []); return };
            value = value.substring(index + 1);
            value = value.split('&');
            value = value.filter(item => item.split('=').length === 2);
            setFieldValue('queries', value.map(item => {
                let query = item.split('=');
                return {
                    key: query[0],
                    value: query[1]
                };
            }));
        }
    }

    const updateURL = (setFieldValue) => {
        if (formikRef.current) {
            const values = formikRef.current.values;
            let index = values.url.indexOf('?');
            let url = index === -1 ? values.url : values.url.substring(0, index)
            if (values.queries.length) {
                let paramString = null;
                values.queries.forEach(query => {
                    let value = isDataQuery ? query.sample : query.value;
                    value = query.key + "=" + value;
                    paramString = paramString ? paramString + "&" + value : value;
                })
                setFieldValue('url', url + "?" + paramString);
            }
            else setFieldValue('url', url);
        }
    }

    const updateHostValue = (module, setFieldValue) => {
        if(module==='other'){
            setFieldValue('host', '');
        }
        else{
            const host = `{{${module}}}`;//hostUrls.hasOwnProperty(module)?hostUrls[module]:'';
            setFieldValue('host', host);
        }
    }



    const validateRequest = async (formValues) => {
        setValidatingRequest(true);
        setErrorData(null);
        setResponseData(null);
        setResponseCode(null);
        let values = JSON.parse(JSON.stringify(formikRef.current?.values));
        values.body = { ...formValues.body };
        if (isDataQuery) {
            values.headers = values.headers.map(header => { return { key: header.key, value: header.sample } });
            values.queries = values.queries.forEach(query => { return { key: query.key, value: query.sample } });
            if (values.body.type === 'form-data') {
                values.body.value = values.body.value.map(item => { return {type:item.type, key: item.key, value: item.sample } });
            }
            if (values.body.type === 'raw') {
                values.body.value = values.body.sample;
            }

            if (values.body.variables && values.body.variables.length>0) {
                values.body.variables= values.body.variables.map(item=>{  return {type:item.type, key: item.key, value: item.sample }});
            }
        }
        if (values && values?.verb && GQLARRAY.includes(values?.verb)) {
          validateGraphQlRequest(values)
            .then((response) => {
              let data =
                isDataQuery && values.transform_response
                  ? transformFunction(values.transform_response, response.data)
                  : response.data;
              if (isDataQuery) {
                if (Array.isArray(data)) {
                  setResponseKeys(Object.keys(response.data[0]));
                } else if (typeof data == "object") {
                  let keys = Object.keys(data);
                  if (Array.isArray(data[keys[0]])) {
                    setResponseKeys(Object.keys(data[keys[0]][0]));
                  }
                } else setResponseKeys([]);
              }
              setResponseData(JSON.stringify(data, null, 2));
              setResponseCode(response.status);
              requestValidated(false);
              setValidatingRequest(false);
            })
            .catch((error) => {
              error.response
                ? setErrorData(
                    typeof error.response.data === "object"
                      ? JSON.stringify(error.response.data)
                      : error.response.data
                  )
                : setErrorData(JSON.stringify(error));
              if (error.response) setResponseCode(error.response.status);
              setValidatingRequest(false);
            });
        } else {
          validateRestRequest(values)
            .then((response) => {
              let data =
                isDataQuery && values.transform_response
                  ? transformFunction(values.transform_response, response.data)
                  : response.data;
              if (isDataQuery) {
                if (Array.isArray(data)) {
                  setResponseKeys(Object.keys(response.data[0]));
                } else if (typeof data == "object") {
                  let keys = Object.keys(data);
                  if (Array.isArray(data[keys[0]])) {
                    setResponseKeys(Object.keys(data[keys[0]][0]));
                  }
                } else setResponseKeys([]);
              }
              setResponseData(JSON.stringify(data, null, 2));
              setResponseCode(response.status);
              requestValidated(false);
              setValidatingRequest(false);
            })
            .catch((error) => {
              error.response
                ? setErrorData(
                    typeof error.response.data === "object"
                      ? JSON.stringify(error.response.data)
                      : error.response.data
                  )
                : setErrorData(JSON.stringify(error));
              if (error.response) setResponseCode(error.response.status);
              setValidatingRequest(false);
            });
        }
    }

    const validationSchema = Yup.object().shape({
        title: Yup.string().trim().required("Required").max(20),
        module: isDataQuery ? Yup.string().nullable() : Yup.string().required("Required"),
        url: Yup.string().required("Required")
    })

    return (
        <Formik
            innerRef={formikRef}
            initialValues={defaultValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
        >
            {({
                errors,
                touched,
                values,
                handleChange,
                setFieldValue
            }) => {
                return (
                    <FormikForm noValidate>
                        <Row>
                            <Col>
                                <Form.Group className="form-group">
                                    <Form.Label >Name <span className="rStar" /></Form.Label>
                                    <Form.Control
                                        name="title"
                                        type="text"
                                        value={values.title}
                                        className={
                                            errors.title && touched.title
                                                ? "form-control error"
                                                : "form-control"
                                        }
                                        onChange={(e) => { titleCharacter(e.target.value); setFieldValue('title', e.target.value) }}
                                        maxLength={20}
                                    />
                                    <p className="charLeft" style={{ textAlign: "right", "fontSize": "11px" }}>({tr['Characters Remaining']}: {additionalcharleft}/20)</p>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="form-group">
                                    {isDataQuery
                                        ? (<>
                                            <Form.Label>Connector</Form.Label>
                                            <div className="minHghtdvTypehd">
                                                <TypeaheadExampleSingleSelect
                                                    id="connectorDropdown"
                                                    name="connector_id"
                                                    className={
                                                        errors.connector_id && touched.connector_id
                                                            ? "form-control error"
                                                            : "form-control"
                                                    }
                                                    onSelection={(item) => { onConnectorSelection(item, setFieldValue) }}
                                                    setErrorColor={() => { }}
                                                    options={connectors ? connectors.connectorsByName.map(item => { return { id: item._id, label: item.title } }) : []}
                                                    selectedOptions={[values['connector_id']]}
                                                    onInputChange={(value) => { connectorInputChange(value, setFieldValue) }}
                                                    onCrossClick={() => { onCrossClickConnector(setFieldValue) }}
                                                    typedValue={searchKey}
                                                    multiple={false}
                                                />
                                            </div>
                                        </>)
                                        : (<>
                                            <Form.Label >Microservice <span className="rStar" /></Form.Label>
                                            <Form.Select
                                                className={
                                                    errors.module && touched.module
                                                        ? "form-control error"
                                                        : "form-control"
                                                }
                                                disabled={isDataQuery}
                                                name="module"
                                                value={values.module}
                                                onChange={(e) => {
                                                    setFieldValue('module', e.target.value);
                                                    updateHostValue(e.target.value, setFieldValue)
                                                    requestValidated(true);
                                                }}>
                                                {
                                                    hosts.map((host, index) => (<option key={index} value={host.value}>{host.label}</option>))
                                                }
                                            </Form.Select>
                                        </>)
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="form-group">
                                    <Form.Label >Configure the API for your connector</Form.Label>
                                    <InputGroup>
                                        <Form.Select disabled={isDataQuery} name="verb" value={values.verb} onChange={(e) => { setFieldValue('verb', e.target.value); requestValidated(true); }}>
                                            <option value="get">GET</option>
                                            <option value="post">POST</option>
                                            <option value="put">PUT</option>
                                            <option value="patch">PATCH</option>
                                            <option value="delete">DELETE</option>
                                            <option value="gqlQuery">GQL Query</option>
                                            <option value="gqlMutation">GQL Mutation</option>
                                        </Form.Select>
                                        {values.module!=='other' && (
                                            <Form.Control disabled className={'w-25'} placeholder="host" name="host" type="text" value={values.host} onChange={(e) => { setFieldValue('host', e.target.value); requestValidated(true) }} />
                                        )}
                                        <Form.Control 
                                            disabled={isDataQuery} 
                                            className={values.module==='other' ?  (isDataQuery ? 'w-50' : 'w-75') : (isDataQuery ? 'w-25' : 'w-50') } 
                                            placeholder={"URL"} 
                                            name="url" 
                                            type="text" 
                                            value={values.url} 
                                            onChange={(e) => { 
                                                setFieldValue('url', e.target.value);
                                                requestValidated(true); 
                                                updateQueriesValues(e.target.value, setFieldValue) 
                                            }} />
                                        <Button size="sm" variant="primary" className="rgSidrkBtn" onClick={() => { validateRequest(values) }}>
                                            {validatingRequest
                                                ? (<Spinner as="span" animation="border" role="status" aria-hidden="true"></Spinner>)
                                                : 'Test'
                                            }
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                                {isDataQuery && <Form.Group className="form-group">
                                    <Form.Label >Configure changeable Url(Optional)</Form.Label>
                                    <InputGroup>
                                        <Form.Control 
                                            className={values.module==='other' ?  (isDataQuery ? 'w-50' : 'w-75') : (isDataQuery ? 'w-25' : 'w-50') } 
                                            placeholder={"URL"} 
                                            name="varUrl" 
                                            type="text" 
                                            value={values.varUrl} 
                                            onChange={(e) => { 
                                                setFieldValue('varUrl', e.target.value);
                                            }} />
                                    </InputGroup>
                                </Form.Group>}
                            </Col>
                        </Row>
                        <Row className="margin-t-10">
                            <Col md={12}>
                                <div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border tabformdv">
                                    <Tabs className="bg-lightgray" activeKey={tab} onSelect={(k) => setTab(k)} fill>
                                        <Tab eventKey="authentication" title="Authentication">
                                            <Row className="mt-2">
                                                <Col md={4} className="border-end">
                                                    <Form.Group className="form-group">
                                                        <Form.Label >Authentication</Form.Label>
                                                        <Form.Select onChange={e => { setAuthentication(e.target.value); requestValidated(true); }}>
                                                            <option value="none">None</option>
                                                            <option value="api">API Key Authentication</option>
                                                            <option value="basic">Basic Authentication</option>
                                                            <option value="jwt">JWT Bearer</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    {isAuthentication === "jwt" && (
                                                        <>
                                                            <Form.Group className="form-group">
                                                                <Form.Label >Algorithm</Form.Label>
                                                                <Form.Select>
                                                                    <option value="0">None</option>
                                                                    <option value="1">HS384</option>
                                                                    <option value="2">RS256</option>
                                                                    <option value="3">PS512</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                            <Form.Group className="form-group">
                                                                <Form.Label >JWT Secret</Form.Label>
                                                                <Form.Control />
                                                            </Form.Group>
                                                            <Form.Group className="form-group">
                                                                <Form.Label >Payload</Form.Label>
                                                                <Form.Control as="textarea" rows="2" />
                                                            </Form.Group>
                                                            <Form.Group className="form-group">
                                                                <Form.Label >JWT Header Prefix</Form.Label>
                                                                <Form.Control as="textarea" rows="1" />
                                                            </Form.Group>
                                                            <Form.Group className="form-group">
                                                                <Form.Label >JWT Headers</Form.Label>
                                                                <Form.Control as="textarea" rows="1" />
                                                            </Form.Group>
                                                            <Form.Group className="form-group">
                                                                <Form.Label >Add JWT token to</Form.Label>
                                                                <Form.Select>
                                                                    <option value="1">Request Header</option>
                                                                    <option value="2">Query Param</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </>
                                                    )}
                                                    {isAuthentication === "basic" && (
                                                        <>
                                                            <Form.Group className="form-group">
                                                                <InputGroup className="margin-b-5">
                                                                    <Form.Control disabled placeholder="Username" />
                                                                    <Form.Control />
                                                                </InputGroup>
                                                                <InputGroup className="margin-b-5">
                                                                    <Form.Control disabled placeholder="Password" />
                                                                    <Form.Control />
                                                                </InputGroup>
                                                            </Form.Group>
                                                        </>
                                                    )}
                                                    {isAuthentication === "api" && (
                                                        <>
                                                            <Form.Group className="form-group">
                                                                <InputGroup>
                                                                    <Form.Control placeholder="key" />
                                                                    <Form.Control placeholder="Value" />
                                                                    <Form.Select>
                                                                        <option value="0">None</option>
                                                                        <option value="1">In Querystring</option>
                                                                        <option value="2">In Header</option>
                                                                        <option value="3">In Body</option>
                                                                    </Form.Select>
                                                                </InputGroup>
                                                                <div className="margin-t-5 f-size-14 color-blue"><LuPlus /> Add more</div>
                                                            </Form.Group>
                                                        </>)}
                                                </Col>
                                            </Row>
                                        </Tab>
                                        {!GQLARRAY.includes(values.verb) && <Tab eventKey="params" title="Params" className="border-bottom">
                                            <Form.Group className="form-group mt-2">
                                                <FieldArray
                                                    name="queries"
                                                    render={arrayHelpers => (
                                                        <>
                                                            {values.queries && values.queries.length > 0 && (
                                                                values.queries.map((query, index) => (
                                                                    <InputGroup className="mt-1" key={index}>
                                                                        <Form.Control name={`queries.${index}.key`} value={query.key} placeholder="Key" onChange={async (e) => { await setFieldValue(`queries.${index}.key`, e.target.value); requestValidated(true); updateURL(setFieldValue) }} />
                                                                        {isDataQuery && (
                                                                            <Form.Control name={`queries.${index}.sample`} value={query.sample} placeholder="Sample" onChange={async (e) => { await setFieldValue(`queries.${index}.sample`, e.target.value); requestValidated(true); updateURL(setFieldValue) }} />
                                                                        )}
                                                                        <Form.Control className={isDataQuery ? "w-25" : "w-50"} name={`queries.${index}.value`} value={query.value} placeholder="Value" onChange={async (e) => { await setFieldValue(`queries.${index}.value`, e.target.value); requestValidated(true); updateURL(setFieldValue) }} />
                                                                        <Button variant="outline-secondary" onClick={async () => { await arrayHelpers.remove(index); requestValidated(true); updateURL(setFieldValue) }}><MdDelete></MdDelete></Button>
                                                                    </InputGroup>
                                                                )))
                                                            }
                                                            <div role="button" className="margin-t-5 f-size-14 color-blue" onClick={() => { arrayHelpers.push({ key: "", value: "" }); requestValidated(true); }}><LuPlus /> Add Query Parameter</div>
                                                        </>
                                                    )
                                                    }></FieldArray>
                                            </Form.Group>
                                        </Tab>}
                                        <Tab eventKey="headers" title="Headers">
                                            <Form.Group className="form-group mt-2">
                                                <FieldArray
                                                    name="headers"
                                                    render={arrayHelpers => (
                                                        <>
                                                            {values.headers && values.headers.length > 0 && (
                                                                values.headers.map((header, index) => (
                                                                    <InputGroup className="mt-1" key={index}>
                                                                        <Form.Control name={`headers.${index}.key`} value={header.key} placeholder="Key" onChange={(e) => { setFieldValue(`headers.${index}.key`, e.target.value); requestValidated(true); }} />
                                                                        {isDataQuery && (
                                                                            <Form.Control name={`headers.${index}.sample`} value={header.sample} placeholder="Sample" onChange={(e) => { setFieldValue(`headers.${index}.sample`, e.target.value); requestValidated(true); }} />
                                                                        )}
                                                                        <Form.Control className={isDataQuery ? "w-25" : "w-50"} name={`headers.${index}.value`} value={header.value} placeholder="Value" onChange={(e) => { setFieldValue(`headers.${index}.value`, e.target.value); requestValidated(true); }} />
                                                                        <Button variant="outline-secondary" onClick={() => { arrayHelpers.remove(index); requestValidated(true); }}><MdDelete></MdDelete></Button>
                                                                    </InputGroup>
                                                                )))
                                                            }
                                                            <div role="button" className="margin-t-5 f-size-14 color-blue" onClick={() => { arrayHelpers.push({ key: "", value: "" }); requestValidated(true); }}><LuPlus /> Add Header</div>
                                                        </>
                                                    )
                                                    }></FieldArray>
                                            </Form.Group>
                                        </Tab>
                                        <Tab eventKey="body" title="Body">
                                            <Row>
                                                <Col md={12}>
                                                    <Form.Group className="form-group mt-2">
                                                        <div className="d-flex">
                                                            <div>
                                                                {!GQLARRAY.includes(values.verb) && <><Form.Check
                                                                    checked={values.body.type === 'none'}
                                                                    type="radio"
                                                                    inline
                                                                    label="none"
                                                                    name="body.type"
                                                                    value="none"
                                                                    onChange={(e) => {
                                                                        setFieldValue('body.type', e.target.value);
                                                                        requestValidated(true);
                                                                        removeHeaderContentType()
                                                                    }}
                                                                ></Form.Check>
                                                                <Form.Check
                                                                    checked={values.body.type === 'form-data'}
                                                                    type="radio"
                                                                    inline
                                                                    label="form-data"
                                                                    name="body.type"
                                                                    value="form-data"
                                                                    onChange={(e) => {
                                                                        setFieldValue('body.type', e.target.value);
                                                                        requestValidated(true);
                                                                        updateHeaderContentType('form-data')
                                                                        setFieldValue('body.value', [])
                                                                    }}
                                                                ></Form.Check></>}
                                                                <Form.Check
                                                                    checked={values.body.type === 'raw'}
                                                                    type="radio"
                                                                    inline
                                                                    label="raw"
                                                                    name="body.type"
                                                                    value="raw"
                                                                    onChange={(e) => {
                                                                        setFieldValue('body.type', e.target.value);
                                                                        requestValidated(true);
                                                                        updateHeaderContentType(isDataQuery && !GQLARRAY.includes(values.verb) &&values.body.subtype)
                                                                    }}
                                                                ></Form.Check>
                                                            </div>
                                                            {values.body.type === 'raw' && (<div className="ms-auto">
                                                                <Form.Select
                                                                    name="body.subtype"
                                                                    onChange={(e) => {
                                                                        setFieldValue('body.subtype', e.target.value);
                                                                        requestValidated(true);
                                                                        updateHeaderContentType(e.target.value);
                                                                    }}
                                                                    value={values.body.subtype}
                                                                >
                                                                    <option value="text">Text</option>
                                                                    <option value="json">JSON</option>
                                                                   {!GQLARRAY.includes(values.verb) && <><option value="js">JavaScript</option>
                                                                    <option value="html">HTML</option>
                                                                    <option value="xml">xml</option></>}
                                                                </Form.Select>
                                                            </div>)}
                                                        </div>
                                                        {values.body.type === 'raw' && (
                                                            <>
                                                                {isDataQuery && !GQLARRAY.includes(values.verb) && (
                                                                    <Form.Control
                                                                        name="body.sample"
                                                                        className="mt-2"
                                                                        as="textarea"
                                                                        rows="3"
                                                                        value={values.body.sample}
                                                                        onChange={(e) => { setFieldValue('body.sample', e.target.value); requestValidated(true); }}
                                                                    />
                                                                )}
                                                                <Form.Control
                                                                    name="body.value"
                                                                    className="mt-2"
                                                                    as="textarea"
                                                                    rows="3"
                                                                    value={values.body.value}
                                                                    disabled={isDataQuery && GQLARRAY.includes(values.verb)}
                                                                    onChange={(e) => { setFieldValue('body.value', e.target.value); requestValidated(true); }}
                                                                />
                                                            </>
                                                        )}
                                                        {values.body.type === 'form-data' && (
                                                            <Form.Group className="form-group mt-2">
                                                                <FieldArray
                                                                    name="body.value"
                                                                    render={arrayHelpers => (
                                                                        <>
                                                                            {values.body.value && Array.isArray(values.body.value) && values.body.value.length > 0 && (
                                                                                values.body.value.map((item, index) => (
                                                                                    <InputGroup className="mt-1" key={index}>
                                                                                        <Form.Control className={"w-25"} name={`body.value.${index}.key`} value={item.key} placeholder="Key" onChange={(e) => { setFieldValue(`body.value.${index}.key`, e.target.value); requestValidated(true); }} />
                                                                                        <Form.Select
                                                                                            name={`body.value.${index}.type`}
                                                                                            value={item.type}
                                                                                            className={isDataQuery?"w-25":""}
                                                                                            onChange={(e) => {
                                                                                                setFieldValue(`body.value.${index}.type`, e.target.value);
                                                                                                if (isDataQuery) { setFieldValue(`body.value.${index}.sample`, ''); }
                                                                                                setFieldValue(`body.value.${index}.value`, '');
                                                                                                requestValidated(true);
                                                                                            }}>
                                                                                            <option value="text">Text</option>
                                                                                            <option value="file">File</option>
                                                                                        </Form.Select>
                                                                                        {item.type !== 'file'
                                                                                            ? (<>
                                                                                                {isDataQuery && (
                                                                                                    <Form.Control className="w-25" name={`body.value.${index}.sample`} value={item.sample} placeholder="Sample" onChange={(e) => { setFieldValue(`body.value.${index}.sample`, e.target.value); requestValidated(true); }} />
                                                                                                )}
                                                                                                <Form.Control className={`w-50 ${isDataQuery?'mt-1':""}`} name={`body.value.${index}.value`} value={item.value} placeholder="Value" onChange={(e) => { setFieldValue(`body.value.${index}.value`, e.target.value); requestValidated(true); }} />
                                                                                            </>)
                                                                                            : (<>
                                                                                                {isDataQuery && (
                                                                                                    <Form.Control type="file" className="w-25" name={`body.value.${index}.sample`} placeholder="Sample" onChange={(e) => { setFieldValue(`body.value.${index}.sample`, e.currentTarget.files[0]); requestValidated(true); }}></Form.Control>
                                                                                                )}
                                                                                                <Form.Control type={isDataQuery?"text":"file"} className={`w-50 ${isDataQuery?"mt-1":""}`} name={`body.value.${index}.value`} value={isDataQuery?item.value:null} placeholder="Value" onChange={(e) => { setFieldValue(`body.value.${index}.value`,isDataQuery?e.target.value:e.currentTarget.files[0]); requestValidated(true); }}></Form.Control>
                                                                                            </>)
                                                                                        }
                                                                                        <Button variant="outline-secondary" onClick={() => { arrayHelpers.remove(index); requestValidated(true); }}><MdDelete></MdDelete></Button>
                                                                                    </InputGroup>
                                                                                )))
                                                                            }
                                                                            <div role="button" className="margin-t-5 f-size-14 color-blue" onClick={() => { arrayHelpers.push({ key: "", type: "", value: "" }); requestValidated(true); }}><LuPlus /> Add Form Data</div>
                                                                        </>
                                                                    )
                                                                    }>
                                                                </FieldArray>
                                                            </Form.Group>
                                                        )}
                                                        {GQLARRAY.includes(values?.verb) && (
                                                            <Form.Group className="form-group mt-2">
                                                                <FieldArray
                                                                    name="body.variables"
                                                                    render={arrayHelpers => (
                                                                        <>
                                                                            {values.body.variables && Array.isArray(values.body.variables) && values.body.variables.length > 0 && (
                                                                                values.body.variables.map((item, index) => (
                                                                                    <InputGroup className="mt-1" key={index}>
                                                                                        <Form.Control className={"w-25"} name={`body.variables.${index}.key`} value={item.key} placeholder="Key" onChange={(e) => { setFieldValue(`body.variables.${index}.key`, e.target.value); requestValidated(true); }} />
                                                                                        <Form.Select
                                                                                            name={`body.variables.${index}.type`}
                                                                                            value={item.type}
                                                                                            className={isDataQuery ? "w-25" : ""}
                                                                                            onChange={(e) => {
                                                                                                setFieldValue(`body.variables.${index}.type`, e.target.value);
                                                                                                if (isDataQuery) { setFieldValue(`body.variables.${index}.sample`, ''); }
                                                                                                setFieldValue(`body.variables.${index}.value`, '');
                                                                                                requestValidated(true);
                                                                                            }}>
                                                                                            <option value="text">Text</option>
                                                                                            <option value="number">Number</option>
                                                                                            <option value="object">Object</option>
                                                                                        </Form.Select>
                                                                                        {item.type !== "object"
                                                                                            ? (<>
                                                                                                {isDataQuery && (
                                                                                                    <Form.Control className="w-25" name={`body.variables.${index}.sample`} value={item.sample} placeholder="Sample" onChange={(e) => { setFieldValue(`body.variables.${index}.sample`, e.target.value); requestValidated(true); }} />
                                                                                                )}
                                                                                                <Form.Control className={`${isDataQuery?"mt-1":""} w-50`} name={`body.variables.${index}.value`} value={item.value} placeholder="Value" onChange={(e) => { setFieldValue(`body.variables.${index}.value`, e.target.value); requestValidated(true); }} />
                                                                                            </>)
                                                                                            : (<>
                                                                                                {isDataQuery && (
                                                                                                    <Form.Control as="textarea" rows={3} className="w-25" name={`body.variables.${index}.sample`} placeholder="Sample" value={item.sample} onChange={(e) => { setFieldValue(`body.variables.${index}.sample`, e.target.value); requestValidated(true); }}></Form.Control>
                                                                                                )}
                                                                                                <Form.Control as="textarea" rows={3} className={`${isDataQuery?"mt-1":""} w-50`} name={`body.variables.${index}.value`} value={item.value} placeholder="Value" onChange={(e) => { setFieldValue(`body.variables.${index}.value`,e.target.value); requestValidated(true); }}></Form.Control>
                                                                                            </>)
                                                                                        }
                                                                                        <Button variant="outline-secondary" onClick={() => { arrayHelpers.remove(index); requestValidated(true); }}><MdDelete></MdDelete></Button>
                                                                                    </InputGroup>
                                                                                )))
                                                                            }
                                                                           {!isDataQuery && <div role="button" className="margin-t-5 f-size-14 color-blue" onClick={() => { arrayHelpers.push({ key: "", type: "", value: "" }); requestValidated(true); }}><LuPlus /> Add Variable Data</div>}
                                                                        </>
                                                                    )
                                                                    }>
                                                                </FieldArray>
                                                            </Form.Group>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </Col>
                        </Row>
                        {isDataQuery && (
                            <Row>
                                <Col md={12}>
                                    <div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
                                        <Form.Group className="form-group">
                                            <Form.Label >Transform Response</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows="1"
                                                value="function transform(data) {"
                                                className="border-bottom-0 rounded-0 rounded-top no-resize"
                                                disabled

                                            />
                                            <Form.Control
                                                name="transform_response"
                                                className="form-control rounded-0 border-top-0 border-bottom-0"
                                                as="textarea"
                                                rows="3"
                                                value={values.transform_response}
                                                onChange={(e) => { setFieldValue('transform_response', e.target.value); requestValidated(true); }}
                                            />
                                            <Form.Control
                                                as="textarea"
                                                rows="1"
                                                value="}"
                                                className="form-control rounded-0 rounded-bottom  border-top-0 no-resize"
                                                disabled
                                            />
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col md={12}>
                                <div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
                                    <div className="d-flex bg-lightgray">
                                        <div className="padding-5 mb-2 text-uppercase fw-bold">
                                            Response
                                        </div>
                                        <div className="padding-5 mb-2 fw-bold f-size-15">
                                            {responseCode}
                                        </div>
                                    </div>
                                    <Row>
                                        <Col md={12}>
                                            {responseData && (<div className="p-2 text-wrap bg-white"><pre>{responseData}</pre></div>)}
                                            {errorData && (<div className="p-2 text-wrap bg-white">{errorData}</div>)}
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </FormikForm>
                )
            }
            }
        </Formik>
    )
})

export default RestApiConnector;