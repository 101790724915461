
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { GLOBAL } from "_Globals";
import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import { bindActionCreators } from 'redux';

import moment from 'moment';
import { getPriorityUrgencyCriticalityValues } from '../../../../actions/common/commonApisActions';
import { loadBreakFixSGI } from '../../../../actions/breakFix/serviceGroupIAction';
import CIDetailsService from '../../../common/WorkItemBoard/ciNameSelect';
import _ from 'lodash';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
import CIDEtails from "../../../common/WorkItemBoard/xsm-ci-editquickView";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import {ImInfo} from 'react-icons/im';
import { getCookiesValuesByPositions } from '../../../common/helper';

let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

let impactedServiceData = [];
let impactedServiceDataDetails = [];
let nameIndex = "";
let consumerData = [];
// let consumerDataDetails = [];
let consumerData1 = [];
let consumerDataDetails1 = [];
let myObj = [];
let myObj0 = [];


class CreateBreakFixInvestigationForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: moment(),
			priorityValue: '',
			priorityColorValue: '',
			reportedThrough: '',
			supportPGroup: '',
			supportPIndividual: '',
			criticality: '',
			urgencyMode: '',
			source: '',
			impactedServiceName: '',
			consumer: '',
			issueDescription: '',
			sourceErrorColor: '',
			reportedpThroughErrorColor: '',
			supportPGroupErrorColor: '',
			supportPIndividualErrorColor: '',
			pCriticalityErrorColor: '',
			urgencyModeErrorColor: '',
			sgErrorColor: '',
			sgIErrorColor: '',
			ciNameDataDetails: [],
			pUrgencyErrorColor: '',
			successMessage: '',
			companyId: '',
			errorColor: '',
			valuesImpacted: '',
			valuesImpactedId: '',
			valuesImpClassLocationId: '',
			valuesCILocationValue: '',
			oldbusinessCriticalityId: GLOBAL.businessCriticalityId,
			oldbusinessCriticality: GLOBAL.businessCriticality,
			newbusinessCriticalityId: '',
			newbusinessCriticality: ''
		};

		//this.onServiceNameChange = this.onServiceNameChange.bind(this);
		this.onGroupNameChange = this.onGroupNameChange.bind(this);
		//this.handleChange = this.handleChange.bind(this);
		this.onChangeUrgency = this.onChangeUrgency.bind(this);
		this.setErrorColor = this.setErrorColor.bind(this);
		this.validateField = this.validateField.bind(this);
		this.setDropDownValue = this.setDropDownValue.bind(this);
		this.onChangeCriticality = this.onChangeCriticality.bind(this);
		this.getLablel = this.getLabel.bind(this);
		this.onImpactedSelection = this.onImpactedSelection.bind(this);
		this.showImpactedCIInfoIcon = this.showImpactedCIInfoIcon.bind(this);

		GLOBAL.breakFixServiceID = this.props.breakFixEditDetails.serviceId;
		GLOBAL.ciId = "";
		GLOBAL.breakFixServiceName = this.props.breakFixEditDetails.serviceName;
		GLOBAL.breakFixConsumerID = this.props.breakFixEditDetails.impactedUserid;
		GLOBAL.breakFixConsumerName = this.props.breakFixEditDetails.impactedUsername;
		this.submit = this.submit.bind(this);
		GLOBAL.pIssueErrorColor = ""
		GLOBAL.pThroughErrorColor = "";
		GLOBAL.sgErrorColor = "";
		GLOBAL.sgIErrorColor = "";
		GLOBAL.pCriticalityErrorColor = "";
		GLOBAL.pUrgencyErrorColor = "";
		GLOBAL.sourceErrorColor = "";
	// 	let amsAttribute={
	// 		"Status":'0',
    //   "Priority":this.props.breakFixEditDetails.priorityId,
    //   "Service":'0',
    //   "Impacted CI":this.props.breakFixEditDetails.ciId,
    //   "Requestor":this.props.breakFixEditDetails.impactedUserid,
    //   "Class":this.props.breakFixEditDetails.ciClassId,
    //   "User.Location":this.props.breakFixEditDetails.requesterLocationId,
    //   "CI.Location":this.props.breakFixEditDetails.ciLocationId
	// 	}
		console.log("amsAttribute",this.props.breakFixEditDetails);
		//this.props.loadNewBreakFixSGAE(this.props.breakFixEditDetails.serviceBased ? this.props.breakFixEditDetails.serviceId : this.props.breakFixEditDetails.ciId, this.props.breakFixEditDetails.consumerCompany, "Investigation", this.props.breakFixEditDetails.serviceBased ? "service" : "impactedCI",amsAttribute);


	}


	//Start===========
	submit(values) {
		//let msgSubmit="TTTT1";
		console.log("timeeememem", values.issueDescription == ""
			|| values.criticality == "" || values.urgencyMode == "" || values.urgencyMode === undefined || values.source == "" || ((GLOBAL.Rfc === '' || GLOBAL.ciNameRfc == null || GLOBAL.ciNameRfc == undefined) && this.props.breakFixEditDetails.serviceBased == true));
		GLOBAL.messageForProposeInvst = "";
		let priorityActualValue = '';
		if (GLOBAL.priorityValueType == "P2") {
			priorityActualValue = 50;
		} else if (GLOBAL.priorityValueType == "P1") {
			priorityActualValue = 45;
		}
		else if (GLOBAL.priorityValueType == "P3") {
			priorityActualValue = 55;
		}
		else if (GLOBAL.priorityValueType == "P4") {
			priorityActualValue = 60;
		}
		let messageForProposeInvst = "";
		let breakFixConsumer = GLOBAL.breakFixConsumerID;
		let breakFixConsumerNameValue = GLOBAL.breakFixConsumerName;
		// let breakFixConsumerCompany=GLOBAL.consumerCompanybreakfix;
		let breakFixConsumerCompany = this.props.breakFixEditDetails.consumerCompany;
		let breakFixService = GLOBAL.breakFixServiceID;
		let breakFixServiceName = GLOBAL.breakFixServiceName;
		let breakFixConsumerCompanyName = this.props.breakFixEditDetails.consumerCompanyName;

		let serviceGroup = [];
		//let serviceGroupNameId = "";
		let serviceGroupIndividualNameId = "";
		//let serviceGroupName = "";
		let serviceGroupIndividualName = "";
		// serviceGroup = this.props.breakFixSGAEName;
		// console.log("this.props.breakFixSGAEName",this.props.breakFixSGAEName,serviceGroup);
		// serviceGroupNameId=serviceGroup[0].assignmentGroupId;
		// serviceGroupIndividualNameId = serviceGroup[0].assignmentMemberId ? serviceGroup[0].assignmentMemberId : 0;
		// serviceGroupName = serviceGroup[0].assignmentGroupName;
		// serviceGroupIndividualName = serviceGroup[0].assignmentMemberName ? serviceGroup[0].assignmentMemberName : '';
		//alert(values.reportedThrough)
		//alert(this.props.breakFixEditDetails.requestId)
		if (`${JSON.stringify(values, null, 2)}` == "{}") {
			throw new SubmissionError({ _error: 'The form has missing required fields, please revalidate the highlighted fields and update the form' })
		} else if (values.issueDescription == ""
			|| values.criticality == "" || values.urgencyMode == "" || values.urgencyMode === undefined || values.source == "" || ((GLOBAL.ciNameRfc === '' || GLOBAL.ciNameRfc == null || GLOBAL.ciNameRfc == undefined) && this.props.breakFixEditDetails.serviceBased == true)) {
			if (values.issueDescription == "") {
				GLOBAL.pIssueErrorColor = "error";
			} else {
				GLOBAL.pIssueErrorColor = "";
			}
			if (values.criticality == "" || values.criticality == undefined || values.criticality == "Select..") {
				GLOBAL.pCriticalityErrorColor = "error";
			} else {
				GLOBAL.pCriticalityErrorColor = "";
			}
			console.log("(GLOBAL.ciName===''||GLOBAL.ciName==null ||GLOBAL.ciName==undefined)", (GLOBAL.ciName === '' || GLOBAL.ciName == null || GLOBAL.ciName == undefined));
			if (GLOBAL.ciNameRfc === '' || GLOBAL.ciNameRfc == null || GLOBAL.ciNameRfc == undefined) {
				this.setState({
					errorColor: "error"
				})
			}
			else {
				this.setState({
					errorColor: ""
				})
			}
			if (values.urgencyMode == "" || values.urgencyMode == undefined || values.urgencyMode == "Select..") {
				GLOBAL.pUrgencyErrorColor = "error";
			} else {
				GLOBAL.pUrgencyErrorColor = "";
			}
			if (values.source == "") {
				GLOBAL.sourceErrorColor = "error";
			}
			else {
				GLOBAL.sourceErrorColor = "";
			}
			// if(values.ciName=="")
			// {
			//     this.setState({
			// 		errorColor:"error"
			// 	});
			// }
			// else
			// {
			//    this.setState({
			// 	   errorColor:""
			//    });
			// }
			throw new SubmissionError({ _error: 'The form has missing required fields, please revalidate the highlighted fields and update the form !' });
		}
		else {
			let ref = this;
			const {value1, value2} = getCookiesValuesByPositions([6, 9])
			return axios.post(GLOBAL.investigationCreateUrl, {
				"cancellationComments": "",
				"cause": 0,
				"closureComments": "",
				"consumerCompany": breakFixConsumerCompany,
				"correctiveActionRequired": "",
				"correctiveActions": "",
				"createdOn": 0,
				"description": values.issueDescription,
				"expectedDate": 0,
				// "impactedUserid": this.props.breakFixEditDetails.impactedUserid,
				// "impactedUsername": this.props.breakFixEditDetails.impactedUsername,
				"impactedUserid": "",
				"impactedUsername": "",
				"modifiedBy": "",
				"modifiedOn": 0,
				"priority": priorityActualValue,
				"priorityValue": GLOBAL.priorityValueType,
				"qualificationComments": "",
				"qualified": "",
				"reportedThrough": ref.props.breakFixEditDetails.reportedThrough,
				"requestId": ref.props.breakFixEditDetails.requestId,
				"rootCause": "",
				"serviceCriticality": values.criticality,
				"source": values.source,
				"serviceBased": false,
				"serviceId": breakFixService != '' ? breakFixService : 0,
				"serviceName": breakFixServiceName != '' ? breakFixServiceName : '',
				"slaStatus": 0,
				"status": 30,
				// "status": 35,
				"symptoms": values.additionalInfo,
				"urgency": values.urgencyMode,
				"createdBy": "",
				"createdByName": "",
				"asgPmId": serviceGroupIndividualNameId,
				"asgPmName": serviceGroupIndividualName,
				"asgPmGroupid": 0,
				"asgPmGroupname": "",
				"supportCompanyId": "",
			    "supportCompanyName": "",
				"consumerCompanyName": breakFixConsumerCompanyName,
				"ciName": this.props.breakFixEditDetails.serviceBased ? this.state.valuesImpacted : this.props.breakFixEditDetails.ciName,
				"ciId": this.props.breakFixEditDetails.serviceBased ? this.state.valuesImpactedId : this.props.breakFixEditDetails.ciId,
				"cimGroup": this.props.breakFixEditDetails.cimGroup,
				"cimGroupname": this.props.breakFixEditDetails.cimGroupname,
				"cimUser": this.props.breakFixEditDetails.cimUser,
				"cimUsername": this.props.breakFixEditDetails.cimUsername,
				"ciClassName": this.props.breakFixEditDetails.ciClassName,
				"environment": this.props.breakFixEditDetails.environmentName,
				"ciConsumerCompany": this.props.breakFixEditDetails.ciConsumerCompany,
				"ciSupportCompany": this.props.breakFixEditDetails.ciSupportCompany,
				"ciClassId": this.props.breakFixEditDetails.serviceBased ? this.state.valuesImpClassId==='' || typeof this.state.valuesImpClassId==='undefined' ? '' : this.state.valuesImpClassId : this.props.breakFixEditDetails.ciClassId,
				"ciLocationId": this.state.valuesImpClassLocationId===''?this.props.breakFixEditDetails.ciLocationId:this.state.valuesImpClassLocationId,
				"ciLocationName": this.state.valuesCILocationValue===''?this.props.breakFixEditDetails.ciLocationName:this.state.valuesCILocationValue,
				// "requesterLocationId": this.props.breakFixEditDetails.requesterLocationId,
				// "requesterLocationName": this.props.breakFixEditDetails.requesterLocationName
				"requesterLocationId": value1,
				"requesterLocationName": value2,
				"businessCriticalityId": this.state.newbusinessCriticalityId,
			    "businessCriticality": this.state.newbusinessCriticality,
				"proposed": true,
			    "proposedWorkItemId": this.props.breakFixEditDetails.breakfixId,
			    "proposedModule": "breakfix"

			})
				.then(function (response) {
					console.log("creating investigation");
					//console.log("creating investigation"+ref.state.msgSubmit);
					console.log(response);
					if (response.status === 201) {
						let activityDetails ={
							"itemId": ref.props.breakFixEditDetails.breakfixId,
							"description":`Added a Related Work Item - ${ref.props.breakFixEditDetails.breakfixNumber} Is a Parent of ${response.data.investigationNumber}`,
							"isInternal":"Y",
							"createdOn":"",
							"createdBy":"",
							"module":"Breakfix",
							"createdByName":"",
							"sendNotification":"true",
							"isExternal":""
						};
						axios.post("/serviceManagement/rest/activity/", activityDetails);
						axios.post(GLOBAL.breakFixRelatedLogPostUrl, {
							"itemId": ref.props.breakFixEditDetails.breakfixId,
							"status": "A",
							"createdOn": "",
							"createdBy": "",
							"modifiedOn": "",
							"modifiedBy": "",
							"module": "Breakfix",
							"workItemId": response.data.investigationId,
							"workModule": "Investigation",
							"workItemNumber": response.data.investigationNumber,
							"createdByName": "",
							"relationType": "p2c",
							"itemNumber": ref.props.breakFixEditDetails.breakfixNumber
						});

						// axios.post(GLOBAL.breakFixRelatedLogPostUrl, {
						// 	"itemId": response.data.investigationId,
						// 	"status": "A",
						// 	"createdOn": "",
						// 	"createdBy": "",
						// 	"modifiedOn": "",
						// 	"modifiedBy": "",
						// 	"module":"Investigation",
						// 	"workItemId":ref.props.breakFixEditDetails.breakfixId,
						// 	"workModule":"Breakfix",
						// 	"workItemNumber":ref.props.breakFixEditDetails.breakfixNumber,
						// 	"createdByName": "",
						// 	"relationType":"p2p"
						// })
						if (response.ciId != 0) {
							console.log("yesssssss", response);
							let payloadObj ={
								"ci_id": response.data.ciId,  //==> ID of CI related
								"wi_id_related": response.data.investigationId, //==> ID of Work Item related
								"wi_id_number": response.data.investigationNumber,//  ==> Number of work item related
								"ci_id_reported": response.data.createdOn,
								"created_by": "",
								"relationship_type": "Y",
								"module": "Investigation"
							}
							axios.post(GLOBAL.cmdbRelatedLogDeleteUrl, /*{
								"ci_id": response.data.investigationId,
								"ci_id_related": response.data.ciId,
								"ci_id_number": response.data.investigationNumber,
								"ci_id_reported": response.data.createdOn,
								"created_by": "",
								"relationship_type": "Y",
								"module": "Investigation"
							}*/
							payloadObj
							).
								then((response) => {
									//alert("Data successfully submitted")
									//this.props.loadChangeRelatedWorkDetails("ci_name","---","CMDB",userDetails.company_id);
									//GLOBAL.relatedtextdisplay= <div className="f-size-18 margin-b-5">{this.props.translator['Related Config Items']}</div>
									//this.props.loadChangeRelatedDataDetails(itemId,"Breakfix");
									//this.setState({toggleFormAndList:'list'});
									console.log("this incident have been related to CMDB");
									//GLOBAL.ciName="";GLOBAL.ciId="";GLOBAL.ciIdNumber="";
								});
						}

						ref.setState({
							successMessage: response.data.investigationNumber + " has been successfully submitted. For more information, please navigate to the record through Investigation List view"
						})

					} else {
						ref.setState({
							successMessage: "Investigation not created"
						});
					}

					//browserHistory.push("/investigationEdit/"+response.data.investigationId.toString());
				})
				.catch(function (error) {
					console.log(error);
					ref.setState({
						successMessage: "Investigation not created"
					});
				});
		}
	}
	//End===========



	setErrorColor(errorColor, fieldType) {
		switch (fieldType) {
			case 'impactedServiceName':
				this.setState({ impactedServiceName: errorColor });
				GLOBAL.serviceErrorColor = "";

				break;
			case 'consumer':
				this.setState({ consumer: errorColor });
				GLOBAL.consumerErrorColor = "";
				break;
			default:

		}

	}
	setDropDownValue(event, fieldType) {
		switch (fieldType) {
			case 'supportPGroup':
				this.setState({ supportPGroup: event.target.value });
				break;
			case 'supportPIndividual':
				this.setState({ supportPIndividual: event.target.value });
				break;
			case 'criticality':
				this.setState({ criticality: event.target.value });
				break;
			case 'urgencyMode':
				this.setState({ urgencyMode: event.target.value });
				break;
			case 'source':
				this.setState({ Source: event.target.value });
				break;
			default:

		}
	}

	onChangeUrgency(e) {
		let criticalityValue = this.props.criticality===null?0:this.props.criticality;
		let urgencyValue = e.target.value===''?0:e.target.value;
		let priorityData = '';
		let priorityActualValue = '';
		let priorityActualColor = '';
		let priorityUrl = `/serviceManagement/rest/priority?module=Investigation&criticality=${criticalityValue}&urgency=${urgencyValue}`;
		if (criticalityValue != "") {
			axios.get(priorityUrl).then((response) => {
				priorityData = response.data;
				priorityActualValue = priorityData.priorityValue;
				priorityActualColor = priorityData.priorityColor;
				GLOBAL.priorityColorType = priorityActualColor;
				this.setState({
					priorityValue: priorityActualValue,
					priorityColorValue: priorityActualColor
				});
			});
		}
	}

	onChangeCriticality(e) {
		let criticalityValue = e.target.value===''?0:e.target.value;
		let urgencyValue = this.props.urgencyMode===null?0:this.props.urgencyMode;
		let priorityData = '';
		let priorityActualValue = '';
		let priorityActualColor = '';
		let priorityUrl = `/serviceManagement/rest/priority?module=Investigation&criticality=${criticalityValue}&urgency=${urgencyValue}`;
		if (urgencyValue != "") {
			axios.get(priorityUrl).then((response) => {
				priorityData = response.data;
				priorityActualValue = priorityData.priorityValue;
				priorityActualColor = priorityData.priorityColor;
				GLOBAL.priorityColorType = priorityActualColor;
				this.setState({
					priorityValue: priorityActualValue,
					priorityColorValue: priorityActualColor
				});
			});
		}
	}

	validateField(fieldType, event) {
		switch (fieldType) {
			case 'issueDescription':
				if (event.currentTarget.defaultValue == "") {
					this.setState({ issueDescription: 'error' });
					GLOBAL.pIssueErrorColor = "";
				} else {
					this.setState({ issueDescription: '' });
					GLOBAL.pIssueErrorColor = "";
				}
				break;
			case 'supportPGroup':
				if (event.target.value == 0) {
					this.setState({ supportPGroupErrorColor: 'error' });
					GLOBAL.sgErrorColor = "";
				} else {
					this.setState({ supportPGroupErrorColor: '' });
					GLOBAL.sgErrorColor = "";
				}
				break;
			case 'supportPIndividual':
				if (event.target.value == 0) {
					this.setState({ supportPIndividualErrorColor: 'error' });
					GLOBAL.sgIErrorColor = "";
				} else {
					this.setState({ supportPIndividualErrorColor: '' });
					GLOBAL.sgIErrorColor = "";
				}
				break;
			case 'criticality':
				if (event.target.value == 0) {
					this.setState({ pCriticalityErrorColor: 'error' });
					GLOBAL.pCriticalityErrorColor = "";
				} else {
					this.setState({ pCriticalityErrorColor: '' });
					GLOBAL.pCriticalityErrorColor = "";
				}
				break;
			case 'urgencyMode':
				if (event.target.value == "") {
					this.setState({ urgencyModeErrorColor: 'error' });
					GLOBAL.pUrgencyErrorColor = "";
				} else {
					this.setState({ urgencyModeErrorColor: '' });
					GLOBAL.pUrgencyErrorColor = "";
				}
				break;
			case 'source':

				if (event.target.value == "") {
					this.setState({ sourceErrorColor: 'error' });
					GLOBAL.sourceErrorColor = "";
				}
				else {
					this.setState({ sourceErrorColor: '' });
					GLOBAL.sourceErrorColor = "";
				}
				break;
			default:

		}
	}

	componentWillMount() {
		//this.props.loadBreakFixSourceName();
		GLOBAL.proposeproblemfromimpacted = 1;

		// let strObj = {};
		// strObj.userId = "";
		// strObj = JSON.stringify(strObj);
		// api.get(GLOBAL.breakFixConsumerUrl, { headers: { 'query': strObj } }).then((response) => {
		// 	consumerData = response.data;
		// 	for (var i = 0; i < consumerData.length; i++) {
		// 		myObj = { value: '', label: '' };
		// 		myObj.value = consumerData[i].userId;
		// 		myObj.label = consumerData[i].fullname + "|" + consumerData[i].userId + "|" + consumerData[i].email;
		// 		GLOBAL.consumerCompanybreakfix = consumerData[i].companyId;
		// 		consumerDataDetails.push(myObj);
		// 	}
		// 	consumerDataDetails = Array.from(new Set(consumerDataDetails));
		// });

		var str = {};
		str.userId = "";
		str.companyId = "";
		str.associatedCompanyId = "";
		str = JSON.stringify(str);

		api.get(GLOBAL.breakFixServiceWithoutDomainUrl, { headers: { 'query': str } }).then((response) => {
			impactedServiceData = response.data;
			console.log(impactedServiceData);
			for (var i = 0; i < impactedServiceData.length; i++) {
				myObj0 = { value: '', label: '', companyname: '' };
				myObj0.value = impactedServiceData[i].offeringId;
				myObj0.label = impactedServiceData[i].offeringName + "|" + impactedServiceData[i].categoryName + "|" + impactedServiceData[i].subCategoryName;
				myObj0.companyname = impactedServiceData[i].company
				//GLOBAL.consumerCompanybreakfix=impactedServiceData[i].companyName;
				//GLOBAL.consumerCompanybreakfix = consumerData[i].companyId;
				impactedServiceDataDetails.push(myObj0);
			}
			impactedServiceDataDetails = Array.from(new Set(impactedServiceDataDetails));
		});

		this.props.getPriorityUrgencyCriticalityValues("Investigation", "Criticality");
		this.props.getPriorityUrgencyCriticalityValues("Investigation", "Urgency");
		this.props.getPriorityUrgencyCriticalityValues("Investigation", "source");
		if(!this.props.breakFixEditDetails.serviceBased){
            let queryPayload={};
            queryPayload.searchByName="ci_id";
            queryPayload.searchByValue=this.props.breakFixEditDetails.ciId+"";
            queryPayload.companyId=this.props.breakFixEditDetails.consumerCompany;
            queryPayload=JSON.stringify(queryPayload);
            axios.get('/api/config_items/V2?limit=1&page=1',{headers: {query: queryPayload}}).then((respondedCIDetails)=>{
                this.setState({newbusinessCriticalityId: respondedCIDetails.data.data[0].METALLIC===""||respondedCIDetails.data.data[0].METALLIC===null?0:respondedCIDetails.data.data[0].METALLIC,
					           newbusinessCriticality: respondedCIDetails.data.data[0].METALLIC_NAME});
                });
        }
	}

	/*componentWillReceiveProps(nextProps){
		console.log("nextProps")
		console.log(nextProps)
		alert(nextProps.submit.submitSucceeded)
	}*/

	/*renderSourceName(breakFixData) {
		if (!breakFixData) {
			return (
				<option></option>
			);
		}

		return breakFixData.map((breakFixObj) => {
			return (
				<option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
			);
		});
	}*/
	componentWillUnmount() {
		GLOBAL.proposeproblemfromimpacted = 0;
		GLOBAL.businessCriticalityId=this.state.oldbusinessCriticalityId;
	    GLOBAL.businessCriticality=this.state.oldbusinessCriticality
	}
	renderPriorityName(breakFixData) {
		if (!(breakFixData && Array.isArray(breakFixData))) {
			return (
				<option></option>
			);
		}

		return breakFixData.map((breakFixObj) => {
			breakFixObj.field1Key = this.state.priorityValue;
			return (
				<option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
			);
		});
	}

	renderCriticalityName(breakFixData) {
		console.log("breakFixData--",breakFixData);
		if (!(breakFixData && Array.isArray(breakFixData))) {
			return (
				<option></option>
			);
		}
		// breakFixData.map((a) => {
		// 	a.field1Value = parseInt(a.field1Value, 10);
		// })
		breakFixData.forEach((a) => {
			a.field1Value = parseInt(a.field1Value, 10);
		})

		breakFixData.sort((a, b) => (a.field1Value > b.field1Value) ? 1 : -1);
		if (!breakFixData) {
			return (
				<option></option>
			);

		}
		return breakFixData.map((breakFixObj) => {

			return (
				<option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
			);
		});
	}

	renderUrgencyName(breakFixData) {
		if (!breakFixData) {
			return (
				<option></option>
			);
		}
		let sorteddata = [];
		sorteddata = _.sortBy(breakFixData, o => parseInt(o.field1Value, 10));
		return sorteddata.map((breakFixObj) => {

			return (
				<option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
			);
		});
	}
	renderSourceData(breakFixData) {
		if (!(breakFixData && Array.isArray(breakFixData))) {
			return (
				<option></option>
			);
		}
		return breakFixData.map((breakFixObj) => {

			return (
				<option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
			);
		});
	}
	// onServiceNameChange(serviceIdValue) {
	// 	let amsAttribute={
	// 		"Status":this.props.breakFixEditDetails.statusId,
    //   "Priority":this.props.breakFixEditDetails.priorityId,
    //   "Service":'0',
    //   "Impacted CI":this.props.breakFixEditDetails.ciId,
    //   "Requestor":this.props.breakFixEditDetails.impactedUserid,
    //   "Class":this.props.breakFixEditDetails.ciClassId,
    //   "User.Location":this.props.breakFixEditDetails.requesterLocationId,
    //   "CI.Location":this.props.breakFixEditDetails.ciLocationId
	// 	}
	// 	if (serviceIdValue != "") {
	// 		//  this.props.loadBreakFixSG(GLOBAL.breakFixServiceID);
	// 		this.props.loadNewBreakFixSGAE(this.props.breakFixEditDetails.serviceBased ? this.props.breakFixEditDetails.serviceId : this.props.breakFixEditDetails.ciId, GLOBAL.breakFixCompanyName, "Investigation", this.props.breakFixEditDetails.serviceBased ? "service" : "impactedCI",amsAttribute);
	// 	} else {
	// 		console.log("Error in loading loadBreakFixSG");
	// 	}
	// }

	onGroupNameChange() {
		if (this.props.supportPGroup != '') {
			this.props.loadBreakFixSGI(this.props.supportPGroup);
		} else {
			console.log("Error in loading loadBreakFixSGI");
		}
	}

	renderSupportPGroup(breakFixData) {
		let grouplist = [];
		if (!breakFixData || breakFixData.length == 0) {
			return (
				null
			);
		}

		breakFixData.map((breakFixObj) => {
			grouplist.push(breakFixObj.assignmentGroupId + "~" + breakFixObj.assignmentGroupName)
		});
		//  console.log(grouplist.toString());

		grouplist = grouplist.filter(function (item, index, inputArray) {
			return inputArray.indexOf(item) == index;
		});
		//console.log("b:::"+grouplist.toString());

		return grouplist.map((breakFixObj) => {
			let breakfixobj1 = breakFixObj.split("~");
			return (
				<option value={breakfixobj1[0]}>{breakfixobj1[1]}</option>
			);
		});
	}

	renderSupportPIAEGroup(breakFixData) {
		//console.log("bgid::"+GLOBAL.breakfixgroupid)
		if (!breakFixData || breakFixData.length == 0) {
			return (
				null
			);
		}
		return breakFixData.map((breakFixObj) => {
			if (breakFixObj.assignmentGroupId == GLOBAL.breakfixgroupid && breakFixObj.assignmentMemberId != 0)
				return (
					<option value={breakFixObj.assignmentMemberId}>{breakFixObj.assignmentMemberName}</option>
				);
		});
	}

	setSupportIndividual(e) {
		GLOBAL.breakfixgroupid = e.target.value
		GLOBAL.supportPGroupName = e.target[e.target.selectedIndex].text
		//console.log("e.target.value"+GLOBAL.supportPGroupName)
		//    this.props.loadBreakFixSGAE(GLOBAL.breakFixServiceID,GLOBAL.breakFixCompanyName);

	}
	setSupportIndividualName(e) {

		GLOBAL.supportPIGroupName = e.target[e.target.selectedIndex].text
		//console.log("e.target.value"+GLOBAL.supportPIGroupName)
		//    this.props.loadBreakFixSGAE(GLOBAL.breakFixServiceID,GLOBAL.breakFixCompanyName);

	}
	getLabel(event) {
		GLOBAL.supportPIGroupName = '';
		this.props.breakFixSGIName.map((obj) => {
			//alert(obj.field1Value);
			if (obj.field1Value == event.target.value) {
				GLOBAL.supportPIGroupName = obj.field1Key;

			}
		})
		//  console.log(GLOBAL.supportPIGroupName);
	}
	onImpactedSelection(impactedID, companyNameVal) {
		// 	console.log("GLOBALGLOBAL",GLOBAL.ciId, GLOBAL.ciConsumerCompany,impactedID)
		// 	if(impactedID!="")
		// 	{
		// 		if(this.state.onCreateUsingSelect=='impactedCI')
		// 		{
		//     this.props.loadBreakFixSGAE(GLOBAL.ciId, this.props.breakFixEditDetails.ciConsumerCompany,"Breakfix","impactedCI");
		//   this.props.dispatch(change('xsmBreakFixFormTab','supportPIndividual',''));
		// 		this.props.dispatch(change("xsmBreakFixFormTab", "supportPGroup",''));

		// 		}
		// 	}
		console.log("impactedid", impactedID);
		this.setState({ companyId: this.props.breakFixEditDetails.ciConsumerCompany });
		this.setState({
			valuesImpacted: GLOBAL.ciNameRfc,
			valuesImpClassId: GLOBAL.ciClassIdRfc,
			valuesImpClassLocationId: GLOBAL.ciLocationIdRfc,
			valuesCILocationValue: GLOBAL.ciLocationNameRfc,
			valuesImpactedId: GLOBAL.ciIdRfc,
			errorColor: ""
		});
		this.setState({newbusinessCriticalityId: GLOBAL.businessCriticalityId, newbusinessCriticality: GLOBAL.businessCriticality});
		GLOBAL.businessCriticalityId=this.state.oldbusinessCriticalityId;
		GLOBAL.businessCriticality=this.state.oldbusinessCriticality;
		this.showImpactedCIInfoIcon(true);
	}
	renderSupportPIGroup(breakFixData) {
		if (!breakFixData || breakFixData.length == 0) {
			return (
				null
			);
		}
		return breakFixData.map((breakFixObj) => {
			return (
				<option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
			);
		});
	}
	showImpactedCIInfoIcon(value){
		this.setState({impIconshow: value});
	}
	render() {
		const imptCI = (
            <Popover className="userInfoPopup">
            <CIDEtails ciId={GLOBAL.ciIdRfc} translator={this.props.tr}/>
            </Popover>
        );
		//alert(GLOBAL.pThroughErrorColor)
		//alert(this.state.reportedpThroughErrorColor)
		//    console.log("this.props.investigationSource",GLOBAL.ciId);
		console.log("GLOBAL<CUNAME", GLOBAL.ciName, GLOBAL.ciId);
		let { error, handleSubmit, pristine, reset, submitting, issueDescription, criticality, urgencyMode, priority } = this.props;
		GLOBAL.priorityValueType = this.state.priorityValue
		if (this.props.breakFixSGName instanceof Error) {
			return (
				<h2>
					Network Error Occured...Break Fix will be here soon....
				</h2>
			);
		}

		if (this.props.breakFixSGIName instanceof Error) {
			return (
				<h2>
					Network Error Occured...Break Fix will be here soon....
				</h2>
			);
		}

		if (this.state.successMessage == "") {
			return (

				<div>
					<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
						<ul>
							<li>
							<button
							    type='button'
								title="Minimize the right panel"
								bsClass=""
								bsStyle=""
								className="closerightPanelBtn"
								onClick={() => {
								this.props.rightEditPanel(false);
								this.props.changeRightView("allClose");
								}}
							>
								<IoClose />
							</button>
							</li>
						</ul>
					</div>
					<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Request For Investigation']}</div></div>
					
					<div className='rBoxGap'>
					<font color="red">{error && <strong>{error}</strong>}</font>
					<div className="borderForm bFormDiv">
						{this.props.breakFixEditDetails.serviceBased ?
							<Row>
								<Col md={12}>
									<Form.Group className="form-group" 
									// validationState={this.props.ImpactedErrorColor || this.state.errorColor}
									>
										<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Impacted CI']}</Form.Label>
										{/*  {this.renderSupportPGroup(this.props.breakFixSGName)}*/}
										<div className='position-re infoBtnDiv'>
										<Field name="ciName" className="form-control"
											onImpactedSelection={this.onImpactedSelection}
											setErrorImpactedColor={this.props.setErrorImpactedColor}
											companyId={this.props.breakFixEditDetails.consumerCompany}
											component={CIDetailsService}
											view={"CreateRfc"}
											showImpactedCIInfoIcon={this.showImpactedCIInfoIcon}
											onType={this.showImpactedCIInfoIcon}
											errorColor={this.props.ImpactedErrorColor || this.state.errorColor}
											>
										</Field>
										{this.state.impIconshow?<OverlayTrigger trigger="click" rootClose placement="left" overlay={imptCI}><span className="infoBtnDiv infoicn"><ImInfo/></span></OverlayTrigger>:null}
               </div>
									</Form.Group>
								</Col>
							</Row> : null}
						<Row>
							<Col md={12}>
								<Form.Group className="form-group" 
								// validationState={this.state.issueDescription == '' ? GLOBAL.pIssueErrorColor : this.state.issueDescription}
								>
									<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Problem Statement']} </Form.Label>
									<div className="position-re">
										<Field name="issueDescription" component="textarea" className={"form-control " + (this.state.issueDescription == '' ? GLOBAL.pIssueErrorColor : this.state.issueDescription)} rows={2} onBlur={(event) => { this.validateField('issueDescription', event) }}  maxLength="2000"/>
									</div>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={12}>
								<Form.Group className="form-group" 
								// validationState={this.state.sourceErrorColor == '' ? GLOBAL.sourceErrorColor : this.state.sourceErrorColor}
								>
									<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Source']}</Form.Label>
									{/*  {this.renderSupportPGroup(this.props.breakFixSGName)}*/}
									<Field name="source" component="select" type="select" className={"form-control " + (this.state.sourceErrorColor == '' ? GLOBAL.sourceErrorColor : this.state.sourceErrorColor)} value={this.state.source} onBlur={(event) => { this.validateField('source', event) }} onChange={(event) => { this.setDropDownValue('source', event); this.props.dispatch(change("createInvestigationForm", "source", event.target[event.target.selectedIndex].text)); }}>
										<option value="">Select..</option>
										{this.renderSourceData(this.props.investigationSource)}
									</Field>

								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={12}>
								<Row>
									<Col md={6}>
										<Form.Group className="form-group" 
										// validationState={this.state.urgencyModeErrorColor == '' ? GLOBAL.pUrgencyErrorColor : this.state.urgencyModeErrorColor}
										>
											<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Urgency']}</Form.Label>
											<Field name="urgencyMode" component="select" type="select" className={"form-control " + (this.state.urgencyModeErrorColor == '' ? GLOBAL.pUrgencyErrorColor : this.state.urgencyModeErrorColor)} value={this.state.urgencyMode} onBlur={(event) => { this.validateField('urgencyMode', event) }} onChange={(event) => { this.setDropDownValue('urgencyMode', event); this.onChangeUrgency(event); }}>
												<option value="">Select..</option>
												{this.renderUrgencyName(this.props.investigationUrgency)}
											</Field>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="form-group" 
										// validationState={this.state.pCriticalityErrorColor == '' ? GLOBAL.pCriticalityErrorColor : this.state.pCriticalityErrorColor}
										>
											<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Impact']}</Form.Label>
											<Field name="criticality" component="select" type="select" className={"form-control " + (this.state.pCriticalityErrorColor == '' ? GLOBAL.pCriticalityErrorColor : this.state.pCriticalityErrorColor)} value={this.state.criticality} onBlur={(event) => { this.validateField('criticality', event) }} onChange={(event) => { this.setDropDownValue('criticality', event); this.onChangeCriticality(event); }}>
												<option value="">Select..</option>
												{this.renderCriticalityName(this.props.investigationCriticality)}
											</Field>
										</Form.Group>
									</Col>

									{/*<Col md={6}>
										<Form.Group className="form-group" validationState={this.state.reportedpThroughErrorColor == '' ? GLOBAL.pThroughErrorColor : this.state.reportedpThroughErrorColor}>
										  <Form.Label bsClass=""><span className="rStar"></span>Through</Form.Label>
											<Field name="reportedThrough" component="select" type="select" className="form-control" value = {this.state.reportedThroughValidaton } onBlur = {(event)=>{this.validateField('reportedThrough',event)}} onChange = {(event)=>{this.setDropDownValue('reportedThrough',event);}}>
		                    <option value="">Select..</option>
		                    {this.renderSourceName(this.props.breakFixSourceName)}
					          	</Field>
										</Form.Group>
									</Col>*/}

								</Row>
							</Col>
							<Col md={12}>
								<Form.Group className="form-group">
									<Form.Label bsClass="">{this.props.translator['Priority']}</Form.Label>
									<Field name="priority" component="input" type="text" className="form-control" disabled="true" placeholder={this.state.priorityValue} value={this.state.criticality}>
									</Field>
								</Form.Group>
							</Col>
						</Row>
					</div>

					<ButtonToolbar className="black"><Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary"
						disabled={submitting} onClick={handleSubmit(this.submit)}>
						{submitting ? <ImSpinner6 className="icn-spinner"/>
							: null} {this.props.translator['Submit']}</Button>
					</ButtonToolbar>

					{/* <Button bsStyle="primary" onClick={handleSubmit(this.submit)} >
		  {this.props.translator['Submit']}</Button> */}

					</div>
				</div>

			);
		} else {
			return (
				<div>
					<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
						<ul>
							<li>
							<button
							    type='button'
								title="Minimize the right panel"
								bsClass=""
								bsStyle=""
								className="closerightPanelBtn"
								onClick={() => {
								this.props.rightEditPanel(false);
								this.props.changeRightView("allClose");
								}}
							>
								<IoClose />
							</button>
							</li>
						</ul>
					</div>
					<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Request For Investigation']}</div></div>
					<div className='rBoxGap'>{this.state.successMessage}</div>
				</div>

			);
		}
	}
}

CreateBreakFixInvestigationForm = reduxForm({
	form: 'createBreakFixInvestigationForm',
	fields: ['issueDescription', 'criticality', 'urgencyMode', 'source', 'ciName'],
	initialValues: { issueDescription: "", criticality: "", urgencyMode: "", source: "", ciName: "" },
	destroyOnUnmount: true

})(CreateBreakFixInvestigationForm);

let selector = formValueSelector('createBreakFixInvestigationForm')
CreateBreakFixInvestigationForm = connect(
	state => {
		//let supportPGroup = selector(state, 'supportPGroup')
		let criticality = selector(state, 'criticality')
		let urgencyMode = selector(state, 'urgencyMode')
		let source = selector(state, 'source')
		let ciName = selector(state, 'ciName')
		return {
			//supportPGroup,
			criticality,
			urgencyMode,
			source,
			ciName
		}
	}
)(CreateBreakFixInvestigationForm)
export function mapStateToProps({ investigationCriticality, investigationSource,
	investigationUrgency, breakFixSGName, breakFixSGIName, breakFixSGAEName, breakFixEditDetails }) {
	return {
		investigationCriticality, investigationSource,
		investigationUrgency, breakFixSGName, breakFixSGIName, breakFixSGAEName, breakFixEditDetails
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getPriorityUrgencyCriticalityValues, loadBreakFixSGI
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBreakFixInvestigationForm);
