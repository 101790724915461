
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { TextArea } from "@progress/kendo-react-inputs";

const TextAreaField = (props) => {
  let { attributes, onValueChange, defaultValue, className, readOnly, nonEditable, id } = props;
  if(nonEditable){
     className = className?className+" inactive":"inactive";
  }
  return (
    <TextArea
      type={attributes?.type}
      disabled={attributes?.disabled || nonEditable || readOnly}
      maxlength={attributes?.maxLength}
      minLength={attributes?.minLength}
      onChange={onValueChange}
      value={defaultValue || ""}
      autoSize={false}
      className={className}
      id={id}
      readOnly={readOnly}
    />
  );
};

export default TextAreaField;
