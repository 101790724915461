
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import axios from 'axios';
import { GLOBAL } from '_Globals';
import { bindActionCreators } from 'redux';
import { getInvestigationItemDetails,getInvestigationItemDetailsById } from '_Actions/investigation/investigationAction.js';
import { loadBreakFixAuditLogDetails } from '_Actions/breakFix/breakFixAuditLogAction';
import { loadStatusBasedHamburgerOptions } from '_Actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '_Actions/common/commonApisActions.js';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import {ImSpinner6} from "react-icons/im";
import {IoClose} from "react-icons/io5";
class ProposeCriticalTab extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			// correctiveActionRequired: 'select',
			comments: '',
			saveLoader:false
		};
		// this.setCorrectiveAction = this.setCorrectiveAction.bind(this);
		this.setComments = this.setComments.bind(this);
		this.saveCorrectiveAction = this.saveCorrectiveAction.bind(this);
	}
/*
	componentWillMount() {
		this.props.getInvestigationItemDetails(this.props.investigationId);
	} */

	// setCorrectiveAction(value) {
	// 	switch (value) {
	// 		case 'Y':
	// 			this.setState({ correctiveActionRequired: 'Y' });
	// 			break;
	// 		case 'N':
	// 			this.setState({ correctiveActionRequired: 'N' });
	// 			break;
	// 	}
	// }

	setComments(value) {
		this.setState({ comments: value });
	}
	saveCorrectiveAction() {
		if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident'))
				swal({
					text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
					button: this.props.translator['OK'],
				  });
        else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') ))
				   swal({
					  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
					  button: this.props.translator['OK'],
				    });
		else
		{
		let ref = this;

		let desc="Corrective action:"+this.state.comments;

		let postjsonactivity = {
			"itemId":this.props.investigationId,
			"description":desc,
			"isInternal":"",
			"createdOn":"",
			"createdBy":"",
			"module":"Investigation",
			"createdByName":""
		};
		if (this.state.comments.length == 0) {
			Swal.fire('Comments can not be left blank.');
		} else {
			this.setState({saveLoader:true});
			axios.patch(GLOBAL.investigationEditUrl + this.props.investigationId, {
                "correctiveActionRequired": 'N',
                "correctiveActions": this.state.comments,
                "status": "55",
				"modifiedBy":"",
				"modifiedByName":""
            })
                .then(function (response) {
                    if (response.status === 200) {
                        axios.patch(GLOBAL.investigationEditUrl + ref.props.investigationId, {
                            "status": "60",
							"modifiedBy":"",
							"modifiedByName":""
                        }).then((response) => {
                            if(response.status === 200) {
                                ref.setState({saveLoader:false});
                                ref.props.changeActionView('processHierarchy');
                                ref.props.getInvestigationItemDetailsById(ref.props.investigationId);
                                // ref.props.loadBreakFixAuditLogDetails(response.data.investigationId.toString(), "dsc", "Investigation",'STATUS');
                                ref.props.loadStatusBasedHamburgerOptions('Investigation',response.data.status,response.data.investigationId);
                            }
                        });
                    } else {
                        this.setState({saveLoader:false});
                        Swal.fire('error saving correction action');
                    }
                })
                .catch(function (error) {
                    this.setState({saveLoader:false});
                    console.log(error);
                });
		}
	}
	}

	render() {
		//Swal.fire(this.props.investigationItemDetails)
		//Swal.fire(this.props.investigationItemDetails.correctiveActionRequired)
		if (this.props.investigationItemDetails.length == 0) {
			return (
				<div></div>
			)
		}

		return (
			<div className="hambTab">
				<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
					<ul>
						<li>
						<button
						    type='button'
							title="Minimize the right panel"
							bsClass=""
							bsStyle=""
							className="closerightPanelBtn"
							onClick={() => {
							this.props.rightEditPanel(false);
							this.props.changeActionView("");
							}}
						>
							<IoClose />
						</button>
						</li>
					</ul>
				</div>
				<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Corrective Action without RFC']}</div></div>
				<div className='rBoxGap'>
					<div>
						<Form.Group >
							<Form.Group >
								<Form.Label><span className="rStar"></span>{this.props.translator['Comments']} </Form.Label>
								<Form.Control as="textarea" rows="4" onChange={(event) => { this.setComments(event.target.value) }} />
							</Form.Group>
						</Form.Group>

						<ButtonToolbar className="black margin-t-10">
							<Button className='rgSidrkBtn smallBtn' disabled={this.state.saveLoader}
								onClick={() => { this.saveCorrectiveAction(); }}>
								{this.state.saveLoader ?
									<ImSpinner6 className="icn-spinner"/> : null} {this.props.translator['Submit']}
							</Button>
							<Button className="rgSiLigBtn smallBtn" bsSize="small" bsStyle="primary" disabled={this.state.saveLoader}
								onClick={() => { this.props.changeActionView('processHierarchy') }}>
								{this.props.translator['Cancel']}
							</Button>
						</ButtonToolbar>
					</div>
				</div>
			</div>
		);

	}
}

export function mapStateToProps({ investigationItemDetails,invRelatedDataDetailsCount }) {
	return {  investigationItemDetails:(investigationItemDetails && investigationItemDetails.data ? investigationItemDetails.data[0]:investigationItemDetails),invRelatedDataDetailsCount };
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ getInvestigationItemDetails, loadBreakFixAuditLogDetails, getInvestigationItemDetailsById,loadStatusBasedHamburgerOptions, loadTimelineData }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposeCriticalTab);
//export default connect(mapStateToProps,null)(ProposeCriticalTab);
//export default ProposeCriticalTab;
