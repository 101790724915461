
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { gql } from "@apollo/client";

export const getKnowledgeData = gql`
   query SearchArticles($searchTerm: String!, $query: ArticleListInput!) {
       searchArticles(
          searchTerm: $searchTerm
          query: $query
      ) {
        data {
        id
        title
        content
        business_function
        business_function_id
        company
        company_name
        category
        category_id
        sub_category
        sub_category_id
        display_category
        display_category_id
        tag
        language
        type
        type_id
        article_type
        expires_on
        knowledge_owner_group_name
        knowledge_owner_group_id
        knowledge_management_group_name
        knowledge_management_group_id
        version
        source
        author
        author_id
        language_id
        article_type_id
        knowledge_base_id
        knowledge_base
        permalink
        scheduled_publish_date
        status
        status_id
        created_by
        created_by_id
        modified_by
        modified_by_id
        sx_id
        article_id
        createdAt
        updatedAt
        }
        meta {
            currentPage
            pageSize
            totalRecords
            totalPages
        }
    }
}
`;