
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import _, { update } from "lodash";
import Spinner from "react-spinkit";
import { Accordion, Alert, Col, Form, Row } from "react-bootstrap";
import { change, Field } from "redux-form";
import sanitizeHtml from 'sanitize-html';
import Joi from "joi";

import {
  getCategoryParentQuestions,
  getServiceCategoryCount,
  getServiceQuestionnaireCategories,
  getTypeAheadChildQuestion,
  loadOrderDeliverOptions,
  getMasterQuestionsPI,
  getUserDefinedServiceQuestionnaireCategories,
  getUrgencyValuesForIncident,
  getOfferingAttachmentsForCart,
  getChildQuestion,
  getChildQuestionForBulkQuestions,
} from "../../../../actions/cart/addToCartActions";
import { _disclaimerCheckbox } from "../../../common/ReduxFormFields/formFields";
import { useDispatch, useSelector } from "react-redux";
import ListLoader from "../../../common/loaders/ListLoader";
import PISectionFields from "./formFields/PISectionFields";
import dummyImage from "../../../../images/service-images/offering-dummy.png";
import { formatMinutesIntoDaysHoursMinutes } from "../../../../actions/common/functionActions.js";
import { ImInfo } from "react-icons/im";
import NumericInput from "react-numeric-input";

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

let CheckoutPISection = (props) => {
  const [componentIdforQuestionnaire, setComponentIdforQuestionnaire] = useState([]);
  const [categoryCountList, setCategoryCountList] = useState([]);
  const [dateFormat, setDateFormat] = useState("");
  const [itemQuantity, setItemQuantity] = useState("2");
  const [questionResponses, setQuestionResponses] = useState({});
  const [tableResponses, setTableResponses] = useState({});
  const [categories, setCategories] = useState([]);
  const [childQuestions, setChildQuestions] = useState([]);
  const [debouncedChildQuestions, setDebouncedChildQuestions] = useState(childQuestions);

  const urgencyValuesReducer = useSelector((state) => state.urgencyValuesReducer);
  const postAttributeBasedSlaReducer = useSelector((state) => state.postAttributeBasedSlaReducer);
  const userDefinedServiceQuestionnaireCategories = useSelector(state => state.userDefinedServiceQuestionnaireCategories);
  const serviceQuestionnaireCategories = useSelector(state => state.serviceQuestionnaireCategories);
  const cartOfferingAttachmentsReducer = useSelector(state => state.cartOfferingAttachmentsReducer);
  const questionnaireList = useSelector(state => state.questionnaireList);
  const windowSize = useSelector(state => state.windowSize);

  const dispatch = useDispatch();
  
  const setTemplateResponses = (qId, value) => {
    if(
      value?.type === 'Dynamic Dropdown' && 
      props?.templateValues?.[qId]?.['value'].length === 0 && 
      value.value.length === 0
    ) {
      return;
    }
    props.setTemplateValues({ [qId]: value });
  }

  const setChildQuestionsFn = (childQuestions) => {
    setChildQuestions((prev) => {
      return [...prev, childQuestions];
    });
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedChildQuestions(childQuestions);
    }, 500);
  
    return () => {
      clearTimeout(handler);
    };
  }, [childQuestions]);
  

  useEffect(() => {
    const updatedArr = debouncedChildQuestions;
    if (updatedArr.length > 0 && updatedArr.some((childQuestion) => !childQuestion.fetched)) {
      dispatch(getChildQuestionForBulkQuestions(updatedArr));
    }
  }, [debouncedChildQuestions]);

  useEffect(() => {
    let str = {};
    str.user_id = "";
    str = JSON.stringify(str);
    api
      .get("/api/userInfo", { headers: { query: str } })
      .then((response) => {
        let tempStr = response.data.dateformat.split(" ");
        let dateFormat = `${tempStr[0].toUpperCase()} ${tempStr[1]}`;
        setDateFormat(dateFormat);
      })
      .catch((error) => console.error(error));

    const { selectedComponentForOffering, offeringId, cartOfferings } = props;
    const minVal =
      cartOfferings && cartOfferings[0] && cartOfferings[0].minValue;
    let offeringIds = [];
    let componentIdforQuestionnaire = [];
    if (offeringId) {
      selectedComponentForOffering.map((component) => {
        if (component.offeringId.toString() === offeringId) {
          componentIdforQuestionnaire.push(component.id);
        }
      });
      dispatch(
        getServiceQuestionnaireCategories(
          props.offeringId,
          componentIdforQuestionnaire
        )
      );
      // dispatch(loadOrderDeliverOptions(offeringId));
      dispatch(
        getUserDefinedServiceQuestionnaireCategories(
          props.offeringId,
          componentIdforQuestionnaire
        )
      );
      dispatch(
        getServiceCategoryCount(
          offeringId,
          componentIdforQuestionnaire,
          "quickCheckout",
          (categoryCount) => {
            setCategoryCountList(categoryCount);
          }
        )
      );
      dispatch(getOfferingAttachmentsForCart(offeringId));
    } else {
      let categoryExistsForServices = false;
      props.cartOfferings.map((offerings) => {
        offeringIds.push(offerings.offeringId);
      });

      selectedComponentForOffering.map((component) => {
        for (let i = 0; i < offeringIds.length; i++) {
          if (offeringIds[i].toString() === component.offeringId) {
            componentIdforQuestionnaire.push(component.componentId);
          }
        }
      });

      for (let i = 0; i < offeringIds.length; i++) {
        let componentIdForService = [];
        for (let k = 0; k < selectedComponentForOffering.length; k++) {
          if (
            offeringIds[i].toString() ===
            selectedComponentForOffering[k].offeringId
          ) {
            componentIdForService.push(
              selectedComponentForOffering[k].componentId
            );
          }
        }
        dispatch(
          getServiceCategoryCount(
            offeringIds[i],
            componentIdForService,
            "cart",
            (categoryCount) => {
              /*check to un-hide validation message for service, as service gets added to the validation message object
               * before getting the category count, this happens on orderNowFromCart page, so need to disable the message
               * if service doesn't have category*/
              let categoryCoutObjetForService = _.find(categoryCount, {
                offeringId: offeringIds[i].toString(),
              });
              if (
                !categoryExistsForServices &&
                categoryCoutObjetForService &&
                categoryCoutObjetForService.categoryCount > 0
              ) {
                categoryExistsForServices = true;
              }
              setCategoryCountList(categoryCount);
            }
          )
        );
        dispatch(
          getServiceQuestionnaireCategories(
            offeringIds[i],
            componentIdForService
          )
        );
        dispatch(
          getUserDefinedServiceQuestionnaireCategories(
            offeringIds[i],
            componentIdForService
          )
        );
        dispatch(getOfferingAttachmentsForCart(offeringIds[i]));
      }
    }
    setComponentIdforQuestionnaire(componentIdforQuestionnaire);
    setItemQuantity(minVal);
    // props.setTemplateValuesRed(JSON.parse(localStorage.getItem("piValues")));
  }, []);

  const { categoryList } = useMemo(() => ({
    categoryList: categories
  }),
  [categories]);

  const spinnerChange = (event) => {
    if (
      event.keyCode == 69 ||
      event.keyCode == 190 ||
      event.keyCode == 187 ||
      event.keyCode == 189
    ) {
      event.preventDefault();
    }
  }

  const handleOnChange = (event) => {
    if(event.type === 'table') {
      let newObj = Object.assign({}, tableResponses, {[event.target.name]: event.target.value})
      setTableResponses(newObj);
      props.setPISectionTableFormValues(newObj);
    } else {
      if(event.target.value === '' || event.target.value === null || event.target.value.length === 0) {
        let quesResp = questionResponses;
        delete quesResp[event.target.name];
        setQuestionResponses(quesResp);
        props.setPISectionFormValues(quesResp);
      }
      else {
        let newObj = questionResponses;
        newObj[event.target.name] = event.target.value;
        if(event.target.dataId) {
          newObj[event.target.name + '_dataId'] = event.target.dataId;
        }
        setQuestionResponses(newObj);
        props.setPISectionFormValues(newObj);
      }
    }
  }

  const setFormErrors = (errorsObj) => {
    props.setPISectionFormErrors(errorsObj);
  }

  const renderOfferingAttachments = (offeringComponents, offeringId) => {
    if (offeringComponents.length > 0) {
      let offeringAttachments = _.find(offeringComponents, {
        serviceId: offeringId.toString(),
      });
      if (
        offeringAttachments?.attachments &&
        offeringAttachments?.attachments?.length > 0
      ) {
        return (
          <div class="attachErpIcn">
            <span style={{ marginRight: "10px", color: "#000000" }}>
              Download Template
            </span>
            <ul style={{ display: "inline" }}>
              {offeringAttachments.attachments.map((attachment, index) => {
                let validated_url = undefined;
                if (attachment && attachment.ATTACHMENT_URL != undefined) {
                  let namedSchema = Joi.string().regex(/^[^<>}{]+$/);
                  let validator_to_add_myQbj = namedSchema.validate(
                    attachment.ATTACHMENT_URL
                  );
                  if (validator_to_add_myQbj.error == null) {
                    validated_url = attachment.ATTACHMENT_URL;
                  } else {
                    validated_url = "\\";
                  }
                } else validated_url = "\\";

                return (
                  <li>
                    <a
                      href={validated_url}
                      title={attachment.ORIGINAL_ATTACHMENT_NAME}
                    >
                      {attachment.ORIGINAL_ATTACHMENT_NAME.length > 20
                        ? attachment.ORIGINAL_ATTACHMENT_NAME.slice(0, 20) +
                          "..."
                        : attachment.ORIGINAL_ATTACHMENT_NAME}
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </a>
                    {index !== offeringAttachments.attachments.length - 1 ? (
                      <b style={{ color: "#000000" }}> ,</b>
                    ) : null}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      }
    }
  };

  const renderDeliverOptions = (offeringId, deliveryTypes) => {

    if (deliveryTypes.length === 0) {
      return <div>{props.translator["No Active SLA found"]}</div>;
    } else {
      return deliveryTypes.map((delOpt, index) => {

        // if (index === 0) {
        //   dispatch(setServiceDeliveries({
        //     offeringId: delOpt.offeringId,
        //     deliverType: delOpt.goalTime,
        //   }));
        // }
        let finalValue = formatMinutesIntoDaysHoursMinutes(delOpt.goalTime);
        return (
          <Form.Check
            type="radio"
            key={finalValue}
            inline
            value={finalValue}
            name={offeringId}
            // onClick={() => {
            //   dispatch(setServiceDeliveries({
            //     offeringId: delOpt.offeringId,
            //     deliverType: delOpt.goalTime,
            //   }));
            // }}
            defaultChecked={deliveryTypes.length === 0 || index === 0}
          >
            {finalValue === "data not available."
              ? props.translator["'" + finalValue + "'"]
              : finalValue}
          </Form.Check>
        );
      });
    }
  };

  const renderCategoryQuestions = useCallback((serviceId, offering, category) => {
    if (
      category.parentQuestions.length === 0 &&
      category.QUESTION_PARENT_ID === 0
    ) {
      return (
        <Row>
          <Col md={6}>
            <h6>{props.translator["loading..."]}</h6>
          </Col>
        </Row>
      );
    }
    let indents = [];
    category.parentQuestions.forEach((question) => {
      indents.push(renderMasterQuestionnaireList(question, offering));
    });
    if (category.otherCategoryQuestions.length !== 0) {
      category.otherCategoryQuestions.forEach((question) => {
        indents.push(renderMasterQuestionnaireList(question, offering));
      });
    }
    return <div>{indents}</div>;
  }, [
    userDefinedServiceQuestionnaireCategories, 
    props.piSectionFormErrors,
    props.templateValuesRed
  ]);

  const renderMasterCategoryQuestions = useCallback((serviceId, offering, category) => {
    if (
      category.parentQuestions.length === 0 &&
      category.QUESTION_PARENT_ID === 0
    ) {
      return (
        <Row>
          <Col md={6}>
            <h6>{props.translator["loading..."]}</h6>
          </Col>
        </Row>
      );
    }
    let indents = [];
    category.parentQuestions.forEach((question) => {
      indents.push(renderMasterQuestionnaireList(question, offering));
    });
    if (category.otherCategoryQuestions.length !== 0) {
      category.otherCategoryQuestions.forEach((question) => {
        indents.push(renderMasterQuestionnaireList(question, offering));
      });
    }
    //}
    return (
      <div className="catBgGroup">
        <div className="catBgGap">{indents}</div>
      </div>
    );
  }, [
    serviceQuestionnaireCategories, 
    props.piSectionFormErrors,
    props.templateValuesRed
  ]);

  const renderCategories = (offering, serviceId, service) => {
    return service.categories.map((category, index) => {
      if(!categoryList.includes(category.CATEGORY_ID)) {
        if (
          category.QUESTION_PARENT_ID === 0 &&
          !_.some(category.parentQuestions, [
            "categoryId",
            category.CATEGORY_ID,
          ])
        ) {
            dispatch(getCategoryParentQuestions(
              category.CATEGORY_ID,
              serviceId,
              componentIdforQuestionnaire
            ));
          }
        setCategories((prev) => [...prev, category.CATEGORY_ID]);
      }
        if (
          category.QUESTION_PARENT_ID !== 0 &&
          category.otherCategoryQuestions.length === 0
        ) {
          /*if it's a category with no parent stop it from rendering.*/
          return null;
        } else {
          if (category.QUESTION_PARENT_ID !== 0) {
            return (
              <Accordion.Item
                // key={category.CATEGORY_ID}
                key={1}
                eventKey={`${index}`}
                className="collapsed"
              >
                <Accordion.Header>
                  <h4 toggle>
                    {category.CATEGORY_NAME}
                    {_.some(category.otherCategoryQuestions, [
                      "questionType",
                      "mandatory",
                    ]) ? (
                      <span style={{ color: "red" }}> *</span>
                    ) : null}
                  </h4>
                </Accordion.Header>
                <Accordion.Body className="catPanelGroupBg">
                  {renderCategoryQuestions(serviceId, offering, category)}
                </Accordion.Body>
              </Accordion.Item>
            );
          } else {
            return (
              <Accordion.Item
                key={category.CATEGORY_ID}
                eventKey={`${index}`}
                className="collapsed"
              >
                <Accordion.Header>
                  <h4 toggle>
                    {category.CATEGORY_NAME}
                    {category.haveMandatory ? (
                      <span style={{ color: "red" }}> *</span>
                    ) : null}
                  </h4>
                </Accordion.Header>
                <Accordion.Body className="catPanelGroupBg">
                  {renderCategoryQuestions(serviceId, offering, category)}{" "}
                </Accordion.Body>
              </Accordion.Item>
            );
          }
        }
    });
  }

  const renderQuestionnaireCategories = (
    offering,
    serviceId,
    questionnaireCategoryList,
    categoryCount
  ) => {
    if (questionnaireCategoryList.length === 0) {
      return (
        <Row>
          <Col md={6}>
            <h6>{props.translator["loading..."]}</h6>
          </Col>
        </Row>
      );
    }
    let service = _.find(questionnaireCategoryList, {
      serviceId: serviceId.toString(),
    });
    if (service) {
      return renderCategories(offering, serviceId, service)
    } else {
      return (
        <Row>
          <Col md={6}>
            <h6>{props.translator["loading..."]}</h6>
          </Col>
        </Row>
      );
    }
  };

  const renderMasterCategories = (offering, serviceId, service) => {
    return service.categories.map((category, index) => {
      if(!categoryList.includes(category.CATEGORY_ID)) {
        if (
          category.QUESTION_PARENT_ID === 0 &&
          !_.some(category.parentQuestions, [
            "categoryId",
            category.CATEGORY_ID,
          ])
        ) {
            dispatch(getMasterQuestionsPI(
              serviceId,
              category.CATEGORY_ID,
              componentIdforQuestionnaire
            ));
        }
        setCategories((prev) => [...prev, category.CATEGORY_ID]);
      }
      if (
        category.QUESTION_PARENT_ID !== 0 &&
        category.otherCategoryQuestions.length === 0
      ) {
        /*if it's a category with no parent stop it from rendering.*/
        return null;
      } else {
        if (category.QUESTION_PARENT_ID !== 0) {
          return (
            <Accordion.Item
              key={1}
              className="collapsed"
            >
              <Accordion.Header>
                <h4 toggle>
                  {category.CATEGORY_NAME}
                  {_.some(category.otherCategoryQuestions, [
                    "questionType",
                    "mandatory",
                  ]) ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : null}
                </h4>
              </Accordion.Header>
              <Accordion.Body collapsible className="catPanelGroupBg">
                {renderMasterCategoryQuestions(serviceId, offering, category)}
              </Accordion.Body>
            </Accordion.Item>
          );
        } else {
          return renderMasterCategoryQuestions(serviceId, offering, category);
        }
      }
    });
  }

  const renderMasterQuestionnaireCategories = (
    offering,
    serviceId,
    questionnaireCategoryList
  ) => {
    if (questionnaireCategoryList.length === 0) {
      return (
        <Row>
          <Col md={6}>
            <h6>{props.translator["loading..."]}</h6>
          </Col>
        </Row>
      );
    }
    let service = _.find(questionnaireCategoryList, {
      serviceId: serviceId.toString(),
    });

    if (service) {
      return renderMasterCategories(offering, serviceId, service);
    } else {
      return (
        <Row>
          <Col md={6}>
            <h6>{props.translator["loading..."]}</h6>
          </Col>
        </Row>
      );
    }
  };

  const knowledgeArticlesFn = (e) => {
    let searchString = e.target.value ? e.target.value.trim() : "";
    props.renderKnowledgeArticles(searchString);
  };

  const showChildQuestions = (
    question,
    childQuestionArray,
    offering,
    dispatch
  ) => {
    if (question.parent_id !== 0) {
      /*as we are rendering parent questions already,
      skipping them from adding in child array*/
      let childQuestion = (
        <div
          key={
            question.resPonseType === "Dynamic Dropdown"
              ? question.key
              : question.qId
          }
        >
          <PISectionFields
            name={question.name}
            question={question}
            getTypeAheadChildQuestion={getTypeAheadChildQuestion}
            offering={offering}
            componentIds={componentIdforQuestionnaire}
            setAnswer={setAnswer}
            dispatch={dispatch}
            renderQuestionLabel={renderQuestionLabel}
            showChildQuestions={showChildQuestions}
            spinnerChange={spinnerChange}
            translator={props.translator}
            dateFormat={dateFormat}
            knowledgeArticles={knowledgeArticlesFn}
            handleOnChange={handleOnChange}
            setFormErrors={setFormErrors}
            piSectionFormErrors={props.piSectionFormErrors}
            piSectionFormValues={questionResponses}
            getUrgencyValuesForIncident={getUrgencyValuesForIncident}
            urgencyValuesReducer={urgencyValuesReducer}
            setServiceCriticalityandUrgency={
              props.setServiceCriticalityandUrgency
            }
            serviceCriticality={props.serviceCriticality}
            language={props.language}
            postAttributeBasedSlaReducer={postAttributeBasedSlaReducer}
            onBehalfOrder={props.onBehalfOrder}
            setTemplateResponses={setTemplateResponses}
            templateValues={props.templateValues}
            templateValuesRed={props.templateValuesRed}
            questionnaireList={questionnaireList}
            setChildQuestions={setChildQuestionsFn}
          />
        </div>
      );
      childQuestionArray.push(childQuestion);
    }

    if (question.childQuestions.length !== 0) {
      for (let index in question.childQuestions) {
        if (question.childQuestions.hasOwnProperty(index)) {
          showChildQuestions(
            question.childQuestions[index],
            childQuestionArray,
            offering,
            dispatch
          );
        }
      }
    }
  };

  const renderQuestionLabel = (question) => {
    return (
      <Col xs={12} sm={6} md={6} lg={6}>
        <Form.Group>
          <Form.Label>
            {props.cartOfferings[0].workItemType === "incidentRequest" &&
            question.categoryId === 1010101010
              ? props.translator[question.questionText]
              : question.questionText}
            {question.questionType === "mandatory" ? (
              <span style={{ color: "red" }}> *</span>
            ) : null}{" "}
            {question.helpText ? (
              <span
                className="f-size-13"
                title={(props.cartOfferings[0].workItemType === "incidentRequest" && question.categoryId === 1010101010) ? props.translator[question.helpText] : question.helpText}
                style={{ color: "#486177" }}
              ><ImInfo /></span>
            ) : null}
          </Form.Label>
        </Form.Group>
      </Col>
    );
  };

  const generateReadOnlyQuestions = (question, dispatch, event) => {
    let alteredQuestionSequence = 1;
    if(question.categoryId == 1010101010){
      if(event.BUSINESS_CRITICALITY && event.BUSINESS_CRITICALITY_ID){
      dispatch(
        change(
          "orderingForm",
          "businessCriticality",
          event.BUSINESS_CRITICALITY
        )
      );
      dispatch(
        change(
          "orderingForm",
          "businessCriticalityId",
          event.BUSINESS_CRITICALITY_ID
        )
      );}
    }
    if (question.roResponses.length !== 0) {
      let roValues = question.roResponses;
      let requestorValues = roValues.filter(
        (value) =>
          value === "Full_Name~Full Name" || value === "user_id~User ID"
      );
      if (requestorValues.length === 2 && event) {
        props.getOptionsFromChild(event.LOCATIONS, event.Location_Name);
      }
      if(event && event !== "") {
        for (let i = 0; i < roValues.length; i++) {
          let readOnlyValueQuestion = {};
          let readOnlyStringValue = roValues[i];
          let objectKey;
          let label;
          if (readOnlyStringValue.includes("~")) {
            let keyAndLabelHolder = readOnlyStringValue.split("~");
            objectKey = keyAndLabelHolder[0];
            label = keyAndLabelHolder[1];
          } else {
            if (readOnlyStringValue.includes("_")) {
              let objCopy = JSON.parse(JSON.stringify(readOnlyStringValue));
              label = objCopy.replace("_", " ").toString();
              objectKey = readOnlyStringValue;
            } else {
              label = readOnlyStringValue;
              objectKey = readOnlyStringValue;
            }
          }
          readOnlyValueQuestion.serviceId = question.serviceId;
          readOnlyValueQuestion.componentId = question.componentId;
          readOnlyValueQuestion.questionText = label;
          readOnlyValueQuestion.answer = event
            ? objectKey === "Assets"
              ? event[objectKey].toString()
              : event[objectKey]
            : event;
          readOnlyValueQuestion.questionId = question.questionId;
          readOnlyValueQuestion.responseType = question.responseType;
          readOnlyValueQuestion.qId = question.qId;
          readOnlyValueQuestion.parent_id = question.parent_id;
          readOnlyValueQuestion.commonId = question.initParentId.toString();
          readOnlyValueQuestion.categoryId = question.categoryId;
          readOnlyValueQuestion.categoryName = question.categoryName;
          readOnlyValueQuestion.categorySequence = question.categorySequence;
          readOnlyValueQuestion.componentNameSx = question.categoryName;
          readOnlyValueQuestion.questionApproval = question.questionApproval;
          readOnlyValueQuestion.requestorLocationID =
            event && typeof event.LOCATIONS !== "undefined"
              ? event.LOCATIONS
              : "";
          readOnlyValueQuestion.requestorLonationName =
            event && typeof event.Location_Name !== "undefined"
              ? event.Location_Name
              : "";
          readOnlyValueQuestion.questionSequence =
            question.questionSequence.toString() + "." + alteredQuestionSequence;
          readOnlyValueQuestion.originalQuestionName = question.name;
          readOnlyValueQuestion.name =
            question.name + "_" + alteredQuestionSequence;
  
          alteredQuestionSequence = alteredQuestionSequence + 1;
          dispatch(
            change(
              "orderingForm",
              readOnlyValueQuestion.name,
              readOnlyValueQuestion
            )
          );
        }
      } else {
        for (let i = 0; i < roValues.length; i++) {
          let readOnlyValueQuestion = {};
          let readOnlyStringValue = roValues[i];
          let objectKey;
          let label;
          if (readOnlyStringValue.includes("~")) {
            let keyAndLabelHolder = readOnlyStringValue.split("~");
            objectKey = keyAndLabelHolder[0];
            label = keyAndLabelHolder[1];
          } else {
            if (readOnlyStringValue.includes("_")) {
              let objCopy = JSON.parse(JSON.stringify(readOnlyStringValue));
              label = objCopy.replace("_", " ").toString();
              objectKey = readOnlyStringValue;
            } else {
              label = readOnlyStringValue;
              objectKey = readOnlyStringValue;
            }
          }
          readOnlyValueQuestion.name =
            question.name + "_" + alteredQuestionSequence;
  
          alteredQuestionSequence = alteredQuestionSequence + 1;
          dispatch(
            change(
              "orderingForm",
              readOnlyValueQuestion.name,
              ""
            )
          );
        }
      }
    }
  };

  const setAnswer = (question, event, dispatch) => {
    switch (question.responseType) {
      case "Typeahead":
        if (question.roResponses && question.roResponses.length !== 0) {
          generateReadOnlyQuestions(question, props.dispatch, event);
        }
        break;
      case "multiSelectTypeahead":
        if (question.roResponses && question.roResponses.length !== 0) {
          generateReadOnlyQuestions(question, props.dispatch, event);
        }
        break;
      case "Dynamic Dropdown":
        if (question.roResponses && question.roResponses.length !== 0) {
          generateReadOnlyQuestions(question, props.dispatch, event);
          let dropdownResp = {
            target: {
              name: question.name
            },
          };
          if(event.Name) {
            dropdownResp.target.value = event.Name;
          } else if(event.NAME) {
            dropdownResp.target.value = event.NAME;
          } else if(event === "") {
            dropdownResp.target.value = "";
          }
          handleOnChange(dropdownResp);
        } else {
          let dropdownResp = {
            target: {
              name: question.name
            },
          };
          if(event.Name) {
            dropdownResp.target.value = event.Name;
          } else if(event.NAME) {
            dropdownResp.target.value = event.NAME;
          } else if(event === "") {
            dropdownResp.target.value = "";
          }
          handleOnChange(dropdownResp);
        }
        break;
      case "Dropzone":
      case "Attachment":
        if (event) {
          let jsonObject = {};
          jsonObject.module = "Item";
          jsonObject.itemId = "";
          jsonObject.createdOn = Math.round(new Date().getTime() / 1000.0);
          jsonObject.createdBy = "";
          jsonObject.createdByName = "";
          let answer = [];
          event.forEach((file) => answer.push(file.name));
          question.answer = answer.join(", ");
          question.attachedFile = event;
          question.attachmentJSONObject = jsonObject;
          question.originalQuestionName = question.name;
          dispatch(change("orderingForm", question.name, question));
        } else {
          question.answer = event;
          dispatch(change("orderingForm", question.name, question));
        }
        break;
      case "Datetime":
        let obj = {
          target: {
            name: question.name,
            value: event,
          },
        };
        setQuestionResponses(
          Object.assign({}, questionResponses, { [question.name]: event })
        );
        handleOnChange(obj);
        setTemplateResponses(question.questionId, { value: typeof event === 'string' ? event : event?.toISOString(), type: question.responseType, name: question.questionText });
        break;
      case "Calendar":
        let obj2 = {
          target: {
            name: question.name,
            value: event,
          },
        };
        setQuestionResponses(
          Object.assign({}, questionResponses, { [question.name]: event })
        );
        handleOnChange(obj2);
        setTemplateResponses(question.questionId, { value: typeof event === 'string' ? event : event?.toISOString(), type: question.responseType, name: question.questionText });
        break;
    }
  };

  const renderMasterQuestionnaireList = (question, offering) => {
    return (
      <div key={question.qId}>
        <PISectionFields
          name={question.name}
          question={question}
          getTypeAheadChildQuestion={getTypeAheadChildQuestion}
          offering={offering}
          componentIds={componentIdforQuestionnaire}
          setAnswer={setAnswer}
          dispatch={dispatch}
          renderQuestionLabel={renderQuestionLabel}
          showChildQuestions={showChildQuestions}
          spinnerChange={spinnerChange}
          translator={props.translator}
          dateFormat={dateFormat}
          knowledgeArticles={knowledgeArticlesFn}
          handleOnChange={handleOnChange}
          setFormErrors={setFormErrors}
          piSectionFormErrors={props.piSectionFormErrors}
          piSectionFormValues={questionResponses}
          getUrgencyValuesForIncident={getUrgencyValuesForIncident}
          urgencyValuesReducer={urgencyValuesReducer}
          setServiceCriticalityandUrgency={
            props.setServiceCriticalityandUrgency
          }
          serviceCriticality={props.serviceCriticality}
          language={props.language}
          postAttributeBasedSlaReducer={postAttributeBasedSlaReducer}
          onBehalfOrder={props.onBehalfOrder}
          setTemplateResponses={setTemplateResponses}
          templateValues={props.templateValues}
          templateValuesRed={props.templateValuesRed}
          questionnaireList={questionnaireList}
          setChildQuestions={setChildQuestionsFn}
        />
      </div>
    );
  };

  const renderBulkPurchaseQuestionForService = (questions) => {
    return questions.map((question, index) => {
      let displayText = "";
      let displayStr = "";
      displayStr =
        question.questionText != null && question.questionText != ""
          ? question.questionText
          : "";
      displayText =
        displayStr != ""
          ? displayStr
              .replace(
                /(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/gi,
                ""
              )
              .trim()
          : "";
      displayText = displayText != "" ? displayText.replace(/\s+/g, " ") : "";
      displayText = displayText != "" ? displayText.replace(/&/g, "" ) : "";
      displayText = displayText != "" ? displayText.replace(/;/g, "" ) : "";

      return (
        <Accordion.Item
          key={question.qId}
          eventKey={`${index}`}
          className="collapsed"
        >
          <Accordion.Header>
            <h4 toggle>
              <div title={question.questionText}>
                {displayText.slice(0, 85)}
                {displayText.length > 85 ? "..." : ""}
                {question.questionType === "mandatory" ? (
                  <span style={{ color: "red" }}> *</span>
                ) : null}
              </div>
            </h4>
          </Accordion.Header>
          <Accordion.Body collapsible>
            <Form.Group>
              <PISectionFields
                // {...question}
                question={question}
                renderQuestionLabel={renderQuestionLabel}
                translator={props.translator}
                handleOnChange={handleOnChange}
                setFormErrors={setFormErrors}
                piSectionFormErrors={props.piSectionFormErrors}
                piSectionFormValues={questionResponses}
                piSectionTableFormErrors={props.piSectionTableFormErrors}
                piSectionTableFormValues={props.piSectionTableFormValues}
                setPISectionTableFormErrors={props.setPISectionTableFormErrors}
                setPISectionTableFormValues={props.setPISectionTableFormValues}
                getUrgencyValuesForIncident={getUrgencyValuesForIncident}
                urgencyValuesReducer={urgencyValuesReducer}
                serviceCriticality={props.serviceCriticality}
                setServiceCriticalityandUrgency={
                  props.setServiceCriticalityandUrgency
                }
                language={props.language}
                postAttributeBasedSlaReducer={postAttributeBasedSlaReducer}
                onBehalfOrder={props.onBehalfOrder}
                setTemplateResponses={setTemplateResponses}
                templateValues={props.templateValues}
                templateValuesRed={props.templateValuesRed}
                questionnaireList={questionnaireList}
                setChildQuestions={setChildQuestionsFn}
              />
            </Form.Group>
          </Accordion.Body>
        </Accordion.Item>
      );
    });
  };

  const renderBulkPurchaseFields = (
    bulkPurchaseQuestions,
    offering,
    eventKey
  ) => {
    return bulkPurchaseQuestions.map((category) => {
      if (category.serviceId === offering.offeringId) {
        let displayText = "";
        let displayStr = "";
        displayStr =
          category.categoryName != null && category.categoryName != ""
            ? category.categoryName
            : "";
        displayText =
          displayStr != ""
            ? displayStr
                .replace(
                  /(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/gi,
                  ""
                )
                .trim()
            : "";
        displayText = displayText != "" ? displayText.replace(/\s+/g, " ") : "";
        displayText = displayText != "" ? displayText.replace(/&/g, "" ) : "";
        displayText = displayText != "" ? displayText.replace(/;/g, "" ) : "";

        eventKey++;
        return (
          <Accordion.Item
            key={category.categoryId}
            eventKey={`${eventKey}`}
            className="collapsed"
          >
            <Accordion.Header>
              <h4 toggle>
                <div title={category.categoryName}>
                  {displayText.slice(0, 85)}
                  {displayText.length > 85 ? "..." : ""}
                  {category.isMandatory === "true" ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : null}
                </div>
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <Accordion
                alwaysOpen
                id="bulkPurcahseQuestionAccordion"
                className=""
                defaultActiveKey={["0"]}
              >
                <Form.Group>
                  {renderBulkPurchaseQuestionForService(
                    category.categoryQuestions
                  )}
                </Form.Group>
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
        );
      }
    });
  };
  const decode = (str) => {
    const str2 = str
      .replaceAll("&nbsp;", "")
      .replaceAll(/&amp;/g, "&")
      .replaceAll("&#39;", "'")
      .replaceAll(/&quot;/g, '"')
      .replaceAll("&lt;", "<")
      .replaceAll("&gt;", ">");
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(str2, {
            allowedAttributes: {
              "*": ["style", "class", "dir", "bgcolor"],
              table: ["border", "cellpadding", "cellspacing"],
              th: ["scope"],
            },
          }),
        }}
      />
    );
  };

  const renderOrderList = (
    offerings,
    serviceHaveMandatoryQuestions,
    bulkPurchaseQuestions
  ) => {
    let eventKey = 2;
    let categoryCoutObjetForService;
    return offerings.map((offering, index) => {
      let displayText = "";
      let displayStr = "";
      displayStr =
        offering.offeringSummary != null && offering.offeringSummary != ""
          ? offering.offeringSummary
          : "";
      displayText =
        displayStr != ""
          ? displayStr
              .replace(
                /(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/gi,
                ""
              )
              .trim()
          : "";
      displayText = displayText != "" ? displayText.replace(/\s+/g, " ") : "";
      displayText = displayText != "" ? displayText.replace(/&/g, "" ) : "";
      displayText = displayText != "" ? displayText.replace(/;/g, "" ) : "";
      
      let displayName = "";
      let displayOfferingName = "";
      displayOfferingName =
        offering.offeringName != null && offering.offeringName != ""
          ? offering.offeringName
          : "";
      displayName =
        displayOfferingName != ""
          ? displayOfferingName
              .replace(
                /(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/gi,
                ""
              )
              .trim()
          : "";
      displayName = displayName != "" ? displayName.replace(/\s+/g, " ") : "";

      let getInnerWidth = (innerWidth) => {
        if (innerWidth > 1535) return 100;
        if (innerWidth > 1367) return 82;
        if (innerWidth > 1279) return 85;
        if (innerWidth > 1023) return 62;
        if (innerWidth > 767) return 44;
        if (innerWidth > 640) return 60;
        if (innerWidth > 539) return 48;
        if (innerWidth > 411) return 62;
        if (innerWidth > 279) return 22;
        return 20;
      };

      let corePackage;
      let optionalComponents = [];
      let haveMandatoryQuestionFlag = "";
      let mandatoryMark = false;
      if (
        userDefinedServiceQuestionnaireCategories &&
        userDefinedServiceQuestionnaireCategories.length > 0
      ) {
        userDefinedServiceQuestionnaireCategories[0].categories.map(
          (category) => {
            if (category.haveMandatory === true) {
              mandatoryMark = true;
            }
          }
        );
      }
      serviceHaveMandatoryQuestions.map((haveMandatoryFlagObject) => {
        if (offering.offeringId === haveMandatoryFlagObject.offeringId) {
          haveMandatoryQuestionFlag =
            haveMandatoryFlagObject.haveMandatoryQuestion;
        }
      });
      categoryCoutObjetForService = _.find(categoryCountList, {
        offeringId: offering.offeringId.toString(),
      });

      corePackage = offering?.componentapiList?.map((coreOfferings) => {
        if (coreOfferings.componentType === "Core") {
          return (
            <Row className="clearboth margin-b-5">
              {offering.hidePrice === "true" ? (
                <Col md={12} sm={12} xs={12} className="">
                  <div
                    className="ordCompoNam padding-l-20"
                    title={coreOfferings.componentName}
                  >
                    {coreOfferings.componentName.slice(0, 130)}
                    {coreOfferings.componentName.length > 130 ? "..." : null}
                  </div>
                </Col>
              ) : (
                <Col md={6} sm={6} xs={6}>
                  <div
                    className="ordCompoNam padding-l-20"
                    title={coreOfferings.componentName}
                  >
                    {coreOfferings.componentName.slice(0, 130)}
                    {coreOfferings.componentName.length > 130 ? "..." : null}
                  </div>
                </Col>
              )}

              {offering.hidePrice === "true" ? null : (
                <Col md={3} sm={3} xs={3} className="">
                  <div className="ordCompoNam">
                    {props.translator["Included"]}
                  </div>
                </Col>
              )}
              {offering.hidePrice === "true" ? null : (
                <Col md={3} sm={3} xs={3} className="">
                  <div className="ordCompoNam">
                    {props.translator["Included"]}
                  </div>
                </Col>
              )}
            </Row>
          );
        }
      });
      offering?.componentapiList?.forEach((optionalOfferings, index) => {
        if (optionalOfferings.componentType === "Optional") {
          if (
            _.some(props.selectedComponentForOffering, [
              "componentId",
              optionalOfferings.componentId.toString(),
            ]) ||
            _.some(props.selectedComponentForOffering, [
              "componentId",
              optionalOfferings.componentId,
            ])
          ) {
            let optionalComponentsHtml = (
              <Row
                className="clearboth margin-b-5"
                key={optionalOfferings.componentId}
              >
                {offering.hidePrice === "true" ? (
                  <Col md={12} sm={12} xs={12} className="">
                    <div
                      className="ordCompoNam padding-l-20"
                      title={optionalOfferings.componentName}
                    >
                      {optionalOfferings.componentName.slice(0, 130)}
                      {optionalOfferings.componentName.length > 130
                        ? "..."
                        : null}
                    </div>
                  </Col>
                ) : (
                  <Col md={6} sm={6} xs={6}>
                    <div
                      className="ordCompoNam padding-l-20"
                      title={optionalOfferings.componentName}
                    >
                      {optionalOfferings.componentName.slice(0, 130)}
                      {optionalOfferings.componentName.length > 130
                        ? "..."
                        : null}
                    </div>
                  </Col>
                )}

                {offering.hidePrice === "true" ? null : (
                  <Col md={3} sm={3} xs={3} className="">
                    <div className="ordCompoNam">
                      {optionalOfferings.billing}
                    </div>
                  </Col>
                )}

                {offering.hidePrice === "true" ? null : (
                  <Col md={3} sm={3} xs={3} className="">
                    <div className="ordCompoNam">
                      {optionalOfferings.currencyCode + " "}
                      {Number(optionalOfferings.actualPrice).toFixed(2)}
                    </div>
                  </Col>
                )}
              </Row>
            );
            optionalComponents.push(optionalComponentsHtml);
          }
        }
      });
      let disclaimerValue = _.find(props.disclaimerCheckbox, {
        serviceId: Number(offering.offeringId),
      }) || { value: false, touched: false };
      return (
        <Accordion.Item eventKey={`${index}`} key={index}>
          <Accordion.Header>
            <h4 toggle>
              <div className="addPenTit">
                <div className="imbx penTd">
                  <div className="addCatIm">
                    <img
                      className="img-responsive"
                      src={
                        offering.offeringImageTempURL
                          ? offering.offeringImageTempURL
                          : dummyImage
                      }
                      title={offering.offeringName}
                    />
                  </div>
                </div>
                <div className="txbx penTd">
                  <div
                    title={offering.offeringName}
                    className="theme1 f-size-17 lineH-22"
                  >
                    {displayName.slice(
                      0,
                      getInnerWidth(windowSize.width)
                    )}
                    {displayName.length >
                    getInnerWidth(windowSize.width)
                      ? "..."
                      : ""}
                  </div>
                  <div className="txbxDes f-size-13">
                    {displayText.slice(0, 105)}
                    {displayText.length > 105 ? "..." : ""}
                  </div>
                </div>
              </div>
            </h4>
          </Accordion.Header>
          <Accordion.Body>
            {renderOfferingAttachments(
              cartOfferingAttachmentsReducer,
              offering.offeringId
            )}
            <Accordion
              alwaysOpen
              id="newOrderNowListFromCart"
              className="oNPanGroSty brkFixOrder"
              defaultActiveKey={["1"]}
            >
              <Accordion.Item eventKey={"0"} className="collapsed">
                <Accordion.Header>
                  <h4 toggle>{props.translator["Components"]}</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    {offering.hidePrice === "true" ? (
                      <Col md={12} sm={12} xs={12} className="">
                        <div className="f-size-16 padding-b-5">
                          {props.translator["Package Contains"]}:
                        </div>
                      </Col>
                    ) : (
                      <Col md={6} sm={6} xs={6}>
                        <div className="f-size-16 padding-b-5">
                          {props.translator["Package Contains"]}:
                        </div>
                      </Col>
                    )}

                    {offering.hidePrice === "true" ? null : (
                      <Col md={3} sm={3} xs={3} className="">
                        <div className="ordCompoNam">
                          {offering.billingMethod}
                        </div>
                      </Col>
                    )}
                    {offering.hidePrice === "true" ? null : (
                      <Col md={3} sm={3} xs={3} className="">
                        <div className="ordCompoNam">
                          {offering.currencyCode + " "}
                          {Number(offering.actualPrice).toFixed(2)}
                        </div>
                      </Col>
                    )}
                  </Row>
                  <div>{corePackage}</div>
                  {optionalComponents.length !== 0 ? (
                    <div>
                      <div className="f-size-16 padding-b-5 margin-t-15">
                        {props.translator["Optional Components"]}:
                      </div>
                      {optionalComponents}
                    </div>
                  ) : null}
                </Accordion.Body>
              </Accordion.Item>
              {(offering.workItemType == "incidentRequest" ||
                offering.workItemType == "standardChange") &&
              categoryCoutObjetForService ? (
                <Accordion.Item
                  eventKey={"1"}
                  className="collapsed rad-check-align clLeftAlign catPanelGroup"
                >
                  <Accordion.Header>
                    <h4 toggle>
                      {offering.workItemType == "standardChange"
                        ? props.translator["Change Details"]
                        : null}
                      {offering.workItemType == "incidentRequest"
                        ? props.translator["Incident Details"]
                        : null}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </h4>
                  </Accordion.Header>
                  <Accordion.Body
                    collapsible
                    style={{ paddingTop: "5px", paddingBottom: "5px" }}
                  >
                    {renderMasterQuestionnaireCategories(
                      offering,
                      offering.offeringId,
                      serviceQuestionnaireCategories
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              ) : null}
              {categoryCoutObjetForService &&
              categoryCoutObjetForService.categoryCount !== 0 &&
              userDefinedServiceQuestionnaireCategories &&
              userDefinedServiceQuestionnaireCategories.length !==
                0 ? (
                <Accordion.Item
                  eventKey={offering.workItemType === "serviceRequest" ? ("1") : ("2")}
                  className=" collapsed rad-check-align clLeftAlign"
                >
                  <Accordion.Header>
                    <h4 toggle>
                      {props.translator["Provide Information"]}{" "}
                      {haveMandatoryQuestionFlag === "yes" ? (
                        <span style={{ color: "red" }}>*</span>
                      ) : null}
                    </h4>
                  </Accordion.Header>
                  <Accordion.Body collapsible  className="position-re">
                    {questionnaireList.fetchingChildQuestions == true ? (
                      <div className="overlay-container piSectionLoader">
                        <ListLoader />
                      </div>
                    ) : null}
                    {offering.hideQuantity === "false" ? (
                      <div className="catPanelGroup margin-b-15">
                        <div className="panel-collapse padding-l-15 padding-r-15">
                          <Row className="botLineWhite">
                            <Col lg={6} md={6} sm={6} xs={12}>
                              <Form.Group className="margin-0 padding-t-5">
                                <span>
                                  {props.translator["Item Quantity"]}
                                </span>
                              </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={12}>
                              <Form.Group className="margin-0 padding-t-5 d-block form-group">
                                <NumericInput
                                  id="numField"
                                  name={"itemQuantity_" + offering.offeringId}
                                  className={"form-control"}
                                  step={1}
                                  min={offering.minValue}
                                  max={offering.maxValue}
                                  onChange={(e) => {
                                    let val = e;
                                    if (val < offering.minValue) {
                                      setItemQuantity(offering.minValue);
                                    } else if (val > offering.maxValue) {
                                      setItemQuantity(offering.maxValue);
                                    } else {
                                      setItemQuantity(val);
                                      props.updateServicePrice(
                                        offering.offeringId,
                                        val
                                      );
                                    }
                                  }}
                                  onKeyDown={(e) => {e.key === "Enter" && e.preventDefault();}}
                                  strict={true}
                                  value={itemQuantity}
                                  snap
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ) : null}

                    <Accordion
                      alwaysOpen
                      id="categoryAccordion"
                      className="catPanelGroup"
                      defaultActiveKey={
                        categoryCoutObjetForService.categoryCount === 1 ||
                        props.offeringId
                          ? (["0"])
                          : (["-1"])
                      }
                    >
                      {renderQuestionnaireCategories(
                        offering,
                        offering.offeringId,
                        userDefinedServiceQuestionnaireCategories,
                        categoryCoutObjetForService.categoryCount
                      )}
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              ) : null}
              {Array.isArray(bulkPurchaseQuestions) &&
              bulkPurchaseQuestions.length
                ? renderBulkPurchaseFields(
                    bulkPurchaseQuestions,
                    offering,
                    eventKey
                  )
                : null}
              {offering.workItemType == "serviceRequest" ||
              offering.workItemType == "incidentRequest" ||
              offering.workItemType == "standardChange" ||
              offering.hideSla === "true" ? null : (
                <Accordion.Item eventKey={"2"} className="collapsed">
                  <Accordion.Header>
                    <h4 toggle>{props.translator["Delivery Option"]}</h4>
                  </Accordion.Header>
                  <Accordion.Body collapsible>
                    <Form.Group>
                      {renderDeliverOptions(
                        offering.offeringId,
                        postAttributeBasedSlaReducer
                      )}
                    </Form.Group>
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </Accordion>
            {offering.serviceDisclaimer !== null ? (
              <Row className="parWantToOrderDv">
                <Col md={12}>
                  <div className="wantToOrderDv">
                    <Field
                      component={_disclaimerCheckbox}
                      className="form-control"
                      name={"disclaimer-" + offering.offeringId}
                      onChange={onChangeFlag}
                      checked={disclaimerValue.value}
                      touched={disclaimerValue.touched}
                      error={"error"}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                    />
                  </div>
                  <Form.Label>
                    <div className="offTxt">
                      {decode(offering.serviceDisclaimer)}
                    </div>
                  </Form.Label>
                </Col>
              </Row>
            ) : null}
          </Accordion.Body>
        </Accordion.Item>
      );
    });
  };

  const onChangeFlag = (e) => {
    let name = e.target.name.split('-')[1];
    props.updateDisclaimer(e.target.checked, name);
  };

  const { bulkPurchaseQuestions } = props;
  if (props.loaderStatus) {
    return <ListLoader />;
  }

  if (
    props.cartOfferings.length === 0 ||
    props.cartOfferings instanceof Error
  ) {
    props.changeCompMessage(
      props.translator[
        "Your cart is looking empty, shop with us to proceed further"
      ],
      "list"
    );
  }

  if (props.cartOfferings.errorCode) {
    return (
      <h2>
        {props.cartOfferings.errorMessage + " " + props.cartOfferings.errorCode}
      </h2>
    );
  }
  if (props.cartOfferings instanceof Error) {
    return props.cartListMessage === "" ? (
      <div>
        <h2>
          <div>
            <Spinner spinnerName="three-bounce" />
          </div>
          {props.translator["loading..."]}
        </h2>
      </div>
    ) : (
      <div>
        <h2>{props.cartListMessage}</h2>
      </div>
    );
  }
  if (props.cartOfferings.length === 0) {
    return props.cartListMessage === "" ? (
      <div>
        <Spinner spinnerName="three-bounce" />
      </div>
    ) : (
      <div>
        <h2>{props.cartListMessage}</h2>
      </div>
    );
  }
  return (
    <div>
      {props.piQuestionsMandatError ? (
        <Alert variant="danger" className="errorMessageSec">
          {props.piQuestionsMandatError}
        </Alert>
      ) : null}
      <Accordion
        alwaysOpen
        id="orderNowOrd"
        defaultActiveKey={["0"]}
        // activeKey={activeKey}
        // onSelect={(event) => setActiveKey(event)}
        className="brkFix brkFixOrder"
      >
        {renderOrderList(
          props.cartOfferings,
          props.haveMandatoryQuestionFlag,
          bulkPurchaseQuestions
        )}
      </Accordion>
    </div>
  );
};

export default CheckoutPISection;
