
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect } from "react";
import { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import matchSorter from "match-sorter";
import { IoSearch } from "react-icons/io5";
import { Form, Table, Row, Col } from "react-bootstrap";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import Pagination from "react-js-pagination";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import ReactDOM from "react-dom";

import { useGetMenusListQuery } from "../../../reducers/rules";
import RulesShortDescriptionList from "./ruleShortDescriptionList";
import ListLoader from "../../common/loaders/ListLoader.js";
import { commonSorting } from "../../common/helper.js";
import { loadBusinessFunctionList } from "../../../actions/businessFunction/businessFunctionAction";
import Cookies from "universal-cookie";
import { usePreviousValue } from "../../../helpers/usePreviousValue.js";

const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
let dateformatCookie = homepagelocation[2]?.substr(0, 10)?.toUpperCase();

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const [inputValue, setInputValue] = useState(filterValue || "");
  const count = preFilteredRows?.length;

  useEffect(() => {
    if (!filterValue) {
      setInputValue("");
    }
  }, [filterValue]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value || undefined);
  };

  const handleIconClick = () => {
    setFilter(inputValue);
  };

  return (
    <div style={{ position: "relative" }}>
      <Form.Control
        value={inputValue}
        onChange={handleInputChange}
        placeholder={`Search here`}
        className="colSearInp form-control"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleIconClick();
          }
        }}
      />
      {inputValue?.trim()?.length >= 3 && (
        <a title="search" className="faicn" onClick={handleIconClick}>
          <IoSearch />
        </a>
      )}
    </div>
  );
}
function DateFilter({ column: { filterValue, setFilter }, clearAll }) {
  const [inputValue, setInputValue] = useState("");

  const handleApply = (event, picker) => {
    const dateRange = `${picker.startDate.format(
      "MM-DD-YYYY"
    )}&${picker.endDate.format("MM-DD-YYYY")}`;

    setFilter({
      startDate: picker.startDate.format("MM-DD-YYYY"),
      endDate: picker.endDate.format("MM-DD-YYYY"),
      dateRange,
    });
  };

  useEffect(() => {
    if (!filterValue) {
      setInputValue("");
    }
  }, [filterValue]);

  const handleCancel = () => {
    setFilter(undefined);
    setInputValue(undefined);
  };

  const handleCallback = (start, end) => {
    setInputValue(
      start.format(dateformatCookie) + " - " + end.format(dateformatCookie)
    );
  };

  return (
    <DateRangePicker
      key={filterValue || Math.random()}
      initialSettings={{
        startDate: moment(filterValue?.startDate),
        endDate: moment(filterValue?.endDate),
        maxDate: moment(),
        autoApply: true,
        autoUpdateInput: false,
        locale: {
          cancelLabel: "Clear",
          applyLabel: "Apply",
        },
        parentEl:"#skipdv",
        opens: "left",
      }}
      onApply={handleApply}
      onCallback={(start, end) => handleCallback(start, end)}
      onCancel={handleCancel}
    >
      <input
        type="text"
        value={inputValue}
        readOnly
        placeholder="Select Date Range"
        className="form-control"
      />
    </DateRangePicker>
  );
}

const SelectColumnFilterCheckbox = ({
  column: { filterValue, setFilter, preFilteredRows, id },
  data,
  clearAll,
  setIsEditDetails,
  ...props
}) => {
  const [isMenuOpenDd, setIsMenuOpenDd] = useState(false);
  const [selected, setSelected] = useState();
  const previousValue = usePreviousValue(props);

  const ref = useRef(null);

  useEffect(() => {
    if (!filterValue) { 
      setSelected([]);
    } else {
      if (
        id === "status" &&
        filterValue.length === 1 &&
        filterValue[0] === "active"
      ) {
        setSelected(data.filter((item) => item.value === "active"));
      }
    }
  }, [filterValue]);

  const handleChange = (selectedOptions) => {
    setSelected(selectedOptions);
    const selectedValues = selectedOptions?.map((option) => option.value) || [];
    setFilter(selectedValues.length > 0 ? selectedValues : undefined);
  };

  const clickOutside = () => {
    if (ref.current && !isMenuOpenDd) {
      props.showDropdownFun("allFalse", false);
      setIsMenuOpenDd(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutside);
    if (id === "status") {
      setFilter(["active"]);
      setSelected(data.filter(item => item.value === 'active'));
    }
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, []);

  useEffect(() => {
    if (ref != undefined || ref != null) {
      ref.current.childNodes[0].className = "multiSelectRef";
      let myRef = ref;
      let obj = ReactDOM.findDOMNode(myRef.current)?.childNodes?.[0]
        ?.childNodes?.[1];
      if (obj) {
        ReactDOM.findDOMNode(
          myRef.current
        ).childNodes[0].childNodes[1].className = "multiSelectPopup";
      }
    }
  }, [isMenuOpenDd]);

  return (
    <div
      id={id}
      ref={ref}
      onClick={() => {
        setIsEditDetails(false);
        setIsMenuOpenDd(!isMenuOpenDd);
        props.showDropdownFun(id, true);
      }}
    >
      <ReactMultiSelectCheckboxes
        placeholderButtonLabel="Select"
        rightAligned={true}
        options={data || []}
        onChange={handleChange}
        value={selected}
      />
    </div>
  );
};

const RulesList = ({
  isBusy,
  setPageNum,
  setPgSize,
  pageNum,
  pgSize,
  allRules,
  rulesFetching,
  setSearchParams,
  isFilterCleared,
  setIsFilterCleared,
  showRightSideFun,
  searchParams,
  ...props
}) => {
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const allBusinessFunctions = useSelector(
    (state) => state?.businessFunctionList
  );

  const [listData, setListData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [rulesType, setRulesType] = useState([]);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [ruleForOptions, setRuleForOptions] = useState([]);
  const [businessOptions, setBusinessOptions] = useState([]);
  const [isEditDetails, setIsEditDetails] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState({});
  const [rowData, setRowData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [globalSortDirection, setGlobalSortDirection] = useState("Ascending");

  const multiDropdownBusinessRef = useRef(null);
  const multiDropdownModuleRef = useRef(null);
  const multiDropdownRuleTypeRef = useRef(null);
  const multiDropdownStatusRef = useRef(null);
  const multiDropdownRuleForRef = useRef(null);

  // const rulesData = allRules?.body?.records || [];

  let caseDropdownState = Object.values(isMenuOpen || {})?.some(
    (val) => val
  );
  let caseResponsive =
    rulesFetching || tableData.length === 0 || (caseDropdownState && tableData.length <= 6)
      ? "overFlowClp"
      : "table-responsive";
  let caseResponsiveTable =
    rulesFetching || tableData.length === 0 || (caseDropdownState && tableData.length <= 6)
      ? "respondv responDvMinH"
      : "respondv";
  let casePanelOverflow =
    caseDropdownState && (rulesFetching || tableData.length === 0 || tableData.length <= 6)
      ? "overflow-clip"
      : "";

  const showDropdownFun = (id, val) => {
    if (id === "allFalse") {
      if (Object.keys(isMenuOpen).length > 0) {
        setIsMenuOpen({});
      }
    } else {
      setIsMenuOpen({ [id]: val });
    }
  };

  const { data: ruleType } = useGetMenusListQuery({
    module_name: "rule",
    field_name: "rule_type",
  });

  const { data: moduleData } = useGetMenusListQuery({
    module_name: "rule",
    field_name: "module_name",
  });

  const { data: stuatusData } = useGetMenusListQuery({
    module_name: "rule",
    field_name: "status",
  });

  const { data: allRulesForData } = useGetMenusListQuery({
    module_name: "rule",
    field_name: "ruleFor",
  });

  const handleCallback = (start, end) => {
    // this.setState({ modifiedDateSearchFilter: "modifiedOn" });
  };

  const handleSearch = (e, picker) => {};

  const columns = useMemo(
    () => [
      {
        Header: tr["Rule Name"] || "Rule Name",
        accessor: "ruleName",
        // sortType: commonSorting,
        // Filter: false,
      },
      {
        Header: tr["Company"] || "Company",
        accessor: "consumerCompany",
        // Filter: false,
      },
      {
        Header: tr["Business Function"] || "Business Function",
        accessor: "businessFunction",
        Filter: ({ column, setFilter }) => (
          <SelectColumnFilterCheckbox
            column={column}
            setFilter={setFilter}
            data={businessOptions || []}
            clearAll={!props?.showFilterIcon}
            setIsEditDetails={setIsEditDetails}
            isDropdownOpen={isMenuOpen}
            showDropdownFun={showDropdownFun}
            ref={multiDropdownBusinessRef}
          />
        ),
      },
      {
        Header: tr["Module"] || "Module",
        accessor: "module",
        Cell: ({ value }) => {
          if (value === "changemanagement") return "Change Management";
          else return value;
        },
        Filter: ({ column, setFilter }) => (
          <SelectColumnFilterCheckbox
            column={column}
            setFilter={setFilter}
            data={moduleOptions || []}
            clearAll={!props?.showFilterIcon}
            setIsEditDetails={setIsEditDetails}
            isDropdownOpen={isMenuOpen}
            showDropdownFun={showDropdownFun}
            ref={multiDropdownModuleRef}
          />
        ),
      },
      {
        Header: tr["Rule Type"] || "Rule Type",
        accessor: "ruleType",
        Filter: ({ column, setFilter }) => (
          <SelectColumnFilterCheckbox
            column={column}
            setFilter={setFilter}
            data={rulesType || []}
            clearAll={!props?.showFilterIcon}
            setIsEditDetails={setIsEditDetails}
            isDropdownOpen={isMenuOpen}
            showDropdownFun={showDropdownFun}
            ref={multiDropdownRuleTypeRef}
          />
        ),
      },
      {
        Header: tr["Status"] || "Status",
        accessor: "status",
        Filter: ({ column, setFilter }) => (
          <SelectColumnFilterCheckbox
            column={column}
            setFilter={setFilter}
            data={statusOptions || []}
            clearAll={!props?.showFilterIcon}
            setIsEditDetails={setIsEditDetails}
            isDropdownOpen={isMenuOpen}
            showDropdownFun={showDropdownFun}
            ref={multiDropdownStatusRef}
          />
        ),
      },
      {
        Header: tr["Created By"] || "Created By",
        accessor: "createdBy",
        // Filter: false,
      },
      {
        Header: tr["Created On"] || "Created On",
        accessor: "createdOn",
        Filter: ({ column, setFilter }) => (
          <DateFilter
            column={column}
            setFilter={setFilter}
            clearAll={!props?.showFilterIcon}
          />
        ),
      },

      {
        Header: tr["Modified On"] || "Modified On",
        accessor: "modifiedOn",
        Filter: ({ column, setFilter }) => (
          <DateFilter
            column={column}
            setFilter={setFilter}
            clearAll={props?.showFilterIcon}
          />
        ),
        // sortType: (rowA, rowB, columnId, desc) => {
        //   const dateA = new Date(rowA.values[columnId]);
        //   const dateB = new Date(rowB.values[columnId]);
        //   return dateA.getTime() - dateB.getTime();
        // },
        // isSorted: true,
      },
      {
        Header: tr["Rule For"] || "Rule For",
        accessor: "ruleFor",
        Cell: ({ value }) => {
          if (value === "criticalincidentmanager")
            return "Critical Incident Manager";
          else if (value === "changemanager") return "Change Manager";
          else if (value === "problemmanager") return "Problem Manager";
          else return value;
        },
        Filter: ({ column, setFilter }) => (
          <SelectColumnFilterCheckbox
            column={column}
            setFilter={setFilter}
            data={ruleForOptions || []}
            clearAll={!props?.showFilterIcon}
            setIsEditDetails={setIsEditDetails}
            isDropdownOpen={isMenuOpen}
            showDropdownFun={showDropdownFun}
            ref={multiDropdownRuleForRef}
          />
        ),
      },
    ],
    [rulesType, moduleOptions, ruleForOptions, statusOptions, tr]
  );

  useEffect(() => {
    (async function () {
      const businessdata = await dispatch(loadBusinessFunctionList());
    })();
  }, []);

  useEffect(() => {
    if (allBusinessFunctions?.length) {
      const businessData = allBusinessFunctions?.map((item) => ({
        label: item?.businessFunctionName,
        value: item?.businessFunctionName,
      }));
      const modifiedData = [
        ...businessData,
      ];
      setBusinessOptions(modifiedData);
    }
  }, [allBusinessFunctions?.length]);

  useEffect(() => {
    if (ruleType) {
      const data = ruleType?.map((item) => ({
        label: item?.fieldValue,
        value: item?.fieldKey,
      }));
      setRulesType(data);
    }
  }, [ruleType]);

  useEffect(() => {
    if (moduleData) {
      const mdoules = moduleData?.map((item) => ({
        label: item?.fieldValue,
        value: item?.fieldKey,
      }));
      setModuleOptions(mdoules);
    }

    if (stuatusData) {
      const statusDd = stuatusData?.map((item) => ({
        label: item?.fieldValue,
        value: item?.fieldKey,
      }));
      setStatusOptions(statusDd);
    }

    if (allRulesForData) {
      const rulefor = allRulesForData?.map((item) => ({
        label: item?.fieldValue,
        value: item?.fieldKey,
      }));
      setRuleForOptions(rulefor);
    }
  }, [moduleData, stuatusData, allRulesForData]);

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    setAllFilters,
  } = useTable(
    {
      columns,
      data: tableData,
      defaultColumn,
      manualFilters: true,
      initialState: {
        sortBy: [
          {
            id: searchParams.sortBy || 'modifiedOn',
            desc: searchParams.orderBy === 'asc' ? false : true,
          },
        ],
      },
    },
    useFilters,
    useSortBy
  );

  useEffect(() => {
    setTableRows(rows.sort((a, b) => Number(a.id) - Number(b.id)));
  }, [rows])

  useEffect(() => {
    if (isFilterCleared) {
      setAllFilters([{ id: "status", value: ["active"] }]);
      setIsFilterCleared(false);
    }
  }, [isFilterCleared]);

  useEffect(() => {
    if (pageNum !== 0) {
      setPageNum(0);
    }
  }, [state?.filters]);

  const handleRowClick = (row, index) => {
    setRowData(row);
    setActiveRowIndex(index);
    setIsEditDetails(true);
    showDropdownFun("allFalse",false);
  };

  const onPageChange = (val) => {
    try {
      const value = val > 0 ? val - 1 : 0;
      setPageNum(value);
    } catch (e) {
      console.error(e);
    }
  };

  const onPageSizeChange = (pageNumber, val) => {
    setPageNum(0);
    setPgSize(val);
  };

  useEffect(() => {
    setPaginationData({
      totalPages: allRules?.body?.metaInfo?.recordCount,
      recordCount: allRules?.body?.metaInfo?.totalPageCount,
    });
    setTableData(allRules?.body?.records || []);
  }, [pageNum, pgSize, allRules]);

  useEffect(() => {
    const filters = [];
    let defaultStatus = "active";

    if (state?.sortBy?.length > 0) {
      setGlobalSortDirection(
        state?.sortBy?.[0]?.desc ? "Descending" : "Ascending"
      );
    }

    if (state?.filters?.length > 0) {
      state.filters.forEach((filter) => {
        if (
          filter.value !== undefined &&
          filter.value !== null &&
          filter.value !== ""
        ) {
          if (filter.id === "modifiedOn" || filter.id === "createdOn") {
            filters.push(
              `${filter.id}=${encodeURIComponent(filter?.value?.dateRange)}`
            );
          } else {
            filters.push(`${filter.id}=${encodeURIComponent(filter.value)}`);
          }
        }
      });
    }

    const searchByList = [];
    const multipleValueList = [];
    const patternList = [];

    filters.forEach((filter) => {
      const [key, value] = filter.split("=");
      searchByList.push(key);
      multipleValueList.push(decodeURIComponent(value.replace(/%2C/g, ",")));
      if (
        key === "ruleName" ||
        key === "consumerCompany" ||
        key === "createdBy"
      ) {
        patternList.push("like");
      } else if (key === "modifiedOn" || key === "createdOn") {
        patternList.push("between");
      } else {
        patternList.push("in");
      }
    });

    // searchByList.push("status");
    // multipleValueList.push(defaultStatus);
    // patternList.push("in");

    const queryObject = {
      searchByList: searchByList.join(","),
      multipleValueList: multipleValueList.join("|"),
      patternList: patternList.join(","),
      currentPage: Number(pageNum),
      size: Number(pgSize),
      orderBy: state?.sortBy?.length > 0 ? state?.sortBy?.[0]?.desc ? 'desc' : 'asc' : 'asc',
      sortBy: state?.sortBy?.length > 0 ? state?.sortBy?.[0]?.id : "modifiedOn",
    };

    setSearchParams(queryObject);
    setIsEditDetails(false);
  }, [state?.filters, state.sortBy, pageNum, pgSize]);

  return (
    <PanelGroup direction="horizontal" className={casePanelOverflow}>
      <Panel
        id="sidebar"
        minSize={33}
        order={1}
        defaultSize={isEditDetails ? 67 : 100}
        className={`${casePanelOverflow} ${
          isEditDetails ? "isShowLeftPanel catBordDv" : "catBordDv"
        }`}
      >
        <div
          aria-label="Table"
          role="contentinfo"
          className={caseResponsiveTable}
        >
          <div className={"tableRgtBor " + caseResponsive}>
            <Table
              striped
              bordered
              hover
              className="tableView nowrapWhright fltr-table"
              style={{ marginTop: "0px" }}
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => {
                      const {
                        render,
                        getHeaderProps,
                        isSorted,
                        isSortedDesc,
                        disableSortBy,
                        getSortByToggleProps,
                      } = column;
                      const extraClass = isSorted
                        ? isSortedDesc
                          ? "sorting_desc desc align-top"
                          : "sorting_asc asc align-top"
                        : `sorting align-top ${
                            disableSortBy
                              ? "disable-sorting align-top"
                              : "align-top"
                          }`;

                      return (
                        <th className={extraClass} title="">
                          <div
                            className="sortParArr"
                            {...getHeaderProps(getSortByToggleProps())}
                            title=""
                          >
                            {column.render("Header")}
                            <span
                              className="px-2"
                              title={isSorted ? globalSortDirection : ""}
                            >
                              {isSorted ? (
                                isSortedDesc ? (
                                  <i className="fa fa-sort-down"></i>
                                ) : (
                                  <i className="fa fa-sort-up"></i>
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                          <div className="colSearDv">
                            {column.canFilter ? column.render("Filter") : null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {isBusy || rulesFetching ? (
                  <tr>
                    <td
                      colSpan={columns.length}
                      style={{ textAlign: "center" }}
                    >
                      <ListLoader />
                    </td>
                  </tr>
                ) : tableRows?.length === 0 ? (
                  <tr>
                    <td colSpan={columns.length} className="text-center">
                      There is no matching data available
                    </td>
                  </tr>
                ) : (
                  tableRows?.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        onClick={() => handleRowClick(row?.original, i)}
                        className={
                          isEditDetails && activeRowIndex === i
                            ? "myActive"
                            : ""
                        }
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="text-capitalize"
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
          <div aria-label="Pagination" className="nBotPagina mt-3">
            <div className="nShow">
              <div className="margin-r-10">Show:</div>
              <Form.Select
                value={pgSize}
                onChange={(e) => {
                  onPageSizeChange(pageNum, e.target.value);
                }}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </Form.Select>
            </div>
            <nav aria-label="Pagination" className="display-inline-block">
              <Pagination
                prevPageText="Prev"
                nextPageText="Next"
                firstPageText={<i className="glyphicon glyphicon-menu-left" />}
                lastPageText={<i className="glyphicon glyphicon-menu-right" />}
                activePage={pageNum + 1}
                itemsCountPerPage={pgSize}
                totalItemsCount={paginationData?.recordCount}
                pageRangeDisplayed={4}
                onChange={(e) => onPageChange(e)}
              />
            </nav>
          </div>
        </div>
      </Panel>
      {isEditDetails ? (
        <>
          <PanelResizeHandle className="isShowLeftPanel resizeHandle">
            <div className="outlne">
              <div className="handIcn">
                <i className="fa fa-chevron-left" aria-hidden="true"></i>
              </div>
            </div>
          </PanelResizeHandle>
          <Panel minSize={33} order={2} defaultSize={33}>
            <RulesShortDescriptionList
              rowData={rowData}
              setIsEditDetails={setIsEditDetails}
            />
          </Panel>
        </>
      ) : (
        ""
      )}
    </PanelGroup>
  );
};

export default RulesList;
