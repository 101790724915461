
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { getCompanyList } from './../../../actions/spcmActions';
import {_inputField, _dropDown, _checkbox, TypeaheadExampleSingleSelect } from '../../../components/common/formFields';
import IncidentServiceTypeAhead from '../createMailboxConfigration/Common/IncidentServiceTypeAhead';
import RequesterTypeAhead from '../createMailboxConfigration/Common/RequestorTypeAhead';
import { getUrgencyImpactForSystemConfig, resetUrgencyImpactForSystemConfig } from '../../../actions/systemConfig/systemConfigAction';
import { GLOBAL } from '../../Globals';

// const validate = (values, props) => {
//   // const errors = {};
//   // if (!values.MAILBOX_ID || (values.MAILBOX_ID && values.MAILBOX_ID.trim() == ""))
//   //   errors.MAILBOX_ID = "Enter value in Mailbox Id/Name";

//   // if (!values.MAILBOX_PROVIDER ) {
//   //   errors.MAILBOX_PROVIDER = 'Select MailBox Type';
//   // }
//   // if (!values.WHITELISTED_DOMAINS || (values.WHITELISTED_DOMAINS && values.WHITELISTED_DOMAINS.trim() == "")){
//   //   errors.WHITELISTED_DOMAINS = "Enter Whitelisted Domains";
//   // }
//   // if (!values.impact){
//   //   errors.impact = "Enter Default Impact";
//   // }
//   // if (!values.urgency){
//   //   errors.urgency = "Enter Default Urgency";
//   // }
//   // if (!values.ACTIONS){
//   //   errors.ACTIONS = "Select Actions";
//   // }
//   // return errors;
// }; 

class xsmSystemConfigurationEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyList: [],
      selectedCompanyList: [],
      isActive: "false",
      companyId: '',
      targetofferingList: [],
      selectedRequestor:{},
      requestorError: '',
      companyName: '',
      selectedOfferingList: [],
      typedCompany: '',
      selectedOfferingName: '',
      initialValuesUpdated: false,
      selectedGuestUser: '',
      disableGuestUser: true
      // setMailboxIdErrorColor: '',
      // setMailboxProviderErrorColor: '',
      // setWhitelistedDomainErrorColor: '',
      // setUrgencyErrorColor: '',
      // setImpactErrorColor: '',
      // setActionsErrorColor: ''
    }
    this.setCompany = this.setCompany.bind(this);
    this.companyInputChange = this.companyInputChange.bind(this);
    this.setErrorColor = this.setErrorColor.bind(this);
    this.onConsumerSelected = this.onConsumerSelected.bind(this);
    this.onCrossClickOffering = this.onCrossClickOffering.bind(this);
    this.onCrossClickCompany = this.onCrossClickCompany.bind(this);
    this.handleOnChangeAction = this.handleOnChangeAction.bind(this);
    this.validateEditConfig = this.validateEditConfig.bind(this);
    this.onCrossClickService = this.onCrossClickService.bind(this);
  }
   
  componentDidMount() {
    this.props.setMailBoxType(this.props.mailBoxType) ;
    const { initialValues, dispatch } = this.props;
    this.props.getCompanyList("");
    this.setState({isActive: this.props.initialValues.STATUS== 'Active'})
  }
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.spcmReducer.companyList.length > 0 && this.state.companyList.length === 0) {
      this.props.setSelectedCompanyList([{
        field1Key: this.props.initialValues.COMPANY_NAME,
          value: this.props.initialValues.COMPANY_ID
      }])
      this.setState({
        companyList: nextProps.spcmReducer.companyList,
        // selectedCompanyList: [{
        //   field1Key: this.props.initialValues.COMPANY_NAME,
        //   value: this.props.initialValues.COMPANY_ID
        // }]
      });
      let data = { "companyId":this.props.initialValues.COMPANY_ID , "module": "Breakfix", "field": "Urgency,Impact", "status": "1", 'language': 'en' }
      this.props.dispatch(getUrgencyImpactForSystemConfig(data));
    }
    if (Object.keys(nextProps.initialValues).length > 0 && !this.state.initialValuesUpdated) {
      this.props.dispatch(change('xsmSystemConfigurationEditForm', 'COMPANY_NAME', nextProps.initialValues.COMPANY_NAME))
      this.props.dispatch(change('xsmSystemConfigurationEditForm','COMPANY_ID',nextProps.initialValues.COMPANY_ID))
      this.props.dispatch(change('xsmSystemConfigurationEditForm','OFFERING_NAME',nextProps.initialValues.OFFERING_NAME))
      this.props.dispatch(change('xsmSystemConfigurationEditForm','OFFERING_ID',nextProps.initialValues.OFFERING_ID))
      this.props.dispatch(change('xsmSystemConfigurationEditForm','GUEST_USER_NAME',nextProps.initialValues.GUEST_USER_NAME))
      this.props.dispatch(change('xsmSystemConfigurationEditForm','GUEST_USER_ID',nextProps.initialValues.GUEST_USER_ID))
      this.props.dispatch(change('xsmSystemConfigurationEditForm','impact',nextProps.initialValues.IMPACT))
      this.props.dispatch(change('xsmSystemConfigurationEditForm','urgency',nextProps.initialValues.URGENCY))
      this.props.dispatch(change('xsmSystemConfigurationEditForm', 'impact_name', nextProps.initialValues.IMPACT_NAME))
      this.props.dispatch(change('xsmSystemConfigurationEditForm', 'urgency_name',nextProps.initialValues.URGENCY_NAME))
      this.setState({ companyId: nextProps.initialValues.COMPANY_ID, selectedOfferingName: nextProps.initialValues.OFFERING_NAME,selectedGuestUser: nextProps.initialValues.GUEST_USER_NAME, initialValuesUpdated:true });
      if (nextProps.initialValues.ACTIONS === 'Create with Guest Account') {
        this.setState({disableGuestUser : false})
      }
    }
  }

  onCrossClickCompany = () => {
     this.companyInputChange("");
     this.props.setSelectedCompanyList([]);
     this.props.setSelectedRequestor({});
     this.setState({ selectedOfferingName: '' });
     this.setState({ selectedGuestUser: '' });
     this.props.setSelectedOffering({});
     this.props.dispatch(change('xsmSystemConfigurationEditForm', 'COMPANY_NAME', []));
     this.props.dispatch(change('xsmSystemConfigurationEditForm', 'COMPANY_ID', ''));
     this.props.dispatch(change('xsmSystemConfigurationEditForm','OFFERING_NAME',''))
    this.props.dispatch(change('xsmSystemConfigurationEditForm', 'OFFERING_ID', ''))
    this.props.setSelectedOffering({});
     this.props.dispatch(change('xsmSystemConfigurationEditForm', 'GUEST_USER_ID', ''));
     this.props.dispatch(change('xsmSystemConfigurationEditForm', 'GUEST_USER_NAME', ''));
     this.props.dispatch(change('xsmSystemConfigurationEditForm', 'impact', ''));
     this.props.dispatch(change('xsmSystemConfigurationEditForm', 'urgency', ''));
     this.props.dispatch(resetUrgencyImpactForSystemConfig());

  };
  onCrossClickService = () => {
    this.props.setSelectedRequestor({});
    this.props.dispatch(change('xsmSystemConfigurationEditForm', 'GUEST_USER_ID', ''));
    this.props.dispatch(change('xsmSystemConfigurationEditForm', 'GUEST_USER_NAME', ''));
  }
  onCrossClickOffering = () => {
    this.props.setSelectedOffering({});
    this.props.dispatch(change('xsmSystemConfigurationEditForm','OFFERING_NAME',''))
    this.props.dispatch(change('xsmSystemConfigurationEditForm', 'OFFERING_ID', ''))
  };
  onConsumerSelected = (suggestion) => {
    this.props.setSelectedRequestor(suggestion)
    if (Object.keys(suggestion).length > 0) {
      let userId = suggestion.user_id;
      let userName = suggestion.full_name;
      this.props.dispatch(change('xsmSystemConfigurationEditForm', 'GUEST_USER_ID', userId));
      this.props.dispatch(change('xsmSystemConfigurationEditForm', 'GUEST_USER_NAME', userName));
    } else {
      this.props.dispatch(change('xsmSystemConfigurationEditForm', 'GUEST_USER_ID', ''));
      this.props.dispatch(change('xsmSystemConfigurationEditForm', 'GUEST_USER_NAME', ''));
    }
  };

   setErrorColor = () => {
    if (this.props.selectedRequestor.length > 0) {
      this.setState({ requestorError: false });
    } else {
      this.setState({ requestorError: true });
    }
  };

  onServiceSelected = (suggestion) => {
    if (Object.keys(suggestion).length > 0) {
      this.props.setSelectedOffering(suggestion);
      this.props.dispatch(change('xsmSystemConfigurationEditForm', 'OFFERING_ID', suggestion.offeringId))
      this.props.dispatch(change('xsmSystemConfigurationEditForm', 'OFFERING_NAME', suggestion.offeringName))
    } else {
      this.props.dispatch(change('xsmSystemConfigurationEditForm', 'OFFERING_ID', ''))
      this.props.dispatch(change('xsmSystemConfigurationEditForm', 'OFFERING_NAME', ''))
      this.props.setSelectedOffering({});
    }
  }

  setCompany = (selectedCompany) => {
    if (selectedCompany.length > 0) { 
      this.props.setSelectedCompanyList(selectedCompany);
      this.setState({companyId:selectedCompany[0].field1Value, companyName:selectedCompany[0].field1Key });
      this.props.dispatch(change('xsmSystemConfigurationEditForm', 'COMPANY_ID', selectedCompany[0].field1Value))
      this.props.dispatch(change('xsmSystemConfigurationEditForm','COMPANY_NAME', selectedCompany[0].field1Key))
      let data = { "companyId":selectedCompany[0].field1Value , "module": "Breakfix", "field": "Urgency,Impact", "status": "1", 'language': 'en' }
      this.props.dispatch(getUrgencyImpactForSystemConfig(data));
    } else {
      this.props.setSelectedCompanyList([]);
      this.props.dispatch(change('xsmSystemConfigurationEditForm', 'impact', ''));
      this.props.dispatch(change('xsmSystemConfigurationEditForm', 'urgency', ''));
      this.props.dispatch(resetUrgencyImpactForSystemConfig());
    }
  }

  companyInputChange = (e) => {
    this.props.setSelectedCompanyList([]);
    this.setState({typedCompany: e})
    this.setCompany([]);
  }
  mailboxCredential = (e) => {
    if (typeof (this.props.mailBoxType) !== 'undefined') {
      this.props.setMailBoxType(e.target.value) ;
    }
    else {
      this.props.setMailBoxType('');
    }
  }
  handleOnChangeAction= (e) => {
    if (e.target.value === 'Create with Guest Account') {
      this.setState({disableGuestUser: false});
    }
    else {
      this.setState({ disableGuestUser: true });
      this.props.dispatch(change('xsmSystemConfigurationEditForm', 'GUEST_USER_NAME', ''));
    }
  }
  validateEditConfig = (fieldType, event) => {
    switch (fieldType) {
      case "MAILBOX_ID":
        if (event.target.value == "") {
          this.props.setMailboxIdErrorColor("error");
        } else {
          this.props.setMailboxIdErrorColor("");
        }
        break;
      case "MAILBOX_PROVIDER":
        if (event.target.value == "") {
          this.props.setMailboxProviderErrorColor("error");
        } else {
          this.props.setMailboxProviderErrorColor("");
        }
        break;
      case "WHITELISTED_DOMAINS":
        if (event.target.value == "") {
          this.props.setWhitelistedDomainErrorColor("error");
        } else {
          this.props.setWhitelistedDomainErrorColor("");
        }
        break;
      case "urgency":
        if (event.target.value == "") {
          this.props.setUrgencyErrorColor("error");
        } else {
          this.props.setUrgencyErrorColor("");
        }
        break;

      case "impact":
        if (event.target.value == "") {
          this.props.setImpactErrorColor("error")
        } else {
          this.props.setImpactErrorColor("")
        }
        break;
        case "ACTIONS":
          if (event.target.value == "") {
            this.props.setActionsErrorColor("error");
          } else {
            this.props.setActionsErrorColor("");
          }
        break;
        case "STATUS":
          if (event.target.value == "") {
            this.props.setStatusErrorColor("error");
          } else {
            this.props.setStatusErrorColor("");
          }
          break;
      default:
    }
  }
  

  render() {
    let urgencyList = this.props.urgencyImpactReducer.filter((data)=>{
      return  data.field2Name=="Urgency"
    });
    let impactList = this.props.urgencyImpactReducer.filter((data)=>{
      return  data.field2Name=="Impact"
    });

    let mailBoxType = [
      { text: 'Gmail', value: 'Gmail' },
      { text: 'Outlook', value: 'Outlook' },];
    
    let actionsValue = [
        {text: 'Discard the Record',
          value: 'Discard the Record'},
        {text: 'Create with Guest Account',
          value: 'Create with Guest Account'},
        {text: 'Create User Profile',
          value: 'Create User Profile'}
    ]
    let status = [
      {
        text: 'Active',
        value: 'Active'
      },
      {
        text: 'Inactive',
        value: 'Inactive'
      },
    ]

    return (
      <div>
        <div className="borderForm bFormDiv">
        <form>
          <Row>
            <Col md={6}>
                <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Mailbox']}</Form.Label>
                  <Field name="MAILBOX_ID" className={"form-control " + (this.props.mailboxIdErrorColor)} component={_inputField} maxLength={120}
                  onBlur={(event) => {
                    this.validateEditConfig("MAILBOX_ID", event);
                  }}>
                  </Field>
                </Form.Group>
              </Col>
              <Col md={6}>
              <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Vendor']}</Form.Label>
                  <Field name="MAILBOX_PROVIDER" className={"form-control "+ (this.props.mailboxProviderErrorColor)} component={_dropDown} options={mailBoxType} onChange={(e) => { this.mailboxCredential(e) }}
                  onBlur={(event) => {
                    this.validateEditConfig("MAILBOX_PROVIDER", event);
                  }}>
                  </Field>
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <Col md={6}>
                <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Company']}</Form.Label>
                  <Field
                  name="COMPANY_NAME"
                  component={TypeaheadExampleSingleSelect}
                  className="form-control"
                  options={this.state.companyList}
                  selectedOptions={this.props.selectedCompanyList}
                  onSelection={this.setCompany}
                  errorClass={this.props.companyError}
                  onInputChange={this.companyInputChange}
                  onCrossClick={this.onCrossClickCompany}
                  typedValue={this.state.typedCompany}
                  setErrorColor={this.props.setCompanyErrorColor}
                  labelKey={"field1Key"}
                />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Service']}</Form.Label>
                  <Field
                  name="OFFERING_NAME"
                  component={IncidentServiceTypeAhead}
                  className="form-control"
                  companyId={this.state.companyId}
                  placeHolder={this.state.selectedOfferingName}
                  onCrossClick={this.onCrossClickOffering}
                  errorColor={this.props.offeringError}
                  onServiceSelected={this.onServiceSelected}
                  validateField= {this.props.setOfferingErrorColor}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="margin-t-5 padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
            <div className="bgGray padding-5">
              <span className="rStar"></span>
              {this.props.translator["Mailbox Filters"]}
            </div>
            <Row>
            <Col md={4}>
                <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Whitelisted Domains']}</Form.Label>
                    <Field name="WHITELISTED_DOMAINS" className={"form-control " + (this.props.whitelistedDomainErrorColor)} component={_inputField} maxLength={120} placeholder={`gmail.com,yahoo.com`}
                    onBlur={(event) => {
                      this.validateEditConfig("WHITELISTED_DOMAINS", event);
                    }}>
                  </Field>
                </Form.Group>
              </Col>
              <Col md={4}>
              <Form.Group >
                  <Form.Label bsClass="">{this.props.translator[`Always Process Exception`]}</Form.Label>
                  <Field name="NO_EXCEPTIONS" className="form-control" component={_inputField}  maxLength={120} placeholder={`abc@outlook.com,xyz@gmail.com`}>
                  </Field>
                </Form.Group>
                </Col>
                <Col md={4}>
              <Form.Group >
                  <Form.Label bsClass="">{this.props.translator[`Never Process Exception`]}</Form.Label>
                  <Field name="EXCEPTION_MAIL_IDS" className="form-control" component={_inputField}  maxLength={120} placeholder={`abc@outlook.com,xyz@gmail.com`}>
                  </Field>
                </Form.Group>
              </Col>
              </Row>
            </div>
            <div className="margin-t-5 padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
            <div className="bgGray padding-5">
              <span className="rStar"></span>
              {this.props.translator["Defaults"]}
            </div>
            <Row>
            <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Default Impact']}</Form.Label>
                    <Field component={_dropDown} name="impact" options={impactList} className={"form-control " + (this.props.impactErrorColor)}
                    onBlur={(event) => {
                      this.validateEditConfig("impact", event);
                      }}
                      onChange={(event)=>{this.props.dispatch(change('xsmSystemConfigurationEditForm', 'impact_name', event.target[event.target.selectedIndex].text));}}/>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Default Urgency']}</Form.Label>
                    <Field component={_dropDown} name="urgency" options={urgencyList} className={"form-control " + (this.props.urgencyErrorColor)}defaultValue={this.props.initialValues.URGENCY}
                    onBlur={(event) => {
                      this.validateEditConfig("urgency", event);
                      }}
                      onChange={(event)=>{this.props.dispatch(change('xsmSystemConfigurationEditForm', 'urgency_name', event.target[event.target.selectedIndex].text));}}/>
                </Form.Group>
              </Col>
              </Row>
            </div>
            <div className="margin-t-5 padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
            <div className="bgGray padding-5">
              <span className="rStar"></span>
              {this.props.translator["User Verification"]}
            </div>
            <Row>
            <Col md={6}>
              <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['User Verification Type']}</Form.Label>
                    <Field name="ACTIONS" className={"form-control " + (this.props.actionsErrorColor)} component={_dropDown} options={actionsValue} defaultValue={this.props.initialValues.ACTIONS} onChange={(e) => { this.handleOnChangeAction(e) }}
                    onBlur={(event) => {
                    this.validateEditConfig("ACTIONS", event);
                  }}>
                  </Field>
                </Form.Group>
              </Col>
            <Col md={6}>
              <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Guest User']}</Form.Label>
                  <div className="dvTypehdropdown">
                  {!this.state.disableGuestUser ? <Field
                    name="GUEST_USER_NAME"
                    className="form-control"
                    validateField={this.props.setRequestorErrorColor}
                    component={RequesterTypeAhead}
                    companyId={this.state.companyId || ""}
                    companyName={this.state.companyName || ""}
                    onConsumerSelected={this.onConsumerSelected}
                    selectedOption={this.props.selectedRequestor}
                    errorColor={this.props.requestorError}
                    placeHolder={this.state.selectedGuestUser}
                    onCrossClick={this.onCrossClickService}
                    >
                    </Field> :
                    <Field
                      className="form-control"
                      component='input'
                      name='GUEST_USER_NAME'
                      disabled
                      placeholder="User Name" />}
                    </div>
                </Form.Group>
              </Col>
              </Row>
              </div>
            <Row>
            <Col md={6}>
                <Form.Group >
                <span className="rStar" />
                  <Form.Label bsClass="" className='margin-r-10'>{this.props.translator['Status']}</Form.Label>
                  <Field name="STATUS" className={"form-control " + (this.props.statusErrorColor)} component={_dropDown} options={status}
                  onBlur={(event) => {
                    this.validateEditConfig("STATUS", event);
                  }}></Field>
                </Form.Group>
              </Col>
        </Row>
        </form>
        </div>
      </div>
    );
  }
}
let selector = formValueSelector('xsmSystemConfigurationEditForm');
xsmSystemConfigurationEditForm = connect((state) => {
  let mailBoxType = selector(state, 'MAILBOX_PROVIDER');
  return{mailBoxType}
})(xsmSystemConfigurationEditForm)

function mapStateToProps({ spcmReducer, savedSystemCofigList,urgencyImpactReducer  }) {

  return {
    initialValues: savedSystemCofigList, 
    spcmReducer,
    urgencyImpactReducer
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCompanyList,
      getUrgencyImpactForSystemConfig,
      resetUrgencyImpactForSystemConfig
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'xsmSystemConfigurationEditForm',
    destroyOnUnmount: true,
    // validate,
    enableReinitialize: true
  })(xsmSystemConfigurationEditForm)
);
