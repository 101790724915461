import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { EditorUtils, EditorToolsSettings } from '@progress/kendo-react-editor';
import { InsertImageDialog } from './insertImageDialog';
import { GetDataQuery } from "../../../studio/connectors/graphQl";
import { useQuery } from '@apollo/client';
import { transformFunction, updateDataQueryValues, validateRestRequest } from '../../../studio/connectors/utils';
import { useSelector } from 'react-redux';
const imageSettings = EditorToolsSettings.image;
const InsertImage = props => {
const openDialog1 = React.useRef(false);
const [files, setFiles] = React.useState([]);
const [dataSourceId, setDataSourceId] = React.useState(null);
const [responseData, setResponseData] = React.useState(null);
const [loading, setLoading] = React.useState(false);
const hosts = useSelector((state)=>state?.hosts);
const attributes = props?.item;

const { data: dataQuery, refetch: fetchDataQuery } = useQuery(GetDataQuery, {
  variables: { id: dataSourceId },
  skip: dataSourceId == null,
});

React.useEffect(() => {
  if (attributes?.data_source && files.length > 0) {
    if(dataQuery){
      fetchOptions();
    }else{
    setDataSourceId(props?.item?.data_source.id);
    fetchDataQuery();
  }
  }
}, [files]);

React.useEffect(() => {
  if (dataQuery) {
    fetchOptions();
  }
}, [dataQuery]);


const fetchOptions = () => {
  setLoading(true);
  const data = JSON.parse(dataQuery.dataQuery.data);
  data.body.value = data.dependent
    ? updateDataQueryValues(data.body.value, data.dependent, {[attributes?.imgName]:files[0],...props?.defaultValues})
    : data.body.value;
  if (data?.body?.variables) {
    data.body.variables = data.dependent
      ? updateDataQueryValues(data.body.variables, data.dependent, {[attributes?.imgName]:files[0],...props?.defaultValues})
      : data.body.variables;
  }
  const restOptions = {
    url: dataQuery.dataQuery.connector.url,
    host:
      dataQuery.dataQuery.connector?.module == "other"
        ? ""
        : hosts.hasOwnProperty(dataQuery.dataQuery.connector?.module)
        ? hosts[dataQuery.dataQuery.connector?.module]
        : "",
    verb: dataQuery.dataQuery.connector.verb,
    headers: data.dependent
      ? updateDataQueryValues(data.headers, data.dependent, {[attributes?.imgName]:files[0],...props?.defaultValues})
      : data.headers,
    queries: data.dependent
      ? updateDataQueryValues(data.query_string, data.dependent, {[attributes?.imgName]:files[0],...props?.defaultValues})
      : data.query_string,
    body: data.body,
  };
  validateRestRequest(restOptions)
    .then((response) => {
      if (data?.transform_response) {
        response.data = transformFunction(
          data?.transform_response,
          response?.data
        );
      }
      setResponseData(response);
      setLoading(false);
    })
    .catch(() => {
      setResponseData(null);
      setLoading(false);
    });
};

const [openDialog, setOpenDialog] = React.useState(openDialog1.current);

const toggleDialog = (dialog) => {setOpenDialog(dialog);openDialog1.current = dialog;};
  const {
    view
  } = props;
  const state = view && view.state;
  const nodeType = state ? state.schema.nodes[imageSettings.node] : undefined;
  return (
    <React.Fragment>
      <Button
        onClick={() => toggleDialog(true)}
        disabled={
          !nodeType || !state || !EditorUtils.canInsert(state, nodeType)
        }
        onMouseDown={(e) => e.preventDefault()}
        onPointerDown={(e) => e.preventDefault()}
        title="Insert Image"
        {...imageSettings.props}
        attributes={props?.attributes}
        defaultValues={props?.defaultValues}
      />
      {openDialog && view && (
        <InsertImageDialog
          key="insertImageDialog"
          view={view}
          onClose={(e) => toggleDialog(e)}
          imageNode={imageSettings.node}
          item={props?.item}
          defaultValues={props?.defaultValues}
          setFiles={setFiles}
          loading={loading}
          responseData={responseData}
        />
      )}
    </React.Fragment>
  );
};

export default InsertImage;