
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from "react";
import { Row, Col, Accordion,Table, Tab, Tabs, ListGroup, Button } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {PiPencilSimpleBold} from "react-icons/pi";
import {IoClose} from "react-icons/io5";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';

const UserShortDesc = (props) => {
    const navigate = useNavigate();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const context = useContext(MyContext);
    const closeButton = useRef(null);
    const editButton = useRef(null);

    useEffect(() => {
        context.keyDownHandler(closeButton.current,editButton.current,"","");
        context.newTabPrevent();
    }, []);

    console.log("companyData", props.userDetails);
    let userDataList = props.userDetails;

    const renderPITable = (a) => {
        return (
            <tr>
                <td className="bgBtn20 f-size-13" style={{"width": "50%"}}>Email Address</td>
                <td className="f-size-13">{a}</td>
            </tr>
        );
    }
    const renderOITable = (b) => {
        return (
            <tr>
                <td className="bgBtn20 f-size-13" style={{"width": "50%"}}>Organization</td>
                <td className="f-size-13">{b}</td>
            </tr>
        );
    }
    const renderUITable = (c) => {
        return (
            <tr>
                <td className="bgBtn20 f-size-13" style={{"width": "50%"}}>User Type</td>
                <td className="f-size-13">{c}</td>
            </tr>
        );
    }
    return (
        <>
            <Row>
                <Col xs={12}>
                    <div className="rPageHeadActBtn" style={{ "marginTop": "-3px" }}>
                        <ListGroup bsPrefix=" " as="ul">
                            <ListGroup.Item as="li" bsPrefix=" "><Link to={`/editUser/${userDataList.userId}`} bsPrefix=" " title={tr["Edit"]} className="editIcn ctrlKeyPrevent" ref={editButton} ><PiPencilSimpleBold/></Link></ListGroup.Item>
                            <ListGroup.Item as="li" bsPrefix=" "><Link to="javascript:void(0)" bsPrefix=" " title={tr["Minimize the details panel"]} className="ctrlKeyPrevent" onClick={()=>{props.setRightSideVisible(false)}} ref={closeButton} ><IoClose/></Link></ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{tr["User Details"]}</div></div>
                </Col>
            </Row>
            <div className="rBoxGap">
                <div className="rBoxTabs">
                    <ListGroup as="ul" bsPrefix=" ">
                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable" style={{ "borderTop": "none" }}><span className="rwKey">Name</span><span className="rwVal">{userDataList.fullname}</span></ListGroup.Item>

                        {/* <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">Type</span><span className="rwVal">{userDataList.userTypeId}</span></ListGroup.Item> */}

                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">Company</span><span className="rwVal">{userDataList.company}</span></ListGroup.Item>

                        <ListGroup.Item as="li" bsPrefix=" " className="rwTable"><span className="rwKey">Status</span><span className="rwVal">{userDataList.STATUS == 'D' ? 'Draft' : userDataList.STATUS == 'Y' ? 'Active' : 'Inactive'}</span></ListGroup.Item>
{/* 
                        <ListGroup.Item as="li" bsClass="">
                            <Accordion id="" accordion className="margin-t-20 brkFix brkFixOrder brkFullAccordion margin-b-10">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <h4 toggle>Personal Information</h4>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Table bordered condensed hover responsive className="tableView sortImg noWhSpace">
                                            <tbody>
                                                {renderPITable(userDataList.email)}
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <h4 toggle>Organizational Information</h4>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Table bordered condensed hover responsive className="tableView sortImg noWhSpace">
                                            <tbody>
                                                {renderOITable(userDataList.organizationName)}
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        <h4 toggle>User Information</h4>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Table bordered condensed hover responsive className="tableView sortImg noWhSpace">
                                            <tbody>
                                                {renderUITable(userDataList.userTypeId)}
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </ListGroup.Item> */}
                    </ListGroup>
                </div>
            </div>
        </>
    )
}

export default UserShortDesc;