
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Nav, NavDropdown, MenuItem, Tab, ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from 'react-redux';
import ListLoader from "../../loaders/ListLoader";
import { Link } from 'react-router-dom';
import { storeCategoryName } from '_Actions/homepage/hamburgerAction';

import { navigationHooks } from '../../../../helpers/NavigationHook';
import Cookies from 'universal-cookie';
import { resetStateInStore } from "../../../../actions/offeringDetails/offeringDetailsActions";
import { openLinkInNewTab } from "../../helper";
import {clientKnowledge} from "../../../../index"
import { useQuery } from "@apollo/client";
import { getKnowledgeData } from "./graphQLQuerry/knowledgeData";

const cookies = new Cookies();

let isKnowledgeEnabled = cookies.get('gph');
if (isKnowledgeEnabled) isKnowledgeEnabled = isKnowledgeEnabled.replace('s:', '');
if (isKnowledgeEnabled) isKnowledgeEnabled = isKnowledgeEnabled.substring(0, isKnowledgeEnabled.lastIndexOf('.'));
isKnowledgeEnabled = isKnowledgeEnabled.split("~");
isKnowledgeEnabled = isKnowledgeEnabled[30];

let discourseUrl = cookies.get('gph');
if (discourseUrl) discourseUrl = discourseUrl.replace('s:', '');
if (discourseUrl) discourseUrl = discourseUrl.substring(0, discourseUrl.lastIndexOf('.'));
discourseUrl = discourseUrl.split("~");
discourseUrl = discourseUrl[50];

let Result = (props) => {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const newRef = useRef(null);
  const [knowledgeOptions, setKnowledgeOptions] = useState([]);
  const toggle = () => props.setIsOpen(!props.isOpen);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  const handleOutsideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      props.setIsOpen(false);
    }
  };

  useEffect(() => {
    if (props.searchText.length > 2) {
      props.setIsOpen(true);
    }
  }, [props.searchText]);

  let serviceSearchData = [];
  let serviceSearchMeta = {};
  if (props.globalSearchDataForRevamp.data) {
    serviceSearchData = props.globalSearchDataForRevamp.data.data ?? [];
    serviceSearchMeta = props.globalSearchDataForRevamp.data.meta ?? {};
  }

  // let knowledgeSearchData = props.knowledgeGlobalResultsList.topics ?? [];

  const onOfferingClick = (i, j, k, l) => {
    let categoryName = k;
    if (j != "Suspended" && j != "Under Repair") {
      dispatch(storeCategoryName(k));
      dispatch(resetStateInStore('selected_components'));
      categoryName = categoryName.includes("?") ? categoryName.replace("?", "") : categoryName
      navigationHooks.navigate("/offeringDetails/" + i + "/" + categoryName + "/" + l);
    }
  }

  const renderServiceList = (data) => {
    return data.map((suggestion, index) => {
      const htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
      const string = suggestion.offeringSummary;
      const newString=string != null ? string.replace(htmlRegexG, "") : null;

      return (
        <>
          <ListGroup.Item as="li"> 
            <Link bsPrefix=' '
            key={index}
            to="javascript:void(0)"
            role="button"
            style={index == data.length - 1 ? { borderBottom: 'none' } : {}}
            onClick={() => onOfferingClick(
              suggestion.offeringId,
              suggestion.subStatus,
              suggestion.category,
              suggestion.subCategory
            )}>
              {/* &gt; {suggestion.subCategory} */}
              <div title={suggestion.offeringName} className="suggAnk">{suggestion.offeringName.length > 50 ? suggestion.offeringName.slice(0, 50) + "..." : suggestion.offeringName.slice(0, 50)}</div>
              {suggestion.category !=null ? <div title={suggestion.category} className="suggCat">{suggestion.category}</div>:null}
              {/* {string != null ? <div className="suggsum">{newString}</div> : null} */}
              </Link>
          </ListGroup.Item>
        </>
      );
    });
  }

  const { data: knowledgeData } = useQuery(getKnowledgeData, {
    variables: { searchTerm: props?.searchText, query: {} },
    client: clientKnowledge,
  });

  
  useEffect(()=>{
    if(knowledgeData && knowledgeData?.searchArticles?.data?.length !== 0){
      const knowledgeSearchData = knowledgeData?.searchArticles?.data;
      setKnowledgeOptions(knowledgeSearchData)
    }
  },[knowledgeData])



  const renderKnowledgeList = (data) => {
    if (data?.length > 10) {
      data = data.slice(0, 10);
    }     
    return data.map((suggestion, index)=>{
      return (
            <>
              <ListGroup.Item as="li">
                <Link bsPrefix=' '
                key={index}
                title={suggestion.title}
                to="javascript:void(0)"
                role="button"
                style={index == data.length - 1 ? { borderBottom: 'none' } : {}}
                onClick={() => {
                  navigationHooks.navigate(`/renderedView/67bc113284dfa81488b86ddb/${suggestion.id}`);
                }}>
                <div title={suggestion.title} className="suggAnk">{suggestion.title.length > 45 ? suggestion.title.slice(0, 45) + "..." : suggestion.title.slice(0, 45)}</div>
                {suggestion.article_type !=null ? <div title={suggestion.article_type} className="suggCat">{suggestion.article_type}</div>:null}
                </Link>
              </ListGroup.Item>
            </>
          );
    })
  }

  const renderResults = () => {
    if (props.searchOn == 'Service') {
      return (
        props.revampGlobalSearchShowLoader.loading == true ? <div className="padding-10"><ListLoader /></div> :
          serviceSearchData.length == 0 ? <div className="padding-10 text-c">{translator['No Data Found']}</div>
            :
            <ListGroup as={'ul'}>
              {renderServiceList(serviceSearchData)}
              {serviceSearchMeta.rowCount > 10 ?
                <Link title={translator["More"]} className="moreLnk"
                  to="/globalSearchList" state={{ searchText: props.searchText, categoryId: props.categoryId, start: 1, end: 10 }}
                >
                  {translator["More"]}
                </Link> : ""}
            </ListGroup>
      );
    } else if (props.searchOn === 'Knowledge' && (isKnowledgeEnabled == true || isKnowledgeEnabled == "true")) {
      return (
        // props.isFetchingGlobalKnowledgeDetails.isFetching == true ? 
        // <div className="padding-10"><ListLoader /></div> :
        knowledgeOptions?.length == 0 ? 
          <div className="padding-10 text-c">{translator['No Data Found']}</div>
            : 
            <ListGroup>
              { renderKnowledgeList(knowledgeOptions)}
              {knowledgeOptions?.length > 5  ?
                <Link title={translator["More"]} className="moreLnk" to={`/renderedView/67b85a2cb34da28934f0f3bd?searchTerm=${props.searchText}`}
                onClick={() => {
                  navigationHooks.navigate(`/renderedView/67b85a2cb34da28934f0f3bd?searchTerm=${props.searchText}`);
                }}
                >
                  {translator["More"]}
                </Link>
                 : ""}
            </ListGroup>
      );
    }
  }

  return (
    <>
      {props.searchText.length > 2 ?
        <div ref={newRef} className={props.isOpen ? "gloSearchResult display-block" : "gloSearchResult hidden"}>
          <Row onClick={toggle} className="clearfix">
            <Col sm={12}>
              {renderResults()}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="skStrip">
              {translator["Press"]} <span>Ctrl</span><span className="skPlus">+</span><span>\</span> {translator["to view Shortcuts."]}
              </div>
            </Col>
          </Row>
        </div>
        : null}
    </>
  )
}

Result = connect(({ revampGlobalSearchShowLoader, globalSearchDataForRevamp, isFetchingGlobalKnowledgeDetails, knowledgeGlobalResultsList, knowledgeCategoriesList }) => ({
  revampGlobalSearchShowLoader,
  globalSearchDataForRevamp,
  isFetchingGlobalKnowledgeDetails,
  knowledgeGlobalResultsList,
  knowledgeCategoriesList
}))(Result);

export default Result;
