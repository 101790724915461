
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react';
import { Table, Form, ButtonToolbar, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Field, change, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { TypeaheadandDropdown, _inputField } from '../../../../common/formFields';
import TableComponent from '../../../../common/ReactTable/TableComponent';
import {getUserCompanyAction,disassociateUserCompany} from "../../../../../actions/foundation/userAction";
import { useParams } from "react-router";
import axios from "axios";
import Swal from 'sweetalert2';
import { usePreviousValue } from '../../../../../helpers/usePreviousValue';
const GroupList = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const [companyErr, setCompanyErr] = useState("");
    const [associatedComapnyData, setAssociatedCompData] = useState([])
    const [associatedCompanyVal, setAssociatedCompanyVal] = useState([]);
    const [selectedAssociatedComp, setSelectedAssociatedComp] = useState([]);
    const userCompanyByTypeData = useSelector((state) => state.userCompanyByTypeRed);
    const disassociateCompany = useSelector((state) => state.disassociateCompRed);
    const previousValue = usePreviousValue(props);
    const dispatch = useDispatch();
    const params = useParams();
    let userId = params.id;
    let { handleSubmit } = props
    let isValid = true;

    useEffect(() => {
        dispatch(getUserCompanyAction(userId));

    }, [])
    useEffect(() => {
        axios.get("/api/viewAssociatedUserCompanyViaAjaxForUser/"+userId)
        .then((resp) => {
            if (resp.status == 200) {                      
                setSelectedAssociatedComp([]);
            }
        setAssociatedCompanyVal(resp.data);
    })

    }, [])

    useEffect(() => {
        if (userCompanyByTypeData && userCompanyByTypeData.length > 0) {
            setAssociatedCompData(userCompanyByTypeData);
        }
        else{
          setAssociatedCompData([]);
        }
    }, [userCompanyByTypeData])

    // useEffect(() => {
    //     console.log("GroupsDeleted", disassociateCompany)
    //     if ((props.companyType.id && props.companyType.id !== previousValue.companyType.id) || disassociateCompany === '1 rows deleted') {
    //         axios.get("/api/viewAssociatedUserCompanyViaAjaxForUser/"+userId)
    //         .then((resp) => {
    //             setAssociatedCompanyVal(resp.data);
    //             dispatch({ type: 'ASSOCIATED_COMPANY_DELETED', payload: ''});
    //         })
    //     }
    // }, [props.companyType, disassociateCompany])
    
    const onCompanySelection = (selectedAssociatedComp) => {
        if (selectedAssociatedComp.length > 0) { 
            setSelectedAssociatedComp(selectedAssociatedComp)
            dispatch(change('GroupList', 'id', selectedAssociatedComp))
            setCompanyErr("")
          } else {
            setSelectedAssociatedComp([]);
          }
    }
 
    const onCrossClickGroup = () => {
        setSelectedAssociatedComp([]);
        setCompanyErr("");
        isValid = false;

    }

    const saveAssociatedCompfun = () =>{
        if (selectedAssociatedComp.length === 0) {
            setCompanyErr("error")
            isValid = false;

        }

        let object = []
        let payload = {}
        selectedAssociatedComp.forEach((values) => {
          let val = {
             id: values.id,
             userId : userId
            }
            object.push(val);
          })
       payload = {
        userCompanyList: object
       }

       if(isValid === true){
       axios
         .post("/api/saveAddAssociateCompanyModalDataForUser", payload)
         .then((response) => {
           console.log("COMPANYASS",response.data);
           axios.get("/api/viewAssociatedUserCompanyViaAjaxForUser/"+userId)
               .then((resp) => {
                   if (resp.status == 200) {                      
                       setSelectedAssociatedComp([]);
                   }
               setAssociatedCompanyVal(resp.data);
        dispatch(getUserCompanyAction(userId));

           })
         })
       }

    }
    const tableColumns = [
        {
            name: "ID",
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: "Name",
          sortable: true,
          cell: (row) => (<div title={row.name}>{row.name}</div>)
         },
         {
            name: "Description",
          sortable: true,
          cell: (row) => (<div title={row.description}>{row.description}</div>)
         },  
         {
            name: "Manual Association",
            cell: (row) => row.actionVal,
          sortable: true,
         },
         {
            name: "Inherited From group",
          sortable: true,
          cell: (row) => (<div title={row.groupList}>{row.groupList}</div>)
         },
         {
			name: "Disassociate",
			sortable: false,
			cell: (row) => (
			  <CellName row={row} 
			  />
			),
			ignoreRowClick: true,
		}
    ];
    const filterColumns = ["id","name"];
    const onRowSelect = () => {
        
    }

    const CellName = (props) => {
        let item = props.row;
        return (
        <div className="text-c">
            <i title="Disassociate" onClick={()=>{
            disassociateCompanies(item)
            }} className="fa fa-chain-broken"></i>
        </div>
        )
    };
    const disassociateCompanies = (item) => {
    let payload =
    {
        userCompanyList:[{
            id: parseInt(item.id, 10),
            userId : userId
        }]
    }
     
      console.log("CheckRoles", payload);
        Swal.fire({
            title: ["Are you sure you want to disassociate this role?"],
            width: 550,
            padding: '15px',
            showDenyButton: true,
            confirmButtonText: tr['Yes'],
            denyButtonText: tr['No'],
          }).then((result) => {
            if (result.isConfirmed) {
              axios.post("/api/deleteViewAssociatedCompanyModalDataForUser",payload).then((resp) => {
                axios.get("/api/viewAssociatedUserCompanyViaAjaxForUser/"+userId)
                .then((resp) => {
                    if (resp.status == 200) {                      
                        setSelectedAssociatedComp([]);
                    }
                setAssociatedCompanyVal(resp.data);
        dispatch(getUserCompanyAction(userId));

            }) 
              })
            // dispatch(disassociateUserCompany(payload));
            
            } 
          })
    }
    return (
        <>
             {props.show &&<><Form.Group className="form-group">
                <Form.Label>Company</Form.Label>
                <Field
                  name="id"
                  className="form-control"
                  component={TypeaheadandDropdown}
                  onSelection={onCompanySelection}
                  options={associatedComapnyData}
                  selectedOptions={selectedAssociatedComp}
                  errorClass={companyErr === "error" ? "error" : ""}
                  onCrossClick={onCrossClickGroup}
                  multiple={true}
                />
            </Form.Group>

            <ButtonToolbar className="black margin-t-5">
            <Button className="rgSidrkBtn smallBtn"
                onClick={handleSubmit(saveAssociatedCompfun)}>
               Add
                </Button>
            </ButtonToolbar></>}

            <div className="catBordDv leftSeardv integrateHubTable">
            {associatedCompanyVal && <div className='f-size-16 margin-t-20 margin-b-10'>Associated Companies</div>}
            <TableComponent
                data={Array.isArray(associatedCompanyVal)? associatedCompanyVal :[]}
                columns={tableColumns}
                headerColumns={filterColumns}
                onRowSelect={onRowSelect}
                uniqueKey={'id'}
                paginationText = {"Show"}
                />
                </div>
        </>
    )
}

export default reduxForm({
    form: 'GroupList',
    destroyOnUnmount: false,
    enableReinitialize: true
})(GroupList);