import React from 'react';
import { PieChart } from "react-minimal-pie-chart";

const D2CPieChart = ({data = [], title}) => {
   return (
      <div style={{ textAlign: 'center', fontSize: '15px' }}>
         <h3>{title}</h3>
      <PieChart data={data}
      style={{ height: "200px", width: "200px", borderRadius: "100%" }}
      startAngle={180}
      lengthAngle={180}
      lineWidth={40}
      animate
      segmentsShift={(index) => (index === 0 ? 0.5 : 0)}
      label={({ dataEntry }) => `${Math.round(dataEntry.percentage)} %`}
      labelStyle={{fontSize: '7px', fontFamily: 'sans-serif', fill: '#FFFFFF'}}
      labelPosition={80}
    />
    </div>);
}
export default D2CPieChart;