
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import {GLOBAL} from '../../components/Globals';
import _ from 'lodash';
import moment from 'moment';
import { loadChangeManagementIndividual, loadChangeManagementImplGroupIndividual} from '../breakFix/serviceGroupIAction';

import { loadStatusBasedHamburgerOptions} from '../common/loadStatusBasedHamburgerOptions';

import { updateWIBSearchData as updatePayload } from '../../utils/common';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

import Cookies from 'universal-cookie';
import { opsCategoryKeysMap } from '../../components/common/helper';
const cookies = new Cookies();
let defaultDateRangeValue = cookies.get('gph');
if (defaultDateRangeValue) defaultDateRangeValue = defaultDateRangeValue.replace('s:', '');
if (defaultDateRangeValue) defaultDateRangeValue = defaultDateRangeValue.substring(0, defaultDateRangeValue.lastIndexOf('.'));
defaultDateRangeValue = defaultDateRangeValue.split("~");
defaultDateRangeValue = defaultDateRangeValue[53];

export function loadChangeManagementmListSuccess(changeManagementList) {
  return {
    type: 'LOAD_CMList_SUCCESS',
    changeManagementList
  };
}

function setWibListFilterCriteria(obj = {}){
	// console.log('setWibListFilterCriteria obj - ', obj);
	return {
		type: 'LOAD_WIB_LIST_PAYLOAD',
		payload: obj['payload']
	}
}

export function loadChangeManagementmList(searchParams,statusValue,changeUserid,impUserid,changeGroupid,impGroupid,type,isWIBGlobalSearchEnabled=false, filterType = '', tab="", groupFilterApplied="", isBacked = false) {
  console.log("searchParams",searchParams);
   let newSearchPara = { searchParams, statusValue, changeUserid, impUserid, changeGroupid, impGroupid, type, isWIBGlobalSearchEnabled, filterType, tab, groupFilterApplied};
    let searchKey = [];
    let searchValue = [];
    let statusPosition="";
    let searchParameter=[];
    if(changeUserid!=""&&impUserid!=""&&changeUserid!=undefined&&impUserid!=undefined||changeGroupid!=""&&impGroupid!=""&&changeGroupid!=undefined&&impGroupid!=undefined)
    {
      statusPosition=true;
    }
    else
    {
      statusPosition=false;
    }
   
    if (statusValue != "" && statusValue != undefined && statusPosition==false && !isBacked) {
      searchKey.push("status");
      searchValue.push(statusValue);
      searchParameter.push('in');
    }
    if(impUserid!=""&&impUserid!=undefined)
    {
      searchKey.push("impUserid");
      searchValue.push(impUserid);
      searchParameter.push('in');
    }
    if(changeUserid!=""&&changeUserid!=undefined)
    {
      searchKey.push("changeUserid");
      searchValue.push(changeUserid);
      searchParameter.push('in');
    }
    
    if(impGroupid!=""&&impGroupid!=undefined)
    {
      searchKey.push("impGroupid");
      searchValue.push(impGroupid);
      searchParameter.push('in');
    }
    if(changeGroupid!=""&&changeGroupid!=undefined)
    {
      searchKey.push("changeGroupid");
      searchValue.push(changeGroupid);
      searchParameter.push('in');
    }
    if (statusValue != "" && statusValue != undefined&& statusPosition==true && !isBacked) {
      searchKey.push("status");
      searchValue.push(statusValue);
      searchParameter.push('in');
    }
    if (type != "" && typeof type !=='undefined'){
      searchKey.push("changeType");
      searchValue.push(type);
      searchParameter.push('in');
    }
    if(!isWIBGlobalSearchEnabled && searchParams.operatorList !=="" && !isBacked)
    {
      searchParams.searchByList = searchParams.searchByList != "" ? searchKey.join(",") + "," + searchParams.searchByList : searchKey.join(",");
      searchParams.multipleValueList = searchParams.multipleValueList != "" ? searchValue.join("|") + "|" + searchParams.multipleValueList : searchValue.join("|");
      searchParams.patternList = searchParams.patternList != "" ? searchParameter.join(",") + "," + searchParams.patternList:searchParameter.join(",") ;
    }
  else if (searchKey.length != 0 && !isBacked) {
    searchParams.searchByList = searchParams.searchByList != "" ? searchKey.join(",") + "," + searchParams.searchByList : searchKey.join(",");
      searchParams.multipleValueList = searchParams.multipleValueList != "" ? searchValue.join("|") + "|" + searchParams.multipleValueList : searchValue.join("|");
      searchParams.patternList = searchParams.patternList != "" ? searchParameter.join(",") + "," + searchParams.patternList:searchParameter.join(",") ;
    }
    
    if(!isWIBGlobalSearchEnabled && searchParams && !isBacked){
      let searchByList = searchParams.searchByList.split(',');
      if(!searchByList.includes('createdOn')){
        let days = (defaultDateRangeValue && !isNaN(defaultDateRangeValue)) ? (parseInt(defaultDateRangeValue,10) - 1) : 29;
        searchByList.unshift('createdOn');
        searchParams.searchByList = searchByList.join(",");
        let value = [];
        // value.push(moment().subtract(29, 'days').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"));
        value.push(moment().subtract(days, 'days').format("MM-DD-YYYY"), moment().format("MM-DD-YYYY"));
        searchParams.multipleValueList = (searchParams.multipleValueList) ? (value.join("&") + "|" + searchParams.multipleValueList) : value.join("&");
			  searchParams.patternList = (searchParams.patternList) ? ("between" + "," + searchParams.patternList) : "between";
        searchParams.operatorList = (searchParams.operatorList) ? ("and" + "," + searchParams.operatorList) : "and";
      }
    }
  
    return (dispatch) => {

      let listPayloadArr = [ (searchParams), (statusValue || ''), (changeUserid || ''), (impUserid || ''), (changeGroupid || ''), (impGroupid || ''), (type || ''), (isWIBGlobalSearchEnabled || false), (filterType || ''), (tab || ''), (groupFilterApplied || ''), (isBacked || false) ];
      let listPayloadObj = { module: 'change', payload: listPayloadArr };
      dispatch({ type: 'RESET_WIB_LIST_PAYLOAD' });
      setTimeout(() => {
        dispatch(setWibListFilterCriteria(listPayloadObj));
      }, 500);

      dispatch({type:'FETCHING_DETAILS_IN_PROGRESS'});
      searchParams.sortBy = 'createdOn';
      searchParams.orderBy = 'desc';
      let params = {};
      if (!_.isEmpty(searchParams)) {
        params = searchParams;
       
      } //lodash isEmpty checks for nulls too

      let payload = updatePayload(params, 'change', 'wib');
      dispatch({type:"WIB_SEARCH_PARAMS", searchParams:{module:"changeManagement",searchParams:newSearchPara}});

      // console.log('payload, filterType - ', {params, payload, filterType});

      // if(!payload.data['filterApplied'] && ( (filterType && (filterType == 'tab-individual' || filterType == 'tab-group')) || (filterType && filterType == 'individual' && (params['searchByList'].includes('changeGroupid') || params['searchByList'].includes('impGroupid'))) ) ){
      //   payload.data['filterApplied'] = 'true';
      // }

      if(!payload.data['bothRoles'] && filterType && filterType == 'both'){
        payload.data['bothRoles'] = true;
      }
      if(tab !=="" && tab !== undefined && tab!=="All"){
         payload.data['tab'] = tab;
      }

      if(typeof payload.data['searchQueryDTOs']!=="undefined" && payload.data['searchQueryDTOs'].length>0){
        let searchQueryDTOs = payload.data['searchQueryDTOs'];
        const index = searchQueryDTOs.findIndex(item => item.key === "createdDate");
        if(index !== -1)
        {
          const date = searchQueryDTOs.splice(index,1);
          payload.data['searchQueryDTOs'] = [date[0],...searchQueryDTOs];
        } 
      }

      if((tab==="assigned_to_me" || tab ==="assigned_to_group") && groupFilterApplied && groupFilterApplied.length>0){
        payload.data['groupFilterApplied'] = groupFilterApplied.join(',');
      }

      api.post(GLOBAL.changeManagementListURL, payload)
        .then((changeManagementList) => {
          if (!changeManagementList) {
            throw Error(changeManagementList.statusText);
          }
          return changeManagementList;
        })
        .then((changeManagementList) => {
          dispatch(loadChangeManagementmListSuccess(changeManagementList));
          dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});
        })
        .catch((err) => {
          console.log(err);
        });
    };
  }

export function loadChangeManagementmListSort(searchParams) {

  return (dispatch) => {
    dispatch({type:'FETCHING_DETAILS_IN_PROGRESS'});
    let params = {};
    if (!_.isEmpty(searchParams)) {
      params = searchParams;
      
    } //lodash isEmpty checks for nulls too
    api.post(GLOBAL.changeManagementListURL, params)
      .then((changeManagementList) => {
        if (!changeManagementList) {
          throw Error(changeManagementList.statusText);
        }
        return changeManagementList;
      })
      .then((changeManagementList) => {
        dispatch(loadChangeManagementmListSuccess(changeManagementList));
        dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export const getLovValues = (module, field) => {

  return (dispatch) => {
    api.get(GLOBAL.getLovValues + "/module/" + module + "/field/" + field)
      .then((lovValueList) => {
        if (!lovValueList) {
          throw Error(lovValueList.statusText);
        }
        return lovValueList;
      })
      .then((lovValueList) => {
        switch (field) {
          case 'Type':
            dispatch(lov_ForType(lovValueList));
            break;
          case 'ChangeTiming':
            dispatch(lov_ForChangeTiming(lovValueList));
            break;
          case 'TechnicalRisk':
            dispatch(lov_ForTechnicalRisk(lovValueList));
            break;
          case 'BusinessRisk':
            dispatch(lov_ForBusinessRisk(lovValueList));
            break;
          case 'PlanType':
            dispatch(lov_ForPlanType(lovValueList));
            break;
          case 'Urgency':
            dispatch(lov_ForUrgency(lovValueList));
            break;
          case 'ReasonForChange':
            dispatch(lov_ForReasonForChange(lovValueList));
            break;
          case 'RiskOccurrenceProbability':
            dispatch(lov_ForRiskOccurrence(lovValueList));
            break;
          case 'RiskImpact':
            dispatch(lov_ForRiskImpact(lovValueList));
            break;
          case 'ControlEffectiveness':
            dispatch(lov_ForControlEffectiveness(lovValueList));
            break;
        }

      })
      .catch((err) => {
        console.log(err);
      });
  };
};



const lov_ForType = (lovValueList) => {
  return {
    type: 'LOV_FOR_TYPE',
    lovValueList
  };
};

const lov_ForChangeTiming = (lovValueList) => {
  return {
    type: 'LOV_FOR_CHANGE_TIMMING',
    lovValueList
  };
};

const lov_ForTechnicalRisk = (lovValueList) => {
  return {
    type: 'LOV_FOR_TECHNICAL_RISK',
    lovValueList
  };
};

const lov_ForBusinessRisk = (lovValueList) => {
  return {
    type: 'LOV_FOR_BUSINESS_RISK',
    lovValueList
  };
};

const lov_ForPlanType = (lovValueList) => {
  return {
    type: 'LOV_FOR_TYPE_PLAN_TYPE',
    lovValueList
  };
};

const lov_ForUrgency = (lovValueList) => {
  return {
    type: 'LOV_FOR_URGENCY',
    lovValueList
  };
};

const lov_ForReasonForChange = (lovValueList) => {
  return {
    type: 'LOV_FOR_CHANGE_REASON',
    lovValueList
  };
};

export const lov_ForRiskOccurrence = (lovValueList) => {
  return {
    type: 'LOV_FOR_RISK_OCCURRENCE',
    lovValueList
  };
};

export const lov_ForRiskImpact = (lovValueList) => {
  return {
    type: 'LOV_FOR_RISK_IMPACT',
    lovValueList
  };
};

export const lov_ForControlEffectiveness = (lovValueList) => {
  return {
    type: 'LOV_FOR_CONTROL_EFFECTIVENESS',
    lovValueList
  };
};
export const loadChangeDetailsForTask = (changeId) => {
   return (dispatch) => {
    dispatch({type:'FETCHING_DETAILS_IN_PROGRESS'});
    axios.get(GLOBAL.changeRelatedLogUrl + changeId).then(taskChangeData => {
      dispatch(changeEditDataLoadSuccess(taskChangeData.data,''));
      dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});
    });
   }
}
export const resetLoaderFlag = () => {
  return dispatch => {
    dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});
  }
}
export const loadChangeDataBackground = changeId => {
  return dispatch => {
    return api.get(GLOBAL.changeRelatedLogUrl + changeId).then(changeDetails => {
      dispatch(changeEditDataLoadSuccess(changeDetails.data,''));
    });
  };
}

export const loadChangeEditData = (changeId,date_format) => {
//  alert(date_format)
let groupAndIndividualData="";
let groupAndIndividualDataCM="";
console.log("date_format:::::"+date_format)
  return (dispatch,getState) => {
    dispatch({type:'FETCHING_DETAILS_IN_PROGRESS'});
    return api.get(GLOBAL.changeRelatedLogUrl + changeId)
      .then((changeData) => {
        if (!changeData) {
          throw Error(changeEditDataLoadFailure.statusText);
          // return Promise.reject('Error');
        }
        return changeData;
      })
      .then((changeData) => {
        console.log("changeData.... ",changeData)
       console.log("68869",changeData.data.status);
        dispatch(loadChangeManagementIndividual(changeData.data.changeGroupid));
        dispatch(loadChangeManagementImplGroupIndividual(changeData.data.impGroupid));
        dispatch(loadStatusBasedHamburgerOptions("ChangeManagement",changeData.data.status,changeData.data.changeId));
        GLOBAL.ciIdEdit = changeData.data.ciId;
        GLOBAL.breakFixConsumerID = changeData.data.requesterId;
        GLOBAL.ciClass = changeData.data.ciClassId;
        GLOBAL.servicelocationid = changeData.data.requesterLocationId;
        GLOBAL.servicelocationname = changeData.data.requesterLocationName;
        GLOBAL.ciLocationIdEdit = changeData.data.ciLocationId;
        let amsAttribute={
          "Status": changeData.data.statusId,
          "Priority": '0',
          "Service": changeData.data['serviceId'] || '0',
          "Impacted CI": changeData.data.ciId,
          "Requestor": changeData.data.requesterId,
          "Class": changeData.data.ciClassId,
          "User.Location": changeData.data.requesterLocationId,
          "CI.Location": changeData.data.ciLocationId
         };


          let rulesAttribute={
            "status":  changeData.data.status,
            "priorityId": undefined,
            "serviceName": changeData.data?.serviceName,
            "impactedCI":  changeData.data?.ciName,
            "impactedUsername": changeData.data?.requesterName,
            "ciClassName": changeData.data?.ciClassName,
            "userLocation": changeData.data?.requesterLocationName,
            "ciLocation": changeData.data?.ciLocationName
          };
         amsAttribute=JSON.stringify(amsAttribute);
        if(changeData.data.serviceBased==false)
        {
       groupAndIndividualData=api.get("/rest/getRuleConfiguration?companyId="+changeData.data.consumerCompany+"&moduleId=10&eventId=25",{ headers: { 'query': amsAttribute }});

        }
        else{
           groupAndIndividualData = api.get("/rest/getRuleConfiguration?companyId=" + changeData.data.consumerCompany + "&moduleId=10&eventId=25",{ headers: { 'query': amsAttribute }})
        }
        groupAndIndividualData.then((dropDownData) => {
          
          if (dropDownData.errorCode) {
            console.log('error loading drop down data for group and individual changemanagement module.')
          } else {
            let filteredbreakFixSGAEName = (dropDownData.data!== '' && Array.isArray(dropDownData.data)) ?
            dropDownData.data.filter((item,i)=>{
						return item.statusValue =="Active" ? true : false;
					}): [];
            if(GLOBAL.changepageLoad===true){
              let typeaheadData=[];//,currentEditdetails=getState().editChangeInitialData,flag=false;
              if(filteredbreakFixSGAEName!==''){
                filteredbreakFixSGAEName.map((item)=>{
                      typeaheadData.push({'value':''+item.assignmentGroupId,'label':item.assignmentGroupName,'isExternal':item.isExternal, 'supportCompanyID': item.assignmentCompanyId, 'supportCompanyName': item.assignmentCompanyName});
                      // if(typeof currentEditdetails.implementationGroup!=='undefined'){
                      //   if(item.assignmentGroupId+''===currentEditdetails.implementationGroup+''){
                      //     flag=true;
                      //   }
                      // }
                });
              }
              // if(GLOBAL.changeEdit===true && flag===false && typeof currentEditdetails.implementationGroup!=='undefined'){
              // if(currentEditdetails.implementationGroup!=='0' && currentEditdetails.implementationGroup!=='' && currentEditdetails.impGroupname!=='' && currentEditdetails.implementationGroup!==0){
              //   typeaheadData.push({'value':currentEditdetails.implementationGroup,'label':currentEditdetails.impGroupname});
              // }
              // }
              dispatch({type:'GROUP_LIST_TYPE_AHEAD_INITIAL',typeaheadData});
            }
            dispatch(loadBreakFixSGAESuccess(filteredbreakFixSGAEName));
          }
        });
      if(changeData.data.serviceBased==false)
      {
        groupAndIndividualDataCM = api.get("/rest/getRuleConfiguration?companyId=" + changeData.data.consumerCompany + "&moduleId=10&eventId=30",{ headers: { 'query': amsAttribute }});

      }
      else{
         groupAndIndividualDataCM = api.get("/rest/getRuleConfiguration?companyId=" + changeData.data.consumerCompany + "&moduleId=10&eventId=30",{ headers: { 'query': amsAttribute }})
      }
        groupAndIndividualDataCM.then((dropDownDataCM) => {
          if (dropDownDataCM.errorCode) {
            console.log('error loading drop down data for group and individual changemanagement module.')
          } else {
            if(GLOBAL.changepageLoad===true){
              let typeaheadData=[];//,currentEditdetails=getState().editChangeInitialData, flag=false;
              if(dropDownDataCM.data!==''){
              dropDownDataCM.data.map((item)=>{
                   typeaheadData.push({'value':''+item.assignmentGroupId,'label':item.assignmentGroupName, 'supportCompanyID': (item?.assignmentCompanyId || ''), 'supportCompanyName': (item?.assignmentCompanyName || '') });   
                  //  if(typeof currentEditdetails.changeManagementGroup!=='undefined'){
                  //    if(currentEditdetails.changeManagementGroup+''===item.assignmentGroupId+''){
                  //      flag=true;
                  //    }
                  //  }
              });
             }
              // if(GLOBAL.changeEdit===true && flag===false  && typeof currentEditdetails.changeManagementGroup!=='undefined'){
              //   if(currentEditdetails.changeManagementGroup!=='0' && currentEditdetails.changeManagementGroup!=='' && currentEditdetails.changeManagementGroup!==0 && currentEditdetails.changeGroupname!==''){
              //     typeaheadData.push({'value':currentEditdetails.changeManagementGroup+'','label':currentEditdetails.changeGroupname});
              // }}
              dispatch({type:'LOAD_BREAKFIXSGAECHANGE_SUCCESS_TYPEAHED',typeaheadData});
            }
            dispatch(loadBreakFixSGAEChangeSuccess(dropDownDataCM));
          }
        });
        dispatch(changeEditDataLoadSuccess(changeData.data,date_format));
        dispatch(extractedChangeDataForSucessRate(changeData.data));
        dispatch(changeFieldStatusData(changeData.data.statusId));
        dispatch({type:'LOAD_HAMBURGER_OPTIONS',payload:true});
        // dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});

        return Promise.resolve(changeData.data);
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject('Error');
      });
  };
};
export const changeFieldStatusData=(status_id)=>{
  console.log("action in field status");
  console.log("URL",GLOBAL.changeFieldStatusData + status_id);
  return(dispatch)=>{
    dispatch({type:'FETCHING_DETAILS_IN_PROGRESS'});
    api.get(GLOBAL.changeFieldStatusData + status_id)
      .then((statusData) => {
        console.log("inside then")
        if (!statusData) {
          throw Error(changeEditDataLoadFailure.statusText);
        }
        return statusData;
      })
      .then((statusData) => {
        console.log("changeData.... ",statusData)
        dispatch(FieldstatusData(statusData));
        dispatch({type:'DETAILS_FETCHED_SUCCESSFULLY'});

      }).catch((err) => {
        console.log(err, "error123");
      });

      
  }
}
export const FieldstatusData=(statusData)=>{
  console.log("statusData",statusData);
  // let fieldStatus={
  //   type:statusData.type,
  //   summary:statusData.summary,
  // }
  return {
    type: 'CHANGE_FIELD_STATUS_DATA_LOAD_SUCCESS',
    statusData
  };
}
export const changeEditDataLoadSuccess = (changeEditData,date_format='DD-MM-YYYY') => {
  console.log("df:::::::",changeEditData)
  console.log("date_format:::::::",date_format)
  let editChangeInitialData = {
    type: changeEditData.changeType,
    summary: changeEditData.summary,
    requestor: changeEditData.requesterName,
    impactedServiceName: changeEditData.serviceName,
    description: changeEditData.description,
    changeTiming: changeEditData.changeTiming,
    overallTechnicalRisk: changeEditData.technicalRisk,
    overallBusinessRisk: changeEditData.businessRisk,
    expectedStartDate: changeEditData.expStartDate,
    expectedEndDate: changeEditData.expEndDate,
    actualStartDate: changeEditData.actStartDate,
    actualEndDate: changeEditData.actEndDate,
    startDate: changeEditData.changeTiming === '20' ? null :changeEditData.dStartDate ,
    endDate: changeEditData.changeTiming === '20' ? null :changeEditData.dEndDate,
    lStartDate: changeEditData.changeTiming === '20' ? changeEditData.dStartDate : null,
    lEndDate: changeEditData.changeTiming === '20' ? changeEditData.dEndDate :  null,
    implementationGroup: changeEditData.impGroupid,
    impGroupname: changeEditData.impGroupname,
    implementationIndividual: changeEditData.impUserid,
    impUsername: changeEditData.impUsername,
    changeManagementGroup: changeEditData.changeGroupid,
    changeGroupname: changeEditData.changeGroupname,
    changeManagementIndividual: changeEditData.changeUserid,
    changeUsername: changeEditData.changeUsername,
    changeId: changeEditData.changeId,
    changeNumber: changeEditData.changeNumber,
    requestId: changeEditData.requestId,
    planType: changeEditData.planType,
    plan: changeEditData.plan,
    requesterName: changeEditData.requesterName,
    consumerCompany: changeEditData.consumerCompany,
    consumerCompanyName: changeEditData.consumerCompanyName,
    status: changeEditData.status,
    modifiedOn: changeEditData.modifiedOn,
    modifiedBy: changeEditData.modifiedBy,
    serviceId: changeEditData.serviceId,
    requesterId: changeEditData.requesterId,
    createdOn: changeEditData.createdOn,
    createdByName: changeEditData.createdByName,
    serviceName: changeEditData.serviceName,
    downtimeRequired: changeEditData.downtimeRequired,
    date_format: date_format ? date_format.toUpperCase() : date_format,
    ciId: changeEditData.ciId,
    ciName: changeEditData.ciName,
    ciCode: changeEditData.ciCode,
    asgGroupHopCount: changeEditData.asgGroupHopCount,
    ciClassName: changeEditData.ciClassName,
    urgency: changeEditData.urgency,
    reasonForChange: changeEditData.reasonForChange,
    riskOccurrenceProbability: changeEditData.riskOccurrenceProbability,
    riskImpact: changeEditData.riskImpact,
    controlEffectiveness: changeEditData.controlEffectiveness,
    risk: changeEditData.risk,
    requestNumber: changeEditData.requestNumber,
    cmdbUpdateNeeded: changeEditData.cmdbUpdateNeeded,
    cmdbUpdateDone: changeEditData.cmdbUpdateDone,
    ciClassId:changeEditData.ciClassId,
    ciConsumerCompany: changeEditData.ciConsumerCompany,
    ciLocationId: changeEditData.ciLocationId,
    ciLocationName: changeEditData.ciLocationName,
    ciSupportCompany: changeEditData.ciSupportCompany,
    serviceBased:changeEditData.serviceBased,
    cabDate:changeEditData.cabDate,
    statusId:changeEditData.statusId,
    isExternal:changeEditData.isExternal,
    requesterLocationId:changeEditData.requesterLocationId,
    requesterLocationName:changeEditData.requesterLocationName,
    supportCompanyId: changeEditData.supportCompanyId,
    supportCompanyName: changeEditData.supportCompanyName,
    residualRisk: changeEditData.residualRisk,
    riskAssessment: changeEditData.riskAssessment,
    tag: changeEditData.tag,
    [opsCategoryKeysMap[`opsCategory1`]]: changeEditData[opsCategoryKeysMap[`opsCategory1`]],
    [opsCategoryKeysMap[`opsCategory2`]]: changeEditData[opsCategoryKeysMap[`opsCategory2`]],
    [opsCategoryKeysMap[`opsCategory3`]]: changeEditData[opsCategoryKeysMap[`opsCategory3`]],
    [opsCategoryKeysMap[`opsCategoryId1`]]: changeEditData[opsCategoryKeysMap[`opsCategoryId1`]],
    [opsCategoryKeysMap[`opsCategoryId2`]]: changeEditData[opsCategoryKeysMap[`opsCategoryId2`]],
    [opsCategoryKeysMap[`opsCategoryId3`]]: changeEditData[opsCategoryKeysMap[`opsCategoryId3`]],
    actEndDate: changeEditData.actEndDate,
    actStartDate: changeEditData.actStartDate,
    isCabApprovalRequired: changeEditData.isCabApprovalRequired,
    isApprovalAttached: changeEditData.isApprovalAttached,
    approvalStepLevel: changeEditData.approvalStepLevel,
    approvalStatus: changeEditData.approvalStatus,
    testingRequired: changeEditData.testingRequired,
    expTestStartDate: changeEditData.expTestStartDate,
    expTestEndDate: changeEditData.expTestEndDate 
  };

  console.log("***************************************** ",editChangeInitialData);
  return {
    type: 'CHANGE_EDIT_DATA_LOAD_SUCCESS',
    editChangeInitialData
  };

};

export const extractedChangeDataForSucessRate = (changeData) => {

  const extractedChangeData = {
    changeType: changeData.changeType,
    cmdbUpdateNeeded: changeData.cmdbUpdateNeeded,
    urgency: changeData.urgency,
    reasonForChange: changeData.reasonForChange,
    residualRisk: changeData.residualRisk,
    ciClassName: changeData.ciClassName,
    ciName: changeData.ciName,
    summary: changeData.summary,
    consumerCompanyName: changeData.consumerCompanyName,
    companyId: changeData.consumerCompany
  }
  return {
    type: 'EXTRACTED_CHANGE_DATA_FOR_RATE_LOAD_SUCCESS',
    extractedChangeData
  };
}

const changeEditDataLoadFailure = (errorResponse) => {
  return {
    type: 'CHANGE_EDIT_DATA_LOAD_FAILURE',
    errorResponse
  };
};

export const loadBreakFixSGAESuccess = (breakFixSGAEName) => {
  return {
    type: 'LOAD_BREAKFIXSGAE_SUCCESS',
    breakFixSGAEName
  };
}

export const loadBreakFixSGAEChangeSuccess = (breakFixSGAEChangeName) => {
  return {
    type: 'LOAD_BREAKFIXSGAECHANGE_SUCCESS',
    breakFixSGAEChangeName
  };
}

export const getRiskCalculated = (riskOccurrence, riskImpact, controlEffectiveness) => {
  return (dispatch) => {
    dispatch({ type: "LOADER_IN_PROGRESS_3" });
    api.get(GLOBAL.getRiskAssessment + "/riskOccurrence/" + riskOccurrence + "/riskImpact/" + riskImpact + "/controlEffectiveness/" + controlEffectiveness)
      .then((response) => {
        dispatch({
          type: 'RISK_ASSESSMENT',
          response
        });
        dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_3' });
      })
      .catch((err) => {
        dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_3' });
        console.log(err);
      });
  };
};

export const changeConflict = (changeConflictJustification,changeConflictData) => {
  return (dispatch) => {
    dispatch({type: 'CHANGE_CONFLICT_JUSTIFICATION', changeConflictJustification});
    dispatch({type: 'CHANGE_CONFLICT_DATA', changeConflictData});
  };
};

export function loadPirLogDetails(changeId){
  console.log("changeId",changeId)
	return(dispatch) =>{
		dispatch({type:'LOADER_IN_PROGRESS_2'});
		if(changeId == undefined || changeId == ""){
		}else{
				api.get(GLOBAL.getPirDetailsUrl+changeId)
					.then((reponse)=>{
						console.log("reponse :: ",reponse)
						if(!reponse){
        dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_2' });
        
							throw Error(reponse.statusText);
						}
						return reponse.data;
					})
					.then((pirDetails) =>{
						dispatch(loadPirDetailsSuccess(pirDetails));
            dispatch({ type: 'LOADER_DETAILS_FETCHED_SUCCESSFULLY_2' });
					})
					.catch((err) => {
						console.log(err);
					});
		}
	};
}

export function loadPirDetailsSuccess(pirDetails){
	//alert('in success')
  console.log("pirDetailspirDetails ",pirDetails)
	return {
		type: 'LOAD_PIR_DETAILS',
		pirDetails
	};
}
export function resetSomeFieldsEditChange(){
  return(dispatch)=>{
    let typeaheadData=[],allGroup=[],editChangeInitialData={},recordCount=-2;
    dispatch({type:'LOAD_BREAKFIXSGAECHANGE_SUCCESS_TYPEAHED',typeaheadData});
		dispatch({type: 'GROUP_LIST_TYPE_AHEAD_INITIAL',typeaheadData});
		dispatch({type:'GROUP_LIST_TYPEAHEAD',allGroup});
		dispatch({type:'CHANGEMANAGEMENT_IMPL_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
		dispatch({type:'CHANGEMANAGEMENT_INDIVIDUAL_LOAD_SUCCESS_TYPE',typeaheadData});
    dispatch({type:'CHANGE_EDIT_DATA_LOAD_SUCCESS',editChangeInitialData});
    dispatch({type: 'LOAD_RELATED_WORK_ITEMS_COUNT',recordCount});
  }
}

export function resetrelatedCount(recordCount){
  return (dispatch)=>{
    dispatch({type: 'LOAD_RELATED_WORK_ITEMS_COUNT',recordCount});
  }
}

export function loadChangeDetails(payload, flag="noreset") {
  // console.log("payload", payload);
  return (dispatch) => {
    if(flag == "reset") return dispatch({type:"RESET_STATE"})
    dispatch({ type: "LOADER_IN_PROGRESS_2" });
    api
      .post(GLOBAL.changeManagementListURL, payload)
      .then((reponse) => {
        console.log("reponse :: ", reponse);
        if (!reponse) {
          dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY_2" });

          throw Error(reponse.statusText);
        }
        dispatch({ type: "LOAD_CNG_DETAILS", payload: reponse.data });
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY_2" });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}