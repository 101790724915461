
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';

import { Checkbox, Button, ButtonToolbar, ListGroup, ListGroupItem } from 'react-bootstrap';
import { Router, Route, hashHistory, Link } from 'react-router-dom';
import LoadingIcons from 'react-loading-icons';
import { connect } from 'react-redux';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import ListLoader from '../../../common/loaders/ListLoader';
import {PiPencilSimpleBold} from "react-icons/pi";
import {IoClose} from "react-icons/io5";
import { MyContext } from '_MyContext';

class CategoryBoardShortDescription extends React.Component {
    static contextType = MyContext;
    constructor(props) {
        super(props);

    }

    componentDidMount() {
		this.context.keyDownHandler(this.closeButton,this.editButton,"","");
        this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,this.editButton,"","");
        this.context.newTabPrevent();
	}

    render() {
        let companyName;
        let categoryID = this.props.categoryId;




        if (this.props.categoryBoardList.length == undefined) {
            return (
                // <LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
                <ListLoader />
            );
        }
        else if (this.props.categoryBoardList.length == 0) {
            return (
                <div>
                    <font><b>{this.props.translator['There is no matching data available']}</b></font>
                </div>
            );
        }
        else if (this.props.showLoader) {
            return (
                <ListLoader />
            );
        }
        this.props.categoryBoardList.map(function (item, index) {
            if (item.CATEGORY_ID == categoryID)
                companyName = item.COMAPNY_NAME;
        });
        return (
            <div className="margin-b-20">
                <div className="rPageHeadActBtn" style={{ marginTop: "-1px" }}>
                    <ul><li>
                        <Link to={"/editCategory/" + this.props.categoryId + "/" + this.props.catPinned} title={this.props.translator['Edit']} ref={e => this.editButton = e} className="ctrlKeyPrevent"><PiPencilSimpleBold/></Link>
                        </li>
                    <li>
                        <Link to="javascript:void(0)" title={this.props.translator["Minimize the details panel"]} bsClass="" bsStyle="" className="ctrlKeyPrevent" onClick={() => { this.props.showRightSide(false); }} ref={e => this.closeButton = e} >
                            <IoClose/>
                        </Link>
                    </li>
                    </ul>
                </div>
                <div className="rPageHeading">
                    <div className='offNam margin-t-5 margin-b-5'>{this.props.translator['Category Details']}</div>
                </div>
                <div className="rBoxGap">
                    <ListGroup bsPrefix=' ' bsClass="" className="">
                        <ListGroup.Item bsClass="" bsPrefix=' ' className="rwTable" style={{ "border-top": "none" }}><span className="rwKey">{this.props.translator['Company']} </span> <span className="rwVal1">{companyName}</span></ListGroup.Item>
                        <ListGroup.Item bsClass="" bsPrefix=' ' className="rwTable"><span className="rwKey">{this.props.translator['Category']} </span> <span className="rwVal1">{this.props.categoryName}</span></ListGroup.Item>
                        <ListGroup.Item bsClass="" bsPrefix=' ' className="rwTable"><span className="rwKey">{this.props.translator['Sub Category']} </span> <span className="rwVal1">{this.props.subCategoryName}</span></ListGroup.Item>
                        <ListGroup.Item bsClass="" bsPrefix=' ' className="rwTable"><span className="rwKey">{this.props.translator['Business Function']} </span> <span className="rwVal">{this.props.businessFunctionName}</span></ListGroup.Item>
                        <ListGroup.Item bsClass="" bsPrefix=' ' className="rwTable"><span className="rwKey">{this.props.translator['Status']} </span> <span className="rwVal">{this.props.status}</span></ListGroup.Item>

                        {/* <ListGroupItem bsClass=""className="rwTable"><span className="rwKey">{this.props.translator['Category Code']} -</span> <span className="rwVal">{this.props.categoryCode}</span></ListGroupItem>
                <ListGroupItem bsClass=""className="rwTable"><span className="rwKey">{this.props.translator['Category Id']} -</span> <span className="rwVal">{this.props.categoryId}</span></ListGroupItem> */}
                    </ListGroup>

                    {/*<ButtonToolbar className="margin-t-10"><Button bsStyle="primary">Transfer</Button><Button bsStyle="primary">Resolve</Button><Button bsStyle="primary">Tag</Button></ButtonToolbar>*/}
                </div>
            </div>
        );
    }
}

export function mapStateToProps({ categoryBoardList, showLoader }) {
    return { categoryBoardList, showLoader: showLoader.loading };
}

export default connect(mapStateToProps, null)(CategoryBoardShortDescription);

// export default CategoryBoardShortDescription;



