
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { GLOBAL } from "../../Globals";
import {
  updateLoadFulfillmentDetails,
  changeFieldStatusData,
} from "../../../actions/fulfillment/fulfillmentDataAction";
import { loadStatusBasedHamburgerOptions } from "../../../actions/common/loadStatusBasedHamburgerOptions";
import { loadTimelineData } from "../../../actions/common/commonApisActions.js";
import axios from "axios";
import Swal from "sweetalert2";
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
import { fulfillmentStatusMapping } from "../../common/helper.js";
const maxLength = 500;

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

const ReopenItemForm = (props) => {
  const translator = useSelector((state) => state.spcmReducer.tr);
  const dispatch = useDispatch();
  const [holdErrorMessage, setHoldErrorMessage] = useState("");
  const [clickOnCalSubmit, setClickOnCalSubmit] = useState(false);
  const [activityDescription, setActivityDescription] = useState("");

  const activityData = (e) => {
    let value = e.target.value.trim().replace(/['"]/g, "");
    if (value.length > 0) {
      setHoldErrorMessage("");
    }
    setActivityDescription(value);
  };

  /// submit function

  const submitReason = () => {
    // if (
    //   props.user_id != props.itemDetails.asgUserid &&
    //   (props.roleIdentification("43") || props.roleIdentification("44"))
    // ) {
    //   Swal.fire({
    //     text: translator[
    //       "Please assign Fulfillment ticket to yourself before you Submit"
    //     ],
    //     button: translator["OK"],
    //   });
    // } else
    if (props.itemDetails.itemStatus != fulfillmentStatusMapping["Fulfilled"]) {
      Swal.fire({
        text: translator["Please fulfill the ticket before you submit"],
        button: translator["OK"],
      });
    } else {
      api.get(GLOBAL.fulfillmentRelatedLogUrl + props.itemId)
      .then((breakFixEditDetails) => {
        if (breakFixEditDetails.data.itemStatus == fulfillmentStatusMapping["Fulfilled"]) {
          setClickOnCalSubmit(true);
      if (activityDescription.length > 0) {
        const itemId = props.itemId;
        const module = props.module;
        axios
          .patch(GLOBAL.fulfillmentpost + itemId, {
            itemStatus: fulfillmentStatusMapping["Under Fulfillment"],
            statusReopen: true,
            reopenReason: activityDescription,
            asgUserid: "0",
            asgUsername: "",
            modifiedBy: "",
            modifiedByName: "",
          })
          .then(function (response) {
            if (response.status === 201) {
              dispatch(changeFieldStatusData(fulfillmentStatusMapping["Under Fulfillment"]));
              dispatch(updateLoadFulfillmentDetails(response));
              dispatch(
                loadStatusBasedHamburgerOptions(
                  "Item",
                  response.data.status,
                  response.data.itemId
                )
              );
              axios
                .post(GLOBAL.breakFixActivityLogPostUrl, {
                  itemId: parseInt(itemId,10),
                  description: activityDescription,
                  isInternal: "N",
                  createdOn: "",
                  createdBy: "",
                  module: module,
                  createdByName: "",
                  resolutionTime: 0
                })
                .then(function (response) {
                  props.changeActionView();
                  setClickOnCalSubmit(false);
                })
                .catch(function (error) {
                  setHoldErrorMessage(translator["Reason Cannot Be Submitted"]);           
                  setClickOnCalSubmit(false);
                });
            }
          })
          .catch(function (error) {
            setHoldErrorMessage(translator["Reason Cannot Be Submitted"]);           
            setClickOnCalSubmit(false);
          });
      } else {
        setHoldErrorMessage(translator["Please provide reason"]);
        setClickOnCalSubmit(false);
      }
        }
        else {
          setHoldErrorMessage(translator["Reason Cannot Be Submitted"]);
          setClickOnCalSubmit(false);
        }
      })
      
    }
  };

  return (
    <div className="hambTab">
      <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
        <ul>
          <li>
          <button
              type='button'
              title="Minimize the right panel"
              bsClass=""
              bsStyle=""
              className="closerightPanelBtn"
              onClick={() => {
              props.rightEditPanel(false);
              props.changeRightView("allClose");
              }}
          >
              <IoClose/>
          </button>
          </li>
        </ul>
      </div>
      <div className="rPageHeading">
        <div className="offNam margin-t-5 margin-b-5">
          {translator["Reason For Reopen"]}
        </div>
      </div>
      <div className="rBoxGap">
        <Form.Group className="form-group">
          <Form.Label bsClass="">
            <span className="rStar"></span>
            {translator["Reason"]}
          </Form.Label>
          <Form.Control
            required="true"
            as="textarea"
            rows="2"
            className={holdErrorMessage ? "error" : ""}
            maxLength={maxLength}
            onChange={activityData}
          />
        </Form.Group>
        <div>
          <font color="red">
            <strong>{holdErrorMessage}</strong>
          </font>
        </div>
        <div>
          <ButtonToolbar className="black">
            <Button
              className="rgSidrkBtn smallBtn"
              bsSize="small"
              bsStyle="primary"
              disabled={clickOnCalSubmit}
              onClick={submitReason}
            >
              {clickOnCalSubmit ? (
                <ImSpinner6 className="icn-spinner"/>
              ) : null}{" "}
              {translator["Submit"]}
            </Button>
            <Button
              className="rgSiLigBtn smallBtn"
              size="sm"
              onClick={() => {
                props.changeActionView();
              }}
            >
              {translator["Cancel"]}
            </Button>
          </ButtonToolbar>
        </div>
      </div>
    </div>
  );
};

export default ReopenItemForm;
