
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
// import { Carousel, Col, Row } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { connect } from 'react-redux';
import { loadCatSelling } from '_Actions//homepage/categoryofferingAction';
import { bindActionCreators } from 'redux';
import { storeCategoryName } from '_Actions//homepage/hamburgerAction';
import { resetStateInStore } from "_Actions//offeringDetails/offeringDetailsActions";
import { loadCartCount } from '_Actions//common/cartCountAction';
import dummyImage from "_Images/service-icons/service-dummy.png";
import PropTypes from 'prop-types';
import ReactStars from 'react-stars';
let controlsFlag = false;
let counter = 0;
let tempSizeToProcess = null;
import axios from 'axios';
import { GLOBAL } from '_Globals';
import _ from 'lodash';
import Cookies from 'universal-cookie';
import swal from 'sweetalert';
import {LiaHeartSolid} from 'react-icons/lia';
import { navigationHooks } from '../../../helpers/NavigationHook';
import Swal from 'sweetalert2';
const Joi = require('joi')
const api = axios.create({
  headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
const cookies = new Cookies();
let cart_limit = cookies.get('gph');

if (cart_limit) cart_limit = cart_limit.replace('s:', '');
if (cart_limit) cart_limit = cart_limit.substring(0, cart_limit.lastIndexOf('.'));
cart_limit = cart_limit.split("~");

cart_limit = cart_limit[19];
if (cart_limit === "" || cart_limit === "undefined" || cart_limit === undefined)
  cart_limit = 5;

let homepagelocation = cookies.get('gph');

if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
//console.log("homepage::::"+homepagelocation);
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];

class BestSelling extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      addCardButtonDisableStatus: false,
      checkoutButtonDisableStatus: false,
      selfCheckoutButtonStatus: false,
      selectedCoreComponentForOffering: [],
      selectedOfferingIds: "",
      index: 0,
      indicators: false,
      direction: null,
      CategoryTotalSizeOriginal: null,
      catData: [],
      controls: false

    };
    GLOBAL.startindexCategory = 1;
    GLOBAL.endindexCategory = 5;
    this.saveOfferingAndComponentToCart = this.saveOfferingAndComponentToCart.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

  }
  componentWillMount() {
    this.props.resetStateInStore('selected_components');
  }

  componentDidMount() {
    let ref = this;
    if(ref.props.catSelling != ''){
    ref.setState({ catData: ref.props.catSelling.data});
    if(ref.props.catSelling && ref.props.catSelling.meta)
    ref.setState({ CategoryTotalSizeOriginal: ref.props.catSelling.meta.rowCount});
    if (ref.props.catSelling.meta.rowCount > 5)
    ref.setState({ controls: true });
    tempSizeToProcess = ref.props.catSelling.meta.rowCount;
    counter = 0;
  }
  }
  onAddFavourite(object, index) {
    let ref = this;
    let arr = [...ref.state.catData];
    arr[index].isFavourite = 1;
    axios.post(GLOBAL.postOfferingFavUrl, {
      entitledUser: "",
      offeringId: object
    })
      .then(function (response) {
        if (response.status == 200) {
          ref.setState({ catData: arr });
        }
      })
      .catch(function (error) {
      });
  }

  onRemoveFavourite(object, index) {
    let ref = this;
    let arr = [...ref.state.catData];
    arr[index].isFavourite = 0;
    axios.post(GLOBAL.postOfferingFavRemoveUrl, {
      entitledUser: "",
      offeringId: object
    })
      .then(function (response) {
        if (response.status == 200) {
          ref.setState({ catData: arr });
        }
      })
      .catch(function (error) {
      });
  }

  handleSelect(selectedIndex, e) {
    let startindex = GLOBAL.startindexCategory;
    let endindex = GLOBAL.endindexCategory;
    this.setState({
      index: selectedIndex,
      direction: e.target.classList[0] === 'carousel-control-next-icon' ? 'next' : 'previous'
    });
    if (e.target.classList[0] === 'carousel-control-next-icon') {
      ++counter;
      tempSizeToProcess = this.state.CategoryTotalSizeOriginal - (counter * 5);
      if (tempSizeToProcess <= 0) {
        GLOBAL.startindexCategory = 1;
        GLOBAL.endindexCategory = 5;
        counter = 0;
        tempSizeToProcess = this.state.CategoryTotalSizeOriginal - (counter * 5);
      } else {
        GLOBAL.startindexCategory = startindex + 5;
        GLOBAL.endindexCategory = 5;
      }
    } else {
      if (tempSizeToProcess == this.state.CategoryTotalSizeOriginal) {
        let tempCounterWhile = 0;
        let incrementedTemoCounterWhile = tempCounterWhile + 1;
        while ((5 * incrementedTemoCounterWhile) < this.state.CategoryTotalSizeOriginal) {
          ++tempCounterWhile;
          incrementedTemoCounterWhile = tempCounterWhile + 1;
        }
        counter = tempCounterWhile;
        tempSizeToProcess = this.state.CategoryTotalSizeOriginal - (counter * 5);
        GLOBAL.startindexCategory = (tempCounterWhile * 5) + 1;
        GLOBAL.endindexCategory = 5;
      } else {
        GLOBAL.startindexCategory = startindex - 5;
        GLOBAL.endindexCategory = 5;

        tempSizeToProcess = this.state.CategoryTotalSizeOriginal - ((--counter) * 5);
      }
    }
    let str = {};
    let ref = this;
    str.categoryId=this.props.cateDataId;
    // str.userId = "";
    // str.currency = "USD";
    str.start = GLOBAL.startindexCategory;
    str.end = GLOBAL.endindexCategory;
    str = JSON.stringify(str);
    const catSellingUrl="/getOfferingsListForCategory";
    api.get(catSellingUrl, { headers: { 'query': str } })
      .then(function (response) {
        if (response && response.data.data.data && Array.isArray(response.data.data.data) && response.data.data.meta.rowCount) {
          ref.setState({ catData: response.data.data.data });
        }
      })
      .catch(function (error) {
      });
  }

  onOffering(i, j, k, l) {
    //alert("ival::::"+j)
    let categoryName = k;
    if (j != "Suspended" && j != "Under Repair") {
      this.props.storeCategoryName(k);
      categoryName = categoryName.includes("?") ? categoryName.replace("?", "") : categoryName;
      // navigationHooks.navigate("/offeringDetails/" + i + "/" + categoryName + "/" + l);
      navigationHooks.navigate(
        `/offeringDetails/${encodeURIComponent(i)}/${encodeURIComponent(
          categoryName
        )}/${encodeURIComponent(l)}`
      );
    }
  }

  loadAllComponentsInCart(offeringId) {
    let ref = this;
    ref.setState({ addCardButtonDisableStatus: true });
    ref.setState({ checkoutButtonDisableStatus: true });
    ref.setState({ selfCheckoutButtonStatus: true });
    let str = {};
    str.userId = "";
    str = JSON.stringify(str);
    const offeringsInCartResponse = api.get(GLOBAL.cartOfferingsUrl, { headers: { 'query': str } });
    offeringsInCartResponse.then((allOfferingsInCart) => {
      if (allOfferingsInCart.data.length >= cart_limit) {
        swal({
          text: this.props.tr["Maximum number of offerings are already added in cart."],
          button: this.props.tr['OK'],
        });

        // alert(this.props.tr["Maximum number of offerings are already added in cart."]);
        ref.setState({
          checkoutButtonDisableStatus: false,
          selfCheckoutButtonStatus: false,
          addCardButtonDisableStatus: false,
          selectedOfferingIds: ""
        });
      }
      else {
        if (allOfferingsInCart.data.length !== 0 && _.some(allOfferingsInCart.data, { "offeringId": offeringId.toString() })) {
          swal({
            text: this.props.tr["Offering already added in cart."],
            button: this.props.tr['OK'],
          });

          // alert(this.props.tr["Offering already added in cart."]);
          ref.setState({
            checkoutButtonDisableStatus: false,
            selfCheckoutButtonStatus: false,
            addCardButtonDisableStatus: false,
            selectedOfferingIds: ""
          });

        } else {
          this.loadComponentPackage(offeringId, allOfferingsInCart);

        }
      }

    });
  }


  loadComponentPackage(offeringId, allOfferingsInCart) {
    //each time an offering is click in categoryList an new api call with offeringId as parameter wiil be hit to get new response corresponding to offering hit.
    let str = {};
    str.userId = "";
    str.offeringId = offeringId;
    str = JSON.stringify(str);
    api.get(GLOBAL.getComponentDetails, { headers: { 'query': str } })
      .then((response) => {
        if (!response) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => {
        if (response.status == 200) {
          let { selectedCoreComponentForOffering } = this.state;
          selectedCoreComponentForOffering = [];
          response.data.map((componentType) => {
            if (componentType.componentType == "Core") {
              if (!(_.some(selectedCoreComponentForOffering, ['id', componentType.id]))) {
                selectedCoreComponentForOffering.push(componentType);

              }
            }
          });
          this.setState({ selectedCoreComponentForOffering });
          this.saveOfferingAndComponentToCart(offeringId, selectedCoreComponentForOffering, allOfferingsInCart);


        }

      });

  }


  saveOfferingAndComponentToCart(offeringId, offeringComponentsForCart, allOfferingsInCart) {
    let ref = this;
    ref.setState({ addCardButtonDisableStatus: true });
    ref.setState({ checkoutButtonDisableStatus: true });
    ref.setState({ selfCheckoutButtonStatus: true });
    api.post(GLOBAL.addOfferingToCartUrl, {
      "offeringId": offeringId,
      "userId": ""
    })
      .then((response) => {
        if (response.status == 200) {
          if (offeringComponentsForCart.length !== 0) {
            for (let i = 0; i < offeringComponentsForCart.length; i++) {
              api.post(GLOBAL.addComponentsToCart, {
                "offeringId": offeringId,
                "componentId": offeringComponentsForCart[i].id
              }).then((response) => {
                if (response.status === 200) {
                  ref.setState({ addCardButtonDisableStatus: true });


                } else {
                  ref.setState({
                    addCardButtonDisableStatus: false,
                    checkoutButtonDisableStatus: false,
                    selfCheckoutButtonStatus: false,
                    selectedOfferingIds: ""
                  });
                  swal({
                    text: this.props.tr["Attached components could not be added, please try again."],
                    button: this.props.tr['OK'],
                  });

                  // alert(this.props.tr["Attached components could not be added, please try again."])
                }
              });

            }
          }

          this.props.loadCartCount();
          ref.setState({ addCardButtonDisableStatus: true });
          //navigationHooks.navigate("/cart");
          Swal.fire({
            title: this.props.tr["Item is added to cart"],
            showCancelButton: true,
            confirmButtonText: this.props.tr["Cart"],
            cancelButtonText: this.props.tr["Proceed to Home"]
          })
          .then((result) => {
            if (result.isDismissed && result.dismiss === "cancel") {
              navigationHooks.navigate(homepagelocation);
              ref.setState({
                addCardButtonDisableStatus: false,
                selectedOfferingIds: ""
              });
            } else if(result.isConfirmed) {
              navigationHooks.navigate("/cart");
            }
          });
        } else {
          ref.setState({
            addCardButtonDisableStatus: false,
            checkoutButtonDisableStatus: false,
            selfCheckoutButtonStatus: false,
            selectedOfferingIds: ""
          });
          swal({
            text: this.props.tr["There is a problem adding your recent offering to the cart try again."],
            button: this.props.tr['OK'],
          });

          // alert(this.props.tr["There is a problem adding your recent offering to the cart try again."]);
        }
        ref.setState({
          checkoutButtonDisableStatus: false,
          selfCheckoutButtonStatus: false,
          addCardButtonDisableStatus: false,
          selectedOfferingIds: ""
        });
      });


  }
  renderactuallist() {
    return this.state.catData
      .map((bestsellingoffObj, index) => {
        let favouriteexist = bestsellingoffObj.isFavourite;
        let displayText="";
        let displayStr="";
        displayStr = bestsellingoffObj.offeringSummary != null && bestsellingoffObj.offeringSummary != "" ? bestsellingoffObj.offeringSummary : "";
        displayText = displayStr != "" ? displayStr.replace(/(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/ig, '').trim() : "";
        displayText = displayText != "" ? displayText.replace(/\s+/g, ' ') : "";
        displayText = displayText != "" ? displayText.replace(/&/g, "" ) : "";
        displayText = displayText != "" ? displayText.replace(/;/g, "" ) : "";

        let displayName="";
        let displayNameStr="";
        displayNameStr = bestsellingoffObj.offeringName != null && bestsellingoffObj.offeringName != "" ? bestsellingoffObj.offeringName : "";
        displayName = displayNameStr != "" ? displayNameStr.replace(/(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/ig, '').trim() : "";
        displayName = displayName != "" ? displayName.replace(/\s+/g, ' ') : "";

        let getInnerWidth = (innerWidth)=>{
          if (innerWidth > 1535) return 55;
          if (innerWidth > 1367) return 46;
          if (innerWidth > 1279) return 40;
          if (innerWidth > 1023) return 28;
          if (innerWidth > 767) return 43;
          if (innerWidth > 640) return 33;
          if (innerWidth > 539) return 45;
          if (innerWidth > 411) return 75;
          if (innerWidth > 279) return 45;
          return 30;
      }

        // let namedSchema = undefined;
        // namedSchema = Joi.string().regex(/^[^}{]+$/);
        // let validator_to_add_myQbj = undefined;
        // let validate_summary = undefined;

        // if (bestsellingoffObj.description != null || bestsellingoffObj.description != "") {
        //   validator_to_add_myQbj = namedSchema.validate(bestsellingoffObj.description);
        //   if (validator_to_add_myQbj.error == null) {
        //     validate_summary = bestsellingoffObj.description;
        //   } else {
        //     validate_summary = "";
        //   }
        // } else validate_summary = "";
        // let displayStr = bestsellingoffObj.description;
        // let displayText = displayStr.replace(/(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/ig, '').trim();
        // displayText = displayText.replace(/\s+/g, ' ');
      let windowWidth = this.props.windowSize.width;
      let addtoCartBtn = windowWidth <= 767 ? ((this.props.tr["Add to Cart"].slice(0, 12) + (this.props?.tr["Add to Cart"].length > 12 ? "..." : ''))) : (windowWidth <= 1505 ? ((this.props.tr["Add to Cart"].slice(0, 15) + (this.props?.tr["Add to Cart"].length > 15 ? "..." : ''))) : ((this.props.tr["Add to Cart"].slice(0, 20) + (this.props?.tr["Add to Cart"].length > 20 ? "..." : ''))));

        return (
          <div key={index} className="prodSlideBox">
            <div className="gap">
              <div className="head">
                <div className="headImg">
                  <a
                    title={bestsellingoffObj.offeringName}
                    role="button"
                    onClick={this.onOffering.bind(
                      this,
                      bestsellingoffObj.offeringId,
                      bestsellingoffObj.subStatus,
                      bestsellingoffObj.category,
                      bestsellingoffObj.subCategory
                    )}
                    href="javascript:void(0)" 
                  >
                    <img
                      alt=''
                      src={
                        bestsellingoffObj.offeringIconTempURL
                          ? bestsellingoffObj.offeringIconTempURL
                          : dummyImage
                      }
                    />
                  </a>
                </div>
                <div className="headTxt">
                  <a
                    title={bestsellingoffObj.offeringName}
                    role="button"
                    id={"gridCateSer_"+index}
                    onClick={this.onOffering.bind(
                      this,
                      bestsellingoffObj.offeringId,
                      bestsellingoffObj.subStatus,
                      bestsellingoffObj.category,
                      bestsellingoffObj.subCategory
                    )}
                    href="javascript:void(0)" 
                  >
                    {displayName.slice(0, getInnerWidth(this.props.windowSize.width))}{displayName.length > getInnerWidth(this.props.windowSize.width) ? "..." : ''}
                  </a>
                </div>
              </div>
              <div className="txtDec">{displayText.slice(0, 70)}{displayText.length > 70 ? "..." : ''}</div>
              <div className="proPricBill">
                {bestsellingoffObj.hidePrice == "true" ? null : (
                  <span className="price">
                    {bestsellingoffObj.currencyCode}&nbsp;
                    {bestsellingoffObj.actualPrice}
                  </span>
                )}{" "}
                {bestsellingoffObj.hidePrice == "true" ? null : (
                  <span className="billMeth">
                    {bestsellingoffObj.billingMethod}
                  </span>
                )}
              </div>
              <div className="headIcon">
                <a id={"gridCateSershare_"+index} title="share" role="button" href="javascript:void(0)" >
                  <i className="fa fa-share-alt"/>
                </a>
                {favouriteexist == "0" ?
                  <a
                    href="javascript:void(0)" 
                    role="button"
                    id={"gridCateSerheart_"+index}
                    onClick={this.onAddFavourite.bind(
                      this,
                      bestsellingoffObj.offeringId, index
                    )}
                    title={this.props.tr["Add to Favorites"]}
                    ><LiaHeartSolid/></a>
                  :
                  <a
                    href="javascript:void(0)" 
                    role="button"
                    id={"gridCateSerheart_"+index}
                    className="active"
                    onClick={this.onRemoveFavourite.bind(
                      this,
                      bestsellingoffObj.offeringId, index
                    )}
                    title={this.props.tr["Remove from Favorites"]}
                    ><LiaHeartSolid/></a>
                }
              </div>
              <div className="offStstus">
                {bestsellingoffObj.subStatus == "Under Repair" ||
                  bestsellingoffObj.subStatus == "Suspended" ? (
                  <a>
                    <i
                      title={this.props.tr["Not Available"]}
                      className="fa fa-ban"
                    />
                  </a>
                ) : (
                  <i/>
                )}
              </div>
              <div className="proStar">
                <ReactStars
                  value={bestsellingoffObj.rating}
                  edit={false}
                  count={5}
                  size={18}
                  color1={"gray"}
                  color2={this.props.appliedThemeData?.secondaryColor}
                />
              </div>
            </div>

            <div className="footBtn">
            {bestsellingoffObj.workItemType === "incidentRequest" ||
          bestsellingoffObj.workItemType === "standardChange" ||
          bestsellingoffObj.status === "Proposed" ||
          bestsellingoffObj.deliveryMode == "Other" ? <span className='disSpan' title='Disabled'>{addtoCartBtn}</span> : (                 
              <a
                title={this.props.tr["Add to Cart"]}
                className={
                  this.state.selectedOfferingIds == bestsellingoffObj.offeringId
                    ? this.state.addCardButtonDisableStatus
                      ? "disSpan"
                      : "cartBtn"
                    : "cartBtn"
                }
                role="button"
                href="javascript:void(0)" 
                id={"gridCateSercart_"+index}
                onClick={() => {
                  let selectedOfferingIds = bestsellingoffObj.offeringId;
                  this.setState({ selectedOfferingIds });
                  this.loadAllComponentsInCart(bestsellingoffObj.offeringId);
                }}
              >
                {addtoCartBtn}
              </a> )}
              <a
                className="orderBtn"
                role="button"
                title={this.props.tr["Order Now"]}
                href="javascript:void(0)" 
                id={"gridCateSerorder_"+index}
                onClick={this.onOffering.bind(
                  this,
                  bestsellingoffObj.offeringId,
                  bestsellingoffObj.subStatus,
                  bestsellingoffObj.category,
                  bestsellingoffObj.subCategory
                )}
              >
                {this.props.tr["Order Now"].slice(0, 15)}{this.props.tr["Order Now"].length > 15 ? "..." : ''}
              </a>
            </div>
          </div>
        );
      });
  }

  renderList(bestSellingData) {
    let indents = [];
    let size = '';
    if (bestSellingData && bestSellingData.data) {
      size = bestSellingData.data.length;
    }
    //alert(size)
    // let caraousel_item_count = Math.ceil(size / 5);
    // if (caraousel_item_count > 1) {
    //   controlsFlag = true;
    // } else {
    //   controlsFlag = false
    // }
    // let indents = [];
    // let startpoint = "";
    // let endpoint = "";
    // for (let i = 1; i <= caraousel_item_count; i++) {
    //   if (i == 1) {
    //     startpoint = 0;
    //     endpoint = 5;
    //   } else {
    //     startpoint = endpoint;
    //     endpoint = endpoint + 5;
    //   }
    indents.push(<Carousel.Item>{this.renderactuallist()}</Carousel.Item>);
    // }

    return (
      <Carousel id="bestSellingUlCate" activeIndex={this.state.index} controls={this.state.controls}
      indicators={this.state.indicators} direction={this.state.direction}
      onSelect={(i, e) => e && this.handleSelect(i, e)}>
      {indents}
    </Carousel>
    );
  }

  render() {
    return (
      <Row className="current" id="bestCate">
        <Col md={4} className="hidden-xs hidden-sm padding-0" id="pro-info">
          {/* <div className="outer">
            <div id="notContent_19" className="pro-details block">
              <div className="name">Lenovo Desktop with 1 Year Onsite Warranty</div>
              <div className="brand"><img src={lenovoImage}
                alt="Lenovo" className="float-l padding-r-20" /><strong>$25.00</strong>Inclusive
                                                                                      of all
                                                                                      taxes
              </div>
              <ul className="ulblock clearfix">
                <li><a href="javascript:void(0)" ><i className="fa fa-heart"></i>Add to
                                                               Favourites</a></li>
                <li><a href="javascript:void(0)" ><i className="fa fa-cart-plus"></i>Add to Cart</a></li>
              </ul>
              <div className="see seedetails border-radius"><a href="service-detail.html">See Details</a></div>
              <p>OS X El Capitan Lite. 896MB RAM 30GB Disk Space 50GB Bandwidth</p>
            </div>
          </div> */}
        </Col>
        <Col md={12} className="shortinfo" id="shortinfoCate">
          {typeof (this.state.catData) === 'string' ? <div className="text-center">{this.state.catData}</div> : this.renderList(this.state.catData)}
        </Col>
      </Row>
    );
  }
}
BestSelling.propTypes = {
	tr: PropTypes.object,
  // cateDataId: PropTypes.number,
	resetStateInStore: PropTypes.func,
	storeCategoryName: PropTypes.func,
  loadCartCount:PropTypes.func
  // catSelling:PropTypes.object

};
function mapStateToProps({ catSelling, spcmReducer,windowSize, appliedThemeData}) {
  return { catSelling, tr: spcmReducer.tr,windowSize, appliedThemeData: appliedThemeData.data};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadCatSelling, storeCategoryName, resetStateInStore, loadCartCount }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BestSelling);
