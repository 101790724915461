
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from "react";
import { formatQuery } from "react-querybuilder";
import { ListGroup, Row, Col, Tabs, Tab, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { PiPencilSimpleBold } from "react-icons/pi";
import { IoClose } from "react-icons/io5";


const CiGroupDescriptionList = ({ rowData, setIsEditDetails }) => {
  const translator = useSelector((state) => state.spcmReducer.tr);
  const closeButton = useRef(null);
  const editButton = useRef(null);

  // useEffect(() => {
  //   context.keyDownHandler(closeButton.current,editButton.current,"","");
  // }, []);
  console.log(rowData);

  return (
    <div className="stickyArea rBoxStyle">
      <Row>
        <Col xs={12}>
          <div className="rPageHeadActBtn">
            <ul>
              <li>
                <Link
                  to={`/add-edit/ci-group/${rowData?.GROUP_ID}`}
                  title={translator["GDS"]}
                  ref={editButton} 
                >
                  <PiPencilSimpleBold />
                </Link>
              </li>
              <li>
                <Link to="javascript:void(0)"
                  title={translator["Minimize the details panel"]}
                  bsClass=""
                  onClick={() => setIsEditDetails(false)}
                  ref={closeButton}
                >
                  <IoClose />
                </Link>
              </li>
            </ul>
          </div>
          <div className="rPageHeading py-3">{rowData?.GROUP_NAME}</div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <div className="rBoxTabs hideTabNav">
          <Tabs id="tabId">
            <Tab eventKey={1} title="CI Group Details">
              <ListGroup bsPrefix=" " bsClass="">
                <ListGroup.Item
                  bsPrefix=" "
                  bsClass=""
                  className="rwTable"
                  style={{ "border-top": "none" }}
                >
                  <span className="rwKey">Name</span>
                  <span className="rwVal text-capitalize">
                    {rowData?.GROUP_NAME || "--"}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">{translator["Class"]} </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.CLASS_NAME || "--"}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">{translator["Company"]} </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.COMPANY_NAME || "--"}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                    {translator["Description"] || "Description"}
                  </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.DESCRIPTION || "--"}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                    {translator["Status"] || "Status"}
                  </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.STATUS}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                    {translator["Created By"] || "Created By"}
                  </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.CREATED_BY}
                  </span>
                </ListGroup.Item>
                
              </ListGroup>
            </Tab>
          </Tabs>
         
        </div>
      </div>
    </div>
  );
};

export default CiGroupDescriptionList;
