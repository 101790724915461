
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import {Row,Col, Form, ButtonToolbar, Button } from 'react-bootstrap';
import { connect,useDispatch, useSelector } from "react-redux";
import { change, Field, reduxForm } from 'redux-form';
import { _inputField,_dropDown,TypeaheadandDropdown,_radio } from '../../../../common/ReduxFormFields/CategoryBoard/formFields';
import {getComapanyBasedDepartment, resetEditUserDetails} from "../../../../../actions/foundation/userAction";
// import CWLTypeahead from './common/cwlAsyncTypeahead.js';
import ManagerTypeahead from './common/managerAsyncTypeahead.js';
import ListLoader from "../../../../common/loaders/ListLoader";
import CostCenterAsyncTypeahead from "./common/costCenterAsyncTypeahead.js";
import CWLAsyncTypeahead from "./common/cwlAsyncTypeahead.js";
import LocationAsyncTypeahead from "./common/locationAsyncTypeahead.js";
import HeadAsyncTypeahead from "./common/headAsynTypeaheadEdit.js";


let OrganizationalInfoForm = (props) => {

    const { isMandatory = true } = props;

    const dispatch = useDispatch();
    const [selectedLocation,setSelectedLocation] = useState([]);
    const [selectedCostCenter,setSelectedCostCenter] = useState([]);
    const [selectedCWL,setSelectedCWL] = useState([]);
    const [selectedManager,setSelectedManager] = useState([]);
    const [OrgzData,setOrgzData] = useState([]);
    const [DepData,setDepData] = useState([]);
    const [ccData,setCCData] = useState([]);
    const [cwlData,setCWLData] = useState([]);
    const [locationData,setLocationData] = useState([]);
    const [managerData,setManagerData] = useState([]);
    const [vipVal, setVIPval] = useState();
    const [autoApproveVal, setAutoApproveVal] = useState();
    const EDITUSERData = useSelector((state) => state.editDetailsRed);
    const mapCWLData = useSelector((state) => state.mapCWLRed);

    useEffect(() => {
        return () => {
            dispatch(resetEditUserDetails());
        }
    }, []);

    useEffect(() => {
        if(props.selectedCompany.length == 0){
            setSelectedLocation([]);
            setSelectedCostCenter([]);
            setSelectedCWL([]);
            setSelectedManager([]);
        }     
    },[props.selectedCompany])
    useEffect(() => {
        if(EDITUSERData && EDITUSERData.cwlId !=null && EDITUSERData.cwlName !=null){
            setSelectedCWL([{id:EDITUSERData.cwlId, label:EDITUSERData.cwlName}]);            
            }else{
                // setSelectedCWL([{id:EDITUSERData.cwlId, label:''}]);            
                setSelectedCWL([]);            
            }
        if(EDITUSERData && EDITUSERData.costcenterId !=null && EDITUSERData.costcenterName!=null){
            setSelectedCostCenter([{id:EDITUSERData.costcenterId, label:EDITUSERData.costcenterName}]);            
            }else{
                // setSelectedCostCenter([{id:EDITUSERData.cwlId, label:''}]);            
                setSelectedCostCenter([]);            
            }
        if(EDITUSERData && EDITUSERData.locationId!=null && EDITUSERData.locationName!=null){
            setSelectedLocation([{id:EDITUSERData.locationId, label:EDITUSERData.locationName}]);            
            }else{
                // setSelectedLocation([{id:EDITUSERData.cwlId, label:''}]);            
                setSelectedLocation([]);            
            }
        if(EDITUSERData && EDITUSERData.managerId !=null && EDITUSERData.managerName!=null){
            setSelectedManager([{id:EDITUSERData.managerId, label:EDITUSERData.managerName}]);            
            }else{
                // setSelectedManager([{id:EDITUSERData.cwlId, label:''}]);            
                setSelectedManager([]);            
            }
        if(EDITUSERData && EDITUSERData.vip){
            setVIPval(EDITUSERData.vip)
            }
        if(EDITUSERData && EDITUSERData.autoApprove){
            setAutoApproveVal(EDITUSERData.autoApprove)
            }
        dispatch(change("EditUserform","organizationId", EDITUSERData.organizationId));
        dispatch(change("EditUserform","departmentId", EDITUSERData.departmentId));
         
        dispatch(change("EditUserform", "locationName", EDITUSERData.locationName));
        dispatch(change("EditUserform", "locationId", EDITUSERData.locationId));
        dispatch(change("EditUserform", "costcenterName", EDITUSERData.costcenterName));
        dispatch(change("EditUserform", "costcenterId", EDITUSERData.costcenterId));
        dispatch(change("EditUserform", "cwlName", EDITUSERData.cwlName));
        dispatch(change("EditUserform", "cwlId", EDITUSERData.cwlId));
        dispatch(change("EditUserform", "managerName", EDITUSERData.managerName));
        dispatch(change("EditUserform", "managerId", EDITUSERData.managerId));

      }, [EDITUSERData,mapCWLData])

    useEffect(() => {
        if(props.userOrgListRedData && props.userOrgListRedData.length >0){
            setOrgzData(props.userOrgListRedData);
        }
    },[props.userOrgListRedData])
    useEffect(() => {
        if(props.userDepListRedData && props.userDepListRedData.length >0){
            setDepData(props.userDepListRedData);
        }
        else{
            setDepData([]);
        }
    },[props.userDepListRedData])
    useEffect(() => {
        if(props.userCostCenterRedData && props.userCostCenterRedData.length >0){
            setCCData(props.userCostCenterRedData);
        }
    },[props.userCostCenterRedData])
    useEffect(() => {
        if(props.cwlDataRedData && props.cwlDataRedData.length >0){
            setCWLData(props.cwlDataRedData);
        }
    },[props.cwlDataRedData])
    useEffect(() => {
        if(props.userLocationRedData && props.userLocationRedData.length >0){
            setLocationData(props.userLocationRedData);
        }
    },[props.userLocationRedData])
    useEffect(() => {
        if(props.userManagerRedData && props.userManagerRedData.length >0){
            setManagerData(props.userManagerRedData);
        }
    },[props.userManagerRedData])


    const onValueSelection = (selected) => {
    let error = '';
    if (selected.length === 0 && isMandatory == true) {
        error = 'error';
      props.setLocationErrColor(error);

    }
        if(selected.length>0){
        dispatch(change('EditUserform','locationName', selected[0].label));
        dispatch(change('EditUserform', 'locationId', selected[0].id));
        props.setLocationErrColor('');
      
        }else{
        dispatch(change("EditUserform","locationName", ''));
        dispatch(change("EditUserform", "locationId", ''));
        }
        setSelectedLocation(selected);
    };

    const onCrossClick = () => {
        setSelectedLocation([]);
        dispatch(change("EditUserform","locationName", ''));
        dispatch(change("EditUserform", "locationId", ''));
        if(isMandatory == true){
            props.setLocationErrColor('error');
        }
    };

    const onCostCenterSelection = (selected) => {
        let error = '';
    if (selected.length === 0 && isMandatory == true) {
        error = 'error';
      props.setCCErrColor(error);
    }
    if(selected.length>0){
        dispatch(change("EditUserform","costcenterName", selected[0].label));
        dispatch(change("EditUserform", "costcenterId", selected[0].id));
        props.setCCErrColor('');
      
        }else{
        dispatch(change("EditUserform","costcenterName", ''));
        dispatch(change("EditUserform", "costcenterId", ''));
        }
        setSelectedCostCenter(selected);
    };

    const onCostCenterCrossClick = () => {
        setSelectedCostCenter([]);
        dispatch(change("EditUserform","costcenterName", ''));
        dispatch(change("EditUserform", "costcenterId", ''));
        if(isMandatory == true){
            props.setCCErrColor('error');
        }
    };

    const onCWLSelection = (selected) => {
        let error = '';
    if (selected.length === 0 && isMandatory == true) {
        error = 'error';
      props.setCWLColorErr(error);
    }
    if(selected.length>0){
        dispatch(change("EditUserform","cwlName", selected[0].label));
        dispatch(change("EditUserform", "cwlId", selected[0].id));
        props.setCWLColorErr('');
      
        }else{
        dispatch(change("EditUserform","cwlName", ''));
        dispatch(change("EditUserform", "cwlId", ''));
        }
        setSelectedCWL(selected);
    };

    const onCWLCrossClick = () => {
        setSelectedCWL([]);
        dispatch(change("EditUserform","cwlName", ''));
        dispatch(change("EditUserform", "cwlId", ''));
        if(isMandatory == true){
            props.setCWLColorErr('error');
        }
    };

    const onManagerSelection = (selected) => {
    if(selected.length>0){
        dispatch(change("EditUserform","managerName", selected[0].label));
        dispatch(change("EditUserform", "managerId", selected[0].id));
      
        }else{
        dispatch(change("EditUserform","managerName", ''));
        dispatch(change("EditUserform", "managerId", ''));
        }
        setSelectedManager(selected);
    };

    const onManagerCrossClick = () => {
        setSelectedManager([]);
    };

    const  validateGroupsField = (fieldType, event)=> {
        switch (fieldType) {
          case "organizationId":
            if (event.target.value == "") {
              props.setOrgzErr("error");
            } else {
              props.setOrgzErr("");
            }
            break;
          case "departmentId":
            if (event.target.value == "") {
              props.setDepErr("error");
            } else {
              props.setDepErr("");
            }
            break;
            default:
            }
        }
const autoApproveFun = (e) => {
    if(e.target.value == 'Yes'){
        setAutoApproveVal('yes')
    }else{
    setAutoApproveVal('no')
    }
}
const vipFun = (e) => {
    if(e.target.value == 'Yes'){
        setVIPval('Yes')
    }else{
    setVIPval('No')
    }
}
const onOrganizationChange = (event) => {
    dispatch(change("userForm", "organizationId", event.target[event.target.selectedIndex].text))
        dispatch(getComapanyBasedDepartment(event.target.value));        

    }

    if(EDITUSERData.length == 0){
        return <ListLoader />;
    }

    return (
        <Row className="tabFormDv">
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>Organization</Form.Label>            
                <Field component={_dropDown} options={OrgzData} name="organizationId" className={"form-control "} 
            //     + ((isMandatory == true) ? props.orgzErr : '')
            //   onBlur={(event) => {
            //     if(isMandatory == true){
            //         validateGroupsField("organizationId", event);
            //     }
            //   }}
              onChange={(event) => (onOrganizationChange(event))}>
				</Field>
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>Department</Form.Label>
                <Field component={_dropDown} options={DepData} name="departmentId" className={"form-control "}> 
                    {/* + ((isMandatory == true) ? props.depErr : '')
              onBlur={(event) => {
                if(isMandatory == true){
                    validateGroupsField("departmentId", event);
                }
              }}  */}
				</Field>
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>Cost Center</Form.Label>
                <CostCenterAsyncTypeahead
                    id="costCenterTypeahead"
                    // errorColor={(isMandatory == true) ? props.ccErr : ''}
                    // setErrorColor={props.setCCErrColor}
                    // translator={translator}
                    costcenterName={EDITUSERData.costcenterName}
                />
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
                        <Form.Group className="form-group">
                            <Form.Label>CWL</Form.Label>
                            <CWLAsyncTypeahead
                                id="cwlTypeahead"
                                // errorColor={(isMandatory == true) ? props.cwlErr : ''}
                                // setErrorColor={props.setCWLColorErr}
                                // translator={translator}
                                cwlName={EDITUSERData?.cwlName}
                            />
                        </Form.Group>
                        </Col>
                        <Col md={4} sm={6} xs={12}>
                        <Form.Group className="form-group">
                        <div className="dvTypehdropdown">
                            <Form.Label>Location</Form.Label>
                            <LocationAsyncTypeahead
                                id="locationTypeahead"
                                // errorColor={(isMandatory == true) ? props.locationErr : ''}
                                // setErrorColor={props.setLocationErrColor}
                                // translator={translator}
                                locationName={EDITUSERData?.locationName}
                            />
                            </div>
                        </Form.Group>
                        </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>L1</Form.Label>
                <Field
                        name="l1"
                        className={"form-control " }
                        component={_inputField}
                        maxLength={200}
                    />
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>L2</Form.Label>
                <Field
                        name="l2"
                        className={"form-control " }
                        component={_inputField}
                        maxLength={200}
                    />
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>L3</Form.Label>
                <Field
                        name="l3"
                        className={"form-control " }
                        component={_inputField}
                        maxLength={200}
                    />
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>L4</Form.Label>
                <Field
                        name="l4"
                        className={"form-control " }
                        component={_inputField}
                        maxLength={200}
                    />
            </Form.Group>
            </Col>
            {/* <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>Prod ID</Form.Label>
                <Field
                        name="prodId"
                        className={"form-control " }
                        component={_inputField}
                        maxLength={200}
                    />
            </Form.Group>
            </Col> */}
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
            <div className="dvTypehdropdown">
                <Form.Label>Manager</Form.Label> 
                <ManagerTypeahead
                    id="managerTypeahead"
                    managerName={EDITUSERData.managerName}
                />
                </div>
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
            <div className="dvTypehdropdown">
                <Form.Label>L1 Head</Form.Label>
                <HeadAsyncTypeahead
                name="l1Head"
                value="l1HeadId"
                defaultInputValue={EDITUSERData.l1Head}
                />
                </div>
            </Form.Group>
            </Col>

             <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
            <div className="dvTypehdropdown">
                <Form.Label>L2 Head</Form.Label>
                <HeadAsyncTypeahead
                name="l2Head"
                value="l2HeadId"
                defaultInputValue={EDITUSERData.l2Head}
                />
                </div>
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
            <div className="dvTypehdropdown">
                <Form.Label>L3 Head</Form.Label>
                <HeadAsyncTypeahead
                name="l3Head"
                value="l3HeadId"
                defaultInputValue={EDITUSERData.l3Head}
                />
                </div>
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
            <div className="dvTypehdropdown">
                <Form.Label>L4 Head</Form.Label>
                <HeadAsyncTypeahead
                name="l4Head"
                value="l4HeadId"
                defaultInputValue={EDITUSERData.l4Head}
                />
                </div>
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>VIP</Form.Label>
                <Row>
                    <div className="radioInlineBox float-l">
                    <Field component={_radio} className='form-control' label="Yes" type="radio" name='vip' value='Yes' checked={vipVal == 'Yes'?true:false} onChange = {(e)=>{vipFun(e)}}  />
					<Field component={_radio} className='form-control' label="No" type="radio" name='vip' value='No' checked={vipVal == 'No'?true:false } onChange = {(e)=>{vipFun(e)}} />
                    </div>
                </Row>
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>Auto Approve</Form.Label>
                <Row>
					<div className="radioInlineBox float-l">
                    <Field component={_radio} className='form-control' label="Yes" type="radio" name='autoApprove' value='Yes' checked={autoApproveVal == 'yes'?true:false} onChange = {(e)=>{autoApproveFun(e)}} />
					<Field component={_radio} className='form-control' label="No" type="radio" name='autoApprove' value='No' checked={autoApproveVal == 'no'?true:false } onChange = {(e)=>{autoApproveFun(e)}} />
                    </div>
                </Row>
            </Form.Group>
            </Col>
        </Row>
    )
}

OrganizationalInfoForm = reduxForm({
    form: "EditUserform",
    enableReinitialize: true,
  })(OrganizationalInfoForm);
  OrganizationalInfoForm = connect(({ editDetailsRed }) => (
    {
    // initialValues: {
    //   autoApprove: editDetailsRed?.autoApprove,
    //   vip: editDetailsRed?.vip,
    //   building: editDetailsRed?.building,
    //   cubicle: editDetailsRed?.cubicle,
    //   managerId: editDetailsRed?.managerId,
    //   costcenterId: editDetailsRed?.costcenterId,
    //   locationId: editDetailsRed?.locationId,
    //   departmentId: editDetailsRed?.departmentId,
    //   cwlId: editDetailsRed?.cwlId,
    //   organizationId: editDetailsRed?.organizationId,
    // },
  }))(OrganizationalInfoForm);
  
export default OrganizationalInfoForm;