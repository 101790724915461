
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Nav from 'react-bootstrap/Nav';
import ListGroup from 'react-bootstrap/ListGroup';
import OnClickOutside from 'react-onclickoutside';
import { GLOBAL } from "_Globals";
import { loadBreakFixEditDetails } from '../../../../actions/breakFix/breakFixEditDetailsAction';
import { connect } from 'react-redux';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { checkRelatedTaskForItem } from '../../../../actions/task/TaskDataAction';
import { loadStatusBasedHamburgerOptions } from '../../../../actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '../../../../actions/common/commonApisActions.js';
import swal from 'sweetalert'
import { breakFixStatusMapping } from '../../../common/helper.js';
class BreakFixHamburger extends React.Component {

	constructor(props) {
		super(props);
		this.changeStatus = this.changeStatus.bind(this);
		this.currentAction = this.currentAction.bind(this);
	}

	componentWillMount() {
		this.props.checkRelatedTaskForItem(this.props.breakFixEditDetails.breakfixId, "Breakfix");
	}

	handleClickOutside() {
		this.props.fun(false);
	}


	changeStatus(status, breakfixId) {
		if ((this.props.user_id != this.props.breakFixEditDetails.spUserid) && (this.props.roleIdentification('12') || this.props.roleIdentification('13')))
			swal({
				text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
				button: this.props.translator['OK'],
			});
		else {
			let self = this;
			axios.patch(GLOBAL.breakFixPatchUrl + GLOBAL.breakFixId, {
				"status": parseInt(status, 10),
				"modifiedBy": "",
				"modifiedByName": ""
			})
				.then(function (response) {
					// self.props.loadBreakFixStatusDetails(status,status,GLOBAL.breakFixId);
					// browserHistory.push("/editBreakFix/breakFixItemId:" + GLOBAL.breakFixId);
					self.props.loadBreakFixEditDetails(breakfixId);
					self.props.loadStatusBasedHamburgerOptions('Breakfix', response.data.status, response.data.breakfixId);
				})
				.catch(function (error) {
					console.log(error);
				});
		}
	}

	currentAction(enableButton) {
		console.log("currentAction() has been called !!!!!")
		console.log("enableButton : ", enableButton)
		// alert(enableButton +' called');
		switch (enableButton) {
			case 'Propose Change':
				this.props.showProposeChange();
				this.props.rightEditPanel(true);
				break;
			case 'Propose Problem':
				this.props.showProposeInvestigation();
				this.props.rightEditPanel(true);
				break;
			case 'Provide Attachments':
				this.props.showProvideAttachment();
				this.props.rightEditPanel(true);
				break;
			case 'Notify':
				this.props.showNotify();
				this.props.rightEditPanel(true);
				break;
			case 'Propose as Critical':
				// if ((this.props.user_id != this.props.breakFixEditDetails.spUserid) && (this.props.roleIdentification('12') || this.props.roleIdentification('13')))
				// 	swal({
				// 		text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
				// 		button: this.props.translator['OK'],
				// 	});
				// else {
					this.props.showProposeCritical();
					this.props.rightEditPanel(true);
				//}
				break;
			case 'Reject as Critical':
				// if ((this.props.user_id != this.props.breakFixEditDetails.spUserid) && (this.props.roleIdentification('12') || this.props.roleIdentification('13')))
				// 	swal({
				// 		text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
				// 		button: this.props.translator['OK'],
				// 	});
				// else {
					this.props.rejectCritical();
					this.props.rightEditPanel(true);
				//}
				break;
			case 'Accept as Critical':
				// if ((this.props.user_id != this.props.breakFixEditDetails.spUserid) && (this.props.roleIdentification('12') || this.props.roleIdentification('13')))
				// 	swal({
				// 		text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
				// 		button: this.props.translator['OK'],
				// 	});
				// else {
					console.log("Condition has satisfied")
					console.log("Calling before acceptCritical()")
					this.props.acceptCritical();
					console.log("Calling after acceptCritical()")
					this.props.rightEditPanel(true);
				//}
				break;
			case 'Start Fixing':
				if ((this.props.user_id != this.props.breakFixEditDetails.spUserid) && (this.props.roleIdentification('12') || this.props.roleIdentification('13') || this.props.roleIdentification('48')))
					swal({
						text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
						button: this.props.translator['OK'],
					});
				else {
					this.changeStatus('15', this.props.breakFixEditDetails.breakfixId);
					this.props.rightEditPanel(true);
					this.props.resetRightSide();
				}
				break;
			case 'Cancel Issue':
				if ((this.props.user_id != this.props.breakFixEditDetails.spUserid) && (this.props.roleIdentification('12') || this.props.roleIdentification('13') || this.props.roleIdentification('48')))
					swal({
						text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
						button: this.props.translator['OK'],
					});
				else {
					this.props.showCancelIssue();
					this.props.rightEditPanel(true);
				}
				break;
			case 'Put on Hold':
				if ((this.props.user_id != this.props.breakFixEditDetails.spUserid) && (this.props.roleIdentification('12') || this.props.roleIdentification('13') || this.props.roleIdentification('48')))
					swal({
						text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
						button: this.props.translator['OK'],
					});
				else {
					this.props.showOnHold();
					this.props.rightEditPanel(true);
				}
				break;
			case 'Provide Fix':
				if ((this.props.user_id != this.props.breakFixEditDetails.spUserid) && (this.props.roleIdentification('12') || this.props.roleIdentification('13') || this.props.roleIdentification('48')))
					swal({
						text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
						button: this.props.translator['OK'],
					});
				else {
					if (this.props.realatedTaskCount == 0)
						{this.props.showProvideFix('');
						this.props.rightEditPanel(true);}
					else
					swal({
						text:  this.props.translator['Tasks related to the item are not completed yet.'],
						button: this.props.translator['OK'],
					})
				}
				break;
			case 'Close':
				if ((this.props.user_id != this.props.breakFixEditDetails.spUserid) && (this.props.roleIdentification('12') || this.props.roleIdentification('13') || this.props.roleIdentification('48')))
					swal({
						text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
						button: this.props.translator['OK'],
					});
				else {
					if (this.props.realatedTaskCount == 0)
						{this.changeStatus('30', this.props.breakFixEditDetails.breakfixId);
						this.props.rightEditPanel(true);}
					else
					swal({
						text:  this.props.translator['Tasks related to the item are not completed yet.'],
						button: this.props.translator['OK'],
					})
				}
				break;
			case 'Reopen':
				// if ((this.props.user_id != this.props.breakFixEditDetails.spUserid) && (this.props.roleIdentification('12') || this.props.roleIdentification('13') || this.props.roleIdentification('48')))
				// 	swal({
				// 		text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
				// 		button: this.props.translator['OK'],
				// 	});
				// else
					this.props.showReasonToReopen()
					this.props.rightEditPanel(true);
				break;
			case 'Critical Incident Review':
					this.props.showCIRform();
					this.props.rightEditPanel(true);
				break;
			case 'Incident Review':
				    this.props.showCIRform();
					this.props.rightEditPanel(true);
				break;
		}
	}

	renderHamburgerSOptions(statusBasedHamburgerOptions) {
		// console.log('---------statusBasedHamburgerOptions---------');
		// console.log(statusBasedHamburgerOptions);
		if (statusBasedHamburgerOptions.length == 0) {
			return (
				null
			);
		}

		return statusBasedHamburgerOptions.map((item, index) => {
			// console.log('hamburger item',item);
			let roleArr = this.props.role_id.split(',');
            let roleCIR = roleArr.includes('14');
			if (item.action !== 'L') {
				if (item.action == 'S') {
					if (item.enableButton === 'Incident Review' && roleCIR && this.props.criticality == 'Accepted' && this.props.statusId == breakFixStatusMapping['Fixed']) {
						return (
							<ListGroup.Item bsClass="" key={index}><Nav.Item title={'Critical Incident Review'} onClick={() => { this.currentAction('Critical Incident Review'); this.handleClickOutside() }}><i className="fa fa-angle-double-right" ></i>{'Critical Incident Review'}</Nav.Item></ListGroup.Item>
						);
					}
					else if (this.props.breakFixEditDetails.type !== null && item.enableButton === 'Incident Review') {
						return '';
					}
					else if (item.enableButton === 'Incident Review' && !roleCIR && this.props.criticality == 'Accepted') {
						return '';
					} else {
						const requiredCheck = (item?.targetStatus == "Fixed" && item?.enableButton == "Incident Review" && this.props.breakFixEditDetails.criticality == 'Yes') ? 'Critical Incident Review' : item.enableButton;
						return (
							<ListGroup.Item bsClass="" key={index}><Nav.Item title={requiredCheck} onClick={() => { this.currentAction(requiredCheck); this.handleClickOutside() }}><i className="fa fa-angle-double-right" ></i>{requiredCheck}</Nav.Item></ListGroup.Item>
						);
					}
				}
			}
		});
	}

	renderHamburgerGOptions(statusBasedHamburgerOptions) {
		// console.log('---------statusBasedHamburgerOptions---------');
		// console.log(statusBasedHamburgerOptions);
		if (statusBasedHamburgerOptions.length == 0) {
			return (
				null
			);
		}

		return statusBasedHamburgerOptions.map((item, index) => {
			// console.log('hamburger item',item);
			if (item.action !== 'L') {
				if (item.action == 'G') {
					if (this.props.fieldStatus.proposeAsCritical == false && item.enableButton == "Propose as Critical" || this.props.fieldStatus.provideAttachments == false && item.enableButton == "Provide Attachments" || this.props.fieldStatus.notify == false && item.enableButton == "Notify" || this.props.fieldStatus.acceptRejectAsCritical == false && item.enableButton == "Accept as Critical" || this.props.fieldStatus.acceptRejectAsCritical == false && item.enableButton == "Reject as Critical") {
						// alert("123",item.enableButton);
						item.enableButton = "blank";
					}
					return (
						<ListGroup.Item bsClass="" key={index}><Nav.Item title={item.enableButton} onClick={() => { this.currentAction(item.enableButton); this.handleClickOutside() }}>{item.enableButton == "blank" ? null : <i className="fa fa-angle-double-right" ></i>}{item.enableButton == "blank" ? null : item.enableButton}</Nav.Item></ListGroup.Item>
					);
				}
			}
		});
	}

	render() {
		// console.log('=============',this.props.statusBasedHamburgerOptions);
		return (
			<div className="breakFixHamburger statusHamburger">
				<ListGroup bsClass="" className="myLinks">
					{this.renderHamburgerSOptions(this.props.statusBasedHamburgerOptions)}
					{/* <ListGroup.Item bsClass=""><span className="HamburgerGap">&nbsp;</span></ListGroup.Item>
					{this.renderHamburgerGOptions(this.props.statusBasedHamburgerOptions)} */}
				</ListGroup>
			</div>
		);
	}
};

BreakFixHamburger = OnClickOutside(BreakFixHamburger);

export function mapStateToProps({ statusBasedHamburgerOptions, realatedTaskCount }) {
	return {
		statusBasedHamburgerOptions,
		realatedTaskCount
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadBreakFixEditDetails, checkRelatedTaskForItem, loadStatusBasedHamburgerOptions, loadTimelineData }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BreakFixHamburger);

// export default BreakFixHamburger;
