
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form, Nav, NavDropdown, FormControl, Button, Table, ButtonToolbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { change, Field, reduxForm, formValueSelector } from 'redux-form';
import { useDispatch, useSelector, connect } from 'react-redux';
import Cookies from 'universal-cookie';

import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {LuSearch, LuBarChart} from 'react-icons/lu';

// const cookies = new Cookies();
// let companyIdLoggedIn = cookies.get('gph');
// if (companyIdLoggedIn) companyIdLoggedIn = companyIdLoggedIn.replace('s:', '');
// if (companyIdLoggedIn) companyIdLoggedIn = companyIdLoggedIn.substring(0, companyIdLoggedIn.lastIndexOf('.'));
// companyIdLoggedIn = companyIdLoggedIn.split("~");
// companyIdLoggedIn = companyIdLoggedIn[48];

const Header = (props) => {
    const dispatch = useDispatch();
    const translator = useSelector((state) => state.spcmReducer.tr);
    // const [companySelectedList, setCompanySelectedList] = useState([]);

    // useEffect(() => {
    //     if (!companyIdParam && companySelectedList.length > 0) {
    //         dispatch(change('adminPreferencesListForm', 'company', companySelectedList[0].id));
    //     }
    // }, [companyIdParam])

    return (
        <Row className="margin-b-15">
            <Col md={6} sm={6} xs={5}>
                <h1 bsClass="" className="sPageHeading1">{translator['Scan Asset']}</h1>
            </Col>
            <Col md={6} sm={6} xs={7}>
                <div className="status myStatus">
                    <Nav bsPrefix=" " as={"ul"} bsClass="">
                        <Nav.Item bsPrefix=" " as={"li"}>
                            <Link
                                to="/cmdblist"
                                bsPrefix=" "
                                title={translator["Close"]}
                                eventKey={2}
                                className="ctrlKeyPrevent"
                            >
                                <IoClose />
                            </Link>
                        </Nav.Item>
                    </Nav>
                </div>
            </Col>
        </Row>
    );
}

export default reduxForm({
    form: "adminPreferencesListForm",
    destroyOnUnmount: true,
    enableReinitialize: true
})(Header);