import React from "react";
import * as faIcons from "react-icons/fa";
import * as aiIcons from "react-icons/ai";
import * as bsIcons from "react-icons/bs";
import * as cgIcons from "react-icons/cg";
import * as diIcons from "react-icons/di";
import * as fcIcons from "react-icons/fc";
import * as fiIcons from "react-icons/fi";
import * as giIcons from "react-icons/gi";
import * as goIcons from "react-icons/go";
import * as hiIcons from "react-icons/hi";
import * as imIcons from "react-icons/im";
import * as ioIcons from "react-icons/io";
import * as mdIcons from "react-icons/md";
import * as siIcons from "react-icons/si";
import * as riIcons from "react-icons/ri";
import * as tiIcons from "react-icons/ti";
import * as vscIcons from "react-icons/vsc";
import * as wiIcons from "react-icons/wi";
import * as ciIcons from "react-icons/ci";

const IconsComponent = ({ item, ...props }) => {
  const renderReactIcon = () => {
    switch (item?.reactIconType?.id) {
      case "faIcons":
        const Icon = faIcons[item?.iconName];
        if (Icon) return <Icon {...item?.attributes} />;
        break;
      case "aiIcons":
        const AisIcon = aiIcons[item?.iconName];
        if (AisIcon) return <AisIcon {...item?.attributes} />;
        break;
      case "bsIcons":
        const BsIcons = bsIcons[item?.iconName];
        if (BsIcons) return <BsIcons {...item?.attributes} />;
        break;
      case "cgIcons":
        const CgIcons = cgIcons[item?.iconName];
        if (CgIcons) return <CgIcons {...item?.attributes} />;
        break;
      case "diIcons":
        const DiIcons = diIcons[item?.iconName];
        if (DiIcons) return <DiIcons {...item?.attributes} />;
        break;
      case "fcIcons":
        const FcIcons = fcIcons[item?.iconName];
        if (FcIcons) return <FcIcons {...item?.attributes} />;
        break;
      case "fiIcons":
        const FiIcons = fiIcons[item?.iconName];
        if (FiIcons) return <FiIcons {...item?.attributes} />;
        break;
      case "giIcons":
        const GiIcons = giIcons[item?.iconName];
        if (GiIcons) return <GiIcons {...item?.attributes} />;
        break;
      case "goIcons":
        const GoIcons = goIcons[item?.iconName];
        if (GoIcons) return <GoIcons {...item?.attributes} />;
        break;
      case "hiIcons":
        const HiIcons = hiIcons[item?.iconName];
        if (HiIcons) return <HiIcons {...item?.attributes} />;
        break;
      case "imIcons":
        const ImIcons = imIcons[item?.iconName];
        if (ImIcons) return <ImIcons {...item?.attributes} />;
        break;
      case "ioIcons":
        const IoIcons = ioIcons[item?.iconName];
        if (IoIcons) return <IoIcons {...item?.attributes} />;
        break;
      case "mdIcons":
        const MdIcons = mdIcons[item?.iconName];
        if (MdIcons) return <MdIcons {...item?.attributes} />;
        break;
      case "siIcons":
        const SiIcons = siIcons[item?.iconName];
        if (SiIcons) return <SiIcons {...item?.attributes} />;
        break;
      case "riIcons":
        const RiIcons = riIcons[item?.iconName];
        if (RiIcons) return <RiIcons {...item?.attributes} />;
        break;
      case "tiIcons":
        const TiIcons = tiIcons[item?.iconName];
        if (TiIcons) return <TiIcons {...item?.attributes} />;
        break;
      case "vscIcons":
        const VscIcons = vscIcons[item?.iconName];
        if (VscIcons) return <VscIcons {...item?.attributes} />;
        break;
      case "wiIcons":
        const WiIcons = wiIcons[item?.iconName];
        if (WiIcons) return <WiIcons {...item?.attributes} />;
        break;
      case "ciIcons":
        const CiIcons = ciIcons[item?.iconName];
        if (CiIcons) return <CiIcons {...item?.attributes} />;
        break;
      default:
        return <></>;
    }
  };

  const renderExternalIcons = () => {
    if(typeof props?.renderComponent === 'function') {
      return props?.renderComponent(item?.components);
    }
  };
  if(props?.type && props?.type === 'external') return renderExternalIcons();
  return renderReactIcon();
};

export default IconsComponent;
