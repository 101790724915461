
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Accordion, Tab, Tabs, ListGroup, Button, ButtonToolbar, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {PiPencilSimpleBold} from "react-icons/pi";
import {IoClose} from "react-icons/io5";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';
import { refreshDataDictionary, ruleDbDetails } from "../../../actions/spcmActions";

const DataRefreshRightSide = (props) => {
    const navigate = useNavigate();
    const tr = useSelector((state) => state.spcmReducer.tr);
    const { loading, data: auditLogData } = useSelector(
        (state) => state.rulesDbDetailsReducer
      );
    const context = useContext(MyContext);
    const closeButton = useRef(null);
    const editButton = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        context.keyDownHandler(closeButton.current,editButton.current,"","");
        context.newTabPrevent();
    }, []);

    useEffect(() => {
        dispatch(ruleDbDetails());
      }, []);


    const renderAttList = (attributeData) => {
      console.log("attributeData",attributeData);
      if (!attributeData) {
        return (
          null
        );
      }
      return attributeData.map((taskObj, index) => {
        return (
          console.log("object",taskObj),
          <tr>
            <td>{taskObj.moduleName}</td>
            <td>{taskObj.status}</td>
            <td>{taskObj.startedOn}</td>
            <td>{taskObj.completedOn}</td>
            <td>{taskObj.updatedBy}</td>
          </tr>
        );
      });
    }

    const handleStart = () => {
        dispatch(refreshDataDictionary());
        dispatch(ruleDbDetails());
    }
    const handleRefresh = () => {
        dispatch(ruleDbDetails());
    }

    return (
      console.log("auditLogData",auditLogData),
        <>
            <Row>
                <Col xs={12}>
                    <div className="rPageHeadActBtn" style={{ "marginTop": "-3px" }}>
                        <ListGroup bsPrefix=" " as="ul">
                            <ListGroup.Item as="li" bsPrefix=" "><Link to="javascript:void(0)" bsPrefix=" " title={tr["Minimize the details panel"]} className="ctrlKeyPrevent" onClick={()=>{props.setRightSidePanel("")}} ref={closeButton} ><IoClose/></Link></ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">Update Data Dictionary</div></div>
                </Col>
            </Row>
            <div className="rBoxGap">
            {<Table responsive striped bordered condensed hover className='f-size-13'>
					<thead>
						<tr className='f-size-12'>
							<th>{tr['Module']}</th>
							<th>{tr['Status']}</th>
							<th>{tr['Started On']}</th>
							<th>{tr['Completed On']}</th>
							<th>{tr['Updated By']}</th>
						</tr>
					</thead>
					<tbody>
						{renderAttList(auditLogData)}
					</tbody>
				</Table>}
       
          <ButtonToolbar className="black margin-t-15">
            <Button className='rgSidrkBtn' title={tr['Start']} bsSize="small" bsStyle="primary" onClick={() => handleStart()}>
              {tr['Start']}
            </Button>
            <Button className='rgSiLigBtn' title={tr['Refresh']} onClick={() => handleRefresh()} bsSize="small" bsStyle="text">{tr['Refresh']}</Button>
          </ButtonToolbar>
        </div>
        </>
    )
}

export default DataRefreshRightSide;
