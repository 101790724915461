
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form, Nav, NavDropdown, FormControl, Button, Table, ButtonToolbar, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, change, SubmissionError, submit } from 'redux-form';
import { useDispatch, useSelector, connect } from 'react-redux';
import { _textArea, _dropDown, _inputField, _checkbox, _number, _dynamicNamedropDown } from "../../common/formFields.js";
import { sanitizeElements } from "../../common/helper";
import { Typeahead } from 'react-bootstrap-typeahead';
import swal from 'sweetalert';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import Modal from "react-bootstrap/Modal";
import { BsUpcScan } from "react-icons/bs";
import { HiPlus } from 'react-icons/hi';
import { IoClose } from "react-icons/io5";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { CiLock, CiUnlock } from "react-icons/ci";
import { MdEdit, MdDelete } from 'react-icons/md';

import CmdbDropdownPlusTypeahead from "../common/CmdbDropdownPlusTypeahead.js";
import BusinessOwner from './formfield/businessOwnerAsyncTypeahead.js';
import TechnicalOwner from './formfield/technicalOwnerAsyncTypeahead.js';
import {
    loadCIStatusCurrent,
    loadCIMetallic,
    loadCIEnv,
    loadCIManr,
    loadCICompany,
    emptyCIGroup,
    loadCICat,
    loadCIType,
    loadCISCat,
    emptyCISupCompany,
    emptyCICatReducer,
    emptyCISCatReducer,
    loadCISupCompany,
} from "../../../actions/cmdb/lovAction";

import {
    loadCmdbItemList,
    loadCMDBFilterResultsForSearchString,
    resetCMDBQVFilters,
    loadCmdbItemFilterList,
    loadStatusAndSubStatusOptions
} from "../../../actions/cmdb/quickViewAction.js";

import {
    loadClassByCompanyId,
    resetCiClass,
} from "../../../actions/ham/hamAction";
import axios from 'axios';
import { GLOBAL } from '../../../components/Globals';

const api = axios.create({
    headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

const hasSelectedField = (selectedArr, field) => {
    return selectedArr.some((item) => item.key === field);
}
const stripHtmlTags = (str) => { return str.replace(/<\/?[^>]+(>|$)/g, ''); }

const extractVariables = (str, obj) => {
    // Decode HTML entities
    const decodeHtmlEntities = (str) => {
        const txt = document.createElement('textarea');
        txt.innerHTML = sanitizeElements(str);
        return txt.value;
    };
    const decodedStr = decodeHtmlEntities(str);
    // Extract and replace the variables
    const result = decodedStr.replace(/<%=(.*?)%>/g, (match, p1) => obj[p1.trim()] || '');
    return result;
};

const cmdbAttributes = [
    // {
    //     label: 'Company',
    //     key: 'company',
    //     value: '',
    //     dataType: 'typeaheadDropdown',
    //     id: 1
    // },
    // {
    //     label: 'Class',
    //     key: 'class',
    //     value: '',
    //     dataType: 'dropdown',
    //     id: 2
    // },
    // {
    //     label: 'Type',
    //     key: 'type',
    //     value: '',
    //     dataType: 'dropdown',
    //     id: 3
    // },
    {
        label: 'Category',
        key: 'category',
        value: '',
        dataType: 'dropdown',
        id: 4
    },
    {
        label: 'Sub Category',
        key: 'subCategory',
        value: '',
        dataType: 'dropdown',
        id: 5
    },
    {
        label: 'Status',
        key: 'status',
        value: '',
        dataType: 'dropdown',
        id: 6
    },
    {
        label: 'Sub Status',
        key: 'subStatus',
        value: '',
        dataType: 'dropdown',
        id: 7
    },
    {
        label: 'Business Owner',
        key: 'businessowner',
        value: '',
        dataType: 'typeahead',
        id: 8
    },
    {
        label: 'Technical Owner',
        key: 'techowner',
        value: '',
        dataType: 'typeahead',
        id: 9
    },
    {
        label: 'Serial Number',
        key: 'serial_number',
        value: '',
        dataType: 'textbox',
        id: 10
    },
    {
        label: 'Model Name',
        key: 'model_name',
        value: '',
        dataType: 'textbox',
        id: 11
    },
    {
        label: 'Model Number',
        key: 'model_number',
        value: '',
        dataType: 'textbox',
        id: 12
    },
    {
        label: 'Asset Tag',
        key: 'asset_tag',
        value: '',
        dataType: 'textbox',
        id: 13
    },
    // {
    //     label: 'CI Name',
    //     key: 'ciName',
    //     value: '',
    //     dataType: 'textbox',
    //     id: 14
    // }
];

const customPatternOptions = [
    { name: '<%=serial_number%>', key: '<%=serial_number%>' },
    { name: '<%=asset_tag%>', key: '<%=asset_tag%>' },
    { name: '<%=category_name%>', key: '<%=category_name%>' },
    { name: '<%=sub_category_name%>', key: '<%=sub_category_name%>' }
]

const List = (props) => {
    const dispatch = useDispatch();
    const translator = useSelector((state) => state.spcmReducer.tr);

    const selector = formValueSelector('ScanAssetForm');
    const companyIdParam = useSelector(state => selector(state, 'company'));
    const classIdParam = useSelector(state => selector(state, 'class'));
    const ciTypeParam = useSelector(state => selector(state, 'type'));

    const [enablePresets, setEnablePresets] = useState(true);
    const [selectedScanFields, setSelectedScanFields] = useState([]);
    const [selectedPresetFields, setSelectedPresetFields] = useState([]);

    const [inputValueCompany, setInputValueCompany] = useState('');
    const [ciCompanySelectedList, setCiCompanySelectedList] = useState([]);
    const [ciCompanyErrorColor, setCiCompanyErrorColor] = useState('');
    const [ciClassErrorColor, setCiClassErrorColor] = useState('');
    const [ciTypeErrorColor, setCiTypeErrorColor] = useState('');
    const [ciCategoryErrorColor, setCiCategoryErrorColor] = useState('');
    const [ciSubCategoryErrorColor, setCiSubCategoryErrorColor] = useState('');
    const [ciStatusErrorColor, setCiStatusErrorColor] = useState('');
    const [ciSubStatusErrorColor, setCiSubStatusErrorColor] = useState('');
    const [ciBusinessOwnerError, setCiBusinessOwnerError] = useState('');
    const [ciTechnicalOwnerError, setCiTechnicalOwnerError] = useState('');
    const [ciNameErrorColor, setCiNameErrorColor] = useState('');

    const [presetOptions, setPresetOptions] = useState(structuredClone(cmdbAttributes));
    const [barcodeSetOptions, setBarcodeSetOptions] = useState(structuredClone(cmdbAttributes).filter(item => item.dataType === 'textbox'));

    const [presetValues, setPresetValues] = useState({});
    const [scanValues, setScanValues] = useState([]);
    const [listDataToSubmit, setListDataToSubmit] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showBarcodeScanComponent, setShowBarcodeScanComponent] = useState(false);
    const [cameraScanData, setCameraScanData] = useState('');
    const [cameraCurrentScanFieldIndex, setCameraCurrentScanFieldIndex] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isEditingRecordIndex, setIsEditingRecordIndex] = useState(null);
    const [assetCount, setAssetCount] = useState(0);
    const [templateFieldsDisable, setTemplateFieldsDisable] = useState(false);
    const [activeKey, setActiveKey] = useState('0');

    let { handleSubmit, pristine, reset, submitting } = props;

    useEffect(() => {
        dispatch(loadCICompany("0"));
        dispatch(resetCiClass());
        dispatch(loadCIStatusCurrent('create', 0));
        dispatch(loadCIType());
        dispatch(loadStatusAndSubStatusOptions('Status List'));
        dispatch(loadStatusAndSubStatusOptions('ALL_UNIQUE_SUBSTATUS'));
    }, []);

    useEffect(() => {
        if (templateFieldsDisable) {
            document
                .getElementById("bodyPart")
                .setAttribute("contentEditable", "false");
        } else {
            document
                .getElementById("bodyPart")
                .setAttribute("contentEditable", "true");
        }
    }, [templateFieldsDisable]);

    useEffect(() => {
        if (document.getElementById("bodyPart")) {
            document
                .getElementById("bodyPart")
                .addEventListener("mousedown", handleEvListener);
            document
                .getElementById("bodyPart")
                .setAttribute("contentEditable", "true");
        }
    }, []);

    useEffect(() => {
        const assetTagField = selectedPresetFields.some(selected => selected.key === 'asset_tag');
        if (assetTagField) {
            if (document.getElementById("bodyPart2")) {
                document
                    .getElementById("bodyPart2")
                    .addEventListener("mousedown", handleEvListener2);
                document
                    .getElementById("bodyPart2")
                    .setAttribute("contentEditable", "true");
            }
        }
    }, [selectedPresetFields]);

    const handleEvListener = (event) => {
        let insertRenderVariables = customPatternOptions;
        let elementNew, myObj, dropdownId, buttonObj, finalResult, parentDiv;
        dropdownId = "div1";
        elementNew = event.target;
        buttonObj = event.target.classList.contains("varModify");

        let str = elementNew.innerHTML;
        let res = str.replace(
            "$",
            "<span tabindex='0' role='link' class='varModify' style='font-weight: 600;'><%=Select%></span>"
        );
        // elementNew.innerHTML = sanitizeElements(res);
        elementNew.innerHTML = res;

        elementNew.querySelectorAll(".varModify").forEach((myChild) => {
            let newChild = myChild.querySelector('select');
            // if(newChild)
            newChild?.remove();
            document.getElementById(dropdownId)?.remove();
        });
        if (buttonObj) {
            let arr = document.getElementsByClassName("selectbox");
            [...arr].forEach((item) => {
                item.remove();
            });

            myObj = document.createElement("select");
            myObj.id = dropdownId;
            myObj.className = "selectbox";
            let option = document.createElement("option");
            option.setAttribute("value", "Select");
            option.text = "Select";
            myObj.appendChild(option);
            for (const myData of insertRenderVariables) {
                let dyaOption = document.createElement("option");
                dyaOption.setAttribute("value", myData.key);
                dyaOption.text = myData.key;
                myObj.appendChild(dyaOption);
            }
            elementNew.appendChild(myObj);
        }

        document.getElementById(dropdownId)?.addEventListener("change", (e) => {
            finalResult = e.target.value;
            // elementNew.innerHTML = "<%=" + finalResult + "%>";
            elementNew.innerHTML = sanitizeElements(finalResult);
            e.target.remove();
            let parentDiv = document.getElementById("editableTextArea").innerHTML;
            dispatch(change("ScanAssetForm", "ciName", stripHtmlTags(parentDiv)));
        });

        window.addEventListener("click", (e) => {
            if (e && e.target) {
                if (document.getElementById("bodyPart").contains(e.target)) {
                } else {
                    document.getElementById(dropdownId)?.remove();
                }
            }
        });
    }

    const setBodyData = () => {
        // document.getElementById("bodyPart").removeAttribute("contentEditable");
        let parentDiv = document.getElementById("editableTextArea").innerHTML;
        dispatch(change("ScanAssetForm", "ciName", parentDiv));
        validateField('ciName', stripHtmlTags(parentDiv).trim());
    }

    const handleEvListener2 = (event) => {
        let insertRenderVariables = customPatternOptions;
        let elementNew, myObj, dropdownId, buttonObj, finalResult, parentDiv;
        dropdownId = "div2";
        elementNew = event.target;
        buttonObj = event.target.classList.contains("varModify");

        let str = elementNew.innerHTML;
        let res = str.replace(
            "$",
            "<span tabindex='0' role='link' class='varModify' style='font-weight: 600;'><%=Select%></span>"
        );
        // elementNew.innerHTML = sanitizeElements(res);
        elementNew.innerHTML = res;

        elementNew.querySelectorAll(".varModify").forEach((myChild) => {
            let newChild = myChild.querySelector('select');
            // if(newChild)
            newChild?.remove();
            document.getElementById(dropdownId)?.remove();
        });
        if (buttonObj) {
            let arr = document.getElementsByClassName("selectbox");
            [...arr].forEach((item) => {
                item.remove();
            });

            myObj = document.createElement("select");
            myObj.id = dropdownId;
            myObj.className = "selectbox";
            let option = document.createElement("option");
            option.setAttribute("value", "Select");
            option.text = "Select";
            myObj.appendChild(option);
            for (const myData of insertRenderVariables) {
                let dyaOption = document.createElement("option");
                dyaOption.setAttribute("value", myData.key);
                dyaOption.text = myData.key;
                myObj.appendChild(dyaOption);
            }
            elementNew.appendChild(myObj);
        }

        document.getElementById(dropdownId)?.addEventListener("change", (e) => {
            finalResult = e.target.value;
            // elementNew.innerHTML = "<%=" + finalResult + "%>";
            elementNew.innerHTML = sanitizeElements(finalResult);
            e.target.remove();
            let parentDiv = document.getElementById("editableTextArea2").innerHTML;
            dispatch(change("ScanAssetForm", "assetTag", stripHtmlTags(parentDiv)));
        });

        window.addEventListener("click", (e) => {
            if (e && e.target) {
                if (document.getElementById("bodyPart2").contains(e.target)) {
                } else {
                    document.getElementById(dropdownId)?.remove();
                }
            }
        });
    }

    const setBodyData2 = () => {
        // document.getElementById("bodyPart2").removeAttribute("contentEditable");
        let parentDiv = document.getElementById("editableTextArea2").innerHTML;
        dispatch(change("ScanAssetForm", "assetTag", parentDiv));
    }

    const enableDisableTemplateFields = (action, values) => {
        if (action === 'disable') {
            var divElement = document.getElementById("bodyPart");
            var spanElements = divElement.getElementsByTagName("span");
            for (var i = 0; i < spanElements.length; i++) {
                var span = spanElements[i];
                if (span.classList.contains("varModify")) {
                    span.classList.replace("varModify", "varModify1");
                }
            }
            if (values.assetTag) {
                var divElement2 = document.getElementById("bodyPart2");
                var spanElements2 = divElement2.getElementsByTagName("span");
                for (var i = 0; i < spanElements2.length; i++) {
                    var span2 = spanElements2[i];
                    if (span2.classList.contains("varModify")) {
                        span2.classList.replace("varModify", "varModify1");
                    }
                }
            }
        } else {
            var divElement = document.getElementById("bodyPart");
            var spanElements = divElement.getElementsByTagName("span");
            for (var i = 0; i < spanElements.length; i++) {
                var span = spanElements[i];
                if (span.classList.contains("varModify1")) {
                    span.classList.replace("varModify1", "varModify");
                }
            }
            if (values.assetTag) {
                var divElement2 = document.getElementById("bodyPart2");
                var spanElements2 = divElement2.getElementsByTagName("span");
                for (var i = 0; i < spanElements2.length; i++) {
                    var span2 = spanElements2[i];
                    if (span2.classList.contains("varModify1")) {
                        span2.classList.replace("varModify1", "varModify");
                    }
                }
            }
        }
    }

    function validateField(field, value) {
        switch (field) {
            case 'class':
                if (value == "") {
                    setCiClassErrorColor('error');
                } else {
                    setCiClassErrorColor('');
                }
                break;
            case 'type':
                if (value == "") {
                    setCiTypeErrorColor('error');
                } else {
                    setCiTypeErrorColor('');
                }
                break;
            // case 'status':
            //     if (value == 0 || value == "") {
            //         setCiStatusErrorColor('error');
            //     } else {
            //         setCiStatusErrorColor('');
            //     }
            //     break;
            // case 'subStatus':
            //     if (value == 0 || value == "") {
            //         setCiSubStatusErrorColor('error');
            //     } else {
            //         setCiSubStatusErrorColor('');
            //     }
            //     break;
            // case 'category':
            //     if (value == 0 || value == "") {
            //         setCiCategoryErrorColor('error');
            //     } else {
            //         setCiCategoryErrorColor('');
            //     }
            //     break;
            // case 'subCategory':
            //     if (value == 0 || value == "") {
            //         setCiSubCategoryErrorColor('error');
            //     } else {
            //         setCiSubCategoryErrorColor('');
            //     }
            //     break;
            case 'ciName':
                if (value == "") {
                    setCiNameErrorColor('error');
                } else {
                    setCiNameErrorColor('');
                }
                break;
            default:
        }
    }

    function setErrorColor(errorVal, field) {
        switch (field) {
            case 'businessowner':
                setCiBusinessOwnerError(errorVal);
                break;
            case 'techowner':
                setCiTechnicalOwnerError(errorVal);
                break;
        }
    }

    function onCompanySelection(selectedCompany) {
        setCiCompanySelectedList(selectedCompany);
        setCiCompanyErrorColor('');
        dispatch(emptyCICatReducer());
        dispatch(emptyCISCatReducer());
        dispatch(change('ScanAssetForm', 'class', ''));
        dispatch(change('ScanAssetForm', 'className', ''));
        if (selectedCompany.length > 0) {
            let status = 'ACTIVE';
            let hamCall = false;
            dispatch(loadClassByCompanyId(selectedCompany[0].id, hamCall, status));
            // if (props.classIdParam)
            //     dispatch(loadCICat(props.classIdParam, selectedCompany[0].id, 'CMDB'));
            dispatch(change('ScanAssetForm', 'company', selectedCompany[0].id));
            dispatch(change("ScanAssetForm", "companyName", selectedCompany[0].label));
            setInputValueCompany(selectedCompany[0].label);
        }
        else {
            dispatch(change('ScanAssetForm', 'company', ''));
            dispatch(change("ScanAssetForm", "companyName", ''));
            setInputValueCompany('');
        }

    }

    function onCompanyInputChange(val) {
        setInputValueCompany(val);
    }

    function onCrossClickCompany() {
        dispatch(change('ScanAssetForm', 'company', ''));
        dispatch(change("ScanAssetForm", "companyName", ''));
        setCiCompanySelectedList([]);
        setInputValueCompany('');
        dispatch(emptyCICatReducer());
        dispatch(emptyCISCatReducer());
        dispatch(change('ScanAssetForm', 'class', ''));
        dispatch(resetCiClass());
        setCiCompanyErrorColor('error');
    }

    function setCompanyErrorColor() {
        if (ciCompanySelectedList.length === 0)
            setCiCompanyErrorColor('error');
        else
            setCiCompanyErrorColor('');
    }

    let companyTypeaheadOptions = [];
    if (props.ciCompany && props.ciCompany.length > 0) {
        props.ciCompany.forEach((item, i) => {
            let obj = {};
            obj.id = item.field1Value;
            obj.label = item.field1Key;
            companyTypeaheadOptions.push(obj);
        });
    }

    function renderClass(classData) {
        if (!classData) {
            return (
                <option></option>
            );
        }
        return classData.map((classObj, i) => {
            return (
                <option value={classObj.CLASS_ID} key={i}>{classObj.CLASS_NAME}</option>
            );
        });
    }

    function onClassSelect(i) {
        dispatch(emptyCICatReducer());
        dispatch(emptyCISCatReducer());
        let filter = props.compClass.filter(d => d.CLASS_ID == i.target.value);
        let type = filter[0].CLASS_TYPE;
        let classId = filter[0].CLASS_ID;
        if (type.toLowerCase() == 'asset') {
            dispatch(change("ScanAssetForm", "type", "5"));
            dispatch(change("ScanAssetForm", "typeName", "Asset"));
            dispatch(change("ScanAssetForm", "status", "1"));
            dispatch(change("ScanAssetForm", "subStatus", "1"));
            dispatch(change("ScanAssetForm", "statusName", "Requested"));
            dispatch(change("ScanAssetForm", "subStatusName", "Planned"));
        } else if (type.toLowerCase() == 'ci') {
            dispatch(change("ScanAssetForm", "type", "10"));
            dispatch(change("ScanAssetForm", "typeName", "CI"));
            dispatch(change("ScanAssetForm", "status", "6"));
            dispatch(change("ScanAssetForm", "subStatus", "11"));
            dispatch(change("ScanAssetForm", "statusName", "Build"));
            dispatch(change("ScanAssetForm", "subStatusName", "In Progress"));
        } else if (type.toLowerCase() == 'both') {
            dispatch(change("ScanAssetForm", "type", 15));
            dispatch(change("ScanAssetForm", "typeName", "Both"));
            dispatch(change("ScanAssetForm", "status", "1"));
            dispatch(change("ScanAssetForm", "subStatus", "1"));
            dispatch(change("ScanAssetForm", "statusName", "Requested"));
            dispatch(change("ScanAssetForm", "subStatusName", "Planned"));
        }
        if (classId && classId != '') {
            if (companyIdParam) {
                dispatch(loadCICat(classId, companyIdParam));
            }
        }
        dispatch(change('ScanAssetForm', 'category', ''));
        dispatch(change('ScanAssetForm', 'categoryId', ''));
        dispatch(change('ScanAssetForm', 'subCategory', ''));
        dispatch(change('ScanAssetForm', 'subCategoryName', ''));
        dispatch(change('ScanAssetForm', 'className', i.target[i.target.selectedIndex].text));
    }

    function renderType(typeData) {
        if (!typeData) {
            return (
                <option></option>
            );
        }
        return typeData.map((typeObj) => {
            return (
                <option value={typeObj.FIELD1_VALUE}>{typeObj.FIELD1_KEY}</option>
            );
        });
    }

    function onTypeSelect(i) {
        if (i.target[i.target.selectedIndex].text == "Asset" || i.target[i.target.selectedIndex].text == "Both") {
            dispatch(change("ScanAssetForm", "status", "1"));
            dispatch(change("ScanAssetForm", "subStatus", "1"));
            dispatch(change("ScanAssetForm", "statusName", "Requested"));
            dispatch(change("ScanAssetForm", "subStatusName", "Planned"));
        }
        else if (i.target[i.target.selectedIndex].text == "CI") {
            dispatch(change("ScanAssetForm", "status", "6"));
            dispatch(change("ScanAssetForm", "subStatus", "11"));
            dispatch(change("ScanAssetForm", "statusName", "Build"));
            dispatch(change("ScanAssetForm", "subStatusName", "In Progress"));
        }
        dispatch(change("ScanAssetForm", "typeName", i.target[i.target.selectedIndex].text));
    }

    function renderCat(catData) {
        if (!catData) {
            return (
                <option></option>
            );
        }
        return catData.map((catObj) => {
            return (
                <option data-companyid={catObj.cat_company_id} data-categoryid={catObj.category_id} value={catObj.category_name}>{catObj.category_name}</option>
            );
        });
    }

    function onCatSelect(i) {
        dispatch(emptyCISCatReducer());
        if (i.target.value && i.target.value != '') {
            dispatch(loadCISCat(i.target.value, classIdParam, i.target[i.target.selectedIndex].dataset.companyid));
        };
        dispatch(change('ScanAssetForm', 'subCategory', ''));
        dispatch(change('ScanAssetForm', 'subCategoryName', ''));
        dispatch(change('ScanAssetForm', 'categoryId', i.target[i.target.selectedIndex].dataset.categoryid));
    }

    function renderSCat(scatData) {
        if (!scatData) {
            return (
                <option></option>
            );
        }
        return scatData.map((scatObj) => {
            return (
                <option value={scatObj.category_id}>{scatObj.sub_category_name}</option>
            );
        });
    }

    function renderStatus(statusData) {
        if (!statusData) {
            return (
                <option></option>
            );
        }
        return statusData.map((obj) => {
            return (
                <option value={obj.ID}>{obj.STATUS_NAME}</option>
            );
        });
    }

    function renderSubStatus(subStatusData) {
        if (!subStatusData) {
            return (
                <option></option>
            );
        }
        return subStatusData.map((obj) => {
            return (
                <option value={obj.ID}>{obj.SUB_STATUS_NAME}</option>
            );
        });
    }

    function savePresets(values) {
        if (enablePresets) {
            let validateCount = 0;
            if (!values['company'] || values['company'] == '') {
                setCiCompanyErrorColor('error');
                validateCount++;
            } else {
                setCiCompanyErrorColor('');
            }
            if (!values['class'] || values['class'] == 0) {
                validateField('class', 0);
                validateCount++;
            } else {
                validateField('class', values['class']);
            }
            if (!values['type'] || values['type'] == 0) {
                validateField('type', 0);
                validateCount++;
            } else {
                validateField('type', values['type']);
            }
            if (!values['ciName'] || stripHtmlTags(values['ciName']).trim() == '') {
                validateField('ciName', '');
                validateCount++;
            } else {
                validateField('ciName', values['ciName']);
            }
            // selectedPresetFields.forEach(item => {
            //     if (values.hasOwnProperty(item.key)) {
            //         if (item.key == 'ciName' || item.key == 'class' || item.key == 'type' || item.key == 'category' || item.key == 'subCategory' || item.key == 'status' || item.key == 'subStatus') {
            //             if (values[item.key] == 0) {
            //                 validateField(item.key, 0);
            //                 validateCount++;
            //             } else {
            //                 validateField(item.key, values[item.key]);
            //             }
            //         } else if (item.key == 'businessowner' || item.key == 'techowner') {
            //             if (values[item.key] == '') {
            //                 setErrorColor('error', item.key);
            //                 validateCount++;
            //             } else {
            //                 setErrorColor('', item.key);
            //             }
            //         }
            //     } else {
            //         if (item.key == 'ciName' || item.key == 'class' || item.key == 'type' || item.key == 'category' || item.key == 'subCategory' || item.key == 'status' || item.key == 'subStatus') {
            //             validateField(item.key, '');
            //             validateCount++;
            //         } else if (item.key == 'company') {
            //             setCiCompanyErrorColor('error');
            //             validateCount++;
            //         } else if (item.key == 'businessowner' || item.key == 'techowner') {
            //             setErrorColor('error', item.key);
            //             validateCount++;
            //         }
            //     }
            // });
            if (validateCount == 0) {
                setEnablePresets(false); // disable the form fields
                setPresetValues(values);
                setTemplateFieldsDisable(true);
                enableDisableTemplateFields('disable', values);
            }
        } else {
            if (listDataToSubmit.length > 0) {
                swal({
                    text: "Please save the existing records before editing preset fields",
                    button: 'OK',
                });
                return false;
            } else {
                setEnablePresets(true); // enable the form fields
                setTemplateFieldsDisable(false);
                enableDisableTemplateFields('enable', values);
            }
        }
    }

    function onPresetFieldSelect(selectedOptions) {
        setSelectedPresetFields(selectedOptions);
        const filteredOptions = structuredClone(cmdbAttributes).filter(item => item.dataType === 'textbox').filter(option => !selectedOptions.some(selected => selected.key === option.key)).filter(item => item.dataType === 'textbox');
        setBarcodeSetOptions(filteredOptions);
    }

    function onScanFieldSelect(selectedOptions) {
        if (listDataToSubmit.length > 0) {
            swal({
                text: "Please save the existing records before changing barcode fields",
                button: 'OK',
            });
            return false;
        } else {
            let arr = [...scanValues];
            if (arr.length > 0) {
                if (selectedOptions.length > 0) {
                    let newArr = [];
                    selectedOptions.forEach((item1, index) => {
                        let foundItem = arr.find(item2 => item2.key === item1.key);
                        if (foundItem) {
                            newArr.push(foundItem);
                        } else {
                            newArr.push({ ...item1, value: '' });
                        }
                        setScanValues(newArr);
                    });
                } else {
                    setScanValues([]);
                }
            } else {
                setScanValues(selectedOptions);
            }
            setSelectedScanFields(selectedOptions);
            const filteredOptions = structuredClone(cmdbAttributes).filter(option => !selectedOptions.some(selected => selected.key === option.key));
            setPresetOptions(filteredOptions);
        }
    }

    function captureScanValues(e, item) {
        let arr = [...scanValues];
        arr.forEach((item2, index) => {
            if (item2.id == item.id) {
                item2.value = e.target.value;
            }
        });
        setScanValues(arr);
    }

    function handleKeyDown(e, len, i) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            if (i < len - 1) {
                document.getElementById('scanField-' + (i + 1)).focus();
            } else if (i == len - 1) {
                isEditing ? updateBarcodeFieldsToList() : addBarcodeFieldsToList();
                document.getElementById('scanField-0').focus();
            }
        }
    }

    const addBarcodeFieldsToList = () => {
        let arr = [...listDataToSubmit];
        if (arr.length >= 50) {
            swal({
                text: "Maximum 50 records can be added at once",
                button: 'OK',
            });
            return false;
        }

        let obj = {};
        obj['company_id'] = presetValues.company;
        obj['company_name'] = presetValues.companyName;
        obj['class_id'] = presetValues.class;
        obj['class_name'] = presetValues.className;
        obj['type'] = presetValues.type;
        obj['type_name'] = presetValues.typeName;
        obj['category_id'] = presetValues.categoryId;
        obj['category_name'] = presetValues.category;
        obj['sub_category_id'] = presetValues.subCategory;
        obj['sub_category_name'] = presetValues.subCategoryName;
        obj['status'] = presetValues.status;
        obj['status_name'] = presetValues.statusName;
        obj['sub_status'] = presetValues.subStatus;
        obj['sub_status_name'] = presetValues.subStatusName;
        obj['business_owner_id'] = presetValues.businessowner;
        obj['business_owner_name'] = presetValues.businessownerName;
        obj['technical_owner_id'] = presetValues.techowner;
        obj['technical_owner_name'] = presetValues.techownerName;

        scanValues.forEach((item, index) => {
            obj[item.key] = item.value.trim();
        });

        obj['ci_name'] = extractVariables(stripHtmlTags(presetValues.ciName).trim(), obj);
        if (obj['ci_name'].length > 250) {
            swal({
                text: "CI Name should not exceed 250 characters",
                button: 'OK',
            });
            return false;
        }
        const found = arr.some(item => item.ci_name === obj['ci_name']);
        if (found) {
            swal({
                text: "CI Name already exists",
                button: 'OK',
            });
            return false;
        }

        if (presetValues.assetTag) {
            obj['asset_tag'] = extractVariables(stripHtmlTags(presetValues.assetTag).trim(), obj);
            if (obj['asset_tag'].length > 250) {
                swal({
                    text: "Asset Tag should not exceed 250 characters",
                    button: 'OK',
                });
                return false;
            }
        }

        if (!obj['category_id']) {
            obj['category_id'] = 0;
        }
        if (!obj['category_name']) {
            obj['category_name'] = '';
        }
        if (!obj['sub_category_id']) {
            obj['sub_category_id'] = 0;
        }
        if (!obj['sub_category_name']) {
            obj['sub_category_name'] = '';
        }
        if (!obj['status']) {
            if (ciTypeParam == '10') {
                obj['status'] = 6;
            } else if (ciTypeParam == '5' || ciTypeParam == '15') {
                obj['status'] = 1;
            }
        }
        if (!obj['status_name']) {
            if (ciTypeParam == '10') {
                obj['status_name'] = 'Build';
            } else if (ciTypeParam == '5' || ciTypeParam == '15') {
                obj['status_name'] = 'Requested';
            }
        }
        if (!obj['sub_status']) {
            if (ciTypeParam == '10') {
                obj['sub_status'] = 11;
            } else if (ciTypeParam == '5' || ciTypeParam == '15') {
                obj['sub_status'] = 1;
            }
        }
        if (!obj['sub_status_name']) {
            if (ciTypeParam == '10') {
                obj['sub_status_name'] = 'Build';
            } else if (ciTypeParam == '5' || ciTypeParam == '15') {
                obj['sub_status_name'] = 'Planned';
            }
        }
        if (!obj['business_owner_id']) {
            obj['business_owner_id'] = 0;
        }
        if (!obj['business_owner_name']) {
            obj['business_owner_name'] = '';
        }
        if (!obj['technical_owner_id']) {
            obj['technical_owner_id'] = 0;
        }
        if (!obj['technical_owner_name']) {
            obj['technical_owner_name'] = '';
        }

        arr.push(obj);
        setListDataToSubmit(arr);
        setAssetCount(arr.length);
        let freshArr = selectedScanFields.map((item, index) => {
            return { ...item, value: '' };
        });
        setScanValues(freshArr);
        // selectedScanFields.forEach((item, index) => {
        //     document.getElementById('scanField-' + index).value = '';
        // });
    }

    const updateBarcodeFieldsToList = () => {
        let arr = structuredClone(listDataToSubmit);

        scanValues.forEach((item, index) => {
            arr[isEditingRecordIndex][item.key] = item.value.trim();
        });
        let newCIName = extractVariables(stripHtmlTags(presetValues.ciName).trim(), arr[isEditingRecordIndex])

        if (newCIName.length > 250) {
            swal({
                text: "CI Name should not exceed 250 characters",
                button: 'OK',
            });
            return false;
        }
        let newArr = arr.slice(0, isEditingRecordIndex).concat(arr.slice(isEditingRecordIndex + 1));
        const found = newArr.some(item => item.ci_name === newCIName);
        if (found) {
            swal({
                text: "CI Name already exists",
                button: 'OK',
            });
            return false;
        }
        arr[isEditingRecordIndex]['ci_name'] = newCIName;

        if (presetValues.assetTag) {
            let newAssetTag = extractVariables(stripHtmlTags(presetValues.assetTag).trim(), arr[isEditingRecordIndex])
            if (newAssetTag.length > 250) {
                swal({
                    text: "Asset Tag should not exceed 250 characters",
                    button: 'OK',
                });
                return false;
            }
            arr[isEditingRecordIndex]['asset_tag'] = newAssetTag;
        }

        setListDataToSubmit(arr);
        setAssetCount(arr.length);

        let freshArr = selectedScanFields.map((item, index) => {
            return { ...item, value: '' };
        });
        setScanValues(freshArr);

        setIsEditing(false);
        setIsEditingRecordIndex(null);
    }

    const editListData = (index) => {
        let arr = [...listDataToSubmit];
        let updateArr = selectedScanFields.map((item, i) => {
            return { ...item, value: arr[index][item.key] };
        });
        setScanValues(updateArr);
        setIsEditing(true);
        setIsEditingRecordIndex(index);
    }

    const deleteListData = (index) => {
        swal({
            title: "Are you sure you want to discard this entry?",
            // text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let arr = [...listDataToSubmit];
                    arr.splice(index, 1);
                    setListDataToSubmit(arr);
                    setAssetCount(arr.length);
                    if (index == isEditingRecordIndex) {
                        let freshArr = selectedScanFields.map((item, index) => {
                            return { ...item, value: '' };
                        });
                        setScanValues(freshArr);
                    }
                } else {
                    return false;
                }
            });
    }

    const submitListData = () => {
        if (!(enablePresets || isSubmitting || isEditing)) {
            setIsSubmitting(true);
            api.post(GLOBAL.importMultipleCiUrl, listDataToSubmit)
                .then((resp) => {
                    // console.log("importMultipleCi success--------", resp.data);
                    setIsSubmitting(false);
                    swal({
                        text: `${stripHtmlTags(resp.data.message)}`,
                        button: 'OK',
                    });
                    setListDataToSubmit([]);
                    setAssetCount(0);
                })
                .catch((err) => {
                    // console.log("importMultipleCi error--------", err);
                    setIsSubmitting(false);
                    swal({
                        text: `Error while importing records - ${stripHtmlTags(err.response.data)}`,
                        button: 'OK',
                    });
                });
        }
    }

    return (
        <div>
            <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                    <Accordion activeKey={activeKey} onSelect={(activeKey) => { setActiveKey(activeKey) }} className='margin-t-15 brkFix brkFixOrder brkFullAccordion'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Preset Fields &nbsp; {enablePresets ? <CiUnlock /> : <CiLock />}</Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    <Col lg={4} md={4} sm={6} xs={12}>
                                        <Form.Group className="form-group">
                                            <Form.Label bsClass=""><span className="rStar"></span>{translator['Company']}</Form.Label>
                                            <Field component={CmdbDropdownPlusTypeahead}
                                                errorColor={ciCompanyErrorColor}
                                                onSelection={onCompanySelection}
                                                setErrorColor={setCompanyErrorColor}
                                                name="company"
                                                id="company"
                                                className="form-control"
                                                options={companyTypeaheadOptions}
                                                disabled={!enablePresets}
                                                selectedOptions={ciCompanySelectedList}
                                                onInputChange={onCompanyInputChange}
                                                inputValue={inputValueCompany}
                                                onCrossClick={onCrossClickCompany}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} md={4} sm={6} xs={12}>
                                        <Form.Group className='form-group'>
                                            <Form.Label><span className="rStar"></span>{translator['Class']}</Form.Label>
                                            <Field
                                                component="select"
                                                name="class"
                                                className={`form-control ${ciClassErrorColor}`}
                                                disabled={!enablePresets}
                                                onBlur={(event) => { validateField('class', event.target.value) }}
                                                onChange={(event) => { onClassSelect(event); }}
                                            >
                                                <option value="">Select</option>
                                                {renderClass(props.compClass)}
                                            </Field>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} md={4} sm={6} xs={12}>
                                        <Form.Group className='form-group'>
                                            <Form.Label><span className="rStar"></span>{translator['Type']}</Form.Label>
                                            <Field
                                                component="select"
                                                name="type"
                                                className={`form-control ${ciTypeErrorColor}`}
                                                disabled={!enablePresets}
                                                onBlur={(event) => { validateField('type', event.target.value) }}
                                                onChange={(event) => { onTypeSelect(event); }}
                                            >
                                                <option value="">Select</option>
                                                {renderType(props.ciType)}
                                            </Field>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        {/* <div className="bgGray padding-5 margin-b-5 margin-t-10">CI Name</div> */}
                                        <Form.Group className="typeaheadInput form-group notifydvTypehd">
                                            <Form.Label><span className="rStar"></span> Enter a pattern for CI Name <span style={{ color: "#828282" }} className="f-size-12">e.g. PC-NYC-$Serial</span></Form.Label>
                                            <div
                                                className={`form-control ${ciNameErrorColor} ${templateFieldsDisable ? ' disableDiv' : ''}`}
                                                style={{ height: "initial", minHeight: "30px" }}
                                                id="editableTextArea"
                                                dangerouslySetInnerHTML={{ __html: sanitizeElements("<div class='inputGap12' id='bodyPart' contentEditable='true' style='outline:none'></div>") }}
                                                onBlur={() => setBodyData()}
                                            />
                                            <div className="text-r f-size-12">
                                                Type "$" and click on it to insert new variable.
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Group className="typeaheadInput form-group notifydvTypehd">
                                            <Form.Label>Select more fields</Form.Label>
                                            <Typeahead
                                                id="presetFields"
                                                multiple
                                                disabled={!enablePresets}
                                                options={presetOptions}
                                                selected={selectedPresetFields}
                                                placeholder={translator["Please choose"] + "..."}
                                                // onBlur={setErrorColor}
                                                // onInputChange={onGroupInputChange}
                                                onChange={onPresetFieldSelect}
                                            />
                                        </Form.Group>
                                    </Col>
                                    {hasSelectedField(selectedPresetFields, 'category') ? <Col lg={4} md={4} sm={6} xs={12}>
                                        <Form.Group className='form-group'>
                                            <Form.Label>{translator['Category']}</Form.Label>
                                            <Field
                                                component="select"
                                                name="category"
                                                className={`form-control ${ciCategoryErrorColor}`}
                                                disabled={!enablePresets}
                                                // onBlur={(event) => { validateField('category', event.target.value) }}
                                                onChange={(event) => { onCatSelect(event); }}
                                            >
                                                <option value="">Select</option>
                                                {renderCat(props.ciCat)}
                                            </Field>
                                        </Form.Group>
                                    </Col> : ''}
                                    {hasSelectedField(selectedPresetFields, 'subCategory') ? <Col lg={4} md={4} sm={6} xs={12}>
                                        <Form.Group className='form-group'>
                                            <Form.Label>{translator['Sub Category']}</Form.Label>
                                            <Field
                                                component="select"
                                                name="subCategory"
                                                className={`form-control ${ciSubCategoryErrorColor}`}
                                                disabled={!enablePresets}
                                                // onBlur={(event) => { validateField('subCategory', event.target.value) }}
                                                onChange={(event) => { dispatch(change('ScanAssetForm', 'subCategoryName', event.target[event.target.selectedIndex].text)); }}
                                            >
                                                <option value="">Select</option>
                                                {renderSCat(props.ciSCat)}
                                            </Field>
                                        </Form.Group>
                                    </Col> : ''}
                                    {hasSelectedField(selectedPresetFields, 'status') ? <Col lg={4} md={4} sm={6} xs={12}>
                                        <Form.Group className='form-group'>
                                            <Form.Label>{translator['Status']}</Form.Label>
                                            <Field
                                                component="select"
                                                name="status"
                                                className={`form-control ${ciStatusErrorColor}`}
                                                disabled={!enablePresets}
                                                // onBlur={(event) => { validateField('status', event.target.value) }}
                                                onChange={(event) => { dispatch(change('ScanAssetForm', 'statusName', event.target[event.target.selectedIndex].text)); }}
                                            >
                                                <option value="">Select</option>
                                                {renderStatus(props.statusList)}
                                            </Field>
                                            {/* {ciTypeParam == '10' ?
                                                <Field
                                                    component="select"
                                                    name="status"
                                                    className={`form-control ${ciStatusErrorColor}`}
                                                    disabled={!enablePresets}
                                                    onBlur={(event) => { validateField('status', event.target.value) }}
                                                    onChange={(event) => { dispatch(change('ScanAssetForm', 'statusName', event.target[event.target.selectedIndex].text)); }}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="6">Build</option>
                                                </Field>
                                                :
                                                ciTypeParam == '5' || ciTypeParam == '15' ?
                                                    <Field
                                                        component="select"
                                                        name="status"
                                                        className={`form-control ${ciStatusErrorColor}`}
                                                        disabled={!enablePresets}
                                                        onBlur={(event) => { validateField('status', event.target.value) }}
                                                        onChange={(event) => { dispatch(change('ScanAssetForm', 'statusName', event.target[event.target.selectedIndex].text)); }}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="1">Requested</option>
                                                    </Field>
                                                    :
                                                    <Field
                                                        component="select"
                                                        name="status"
                                                        className={`form-control ${ciStatusErrorColor}`}
                                                        disabled={!enablePresets}
                                                        onBlur={(event) => { validateField('status', event.target.value) }}
                                                    >
                                                        <option value="">Select</option>
                                                    </Field>
                                            } */}
                                        </Form.Group>
                                    </Col> : ''}
                                    {hasSelectedField(selectedPresetFields, 'subStatus') ? <Col lg={4} md={4} sm={6} xs={12}>
                                        <Form.Group className='form-group'>
                                            <Form.Label>{translator['Sub Status']}</Form.Label>
                                            <Field
                                                component="select"
                                                name="subStatus"
                                                className={`form-control ${ciSubStatusErrorColor}`}
                                                disabled={!enablePresets}
                                                // onBlur={(event) => { validateField('subStatus', event.target.value) }}
                                                onChange={(event) => { dispatch(change('ScanAssetForm', 'subStatusName', event.target[event.target.selectedIndex].text)); }}
                                            >
                                                <option value="">Select</option>
                                                {renderSubStatus(props.subStatusList)}
                                            </Field>
                                            {/* {ciTypeParam == '10' ?
                                                <Field
                                                    component="select"
                                                    name="subStatus"
                                                    className={`form-control ${ciSubStatusErrorColor}`}
                                                    disabled={!enablePresets}
                                                    onBlur={(event) => { validateField('subStatus', event.target.value) }}
                                                    onChange={(event) => { dispatch(change('ScanAssetForm', 'subStatusName', event.target[event.target.selectedIndex].text)); }}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="11">Build</option>
                                                </Field>
                                                :
                                                ciTypeParam == '5' || ciTypeParam == '15' ?
                                                    <Field
                                                        component="select"
                                                        name="subStatus"
                                                        className={`form-control ${ciSubStatusErrorColor}`}
                                                        disabled={!enablePresets}
                                                        onBlur={(event) => { validateField('subStatus', event.target.value) }}
                                                        onChange={(event) => { dispatch(change('ScanAssetForm', 'subStatusName', event.target[event.target.selectedIndex].text)); }}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="1">Planned</option>
                                                    </Field>
                                                    :
                                                    <Field
                                                        component="select"
                                                        name="subStatus"
                                                        className={`form-control ${ciSubStatusErrorColor}`}
                                                        disabled={!enablePresets}
                                                        onBlur={(event) => { validateField('subStatus', event.target.value) }}
                                                    >
                                                        <option value="">Select</option>
                                                    </Field>
                                            } */}
                                        </Form.Group>
                                    </Col> : ''}
                                    {hasSelectedField(selectedPresetFields, 'businessowner') ? <Col lg={4} md={4} sm={6} xs={12}>
                                        <Form.Group className='form-group'>
                                            <Form.Label>{translator['Business Owner']}</Form.Label>
                                            <BusinessOwner
                                                id="businessowner"
                                                errorColor={ciBusinessOwnerError}
                                                setErrorColor={setErrorColor}
                                                translator={translator}
                                                disabled={!enablePresets}
                                            />
                                        </Form.Group>
                                    </Col> : ''}
                                    {hasSelectedField(selectedPresetFields, 'techowner') ? <Col lg={4} md={4} sm={6} xs={12}>
                                        <Form.Group className='form-group'>
                                            <Form.Label>{translator['Technical Owner']}</Form.Label>
                                            <TechnicalOwner
                                                id="techowner"
                                                errorColor={ciTechnicalOwnerError}
                                                setErrorColor={setErrorColor}
                                                translator={translator}
                                                disabled={!enablePresets}
                                            />
                                        </Form.Group>
                                    </Col> : ''}
                                    {hasSelectedField(selectedPresetFields, 'serial_number') ? <Col lg={4} md={4} sm={4} xs={4}>
                                        <Form.Group className="form-group">
                                            <Form.Label>{translator['Serial Number']}</Form.Label>
                                            <Field name="serial_number" component="input" type="text" disabled={!enablePresets} className="form-control" />
                                        </Form.Group>
                                    </Col> : ''}
                                    {hasSelectedField(selectedPresetFields, 'model_name') ? <Col lg={4} md={4} sm={4} xs={4}>
                                        <Form.Group className="form-group">
                                            <Form.Label>Model Name</Form.Label>
                                            <Field name="model_name" component="input" type="text" disabled={!enablePresets} className="form-control" />
                                        </Form.Group>
                                    </Col> : ''}
                                    {hasSelectedField(selectedPresetFields, 'model_number') ? <Col lg={4} md={4} sm={4} xs={4}>
                                        <Form.Group className="form-group">
                                            <Form.Label>Model Number</Form.Label>
                                            <Field name="model_number" component="input" type="text" disabled={!enablePresets} className="form-control" />
                                        </Form.Group>
                                    </Col> : ''}
                                </Row>
                                {hasSelectedField(selectedPresetFields, 'asset_tag') ? <Row>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        {/* <div className="bgGray padding-5 margin-b-5 margin-t-10">{translator['Asset Tag']}</div> */}
                                        <Form.Group className="typeaheadInput form-group notifydvTypehd">
                                            <Form.Label>Enter a pattern for Asset Tag <span style={{ color: "#828282" }} className="f-size-12">e.g. PC-NYC-$Serial</span></Form.Label>
                                            <div
                                                className={`form-control  ${templateFieldsDisable ? ' disableDiv' : ''}`}
                                                style={{ height: "initial", minHeight: "30px" }}
                                                id="editableTextArea2"
                                                dangerouslySetInnerHTML={{ __html: sanitizeElements("<div class='inputGap12' id='bodyPart2' contentEditable='true' style='outline:none'></div>") }}
                                                onBlur={() => setBodyData2()}
                                            />
                                            <div className="text-r f-size-12">
                                                Type "$" and click on it to insert new variable.
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row> : ''}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
            <Row className="margin-t-20">
                {/* <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="bgGray padding-5 margin-b-5 margin-t-10">Input Fields</div>
                </Col> */}
                <Col lg={12} md={12} sm={12} xs={12}>
                    <Form.Group className="typeaheadInput form-group notifydvTypehd">
                        <Form.Label>Select fields for input/barcode scan</Form.Label>
                        <Typeahead
                            id="scanFields"
                            multiple
                            options={barcodeSetOptions}
                            selected={selectedScanFields}
                            placeholder={translator["Please choose"] + "..."}
                            // onBlur={setErrorColor}
                            // onInputChange={onGroupInputChange}
                            onChange={onScanFieldSelect}
                        />
                    </Form.Group>
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                    <Row>
                        {selectedScanFields.map((item, i) => {
                            return <Col lg={6} md={6} sm={12} xs={12} key={item.id}>
                                <Form.Group className='form-group'>
                                    <Form.Label>{item.label}</Form.Label>
                                    <div className="infoBtnDiv">
                                        <Form.Control
                                            type="text"
                                            id={"scanField-" + i}
                                            disabled={enablePresets}
                                            onChange={(e) => captureScanValues(e, item)}
                                            onKeyDown={(e) => handleKeyDown(e, selectedScanFields.length, i)}
                                            value={scanValues[i].value}
                                        />
                                        <Button
                                            title={"Open Camera to scan"}
                                            bsPrefix=" "
                                            className="infoicn"
                                            onClick={() => {
                                                if (!enablePresets) {
                                                    setShowModal(true);
                                                    setShowBarcodeScanComponent(true);
                                                    setCameraCurrentScanFieldIndex(i)
                                                    const root = document.documentElement;
                                                    root?.style.setProperty("--modalBackDrop", 0.5);
                                                }
                                            }}
                                        >
                                            <BsUpcScan title={"Open Camera to scan"} />
                                        </Button>
                                    </div>
                                </Form.Group>
                            </Col>
                        })}
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ButtonToolbar className="float-r">
                        <Button
                            bsSize="xsmall"
                            bsStyle="primary"
                            className="rgSidrkBtn smallBtn"
                            onClick={handleSubmit(savePresets)}
                        >
                            {enablePresets ? <FaLockOpen title="Lock fields" /> : <FaLock title="Unlock fields" />}
                        </Button>
                        <Button
                            bsSize="xsmall"
                            bsStyle="primary"
                            className="rgSidrkBtn smallBtn"
                            disabled={enablePresets}
                            onClick={() => isEditing ? updateBarcodeFieldsToList() : addBarcodeFieldsToList()}
                        >
                            {isEditing ? 'Update' : 'Add to list'}
                        </Button>
                    </ButtonToolbar>
                </Col>
            </Row>
            <>
                <Modal
                    show={showModal}
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="escalation-level-form"
                >
                    <Modal.Header>
                        <Row>
                            <Col lg={9} md={9} sm={8} xs={8}>
                                <h2 className="sPageHeading1 f-size-20">Scan</h2>
                            </Col>
                            <Col lg={3} md={3} sm={4} xs={4}>
                                <div className="paHedFilter">
                                    <Button
                                        bsPrefix=" "
                                        role="button"
                                        className="myBt cancel fillBtn"
                                        title={translator["Cancel"]}
                                        onClick={() => { setShowModal(false) }}
                                    >
                                        <IoClose />
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {showModal ? <Col md={12} sm={12} xs={12}>
                                {showBarcodeScanComponent ?
                                    <>
                                        <p>{selectedScanFields[cameraCurrentScanFieldIndex].label}</p>
                                        <BarcodeScannerComponent
                                            // width={500}
                                            height={400}
                                            onUpdate={(err, result) => {
                                                if (result) {
                                                    setCameraScanData(result.text);
                                                    setShowBarcodeScanComponent(false);
                                                    scanValues[cameraCurrentScanFieldIndex].value = result.text;
                                                    setTimeout(() => {
                                                        if (cameraCurrentScanFieldIndex < selectedScanFields.length - 1) {
                                                            setCameraCurrentScanFieldIndex(cameraCurrentScanFieldIndex + 1);
                                                            setShowBarcodeScanComponent(true);
                                                        } else if (cameraCurrentScanFieldIndex == selectedScanFields.length - 1) {
                                                            setCameraCurrentScanFieldIndex(null);
                                                            setShowModal(false);
                                                            isEditing ? updateBarcodeFieldsToList() : addBarcodeFieldsToList();
                                                        }
                                                    }, 1000);
                                                }
                                                else {
                                                    setCameraScanData('');
                                                }
                                            }}
                                        />
                                        <p class="supported-formats" id="supportedFormats"><span>Supported formats:</span> (1D product) UPC-A, UPC-E, EAN-8, EAN-13, (1D industrial) Code 39, Code 128, ITF, RSS-14, (2D) QR Code, Data Matrix, Aztec, PDF 417</p>
                                    </>
                                    : <Row className="jwtContent">
                                        <Col md={12} sm={12} xs={12}>
                                            <div className="infoBtnDiv">
                                                <Form.Label>{selectedScanFields[cameraCurrentScanFieldIndex].label}</Form.Label>
                                            </div>
                                            <p id="myJwt" className="myJwt">{cameraScanData}</p>
                                        </Col>
                                    </Row>
                                }
                            </Col> : ''}
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
            {listDataToSubmit.length > 0 ? <>
                <Row className="margin-t-20">
                    <Col>
                        Assets ({assetCount})
                        <div className="table-responsive">
                            <Table striped bordered condensed hover className="tableView nowrapWhright">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Company</th>
                                        <th>Class</th>
                                        <th>Type</th>
                                        {selectedScanFields.map((field, i) => {
                                            return <th key={i}>{field.label}</th>
                                        })}
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listDataToSubmit.map((item, i) => {
                                            return (
                                                <tr className="wordBreak" key={i}>
                                                    <td>{item.ci_name}</td>
                                                    <td>{item.company_name}</td>
                                                    <td>{item.class_name}</td>
                                                    <td>{item.type_name}</td>
                                                    {selectedScanFields.map((field, i) => {
                                                        return <td key={i}>{item[field.key]}</td>
                                                    })}
                                                    <td>
                                                        <Button
                                                            bsSize="xsmall"
                                                            bsStyle="text"
                                                            bsPrefix=" "
                                                            className="btn smallBtn2"
                                                            fillMode="flat"
                                                            disabled={isEditing}
                                                            onClick={() => { if (!isEditing) editListData(i); }}
                                                        >
                                                            <MdEdit />
                                                        </Button>
                                                        <Button
                                                            bsSize="xsmall"
                                                            bsStyle="text"
                                                            bsPrefix=" "
                                                            className="btn smallBtn2"
                                                            fillMode="flat"
                                                            disabled={isEditing}
                                                            onClick={() => { if (!isEditing) deleteListData(i); }}
                                                        >
                                                            <MdDelete />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
                <Row className="margin-t-10">
                    <Col md={12} className="text-c">
                        <Button
                            bsSize="xsmall"
                            bsStyle="primary"
                            className="rgSidrkBtn smallBtn"
                            disabled={enablePresets || isSubmitting || isEditing}
                            onClick={submitListData}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </> : ''}
        </div>
    );
}

const mapStateToProps = (state) => ({
    ciCompany: state.ciCompany,
    compClass: state.compClass,
    ciType: state.ciType,
    ciCat: state.ciCat,
    ciSCat: state.ciSCat,
    statusList: state.statusList,
    subStatusList: state.subStatusList,
});

export default connect(mapStateToProps, null)(
    reduxForm({
        form: 'ScanAssetForm',
        destroyOnUnmount: true,
        enableReinitialize: true
    })(List)
);
