
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";

import PindCategories from "../../common/header/pind-categories";
import Breadcrumbs from "../../common/header/breadcrumbs";
import EscalationListHeader from "./EscalationListHeader";
import EscalationtListView from "./EscalationListView";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useDispatch, useSelector } from "react-redux";
import { loadEscalationPlans } from "../../../actions/onCallSchedule/escalationAction";

const EscalationListIndex = () => {
  const dispatch = useDispatch();
  const [isRightSideVisible, setRightSideVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedBusinessFn, setSelectedBusinessFn] = useState([]);
  const [businessFnError, setBusinessFnError] = useState("");
  const [rowIndex, setRowIndex] = useState(0);
  const [planSearchInput, setPlanSearchInput] = useState("");
  const [escalationIdSearchInput, setEscalationIdSearchInput] = useState("");
  const [createdonSearchInput, setCreatedonSearchInput] = useState("");
  const [companySearchInput, setCompanySearchInput] = useState("");
  const [createdBySearchInput, setCreatedBySearchInput] = useState("");
  const [statusSearchInput, setStatusSearchInput] = useState("");
  const [activePage, setActivePage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filters, setFilters] = useState({});
  const [modifiedOnRef, setModifiedOnRef] = useState(null);
  const escalationPlanCount = useSelector((state) => state.escalationPlanList);
  let colWidth = "";
  isRightSideVisible ? (colWidth = 8) : (colWidth = 12);

  const showRightSideFun = (val) => {
    setRightSideVisible(val);
  };

  const setSelectedItemFun = (data) => {
    setSelectedItem(data);
  };
  const setBusinessFnErrorColor = () => {
    if (selectedBusinessFn.length > 0) {
      setBusinessFnError("");
    } else {
      setBusinessFnError("error");
    }
  };

  const updateSelectedBusinessFn = (value) => {
    setSelectedBusinessFn(value);
  };
  const showActiveRow = (i) => {
    setRowIndex(i);
  };

  const setRef = (type, ref) => {
    if (type == "modifiedOn") {
      setModifiedOnRef(ref);
    }
  };

  const resetFilters = () => {
    setIsFilterApplied(false);
    setPlanSearchInput("");
    setEscalationIdSearchInput("");
    setCreatedonSearchInput("");
    setCreatedBySearchInput("");
    setCompanySearchInput("");
    setStatusSearchInput("");
    setFilters({});
    setActivePage(0);
    setItemsPerPage(10);
    if (modifiedOnRef) {
      modifiedOnRef.endDate = moment();
      modifiedOnRef.startDate = moment();
    }
    let filter = { page: 0, size: 10 };
    // if (selectedBusinessFn && selectedBusinessFn.length > 0) {
    //   filter["groupId"] = selectedBusinessFn[0].groupId;
    // }
    dispatch(loadEscalationPlans(filter));
  };

   let size = escalationPlanCount && (itemsPerPage) ? (itemsPerPage) : 0;
   let currentPage = escalationPlanCount && (escalationPlanCount?.currentPage) ? (escalationPlanCount?.currentPage) : 0;
   
   let startLimit = (currentPage == 0? 1 : (size * currentPage) + 1);
   let endLimit = size * (currentPage + 1);
   let recordCount = escalationPlanCount && escalationPlanCount.numberOfItems ? escalationPlanCount.numberOfItems : 0;
  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        {/* <div className="tabDatacount">1-1 of 1</div> */}
        <Row>
        <Col md={8} xs={8}>  
        <Breadcrumbs activePageName="Escalation" />
        </Col>
            <Col md={4} xs={4}>
              {recordCount != 0 ? (
                <div className="tabDatacount">
                  {startLimit}-
                  {endLimit > recordCount ? (endLimit = recordCount) : endLimit} of{" "}
                  {recordCount}
                </div>
              ) : null}
            </Col>
          </Row>
      </Container>

      <Container fluid>
        <EscalationListHeader
          showRightSideFun={showRightSideFun}
          //  translator={tr}
          setBusinessFnErrorColor={setBusinessFnErrorColor}
          businessFnError={businessFnError}
          setSelectedBusinessFn={updateSelectedBusinessFn}
          selectedBusinessFn={selectedBusinessFn}
          isFilterApplied={isFilterApplied}
          setActivePage={setActivePage}
          setItemsPerPage={setItemsPerPage}
          resetFilters={resetFilters}
          filters={filters}
        />
        <PanelGroup direction="horizontal" className="panelOverflow">
          <Panel
            id="sidebar"
            minSize={30}
            order={1}
            defaultSize={isRightSideVisible ? 67 : 100}
            className={
              isRightSideVisible ? "isShowLeftPanel catBordDv" : "catBordDv"
            }
          >
            <EscalationtListView
              rightDisplay={isRightSideVisible}
              showRightSideFun={showRightSideFun}
              setSelectedItemFun={setSelectedItemFun}
              rowIndex={rowIndex}
              selectedBusinessFn={selectedBusinessFn}
              showActiveRow={showActiveRow}
              planSearchInput={planSearchInput}
              escalationIdSearchInput={escalationIdSearchInput}
              createdBySearchInput={createdBySearchInput}
              createdonSearchInput={createdonSearchInput}
              itemsPerPage={itemsPerPage}
              activePage={activePage}
              setPlanSearchInput={setPlanSearchInput}
              setEscalationIdSearchInput={setEscalationIdSearchInput}
              setCreatedonSearchInput={setCreatedonSearchInput}
              setCreatedBySearchInput={setCreatedBySearchInput}
              setItemsPerPage={setItemsPerPage}
              setActivePage={setActivePage}
              setIsFilterApplied={setIsFilterApplied}
              resetFilters={resetFilters}
              setFilters={setFilters}
              setRef={setRef}
              companySearchInput={companySearchInput}
              setCompanySearchInput={setCompanySearchInput}
              statusSearchInput={statusSearchInput}
              setStatusSearchInput={setStatusSearchInput}
            />
          </Panel>
        </PanelGroup>
      </Container>
    </main>
  );
};

export default EscalationListIndex;
