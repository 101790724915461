
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
//below URL used for testing purpose
//const breakFixUrl = 'https://xsm-f.mybluemix.net/api/SX_FAVOURITEOFFERINGs/';
import { GLOBAL }  from '../../components/Globals';
import constanturl from '../../../src/utils/constants.js';
//let breakFixUrl =`https://xsm-f.mybluemix.net/api/breakfixitem?breakfixitemId=`;
//let breakFixUrl=`https://xsm-f.mybluemix.net/groupListSelfService?companyId=2932&group=ResolvedER`
export function loadChangeRelatedWorkDetailsSuccess(changeRelatedWorkDetails){
	//alert('in success')
	return {
		type: 'LOAD_CMDBCHANGERELATEDWORKDETAILS_SUCCESS',
		changeRelatedWorkDetails
	};
}

export function loadChangeRelatedWorkDetailsFailure(errorResponse){
	return {
		type: 'LOAD_CMDBCHANGERELATEDWORKDETAILS_FAILURE',
		errorResponse
	};
}
export function loadChangeRelatedWorkDetails(query, value, module, companyid, page, size) {
	// console.log("Pagination parameters:", query, value, module, companyid, page, size);
	return (dispatch) => {
	  if (!(value === "--")) {
	  dispatch({
		type: "MENU_LOADER_IN_PROGRESS",
		payload: "",
	  });
	  }
	
	  let relatedurl = "";
	  if (module === "CMDB") {
		relatedurl = `${GLOBAL.cmdbRelatedWorkUrl}?searchByName=${query}&searchByValue=${value}&company_id=${companyid}`;
		relatedurl += (size !== undefined) ? `&limit=${size}` : '';
		relatedurl += (page !== undefined) ? `&page=${page}` : '';
	  api
		.get(relatedurl)
		.then((changeRelatedWorkDetails) => {
		if (!changeRelatedWorkDetails) {
		  dispatch({ type: "MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
		  throw Error(changeRelatedWorkDetails.statusText);
		}
		return changeRelatedWorkDetails;
		})
		.then((changeRelatedWorkDetails) => {
		dispatch(loadChangeRelatedWorkDetailsSuccess(changeRelatedWorkDetails));
		dispatch({ type: "MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
		})
		.catch((err) => {
		dispatch({ type: "MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
		console.log(err);
		});
	  } 
	  
	  else if (module === "SLA") {
	  relatedurl = `${GLOBAL.slaRelatedWorkUrl}/${companyid}`;
	  api
		.get(relatedurl, { headers: { slaname: value } })
		.then((changeRelatedWorkDetails) => {
		if (!changeRelatedWorkDetails) {
		  dispatch({ type: "MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
		  throw Error(changeRelatedWorkDetails.statusText);
		}
		return changeRelatedWorkDetails;
		})
		.then((changeRelatedWorkDetails) => {
		dispatch(
		  loadChangeRelatedWorkDetailsSuccess({ data: changeRelatedWorkDetails })
		);
		dispatch({ type: "MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
		})
		.catch((err) => {
		dispatch({ type: "MENU_LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
		console.log(err);
		});
	  }
	};
	}
