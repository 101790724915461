
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { MyContext } from '_MyContext';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { Link } from "react-router-dom";

import { GLOBAL } from "../Globals";
import { connect } from "react-redux";
import { loadBreakFixActivityLogDetails } from "../../actions/breakFix/breakFixActivityLogAction";
import { bindActionCreators } from "redux";
import { loadImplPlanList } from "../../actions/common/attachmentAction";
import { loadBreakFixTaskLogDetails } from "../../actions/task/TaskDataAction";
import AttachmentTab from "./Attachment";
import axios from "axios";
import swal from "sweetalert";
import ListLoader from "../common/loaders/ListLoader";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import datetimeConvertor from "../../ISO8601converter";
import _ from "lodash";
import moment from "moment";
import { navigationHooks } from "../../helpers/NavigationHook";
import WatcherDetails from "../common/WorkItemBoard/watcher/WatcherDetails";
import ReactReadMoreLess from "../../helpers/ReactReadMoreLess";
import {IoClose} from "react-icons/io5";
import {ImInfo, ImSpinner6} from "react-icons/im";

import TaskDetailsPopup from '_Commons/WorkItemBoard/taskDetailsPopup.js';
import { opsCategoryKeysMap, sanitizeElements } from "../common/helper";
import { isJson } from "../formBuilderTemplate/blocksHelper";
import AssetTablePopup from "../common/assetTableModal";

// import { ReadMore } from 'react-read-more';
const MoreArrow = (
  <i title="More" className="fa fa-angle-double-down f-size-15" />
);

const LessArrow = (
  <i title="Less" className="margin-l-5 lessArrow fa fa-angle-double-up"></i>
);

const Joi = require("joi");
const cookies = new Cookies();

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
timezone = timezone.split("~");
timezone = timezone[3];

let attachmentDetails = cookies.get("gph");
if (attachmentDetails) attachmentDetails = attachmentDetails.replace("s:", "");
if (attachmentDetails)
  attachmentDetails = attachmentDetails.substring(
    0,
    attachmentDetails.lastIndexOf(".")
  );
attachmentDetails = attachmentDetails.split("~");
const attachmentSize = attachmentDetails[21];

let user_id = cookies.get("gph");
if (user_id) user_id = user_id.replace("s:", "");
if (user_id) user_id = user_id.substring(0, user_id.lastIndexOf("."));
user_id = user_id.split("~");
user_id = user_id[43];

let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];
class MyRequestsShortDescInc extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
      activityLogHideShow: 0,
      showUserData: false,
      quickViewOrders: {},
      comments: "",
      showUpdates: 0,
      viewUpdates: 0,
      sortingArrow: 0,
      commentsAreEmpty: false,
      isUloadingDisabled: false,
      showAttachWithoutComments: false,
      loadWatcherDetails:false
    };
    this.showUserDetails = this.showUserDetails.bind(this);
    this.activityData = this.activityData.bind(this);
    this.postActivityData = this.postActivityData.bind(this);
    this.getActivityLog = this.getActivityLog.bind(this);
    this.viewUpdateShow = this.viewUpdateShow.bind(this);
    this.postAttachmentWithoutComments =
      this.postAttachmentWithoutComments.bind(this);
    this.renderAdditionalDetails = this.renderAdditionalDetails.bind(this);
    this.inputComments = navigationHooks.useRef;
		this.renderQuestionCategoryWise = this.renderQuestionCategoryWise.bind(this);

  }
  componentDidMount() {
    this.context.keyDownHandler(this.closeButton,this.editButton,this.globalSearchRef,this.saveObjRef);
  }
  componentDidUpdate() {
    this.context.keyDownHandler(this.closeButton,this.editButton,this.globalSearchRef,this.saveObjRef);
  }
  componentWillReceiveProps(newProps) {
		if(typeof newProps.myBreakFixOrderOrderDetails!=='undefined' && typeof this.props.myBreakFixOrderOrderDetails!=='undefined'){
			if(typeof newProps.myBreakFixOrderOrderDetails.status!=='undefined'){
                if(newProps.myBreakFixOrderOrderDetails.status !== this.props.myBreakFixOrderOrderDetails.status){
					this.setState({showAttachWithoutComments: false});
			}
		}
	}
  }

  getAttachment(itemId, Module) {
    this.props.loadImplPlanList(Module, itemId);
  }
  renderIndividualCategoryQuestions(categoryQuestions) {
		let answers = "";
		return [].concat(categoryQuestions).sort((a, b) => a.questionSequence > b.questionSequence ? 1 : -1)
    .map((categoryQuestion, i) => {
			if (categoryQuestion.question == "Assets") {
				//	console.log("assets answer");
				//	console.log(categoryQuestion.answer);
				let newstringreplaced = "";
				if(typeof categoryQuestion.answer === 'undefined' || categoryQuestion.answer === null ||  categoryQuestion.answer === ""){
					answers = "NA";
				}else if (categoryQuestion.answer != null && categoryQuestion.answer != "") {
					newstringreplaced = categoryQuestion.answer.replace(/,Name/gi, "~Name");
					answers = newstringreplaced.split('~');
				}
				else {
					answers = categoryQuestion.answer;
				}

			}
			return (

				<tr key={i}>
					<td className="bgBtn20 f-size-11" style={{ "width": "50%" }}>{categoryQuestion.question}</td>
					<td className="f-size-11">

						{/*	{categoryQuestion.question !== "Assets" ?categoryQuestion.answer :<div className="assetsAnswers">{answers.map((a,i)=>{return <p>{a}</p>})};</div>}*/}
						{categoryQuestion.question !== "Assets" ? typeof categoryQuestion.answer === 'undefined' || categoryQuestion.answer === null ||  categoryQuestion.answer === "" ? 'NA' : (
							categoryQuestion.answer.replace(/ ~ /g, "\n").split("\n").map((data) => {
								console.log(data)
                if(isJson(data) && JSON.parse(data).length > 0){
                  return <AssetTablePopup question={categoryQuestion.question} answer={data}/>;
                }
								return <p>{data}</p>
							})
						) : answers != null ? (
							<div className="assetsAnswers">
								{answers.map((a, i) => {
									return <p>{a}</p>;
								})}
								;
							</div>
						) : null}
					</td>
				</tr>
			);
		});
	}
  renderQuestionCategoryWise(myOrderQuestionDetails) {
		return [].concat(myOrderQuestionDetails).sort((a, b) => a.categorySequence > b.categorySequence ? 1 : -1).map((category, i) => {

			return (
				<Accordion id={'questions' + i} accordion className="brkFix brkFixOrder">
					<Accordion.Item eventKey='1' className="collapsed">
						<Accordion.Header><h4 toggle className="font1">{category.categoryName}</h4></Accordion.Header>
						<Accordion.Body collapsible>
							<div key={i}>
								<Table bordered condensed hover responsive className="tableView addWhiteSpc">
									<tbody>
										{this.renderIndividualCategoryQuestions(category.questions)}
									</tbody>
								</Table>
							</div>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			);
		});
	}
  renderAttachedItems(attachments) {
    if (_.isEmpty(attachments)) {
      return (
        <tr>
          <td colSpan={3} className="text-c">
            {this.props.tr["No File Found"]}
          </td>
        </tr>
      );
    }
    return attachments.map((attachment) => {
      console.log("attachment", attachment);
      if (attachment.filename !== null && attachment != undefined) {
        let attachfilename = attachment.filename || attachment.fileName;
        if (attachfilename.indexOf("##") > -1)
          attachfilename = attachfilename.slice(15);

        let actDateTimeFormat = jwtdf + " hh:mm A";
        let newDate = new Date(attachment.createdOn.toString().length > 10 ? attachment.createdOn : attachment.createdOn*1000).toLocaleString(
          "en-US",
          { timeZone: timezone }
        );
        newDate = moment(newDate).format(jwtdf + " HH:mm:ss");
        let isoDate = datetimeConvertor(newDate, jwtdf + " HH:mm:ss");
        let myDateObj = moment(isoDate).format(actDateTimeFormat);

        let validated_url = undefined;
        if (attachment != null && attachment.fileTempURL != "") {
          let namedSchema = Joi.string().regex(/^[^<>}{]+$/);
          let validator_to_add_myQbj = namedSchema.validate(
            attachment.fileTempURL
          );
          if (validator_to_add_myQbj.error == null) {
            validated_url = attachment.fileTempURL;
          } else {
            validated_url = "\\";
          }
        } else validated_url = "\\";

        if (attachment.status == "Y") {
          return null;
        } else if (attachment.planType == null) {
          return (
            <tr bsClass="" key={attachment.attachmentId}>
              <td>
                <div>{attachfilename}</div>
                <div style={{ color: "rgb(0 0 0 / 50%)" }}>
                  {attachment.createdByName}
                </div>
              </td>
              <td>{myDateObj}</td>
              <td className="text-c">
                <a className="margin-r-10" href={validated_url}>
                  <i className="fa fa-eye"></i>
                </a>
              </td>
            </tr>
          );
        }
      }
    });
  }

  activityLogHideShow() {
    if (this.state.activityLogHideShow == 1) this.setState({ comments: "" });
    this.setState({
      activityLogHideShow: !this.state.activityLogHideShow,
      viewUpdates: 0,
    });
  }

  viewUpdateShow() {
    this.getActivityLog();
    this.setState({
      activityLogHideShow: 0,
      viewUpdates: !this.state.viewUpdates,
    });
  }
  getActivityLog() {
    let itemId = this.props.itemId;
    this.props.loadBreakFixActivityLogDetails(itemId, "desc", "Breakfix");
  }
  showUserDetails() {
    this.setState({ showUserData: !this.state.showUserData });
  }

  renderApproverList(approverData) {
    if (!approverData) {
      return <div></div>;
    } else {
      return approverData.map((approverObj, index) => {
        return (
          <ListGroup.Item bsPrefix=" " bsClass="">
            <span className="labSpan">{this.props.tr["Approver"]}-</span>
            {approverObj.signatures}
          </ListGroup.Item>
        );
      });
    }
  }
  brkascactivitysort() {
    this.setState({
      sortingArrow: 0,
    });
    let module = this.props.module;
    let itemId = this.props.itemId;
    this.props.loadBreakFixActivityLogDetails(itemId, "asc", "Breakfix");
  }
  brkdescactivitysort() {
    this.setState({
      sortingArrow: 1,
    });

    let module = this.props.module;
    let itemId = this.props.itemId;
    this.props.loadBreakFixActivityLogDetails(itemId, "desc", "Breakfix");
  }

  renderActivityLog(activityLogData) {
    if (activityLogData.length == 0) {
      return <div>{this.props.tr["No updates found"]}</div>;
    } else {
      return activityLogData.map((activityObj, index) => {
        if (activityObj.description.includes("Group Reassignment Reason -")) {
          return null;
        }
        let intval = "",
          activitymsg = "";
        if (activityObj.isInternal == "Y") intval = "- Internal";

        if (activityObj.from === activityObj.to)
          activitymsg = this.props.tr["Status set to "] + activityObj.to;
        else
          activitymsg =
            this.props.tr["Changed Status from "] +
            activityObj.from +
            this.props.tr[" to "] +
            activityObj.to;

        if (activityObj.isInternal != "Y")
          return (
            <Card bsPrefix=" " className="mediaCrd">
              <Card.Body>
                <Card.Header className="blue margin-0">
                  {activityObj.createdOn}{" "}
                  <span
                    className="colorStOrange cursorPoint"
                    onClick={this.showUserDetails}
                  >
                    {activityObj.createdByName}
                  </span>
                </Card.Header>
                <div className="cTxt">
                <ReactReadMoreLess charLimit={235}
                    onShowMore={this.props.onChange}
                    readMoreText={MoreArrow}
                    readLessText={LessArrow}
                    content={<span dangerouslySetInnerHTML={{ __html: activityObj.from === "" ? sanitizeElements(activityObj.description.replace(/\n/g, "<br />")) : sanitizeElements(activitymsg) }} />}
                    />
                </div>
              </Card.Body>
            </Card>
          );
      });
    }
  }

  activityData(comnts) {
    this.setState({ comments: comnts.target.value });
    if (comnts != "" && comnts.target.value.trim() != "")
      this.setState({ commentsAreEmpty: false });
  }

  postActivityData() {
    let itemId = this.props.itemId;
    let module = "Breakfix";
    if (this.state.comments != "" && this.state.comments.trim() != "") {
      this.setState({ commentsAreEmpty: false });
      let ref = this;
      this.setState({ disabledSave: true });
      axios.get('/serviceManagement/rest/breakfixAction/'+this.props.myBreakFixOrderOrderDetails.breakfixId+'?requireUrgencyValue=Y').then((latestResponse)=>{
          if(latestResponse.data.impactedUserid==user_id && latestResponse.data.statusId=="20" && latestResponse.data.holdingReasonCode=="75"){
              axios.patch(GLOBAL.breakFixPatchUrl + latestResponse.data.breakfixId,{"status":'15',"modifiedBy": "","modifiedByName": "","holdingReasonCode": "","holdingComments": "","holdingReasonValue": "", "spUserid": 0, "spUsername": ""});
          }
      });
      axios
        .post(GLOBAL.breakFixActivityLogPostUrl, {
          itemId: itemId,
          description: this.state.comments
            .replace(/"/g, "")
            .replace(/\\/g, "\\\\")
            .replace(/</g, "")
            .replace(/>/g, "")
            .replace(/\n/g, "<br/>"),
          createdOn: "",
          createdBy: "",
          isInternal: "N",
          module: module,
          createdByName: "",
          sendNotification: "true",
        })
        .then((response) => {
          if (response.status === 201) {
            ref.inputComments.current.value = "";
            ref.setState({ comments: "" });
            if (GLOBAL.filesToBeSent && GLOBAL.filesToBeSent.length > 0) {
              let formData = new FormData();
              formData.append("file", GLOBAL.filesToBeSent[0]);
              let jsonObject = new Object();
              jsonObject.module = ref.props.module;
              jsonObject.itemId = itemId;
              jsonObject.createdOn = Math.round(new Date().getTime() / 1000.0);
              jsonObject.createdBy = "";
              formData.append("attachment", JSON.stringify(jsonObject));

              axios
                .post("/change/api/saveImplPlan", formData)
                .then((response) => {
                  let res = response.data;
                  if (response.status == 200) {
                    ref.setState({
                      activityLogHideShow: 0,
                      viewUpdates: 0,
                      disabledSave: false,
                    });
                    GLOBAL.filesToBeSent = [];
                    ref.getAttachment(itemId, "Breakfix");
                  }
                })
                .catch((error) => {
                  console.log("Please upload File up to 20 MB size.");
                  console.log("error in uploading file.");
                });
            }
            ref.setState({
              activityLogHideShow: 0,
              viewUpdates: 0,
              disabledSave: false,
            });
          } else {
            //console.log("response status is not ok:"+response.statusText)
          }
          console.log("API Call Start");
          this.props.loadBreakFixActivityLogDetails(
            response.data.itemId,
            "desc",
            module
          );
        });
    } else {
      this.setState({ commentsAreEmpty: true });
    }
  }

  postAttachmentWithoutComments() {
    let ref = this;
    let itemId = this.props.itemId;
    let module = "Breakfix";
    this.setState({ isUloadingDisabled: true });
    console.log("GLOBAL.filesToBeSent",GLOBAL.filesToBeSent);

    // if (GLOBAL.filesToBeSent && GLOBAL.filesToBeSent.length > 0) {
    //   let formData = new FormData();
      if (GLOBAL.filesToBeSent && GLOBAL.filesToBeSent.length > 0) {
      this.setState({ disabledSave: true });

        let formData = new FormData();
        GLOBAL.filesToBeSent.forEach((file,i)=>{
          formData.append(
            "file",
            file,
            file.name
          );
        })
      // formData.append("file", GLOBAL.filesToBeSent[0]);
      let jsonObject = new Object();
      jsonObject.module = ref.props.module;
      jsonObject.itemId = itemId;
      jsonObject.createdOn = Math.round(new Date().getTime() / 1000.0);
      jsonObject.createdBy = "";
      formData.append("attachment", JSON.stringify(jsonObject));
      let totalSize = 0;
      for (let i = 0; i < GLOBAL.filesToBeSent.length; i++) {
        totalSize += GLOBAL.filesToBeSent[i].size;
      }
      totalSize = totalSize / 1024 / 1024;

      if (totalSize > attachmentSize) {
        swal({
          text:
            // this.props.tr["Please upload File up to "] +
            this.props.tr["Total file size should be less than "] +
            attachmentSize +
            this.props.tr[" MB."],
            // this.props.tr[" MB size."],
          button: this.props.tr["OK"],
        }).then(() => {
          this.setState({ isUloadingDisabled: false });
          this.setState({ disabledSave: false });
        });
        return;
      }

      axios
        .post("/change/api/saveImplPlan", formData)
        .then((response) => {
          let res = response.data;
          if (response.status == 200) {
            swal({
              text: this.props.tr["File(s) uploaded successfully."],
              button: this.props.tr["OK"],
            });
            ref.setState({
              activityLogHideShow: 0,
              viewUpdates: 0,
              disabledSave: false,
              showAttachWithoutComments: false,
            });
            this.setState({ disabledSave: false });
            this.setState({ isUloadingDisabled: false });
            GLOBAL.filesToBeSent = [];
            ref.getAttachment(itemId, "Breakfix");
          }
          this.props.loadBreakFixActivityLogDetails(itemId, "desc", module);
        })
        .catch((error) => {
          console.log("Please upload File up to 20 MB size.");
          console.log("error in uploading file.");
          swal({
            // text: this.props.tr["error in uploading file for "],
            text: "Error in uploading file",
            button: this.props.tr["OK"],
          }).then(() => {
            this.setState({ isUloadingDisabled: true });
            this.setState({ disabledSave: true });
          });
        });
    } else {
      this.setState({ disabledSave: false });
      swal({
        text: this.props.tr["Please upload some files first"],
        button: this.props.tr["OK"],
      }).then(() => {
        this.setState({ isUloadingDisabled: false });
      });
    }
  }

  renderAdditionalDetails(profileDetails) {
    let locationName = "";
    if (
      profileDetails.cwlName != "" &&
      profileDetails.cwlName != null &&
      profileDetails.cwlName != "null"
    ) {
      locationName = profileDetails.cwlName;
    } else {
      locationName = profileDetails.locationName;
    }

    return (
      <div className="table-responsive">
        <Table
          bordered
          condensed
          hover
          responsive
          className="tableView sortImg"
        >
          <tbody>
            <tr>
              <td className="bgBtn20 f-size-13" style={{ width: "50%" }}>
                {this.props.tr["Permanent Location"]}
              </td>
              <td className="f-size-13">
                {this.props.myBreakFixOrderOrderDetails.requesterLocationName}
              </td>
            </tr>
            <tr>
              <td className="bgBtn20 f-size-13" style={{ width: "50%" }}>
                {this.props.tr["Current Working Location"]}
              </td>
              <td className="f-size-13">{locationName}</td>
            </tr>
            <tr>
              <td className="bgBtn20 f-size-13" style={{ width: "50%" }}>
                {this.props.tr["Alternate Phone"]}
              </td>
              <td className="f-size-13">{profileDetails.alternatePhone}</td>
            </tr>
            <tr>
              <td className="bgBtn20 f-size-13" style={{ width: "50%" }}>
                {this.props.tr["Alternate Email"]}
              </td>
              <td className="f-size-13">{profileDetails.alternateEmail}</td>
            </tr>
            <tr>
              <td className="bgBtn20 f-size-13" style={{ width: "50%" }}>
                {this.props.tr["Manager Name"]}
              </td>
              <td className="f-size-13">{profileDetails.managerName}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
  renderAttList(attributeData) {
    try{
      if (!attributeData) {
        return null;
      }
      return attributeData.map((taskObj, index) => {
        let finalDate = "";
        let splitDate1 = '';
        let splitDate2 = '';
        let splitDate3 = '';
        if(taskObj?.createdDate && typeof taskObj.createdDate == 'string'){
          let data = taskObj.createdDate.split("T");
          if(data.length > 0){
            splitDate1 = data[0] || '';
            splitDate2 = data[1] || '';
            let data2 = splitDate2.split(".");
            if(data2.length > 0){
              splitDate3 = data2[0] || '';
            }
          }
        }
        // let splitDate1 = taskObj.createdDate.split("T")[0];
        // let splitDate2 = taskObj.createdDate.split("T")[1];
        // let splitDate3 = splitDate2.split(".")[0];
        finalDate = finalDate.concat(splitDate1).concat(" ").concat(splitDate3);
        taskObj.requestType='myRequest';
        const tooltip = (<Popover className=""><TaskDetailsPopup taskObj={taskObj} disableFieldsForGuestRole={true}  /></Popover>);
  
        return (
          <tr>
            <td><span className='nowrap'><OverlayTrigger trigger="click" rootClose placement={this.props.windowSize.width < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
} overlay={tooltip}><span className="cursorPoint" title='Info'>{taskObj.taskCode}<ImInfo className='taskInfoicn'/></span></OverlayTrigger></span></td>
            <td>{taskObj.status.status}</td>
            <td>{taskObj.assignedTo}</td>
            <td>{/* {finalDate} */}{taskObj.expectedDueDate}</td>
          </tr>
        );
      });
    }catch(e){
      console.error(e);
      return null;
    }
  }
  loadRelatedTaskData(itemId, module, companyId) {
    if (module == "Breakfix") {
      this.props.loadBreakFixTaskLogDetails(
        itemId,
        "Breakfix",
        "asc",
        companyId,
        "myRequest"
      );
    }
    if (module == "Item") {
      this.props.loadBreakFixTaskLogDetails(
        this.props.itemId,
        "Fullfilment",
        "asc",
        companyId,
        "myRequest"
      );
    }
  }
  render() {
    console.log("this.props.myOrderQuestionDetails",this.props.myOrderQuestionDetails);
    console.log("this.props.myBreakFixOrderOrderDetails",this.props.myBreakFixOrderOrderDetails);
    if (
      this.props.ordersList.length == 0
    ) {
      return (
        <div>
          <font color="green">
            <b>{this.props.tr["There is no matching data available"]}</b>
          </font>
        </div>
      );
    }
    if (
      this.props.showLoader_5 ||
      this.props.showLoader_1 ||
      this.props.showLoader_2 ||
      this.props.showLoader3 ||
      this.props.showLoader
    ) {
      return (
        <div>
          <ListLoader myStyle={{ marginTop: "20px" }} />
        </div>
      );
    }
    let showActivity = "";
    if (
      this.props.orderStatus == "Fixed" ||
      this.props.orderStatus == "Closed" ||
      this.props.orderStatus == "Cancelled" ||
      this.props.orderStatus == "Fullfiled" ||
      this.props.orderStatus == "Draft"
    ) {
      showActivity = <div></div>;
    } else {
      showActivity = (
        <Button
          className={this.state.activityLogHideShow == 1 ? "rgSidrkBtn": "rgSiLigBtn"}
          bsSize="small"
          title={this.props.tr["Post a Comment"]}
          variant="primary"
          onClick={() => {
            this.activityLogHideShow();
            this.setState({ showAttachWithoutComments: false });
          }}
        >
          {this.props.tr["Post a Comment"]}
        </Button>
      );
    }
    let showTestAreaForComments = "";
    if (this.state.showUpdates == 0) {
      showTestAreaForComments = (
        <div className="position-re">
          <Form.Label bsClass="">
            <span className="rStar"></span>
            {this.props.tr["Write your comment"]}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="2"
            ref={this.inputComments}
            onChange={this.activityData.bind(this)}
            spellCheck="true"
          />
          {this.state.commentsAreEmpty ? (
            <div className="error">
              <font color="red">
                <strong>
                  {this.props.tr["Comments should not be left empty"]}
                </strong>
              </font>
            </div>
          ) : (
            ""
          )}
          <ButtonToolbar className="margin-t-15">
            <Button
              bsSize="small"
              variant="primary"
              disabled={this.state.disabledSave}
              onClick={this.postActivityData.bind(this)}
            >
              {this.props.tr["Post"]}
            </Button>
          </ButtonToolbar>
        </div>
      );
    } else {
      showTestAreaForComments = <div></div>;
    }

    let showSoringDescArrow = "";
    if (
      this.state.sortingArrow == 0 &&
      this.props.breakFixActivityLogDetails.length > 0
    ) {
      showSoringDescArrow = (
        <a
          href="javascript:void(0)"
          onClick={this.brkdescactivitysort.bind(this)}
          className="black"
          title={this.props.tr["Descending"]}
        >
          <i className="fa fa-angle-down"></i>
        </a>
      );
    }

    let showSoringAscArrow = "";
    if (this.state.sortingArrow == 1) {
      showSoringAscArrow = (
        <a
          href="javascript:void(0)"
          onClick={this.brkascactivitysort.bind(this)}
          className="black"
          title={this.props.tr["Ascending"]}
        >
          <i className="fa fa-angle-up"></i>
        </a>
      );
    }
    return (
      <div
        aria-label="Right Section"
        role="contentinfo"
        className="margin-b-20"
      >
        <Row>
          <Col xs={12}>
            <div className="rPageHeadActBtn">
              <ul>
                <li>
                <Link to="javascript:void(0)" className="closeRsBtn" id="closeButton"
                    title={this.props.tr["Minimize the details panel"]}
                    onClick={() => {
                      this.props.showRightPanelFun(false);
                    }}
                    ref={e => this.closeButton = e}
                  >
                    <IoClose/>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              {this.props.myBreakFixOrderOrderDetails.requestNumber +
                "-" +
                this.props.myBreakFixOrderOrderDetails.breakfixNumber}
              <div className="offNam">{this.props.ciServiceName}</div>
            </div>
          </Col>
        </Row>

        <div className="rBoxGap">
          <div className="rBoxTabs hideTabNav">
            <Tabs
              activeKey={this.state.tabKey}
              onSelect={this.handleSelect}
              id="tabId"
            >
              <Tab eventKey={1} title={this.props.tr["Request Details"]}>
                <ListGroup bsPrefix=" " bsClass="">
                  {this.props.myBreakFixOrderOrderDetails
                    ?.expectedResolutionTime &&
                    this.props.myBreakFixOrderOrderDetails
                      ?.expectedResolutionTime !== "" && (
                      <ListGroup.Item
                        bsPrefix=" "
                        bsClass=""
                        className="rwTable"
                        style={{ "border-top": "none",paddingTop:"0px"}}
                      >
                        <div className="text-c">{`${this.props.tr["The incident is estimated to be resolved by"]} `}</div>
                        <div className="text-c margin-b-5"><b>{`${moment(
                          this.props.myBreakFixOrderOrderDetails
                            ?.expectedResolutionTime,
                          jwtdf + " HH:mm:ss"
                        ).format("MMMM D, YYYY HH:mm")}`}</b></div>
                        <div className="text-c f-size-10 padding-l-20 padding-r-20 margin-b-5"><b>{this.props.tr["Note: "]}</b>
                        {` ${this.props.tr["The predicted time is an estimate and may be subject to change based on the actual progress of the incident."]}`}</div>
                      </ListGroup.Item>
                    )}
                  <ListGroup.Item
                    bsPrefix=" "
                    bsClass=""
                    className="rwTable"
                    style={{
                      "border-top":
                        this.props.myBreakFixOrderOrderDetails
                          ?.expectedResolutionTime &&
                        this.props.myBreakFixOrderOrderDetails
                          ?.expectedResolutionTime !== ""
                          ? ""
                          : "none",
                    }}
                  >
                    <span className="rwKey">
                      {this.props.serviceBased == true
                        ? this.props.tr["Service Name"]
                        : this.props.tr["Impacted CI"]}
                    </span>
                    <span className="rwVal">{this.props.ciServiceName}</span>
                  </ListGroup.Item>
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">
                      {this.props.tr["Requested For"]}
                    </span>
                    <span className="rwVal">
                      {this.props.myBreakFixOrderOrderDetails.impactedUsername}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">
                      {this.props.tr["Issue Description"]}
                    </span>
                    <span className="rwVal">
                      {this.props.myBreakFixOrderOrderDetails.description !==
                      null
                        ? this.props.myBreakFixOrderOrderDetails.description
                            .split(/\n/)
                            .map((description) => <div>{description}</div>)
                        : ""}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">
                      {this.props.tr["Additional Information"]}
                    </span>
                    <span className="rwVal">
                      {this.props.myBreakFixOrderOrderDetails.additionalInfo !==
                      null
                        ? this.props.myBreakFixOrderOrderDetails.additionalInfo
                            .split(/\n/)
                            .map((additionalInfo) => (
                              <div>{additionalInfo}</div>
                            ))
                        : ""}
                    </span>
                  </ListGroup.Item>
                  {this.props.myBreakFixOrderOrderDetails[opsCategoryKeysMap["opsCategory1"]] !== null && (
                    <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                      <span className="rwKey">
                        {this.props.tr["Operational category 1"]}
                      </span>
                      <span className="rwVal">
                        {this.props.myBreakFixOrderOrderDetails[opsCategoryKeysMap["opsCategory1"]]
                          .split(/\n/)
                          .map((opsCategory1, index) => (
                            <div key={index}>{opsCategory1}</div>
                          ))}
                      </span>
                    </ListGroup.Item>
                  )}

                  {this.props.myBreakFixOrderOrderDetails[opsCategoryKeysMap["opsCategory2"]] !== null && (
                    <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                      <span className="rwKey">
                        {this.props.tr["Operational category 2"]}
                      </span>
                      <span className="rwVal">
                        {this.props.myBreakFixOrderOrderDetails[opsCategoryKeysMap["opsCategory2"]]
                          .split(/\n/)
                          .map((opsCategory2, index) => (
                            <div key={index}>{opsCategory2}</div>
                          ))}
                      </span>
                    </ListGroup.Item>
                  )}

                  {this.props.myBreakFixOrderOrderDetails[opsCategoryKeysMap["opsCategory3"]] !== null && (
                    <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                      <span className="rwKey">
                        {this.props.tr["Operational category 3"]}
                      </span>
                      <span className="rwVal">
                        {this.props.myBreakFixOrderOrderDetails[opsCategoryKeysMap["opsCategory3"]]
                          .split(/\n/)
                          .map((opsCategory3, index) => (
                            <div key={index}>{opsCategory3}</div>
                          ))}
                      </span>
                    </ListGroup.Item>
                  )}

                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">{this.props.tr["Urgency"]}</span>
                    <span className="rwVal">
                      {this.props.urgencyList[this.props.myBreakFixOrderOrderDetailsInteger.urgency]}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">
                      {this.props.tr["Submit Date"]}
                    </span>
                    <span className="rwVal">
                      {this.props.myBreakFixOrderOrderDetails.createdOn}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">
                      {this.props.tr["Item Status"]}
                    </span>
                    <span className="rwVal">
                      {this.props.tr[this.props.myBreakFixOrderOrderDetails.status]}
                    </span>
                  </ListGroup.Item>
                  {/* {this.props.myBreakFixOrderOrderDetails?.expectedResolutionTime && 
                  this.props.myBreakFixOrderOrderDetails?.expectedResolutionTime !== "" && 
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">
                      {this.props.tr["Resolution Time"]}
                    </span>
                    <span className="rwVal">
                      {this.props.myBreakFixOrderOrderDetails?.expectedResolutionTime}
                    </span>
                  </ListGroup.Item>} */}
                  <ListGroup.Item
                    bsPrefix=" "
                    bsClass=""
                    className="rwTable"
                    style={{ "border-bottom": "1px solid #b3d4e6" }}
                  >
                    <span className="rwKey">{this.props.tr["Order Type"]}</span>
                    <span className="rwVal">Incident</span>
                  </ListGroup.Item>
                </ListGroup>

                <Accordion
                  id="rightSidePanel6"
                  accordion
                  className="brkFix brkFixOrder margin-t-25"
                >
                  <Accordion.Item eventKey="1" className="collapsed">
                    <Accordion.Header
                      onClick={() => {
                        this.loadRelatedTaskData(
                          this.props.itemId,
                          this.props.module,
                          this.props.profileDetails.companyId
                        );
                      }}
                    >
                      <h4 toggle>{this.props.tr["Task Progress"]}</h4>
                    </Accordion.Header>
                    <Accordion.Body collapsible>
                      {this.props.showLoaderRelatedTask ? (
                        <div>
                          <ListLoader />
                        </div>
                      ) : this.props.breakFixTaskLogDetails.length == 0 ? (
                        <div>{this.props.tr["Data not available"]}</div>
                      ) : (
                        <Table
                          responsive
                          striped
                          bordered
                          condensed
                          hover
                          className="f-size-13"
                        >
                          <thead>
                            <tr>
                              <th>{this.props.tr["Task Code"]}</th>
                              <th>{this.props.tr["Status"]}</th>
                              <th>{this.props.tr["Fulfiller"]}</th>
                              <th>{this.props.tr["Expected Completion"]}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.renderAttList(
                              this.props.breakFixTaskLogDetails
                            )}
                          </tbody>
                        </Table>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" className="collapsed">
                    <Accordion.Header>
                      <h4 toggle>{this.props.tr["Requestor Information"]}</h4>
                    </Accordion.Header>
                    <Accordion.Body collapsible>
                      {this.props.showLoader_6 ? (
                        <ListLoader />
                      ) : (
                        this.renderAdditionalDetails(this.props.profileDetails)
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                  
                  
                  {this.props.myOrderQuestionDetails.length != 0 ? this.props.myOrderQuestionDetails[0].categoryId != null ?
                    <Accordion.Item eventKey='5' className="collapsed">
                      <Accordion.Header><h4 toggle>{this.props.tr['Information Provided']}</h4></Accordion.Header>
                      <Accordion.Body collapsible>
                        {this.renderQuestionCategoryWise(this.props.myOrderQuestionDetails)}
                      </Accordion.Body>
                    </Accordion.Item> : null : null}


                  {this.props.orderStatus != "Draft" ? (
                    <Accordion.Item eventKey="3" className="collapsed">
                      <Accordion.Header
                        onClick={() => {
                          this.getAttachment(this.props.itemId, "Breakfix");
                        }}
                      >
                        <h4 toggle>{this.props.tr["Attachments"]}</h4>
                      </Accordion.Header>
                      <Accordion.Body collapsible>
                        <Table
                          responsive
                          striped
                          bordered
                          condensed
                          hover
                          className="f-size-12 wrpAttTab"
                        >
                          <thead>
                            <tr>
                              <th width="55%">
                                Name & {this.props.tr["Linked By"]}
                              </th>
                              <th width="25%">Date & Time</th>
                              <th width="20%" className="text-c">
                                {this.props.tr["Action"]}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.renderAttachedItems(
                              this.props.implememtationPlanList
                            )}
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : (
                    ""
                  )}
                   <Accordion.Item eventKey="8" className="collapsed">
                    <Accordion.Header
                      onClick={() => {
                        this.setState({
                          loadWatcherDetails: true,
                        });
                      }}
                    >
                      {this.props.tr["Watcher Details"]}
                    </Accordion.Header>
                    <Accordion.Body>
                      {this.state.loadWatcherDetails ? (
                        <WatcherDetails loadFrom="myRequest" itemId={this.props.itemId} module={this.props.module} status={this.props.orderStatus} />
                      ) : null}
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* {this.props.myBreakFixOrderOrderDetails?.expectedResolutionTime && 
                  this.props.myBreakFixOrderOrderDetails?.expectedResolutionTime !== "" && 
                  <Accordion.Item eventKey="9" className="collapsed">
                    <Accordion.Header>
                      <h4 toggle>{this.props.tr["Resolution Information"]}</h4>
                    </Accordion.Header>
                    <Accordion.Body collapsible>
                      {`${this.props.tr["The incident is estimated to be resolved by"]} `}
                      <b>{`${moment(this.props.myBreakFixOrderOrderDetails?.expectedResolutionTime, jwtdf + " HH:mm:ss").format('MMMM D, YYYY HH:mm:ss')}.`}</b>
                      <br />
                      <br />
                      <b>{this.props.tr["Note: "]}</b>{` ${this.props.tr["The predicted time is an estimate and may be subject to change based on the actual progress of the incident."]}`}
                    </Accordion.Body>
                  </Accordion.Item>} */}
                </Accordion>

                <ButtonToolbar className="margin-t-15 black">
                  {showActivity}
                  {/* {this.props.orderStatus != "Draft" &&
                  this.props.orderStatus != "Closed" ? ( */}
                  {(this.props.orderStatus == "Fixed" ||
                    this.props.orderStatus == "Draft"||
                    this.props.orderStatus == "Closed" ||
                    this.props.orderStatus == "Cancelled" ||
                    this.props.orderStatus == "Fulfilled" ||
                    this.props.orderStatus == "Rejected" ) ? (
                  ""
                  ) : (
                    <Button
                    className={this.state.showAttachWithoutComments ? "rgSidrkBtn": "rgSiLigBtn"}
                    bsSize="small"
                    title={this.props.tr["Attach Files"]}
                    variant="primary"
                    onClick={() => {
                      this.setState({
                        showAttachWithoutComments:
                          !this.state.showAttachWithoutComments,
                        activityLogHideShow: 0,
                        viewUpdates: 0,
                        disabledSave:false
                      });
                    }}
                  >
                  {this.props.tr["Attach Files"]}
                  </Button>
                  )}
                  <Button
                    className={this.state.viewUpdates == 1 ? "rgSidrkBtn": "rgSiLigBtn"}
                    bsSize="small"
                    title={this.props.tr["View Updates"]}
                    variant="primary"
                    onClick={() => {
                      this.viewUpdateShow();
                      this.setState({ showAttachWithoutComments: false });
                    }}
                  >
                    {this.props.tr["View Updates"]}
                  </Button>
                </ButtonToolbar>
                {this.state.showAttachWithoutComments ? (
                  <div className="margin-t-20">
                    <AttachmentTab
                      translator={this.props.tr}
                      itemId={this.state.itemId}
                      isUloadingDisabled={this.state.isUloadingDisabled}
                      page = {"myRequest"}
                    />
                    <Button
                      className="margin-t-20"
                      bsSize="small"
                      variant="primary"
                      disabled={this.state.isUloadingDisabled}
                      onClick={this.postAttachmentWithoutComments}
                    >
                      {this.state.disabledSave == true ? (
                        <span>
                          <ImSpinner6 className="icn-spinner"/>
                        </span>
                      ) : (
                        ""
                      )}
                      {this.props.tr["Attach"]}
                    </Button>
                  </div>
                ) : (
                  ""
                )}
                {this.state.activityLogHideShow == 1 ? (
                  <div className="margin-t-20 blueAnq">
                    {showTestAreaForComments}
                  </div>
                ) : null}
                {this.state.viewUpdates == 1 ? (
                  <div className="margin-t-15 padding-b-5">
                    <span className="float-r f-size-17">
                      {showSoringAscArrow}
                      {showSoringDescArrow}
                      <span className="float-r margin-r-10"></span>
                    </span>
                    {this.renderActivityLog(
                      this.props.breakFixActivityLogDetails
                    )}
                  </div>
                ) : null}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}
MyRequestsShortDescInc.propTypes = {
  orderStatus: PropTypes.string,
  loadImplPlanList: PropTypes.func,
  ordersList: PropTypes.object,
  loadBreakFixActivityLogDetails: PropTypes.func,
  tr: PropTypes.object,
  module: PropTypes.string,
  showActiveRow: PropTypes.func,
  loadBreakFixTaskLogDetails: PropTypes.func,
  showLoader: PropTypes.bool,
  showLoader_1: PropTypes.bool,
  showLoader3: PropTypes.bool,
  showLoader_5: PropTypes.bool,
  showLoader_6: PropTypes.bool,
  showRightPanelFun: PropTypes.func,
};

export function mapStateToProps({
  breakFixActivityLogDetails,
  ordersList,
  myBreakFixOrderOrderDetails,
  approverListDetails,
  implememtationPlanList,
  showLoader_5,
  showLoader_1,
  showLoader_2,
  showLoader3,
  showLoader,
  showLoader_6,
  profileDetails,
  breakFixTaskLogDetails,
  showLoaderRelatedTask,
  myOrderQuestionDetails,
  urgencyList, 
  myBreakFixOrderOrderDetailsInteger,
  windowSize
}) {
  return {
    breakFixActivityLogDetails,
    ordersList,
    myBreakFixOrderOrderDetails,
    approverListDetails,
    implememtationPlanList,
    showLoader_5: showLoader_5.loading,
    showLoader_1: showLoader_1.loading,
    showLoader_2: showLoader_2.loading,
    showLoader3: showLoader3.loading,
    showLoader: showLoader.loading,
    showLoader_6: showLoader_6.loading,
    profileDetails,
    breakFixTaskLogDetails,
    showLoaderRelatedTask: showLoaderRelatedTask.loading,
    myOrderQuestionDetails,
    urgencyList, 
    myBreakFixOrderOrderDetailsInteger,
    windowSize
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadBreakFixActivityLogDetails,
      loadImplPlanList,
      loadBreakFixTaskLogDetails,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyRequestsShortDescInc);
