
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { Field, reduxForm,formValueSelector,SubmissionError } from 'redux-form';

import { loadBreakFixSupportCIMGroup } from '../../../../actions/breakFix/cimGroupAction';
import { loadBreakFixCIMForUserDetails } from '../../../../actions/breakFix/cimDetailsAction';
import { loadBreakFixEditDetails, loadBreakFixEditDetailsUpdate } from '../../../../actions/breakFix/breakFixEditDetailsAction';
import { loadBreakFixSGAE,loadNewBreakFixSGAEWithQueryParams } from '../../../../actions/breakFix/serviceGroupAEAction';
import { GLOBAL } from "_Globals";
import axios from 'axios';
import { navigationHooks } from '../../../../helpers/NavigationHook';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
import { RulesDropdownList } from '../../../common/forms/RulesDropdownList';
// import { browserHistory } from 'react-router';
let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

class ProposeCriticalTab extends React.Component{

	constructor(props){
	super(props);
	this.state={
		checkButtonStatus:false
	}
	this.onCIMGroupChange = this.onCIMGroupChange.bind(this);
	this.changeButtonStatus = this.changeButtonStatus.bind(this);
	this.submit = this.submit.bind(this);
	};

	changeButtonStatus(e)
	{
		this.setState({checkButtonStatus : e})
	}

	componentWillMount(){
		//alert("GLOBAL.breakFixServiceID"+this.props.offeringId);
		// if(GLOBAL.breakFixServiceID=="")
		// this.props.loadBreakFixSupportCIMGroup(this.props.offeringId,"offering");
		// else
		// this.props.loadBreakFixSupportCIMGroup(GLOBAL.breakFixServiceID,"service");
		let amsAttribute={
			"Status":this.props.breakFixEditDetails.statusId,
			"Priority":this.props.breakFixEditDetails.priorityValue,
			"Service":this.props.breakFixEditDetails.serviceId,
			"Impacted CI":this.props.breakFixEditDetails.ciId,
			"Requestor":this.props.breakFixEditDetails.impactedUserid,
			"Class":this.props.breakFixEditDetails.ciClassId,
			"User.Location":this.props.breakFixEditDetails.requesterLocationId,
			"CI.Location":this.props.breakFixEditDetails.ciLocationId
		}
		let rulesAttribute = {
			status: this.props.breakFixEditDetails.status,
			priorityId: this.props.breakFixEditDetails.priorityId,
			serviceName: this.props.breakFixEditDetails.serviceName,
			impactedCi: this.props.breakFixEditDetails.ciName,
			impactedUsername: this.props.breakFixEditDetails.impactedUsername,
			ciClassName: this.props.breakFixEditDetails.ciClassName,
			userLocation: this.props.breakFixEditDetails.requesterLocationName,
			ciLocation: this.props.breakFixEditDetails.ciLocationName,
			serviceId:this.props.breakFixEditDetails.serviceId,
			impactedCiId:this.props.breakFixEditDetails.ciId,
			requestorId:this.props.breakFixEditDetails.impactedUserid,
			classId:this.props.breakFixEditDetails.ciClassId,
			userLocationId:this.props.breakFixEditDetails.requesterLocationId,
			ciLocationId:this.props.breakFixEditDetails.ciLocationId
		};
		this.setState({ rulesAttribute });
		console.log("componentWillMount called",this.props.breakFixEditDetails.cimGroup);
		GLOBAL.breakfixgroupid=this.props.breakFixEditDetails.cimGroup
		let getBreanFixkItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") :""
		//let BRKItemId = getBreanFixkItemId[0];
		let breakFixActualItemId = this.props.breakFixItemId || ""; //getBreanFixkItemId[1];
		GLOBAL.breakFixId=breakFixActualItemId;
		this.props.loadBreakFixEditDetailsUpdate(breakFixActualItemId);
		// this.props.loadNewBreakFixSGAEWithQueryParams(this.props.breakFixEditDetails.serviceBased?this.props.breakFixEditDetails.serviceId:this.props.breakFixEditDetails.ciId,this.props.breakFixEditDetails.consumerCompany,"CIM",this.props.breakFixEditDetails.serviceBased?"service":"impactedCI",amsAttribute,this.props.breakFixEditDetails.serviceCompanyId);
		// this.props.loadBreakFixCIMForUserDetails(this.props.breakFixEditDetails.cimGroup);
		// const initData = {
		// 	};
		// 	 this.props.initialize(initData);
			 GLOBAL.breakfixChangeGrpname=this.props.breakFixEditDetails.cimGroupname;

	}

	onCIMGroupChange(e){
		GLOBAL.breakfixgroupid=e.target.value
		this.props.loadBreakFixCIMForUserDetails(e.target.value);
		// alert(e.target[e.target.selectedIndex].text);
		GLOBAL.breakfixChangeGrpname=e.target[e.target.selectedIndex].text;
      //alert("this.props.supportPGroup"+this.props.supportPGroup);
    /*  if(this.props.cimGroup!=undefined){
        this.props.loadBreakFixCIMForUserDetails(this.props.cimGroup);
      }else{
        console.log("Error in loading loadBreakFixSGI");
      }*/
    }

		renderCIMGroup(breakFixData){
			//alert(this.props.breakFixEditDetails.serviceId);
	      //alert("breakFixData:"+breakFixData);
	      if(breakFixData==undefined){
					//alert("Hi");
	        return(
	            null
	        );
	      }
	      if(GLOBAL.breakFixId!=""){
	        return breakFixData.map((breakFixObj)=>{
	          return(
	              <option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
	            );
	        });
	      }
	    }

			renderCIMDetails(breakFixData){
				//alert(this.props.cimGroup)
				//alert("breakFixData1"+breakFixData);
				//alert("this.props.cimGroup"+this.props.cimGroup);
				//alert(breakFixData);
				if(!breakFixData){
					return(
							null
					);
				}
				if(this.props.cimGroup!=""){
						return breakFixData.map((breakFixObj)=>{
							return(
										<option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
									);
						});
				}
			}

			renderSupportPIAEGroup(breakFixData){
			 //console.log("bgid::"+GLOBAL.breakfixgroupid)
			 if(!breakFixData || breakFixData.length==0){
				 return(
						 null
				 );
			 }
					 return breakFixData.map((breakFixObj)=>{
						 if(breakFixObj.assignmentGroupId==GLOBAL.breakfixgroupid && breakFixObj.assignmentMemberId!=0)
						 if(breakFixObj.assignmentMemberId!=null && breakFixObj.assignmentMemberName!=null)
						 {
						 return(
									 <option value={breakFixObj.assignmentMemberId}>{breakFixObj.assignmentMemberName}</option>
								 );
						 }		 
					 });
		 }

		 renderSupportPGroup(breakFixData){
			 let grouplist = [];
			 if(!breakFixData || breakFixData.length==0){
				 return(
						 null
				 );
			 }

	 breakFixData.map((breakFixObj)=>{
		 grouplist.push(breakFixObj.assignmentGroupId+"~"+breakFixObj.assignmentGroupName)
	 });
		 //  console.log(grouplist.toString());

			 grouplist = grouplist.filter( function( item, index, inputArray ) {
							return inputArray.indexOf(item) == index;
			 });
			 //console.log("b:::"+grouplist.toString());

						return grouplist.map((breakFixObj)=>{
						 let breakfixobj1=breakFixObj.split("~");
						return(
										<option value={breakfixobj1[0]}>{breakfixobj1[1]}</option>
									);
						});
		 }

		 submit(values) {
			let ref = this;
			return sleep(1000) // simulate server latency
			  .then(() => {
				let breakFixConsumer=GLOBAL.breakFixConsumerID;
				let cimGroup="";
				let cimGroupname="";
				let cimUser="";
				let cimUserName="";
				let actualValueCimGroup = (values.cimGroup) ? (values.cimGroup).split(":"):""
				let actualValueCimUser = (values.cimUserDeails) ? (values.cimUserDeails).split(":"):""
				let cim=values.cimGroup;
				cimGroup=actualValueCimGroup[0];
				cimGroupname=GLOBAL.breakfixChangeGrpname;
				cimUser=actualValueCimUser[0];
				cimUserName=actualValueCimUser[1];
		        // alert("values",actualValueCimGroup);
				console.log("values.cimUserDeails ::",values.cimUserDeails)
				console.log("actualValueCimUser",actualValueCimUser)
				console.log("cimGroup ::",cimGroup)
				console.log("cimGroupname",cimGroupname)
				if (values.cimUserDeails==undefined || values.cimUserDeails=="" || values.cimUserDeails==null){
				cimUserName="";
				cimUser=0;
				}
		  
				if(`${JSON.stringify(values, null, 2)}`=="{}"){
				  throw new SubmissionError({ _error: 'Please select a values!' })
				}else if (values.justification=="" ||values.justification==undefined) {
				  throw new SubmissionError({ _error: 'Please provide justification for critical !' })
				}else if (values.cimGroup==undefined || values.cimGroup=="") {
				  throw new SubmissionError({ _error: 'Please select CIM Group!' })
				}else{
					ref.changeButtonStatus(true)
				  GLOBAL.proposeButtonStatus="true";
				  axios.patch(GLOBAL.breakFixEditUrl+GLOBAL.breakFixId, {
					//======================
					"criticalJustification" : values.justification,
					"cimGroup" : values.cimGroup,
					"cimUser" : cimUser,
					"cimGroupname":cimGroupname,
					"cimUsername":cimUserName,
					"criticalityStatus":"5",
					"modifiedBy" : "",
					"modifiedByName":""
				  })
				  .then(function (response) {
					console.log(response);
					GLOBAL.removeCritical = 0;
					GLOBAL.proposeCritical=1;
					GLOBAL.criticalitystatusval=1;
					GLOBAL.proposemessage="Saved";
					ref.changeButtonStatus(false)
					navigationHooks.navigate("/editBreakFix/"+GLOBAL.breakFixId);
					ref.props.showProcessHierarchy();
				  })
				  .catch(function (error) {
					console.log(error);
					GLOBAL.removeCritical = 1
				  });
				}
			  })
		    //}  
		  }

	renderCIMGroupOptions(options){
		try{
			if(!(options && Array.isArray(options))){
				return null;
			}
			return options.map(item => {
				return(
					<option value={item['assignmentGroupId']}>{ (item?.assignmentCompanyName) ? `${item.assignmentGroupName} (${item.assignmentCompanyName})` : item.assignmentGroupName }</option>
				);
			});
		}catch(e){
			return null;
		}
	}


	render(){
		console.log("this.props.breakFixEditDetails.criticalityStatus",this.props.breakFixEditDetails.criticalityStatus);
		let { error,handleSubmit, pristine, reset, submitting,justification,cimGroup,cimUserDeails} = this.props
		let savebutton='';
		let savemsg=''
		if(this.props.proposeCriticalTabStatus)
		    savemsg='The Work Item priority is proposed as critical'
		savebutton= <ButtonToolbar ><Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary"  disabled={this.state.checkButtonStatus||this.props.breakFixEditDetails.criticalityStatus=="Accepted" || this.props.breakFixEditDetails.criticalityStatus=="Proposed"||this.props.disableFieldsForGuestRole} onClick={handleSubmit(this.submit)}>{this.state.checkButtonStatus ? <ImSpinner6 className="icn-spinner"/>:null}{this.props.translator['Save']}</Button></ButtonToolbar>
		if(this.props.cimGroupDetails instanceof Error){
       return (
         <h2>
           Network Error Occured...Please try again....
         </h2>
       );
     }


		if(this.props.breakFixEditDetails.criticalityStatus=="Accepted" || this.props.breakFixEditDetails.criticalityStatus=="Proposed"){
			GLOBAL.proposeButtonStatus="true";
		}

		return (
      <div className="hambTab">
        <div
          className="rPageHeadActBtn d-md-none"
          style={{ marginTop: "-3px" }}
        >
          <ul>
            <li>
              <button
                type="button"
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                  this.props.rightEditPanel(false);
				  this.props.changeRightView("allClose");
                }}
              >
                <IoClose />
              </button>
            </li>
          </ul>
        </div>
        {this.props.breakFixEditDetails.criticalityStatus == "Accepted" ? (
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {this.props.translator["Accepted As Critical"]}
            </div>
          </div>
        ) : (
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {this.props.translator["Propose Critical"]}
            </div>
          </div>
        )}

        <div className="rBoxGap">
          <font color="red">{error && <strong>{error}</strong>}</font>
          <Form.Group className="form-group">
            <Form.Label>
              <span className="rStar"></span>
              {this.props.translator["Justification"]}
            </Form.Label>
            <Field
              name="justification"
              component="input"
              type="text"
              className="form-control"
              placeholder={this.props.breakFixEditDetails.criticalJustification}
              readOnly={GLOBAL.proposeButtonStatus}
            ></Field>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>
              <span className="rStar"></span>
              {this.props.translator["CIM Group"]}
            </Form.Label>
            <RulesDropdownList
              name="cimGroup"
              component="select"
              className="form-control"
              onChange={(event) => {
                this.onCIMGroupChange(event);
              }}
              rulesAttribute={this.state.rulesAttribute}
			  disabled={this.props.disableFieldsForGuestRole}
              companyId={this.props.breakFixEditDetails.consumerCompany}
			  serviceCompanyId={this.props.breakFixEditDetails.serviceCompanyId}
              moduleName="incident"
              ruleType="assignment"
              ruleFor="criticalIncidentManager"
            />
          </Form.Group>
          {savebutton}
          <font color="red">{savemsg}</font>
        </div>
      </div>
    );
	}
}

ProposeCriticalTab = reduxForm({
  form: 'proposeCriticalTab',
	fields: ['justification','cimGroup','cimUserDeails'],
	enableReinitialize:true
})(ProposeCriticalTab);

let selector = formValueSelector('proposeCriticalTab') // <-- same as form name
ProposeCriticalTab = connect(
  state => {
		// can select values individually
    let justification = selector(state, 'justification')
    let cimGroup = selector(state, 'cimGroup')
		let cimUserDeails = selector(state, 'cimUserDeails')

    return {
      justification,
      cimGroup,
			cimUserDeails
    }
  }
)(ProposeCriticalTab)

export function mapStateToProps({ cimGroupDetails,cimUserDetailsForUser,breakFixEditDetails,breakFixSGAEName, breakFixSGAENameCIM}){
  //alert(breakFixServiceWithoutDomain);
	return { 
		initialValues: {
			"justification": breakFixEditDetails.criticalJustification,
				"cimGroup":breakFixEditDetails.cimGroup,
				"cimUserDeails":breakFixEditDetails.cimUser
		},
		cimGroupDetails,cimUserDetailsForUser,breakFixEditDetails,breakFixSGAEName, breakFixSGAENameCIM
	
	};
}

function mapDispatchToProps(dispatch){
	return bindActionCreators({ loadBreakFixSupportCIMGroup,loadBreakFixCIMForUserDetails,loadBreakFixEditDetailsUpdate,loadBreakFixEditDetails,loadBreakFixSGAE,loadNewBreakFixSGAEWithQueryParams}, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ProposeCriticalTab);
