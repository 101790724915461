
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef, useContext } from "react";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector, connect } from "react-redux";

import { Row, Col, Button, ListGroup, Nav } from "react-bootstrap";
import { IoSaveOutline, IoClose } from "react-icons/io5";
import { BiDotsVertical } from "react-icons/bi";
import { ImAttachment, ImSpinner6 } from "react-icons/im";
import StatausActions from "./statusAction";

import { useGetActionStatusTimelineQuery } from "../../../../reducers/governance/meeting/actionItem";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';
import { LuActivitySquare, LuCalendarClock } from "react-icons/lu";

const Header = ({
  submitForm,
  formikref,
  toggle,
  activeTabs,
  isAddMode,
  isSubmitting,
  actionStatus,
  setShowTimeLine,
  showTimeLine,
  editActionData,
  handleOnSelectAction,
  dirty,
  ...props
}) => {
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const params = useParams();

  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const currentActionItem_status_id = editActionData[0]?.statusId;
  const status = editActionData[0]?.statusName;
  const [isOpen, setIsOpen] = useState(false);
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
    context.newTabPrevent();
	}, []);

  const statusHamburgerToggle = () => {
    // fetechActions();
    setIsOpen(!isOpen);
  };

  const {
    data: timelines,
    error: timelineError,
    isLoading: timelineLoading,
  } = useGetActionStatusTimelineQuery(currentActionItem_status_id, {
    skip: !currentActionItem_status_id,
  });

  const handleSubmit = () => {
    formikref.current?.submitForm();
  };

  useEffect(() => {}, [formikref?.current]);

  return (
    <Row className="margin-b-15">
      <Col md={8} xs={12}>
        <Row>
          <Col lg={8} md={7} sm={6} xs={12}>
            <h1 bsClass="" className="sPageHeading1">
              Actions
              {!isAddMode && (
                <Button
                  title="Open Timeline"
                  bsPrefix=" "
                  className="barDropMenu tinelineicon"
                  bsStyle="link"
                  onClick={() => setShowTimeLine(!showTimeLine)}
                >
                  <LuCalendarClock />
                </Button>
              )}
              <div className="order position-re">
                <div className="lnk">{editActionData[0]?.actionitemCode}</div>
              </div>
            </h1>
          </Col>
          <Col lg={4} md={5} sm={6} xs={12}>
            {!isAddMode && (
                <div className="toprigLnk">
                  <div className="thrlnebtn" ref={menuRef}>
                    <Button
                      title="Statuses"
                      className="btnDropMenu btn btn-default"
                      bsPrefix=" "
                      onClick={statusHamburgerToggle}
                      disabled={status === "Cancelled" || status === "Closed"}
                    >
                    {typeof timelines!=='undefined' && typeof timelines.response!=='undefined' && timelines.response.length>0 && timelines["response"][0]["status"]!=='undefined' && timelines["response"][0]["status"]}
                      <i className="fa fa-angle-down" aria-hidden="true" />
                    </Button>

                    <StatausActions
                      setIsOpen={setIsOpen}
                      isOpen={isOpen}
                      mref={menuRef}
                      handleOnSelectAction={handleOnSelectAction}
                      componentType="MeetingForum"
                      editActionData={editActionData}
                      data={timelines}
                      actionsData={timelines}
                    />
                  </div>
                  {/* <div className="thrlnebtn margin-l-10">
                    <Button
                      title="Actions"
                      className="btnDropMenu bgtran btn btn-default"
                      bsPrefix=" "
                    >
                      <BiDotsVertical />
                    </Button>
                  </div> */}
                </div>
            )}
          </Col>
        </Row>
      </Col>
      <Col md={4} xs={12} >
        <div className="paHedFilter govrHedFilter">
          {!isAddMode && (
            <Nav>
              <Button
                bsPrefix=" "
                id="creTag"
                role="button"
                className={
                  activeTabs === "1" ? "myBt active" : "myBt"
                }
                title={"Activity Details"}
                onClick={() => {
                  toggle("1");
                  props.rightEditPanel(true);
                }}
              >
                <LuActivitySquare/>
              </Button>

              <Button
                bsPrefix=" "
                id="creTag"
                role="button"
                className={
                  activeTabs === "2" ? "myBt active" : "myBt"
                }
                title={"Attachments"}
                onClick={() => {
                  toggle("2");
                  props.rightEditPanel(true);
                }}
              >
                <ImAttachment/>
              </Button>
            </Nav>
          )}

          <Link
            // disabled={isSubmitting || !dirty}
            className="myBt fillBtn ctrlKeyPrevent"
            bsPrefix=" "
            to="javascript:void(0)"
            bsStyle="primary"
            title={isAddMode ? "Update " : tr["Save"]}
            onClick={() => handleSubmit()}
            ref={saveObjRef}
          >
            {props.saveActionItemLoading || props.updateActionLoading ? (
              <ImSpinner6 className="icn-spinner" />
            ) : (
              <IoSaveOutline />
            )}
          </Link>
          <Link
            bsPrefix=" "
            to="/quickViewBreakFix"
            className="myBt cancel fillBtn ctrlKeyPrevent"
            title={tr["Cancel"]}
            ref={closeButton}
          >
            <IoClose />
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default Header;
