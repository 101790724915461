
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import StatusFulfillmentHamburger from "./statusFulfillmentHamburger.js";
import { Link, Link as LinkContainer } from "react-router-dom";
import {
  fetchFulfillmentGroupList,
  setBreakfixQuickViewTableCarouselIndex,
  loadFulfillmentList,
  resetBFQVFilters,
} from "../../../actions/breakFix/quickViewAction.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setInitialwibGlobalSearchFilterData } from "../../../actions/globalSearch/globalSearchAction.js";
import {LuActivitySquare, LuClipboardCheck, LuCalendarClock } from "react-icons/lu";
import {ImAttachment, ImSpinner6} from "react-icons/im";
import {FaRegThumbsUp} from "react-icons/fa";
import {FaRegEye} from "react-icons/fa6";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {LiaHourglass} from "react-icons/lia";
import {PiTagBold} from "react-icons/pi";
import { BiDotsVertical } from "react-icons/bi";
import {HiOutlineSpeakerphone, HiOutlineRefresh} from "react-icons/hi";
import {TbSubtask, TbCirclesRelation, TbTextWrap} from "react-icons/tb";
import { GLOBAL } from "_Globals";
import { MyContext } from '_MyContext';
import ExportTo from "../../common/WorkItemBoard/exportTo.js";
import { fulfillmentStatusMapping } from "../../common/helper.js";

class FulfillmentHeader extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
      hamburgerShowHide: false,
      statusHamburgerShowHide: false,
      disabledSave: false,
    };
    this.callbackFun = this.callbackFun.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
  }
  componentDidMount(){
    if (GLOBAL.slaHeadEnable) {
      this.props.setProcessHierarchyState(0);
    }
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}

  statusHamburgerShowHide(event) {
    this.setState({
      statusHamburgerShowHide: !this.state.statusHamburgerShowHide,
    });
  }

  hamburgerShowHide(event) {
    this.setState({ hamburgerShowHide: !this.state.hamburgerShowHide });
  }

  callbackFun(id) {
    this.setState({ hamburgerShowHide: id });
    this.setState({ statusHamburgerShowHide: id });
  }

  goBack() {
    //this.props.resetBFQVFilters();
    //this.props.fetchFulfillmentGroupList();
    // this.setSelectedSearchFilterFulfilment("displayNumber");
    // this.changeSearchInputFulfilment("");
    let searchParams = Object.assign({});
    searchParams.searchByList = "";
    searchParams.multipleValueList = "";
    searchParams.patternList = "";
    searchParams.size = 10;
    searchParams.currentPage = 0;
    searchParams.sortBy = "displayNumber";
    searchParams.orderBy = "desc";
    //this.props.loadFulfillmentList(searchParams, [70, 100], []);
    this.props.setBreakfixQuickViewTableCarouselIndex("1");
    this.props.setInitialwibGlobalSearchFilterData();
    // let str=window.location.href;
    // if(str.charAt(str.length-1) === '#') {
    // 	browserHistory.goBack();
    // 	browserHistory.goBack();
    // }
    // else
    // 	browserHistory.goBack();
  }
  onSaveClick(){
    if(!this.props.disableFieldsForGuestRole){
      this.props.saveFulfill();
      this.props.setState({isVisible: true});
    }
  }

  render() {
    return (
      <Row className="margin-b-15">
        <Col lg={8} xs={12}>
          <Row>
            <Col lg={8} md={7} sm={6} xs={12}>
              <h1 bsClass="" className="sPageHeading">
                {this.props.translator["Fullfilment"]}
                <div className="nowap"><Button
                  title="Open Timeline"
                  onClick={() => {
                    this.props.showTimeline(true);
                  }}
                  className="barDropMenu tinelineicon btn btn-link"
                  variant="link"
                >
                  <LuCalendarClock />
                </Button>
                <ExportTo exportDocument={this.props.exportDocument} title='Export Fulfillment Report' moduleName={'change'}/>
                </div>
                <div className="order position-re">
                  <div className="lnk">{this.props.ordernumber} &gt;&gt;{" "}
                  {this.props.fulfillmentnumber}{" "}</div>
                </div>
              </h1>
        </Col>
        <Col lg={4} md={5} sm={6} xs={12}>
        <div className="toprigLnk status toprigStatusDv">
                <div className="thrlnebtn nwthrDotBtn">
                  <Button
                    title="Statuses"
                    onClick={this.statusHamburgerShowHide.bind(this)}
                    className="btnDropMenu"
                    disabled={this.props.disableFieldsForGuestRole}
                    >
                    {this.props.fulfillmentDetails.status}{" "}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </Button>
                  {this.state.statusHamburgerShowHide ? (
                    <StatusFulfillmentHamburger
                      fieldStatus={this.props.fieldStatus}
                      status={this.props.fulfillmentDetails.status}
                      translator={this.props.translator}
                      fun={this.callbackFun}
                      showNotify={this.props.showNotify}
                      showProvideAttachment={this.props.showProvideAttachment}
                      showCancel={this.props.showCancel}
                      itemId={this.props.itemId}
                      showPutOnHold={this.props.showPutOnHold}
                      fulfillmentDetails={this.props.fulfillmentDetails}
                      showfulfill={this.props.showfulfill}
                      user_id={this.props.user_id}
                      roleIdentification={this.props.roleIdentification}
                      showApprovals={this.props.showApprovals}
                      showReopen={this.props.showReopen}
                      rightEditPanel={this.props.rightEditPanel}
                      resetRightSide={this.props.resetRightSide}
                    />
                  ) : null}
                </div>
                {/* <div className="thrlnebtn margin-l-10">
									<Button title="Actions" onClick={this.hamburgerShowHide.bind(this)} className="btnDropMenu bgtran"><i className="fa fa-ellipsis-v" aria-hidden="true"></i></Button>
									{this.state.hamburgerShowHide ? <FulfillmentHamburger fieldStatus={this.props.fieldStatus} status={this.props.fulfillmentDetails.status} translator={this.props.translator} fun={this.callbackFun} showNotify={this.props.showNotify} showProvideAttachment={this.props.showProvideAttachment} showCancel={this.props.showCancel} itemId={this.props.itemId} showPutOnHold={this.props.showPutOnHold} fulfillmentDetails={this.props.fulfillmentDetails} showfulfill={this.props.showfulfill} user_id={this.props.user_id} roleIdentification={this.props.roleIdentification}/> : null}
								</div> */}
              </div>
              </Col>
          </Row>
        </Col>
        <Col lg={4} xs={12}>

        <div className={`float-r toprigLnk fulfillHeader`}>
            <Nav className="icnlnk riggep float-l" as="ul">
              <Nav.Item
                title={this.props.translator["Activity Details"]}
                eventKey={4}
                as="li"
              >
                <a className={this.props.isProcesshierarchy == 1 ? "active" : ""}
                  href="javascript:void(0)"
                  onClick={() => {
                    this.props.processHierarchy(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});
                  }}
                >
                  <LuActivitySquare/>
                </a>
              </Nav.Item>
              <Nav.Item as="li"  eventKey={1}>
                <a className={this.props.isShowTags == 1 ? "active" : ""} href="javascript:void(0)"
                title={this.props.translator["Custom Attribute"]}
                onClick={() => {
                  this.props.showTags();
                  this.props.rightEditPanel(true);
                  this.props.setState({isVisible: true});
                  }}><PiTagBold className="svgRotate90"/>
                 </a>
              </Nav.Item>
              <Nav.Item
                title={this.props.translator["Attachment"]}
                eventKey={0}
                as="li"
              >
                <a className={this.props.provideattachment == 1 ? "active" : ""}
                  href="javascript:void(0)"
                  onClick={() => {
                    this.props.showProvideAttachment(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});
                  }}
                >
                  <ImAttachment/>
                </a>
              </Nav.Item>
              <Nav.Item
                title={this.props.translator["Tasks"]}
                eventKey={1}
                as="li"
              >
                <a className={this.props.taskeditFlag == 1 ? "active" : ""}
                  href="javascript:void(0)"
                  onClick={() => {
                    this.props.showTasks(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});
                  }}
                >
                  <LuClipboardCheck/>
                </a>
              </Nav.Item>
              {/* <NavItem title={this.props.translator['External Attributes']} eventKey={1} ><span className="sAction bgBtn9 icnexSysAttr"></span></NavItem> */}
              <Nav.Item title="Approvals" eventKey={1} as="li">
                <a className={this.props.approvalFlag == 1 ? "active" : ""}
                  href="javascript:void(0)"
                  onClick={() => {
                    this.props.showApprovalsStatus(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});
                  }}
                >
                  <FaRegThumbsUp/>
                </a>
              </Nav.Item>
              <Nav.Item title={this.props.translator['Watcher Details']} eventKey={1} as="li">
                <a className={this.props.isShowWatcher == 1 ? "active" : ""}
                  href="javascript:void(0)"
                  onClick={() => {
                    this.props.showWatcher(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});
                  }}
                >
                  <FaRegEye/><b className="watcher-count">{this.props.watcherCount}</b>
                </a>
              </Nav.Item>
              <Nav.Item
                title={this.props.translator["SLA Progress"]}
                eventKey={2}
                as="li"
              >
                <a className={this.props.SLA == 1 ? "active": ""}
                  href="javascript:void(0)"
                  onClick={() => {
                    this.props.showSLA(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});
                  }}
                >
                  <LiaHourglass className="hourGlsWidth" />
                </a>
              </Nav.Item>
              <NavDropdown
                className="threeDotul"
                eventKey={3}
                title={
                  <span title="More">
                    <BiDotsVertical/>
                  </span>
                }
                id="threeDotdrpdown"
                as="ul"
              >
                <NavDropdown.Item
                  bsPrefix=" "
                  as="li"
                  title="External Attributes"
                  eventKey={3.1}
                  
                >
                  <Nav.Link href="#" onClick={() => {
                    this.props.showSystemExternalAttributes(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});
                  }}>
											<TbTextWrap />External Attributes
										</Nav.Link>
                  
                </NavDropdown.Item>
                <NavDropdown.Item
                  bsPrefix=" "
                  as="li"
                  title="Related Work Items"
                  eventKey={3.2}
                  
                >
                  <Nav.Link href="#" onClick={() => {
                    this.props.showRelatedWorkItems(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});
                  }}>
											<TbSubtask/>Related Work Items
										</Nav.Link>
                  
                </NavDropdown.Item>
                <NavDropdown.Item
                  bsPrefix=" "
                  as="li"
                  title="Related CI"
                  eventKey={3.3}
                  
                >
                  <Nav.Link href="#" onClick={() => {
                    this.props.showRelateCIs(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});
                  }}>
											<TbCirclesRelation />Related CI
										</Nav.Link>
                  
                </NavDropdown.Item>
                <NavDropdown.Item
                  bsPrefix=" "
                  as="li"
                  title="Notify"
                  eventKey={3.4}
                  
                >
                  <Nav.Link href="#"  onClick={() => {
                    this.props.showNotify(); this.props.rightEditPanel(true); this.props.setState({isVisible: true});
                  }}>
											<HiOutlineSpeakerphone/>Notify
										</Nav.Link>
                  
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>

            {/* <Nav className="icnlnk" as="ul">
              <Nav.Item
                title={this.props.translator["Save"]}
                eventKey={1}
                onClick={() => {
                  this.props.saveFulfill();
                  console.log("disabledSave :::: " + this.props.disabledSave);
                }}
                disabled={
                  this.props.fulfillmentDetails.status === "Closed"
                    ? true
                    : this.props.disabledSave
                }
                as="li"
              >
                <a href={void 0}>
                  <span>
                    <i
                      className={
                        !this.props.disabledSave
                          ? "fa fa-floppy-o"
                          : "fa fa-spinner fa-spin"
                      }
                    ></i>
                  </span>
                </a>
              </Nav.Item>
              <LinkContainer to="/quickViewBreakFix">
                <Nav.Item
                  title={this.props.translator["Close"]}
                  eventKey={2}
                  as="li"
                >
                  <span
                    className="sAction bgBtn17 icnbclose"
                    onClick={this.goBack.bind(this)}
                  ></span>
                </Nav.Item>
              </LinkContainer>
            </Nav> */}


            <Nav as="ul" className="icnlnk">
              <Nav.Item bsPrefix=' ' eventKey={1} as="li">
								<a title={this.props.translator["Refresh"]} href="javascript:void(0)" onClick={() => {this.props.RefreshScreen();}}>
									<HiOutlineRefresh className="refreshFlip" />
								</a>
							</Nav.Item>
							<Nav.Item as="li"
                eventKey={2}
                onClick={this.onSaveClick}
                disabled={
                  this.props.fulfillmentDetails.itemStatus == fulfillmentStatusMapping["Closed"]
                    ? true
                    : this.props.disabledSave
                } 
                title={this.props.translator['Save']}>
									<Link to="javascript:void(0)" ref={(e) => (this.saveObjRef = e)} className="ctrlKeyPrevent">
									<span>{this.props.disabledSave ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/> }</span>
									</Link>
							</Nav.Item>
							
								<Nav.Item as="li" title={this.props.translator['Close']} eventKey={3} >
								<Link to="/quickViewBreakFix" onClick={this.goBack.bind(this)} className="ctrlKeyPrevent" ref={e => this.closeButton = e}>
								<IoClose/>
								</Link></Nav.Item>
						</Nav>

          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ fieldStatus,watcherDetailsFetched }) => {
  let watcherCount = watcherDetailsFetched.length;
  return { fieldStatus,watcherCount };
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchFulfillmentGroupList,
      loadFulfillmentList,
      setBreakfixQuickViewTableCarouselIndex,
      resetBFQVFilters,
      setInitialwibGlobalSearchFilterData
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(FulfillmentHeader);
