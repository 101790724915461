
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row, Col,Carousel, ButtonToolbar, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { loadAdvertisement } from '../../actions/homepage/advertisementAction';
import { bindActionCreators } from 'redux';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Joi = require('joi')

class SxAdvertisement extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isSlickPause: false
		};
		this.isSlickPlay = this.isSlickPlay.bind(this);
		this.isSlickPause = this.isSlickPause.bind(this);
	}

	isSlickPlay() {
		this.setState({ isSlickPause: true });
		this.slider.slickPlay();
	}
	isSlickPause() {
		this.setState({ isSlickPause: false });
		this.slider.slickPause();
	}



	componentWillMount() {
		//this.props.loadAdvertisement();
	}
	renderList(advertisementData) {
		return advertisementData.map((advertObj, index) => {
			//var cls = (index === 0) ? 'active item' : 'item';
			//var cls = (index === 0) ? 'active item' : 'item';
			//	console.log("adverturl::::"+advertObj.advertisementURL);
			let advertisementImg = { backgroundImage: `url(${advertObj.advertisementIconTempURL})` };
			if (advertObj.advertisementURL == "") {
				return (
					<a href="javascript:void(0)" >
						<div className="picture" style={advertisementImg}>
							{/*<source media="(min-width: 650px)" srcSet={advertObj.advertisementIconTempURL} />
					<source media="(min-width: 465px)" srcSet={advertObj.advertisementIconTempURL} />*/}
							{/*<img className="img-responsive display-inline-block" src={advertObj.advertisementIconTempURL} title="Advertisement 1" alt="Advertisement 1" />*/}
							{/* <img className="img-responsive display-inline-block" src={advertObj.advertisementIconTempURL} /> */}
						</div>
					</a>
				);
			}
			else {
				let validated_url = undefined;
				if (advertObj.advertisementURL != null) {
					let namedSchema = Joi.string().regex(/^[^<>}{]+$/);
					let validator_to_add_myQbj = namedSchema.validate(advertObj.advertisementURL);
					if (validator_to_add_myQbj.error == null) {
						validated_url = advertObj.advertisementURL;
					}
					else {
						validated_url = "\\";
					}
				}
				else
					validated_url = "\\";
				return (
					<a href={validated_url} target="_blank">
						<div className="picture" style={advertisementImg}>
							{/*<source media="(min-width: 650px)" srcSet={advertObj.advertisementIconTempURL} />
					<source media="(min-width: 465px)" srcSet={advertObj.advertisementIconTempURL} />*/}
							{/*<img className="img-responsive display-inline-block" src={advertObj.advertisementIconTempURL} title="Advertisement 1" alt="Advertisement 1" />*/}
							{/* <a href={advertObj.advertisementURL} target="_blank"><img className="img-responsive display-inline-block" src={advertObj.advertisementIconTempURL} /></a>
					 */}
							{/* <a href={validated_url} target="_blank"><img className="img-responsive display-inline-block" src={advertObj.advertisementIconTempURL} /></a> */}
						</div></a>
				);
			}

		});
	}
	showAllAdvertisements(records){
		let settings = {
			dots: false,
			pauseOnDotsHover: true,
			pauseOnHover: true,
			arrows: true,
			autoplay: false,
			infinite: true,
			speed: 100,
			initialSlide: 0,
			slidesToShow: 1,
			slidesToScroll: 1,
			appendDots: dots => (
				<div><ul>{dots}<Button className="adbtn" bsPrefix=' '  onClick={this.state.isSlickPause ? this.isSlickPause : this.isSlickPlay} title={this.state.isSlickPause ? this.props.tr["Pause"] : this.props.tr["Play"]}><i className={this.state.isSlickPause ? "fa fa-pause pause" : "fa fa-play-circle"}></i></Button></ul></div>
			),
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						dots: true
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						initialSlide: 1,
						dots: true
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		};		
		if (records.length <= 0) {
			return null
		}
		return(
			<div className="margin-t-5 margin-b-5 container-fluid">
				<Row><Col xs={12}>
				<div className="topAdvtimt" /*className="border-a"*/>
					<Slider {...settings} ref={slider => this.slider = slider}>
						{this.renderList(records)}
					</Slider>
				</div>
				</Col></Row>
			</div>
		)
	}
	render() {
		return (
			this.showAllAdvertisements(this.props.advertisement)
		);
	}
}
export function mapStateToProps({ advertisement }) {
	return { advertisement };
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadAdvertisement }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(SxAdvertisement);
