import moment from "moment";

const mappings = {
  eq: "{0} = {1}",
  "=": "{0} = {1}",
  neq: "{0} != {1}",
  isnull: "{0} IS NULL",
  isnotnull: "{0} IS NOT NULL",
  lt: "{0} < {1}",
  lte: "{0} <= {1}",
  gt: "{0} > {1}",
  gte: "{0} >= {1}",
  startswith: "{0} LIKE '{1}%'",
  doesnotstartwith: "{0} NOT LIKE '{1}%'",
  contains: "{0} LIKE '%{1}%'",
  doesnotcontain: "{0} NOT LIKE '%{1}%'",
  isempty: "{0} = ''",
  isnotempty: "{0} != ''",
  between: "{0} BETWEEN {1} AND {2}",
};

const getEpochTimestamp = (dateValue) => {
  if (!dateValue) return null;
  
  let epochMs;
  
  if (typeof dateValue === 'number') {
    // If already a number, ensure it's in milliseconds
    epochMs = dateValue.toString().length === 10 ? dateValue * 1000 : dateValue;
  } else if (dateValue instanceof Date) {
    // Convert Date object to epoch
    epochMs = dateValue.getTime();
  } else if (typeof dateValue === 'string') {
    // Parse string date to epoch
    const parsed = moment(dateValue);
    if (!parsed.isValid()) return null;
    epochMs = parsed.valueOf();
  } else {
    return null;
  }

  return epochMs;
};

const escapeValue = (value) => {
  if (typeof value === "string") {
    return value.replace(/'/g, "''");
  }
  return value;
};

export const toSQLExpression = (filter) => {
  if (!filter) return;

  const { filters, logic = "AND" } = filter;
  const result = [];

  for (const subFilter of filters) {
    const { field, value, operator, filters: nestedFilters } = subFilter;

    if (nestedFilters) {
      result.push(toSQLExpression(subFilter));
    } else {
      const mapping = mappings[operator];
      if (!mapping) {
        throw new Error(`Unsupported operator: ${operator}`);
      }

      let formattedValue;

      // Handle date operations
      if (operator === "between" && typeof value === "object") {
        if (!value || !value.start || !value.end) {
          throw new Error(`Invalid value for 'between': ${JSON.stringify(value)}`);
        }

        const startEpoch = getEpochTimestamp(value.start);
        const endEpoch = getEpochTimestamp(value.end);
        
        if (!startEpoch || !endEpoch) {
          throw new Error(`Invalid date format for 'between': ${JSON.stringify(value)}`);
        }

        formattedValue = mapping
          .replace("{0}", field)
          .replace("{1}", startEpoch)
          .replace("{2}", endEpoch);
      } else if (["eq", "neq", "lt", "lte", "gt", "gte"].includes(operator)) {
        // Try to convert to epoch if it's a date value
        const epochTimestamp = getEpochTimestamp(value);
        if (epochTimestamp !== null) {
          formattedValue = mapping
            .replace("{0}", field)
            .replace("{1}", epochTimestamp);
        } else {
          // If not a valid date, treat as regular value
          formattedValue = mapping
            .replace("{0}", field)
            .replace("{1}", typeof value === 'string' ? `'${escapeValue(value)}'` : value);
        }
      } else {
        // Handle non-date operations
        formattedValue = mapping
          .replace("{0}", field)
          .replace("{1}", typeof value === 'string' ? `'${escapeValue(value)}'` : value);
      }

      result.push(formattedValue);
    }
  }

  const combined = result.join(` ${logic.toUpperCase()} `);
  return result.length > 1 ? `(${combined})` : combined;
};