
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react';
import { Table, Form, ButtonToolbar, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Field, change, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { TypeaheadandDropdown, _inputField } from '../../../../common/formFields';
import TableComponent from '../../../../common/ReactTable/TableComponent';
import {getUserRoles} from "../../../../../actions/foundation/userAction";
import { useParams } from "react-router";
import axios from "axios";
import Swal from 'sweetalert2';


const GroupList = (props) => {
  const tr = useSelector((state) => state.spcmReducer.tr);
    const [roleError, setRoleError] = useState("");
    const [roleData, setRoleData] = useState([])
    const [associatedRolesValue, setAssociatedRolesValue] = useState([]);
    const [selectedRole, setSelectedRole] = useState([]);
    const roleDataList = useSelector((state) => state.userRolesRed);
    const groupDataList = useSelector((state) => state.userGroupsRed);

    const dispatch = useDispatch();
    const params = useParams();
    let userId = params.id;
    let { handleSubmit } = props;
    let isValid = true;

    useEffect(() => {
            dispatch(getUserRoles(userId));     
            axios.get("/api/viewAssociatedUserRolesViaAjaxForUser/"+userId)
            .then((resp) => {
                if (resp.status == 200) {
                   
                    setSelectedRole([]);
                }
            setAssociatedRolesValue(resp.data);
        })   
    }, [groupDataList])
    
    useEffect(() =>{
      if(roleDataList && roleDataList.length > 0){
        setRoleData(roleDataList);
      }
      else{
        setRoleData([]);
      }      
    }, [roleDataList])
    console.log("roleDataList+++++++++++++++++",roleDataList)

    const saveAssociatedRoles = () => {

      
        
        if (selectedRole.length === 0) {
             setRoleError("error")
             isValid = false;

         }

         let roles = []
         let payload = {}
         selectedRole.forEach((values) => {
           let val = {
              roleId: values.id,
              userId : userId
             }
             roles.push(val);
           })
        payload = {
            userMemberList: roles
        }

        console.log("CheckGroupsList",payload)
        if(isValid === true){
        axios
          .post("/api/saveAddAssociateRolesModalDataForUser", payload)
          .then((response) => {
            console.log("ROLERESPONSE",response.data);
            axios.get("/api/viewAssociatedUserRolesViaAjaxForUser/"+userId)
                .then((resp) => {
                    if (resp.status == 200) {
                       
                        setSelectedRole([]);
                    }
                setAssociatedRolesValue(resp.data);
                dispatch(getUserRoles(userId));
            })
          })
        }
    };

    const CellName = (props) => {
        let item = props.row;
        return (
        <div className="text-c">
            <i title="Disassociate" onClick={()=>{
            disassociateRoles(item)
            }} className="fa fa-chain-broken"></i>
        </div>
        )
    };

    const disassociateRoles = (item) => {
        console.log("GroupDelete", item)
        let roles = []
      let payload = {}
        let val = {
          roleId: parseInt(item.roleId, 10),
          // group_id: parseInt(groupid)
          userId : userId

        }
        roles.push(val);
        console.log("roles", roles)
    
      payload = {
        userMemberList: roles
      }
      console.log("CheckRoles", payload);
        Swal.fire({
            title: ["Are you sure you want to disassociate this role?"],
            width: 550,
            padding: '15px',
            showDenyButton: true,
            confirmButtonText: tr['Yes'],
            denyButtonText: tr['No'],
          }).then((result) => {
            if (result.isConfirmed) {
              axios.post("/api/deleteViewAssociatedRolesModalDataForUser",payload).then((resp) => {
                axios.get("/api/viewAssociatedUserRolesViaAjaxForUser/" + userId)
                  .then((resp) => {
                    if (resp.status == 200) {
                      setSelectedRole([]);
                    }
                    setAssociatedRolesValue(resp.data);
                  })
                dispatch(getUserRoles(userId));
              })
            } 
          })
    }
    const tableColumns = [
        {
            name: "ID",
            selector: (row) => row.roleId,
            sortable: true,
        },
        {
            name: "Role",
            sortable: true,
            cell: (row) => (<div title={row.roleName}>{row.roleName}</div>)
         },
        {
            name: "Role Description",
          sortable: true,
          cell: (row) => (<div title={row.roleDescription}>{row.roleDescription}</div>)
         },
        {
            name: "Manual Association",
            cell: (row) => row.inheritedFrom,
          sortable: true,
         },
        {
            name: "Inherited From group",
            cell: (row) => row.groupList,
          sortable: true,
         },
      //    {
      //     name: "Status",
      //     cell: (row) => row.role_Active == 'Y'?"Active":"Inactive",
      //   sortable: true,
      //  },  
        {
			name: "Disassociate",
			sortable: false,
			cell: (row) => (
			  <CellName row={row} 
			  />
			),
			ignoreRowClick: true,
		}
    ];
    const filterColumns = ["roleId","roleName"];
    const onRowSelect = () => {
        
    }

    const onGroupSelection = (selectedRole) => {
        if (selectedRole.length > 0) { 
            setSelectedRole(selectedRole)
            dispatch(change('GroupList', 'groupId', selectedRole))
            setRoleError("")
          } else {
            setSelectedRole([]);
          }
    }
 
    const onCrossClickGroup = () => {
        setSelectedRole([]);
        setRoleError("");
        isValid = false;

    }

    return (
        <>
             {props.show &&<><Form.Group className="form-group">
                <Form.Label>Role Name</Form.Label>
                <Field
                  name="roleId"
                  className="form-control"
                  component={TypeaheadandDropdown}
                  onSelection={onGroupSelection}
                  options={roleData}
                  selectedOptions={selectedRole}
                  errorClass={roleError === "error" ? "error" : ""}
                  onCrossClick={onCrossClickGroup}
                  multiple={true}
                />
            </Form.Group>

            <ButtonToolbar className="black margin-t-5">
            <Button className="rgSidrkBtn smallBtn"
                onClick={handleSubmit(saveAssociatedRoles)}>
               Add
                </Button>
            </ButtonToolbar></>}

            <div className="catBordDv leftSeardv integrateHubTable">
            {associatedRolesValue && <div className='f-size-16 margin-t-20 margin-b-10'>Associated Roles</div>}
            <TableComponent
                data={Array.isArray(associatedRolesValue)? associatedRolesValue :[]}
                columns={tableColumns}
                headerColumns={filterColumns}
                onRowSelect={onRowSelect}
                uniqueKey={'roleId'}
                paginationText = {"Show"}
                />
            </div>
        </>
    )
}

export default reduxForm({
    form: 'GroupList',
    destroyOnUnmount: false,
    enableReinitialize: true
})(GroupList);