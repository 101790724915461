
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react';
import { Table, Form, ButtonToolbar, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Field, change, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { TypeaheadandDropdown, _inputField } from '../../../../common/formFields';
import TableComponent from '../../../../common/ReactTable/TableComponent';
import {getNonRestrictAccessAction} from "../../../../../actions/foundation/userAction";
import { useParams } from "react-router";
import axios from "axios";
import Swal from 'sweetalert2';

const GroupList = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const [restrictedAccessErr, setRestrictedAccessErr] = useState("");
    const [restrictedData, setRestrictedAccessData] = useState([])
    const [restrictedValues, setRestrictedValues] = useState([]);
    const [selectedFunction, setSelectedFunction] = useState([]);
    const accessRestrictedReducerData = useSelector((state) => state.accessRestrictRed);

    const dispatch = useDispatch();
    const params = useParams();
    let userId = params.id;
    let { handleSubmit } = props;
    let isValid = true;

    useEffect(() => {
        dispatch(getNonRestrictAccessAction(userId));
    }, [])

    useEffect(() => {
        if (accessRestrictedReducerData && accessRestrictedReducerData.length > 0) {
            setRestrictedAccessData(accessRestrictedReducerData);
        }
        else{
          setRestrictedAccessData([]);
        }
    }, [accessRestrictedReducerData])

  useEffect(() => {
    axios.get("/api/viewUserFunctionsNotAssociatedViaAjaxForUser/"+userId)
    .then((resp) => {
        if (resp.status == 200) {
           
            setSelectedFunction([]);
        }
    setRestrictedValues(resp.data);
})   
}, [])
  
    const onFunctionSelection = (selectedFunction) => {
        if (selectedFunction.length > 0) { 
            setSelectedFunction(selectedFunction)
            dispatch(change('GroupList', 'functionId', selectedFunction))
            setRestrictedAccessErr("")
          } else {
            setSelectedFunction([]);
          }
        }
 
    const onCrossClickGroup = () => {
        setSelectedFunction([]);
        setRestrictedAccessErr("");
        isValid = false;
    }

    const tableColumns = [
        {
            name: "ID",
            selector: (row) => row.functionId,
            sortable: true,
        },
        {
            name: "Name",
          sortable: true,
          cell: (row) => (<div title={row.functionName}>{row.functionName}</div>)
         },
        {
            name: "Description",
          sortable: true,
          cell: (row) => (<div title={row.description}>{row.description}</div>)
         },       
        {
			name: "Disassociate",
			sortable: false,
			cell: (row) => (
			  <CellName row={row} 
			  />
			),
			ignoreRowClick: true,
		}
    ];
    const filterColumns = ["functionId","functionName"];
    const onRowSelect = () => {
        
    }
    const CellName = (props) => {
        let item = props.row;
        return (
        <div className="text-c">
            <i title="Disassociate" onClick={()=>{
            disassociateFunction(item)
            }} className="fa fa-chain-broken"></i>
        </div>
        )
    };
    const disassociateFunction = (item) => {
        let object = []
      let payload = {}
        let val = {
            functionId: parseInt(item.functionId, 10),
            userId : userId

        }
        object.push(val);
   
      payload = {
        userFunctionList: object
      }
        Swal.fire({
            title: ["Are you sure you want to disassociate this role?"],
            width: 550,
            padding: '15px',
            showDenyButton: true,
            confirmButtonText: tr['Yes'],
            denyButtonText: tr['No'],
          }).then((result) => {
            if (result.isConfirmed) {
              axios.post("/api/unRestrictAssociatedFunctionModalDataForUser",payload).then((resp) => {
                axios.get("/api/viewUserFunctionsNotAssociatedViaAjaxForUser/"+userId)
                .then((resp) => {
                    if (resp.status == 200) {
                       
                        setSelectedFunction([]);
                    }
                setRestrictedValues(resp.data);
                dispatch(getNonRestrictAccessAction(userId));
            })   
              })
            } 
          })
    }
    const saveRestrictedAccess = () => {
        if (selectedFunction.length === 0) {
             setRestrictedAccessErr("error")
             isValid = false;
         }

         let restrictVals = []
         let payload = {}
         selectedFunction.forEach((values) => {
           let val = {
            functionId: values.id,
            userId : userId
             }
             restrictVals.push(val);
           })
        payload = {
            userFunctionList: restrictVals
        }

        if(isValid === true){
        axios
          .post("/api/restrictAssociatedFunctionModalDataForUser", payload)
          .then((response) => {
            axios.get("/api/viewUserFunctionsNotAssociatedViaAjaxForUser/"+userId)
                .then((resp) => {

                    if (resp.status == 200) {                       
                        setSelectedFunction([]);
                    }
                setRestrictedValues(resp.data);
                dispatch(getNonRestrictAccessAction(userId));

            })
          })
        }
    };
    return (
        <>
             {props.show &&<><Form.Group className="form-group">
                <Form.Label>Name</Form.Label>
                <Field
                  name="functionId"
                  className="form-control"
                  component={TypeaheadandDropdown}
                  onSelection={onFunctionSelection}
                  options={restrictedData}
                  selectedOptions={selectedFunction}
                  errorClass={restrictedAccessErr === "error" ? "error" : ""}
                  onCrossClick={onCrossClickGroup}
                  multiple={true}
                />
            </Form.Group>

            <ButtonToolbar className="black margin-t-5">
            <Button className="rgSidrkBtn smallBtn"
                onClick={handleSubmit(saveRestrictedAccess)}>
               Add
                </Button>
            </ButtonToolbar></>}

            <div className="catBordDv leftSeardv integrateHubTable">
            {restrictedValues && <div className='f-size-16 margin-t-20 margin-b-10'>Restrict Funtion Access</div>}
            <TableComponent
                data={Array.isArray(restrictedValues)? restrictedValues :[]}
                columns={tableColumns}
                headerColumns={filterColumns}
                onRowSelect={onRowSelect}
                uniqueKey={'functionId'}
                paginationText = {"Show"}
                />
            </div>
        </>
    )
}


export default reduxForm({
    form: 'GroupList',
    destroyOnUnmount: false,
    enableReinitialize: true
})(GroupList);