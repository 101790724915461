
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
import { GLOBAL } from '_Globals';

const api = axios.create({
    headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

export function loadAllScheduleList(params = {}, showAllRecords = false, status = '') {
    return (dispatch) => {
        if (showAllRecords) {
            params["page"] = 0;
            params["size"] = 5000;
        }
        if(status != '') {
            params["status"] = status;
        }
        let queryParams = "";
        params["sort"] = "createdOn,desc";
        queryParams += "?";
        Object.keys(params).forEach((key, index) => {
            queryParams += key + "=" + params[key];
            if (index !== Object.keys(params).length - 1) {
                queryParams += "&";
            }
        })
        if(!showAllRecords){dispatch({ type: 'LOAD_SCHEDULE_LIST_LOADER_IN_PROGRESS' });}
        api.get(GLOBAL.loadScheduleListUrl + queryParams)
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                if (response.status == 204) {
                    dispatch(loadAllScheduleListSuccess([]));
                } else {
                    dispatch(loadAllScheduleListSuccess(response.data.data));
                    dispatch({
                        type: 'LOAD_ALL_SCHEDULE_LIST_META',
                        payload: {
                            currentPage: response.data.currentPage,
                            numberOfItems: response.data.numberOfItems
                        }
                    });
                }
                if(!showAllRecords){ dispatch({ type: 'LOAD_SCHEDULE_LIST_LOADER_FINISHED' }); }
            })
            .catch((err) => {
                console.log(err);
                if(!showAllRecords){
                    dispatch({ type: 'LOAD_SCHEDULE_LIST_LOADER_FINISHED' });
                }
                
            });
    };
}

export function loadAllScheduleListSuccess(payload) {
    return {
        type: 'LOAD_ALL_SCHEDULE_LIST_SUCCESS',
        payload
    };
}

export function loadScheduleDetailsByScheduleId(scheduleId) {
    return (dispatch) => {
        dispatch({ type: 'LOAD_SCHEDULE_LIST_LOADER_IN_PROGRESS' });
        api.get(GLOBAL.loadScheduleDetailsUrl + '/' + scheduleId)
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                if (response.status == 204) {
                    dispatch(loadScheduleDetailsByScheduleIdSuccess([]));
                } else {
                    let initialValuesObj = {};
                    initialValuesObj.scheduleId = response.data.data.scheduleId;
                    initialValuesObj.scheduleName = response.data.data.scheduleName;
                    initialValuesObj.scheduleType = response.data.data.scheduleType;
                    initialValuesObj.timeZone = response.data.data.timeZone;
                    initialValuesObj.onCallStatus = response.data.data.status;
                    dispatch(loadScheduleDetailsByScheduleIdSuccess(initialValuesObj));
                }
                dispatch({ type: 'LOAD_SCHEDULE_LIST_LOADER_FINISHED' });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: 'LOAD_SCHEDULE_LIST_LOADER_FINISHED' });
            });
    };
}

export function loadScheduleDetailsByScheduleIdSuccess(payload) {
    return {
        type: 'LOAD_SCHEDULE_DETAILS_BY_SCHEDULE_ID_SUCCESS',
        payload
    };
}

export function emptyScheduleDetailsByScheduleId() {
    return (dispatch) => {
        dispatch({ type: 'EMPTY_SCHEDULE_DETAILS_BY_SCHEDULE_ID_SUCCESS' });
    };
}

export function loadScheduleAssociationDataByScheduleId(scheduleId) {
    return (dispatch) => {
        dispatch({ type: 'LOAD_SCHEDULE_ASSOCIATION_LOADER_IN_PROGRESS' });
        api.get(GLOBAL.loadScheduleAssociationDataUrl + '?scheduleId=' + scheduleId + "&sort=createdOn,desc")
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                dispatch(loadScheduleAssociationDataByScheduleIdSuccess(response.data.data.content));
                dispatch({ type: 'LOAD_SCHEDULE_ASSOCIATION_LOADER_FINISHED' });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: 'LOAD_SCHEDULE_ASSOCIATION_LOADER_FINISHED' });
            });
    };
}

export function loadScheduleAssociationDataByScheduleIdSuccess(payload) {
    return {
        type: 'LOAD_SCHEDULE_ASSOCIATION_LIST_SUCCESS',
        payload
    };
}

export function deleteScheduleAssociationData(saId, callback) {
    return (dispatch) => {
        api.delete(GLOBAL.deleteScheduleAssociationDataUrl + '/' + saId)
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                callback();
            })
            .catch((err) => {
                console.log(err);
            });
    };
}

export function loadRelatedScheduleList(scheduleId) {
    return (dispatch) => {
        dispatch({ type: 'LOAD_SCHEDULE_ASSOCIATION_LOADER_IN_PROGRESS' });
        api.get(GLOBAL.loadRelatedScheduleDataUrl + '/' + scheduleId)
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                dispatch(loadRelatedScheduleListSuccess(response.data.data ?? []));
                dispatch({ type: 'LOAD_SCHEDULE_ASSOCIATION_LOADER_FINISHED' });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: 'LOAD_SCHEDULE_ASSOCIATION_LOADER_FINISHED' });
            });
    };
}

export function loadRelatedScheduleListSuccess(payload) {
    return {
        type: 'LOAD_RELATED_SCHEDULE_LIST_SUCCESS',
        payload
    };
}

export function deleteRelatedScheduleData(asId, callback) {
    return (dispatch) => {
        api.delete(GLOBAL.deleteRelatedScheduleDataUrl + '/' + asId)
            .then((response) => {
                if (!response) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                callback();
            })
            .catch((err) => {
                console.log(err);
            });
    };
}

export function emptyScheduleLists() {
    return (dispatch) => {
        dispatch({ type: 'EMPTY_SCHEDULE_LISTS_SUCCESS' });
    };  
}