
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { OverlayTrigger, Popover, Button, Table } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { loadChangeTotalApprovalDetails } from "../../../actions/changeManagement/changeTotalApprovalAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import ListLoader from "../../common/loaders/ListLoader";
import { getApprovals } from "../../../actions/spcmActions";
import { loadApprovalHistory, fetchApprovalsQuesAns } from "../../../actions/MyApprovals/MyApprovalActions";
import ApprovalHistory from "../../common/WorkItemBoard/approvalHistory/ApprovalHistory";
import {IoClose} from "react-icons/io5";
import { fulfillmentStatusMapping, sanitizeElements } from "../../common/helper";
const MoreArrow = (
  <i title="More" className="fa fa-angle-double-down f-size-15"></i>
);

class FulfillmentApprovalStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserData: false,
      displayMessage: "",
      showApprovalLoader: false,
    };
    this.individualPopoverTable = this.individualPopoverTable.bind(this);
    this.groupPopoverTable = this.groupPopoverTable.bind(this);
    this.checkRenderApproverData = this.checkRenderApproverData.bind(this);
  }

  componentWillMount() {
    console.log("this.props.fulfillmentDetails", this.props.fulfillmentDetails);
    let itemId = this.props.itemId;
    // this.props.loadChangeTotalApprovalDetails(itemId, "10", "N");
    this.checkRenderApproverData(
      this.props.fulfillmentDetails.offeringIdSx,
      "0",
      "0",
      this.props.fulfillmentDetails.itemId
    );
   this.props.loadApprovalHistory(this.props.fulfillmentDetails.itemId, "10");
   this.props.fetchApprovalsQuesAns(this.props.fulfillmentDetails.itemNumber, "10");
  }
  // componentWillReceiveProps(nextProps) {
  //     console.log("nextprppsss outside",nextProps.fulfillmentDetails,this.props.fulfillmentDetails);
  //     console.log("trueorfalse",nextProps.fulfillmentDetails==this.props.fulfillmentDetails)
  // 	if(nextProps.fulfillmentDetails && nextProps.fulfillmentDetails!=this.props.fulfillmentDetails){
  //         console.log("nextprppsss",nextProps.fulfillmentDetails.offeringIdSx);
  //         this.props.getApprovals(nextProps.fulfillmentDetails.offeringIdSx);
  // 	}
  // }

  checkRenderApproverData(offeringId, ciId, ciClass, itemid) {
    this.setState({ showApprovalLoader: true });
    axios
      .get(
        "/api/approval/ruleCount/offeringId/" +
          offeringId +
          "/moduleId/" +
          "10" +
          "/ciId/" +
          ciId +
          "/ciclass/" +
          ciClass +
          "/requestId/" +
          itemid
      )
      .then((resp) => {
        if ((resp.status == 200)) {
          this.setState({ showApprovalLoader: false });
          if (resp.data.count >= 1) {
            this.props.loadChangeTotalApprovalDetails(
              this.props.itemId,
              "10",
              "N"
            );
            this.setState({ displayMessage: "" });
          } 
          else if (resp.data.count == -1) {
            this.setState({ showApprovalLoader: false });
            this.setState({
              displayMessage:
              this.props.tr["There are no active approvals as the request is cancelled"],
            });
          }
          else {
            if(this.props.status==='Submitted'){
            this.setState({ showApprovalLoader: false });
            this.setState({
              displayMessage:
                this.props.tr["This record is currently under review to determine if approval is required. Please check back later for updates"],
            });
            } else{
                this.setState({ showApprovalLoader: false });
                this.setState({
                  displayMessage:
                    this.props.tr["This service does not require any approval"],
                });}
            
          }
        }
      });
  }

  renderAttList(relatedAppHistoryDataLog) {
    //console.log(attributeData)
    if (!relatedAppHistoryDataLog) {
      return null;
    }
    return relatedAppHistoryDataLog.map((relatedAppHistoryData, i) => {
      return (
        <tr>
          <td>{relatedAppHistoryData.levelname}</td>
          <td>{relatedAppHistoryData.signatures}</td>
          <td>{relatedAppHistoryData.status}</td>
          <td>{relatedAppHistoryData.modifieddate}</td>
        </tr>
      );
    });
  }
  individualPopoverTable(tabledata) {
    return (
      <tr>
        <td>{tabledata.name}</td>
        <td
        style={{ whiteSpace: 'pre-line' }}
        dangerouslySetInnerHTML={{
          __html: sanitizeElements(tabledata.reason)
        }}
      />
      </tr>
    );
  }

  groupPopoverTable(tabledata) {
    return tabledata.map((data, index) => {
      let statusapp = data.status;
      if (statusapp == "null" || statusapp == "" || statusapp == null)
        statusapp = "Pending";
      else statusapp = data.status;

      return (
        <tr>
          <td>{data.name}</td>
          <td>
            {statusapp}
            {data.approvedby != null ? " (" + data.approvedby + ")" : ""}
          </td>
          <td>{data.date}</td>
          <td
            style={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{
              __html: sanitizeElements(data.reason)
            }}
          />
        </tr>
      );
    });
  }

  renderApproverQuestions(questionsItem) {
    if (!questionsItem) {
      return (
        <tr>  
          <td colspan="4">
            <p>
              <center>{this.props.tr["Data not available."]}</center>
            </p>
          </td>
        </tr>
      );
    } else {
    return questionsItem.map((question) => {
      return (
        <tr>
          <td>{question.levelId}</td>
          <td>{question.approver}</td>
          <td>{question.approvalQuestion}</td>
          <td>{question.approvalAnswer}</td>
        </tr>
      );
    });
  }
  }
  renderAppStatusDataLog(relatedAppHistoryDataLog) {
    // document.body.style.overflow = "hidden";
    if (!relatedAppHistoryDataLog) {
      return (
        <tr>
          <td width="100%">
            <p>
              <center>{this.props.translator["Data not available."]} </center>
            </p>
          </td>
        </tr>
      );
    } else {
      return relatedAppHistoryDataLog.map((relatedAppHistoryData, index) => {
        let statusapp = relatedAppHistoryData.status;
        if (statusapp == "null" || statusapp == "" || statusapp == null)
          statusapp = "Pending";
        else statusapp = relatedAppHistoryData.status;

        let statusappColor = "";
        if (statusapp == "Approved") {
          statusappColor = "greenDot";
        } else if (statusapp == "Rejected") {
          statusappColor = "redDot";
        } else if (statusapp == "Pending") {
          statusappColor = "blueDot";
        } else if (statusapp == "Referred Back") {
          statusappColor = "orangeDot";
        }

        let approverDetails = "";
        approverDetails =
          relatedAppHistoryData.approverDetails != null
            ? relatedAppHistoryData.approverDetails
            : "";

        let indData = {
          name: relatedAppHistoryData.signatures,
          reason: relatedAppHistoryData.rejectreason,
        };

        let individualPopoverRej = (
          <Popover
            id={index + "ind"}
            title="Rejection Reason"
            className="approvalPopup posCustom "
          >
            <Table
              responsive
              striped
              bordered
              condensed
              hover
              className="f-size-13"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>{this.individualPopoverTable(indData)}</tbody>
            </Table>
          </Popover>
        );
        
        let indApprovalData = { name: relatedAppHistoryData.signatures };
        relatedAppHistoryData.itemStatus == fulfillmentStatusMapping["Referred Back"]
          ? (indApprovalData.reason = relatedAppHistoryData.referbackComments)
          : (indApprovalData.reason = relatedAppHistoryData.approvalComments);
        let individualPopoverApproval = (
          <Popover
            id={index + "ind" + index}
            title={
        relatedAppHistoryData.itemStatus == fulfillmentStatusMapping["Referred Back"]
                ? "Referred Back Comment"
                : "Approver Comment"
            }
            className="approvalPopup posCustom "
          >
            <Table
              responsive
              striped
              bordered
              condensed
              hover
              className="f-size-13"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>{this.individualPopoverTable(indApprovalData)}</tbody>
            </Table>
          </Popover>
        );

        let groupPopoverRej = (
          <Popover
            id={index + "groupRej"}
            title="Rejection Reason"
            className="approvalPopup posCustom "
          >
            <Table
              responsive
              striped
              bordered
              condensed
              hover
              className="f-size-13"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Date & Time</th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>
                {approverDetails == "" ? (
                  <tr>
                    <td colspan="4" align="centercenter">
                      <div>
                        <p>
                          <center>
                            {this.props.translator["No Data Available"]}
                          </center>
                        </p>
                      </div>
                    </td>
                  </tr>
                ) : (
                  this.groupPopoverTable(approverDetails)
                )}
              </tbody>
            </Table>
          </Popover>
        );

        let groupPopover = (
          <Popover
            id={index + "group"}
            title={
              relatedAppHistoryData.adhocApproverFlag == "Y"
                ? this.props.tr["Adhoc Approvals"]
                : relatedAppHistoryData.grpsignatures == 'Parallel Approvers'?["Parallel Approvers"]:this.props.tr["Group Approvals"]
            }
            className="approvalPopup posCustom "
          >
            <Table
              responsive
              striped
              bordered
              condensed
              hover
              className="f-size-13"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Date & Time</th>
                  <th>Comments</th>
                </tr>
              </thead>
              <tbody>
                {approverDetails == "" ? (
                  <tr>
                    <td colspan="4">
                      <p>
                        <center>
                          {this.props.translator["Data not available."]}
                        </center>
                      </p>
                    </td>
                  </tr>
                ) : (
                  this.groupPopoverTable(approverDetails)
                )}
              </tbody>
            </Table>
          </Popover>
        );

        return (
          <tr>
            <td>
              {statusapp == "Rejected" ? (
                relatedAppHistoryData.approverType != "Group" &&
                relatedAppHistoryData.approverType != "GBO" &&
                relatedAppHistoryData.adhocApproverFlag != "Y" ? (
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="bottom"
                    overlay={individualPopoverRej}
                  >
                    <Button
                      className={statusappColor}
                      title={statusapp}
                      variant="success"
                    ></Button>
                  </OverlayTrigger>
                ) : relatedAppHistoryData.approverType == "Group" ||
                  relatedAppHistoryData.adhocApproverFlag == "Y" ? (
                  <span
                    className={statusappColor}
                    title={this.props.tr[statusapp]}
                  ></span>
                ) : (
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="bottom"
                    overlay={groupPopoverRej}
                  >
                    <Button
                      className={statusappColor}
                      title={statusapp}
                      variant="success"
                    ></Button>
                  </OverlayTrigger>
                )
              ) : relatedAppHistoryData.approverType != "Group" &&
                relatedAppHistoryData.approverType != "GBO" &&
                relatedAppHistoryData.adhocApproverFlag != "Y" ? (
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom"
                  overlay={individualPopoverApproval}
                >
                  <Button
                    className={statusappColor}
                    title={statusapp}
                    variant="success"
                  ></Button>
                </OverlayTrigger>
              ) : (
                <span className={statusappColor} title={statusapp}></span>
              )}
            </td>

            <td>{relatedAppHistoryData.levelname}</td>
            <td>
              {relatedAppHistoryData.approverType.trim() != "Group" &&
              relatedAppHistoryData.approverType.trim() != "GBO" &&
              relatedAppHistoryData.adhocApproverFlag != "Y"
                ? relatedAppHistoryData.signatures == "null" ||
                  relatedAppHistoryData.signatures == null
                  ? "Approver does not exist"
                  : relatedAppHistoryData.signatures
                : relatedAppHistoryData.signatures}
              {relatedAppHistoryData.approverType.trim() == "Group" ||
              relatedAppHistoryData.approverType.trim() == "GBO" ||
              relatedAppHistoryData.adhocApproverFlag == "Y" ? (
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom"
                  overlay={groupPopover}
                >
                  <Button className="btninfo">i</Button>
                </OverlayTrigger>
              ) : (
                ""
              )}
            </td>
            <td>{relatedAppHistoryData.lastUpdatedDate}</td>
          </tr>
        );
      });
    }
  }

  render() {
    return this.state.showApprovalLoader ? (
      <div>
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
          <ul>
            <li>
            <button
                type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                this.props.changeRightView("allClose");
                }}
            >
                <IoClose/>
            </button>
            </li>
          </ul>
        </div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {this.props.translator["Approval"]}
          </div>
        </div>
        <div className="rBoxGap">
          <ListLoader />
        </div>
      </div>
    ) : (
      <div>
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
          <ul>
            <li>
            <button
                type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                this.props.changeRightView("allClose");
                }}
            >
                <IoClose/>
            </button>
            </li>
          </ul>
        </div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {this.props.translator["Approval"]}
          </div>
        </div>
        <div className="rBoxGap">
          <div className="rBoxTabs">
            <Tabs
              className="rBoxTabs"
              activeKey={this.state.tabKey}
              onSelect={this.handleSelect}
              id="tabId"
            >
              <Tab eventKey={1} title={this.props.translator["Approval"]}>
                {this.state.displayMessage != "" ? (
                  <div>{this.state.displayMessage}</div>
                ) : this.props.showLoader ? (
                  <div>
                    <ListLoader />
                  </div>
                ) : this.props.changeTotalApprovalDetails.length > 0 ? (
                  <Table
                    responsive
                    striped
                    bordered
                    condensed
                    hover
                    className="f-size-13"
                  >
                    <thead>
                      <tr>
                        <th width="10%"></th>
                        <th width="16%">Level</th>
                        <th width="44%">Approver</th>
                        <th width="30%">Date & Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.renderAppStatusDataLog(
                        this.props.changeTotalApprovalDetails
                      )}
                    </tbody>
                  </Table>
                ) : (
                  <div>
                    {
                      this.props.tr[
                        "Approval is being triggered. Please try later!"
                      ]
                    }
                  </div>
                )}
              </Tab>
              <Tab eventKey={2} title={this.props.translator["Question"]}>
                <Table
                responsive
                striped
                bordered
                condensed
                hover
                className="f-size-13">
                <thead>
                  <tr>
                        <th width="10%">Level</th>
                        <th width="20%">Approver</th>
                        <th width="35%">Question</th>
                        <th width="35%">Answer</th>
                      </tr>
                  </thead>
                  <tbody>
                    {this.renderApproverQuestions(this.props.fetchApprovalQuestionsDetails)}
                  </tbody>
                </Table>
              </Tab>
              <Tab
                eventKey={6}
                title={`${this.props.translator["Audit Log"]}`}
              >
                <ApprovalHistory
                  approvalAuditLogData={this.props.approvalHistoryData}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps({
  state,
  spcmReducer,
  changeTotalApprovalDetails,
  rightMenuShowLoader,
  myOrderOrderDetails,
  approvalHistoryData,
  fetchApprovalQuestionsDetails
}) {
  return {
    changeTotalApprovalDetails,
    spcmReducer,
    rightMenuShowLoader,
    showLoader: rightMenuShowLoader.loading,
    tr: spcmReducer.tr,
    myOrderOrderDetails,
    approvalHistoryData,
    fetchApprovalQuestionsDetails
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { loadChangeTotalApprovalDetails, getApprovals, loadApprovalHistory, fetchApprovalsQuesAns },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FulfillmentApprovalStatus);
