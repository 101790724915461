
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect } from "react";
import { Popover, Card, OverlayTrigger } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { ImInfo } from "react-icons/im";
import { useSelector, useDispatch } from "react-redux";
import XsmUserDetails from "_Commons/WorkItemBoard/xsmEditRequestedBy.js";
import datetimeConvertor from "../../../helpers/ISO8601converter.js";
import moment from "moment";
import ListLoader from "../../common/loaders/ListLoader.js";
import { Link } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { getRulesAuditData } from "../../../actions/spcmActions.js";
import { useParams } from "react-router";
import Cookies from "universal-cookie";
const cookies = new Cookies();
let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
timezone = timezone.split("~");
timezone = timezone[3];

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));

dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

let companyId = cookies.get("gph");
if (companyId) companyId = companyId.replace("s:", "");
if (companyId) companyId = companyId.substring(0, companyId.lastIndexOf("."));
companyId = companyId.split("~");
companyId = companyId[48];

let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];

const AuditLog = (props) => {
    const [key, setKey] = useState(1);
    const [sortOrder, setSortOrder] = useState("desc");
    const params = useParams();
    const dispatch = useDispatch();
    const translator = useSelector((state) => state.spcmReducer.tr);
    const { loading, data: auditLogData } = useSelector(
      (state) => state.rulesAuditData
    );
    const windowSize = useSelector((state) => state.windowSize.width);
  
    useEffect(() => {
      dispatch(getRulesAuditData(companyId, params?.ruleId, sortOrder));
    }, [sortOrder]);

    const renderAuditLog = (auditData) => {
        if (auditData.length > 0) {
          return (
            <>
              <div className="contractTab">
                <span className="float-r f-size-17">
                  <a
                    href="javascript:void(0)"
                    onClick={() => setSortOrder("asc")}
                    className="black"
                    title={translator["Ascending"]}
                  >
                    <i className="fa fa-angle-up"></i>
                  </a>
                  &nbsp;&nbsp;
                  <a
                    href="javascript:void(0)"
                    onClick={() => setSortOrder("desc")}
                    className="black"
                    title={translator["Descending"]}
                  >
                    <i className="fa fa-angle-down"></i>
                  </a>
                </span>
              </div>
              <div id="brkAudLogcomm" className="actCommts">
                {auditData.map((comment, childKey) => {
                  let actDateTimeFormat = jwtdf + " hh:mm A";
                  let myDateObj,
                    endDate,
                    endDateStr,
                    timeago,
                    startTime,
                    duration,
                    hours,
                    isoDate;
                  isoDate = datetimeConvertor(
                    comment.createdOn,
                    jwtdf + " HH:mm:ss"
                  );
                  myDateObj = moment(isoDate).format(actDateTimeFormat);
                  endDate = moment(myDateObj, actDateTimeFormat);
                  endDate = moment(myDateObj, actDateTimeFormat);
                  endDateStr = endDate._i;
                  startTime = moment(
                    new Date().toLocaleString("en-US", { timeZone: timezone })
                  );
                  timeago = moment(endDateStr, actDateTimeFormat).from(startTime);
                  duration = moment.duration(startTime.diff(endDate));
                  hours = duration.asHours();
    
                  const tooltip = (
                    <Popover className="userInfoPopup">
                      <XsmUserDetails
                        userId={parseInt(comment.modifiedById, 10)}
                        translator={translator}
                        isHeadingVisible={false}
                        isPopup={true}
                      />
                    </Popover>
                  );
                  return (
                    console.log("ruleType", comment),
                    <Card className="actlogCrd" bsPrefix=" " key={childKey}>
                      <div className="circleTxt">
                        <div className="subhed">
                          {comment.fieldModified !== "System" ? (
                            <OverlayTrigger
                              trigger="click"
                              rootClose
                              placement={
                                windowSize < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
                              }
                              overlay={tooltip}
                            >
                              <span className="circleNa" title={comment.createdBy}>
                                {comment.createdBy} <ImInfo />
                              </span>
                            </OverlayTrigger>
                          ) : (
                            <span className="margin-r-20">{comment.createdBy}</span>
                          )}
                          <span
                            className="margin-l-5 timeao"
                            title={hours >= 24 ? "" : endDateStr}
                          >
                            {hours >= 24 ? endDateStr : timeago}
                          </span>
                        </div>
                        <div className="desFld">
                          <div title="Field Name" className="fenme">
                            {comment?.fieldModified}
                          </div>
                          {comment?.fieldModified !== "Created" && (
                            <>
                              <div title="New Value" className="nwnme commn">
                                {comment?.updateTo ? comment?.updateTo : "NA"}
                              </div>
                              <div title="Old Value" className="oldnme commn">
                                {comment?.updatedFrom ? comment?.updatedFrom : "NA"}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </Card>
                  );
                })}
              </div>
            </>
          );
        } else {
          return <div className="text-c">{translator["No Data Available"]}</div>;
        }
      };

      return (
        <>
          <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
              <li>
                <Link
                  to="javascript:void(0)"
                  id="closeButton"
                  className="closerightPanelBtn"
                  title={translator["Minimize the details panel"]}
                  onClick={() => {
                    props.rightEditPanel(false);
                    props.setView("");
                  }}
                >
                  <IoClose />
                </Link>
              </li>
            </ul>
          </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {translator["Activity Details"]}
            </div>
          </div>
          <div className="rBoxGap">
            <div className="rBoxTabs cntrtTab">
              <Tabs activeKey={key} onSelect={(k) => setKey(k)} id="tabId">
                <Tab eventKey={1} title={translator["Audit Log"]}>
                  {loading && (
                    <div className="margin-t-10">
                      <ListLoader />
                    </div>
                  )}
                  {!loading && renderAuditLog(auditLogData)}
                </Tab>
              </Tabs>
            </div>
          </div>
        </>
      );
}
export default AuditLog;