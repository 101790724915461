
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import {
  _inputField,
  _textArea,
  _dropDown,
  _dropDownNotification
} from "../../common/ReduxFormFields/formFields";
import {
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Image,
  Alert,
} from "react-bootstrap";

import {
  spcmLoader,
  getCompanyList,
  postNEcompanyDetails,
  uploadLogoForCompanyNE, getMilboxName,
} from "../../../actions/spcmActions";
import { loadFunctionByPageAndRole } from "../../../actions/common/functionActions.js";
import { connect } from "react-redux";
import { field_file } from "../../../components/common/formFields";
// import ConfigurationRight from './configurationRight.js';
import { Field, reduxForm, SubmissionError, change, reset } from "redux-form";
import axios from "axios";
import { bindActionCreators } from "redux";
import { browserHistory } from "react-router";
import Cookies from "universal-cookie";
const cookies = new Cookies();
import NotificationBoardConfRightSide from "./NotificationBoardConfRightSide";
import { navigationHooks } from "../../../helpers/NavigationHook";
import Swal from "sweetalert2";
import sanitizeHtml from 'sanitize-html';
import { IoSaveOutline, IoClose } from "react-icons/io5";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import ListLoader from "../../common/loaders/ListLoader.js";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';
import { VscOpenPreview } from "react-icons/vsc";

let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];


let userLanguage = cookies.get("gph");
if (userLanguage) userLanguage = userLanguage.replace("s:", "");
if (userLanguage)
  userLanguage = userLanguage.substring(
    0,
    userLanguage.lastIndexOf(".")
  );
userLanguage = userLanguage.split("~");
const userSelectedLanguage = userLanguage[20];

let insertRenderVariables = [
  { name: "User Name", key: "userName" },
  { name: "Requester Name", key: "requesterName" },
  { name: "Offering Name", key: "offeringName" },
  { name: "Order Number", key: "orderNumber" },
  { name: "Breakfix Number", key: "breakfixNumber" },
  { name: "Target For", key: "targetFor" },
  { name: "Priority", key: "priority" },
  { name: "Requested On", key: "requestedOn" },
  { name: "Description", key: "description" },
  { name: "Service or CI Name", key: "serviceorCIName" },
  { name: "Due By", key: "dueBy" },
];

class NotificationBoardConf extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      companyErr: 0,
      companyLogoErr: 0,
      portalErr: 0,
      signatureErr: 0,
      languageErr: 0,
      submitLoadingTab: false,
      crossFooter: false,
      callFooterImgText: false,
      logoState: "",
      signatureState: "",
      footerTxtState: "",
      footerImgState: "",
      footerNoteState: "",
      logoPreviewState: "",
      signaturePreviewState: "",
      footerTxtPreviewState: "",
      footerImgPreviewState: "",
      footerNotePreviewState: "",
      companyCalledOnSelect: "1",
      theInputKey: "",
      onSave: "false",
      companyId: "",
      isRightEditPanel: true,
      mailboxDisplayName: "",
      companyName: '',
      displayNameErr: 0,
    };
    this.submit = this.submit.bind(this);
    this.onCompanyChange = this.onCompanyChange.bind(this);
    this.onLogoChange = this.onLogoChange.bind(this);
    this.onPortalChange = this.onPortalChange.bind(this);
    this.onDisplayNameChange = this.onDisplayNameChange.bind(this);
    this.onSignatureChange = this.onSignatureChange.bind(this);
    this.onDeleteFooter = this.onDeleteFooter.bind(this);
    this.onLanguageChange = this.onLanguageChange.bind(this);
    // this.handleEvListener = this.handleEvListener.bind(this);
    this.rightEditPanel = this.rightEditPanel.bind(this);
    //this.isNePreview=this.isNePreview.bind(this);
  }

  componentDidMount() {
		this.context.keyDownHandler("","","",this.saveObjRef);
	}
	componentDidUpdate() {
		this.context.keyDownHandler("","","",this.saveObjRef);
	}

  rightEditPanel(value) {
    this.setState({ isRightEditPanel: value });
  }

  componentWillMount() {
    this.props.getCompanyList("");
  }
  componentWillUnmount() {
    this.onCompanyChange({ target: { value: "0" } });

    // if( this.props.spcmReducer.necompanydetails!=""){
    // this.props.spcmReducer.necompanydetails.map(function (item) {

    //   item.portalName = "",
    //     item.logo = "",
    //     item.footerSignature = "",
    //     item.footerNote = "",
    //     item.otherInfo = "",
    //     item.footerImg = "",
    //     item.id = ""

    // });
    //   }
  }

  submit(values, event) {
    this.setState({ onSave: "true" });

    let logoFileName = JSON.parse(JSON.stringify(values.logo));
    let footerFileName = values.footerImg;
    let count = 0;

    if (footerFileName && typeof footerFileName == "object" && footerFileName.length == 0) {

      footerFileName = null;
      values.footeImg = null
    }

    if (!values.name || values.name === "" || values.name === "0") {
      count++;
      this.state.companyErr = "error";
    }
    if (!values.language || values.language === "" || values.language === "0") {
      count++;
      this.state.languageErr = "error";
    }
    if (
      !values.logo ||
      values.logo === "" ||
      values.logo == null ||
      values.logo == "null"
    ) {
      count++;
      this.state.companyLogoErr = "error";
    } else {
      if (typeof logoFileName == "object") {
        logoFileName = values.logo[0].name;

        if (
          logoFileName.indexOf(".jpg") <= "-1" &&
          logoFileName.indexOf(".JPG") <= "-1" &&
          logoFileName.indexOf(".jpeg") <= "-1" &&
          logoFileName.indexOf(".JPEG") <= "-1" &&
          logoFileName.indexOf(".png") <= "-1" &&
          logoFileName.indexOf(".PNG") <= "-1" &&
          logoFileName.indexOf(".gif") <= "-1" &&
          logoFileName.indexOf(".GIF") <= "-1" &&
          logoFileName.indexOf(".giff") <= "-1" &&
          logoFileName.indexOf(".GIFF") <= "-1"
        ) {
          count++;
          this.state.companyLogoErr = "error";
          Swal.fire(
            this.props.tr["Insert the Company Logo in the given format"],
            "",
            ""
          );
        }
      }
    }
    if (typeof footerFileName == "object" && footerFileName != null) {
      footerFileName = values.footerImg[0].name;

      if (
        footerFileName.indexOf(".jpg") <= "-1" &&
        footerFileName.indexOf(".JPG") <= "-1" &&
        footerFileName.indexOf(".jpeg") <= "-1" &&
        footerFileName.indexOf(".JPEG") <= "-1" &&
        footerFileName.indexOf(".png") <= "-1" &&
        footerFileName.indexOf(".PNG") <= "-1" &&
        footerFileName.indexOf(".gif") <= "-1" &&
        footerFileName.indexOf(".GIF") <= "-1" &&
        footerFileName.indexOf(".giff") <= "-1" &&
        footerFileName.indexOf(".GIFF") <= "-1"
      ) {
        count++;
        Swal.fire(
          this.props.tr["Insert the Footer Image in the given format"],
          "",
          ""
        );
      }
    }
    if (
      !values.footerSignature ||
      values.footerSignature.trim() == "" ||
      values.footerSignature == null ||
      values.footerSignature == "null"
    ) {
      count++;
      this.state.signatureErr = "error";
    }
    if (
      !values.portalName ||
      values.portalName.trim() == "" ||
      values.portalName == null ||
      values.portalName == "null"
    ) {
      count++;
      this.state.portalErr = "error";
    }
    if (
      !values.mailboxDisplayName ||
      values.mailboxDisplayName.trim() == "" ||
      values.mailboxDisplayName == null ||
      values.mailboxDisplayName == "null"
    ) {
      count++;
      this.state.displayNameErr = "error";
    }
    if (count != 0) {
      this.setState({ portalErr: this.state.portalErr });
    } else {
      this.setState({ submitLoadingTab: true });
      let formData = new FormData();
      let formDataFooter = new FormData();
      let footerImage;
      let logoImage;

      const logoFile = values.logo;
      if (values.mailboxDisplayName != null && values.mailboxDisplayName != "null") {
        // ne api call for mailbox display name  
         axios
          .patch("/api/notificationCred", {
            tenantId: this.state.companyName,
            displayName: values.mailboxDisplayName,
            companyId: values.name

          })
          .then((response) => {
            Swal.fire(
              this.props.tr[
              "Notification Configuration submitted successfully"
              ],
              "",
              ""
            ).then((x) => {
              navigationHooks.navigate("/notificationboard");
              this.setState({ submitLoadingTab: false });
              this.props.postNEcompanyDetails(values.name, values.language).then((res) => {
                if (res && res[0] && res[0].footerImg) {
                  this.setState({ callFooterImgText: true });
                  this.setState({ crossFooter: true });
                } else this.setState({ callFooterImgText: false });
                this.setState({ crossFooter: false });
              });
            });
          })
          .catch((error) => {
            this.setState({ submitLoadingTab: false });

            this.props.spcmLoader("attribute", "success");
            throw new SubmissionError({
              _error: error.response.data.message,
            });
          });

      }

      if (values.logo != null && values.logo != "null") {
        const logoFileUpload = values.logo[0];
        formData.append("imageFiles", logoFileUpload);
        formData.append("id", values.name);
        formData.append("suffix", "logo");
        formData.append("lang", values.language);
      }
      const footerImgFile = values.footerImg;

      if (values.footerImg != null && values.footerImg != "null") {
        const footerFileUpload = values.footerImg[0];
        formDataFooter.append("footerFiles", footerFileUpload);
        formDataFooter.append("id", values.name);
        formDataFooter.append("suffix", "footer");
        formDataFooter.append("lang", values.language);

      }
      // if footerImg exists and Logo does not
      if (
        (values.logo == "" ||
          values.logo == null ||
          values.logo == "null" ||
          typeof logoFile == "string") &&
        values.footerImg != "" &&
        values.footerImg != null &&
        values.footerImg != "null" &&
        typeof footerImgFile != "string"
      ) {
        console.log("Only footer Img", values.footerImg);
        if (values.footerImg && values.footerImg.length == 0) {
          axios
            .patch("/api/updateCompanyDetailsNE", {
              footerImg: "",
              footerNote: values.footerNote,
              footerSignature: values.footerSignature,
              id: values.name,
              otherInfo: values.otherInfo,
              portalName: values.portalName,
              language: values.language
            })
            .then((response) => {

              Swal.fire(
                this.props.tr[
                "Notification Configuration submitted successfully"
                ],
                "",
                ""
              ).then((x) => {


                navigationHooks.navigate("/notificationboard");
                this.setState({ submitLoadingTab: false });
                this.props.postNEcompanyDetails(values.name, values.language).then((res) => {
                  if (res && res[0] && res[0].footerImg) {
                    this.setState({ callFooterImgText: true });
                    this.setState({ crossFooter: true });
                  } else this.setState({ callFooterImgText: false });
                  this.setState({ crossFooter: false });
                });




              });

            });
        } else {
          axios
            .post("/api/uploadFooterForCompany", formDataFooter)
            .then((response) => {
              footerImage = response.data.TempFileName;
              if (this.state.crossFooter == true) {
                this.setState({
                  logoState: logoFileName,
                  signatureState: values.footerSignature,
                  footerTxtState: values.otherInfo,
                  footerImgState: "",
                  footerNoteState: values.footerNote,
                });
                this.setState({
                  logoPreviewState: null,
                  signaturePreviewState: null,
                  footerTxtPreviewState: null,
                  footerImgPreviewState: null,
                  footerNotePreviewState: null,
                });
                //if crossClick is true then the state of footerImg will be blank and footerimage is not passed.

                return axios
                  .patch("/api/updateCompanyDetailsNE", {
                    footerImg: "",
                    footerNote: values.footerNote,
                    footerSignature: values.footerSignature,
                    id: values.name,
                    otherInfo: values.otherInfo,
                    portalName: values.portalName,
                    language: values.language
                  })
                  .then((response) => {

                    Swal.fire(
                      this.props.tr[
                      "Notification Configuration submitted successfully"
                      ],
                      "",
                      ""
                    ).then((x) => {


                      navigationHooks.navigate("/notificationboard");
                      this.setState({ submitLoadingTab: false });
                      this.props.postNEcompanyDetails(values.name, values.language).then((res) => {
                        if (res && res[0] && res[0].footerImg) {
                          this.setState({ callFooterImgText: true });
                          this.setState({ crossFooter: true });
                        } else this.setState({ callFooterImgText: false });
                        this.setState({ crossFooter: false });
                      });




                    });

                  });
              } else {
                this.setState({
                  logoState: logoFileName,
                  signatureState: values.footerSignature,
                  footerTxtState: values.otherInfo,
                  footerImgState: response.data.TempFileName,
                  footerNoteState: values.footerNote,
                });
                this.setState({
                  logoPreviewState: null,
                  signaturePreviewState: null,
                  footerTxtPreviewState: null,
                  footerImgPreviewState: null,
                  footerNotePreviewState: null,
                });
                return axios
                  .patch("/api/updateCompanyDetailsNE", {
                    footerImg: footerImage,
                    footerNote: values.footerNote,
                    footerSignature: values.footerSignature,
                    id: values.name,
                    otherInfo: values.otherInfo,
                    portalName: values.portalName,
                    language: values.language

                  })
                  .then((response) => {
                    Swal.fire(
                      this.props.tr[
                      "Notification Configuration submitted successfully"
                      ],
                      "",
                      ""
                    ).then((x) => {
                      navigationHooks.navigate("/notificationboard");
                      this.setState({ submitLoadingTab: false });
                      this.props.postNEcompanyDetails(values.name, values.language).then((res) => {
                        if (res && res[0] && res[0].footerImg) {
                          this.setState({ callFooterImgText: true });
                          this.setState({ crossFooter: true });
                        } else this.setState({ callFooterImgText: false });
                        this.setState({ crossFooter: false });
                      });
                    });
                  });
              }
            })
            .catch((error) => {
              this.setState({ submitLoadingTab: false });

              this.props.spcmLoader("attribute", "success");
              throw new SubmissionError({ _error: error.response.data.message });
            });
        }

      }

      if (
        (values.footerImg == "" ||
          values.footerImg == null ||
          values.footerImg == "null" ||
          typeof footerImgFile == "string") &&
        values.logo != "" &&
        values.logo != null &&
        values.logo != "null" &&
        typeof logoFile != "string"
      ) {
        axios
          .post("/api/uploadLogoForCompany", formData)
          .then((response) => {
            logoImage = response.data.TempFileName;
            if (this.state.crossFooter == true) {
              this.setState({
                logoState: response.data.TempFileName,
                signatureState: values.footerSignature,
                footerTxtState: values.otherInfo,
                footerImgState: "",
                footerNoteState: values.footerNote,
              });
              this.setState({
                logoPreviewState: null,
                signaturePreviewState: null,
                footerTxtPreviewState: null,
                footerImgPreviewState: null,
                footerNotePreviewState: null,
              });
              return axios
                .patch("/api/updateCompanyDetailsNE", {
                  footerImg: "",
                  logo: logoImage,
                  footerNote: values.footerNote,
                  footerSignature: values.footerSignature,
                  id: values.name,
                  otherInfo: values.otherInfo,
                  portalName: values.portalName,
                  language: values.language

                })
                .then((response) => {
                  Swal.fire(
                    this.props.tr[
                    "Notification Configuration submitted successfully"
                    ],
                    "",
                    ""
                  ).then((x) => {
                    navigationHooks.navigate("/notificationboard");
                    this.setState({ submitLoadingTab: false });
                    this.props.postNEcompanyDetails(values.name, values.language).then((res) => {
                      if (res && res[0] && res[0].footerImg) {
                        this.setState({ callFooterImgText: true });
                        this.setState({ crossFooter: true });
                      } else this.setState({ callFooterImgText: false });
                      this.setState({ crossFooter: false });
                    });
                  });
                });
            } else {
              this.setState({
                logoState: response.data.TempFileName,
                signatureState: values.footerSignature,
                footerTxtState: values.otherInfo,
                footerImgState: footerFileName,
                footerNoteState: values.footerNote,
              });
              this.setState({
                logoPreviewState: null,
                signaturePreviewState: null,
                footerTxtPreviewState: null,
                footerImgPreviewState: null,
                footerNotePreviewState: null,
              });
              return axios
                .patch("/api/updateCompanyDetailsNE", {
                  logo: logoImage,
                  footerNote: values.footerNote,
                  footerSignature: values.footerSignature,
                  id: values.name,
                  otherInfo: values.otherInfo,
                  portalName: values.portalName,
                  language: values.language

                })
                .then((response) => {
                  Swal.fire(
                    this.props.tr[
                    "Notification Configuration submitted successfully"
                    ],
                    "",
                    ""
                  ).then((x) => {
                    navigationHooks.navigate("/notificationboard");
                    this.setState({ submitLoadingTab: false });
                    this.props.postNEcompanyDetails(values.name, values.language).then((res) => {
                      if (res && res[0] && res[0].footerImg) {
                        this.setState({ callFooterImgText: true });
                        this.setState({ crossFooter: true });
                      } else this.setState({ callFooterImgText: false });
                      this.setState({ crossFooter: false });
                    });
                  });
                });
            }
          })
          .catch((error) => {
            this.setState({ submitLoadingTab: false });

            this.props.spcmLoader("attribute", "success");
            throw new SubmissionError({ _error: error.response.data.message });
          });


      }

      if (
        (values.logo == "" ||
          values.logo == null ||
          values.logo == "null" ||
          typeof logoFile == "string") &&
        (values.footerImg == "" ||
          values.footerImg == null ||
          values.footerImg == "null" ||
          typeof footerImgFile == "string")
      ) {
        if (this.state.crossFooter == true) {
          this.setState({
            logoState: logoFileName,
            signatureState: values.footerSignature,
            footerTxtState: values.otherInfo,
            footerImgState: "",
            footerNoteState: values.footerNote,
          });
          this.setState({
            logoPreviewState: null,
            signaturePreviewState: null,
            footerTxtPreviewState: null,
            footerImgPreviewState: null,
            footerNotePreviewState: null,
          });
          return axios
            .patch("/api/updateCompanyDetailsNE", {
              footerImg: "",
              footerNote: values.footerNote,
              footerSignature: values.footerSignature,
              id: values.name,
              otherInfo: values.otherInfo,
              portalName: values.portalName,
              language: values.language

            })
            .then((response) => {
              Swal.fire(
                this.props.tr[
                "Notification Configuration submitted successfully"
                ],
                "",
                ""
              ).then((x) => {
                navigationHooks.navigate("/notificationboard");
                this.setState({ submitLoadingTab: false });
                this.props.postNEcompanyDetails(values.name, values.language).then((res) => {
                  if (res && res[0] && res[0].footerImg) {
                    this.setState({ callFooterImgText: true });
                    this.setState({ crossFooter: true });
                  } else this.setState({ callFooterImgText: false });
                  this.setState({ crossFooter: false });
                });
              });
            })
            .catch((error) => {
              this.setState({ submitLoadingTab: false });

              this.props.spcmLoader("attribute", "success");
              throw new SubmissionError({
                _error: error.response.data.message,
              });
            });
        } else {
          this.setState({
            logoState: logoFileName,
            signatureState: values.footerSignature,
            footerTxtState: values.otherInfo,
            footerImgState: footerFileName,
            footerNoteState: values.footerNote,
          });
          this.setState({
            logoPreviewState: null,
            signaturePreviewState: null,
            footerTxtPreviewState: null,
            footerImgPreviewState: null,
            footerNotePreviewState: null,
          });
          return axios
            .patch("/api/updateCompanyDetailsNE", {
              footerNote: values.footerNote,
              footerSignature: values.footerSignature,
              id: values.name,
              otherInfo: values.otherInfo,
              portalName: values.portalName,
              language: values.language

            })
            .then((response) => {
              Swal.fire(
                this.props.tr[
                "Notification Configuration submitted successfully"
                ],
                "",
                ""
              ).then((x) => {
                navigationHooks.navigate("/notificationboard");
                this.setState({ submitLoadingTab: false });
                this.props.postNEcompanyDetails(values.name, values.language).then((res) => {
                  if (res && res[0] && res[0].footerImg) {
                    this.setState({ callFooterImgText: true });
                    this.setState({ crossFooter: true });
                  } else this.setState({ callFooterImgText: false });
                  this.setState({ crossFooter: false });
                });
              });
            })
            .catch((error) => {
              this.setState({ submitLoadingTab: false });

              this.props.spcmLoader("attribute", "success");
              throw new SubmissionError({
                _error: error.response.data.message,
              });
            });
        }
      }
      if (
        values.logo != "" &&
        values.logo != null &&
        values.logo != "null" &&
        typeof logoFile != "string" &&
        values.footerImg != "" &&
        values.footerImg != null &&
        values.footerImg != "null" &&
        typeof footerImgFile != "string"
      ) {
        axios
          .post("/api/uploadLogoForCompany", formData)
          .then((response) => {
            logoImage = response.data.TempFileName;
            axios
              .post("/api/uploadFooterForCompany", formDataFooter)
              .then((response) => {
                footerImage = response.data.TempFileName;
                if (this.state.crossFooter == true) {
                  this.setState({
                    logoState: logoImage,
                    signatureState: values.footerSignature,
                    footerTxtState: values.otherInfo,
                    footerImgState: "",
                    footerNoteState: values.footerNote,
                  });
                  this.setState({
                    logoPreviewState: null,
                    signaturePreviewState: null,
                    footerTxtPreviewState: null,
                    footerImgPreviewState: null,
                    footerNotePreviewState: null,
                  });
                  return axios
                    .patch("/api/updateCompanyDetailsNE", {
                      footerImg: "",
                      footerNote: values.footerNote,
                      footerSignature: values.footerSignature,
                      logo: logoImage,
                      id: values.name,
                      otherInfo: values.otherInfo,
                      portalName: values.portalName,
                      language: values.language

                    })
                    .then((response) => {
                      Swal.fire(
                        this.props.tr[
                        "Notification Configuration submitted successfully"
                        ],
                        "",
                        ""
                      ).then((x) => {
                        navigationHooks.navigate("/notificationboard");
                        this.setState({ submitLoadingTab: false });
                        this.props
                          .postNEcompanyDetails(values.name, values.language)
                          .then((res) => {
                            if (res && res[0] && res[0].footerImg) {
                              this.setState({ callFooterImgText: true });
                              this.setState({ crossFooter: true });
                            } else this.setState({ callFooterImgText: false });
                            this.setState({ crossFooter: false });
                          });
                      });
                    });
                } else {
                  this.setState({
                    logoState: logoImage,
                    signatureState: values.footerSignature,
                    footerTxtState: values.otherInfo,
                    footerImgState: footerImage,
                    footerNoteState: values.footerNote,
                  });
                  this.setState({
                    logoPreviewState: null,
                    signaturePreviewState: null,
                    footerTxtPreviewState: null,
                    footerImgPreviewState: null,
                    footerNotePreviewState: null,
                  });
                  return axios
                    .patch("/api/updateCompanyDetailsNE", {
                      footerImg: footerImage,
                      footerNote: values.footerNote,
                      footerSignature: values.footerSignature,
                      logo: logoImage,
                      id: values.name,
                      otherInfo: values.otherInfo,
                      portalName: values.portalName,
                      language: values.language

                    })
                    .then((response) => {
                      Swal.fire(
                        this.props.tr[
                        "Notification Configuration submitted successfully"
                        ],
                        "",
                        ""
                      ).then((x) => {
                        navigationHooks.navigate("/notificationboard");
                        this.setState({ submitLoadingTab: false });
                        this.props
                          .postNEcompanyDetails(values.name, values.language)
                          .then((res) => {
                            if (res && res[0] && res[0].footerImg) {
                              this.setState({ callFooterImgText: true });
                              this.setState({ crossFooter: true });
                            } else this.setState({ callFooterImgText: false });
                            this.setState({ crossFooter: false });
                          });
                      });
                    });
                }
              });
          })
          .catch((error) => {
            this.setState({ submitLoadingTab: false });
            this.props.spcmLoader("attribute", "success");
            throw new SubmissionError({ _error: error.response.data.message });
          });
      }
    }

  }

  onLogoChange(e) {
    this.setState({ companyLogoErr: "" });
  }
  onPortalChange(e) {
    this.setState({ portalErr: "" });
  }
  onDisplayNameChange(e) {
    this.setState({ displayNameErr: "" });
  }
  onSignatureChange(e) {
    this.setState({ signatureErr: "" });
  }
  onDeleteFooter() {
    const randomArray = new Uint32Array(1);
    window.crypto.getRandomValues(randomArray);
    const randomNumber = randomArray[0] % Math.pow(36, 6);
    let randomString = randomNumber.toString(36);
    this.setState({ crossFooter: true });
    this.setState({ theInputKey: randomString });
  }

  onCompanyChange(e) {
    //console.log("event.target[event.target.selectedIndex].texto", e.target[e.target.selectedIndex].text);
    if (e.target.value == "0") {
      this.setState({ companyCalledOnSelect: "true" });
      this.setState({ companyId: e.target.value });
      this.props.postNEcompanyDetails(e.target.value, "")
    } else {
      this.setState({ companyId: e.target.value });
      this.setState({ companyCalledOnSelect: "false", companyName: e.target[e.target.selectedIndex].text });
      //this.props.change("notificationboard","tenantName",e.target[e.target.selectedIndex].text);
      this.props.getMilboxName(e.target.value)
        .then((res) => {
         // console.log(" getMilboxName res", res.displayName)
          if (res && res.displayName) {
            this.props.change("notificationboard", "mailboxDisplayName", res.displayName);
          };

        });
    }
    this.setState({ onSave: "false" });
    this.setState({ companyId: e.target.value });
    this.props.postNEcompanyDetails(e.target.value, userSelectedLanguage).then((res) => {
      if (res && res[0] && res[0].footerImg) {
        this.setState({ callFooterImgText: true });
      } else this.setState({ callFooterImgText: false });
      if (this.state.companyCalledOnSelect == "false") {
        if (res && res[0]) {
          this.setState({
            logoPreviewState: res[0].logo,
            signaturePreviewState: res[0].footerSignature,
            footerTxtPreviewState: res[0].otherInfo,
            footerImgPreviewState: res[0].footerImg,
            footerNotePreviewState: res[0].footerNote,
          });
        }
      } else {
        this.setState({
          logoPreviewState: null,
          signaturePreviewState: null,
          footerTxtPreviewState: null,
          footerImgPreviewState: null,
          footerNotePreviewState: null,
        });
      }
    });

    this.setState({ loadingTab: true });
    this.setState({ companyErr: "" });
    const randomArray = new Uint32Array(1);
    window.crypto.getRandomValues(randomArray);
    const randomNumber = randomArray[0] % Math.pow(36, 6);
    let randomString = randomNumber.toString(36);
    this.setState({ theInputKey: randomString });
  }

  onLanguageChange(e) {
    this.setState({ languageErr: "" });

    this.props.postNEcompanyDetails(this.state.companyId, e.target.value).then((res) => {
      if (res && res[0] && res[0].footerImg) {
        this.setState({ callFooterImgText: true });
      } else this.setState({ callFooterImgText: false });
      if (this.state.companyCalledOnSelect == "false") {
        if (res && res[0]) {
          this.setState({
            logoPreviewState: res[0].logo,
            signaturePreviewState: res[0].footerSignature,
            footerTxtPreviewState: res[0].otherInfo,
            footerImgPreviewState: res[0].footerImg,
            footerNotePreviewState: res[0].footerNote,

          });
        }
      } else {
        this.setState({
          logoPreviewState: null,
          signaturePreviewState: null,
          footerTxtPreviewState: null,
          footerImgPreviewState: null,
          footerNotePreviewState: null,
        });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextProps.spcmReducer.mailboxDisplayName", nextProps.spcmReducer.mailbox);
    if (nextProps.spcmReducer.mailbox != undefined) {
      if (
        nextProps.spcmReducer.mailbox.displayName != null &&
        nextProps.spcmReducer.mailbox.displayName != "null" &&
        nextProps.spcmReducer.mailbox.displayName != ""
      ) {
        this.setState({ displayNameErr: "" });
      }
    }
    if (nextProps.spcmReducer.necompanydetails[0] != undefined) {
      if (
        nextProps.spcmReducer.necompanydetails[0].portalName != null &&
        nextProps.spcmReducer.necompanydetails[0].portalName != "null" &&
        nextProps.spcmReducer.necompanydetails[0].portalName != ""
      ) {
        this.setState({ portalErr: "" });
      }
      if (
        nextProps.spcmReducer.necompanydetails[0].footerSignature != null &&
        nextProps.spcmReducer.necompanydetails[0].footerSignature != "null" &&
        nextProps.spcmReducer.necompanydetails[0].footerSignature != ""
      ) {
        this.setState({ signatureErr: "" });
      }
      if (
        nextProps.spcmReducer.necompanydetails[0].logo != null &&
        nextProps.spcmReducer.necompanydetails[0].logo != "null" &&
        nextProps.spcmReducer.necompanydetails[0].logo != ""
      ) {
        this.setState({ companyLogoErr: "" });
      }
    }
  }

  render() {
    //console.log("this .props.initialValues", this.props.initialValues)
    let comp = this.props.spcmReducer.companyList;
    let optionsList = [];
    if (comp.length != 0) {
      optionsList = comp.map((item) => {
        item.field1Key =
          item.field1Key.length >= 70
            ? `${item.field1Key.slice(0, 70)}...`
            : item.field1Key;
        return item;
      });
    }
    const { error, handleSubmit } = this.props;
    const languageList = [{ field1Key: 'Chinese Simplified', field1Value: "zhcn" }, { field1Key: 'English', field1Value: "en" },{ field1Key: 'Filipino', field1Value: "fil" }, { field1Key: 'French', field1Value: "fr" }, { field1Key: 'German', field1Value: "de" },
      { field1Key: 'Indonesian', field1Value: "id" },{ field1Key: 'Malay', field1Value: "ms" },{ field1Key: 'Spanish', field1Value: "es" },  { field1Key: 'Tamil', field1Value: "ta" },{ field1Key: 'Thai', field1Value: "th" },
      { field1Key: 'Arabic', field1Value: "ar" },{ field1Key: 'Hindi', field1Value: "hi" },{ field1Key: 'Italian', field1Value: "it" },  { field1Key: 'Japanese', field1Value: "ja" },{ field1Key: 'Korean', field1Value: "ko" },
      { field1Key: 'Vietnamese', field1Value: "vi" }]
     
    return (
      <>
        {this.props.isFetchingDetails == true ? (
          <ListLoader />
        ) : (
          <div style={{ position: "initial" }}>
            <div
              className="paHedFilter nbConfigHeader"
            >
             { !this.state.isRightEditPanel &&
              <Button 
                role="button"
                bsPrefix=" "
                className="myBt cancel margin-r-30"
                title={"Preview"}
                onClick={() => {this.rightEditPanel(true)}}
              >
              <VscOpenPreview/> 
                
              </Button> }
              <Link
                to="javascript:void(0)"
                bsPrefix=" "
                className="myBt plus"
                disabled={this.state.submitLoadingTab}
                onClick={handleSubmit(this.submit)}
                title={this.props.tr["Save"]}
                ref={(e) => (this.saveObjRef = e)}
              >
                <IoSaveOutline />
              </Link>
            </div>
            <PanelGroup direction="horizontal">
              <Panel id="sidebar" minSize={33} order={1} defaultSize={this.state.isRightEditPanel ? 60 : 100} className={this.state.isRightEditPanel ? "isShowLeftPanel" : ""}>
                <div style={{ position: "relative" }}>
                  {this.props.isFetchingDetails == true ? (
                    <div className=""></div>
                  ) : null}
                  {error == undefined || error == null ? null : (
                    <Alert
                      style={{
                        marginBottom: "15px",
                        fontSize: "13px",
                        padding: "8px 30px 8px 8px",
                      }}
                      variant="danger"
                    >
                      <h4>{error}</h4>
                    </Alert>
                  )}

                  <Row>
                    <Col lg={6} md={12} sm={12} xs={12}>
                      <Form.Label>
                        <span className="rStar"></span> {this.props.tr["Company"]}
                      </Form.Label>
                      <Form.Group className="form-group">
                        <Field
                          component={_dropDownNotification}
                          name="name"
                          onChange={this.onCompanyChange}
                          options={optionsList}
                          className={"form-control " + this.state.companyErr}
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={6} md={12} sm={12} xs={12}>
                      <Form.Label>
                        <span className="rStar"></span> {this.props.tr["Language"]}
                      </Form.Label>
                      <Form.Group className="form-group">
                        <Field
                          component={_dropDownNotification}
                          name="language"
                          onChange={this.onLanguageChange}
                          options={languageList}
                          className={"form-control " + this.state.languageErr}
                        />
                      </Form.Group>
                    </Col>

                  </Row>
                  <Row>
                    <Col lg={6} md={12} sm={12} xs={12}>
                      <Form.Group className="form-group priceForm-group withImgbox">
                        <Form.Label>
                          <span className="rStar"></span>{" "}
                          {this.props.tr["Company Logo"]}{" "}
                          <b className="f-size-11">
                            (upload only .jpeg,.jpg,.png,.giff,.gif)
                          </b>
                        </Form.Label>
                        <InputGroup>
                          <Field
                            className={"form-control " + this.state.companyLogoErr}
                            component={field_file}
                            type="file"
                            name="logo"
                            onChange={this.onLogoChange}
                          />
                          <InputGroup.Text>
                            <Image
                              src={
                                this.props.spcmReducer.necompanydetails[0] ==
                                  undefined
                                  ? null
                                  : this.props.spcmReducer.necompanydetails[0].logo
                                    ? this.props.spcmReducer.necompanydetails[0].logo
                                    : "./views/images/service-icons/service-dummy.png"
                              }
                            />
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </Col>

                    <Col lg={6} md={12} sm={12} xs={12}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          <span className="rStar"></span>{" "}
                          {this.props.tr["Portal Name"]}
                        </Form.Label>
                        <Field
                          name="portalName"
                          component={_inputField}
                          className={"form-control " + this.state.portalErr}
                          maxlength="100"
                          onChange={this.onPortalChange}
                        ></Field>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* <Row>
            <Col xs={12}>
              <Form.Group className='position-re form-group'>
                <Form.Label ><span className="rStar"></span>{" "}Body Text</Form.Label>
                <div className="form-control" style={{ height: "initial" }} id="editableTextArea" dangerouslySetInnerHTML={{ __html:this.state.dbtemplate}} />
                <div className='text-r f-size-12'>Type "@" and click on it to insert new variable.</div>
              </Form.Group>
            </Col>
          </Row> */}
                  <Row>
                    <Col lg={6} md={12} sm={12} xs={12}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          <span className="rStar"></span>{" "}
                          {this.props.tr["Mailbox Display Name"]}
                        </Form.Label>
                        <Field
                          name="mailboxDisplayName"
                          component={_inputField}
                          className={"form-control " + this.state.displayNameErr}
                          maxlength="100"
                          onChange={this.onDisplayNameChange}
                        ></Field>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          <span className="rStar"></span>{" "}
                          {this.props.tr["Signature"]}
                        </Form.Label>
                        <div className="position-re">
                          <Field
                            name="footerSignature"
                            type="textarea"
                            component={_textArea}
                            className={"form-control " + this.state.signatureErr}
                            onChange={this.onSignatureChange}
                            rows="1"
                            maxlength="100"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <Form.Group className="form-group">
                        <Form.Label>{this.props.tr["Footer Text"]}</Form.Label>
                        <div className="position-re">
                          <Field
                            name="otherInfo"
                            type="textarea"
                            component={_textArea}
                            className="form-control"
                            rows="2"
                            maxlength="5000"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <Form.Group className="form-group priceForm-group withImgbox">
                        <Form.Label>
                          {this.props.tr["Footer Image"]}{" "}
                          <b className="f-size-11">
                            (upload only .jpeg,.jpg,.png,.giff,.gif)
                          </b>
                        </Form.Label>
                        <InputGroup>
                          <Field
                            className="form-control"
                            component={field_file}
                            type="file"
                            name="footerImg"
                            key={this.state.theInputKey || ""}
                          />
                          <InputGroup.Text>
                            <Image
                              src={
                                this.props.spcmReducer.necompanydetails[0] ==
                                  undefined
                                  ? null
                                  : this.props.spcmReducer.necompanydetails[0]
                                    .footerImg
                                    ? this.props.spcmReducer.necompanydetails[0]
                                      .footerImg
                                    : "./views/images/service-icons/service-dummy.png"
                              }
                            />
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      {this.state.crossFooter === false ? (
                        this.state.callFooterImgText ? (
                          <div className="atRemIm">
                            <div className="txt">Remove Footer Image</div>
                            <Button
                              className="xBtn"
                              bsPrefix=" "
                              bsSize="small"
                              title="Remove Footer Image"
                              onClick={this.onDeleteFooter}
                            >
                              <IoClose />
                            </Button>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col xs={12}>
                      <Form.Group className="form-group">
                        <Form.Label>{this.props.tr["Footer Note"]}</Form.Label>
                        <div className="position-re">
                          <Field
                            type="textarea"
                            component={_textArea}
                            name="footerNote"
                            className="form-control"
                            rows="2"
                            maxlength="1000"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Panel>
              {this.state.isRightEditPanel ?
                <>
                  <PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
                  <Panel minSize={33} order={2} defaultSize={40} className="shopCartloader2">
                    <div className="rBoxStyle">
                      <NotificationBoardConfRightSide
                        // dbtemplate={this.state.dbtemplate}
                        logoState={this.state.logoState}
                        signatureState={this.state.signatureState}
                        footerTxtState={this.state.footerTxtState}
                        footerImgState={this.state.footerImgState}
                        footerNoteState={this.state.footerNoteState}
                        logoPreviewState={this.state.logoPreviewState}
                        signaturePreviewState={this.state.signaturePreviewState}
                        footerTxtPreviewState={this.state.footerTxtPreviewState}
                        footerImgPreviewState={this.state.footerImgPreviewState}
                        footerNotePreviewState={this.state.footerNotePreviewState}
                        companyCalledOnSelect={this.state.companyCalledOnSelect}
                        onSave={this.state.onSave}
                        // LOCALE_ID={props.LOCALE_ID}
                        isRightEditPanel={this.state.isRightEditPanel}
                        rightEditPanel={this.rightEditPanel}
                      />
                    </div>
                  </Panel>
                </> : ""
              }
            </PanelGroup>
          </div>
        )}
      </>
    );
  }
}
NotificationBoardConf = reduxForm({
  form: "notificationboard",
  enableReinitialize: true,
  destroyOnUnmount: false,
})(NotificationBoardConf);

const mapStateToProps = ({ spcmReducer, isFetchingDetails }) => {
  let initialValues;
  let mailboxValues = structuredClone(spcmReducer.mailbox);
  if (spcmReducer.necompanydetails.length > 0) {
    let neDetails = structuredClone(spcmReducer.necompanydetails);
    neDetails.map(function (item) {
      initialValues = {
        logo: item.logo,
        portalName: item.portalName,
        footerSignature: item.footerSignature,
        footerNote: item.footerNote,
        otherInfo: item.otherInfo,
        name: item.id,
        footerImg: item.footerImg,
        language: item.language,
        mailboxDisplayName: mailboxValues.displayName
      };
    });
  } else {
    initialValues = {
      logo: "",
      portalName: "",
      footerSignature: "",
      footerNote: "",
      otherInfo: "",
      name: "",
      footerImg: "",
      language: "",
      mailboxDisplayName: ""
    };
  }

  return {
    spcmReducer,
    initialValues,
    isFetchingDetails: isFetchingDetails.isFetching,
    tr: spcmReducer.tr,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      spcmLoader,
      getCompanyList,
      postNEcompanyDetails, getMilboxName,
      uploadLogoForCompanyNE,
      loadFunctionByPageAndRole,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationBoardConf);
