
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState,useRef } from "react";
import { Row, Col, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector, connect, } from "react-redux";
import { Field, reduxForm, change,formValueSelector} from "redux-form";
import { _inputField, _dropDown } from "../../../common/ReduxFormFields/CategoryBoard/formFields";
import { _checkbox, _number, TypeaheadExampleSingleSelect } from "../../../common/formFields";
import { useQuery } from "@apollo/client";
import { getSingleLanguage ,masterlanguages,getFormCompanyLanguage} from "../../graphQl/GraphqlQueries";
import { getCompanyList } from "../../../../actions/spcmActions";

import axios from "axios";
import { set } from "lodash";
import LanguageEditor from "./LanguageEditor";

// const languages = [
//   { field1Value: "en", field1Key: "English" },
//   { field1Value: "de", field1Key: "German" },
//   { field1Value: "fr", field1Key: "French" },
//   { field1Value: "es", field1Key: "Spanish" },
// ];
const statusOptions = [
  { text: "Select", value: "" },
  { text: "Active", value: "Active" },
  { text: "Inactive", value: "Inactive" },
];

const validate = (values, props) => {
  const errors = {};
  if (!values.language) errors.language = "Please choose language";
  if (!values.key) errors.key = " ";

  if (!values.value)
    errors.value = "Please fill value";

  

  if (!values.status)
    errors.status = "Please choose escalation status";

  return errors;
};
const AddLanguageForm = (props) => {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const companyReducer = useSelector((state) => state.spcmReducer.companyList);
  const [companyList, setCompanyList] = useState([]);
  const [languageList, setLanguageList]=useState([]);
  const [typedCompany, setTypedCompany] = useState([]);
  const [typedForm, setTypedForm] = useState([]);
  const [formList,setFormList]=useState([])
  const [selectedFormId, setSelectedFormId] = useState();
  const [selectedFormName, setSelectedFormName] = useState();
  const [companyId, setCompanyId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [selectedCompanyList, setSelectedCompanyList] = useState([]);
  const [selectedFormList, setSelectedFormList] = useState([]);
  const [trData,setTRData]=useState(null);
  const typeaheadRef = useRef(null);
  const formlanguageRef=useRef(null);
  const { data:langData } = useQuery(getSingleLanguage, {
    variables: { id: props?.params.languageId },
    skip: !props?.params?.languageId
   });
  const { data: formCompanyLanguageData } = useQuery(getFormCompanyLanguage, {
    variables: { companyId: parseInt(companyId,10) },
    skip: !companyId,
  });
 

   useEffect(() => {
    if(langData?.getLanguage){
      Object.keys(langData.getLanguage).forEach(res => {
        dispatch(change("AddLanguageForm", res, langData.getLanguage[res]));
      })
    }
  }, [langData?.getLanguage]);

  useEffect(() =>
  {
    if(companyReducer && companyReducer.length > 0)
    {
      companyReducer.push({ field1Key: "Default",  field1Value : "00000" })
      setCompanyList(companyReducer)
    }
    
  },[companyReducer])

  useEffect(() => {
    dispatch(getCompanyList(""));
    // dispatch(resetUrgencyImpactForSystemConfig());
  }, []);
  const { data:masterLangdata } = useQuery(masterlanguages);

  useEffect(() => {
    if(masterLangdata?.getAllMasterLanguages){
      let languageArray=masterLangdata?.getAllMasterLanguages ?? [];
      let languageListArray=languageArray.map(language=>{
         return {
           "field1Key":language.name,
           "field1Value":language.code
         }
      })
      languageListArray.push({
        field1Key:"Select",
        field1Value:""
      })
      setLanguageList([...languageListArray])
     }
  }, [masterLangdata?.getAllMasterLanguages]);

  useEffect(() => {
    let newformList = [];
    if (formCompanyLanguageData?.getAllForms && Array.isArray(formCompanyLanguageData?.getAllForms)) {
      formCompanyLanguageData?.getAllForms.map(data=> {
        let formCompanyLanguageData = {
          field1Key:data.name,
          field1Value:data._id
        }
        newformList.push(formCompanyLanguageData)
      }
    )
    setFormList([...newformList]);
    } else {
      setFormList([]);
    }
  }, [formCompanyLanguageData]);

  const selector = formValueSelector("AddLanguageForm");
  const selectedLanguage = useSelector(state => selector(state, "language"));

  useEffect(() => {
    if (companyId && selectedLanguage && selectedFormId) {
      axios.get(`/api/getformlabels/${selectedFormId}`, {
        headers: {
          query: JSON.stringify({
            companyId: parseInt(companyId,10),
            languageCodes: [selectedLanguage] 
          })
        }
      }).then((response)=>{
       
        setTRData({...response?.data?.data?.translations?.[selectedLanguage]})
      }).catch(err=>{
        console.log("err",err)
      });
    }
  }, [companyId, selectedFormId, selectedLanguage]);

  const setCompany = (selectedCompany) => {
      if (selectedCompany.length > 0) { 
        setSelectedCompanyList(selectedCompany);
        setCompanyId(selectedCompany[0].field1Value)
        setCompanyName(selectedCompany[0].field1Key)
      } else {
        //dispatch(resetUrgencyImpactForSystemConfig());
        setSelectedCompanyList([]);
      }
    };

    const setForm = (selectedForm) => {
      if (selectedForm.length > 0) { 
        setSelectedFormList(selectedForm);
        setSelectedFormId(selectedForm[0].field1Value)
        setSelectedFormName(selectedForm[0].field1Key)
        //dispatch(change('xsmMailboxConfigCreateForm', 'COMPANY_ID', selectedCompany[0].field1Value))
        //dispatch(change('xsmMailboxConfigCreateForm','COMPANY_NAME', selectedCompany[0].field1Key))
      } else {
        //dispatch(resetUrgencyImpactForSystemConfig());
        setSelectedFormList([]);
      }
    };    
    const companyInputChange = (e) => {
    setTypedCompany(e);
    setSelectedCompanyList([]);
    setCompany([]);
   // dispatch(resetUrgencyImpactForSystemConfig());
  };
  const companyInputChangeForm = (e) => {
    setTypedForm(e);
    setSelectedFormList([]);
    setForm([]);
   // dispatch(resetUrgencyImpactForSystemConfig());
  };


  const onCrossClickCompany = () => {
  
    dispatch(change("AddLanguageForm", "FORM_NAME", ""));
    companyInputChange("");
    setCompanyId('')
    setCompanyName('');
    setTRData(null);
    setForm('')
    setSelectedFormList([]);
    
  };  
  const onCrossClickForm = () => {
    companyInputChangeForm("");
    setSelectedFormList([]);
    setTRData(null);
  };  

  return (
      <Row>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
              <span className="rStar"></span>
              {"Language"}
            </Form.Label>
            <div className="heightdvTypehd infoBtnDiv">
              <Field
                component={_dropDown}
                name="language"
                options={languageList}
                className="form-control"
              />
            </div>
          </Form.Group>
        </Col>
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
            <Form.Label>
              <span className="rStar"></span>
              {"Company"}
            </Form.Label>
            <div className="">
            <Field
                  ref={typeaheadRef}
                  name="COMPANY_NAME"
                  component={TypeaheadExampleSingleSelect}
                  className="form-control"
                  options={companyList}
                  selectedOptions={selectedCompanyList}
                  onSelection={setCompany}
                  errorClass={props?.companyError}
                  diableStatus={false}
                  onInputChange={companyInputChange}
                  onCrossClick={onCrossClickCompany}
                  typedValue={typedCompany}
                  setErrorColor={props.setCompanyErrorColor}
                  labelKey={"field1Key"}
                />
            </div>
          </Form.Group>
        </Col>        
        <Col md={6} sm={6} xs={12}>
          <Form.Group className="form-group">
          <Form.Label>
              <span className="rStar"></span>
              {"Form"}
            </Form.Label>
            <div className="">
            <Form.Group className="form-group">

            <div className="">
            <Field
                  ref={formlanguageRef}
                  name="FORM_NAME"
                  component={TypeaheadExampleSingleSelect}
                  className="form-control"
                  options={formList}
                  selectedOptions={selectedFormList}
                  onSelection={setForm}
                  errorClass={props?.companyError}
                  diableStatus={false}
                  onInputChange={companyInputChangeForm}
                  onCrossClick={onCrossClickForm}
                  typedValue={typedForm}
                  setErrorColor={props.setCompanyErrorColor}
                  labelKey={"field1Key"}
                />
            </div>
          </Form.Group>
            </div>
          </Form.Group>
        </Col>
        <Col md={12} sm={12} xs={12}>
        {trData && (
          <LanguageEditor formikRef={props.formikRef} formId={selectedFormId} targetlanguage={selectedLanguage} companyId={companyId} trData={trData} />
        )}
      </Col>
    
   
        
      </Row>
  );
};

const mapStateToProps = (state) => ({});

export default reduxForm({
  form: "AddLanguageForm",
  destroyOnUnmount: true,
  validate,
  enableReinitialize: true,
})(connect(mapStateToProps)(AddLanguageForm));
