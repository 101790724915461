/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import NlpSearchSubStatusComponent from "./WorkItemBoard/QueryComponent/NlpSearchSubStatusComponent";
import QuerySearchSubStstusComponent from "./WorkItemBoard/QueryComponent/QuerySearchSubStstusComponent";

const SavedQueryDropdown = ({isAiINCTypeENV, isAiITMTypeENV, showConditionBuilder, onSubStatusSelect, selectedFilterTab, isButtonActive, moduleName}) => {
  const isNLPEnabledITM= moduleName === 'fullfilment' && isAiITMTypeENV;
  const isNLPEnabledINC =  moduleName === 'breakfix' && isAiINCTypeENV; 
  return (
    <>
      <div className="savQueDropDv dropdown">
        {(isNLPEnabledITM || isNLPEnabledINC) ?
          <NlpSearchSubStatusComponent isAiINCTypeENV={isAiINCTypeENV} isAiITMTypeENV={isAiITMTypeENV} showConditionBuilder={showConditionBuilder} onSubStatusSelect={onSubStatusSelect} selectedFilterTab={selectedFilterTab} moduleName={moduleName}/>
          :
          <QuerySearchSubStstusComponent showConditionBuilder={showConditionBuilder} onSubStatusSelect={onSubStatusSelect} selectedFilterTab={selectedFilterTab} isButtonActive={isButtonActive} />
        }
      </div>
    </>
  );
};

export default (SavedQueryDropdown);
