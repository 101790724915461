
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row,Col,Form,Tab,Tabs,Button} from "react-bootstrap";
import Spinner from 'react-spinkit';

import { loadProfileCurrency } from '_Actions/myProfile/profileCurrencyAction';
import { loadProfileDateFormat } from '_Actions/myProfile/profileDateFormatAction';
import { loadProfileTimeZone } from '_Actions/myProfile/profileTimeZoneAction';
import { loadProfileCWL } from '_Actions/myProfile/profileCWLAction';
import { loadLanguagePreferences } from '_Actions/myProfile/languagePreferencesAction';
import { loadProfile } from '_Actions/myProfile/profileAction';
import { _checkbox } from '../common/formFields';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from 'universal-cookie';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import DisclaimerPopup from '_Commons/disclaimerPopup';
const cookies = new Cookies();
let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let isSuperAdmin = cookiesArray[60];


let count1 = 0;
let count2 = 0;

class BottomSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			//updatebtnShowHide:false,
			//inputClassName:true,
			//textInputType1:"text",
			//textInputType2:"hidden",
			//titleErrorColor:"error",
			//CWLErrorColor:"error",
			//buildingErrorColor:"error",
			//cubicleErrorColor:"error",
			//dateFormatErrorColor:"error",
			//timeZoneErrorColor:"error",
			//currencyErrorColor:"error",
			building1: this.props.profileDetails.building,
			updatedMsg: 0,
			cwlClassname: "editInput",
			buildingClassname: "editInput",
			cubicleClassname: "editInput",
			dateFormatClassname: "editInput",
			timeZoneClassname: "editInput",
			currencyClassname: "editInput",
			languagePreferencesClassname: "editInput",
			isLessMoreGroup: false,
			isLessMore: false,
			isNotificationSwitchChecked: false,
			languagePreferences: "",
		};
		count1 = 0;
		count2 = 0;
		this.setLessMoreGroupFun = this.setLessMoreGroupFun.bind(this);
		this.setLessMoreFun = this.setLessMoreFun.bind(this);
	}

	setLessMoreGroupFun() {
		this.setState({ isLessMoreGroup: !this.state.isLessMoreGroup });
	}
	setLessMoreFun() {
		this.setState({ isLessMore: !this.state.isLessMore });
	}

	componentDidMount() {
		this.getOrganizationalDetails();
	}

	componentWillMount() {
		this.props.loadProfile();
	}

	componentWillReceiveProps(nextProps) {
		if (Object.keys(nextProps.profileDetails).length > 0 && nextProps.profileDetails.languagePreference) {
			this.setState({ languagePreferences: nextProps.profileDetails.languagePreference})
		}
	}

	onTabSelection(k) {
		if (k == 2)
			this.getApplicationPreferences();
	}

	getOrganizationalDetails() {
		if (count1 === 0) {
			this.props.loadProfileCWL();
			count1++;
		}
	}

	getApplicationPreferences() {
		if (count2 === 0) {
			this.props.loadProfileCurrency();
			this.props.loadProfileDateFormat();
			this.props.loadProfileTimeZone();
			this.props.loadLanguagePreferences("language");
			count2++;
		}
	}

	myInputCallBackFun(mySelect, field) {
	
		if (mySelect.className === "editInput" || mySelect.className === "editInput  ") {
			mySelect.className = "form-control showSelect";
			mySelect.disabled = false;
			mySelect.tabIndex = 0
			mySelect.focus();
			this.setState({
				updatedMsg: 0
			})
			
		}
		else {
			mySelect.className = "editInput";
			mySelect.disabled = true;
			mySelect.tabIndex = -1
			this.setState({
				updatedMsg: 1
			})
		}
		switch (field) {
			case "cwl": this.setState({ cwlClassname: mySelect.className }); 
			this.props.isSaveCWLClicked(false);
			break;
			case "building": this.setState({ buildingClassname: mySelect.className }); 
			this.props.isSaveBuildingClicked(false); 
			break;
			case "cubicle": this.setState({ cubicleClassname: mySelect.className }); 
			this.props.isSaveCubicleClicked(false); 
			break;
			case "dateFormat": this.setState({ dateFormatClassname: mySelect.className }); 
			this.props.isSaveDateFormatClicked(false); 
			break;
			case "timeZone": this.setState({ timeZoneClassname: mySelect.className }); 
			this.props.isSaveTimeZoneClicked(false); 
			break;
			case "currency": this.setState({ currencyClassname: mySelect.className }); 
			this.props.isSaveCurrencyClicked(false); 
			break;
			case "languagePreferences": this.setState({ languagePreferencesClassname: mySelect.className }); 
			this.props.isSaveLanguagePreferencesClicked(false); 
			break;
		}
	}

	
	changeCWL(e) {
		this.props.updateCWL(e.target.value);
	}

	changeBuilding(e) {
		this.props.updateBuilding(e.target.value);
	}

	changeCubical(e) {
		this.props.updateCubicle(e.target.value);
	}

	changeDataFormat(e) {
		this.props.updateDateFormat(e.target.value);
	}

	changeTimeZone(e) {
		this.props.updateTimeZone(e.target.value);
	}

	changeCurrency(e) {
		this.props.updateCurrency(e.target.value);
	}

	changeMostPopular(e) {
		let value = false;
		if (e.target.checked) {
			value = "Y";
		} else {
			value = "N";
		}
		this.props.updateMostPopular(value);
	}

	changeNewLaunches(e) {
		let value = false;
		if (e.target.checked) {
			value = "Y";
		} else {
			value = "N";
		}
		this.props.updateNewLaunches(value);
	}

	changeComingSoon(e) {
		let value = false;
		if (e.target.checked) {
			value = "Y";
		} else {
			value = "N";
		}
		this.props.updateComingSoon(value);
	}

	changeFree(e) {
		let value = false;
		if (e.target.checked) {
			value = "Y";
		} else {
			value = "N";
		}
		this.props.updateFree(value);
	}

	changeMyFavourites(e) {
		let value = false;
		if (e.target.checked) {
			value = "Y";
		} else {
			value = "N";
		}
		this.props.updateMyFavourites(value);
	}

	changeCategoryView(e) {
		let value = false;
		if (e.target.checked) {
			value = "Y";
		} else {
			value = "N";
		}
		this.props.updateCategoryView(value);
	}

	changeFeatured(e) {
		let value = false;
		if (e.target.checked) {
			value = "Y";
		} else {
			value = "N";
		}
		this.props.updateFeatured(value);
	}

	changeLanguage(e) {
		this.setState({ languagePreferences: e.target.value });
		this.props.updateLanguage(e.target.value);
	}

	renderProfileCurrency(breakFixData) {
		if (!breakFixData) {
			return (
				<option></option>
			);
		}
		return breakFixData.map((breakFixObj) => {
			return (
				<option value={breakFixObj.KEY}>{breakFixObj.VALUE}</option>
			);
		});
	}



	renderLanguagesDropdown(languages) {
		let languagesSort = languages.sort((a, b) => a.field1Key.localeCompare(b.field1Key));
		if (languagesSort.length === 0) {
			return (
				<option />
			);
		}

		return languagesSort.map((languages) => {
			return (
				<option value={languages.field1Value}>{languages.field1Key}</option>
			);
		});
	}

	renderProfileDateFormat(breakFixData) {
		if (!breakFixData) {
			return (
				<option></option>
			);
		}
		return breakFixData.map((breakFixObj) => {
			return (
				<option value={breakFixObj.KEY}>{breakFixObj.VALUE}</option>
			);
		});
	}

	renderProfileTimeZone(breakFixData) {
		if (!breakFixData) {
			return (
				<option></option>
			);
		}
		return breakFixData.map((breakFixObj) => {
			return (
				<option value={breakFixObj.KEY}>{breakFixObj.VALUE}</option>
			);
		});
	}

	renderProfileCWL(breakFixData) {
		if (!breakFixData) {
			return null;
		}
		return breakFixData.map((breakFixObj) => {
			return (
				<option value={breakFixObj.LOCATION_ID}>{breakFixObj.LOCATION_NAME}</option>
			);
		});
	}

	
	render() {
		if (this.props.profileDetails.length == 0) {
			return (
				<Spinner spinnerName="three-bounce" />
			);
		}

		let groupNameTxt = this.props.profileDetails.groupName?.split(',').map(str => <p>{str}</p>);
		let roleTxt = this.props.profileDetails.roleName?.split(',').map(str => <p>{str}</p>);

		return (
			<div className="margin-t-20">
				<div className="tabStyle">
				<Tabs id="myProfileLeftSidePanel" defaultActiveKey={1} onSelect={(k) => this.onTabSelection(k)}>
					<Tab eventKey={1} title={this.props.tr['Organizational Information']}>

							<Row>
								<Col sm={5} xs={12}>
									<div className="inLineLabel inLineLabDv">

										<Form.Group className='form-group'>
											<Form.Label bsClass="">{this.props.tr['Company']}</Form.Label>
											<div className="formOpt">{this.props.profileDetails.companyName}</div>
										</Form.Group>

										{this.props.profileDetails.datasource != 'Automated' ?

											<Form.Group className='form-group'>
												<Form.Label bsClass="">{this.props.tr['Organization']}</Form.Label>
												<div className="formOpt">{this.props.profileDetails.organizationName}</div>
											</Form.Group>
											: ""}


										<Form.Group className='form-group'>
											<Form.Label bsClass="">{this.props.tr['Department']}</Form.Label>
											<div className="formOpt">{this.props.profileDetails.departmentName}</div>
										</Form.Group>


										<Form.Group className='form-group'>
											<Form.Label bsClass="">{this.props.tr['Cost Center']}</Form.Label>
											<div className="formOpt">{this.props.profileDetails.costcenterName}</div>
										</Form.Group>

										<Form.Group className='form-group'>
											<Form.Label bsClass="">{this.props.tr['Manager']}</Form.Label>
											<div className="formOpt">{this.props.profileDetails.managerName}</div>
										</Form.Group>

									</div>
								</Col>
								<Col sm={6} xs={12}>
									<div className="inLineLabel">
										<Form.Group className='form-group'>
											<Form.Label bsClass="">{this.props.tr['Location']}</Form.Label>
											<div className="formOpt">{this.props.profileDetails.locationName}</div>
										</Form.Group>

										{this.props.profileDetails.datasource != 'Automated' ?
												<Form.Group className='form-group'>
													<Form.Label bsClass="">{this.props.tr['Current Working Location']} <a title={this.props.tr['Edit']} role="button" href={void (0)} onClick={() => { this.myInputCallBackFun(this.element_2, "cwl") }}>
													<HiOutlinePencilAlt />
														</a>
														</Form.Label>
													<div className="formOpt editSelect">
														<select aria-label={this.props.tr['Current Working Location']} tabIndex={-1} disabled className={this.props.saveCWLClicked ? "editInput" : this.state.cwlClassname} ref={(input) => { this.element_2 = input; }} onChange={this.changeCWL.bind(this)} defaultValue={this.props.profileDetails.cwlId}>
														<option value="0">Select</option>
														{this.renderProfileCWL(this.props.CWLDetails)}
													</select></div>
												</Form.Group> : ""}

										<Form.Group className='form-group'>
											<Form.Label bsClass="">
												{this.props.tr['Building']} {this.props.profileDetails.datasource != 'Automated' ? <a title={this.props.tr['Edit']} role="button" href={void(0)} onClick={() => { this.myInputCallBackFun(this.element_3, "building") }}><HiOutlinePencilAlt /></a> : ""}
											</Form.Label>
											<textarea
												aria-label={this.props.tr['Building']}
												className={this.props.saveBuildingClicked ? "editInput" : this.state.buildingClassname}
												disabled={this.props.profileDetails.datasource != 'Automated' ? false : true}
												defaultValue={this.props.profileDetails.building}
												type="text"
												tabIndex={-1}
												maxLength="100"
												ref={(input) => { this.element_3 = input; }}
												onChange={this.changeBuilding.bind(this)} />
										</Form.Group>

										<Form.Group className='form-group'>
											<Form.Label bsClass="">{this.props.tr['Cubicle']} {this.props.profileDetails.datasource != 'Automated' ?
												<a title={this.props.tr['Edit']} role="button" href={void(0)} onClick={() => { this.myInputCallBackFun(this.element_4, "cubicle") }}><HiOutlinePencilAlt /></a> : ""}</Form.Label>
											<textarea aria-label={this.props.tr['Cubicle']} maxLength="100" tabIndex={-1} className={this.props.saveCubicleClicked ? "editInput" : this.state.cubicleClassname} disabled={this.props.profileDetails.datasource != 'Automated' ? false : true} defaultValue={this.props.profileDetails.cubicle} type="text" ref={(input) => { this.element_4 = input; }} onChange={this.changeCubical.bind(this)} />
										</Form.Group>
									</div>
								</Col>
							</Row>
					</Tab>

					<Tab eventKey={2} title={this.props.tr["User Preferences"]}>
						{this.props.dateFormatDetails.length == 0 || this.props.timeZoneDetails.length == 0 || this.props.currencyDetails.length == 0 ? <Spinner spinnerName="three-bounce" /> :
							<Row>
								<Col sm={5} xs={12}>
									<div className="inLineLabel inLineLabDv">
										<Form.Group className='form-group bor-bottom padding-b-10'>
											<Form.Label bsClass="">{this.props.tr['Notifications']}</Form.Label>
											<div className="formOpt notiChkBtn"><Form.Check type="switch" 
											checked={this.state.isNotificationSwitchChecked}
											onClick={(e) => {this.setState({ isNotificationSwitchChecked: e.target.checked });}} id="custom-switch" label={this.state.isNotificationSwitchChecked ? "Turn On" : "Turn Off"} /></div>
										</Form.Group>
										<Form.Group className='form-group'>
											<Form.Label bsClass="">{this.props.tr['Date Format']} <a title={this.props.tr['Edit']} role="button" href={void(0)} onClick={() => { this.myInputCallBackFun(this.element_5, "dateFormat") }}><HiOutlinePencilAlt /></a></Form.Label>
											<div className="formOpt editSelect"><select aria-label={this.props.tr['Date Format']} tabIndex={-1} disabled className={this.props.saveDateFormatClicked ? "editInput" : this.state.dateFormatClassname} ref={(input) => { this.element_5 = input; }} onChange={this.changeDataFormat.bind(this)} defaultValue={this.props.profileDetails.dateformat}>
												{this.renderProfileDateFormat(this.props.dateFormatDetails)}
											</select></div>
										</Form.Group>

										<Form.Group className='form-group'>
											<Form.Label bsClass="">{this.props.tr['Time Zone']} <a title={this.props.tr['Edit']} role="button" href={void(0)} onClick={() => { this.myInputCallBackFun(this.element_6, "timeZone") }}><HiOutlinePencilAlt /></a></Form.Label>
											<div className="formOpt editSelect"><select aria-label={this.props.tr['Time Zone']} tabIndex={-1} disabled className={this.props.saveTimeZoneClicked ? "editInput" : this.state.timeZoneClassname} ref={(input) => { this.element_6 = input; }} onChange={this.changeTimeZone.bind(this)} defaultValue={this.props.profileDetails.timezoneid}>
												{this.renderProfileTimeZone(this.props.timeZoneDetails)}
											</select></div>
										</Form.Group>

										<Form.Group className='form-group'>
											<Form.Label bsClass="">{this.props.tr['Currency']} <a title={this.props.tr['Edit']} role="button" href={void(0)} onClick={() => { this.myInputCallBackFun(this.element_7, "currency") }}><HiOutlinePencilAlt /></a></Form.Label>
											<div className="formOpt editSelect"><select aria-label={this.props.tr['Currency']} tabIndex={-1} disabled className={this.props.saveCurrencyClicked ? "editInput" : this.state.currencyClassname} ref={(input) => { this.element_7 = input; }} onChange={this.changeCurrency.bind(this)} defaultValue={this.props.profileDetails.baseCurrency}>
												{this.renderProfileCurrency(this.props.currencyDetails)}
											</select></div>
										</Form.Group>
{console.log("this.props.profileDetails.languagePreference",this.props.profileDetails.languagePreference)}
										<Form.Group className='form-group'>
											<Form.Label bsClass="">{this.props.tr['Language Preferences']} <a title={this.props.tr['Edit']} role="button" href={void(0)} onClick={() => { this.myInputCallBackFun(this.element_15, "languagePreferences") }} ><HiOutlinePencilAlt /></a></Form.Label>
											<div className="formOpt editSelect"><Form.Select bsPrefix=' ' aria-label={this.props.tr['Language Preferences']} tabIndex={-1} disabled className={this.props.saveLanguagePreferencesClicked ? "editInput" : this.state.languagePreferencesClassname} ref={(input) => { this.element_15 = input; }} onChange={this.changeLanguage.bind(this)} value={this.state.languagePreferences}>
												{this.renderLanguagesDropdown(this.props.languageDetails)}
											</Form.Select> <DisclaimerPopup /></div>
										</Form.Group>
									</div>
								</Col>
                                  {isSuperAdmin == 'Y' ? '': <>
									<Col sm={3} xs={6}>
									<div className="inLineLabel">
										<Form.Group className='form-group'>
											<div className="dCheckbox">
												<label aria-label={this.props.tr['Best Selling']}>
													<input  className="editInput" ref={(input) => { this.element_8 = input; }}
														type="checkbox" defaultChecked={this.props.profileDetails.bestSelling == "Y"}
														onChange={this.changeMostPopular.bind(this)} />
													<p className="custmChkLbl"></p>
												</label>
											</div>
											<Form.Label className="checkLabel" bsClass="">{this.props.tr['Best Selling']}</Form.Label>
										</Form.Group>

										<Form.Group className='form-group'>
											<div className="dCheckbox">
												<label aria-label={this.props.tr['New Launches']}>
													<input className="editInput" ref={(input) => { this.element_9 = input; }}
														type="checkbox" defaultChecked={this.props.profileDetails.newLaunch == "Y"}
														onChange={this.changeNewLaunches.bind(this)} />
													<p className="custmChkLbl"></p>
												</label>
											</div>
											<Form.Label className="checkLabel" bsClass="">{this.props.tr['New Launches']} </Form.Label>
										</Form.Group>

										<Form.Group className='form-group'>
											<div className="dCheckbox">
												<label aria-label={this.props.tr['Coming Soon']}>
													<input  className="editInput" ref={(input) => { this.element_10 = input; }}
														type="checkbox" defaultChecked={this.props.profileDetails.comingSoon == "Y"}
														onChange={this.changeComingSoon.bind(this)} />
													<p className="custmChkLbl"></p>
												</label>
											</div>
											<Form.Label className="checkLabel" bsClass="">{this.props.tr['Coming Soon']} </Form.Label>
										</Form.Group>

										<Form.Group className='form-group'>
											<div className="dCheckbox">
												<label aria-label={this.props.tr['Free']}>
													<input  className="editInput" ref={(input) => { this.element_11 = input; }}
														type="checkbox" defaultChecked={this.props.profileDetails.free == "Y"}
														onChange={this.changeFree.bind(this)} />
													<p className="custmChkLbl"></p>
												</label>
											</div>
											<Form.Label className="checkLabel" bsClass="">{this.props.tr['Free']} </Form.Label>
										</Form.Group>
									</div>
								</Col>

								<Col sm={3} xs={6}>
									<div className="inLineLabel">
										<Form.Group className='form-group'>
											<div className="dCheckbox">
												<label aria-label={this.props.tr['Featured']}>
													<input  className="editInput" ref={(input) => { this.element_14 = input; }}
														type="checkbox" defaultChecked={this.props.profileDetails.featured == "Y"}
														onChange={this.changeFeatured.bind(this)} />
													<p className="custmChkLbl"></p>
												</label>
											</div>
											<Form.Label className="checkLabel" bsClass="">{this.props.tr['Featured']} </Form.Label>
										</Form.Group>

										<Form.Group className='form-group'>
											<div className="dCheckbox">
												<label aria-label={this.props.tr['My Favourites']}>
													<input  className="editInput" ref={(input) => { this.element_12 = input; }}
														type="checkbox" defaultChecked={this.props.profileDetails.myFavourite == "Y"}
														onChange={this.changeMyFavourites.bind(this)} />
													<p className="custmChkLbl"></p>
												</label>
											</div>
											<Form.Label className="checkLabel" bsClass="">{this.props.tr['My Favourites']} </Form.Label>
										</Form.Group>

										<Form.Group className='form-group'>
											<div className="dCheckbox">
												<label aria-label={this.props.tr['Category View']}>
													<input  className="editInput" ref={(input) => { this.element_13 = input; }}
														type="checkbox" defaultChecked={this.props.profileDetails.catwise == "Y"}
														onChange={this.changeCategoryView.bind(this)} />
													<p className="custmChkLbl"></p>
												</label>
											</div>
											<Form.Label className="checkLabel" bsClass="">{this.props.tr['Category View']} </Form.Label>
										</Form.Group>
									</div>
										</Col>
										</>}
							</Row>
						}
					</Tab>
					<Tab eventKey={3} title={this.props.tr["Current Group Membership"]}>
						<Row>
							<Col xs={12}>
								<div className="proPtag">
									{this.props.profileDetails.groupName != null ?
										(groupNameTxt) : <div className='text-c margin-b-10'>{this.props.tr['There is no data to display']}</div>}
								</div>
							</Col>
						</Row>
					</Tab>
					<Tab eventKey={4} title={this.props.tr["Current Roles"]}>
							<Row>
								<Col xs={12}>
									<div className="proPtag">
									{this.props.profileDetails.roleName != null ?
										(roleTxt) : <div className='text-c margin-b-10'>{this.props.tr['There is no data to display']}</div>}
									</div>
								</Col>
							</Row>
						</Tab>
				</Tabs>
				</div>
			</div>
		);
	}
}

export function mapStateToProps({ dateFormatDetails, timeZoneDetails, currencyDetails, languageDetails, CWLDetails }) {
	return { dateFormatDetails, timeZoneDetails, currencyDetails, CWLDetails, languageDetails };
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadProfileCurrency, loadProfileDateFormat, loadProfileTimeZone, loadProfileCWL, loadLanguagePreferences,loadProfile }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BottomSection);

//export default XsmBreakFixFormTab;
