
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef, useContext } from "react";
import { MyContext } from '_MyContext';
import { ListGroup, Row, Col, Tabs, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import AiIcon from "./aiIcon";

const AiInsightsBar = (props) => {
    const editButton = useRef(null);
    const context = useContext(MyContext);
    const tr = useSelector((state) => state.spcmReducer.tr);

    useEffect(() => {
        context.keyDownHandler(editButton.current);
    }, []);

    const handleClick = async () => {
        props.aiInsightsShowFun();
        props.rightEditPanel(true);
    };

    const showInsightIcon = props.isVisible && (props.aismDSAT || props.aiInsightFCREnabled || props.aismChangeRate || props.isReopen);
    return (showInsightIcon && <div className="aiInsightsBtn">
            <Link to="javascript:void(0)" title={tr['AI Insights']} ref={editButton} onClick={handleClick}>
                <AiIcon iconSize={20} /> 
                    <span className="margin-t-5">{tr['Insights']}</span>
                </Link>
        </div>);
};

export default AiInsightsBar;
