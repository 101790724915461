/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import Swal from "sweetalert2";
import axios from "axios";
import { Field, Formik, Form } from "formik";
import { QueryBuilder } from "react-querybuilder";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

import { GLOBAL } from "_Globals";
import CmdbDropdownPlusTypeahead from "../common/CmdbDropdownPlusTypeahead.js";
import Breadcrumbs from "../../common/header/breadcrumbs";
import { useFetchData } from "../add-edit/formfield/hooks.js";
import {
  loadScheduleDetailsByScheduleId,
  emptyScheduleDetailsByScheduleId,
  loadScheduleAssociationDataByScheduleId
} from "_Actions/onCallSchedule/schedule/onCallScheduleAction";

import ScheduleAssociationList from "./scheduleAssociation/scheduleAssociationList";
import ScheduleAssociationForm from "./scheduleAssociation/scheduleAssociationForm";
import RelatedSchedule from "./scheduleAssociation/relatedSchedule";
import EditMaintenanceWindow from "./edit-maintenance.js";
import Header from "./header";

const initialQuery = {
  combinator: "and",
  rules: [],
};

const Index = () => {
  const formikRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tr = useSelector((state) => state.spcmReducer.tr);
  const scheduleDetails = useSelector(
    (state) => state?.on_call_schedule_reducer?.scheduleDetailsByScheduleId
  );

  const on_call_schedule_association_reducer = useSelector(
    (state) => state.on_call_schedule_association_reducer?.scheduleAssociationList
  );


  const maintenanceId = params?.maintenanceWindowId;
  const isAddMode = !maintenanceId;

  const [isRightEditPanel, setIsRightEditPanel] = useState(true);
  const [fields, setFields] = useState([]);
  const [query, setQuery] = useState(initialQuery);
  const [view, setView] = useState("scheduleAssociation");
  const [actionView, setActionView] = useState("scheduleAssociationList");
  const [addEditMode, setAddEditMode] = useState("add");
  const [editFormValues, setEditFormValues] = useState({});
  const [hideButtons, setHideButtons] = useState(false);

  const rightEditPanel = (value) => {
    setIsRightEditPanel(value);
  };

  function setAddEditModeFun(val) {
    setAddEditMode(val);
  }

  function setActionViewFun(val) {
    setActionView(val);
  }

  function setEditFormValuesFun(val) {    
    const isNoEndDate =
    moment(val.scheduleEndDate).valueOf() === 0
    let obj = {
      saId: val.saId,
      scheduleName: val.scheduleAssociationName,
      scheduleType: val.scheduleType,
      onCallStatus: val.scheduleStatus,
      scheduleStartDate: val.is24by7 ? val.scheduleStartDate.slice(0, 10) : val.scheduleStartDate,
      scheduleEndDate: val.is24by7 && !isNoEndDate ? val.scheduleEndDate.slice(0, 10) : val.scheduleEndDate,
      allDaySchedule: '',
      scheduleRepeatType: val.scheduleRepeatType,
      scheduleRepeat: val.scheduleRepeat,
      byDay: val.byDay,
      scheduleStartTime: val.scheduleStartTime,
      scheduleEndTime: val.scheduleEndTime,
      is24by7: val.is24by7
    };
    setEditFormValues(obj);
  }

  const [initVals, setInitVals] = useState({
    scheduleName: "",
    scheduleType: "",
    status: "",
    timeZone: "",
  });

  let initialValues = {
    ...initVals,
  };

  const validationSchema = Yup.object().shape({
    scheduleName: Yup.string().required("Required"),
    scheduleType: Yup.string().required("Required"),
    timeZone: Yup.string().required("Required"),
    status: Yup.string().required("Required"),
  });

  const parseTime = (time) => {
    const [timePart, period] = time.split(" ");
    const [hours, minutes] = timePart.split(":").map(Number);
  
    let formattedHours = hours % 12; // Convert to 12-hour format
    if (period === "PM") {
      formattedHours += 12; // Convert PM hours to 24-hour format
    }
    if (period === "AM" && formattedHours === 12) {
      formattedHours = 0; // Midnight edge case
    }
    return `${String(formattedHours).padStart(2, "0")}:${String(minutes || 0).padStart(2, "0")}:00`;
  };

  
  const onSubmit = async (
    fields,
    { resetForm, setSubmitting, errors, setErrors }
  ) => {
    const PostData = {
      ...fields,
    };
    try {
      const updateData = {
        ...PostData,
        onCallStatus: undefined,
        scheduleId: fields?.scheduleId,
      };

      const content=on_call_schedule_association_reducer[0];
      let scheduleAssociationData={};
     if(content){
       scheduleAssociationData = {
        sxScheduleId: content.scheduleId.scheduleId.toString(),
        scheduleAssociationName: fields?.scheduleName,
        scheduleType: fields.scheduleType,
        scheduleStatus: fields.status,
        scheduleRepeatType: content.scheduleRepeatType,
        scheduleRepeat: content.scheduleRepeat,
        byDay: content.byDay || "",
        scheduleStartDate: new Date(content.scheduleStartDate).getTime(),
        repeatUntil: new Date(content.repeatUntil).getTime(),
        is24by7: content.is24by7,
        scheduleStartTime: parseTime(content.scheduleStartTime),
        scheduleEndTime: parseTime(content.scheduleEndTime),
        scheduleEndDate: new Date(content.scheduleEndDate).getTime(),
      };
    }
      const response = await axios.patch(
        `/rest/aiosem/updateScheduleAction/${fields?.scheduleId}`,
        updateData
      );
    
      if (response?.status === 200) {      
        Swal.fire({
          title: "Maintenance Schedule updated successfully",
          confirmButtonText: "OK",
        }); 
        if(content){
          await axios
            .patch(
              `/rest/aiosem/updateScheduleAssociationAction/${content?.saId}`,
              scheduleAssociationData
            );                     
          await axios.patch(
            GLOBAL.updateMaintenanceWindow(fields?.scheduleId),
            updateData
          );  
        }
        dispatch(loadScheduleAssociationDataByScheduleId(maintenanceId));
        dispatch(loadScheduleDetailsByScheduleId(maintenanceId));
      } else {
        console.warn("Unexpected status code:", response?.status);
      }
    } catch (err) {
      console.log(err,"Unexpected");
      console.error(err?.response?.data || "An error occurred");
      Swal.fire({
        title: err?.response?.data?.businessMessage || "An error occurred",
        confirmButtonText: "OK",
      });
    } finally {
      setSubmitting(false);
    }
  };

  function renderRightSide(actionView, values) {
    switch (actionView) {
      case "scheduleAssociationList":
        return (
          <ScheduleAssociationList
            hideButtons={hideButtons}
            scheduleId={maintenanceId}
            setActionViewFun={setActionViewFun}
            setAddEditModeFun={setAddEditModeFun}
            setEditFormValuesFun={setEditFormValuesFun}
          />
        );
      case "scheduleAssociationForm":
        return (
          <ScheduleAssociationForm
            scheduleId={maintenanceId}
            setActionViewFun={setActionViewFun}
            addEditMode={addEditMode}
            values={values}
            initialValues={addEditMode == "edit" ? editFormValues : {}}
          />
        );     
    }
  }

  useEffect(() => {
    dispatch(loadScheduleDetailsByScheduleId(maintenanceId));
  }, []);

  useEffect(() => {
    setInitVals((prev) => {
      return {
        ...prev,
        ...scheduleDetails,
        status: scheduleDetails?.onCallStatus,
      };
    });
  }, [scheduleDetails]);

  useEffect(() => {
    if (scheduleDetails) {
      if (
        scheduleDetails.scheduleType !== "On-call" &&
        scheduleDetails.scheduleType !== "Out of Office"
      ) {
        setHideButtons(true);
      }
    }
  }, [scheduleDetails]);

  return (
    <main>
      <Container fluid className="margin-b-15 margin-t-10">
        <Breadcrumbs
          activePageName="Edit"
          parentPageurl="/renderedView/674d2b3f6c6923cb55cf1645"
          parentPageName="Maintenance Window"
        />
      </Container>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <Header
              isSubmitting={formikRef?.current?.isSubmitting}
              dirty={formikRef?.current?.dirty}
              submitForm={formikRef?.current?.handleSubmit}
              formikref={formikRef}
              errors={formikRef?.current?.errors}
              view={view}
              setView={setView}
              rightEditPanel={rightEditPanel}
            />
          </Col>
          <Col xs={12} md={12}>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={onSubmit}
            >
              {({
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values,
                dirty,
                resetForm,
                handleChange,
                isValid,
                submitCount,
              }) => {
                return (
                  <Form noValidate>
                    <PanelGroup direction="horizontal">
                      <Panel
                        id="sidebar"
                        minSize={33}
                        order={1}
                        defaultSize={60}
                        className={
                          isRightEditPanel
                            ? "isShowLeftPanel catBordDv"
                            : "catBordDv"
                        }
                      >
                        <EditMaintenanceWindow
                          setFieldValue={setFieldValue}
                          values={values}
                          isValid={isValid}
                          submitCount={submitCount}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          setFields={setFields}
                        />
                      </Panel>
                      {isRightEditPanel ? (
                        <>
                          <PanelResizeHandle className="isShowLeftPanel resizeHandle">
                            <div className="outlne">
                              <div className="handIcn">
                                <i
                                  className="fa fa-chevron-left"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          </PanelResizeHandle>
                          <Panel minSize={33} order={2} defaultSize={40}>
                            {view === "scheduleAssociation" &&
                              renderRightSide(
                                actionView,
                                values
                              )
                            }                          
                          </Panel>
                        </>
                      ) : (
                        ""
                      )}
                    </PanelGroup>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default Index;
