
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import ReactDOM from "react-dom";
import {Nav,NavDropdown, FormControl,ListGroup, Row, Col, Tabs, Tab} from 'react-bootstrap';
import { Link } from "react-router-dom";
import {GLOBAL} from '_Globals';

import { connect } from 'react-redux';
import { loadCartCount } from '_Actions/common/cartCountAction';
import { getMyApprovalList } from "_Actions/MyApprovals/MyApprovalActions";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import { loadPendingReviewOrderListCount } from "_Actions/reviews/reviewAction";
import { loadExternalLink } from "_Actions/common/externalLinkAction.js";
import dummyImage from "_Images/profileimg.png";
import axios from 'axios';
import { loadProfile } from '_Actions/myProfile/profileAction';
import OnBehalfSection from './OnBehalfSection/OnBehalfSection';
import { setWipSearchApply } from '../../../actions/globalSearch/globalSearchAction';
import {LuFileEdit, LuShoppingCart, LuThumbsUp} from "react-icons/lu";
import {MdOutlineReviews,MdOutlineNotificationsNone} from "react-icons/md";
import {PiBookOpenText} from "react-icons/pi";
import {IoSearch} from 'react-icons/io5';
import {TbBulb} from 'react-icons/tb';
import {BiLinkExternal} from "react-icons/bi";
import { TbClockPin } from "react-icons/tb";
import { TbClockPlus } from "react-icons/tb";
import { FaPersonWalkingDashedLineArrowRight } from "react-icons/fa6";

import NotificationsPop from "./notificationsPop";

import { LuGalleryVerticalEnd } from "react-icons/lu";
import { setWalkupEnabled } from '../../../actions/adminPreferences/adminPreferencesAction';
import KeyboardShortcuts from '../shortcutKeysPopup';

let chat_icon_visible = cookies.get('gph');

if (chat_icon_visible) chat_icon_visible = chat_icon_visible.replace('s:', '');
if (chat_icon_visible) chat_icon_visible = chat_icon_visible.substring(0, chat_icon_visible.lastIndexOf('.'));
chat_icon_visible = chat_icon_visible.split("~");
chat_icon_visible = chat_icon_visible[23];

let top_icon_visible = cookies.get('gph');

if (top_icon_visible) top_icon_visible = top_icon_visible.replace('s:', '');
if (top_icon_visible) top_icon_visible = top_icon_visible.substring(0, top_icon_visible.lastIndexOf('.'));
top_icon_visible = top_icon_visible.split("~");
top_icon_visible = top_icon_visible[24];

let password_icon_visible = cookies.get('gph');

if (password_icon_visible) password_icon_visible = password_icon_visible.replace('s:', '');
if (password_icon_visible) password_icon_visible = password_icon_visible.substring(0, password_icon_visible.lastIndexOf('.'));
password_icon_visible = password_icon_visible.split("~");
password_icon_visible = password_icon_visible[25];

let lucy_icon_visible = cookies.get('gph');

if (lucy_icon_visible) lucy_icon_visible = lucy_icon_visible.replace('s:', '');
if (lucy_icon_visible) lucy_icon_visible = lucy_icon_visible.substring(0, lucy_icon_visible.lastIndexOf('.'));
lucy_icon_visible = lucy_icon_visible.split("~");
lucy_icon_visible = lucy_icon_visible[26];

let external_link_visible = cookies.get('gph');

if (external_link_visible) external_link_visible = external_link_visible.replace('s:', '');
if (external_link_visible) external_link_visible = external_link_visible.substring(0, external_link_visible.lastIndexOf('.'));
external_link_visible = external_link_visible.split("~");
external_link_visible = external_link_visible[27];

let chatbot_id = cookies.get('gph');

if (chatbot_id) chatbot_id = chatbot_id.replace('s:', '');
if (chatbot_id) chatbot_id = chatbot_id.substring(0, chatbot_id.lastIndexOf('.'));
chatbot_id = chatbot_id.split("~");
chatbot_id = chatbot_id[28] + '?card={"type":"AdaptiveCard","body":[{"type":"TextBlock","size":"Medium","weight":"Bolder","text":"Chat with Service Desk:"},{"type":"TextBlock","text":"Please enter problem statement in brief and click OK to initiate chat with Service Desk. Ex: Outlook not working / Follow up on existing issue etc","wrap":true},{"type":"TextBlock","text":"Chat Type Purpose","wrap":true,"weight":"Bolder"},{"type":"ColumnSet","columns":[{"type":"Column","width":"120px","items":[{"type":"Input.ChoiceSet","id":"chatType","style":"expanded","isMultiSelect":false,"choices":[{"title":"Issue","value":"Issue"},{"title":"Information","value":"Information"},{"title":"Follow-up","value":"followup"}],"separator":true,"value":"Issue","spacing":"None"}],"spacing":"None"},{"type":"Column","width":"320px","items":[{"type":"Input.Text","placeholder":"Problem Statement.","isMultiline":true,"id":"problemStatement","spacing":"None"}]}]},{"type":"ActionSet","actions":[{"type":"Action.Submit","title":"Ok","style":"positive"}]}],"$schema":"http://adaptivecards.io/schemas/adaptive-card.json","version":"1.2"}&height=310&width=510&title=Live+Chat&completionBotId=6edd569b-8ef9-4c5f-8d38-80113cd82299';

let emailAddress = cookies.get('gph');

if (emailAddress) emailAddress = emailAddress.replace('s:', '');
if (emailAddress) emailAddress = emailAddress.substring(0, emailAddress.lastIndexOf('.'));
let emailAddressArray = emailAddress.split("~");
emailAddress = emailAddressArray[29];
let insided_community_url = emailAddressArray[37];
let insided_sso_url = emailAddressArray[38];
let insided_login_url = emailAddressArray[39];
let insided_customer = emailAddressArray[40];

let isKnowledgeEnabled = cookies.get('gph');
if (isKnowledgeEnabled) isKnowledgeEnabled = isKnowledgeEnabled.replace('s:', '');
if (isKnowledgeEnabled) isKnowledgeEnabled = isKnowledgeEnabled.substring(0, isKnowledgeEnabled.lastIndexOf('.'));
isKnowledgeEnabled = isKnowledgeEnabled.split("~");
isKnowledgeEnabled = isKnowledgeEnabled[30];

let userLogoutUrl = cookies.get('gph');
if (userLogoutUrl) userLogoutUrl = userLogoutUrl.replace('s:', '');
if (userLogoutUrl) userLogoutUrl = userLogoutUrl.substring(0, userLogoutUrl.lastIndexOf('.'));
userLogoutUrl = userLogoutUrl.split("~");
userLogoutUrl = userLogoutUrl[44];

let cookiesData = cookies.get('gph');
if (cookiesData) cookiesData = cookiesData.replace('s:', '');
if (cookiesData) cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf('.'));
cookiesData = cookiesData.split("~");
let discourseUrl = cookiesData[50];

let onbehalfVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onbehalfVisible =
onbehalfVisible.includes('43') ||
  onbehalfVisible.includes('44') ||
  onbehalfVisible.includes('12') ||
  onbehalfVisible.includes('13') ||
  onbehalfVisible.includes('16') ||
  onbehalfVisible.includes('17') ||
  onbehalfVisible.includes('19') ||
  onbehalfVisible.includes('20');
  
  let cookiesArray = cookies.get('gph');
  if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
  if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
  cookiesArray = cookiesArray.split("~");
  let isSuperAdmin = cookiesArray[60];
  let loggedInCompanyId = cookiesArray[48];
  let rolesArr = cookiesArray[33]?.split(',') || [];
  let oboValue = cookiesArray[76];

class CustomInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			access_token: '',
			insided_access_token: '',
			expires_in: '',
			token_type: '',
			categories: '',
			title: '',
			articles: []
		}
	}

	componentDidMount() {
		// if(isKnowledgeEnabled == "true" || isKnowledgeEnabled == true){
		// 	let authTokenResponse;
		// 	axios.get('/api/insided/token')
		// 		.then(response => {
		// 			// console.log('response==', response);
		// 			authTokenResponse = response;
		// 			this.setState({
		// 				access_token: response.data.access_token,
		// 				expires_in: response.data.expires_in,
		// 				token_type: response.data.token_type
		// 			});
		// 			return axios.get('/user/email/' + emailAddress, { headers: { 'authorization': `bearer ${authTokenResponse.data.access_token}` } })
		// 		})
		// 		.then((res) => {
		// 			// console.log('res====',res);
		// 			let authorId = res.data.userid;

		// 			return axios.get('/v2/categories/' + authorId, { headers: { 'authorization': `bearer ${authTokenResponse.data.access_token}` } })
		// 		})
		// 		.then((res) => {
		// 			// console.log('res====',res);
		// 			let categoryData = res.data.result;

		// 			let categories = '';
		// 			categoryData.forEach((item) => {
		// 				categories = categories + item.id + ',';
		// 			});
		// 			categories = categories.substring(0, categories.length - 1);

		// 			// console.log('categories====',categories);

		// 			this.setState({ categories: categories });
		// 			return axios.get('/v2/topics/search/' + categories, { headers: { 'authorization': `bearer ${authTokenResponse.data.access_token}` } })
		// 		})
		// 		.then((res) => {
		// 			// console.log('final res====',res);
		// 			let allData = res.data.result;
		// 			let displayData = allData;
		// 			if (allData.length > 5)
		// 				displayData = allData.slice(0, 5);

		// 			this.setState({ articles: displayData });
		// 		});

			// axios.get(`/api/generateInsidedToken?email=${emailAddress}`
			// ).then(response => {
			// 	this.setState({
			// 		insided_access_token: response.data.token
			// 	});
			// });
		// }
	}

	searchInsided(searchText) {
		if(isKnowledgeEnabled == "true" || isKnowledgeEnabled == true){
			if (searchText == '') {
				axios.get('/v2/topics/search/' + this.state.categories, { headers: { 'authorization': `bearer ${this.state.access_token}` } })
					.then((res) => {
						let allData = res.data.result;
						let displayData = allData;
						if (allData.length > 5)
							displayData = allData.slice(0, 5);

						this.setState({ articles: displayData });
					});
			}
			else if (searchText.length >= 3) {
				axios.get('/v2/topics/search/' + this.state.categories + '/' + searchText, { headers: { 'authorization': `bearer ${this.state.access_token}` } })
					.then((res) => {
						let allData = res.data.result;
						let displayData = allData;
						if (allData.length > 5)
							displayData = allData.slice(0, 5);

						this.setState({ articles: displayData });
					})
			}
		}
	}

	redirectInsidedPage() {
		let insided_access_token = this.state.insided_access_token;
		if (insided_access_token != '' && insided_access_token != null && insided_access_token != undefined) {
			window.open(`${insided_sso_url}?token=${this.state.insided_access_token}&customer=${insided_customer}`, '_blank');
		} else {
			window.open(`${insided_login_url}`, '_blank');
		}
	}
	render() {
		return (
			<div className="hidden-xs hidden-sm">
				<div className="bgInpt">
					<p>Hi, how can we help?</p>
					<div className="dinSch">
						<FormControl type="text" placeholder="Search" onChange={(e) => { this.searchInsided(e.target.value); this.setState({ title: e.target.value }) }} />
						<a title="search" className="faicn" href={void(0)}><IoSearch/></a>
					</div>
				</div>
				<div className="hdRst"><p>Suggested Articles</p></div>
				{/* {
					this.state.articles.map((article, i) => {
						return (
							<MenuItem onClick={() => this.openInsidedURL(article.title)} eventKey={i}>{article.title} <p>updated by {article.lastContributor.username}</p></MenuItem>
						);
					})
				} */}
				{/* <LinkContainer to="/myArticle"><MenuItem>Promote community categories on your homepage <p>updated by Robil</p></MenuItem></LinkContainer>
				<LinkContainer to="/myArticle"><MenuItem>How to promote categories on your homepage <p>updated by Dheeraj</p></MenuItem></LinkContainer>
				<LinkContainer to="/myArticle"><MenuItem>How community categories on homepage <p>updated by Ajay</p></MenuItem></LinkContainer>
				<LinkContainer to="/myArticle"><MenuItem>Categories on your homepage <p>updated by Robil</p></MenuItem></LinkContainer>
				<LinkContainer to="/myArticle"><MenuItem>How to promote community categories on your homepage <p>updated by Robil</p></MenuItem></LinkContainer>
				<LinkContainer to="/myArticle"><MenuItem className="botLnk">Visit Knowledge Center</MenuItem></LinkContainer> */}
				{/* <MenuItem onClick={() => this.openInsidedURL(this.state.title)} className="botLnk more">More</MenuItem> */}
				{/* <MenuItem onClick={() => this.openInsidedURL(this.state.title)} className="botLnk">Visit Knowledge Center</MenuItem> */}
				{/* <LinkContainer to=""><MenuItem onClick={() => window.open(`https://sso-us-west-2.api.insided.com/auth/token/return?token=${this.state.insided_access_token}&customer=hcl-us`, '_blank')} className="botLnk">Visit Knowledge Center</MenuItem></LinkContainer> */}
			</div>
		);
	}
}

class XSMMainMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			knowledge_access_token: '',
			isOnbehalf:false,
			isShowExternalDropdown:false,
			isShowNotifyDropdown:false,
			isShowMyViewDropdown:false,
			isShowMyAccountDropdown:false,
			isWalkupEnabled: false,
			isKeyShortcuts: false,
		};
		// this.openKnowledgeCenter = this.openKnowledgeCenter.bind(this);
		this.openKnowledge = this.openKnowledge.bind(this);
		this.showOnbehalf = this.showOnbehalf.bind(this);
		this.disableWibSearchFilter = this.disableWibSearchFilter.bind(this);
		this.showDropdown=this.showDropdown.bind(this);
		this.hideDropdown=this.hideDropdown.bind(this);
		this.handleToggle=this.handleToggle.bind(this);
		this.showShortCuts=this.showShortCuts.bind(this);
	}

	
   showDropdown(myState){
		this.setState({[myState]:!this.state.myState});
		// this.setState(prevState => ({[myState]: !prevState.myState}));
	}
	hideDropdown(myState){
		this.setState({[myState]:false});
	}


	showOnbehalf(id){
		this.setState({isOnbehalf:id});
	}

	showShortCuts(key){
		this.setState({isKeyShortcuts:key});
		const root = document.documentElement;
		root?.style.setProperty("--modalBackDrop", 0.5);
	}
	componentWillMount() {
		// let status = "pending";
		// let ModuleValue = [10, 14];
		// const userId = "1";
		this.props.loadCartCount();
		this.props.loadExternalLink();
		// this.props.loadPendingReviewOrderList("", "Breakfix,Item", "Fulfilled,Fixed", "N", "", "requesterId");
		this.props.loadPendingReviewOrderListCount("", "Breakfix,Item", "N", "displayNumber", "desc", "requesterId", "", "","");
		this.getAdminPrefrences(loggedInCompanyId,'Walk-up','23');
		// this.props.loadPendingReviewOrderList("", "Breakfix,Item", "75,25", "N", "", "requesterId");
			// this.props.getMyApprovalList(userId,"desc",status,"1","5",ModuleValue);
		// this.props.loadFunctionByPageAndRole(this.props.pageId);
		// this.props.loadProfile();
		// if(isKnowledgeEnabled == "true" || isKnowledgeEnabled == true){
		// 	axios.get(`/api/generateInsidedToken?email=${emailAddress}`
		// 	).then(response => {
		// 		this.setState({
		// 			knowledge_access_token: response.data.token
		// 		});
		// 	});
		// }
	}
	getAdminPrefrences(companyId,moduleName,propertyID){
		let adminQuery = {
		  "companyId": companyId,
		  "module": moduleName,
		  "propertyId": propertyID
		};
		adminQuery = JSON.stringify(adminQuery);
		axios
		.get("/api/getAdminPreferences", { headers: { query: adminQuery } })
		.then((responseData) => {
			if (responseData.data !== "" || responseData.data[0].length > 0) {
				let walkupEnabled =
				responseData.data[0].value === "True" && rolesArr.includes("11")
					? true
					: false;
				this.setState({ isWalkupEnabled: walkupEnabled });
				this.props.setWalkupEnabled(walkupEnabled);
			}
      })
      .catch((err) => {
        console.log(err);
      });
	}
	popupHoverFun() {
		if (this.extLinkPopup != undefined || this.extLinkPopup != null) {
		ReactDOM.findDOMNode(this.extLinkPopup).setAttribute('title', this.props.tr["External Links"]);
		ReactDOM.findDOMNode(this.extLinkPopup).setAttribute('aria-label', this.props.tr["External Links"]);
		}
		if (this.profilePopup != undefined || this.profilePopup != null) {
		ReactDOM.findDOMNode(this.profilePopup).childNodes[0].setAttribute('title', this.props.tr["My Account"]);
		ReactDOM.findDOMNode(this.profilePopup).childNodes[0].setAttribute('aria-label', this.props.tr["My Account"]);
		}
		if (this.knwledgPopup != undefined || this.knwledgPopup != null) {
		ReactDOM.findDOMNode(this.knwledgPopup).childNodes[0].setAttribute('title', this.props.tr["Knowledge Center"]);
		ReactDOM.findDOMNode(this.knwledgPopup).childNodes[0].setAttribute('aria-label', this.props.tr["Knowledge Center"]);
		}
		if (this.notificationPopup != undefined || this.notificationPopup != null) {
		ReactDOM.findDOMNode(this.notificationPopup).childNodes[0].setAttribute('title', this.props.tr["My Notifications"]);
		ReactDOM.findDOMNode(this.notificationPopup).childNodes[0].setAttribute('aria-label', this.props.tr["My Notifications"]);
		}
		if (this.myView != undefined || this.myView != null) {
		ReactDOM.findDOMNode(this.myView).childNodes[0].setAttribute('title', this.props.tr["My View"]);
		ReactDOM.findDOMNode(this.myView).childNodes[0].setAttribute('aria-label', this.props.tr["My View"]);
		}
	}

	handleToggle(value) {
		this.setState({ isShowNotifyDropdown: value })
	}

	// openKnowledgeCenter(){
	// 	console.log("this.state====");
	// 	console.log(this.state);
	// 	const ssoUrl = `${insided_sso_url}?token=${this.state.knowledge_access_token}&customer=${insided_customer}`;
	// 	window.open(ssoUrl, '_blank');
	// }

	logout() {
		axios.get("/authentication/logout/" + userLogoutUrl).then(() => {
			GLOBAL.isLogout = "true";
		});
	}

	openKnowledge() {
		window.open(discourseUrl, '_blank');
		this.disableWibSearchFilter()
	}
    disableWibSearchFilter(){
		this.props.setWipSearchApply(false);
	}
	render() {
		 let UserImg = this.props.userImage != null && this.props.userImage != "" ?this.props.userImage : 	dummyImage;

		let UserImgStyle = { backgroundImage: `url(${UserImg})` };
		let meta = this.props.myReviewOrdersListMeta;
		const cartCount = this.props.cartCount;
		let externalLinks = this.props.externalLinks;
		let yesCount = 0;
		let noCount = 0;
		if (externalLinks.length != 0 && externalLinks != "") {
			externalLinks.map(function (value, index) {
				value.topThree == "N" ? noCount = noCount + 1 : yesCount = yesCount + 1;
			}, this)
		}

		return (
			<div className='headerNavSec'>
				<Nav role="list" bsPrefix=' ' className="headerNavigation" as={"ul"}>
				{yesCount >= 1 ? externalLinks.length != 0 && externalLinks != "" ?
					externalLinks.map(function (value, index) {
						return (
							value.topThree == "Y" ?
								<Nav.Item key={index} as="li" id={"extLink" + index} title={value.link_Name} className="tIcextLik d-none d-lg-inline-block"><Nav.Link target="_blank" as={Link} to={value.link_URL} onClick={()=>this.disableWibSearchFilter()}><span className='tIcextLikIcn' style={{ backgroundImage: `url(${value.link_Icon})` }}></span></Nav.Link></Nav.Item> : null
						)
					}, this)
					: null : null}

				{noCount >= 1 ? externalLinks.length != 0 && externalLinks != "" ?
					<NavDropdown key={11} bsPrefix=' ' as="li" show={this.state.isShowExternalDropdown} onMouseEnter={()=>{this.showDropdown('isShowExternalDropdown')}} onMouseLeave={()=>{this.hideDropdown('isShowExternalDropdown')}} onMouseOver={this.popupHoverFun.bind(this)} ref={e => this.extLinkPopup = e} href={void(0)} className="topNavIcn extLink d-none d-lg-inline-block" title={<BiLinkExternal />}>
						{externalLinks.map(function (value, index) {
							return (
								value.topThree == "N" ?
									<NavDropdown.Item key={index} bsPrefix=' ' id={"extLink" + index} href={value.link_URL} title={value.link_Name} target="_blank" onClick={()=>{this.hideDropdown('isShowExternalDropdown')}}><img src={value.link_Icon} /> {value.link_Name}</NavDropdown.Item>
									: null
							)
						}, this)}
					</NavDropdown> 
					: null : null}

				{/* {lucy_icon_visible == "true"?
				<NavItem href="https://aexdemo-lucy-next.mybluemix.net/" eventKey={7} className="topNav lucy" title={this.props.tr['Enterprise Chat Bot–Lucy']} target="_blank"></NavItem>:null
				}
				{lucy_icon_visible == "true"?
				<NavItem href="https://noq.dwpautomation.com/web" eventKey={68} className="topNav noq" title={this.props.tr['Virtual IntelliZone–NoQ']} target="_blank"></NavItem> :null
				} */}

				{/* {top_icon_visible == "true" ?
					<NavItem href="https://thomsonreuters.thefutureis.mobi"  className="topNav tangoe hidden-xs hidden-sm" title={this.props.tr['Tangoe Mobi']} target="_blank"></NavItem> : null
				} */}

				{/* {top_icon_visible == "true" ?
					<NavItem href="sip:chat@trservicedesk.com"  className="topNav chaticon" title={this.props.tr['Chat with IT Support']}></NavItem> : null
				} */}
				{/* {chat_icon_visible == "true" ?
					<NavItem target="_blank" href="sip:globalit.servicedesk@hcl.com"  className="topNav chaticon" title={this.props.tr['Chat with IT Support']}></NavItem> : null
				} */}

				{/* <Nav.Item as="li" className="topNavIcn d-none d-lg-inline-block menuNotiIcn" title={this.props.tr['My Notifications']}><Nav.Link className={this.props.currentLocation == "/myNotifications" ? "active" : ""} as={Link} to="/myNotifications" onClick={()=>{this.disableWibSearchFilter()}}><MdOutlineNotificationsNone /><span className="approvalCount">{9}</span></Nav.Link></Nav.Item> */}

				<NavDropdown as="li" onToggle={this.handleToggle} show={this.state.isShowNotifyDropdown} onMouseOver={this.popupHoverFun.bind(this)} ref={e => this.notificationPopup = e} href={void(0)} className={this.props.currentLocation == "/myNotifications" ? "active topNavIcn d-none d-lg-inline-block menuNotiIcn" : "topNavIcn d-none d-lg-inline-block menuNotiIcn"} title={<><MdOutlineNotificationsNone />{(this.props.notificationCount && this.props.notificationCount != '0') ? <span className="approvalCount">{this.props.notificationCount}</span> : null}</>}>
					<NotificationsPop handleToggle={this.handleToggle} showDropdown={this.state.isShowNotifyDropdown} sendMessage={this.props.sendMessage} />
				</NavDropdown>

				<NavDropdown as="li" show={this.state.isShowMyViewDropdown} onMouseEnter={()=>{this.showDropdown('isShowMyViewDropdown')}} onMouseLeave={()=>{this.hideDropdown('isShowMyViewDropdown')}} onMouseOver={this.popupHoverFun.bind(this)} ref={e => this.myView = e} href={void(0)} className="topNavIcn d-none d-lg-inline-block menuViewIcn" title={<LuFileEdit />}>
					{cartCount.isRequestVisible == true ? <NavDropdown.Item as="li"><Nav.Link as={Link} to="/myOrders" onClick={()=>{this.disableWibSearchFilter();this.hideDropdown('isShowMyViewDropdown');}} title={this.props.tr['My Requests']}>{this.props.tr["My Requests"]}</Nav.Link></NavDropdown.Item> : null}

					{this.state.isWalkupEnabled && <NavDropdown.Item as="li"><Nav.Link as={Link} to="/walkUpReq" onClick={()=>{this.disableWibSearchFilter();this.hideDropdown('isShowMyViewDropdown');}} title={this.props.tr['My Walk Up Requests']}>{this.props.tr['My Walk Up Requests']}</Nav.Link></NavDropdown.Item>}

					<NavDropdown.Item as="li"><Nav.Link as={Link} to="/myAssets" onClick={()=>{this.disableWibSearchFilter();this.hideDropdown('isShowMyViewDropdown');}} title={this.props.tr['My Assets/CIs']}>{this.props.tr["My Assets/CIs"]}</Nav.Link></NavDropdown.Item>

					<NavDropdown.Item as="li"><Nav.Link as={Link} to="/myNotifications" onClick={()=>{this.disableWibSearchFilter();this.hideDropdown('isShowMyViewDropdown');}} title={this.props.tr['My Notifications']}>{this.props.tr["My Notifications"]} {(this.props.notificationCount && this.props.notificationCount != '0') ? <span className="bCount">({this.props.notificationCount})</span> : null}</Nav.Link></NavDropdown.Item>
				</NavDropdown>

				{/* {isSuperAdmin == 'Y'? "":(cartCount.isRequestVisible == true ?
					<Nav.Item as="li" key={6} className="topNavIcn d-none d-lg-inline-block menuOrdIcn" title={<LuFileEdit />}></Nav.Item> : null
				)} */}

				{this.state.isWalkupEnabled && <Nav.Item as="li" className="topNavIcn d-none d-lg-inline-block" title={this.props.tr['Walk Up']}><Nav.Link className={this.props.currentLocation == "/renderedView" ? "active" : ""} as={Link} to="/renderedView/665ec115794d529900518fd8" onClick={()=>{this.disableWibSearchFilter()}}><TbClockPlus className='f-size-22'/></Nav.Link></Nav.Item>}

				{isSuperAdmin == 'Y'? "":(cartCount.isFeedbackVisible == true ?
					<Nav.Item as="li" key={7} className="topNavIcn d-none d-lg-inline-block menuKledicn" title={this.props.tr['Pending Surveys']}><Nav.Link className={this.props.currentLocation == "/reviews" ? "active" : ""} as={Link} to="/reviews" onClick={()=>{this.disableWibSearchFilter()}}><MdOutlineReviews className='f-size-22'/>{meta.recordCount && meta.recordCount != 0 ? <span className="approvalCount">{meta.recordCount}</span> : ""}</Nav.Link></Nav.Item> : null
				)}
				{/* {cartCount.isApprovalVisible == true ?
					<NavDropdown className="topNav knwledg hidden-sm">
						<li id="nKgList">Hi, how can we help?
						</li>
					</NavDropdown>
					: null
				} */}

				{/* {isKnowledgeEnabled == "true" || isKnowledgeEnabled == true ?
					// <LinkContainer to="/myArticle"><NavItem href={void(0)} className="topNav knwledg" title="Knowledge Center"></NavItem></LinkContainer> : null
					// <NavDropdown onMouseOver={this.popupHoverFun.bind(this)} ref={e => this.knwledgPopup = e} id="nKgList" className="topNav knwledg hidden-xs hidden-sm">
					// 	<CustomInput />
					// </NavDropdown>
					null
					: null
				} */}

				{/* {isKnowledgeEnabled == "true" || isKnowledgeEnabled == true ?
					<NavItem href={void(0)} onClick={this.openKnowledgeCenter}eventKey={1} className="topNav knowledgeCenter hidden-xs hidden-sm" title="Knowledge Center"></NavItem>
					 : 
					 null
				} */}

				{isKnowledgeEnabled == "true" || isKnowledgeEnabled == true ?
					<Nav.Item as="li" key={8} className="topNavIcn d-none d-lg-inline-block menuKledicn" title={this.props.tr["Knowledge Center"]}><Nav.Link as={Link} to="#" onClick={this.openKnowledge} ><PiBookOpenText className='f-size-22'/><TbBulb className='knwldgIcn'/></Nav.Link></Nav.Item>
					: null
				}

				{isSuperAdmin == 'Y'? "":(cartCount.isApprovalVisible == true ?
					<Nav.Item as="li" key={9}  className={"topNavIcn d-none d-lg-inline-block menuAppIcn"} title={this.props.tr['My Approvals']}><Nav.Link as={Link} to="/myApprovals" className={this.props.currentLocation == "/myApprovals" ? "active" : ""} onClick={()=>this.disableWibSearchFilter()}><LuThumbsUp/>{cartCount.pendingApprovalCount != 0 ? <span className="approvalCount">{cartCount.pendingApprovalCount}</span> : ""}</Nav.Link></Nav.Item> : null
				)}
				{isSuperAdmin == 'Y'? "":(cartCount.isVisible == true ?
					<Nav.Item as="li" key={10} className="topNavIcn d-none d-lg-inline-block menuCrtIcn" title={this.props.tr['Shopping Cart']}><Nav.Link className={this.props.currentLocation == "/cart" ? "active" : ""} as={Link} to="/cart" onClick={()=>this.disableWibSearchFilter()}><LuShoppingCart/>{cartCount.cartCount != 0 ? <span className="approvalCount">{cartCount.cartCount}</span> : ""}</Nav.Link></Nav.Item> : null
				)}

				{/* <LinkContainer to="/instanceAvailability"><NavItem href={void(0)} className="topNav myrequest" title="Instance Availability"></NavItem></LinkContainer> */}
				
				<NavDropdown as="li" onMouseOver={this.popupHoverFun.bind(this)} ref={e => this.profilePopup = e} href={void(0)} className="profileBox profileBoxMob d-md-block d-lg-none d-xl-none" style={UserImgStyle}>
				<div className='mbPopupScrol'>
                    <div className="userNa"><div className="welTxt">{this.props.tr['Welcome']}</div><div className="userInfo">{this.props.userInfo}</div></div>

                    {cartCount.isRequestVisible == true ? <NavDropdown.Item as="li"><Nav.Link title={this.props.tr['My Requests']} as={Link} to="/myOrders" onClick={()=>this.disableWibSearchFilter()}>{this.props.tr["My Requests"]}</Nav.Link></NavDropdown.Item> : null}

					{this.state.isWalkupEnabled && <NavDropdown.Item as="li"><Nav.Link title={this.props.tr['My Walk Up Requests']} as={Link} to="/walkUpReq" onClick={()=>this.disableWibSearchFilter()}>{this.props.tr['My Walk Up Requests']}</Nav.Link></NavDropdown.Item>}

					<NavDropdown.Item as="li"><Nav.Link title={this.props.tr['My Assets/CIs']} as={Link} to="/myAssets" onClick={()=>this.disableWibSearchFilter()}>{this.props.tr["My Assets/CIs"]}</Nav.Link></NavDropdown.Item>

					<NavDropdown.Item as="li"><Nav.Link title={this.props.tr['My Notifications']} as={Link} to="/myNotifications" onClick={()=>this.disableWibSearchFilter()}>{this.props.tr["My Notifications"]} {(this.props.notificationCount && this.props.notificationCount != '0') ? <span className="bCount">({this.props.notificationCount})</span> : null}</Nav.Link></NavDropdown.Item>

					<NavDropdown.Item as="li"><Nav.Link title={this.props.tr['Walk Up']} as={Link} to="/renderedView/665ec115794d529900518fd8" onClick={()=>this.disableWibSearchFilter()}>{this.props.tr["Walk Up"]}</Nav.Link></NavDropdown.Item>

                    {cartCount.isFeedbackVisible == true ? <NavDropdown.Item as="li" title={this.props.tr['My Surveys']}><Nav.Link as={Link} to="/reviews" onClick={()=>this.disableWibSearchFilter()}>{this.props.tr["My Surveys"]} {meta.recordCount && meta.recordCount != 0 ? <span className="bCount">({meta.recordCount})</span> : ""}</Nav.Link></NavDropdown.Item> : null}

                    {isKnowledgeEnabled == "true" || isKnowledgeEnabled == true ? <NavDropdown.Item as="li" title={this.props.tr["Knowledge Center"]}><Nav.Link as={Link} to="#" onClick={this.openKnowledge} >{this.props.tr["Knowledge Center"]}</Nav.Link></NavDropdown.Item> : null}

                    {cartCount.isApprovalVisible == true ? <NavDropdown.Item as="li"  title={this.props.tr['My Approvals']}><Nav.Link as={Link} to="/myApprovals" onClick={()=>this.disableWibSearchFilter()}>{this.props.tr["My Approvals"]} {cartCount.pendingApprovalCount != 0 ? <span className="bCount">({cartCount.pendingApprovalCount})</span> : ""}</Nav.Link></NavDropdown.Item> : null}

                    {cartCount.isVisible == true ? <NavDropdown.Item as="li" className="" title={this.props.tr['Shopping Cart']}><Nav.Link as={Link} to="/cart" onClick={()=>this.disableWibSearchFilter()}>{this.props.tr["Shopping Cart"]} {cartCount.cartCount != 0 ? <span className="bCount">({cartCount.cartCount})</span> : ""}</Nav.Link></NavDropdown.Item> : null}
 

                    {this.props.roleId.includes('10') || this.props.roleId.includes('11') ? <NavDropdown.Item as="li"><Nav.Link as={Link} title={this.props.tr["My Delegations"]} to="/delegate" onClick={()=>this.disableWibSearchFilter()}>{this.props.tr["My Delegations"]}</Nav.Link></NavDropdown.Item>:""}

                    <NavDropdown.Item as="li"><Nav.Link as={Link} title={this.props.tr["My Profile"]} to="/myProfile" onClick={()=>this.disableWibSearchFilter()}>{this.props.tr["My Profile"]}</Nav.Link></NavDropdown.Item>

					{(onbehalfVisible === true && oboValue === "true") && <NavDropdown.Item as="li">
						<Nav.Link as={"a"} title={this.props.tr["On Behalf of"]} href={void(0)} onClick={()=>{this.showOnbehalf(true);}}>{this.props.tr["On Behalf of"]}</Nav.Link>
					</NavDropdown.Item>}

                    {password_icon_visible == "true" ? <NavDropdown.Item as="li"><Nav.Link as={'a'} title={this.props.tr["Change Password"]} href="https://sspr.hcl.com/" target="_blank" onClick={()=>this.disableWibSearchFilter()}>{this.props.tr["Change Password"]}</Nav.Link></NavDropdown.Item> : null}

                    <NavDropdown.Item as="li"><Nav.Link as={'a'} title={this.props.tr["Logout"]} onClick={()=>{localStorage.removeItem("GlobalSearchDefaultChoice"); GLOBAL.isLogout = "true";this.disableWibSearchFilter();}} href={"/authentication/logout/" + userLogoutUrl}  className="logli">{this.props.tr["Logout"]}</Nav.Link></NavDropdown.Item>                
				</div>
                </NavDropdown>

				<NavDropdown key={12} as="li" show={this.state.isShowMyAccountDropdown} onMouseEnter={()=>{this.showDropdown('isShowMyAccountDropdown')}} onMouseLeave={()=>{this.hideDropdown('isShowMyAccountDropdown')}} onMouseOver={this.popupHoverFun.bind(this)} ref={e => this.profilePopup = e} href={void(0)} className="profileBox d-none d-lg-block" title={<div style={UserImgStyle} className='accimg'></div>} >
					<div className="userNa"><div className="welTxt">{this.props.tr['Welcome']}</div><div className="userInfo">{this.props.userInfo}</div></div>
						 { isSuperAdmin == 'Y'? "":(this.props.roleId.includes('10') || this.props.roleId.includes('11') ? <NavDropdown.Item as="li"><Nav.Link as={Link} title={this.props.tr["My Delegations"]} to="/delegate" onClick={()=>{this.disableWibSearchFilter();this.hideDropdown('isShowMyAccountDropdown');}}>{this.props.tr["My Delegations"]}</Nav.Link></NavDropdown.Item> : "") }
					<NavDropdown.Item as="li"><Nav.Link as={Link} title={this.props.tr["My Profile"]} to="/myProfile" onClick={()=>{this.disableWibSearchFilter();this.hideDropdown('isShowMyAccountDropdown');}}>{this.props.tr["My Profile"]}</Nav.Link></NavDropdown.Item>
					{isSuperAdmin == 'Y'? "":((onbehalfVisible === true && oboValue === "true") && <NavDropdown.Item as="li">
						<Nav.Link as={"a"} title={this.props.tr["On Behalf of"]} href={void(0)} onClick={()=>{this.showOnbehalf(true);this.hideDropdown('isShowMyAccountDropdown');}}>{this.props.tr["On Behalf of"]}</Nav.Link>
					</NavDropdown.Item>)}
					<NavDropdown.Item as="li" className="shortcutLink"><Nav.Link as={Link} title={this.props.tr["Keyboard Shortcuts"]} onClick={()=>{this.showShortCuts(true);this.hideDropdown('isShowMyAccountDropdown');}} to="javascript:void(0)" >{this.props.tr["Keyboard Shortcuts"]}<p>{this.props.tr["Press"]} <span>Ctrl</span><span className="skPlus">+</span><span>\</span></p></Nav.Link></NavDropdown.Item>
					{/* <MenuItem style={{ display: "none" }} title={this.props.tr["Install App"]} href={void(0)} className="pwaInstallApp">{this.props.tr["Install App"]}</MenuItem> */}
					{password_icon_visible == "true" ? <NavDropdown.Item as="li"><Nav.Link as={'a'} title={this.props.tr["Change Password"]} href="https://sspr.hcl.com/" target="_blank" onClick={()=>{this.disableWibSearchFilter();this.hideDropdown('isShowMyAccountDropdown');}}>{this.props.tr["Change Password"]}</Nav.Link></NavDropdown.Item> : null}
					<NavDropdown.Item as="li">
						<Nav.Link as={'a'} title={this.props.tr["Logout"]} onClick={()=>{localStorage.removeItem("GlobalSearchDefaultChoice"); GLOBAL.isLogout = "true";this.disableWibSearchFilter();this.hideDropdown('isShowMyAccountDropdown');this.props.handleLogoutClick();}} href={"/authentication/logout/" + userLogoutUrl}  className="logli">{this.props.tr["Logout"]}</Nav.Link></NavDropdown.Item>
				</NavDropdown>


				{/*<NavItem href="/logoutclient" className="topNav logOut" title="Logout">Logout</NavItem>*/}
				{/* <NavItem href={void(0)}  className="topNav hamBurger" title={this.props.tr['Other Links']} onClick={() => { this.props.rightNav(true); this.props.loadFunctionByPageAndRole(this.props.pageId); }}>Other Links</NavItem> */}
			</Nav>
			
			{this.state.isOnbehalf ? <OnBehalfSection tr={this.props.tr} showModal={this.state.isOnbehalf} showOnbehalf={this.showOnbehalf} /> :null}
			
			{this.state.isKeyShortcuts ? <KeyboardShortcuts tr={this.props.tr} showModal={this.state.isKeyShortcuts} showShortCuts={this.showShortCuts} /> :null}
			
			</div>
		);
	}
}




const mapStateToProps = ({ cartCount, approvalList, myPendingReviewOrdersCount, profileDetails, externalLinks }) => {
	return {
		cartCount, approvalList, externalLinks, profileDetails,
		myPendingReviewOrdersCount: myPendingReviewOrdersCount.data ? myPendingReviewOrdersCount.data : {},
		myReviewOrdersListMeta: myPendingReviewOrdersCount.meta ? myPendingReviewOrdersCount.meta : {}
	}
};

export default connect(mapStateToProps, { loadPendingReviewOrderListCount, loadProfile, loadCartCount, loadExternalLink, getMyApprovalList,setWipSearchApply,setWalkupEnabled })(XSMMainMenu);
