
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef } from 'react';

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from 'react-bootstrap/Alert';
import { useDispatch, useSelector } from 'react-redux';
import {_inputField, _dropDown, _checkbox,TypeaheadExampleSingleSelect } from '../../common/formFields';
import {getUrgencyImpactForSystemConfig, resetUrgencyImpactForSystemConfig} from '../../../actions/systemConfig/systemConfigAction'
import axios from 'axios';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';
import { getCompanyList } from '../../../actions/spcmActions';
const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};
const CreateSurveyConfigForm = (props) => {
  const [companyList, setCompanyList] = useState([]);
  const [typedCompany, setTypedCompany] = useState([]);
  const [companyId, setCompanyId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const dispatch = useDispatch()
  
  const translator = useSelector(state => state.spcmReducer.tr);
  const companyReducer = useSelector((state) => state.spcmReducer.companyList);
  const typeaheadRef = useRef(null);
  const selector = formValueSelector('xsmMailboxConfigCreateForm');
  const mailBoxType = useSelector(state=>selector(state,'MAILBOX_PROVIDER'));
  


  useEffect(() =>
  {
    if(companyReducer && companyReducer.length > 0)
    {
      setCompanyList(companyReducer)
    }
    
  },[companyReducer])

  useEffect(() =>
  {
    if (props.selectedCompanyList && props.selectedCompanyList.length > 0) {
      let data = { "companyId": props.selectedCompanyList[0].field1Value, "module": "Breakfix", "field": "Urgency,Impact", "status": "1", 'language': 'en' }
     // dispatch(getUrgencyImpactForSystemConfig(data))
    }
  },[props.selectedCompanyList])   
  
  let mailBox_type = [
    { text: 'Gmail', value: 'Gmail' },
    {text: 'Outlook', value : 'Outlook'},
  ]
 
  let status = [
    {
      text: 'Active',
      value: 'Active'
    },
    {
      text: 'Inactive',
      value: 'Inactive'
    },
  ]

  useEffect(() => {
    if (typeof (mailBoxType) !== 'undefined') {
      props.setMailBoxType(mailBoxType);
    }
    else {
      props.setMailBoxType(''); 
    }
  }, [mailBoxType])
  useEffect(() => {
    dispatch(getCompanyList(""));
  }, []);

  const setCompany = (selectedCompany) => {
    if (selectedCompany.length > 0) { 
      props.setSelectedCompanyList(selectedCompany);
      setCompanyId(selectedCompany[0].field1Value)
      setCompanyName(selectedCompany[0].field1Key)
      dispatch(change('xsmMailboxConfigCreateForm', 'COMPANY_ID', selectedCompany[0].field1Value))
      dispatch(change('xsmMailboxConfigCreateForm','COMPANY_NAME', selectedCompany[0].field1Key))
    } else {
       
      props.setSelectedCompanyList([]);
    }
  };
  const companyInputChange = (e) => {
    setTypedCompany(e);
    props.setSelectedCompanyList([]);
    setCompany([]);
     
  };

  const onCrossClickCompany = () => {
    companyInputChange("");
    props.setSelectedCompanyList([]);
    props.setSelectedRequestor('');
    setCompanyId('')
    setCompanyName('');
    dispatch(change('xsmMailboxConfigCreateForm', 'COMPANY_ID', ''))
    dispatch(change('xsmMailboxConfigCreateForm', 'COMPANY_NAME', ''))  
  };

 const  validateFieldConfig = (fieldType, event)=> {
    switch (fieldType) {
      case "MAILBOX_ID":
        if (event.target.value == "" ||!validateEmail(event.target.value)) {
          props.setMailboxIdErrorColor("error");
        } else {
          props.setMailboxIdErrorColor("");
        }
        break;
      case "MAILBOX_PROVIDER":
        if (event.target.value == "") {
          props.setMailboxProviderErrorColor("error");
        } else {
          props.setMailboxProviderErrorColor("");
        }
        break;
      case "STATUS":
          if (event.target.value == "") {
            props.setStatusErrorColor("error");
          } else {
            props.setStatusErrorColor("");
          }
          break;
      case "WHITELISTED_DOMAINS":
        if (event.target.value == "") {
          props.setWhitelistedDomainErrorColor("error");
        } else {
          props.setWhitelistedDomainErrorColor("");
        }
        break;
      case "ALWAYS_EXCEPTION":
      case "NEVER_EXCEPTION":
        const emails = event.target.value.split(',').map(email => email.trim());
        const invalidEmails = emails.filter(email => !validateEmail(email));
        if (invalidEmails.length > 0) {
          props.setWhitelistedDomainErrorColor("error");
        } else {
          props.setWhitelistedDomainErrorColor("");
        }
        break;
      default:
    }
  }
  return (
    <div>
        <div className="borderForm bFormDiv">
          <form>
            <Row>
            <Col md={6}>
                <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['Mailbox']}</Form.Label>
                <Field name="MAILBOX_ID" className={"form-control " + (props.mailboxIdErrorColor )} component={_inputField} maxLength={120}
                onBlur={(event) => {
                  validateFieldConfig("MAILBOX_ID", event);
                }}>
                  </Field>
                </Form.Group>
              </Col>
              <Col md={6}>
              <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['Vendor']}</Form.Label>
                <Field name="MAILBOX_PROVIDER" className={"form-control " + (props.mailboxProviderErrorColor)} component={_dropDown} options={mailBox_type}
                onBlur={(event) => {
                  validateFieldConfig("MAILBOX_PROVIDER", event);
                }}>
                  </Field>
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <Col md={6}>
            <Form.Group className="form-group">
                <Form.Label bsClass="">
                  <span className="rStar" />
                  {translator["Company"]}
                </Form.Label>
                <Field
                  ref={typeaheadRef}
                  name="COMPANY_NAME"
                  component={TypeaheadExampleSingleSelect}
                  className="form-control"
                  options={companyList}
                  selectedOptions={props.selectedCompanyList}
                  onSelection={setCompany}
                  errorClass={props.companyError}
                  diableStatus={false}
                  onInputChange={companyInputChange}
                  onCrossClick={onCrossClickCompany}
                  typedValue={typedCompany}
                  setErrorColor={props.setCompanyErrorColor}
                  labelKey={"field1Key"}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group >
              <span className="rStar" />
                  <Form.Label bsClass="" className='margin-r-10'>{translator['Status']}</Form.Label>
                  <Field name="STATUS" className={"form-control " + (props.statusErrorColor)} component={_dropDown} options={status}
                  onBlur={(event) =>{validateFieldConfig("STATUS", event);}}>
                  </Field>
                </Form.Group>
            </Col>
          </Row>
          <div className="margin-t-5 padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
            <div className="bgGray padding-5">
              <span className="rStar"></span>
              {translator["Mailbox Filters"]}
            </div>
            <Row>
            <Col md={4}>
                <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{translator['Whitelisted Domains']}</Form.Label>
                  <Field name="WHITELISTED_DOMAINS" className={"form-control " +(props.whitelistedDomainErrorColor )} component={_inputField} maxLength={120} placeholder={`gmail.com,yahoo.com`}
                  onBlur={(event) => {
                    validateFieldConfig("WHITELISTED_DOMAINS", event);
                  }}>
                  </Field>
                </Form.Group>
              </Col>
              <Col md={4}>
              <Form.Group >
                  <Form.Label bsClass="">{translator[`Always Process Exception`]}</Form.Label>
                <Field name="ALWAYS_EXCEPTION" className="form-control" component={_inputField} maxLength={120} placeholder={`xyz@gmail.com,abc@yahoo.com`}>
                  </Field>
                </Form.Group>
              </Col>
              <Col md={4}>
              <Form.Group >
                  <Form.Label bsClass="">{translator[`Never Process Exception`]}</Form.Label>
                <Field name="NEVER_EXCEPTION" className="form-control" component={_inputField} maxLength={120} placeholder={`abc@gmail.com,xyz@yahoo.com`}>
                  </Field>
                </Form.Group>
              </Col>
            </Row>
          </div>
          </form>
        </div>
      </div>
  )
}

export default reduxForm({
  form: 'xsmMailboxConfigCreateForm',
  destroyOnUnmount: false,
  // validate,
  enableReinitialize: true
})(CreateSurveyConfigForm);