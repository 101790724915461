import { Checkbox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import React, { useEffect } from "react";

export const CheckboxGroupFields = (props) => {
  const {
    attributes,
    onChange,
    language,
    readOnly,
    defaultValues,
    hiddenFIelds,
    readOnlyFields,
    atr,
    errors,
  } = props;
  let defaultVal = [];

  const handleChange = (event, value) => {
    let currentValue = defaultValues[attributes?.name] || [];
    if (currentValue && !Array.isArray(currentValue)) {
      currentValue = currentValue.split(",");
    }
    if (event?.value) {
      currentValue.push(value);
    } else {
      currentValue = currentValue.filter((f) => f !== value);
    }
    onChange(
      attributes?.saveValueIn?.id === "commaSep"
        ? currentValue.join(",")
        : currentValue,
      attributes
    );
  };

  const checkBoxValues = attributes?.values
    ? attributes?.values.split(",")
    : "";
  const labels = attributes?.labels ? attributes?.labels.split(",") : "";

  if (defaultValues && defaultValues.hasOwnProperty(attributes?.name)) {
    if (attributes?.saveValueIn === "commaSep") {
      defaultVal = defaultValues[attributes?.name].split(",");
    } else {
      defaultVal = defaultValues[attributes?.name];
    }
  }

  useEffect(() => {
    if (attributes?.defaultSelected) {
      setTimeout(() => {
        onChange(attributes?.defaultSelected, attributes);
      }, 200);
    }
  }, []);

  const getCheckBoxes = (labels) => {
    if (Array.isArray(labels) && labels.length > 0) {
      return (
        <div
          className={`${
            attributes?.viewType?.id === "horizontal" ? "d-flex" : ""
          } align-items-center`}
        >
          {labels.map((value, index) => {
            return (
              <Checkbox
                className={`${atr?.className} margin-r-10`}
                id={value.replace(/ /g, "")}
                checked={defaultVal.includes(checkBoxValues[index])}
                onChange={(e) => {
                  handleChange(e, checkBoxValues[index]);
                }}
                disabled={
                  attributes?.disabled ||
                  readOnly ||
                  readOnlyFields.includes(attributes?.name)
                }
              >
                <Label
                  editorId={value.replace(/ /g, "")}
                  id={atr?.id}
                  className={`k-checkbox-label text-center ${
                    errors[attributes?.name] && "dvTableError"
                  }`}
                  style={{ display: "block", marginLeft: "5px" }}
                >
                  {value}
                </Label>
              </Checkbox>
            );
          })}
        </div>
      );
    }
  };
  if (!hiddenFIelds.includes(attributes?.name)) {
    return getCheckBoxes(labels);
  } else {
    return null;
  }
};
