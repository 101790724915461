
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Link } from 'react-router-dom';
import {
  ListGroup,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { connect } from "react-redux";
import Attributes from "../Attributes.js";
import ServiceHistory from "./serviceHistory.js";
import ServiceImages from "./serviceImages.js";
import ServiceRatings from "./serviceRatings.js";
import CopyOfferingForm from "./copyOfferingForm.js";
import LanguageActivation from "./languageActivation.js";
import ServiceAuditLogs from "./serviceAuditLogs.js";
import CIDetails from '_Commons/WorkItemBoard/xsm-ci-editquickView';
import UserDetails from '_Commons/WorkItemBoard/xsmEditRequestedBy';

import {PiPencilSimpleBold} from "react-icons/pi";
import {IoClose} from "react-icons/io5";
import { setEditId, getEditServiceDetails,getServiceDetailsDataList } from "../../../actions/spcmActions";
const Joi = require("joi");
import sanitizeHtml from 'sanitize-html';
import CloseBtnRightPanel from "../../common/closeBtnRightPanel.js";
import { MyContext } from '_MyContext';

class ShortDescription extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {};
    this.showPageFun = this.showPageFun.bind(this);
  }

  componentDidMount() {
		this.context.keyDownHandler(this.closeButton,this.editButton,"","");
    this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,this.editButton,"","");
    this.context.newTabPrevent();
	}

  showPageFun(value) {
    const tr = this.props.tr;
    switch (value) {
      case 0:
        return (
          <Attributes
            tr={tr}
            rightComponentCountNo2={this.props.rightComponentCountNo1}
            clickTabName2={this.props.clickTabName1}
            clickTabHeading2={this.props.clickTabHeading1}
            listViewShow2={this.props.listViewShow1}
            componentFormToggle2={this.props.componentFormToggle1}
            componentFormFun2={this.props.componentFormFun1}
            boardPageInActive3={this.props.boardPageInActive2} 
            setServiceStatus={this.props.setServiceStatus} 
            serviceStatusState = {this.props.serviceStatusState}
            showRightSide={this.props.showRightSide}
          />
        );
      case 1:
        return (
          <div>            
            <CloseBtnRightPanel showRightSide={this.props.showRightSide}/>
            <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{tr["Service History"]}</div></div>
            <ServiceHistory tr={tr} />
          </div>
        );
      case 2:
        return (
          <div>
            <CloseBtnRightPanel showRightSide={this.props.showRightSide}/>
            <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{tr["Service Ratings"]}</div></div>
            <ServiceRatings tr={tr} />
          </div>
        );
      case 3:
        return (
          <div>
            <CloseBtnRightPanel showRightSide={this.props.showRightSide}/>
            <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{tr["Service Images"]}</div></div>
            <ServiceImages tr={tr} service={this.props.service} setServiceStatus={this.props.setServiceStatus} 
            serviceStatusState = {this.props.serviceStatusState}
            showRightSide={this.props.showRightSide} />
          </div>
        );
      case 4:
        return (
          <div>
            <CloseBtnRightPanel showRightSide={this.props.showRightSide}/>
            <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{tr["Copy a Service"]}</div></div>
            <CopyOfferingForm
              tr={tr}
              insideEditOffering={true}
              copyOfferingToggle={this.props.copyOfferingToggle}
              copyOfferingToggleValue={this.props.copyOfferingToggleValue}
              rightSidePageNoFun={this.props.rightSidePageNoFun}
              rightSidePageHead={this.props.rightSidePageHead}
              componentFormFun2={this.props.componentFormFun1}
              boardPageInActive3={this.props.boardPageInActive2}
              showRightSide={this.props.showRightSide}
            />
          </div>
        );
      case 5:
        return (
          <LanguageActivation
            reviewShowFun3={this.props.reviewShowFun2}
            isReviewOpen3={this.props.isReviewOpen2}
            whichInsideFormOpen3={this.props.whichInsideFormOpen2}
            insideTableShowFun3={this.props.insideTableShowFun2}
            isInsideTableShow3={this.props.isInsideTableShow2}
            isPageEdit3={this.props.isPageEdit2}
            isLanEditPageOpen3={this.props.isLanEditPageOpen2}
            lanEditPageFun3={this.props.lanEditPageFun2}
            langActSecFun3={this.props.langActSecFun2}
            isLangActSecOpen3={this.props.isLangActSecOpen2}
            tr={tr}
            showRightSide={this.props.showRightSide}
          />
        );
      case 6:
        return (
          <ServiceAuditLogs
            reviewShowFun3={this.props.reviewShowFun2}
            isReviewOpen3={this.props.isReviewOpen2}
            whichInsideFormOpen3={this.props.whichInsideFormOpen2}
            insideTableShowFun3={this.props.insideTableShowFun2}
            isInsideTableShow3={this.props.isInsideTableShow2}
            isPageEdit3={this.props.isPageEdit2}
            isLanEditPageOpen3={this.props.isLanEditPageOpen2}
            lanEditPageFun3={this.props.lanEditPageFun2}
            langActSecFun3={this.props.langActSecFun2}
            isLangActSecOpen3={this.props.isLangActSecOpen2}
            tr={tr}
            showRightSide={this.props.showRightSide}
          />
        );
      case 7:
          return (
            <CIDetails
                translator={tr}
                ciId={this.props.serviceCiId}
                rightEditPanel={this.props.showRightSide}
              />
          );
      case 8:
          return (
            <UserDetails
              userId={this.props.serviceOwnerid}
              userDetailsLabel={this.props.label}
              translator={tr}
              rightEditPanel={this.props.showRightSide}
            />
          );
    }
  }
  // onCloseClick() {
  //   this.props.copyOfferingToggle(false);
  //   this.props.rightSidePageNoFun(0);
  //   this.props.rightSidePageHead("default");
  //   // this.props.updateServiceState(false, true);
  //   this.props.componentFormFun1(false);
  //   this.props.boardPageInActive2(false);
  //   this.props.editServiceToggle(false);
  //   this.props.OFFERING_ID = undefined;
  // }
  render() {
    const tr = this.props.tr;
    // console.log("this.props.pageCountNo1",this.props.pageCountNo1);
    return (
      <div className="">
        {this.props.creServiceState1 ? (
          this.showPageFun(this.props.pageCountNo1)
        ) : (
          <div className="">
            <Row>
              <Col xs={12}>
                <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
                  <ul>
                    {this.props.spcmReducer.currentlySelectedServiceDetails
                      .allowEdit == false ? (
                      ""
                    ) : this.props.creServiceState1 ? null : (
                      <li>
                        <Link to="javascript:void(0)" className="editIcn ctrlKeyPrevent" id="editBtnObj"
                        title={this.props.tr["Edit"]}
                        onClick={() => {
                          this.props.editServiceFun(true);
                          this.props.creServiceToggle(true, false, 0);
                          // this.props.setActiveKeyVal(2);
                          this.props.serDetailsIcon(false);
                          this.props.setEditId(this.props.OFFERING_ID);
                          this.props.getEditServiceDetails();
                        }}
                        ref={e => this.editButton = e}
                        >
                        <PiPencilSimpleBold/>
                        </Link>		
                      </li>
                    )}
                    <li>
                      <Link to="javascript:void(0)" className="closeRsBtn ctrlKeyPrevent" id="closeButton"
                        title={this.props.tr["Minimize the details panel"]}
                        onClick={() => {
                          this.props.showRightSide(false);
                          this.props.setActiveKeyVal(1);
                          // this.props.getServiceDetailsDataList(
                          //   this.props.searchParams,
                          //   this.props.status,
                          //   this.props.category,
                          //   this.props.featured,
                          //   this.props.workItemType,
                          //   this.props.modifiedDateFilterValues,
                          //   this.props.sortOrder,
                          //   this.props.sortOnColumn,
                          //   this.props.page,
                          //   this.props.size
                          // );
                        }}
                        ref={e => this.closeButton = e}
                        >
                        <IoClose/>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="rPageHeading">
                  <div className="offNam margin-t-5 margin-b-5">
                    {" "}
                    {this.props.OFFERING_NAME}
                  </div>
                </div>
              </Col>
            </Row>

            <div className="rBoxGap">
              <ListGroup variant="flush" bsPrefix=" " as="ul">
                <ListGroup.Item
                  as="li"
                  bsPrefix=" "
                  className="dynTxt margin-b-20"
                  dangerouslySetInnerHTML={{ __html: sanitizeHtml(this.props.SUMMARY, {allowedAttributes: {
                    '*': ['style', 'class', 'dir', 'bgcolor'],
                    'table': ['border', 'cellpadding', 'cellspacing'],
                    'th': ['scope']
                  }}) }}
                ></ListGroup.Item>
                {/* <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
                  <span className="rwKey">Business Function</span>
                  <span className="rwVal">{this.props.BUSINESS_FUNCTION_NAME}</span>
                </ListGroup.Item> */}
                <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
                  <span className="rwKey">Delivery Mode</span>
                  <span className="rwVal">{this.props.DELIVERY_MODE}</span>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
                  <span className="rwKey">Base Language</span>
                  <span className="rwVal">{this.props.BASE_LANGUAGE}</span>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" " className="rwTable">
                  <span className="rwKey">Created by</span>
                  <span className="rwVal">{this.props.CREATED_BY}</span>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ spcmReducer }) => {
  return {
    spcmReducer,
  };
};

export default connect(mapStateToProps, { setEditId, getEditServiceDetails,getServiceDetailsDataList })(
  ShortDescription
);
