
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef, useContext } from "react";
import { MyContext } from '_MyContext';
import { Row, Col, Button, Form, ButtonToolbar, Table, Tabs, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IoClose, IoSearch } from "react-icons/io5";
import { Link } from "react-router-dom";
import AiIcon from "./aiIcon";
import { VscArrowSmallUp, VscArrowSmallDown } from "react-icons/vsc";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { LuLink } from "react-icons/lu";
import { DropDownList } from "@progress/kendo-react-dropdowns";


const ForecastingComponent = (props) => {
  const closeButton = useRef(null);
  const context = useContext(MyContext);
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const [tabKey, setTabKey] = useState('1');

  useEffect(() => {
    context.keyDownHandler(closeButton.current);
  }, []);

  return (
    <div className="stickyArea rBoxStyle">
      <Row>
        <Col xs={12}>
          <div className="rPageHeadActBtn">
            <ul>
              <li>
                <Link to="javascript:void(0)" className="closeRsBtn" id="closeButton"
                  title={tr["Minimize the details panel"]}
                  onClick={() => { props.forecastingHandleClick(false); props.showRightSide(false); }}
                  ref={closeButton}
                ><IoClose /></Link>
              </li>
            </ul>
          </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5"><AiIcon />{tr['Insights']}</div>
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <div className="aiTxt margin-b-20">This feature forecasts the 'Volume' and 'SLA' of upcoming incident tickets using data analysis and machine learning. Predictions are based on historical data and may vary due to unforeseen factors.</div>

        <div className="rBoxTabs hideTabNav">
          <Tabs
            activeKey={tabKey}
            onSelect={(k) => setTabKey(k)}
            id="tabId"
          >
            <Tab
              tabindex="0"
              eventKey={'1'}
              title={"Forecast Tickets"}
            >
              <div className="margin-b-10">
                <DateRangePicker initialSettings={{ startDate: '01/01/2025', endDate: '01/15/2025' }}>
                  <input type="text" className="form-control" />
                </DateRangePicker>
              </div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Date for</th>
                    <th>Volume</th>
                    <th>%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>24-03-2025</td>
                    <td>347 <span className="color-lightgrey display-inline-block margin-l-10">tickets</span></td>
                    <td><span className="green1 display-inline-block">(20%) <VscArrowSmallUp className="f-size-24" /></span></td>
                  </tr>
                  <tr>
                    <td>25-03-2025</td>
                    <td>17 <span className="color-lightgrey display-inline-block margin-l-10">tickets</span></td>
                    <td><span className="display-inline-block">(0%) <VscArrowSmallDown className="f-size-24" /></span></td>
                  </tr>
                  <tr>
                    <td>26-03-2025</td>
                    <td>10 <span className="color-lightgrey display-inline-block margin-l-10">tickets</span></td>
                    <td><span className="green1 display-inline-block">(5%) <VscArrowSmallUp className="f-size-24" /></span></td>
                  </tr>
                  <tr>
                    <td>27-03-2025</td>
                    <td>23 <span className="color-lightgrey display-inline-block margin-l-10">tickets</span></td>
                    <td><span className="green1 display-inline-block">(17%) <VscArrowSmallUp className="f-size-24" /></span></td>
                  </tr>
                  <tr>
                    <td>28-03-2025</td>
                    <td>78 <span className="color-lightgrey display-inline-block margin-l-10">tickets</span></td>
                    <td><span className="display-inline-block5">(2%) <VscArrowSmallDown className="f-size-24" /></span></td>
                  </tr>
                </tbody>
              </Table>
            </Tab>
            <Tab
              tabindex="0"
              eventKey={'2'}
              title={"Forecast SLA"}
            >
              <div className="margin-b-10">
                <DateRangePicker initialSettings={{ startDate: '01/01/2025', endDate: '01/15/2025' }}>
                  <input type="text" className="form-control" />
                </DateRangePicker>
              </div>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Date for</th>
                    <th>SLA Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>24-03-2025</td>
                    <td><span className="green1 display-inline-block">On Time <VscArrowSmallUp className=" f-size-24" /></span></td>
                  </tr>
                  <tr>
                    <td>25-03-2025</td>
                    <td><span className="red display-inline-block">Breached <VscArrowSmallDown className="f-size-24" /></span></td>
                  </tr>
                  <tr>
                    <td>26-03-2025</td>
                    <td><span className="display-inline-block">At Risk <VscArrowSmallUp className="f-size-24" /></span></td>
                  </tr>
                  <tr>
                    <td>27-03-2025</td>
                    <td><span className="display-inline-block">At Risk <VscArrowSmallUp className="f-size-24" /></span></td>
                  </tr>
                  <tr>
                    <td>28-03-2025</td>
                    <td><span className="red display-inline-block5">Breached<VscArrowSmallDown className="f-size-24" /></span></td>
                  </tr>
                </tbody>
              </Table>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default ForecastingComponent;
