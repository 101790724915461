
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { bindActionCreators } from 'redux';
//import { loadInvestigationCause } from '_Actions/investigation/investigationCauseAction';
import {getInvestigationItemDetailsUpdate,changeFieldStatusData} from '_Actions/investigation/investigationAction.js';
import { loadBreakFixAuditLogDetails } from '_Actions/breakFix/breakFixAuditLogAction';
import { loadStatusBasedHamburgerOptions } from '_Actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '_Actions/common/commonApisActions.js';
import Spinner from 'react-spinkit';
import { GLOBAL } from '_Globals';
import {getInvestigationTeamList, getInvestigationItemDetailsById} from "../../../../actions/investigation/investigationAction";
import swal from 'sweetalert';
import { Typeahead } from "react-bootstrap-typeahead";
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";

import Cookies from 'universal-cookie';
import { problemStatusMapping } from '../../../common/helper';
const cookies = new Cookies();
let username = cookies.get('gph');
if (username) username = username.replace('s:', '');
if (username) username = username.substring(0, username.lastIndexOf('.'));
username = username.split("~");
username = username[32];


const TypeaheadSelect = (props) => {
	const { tr = {}, options = [], selectedOptions = [], disabled = false, typedValue = '', onSelection = () => {}, onCrossClick = () => {}, errorClass = '', onInputChange = () => {} } = props || {};
	let _typeahead;
	return(
		<div className="dvTypehdropdown">
			<div className="dvTypehd">
				{!disabled && ( (selectedOptions && selectedOptions.length > 0) || (typedValue && typedValue.length > 0) ) ? (
				<span
					title="clear"
					role="img"
					aria-label="Clear"
					className="css-hakgx8 icn"
					onClick={() => {
					// _typeahead.getInstance().clear();
					onCrossClick();
					}}
				>
					<svg
					width="18"
					height="18"
					viewBox="0 0 24 24"
					role="presentation"
					>
					<path
						d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
						fill="gray"
					></path>
					</svg>
				</span>
				) : null}
				<div className={errorClass ? 'dvTypehd error': 'dvTypehd'}>
				<Typeahead
				ref={(ref) => {
					_typeahead = ref;
				}}
				disabled={ disabled ? true : false }
				onChange={onSelection}
				// onBlur={this.seterrorGroupcolor}
				options={options}
				selected={selectedOptions}
				placeholder={
					tr["Please choose"] + "..."
				}
				onInputChange={onInputChange}
				multiple={false}
				// renderMenuItemChildren={(option, props) => {
				// 	let label;
				// 	let title;
				// 	label =
				// 		option.label.length > 30
				// 		? option.label.slice(0, 30) + "..."
				// 		: option.label;
				// 	title = option.label.length > 30 ? option.label : "";
				// 	if(option?.supportCompanyName){
				// 	return (
				// 		<div>
				// 		<span className="typHdmainHead" title={title} style={{'display': 'block'}}>{label}</span>
				// 		<span className="typHdmainSubHead" style={{'display': 'block', 'margin-top': '6px', 'color': '#777'}}>{option?.supportCompanyName || ''}</span>
				// 		</div>
				// 	)
				// 	}
				// 	return (
				// 	<div>
				// 		<span className="typHdmainHead" title={title}>{label}</span>
				// 	</div>
				// 	);
				// }}
				/>
				</div>
			</div>
		</div>
	);
}

class ProposeCriticalTab extends React.Component{
	constructor(props){
	super(props);
		this.state = {
			issueValidationError:'',
			issueValidationCauseError:'',
			issueValidationWorkaroundError:'',
			savedRootCauseMessage:'',
			//causeBy:'',
			rootCause:'',
			//causeByName:'',
			saveLoader:false,
			workaround:'',
			categories_KA: null,
			subCategories_KA: null,
			categorySelected: null,
			subCategorySelected: null,
			permanentSolution: '',
			categoryError: '',
			subCategoryError: '',
			categoryTextSelected: '',
			subCategoryTextSelected: '',
			discourseError: '',
			typedCategory: '',
			workaround_and_rootCause_Editable_after_investigation: (this.props.investigationItemDetails.status_id != problemStatusMapping['Under Investigation'] && this.props.investigationItemDetails.status_id != problemStatusMapping['Closed']) && this.props.isProblemManagerEnabled

		};
		//this.props.loadInvestigationCause();
		//this.getCauseBy = this.getCauseBy.bind(this);
		this.getRootCause = this.getRootCause.bind(this);
		this.getworkaround=this.getworkaround.bind(this);
		this.postRequest=this.postRequest.bind(this);
		this.getCategory_KA = this.getCategory_KA.bind(this);
		this.renderCategoryOptions = this.renderCategoryOptions.bind(this);
		this.setSubCategories = this.setSubCategories.bind(this);
		this.renderSubCategoryOptions = this.renderSubCategoryOptions.bind(this);
		this.renderCategorySubCategory = this.renderCategorySubCategory.bind(this);
	}

/* 	componentWillMount() {
			this.props.getInvestigationItemDetails(this.props.investigationId);
	} */
  componentWillMount() {
    this.props.getInvestigationTeamList(this.props.investigationItemDetails.investigationId);
	// if( ['45', '50', '55', '60', '65', '70'].includes(this.props?.status) ){
	// 	this.getCategory_KA({ category: this.props?.investigationItemDetails?.category });
	// }
	// else{
	// 	this.getCategory_KA({});
	// }
	if( this.props?.investigationItemDetails?.category && this.props?.investigationItemDetails?.subCategory ){
		this.getCategory_KA({ category: this.props?.investigationItemDetails?.category });
	}
	else{
		this.getCategory_KA({});
	}
	this.setState({ rootCause: this.props?.investigationItemDetails?.rootCause || '', workaround: this.props?.investigationItemDetails?.workaroundProvided || '', categorySelected: this.props?.investigationItemDetails?.category || '', subCategorySelected: this.props?.investigationItemDetails?.subCategory || '', permanentSolution: this.props?.investigationItemDetails?.permanentSolution || '', categoryTextSelected: this.props?.investigationItemDetails?.categoryName || '', subCategoryTextSelected: this.props?.investigationItemDetails?.subCategoryName || '' 
	});
  }

//   getCauseBy(event){
// 			//issue="";

// 			this.setState({
// 				causeBy:event.target.value,
// 				causeByName:event.target[event.target.selectedIndex].text
// 			})
// 			//console.log(event.target.value);
// 	}

	getRootCause(event){
			//issue="";
			let val = (event && event?.target && event?.target?.value) ? event.target.value : '';
			this.setState({
				rootCause: val,
				issueValidationCauseError: ((val=="" || val.trim()=="") ? 'error' : '')
			});
			//console.log(event.target.value);
	}
	getworkaround(event)
	{
		this.setState({
		 workaround:event.target.value
		})
	}

// 	renderCauseName(breakFixData){
//     if(!breakFixData){
//       return(
//           <option></option>
//       );
//     }
//     return breakFixData.map((breakFixObj)=>{

//       return(
//           <option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
//         );
//     });
//   }
	//posting request
	postRequest(){
		if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident'))
				swal({
					text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
					button: this.props.translator['OK'],
				  });
        // else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') ))
		// 		   swal({
		// 			  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
		// 			  button: this.props.translator['OK'],
		// 		    });
		else
		{
    if(this.props.investigationTeamList.length==0){
		swal({
			text: this.props.translator["Form Investigation Team First"],
			button: this.props.translator['OK'],
		  });
    }else{
      //this.onChangeUrgency();
      //alert(this.props.investigationId)
      //alert(this.state.causeBy)
      //alert(this.state.rootCause)

		let ref=this;


		if(this.state.rootCause=="" || this.state.rootCause.trim()==""){
			this.setState({
				// issueValidationError:"error",
				issueValidationCauseError:"error",
			})
			// return false;
		}else{
			this.setState({
				// issueValidationError:'',
				issueValidationCauseError:''
			})

		}
		if((this.state.rootCause && typeof this.state.rootCause == 'string' && this.state.rootCause.trim().length > 0) && this.state.categorySelected && this.state.subCategorySelected){
			this.setState({
				issueValidationError:''
			});
		}
		else{
			this.setState({
				issueValidationError:'error'
			});
		}
		this.setState({
			categoryError: (this.state.categorySelected) ? '' : 'error',
			subCategoryError: (this.state.subCategorySelected) ? '' : 'error'
		});

		if(this.state.rootCause=="" || this.state.rootCause.trim()=="" || !this.state.categorySelected || !this.state.subCategorySelected){
			return false;
		}

		//insert request start
		//console.log(this.state.causeBy+":"+this.state.causeByName);

		// let rootcause="KEDB: Root Cause: "+ this.state.rootCause+",Caused By:"+this.state.causeByName+",Workaround Provided:"+this.state.workaround;
		let rootcause=`Known Error: Root Cause: ${this.state.rootCause}${(this.state.workaround && this.state.workaround.trim().length) ? (` ,Workaround Provided: ${this.state.workaround.trim()}`) : ""}${(this.state.categoryTextSelected) ? (` ,Category: ${this.state.categoryTextSelected}`) : ''}${(this.state.subCategoryTextSelected) ? (` ,Sub-Category: ${this.state.subCategoryTextSelected}`) : ''}`;
    let postjson = {
      //======================JSON Start
			//"cause": this.state.causeBy,
			"rootCause":this.state.rootCause,
			"workaroundProvided":this.state.workaround,
			//"status":"45",
			"modifiedBy": "",
			"modifiedByName":"",
			'category': this.state.categorySelected,
			'subCategory': this.state.subCategorySelected,
			'permanentSolution': (this.state.permanentSolution && typeof this.state.permanentSolution == 'string') ? this.state.permanentSolution.trim() : '',
			categoryName: (this.state?.categoryTextSelected || ''),
			subCategoryName: (this.state?.subCategoryTextSelected || '')
			//==============JSON End
    };
		let postjsonactivity = {
			"itemId":this.props.investigationId,
			"description":rootcause,
			"isInternal":"",
			"createdOn":"",
			"createdBy":"",
			"module":"Investigation",
			"createdByName":""
		};
    //console.log(postjson);
		if(this.state.rootCause!="" /*&& this.state.causeBy!=""*/){
			this.setState({saveLoader:true});
	    axios.patch(GLOBAL.investigationEditUrl+this.props.investigationId, postjson)
	    .then(function (response) {
	      console.log(response);
	      //alert(response.statusText);
				if(response.status === 200){

					ref.setState({
						savedRootCauseMessage:"Root Cause Saved Successfully "
					})
					//ref.props.setForRootCause(true,'specifyRootResponse'),ref.props.setForRootCause(ref.props.translator["Root Cause Saved Successfully"],'responseMessage');
					//ref.props.getInvestigationItemDetailsUpdate(response.data.investigationId);
					// ref.props.loadBreakFixAuditLogDetails(response.data.investigationId.toString(),"dsc","Investigation",'STATUS');
					//ref.props.loadStatusBasedHamburgerOptions('Investigation',response.data.status,response.data.investigationId);
					//ref.props.changeFieldStatusData('45');
					GLOBAL.hierarchy=true;
					//ref.props.loadInvestigationCause();
					//ref.renderCauseName(ref.props.invCauseName)
					//boc post in activity log
					axios.post(GLOBAL.breakFixActivityLogPostUrl, postjsonactivity)
					.then(function (response) {
						console.log("response.statusText:::"+response.status)
						ref.setState({saveLoader:false});
						})
					.catch(function (error) {
						console.log(error);
						ref.setState({saveLoader:false});
						//alert("H1"+error);
					});

					ref.props.getInvestigationItemDetailsById(ref.props.investigationId);

					//eoc
				}else{
					ref.setState({saveLoader:false});
					console.log("response status is not ok:"+response.statusText)
				}
                ref.props.changeActionView('');
	    })
	    .catch(function (error) {
	      console.log(error);
	      //alert("H1"+error);
	    });
		}else{

        console.log("Error in creating investigation");
      }
    }
    }
	}

	getCategory_KA({ category = '' }){
		try{
			this.setState({ categories_KA: null, subCategories_KA: null });
			axios.get('/api/getCategoriesKnowledgeArticles', { headers: { 'username': username } }) 
			.then((res) => {
				// console.log('res - ', res);
				if(res?.data?.category_list){
					const data = res?.data?.category_list?.categories;
					this.setState({ categories_KA: data }, () => {
						if(category){
							this.setSubCategories(category);
						}
					});
				}
				else{
					this.setState({ discourseError: 'error' });
				}
			})
			.catch(err => {
				console.error(err);
				this.setState({ discourseError: 'error' });
			});
		}catch(e){
			console.error(e);
			this.setState({ discourseError: 'error' });
			this.setState({ categories_KA: null, subCategories_KA: null });
		}
	}

	renderCategoryOptions(){
		try{
			// const { categories_KA = null } = this.state;
			// if(!(categories_KA && Array.isArray(categories_KA) && categories_KA.length > 0)){
			// 	return null;
			// }
			// return categories_KA.map(item => {
			// 	return (
			// 		<option value={item['id']}>{item['name']}</option>
			// 	);
			// });
			const { categories_KA = null } = this.state;
			if(!(categories_KA && Array.isArray(categories_KA) && categories_KA.length > 0)){
				return [];
			}
			let arr = [];
			categories_KA.forEach(item => {
				arr.push({id: item['id'], label: item['name']});
			});
			return arr;
		}catch(e){
			console.error(e);
			return [];
		}
	}

	renderSubCategoryOptions(){
		try{
			// const { subCategories_KA = null } = this.state;
			// if(!(subCategories_KA && Array.isArray(subCategories_KA) && subCategories_KA.length > 0)){
			// 	return null;
			// }
			// return subCategories_KA.map(item => {
			// 	return (
			// 		<option value={item['id']}>{item['name']}</option>
			// 	);
			// });
			const { subCategories_KA = null } = this.state;
			if(!(subCategories_KA && Array.isArray(subCategories_KA) && subCategories_KA.length > 0)){
				return [];
			}
			let arr = [];
			subCategories_KA.forEach(item => {
				arr.push({id: item['id'], label: item['name']});
			});
			return arr;
		}catch(e){
			console.error(e);
			return [];
		}
	}

	setSubCategories(val){
		try{
			const { categories_KA = null } = this.state;
			this.setState({ subCategories_KA: null }, () => {
				if(categories_KA && Array.isArray(categories_KA) && categories_KA.length > 0){
					const found = categories_KA.find(item => item['id'] == val);
					if(found && found?.subcategory_list && Array.isArray(found.subcategory_list) && found.subcategory_list.length > 0){
						this.setState({subCategories_KA: found.subcategory_list});
					}
				}
			});
		}catch(e){
			console.error(e);
			this.setState({ subCategories_KA: null });
		}
	}

	// renderCategorySubCategory(obj){
	// 	try{
	// 		const { isDisabled = false } = obj || {};
	// 		return (
	// 			<>
	// 				<Form.Group style={{'margin-top': '10px'}}>
	// 					<Form.Label> <span className="rStar"></span> Category </Form.Label>
	// 					<select className={`form-control ${this.state.categoryError}`} onChange={(event) => { 
	// 						if(isDisabled){
	// 							return false;
	// 						}
	// 						this.setState({ 
	// 							categorySelected: event.target.value, subCategorySelected: null, 
	// 							categoryTextSelected: event?.target[event?.target?.selectedIndex].text,
	// 							subCategoryTextSelected: '',
	// 							categoryError: (event?.target?.value ? '' : 'error'),
	// 							subCategoryError: 'error'
	// 						});
	// 						this.setSubCategories(event.target.value);
	// 					 }}
	// 					 disabled={isDisabled||this.props.disableFieldsForGuestRole}
	// 					 value={this.state.categorySelected}
	// 					 >
	// 						<option value="">Select</option>
	// 						{this.renderCategoryOptions()}
	// 					</select>
	// 				</Form.Group>
	// 				<Form.Group style={{'margin-top': '10px'}}>
	// 					<Form.Label> <span className="rStar"></span> Sub-Category </Form.Label>
	// 					<select className={`form-control ${this.state.subCategoryError}`} onChange={(event) => { 
	// 						if(isDisabled){
	// 							return false;
	// 						}
	// 						this.setState({ 
	// 							subCategorySelected: event.target.value, 
	// 							subCategoryTextSelected: event?.target[event?.target?.selectedIndex].text,
	// 							subCategoryError: (event?.target?.value ? '' : 'error') 
	// 						});
	// 					 }}
	// 					 disabled={isDisabled||this.props.disableFieldsForGuestRole}
	// 					 value={this.state.subCategorySelected}
	// 					 >
	// 						<option value="">Select</option>
	// 						{this.renderSubCategoryOptions()}
	// 					</select>
	// 				</Form.Group>
	// 				<Form.Group style={{'margin-top': '10px'}}>
	// 					<Form.Label> Permanent Solution </Form.Label>
	// 					<Form.Control as="textarea" rows="4" onChange={(event) => {
	// 						if(isDisabled){
	// 							return false;
	// 						}
	// 						this.setState({permanentSolution: event.target.value})
	// 					}} 
	// 					disabled={isDisabled || this.props.disableFieldsForGuestRole}
	// 					value={this.state.permanentSolution}
	// 					/>
	// 				</Form.Group>
	// 			</>
	// 		);
	// 	}catch(e){
	// 		console.error(e);
	// 		return null;
	// 	}
	// }

	renderCategorySubCategory(obj){
		try{
			const { isDisabled = false } = obj || {};
			const { categorySelected = '', categoryTextSelected = '', subCategorySelected = '', subCategoryTextSelected = '' } = this.state;
			// console.log('this.state - ', this.state);
			const categoryOptions = this.renderCategoryOptions();
			const selectedCategory = (categorySelected && categoryTextSelected) ? [{id: categorySelected, label: categoryTextSelected}] : [];
			const subCategoryOptions = this.renderSubCategoryOptions();
			const selectedSubCategory = (subCategorySelected && subCategoryTextSelected) ? [{id: subCategorySelected, label: subCategoryTextSelected}] : [];
			// console.log('categoryOptions, selectedCategory, subCategoryOptions, selectedSubCategory - ', categoryOptions, selectedCategory, subCategoryOptions, selectedSubCategory);
			return (
				<>
					<Form.Group style={{'margin-top': '10px'}}>
						<Form.Label> <span className="rStar"></span> Category </Form.Label>
						<TypeaheadSelect 
							onSelection={(val) => {
								if(isDisabled){
									return false;
								}
								// console.log('val - ', val);
								const id = (val && val.length > 0) ? val[0].id : '';
								const label = (val && val.length > 0) ? val[0].label : '';
								this.setState({ 
									categorySelected: id, subCategorySelected: null, 
									categoryTextSelected: label,
									subCategoryTextSelected: '',
									categoryError: ((val && id) ? '' : 'error'),
									subCategoryError: 'error'
								});
								this.setSubCategories(id);
							}}
							id='category'
							name="category"
							className="form-control"
							errorClass={this.state.categoryError ? 'error' : ''}
							options={categoryOptions}
							selectedOptions={selectedCategory}
							disabled={isDisabled || this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled||this.state.workaround_and_rootCause_Editable_after_investigation}
							onInputChange={(e) => {
								if(isDisabled){
									return false;
								}
								// console.log('onInputChange e - ', e);
								this.setState({ 
									// typedCategory: e ,
									categorySelected: '', subCategorySelected: null, 
									categoryTextSelected: '',
									subCategoryTextSelected: '',
									categoryError: 'error',
									subCategoryError: 'error',
									subCategories_KA: null
								});
							}}
							onCrossClick={() => {
								if(isDisabled){
									return false;
								}
								// console.log('onCrossClick');
								this.setState({ 
									// typedCategory: '',
									categorySelected: '', subCategorySelected: null, 
									categoryTextSelected: '',
									subCategoryTextSelected: '',
									categoryError: 'error',
									subCategoryError: 'error',
									subCategories_KA: null
								});
							}}
							// typedValue={this.state.typedCategory}
							tr={this.props.translator}
						/>
					</Form.Group>
					<Form.Group style={{'margin-top': '10px'}}>
						<Form.Label> <span className="rStar"></span> Sub-Category </Form.Label>
						<TypeaheadSelect 
							onSelection={(val) => {
								if(isDisabled){
									return false;
								}
								// console.log('val - ', val);
								const id = (val && val.length > 0) ? val[0].id : '';
								const label = (val && val.length > 0) ? val[0].label : '';
								this.setState({ 
									subCategorySelected: id, 
									subCategoryTextSelected: label,
									subCategoryError: (id ? '' : 'error') 
								});
							}}
							id='subCategory'
							name="subCategory"
							className="form-control"
							errorClass={this.state.subCategoryError ? 'error' : ''}
							options={subCategoryOptions}
							selectedOptions={selectedSubCategory}
							disabled={isDisabled || this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled||this.state.workaround_and_rootCause_Editable_after_investigation}
							onInputChange={(e) => {
								if(isDisabled){
									return false;
								}
								// console.log('onInputChange e - ', e);
								this.setState({ 
									subCategorySelected: '', 
									subCategoryTextSelected: '',
									subCategoryError: 'error'
								});
							}}
							onCrossClick={() => {
								if(isDisabled){
									return false;
								}
								// console.log('onCrossClick');
								this.setState({ 
									subCategorySelected: '', 
									subCategoryTextSelected: '',
									subCategoryError: 'error'
								});
							}}
							tr={this.props.translator}
						/>
					</Form.Group>
					<Form.Group style={{'margin-top': '10px'}}>
						<Form.Label> Permanent Solution </Form.Label>
						<Form.Control as="textarea" rows="4" onChange={(event) => {
							if(isDisabled){
								return false;
							}
							this.setState({permanentSolution: event.target.value})
						}} 
						disabled={isDisabled || this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled||this.state.workaround_and_rootCause_Editable_after_investigation}
						value={this.state.permanentSolution}
						/>
					</Form.Group>
				</>
			);
		}catch(e){
			console.error(e);
			return null;
		}
	}

	//==============

	render(){

		// console.log('*****', {state: this.state, props: this.props});

		const {showTitle = true} = this.props;

		// console.log("hierarchy",GLOBAL.hierarchy)

		if(this.props.investigationItemDetails.investigationFinding=="N"){
			return(
				<font><b>Investigation findings details not found.
				Please provide investigation findings before providing root cause.</b></font>
			);
		}
		// if (this.props.invCauseName.length == 0) {
		// 	return (
		// 		<Spinner spinnerName="three-bounce" />
		// 	);
		// }
		if(this.props.investigationItemDetails.status_id == problemStatusMapping['Under Investigation']){
			return(
			<div className="hambTab">
				{(showTitle == true) && <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Specify Root Cause']}</div></div>}
				<div className=''>
				{(this.state.issueValidationError=="error") ? <div><font color="red"><strong>Please revalidate the highlighted fields !</strong></font></div>:<div></div>}
				{(this.state.discourseError == 'error') && <div><font color="red"><strong> An unexpected error has occurred. Please try again after some time. If you are getting this error frequently, please contact application administrator/support. </strong></font></div>}
				{/* <Form.Group validationState={this.state.issueValidationCauseError}>
				  <Form.Label><span className="rStar"></span>{this.props.translator[' Caused By']}</Form.Label>
				  <Form.Select componentClass="select" onChange ={this.getCauseBy.bind(this)} defaultValue={this.props.investigationItemDetails.cause} disabled={this.props.specifyRootResponse}>
					<option value="">select</option>
					{this.renderCauseName(this.props.invCauseName)}
				  </Form.Select>
				</Form.Group> */}
				<Form.Group>
				  <Form.Label><span className="rStar"></span>{this.props.translator['Root Cause']} </Form.Label>
				  <Form.Control className={this.state.issueValidationCauseError} as="textarea" rows="4" onChange ={this.getRootCause.bind(this)} value={this.state.rootCause} disabled={this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled}/>
				</Form.Group>
				<Form.Group>
				  <Form.Label> {this.props.translator['Workaround Provided']} </Form.Label>
				  <Form.Control as="textarea" rows="4" onChange ={this.getworkaround.bind(this)} value={this.state.workaround} disabled={this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled}/>
				</Form.Group>
				{/* {this.props.specifyRootResponse?<div><font color="green"><strong>{this.props.responseMessage}</strong></font></div>:''} */}
				{this.renderCategorySubCategory()}
				{this.state.savedRootCauseMessage=="" || this.state.issueValidationError=="error"?

				// <div>
				// 	<ButtonToolbar className="margin-t-15">
				// 	<Button bsStyle="primary" onClick={()=>{this.postRequest();}}>
				// 	{this.props.translator['Save']}</Button>
				// 	</ButtonToolbar></div>

					<ButtonToolbar className="black margin-t-10">
					<Button className='rgSidrkBtn smallBtn' disabled={this.state.saveLoader||this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled}
						onClick={this.postRequest} >
						{this.state.saveLoader ?
							<ImSpinner6 className="icn-spinner"/> : null} {this.props.translator['Save']}
					</Button>
				</ButtonToolbar>
					:<div></div>}
					</div>
			</div>
			);
		}else if(this.state.workaround_and_rootCause_Editable_after_investigation){
            return (
				<div className="hambTab">
				{(showTitle == true) && <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Specify Root Cause']}</div></div>}
				<div className=''>
				{(this.state.issueValidationError=="error") ? <div><font color="red"><strong>Please revalidate the highlighted fields !</strong></font></div>:<div></div>}
				{(this.state.discourseError == 'error') && <div><font color="red"><strong> An unexpected error has occurred. Please try again after some time. If you are getting this error frequently, please contact application administrator/support. </strong></font></div>}
				<Form.Group>
				  <Form.Label><span className="rStar"></span>{this.props.translator['Root Cause']} </Form.Label>
				  <Form.Control className={this.state.issueValidationCauseError} as="textarea" rows="4" onChange ={this.getRootCause.bind(this)} value={this.state.rootCause} disabled={this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled}/>
				</Form.Group>
				<Form.Group>
				  <Form.Label> {this.props.translator['Workaround Provided']} </Form.Label>
				  <Form.Control as="textarea" rows="4" onChange ={this.getworkaround.bind(this)} value={this.state.workaround} disabled={this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled}/>
				</Form.Group>
				{this.renderCategorySubCategory()}
				{this.state.savedRootCauseMessage=="" || this.state.issueValidationError=="error"?
					<ButtonToolbar className="black margin-t-10">
					<Button className='rgSidrkBtn smallBtn' disabled={this.state.saveLoader||this.props.disableFieldsForGuestRole||this.props.isServiceDeskRolesEnabled}
						onClick={this.postRequest} >
						{this.state.saveLoader ?
							<ImSpinner6 className="icn-spinner"/> : null} {this.props.translator['Save']}
					</Button>
				</ButtonToolbar>

					:<div></div>}
					</div>
			</div>
			);
		}else{
			return(
				<div className="hambTab">
					<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
						<ul>
							<li>
							<button
							    type='button'
								title="Minimize the right panel"
								bsClass=""
								bsStyle=""
								className="closerightPanelBtn"
								onClick={() => {
								this.props.rightEditPanel(false);
								this.props.changeActionView("");
								}}
							>
								<IoClose />
							</button>
							</li>
						</ul>
					</div>
					{(showTitle == true) && <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Specify Root Cause']}</div></div>}
					<div className=''>
						{(this.state.issueValidationError=="error") ? <div><font color="red"><strong>Please revalidate the highlighted fields !</strong></font></div>:<div></div>}
						{(this.state.discourseError == 'error') && <div><font color="red"><strong> An unexpected error has occurred. Please try again after some time. If you are getting this error frequently, please contact application administrator/support. </strong></font></div>}
						{/* <Form.Group validationState={this.state.issueValidationCauseError}>
						  <Form.Label><span className="rStar"></span>{this.props.translator[' Caused By']}</Form.Label>
						  <Form.Control componentClass="select" onChange ={this.getCauseBy.bind(this)} defaultValue={this.props.investigationItemDetails.cause} disabled={this.props.specifyRootResponse}>
							<option value="">select</option>
							{this.renderCauseName(this.props.invCauseName)}
						  </Form.Control>
						</Form.Group> */}
						<Form.Group className='form-group'>
						  <Form.Label><span className="rStar"></span>{this.props.translator['Root Cause']} </Form.Label>
						  <Form.Control as="textarea" rows="4" onChange ={this.getRootCause.bind(this)} placeholder={this.props.investigationItemDetails.rootCause} disabled={true}/>
						</Form.Group>
						<Form.Group className='form-group'>
					  <Form.Label> {this.props.translator['Workaround Provided']} </Form.Label>
					  <Form.Control as="textarea" rows="4" onChange ={this.getworkaround.bind(this)} placeholder={this.props.investigationItemDetails.workaroundProvided} disabled={true}/>
					</Form.Group>
					{this.renderCategorySubCategory({isDisabled: true})}
						{/* {this.props.specifyRootResponse?<div><font color="green"><strong>{this.props.responseMessage}</strong></font></div>:''} */}
					</div>
				</div>
			);

		}
	}
}

export function mapStateToProps({investigationItemDetails,investigationTeamList,invRelatedDataDetailsCount }){
	return { investigationItemDetails:(investigationItemDetails && investigationItemDetails.data ? investigationItemDetails.data[0]:investigationItemDetails),investigationTeamList,invRelatedDataDetailsCount };
}

function mapDispatchToProps(dispatch){
	return bindActionCreators({ getInvestigationTeamList,getInvestigationItemDetailsUpdate,changeFieldStatusData,loadBreakFixAuditLogDetails, loadStatusBasedHamburgerOptions, loadTimelineData, getInvestigationItemDetailsById }, dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(ProposeCriticalTab);

//export default ProposeCriticalTab;
