
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef, useContext } from "react";
import { MyContext } from '_MyContext';
import { ListGroup, Row, Col, Tabs, Tab, Accordion, Form, Button, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { IoArrowUpCircleOutline, IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import AiIcon from "./aiIcon";
import { useGetAISMDsatDetailsMutation, useGetAISMFcrDetailsMutation, useGetChangeSucessRateMutation, useGetFullfilmentReopenRateMutation, useGetIncidentReopenRateMutation, useGetIncidentSucessRateMutation } from "../../reducers/aism/aismAPI";
import ListLoader from "./loaders/ListLoader";
import D2CGenericAIDesctiption from "./D2CGenericAIDesctiption";
import D2CPieChart from "./D2CPieChart";
import { usePreviousValue } from "../../helpers/usePreviousValue";
import SimilarityDetection from "./similarityDetection";

const AiInsights = (props) => {
    const closeButton = useRef(null);
    const context = useContext(MyContext);
    const tr = useSelector((state) => state.spcmReducer.tr); 
    const extractedChangeData = useSelector(state => state.extractedChangeDataForSucessRate); 
    const [result, setResult] = useState(null);
    const [dsatResult, setDsatResult] = useState(false);
    const [dsatData, setDsatData] = useState([])
    const [changeSuccessRate, setChangeSuccessRate] = useState(0.0);
    const [changeData, setChangeData] = useState([]);
    const hasTrueKey = result && result.hasOwnProperty('true');
    const previousValue = usePreviousValue(props);
    const [reOpenRate, setGetReOpenRate] = useState('');

    const [getAISMFcrDetails, { data, error, isLoading: getFCRLoader}] = useGetAISMFcrDetailsMutation();
    const [getAISMDsatDetails, { dataDSAT, errorDSAT, isLoading: getDSATLoader }] = useGetAISMDsatDetailsMutation();
    const [getChangeSuccessRate, { dataChangeSucess, errorChangeSuccess, isLoading: getChangeSucessLoader }] = useGetChangeSucessRateMutation();
    const [getIncidentSucessRate, { dataIncidentSucess, errorIncidentSuccess, isLoading: getIncidentSucessLoader }] = useGetIncidentSucessRateMutation();
    const [getIncidentReopenRate, {isLoading: getIncidentReOpenLoader}] = useGetIncidentReopenRateMutation();
    const [getFullfilmentReopenRate, {isLoading: getFullfilmentReOpenLoader}] = useGetFullfilmentReopenRateMutation();

    useEffect(() => {
        context.keyDownHandler(closeButton.current);
        if (props.isVisible === false && props.isVisible != previousValue?.isVisible) {
			window.addEventListener('resize', () => props.updateIsVisible(false));
		}
		else if (props.aiInsights === 0 && props.aiInsights != previousValue?.aiInsights) {
			window.addEventListener('resize', () => props.updateIsVisible(true));
		}
        return () => {
            window.removeEventListener('resize', () => props.updateIsVisible());
        }
    }, [props.isVisible, props.aiInsights]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getAISMFcrDetails(props.payload).unwrap();
                setResult(result);
            } catch (err) {
            }
        }
        if (props.aism == true ) {
            fetchData();
        }
        }, []);

        const fetchIncidentSuccessRate = async () => {
            try {
    
                // Fetch success rate data from the API
                const incidentRate = await getIncidentSucessRate(props.payloadDSAT).unwrap();
                const rawDsatRate = incidentRate && incidentRate?.["true"];

                // Parse success rate
                if(rawDsatRate){
                    let dsatRate = parseFloat(rawDsatRate.replace('%', '').trim());
                    // Initialize data array
                    const data = [];
                    if (dsatRate === 0) {
                        data.push({ title: tr['Success Rate'], value: 100, color: '#4caf50' });
                    } else if (dsatRate === 100) {
                        data.push({ title: tr['Failure Rate'], value: 100, color: '#FF0000' });
                    } else if(dsatRate > 0 && dsatRate < 100){
                        data.push(
                            { title: tr['Failure Rate'], value: dsatRate, color: '#FF0000' },
                            { title: tr['Success Rate'], value: 100 - dsatRate, color: '#4caf50' }
                        );
                    } else {
                        [{ title: "Invalid Data", value: 100, color: "#d3d3d3" }]
                    }
                    setDsatData(data);
                    setDsatResult(true);
                } else {
                    setDsatResult(false);
                }
            } catch (error) {
                console.error('Error in retrieving data from API /api/getIncidentSucessRate:', error?.data?.message || error);
            }
        };

        const fetchReopenRate = async () => {
            if(props.module == "incident"){
                getIncidentReopenRate(props.reOpenPayload).then((res) => {
                    let d = res.data.outputvalue;
                    d = Number(d.split("%")[0])
                    setGetReOpenRate(d);
                })
            } else {
                getFullfilmentReopenRate(props.reOpenPayload).then((res) => {
                    let d = res.data.outputvalue;
                    d = Number(d.split("%")[0])
                    setGetReOpenRate(d);
                })
            }
        }

    useEffect(() => {
        const fetchDSATData = async () => {

            try {
                const result = await getAISMDsatDetails(props.payloadDSAT).unwrap();
                const rawDsatRate = result && result?.["true"];

                // Parse success rate
                if(rawDsatRate){
                    let dsatRate = parseFloat(rawDsatRate.replace('%', '').trim());
                    // Initialize data array
                    const data = [];
                    if (dsatRate === 0) {
                        data.push({ title: tr['Success Rate'], value: 100, color: '#4caf50' });
                    } else if (dsatRate === 100) {
                        data.push({ title: tr['Failure Rate'], value: 100, color: '#FF0000' });
                    } else if(dsatRate > 0 && dsatRate < 100){
                        data.push(
                            { title: tr['Failure Rate'], value: dsatRate, color: '#FF0000' },
                            { title: tr['Success Rate'], value: 100 - dsatRate, color: '#4caf50' }
                        );
                    } else {
                        [{ title: "Invalid Data", value: 100, color: "#d3d3d3" }]
                    }
                    setDsatData(data);
                    setDsatResult(true);
                } else {
                    setDsatResult(false);
                }
            } catch (err) {
                console.error('Error in retrieving data from API /api/getAISMBasedDSAT:', err?.data?.message || err);
            }
        }
        if (props.aismDSAT == true ) {
            if(props.module == "fulfillment"){
                fetchDSATData();
            } else if(props.module == "incident") {
                fetchIncidentSuccessRate();
            }
        }
        fetchReopenRate();
        const fetchChangeSuccessRate = async () => {
            try{
                const changeRate = await getChangeSuccessRate(extractedChangeData).unwrap();
                let sucessRate = changeRate["outputvalue"].replace('%', '').trim();
                sucessRate = parseFloat(sucessRate);
                sucessRate = sucessRate === null || sucessRate === undefined || sucessRate === '' ? 0 : sucessRate;
                let data = [];
                if(sucessRate === 0.0 || sucessRate === 0){
                  data.push({title: tr['Failure Rate'], value: sucessRate, color: '#FF0000'});
                }else if(sucessRate === 100.0 || sucessRate === 100){
                  data.push({ title: tr['Sucess Rate'], value: sucessRate, color: '#4caf50' });
                }else{
                  data.push({ title: tr['Sucess Rate'], value: sucessRate, color: '#4caf50' },
                    { title: tr['Failure Rate'], value: 100 - sucessRate, color: '#FF0000' }); 
                }
                setChangeData(data);
                setChangeSuccessRate(sucessRate);
            }catch(error){
                console.log('Error in retriving data from API /api/getChangeSucessRate : ',error?.data?.message); 
            }
        }
        if(props.aismChangeRate){
            fetchChangeSuccessRate();
        }
    }, []);

    const showLoader = getFCRLoader || getDSATLoader || getChangeSucessLoader || getIncidentSucessLoader || getIncidentReOpenLoader || getFullfilmentReOpenLoader;
    const showFcr = props.aism && props.breakFixEditDetails.statusReopen !=true && (props.breakFixEditDetails.assigneeHopCount== 0 || props.breakFixEditDetails.assigneeHopCount == null) && props.breakFixEditDetails.asgGroupHopCount== 0;

    return (
        <div className="">
            <Row>
                <Col xs={12}>
                    <div className="rPageHeadActBtn">
                        <ul>
                            <li>
                                <Link to="javascript:void(0)" className="closeRsBtn" id="closeButton"
                                    title={tr["Minimize the details panel"]}
                                    onClick={() => { props.resetRightSide(); props.changeRightView("processhierarchy"); }}
                                    ref={closeButton}
                                ><IoClose /></Link>
                            </li>
                        </ul>
                    </div>
                    <div className="rPageHeading">
                        <div className="offNam margin-t-5 margin-b-5"><AiIcon /> {tr['Insights']}</div>
                    </div>
                </Col>
            </Row>
            <div className="rBoxGap">
                <div className="rBoxTabs hideTabNav">
                    <Tabs id="tabId">
            <Tab eventKey={1} title={tr["AI INSIGHTS DETAILS"]}>
                { showLoader ? <ListLoader/> : changeSuccessRate ? 
                  <>
                    <D2CGenericAIDesctiption />
                    <Accordion id={"aiQues"} accordion className="brkFix brkFixOrder" defaultActiveKey="0">
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                <h4 className="font1">{tr['Change Success Rate']}</h4>
                            </Accordion.Header>
                            <Accordion.Body>
                                {changeData ?
                                    <D2CPieChart data={changeData} title={tr['Change Success Rate']} />
                                    :
                                    <div>{tr['Data not available']}</div>
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                  </>
                : (showFcr || props.aismDSAT || props.isReopen) ? (
                    <>
                        <D2CGenericAIDesctiption />
                        <Accordion id={"aiQues"} accordion className="brkFix brkFixOrder" defaultActiveKey="0">
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>
                                    <h4 className="font1">SIMILARITY DETECTION</h4>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <SimilarityDetection />
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>
                                    <h4 className="font1">PREDICTION</h4>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Table responsive  className="predictionList">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p title="Service">Service</p>
                                                    <Link to="javascript:void(0)">
                                                        <IoArrowUpCircleOutline />
                                                    </Link>
                                                </td>
                                                <td>
                                                <Form.Group>
                                                    <Form.Select className="form-control">
                                                    <option value="WIB" selected>Hindustan Ltd.</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Check type={"checkbox"} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p title="Impacted CI">Impacted CI</p>
                                                    <Link to="javascript:void(0)">
                                                        <IoArrowUpCircleOutline />
                                                    </Link>
                                                </td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p title="Category">Category</p>
                                                    <Link to="javascript:void(0)">
                                                        <IoArrowUpCircleOutline />
                                                    </Link>
                                                </td>
                                                <td>
                                                <Form.Group>
                                                    <Form.Select className="form-control">
                                                    <option value="WIB" selected>IT Asset</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Check type={"checkbox"} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p title="Priority">Priority</p>
                                                    <Link to="javascript:void(0)">
                                                        <IoArrowUpCircleOutline />
                                                    </Link>
                                                </td>
                                                <td>
                                                <Form.Group>
                                                    <Form.Select className="form-control">
                                                    <option value="WIB" selected>P4</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Check type={"checkbox"} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p title="Risk Residual">Risk Residual</p>
                                                    <Link to="javascript:void(0)">
                                                        <IoArrowUpCircleOutline />
                                                    </Link>
                                                </td>
                                                <td>
                                                <Form.Group>
                                                    <Form.Select className="form-control">
                                                    <option value="WIB" selected>Low</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Check type={"checkbox"} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <div className="text-r margin-t-5">
                                        <Button type="button" bsSize="xsmall" className="applyBtn">Apply</Button>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                                {showFcr &&
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            <h4 className="font1">FCR</h4>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {result?.true ?
                                                <div className="aiSecBox">
                                                    <div className="aiSec1"><div>Possibility</div>{result.true}</div>
                                                    <div>Can be Potential <span className="primaryColorDarkest">First-Contact Resolution (FCR)</span></div>
                                                </div>
                                                :
                                                <div>{tr['Data not available']}</div>
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                }
                                {props.aismDSAT &&
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                            <h4 className="font1">DSAT</h4>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {dsatResult ?
                                                <D2CPieChart data={dsatData} title={props.title} />
                                                :
                                                <div>{tr['Data not available']}</div>
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                }
                                {props.isReopen &&
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                            <h4 className="font1">REOPEN</h4>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {reOpenRate ?
                                                <div className="aiSecBox">
                                                    <div className="aiSec1"><div>Possibility</div>{reOpenRate}%</div>
                                                    <div>Chance of getting Reopen</div>
                                                </div>
                                                :
                                                <div>{tr['Data not available']}</div>
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                }                                
                        </Accordion>
                    </>
                ) : (
                    <div>{tr['No AI insights available for this ticket']}</div>
                )}
            </Tab>
        </Tabs>
                </div>
            </div>
        </div>
    );
};

export default AiInsights;
