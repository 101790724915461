/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React from "react";
import { AsyncTypeahead, Highlighter, Token } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import axios from "axios";
import { Col, Form, Row } from "react-bootstrap";
import {
  getTypeAheadChildQuestion,
  removeChildQuestion,
} from "../../../../../actions/cart/addToCartActions";
import { connect } from "react-redux";
import { GLOBAL } from "../../../../Globals";
import { change } from "redux-form";
import Swal from "sweetalert2";
import _, { debounce } from "lodash";

const generateHtmlContent = (props) => {
  const { label } = props;
  const hideUserNameID =
    (props.label == "Full Name" ||
      props.label == "User ID" ||
      props.label == "user id" ||
      props.label == "Vollständiger Name" ||
      props.label == "Nom complet" ||
      props.label == "ID utilisateur" ||
      props.label == "Benutzer-ID" ||
      props.label == "CI ID" ||
      props.label == "CI CODE" ||
      props.label == "CLASS ID" ||
      props.label == "COMPANY ID" ||
      props.label == "COMPANY NAME" ||
      props.label == "SUPPORT COMPANY_ID" ||
      props.label == "LOCATION ID" ||
      props.label == "METALLIC") &&
    props.offering.deliveryMode == "Other"
      ? "hideROInput"
      : null;
  return (
    <>
      {label == "METALLIC" || label == "ENVIRONMENT" ? (
        ""
      ) : (
        <Row className={`botLineWhite ${hideUserNameID}`} key={props.key}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Group>
              <Form.Label>{props.label}</Form.Label>
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Group>
              <Form.Control
                componentClass={props.elementType}
                value={props.value}
                style={props.style}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>
      )}
    </>
  );
};

class OnBehalfTypeAhead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      selectHintOnEnter: true,
      highlightOnlyResult: false,
      isLoading: false,
      minLength:
        props.questionnaire.callApiAfterTypeahead &&
        props.questionnaire.callApiAfterTypeahead != null
          ? parseInt(props.questionnaire.callApiAfterTypeahead, 10)
          : 3,
      delay: 200,
      useCache: false,
      url: "",
      //placeholder: "enter search keywords",
      readOnlyInputHtml: [],
      mobileModelsHtml: [],
      typeaheadTyped: "",
      showSerachlence: true,
      disableSearch: false,
      selected: [],
      setInitialValue: '',
    };
    this.onValueSelection = this.onValueSelection.bind(this);
    this._renderMenuItemChildren = this._renderMenuItemChildren.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.setReadOnlyValues = this.setReadOnlyValues.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onTypeaheadblur = this.onTypeaheadblur.bind(this);
    this.onTypeaheadClick = this.onTypeaheadClick.bind(this);
    this.getOptionsForMultiSelect = this.getOptionsForMultiSelect.bind(this);
    this.onClickToken = this.onClickToken.bind(this);
  }

  componentWillMount() {
    const { questionnaire } = this.props;
    this.setState({ url: questionnaire.api });
  }

  componentDidUpdate() {
    const { questionnaire, templateValuesRed, setChildQuestions, parentProps } = this.props;
    if (
      templateValuesRed?.[questionnaire.questionId] &&
      templateValuesRed[questionnaire.questionId].value.length > 0 &&
      this.state.setInitialValue !== templateValuesRed?.id?.toString()
    ) {
      if (questionnaire.haveChild === "true") {
        setChildQuestions({
          payload: {
            selectedValue: templateValuesRed[questionnaire.questionId].value.at(-1),
            question: questionnaire,
            componentIds: parentProps.componentIds,
          },
          type: "typeahead",
          fetched: false
        })
      }
      this.setState({ setInitialValue: templateValuesRed?.id?.toString() });
      this.onValueSelection(templateValuesRed[questionnaire.questionId].value);
      if (questionnaire.responseType !== "multiSelectTypeahead") {
        this.onTypeaheadblur({
          target: {
            value:
              Array.isArray(
                templateValuesRed[questionnaire.questionId].value
              ) && templateValuesRed[questionnaire.questionId].value.length > 0
                ? templateValuesRed[questionnaire.questionId].value[0].Name
                : "",
          },
        });
      }
    }
  }

  onValueSelection(value) {
    const {
      questionnaire,
      setAnswer,
      dispatch,
      parentProps,
      piSectionFormErrors,
      setFormErrors,
      handleOnChange,
    } = this.props;
    let selectedValue = value[0];
    if (questionnaire.responseType === "multiSelectTypeahead") {
      selectedValue = value.at(-1);
      let oldSelectedValue = this.state.selected;
      if (
        Array.isArray(oldSelectedValue) &&
        oldSelectedValue.length > 0 &&
        value.length > oldSelectedValue.length
      ) {
        let duplicate = oldSelectedValue.some((val) => {
          return _.isEqual(val, selectedValue);
        });
        if (duplicate) {
          this.setState({
            typeaheadTyped: false,
            readOnlyInputHtml: this.setReadOnlyValues(
              questionnaire.roResponses,
              selectedValue
            ),
          });
          return;
        }
      }
      let valueStr = value
        .reduce((acc, curr) => {
          acc.push(curr.itemValue);
          return acc;
        }, [])
        .join(", ");
      let event = {
        target: {
          name: questionnaire.name,
          value: valueStr,
        },
      };
      handleOnChange(questionnaire, event);
    } else {
      setAnswer(questionnaire, selectedValue, dispatch);
    }
    this.setState({ readOnlyInputHtml: [], mobileModelsHtml: [] });
    if (typeof selectedValue !== "undefined") {
      this.setState({
        readOnlyInputHtml: this.setReadOnlyValues(
          questionnaire.roResponses,
          selectedValue
        ),
        selected: value,
        typeaheadTyped: false
      });
      let errorObj = Object.assign({}, piSectionFormErrors, {
        [questionnaire.name]: false,
      });
      setFormErrors(errorObj);
    } else {
      if (questionnaire.questionType === "mandatory") {
        let errorObj = Object.assign({}, piSectionFormErrors, {
          [questionnaire.name]: true,
        });
        setFormErrors(errorObj);
      }
    }
    this.props.setTemplateResponses(
      questionnaire.questionId, 
      { type: questionnaire.responseType, value, name: questionnaire.questionText }
    );

    if (
      questionnaire.responseType === "multiSelectTypeahead" &&
      value.length === 0
    ) {
      this.setState({ showSerachlence: true });
    }

    if (
      selectedValue &&
      questionnaire.responseType !== "multiSelectTypeahead"
    ) {
      this.props.getTypeAheadChildQuestion(
        selectedValue,
        questionnaire,
        parentProps.componentIds
      );
    }
  }

  getOptionsForMultiSelect(data = []) {
    const {
      dataSourceIdentifier,
      responseType,
      lookupKey,
      dataSourceLabelKey,
    } = this.props.questionnaire;
    let optionsList = [];
    if (responseType === "multiSelectTypeahead") {
      let objectKey = "";
      if (dataSourceIdentifier) {
        if (dataSourceIdentifier.includes("~")) {
          let keyAndLabelHolder = dataSourceIdentifier.split("~");
          objectKey = keyAndLabelHolder[0];
        } else {
          objectKey = dataSourceIdentifier;
        }
      }
      if (Array.isArray(data)) {
        optionsList = data.map((option) => {
          let labelKey = "Name";
          if (option?.name && lookupKey !== "asset_type") {
            labelKey = "name";
          }
          if (dataSourceLabelKey) {
            labelKey = dataSourceLabelKey;
            option.Name = option[dataSourceLabelKey];
          }
          if (objectKey && objectKey !== "" && option[objectKey]) {
            option.itemValue = `${option[labelKey]} (${option[objectKey]})`;
          } else {
            option.itemValue = `${option[labelKey]}`;
          }
          return option;
        });
      }
    } else {
      if (dataSourceLabelKey) {
        optionsList = data.map((obj) => {
          return { ...obj, Name: obj[dataSourceLabelKey] };
        });
      } else {
        optionsList = data.map((option) => {
          let labelKey = "Name";
          if (option.hasOwnProperty("name") && !option.hasOwnProperty("Name")) {
            labelKey = "name";
          } else if (
            option.hasOwnProperty("NAME") &&
            !option.hasOwnProperty("Name")
          ) {
            labelKey = "NAME";
          }
          option.Name = `${option[labelKey]}`;
          return option;
        });
      }
    }
    return optionsList;
  }

  setReadOnlyValues(roValues, selectedValue) {
    let readOnlyHtml = [];
    if (selectedValue) {
      if (roValues == null) {
        readOnlyHtml = [];
      } else {
        readOnlyHtml = roValues.map((value, index) => {
          let readOnlyStringValue = roValues[index];
          let objectKey;
          let label;
          if (readOnlyStringValue.includes("~")) {
            let keyAndLabelHolder = readOnlyStringValue.split("~");
            objectKey = keyAndLabelHolder[0];
            label = keyAndLabelHolder[1];
          } else {
            if (readOnlyStringValue.includes("_")) {
              let objCopy = JSON.parse(JSON.stringify(readOnlyStringValue));
              label = objCopy.replace("_", " ").toString();
              objectKey = readOnlyStringValue;
            } else {
              label = readOnlyStringValue;
              objectKey = readOnlyStringValue;
            }
          }
          let isElementTypeAsset = objectKey === "Assets" ? true : false;
          return generateHtmlContent({
            elementType: isElementTypeAsset ? "textarea" : "input",
            value: isElementTypeAsset
              ? selectedValue.Assets.join("\r\n\n")
              : selectedValue[objectKey],
            style: isElementTypeAsset ? { height: 60 } : {},
            label: label,
            offering: this.props.offering,
          });
        });
      }
    }
    return readOnlyHtml;
  }

  onSearch = debounce((searchKeywords) => {
    const { questionnaire, dispatch } = this.props;
    const companyId = GLOBAL.onBehalfUserCompanyId;
    this.setState({ options: [] });
    this.setState({ isLoading: true });
    let query = {};
    if (this.state.url.includes("&companyId=")) {
      query.api = this.state.url.replace(
        "&companyId=",
        "&companyId=" + companyId
      );
    } else if (this.state.url.includes("?q=")) {
      query.api = this.state.url.replace(
        "?q=",
        "?companyId=" + companyId + "&q="
      );
    } else if (this.state.url.includes("&q=")) {
      query.api = this.state.url.replace(
        "&q=",
        "&companyId=" + companyId + "&q="
      );
    } else {
      query.api = this.state.url;
    }
    if (questionnaire.lookupKey == "asset_type") {
      query.serialnumber = searchKeywords;
      query.companyid = companyId;
    } else {
      query.q = searchKeywords;
      if (questionnaire.dataSourceSearchAttributes) {
        query.filterKeys = questionnaire.dataSourceSearchAttributes;
      }
    }
    try {
      let filterCriteria = JSON.parse(questionnaire.dataSourceFilterCriteria);
      if (
        filterCriteria &&
        Array.isArray(filterCriteria) &&
        filterCriteria.length > 0
      ) {
        if (
          filterCriteria.length === 1 &&
          filterCriteria[0].field === "nationality"
        ) {
          let api = query.api.split("nationality=");
          query.api =
            api[0] + "nationality=" + filterCriteria[0].value + api[1];
        } else {
          query.criteria = questionnaire.dataSourceFilterCriteria;
        }
      }
    } catch (err) {
      console.log("err -", err);
    }

    axios
      .get("/search/typeahead/data/", {
        headers: { query: JSON.stringify(query) },
      })
      .then((response) => {
        if (questionnaire.lookupKey == "asset_type") {
          let resp = response.data;
          if (resp.data) {
            resp.data.forEach((temp) => {
              temp.Name = temp.serial_number;
            });
            if (questionnaire.responseType === "multiSelectTypeahead") {
              resp.data = this.getOptionsForMultiSelect(resp.data);
            }

            this.setState({
              isLoading: false,
              options: resp.data,
              readOnlyInputHtml: [],
              showSerachlence: false,
              //placeholder: "enter search keywords..."
            });
          } else {
            this.setState({
              isLoading: false,
              options: [],
              readOnlyInputHtml: [],
              // placeholder: "Sorry ! No matching data found..."
            });
          }
        } else {
          if (response.data.length === 0) {
            // this._typeahead._instance.clear();
            this.setState({
              isLoading: false,
              options: [],
              readOnlyInputHtml: [],
              // placeholder: "Sorry ! No matching data found..."
            });
          } else {
            let optionsList = this.getOptionsForMultiSelect(response.data);
            this.setState({
              isLoading: false,
              options: optionsList,
              readOnlyInputHtml: [],
              showSerachlence: false,
              //placeholder: "enter search keywords..."
            });
          }
        }
      })
      .catch((err) => {
        this.setState({
          searchText: "oops ! something went wrong..",
          options: [],
        });
        console.log("error occurred loading data...");
        console.log(err);
      });
  }, 500);

  _renderMenuItemChildren(option, props) {
    return [
      <Highlighter key="Name" search={props.text}>
        {option.Name}
      </Highlighter>,
    ];
  }

  filterByCallback(option) {
    return option ? Object.keys(option) : ["Name"];
  }

  onInputChange(value) {
    const {
      questionnaire,
      setAnswer,
      dispatch,
      piSectionFormErrors,
      setFormErrors,
    } = this.props;
    if (value) {
      this.setState({ showSerachlence: false, typeaheadTyped: true });
    } else if (value.trim() === " ") {
      this.setState({ showSerachlence: false, typeaheadTyped: true });
    } else {
      this.setState({ isLoading: false, showSerachlence: true });
      let errorObj = Object.assign({}, this.props.piSectionFormErrors, {
        [questionnaire.name]: false,
      });
      this.props.setFormErrors(errorObj);
      if (
        questionnaire.questionType === "mandatory" &&
        this.state.selected.length > 0
      ) {
        this.setState({ typeaheadTyped: false });
      } else if (questionnaire.questionType !== "mandatory") {
        this.setState({ typeaheadTyped: false });
      }
    }
    this.props.removeChildQuestion(questionnaire);
    this.props.setTemplateResponses(
      questionnaire.questionId, 
      { type: questionnaire.responseType, value: [] }
    );
    if (value === "") {
      const { questionnaire, setAnswer, dispatch } = this.props;
      if (questionnaire.responseType === "multiSelectTypeahead") {
        if (
          questionnaire.questionType === "mandatory" &&
          this.state.selected.length === 0
        ) {
          setAnswer(questionnaire, "", dispatch);
          this.setState({ readOnlyInputHtml: [], mobileModelsHtml: [] });
          let errorObj = Object.assign({}, piSectionFormErrors, {
            [questionnaire.name]: true,
          });
          setFormErrors(errorObj);
          this.setState({ typeaheadTyped: true, showSerachlence: true });
        } else if (this.state.selected.length > 0) {
          this.setState({ showSerachlence: false });
        }
      } else {
        if (questionnaire.questionType === "mandatory") {
          setAnswer(questionnaire, "", dispatch);
          this.setState({ readOnlyInputHtml: [], mobileModelsHtml: [] });
          let errorObj = Object.assign({}, piSectionFormErrors, {
            [questionnaire.name]: true,
          });
          setFormErrors(errorObj);
          this.setState({ typeaheadTyped: true });
        }
      }
    }
  }
  getLabelKey() {
    return "Name";
  }

  //this function added by Priyeshwar
  onTypeaheadClick() {
    const {
      questionnaire,
      dispatch,
      piSectionFormErrors,
      setFormErrors,
      setAnswer,
    } = this.props;
    this._typeahead.clear();
    this.setState({ showSerachlence: true });
    this.setState({ readOnlyInputHtml: [], mobileModelsHtml: [] });
    this.setState({
      isLoading: false,
      options: [],
      readOnlyInputHtml: [],
      selected: [],
    });
    if (questionnaire.questionType === "mandatory") {
      let errorObj = Object.assign({}, piSectionFormErrors, {
        [questionnaire.name]: true,
      });
      setFormErrors(errorObj);
    } else {
      this.setState({ typeaheadTyped: false }, () =>
        this.onTypeaheadblur({ target: { value: "" } })
      );
    }
    this.props.removeChildQuestion(questionnaire);
    setAnswer(questionnaire, "", dispatch);
    dispatch(change("orderingForm", questionnaire.name, ""));
    this.props.setTemplateResponses(
      questionnaire.questionId, 
      { type: questionnaire.responseType, value: [], name: questionnaire.questionText }
    );
  }

  onTypeaheadblur(e) {
    const { questionnaire, dispatch, piSectionFormErrors, setFormErrors } =
      this.props;
    if (
      this.state.typeaheadTyped ||
      (questionnaire.responseType === "multiSelectTypeahead" &&
        questionnaire.questionType === "mandatory" &&
        this.state.selected.length === 0)
    ) {
      dispatch(change("orderingForm", questionnaire.name, ""));
      let errorObj = Object.assign({}, piSectionFormErrors, {
        [questionnaire.name]: true,
      });
      setFormErrors(errorObj);
    } else {
      dispatch(change("orderingForm", questionnaire.name, e.target.value));
      let errorObj = Object.assign({}, piSectionFormErrors, {
        [questionnaire.name]: false,
      });
      setFormErrors(errorObj);
    }
  }

  onClickToken(token) {
    this.setState({
      readOnlyInputHtml: this.setReadOnlyValues(
        this.props.questionnaire.roResponses,
        token
      ),
    });
  }

  render() {
    const { questionnaire, parentProps, dispatch, questionType } = this.props;
    const props = {};
    const { readOnlyInputHtml, mobileModelsHtml } = this.state;
    let fieldClass = this.props.piSectionFormErrors[questionnaire.name]
      ? "error typeahead"
      : "typeahead";
    const { showSerachlence, isLoading } = this.state;
    props.renderMenuItemChildren = this._renderMenuItemChildren;
    props.multiple = this.props.multiple;
    let labelKey = "Name";
    if (questionnaire.responseType === "multiSelectTypeahead") {
      props.renderToken = (option, { onRemove }, index) => (
        <Token
          key={index}
          onRemove={onRemove}
          option={option}
          onClick={() => this.onClickToken(option)}
        >
          {option.itemValue}
        </Token>
      );
    }
    return (
      <div
        className={
          questionnaire.responseType === "multiSelectTypeahead"
            ? "notifydvTypehd"
            : "dvTypehd"
        }
      >
        {!isLoading ? (
          showSerachlence ? (
            <span
              title={this.props.translator["Search"]}
              role="presentation"
              aria-hidden="true"
              className="css-hakgx8 icn"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                role="presentation"
              >
                <path
                  d="M16.436 15.085l3.94 4.01a1 1 0 01-1.425 1.402l-3.938-4.006a7.5 7.5 0 111.423-1.406zM10.5 16a5.5 5.5 0 100-11 5.5 5.5 0 000 11z"
                  fill="currentColor"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </span>
          ) : (
            <span
              title={this.props.translator["Clear"]}
              role="img"
              aria-label="Clear"
              className="css-hakgx8 icn"
              onClick={this.onTypeaheadClick}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                role="presentation"
              >
                <path
                  d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          )
        ) : (
          ""
        )}

        <AsyncTypeahead
          id={questionnaire.qId}
          {...parentProps.input}
          {...this.state}
          {...props}
          ref={(ref) => (this._typeahead = ref)}
          useCache={false}
          onSearch={this.onSearch}
          labelKey={labelKey}
          filterBy={this.filterByCallback}
          onChange={this.onValueSelection}
          onInputChange={this.onInputChange}
          onBlur={(e, questionnaire) => this.onTypeaheadblur(e, questionnaire)}
          placeholder={
            questionnaire.placeholder !== null
              ? questionnaire.placeholder
              : "enter search keywords"
          }
          className={fieldClass}
          flip={true}
          positionFixed={true}
          renderMenuItemChildren={(option, props) => {
            let label;
            let title;
            if (labelKey !== null) {
              label = option[labelKey];
              title = option[labelKey];
            } else {
              label = option.label;
              title = option.label;
            }
            if (option?.Company_Name) {
              return (
                <div>
                  <span
                    className="typHdmainHead"
                    title={title}
                    style={{ display: "block" }}
                  >
                    {label}
                  </span>
                  <span
                    className="typHdmainSubHead"
                    style={{
                      display: "block",
                      "margin-top": "6px",
                      color: "#777",
                    }}
                  >
                    {option?.Company_Name || ""}
                  </span>
                </div>
              );
            }
            return (
              <div>
                <span title={title}>{label}</span>
                <span></span>
              </div>
            );
          }}
        />
        {readOnlyInputHtml.length === 0 ? null : readOnlyInputHtml}
        {mobileModelsHtml.length === 0 ? null : mobileModelsHtml}
      </div>
    );
  }
}

export default connect(null, {
  getTypeAheadChildQuestion,
  removeChildQuestion,
})(OnBehalfTypeAhead);
