/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import { getInitialSettings, daysDifference } from '../../../common/helper';
import moment from 'moment';
import Cookies from "universal-cookie";

const cookies = new Cookies();
let defaultDateRangeValue = cookies.get("gph");
if (defaultDateRangeValue){
  defaultDateRangeValue = defaultDateRangeValue.replace("s:", "");
}
if (defaultDateRangeValue){
  defaultDateRangeValue = defaultDateRangeValue.substring(0, defaultDateRangeValue.lastIndexOf("."));
}
defaultDateRangeValue = defaultDateRangeValue.split("~");
defaultDateRangeValue = defaultDateRangeValue[53];

const DateRangeComponent = ({handleOnChange, value, fieldData}) => {
  const [dateRangeToShow, setDateRangeToShow] = useState('');
  const dateFormat = "MM-DD-YYYY";
  const hasPreValue = value.length > 0;
  const defaultDateRangeDays = hasPreValue ? daysDifference(moment(value[0], dateFormat), moment(value[1], dateFormat)) : '';
  const initialStartDate = hasPreValue ? moment(value[0], dateFormat) : moment().subtract(defaultDateRangeDays, "days");
  const initialEndDate = hasPreValue ? moment(value[1], dateFormat) : moment();
  const [fieldName, setFieldName] = useState('');
  const translator = useSelector(state => state.spcmReducer.tr);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [initialSettings, setInitialSettings] = useState(getInitialSettings(startDate, endDate));
  const dateRangePickerRef = useRef(null);
  const handleValueChange = (value) => {
    let [startDate, endDate] = value;
    if(moment(value[0], 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()){
      startDate = moment(value[0], dateFormat);
    }else{
      startDate = moment(value[0]);
    }
    if(moment(value[1], 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()){
      endDate = moment(value[1], dateFormat);
    }else{
      endDate = moment(value[1]);
    }
    const dateStringToStore = `${startDate.format("MM/DD/YYYY")} - ${endDate.format("MM/DD/YYYY")}`;
    if(dateStringToStore !== dateRangeToShow){
      setDateRangeToShow(`${startDate.format("MM/DD/YYYY")} - ${endDate.format("MM/DD/YYYY")}`);
      handleOnChange([startDate.format(dateFormat), endDate.format(dateFormat)]);
    }
  }
  const handleChange = (start, end) => {
    handleValueChange([start, end]);
  };

  useEffect(() => {
    if (value != undefined && value != null && value.length != 0 && value?.[0] != dateRangeToShow?.[0]) {
        handleValueChange(value);
    }
    setFieldName(fieldData?.name);
  }, []);

  useEffect(() => {
    if(fieldName !== fieldData.name && (value === '' || value?.length === 0)){
      setDateRangeToShow('');
      setFieldName(fieldData.name);
      handleOnChange('');
      if (dateRangePickerRef.current) {
        dateRangePickerRef.current.setStartDate(moment().subtract(defaultDateRangeDays, "days"));
        dateRangePickerRef.current.setEndDate(moment());
      }
    }
   }, [fieldData]);
  return (
    <div className="colSearDv">
      <DateRangePicker initialSettings={initialSettings} onCallback={handleChange} ref={dateRangePickerRef} >
       <input type="text" placeholder={translator['Search here']} readOnly
              className="form-control"
              value={dateRangeToShow}
              id="probReportedOnField" />
      </DateRangePicker>
    </div>
  );
};

export default DateRangeComponent;