/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { findChildElement } from "./helper";
import TypeAheadComponent from "./WorkItemBoard/QueryComponent/TypeAheadComponent";
import TypeAheadDynamicComponent from "./WorkItemBoard/QueryComponent/TypeAheadDynamicComponent";
import MultiSelectDropdownComponent from "./WorkItemBoard/QueryComponent/MultiSelectDropdownComponent";
import InputFieldComponent from "./WorkItemBoard/QueryComponent/InputFieldComponent";
import RadioButtonComponent from "./WorkItemBoard/QueryComponent/RadioButtonComponent";
import TextAreaComponent from "./WorkItemBoard/QueryComponent/TextAreaComponent";
import DateRangeComponent from './WorkItemBoard/QueryComponent/DateRangeComponent';
import IntegerFieldComponent from './WorkItemBoard/QueryComponent/IntegerFieldComponent';
import { useSelector } from "react-redux";

import Cookies from "universal-cookie";
const cookies = new Cookies();
let cookiesValues = cookies.get("gph");
if (cookiesValues) cookiesValues = cookiesValues.replace("s:", "");
if (cookiesValues) cookiesValues = cookiesValues.substring(0, cookiesValues.lastIndexOf("."));
cookiesValues = cookiesValues.split("~");
const NLP_TYPEAHEAD_MAX_SELECTION = cookiesValues[69] === undefined || cookiesValues[69] === 'undefined' || cookiesValues[69] === '' ? '20' : cookiesValues[69];
//Display Message to User
export const displayMessage = (message,ok) => {
  swal({text: message,button: ok});
};

//Generates Dropdown fields for value and label objects
export const FieldValueSelector = ({className,handleOnChange,options,value,context}) => {
    const translator = useSelector(state => state.spcmReducer.tr);
    return (<>
      <select className="" value={value} onChange={(v) => {handleOnChange(v.target.value); /*handleOnRuleChange(v.target.value); */}}>
        {options.map((item, i) => {
          const key = i;
          return (
            <option key={key} value={item.name}>
              {translator[item.label]}
            </option>
          );
        })}
      </select>
	  </>);
};

// Customized Components for User Value Selection for Breakfix module
export const BreakfixValueEditor = ({operator, value, handleOnChange, context, disabled, field, ...valueEditorProps}) => { 
    const { fields } = valueEditorProps?.schema ?? {};
    const fieldData = findChildElement(fields, "name", field, "attributes");
    const showTypeAhead = fieldData?.inputType === 'typeahead';
    const showDynamicTypeAhead = fieldData?.inputType === 'dropdown+typeahead';
    const showDropDown = fieldData?.inputType === 'dropdown';
    const showDateTime = fieldData?.inputType === 'dateRange';
    const showInputField = fieldData?.inputType === 'input';
    const showRadioButton = fieldData?.inputType === 'radio';
    const showTextArea = fieldData?.inputType === 'textarea';
    const showIntegerField = fieldData?.inputType === 'integerTextArea';

    const translator = useSelector(state => state.spcmReducer.tr);

    const inputFieldPlaceholder = fieldData?.name === 'requestNumber' ? 'REQ000000012345' 
    : fieldData?.name === 'breakfixNumber' ?  'INC000000012345' 
    : translator['Enter Some Text'];

    const statusData = useSelector(state => state.statusData);
    const priorityData = useSelector(state => state.priorityData); 
    const impactedCiData = useSelector(state => state.ciData);
    const serviceData = useSelector(state => state.serviceData);
    const classData = useSelector(state => state.advanceSearchClass);
    const slaStatusData = useSelector(state => state.slaStatusData);

    const criticalityData = useSelector(state => state.criticalityData);
    const cimGroupData = useSelector(state => state.cimGroupData);
    const supportGroupData = useSelector(state => state.supportGroupData);
    const serviceBasedData = useSelector(state => state.serviceBasedData);

    const companyList = useSelector(state => state.companyList);
    const reportedThroughDataBreakfix = useSelector(state => state.reportedThroughDataBreakfix);
    
    const urgencyList = useSelector(state => state.NlpUrgencyList);
    const impactList = useSelector(state => state.NlpImpactList);

    const dropDownData = fieldData?.name === 'ciName' ? impactedCiData 
    : fieldData?.name === 'status' ? statusData 
    : fieldData?.name === 'ciClassName' ? classData
    : fieldData?.name === 'serviceName' ? serviceData 
    : fieldData?.name === 'priorityId' ? priorityData 
    : fieldData?.name === 'criticality' ? criticalityData 
    : fieldData?.name === 'cimGroupname' ? cimGroupData 
    : fieldData?.name === 'spGroupname' ? supportGroupData 
    : fieldData?.name === 'slaStatusValue' ? slaStatusData
    : fieldData?.name === 'consumerCompanyName' ? companyList
    : fieldData?.name === 'serviceBased' ? serviceBasedData
    : fieldData?.name === 'reportedThrough' ? reportedThroughDataBreakfix
    : fieldData?.name === 'urgencyValue' ? urgencyList
    : fieldData?.name === 'serviceCriticalityValue' ? impactList
    : [];

    return (<div className="aiRuleInptWidth">
       {showDynamicTypeAhead && <TypeAheadDynamicComponent handleOnChange={handleOnChange} value={value || []} context={context} fieldData={fieldData} maximumElemantsSelected={NLP_TYPEAHEAD_MAX_SELECTION}/>}
       {showTypeAhead && <TypeAheadComponent handleOnChange={handleOnChange} value={value || []} context={context} fieldData={fieldData} drpoDownData={dropDownData} maximumElemantsSelected={NLP_TYPEAHEAD_MAX_SELECTION} />}
       {showDropDown && <MultiSelectDropdownComponent handleOnChange={handleOnChange} value={value || []} context={context} fieldData={fieldData} drpoDownData={dropDownData} />}
       {showDateTime && <DateRangeComponent handleOnChange={handleOnChange} value={value || []} context={context} fieldData={fieldData} />}
       {showInputField && <InputFieldComponent handleOnChange={handleOnChange} value={value} context={context} fieldData={fieldData} inputFieldPlaceholder={inputFieldPlaceholder}/>}
       {showRadioButton && <RadioButtonComponent handleOnChange={handleOnChange} value={value} context={context} fieldData={fieldData} btnData={dropDownData} />}
       {showTextArea && <TextAreaComponent handleOnChange={handleOnChange} value={value} context={context} fieldData={fieldData} preDefinedMaxLength={2000} />}
       {showIntegerField && <IntegerFieldComponent handleOnChange={handleOnChange} value={value} context={context} fieldData={fieldData} minimumValue={0} maximumValue={100} />}
    </div>);
};

// Customized Components for User Value Selection for Fulfillment module
export const FulfillmentValueEditor = ({operator, value, handleOnChange, context, disabled, field, ...valueEditorProps}) => { 
  const { fields } = valueEditorProps?.schema ?? {};
    const fieldData = findChildElement(fields, "name", field, "attributes");
    const showTypeAhead = fieldData?.inputType === 'typeahead';
    const showDynamicTypeAhead = fieldData?.inputType === 'dropdown+typeahead';
    const showDropDown = fieldData?.inputType === 'dropdown';
    const showDateTime = fieldData?.inputType === 'dateRange';
    const showInputField = fieldData?.inputType === 'input';
    const showRadioButton = fieldData?.inputType === 'radio';
    const showTextArea = fieldData?.inputType === 'textarea';
    const showIntegerField = fieldData?.inputType === 'integerTextArea';

    const translator = useSelector(state => state.spcmReducer.tr);

    const inputFieldPlaceholder = fieldData?.name === 'orderNumber' ? 'REQ000000012345' 
    : fieldData?.name === 'itemNumber' ?  'ITM000000012345' 
    : translator['Enter Some Text'];

    const serviceData = useSelector(state => state.serviceData);
    const slaStatusData = useSelector(state => state.slaStatusData);
    const supportGroupData = useSelector(state => state.supportGroupData);
    const statusData = useSelector(state => state.statusData);

    const companyList = useSelector(state => state.companyList);
    const reportedThroughDataFulfillment = useSelector(state => state.reportedThroughDataFulfillment);
    
    const dropDownData = fieldData?.name === 'offeringNameSx' ? serviceData
    : fieldData?.name === 'slaStatusValue' ? slaStatusData
    : fieldData?.name === 'asgGroupname' ? supportGroupData
    : fieldData?.name === 'consumerCompanyName' ? companyList
    : fieldData?.name === 'itemStatus' ? statusData 
    : fieldData?.name === 'reportedThrough' ? reportedThroughDataFulfillment
    : [];

    return (<div className="aiRuleInptWidth">
       {showDynamicTypeAhead && <TypeAheadDynamicComponent handleOnChange={handleOnChange} value={value || []} context={context} fieldData={fieldData} maximumElemantsSelected={NLP_TYPEAHEAD_MAX_SELECTION}/>}
       {showTypeAhead && <TypeAheadComponent handleOnChange={handleOnChange} value={value || []} context={context} fieldData={fieldData} drpoDownData={dropDownData} maximumElemantsSelected={NLP_TYPEAHEAD_MAX_SELECTION} />}
       {showDropDown && <MultiSelectDropdownComponent handleOnChange={handleOnChange} value={value || []} context={context} fieldData={fieldData} drpoDownData={dropDownData} />}
       {showDateTime && <DateRangeComponent handleOnChange={handleOnChange} value={value || []} context={context} fieldData={fieldData} />}
       {showInputField && <InputFieldComponent handleOnChange={handleOnChange} value={value} context={context} fieldData={fieldData} inputFieldPlaceholder={inputFieldPlaceholder}/>}
       {showRadioButton && <RadioButtonComponent handleOnChange={handleOnChange} value={value} context={context} fieldData={fieldData} btnData={dropDownData} />}
       {showTextArea && <TextAreaComponent handleOnChange={handleOnChange} value={value} context={context} fieldData={fieldData} preDefinedMaxLength={2000} />}
       {showIntegerField && <IntegerFieldComponent handleOnChange={handleOnChange} value={value} context={context} fieldData={fieldData} minimumValue={0} maximumValue={100} />}
    </div>);
};