
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';
import { _inputField, _textArea, _dropDown,_dropDown1, _dateField,_latentField, _dateTimeField, _latentDateTimeField } from '../../../common/ReduxFormFields/formFields';
import UploadScreen from './Attachment';
import axios from 'axios'
import { bindActionCreators } from 'redux';
import RFReactConsumer from '../../../common/WorkItemBoard/AutoSearchConsumer';
import { Field, reduxForm} from 'redux-form';
import { loadProfile2 } from '../../../../actions/myProfile/profileAction';
import { GLOBAL } from "_Globals";
import ListLoader from '_Commons/loaders/ListLoader';
import _ from 'lodash';
import {ImSpinner6} from "react-icons/im";
import {IoClose} from "react-icons/io5";
import {ImInfo} from 'react-icons/im';
import Cookies from 'universal-cookie';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import XsmUserDetails from '_Commons/WorkItemBoard/xsmEditRequestedBy.js';
import ServiceDetails from "../../../common/WorkItemBoard/xsm-service-editquickView";
import CIDEtails from "../../../common/WorkItemBoard/xsm-ci-editquickView";
import { opsCategoryKeysMap } from '../../../common/helper';
const cookies = new Cookies();

let attachmentDetails = cookies.get('gph');
if(attachmentDetails) attachmentDetails = attachmentDetails.replace('s:','');
if(attachmentDetails) attachmentDetails = attachmentDetails.substring(0,attachmentDetails.lastIndexOf('.'));
attachmentDetails=attachmentDetails.split("~");
const attachmentSize=attachmentDetails[21];
const attachmentType=attachmentDetails[22];

let languageSelected = cookies.get("gph");
languageSelected = languageSelected.split("~");
languageSelected = languageSelected[20];

let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];
class BreakFixCopy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            consumerName:'',
            showConsumerError:'',
            showConsumerValidError:'',
            describeIssue:this.props.breakFixEditDetails.description=='null' || this.props.breakFixEditDetails.description==null ?'':this.props.breakFixEditDetails.description,
            showDescriptionError:0,
            showDescriptionValidError:'',
            additionalInfo:this.props.breakFixEditDetails.additionalInfo=='null' || this.props.breakFixEditDetails.additionalInfo==null ?'':this.props.breakFixEditDetails.additionalInfo,
            showadditionalInfoError:'',
            showaddInfoValidError:'',
            validResult:false,
            ResponseSuccess:false,
            disableButton:false,
            priority:'',
            priorityLabel: '',
            ticketNumGenerated:'',
            saveConsumer:{},
            oldBusinessCriticalityId: '',
            oldBusinessCriticality: '',
            urgencyList: [],
            urgencyListRecived: false,
            sortedUrgencyList: [],
            sortedImpactList: [],
            urgencyID:'',
            consDetalIconshow:false,
        }
        this.changeConsumer=this.changeConsumer.bind(this)
        this.onDescriptionChange=this.onDescriptionChange.bind(this)
        this.onAdditionalInfoChange=this.onAdditionalInfoChange.bind(this)
        this.postData=this.postData.bind(this)
        this.renderUrgencyName = this.renderUrgencyName.bind(this)
        this.getUrgency=this.getUrgency.bind(this)
        this.setErrorColor = this.setErrorColor.bind(this);
        this.afterConsumerSelected = this.afterConsumerSelected.bind(this);
        this.setshowVIPIcon=this.setshowVIPIcon.bind(this);
        this.showConsumerIcon = this.showConsumerIcon.bind(this);
    }
    setshowVIPIcon(value){
        this.state.saveConsumer.Vip='';  
        this.setState({saveConsumer:this.state.saveConsumer});
    }
    afterConsumerSelected(){
        if(GLOBAL.Vip==='Yes' || GLOBAL.Vip==='yes'){
            this.getUrgency(this.state.sortedUrgencyList[0].field1Value,this.state.sortedImpactList.at(-2).field1Value);
        }else{
            this.getUrgency(this.state.urgencyID,this.state.sortedImpactList.at(-2).field1Value);
        }
        this.setState({saveConsumer:{
            'breakFixConsumerName':GLOBAL.breakFixConsumerName,
            'breakFixConsumerID':GLOBAL.breakFixConsumerID,
            'Vip': (GLOBAL.Vip==='Yes' || GLOBAL.Vip==='yes') ?'yes':'no'
        }});
        GLOBAL.breakFixConsumerName=this.props.consumerState.breakFixConsumerName;
        GLOBAL.breakFixConsumerID=this.props.consumerState.breakFixConsumerID;
        GLOBAL.servicelocationid=this.props.consumerState.servicelocationid;
        GLOBAL.servicelocationname=this.props.consumerState.servicelocationname;
        GLOBAL.Vip=this.props.consumerState.Vip;
        GLOBAL.servicedepartmentid=this.props.consumerState.servicedepartmentid;
        GLOBAL.servicedepartmentname=this.props.consumerState.servicedepartmentname;  
        this.showConsumerIcon(true);
    }
    setErrorColor(errorColor,fieldType) {
        if(this.state.saveConsumer.breakFixConsumerName!='' && this.state.saveConsumer.breakFixConsumerID!='')
            this.setState({showConsumerError:'',showConsumerValidError:''})
    }
    getUrgency(urgencyValue,impactValue)
    {
        let companyId=this.props.breakFixEditDetails.serviceBased?this.props.breakFixEditDetails.serviceCompanyId:this.props.breakFixEditDetails.ciConsumerCompany;
        axios.get('/api/getCompanyPriorityMatrix?companyId='+companyId+'&module=Breakfix&impact='+impactValue+'&urgency='+urgencyValue).then((respondedPriority)=>{
            if(typeof respondedPriority.data[0].priority!=='undefined'){
                this.setState({priority: respondedPriority.data[0].priority, priorityLabel: respondedPriority.data[0].priorityValue});
            }
        })
    }
    componentWillMount() {
        if(this.props.disableFieldsForGuestRole){
            this.setState({urgencyListRecived: true});
        }else{
        this.setState({saveConsumer:{
            'breakFixConsumerName':'',
            'breakFixConsumerID':'',
            'Vip':''
        }});
        this.props.loadProfile2("");
        GLOBAL.filesToBeSent=[]   

        let companyId=this.props.breakFixEditDetails.serviceBased?this.props.breakFixEditDetails.serviceCompanyId:this.props.breakFixEditDetails.ciConsumerCompany;
        axios.get('/api/company_menulist?companyId='+companyId+'&module=Breakfix&field=Urgency,Impact&language='+languageSelected+'&status=1').then((responseDropdownValues)=>{
            let urgencyList=[], impactList=[];
            if(responseDropdownValues.data!==''){
             responseDropdownValues.data.map((item,i)=>{
                if(item.field2Name==='Impact'){
                    impactList.push(item);
                }else{
                    urgencyList.push(item);
                }
             });
             let sortedUrgency=_.sortBy(urgencyList, o=> parseInt(o.field1Value, 10));
             let sortedImpact=_.sortBy(impactList, o=> parseInt(o.field1Value, 10));
             this.getUrgency(this.props.breakFixEditDetails.urgency,sortedImpact.at(-1).field1Value);
             this.setState({urgencyList, urgencyListRecived: true, sortedUrgencyList: sortedUrgency, sortedImpactList: sortedImpact,urgencyID: this.props.breakFixEditDetails.urgency});
            }
        });
        }
	}
    componentWillUnmount() {
        GLOBAL.filesToBeSent=[]
    }

    renderUrgencyName(breakFixData) {
		let urgency=breakFixData
		if (!breakFixData) {
			return (
				<option></option>
			);
		}
		return urgency.map((breakFixObj) => {
			   return (
				<option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
			   );
		});
	}

    changeConsumer(e){
        this.setState({consumerName:e.target.value,showConsumerError:'',showConsumerValidError:''})
    }
    onDescriptionChange(e){
             this.setState({describeIssue:e.target.value,showDescriptionValidError:'',showDescriptionError:''})
    }
    onAdditionalInfoChange(e){     
              this.setState({additionalInfo:e.target.value,showaddInfoValidError:'',showadditionalInfoError:''}) 
    }
    postData(values){
        let count=0
        if(this.state.consumerName=='' || this.state.saveConsumer.breakFixConsumerName=='' || this.state.saveConsumer.breakFixConsumerID=='')
        {
            this.state.showConsumerValidError=this.props.translator['Consumer Cannot Be Empty']  
            this.state.showConsumerError='error'
            count++
        }    
        if(values.description.length!=0)
        {
            let count_size=0
            for(let i=0;i<values.description.length;i++)
            {
                if(values.description[i]==' ')
                   count_size=count_size+1
            } 
            if(values.description.length==count_size)
            {
              this.state.showDescriptionValidError=this.props.translator['Issue Description Cannot Be Empty']  
              this.state.showDescriptionError='error'
              count++
            }
        }
        else
        {
            this.state.showDescriptionValidError=this.props.translator['Issue Description Cannot Be Empty']  
            this.state.showDescriptionError='error'
            count++  
        }   
        
        if(values.additionalInfo.length!=0)
        {
            let count_size=0
            for(let i=0;i<values.additionalInfo.length;i++)
            {
                if(values.additionalInfo[i]==' ')
                   count_size=count_size+1
            } 
            if(values.additionalInfo.length==count_size)
            {
                // this.state.showaddInfoValidError=this.props.translator['Additional Information Cannot Be Empty']  
                // this.state.showadditionalInfoError='error'
                // count++
                values.additionalInfo=""
                this.setState({additionalInfo:""})
            }
        }
        // else
        // {
        //     this.state.showaddInfoValidError=this.props.translator['Additional Information Cannot Be Empty']  
        //     this.state.showadditionalInfoError='error'
        //     count++  
        // }

        if(count!=0)
        {
            this.setState({disableButton: false,validResult:true}) 
        }
        else
        {
           this.setState({disableButton: true})
           let ref=this
           let breakfixId;
           let breakfixNumber;

           const categorizationFields = Object.values(opsCategoryKeysMap).reduce((acc, key) => {
            if (key in this.props.breakFixEditDetails) {
              acc[key] = this.props.breakFixEditDetails[key];
            }
            return acc;
          }, {}) || {};

            let payload={
            ...categorizationFields,
            "serviceId": this.props.breakFixEditDetails.serviceBased?this.props.breakFixEditDetails.serviceId:0,
            "serviceCompanyName": this.props.breakFixEditDetails.serviceBased?this.props.breakFixEditDetails.serviceCompanyName:'',
            "serviceCompanyId": this.props.breakFixEditDetails.serviceBased?this.props.breakFixEditDetails.serviceCompanyId:'',
            "serviceCriticality": (this.state.saveConsumer.Vip=="yes" || this.state.saveConsumer.Vip=="Yes")?this.state.sortedImpactList.at(-2).field1Value:this.state.sortedImpactList.at(-1).field1Value,
            "impactTo": "S",
            "impactedUserid": this.state.saveConsumer.breakFixConsumerID, 
            "impactedUsername": this.state.saveConsumer.breakFixConsumerName,  
            "description": values.description,
            "reportedOn": "",
            "additionalInfo":values.additionalInfo,
            /*"spGroupid": serviceGroupNameId,
            "spGroupname": serviceGroupName,
            "spUserid": serviceGroupIndividualNameId,
            "spUsername": serviceGroupIndividualName,*/
            "spGroupid": 0,
            "spGroupname": "",
            "spUserid": 0,
            "spUsername": "0",
            "urgencyJustification": "0",
            "soGroupid": 0,
            "soUserid": 0,
            "status":"10",
            "breakfixNumber": this.props.breakFixEditDetails.isExternallyFulfilled ? this.props.translator["In Process"] : "",
            "priorityId" :this.state.priority,
            "priorityValue": this.state.priorityLabel,
            "criticalJustification": "0",
            "criticality":"No",
            "holdJustification": "0",
            "cause": 1,
            "slaStatus": "0",
            "expectedDate": "0",
            "createdOn": "",  
            "createdBy": this.props.profileDetails.userId, 
            "createdByName": this.props.profileDetails.username,
            "modifiedOn": "",   
            "modifiedBy": "",
            "reportedThrough": "55",
            "urgency" :(this.state.saveConsumer.Vip=="yes" || this.state.saveConsumer.Vip=="Yes" )?this.state.sortedUrgencyList[0].field1Value: values.urgencyMode,
            "serviceName": this.props.breakFixEditDetails.serviceName,
            "consumerCompany": this.props.breakFixEditDetails.consumerCompany,
            "consumerCompanyName": this.props.breakFixEditDetails.consumerCompanyName,
            // "ciId":ciId,
            // "ciName":ciName,
            // "ciCode":ciCode,
            "holingReasonCode":"0",
            "holdingReasonValue":"",
            "isExternallyFulfilled":this.props.breakFixEditDetails.isExternallyFulfilled,  
            "integrationPreferences":this.props.breakFixEditDetails.integrationPreferences,  
            "requesterLocationName": "",
            "requesterLocationId": "",
            "ciName":this.props.breakFixEditDetails.ciName,
            "ciId": this.props.breakFixEditDetails.ciId,
            "ciCode": this.props.breakFixEditDetails.ciCode,
            "serviceBased": this.props.breakFixEditDetails.serviceBased,

            "environment":this.props.breakFixEditDetails.environmentName,
			"ciConsumerCompany":this.props.breakFixEditDetails.ciConsumerCompany,
			"ciSupportCompany":this.props.breakFixEditDetails.ciSupportCompany,
			"ciClassId":this.props.breakFixEditDetails.ciClassId,
			"ciLocationId":this.props.breakFixEditDetails.ciLocationId,
			"ciLocationName":this.props.breakFixEditDetails.ciLocationName,
            "businessCriticalityId": this.state.oldBusinessCriticalityId,
			"businessCriticality": this.state.oldBusinessCriticality
           };
        //    if(this.props.breakFixEditDetails.serviceBased){
        //     payload.contextualRuleId=this.props.breakFixEditDetails.contextualRuleId;
        //    }
           axios.post(GLOBAL.breakFixCreateUrl, payload).then((response)=>{
            if (response.status == 201) {
           breakfixId = response.data.breakfixId;
           breakfixNumber=response.data.breakfixNumber;
           axios.post(GLOBAL.breakFixUrlCIDetailCopy,{
            "copyFromWorkItemId": this.props.breakFixEditDetails.breakfixId,                                  
            "copyToWorkItemId": breakfixId,                                      
            "workItemIdNumber":breakfixNumber ,            
            "module": "Breakfix",                                           
            "workItemReported":response.data.reportedOn,          
            "created_by":"",
            "supportCompanyId": this.props.breakFixEditDetails.supportCompanyId,
            "supportCompanyName": this.props.breakFixEditDetails.supportCompanyName                                             
        
           })
           .then((response)=>{
               console.log("successfully copied")
           }).catch((error) => {
        });
            if(GLOBAL.filesToBeSent.length>0){
                let formData = new FormData();
            	formData.append('file', GLOBAL.filesToBeSent[0][0]);
				let jsonObject = new Object;
				jsonObject.module = 'Breakfix';
			    jsonObject.itemId = breakfixId;
				jsonObject.createdOn = Math.round(new Date().getTime() / 1000.0);
				jsonObject.createdBy = "";
				formData.append("attachment", JSON.stringify(jsonObject));
                axios.post("/change/api/saveImplPlan", formData).then((response) => {
                    ref.setState({ResponseSuccess: true})
                }).catch((error) => {
                });	
            }
           }
           ref.setState({ResponseSuccess: true,ticketNumGenerated:response.data.displayNumber})
           }).catch(function (error) { 
              ref.setState({ResponseSuccess: false,disableButton:false})
           })
           
        }
    }
    showConsumerIcon(value){
        this.setState({consDetalIconshow: value});
    }
    
    render() {
        const tooltip = (
            <Popover className={this.state.saveConsumer.breakFixConsumerID!==''?'userInfoPopup':''}>
            <div className='serviceInfoPopup'>
            <XsmUserDetails isHeadingVisible={false} userId={this.state.saveConsumer.breakFixConsumerID} userDetailsLabel={this.state.saveConsumer.breakFixConsumerName} translator={this.props.tr}/>
            </div>
            </Popover>
        );
        const imptCI = (
            <Popover className="userInfoPopup">
            <div className='serviceInfoPopup'>
            <CIDEtails ciId={this.props.breakFixEditDetails.ciId} translator={this.props.tr}/>
            </div>
            </Popover>
        );
        const impServices = (
            <Popover className="userInfoPopup">
            <div className='serviceInfoPopup'>
            <ServiceDetails offeringId={this.props.breakFixEditDetails.serviceId} translator={this.props.tr}/>
            </div>
            </Popover>
        );
       

        const {handleSubmit} = this.props;
        if(!this.state.urgencyListRecived){
            return <ListLoader />;
        }else if(this.state.ResponseSuccess==true)
        {
            return (
                 		<div><font color="green"><b>{this.props.translator["Incident created, reference number is "]+this.state.ticketNumGenerated}</b></font></div>
         	); 
        }
        else
     return (
           <div>
                <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                    <ul>
                        <li>
                        <button
                            type='button'
                            title="Minimize the right panel"
                            bsClass=""
                            bsStyle=""
                            className="closerightPanelBtn"
                            onClick={() => {
                            this.props.rightEditPanel(false);
                            this.props.changeRightView("allClose");
                            }}
                        >
                            <IoClose />
                        </button>
                        </li>
                    </ul>
                </div>
               <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Copy Incident Service']}</div></div>
               
               <div className='rBoxGap'>
               <div>
               <Form.Group className="form-group">
               <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator["Incident to be copied"]}</Form.Label>
               <Field name="requestNumber" className="form-control" type="text"  component="input"  disabled="true" onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}></Field>
               </ Form.Group>  
               <Form.Group className="form-group">
               <Form.Label bsClass=""><span className="rStar"></span>{this.props.breakFixEditDetails.serviceBased?this.props.translator["Impacted Service"]:this.props.translator["Impacted CI"]}</Form.Label>
               <div className='position-re infoBtnDiv'>
               <Field name="serviceName" className="form-control"  type="text" component="input" disabled="true" />
               <OverlayTrigger trigger="click" rootClose placement={this.props.windowSize.width < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
} overlay={this.props.breakFixEditDetails.serviceBased?impServices:imptCI}><span className="infoBtnDiv infoicn"><ImInfo/></span></OverlayTrigger>
               </div></ Form.Group>  
               {this.props.breakFixEditDetails.serviceBased&&this.props.breakFixEditDetails.ciName!=null?
               <Form.Group className="form-group">
               <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator["Impacted CI"]}</Form.Label>
               <div className='position-re infoBtnDiv'>
               <Field name="ciName" className="form-control"  type="text" component="input" disabled="true" />
               <OverlayTrigger trigger="click" rootClose placement={this.props.windowSize.width < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
} overlay={imptCI}><span className="infoBtnDiv infoicn"><ImInfo/></span></OverlayTrigger>
               </div>
               </ Form.Group>:null
               }
               <Form.Group className="form-group" 
            //    validationState={this.state.showConsumerError} 
               onChange={(e)=>this.changeConsumer(e)}>
               <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator["Consumer"]}{(this.state.saveConsumer.Vip==='yes' || this.state.saveConsumer.Vip=="Yes")?<i className="vipIcn fa fa-diamond" aria-hidden="true" title={this.props.translator['VIP User']}></i>:''}</Form.Label>
               {this.props.disableFieldsForGuestRole?
               <Field name="consumer" className="form-control"  type="text" component="input" disabled="true" onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}} />
               :<div className='position-re infoBtnDiv'>               
               <Field name="consumer" component={RFReactConsumer}  
               companyId={this.props.breakFixEditDetails.consumerCompany}
               setErrorColor = {this.setErrorColor}
               afterConsumerSelected = {this.afterConsumerSelected}
               setshowVIPIcon = {this.setshowVIPIcon}
               showConsumerInfoIcon={this.showConsumerIcon}
               className="form-control" 
               errorColor={this.state.showConsumerError}
               onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}
               />
             {this.state.consDetalIconshow?<OverlayTrigger trigger="click" rootClose placement={this.props.windowSize.width < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
} overlay={tooltip}><span className="infoBtnDiv infoicn"><ImInfo/></span></OverlayTrigger>:null}
              </div>
              
             }
                </ Form.Group>
             

               {this.state.showConsumerValidError!='' && this.state.validResult ? <div><font color="red" ><b>{this.state.showConsumerValidError}</b></font></div>: null}
               <Form.Group className="form-group" 
            //    validationState={this.state.showDescriptionError}
               >
               <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator["Issue Description"]} </Form.Label>
               <Field name="description" component="textarea" className={"form-control " + (this.state.showDescriptionError)} maxLength="2000" rows="2" onChange={(e)=>{this.onDescriptionChange(e)}} readOnly={this.props.disableFieldsForGuestRole}/>
               <p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {this.state.describeIssue.length}/2000)</p>
               </Form.Group>
               {this.state.showDescriptionValidError!='' && this.state.validResult ? <div><font color="red" ><b>{this.state.showDescriptionValidError}</b></font></div>: null} 
               <Form.Group className="form-group" 
            //    validationState={this.state.showadditionalInfoError}
               >
               <Form.Label bsClass="">{this.props.translator["Additional Information"]} </Form.Label>
               <Field name="additionalInfo" component="textarea" className={"form-control " + (this.state.showadditionalInfoError)} maxLength="3500" rows="2" onChange={(e)=>{this.onAdditionalInfoChange(e)}} readOnly={this.props.disableFieldsForGuestRole}/>
               <p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {this.state.additionalInfo.length}/3500)</p>
               </Form.Group>
               {this.state.showaddInfoValidError!='' && this.state.validResult ? <div><font color="red" ><b>{this.state.showaddInfoValidError}</b></font></div>: null}
               <Form.Group className="form-group">
				<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Urgency']} </Form.Label>
								<Field component="select" name="urgencyMode"  type="select" className="form-control"  onChange={(e)=>{this.setState({urgencyID: e.target.value});this.getUrgency((this.state.saveConsumer.Vip==="yes" || this.state.saveConsumer.Vip=="Yes")?this.state.sortedUrgencyList[0].field1Value:e.target.value,(this.state.saveConsumer.Vip==="yes" || this.state.saveConsumer.Vip=="Yes")?this.state.sortedImpactList.at(-2).field1Value:this.state.sortedImpactList.at(-1).field1Value)}} disabled={this.props.disableFieldsForGuestRole}>
                                {this.renderUrgencyName(this.state.urgencyList)}
								</Field>
			    </Form.Group>
                {this.props.disableFieldsForGuestRole?'':
                <Form.Label bsClass="">{this.props.translator['Attachments']} <span className='f-size-13'>{this.props.translator['(max size']+' '+attachmentSize+' '+this.props.translator['MB)']}</span></Form.Label>}
                {this.props.disableFieldsForGuestRole?'':<UploadScreen translator={this.props.translator}/>} 
                <ButtonToolbar className="margin-t-15">
                     <Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary" disabled={this.state.disableButton || this.props.disableFieldsForGuestRole} onClick={handleSubmit(this.postData)}>{this.state.disableButton ? <ImSpinner6 className="icn-spinner"/> : null }{this.props.translator['Submit']}</Button>
                     <Button className="rgSiLigBtn smallBtn" bsSize="small" bsStyle="primary" disabled={this.state.disableButton || this.props.disableFieldsForGuestRole} onClick={()=>this.props.setShowCopyServiceForm(0)}>{this.props.translator['Cancel']}</Button>
                </ButtonToolbar>
            </div>   

            </div>
            </div>   
     )
    }
}

export function mapStateToProps({breakFixEditDetails,profileDetails,fielStatus, windowSize}){
    return {
        initialValues: {
            'requestNumber':breakFixEditDetails.requestNumber+'-'+breakFixEditDetails.breakfixNumber,
            'serviceName': breakFixEditDetails.serviceBased?breakFixEditDetails.serviceName:breakFixEditDetails.ciName,
            'ciName':breakFixEditDetails.ciName,
            'description':breakFixEditDetails.description=='null'||breakFixEditDetails.description==null?'':breakFixEditDetails.description,
            'additionalInfo':breakFixEditDetails.additionalInfo=='null' || breakFixEditDetails.additionalInfo==null?'':breakFixEditDetails.additionalInfo,
            'urgencyMode':breakFixEditDetails.urgency
        },
        breakFixEditDetails,profileDetails,fielStatus, windowSize}
}

BreakFixCopy = reduxForm({
	form: 'CopyABreakFixService'
})(BreakFixCopy);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ loadProfile2 }, dispatch);
}
export default connect(mapStateToProps,mapDispatchToProps)(BreakFixCopy);