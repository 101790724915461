import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectField } from "../../common/forms/FormFields";
import { Button, ButtonToolbar, Col, Form, Row } from "react-bootstrap";
import {
  loadCIMenulistSubStatus,
  loadCIMenulistStatus,
} from "../../../actions/cmdb/lovAction";
import { Formik, Form as FormikForm } from "formik";
import AssignedToTypeHead from "../add-edit/formfield/AssignedToAsyncTypeahead";
import { useParams } from "react-router";
import { TypeaheadSelect } from "../common/helper";

import {
  useAllGroup,
  useDepartmentOptions,
  useFetchGroupUsers,
  useLocationData,
} from "../add-edit/formfield/hooks";
import { ImSpinner6 } from "react-icons/im";
import axios from "axios";
import Swal from "sweetalert2";

function validate(values) {
  const errors = {};

  if (!values.status) {
    errors.status = "Status is required";
  }

  if (!values.sub_status) {
    errors.sub_status = "Sub Status is required";
  }

  return errors;
}

const CmdbBulkUpdate = (props) => {
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const statusOptions = useSelector((state) => state?.ciStatus);
  const subStatusOptions = useSelector((state) => state?.ciSubStatus);
  const [locationToggle, setLocationToggle] = useState("all");
  const [formValues, setFormValues] = useState({});
  const [type, setType] = useState("location");
  const params = useParams();
  const ciId = params?.ciId;
  const isAddMode = !ciId;
  // const { setFieldValue, values } = props;
  const formikRef = useRef(null);
  const { locationOptions } = useLocationData(props.COMPANY_ID, locationToggle);
  const departmentOptions = useDepartmentOptions(props.COMPANY_ID);
  const allGroupOptions = useAllGroup(props.COMPANY_ID);
  const { groupUsers, fetchGroupUsers } = useFetchGroupUsers();

  const [initVals, setInitVals] = useState({
    status: "",
    sub_status: "",
    owner_id: "0",
    owner_name: "",
    owner_email: "",
    assigned_user: [],
    location_id: "0",
    location_name: "",
    department_id: "0",
    department_name: "",
    business_owner_group_id: "0",
    business_owner_id: "0",
    business_owner_name: "",
    business_owner_group_name: "",
    technical_owner_group_id: "0",
    technical_owner_id: "0",
    technical_owner_name: "",
    technical_owner_group_name: "",
  });
  const initialValues = {
    ...initVals,
  };

  useEffect(() => {
    const statusId = initVals.status;
    if (statusId) {
      dispatch(loadCIMenulistSubStatus("SubStatus List", statusId));
    }
  }, [initVals.status]);

  useEffect(() => {
    dispatch(loadCIMenulistStatus("Status List"));
  }, []);

  useEffect(() => {
    if (initVals.business_owner_group_id) {
      fetchGroupUsers(initVals.business_owner_group_id);
    }
    if (initVals?.technical_owner_group_id) {
      fetchGroupUsers(initVals.technical_owner_group_id);
    }
  }, [
    initVals?.business_owner_group_id,
    initVals?.technical_owner_group_id,
    fetchGroupUsers,
  ]);

  const submitForm = async (values, { resetForm }) => {
    console.log("values", values);

    values = Object.fromEntries(
      Object.entries(values).filter(([key, value]) => {
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return value !== null && value !== "" && value !== undefined;
      })
    );

    let postBulkData = {
      status: values.status,
      sub_status: values.sub_status,
      business_owner_group_id: values.business_owner_group_id,
      business_owner_id: values.business_owner_id,
      business_owner_name: values.business_owner_name,
      technical_owner_group_id: values.technical_owner_group_id,
      technical_owner_id: values.technical_owner_id,
      technical_owner_name: values.technical_owner_name,
      owner_id: values.owner_id,
      owner_name: values.owner_name,
      owner_email: values.owner_email,
      assigned_user: values.assigned_user?.map((user) => ({
        assigned_user_email: user.email,
        assigned_user_id: user.userId,
        assigned_user_name: user.fullname,
      })),
      department_id: values.department_id,
      department_name: values.department_name,
      business_owner_group_name: values.business_owner_group_name,
      technical_owner_group_name: values.technical_owner_group_name,
    };

    console.log("postBulkData", postBulkData);

    try {
      const selectedCiIds =
        props.selectedCiIds && props.selectedCiIds.length > 0
          ? props.selectedCiIds
          : [];

      if (selectedCiIds.length === 0) {
        Swal.fire({
          text: "No CI selected for bulk update.",
          confirmButtonText: "OK",
        });
        return;
      }

      const url = `/api/config_items/multiciupdate/${selectedCiIds.join(",")}`;
      const response = await axios.patch(url, postBulkData);

      if (response.status === 200) {
        Swal.fire({
          text: response.data.message || "Bulk update successful",
          confirmButtonText: "OK",
        }).then(() => {
          resetForm();
          props.resetSelection();
          props.showRightSide(false);
          props.refreshTable();
        });
      } else {
        console.error("Bulk update failed", response);
      }
    } catch (error) {
      console.error("Error during bulk update", error);
    }
  };

  return (
    <div className="">
      <Row>
        <Col xs={12}>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {tr["Bulk Update"]}
            </div>
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={submitForm}
          // innerRef={formikRef}
          enableReinitialize={true}
          validationSchema={false}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            values ? setInitVals(values) : setInitVals('');
            console.log(values);

            return (
              <FormikForm>
                <Row>
                  <SelectField
                    name="status"
                    label="Status"
                    required
                    onChange={(e) => {
                      const selectedOption = e.target["selectedIndex"];
                      const nameText =
                        e.target.options[selectedOption]?.dataset?.name;

                      setFieldValue("status_name", nameText);
                      setFieldValue("status", e.target.value);
                      setFieldValue("sub_status", "");
                      setFieldValue("sub_status_name", "");
                    }}
                  >
                    {statusOptions?.map((option) => (
                      <option
                        key={option.ID}
                        value={option?.ID}
                        data-name={option.STATUS_NAME}
                      >
                        {option.STATUS_NAME}
                      </option>
                    ))}
                  </SelectField>
                  {errors.status && touched.status && (
                    <div className="error-message validateErrorMessage">
                      {errors.status}
                    </div>
                  )}
                </Row>
                <Row>
                  <SelectField
                    name="sub_status"
                    label="Sub Status"
                    required
                    onChange={(e) => {
                      const selectedOption = e.target["selectedIndex"];
                      const nameText =
                        e.target.options[selectedOption].dataset?.name;
                      setFieldValue("sub_status", e.target.value);
                      setFieldValue("sub_status_name", nameText);
                    }}
                  >
                    {subStatusOptions?.map((option) => (
                      <option
                        key={`sub_key-${option?.ID}`}
                        value={option?.ID}
                        data-name={option?.SUB_STATUS_NAME}
                      >
                        {option?.SUB_STATUS_NAME}
                      </option>
                    ))}
                  </SelectField>
                  {errors.sub_status && touched.sub_status && (
                    <div className="error-message validateErrorMessage">
                      {errors.sub_status}
                    </div>
                  )}
                </Row>
                <Row>
                  <AssignedToTypeHead
                    clearButton
                    id="owner_id"
                    name="owner_name"
                    label={tr["Assigned To"]}
                    thfor="personApi"
                    placeholder="Please type to search"
                    companyId={props.COMPANY_ID}
                    labelkey="fullname"
                    onChange={(selected) => {
                      // Handle both selection and deselection
                      if (selected && selected.length > 0) {
                        const selectedUser = selected[0];
                        setFieldValue("owner_id", selectedUser?.userId || "0");
                        setFieldValue(
                          "owner_name",
                          selectedUser?.fullname?.trim() || ""
                        );
                        setFieldValue("owner_email", selectedUser?.email || "");
                      } else {
                        // Reset values when cleared
                        setFieldValue("owner_id", "0");
                        setFieldValue("owner_name", "");
                        setFieldValue("owner_email", "");
                      }
                    }}
                    selected={values.owner_name ? [values.owner_name] : []}
                  />
                </Row>
                <Row>
                  <AssignedToTypeHead
                    multiple
                    clearButton
                    id="other_users_id"
                    name="assigned_user"
                    label="Other Users"
                    thfor="personApi"
                    placeholder="Please type to search"
                    companyId={props.COMPANY_ID}
                    autoComplete="off"
                    labelkey={
                      isAddMode
                        ? "fullname"
                        : (option) =>
                            option?.fullname ? `${option?.fullname}` : option
                    }
                    onChange={(selected) => {
                      setFieldValue("assigned_user", selected);
                    }}
                    selected={
                      values?.assigned_user?.map((user) => ({
                        ...user,
                        fullname: user?.fullname || user,
                      })) || []
                    }
                  />
                </Row>
                <Row>
                  <Form.Group className="form-group">
                    <a
                      className="showwAllGrps margin-t-5 float-r"
                      href="javascript:void(0)"
                      onClick={() => {
                        let newFlag =
                          locationToggle == "all" ? "stockroom" : "all";
                        setLocationToggle(newFlag);
                        //type: "drLocation",
                        setType(
                          locationToggle == "all" ? "location" : "stockroom"
                        );
                      }}
                    >
                      {locationToggle == "all"
                        ? "Show Stockroom Locations"
                        : "Show All Locations"}
                    </a>
                    <Form.Label bsClass="">{tr["Location"]}</Form.Label>
                    <div className="dvTypehd">
                      <TypeaheadSelect
                        onSelection={(val) => {
                          setFieldValue("location_id", val[0]?.id?.toString());
                          setFieldValue("location_name", val[0]?.label);
                        }}
                        id="location_id"
                        name="location_name"
                        className="form-control"
                        options={locationOptions || []}
                        selectedOptions={
                          values?.location_name
                            ? [
                                {
                                  id: values.location_id,
                                  label: values.location_name,
                                },
                              ]
                            : []
                        }
                        tr={tr}
                        typedValue=""
                        onCrossClick={() => {
                          setFieldValue("location_id", "0");
                          setFieldValue("location_name", "");
                        }}
                      />
                    </div>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">Department</Form.Label>
                    <div className="dvTypehd">
                      <TypeaheadSelect
                        id="department-field"
                        name="department"
                        className="form-control"
                        options={departmentOptions || []}
                        // onInputChange={(e) => {

                        // }}
                        onCrossClick={() => {
                          setFieldValue("department_id", null);
                          setFieldValue("department_name", null);
                        }}
                        onSelection={(val) => {
                          setFieldValue("department_id", val[0]?.id);
                          setFieldValue("department_name", val[0]?.label);
                        }}
                        selectedOptions={
                          values?.department_name
                            ? [
                                {
                                  id: values.department_id,
                                  label: values.department_name,
                                },
                              ]
                            : []
                        }
                        tr={tr}
                      />
                    </div>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">Business Owner Group</Form.Label>
                    <div className="dvTypehd">
                      <TypeaheadSelect
                        onSelection={(val) => {
                          setFieldValue("business_owner_id", "0");
                          setFieldValue("business_owner_name", "");
                          setFieldValue("business_owner_group_id", val[0]?.id);
                          setFieldValue(
                            "business_owner_group_name",
                            val[0]?.label
                          );
                        }}
                        id="business_owner_group_name-field"
                        name="business_owner_group_name"
                        className="form-control"
                        options={allGroupOptions || []}
                        selectedOptions={
                          values?.business_owner_group_name
                            ? [
                                {
                                  id: values.business_owner_group_id,
                                  label: values.business_owner_group_name,
                                },
                              ]
                            : []
                        }
                        onCrossClick={() => {
                          setFieldValue("business_owner_group_id", "");
                          setFieldValue("business_owner_group_name", "");
                          setFieldValue("business_owner_id", "0");
                          setFieldValue("business_owner_name", "");
                        }}
                        tr={tr}
                      />
                    </div>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">Business Owner</Form.Label>
                    <div className="dvTypehd">
                      <TypeaheadSelect
                        onSelection={(val) => {
                          setFieldValue("business_owner_id", val[0]?.id);
                          setFieldValue("business_owner_name", val[0]?.label);
                        }}
                        id="business_owner_name-field"
                        name="business_owner_name"
                        className="form-control"
                        options={
                          groupUsers[values?.business_owner_group_id] || []
                        }
                        selectedOptions={
                          values?.business_owner_name
                            ? [
                                {
                                  id: values.business_owner_id || 0,
                                  label: values.business_owner_name,
                                },
                              ]
                            : []
                        }
                        onCrossClick={() => {
                          setFieldValue("business_owner_id", 0);
                          setFieldValue("business_owner_name", "");
                        }}
                        tr={tr}
                      />
                    </div>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">Technical Owner Group</Form.Label>
                    <div className="dvTypehd">
                      <TypeaheadSelect
                        onSelection={(val) => {
                          setFieldValue("technical_owner_id", 0);
                          setFieldValue("technical_owner_name", "");
                          setFieldValue("technical_owner_group_id", val[0]?.id);
                          setFieldValue(
                            "technical_owner_group_name",
                            val[0]?.label
                          );
                        }}
                        id="technical_owner_group_name-field"
                        name="technical_owner_group_name"
                        className="form-control"
                        options={allGroupOptions || []}
                        selectedOptions={
                          values?.technical_owner_group_name
                            ? [
                                {
                                  id: values.technical_owner_group_id,
                                  label: values.technical_owner_group_name,
                                },
                              ]
                            : []
                        }
                        onCrossClick={() => {
                          setFieldValue("technical_owner_group_id", "");
                          setFieldValue("technical_owner_group_name", "");
                          setFieldValue("technical_owner_id", 0);
                          setFieldValue("technical_owner_name", "");
                        }}
                        tr={tr}
                      />
                    </div>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group className="form-group">
                    <Form.Label bsClass="">{tr["Technical Owner"]}</Form.Label>
                    <div className="dvTypehd">
                      <TypeaheadSelect
                        onSelection={(val) => {
                          setFieldValue("technical_owner_id", val[0]?.id);
                          setFieldValue("technical_owner_name", val[0]?.label);
                        }}
                        id="technical_owner_name-field"
                        name="technical_owner_name"
                        className="form-control"
                        options={
                          groupUsers[values?.technical_owner_group_id] || []
                        }
                        selectedOptions={
                          values?.technical_owner_name
                            ? [
                                {
                                  id: values.technical_owner_id || 0,
                                  label: values.technical_owner_name,
                                },
                              ]
                            : []
                        }
                        onCrossClick={() => {
                          setFieldValue("technical_owner_id", 0);
                          setFieldValue("technical_owner_name", "");
                        }}
                        tr={tr}
                      />
                    </div>
                  </Form.Group>
                </Row>
                <ButtonToolbar className="black margin-t-15">
                  <Button
                    className="rgSidrkBtn"
                    bsSize="small"
                    bsStyle="primary"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {isSubmitting && <ImSpinner6 className="icn-spinner" />}{" "}
                    {tr["Save"]}
                  </Button>
                  <Button
                    className="rgSidrkBtn"
                    bsSize="small"
                    bsStyle="secondary"
                    onClick={() => {
                      props.showRightSide(false);
                      props.resetSelection();
                    }}
                  >
                    Cancel
                  </Button>
                </ButtonToolbar>
              </FormikForm>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default CmdbBulkUpdate;
