
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const myInputComponent = (e) => {
  e.DomComponents.addType("input", {
    // Make the editor understand when to bind `my-input-type`
    isComponent: (el) => el.tagName === "INPUT",
    // Model definition
    model: {
      // Default properties
      defaults: {
        traits: [
          "name",
          "placeholder",
          {
            type: "text",
            label: "Default Value",
            name: "defaultValue",
          },
          {
            type: "select",

            name: "type",
            label: "Type",
            options: [
              { id: "text", label: "Text" },
              { id: "textArea", label: "Textarea" },
              { id: "email", label: "Email" },
              { id: "password", label: "Password" },
              { id: "number", label: "Number" },
              { id: "colorPicker", label: "Color picker" },
              { id: "textEditor", label: "Editor" },
            ],
          },
          {
            type: "checkbox",
            name: "required",
          },
          {
            type: "number",
            label: "Min Length",
            name: "minLength",
          },
          {
            type: "number",
            label: "Max Length",
            name: "maxLength",
          },
          {
            type: "text",
            label: "Image Field Name",
            name: "imgName",
          },
          {
            type: "select",
            label: "Data Source",
            name: "data_source",
            options: [],
          },
          {
            type: "checkbox",
            label: "Disabled",
            name: "disabled",
          },
          {
            type: "checkbox",
            label: "Show Eye Icon",
            name: "showEyeIcon",
          },
        ],
        attributes: { type: "text", required: true, class: "form-control" },
      },
    },
  });
};
