import * as React from 'react';
import { EditorUtils,Editor, EditorTools, ProseMirror } from '@progress/kendo-react-editor';
import { insertImagePlugin } from './insertImagePlugin.js';
import { insertImageFiles } from './utils';
import InsertImage from './insertImageTool';
import { useQuery } from '@apollo/client';
import { GetDataQuery } from '../../../studio/connectors/graphQl.js';
import { transformFunction, updateDataQueryValues, validateRestRequest } from '../../../studio/connectors/utils.js';
import { useSelector } from 'react-redux';
const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
} = EditorTools;
export const EditorBox = (props) => {
  const {
    attributes,
    language,
    onValueChange,
    defaultValues,
    hiddenFIelds,
    readOnly,
    errors,
    nonEditable,
    className,
    id,
  } = props;
  const onImageInsert = (args) => {
    const { files, view, event } = args;
    const nodeType = view.state.schema.nodes.image;
    const position =
      event.type === "drop"
        ? view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          })
        : null;
    const dataQ = callBack();
    if (files.length > 0) {
      fetchOptions(view, files, nodeType, position);
    }
    // insertImageFiles({
    //   view,
    //   files,
    //   nodeType,
    //   position
    // });
    return files.length > 0;
  };
  const onMount = (event) => {
    const state = event.viewProps.state;
    const plugins = [...state.plugins, insertImagePlugin(onImageInsert)];
    return new ProseMirror.EditorView(
      {
        mount: event.dom,
      },
      {
        ...event.viewProps,
        state: ProseMirror.EditorState.create({
          doc: state.doc,
          plugins,
        }),
      }
    );
  };
  const hosts = useSelector((state) => state?.hosts);
  const [dataSourceId, setDataSourceId] = React.useState(null);
  const [imageUploading, setImageUploading] = React.useState(false);
  const { data: dataQuery, refetch: fetchDataQuery } = useQuery(GetDataQuery, {
    variables: { id: dataSourceId },
    skip: dataSourceId == null,
  });

  React.useEffect(() => {
    if (attributes?.data_source) {
      setDataSourceId(attributes.data_source.id);
      fetchDataQuery();
    }
  }, []);

  const callBack = () => {
    return dataQuery;
  };
  const fetchOptions = (view, file, nodeType, position) => {
    setImageUploading(true);
    const data = JSON.parse(dataQuery.dataQuery.data);
    data.body.value = data.dependent
      ? updateDataQueryValues(data.body.value, data.dependent, {
          [attributes?.imgName]: file[0],
          ...defaultValues,
        })
      : data.body.value;
    if (data?.body?.variables) {
      data.body.variables = data.dependent
        ? updateDataQueryValues(data.body.variables, data.dependent, {
            [attributes?.imgName]: file[0],
            ...defaultValues,
          })
        : data.body.variables;
    }
    const restOptions = {
      url: dataQuery.dataQuery.connector.url,
      host:
        dataQuery.dataQuery.connector?.module == "other"
          ? ""
          : hosts.hasOwnProperty(dataQuery.dataQuery.connector?.module)
          ? hosts[dataQuery.dataQuery.connector?.module]
          : "",
      verb: dataQuery.dataQuery.connector.verb,
      headers: data.dependent
        ? updateDataQueryValues(data.headers, data.dependent, {
            [attributes?.imgName]: file[0],
            ...props?.defaultValues,
          })
        : data.headers,
      queries: data.dependent
        ? updateDataQueryValues(data.query_string, data.dependent, {
            [attributes?.imgName]: file[0],
            ...props?.defaultValues,
          })
        : data.query_string,
      body: data.body,
    };
    validateRestRequest(restOptions)
      .then((response) => {
        if (data?.transform_response) {
          response.data = transformFunction(
            data?.transform_response,
            response?.data
          );
        }
        setImageUploading(false);
        const attr = { src: response?.data[attributes?.name] };
        insertImageFiles({
          view,
          files:file,
          nodeType,
          position,
          attrs:attr,
        });
      })
      .catch((error) => {
        setImageUploading(false);
        console.log(error?.message);
      });
  };

  if (attributes.data_source.id && !dataQuery) {
    return null;
  } else {
    return (
      <Editor
        tools={[
          [Bold, Italic, Underline, Strikethrough],
          [Subscript, Superscript],
          [AlignLeft, AlignCenter, AlignRight, AlignJustify],
          [Indent, Outdent],
          [OrderedList, UnorderedList],
          FontSize,
          FontName,
          FormatBlock,
          [Undo, Redo],
          [Link, Unlink],
          [
            (e) =>
              InsertImage({
                ...e,
                item: attributes,
                defaultValues: defaultValues,
              }),
          ],
          [InsertTable],
          [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
          [DeleteRow, DeleteColumn, DeleteTable],
        ]}
        contentStyle={{ height: 300 }}
        onMount={onMount}
        defaultContent={
          defaultValues && defaultValues.hasOwnProperty(attributes?.name)
            ? defaultValues[attributes?.name]
            : ""
        }
        type="file"
        onChange={(e) => {
          onValueChange(e.html);
        }}
        name={attributes.name}
        className={`${className} ${
          errors && errors[attributes?.name] && "error"
        }`}
        id={id}
        attributes={attributes}
        disabled={attributes?.disabled || nonEditable || readOnly || imageUploading}
      />
    );
  }
};