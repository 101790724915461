
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { OverlayTrigger, Popover, Table, Tooltip } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import { GLOBAL } from "../../../Globals";
import axios from "axios";
import { TypeaheadandDropdown } from "../../formFields";
import Cookies from "universal-cookie";
import { fetchWatcherDetails } from "../../../../actions/fulfillment/watcherActions";
import ListLoader from "../../loaders/ListLoader";
import { Link } from "react-router-dom";
import useDebounce from "../../../../helpers/useDebounce";
const disabledStatus = ["Completed", "Cancelled", "Rejected", "Closed"];
import XsmUserDetails from "_Commons/WorkItemBoard/xsmEditRequestedBy.js";
import {IoClose} from "react-icons/io5";
import { fetchTmsWatcherDetails } from "../../../../actions/task/TaskDataAction";
import {ImInfo} from "react-icons/im";

/* cookies use for getting company id */

const cookie = new Cookies();
const gph = cookie.get("gph").split("~");
const companyId = typeof gph[48] !== "undefined" ? gph[48] : "";
const currentUserEmail = typeof gph["29"] !== "undefined" ? gph["29"] : "";
const lang = typeof gph["29"] !== "undefined" ? gph["20"] : "en";

let cookiesArray = cookie.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];

// email validations

const validateEmail = (email) => {
  let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;
  return email.match(mailformat);
};

const getDuplicateRemovedWatchers = (watchers) => {
  let newWatchers = [];
  let uniqueWatchers = {};
  for (let i in watchers) {
    if (watchers.hasOwnProperty(i)) {
      let email = watchers[i]["watcherEmailid"];
      uniqueWatchers[email] = watchers[i];
    }
  }
  for (let i in uniqueWatchers) {
    if (uniqueWatchers.hasOwnProperty(i)) {
    newWatchers.push(uniqueWatchers[i]);
    }
  }
  return newWatchers;
};

const WatcherDetails = (props) => {
  const translator = useSelector((state) => state.spcmReducer.tr);
  const dispatch = useDispatch();
  const watcherLoader = useSelector((state) => state.watcherLoader);
  const watcherData = useSelector((state) => state.watcherDetailsFetched);
  const [holdErrorMessage, setHoldErrorMessage] = useState("");
  const [currentMethod, setCurrentMethod] = useState("watcherName");
  const [consumerDataDetails, setConsumerDataDetails] = useState([]);
  const [selectedConsumers, setSelectedConsumers] = useState([]);
  const [valueError, setValueError] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [emailIds, setEmailIds] = useState("");
  const [typedChar, setTypedChar] = useState("");
  const windowSize = useSelector((state) => state.windowSize.width);

  useEffect(() => {
    if(props.module ==="Task"){
      dispatch(fetchTmsWatcherDetails(props.module, props.itemId));
    }
    else{
      dispatch(fetchWatcherDetails(props.module, props.itemId));
    }
  }, []);

  const onValueChange = (value) => {
    onWatcherChange(value);
  };

  const consumerDetailsApi = useDebounce(() => {
    let value = typedChar;
    let consumerDataDetails = [],
      consumerData = [],
      myObj = {},
      strObj = {};
    strObj.like = value;
    strObj.companyId = companyId;
    strObj = JSON.stringify(strObj);
    if (value !== "" && value.length > 3) {
      axios
        .get(GLOBAL.consumerUsersNewUrl, { headers: { query: strObj } })
        .then((response) => {
          consumerData = response.data;
          if (Array.isArray(response.data)) {
            for (const element of consumerData) {
              myObj = { value: "", label: "" };
              myObj.id = element.userId;
              myObj.label = element.fullname;
              myObj.name = element.fullname;
              myObj.email = element.email;
              consumerDataDetails.push(myObj);
            }
            consumerDataDetails = Array.from(new Set(consumerDataDetails));
          } else {
            consumerDataDetails = [];
          }
          setConsumerDataDetails(consumerDataDetails);
        });
    }
  });

  const showHideFields = (e) => {
    if (e.target.checked) {
      setSelectedConsumers([]);
      setValueError("");
      setCurrentMethod(e.target.value);
    } else {
      setEmailIds("");
      setHoldErrorMessage("");
      setCurrentMethod("watcherName");
    }
  };

  /// watcher list api call
  const onWatcherChange = (value) => {
    setTypedChar(value);
    if (value.length > 3) {
      consumerDetailsApi();
    } else {
      setConsumerDataDetails([]);
    }
  };

  /// on consumer name selection fn
  const onValueSelection = (selected) => {
    if (!(Array.isArray(selected) && selected.length)) {
      setValueError("error");
      setConsumerDataDetails([]);
    } else {
      setValueError("");
    }
    setSelectedConsumers(selected);
  };

  // on input changes

  const onChange = (e) => {
    if (e.target.name == "emailIds") {
      setEmailIds(e.target.value);
    }
    if (emailIds !== "") {
      setHoldErrorMessage("");
    }
  };

  // on save button click func

  const saveData = (type) => {
    let watcherDetails = [];

    if (currentMethod === "watcherName" && type !== "addMe") {
      if (selectedConsumers.length === 0) {
        setValueError("error");
        return;
      }
      selectedConsumers.forEach((consumer) => {
        watcherDetails.push({
          watcherName: consumer.name,
          watcherEmailid: consumer.email,
          watcherUserId: consumer.id,
        });
      });
    } else if (currentMethod === "watcherEmails" && type !== "addMe") {
      if (emailIds === "") {
        setHoldErrorMessage(translator["Please enter the required data..."]);
        return;
      }
      let customEmails = emailIds.split(",");
      let invalidEmails = 0;
      customEmails.forEach((email) => {
        if (!validateEmail(email.trim())) {
          invalidEmails++;
        }
        watcherDetails.push({
          watcherName: "Custom",
          watcherEmailid: email.trim(),
          watcherUserId: "",
        });
      });
      if (invalidEmails > 0) {
        setHoldErrorMessage(translator["Please enter valid email ids"]);
        return;
      }
      watcherDetails = getDuplicateRemovedWatchers(watcherDetails);
    }
    /// do error empty
    setValueError("");
    setHoldErrorMessage("");
    if (type === "addMe") {
      watcherDetails.push({
        watcherName: gph["42"],
        watcherEmailid: currentUserEmail,
        watcherUserId: gph["43"],
      });
    }
    /// check if watcher details does not exists
    if (watcherDetails.length === 0) {
      return;
    }

    //// validations for existing emails
    let existingEmails = [];
    let emailList =
      watcherData.length !== 0
        ? watcherData.map((data) => {
            return data.watcherEmailid;
          })
        : [];

    watcherDetails.forEach((data) => {
      if (emailList.includes(data.watcherEmailid)) {
        return existingEmails.push(data.watcherEmailid);
      }
    });

    if (existingEmails.length > 0) {
      Swal.fire({
        text: translator["Some email ids are already in the list"],
        button: translator["OK"],
      });
      return;
    }

    let isExternal = false;
    if (currentMethod === "watcherEmails" && type!=="addMe") {
      isExternal = true;
    }

    const postData = {
      module: props.module,
      itemId: props.itemId,
      companyId: companyId,
      isExternal: isExternal,
      watcherSet: watcherDetails,
    };

    setDisableBtn(true);
    if (props.module === "Task") {
      delete postData['itemId'];
      axios
        .post('/api/watchers', {taskId:props.itemId,...postData,taskCode:props.taskCode})
        .then((watcherData) => {
          if (!watcherData) {
            throw Error("Watcher details not found.");
          }
          return watcherData;
        })
        .then((watcherData) => {
          setSelectedConsumers([]);
          setEmailIds("");
          setDisableBtn(false);
          setConsumerDataDetails([]);
          dispatch(fetchTmsWatcherDetails(props.module, props.itemId));
        })
        .catch((error) => {
          setDisableBtn(false);
          console.log("WatcherError", error);
        });
    } else {
      axios
        .post(GLOBAL.addWatcherDetails, postData)
        .then((watcherData) => {
          if (!watcherData) {
            throw Error("Watcher details not found.");
          }
          return watcherData;
        })
        .then((watcherData) => {
          setSelectedConsumers([]);
          setEmailIds("");
          setDisableBtn(false);
          setConsumerDataDetails([]);
          dispatch(fetchWatcherDetails(props.module, props.itemId));
        })
        .catch((error) => {
          setDisableBtn(false);
          console.log("WatcherError", error);
        });
    }
  };

  // delete watcher func
  const deleteWatcher = (watcherId) => {
    if (props.module === "Task") {
      axios.patch("/api/watchers/" + watcherId, {}).then((response) => {
        if (response) {
          dispatch(fetchTmsWatcherDetails(props.module, props.itemId));
        }
      });
    } else {
      axios.patch(GLOBAL.updateWatcher + watcherId, {}).then((response) => {
        if (response) {
          dispatch(fetchWatcherDetails(props.module, props.itemId));
        }
      });
    }
  };

  // check for current user ---
  const checkCurrentUser = (list) => {
    let userWatcherId;
    list.forEach((user) => {
      if (user.watcherEmailid === currentUserEmail) {
        userWatcherId = user.watcherId;
      }
    });
    return userWatcherId;
  };
  // render table data
  const renderMemberData = (list) => {
    return list.map((data) => {
      console.log("DATABRKFX",data);
      const tooltip = (
        <Popover className="userInfoPopup">
          <XsmUserDetails
            userId={data.watcherUserId}
            translator={translator}
            isHeadingVisible={false}
            isPopup={true}
          />
        </Popover>
      );
      let id = `watcher-${data.watcherId}`;
      return (
        <tr key={id}>
          <td className="w-100">
            {data.isExternal?.toString()=== "false" ? (
              <OverlayTrigger
                trigger="click"
                rootClose
                placement={windowSize < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
}
                key={id}
                overlay={tooltip}
              >
                <span title={translator["Info"]} className="cursorPoint">
                {data.watcherName}{" "}
                <ImInfo />
              </span>
              </OverlayTrigger>
            ) : (
              data.watcherEmailid || data.watcherEmailId
            )}
          </td>
          {!disabledStatus.includes(props.status) && (
            props.disableFieldsForGuestRole || props.isFulfillmentSubmittedStatus ? '' :
            <td>
              <Link
                to={void 0}
                onClick={(e) => {
                  e.preventDefault();
                  deleteWatcher(data.watcherId);
                }}
              >
                <i className="fa fa-trash"></i>
              </Link>
            </td>
          )}
        </tr>
      );
    });
  };

  let loadFrom = props.loadFrom ? props.loadFrom : "";
  ///main render part
  return (
    <div className="hambTab watcher-container">
      {loadFrom !== "myRequest" ? (
        <>
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
          <ul>
              <li>
              <button
                  type="button"
                  title="Minimize the right panel"
                  bsClass=""
                  bsStyle=""
                  className="closerightPanelBtn"
                  onClick={() => {
                  props.rightEditPanel(false);
                  props.changeActionView("");
                  props.changeRightView("allClose");
                  }}
              >
                  <IoClose/>
              </button>
              </li>
          </ul>
      </div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {translator["Watcher Details"]}
          </div>
        </div>
        </>
      ) : (
        ""
      )}
      <div className={loadFrom !== "myRequest" ? "rBoxGap" : ""}>
        {props.disableFieldsForGuestRole || props.isFulfillmentSubmittedStatus ? '' : !disabledStatus.includes(props.status) && 
          <>
            <Row className="borderForm  row">
              <Col xs={12}>
                {currentMethod === "watcherName" && (
                  <Form.Group className="attrvaldv form-group radioInlineBox margin-t-10">
                    <Field
                      component={TypeaheadandDropdown}
                      onSelection={onValueSelection}
                      onInputChange={onValueChange}
                      name="watcherName"
                      className="form-control"
                      errorClass={valueError === "error" ? "error" : ""}
                      options={consumerDataDetails}
                      selectedOptions={selectedConsumers}
                      onCrossClick={() => {
                        setSelectedConsumers([]);
                        setConsumerDataDetails([]);
                      }}
                      multiple={true}
                      placeholder={translator["Enter watcher name"]}
                      diableStatus={currentMethod !== "watcherName"}
                    ></Field>

                    {valueError ? (
                      <div className="error">
                        {translator["Please enter the required data..."]}
                      </div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                )}
                {currentMethod === "watcherEmails" && (
                  <Form.Group className="radioInlineBox margin-t-10">
                    <label className="radio-inline d-inline-flex w-100">
                      <Form.Control
                        type="text"
                        className={
                          holdErrorMessage
                            ? "error form-control"
                            : "form-control"
                        }
                        placeholder={
                          translator["Enter comma-separated email ids"]
                        }
                        disabled={currentMethod !== "watcherEmails"}
                        name="emailIds"
                        value={emailIds}
                        onChange={onChange}
                        style={{ height: "36px" }}
                      />
                    </label>
                    {holdErrorMessage ? (
                      <div className="error">{holdErrorMessage}</div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row className="row">
              <Col xl={8} lg={7} md={12} sm={8} xs={12}>
                <ButtonToolbar className="black" bsPrefix=" ">
                  <Button
                    className="rgSidrkBtn smallBtn"
                    size="xs"
                    onClick={() => saveData("addMembers")}
                    disabled={disableBtn}
                  >
                    {translator["Add Watcher"]}
                  </Button>
                  &nbsp;
                  {checkCurrentUser(watcherData) ? (
                    <Button
                      className="rgSiLigBtn smallBtn"
                      disabled={disableBtn}
                      onClick={() =>
                        deleteWatcher(checkCurrentUser(watcherData))
                      }
                      size="xs"
                    >
                      {translator["End Watching"]}
                    </Button>
                  ) : (
                    <Button
                      className="rgSiLigBtn smallBtn"
                      disabled={disableBtn}
                      onClick={() => saveData("addMe")}
                      size="xs"
                    >
                      {translator["Start Watching"]}
                    </Button>
                  )}
                </ButtonToolbar>
              </Col>
              <Col xl={4} lg={5} md={12} sm={4} xs={12}>
                <label className="radio-inline float-r ovrFlowContent" 
                    title={translator["External user"]}>
                  <input
                    type="checkbox"
                    name="req_info_chk"
                    value="watcherEmails"
                    onChange={(event) => {
                      showHideFields(event);
                    }}
                    onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}
                    checked={currentMethod === "watcherEmails"}
                    className="margin-r-5"
                    style={{ position: "relative", top: "-1px" }}
                  />
                  {translator["External user"]}
                </label>
              </Col>
            </Row>
          </>
        }

        <div className="margin-t-10">
          <div className="form-group">
            <span>{translator["Watchers"]}</span>
          </div>
          {watcherLoader.loading ? (
            <Table
              responsive
              striped
              bordered
              condensed
              hover
              className="f-size-13 margin-b-0"
            >
              <thead>
                <tr>
                  <th>{translator["Member"]}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">
                    <ListLoader />
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : watcherData.length > 0 ? (
            <Table
              responsive
              striped
              bordered
              condensed
              hover
              className="f-size-13"
            >
              <thead>
                <tr>
                  <th className="w-100">
                    <sp>{translator["Member"]}</sp>
                  </th>
                  {!disabledStatus.includes(props.status) && !props.disableFieldsForGuestRole && <th>&nbsp;</th>}
                </tr>
              </thead>
              <tbody>{renderMemberData(watcherData)}</tbody>
            </Table>
          ) : (
            <Table
              responsive
              striped
              bordered
              condensed
              hover
              className="f-size-13 margin-b-0"
            >
              <thead>
                <tr>
                  <th>{translator["Member"]}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">{translator["No Data Available"]}</td>
                </tr>
              </tbody>
            </Table>
          )}
        </div>
      </div>
    </div>
  );
};

export default reduxForm({
  form: "watcherDetailsForm",
})(WatcherDetails);
