
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { DropDownButton } from '@progress/kendo-react-buttons';
import { filterAllowedFields, filterFromArray } from "../../../../common/helper";

const ThreeDotComponent = (props) => {
    const {item, defaultValues, language, attributes, renderComponent} = props;
    let filteredData = [];
    const arrayToBeFiltered = item?.renderComponent.split(",") || [];
    if(arrayToBeFiltered && arrayToBeFiltered.length>0){
      arrayToBeFiltered.forEach((ar)=>{
        filteredData.push(
          ...filterAllowedFields(item?.components || [], [ar])
        );
      });
    }
    const getDivs = (components) => {
      return renderComponent(components, false, {
        ...defaultValues,
      });
    };

    const renderItem = ({ item, itemIndex }) => {
      return getDivs(item?.components);
    };
      
    const renderPage = ()=>{
      return (
        <DropDownButton
          text={<BsThreeDotsVertical />}
          items={filteredData}
          itemRender={renderItem}
          {...attributes}
        ></DropDownButton>
      );
     }
  return renderPage();
};
export default ThreeDotComponent;