
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, {useEffect} from "react";
import { Row, Col, Button } from "react-bootstrap";
import {useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { LuFilterX } from "react-icons/lu";
import { HiOutlineRefresh } from "react-icons/hi";

import Cookies from "universal-cookie";

const cookies = new Cookies();


const Header = (props) => {

    const tr = useSelector((state) => state.spcmReducer.tr);

    const resetFilters = () => { 
        props.setSearchParams({});
        props.refresh();
        props.setIsFilterCleared(true);
        props.setPgSize(10);
        props.setPageNum(0);
        props.setShowFilterIcon(false);
      };

    return (
        <>
            <Row className="">
                <Col lg={10} md={10} sm={10} xs={12}>
                    <ul className="wibTabs">
                        <li className="active">
                            <Link title={tr["Golden Dataset"] || "Golden Dataset"} to="javascript:void(0)">
                            {tr["Golden Dataset"]?.trim() ? tr["Golden Dataset"] : "Golden Dataset"}
                            </Link>
                        </li>
                        {/* <li>
                            <Link title={tr["Pending Actions"]} to="/pendingActions">
                                {tr["Pending Actions"]}
                            </Link>
                        </li> */}
                    </ul>
                </Col>
                <Col lg={2} md={2} sm={2} xs={12}>
                    <div
                        aria-label="Page Filters"
                        role="contentinfo"
                        className="paHedFilter"
                    >
                        {props?.showFilterIcon && (
                            <div className="overlayDv">
                            <Button
                                title="Clear All Filters"
                                role="button"
                                bsPrefix=" "
                                className="myBt clrbtn"
                                onClick={resetFilters}
                            >
                                <LuFilterX />
                            </Button>
                            </div>
                        )}
                        <Button
                            aria-label={tr["Refresh"]}
                            title={tr["Refresh"]}
                            role="button"
                            bsClass=""
                            bsPrefix=" "
                            className="myBt"
                            onClick={props?.refresh}
                        >
                            <HiOutlineRefresh className="refreshFlip" />
                        </Button>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Header;
