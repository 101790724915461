/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import AiIcon from "../../aiIcon";
import { Button } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { RiMenuSearchLine } from "react-icons/ri";
const NlpSearchSubStatusComponent = ({isAiINCTypeENV, isAiITMTypeENV, showConditionBuilder, onSubStatusSelect, selectedFilterTab, isButtonActive, moduleName }) => {
  const translator = useSelector(state => state.spcmReducer.tr);
  const isNLPEnabledITM= moduleName === 'fullfilment' && isAiITMTypeENV;
  const isNLPEnabledINC =  moduleName === 'breakfix' && isAiINCTypeENV; 
  const buttonClass = isNLPEnabledITM || isNLPEnabledINC ? "aiButtonCss" : "";
  const buttonTitle = isNLPEnabledITM || isNLPEnabledINC ? translator["Advanced Search"] : translator["NLP Advanced Search"]
  const isNLPSearchDisabled = !(isNLPEnabledITM || isNLPEnabledINC);

  return (
    <><div className={buttonClass}>
    <Button
      title={buttonTitle}
      bsPrefix=" "
      onClick={() => {
        showConditionBuilder(true);
        onSubStatusSelect(5);
        selectedFilterTab(5);
      }}
    >
      {isNLPSearchDisabled ? (
          <RiMenuSearchLine style={{ fontSize: "15px", verticalAlign: "sub" }} />
        ) : (
          <AiIcon style={{ top: "-1px" }} />
        )}
      {translator["Advanced Search"]}
    </Button>
  </div>

    </>
  );  
};

export default NlpSearchSubStatusComponent;