
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import TraitPropertyField from './traitPropertyField';
import { Button } from '@progress/kendo-react-buttons';
import {IoClose} from "react-icons/io5";

export default function CustomTraitManager({ traits, updateDataSourceKeys,setRightPanelShow, sessionPageId, pageJson}) {
  return (
    <div className="gjs-custom-style-manager rightPanelComp text-left">
      <div className="rPageHeading d-flex">
        <div>Properties</div>
        <Button className='closeIcon ms-auto' onClick={()=>{setRightPanelShow(false)}} title='Minimize the details panel' fillMode="outline"><IoClose/></Button>
      </div>
      <div className="rightPanelGap">
        {
          !traits.length ?
            <div className="text-c">No properties available</div>
            :
            traits.map(trait => (
              <TraitPropertyField key={trait.getId()} trait={trait} updateDataSourceKeys={updateDataSourceKeys} pageJson={pageJson} />
            ))}
      </div>
    </div>
  );
}