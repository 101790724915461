
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import sanitizeHtml from 'sanitize-html';
import { IoClose } from "react-icons/io5";

let bg = require("../../../../images/hcl-sx-logo-black.png");
let footerBg = require("../../../../images/footerImg.jpg");

var stylingObject = {
  logoAuth: {
    backgroundImage: `url(${bg})`,
  },
  footerImg: {
    backgroundImage: `url(${footerBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    height: "60px",
    margin: "0px",
    textIndent: "-9999px",
  },
};

const NotificationTemplateEditShortDesc = (props) => {
  const [to, setTo] = useState("")
  const [cc, setCc] = useState("")
  const [subject, setSubject] = useState("")
  
  const decodeEntities = (() => {
    var element = document.createElement("div");

    const decodeHTMLEntities = (str) => {
      if (str && typeof str === "string") {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        element.innerHTML = sanitizeHtml(str);
        str = element.textContent;
        element.textContent = "";
      }
      return str;
    }
    return decodeHTMLEntities;
  })();

  // let decodedText = decodeEntities(props.MESSAGE);
  // let text = decodedText.trim();
  // text = text.split(" ");
  // text.forEach((word , index) => {
  //   if(text[index].includes('<%=newValue.')) {
  //     text[index] = word.replace('<%=newValue.', '\"');
  //   }
  //   if(text[index].includes('%>')) {
  //     text[index] = text[index].replaceAll('%>', '\"');
  //   }
  //   if(text[index].includes('<%=')) {
  //     text[index] = text[index].replaceAll('<%=', '\"');
  //   }
  // })
  // text = text.join(" ");

  useEffect(() => {
    // if (props.MESSAGE != null) {
    //   let notificBody = props.MESSAGE.replace('\"','"');
    if (props.TEMPLATE_BODY != null) {
      let notificBody = props.TEMPLATE_BODY.replace('\"','"');
       notificBody = notificBody.replace('Best Regards,','');
       notificBody = notificBody.replace('bodyPart','bodyPart1');
       notificBody = notificBody.replace('href="<%=appLink%>"','');
      if(props.BODY){
        if (document.getElementById("notification_body"))
      {
        document.getElementById("notification_body").innerHTML = props.BODY.replace('Best Regards,','');
      }
      }
       else if (document.getElementById("notification_body"))
      {
        document.getElementById("notification_body").innerHTML = notificBody; 
      }
    }
    if(props.SUBJECT !== undefined && props.SUBJECT !== "" && props.SUBJECT !== null){
      let decodedSubject = decodeEntities(props.SUBJECT);
      let subject = decodedSubject.split(" ");
      subject.forEach((word, index) => {
        // if (subject[index].includes("%>")) {
        //   subject[index] = subject[index].replaceAll("%>", '"');
        // }
        // if (subject[index].includes("<%=newValue.")) {
        //   subject[index] = subject[index].replaceAll("<%=newValue.", '"');
        // }
        // if (subject[index].includes("<%=")) {
        //   subject[index] = subject[index].replaceAll("<%=", '"');
        // }
      });
      subject = subject.join(" ");
      setSubject(subject);
    }
    if(props.TO_EMAIL !== undefined && props.TO_EMAIL !== "" && props.TO_EMAIL !== null){
      let decodedTo = decodeEntities(props.TO_EMAIL);
      let to = decodedTo.split(" ");
      to.forEach((word, index) => {
        if (to[index].includes("%>")) {
          to[index] = to[index].replaceAll("%>", '"');
        }
        if (to[index].includes("<%=newValue.")) {
          to[index] = to[index].replaceAll("<%=newValue.", '"');
        }
        if (to[index].includes("<%=")) {
          to[index] = to[index].replaceAll("<%=", '"');
        }
      });
      to = to.join(" ");
      setTo(to);
    }
    if(props.CC_EMAIL !== undefined && props.CC_EMAIL !== "" && props.CC_EMAIL !== null){
      let decodedCC = decodeEntities(props.CC_EMAIL);
      let cc = decodedCC.split(" ");
      cc.forEach((word, index) => {
        if (cc[index].includes("%>")) {
          cc[index] = cc[index].replaceAll("%>", '"');
        }
        if (cc[index].includes("<%=newValue.")) {
          cc[index] = cc[index].replaceAll("<%=newValue.", '"');
        }
        if (cc[index].includes("<%=")) {
          cc[index] = cc[index].replaceAll("<%=", '"');
        }
      });
      cc = cc.join(" ");
      setCc(cc);
    }
  }, [props]);
  let languageKey ;
  if(props.LOCALEID == "en"){
    languageKey = "English";
  }else if(props.LOCALEID == "ar"){ languageKey = "Arabic";}
  else if(props.LOCALEID == "zhcn"){ languageKey = "Chinese Simplified";}
  else if(props.LOCALEID == "fr"){ languageKey = "French";}
  else if(props.LOCALEID == "fil"){ languageKey = "Filipino";}
  else if(props.LOCALEID == "hi"){ languageKey = "Hindi";}
  else if(props.LOCALEID == "id"){ languageKey = "Indonesian";}
  else if(props.LOCALEID == "it"){ languageKey = "Italian";}
  else if(props.LOCALEID == "ja"){ languageKey = "Japanese";}
  else if(props.LOCALEID == "ko"){ languageKey = "Korean";}
  else if(props.LOCALEID == "ms"){ languageKey = "Malay";}
  else if(props.LOCALEID == "es"){ languageKey = "Spanish";}
  else if(props.LOCALEID == "ta"){ languageKey = "Tamil";}
  else if(props.LOCALEID == "th"){ languageKey = "Thai";}
  else if(props.LOCALEID == "vi"){ languageKey = "Vietnamese";} 
  else{
    languageKey = "German";
  }
  return (
    <div>
      <Row>
        <Col xs={12}>
          <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                  <li className="">
                      <button
                      type="button"
                      title="Minimize the right panel"
                      bsClass=""
                      bsStyle=""
                      className="closerightPanelBtn"
                      onClick={() => {
                      props.rightEditPanel(false);
                      }}
                      >
                      <IoClose/>
                      </button>
                  </li>
              </ul>
          </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {/* {props.tr["Preview Configuration"]} */}
              {props.tr["Preview"]} - {languageKey}
            </div>
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <div className="notiPre">
          {/* <div className="secdv"><span className="tag">To</span><span className="tagDes">&lt;%=Impacted User%&gt;</span></div> */}
          <div className="secdv">
            <span className="tag">To</span>
            <span className="tagDes">{to}</span>
          </div>

          {/* <div className="secdv"><span className="tag">CC</span><span className="tagDes">&lt;%=Reported By%&gt;</span></div> */}
          <div className="secdv">
            <span className="tag">CC</span>
            <span className="tagDes">
              {props.CC_EMAIL != null ? cc : ""}
            </span>
          </div>

          {/* <div className="secdv"><span className="tag">Subject</span><span className="tagDes">Request for Support &lt;%=OrderNumber%&gt; / &lt;%=breakfixNumber%&gt; has been submitted</span></div> */}
          <div className="secdv">
            <span className="tag">{props.tr["Subject"]}</span>
            <span
              className="tagDes"
              style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
            >
              {subject}
            </span>
          </div>

          <div className="secdv margin-t-10" style={{ borderBottom: "none" }}>
            <div className="secdvLogo" style={stylingObject.logoAuth}>
              {props.tr["Company Logo"]}
            </div>
          </div>
          {/* <div className="secdv notiBodyPre" style={{borderBottom:'none'}}>
          Dear &lt;%=Impacted Username%&gt;,<br /><br />
          Thank you for contacting us. Your Incident ticket has been logged successfully. Additional details about the ticket are given below:-<br /><br />
          Ticket ID: &lt;%=orderNumber%&gt; / &lt;%=breakfixNumber%&gt;<br />
          Priority: &lt;%=priorityid%&gt;<br />
          Reported On: &lt;%=createdOnGMT%&gt;<br />
          Impacted Service: &lt;%=serviceorCIName%&gt;<br />
          Issue Description: &lt;%=description%&gt;<br />
          </div> */}
          <div
            className="secdv notiBodyPre"
            id="notification_body"
            style={{
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              borderBottom: "none",
            }}
          > 
        </div>
          <div className="secdv" style={{ borderBottom: "none" }}>
            Best Regards,
            <br />
            Support Team
          </div>
          <div
            className="secdv"
            style={{ borderBottom: "none", fontSize: "12px" }}
          >
            Please do not reply to this email as it will not update your ticket.
            Please login to the Support Portal to provide an update.
          </div>
          <div className="secdv" style={{ borderBottom: "none" }}>
            <div style={stylingObject.footerImg}>
              {props.tr["Footer Image"]}
            </div>
          </div>
          <div className="secdv notbg" style={{ borderBottom: "none" }}>
            This is a system generated email for notification purposes only sent
            from an unmonitored mailbox. Please do not reply to this mail.
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationTemplateEditShortDesc;
