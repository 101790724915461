
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row, Col, Form, Button, Table, Alert} from 'react-bootstrap';
import { connect } from 'react-redux'; 
import { applySLAFilter,getServiceLevels,setEditServiceDetails,setForm,getServiceDetailCounts,updateHideFromConsumerSla } from '../../../actions/spcmActions';
import ComponentForm from './editForm.js';
import constants from '../../../utils/constants';
import axios from 'axios';
import Pagination from "react-js-pagination";
import ListLoader from "_Commons/loaders/ListLoader";
import { change } from 'redux-form';
import Swal from 'sweetalert2';
import { GLOBAL } from '_Globals';
import {postAttributeBasedSlaAction} from '../../../actions/sla/lovAction';
import TableComponent from '_Commons/ReactTable/TableComponent';
import Cookies from "universal-cookie";
import { openLinkInNewTab } from '../../common/helper.js';

const cookies = new Cookies();
let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];

const CellName = (props) => {
	console.log("Props,slaID",props)
	let slaID = props.row.slaId;
	return (
	<div className="text-c">

		<i title="Edit in SLA Board" onClick={()=>{
		if(slaID){
			openLinkInNewTab(app_url+"/home?editSlaDef="+slaID, "New Tab"); 
		}
		}} className="fa fa-pencil-square-o cursorPoint"></i>
	
	</div>
	)
  };
const CellNameAndModule = ({row,tr}) => {
console.log("ROW===",row);
	return (
		<div>
			<div class="" title={row.slaName}>
				{/* {row.slaName.slice(0, 12)}{row.slaName.length > 12 ? "..." : ""} */}
				{row.slaName}

			</div>
			<div style={{'color':'#8a8a8a'}} class="" title={row.moduleName}>
				{/* {row.moduleName.slice(0, 12)}{row.moduleName.length > 12 ? "..." : ""} */}
				{row.moduleName}
			</div>
		</div>
	)
  };
const CellNameTargetForAndTime = ({row,tr}) => {
	return (
		<div>
			<div class="" title={row.goalType}>
				{row.goalType}

			</div>
			<div style={{'color':'#8a8a8a'}} class="" title={row.goalTime}>
				{row.goalTime}
			</div>
		</div>
	)
  };
  

class ListView extends React.Component {
	constructor(props){
		super(props);
		this.state={
			queryBy:'moduleName',
			sortBy:'moduleName',
			query:'',
			deleteError:'',
			sortIcons: false,
			class1: 'sort up',
			class2: 'sort',
			showError:false,
			error:'Changes are not allowed in Deployed status',
			sortByTitle:'',
			hideFromConsumer:false,
			expectedEditable:"false",
			slaManager : false,
			slaUser : false
		}
		this.sortFun = this.sortFun.bind(this);
		this.onTextChange=this.onTextChange.bind(this);
		this.onPageChange=this.onPageChange.bind(this);
		this.onPageSizeChange=this.onPageSizeChange.bind(this);
		this.onSearchClick=this.onSearchClick.bind(this);
		this.queryBySelect=this.queryBySelect.bind(this);
		this.onDeleteClick=this.onDeleteClick.bind(this);
		this.componentWillMount = this.componentWillMount.bind(this);
		this.onSort = this.onSort.bind(this);
		this.handleDismiss = this.handleDismiss.bind(this);
		this.sortHover = this.sortHover.bind(this);
		this.hideFromConsumer = this.hideFromConsumer.bind(this);
		this.onexpectedEditableChange = this.onexpectedEditableChange.bind(this);
	}


	handleKeyPress(e){
		if (e.key === 'Enter'){
			this.onSearchClick();
			if(e.preventDefault) e.preventDefault();
		}
	}

	sortFun(myObj){
		this.setState({ sortIcons:!this.state.sortIcons});
		switch(myObj) {
			case 'class1':
				this.setState({ class1:(this.state.sortIcons ? 'sort up' : 'sort down')});
				this.setState({ class2:'sort',sortBy:'moduleName'});
				this.props.getServiceLevels(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,'moduleName',this.state.sortIcons?'asc':'desc');
			break;
			case 'class2':
				this.setState({ class1:'sort'});
				this.setState({ class2:(this.state.sortIcons ? 'sort up' : 'sort down'),sortBy:'goalType'});
				this.props.getServiceLevels(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,'goalType',this.state.sortIcons?'asc':'desc');
			break;
		}

	};

	onTextChange(e){
		this.setState({query:e.target.value});	
	}

	onPageSizeChange(e){
		this.setState({pageSize:e.target.value});
		this.props.getServiceLevels(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,this.state.sortBy,this.state.sortIcons?'desc':'asc',1,e.target.value);
		//this.props.applySLAFilter(this.state.queryBy,this.state.query,'',1,e.target.value);
	}

	onPageChange(e){
		this.props.getServiceLevels(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,this.state.sortBy,this.state.sortIcons?'desc':'asc',e,this.state.pageSize);
	}

	onSearchClick(){
		this.props.getServiceLevels(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,this.state.sortBy);
	}

	queryBySelect(e){
		this.setState({queryBy:e.target.value});
	}

	
	sortHover(e){
		let index = e.nativeEvent.target.selectedIndex;
		this.setState({sortByTitle:e.nativeEvent.target[index].text})
	}


	handleDismiss() {
		this.setState({ showError: false });
	  }

	onDeleteClick(id){
		if(this.props.spcmReducer.currentlySelectedServiceDetails.STATUS=="Deployed"){					
			this.setState({showError:true});
		}else{
			Swal.fire({
				title: 'Are you sure you wish to delete this item?',
				
				width: 550,
				padding: '15px',
				showDenyButton: true,
				confirmButtonText: 'Yes',
				denyButtonText: 'No',
			  }).then((result) => {
				if (result.isConfirmed) {
					axios.delete(constants.SERVER+constants.OFG_PERFORMANCE_TARGETS_API_URL+"/"+id).then((resp)=>{
						this.props.getServiceLevels(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
						this.props.getServiceDetailCounts(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
					}).catch((error)=>{
						this.setState({deleteError:error.response.data[0].message})
					})
				} else if (result.isDenied) {

				}
			  })
	    }
	}
	onSort(e){
		this.setState({sortBy:e.target.value});
		this.props.getServiceLevels(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.state.queryBy,this.state.query,e.target.value)
	}

	hideFromConsumer(e){
		axios.patch(constants.SERVICEPATCH_API_URL+"/"+this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,{"hide_sla":e.target.checked ? "true":"false" })
		this.props.updateHideFromConsumerSla(e.target.checked ? "true":"false")
	}


	onexpectedEditableChange(e){

			

			axios.patch(constants.SERVICEPATCH_API_URL+"/"+this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,{"editable_expectedby":e.target.value });
				//this.props.expectedCompletionEditable(e.target.value)
				this.props.change("CreateSerForm", "editable_expectedby", e.target.value)
				this.setState({expectedEditable:e.target.value}
					);
	}

	componentWillMount(){
		this.props.setForm("serviceLevelForm");
		this.props.getServiceLevels(this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID);
		console.log("CALLED");
		this.props.slaCountFun(this.props.spcmReducer.serviceDetailCounts.SERVICELEVEL);

		//this.props.showHideTabCreate(false);

		if(this.props.spcmReducer.updatedHideSlaStatus){
			if(this.props.spcmReducer.updatedHideSlaStatus == "true"){
				this.setState({hideFromConsumer:true})
			}
			else{
				this.setState({hideFromConsumer:false})
			}
		}
		else{
			if(this.props.spcmReducer.currentlySelectedServiceDetails.HIDE_SLA === "true"){
				this.setState({hideFromConsumer:true})
			}
			else{
				this.setState({hideFromConsumer:false})
			}
		}

		this.setState({expectedEditable:this.props.spcmReducer.currentlySelectedServiceDetails.EDITABLE_EXPECTEDBY});
		this.props.postAttributeBasedSlaAction('',this.props.spcmReducer.currentlySelectedServiceDetails.OFFERING_ID,this.props.spcmReducer.currentlySelectedServiceDetails.COMPANY_ID,'','','','','','')
		let str = {};
		str.user_id = "";
		str = JSON.stringify(str);
		axios.get("/api/userInfo", { headers: { query: str } }).then((response) => {
		//   this.setState({ role_id: response.data.role_id });
		  let roleArray = response.data.role_id.split(",");
		  console.log("roleArray===",roleArray)
		//   let amsRole = roleArray.includes("41");
		//   this.setState({ showRedirectionLink: amsRole });
		let slaManagerRole = roleArray.includes("40");
		this.setState({slaManager:slaManagerRole});
		let slaUserRole = roleArray.includes("45");
		this.setState({slaUser:slaUserRole});
		});
	}
	  tableColumns = [
		  {
			name: <div>
				<div>SLA Name,</div><div>Module</div>
				</div> ,
			selector: (row) => row.slaName,
			sortable: true,
			cell: (row) => (
				<CellNameAndModule row={row} 				
				 tr={this.props.tr}				
				  />
			  ),
			  ignoreRowClick: true,
			
		  },
		//   {
		// 	name: 'Module',
		// 	selector: (row) => row.moduleName,
		// 	sortable: true,
		//   },
		{
			name: <div>
			<div>Target For,</div><div>Time (Mins)</div>
			</div> ,
			selector: (row) => row.goalType,
			sortable: true,
			cell: (row) => (
			  <CellNameTargetForAndTime row={row} 				
			   tr={this.props.tr}				
				/>
			),
		  },
		  {
			name: 'Status',
			selector: (row) => row.slaStatus,
			sortable: false,
		  },
		  {
			name: 'Action',
		  //   selector: (row) => row.slaStatus,
			sortable: false,
			cell: (row) => (
			  <CellName row={row} 
			  //  isClickable={true}
			   tr={this.props.tr}
			   {...this.props}
				/>
			),
			ignoreRowClick: true,
		  }
		  
		]
		tableColumnsBasedOnSLARole = [
			{
			  name: <div>
			  <div>SLA Name,</div><div>Module</div>
			  </div> ,
			  selector: (row) => row.slaName,
			  sortable: true,
			  cell: (row) => (
				<CellNameAndModule row={row} 				
				 tr={this.props.tr}				
				  />
			  ),
			  ignoreRowClick: true,
			},
			// {
			//   name: 'Module',
			//   selector: (row) => row.moduleName,
			//   sortable: true,
			// },
			{
			  name: <div>
			  <div>Target For,</div><div>Time (Mins)</div>
			  </div> ,
			  selector: (row) => row.goalType,
			  sortable: true,
			  cell: (row) => (
				<CellNameTargetForAndTime row={row} 				
				 tr={this.props.tr}				
				  />
			  ),
			},
			// {
			//   name: 'Target Time',
			//   selector: (row) => row.goalTime,
			//   sortable: false,
			// },
			{
			  name: 'Status',
			  selector: (row) => row.slaStatus,
			  sortable: false,
			}
			
		  ]
       filterColumns = ["slaName", "moduleName", "goalType", "goalTime","slaStatus"];

		renderCategoryTableList = (slaAttributeData) => {
			if(this.state.slaManager == false && this.state.slaUser == false){
				console.log("TableColumnsSLA");
				return (
					<div className="catBordDv leftSeardv">
						<TableComponent
						  data={slaAttributeData}
						  columns={this.tableColumnsBasedOnSLARole}
						  headerColumns={this.filterColumns}
						  uniqueKey={'slaId'}
						  paginationText = {"Show"}
						/>
					  </div>         
				  );
			}else{
				console.log("TableColumns");

				return (
					<div className="catBordDv leftSeardv">
						<TableComponent
						  data={slaAttributeData}
						  columns={this.tableColumns}
						  headerColumns={this.filterColumns}
						  uniqueKey={'slaName'}
						  paginationText = {"Show"}

						/>
					  </div>         
				  );
			}
       
      };
   render() {
		const tr=this.props.tr;
		const serviceWorkItemType=this.props.spcmReducer.currentlySelectedServiceDetails.WORK_ITEM_TYPE;

	var meta = this.props.spcmReducer.meta;
	console.log(this.props.spcmReducer);

    const serviceLevel = this.props.spcmReducer.serviceLevels;
    const expectedEditable = this.state.expectedEditable;
	const attributeBasedSLAData = this.props.postAttributeBasedSlaReducer;

		if(this.props.spcmReducer.serviceDetailCounts.SERVICELEVEL != 0){
		this.props.slaCountFun (this.props.spcmReducer.serviceDetailCounts.SERVICELEVEL)
		}
	console.log("serviceLevel",serviceLevel)
	console.log("this.props.postAttributeBasedSlaReducer",this.props.postAttributeBasedSlaReducer)
	console.log("attributeBasedSLAData.data",attributeBasedSLAData)

      return (
			<div className="">
				{this.state.showError&&(<Alert style={{"marginBottom":"15px","fontSize":"13px","padding":"8px 30px 8px 8px"}} variant="danger" onDismiss={this.handleDismiss}><h4>{this.state.error}</h4></Alert>)}
				{this.props.componentFormToggle3 ? 
				<ComponentForm tr={tr} componentFormFun4={this.props.componentFormFun3} setServiceStatus={this.props.setServiceStatus} serviceStatusState = {this.props.serviceStatusState} /> :
				<div className='rBoxGap'>
				
					{/* {this.props.spcmReducer.spcmFetchingError == true && this.props.spcmReducer.spcmFetchingModule == 'SERVICE_LEVELS'  ?  <Alert variant="danger" className="errorMessageSec margin-t-20"><p>Oops! Something went wrong.</p></Alert> :  null} */}
					<div className="f-size-12">
					{
						(attributeBasedSLAData.length == 0) ?
							<div>
								<font>{tr['No Data Found']}</font>
							</div>

							:
							<div>
								{this.props.showLoader ? <ListLoader /> : this.renderCategoryTableList(attributeBasedSLAData)}
							</div>
					}
					</div>
			
				</div>
				}
			</div>
		);
	}
}

const mapStateToProps=({spcmReducer,postAttributeBasedSlaReducer})=>{
	return{
		spcmReducer,postAttributeBasedSlaReducer
	}
}
export default connect(mapStateToProps,{ applySLAFilter,getServiceLevels,setEditServiceDetails,setForm,getServiceDetailCounts,updateHideFromConsumerSla,change,postAttributeBasedSlaAction  })(ListView);