
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { connect } from "react-redux";
import BasicTable from "../../../common/ReactTable/BasicTable";
import {IoClose} from "react-icons/io5";

class TaskExternalSystemAttributes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.renderExternalSystemAttributeHTML =
      this.renderExternalSystemAttributeHTML.bind(this);

    this.options = {
      page: 1,
      sizePerPageList: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "15",
          value: 15,
        },
        {
          text: "20",
          value: 20,
        },
        {
          text: "All",
          value: 99999,
        },
      ], // you can change the dropdown list for size per page
      sizePerPage: 5, // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 6, // the pagination bar size.
      paginationPosition: "top", // default is bottom, top and both is all available
    };
  }

  componentWillReceiveProps(nextProps, prevProps) {
   
  }

  componentDidMount() {
    console.log("Inside Task External");
    console.log(this.props.taskDetails.clientTaskData);
  }

  renderExternalSystemAttributeHTML(list) {
    if (
      this.props.taskDetails.clientTaskData &&
      Array.isArray(this.props.taskDetails.clientTaskData) &&
      this.props.taskDetails.clientTaskData.length > 0
    ) {
	  console.log("clientTask===",this.props.taskDetails.clientTaskData);
		const tableColumns = [
      
      {
			  name: "Client Name",
			  selector: (row) => row.clientName,
			  sortable: true,
			},
			{
			  name: "Attribute",
			  selector: (row) => "clientTaskNumber",
			  sortable: true,
			},
			{
			  name: "Value",
			  selector: (row) => row.clientTaskNumber,
			  sortable: true,
			},
		  ];
      return (
        <div>
          <div className="knowledgeDiv">
		  <BasicTable
              tableColumns={tableColumns}
              data={this.props.taskDetails.clientTaskData}
              pagination={true}
              onRowSelect={(row) => {console.log("&&&&&&&&&&",row)}}
            />
            {/* <BootstrapTable
              bordered
              condensed
              hover
              responsive
              className="tableView paginationSetNew"
              data={this.props.taskDetails.clientTaskData}
            >
              <TableHeaderColumn dataField="attrId" isKey hidden>
                attribute id
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField=";"
                dataSort
                columnClassName=""
                width="50%"
              >
                Attribute
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="attrValue"
                dataSort
                columnClassName=""
                width="50%"
              >
                Value
              </TableHeaderColumn>
            </BootstrapTable> */}
          </div>
        </div>
      );
    } else {
      return <div>{this.props.translator["No Data Available"]}</div>;
    }
  }

  render() {
    // console.log('this.props',this.props);

    return (
      <div>
        <div>
          <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    this.props.changeActionView("");
                    }}
                >
                    <IoClose />
                </button>
                </li>
            </ul>
          </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {this.props.translator["External Attributes"]}
            </div>
          </div>
          <div className="rBoxGap">
            {this.renderExternalSystemAttributeHTML(
              this.props.taskDetails.clientTaskData
            )}
          </div>
        </div>
      </div>
    );
  }
}

// export default BreakFix;

const mapStateToProps = ({
  externalSystemAttributesList,
  isFetchingDetails,
}) => {
  return {
    externalSystemAttributesList,
    isFetchingDetails,
  };
};

export default connect(mapStateToProps, {})(TaskExternalSystemAttributes);
