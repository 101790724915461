/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/

import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { IoSearch, IoClose } from "react-icons/io5";
import {
  Row,
  Col,
  Form,
  Table,
  Nav,
  Button,
  ButtonToolbar,
} from "react-bootstrap";
import Cookies from "universal-cookie";

import BasicTable from "../../../common/ReactTable/BasicTable";

const cookies = new Cookies();
let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];

let cookiesData = cookies.get("gph");
if (cookiesData) cookiesData = cookiesData.replace("s:", "");
if (cookiesData)
  cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf("."));
cookiesData = cookiesData.split("~");
let onRoleVisible = cookiesData[33] ? cookiesData[33].split(",") : [];
let assignedGrpId = cookiesData[58] ? cookiesData[58].split(",") : [];

let onContractRoleVisible = cookiesData[33] ? cookiesData[33].split(",") : [];
onContractRoleVisible = onContractRoleVisible.includes("68");

let onConfigManagerRoleVisible = cookiesData[33]
  ? cookiesData[33].split(",")
  : [];
onConfigManagerRoleVisible = onConfigManagerRoleVisible.includes("37");

let onAssetRoleVisible = cookiesData[33] ? cookiesData[33].split(",") : [];
onAssetRoleVisible = onAssetRoleVisible.includes("38");
export const CIGroups = (props) => {
  const [isList, setIsList] = useState(true);
  const CI_GROUP_DETAILS = props && props.ciDetails ? props.ciDetails.CI_GROUP_DETAILS : null;
  const toggleFormAndList = () => {
    setIsList(!isList);
  };

  const tableColumns = [
    {
      name: "Group Name",
      selector: (row) => (row?.group_name || ""),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (row?.status || ""),
      sortable: true,
    }    
  ];

  
  return (
    <div className="clDiv Ovrflow StTimeline">
      <Row>
        <Col>
          <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
            {/* <ul>
              <li>
                {onAssetRoleVisible || onConfigManagerRoleVisible ? (
                  <Button
                    bsPrefix=" "
                    onClick={toggleFormAndList}
                    bsClass=""
                    bsStyle=""
                  >
                    <i
                      className={
                        isList ? "fa fa-plus f-size-16" : "fa fa-list f-size-16"
                      }
                    ></i>
                  </Button>
                ) : null}
              </li>
              <li className="d-md-none">
                <button
                  type="button"
                  title="Minimize the right panel"
                  bsClass=""
                  bsStyle=""
                  className="closerightPanelBtn"
                  onClick={() => {
                    props.rightEditPanel(false);
                  }}
                >
                  <IoClose />
                </button>
              </li>
            </ul> */}
          </div>
          <div className={"rPageHeading"}>
            <div className="offNam margin-t-5 margin-b-5">CI Groups</div>
          </div>
        </Col>
      </Row>
      <div className="rBoxGap">
      <BasicTable
        persistTableHead
        tableColumns={tableColumns}
        data={CI_GROUP_DETAILS || []}
        pagination={true}    
      />
      </div>
    </div>
  );
};
