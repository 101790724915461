
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Form, Table } from 'react-bootstrap';
import { getAllServicesList, getAuditHistory, getReviews, getChainEntitiesCompanyList, getAllCompanyList, getAllServiceListForSearch,getServiceDetailsDataList } from '../../actions/spcmActions';
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import { loadCategoryList } from '_Actions/categoryBoard/quickViewAction.js';

import ListLoader from "_Commons/loaders/ListLoader";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import axios from 'axios';
const Joi=require('joi')
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import ReactDOM from "react-dom";
import moment from 'moment';
import _ from 'lodash';
import {IoSearch} from 'react-icons/io5';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
import Cookies from 'universal-cookie';
import { featuredInitialOptions, statusInitialOptions, workItemInitialOptions } from '../../utils/common';
import MultiSelectCheckboxWithOK from '../common/MultiSelectCheckboxWithOK';
const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
let dateformatCookie = homepagelocation[2];
let result = dateformatCookie.substr(0, 10);

class ServiceList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			queryBy: 'Company',
			query: '',
			pageSize: 10,
			row: '',
			filterSelected: "",
			allCheckedFilters: [],
			advanceFilterIsOpen: false,
			queryArray: [],
			impactedServiceDataDetails: [],
			endDate: "",
			startDate: "",
			defaultServiceSelected: [],
      serviceNameSearchFilter: "",
      companySearchFilter: "",
      isMultiSelectOpen: false,
      categoryDropdownValues: [],
      selectedAction: '',
      faCustomDesc1: "customArrow dropdown",
      faCustomAsc1: "customArrow dropup",
      faCustomDesc2: "customArrow dropdown",
      faCustomAsc2: "customArrow dropup",
      faCustomDesc3: "customArrow dropdown",
      faCustomAsc3: "customArrow dropup",
      faCustomDesc4: "customArrow dropdown",
      faCustomAsc4: "customArrow dropup",
      faCustomDesc5: "customArrow dropdwn",
      faCustomAsc5: "customArrow dropup",
      faCustomDesc6: "customArrow dropdown",
      faCustomAsc6: "customArrow dropup",
      faCustomDesc7: "customArrow dropdown isActive",
      faCustomAsc7: "customArrow dropup",
      sort: true,
      lastSelectedSearchFilter: 'modifiedDate',
      searchclick: false,
      categoryMultiSelect: false,
      isSubmittedModified: "",
      multiSelectObjLeftPos: "0",
      isSBStatusAllLabel: false,
      lastSelectedStatusFilter: [],
      selectObjLeftPos: "0",
      isMultiDropdownOpen: true,
    }
		// }, this.props.serviceListInitialState)
		// this.onAddFilterTextChange = this.onAddFilterTextChange.bind(this);
		this.onPageChange = this.onPageChange.bind(this);
		this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.handleInputSearch = this.handleInputSearch.bind(this);
    this.handleCallback = this.handleCallback.bind(this);
    this.handleWorkItemChange = this.handleWorkItemChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleFeaturedChange = this.handleFeaturedChange.bind(this);
    this.onSortAsc = this.onSortAsc.bind(this);
    this.clickOutside = this.clickOutside.bind(this);
    this.selectAllLabelFun = this.selectAllLabelFun.bind(this);
    this.multiSelectOkButtonFunc = this.multiSelectOkButtonFunc.bind(this);
    this.setLastSelectedStatusFilter = this.setLastSelectedStatusFilter.bind(this);
	}
  componentDidMount() {
    document.addEventListener('mousedown', this.clickOutside);
    // if (this.multiSelectStatusDropdownRef != undefined || this.multiSelectStatusDropdownRef != null) {
    //   ReactDOM.findDOMNode(this.multiSelectStatusDropdownRef).childNodes[0].className = "multiSelectRef";
    // }
    // if (this.multiSelectWorkItemDropdownRef != undefined || this.multiSelectWorkItemDropdownRef != null) {
    //   ReactDOM.findDOMNode(this.multiSelectWorkItemDropdownRef).childNodes[0].className = "multiSelectRef";
    // }
    // if (this.multiSelectFeaturedDropdownRef != undefined || this.multiSelectFeaturedDropdownRef != null) {
    //   ReactDOM.findDOMNode(this.multiSelectFeaturedDropdownRef).childNodes[0].className = "multiSelectRef";
    // }
    // if (this.multiSelectCategoryDropdownRef != undefined || this.multiSelectCategoryDropdownRef != null) {
    //   ReactDOM.findDOMNode(this.multiSelectCategoryDropdownRef).childNodes[0].className = "multiSelectRef";
    // }

    let filterData = sessionStorage.getItem("filterValues");
    let isStatusFilterApplied;
    filterData = JSON.parse(filterData);
    if(filterData?.status) {
      let defaultStatus = ["Draft", "Deployed", "Proposed", "Suspended"];
      isStatusFilterApplied = false;
      defaultStatus.forEach((status, index) => {
        if(status !== filterData.status[index]) {
          isStatusFilterApplied = true;
        }
      })
    }
    if (filterData && Object.keys(filterData).length > 0) {
      this.props.isClearAllFilterFunc(true);
      if (
        _.isEmpty(filterData.category) &&
        _.isEmpty(filterData.featured) &&
        _.isEmpty(filterData.workItemType) &&
        (isStatusFilterApplied === false && filterData.status.length === 4) &&
        _.isEmpty(filterData.searchParams) &&
        _.isEmpty(filterData.modifiedDate)
      ) {
        this.props.isClearAllFilterFunc(false);
      }
      if(filterData.searchParams && filterData.searchParams.Company) {
        this.props.setCompanyNameInputValues(filterData.searchParams.Company);
        this.setState({ companySearchFilter: filterData.searchParams.Company });
      }
      if(filterData.searchParams && filterData.searchParams.Service) {
        this.props.setServiceNameInputValues(filterData.searchParams.Service);
        this.setState({ serviceNameSearchFilter: filterData.searchParams.Service });
      }

      let featured, workItemType, status;

      if(filterData.status.length > 0) {
        status = statusInitialOptions.filter(elem => filterData.status.includes(elem.value))
      }
      if(filterData.featured.length > 0) {
        featured = featuredInitialOptions.filter(elem => filterData.featured.includes(elem.value))
      }
      if(filterData.workItemType.length > 0) {
        workItemType = workItemInitialOptions.filter(elem => filterData.workItemType.includes(elem.value))
      }

      this.props.setSelectedStatusValue(status);
      this.props.setSelectedFeaturedValue(featured);
      this.props.setSelectedCategoryValue(filterData.selectedCategoryOptions);
      this.props.setSelectedWorkItemValue(workItemType);
      this.props.setMultiSelectParams(
        filterData.featured,
        filterData.status,
        filterData.category,
        filterData.workItemType
      );
      this.onSortAsc(
        filterData.sort,
        filterData.sortOnColumn,
        filterData.sortOrder,
        "",
        true
      );
      this.props.setSearchParams(filterData.searchParams);
      this.props.setLimit(filterData.size);

      if(filterData.modifiedDate !== "") {
        this.props.setModifiedStartAndEndDate(moment(filterData.modifiedDate[0]).format('MM/DD/YYYY HH:mm:ss'), moment(filterData.modifiedDate[1]).format('MM/DD/YYYY HH:mm:ss'), filterData.modifiedDate);
      }
      this.props.setPage(filterData.page);
      this.props.getServiceDetailsDataList(
        filterData.searchParams,
        filterData.status,
        filterData.category,
        filterData.featured,
        filterData.workItemType,
        filterData.modifiedDate,
        filterData.sortOrder,
        filterData.sortOnColumn,
        filterData.page,
        filterData.size
      );
    }
    else {
      this.props.getServiceDetailsDataList("", this.props.status, "", "", "");
    }

    this.props.loadCategoryList();
  }


  componentWillReceiveProps(nextProps) {
    let filterData = sessionStorage.getItem("filterValues");
    filterData = JSON.parse(filterData);
    if(filterData && Object.keys(filterData).length > 0 && Object.keys(nextProps.categoryBoardList).length !== 0 && nextProps.categoryBoardList != undefined && !this.state.categoryMultiSelect) {
      if(filterData.category.length > 0) {
        let filter = nextProps.categoryBoardList.filter(elem => filterData.category.includes(elem.CATEGORY_ID));
        let selectedCategory = []
        filter.map((category) => {
          let categoryObj = {};
          categoryObj.label = category.CATEGORY_NAME.length > 30 ? category.CATEGORY_NAME.slice(0,30)+ "..." + "/" + category.SUB_CATEGORY_NAME.slice(0,30)+ "..." : category.CATEGORY_NAME + "/" + category.SUB_CATEGORY_NAME;
          categoryObj.value = category.CATEGORY_ID;
          selectedCategory.push(categoryObj);
        });
        this.props.setSelectedCategoryValue(selectedCategory);
        this.setState({ categoryMultiSelect: true });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.multiSelectStatusDropdownRef != undefined || this.multiSelectStatusDropdownRef != null) {
      ReactDOM.findDOMNode(this.multiSelectStatusDropdownRef).childNodes[0].className = "multiSelectRef";
      let myRef = this.multiSelectStatusDropdownRef;
      if (this.props.isStatusOpen !== prevProps.isStatusOpen) {
        let obj = ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].className =
            "multiSelectPopup";
          // ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].style =
          //   "left:" + this.state.selectObjLeftPos + "px";
          ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[2].style =
            "display:none";
        }
      }
    }
    if (this.multiSelectWorkItemDropdownRef != undefined || this.multiSelectWorkItemDropdownRef != null) {
      ReactDOM.findDOMNode(this.multiSelectWorkItemDropdownRef).childNodes[0].className = "multiSelectRef";
      let myRef = this.multiSelectWorkItemDropdownRef;
      if (this.props.isWrkItmOpen !== prevProps.isWrkItmOpen) {
        let obj = ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].className =
          "multiSelectPopup";
        }
      }
    }
    if (this.multiSelectFeaturedDropdownRef != undefined || this.multiSelectFeaturedDropdownRef != null) {
      ReactDOM.findDOMNode(this.multiSelectFeaturedDropdownRef).childNodes[0].className = "multiSelectRef";
      let myRef = this.multiSelectFeaturedDropdownRef;
      if (this.props.isFeaturedOpen !== prevProps.isFeaturedOpen) {
        let obj = ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].className =
            "multiSelectPopup";
        }
      }
    }
    if (this.multiSelectCategoryDropdownRef != undefined || this.multiSelectCategoryDropdownRef != null) {
      ReactDOM.findDOMNode(this.multiSelectCategoryDropdownRef).childNodes[0].className = "multiSelectRef";
      let myRef = this.multiSelectCategoryDropdownRef;
      if (this.props.isCategoryOpen !== prevProps.isCategoryOpen) {
        let obj = ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].className =
            "multiSelectPopup";
        }
      }
    }
    
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.clickOutside);
  }

  selectAllLabelFun(keyName, value) {
    switch(keyName) {
      case "sericeBoardStatus":
        this.setState({ isSBStatusAllLabel: value });
        break;
    }
  }

  setLastSelectedStatusFilter() {
    if(!this.props.isStatusOpen) {
      this.setState({ lastSelectedStatusFilter: this.props.selectedStatusValue });
    }
  }

  onRowSelect(e, row, index) {

    this.props.rightSidePageHead('ShortDescription', row.OFFERING_ID);
    this.props.getServiceData(row.OFFERING_ID,row.OFFERING_NAME,row.COMPANY_NAME,row.WORK_ITEM_TYPE,row.STATUS,row.CATEGORY_NAME,row.FEATURED,row.CREATED_AT,row.SUMMARY,row.DELIVERY_MODE,row.BASE_LANGUAGE,row.CREATEDBY_NAME, row.BUSINESS_FUNCTION_NAME);
    this.props.showRightSide(true);
    this.props.showActiveRow(index);
  }

  onSortAsc(sort, sortValue, sortType, lastFilter, filterRetaining = false) {
    let sortOrder = "";
    if(!filterRetaining) {
      if (lastFilter == sortValue) {
        this.setState({ sort: !sort });
        sortOrder = sortType;
      }
      else if (lastFilter != sortValue) {
        this.setState({ sort: true });
        sortOrder = "desc";
      }
      this.props.getServiceDetailsDataList(this.props.searchParams, this.props.status, this.props.category, this.props.featured, this.props.workItemType, this.props.modifiedDateFilterValues, sortOrder, sortValue,  this.props.page, this.props.size)
    } else {
      this.setState({ sort: sort });
      sortOrder = sortType;
    }
    // this.props.loadFilterOrderList(searchParams, 0, this.props.limit, sortValue, sortOrder, this.props.searchKey, this.props.searchValue, this.props.pattern, this.props.status);
    this.props.setSortOrder(sortOrder, sortValue);

    switch (sortValue) {
      case 'Service':
        this.setState({ lastSelectedSearchFilter: 'Service' });
        this.setState({
          faCustomDesc1: sortOrder == "asc" ? "customArrow dropdown" : sortOrder == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc1: sortOrder == "asc" ? "customArrow dropup isActive" : sortOrder == "desc" ? "customArrow dropup" : "",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
          faCustomDesc6: "customArrow dropdown",
          faCustomAsc6: "customArrow dropup",
          faCustomDesc7: "customArrow dropdown",
          faCustomAsc7: "customArrow dropup"
        });
        break;
      case 'Company':
        this.setState({ lastSelectedSearchFilter: 'Company' });
        this.setState({
          faCustomDesc2: sortOrder == "asc" ? "customArrow dropdown" : sortOrder == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc2: sortOrder == "asc" ? "customArrow dropup isActive" : sortOrder == "desc" ? "customArrow dropup" : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
          faCustomDesc6: "customArrow dropdown",
          faCustomAsc6: "customArrow dropup",
          faCustomDesc7: "customArrow dropdown",
          faCustomAsc7: "customArrow dropup"
        });
        break;
      case 'Type':
        this.setState({ lastSelectedSearchFilter: 'Type' });
        this.setState({
          faCustomDesc3: sortOrder == "asc" ? "customArrow dropdown" : sortOrder == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc3: sortOrder == "asc" ? "customArrow dropup isActive" : sortOrder == "desc" ? "customArrow dropup" : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
          faCustomDesc6: "customArrow dropdown",
          faCustomAsc6: "customArrow dropup",
          faCustomDesc7: "customArrow dropdown",
          faCustomAsc7: "customArrow dropup"
        });
        break;
      case 'Status':
        this.setState({ lastSelectedSearchFilter: 'Status' });
        this.setState({
          faCustomDesc4: sortOrder == "asc" ? "customArrow dropdown" : sortOrder == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc4: sortOrder == "asc" ? "customArrow dropup isActive" : sortOrder == "desc" ? "customArrow dropup" : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
          faCustomDesc6: "customArrow dropdown",
          faCustomAsc6: "customArrow dropup",
          faCustomDesc7: "customArrow dropdown",
          faCustomAsc7: "customArrow dropup"
        });
        break;
      case 'Category':
        this.setState({ lastSelectedSearchFilter: 'Category' });
        this.setState({
          faCustomDesc5: sortOrder == "asc" ? "customArrow dropdown" : sortOrder == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc5: sortOrder == "asc" ? "customArrow dropup isActive" : sortOrder == "desc" ? "customArrow dropup" : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc6: "customArrow dropdown",
          faCustomAsc6: "customArrow dropup",
          faCustomDesc7: "customArrow dropdown",
          faCustomAsc7: "customArrow dropup"
        });
        break;
      case 'Featured':
        this.setState({ lastSelectedSearchFilter: 'Featured' });
        this.setState({
          faCustomDesc6: sortOrder == "asc" ? "customArrow dropdown" : sortOrder == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc6: sortOrder == "asc" ? "customArrow dropup isActive" : sortOrder == "desc" ? "customArrow dropup" : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
          faCustomDesc7: "customArrow dropdown",
          faCustomAsc7: "customArrow dropup"
        });
        break;
      case 'modifiedDate':
        this.setState({ lastSelectedSearchFilter: 'modifiedDate' });
        this.setState({
          faCustomDesc7: sortOrder == "asc" ? "customArrow dropdown" : sortOrder == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc7: sortOrder == "asc" ? "customArrow dropup isActive" : sortOrder == "desc" ? "customArrow dropup" : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
          faCustomDesc6: "customArrow dropdown",
          faCustomAsc6: "customArrow dropup"
        });
        break;
    }
    // this.props.showActiveRow(0, 0);
    // this.props.sortingArrowsChanged();
  }
  
  onPageChange(e) {
    this.props.showRightSide(false);
    this.props.setPage(e);
    this.props.getServiceDetailsDataList(this.props.searchParams, this.props.status, this.props.category, this.props.featured, this.props.workItemType, this.props.modifiedDateFilterValues, this.props.sortOrder, this.props.sortOnColumn, e, this.props.size)
  }

  onPageSizeChange(e) {
    this.props.multiSelectOpenFun("allFalse", false);
    this.props.showRightSide(false);
    this.props.setLimit(e.target.value);
    this.props.getServiceDetailsDataList(this.props.searchParams, this.props.status, this.props.category, this.props.featured, this.props.workItemType, this.props.modifiedDateFilterValues, this.props.sortOrder, this.props.sortOnColumn, this.props.page, e.target.value)
    // this.props.getNotificationDataListApi("",this.props.page,e.target.value);
  }

  clickOutside(event) {
    const statusDiv = document.getElementById("statusDiv");
    const workitemDiv = document.getElementById("workitemDiv");
    const categoryDiv = document.getElementById("categoryDiv");
    const featuredDiv = document.getElementById("featuredDiv");

    if (this.multiSelectWorkItemDropdownRef != undefined || this.multiSelectWorkItemDropdownRef != null  ||
      this.multiSelectCategoryDropdownRef != undefined || this.multiSelectCategoryDropdownRef != null  ||
      this.multiSelectFeaturedDropdownRef != undefined || this.multiSelectFeaturedDropdownRef != null) {
      document.addEventListener("mousedown", (event) => {
        if (workitemDiv.contains(event.target)) {
          // console.log("TYPE CLICKED");
          this.setState({ isMultiSelectOpen: true });
          this.props.showRightSide(false);
        }
        // else if (statusDiv.contains(event.target)) {
        //   this.setState({ isMultiSelectOpen: true });
        //   this.props.showRightSide(false);
        // }
        else if (categoryDiv.contains(event.target)) {
          this.setState({ isMultiSelectOpen: true });
          this.props.showRightSide(false);
        }
        else if (featuredDiv.contains(event.target)) {
          this.setState({ isMultiSelectOpen: true });
          this.props.showRightSide(false);
        }
        else {
          this.setState({ isMultiSelectOpen: false });
        }
        this.props.multiSelectOpenFun("allFalseNoStatus", false);
      });
    }
  }

  handleInputSearch() {
    let searchParamsObj = {};
    this.props.showRightSide(false);
    this.props.isClearAllFilterFunc(true);
    if(this.props.companyNameSearchInput !== '') {
      searchParamsObj.Company = this.props.companyNameSearchInput.trim()
    }
    if(this.props.serviceNameSearchInput !== '') {
      searchParamsObj.Service = this.props.serviceNameSearchInput.trim()
    }
    this.props.setSearchParams(searchParamsObj);
    this.props.getServiceDetailsDataList(searchParamsObj, this.props.status, this.props.category, this.props.featured, this.props.workItemType, this.props.modifiedDateFilterValues, this.props.sortOrder, this.props.sortOnColumn, this.props.page, this.props.size)
  }

  handleStatusChange(value) {
    let searchOnStatusArr = [];
    this.props.setSelectedStatusValue(value);

    this.props.showRightSide(false);
    value.map((checkBoxValue) => {
      return (
        searchOnStatusArr.push(checkBoxValue.value)
      );
    }
    );
    this.props.setMultiSelectParams(this.props.featured, searchOnStatusArr, this.props.category, this.props.workItemType);
  }

  multiSelectOkButtonFunc() {
    let value = this.props.status;
    this.props.isClearAllFilterFunc(true);
    this.props.getServiceDetailsDataList(this.props.searchParams, value, this.props.category, this.props.featured, this.props.workItemType, this.props.modifiedDateFilterValues, this.props.sortOrder, this.props.sortOnColumn, this.props.page, this.props.size)
  }

  handleCategoryChange(value) {
    // console.log("value", value, this.props.category.length);
    let searchOnCategoryArr = [];
    this.props.setSelectedCategoryValue(value);
    (_.isEmpty(this.props.searchParams)) ?
      this.props.featured.length == 0 && this.props.workItemType.length == 0 && this.props.status.length == 0 ?
        value.length == 0 ?
          this.props.isClearAllFilterFunc(false) :
          this.props.isClearAllFilterFunc(true) :
        this.props.isClearAllFilterFunc(true) :
      this.props.isClearAllFilterFunc(true);

    this.props.showRightSide(false);
    value.map((checkBoxValue) => {
      return (
        searchOnCategoryArr.push(checkBoxValue.value)
      );
    }
    );
    this.props.setMultiSelectParams(this.props.featured, this.props.status, searchOnCategoryArr, this.props.workItemType);
    // console.log('----Column Filter Featured', searchOnCategoryArr);
    this.props.getServiceDetailsDataList(this.props.searchParams, this.props.status, searchOnCategoryArr, this.props.featured, this.props.workItemType, this.props.modifiedDateFilterValues, this.props.sortOrder, this.props.sortOnColumn, this.props.page, this.props.size)
  }

  handleWorkItemChange(value) {
    let searchOnWorkItemArr = [];
    this.props.setSelectedWorkItemValue(value);
    (_.isEmpty(this.props.searchParams)) ?
    this.props.featured.length == 0 && this.props.category.length == 0 && this.props.status.length == 0 ?
        value.length == 0 ?
          this.props.isClearAllFilterFunc(false) :
          this.props.isClearAllFilterFunc(true) :
        this.props.isClearAllFilterFunc(true) :
      this.props.isClearAllFilterFunc(true);

    this.props.showRightSide(false);
    value.map((checkBoxValue) => {
      return (
        searchOnWorkItemArr.push(checkBoxValue.value)
      );
    }
    );
    this.props.setMultiSelectParams(this.props.featured, this.props.status, this.props.category, searchOnWorkItemArr);
    // console.log('----Column Filter WorkItem', searchOnWorkItemArr, this.props.featured, this.props.status, this.props.category);
    this.props.getServiceDetailsDataList(this.props.searchParams, this.props.status, this.props.category, this.props.featured, searchOnWorkItemArr, this.props.modifiedDateFilterValues, this.props.sortOrder, this.props.sortOnColumn, this.props.page, this.props.size);
  }

  handleFeaturedChange(value) {
    let searchOnFeaturedArr = [];
    this.props.setSelectedFeaturedValue(value);
    (_.isEmpty(this.props.searchParams)) ?
    this.props.category.length == 0 && this.props.workItemType.length == 0 && this.props.status.length == 0 ?
        value.length == 0 ?
          this.props.isClearAllFilterFunc(false) :
          this.props.isClearAllFilterFunc(true) :
        this.props.isClearAllFilterFunc(true) :
      this.props.isClearAllFilterFunc(true);

    this.props.showRightSide(false);
    value.map((checkBoxValue) => {
      return (
        searchOnFeaturedArr.push(checkBoxValue.value)
      );
    }
    );
    this.props.setMultiSelectParams(searchOnFeaturedArr, this.props.status, this.props.category, this.props.workItemType);
    // console.log('----Column Filter Featured', searchOnFeaturedArr, this.props.status, this.props.category, this.props.workItemType);
    this.props.getServiceDetailsDataList(this.props.searchParams, this.props.status, this.props.category, searchOnFeaturedArr, this.props.workItemType, this.props.modifiedDateFilterValues, this.props.sortOrder, this.props.sortOnColumn, this.props.page, this.props.size)
  }

  handleSearch(e, picker) {
    this.props.showRightSide(false);
    this.props.isClearAllFilterFunc(true);
    let dateArr = [];
    if (picker) {
      this.props.setModifiedRef(picker);
      dateArr.push(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"), moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
    }
   
    if (picker == undefined && !_.isEmpty(this.props.modifiedStartDate) && !_.isEmpty(this.props.modifiedEndDate)) {
      dateArr.push(moment(this.props.modifiedStartDate).format("YYYY-MM-DD HH:mm:ss"), moment(this.props.modifiedEndDate).format("YYYY-MM-DD HH:mm:ss"));
    }
    this.props.getServiceDetailsDataList(this.props.searchParams, this.props.status, this.props.category, this.props.featured, this.props.workItemType, dateArr, this.props.sortOrder, this.props.sortOnColumn, this.props.page, this.props.size)


  }

  handleCallback(filter, start, end) {
    let modifiedDateFilterValue = [];
    switch (filter) {
      case 'modifiedOn':
        modifiedDateFilterValue.push(start.format("YYYY-MM-DD HH:mm:ss"), end.format("YYYY-MM-DD HH:mm:ss"));
        this.props.setModifiedStartAndEndDate(start.format('MM/DD/YYYY HH:mm:ss'), end.format('MM/DD/YYYY HH:mm:ss'), modifiedDateFilterValue);
        break;
    }
  }

  handleChange(e, searchValue) {
    // this.props.isClearAllFilterFunc(false);
    this.props.showRightSide(false);
    switch (searchValue) {
      case "serviceName":
        this.props.setServiceNameInputValues(e.target.value);
        this.setState({ serviceNameSearchFilter: searchValue });
        break;
      case "companyName":
        this.props.setCompanyNameInputValues(e.target.value);
        this.setState({ companySearchFilter: searchValue });
        break;
    }
  }

  renderTable(data) {
    return data.map((data, index) => {
      let { OFFERING_NAME, COMPANY_NAME, WORK_ITEM_TYPE, STATUS, CATEGORY_NAME, FEATURED, UPDATED_AT, CREATED_AT,OFFERING_ID } = data;
      return (
        // <tr className={this.props.isRightSideVisible && index == this.props.rowIndex ? "myActive" : ""} onClick={(e) => this.onRowSelect(e, data, index)}>
        <tr className={this.props.isRightSideVisible && index == this.props.rowIndex ?  "myActive" :  ""}
         onClick={(e) => {this.onRowSelect(e, data, index) ; this.props.multiSelectOpenFun("allFalse", false);}} eventKey={index}>
          {OFFERING_NAME != null ? OFFERING_NAME.length > 70 ? <td title={OFFERING_NAME}>{OFFERING_NAME.slice(0,70)}{"..."}</td> : <td>{OFFERING_NAME}</td> : <td>{OFFERING_NAME}</td>}
          {COMPANY_NAME != null ? COMPANY_NAME.length > 30 ? <td title={COMPANY_NAME}><div className='showdv414'>
                <div className='keydv414'>{this.props.tr["Company"]}</div>
                <div className='valdv414'>
                  {COMPANY_NAME.slice(0,30)}{"..."}
                </div>
              </div></td> : <td><div className='showdv414'><div className='keydv414'>{this.props.tr["Company"]}</div><div className='valdv414'>{COMPANY_NAME}</div></div></td> : <td><div className='showdv414'><div className='keydv414'>{this.props.tr["Company"]}</div><div className='valdv414'>{COMPANY_NAME}</div></div></td>}
          {WORK_ITEM_TYPE =="serviceRequest" ? <td><div className='showdv414'>
                <div className='keydv414'>{this.props.tr["Work Item Type"]}</div>
                <div className='valdv414'>
                  Service Request
                </div>
              </div></td> : data.WORK_ITEM_TYPE =="standardChange" ? <td><div className='showdv414'><div className='keydv414'>{this.props.tr["Work Item Type"]}</div><div className='valdv414'>Standard Change</div></div></td> : <td><div className='showdv414'><div className='keydv414'>{this.props.tr["Work Item Type"]}</div><div className='valdv414'>Incident</div></div></td>}
          <td>
            <div className='showdv414'>
              <div className='keydv414'>{this.props.tr["Status"]}</div>
              <div className='valdv414'>
                {STATUS}
              </div>
            </div>
          </td>
          {CATEGORY_NAME != null ? CATEGORY_NAME.length > 30 ? <td title={CATEGORY_NAME}><div className='showdv414'>
                <div className='keydv414'>{this.props.tr["Category"]}</div>
                <div className='valdv414'>
                  {CATEGORY_NAME.slice(0,30)}{"..."}
                </div>
              </div></td> : <td><div className='showdv414'><div className='keydv414'>{this.props.tr["Category"]}</div><div className='valdv414'>{CATEGORY_NAME}</div></div></td> : <td><div className='showdv414'><div className='keydv414'>{this.props.tr["Category"]}</div><div className='valdv414'>{CATEGORY_NAME}</div></div></td>}
          <td>
            <div className='showdv414'>
              <div className='keydv414'>{this.props.tr["Featured"]}</div>
              <div className='valdv414'>
                {FEATURED}
              </div>
            </div>
          </td>
          {/* <td>{data.CREATED_AT}</td> */}
          {dateformatCookie == "dd-MM-yyyy HH:mm:ss" ?
            <td>
              <div className='showdv414'>
                <div className='keydv414'>{this.props.tr["Last Modified On"]}</div>
                <div className='valdv414'>
                  {UPDATED_AT != null ? moment(data.UPDATED_AT).format("DD-MM-YYYY HH:mm:ss") : moment(CREATED_AT).format("DD-MM-YYYY HH:mm:ss")}
                </div>
              </div>
            </td>
            : dateformatCookie == "yyyy-MM-dd HH:mm:ss" ?
            <td>
              <div className='showdv414'>
                <div className='keydv414'>{this.props.tr["Last Modified On"]}</div>
                <div className='valdv414'>
                  {UPDATED_AT != null ? moment(data.UPDATED_AT).format("YYYY-MM-DD HH:mm:ss") : moment(CREATED_AT).format("YYYY-MM-DD HH:mm:ss")}
                </div>
              </div>
            </td>
              :
              <td>
                <div className='showdv414'>
                  <div className='keydv414'>{this.props.tr["Last Modified On"]}</div>
                  <div className='valdv414'>
                    {UPDATED_AT != null ? moment(data.UPDATED_AT).format("MM-DD-YYYY HH:mm:ss") : moment(CREATED_AT).format("MM-DD-YYYY HH:mm:ss")}
                  </div>
                </div>
              </td>
          }
        </tr>

      );
    });
  }
  render() {
    let namedSchema=undefined 
    namedSchema = Joi.string().regex(/^[^}{)(]+$/);
    let validator_to_add_myQbj=undefined
    let validate_summary=undefined

    let ascDscToggle = "";
    ascDscToggle = this.state.sort ? "asc" : "desc";

    const tr = this.props.tr;
    let meta = this.props.spcmReducer.serviceListDataMeta;
    let panelColSize = this.props.panelResize;

    let myModifiedStartDate;
    let myModifiedEndDate;
    if(result == "yyyy-MM-dd"){
       myModifiedStartDate = !_.isEmpty(this.props.modifiedStartDate) ? moment(this.props.modifiedStartDate).format("YYYY/MM/DD") : "";
       myModifiedEndDate = !_.isEmpty(this.props.modifiedEndDate) ? moment(this.props.modifiedEndDate).format("YYYY/MM/DD") : "";
    }
    else if(result == "dd-MM-yyyy"){
       myModifiedStartDate = !_.isEmpty(this.props.modifiedStartDate) ? moment(this.props.modifiedStartDate).format("DD/MM/YYYY") : "";
       myModifiedEndDate = !_.isEmpty(this.props.modifiedEndDate) ? moment(this.props.modifiedEndDate).format("DD/MM/YYYY") : "";
    }
    else{
       myModifiedStartDate = !_.isEmpty(this.props.modifiedStartDate) ? moment(this.props.modifiedStartDate).format("MM/DD/YYYY") : "";
       myModifiedEndDate = !_.isEmpty(this.props.modifiedEndDate) ? moment(this.props.modifiedEndDate).format("MM/DD/YYYY") : "";
    }

    let myModifiedNewDate = myModifiedStartDate == "" && myModifiedEndDate == "" ? "" : myModifiedStartDate + "-" + myModifiedEndDate;

    let tableClass = this.state.isMultiSelectOpen;

    let categoryOptions = [];
    if(Object.keys(this.props.categoryBoardList).length !== 0 && this.props.categoryBoardList != undefined) {
      let categories = this.props.categoryBoardList.sort((a, b) => {
        return a.CATEGORY_NAME.localeCompare(b.CATEGORY_NAME)
      });
      categories.map((category) => {
        let categoryObj = {};
        categoryObj.label = category.CATEGORY_NAME.length > 30 ? category.CATEGORY_NAME.slice(0,30)+ "..." + "/" + category.SUB_CATEGORY_NAME.slice(0,30)+ "..." : category.CATEGORY_NAME + "/" + category.SUB_CATEGORY_NAME;
        categoryObj.value = category.CATEGORY_ID;
        categoryOptions.push(categoryObj);
      });
    }
    let statusOptions = [
      {label: 'Draft', value: 'Draft'},
      {label: 'Deployed', value: 'Deployed'},
      {label: 'Obsolete', value: 'Obsolete'},
      {label: 'Proposed', value: 'Proposed'},
      {label: 'Suspended', value: 'Suspended'}
    ];
    let workItemOptions = [
      {label: 'Service Request', value: 'serviceRequest'},
      {label: 'Incident', value: 'incidentRequest'},
      {label: 'Standard Change', value: 'standardChange'}
    ];
    let featuredOptions = [
      {label: 'Yes', value: 'Yes'},
      {label: 'No', value: 'No'}
    ];
    
    let caseResponsive = this.props.spcmReducer.serviceListData == undefined || this.props.spcmReducer.serviceListData.length === 0 || this.props.spcmReducer?.serviceListData?.length <= 6 || this.props.isFetchingDetails ? "overFlowClp" : (this.state.isMultiSelectOpen ? "overFlowClp" : "table-responsive");
    return (
      <div>
        <div className={this.props.spcmReducer.serviceListData == undefined || this.props.spcmReducer.serviceListData.length === 0 || this.props.spcmReducer?.serviceListData?.length <= 6 || this.props.isFetchingDetails ? "respondv responDvMinH" : "respondv"}>
          <div className={"tableRgtBor " + caseResponsive}>
            <Table
              striped
              bordered
              condensed
              hover
              className="tableView nowrapWhright"
              style={{ marginTop: "0px" }}
            >
              <thead>
                <tr>
                  <th>
                    <div
                      className="sortParArr"
                      onClick={() =>
                        this.onSortAsc(
                          this.state.sort,
                          "Service",
                          ascDscToggle,
                          this.state.lastSelectedSearchFilter
                        )
                      }
                    >
                      {this.props.tr["Service Name"]}
                      <div className="sortArr">
                        {this.props.sortOrder == "asc" ? (
                          <span
                            className={this.state.faCustomAsc1}
                            title={this.props.tr["Ascending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        ) : (
                          <span
                            className={this.state.faCustomDesc1}
                            title={this.props.tr["Descending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="colSearDv">
                      <Form.Control
                        className="colSearInp"
                        type="text"
                        value={
                          this.props.serviceNameSearchInput != ""
                            ? this.props.serviceNameSearchInput
                            : ""
                        }
                        placeholder={this.props.tr["Search here"]}
                        onKeyPress={(e) => {
                          if (e.charCode == "13") {
                            if (this.props.serviceNameSearchInput != "" && this.props.serviceNameSearchInput.trim().length > 2)
                              this.handleInputSearch();
                          }
                        }}
                        onChange={(event) => {
                          this.handleChange(event, "serviceName");
                        }}
                        onClick={() => this.props.multiSelectOpenFun("allFalse", false)}
                      />
                      {this.props.serviceNameSearchInput != "" &&
                      this.props.serviceNameSearchInput.trim().length > 2 ? (
                        <a
                          title="search"
                          className="faicn"
                          href="javascript:void(0)"
                          onClick={(event) => this.handleInputSearch()}
                        >
                          <IoSearch/>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </th>

                  <th>
                    <div
                      className="sortParArr"
                      onClick={() =>
                        this.onSortAsc(
                          this.state.sort,
                          "Company",
                          ascDscToggle,
                          this.state.lastSelectedSearchFilter
                        )
                      }
                    >
                      {this.props.tr["Company"]}
                      <div className="sortArr">
                        {this.props.sortOrder == "asc" ? (
                          <span
                            className={this.state.faCustomAsc2}
                            title={this.props.tr["Ascending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        ) : (
                          <span
                            className={this.state.faCustomDesc2}
                            title={this.props.tr["Descending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="colSearDv">
                      <Form.Control
                        className="colSearInp"
                        type="text"
                        value={
                          this.props.companyNameSearchInput != ""
                            ? this.props.companyNameSearchInput
                            : ""
                        }
                        placeholder={this.props.tr["Search here"]}
                        onKeyPress={(e) => {
                          if (e.charCode == "13") {
                            if (this.props.companyNameSearchInput != "" && this.props.companyNameSearchInput.trim().length > 2)
                              this.handleInputSearch();
                          }
                        }}
                        onChange={(event) => {
                          this.handleChange(event, "companyName");
                        }}
                        onClick={() => this.props.multiSelectOpenFun("allFalse", false)}
                      />
                      {this.props.companyNameSearchInput != "" &&
                      this.props.companyNameSearchInput.trim().length > 2 ? (
                        <a
                          title="search"
                          className="faicn"
                          href="javascript:void(0)"
                          onClick={(event) => this.handleInputSearch()}
                        >
                          <IoSearch/>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </th>

                  <th>
                    <div
                      className="sortParArr"
                      onClick={() =>
                        this.onSortAsc(
                          this.state.sort,
                          "Type",
                          ascDscToggle,
                          this.state.lastSelectedSearchFilter
                        )
                      }
                    >
                     {this.props.tr["Work Item Type"]} 
                      <div className="sortArr">
                        {this.props.sortOrder == "asc" ? (
                          <span
                            className={this.state.faCustomAsc3}
                            title={this.props.tr["Ascending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        ) : (
                          <span
                            className={this.state.faCustomDesc3}
                            title={this.props.tr["Descending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      id="workitemDiv"
                      className="colSearDv multiSelectObjClass"
                      ref={(e) => (this.multiSelectWorkItemDropdownRef = e)}
                      onClick={() => {this.props.multiSelectOpenFun("serviceBoardWorkItm", true)}}
                    >
                    {console.log("PROPS---",this.props)}
                      <ReactMultiSelectCheckboxes
                        placeholderButtonLabel={this.props.tr["Select"]}
                        rightAligned={true}
                        key={"serviceBoardWorkItm"}
                        options={workItemOptions}
                        value={this.props.selectedWorkItemValue}
                        onChange={this.handleWorkItemChange}
                      />
                    </div>
                  </th>

                  <th>
                    <div
                      className="sortParArr"
                      onClick={() =>
                        this.onSortAsc(
                          this.state.sort,
                          "Status",
                          ascDscToggle,
                          this.state.lastSelectedSearchFilter
                        )
                      }
                    >
                      {this.props.tr["Status"]}
                      <div className="sortArr">
                        {this.props.sortOrder == "asc" ? (
                          <span
                            className={this.state.faCustomAsc4}
                            title={this.props.tr["Ascending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        ) : (
                          <span
                            className={this.state.faCustomDesc4}
                            title={this.props.tr["Descending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      id="statusDiv"
                      onClick={() => {
                        this.setLastSelectedStatusFilter();
                        this.props.multiSelectOpenFun("sericeBoardStatus", true);
                      }}
                      className="colSearDv multiSelectObjClass"
                      ref={(e) => (this.multiSelectStatusDropdownRef = e)}
                    >
                      <MultiSelectCheckboxWithOK
                            options={statusOptions}
                            selectValue={this.props.selectedStatusValue}
                            setSelectedValueFun={
                              this.props.setSelectedStatusValue
                            }
                            keyName={"sericeBoardStatus"}
                            isSelectAllLabel={this.state.isSBStatusAllLabel}
                            tr={this.props.tr}
                            OkSubmitFun={this.multiSelectOkButtonFunc}
                            showCheckBoxOptions={this.handleStatusChange}
                            selectAllLabelFun={this.selectAllLabelFun}
                            multiSelectOpenFun={this.props.multiSelectOpenFun}
                            isOpen={this.props.isStatusOpen}
                            refreshClickFun={this.props.refreshClickFun}
                            isRefreshClick={this.props.isRefreshClick}
                            isFetchingDetails={this.props.isFetchingDetails}
                            lastSelectedStatusFilter={this.state.lastSelectedStatusFilter}
                          />
                    </div>
                  </th>

                  <th>
                    <div
                      className="sortParArr"
                      onClick={() =>
                        this.onSortAsc(
                          this.state.sort,
                          "Category",
                          ascDscToggle,
                          this.state.lastSelectedSearchFilter
                        )
                      }
                    >
                      {this.props.tr["Category"]}
                      <div className="sortArr">
                        {this.props.sortOrder == "asc" ? (
                          <span
                            className={this.state.faCustomAsc5}
                            title={this.props.tr["Ascending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        ) : (
                          <span
                            className={this.state.faCustomDesc5}
                            title={this.props.tr["Descending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      id="categoryDiv"
                      className="colSearDv multiSelectObjClass"
                      ref={(e) => (this.multiSelectCategoryDropdownRef = e)}
                      onClick={() => {
                        this.props.multiSelectOpenFun("serviceBoardCategory", true)
                      }}
                    >
                      <ReactMultiSelectCheckboxes
                        placeholderButtonLabel={this.props.tr["Select"]}
                        rightAligned={true}
                        key={"serviceBoardCategory"}
                        options={categoryOptions}
                        value={this.props.selectedCategoryValue}
                        onChange={this.handleCategoryChange}
                      />
                    </div>
                  </th>

                  <th>
                    <div
                      className="sortParArr"
                      onClick={() =>
                        this.onSortAsc(
                          this.state.sort,
                          "Featured",
                          ascDscToggle,
                          this.state.lastSelectedSearchFilter
                        )
                      }
                    >
                      {this.props.tr["Featured"]}
                      <div className="sortArr">
                        {this.props.sortOrder == "asc" ? (
                          <span
                            className={this.state.faCustomAsc6}
                            title={this.props.tr["Ascending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        ) : (
                          <span
                            className={this.state.faCustomDesc6}
                            title={this.props.tr["Descending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      id="featuredDiv"
                      className="colSearDv multiSelectObjClass"
                      ref={(e) => (this.multiSelectFeaturedDropdownRef = e)}
                      onClick={() => {this.props.multiSelectOpenFun("serviceBoardFeatured", true)}}
                    >
                      <ReactMultiSelectCheckboxes
                        placeholderButtonLabel={this.props.tr["Select"]}
                        rightAligned={true}
                        key={"serviceBoardFeatured"}
                        options={featuredOptions}
                        value={this.props.selectedFeaturedValue}
                        onChange={this.handleFeaturedChange}
                      />
                    </div>
                  </th>

                  <th>
                    <div
                      className="sortParArr"
                      onClick={() =>
                        this.onSortAsc(
                          this.state.sort,
                          "modifiedDate",
                          ascDscToggle,
                          this.state.lastSelectedSearchFilter
                        )
                      }
                    >
                      {this.props.tr["Last Modified On"]}
                      <div className="sortArr">
                        {this.props.sortOrder == "asc" ? (
                          <span
                            className={this.state.faCustomAsc7}
                            title={this.props.tr["Ascending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        ) : (
                          <span
                            className={this.state.faCustomDesc7}
                            title={this.props.tr["Descending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="colSearDv" onClick={() => this.props.multiSelectOpenFun("allFalse", false)}>
                      <DateRangePicker
                        initialSettings={{
                          autoUpdateInput: false,
                          maxDate: moment(),
                          autoApply: true,
                          parentEl:"#skipdv"
                        }}
                        onApply={(e, picker) => this.handleSearch(e, picker)}
                        onCallback={(start, end) =>
                          this.handleCallback("modifiedOn", start, end)
                        }
                      >
                        <input
                          type="text"
                          value={myModifiedNewDate}
                          placeholder={this.props.tr["Search here"]}
                          readOnly
                          className="form-control col-4"
                          onPaste={this.handleKeyPress}
                          onKeyDown={this.handleKeyPress}
                        />
                      </DateRangePicker>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.spcmReducer.serviceListData == undefined ||
                this.props.isFetchingDetails ? (
                  <tr>
                    <td colSpan="9" style={{ textAlign: "center" }}>
                      <ListLoader />
                    </td>
                  </tr>
                ) : this.props.spcmReducer.serviceListData.length == 0 ? (
                  <tr>
                    <td colSpan="9" style={{ textAlign: "center" }}>
                      {this.props.tr["There is no matching data available"]}
                    </td>
                  </tr>
                ) : (
                  this.renderTable(this.props.spcmReducer.serviceListData)
                )}
              </tbody>
            </Table>
          </div>

          <div className="nBotPagina">
            <div className="nShow">
              <div className="margin-r-10">{this.props.tr["Show"]}:</div>
              <Form.Select
                componentClass="select"
                value={this.props.size}
                onChange={this.onPageSizeChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </Form.Select>
            </div>
            <nav aria-label="Pagination" className="display-inline-block">
              <Pagination
                prevPageText="Prev"
                nextPageText="Next"
                firstPageText={<i className="glyphicon glyphicon-menu-left" />}
                lastPageText={<i className="glyphicon glyphicon-menu-right" />}
                activePage={meta ? meta.currentPage : 1}
                itemsCountPerPage={this.props.size}
                totalItemsCount={meta ? meta.rowCount : 1}
                pageRangeDisplayed={panelColSize <= 42 ? 2 : 5}
                onChange={(e) => {this.onPageChange(e); this.props.multiSelectOpenFun("allFalse", false);}}
              />
            </nav>
          </div>
        </div>
      </div>
      //   </div>
    );
  }
}



const mapStateToProps = ({ spcmReducer, categoryBoardList, isFetchingDetails }) => {
	return {
		spcmReducer, categoryBoardList, isFetchingDetails: isFetchingDetails.isFetching
	}
};


export default connect(mapStateToProps, { getAllServicesList, getAllServiceListForSearch, getAuditHistory, getReviews, getChainEntitiesCompanyList, getAllCompanyList,getServiceDetailsDataList, loadCategoryList })(ServiceList);



