
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import axios from 'axios';
import { jsPDF } from "jspdf";
import { sanitizeElements, sanitizeHTMLForDocExport } from '../../../common/helper';

const getPlanData = (obj) => {
    try{
        return new Promise((resolve, reject) => {
            const { changeId = '' } = obj || {};
            axios
            .get(`/api/getImplementationPlanList?module=ChangeManagement&itemId=${changeId}`, {timeout: 7000})
            .then(res => {
                // console.log('getPlanData res - ', res);
                let result = {};
                if(res?.data && Array.isArray(res.data) && res.data.length > 0){
                    (res.data).forEach(item => {
                        let type = (item['planType'] && typeof item['planType'] == 'string') ? item['planType'].toLowerCase() : '';
                        if(type && ['backout', 'implementation'].includes(type)){
                            result[[type]] = item['planDetail'];
                        }
                    });
                }
                resolve(result);
            })
            .catch(err => {
                console.error(err);
                // return err;
                // reject('error');
                resolve('');
            });
        });
    }catch(e){
        console.error(e);
        // return Promise.reject('error');
        return Promise.resolve('');
    }
}

const getTaskData = (obj) => {
    try{
        return new Promise((resolve, reject) => {
            const { changeId = '', consumerCompany = '' } = obj || {};
            let insideParams =  JSON.stringify({"workItemId":[changeId],"applicationName":"XSM","workItemType":['ChangeManagement'],"moduleName":'ChangeManagement',"clientId":consumerCompany,"orderBY":"sequenceNumber asc , taskId asc","requestType":"viewTask" });
            let config = {
                timeout: 7000,
                headers: {
                'X-IBM-Client-Id': '5a78384c-5316-42ad-a23e-56bc2eb76d7d ',
                'query': insideParams
                }
            };
            axios
            .get('/api/tasks/', config)
            .then(res => {
                // console.log('getTaskData res - ', res);
                let result = '';
                if(res?.data && Array.isArray(res.data) && res.data.length > 0){
                    (res.data).forEach(item => {
                        result += `<tr><td class="wwrap">${item['taskCode'] || ''}</td><td class="wwrap">${item['sequenceNumber'] || ''}</td><td class="wwrap">${item['description'] || ''}</td><td class="wwrap">${item['additionalParams'] || ''}</td><td class="wwrap">${item['statusName'] || ''}</td><td class="wwrap">${item['createdDate'] || ''}</td><td class="wwrap">${item['actualEndDate'] || ''}</td><td class="wwrap">${item['closeNotes'] || ''}</td></tr>`;
                    });
                }
                else{
                    result = '<tr style="text-align: center;"><td colspan="8">No data available.</td></tr>';
                }
                resolve(result);
            })
            .catch(err => {
                console.error(err);
                // return err;
                // reject('error');
                resolve('<tr style="text-align: center;"><td colspan="8">No data available.</td></tr>');
            });
        });
    }catch(e){
        console.error(e);
        // return Promise.reject('error');
        return Promise.resolve('<tr style="text-align: center;"><td colspan="8">No data available.</td></tr>');
    }
}

const getAuditLogData = (obj) => {
    try{
        return new Promise((resolve, reject) => {
            const { changeId = '', date_format = '' } = obj || {};
            let url = `/serviceManagement/rest/auditAction?itemId=${changeId}&orderBy=desc&module=ChangeManagement`;
            axios
            .get(url, {timeout: 7000})
            .then(res => {
                // console.log('getAuditLogData res - ', res);
                let result = '';
                if(res?.data && Array.isArray(res.data) && res.data.length > 0){
                    (res.data).forEach(item => {
                        let update = '';
                        if(item['auditField'] && typeof item['auditField'] == 'string'){
                            if(item['auditField'] == 'CREATE'){
                                update = 'Created';
                            }
                            else{
                                let type = item['auditField'].split(' ').map(item => ( item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() )).join(' ');
                                if(type == 'Tag'){
                                    let fromUpdate = '';
                                    let toUpdate = '';
                                    let fromArr = ( item['from'] && typeof item['from'] == 'string' && Array.isArray(JSON.parse(item['from'])) ) ? JSON.parse(item['from']) : [];
                                    let toArr = ( item['to'] && typeof item['to'] == 'string' && Array.isArray(JSON.parse(item['to'])) ) ? JSON.parse(item['to']) : [];
                                    if(fromArr && Array.isArray(fromArr) && (fromArr).length>0){
                                        let arr = [];
                                        fromArr.forEach((x)=>{
                                            arr.push((`${x.tag_name}: ${x.tag_value}`));
                                        });
                                        if(arr.length > 0){
                                            fromUpdate = `( ${arr.join(', ')} )`;
                                        }
                                    }
                                    if(toArr && Array.isArray(toArr) && toArr.length>0){
                                        let arr = [];
                                        toArr.forEach((x)=>{
                                            arr.push((`${x.tag_name}: ${x.tag_value}`));
                                        });
                                        if(arr.length > 0){
                                            toUpdate = `( ${arr.join(', ')} )`;
                                        }
                                    }
                                    update = `${type} changed from ${fromUpdate || 'NA'} to ${toUpdate || 'NA'}`;
                                }else{
                                    update = `${type} changed from ${item['from'] || '-'} to ${item['to'] || '-'}`;
                                }
                            }
                        }
                        result += `<tr><td class="wwrap">${item['createdByName'] || ''}</td><td class="wwrap">${item['createdOn']}</td><td class="wwrap">${update}</td></tr>`;
                    });
                }
                else{
                    result = '<tr style="text-align: center;"><td colspan="3">No data available.</td></tr>';
                }
                resolve(result);
            })
            .catch(err => {
                console.error(err);
                // return err;
                // reject('error');
                resolve('<tr style="text-align: center;"><td colspan="3">No data available.</td></tr>');
            });
        });
    }catch(e){
        console.error(e);
        // return Promise.reject('error');
        return Promise.resolve('<tr style="text-align: center;"><td colspan="3">No data available.</td></tr>');
    }
}

const getActivityLogData = (obj) => {
    try{
        return new Promise((resolve, reject) => {
            const { changeId = '', date_format = '' } = obj || {};
            let url = `/serviceManagement/rest/activityAction?itemId=${changeId}&orderBy=desc&requireStatusValue=Y&module=ChangeManagement`;
            axios
            .get(url, {timeout: 7000})
            .then(res => {
                // console.log('getActivityLogData res - ', res);
                let result = '';
                let data = [];
                if(res?.data && Array.isArray(res.data) && res.data.length > 0){
                    data = res.data;
                    (res.data).forEach(item => {
                        let update = '';
                        if(item['description'] && typeof item['description'] == 'string'){
                            if(item['description'] == 'Changed Status'){
                                update = `${item['description']} from ${item['from'] || '-'} to ${item['to'] || '-'}`;
                            }
                            else{
                                update = item['description'];
                            }
                        }
                        result += `<tr><td class="wwrap">${item['createdByName'] || ''}</td><td class="wwrap">${item['createdOn']}</td><td class="wwrap">${update}</td></tr>`;
                    });
                }
                else{
                    result = '<tr style="text-align: center;"><td colspan="3">No data available.</td></tr>';
                }
                resolve({result, data});
            })
            .catch(err => {
                console.error(err);
                // return err;
                // reject('error');
                resolve('<tr style="text-align: center;"><td colspan="3">No data available.</td></tr>');
            });
        });
    }catch(e){
        console.error(e);
        // return Promise.reject('error');
        return Promise.resolve('<tr style="text-align: center;"><td colspan="3">No data available.</td></tr>');
    }
}

const getPIReviewData = (obj) => {
    try{
        return new Promise((resolve, reject) => {
            const { changeId = '' } = obj || {};
            axios
            .get(`/serviceManagement/rest/changeReviewActionByChangeId?changeId=${changeId}`, {timeout: 7000})
            .then(res => {
                // console.log('getPIReviewData res - ', res);
                let result = {};
                if(res?.data && Array.isArray(res.data) && res.data.length > 0){
                    result = res.data[0];
                }
                resolve(result);
            })
            .catch(err => {
                console.error(err);
                // return err;
                // reject('error');
                resolve({});
            });
        });
    }catch(e){
        console.error(e);
        // return Promise.reject('error');
        return Promise.resolve({});
    }
}

const getDependentData_KA = (obj) => {
    try{
        const promises = [ getPlanData(obj), getTaskData(obj), getAuditLogData(obj), getActivityLogData(obj), getPIReviewData(obj) ];
        return Promise.allSettled(promises)
        .then(res => {
            // console.log('res - ', res);
            return Promise.resolve(res);
        })
        .catch(err => {
            console.error(err);
            return Promise.resolve('error');
        });
    }catch(e){
        console.error(e);
        return Promise.resolve('error');
    }
}

export const exportDoc = async (type = '', obj = {}) => {
    try{
        if(!type){
            throw new Error('Error in exporting document');
        }

        // console.log('*************', obj);
        const { changeNumber = '',
            requestNumber = '',
            consumerCompanyName = '',
            requesterName = '',
            status = '',
            typeName = '',
            reasonForChangeName = '',
            ciName = '',
            cmdbUpdateNeeded = '',
            ciClassName = '',
            urgencyName = '',
            cabDate = '',
            summary = '',
            description = '',
            changeGroupname = '',
            changeUsername = '',
            expectedStartDate = '',
            expectedEndDate = '',
            downtimeRequired = '',
            startDate = '',
            endDate = '',
            impGroupname = '',
            impUsername = '',
            residualRisk = '',
            riskAssessment = [],
            date_format = ''
        } = obj || {};
        let riskMgmt = '';
        let riskMgmtMethodology = '';
        if(riskAssessment && Array.isArray(riskAssessment) && riskAssessment.length > 0){
            riskMgmtMethodology = riskAssessment[0]['methodologyName'];
            if(riskMgmtMethodology == 'GBP'){
                let arr = [];
                riskAssessment.forEach(item => {
                    let str = `(Risk Category: ${item['riskCategoryName']}, Risk Type: ${item['riskTypeName']}, Risk: ${item['overallRisk']})`;
                    arr.push(str);
                });
                riskMgmt = arr.join(', ');
            }
            if(riskMgmtMethodology == 'Questionnaire'){
                let arr = [];
                riskAssessment.forEach(item => {
                    let str = `(Question: ${item['questionName']}, Answer: ${item['answerName']})`;
                    arr.push(str);
                });
                riskMgmt = arr.join(', ');
            }
        }
        let plansData = {};
        let taskData = '';
        let auditLogData = '';
        let activityLogData = '';
        let pirData = {};
        const dataRes = await getDependentData_KA(obj);
        // console.log('dataRes - ', dataRes);

        if(dataRes && Array.isArray(dataRes)){
            if(dataRes[0] && dataRes[0]?.value){
                plansData = dataRes[0]?.value || {};
            }
            if(dataRes[1] && dataRes[1]?.value){
                taskData = dataRes[1]?.value || '';
            }
            if(dataRes[2] && dataRes[2]?.value){
                auditLogData = dataRes[2]?.value || '';
            }
            if(dataRes[3] && dataRes[3]?.value){
                let obj = dataRes[3]?.value || {};
                activityLogData = obj['result'] || '';
                if(obj['data'] && Array.isArray(obj['data']) && obj['data'].length > 0){
                    obj['data'].forEach(x => {
                        if(x['description'] && x['description'].includes('Closure Code:') == true && x['description'].includes('Closure Notes:') == true){
                            let temp = x['description'].split('Closure Notes: ');
                            if(temp && temp.length == 2){
                                pirData = {...pirData, ...{closureNotes: temp[1]}};
                            }
                        }
                    });
                }
            }
            if(dataRes[4] && dataRes[4]?.value){
                pirData = {...pirData, ...dataRes[4]?.value};
            }
        }

        // return false;

        let htmlData = `<head>
            <meta charset='utf-8'>
            <style>
            #exportDocument table {
                font-family: arial, sans-serif;
                border-collapse: collapse;
                width: 100%;
            }

            #exportDocument td,
            #exportDocument th {
                border: 1px solid #000000;
                text-align: left;
                padding: 8px;
            }
            #exportDocument .wwrap {
                word-wrap: break-word;
            }
            #exportDocument .tbStyle {
                margin-left: auto;
                margin-right: auto;
                table-layout: fixed;
            }
            #exportDocument .w40 {
                width: 40%;
            }
            #exportDocument .w50 {
                width: 50%;
            }
            #exportDocument .w60 {
                width: 60%;
            }
            #exportDocument .w70 {
                width: 70%;
            }
            #exportDocument .tbHeading {
                width: 100%;
                text-align: center;
                font-weight: bold;
                background-color: #d0cece;
            }
            #exportDocument .fontBold {
                font-weight: bold;
            }
            </style>
            <style>
            @page WordSection1{size: 841.95pt 595.35pt;mso-page-orientation: landscape;}
            div.WordSection1 {page: WordSection1;}
            table{border-collapse:collapse;}td{border:1px gray solid;width:5em;padding:2px;}
            </style>
        </head>
        <body>
            <div id="exportDocument" class="WordSection1">
                <div style="text-align: center"><h2>Change Report</h2></div>

                <br /><br /><br />
        
                <div>
                <table class="tbStyle w50">
                    <tr>
                    <td class="wwrap w50">Request ID >> Change ID</td>
                    <td class="wwrap w50">${requestNumber + ' >> ' + changeNumber}</td>
                    </tr>
                    <tr>
                    <td class="wwrap w50">Company</td>
                    <td class="wwrap w50">${consumerCompanyName || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap w50">Requestor</td>
                    <td class="wwrap w50">${requesterName || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap w50">Status</td>
                    <td class="wwrap w50">${status || ''}</td>
                    </tr>
                </table>
                </div>
        
                <br /><br /><br />
        
                <div>
                <table class="tbStyle w70">
                    <tr>
                    <td colspan="2" class="tbHeading">Ticket Details</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Change Type</td>
                    <td class="wwrap w60">${typeName || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Impacted CI</td>
                    <td class="wwrap w60">${ciName || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Class</td>
                    <td class="wwrap w60">${ciClassName || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Reason for Change</td>
                    <td class="wwrap w60">${reasonForChangeName || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">CMDB Update Needed</td>
                    <td class="wwrap w60">${cmdbUpdateNeeded || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Urgency</td>
                    <td class="wwrap w60">${urgencyName || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">CAB Date</td>
                    <td class="wwrap w60">${cabDate == null || cabDate == '0' || cabDate == 0 ? '' : cabDate}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Summary</td>
                    <td class="wwrap w60">${summary || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Description</td>
                    <td class="wwrap w60">${description || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Change Management Group</td>
                    <td class="wwrap w60">${changeGroupname || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Individual</td>
                    <td class="wwrap w60">${changeUsername || ''}</td>
                    </tr>
                </table>
                </div>
        
                <br /><br /><br />
        
                <div>
                <table class="tbStyle w50">
                    <tr>
                    <td colspan="2" class="tbHeading">Implementation Details</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Scheduled Start Date</td>
                    <td class="wwrap w60">${expectedStartDate == null || expectedStartDate == '0' || expectedStartDate == 0 ? '' : expectedStartDate}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Scheduled End Date</td>
                    <td class="wwrap w60">${expectedEndDate == null || expectedEndDate == '0' || expectedEndDate == 0 ? '' : expectedEndDate}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Downtime Required</td>
                    <td class="wwrap w60">${downtimeRequired || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Downtime Start Date</td>
                    <td class="wwrap w60">${startDate == null || startDate == '0' || startDate == 0 ? '' : startDate}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Downtime End Date</td>
                    <td class="wwrap w60">${endDate == null || endDate == '0' || endDate == 0 ? '' : endDate}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Implementation Group</td>
                    <td class="wwrap w60">${impGroupname || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Individual</td>
                    <td class="wwrap w60">${impUsername || ''}</td>
                    </tr>
                </table>
                </div>
        
                <br /><br /><br />
        
                ${(residualRisk) ? `<div>
                <table class="tbStyle w50">
                    <tr>
                    <td colspan="2" class="tbHeading">Risk Assessment</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Methodology</td>
                    <td class="wwrap w60">${riskMgmtMethodology || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Residual Risk</td>
                    <td class="wwrap w60">${residualRisk || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Risk Assessment</td>
                    <td class="wwrap w60">${riskMgmt || ''}</td>
                    </tr>
                </table>
                </div>
        
                <br /><br /><br />` : ''}
        
                <div>
                <table class="tbStyle w50">
                    <tr>
                    <td colspan="2" class="tbHeading">Plans</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Backout Plan</td>
                    <td class="wwrap w60">${plansData['backout'] || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Implementation Plan</td>
                    <td class="wwrap w60">${plansData['implementation'] || ''}</td>
                    </tr>
                </table>
                </div>
        
                <br /><br /><br />
        
                <div>
                <table class="tbStyle w70">
                    <tr>
                    <td colspan="8" class="tbHeading">Task Details</td>
                    </tr>
                    <tr>
                    <th class="wwrap">Task Code</th>
                    <th class="wwrap">Task Sequence</th>
                    <th class="wwrap">Summary</th>
                    <th class="wwrap">Additional Information</th>
                    <th class="wwrap">Status</th>
                    <th class="wwrap">Created On</th>
                    <th class="wwrap">Completed On</th>
                    <th class="wwrap">Completion Notes</th>
                    </tr>
                    ${taskData}
                </table>
                </div>
        
                <br /><br /><br />
        
                ${(Object.keys(pirData).length > 0 && pirData['incRaised']) ? `<div>
                <table class="tbStyle w50">
                    <tr>
                    <td colspan="2" class="tbHeading">Post Implementation Review</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Any Incidents raised due to Change Implementation?</td>
                    <td class="wwrap w60">${pirData['incRaised'] || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Was the Change implemented on Schedule?</td>
                    <td class="wwrap w60">${pirData['chgOnSchedule'] || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Did the Change impact any other services or Cis which was not in the plan?</td>
                    <td class="wwrap w60">${pirData['impact'] || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Were realized risks adequately mitigated?</td>
                    <td class="wwrap w60">${pirData['adqMitigated'] || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Any new risks observed?</td>
                    <td class="wwrap w60">${(pirData['newRisks'] && pirData['newRisks'] == 'Yes') ? (pirData['newRisks'] + ': ' + pirData['detailsOfRisks']) : pirData['newRisks']}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Any new change were required during implementation?</td>
                    <td class="wwrap w60">${pirData['newChangeReq'] || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Closure Code</td>
                    <td class="wwrap w60">${pirData['closureCodes'] || ''}</td>
                    </tr>
                    <tr>
                    <td class="wwrap fontBold w40">Closure Notes</td>
                    <td class="wwrap w60">${pirData['closureNotes'] || ''}</td>
                    </tr>
                </table>
                </div>
        
                <br /><br /><br />` : ''}
        
                <div>
                <table class="tbStyle w70">
                    <tr>
                    <td colspan="3" class="tbHeading">Audit Log</td>
                    </tr>
                    <tr>
                    <th class="wwrap">Update Done By</th>
                    <th class="wwrap">Date/Time of Update</th>
                    <th class="wwrap">Update</th>
                    </tr>
                    ${auditLogData}
                </table>
                </div>
        
                <br /><br /><br />
        
                <div>
                <table class="tbStyle w70">
                    <tr>
                    <td colspan="3" class="tbHeading">Comments/Activity Update</td>
                    </tr>
                    <tr>
                    <th class="wwrap">Update Done By</th>
                    <th class="wwrap">Date/Time of Update</th>
                    <th class="wwrap">Comment</th>
                    </tr>
                    ${activityLogData}
                </table>
                </div>
        
                <br /><br /><br />
            </div>
        </body>`;
        if(type == 'pdf'){

            let htmlStr = `<!DOCTYPE html><html>${htmlData}</html>`;

            let virtualEl = document.createElement('div');

            virtualEl.innerHTML = sanitizeHTMLForDocExport(htmlStr);

            const doc = new jsPDF();

            doc.html(virtualEl, {

                callback: function(doc) {

                    let filename = `Change Report - ${requestNumber}-${changeNumber}.pdf`;

                    doc.save(filename);

                },

                margin: [10, 10, 10, 10],

                // autoPaging: 'text',

                x: 0,

                y: 0,

                width: 190, //target width in the PDF document

                windowWidth: 675, //window width in CSS pixels

                pagesplit: true

            });

        }
        if(type == 'word'){
            let htmlStr = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>${htmlData}</html>`;
            let blob = new Blob(['\ufeff', htmlStr], {
                type: 'application/msword'
            });
            // let url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(htmlStr);
            let url = URL.createObjectURL(blob);
            let filename = `Change Report - ${requestNumber}-${changeNumber}.doc`;
            let downloadLink = document.createElement("a");
            document.body.appendChild(downloadLink);
            
            if(navigator?.msSaveOrOpenBlob ){
                navigator.msSaveOrOpenBlob(blob, filename);
            }else{
                downloadLink.href = url;
                downloadLink.download = filename;
                downloadLink.click();
            }
            document.body.removeChild(downloadLink);

        }
    }catch(e){
        console.error(e);
    }
}