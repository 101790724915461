import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Field, change, formValueSelector } from "redux-form";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { GLOBAL } from "../../../Globals";
import { opsCategoryKeysMap } from "../../../common/helper";

export const CategorizationForm = ({ opsValueKey, opsLabelKey, module, companyId, formName, initialValues, formType = "create", disabled, fieldsStatuses = {}, resetGroupsandIndividual, setTypeAheadEnabled, keysToCheck = [] }) => {
  const [categoryData, setCategoryData] = useState({});
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [showDropDowns, setShowDropDowns] = useState(0);
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const selector = formValueSelector(formName);

  const fields = {
    "field1": "Category",
    "field2": "Sub Category",
    "field3": "Type"
  }

  const allKeysHaveValues = useSelector((state) =>
    keysToCheck.every((key) => Boolean(selector(state, key)))
  );

  useEffect(() => {
  GLOBAL.categorizationErrors = {};
    if (companyId) {
      const str = JSON.stringify({ companyId, module: 'Foundation', propertyId: '35' });
      axios
        .get('/api/getAdminPreferences/', { headers: { 'query': str } })
        .then((res) => {
          const dropDowns = typeof res.data[0]?.value === "string" ? parseInt(res.data[0]?.value, 10) : res.data[0]?.value;
          setShowDropDowns(dropDowns);
          GLOBAL.categorizationDropdowns = dropDowns;
          if (formType === "create" && dropDowns !== 0) {
            dispatch(change(formName, opsCategoryKeysMap[`${opsValueKey}1`], null));
          }
          setCategoryData({})
          for (let i = 1 + 1; i <= showDropDowns; i++) {
            dispatch(change(formName, opsCategoryKeysMap[`${opsValueKey}${i}`], null));
            dispatch(change(formName, opsCategoryKeysMap[`${opsLabelKey}${i}`], null));
          }
        });
    }
  }, [companyId]); 

  const fetchDropdownOptions = async (idx, parent = '') => {

    try {
      if ((idx == 1)) {
        const response = await axios.get(`/api/company_menulist/${companyId}/${module}/${fields[`field${idx}`]}/1/en/0/null`);
        setDropdownOptions((prevOptions) => ({
          ...prevOptions,
          [opsCategoryKeysMap[`${opsValueKey}${idx}`]]: response.data,
        }));
      } else if( parent && idx > 1){
        const response = await axios.get(`/api/company_menulist/${companyId}/${module}/${fields[`field${idx}`]}/1/en/0/${parent}`);
        setDropdownOptions((prevOptions) => ({
          ...prevOptions,
          [opsCategoryKeysMap[`${opsValueKey}${idx}`]]: response.data,
        }));
      }
    } catch (error) {
      console.error(`Error fetching options for dropdown ${idx}:`, error);
    }
  };

  useEffect(() => {
    if (!companyId) return;
  
    if (formType === "edit" && initialValues) {
      setCategoryData(initialValues);
  
      // Fetch options for each dropdown based on initial values
      for (let idx = 1; idx <= showDropDowns; idx++) {
        if (!dropdownOptions[opsCategoryKeysMap[`${opsValueKey}${idx}`]]) {
          const parentValue = initialValues[opsCategoryKeysMap[`${opsValueKey}${idx - 1}`]] || '';
          fetchDropdownOptions(idx, parentValue);
        }
      }
    } else if (formType === "create") {
      fetchDropdownOptions(1); // Fetch only the first dropdown options
    }
  }, [showDropDowns]);

  const handleCategoryChange = (e, optionFieldName, idx) => {
    const { name, value } = e.target;
    
    if (resetGroupsandIndividual) {
        resetGroupsandIndividual();
      }
  
    if (setTypeAheadEnabled && allKeysHaveValues && formType == "create") {
      setTypeAheadEnabled(idx === showDropDowns && value !== "0" ? false : true);
    }
  
    setCategoryData((prevData) => {
      const updatedData = { 
        ...prevData, 
        [name]: value, 
        [optionFieldName]: e.target.options[e.target.selectedIndex].text 
      };
  
      GLOBAL.categorizationErrors = { ...GLOBAL.categorizationErrors, [name]: value && value !== "0" ? "" : "error" };
  
      // Clear subsequent dropdowns
      for (let i = idx + 1; i <= showDropDowns; i++) {
        delete updatedData[opsCategoryKeysMap[`${opsValueKey}${i}`]];
        delete updatedData[opsCategoryKeysMap[`${opsLabelKey}${i}`]];
      }
  
      return updatedData;
    });
  
    setDropdownOptions((prevOptions) => {
      const updatedOptions = { ...prevOptions };
  
      // Clear subsequent dropdowns
      for (let i = idx + 1; i <= showDropDowns; i++) {
        updatedOptions[opsCategoryKeysMap[`${opsValueKey}${i}`]] = [];
      }
  
      return updatedOptions;
    });
  
    // Update Redux form state
    dispatch(change(formName, name, value));
    dispatch(change(formName, optionFieldName, e.target.options[e.target.selectedIndex].text));
    for (let i = idx + 1; i <= showDropDowns; i++) {
      dispatch(change(formName, opsCategoryKeysMap[`${opsValueKey}${i}`], null));
      dispatch(change(formName, opsCategoryKeysMap[`${opsLabelKey}${i}`], null));
    }
  
    // Fetch options for the next dropdown only if necessary
    if (value && idx < showDropDowns) {
      fetchDropdownOptions(idx + 1, value);
    }
  };

  return (
    showDropDowns > 0 && companyId && (
      <div>
        <div className="margin-t-5 padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
          <div className="bgGray padding-5">
            <span className="rStar"></span>
            {tr[`Operational Categorization`]}
          </div>
          <Row md={showDropDowns==1 || showDropDowns==2 ? 2 : 3}>
            {Array.from({ length: showDropDowns }, (_, index) => index + 1).map((idx) => (
              <Col key={idx}>
                <Form.Group className="form-group">
                  <Form.Label>
                    <span className="rStar"></span>
                    {tr[`Operational category ${idx}`]}
                  </Form.Label>
                  <Field
                    name={opsCategoryKeysMap[`${opsValueKey}${idx}`]}
                    component="select"
                    type="select"
                    className={`form-control ${GLOBAL.categorizationErrors[opsCategoryKeysMap[`${opsValueKey}${idx}`]] ? "error" : ""}`}
                    value={categoryData[opsCategoryKeysMap[`${opsValueKey}${idx}`]] || ''}
                    onChange={(e) => handleCategoryChange(e, opsCategoryKeysMap[`${opsLabelKey}${idx}`], idx)}
                    disabled={disabled ? true : fieldsStatuses[opsCategoryKeysMap[`${opsValueKey}${idx}`]] === true ? false : (fieldsStatuses[opsCategoryKeysMap[`${opsValueKey}${idx}`]] === undefined ? false : true)}
                  >
                    <option value="0">Select</option>
                    {(dropdownOptions?.[opsCategoryKeysMap[`${opsValueKey}${idx}`]] || []).map((option, optionIdx) => (
                      <option key={optionIdx} value={option?.field1Value}>
                        {option?.field1Key}
                      </option>
                    ))}
                  </Field>
                </Form.Group>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    )
  );
};

export const CategorizationResolutionForm = ({opsValueKey, opsLabelKey, module, companyId, formName, showDropDowns = 0, parentValue }) => {
  const [categoryData, setCategoryData] = useState({});
  const [dropdownOptions, setDropdownOptions] = useState({});
  const dispatch = useDispatch();

  const labels = {
    label1: "Resolution Category",
    label2: "Resolution Sub Category",
    label3: "Resolution Type"
  }

  const fetchDropdownOptions = async (idx, parent) => {
    try {
          const response = await axios.get(`/api/company_menulist/${companyId}/${module}/${labels[`label${idx}`]}/1/en/0/${parent}`);
          setDropdownOptions((prevOptions) => ({
          ...prevOptions,
          [`${opsValueKey}${idx}`]: response.data,
        }));
    } catch (error) {
      console.error(`Error fetching options for dropdown ${idx}:`, error);
    }
  };

  useEffect(() => {
    if(parentValue && parentValue != '0'){
      fetchDropdownOptions(1, parentValue);
    }
  }, [showDropDowns, parentValue]);

  const handleCategoryChange = (e, optionFieldName, idx) => {
    const { name, value } = e.target;
 
    setCategoryData((prevData) => {
      const updatedData = { 
        ...prevData, 
        [name]: value, 
        [optionFieldName]: e.target.options[e.target.selectedIndex].text 
      };

      // Clear subsequent dropdowns
      for (let i = idx + 1; i <= showDropDowns; i++) {
        delete updatedData[`${opsValueKey}${i}`];
        delete updatedData[`${opsLabelKey}${i}`];
      }
  
      return updatedData;
    });
  
    setDropdownOptions((prevOptions) => {
      const updatedOptions = { ...prevOptions };
  
      // Clear subsequent dropdowns
      for (let i = idx + 1; i <= showDropDowns; i++) {
        updatedOptions[`${opsValueKey}${i}`] = [];
      }
  
      return updatedOptions;
    });
  
    // Update Redux form state
    dispatch(change(formName, name, value));
    dispatch(change(formName, optionFieldName, e.target.options[e.target.selectedIndex].text));
    for (let i = idx + 1; i <= showDropDowns; i++) {
      dispatch(change(formName, `${opsValueKey}${i}`, null));
      dispatch(change(formName, `${opsLabelKey}${i}`, null));
    }
  
    // Fetch options for the next dropdown only if necessary
    if (value && idx < showDropDowns) {
      fetchDropdownOptions(idx + 1, value);
    }
  };

  return (
    showDropDowns > 0 && (
      <div>
          <Row md={1}>
            {Array.from({ length: showDropDowns }, (_, index) => index + 1).map((idx) => (
              <Col key={idx}>
                <Form.Group className="form-group">
                  <Form.Label>
                    <span className="rStar"></span>
                    {labels[`label${idx}`]}
                  </Form.Label>
                  <Field
                    name={`${opsValueKey}${idx}`}
                    component="select"
                    type="select"
                    className={`form-control`}
                    value={categoryData[`${opsValueKey}${idx}`] || ''}
                    onChange={(e) => handleCategoryChange(e, `${opsLabelKey}${idx}`, idx)}
                  >
                    <option value="0">Select</option>
                    {(dropdownOptions?.[`${opsValueKey}${idx}`] || []).map((option, optionIdx) => (
                      <option key={optionIdx} value={option?.field1Value}>
                        {option?.field1Key}
                      </option>
                    ))}
                  </Field>
                </Form.Group>
              </Col>
            ))}
          </Row>
      </div>
    )
  );
};