
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Button } from "react-bootstrap";
import {
  _textArea,
  _dropDown,
  _inputField,
  _number,
  _radio,
} from "../../../../components/common/formFields";
import { _textAreaMaxlength2 } from "../../../common/ReduxFormFields/formFields.js";
import axios from "axios";
import { GLOBAL } from "../../../Globals";
const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

import { connect } from "react-redux";
import { Row, Col, Form, ButtonToolbar } from "react-bootstrap";
import { Field, reduxForm, change, SubmissionError } from "redux-form";
import swal from "sweetalert";

import Cookies from "universal-cookie";
import { navigationHooks } from "../../../../helpers/NavigationHook";
import {IoClose} from "react-icons/io5";
import { getFormValues } from 'redux-form';
import { taskStatusMapping } from "../../../common/helper.js";
const cookies = new Cookies();

let full_name = cookies.get("gph");
if (full_name) full_name = full_name.replace("s:", "");
if (full_name) full_name = full_name.substring(0, full_name.lastIndexOf("."));
full_name = full_name.split("~");
full_name = full_name[42];

let user_id = cookies.get("gph");
if (user_id) user_id = user_id.replace("s:", "");
if (user_id) user_id = user_id.substring(0, user_id.lastIndexOf("."));
user_id = user_id.split("~");
user_id = user_id[43];

const validate = (values) => {
  let errors = {};

  if (!values.closeNotes) {
    errors.closeNotes = "Enter Notes";
  }
  if (!values.closingStatus) {
    errors.closingStatus = "Please Select Sub Status";
  }

  return errors;
};
const required = (value) => (value ? undefined : "Required");

let CompletedTask = class CompletedTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayRight: false,
      disableSubmit: false,
      closingStatusError: false
    };
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(
      change("CompletedForm", "closeNotes", this.props.taskDetails.closeNotes)
    );
    this.props.dispatch(
      change("CompletedForm", "closingStatus", this.props.taskDetails.closingStatus)
    )
    // if (this.props.taskDetails.statusName == 'Completed')
    //   this.setState({ disableSubmit: true });
    console.log("Previous status ", this.props.previousStatus);
  }

  submitForm(values) {
    const { reset, showProcess } = this.props;
    let ref = this;
    // console.log(this.props.taskDetails);
    // console.log(this.props);
    console.log(this.props.taskDetails);
    console.log("Close Notes -------------");
    ////ApiCall
    let aed = "";
    let actualEndDate = "";
    if (
      this.props.previousStatusId != this.props.taskDetails.statusId &&
      this.props.previousStatusId === 2
    ) {
      aed = moment().format("YYYY-MM-DD hh:mm A");
    }
    if (
      this.props.previousStatus !== this.props.taskDetails.status &&
      this.props.previousStatusId === 1 &&
      this.props.taskDetails.statusId === 4 &&
      this.props.taskDetails.actualStartDate != null
    ) {
      aed = moment().format("YYYY-MM-DD hh:mm A");
    }
    actualEndDate = aed.length != 0 ? moment.utc(moment(aed)).format() : "";
    const isChangeImplementation = (this.props.taskDetails.workItemType==='Change' || this.props.taskDetails.workItemType==='ChangeManagement') &&  (this.props.taskDetails.eventId == '1');
    const reviewEnabled = this.props.adminPreferencesListData && isChangeImplementation;
    const isLoggedIn_as_Reviewer = this.props.user_id==this.props.editTaskFormValues.reviewerId;
    const isLoggedIn_as_Assignee = this.props.user_id==this.props.editTaskFormValues.assignedToId;

    if(!values?.closingStatus && this.props.taskStatusId == "3"){
      this.setState({closingStatusError: true})
      return;
    } else {
      this.setState({closingStatusError: false})
    }

    if(reviewEnabled && this.props.previousStatusId=='2' && this.props.taskDetails.statusId == "8" && (this.props.editTaskFormValues.reviewerId=='' || typeof this.props.editTaskFormValues.reviewerId=='undefined' || this.props.editTaskFormValues.reviewerId==null)){
      swal({
        text: this.props.translator['Please assign the reviewer before submitting for Review'],
        button: this.props.translator["OK"]
      });
      this.props.setReviewerErrorParent('error');
      return;
    }
    
    if(reviewEnabled && this.props.previousStatusId=='2' && this.props.taskDetails.statusId == taskStatusMapping['In Review'] && this.props.editTaskFormValues.reviewerId==this.props.user_id){
      swal({
         text: this.props.translator['Assignee and Reviewer cannot be the same user'],
         button: this.props.translator["OK"]
      });
      return;
    }
    
    if(reviewEnabled && ((this.props.previousStatusId=='2' && this.props.taskDetails.statusId == taskStatusMapping['In Review']) || (this.props.previousStatusId=='9' && this.props.taskDetails.statusId == taskStatusMapping['Completed'])) && !isLoggedIn_as_Assignee){
      swal({
        text: this.props.translator['Please assign the ticket to yourself before changing the status of the ticket'],
        button: this.props.translator["OK"]
      });
      return;
    }
    
    if(reviewEnabled && this.props.previousStatusId=='8' && (this.props.taskDetails.statusId== taskStatusMapping['Reviewed'] || this.props.taskDetails.statusId== taskStatusMapping['In Progress']) && !isLoggedIn_as_Reviewer){
      swal({
        text: this.props.translator['You are not allowed to Change the status of ticket, as You are not Reviewer'],
        button: this.props.translator["OK"]
      });
      return;
    } 
    
    if ((this.props.user_id != this.props.assignedToId) && !(this.props.previousStatusId=='8')) {
      swal({
        text: this.props.translator[
          "Please assign the ticket to yourself before changing the status of the ticket"
        ],
        button: this.props.translator["OK"],
      });
      return false;
    }
    if(this.props.user_id != this.props.editTaskFormValues.assignedToId && (this.props.previousStatusId != '8')){
      swal({
        text: this.props.translator[
          "Please assign the ticket to yourself before changing the status of the ticket"
        ],
        button: this.props.translator["OK"],
      });
      return false;
    }
    ref.setState({ disableSubmit: true });
    console.log("this.props.taskDetails", this.props.taskDetails);
    axios
      .patch(GLOBAL.editTaskUrl + this.props.taskDetails.taskCode, {
        statusId: this.props.taskDetails.statusId,
        statusName: this.props.taskDetails.statusName,
        taskCode: this.props.taskDetails.taskCode,
        closeNotes: values.closeNotes,
        closingStatus: values.closingStatus,
        clientId: this.props.taskDetails.clientId,
        applicationName: "XSM",
        moduleName: "Breakfix",
        assignedTo: this.props.editTaskFormValues.assignedTo,
        assignedToId: this.props.editTaskFormValues.assignedToId,
        actualEndDate: actualEndDate,
        reviewerId: this.props.editTaskFormValues.reviewerId==''?0:this.props.editTaskFormValues.reviewerId,
        reviewer: this.props.editTaskFormValues.reviewerName
      })
      .then((response) => {
        if (response.status == 200) {
          // return api.post(GLOBAL.breakFixActivityLogPostUrl, {
          //   "itemId": this.props.taskDetails.taskidforactivity,
          //   "description": "Close notes:"+values.closeNotes,
          //   "isInternal": 'N',
          //   "createdOn": "",
          //   "createdBy": "",
          //   "module": "Task",
          //   "createdByName": ""
          // });
          let isExternal = "";
          console.log("this.props.taskDetails completed task");
          console.log(this.props.taskDetails);
          if (
            this.props.taskDetails.isExternal == null ||
            this.props.taskDetails.isExternal == "" ||
            this.props.taskDetails.isExternal == undefined
          ) {
            isExternal = "false";
          } else {
            isExternal = this.props.taskDetails.isExternal;
          }
          const desc = values.closingStatus ? "Sub Status: " + values.closingStatus + ", " + "Notes: " + values.closeNotes: "Notes: " + values.closeNotes;
          return api.post(GLOBAL.TMSActivityLogUrl, {
            workitemId: this.props.taskDetails.taskidforactivity,
            taskCode: this.props.taskDetails.taskCode,
            module: "Task",
            description: desc,
            isInternal: "N",
            createdBy: user_id,
            createdbyName: full_name,
            isExternal: isExternal,
          });
        }
        ref.setState({ disableSubmit: false });
      })
      .then((response2) => {
        const { changeActionView, refreshAfterNotes } = ref.props;
        changeActionView('processHierarchy');
        refreshAfterNotes();
      })
      .catch(function (error) {
        ref.setState({ disableSubmit: false });
        console.log(error);
      });
  }

  render() {
    const { error, handleSubmit, change, submitting, taskStatusId } = this.props;
    const displayRight = this.state.displayRight;
    if (displayRight === true) {
      return <div></div>;
    } else {
      return (
        <div className="borderForm margin-b-10">
          <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
              <ul>
                  <li>
                  <button
                      type='button'
                      title="Minimize the right panel"
                      bsClass=""
                      bsStyle=""
                      className="closerightPanelBtn"
                      onClick={() => {
                      this.props.rightEditPanel(false);
                      this.props.changeActionView("");
                      }}
                  >
                      <IoClose />
                  </button>
                  </li>
              </ul>
          </div>
          <div className="rPageHeading">
            <div className="offNam margin-t-5 margin-b-5">
              {this.props.translator["Notes"]}
            </div>
          </div>
          <div className="rBoxGap">
            <form onSubmit={handleSubmit(this.submitForm)}>
              {taskStatusId == "3" && <Row>
                <Col md={12}>
                  <Form.Label bsClass="">
                    <span className="rStar" />
                    {this.props.translator["Sub Status"]}
                  </Form.Label>
                  <Field
                    name="closingStatus"
                    component={({ input, meta }) => (
                      <div>
                        <select
                          {...input}
                          className={`form-control ${meta.touched && meta.error ? "error" : ""}`}
                        >
                          <option value="">{this.props.translator["Please Select"]}</option>
                          <option value="Successful">Successful</option>
                          <option value="Unsuccessful">Unsuccessful</option>
                          <option value="Partially Successful">Partially Successful</option>
                        </select>
                        {meta.touched && meta.error && <span className="error-text">{meta.error}</span>}
                      </div>
                    )}
                  />
                </Col>
              </Row>}
              <Row>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label bsClass="">
                      <span className="rStar" />{" "}
                      {this.props.translator["Notes"]}
                    </Form.Label>
                    <Field
                      name="closeNotes"
                      type="textarea"
                      component={_textAreaMaxlength2}
                      label=""
                      className="form-control"
                      rows="2"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <ButtonToolbar className="black">
                    <Button
                      type="submit"
                      bsSize="small"
                      bsStyle="primary"
                      disabled={this.state.disableSubmit}
                    >
                      {this.props.translator["Submit"]}
                    </Button>
                    {/* <Button bsSize="small" bsStyle="text">
                    Button
                  </Button> */}
                  </ButtonToolbar>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      );
    }
  }
};

CompletedTask = reduxForm({
  form: "CompletedForm",
  validate,
})(CompletedTask);

const mapStateToProps = (state, props) => {
  const editTaskFormValues = getFormValues('editTaskForm')(state);
  const adminPreferencesListData = state.admin_preferences_list_data_reducer.adminPreferencesListData.value?state.admin_preferences_list_data_reducer.adminPreferencesListData.value==='True':false;
  return {adminPreferencesListData,editTaskFormValues};
};

export default connect(mapStateToProps, {})(CompletedTask);
