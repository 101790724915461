/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { initialQuery, allPriority, initialCriticalityData, initialServiceBasedData, codeForReducer, incidentStatuses } from "../../../components/common/helper"; 

export const statusData = (state = [], action) => {
   return codeForReducer(action, 'SET_ALL_STATUSES', state);
}

export const priorityData = (state = allPriority, action) => {
      return state;
}

export const cimGroupData = (state = [], action) => {
   return codeForReducer(action, 'SET_ALL_CIM_GROUPS', state);
}

export const supportGroupData = (state = [], action) => {
   return codeForReducer(action, 'SET_ALL_SUPPORT_GROUPS', state);
}

export const serviceData = (state = [], action) => {
   return codeForReducer(action, 'SET_ALL_SERVICES', state);
}

export const criticalityData = (state = initialCriticalityData, action) => {
   return codeForReducer(action, 'SET_ALL_CRITICALITIES', state);  
}

export const serviceBasedData = (state = initialServiceBasedData, action) => {
   return codeForReducer(action, 'SET_ALL_SERVICE_BASED_DATA', state);
}

export const ciData = (state = [], action) => {
   return codeForReducer(action, 'SET_ALL_CIs', state);
}

export const advanceSearchClass = (state = [], action) => {
   return codeForReducer(action, 'SET_ALL_CLASSES', state);
}

export const queryData = (state = initialQuery, action) => {
   return codeForReducer(action, 'PRODUCED_QUERY', state);
}

export const finalQuery = (state = initialQuery, action) => {
   return codeForReducer(action, 'FINAL_QUERY', state);
}

export const slaStatusData = (state = [], action) => { 
   return codeForReducer(action, 'SET_ALL_SLA_COLORS', state); 
}

export const companyList = (state = [], action) => {
   return codeForReducer(action, 'SET_ALL_COMPANIES', state); 
}

export const reportedThroughDataBreakfix = (state = [], action) => {
   return codeForReducer(action, 'SET_ALL_REPORTED_THROUGH_BREAKFIX', state); 
}

export const reportedThroughDataFulfillment = (state = [], action) => {
   return codeForReducer(action, 'SET_ALL_REPORTED_THROUGH_FULFILLMENT', state); 
}

export const urgencyList = (state = [], action) => {
   return codeForReducer(action, 'SET_ALL_URGENCY', state); 
}

export const impactList = (state = [], action) => {
   return codeForReducer(action, 'SET_ALL_IMPACT', state); 
}

export const showRunLoader = (state = false, action) => {
   if(action.type === 'ON_RUN_LOADER'){
      return true;
   }else if(action.type === 'OFF_RUN_LOADER'){
      return false;
   }else{
      return state;
   }
}

export const showAiSearchLoader = (state = false, action) => {
   if(action.type === 'ON_AI_SEARCH_LOADER'){
      return true;
   }else if(action.type === 'OFF_AI_SEARCH_LOADER'){
      return false;
   }else{
      return state;
   }
}