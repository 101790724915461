
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Cookies from "universal-cookie";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import {  Form , Table } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import moment from 'moment';
import _ from 'lodash';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import ReactDOM from "react-dom";
import {IoSearch} from 'react-icons/io5';

import {
  loadCmdbItemList,
  loadCMDBFilterResultsForSearchString,
  resetCMDBQVFilters,
  loadCmdbItemFilterList,
  retriveCMDBStatuses,
  retriveCMDBSubStatuses
} from "../../../actions/cmdb/quickViewAction.js";
import { loadCIClass, getCmdbApprovals, loadCmdbClassBasedList } from '../../../actions/cmdb/lovAction.js';

import ListLoader from "../../common/loaders/ListLoader.js"

import { openLinkInNewTab } from '../../common/helper';

const cookies = new Cookies();
let languageSelected = cookies.get("gph");
languageSelected = languageSelected.split("~");
languageSelected = languageSelected[20];

let loggedInCompanyId = cookies.get("gph");
loggedInCompanyId = loggedInCompanyId.split("~");
loggedInCompanyId = loggedInCompanyId[48];

let app_url = cookies.get("gph");
if (app_url) app_url = app_url.replace("s:", "");
if (app_url) app_url = app_url.substring(0, app_url.lastIndexOf("."));
app_url = app_url.split("~");
app_url = app_url[18];

let cookiesData = cookies.get("gph");
if (cookiesData) cookiesData = cookiesData.replace("s:", "");
if (cookiesData)
  cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf("."));
cookiesData = cookiesData.split("~");

let onConfigManagerRoleVisible = cookiesData[33]
  ? cookiesData[33].split(",")
  : [];
onConfigManagerRoleVisible = onConfigManagerRoleVisible.includes("37");

let onAssetRoleVisible = cookiesData[33] ? cookiesData[33].split(",") : [];
onAssetRoleVisible = onAssetRoleVisible.includes("38");

let onConfigViewerRoleVisible = cookiesData[33]
  ? cookiesData[33].split(",")
  : [];
onConfigViewerRoleVisible = onConfigViewerRoleVisible.includes("39");

class CmdbList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // page: 1,
      // size: 10,
      index: 0,
      sortBy: 'CI_NAME',
      //orderBy: 'desc',
      // searchText: "",
      // searchFilter: "CI_ID",
      isErrorFilterSearch: false,
      assetIdSearchFilter: "",
      nameSearchFilter: "",
      companySearchFilter: "",
      classSearchFilter: "",
      businessOwnerSearchFilter: "",
      technicalOwnerSearchFilter: "",
      locationSearchFilter: "",
      isMultiSelectOpen: false,
      selectObjLeftPos: "0",
      selection: {},
      //isOpen: true,
    };
    this.onPageChange = this.onPageChange.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    // this.getSelectedOrder = this.getSelectedOrder.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.renderCmdbList = this.renderCmdbList.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleClassChange = this.handleClassChange.bind(this);
    this.clickOutside = this.clickOutside.bind(this);
    this.handleSubStatusChange = this.handleSubStatusChange.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.clickOutside);
    this.props.loadCmdbItemList(this.props.page, this.props.size, {}, [], [], [],[]);
    this.props.retriveCMDBStatuses(loggedInCompanyId,'CMDB','Status',languageSelected,'1');
    let status = 'ALL'
    this.props.loadCIClass(status);
    this.props.retriveCMDBSubStatuses(loggedInCompanyId,'CMDB','Sub Status',languageSelected,'1');

  }

  componentDidUpdate(prevProps) {
    if (this.multiSelectTypeDropdownRef != undefined || this.multiSelectTypeDropdownRef != null) {
      ReactDOM.findDOMNode(this.multiSelectTypeDropdownRef).childNodes[0].className = "multiSelectRef";
      let myRef = this.multiSelectTypeDropdownRef;
      if (this.props.isTypeOpen !== prevProps.isTypeOpen) {
        let obj = ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].className = "multiSelectPopup";
            // ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].style = "left:" + this.state.selectObjLeftPos + "px";
        }
      }
    }
    if (this.multiSelectStatusDropdownRef != undefined || this.multiSelectStatusDropdownRef != null) {
      ReactDOM.findDOMNode(this.multiSelectStatusDropdownRef).childNodes[0].className = "multiSelectRef";
      let myRef = this.multiSelectStatusDropdownRef;
      if (this.props.isStatusOpen !== prevProps.isStatusOpen) {
        let obj = ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].className = "multiSelectPopup";
            // ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].style = "left:" + this.state.selectObjLeftPos + "px";
        }
      }
    }
    if (this.multiSelectSubstatusDropdownRef != undefined || this.multiSelectSubstatusDropdownRef != null) {
      ReactDOM.findDOMNode(this.multiSelectSubstatusDropdownRef).childNodes[0].className = "multiSelectRef";
      let myRef = this.multiSelectSubstatusDropdownRef;
      if (this.props.isSubStatusOpen !== prevProps.isSubStatusOpen) {
        let obj = ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].className = "multiSelectPopup";         
        }
      }
    }
    if (this.multiSelectClassDropdownRef != undefined || this.multiSelectClassDropdownRef != null) {
      ReactDOM.findDOMNode(this.multiSelectClassDropdownRef).childNodes[0].className = "multiSelectRef";
      let myRef = this.multiSelectClassDropdownRef;
      if (this.props.isClassOpen !== prevProps.isClassOpen) {
        let obj = ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
          ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].className = "multiSelectPopup";
            // ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].style = "left:" + this.state.selectObjLeftPos + "px";
        }
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.clickOutside);
  }

  clickOutside(event) {
    const typeDiv = document.getElementById("typeDiv");
    const statusDiv = document.getElementById("statusDiv");
    const subStatusDiv = document.getElementById("subStatusDiv");
    // const supportGroupDv = document.getElementById("supportGroupDv");
    const classDiv = document.getElementById("classDiv");

    if (this.multiSelectTypeDropdownRef != undefined || this.multiSelectTypeDropdownRef != null || this.multiSelectStatusDropdownRef != undefined || this.multiSelectStatusDropdownRef != null || this.multiSelectClassDropdownRef != undefined || this.multiSelectClassDropdownRef != null) {
      document.addEventListener("mousedown", (event) => {
        if (typeDiv.contains(event.target)) {          
          this.setState({ isMultiSelectOpen: true });
          this.props.showRightSide(false);
        }
        else if (statusDiv.contains(event.target)) {
          this.setState({ isMultiSelectOpen: true });
          this.props.showRightSide(false);
        }
        else if (subStatusDiv.contains(event.target)) {
          this.setState({ isMultiSelectOpen: true });
          this.props.showRightSide(false);
        }

        else if (classDiv.contains(event.target)) {
          this.setState({ isMultiSelectOpen: true });
          this.props.showRightSide(false);
        }
        else {
          this.setState({ isMultiSelectOpen: false });
        }
        this.props.multiSelectOpenFun("allFalse", false);
      });
    }
  }

  onPageChange(e) {
    const resetSelection = {};
    this.setState({ selection: resetSelection }, () => {
      this.props.updateBulkUpdateData([]);
      this.props.showRightSide(false);
    });
    this.props.setPage(e);
    this.props.loadCmdbItemList(e, this.props.size, this.props.searchParams, this.props.type, this.props.status, this.props.searchClass,this.props.sub_status);
  }

  onPageSizeChange(e) {
    this.props.showRightSide(false);
    this.props.setLimit(e.target.value);
    this.props.loadCmdbItemList(this.props.page, e.target.value, this.props.searchParams, this.props.type, this.props.status, this.props.searchClass,this.props.sub_status);    
    this.props.multiSelectOpenFun("allFalse", false);
  }

  handleSearch(e, picker) {  
    let searchParamsobj = {};
    let dateArr = [];

    this.props.showRightSide(false);
    this.props.isClearAllFilterFunc(true);
    if (this.props.assetIdSearchInput != '') {
      searchParamsobj.ci_code = this.props.assetIdSearchInput
    }
    if (this.props.nameSearchInput != '') {
      searchParamsobj.ci_name = this.props.nameSearchInput
    }
    if (this.props.companySearchInput != '') {
      searchParamsobj.company_name = this.props.companySearchInput
    }
    // if (this.props.classSearchInput != '') {
    //   searchParamsobj.class_name = this.props.classSearchInput
    // }
    if (this.props.serialNumberSearchInput != '') {
      searchParamsobj.serial_number = this.props.serialNumberSearchInput
    }
    if (this.props.businessOwnerSearchInput != '') {
      searchParamsobj.business_owner_name = this.props.businessOwnerSearchInput
    }
    if (this.props.technicalOwnerSearchInput != '') {
      searchParamsobj.technical_owner_name = this.props.technicalOwnerSearchInput
    }
    if (this.props.locationSearchInput != '') {
      searchParamsobj.location_name = this.props.locationSearchInput
    }
    if (picker) {
      this.props.setRef(picker);
      dateArr.push(moment(picker.startDate).format("MM-DD-YYYY"), moment(picker.endDate).format("MM-DD-YYYY"));
      this.props.setStartAndEndDate(picker.startDate.format('MM/DD/YYYY'), picker.endDate.format('MM/DD/YYYY'));
      searchParamsobj.updated_at = dateArr.join('&');
    }

    if (picker == undefined && !_.isEmpty(this.props.startDate) && !_.isEmpty(this.props.endDate)) {
      dateArr.push(moment(this.props.startDate).format("MM-DD-YYYY"), moment(this.props.endDate).format("MM-DD-YYYY"));
      searchParamsobj.updated_at = dateArr.join('&');
    }
    this.props.loadCmdbItemList(this.props.page, this.props.size, searchParamsobj, this.props.type, this.props.status, this.props.searchClass, this.props.sub_status);
    this.props.setSearchParams(searchParamsobj);
  }

  handleTypeChange(value) {
    let searchOnTypeArr = [];
    this.props.setSelectedTypeValue(value);

    (_.isEmpty(this.props.searchParams)) ?
      this.props.status.length == 0 && this.props.searchClass.length == 0 && this.props.sub_status.length ?
        value.length == 0 ?
          this.props.isClearAllFilterFunc(false) :
          this.props.isClearAllFilterFunc(true) :
        this.props.isClearAllFilterFunc(true) :
      this.props.isClearAllFilterFunc(true);
    this.props.showRightSide(false);

    value.map((checkBoxValue) => {
      return (
        searchOnTypeArr.push(checkBoxValue.value)
      );
    }
    );
    this.props.setMultiSelectParams(searchOnTypeArr, this.props.status, this.props.searchClass, this.props.sub_status);
    this.props.loadCmdbItemList(this.props.page, this.props.size, this.props.searchParams, searchOnTypeArr, this.props.status, this.props.searchClass,this.props.sub_status);
  }

  handleStatusChange(value) {
    let searchOnStatusArr = [];
    this.props.setSelectedStatusValue(value);
    (_.isEmpty(this.props.searchParams)) ?
      this.props.type.length == 0 && this.props.searchClass.length == 0 && this.props.sub_status.length ?
        value.length == 0 ?
          this.props.isClearAllFilterFunc(false) :
          this.props.isClearAllFilterFunc(true) :
        this.props.isClearAllFilterFunc(true) :
      this.props.isClearAllFilterFunc(true);

    this.props.showRightSide(false);
    value.map((checkBoxValue) => {
      return (
        searchOnStatusArr.push(checkBoxValue.value)
      );
    }
    );
    this.props.setMultiSelectParams(this.props.type, searchOnStatusArr, this.props.searchClass,this.props.sub_status);
    this.props.loadCmdbItemList(this.props.page, this.props.size, this.props.searchParams, this.props.type, searchOnStatusArr, this.props.searchClass, this.props.sub_status);
  }

  handleClassChange(value) {
    let searchOnClassArr = [];
    this.props.setSelectedClassValue(value);
    (_.isEmpty(this.props.searchParams)) ?
      this.props.type.length == 0 && this.props.status.length == 0 && this.props.sub_status.length == 0 ?
        value.length == 0 ?
          this.props.isClearAllFilterFunc(false) :
          this.props.isClearAllFilterFunc(true) :
        this.props.isClearAllFilterFunc(true) :
      this.props.isClearAllFilterFunc(true);

    this.props.showRightSide(false);
    value.map((checkBoxValue) => {
      return (
        searchOnClassArr.push(checkBoxValue.value)
      );
    }
    );
    this.props.setMultiSelectParams(this.props.type, this.props.status, searchOnClassArr, this.props.sub_status);
    this.props.loadCmdbItemList(this.props.page, this.props.size, this.props.searchParams, this.props.type, this.props.status, searchOnClassArr,this.props.sub_status);
  }


  handleSubStatusChange(value) {
    let searchOnSubStatusArr = [];
    this.props.setSelectedSubStatusValue(value);

    (_.isEmpty(this.props.searchParams)) ?
      this.props.type.length == 0 && this.props.status.length == 0 && this.props.searchClass.length == 0 ?
        value.length == 0 ?
          this.props.isClearAllFilterFunc(false) :
          this.props.isClearAllFilterFunc(true) :
        this.props.isClearAllFilterFunc(true) :
      this.props.isClearAllFilterFunc(true);

    this.props.showRightSide(false);
    value.map((checkBoxValue) => {
      return (
        searchOnSubStatusArr.push(checkBoxValue.label)
      );
    });
    this.props.setMultiSelectParams(this.props.type, this.props.status, this.props.searchClass, searchOnSubStatusArr);
    this.props.loadCmdbItemList(this.props.page, this.props.size, this.props.searchParams, this.props.type, this.props.status, this.props.searchClass, searchOnSubStatusArr);
 
  }

  handleChange(e, searchValue) {
    this.props.showRightSide(false);
    switch (searchValue) {
      case "CI_ID":
        this.props.setAssetIdSearchInput(e.target.value);
        this.setState({ assetIdSearchFilter: searchValue });
        break;
      case "CI_NAME":
        this.props.setNameSearchInput(e.target.value);
        this.setState({ nameSearchFilter: searchValue });
        break;
      case "COMPANY_NAME":
        this.props.setCompanySearchInput(e.target.value);
        this.setState({ companySearchFilter: searchValue });
        break;
      case "CLASS_NAME":
        this.props.setClassSearchInput(e.target.value);
        this.setState({ classSearchFilter: searchValue });
        break;
        case "SERIAL_NUMBER":
          this.props.setSerialNumberSearchInput(e.target.value);
          this.setState({ serialNumberSearchInput: searchValue });
          break;
      case "BUSINESS_OWNER_NAME":
        this.props.setBusinessOwnerSearchInput(e.target.value);
        this.setState({ businessOwnerSearchFilter: searchValue });
        break;
      case "TECHNICAL_OWNER_NAME":
        this.props.setTechnicalOwnerSearchInput(e.target.value);
        this.setState({ technicalOwnerSearchFilter: searchValue });
        break;
      case "LOCATION_NAME":
        this.props.setLocationSearchInput(e.target.value);
        this.setState({ locationSearchFilter: searchValue });
        break;
    }
  }

  onRowSelect(e, row, index) {
    this.props.updateBulkUpdateData([]);
    this.props.getCMDBDetails(row.CI_ID, row.CI_NAME, row.GROUP_NAME, row.OWNER_NAME, row.LOCATION_NAME, row.ENVIRONMENT_NAME,
      row.DATASET, row.MANUFACTURER_NAME, row.CI_CODE, row.BUSINESS_OWNER_NAME, row.TECHNICAL_OWNER_NAME, row.COMPANY_ID, row.CLASS_NAME, row.CLASS_ID,
      row.COMPANY_NAME, row.TYPE, row.STATUS_NAME, row.SUB_STATUS_NAME, row.UPDATED_AT, row.ASSET_TAG, row.CATEGORY_NAME, row.METALLIC_NAME, row.SUB_CATEGORY_NAME, row.SERIAL_NUMBER);
    this.props.showRightSide(true);
    this.props.showActiveRow(index);
  }

  renderCmdbList(cmdbData) {
    const isCheckboxVisible = onConfigManagerRoleVisible || onAssetRoleVisible;
    let data = cmdbData.result;
    return data.map((data, index) => {
      let businessOwnerName = '';

      if (data.BUSINESS_OWNER_NAME && typeof data.BUSINESS_OWNER_NAME === "string") {
        if (data.BUSINESS_OWNER_NAME.indexOf("|") > -1) {
          businessOwnerName = data.BUSINESS_OWNER_NAME.split("|");
          businessOwnerName = businessOwnerName[0];
        }
        else {
          businessOwnerName = data.BUSINESS_OWNER_NAME;
        }
      }

      let technicalOwnerName = '';
      if (data.TECHNICAL_OWNER_NAME && typeof data.TECHNICAL_OWNER_NAME === "string") {
        if (data.TECHNICAL_OWNER_NAME.indexOf("|") > -1) {
          technicalOwnerName = data.TECHNICAL_OWNER_NAME.split("|");
          technicalOwnerName = technicalOwnerName[0];
        }
        else {
          technicalOwnerName = data.TECHNICAL_OWNER_NAME;
        }
      }

      let locationName = '';
      if (data.LOCATION_NAME && typeof data.LOCATION_NAME === "string") {
        if (data.LOCATION_NAME.indexOf("|") > -1) {
          locationName = data.LOCATION_NAME.split("|");
          locationName = locationName[0];
        }
        else {
          locationName = data.LOCATION_NAME;
        }
      }

      return (
        <tr className={this.props.isRightSideVisible && index == this.props.rowIndex ? "myActive" : ""} onClick={(e) => {this.onRowSelect(e, data, index);this.props.multiSelectOpenFun("allFalse", false);}}>
          {isCheckboxVisible && (
          <td>
            <input
              type="checkbox"
              checked={!!this.props.selection[data.CI_ID]} 
              onChange={(e) => this.props.handleCheckboxChange(e.target.checked, data)}
            />
          </td>
          )}
          <td>
              <a href="javascript:void(0)" className="hyperLinkWIB cmdbMbHyperLink" title={this.props.translator['Open in new tab']} onClick={() => {
                  openLinkInNewTab(`${app_url}/home?editcmdb=${data.CI_ID}`, "New Tab")
              }}>
                 {data.CI_CODE}
              </a>
            </td>
          <td title={data.CI_NAME}>{data.CI_NAME.slice(0, 70)}{data.CI_NAME.length > 70 ? "..." : ""}</td>
          <td title={data.COMPANY_NAME}>{data.COMPANY_NAME.slice(0, 70)}{data.COMPANY_NAME.length > 70 ? "..." : ""}</td>
          <td>{data.CLASS_NAME}</td>
          <td>{data.TYPE == "5" ? "Asset" : data.TYPE == "10" ? "CI" : "Both"}</td>
          <td>{data.STATUS_NAME}</td>
          <td>{data.SUB_STATUS_NAME}</td>
          <td>{data.SERIAL_NUMBER}</td>
          <td title={businessOwnerName}>{businessOwnerName.slice(0, 70)}{businessOwnerName.length > 70 ? "..." : ""}</td>
          <td title={technicalOwnerName}>{technicalOwnerName.slice(0, 70)}{technicalOwnerName.length > 70 ? "..." : ""}</td>
          <td title={locationName}>{locationName.slice(0, 70)}{locationName.length > 70 ? "..." : ""}</td>
          <td>{data.UPDATED_AT}</td>
        </tr>
      );
    });
  }

  render() {
    let panelColSize = this.props.panelResize;
    let caseResponsive = this.props.showLoader == true ? "overFlowClp" : this.state.isMultiSelectOpen ? "overFlowClp" : "table-responsive";
    let meta = !_.isEmpty(this.props.cmdbQuickItemList) ? this.props.cmdbQuickItemList.meta : {};
    let classmap = new Map();

    let typeOptions = [{ label: 'Asset', value: '5' }, { label: 'CI', value: '10' }, { label: 'Both', value: '15' }];
    let classOptions = [];

    let myStartDate = !_.isEmpty(this.props.startDate) ? moment(this.props.startDate).format("MM/DD/YYYY") : "";
    let myEndDate = !_.isEmpty(this.props.endDate) ? moment(this.props.endDate).format("MM/DD/YYYY") : "";
    let myNewDate = myStartDate == "" && myEndDate == "" ? "" : myStartDate + "-" + myEndDate;

    let statusOptions = this.props.statusList.map(item => {
        return {label: item.field1Key, value: item.field1Value};
    });

    classmap = new Map(Object.entries(this.props.ciClass));
    for (let [key, value] of classmap) {
      let obj = {};
      obj.label = value.CLASS_NAME;
      obj.value = value.CLASS_ID;
      classOptions.push(obj);
    }

    let subStatusOptions = this.props.subStatusList.map(item => {
      return {label: item.field1Key, value: item.field1Value};
    }); 
   
    return (
      <div>
        <div className={(this.props.cmdbQuickItemList?.result?.length < 6) || (this.props.showLoader == true) || (this.props.cmdbQuickItemList == 'No record found') ? "respondv responDvMinH" : "respondv"}>
          <div className={`tableRgtBor ${caseResponsive} ${this.props.isSubStatusOpen ? 'display-contents' : ''}`}>
            <Table striped bordered condensed hover className="tableView nowrapWhright" style={{ "marginTop": "0px" }}>
              <thead>
                <tr>
                {(onConfigManagerRoleVisible || onAssetRoleVisible) && (
                  <th style={{minWidth:"20px",verticalAlign:"middle"}}>
                      <input
                        type="checkbox"
                        checked={
                          this.props.cmdbQuickItemList?.result?.length > 0 &&
                          this.props.cmdbQuickItemList.result.every(
                            (row) => this.props.selection[row.CI_ID]
                          )
                        }
                        onChange={(e) => {
                          this.props.handleBulkCheckbox(e)
                        }}
                      />
                    </th>
                )}
                  <th>
                    <div className="sortParArr">Asset/CI ID</div>
                    <div className="colSearDv">
                    <Form.Control
                            className="colSearInp"
                            type="text"
                            value={
                              this.props.assetIdSearchInput != "" ? this.props.assetIdSearchInput : ""
                            }
                            placeholder={this.props.translator["Search here"]}
                            onKeyPress={(e) => {
                              if (e.charCode == "13") {
                                if (
                                  this.props.assetIdSearchInput != "" &&
                                  this.props.assetIdSearchInput.trim().length > 3
                                )
                                  this.handleSearch();
                              }
                            }}
                            onChange={(event) => {
                              this.handleChange(event, "CI_ID");
                            }}
                            onClick={() => this.props.multiSelectOpenFun("allFalse", false)}
                          />
                      {this.props.assetIdSearchInput.trim().length > 3 ?
                        <a title="search" className="faicn" href={void(0)} onClick={(event) => this.handleSearch()}>
                          <IoSearch/>
                        </a> : ""}
                    </div>
                  </th>

                  <th>
                    <div className="sortParArr">Name</div>
                    <div className="colSearDv">
                    <Form.Control
                        className="colSearInp"
                        type="text"
                        value={this.props.nameSearchInput != "" ? this.props.nameSearchInput : ""}
                        placeholder={this.props.translator["Search here"]}
                        onKeyPress={(e) => {
                          if (e.charCode == "13") {
                            if (
                              this.props.nameSearchInput != "" &&
                              this.props.nameSearchInput.trim().length > 3
                            )
                              this.handleSearch();
                          }
                        }}
                        onChange={(event) => {
                          this.handleChange(event, "CI_NAME");
                        }}
                        onClick={() => this.props.multiSelectOpenFun("allFalse", false)}
                      />
                      {this.props.nameSearchInput.trim().length > 3 ?
                        <a title="search" className="faicn" href={void(0)} onClick={(event) => this.handleSearch()}>
                          <IoSearch/>
                        </a> : ""}
                    </div>
                  </th>

                  <th>
                    <div className="sortParArr">Company</div>
                    <div className="colSearDv">
                    <Form.Control
                        className="colSearInp"
                        type="text"
                        value={
                          this.props.companySearchInput != "" ? this.props.companySearchInput : ""
                        }
                        placeholder={this.props.translator["Search here"]}
                        onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            this.props.companySearchInput != "" &&
                            this.props.companySearchInput.trim().length >= 3
                          )
                            this.handleSearch();
                        }
                      }}
                        onChange={(event) => {
                          this.handleChange(event, "COMPANY_NAME");
                        }}
                        onClick={() => this.props.multiSelectOpenFun("allFalse", false)}
                      />

                      {this.props.companySearchInput.trim().length >= 3 ?
                        <a title="search" className="faicn" href={void(0)} onClick={(event) => this.handleSearch()}>
                          <IoSearch/>
                        </a> : ""}
                    </div>

                  </th>
                  {/* <th>
                    <div className="sortParArr">Class</div>
                    <div className="colSearDv">
                      <FormControl className="colSearInp" type="text" value={this.props.classSearchInput != "" ? this.props.classSearchInput : ""} placeholder={this.props.translator["Search here"]} onKeyPress={(e) => { e.charCode == "13" ? this.props.classSearchInput.trim().length > 3 != "" ? this.handleSearch() : "" : ""; }} onChange={(event) => { this.handleChange(event, 'CLASS_NAME'); }} />
                      {this.props.classSearchInput.trim().length > 3 ?
                        <a title="search" className="faicn" href={void(0)} onClick={(event) => this.handleSearch()}>
                          <i className="fa fa-search" />
                        </a> : ""}
                    </div>

                  </th> */}

                  <th>
                    <div className="sortParArr">Class</div>
                    <div id="classDiv" className="colSearDv" ref={e => this.multiSelectClassDropdownRef = e} onClick={() => this.props.multiSelectOpenFun("cmdbClass", true)}>
                      <ReactMultiSelectCheckboxes placeholderButtonLabel={this.props.translator["Select"]} key={"cmdbClass"} rightAligned={true} options={classOptions} value={this.props.selectedClassValue} onChange={this.handleClassChange} />
                    </div>
                  </th>

                  <th>
                    <div className="sortParArr">Type</div>
                    <div id="typeDiv" className="colSearDv" ref={e => this.multiSelectTypeDropdownRef = e} onClick={() => this.props.multiSelectOpenFun("cmdbType", true)}>
                      <ReactMultiSelectCheckboxes placeholderButtonLabel={this.props.translator["Select"]} key={"cmdbType"} rightAligned={true} options={typeOptions} value={this.props.selectedTypeValue} onChange={this.handleTypeChange} />
                    </div>
                  </th>
                
                  <th>
                    <div className="sortParArr">Status</div>
                    <div id="statusDiv" className="colSearDv" ref={e => this.multiSelectStatusDropdownRef = e} onClick={() => this.props.multiSelectOpenFun("cmdbStatus", true)}>
                      <ReactMultiSelectCheckboxes placeholderButtonLabel={this.props.translator["Select"]} key={"cmdbStatus"} rightAligned={true} options={statusOptions} value={this.props.selectedStatusValue} onChange={this.handleStatusChange} />
                    </div>
                  </th>

                  <th>
                    <div className="sortParArr">Sub Status</div>
                    <div id="subStatusDiv" className="colSearDv" ref={e => this.multiSelectSubstatusDropdownRef = e} onClick={() => this.props.multiSelectOpenFun("cmdbSubStatus", true)}>
                      <ReactMultiSelectCheckboxes placeholderButtonLabel={this.props.translator["Select"]} rightAligned={true} options={subStatusOptions} value={this.props.selectedSubStatusValue} onChange={this.handleSubStatusChange} />
                    </div>
                  </th>
                  <th>
                    <div className="sortParArr">Serial Number</div>
                    <div className="colSearDv colSrchInpWidth">
                    <Form.Control
                        className="colSearInp"
                        type="text"
                        value={
                          this.props.serialNumberSearchInput != ""
                            ? this.props.serialNumberSearchInput
                            : ""
                        }
                        placeholder={this.props.translator["Search here"]}
                        onKeyPress={(e) => {
                          if (e.charCode == "13") {
                            if (
                              this.props.serialNumberSearchInput != "" &&
                              this.props.serialNumberSearchInput.trim().length > 3
                            )
                              this.handleSearch();
                          }
                        }}
                        onChange={(event) => {
                          this.handleChange(event, "SERIAL_NUMBER");
                        }}
                        onClick={() => this.props.multiSelectOpenFun("allFalse", false)}
                      />
                      {this.props.serialNumberSearchInput.trim().length > 3 ?
                        <a title="search" className="faicn" href={void(0)} onClick={(event) => this.handleSearch()}>
                          <IoSearch/>
                        </a> : ""}
                    </div>
                  </th>
                  <th>
                    <div className="sortParArr">Business Owner</div>
                    <div className="colSearDv colSrchInpWidth">
                    <Form.Control
                        className="colSearInp"
                        type="text"
                        value={
                          this.props.businessOwnerSearchInput != ""
                            ? this.props.businessOwnerSearchInput
                            : ""
                        }
                        placeholder={this.props.translator["Search here"]}
                        onKeyPress={(e) => {
                          if (e.charCode == "13") {
                            if (
                              this.props.businessOwnerSearchInput != "" &&
                              this.props.businessOwnerSearchInput.trim().length > 3
                            )
                              this.handleSearch();
                          }
                        }}
                        onChange={(event) => {
                          this.handleChange(event, "BUSINESS_OWNER_NAME");
                        }}
                        onClick={() => this.props.multiSelectOpenFun("allFalse", false)}
                      />
                      {this.props.businessOwnerSearchInput.trim().length > 3 ?
                        <a title="search" className="faicn" href={void(0)} onClick={(event) => this.handleSearch()}>
                          <IoSearch/>
                        </a> : ""}
                    </div>
                  </th>

                  <th>
                    <div className="sortParArr">Technical Owner</div>
                    <div className="colSearDv">
                    <Form.Control
                        className="colSearInp colSrchInpWidth"
                        type="text"
                        value={
                          this.props.technicalOwnerSearchInput != ""
                            ? this.props.technicalOwnerSearchInput
                            : ""
                        }
                        placeholder={this.props.translator["Search here"]}
                        onKeyPress={(e) => {
                          if (e.charCode == "13") {
                            if (
                              this.props.technicalOwnerSearchInput != "" &&
                              this.props.technicalOwnerSearchInput.trim().length > 3
                            )
                              this.handleSearch();
                          }
                        }}
                        onChange={(event) => {
                          this.handleChange(event, "TECHNICAL_OWNER_NAME");
                        }}
                        onClick={() => this.props.multiSelectOpenFun("allFalse", false)}
                      />
                      {this.props.technicalOwnerSearchInput.trim().length > 3 ?
                        <a title="search" className="faicn" href={void(0)} onClick={(event) => this.handleSearch()}>
                          <IoSearch/>
                        </a> : ""}
                    </div>
                  </th>

                  {/* <th>
                    <div className="sortParArr">Support Company</div>
                    <div className="colSearDv">
                      <FormControl className="colSearInp" type="text" value={this.props.supportCompanySearchInput != "" ? this.props.supportCompanySearchInput : ""} placeholder={this.props.translator["Search here"]} onKeyPress={(e) => { e.charCode == "13" ? this.props.supportCompanySearchInput.trim().length > 3 != "" ? this.handleSearch() : "" : ""; }} onChange={(event) => { this.handleChange(event, 'CI_NAME'); }} />
                      {this.props.supportCompanySearchInput.trim().length > 3 ?
                        <a title="search" className="faicn" href={void(0)} onClick={(event) => this.handleSearch()}>
                          <i className="fa fa-search" />
                        </a> : ""}
                    </div>
                  </th>

                  <th>
                    <div className="sortParArr">Support Group</div>
                    <div className="colSearDv" ref={e => this.multiSelectTargetDropdownRef = e}>
                      <ReactMultiSelectCheckboxes placeholderButtonLabel={this.props.translator["Select"]} rightAligned={true} options={supportGroupOptions} value={this.props.selectedSupportGroupValue} onChange={this.handleTargetForChange} />
                    </div>
                  </th> */}

                  <th>
                    <div className="sortParArr">Location</div>
                    <div className="colSearDv">
                    <Form.Control
                        className="colSearInp colSrchInpWidth"
                        type="text"
                        value={
                          this.props.locationSearchInput != ""
                            ? this.props.locationSearchInput
                            : ""
                        }
                        placeholder={this.props.translator["Search here"]}
                        onKeyPress={(e) => {
                          if (e.charCode == "13") {
                            if (
                              this.props.locationSearchInput != "" &&
                              this.props.locationSearchInput.trim().length > 3
                            )
                              this.handleSearch();
                          }
                        }}
                        onChange={(event) => {
                          this.handleChange(event, "LOCATION_NAME");
                        }}
                        onClick={() => this.props.multiSelectOpenFun("allFalse", false)}
                      />
                      {this.props.locationSearchInput.trim().length > 3 ?
                        <a title="search" className="faicn" href={void(0)} onClick={(event) => this.handleSearch()}>
                          <IoSearch/>
                        </a> : ""}
                    </div>
                  </th>


                  <th>
                    <div className="sortParArr">Modified On</div>
                    <div className="colSearDv" onClick={() => this.props.multiSelectOpenFun("allFalse", false)}>
                      <DateRangePicker initialSettings={{
                        autoUpdateInput: false,
                        maxDate: moment(),
                        autoApply: true,
                        parentEl:"#skipdv"
                      }}
                        onApply={(e, picker) => this.handleSearch(e, picker)} onCallback={this.handleCallback}>
                        <input type="text" placeholder={this.props.translator["Search here"]} readOnly className="form-control" onPaste={this.handleKeyPress} onKeyDown={this.handleKeyPress} value={myNewDate} />
                      </DateRangePicker>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {(this.props.cmdbQuickItemList == undefined || this.props.showLoader == true) || this.props.cmdbQuickItemList.length == 0 ?
                  <tr>
                    <td colSpan="14" style={{ "textAlign": "center" }}><ListLoader /></td>
                  </tr> :
                  this.props.cmdbQuickItemList == 'No record found' ?
                    <tr>
                      <td colSpan="14" style={{ "textAlign": "center" }}>{this.props.translator['There is no matching data available']}</td>
                    </tr> :
                    this.renderCmdbList(this.props.cmdbQuickItemList)
                }
              </tbody>
            </Table>
          </div>
          <div className="nBotPagina">
          <div className="nShow">
            <div className="margin-r-10" componentClass={Form.Label}>{this.props.translator['Show']}:</div>
            <Form.Select value={this.props.size} onChange={this.onPageSizeChange}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              {/* <option value="100">100</option> */}
            </Form.Select>
          </div>
          <nav aria-label='Pagination' className='display-inline-block'>
          <Pagination
            prevPageText='Prev'
            nextPageText='Next'
            firstPageText={<i className='glyphicon glyphicon-menu-left' />}
            lastPageText={<i className='glyphicon glyphicon-menu-right' />}
            activePage={meta ? meta.currentPage : 1}
            itemsCountPerPage={this.props.size}
            totalItemsCount={meta ? meta.rowCount : 1}
            pageRangeDisplayed={panelColSize <= 42 ? 2 : 5}
            onChange={(e) => {this.onPageChange(e); this.props.multiSelectOpenFun("allFalse", false)}}
          />
          </nav>
          
        </div>
        </div>        
      </div>
    );
  }
}


export function mapStateToProps({ cmdbQuickItemList, showLoader, statusList, subStatusList, ciClass }) {
  return {
    cmdbQuickItemList,
    showLoader: showLoader.loading, statusList, subStatusList, ciClass
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadCmdbItemList,
    loadCMDBFilterResultsForSearchString,
    resetCMDBQVFilters,
    loadCmdbItemFilterList, getCmdbApprovals, loadCIClass, loadCmdbClassBasedList, retriveCMDBStatuses, retriveCMDBSubStatuses
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CmdbList);
