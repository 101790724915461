/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
import { transformPayload } from "../common/helper";

// A function to retrive React-Query-Builder's Query based on Module's Name, Company ID and Search Text from D2C for Breakfix module 
export const getQueryBasedOnUserTextBreakfix = async (moduleName, companyID, userText) => {
    try{
        const response = await axios.post(`/getGeneratedParametersBreakfix?module=${moduleName}&companyId=${companyID}`,{"searchString": userText});
        const retrivedQuery = JSON.parse(response.data.response);
        let rule = {
            "combinator": "and",
            "not": false,
            "rules": []
        };
        retrivedQuery.map(item => {
                rule.rules.push({
                    "field": item.key,
                    "operator": item.operator, 
                    "value": item.value
                });     
        });
        return rule;
    }catch(error){
        console.log("Error in getQueryBasedOnUserTextBreakfix : ", error);
        return null;
    }
};

// A function to retrive React-Query-Builder's Query based on Module's Name, Company ID and Search Text from D2C for Fulfillment module 
export const getQueryBasedOnUserTextFulfillment = async (moduleName, companyID, userText) => {
    try{
        const response = await axios.post(`/getGeneratedParametersFulfillment?module=${moduleName}&companyId=${companyID}`,{"searchString": userText});
        const retrivedQuery = JSON.parse(response.data.response);
        let rule = {
            "combinator": "and",
            "not": false,
            "rules": []
        };
        retrivedQuery.map(item => {
                rule.rules.push({
                    "field": item.key,
                    "operator": item.operator, 
                    "value": item.value
                });     
        });
        return rule;
    }catch(error){
        console.log("Error in getQueryBasedOnUserTextFulfillment : ", error);
        return null;
    }
};

// A function to retrive Incident (i.e, Breakfix) tickets by using React-Builder's Query payload from D2C
export const retriveBreakfixRecordsBasedOnQuerry = async (dispatch, query, pageNumber, pageSize, sortingOrder) => {
    try {
        const transformedPayload = transformPayload(query);
        dispatch({type: 'FINAL_QUERY', payload: transformedPayload});
        const payload = {"searchData": transformedPayload,"page": {"pageNo": pageNumber, "pageSize": pageSize},"sort": {"direction": sortingOrder}};
        const response = await axios.post(`/getBreakfixRecords`,payload);
        const retrivedData = response.data;
        const metaData = {currentPage: retrivedData.number,
            recordCount: retrivedData.totalElements,
            size: retrivedData.size,
            totalPageCount: retrivedData.totalPages
        };
        return {data: {data: retrivedData.content, meta: metaData}};
    }catch(error){
        console.log("Error in retriveBreakfixRecordsBasedOnQuerry : ",error);
        return;
    } 
};

// A function to retrive Fulfillment tickets by using React-Builder's Query payload from D2C
export const retriveFulfillmentRecordsBasedOnQuerry = async (dispatch, query, pageNumber, pageSize, sortingOrder) => {
    try{
        const transformedPayload = transformPayload(query);
        dispatch({type: 'FINAL_QUERY', payload: transformedPayload});
        const payload = {"searchData": transformedPayload,"page": {"pageNo": pageNumber, "pageSize": pageSize},"sort": {"direction": sortingOrder}};
        const response = await axios.post(`/getFulfillmentRecords`,payload);
        const retrivedData = response.data;
        const metaData = {currentPage: retrivedData.number,
            recordCount: retrivedData.totalElements,
            size: retrivedData.size,
            totalPageCount: retrivedData.totalPages
        };
        return {data: {data: retrivedData.content, meta: metaData}};
    }catch(error){
        console.log("Error in retriveFulfillmentRecordsBasedOnQuerry : ",error);
        return;
    }
} 

// A function to save retrived Breakfix records to Redux Store for Functional Components
export const saveRetrivedBreakfixRecords = async (dispatch, query, pageNumber, pageSize, sortingOrder) => {
    try{
        dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });
        dispatch({ type: 'ON_RUN_LOADER' });
        const breakfixRecords = await retriveBreakfixRecordsBasedOnQuerry(dispatch, query, pageNumber, pageSize, sortingOrder);
        if(breakfixRecords === undefined){
            throw new Error('Validation Error ! All fields must be filled !'); 
        }
        if(breakfixRecords?.data){
            dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
            dispatch({ type: 'OFF_RUN_LOADER' });
            dispatch({type: 'LOAD_BREAKFIXQUICKVIEW_SUCCESS', quickViewDetails: breakfixRecords});
        }
        return breakfixRecords;
    }catch(error){
        dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
        dispatch({ type: 'OFF_RUN_LOADER' });
        console.error('Failure in saveRetrivedBreakfixRecords() : ',error);
        return Promise.reject(error); 
    }
};

// A function to save retrived Fulfillment records to Redux Store for Functional Components
export const saveRetrivedFulfillmentRecords = async (dispatch, query, pageNumber, pageSize, sortingOrder) => {
    try{
        dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });
        dispatch({ type: 'ON_RUN_LOADER' });
        const fulFillmentList = await retriveFulfillmentRecordsBasedOnQuerry(dispatch, query, pageNumber, pageSize, sortingOrder);
        if(fulFillmentList === undefined){
            throw new Error('Validation Error ! All fields must be filled !'); 
        }
        if(fulFillmentList?.data){
            dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
            dispatch({ type: 'OFF_RUN_LOADER' });
            dispatch({type: 'LOAD_FULFILLMENTLIST_SUCCESS', fulFillmentList });
        }
        return fulFillmentList;
    }catch(error){
        dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
        dispatch({ type: 'OFF_RUN_LOADER' });
        console.error('Failure in saveRetrivedFulfillmentRecords() : ',error);
        return Promise.reject(error);
    }
};

// A function to save retrived Breakfix records to Redux Store for Class Components
export const saveRetrivedBreakfixRecordsForClassComponents = (query, pageNumber, pageSize, sortingOrder) => {
    const payload = {"searchData": query,"page": {"pageNo": pageNumber, "pageSize": pageSize},"sort": {"direction": sortingOrder}};
    return dispatch => {
        dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });
        axios.post(`/getBreakfixRecords`,payload).then(response => {
        dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
        const retrivedData = response.data;
        const metaData = {currentPage: retrivedData.number,
            recordCount: retrivedData.totalElements,
            size: retrivedData.size,
            totalPageCount: retrivedData.totalPages
        };
        const breakfixRecords = {data: {data: retrivedData.content, meta: metaData}};
        dispatch({type: 'LOAD_BREAKFIXQUICKVIEW_SUCCESS', quickViewDetails: breakfixRecords});
    }).catch(error => {
        console.error('Failure in saveRetrivedBreakfixRecordsForClassComponents() : ',error);
        dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
    });
   }
};

// A function to save retrived Fulfillment records to Redux Store for Class Components
export const saveRetrivedFulfillmentRecordsForClassComponents = (query, pageNumber, pageSize, sortingOrder) => {
    const payload = {"searchData": query,"page": {"pageNo": pageNumber, "pageSize": pageSize},"sort": {"direction": sortingOrder}};
    return dispatch => { 
        dispatch({ type: 'FETCHING_DETAILS_IN_PROGRESS' });
        axios.post(`/getFulfillmentRecords`,payload).then(response => { 
            dispatch({ type: 'DETAILS_FETCHED_SUCCESSFULLY' });
            const retrivedData = response.data;
            const metaData = {currentPage: retrivedData.number,
                recordCount: retrivedData.totalElements,
                size: retrivedData.size,
                totalPageCount: retrivedData.totalPages
            };
            const fulFillmentList = {data: {data: retrivedData.content, meta: metaData}};
            dispatch({type: 'LOAD_FULFILLMENTLIST_SUCCESS', fulFillmentList });
        });
    }
};
