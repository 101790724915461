
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PopUpAuditLog from "../../common/WorkItemBoard/breakfixAuditLog";
import PopUpActivityLog from "../../common/WorkItemBoard/breakfixActivityLog";
import {IoClose} from "react-icons/io5";
import WIBNotification from "../WIBNotification";
const MoreArrow = (
  <i title="More" className="fa fa-angle-double-down f-size-15"></i>
);
class FulfillmentTabPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabKey: 1,
      isOpen: true,
      message: "",
      messageactivity: "",
      taskeditFlag: "0",
      taskcode: "",
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.changeCompMessage = this.changeCompMessage.bind(this);
    this.changeCompMessageactivity = this.changeCompMessageactivity.bind(this);
    this.clicktaskEdit = this.clicktaskEdit.bind(this);
  }
  clicktaskEdit(i, j, k) {
    this.setState({
      taskeditFlag: i,
      taskcode: j,
      taskregid: k,
    });
  }
  changeCompMessage(message) {
    this.setState({ message });
  }
  changeCompMessageactivity(messageactivity) {
    this.setState({ messageactivity });
  }
  overlayExit() {
    document.body.style.overflow = "visible";
  }
  handleSelect(tabKey) {
    this.setState({ tabKey });
  }
  render() {
    return (
      <div>
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
          <ul>
            <li>
            <button
                type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                this.props.changeRightView("allClose");
                }}
            >
                <IoClose/>
            </button>
            </li>
          </ul>
        </div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {this.props.translator["Activity Details"]}
          </div>
        </div>
        <div className="rBoxGap">
          <div className="rBoxTabs">
          <Tabs
            activeKey={this.state.tabKey}
            onSelect={this.handleSelect}
            id="tabId"
          >
            <Tab
              eventKey={1}
              title={this.props.translator["Comments"]}
            >
              <PopUpActivityLog
                fieldStatus={this.props.fieldStatus}
                messageactivity={this.state.messageactivity}
                fulfillmentDetails={this.props.fulfillmentDetails}
                changeCompMessageactivity={this.changeCompMessageactivity}
                breakFixItemId={this.props.breakFixItemId}
                module="Fullfilment"
                itemId={this.props.itemId}
                translator={this.props.translator}
                date_format={this.props.date_format}
                disableFieldsForGuestRole={this.props.disableFieldsForGuestRole}
                aiSummaruzationEnabled={this.props.aiSummaruzationEnabled}
              />
            </Tab>
            <Tab
              eventKey={6}
              title={this.props.translator["Audit Log"]}
            >
              <PopUpAuditLog
                fieldStatus={this.props.fieldStatus}
                message={this.state.message}
                changeCompMessage={this.changeCompMessage}
                itemId={this.props.itemId}
                module="Fullfilment"
                translator={this.props.translator}
                date_format={this.props.date_format}
              />
            </Tab>
            {console.log("props",this.props.fulfillmentDetails)}
            <Tab eventKey={3} title={"Notifications"}>
              <WIBNotification
                requestNumber={this.props.fulfillmentDetails.itemNumber}
                orderNumber={""}
              />
              </Tab>
            </Tabs>
            </div>
        </div>
      </div>
    );
  }
}

export default FulfillmentTabPage;
