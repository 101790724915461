
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { useSelector } from "react-redux";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import AiIcon from "_Commons/aiIcon";


const SlaStatusPopup = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);

    const tooltip = (
        <Popover id="tooltip" className="slaStatusPopup" style={{position: "fixed"}}>
            <span>Forecasting of Breached</span> 80%
        </Popover>
    );

    return (
        <OverlayTrigger placement="bottom-end" overlay={tooltip}>
            <span className="slaStatusIcn"><AiIcon/></span>
        </OverlayTrigger>
    );
};

export default SlaStatusPopup;
