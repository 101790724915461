
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { useEffect, useRef, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";

import Cookies from "universal-cookie";
const cookies = new Cookies();

let cookieData = cookies.get("gph");
if (cookieData) cookieData = cookieData.replace("s:", "");
if (cookieData)
  cookieData = cookieData.substring(0, cookieData.lastIndexOf("."));
cookieData = cookieData.split("~");
let loggedInUserEmail = cookieData[29];
let wsUrl = cookieData[62] || "";

const useWebSocketConnection = (socket) => {
  const WEB_SOCKET_URL = wsUrl.toString().replace("https", "wss");
  // const WEB_SOCKET_URL = 'ws://localhost:3000';
  const sendMessage = () => {};
  const lastMessage = {data:"Connection established."};
  const readyState = {};
  // const { sendMessage, lastMessage, readyState } = useWebSocket(
  //   WEB_SOCKET_URL,
  //   {
  //     queryParams: {
  //       emailId: loggedInUserEmail
  //     },
  //     shouldReconnect: () => true,
  //     reconnectAttempts: 10,
  //     reconnectInterval: () => 3000,
  //     share: true,
  //   }
  // );
  const [messageHistory, setMessageHistory] = useState([]);

  // useEffect(() => {
  //   if (lastMessage !== null) {
  //     setMessageHistory((prev) => prev.concat(lastMessage));
  //   }
  // }, [lastMessage]);

  // const connectionStatus = {
  //   [ReadyState.CONNECTING]: "Connecting",
  //   [ReadyState.OPEN]: "Open",
  //   [ReadyState.CLOSING]: "Closing",
  //   [ReadyState.CLOSED]: "Closed",
  //   [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  // }[readyState];
  const connectionStatus = {}

  return {
    sendMessage,
    lastMessage,
    connectionStatus,
    messageHistory,
  };
};

export default useWebSocketConnection;
