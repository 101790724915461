
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef } from "react";
import { formatQuery } from "react-querybuilder";
import { ListGroup, Row, Col, Tabs, Tab, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MyContext } from '_MyContext';

import { PiPencilSimpleBold } from "react-icons/pi";
import { IoClose } from "react-icons/io5";
import { customQueryFormatter } from "../../common/helper";

const RulesShortDescriptionList = ({ rowData, setIsEditDetails }) => {
  const translator = useSelector((state) => state.spcmReducer.tr);
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const editButton = useRef(null);

  useEffect(() => {
    context.keyDownHandler(closeButton.current,editButton.current,"","");
    context.newTabPrevent();
  }, []);

  const getCondition = (rowData) => {
    try {
      if (rowData?.conditionalJson && rowData.conditionalJson !== "null") {
        let parsedJson;
        try {
          parsedJson = JSON.parse(rowData.conditionalJson);
        } catch (error) {
          throw new Error("Error parsing JSON");
        }
        const formattedQuery = customQueryFormatter(parsedJson, "sql");
    
        if (formattedQuery === "(1 = 1)") {
          return "No condition found";
        } else {
          return formattedQuery;
        }
      } else {
        return "No condition found";
      }
    } catch (error) {
      console.error(error.message);
      return "No condition found";
    }
  }

  return (
    <div className="stickyArea rBoxStyle">
      <Row>
        <Col xs={12}>
          <div className="rPageHeadActBtn">
            <ul>
              <li>
                <Link
                  to={`/editRule/${rowData?.id}`}
                  title={translator["Rule"]}
                  ref={editButton} 
                  className="ctrlKeyPrevent"
                >
                  <PiPencilSimpleBold />
                </Link>
              </li>
              <li>
                <Link to="javascript:void(0)"
                  title={translator["Minimize the details panel"]}
                  bsClass=""
                  onClick={() => setIsEditDetails(false)}
                  ref={closeButton}
                  className="ctrlKeyPrevent"
                >
                  <IoClose />
                </Link>
              </li>
            </ul>
          </div>
          <div className="rPageHeading py-3">{rowData?.ruleName}</div>
        </Col>
      </Row>
      <div className="rBoxGap">
        <div className="rBoxTabs hideTabNav">
          <Tabs id="tabId">
            <Tab eventKey={1} title="ASSIGNMENT DETAILS">
              <ListGroup bsPrefix=" " bsClass="">
                <ListGroup.Item
                  bsPrefix=" "
                  bsClass=""
                  className="rwTable"
                  style={{ "border-top": "none" }}
                >
                  <span className="rwKey">{translator["Rule Name"]} </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.ruleName || "--"}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">{translator["Company"]} </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.consumerCompany || "--"}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                    {translator["Business Function"] || "Business Function"}{" "}
                  </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.businessFunction || "--"}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                    {translator["Module"] || "Module"}
                  </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.module}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                    {translator["Rule Type"] || "Rule Type"}
                  </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.ruleType}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                    {translator["Support Company"] || "Support Company"}
                  </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.assignmentCompany || "--"}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                    {rowData?.ruleType === "assignment" ? translator["Support Group"] || "Support Group" : ""}
                    {rowData?.ruleType === "workflow" ? translator["Workflow"] || "Workflow" : ""}
                    {rowData?.ruleType === "escalation" ? translator["Escalation Plan"] || "Escalation Plan" : ""}
                    {rowData?.ruleType === "statusTransition" ? translator["Status Transition"] || "Status Transition" : ""}
                    {rowData?.ruleType === "taskPlan" ? translator["Task Plan"] || "Task Plan" : ""}
                    {rowData?.ruleType === "approval" ? translator["Approval Set"] || "Approval Set" : ""}
                  </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.responseEntity || "--"}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                    {translator["Weightage"] || "Weightage"}
                  </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.weight || "--"}
                  </span>
                </ListGroup.Item>

                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                    {translator["Status"] || "Status"}
                  </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.status || "--"}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                  <span className="rwKey">
                    {translator["Rule For"] || "Rule For"}
                  </span>
                  <span className="rwVal text-capitalize">
                    {rowData?.ruleFor || "--"}
                  </span>
                </ListGroup.Item>
              </ListGroup>
            </Tab>
          </Tabs>
          <div className="margin-t-20 clearboth brkFix brkFixOrder">
            <Accordion defaultActiveKey="0" className="brkFix brkFixOrder">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Rule</Accordion.Header>
                <Accordion.Body>
                  <pre>
                    {getCondition(rowData)}
                  </pre>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RulesShortDescriptionList;
