
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { connect } from 'react-redux';
import Spinner from 'react-spinkit';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import axios from 'axios';
import SearchArticles from './searchArticles';
import AttachedArticles from './attachedArticles';
import ArticleDetails from './articleDetails';
import { loadArticleResultsForSearchString_KA, loadInsidedArticlesForSearch_KA, getAttachedArticles, linkKnowledgeArticles, articlesLoading_attached_KA, articlesSuccess_attached_KA, removeAttachedArticle } from "../../../../actions/breakFix/knowledgeArticleAction";
import { emptyInsidedReducer, loadDiscourseArticlesForSearch, loadDiscourseArticlesForSearchByTopicId } from '../../../../actions/knowledge/knowledgeAction';
import {IoClose} from "react-icons/io5";
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import { openLinkInNewTab } from '../../helper';
const cookies = new Cookies();

let emailAddress = cookies.get('gph');
if (emailAddress) emailAddress = emailAddress.replace('s:', '');
if (emailAddress) emailAddress = emailAddress.substring(0, emailAddress.lastIndexOf('.'));
let emailAddressArray = emailAddress.split("~");
emailAddress = emailAddressArray[29];

let isKnowledgeEnabled = cookies.get('gph');
if (isKnowledgeEnabled) isKnowledgeEnabled = isKnowledgeEnabled.replace('s:', '');
if (isKnowledgeEnabled) isKnowledgeEnabled = isKnowledgeEnabled.substring(0, isKnowledgeEnabled.lastIndexOf('.'));
let cookiesArray = isKnowledgeEnabled.split("~");
isKnowledgeEnabled = cookiesArray[30];
let insided_community_url = cookiesArray[37];
let discourseUrl = cookies.get('gph');
if (discourseUrl) discourseUrl = discourseUrl.replace('s:', '');
if (discourseUrl) discourseUrl = discourseUrl.substring(0, discourseUrl.lastIndexOf('.'));
discourseUrl = discourseUrl.split("~");
discourseUrl = discourseUrl[50];

let username = cookies.get('gph');
if (username) username = username.replace('s:', '');
if (username) username = username.substring(0, username.lastIndexOf('.'));
username = username.split("~");
username = username[32];

// let insided_customer = cookiesArray[40];
// const spcmKnowledgeCall = process.env.SPCM_KNOWLEDGE_SEARCH;

class BreakFixKnowledgeArticles extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			categories: '',
			insidedError: "",
			searchArticle: "",
			showArticleTable: false,
			messageText: "",
			messageType: "",
			articleDetails: null,
			searchFilter: 'all',
			searchBy: "Keyword"
		};
		this.submitSearchArticle = this.submitSearchArticle.bind(this);
		this.setStateV1 = this.setStateV1.bind(this);
		this.linkKnowledgeArticles = this.linkKnowledgeArticles.bind(this);
		this.downloadFile = this.downloadFile.bind(this);
		this.removeAttachedArticle = this.removeAttachedArticle.bind(this);
		this.checkEditAllowed = this.checkEditAllowed.bind(this);
		this.showDescription = this.showDescription.bind(this);
		this.setMessageText = this.setMessageText.bind(this);
		this.checkInsidedApplicable = this.checkInsidedApplicable.bind(this);
		this.renderLargeTextEllipse = this.renderLargeTextEllipse.bind(this);
		this.setSearchBy = this.setSearchBy.bind(this);
	};

	componentDidMount() {
		const {
			breakFixEditDetails: {
				breakfixId,
				breakfixNumber
			}
		} = this.props;
		this.props.emptyInsidedReducer();
		this.props.getAttachedArticles(breakfixId, breakfixNumber);
		if ((isKnowledgeEnabled==true || isKnowledgeEnabled == "true")) {
			//this.props.loadDiscourseArticlesForSearch(this.props.SearchText)
		
		// 	axios.get('/user/email/' + emailAddress, { headers: { 'authorization': `bearer ${this.props.insidedTokenDetails.access_token}` } })
		// 		.then((res) => {
		// 			let authorId = res.data.userid;

		// 			return axios.get('/v2/categories/' + authorId, { headers: { 'authorization': `bearer ${this.props.insidedTokenDetails.access_token}` } })
		// 		})
		// 		.catch(err => {
		// 			this.setState({ insidedError: true });
		// 		})
		// 		.then((res) => {
		// 			let categoryData = res.data.result;

		// 			let categories = '';
		// 			categoryData.forEach((item) => {
		// 				categories = categories + item.id + ',';
		// 			});
		// 			categories = categories.substring(0, categories.length - 1);
		// 			this.setState({ categories: categories });
		// 			this.setState({ insidedError: false });
		// 		})
		// 		.catch(err => {
		// 			this.setState({ insidedError: true });
		// 		});
		// 		}

		// this.props.getAttachedArticles(breakfixId, breakfixNumber);
				
		// if ((isKnowledgeEnabled==true || isKnowledgeEnabled == "true")){
		// 	const that = this;
		// 	axios.get(`/api/generateInsidedToken?email=${emailAddress}`
		// 	).then(response => {
		// 		that.setState({
		// 			insided_access_token: response.data.token
		// 		});
		// 	});
		}
		
	}

	checkInsidedApplicable() {
		console.log("checkInsidedApplicable");
		console.log(isKnowledgeEnabled);
		if ((isKnowledgeEnabled==true || isKnowledgeEnabled == "true")) {
			console.log("insidedError");
			console.log(this.state.insidedError);
			if (this.state.insidedError === "") {
				return "";
			}
			else {
				return !this.state.insidedError;
			}
		}
		return false;
	}

	setStateV1(obj) {
		this.setState((state) => {
			let updatedState = Object.assign({}, state, obj);
			return updatedState;
		});
	}

	setSearchBy(val) {
		this.setState({searchBy: val});
	}

	submitSearchArticle(searchText) {
		const { searchFilter, searchBy } = this.state;

		// if(searchFilter == 'all' || searchFilter == 'knowledge') {
		// 	this.props.loadArticleResultsForSearchString_KA(searchText);
		// }
		// else if(searchFilter == 'community') {
		// 	this.props.loadArticleResultsForSearchString_KA('');
		// }
		// {
		// 	spcmKnowledgeCall == 'true' ?
		// 	(searchFilter == 'all' || searchFilter == 'knowledge') ?
		// 		this.props.loadArticleResultsForSearchString_KA(searchText)
		// 		: searchFilter == 'community' ?
		// 			this.props.loadArticleResultsForSearchString_KA('') : ""
		// 	: null;
		// }
		if (isKnowledgeEnabled==true || isKnowledgeEnabled == "true") {
			console.log("data should be visible");
			let tempText = searchText.replace("?", "").replace("%", "").replace(/#/g, '');
			//this.props.loadInsidedArticlesForSearch_KA(this.state.categories, tempText, this.props.insidedTokenDetails.access_token, searchFilter, true);
			if(searchBy == "Keyword")
				this.props.loadDiscourseArticlesForSearch(searchText, username);
			else if(searchBy == "Topic ID")
				this.props.loadDiscourseArticlesForSearchByTopicId(searchText, username);
		}
	}

	linkKnowledgeArticles(linkArticles) {
		const {
			breakFixEditDetails: {
				breakfixId,
				breakfixNumber
			},
			isFetchingDetails_KA: {
				isFetching,
				isFetchingAttachedKA
			},
		} = this.props;
		if (isFetching || isFetchingAttachedKA) {
			Swal.fire('Another process is already running, Please try later!');
			return false;
		}
		this.setState({
			messageText: '',
			messageType: ''
		});
		this.props.articlesLoading_attached_KA();
		this.props.linkKnowledgeArticles(linkArticles)
			.then((res) => {
				if (res['status'] && res['status'] === 206) {
					this.setMessageText(res['data'], 'error');
				}
				else {
					let alertMessage = (linkArticles && Array.isArray(linkArticles) && linkArticles.length > 1) ? res['data'] : 'Article attached successfully';
					this.setMessageText(alertMessage, 'success');
				}
				this.props.articlesSuccess_attached_KA();
				this.props.getAttachedArticles(breakfixId, breakfixNumber);
			})
			.catch(err => {
				this.setMessageText('Unable to link article', 'error');
				this.props.articlesSuccess_attached_KA();
				this.props.getAttachedArticles(breakfixId, breakfixNumber);
			});
	}

	removeAttachedArticle(knowledgeId) {
		const {
			breakFixEditDetails: {
				breakfixId,
				breakfixNumber
			},
			isFetchingDetails_KA: {
				isFetching,
				isFetchingAttachedKA
			},
		} = this.props;
		if (isFetching || isFetchingAttachedKA) {
			Swal.fire('Another process is already running, Please try later!');
			return false;
		}
		this.setState({
			messageText: '',
			messageType: ''
		});
		this.props.articlesLoading_attached_KA();
		this.props.removeAttachedArticle(breakfixId, knowledgeId)
			.then((res) => {
				if (res['status'] && res['status'] === 206) {
					this.setMessageText('Unable to remove article', 'error');
				}
				else {
					this.setMessageText('Article removed successfully', 'success');
				}
				this.props.articlesSuccess_attached_KA();
				this.props.getAttachedArticles(breakfixId, breakfixNumber);
			})
			.catch(err => {
				this.setMessageText('Unable to remove article', 'error');
				this.props.articlesSuccess_attached_KA();
				this.props.getAttachedArticles(breakfixId, breakfixNumber);
			});
	}

	downloadFile(type, slug, id, path) {

		if (type === "SPCM") {

			let link = document.createElement('a');
			link.href = path;
			link.id = "temp_downloadfile";
			link.target = "_self"
			document.body.appendChild(link);
			link.click();
			setTimeout(() => {
				link.remove();
			}, 1000000);
		}
		else {
			const ssoUrl=`${discourseUrl}/t/${slug}`;
			const win = openLinkInNewTab(ssoUrl, 'New Tab');
			// setTimeout(() => {
			// 	if (type == "Community") {
			// 		win.location = insided_community_url + '/questions/' + id;
			// 	} else if (type == "conversation") {
			// 		win.location = insided_community_url + '/conversations/' + id;
			// 	} else {
			// 		win.location = insided_community_url + '/articles/' + id;
			// 	}
			// }, 2500);
		}
	}

	checkEditAllowed() {
		const {
			breakFixEditDetails: {
				status
			}
		} = this.props;
		if (status === 'Fixed' || status === 'Closed' || status === 'Cancelled') {
			return false;
		}
		return true;
	}

	showDescription(item) {
		this.setState({
			articleDetails: item
		});
	}

	setMessageText(message, type) {
		const that = this;
		this.setState({
			messageText: message,
			messageType: type
		});
		setTimeout(() => {
			that.setState({
				messageText: '',
				messageType: ''
			});
		}, 10000);
	}

	renderLargeTextEllipse(text, limit = 21) {
		try {
			return (
				<span title={text}>{text.slice(0, limit)}{text.length > limit ? "..." : ""}</span>
			);
		} catch (e) {
			return null;
		}
	}

	render() {

		// console.log('KA***********', {props: this.props, state: this.state});

		const { articleDetails, messageText, messageType, searchArticle, showArticleTable, searchFilter } = this.state;
		const {
			isFetchingDetails_KA: {
				isFetchingAttachedKA,
				isFetching
			},
			attachedArticlesList
		} = this.props;

		let checkInsidedApplicable = this.checkInsidedApplicable();


		return (
			<div>
				{articleDetails ? (
					<ArticleDetails
						articleDetails={articleDetails}
						setStateV1={this.setStateV1}
						downloadFile={this.downloadFile}
						translator={this.props.translator}
						renderLargeTextEllipse={this.renderLargeTextEllipse}
					/>
				) : (
					<div>
						<Row>
							<Col md={12}>
								<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
									<ul>
										<li>
										<button
											type='button'
											title="Minimize the right panel"
											bsClass=""
											bsStyle=""
											className="closerightPanelBtn"
											onClick={() => {
											this.props.rightEditPanel(false);
											this.props.changeRightView("allClose");
											}}
										>
											<IoClose />
										</button>
										</li>
									</ul>
								</div>
								<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Relate Knowledge Articles']}</div></div>
								{/* <div className="border-b padding-b-10 margin-b-10 black f-size-20 fw-300"><b>{this.props.translator['Relate Knowledge Articles']}</b></div> */}
							</Col>
						</Row>
						<div className='rBoxGap'>
							<Row>
								{(messageText) && (<Col md={12}>
									<div className="inlineFields KA-error-msg">
										<span
											style={{ color: (messageType === 'success' ? 'rgb(80, 178, 69)' : '#ff4e00') }}
										>{this.renderLargeTextEllipse(messageText, 50)}</span>
									</div>
								</Col>)}

								{(this.checkEditAllowed()) && !this.props.disableFieldsForGuestRole && (
									<Col md={12}>
										<SearchArticles
											searchArticle={searchArticle}
											showArticleTable={showArticleTable}
											translator={this.props.translator}
											submitSearchArticle={this.submitSearchArticle}
											setStateV1={this.setStateV1}
											linkKnowledgeArticles={this.linkKnowledgeArticles}
											downloadFile={this.downloadFile}
											showDescription={this.showDescription}
											searchFilter={searchFilter}
											checkInsidedApplicable={checkInsidedApplicable}
											renderLargeTextEllipse={this.renderLargeTextEllipse}
											searchBy={this.state.searchBy}
											setSearchBy={this.setSearchBy}
										/>
									</Col>)}

								<Col md={12}>
									{(isFetchingAttachedKA) ? (
										<div>
											{/* <h2>{this.props.translator['Loading Knowledge Articles']}</h2> */}
											<Spinner spinnerName='three-bounce' />
										</div>
									) : (
										(attachedArticlesList && Array.isArray(attachedArticlesList) && attachedArticlesList.length > 0) && (<AttachedArticles
											removeAttachedArticle={this.removeAttachedArticle}
											downloadFile={this.downloadFile}
											checkEditAllowed={this.checkEditAllowed}
											showDescription={this.showDescription}
											translator={this.props.translator}
											renderLargeTextEllipse={this.renderLargeTextEllipse}
											disableFieldsForGuestRole={this.props.disableFieldsForGuestRole}
										/>)
									)}
								</Col>
							</Row>
						</div>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = ({ spcmReducer, externalSystemAttributesList, insidedTokenDetails, isFetchingDetails_KA, breakFixEditDetails, attachedArticlesList, insidedResultsList }) => {

	return {
		tr: spcmReducer.tr,
		externalSystemAttributesList,
		insidedTokenDetails,
		isFetchingDetails_KA,
		breakFixEditDetails,
		attachedArticlesList,
		insidedResultsList
	};
};


export default connect(mapStateToProps, {
	emptyInsidedReducer, loadArticleResultsForSearchString_KA, loadInsidedArticlesForSearch_KA, getAttachedArticles, linkKnowledgeArticles, articlesLoading_attached_KA, articlesSuccess_attached_KA, removeAttachedArticle, loadDiscourseArticlesForSearch, loadDiscourseArticlesForSearchByTopicId
})(BreakFixKnowledgeArticles);
