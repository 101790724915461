
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Table from "react-bootstrap/Table";
import OnClickOutside from "react-onclickoutside";
import {
  loadOrdersList,
  loadFilterOrderList,
  resetOrdersList,
  loadOrderDetails,
  loadBreakFixOrderDetails,
  loadBreakFixOrderDetailsInteger,
  loadApproverList,
  getQuestionsCategoryWise,
  resetMRQVFilters,
  loadOrdersFilterResultsForSearchString,
  resetSortingArrows,
  sortingArrowsChanged,
  setUrgencyList,
  fetchCancellationType
} from "_Actions/serviceDeskActions/myOrdersAction";
import { loadBreakFixActivityLogDetails } from "_Actions/breakFix/breakFixActivityLogAction";
import {
  loadChangeTotalApprovalDetails,
  loadChangeTotalApprovalHistoryDetails,
} from "_Actions/changeManagement/changeTotalApprovalAction";
import { loadProfile2 } from "_Actions/myProfile/profileAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Pagination from "react-js-pagination";
import LeftSidebarMenulist from "./serviceDeskOrdersFilter.js";
//let user_id=userDetails.user_id;
//let company_id=userDetails.company_id;

let actualItemId = "";
import ListLoader from "../common/loaders/ListLoader.js";
import {IoSearch} from 'react-icons/io5';

import _ from "lodash";
import { loadImplPlanList } from "_Actions/common/attachmentAction";
//import ExportCSV from "./serviceDeskExportCSV.js";
import Datetime from "react-datetime";

import Cookies from 'universal-cookie';
const cookies = new Cookies();
let languageSelected = cookies.get("gph");
languageSelected = languageSelected.split("~");
languageSelected = languageSelected[20];

class XsmBreakFixFormTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAction: "",
      faCustomDesc1: "customArrow dropdown",
      faCustomAsc1: "customArrow dropup",
      faCustomDesc2: "customArrow dropdown",
      faCustomAsc2: "customArrow dropup",
      faCustomDesc3: "customArrow dropdown",
      faCustomAsc3: "customArrow dropup",
      faCustomDesc4: "customArrow dropdown isActive",
      faCustomAsc4: "customArrow dropup",
      faCustomDesc5: "customArrow dropdown",
      faCustomAsc5: "customArrow dropup",
      page: 0,
      size: 10,
      index: 0,
      searchText: "",
      searchFilter: "displayNumber",
      isErrorFilterSearch: false,
      asc_desc: "desc",
      searchclick: false,
      sortBy: "createdOn",
      check_text_field: false,
      sub_drop_down_value: null,
      check_status: 0,
      urgencyList: {}
    };
    this.props.resetOrdersList();
    // this.props.loadOrdersList("requesterId", "type", "", "createdOn", "desc", 0, this.state.size);
    this.props.set_asc_desc("desc");

    this.onSortAsc = this.onSortAsc.bind(this);
    this.onSortDesc = this.onSortDesc.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.showActive = this.showActive.bind(this);
    this.getSelectedSearch = this.getSelectedSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.getSelectedSearchFilter = this.getSelectedSearchFilter.bind(this);
    this.refreshTable = this.refreshTable.bind(this);
    this.getSelected_Sub_SearchFilter =
      this.getSelected_Sub_SearchFilter.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    console.log("this.props.resetSortArrows", this.props.resetSortArrows);
    console.log("nextProps.resetSortArrows", nextProps.resetSortArrows);
    if (
      this.props.resetSortArrows != nextProps.resetSortArrows &&
      nextProps.resetSortArrows == true
    ) {
      console.log("this.props.resetSortArrows changing");
      this.setState({
        sortBy: "createdOn",
        faCustomDesc1: "customArrow dropdown",
        faCustomAsc1: "customArrow dropup",
        faCustomDesc2: "customArrow dropdown",
        faCustomAsc2: "customArrow dropup",
        faCustomDesc3: "customArrow dropdown",
        faCustomAsc3: "customArrow dropup",
        faCustomDesc4: "customArrow dropdown isActive",
        faCustomAsc4: "customArrow dropup",
        faCustomDesc5: "customArrow dropdown",
        faCustomAsc5: "customArrow dropup",
      });
    }
    if (this.props.ordersList.length > 0) {
      this.setState({ isErrorFilterSearch: false });
    }
  }

  onSortAsc(field) {
    let searchInputVar = undefined;
    if (this.state.sub_drop_down_value != null)
      searchInputVar = this.state.sub_drop_down_value;
    else searchInputVar = this.searchInput.value;

    if (this.searchInput == null) {
      this.searchInput = { value: this.state.sub_drop_down_value };
    }
    //let searchInputVar = this.searchInput.value;
    let searchParams = {};
    searchParams = this.props.buildSearchParamsForOrderFilter();
    switch (field) {
      case "requestId":
        this.setState({
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup isActive",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
        });
        if (_.isEmpty(searchParams)) {
          if (this.state.faCustomAsc1 != "customArrow dropup isActive") {
            if (
              this.searchInput.value != "" &&
              this.state.searchFilter &&
              searchInputVar &&
              typeof searchInputVar == "string" &&
              searchInputVar.trim().length
            )
              this.props.loadOrdersFilterResultsForSearchString(
                "requesterId",
                "type",
                this.state.searchFilter,
                "",
                "displayNumber",
                "asc",
                0,
                10,
                searchInputVar.trim()
              );
            else
              this.props.loadOrdersList(
                "requesterId",
                "type",
                "",
                "displayNumber",
                "asc",
                0,
                10
              );
            this.setState({ sortBy: "displayNumber" });
          }
        } else {
          if (this.state.faCustomAsc1 != "customArrow dropup isActive") {
            if (
              this.searchInput.value != "" &&
              this.state.searchFilter &&
              searchInputVar &&
              typeof searchInputVar == "string" &&
              searchInputVar.trim().length
            )
              this.props.loadFilterOrderList(
                searchParams,
                0,
                10,
                "displayNumber",
                "asc",
                this.state.searchFilter,
                searchInputVar.trim()
              );
            else
              this.props.loadFilterOrderList(
                searchParams,
                0,
                10,
                "displayNumber",
                "asc"
              );
            this.setState({ sortBy: "displayNumber" });
          }
        }
        break;
      case "serviceName":
        this.setState({
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup isActive",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
        });
        if (_.isEmpty(searchParams)) {
          if (this.state.faCustomAsc2 != "customArrow dropup isActive") {
            if (
              this.searchInput.value != "" &&
              this.state.searchFilter &&
              searchInputVar &&
              typeof searchInputVar == "string" &&
              searchInputVar.trim().length
            )
              this.props.loadOrdersFilterResultsForSearchString(
                "requesterId",
                "type",
                this.state.searchFilter,
                "",
                "offeringNameSx",
                "asc",
                0,
                10,
                searchInputVar.trim()
              );
            else
              this.props.loadOrdersList(
                "requesterId",
                "type",
                "",
                "offeringNameSx",
                "asc",
                0,
                10
              );
            this.setState({ sortBy: "offeringNameSx" });
          }
        } else {
          if (this.state.faCustomAsc2 != "customArrow dropup isActive") {
            if (
              this.searchInput.value != "" &&
              this.state.searchFilter &&
              searchInputVar &&
              typeof searchInputVar == "string" &&
              searchInputVar.trim().length
            )
              this.props.loadFilterOrderList(
                searchParams,
                0,
                10,
                "offeringNameSx",
                "asc",
                this.state.searchFilter,
                searchInputVar.trim()
              );
            else
              this.props.loadFilterOrderList(
                searchParams,
                0,
                10,
                "offeringNameSx",
                "asc"
              );
            this.setState({ sortBy: "offeringNameSx" });
          }
        }
        break;
      case "price":
        this.setState({
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup isActive",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
        });
        if (_.isEmpty(searchParams)) {
          if (this.state.faCustomAsc3 != "customArrow dropup isActive") {
            if (
              this.searchInput.value != "" &&
              this.state.searchFilter &&
              searchInputVar &&
              typeof searchInputVar == "string" &&
              searchInputVar.trim().length
            )
              this.props.loadOrdersFilterResultsForSearchString(
                "requesterId",
                "type",
                this.state.searchFilter,
                "",
                "itemPrice",
                "asc",
                0,
                10,
                searchInputVar.trim()
              );
            else
              this.props.loadOrdersList(
                "requesterId",
                "type",
                "",
                "itemPrice",
                "asc",
                0,
                10
              );
            this.setState({ sortBy: "itemPrice" });
          }
        } else {
          if (this.state.faCustomAsc3 != "customArrow dropup isActive") {
            if (
              this.searchInput.value != "" &&
              this.state.searchFilter &&
              searchInputVar &&
              typeof searchInputVar == "string" &&
              searchInputVar.trim().length
            )
              this.props.loadFilterOrderList(
                searchParams,
                0,
                10,
                "itemPrice",
                "asc",
                this.state.searchFilter,
                searchInputVar.trim()
              );
            else
              this.props.loadFilterOrderList(
                searchParams,
                0,
                10,
                "itemPrice",
                "asc"
              );
            this.setState({ sortBy: "itemPrice" });
          }
        }
        break;
      case "submittedDate":
        this.setState({
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup isActive",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
        });
        if (_.isEmpty(searchParams)) {
          if (this.state.faCustomAsc4 != "customArrow dropup isActive") {
            if (
              this.searchInput.value != "" &&
              this.state.searchFilter &&
              searchInputVar &&
              typeof searchInputVar == "string" &&
              searchInputVar.trim().length
            )
              this.props.loadOrdersFilterResultsForSearchString(
                "requesterId",
                "type",
                this.state.searchFilter,
                "",
                "createdOn",
                "asc",
                0,
                10,
                searchInputVar.trim()
              );
            else
              this.props.loadOrdersList(
                "requesterId",
                "type",
                "",
                "createdOn",
                "asc",
                0,
                10
              );
            this.setState({ sortBy: "createdOn" });
          }
        } else {
          if (this.state.faCustomAsc4 != "customArrow dropup isActive") {
            if (
              this.searchInput.value != "" &&
              this.state.searchFilter &&
              searchInputVar &&
              typeof searchInputVar == "string" &&
              searchInputVar.trim().length
            )
              this.props.loadFilterOrderList(
                searchParams,
                0,
                10,
                "createdOn",
                "asc",
                this.state.searchFilter,
                searchInputVar.trim()
              );
            else
              this.props.loadFilterOrderList(
                searchParams,
                0,
                10,
                "createdOn",
                "asc"
              );
            this.setState({ sortBy: "createdOn" });
          }
        }
        break;
      case "status":
        this.setState({
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup isActive",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
        });
        if (_.isEmpty(searchParams)) {
          if (this.state.faCustomAsc5 != "customArrow dropup isActive") {
            if (
              this.searchInput.value != "" &&
              this.state.searchFilter &&
              searchInputVar &&
              typeof searchInputVar == "string" &&
              searchInputVar.trim().length
            )
              this.props.loadOrdersFilterResultsForSearchString(
                "requesterId",
                "type",
                this.state.searchFilter,
                "",
                "itemStatus",
                "asc",
                0,
                10,
                searchInputVar.trim()
              );
            else
              this.props.loadOrdersList(
                "requesterId",
                "type",
                "",
                "itemStatus",
                "asc",
                0,
                10
              );
            this.setState({ sortBy: "itemStatus" });
          }
        } else {
          if (this.state.faCustomAsc5 != "customArrow dropup isActive") {
            if (
              this.searchInput.value != "" &&
              this.state.searchFilter &&
              searchInputVar &&
              typeof searchInputVar == "string" &&
              searchInputVar.trim().length
            )
              this.props.loadFilterOrderList(
                searchParams,
                0,
                10,
                "itemStatus",
                "asc",
                this.state.searchFilter,
                searchInputVar.trim()
              );
            else
              this.props.loadFilterOrderList(
                searchParams,
                0,
                10,
                "itemStatus",
                "asc"
              );
            this.setState({ sortBy: "itemStatus" });
          }
        }
        break;
    }
    this.setState({
      asc_desc: "asc",
    });
    this.props.showActiveRow(null, 0);
    this.props.set_asc_desc("asc");
    this.props.sortingArrowsChanged();
  }

  onSortDesc(field) {
    let searchInputVar = undefined;
    if (this.state.sub_drop_down_value != null)
      searchInputVar = this.state.sub_drop_down_value;
    else searchInputVar = this.searchInput.value;

    if (this.searchInput == null) {
      this.searchInput = { value: this.state.sub_drop_down_value };
    }
    //let searchInputVar = this.searchInput.value;
    let searchParams = {};
    searchParams = this.props.buildSearchParamsForOrderFilter();
    switch (field) {
      case "requestId":
        this.setState({
          faCustomDesc1: "customArrow dropdown isActive",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
        });
        if (_.isEmpty(searchParams)) {
          if (this.state.faCustomDesc1 != "customArrow dropdown isActive") {
            if (
              this.searchInput.value != "" &&
              this.state.searchFilter &&
              searchInputVar &&
              typeof searchInputVar == "string" &&
              searchInputVar.trim().length
            )
              this.props.loadOrdersFilterResultsForSearchString(
                "requesterId",
                "type",
                this.state.searchFilter,
                "",
                "displayNumber",
                "desc",
                0,
                10,
                searchInputVar.trim()
              );
            else
              this.props.loadOrdersList(
                "requesterId",
                "type",
                "",
                "displayNumber",
                "desc",
                0,
                10
              );
            this.setState({ sortBy: "displayNumber" });
          }
        } else {
          if (this.state.faCustomDesc1 != "customArrow dropdown isActive") {
            if (
              this.searchInput.value != "" &&
              this.state.searchFilter &&
              searchInputVar &&
              typeof searchInputVar == "string" &&
              searchInputVar.trim().length
            )
              this.props.loadFilterOrderList(
                searchParams,
                0,
                10,
                "displayNumber",
                "desc",
                this.state.searchFilter,
                searchInputVar.trim()
              );
            else
              this.props.loadFilterOrderList(
                searchParams,
                0,
                10,
                "displayNumber",
                "desc"
              );
            this.setState({ sortBy: "displayNumber" });
          }
        }
        break;
      case "serviceName":
        this.setState({
          faCustomDesc2: "customArrow dropdown isActive",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
        });
        if (_.isEmpty(searchParams)) {
          if (this.state.faCustomDesc2 != "customArrow dropdown isActive") {
            if (
              this.searchInput.value != "" &&
              this.state.searchFilter &&
              searchInputVar &&
              typeof searchInputVar == "string" &&
              searchInputVar.trim().length
            )
              this.props.loadOrdersFilterResultsForSearchString(
                "requesterId",
                "type",
                this.state.searchFilter,
                "",
                "offeringNameSx",
                "desc",
                0,
                10,
                searchInputVar.trim()
              );
            else
              this.props.loadOrdersList(
                "requesterId",
                "type",
                "",
                "offeringNameSx",
                "desc",
                0,
                10
              );
            this.setState({ sortBy: "offeringNameSx" });
          }
        } else {
          if (this.state.faCustomDesc2 != "customArrow dropdown isActive") {
            if (
              this.searchInput.value != "" &&
              this.state.searchFilter &&
              searchInputVar &&
              typeof searchInputVar == "string" &&
              searchInputVar.trim().length
            )
              this.props.loadFilterOrderList(
                searchParams,
                0,
                10,
                "offeringNameSx",
                "desc",
                this.state.searchFilter,
                searchInputVar.trim()
              );
            else
              this.props.loadFilterOrderList(
                searchParams,
                0,
                10,
                "offeringNameSx",
                "desc"
              );
            this.setState({ sortBy: "offeringNameSx" });
          }
        }
        break;
      case "price":
        this.setState({
          faCustomDesc3: "customArrow dropdown isActive",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
        });
        if (_.isEmpty(searchParams)) {
          if (this.state.faCustomDesc3 != "customArrow dropdown isActive") {
            if (
              this.searchInput.value != "" &&
              this.state.searchFilter &&
              searchInputVar &&
              typeof searchInputVar == "string" &&
              searchInputVar.trim().length
            )
              this.props.loadOrdersFilterResultsForSearchString(
                "requesterId",
                "type",
                this.state.searchFilter,
                "",
                "itemPrice",
                "desc",
                0,
                10,
                searchInputVar.trim()
              );
            else
              this.props.loadOrdersList(
                "requesterId",
                "type",
                "",
                "itemPrice",
                "desc",
                0,
                10
              );
            this.setState({ sortBy: "itemPrice" });
          }
        } else {
          if (this.state.faCustomDesc3 != "customArrow dropdown isActive") {
            if (
              this.searchInput.value != "" &&
              this.state.searchFilter &&
              searchInputVar &&
              typeof searchInputVar == "string" &&
              searchInputVar.trim().length
            )
              this.props.loadFilterOrderList(
                searchParams,
                0,
                10,
                "itemPrice",
                "desc",
                this.state.searchFilter,
                searchInputVar.trim()
              );
            else
              this.props.loadFilterOrderList(
                searchParams,
                0,
                10,
                "itemPrice",
                "desc"
              );
            this.setState({ sortBy: "itemPrice" });
          }
        }
        break;
      case "submittedDate":
        this.setState({
          faCustomDesc4: "customArrow dropdown isActive",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
        });
        if (_.isEmpty(searchParams)) {
          if (this.state.faCustomDesc4 != "customArrow dropdown isActive") {
            if (
              this.searchInput.value != "" &&
              this.state.searchFilter &&
              searchInputVar &&
              typeof searchInputVar == "string" &&
              searchInputVar.trim().length
            )
              this.props.loadOrdersFilterResultsForSearchString(
                "requesterId",
                "type",
                this.state.searchFilter,
                "",
                "createdOn",
                "desc",
                0,
                10,
                searchInputVar.trim()
              );
            else
              this.props.loadOrdersList(
                "requesterId",
                "type",
                "",
                "createdOn",
                "desc",
                0,
                10
              );
            this.setState({ sortBy: "createdOn" });
          }
        } else {
          if (this.state.faCustomDesc4 != "customArrow dropdown isActive") {
            if (
              this.searchInput.value != "" &&
              this.state.searchFilter &&
              searchInputVar &&
              typeof searchInputVar == "string" &&
              searchInputVar.trim().length
            )
              this.props.loadFilterOrderList(
                searchParams,
                0,
                10,
                "createdOn",
                "desc",
                this.state.searchFilter,
                searchInputVar.trim()
              );
            else
              this.props.loadFilterOrderList(
                searchParams,
                0,
                10,
                "createdOn",
                "desc"
              );
            this.setState({ sortBy: "createdOn" });
          }
        }
        break;
      case "status":
        this.setState({
          faCustomDesc5: "customArrow dropdown isActive",
          faCustomAsc5: "customArrow dropup",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
        });
        if (_.isEmpty(searchParams)) {
          if (this.state.faCustomDesc5 != "customArrow dropdown isActive") {
            if (
              this.searchInput.value != "" &&
              this.state.searchFilter &&
              searchInputVar &&
              typeof searchInputVar == "string" &&
              searchInputVar.trim().length
            )
              this.props.loadOrdersFilterResultsForSearchString(
                "requesterId",
                "type",
                this.state.searchFilter,
                "",
                "itemStatus",
                "desc",
                0,
                10,
                searchInputVar.trim()
              );
            else
              this.props.loadOrdersList(
                "requesterId",
                "type",
                "",
                "itemStatus",
                "desc",
                0,
                10
              );
            this.setState({ sortBy: "itemStatus" });
          }
        } else {
          if (this.state.faCustomDesc5 != "customArrow dropdown isActive") {
            if (
              this.searchInput.value != "" &&
              this.state.searchFilter &&
              searchInputVar &&
              typeof searchInputVar == "string" &&
              searchInputVar.trim().length
            )
              this.props.loadFilterOrderList(
                searchParams,
                0,
                10,
                "itemStatus",
                "desc",
                this.state.searchFilter,
                searchInputVar.trim()
              );
            else
              this.props.loadFilterOrderList(
                searchParams,
                0,
                10,
                "itemStatus",
                "desc"
              );
            this.setState({ sortBy: "itemStatus" });
          }
        }
        break;
    }
    this.setState({
      asc_desc: "desc",
    });
    this.props.showActiveRow(null, 0);
    this.props.set_asc_desc("desc");
    this.props.sortingArrowsChanged();
  }

  onSort(fieldName) {
    if (this.state.asc_desc == "desc") {
      this.onSortAsc(fieldName);
    } else {
      this.onSortDesc(fieldName);
    }
  }

  onPageChange(e) {
    let searchInputVar = undefined;
    if (this.state.sub_drop_down_value != null)
      searchInputVar = this.state.sub_drop_down_value;
    else searchInputVar = this.searchInput.value;
    //let searchInputVar = this.searchInput.value;

    this.setState({ page: e - 1 });

    let searchParams = {};
    searchParams = this.props.buildSearchParamsForOrderFilter();

    if (_.isEmpty(searchParams)) {
      if (
        this.state.searchFilter &&
        searchInputVar &&
        typeof searchInputVar == "string" &&
        searchInputVar.trim().length
      ) {
        this.setState({ searchclick: true });
        this.props.loadOrdersFilterResultsForSearchString(
          "requesterId",
          "type",
          this.state.searchFilter,
          "",
          this.state.sortBy,
          this.state.asc_desc,
          e - 1,
          this.state.size,
          searchInputVar.trim()
        );
      } else {
        this.props.loadOrdersList(
          "requesterId",
          "type",
          "",
          this.state.sortBy,
          this.state.asc_desc,
          e - 1,
          this.state.size
        );
      }
    } else {
      if (
        this.state.searchFilter &&
        searchInputVar &&
        typeof searchInputVar == "string" &&
        searchInputVar.trim().length
      ) {
        this.setState({ searchclick: true });
        this.props.loadFilterOrderList(
          searchParams,
          e - 1,
          this.state.size,
          this.state.sortBy,
          this.state.asc_desc,
          this.state.searchFilter,
          searchInputVar.trim()
        );
      } else {
        this.props.loadFilterOrderList(
          searchParams,
          e - 1,
          this.state.size,
          this.state.sortBy,
          this.state.asc_desc
        );
      }
    }
    this.props.showActiveRow(null, 0);
  }

  onPageSizeChange(e) {
    let searchInputVar = undefined;
    if (this.state.sub_drop_down_value != null)
      searchInputVar = this.state.sub_drop_down_value;
    else searchInputVar = this.searchInput.value;
    //let searchInputVar = this.searchInput.value;

    let size = parseInt(e.target.value, 10);
    this.setState({ size: size });

    let searchParams = {};
    searchParams = this.props.buildSearchParamsForOrderFilter();

    if (_.isEmpty(searchParams)) {
      if (
        this.state.searchFilter &&
        searchInputVar &&
        typeof searchInputVar == "string" &&
        searchInputVar.trim().length
      ) {
        this.setState({ searchclick: true });
        this.props.loadOrdersFilterResultsForSearchString(
          "requesterId",
          "type",
          this.state.searchFilter,
          "",
          this.state.sortBy,
          this.state.asc_desc,
          0,
          size,
          searchInputVar.trim()
        );
      } else {
        this.props.loadOrdersList(
          "requesterId",
          "type",
          "",
          this.state.sortBy,
          this.state.asc_desc,
          0,
          size
        );
      }
    } else {
      if (
        this.state.searchFilter &&
        searchInputVar &&
        typeof searchInputVar == "string" &&
        searchInputVar.trim().length
      ) {
        this.setState({ searchclick: true });
        this.props.loadFilterOrderList(
          searchParams,
          0,
          size,
          this.state.sortBy,
          this.state.asc_desc,
          this.state.searchFilter,
          searchInputVar.trim()
        );
        // this.props.loadOrdersFilterResultsForSearchString("requesterId", "type", this.state.searchFilter, "", this.state.sortBy, this.state.asc_desc, 0, size, (searchInputVar).trim(), this.props.listFilter);
      } else {
        this.props.loadFilterOrderList(
          searchParams,
          0,
          size,
          this.state.sortBy,
          this.state.asc_desc
        );
      }
    }
    this.props.showActiveRow(null, 0);
  }

  refreshTable() {
    this.setState({ asc_desc: "desc" });
    this.props.set_asc_desc("desc");
    this.props.resetOrdersList();
    this.props.showActiveRow(null, 0);
    // this.props.loadOrdersList("requesterId", "type", "", "createdOn", "desc", 0, 10);
    // this.props.loadCmdbItemList("1", "10");
    // this.props.resetCMDBQVFilters();
    this.props.resetMRQVFilters();
    this.setState({ isErrorFilterSearch: false });
    this.props.resetSortingArrows();
    if (this.searchInput != null) this.searchInput.value = "";
    else this.state.sub_drop_down_value = null;
    //this.searchInput.value = "";
  }

  getSelectedSearchFilter(searchFilter) {
    this.props.showActiveRow(this.props.rowIndex, 1);
    this.setState({ isErrorFilterSearch: false });
    if (searchFilter.target.value == "itemStatus")
      this.setState({
        check_text_field: true,
        searchFilter: searchFilter.target.value,
        searchclick: false,
      });
    else
      this.setState({
        check_text_field: false,
        sub_drop_down_value: null,
        searchFilter: searchFilter.target.value,
        searchclick: false,
      });
    this.searchInput.value = "";
    this.props.setShowCopyServiceForm(false);
  }

  getSelected_Sub_SearchFilter(searchFilter) {
    this.props.showActiveRow(this.props.rowIndex, 1);
    this.setState({
      check_text_field: true,
      sub_drop_down_value: searchFilter.target.value,
      searchclick: false,
    });
  }

  handleSearch() {
    console.log("search============");
    let searchInputVar = undefined;
    if (
      this.state.check_text_field == true &&
      this.state.sub_drop_down_value == null
    )
      this.state.sub_drop_down_value = "90";
    if (this.state.sub_drop_down_value != null)
      searchInputVar = this.state.sub_drop_down_value;
    else searchInputVar = this.searchInput.value;
    this.setState({ isErrorFilterSearch: false });
    this.setState({ asc_desc: "desc" });
    this.props.set_asc_desc("desc");
    let searchParams = {};
    searchParams = this.props.buildSearchParamsForOrderFilter();
    if (_.isEmpty(searchParams)) {
      if (
        this.state.searchFilter &&
        searchInputVar &&
        typeof searchInputVar == "string" &&
        searchInputVar.trim().length
      ) {
        this.setState({ searchclick: true });
        this.props.resetSortingArrows();
        this.props.ordersListFilter2();

        this.props.loadOrdersFilterResultsForSearchString(
          "requesterId",
          "type",
          this.state.searchFilter,
          "",
          "createdOn",
          "desc",
          0,
          10,
          searchInputVar.trim()
        );
        this.props.showActiveRow(null, 0);
        this.props.setShowCopyServiceForm(false);
        // this.props.loadFilterOrderList(searchParams, 0, 10, "createdOn", "desc", this.state.searchFilter, searchInputVar);
      } else {
        this.setState({ isErrorFilterSearch: true });
      }
    } else {
      if (
        this.state.searchFilter &&
        searchInputVar &&
        typeof searchInputVar == "string" &&
        searchInputVar.trim().length
      ) {
        this.setState({ searchclick: true });
        this.props.resetSortingArrows();
        this.props.ordersListFilter2();
        this.props.loadFilterOrderList(
          searchParams,
          0,
          10,
          "createdOn",
          "desc",
          this.state.searchFilter,
          searchInputVar
        );
        this.props.showActiveRow(null, 0);
        this.props.setShowCopyServiceForm(false);
      } else {
        this.setState({ isErrorFilterSearch: true });
      }
    }
  }

  getSelectedSearch(searchText) {
    //alert(searchText.target.value)
    this.setState({
      searchText: searchText.target.value,
    });
  }

  showActive(i) {
    if (this.state.check_status == 0) this.state.check_status++;
    this.setState({ index: i });
  }

  renderData(breakFixData) {
    if (this.searchInput != null) {
      if (this.state.searchclick == false && this.props.searchclick == false)
        this.searchInput.value = "";
      if (this.props.searchclick == false) this.searchInput.value = "";
    }

    // if(this.state.searchclick==false && this.props.searchclick==false)
    //   this.searchInput.value = "";
    // if(this.props.searchclick==false)
    //   this.searchInput.value = "";

    //   if(this.props.searchclick==false)
    //   {
    //   if(this.state.searchclick==false)
    //   this.searchInput.value = "";
    // }
    let userOrders = [];
    if (breakFixData.length == 0 || breakFixData.length == undefined) {
      userOrders = [];
      return (
        <div>
          <ListLoader myStyle={{ marginTop: "20px" }} />
        </div>
        // <div><font color="green" ><b>{this.props.tr['There is no matching data available']}</b></font></div>
      );
    } else {
      for (let i = 0; i <= breakFixData.length - 1; i++) {
        breakFixData[i].itemId =
          breakFixData[i].itemId + "," + breakFixData[i].type;
        userOrders.push(breakFixData[i]);
      }
    }

    if (userOrders.length == 0 || userOrders.length == undefined) {
      return (
        <div className="text-c">{this.props.tr['There is no matching data available']}</div>
      );
    }

    return userOrders.map((elem, index) => {
      let cls = "";
      console.log('===index',index);
      if (index == this.props.rowIndex) {
        cls = "myActive";
      } else {
        cls = "";
      }

      if (
        index == this.props.rowIndex &&
        (this.props.check_status == 0 || this.props.check_status == "")
      ) {
        let getItemId = elem.itemId ? elem.itemId.split(",") : "";
        actualItemId = getItemId[0];
        if (elem.type == "Breakfix") {
          // this.props.loadBreakFixActivityLogDetails(actualItemId, "desc", "Breakfix");
          // this.props.loadBreakFixOrderDetails(actualItemId);
          // this.props.loadProfile2(elem.requesterId);
        } else if (elem.type == "Item") {
          //   this.props.loadBreakFixActivityLogDetails(actualItemId, "desc", "Fullfilment");
          //   this.props.loadOrderDetails(actualItemId);
          //  // this.props.loadApproverList(actualItemId, elem.offeringIdSx);
          //   // this.props.loadChangeTotalApprovalDetails(actualItemId, "10", "N");
          //   // this.props.loadChangeTotalApprovalHistoryDetails(actualItemId, "10", "Y");
          //   this.props.getQuestionsCategoryWise(actualItemId);
          //   this.props.loadProfile2(elem.requesterId);
        }
        // this.props.getOrderType(elem.type);
        // this.props.getOrderDetails(elem.displayNumber);
        // this.props.getItemId(actualItemId);
        // this.props.setShowCopyServiceForm(false)
        // this.props.getOrderStatus(elem.itemStatus);
        // this.props.UpdateExternalRequestNumber(elem.externalId);
      }
      return (
        <tr
          className={cls}
          onClick={() => {
            let getItemId = elem.itemId ? elem.itemId.split(",") : "";
            actualItemId = getItemId[0];
            this.props.setRightSideBar(true);
            if (elem.type == "Breakfix") {
              this.props.loadBreakFixActivityLogDetails(
                actualItemId,
                "desc",
                "Breakfix"
              );
              this.props.loadBreakFixOrderDetails(actualItemId);
              this.props.loadBreakFixOrderDetailsInteger(actualItemId);
              this.props.setUrgencyList(elem.ciConsumerCompany,'Breakfix','Urgency',languageSelected,'1');
              this.props.loadImplPlanList("Breakfix", actualItemId);
              this.props.loadProfile2(elem.requesterId);
            } else if (elem.type == "Item") {
              this.props.loadBreakFixActivityLogDetails(
                actualItemId,
                "desc",
                "Fullfilment"
              );
              this.props.loadOrderDetails(actualItemId);
              //this.props.loadApproverList(actualItemId, elem.offeringIdSx);
              // this.props.loadChangeTotalApprovalDetails(actualItemId, "10", "N");
              // this.props.loadChangeTotalApprovalHistoryDetails(actualItemId, "10", "Y");
              this.props.getQuestionsCategoryWise(actualItemId);
              this.props.loadImplPlanList("Item", actualItemId);
              this.props.loadProfile2(elem.requesterId);
              this.props.fetchCancellationType(elem.companyId,'Fulfillment','Cancelled','1');
            }
            this.props.getOrderType(elem.type);
            this.props.getOrderDetails(elem.displayNumber);
            this.props.getItemId(actualItemId);
            this.props.setShowCopyServiceForm(false);
            this.props.getOrderStatus(elem.itemStatus);
            this.props.showActiveRow(index, 1);
            this.props.UpdateExternalRequestNumber(elem.externalId);
          }}
        >
          <td width="18%">{elem.displayNumber}</td>
          <td>
            <div className='showdv414'>
              <div className='keydv414'>{this.props.tr["Service/CI Name"]}</div>
              <div className='valdv414'>
                {elem.ciServiceName}
              </div>
            </div>
          </td>
          {/* {elem.currency != null ? elem.itemPrice > 0 ? <td>{elem.currency + ' ' +elem.itemPrice}</td> : <td>Not Applicable</td> : <td>Not Applicable</td>} */}
          {elem.currency != null &&
          elem.itemPrice > 0 &&
          elem.isPriceEnabled ? (
            <td>
              <div className='showdv414'>
                <div className='keydv414'>{this.props.tr["Price"]}</div>
                <div className='valdv414'>
                  {elem.currency + " " + elem.itemPrice}
                </div>
              </div>
            </td>
          ) : (
            <td>
              <div className='showdv414'>
                <div className='keydv414'>{this.props.tr["Price"]}</div>
                <div className='valdv414'>
                  {this.props.tr["NA"]}
                </div>
              </div>
            </td>
          )}
          <td>
            <div className='showdv414'>
              <div className='keydv414'>{this.props.tr["Submitted Date"]}</div>
              <div className='valdv414'>
                {elem.createdOn}
              </div>
            </div>
          </td>
          {elem.itemStatus == "Fullfiled" ? (
            <td>
              <div className='showdv414'>
                <div className='keydv414'>{this.props.tr["Status"]}</div>
                <div className='valdv414'>
                  Fulfilled
                </div>
              </div>              
            </td>
          ) : (
            <td>
              <div className='showdv414'>
                <div className='keydv414'>{this.props.tr["Status"]}</div>
                <div className='valdv414'>
                  {elem.itemStatus}
                </div>
              </div>
            </td>
          )}
        </tr>
      );
    });
  }

  render() {
    // console.log('this.props.resetSortArrows===========',this.props.resetSortArrows);
    // console.log('ordersList====');
    // console.log(this.props.ordersList);
    let meta = this.props.ordersListMeta;
    let jwtdf = "MM-DD-YYYY";
    let dateformat =
      this.props &&
      this.props.specificUserDetails &&
      this.props.specificUserDetails.dateformat
        ? this.props.specificUserDetails.dateformat
        : "";
    let dateFormatFirstItem = null;
    let panelColSize = this.props.panelResize;

    if (dateformat) {
      let dateFormatCopy = Object.assign(dateformat);
      if (dateFormatCopy) {
        let dateFormatArr = dateFormatCopy.split(" ");
        if (dateFormatArr && dateFormatArr.length) {
          dateFormatFirstItem = dateFormatArr[0].toUpperCase();
          jwtdf = dateFormatFirstItem;
        }
      }
    }

    let CSVData = [];
    let myObj = {};
    for (let i = 0; i <= this.props.ordersList.length - 1; i++) {
      myObj = {
        Request_ID: "",
        Service_Name: "",
        Price: "",
        Submitted_Date: "",
        Status: "",
      };
      myObj.Request_ID = this.props.ordersList[i].displayNumber;
      myObj.Service_Name = this.props.ordersList[i].offeringNameSx;
      myObj.Price = this.props.ordersList[i].itemPrice;
      myObj.Submitted_Date = this.props.ordersList[i].createdOn;
      myObj.Status = this.props.ordersList[i].itemStatus;
      CSVData.push(myObj);
    }

    // console.log("CSV================>");
    //console.log(CSVData);
    return (
      <div>
        <Row className=" margin-b-5">
          {/* <Col md={7} sm={6} xs={6}><h3 className="margin-0">{this.props.tr['My Orders']} ({meta.recordCount})</h3></Col> */}

          {this.state && this.state.isErrorFilterSearch ? (
            <Col md={12} sm={6} xs={6}>
              <span style={{ color: "red" }}>
                Please enter the required data...
              </span>
            </Col>
          ) : null}

          <Col md={9} sm={9} xs={12}>
            <Form.Group bsPrefix=" " className="myFields myReqst form-inline">
              <Form.Select
                className="myControl"
                onChange={(e) => this.getSelectedSearchFilter(e)}
              >
                <option value="displayNumber">
                  {this.props.tr["Order Id"]}
                </option>
                <option value="employeeNumber">
                  {this.props.tr["Employee ID"]}
                </option>
                {/* <option value="orderNumber">{this.props.tr['Order Number']}</option> */}
                {/* <option value="itemStatus">{this.props.tr['Item Status']}</option>*/}
                <option value="offeringNameSx">
                  {this.props.tr["Service Name"]}
                </option>
                {/* <option value="orderDate">{this.props.tr['Order Date']}</option>*/}
                {/*  <option value="requesterName">{this.props.tr['Requester']}</option>*/}
              </Form.Select>

              {this.state.searchFilter == "orderDate" ? (
                <Form.Group className="dateInfo">
                  <span>From:</span>{" "}
                  <Datetime
                    onChange={(event) => {
                      this.changeDate(event);
                    }}
                    className={
                      this.props.showDateError
                        ? "form-control dateTimePickerField error"
                        : "form-control dateTimePickerField"
                    }
                    locale="en"
                    dateFormat={jwtdf}
                    closeOnSelect
                    timeFormat={true}
                  />
                  <span>To:</span>{" "}
                  <Datetime
                    onChange={(event) => {
                      this.changeDate(event);
                    }}
                    className={
                      this.props.showDateError
                        ? "form-control dateTimePickerField error"
                        : "form-control dateTimePickerField"
                    }
                    locale="en"
                    dateFormat={jwtdf}
                    closeOnSelect
                    timeFormat={true}
                  />
                </Form.Group>
              ) : this.state.check_text_field == true ? (
                <Form.Select
                  className="myControl margin-l-5"
                  onChange={(e) => this.getSelected_Sub_SearchFilter(e)}
                >
                  <option value="90">Approved</option>
                  <option value="85">Cancelled</option>
                  <option value="80">Closed</option>
                  <option value="-20">Draft</option>
                  <option value="75">Fulfilled</option>
                  <option value="60">On Hold</option>
                  <option value="65">Pending Approval</option>
                  <option value="100">Refer Back</option>
                  <option value="70">Under Fulfillment</option>

                  <option value="95">Rejected</option>
                </Form.Select>
              ) : (
                <Form.Control
                  className="myControl myInput margin-l-5 myInputWidth"
                  type="text"
                  onKeyPress={(e) => {
                    if (e.charCode == "13")
                      this.handleSearch();
                  }}
                  ref={(ref) => {
                    this.searchInput = ref;
                  }}
                />
              )}

              <Button
                type="submit"
                className="popFormBtn"
                onClick={() => this.handleSearch()}
                title={this.props.tr["Search"]}
              >
                <IoSearch/>
              </Button>
            </Form.Group>
          </Col>

          <Col md={3} sm={3} xs={12}>
            <div className="innerStatus margin-r-0 padding-r-0 float-r margin-sm-t-5">
              <div className="status float-l">
                <LeftSidebarMenulist
                  showActiveRow={this.props.showActiveRow}
                  ordersListFilter={this.props.ordersListFilter}
                  listFilter={this.props.listFilter}
                  searchclick={this.props.searchclick}
                  tr={this.props.tr}
                  newMobileLeftMenuFilter={this.props.mobileLeftMenuFilter}
                  filterOrder={this.props.filterOrder}
                  buildSearchParamsForOrderFilter={
                    this.props.buildSearchParamsForOrderFilter
                  }
                  setShowCopyServiceForm={this.props.setShowCopyServiceForm}
                />
              </div>
            </div>
          </Col>
        </Row>

        {this.props.showLoader == true ||
        this.props.showLoader_1 == true ||
        this.props.showLoader_2 == true ||
        this.props.showLoader_3 == true ? (
          <ListLoader />
        ) : this.props.ordersList.length == 0 ? (
          <div className="text-c">{this.props.tr['There is no matching data available']}</div>
        ) : (
          <div>
            <div className="table-responsive">
              <Table
                bordered
                condensed
                striped
                hover
                className="sdeskTable tableView nowrapWhright"
                style={{ marginTop: "0px" }}
              >
                <thead className="hiddenBe414">
                  <tr>
                    <th width="20%">
                      <div
                        className="sortParArr"
                        onClick={() => this.onSort("requestId")}
                      >
                        {this.props.tr["Order ID"]}
                        {console.log(
                          "===",
                          this.state.sortBy,
                          this.state.asc_desc
                        )}
                        <div className="sortArr">
                          {this.state.sortBy == "displayNumber" &&
                          this.state.asc_desc == "asc" ? (
                            <span
                              className={this.state.faCustomAsc1}
                              title="Ascending"
                            >
                              <span className="caret"></span>
                            </span>
                          ) : (
                            <span
                              className={this.state.faCustomDesc1}
                              title="Descending"
                            >
                              <span className="caret"></span>
                            </span>
                          )}
                        </div>
                      </div>
                    </th>
                    <th width="35%">
                      <div
                        className="sortParArr"
                        onClick={() => this.onSort("serviceName")}
                      >
                        {this.props.tr["Service/CI Name"]}
                        <div className="sortArr">
                          {this.state.sortBy == "offeringNameSx" &&
                          this.state.asc_desc == "asc" ? (
                            <span
                              className={`${this.state.faCustomAsc2}`}
                              title="Ascending"
                            >
                              <span className="caret"></span>
                            </span>
                          ) : (
                            <span
                              className={`${this.state.faCustomDesc2}`}
                              title="Descending"
                            >
                              <span className="caret"></span>
                            </span>
                          )}
                        </div>
                      </div>
                    </th>
                    <th width="15%">
                      <div
                        className="sortParArr"
                        onClick={() => this.onSort("price")}
                      >
                        {this.props.tr["Price"]}
                        <div className="sortArr">
                          {this.state.sortBy == "itemPrice" &&
                          this.state.asc_desc == "asc" ? (
                            <span
                              className={`${this.state.faCustomAsc3}`}
                              title="Ascending"
                            >
                              <span className="caret"></span>
                            </span>
                          ) : (
                            <span
                              className={`${this.state.faCustomDesc3}`}
                              title="Descending"
                            >
                              <span className="caret"></span>
                            </span>
                          )}
                        </div>
                      </div>
                    </th>
                    <th width="15%">
                      <div
                        className="sortParArr"
                        onClick={() => this.onSort("submittedDate")}
                      >
                        {this.props.tr["Submitted Date"]}
                        <div className="sortArr">
                          {this.state.sortBy == "createdOn" &&
                          this.state.asc_desc == "asc" ? (
                            <span
                              className={`${this.state.faCustomAsc4}`}
                              title="Ascending"
                            >
                              <span className="caret"></span>
                            </span>
                          ) : (
                            <span
                              className={`${this.state.faCustomDesc4}`}
                              title="Descending"
                            >
                              <span className="caret"></span>
                            </span>
                          )}
                        </div>
                      </div>
                    </th>
                    <th width="15%">
                      <div
                        className="sortParArr"
                        onClick={() => this.onSort("status")}
                      >
                        {this.props.tr["Status"]}
                        <div className="sortArr">
                          {this.state.sortBy == "itemStatus" &&
                          this.state.asc_desc == "asc" ? (
                            <span
                              className={`${this.state.faCustomAsc5}`}
                              title="Ascending"
                            >
                              <span className="caret"></span>
                            </span>
                          ) : (
                            <span
                              className={`${this.state.faCustomDesc5}`}
                              title="Descending"
                            >
                              <span className="caret"></span>
                            </span>
                          )}
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>{this.renderData(this.props.ordersList)}</tbody>
              </Table>
            </div>
            <div className="margin-t-10 nBotPagina">
              <div className="nShow">
                <div className="margin-r-10">{this.props.tr["Show"]}:</div>
                <div aria-label="Show Count" role="contentinfo">
                  <Form.Select
                    aria-label={this.props.tr["Show"]}
                    value={meta ? meta.size : 10}
                    onChange={this.onPageSizeChange}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </Form.Select>
                </div>
              </div>
              <nav aria-label="Pagination" className="display-inline-block">
                <Pagination
                  prevPageText={this.props.tr['Prev']}
                  nextPageText={this.props.tr['Next']}
                  firstPageText={<i className='glyphicon glyphicon-menu-left' />}
                  lastPageText={<i className='glyphicon glyphicon-menu-right' />}
                  activePage={meta ? meta.currentPage + 1 : 1}
                  itemsCountPerPage={this.state.size}
                  totalItemsCount={meta ? meta.recordCount : 1}
                  pageRangeDisplayed={panelColSize <= 42 ? 2 : 5}
                  onChange={this.onPageChange}
                />
              </nav>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export function mapStateToProps({
  ordersList,
  showLoader,
  resetSortArrows,
  showLoader_1,
  showLoader_2,
  showLoader_3,
}) {
  let data = JSON.parse(JSON.stringify(ordersList.data ? ordersList.data : []));
  if (data == undefined) data = [];
  return {
    ordersList: data,
    ordersListMeta: ordersList.meta,
    showLoader: showLoader.loading,
    resetSortArrows,
    showLoader_1: showLoader_1.loading,
    showLoader_2: showLoader_2.loading,
    showLoader_3: showLoader_3.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadOrdersList,
      resetOrdersList,
      loadFilterOrderList,
      loadOrderDetails,
      loadImplPlanList,
      loadBreakFixOrderDetails,
      loadBreakFixOrderDetailsInteger,
      loadBreakFixActivityLogDetails,
      loadApproverList,
      getQuestionsCategoryWise,
      loadChangeTotalApprovalDetails,
      loadChangeTotalApprovalHistoryDetails,
      resetMRQVFilters,
      loadOrdersFilterResultsForSearchString,
      resetSortingArrows,
      sortingArrowsChanged,
      loadProfile2,
      setUrgencyList,
      fetchCancellationType
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(XsmBreakFixFormTab);

//export default XsmBreakFixFormTab;
