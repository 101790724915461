
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import XsmUserDetails from '_Commons/WorkItemBoard/xsmEditRequestedBy.js';
import { Scrollbars } from "react-custom-scrollbars";
import {ImInfo} from 'react-icons/im';

import { loadTaskAuditLogDetails } from "../../../../actions/task/taskAuditLogAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ListLoader from "../../../common/loaders/ListLoader";
import moment from "moment";

import Cookies from "universal-cookie";
import datetimeConvertor from "../../../../helpers/ISO8601converter";
import ReactReadMoreLess from "../../../../helpers/ReactReadMoreLess";
const cookies = new Cookies();

let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
timezone = timezone.split("~");
timezone = timezone[3];

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
//console.log("homepage::::"+homepagelocation);
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];

const MoreArrow = (
  <i title="More" className="moreArrow fa fa-angle-double-down"></i>
);
const LessArrow = (
  <i title="Less" className="margin-l-5 lessArrow fa fa-angle-double-up"></i>
);

class PopoverAuditLogData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverlayTrigger:true
    };
  }

  componentWillMount() {
    let taskCode = this.props.taskCode;
    // let taskCode = 'TSK000000000112';
    this.props.loadTaskAuditLogDetails(taskCode, "desc",this.props?.isLoaderRequired,'myRequest');
    if(this.props.isOverlayTrigger != undefined){
      this.setState({ isOverlayTrigger:false});
    }
  }

  renderAuditLog(auditLogData) {
    // try {
    // } catch (error) {
    // }
    console.log("auditLogData", auditLogData);
    const groupActivityLog = auditLogData.reduce((obj, index) => {
      //const date = moment(index.createdOn).format(jwtdf);
      const date = index.updatedOn.split("T")[0];
      if (!obj[date]) {
        obj[date] = [];
      }
      obj[date].push(index);
      return obj;
    }, {});

    console.log("groupActivityLog_00", groupActivityLog);
    let oldValueData="",newValueData=""; 

    // return auditLogData.map((auditObj, index) => {
    return Object.keys(groupActivityLog).map((auditObj, index) => {
      console.log("auditObj", auditObj);
      return (
        <div id="tskAudLog" className="actCommts">
          {/* <div className='hed' key={index}>{moment(auditObj).format(jwtdf)}</div> */}
          {groupActivityLog[auditObj].map((comment, childKey) => {
            let fieldName = comment.fieldName.replace(/_/g, " ");
            let oldVal = "",
              newVal = ""; 
              if(comment.fieldName==='TAG'){
               // comment.oldValue = comment.oldValue=="[]"?"NA":comment.oldValue.substring(1,comment.oldValue.length-1);
               // comment.newValue = comment.newValue=="[]"?"NA":comment.newValue.substring(1,comment.newValue.length-1);  
                oldValueData = comment.oldValue=="[]"?"NA":comment.oldValue.substring(1,comment.oldValue.length-1);
                newValueData = comment.newValue=="[]"?"NA":comment.newValue.substring(1,comment.newValue.length-1);  

              }
            if (comment.oldValue == "" || comment.oldValue == null) {
              oldVal = "NA";
            } else {
              // oldVal = moment(comment.oldValue).format(jwtdf+ " hh:mm A")
              let newDate = new Date(
                datetimeConvertor(
                  moment(comment.oldValue).format(jwtdf + " HH:mm:ss"),
                  jwtdf + " HH:mm:ss"
                )
              ).toLocaleString("en-US", { timeZone: timezone });
              oldVal = moment(newDate).format(jwtdf + " hh:mm A");
            }
            if (comment.newValue == "" || comment.newValue == null) {
              newVal = "NA";
            } else {
              newVal = moment(comment.newValue).format(jwtdf + " hh:mm A");
              let newDate = new Date(
                datetimeConvertor(
                  moment(comment.newValue).format(jwtdf + " HH:mm:ss"),
                  jwtdf + " HH:mm:ss"
                )
              ).toLocaleString("en-US", { timeZone: timezone });
              newVal = moment(newDate).format(jwtdf + " hh:mm A");
            }
            let actDateTimeFormat = jwtdf + " hh:mm A";
            let myDateObj,
              endDate,
              endDateStr,
              timeago,
              startTime,
              duration,
              hours,
              isoDate,
              newDate;
            newDate = new Date(comment.updatedOn).toLocaleString("en-US", {
              timeZone: timezone,
            });
            myDateObj = moment(newDate).format(jwtdf + " HH:mm:ss");
            isoDate = datetimeConvertor(myDateObj, jwtdf + " HH:mm:ss");
            myDateObj = moment(isoDate).format(actDateTimeFormat);
            endDate = moment(myDateObj, actDateTimeFormat);
            endDateStr = endDate._i;
            startTime = moment(
              new Date().toLocaleString("en-US", { timeZone: timezone })
            );
            timeago = moment(endDateStr, actDateTimeFormat).from(startTime);
            duration = moment.duration(startTime.diff(endDate));
            hours = duration.asHours();
            comment.createdBy= comment.createdBy!==null?comment.createdBy:'';
            const isAutoUpdated = comment.createdByName === 'System' || comment.createdByName === 'system' || comment.createdByName === 'SYSTEM' ? true : false;
            const tooltip = (
                <Popover className="userInfoPopup">
                <XsmUserDetails userId={comment.createdBy} translator={this.props.translator} isHeadingVisible={false} isPopup={true} />
                </Popover>
            );

            return (
              <Card key={childKey} bsPrefix=' ' className='mediaCrd'>
                <div className="circleTxt">
                <div className="subhed">
                {this.state.isOverlayTrigger && !isAutoUpdated ? <OverlayTrigger trigger="click" rootClose placement={this.props.windowSize.width < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
}overlay={tooltip}><span className="circleNa" title={comment.createdByName}>{comment.createdByName} <ImInfo /></span></OverlayTrigger>:<span className="circleNa" title={comment.createdByName}>{comment.createdByName}</span>}
                  <span
                    className="margin-l-5 timeao"
                    title={hours >= 24 ? "" : endDateStr}
                  >
                    {hours >= 24 ? endDateStr : timeago}
                  </span>
                </div>
                {fieldName == "EXPECTED START DATE" ||
                fieldName == "EXPECTED END DATE" ||
                fieldName == "ACTUAL START DATE" ||
                fieldName == "ACTUAL END DATE" ? (
                  <div className="desFld">
                    <div title={this.props.translator["Field Name"]} className="fenme">
                      {fieldName}
                    </div>
                    <div title={this.props.translator["New Value"]} className="nwnme commn">
                      {newVal}
                    </div>
                    <div title={this.props.translator["Old Value"]} className="oldnme commn">
                      {oldVal}
                    </div>
                  </div>
                ) : (
                  <div className="desFld">
                    <div title={this.props.translator["Field Name"]} className="fenme">
                      {fieldName}
                    </div>
                    {comment.newValue == null || comment.newValue == "" ? (
                      <div title={this.props.translator["New Value"]} className="nwnme commn">
                        NA
                      </div>
                    ) : (
                      <div title={this.props.translator["New Value"]} className="nwnme commn">
                        {/* <ReactReadMoreLess 
                        charLimit={48}
                        readMoreText={MoreArrow}
                        readLessText={LessArrow}
                        content={comment.newValue}
                        /> */}
                       { comment.fieldName === 'TAG' ? newValueData : comment.newValue }
                      </div>
                    )}
                    {comment.oldValue == null || comment.oldValue == "" ? (
                      <div title={this.props.translator["Old Value"]} className="oldnme commn">
                        NA
                      </div>
                    ) : (
                      <div title={this.props.translator["Old Value"]} className="oldnme commn">
                        {/* <ReactReadMoreLess 
                        charLimit={48}
                        readMoreText={MoreArrow}
                        readLessText={LessArrow}
                        content={comment.oldValue}
                        /> */}
                       { comment.fieldName === 'TAG' ? oldValueData : comment.oldValue }
                      </div>
                    )}
                  </div>
                )}
              </div>
                {/* <div className='f-size-12' style={{"marginTop":"2px"}}>{timeago}</div> */}
              </Card>
            );
          })}
        </div>
      );
    });
  }

  ascsort() {
    let taskCode = this.props.taskCode;
    this.props.loadTaskAuditLogDetails(taskCode, "asc",this.props?.isLoaderRequired,'myRequest');
  }

  descsort() {
    let taskCode = this.props.taskCode;
    this.props.loadTaskAuditLogDetails(taskCode, "desc",this.props?.isLoaderRequired,'myRequest');
  }

  render() {
    if (this.props.showLoader || this.props.taskAuditLogDetails == null) {
      return (
        <div>
          {/* <div className="f-size-18 margin-b-5">{this.props.translator['Audit Log']}</div> */}
          <ListLoader />
        </div>
      );
    } else {
      if (
        this.props.taskAuditLogDetails &&
        this.props.taskAuditLogDetails.length == 0
      ) {
        return (
          <div>
            {/* <div className="f-size-18 margin-b-5">{this.props.translator['Audit Log']}</div> */}
            {this.props.translator["No Data Available"]}.
          </div>
        );
      } else if (
        this.props.taskAuditLogDetails &&
        this.props.taskAuditLogDetails.length > 0
      ) {
        return (
          <div className="tskLog">
            {/* <div className="f-size-18 margin-b-5">{this.props.translator['Audit Log']}</div> */}
            {this.props.showLoader ? (
              <div className="margin-t-5 padding-b-5 border-b">
                <span className="float-r f-size-17">
                  <a
                    href="javascript:void(0)"
                    className="black"
                    title={this.props.translator["Ascending"]}
                  >
                    <i className="fa fa-angle-up"></i>
                  </a>
                  &nbsp;&nbsp;
                  <a
                    href="javascript:void(0)"
                    className="black"
                    title={this.props.translator["Descending"]}
                  >
                    <i className="fa fa-angle-down"></i>
                  </a>
                </span>
              </div>
            ) : (
              <div className="margin-t-5 padding-b-5 border-b">
                <span className="float-r f-size-17">
                  <a
                    href="javascript:void(0)"
                    onClick={this.ascsort.bind(this)}
                    className="black"
                    title={this.props.translator["Ascending"]}
                  >
                    <i className="fa fa-angle-up"></i>
                  </a>
                  &nbsp;&nbsp;
                  <a
                    href="javascript:void(0)"
                    onClick={this.descsort.bind(this)}
                    className="black"
                    title={this.props.translator["Descending"]}
                  >
                    <i className="fa fa-angle-down"></i>
                  </a>
                </span>
              </div>
            )}
            {this.renderAuditLog(this.props.taskAuditLogDetails)}
          </div>
        );
      }
    }
  }
}

export function mapStateToProps({ taskAuditLogDetails, showLoader, windowSize }) {
  return { taskAuditLogDetails, showLoader: showLoader.loading, windowSize };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadTaskAuditLogDetails }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopoverAuditLogData);
