
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Field, reduxForm, SubmissionError, change, reset } from "redux-form";
import { connect, useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
const cookies = new Cookies();
import { getEditFormNEDetails,getCompanyList,getTemplateVariables } from "../../../../actions/spcmActions";
import { useLocation } from "react-router";
import {
  _inputField,
  _textArea,
  _dropDown,
  _dropDownNotification,
} from "../../../common/ReduxFormFields/formFields";

let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];

const validate = (values) => {
  let errors = {};
  // if (!values.COMPANY_ID || values.COMPANY_ID.trim().length === 0 || values.COMPANY_ID === "0") {
  //   errors.COMPANY_ID = "Please Select Company Name";
  // }
 
  if (
    !values.NOTIFICATION_NAME ||
    values.NOTIFICATION_NAME.trim().length === 0
  ) {
    errors.NOTIFICATION_NAME = "Please Select Notification Name";
  }
	if (values.NOTIFICATION_NAME != undefined || values.NOTIFICATION_NAME != null) {
  if (values.NOTIFICATION_NAME.length >95){
      errors.NOTIFICATION_NAME = "Notification Name Size Exceeded (Max. 95 Characters)";
    }
  }
  if (
    !values.STATUS ||
    values.STATUS === "0" ||
    values.STATUS.trim().length === 0
  ) {
    errors.STATUS = "Please Select Status";
  }
  if (
    !values.SUBJECT ||
    values.SUBJECT.trim().length === 0
  ) {
    errors.SUBJECT = "Please add the Subject";
  }
  return errors;
};

const NotificationTemplateEditForm = (props) => {
  const customVariablesReducer = useSelector((state) => state.customVariablesReducer);
  const [portalErr, setPortalErr] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();
  const [dbtemplate, setDbtemplate] = useState();
  const [additionalcharleft, setAdditionalcharleft] = useState(100);
  const [additionalSubjectcharleft, setAdditionalSubjectcharleft] = useState(500);

  useEffect(() => {
    let id = props.ID;
    let localeID = props.LOCALEID;
    let tenantId = location.state.tenantId;
    dispatch(getEditFormNEDetails(id,localeID, tenantId));
    dispatch(getTemplateVariables(id, localeID));
    dispatch(getCompanyList(""));
  }, [])
  
  const onStatusChange = (e) => {
    props.statusValueNE(e.target.value);
  }
  const onCompanychange = (e) => {
    props.setChangedCompanyName(e.target[e.target.selectedIndex].text);
    props.setChangedCompanyID(e.target.value);
  }
  const onNotificNameChange = (e) => {
    props.setChangedName(e.target.value);
    let input = e.target.value.length;
		let additionalchar = 100 - input;
		setAdditionalcharleft(additionalchar)
		console.log("e.target.value", input);
  }
  const onSubjectChange = (e) => {
    props.setSubjectContent(e.target.value);
    let input = e.target.value.length;
    let additionalchar = 500 - input;
    setAdditionalSubjectcharleft(additionalchar)
  }

  const renderData = (data) => {
    if (data && Object.keys(data).length != 0) {
      props.renderEditForm(
        data.STATUS,
        data.MODULE_NAME,
        data.NOTIFICATION_NAME,
        data.SUBJECT,
        data.MESSAGE,
        data.TO_EMAIL,
        data.CC_EMAIL,
        data.COMPANY_NAME,
        data.EVENT_TYPE,
        data.MODID,
        data.TEMPLATE_BODY,
        data.COMPANY_ID
      );
    }
  }
  
  const spcmReducer = useSelector((state) => state.spcmReducer);
  let optionsList1 = spcmReducer.companyList
    ? spcmReducer.companyList.map((item) => item)
    : [];
  let optionsList = [];
  if (optionsList1.length != 0) {
    optionsList = optionsList1.map((item) => {
      item.field1Key =
        item.field1Key.length >= 70
          ? `${item.field1Key.slice(0, 70)}...`
          : item.field1Key;
      return item;
    });
  }
  useEffect(() => {
    if (props.initialValues && props.initialValues.NOTIFICATION_NAME) {
      dispatch(
        change(
          "editFormNE",
          "NOTIFICATION_NAME", props.initialValues.NOTIFICATION_NAME
        )
      );
      dispatch(
        change(
          "editFormNE",
          "SUBJECT", props.initialValues.SUBJECT
        )
      );
		let charLength = 100 - props.initialValues.NOTIFICATION_NAME.length;
    
		setAdditionalcharleft(charLength)
      let subjectCharLength = 500 - props.initialValues.SUBJECT.length;
      setAdditionalSubjectcharleft(subjectCharLength)
      // dispatch(change("editFormNE", "COMPANY_ID",props.initialValues.COMPANY_ID))
    }
  }, [props.initialValues]);

  let editFormNe = props.neReducer[0];
  renderData(editFormNe);

  useEffect(() => {
    if(props.initialValues){
      let dbtemp = props.initialValues.TEMPLATE_BODY.replace('\"','"');
      // dbtemp = dbtemp.replace(/"/g, "'");
        setDbtemplate(dbtemp);
        if (document.getElementById("bodyPart")) {
          document
            .getElementById("bodyPart")
            .addEventListener("mousedown", handleEvListener);
          document
            .getElementById("bodyPart")
            .setAttribute("contentEditable", "true");
        }
        props.setChangedBody(dbtemp)
      }
  }, [props.initialValues]);

  useEffect(() => {
    if (document.getElementById("bodyPart")) {
      document
        .getElementById("bodyPart")
        .addEventListener("mousedown", handleEvListener);
      document
        .getElementById("bodyPart")
        .setAttribute("contentEditable", "true");
    }
  }, [dbtemplate]);

  const handleEvListener = (event) => {
    document
        .getElementById("bodyPart")
        .addEventListener("mousedown", handleEvListener);
      document
        .getElementById("bodyPart")
        .setAttribute("contentEditable", "true");
    // let insertRenderVariables = [
    //   { name: "User Name", key: "userName" },
    //   { name: "Requester Name", key: "requesterName" },
    //   { name: "Offering Name", key: "offeringName" },
    //   { name: "Order Number", key: "orderNumber" },
    //   { name: "Breakfix Number", key: "breakfixNumber" },
    //   { name: "Target For", key: "targetFor" },
    //   { name: "Priority", key: "priority" },
    //   { name: "Requested On", key: "requestedOn" },
    //   { name: "Description", key: "description" },
    //   { name: "Service or CI Name", key: "serviceorCIName" },
    //   { name: "Due By", key: "dueBy" },
    // ];
    let insertRenderVariables = customVariablesReducer;

    let elementNew, myObj, dropdownId, buttonObj, finalResult, parentDiv;
    dropdownId = "div1";
    elementNew = event.target;
    buttonObj = event.target.classList.contains("varModify");

    let str = elementNew.innerHTML;
    let res = str.replace(
      "$",
      "<span tabindex='0' role='link' class='varModify' style='font-weight: 600;'><%=Select%></span>"
    );
    elementNew.innerHTML = res;

    elementNew.querySelectorAll(".varModify").forEach((myChild) => {
      let newChild = myChild.querySelector('select');
      // if(newChild)
      newChild?.remove();
      document.getElementById(dropdownId)?.remove();
    });
    if (buttonObj) {
      let arr = document.getElementsByClassName("selectbox");
      [...arr].forEach((item) => {
        item.remove();
      });

      myObj = document.createElement("select");
      myObj.id = dropdownId;
      myObj.className = "selectbox";
      let option = document.createElement("option");
      option.setAttribute("value", "Select");
      option.text = "Select";
      myObj.appendChild(option);
      for (const myData of insertRenderVariables) {
        let dyaOption = document.createElement("option");
        dyaOption.setAttribute("value", myData.key);
        dyaOption.text = myData.key;
        myObj.appendChild(dyaOption);
      }
      elementNew.appendChild(myObj);
    }

    document.getElementById(dropdownId)?.addEventListener("change", (e) => {
      finalResult = e.target.value;
      // elementNew.innerHTML = "<%=" + finalResult + "%>";
      elementNew.innerHTML =  finalResult;
      e.target.remove();
      let parentDiv = document.getElementById("editableTextArea").innerHTML;
      dispatch(change("editFormNE", "MESSAGE", parentDiv));
      console.log("-----------parentDiv------------")
      console.log(parentDiv)
      if(parentDiv.includes(`&nbsp;`) || parentDiv.includes(`<div><br></div>`) || parentDiv.includes(`<div>&nbsp;</div>`) || parentDiv.includes(`<div></div>`) ||  parentDiv.includes(`<div id="bodyPart"><br></div>`)){
        parentDiv = parentDiv.replace(/ &nbsp; /g,'');
        parentDiv = parentDiv.replace(/ &nbsp;/g,'');
        parentDiv = parentDiv.replace(/&nbsp; /g,'');
        parentDiv = parentDiv.replace(/&nbsp;/g,'');
        parentDiv = parentDiv.replace(/<div>\s+<\/div>/g, '');
        parentDiv = parentDiv.replace(/\s+<\/div>/g, '</div>');
        parentDiv = parentDiv.replace(/<div><br><\/div>/g,'');
        parentDiv = parentDiv.replace(/<div><\/div>/g, ''); 
        parentDiv = parentDiv.replace(/<div id="bodyPart"><br><\/div>/g,'<div id="bodyPart"></div>')
        console.log("-----------parentDiv2------------")
        console.log(parentDiv)
             
      }
    
     props.setChangedBody(parentDiv);
    
      
    });

    window.addEventListener("click", (e) => {
      if (document.getElementById("bodyPart").contains(e.target)) {
      } else {
        document.getElementById(dropdownId)?.remove();
      }
    });
  };

  const setBodyData = () => {
    document.getElementById("bodyPart").removeAttribute("contentEditable");
    let parentDiv = document.getElementById("editableTextArea").innerHTML;
    dispatch(change("editFormNE", "MESSAGE", parentDiv));
    console.log("-----------parentDiv11------------")
    console.log(parentDiv)
    if (parentDiv.includes(`&nbsp;`) || parentDiv.includes(`<div><br></div>`) || parentDiv.includes(`<div>&nbsp;</div>`) || parentDiv.includes(`<div></div>`) || parentDiv.includes(`<div id="bodyPart"><br></div>`)) {
      parentDiv = parentDiv.replace(/ &nbsp; /g,'');
      parentDiv = parentDiv.replace(/ &nbsp;/g,'');
      parentDiv = parentDiv.replace(/&nbsp; /g,'');
      parentDiv = parentDiv.replace(/&nbsp;/g,'');
      parentDiv = parentDiv.replace(/<div>\s+<\/div>/g, '');
      parentDiv = parentDiv.replace(/\s+<\/div>/g, '</div>');
      parentDiv = parentDiv.replace(/<div><br><\/div>/g,'');
      parentDiv = parentDiv.replace(/<div><\/div>/g, ''); 
      parentDiv = parentDiv.replace(/<div id="bodyPart"><br><\/div>/g,'<div id="bodyPart"></div>')
      console.log("-----------parentDiv22------------")
      console.log(parentDiv)
    }
    props.setChangedBody(parentDiv);
    
  }
  return (
    <div>
      {props.neReducer.length == "0" ||
      props.neReducer.length == 0 ||
      props.neReducer.length == undefined ? (
        <div>
          <Row>
            <Col md={6} sm={6} xs={12}>
             <Form.Group className="form-group">
              <Form.Label >
                {props.translator["Company"]}                
              </Form.Label>
              <input
                name="COMPANY_NAME"
                value=""
                className="form-control"
                disabled="true"
                type="text"
              />
             </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group" validationState={portalErr}>
                <Form.Label >
                  {props.translator["Module"]}
                </Form.Label>
                <input
                  name="MODULE_NAME"
                  value=""
                  className="form-control"
                  disabled="true"
                  type="text"
                />
              </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group" validationState={portalErr}>
                <Form.Label >
                  {props.translator["Name"]}
                </Form.Label>
                <input
                  name="NOTIFICATION_NAME"
                  value=""
                  className="form-control"
                  type="text"                  
                />
							<p className="charLeft" style={{ textAlign: "right", "fontSize": "11px" }}>({props.translator['Characters Remaining']}: {additionalcharleft}/100)</p>

              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group" validationState={portalErr}>
                <Form.Label >
                  {props.translator["Status"]}
                </Form.Label>
                <select
                  name="STATUS"
                  value=""
                  className="form-control"
                  type="select"
                  placeholder="select"
                >
                  <option value="ACTIVE">Active</option>
                  <option value="INACTIVE">Inactive</option>
                </select>
              </Form.Group>
            </Col>
          </Row>

         
          <Row>
            <Col xs={12}>
              <Form.Group className="form-group">
                <Form.Label >To</Form.Label>
                <input
                  name="TO_EMAIL"
                  value=""
                  className="form-control"
                  disabled="true"
                  type="text"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label >CC</Form.Label>
                <input
                  name="CC_EMAIL"
                  value=""
                  className="form-control"
                  disabled="true"
                  type="text"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label >Subject</Form.Label>
                <textarea
                  name="SUBJECT"
                  value=""
                  className="form-control"
                  disabled="true"
                  rows="2"
                  type="textarea"
                />
                  <p className="charLeft" style={{ textAlign: "right", "fontSize": "11px" }}>({props.translator['Characters Remaining']}: {additionalSubjectcharleft}/500)</p>

              </Form.Group>
            </Col>
            {/* <Col xs={12}>
              <Form.Group className="form-group">
                <Form.Label >Message</Form.Label>

                <textarea
                  name="MESSAGE"
                  value=""
                  disabled="true"
                  className="form-control whiteSpacePre"
                  rows="10"
                  type="textarea"
                />
              </Form.Group>
            </Col> */}
          </Row>
        </div>
      ) : (
        editFormNe.COMPANY_ID == '0' && editFormNe.LOCALE_ID == 'en' ? (        <div>
          <Row>
            <Col md={6} sm={6} xs={12}>
             <Form.Group className="form-group">
              <Form.Label >
                {props.translator["Company"]}                
              </Form.Label>
             <input
                value={editFormNe.COMPANY_NAME}
                className="form-control"
                disabled="true"
                type="text"
              />
             </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group" validationState={portalErr}>
                <Form.Label >
                  {props.translator["Module"]}
                </Form.Label>
                <input
                  name="MODULE_NAME"
                  value={editFormNe.MODULE_NAME}
                  className="form-control"
                  disabled="true"
                  type="text"
                />
              </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group" validationState={portalErr}>
                <Form.Label >
                  {props.translator["Name"]}
                </Form.Label>
                <input
                  name="NOTIFICATION_NAME"
                  value={editFormNe.NOTIFICATION_NAME}
                  className="form-control"
                  type="text"
                  disabled="true"
                />
							<p className="charLeft" style={{ textAlign: "right", "fontSize": "11px" }}>({props.translator['Characters Remaining']}: {additionalcharleft}/100)</p>

              </Form.Group>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group" validationState={portalErr}>
                <Form.Label >
                  {props.translator["Status"]}
                </Form.Label>
                <select
                  name="STATUS"
                  className="form-control"
                  type="select"
                  placeholder="select"
                  onChange={onStatusChange}
                >
                  {editFormNe.STATUS == "ACTIVE" ? 

                  <option value={editFormNe.STATUS}>
                    Active
                  </option>
                  :
                  <option value={editFormNe.STATUS}>
                    Inactive
                  </option>
}
                  {editFormNe.STATUS == "INACTIVE" ? (
                    <option value="ACTIVE">Active</option>
                  ) : (
                    <option value="INACTIVE">Inactive</option>
                  )}
                </select>
              </Form.Group>
            </Col>
          </Row>

         
          <Row>
            <Col xs={12}>
              <Form.Group className="form-group">
                <Form.Label >To</Form.Label>
                <input
                  name="TO_EMAIL"
                  value={editFormNe.TO_EMAIL}
                  className="form-control"
                  disabled="true"
                  type="text"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label >CC</Form.Label>
                <input
                  name="CC_EMAIL"
                  value={editFormNe.CC_EMAIL}
                  className="form-control"
                  disabled="true"
                  type="text"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label >Subject</Form.Label>
                <textarea
                  name="SUBJECT"
                  value={editFormNe.SUBJECT}
                  className="form-control"
                  disabled={true}
                  rows="2"
                  type="textarea"
                />
                  <p className="charLeft" style={{ textAlign: "right", "fontSize": "11px" }}>({props.translator['Characters Remaining']}: {additionalSubjectcharleft}/500)</p>

              </Form.Group>
            </Col>
           
          </Row>
        </div>
) :
       ( <div>
          <Row>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group">
              <Form.Label >
              {props.translator["Company"]}
              </Form.Label>
              <input
                value={editFormNe.COMPANY_NAME}
                className="form-control"
                disabled="true"
                type="text"
              />
               {/* <Field
                component={_dropDownNotification}
                name="COMPANY_ID"
                options={optionsList}
                className={"form-control"}
                // onChange={(e) => dispatch(change("editFormNE", "COMPANY_NAME", e.target[e.target.selectedIndex].text))}
                onChange={onCompanychange}

              /> */}
              </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group" validationState={portalErr}>
                <Form.Label >
                  {props.translator["Module"]}
                </Form.Label>
                <input
                  name="MODULE_NAME"
                  value={editFormNe.MODULE_NAME}
                  className="form-control"
                  disabled="true"
                  type="text"
                />
              </Form.Group>
            </Col>

            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group" validationState={portalErr}>
                <Form.Label >
                <span className="rStar"></span>{props.translator["Name"]}
                </Form.Label>
                {/* <input
                  name="NOTIFICATION_NAME"
                  value={editFormNe.NOTIFICATION_NAME}
                  className="form-control"
                  // disabled="true"
                  type="text"
                /> */}
                <Field
                component={_inputField}
                name="NOTIFICATION_NAME"
                className="form-control"
                onChange={onNotificNameChange}
                maxlength = {100}
              />
							<p className="charLeft" style={{ textAlign: "right", "fontSize": "11px" }}>({props.translator['Characters Remaining']}: {additionalcharleft}/100)</p>

              </Form.Group>
              
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group" validationState={portalErr}>
                <Form.Label >
                <span className="rStar"></span>{props.translator["Status"]}
                </Form.Label>
                <select
                  name="STATUS"
                  className="form-control"
                  type="select"
                  placeholder="select"
                  onChange={onStatusChange}
                >
                  {editFormNe.STATUS == "ACTIVE" ? 

                  <option value={editFormNe.STATUS}>
                    Active
                  </option>
                  :
                  <option value={editFormNe.STATUS}>
                    Inactive
                  </option>
}
                  {editFormNe.STATUS == "INACTIVE" ? (
                    <option value="ACTIVE">Active</option>
                  ) : (
                    <option value="INACTIVE">Inactive</option>
                  )}
                </select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
          <Col md={6} sm={6} xs={12}>
              <Form.Group className="form-group" validationState={portalErr}>
                <Form.Label >
                  {props.translator["Language"]}
                </Form.Label>
                <input
                  name="LOCALE_ID"
                  value={ (() => {
                    let languageKey = "";
                    if (editFormNe.LOCALE_ID === "en") {
                      languageKey = "English";
                    }else if(editFormNe.LOCALE_ID == "ar"){ languageKey = "Arabic";}  
                    else if(editFormNe.LOCALE_ID == "zhcn"){ languageKey = "Chinese Simplified";}
                    else if(editFormNe.LOCALE_ID == "fr"){ languageKey = "French";}
                    else if(editFormNe.LOCALE_ID == "fil"){ languageKey = "Filipino";}
                    else if(editFormNe.LOCALE_ID == "hi"){ languageKey = "Hindi";}
                    else if(editFormNe.LOCALE_ID == "id"){ languageKey = "Indonesian";}
                    else if(editFormNe.LOCALE_ID == "it"){ languageKey = "Italian";}
                    else if(editFormNe.LOCALE_ID == "ja"){ languageKey = "Japanese";}
                    else if(editFormNe.LOCALE_ID == "ko"){ languageKey = "Korean";}
                    else if(editFormNe.LOCALE_ID == "ms"){ languageKey = "Malay";}
                    else if(editFormNe.LOCALE_ID == "es"){ languageKey = "Spanish";}
                    else if(editFormNe.LOCALE_ID == "ta"){ languageKey = "Tamil";}
                    else if(editFormNe.LOCALE_ID == "th"){ languageKey = "Thai";} 
                    else if(editFormNe.LOCALE_ID == "vi"){ languageKey = "Vietnamese";}  
                    else {
                      languageKey = "German";
                    }
                    return languageKey;
                  })()
                }
                  className="form-control"
                  disabled="true"
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Group className="form-group">
                <Form.Label >To</Form.Label>
                <input
                  name="TO_EMAIL"
                  value={editFormNe.TO_EMAIL}
                  className="form-control"
                  disabled="true"
                  type="text"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label >CC</Form.Label>
                <input
                  name="CC_EMAIL"
                  value={editFormNe.CC_EMAIL}
                  className="form-control"
                  disabled="true"
                  type="text"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label >Subject</Form.Label>
                {/* <textarea
                  name="SUBJECT"
                  value={editFormNe.SUBJECT}
                  className="form-control"
                  disabled={false}
                  rows="2"
                  type="textarea"
                /> */}
                    <Field
                      component={_textArea}
                      name="SUBJECT"
                      className="form-control"
                      disabledValue={false}
                      onChange={onSubjectChange}
                      maxlength={500}
                    /> 
                    <p className="charLeft" style={{ textAlign: "right", "fontSize": "11px" }}>({props.translator['Characters Remaining']}: {additionalSubjectcharleft}/500)</p>

              </Form.Group>
            </Col>
          
          </Row>
          <div className="margin-t-20 margin-b-10 f-size-16 border-b">
          Email Content
        </div>
        <Row>
          <Col xs={12}>
            <Form.Group className="position-re form-group">
              <Form.Label>
                <span className="rStar"></span> Body Text
              </Form.Label>
              <div
                className="form-control"
                style={{ height: "initial" }}
                id="editableTextArea"
                dangerouslySetInnerHTML={{ __html: dbtemplate }}
                onBlur={() => setBodyData()}
              />
              <div className="text-r f-size-12">
                Type "$" and click on it to insert new variable.
              </div>
            </Form.Group>
          </Col>
        </Row>
        </div> ) 
      )}
      {/* </form> */}
    </div>
  );
}

// export default NotificationTemplateEditForm
const mapStateToProps = (state) => ({
  initialValues: state.neReducer[0],
});

export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    form: "editFormNE",
    destroyOnUnmount: true,
    validate,
  })(NotificationTemplateEditForm)
);