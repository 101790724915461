
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, {useState, useEffect} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import GBPRiskAssessment from './GBPRiskAssessment';
import QuestionAssessment from './QuestionAssessment';
import { renderOptions, getRiskMethodology, getRiskCategoryTypeList, getProbabilityImpactConfidenceList, getQuestionaireList, getResidualRisk, filteredQuestionsWithAnswer } from './helper';
import { HiPlus } from "react-icons/hi";

const RiskAssessment = (props) => {
    // console.log('RiskAssessment props - ', props);
    const { 
        translator = {}, 
        mode = 'add',
        isEditable = true,
        updateRiskAssessmentDetails = () => {},
        companyId = '',
        data = [],
        residualRisk = '',
        changeType = '',
        isLegacyExist = false
    } = props;

    const [methodology, setMethodology] = useState('');
    const [methodologyDropdown, setMethodologyDropdown] = useState('');
    const [showAddIcon, setShowAddIcon] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [riskMethodologyList, setRiskMethodologyList] = useState(null);
    const [riskCategoryTypeList, setRiskCategoryTypeList] = useState(null);
    const [gbpDropdownList, setGBPDropdownList] = useState(null);
    const [savedGBPAssessment, setSavedGBPAssessment] = useState([]);
    const [editGBPAssessmentIndex, setEditGBPAssessmentIndex] = useState(null);
    const [editGBPAssessmentObj, setEditGBPAssessmentObj] = useState(null);
    const [questionaireList, setQuestionaireList] = useState(null);
    const [questionaireListOriginal, setQuestionaireListOriginal] = useState(null);
    const [isFetchQuestionaireList, setIsFetchQuestionaireList] = useState(false);
    const [initialEditProcess, setInitialEditProcess] = useState(false);
    const [resRisk, setResRisk] = useState('');
    const [initialSavedAssessmentData, setInitialSavedData] = useState(null);
    const [initialSavedResRisk, setInitialSavedResRisk] = useState('');

    useEffect(() => {
        // if(!changeType){
        //     return;
        // }
        // updateIsMandatoryFlag();
        // updateDataFilledFlag();
        if(isLegacyExist == true){
            return;
        }
        setMethodology('');
        setMethodologyDropdown('');
        setIsFetchQuestionaireList(false);
        setSavedGBPAssessment([]);
        setEditGBPAssessmentIndex(null);
        setEditGBPAssessmentObj(null);
        setGBPDropdownList(null);
        setQuestionaireList(questionaireListOriginal);
        setResRisk('');
        setShowAddIcon(false);
        setInitialSavedDataOnLoad({type: 'data', data: null});
        setInitialSavedDataOnLoad({type: 'residualRisk', data: ''});
    }, [changeType]);

    useEffect(() => {
        setShowAddIcon(false);
        if(!companyId){
            clearAllStates();
            return;
        }
        getRiskMethodology(companyId, isLegacyExist).then(response => setRiskMethodologyList(response) ).catch((error) => setRiskMethodologyList([]) );
        getRiskCategoryTypeList(companyId).then(response => setRiskCategoryTypeList(response) ).catch((error) => setRiskCategoryTypeList([]) );

        // getQuestionaireList(companyId).then(response => {setQuestionaireList(response); setQuestionaireListOriginal(response); setIsFetchQuestionaireList(true);} ).catch((error) => setQuestionaireList([]) );

    }, [companyId]);
    
    useEffect(() => {
        updateRiskAssessmentDetails( {actionType: 'updatePayload', methodology, data: ((methodology == '1') ? savedGBPAssessment : filteredQuestionsWithAnswer(questionaireList)), residualRisk: resRisk} );
        updateIsMandatoryFlag();
        updateDataFilledFlag();
        // setResidualRisk(methodology, ((methodology == '1') ? savedGBPAssessment : questionaireList));
        if (props.showCabApproval) {
            props.showCabApproval(resRisk);
        }

    }, [methodology, savedGBPAssessment, questionaireList, resRisk]);

    useEffect(() => {
        if(mode == 'edit'){
            setShowForm(false);
            // setShowAddIcon(true);
            setResRisk(residualRisk);
            setInitialSavedDataOnLoad({type: 'residualRisk', data: residualRisk});
        }

        if(!changeType){
            return;
        }
        updateIsMandatoryFlag();
        updateDataFilledFlag();

    }, []);

    useEffect(() => {
        try{
            if(mode == 'edit' && data.length && isFetchQuestionaireList == false){
                const methodology = data[0]['methodologyId'];
                if(methodology == '2'){
                    setShowAddIcon(false);
                    const riskMethodologyId = data[0]['riskMethodologyId'] || '';
                    setQuestionaireList(null); 
                    setQuestionaireListOriginal(null); 
                    getQuestionaireList(companyId, riskMethodologyId).then(response => {
                        setQuestionaireList(response); 
                        setQuestionaireListOriginal(response); 
                        setIsFetchQuestionaireList(true);
                    } ).catch((error) => setQuestionaireList([]) );
                }
            }
            if(mode == 'edit' && data.length && initialEditProcess == false){
                const methodology = data[0]['methodologyId'];
                setMethodology(methodology.toString());
                if(methodology == '1'){
                    setShowAddIcon(true);
                    setMethodologyDropdown( methodology );
                }
                else{
                    setShowAddIcon(false);
                    const riskMethodologyId = data[0]['riskMethodologyId'] || '';
                    setMethodologyDropdown( (methodology + '|' + riskMethodologyId) );
                }
                if(methodology == '1'){
                    let riskArr = [];
                    data.forEach(item => {
                        let obj = {
                            'methodologyId': '1',
                            'methodologyName': 'GBP',
                            'riskCategory': item['riskCategory'],
                            'riskCategoryName': item['riskCategoryName'],
                            'riskType': item['riskType'],
                            'riskTypeName': item['riskTypeName'],
                            'probability': item['probability'],
                            'impact': item['impact'],
                            'confidence': item['confidence'],
                            'riskDescription': item['riskDescription'],
                            'controlMeasures': item['controlMeasures'],
                            'overallRisk': item['overallRisk'] || ''
                        };
                        riskArr.push(obj);
                    });
                    setSavedGBPAssessment(riskArr);
                    setInitialSavedDataOnLoad({type: 'data', data: riskArr});
                    setInitialEditProcess(true);
                }
                if(methodology == '2' && isFetchQuestionaireList == true){
                    let quesList = structuredClone(questionaireList);
                    quesList = quesList.map(item => {
                        const obj = data.find(item1 => item1['question'] == item['question']);
                        if(obj){
                            const answerObj = item['riskAnswer'].find(item2 => item2['answer'] == obj['answer']);
                            item['response'] = obj['answer'];
                            item['responseName'] = obj['answerName'];
                            item['responseWeightage'] = (answerObj) ? answerObj['weightage'] : '';
                        }
                        return item;
                    });
                    setQuestionaireList(quesList);
                    setInitialSavedDataOnLoad({type: 'data', data: quesList});
                    setInitialEditProcess(true);
                }
            }
        }catch(e){}
    }, [data, isFetchQuestionaireList]);

    const fetchGBPDropdownOptions = ({category = '', type = ''}) => {
        try{
            setGBPDropdownList(null);
            if(!(category && type)){
                return;
            }
            // setGBPDropdownList(null);
            getProbabilityImpactConfidenceList(companyId, category, type).then(response => setGBPDropdownList(response) ).catch((error) => setGBPDropdownList({}) );
        }catch(e){}
    }

    const clearSavedAssessmentStates = () => {
        setSavedGBPAssessment([]);
        setEditGBPAssessmentIndex(null);
        setEditGBPAssessmentObj(null);
        setGBPDropdownList(null);
    }

    const clearAllStates = () => {
        setRiskMethodologyList(null);
        setMethodology('');
        setMethodologyDropdown('');
        setRiskCategoryTypeList(null);
        setIsFetchQuestionaireList(false);
        setSavedGBPAssessment([]);
        setEditGBPAssessmentIndex(null);
        setEditGBPAssessmentObj(null);
        setGBPDropdownList(null);
        setQuestionaireList(null);
        setQuestionaireListOriginal(null);
        setResRisk('');
        setInitialSavedDataOnLoad({type: 'data', data: null});
        setInitialSavedDataOnLoad({type: 'residualRisk', data: ''});
    }

    const updateQuestionaireResponse = (questionId = '', response = '', responseName = '') => {
        let quesList = structuredClone(questionaireList);
        quesList = quesList.map(item => {
            if(item['question'] == questionId){
                let arr = response.split('|');
                item['response'] = arr[0] || '';
                item['responseName'] = responseName;
                item['responseWeightage'] = arr[1] || '';
            }
            return item;
        });
        setQuestionaireList(quesList);
        setResidualRisk('2', quesList);
        setInitialSavedDataOnLoad({type: 'data', data: null});
        setInitialSavedDataOnLoad({type: 'residualRisk', data: ''});
    }

    const setResidualRisk = async (methodology = '', data = null) => {
        let resRisk = '';
        try{
            if(methodology == '1'){
                // resRisk = getResidualRisk(methodology, savedGBPAssessment);
                resRisk = getResidualRisk(methodology, data);
            }
            else{
                // let dataArr = structuredClone(questionaireList);
                const countAnswers = filteredQuestionsWithAnswer(data);
                if(countAnswers.length == data.length){
                    const answersList = data.map(item => {
                        let obj = {
                            'question': parseInt(item['question'], 10),
                            'questionWeightage': parseInt(item['weightage'], 10),
                            'answer': parseInt(item['response'], 10),
                            'answerWeightage': parseInt(item['responseWeightage'], 10)
                        };
                        return obj;
                    });
                    resRisk = await getResidualRisk(methodology, answersList, companyId);
                    resRisk = (resRisk && resRisk['residualRisk']) ? resRisk['residualRisk'] : '';
                }
            }
            setResRisk(resRisk);
        }catch(e){
            setResRisk(resRisk);
        }
    }

    const isDataFilled = () => {
        if(!methodology){
            return false;
        }
        if(methodology == '1'){
            return(
                !!(savedGBPAssessment && savedGBPAssessment.length)
            );
        }
        else{
            const countAnswers = (questionaireList && questionaireList.length) ? filteredQuestionsWithAnswer(questionaireList) : [];
            return (
                !!(questionaireList && questionaireList.length && countAnswers.length == questionaireList.length)
            );
        }
    }

    const updateDataFilledFlag = () => {
        updateRiskAssessmentDetails({actionType: 'isDataFilled', data: isDataFilled() });
        // setTimeout(() => {
        //     updateRiskAssessmentDetails({actionType: 'isDataFilled', data: isDataFilled() });
        // }, 100);
    }

    const updateIsMandatoryFlag = (val = '') => {
        let value = val || methodology;
        if(changeType == '25' || changeType == '30'){
            updateRiskAssessmentDetails({actionType: 'isMandatory', data: true});
        }
        if(changeType == '35'){
            updateRiskAssessmentDetails({actionType: 'isMandatory', data: !!value});
        }
        // if(changeType == '20'){
        //     updateRiskAssessmentDetails({actionType: 'isMandatory', data: false});
        // }
    }

    const setInitialSavedDataOnLoad = ({type = '', data = null}) => {
        try{
            switch(type){
                case 'data':
                    setInitialSavedData(data);
                    break;
                case 'residualRisk':
                    setInitialSavedResRisk(data);
                    break;
            }
        }catch(e){}
    }

    const methodologyChange = (value = '') => {
        try{
            // clearQuestionaireResponse();
            props.showCabApproval(false);
            if (value == "0") {
                setResRisk("")
            }
            const arr = value.split('|');
            const methodologyId = arr[0];
            updateIsMandatoryFlag(methodologyId);
            if(methodologyId && methodologyId == '1'){
                setShowAddIcon(true);
                if(initialSavedAssessmentData){
                    const methodologyIdInitial = initialSavedAssessmentData[0]['methodologyId'] || '';
                    if(methodologyId == methodologyIdInitial){
                        setShowForm(false);
                        setSavedGBPAssessment(initialSavedAssessmentData);
                        // setResidualRisk( methodologyId, initialSavedAssessmentData );
                        setResRisk(initialSavedResRisk);
                    }
                    else{
                        setShowForm(true);
                        clearSavedAssessmentStates();
                    }
                }
                else{
                    setShowForm(true);
                    clearSavedAssessmentStates();
                }
            }
            else{
                setShowAddIcon(false);
                setShowForm(false);

                const riskMethodologyId = arr[1];
                setQuestionaireList(null); 
                setQuestionaireListOriginal(null); 

                if(initialSavedAssessmentData){
                    const riskMethodologyIdInitial = initialSavedAssessmentData[0]['riskMethodologyId'] || '';
                    if(riskMethodologyId == riskMethodologyIdInitial){
                        setQuestionaireList(initialSavedAssessmentData);
                        // setResidualRisk( methodologyId, initialSavedAssessmentData );
                        setResRisk(initialSavedResRisk);
                    }
                    else{
                        getQuestionaireList(companyId, riskMethodologyId).then(response => {
                            setQuestionaireList(response); 
                            setResidualRisk( methodologyId, response );
                            // setQuestionaireListOriginal(response); 
                            setIsFetchQuestionaireList(true);
                        } ).catch((error) => {
                            setQuestionaireList([]); setResidualRisk( methodologyId, [] );
                        });
                    }
                }
                else{
                    getQuestionaireList(companyId, riskMethodologyId).then(response => {
                        setQuestionaireList(response); 
                        setResidualRisk( methodologyId, response );
                        // setQuestionaireListOriginal(response); 
                        setIsFetchQuestionaireList(true);
                    } ).catch((error) => {
                        setQuestionaireList([]); setResidualRisk( methodologyId, [] );
                    });
                }

                // getQuestionaireList(companyId, riskMethodologyId).then(response => {
                //     setQuestionaireList(response); 
                //     // setQuestionaireListOriginal(response); 
                //     setIsFetchQuestionaireList(true);
                // } ).catch((error) => setQuestionaireList([]) );

            }
            setMethodology(methodologyId);
            setMethodologyDropdown(value);
            // setResidualRisk(methodologyId, ((methodologyId == '1') ? savedGBPAssessment : questionaireList));
            // clearSavedAssessmentStates();
            updateDataFilledFlag();
            // if(initialSavedAssessmentData && methodologyId == '1'){
            //     setSavedGBPAssessment(initialSavedAssessmentData);
            //     setResRisk(initialSavedResRisk);
            // }
            // if(initialSavedAssessmentData){
            //     if(methodologyId == '1'){
            //         setSavedGBPAssessment(initialSavedAssessmentData);
            //     }
            //     else{
            //         setQuestionaireList(initialSavedAssessmentData);
            //     }
            //     setResRisk(initialSavedResRisk);
            // }
        }catch(e){}
    }

    const clearQuestionaireResponse = () => {
        try{
            let quesList = structuredClone(questionaireList);
            quesList = quesList.map(item => {
                let obj = structuredClone(item);
                delete obj['response'];
                delete obj['responseName'];
                delete obj['responseWeightage'];
                return obj;
            });
            setQuestionaireList(quesList);
            setResidualRisk('2', quesList);
            // setInitialSavedDataOnLoad({type: 'data', data: null});
            // setInitialSavedDataOnLoad({type: 'residualRisk', data: ''});
        }catch(e){
            setQuestionaireList(null);
            // setInitialSavedDataOnLoad({type: 'data', data: null});
            // setInitialSavedDataOnLoad({type: 'residualRisk', data: ''});
        }
    }
    
    return (
        <div className="padding-t-5 padding-r-10 padding-l-15 margin-b-15 border">
            <div className="bgGray padding-5">
                {translator['Risk Assessment']}
            </div>
            <Row>
                <Col md={4}>
                    <Form.Group>
                        <Form.Label bsClass="">{translator['Methodology']}</Form.Label>
                        <Form.Select
                        className={false ? 'myControl myInput error' : 'myControl myInput'}
                        componentClass="select"
                        onChange={(e) => {
                            methodologyChange(e.target.value);
                        }}
                        value={methodologyDropdown}
                        disabled={!isEditable}
                        >
                            <option value="0">Select</option>
                            {renderOptions('riskMethodology', riskMethodologyList)}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    {( methodology && isDataFilled() == true ) && <Form.Group>
                        <Form.Label bsClass="">{translator['Residual Risk']}</Form.Label>
                        <Form.Control type='text' className='myControl myInput' value={resRisk} disabled />
                    </Form.Group>}
                </Col>
                <Col md={4} className="paHedFilter">
                    {(showAddIcon && isEditable == true) && 
                    <a
                        title={translator["Add"]}
                        bsPrefix=" "
                        className="myBt plus fillBtn"
                        size="xs"
                        variant="primary"
                        bsClass=''
                        href="javascript:void(0)"
                        onClick={() => {
                            setShowForm(true);
                        }}
                        >
                        <HiPlus/>
                    </a>}
                </Col>
            </Row>
            {(methodology == '1') && 
            <GBPRiskAssessment
                setShowCabApproval={props.showCabApproval}
                companyId={companyId}
                isEditable={isEditable}
                renderOptions={renderOptions}
                showForm={showForm}
                setShowForm={setShowForm}
                setShowAddIcon={setShowAddIcon}
                riskCategoryTypeList={riskCategoryTypeList}
                fetchGBPDropdownOptions={fetchGBPDropdownOptions}
                gbpDropdownList={gbpDropdownList}
                savedAssessment={savedGBPAssessment}
                setSavedAssessment={setSavedGBPAssessment}
                editAssessmentIndex={editGBPAssessmentIndex}
                setEditAssessmentIndex={setEditGBPAssessmentIndex}
                editAssessmentObj={editGBPAssessmentObj}
                setEditAssessmentObj={setEditGBPAssessmentObj}
                setResidualRisk={setResidualRisk}
                setInitialSavedDataOnLoad={setInitialSavedDataOnLoad}
                panelResize={props.panelResize}
                {...props}
            />}

            {(methodology == '2') && 
            <QuestionAssessment
                isEditable={isEditable}
                questionaireList={questionaireList}
                updateQuestionaireResponse={updateQuestionaireResponse}
                {...props}
            />}
        </div>
    );
};

export default RiskAssessment;
