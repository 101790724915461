import { extendDataItem, mapTree } from '@progress/kendo-react-common';
import { filterBy } from '@progress/kendo-react-data-tools';
import { DropDownTree } from '@progress/kendo-react-dropdowns';
import React, { useCallback, useMemo, useState } from 'react';
import { LuText } from 'react-icons/lu';
import { MdGTranslate } from 'react-icons/md';
import { TbBlockquote, TbFileTextAi } from 'react-icons/tb';

const selectField = 'selected';
const expandField = 'expanded';
const dataItemKey = 'id';
const textField = 'text';
const subItemsField = 'items';
const fields = {
    selectField,
    expandField,
    dataItemKey,
    subItemsField
};

export const data = [
    { id: 1, text: "Summarize", icon: <TbBlockquote /> },
    { id: 2, text: "Make shorter", icon: <LuText /> },
    {
        id: 3,
        text: "Change tone",
        icon: <TbFileTextAi />,
        items: [
            { id: 31, text: "Professional" },
            { id: 32, text: "Empathetic" },
            { id: 33, text: "Casual" },
            { id: 34, text: "Neutral" },
            { id: 35, text: "Educational" }
        ]
    },
    {
        id: 4,
        text: "Translate",
        icon: <MdGTranslate />,
        items: [
            { id: "en", text: "English" },
            { id: "es", text: "Spanish" },
            { id: "it", text: "Italian" },
            { id: "fi", text: "Filipino" },
            { id: "id", text: "Indonesian" },
            { id: "ms", text: "Malay" },
            { id: "ta", text: "Tamil" },
            { id: "th", text: "Thai" },
            { id: "zhcn", text: "Chinese Simplified" },
            { id: "ar", text: "Arabic" },
            { id: "hi", text: "Hindi" },
            { id: "ja", text: "Japanese" },
            { id: "ko", text: "Korean" },
            { id: "vi", text: "Vietnamese" },
            { id: "de", text: "German" },
            { id: "fr", text: "French" },
        ]
    },
];

export const CustomDropdownTree = () => {
    const [value, setValue] = useState();
    const [expanded, setExpanded] = useState([]);
    const processTreeData = (data, state, fields) => {
        const {
            selectField,
            expandField,
            dataItemKey,
            subItemsField
        } = fields;
        const {
            expanded,
            value,
            filter
        } = state;
        const filtering = Boolean(filter && filter.value);
        return mapTree(filtering ? filterBy(data, [filter], subItemsField) : data, subItemsField, item => {
            const props = {
                [expandField]: expanded.includes(item[dataItemKey]),
                [selectField]: value && item[dataItemKey] === value[dataItemKey]
            };
            return filtering ? extendDataItem(item, subItemsField, props) : {
                ...item,
                ...props
            };
        });
    };

    const expandedState = (item, dataItemKey, expanded) => {
        const nextExpanded = expanded.slice();
        const itemKey = item[dataItemKey];
        const index = expanded.indexOf(itemKey);
        index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);
        return nextExpanded;
    };
    const onChange = event => setValue(event.value);
    const onExpandChange = useCallback(event => setExpanded(expandedState(event.item, dataItemKey, expanded)), [expanded]);
    const treeData = useMemo(() => processTreeData(data, {
        expanded,
        value
    }, fields), [expanded, value]);


    return (
        <DropDownTree
            className="ftrBtn"
            data={treeData}
            value={value}
            onChange={onChange}
            placeholder="Refine"
            textField={textField}
            dataItemKey={dataItemKey}
            selectField={selectField}
            expandField={expandField}
            onExpandChange={onExpandChange}
            popupSettings={{ popupClass: "rulesDropdown", appendTo: (document.getElementById("skipdv")) }}
        />
    );
};
