
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { reduxForm, SubmissionError } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { deleteMailboxConfigList, editMailboxConfigList } from "../../../actions/systemConfig/systemConfigAction.js";
import moment from "moment";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import Cookies from "universal-cookie";
import axios from "axios";
import { MyContext } from '_MyContext';
import { LuFileText } from "react-icons/lu";
const cookies = new Cookies();

let cookieData = cookies.get("gph");
if (cookieData) cookieData = cookieData.replace("s:", "");
if (cookieData) cookieData = cookieData.substring(0, cookieData.lastIndexOf("."));
cookieData = cookieData.split("~");

let loggedInUserId = cookieData[43];
let loggedInUserName = cookieData[42];
const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

const EditMailboxConfigHeader = (props) => {

  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

  useEffect(() => {
    context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
    context.newTabPrevent();
	}, []);

  const dispatch = useDispatch();
  const submit = (values) => {
    let isValid = true;
    if (typeof (values.COMPANY_NAME) == 'undefined' || values.COMPANY_NAME == '' || values.COMPANY_NAME.trim() == '') {
      props.setCompanyErrorColor();
      isValid = false;
    } 
    if ( !values.MAILBOX_ID || values.MAILBOX_ID == '' || values.MAILBOX_ID.trim().length===0 || !validateEmail(values.MAILBOX_ID)) {
      props.setMailboxIdErrorColor("error");
      isValid = false;
    } else {
      props.setMailboxIdErrorColor("");
    }
    if (!values.MAILBOX_PROVIDER ||values.MAILBOX_PROVIDER == '') {
      props.setMailboxProviderErrorColor("error");
      isValid = false;
    } else {
      props.setMailboxProviderErrorColor("");
    }
    if (!values.STATUS || values.STATUS == '') {
      props.setStatusErrorColor("error");
      isValid = false;
    } else {
      props.setStatusErrorColor("");
    }
    // if (!values.WHITELISTED_DOMAINS ||values.WHITELISTED_DOMAINS == '' || values.WHITELISTED_DOMAINS.trim().length===0) {
    //   props.setWhitelistedDomainErrorColor("error");
    //   isValid = false;
    // } else {
    //   props.setWhitelistedDomainErrorColor("");
    // }
    // let alwaysException = null;
    // let neverException = null;
    // if (typeof (values.NO_EXCEPTIONS) !== 'undefined')
    //   alwaysException = values.NO_EXCEPTIONS
    // if (typeof (values.EXCEPTION_EMAILIDS) !== 'undefined')
    //   neverException = values.EXCEPTION_EMAILIDS

//console.log("values.ALWAYS_EXCEPTION",values.EXCEPTION_EMAILIDS)
//console.log("values.NEVER_EXCEPTION",values.NO_EXCEPTIONS)

    if (isValid == true) {
      let postjson;
            postjson = {
              mailbox_provider: values.MAILBOX_PROVIDER,
              mailbox: values.MAILBOX_ID,
              company_id: parseInt(values.COMPANY_ID, 10),
              company_name: values.COMPANY_NAME,
              type:"Mailbox",
              status: values.STATUS === "Active" ? 1 : 0,
              whitelisted_domains: "null",
              exception_emailids: null, 
              no_exceptions: null, 
              updated_by: loggedInUserId,
              updated_by_name: loggedInUserName,
              updated_at: moment().format('YYYY-MM-DD HH:mm:ss'),
              auth_type: values.AUTH_TYPE              
            };
            dispatch(editMailboxConfigList(postjson, props.id,props.translator));
        } 
    
  };
  
  const onDeleteClick = () => {
    const id = window.location.href.split("/").pop();
    Swal.fire({
      title: props.translator["Are you sure you want to delete?"],
      width: 550,
      padding: '15px',
      showDenyButton: true,
      confirmButtonText: props.translator['Yes'],
      denyButtonText: props.translator['No'],
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteMailboxConfigList(id))
      } else if (result.isDenied) {
        
      }
    })
  };

  let { handleSubmit, submitting } = props;

  return (
    <Row className="margin-b-15">
      <Col lg={4} md={6} sm={4} xs={4}>
        <h1 bsClass="" className="sPageHeading1">
          {props.translator["Mailbox Configuration"]}
        </h1>
      </Col>
      <Col lg={8} md={6} sm={8} xs={8}>
        <div className="paHedFilter">
          { !props.isRightEditPanel &&
            <Link
            title={props.translator["Add Credentials"]}
            as="li" to="javascript:void(0)"
            onClick={() => {
              props.rightEditPanel(true);
            }}
            className="myBt fillBtn"
            >
            <LuFileText/>
          </Link>}
          <Button role="button" bsPrefix=' '  className="myBt rclose" title={props.translator["Delete"]} onClick={onDeleteClick} >
            <i aria-hidden="true" className="fa fa-trash-o"></i>
          </Button>
          <Link bsPrefix=' ' to="javascript:void(0)" className="myBt plus ctrlKeyPrevent" onClick={handleSubmit(submit)} disabled={submitting} title={props.translator["Save"]} ref={saveObjRef}>
          {submitting ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}
          </Link>
          <Link
            bsPrefix=' '
            className="myBt cancel fillBtn ctrlKeyPrevent"
            title={props.translator["Close"]}
            to="/systemConfig" 
            state= {{activeTab: 4}}
            ref={closeButton}
            >
            <IoClose/>
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default reduxForm({
  form: "xsmSystemConfigurationEditForm",
  destroyOnUnmount: false,
})(EditMailboxConfigHeader);
