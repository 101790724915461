/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import swal from "sweetalert";
import moment from "moment";
import { Table, Form, Button } from "react-bootstrap";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useTable, useSortBy, useFilters } from "react-table";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import { IoSearch } from "react-icons/io5";
import { LuCalendarDays } from "react-icons/lu";
import Pagination from "react-js-pagination";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import Swal from "sweetalert2";
import axios from "axios";
import * as XLSX from "xlsx";

import ListLoader from "../../common/loaders/ListLoader";
import { GLOBAL } from "../../../components/Globals";

import { Get } from "../../../utils/axiosUtils";
import { useFetchData } from "../add-edit/formfield/hooks";
import CiGroupDescriptionList from "./cigroupShortDescriptionList";

const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
let dateformatCookie = homepagelocation[2]?.substr(0, 10)?.toUpperCase();

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter, id },
  setShowFilterIcon,
}) {
  const [inputValue, setInputValue] = useState("");
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    if (filterValue === undefined || filterValue === "") {
      setInputValue("");
    }
    setInputValues((prev) => ({ ...prev, [id]: filterValue || "" }));
  }, [filterValue, id]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value || "");
  };

  const handleSearch = () => {
    setFilter(inputValue || undefined);
    setShowFilterIcon(true);
  };

  const handleIconClick = () => {
    handleSearch();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Form.Control
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Search here"
        className="colSearInp form-control"
        onKeyDown={handleKeyDown}
      />

      {inputValues && inputValue?.trim()?.length >= 1 && (
        <a title="search" className="faicn" onClick={handleIconClick}>
          <IoSearch />
        </a>
      )}
    </div>
  );
}

const getLabel = (startDate, endDate) => {
  let dateRangeLabel = "Custom Range";
  if (moment(startDate).isBefore(moment())) {
    let days = moment(endDate).diff(moment(startDate), "days") + 1;
    if (
      days === 7 ||
      days === 10 ||
      days === 15 ||
      days === 30 ||
      days === 90
    ) {
      dateRangeLabel = `Last ${days} Days`;
    }
  }
  return dateRangeLabel;
};

const DateFilter = ({
  column: { setFilter },
  initialStartDate,
  initialEndDate,
  onDateLabelChange,
  inputRef,
}) => {
  const dateRangePickerRef = useRef(null);

  const [dateRange, setDateRange] = useState({
    startDate: initialStartDate || moment().subtract(29, "days"),
    endDate: initialEndDate || moment(),
  });

  useEffect(() => {
    const label = getLabel(dateRange.startDate, dateRange.endDate);
    onDateLabelChange(label);
    applyFilter(dateRange.startDate, dateRange.endDate);
  }, []);

  const handleApply = (event, picker) => {
    setDateRange({
      startDate: picker.startDate,
      endDate: picker.endDate,
    });
    applyFilter(picker.startDate, picker.endDate);
    const label = getLabel(picker.startDate, picker.endDate);
    onDateLabelChange(label);
    // toggleOpen(false);
  };

  const applyFilter = (start, end) => {
    const formattedStartDate = start.format("YYYY-MM-DD");
    const formattedEndDate = end.format("YYYY-MM-DD");
    setFilter(`${formattedStartDate},${formattedEndDate} 23:59:59`);
  };

  useEffect(() => {
    if (dateRangePickerRef.current) {
      inputRef.current = dateRangePickerRef.current.$picker[0]; // Access input via $picker
    }
  }, [dateRangePickerRef]);

  return (
    <DateRangePicker
      ref={dateRangePickerRef}
      initialSettings={{
        startDate: dateRange.startDate.toDate(),
        endDate: dateRange.endDate.toDate(),
        ranges: {
          "Last 7 Days": [
            moment().subtract(6, "days").toDate(),
            moment().toDate(),
          ],
          "Last 10 Days": [
            moment().subtract(9, "days").toDate(),
            moment().toDate(),
          ],
          "Last 15 Days": [
            moment().subtract(14, "days").toDate(),
            moment().toDate(),
          ],
          "Last 30 Days": [
            moment().subtract(29, "days").toDate(),
            moment().toDate(),
          ],
          "Last 90 Days": [
            moment().subtract(89, "days").toDate(),
            moment().toDate(),
          ],
        },
        locale: {
          format: "YYYY-MM-DD",
        },
        alwaysShowCalendars: false,
      }}
      onApply={handleApply}
    >
      <input
        readOnly
        type="text"
        className="form-control"
        value={`${dateRange.startDate.format(
          "YYYY-MM-DD"
        )} to ${dateRange.endDate.format("YYYY-MM-DD")}`}
        ref={inputRef}
      />
    </DateRangePicker>
  );
};

export const List = (props) => {
  const {
    activeTab,
    data,
    isBusy,

    jobRefetch,
    isFilterCleared,
    setSearchParams,
    setIsFilterCleared,
    setShowFilterIcon,
    showFilterIcon,
    setActiveTab
  } = props;

  let dateRef = useRef();

  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const loader = useSelector((state) => state.showLoader.loading);

  const [pageNum, setPageNum] = useState(1);
  const [pgSize, setPgSize] = useState(10);
  // const [isFilterCleared, setIsFilterCleared] = useState(false);
  // const [showFilterIcon, setShowFilterIcon] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [isEditDetails, setIsEditDetails] = useState(false);
  const [paginationData, setPaginationData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [isPullActionFetching, setIsPullActionFetching] = useState(false);
  const [activeDateLabel, setActiveDateLabel] = useState("");
  const [startDateVal, setStartDateVal] = useState("");
  const [globalSortDirection, setGlobalSortDirection] = useState("Ascending");

  const [pushToMasterState, setPushToMasterState] = useState({});
  const inputRef = useRef(null);

  const {
    data: listData,
    loading,
    error,
  } = useFetchData(GLOBAL.getCiGroup, { page: pageNum, limit: pgSize }, {}, [
    pageNum,
    pgSize,
  ]);

  const handleCheckboxChange = (jobId, checked) => {
    setPushToMasterState((prevState) => {
      if (prevState[jobId]) {
        return {};
      } else {
        return { [jobId]: true };
      }
    });
  };

  const handleRowClick = (row, index) => {
    // setrowIndex(index);
    setRowData(row);
    setIsEditDetails(true);
    // showDropdownFun("allFalse",false);
  };

  const updateDateLabel = (label) => {
    setActiveDateLabel(label);
  };

  const toggleDateFilter = () => {
    if (inputRef?.current) {
      inputRef.current.click();
    }
  };

  let caseDropdownState = Object.values(props?.isMenuOpen || {})?.some(
    (val) => val
  );
  let caseResponsive =
    isBusy ||
    tableData.length === 0 ||
    (caseDropdownState && tableData.length < 6)
      ? "overFlowClp"
      : "table-responsive";
  let caseResponsiveTable =
    isBusy ||
    tableData.length === 0 ||
    (caseDropdownState && tableData.length < 6)
      ? "respondv responDvMinH"
      : "respondv";
  let casePanelOverflow =
    caseDropdownState &&
    (isBusy || tableData.length === 0 || tableData.length < 6)
      ? "overflow-clip"
      : "";

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "GROUP_NAME",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Company",
        accessor: "COMPANY_NAME",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Class",
        accessor: "CLASS_NAME",
        disableSortBy: true,
        disableFilters: true,
      },
      // {
      //   Header: "Status",
      //   accessor: "STATUS",
      //   disableSortBy: true,
      //   disableFilters: true,
      // },
      {
        Header: "Modified On",
        accessor: "UPDATED_DATE",
        disableSortBy: true,
        disableFilters: true,
        // Filter: (props) => (
        //   <DateFilter
        //     {...props}
        //     onDateLabelChange={updateDateLabel}
        //     inputRef={inputRef}
        //   />
        // ),
        // filter: "between",
      },
    ],
    [startDateVal, pushToMasterState]
  );

  const onPageSizeChange = (pageNumber, val) => {
    setPgSize(Number(val));
    setPgSize(val);
  };

  const onPageChange = (val) => {
    try {
      setPageNum(val);
    } catch (e) {
      console.error(e);
    }
  };

  const defaultColumn = useMemo(
    () => ({
      Filter: (props) => (
        <DefaultColumnFilter {...props} setShowFilterIcon={setShowFilterIcon} />
      ),
    }),
    [setShowFilterIcon]
  );

  const filterTypes = React.useMemo(
    () => ({
      between: (rows, id, filterValue) => {
        let [start, end] = filterValue.split(",");
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue >= start && rowValue <= end;
        });
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    setAllFilters,
  } = useTable(
    {
      columns,
      data: tableData,
      defaultColumn,
      manualFilters: true,
      filterTypes,
    },
    useFilters,
    useSortBy
  );

  useEffect(() => {
    setTableRows(rows.sort((a, b) => Number(a.id) - Number(b.id)));
  }, [rows]);

  useEffect(() => {
    if (isFilterCleared) {
      setAllFilters([]);
      setIsFilterCleared(false);
    }
  }, [isFilterCleared]);

  useEffect(() => {
    setPaginationData({
      totalPages: listData?.meta?.totalPageCount,
      recordCount: listData?.meta?.rowCount,
    });
    setTableData(listData?.result || []);
  }, [tableData, listData, pageNum, pgSize]);

  return (
    <PanelGroup direction="horizontal" className={casePanelOverflow}>
      <Panel
        id="sidebar"
        minSize={33}
        order={1}
        defaultSize={isEditDetails ? 67 : 100}
        className={`${casePanelOverflow} ${
          isEditDetails ? "isShowLeftPanel catBordDv" : "catBordDv"
        }`}
      >
        <div aria-label="Table" role="contentinfo" className={"respondv"}>
          {/* <div className="float-none dateRangeLabel margin-b-5 top-0">
            <a onClick={toggleDateFilter}>
              <LuCalendarDays className="mx-1" />
              {activeDateLabel}
            </a>
          </div> */}
          <div className={"tableRgtBor table-responsive"}>
            <Table
              striped
              bordered
              hover
              className="tableView nowrapWhright fltr-table"
              style={{ marginTop: "0px" }}
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => {
                      const {
                        render,
                        getHeaderProps,
                        isSorted,
                        isSortedDesc,
                        disableSortBy,
                        getSortByToggleProps,
                      } = column;
                      const extraClass = isSorted
                        ? isSortedDesc
                          ? "sorting_desc desc align-top"
                          : "sorting_asc asc align-top"
                        : `sorting align-top ${
                            disableSortBy
                              ? "disable-sorting align-top"
                              : "align-top"
                          }`;

                      return (
                        <th className={extraClass} title="">
                          <div
                            className="sortParArr"
                            {...getHeaderProps(getSortByToggleProps())}
                            title=""
                          >
                            {column.render("Header")}
                            <span
                              className="px-2"
                              title={isSorted ? globalSortDirection : ""}
                            >
                              {isSorted ? (
                                isSortedDesc ? (
                                  <i className="fa fa-sort-down"></i>
                                ) : (
                                  <i className="fa fa-sort-up"></i>
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                          <div className="colSearDv">
                            {column.canFilter ? column.render("Filter") : null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {isBusy || isPullActionFetching ? (
                  <tr>
                    <td
                      colSpan={columns.length}
                      style={{ textAlign: "center" }}
                    >
                      <ListLoader />
                    </td>
                  </tr>
                ) : tableRows?.length === 0 ? (
                  <tr>
                    <td colSpan={columns.length} className="text-center">
                      There is no matching data available
                    </td>
                  </tr>
                ) : (
                  tableRows?.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        onClick={() => handleRowClick(row?.original, i)}
                        className={
                          isEditDetails && activeRowIndex === i
                            ? "myActive"
                            : ""
                        }
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              // className="text-capitalize"
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
          <div aria-label="Pagination" className="nBotPagina mt-3">
            <div className="nShow">
              <div className="margin-r-10">Show:</div>
              <Form.Select
                value={pgSize}
                onChange={(e) => {
                  onPageSizeChange(pageNum, e.target.value);
                }}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </Form.Select>
            </div>
            <nav aria-label="Pagination" className="display-inline-block">
              <Pagination
                prevPageText="Prev"
                nextPageText="Next"
                firstPageText={<i className="glyphicon glyphicon-menu-left" />}
                lastPageText={<i className="glyphicon glyphicon-menu-right" />}
                activePage={pageNum} 
                 itemsCountPerPage={pgSize}
                totalItemsCount={paginationData?.recordCount}
                pageRangeDisplayed={4}
                onChange={(e) => onPageChange(e)}
              />
            </nav>
          </div>
        </div>
      </Panel>
      {isEditDetails ? (
        <>
          <PanelResizeHandle className="isShowLeftPanel resizeHandle">
            <div className="outlne">
              <div className="handIcn">
                <i className="fa fa-chevron-left" aria-hidden="true"></i>
              </div>
            </div>
          </PanelResizeHandle>
          <Panel minSize={33} order={2} defaultSize={33}>
            <CiGroupDescriptionList
              rowData={rowData}
              setIsEditDetails={setIsEditDetails}
            />
          </Panel>
        </>
      ) : (
        ""
      )}
    </PanelGroup>
  );
};

export default List;
