
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { MyContext } from '_MyContext';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

import swal from "sweetalert";
import axios from "axios";
import { GLOBAL } from "../Globals";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { loadBreakFixActivityLogDetails } from "../../actions/breakFix/breakFixActivityLogAction";
import {IoClose} from "react-icons/io5";
import {ImInfo, ImSpinner6} from "react-icons/im";
import {
  loadOrdersList,
  loadOrderDetails,
  loadBreakFixOrderDetails,
  loadApproverList,
  resetSortingArrows,
  loadFilterOrderList,
} from "../../actions/cart/myOrders/myOrdersAction";
import {
  loadChangeTotalApprovalDetails,
  loadChangeTotalApprovalHistoryDetails,
} from "../../actions/changeManagement/changeTotalApprovalAction";
import { loadImplPlanList } from "../../actions/common/attachmentAction";
import { formatMinutesIntoDaysHoursMinutes } from "../../actions/common/functionActions.js";
import { loadBreakFixTaskLogDetails } from "../../actions/task/TaskDataAction";
import AttachmentTab from "./Attachment";
import ListLoader from "../common/loaders/ListLoader";
import PropTypes from "prop-types";
import moment from "moment";
import datetimeConvertor from "../../ISO8601converter";
import _ from "lodash";
import Cookies from "universal-cookie";
import { navigationHooks } from "../../helpers/NavigationHook";
import WatcherDetails from "../common/WorkItemBoard/watcher/WatcherDetails";
import ReactReadMoreLess from "../../helpers/ReactReadMoreLess";

import TaskDetailsPopup from '_Commons/WorkItemBoard/taskDetailsPopup.js';
import PieChart from '_Commons/pieChart.js';
import { getTaskCountForPieChart } from "../../actions/task/TaskDataAction";
import { sanitizeElements } from "../common/helper.js";
import { isJson } from "../uiBuilder/studio/utils.js";
import AssetTablePopup from "../common/assetTableModal.js";


// import { ReadMore } from 'react-read-more';
const MoreArrow = (
  <i title="More" className="fa fa-angle-double-down f-size-15" />
);
const LessArrow = (
  <i title="Less" className="margin-l-5 lessArrow fa fa-angle-double-up"></i>
);

const Joi = require("joi");

const cookies = new Cookies();
let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
timezone = timezone.split("~");
timezone = timezone[3];

let user_id = cookies.get("gph");
if (user_id) user_id = user_id.replace("s:", "");
if (user_id) user_id = user_id.substring(0, user_id.lastIndexOf("."));
user_id = user_id.split("~");
user_id = user_id[43];

let attachmentDetails = cookies.get("gph");
if (attachmentDetails) attachmentDetails = attachmentDetails.replace("s:", "");
if (attachmentDetails)
  attachmentDetails = attachmentDetails.substring(
    0,
    attachmentDetails.lastIndexOf(".")
  );
attachmentDetails = attachmentDetails.split("~");
const attachmentSize = attachmentDetails[21];

let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];
class MyRequestsShortDesc extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
      isUloadingDisabled: false,
      activityLogHideShow: 0,
      showUserData: false,
      quickViewOrders: {},
      comments: "",
      showUpdates: 0,
      viewUpdates: 0,
      sortingArrow: 0,
      cancelHideShow: 0,
      issueValidationError: "",
      errstate: "",
      savedMessage: "",
      issue: "",
      disabledSave: false,
      commentsAreEmpty: false,
      showAttachWithoutComments: false,
      reorderHideShow: 0,
      reorderComments: "",
      displayMessage: "",
      showApprovalLoader: false,
      tabKey: 1,
      loadWatcherDetails:false,
      canErrorCode: "",
      cancelCode: "",
      cancelValue: "",
      taskCount: {
        data: [
          { name: "open", value: 0, color: "#2DCCFF" },
          { name: "inProgress", value: 0, color: "#b57bff" },
          { name: "completed", value: 0, color: "#56F000" }
        ],
        meta: {
          totalCount: 0,
          completed: 0,
        },
      }
    };
    this.showUserDetails = this.showUserDetails.bind(this);
    this.activityData = this.activityData.bind(this);
    this.postActivityData = this.postActivityData.bind(this);
    this.getActivityLog = this.getActivityLog.bind(this);
    this.viewUpdateShow = this.viewUpdateShow.bind(this);
    this.cancelHideShow = this.cancelHideShow.bind(this);
    this.groupPopoverTable = this.groupPopoverTable.bind(this);
    this.individualPopoverTable = this.individualPopoverTable.bind(this);
    this.renderQuestionCategoryWise =
      this.renderQuestionCategoryWise.bind(this);
    this.renderIndividualCategoryQuestions =
      this.renderIndividualCategoryQuestions.bind(this);
    this.postAttachmentWithoutComments =
      this.postAttachmentWithoutComments.bind(this);
    this.renderOrderingInformation = this.renderOrderingInformation.bind(this);
    this.reorderHideShow = this.reorderHideShow.bind(this);
    this.renderAdditionalDetails = this.renderAdditionalDetails.bind(this);
    this.checkRenderApproverData = this.checkRenderApproverData.bind(this);
    GLOBAL.filesToBeSent = [];
    this.handleSelect = this.handleSelect.bind(this);
    this.inputComments = navigationHooks.useRef;
    this.setCancellationType = this.setCancellationType.bind(this);
  }
  componentDidMount() {
    this.context.keyDownHandler(this.closeButton,this.editButton,this.globalSearchRef,this.saveObjRef);
  }
  componentDidUpdate() {
    this.context.keyDownHandler(this.closeButton,this.editButton,this.globalSearchRef,this.saveObjRef);
  }
  groupPopoverTable(tabledata) {
    return tabledata.map((data, index) => {
      let statusapp = data.status;
      if (statusapp == "null" || statusapp == "" || statusapp == null)
        statusapp = "Pending";
      else statusapp = data.status;

      return (
        <tr>
          <td>{data.name}</td>
          <td>
            {statusapp}
            {data.approvedby != null ? " (" + data.approvedby + ")" : ""}
          </td>
          <td>{data.date}</td>
          <td
        style={{ whiteSpace: 'pre-line' }}
        dangerouslySetInnerHTML={{
          __html: sanitizeElements(data.reason)
        }}
      />

        </tr>
      );
    });
  }

  individualPopoverTable(tabledata) {
    return (
      <tr>
        <td>{tabledata.name}</td>
        <td
        style={{ whiteSpace: 'pre-line' }}
        dangerouslySetInnerHTML={{
          __html: sanitizeElements(tabledata.reason)
        }}
      />
      </tr>
    );
  }

  getAttachment(itemId, Module) {
    this.props.loadImplPlanList(Module, itemId);
  }
  renderAttachedItems(attachments) {
    if (_.isEmpty(attachments)) {
      return (
        <tr>
          <td colSpan={3} className="text-c">
            {this.props.tr["No File Found"]}
          </td>
        </tr>
      );
    }

    return attachments.map((attachment) => {
      console.log("attachment", attachment);
      if (attachment.filename !== null && attachment != undefined) {
        let attachfilename = attachment.filename || attachment.fileName;
        if (attachfilename.indexOf("##") > -1)
          attachfilename = attachfilename.slice(15);

        let actDateTimeFormat = jwtdf + " hh:mm A";
        let newDate = new Date(attachment.createdOn * 1000).toLocaleString(
          "en-US",
          { timeZone: timezone }
        );
        newDate = moment(newDate).format(jwtdf + " HH:mm:ss");
        let isoDate = datetimeConvertor(newDate, jwtdf + " HH:mm:ss");
        let myDateObj = moment(isoDate).format(actDateTimeFormat);

        let validated_url = undefined;
        if (attachment && attachment.fileTempURL != "") {
          let namedSchema = Joi.string().regex(/^[^<>}{]+$/);
          let validator_to_add_myQbj = namedSchema.validate(
            attachment.fileTempURL
          );
          if (validator_to_add_myQbj.error == null) {
            validated_url = attachment.fileTempURL;
          } else {
            validated_url = "\\";
          }
        } else validated_url = "\\";
        if (attachment.status == "Y") {
          return null;
        } else if (attachment.planType == null) {
          return (
            <tr bsClass="" key={attachment.attachmentId}>
              <td>
                <div>{attachfilename}</div>
                <div style={{ color: "rgb(0 0 0 / 50%)" }}>
                  {attachment.createdByName}
                </div>
              </td>
              <td>{myDateObj}</td>
              <td className="text-c">
                <a className="margin-r-10" href={validated_url}>
                  <i className="fa fa-eye"></i>
                </a>
              </td>
            </tr>
          );
        }
      }
    });
  }
  cancelHideShow() {
    this.setState({
      activityLogHideShow: 0,
      viewUpdates: 0,
      cancelHideShow: !this.state.cancelHideShow,
      reorderHideShow: 0,
    });
  }

  reorderHideShow() {
    this.setState({
      activityLogHideShow: 0,
      viewUpdates: 0,
      cancelHideShow: 0,
      reorderHideShow: !this.state.reorderHideShow,
    });
  }

  activityLogHideShow() {
    if (this.state.activityLogHideShow == 1) this.setState({ comments: "" });
    this.setState({
      activityLogHideShow: !this.state.activityLogHideShow,
      viewUpdates: 0,
      cancelHideShow: 0,
      reorderHideShow: 0,
      savedMessage: "",
    });
  }

  viewUpdateShow() {
    this.getActivityLog();
    this.setState({
      activityLogHideShow: 0,
      viewUpdates: !this.state.viewUpdates,
      cancelHideShow: 0,
      reorderHideShow: 0,
      savedMessage: "",
    });
  }
  showUserDetails() {
    this.setState({ showUserData: !this.state.showUserData });
  }

  ascactivitysort() {
    this.setState({
      sortingArrow: 0,
    });

    let module = this.props.module;
    let itemId = this.props.itemId;
    this.props.loadBreakFixActivityLogDetails(itemId, "asc", "Fullfilment");
  }
  descactivitysort() {
    this.setState({
      sortingArrow: 1,
    });
    let module = this.props.module;
    let itemId = this.props.itemId;
    this.props.loadBreakFixActivityLogDetails(itemId, "desc", "Fullfilment");
  }

  renderApproverList(approverData) {
    if (!approverData) {
      return <div />;
    } else {
      return approverData.map((approverObj, index) => {
        return (
          <ListGroup.Item bsClass="">
            <span className="labSpan">
              {this.props.tr["Approver Details"]}-
            </span>
            {approverObj.signatures}
          </ListGroup.Item>
        );
      });
    }
  }

  renderActivityLog(activityLogData) {
    if (activityLogData.length == 0) {
      return <div>{this.props.tr["No updates found"]}</div>;
    } else {
      return activityLogData.map((activityObj, index) => {
        let intval = "",
          activitymsg = "";
        if (activityObj.description.includes("Group Reassignment Reason -")) {
          return null;
        }
        if (
          activityObj.createdByName == null ||
          activityObj.createdByName == "null"
        )
          activityObj.createdByName = "";
        if (activityObj.isInternal == "Y") intval = "- Internal";
        if (activityObj.from === activityObj.to)
          activitymsg = this.props.tr["Status set to "] + activityObj.to;
        else
          activitymsg =
            this.props.tr["Changed Status from "] +
            activityObj.from +
            this.props.tr[" to "] +
            activityObj.to;
        if (activityObj.isInternal != "Y")
          return (
            <Card bsPrefix=" " className="mediaCrd" key={"activity_" + index}>
              <Card.Body>
                <Card.Title className="blue margin-0">
                  {activityObj.createdOn}
                  <span
                    className="colorStOrange cursorPoint"
                    onClick={this.showUserDetails}
                  >
                    {" "}{activityObj.createdByName}
                  </span>
                </Card.Title>
                <div className="f-size-13 fw-500 cTxt">{console.log('description Attachment: ', activityObj.description, activitymsg)}
                  {activityObj.isAttachment != "Y" && 
                  <ReactReadMoreLess charLimit={235}
                  onShowMore={this.props.onChange}
                  readMoreText={MoreArrow}
                  readLessText={LessArrow}
                  content={activityObj.isAttachment == "Y" ? (
                    <></>
                  ) :  activityObj.from == "" ? (
                    <span dangerouslySetInnerHTML={{ __html: sanitizeElements(activityObj.description.replace(/\n/g, "<br />")) }} />
                  ) : (
                    <span dangerouslySetInnerHTML={{ __html: sanitizeElements(activitymsg.replace(/\n/g, "<br />")) }} />
                  )}
                  />}
                  {activityObj.isAttachment == "Y" ? 
                  <span>
                  <div>
                    <i
                      styles="transform:rotate(-90deg);"
                      className="fa fa-paperclip margin-r-5 f-size-16"
                    ></i>
                    {activityObj.description}{" "}
                  </div>
                </span>
                : <></>}
                </div>
              </Card.Body>
            </Card>
          );
      });
    }
  }

  activityData(comnts) {
    comnts.preventDefault();
    this.setState({ comments: comnts.target.value });
    if (comnts != "" && comnts.target.value.trim() != "")
      this.setState({ commentsAreEmpty: false });
  }

  getActivityLog() {
    let itemId = this.props.itemId;
    this.props.loadBreakFixActivityLogDetails(itemId, "desc", "Fullfilment");
  }

  postActivityData() {
    let itemId = this.props.itemId;
    let module = "Fullfilment";
    if (this.state.comments != "" && this.state.comments.trim() != "") {
      this.setState({ commentsAreEmpty: false });
      let ref = this;

      this.setState({ disabledSave: true });
      let str={};
      str.orderId=this.props.myOrderOrderDetails[0].itemId;
      str = JSON.stringify(str);
      axios.get('/api/orderFullfilmentQuickViewActionMyRequest',{headers:{'query':str}}).then(latestResponse => {
          if(latestResponse.data.data[0].requesterId==user_id && latestResponse.data.data[0].itemStatus=="60" && latestResponse.data.data[0].holdingReasonCode=="75"){
              axios.patch(GLOBAL.fulfillmentpost + latestResponse.data.data[0].itemId,{"itemStatus": "70","modifiedBy": "","modifiedByName": "","holdingReasonCode": "","holdingComments": "","holdingReasonValue": "","asgUserid": 0, "asgUsername": ""});
          }
      });
      axios
        .post(GLOBAL.breakFixActivityLogPostUrl, {
          itemId: itemId,
          description: this.state.comments
            .replace(/"/g, "")
            .replace(/\\/g, "\\\\")
            .replace(/</g, "")
            .replace(/>/g, "")
            .replace(/\n/g, "<br/>"),
          createdOn: "",
          createdBy: "",
          isInternal: "N",
          module: module,
          createdByName: "",
          sendNotification: "true",
        })
        .then((response) => {
          if (response.status === 201) {
            ref.inputComments.value = "";
            ref.setState({ comments: "" });
            if (GLOBAL.filesToBeSent && GLOBAL.filesToBeSent.length > 0) {
              let formData = new FormData();
              formData.append("file", GLOBAL.filesToBeSent[0]);
              let jsonObject = new Object();
              jsonObject.module = ref.props.module;
              jsonObject.itemId = itemId;
              jsonObject.createdOn = Math.round(new Date().getTime() / 1000.0);
              jsonObject.createdBy = "";
              formData.append("attachment", JSON.stringify(jsonObject));

              axios
                .post("/change/api/saveImplPlan", formData)
                .then((response) => {
                  let res = response.data;
                  if (response.status == 200) {
                    ref.setState({
                      activityLogHideShow: 0,
                      viewUpdates: 0,
                      disabledSave: false,
                    });
                    GLOBAL.filesToBeSent = [];
                    ref.getAttachment(itemId, "Item");
                  }
                })
                .catch((error) => {
                  //	console.log('Please upload File up to 20 MB size.');
                  //console.log("error in uploading file.");
                });
            }
            ref.setState({
              activityLogHideShow: 0,
              viewUpdates: 0,
              disabledSave: false,
            });
          } else {
            //console.log("response status is not ok:"+response.statusText)
          }
          this.props.loadBreakFixActivityLogDetails(
            response.data.itemId,
            "desc",
            module
          );
        });
    } else {
      this.setState({ commentsAreEmpty: true });
    }
  }

  postAttachmentWithoutComments() {
    let ref = this;
    let itemId = this.props.itemId;
    let module = "Fullfilment";
    this.setState({ isUloadingDisabled: true });
    this.setState({ disabledSave: true });
  
    if (GLOBAL.filesToBeSent.length > 0) {
      let formData = new FormData();
      GLOBAL.filesToBeSent.forEach((file,i)=>{
        formData.append(
          "file",
          file,
          file.name
        );
      })
      // formData.append("file", GLOBAL.filesToBeSent[0]);
      let jsonObject = new Object();
      jsonObject.module = ref.props.module;
      jsonObject.itemId = itemId;
      jsonObject.createdOn = Math.round(new Date().getTime() / 1000.0);
      jsonObject.createdBy = "";
      formData.append("attachment", JSON.stringify(jsonObject));
      let totalSize = 0;
      for (let i = 0; i < GLOBAL.filesToBeSent.length; i++) {
        totalSize += GLOBAL.filesToBeSent[i].size;
      }
      totalSize = totalSize / 1024 / 1024;

      if (totalSize > attachmentSize) {
        swal({
          text:
            // this.props.tr["Please upload File up to "] +
            this.props.tr["Total file size should be less than "] +
            attachmentSize +
            this.props.tr[" MB."],
            // this.props.tr[" MB size."],
          button: this.props.tr["OK"],
        }).then(() => {
          this.setState({ isUloadingDisabled: false });
          this.setState({ disabledSave: false });
        });
       // this.setState({disabledSave:false})
        return;
      }
      axios
        .post("/change/api/saveImplPlan", formData)
        .then((response) => {
          let res = response.data;
          if (response.status == 200) {
            swal({
              text: this.props.tr["File(s) uploaded successfully."],
              button: this.props.tr["OK"],
            });
            ref.setState({
              activityLogHideShow: 0,
              viewUpdates: 0,
              disabledSave: false,
              showAttachWithoutComments: false,
            });
            this.setState({ disabledSave: false });
            this.setState({ isUloadingDisabled: false });
            GLOBAL.filesToBeSent = [];
            ref.getAttachment(itemId, "Item");
          }
          this.props.loadBreakFixActivityLogDetails(itemId, "desc", module);
        })
        .catch((error) => {
          console.log("Please upload File up to 20 MB size.");
          console.log("error in uploading file.");
          swal({
            text: "Error in uploading file",
            button: this.props.tr["OK"],
          })
          this.setState({ isUloadingDisabled: true });
          this.setState({ disabledSave: true });
        });
    } else {
      this.setState({ disabledSave: false });
      swal({
        text: this.props.tr["Please upload some files first"],
        button: this.props.tr["OK"],
      }).then(() => {
        this.setState({ isUloadingDisabled: false });
      });
    }
  }

  renderAppHistoryDataLog(relatedAppHistoryDataLog) {
    if (!relatedAppHistoryDataLog) {
      return (
        <tr>
          <td width="100%">
            <div>{this.props.tr["Data not available."]}</div>
          </td>
        </tr>
      );
    } else {
      return relatedAppHistoryDataLog.map((relatedAppHistoryData, index) => {
        let statusapp = relatedAppHistoryData.status;
        let orderStatusApp = relatedAppHistoryData.orderStatus;
        let statusappColor = "";
        if (orderStatusApp == "Cancelled") {
          statusapp = "Cancelled";
        } else if (
          statusapp == "null" ||
          statusapp == "" ||
          statusapp == null
        ) {
          statusapp = "Pending";
        } else {
          statusapp = relatedAppHistoryData.status;
        }

        if (statusapp == "Approved") {
          statusappColor = "greenDot";
        } else if (statusapp == "Rejected") {
          statusappColor = "redDot";
        } else if (statusapp == "Pending") {
          statusappColor = "blueDot";
        } else if (statusapp == "Referred Back") {
          statusappColor = "orangeDot";
        } else if (statusapp == "Cancelled") {
          statusappColor = "redDot";
        }

        let approverDetails = "";
        approverDetails =
          relatedAppHistoryData.approverDetails != null
            ? relatedAppHistoryData.approverDetails
            : "";
        let indData = {
          name: relatedAppHistoryData.signatures,
          reason: relatedAppHistoryData.rejectreason,
        };

        let individualPopoverRej = (
          <Popover
            id={index + "ind"}
            className="approvalPopup myReqApprvalPopup"
          >
            <Popover.Header>
            {this.props.tr["Rejection Reason"]}
            </Popover.Header>
            <Popover.Body>
            <Table responsive className="f-size-13 table table-striped table-bordered table-condensed table-hover tablesl ">
              <thead>
                <tr>
                  <th>{this.props.tr["Name"]}</th>
                  <th>{this.props.tr["Comments"]}</th>
                </tr>
              </thead>
              <tbody>{this.individualPopoverTable(indData)}</tbody>
            </Table>
            </Popover.Body>
          </Popover>
        );

        let indApprovalData = { name: relatedAppHistoryData.signatures };
        relatedAppHistoryData.status == "Referred Back"
          ? (indApprovalData.reason = relatedAppHistoryData.referbackComments)
          : (indApprovalData.reason = relatedAppHistoryData.approvalComments);
        let individualPopoverApproval = (
          <Popover
            id={index + "ind" + index}
            className="approvalPopup myReqApprvalPopup"
          >
            <Popover.Header>
            {
              relatedAppHistoryData.status == "Referred Back"
                ? this.props.tr["Referred Back Comment"]
                : this.props.tr["Approver Comment"]
            }
            </Popover.Header>

            <Popover.Body>             
            <Table responsive className="f-size-13 table table-striped table-bordered table-condensed table-hover tablesl ">             
              <thead>
                <tr>
                  <th>{this.props.tr["Name"]}</th>
                  <th>{this.props.tr["Comments"]}</th>
                </tr>
              </thead>
              <tbody>{this.individualPopoverTable(indApprovalData)}</tbody>
            </Table>                       
            </Popover.Body>            
          </Popover>
        );

        let groupPopoverRej = (
          <Popover
            id={index + "groupRej"}            
            className="approvalPopup myReqApprvalPopup"
          >
            <Popover.Header>
            {this.props.tr["Rejection Reason"]}
            </Popover.Header>
            <Popover.Body>
            <Table responsive className="f-size-13 table table-striped table-bordered table-condensed table-hover tablesl ">
              <thead>
                <tr>
                  <th>{this.props.tr["Name"]}</th>
                  <th>{this.props.tr["Status"]}</th>
                  <th>{this.props.tr["Date & Time"]}</th>
                  <th>{this.props.tr["Comments"]}</th>
                </tr>
              </thead>
              <tbody>
                {approverDetails == "" ? (
                  <tr>
                    <td colspan="4">{this.props.tr["No Approver Found"]}</td>
                  </tr>
                ) : (
                  this.groupPopoverTable(approverDetails)
                )}
              </tbody>
            </Table>
            </Popover.Body>
          </Popover>
        );

        let groupPopover = (
          <Popover
            id={index + "group"}
            title={
              relatedAppHistoryData.adhocApproverFlag == "Y"
                ? relatedAppHistoryData.orderStatus == "Cancelled"
                  ? ""
                  : this.props.tr["Adhoc Approvals"]
                : relatedAppHistoryData.status == "Cancelled"
                ? ""
                : relatedAppHistoryData.grpsignatures == 'Parallel Approvers'?["Parallel Approvers"]:this.props.tr["Group Approvals"]
            }
            className="approvalPopup myReqApprvalPopup"
          >
            <Popover.Header>
            {
              relatedAppHistoryData.adhocApproverFlag == "Y"
                ? relatedAppHistoryData.orderStatus == "Cancelled"
                  ? ""
                  : this.props.tr["Adhoc Approvals"]
                : relatedAppHistoryData.status == "Cancelled"
                ? ""
                : relatedAppHistoryData.grpsignatures == 'Parallel Approvers'?["Parallel Approvers"]:this.props.tr["Group Approvals"]
            }
            </Popover.Header>
            <Popover.Body>
            {relatedAppHistoryData.orderStatus == "Cancelled" ? (
              this.props.tr["Data not available."]
            ) : (
              <Table responsive className="f-size-13 table table-striped table-bordered table-condensed table-hover tablesl ">
                <thead>
                  <tr>
                    <th>{this.props.tr["Name"]}</th>
                    <th>{this.props.tr["Status"]}</th>
                    <th>{this.props.tr["Date & Time"]}</th>
                    <th>{this.props.tr["Comments"]}</th>
                  </tr>
                </thead>
                <tbody>
                  {approverDetails == "" ? (
                    <tr>
                      <td colspan="4">{this.props.tr["No Approver Found"]}</td>
                    </tr>
                  ) : (
                    this.groupPopoverTable(approverDetails)
                  )}
                </tbody>
              </Table>
            )}
            </Popover.Body>
          </Popover>
        );

        return (
          <tr>
            <td>
              {statusapp == "Rejected" ? (
                relatedAppHistoryData.approverType != "Group" &&
                relatedAppHistoryData.approverType != "GBO" &&
                relatedAppHistoryData.adhocApproverFlag != "Y" ? (
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="bottom"
                    overlay={individualPopoverRej}
                  >
                    <Button
                      className={statusappColor}
                      title={this.props.tr[statusapp]}
                      bsClass=""
                      variant="success"
                    />
                  </OverlayTrigger>
                ) : relatedAppHistoryData.approverType == "Group" ||
                  relatedAppHistoryData.adhocApproverFlag == "Y" ? (
                  <span
                    className={statusappColor}
                    title={this.props.tr[statusapp]}
                  />
                ) : (
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="bottom"
                    overlay={groupPopoverRej}
                  >
                    <Button
                      className={statusappColor}
                      title={this.props.tr[statusapp]}
                      bsClass=""
                      variant="success"
                    />
                  </OverlayTrigger>
                )
              ) : relatedAppHistoryData.approverType != "Group" &&
                relatedAppHistoryData.approverType != "GBO" &&
                relatedAppHistoryData.adhocApproverFlag != "Y" ? (
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom"
                  overlay={individualPopoverApproval}
                >
                  <Button
                    className={statusappColor}
                    title={this.props.tr[statusapp]}
                    bsClass=""
                    variant="success"
                  />
                </OverlayTrigger>
              ) : (
                <span
                  className={statusappColor}
                  title={this.props.tr[statusapp]}
                />
              )}
            </td>

            <td>{relatedAppHistoryData.levelname}</td>
            <td>
              {relatedAppHistoryData.approverType.trim() != "Group" ||
              relatedAppHistoryData.approverType.trim() != "GBO"
                ? relatedAppHistoryData.signatures == "null" ||
                  relatedAppHistoryData.signatures == null
                  ? "Approver does not exist"
                  : relatedAppHistoryData.signatures
                : relatedAppHistoryData.signatures}
              {relatedAppHistoryData.approverType.trim() == "Group" ||
              relatedAppHistoryData.approverType.trim() == "GBO" ||
              relatedAppHistoryData.adhocApproverFlag == "Y" ? (
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom"
                  overlay={groupPopover}
                >
                  <Button bsPrefix=" " title="Info" bsClass="" className="btninfo">
                    i
                  </Button>
                </OverlayTrigger>
              ) : (
                ""
              )}
            </td>
            <td>{relatedAppHistoryData.lastUpdatedDate}</td>
          </tr>
        );
      });
    }
  }

  renderApprovalHistoryDataLog(relatedAppHistoryDataLog) {
    if (!relatedAppHistoryDataLog) {
      return (
        <tr>
          <td width="100%">
            <div>{this.props.tr["Data not available."]}</div>
          </td>
        </tr>
      );
    } else {
      return relatedAppHistoryDataLog.map((relatedAppHistoryData, index) => {
        return (
          <div>
            <div className="rwTable" style={{ "border-top": "none" }}>
              <div md={4} className="padding-r-0 rwKey">
                {this.props.tr["Level Name"]}
              </div>
              <div>{relatedAppHistoryData.levelname}</div>
            </div>

            <div className="rwTable">
              {
                <div md={4} className="padding-r-0 rwKey">
                  {relatedAppHistoryData.approverType.trim() == "Group" ||
                  relatedAppHistoryData.approverType.trim() == "GBO"
                    ? this.props.tr["Approver Group"]
                    : this.props.tr["Approver Name"]}
                </div>
              }
              <div>{relatedAppHistoryData.signatures}</div>
            </div>

            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
                {this.props.tr["Status"]}
              </div>
              <div>{relatedAppHistoryData.status}</div>
            </div>

            <div className="rwTable">
              <div md={4} className="padding-r-0 rwKey">
                {this.props.tr["App/Rej By"]}
              </div>
              <div>{relatedAppHistoryData.modifiedby}</div>
            </div>

            {relatedAppHistoryData.status == "Rejected" ? (
              <div>
                <div className="rwTable">
                  <div md={4} className="padding-r-0 rwKey">
                    {this.props.tr["App/Rej Date"]}
                  </div>
                  <div>{relatedAppHistoryData.modifieddate}</div>
                </div>
                <div className="rwTable" style={{ "border-bottom": "none" }}>
                  <div md={4} className="padding-r-0 rwKey">
                    {this.props.tr["Rejected Reason"]}
                  </div>
                  <div>{relatedAppHistoryData.rejectreason}</div>
                </div>
              </div>
            ) : (
              <div className="rwTable" style={{ "border-bottom": "none" }}>
                <div md={4} className="padding-r-0 rwKey">
                  {this.props.tr["App/Rej Date"]}
                </div>
                <div>{relatedAppHistoryData.modifieddate}</div>
              </div>
            )}
          </div>
        );
      });
    }
  }

  getIssue(event) {
    console.log("CANCEl=",event.target.value);
    if(event.target.value.trim()== ''){
      this.setState({
        issue: '',
        issueValidationError : 'error'
      })
    }else{
      this.setState({
        issueValidationError : ''
      })
      this.setState({
        issue: event.target.value,
        // issueValidationError: event.target.value.trim().length===0?'error':''
      });
    }
  }

  reorderComments(comnts) {
    comnts.preventDefault();
    this.setState({ reorderComments: comnts.target.value });
    if (comnts != "" && comnts.trim() != "")
      this.setState({ commentsAreEmpty: false });
  }

  componentWillReceiveProps(newProps) {
    if(typeof newProps.myOrderOrderDetails[0]!=='undefined' && typeof this.props.myOrderOrderDetails[0]!=='undefined'){
			if(typeof newProps.myOrderOrderDetails[0].status!=='undefined'){
        if(newProps.myOrderOrderDetails[0].status !== this.props.myOrderOrderDetails[0].status){
					this.setState({showAttachWithoutComments: false});
			  }
		  }
	  }
    if(this.props?.myOrderOrderDetails[0]?.itemId !== newProps?.myOrderOrderDetails[0]?.itemId) {
      this.props.getTaskCountForPieChart("Fulfillment", newProps.myOrderOrderDetails[0].itemId, newProps.myOrderOrderDetails[0].companyId,'myRequest');
    }
    if (newProps.taskCountByItemId) {
      let data = {
        data: [
          { name: "open", value: 0, color: "#2DCCFF" },
          { name: "inProgress", value: 0, color: "#b57bff" },
          { name: "completed", value: 0, color: "#56F000" },
        ],
        meta: {
          totalCount: 0,
          completed: 0,
        },
      };
      let tasksCount = newProps.taskCountByItemId.reduce((acc, curr) => {
        if (curr.status === "Assigned" || curr.status === "Draft") {
          _.find(acc.data, { name: "open" }).value += Number(curr.count);
        } else if (curr.status === "In Progress" || curr.status === "On Hold") {
          _.find(acc.data, { name: "inProgress" }).value += Number(curr.count);
        } else if (curr.status === "Completed" || curr.status === "Cancelled") {
          _.find(acc.data, { name: "completed" }).value += Number(curr.count);
          acc.meta.completed += Number(curr.count);
        }
        acc.meta.totalCount += Number(curr.count);
        return acc;
      }, data);
      console.log("Task Count Final --- ", tasksCount);
      this.setState({ taskCount: tasksCount });
    }
    this.setState({
      savedMessage: "",
    });
  }

  postCancellationRequest() {
    let count = 0;

    if(this.state.cancelCode===''){
      count++;
      this.setState({canErrorCode: "error"});
    }
    if (!this.state.issue && this.state.issue.trim() == "") {
      count++;
      this.setState({ issueValidationError: 'error' });
      // this.state.issueValidationError = "error";
    } else {
      // this.state.issueValidationError = "";
      this.setState({ issueValidationError: '' });

    }
    if (count != 0) {
      this.setState({ issueValidationError: this.state.issueValidationError });
    } else {
      let ref = this;

      if (this.state.issue != "") {
        if (this.props.myOrderOrderDetails[0].itemStatus == "65" ||
          this.props.myOrderOrderDetails[0].itemStatus == "100" || 
          this.props.myOrderOrderDetails[0].itemStatus == "50"
        ) {
          this.setState({ disabledSave: true });
          axios
            .patch(GLOBAL.fulfillmentpost + this.props.itemId, {
              itemStatus: "85",
              itemType: "70",
              cancelledOn: "",
              modifiedBy: "",
              modifiedByName: "",
              cancellationReason: ref.state.issue
                .replace(/</g, "")
                .replace(/>/g, "")
                .replace(/"/g, "")
                .replace(/\\/g, "\\\\")
                .replace(/\n/g, "<br/>"),
              cancellationReasonCode: this.state.cancelCode,
              cancellationReasonValue: this.state.cancelValue, 
            })
            .then(function (response) {
              if (response.status == 201) {
                ref.setState({
                  savedMessage: "Cancellation request created",
                  cancelHideShow: 0,
                  disabledSave: false,
                });
                ref.props.showRightPanelFun(false);
                ref.props.showActiveRow(0, 0);
                let searchParams = {};
                searchParams = ref.props.buildSearchParamsForOrderFilter();
                ref.props.loadFilterOrderList(
                  searchParams,
                  0,
                  10,
                  ref.props.sortOnColumn,
                  ref.props.sortOrder,
                  ref.props.searchKey,
                  ref.props.searchValue,
                  ref.props.pattern,
                  ref.props.status
                );
                ref.props.loadChangeTotalApprovalDetails(
                  response.data.itemId,
                  "10",
                  "N"
                );
                ref.props.resetSortingArrows();

                axios
                  .post(GLOBAL.breakFixActivityLogPostUrl, {
                    itemId: response.data.itemId.toString(),
                    description: `Cancellation Type: ${ref.state.cancelValue}, Cancellation Reason: ${ref.state.issue
                        .replace(/</g, "")
                        .replace(/>/g, "")
                        .replace(/"/g, "")
                        .replace(/\\/g, "\\\\")
                        .replace(/\n/g, "<br/>")}`,
                    createdOn: "",
                    createdBy: "",
                    isInternal: "N",
                    module: "Fullfilment",
                    createdByName: "",
                    sendNotification: "true",
                  })
                  .then(function (response) {
                    if (response.status == 201) {
                      console.log(response);
                    }

                    console.log(response);
                  });
              } else {
                console.log("error in updating status");
              }
            })
            .catch(function (error) {
              ref.setState({ disabledSave: false });
            });
        } else if (this.props.myOrderOrderDetails[0].itemStatus == "70") {
          this.setState({ disabledSave: true });
          axios
            .patch(GLOBAL.fulfillmentpost + this.props.itemId, {
              itemStatus: "60",
              holdingReasonCode: "110",
              modifiedBy: "",
              modifiedByName: "",
              cancellationReasonCode: this.state.cancelCode,
              cancellationReasonValue: this.state.cancelValue,
              cancellationReason: this.state.issue.replace(/</g, "").replace(/>/g, "").replace(/"/g, "").replace(/\\/g, "\\\\")
            })
            .then(function (response) {
              if (response.status == 201) {
                ref.props.showRightPanelFun(false);
                let searchParams = {};
                searchParams = ref.props.buildSearchParamsForOrderFilter();
                ref.props.loadFilterOrderList(
                  searchParams,
                  0,
                  10,
                  ref.props.sortOnColumn,
                  ref.props.sortOrder,
                  ref.props.searchKey,
                  ref.props.searchValue,
                  ref.props.pattern,
                  ref.props.status
                );
                ref.props.loadChangeTotalApprovalDetails(
                  response.data.itemId,
                  "10",
                  "N"
                );
                ref.props.loadChangeTotalApprovalHistoryDetails(
                  response.data.itemId,
                  "10",
                  "Y"
                );
                ref.props.resetSortingArrows();
                ref.inputComments.value = "";
                ref.setState({
                  savedMessage: "Cancellation request created",
                  cancelHideShow: 0,
                  disabledSave: false,
                });
                axios
                  .post(GLOBAL.breakFixActivityLogPostUrl, {
                    itemId: ref.props.itemId,
                    description: `Cancellation Type: ${ref.state.cancelValue}, Cancellation Reason: ${ref.state.issue
                      .replace(/</g, "")
                      .replace(/>/g, "")
                      .replace(/"/g, "")
                      .replace(/\\/g, "\\\\")
                      .replace(/\n/g, "<br/>")}`,
                    createdOn: "",
                    createdBy: "",
                    isInternal: "N",
                    module: ref.props.module,
                    createdByName: "",
                    sendNotification: "true",
                  })
                  .then(function (response) {
                    if (response.status == 201) {
                      console.log(response);
                    }
                  });
              } else {
                console.log("error in updating status");
              }
            })
            .catch(function (error) {
              // console.log(error);
              //alert("H1"+error);
            });
        }
      } else {
        console.log("Cancellation reason not found");
        ref.setState({ disabledSave: false });
      }
    }
  }

  postReorderRequest() {
    let itemId = this.props.itemId;
    let module = "Fullfilment";
    if (
      this.state.reorderComments != "" &&
      this.state.reorderComments.trim() != ""
    ) {
      this.setState({ commentsAreEmpty: false });
      let ref = this;

      this.setState({ disabledSave: true });

      axios
        .patch(GLOBAL.fulfillmentpost + itemId, {
          itemStatus: "65",
          modifiedBy: "",
          modifiedByName: "",
        })
        .then(function (response) {
          console.log(response);
          if (response.status == 201) {
            let searchParams = {};
            searchParams = ref.props.buildSearchParamsForOrderFilter();
            console.log("inside the reorder++", searchParams);
            ref.props.showRightPanelFun(false);
            ref.props.loadFilterOrderList(
              searchParams,
              0,
              10,
              ref.props.sortOnColumn,
              ref.props.sortOrder,
              ref.props.searchKey,
              ref.props.searchValue,
              ref.props.pattern,
              ref.props.status
            );
            ref.props.loadChangeTotalApprovalDetails(
              response.data.itemId,
              "10",
              "N"
            );
            ref.props.loadChangeTotalApprovalHistoryDetails(
              response.data.itemId,
              "10",
              "Y"
            );
            ref.props.resetSortingArrows();
            axios
              .post(GLOBAL.breakFixActivityLogPostUrl, {
                itemId: itemId,
                description: ref.state.reorderComments.replace(/\n/g, "<br/>"),
                createdOn: "",
                createdBy: "",
                isInternal: "N",
                module: module,
                createdByName: "",
                isReorder: "Y",
                sendNotification: "true",
              })
              .then((response) => {
                if (response.status === 201) {
                  ref.inputComments.value = "";
                  ref.setState({ reorderComments: "" });
                  ref.setState({
                    reorderHideShow: 0,
                    viewUpdates: 0,
                    disabledSave: false,
                  });
                } else {
                  //console.log("response status is not ok:"+response.statusText)
                }
                ref.props.loadBreakFixActivityLogDetails(
                  response.data.itemId,
                  "desc",
                  module
                );
              });
          } else {
            console.log("error in updating status");
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    } else {
      this.setState({ commentsAreEmpty: true });
    }
  }

  renderQuestionCategoryWise(myOrderQuestionDetails) {
    return [].concat(myOrderQuestionDetails).sort((a, b) => a.categorySequence > b.categorySequence ? 1 : -1).map((category, i) => {
      return (
        <Accordion
          id={"questions" + i}
          accordion
          className="brkFix brkFixOrder"
        >
          <Accordion eventKey="1">
            <Accordion.Header>
              <h4 toggle className="font1">
                {category.categoryName}
              </h4>
            </Accordion.Header>
            <Accordion.Body collapsible>
              <div key={i}>
                <Table
                  bordered
                  condensed
                  hover
                  responsive
                  className="tableView addWhiteSpc"
                >
                  <tbody>
                    {this.renderIndividualCategoryQuestions(category.questions)}
                  </tbody>
                </Table>
              </div>
            </Accordion.Body>
          </Accordion>
        </Accordion>
      );
    });
  }

  renderIndividualCategoryQuestions(categoryQuestions) {
    let answers = "";
    return [].concat(categoryQuestions).sort((a, b) => a.questionSequence > b.questionSequence ? 1 : -1)
    .map((categoryQuestion, i) => {
      if (categoryQuestion.question == "Assets") {
        let newstringreplaced = "";
        if (
          typeof categoryQuestion.answer === "undefined" ||
          categoryQuestion.answer === null ||
          categoryQuestion.answer === ""
        ) {
          answers = "NA";
        } else if (
          categoryQuestion.answer != null &&
          categoryQuestion.answer != ""
        ) {
          newstringreplaced = categoryQuestion.answer.replace(
            /,Name/gi,
            "~Name"
          );
          answers = newstringreplaced.split("~");
        } else {
          answers = categoryQuestion.answer;
        }
      }
      return (
        <tr key={i}>
          <td className="bgBtn20 f-size-13" style={{ width: "50%" }}>
            {categoryQuestion.question}
          </td>
          <td className="f-size-13">
            {categoryQuestion.question !== "Assets" ? (
              typeof categoryQuestion.answer === "undefined" ||
              categoryQuestion.answer === null ||
              categoryQuestion.answer === "" ? (
                "NA"
              ) : (
                categoryQuestion.answer
                  .replace(/ ~ /g, "\n")
                  .split("\n")
                  .map((data) => {
                    console.log(data);
                    if(isJson(data) && JSON.parse(data).length > 0){
                      return <AssetTablePopup question={categoryQuestion.question} answer={data}/>;
                    }
                    return <p>{data}</p>;
                  })
              )
            ) : answers != null ? (
              <div className="assetsAnswers">
                {answers.map((a, i) => {
                  return <p>{a}</p>;
                })}
                ;
              </div>
            ) : null}
          </td>
        </tr>
      );
    });
  }

  renderOrderingInformation(orderingInfo) {
    return (
      <div className="table-responsive">
        <Table
          bordered
          condensed
          hover
          responsive
          className="tableView sortImg"
        >
          <tbody>
            {(orderingInfo.addressLine1 != null &&
              orderingInfo.addressLine1 != undefined &&
              orderingInfo.addressLine1 != "") ||
            (orderingInfo.addressLine2 != null &&
              orderingInfo.addressLine2 != undefined &&
              orderingInfo.addressLine2 != "") ||
            (orderingInfo.city != null &&
              orderingInfo.city != undefined &&
              orderingInfo.city != "") ||
            (orderingInfo.country != null &&
              orderingInfo.country != undefined &&
              orderingInfo.country != "") ||
            (orderingInfo.postalCode != null &&
              orderingInfo.postalCode != undefined &&
              orderingInfo.postalCode != "") ||
            (orderingInfo.locationName != null &&
              orderingInfo.locationName != undefined &&
              orderingInfo.locationName != "") ||
            (orderingInfo.recipientCubicle != null &&
              orderingInfo.recipientCubicle != "null" &&
              orderingInfo.recipientCubicle != undefined &&
              orderingInfo.recipientCubicle != "") ? (
              <tr>
                <td className="bgBtn20 f-size-13" style={{ width: "50%" }}>
                  {this.props.tr["Shipping Location"]}
                </td>
                <td className="f-size-13">
                  {orderingInfo.locationName == null ||
                  orderingInfo.locationName == "" ||
                  orderingInfo.locationName == undefined ? (
                    <div>
                      {orderingInfo.addressLine1 != null &&
                      orderingInfo.addressLine1 != undefined &&
                      orderingInfo.addressLine1 != "" ? (
                        <p>{orderingInfo.addressLine1}</p>
                      ) : (
                        ""
                      )}
                      {orderingInfo.addressLine2 != null &&
                      orderingInfo.addressLine2 != undefined &&
                      orderingInfo.addressLine2 != "" ? (
                        <p>{orderingInfo.addressLine2}</p>
                      ) : (
                        ""
                      )}
                      {orderingInfo.city != null &&
                      orderingInfo.city != undefined &&
                      orderingInfo.city != "" ? (
                        <p>{orderingInfo.city}</p>
                      ) : (
                        ""
                      )}
                      {orderingInfo.state != null &&
                      orderingInfo.state != undefined &&
                      orderingInfo.state != "" ? (
                        <p>{orderingInfo.state}</p>
                      ) : (
                        ""
                      )}
                      {orderingInfo.country != null &&
                      orderingInfo.country != undefined &&
                      orderingInfo.country != "" ? (
                        <p>{orderingInfo.country}</p>
                      ) : (
                        ""
                      )}
                      {orderingInfo.postalCode != null &&
                      orderingInfo.postalCode != undefined &&
                      orderingInfo.postalCode != "" ? (
                        <p>{orderingInfo.postalCode}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div>
                      {orderingInfo.locationName != null &&
                      orderingInfo.locationName != undefined &&
                      orderingInfo.locationName != "" ? (
                        <p>{orderingInfo.locationName}</p>
                      ) : (
                        ""
                      )}
                      {orderingInfo.recipientCubicle != null &&
                      orderingInfo.recipientCubicle != "null" &&
                      orderingInfo.recipientCubicle != undefined &&
                      orderingInfo.recipientCubicle != "" ? (
                        <p>{orderingInfo.recipientCubicle}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </td>
              </tr>
            ) : (
              ""
            )}

            {orderingInfo.recipientName != null &&
            orderingInfo.recipientName != undefined &&
            orderingInfo.recipientName != "" ? (
              <tr>
                <td className="bgBtn20 f-size-13" style={{ width: "50%" }}>
                  {this.props.tr["Delivery Recipient"]}
                </td>
                <td className="f-size-13">{orderingInfo.recipientName}</td>
              </tr>
            ) : (
              ""
            )}

            {orderingInfo.recipientContact != null &&
            orderingInfo.recipientContact != undefined &&
            orderingInfo.recipientContact != "" ? (
              <tr>
                <td className="bgBtn20 f-size-13" style={{ width: "50%" }}>
                  {this.props.tr["Contact Number"]}
                </td>
                <td className="f-size-13">{orderingInfo.recipientContact}</td>
              </tr>
            ) : (
              ""
            )}
          </tbody>
        </Table>
      </div>
    );
  }

  renderAdditionalDetails(profileDetails) {
    let locationName = "";
    if (
      profileDetails.cwlName != "" &&
      profileDetails.cwlName != null &&
      profileDetails.cwlName != "null"
    ) {
      locationName = profileDetails.cwlName;
    } else {
      locationName = profileDetails.locationName;
    }

    return (
      <div className="table-responsive">
        <Table
          bordered
          condensed
          hover
          responsive
          className="tableView sortImg"
        >
          <tbody>
            <tr>
              <td className="bgBtn20 f-size-13" style={{ width: "50%" }}>
                {this.props.tr["Permanent Location"]}
              </td>
              <td className="f-size-13">
                {this.props.myOrderOrderDetails[0].requesterLocationName ==
                  "undefined" ||
                this.props.myOrderOrderDetails[0].requesterLocationName ==
                  undefined ||
                this.props.myOrderOrderDetails[0].requesterLocationName ==
                  "null" ||
                this.props.myOrderOrderDetails[0].requesterLocationName == null
                  ? ""
                  : this.props.myOrderOrderDetails[0].requesterLocationName}
              </td>
            </tr>
            <tr>
              <td className="bgBtn20 f-size-13" style={{ width: "50%" }}>
                {this.props.tr["Current Working Location"]}
              </td>
              <td className="f-size-13">{locationName}</td>
            </tr>
            <tr>
              <td className="bgBtn20 f-size-13" style={{ width: "50%" }}>
                {this.props.tr["Alternate Phone"]}
              </td>
              <td className="f-size-13">{profileDetails.alternatePhone}</td>
            </tr>
            <tr>
              <td className="bgBtn20 f-size-13" style={{ width: "50%" }}>
                {this.props.tr["Alternate Email"]}
              </td>
              <td className="f-size-13">{profileDetails.alternateEmail}</td>
            </tr>
            <tr>
              <td className="bgBtn20 f-size-13" style={{ width: "50%" }}>
                {this.props.tr["Manager Name"]}
              </td>
              <td className="f-size-13">{profileDetails.managerName}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
  checkRenderApproverData(offeringId, module, ciId, ciClass, itemid) {
    let moduleId;
    if (module == "RFC") {
      moduleId = "14";
    } else {
      moduleId = "10";
    }
    this.setState({ showApprovalLoader: true });
    axios
      .get(
        "/api/approval/ruleCount/offeringId/" +
          offeringId +
          "/moduleId/" +
          moduleId +
          "/ciId/" +
          ciId +
          "/ciclass/" +
          ciClass +
          "/requestId/" +
          itemid
      )
      .then((resp) => {
        if (resp.status == 200) {
          this.setState({ showApprovalLoader: false });
          if (resp.data.count >= 1) {
            this.props.loadChangeTotalApprovalDetails(
              this.props.itemId,
              moduleId,
              "N"
            );
            this.setState({ displayMessage: "" });
          }
          else if (resp.data.count == -1) {
            this.setState({ showApprovalLoader: false });
            this.setState({
              displayMessage:
              this.props.tr["There are no active approvals as the request is cancelled"],
            });
          }
          else {
            if(this.props.myOrderOrderDetails[0].status==='Submitted'){
              this.setState({ showApprovalLoader: false });
              this.setState({
                displayMessage:
                  this.props.tr["This record is currently under review to determine if approval is required. Please check back later for updates"],
              });}
              else{
            this.setState({ showApprovalLoader: false });
            this.setState({
              displayMessage:
                this.props.tr["This service does not require any approval"],
            });}
          }
        }
      });
  }

  renderAttList(attributeData) {
    if (!attributeData) {
      return null;
    }
    return attributeData.map((taskObj) => {
      let statusClassName = ""
      if (taskObj.status.status === "Assigned") {
        statusClassName = "custom-label-primary";
        } else if (taskObj.status.status === "Draft") {
        statusClassName = "custom-label-draft";
        } else if (taskObj.status.status === "In Progress") {
          statusClassName = "custom-label-inProgress";
        } else if (taskObj.status.status === "On Hold") {
          statusClassName = "custom-label-warning";
        } else if (taskObj.status.status === "Completed") {
          statusClassName = "custom-label-success";
        } else if (taskObj.status.status === "Cancelled") {
          statusClassName = "custom-label-error";
        }
        taskObj.requestType='myRequest';
      const tooltip = (<Popover className=""><TaskDetailsPopup taskObj={taskObj} disableFieldsForGuestRole={true}  /></Popover>);

      return (
        <tr>
          <td><span className='nowrap'><OverlayTrigger trigger="click" rootClose placement={this.props.windowSize.width < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
} overlay={tooltip}><span className="cursorPoint" title={this.props.tr['Info']}>{taskObj.taskCode}<ImInfo className='taskInfoicn'/></span></OverlayTrigger></span></td>
          <td className={statusClassName}>{taskObj.status.status}</td>
          <td>{taskObj.assignedTo}</td>
          <td>{taskObj.expectedDueDate}</td>
        </tr>
      );
    });
  }

  loadRelatedTaskData(itemId, module, companyId) {
    if (module == "Breakfix") {
      this.props.loadBreakFixTaskLogDetails(
        itemId,
        "Breakfix",
        "asc",
        companyId,
        "myRequest"
      );
    }
    if (module == "Item") {
      this.props.loadBreakFixTaskLogDetails(
        this.props.itemId,
        "Fullfilment",
        "asc",
        companyId,
        "myRequest"
      );
    }
  }
  handleSelect(tabKey) {
    this.setState({ tabKey });
  }
  renderItemComponents(orderDetails) {
    let finalArray = [],
      coreArray = [],
      optionalArray = [];
    let optionalCompCount = 0;
    let totalUnitPrice = parseFloat(orderDetails.servicePrice);

    finalArray.push(
      <ListGroup.Item
        bsClass=""
        className="rwTableGap"
        style={{ "border-top": "none" }}
      >
        <span className="rwKey">
          <b>{this.props.tr["Service Price"]}</b>
        </span>
        <span className="rwGap">-</span>
        <span className="rwVal">
          {orderDetails.currency != null &&
          orderDetails.itemPrice > 0 &&
          orderDetails.isPriceEnabled
            ? orderDetails.currency + " " + orderDetails.servicePrice
            : this.props.tr["NA"]}
        </span>
      </ListGroup.Item>
    );

    orderDetails.components.forEach((component, i) => {
      if (component.componentType == "Core") {
        coreArray.push(
          <ListGroup.Item bsClass="" className="rwTableGap">
            <span className="rwKey"><b>{component.componentNameSx}</b></span>
            <span className="rwGap">-</span>
            <span className="rwVal">{this.props.tr["Included"]}</span>
          </ListGroup.Item>
        );
      }
      if (component.componentType == "Optional") {
        optionalArray.push(
          <ListGroup.Item bsClass="" className="rwTableGap">
            <span className="rwKey"><b>{component.componentNameSx}</b></span>
            <span className="rwGap">-</span>
            <span className="rwVal">
              {orderDetails.currency != null &&
              orderDetails.itemPrice > 0 &&
              orderDetails.isPriceEnabled
                ? orderDetails.currency + " " + component.componentPrice
                : this.props.tr["NA"]}
            </span>
          </ListGroup.Item>
        );
        optionalCompCount++;

        totalUnitPrice =
          orderDetails.servicePrice + parseFloat(component.componentPrice);
      }
    });

    finalArray = finalArray.concat(coreArray);

    if (optionalCompCount > 0) {
      finalArray.push(
        <ListGroup.Item bsClass="" className="rwTableGap">
          <span className="rwKey">
            <b>{this.props.tr["Optional Components"]}</b>
          </span>
          <span className="rwGap"></span>
          <span className="rwVal"></span>
        </ListGroup.Item>
      );
      finalArray = finalArray.concat(optionalArray);
    }

    finalArray.push(
      <ListGroup.Item bsClass="" className="rwTableGap">
        <span className="rwKey">
          <b>{this.props.tr["Total Unit Price"]}</b>
        </span>
        <span className="rwGap">-</span>
        <span className="rwVal">
          {orderDetails.currency != null &&
          orderDetails.itemPrice > 0 &&
          orderDetails.isPriceEnabled
            ? orderDetails.currency + " " + totalUnitPrice
            : this.props.tr["NA"]}
        </span>
      </ListGroup.Item>
    );

    return finalArray;
  }
  setCancellationType(event){
    if(event.nativeEvent.target.value===''){
      this.setState({canErrorCode: 'error'});
    }else{
      this.setState({canErrorCode: ''}); 
    }
    this.setState({cancelCode: event.nativeEvent.target.value, cancelValue: event.nativeEvent.target[event.target.selectedIndex].text});
  }

  renderCancellationType(values){
    if (values.length == 0) {
      return null;
    }
    return values.map(item => <option key={item.field1Key} value={item.field1Value}>{item.field1Key}</option>);
  }

  render() {
    let displayName = "";
    let displayNameStr = "";
    displayNameStr =
      this.props.ciServiceName != null && this.props.ciServiceName != ""
        ? this.props.ciServiceName
        : "";
    displayName =
      displayNameStr != ""
        ? displayNameStr
            .replace(
              /(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/gi,
              ""
            )
            .trim()
        : "";
    displayName = displayName != "" ? displayName.replace(/\s+/g, " ") : "";

    let getInnerWidth = (innerWidth) => {
      if (innerWidth > 1535) return 92;
      if (innerWidth > 1367) return 80;
      if (innerWidth > 1279) return 70;
      if (innerWidth > 1023) return 50;
      if (innerWidth > 767) return 38;
      if (innerWidth > 640) return 62;
      if (innerWidth > 539) return 48;
      if (innerWidth > 411) return 70;
      if (innerWidth > 279) return 40;
      return 20;
    };

    if (
      this.props.showLoader ||
      this.props.showLoader_1 ||
      this.props.showLoader2 ||
      this.props.showLoader3 ||
      this.props.showLoader4 ||
      this.props.serviceReviewShowLoader
    ) {
      return (
        <div>
          <ListLoader myStyle={{ marginTop: "20px" }} />
        </div>
      );
    } else if (
      this.props.myOrderOrderDetails.length == 0 ||
      this.props.myOrderOrderDetails.length == undefined
    ) {
      return (
        <div>
          <font color="green">
            <b>{this.props.tr["There is no matching data available"]}</b>
          </font>
        </div>
      );
    }

    let questionsData = [];
    let approverName = [];
    let approvalStatus = [];
    let approverLevel = [];
    let cancelOrder = [];
    let itemType = "";
    let orderType = "";
    let reOrder = [];

    if (this.props.myOrderOrderDetails[0].itemType == "70") {
      (orderType = "Cancel"), (itemType = true);
    } else {
      (orderType = "Fulfillment"), (itemType = false);
    }

    if (this.props.approverListDetails.length > 0) {
      for (let i = 0; i <= this.props.approverListDetails.length - 1; i++) {
        approverName.push(
          <div className="details">
            <span className="labSpan">{this.props.tr["Approver Name"]}-</span>
            {this.props.approverListDetails[i].signatures}
          </div>
        );
      }
    } else {
      approverName.push(
        <div className="details">
          <span className="labSpan">{this.props.tr["Approver Name"]}-</span>NA
        </div>
      );
    }
if(this.props.isSelf == 'watcher'){

}else{
  if (
    (this.props.myOrderOrderDetails[0].itemStatus == "65" ||
      this.props.myOrderOrderDetails[0].itemStatus == "100" || this.props.myOrderOrderDetails[0].itemStatus == "50") &&
    this.props.myOrderOrderDetails[0].isCancelEnabled == true
  ) {
    cancelOrder.push(
      <Button
        className={this.state.cancelHideShow ? "rgSidrkBtn": "rgSiLigBtn"}
        bsSize="small"
        title={this.props.tr["Cancel Order"]}
        variant="primary"
        onClick={() => {
          this.cancelHideShow();
          this.setState({ showAttachWithoutComments: false });
        }}
        disabled={itemType}
      >
        {this.props.tr["Cancel Order"]}
      </Button>
    );
  } else {
    cancelOrder.push(null);
  }
}
    

    if (this.props.myOrderOrderDetails[0].itemStatus == "100") {
      reOrder.push(
        <Button
          className={this.state.reorderHideShow ? "rgSidrkBtn": "rgSiLigBtn"}
          bsSize="small"
          title={this.props.tr["Reorder"]}
          variant="primary"
          onClick={() => {
            this.reorderHideShow();
            this.setState({ showAttachWithoutComments: false });
          }}
        >
          {this.props.tr["Reorder"]}
        </Button>
      );
    } else {
      reOrder.push(null);
    }

    if (this.props.approverListDetails.length > 0) {
      for (let i = 0; i <= this.props.approverListDetails.length - 1; i++) {
        approvalStatus.push(
          <div className="details">
            <span className="labSpan">{this.props.tr["Status"]}-</span>
            {this.props.approverListDetails[i].status}
          </div>
        );
      }
    } else {
      approvalStatus.push(
        <div className="details">
          <span className="labSpan">{this.props.tr["Status"]}-</span>
          NA
        </div>
      );
    }

    if (this.props.approverListDetails.length > 0) {
      for (let i = 0; i <= this.props.approverListDetails.length - 1; i++) {
        approverLevel.push(
          <div className="details">
            <span className="labSpan">{this.props.tr["Approver Level"]}-</span>
            {this.props.approverListDetails[i].levelname}
          </div>
        );
      }
    } else {
      approverLevel.push(
        <div className="details">
          <span className="labSpan">{this.props.tr["Approver Level"]}-</span>
          NA
        </div>
      );
    }
    if (this.props.myOrderOrderDetails[0].components == "undefined") {
      //alert("HHM")
    }
    for (
      let j = 0;
      j <= this.props.myOrderOrderDetails[0].questions.length - 1;
      j++
    ) {
      questionsData.push(
        <div className="details">
          <i className="fa fa-check bullets dCheckbox" />
          {this.props.myOrderOrderDetails[0].questions[j].question} :{" "}
          {this.props.myOrderOrderDetails[0].questions[j].answer}
        </div>
      );
    }
    let approverDetails = "";
    if (this.props.approverListDetails.length > 0) {
      // console.log(this.props.approverListDetails.length);
    }
    let showActivity = "";
    if (
      this.props.orderStatus == "Fixed" ||
      this.props.orderStatus == "Closed" ||
      this.props.orderStatus == "Cancelled" ||
      this.props.orderStatus == "Fullfiled" ||
      this.props.orderStatus == "Draft"
    ) {
      showActivity = <div />;
    } else {
      showActivity = (
        <Button
          className={this.state.activityLogHideShow == 1 ? "rgSidrkBtn": "rgSiLigBtn"}
          bsSize="small"
          title={this.props.tr["Post a Comment"]}
          variant="primary"
          onClick={() => {
            this.activityLogHideShow();
            this.setState({ showAttachWithoutComments: false });
          }}
        >
          {this.props.tr["Post a Comment"]}
        </Button>
      );
    }

    let showTestAreaForComments = "";
    if (this.state.showUpdates == 0) {
      showTestAreaForComments = (
        <div className="position-re">
          <Form.Label bsClass="">
            <span className="rStar"></span>
            {this.props.tr["Write your comment"]}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="2"
            inputRef={(input) => (this.inputComments = input)}
            onChange={this.activityData.bind(this)}
            spellCheck="true"
          />

          {this.state.commentsAreEmpty ? (
            <div className="error">
              <font color="red">
                <strong>
                  {this.props.tr["Comments should not be left empty"]}
                </strong>
              </font>
            </div>
          ) : (
            ""
          )}
          <ButtonToolbar className="margin-t-15">
            <Button
              bsSize="small"
              variant="primary"
              disabled={this.state.disabledSave}
              onClick={this.postActivityData.bind(this)}
            >
              {this.props.tr["Post"]}
            </Button>
          </ButtonToolbar>
        </div>
      );
    } else {
      showTestAreaForComments = <div></div>;
    }

    let showTextAreaForReorderComments = "";
    showTextAreaForReorderComments = (
      <div className="position-re">
        <Form.Label bsClass="">
          <span className="rStar"></span>
          {this.props.tr["Reorder Comments"]}{" "}
        </Form.Label>
        <Form.Control
          as="textarea"
          rows="2"
          inputRef={(input) => (this.inputComments = input)}
          onChange={this.reorderComments.bind(this)}
          spellCheck="true"
        />
        {this.state.commentsAreEmpty ? (
          <div className="error">
            <font color="red">
              <strong>
                {this.props.tr["Comments should not be left empty"]}
              </strong>
            </font>
          </div>
        ) : (
          ""
        )}
        <ButtonToolbar className="margin-t-15">
          <Button
            bsSize="small"
            variant="primary"
            disabled={this.state.disabledSave}
            onClick={this.postReorderRequest.bind(this)}
          >
            {this.props.tr["Submit"]}
          </Button>
        </ButtonToolbar>
      </div>
    );

    let showSoringDescArrow = "";
    if (
      this.state.sortingArrow == 0 &&
      this.props.breakFixActivityLogDetails.length > 0
    ) {
      showSoringDescArrow = (
        <a
          href="javascript:void(0)"
          onClick={this.descactivitysort.bind(this)}
          className="black"
          title={this.props.tr["Descending"]}
        >
          <i className="fa fa-angle-down"></i>
        </a>
      );
    }

    let showSoringAscArrow = "";
    if (this.state.sortingArrow == 1) {
      showSoringAscArrow = (
        <a
          href="javascript:void(0)"
          onClick={this.ascactivitysort.bind(this)}
          className="black"
          title={this.props.tr["Ascending"]}
        >
          <i className="fa fa-angle-up"></i>
        </a>
      );
    }

    let delTime = this.props.myOrderOrderDetails[0].deliveryVal;

    let finalValue = formatMinutesIntoDaysHoursMinutes(delTime);

    let itemStatus = this.props.orderStatus;
    if (itemStatus == "Fullfiled") itemStatus = "Fulfilled";
    return (
      <div aria-label="Right Section" role="contentinfo">
        <Row>
          <Col xs={12}>
            <div className="rPageHeadActBtn">
              <ul>
                <li>
                <Link to="javascript:void(0)" className="closeRsBtn" id="closeButton"
                    title={this.props.tr["Minimize the details panel"]}
                    onClick={() => {
                      this.props.showRightPanelFun(false);
                    }}
                    ref={e => this.closeButton = e}
                  >
                    <IoClose/>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="rPageHeading">
              {this.props.myOrderOrderDetails[0].orderNumber +
                "-" +
                this.props.myOrderOrderDetails[0].itemNumber}
              <div className="offNam" title={this.props.ciServiceName}>
                {displayName.slice(
                  0,
                  getInnerWidth(this.props.windowSize.width)
                )}
                {displayName.length > getInnerWidth(this.props.windowSize.width)
                  ? "..."
                  : ""}
              </div>
            </div>
          </Col>
        </Row>

        <div className="rBoxGap">
          <div className="rBoxTabs hideTabNav">
            <Tabs
              activeKey={this.state.tabKey}
              onSelect={this.handleSelect}
              id="tabId"
            >
              <Tab
                tabindex="0"
                eventKey={1}
                title={this.props.tr["Request Details"]}
              >
                <ListGroup bsPrefix=" " bsClass="">
                  <ListGroup.Item
                    bsPrefix=" "
                    bsClass=""
                    className="rwTable"
                    style={{ "border-top": "none" }}
                  >
                    <span className="rwKey">
                      {this.props.serviceBased == true
                        ? this.props.tr["Service Name"]
                        : this.props.tr["Impacted CI"]}
                    </span>
                    <span
                      className="rwVal"
                      style={{
                        whiteSpace: "pre-wrap",
                        overflowWrap: "anywhere",
                      }}
                      title={this.props.ciServiceName}
                    >
                      {displayName.slice(
                        0,
                        getInnerWidth(this.props.windowSize.width)
                      )}
                      {displayName.length >
                      getInnerWidth(this.props.windowSize.width)
                        ? "..."
                        : ""}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">
                      {this.props.tr["Requested For"]}
                    </span>
                    <span className="rwVal">
                      {this.props.myOrderOrderDetails[0].requesterName}
                    </span>
                  </ListGroup.Item>
                  {this.props.myOrderOrderDetails[0].quantity &&
                  this.props.myOrderOrderDetails[0].quantity > 0 ? (
                    <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                      <span className="rwKey">{this.props.tr["Quantity"]}</span>
                      <span className="rwVal">
                        {this.props.myOrderOrderDetails[0].quantity}
                      </span>
                    </ListGroup.Item>
                  ) : null}
                  {this.props.myOrderOrderDetails[0].deliveryVal == 0 ? null : (
                    <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                      <span className="rwKey">
                        {this.props.tr["Delivery Type"]}
                      </span>
                      <span className="rwVal">{finalValue}</span>
                    </ListGroup.Item>
                  )}
                  {this.props.myOrderOrderDetails[0].currency != null &&
                  this.props.myOrderOrderDetails[0].itemPrice > 0 &&
                  this.props.myOrderOrderDetails[0].isPriceEnabled ? (
                    <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                      <span className="rwKey">
                        {this.props.tr["Service Value"]}
                      </span>
                      <span className="rwVal">
                        {this.props.myOrderOrderDetails[0].currency}{" "}
                        {this.props.myOrderOrderDetails[0].itemPrice}
                      </span>
                    </ListGroup.Item>
                  ) : (
                    <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                      <span className="rwKey">
                        {this.props.tr["Service Value"]}
                      </span>
                      <span className="rwVal">{this.props.tr["NA"]}</span>
                    </ListGroup.Item>
                  )}
                  {this.props.myOrderOrderDetails[0].deliveryVal == 0 ? null : (
                    <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                      <span className="rwKey">
                        {this.props.tr["Expected Delivery Date"]}
                      </span>
                      <span className="rwVal">
                        {this.props.myOrderOrderDetails[0].expdeliveryDate}
                      </span>
                    </ListGroup.Item>
                  )}
                  <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                    <span className="rwKey">{this.props.tr["Status"]}</span>
                    <span className="rwVal">{this.props.tr[itemStatus]}</span>
                  </ListGroup.Item>
                  {this.props.myOrderOrderDetails[0].holdingReasonCode ==
                  "110" ? (
                    <ListGroup.Item
                      bsPrefix=" "
                      bsClass=""
                      className="rwTable"
                      style={{ "border-bottom": "1px solid #b3d4e6" }}
                    >
                      <span className="rwKey">
                        {this.props.tr["Reason for Hold"]}
                      </span>
                      <span className="rwVal">
                        {this.props.myOrderOrderDetails[0].holdingReasonValue}
                      </span>
                    </ListGroup.Item>
                  ) : (
                    <ListGroup.Item
                      bsPrefix=" "
                      bsClass=""
                      className="rwTable"
                      style={{ "border-bottom": "1px solid #b3d4e6" }}
                    >
                      <span className="rwKey">
                        {this.props.tr["Order Type"]}
                      </span>
                      <span className="rwVal">{orderType}</span>
                    </ListGroup.Item>
                  )}
                </ListGroup>

                <Accordion
                  id="rightSidePanel6"
                  accordion
                  className="brkFix brkFixOrder margin-t-25"
                >
                   <Accordion.Item eventKey="1" className="collapsed">
                    <Accordion.Header
                      onClick={() => {
                        this.loadRelatedTaskData(
                          this.props.itemId,
                          this.props.module,
                          this.props.myOrderOrderDetails[0].companyId
                        );
                      }}
                    >
                      <h4 toggle>{this.props.tr["Task Progress"]}<PieChart taskCount={this.state.taskCount} /></h4>
                    </Accordion.Header>
                    <Accordion.Body collapsible>
                      {this.props.showLoaderRelatedTask ? (
                        <div>
                          <ListLoader />
                        </div>
                      ) : this.props.breakFixTaskLogDetails.length == 0 ? (
                        <div>{this.props.tr["Data not available"]}</div>
                      ) : (
                        <Table
                          responsive
                          striped
                          bordered
                          condensed
                          hover
                          className="f-size-13"
                        >
                          <thead>
                            <tr>
                              <th>{this.props.tr["Task Code"]}</th>
                              <th>{this.props.tr["Status"]}</th>
                              <th>{this.props.tr["Fulfiller"]}</th>
                              <th>{this.props.tr["Expected Completion"]}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.renderAttList(
                              this.props.breakFixTaskLogDetails
                            )}
                          </tbody>
                        </Table>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <h4 toggle>{this.props.tr["Requestor Information"]}</h4>
                    </Accordion.Header>
                    <Accordion.Body collapsible>
                      {this.props.showLoader_6 ? (
                        <ListLoader />
                      ) : (
                        this.renderAdditionalDetails(this.props.profileDetails)
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3" className="collapsed">
                    <Accordion.Header
                      onClick={() => {
                        this.checkRenderApproverData(
                          this.props.myOrderOrderDetails[0].offeringIdSx,
                          this.props.module,
                          "0",
                          "0",
                          this.props.myOrderOrderDetails[0].itemId
                        );
                      }}
                    >
                      <h4 toggle>{this.props.tr["Approval"]}</h4>
                    </Accordion.Header>
                    <Accordion.Body collapsible>
                      {this.state.showApprovalLoader ? (
                        <div>
                          <ListLoader />
                        </div>
                      ) : this.state.displayMessage != "" ? (
                        this.state.displayMessage
                      ) : this.props.rightMenuShowLoader ? (
                        <div>
                          <ListLoader />
                        </div>
                      ) : this.props.changeTotalApprovalDetails.length > 0 ? (
                        <Table responsive className="f-size-13 table table-striped table-bordered table-condensed table-hover margin-b-0">
                          <thead>
                            <tr>
                              <th width="10%"></th>
                              <th width="20%">{this.props.tr["Level"]}</th>
                              <th width="42%">{this.props.tr["Approver"]}</th>
                              <th width="28%">
                                {this.props.tr["Date & Time"]}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.renderAppHistoryDataLog(
                              this.props.changeTotalApprovalDetails
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <div>
                          {
                            this.props.tr[
                            "Approval is being triggered. Please try later!"
                            ]
                          }
                        </div>
                      )}
                    </Accordion.Body>

                  </Accordion.Item>
                  <Accordion.Item eventKey="4" className="collapsed">
                    <Accordion.Header>
                      <h4 toggle>{this.props.tr["Item Components"]}</h4>
                    </Accordion.Header>
                    <Accordion.Body collapsible>
                      <ListGroup bsPrefix=" " bsClass="">
                        {this.renderItemComponents(
                          this.props.myOrderOrderDetails[0]
                        )}
                      </ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>

                 

                  {this.props.myOrderQuestionDetails.length != 0 ? (
                    this.props.myOrderQuestionDetails[0].categoryId != null ? (
                      <Accordion.Item eventKey="5" className="collapsed">
                        <Accordion.Header>
                          <h4 toggle>
                            {this.props.tr["Information Provided"]}
                          </h4>
                        </Accordion.Header>
                        <Accordion.Body collapsible>
                          {this.renderQuestionCategoryWise(
                            this.props.myOrderQuestionDetails
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    ) : null
                  ) : null}

                  {this.props.myOrderOrderDetails[0].recipientName != null &&
                  this.props.myOrderOrderDetails[0].recipientName !=
                    undefined &&
                  this.props.myOrderOrderDetails[0].recipientName != "" ? (
                    <Accordion.Item eventKey="6" className="collapsed">
                      <Accordion.Header>
                        <h4 toggle>{this.props.tr["Ordering Information"]}</h4>
                      </Accordion.Header>
                      <Accordion.Body collapsible>
                        {this.renderOrderingInformation(
                          this.props.myOrderOrderDetails[0]
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : null}

                  {this.props.orderStatus != "Draft" ? (
                    <Accordion.Item eventKey="7" className="collapsed">
                      <Accordion.Header
                        onClick={() => {
                          this.getAttachment(this.props.itemId, "Item");
                        }}
                      >
                        <h4 toggle>{this.props.tr["Attachments"]}</h4>
                      </Accordion.Header>
                      <Accordion.Body collapsible>
                        <Table
                          responsive
                          striped
                          bordered
                          condensed
                          hover
                          className="f-size-12 wrpAttTab"
                        >
                          <thead>
                            <tr>
                              <th width="55%">
                                {this.props.tr["Name & Linked By"]}
                              </th>
                              <th width="25%">
                                {this.props.tr["Date & Time"]}
                              </th>
                              <th width="20%" className="text-c">
                                {this.props.tr["Action"]}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.renderAttachedItems(
                              this.props.implememtationPlanList
                            )}
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : (
                    ""
                  )}

                <Accordion.Item eventKey="8" className="collapsed">
                    <Accordion.Header
                      onClick={() => {
                        this.setState({
                          loadWatcherDetails: true,
                        });
                      }}
                    >
                      {this.props.tr["Watcher Details"]}
                    </Accordion.Header>
                    <Accordion.Body>
                      {this.state.loadWatcherDetails ? (
                        <WatcherDetails loadFrom="myRequest" itemId={this.props.itemId} module={this.props.module==="Item"?"Fullfilment":''} status={this.props.orderStatus} />
                      ) : null}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <div
                  aria-label="Button Toolbar"
                  role="contentinfo"
                  className="margin-t-15"
                >
                  <ButtonToolbar>
                    {showActivity}
                    {/* {this.props.orderStatus != "Draft" &&
                    this.props.orderStatus != "Closed" ? ( */}
                    {(this.props.orderStatus == "Fixed" ||
                    this.props.orderStatus == "Draft"||
                    this.props.orderStatus == "Closed" ||
                    this.props.orderStatus == "Cancelled" ||
                    this.props.orderStatus == "Fulfilled" ||
                    this.props.orderStatus == "Rejected" )? (
                      ""
                    ) : (                   
                      <Button
                        className={this.state.showAttachWithoutComments ? "rgSidrkBtn": "rgSiLigBtn"}
                        bsSize="small"
                        title={this.props.tr["Attach Files"]}
                        variant="primary"
                        onClick={() => {
                          this.setState({
                            showAttachWithoutComments:
                              !this.state.showAttachWithoutComments,
                            activityLogHideShow: 0,
                            viewUpdates: 0,
                            cancelHideShow: 0,
                            reorderHideShow: 0,
                            disabledSave: false
                          });
                        }}
                      >
                        {this.props.tr["Attach Files"]}                        
                      </Button>
                    )}
                    <Button
                      className={this.state.viewUpdates ? "rgSidrkBtn": "rgSiLigBtn"}
                      bsSize="small"
                      variant="primary"
                      title={this.props.tr["View Updates"]}
                      onClick={() => {
                        this.viewUpdateShow();
                        this.setState({ showAttachWithoutComments: false });
                      }}
                    >
                      {this.props.tr["View Updates"]}
                    </Button>
                    {cancelOrder}
                    {reOrder}
                  </ButtonToolbar>
                </div>

                {this.state.showAttachWithoutComments ? (
                  <div className="margin-t-20">
                    <AttachmentTab
                      translator={this.props.tr}
                      itemId={this.state.itemId}
                      isUloadingDisabled={this.state.isUloadingDisabled}
                      page = {"myRequest"}
                    />
                    <Button
                      className="margin-t-20"
                      bsSize="small"
                      variant="primary"
                      disabled={this.state.isUloadingDisabled}
                     // disabled={this.state.disabledSave}
                      onClick={this.postAttachmentWithoutComments}
                    >
                      {this.state.disabledSave == true ? (
                        <span>
                          <ImSpinner6 className="icn-spinner"/>{" "}
                        </span>
                      ) : (
                        ""
                      )}
                      {this.props.tr["Attach"]}
                    </Button>
                  </div>
                ) : (
                  ""
                )}

                {this.state.activityLogHideShow == 1 ? (
                  <div className="margin-t-20 blueAnq">
                    {showTestAreaForComments}
                  </div>
                ) : null}

                {this.state.reorderHideShow == 1 ? (
                  <div className="margin-t-20 blueAnq">
                    {showTextAreaForReorderComments}
                  </div>
                ) : null}

                {this.state.cancelHideShow == 1 ? (
                  <div className="margin-t-20 blueAnq">
                    <Form.Group className="form-group">
                        <Form.Label bsClass="">
                          <span className="rStar"></span>
                          {this.props.tr["Cancellation Type"]}{" "}
                        </Form.Label>
                        <Form.Select required="true" componentClass="select" onChange={this.setCancellationType} className={this.state.canErrorCode}>
                            <option value="">Select</option>
                            {this.renderCancellationType(this.props.loadReasons)}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label bsClass="">
                        <span className="rStar"></span>
                        {this.props.tr["Cancellation Reason"]}{" "}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        maxLength="500"
                        rows="2"
                        className={this.state.issueValidationError}
                        inputRef={(input) => (this.inputComments = input)}
                        onChange={this.getIssue.bind(this)}
                        spellCheck="true"
                      />
                    </Form.Group>

                    {this.state.savedMessage == "" ||
                    this.state.issueValidationError == "error" ? (
                      <ButtonToolbar className="margin-t-15">
                        <Button
                          variant="primary"
                          bsSize="small"
                          disabled={this.state.disabledSave}
                          onClick={() => {
                            this.postCancellationRequest();
                          }}
                        >
                          {
                              this.state.disabledSave
                                ? <ImSpinner6 className="icn-spinner"/>
                                : ""
                            }
                          {" "}
                          {this.props.tr["Submit"]}
                        </Button>
                      </ButtonToolbar>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <font color="green">
                      <b>{this.state.savedMessage}</b>
                    </font>
                  </div>
                )}

                {this.state.viewUpdates == 1 ? (
                  <div className="margin-t-15 padding-b-5">
                    <span className="text-c float-r f-size-17">
                      {showSoringAscArrow}
                      {showSoringDescArrow}
                      <span className="float-r margin-r-10"></span>
                    </span>
                    {this.renderActivityLog(
                      this.props.breakFixActivityLogDetails
                    )}
                  </div>
                ) : null}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}
MyRequestsShortDesc.propTypes = {
  approverListDetails: PropTypes.object,
  orderStatus: PropTypes.string,
  loadImplPlanList: PropTypes.func,
  loadBreakFixActivityLogDetails: PropTypes.func,
  tr: PropTypes.object,
  module: PropTypes.string,
  loadOrdersList: PropTypes.func,
  resetSortingArrows: PropTypes.func,
  loadChangeTotalApprovalDetails: PropTypes.func,
  showActiveRow: PropTypes.func,
  loadBreakFixTaskLogDetails: PropTypes.func,
  listFilter: PropTypes.string,
  showLoader: PropTypes.bool,
  showLoader_1: PropTypes.bool,
  showLoader3: PropTypes.bool,
  showLoader4: PropTypes.bool,
  showLoader_5: PropTypes.bool,
  showLoader_6: PropTypes.bool,
  showApprovalLoader: PropTypes.bool,
  moduleId: PropTypes.number,
  showRightPanelFun: PropTypes.func,
};

export function mapStateToProps({
  breakFixActivityLogDetails,
  ordersList,
  myOrderOrderDetails,
  approverListDetails,
  changeTotalApprovalDetails,
  changeTotalApprovalHistoryDetails,
  implememtationPlanList,
  myOrderQuestionDetails,
  showLoader,
  showLoader_1,
  showLoader2,
  showLoader3,
  showLoader4,
  showLoader_5,
  showLoader_6,
  rightMenuShowLoader,
  serviceReviewShowLoader,
  profileDetails,
  breakFixTaskLogDetails,
  showLoaderRelatedTask,
  windowSize,
  loadReasons,
  taskCountByItemId
}) {
  let data = ordersList.data;
  if (data == undefined) data = [];
  return {
    breakFixActivityLogDetails,
    ordersList: data,
    myOrderOrderDetails,
    approverListDetails,
    changeTotalApprovalDetails,
    changeTotalApprovalHistoryDetails,
    implememtationPlanList,
    myOrderQuestionDetails,
    showLoader: showLoader.loading,
    showLoader_1: showLoader_1.loading,
    showLoader2: showLoader2.loading,
    showLoader3: showLoader3.loading,
    showLoader4: showLoader4.loading,
    rightMenuShowLoader: rightMenuShowLoader.loading,
    showLoader_5: showLoader_5.loading,
    showLoader_6: showLoader_6.loading,
    serviceReviewShowLoader: serviceReviewShowLoader.loading,
    profileDetails: profileDetails,
    breakFixTaskLogDetails,
    showLoaderRelatedTask: showLoaderRelatedTask.loading,
    windowSize,
    loadReasons,
    taskCountByItemId
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadBreakFixActivityLogDetails,
      loadOrdersList,
      loadApproverList,
      loadImplPlanList,
      loadChangeTotalApprovalDetails,
      loadChangeTotalApprovalHistoryDetails,
      resetSortingArrows,
      loadBreakFixTaskLogDetails,
      loadFilterOrderList,
      getTaskCountForPieChart
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyRequestsShortDesc);
