
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change, SubmissionError } from 'redux-form';

import { loadTagCompany, loadBusinessFunction, emptyBusinessFunction, loadSXMenulistData, loadAllCompositeTags, loadMemberTagsForCompositeTag } from '_Actions/foundation/tags/tagsAction';
import TagsDropdownPlusTypeahead from '../common/TagsDropdownPlusTypeahead.js';
import { _passwordField, _inputField, _dropDown, _checkbox, _number, TypeaheadandDropdown } from '../../../common/formFields';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { getCookiesValuesByPositions } from "_Commons/helper.js";

const validate = (values) => {
    const errors = {};
    if (!values.company)
        errors.company = "Select company";
    if (!values.module)
        errors.module = "Select module";
    if (!values.businessFunctionId)
        errors.businessFunctionId = "Select business function";
    if (!values.label || values.label.trim() == '')
        errors.label = "Select label";
    if (!values.tagType)
        errors.tagType = "Select type";
    if (!values.tagDataValidation)
        errors.tagDataValidation = "Select data validation";
    if (!values.compositeTag)
        errors.compositeTag = "Select composite attribute";
    // if (!values.parentTag)
    //     errors.parentTag = "Select parent attribute";
    if (!values.tagEnabled)
        errors.tagEnabled = "Select enabled";
    // if (!values.tagDataSource)
    //     errors.tagDataSource = "Select data source";
    if (!values.character_limit || (values.character_limit && (values.character_limit < 1 || values.character_limit > 500)))
        errors.character_limit = "Please enter a number between 1 to 500";
    return errors;
}

const FormComponent = (props) => {

    const dispatch = useDispatch();
    const translator = useSelector((state) => state.spcmReducer.tr);
    const foundation_tags_reducer = useSelector((state) => state.foundation_tags_reducer);
    const [companySelectedList, setCompanySelectedList] = useState([]);
    const [inputValueCompany, setInputValueCompany] = useState('');
    let tagTypeValues = useSelector((state) => state.foundation_tags_reducer.tagType);
    tagTypeValues = tagTypeValues.filter((item) => item.field1Value != 'Member');

    const selector = formValueSelector('foundationTagsCreateForm');
    const tagTypeParam = useSelector(state => selector(state, 'tagType'));
    const tagDataValidationParam = useSelector(state => selector(state, 'tagDataValidation'));
    const companyIdParam = useSelector(state => selector(state, 'company'));
    const businessFunctionNameParam = useSelector(state => selector(state, 'businessFunctionName'));
    const moduleParam = useSelector(state => selector(state, 'module'));
    const { value1: langDir } = getCookiesValuesByPositions([66]);

    useEffect(() => {
        dispatch(loadTagCompany());
        dispatch(loadSXMenulistData('Tag', 'Module'));
        dispatch(loadSXMenulistData('Tag', 'Type'));
        dispatch(loadSXMenulistData('Tag', 'DataType'));
        // dispatch(loadSXMenulistData('Tag', 'DataSource'));
    }, []);

    function onCompanySelection(selectedCompany) {
        props.setCompanyErrorState('');
        setCompanySelectedList(selectedCompany);
        dispatch(change('foundationTagsCreateForm', 'module', ''));
        dispatch(change('foundationTagsCreateForm', 'businessFunctionId', ''));
        dispatch(change('foundationTagsCreateForm', 'businessFunctionName', ''));
        dispatch(change('foundationTagsCreateForm', 'tagType', ''));
        dispatch(change('foundationTagsCreateForm', 'compositeTag', ''));
        dispatch(emptyBusinessFunction());
        if (selectedCompany.length > 0) {
            dispatch(loadBusinessFunction(selectedCompany[0].id));
            dispatch(change('foundationTagsCreateForm', 'company', selectedCompany[0].id));
            dispatch(change("foundationTagsCreateForm", "companyName", selectedCompany[0].label));
            setInputValueCompany(selectedCompany[0].label);
        }
        else {
            dispatch(change('foundationTagsCreateForm', 'company', ''));
            dispatch(change("foundationTagsCreateForm", "companyName", ''));
        }
    }

    function setCompanyErrorColor() {
        if (companySelectedList.length === 0)
            props.setCompanyErrorState('error');
        else
            props.setCompanyErrorState('');
    }

    function onCompanyInputChange(val) {
        setInputValueCompany(val);
    }

    function onCrossClickCompany() {
        dispatch(change('foundationTagsCreateForm', 'company', ''));
        dispatch(change("foundationTagsCreateForm", "companyName", ''));
        setInputValueCompany('');
        setCompanySelectedList([]);
        dispatch(change('foundationTagsCreateForm', 'module', ''));
        dispatch(change('foundationTagsCreateForm', 'businessFunctionId', ''));
        dispatch(change('foundationTagsCreateForm', 'businessFunctionName', ''));
        dispatch(change('foundationTagsCreateForm', 'tagType', ''));
        dispatch(change('foundationTagsCreateForm', 'compositeTag', ''));
        dispatch(emptyBusinessFunction());
        props.setCompanyErrorState('error');
    }

    let companyTypeaheadOptions = [];
    if (foundation_tags_reducer.tagCompany && foundation_tags_reducer.tagCompany.length > 0) {
        foundation_tags_reducer.tagCompany.forEach((item, i) => {
            let obj = {};
            obj.id = item.field1Value;
            obj.label = item.field1Key;
            companyTypeaheadOptions.push(obj);
        });
    }

    function onModuleChange(e) {
        dispatch(change('foundationTagsCreateForm', 'tagType', ''));
        dispatch(change('foundationTagsCreateForm', 'compositeTag', ''));
        // if (e.target.value == 'CMDB') {
        dispatch(change('foundationTagsCreateForm', 'businessFunctionId', 0));
        dispatch(change('foundationTagsCreateForm', 'businessFunctionName', 'Default'));
        // }
        // else {
        //     dispatch(change('foundationTagsCreateForm', 'businessFunctionId', ''));
        //     dispatch(change('foundationTagsCreateForm', 'businessFunctionName', ''));
        // }
    }

    const tooltip = (
        <Popover id="popover-positioned-right" className="padding-10" title="Popover right">
        <strong>Simple:</strong> {translator['Simple Tooltip']} <br />
        <strong>Composite:</strong>{translator['Simple Tooltip2']} <br />
        <strong>Checklist:</strong> {translator['Checklist Tooltip2']}
        </Popover>
    );

    return (
        <>
            <Row>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label bsClass=""><span className="rStar"></span>{translator['Company']}</Form.Label>
                        <div className="minHghtdvTypehd">
                        <Field component={TypeaheadandDropdown}
                            errorColor={props.companyErrorState}
                            onSelection={onCompanySelection}
                            setErrorColor={setCompanyErrorColor}
                            name="company"
                            id="company"
                            className="form-control"
                            options={companyTypeaheadOptions}
                            selectedOptions={companySelectedList}
                            onInputChange={onCompanyInputChange}
                            inputValue={inputValueCompany}
                            onCrossClick={onCrossClickCompany}
                        />
                        </div>
                    </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label><span className="rStar"></span>{translator['Module']}</Form.Label>
                        <Field component={_dropDown} name="module" options={foundation_tags_reducer.tagModule} onChange={onModuleChange} className="form-control" />
                    </Form.Group>
                </Col>
                {/* {moduleParam != 'CMDB' ?
                    <Col md={6} sm={6} xs={12}>
                        <Form.Group className="form-group">
                            <Form.Label><span className="rStar"></span>{translator['Business Function']}</Form.Label>
                            <Field component={_dropDown} name="businessFunctionId" onChange={(e) => { dispatch(change('foundationTagsCreateForm', 'businessFunctionName', e.target[e.target.selectedIndex].text)); dispatch(change('foundationTagsCreateForm', 'tagType', '')); dispatch(change('foundationTagsCreateForm', 'compositeTag', '')); }} options={foundation_tags_reducer.businessFunction} className="form-control" />
                        </Form.Group>
                    </Col>
                    : ''} */}
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label><span className="rStar"></span>{translator['Type']}</Form.Label>
                        <OverlayTrigger trigger={['hover', 'focus']} rootClose placement={langDir === "rtl" ? "left" : "right"} overlay={tooltip}>
                            <span><AiOutlineQuestionCircle className="questionSvg" style={{ fontSize: "18px" }}/></span>
                        </OverlayTrigger>
                        <Field component={_dropDown} name="tagType" options={tagTypeValues} onChange={(e) => { if (e.target.value == 'Member') { dispatch(loadAllCompositeTags(companyIdParam, businessFunctionNameParam, moduleParam, 'Composite')); } }} className="form-control" />
                    </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label><span className="rStar"></span>{translator['Enabled']}</Form.Label>
                        <Field component={_dropDown} name="tagEnabled" options={[{ field1Key: 'True', field1Value: true }, { field1Key: 'False', field1Value: false }]} className="form-control" />
                    </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label><span className="rStar"></span>{translator['Label']}</Form.Label>
                        <Field name="label" component={_inputField} maxLength="50" className="form-control" />
                    </Form.Group>
                </Col>
                {/* {tagTypeParam && tagTypeParam == 'Member' ?
                    <>
                        <Col md={6} sm={6} xs={12}>
                            <Form.Group className="form-group">
                                <Form.Label><span className="rStar"></span>{translator['Composite Attribute']}</Form.Label>
                                <Field component={_dropDown} name="compositeTag" options={foundation_tags_reducer.allCompositeTags} onChange={(e) => { dispatch(loadMemberTagsForCompositeTag(e.target.value)); }} className="form-control" />
                            </Form.Group>
                        </Col>
                        <Col md={6} sm={6} xs={12}>
                            <Form.Group className="form-group">
                                <Form.Label>{translator['Dependent on']}</Form.Label>
                                <Field component={_dropDown} name="parentTag" options={foundation_tags_reducer.memberTagsForComposite} className="form-control" />
                            </Form.Group>
                        </Col>
                    </>
                    : ''} */}
                {tagTypeParam != 'Composite' && tagTypeParam != 'Checklist' ?
                    <Col md={6} sm={6} xs={12}>
                        <Form.Group className="form-group">
                            <Form.Label><span className="rStar"></span>{translator['Data validation']}</Form.Label>
                            <Field component={_dropDown} name="tagDataValidation" onChange={(e) => { dispatch(change('foundationTagsCreateForm', 'character_limit', '')); }} options={foundation_tags_reducer.tagDataValidation} className="form-control" />
                        </Form.Group>
                    </Col>
                    : ''}
                {/* <Col md={6} sm={6} xs={12}>
                    <Form.Group className="form-group">
                        <Form.Label><span className="rStar"></span>{translator['Data Source']}</Form.Label>
                        <Field component={_dropDown} name="tagDataSource" options={foundation_tags_reducer.tagDataSource} className="form-control" />
                    </Form.Group>
                </Col> */}
                {tagDataValidationParam && tagDataValidationParam == 'Text' && tagTypeParam && tagTypeParam == 'Simple' ?
                    <>
                        <Col md={6} sm={6} xs={12}>
                            <Form.Group className="form-group">
                                <Form.Label><span className="rStar"></span>{translator['Character limit']}</Form.Label>
                                <Field component={_number} name="character_limit" className="form-control" min={1} max={500} />
                            </Form.Group>
                        </Col>
                    </>
                    : ''}
            </Row>
        </>
    )
}

export default reduxForm({
    form: 'foundationTagsCreateForm',
    destroyOnUnmount: true,
    validate,
    enableReinitialize: true
})(FormComponent);
