
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";

import PindCategories from "../common/header/pind-categories";
import Breadcrumbs from "../common/header/breadcrumbs";
import { getCookiesValuesByPositions } from "../common/helper";
import Header from "./header";
import {
  useGetActiveQuadrantsIdQuery,
  useSubmitSuppliersScoreCardMutation,
} from "../../reducers/scorecard";
import { useContractVendorUrlQuery, useGetSuppliersQuery } from "../../reducers/governance/meeting/meeting";
import { useGetAllU2CCompanyQuery } from "../../reducers/foundation/forum/forumQuery";
import ListLoader from "../common/loaders/ListLoader";
import ScoreCardView from "./scoreCardView";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { useSelector } from "react-redux";
import jsPDF from 'jspdf';
import { applyPlugin } from 'jspdf-autotable'
import 'jspdf-autotable';
import "_Css/form/_form.scss";
applyPlugin(jsPDF)

const ScoreCard = () => {
  const cookieValues = getCookiesValuesByPositions([2, 48, 59]);
  const dateformat = cookieValues?.value1;
  const userCompanyId = cookieValues?.value2;
  const userCompanyName = cookieValues?.value3;

  const [supplierData, setSupplierData] = useState([]);
  const [monthsData, setMonthsData] = useState(null);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState([]);
  const downloadData = useSelector(state => state.downloadData);
  const tr = useSelector((state) => state.spcmReducer.tr);

  const [submitScoreCard, { isLoading: submitScoreCardLoading }] =
    useSubmitSuppliersScoreCardMutation();

  const {
    data: allQuardants,
    isFetching: isQuardantsFetching,
    refetch: refetchQuardantsId,
  } = useGetActiveQuadrantsIdQuery(
    {
      queryString: `?tenantId=${selectedCompanyId?.[0]?.id}`,
    },
    {
      skip: !selectedCompanyId?.[0]?.id,
    }
  );

  const { data: allCompany, isFetching: companyFetching } =
  useGetAllU2CCompanyQuery();

  const { data: allSuppliers, isFetching: isSupliersLoading } =
  useContractVendorUrlQuery(
      {
        queryString: `/${selectedCompanyId?.[0]?.id}`,
      },
      {
        skip: !selectedCompanyId?.[0]?.id,
      }
    );

  const handleSubmit = async (compID) => {
    setIsBusy(true);
    const promises = allQuardants?.map((quadrant) =>
      submitScoreCard({
        queryString: `?quadrantId=${quadrant?.quadrantId}&tenantId=${selectedCompanyId?.[0]?.id}`,
      })
    );

    try {
      const resp = await Promise.all(promises);
      setIsBusy(false);
      console.log(resp);
      // if (resp?.[0].type === "scorecard/submitSuppliersScorecard/fulfilled") {
      //   setAlert({
      //     ...alert,
      //     msg: resp?.[0]?.payload?.message || "Records Submitted Successfully",
      //     open: true,
      //   });
      // }
    } catch (error) {
      console.error("Error fetching quadrant data:", error);
    } finally {
      setIsBusy(false);
    }
  };

  useEffect(() => {
    if (!isSupliersLoading && Array.isArray(allSuppliers)) {
      const updatedSupplierData = allSuppliers
        .filter((data) => data?.field1Key)
        .map((data) => ({
          supplierName: data.field1Key,
          supplierId: data.field1Value || null,
        }));

      setSupplierData(updatedSupplierData);
    }
  }, [allSuppliers, isSupliersLoading]);

  useEffect(() => {
    const companyData = allCompany?.message
      ? []
      : allCompany?.length !== 0 &&
        allCompany?.map((item) => ({
          label: item?.field1Key,
          id: item?.field1Value,
        }));
    setSelectedCompanyId([
      {
        id: companyData && companyData[0] ? companyData[0].id : "",
        label: companyData && companyData[0] ? companyData[0].label : "",
      },
    ]);
    setCompanyOptions(companyData || []);
  }, [allCompany]);

  const generateExcelFile = () => {
    if(!downloadData[selectedCompanyId?.[0]?.id]){
      swal({
        title: tr["Failed to Download File"],
      });  
      return;  
    }
    const fileName=`${selectedCompanyId[0].label} Scorecard Report.xlsx`;
    const otherColumnWidths = [
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
    let maxContentLength = 0;
    let i = 0;
    const columns = 10;
    const columnConfigrations = [];
    let data = [['Consolidated View']]; 

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const previousMonth = currentMonth - 1;
    const earlyMonth = currentMonth -2;

    const currentMonthName = new Date(currentYear,currentMonth).toLocaleString("default", { month: "long" });
    const previousMonthName = new Date(currentYear,previousMonth).toLocaleString("default", { month: "long" });
    const earlyMonthName = new Date(currentYear,earlyMonth).toLocaleString("default", { month: "long" });
    
    try{  
    allQuardants.map(quadrantData => {
      const quadrantID = quadrantData.quadrantId;
      const quadrantName = quadrantData.quadrantName;
      data.push([quadrantName]);
      const quadrantDetails = downloadData[selectedCompanyId?.[0]?.id][quadrantID][0];
      const headers = quadrantDetails.supplierMeasuresDetails.headings;
      const userEntries = quadrantDetails.supplierMeasuresDetails.supplierMeasure;
      supplierData.map((supplierDetails,itr) => {
        const companyID = supplierDetails.supplierId;
        const companyName = supplierDetails.supplierName;
        maxContentLength = companyName.length > maxContentLength ? companyName.length : maxContentLength;
        data.push([companyName,'','','',earlyMonthName+' '+currentYear,,previousMonthName+' '+currentYear,,currentMonthName+' '+currentYear,,'']);
        columnConfigrations.push({s: {c: 4, r: data.length-1}, e: {c: 5, r: data.length-1}});
        columnConfigrations.push({s: {c: 6, r: data.length-1}, e: {c: 7, r: data.length-1}});
        columnConfigrations.push({s: {c: 8, r: data.length-1}, e: {c: 9, r: data.length-1}});
        data.push([headers.heading1,headers.heading2+' (30%)',headers.heading3,headers.heading4,'Achieved %','Achieved Score','Achieved %','Achieved Score','Achieved %','Achieved Score']);
        for (i = 0; i < columns; i++) {
          columnConfigrations.push({ s: { c: i, r: data.length - 1 }, e: { c: i, r: data.length } });
        }
        data.push(['','','','','','','','','','']);


        userEntries.map(item => {
          const userData = item.supplierScorecardDetails;
          const intermediateArray = [item.measureName,item.weightage,item.score,item.target];
          if(typeof userData != 'undefined'){
            item.supplierScorecardDetails.map(details => {
              if(details.supplierId == companyID){
                intermediateArray.push(details.draftAchievedPercentage == 0 ? '' : details.draftAchievedPercentage);
                intermediateArray.push(details.draftScore == 0 ? '' : details.draftScore);
              }
            });
          }
          data.push(intermediateArray);
        });
        data.push([]);
      });
    });
    const columnWidths = [{ width: maxContentLength + 5 }, ...otherColumnWidths]; 
    const workbook = XLSX.utils.book_new();  
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    worksheet['!cols'] = columnWidths;
    worksheet['!merges'] = columnConfigrations;
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Scorecard Report');
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  }catch(error){
    swal({
      title: tr["Failed to Download File"],
    });
  }
  }
  const generatePdfFile = () => { 
    if(!downloadData[selectedCompanyId?.[0]?.id]){
      swal({
        title: tr["Failed to Download File"],
      });  
      return;  
    }
    const fileName=`${selectedCompanyId[0].label} Scorecard Report.pdf`;
    const doc = new jsPDF({ orientation: 'landscape' });
    const MainHeaderSize = { 0: { cellWidth: 100 } };
    const MainHeaderStyle =  { 
        fillColor: [208, 232, 242],
        textColor: [0, 51, 102],
        lineWidth: 0.1,
        lineColor: [0, 0, 0]
      };
    const SubMainHeaderStyle = {
        fillColor: [223, 242, 225],
        textColor: [0, 51, 102],
        lineWidth: 0.1,
        lineColor: [0, 0, 0]
      };
    const columnSize =  {
        0: { cellWidth: 89 },
        1: { cellWidth: 20 }, 
        2: { cellWidth: 20 }, 
        3: { cellWidth: 20 }, 
        4: { cellWidth: 20 },
        5: { cellWidth: 20 },
        6: { cellWidth: 20 },
        7: { cellWidth: 20 },
        8: { cellWidth: 20 },
        9: { cellWidth: 20 }
      };
    const bodyStyle = {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineWidth: 0.1,
        lineColor: [0, 0, 0]
      };
    const headerStyle = { 
        fillColor: [255, 249, 204],
        textColor: [0, 0, 0],
        lineWidth: 0.1,
        lineColor: [0, 0, 0]
      };
    const stylesMeta = { fontSize: 8, overflow: 'linebreak' };
    const alternateRowStylesMeta = { fillColor: [240, 240, 240] };
    let position = 10;
    let addNewPage = false;

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const previousMonth = currentMonth - 1;
    const earlyMonth = currentMonth -2;

    const currentMonthName = new Date(currentYear,currentMonth).toLocaleString("default", { month: "long" });
    const previousMonthName = new Date(currentYear,previousMonth).toLocaleString("default", { month: "long" });
    const earlyMonthName = new Date(currentYear,earlyMonth).toLocaleString("default", { month: "long" });
      
    doc.autoTable({
        head: [[{content: 'Consolidated View', styles: {halign: 'center'}}]],
        startY: position,   
        theme: 'grid',
        styles: stylesMeta,
        headStyles: MainHeaderStyle,
        columnStyles: MainHeaderSize,
    });
    position = doc.lastAutoTable.finalY + 5;
    try {
      allQuardants.map((quadrantData,iteratorNumber) => {
          const quadrantID = quadrantData.quadrantId;
          const quadrantName = quadrantData.quadrantName;
          const quadrantDetails = downloadData[selectedCompanyId?.[0]?.id][quadrantID]?.[0];
          const headers = quadrantDetails.supplierMeasuresDetails.headings;
          const userEntries = quadrantDetails.supplierMeasuresDetails.supplierMeasure;
          doc.autoTable({
            head: [[{content: quadrantName, styles: {halign: 'center'}}]],
            theme: 'grid',
            styles: stylesMeta,
            headStyles: SubMainHeaderStyle,
            columnStyles: MainHeaderSize,
          });
          position = doc.lastAutoTable.finalY + 5;
          const totalTables = supplierData.length;
          supplierData.map((supplierDetails,itrNumber) => {
              const companyID = supplierDetails.supplierId;
              const companyName = supplierDetails.supplierName;
              const intermediateArrayList = [];
              const headerArray = [
                [
                  {content: companyName, styles: {halign: 'center'}},'', '', '', {content: earlyMonthName+' '+currentYear, colSpan: 2, styles: {halign: 'center'}}, {content: previousMonthName+' '+currentYear, colSpan: 2,  styles: {halign: 'center'}}, {content: currentMonthName+' '+currentYear, colSpan: 2, styles: {halign: 'center'}}
                ],
                [
                  {content: headers.heading1, styles: {halign: 'center'}}, {content: headers.heading2+' (30%)', styles: {halign: 'center'}}, {content: headers.heading3, styles: {halign: 'center'}}, {content: headers.heading4, styles: {halign: 'center'}}, {content: 'Achieved %', styles: {halign: 'center'}}, {content: 'Achieved Score', styles: {halign: 'center'}}, {content: 'Achieved %', styles: {halign: 'center'}}, {content: 'Achieved Score', styles: {halign: 'center'}}, {content: 'Achieved %', styles: {halign: 'center'}}, {content: 'Achieved Score', styles: {halign: 'center'}}
                ]
              ];
              userEntries.map(item => {
                  const userData = item.supplierScorecardDetails;
                  const intermediateArray = [];
                  intermediateArray.push(item.measureName);
                  intermediateArray.push(item.weightage);
                  intermediateArray.push(item.score);
                  intermediateArray.push(item.target);
                  if(typeof userData != 'undefined'){
                    item.supplierScorecardDetails.map(details => {
                      if(details.supplierId == companyID){
                        intermediateArray.push(details.draftAchievedPercentage == 0 ? '' : details.draftAchievedPercentage);
                        intermediateArray.push(details.draftScore == 0 ? '' : details.draftScore);
                      }
                    });
                  }
                  intermediateArrayList.push(intermediateArray);
              });
              doc.autoTable({
                head: headerArray,
                body: intermediateArrayList,
                startY: position,   
                theme: 'grid',
                styles: stylesMeta,
                headStyles: headerStyle,
                bodyStyles: bodyStyle,
                columnStyles: columnSize,
                alternateRowStyles: alternateRowStylesMeta
              });
              position = doc.lastAutoTable.finalY + 5;
              const pageHeight = doc.internal.pageSize.height;
              const cursorPosition = doc.lastAutoTable.finalY;
              const remainingPageLength = pageHeight - cursorPosition;
              const isLastTable = itrNumber == totalTables - 1;
              if(remainingPageLength < 60 && !isLastTable){
                doc.addPage();
                position = 10;
                addNewPage = true;
              }else{
                addNewPage = false;
              }
          });
          if(!addNewPage && iteratorNumber < 3){
           doc.addPage();
          }
      });
      doc.save(fileName);
    }catch(error){
      swal({
        title: tr["Failed to Download File"],
      });  
      console.log('Error : ',error);
    }
  }
  const exportDocument = (docType) => {
    if(docType==='pdf'){
      generatePdfFile();
    }else{
      generateExcelFile();
    }
  }
  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName="Scorecard" />
      </Container>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Header
              refetchQuardantsId={refetchQuardantsId}
              companyOptions={companyOptions}
              selectedCompanyId={selectedCompanyId}
              setSelectedCompanyId={setSelectedCompanyId}
              handleSubmit={handleSubmit}
              exportDocument={exportDocument}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            {isBusy || (isQuardantsFetching && selectedCompanyId?.[0]?.id) ? (
              <ListLoader />
            ) : (
              allQuardants?.map((quadrant) => {
                return (
                  <ScoreCardView
                    key={quadrant.quadrantId}
                    quadrantData={quadrant}
                    // orderData={orderData}
                    allQuardants={allQuardants}
                    userCompanyId={selectedCompanyId?.[0]?.id}
                    quadrantId={quadrant.quadrantId}
                    allSuppliers={supplierData}
                    monthsData={monthsData}
                    setIsBusy={setIsBusy}
                    refetchQuardantsId={refetchQuardantsId}
                    isQuardantsFetching={isQuardantsFetching}
                  />
                );
              })
            )}
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default ScoreCard;
