
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
// import { Row, Col, PageHeader,Button} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import {HiPlus, HiOutlineRefresh} from "react-icons/hi";

import { loadIntegrationHubList } from '../../actions/integrationHub/integrationHubAction.js';
import { useDispatch, useSelector } from "react-redux";
import { getSystemConfigList,getSurveyConfigList} from '../../actions/systemConfig/systemConfigAction.js';

const SystemConfigListHeader = (props)=>{
	const translator = useSelector((state) => state.spcmReducer.tr);
	const dispatch = useDispatch()
	const refreshConfigListData = () => {
		 
		dispatch(getSystemConfigList());			
		dispatch(getSurveyConfigList([props.type]));
		
		 
	}
		return (
			<>
				<Row className="margin-b-15">
					<Col lg={4} md={6} sm={4} xs={12}>
						<h2 bsClass="" className="sPageHeading1">{translator['Mailbox Configuration']}</h2>
					</Col>
					<Col lg={8} md={6} sm={8} xs={12} className="text-r">
						<div className="paHedFilter">
						    {props.filterTabActive == 1 ? <Link role="button" className="myBt plus fillBtn" title={translator['Mailbox Configuration']} to="/createSystemConfig"  state={{ activeTab: 1 }}><HiPlus /></Link>:null}
							{props.filterTabActive == 2 ? <Link role="button" className="myBt plus fillBtn" title={translator['Mailbox Configuration']} to="/createSurveyMilBoxConfig" state={{ activeTab: 2 }}><HiPlus /></Link>:null}
							{props.filterTabActive == 3 ? <Link role="button" className="myBt plus fillBtn" title={translator['Mailbox Configuration']} to="/createApprovalConfig" state={{ activeTab: 3 }}><HiPlus /></Link>:null} 
							{props.filterTabActive == 4 ? <Link role="button" className="myBt plus fillBtn" title={translator['Mailbox Configuration']} to="/mailboxConfig" state={{ activeTab: 4 }}><HiPlus /></Link>:null} 
							<Button title={translator["Refresh"]} onClick={refreshConfigListData} role="button" bsPrefix=' ' className="myBt"><HiOutlineRefresh className="refreshFlip" /></Button>
						</div>
					</Col>
				</Row>
				<Row className="margin-b-5">
					<Col xs={12}>
						<div aria-label="Table Filter" role="contentinfo" id="assFiltr">
							<div className="filtr">
							   <Button className={props.filterTabActive == 4 ? "active" : null} title={"Mailbox"} bsPrefix=" " onClick={()=>{props.setFilterTabActive(4);dispatch(getSurveyConfigList(['mailbox']))}}>
									{"Mailbox"}
								</Button> 
								<Button className={props.filterTabActive == 1 ? "active" : null} title={"Mail to Ticket"} bsPrefix=" " onClick={()=>{props.setFilterTabActive(1);dispatch(getSystemConfigList())}}>
									{"Mail to Ticket"}
								</Button>
								<Button className={props.filterTabActive == 2 ? "active" : null} title={"Survey"} bsPrefix=" " onClick={()=>{props.setFilterTabActive(2); dispatch(getSurveyConfigList(['survey']))}}
								>
									{"Survey"}
								</Button>
								<Button className={props.filterTabActive == 3 ? "active" : null} title={"Approval"} bsPrefix=" " onClick={()=>{props.setFilterTabActive(3);dispatch(getSurveyConfigList(['approval']))}}>
									{"Approval"}
								</Button> 
							</div>
						</div>
					</Col>
				</Row>
			</>		
		);
	}



export default SystemConfigListHeader;
