
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm, reset } from "redux-form";
import {
  allowedFields,
  filterAllowedFields,
  generateCondition,
  filterFromArray,
  getComponents,
  getLanguageString,
  getFilteredFields,
  getInitialFieldsForRules,
  openLinkInNewTab,
} from "../../common/helper";
import { Alert, Button, ButtonToolbar, Col, Row } from "react-bootstrap";
import {
  _inputField,
  _textArea,
  _number,
  _dropDown,
} from "../../common/formFields";
import axios from "axios";
import swal from "sweetalert";
import { useLocation, useNavigate, useParams } from "react-router";
import { useQuery } from "@apollo/client";
import {
  addRemoveEncapsulatedValue,
  checkErrors,
  checkIfHiddenField,
  cssStringToReactStyle,
  envVariables,
  getComponentsHavingRule,
  getTag,
  isJson,
  jsonEscape,
  replaceVariables,
} from "../studio/utils";
import { getForm, getLanguage } from "../graphQl/GraphqlQueries";
import FormViewHeader from "./components/headers/formViewHeader";
import BuilderBreadcrumbs from "./components/bredCrumb/builderBreadcrumbs";
import {
  GetInputField,
  CustomDropdown,
  GetCheckBoxField,
  GetDropdownConnectorField,
  GetRadioButtonField,
  GetTextareaField,
  GetTypeaheadField,
  GetUploadDropzoneField,
  ITagComponent,
  InputCheckboxMainField
} from "./components/common/builderFormFields";
import ListLoader from "../../common/loaders/ListLoader";
import { Helmet } from "react-helmet";
import ViewTableComponent from "../../formBuilderTemplate/generatedListingComponents/tableComponent";
import ButtonsComponent from "./components/ButtonsComponent";
import RepeatativeField from "./components/subComponents/RepeatativeFieldsViewComponent";
import DependentFieldsWithDefaultValue from "./components/subComponents/DependentFieldsWithDefaultValue";
import RenderMap from "./components/subComponents/renderMap";
import RepeatativeBlock from "./components/subComponents/RepeatativeBlocks";
import useDataQueryWithApi from "../hooks/useDataQueryWithApi";
import TabRenderer from "./components/subComponents/tabRenderer";
import LinkComponent from "./components/LinkComponent";
import AccordionRenderer from "./components/subComponents/customAccordionRenderer";
import SwitchButtonComponent from "./components/SwitchButtonComponent";
import PopupModal from "./components/modals/modalPopup";
import PageContainerComponent from "./components/subComponents/Containers";
import RepetativeLevel from "./components/repetativeLevels";
import HorizontalSlider from "./components/HorizontalSlider";
import IconsComponent from "./components/IconsComponent";
import LeftRightSection from "./components/subComponents/LeftRightSection";
import PaginationNumber from "./components/subComponents/PaginationNumber";
import ThreeDotComponent from "./components/subComponents/ThreeDotComponent";
import { CheckboxGroupFields } from "./components/kendoFormComponents/CheckboxGroup";

export function childernText(text) {
  return <span dangerouslySetInnerHTML={{ __html: text }}></span>;
}

const View = (props) => {
  const translator = useSelector((state) => state.spcmReducer.tr);
  const navigate = useNavigate();
  const params = useParams();
  const [forms, setForms] = useState({});
  const [ruleData, setRuleData] = useState({});
  const [defaultValues, setDefaultValues] = useState({...envVariables});
  const [errors, setErrors] = useState({});
  const [hiddenFIelds, setHiddenFields] = useState([]);
  let { handleSubmit } = props;
  const [language, setLanguage] = useState({});
  const [myTitle, setMyTitle] = useState({});
  const [myBreadCrumb, setMyBreadCrumb] = useState({});
  const [isEditForm, setIsEditForm] = useState(false);
  const [editData, setEditData] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);
  const [touchedFields, setTouchedFields] = useState({});
  const [formObjects, setFormObjects] = useState(null);
  const [cssString, setCss] = useState(null);
  const [isSubmitClicked,setIsSubmitClicked] = useState(false);
  const [formValues,setFormValues] = useState({});
  const [buttonProp,setButtonProps] = useState({});
  const [isDataRefreshed,setIsDataRefreshed] = useState(false);
  const [clearFilterClicked,setClearFilterClicked] = useState(false);
  const [mainPageId,setMainPageId] = useState(null);
  const [resetForm,setResetForm] = useState(false);
  const [formAttributes,setFormAttritutes] = useState({});
  const [buttonReplacableValue, setButtonReplacableValue] = useState({});
  const [clickedButton,setClickedButton] = useState(null)
  const [readOnlyFields,setReadOnlyFields] = useState([])
  const [manadatoryFields,setManadatoryFields] = useState([])
  const [openPopup,setOpenPopup] = useState(false);
  const [fileData,setFileData] = useState({});
  const [refreshDataOnUpdate,setRefreshDataOnUpdate] = useState(false);
  const [rightPanelDisplay, setRightPanelDisplay] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [isAllStateUpdated, setIsAllStateUpdated] = useState(false);
  const prevDefaultValuesRef = useRef(defaultValues);
  let { state: previousPageState, search } = useLocation();
  const type  = props?.type || "";
  const dispatch = useDispatch();
  let defaultVal={};
  const {
    loading: languageLoading,
    error: languageError,
    data: languageData,
  } = useQuery(getLanguage);

  const queryParams = new URLSearchParams(search);
  const searchTerm = queryParams.get("searchTerm");
  const parentValues = props?.parentValues || {};

  const [responseData , loading, setDataSourceId, error, setRefreshed] = useDataQueryWithApi({...parentValues,...envVariables,...buttonReplacableValue,...formValues},buttonProp, false, setResetForm, {}, fileData, setRefreshDataOnUpdate);

  const [editResponseData , editLoading, setEditResourceId, editError, setEditRefresh] = useDataQueryWithApi({dataId:params?.dataId,...parentValues,...envVariables},{}, false, setResetForm, {}, {}, setRefreshDataOnUpdate);
  useEffect(() => {
    if (error) {
      setClickedButton(null);
      setDataSourceId(null);
    }
  }, [error]);

  useEffect(() => {
    if (params) {
      setMainPageId(params?.id);
      //setEditRefresh(true);
    }
  }, [params.id]);
 
  useEffect(() => {
    if(refreshDataOnUpdate && props?.isChildPage && typeof props?.refreshFromChildPage == "function"){
      props?.refreshFromChildPage();
      setRefreshDataOnUpdate(false);
    } else if(refreshDataOnUpdate && !props?.isChildPage && typeof refreshFromChildPage == "function") {
      refreshFromChildPage();
      setRefreshDataOnUpdate(false);
    }
  }, [refreshDataOnUpdate]);
  
  useEffect(() => {
    if (!languageLoading && Object.keys(languageData).length > 0) {
      //setLanguage(JSON.parse(languageData?.getAllLanguages?.value));
    }
  }, [languageData]);
  const {
    data: formData,
    loading: formDataLoading,
    error: formDataError,
    refetch: formDataRefetch,
  } = useQuery(getForm, { variables: { id: mainPageId },skip:mainPageId == null, fetchPolicy: 'no-cache' });

  useEffect(()=>{
    if ((params && params?.id) || props?.pageId) {
      setMainPageId(props?.pageId || params?.id);
      //setEditRefresh(true);
      if(params?.dataId){
        setEditResourceId(params?.dataId);
      }
    }
  },[params.id,props?.pageId]);

  useEffect(()=>{
    if(mainPageId){
      formDataRefetch()
    }
},[mainPageId]);

useEffect(()=>{
  if(props?.parentValues){
    setDefaultValues({...parentValues,...defaultValues})
  }
},[props?.parentValues]);

  useEffect(() => {
    formDataRefetch();
  }, []);
  const updateValues = (name, value, extraMappedKey = "", secondValue = "") => {
    let mappedValue = {};
    mappedValue[name] = value;
    if (extraMappedKey && secondValue) {
      mappedValue[extraMappedKey] = secondValue;
    }
    setDefaultValues({...defaultValues, ...mappedValue });
  };

  const checkRules = () => {
    let hidden = structuredClone(hiddenFIelds);
    let readOnly = structuredClone(readOnlyFields);
    let manadatory = structuredClone(manadatoryFields);
    if (ruleData && ruleData.length) {
      const mainRuleData = ruleData?.filter((res) => !res?.ruleType || res?.ruleType == "mainRule");
      mainRuleData.forEach((ruleRes) => {
        const condition = generateCondition(ruleRes?.query, defaultValues)
          .replace(/\band\b/g, "&&")
          .replace(/\bor\b/g, "||");
        if (condition && eval(condition)) {
          if (ruleRes?.conditionTrue && ruleRes.conditionTrue.length > 0) {
            ruleRes.conditionTrue.forEach((res) => {
              if (res.operation == "show") {
                const index = hidden.indexOf(res.field);
                if (index > -1) {
                  hidden.splice(index, 1);
                }
              } else if (res.operation == "hide") {
                if (!hidden.includes(res.field)) {
                  hidden.push(res.field);
                }
              }
              //// starts readOnly if condition true
              if (res.operation == "readOnly") {
                if (!readOnly.includes(res.field)) {
                  readOnly.push(res.field);
                }
              } else if(res.operation == "removeReadOnly") {
                const index = readOnly.indexOf(res.field);
                if (index > -1) {
                  readOnly.splice(index, 1);
                }
              }

              //// starts manadatory if condition true
              if (res.operation == "manadatory") {
                if (!manadatory.includes(res.field)) {
                  manadatory.push(res.field);
                }
              } else if(res.operation == "nonManadatory") {
                const index = manadatory.indexOf(res.field);
                if (index > -1) {
                  manadatory.splice(index, 1);
                }
              }
            });
          }
        } else {
          if (ruleRes?.conditionFalse && ruleRes.conditionFalse.length > 0) {
            ruleRes.conditionFalse.forEach((res) => {
              if (res.operation == "show") {
                const index = hidden.indexOf(res.field);
                if (index > -1) {
                  hidden.splice(index, 1);
                }
              } else if (res.operation == "hide") {
                if (!hidden.includes(res.field)) {
                  hidden.push(res.field);
                }
              }
              //// starts readOnly if condition false
              if (res.operation == "readOnly") {
                if (!readOnly.includes(res.field)) {
                  readOnly.push(res.field);
                }
              } else if(res.operation == "removeReadOnly"){
                const index = readOnly.indexOf(res.field);
                if (index > -1) {
                  readOnly.splice(index, 1);
                }
              }

              //// starts manadatory if condition false
              if (res.operation == "manadatory") {
                if (!manadatory.includes(res.field)) {
                  manadatory.push(res.field);
                }
              } else if(res.operation == "nonManadatory") {
                const index = manadatory.indexOf(res.field);
                if (index > -1) {
                  manadatory.splice(index, 1);
                }
              }
            });
          }
        }
      });
    }
    setReadOnlyFields(readOnly);
    setManadatoryFields(manadatory);
    setHiddenFields(hidden);
  };

  const checkFieldsRule = (mainRuleData) => {
    let newDefault = structuredClone(defaultValues);
    if (ruleData && ruleData.length) {
      mainRuleData.forEach((ruleRes) => {
        const condition = generateCondition(ruleRes?.query, defaultValues)
          .replace(/\band\b/g, "&&")
          .replace(/\bor\b/g, "||");
        if (condition && eval(condition)) {
          if (ruleRes?.conditionTrue && ruleRes.conditionTrue.length > 0) {
            ruleRes.conditionTrue.forEach((res) => {
              if (res.operation == "setValue" && res?.field) {
                newDefault[res?.field] = res?.value?res?.value:"";
              }
            });
          }
        } else {
          if (ruleRes?.conditionFalse && ruleRes.conditionFalse.length > 0) {
            ruleRes.conditionFalse.forEach((res) => {
              if (res.operation == "setValue" && res?.field) {
                newDefault[res?.field] = res?.value?res?.value:"";
              }
            });
          }
        }
      });
    }
    return newDefault;
  };

  useEffect(() => {
    if (ruleData && Object.keys(ruleData).length > 0) {
      checkRules();
      const fieldsRule = ruleData?.filter((res) => res?.ruleType == "fieldRule");
      if(fieldsRule.length>0){
      const newDefaultValues = checkFieldsRule(fieldsRule);
      if(newDefaultValues && JSON.stringify(newDefaultValues) !== JSON.stringify(prevDefaultValuesRef.current)){
        setDefaultValues(newDefaultValues);
      }
     }
    }
    if (defaultValues && Object.keys(defaultValues).length > 0 && isSubmitClicked) {
      let errors = checkErrors(forms, defaultValues, touchedFields, hiddenFIelds, manadatoryFields);
      setErrors(errors);
    }
  }, [ruleData, defaultValues]);

  useEffect(() => {
    if (hiddenFIelds && hiddenFIelds.length > 0) {
      hiddenFIelds.forEach((res) => {
        if (defaultValues.hasOwnProperty(res)) {
          delete defaultValues[res];
        }
      });
      if(props?.isChildPage && typeof props?.deleteFromChildPage == "function"){
        props.deleteFromChildPage(hiddenFIelds);
      }
    }
  }, [hiddenFIelds]);


  // const removeHiddenFields = (fieldName)=> {
  //   if (defaultValues.hasOwnProperty(fieldName)) {
  //     delete defaultValues[fieldName];
  //   }
  // }

  /// useEffect for checking values and conditions before rendering
  useEffect(() => {
    if (formData && Object.keys(formData).length > 0 && Object.keys(forms).length === 0) {
      if (formData?.getForm?.customScript) {
        const script = formData?.getForm?.customScript;
        setTimeout(() => {
          eval(script);
        }, 2000);
      }
      const data = JSON.parse(formData?.getForm?.formObjects);
      if (formData?.getForm?.ruleData && isJson(formData?.getForm.ruleData)) {
        //setRuleData(JSON.parse(formData?.getForm?.ruleData));
        const ruleData = JSON.parse(formData?.getForm.ruleData);
        if (Array.isArray(ruleData)) {
          setRuleData(ruleData);
        } else if (
          typeof ruleData == "object" &&
          Object.keys(ruleData).length > 0
        ) {
          const newRule = [];
          newRule.push(ruleData);
          setRuleData(newRule);
        }
      }
      if (typeof data == "object" && Object.keys(data).length > 0) {
        let components = [];
        for (let i = 0; i < data.pages.length; i++) {
          for (let j = 0; j < data.pages[i].frames.length; j++) {
            components = [
              ...components,
              getComponents(data.pages[i].frames[j].component.components),
            ];
          }
        }
        const filteredFields = filterAllowedFields(components, allowedFields);
        if(filteredFields.length>0){
          setForms(filteredFields);
        }
        setFormObjects(data);
        let buttons = filterFromArray(filteredFields, "type", "button");
        const title = filterFromArray(filteredFields, "type", "titleComponent");
        const formAttributes = filterFromArray(filteredFields, "type", "form");
        const breadCrumb = filterFromArray(
          filteredFields,
          "type",
          "myBreadCrumb"
        );
        if (title.length > 0) {
          setMyTitle(title[0]);
        }
        if (breadCrumb.length > 0) {
          setMyBreadCrumb(breadCrumb[0]);
        }

        if (formAttributes.length > 0) {
          setFormAttritutes(formAttributes[0]);
        }else{
          setFormAttritutes({});
        }

        if (params?.dataId) {
          setIsEditForm(true);
        }
      }
    }
  }, [formData, forms]);
  useEffect(() => {
    setErrors({});
    setIsSubmitClicked(false);
    setClickedButton(null);
    setButtonProps({});
    setFormValues({});
    setDataSourceId(null);
    setRefreshed(null);
    setHiddenFields([]);
    setForms({});
    setEditData({});
    setIsDataRefreshed(false);
    setFormObjects(null);
    setButtonReplacableValue({});
    setRightPanelDisplay(false);
    if(!params?.dataId && !isEditForm) {
      setIsEditForm(false);
      let editData = {};
      if(formAttributes && Object.keys(formAttributes).length>0 && formAttributes?.data_source?.id){
        editData = editResponseData;
      }
      setDefaultValues({...envVariables,...editData, searchTerm: searchTerm});
    }
    setResetForm(false);
    setFileData({});
  }, [params?.id, resetForm])

  useEffect(()=>{
     if(resetForm && props?.isFormView){
        props?.setIsFormView(false);
     } 
  },[resetForm])

  useEffect(()=>{
    if(resetForm && props?.isChildPage){
      setDefaultValues({});
    }

    if(resetForm && props?.isChildPage && buttonProp?.buttonType?.id === "save" && typeof props?.closePopup == "function" && buttonProp?.closePopup){
      props?.closePopup(false);
   }
 },[resetForm])

  useEffect(()=>{
     if(isEditForm){
        setIsEditForm(false);
        setDefaultValues({});
     }
  },[params?.id,params?.dataId])

  useEffect(() => {
    if (isEditForm && Object.keys(editData).length == 0) {
      if (previousPageState?.editData) {
        setDefaultValues(previousPageState?.editData);
      }
    }
  }, [isEditForm, editData]);

  useEffect(()=>{
     if(formAttributes && Object.keys(formAttributes).length>0){
       setEditResourceId(formAttributes.data_source?.id);
     }
  },[formAttributes]);

  useEffect(() => {
    if(editResponseData){
       setDefaultValues(editResponseData);
    }
  }, [editResponseData]);

  //////// end of use effect of edit response

  useEffect(() => {
    if (responseData && typeof responseData == "object" && isEditForm) {
      setDefaultValues({...defaultValues, ...responseData });
    }
  }, [responseData]);

  useEffect(() => {
    if (responseData && typeof responseData == "object" && fileData && Object.keys(fileData).length > 0 && buttonProp?.uploadFile) {
       const buttonPropData = structuredClone(buttonProp);
       delete buttonPropData["uploadFile"];
       setButtonProps(buttonPropData);
       setErrors({});
       setTimeout(() => {
        submit("save",{...buttonPropData,data_source:fileData?.attributes?.data_source},responseData);
       }, 100);
    }
  }, [responseData]);

  //// end of use button api response ----


  //// if page is gets refreshed 

  useEffect(()=>{
     if(props?.pageRefreshed){
      setIsDataRefreshed(true);
      if(props?.refreshClickFun && typeof props?.refreshClickFun =="function"){
        props?.refreshClickFun(false);
      }
     }
  },[props?.pageRefreshed])

  /// end of form data change useEffect

  const jsonToCss = (json) => {
    if (json) {
      let css = "";
      json.forEach((item) => {
        let inCss = "";
        item.selectors.forEach((selector) => {
          let name = typeof selector === "string" ? selector : selector.name;
          if (name.indexOf("col-") == -1) {
            if (name.charAt(0) == "#") {
              inCss += `${name} {\n`;
            } else {
              inCss += `.${name} {\n`;
            }

            for (let property in item.style) {
              inCss += `  ${property}: ${item.style[property]};\n`;
            }
            inCss += "}\n";
          }
        });
        if (item.atRuleType === "media") {
          css += `@media ${item.mediaText} {\n${inCss}}\n`;
        } else {
          css += inCss;
        }
      });

      return css;
    }
  };

  useEffect(() => {
    if (formObjects) {
      let css = jsonToCss(formObjects?.styles);
      setCss(css);
    }
  }, [formObjects]);

  const onChange = (e, attributes) => {
    if(attributes?.defaultValue){
      defaultVal = {...defaultVal, [attributes.name]: e};
      setDefaultValues((prev)=>{ return {...prev,...defaultVal}});
    }else{
      setDefaultValues((prev)=>{return { ...prev, [attributes.name]: e }});
    }
  };

  const resetButtonProps = () => {
    setTimeout(() => {
      setButtonProps({});
    },100)
  }

  const submit = (type, buttonProp, extData={}) => {
    if (type === "save") {
        let postJson = {};
        let error = false;
        let values= {};
        setIsSubmitClicked(true);
        let combinedValues = { ...values, ...defaultValues, ...extData, };
        if(!buttonProp?.skipValidation){
          let errors = checkErrors(forms, combinedValues, {}, hiddenFIelds, manadatoryFields);
          if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
          } else {
            error = false;
            setErrors(errors);
          }
        }
        if (
          combinedValues &&
          typeof combinedValues == "object" &&
          Object.keys(combinedValues).length > 0
        ) {
          Object.keys(combinedValues).forEach((key) => {
            if (
              combinedValues[key] &&
              typeof combinedValues[key] == "object" &&
              combinedValues[key].hasOwnProperty("dataType") &&
              combinedValues[key]?.dataType == "file"
            ) {
              postJson[key] = combinedValues[key]?.data;
            } else if (
              typeof combinedValues[key] == "object" ||
              Array.isArray(combinedValues[key])
            ) {
              buttonProp?.passingKeys &&
              buttonProp?.passingKeys?.split(",")?.includes(key)
                ? (postJson[key] = JSON.stringify(
                    JSON.stringify(combinedValues[key])
                  ))
                : (postJson[key] = JSON.stringify(combinedValues[key]));
            } else {
              postJson[key] = jsonEscape(combinedValues[key]);
            }
          });
        }
        if(!error) {
          setClickedButton(buttonProp);
          setFormValues(postJson);
          setButtonProps(structuredClone(buttonProp));
          setDataSourceId(buttonProp?.data_source?.id);
          // setRefreshed(true);
        }
      // handleSubmit((values) => {
        
      // })();
    } else if(type === 'refresh') {
      setRefreshed(true);
    }else{
      if(buttonProp?.data_source && buttonProp?.data_source?.id){
        setClickedButton(buttonProp);
        setFormValues(defaultValues);
        setButtonProps(structuredClone(buttonProp));
        setDataSourceId(buttonProp?.data_source?.id);
      }
    }
  }


  const addEncapsulatedFormValues = (type, buttonProp, replacableValues={}) => {
    if (type === "addInDefault" && buttonProp?.encapsulationKey) {
      setIsSubmitClicked(true);
      let combinedValues = {...parentValues, ...defaultValues };
      if (!buttonProp?.skipValidation) {
        let errors = checkErrors(forms, combinedValues, {}, hiddenFIelds, manadatoryFields);
        if (Object.keys(errors).length > 0) {
          setErrors(errors);
          return;
        } else {
          setErrors(errors);
        }
      }
      let json = {};
      Object.keys(combinedValues).forEach((key) => {
        json[key] = combinedValues[key];
      });
      const { paraVal, keys } = addRemoveEncapsulatedValue(
        props?.parentValues,
        buttonProp?.encapsulationKey,
        json,
        type,
        buttonProp?.passingKeys?.split(',')
      );
      
      let struparaVal = structuredClone(paraVal);
      if (typeof props?.setFromChildPage == "function") {
        props?.setFromChildPage(struparaVal);
        if (buttonProp?.closePopup) {
          props?.closePopup(false);
        }
      }
    } else if (type === "removeFromDefault" && buttonProp?.encapsulationKey) {
      const parentVal = addRemoveEncapsulatedValue(
        { ...defaultValues, index: replacableValues?.index },
        buttonProp?.encapsulationKey,
        defaultValues,
        type
      );
      if (props?.isChildPage) {
        props?.setFromChildPage(parentVal);
        if (buttonProp?.closePopup) {
          props?.closePopup(false);
        }
      } else {
        setTimeout(() => {
          setDefaultValues({...defaultValues,...parentVal});
        }, 200);
      }
    } else if (type === "removeAll" && buttonProp?.encapsulationKey) {
      const defaultValStatus = structuredClone(defaultValues);
      delete defaultValStatus[buttonProp?.encapsulationKey];
      setDefaultValues(defaultValStatus);
    }
  }

  const buttonClicked = (type, buttonProp, replacableValues={}) => {
    setButtonReplacableValue(replacableValues);

    if (type === "link") {
      let url = replaceVariables(buttonProp?.redirectUrl, replacableValues);
      if (buttonProp?.confirmationMessage) {
        swal({
          title: buttonProp?.confirmationMessage,
          icon: "warning",
          buttons: ["No", "Yes"],
          dangerMode: true,
        }).then(function (isConfirm) {
          if (isConfirm) {
            navigate(url);
          }
        });
      } else {
        navigate(url);
      }
    } else if(type === "export") {
      if (buttonProp?.confirmationMessage) {
        swal({
          title: buttonProp?.confirmationMessage,
          icon: "warning",
          buttons: ["No", "Yes"],
          dangerMode: true,
        }).then(function (isConfirm) {
          if (isConfirm) {
            submit(type, buttonProp);
          }
        });
      } else {
        submit(type, buttonProp);
      }
    } else if (type === "refresh") {
      setIsDataRefreshed(true);
    } else if (type === "clearAllFilter") {
      setClearFilterClicked(true);
    } else if (type === "closeRightSide") {
      setRightPanelDisplay(false);
    } else if (type === "newTabOpen") {
      let url = replaceVariables(buttonProp?.redirectUrl, replacableValues);
      openLinkInNewTab(url,"New Tab")
    } else if (type === "newPageOpen") {
      if (
        props?.setOpenedTabPageId &&
        typeof props?.setOpenedTabPageId == "function"
      ) {
        props?.setOpenedTabPageId(buttonProp?.page?.id);
      } else if (
        buttonProp?.openPageInPopup?.id == "bootstrapPopup" ||
        buttonProp?.openPageInPopup?.id == "kendoPopup"
      ) {
        setOpenPopup(true);
        setButtonProps({ buttonProp, data: replacableValues });
      } else if (buttonProp?.openPageInPopup?.id == "container") {
        setButtonProps({ buttonProp, data: replacableValues });
      }
      return;
    }
    if (buttonProp?.confirmationMessage && type !== "link") {
      swal({
        title: buttonProp?.confirmationMessage,
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then(function (isConfirm) {
        if (isConfirm) {
          submit(type, buttonProp);
          // setButtonProps(buttonProp);
          // setDataSourceId(buttonProp?.data_source?.id);
          addEncapsulatedFormValues(type, buttonProp, replacableValues);
        }
      });
    } else {
      submit(type, buttonProp);
      // setButtonProps(buttonProp);
      // setDataSourceId(buttonProp?.data_source?.id);
      addEncapsulatedFormValues(type, buttonProp, replacableValues);
    }

    if (buttonProp?.setDetaultValue && buttonProp?.ruleName) {
      let buttonValueVar;
      defaultValues[buttonProp?.ruleName] &&
      defaultValues[buttonProp?.ruleName] === "true"
        ? (buttonValueVar = "false")
        : (buttonValueVar = "true");
      setDefaultValues((prev) => {
        return {
          ...prev,
          [buttonProp?.ruleName]: buttonValueVar,
        };
      });
    }
  };

  const removeFromDefaultValues = (json) => {
      const getFilteredField = filterAllowedFields(getComponents(json?.components), allowedFields);
      const getFieldsName = getInitialFieldsForRules(getFilteredField);
      return getFieldsName;
    }

    useEffect(() => {
      const newDefaultValues = structuredClone(defaultValues);
      const getRuleBasedComponents = getComponentsHavingRule(
        JSON.stringify(formObjects?.pages?formObjects:{})
      );    
      const names = [];
      if (Array.isArray(getRuleBasedComponents)) {
        getRuleBasedComponents.forEach((item) => {
          if(hiddenFIelds.includes(item?.ruleName)){
            const removedValue = removeFromDefaultValues(item);
            if(Array.isArray(removedValue) && removedValue.length>0){
            names.push(...names, ...removedValue);
           }
          }
        });
      }
      if (names.length > 0) {
        names.forEach((name) => {
          if (newDefaultValues.hasOwnProperty(name?.name)) {
            delete newDefaultValues[name?.name];
          }
        });
      }
      
      if (JSON.stringify(newDefaultValues) !== JSON.stringify(prevDefaultValuesRef.current)) {
        if (props?.isChildPage && Object.keys(props?.parentValues).length > 0 && !isAllStateUpdated) {
          Object.keys(props?.parentValues).forEach((key) => {
            if (!hiddenFIelds.includes(key)) {
              newDefaultValues[key] = props?.parentValues[key];
            }
          });
          setIsAllStateUpdated(true);
        }
        prevDefaultValuesRef.current = newDefaultValues;
        setDefaultValues(newDefaultValues);
      }

    }, [hiddenFIelds]);

  const setFromChildPage = (values)=>{
       delete values["index"];
       delete defaultValues["index"];
       setDefaultValues({...defaultValues,...values});
  }

  const deleteFromChildPage = (values) => {
    const existingValues = structuredClone(defaultValues);
    if (values.length > 0) {
      values.forEach((val) => {
        delete existingValues[val];
      });
      if(JSON.stringify(prevDefaultValuesRef.current)!== JSON.stringify({...existingValues})){
        prevDefaultValuesRef.current = { ...existingValues };
        setDefaultValues({ ...existingValues });
      }
      if (buttonProp && buttonProp?.data) {
        const buttonPropData = structuredClone(buttonProp?.data);
        values.forEach((val) => {
          delete buttonPropData[val];
        });
        if (
          JSON.stringify(buttonProp?.data) !== JSON.stringify(buttonPropData)
        ) {
          setButtonProps({ ...buttonProp, data: { ...buttonPropData } });
        }
      }
    }
  };

  const refreshFromChildPage = ()=> {
    setResetForm(true);
    setEditRefresh(true);
  }

  const renderComponent = (json, isRowBased = false, rowData={}) => {
    if(resetForm) return <ListLoader />
    else {
      return json.map((item, index) => {
        let attributes = {};
        if (item.attributes) {
          attributes = { ...item.attributes };
        }
        if (item.classes) {
          attributes.className = item.classes
            .map((c) => (c.name ? c.name : c))
            .join(" ");
        }
        if (attributes?.style) {
          attributes.style = cssStringToReactStyle(attributes.style);
        }
        if (item?.name && item.name == "Row") {
          attributes.className = attributes.className + "";
        }
        if(item?.ruleName && hiddenFIelds.includes(item?.ruleName)){
          return null;
        }
        if (
          item?.name &&
          item.name == "Row" &&
          item?.attributes &&
          item?.attributes?.title == "form"
        ) {
          if (checkIfHiddenField(item.components, hiddenFIelds) || hiddenFIelds.includes(item?.ruleName)) {
            return null;
          }
        }
        if (item?.name && item.name == "Cell") {
          attributes.className = attributes.className + "";
        }
        if (
          item?.name &&
          item.name == "Cell" &&
          item?.attributes &&
          item?.attributes?.title == "form"
        ) {
          if (checkIfHiddenField(item.components, hiddenFIelds) || hiddenFIelds.includes(item?.ruleName)) {
            return null;
          }
        }

        if (attributes && attributes.title == "form") {
          delete attributes["title"];
        }
        let children = null;
        if (item.components) {
          children = renderComponent(item.components, isRowBased, rowData);
        } else if (item.content) {
          children = childernText(replaceVariables(item.content,{...rowData,...parentValues,...defaultValues,...envVariables}));
        }
        if (item.type === "textnode") {
          return children;
        } else if (item.type == "input") {
          return (
            <GetInputField
              attributes={item.attributes}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues,...rowData}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
              atr={{id:attributes?.id,className:attributes?.className}}
              setDefaultValues={setDefaultValues}
            />
          );
        }else if (item.type == "myDateTimeComponent") {
          return (
            <GetInputField
              attributes={item.attributes}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues,...rowData}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
              atr={{id:attributes?.id,className:attributes?.className}}
            />
          );
        } else if (item.type == "textarea") {
          return (
            <GetTextareaField
              attributes={item.attributes}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues,...rowData}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
              atr={{id:attributes?.id,className:attributes?.className}}
            />
          );
        } else if (item.type == "custom-dropdown" || item.type == "select") {
          return (
            <CustomDropdown
              props={item}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues,...rowData}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              setDefaultValues={setDefaultValues}
              errors={errors}
              atr={{id:attributes?.id, className:attributes?.className}}
            />
          );
        } else if (item.type == "typeaheadComponent") {
          return (
            <GetTypeaheadField
              attributes={item}
              language={language}
              onChange={onChange}
              setDefaultValues={setDefaultValues}
              defaultValues={{...parentValues,...defaultValues,...rowData}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
              resetForm={resetForm}
              isEditForm={isEditForm}
              atr={{id:attributes?.id,className:attributes?.className}}
              deleteFromChildPage={props?.deleteFromChildPage}
            />
          );
        } else if (item.type == "uploadComponent") {
          return (
            <GetUploadDropzoneField
              attributes={item}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues,...rowData}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
              atr={{id:attributes?.id,className:attributes?.className}}
              setFileData={setFileData}
            />
          );
        } else if (item.type == "custom-checkbox") {
          return (
            <GetCheckBoxField
              attributes={item}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues,...rowData}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
              atr={{id:attributes?.id,className:attributes?.className}}
            />
          );
        } else if (item.type == "checkbox-group") {
          return (
            <CheckboxGroupFields
              attributes={item}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
              atr={{id:attributes?.id,className:attributes?.className}}
            />
          );
        } else if (item.type == "custom-radio" && item.labels) {
          return (
            <GetRadioButtonField
              attributes={item}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues,...rowData}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
              atr={{id:attributes?.id,className:attributes?.className}}
            />
          );
        } else if (item.type == "dropdownConnector") {
          return (
            <GetDropdownConnectorField
              attributes={item}
              language={language}
              onChange={onChange}
              updateValues={updateValues}
              defaultValues={{...parentValues,...defaultValues,...rowData}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
              isEditForm={isEditForm}
              atr={{id:attributes?.id,className:attributes?.className}}
            />
          );
        } else if (item.type === "myBreadCrumb") {
          return (
            <BuilderBreadcrumbs
              breadcrumbs={item?.label}
              links={item?.breadCrumbLinks}
              attributes={attributes}
              defaultValues={{...parentValues,...defaultValues,...rowData}}
            />
          );
        } else if (item.tagName === "i") {
          return <ITagComponent attributes={attributes} />;
        } else if (item.type === "RepeatativeFieldsComponent") {
          return (
            <RepeatativeField
              components={item.components}
              item={item}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues,...rowData}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
              renderComponent={renderComponent}
            />
          );
        } else if (item.type === "RepetativeRowBlock") {
          return (
            <RepeatativeBlock
              components={item.components}
              item={item}
              language={language}
              languageData={languageData}
              defaultValues={{...parentValues,...defaultValues,...rowData}}
              isDataRefreshed={isDataRefreshed}
              setIsDataRefreshed={setIsDataRefreshed}
              buttonClicked={buttonClicked}
              renderComponent={renderComponent}
            />
          );
        } else if (item.type === "dependentFieldsBlockComponent") {
          return (
            <DependentFieldsWithDefaultValue
              components={item.components}
              item={item}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues,...rowData}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
            />
          );
        } else if (item.type === "button") {
          return (
            <ButtonsComponent
              buttonsProp={item}
              buttonClicked={buttonClicked}
              disabled={loading}
              defaultValues={{...parentValues,...defaultValues,...rowData}}
              clickedButton={clickedButton}
              isRowBased={isRowBased}
              rowData={rowData}
              atr={{id:attributes?.id,className:attributes?.className}}
              setFromChildPage={setFromChildPage}
            />
          );
        } else if (item.type == "table" && item?.attributes) {
          return (
            <ViewTableComponent
              renderComponent={renderComponent}
              languageData={languageData}
              table={item}
              title={myTitle}
              defaultValues={{...parentValues,...defaultValues,...rowData}}
              isDataRefreshed={isDataRefreshed}
              setIsDataRefreshed={setIsDataRefreshed}
              clearFilterClicked={clearFilterClicked}
              setClearFilterClicked={setClearFilterClicked}
              setFormValues={setFormValues}
              setButtonReplacableValue={setButtonReplacableValue}
              setDefaultValues={setDefaultValues}
              errors={errors}
              setRightPanelDisplay={setRightPanelDisplay}
              setSelectedRow={setSelectedRow}
            />
          );
        } else if (item.type == "image") {
          return <img {...attributes} />;
        } else if (item.type == "checkbox") {
          return (
            <InputCheckboxMainField
              attributes={item?.attributes}
              language={language}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues,...rowData}}
              hiddenFIelds={hiddenFIelds}
              readOnlyFields={readOnlyFields}
              errors={errors}
              atr={{id:attributes?.id,className:attributes?.className}}
            />
          );
        } else if (item.type == "map" || item.type == "video") {
          return (
            <RenderMap
              attributes={attributes}
              src={item.src}
              title={item.address}
              languageData={languageData}
            />
          );
        } else if (item.type == "tabs") {
          return (
            <TabRenderer
              item={item}
              languageData={languageData}
              renderComponent={renderComponent}
              defaultValues={{...parentValues,...defaultValues,...rowData}}
              setFromChildPage={setFromChildPage}
              deleteFromChildPage={deleteFromChildPage}
              refreshFromChildPage={refreshFromChildPage}
            />
          );
        } else if (item.type == "CustomAccordion") {
          return (
            <AccordionRenderer
              item={item}
              languageData={languageData}
              renderComponent={renderComponent}
              defaultValues={{...parentValues,...defaultValues,...rowData}}
              setDefaultValues={ setDefaultValues }
              atr={{id:attributes?.id,className:attributes?.className}}
              setFromChildPage={setFromChildPage}
              deleteFromChildPage={deleteFromChildPage}
              refreshFromChildPage={refreshFromChildPage}
            />
          );
        } else if (item.type == "link") {
          return (
            <LinkComponent
              item={item}
              languageData={languageData}
              renderComponent={renderComponent}
              buttonClicked={buttonClicked}
              attributes={attributes}
              clickedButton={clickedButton}
              defaultValues={{...parentValues,...defaultValues,...rowData}}
              isRowBased={isRowBased}
              rowData={rowData}
            />
          );
        }else if (item.type == "switch-button") {
          return (
            <SwitchButtonComponent
              item={item}
              languageData={languageData}
              renderComponent={renderComponent}
              attributes={attributes}
              onChange={onChange}
              defaultValues={{...parentValues,...defaultValues,...rowData}}
            />
          );
        }else if (item.type == "pageContainer") {
          return (
            <PageContainerComponent
              item={item}
              languageData={languageData}
              buttonProp={buttonProp}
              defaultValues={{ ...parentValues, ...defaultValues,...rowData}}
              setFromChildPage={setFromChildPage}
              setButtonProps={resetButtonProps}
              deleteFromChildPage={deleteFromChildPage}
              refreshFromChildPage={refreshFromChildPage}
            />
          );
        } else if (item.type == "repetativeLevelBlock") {
          return (
            <RepetativeLevel
            components={item.components}
            item={item}
            language={language}
            languageData={languageData}
            defaultValues={{...parentValues,...defaultValues,...rowData}}
            isDataRefreshed={isDataRefreshed}
            setIsDataRefreshed={setIsDataRefreshed}
            buttonClicked={buttonClicked}
            renderComponent={renderComponent}
            />
          );
        } else if (item.type == "horizontal-slider") {
          return (
            <HorizontalSlider
            components={item.components}
            item={item}
            language={language}
            languageData={languageData}
            defaultValues={{...parentValues,...defaultValues,...rowData}}
            isDataRefreshed={isDataRefreshed}
            setDefaultValues={setDefaultValues}
            setIsDataRefreshed={setIsDataRefreshed}
            buttonClicked={buttonClicked}
            renderComponent={renderComponent}
            />
          );
        }else if (item.type == "ReactIcons") {
          return (
            <IconsComponent
            item={item}
            language={language}
            />
          );
        }else if (item.type == "ExternalIcons") {
          return (
            <IconsComponent
            item={item}
            language={language}
            type="external"
            renderComponent={renderComponent}
            />
          );
        }else if (item.type == "LeftRightSection") {
          return (
            <LeftRightSection
              item={item}
              language={language}
              type="leftRightSection"
              renderComponent={renderComponent}
              rightPanelDisplay={rightPanelDisplay}
              setRightPanelDisplay={setRightPanelDisplay}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
            />
          );
        } else if (item.type == "PaginationNumber") {
          return (
            <PaginationNumber
              item={item}
              language={language}
              renderComponent={renderComponent}
              defaultValues={{...parentValues,...defaultValues}}
              attributes={attributes}
            />
          );
        } else if (item.type == "ThreeDotComponent") {
          return (
            <ThreeDotComponent
              item={item}
              language={language}
              renderComponent={renderComponent}
              defaultValues={{...parentValues,...defaultValues}}
              attributes={attributes}
            />
          );
        } else {
          return React.createElement(
            item.type || item?.tagName ? getTag(item) : "div",
            { ...attributes, key: index },
            children
          );
        }
      });
    }
  };

  if (languageLoading || formDataLoading || editLoading) {
    return (
      <div>
        <Row>
          <Col className="margin-t-10">
            <ListLoader />
          </Col>
        </Row>
      </div>
    );
  } else {
    ///main render part
    return (
      <div>
        <Helmet>
          <style>{cssString}</style>
        </Helmet>
        {/* <div className="container-fluid margin-t-10 margin-b-15">
          {Object.keys(myBreadCrumb).length > 0 && (
            <BuilderBreadcrumbs breadcrumbs={myBreadCrumb?.label} />
          )}
        </div> */}
        <div className="container-fluid">
          <>
            {errors && Object.keys(errors).length > 0 && (
              <Alert variant="danger" className="errorMessageSec margin-t-15">
                Please checkout the required fields!
              </Alert>
            )}
          </>
          <>
            {formObjects &&
              renderComponent(
                formObjects.pages[0].frames[0].component?.components
              )}
          </>
        </div>
        {openPopup && (
          <PopupModal
            open={openPopup}
            buttonProp={buttonProp}
            setOpenPopup={setOpenPopup}
            defaultValues={{...parentValues,...defaultValues}}
            setFromChildPage={setFromChildPage}
            deleteFromChildPage={deleteFromChildPage}
            refreshFromChildPage={refreshFromChildPage}
          />
        )}
      </div>
    );
  }
};

// export default reduxForm({
//   form: "FormView",
// })(View);

export default View;
