
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Button, Form, Table,ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Cookies from 'universal-cookie';
import {ImInfo} from 'react-icons/im';
import TaskDetailsPopup from '_Commons/WorkItemBoard/taskDetailsPopup.js';
import { GLOBAL } from "_Globals";

import { loadBreakFixEditDetails, loadBreakFixEditDetailsUpdate } from '../../../../actions/breakFix/breakFixEditDetailsAction';
import { loadBreakFixSG } from '../../../../actions/breakFix/serviceGroupAction';
import { loadBreakFixSGI } from '../../../../actions/breakFix/serviceGroupIAction';
import { loadBreakFixTaskLogDetails, loadTaskGroup, loadTaskGroup2, loadAsignedTo, checkRelatedTaskForItem, getTasktype } from '../../../../actions/task/TaskDataAction';
import { loadBreakFixSGAETask } from '../../../../actions/breakFix/serviceGroupAEAction';
import { getInvestigationItemDetails, getInvestigationItemDetailsUpdate } from '../../../../actions/investigation/investigationAction.js';
import { loadChangeEditData } from '../../../../actions/changeManagement/changeManagementAction.js';
import { loadFulfillmentDetails } from '../../../../actions/fulfillment/fulfillmentDataAction';


import ListLoader from '../../../common/loaders/ListLoader';
import Swal from 'sweetalert2';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
import { TypeaheadExampleSingleSelect } from '../../../../components/common/formFields';
import { problemStatusMapping } from '../../../common/helper.js';

const cookies = new Cookies();
let dateformat = cookies.get('gph');
if (dateformat) dateformat = dateformat.replace('s:', '');
if (dateformat) dateformat = dateformat.substring(0, dateformat.lastIndexOf('.'));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let languageSelected = cookies.get("gph");
languageSelected = languageSelected.split("~");
languageSelected = languageSelected[20];

let offset = cookies.get('gph');
if (offset) offset = offset.replace('s:', '');
if (offset) offset = offset.substring(0, offset.lastIndexOf('.'));
offset = offset.split("~");
offset = offset[34];
let timezone = cookies.get('gph');
if (timezone) timezone = timezone.replace('s:', '');
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf('.'));
timezone = timezone.split("~");
timezone = timezone[3];

let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];

const MoreArrow = <i title="More" className="fa fa-angle-double-down f-size-15" ></i>;

class PopoverTasksData extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			showUserData: false,
			taskName: "",
			showLoader: false,
			assignedToUser: '',
			isExternal: '',
			additionalcharleft: 500,
			hideTaskForm: false,
			additionalSummaaryCharLeft: 4000,
			oldUrgencyList: [],
			taskType: '',
			selectedGroup: [],
			groupList: [],
			typedGroupName: '',
			maxSequenceNumber: 100,
			minSequenceNumber: 1,
			eventList: [],
			eventName: "",
			eventId: "0",
			eventErrorColor: "",
			isFetchedGroupList: false,
			supportCompanyName: '',
			supportCompanyID: ''
		};
		GLOBAL.taskdescriptionval = "",
		GLOBAL.tasksequenceval = "0",
		GLOBAL.tasksequencetypeval = "",
		GLOBAL.taskstatusval = "0",
		GLOBAL.taskassignmentgroupval = "0",
		GLOBAL.taskassignementuserval = "0",
		GLOBAL.taskinfoval = "",
		GLOBAL.taskTypeId = "",
		this.getData = this.getData.bind(this);
		this.onGroupNameChange = this.onGroupNameChange.bind(this);
		this.onGroupSelected = this.onGroupSelected.bind(this);
		this.setErrorColor = this.setErrorColor.bind(this);  
		this.onCrossClick = this.onCrossClick.bind(this);
		this.resetAssignedToDetails = this.resetAssignedToDetails.bind(this);
		this.resetFields = this.resetFields.bind(this);
		this.setEventErrorColor = this.setEventErrorColor.bind(this);
	}

	setEventErrorColor(ev){
		if(!ev?.target?.value || ev.target.value == "0"){
			this.setState({ eventErrorColor: "error" })
		} else {
			this.setState({ eventErrorColor: "" })
		}
	}

    getData(e) {
		if(e.target.id == "taskTypeId"){
		GLOBAL.taskTypeId = e.target.value
		this.setState({ taskType: e.target[e.target.selectedIndex].text });}
		if (e.target.id == "description"){
			GLOBAL.taskdescriptionval = e.target.value
			this.setState({additionalSummaaryCharLeft: 4000-e.target.value.length});
		}
		if (e.target.id == "sequence")
			GLOBAL.tasksequenceval = e.target.value
		if (e.target.id == "status")
			GLOBAL.taskstatusval = e.target.value
		if (e.target.id == "supportPIndividual") {
			GLOBAL.taskassignementuserval = e.target.value;
			this.setState({ assignedToUser: e.target[e.target.selectedIndex].text==='Select' ? '':e.target[e.target.selectedIndex].text});
		}
		if (e.target.id == "information")
			GLOBAL.taskinfoval = e.target.value
	}
    componentWillMount() {
		let getBreanFixkItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") : ""
		let breakFixActualItemId = getBreanFixkItemId[1];
		const modules = {
			Breakfix: "Incident",
			Fullfilment: "Fulfillment",
			Investigation: "Problem",
			ChangeManagement: "Change"
		}
		const getModule = modules[this.props.module];

		let config = {
			headers: { 
			  'query' : JSON.stringify({module: getModule, field1_name: "event"})
			}
		  }
		axios.get('/api/getmenulist', config).then((resp) => {
			const options = resp?.data.map((option) => {
				return {
					label: option.field1_key,
					value: option.field1_value
				}
			})
			this.setState({ eventList: options})
		})
		if (this.props.module == "Breakfix") {
			this.props.loadBreakFixTaskLogDetails(breakFixActualItemId, "Breakfix", "asc", this.props.breakFixEditDetailsUpdate.consumerCompany);
			this.props.getTasktype('ADHOC_TASK', "Incident");

		}
		if (this.props.module == "Fullfilment") {
			this.props.getTasktype('ADHOC_TASK', "Fulfillment")
			this.props.loadBreakFixTaskLogDetails(this.props.itemId, "Fullfilment", "asc", this.props.fulfillmentDetails.companyId);
		}
		if (this.props.module == "ChangeManagement") {
			// this.props.getTasktype('ADHOC_TASK', "Change")
			this.props.loadBreakFixTaskLogDetails(this.props.itemId, "ChangeManagement", "asc", this.props.editChangeInitialData.consumerCompany);
			axios.get('/api/company_menulist?companyId='+(this.props.editChangeInitialData.consumerCompany)+'&module=Change&field=Task Type&language='+languageSelected+'&status=1').then((responseDropdownValues)=>{
				const oldUrgencyList1 = []
				responseDropdownValues.data.map((item)=>{
						oldUrgencyList1.push(item);
						this.setState({oldUrgencyList: oldUrgencyList1})
				});
				}); 
		}

		if (this.props.module == "Investigation") {
			let serviceId = this.props.investigationdetail.serviceId;
			let consumerCompany = this.props.investigationdetail.consumerCompany;
			this.props.getTasktype('ADHOC_TASK', "Problem");
			this.props.loadBreakFixTaskLogDetails(this.props.itemId, "Investigation", "asc", this.props.investigationdetail.consumerCompany);
		}
		if(typeof this.props.roleIdentification == 'function'){
			if( this.props.investigationdetail && Object.keys(this.props.investigationdetail).length > 0 && this.props.investigationdetail.status_id && (this.props.investigationdetail.status_id == problemStatusMapping['Under Investigation'] || this.props.investigationdetail.status_id == problemStatusMapping['Root Cause Identified']) && this.props.roleIdentification('53')){
			 this.setState({hideTaskForm: false});
			}else if(!(this.props.roleIdentification('17')||
		     this.props.roleIdentification('16')|| 
			 this.props.roleIdentification('19')||
		     this.props.roleIdentification('20')||
		     this.props.roleIdentification('51')||
		     this.props.roleIdentification('52'))){
             this.setState({hideTaskForm: this.props.roleIdentification('48')});
		    }
	    }
	}
    componentWillReceiveProps(nextProps) {
		if(!this.state.isFetchedGroupList){
			if(this.props.module == "Breakfix"){
				this.props.loadTaskGroup2(nextProps.breakFixEditDetailsUpdate.consumerCompany);
				this.setState({isFetchedGroupList: true});
			}
			if (this.props.module == "Fullfilment") {
				this.props.loadTaskGroup2(nextProps.fulfillmentDetails.companyId);
				this.setState({isFetchedGroupList: true});
			}
			if (this.props.module == "Investigation") {
				this.props.loadTaskGroup2(nextProps.investigationdetail.consumerCompany);
				this.setState({isFetchedGroupList: true});
			}
			if (this.props.module == "ChangeManagement") {
				this.props.loadTaskGroup2(nextProps.editChangeInitialData.consumerCompany);
				this.setState({isFetchedGroupList: true});
			}
		}
    }
    onEditTask(taskcode, regid) {
		GLOBAL.taskcode = taskcode;
		GLOBAL.taskregid = regid
		this.props.clicktaskEdit("1", taskcode, regid);
	}
    renderSupportPIGroup(breakFixData) {
		if (!breakFixData || breakFixData.length == 0) {
			return (
				null
			);
		}
		return breakFixData.map((breakFixObj, index) => {
			return (
				<option title={breakFixObj.field1Key} value={breakFixObj.field1Value}>{breakFixObj.field1Key.length > 50 ? (breakFixObj.field1Key.slice(0,50) + "...") : breakFixObj.field1Key}</option>
			);
		});
	}
    renderTasktype(dropdownOptions) {
		if (!dropdownOptions) {
			return <option></option>;
		  }
		  return dropdownOptions.map((option) => {
			return <option value={option.field1Value}>{option.field1Key}</option>;
		  });
	}
    renderAttList(attributeData) {
		if (!attributeData) {
			return (null);
		}
		return attributeData.map((taskObj, index) => {
			const tooltip = (<Popover className=""><TaskDetailsPopup taskObj={taskObj} disableFieldsForGuestRole={this.props.disableFieldsForGuestRole}  /></Popover>);
			let finalDate = taskObj.createdDate;
			return (
				<tr>
					{/* <td><Link to={"/editTask/" + taskObj.taskCode + "/" + taskObj.clientId + "/" + taskObj.assignedToGroupId}>{taskObj.taskCode}</Link></td> */}
					<td><span className='nowrap'>{this.props.isTaskRoleEnabled?<Link to={"/editTask/" + taskObj.taskCode + "/" + taskObj.clientId + "/" + taskObj.assignedToGroupId}>{taskObj.taskCode}</Link>:taskObj.taskCode}<OverlayTrigger trigger="click" rootClose placement={this.props.windowSize < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
} overlay={tooltip}><span title='Info' className='cursorPoint'><ImInfo className='taskInfoicn'/></span></OverlayTrigger></span></td>
					<td>{taskObj.sequenceNumber}</td>
					<td>{taskObj.status.status}</td>
					<td>{taskObj.assignedTo}</td>
					<td>{/*{finalDate}*/}{taskObj.expectedDueDate}</td>
				</tr>
			);
		});
	}
    renderTaskData(taskData) {
		if (this.props.showLoaderRelatedTask) {
			return (<div><ListLoader /></div>);
		}
		else if (taskData.length == 0) {
			return (
				<div>{this.props.translator['Data not available.']}</div>
			);
		}
		else {
			return (
				<Table responsive striped bordered condensed hover className='f-size-13'>
					<thead>
						<tr className='f-size-12'>
							<th>{this.props.translator['Task Code']}</th>
							<th>{this.props.translator['Task Sequence']}</th>
							<th>{this.props.translator['Status']}</th>
							<th>{this.props.translator['Fulfiller']}</th>
							<th>{this.props.translator['Expected Completion']}</th>
						</tr>
					</thead>
					<tbody>
						{this.renderAttList(taskData)}
					</tbody>
				</Table>
			);
		}
	}
    descriptionCharacter(e) {
		let input = e.target.value.length;
		let additionalchar = 500 - input;
		this.setState({additionalcharleft: additionalchar});
	}

	resetFields() {
				
			this.setState({
				taskName: "",
				assignedToUser: "",
				isExternal: "",
				additionalcharleft: 500,
				additionalSummaaryCharLeft: 4000,
				selectedGroup: [],      
				typedGroupName: "",
				taskType: "",
				eventName: "",
				eventId: "0",       
			});
		
			console.log("State after reset:", this.state);
		
			GLOBAL.taskdescriptionval = "";
			GLOBAL.tasksequenceval = "0";
			GLOBAL.tasksequencetypeval = "";
			GLOBAL.taskstatusval = "0";
			GLOBAL.taskassignmentgroupval = "0";
			GLOBAL.taskassignementuserval = "0";
			GLOBAL.taskinfoval = "";
			GLOBAL.taskTypeId = "";
		
		
			if (this.inputDescription) {
				this.inputDescription.value = "";
			}
			if (this.inputSequence) {
				this.inputSequence.value = "";
			}
			if (this.inputSequenceType) {
				this.inputSequenceType.value = "0"; 
			}
			if (this.inputStatus) {
				this.inputStatus.value = "1";
			}
			if (this.inputGroup) {
				this.inputGroup.value = "0"; 
			}
			if (this.inputGroupInd) {
				this.inputGroupInd.value = "0"; 
			}
			if (this.inputAddInfo) {
				this.inputAddInfo.value = "";
			}
		
			if (this.typeaheadComponent) {
				if (typeof this.typeaheadComponent.clear === "function") {
					this.typeaheadComponent.clear();
				} else {
					this.setState({ selectedGroup: [] });
				}
			}
		}

    postTaskData() {
		let ref = this;
		if (GLOBAL.tasksequenceval <= 0 || GLOBAL.tasksequenceval > this.state.maxSequenceNumber) {
			const messageToShow = `${this.props.translator['The sequence value must be positive integer and less than']} ${this.state.maxSequenceNumber}`;
			Swal.fire(messageToShow);
		}
		else if (this.state.selectedGroup.length === 0) {
			Swal.fire(this.props.translator['Please select Assignment Group']);
			
		}
		else if (GLOBAL.taskTypeId == 0) {
			Swal.fire(this.props.translator['Please select Task Type']);
		} else if(!this.state.eventId || this.state.eventId == "0"){
			this.setState({eventErrorColor: "error" });
			Swal.fire(this.props.translator['Please select Task Model']);
		}
		else {
			this.setState({ showLoader: true });
			let module = this.props.module;
			let actualidtosave = "";
			let clientid = "";
			let companyName = "";
			let ref = this;
			let serviceid = "";
			let servicename = "";
			let parentWorkItemId = "";
			let ciId = "";
			let ciName = "";
			let serviceBased = "";
			let serviceCompanyId = "";
			let serviceCompanyName = "";
			let classId = "";
			let className = "";
			if (module == "Fullfilment") {
				actualidtosave = this.props.fulfillmentDetails.itemId;
				clientid = this.props.fulfillmentDetails.companyId;
				companyName = this.props.fulfillmentDetails.consumerCompanyName;
				serviceid = this.props.fulfillmentDetails.offeringIdSx;
				servicename = this.props.fulfillmentDetails.offeringNameSx
				parentWorkItemId = this.props.fulfillmentDetails.itemNumber;
				serviceCompanyId = this.props.fulfillmentDetails.serviceCompanyId;
				serviceCompanyName = this.props.fulfillmentDetails.serviceCompanyName;
				serviceBased = true;
			}
			if (module == "ChangeManagement") {
				actualidtosave = this.props.editChangeInitialData.changeId;
				clientid = this.props.editChangeInitialData.consumerCompany;
				companyName = this.props.editChangeInitialData.consumerCompanyName;
				serviceid = this.props.editChangeInitialData.serviceId;
				servicename = this.props.editChangeInitialData.serviceName;
				parentWorkItemId = this.props.editChangeInitialData.changeNumber;
				ciId = this.props.editChangeInitialData.ciId;
				ciName = this.props.editChangeInitialData.ciName;
				serviceBased = this.props.editChangeInitialData.serviceBased;
				serviceCompanyId = this.props.editChangeInitialData.serviceCompanyId;
				serviceCompanyName = this.props.editChangeInitialData.serviceCompanyName;
				classId = this.props.editChangeInitialData.ciClassId;
				className = this.props.editChangeInitialData.ciClassName;
			}
			if (module == "Investigation") {
				let serviceId = this.props.investigationdetail.serviceId;
				let consumerCompany = this.props.investigationdetail.consumerCompany;
				actualidtosave = this.props.investigationdetail.investigationId;
				clientid = consumerCompany;
				companyName = this.props.investigationdetail.consumerCompanyName;
				serviceid = serviceId;
				servicename = this.props.investigationdetail.serviceName;
				parentWorkItemId = this.props.investigationdetail.investigationNumber;
				ciId = this.props.investigationdetail.ciId;
				ciName = this.props.investigationdetail.ciName;
				serviceBased = this.props.investigationdetail.serviceBased;
				serviceCompanyId = this.props.investigationdetail.serviceCompanyId;
				serviceCompanyName = this.props.investigationdetail.serviceCompanyName;
			}
			if (module == "Breakfix") {
				actualidtosave = this.props.breakFixEditDetails.breakfixId;
				clientid = this.props.breakFixEditDetails.consumerCompany;
				companyName = this.props.breakFixEditDetails.consumerCompanyName;
				serviceid = this.props.breakFixEditDetails.serviceId;
				servicename = this.props.breakFixEditDetails.serviceName;
				parentWorkItemId = this.props.breakFixEditDetails.breakfixNumber;
				ciId = this.props.breakFixEditDetails.ciId;
				ciName = this.props.breakFixEditDetails.ciName;
				serviceBased = this.props.breakFixEditDetails.serviceBased;
				serviceCompanyId = this.props.breakFixEditDetails.serviceCompanyId;
				serviceCompanyName = this.props.breakFixEditDetails.serviceCompanyName;
			}
			if (GLOBAL.taskdescriptionval.trim().length == 0) {
				Swal.fire(this.props.translator['Please enter Summary']);
				this.setState({ showLoader: false });
				return false;
			}
			let postData = {
				"assignedToId": GLOBAL.taskassignementuserval,
				"assignedToGroupId": ref.state.selectedGroup?.[0].id,
				"description": GLOBAL.taskdescriptionval,
				"sequenceNumber": GLOBAL.tasksequenceval,
				"workItemId": actualidtosave,
				"parentWorkItemId": parentWorkItemId,
				"additionalParams": GLOBAL.taskinfoval,
				"statusId": '1',
				"statusName": "Assigned",
				"workItemType": module,
				"applicationName": "XSM",
				"moduleName": module,
				"clientId": clientid,
				"assignedTo": ref.state.assignedToUser,
				"taskTypeId": GLOBAL.taskTypeId,
				"taskType": ref.state.taskType,
				"assignedToGroup": ref.state.selectedGroup?.[0].label,
				"requesterId": "",
				"requesterName": "",
				"serviceId": serviceid,
				"serviceName": servicename,
				"createdBy": "",
				"companyName": companyName,
				"ciId": ciId,
				"ciName": ciName,
				"serviceBased": serviceBased,
				"isExternalFulfilled": this.state.isExternal,
				"serviceCompanyId": serviceCompanyId,
				"serviceCompanyName": serviceCompanyName,
				"supportCompanyId": this.state.supportCompanyID,
				"supportCompanyName": this.state.supportCompanyName,
				"className": (module== "Breakfix" || module == "Investigation" || module == "ChangeManagement") ? className : null,
				"classId": (module== "Breakfix" || module == "Investigation" || module == "ChangeManagement") ? classId : null,
				"eventName": this.state.eventName,
				"eventId": this.state.eventId
			};

			console.log("editChangeInitialData79",this.props.editChangeInitialData);

			if (module == "ChangeManagement") {
				let jwtdf = dateformat.slice(0, 10).toUpperCase();
				let jwtdf1 = jwtdf + ' HH:mm:ss';
				if (this.props.editChangeInitialData.expectedStartDate && this.props.editChangeInitialData.expectedStartDate != '') {
					let expectedStartDate = moment.tz(this.props.editChangeInitialData.expectedStartDate, jwtdf1,timezone).format('YYYY-MM-DD HH:mm:ss');
					console.log("expectedStartDate",expectedStartDate);
					postData.expectedStartDate = expectedStartDate;
				}
				if (this.props.editChangeInitialData.expectedEndDate && this.props.editChangeInitialData.expectedEndDate != '') {
					let expectedEndDate = moment.tz(this.props.editChangeInitialData.expectedEndDate, jwtdf1,timezone).format('YYYY-MM-DD HH:mm:ss');
					console.log("expectedEndDate",expectedEndDate);
					postData.expectedDueDate = expectedEndDate;
				}
			}
			console.log("postData",postData);
			axios.post(GLOBAL.taskurl, postData, {headers: {"X-IBM-Client-Id": "5a78384c-5316-42ad-a23e-56bc2eb76d7d"}}).then((response) => {
					let taskId = response.data.taskCode.substring(3);
					let taskDescription = response.data.description;
					ref.props.checkRelatedTaskForItem(response.data.workItemId, response.data.workItemType)
					if (module == "Breakfix") {
						this.setState({ showLoader: false });								
						this.props.loadBreakFixTaskLogDetails(actualidtosave, "Breakfix", "asc", this.props.breakFixEditDetails.consumerCompany);
						this.inputDescription.value = "";
						this.inputSequence.value = "";
						this.inputSequenceType.value = "0";
						this.inputStatus.value = "1";
						this.inputGroup.value = "0";
						this.inputGroupInd.value = "0";
						this.inputAddInfo.value = "";
					}
					if (module == "Fullfilment") {
						this.setState({ showLoader: false });
						this.props.loadBreakFixTaskLogDetails(actualidtosave, "Fullfilment", "asc", this.props.fulfillmentDetails.companyId);
						this.inputDescription.value = "";
						this.inputAddInfo.value = "";
						this.inputSequence.value = "";
						this.inputSequenceType.value = "0";
						this.inputStatus.value = "1";
						this.inputGroup.value = "0";
						this.inputGroupInd.value = "0";
					}
					if (module == "ChangeManagement") {
						this.setState({ showLoader: false });
						this.props.loadBreakFixTaskLogDetails(actualidtosave, "ChangeManagement", "asc", this.props.editChangeInitialData.consumerCompany);
						this.inputDescription.value = "";
						this.inputAddInfo.value = "";
						this.inputSequence.value = "";
						this.inputSequenceType.value = "0";
						this.inputStatus.value = "1";
						this.inputGroup.value = "0";
						this.inputGroupInd.value = "0";
					}
					if (module == "Investigation") {
						this.setState({ showLoader: false });
						this.props.loadBreakFixTaskLogDetails(actualidtosave, "Investigation", "asc", this.props.investigationdetail.consumerCompany);
						this.inputDescription.value = "";
						this.inputAddInfo.value = "";
						this.inputSequence.value = "";
						this.inputSequenceType.value = "0";
						this.inputStatus.value = "1";
						this.inputGroup.value = "0";
						this.inputGroupInd.value = "0";
					}
				}).catch(() => {
					this.setState({ showLoader: false });
				}).finally(() => {
					this.resetFields();
				});
		}
	}
    asctasksort() {
		let getBreanFixkItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") : ""
		let breakFixActualItemId = getBreanFixkItemId[1];
		if (this.props.module == "Breakfix") {
			this.props.loadBreakFixTaskLogDetails(breakFixActualItemId, "Breakfix", "asc", this.props.breakFixEditDetails.consumerCompany);
		}
		if (this.props.module == "Fullfilment") {
			this.props.loadBreakFixTaskLogDetails(this.props.itemId, "Fullfilment", "asc", this.props.fulfillmentDetails.companyId);
		}
		if (this.props.module == "ChangeManagement") {
			this.props.loadBreakFixTaskLogDetails(this.props.itemId, "ChangeManagement", "asc", this.props.editChangeInitialData.consumerCompany);
		}
		if (this.props.module == "Investigation") {
			this.props.loadBreakFixTaskLogDetails(this.props.itemId, "Investigation", "asc", this.props.investigationdetail.consumerCompany);
		}
	}
    desctasksort() {
		let getBreanFixkItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") : ""
		let breakFixActualItemId = getBreanFixkItemId[1];
		if (this.props.module == "Breakfix") {
			this.props.loadBreakFixTaskLogDetails(breakFixActualItemId, "Breakfix", "desc", this.props.breakFixEditDetails.consumerCompany);
		}
		if (this.props.module == "Fullfilment") {
			this.props.loadBreakFixTaskLogDetails(this.props.itemId, "Fullfilment", "desc", this.props.fulfillmentDetails.companyId);
		}
		if (this.props.module == "ChangeManagement") {
			this.props.loadBreakFixTaskLogDetails(this.props.itemId, "ChangeManagement", "desc", this.props.editChangeInitialData.consumerCompany);
		}
		if (this.props.module == "Investigation") {
			this.props.loadBreakFixTaskLogDetails(this.props.itemId, "Investigation", "desc", this.props.investigationdetail.consumerCompany);
		}
	}
	onGroupNameChange(groupName){
		this.setState({typedGroupName: groupName, selectedGroup: []});
		this.resetAssignedToDetails();
	}
	onGroupSelected(selectedGroup){
		  if(selectedGroup.length > 0){
			this.props.loadAsignedTo(selectedGroup[0].id);
			this.resetAssignedToDetails(); 
			this.setState({
				selectedGroup: selectedGroup,
				typedGroupName: '',
				isExternal: selectedGroup[0].isExternal,
				supportCompanyName: selectedGroup[0].supportCompanyName,
				supportCompanyID: selectedGroup[0].supportCompanyID
			});
			return;
		  }
		  this.setState({selectedGroup: []});
	}
	setErrorColor(){
		if(this.state.selectedGroup.length === 0){
			return;
		}
	}
	onCrossClick(){
		this.resetAssignedToDetails();
		this.props.loadAsignedTo(''); 	
		this.setState({selectedGroup: [], typedGroupName: ''});
	} 
	resetAssignedToDetails(){
		GLOBAL.taskassignementuserval = 0; 
		this.setState({ assignedToUser: '' });
	}
    render(){
		const extractedGroups = this.props.taskGroupData2.map(groupData => {
			return { id: groupData.groupId, label: groupData.groupName, supportCompanyName: groupData.companyName, isExternal: groupData.isExternal, supportCompanyID: groupData.companyId };
		});
        return (
			<div>
				<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                    <ul>
                        <li>
                        <button
						    type='button'
                            title="Minimize the right panel"
                            bsClass=""
                            bsStyle=""
                            className="closerightPanelBtn"
                            onClick={() => {
                            this.props.rightEditPanel(false);
							this.props.changeActionView("");
                            }}
                        >
                            <IoClose/>
                        </button>
                        </li>
                    </ul>
                </div>
				<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator['Tasks']}</div></div>
				<div className='rBoxGap'>
					{(
						(this.props && this.props.fieldStatus && this.props.fieldStatus.tasks == false)
						||
						(this.state.hideTaskForm)
						||
						this.props.disableFieldsForGuestRole
					) ?null:
						<div className="">
							<Row>
								<Col md={12}>
                                    <Form.Group className="margin-b-5">
                                        <span className="rStar" />{" "}
                                        <Form.Label>{this.props.translator['Summary']}</Form.Label>
                                        <Form.Control as="textarea" id="description" maxLength="4000" rows="2" ref={(input) => this.inputDescription = input} onChange={(event) => { this.getData(event) }}/></Form.Group>
										<p className="charLeft" style={{ textAlign: "right", "fontSize": "11px" }}>({this.props.translator['Characters Remaining']}: {this.state.additionalSummaaryCharLeft}/4000)</p>
                                    </Col>
								<Col md={6}>
									<Form.Group className="margin-b-5">
										<span className="rStar" />{" "}
										<Form.Label>{this.props.translator['Task Type']}</Form.Label>
										<Form.Select name="tasktype" id="taskTypeId" as="select" ref={(input) => this.inputGroup = input} onChange={(event) => { this.getData(event) }}>
											<option value="0">Select</option>
											{this.renderTasktype(this.state.oldUrgencyList)}
										</Form.Select>
									</Form.Group>
								</Col>
								<Col md={6}><Form.Group className="margin-b-5"><span className="rStar" />{" "}
									<Form.Label>{this.props.translator['Sequence']}</Form.Label>
									<Form.Control type="number" name="sequence" id="sequence" min={this.state.minSequenceNumber} max={this.state.maxSequenceNumber} ref={(input) => this.inputSequence = input} onChange={(event) => { this.getData(event) }}>
									</Form.Control>
								</Form.Group></Col>
							</Row>
							<Row>
								<Col md={6}>
									<Form.Group className="margin-b-5">
										<span className="rStar" />{" "}
										<Form.Label>{this.props.translator['Assignment Group']}</Form.Label>
										<TypeaheadExampleSingleSelect
                                                    id="supportPGroup"
                                                    name="supportPGroup"
													ref={(typeahead) => (this.typeaheadComponent = typeahead)}
                                                    onSelection={this.onGroupSelected}
                                                    setErrorColor={this.setErrorColor}
                                                    options={extractedGroups}
                                                    selectedOptions={this.state.selectedGroup}
                                                    onInputChange={this.onGroupNameChange}
                                                    onCrossClick={this.onCrossClick}
                                                    typedValue={this.state.typedGroupName}
                                                    errorClass={''} />
									</Form.Group>
								</Col>
								<Col md={6}><Form.Group className="margin-b-5">
									<Form.Label>{this.props.translator['Assigned To']}</Form.Label>
									<Form.Select as="select" id="supportPIndividual" className="tskSupportDropdown" name="supportPIndividual" ref={(input) => this.inputGroupInd = input} onChange={(event) => { this.getData(event) }}>
										<option value="0">Select</option>
										{this.renderSupportPIGroup(this.props.taskAssignedToData)}
									</Form.Select>
								</Form.Group>
								</Col>
								{/* Task Model field starts */}
								<Col md={6}>
									<Form.Group className="form-group margin-b-5">
										<span className="rStar" />
										<Form.Label>{this.props.translator['Task Model']}</Form.Label>
										<Form.Select
											onBlur={this.setEventErrorColor}
											aria-label="select"
											id="event"
											className={`tskSupportDropdown ${this.state.eventErrorColor}`}
											name="event"
											value={this.state.eventId}
											onChange={(e) => {
												const selectedIndex = e.target.selectedIndex;
    											const selectedEvent = e.target.options[selectedIndex].text;
												if(e.target.value && e.target.value != "0"){
													this.setState({eventErrorColor: "", eventId: e.target.value, eventName: selectedEvent})
												} else {
													this.setState({eventErrorColor: "error", eventId: "0", eventName: ""})
												}
											}}
										>
											<option value="0">Select</option>
											{this.state.eventList.map((op, idx) => <option key={idx} value={op.value}>{op.label}</option>)}
										</Form.Select>
									</Form.Group>
								</Col>
								{/* Task Model field end */}
								<Col md={12}><Form.Group className="margin-b-5">
									<Form.Label>{this.props.translator['Additional Information']}</Form.Label>
									<Form.Control as="textarea" maxLength="500" id="information" ref={(input) => this.inputAddInfo = input} rows="2" onChange={(event) => { this.getData(event); this.descriptionCharacter(event) }} />
									<p className="charLeft" style={{ textAlign: "right", "fontSize": "11px" }}>({this.props.translator['Characters Remaining']}: {this.state.additionalcharleft}/500)</p>
								</Form.Group></Col>
							</Row>
							<Row>
						</Row>
						<div className="black margin-t-5">
							<Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary" title={this.props.translator['Save']} disabled={this.state.showLoader} onClick={this.postTaskData.bind(this)}>
									{this.state.showLoader ? <ImSpinner6 className="icn-spinner"/> : null} {this.props.translator['Save']}</Button>
						</div>
						</div>
					}
					<div className="margin-t-5 padding-b-5 border-b"><span className="float-r f-size-17"><a onClick={this.asctasksort.bind(this)} className="black" title={this.props.translator['Ascending']}><i className="fa fa-angle-up"></i></a>&nbsp;&nbsp;<a onClick={this.desctasksort.bind(this)} className="black" title={this.props.translator['Descending']}><i className="fa fa-angle-down" ></i></a></span></div>
					<div className="clearboth">{this.renderTaskData(this.props.breakFixTaskLogDetails)}</div>

				</div>
			</div>
		);
    }
}
export function mapStateToProps({ taskAssignedToData, breakFixEditDetails, breakFixSGName, breakFixSGIName,
	breakFixTaskLogDetails, fulfillmentDetails, breakFixSGAETaskName, investigationItemDetails,
	editChangeInitialData, taskGroupData2, showLoader, showLoaderRelatedTask, taskTypeList, windowSize }) {
	return {
		taskAssignedToData, taskGroupData2, breakFixEditDetails, breakFixSGName, breakFixSGIName,
		breakFixTaskLogDetails, fulfillmentDetails, breakFixSGAETaskName, investigationItemDetails,
		editChangeInitialData, showLoader: showLoader.loading, showLoaderRelatedTask: showLoaderRelatedTask.loading, taskTypeList, windowSize : windowSize.width
	};
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({ checkRelatedTaskForItem, getInvestigationItemDetailsUpdate, loadAsignedTo, loadBreakFixEditDetails, loadBreakFixEditDetailsUpdate, loadBreakFixSG, loadBreakFixSGI, loadBreakFixTaskLogDetails, loadFulfillmentDetails, loadBreakFixSGAETask, getInvestigationItemDetails, loadChangeEditData, loadTaskGroup, loadTaskGroup2, getTasktype }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(PopoverTasksData)