
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from "react";
import { ButtonToolbar, Button, ListGroup, Table } from "react-bootstrap";
import Dropzone from "react-dropzone";
import axios from "axios";
import { connect } from "react-redux";
import { validateTaskField } from "../../../../utils/common";
import { loadTmsList } from "../../../../actions/common/attachmentAction";
import _ from "lodash";
import moment from "moment";
import configureStore from "../../../../store/configureStore";
const store = configureStore();
import Cookies from "universal-cookie";
const Joi = require("joi");
import datetimeConvertor from "../../../../ISO8601converter";
import swal from "sweetalert";
import { fileobjectGenerator, validateFile } from "../../../common/MIMEObjGenerator";
import { PiEyeBold } from "react-icons/pi";
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";

const cookies = new Cookies();

let attachmentDetails = cookies.get("gph");
if (attachmentDetails) attachmentDetails = attachmentDetails.replace("s:", "");
if (attachmentDetails)
  attachmentDetails = attachmentDetails.substring(
    0,
    attachmentDetails.lastIndexOf(".")
  );
attachmentDetails = attachmentDetails.split("~");
const attachmentSize = attachmentDetails[21];
const attachmentType = fileobjectGenerator(attachmentDetails[22].split(', '));

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
//console.log("homepage::::"+homepagelocation);
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
timezone = timezone.split("~");
timezone = timezone[3];
class UploadScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filesPreview: [],
      filesToBeSent: [],
      printcount: 1,
      clickAttachButton: 0,
    };
    this.deleteFileAction = this.deleteFileAction.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }
  componentWillMount() {
    let itemId;
    let module;
    if (_.has(this.props.itemDetails, "breakfixNumber")) {
      module = "Breakfix";
      itemId = this.props.itemDetails.breakfixId;
    } else if (_.has(this.props.itemDetails, "changeNumber")) {
      module = "ChangeManagement";
      itemId = this.props.itemDetails.changeId;
    } else if (_.has(this.props.itemDetails, "investigationNumber")) {
      module = "Investigation";
      itemId = this.props.itemDetails.investigationId;
    } else if (_.has(this.props.itemDetails, "itemNumber")) {
      module = "Item";
      itemId = this.props.itemDetails.itemId;
    }
    this.props.loadTmsList(this.props.taskDetails.taskCode);
  }

  removeFile() {
    this.setState({ filesToBeSent: [], filesPreview: [] });
  }
  onDrop(acceptedFiles, rejectedFiles) {
    let filesToBeSent = this.state.filesToBeSent;
    if (acceptedFiles.length > 0) {
      if (filesToBeSent.length < this.state.printcount) {
        filesToBeSent.push(acceptedFiles);
        let filesPreview = [];
        for (let i in filesToBeSent) {
          if (filesToBeSent.hasOwnProperty(i)) {
            console.log(filesToBeSent);
            console.log(filesToBeSent[i][0]);
            filesPreview.push(
              <ListGroup.Item>
                <span className="float-r"
                    onClick={() => {
                      this.removeFile();
                    }}
                  ><IoClose />
                </span>
                {filesToBeSent[i][0].name}
              </ListGroup.Item>
            );
          }
        }

        this.setState({ filesToBeSent, filesPreview });
      } else {
        swal(this.props.translator["File already attached"]);
      }
    } else {

    }
  }

  onDropRejected(rejectedFiles) {
    if (rejectedFiles && rejectedFiles[0] && rejectedFiles[0].file && rejectedFiles[0].file.size && rejectedFiles[0].file.size > 1048576 * attachmentSize) {
      swal(
        this.props.translator["Please upload File up to "] +
          attachmentSize +
          this.props.translator[" MB size."]
      );
    } else {
      swal("File not supported.");
    }
  }

  handleClick(event, itemDetails) {
    let self = this;
    if (this.state.filesPreview.length > 0) {
      self.setState({
        clickAttachButton: 1,
      });
    }

    if (this.state.filesToBeSent.length > 0) {
      let formData = new FormData();
      formData.append("file", this.state.filesToBeSent[0][0]);
      let jsonObject = new Object();
      jsonObject.taskCode = this.props.taskDetails.taskCode;
      jsonObject.createdBy = "";
      jsonObject.createdByName = "";
      jsonObject.modifiedBy = "";
      jsonObject.modifiedByName = "";
      jsonObject.status = "Y";
      store.dispatch({ type: "LOAD_IMPLPLAN_SUCCESS", implPlanList: {} });
      formData.append("attachment", JSON.stringify(jsonObject));

      axios
        .post("/task/api/uploadAttach", formData)
        .then((response) => {
          if (response.status == 200) {
            this.props.loadTmsList(jsonObject.taskCode);
            swal("file uploaded successfully.");
            this.setState({
              filesToBeSent: [],
              filesPreview: [],
              clickAttachButton: 0,
            });
          }
        })
        .catch((error) => {
          swal(
            this.props.translator["Please upload File up to "] +
              attachmentSize +
              this.props.translator[" MB size."]
          );
          console.log("error in uploading file.");
          this.setState({ clickAttachButton: 0 });
        });
    } else {
      swal("Please upload some files first");
    }
  }

  deleteFileAction(attachmentId) {
    let ref = this;

    axios
      .put("/attachment/api/deleteTmsFile/" + attachmentId, {})
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          swal("file removed successfully.");
          ref.props.loadTmsList(response.data.taskCode);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  renderAttachedItems(attachments) {
    console.log(attachments);
    if (_.isEmpty(attachments)) {
      return (
        <tr>
          <td colSpan={3} className="text-c">
            {this.props.translator["loading attachments..."]}
          </td>
        </tr>
      );
    }
    const isDeleteDisabled = (this.props.taskDetails.statusId == "3" || this.props.taskDetails.statusId == "4");

    return attachments.map((attachment) => {
      // const userDate = attachment.createdOn.slice(0, 10).toUpperCase();
      const isCreatedByLoggedInUser = this.props.user_id == attachment.createdBy;
      const userDate = moment(attachment.createdOn * 1000);
      const userpreDate = moment(userDate).format(this.props.date_format);
      console.log("userpreDate_00", userpreDate);

      const aa = moment(userDate).format("D");
      console.log("hello bhai", aa);

      const date1111 = moment(userDate);
      const dow = date1111.day();
      console.log("dow_00", dow);

      if (attachment.status !== "Y") {
        return null;
      } else {
        // console.log("else part has been called !!!!!")

        let attachfilename = attachment.fileName;
        if (attachfilename.indexOf("##") > -1)
          attachfilename = attachfilename.slice(15);

        let actDateTimeFormat = jwtdf + " hh:mm A";
        const dateIntoMillisec =  (attachment.createdOnEpoch &&  attachment.createdOnEpoch.toString().length == 10) ? attachment.createdOnEpoch * 1000 : attachment.createdOnEpoch

        let newDate = new Date(dateIntoMillisec).toLocaleString("en-US", {
          timeZone: timezone,
        });
        let myDateObj = moment(newDate).format(jwtdf + " HH:mm:ss");
        let isoDate = datetimeConvertor(myDateObj, jwtdf + " HH:mm:ss");
        myDateObj = moment(isoDate).format(actDateTimeFormat);

        let validated_url = undefined;
        // if (attachment != null || attachment.url != undefined) {
        if (attachment && attachment.url != "undefined") {
          let namedSchema = Joi.string().regex(/^[^<>}{]+$/);
          let validator_to_add_myQbj = namedSchema.validate(attachment.url);
          if (validator_to_add_myQbj.error == null) {
            validated_url = attachment.url;
          } else {
            console.log("Validation failed : ", validator_to_add_myQbj);
            validated_url = "\\";
          }
        } else validated_url = "\\";
        return (
          <tr bsClass="" key={attachment.attachmentId}>
            <td>
              <div>{attachfilename}</div>
              <div style={{ color: "rgb(0 0 0 / 50%)" }}>
                {attachment.createdByName}
              </div>
            </td>
            <td>{myDateObj}</td>
            <td className="text-c">
              <a className="margin-r-10" href={validated_url}>
                <PiEyeBold />
              </a>
              {!isCreatedByLoggedInUser || isDeleteDisabled || this.props.disableFieldsForGuestRole?'':<span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.deleteFileAction(
                    attachment.attachmentId,
                    attachment.module,
                    attachment.itemId
                  );
                }}
              ><IoClose /></span>}
            </td>
          </tr>
          /* <ListGroup.Item bsClass="" key={attachment.attachmentId}>{attachment.fileName} <span className="float-r"><a href={attachment.url} ><i className="fa fa-eye"></i></a>&nbsp;&nbsp;<i className="fa fa-times" style={{ cursor: "pointer" }} onClick={() => { this.deleteFileAction(attachment.attachmentId, attachment.module, attachment.itemId) }}></i></span></ListGroup.Item> */
        );
      }
    });
  }
  render() {
    let readOnlyResponse = "";
    if (
      this.props.role_id &&
      this.props.taskDetails.statusId &&
      this.props.taskDetails.statusId > 0
    ) {
      readOnlyResponse = validateTaskField(
        this.props.role_id,
        this.props.taskDetails.statusId
      );
      console.log(readOnlyResponse);
    }
    return (
      <div className="App">
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
          <ul>
            <li>
            <button
                type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                this.props.changeActionView("");
                }}
            >
                <IoClose />
            </button>
            </li>
          </ul>
        </div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {this.props.translator["Provide Attachments"]}
          </div>
        </div>
        <div className="rBoxGap">
          {this.props.disableFieldsForGuestRole||readOnlyResponse.attachedFile === true ? (
            ""
          ) : (
            <div>
              <div style={{ "text-align": "left" }}>
                <b>{this.props.translator["Upload your file."]}</b>{" "}
                {this.props.translator["(max size"]} {attachmentSize}{" "}
                {this.props.translator["MB)"]}
                {/* <br/> */}
                {/* <b>{this.props.translator['Supported file types:']}</b> .doc, .docx, .xls, .xlsx, .pdf, .jpeg, .jpg, .png, .ico, .xml, .tif, .msg, .zip, .rar, .txt, .csv, .ldif, .log, .sql */}
              </div>
              {/* <div className="margin-t-20">
                        <div className="border-b padding-b-5 margin-b-10 black f-size-16 fw-400"></div>
                    </div> */}
              <Dropzone
                onDrop={(files) => this.onDrop(files)}
                onDropRejected={(files) => this.onDropRejected(files)}
                maxSize={1048576 * attachmentSize}
                accept={attachmentType}
                validator={(file)=>{ return validateFile(file,this.props.translator);}}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <div className="text-c cursorPoint margin-t-5 margin-b-5 grayBg" style={{
                      width: "100%",
                      height: "60px",
                      "padding-top": "20px",
                      border: "1px solid black",
                      "border-top-color": "#bdc4c9",
                      "border-right-color": "#bdc4c9",
                      "border-bottom-color": "#bdc4c9",
                      "border-left-color": "#bdc4c9",
                    }}>
                      <input {...getInputProps()} />
                      {
                        this.props.translator[
                          "Drop files or click here to upload."
                        ]
                      }
                    </div>
                  </div>
                )}
              </Dropzone>
              {/* <span className="f-size-12" style={{ "marginTop": "4px", "display": "inline-block" }}><i>(<b>{this.props.translator['Supported file types:']}</b> {attachmentType})</i> </span> */}
              <div className="margin-t-20">
                {/* <div className="border-b padding-b-5 margin-b-10 black f-size-16 fw-400">{this.state.filesPreview.length > 0 ? this.props.translator['file attached'] : this.props.translator['file not attached']}</div> */}
                <div
                  style={{ "text-align": "left" }}
                  className="padding-b-5 margin-b-0 black f-size-16 fw-400"
                >
                  {this.state.filesPreview.length > 0
                    ? this.props.translator["file attached"]
                    : this.props.translator["No file attached"]}
                </div>
                <ListGroup className="attachment-break">
                  {this.state.filesPreview}
                </ListGroup>
              </div>
              <>
                {/*  <RaisedButton label="attach" className="btn btn-default" primary={true} style={style} onClick={(event) => this.handleClick(event, this.props.itemDetails)} />*/}

                <ButtonToolbar className="black margin-t-5">
                  <Button
                    className="rgSidrkBtn smallBtn"
                    bsSize="small"
                    bsStyle="primary"
                    disabled={this.state.clickAttachButton == 1 || this.props.disableFieldsForGuestRole}
                    onClick={(event) =>
                      this.handleClick(event, this.props.itemDetails)
                    }
                  >
                    {this.state.clickAttachButton == 1 ? (
                      <ImSpinner6 className="icn-spinner"/>
                    ) : null}{" "}
                    {this.props.translator["Attach"]}
                  </Button>
                </ButtonToolbar>

                {/* {this.state.clickAttachButton == 1 ? <Button type="button" disabled>{this.props.translator['Attach']}</Button> :
                        <Button type="button" onClick={(event) => this.handleClick(event, this.props.itemDetails)} >{this.props.translator['Attach']}</Button>} */}
              </>
              {/* <div className="margin-t-10">
                                <div className="border-b padding-b-5 margin-b-10 black f-size-16 fw-400"></div>
                            </div> */}
            </div>
          )}

          <div className="margin-t-20">
            <div className="border-b padding-b-5 margin-b-10 black f-size-16 fw-400">
              {this.props.translator["Attached Files"]}
            </div>
            {/* <Scrollbars style={{height:"300"}} hideTracksWhenNotNeeded={true} className="sideScrl" > */}
            <Table
              responsive
              striped
              bordered
              condensed
              hover
              className="f-size-13 wrpAttTab"
              style={{ marginBottom: "0px" }}
            >
              <thead>
                <tr>
                  <td width="60%">
                    Name & {this.props.translator["Linked By"]}
                  </td>
                  <td width="25%">Date & Time</td>
                  <td width="15%" className="text-c">
                    {this.props.translator["Action"]}
                  </td>
                </tr>
              </thead>
              <tbody>
                {this.renderAttachedItems(this.props.attachmentDetails)}
              </tbody>
            </Table>
            {/* </Scrollbars> */}
          </div>
        </div>
      </div>
    );
  }
}

const style = {
  margin: 15,
};
const mapStateToProps = ({ implememtationPlanList }) => {
  return {
    attachmentDetails: implememtationPlanList,
  };
};
export default connect(mapStateToProps, { loadTmsList })(UploadScreen);
