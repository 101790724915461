
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { IoSaveOutline, IoClose } from "react-icons/io5";
import { LuActivitySquare, LuFileText } from "react-icons/lu";
import { GrHostMaintenance } from "react-icons/gr";

import { MyContext } from '_MyContext';
import {GLOBAL} from '_Globals';

const Header = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const saveObjRef = useRef(null);

  const { formikref, isSubmitting, dirty, errors, submitForm } = props;

  const tr = useSelector((state) => state.spcmReducer.tr);
  const context = useContext(MyContext);
  const closeButton = useRef(null);

  useEffect(() => {
      context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
      context.newTabPrevent();
    }, []);

  const handleSubmit = () => {
    formikref.current?.submitForm();
  };
  const handleCancelClick = () => {
    event.preventDefault(); // Prevent the default behavior of the Link
    GLOBAL.ciActivetab = "tab2";
    navigate("/renderedView/674d2b3f6c6923cb55cf1645"); // Navigate programmatically
  };
 
  return (
    <Row className="margin-b-15">
      <Col md={7} sm={7} xs={7}>
        <h2 bsClass="" className="sPageHeading1">
          Maintenance Window
        </h2>
      </Col>
      <Col md={5} sm={5} xs={5} className="hidden-xs serviceHedRFltrMargn">
        <div className="paHedFilter">
   
          <Link
            to="javascript:void(0)"
            bsPrefix=" "
            id="creDeleSav"
            className="myBt plus ctrlKeyPrevent"
            title={tr["Save"]}
            onClick={handleSubmit}
            ref={saveObjRef}
          >
            <IoSaveOutline />
          </Link>
          <Link
           to="javascript:void(0)"           
            bsPrefix=" "
            className="myBt cancel fillBtn ctrlKeyPrevent"
            title={tr["Cancel"]}
            ref={closeButton}
            onClick={handleCancelClick}
          >
            <IoClose />
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default Header;
