
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { GLOBAL } from '_Globals';
import InvestigationEditHeader from './InvestigationEditHeader.js';
import XsmBreakFixFormTab from './editForm.js';
import LoadingIcons from 'react-loading-icons';
import QualifyAsProblemFormTab from './qualifyProblem.js';
import CancelProblemFormTab from './cancelProblem.js';
import InvestigationTeamFormTab from './investigationTeam.js';
import RecordInvestigationFindings from './RecordInvestigationFindings.js';
import ViewInvestigationFindings from './ViewInvestigationFindings.js';
import RootCauseFormTab from './speRootCause.js';
import CorrectiveRequiredFormTab from './correction.js';
import CorrectiveNotRequiredFormTab from './correctionNotRequired.js';
import RFCFormTab from './propose-rfc.js';
import AttachmentFormTab from '_Commons/WorkItemBoard/attachment.js';
import CloseFormTab from './close.js';
import CancelFormTab from './cancel.js';
import XSMNotify from './NotifyTab.js';
import XSMChange from './createInvestigationChange.js';
import XsmCiDetails from "_Commons/WorkItemBoard/xsm-ci-editquickView.js";
import XsmServiceDetails from '_Commons/WorkItemBoard/xsm-service-quickView.js';
import XsmUserDetails from '_Commons/WorkItemBoard/xsmEditRequestedBy.js';
import XSMBreakCausalRelation from '_Commons/WorkItemBoard/causalRelationship.js'
import {connect} from 'react-redux';
import { loadBreakFixAuditLogDetails } from '_Actions/breakFix/breakFixAuditLogAction';
import {getInvestigationItemDetailsById,changeFieldStatusData} from '_Actions/investigation/investigationAction.js';
import { getTranslation} from '_Actions/spcmActions';
import { loadStatusBasedHamburgerOptions, resetLoadHamburgerOptionsReducer } from '_Actions/common/loadStatusBasedHamburgerOptions';
import XSMSLA from './sla.js';
import XsmBreakFixExternalSystemAttributes from '_Commons/WorkItemBoard/xsm-breakFix-externalSystemAttribute.js';
import { loadExternalAttributesDetails } from '_Actions/breakFix/breakFixEditDetailsAction';
import XsmVendor from './specifyVendor';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
import TabPage from './investigationEdit-TabPage.js';
import  PopUpTask  from '_Commons/WorkItemBoard/breakfixTask';
import  PopUpCMDBRelatedWork  from './cmdbinvestigationRelatedWork';
import  PopUpRelatedWork  from './invRelatedWork';
import { getInvestigationTeamList } from '_Actions/investigation/investigationAction.js';
import CloseInvestigationComp from './closeInv.js';
import RootCauseDetails from './rootCauseDetails.js';
import { navigationHooks } from '../../../../helpers/NavigationHook';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "_Css/form/_form.scss";

import Swal from "sweetalert2";

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home")
	homepagelocation = "/" + homepagelocation;

let username = cookies.get('gph');
if (username) username = username.replace('s:', '');
if (username) username = username.substring(0, username.lastIndexOf('.'));
username = username.split("~");
username = username[32];

import WibSearchFulfillment from '_Commons/WorkItemBoard/wibSearch-fulfillment';
import { fetchWatcherDetails } from '../../../../actions/fulfillment/watcherActions.js';
import WatcherDetails from '../../../common/WorkItemBoard/watcher/WatcherDetails.js';
import TagDetails from '../../../common/tags/TagDetails.js';

import KnowledgeArticles from './KnowledgeArticles.js';
import { loadBreakFixActivityLogDetails } from "_Actions/breakFix/breakFixActivityLogAction";
import AvailableIndividuals from '../../../common/WorkItemBoard/availableIndividuals/AvailableIndividuals.js';

import { exportDoc } from './helper';
import { opsCategoryKeysMap, problemStatusMapping } from '../../../common/helper.js';

class InvestigationEdit extends React.Component {
	constructor(props) {
		super(props);
		this.state ={
			subMenu: '4',
			showHideAlfy:false,
			showHideRightNav:false,
			actionView:'',
			rootCauseActionClicked:'',
			mobileLeftMenuFilter:false,
			user_id:'',
			role_id:'',
			groupID:'',
		    roleID:'',
			changeImplentationRole:'',
			date_format:'',
			full_name:'',
			//specifyRootResponse:'',
            //responseMessage:'',
			showNotifyForm: true,
			unchangedData: null,
			userDetailsID: '',
			impactedCIID: '',
			problemManagerID: '',
			proManagerId:'',
			userDetailsLabel: '',
			urgencyListKeyValue:{},
			impactListKeyValue:{},
			isRightEditPanel: true,
			attachedArticlesList: [],
			selectedAssignmentGroup:[],
			selectedAssignmentUser:[],
			prevSelectedAction: "",
			aiSummaruzationEnabled: false,
			isProblemCalled: false,
		};
		this.showHideAlfy = this.showHideAlfy.bind(this);
		this.showHideRightNav = this.showHideRightNav.bind(this);
		this.changeActionView = this.changeActionView.bind(this);
		this.showRightPaneAction = this.showRightPaneAction.bind(this);
		this.showTimeline = this.showTimeline.bind(this);
		this.roleIdentification=this.roleIdentification.bind(this);
		//this.setForRootCause=this.setForRootCause.bind(this);
		this.loadHome = this.loadHome.bind(this);
		this.setUnchangedData = this.setUnchangedData.bind(this);
		this.checkDataChanged = this.checkDataChanged.bind(this);
		this.proManagerId = this.proManagerId.bind(this);
		this.clearUserDetails = this.clearUserDetails.bind(this);
		this.rightEditPanel=this.rightEditPanel.bind(this);
		this.RefreshScreen = this.RefreshScreen.bind(this);
		this.roleCalculatorForGuestUser = this.roleCalculatorForGuestUser.bind(this);
		this.createKnowledgeArticle = this.createKnowledgeArticle.bind(this);
		this.getKnowledgeArticles = this.getKnowledgeArticles.bind(this);
		this.postComment = this.postComment.bind(this);
		this.getRelateCIData = this.getRelateCIData.bind(this);
		this.getRelatedWorkItemData = this.getRelatedWorkItemData.bind(this);
		this.getRCATechnique = this.getRCATechnique.bind(this);
		this.getDependentData_KA = this.getDependentData_KA.bind(this);
		this.setAssignmentGrpUsr = this.setAssignmentGrpUsr.bind(this);
		this.exportDocument = this.exportDocument.bind(this);
		this.retireKnowledgeArticle = this.retireKnowledgeArticle.bind(this);
		// this.getDiscourseAdminUsername = this.getDiscourseAdminUsername.bind(this);
	}
	proManagerId(value){
		this.setState({proManagerId: value});
	}

	setAssignmentGrpUsr(selectd,type){
		switch(type){
		  case "user":
			this.setState({selectedAssignmentUser:selectd});
			break;
		  case  "group":
			this.setState({selectedAssignmentGroup:selectd});
			break;
		}
	}

	// setForRootCause(value,typeOfVariable){
	// 	if(typeOfVariable==='specifyRootResponse'){
    //             this.setState({specifyRootResponse:value});
	// 	}else if(typeOfVariable==='responseMessage'){
	// 		    this.setState({responseMessage:value});
	// 	}
	// }
	rightEditPanel(value) {
		this.setState({ isRightEditPanel: value });
	}

	componentDidMount() {
		if (GLOBAL.slaHeadEnable) {
			this.changeActionView('sla');
			this.setState({
				actionView: 'sla'
			})
			this.rightEditPanel(true);
		}
		this.props.resetLoadHamburgerOptionsReducer();
		this.props.fetchWatcherDetails('Investigation',navigationHooks.params.investigationId);
		this.props.getInvestigationItemDetailsById(navigationHooks.params.investigationId);
		/* if (navigationHooks.params && navigationHooks.params.investigationId) {
			this.props.getInvestigationItemDetailsById(navigationHooks.params.investigationId);
		} else {
			this.props.getInvestigationItemDetailsById(2387);
		} */

		let str={};
		str.user_id="";
		str = JSON.stringify(str);
		api.get("/api/userInfo",{headers:{'query':str}}).then((response)=>{
			let changeUserid='';
    if(response.data.role_id.split(",").includes("20")&&(!response.data.role_id.split(",").includes("52")))
    {
      changeUserid="changeUserid";
    }
    else if(response.data.role_id.split(",").includes("52")&&(!response.data.role_id.split(",").includes("20")))
    {
      changeUserid="impUserid";
    }
    else if(response.data.role_id.split(",").includes("20")&&response.data.role_id.split(",").includes("52")||response.data.role_id.split(",").includes("20")&&response.data.role_id.split(",").includes("51")||response.data.role_id.split(",").includes("52")&&response.data.role_id.split(",").includes("19"))
    {
      changeUserid="impUserid,changeUserid";
    }
    else if(response.data.role_id.split(",").includes("19")&&(!response.data.role_id.split(",").includes("51")))
    {
      changeUserid="changeUserid";
    }
    else if(response.data.role_id.split(",").includes("51")&&(!response.data.role_id.split(",").includes("19")))
    {
      changeUserid="impUserid";
    }
    else if(response.data.role_id.split(",").includes("19")&&response.data.role_id.split(",").includes("51"))
    {
      changeUserid="impUserid,changeUserid";
    }
			this.setState({
			 user_id:response.data.user_id,
			 role_id:response.data.role_id,
			 groupID:response.data.groupId,
			 roleID:response.data.role_id,
             changeImplentationRole: changeUserid,
			 date_format:response.data.dateformat,
			 full_name:response.data.full_name,
			 locationId: response.data['locationId'] || '',
			 locationName: response.data['locationName'] || '',
			 actionView: this.props.windowSize < 768 ? "" : "processHierarchy",
        	 isRightEditPanel: this.props.windowSize < 768 ? false : true,
			}, () => {
				// this.getCurrentPITLead(navigationHooks.params.investigationId);
			});
			// console.log("userid::::"+this.state.user_id);
			this.roleCalculatorForGuestUser(response.data.role_id);
		})
		this.props.getInvestigationTeamList(navigationHooks.params.investigationId);
		/* if (navigationHooks.params && navigationHooks.params.investigationId) {
			this.props.getInvestigationTeamList(navigationHooks.params.investigationId);
		} else {
			this.props.getInvestigationTeamList(2387);
		} */
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.windowSize != nextProps.windowSize) {
			if (nextProps.windowSize < 768) {
				this.setState({ isRightEditPanel: false, actionView: "" });
			}
			else {
			  	this.setState({ isRightEditPanel: true });      
				this.changeActionView(this.state.prevSelectedAction);
				if (this.state.actionView === "" && this.state.prevSelectedAction === "") {
				this.changeActionView("processHierarchy");
				}
			}
		}
		let incidentID1=(nextProps.params && nextProps.params.investigationId) || "";
		let incidentID2=navigationHooks.params.investigationId
		if(incidentID1!=incidentID2)
		{
			this.props.resetLoadHamburgerOptionsReducer();
			this.props.getInvestigationItemDetailsById(incidentID2);
			let str={};
			str.user_id="";
			str = JSON.stringify(str);
			api.get("/api/userInfo",{headers:{'query':str}}).then((response)=>{
				let changeUserid='';
				if(response.data.role_id.split(",").includes("20")&&(!response.data.role_id.split(",").includes("52")))
				{
				  changeUserid="changeUserid";
				}
				else if(response.data.role_id.split(",").includes("52")&&(!response.data.role_id.split(",").includes("20")))
				{
				  changeUserid="impUserid";
				}
				else if(response.data.role_id.split(",").includes("20")&&response.data.role_id.split(",").includes("52")||response.data.role_id.split(",").includes("20")&&response.data.role_id.split(",").includes("51")||response.data.role_id.split(",").includes("52")&&response.data.role_id.split(",").includes("19"))
				{
				  changeUserid="impUserid,changeUserid";
				}
				else if(response.data.role_id.split(",").includes("19")&&(!response.data.role_id.split(",").includes("51")))
				{
				  changeUserid="changeUserid";
				}
				else if(response.data.role_id.split(",").includes("51")&&(!response.data.role_id.split(",").includes("19")))
				{
				  changeUserid="impUserid";
				}
				else if(response.data.role_id.split(",").includes("19")&&response.data.role_id.split(",").includes("51"))
				{
				  changeUserid="impUserid,changeUserid";
				}
				this.setState({
			subMenu: '4',
			showHideAlfy:false,
			showHideRightNav:false,
			rootCauseActionClicked:'',
			mobileLeftMenuFilter:false,
				 user_id:response.data.user_id,
				 role_id:response.data.role_id,
				 groupID:response.data.groupId,
			     roleID:response.data.role_id,
				 changeImplentationRole: changeUserid,
				 date_format:response.data.dateformat,
				 full_name:response.data.full_name,
				 locationId: response.data['locationId'] || '',
				 locationName: response.data['locationName'] || ''
				});
				this.roleCalculatorForGuestUser(response.data.role_id);
			})
		}
		else if(nextProps.loadHamburgerOptions === true && Object.keys(this.props.investigationItemDetails).length>0){
			this.props.loadStatusBasedHamburgerOptions('Investigation',nextProps.investigationItemDetails.status,nextProps.investigationItemDetails.investigationId);
			this.props.resetLoadHamburgerOptionsReducer();
		}

		if(!this.state.isProblemCalled && nextProps.investigationItemDetails.consumerCompany){
			this.setState({isProblemCalled: true});
			this.getAdminParameters(nextProps.investigationItemDetails.consumerCompany,'Work Item Board','30','Summarization');
		}
		console.log("==nextProp",nextProps.loadHamburgerOptions,this.props.loadHamburgerOptions);
	}

	loadHome() {
		navigationHooks.navigate(homepagelocation);
	}

	showHideAlfy(value){
		this.setState({showHideAlfy: value});
	}
	showHideRightNav(value){
		this.setState({showHideRightNav:!this.state.showHideRightNav});
	}

	mobileLeftMenuFilter(){
		this.setState({mobileLeftMenuFilter:!this.state.mobileLeftMenuFilter});
	};

	showTimeline(flagValue){
		// console.log('this - ',this);
		this.setState({showTimelineFlag: flagValue});
	}

	RefreshScreen(obj){
		Swal.fire({
			title:this.props.tr["Are you sure your want to refresh ?"],
			showDenyButton:true,
			confirmButtonText:this.props.tr['Yes'],
			denyButtonText:this.props.tr['No'],
		  }).then((result) => {
			if (result.isConfirmed) {
				try{
					this.props.resetLoadHamburgerOptionsReducer();
					this.props.fetchWatcherDetails('Investigation',navigationHooks.params.investigationId);
					this.props.getInvestigationItemDetailsById(navigationHooks.params.investigationId);
					this.changeActionView("processHierarchy");
					this.props.getInvestigationTeamList(navigationHooks.params.investigationId);
				} catch(e){
					console.error(e);
				}
			} else if (result.isDenied) {}
		  })
	}

	changeActionView(actionSelected, userID = '', label = ''){
		if(['consumer', 'individual'].includes(actionSelected) && !( userID && !isNaN(userID) && !!parseInt(userID,10) ) ){
			return;
		}
		if(actionSelected && actionSelected == 'showExternalSystemAttributes'  && this.props && this.props.params && navigationHooks.params.investigationId){
			// console.log('aaaaaaaaaaaaaaaaaa', this.props);
			// this.props.loadExternalAttributesDetails(this.props.editChangeInitialData.changeId, 'changeManagement');
			this.props.loadExternalAttributesDetails(navigationHooks.params.investigationId, 'investigation');
		}
		this.setState({actionView: '', prevSelectedAction: ''}, () => {
			if(actionSelected==='ciId'){
				this.setState({
					actionView:actionSelected,
					impactedCIID: userID,
					userDetailsLabel: label,
					prevSelectedAction: actionSelected,
				});
			}else if(actionSelected==='individual'){
				this.setState({
					actionView:actionSelected,
					problemManagerID: userID,
					userDetailsLabel: label,
					prevSelectedAction: actionSelected,
				});
			}else{
			this.setState({
				actionView:actionSelected,
				userDetailsID: userID,
				userDetailsLabel: label,
				prevSelectedAction: actionSelected,
			});
		    }
		});

		if(actionSelected === 'notify'){
			this.setState({
				showNotifyForm:true
			})
		}
		//alert("actionSelected"+actionSelected);
	}

	//============render right side pages==
	//to get clicked view
	showRightPaneAction(actionView) {
		switch (actionView) {
			case 'qualifyAsProblem':
				return (
					<QualifyAsProblemFormTab investigationId={navigationHooks.params.investigationId} translator={this.props.tr} user_id={this.state.user_id} roleIdentification={this.roleIdentification} changeActionView={this.changeActionView} rightEditPanel={this.rightEditPanel}/>
				);

			// case 'disqualifyAsProblem':
			// 	return (
			// 		<DisqualifyAsProblemFormTab investigationId={navigationHooks.params.investigationId} translator={this.props.tr} user_id={this.state.user_id} roleIdentification={this.roleIdentification} changeActionView={this.changeActionView}/>
			// 	);
				case 'cancelProblem':
					return (
						<CancelProblemFormTab investigationId={navigationHooks.params.investigationId} translator={this.props.tr} user_id={this.state.user_id} roleIdentification={this.roleIdentification} changeActionView={this.changeActionView} rightEditPanel={this.rightEditPanel}/>
					);
			case 'investigationTeam' :
				return(
					<InvestigationTeamFormTab user_id={this.state.user_id} investigationItemDetails={this.props.investigationItemDetails} investigationId={navigationHooks.params.investigationId}  changeActionView={this.changeActionView} translator={this.props.tr} roleIdentification={this.roleIdentification} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}/>
				);
			case 'VendorTickets' :
				return(
                 <XsmVendor investigationId={navigationHooks.params.investigationId}  changeActionView={this.changeActionView} translator={this.props.tr} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}/>
				 );
			case 'recordinvestigationFindings':
				return(
					<RecordInvestigationFindings translator={this.props.tr} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} isServiceDeskRolesEnabled={this.state.isServiceDeskRolesEnabled} changeActionView={this.changeActionView}/>
				);
			case 'viewinvestigationFindings':
				return(
					<ViewInvestigationFindings translator={this.props.tr} rightEditPanel={this.rightEditPanel} changeActionView={this.changeActionView}/>
				)
			case 'rootCause':
				GLOBAL.hierarchy=false;
				return(
						<RootCauseFormTab investigationId={navigationHooks.params.investigationId} translator={this.props.tr} user_id={this.state.user_id} roleIdentification={this.roleIdentification} /*setForRootCause={this.setForRootCause} specifyRootResponse={this.state.specifyRootResponse} responseMessage={this.state.responseMessage}*/ changeActionView={this.changeActionView} rightEditPanel={this.rightEditPanel} isServiceDeskRolesEnabled={this.state.isServiceDeskRolesEnabled} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} isProblemManagerEnabled={this.state.isProblemManagerEnabled}/>
					);
			case 'correctiveActionRequired':
				return (
					<CorrectiveRequiredFormTab  investigationId={navigationHooks.params.investigationId} changeActionView={this.changeActionView} translator={this.props.tr} user_id={this.state.user_id} roleIdentification={this.roleIdentification} rightEditPanel={this.rightEditPanel}/>
				);
			case 'correctiveActionNotRequired':
				return (
					<CorrectiveNotRequiredFormTab  investigationId={navigationHooks.params.investigationId} changeActionView={this.changeActionView} translator={this.props.tr} user_id={this.state.user_id} roleIdentification={this.roleIdentification} rightEditPanel={this.rightEditPanel}/>
				);
			case 'correctiveActionNotImplemented':
			case 'withdrawInvestigation':
			case 'disqualifyAsProblem':
				return (
					<CloseInvestigationComp  investigationId={navigationHooks.params.investigationId} changeActionView={this.changeActionView} translator={this.props.tr} user_id={this.state.user_id} roleIdentification={this.roleIdentification} actionView={this.state.actionView} rightEditPanel={this.rightEditPanel} retireKnowledgeArticle={this.retireKnowledgeArticle} />
				);
			case 'proposeRFC':
				return (
					<RFCFormTab rightEditPanel={this.rightEditPanel}changeActionView={this.changeActionView} />
				);
			case 'provideAttachments':
				return (
					<AttachmentFormTab itemDetails = {this.props.investigationItemDetails} translator={this.props.tr} user_id={this.state.user_id} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} changeActionView={this.changeActionView}/>
				);
			case 'close':
				return (
					<CloseFormTab investigationId={navigationHooks.params.investigationId} translator={this.props.tr} user_id={this.state.user_id} roleIdentification={this.roleIdentification} investigationItemDetails={this.props.investigationItemDetails} invRelatedDataDetailsCount={this.props.invRelatedDataDetailsCount} changeActionView={this.changeActionView} rightEditPanel={this.rightEditPanel} retireKnowledgeArticle={this.retireKnowledgeArticle} />
				);
			case 'cancel':
				return (
					<CancelFormTab investigationId={navigationHooks.params.investigationId} translator={this.props.tr} user_id={this.state.user_id} roleIdentification={this.roleIdentification} investigationItemDetails={this.props.investigationItemDetails} invRelatedDataDetailsCount={this.props.invRelatedDataDetailsCount} rightEditPanel={this.rightEditPanel} changeActionView={this.changeActionView}/>
				);
			case 'processHierarchy':
				return (
					// <XsmProcessHierarchy translator={this.props.tr}/>
					<TabPage
						translator={this.props.tr}
						investigationId={navigationHooks.params.investigationId}
						invdetails={this.props.investigationItemDetails}
						fieldStatus={this.props.fieldStatus}
						date_format={this.state.date_format}
						urgencyListKeyValue={this.state.urgencyListKeyValue}
			            impactListKeyValue={this.state.impactListKeyValue}
						rightEditPanel={this.rightEditPanel}
						disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
						changeActionView={this.changeActionView}
						aiSummaruzationEnabled={this.state.aiSummaruzationEnabled}
					/>
				);
				case 'notify':
					return (
						<XSMNotify investigationItemDetails={this.props.investigationItemDetails} investigationId={navigationHooks.params.investigationId} translator={this.props.tr} full_name={this.state.full_name}
						showNotifyForm={this.state.showNotifyForm}
						setShowNotifyForm={(value) => {
							this.setState({showNotifyForm: value})
						}}
						rightEditPanel={this.rightEditPanel}
						disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
						changeActionView={this.changeActionView}
						
						/>
					);
				case 'change':
					return (
							<XSMChange investigationItemDetails={this.props.investigationItemDetails} investigationId={navigationHooks.params.investigationId} translator={this.props.tr} roleIdentification={this.roleIdentification} locationId={this.state.locationId} locationName={this.state.locationName} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} changeActionView={this.changeActionView}/>
						);
				case 'service':
						return (
                            <XsmServiceDetails  offeringId={this.props.investigationItemDetails.ciId} translator={this.props.tr} rightEditPanel={this.rightEditPanel} changeActionView={this.changeActionView}/>
						);
				case 'consumer':
					  return (
						  <XsmUserDetails  userId={this.state.userDetailsID} userDetailsLabel={this.state.userDetailsLabel} translator={this.props.tr} rightEditPanel={this.rightEditPanel} changeActionView={this.changeActionView}/>
					  );
				case 'individual':
						return (
							<XsmUserDetails  userId={this.state.problemManagerID} userDetailsLabel={this.state.userDetailsLabel} translator={this.props.tr} rightEditPanel={this.rightEditPanel} changeActionView={this.changeActionView}/>
						);
						// return (
						// 			<XsmUserDetails  userId={this.props.investigationItemDetails.impactedUserid} translator={this.props.tr}/>
						// 	);
				case 'proManager':
					return (
								<XsmUserDetails  userId={this.state.proManagerId} translator={this.props.tr} rightEditPanel={this.rightEditPanel} changeActionView={this.changeActionView}/>
						);
				case 'causalRelation':
						return (
									<XSMBreakCausalRelation  investigationId={navigationHooks.params.investigationId} requestId={this.props.investigationItemDetails.requestId} translator={this.props.tr} rightEditPanel={this.rightEditPanel} isChangedRolesEnabled={this.state.isChangedRolesEnabled} isProblemRolesEnabled={this.state.isProblemRolesEnabled} isIncidentRolesEnabled={this.state.isIncidentRolesEnabled} isFulfillmentRolesEnabled={this.state.isFulfillmentRolesEnabled} changeActionView={this.changeActionView}/>
						);

				case 'showExternalSystemAttributes':
					return (
						<XsmBreakFixExternalSystemAttributes translator={this.props.tr} rightEditPanel={this.rightEditPanel} changeActionView={this.changeActionView}/>
					);
				case 'sla':
					return (
						<XSMSLA translator={this.props.tr} investigationItemDetails={this.props.investigationItemDetails} rightEditPanel={this.rightEditPanel} role_id={this.state.role_id} changeActionView={this.changeActionView}/>
					);
				case 'ciId':
					return (
						<XsmCiDetails  ciId={this.state.impactedCIID} translator={this.props.tr} rightEditPanel={this.rightEditPanel} changeActionView={this.changeActionView}/>
					);
				case 'task':
					return (
						<PopUpTask  fieldStatus={this.props.fieldStatus}  clicktaskEdit= {this.clicktaskEdit}  itemId={navigationHooks.params.investigationId} module="Investigation" investigationdetail={this.props.investigationItemDetails} translator={this.props.tr} roleIdentification={this.roleIdentification} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} isTaskRoleEnabled={this.state.isTaskRoleEnabled} changeActionView={this.changeActionView}/>
					)
				case 'relatedCI':
					return (
						<PopUpCMDBRelatedWork fieldStatus={this.props.fieldStatus} itemId={navigationHooks.params.investigationId} investigationNumber={this.props.investigationItemDetails.investigationNumber} investigationdetail={this.props.investigationItemDetails} createdOn={this.props.investigationItemDetails.createdOn} editChangeInitialData={this.props.editChangeInitialData} translator={this.props.tr} investigationItemDetails={this.props.investigationItemDetails} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} isCMDBRoleEnabled={this.state.isCMDBRoleEnabled} changeActionView={this.changeActionView}/>
					)
				case 'relatedWorkItems':
					return (
						<PopUpRelatedWork fieldStatus={this.props.fieldStatus} itemId={navigationHooks.params.investigationId} investigationItemDetails={this.props.investigationItemDetails} investigationdetail={this.props.investigationItemDetails} translator={this.props.tr} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} isChangedRolesEnabled={this.state.isChangedRolesEnabled} isProblemRolesEnabled={this.state.isProblemRolesEnabled} isIncidentRolesEnabled={this.state.isIncidentRolesEnabled} isFulfillmentRolesEnabled={this.state.isFulfillmentRolesEnabled} full_name={this.state.full_name} changeActionView={this.changeActionView}/>
					)
				// case 'problemTeam':
				// 	return (
				// 		<PopUpTeamMembers investigationTeamList={this.props.investigationTeamList} translator={this.props.tr}/>
				// 	)
				case 'rootCauseDetails':
					return(
						<RootCauseDetails investigationId={navigationHooks.params.investigationId} translator={this.props.tr} user_id={this.state.user_id} roleIdentification={this.roleIdentification} changeActionView={this.changeActionView} rightEditPanel={this.rightEditPanel} status={this.props?.investigationItemDetails?.status_id} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} isServiceDeskRolesEnabled={this.state.isServiceDeskRolesEnabled} isProblemManagerEnabled={this.state.isProblemManagerEnabled}/>
					);
				case 'watcher':
					return(
					<WatcherDetails itemId={navigationHooks.params.investigationId} module='Investigation' itemDetails={this.props.investigationItemDetails} status={this.props.investigationItemDetails.status} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} changeActionView={this.changeActionView}/>);
				case 'tags':
					const isCancelledStatus = this.props.investigationItemDetails.status_id == problemStatusMapping['Cancelled'];
				    const isClosedStatus = this.props.investigationItemDetails.status_id == problemStatusMapping['Closed'];
				    const isTerminalStatus = isCancelledStatus || isClosedStatus;
					return (<TagDetails companyId={this.props.investigationItemDetails.consumerCompany} itemId={navigationHooks.params.investigationId} itemDetails={this.props.investigationItemDetails} module="Problem" businessFunction="" mainModule="Problem" userId={this.state.user_id} userName={this.state.full_name} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole} isD2CRolesEnabled={this.state.isD2CRolesEnabled} isTerminalStatus={isTerminalStatus} rightEditPanel={this.rightEditPanel}  changeActionView={this.changeActionView}/>);
				case 'knowledgeArticles':
					return (
						<KnowledgeArticles 
							attachedArticlesList = {this.state.attachedArticlesList}
							tr = {this.props.tr}
							getKnowledgeArticles = {this.getKnowledgeArticles}
							changeActionView={this.changeActionView}
						/>
					);
				case 'rootCauseDetails_view':
					return(
						<RootCauseDetails investigationId={navigationHooks.params.investigationId} translator={this.props.tr} user_id={this.state.user_id} roleIdentification={this.roleIdentification} changeActionView={this.changeActionView} rightEditPanel={this.rightEditPanel} status={this.props?.investigationItemDetails?.status_id} isProblemManagerEnabled={this.state.isProblemManagerEnabled}/>
					);
			    case 'showAvailableIndividuals':
					return(
					<AvailableIndividuals setAssignmentGrpUsr={this.setAssignmentGrpUsr}  selectedAssignmentGroup={this.state.selectedAssignmentGroup} selectedAssignmentUser={this.state.selectedAssignmentUser} rightEditPanel={this.rightEditPanel} disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}/>);
			default:
				return(
					// <XsmProcessHierarchy translator={this.props.tr}/>
					<TabPage
						translator={this.props.tr}
						investigationId={navigationHooks.params.investigationId}
						invdetails={this.props.investigationItemDetails}
						fieldStatus={this.props.fieldStatus}
						date_format={this.state.date_format}
						urgencyListKeyValue={this.state.urgencyListKeyValue}
			            impactListKeyValue={this.state.impactListKeyValue}
						rightEditPanel={this.rightEditPanel}
						disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
						changeActionView={this.changeActionView}
					/>
				);
		}
	}
    roleIdentification(Rolevalue){
		let flag=false
		let roles=this.state.role_id.split(',')
		for(let i=0;i<roles.length;i++)
		{
			if(roles[i]==Rolevalue)
			   {
				   flag=true
				   break
			   }
		}
		if(flag)
		   return true
		else
		   return false
	   }
	//===================================

	setUnchangedData(obj){
		function checkEmptyValue(val){
			if(!val || val == "" || val == "0" || val == "null"){
				return "";
			}
			return val;
		}
		try{
			// console.log('obj - ', obj);
			const urgencyObj = {
				"High": "5",
				"Medium": "10",
				"Low": "15",
			};
			const criticalityObj = {
				"Significant": "20",
				"Moderate": "25",
				"Low": "30",
			};


			let tempObj = {};
			tempObj['companyName'] = checkEmptyValue(obj['consumerCompany']);
			tempObj['ciName'] = checkEmptyValue(obj['ciId']);
			tempObj['consumer'] = checkEmptyValue(obj['impactedUserid']);
			tempObj['issueDescription'] = checkEmptyValue(obj['description']);
			tempObj['additionalInfo'] = checkEmptyValue(obj['symptoms']);
			tempObj['supportPGroup'] = checkEmptyValue(obj['asgPmGroupid']);
			tempObj['supportPIndividual'] = checkEmptyValue(obj['asgPmId']);
			tempObj['criticality'] = checkEmptyValue(criticalityObj[obj['serviceCriticality']]);
			tempObj['urgencyMode'] = checkEmptyValue(urgencyObj[obj['urgency']]);
			tempObj['source'] = obj['source']==='Recurring Incident'?'20':obj['source']==='Unresolved Incident'?'15':obj['source']==='Proactive Analysis'?'25':'10';

			Object.values(opsCategoryKeysMap).reduce((acc, key) => {
				tempObj[key] = checkEmptyValue(obj[key]);
			}, {})
			// console.log('tempObj - ', tempObj);
			this.setState({unchangedData: tempObj});
		}catch(e){
			console.error(e);
		}
	}

	checkDataChanged(obj){
		function isObject(obj) {
			return (Object.prototype.toString.call(obj) === '[object Object]');
		}
		function compareObject(obj1, obj2){
			if(!isObject(obj1) || !isObject(obj2)){
				return false;
			}

			let len = null;
			//check if they're of thesame length
			if(Object.keys(obj1).length != Object.keys(obj2).length){
				return false;
			}else{
				len = Object.keys(obj1).length; //use any length
			}

			let match = 0; //store number of matched properties
			Object.keys(obj1).forEach(i => {
				//check if values with the same keys are equal
				if(obj1[i] == obj2[i]){
					match++; //increment the variable
				}
			})
			//check if object length equals the number of matched properties
			if(match === len){
				return true;
			}

			return false;
		}
		function checkEmptyValue(val){
			if(!val || val == "" || val == "0" || val == "null"){
				return "";
			}
			return val;
		}
		try{
			const { unchangedData } = this.state;
			// console.log('obj - ', obj);

			

			let tempObj = {};
			tempObj['companyName'] = checkEmptyValue(obj['consumerCompany']);
			tempObj['ciName'] = checkEmptyValue(obj['ciId']);
			tempObj['consumer'] = checkEmptyValue(obj['impactedUserid']);
			tempObj['issueDescription'] = checkEmptyValue(obj['description']);
			tempObj['additionalInfo'] = checkEmptyValue(obj['symptoms']);
			tempObj['supportPGroup'] = checkEmptyValue(obj['asgPmGroupid']);
			tempObj['supportPIndividual'] = checkEmptyValue(obj['asgPmId']);
			tempObj['criticality'] = checkEmptyValue(obj['serviceCriticality']);
			tempObj['urgencyMode'] = checkEmptyValue(obj['urgency']);
			tempObj['source'] = checkEmptyValue(obj['source']);
			Object.values(opsCategoryKeysMap).reduce((acc, key) => {
				tempObj[key] = checkEmptyValue(obj[key]);
			}, {})
			// console.log('Data - ', {unchangedData, tempObj});
			const isEqual = compareObject(unchangedData, tempObj);
			// console.log('isEqual - ', isEqual);
			return !isEqual;
		}catch(e){
			console.error(e);
		}
	}

	clearUserDetails(){
		this.setState({actionView: '', userDetailsID: '', userDetailsLabel: '', impactedCIID: '', problemManagerID: ''}, () => {
			this.changeActionView('processHierarchy');
		});
	}
	roleCalculatorForGuestUser(rolesList){
		let roles = rolesList.split(",");
		let isChangedRolesEnabled = roles.includes('19') || roles.includes('20') || roles.includes('51') || roles.includes('52');
		let isProblemUserEnabled = roles.includes('16');
		let isProblemManagerEnabled = roles.includes('17');
		let isProblemInvestigatorEnabled = roles.includes('53');
		let isProblemCoreRolesEnabled = isProblemUserEnabled || isProblemManagerEnabled || isProblemInvestigatorEnabled;
		let isProblemRolesEnabled = isProblemCoreRolesEnabled;
		let isIncidentRolesEnabled = roles.includes('12') || roles.includes('13') || roles.includes('14');
		let isFulfillmentRolesEnabled = roles.includes('43') || roles.includes('44');
		let isServiceDeskRolesEnabled = roles.includes('48');
		let isD2CRolesEnabled = isChangedRolesEnabled || isProblemRolesEnabled || isIncidentRolesEnabled || isFulfillmentRolesEnabled;
		let isGuestUserRoleEnabled = !(isProblemRolesEnabled || isServiceDeskRolesEnabled) && roles.includes('64');
		this.setState({disableFieldsForGuestRole: isGuestUserRoleEnabled});
		isChangedRolesEnabled=isChangedRolesEnabled|| isServiceDeskRolesEnabled|| roles.includes('65');
		isProblemRolesEnabled=isProblemRolesEnabled|| isServiceDeskRolesEnabled|| roles.includes('64');
		isIncidentRolesEnabled=isIncidentRolesEnabled || isServiceDeskRolesEnabled|| roles.includes('63');
		isFulfillmentRolesEnabled=isFulfillmentRolesEnabled|| isServiceDeskRolesEnabled|| roles.includes('62');
		const isD2cViewerRoleEnabled = roles.includes('65') || roles.includes('64') || roles.includes('63') || roles.includes('62');
		let isTaskRoleEnabled = isD2CRolesEnabled || isServiceDeskRolesEnabled || roles.includes('61') || isD2cViewerRoleEnabled ;
		let isCMDBRoleEnabled = roles.includes('37') || roles.includes('38') || roles.includes('39');
		isServiceDeskRolesEnabled = !isProblemCoreRolesEnabled && isServiceDeskRolesEnabled;
		this.setState({isChangedRolesEnabled, isProblemRolesEnabled, isIncidentRolesEnabled, isFulfillmentRolesEnabled, isTaskRoleEnabled, isCMDBRoleEnabled, isServiceDeskRolesEnabled, isProblemManagerEnabled, isD2CRolesEnabled});
	}

	getRelateCIData(){
		try{
			return new Promise((resolve, reject) => {
				const { investigationItemDetails: { investigationId = null } = {} } = this.props;
				axios
				.get(`/api/config_items/relationship/${investigationId}/Investigation/Yes`)
				.then(res => {
					// console.log('res - ', res);
					// return res;
					if(res?.data && Array.isArray(res.data) && res.data.length > 0){
						resolve(res.data);
					}
					else{
						reject('error');
					}
				})
				.catch(err => {
					console.error(err);
					// return err;
					reject('error');
				});
			});
		}catch(e){
			console.error(e);
			return Promise.reject('error');
		}
	}

	getRelatedWorkItemData(){
		try{
			return new Promise((resolve, reject) => {
				const { investigationItemDetails: { investigationId = null } = {} } = this.props;
				axios
				.get(`/serviceManagement/rest/investigationRelationsAction?itemId=${investigationId}&module=Investigation`)
				.then(res => {
					// console.log('res - ', res);
					let result = '';
					if(res?.data && Array.isArray(res.data) && res.data.length > 0){
						result += 'Related Work Items: ';
						let arr = [];
						(res.data).forEach(item => {
							if(item['workItemNumber']){
								arr.push(item['workItemNumber']);
							}
						});
						result += arr.join(', ');
					}
					resolve(result);
				})
				.catch(err => {
					console.error(err);
					reject('error');
				});
			});
		}catch(e){
			console.error(e);
			return Promise.reject('error');
		}
	}

	getRCATechnique(techniqueId = ''){
		try{
			return new Promise((resolve, reject) => {
				const { investigationItemDetails: { investigationId = null } = {} } = this.props;
				axios.get(`/serviceManagment/rest/rcaTechnique/${investigationId}/${techniqueId}`)
				.then(res => {
					// console.log('res - ', res);
					let result = '';
					if(res?.data?.data?.keyValueData){
						let obj = res?.data?.data?.keyValueData || {};
						if(techniqueId == '120'){
							result += 'RCA Technique (5Whys): ';
							result += `${(obj?.First && obj?.First?.trim().length > 0) ? `First : ${obj.First.trim()}` : ''}${(obj?.Second && obj?.Second?.trim().length > 0) ? `, Second : ${obj.Second.trim()}` : ''}${(obj?.Third && obj?.Third?.trim().length > 0) ? `, Third : ${obj.Third.trim()}` : ''}${(obj?.Fourth && obj?.Fourth?.trim().length > 0) ? `, Fourth : ${obj.Fourth.trim()}` : ''}${(obj?.Fifth && obj?.Fifth?.trim().length > 0) ? `, Fifth : ${obj.Fifth.trim()}` : ''}`;
						}
						if(techniqueId == '125'){
							result += 'RCA Technique (5W 1H): ';
							result += `${(obj?.What && obj?.What?.trim().length > 0) ? `What : ${obj.What}` : ''}${(obj?.Where && obj?.Where?.trim().length > 0) ? `, Where : ${obj.Where}` : ''}${(obj?.When && obj?.When?.trim().length > 0) ? `, When : ${obj.When}` : ''}${(obj?.Who && obj?.Who?.trim().length > 0) ? `, Who : ${obj.Who}` : ''}${(obj?.Why && obj?.Why?.trim().length > 0) ? `, Why : ${obj.Why}` : ''}${(obj?.How && obj?.How?.trim().length > 0) ? `, How : ${obj.How}` : ''}`;
						}
						if(techniqueId == '130'){
							result += 'RCA Technique (Convergence-Divergence): ';
							result += `${(obj?.Discover && obj?.Discover?.trim().length > 0) ? `Discover : ${obj.Discover}` : ''}${(obj?.Define && obj?.Define?.trim().length > 0) ? `, Define : ${obj.Define}` : ''}${(obj?.Deduce && obj?.Deduce?.trim().length > 0) ? `, Deduce : ${obj.Deduce}` : ''}${(obj?.Determine && obj?.Determine?.trim().length > 0) ? `, Determine : ${obj.Determine}` : ''}`;
						}
					}
					resolve(result);
				})
				.catch(err => {
					console.error(err);
					reject('error');
				});
			});
		}catch(e){
			console.error(e);
			return Promise.reject('error');
		}
	}

	getDependentData_KA(){
		try{
			const promises = [ this.getRelatedWorkItemData(), this.getRCATechnique('120'), this.getRCATechnique('125'), this.getRCATechnique('130') ];
			return Promise.allSettled(promises)
			.then(res => {
				return Promise.resolve(res);
			})
			.catch(err => {
				console.error(err);
				return Promise.resolve('error');
			});
		}catch(e){
			console.error(e);
			return Promise.resolve('error');
		}
	}

	async createKnowledgeArticle(){
		try{
			const dataRes = await this.getDependentData_KA();
			let depDescription = '';
			if(dataRes && Array.isArray(dataRes)){
				let arr = [];
				dataRes.forEach(item => {
					if(item['value']){
						arr.push(item['value']);
					}
				});
				depDescription = arr.join(', ');
			}
			depDescription = (depDescription && depDescription.length > 255) ? depDescription.substring(0, 256) : depDescription;

			// console.log('****************', this.props.investigationItemDetails);
			const { investigationItemDetails: { category = '', description = '', rootCause = '', investigationId = null, investigationNumber = '', symptoms = '', workaroundProvided = '', permanentSolution = '', ciName = '', categoryName = '', subCategoryName = '' } = {} } = this.props;
			const title = `Known Error ${investigationNumber} ${symptoms || ''}`;
			let titleArr = [];
			titleArr.push((description) ? (`Problem Statement: ${description}`) : '');
			titleArr.push((symptoms) ? (`Problem Symptoms: ${symptoms}`) : '');
			titleArr.push((rootCause) ? (`Root Cause: ${rootCause}`) : '');
			titleArr.push((workaroundProvided) ? (`Workaround Provided: ${workaroundProvided}`) : '');
			titleArr.push((permanentSolution) ? (`Permanent Solution: ${permanentSolution}`) : '');
			titleArr.push((ciName) ? (`Impacted CI: ${ciName}`) : '');
			titleArr.push((categoryName) ? (`Category: ${categoryName}`) : '');
			titleArr.push((subCategoryName) ? (`Sub-Category: ${subCategoryName}`) : '');
			titleArr.push(depDescription || '');
			const desc = titleArr.filter(x => typeof x === 'string' && x.length > 0).join(', ');
			let data = {};
			data['title'] = title;
			data['raw'] = desc;
			data['category'] = category;
			// const discourseUsername = this.getDiscourseAdminUsername() || username;
			axios.post('/api/createKnowledgeArticle', data, { headers: { 'username': username } }) 
			.then((res) => {
				// console.log('res - ', res);
				if(res?.status == 200){
					const { id, topic_id } = res?.data || {};
					let data2 = [];
					// let obj2 = {itemId: investigationId,'itemNumber': investigationNumber,module: 'Investigation',createdOn: 0,createdBy: '',createdByName: '',knowledgeArticle: title ,knowledgeId: id,articleUrl: `${topic_slug}/${id-1}`,knowledgeDescription: desc};
					let obj2 = {itemId: investigationId,'itemNumber': investigationNumber,module: 'Investigation',createdOn: 0,createdBy: '',createdByName: '',knowledgeArticle: title ,knowledgeId: topic_id,articleUrl: topic_id,knowledgeDescription: desc};
					data2.push(obj2);

					axios.post('/serviceManagement/rest/linkKnowledgeArticles', data2)
					.then((res2) => {
						// console.log('res2 - ', res2);
						if(res2?.status == 200){
							let msg = `Known Error: Knowledge article created and attached successfully`;
							this.postComment(msg);
						}
						else{
							let error = `Known Error: Error in linking knowledge article ${(res2?.data && typeof res2?.data == 'string') ? (': ' + res2?.data) : ''}`;
							this.postComment(error);
						}
					})
					.catch(err2 => {
						console.error(err2);
						// console.log('error 2 : ', {err2, msg: err2.message, status: err2.status});
						let error = `Known Error: Error in linking knowledge article`;
						this.postComment(error);
					});
				}
				else{
					let error = 'Known Error: Error in creating knowledge article';
					this.postComment(error);
				}
			})
			.catch(err => {
				console.error(err);
				
				let error = 'Known Error: Error in creating knowledge article';
				if(err?.response?.status == 500){
					error = 'Known Error: Error in creating knowledge article';
				}
				else if(err?.response?.status == 422){
					let errorTemp = '';
					errorTemp = err?.response?.data?.errors || '';
					errorTemp = (errorTemp && Array.isArray(errorTemp) && errorTemp.length > 0) ? errorTemp.join(',') : '';
					error = `${error} ${(errorTemp) ? (': ' + errorTemp) : ''}`;
				}
				// console.log('error - ', {error});
				this.postComment(error);
			});
		}catch(e){
			console.error(e);
		}
	}

	postComment(comments = ''){
		try{
			const { investigationItemDetails: { investigationId = null } = {} } = this.props;
			api
			.post('/serviceManagement/rest/activity/', {
				itemId: investigationId,
				description: comments,
				isInternal: 'N',
				createdOn: '',
				createdBy: '',
				module: 'Investigation',
				createdByName: '',
				sendNotification: 'true',
				isExternal: '',
			})
			.then(() => {
				// console.log('res2 - ', res2);
				this.props.loadBreakFixActivityLogDetails(
					investigationId,
					"desc",
					"Investigation"
				  );
			})
			.catch(err2 => {
				console.error(err2);
			});
		}catch(e){
			console.error(e);
		}
	}

	getKnowledgeArticles(){
		try{
			this.setState({ attachedArticlesList: [] });
			const { investigationItemDetails: { investigationId = null, investigationNumber = '' } = {} } = this.props;
			axios.get(`/serviceManagement/rest/getAttachedArticles?itemId=${investigationId}&itemNumber=${investigationNumber}&module=Investigation&sortBy=knowledgeId&orderBy=asc`)
			.then((res) => {
				// console.log('res - ', res);
				if(res?.data && Array.isArray(res.data) && res.data.length > 0){
					this.setState({ attachedArticlesList: res.data });
				}
				else{
					this.setState({ attachedArticlesList: [] });
				}
			})
			.catch(err => {
				console.error(err);
				this.setState({ attachedArticlesList: [] });
			});
		}catch(e){
			console.error(e);
			this.setState({ attachedArticlesList: [] });
		}
	}

	exportDocument(type = ''){
		try{
			if(!type){
				throw new Error('Error in exporting document');
			}
			let date_format = this.state.date_format;
			let obj = {...this.props.investigationItemDetails, ...{ date_format }};
			exportDoc(type, obj);
		}catch(e){
			console.error(e);
		}
	}

	retireKnowledgeArticle(){
		try{
			const { investigationItemDetails: { investigationId = null, investigationNumber = '' } = {} } = this.props;
			axios.get(`/serviceManagement/rest/getAttachedArticles?itemId=${investigationId}&itemNumber=${investigationNumber}&module=Investigation&sortBy=knowledgeId&orderBy=asc`)
			.then((res) => {
				// console.log('res - ', res);
				let articles = [];
				if(res?.data && Array.isArray(res.data) && res.data.length > 0){
					articles = res.data;
				}
				if(articles.length > 0){
					// console.log('articles - ', articles);
					const knowledgeId = articles[0].knowledgeId || '';
					let data = {
						status: 'archived',
						enabled: 'true'
					};
					// const discourseUsername = this.getDiscourseAdminUsername() || username;
					axios.put(`/api/retireKnowledgeArticle/${knowledgeId}`, data, { headers: { 'username': username } })
					.then((res2) => {
						// console.log('res2 - ', res2);
					})
					.catch(err2 => {
						console.error(err2);
					});
				}
			})
			.catch(err => {
				console.error(err);
			});
		}catch(e){
			console.error(e);
		}
	}

	getDiscourseAdminUsername(){
		try{
			const config = {
				'localhost': 'HCLBreakfixManager121',
				'xsmdev.dryice-aws.com': 'HCLBreakfixManager121',
				'xsmtest.dryice-aws.com': 'HCLBreakfixManager121',
				'sxgbp-preprod.hclmtaas.com': 'harsh.r_hcl.com',
				'sxgbp-staging.hclmtaas.com': 'varunna_hcl.com',
				'sxgbp.hclmtaas.com': 'harsh.r_hcl.com',
				'support.dryice.ai': 'harsh.r_hcl.com',
				'supportstg.dryice.ai': 'harsh.r_hcl.com'
			};
			const hostName = window?.location?.hostname || '';
			return ( config[hostName] || '');
		}catch(e){
			console.error(e);
			return '';
		}
	}
	getAdminParameters(companyId, module, propertyId, propertyName){
		const queryObj = {companyId,module,propertyId};
        const queryString = JSON.stringify(queryObj);
		axios.get('/api/getAdminPreferences', { headers: { query: queryString } }).then(retrivedFlags => {
			switch(propertyName){
			    case 'Summarization': 
				      const retrivedString =  retrivedFlags.data[0].value;
			          const retrivedFlag = retrivedString === 'true' || retrivedString === 'True' ? true : false;
				      this.setState({aiSummaruzationEnabled: retrivedFlag,});
				      break;

			}
		});
	}

   render() {
	if(this.props.investigationItemDetails.serviceId==undefined||this.props.isFetching){
	 	return (
	 		<div className='rctLoader'>
					<LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
	 		</div>
	 	);
	 }
      return (
		<div className='container-fluid'>
			{this.state.role_id!==''?<WibSearchFulfillment tr={this.props.tr} roleIdentification={this.roleIdentification} group_id={this.state.groupID} changeImplentationRole={this.state.changeImplentationRole}/>:null}
			<div className="margin-t-10 margin-b-15">
				<ul className="breadcrumb"><li className="breadcrumb-item"><a role="button" onClick={this.loadHome}>{this.props.tr["Home"]}</a></li><li className="breadcrumb-item"><Link title={this.props.tr["Work Item Board"]} to="/quickViewBreakFix">{this.props.tr["Work Item Board"]}</Link></li><li className="breadcrumb-item active">{this.props.tr["Edit"]}</li></ul>
			</div>
			<div bsClass="minHeightWIB">
			<Form>
				<InvestigationEditHeader user_id={this.state.user_id} role_id={this.state.role_id} changeActionView={this.changeActionView} translator={this.props.tr} investigationItemDetails = {this.props.investigationItemDetails} showTimeline={this.showTimeline} roleIdentification={this.roleIdentification} investigationTeamList={this.props.investigationTeamList}
					checkDataChanged={this.checkDataChanged}
					clearUserDetails={this.clearUserDetails}
					rightEditPanel={this.rightEditPanel}
					RefreshScreen={this.RefreshScreen}
					disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
					createKnowledgeArticle={this.createKnowledgeArticle}
					exportDocument={this.exportDocument}
					actionView={this.state.actionView}
					isProblemManagerEnabled={this.state.isProblemManagerEnabled}
					/>

				<PanelGroup direction="horizontal">
					<Panel id="sidebar" minSize={33} order={1} defaultSize={this.state.isRightEditPanel ? 67 : 100} className={this.state.isRightEditPanel ? "isShowLeftPanel" : ""}>
						{/* Xsm Break Fix Form tabs Section Start*/}
						<XsmBreakFixFormTab proManagerId={this.proManagerId} roleIdentification={this.roleIdentification} changeActionView={this.changeActionView} translator={this.props.tr} investigationItemDetails = {this.props.investigationItemDetails} showTimeline={this.showTimeline} showTimelineFlag={this.state.showTimelineFlag}
							setUnchangedData={this.setUnchangedData}
							rightEditPanel={this.rightEditPanel}
							disableFieldsForGuestRole={this.state.disableFieldsForGuestRole}
							selectedAssignmentGroup={this.state.selectedAssignmentGroup}
							selectedAssignmentUser={this.state.selectedAssignmentUser}
							setAssignmentGrpUsr={this.setAssignmentGrpUsr}
							isServiceDeskRolesEnabled={this.state.isServiceDeskRolesEnabled}
							aiSummaruzationEnabled={this.state.aiSummaruzationEnabled}
							/>
						{/* Xsm Break Fix Form tabs Section End*/}
					</Panel>
					{this.state.isRightEditPanel ?
						<>
						<PanelResizeHandle className='isShowLeftPanel resizeHandle'><div className='outlne'><div className='handIcn'><i className="fa fa-chevron-left" aria-hidden="true"></i></div></div></PanelResizeHandle>
						<Panel minSize={33} order={2} defaultSize={33}>
							<div className="stickyArea rBoxStyle">
								{/* Xsm BreakFix Action Section Start*/}
								{this.showRightPaneAction(this.state.actionView)}
								{/* Xsm BreakFix Action Section End*/}
							</div>
						</Panel>
						</> : ""
					}
				</PanelGroup>	
				
				<Row>
					<Col xs={12}>
						{/* Footer Section Start*/}
						{/*<FooterMain />*/}
						{/* Footer Section End*/}
					</Col>
				</Row>
			</Form>
			</div>
			{/* {homepagelocation == "/unauthorized" || homepagelocation === "/userActivationPending" ? null : <FooterMain isLogout={GLOBAL.isLogout} />}  */}
		</div>
      );
   }
}

const mapStateToProps = ({investigationItemDetails,fieldStatus,spcmReducer,isFetchingDetails,loadHamburgerOptions,invRelatedDataDetailsCount, investigationTeamList,windowSize}) => {
	return {
		investigationItemDetails:(investigationItemDetails && investigationItemDetails.data ? investigationItemDetails.data[0]:investigationItemDetails) ,
		tr:spcmReducer.tr,
		isFetching:isFetchingDetails.isFetching,
		loadHamburgerOptions,
		invRelatedDataDetailsCount,
		fieldStatus,
		investigationTeamList,
		params: navigationHooks.params,
		windowSize: windowSize.width,
	}
}
export default connect(mapStateToProps,{getInvestigationItemDetailsById,changeFieldStatusData,loadBreakFixAuditLogDetails,getTranslation, loadExternalAttributesDetails, loadStatusBasedHamburgerOptions, resetLoadHamburgerOptionsReducer, getInvestigationTeamList, fetchWatcherDetails, loadBreakFixActivityLogDetails})(InvestigationEdit);
