/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
export const DivCompComponent = {
  name: "DivComp",
  traits: [
    {
      type: "text",
      label: "Title",
      name: "iconTitle",
      placeholder: "Title",
      changeProp: 1,
    },
  ],
};

export const DivCompAdd = {
  model: {
    defaults: {
      tagName: "div",
      draggable: true,
      attributes: { class: "" },
      components: [
        {
          tagName: "span",
          attributes: { class: "" },
          content: "Div Section",
          draggable: true,
          droppable: true,
          editable: true,
        },
      ],
      traits: DivCompComponent.traits,
      editable: true,
    },
    init() {},
  },
};

export const addDivCompComponent = (e) => {
  e.DomComponents.addType(DivCompComponent.name, DivCompAdd);
};

export const DivCompBlockJson = {
  id: "DivComp",
  label: "Div",
  media: `<svg fill="#808080" viewBox="0 0 24 24" id="menu-alt" data-name="Line Color" xmlns="http://www.w3.org/2000/svg" class="icon line-color"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><line id="secondary" x1="3" y1="12" x2="21" y2="12" style="fill: none; stroke: #2ca9bc; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></line><path id="primary" d="M3,18H21M3,6H21" style="fill: none; stroke: #808080; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></path></g></svg>
  `,
  category: "Extra",
  content: {
    type: DivCompComponent.name,
  },
};
