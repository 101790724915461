
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { getCompanyList } from '../../../actions/spcmActions';
import {_inputField, _dropDown, _checkbox, TypeaheadExampleSingleSelect } from '../../common/formFields';
 
 
const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

class xsmSystemConfigurationEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyList: [],
      selectedCompanyList: [],
      isActive: "false",
      companyId: '',
      companyName: '',
      typedCompany: '',
      initialValuesUpdated: false,
    }
    this.setCompany = this.setCompany.bind(this);
    this.companyInputChange = this.companyInputChange.bind(this);
    this.setErrorColor = this.setErrorColor.bind(this);
    this.onCrossClickCompany = this.onCrossClickCompany.bind(this);
    this.validateEditConfig = this.validateEditConfig.bind(this); 
  }
   
  componentDidMount() {
    console.log("this.props",this.props);
    this.props.setMailBoxType(this.props.mailBoxType) ;
    const { initialValues, dispatch } = this.props;
    this.props.getCompanyList("");
    this.setState({isActive: this.props.initialValues?.STATUS== 'Active'})
  }
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.spcmReducer.companyList.length > 0 && this.state.companyList.length === 0) {
      this.props.setSelectedCompanyList([{
        field1Key: this.props.initialValues.COMPANY_NAME,
          value: this.props.initialValues.COMPANY_ID
      }])
      this.setState({
        companyList: nextProps.spcmReducer.companyList,
       
      });
      //let data = { "companyId":this.props.initialValues.COMPANY_ID , "module": "Breakfix", "field": "Urgency,Impact", "status": "1", 'language': 'en' }
      
    }
    if (Object.keys(nextProps.initialValues).length > 0 && !this.state.initialValuesUpdated) {
      this.props.dispatch(change('xsmSystemConfigurationEditForm', 'COMPANY_NAME', nextProps.initialValues.COMPANY_NAME))
      this.props.dispatch(change('xsmSystemConfigurationEditForm','COMPANY_ID',nextProps.initialValues.COMPANY_ID))
    this.setState({ companyId: nextProps.initialValues.COMPANY_ID, selectedOfferingName: nextProps.initialValues.OFFERING_NAME,selectedGuestUser: nextProps.initialValues.GUEST_USER_NAME, initialValuesUpdated:true });
      if (nextProps.initialValues.ACTIONS === 'Create with Guest Account') {
        this.setState({disableGuestUser : false})
      }
    }
  }

  onCrossClickCompany = () => {
     this.companyInputChange("");
     this.props.setSelectedCompanyList([]);
     this.props.dispatch(change('xsmSystemConfigurationEditForm', 'COMPANY_NAME', []));
     this.props.dispatch(change('xsmSystemConfigurationEditForm', 'COMPANY_ID', ''));
  }

   setErrorColor = () => {
    if (this.props.selectedRequestor.length > 0) {
      this.setState({ requestorError: false });
    } else {
      this.setState({ requestorError: true });
    }
  };


  setCompany = (selectedCompany) => {
    if (selectedCompany.length > 0) { 
      this.props.setSelectedCompanyList(selectedCompany);
      this.setState({companyId:selectedCompany[0].field1Value, companyName:selectedCompany[0].field1Key });
      this.props.dispatch(change('xsmSystemConfigurationEditForm', 'COMPANY_ID', selectedCompany[0].field1Value))
      this.props.dispatch(change('xsmSystemConfigurationEditForm','COMPANY_NAME', selectedCompany[0].field1Key))
      
     
    } else {
      this.props.setSelectedCompanyList([]);
      this.props.dispatch(change('xsmSystemConfigurationEditForm', 'impact', ''));
      this.props.dispatch(change('xsmSystemConfigurationEditForm', 'urgency', ''));
     
    }
  }

  companyInputChange = (e) => {
    this.props.setSelectedCompanyList([]);
    this.setState({typedCompany: e})
    this.setCompany([]);
  }
  mailboxCredential = (e) => {
    if (typeof (this.props.mailBoxType) !== 'undefined') {
      this.props.setMailBoxType(e.target.value) ;
    }
    else {
      this.props.setMailBoxType('');
    }
  }
 
  validateEditConfig = (fieldType, event) => {
    switch (fieldType) {
      case "MAILBOX_ID":
        if (event.target.value == "" ||!validateEmail(event.target.value)){
          this.props.setMailboxIdErrorColor("error");
        } else {
          this.props.setMailboxIdErrorColor("");
        }
        break;
      case "MAILBOX_PROVIDER":
        if (event.target.value == "") {
          this.props.setMailboxProviderErrorColor("error");
        } else {
          this.props.setMailboxProviderErrorColor("");
        }
        break;
        case "AUTH_TYPE":
        if (event.target.value == "") {
          this.props.setAuthErrorColor("error");
        } else {
          this.props.setAuthErrorColor("");
        }
        break;
        case "STATUS":
          if (event.target.value == "") {
            this.props.setStatusErrorColor("error");
          } else {
            this.props.setStatusErrorColor("");
          }
          break; 
        // case "AUTH_TYPE":
        // if (event.target.value == "") {
        //   this.props.setWhitelistedDomainErrorColor("error");
        // } else {
        //   this.props.setWhitelistedDomainErrorColor("");
        // }
        // break;
      default:
    }
  }


  render() {
     let mailBoxType = [
      { text: 'Gmail', value: 'Gmail' },
      { text: 'Outlook', value: 'Outlook' },];

    let status = [
      {
        text: 'Active',
        value: 'Active'
      },
      {
        text: 'Inactive',
        value: 'Inactive'
      },
    ]

    let Auth_Type=[{
      text: 'OAuth',
      value: 'OAuth'
    },
    {
      text: 'Imap',
      value: 'Imap'
    },]

    return (
      <div>
        <div className="borderForm bFormDiv">
        <form>
          <Row>
          <Col md={6}>
                <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Company']}</Form.Label>
                  <Field
                  name="COMPANY_NAME"
                  component={TypeaheadExampleSingleSelect}
                  className="form-control"
                  options={this.state.companyList}
                  selectedOptions={this.props.selectedCompanyList}
                  onSelection={this.setCompany}
                  errorClass={this.props.companyError}
                  onInputChange={this.companyInputChange}
                  onCrossClick={this.onCrossClickCompany}
                  typedValue={this.state.typedCompany}
                  setErrorColor={this.props.setCompanyErrorColor}
                  labelKey={"field1Key"}
                />
                </Form.Group>
              </Col>
            <Col md={6}>
                <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Mailbox']}</Form.Label>
                  <Field name="MAILBOX_ID" className={"form-control " + (this.props.mailboxIdErrorColor)} component={_inputField} maxLength={120}
                  onBlur={(event) => {
                    this.validateEditConfig("MAILBOX_ID", event);
                  }}>
                  </Field>
                </Form.Group>
              </Col>
              
            </Row>
            <Row>
            <Col md={6}>
              <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Vendor']}</Form.Label>
                  <Field name="MAILBOX_PROVIDER" className={"form-control "+ (this.props.mailboxProviderErrorColor)} component={_dropDown} options={mailBoxType} onChange={(e) => { this.mailboxCredential(e) }}
                  onBlur={(event) => {
                    this.validateEditConfig("MAILBOX_PROVIDER", event);
                  }}>
                  </Field>
                </Form.Group>
              </Col>
              <Col md={6}>
              <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Auth Type']}</Form.Label>
                  <Field name="AUTH_TYPE" className={"form-control "+ (this.props.authErrorColor)} component={_dropDown} options={Auth_Type} onChange={(e) => { this.mailboxCredential(e) }}
                  onBlur={(event) => {
                    this.validateEditConfig("AUTH_TYPE", event);
                  }}>
                  </Field>
                </Form.Group>
              </Col>
            </Row> 
            <Row>

            <Col md={6}>
                <Form.Group >
                <span className="rStar" />
                  <Form.Label bsClass="" className='margin-r-10'>{this.props.translator['Status']}</Form.Label>
                  <Field name="STATUS" className={"form-control " + (this.props.statusErrorColor)} component={_dropDown} options={status}
                  onBlur={(event) => {
                    this.validateEditConfig("STATUS", event);
                  }}></Field>
                </Form.Group>
              </Col>
            </Row>
            {/* <div className="margin-t-5 padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
            <div className="bgGray padding-5">
              <span className="rStar"></span>
              {this.props.translator["Mailbox Filters"]}
            </div>
            <Row>
            <Col md={4}>
                <Form.Group >
                  <Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Whitelisted Domains']}</Form.Label>
                  <Field name="WHITELISTED_DOMAINS" className={"form-control " +(this.props.whitelistedDomainErrorColor )} component={_inputField} maxLength={120} placeholder={`gmail.com,yahoo.com`}
                  onBlur={(event) => {
                    this.validateEditConfig("WHITELISTED_DOMAINS", event);
                  }}>
                  </Field>
                </Form.Group>
              </Col>
              <Col md={4}>
              <Form.Group >
                  <Form.Label bsClass="">{this.props.translator[`Always Process Exception`]}</Form.Label>
                <Field name="EXCEPTION_EMAILIDS" className="form-control" component={_inputField} maxLength={120} placeholder={`xyz@gmail.com,abc@yahoo.com`}>
                  </Field>
                </Form.Group>
              </Col>
              <Col md={4}>
              <Form.Group >
                  <Form.Label bsClass="">{this.props.translator[`Never Process Exception`]}</Form.Label>
                <Field name="NO_EXCEPTIONS" className="form-control" component={_inputField} maxLength={120} placeholder={`abc@gmail.com,xyz@yahoo.com`}>
                  </Field>
                </Form.Group>
              </Col>
            </Row>
          </div>           */}
          </form>
        </div>
      </div>
    );
  }

}
let selector = formValueSelector('xsmSystemConfigurationEditForm');
xsmSystemConfigurationEditForm = connect((state) => {
  let mailBoxType = selector(state, 'MAILBOX_PROVIDER');
  return{mailBoxType}
})(xsmSystemConfigurationEditForm)

function mapStateToProps({ spcmReducer, savedMailboxCofigList }) {
console.log("savedMailboxCofigList reducer",savedMailboxCofigList)
  return {
    initialValues: savedMailboxCofigList, 
    spcmReducer
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCompanyList
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'xsmSystemConfigurationEditForm',
    destroyOnUnmount: true,
    // validate,
    enableReinitialize: true
  })(xsmSystemConfigurationEditForm)
);
