
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import axios from 'axios';
import { GLOBAL } from '_Globals';
import { bindActionCreators } from 'redux';
import { getInvestigationItemDetails,getInvestigationItemDetailsUpdate,getInvestigationItemDetailsById } from '_Actions/investigation/investigationAction.js';
import { loadBreakFixAuditLogDetails } from '_Actions/breakFix/breakFixAuditLogAction';
import { loadStatusBasedHamburgerOptions } from '_Actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '_Actions/common/commonApisActions.js';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import {ImSpinner6} from "react-icons/im";
import {IoClose} from "react-icons/io5";
class CloseInvComp extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			comments: '',
			saveLoader:false
		};
		this.setComments = this.setComments.bind(this);
		this.saveData = this.saveData.bind(this);
	}

	setComments(value) {
		this.setState({ comments: value });
	}
	saveData() {
        try{
            let ref = this;
            const { comments = '' } = this.state;
            const {actionView = ''} = this.props;

            // if(this.props.user_id != this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') )){
            //     swal({
            //         text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
            //         button: this.props.translator['OK'],
            //     });
            //     return;
            // }

            if (!(comments && comments.trim().length > 0)) {
                swal({
                    text: "Comments can not be left blank.",
                    button: this.props.translator['OK'],
                });
                return;
            } else {

                let description = "";
                if(actionView == 'correctiveActionNotImplemented'){
                    description = 'Closure reason : Corrective Action - Not Implemented';
                }
                if(actionView == 'withdrawInvestigation'){
                    description = 'Closure reason : Investigation Withdrawn';
                }
                if(actionView == 'disqualifyAsProblem'){
                    description = 'Closure reason : Disqualified';
                }
                description = description + " - " + this.state.comments;

                let postjson = {
                    "closureComments":description,
                    "status": '65',
                    "modifiedBy":"",
                    "modifiedByName":""
                };
                if(actionView == 'disqualifyAsProblem'){
                    postjson["qualified"] = "N";
                }

                let postjsonactivity = {
                    "itemId":this.props.investigationId,
                    "description": description,
                    "isInternal":"",
                    "createdOn":"",
                    "createdBy":"",
                    "module":"Investigation",
                    "createdByName":""
                };

                this.setState({saveLoader:true});
                axios.patch(GLOBAL.investigationEditUrl + this.props.investigationId, postjson)
                    .then(function (response) {
                        if (response.status === 200) {

                            axios.post(GLOBAL.breakFixActivityLogPostUrl, postjsonactivity)
                            .then(function (response1) {
                                this.setState({saveLoader:false});
                                // console.log("response.statusText:::"+response1.status)
                                })
                            .catch(function (error) {
                                // console.log(error);
                                this.setState({saveLoader:false});
                                //alert("H1"+error);
                            });

                            // ref.props.changeActionView('processHierarchy');
                            // ref.props.seteditInvestigationDetails(response);
                            // ref.props.loadStatusBasedHamburgerOptions('Investigation',response.data.status,response.data.investigationId);

                            // ref.setState({saveLoader:false});
                            ref.props.changeActionView('processHierarchy');
                            // ref.props.getInvestigationItemDetailsUpdate(ref.props.investigationId);
                            ref.props.getInvestigationItemDetailsById(ref.props.investigationId);
                            ref.props.loadStatusBasedHamburgerOptions('Investigation',response.data.status,response.data.investigationId);

                            if(actionView !== 'correctiveActionNotImplemented'){
                                ref.props.retireKnowledgeArticle();
                            }
                            
                        } else {
                            ref.setState({saveLoader:false});
                            Swal.fire('error saving correction action');
                        }
                    })
                    .catch(function (error) {
                        ref.setState({saveLoader:false});
                        // console.log(error);
                    });
            }
        }catch(e){
            console.error(e);
        }
	}

	render() {
        const { investigationItemDetails = {}, actionView = "" } = this.props;
		if (!(investigationItemDetails && Object.keys(investigationItemDetails).length > 0)) {
			return (
				<div></div>
			)
		}

        let headingLabel = '';
        if(actionView == 'correctiveActionNotImplemented'){
            headingLabel = 'Corrective Action - Not Implemented';
        }
        if(actionView == 'withdrawInvestigation'){
            headingLabel = 'Withdraw Investigation';
        }
        if(actionView == 'disqualifyAsProblem'){
            headingLabel = 'Disqualify Problem';
        }

		return (
			<div className="hambTab">
                <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
					<ul>
						<li>
						<button
                            type='button'
							title="Minimize the right panel"
							bsClass=""
							bsStyle=""
							className="closerightPanelBtn"
							onClick={() => {
							this.props.rightEditPanel(false);
                            this.props.changeActionView("");
							}}
						>
							<IoClose />
						</button>
						</li>
					</ul>
				</div>
				<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.props.translator[headingLabel] || headingLabel}</div></div>
				<div className='rBoxGap'>
					<div>
						<Form.Group >
							<Form.Group >
								<Form.Label><span className="rStar"></span>{this.props.translator['Comments']} </Form.Label>
								<Form.Control as="textarea" rows="4" onChange={(event) => { this.setComments(event.target.value) }} />
							</Form.Group>
						</Form.Group>

						<ButtonToolbar className="black margin-t-10">
							<Button className='rgSidrkBtn smallBtn' disabled={this.state.saveLoader}
								onClick={() => { this.saveData(); }}>
								{this.state.saveLoader ?
									<ImSpinner6 className="icn-spinner"/> : null} {this.props.translator['Submit']}
							</Button>
							<Button className="rgSiLigBtn smallBtn" bsSize="small" bsStyle="primary" disabled={this.state.saveLoader}
								onClick={() => { this.props.changeActionView('processHierarchy') }}>
								{this.props.translator['Cancel']}
							</Button>
						</ButtonToolbar>
					</div>
				</div>
			</div>
		);

	}
}

export function mapStateToProps({ investigationItemDetails,invRelatedDataDetailsCount }) {
	return {  investigationItemDetails:(investigationItemDetails && investigationItemDetails.data ? investigationItemDetails.data[0]:investigationItemDetails),invRelatedDataDetailsCount };
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ getInvestigationItemDetails, loadBreakFixAuditLogDetails,getInvestigationItemDetailsUpdate, getInvestigationItemDetailsById,loadStatusBasedHamburgerOptions, loadTimelineData }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CloseInvComp);
