
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListLoader from "../../../common/loaders/ListLoader.js";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import Pagination from "react-js-pagination";
//import CustomCalendar from "../CustomCalendar.js";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { IoSearch } from "react-icons/io5";
import { loadEventList } from "../../../../actions/onCallSchedule/calendar/onCallCalendarAction.js";
import Swal from "sweetalert2";
import { loadOnCallSXMenulistData, onCallGroupUsersByGroupId } from "../../../../actions/onCallSchedule/common/onCallCommonAction.js";

const CompanyList = (props) => {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const showLoader = useSelector((state) => state.showLoader);
  const eventList = useSelector((state) => state.onCallCalendarEventList);
  const userList = useSelector((state) => state.onCallGroupUsersList);
  const scheduleType = useSelector(
    (state) => state.on_call_common_reducer.scheduleType
  );
  useEffect(() => {
    if (props.selectedGroupList && props.selectedGroupList.length > 0) {
      dispatch(onCallGroupUsersByGroupId(props.selectedGroupList[0].id));
    } else {
      dispatch({
        type: "ON_CALL_CALENDER_EVENT_DATA_LIST",
        events: [],
      });
    }
  }, [props.selectedGroupList]);

  useEffect(() => {
    if (eventList && Object.keys(eventList).length > 0) {
      props.setTotalItemsCount(eventList.itemsCount);
    }
  }, [eventList]);

  useEffect(() => {
    if (props.selectedGroupList && props.selectedGroupList.length > 0 && userList && userList.length > 0) {
      let userListFields = [];
      userList.forEach((list) => {
        userListFields.push(list.field1Value);
      });
      props.setUsers(userListFields);
      dispatch(
        loadEventList({
          page: 0,
          size: 10,
          userId: userListFields.join(","),
        })
      );
    }
  }, [userList]);

  useEffect(() => {
    dispatch(loadOnCallSXMenulistData("AIOSEM", "ScheduleType"));
  }, []);

  const handleChange = (event, name) => {
    switch (name) {
      case "fullname":
        props.setFullnameSearchInput(event.target.value);
        break;
      case "shiftname":
        props.setShiftnameSearchInput(event.target.value);
        break;
      case "scheduletype":
        props.setShiftTypeSearchInput(event.target.value);
        break;
      case "available":
        props.setAvailableSearchInput(event.target.value);
        break;
      default:
        break;
    }
  };

  const prepareFilters = () => {
    let filter = {};
    if (props.selectedGroupList && props.selectedGroupList.length > 0) {
      if (props.users && props.users.length > 0) {
        filter["userId"] = props.users.join(",");
      } else {
        filter["userId"] = "";
      }
    } else {
      Swal.fire({ title: "Please select group first" });
      return false;
    }
    if (props.fullnameSearchInput) {
      filter["userName"] = props.fullnameSearchInput;
    }
    if (props.shiftnameSearchInput) {
      filter["shiftName"] = props.shiftnameSearchInput;
    }
    if (props.shiftTypeSearchInput) {
      filter["scheduleType"] = props.shiftTypeSearchInput;
    }
    if (props.availableSearchInput) {
      filter["available"] = props.availableSearchInput;
    }
    if (props.startDateSearchInput) {
      filter["eventStartTimestamp"] = props.startDateSearchInput.replaceAll("/",'-');
    }
    if (props.endDateSearchInput) {
      filter["eventEndTimestamp"] =  props.endDateSearchInput.replaceAll('/','-');
    }
    return filter;
  };

  const handleSearch = (e, picker = false) => {
    let filter = prepareFilters();
    filter["page"] = 0;
    filter["size"] = 10;
    props.setActivePage(0);
    props.setItemsCountPerPage(10);
    if (e && e.target && picker) {
      let date = [];
      date.push(
        moment(picker.startDate).format("MM-DD-YYYY"),
        moment(picker.endDate).format("MM-DD-YYYY")
      );
      if (e.target.name === "startDateSearchInput") {
        props.setRef("from",picker);
        props.setStartDateSearchInput(
          picker.startDate.format("MM/DD/YYYY") +
            "," +
            picker.endDate.format("MM/DD/YYYY")
        );
        filter["eventStartTimestamp"] = date.join(",");
      } else if (e.target.name === "endDateSearchInput") {
        props.setRef("to",picker);
        props.setEndDateSearchInput(
          picker.startDate.format("MM/DD/YYYY") +
            "," +
            picker.endDate.format("MM/DD/YYYY")
        );
        filter["eventEndTimestamp"] = date.join(",");
      }
    }
    if (Object.keys(filter).length > 0) {
      props.setIsFilterApplied(true);
    }
    dispatch(loadEventList(filter));
  };

  const onDropDownChange = (e) => {
    let filter = prepareFilters();
    filter["page"] = 0;
    filter["size"] = 10;
    props.setActivePage(0);
    props.setItemsCountPerPage(10);
    if (e.target.name === "available") {
      props.setAvailableSearchInput(e.target.value);
      if (e.target.value) {
        filter["available"] = e.target.value;
      } else {
        delete filter["available"];
      }
    }

    if (e.target.name === "scheduleType") {
      props.setShiftTypeSearchInput(e.target.value);
      if (e.target.value) {
        filter["scheduleType"] = e.target.value;
      } else {
        delete filter["scheduleType"];
      }
    }

    if (Object.keys(filter).length > 0) {
      props.setIsFilterApplied(true);
    }
    dispatch(loadEventList(filter));
  };

  const handleCallback = () => {};

  const handleKeyPress = (event) => {};

  const onPageSizeChange = (e) => {
    let filter = prepareFilters();
    filter["page"] = 0;
    filter["size"] = e.target.value;
    dispatch(loadEventList(filter));
    props.setActivePage(0);
    props.setItemsCountPerPage(e.target.value);
  };

  const onPaginationChange = (e) => {
    let filter = prepareFilters();
    filter["page"] = e - 1;
    filter["size"] = props.itemsCountPerPage;
    dispatch(loadEventList(filter));
    props.setActivePage(parseInt(filter["page"], 10));
  };

  const renderList = (list) => {
    let lists;
    if (list && list.length > 0) {
      lists = list.map((event) => {
        return (
          <tr key={"calendar-list-" + event.eventId}>
            <td>{event.userName}</td>
            <td>{event.shiftName}</td>
            <td>{event.scheduleType}</td>
            <td>{event.eventStartTimestamp}</td>
            <td>{event.eventEndTimestamp}</td>
            <td>{event.available}</td>
          </tr>
        );
      });
      return lists;
    }
  };

  return (
    <div className={props.rightDisplay ? "leftTrue" : "rightTrue"}>
      <div>
        <div className="respondv">
          <div className={"tableRgtBor table-responsive"}>
            <Table
              striped
              bordered
              condensed
              hover
              className="tableView nowrapWhright"
              style={{ marginTop: "0px" }}
            >
              <thead>
                <tr>
                  <th>
                    <div className="sortParArr">{translator["User Name"]}</div>
                    <div className="colSearDv">
                      <Form.Control
                        className="colSearInp"
                        type="text"
                        value={
                          props.fullnameSearchInput != ""
                            ? props.fullnameSearchInput
                            : ""
                        }
                        placeholder={translator["Search here"]}
                        onKeyPress={(e) => {
                          if (e.charCode == "13") {
                            if (
                              props.fullnameSearchInput != "" &&
                              props.fullnameSearchInput.trim().length > 3
                            )
                              handleSearch();
                          }
                        }}
                        onChange={(event) => {
                          handleChange(event, "fullname");
                        }}
                      />
                      {props.fullnameSearchInput.trim().length > 3 ? (
                        <a
                          title="search"
                          className="faicn"
                          href={void 0}
                          onClick={(event) => handleSearch()}
                        >
                          <IoSearch />
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </th>

                  <th>
                    <div className="sortParArr">{translator["Shift Name"]}</div>
                    <div className="colSearDv">
                      <Form.Control
                        className="colSearInp"
                        type="text"
                        value={
                          props.shiftnameSearchInput != ""
                            ? props.shiftnameSearchInput
                            : ""
                        }
                        placeholder={translator["Search here"]}
                        onKeyPress={(e) => {
                          if (e.charCode == "13") {
                            if (
                              props.shiftnameSearchInput != "" &&
                              props.shiftnameSearchInput.trim().length > 3
                            )
                              handleSearch();
                          }
                        }}
                        onChange={(event) => {
                          handleChange(event, "shiftname");
                        }}
                      />
                      {props.shiftnameSearchInput.trim().length > 3 ? (
                        <a
                          title="search"
                          className="faicn"
                          href={void 0}
                          onClick={(event) => handleSearch()}
                        >
                          <IoSearch />
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </th>

                  <th>
                    <div className="sortParArr">{translator["Schedule Type"]}</div>
                    <div className="colSearDv">
                      <Form.Select
                        className="colSearInp"
                        type="text"
                        name="scheduleType"
                        onChange={(event) => {
                          onDropDownChange(event);
                        }}
                      >
                        <option value="">Select</option>
                        {scheduleType &&
                          scheduleType.length > 0 &&
                          scheduleType.map((type) => {
                            return (
                              <option
                                value={type.field1Key}
                                selected={
                                  props.shiftTypeSearchInput === type.field1Key
                                }
                              >
                                {type.field1Key}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </div>
                  </th>

                  <th>
                    <div className="sortParArr">{translator["Start Date"]}</div>
                    <div className="colSearDv">
                      <DateRangePicker
                        initialSettings={{
                          autoUpdateInput: false,
                          autoApply: true,
                          parentEl:"#skipdv"
                        }}
                        onApply={(e, picker) => handleSearch(e, picker)}
                        onCallback={handleCallback}
                      >
                        <input
                          type="text"
                          placeholder={translator["Search here"]}
                          readOnly
                          className="form-control"
                          name="startDateSearchInput"
                          onPaste={handleKeyPress}
                          onKeyDown={handleKeyPress}
                          value={props.startDateSearchInput}
                        />
                      </DateRangePicker>
                    </div>
                  </th>

                  <th>
                    <div className="sortParArr">{translator["End Date"]}</div>
                    <div className="colSearDv">
                      <DateRangePicker
                        initialSettings={{
                          autoUpdateInput: false,
                          autoApply: true,
                          parentEl:"#skipdv"
                        }}
                        onApply={(e, picker) => handleSearch(e, picker)}
                        onCallback={handleCallback}
                      >
                        <input
                          type="text"
                          placeholder={translator["Search here"]}
                          readOnly
                          name="endDateSearchInput"
                          className="form-control"
                          onPaste={handleKeyPress}
                          onKeyDown={handleKeyPress}
                          value={props.endDateSearchInput}
                        />
                      </DateRangePicker>
                    </div>
                  </th>
                  <th>
                    <div className="sortParArr">{translator["Available"]}</div>
                    <div className="colSearDv">
                      <Form.Select
                        className="colSearInp"
                        type="text"
                        name="available"
                        onChange={(event) => {
                          onDropDownChange(event);
                        }}
                      >
                        <option value="">Select</option>
                        <option
                          value={"Yes"}
                          selected={props.availableSearchInput === "Yes"}
                        >
                          Yes
                        </option>
                        <option
                          value={"No"}
                          selected={props.availableSearchInput === "No"}
                        >
                          No
                        </option>
                      </Form.Select>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {showLoader.loading ? (
                  <td colSpan={6}>
                    <ListLoader />
                  </td>
                ) : eventList.data && eventList.data.length > 0 ? (
                  renderList(eventList.data)
                ) : (
                  <td colSpan={6} className="text-c">
                    {translator["No Data Available"]}
                  </td>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        {eventList.data && eventList.data.length > 0 && (
          <div className="nBotPagina">
            <div className="nShow">
              <div className="margin-r-10" componentClass={""}>
                {translator["Show"]}:
              </div>
              <Form.Select
                value={props.itemsCountPerPage}
                onChange={(e) => {
                  onPageSizeChange(e);
                }}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </Form.Select>
            </div>
            <nav aria-label="Pagination" className="display-inline-block">
              <Pagination
                prevPageText="Prev"
                nextPageText="Next"
                firstPageText={<i className="glyphicon glyphicon-menu-left" />}
                lastPageText={<i className="glyphicon glyphicon-menu-right" />}
                activePage={props.activePage + 1}
                itemsCountPerPage={props.itemsCountPerPage}
                totalItemsCount={props.totalItemsCount}
                pageRangeDisplayed={4}
                onChange={(e) => {
                  onPaginationChange(e);
                }}
              />
            </nav>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyList;
