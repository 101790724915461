import { FieldWrapper } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Error, Hint, Label } from "@progress/kendo-react-labels";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import React, { useEffect, useState } from "react";
import { RadioGroup } from "@progress/kendo-react-inputs";
import { useNavigate } from "react-router";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import { useSelector } from "react-redux";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "universal-cookie";
import { FiRefreshCcw, FiUser } from "react-icons/fi";
import { FaArrowsSpin } from "react-icons/fa6";
import { RiLockLine, RiLockPasswordLine } from "react-icons/ri";
import loginBgtextStyle from "_Images/login-headImg.png";
import { TbRotateClockwise } from "react-icons/tb";

const cookies = new Cookies();
const gph = cookies.get("gph").split("~");
const CRED_HOST = gph[23];
const isValidPassword = (password) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{15,}$/;
  return passwordRegex.test(password);
};

const Forgotpass = () => {
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [showCaptchaError, setShowCaptchaError] = useState(false);
  const [captchaErrorMsg, setCaptchaErrorMsg] = useState("");
  const [step, setStep] = useState(1);
  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmNewPassword: "",
    otp: "",
  });
  const [otpType, setOtpType] = useState("");
  const [userId, setUserId] = useState("");
  const [captchaText, setCaptchaText] = useState("");
  const navigate = useNavigate();
  const [nextBtnDisable, setNextBtnDisable] = useState(false);
  const [respEmail, setRespEmail] = useState("");
  const [validatedUser, setValidatedUser] = useState({});
  const [otpErrMsg, setOtpErrMsg] = useState("");
  const [passwordErrMsg, setPasswordErrMsg] = useState("");
  const [confirmPasswordErrMsg, setConfirmPasswordErrMsg] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getWidth = (windowWidth) => {
    if (windowWidth <= 768) {
      return '95vw';
    } else if (windowWidth <= 1023) {
      return '90vw';
    } else if (windowWidth <= 1280) {
      return '75vw';
    } else {
      return '63vw';
    }
  };
  let widthCalc = getWidth(windowWidth);

  let styles = {
    fpBgtextStyle: {
      backgroundImage: `url(${loginBgtextStyle})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: windowWidth <= 690 ? 'contain' : 'initial',
      backgroundPosition: 'left top',
      minHeight: '324px',
      minWidth: windowWidth <= 690 ? '315px' : '400px',
      padding: windowWidth <= 690 ? '80px 0px 0px 45px' : '100px 0px 0px 55px',
      zIndex: 1,
    },
    fpMain: {
      width: widthCalc,
      height: "auto",
      textAlign: 'left',
      margin: 'auto',
      zIndex: 1,
      display: 'flex',
      flexDirection: windowWidth <= 690 ? 'column' : "row",
      justifyContent: 'space-around',
      alignItems: 'center',
      flexWrap: windowWidth <= 690 ? 'wrap' : "nowrap",
    },
  };

  const setUserIdText = (value) => {
    if (value === "") {
      setShowValidationMessage(true);
      setValidationMessage("User ID is required");
    } else {
      setShowValidationMessage(false);
      setValidationMessage("");
    }
    setUserId(value);
  };

  const setPasswordsObj = (value, type) => {
    if (type === "newPassword") {
      if (!isValidPassword(value)) {
        setPasswordErrMsg(
          "Password must contain at least 15 characters, including uppercase, lowercase, and special characters."
        );
      } else {
        setPasswordErrMsg("");
      }
    }
    if (type === "confirmNewPassword") {
      if (value !== passwords.newPassword) {
        setConfirmPasswordErrMsg("Passwords do not match");
      } else {
        setConfirmPasswordErrMsg("");
      }
    }
    setPasswords({ ...passwords, [type]: value });
  };

  const clickedNext = () => {
    let error = false;
    if (step === 1) {
      if (captchaText === "") {
        setShowCaptchaError(true);
        setCaptchaErrorMsg("Captcha is required");
        error = true;
      } else if (!validateCaptcha(captchaText)) {
        setShowCaptchaError(true);
        setCaptchaErrorMsg("Invalid Captcha");
        error = true;
      }

      if (userId === "") {
        setShowValidationMessage(true);
        setValidationMessage("User ID is required");
        error = true;
      }

      if (!error) {
        let requestOptions = {};
        requestOptions.body = { email: userId };
        requestOptions.url = CRED_HOST + "/reset/validate/user";
        requestOptions.verb = "post";
        setNextBtnDisable(true);
        axios({
          url: requestOptions?.url,
          method: requestOptions?.verb,
          data: requestOptions?.body,
        })
          .then((res) => {
            const respData = res?.data;
            if (respData?.data?.isAdUserExist && !respData?.data?.err) {
              setStep(2);
              setRespEmail(respData?.emailId);
              setValidatedUser({
                userName: respData?.data?.userDisplayName,
                email: respData?.data?.userEmail,
              });
              setOtpType("email");
              setNextBtnDisable(false);
            } else {
              setValidationMessage("User ID does not exist");
              setShowValidationMessage(true);
              setNextBtnDisable(false);
              setValidatedUser({});
            }
          })
          .catch((error) => {
            Swal.fire({ text: error?.message, icon: "error" });
            setNextBtnDisable(false);
          });
      }
    } else if (step === 2) {
      let requestOptions = {};
      requestOptions.body = { email: userId };
      requestOptions.url = CRED_HOST + "/reset/send-otp";
      requestOptions.verb = "post";
      setNextBtnDisable(true);
      axios({
        url: requestOptions?.url,
        method: requestOptions?.verb,
        data: requestOptions?.body,
      })
        .then((res) => {
          const respData = res?.data;
          if (respData?.data?.isSuccess) {
            setStep(3);
            setNextBtnDisable(false);
          } else {
            setNextBtnDisable(false);
          }
        })
        .catch((error) => {
          console.log("error", error?.message);
          Swal.fire({ text: error?.message, icon: "error" });
          setNextBtnDisable(false);
        });
    } else if (step === 3) {
      if (passwords.otp === "") {
        setOtpErrMsg("OTP is required");
        error = true;
      }

      if (!error) {
        let requestOptions = {};
        requestOptions.body = { email: userId, otp: passwords.otp };
        requestOptions.url = CRED_HOST + "/reset/validate-otp";
        requestOptions.verb = "post";
        setNextBtnDisable(true);
        axios({
          url: requestOptions?.url,
          method: requestOptions?.verb,
          data: requestOptions?.body,
        })
          .then((res) => {
            const respData = res?.data;
            if (respData?.data?.isError) {
              Swal.fire({
                text: respData?.data?.message,
                icon: "error",
              });
              setNextBtnDisable(false);
            } else {
              setStep(4);
              setNextBtnDisable(false);
            }
          })
          .catch((error) => {
            console.log("error", error?.message);
            Swal.fire({ text: error?.message, icon: "error" });
            setNextBtnDisable(false);
          });
      }
    } else if (step === 4) {
      if (passwords.newPassword === "") {
        setPasswordErrMsg("Password is required");
        error = true;
      }
      if (passwords.confirmNewPassword === "") {
        setConfirmPasswordErrMsg("Confirm Password is required");
        error = true;
      }

      if (
        passwords.newPassword !== "" &&
        !isValidPassword(passwords.newPassword)
      ) {
        setPasswordErrMsg(
          "Password must contain at least 15 characters, including uppercase, lowercase, and special characters."
        );
        error = true;
      }
      if (
        passwords.confirmNewPassword !== "" &&
        passwords.confirmNewPassword !== passwords.newPassword
      ) {
        setConfirmPasswordErrMsg("Passwords do not match");
        error = true;
      }
      if (!error) {
        setNextBtnDisable(true);
        let requestOptions = {};
        requestOptions.body = {
          email: userId,
          newPassword: passwords.newPassword,
        };
        requestOptions.url = CRED_HOST + "/reset/reset-password";
        requestOptions.verb = "post";
        axios({
          url: requestOptions?.url,
          method: requestOptions?.verb,
          data: requestOptions?.body,
        })
          .then((res) => {
            const respData = res?.data;
            if (respData?.status === 200 && respData?.data?.isChanged) {
              Swal.fire({
                text: respData?.data?.message,
                icon: "success",
              });
              navigate("/login");
            } else {
              Swal.fire({
                text: respData?.data?.message,
                icon: "error",
              });
              setNextBtnDisable(false);
            }
          })
          .catch((error) => {
            console.log("error", error?.message);
            Swal.fire({
              text: error?.message,
              icon: "error",
            });
            setNextBtnDisable(false);
          });
      }
    }
  };
  return (
    <Container fluid className="forgot-password" style={styles.fpMain}>
          <div className="k-card right-side-panel" style={styles.fpBgtextStyle}>
            <div className="text-container">
              <div className="first-line">
                <span>
                  All passwords <span className="boldTxt">created or changed</span> <br/> must confirm to the following
                  requirements.
                </span>
                <br />
              </div>
              <div>
                <span>Must be at least <span className="boldTxt">15 characters</span> in length</span>
                <br />
                <br />
                <span>Password <b>must contain</b> the following attributes:</span>
              </div>
              <div className="password-attributes">
                <span>- <b>Lower case</b> letters</span>
                <br />
                <span>- <b>Upper case</b> letters</span>
                <br />
                <span>- Numbers <b>0 to 9</b></span>
                <br />
                <span>- <b>Special characters</b> (e.g. !, $, #, %)</span>
              </div>
            </div>
          </div>

          <div className="k-card left-side-panel">            
            <div className="progress-line-container">
              <div>
                <div className={`step step-1 ${step == 1 ? "active" : ""}`}>
                  <span>1</span>
                </div>
                <div className={`step step-2 ${step == 2 ? "active" : ""}`}>
                  <span>2</span>
                </div>
                <div className={`step step-3 ${step == 3 ? "active" : ""}`}>
                  <span>3</span>
                </div>
                <div className={`step step-4 ${step == 4 ? "active" : ""}`}>
                  <span>4</span>
                </div>
                {/* <div className="progress-line">
                  <br />
                </div> */}
              </div>
            </div>
            <h2>Forgot Password</h2>
            <div className="form-container">
              <div className="form-fields">
                {step === 1 && (
                  <div>
                    <FieldWrapper>
                      <Label className="k-form-label" editorId={"userId"}>
                        <FiUser />
                      </Label>
                      <div className="k-form-field-wrap">
                        <Input
                          type={"email"}
                          id={"userId"}
                          className="form-control"
                          placeholder="Enter Your Email Address"
                          onChange={(e) => {
                            setUserIdText(e.value);
                          }}
                        />
                        {/* {!showValidationMessage && (
                    <Hint>Enter your personal email address.</Hint>
                  )} */}
                        {showValidationMessage && (
                          <Error>{validationMessage}</Error>
                        )}
                      </div>
                    </FieldWrapper>
                    <FieldWrapper className="margin-t-20">
                      <Label className="k-form-label" editorId={"userId"}>
                        <TbRotateClockwise />
                      </Label>
                      <div className="k-form-field-wrap">
                        <Input
                          type={"text"}
                          id={"captcha"}
                          className="form-control"
                          placeholder="Enter Captcha"
                          onChange={(e) => {
                            setCaptchaText(e.value);
                            setShowCaptchaError(false);
                            setCaptchaErrorMsg("");
                          }}
                        />
                        {showCaptchaError && <Error>{captchaErrorMsg}</Error>}
                      </div>
                    </FieldWrapper>
                    <div className="captcha-container margin-t-10 k-inline-flex">
                      <div className="canvasDv">
                        <LoadCanvasTemplate
                          reloadText="<i class='fa fa-refresh'></i>"
                          reloadColor="rgb(0, 131, 143)"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <FieldWrapper className="otpDv">
                    <Label className="k-form-label">Pin/Passcode or OTP</Label>
                    <RadioGroup
                      data={[
                        {
                          label: `via user email on ${respEmail}`,
                          value: "email",
                        },
                      ]}
                      defaultValue={"email"}
                    />
                  </FieldWrapper>
                )}
                {step === 3 && (
                  <>
                    <FieldWrapper>
                      <Label
                        className="k-form-label margin-t-10"
                        editorId={"otp"}
                      >
                        <RiLockLine />
                      </Label>
                      <div className="k-form-field-wrap">
                        <Input
                          type={"password"}
                          id={"otp"}
                          className="form-control"
                          placeholder="Enter OTP"
                          name="otp"
                          onChange={(e) => setPasswordsObj(e.value, "otp")}
                        />
                      </div>
                      {otpErrMsg && <Error>{otpErrMsg}</Error>}
                    </FieldWrapper>
                  </>
                )}
                {step === 4 && (
                  <>
                    <FieldWrapper>
                      <Label
                        className="k-form-label"
                        editorId={"newPassword"}
                      >
                        <RiLockPasswordLine />
                      </Label>
                      <div className="k-form-field-wrap">
                        <Input
                          type={"password"}
                          id={"newPassword"}
                          placeholder="New Password"
                          className="form-control"
                          onChange={(e) =>
                            setPasswordsObj(e.value, "newPassword")
                          }
                        />
                        {passwordErrMsg && <Error>{passwordErrMsg}</Error>}
                      </div>
                    </FieldWrapper>
                    <FieldWrapper className="margin-t-20">
                      <Label
                        className="k-form-label"
                        editorId={"confirmNewPassword"}
                      >
                        <RiLockPasswordLine />
                      </Label>
                      <div className="k-form-field-wrap">
                        <Input
                          type={"password"}
                          id={"confirmNewPassword"}
                          className="form-control"
                          placeholder="Confirm New Password"
                          onChange={(e) =>
                            setPasswordsObj(e.value, "confirmNewPassword")
                          }
                        />
                        {confirmPasswordErrMsg && (
                          <Error>{confirmPasswordErrMsg}</Error>
                        )}
                      </div>
                    </FieldWrapper>
                  </>
                )}
                <ButtonGroup className="fpBtnGrp">
                  <Button
                    className="cancel-button"
                    togglable={true}
                    onClick={() => navigate("/login")}
                    disabled={nextBtnDisable}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => clickedNext()}
                    className="reset-button"
                    togglable={true}
                    disabled={nextBtnDisable}
                  >
                    {nextBtnDisable ? (
                      <Spinner />
                    ) : step === 3 ? (
                      "Validate OTP"
                    ) : step === 4 ? (
                      "Reset Password"
                    ) : (
                      "Next"
                    )}
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
    </Container>
  );
};

export default Forgotpass;
