
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Row, Col, Button, Table, Pagination, Form } from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import { bindActionCreators } from "redux";
import HolidayEntryForm from "./createRuleForm.js";
import { loadSlaHolidayEntries } from "../../../../actions/sla/quickViewAction";
import { loadSearchStringResults } from "../../../../actions/sla/lovAction";
//class Approvals extends React.Component {
import { GLOBAL } from "_Globals";
import {IoSearch, IoClose} from 'react-icons/io5';
import {HiPlus} from "react-icons/hi";

class HolidayEntryFormIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddForm: false,
      showEditForm: "add",
      holidayId: null,
      searchString: "",
      searchStringErrorLabel: "",
    };
    this.formToggle = this.formToggle.bind(this);
    //console.log(this.props.scheduleId);
    //this.props.loadSlaHolidayEntries(this.props.scheduleId);
    this.deleteHolidayEntry = this.deleteHolidayEntry.bind(this);
    this.getEditDetailsHoliday = this.getEditDetailsHoliday.bind(this);
    this.changeSearchField = this.changeSearchField.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.renderSearchStringResults = this.renderSearchStringResults.bind(this);
  }
  formToggle(value, formSelection) {
    this.setState({
      showAddForm: value,
      showEditForm: formSelection,
    });
  }

  getEditDetailsHoliday(value, formSelection, holidayId) {
    this.setState({
      showAddForm: value,
      showEditForm: formSelection,
      holidayId: holidayId,
    });
  }

  deleteHolidayEntry(holidayId) {
    let ref = this;
    //alert(this.props.scheduleId);
    //alert(holidayId+"==="+holidayDate+"==="+holidayName)
    if (holidayId !== "") {
      //console.log(holidayId);
      //console.log(GLOBAL.slaHolidatEntryDeleteUrl+ref.props.scheduleId+"/"+holidayId);
      axios
        .delete(
          GLOBAL.slaHolidatEntryDeleteUrl +
            ref.props.scheduleId +
            "/" +
            holidayId
        )
        .then((resp) => {
          this.props.loadSlaHolidayEntries(ref.props.scheduleId);
        })
        .catch((error) => {
          console.log("error in deleting holiday entry");
          console.log(error);
        });
    }
  }

  renderHolidayEntries(entriesData) {
    //console.log("entriesData");
    //console.log(this.props.slaHolidayEntriesList.data)
    //alert(this.props.isFetchingDetails)
    if (this.props.isFetchingDetails) {
      return <div>{this.props.translator["loading..."]}</div>;
    } else if (this.props.slaHolidayEntriesList.data == null) {
      return <div>{this.props.translator["No Data Found"]}</div>;
    } else {
      return entriesData.data.scheduleList.map((objjectData) => {
        return (
          <tr>
            <td>{objjectData.holidayName}</td>
            <td>{objjectData.date}</td>
            <td>
              <div className="text-c">
                <i
                  title="Edit Component"
                  onClick={() => {
                    this.getEditDetailsHoliday(true, "edit", objjectData.id);
                  }}
                  className="fa fa-pencil-square-o cursorPoint"
                ></i>
                <i
                  className="margin-l-10 fa fa-trash-o cursorPoint"
                  onClick={() => {
                    this.deleteHolidayEntry(objjectData.id);
                  }}
                ></i>
              </div>
            </td>
          </tr>
        );
      });
    }
  }

  changeSearchField(e) {
    console.log("eeeeeeeeeeeeeee", e.target.value);
    // e.preventdefault();
    this.setState({ searchString: e.target.value, searchStringErrorLabel: "" });
    // this.props.loadSearchStringResults('TT43');
    // console.log('this.props',this.props);
    // if(e && e.length >= 3){
    // 	this.props.loadSearchStringResults(e);
    // }
    // else{
    // 	alert('Please specify search string...')
    // }
  }

  keyPress(e) {
    // console.log('value---',this.state);
    // e.preventdefault();
    if (e.keyCode == 13) {
      if (
        this.state &&
        this.state.searchString &&
        this.state.searchString.length >= 3
      ) {
        // console.log('value', e.target.value);
        this.props.loadSearchStringResults(this.state.searchString);
      } else {
        this.setState({
          searchStringErrorLabel: "Please enter atleast 3 characters...",
        });
        let that = this;
        setTimeout(function () {
          that.setState({ searchStringErrorLabel: "" });
        }, 3000);
      }
    }
  }

  renderSearchStringResults(event) {
    console.log(
      "event------------",
      event.target.value,
      this.state.searchString
    );
    // console.log('value---',this.state);
    // this.setState({searchStringErrorLabel: ''});
    if (event.target.value == undefined) {
      this.setState({ searchStringErrorLabel: "Please enter keyword..." });
      let that = this;
      setTimeout(function () {
        that.setState({ searchStringErrorLabel: "" });
      }, 3000);
    } else if (this.state && this.state.searchString) {
      // console.log('value', e.target.value);
      this.setState({ searchStringErrorLabel: "" });
      this.props.loadSearchStringResults(this.state.searchString);
    } else {
      this.setState({ searchStringErrorLabel: "Please enter keyword..." });
      let that = this;
      setTimeout(function () {
        that.setState({ searchStringErrorLabel: "" });
      }, 3000);
    }

    // if(this.state && this.state.searchString){
    // 	this.props.loadArticleResultsForSearchString(this.state.searchString);
    // }
  }

  render() {
    return (
      <div className="stickyArea rBoxStyle">
        <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
          <ul>
            <li>
              {this.state.showEditForm == "edit" ? null : (
                <a href={void(0)}
                  onClick={() => {
                    this.formToggle(true, "add");
                  }}
                  title={this.props.translator["Add"]}
                ><HiPlus/>
                </a>
              )}
            </li>
            <li className="d-md-none">
              <button
                  type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                }}
                >
                <IoClose/>
                </button>
              </li>
          </ul>
        </div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {this.props.translator["New Holiday"]}
          </div>
        </div>
        {!this.state.showAddForm ? (
          <div className="rBoxGap">
            <Row className="">
              <Col lg={8} md={12} sm={8} xs={12}>
                <div className="form-inline rightSideFields d-lg-flex d-md-block d-sm-flex d-block">
                  <Form.Select className="myControl mySel mySelectWidth">
                    <option value="1">Holiday Name</option>
                    <option value="2">Date</option>
                  </Form.Select>
                  {/*<FormControl className="myControl myInput margin-l-5 myInputWidths" type="text" onChange={(event) => {this.changeSearchField(event.target.value)}} onKeyDown={this.keyPress}/>*/}
                  <div className="d-flex">
                  <input
                    type="text"
                    name="searchString"
                    className="form-control myControl myInput margin-l-5 myInputWidth"
                    value={this.state.searchString}
                    onChange={this.changeSearchField}
                    placeholder={this.props.translator["Enter keyword..."]}
                  />

                  <Button
                    type="button"
                    className="myBtn"
                    onClick={(event) => {
                      this.renderSearchStringResults(event);
                    }}
                  >
                    <IoSearch/>
                  </Button>
                  </div>
                </div>
                <div className="inlineFields">
                  <span style={{ color: "red" }}>
                    {this.state.searchStringErrorLabel}
                  </span>
                </div>
              </Col>
              <Col lg={4} md={12} sm={4} xs={12}>
                <div className="text-r">
                  <Form.Label className="margin-r-5 display-inline-block widthAuto padding-t-5 f-normal">
                    {this.props.translator["Show"]}
                  </Form.Label>
                  <Form.Select className=" display-inline-block widthAuto">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
            <div className="f-size-12">
              <Table bordered hover responsive className="tableView sortImg margin-xs-t-15">
                <thead>
                  <tr>
                    <th width="60%" className="sort">
                      {this.props.translator["Holiday Name"]}
                    </th>
                    <th width="30%" className="sort">
                      {this.props.translator["Holiday Date"]}
                    </th>
                    <th width="10%" className="text-c">
                      {this.props.translator["Action"]}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderHolidayEntries(this.props.slaHolidayEntriesList)}
                </tbody>
              </Table>
            </div>
            <Row className="margin-t-10 labelInline rightSideFields">
              <Col md={8} sm={8} xs={12}>
                <div className="dataShow text-r">
                  <Pagination
                    className="margin-0"
                    prev
                    next
                    boundaryLinks
                    items={10}
                    maxButtons={3}
                    activePage={1}
                  />
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="rBoxGap">
            <HolidayEntryForm
              scheduleId={this.props.initialValues.scheduleId}
              holidayId={this.state.holidayId}
              showEditForm={this.state.showEditForm}
              translator={this.props.translator}
              formToggle={this.formToggle}
            />
          </div>
        )}
      </div>
    );
  }
}

export function mapStateToProps({
  slaHolidayScheduleDetails,
  slaHolidayEntriesList,
  sLaHolidayFetchingDetails,
}) {
  return {
    initialValues: slaHolidayScheduleDetails.slaHolidayScheduleDetails,
    slaHolidayScheduleDetails,
    slaHolidayEntriesList,
    isFetchingDetails: sLaHolidayFetchingDetails.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { loadSlaHolidayEntries, loadSearchStringResults },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HolidayEntryFormIndex);

//export default arrtibuteForm;
