
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {ImInfo} from 'react-icons/im';

import { Field, reduxForm, formValueSelector, change, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { GLOBAL } from "_Globals";
import _ from 'lodash';
import CIDetailsService from '_Commons/WorkItemBoard/ciNameSelectEdit';
import { getPriorityUrgencyCriticalityValues } from '_Actions/common/commonApisActions';
import { loadBreakFixSourceName } from '_Actions/breakFix/sourceAction';
import { loadBreakFixSG } from '_Actions/breakFix/serviceGroupAction';
import { loadBreakFixSGI, loadBreakFixSGISuccess } from '_Actions/breakFix/serviceGroupIAction';
import { loadBreakFixEditDetails } from '_Actions/breakFix/breakFixEditDetailsAction';
import { loadBreakFixStatusDetails } from '_Actions/breakFix/breakFixStatusAction';
import { loadNewBreakFixSGAE, loadBreakFixSGAESuccess } from '_Actions/breakFix/serviceGroupAEAction';
import ListLoader from '_Commons/loaders/ListLoader.js';
import GenericTimeline from '_Commons/WorkItemBoard/genericTimeline';
import {getCIGroupData,getAllgroup} from '_Actions/amsActions/amsActions';
import RFReactConsumer from '_Commons/WorkItemBoard/AutoSearchConsumer.js';
import { RuleAmsDdropDown } from "../../../common/forms/ruleDropDown";
import {
	TypeaheadExampleSingleSelect
} from "_Commons/formFields";
import { RiUserSearchLine } from 'react-icons/ri';
import { CategorizationForm } from '../../incident/create/CategorizationForm';
import { opsCategoryKeysMap, problemStatusMapping } from '../../../common/helper';
import AiPopup from '../../../common/aiPopup';

// import { AsyncTypeahead } from 'react-bootstrap-typeahead';

const api = axios.create({
	headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

let breakFixActualItemId = '';
let serviceData = [];
let serviceNameDisplay = "";
let enableButtonData = [];
let myObj = [];
let ciNameData = [];
let ciNameDataDetails=[];
let ciNameObject=[];

let InvestigationEditForm = class InvestigationEditForm extends React.Component {
	constructor(props) {
		GLOBAL.breakfixongrpchangeid = "";
		super(props);
		this.state = {
			searchResultsDiv: false,
			readOnly: true,
			statusActionValue: "",
			supportPGroup: '',
			supportPIndividual: '',
			priorityValue: '',
			priorityId:'',
			urgencyValue: '',
			companyId:'',
			AllAMSGrps:false,
            ShowAllAMSGRP:true,
			supportPGroupErrorColor:'',
			consumerDataDetails:[],
			consumer:'',
			selectedIndividualList: [],
			selectedGroups: [],
			isGroupOptionRendered: false,
			isIndividualOptionRendered: false,
			isBreakfixSGLoaded: false,
			typedGroup: '',
			typedIndividual: '',
			cancelreasonCharLeft:2000,
			requestorValue: '',
			showRequestorInfo: false,
			individualValue: '',
			showIndividualInfo: false,
			isCiNameInfoIcn:false,
			companyList:[],
			companyError:'',
			selectedCompanyList: [],
			typedCompany: '',
			isServiceDesk: false,
			errorImpactedCI: '',
			rulesAttribute: {},
			isProbStatementAi: false,
			isProbSymptomAi: false,
		};

		ciNameData = [];
		ciNameDataDetails=[];
		ciNameObject=[];
		GLOBAL.ciNameEdit="";GLOBAL.ciIdEdit="";GLOBAL.ciIdNumberEdit="";
		this.renderSupportPAEGroup = this.renderSupportPAEGroup.bind(this);
		this.AllGrps=this.AllGrps.bind(this);
		this.AllAMSGrps=this.AllAMSGrps.bind(this);
    this.ShowAMSGroup=this.ShowAMSGroup.bind(this);
	this.validateField = this.validateField.bind(this);
	this.setErrorColor = this.setErrorColor.bind(this);
	this.onIndividualSelection = this.onIndividualSelection.bind(this);
	this.getIndividualOptions = this.getIndividualOptions.bind(this);
	this.getGroupOptions = this.getGroupOptions.bind(this);
	this.setSelectGroup = this.setSelectGroup.bind(this);
	this.seterrorGroupcolor = this.seterrorGroupcolor.bind(this);
	this.onServiceSelect = this.onServiceSelect.bind(this);
	this.descriptionCharacter=this.descriptionCharacter.bind(this);

	this.setGroupIndividualValue = this.setGroupIndividualValue.bind(this);

	this.renderSupportAMSGroup=this.renderSupportAMSGroup.bind(this);

	this.onGroupInputChange = this.onGroupInputChange.bind(this);
	this.onCrossClickGroup = this.onCrossClickGroup.bind(this);
	this.onIndividualInputChange = this.onIndividualInputChange.bind(this);
	this.onCrossClickIndividual = this.onCrossClickIndividual.bind(this);
	this.onCompanySelection = this.onCompanySelection.bind(this);
	this.setErrorCompanyColor = this.setErrorCompanyColor.bind(this);
	this.companyInputChange = this.companyInputChange.bind(this);
	this.onCrossClickCompany = this.onCrossClickCompany.bind(this);
	this.setConsumerCompany = this.setConsumerCompany.bind(this);
	this.checkforAMSattributes=this.checkforAMSattributes.bind(this);
	this.afterConsumerSelected=this.afterConsumerSelected.bind(this);
	this.resettingConsumerInfoState=this.resettingConsumerInfoState.bind(this);
	this.resetImpactedCIValue = this.resetImpactedCIValue.bind(this);
	this.onRequestorType = this.onRequestorType.bind(this);
	this.onImpactCIType = this.onImpactCIType.bind(this);
    this.setErrorColorImpactedCI = this.setErrorColorImpactedCI.bind(this);
	this.clearGroupandIndividual=this.clearGroupandIndividual.bind(this);
	this.handleStatementAiPopup=this.handleStatementAiPopup.bind(this);
	this.handleSymptomAiPopup=this.handleSymptomAiPopup.bind(this);

	GLOBAL.cancelreasonCharLeft=2000,
        GLOBAL.cancelreasonCharMax=2000
	}

	setErrorColor(errorColor, fieldType) {
		this.setState({ consumer: errorColor});
	}
	setErrorColorImpactedCI(errorColor, fieldType){
		this.setState({errorImpactedCI: errorColor});
	}

	searchResults(event) {
		this.setState({ searchResultsDiv: true });
	}
	callbackFun(id) {
		this.setState({ searchResultsDiv: id });
	}

    componentWillUnmount(){
		GLOBAL.sgErrorColor=''
		GLOBAL.issueErrorColor=''
		GLOBAL.sourceErrorColor=''
		GLOBAL.criticalityErrorColor=''
		GLOBAL.urgencyErrorColor=''
		// console.log("component will unmount");
		GLOBAL.AMSEXIST="yes";
		GLOBAL.companyError='';
		GLOBAL.businessCriticalityId='';
		GLOBAL.businessCriticality='';
	}

	componentDidMount() {

		const categorizationObj = Object.values(opsCategoryKeysMap).reduce((acc, key) => {
			if (key in this.props.investigationItemDetails) {
			  acc[key] = this.props.investigationItemDetails[key];
			}
			return acc;
		  }, {})
		GLOBAL.categorizationObj = Object.keys(categorizationObj).length > 0 ? categorizationObj : {};

		GLOBAL.breakFixServiceID=GLOBAL.ciId=GLOBAL.breakFixConsumerID=GLOBAL.servicelocationid=GLOBAL.ciLocationId=GLOBAL.ciClassId=GLOBAL.businessCriticalityId=GLOBAL.businessCriticality='';

		GLOBAL.ciNameEdit=this.props.investigationItemDetails.ciName,
		GLOBAL.breakFixConsumerName=this.props.investigationItemDetails.impactedUsername,
        GLOBAL.isExternalInvestigationEdit=(this.props.investigationItemDetails.isExternal==null || this.props.investigationItemDetails.isExternal==undefined || this.props.investigationItemDetails.isExternal== "") ? false : this.props.investigationItemDetails.isExternal;
	//	console.log(this.props.fetchinvestigationItemDetails.serviceId)
	this.setState({
		AllAMSGrps:false
	  });
		this.props.getPriorityUrgencyCriticalityValues("Investigation", "Criticality");
		this.props.getPriorityUrgencyCriticalityValues("Investigation", "Urgency");
		this.props.getPriorityUrgencyCriticalityValues("Investigation", "source");
		this.props.loadBreakFixSourceName();
		GLOBAL.priorityValueType = this.props.investigationItemDetails.priorityValue
		GLOBAL.supportPGroupNameEdit = this.props.investigationItemDetails.spGroupname
		GLOBAL.supportPIGroupNameEdit = this.props.investigationItemDetails.spUsername;
		this.checkforAMSattributes('initial',-1);
		this.props.loadBreakFixSGI(this.props.investigationItemDetails.asgPmGroupid);

		let consumerCompanyID = (this.props.investigationItemDetails && this.props.investigationItemDetails.consumerCompany && !isNaN(parseInt(this.props.investigationItemDetails.consumerCompany, 10)) ) ? parseInt(this.props.investigationItemDetails.consumerCompany, 10) : '';

		if(typeof this.props.investigationItemDetails.description!=='undefined' && this.props.investigationItemDetails.description!=null){
		    this.setState({ cancelreasonCharLeft:GLOBAL.cancelreasonCharMax-this.props.investigationItemDetails.description.length });
	    }
		this.setState({
			companyId:consumerCompanyID
		});

		this.props.setUnchangedData(this.props.investigationItemDetails);

		if(this.props && this.props.investigationItemDetails && this.props.investigationItemDetails.impactedUserid && !isNaN(this.props.investigationItemDetails.impactedUserid) && !!parseInt(this.props.investigationItemDetails.impactedUserid,10)){
			this.setState({requestorValue: this.props.investigationItemDetails.impactedUserid, showRequestorInfo: true});
		}
		else{
			this.setState({requestorValue: '', showRequestorInfo: false});
		}
        if(this.props && this.props.investigationItemDetails && this.props.investigationItemDetails.ciId && !isNaN(this.props.investigationItemDetails.ciId) && !!parseInt(this.props.investigationItemDetails.ciId,10)){
			this.setState({impactedIDSelected: this.props.investigationItemDetails.ciId, isCiNameInfoIcn: true});
		}else{
			this.setState({impactedIDSelected: '', isCiNameInfoIcn: false});
		}
		if(this.props && this.props.investigationItemDetails && this.props.investigationItemDetails.asgPmId && !isNaN(this.props.investigationItemDetails.asgPmId) && !!parseInt(this.props.investigationItemDetails.asgPmId,10)){
			this.setState({individualValue: this.props.investigationItemDetails.asgPmId, showIndividualInfo: true});
		}
		else{
			this.setState({individualValue: '', showIndividualInfo: false});
		}

		api.get('/api/serviceportfolio/usercompanylist').then((responseCompany)=>{
			// console.log('responseCompany.data - ', responseCompany.data);
			let arr = [];
			responseCompany.data.forEach(item => {
				let obj = item;
				obj['label'] = item['field1Key'];
				obj['id'] = item['field1Value'];
				arr.push(obj);
			});
			this.setState({companyList: arr});
			// this.state.companyList=arr;
		});

		this.setConsumerCompany();
		if(this.props.investigationItemDetails.status_id == problemStatusMapping['Draft'] && this.props.roleIdentification('53')){
			this.setState({isServiceDesk: true});
		}else if(!(this.props.roleIdentification('17') || this.props.roleIdentification('16')||this.props.roleIdentification('53'))){
		  this.setState({isServiceDesk: this.props.roleIdentification('48')});
		}
		this.setGroupIndividualValue();
		this.props.dispatch(change("investigationEditForm","supportCompanyID",this.props.investigationItemDetails.supportCompanyId));
		this.props.dispatch(change("investigationEditForm","supportCompanyName",this.props.investigationItemDetails.supportCompanyName));
	}

	componentWillReceiveProps(nextProps){
		// console.log('$$$$$$$$$$$$$', {props: this.props, nextProps, state: this.state});
		if(this.props.selectedAssignmentUser !== nextProps.selectedAssignmentUser){
			this.onIndividualSelection(nextProps.selectedAssignmentUser);
		}

	}
	onAdditionalInfoChange(e) {
		this.setState({
		  additionalInfo: e.target.value
		});
		this.props.dispatch(
			change("investigationEditForm", "additionalInfo", e.target.value)
		  );
	  }
	descriptionCharacter(e, from="self"){
	if (from !== "self"){
		this.setState({issueDescription: e.target.value})
		this.props.dispatch(
				change("investigationEditForm", "issueDescription", e.target.value)
			  );
	}
	else
	{		
		    let input = e.target.value.length;
			GLOBAL.cancelreasonCharLeft = GLOBAL.cancelreasonCharMax-input;
			this.setState({
				cancelreasonCharLeft:GLOBAL.cancelreasonCharMax-input
			})
            console.log("e.target.value",input,GLOBAL.cancelreasonCharLeft);
	}
}
	AllGrps(e)
	{
	  e.preventDefault();
	  console.log("dbdjhc");
	 this.props.getAllgroup(this.props.investigationItemDetails.consumerCompany);
	  this.setState({
		AllAMSGrps:true,
		ShowAllAMSGRP:false,
		individualValue: '', showIndividualInfo: false
	  });
	//   GLOBAL.AMSGRP="yes";
	  GLOBAL.AMSEXIST="yes";

	  this.setSelectGroup([]);
	}
	AllAMSGrps(e)
	{
		e.preventDefault();
		console.log("ALLAMSGRP ")
		this.checkforAMSattributes();
		// this.props.loadBreakFixSGAE(this.props.investigationItemDetails.ciId, this.props.investigationItemDetails.consumerCompany, "Investigation","impactedCI");
        this.setState({
			ShowAllAMSGRP:true,
			AllAMSGrps:false,
			individualValue: '', showIndividualInfo: false
		})
		GLOBAL.AMSEXIST="";

		this.setSelectGroup([]);
	}
	validateField(fieldType, event) {
		switch (fieldType) {

			case 'supportPGroup':
				if(this.props.investigationItemDetails.status_id != problemStatusMapping["Draft"]){
				if (event.target.value == "0") {
					this.setState({ supportPGroupErrorColor: 'error' });
					GLOBAL.sgErrorColor = "";
				} else {
					this.setState({ supportPGroupErrorColor: '' });
					GLOBAL.sgErrorColor = "";
				}
			}
				break;
			// case 'supportPIndividual':
			// 	if (event.target.value == 0) {
			// 		this.setState({ supportPIndividualErrorColor: 'error' });
			// 		GLOBAL.sgIErrorColor = "";
			// 	} else {
			// 		this.setState({ supportPIndividualErrorColor: '' });
			// 		GLOBAL.sgIErrorColor = "";
			// 	}
			// 	break;

			default:

		}
	}
	renderSourceName(breakFixData) {
		let optionsList = [];
		if (!breakFixData) {
			return (
				<option></option>
			);
		}
		for (let i = 0; i < breakFixData.length; i++) {
			if (breakFixData[i].field1Value == this.props.investigationItemDetails.reportedThrough) {
				optionsList.push(<option value={breakFixData[i].field1Value}>{breakFixData[i].field1Key}</option>)
			}
		}
		for (let i = 0; i < breakFixData.length; i++) {
			if (breakFixData[i].field1Value != this.props.investigationItemDetails.reportedThrough) {
				optionsList.push(<option value={breakFixData[i].field1Value}>{breakFixData[i].field1Key}</option>)
			}
		}
		return optionsList;
	}

	//defect no 5
	//==========
	onChangeCriticality(e) {
		//let criticalityValue= this.props.criticality;

		console.log('e - ',e);
		// console.log('this.props.criticality - ',this.props.criticality);
		// console.log('this.props.urgencyMode - ',this.props.urgencyMode);

		let criticalityValue = "";
		// if(this.props.criticality=="High"){
		// 	criticalityValue=20
		// }else if(this.props.criticality=="Medium"){
		// 	criticalityValue=25
		// }else if(this.props.criticality=="Low"){
		// 	criticalityValue=30
		// }
		let eventTargetValue = (e && e.target && e.target.value) ? e.target.value : '';
		// alert(eventTargetValue)

		if (eventTargetValue == "Significant"||eventTargetValue==20) {
			criticalityValue = 20
		} else if (eventTargetValue == "Moderate"||eventTargetValue==25) {
			criticalityValue = 25
		} else if (eventTargetValue == "Low"||eventTargetValue==30) {
			criticalityValue = 30
		} else {
		    criticalityValue = 0
		}
        console.log("infor",eventTargetValue,e.target.value);
		let urgencyValue = this.props.urgencyMode===null?0:this.props.urgencyMode;
		let priorityData = '';
		let priorityActualValue = '';
		let priorityActualColor = '';
		let priorityUrl = `/serviceManagement/rest/priority?module=Investigation&criticality=${criticalityValue}&urgency=${urgencyValue}`;
		axios.get(priorityUrl).then((response) => {
		
			priorityData = response.data;
			priorityActualValue = priorityData.priorityValue;
			priorityActualColor = priorityData.priorityColor;
			GLOBAL.priorityColorType = priorityActualColor;
			this.setState({
				priorityValue: priorityActualValue,
				priorityColorValue: priorityActualColor,
				priorityId: priorityData.priority
			});
			this.checkforAMSattributes('impact',priorityData.priority);
		});
	}

	//defect no 5
	//==========
	onChangeUrgency(e) {
		//alert(this.props.breakFixEditDetails.priorityId);
		//alert(e.target.value)
		//console.log("urgency")
		//alert(this.props.criticality)
		//console.log(e)

		// console.log('e - ',e);
		//    console.log('this.props.criticality - ',this.props.criticality);
		//    console.log('this.props.urgencyMode - ',this.props.urgencyMode);

		let criticalityValue = "";
		if (this.props.criticality == "Significant"||this.props.criticality==20) {
			criticalityValue = 20
		} else if (this.props.criticality == "Moderate"||this.props.criticality==25) {
			criticalityValue = 25
		} else if (this.props.criticality == "Low"||this.props.criticality==30) {
			criticalityValue = 30
		} else {
			criticalityValue = 0
		}
      console.log("information",this.props.criticality);
		let urgencyValue = e.target.value==='' || e.target.value===null ?0:e.target.value;
		//alert("criticalityValue"+criticalityValue)
		//console.log(criticalityValue)

		let priorityData = '';
		let priorityActualValue = '';
		let priorityActualColor = '';
		let priorityUrl = `/serviceManagement/rest/priority?module=Investigation&criticality=${criticalityValue}&urgency=${urgencyValue}`;
		axios.get(priorityUrl).then((response) => {
			priorityData = response.data;
			priorityActualValue = priorityData.priorityValue;
			priorityActualColor = priorityData.priorityColor;
			GLOBAL.priorityColorType = priorityActualColor;
			this.setState({
				priorityValue: priorityActualValue,
				priorityColorValue: priorityActualColor,
				priorityId: priorityData.priority
			});
			this.checkforAMSattributes('urgency',priorityData.priority);
		});
	}

	renderCriticalityName(breakFixData) {
		breakFixData.map((a)=>{
			a.field1Value=parseInt(a.field1Value, 10);

		})
		breakFixData.sort((a, b) => (a.field1Value > b.field1Value) ? 1 : -1);

		if (!breakFixData) {
			return (
				null
			);
		}
		return breakFixData.map((breakFixObj) => {

			return (
				<option value={breakFixObj.field1Value}>{breakFixObj.field1Key}</option>
			);
		});

	}

	renderSupportAMSGroup(breakFixData)
	{
        console.log("renderSupportPAEGrouprenderabcfdkndknd");
let grouplist = [];
let isFieldEditable = (this.props.investigationItemDetails && this.props.investigationItemDetails.status_id && (this.props.investigationItemDetails.status_id == problemStatusMapping["Cancelled"] || this.props.investigationItemDetails.status_id == problemStatusMapping["Closed"]) ) ? true : false;
		if (!breakFixData || breakFixData.length == 0) {
		//	this.props.loadBreakFixSGAE(this.props.investigationItemDetails.serviceId, this.props.investigationItemDetails.consumerCompany, "Investigation");
		//	this.props.loadBreakFixSGI(this.props.investigationItemDetails.asgPmGroupid);
			return (
				null
			);
		}


		// this.props.setBreakFixSGAEName(breakFixData);
		if((breakFixData!=""||breakFixData.length!= 0))
		breakFixData.map((breakFixObj) => {
			grouplist.push(breakFixObj.assignmentGroupId + "~" + breakFixObj.assignmentGroupName);
		});
		//  console.log(grouplist.toString());
        // grouplist.push(this.props.investigationItemDetails.asgPmGroupid+"~"+this.props.investigationItemDetails.asgPmGroupname);

		grouplist = grouplist.filter(function (item, index, inputArray) {
			return inputArray.indexOf(item) == index;
		});
		//console.log("b:::"+grouplist.toString());
        // console.log("grouplist",grouplist);
		return grouplist.map((breakFixObj) => {
			let breakfixobj1 = breakFixObj.split("~");
			return (
				<option value={breakfixobj1[0]} disabled={isFieldEditable}>{breakfixobj1[1]}</option>
			);
		});
	}
	renderUrgencyName(urgencyData) {
		let isFieldEditable = (this.props.investigationItemDetails && this.props.investigationItemDetails.status_id && (this.props.investigationItemDetails.status_id == problemStatusMapping["Cancelled"] || this.props.investigationItemDetails.status_id == problemStatusMapping["Closed"]) ) ? true : false;
		let sorteddata = [];
		if (!urgencyData) {
			return (
				null
			);
		}
		sorteddata = _.sortBy(urgencyData, o => parseInt(o.field1Value, 10));

		return sorteddata.map((value, index) => {
			return (
				<option value={value.field1Value} key={index} disabled={isFieldEditable}>{value.field1Key}</option>
			);
		});
	}

	onGroupNameChange(e) {
		/**
		 * previous condition: (this.props.supportPGroup != "")
		 */
		if ( e && e.target && e.target.value ) {
			this.props.loadBreakFixSGI(e.target.value);
		} else {
			console.log("Error in loading loadBreakFixSGI");
		}
		this.props.dispatch(change("investigationEditForm", "supportPIndividualName", 'Select..'));
		this.props.dispatch(change("investigationEditForm", "supportPIndividual", '0'));
	}

	renderSupportPGroup(breakFixData) {

		// if (this.props.investigationItemDetails.length != 0 && this.state.isBreakfixSGLoaded === false) {
		// 	// console.log('renderSupportPGroup');
		// 	this.props.loadBreakFixSG(this.props.investigationItemDetails.serviceId);
		// 	this.setState({isBreakfixSGLoaded: true});
		// }
		let optionsList = [];
		if (!breakFixData || breakFixData.length == 0) {
			return (
				<option></option>
			);
		}
		if (GLOBAL.breakFixServiceID != "" || breakFixData.length != 0) {
			//this.props.loadBreakFixSGI(breakFixData[0].field1Value);
			for (let i = 0; i < breakFixData.length; i++) {
				if (breakFixData[i].field1Key == this.props.investigationItemDetails.spGroupid) {
					optionsList.push(<option value={breakFixData[i].field1Value}>{breakFixData[i].field1Key}</option>)
				}
			}
			for (let i = 0; i < breakFixData.length; i++) {
				if (breakFixData[i].field1Key != this.props.investigationItemDetails.spGroupid) {
					optionsList.push(<option value={breakFixData[i].field1Value}>{breakFixData[i].field1Key}</option>)
				}
			}
			return optionsList;
		} else {
			return(
				<option>no data</option>
			)
			
		}
	}

	renderSupportPAEGroup(breakFixData) {
//alert("hi"+this.props.investigationItemDetails.serviceId);
console.log("renderSupportPAEGrouprenderSupportPAEGroup",breakFixData);
let grouplist = [];
let isFieldEditable = (this.props.investigationItemDetails && this.props.investigationItemDetails.status_id && (this.props.investigationItemDetails.status_id == problemStatusMapping["Cancelled"] || this.props.investigationItemDetails.status_id == problemStatusMapping["Closed"]) ) ? true : false;
		if ((!breakFixData || breakFixData.length == 0)&&(this.props.investigationItemDetails.asgPmGroupid==0||this.props.investigationItemDetails.asgPmGroupid==null)) {
		//	this.props.loadBreakFixSGAE(this.props.investigationItemDetails.serviceId, this.props.investigationItemDetails.consumerCompany, "Investigation");
		//	this.props.loadBreakFixSGI(this.props.investigationItemDetails.asgPmGroupid);
			return (
				null
			);
		}

       if((breakFixData!=""||breakFixData.length!= 0))
		// this.props.setBreakFixSGAEName(breakFixData);
		breakFixData.map((breakFixObj) => {
			grouplist.push(breakFixObj.assignmentGroupId + "~" + breakFixObj.assignmentGroupName)
		});
		//  console.log(grouplist.toString());
		if(this.props.investigationItemDetails.asgPmGroupname!==null)
        grouplist.push(this.props.investigationItemDetails.asgPmGroupid+"~"+this.props.investigationItemDetails.asgPmGroupname);

		grouplist = grouplist.filter(function (item, index, inputArray) {
			return inputArray.indexOf(item) == index;
		});
		//console.log("b:::"+grouplist.toString());
        // console.log("grouplist",grouplist);
		return grouplist.map((breakFixObj) => {
			let breakfixobj1 = breakFixObj.split("~");
			return (
				<option value={breakfixobj1[0]} disabled={isFieldEditable}>{breakfixobj1[1]}</option>
			);
		});
	}

	renderSupportPIGroup(breakFixData) {
		let optionsList = [];
		let isFieldEditable = (this.props.investigationItemDetails && this.props.investigationItemDetails.status_id && (this.props.investigationItemDetails.status_id == problemStatusMapping["Cancelled"] || this.props.investigationItemDetails.status_id == problemStatusMapping["Closed"]) ) ? true : false;
		if (!breakFixData || breakFixData.length == 0) {
			return (
				<option disabled={isFieldEditable}></option>
			);
		}
		if (this.props.supportPGroup != '') {
			for (let i = 0; i < breakFixData.length; i++) {
				if (breakFixData[i].field1Key == this.props.investigationItemDetails.asgPmId) {
					optionsList.push(<option value={breakFixData[i].field1Value} disabled={isFieldEditable}>{breakFixData[i].field1Key}</option>)
				}
			}
			for (let i = 0; i < breakFixData.length; i++) {
				if (breakFixData[i].field1Key != this.props.investigationItemDetails.asgPmId) {
					optionsList.push(<option value={breakFixData[i].field1Value} disabled={isFieldEditable}>{breakFixData[i].field1Key}</option>)
				}
			}
			return optionsList;
		} else {
			return(
				<option disabled={isFieldEditable}>no data</option>
			)
		
		}
	}

	renderSupportPIAEGroup(breakFixData) {

let isFieldEditable = (this.props.investigationItemDetails && this.props.investigationItemDetails.status_id && (this.props.investigationItemDetails.status_id == problemStatusMapping["Cancelled"] || this.props.investigationItemDetails.status_id == problemStatusMapping["Closed"]) ) ? true : false;
		if (!breakFixData || breakFixData.length == 0) {
			return (
				null
			);
		}

		if (GLOBAL.breakfixongrpchangeid != "")
			GLOBAL.breakfixgroupid = GLOBAL.breakfixongrpchangeid;
		else
			GLOBAL.breakfixgroupid = this.props.investigationItemDetails.asgPmGroupid;
		return breakFixData.map((breakFixObj) => {
			if (breakFixObj.assignmentGroupId == GLOBAL.breakfixgroupid && breakFixObj.assignmentMemberId != 0 && breakFixObj.assignmentMemberId != null)
				return (
					<option value={breakFixObj.assignmentMemberId} disabled={isFieldEditable}>{breakFixObj.assignmentMemberName}</option>
				);
		});
	}

	setDropDownValue(event, fieldType) {
		console.log("setDROP")
		switch (fieldType) {

			case 'supportPGroup':
				GLOBAL.sgErrorColor = "";
				this.setState({ supportPGroup: event.target.value });
				break;
			case 'supportPIndividual':
				this.setState({ supportPIndividual: event.target.value });
				break;


			default:

		}
	}
	ShowAMSGroup(breakFixData)
	{

	  console.log("breakFixDatagropup",breakFixData=="")
		let grouplist = [];
		let type=typeof(breakFixData);
		console.log("sabc",type);
		if(breakFixData=="" || breakFixData.length==0||type=="string"){
		  return(
			  null
		  );
		}
	else{
	breakFixData.map((breakFixObj)=>{
	  grouplist.push(breakFixObj.groupName+"~"+breakFixObj.groupId);
	});
	  //  console.log(grouplist.toString());

		// grouplist = grouplist.filter( function( item, index, inputArray ) {
		//        return inputArray.indexOf(item) == index;
		// });
		//console.log("b:::"+grouplist.toString());

		return grouplist.map((breakFixObj)=>{
		  let breakfixobj1=breakFixObj.split("~");
		 return(
				 <option value={breakfixobj1[1]}>{breakfixobj1[0]}</option>
			   );
		 });
		}
	}

	onIndividualSelection(selected){
		// console.log('selected - ', selected);
		this.setState({selectedIndividualList: selected});
		let event = {
			target: {
				text: 'Select..',
				value: '0'
			}
		};
		if(selected && selected.length === 1){
			event['target']['value'] = selected[0]['id'];
			event['target']['text'] = selected[0]['label'];
			this.setState({individualValue: event.target.value, showIndividualInfo: true})
		}else{
			this.setState({individualValue: '', showIndividualInfo: false})
		}

		// this.setDropDownValue('supportPIndividual', event);
		this.setState({ supportPIndividual: event.target.value });
		console.log("event.target.text",event.target.text);
		this.props.dispatch(change("investigationEditForm", "supportPIndividual", event.target.value));
		this.props.dispatch(change("investigationEditForm", "supportPIndividualName", event.target.text));
	}

	getIndividualOptions(groupId, indivOptions){
		// debugger;
		const {breakFixSGIName = []} = this.props;
		// console.log('(((((((((((((((', breakFixSGIName);
		let arr = [];
		let optionsArr = (indivOptions && indivOptions.length) ? JSON.parse(JSON.stringify(indivOptions)) : JSON.parse(JSON.stringify(breakFixSGIName));
		if ( !optionsArr || optionsArr.length == 0 ) {
			return arr;
		}
		// previous IF condition: (this.props.supportPGroup != '')
		if ((groupId) || (this.state.selectedGroups && this.state.selectedGroups.length === 1)) {
			for (let i = 0; i < optionsArr.length; i++) {
				let obj = JSON.parse(JSON.stringify(optionsArr[i]));
				obj['id'] = optionsArr[i].field1Value;
				obj['label'] = optionsArr[i].field1Key;
				arr.push(obj);
			}
			return arr;
		} else {
			return arr;
		}
	}

	getGroupOptions(){

		// GLOBAL.AMSEXIST=="yes"?
		// (this.state.AllAMSGrps==false?this.renderSupportPAEGroup(this.props.breakFixSGAEName):this.ShowAMSGroup(this.props.allgroupdata))
		// 	:this.renderSupportAMSGroup(this.props.breakFixSGAEName)

		// debugger;

		if(GLOBAL.AMSEXIST=="yes"){
			if(this.state.AllAMSGrps==false){
				// this.renderSupportPAEGroup(this.props.breakFixSGAEName)
				// console.log("renderSupportPAEGrouprenderSupportPAEGroup",(this.props.breakFixSGAEName));
				let grouplist = [];
						if ((!(this.props.breakFixSGAEName) || (this.props.breakFixSGAEName).length == 0)&&(this.props.investigationItemDetails.asgPmGroupid==0||this.props.investigationItemDetails.asgPmGroupid==null)) {
							return (
								[]
							);
						}

					if(((this.props.breakFixSGAEName)!=""||(this.props.breakFixSGAEName).length!= 0)){
						(this.props.breakFixSGAEName).map((breakFixObj) => {
							let obj = {};
							obj['id'] = breakFixObj.assignmentGroupId;
							obj['label'] = breakFixObj.assignmentGroupName;
							obj['isExternal'] = breakFixObj.isExternal;
							obj['supportCompanyID']= breakFixObj.assignmentCompanyId;
			                obj['supportCompanyName']= breakFixObj.assignmentCompanyName;
							grouplist.push(obj);
						});
					}
						//  console.log(grouplist.toString());
						// if(this.props.investigationItemDetails.asgPmGroupname!==null){
						// 	let obj = {};
						// 	obj['id'] = this.props.investigationItemDetails.asgPmGroupid;
						// 	obj['label'] = this.props.investigationItemDetails.asgPmGroupname;
						// 	grouplist.push(obj);
						// }

						grouplist = grouplist.filter(function (item, index, inputArray) {
							return inputArray.indexOf(item) == index;
						});

						grouplist = grouplist.filter((item, index, self) =>
							index === self.findIndex((t) => (
								t.id === item.id && t.label === item.label
							))
						);
						//console.log("b:::"+grouplist.toString());
						// console.log("grouplist",grouplist);
						return grouplist;
			}
			else{
				// this.ShowAMSGroup(this.props.allgroupdata)
				// console.log("breakFixDatagropup",this.props.allgroupdata=="")
				let grouplist = [];
				let type=typeof(this.props.allgroupdata);
				// console.log("sabc",type);
				if(this.props.allgroupdata=="" || this.props.allgroupdata.length==0||type=="string"){
				  return(
					  []
				  );
				}
				else{
					this.props.allgroupdata.map((breakFixObj)=>{
						let obj = {};
						obj['id'] = breakFixObj.groupId;
						obj['label'] = breakFixObj.groupName;
						obj['isExternal'] = breakFixObj.isExternal;
						obj['supportCompanyID']= breakFixObj.supportCompanyID;
			            obj['supportCompanyName']= breakFixObj.supportCompanyName;
						grouplist.push(obj);
					});
				}
				return grouplist;
			}
		}
		else{
			// this.renderSupportAMSGroup(this.props.breakFixSGAEName)
			let grouplist = [];
			if (!(this.props.breakFixSGAEName) || (this.props.breakFixSGAEName).length == 0) {
				return (
					[]
				);
			}
			if(((this.props.breakFixSGAEName)!=""||(this.props.breakFixSGAEName).length!= 0)){
				(this.props.breakFixSGAEName).map((breakFixObj) => {
					let obj = {};
					obj['id'] = breakFixObj.assignmentGroupId;
					obj['label'] = breakFixObj.assignmentGroupName;
					obj['isExternal'] = breakFixObj.isExternal;
					obj['supportCompanyID']= breakFixObj.assignmentCompanyId;
					obj['supportCompanyName']= breakFixObj.assignmentCompanyName;
					grouplist.push(obj);
				});
			}

			grouplist = grouplist.filter(function (item, index, inputArray) {
				return inputArray.indexOf(item) == index;
			});
			grouplist = grouplist.filter((item, index, self) =>
				index === self.findIndex((t) => (
					t.id === item.id && t.label === item.label
				))
			);
			return grouplist;
		}
	}

	setSelectGroup(selected){
console.log('selected[0]===');
console.log(selected[0]);
		this.props.loadBreakFixSGISuccess({data: []});
		this.setState({selectedGroups: selected});
		this.props.setAssignmentGrpUsr(selected,"group");
		let event = {
			target: {
				text: '',
				value: ''
			}
		};
		if(selected && selected.length === 1){
			event['target']['value'] = selected[0]['id'];
			event['target']['text'] = selected[0]['label'];
			this.props.dispatch(change("investigationEditForm","supportCompanyID",selected[0]['supportCompanyID']));
		    this.props.dispatch(change("investigationEditForm","supportCompanyName",selected[0]['supportCompanyName']));
		}
		// this.setDropDownValue('supportPGroup', event);
		GLOBAL.sgErrorColor = "";
		this.setState({ supportPGroup: event.target.value, individualValue: '', showIndividualInfo: false });
		this.onGroupNameChange(event);
		console.log('selected - ',event.target.text);

		this.props.dispatch(change("investigationEditForm", "supportPGroup", event.target.value));
		this.props.dispatch(change("investigationEditForm", "supportPGroupName", event.target.text));
		GLOBAL.breakfixgroupid = event.target.value;
		GLOBAL.breakfixongrpchangeid = event.target.value;
		if(selected.length!==0)
		GLOBAL.isExternalInvestigationEdit=(selected[0]['isExternal']==null || selected[0]['isExternal']==undefined || selected[0]['isExternal']== "") ? false : selected[0]['isExternal'];
		this.onIndividualSelection([]);
	}

	seterrorGroupcolor(){
		if(this.props.investigationItemDetails.status_id!= problemStatusMapping["Draft"]){
		if(this.state.selectedGroups.length === 1){
			this.setState({supportPGroupErrorColor: ''});
		}
		else{
			this.setState({supportPGroupErrorColor: 'error'});
		}
	}
	}

	onServiceSelect(){
		// debugger;
		// this.props.dispatch(change("investigationEditForm", "supportPGroup", asgPmGroupid));
		// this.props.dispatch(change("investigationEditForm", "supportPGroupName", asgPmGroupname));
		this.setState({
			AllAMSGrps:false
		  });
		if(GLOBAL.ciIdEdit!='')
		{
			//if(this.state.RadioValue=='impactedCI'){
				this.checkforAMSattributes();
				 //this.setState({companyId:GLOBAL.ciConsumerCompany})
				 this.props.dispatch(change("createInvestigationForm", "supportPGroup", ''));
				 this.props.dispatch(change("createInvestigationForm", "supportPGroupName", ''));
		         this.props.dispatch({
					 type: 'LOAD_BREAKFIXSGI_SUCCESS',
					 breakFixSGIName: {data: ''}
				 })
				 //GLOBAL.breakFixConsumerID = "";
		         GLOBAL.breakFixConsumerName = "";
				 GLOBAL.CIError ='';

				 this.props.loadBreakFixSGAESuccess([]);
				//  this.setState({selectedGroups: []});
				 this.setSelectGroup([]);
				//  this.onIndividualSelection([]);
			//}
		}
		let impactedCIValue=GLOBAL.ciIdEdit!==''&&GLOBAL.ciIdEdit!=='0'&&GLOBAL.ciIdEdit!==0?GLOBAL.ciIdEdit:this.props.investigationItemDetails.ciId!==''&&this.props.investigationItemDetails.ciId!=='0'&&this.props.investigationItemDetails.ciId!==0?this.props.investigationItemDetails.ciId:'';
		this.setState({impactedIDSelected: impactedCIValue, isCiNameInfoIcn: impactedCIValue!==''?true:false});
	}

	setGroupIndividualValue() {
		// const { isGroupOptionRendered, isIndividualOptionRendered } = this.state;
		// console.log('-----------', {state: this.state, props: this.props});

		const {
			investigationItemDetails: {
				asgPmGroupid = '',
				asgPmGroupname = '',
				asgPmId = '0',
				asgPmName = 'Select..',
			}
		} = this.props;

		if(asgPmGroupid && asgPmGroupname){
			let arr = [];
			arr.push({
				id: asgPmGroupid,
				label: asgPmGroupname
			});
			this.setState({selectedGroups: arr});
			this.props.setAssignmentGrpUsr(arr,"group");
		}
		if(asgPmId && asgPmId !== '0' && asgPmName && asgPmName !== 'Select..'){
			let arr = [];
			arr.push({
				id: asgPmId,
				label: asgPmName
			});
			this.setState({selectedIndividualList: arr});
		}

		// let groupOptions = this.getGroupOptions();
		// if(!isGroupOptionRendered && (groupOptions && groupOptions.length)){
		// 	const {
		// 		investigationItemDetails: {
		// 			asgPmGroupid = '',
		// 			asgPmGroupname = '',
		// 		}
		// 	} = this.props;
		// 	this.setState({ supportPGroup: asgPmGroupid });
		// 	this.props.dispatch(change("investigationEditForm", "supportPGroup", asgPmGroupid));
		// 	this.props.dispatch(change("investigationEditForm", "supportPGroupName", asgPmGroupname));

		// 	let selectedGroup = groupOptions.filter(item => {
		// 		return ((['string', 'number'].includes(typeof item.id) && ['string', 'number'].includes(typeof asgPmGroupid)) && ((item.id).toString() === asgPmGroupid.toString()) );
		// 	});
		// 	this.setState({selectedGroups: selectedGroup, isGroupOptionRendered: true});

		// }

		// if((!isIndividualOptionRendered) && (nextProps.breakFixSGIName && nextProps.breakFixSGIName.length)){
		// 	const {
		// 		investigationItemDetails: {
		// 			asgPmGroupid = '',
		// 			asgPmGroupname = '',
		// 			asgPmId = '0',
		// 			asgPmName = 'Select..',
		// 		}
		// 	} = this.props;

		// 	this.setState({ supportPIndividual: asgPmId });
		// 	this.props.dispatch(change("investigationEditForm", "supportPIndividual", asgPmId));
		// 	this.props.dispatch(change("investigationEditForm", "supportPIndividualName", asgPmName));

		// 	let individualOptions = this.getIndividualOptions(asgPmGroupid, nextProps.breakFixSGIName);
		// 	let selectedIndividual = individualOptions.filter(item => {
		// 		return ( (['string', 'number'].includes(typeof item.id) && ['string', 'number'].includes(typeof asgPmGroupid)) && ((item.id).toString() === asgPmId.toString()) );
		// 	});
		// 	this.setState({selectedIndividualList: selectedIndividual, isIndividualOptionRendered: true});

		// }
	}

	onGroupInputChange(e){
		this.setState({
		  typedGroup: e,
		  individualValue: '', showIndividualInfo: false
		 });
		 this.setSelectGroup([]);
	  }
	  onCrossClickGroup(){
		this.onGroupInputChange('');
		this.setState({typedIndividual: ''});
		if(this.props.investigationItemDetails.status_id != problemStatusMapping["Draft"]){
			this.setState({supportPGroupErrorColor: 'error'});
		}
		this.props.dispatch({type:"RESET_BREAKFIXSGI"})
		this.props.setAssignmentGrpUsr([],"group");
		// this.onSupGroupSelection([]);
	  }

	  onIndividualInputChange(e){
		// console.log('e - ',e);
		this.setState({
		  typedIndividual: e,
		  individualValue: '', showIndividualInfo: false
		 });
		 this.onIndividualSelection([]);
	  }
	  onCrossClickIndividual(){
		this.onIndividualInputChange('');
		// this.onIndivSelection([]);
	  }
	  onCompanySelection(selectedCompany) {
		// console.log('selectedCompany - ',selectedCompany);
		this.setState({ selectedCompanyList: selectedCompany });

		let event = {
			target: {
				value: '',
				text: ''
			}
		};
		const { AllAMSGrps } = this.state;
		if(selectedCompany && selectedCompany.length === 1){
			if(AllAMSGrps == true){
				this.props.getAllgroup(selectedCompany[0]['id']);
				GLOBAL.AMSEXIST="yes";
			}
			this.setSelectGroup([]);

			event['target']['value'] = selectedCompany[0]['id'];
			event['target']['text'] = selectedCompany[0]['label'];
		}

		let groupList=[];
		this.setState({ companyId: event.target.value, selectedGroups: [], selectedIndividualList: [] });
		this.props.setAssignmentGrpUsr([],"group");
		// GLOBAL.breakFixConsumerID='',GLOBAL.ciId='',GLOBAL.ciConsumerCompany='';
		this.props.dispatch(change('investigationEditForm','company',event.target.value));
		this.props.dispatch(change('investigationEditForm','companyName',event.target.text));
		this.props.dispatch(change('investigationEditForm','supportPIndividualName',''));
		this.props.dispatch(change('investigationEditForm','supportPIndividual',''));
		this.props.dispatch(change('investigationEditForm','implementationIndividual',''));
		this.props.dispatch(change('investigationEditForm','supportPGroupName',''));
		this.props.dispatch(change('investigationEditForm','supportPGroup',''));
		this.props.dispatch({type:'GROUP_LIST',groupList});
	  }

	setErrorCompanyColor(){
		if(this.state.selectedCompanyList.length === 1){
			this.setState({companyError: ''});
		}
		else{
			this.setState({companyError: 'error'});
		}
	}

	companyInputChange(e){
		// GLOBAL.breakFixConsumerID='',GLOBAL.ciId='';
		this.setState({
			typedCompany: e,
			selectedCompanyList: []
		});
		this.onCompanySelection([]);
	}

	onCrossClickCompany(){
		// GLOBAL.breakFixConsumerID='',GLOBAL.ciId='';
		this.companyInputChange('');
	}

	setConsumerCompany() {
		// const { isGroupOptionRendered, isIndividualOptionRendered } = this.state;
		// console.log('-----------', {state: this.state, props: this.props});

		const {
			investigationItemDetails: {
				consumerCompany = '',
				consumerCompanyName = '',
			}
		} = this.props;

		if(consumerCompany && consumerCompanyName){
			let arr = [];
			arr.push({
				id: consumerCompany,
				label: consumerCompanyName
			});
			this.setState({selectedCompanyList: arr});
		}
	}
	clearGroupandIndividual(){
		this.props.dispatch(change('investigationEditForm','supportPIndividualName',''));
		this.props.dispatch(change('investigationEditForm','supportPIndividual',''));
		this.props.dispatch(change('investigationEditForm','implementationIndividual',''));
		this.props.dispatch(change('investigationEditForm','supportPGroupName',''));
		this.props.dispatch(change('investigationEditForm','supportPGroup',''));
		this.props.dispatch({type:'GROUP_LIST', groupList: []});
		this.setState({selectedGroups: [], selectedIndividualList: [], disableTypeAhead: true });
		this.props.setAssignmentGrpUsr([],"group");
	}
	checkforAMSattributes(fieldName,value){
		if(fieldName!=='initial' && value!==-1){
			this.props.dispatch({type: 'GROUP_LIST_TYPE_AHEAD_INITIAL', typeaheadData: []});
			this.props.dispatch({type: '', typeaheadData: []});
			this.props.dispatch({type: '', typeaheadData: []});
			this.setState({selectedGroups: [], selectedIndividualList: [], typedGroup: '', typedIndividual: '', showIndividualInfo: false, AllAMSGrps: false});
			this.props.dispatch(change("investigationEditForm", "supportPGroup", ''));
			this.props.dispatch(change("investigationEditForm", "supportPGroupName", ''));
		}
		let amsAttribute={
			"Status": this.props.investigationItemDetails.status_id,
			"Priority":(fieldName==='urgency'||fieldName==='impact') && value!=='' ? value : this.state.priorityValue===""?this.props.investigationItemDetails.priorityValue:this.state.priorityValue,
			"Service": '0',
			"Impacted CI": GLOBAL.ciIdEdit==='' ? this.props.investigationItemDetails.ciId : GLOBAL.ciIdEdit,
			"Requestor": GLOBAL.breakFixConsumerID==='' ? this.props.investigationItemDetails.impactedUserid : GLOBAL.breakFixConsumerID,
			"Class": GLOBAL.ciClassIdEdit===''? this.props.investigationItemDetails.ciClassId : GLOBAL.ciClassIdEdit,
			"User.Location": GLOBAL.servicelocationid===''?this.props.investigationItemDetails.requesterLocationId:GLOBAL.servicelocationid,
			"CI.Location": GLOBAL.ciLocationIdEdit==='' ? this.props.investigationItemDetails.ciLocationId : GLOBAL.ciLocationIdEdit
		};
	
		let rulesAttribute={
			"status": this.props.investigationItemDetails.status_id,
			"priorityId": (fieldName==='urgency'||fieldName==='impact') && value!=='' ? value : this.state.priorityId===""?this.props.investigationItemDetails.priority:this.state.priorityId,
			"serviceName": undefined,
			"impactedCi":  GLOBAL.ciNameEdit==='' ? this.props.investigationItemDetails.ciName : GLOBAL.ciNameEdit,
			"impactedUsername": GLOBAL.breakFixConsumerName === "" ? this.props.investigationItemDetails?.impactedUsername : GLOBAL.breakFixConsumerName,
			"ciClassName": GLOBAL.ciClass===''? this.props.investigationItemDetails?.ciClassName : GLOBAL.ciClass,
			"userLocation": GLOBAL.servicelocationname===''?this.props.investigationItemDetails.requesterLocationName:GLOBAL.servicelocationname,
			"ciLocation": GLOBAL.ciLocationNameEdit==='' ? this.props.investigationItemDetails.ciLocationName : GLOBAL.ciLocationNameEdit,
			"impactedCiId": GLOBAL.ciIdEdit==='' ? this.props.investigationItemDetails.ciId : GLOBAL.ciIdEdit,
			"requestorId": GLOBAL.breakFixConsumerID==='' ? this.props.investigationItemDetails.impactedUserid : GLOBAL.breakFixConsumerID,
			"classId": GLOBAL.ciClassIdEdit===''? this.props.investigationItemDetails.ciClassId : GLOBAL.ciClassIdEdit,
			"userLocationId": GLOBAL.servicelocationid===''?this.props.investigationItemDetails.requesterLocationId:GLOBAL.servicelocationid,
			"ciLocationId": GLOBAL.ciLocationIdEdit==='' ? this.props.investigationItemDetails.ciLocationId : GLOBAL.ciLocationIdEdit
		};
		this.setState({ rulesAttribute });
		// this.props.loadNewBreakFixSGAE(GLOBAL.ciIdEdit==='' ? this.props.investigationItemDetails.ciId : GLOBAL.ciIdEdit, this.props.investigationItemDetails.consumerCompany, "Investigation","impactedCI",amsAttribute);
	}
	afterConsumerSelected(){
		let value=GLOBAL.breakFixConsumerID!==''&&GLOBAL.breakFixConsumerID!=='0'&&GLOBAL.breakFixConsumerID!==0?GLOBAL.breakFixConsumerID:this.props.investigationItemDetails.impactedUserid!==''&&this.props.investigationItemDetails.impactedUserid!=='0'&&this.props.investigationItemDetails.impactedUserid!==0?this.props.investigationItemDetails.impactedUserid:'';
		this.setState({requestorValue: value, showRequestorInfo: value!==''?true:false});
		this.checkforAMSattributes();
	}
	resettingConsumerInfoState(value = ''){
		value=value!=''&&value!='0'&&value!=0?value:this.props.investigationItemDetails.impactedUserid!=''&&this.props.investigationItemDetails.impactedUserid!='0'&&this.props.investigationItemDetails.impactedUserid!=0?this.props.investigationItemDetails.impactedUserid:'';
		this.setState({requestorValue: value, showRequestorInfo: value!==''?true:false});
	}
	resetImpactedCIValue(){
		let impactedCIValue=this.props.investigationItemDetails.ciId!==''&&this.props.investigationItemDetails.ciId!=='0'&&this.props.investigationItemDetails.ciId!==0?this.props.investigationItemDetails.ciId:'';
		this.setState({impactedIDSelected: impactedCIValue, isCiNameInfoIcn: impactedCIValue!==''?true:false});
	}
	onRequestorType(value){
		this.setState({showRequestorInfo: value});
	}
	onImpactCIType(value){
		this.setState({isCiNameInfoIcn: value});
	}

	handleStatementAiPopup(val) {
		this.setState({ isProbStatementAi: val, isProbSymptomAi: false });
	  }
	  
	  handleSymptomAiPopup(val) {
		this.setState({ isProbStatementAi: false, isProbSymptomAi: val });
	  }

	render() {
		// console.log('**************', {state: this.state, props: this.props});
		/* if current status of investigation item is Cancelled than it should
		come in non-editable mode */
		console.log("this.state.AllAMSGrpsthis.state.AllAMSGrps",GLOBAL.sgErrorColor);
		console.log("GLOBAL.AMSEXIST",this.props);
		 let Disable=true;
		// console.log('this.props',this.props)
		let isFieldEditable = (this.props.investigationItemDetails && this.props.investigationItemDetails.status_id && (this.props.investigationItemDetails.status_id == problemStatusMapping["Cancelled"] || this.props.investigationItemDetails.status_id == problemStatusMapping["Closed"]) ) ? true : false;
		let isFieldDisable = ((this.props.investigationItemDetails.status_id == problemStatusMapping["Under Corrective Action"] ) ? true : false);
        let Disable1=(this.props.roleIdentification('14')==true);
		// console.log('isFieldEditable', isFieldEditable);
        let Disable2=(this.props.roleIdentification('48')==true);
		if(Disable1==true||Disable2==true)
		{
            Disable=false;
		}
		GLOBAL.investigationId = this.props.investigationItemDetails.investigationId;
		if (this.props.investigationItemDetails.investigationId == undefined || this.props.isFetchingInvestigation) {
			return (
				<ListLoader/>
				// <div className='rctLoader'>
				// 	<OverlayLoader color={'blue'} loader="ScaleLoader" text="Loading..." active={true} backgroundColor={'black'} opacity=".0" />
				// </div>
			);
		}else{
			GLOBAL.ciId=this.props.investigationItemDetails.ciId;
				GLOBAL.ciName=this.props.investigationItemDetails.ciName;
				//alert("GLOBAL.ciId1"+GLOBAL.ciId)
		}


		//let priorityField;
		if (this.state.priorityValue == "") {
			//alert("M1")
			this.props.dispatch(change("investigationEditForm", "priority", this.props.investigationItemDetails.priorityValue));

		} else {
			//alert("M2"+this.state.priorityValue)
			GLOBAL.priorityValueType = this.state.priorityValue;
			this.props.dispatch(change("investigationEditForm", "priority", this.state.priorityValue));
		}
		let { error, handleSubmit, pristine, reset, submitting, consumer, issueDescription, additionalInfo, reportedOn, reportedThrough, supportPGroup, supportPIndividual, criticality, urgencyMode } = this.props
		const errormsg=this.props.translator[error];

		// console.log('****************', {state: this.state, props: this.props});

		const { investigationItemDetails : { status_id = "" } = {} } = this.props;

		let groupOptions = this.getGroupOptions();
		let individualOptions = this.getIndividualOptions();
		let {isServiceDesk } = this.state;
		// console.log('individualOptions - ', individualOptions)

		return (
			<div>
				{this.props && this.props.showTimelineFlag ?
					<GenericTimeline investigationStatus={this.props.investigationItemDetails} auditDetails={this.props.breakFixAuditLogDetails} translator={this.props.translator} isFetching={this.props.isFetchingTimeline.isFetching} isFetchingItemDetails={this.props.isFetching} itemId={this.props.investigationItemDetails.investigationId} module={"Investigation"} showTimeline={this.props.showTimeline}/>
				: null}
				<font color="red">{errormsg && <strong>{errormsg}</strong>}</font>
				<div className="borderForm bFormDiv">
					<Row>
						<Col md={6}>
								<Form.Group>
								<Form.Label bsClass=""><span className="rStar" /> {this.props.translator['Company']}</Form.Label>
								<Field name="companyName" type="text" disabled="true" className="form-control" component="input" />
								</Form.Group>
						</Col>

						<Col md={6}>
							<Form.Group>
								<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Requestor']}</Form.Label>
								<div className="infoBtnDiv typhdRig placeholderActive">
									{(this.props.fieldStatus.Requestor==true || (status_id && status_id.toString() == "30" && !isServiceDesk) ) && !this.props.disableFieldsForGuestRole?<div className="infoBtnDiv">
									<Field name="consumer"
										options={this.state.consumerDataDetails}
										companyId={this.state.companyId}
										onCreateUsingSelect={'impactedCI'}
										placeHolder={this.props.investigationItemDetails.impactedUsername}
										component={RFReactConsumer} setErrorColor={this.setErrorColor}
										afterConsumerSelected={this.afterConsumerSelected}
										resettingConsumerInfoState={this.resettingConsumerInfoState}
										errorColor={this.state.consumer == '' ? GLOBAL.consumerErrorColor : this.state.consumer}
										onType={this.onRequestorType}						
										>
									</Field>
									</div>
									:
									<Field name="consumer" component="input" type="text" disabled={true} className="form-control" placeholder={this.props.investigationItemDetails.impactedUsername}  />}
									<Field name="consumerID" type="hidden" disabled="true" className="form-control" component="input"/>
									{this.state.showRequestorInfo ?
									<Button title="Info" bsPrefix=" " className="infoicn" onClick={() => { this.props.changeActionView('consumer', this.state.requestorValue, 'Requestor Details'); this.props.rightEditPanel(true); }}><ImInfo/></Button>
									: null }
								</div>
							</Form.Group>
						</Col>

						<Col md={6}>
							<Form.Group >
								<Form.Label bsClass="">{<span className="rStar"></span>}{this.props.translator['Impacted CI']}</Form.Label>
								<div className="infoBtnDiv placeholderActive">
								{this.props.fieldStatus.impactedCI==false || this.props.disableFieldsForGuestRole?
									<Field name="ciName" component="input" type="text" disabled={(this.props.fieldStatus.impactedCI==true || (status_id && status_id.toString() == "30" && !isServiceDesk) ) && !this.props.disableFieldsForGuestRole?false:true} className="form-control" placeholder={this.props.investigationItemDetails.ciName}  />

									: <Field name="ciName" options={ciNameDataDetails} disabled={(this.props.fieldStatus.impactedCI==true || (status_id && status_id.toString() == "30" && !isServiceDesk) ) && !this.props.disableFieldsForGuestRole?false:true}companyId={this.state.companyId} component={CIDetailsService} onServiceSelect={this.onServiceSelect} showImpactedCIInfoIcon={this.resetImpactedCIValue} onType={this.onImpactCIType} setErrorServiceColor={this.setErrorColorImpactedCI} errorColor={this.state.errorImpactedCI}/>
								}
								{this.state.isCiNameInfoIcn?<Button title="Info" bsPrefix=" " className="infoicn" onClick={() => { this.props.changeActionView('ciId', this.state.impactedIDSelected); this.props.rightEditPanel(true); }}><ImInfo/></Button>:null}
								</div>
							</Form.Group>
					</Col>
					
						<Col md={6}>
							<Form.Group >
								<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Source']}</Form.Label>
								{/*  {this.renderSupportPGroup(this.props.breakFixSGName)}*/}
								<Field name="source" component="select" type="select" className="form-control" disabled={(this.props.fieldStatus.source==true || (status_id && status_id.toString() == "30" && !isServiceDesk) ) && !this.props.disableFieldsForGuestRole?false:true}  >
									{this.renderSupportPGroup(this.props.investigationSource)}
								</Field>

							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<Form.Group className="position-re" ref={(e) => (this.probStatementRef = e)}>
							{this.props.aiSummaruzationEnabled && <AiPopup
							text={this.props.issueDesc}
							disabled={(this.props.fieldStatus.problemStatement==true || (status_id && status_id.toString() == "30" && !isServiceDesk) ) && !this.props.disableFieldsForGuestRole?false:true}
							onChange={(text)=>this.descriptionCharacter({target:{value:text}}, "aiTool")}
							show={this.state.isProbStatementAi}
							setShow={this.handleStatementAiPopup}
							containerRef={this.probStatementRef}
							/>}
							{/* <Form.Group> */}
								<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator[' Problem Statement']}</Form.Label>
								<div className="position-re">
									<Field name="issueDescription" component="textarea"  maxLength="2000" disabled={(this.props.fieldStatus.problemStatement==true || (status_id && status_id.toString() == "30" && !isServiceDesk) ) && !this.props.disableFieldsForGuestRole?false:true} onChange={(event)=>{this.descriptionCharacter(event)}} className={GLOBAL.issueErrorColor==='error'?"form-control error":"form-control"} rows={2} /* placeholder={this.props.investigationItemDetails.description} */ >
										{this.props.investigationItemDetails.description}
									</Field>
									<p className="charLeft" style={{textAlign:"right","fontSize":"11px"}}>({this.props.translator['Characters Remaining']}: {this.state.cancelreasonCharLeft}/2000)</p>

								</div>
							</Form.Group>
						</Col>

						<Col md={12}>
							<Form.Group className="position-re" ref={(e) => (this.probSymptomRef = e)}>
							{this.props.aiSummaruzationEnabled && <AiPopup
								text={this.props.issueAddiInfo}
								disabled={(this.props.fieldStatus.problemSymptoms==true|| (status_id && status_id.toString() == "30" && !isServiceDesk)) && !this.props.disableFieldsForGuestRole?false:true}
								onChange={(text)=>this.onAdditionalInfoChange({target:{value:text}}, "aiTool")}
								show={this.state.isProbSymptomAi}
								setShow={this.handleSymptomAiPopup}
								containerRef={this.probSymptomRef}
								/>}
								<Form.Label bsClass="">{this.props.translator['Problem Symptoms']}</Form.Label>
								<Field name="additionalInfo" component="textarea" disabled={(this.props.fieldStatus.problemSymptoms==true|| (status_id && status_id.toString() == "30" && !isServiceDesk)) && !this.props.disableFieldsForGuestRole?false:true} onChange={(e) => {this.onAdditionalInfoChange(e);}} className="form-control" rows={2} placeholder={this.props.investigationItemDetails.symptoms}>
									{this.props.investigationItemDetails.symptoms}
								</Field>
							</Form.Group>
						</Col>

					</Row>
					{/* Categorization start here */}
					<CategorizationForm
						heading="Categorization"
						opsLabelKey="opsCategory"
						opsValueKey="opsCategoryId"
						module="Problem"
						companyId={this.state.companyId}
						formName="investigationEditForm"
						formType="edit"
						initialValues={
							Object.values(opsCategoryKeysMap).reduce((acc, key) => {
								  acc[key] = this.props.investigationItemDetails[key];
								return acc;
							}, {})
						}
						disabled={this.props.disableFieldsForGuestRole ? true : this.props.isServiceDeskRolesEnabled ? true : false}
						fieldsStatuses={
							Object.values(opsCategoryKeysMap).reduce((acc, key) => {
								  acc[key] = this.props.fieldStatus[key];
								return acc;
							}, {})
						}
						resetGroupsandIndividual={this.clearGroupandIndividual}
						/>
					{/* Categorization end here */}
					<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
						<div className="bgGray padding-5">{this.props.translator['Prioritization']}</div>
						<Row>
							<Col md={6}>
								<Row>
								<Col md={6}>
										<Form.Group>
											<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Urgency']}</Form.Label>
											<Field name="urgencyMode"  disabled={(this.props.fieldStatus.urgency==true || (status_id && status_id.toString() == "30" && !isServiceDesk) ) && !this.props.disableFieldsForGuestRole?false:true} component="select" type="select" className="form-control" onChange={(event) => { this.onChangeUrgency(event); }} >
												{this.renderUrgencyName(this.props.investigationUrgency)}
											</Field>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group>
											<Form.Label bsClass=""><span className="rStar"></span>{this.props.translator['Impact']}</Form.Label>
											<Field name="criticality" disabled={(this.props.fieldStatus.impact==true || (status_id && status_id.toString() == "30" && !isServiceDesk) ) && !this.props.disableFieldsForGuestRole?false:true} component="select" type="select"className="form-control"  onChange={(event) => { this.onChangeCriticality(event); }}>
											{this.renderCriticalityName(this.props.investigationCriticality)}
											</Field>
										</Form.Group>
									</Col>

								</Row>
							</Col>
							<Col md={6}>
								<Form.Group>
									<Form.Label bsClass=""><span className="rStar"></span> {this.props.translator['Priority']}</Form.Label>
									<Field name="priority" component="input" type="text" className="form-control" readOnly  />
								</Form.Group>
							</Col>
						</Row>
					</div>
					<div className="padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
						<div className="bgGray padding-5 inciSupProviderDv">{this.props.translator['Assignment']}{this.props.roleIdentification('17')||this.props.roleIdentification('53')||this.props.roleIdentification('48')?(this.props.fieldStatus.showAllGroups==true || (status_id && status_id.toString() == "30" && !isServiceDesk && !this.props.roleIdentification('16')) )?<span><span> {this.state.AllAMSGrps==false?<a className="showwAllGrps"  href='javascript:void(0)' onClick={this.AllGrps}>Show ALL Groups</a>:<a className="showwAllGrps"  href='javascript:void(0)' onClick={this.AllAMSGrps}>Show AMS Groups</a>}</span></span>:"":""}</div>
						<Row>
							<Col md={6}>
								<Form.Group>
									<Form.Label bsClass="">{this.props.investigationItemDetails.status_id == problemStatusMapping["Draft"] ? '':<span className="rStar"></span>}{this.props.translator['Problem Mgmt. Group']}</Form.Label>
									<div className="dvTypehdropdown">
								   <RuleAmsDdropDown 
									component={TypeaheadExampleSingleSelect}
									showAms={this.state?.AllAMSGrps}
									onSelection={this.setSelectGroup}
									setErrorColor={this.seterrorGroupcolor}
									name="supportPGroup"
									className="form-control"
									errorClass={this.state.supportPGroupErrorColor==='error' || GLOBAL.sgErrorColor==='error'?'error':''}
									groupData={groupOptions}
									selectedOptions={this.state.selectedGroups}
									disabled={(this.props.fieldStatus.problemMgtGroupIndividual==true || (status_id && status_id.toString() == "30" && !isServiceDesk) ) && !this.props.disableFieldsForGuestRole?false:true}
									onInputChange={this.onGroupInputChange}
									onCrossClick={this.onCrossClickGroup}
									typedValue={this.state.typedGroup}
									moduleName="problem"
									ruleType="assignment"
									formData={this.props}
									rulesAttribute={this.state?.rulesAttribute}
									formName="investigationEditForm"
									ruleFor="problemManager"
									hasMissingCategoryField={this.props.hasMissingCategoryField}
									categorizationFields={this.props.categorizationFields}
									/>
									{/* <Field component={TypeaheadExampleSingleSelect}
										onSelection={this.setSelectGroup}
										setErrorColor={this.seterrorGroupcolor}
										name="supportPGroup"
										className="form-control"
										errorClass={this.state.supportPGroupErrorColor==='error' || GLOBAL.sgErrorColor==='error'?'error':''}
										options={groupOptions}
										selectedOptions={this.state.selectedGroups}
										disabled={(this.props.fieldStatus.problemMgtGroupIndividual==true || (status_id && status_id.toString() == "30" && !isServiceDesk) ) && !this.props.disableFieldsForGuestRole?false:true}
										onInputChange={this.onGroupInputChange}
										onCrossClick={this.onCrossClickGroup}
										typedValue={this.state.typedGroup}
										>
									</Field> */}
									</div>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className={this.state.showIndividualInfo ? "prblmBtnDv infoBtnDiv typeHeadicn downinfobtn form-group" : "chngBtnDv infoBtnDiv typeHeadicn downinfobtn form-group"}>
									<Form.Label bsClass="">{this.props.translator['Problem Manager']}</Form.Label>
									<div className="dvTypehdropdown">
									<Field component={TypeaheadExampleSingleSelect}
										onSelection={this.onIndividualSelection}
										// setErrorColor={this.setErrorIndividualColor}
										name="supportPIndividual"
										className="form-control"
										options={individualOptions}
										selectedOptions={this.state.selectedIndividualList}
										disabled={(this.props.fieldStatus.problemMgtGroupIndividual==true || (status_id && status_id.toString() == "30" && !isServiceDesk) ) && !this.props.disableFieldsForGuestRole?false:true}
										onInputChange={this.onIndividualInputChange}
										onCrossClick={this.onCrossClickIndividual}
										typedValue={this.state.typedIndividual}
										>
									</Field>
									<div className={`${this.state.showIndividualInfo || this.state.selectedGroups.length>0?'indiIcnDv':""}`}>	
									{this.state.selectedGroups.length>0?(
										<Button 
										title={this.props.translator["Show available individuals"]}
										bsPrefix=" " 
										className="asignicn"
										disabled={(this.props.fieldStatus.problemMgtGroupIndividual==true || (status_id && status_id.toString() == "30" && !isServiceDesk) ) && !this.props.disableFieldsForGuestRole?false:true} 
										onClick={() => { 
										  this.props.changeActionView("showAvailableIndividuals");
										  this.props.rightEditPanel(true);}}>
										  <RiUserSearchLine />
										  </Button>
										  ):""
										}
									{this.state.showIndividualInfo ?
									<Button title="Info" bsPrefix=" " className="asignicn" onClick={() => { this.props.changeActionView('individual', this.state.individualValue, 'Individual Details'); this.props.rightEditPanel(true); }}><ImInfo/></Button>
									: null } 
									</div>
									{/* this.props.supportPIndividual !=='0' */}
									{/* {this.state.selectedIndividualList.length =='1' ?<Button title="Info" bsClass="" className="infoicn" onClick={() => { this.props.changeActionView('proManager');this.props.proManagerId(this.props.supportPIndividual)}}><i className="fa fa-info-circle" aria-hidden="true"></i></Button>:null} */}
									</div>
								</Form.Group>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		);
	}
}

InvestigationEditForm = reduxForm({
	form: 'investigationEditForm',
	fields: [...Object.keys(GLOBAL.categorizationObj), 'consumer', 'issueDescription', 'additionalInfo', 'reportedOn', 'reportedThrough', 'supportPGroup', 'supportPIndividual', 'criticality', 'urgencyMode', 'priority','source'],
	destroyOnUnmount: false,
	enableReinitialize: true
})(InvestigationEditForm);

let selector = formValueSelector('investigationEditForm') // <-- same as form name
InvestigationEditForm = connect(
	state => {
		// can select values individually
		//let impactedServiceName = selector(state, 'impactedServiceName');
		let consumer = selector(state, consumer);
		let issueDescription = selector(state, 'issueDescription');
		let additionalInfo = selector(state, 'additionalInfo');
		let reportedOn = selector(state, 'reportedOn');
		let reportedThrough = selector(state, 'reportedThrough');
		let supportPGroup = selector(state, 'supportPGroup');

		let supportPIndividual = selector(state, 'supportPIndividual');
		let criticality = selector(state, 'criticality');
		let urgencyMode = selector(state, 'urgencyMode');
		let priority = selector(state, 'priority');
    let source=selector(state,'source');
		let ItemList = getFormValues("investigationEditForm")(state);
	    let	issueDesc = selector(state, 'issueDescription');
		let issueAddiInfo = selector(state, 'additionalInfo');
		let hasMissingCategoryField = GLOBAL.categorizationDropdowns > 0;
		let categorizationFields = {};
		for(let i = 1; i <= GLOBAL.categorizationDropdowns; i++){
			let key = opsCategoryKeysMap[`opsCategoryId${i}`];
			let key2 = opsCategoryKeysMap[`opsCategory${i}`];
			hasMissingCategoryField = !selector(state, key) || selector(state, key) == "0";
			if (selector(state, key) && selector(state, key) != "0") {
				categorizationFields[key] = selector(state, key);
				categorizationFields[key2] = selector(state, key2);
			}
		}

		return {
			consumer,
			issueDescription,
			additionalInfo,
			reportedOn,
			reportedThrough,
			supportPGroup,
			supportPIndividual,
			criticality,
			urgencyMode,
			priority,
			source,
			ItemList,
			hasMissingCategoryField,
			categorizationFields,
			issueDesc:issueDesc,
			issueAddiInfo:issueAddiInfo
		}
	}
)(InvestigationEditForm)

export function mapStateToProps({ breakFixSourceName, fieldStatus,breakFixCriticalityName, breakFixUrgencyName, breakFixSGName, breakFixSGIName,isFetchingDetails,
	investigationItemDetails,isFetchingTimeline, breakFixStatusDetails, breakFixSGAEName, investigationCriticality,investigationSource,breakFixAuditLogDetails,
	investigationUrgency,allgroupdata, ItemList}) {
	let urgencyValue;
	let criticalvalue;
	 let investigationItemDetailsData = (investigationItemDetails && investigationItemDetails.data ? investigationItemDetails.data[0]:investigationItemDetails);
	 console.log("investigationItemDetailsData",investigationItemDetailsData);
	 let urgencyObj = {
		"5": "High",
		"10": "Medium",
		"15": "Low"
	 };
	 if(investigationItemDetailsData.urgency && !isNaN(investigationItemDetailsData.urgency)){
		investigationItemDetailsData.urgency = urgencyObj[investigationItemDetailsData.urgency] || "";
	 }

	 let criticalityObj = {
		"20": "Significant",
		"25": "Moderate",
		"30": "Low"
	 };
	 if(investigationItemDetailsData.serviceCriticality && !isNaN(investigationItemDetailsData.serviceCriticality)){
		investigationItemDetailsData.serviceCriticality = criticalityObj[investigationItemDetailsData.serviceCriticality] || "";
	 }

	 switch (investigationItemDetailsData.urgency) {
		case 'High':
			urgencyValue = "5";
			break;
		case 'Medium':
			urgencyValue = "10";
			break;
		case 'Low':
			urgencyValue = "15";
			break;
	}
	switch (investigationItemDetailsData.serviceCriticality) {
		case 'Significant':
			criticalvalue = "20";
			break;
		case 'Moderate':
			criticalvalue = "25";
			break;
		case 'Low':
			criticalvalue = "30";
			break;
	}

	let obj = (investigationItemDetails && investigationItemDetails.data ? investigationItemDetails.data[0]:investigationItemDetails);

	return {

		initialValues: {
			...GLOBAL.categorizationObj,
			"consumer": investigationItemDetailsData.impactedUsername,
			"consumerID": investigationItemDetailsData.impactedUserid,
			"issueDescription": investigationItemDetailsData.description,
			"additionalInfo": investigationItemDetailsData.symptoms,
			"supportPGroup": investigationItemDetailsData.asgPmGroupid,
			"supportPIndividual": investigationItemDetailsData.asgPmId,
			"criticality": criticalvalue,
			"source":investigationItemDetailsData.source==='Recurring Incident'?'20':investigationItemDetailsData.source==='Unresolved Incident'?'15':investigationItemDetailsData.source==='Proactive Analysis'?'25':'10',
			"urgencyMode": urgencyValue,
			"priority": investigationItemDetailsData.priorityValue,
			"ciName":investigationItemDetailsData.ciName,
			"company":investigationItemDetailsData.consumerCompany,
			"companyName":investigationItemDetailsData.consumerCompanyName
		},
		breakFixSourceName,fieldStatus,isFetchingTimeline, breakFixSGName, breakFixSGIName, investigationUrgency,investigationCriticality,isFetching:isFetchingDetails.isFetching,allgroupdata,
		investigationItemDetails: obj, breakFixStatusDetails, breakFixSGAEName, breakFixAuditLogDetails,isFetchingInvestigation:isFetchingDetails.isFetching,investigationSource, 
		ItemList,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		loadBreakFixSourceName, getPriorityUrgencyCriticalityValues,
		loadBreakFixSG, loadBreakFixSGI, loadBreakFixEditDetails, loadBreakFixStatusDetails, loadNewBreakFixSGAE,getCIGroupData,getAllgroup, loadBreakFixSGISuccess, loadBreakFixSGAESuccess
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InvestigationEditForm)
