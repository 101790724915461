/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import { GetDataQuery } from "../studio/connectors/graphQl";
import {
  buildQueryParams,
  buildUrlWithParams,
  GQLARRAY,
  transformFunction,
  updateDataQueryValues,
  validateGraphQlRequest,
  validateRestRequest,
} from "../studio/connectors/utils";
import { useLocation, useNavigate } from "react-router";
import { replaceEmptyValues, replaceVariables as replaceVariablesFn } from "../studio/utils";
import { usePreviousValue } from "../../../helpers/usePreviousValue";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { filter } from "lodash";

export default function useDataQueryWithApi(
  replaceVariables = {},
  fieldAttributes,
  defaultVal = false,
  resetForm,
  tableQuery = {},
  fileData = {},
  refreshDataOnUpdate
) {
  const [responseData, setResponseData] = useState(null);
  const [responseStatus, setResponseStatus] = useState(null);
  const [dataSourceId, setDataSourceId] = useState(null);
  const [customLoader, setCustomLoader] = useState(false);
  const [error, setError] = useState(null);
  const [refreshed, setRefreshed] = useState(null);
  const [dependents, setDependents] = useState(null);
  const previousValues = usePreviousValue(defaultVal);
  const hosts = useSelector((state) => state?.hosts);
  const location = useLocation();
  const {
    data: dataQuery,
    loading: dataLoading,
    refetch: fetchDataQuery,
    error: dataLoadError,
  } = useQuery(GetDataQuery, {
    variables: { id: dataSourceId },
    skip: dataSourceId == null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (dataSourceId) {
      fetchDataQuery();
    }
  }, [dataSourceId]);

  useEffect(() => {
      if (dataQuery?.dataQuery){
        if(fieldAttributes && fieldAttributes?.type ==="typeaheadComponent" && fieldAttributes?.isPreloaded){
          fetchData();
          return;
        }else if(fieldAttributes && fieldAttributes?.type ==="typeaheadComponent" && !fieldAttributes?.isPreloaded){
          return;
        }
      } 
    if (dataQuery?.dataQuery) fetchData();
  }, [dataQuery]);

  useEffect(() => {
    if (dataLoadError) {
      setError(dataLoadError);
    }
  }, [dataLoadError]);

  useEffect(() => {
    if (refreshed) {
      if (dataQuery?.dataQuery) {
        fetchData();
      }
      setRefreshed(false);
    }
  }, [refreshed]);

  useEffect(() => {
    if (defaultVal && dependents) {
      let refetch = false;
      dependents.forEach((key) => {
        if (previousValues[key] !== defaultVal[key]) {
          refetch = true;
        }
      });
      if (refetch) {
        setTimeout(() => {
          fetchData();
          refetch = false;
        }, 200);
      }
    }
  }, [defaultVal]);

  useEffect(() => {
    if (
      responseStatus === 200 ||
      responseStatus === 201 ||
      responseStatus === 204
    ) {
      if (fieldAttributes?.successMessage?.length > 0) {
        Swal.fire({
          title: replaceVariablesFn(fieldAttributes?.successMessage,{...replaceVariables,...defaultVal,...responseData}),
          icon: "success",
        }).then(() => {
          if (
            !fieldAttributes?.uploadFile ||
            Object.keys(fileData).length === 0
          ) {
            resetForm(true);
            navigate(
              replaceVariablesFn(fieldAttributes?.redirectUrl, {
                ...replaceVariables,
                ...defaultVal,
                ...responseData,
              }),
              { state:{...location.state }, replace: false }
            );
          }
        });
      } else {
        if (
          !fieldAttributes?.uploadFile ||
          Object.keys(fileData).length === 0
        ) {
          resetForm ? resetForm(true) : "";
          navigate(
            replaceVariablesFn(fieldAttributes?.redirectUrl, {
              ...replaceVariables,
              ...defaultVal,
              ...responseData,
            }),
            { state:{...location.state}, replace: false }
          );
        }
      }
      if(fieldAttributes?.refreshOnSuccess) {
        refreshDataOnUpdate(true);
      }
    }
  }, [responseStatus]);

  const getRequestData = () => {
    const data = JSON.parse(dataQuery.dataQuery.data);
    data.body.value = data.dependent
      ? updateDataQueryValues(data.body.value, data.dependent, {
          ...replaceVariables,
          ...defaultVal,
        })
      : data.body.value;
    if (data?.body?.variables) {
      data.body.variables = data.dependent
        ? updateDataQueryValues(data.body.variables, data.dependent, {
            ...replaceVariables,
            ...defaultVal,
          })
        : data.body.variables;
    }
    
    if(data.body.value && typeof data.body.value === 'string') {
      data.body.value = replaceEmptyValues(data.body.value);
    }
    const restOptions = {
      url: data?.varUrl?replaceVariablesFn(data?.varUrl,{...replaceVariables,...defaultVal}):dataQuery.dataQuery.connector.url,
      host:
        dataQuery.dataQuery.connector?.module == "other"
          ? ""
          : `{{${dataQuery.dataQuery.connector?.module}}}`,
      verb: dataQuery.dataQuery.connector.verb,
      module: dataQuery.dataQuery.connector?.module,
      headers: data.dependent
        ? updateDataQueryValues(data.headers, data.dependent, {
            ...replaceVariables,
            ...defaultVal,
          })
        : data.headers,
      queries: data.dependent
        ? updateDataQueryValues(data.query_string, data.dependent, {
            ...replaceVariables,
            ...defaultVal,
          })
        : data.query_string,
      body: data.body,
      connectorId:dataQuery.dataQuery.connector?._id
    };
    setDependents(data.dependent);
    return restOptions;
  };

  const getRequestDataForTable = () => {
    const data = JSON.parse(dataQuery.dataQuery.data);
    if (tableQuery?.dataTransmissionMethod === "body") {
      if (GQLARRAY.includes(dataQuery.dataQuery.connector.verb)) {
        data.body.value = fieldAttributes?.sendDataAsJSON && !data.body?.variables
          ? JSON.stringify(data.body.value)
          : data.body.value;
        if (data?.body?.variables) {
          data.body.variables.map((variable) => {
            if (variable.key === fieldAttributes?.filterValuesKey) {
              if(typeof variable.value === "string") {
                variable.value = JSON.parse(variable.value);
                if(typeof variable.value === 'string') {
                  variable.value = JSON.parse(variable.value);
                }
              }
              if (fieldAttributes?.sendDataAsJSON) {
                variable.value = JSON.stringify(JSON.stringify({
                  ...variable.value,
                  ...tableQuery?.filterValues,
                }));
              } else {
                variable.value = JSON.stringify({
                  ...variable.value,
                  ...tableQuery?.filterValues,
                });
              }
            }
          });
        }
      } else {
        data.body.value = data.dependent
          ? updateDataQueryValues(data.body.value, data.dependent, {
              ...replaceVariables,
              ...defaultVal,
            })
          : data.body.value;
        if (data?.body?.variables) {
          data.body.variables = data.dependent
            ? updateDataQueryValues(data.body.variables, data.dependent, {
                ...replaceVariables,
                ...defaultVal,
              })
            : data.body.variables;
        }
      }
    } else {
      data.body = {};
    }
    let headers = [];
    if (tableQuery?.dataTransmissionMethod === "headers") {
      if (
        fieldAttributes?.filterValuesKey &&
        fieldAttributes?.filterValuesKey !== ""
      ) {
        headers = [
          ...(data.dependent
            ? updateDataQueryValues(data.headers, data.dependent, {
                ...replaceVariables,
                ...defaultVal,
              })
            : data.headers),
          ...[
            {
              key: fieldAttributes?.filterValuesKey,
              value: JSON.stringify(tableQuery?.filterValues),
            },
          ],
        ];
      }
    } else {
      headers = data.dependent
        ? updateDataQueryValues(data.headers, data.dependent, {
            ...replaceVariables,
            ...defaultVal,
          })
        : data.headers;
    }
    const restOptions = {
      url: data?.varUrl?replaceVariablesFn(data?.varUrl,{...replaceVariables,...defaultVal}):dataQuery.dataQuery.connector.url,
      host:
        dataQuery.dataQuery.connector?.module == "other"
          ? ""
          : `{{${dataQuery.dataQuery.connector?.module}}}`,
      verb: dataQuery.dataQuery.connector.verb,
      module: dataQuery.dataQuery.connector?.module,
      headers: headers,
      queries:
        tableQuery?.dataTransmissionMethod === "queryParams"
          ? [...data.query_string, ...buildQueryParams(tableQuery?.filterValues)]
          : data.query_string,
      body: data.body,
      connectorId:dataQuery.dataQuery.connector?._id
    };
    return restOptions;
  };

  const fetchData = () => {
    if (customLoader || dataLoading) {
      return;
    }
    setResponseStatus(null);
    setCustomLoader(true);
    let restOptions = tableQuery?.tableReq
      ? getRequestDataForTable()
      : getRequestData();
    if (GQLARRAY.includes(restOptions?.verb)) {
      validateGraphQlRequest(restOptions)
        .then((response) => { 
          setCustomLoader(false);
          setResponseStatus(response.status);
          if(fieldAttributes?.buttonType?.id === "export") {
            if (fieldAttributes?.exportFormat?.id === "excel") {
              const byteArray = new Uint8Array(response.data.data);
              const arrayBuffer = byteArray.buffer;
              const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);  
              link.download = 'data.xlsx';
              link.click();
            }
          }
          const data = JSON.parse(dataQuery.dataQuery.data);
          if(data?.transform_response){
              response.data = transformFunction(data?.transform_response, response?.data);
          }
          if (Array.isArray(response.data)) {
            setResponseData(response?.data);
          } else if (typeof response.data == "object") {
            let keys = Object.keys(response.data);
            if (keys.length == 1) {
              setResponseData(response.data[keys[0]]);
            }else if (response.hasOwnProperty("data") && !tableQuery?.tableReq) {
              setResponseData(response.data);
            } else {
              setResponseData(response.data);
            }
          } else {
            setResponseData(response?.data);
          }
        })
        .catch((error) => {
           console.log("An error occured:" + error.response.message);
           if(error?.response?.data?.graphQLErrors[0]?.extensions?.httpCode=="401" && error?.response?.data?.graphQLErrors[0]?.message){
            Swal.fire({
              title: error?.response?.data?.graphQLErrors[0]?.message,
            });
           }else if (error?.response?.status === 401) {
            Swal.fire({
              title: error?.response?.data?.message,
            });
          }else if (error?.response?.status === 400) {
            Swal.fire({
              title: error?.response?.data?.message,
            });
          }
          setCustomLoader(false);
          setError(error);
        });
    } else {
      validateRestRequest(restOptions)
        .then((response) => {
          setCustomLoader(false);
          setResponseStatus(response.status);
          let refreshData = true;
          if(fieldAttributes?.buttonType?.id === "export") {
            refreshData = false;
            if (fieldAttributes?.exportFormat?.id === "excel") {
              const byteArray = new Uint8Array(response.data.data);
              const arrayBuffer = byteArray.buffer;
              const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);  
              link.download = 'data.xlsx';
              link.click();
            }
          }
          const data = JSON.parse(dataQuery.dataQuery.data);
            if(data?.transform_response){
              response.data = transformFunction(data?.transform_response, response?.data);
            } 
          if(refreshData) {
            if (Array.isArray(response.data)) {
              setResponseData(response?.data);
            } else if (typeof response.data == "object") {
              let keys = Object.keys(response.data);
              if (keys.length == 1) {
                setResponseData(response.data[keys[0]]);
              }else if (response?.data?.hasOwnProperty("data") && !tableQuery?.tableReq) {
                setResponseData(response.data?.data);
              } else {
                setResponseData(response.data);
              }
            } else {
              setResponseData(response?.data);
            }
          }
        })
        .catch((error) => {
          console.log("An error occured:" + error);
          if (error?.response?.status === 401) {
            Swal.fire({
              title: error?.response?.data?.message,
            });
          }else if (error?.response?.status === 400) {
            Swal.fire({
              title: error?.response?.data?.message,
            });
          }
          setCustomLoader(false);
          setError(error);
        });
    }
  };

  const loading = customLoader || dataLoading ? true : false;
  return [
    responseData,
    loading,
    setDataSourceId,
    error,
    setRefreshed,
  ];
}
