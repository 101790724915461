
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import { filterAllowedFields, getComponents } from "../../common/helper";
import axios from "axios";
import { getDataInTable } from "../blocksHelper";
import GeneratedListDescription from "../generatedListingComponents/generatedListDescription";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import GeneratedCustomTableView from "../generatedListingComponents/customTable";
import useDataQueryWithApi from "../../uiBuilder/hooks/useDataQueryWithApi";
import TableComponent from "../../common/ReactTable/TableComponent";
import { getValueFromKey, isJson } from "../../uiBuilder/studio/utils";
import _ from "lodash";
import ListLoader from "../../common/loaders/ListLoader";

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

const ViewTableComponent = (props) => {
  const [theaders, setTheaders] = useState([]);
  const [tRow, setTRow] = useState([]);
  const [tableProperties, setTableProperties] = useState({});
  const [data, setData] = useState([]);
  const [language, setLanguage] = useState({});
  const [rightDisplay, setRightDisplay] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [rowIndex, setRowIndex] = useState(0);
  const [headerKeys, setHeaderKeys] = useState([]);
  const [filterValues, setFilterValues] = useState({});
  const [metaData, setMetaData] = useState({});
  const [perPageData, setPerPageData] = useState(10);
  const [activePage, setActivePage] = useState(0);
  const [sort, setSort] = useState("");
  const [tableQuery, setTableQuery] = useState({});
  const [showClearFilter, setShowClearFilter] = useState(false);
  const [defaultFiltersUpdated, setDefaultFiltersUpdated] = useState(false);
  const [mutliSelectFilters, setMutliSelectFilters] = useState({});

  const [
    responseData,
    tableDataLoading,
    setDataSourceId,
    dataError,
    setRefreshed,
  ] = useDataQueryWithApi(
    filterValues,
    tableProperties,
    props?.defaultValues,
    () => {},
    tableQuery,
  );

  useEffect(() => {
    if (isJson(props.languageData?.getAllLanguages?.value) && Object.keys(props?.languageData).length > 0) {
      setLanguage(JSON.parse(props.languageData?.getAllLanguages?.value));
    }
  }, [props.languageData]);

  useEffect(() => {
    if (props?.isDataRefreshed) {
      setRefreshed(true);
      props?.setIsDataRefreshed(false);
    }
  }, [props?.isDataRefreshed]);
  
  useEffect(() => {
    if (props?.clearFilterClicked) {
      clearAllFilters();
      props?.setClearFilterClicked(false);
    }
  }, [props?.clearFilterClicked]);

  useEffect(() => {
    if (props?.table && props?.table?.attributes) {
      let filteredTable = props?.table;
      const tableData = filteredTable;
      const tableComponents = getComponents(tableData?.components);
      const thComponents = filterAllowedFields(tableComponents, ["custom-th"]);
      const tdComponents = filterAllowedFields(tableComponents, ["custom-td"]);
      setTheaders(thComponents);
      setTRow(tdComponents);
      setTableProperties(tableData?.attributes);
    }
  }, [props.table]);

  useEffect(() => {
    if (tableProperties && Object.keys(tableProperties).length > 0) {
      if (tableProperties?.data_source) {
        if (tableProperties?.data_source && tableProperties?.tableType?.id == "customTable") {
          let filters = {};
          if(tableProperties?.sendPageSizeInString) {
            filters = {
              [tableProperties?.currPageKey ?? "page"]: "0",
              [tableProperties?.sizeKey ?? "size"]:
                tableProperties?.itemsPerPageOptions?.split(",")?.[0] ?? "10",
            };
          } else {
            filters = {
              [tableProperties?.currPageKey ?? "page"]: 0,
              [tableProperties?.sizeKey ?? "size"]:
                parseInt(tableProperties?.itemsPerPageOptions?.split(",")?.[0], 10) ?? 10,
            };
          }
          if (theaders && Array.isArray(theaders) && theaders.length > 0 && !defaultFiltersUpdated) {
            theaders.forEach((header) => {
              if (header?.defaultFilterValue) {
                filters[header?.bindingKey] = header?.defaultFilterValue;
              }
            });
            setDefaultFiltersUpdated(true);
          }
          setFilterValues(filters);
          setTableQuery({
            filterValues: filters,
            dataTransmissionMethod: tableProperties?.dataTransmissionMethod?.id,
            tableReq: true,
          });
          setPerPageData(tableProperties?.itemsPerPageOptions?.split(",")?.[0] ?? 10);
        }
        setDataSourceId(tableProperties?.data_source.id);
      }
    }
  }, [tableProperties]);

  useEffect(() => {
    let headerKeys = [];
    if (theaders && Array.isArray(theaders)) {
      theaders.forEach((header) => {
        headerKeys.push(header?.bindingKey);
      });
      setHeaderKeys(headerKeys);
    }
  }, [theaders]);

  useEffect(() => {
    if (responseData) {
      if (Array.isArray(responseData)) {
        setData(responseData);
      } else if (
        tableProperties?.listingKey &&
        responseData.hasOwnProperty(tableProperties?.listingKey)
      ) {
        if (
          tableProperties?.metaKey &&
          responseData.hasOwnProperty(tableProperties?.listingKey)
        ) {
          const meta = getValueFromKey(responseData, tableProperties?.metaKey);
          setMetaData(meta);
          props.setDefaultValues({...props?.defaultValues,...meta});
        } else {
          let data = structuredClone(responseData);
          delete data[tableProperties?.listingKey];
          setMetaData(data);
          props.setDefaultValues({...props?.defaultValues,...data});
        }
        const list = getValueFromKey(responseData, tableProperties?.listingKey);
        setData(list);
      }
    }
    let filterMultiSelect = Object.keys(mutliSelectFilters).reduce((acc, curr) => {
      acc[curr] = false;
      return acc;
    }, {});
    setMutliSelectFilters(filterMultiSelect)
    setRightDisplay(false);
  }, [responseData]);

  useEffect(() => {
    if (tableQuery?.filterValues) {
      setDataSourceId(tableProperties?.data_source?.id);
      setRefreshed(true);
    }
  }, [tableQuery]);

  useEffect(() => {
    if (tableQuery?.filterValues) {
      let filterQuery = structuredClone(tableQuery?.filterValues);
      delete filterQuery?.[tableProperties?.sortingKey];
      delete filterQuery?.[tableProperties?.currPageKey ?? "page"];
      delete filterQuery?.[tableProperties?.sizeKey ?? "size"];
      let defaultFilters = {};
      theaders.forEach((header) => {
        if(header?.defaultFilterValue) {
          defaultFilters[header?.bindingKey] = header?.defaultFilterValue;
        }
      });
      if (_.isEqual(filterQuery, defaultFilters)) {
        setShowClearFilter(false);
        props?.setDefaultValues({...props.defaultValues, showClearFilter: false});
      }
      else {
        setShowClearFilter(true);
        props.setDefaultValues({...props.defaultValues, showClearFilter: true});
      } 
    } else {
      setShowClearFilter(true);
      props.setDefaultValues({...props.defaultValues, showClearFilter: true});
    }
    setRightDisplay(false);
  }, [tableQuery]);

  useEffect(() => {
    if (!tableProperties?.data_source?.id) {
      if (tableProperties?.listingKey) {
        if (props?.defaultValues?.[tableProperties?.listingKey]) {
          setData(props?.defaultValues?.[tableProperties?.listingKey]);
        } else {
          setData([]);
        }
      }
      if (tableProperties?.useDynamicColumns) {
        if (tableProperties?.dynamicColumnsKey && tableProperties?.dynamicColumnsBindingKey) {
          let cols = tableProperties?.dynamicColumnsKey?.split(',');
          let keys = tableProperties?.dynamicColumnsBindingKey?.split(',');
          if(cols.length === 1 && keys.length === 1) {
            if (props?.defaultValues?.[tableProperties?.dynamicColumnsKey]) {
              let dynamicCols =
                typeof props?.defaultValues?.[
                  tableProperties?.dynamicColumnsKey
                ] === "string"
                  ? props?.defaultValues?.[
                      tableProperties?.dynamicColumnsKey
                    ].split(",")
                  : props?.defaultValues?.[tableProperties?.dynamicColumnsKey];
              let bindingKeys = typeof props?.defaultValues?.[
                tableProperties?.dynamicColumnsBindingKey
              ] === "string"
                ? props?.defaultValues?.[
                    tableProperties?.dynamicColumnsBindingKey
                  ].split(",")
                : props?.defaultValues?.[tableProperties?.dynamicColumnsBindingKey];
              dynamicCols = dynamicCols.map((col, index) => {
                return {
                  bindingKey: bindingKeys[index],
                  innerText: col,
                }
              });
              setTheaders(dynamicCols);
            } else {
              setTheaders([]);
            }
          } else {
            let dynamicCols = cols.map((col, index) => {
              return {
                bindingKey: keys[index],
                innerText: col,
              }
            });
            setTheaders(dynamicCols);
          }
        } else {
          setTheaders([]);
        }
      }
    }
  }, [props?.defaultValues]);

  const onRowSelect = (row, iconClick = false) => {
    setSelectedRow(row);
    props.setFormValues(row);
    props.setDefaultValues({ ...props.defaultValues });
    props.setButtonReplacableValue(row);
    if (typeof props?.setRightPanelDisplay === "function") {
      props.setRightPanelDisplay(true);
    }
    if (typeof props?.setSelectedRow === "function") {
      props.setSelectedRow(row);
    }
    if (tableProperties?.isDetailViewAvailable && !iconClick) {
      setRightDisplay(true);
    }
  };

  const applyFilter = (filters) => {
    let filterObj = {};
    if (filters) {
      for (let key in filters) {
        if (typeof filters[key] === "object" && filters[key]?.dateRange) {
          filterObj[key] = filters[key]?.dateRange;
        } else {
          if (typeof filters[key] === "number" || filters[key].length > 0)
            filterObj[key] = filters[key];
        }
      }
    } else {
      for (let key in filterValues) {
        if (
          typeof filterValues[key] === "object" &&
          filterValues[key]?.dateRange
        ) {
          filterObj[key] = filterValues[key]?.dateRange;
        } else {
          if (
            typeof filterValues[key] === "number" ||
            filterValues[key].length > 0
          )
            filterObj[key] = filterValues[key];
        }
      }
    }
    setTableQuery(
      structuredClone({
        filterValues: structuredClone(filterObj),
        dataTransmissionMethod: tableProperties?.dataTransmissionMethod?.id,
        tableReq: true,
      })
    );
  };

  const clearAllFilters = () => {
    let filterObj = {};
    if(tableProperties?.sendPageSizeInString) {
      filterObj = {
        [tableProperties?.currPageKey ?? "page"]: "0",
        [tableProperties?.sizeKey ?? "size"]:
          tableProperties?.itemsPerPageOptions?.split(",")?.[0] ?? "10",
      };
    } else {
      filterObj = {
        [tableProperties?.currPageKey ?? "page"]: 0,
        [tableProperties?.sizeKey ?? "size"]:
          parseInt(tableProperties?.itemsPerPageOptions?.split(",")?.[0], 10) ?? 10,
      };
    }
    theaders.forEach((header) => {
      if (header?.defaultFilterValue) {
        filterObj[header?.bindingKey] = header?.defaultFilterValue;
      }
    });
    setFilterValues(filterObj);
    setTableQuery({
      filterValues: filterObj,
      dataTransmissionMethod: tableProperties?.dataTransmissionMethod?.id,
      tableReq: true,
    });
    setSort("");
  };

  const buttonClicked = (event) => {
    switch (event.type) {
      case "rightSideMinimize":
        setRightDisplay(false);
        break;
      case "button":
        switch (event?.buttonType?.id) {
          case "refresh":
            setRefreshed(true);
            break;
        }
        break;
      default:
        break;
    }
  };

  const renderTable = () => {
    if (tableProperties && tableProperties?.tableType?.id == "bootstrapTable") {
      let tableColumns = [];
      let filterColumns = [];
      if (theaders && theaders.length > 0) {
        theaders.forEach((res, index) => {
          let column = {
            name: language.hasOwnProperty(res?.innerText)
              ? language[res?.innerText]
              : res?.innerText,
            selector: (row) => getDataInTable(row[res.bindingKey]),
            sortable: res?.isSortable,
            cell: (row) => {
              if(tRow?.[index]?.isCustomCol) {
                return (
                  <div data-tag="allowRowEvents" onClick={() => onRowSelect(row, true)}>
                    {props.renderComponent(tRow?.[index]?.components, false, row)}
                  </div>
                );
              } else {
                return (
                  <div title={row[res.bindingKey]} data-tag="allowRowEvents">
                    {row[res.bindingKey]}
                  </div>
                );
              }
            },
          };
          tableColumns.push(column);
          if (res?.isFilterable) {
            filterColumns.push(res.bindingKey);
          }
        });
        return (
          <>
            {tableDataLoading && <ListLoader />}
            {!tableDataLoading && data && data.length > 0 ? (
              <div className={`dtaTableDv integrateHubTable ${props?.errors[tableProperties?.listingKey]?'dvTableError':''}`}>
                <div className={"rightTrue"}>
                  <TableComponent
                    data={data}
                    columns={tableColumns}
                    headerColumns={filterColumns}
                    onRowSelect={onRowSelect}
                    rightDisplay={rightDisplay}
                    uniqueKey={tableProperties?.uniqueKey}
                    hideSearch={!tableProperties?.showSearchBox}
                    pagination={tableProperties?.hidePagination}
                  />
                </div>
              </div>
            ) : (
              <div className="f-size-14 text-c"></div>
            )}
          </>
        );
      }
    } else if (tableProperties?.tableType?.id == "customTable") {
      return (
        <div className={`dtaTableDv integrateHubTable  ${props?.errors[tableProperties?.listingKey]?'dvTableError':''}`}>
          <div className={"rightTrue"}>
            <GeneratedCustomTableView
              theaders={theaders}
              headerKeys={headerKeys}
              filterValues={filterValues}
              setFilterValues={setFilterValues}
              perPageData={perPageData}
              setPerPageData={setPerPageData}
              activePage={activePage}
              setActivePage={setActivePage}
              metaData={metaData}
              setMetaData={setMetaData}
              rowIndex={rowIndex}
              setRowIndex={setRowIndex}
              rightDisplay={rightDisplay}
              setRightDisplay={setRightDisplay}
              data={data}
              applyFilter={applyFilter}
              itemsPerPageOptions={
                tableProperties?.itemsPerPageOptions ?? "10,25,50"
              }
              itemsPerPageText={tableProperties?.itemsPerPageText ?? "Show"}
              tRow={tRow}
              renderComponent={props.renderComponent}
              setFormValues={props.setFormValues}
              setButtonReplacableValue={props.setButtonReplacableValue}
              setSort={setSort}
              sort={sort}
              tableProperties={tableProperties}
              clearAllFilters={clearAllFilters}
              showClearFilter={showClearFilter}
              setSelectedRow={setSelectedRow}
              tableDataLoading={tableDataLoading}
              mutliSelectFilters={mutliSelectFilters}
              setMutliSelectFilters={setMutliSelectFilters}
              setRightPanelDisplay={props.setRightPanelDisplay}
              setRow={props?.setSelectedRow}
            />
          </div>
        </div>
      );
    }
  };

  ///main render part

  return (
    <PanelGroup direction="horizontal" className="panelOverflow" style={{height:"auto"}}>
      <Panel
        id="sidebar"
        minSize={36}
        order={1}
        defaultSize={rightDisplay ? 67 : 100}
        className={
          rightDisplay
            ? "catBordDv isShowLeftPanel rwLeftPart"
            : "catBordDv rwLeftPart"
        }
      >
        {tableDataLoading && <ListLoader />}
        {!tableDataLoading && data && data.length === 0 && tableProperties?.notDataFound && tableProperties?.tableType?.id == "bootstrapTable" ? (
          (<div className="text-c padding-t-10 padding-b-10 border">
            No Data Found
          </div>)
        ) : (
          renderTable()
        )}
      </Panel>
      {rightDisplay ? (
        <>
          <PanelResizeHandle className="isShowLeftPanel resizeHandle">
            <div className="outlne">
              <div className="handIcn">
                <i className="fa fa-chevron-left" aria-hidden="true"></i>
              </div>
            </div>
          </PanelResizeHandle>
          <Panel minSize={33} order={2} defaultSize={33}>
            <div className="stickyArea rBoxStyle">
              <GeneratedListDescription
                title={props?.title}
                selectedRow={selectedRow}
                rightSideKeys={tableProperties?.rightSideKeys}
                rightSideLabels={tableProperties?.rightSideLabels}
                headerText={tableProperties?.rightSideKeys}
                buttonClicked={buttonClicked}
                tableProperties={tableProperties}
              />
            </div>
          </Panel>
        </>
      ) : (
        ""
      )}
    </PanelGroup>
  );
};

export default reduxForm({
  form: "ViewTableComponent",
})(ViewTableComponent);
