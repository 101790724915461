
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { combineReducers } from "redux";
import courses from "./courseReducer";
import authors from "./authorReducer";
import { reducer as reduxFormReducer } from "redux-form";

import breakFixSourceName from "./breakFix/sourceReducer";
import breakFixDomainName from "./breakFix/domainReducer";
import breakFixSubDomainName from "./breakFix/subDomainReducer";
import breakFixCriticalityName from "./breakFix/criticalityReducer";
import breakFixUrgencyName from "./breakFix/urgencyReducer";
import breakFixSGName from "./breakFix/serviceGroupReducer";
import windowSize from "./windowSize";
import breakFixSGIName from "./breakFix/serviceGroupIReducer";
import breakFixSGIENameTypeahead from "./fulfillment/breakFix/serviceGrouplReducerTypeahead";
import breakFixEditDetails from "./breakFix/breakFixEditDetailsReducer";
import { hamList, classEditList, compClass } from "./ham/hamReducer.js";

import { externalSystemAttributesList } from "./breakFix/breakFixExternalSystemAttributesReducer";

import breakFixCause from "./breakFix/causeReducer";
import chat from "./chat/chatReducer";
import cimUserDetails from "./breakFix/cimDetailsReducer";
import cimUserDetailsForUser from "./breakFix/cimForUserDetailsReducer";
import cimGroupDetails from "./breakFix/cimGroupReducer";
//import quickViewDetails from './breakFix/quickViewReducer';
import {
  quickViewDetails,
  moduleList,
  multiSelectDropDownWithOk,
  loadIncData,
  wibListPayload,
} from "./breakFix/quickViewReducer";
import { reasonCode, complexity } from "./breakFix/reasonCodeReducer";
import { provideFixDropdownList } from "./breakFix/provideFixReducer";
import {
  isFetchingDetails_KA,
  attachedArticlesList,
} from "./breakFix/knowledgeArticleReducer";

import {
  articleCategoryDropdownList,
  articleServicesDropdownList,
  articleResultsList,
} from "./article/articleReducer";
import {
  insidedResultsList,
  isFetchingInsidedDetails,
  insidedTokenDetails,
  knowledgeGlobalResultsList,
  isFetchingGlobalKnowledgeDetails,
  articleDetails,
  knowledgeCategoriesList
} from "./knowledge/knowledgeReducer";

import hamburgerCategory from "./homepage/hamburgerReducer";
import hamburgerCategoryList from "./homepage/hamburgercatReducer";
import subHamburgerCategory from "./homepage/subHamburgerReducer";
import bulletin from "./homepage/bulletinReducer";
import advertisement from "./homepage/advertisementReducer";
import bestSelling from "./homepage/bestSellingReducer";
import catSelling from "./homepage/catSellingReducer";
import bestFeature from "./homepage/featuredReducer";
import newLaunches from "./homepage/newLaunchesReducer";
import comingSoon from "./homepage/comingSoonReducer";
import categoryList from "./categorylist/categoryListReducer";
import selectedOffering from "./categorylist/offeringDescriptionReducer";
import componentPackage from "./categorylist/componentPackageReducer";
import servicecategoryList from "./categorylist/reducer_servicefilter";
import providercategoryList from "./categorylist/reducer_providerfilter";
import freeoffering from "./homepage/freeOfferingReducer";
import favorites from "./homepage/favoritesReducers";
import welcomeMessageReducer from "./homepage/welcomeMessageReducer";
import multiTenantLogoReducer from "./homepage/multiTenantLogoReducer";

import providersliderList from "./categorylist/reducer_sliderfilter";
import {
  offeringDetails,
  offeringSlaDetails,
  selectedComponentForOffering,
  relatedOfferings,
  offeringView
} from "./offeringDetailsReducer";

import rcaTechniques from "./investigation/RCATechniquesReducer";
import {
  cartOfferings,
  categoryParentQuestions,
  orderDeliverTypes,
  questionnaireList,
  selectedServiceDeliveries,
  serviceQuestionnaireCategories,
  userDefinedServiceQuestionnaireCategories,
  categoryCountList,
  orderingInformationDetails,
  recipientDeliveryLocations,
  recipientDeliveryCountries,
  haveMandatQuestionReducer,
  bulkPurchaseQuestions,
  recipientDeliveryLocationsIsShared,
  urgencyValuesReducer,
  cartOfferingAttachmentsReducer,
  // master_questions
} from "./cart/addToCartReducer";
import breakFixAuditLogDetails from "./breakFix/breakFixAuditLogDetailsReducer";
import breakFixRelatedWorkDetails from "./breakFix/breakFixRelatedWorkDetailsReducer";
import bfRelatedWorkCheckList from "./breakFix/breakfixRelatedWorkCheckListReducer";
import { causalRelationshipList } from "./breakFix/causalRelationshipDataReducer";
import breakFixRelatedDataDetails from "./breakFix/breakFixRelatedDataDetailsReducer";
import breakFixActivityLogDetails from "./breakFix/breakFixActivityLogDetailsReducer";
import breakFixStatusDetails from "./breakFix/breakFixStatusReducer";
import WorkItemBoardFilterCache from "./breakFix/WorkItemBoardFilterCache";
//profile
import profileDetails from "./myProfile/profileReducer";
import dateFormatDetails from "./myProfile/profileDateFormatReducer";
import timeZoneDetails from "./myProfile/profileTimeZoneReducer";
import currencyDetails from "./myProfile/profileCurrencyReducer";
import CWLDetails from "./myProfile/profileCWLReducer";
import languageDetails from "./myProfile/languageDetailsReducer";

//OrderNow
import deliverytypeList from "./cart/orderNowDeliveryReducer";
//import componentPackage from './componentPackageReducer';
import orderofferingList from "./cart/ordernowOfferingReducer";
import orderquestionnaireList from "./cart/ordernowQuestionnaireReducer";
//Fulfillment
import fulfillmentDetails from "./fulfillment/fulfillmentDetailsReducer";
import {
  isFulfillmentGroupLoaded,
  isIncidentGroupLoaded,
  isProblemGroupLoaded,
  isChangeMgmtGroupLoaded,
} from "./fulfillment/fulfillmentGroupFetched";
import fullfilmentRelatedWorkDetails from "./fulfillment/fullfilmentRelatedWorkDetailsReducer";
import fullfilmentRelatedDataDetails from "./fulfillment/fullfilmentRelatedDataDetailsReducer";
import rightSidebarMenulist from "./common/rightSideBarMenuListReducer.js";
import {
  statusBasedHamburgerOptions,
  loadHamburgerOptions,
  slaViewListRed,
  appointmentCount,
  actionCount
} from "./common/statusBasedHamburgerReducer.js";
import breakfixQuickViewFilters from "./breakFix/breakFixQuickViewFiltersReducer";
import cmdbQuickViewFilters from "./cmdb/cmdbQuickViewFiltersReducer";
import breakfixQuickViewTable from "./breakFix/breakFixQuickViewTableReducer";
import fulFillmentList from "./fulfillment/fulfillmentListReducer";
import {
  selectedFulfillmentItem,
  selectedFulfillmentItemComponent,
  fetchApprovalQuestionsDetails
} from "./fulfillment/fulfillmentItemReducer";
import { fullFillmentStatusTimeLine } from "./fulfillment/fulFillmentTimeLineReducer";
import {
  globalSearchData,
  globalSearchDataForRevamp,
  globalSearchListSearchText,
  globalSearchListCategoryId,
  wibGlobalSearchFilterData,
  wibSearchParams,
  isSearchParamsApply,
  wibActiveTab,
  wibSelectedDateRanceLabel,
  wibSlaColors
} from "./globalSearch/globalSearchReducer";

//myOrders
//myOrders
import {
  categoryName,
  ordersList,
  myOrderOrderDetails,
  loadReasons,
  myBreakFixOrderOrderDetails,
  myBreakFixOrderOrderDetailsInteger,
  urgencyList,
  offeringForOrder,
  approverListDetails,
  myOrderQuestionDetails,
  resetSortArrows,
  ordersListGblSearch,
} from "./cart/myOrders/myOrdersReducer";
import myOrderViewFilters from "./cart/myOrders/myOrdersFilterReducer";
//reviews
import reviewFilters from "./reviews/reviewsFilterReducer";
import {
  myReviewOrdersList,
  myPendingReviewOrdersList,
  myPendingReviewOrdersCount,
  myReviewOrderDetails,
  myReviewBreakFixOrderDetails,
  topReviewsList,
  myReviewOrderDetailsFrmEngine,
} from "./reviews/reviewsReducer";
import breakFixTaskLogDetails from "./task/taskDetailsReducer";
import {
  myTaskDetailsList,
  createTaskFormValues,
  realatedTaskCount,
  relatedCMDBTasks,
  taskGroupData,
  taskGroupData2,
  taskAssignedToData,
  taskStatusToEdit,
  regionListData,
  editTaskStatus,
  taskTypeList,
  taskCountByItemId,
  NotificationListByWID
} from "./task/taskDetailsReducer";
import taskAuditLogDetails from "./task/taskAuditLogReducer";
import breakFixTaskEditDetails from "./task/taskEditDetailsReducer";
import {
  breakFixSGAENameType,
  breakFixSGAEName,
  breakFixSGAENameCIM,
  allCIMgroups,
  allCIMgroupsRules,
  breakFixSGAEChangegroup,
  breakFixSGAEEditName,
  breakFixSGAETaskName,
  breakFixDetailsLoadSatatus,
  breakfixD2cNumber,
  changeManagementGroupIndividual,
  changeManagementGroupIndividualTypeahead,
  changeManagementGroupImplIndividual,
  changeManagementGroupImplIndividualTypeahead,
} from "./breakFix/serviceGroupAEReducer";
//import breakFixSGAEEditName  from './breakFix/serviceGroupAEEditReducer';
import {
  myServiceList,
  serviceList,
  myServiceDetails,
  userListReviewFromRE,
  ratingCalcReviewFromRE,
  serviceReviewwFromRE,
  loadItmData,
} from "./myServices/myServicesReducers";
/* my approvals */
import {
  approvalList,
  adhocApproversList,
} from "./myApproval/myApprovalReducer";
import { delegationList } from "./delegations/delegationsReducer.js";
//import {selectedServicePortfolioDetails, servicePortFolioList, servicePortfolioLoggedInUserCompany, catalogAndPortfolioGroups, portfolioManagerIndividual, catlogManagerIndividual, servicePortfolioStatusList, servicePortfoliosubStatusList, servicePortfolioSubCategoryList, editPortfolioService, servicePortfolioUserInfo} from './servicePortfolio/servicePortfolioReducer.js';

//Investigation
import {
  investigationList,
  investigationItemDetails,
  investigationTeamList,
  specificVendorTicketList,
  loadPrbData,
} from "./investigation/investigationReducer.js";
import invCauseName from "./investigation/causeReducer.js";
import { acquaintedArticles } from "./acquainted/acquaintedReducer.js";
import breakFixSLALogDetails from "./breakFix/breakFixSLALogDetailsReducer";
import invRelatedWorkDetails from "./investigation/invRelatedWorkDetailsReducer";
import invRelatedDataDetails from "./investigation/invRelatedDataDetailsReducer";
import invRelatedDataDetailsCount from "./investigation/RelatedBreakFixDetails";
import breakFixSLAResponseLogDetails from "./breakFix/breakFixSLAResponseLogDetailsReducer";
import breakFixSLAResolutionLogDetails from "./breakFix/breakFixSLAResolutionLogDetailsReducer";
//CM
import { myChangeManagementList } from "./changeManagement/changeManagementReducer";
import {
  changeConflictJustification,
  changeConflictData,
  loadPirData,
  loadCngData,
} from "./changeManagement/changeConflictReducer";
import { implememtationPlanList } from "./common/attachmentReducer.js";
import {
  isFetchingDetails,
  isFetchingTimeline,
  isCausalRelationshipData,
  sLaHolidayFetchingDetails,
  showLoader,
  rightMenuShowLoader,
  showLoader2,
  showLoader3,
  showLoader4,
  showLoader_1,
  showLoader_2,
  showLoader_3,
  showLoader_5,
  showLoader_6,
  amsBoardShowLoader,
  bestSellingShowLoader,
  comingSoonShowLoader,
  favouriteServiceShowLoader,
  freeServiceShowLoader,
  newLaunchShowLoader,
  showLoader_SLA_Deflist_Pagination,
  showLoader_SLA_Work_Schedule_Pagination,
  showLoader_SLA_Holiday_Schedule_Pagination,
  delegateShowLoader,
  serviceReviewShowLoader,
  slaDetailsShowLoader,
  allCategoriesLoader,
  topReviewLoader,
  loading_orders_gsearch_2,
  showLoaderRelatedTask,
  featuredShowLoader,
  revampGlobalSearchShowLoader,
  taskStatusLoader,
  showLoader_ne,
} from "./common/loadingStatus";

import cartCount from "./common/cartCountReducer";

//lov reducer
import {
  lovForType,
  lovForChangeTiming,
  lovForBusinessRisk,
  lovForTechnicalRisk,
  lovForPlanType,
  lovForUrgency,
  lovForReasonForChange,
  lovForRiskOccurrence,
  lovForRiskImpact,
  lovForControlEffectiveness,
  riskAssessmentValue,
} from "./lovValues/lovValuesReducer.js";
import changeRelatedWorkDetails from "./changeManagement/changeRelatedWorkDetailsReducer";
import changeRelatedDataDetails from "./changeManagement/changeRelatedDataDetailsReducer";
import { relatedworkitemsCount } from "./changeManagement/relatedworkitemsCount";
import cmdbChangeRelatedWorkDetails from "./changeManagement/cmdbChangeRelatedWorkDetailsReducer";
import relatedAssetsWorkDetails from "./changeManagement/cmdbChangeRelatedWorkDetailsReducer";

import cmdbChangeRelatedDataDetails from "./changeManagement/cmdbChangeRelatedDataDetailsReducer";

import {
  editChangeInitialData,
  fieldStatus,
  extractedChangeDataForSucessRate
} from "./changeManagement/changeManagementReducer.js";

import {
  queryBuilderAllRulesData,
  queryBuilderData,
  queryBuilderChildData,
  postQueryBuilderData,
  amsEditRuleConfiguration,
  queryBuilderChildAttributes,
} from "./common/queryBuilderReducer";
import {
  investigationCriticality,
  investigationSource,
  investigationPriority,
  investigationUrgency,
  implementationPlanDetails,
  specificUserDetails,
  timelineDataResult,
  externalLinks,
} from "./common/commonApiReducer.js";
import changeApprovalDetails from "./changeManagement/changeApprovalDetailsReducer";
import {
  changeTotalApprovalDetails,
  requesterDelegateeDetails,
  changeTotalApprovalHistoryDetails,
} from "./changeManagement/changeTotalApprovalDetailsReducer";
import breakFixSGAEChangeName from "./breakFix/serviceGroupAEChangeReducer";
import breakFixSGAEChangeNameType from "./breakFix/serviceGroupAEChangeReducerType";

//cmdb
import {
  cmdbQuickItemList,
  cmdbQuickTechnicalItemList,
  cmdbQuickBusinessItemList,
  statusList,
  subStatusList,
} from "./cmdb/quickViewReducer";
import {
  ciClass,
  ciType,
  ciName,
  ciStatus,
  ciAllStatus,
  ciSubStatus,
  ciSubStatusCopy,
  ciMetallic,
  ciEnv,
  ciResidualRisk,
  approvalsAutoAction,
  ciManr,
  ciDataset,
  ciCompany,
  ciLocation,
  ciGroup,
  ciGroupForTaskPlan,
  ciInd,
  CIEditDetails,
  ciCat,
  ciSCat,
  ciAtt,
  ciSupCompany,
  vendorSuccess,
  companyReportData,
  cmdbSupportCompany,
  ciCategories,
  cmdAssetsBasedOnClassList,
  CIModelName,
  ciUserBasedLocation,
  ciResolutionCat,
  FdnTagList,
} from "./cmdb/lovReducer";
import cmdbAuditLogDetails from "./breakFix/cmdbAuditLogDetailsReducer";
import { spcmReducer , isFetchingDetails_Knowledge, attachedKnowledgeArticlesList, toaAuditData, rulesAuditData, refreshDataDictionaryReducer, rulesDbDetailsReducer, customDataSources, getApprovalRulesData } from "./reducer_spcm";
import {
  relationshipTypes,
  classNames,
  assetCIListByClassName,
  assetCIDisclaimer,
  ciForTypeahead,
} from "./cmdb/relateAssetCIReducer";

import { reportReducer } from "./reducer_reports";

//sla
import {
  slaDefEditDetails,
  slaCompany,
  slaModule,
  slaStatus,
  slaService,
  slaImpacted,
  slaType,
  slaTarget,
  slaCriteriaType,
  slaOperatorType,
  slaMilestoneActionList,
  slaMilestoneTargetList,
  slaHolidaySchedules,
  slaWorkdaySchedules,
  slaWorkdaySchedulesDays,
  slaWorkdaySchedulesStatus,
  slaAttributes,
  slaAttributesList,
  slaWorkScheduleDetails,
  newSlaWorkScheduleAssociation,
  slaHolidayScheduleDetails,
  timeZoneData,
  slaMilestoneList,
  slaHolidayDetails,
  slaRelatedDetails,
  slaEditLov,
  slaUniqueMilestoneFromRecord,
  slaUniqueEventTypeFromRecord,
  slaSelectedAttributesList,
  postAttributeBasedSlaReducer,
} from "./sla/lovReducer";
import {
  slaDefList,
  slaWorkdayList,
  slaHolidayList,
  slaHolidayEntriesList,
  slaDefActiveMandatory,
  slaModuleStatusValues,
  targetSlaAcitvity,
  opsCategoryLevels
} from "./sla/quickViewReducer";
import { cmdbTimeLineData } from "./cmdb/cmdbTimelineReducer";
import { cmdbRelationshipData } from "./cmdb/cmdbTimelineReducer";
//import {slaDefEditDetails,slaHolidaySchedules,slaWorkdaySchedules}  from './sla/lovReducer';
import {
  companyData,
  companyDataTypeahead,
  classData,
  impactedCIData,
  associatedCompanyData,
  associatedCompanyDataTypeahead,
  moduleData,
  eventData,
  parameterData,
  allquerygroupdata,
  allgroupdata,
  groupData,
  groupDataTypeahead,
  allGroups,
  individualData,
  problemTeam,
  parameterValueData,
  ruleConfiguration,
  ruleCondition,
  editRuleConfiguration,
  companyService,
  companyServiceTypeahead,
  allRuleConditions,
  companyStatus,
  allRuleConfigurationsData,
  allAssignmentGroups,
  attributesList_ams,
  operatorsList_ams,
  amsAttributeList_ams,
  loaders_ams,
  isClassExist_list,
  groupFunctionDataTypeahead,
} from "./amsReducers";
import { taskInputOutputVariables } from "./task/taskVariablesReducer.js";
//reporting
import {
  reportCompany,
  reportModule,
  criteriaList,
  reportFields,
  reportListData,
} from "./reports/reportsReducer";
//categoryBoard
import {
  categoryBoardList,
  categoryDropdown,
  CategoriesByCompanyIdDropdown,
  statusDropdown,
  categoryEditDetails,
  associatedCompanies,
  showLoaderCICategory,
  ciCategoryListData,
  ciCategoryListDataLoader,
} from "./categoryBoard/quickViewReducer";
import { getIntegrationHubData } from "./integrationHub/integrationHubReducer";
import { reportList, versionList } from "./sreReducer/sreReducer.js";
import { companyMenuListDataLoader, companyMenuListData } from "./companyMenuList/companyMenuListReducer.js";

//instanceAvailability
import {
  allCINames,
  hourlySlicingData,
  dailySlicingData,
  alertsData,
  showCINamesDataLoader,
  showSlicingDataLoader,
  showAlertsDataLoader,
} from "./instanceAvailabilityReducer/instanceAvailabilityReducer.js";
import {
  neReducer,
  auditLogNEReducer,
  customVariablesReducer,
} from "./reducer_ne";
import approvalHistoryData from "./changeManagement/approvalHistoryData";
import {
  watcherDetailsFetched,
  watcherLoader,
} from "./fulfillment/watcherReducers";

// businessFunction
import { parentFunctionByCompanyIdDropdown } from "./businessFunction/businessFunctionReducer";
import {
  businessFunctionList,
  businessFunctionListIntialValues,
} from "./businessFunction/businessFunctionReducer";
import {
  urgencyImpactReducer,
  systemCofigList,
  deleteSystemConfigList,
  editSystemConfigList,
  savedSystemCofigList,
  savedSurveyCofigList,
  savedApprovalCofigList,
  savedMailboxCofigList,
} from "./systemConfig/systemConfigReducer";

import { getDashboardReducerData, dashboardListData } from "./dashboardConfigration/dashboardConfigReducer";

// foundation tags
import {
  foundation_tags_reducer,
  foundation_tags_loader_reducer,
} from "./foundation/tags/tagsReducer";

// AdminFoundation
import {
  companyFdnList,
  menuListFDNReducer,
  editCompanyFdnList,
  associateCompanyDropdown,
  associateGroupDropdown,
  associatedCompaniesDropdownValue,
  disassociatedcompany,
  disassociatedGroup,
  associationFlag,
  syncedData,
  associationTypeMenuList,
  disassociateConsumerCompany,
  serviceConsumerMenuList,
  notServiceConsumerMenuList,
} from "./foundation/companyReducers";
import {
  userListRed,
  userDepListRed,
  userCostCenterRed,
  userLocationRed,
  userManagerRed,
  userOrgListRed,
  languageListRed,
  dateListREd,
  authenticationRed,
  userTypeRed,
  timezoneRed,
  currencyRed,
  editDetailsRed,
  userPageCompanyValues,
  userGroupsRed,
  userRolesRed,
  accessRestrictRed,
  userCompanyByTypeRed,
  disassociateCompRed,
  mapDepartmentRed,
  mapOrganizationRed,
  mapCWLRed,
  notSyncRed,
  SyncRed,
  cwlDataRed,
  userJwtData,
  countryRed
} from "./foundation/userReducer.js";
// import forumReducer from "./foundation/forum/forum";
import {
  costCenterList,
  getCostCenterEditDetails,
  costCenterCompanyDropdown,
  costCenterStatusValues,
} from "./foundation/costCenterReducers";

import {
  departmentList,
  departmentEditList,
  organizationList,
  companyMap,
  organizationMap,
} from "./foundation/departmentReducers.js";
import {
  organizationData,
  organizationEditPageData,
  orgCompanyDropdown,
} from "./foundation/organizationReducers.js";
import {
  groupList,
  groupInitialValues,
  companyMenuListValues,
  groupEditPageValues,
  groupCategoryValues,
  groupForumValues,
  associateRoleValues,
  assoMembersValues,
  disassociatedMember,
  disassociatedRoles,
  aiosemDropdownDataForGroup,
  getGroupsActivityDetails,
  fetchAiosemActivityForGroup
} from "./foundation/groupReducers.js";
import { commonLoader, hosts } from "./foundation/commonFdnReducer.js";

// on call schedule
import {
  on_call_common_reducer,
  on_call_common_loader_reducer,
  onCallGroupList,
  onCallGroupUsersList,
  selectedOnCallGroup,
  availableIndividuals,
} from "./onCallSchedule/common/onCallCommonReducer";
import {
  on_call_schedule_reducer,
  on_call_schedule_association_reducer,
  on_call_schedule_loader_reducer,
} from "./onCallSchedule/schedule/onCallScheduleReducer";
import {
  on_call_roster_reducer,
  on_call_roster_members_reducer,
  on_call_roster_loader_reducer,
} from "./onCallSchedule/roster/onCallRosterReducer";
import {
  on_call_shift_reducer,
  on_call_shift_loader_reducer,
  shiftListData,
} from "./onCallSchedule/shift/onCallShiftReducer";
import {
  onCallCalendarEventList,
  shiftListByGroupId,
} from "./onCallSchedule/calendar/onCallCalendarReducers.js";

// admin preferences
import {
  admin_preferences_list_data_reducer,
  admin_preferences_list_loader_reducer,
  aismProperty,
  walkupEnabled,
} from "./adminPreferences/adminPreferencesReducer.js";

// aism
import {
  aism_data_reducer,
  aism_loader_reducer,
} from "./aism/aismReducer.js";

// Location
import {
  locationData,
  locationFieldData,
  countryFieldData,
  statesFieldData,
  citiesFieldData,
  locationEditPageDetails,
  locationCountriesMap,
  locationStatesMap,
  locationCitiesMap,
} from "./foundation/locationReducers.js";
import {
  advertisementList,
  advertisementEditData,
  saveAdvertisement,
  advertisementIcon,
} from "./foundation/AdvertisementReducer.js";
import {
  BulletinList,
  BulletinEditData,
  saveBulletin,
} from "./foundation/BulletinReducer.js";
import {
  ExternalLinkListValues,
  ExternalLinkEditData,
  ExternalLinkSaved,
  ExternalLinkImage,
} from "./foundation/ExternalLinkReducer.js";
import {
  MultiSSOLogo,
  updateMultiSSOLogo,
} from "./foundation/MultiSSOLogoReducer.js";

import forumReducer from "./foundation/forum/forum";
import { forumApi } from "./foundation/forum/forumQuery";
import { actionitemApi } from "./governance/meeting/actionItem";
import { governanceApi } from "./governance/meeting/meeting";
import { scoreCardApi } from "./scorecard";
import { downloadData } from "./scorecard/downloadData";
import { themeData, appliedThemeData } from "./foundation/themesReducer.js";
import {
  escalationPlanList,
  escalationPlanLevel,
  escalationPlanDetail,
  escalationPlansLevelDetail,
} from "./onCallSchedule/escalation/escalationReducers.js";
import { VerticalCatalogApi } from "./VerticalCatalog/VerticalCatalogReducer.js";
import { rulesApi } from "./rules";
import { contractList, contractEditList, budgetList, administratorList, contractPlanList, contractAuditData, contractEditLoader, contractRelationshipData, contractRelatedWorkDetails, contractWorkDetails, administratorGroupList, resetVendor, resetCostCenter, resetAdminList } from "../reducers/ham/contractReducers.js"
import { NlpSearchApis } from "./NLP-Related-Query/WorkItemBoardQuerries";
import { statusData, priorityData, cimGroupData, supportGroupData, serviceData, criticalityData, ciData, advanceSearchClass, queryData, finalQuery, slaStatusData, companyList, serviceBasedData, reportedThroughDataBreakfix, reportedThroughDataFulfillment, urgencyList as NlpUrgencyList, impactList as NlpImpactList, showRunLoader, showAiSearchLoader } from "./NLP-Related-Query/WorkItemBoardQuerries/NlpReducer.js";
import { schedulerApi } from "./schedulerUi/index.js";

import { gdsApi } from "./gds";
import { aismAPI } from "./aism/aismAPI.js"
import { NotificationReducer } from "./NotificationApi/NotificationsApi.js";
import { SmartFormApi } from "./smartForm/smartFormApi.js";
import { OfferingTemplatesApi } from "./offeringReducers/OfferingTemplatesApi.js";

const rootReducer = combineReducers({
  courses,
  authors,

  //Used for breakFix----start
  breakFixEditDetails,
  externalSystemAttributesList,
  form: reduxFormReducer,
  breakFixSourceName,
  breakFixCriticalityName,
  breakFixUrgencyName,
  breakFixSGName,
  breakFixSGIName,
  breakFixSGIENameTypeahead,
  breakFixCause,
  cimUserDetails,
  cimUserDetailsForUser,
  cimGroupDetails,
  quickViewDetails,
  moduleList,
  multiSelectDropDownWithOk,
  loadIncData,
  wibListPayload,
  provideFixDropdownList,
  articleCategoryDropdownList,
  articleServicesDropdownList,
  articleResultsList,
  insidedResultsList,
  isFetchingInsidedDetails,
  insidedTokenDetails,
  knowledgeGlobalResultsList,
  isFetchingGlobalKnowledgeDetails,
  knowledgeCategoriesList,
  fulFillmentList,
  selectedFulfillmentItem,
  breakfixQuickViewFilters,
  breakfixQuickViewTable,
  WorkItemBoardFilterCache,
  isFetchingDetails_KA,
  attachedArticlesList,
  //used for breakFix----end
  statusBasedHamburgerOptions,
  loadHamburgerOptions,
  slaViewListRed,
  appointmentCount,
  actionCount,
  hamburgerCategory,
  hamburgerCategoryList,
  subHamburgerCategory,
  bulletin,
  chat,
  advertisement,
  bestSelling,
  catSelling,
  bestFeature,
  newLaunches,
  comingSoon,
  categoryList,
  selectedOffering,
  componentPackage,
  servicecategoryList,
  providercategoryList,
  freeoffering,
  favorites,
  welcomeMessageReducer,
  multiTenantLogoReducer,
  providersliderList,
  offeringDetails,
  offeringSlaDetails,
  cartOfferings,
  questionnaireList,
  breakFixAuditLogDetails,
  breakFixRelatedWorkDetails,
  bfRelatedWorkCheckList,
  causalRelationshipList,
  breakFixActivityLogDetails,
  breakFixStatusDetails,
  breakFixRelatedDataDetails,
  profileDetails,
  createTaskFormValues,
  reportReducer,
  //profile
  dateFormatDetails,
  timeZoneDetails,
  currencyDetails,
  CWLDetails,
  languageDetails,

  //orderNow====
  orderofferingList,
  deliverytypeList,
  orderquestionnaireList,
  orderDeliverTypes,
  //===============
  fulfillmentDetails,
  isFulfillmentGroupLoaded,
  isIncidentGroupLoaded,
  isProblemGroupLoaded,
  isChangeMgmtGroupLoaded,
  fullfilmentRelatedWorkDetails,
  fullfilmentRelatedDataDetails,
  fullFillmentStatusTimeLine,

  //myOrders
  ordersList,
  ordersListGblSearch,
  myOrderOrderDetails,
  loadReasons,
  myBreakFixOrderOrderDetails,
  myBreakFixOrderOrderDetailsInteger,
  urgencyList,
  offeringForOrder,
  approverListDetails,
  myOrderViewFilters,
  myOrderQuestionDetails,
  categoryName,
  resetSortArrows,

  rightSidebarMenulist,
  breakFixTaskLogDetails,
  taskTypeList,
  taskAuditLogDetails,
  breakFixTaskEditDetails,
  myTaskDetailsList,
  taskCountByItemId,

  //Reviews
  myReviewOrdersList,
  myPendingReviewOrdersList,
  myPendingReviewOrdersCount,
  myReviewOrderDetails,
  myReviewBreakFixOrderDetails,
  myReviewOrderDetailsFrmEngine,
  topReviewsList,
  breakFixSGAENameType,
  breakFixSGAEName,
  breakFixSGAENameCIM,
  allCIMgroups,
  allCIMgroupsRules,
  breakFixSGAEChangegroup,
  //=======

  //myService
  myServiceList,
  serviceList,
  myServiceDetails,
  userListReviewFromRE,
  ratingCalcReviewFromRE,
  loadItmData,
  serviceReviewwFromRE,
  /* my approvals */
  approvalList,
  adhocApproversList,
  delegationList,

  /*serviceportfolio
  servicePortFolioList,
  servicePortfolioLoggedInUserCompany,
  catalogAndPortfolioGroups,
  portfolioManagerIndividual,
  catlogManagerIndividual,
  servicePortfolioStatusList,
  servicePortfoliosubStatusList,
  servicePortfolioSubCategoryList,
  editPortfolioService,
  servicePortfolioUserInfo,
  selectedServicePortfolioDetails,*/
  acquaintedArticles,
  /*Investigation*/
  investigationList,
  specificVendorTicketList,
  loadPrbData,
  investigationItemDetails,
  investigationTeamList,
  breakFixSLALogDetails,
  invCauseName,
  invRelatedWorkDetails,
  invRelatedDataDetails,
  invRelatedDataDetailsCount,
  breakFixSLAResponseLogDetails,
  breakFixSLAResolutionLogDetails,
  //CM
  myChangeManagementList,
  implememtationPlanList,
  //lov for changeManagement
  lovForType,
  lovForChangeTiming,
  lovForBusinessRisk,
  lovForTechnicalRisk,
  lovForPlanType,
  lovForUrgency,
  lovForReasonForChange,
  lovForRiskOccurrence,
  lovForRiskImpact,
  lovForControlEffectiveness,
  riskAssessmentValue,
  changeRelatedWorkDetails,
  changeRelatedDataDetails,
  relatedworkitemsCount,
  cmdbChangeRelatedWorkDetails,
  relatedAssetsWorkDetails,
  cmdbChangeRelatedDataDetails,
  editChangeInitialData,
  extractedChangeDataForSucessRate,
  fieldStatus,
  changeConflictJustification,
  changeConflictData,
  loadPirData,
  loadCngData,
  investigationCriticality,
  queryBuilderAllRulesData,
  queryBuilderData,
  queryBuilderChildData,
  postQueryBuilderData,
  amsEditRuleConfiguration,
  investigationSource,
  investigationPriority,
  investigationUrgency,
  implementationPlanDetails,
  timelineDataResult,
  specificUserDetails,
  changeApprovalDetails,
  changeTotalApprovalDetails,
  requesterDelegateeDetails,
  changeTotalApprovalHistoryDetails,
  breakFixSGAEChangeName,
  breakFixSGAEChangeNameType,
  breakFixSGAEEditName,
  breakFixSGAETaskName,
  postAttributeBasedSlaReducer,
  queryBuilderChildAttributes,
  //cmdb
  cmdbQuickItemList,
  cmdbQuickTechnicalItemList,
  cmdbQuickBusinessItemList,
  statusList,
  subStatusList,
  cmdAssetsBasedOnClassList,
  CIModelName,
  ciClass,
  ciType,
  ciName,
  ciStatus,
  ciAllStatus,
  ciSubStatus,
  ciSubStatusCopy,
  ciMetallic,
  ciEnv,
  ciResidualRisk,
  approvalsAutoAction,
  ciManr,
  ciDataset,
  ciCompany,
  companyReportData,
  ciLocation,
  ciUserBasedLocation,
  ciGroup,
  ciGroupForTaskPlan,
  ciInd,
  CIEditDetails,
  ciCat,
  ciSCat,
  ciAtt,
  ciResolutionCat,
  cmdbAuditLogDetails,
  ciSupCompany,
  vendorSuccess,
  cmdbSupportCompany,
  cmdbQuickViewFilters,
  spcmReducer,
  attachedKnowledgeArticlesList,
  isFetchingDetails_Knowledge,
  toaAuditData,
  rulesAuditData,
  getApprovalRulesData,
  refreshDataDictionaryReducer,
  rulesDbDetailsReducer,
  ciCategories,
  relationshipTypes,
  classNames,
  assetCIListByClassName,
  ciForTypeahead,
  assetCIDisclaimer,
  FdnTagList,
  //sla
  slaDefEditDetails,
  slaCompany,
  slaModule,
  slaStatus,
  slaService,
  slaImpacted,
  slaType,
  slaTarget,
  slaCriteriaType,
  slaOperatorType,
  slaMilestoneActionList,
  slaMilestoneTargetList,
  slaHolidaySchedules,
  slaWorkdaySchedules,
  slaWorkdaySchedulesDays,
  slaWorkdaySchedulesStatus,
  slaAttributes,
  slaDefList,
  slaDefActiveMandatory,
  slaModuleStatusValues,
  targetSlaAcitvity,
  opsCategoryLevels,
  slaAttributesList,
  slaSelectedAttributesList,
  slaMilestoneList,
  slaWorkdayList,
  slaHolidayList,
  slaWorkScheduleDetails,
  newSlaWorkScheduleAssociation,
  slaHolidayScheduleDetails,
  slaUniqueMilestoneFromRecord,
  slaUniqueEventTypeFromRecord,
  slaHolidayDetails,
  slaRelatedDetails: slaRelatedDetails,
  cmdbTimeLineData,
  cmdbRelationshipData,
  timeZoneData,
  companyData,
  companyDataTypeahead,
  associatedCompanyData,
  associatedCompanyDataTypeahead,
  groupFunctionDataTypeahead,
  groupData,
  groupDataTypeahead,
  allGroups,
  allgroupdata,
  allquerygroupdata,
  individualData,
  problemTeam,
  moduleData,
  eventData,
  parameterData,
  parameterValueData,
  ruleConfiguration,
  ruleCondition,
  editRuleConfiguration,
  companyService,
  companyServiceTypeahead,
  allRuleConditions,
  companyStatus,
  allRuleConfigurationsData,
  allAssignmentGroups,
  reportCompany,
  reportModule,
  criteriaList,
  reportFields,
  reportListData,
  globalSearchData,
  globalSearchDataForRevamp,
  globalSearchListSearchText,
  globalSearchListCategoryId,
  wibGlobalSearchFilterData,
  realatedTaskCount,
  relatedCMDBTasks,
  taskGroupData,
  taskGroupData2,
  taskAssignedToData,
  regionListData,
  editTaskStatus,
  taskStatusToEdit,
  breakFixDetailsLoadSatatus,
  breakfixD2cNumber,
  isFetchingDetails,
  sLaHolidayFetchingDetails,
  loading_orders_gsearch_2,
  isFetchingTimeline,
  isCausalRelationshipData,
  reasonCode,
  complexity,
  taskInputOutputVariables,
  slaHolidayEntriesList,
  changeManagementGroupIndividual,
  changeManagementGroupIndividualTypeahead,
  changeManagementGroupImplIndividual,
  changeManagementGroupImplIndividualTypeahead,
  showLoader,
  allCategoriesLoader,
  topReviewLoader,
  showLoader2,
  showLoaderRelatedTask,
  slaEditLov,
  selectedServiceDeliveries,
  rightMenuShowLoader,
  serviceQuestionnaireCategories,
  userDefinedServiceQuestionnaireCategories,
  categoryCountList,
  orderingInformationDetails,
  categoryParentQuestions,
  selectedFulfillmentItemComponent,
  fetchApprovalQuestionsDetails,
  classData,
  impactedCIData,
  // AMS
  attributesList_ams,
  operatorsList_ams,
  amsAttributeList_ams,
  loaders_ams,
  isClassExist_list,
  // AMS END
  recipientDeliveryLocations,
  recipientDeliveryCountries,
  recipientDeliveryLocationsIsShared,
  // Cart Count
  externalLinks,
  cartCount,
  selectedComponentForOffering,
  haveMandatQuestionReducer,
  showLoader3,
  showLoader4,
  categoryBoardList,
  categoryDropdown,
  statusDropdown,
  categoryEditDetails,
  associatedCompanies,
  showLoaderCICategory,
  ciCategoryListData,
  ciCategoryListDataLoader,
  bulkPurchaseQuestions,
  getIntegrationHubData,
  reportList,
  versionList,
  showLoader_1,
  showLoader_2,
  showLoader_3,
  showLoader_5,
  showLoader_6,
  showLoader_ne,
  relatedOfferings,
  offeringView,
  showLoader_SLA_Deflist_Pagination,
  showLoader_SLA_Work_Schedule_Pagination,
  showLoader_SLA_Holiday_Schedule_Pagination,
  amsBoardShowLoader,
  featuredShowLoader,
  revampGlobalSearchShowLoader,
  taskStatusLoader,
  bestSellingShowLoader,
  comingSoonShowLoader,
  favouriteServiceShowLoader,
  freeServiceShowLoader,
  newLaunchShowLoader,
  allCINames,
  hourlySlicingData,
  hamList,
  classEditList,
  compClass,
  dailySlicingData,
  alertsData,
  delegateShowLoader,
  showCINamesDataLoader,
  showSlicingDataLoader,
  showAlertsDataLoader,
  serviceReviewShowLoader,
  slaDetailsShowLoader,
  reviewFilters,
  CategoriesByCompanyIdDropdown,
  neReducer,
  customVariablesReducer,
  auditLogNEReducer,
  rcaTechniques,
  articleDetails,
  // master_questions,
  cartOfferingAttachmentsReducer,
  windowSize,
  approvalHistoryData,
  urgencyValuesReducer,
  watcherDetailsFetched,
  watcherLoader,
  parentFunctionByCompanyIdDropdown,
  businessFunctionList,
  businessFunctionListIntialValues,
  wibSearchParams,
  isSearchParamsApply,
  wibActiveTab,
  urgencyImpactReducer,
  systemCofigList,
  savedSystemCofigList,
  savedSurveyCofigList,
  savedApprovalCofigList,
  savedMailboxCofigList,
  editSystemConfigList,
  deleteSystemConfigList,
  wibSelectedDateRanceLabel,
  foundation_tags_reducer,
  //dashboard reducer
  getDashboardReducerData,
  dashboardListData,
  foundation_tags_loader_reducer,
  // companyFoundation
  companyFdnList,
  userListRed,
  userDepListRed,
  userCostCenterRed,
  userLocationRed,
  userManagerRed,
  userOrgListRed,
  languageListRed,
  dateListREd,
  authenticationRed,
  userTypeRed,
  timezoneRed,
  currencyRed,
  editDetailsRed,
  userPageCompanyValues,
  userGroupsRed,
  userRolesRed,
  accessRestrictRed,
  userCompanyByTypeRed,
  disassociateCompRed,
  mapDepartmentRed,
  mapOrganizationRed,
  mapCWLRed,
  notSyncRed,
  SyncRed,
  cwlDataRed,
  userJwtData,
  countryRed,
  menuListFDNReducer,
  editCompanyFdnList,
  associateGroupDropdown,
  associateCompanyDropdown,
  associatedCompaniesDropdownValue,
  disassociatedcompany,
  disassociatedGroup,
  associationFlag,
  syncedData,
  associationTypeMenuList,
  disassociateConsumerCompany,
  serviceConsumerMenuList,
  notServiceConsumerMenuList,

  // costCenter
  costCenterList,
  getCostCenterEditDetails,
  costCenterCompanyDropdown,
  costCenterStatusValues,

  //department
  departmentList,
  departmentEditList,
  organizationList,

  //organization
  organizationData,
  organizationEditPageData,
  orgCompanyDropdown,
  on_call_common_reducer,
  // Groups
  groupList,
  groupInitialValues,
  companyMenuListValues,
  groupEditPageValues,
  groupForumValues,
  groupCategoryValues,
  assoMembersValues,
  associateRoleValues,
  disassociatedMember,
  disassociatedRoles,
  aiosemDropdownDataForGroup,
  getGroupsActivityDetails,
  fetchAiosemActivityForGroup,

  //commonFdnReducer
  commonLoader,
  on_call_schedule_reducer,
  on_call_schedule_association_reducer,
  on_call_schedule_loader_reducer,
  on_call_roster_reducer,
  on_call_roster_members_reducer,
  on_call_roster_loader_reducer,
  onCallGroupList,
  onCallGroupUsersList,
  onCallCalendarEventList,
  shiftListByGroupId,
  shiftListData,
  on_call_shift_reducer,
  on_call_shift_loader_reducer,
  selectedOnCallGroup,
  availableIndividuals,

  // Location
  locationData,
  locationFieldData,
  statesFieldData,
  citiesFieldData,
  countryFieldData,
  locationEditPageDetails,
  locationCountriesMap,
  locationStatesMap,
  locationCitiesMap,

  //Advertisement
  advertisementList,
  advertisementEditData,
  saveAdvertisement,
  advertisementIcon,

  //Bulletin
  BulletinList,
  BulletinEditData,
  saveBulletin,

  // ExternalLink
  ExternalLinkListValues,
  ExternalLinkEditData,
  ExternalLinkSaved,
  ExternalLinkImage,

  // MultiSSOLogo
  MultiSSOLogo,
  updateMultiSSOLogo,

  admin_preferences_list_data_reducer,
  admin_preferences_list_loader_reducer,
  
  aism_data_reducer,
  aism_loader_reducer,

  forums: forumReducer,
  //Governance
  [governanceApi.reducerPath]: governanceApi.reducer,
  [forumApi.reducerPath]: forumApi.reducer,
  [actionitemApi.reducerPath]: actionitemApi.reducer,
  [scoreCardApi.reducerPath]: scoreCardApi.reducer,
  [rulesApi.reducerPath]: rulesApi.reducer,

  // NLP Search for D2C
  [NlpSearchApis.reducerPath]: NlpSearchApis.reducer,
  statusData,
  priorityData,
  cimGroupData,
  supportGroupData,
  serviceData,
  criticalityData,
  ciData,
  advanceSearchClass,
  queryData,
  finalQuery,
  slaStatusData,
  companyList,
  serviceBasedData,
  reportedThroughDataBreakfix,
  reportedThroughDataFulfillment,
  NlpUrgencyList,
  NlpImpactList,
  showRunLoader,
  showAiSearchLoader,
  
  //theme data
  themeData,
  appliedThemeData,

  //companymenuList
  companyMenuListDataLoader,
  companyMenuListData,

  [VerticalCatalogApi.reducerPath]: VerticalCatalogApi.reducer,

  ///// escalation
  escalationPlanList,
  escalationPlanLevel,
  escalationPlanDetail,
  escalationPlansLevelDetail,

  //Contract
  contractList,
  contractEditList,
  budgetList,
  administratorList,
  contractPlanList,
  contractAuditData,
  contractEditLoader,
  contractRelationshipData,
  contractRelatedWorkDetails,
  contractWorkDetails,
  administratorGroupList,
  resetVendor,
  resetCostCenter,
  resetAdminList,
  NotificationListByWID,
  //// scheduler ui
  [schedulerApi.reducerPath]: schedulerApi.reducer,
  wibSlaColors,

  ///GDS API
  [gdsApi.reducerPath]: gdsApi.reducer,
  hosts,
  
  //AISM API
  [aismAPI.reducerPath]: aismAPI.reducer,
  walkupEnabled,
  aismProperty,

  //Scorecard Data
  downloadData, 
  [NotificationReducer.reducerPath]: NotificationReducer.reducer,
  [SmartFormApi.reducerPath]: SmartFormApi.reducer,
  customDataSources,
  [OfferingTemplatesApi.reducerPath]: OfferingTemplatesApi.reducer,
});

export default rootReducer;
