
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import Swal from "sweetalert2";
import * as api from "../services/spcmApi";
import Cookies from "universal-cookie";
import { GLOBAL }  from '_Globals';
import axios from 'axios';
export function createService(form) {
  return (dispatch) => {
    api.postServiceAPI(form).then((resp) => {
      dispatch({
        type: "SERVICE_DETAILS",
        payload: resp.data,
      });
    });
  };
}

export function audittReminders(module, offeringId, audit_type, orderBy) {
  return (dispatch) => {
    api.auditReminders(module, offeringId, audit_type, orderBy).then((resp) => {
      dispatch({
        type: "REMINDER",
        payload: resp.data,
      });
    });
  };
}
export function clearReminder() {
  return (dispatch) => {
    dispatch({
      type: "REMINDER",
      payload: "",
    });
  };
}

export function getTranslation(lang) {
  return (dispatch) => {
    api.getTranslationAPI(lang).then((resp) => {
      dispatch({
        type: "TRANSLATION",
        payload: { data: resp.data, lang },
      });
    });
  };
}

export function updateService(form) {
  return (dispatch) => {
    api.patchServiceAPI(form).then((resp) => {
      dispatch({
        type: "SERVICE_DETAILS",
        payload: resp.data,
      });
    });
  };
}

export const getCompanyList = (userId) => {
  return (dispatch) => {
    dispatch({ type: "FETCHING_DETAILS_IN_PROGRESS" });
    api.getCompanyListAPI(userId).then((resp) => {
      dispatch({ type: "DETAILS_FETCHED_SUCCESSFULLY" });
      dispatch({
        type: "COMPANY",
        payload: resp.data,
      });
    });
  };
};
export const postNEcompanyDetails = (id,langCode) => {
  return (dispatch) => {
    dispatch({ type: "FETCHING_DETAILS_IN_PROGRESS" });
    return api.postNEcompanyDetailsApi(id,langCode).then((resp) => {
      dispatch({ type: "DETAILS_FETCHED_SUCCESSFULLY" });

      dispatch({
        type: "NE_COMPANY",
        payload: resp.data,
        spcmPostingAttribute: true,
        spcmPostingService: false,
      });
      return resp.data;
    });
  };
};

export const getMilboxName = (tenantId) => {
  return (dispatch) => {
    return api.getMilboxName(tenantId).then((resp) => {
      dispatch({
        type: "NE_COMPANY_MAILBOX",
        payload: resp.data,
      });
      return resp.data;
    });
  };
};

export const uploadLogoForCompanyNE = (id, values) => {
  return (dispatch) => {
    api.uploadLogoForCompanyNEApi(id, values).then((resp) => {
      dispatch({
        type: "LOGO_NE",
        payload: resp.data,
        spcmPostingAttribute: true,
        spcmPostingService: false,
      });
    });
  };
};

export const getCompanyListCopy = (userId) => {
  return (dispatch) => {
    api.getCompanyListAPI(userId).then((resp) => {
      dispatch({
        type: "COMPANY_COPY",
        payload: resp.data,
      });
    });
  };
};
export const getGroupList = (companyId,type) => {
  return (dispatch) => {
    api.getCatalogueGroupListAPI(companyId).then((resp) => {
      if(type == 'RESET_GROUPS'){
        dispatch({
          type: "GROUPS",
          payload: [],
        });
      }else{
        dispatch({
          type: "GROUPS",
          payload: resp.data === "" ? [] : resp.data,
        });
        
      }
    });
  };
};

export const resetGroupList = (companyId) => {
  return (dispatch) => {
    dispatch({
      type: "RESETGROUPS",
      payload: []
    });
  };
};

export const getManagerList = (groupId,type) => {
  return (dispatch) => {
    let serviceOwnerListArr = [];
    if(type == 'SERVICE_OWNER_BY_GROUPID'){
      api.getPortfolioManagerListAPI(groupId).then((resp) => {
    const soList = resp.data;
        if (typeof soList !== "undefined" && soList.length > 0) {
          soList.forEach((values) => {
            let val = { id: values.field1Value, label: values.field1Key };
            serviceOwnerListArr.push(val);
          });
        }
        dispatch({
          type: "MANAGERS",
          payload: serviceOwnerListArr,
        });
      });
    }
    else if (type == 'EMPTY_MANAGER_LIST'){
      dispatch({
        type: "MANAGERS",
        payload: [],
      });
    }
    else{
      api.getPortfolioManagerListAPI(groupId).then((resp) => {
        dispatch({
          type: "MANAGERS",
          payload: resp.data,
        });
      });
    }   
  };
};

export const getPortfolioGroupList = (companyId) => {
  return (dispatch) => {
    api.getPortfolioGroupListAPI(companyId).then((resp) => {
      dispatch({
        type: "PORTFOLIO_GROUP",
        payload: resp.data,
      });
    });
  };
};

export const getPortfolioManagerList = (groupId) => {
  return (dispatch) => {
    api.getPortfolioManagerListAPI(groupId).then((resp) => {
      dispatch({
        type: "PORTFOLIO_MANAGER",
        payload: resp.data,
      });
    });
  };
};

export const getCatalogueGroupList = (companyId) => {
  return (dispatch) => {
    api.getCatalogueGroupListAPI(companyId).then((resp) => {
      dispatch({
        type: "CATALOGUE_GROUP",
        payload: resp.data,
      });
    });
  };
};

export const getCatalogueManagerList = (groupId) => {
  return (dispatch) => {
    api.getCatalogueManagerListAPI(groupId).then((resp) => {
      dispatch({
        type: "CATALOGUE_MANAGER",
        payload: resp.data,
      });
    });
  };
};

/**
 * Return all services data
 */

export const getAllServicesList = (
  queryObj = "",
  pageNo = 1,
  pageSize = 10
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: true,
      spcmFetchingAttribute: false,
      spcmFetchingError: false,
    });
    api
      .getAllServices(queryObj, pageNo, pageSize)
      .then((resp) => {
        dispatch({
          type: "ALL_SERVICES_LIST",
          payload: resp.data.data,
          meta: resp.data.meta,
        });

        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: "ALL_SERVICES_LIST",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmErrorMsg: err.response.data,
        });
      });
  };
};
export const getAllServiceListForSearch = () => {
  return (dispatch) => {
    api
      .getAllServicesListForSearch()
      .then((resp) => {
        dispatch({
          type: "SERVICES_LIST_FOR_SEARCH",
          payload: resp.data.data,
          meta: resp.data.meta,
        });
      })
      .catch((err) => {
        dispatch({
          type: "SERVICES_LIST_FOR_SEARCH",
          payload: [],
        });
      });
  };
};

export const getNotificationDataListApi = (
 modifiedDate,
  type,
  language,
  module,
  status,
  searchParams,
  pageNo = 1,
  pageSize = 10
) => {
  return (dispatch) => {
    dispatch({ type: "LOADER_NE_IN_PROGRESS" });
    api
      .getNotificationDataList(
        modifiedDate,
        type,
        language,
        module,
        status,
        searchParams,
        pageNo,
        pageSize
      )
      .then((resp) => {
        dispatch({
          type: "NOTIFICATION_DATA",
          payload: resp.data.data,
          meta: resp.data.meta,
        });
        dispatch({ type: "LOADER_NE_DETAILS_FETCHED_SUCCESSFULLY" });
      })
      .catch((err) => {
        console.log("ne error", err);
        dispatch({
          type: "NOTIFICATION_DATA",
          payload: err.response.data[0].message,
        });
        dispatch({ type: "LOADER_NE_DETAILS_FETCHED_SUCCESSFULLY" });
      });
  };
};

export const getEditFormNEDetails = (notificationID, localeID, tenantId) => {
  return (dispatch) => {
    api.getEditFormNEDetailsApi(notificationID, localeID, tenantId).then((response) => {
      dispatch({
        type: "EDITFORM_NE_DETAILS",
        payload: response.data,
      });
    });
  };
};

//------
export const getAuditLogsNEData = (templateId, templateType) => {
  return (dispatch) => {
    api.getAuditLogsNEDataAPI(templateId,templateType).then((response) => {
      dispatch({
        type: "NE_AUDIT_LOGS",
        payload: response.data.data,
      });
    });
  };
};

//---------
export const getTemplateVariables = (notificationID, localeID) => {
  return (dispatch) => {
    api.getTemplateVariablesApi(notificationID, localeID).then((response) => {
      dispatch({
        type: "TEMPLATE_VARIABLES",
        payload: response.data,
      });
    });
  };
};

export function patchTemplateFormNE(notificationID, values,templateId,audit_type) {
  return (dispatch) => {
    api.patchTemplateFormNEApi(notificationID, values).then((resp) => {
      dispatch({
        type: "EDITFORM_TEMPLATE_NE",
        payload: resp.data,
      });
       dispatch(getAuditLogsNEData(templateId,audit_type))
    });
  };
}

export function overRideCustomTemplate(values) {
  return (dispatch) => {
    api.overRideTemplateFormNEApi(values).then((resp) => {
      Swal.fire(
        resp.data.Message,
      )
      dispatch({
        type: "OVERRIECUSTOM_TEMPLATE_NE",
        payload:resp,
      });
    })
    .catch((error)=>{
      Swal.fire(
        error.response.data.Message,
      )

    })
    
  };
}
export const getServiceDetailCounts = (offeringId) => {
  return (dispatch) => {
    api.getServiceDetailCountsAPI(offeringId).then((resp) => {
      dispatch({
        type: "SERVICE_DETAILS_COUNTS",
        payload: resp.data,
      });
    });
  };
};

export const getAuditHistory = (offeringId) => {
  return (dispatch) => {
    api
      .getAuditHistoryAPI(offeringId)
      .then((resp) => {
        dispatch({
          type: "AUDIT",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "AUDIT",
          payload: [],
        });
      });
  };
};

export const getReviews = (offeringId) => {
  let config = {
    // params: {
    //   filter: { where: { workItemId: offeringId /* ,"requestorId":1 */ } },
    // },
    headers: {
      "X-IBM-Client-Id": "b56cce80-a3fc-4194-87d7-edddb4d3bd28",
      query: JSON.stringify({ workItemId: offeringId }),
    },
  };

  return (dispatch) => {
    api.getReviewsAPI(config).then((resp) => {
      dispatch({
        type: "REVIEW",
        payload: resp.data,
      });
    });
  };
};

export const setServiceDetails = (data) => {
  return (dispatch) => {
    dispatch({
      type: "CURRENTLY_SELECTED_SERVICE",
      payload: data,
    });
  };
};

export const getCriticalityList = () => {
  return (dispatch) => {
    api.getCriticalityListAPI().then((resp) => {
      dispatch({
        type: "CRITICALITY_LIST",
        payload: resp.data,
      });
    });
  };
};

export const getCategoryList = (companyId, serviceBoard, businessFunction = "") => {
  return (dispatch) => {
    api.getCategoryListAPI(companyId, serviceBoard, businessFunction).then((resp) => {
      dispatch({
        type: "CATEGORY_LIST",
        payload: resp.data,
      });
    });
  };
};

export const resetCategoryList = () => {
  return (dispatch) => {
    dispatch({
      type: "CATEGORY_LIST",
      payload: [],
    });
  };
};

export const getCategoryListCopy = (companyId, serviceBoard, businessFn = "0") => {
  return (dispatch) => {
    api.getCategoryListAPI(companyId, serviceBoard, businessFn).then((resp) => {
      dispatch({
        type: "CATEGORY_LIST_COPY",
        payload: resp.data,
      });
    });
  };
};
export const getEditServiceDetails = () => {
  return (dispatch) => {
    dispatch({
      type: "EDIT_SERVICE",
    });
  };
};

export const setEditServiceDetails = (values) => {
  return (dispatch) => {
    dispatch({
      type: "EDIT_LEFT_TAB_SERVICE",
      payload: values,
    });
  };
};

export const clearEditServiceDetails = () => {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_EDIT_SERVICE",
    });
  };
};

export const uploadPicture = (offeringId, values, callback) => {
  return (dispatch) => {
    api.uploadPictureAPI(offeringId, values).then((resp) => {
      if (resp.status == "200") {
        callback("Uploaded Successfully", "success");
        dispatch({
          type: "CURRENTLY_SELECTED_SERVICE",
          payload: resp.data,
        });
      } else {
        callback("Error Uploading Image", "error");
      }
    });
  };
};

export const getConsumptionItems = (
  offeringId,
  query = "",
  queryValue = "",
  sort = "",
  order,
  pageNo = 1,
  pageSize = 10
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "CONSUMPTION_ITEM",
    });
    api
      .getConsumptionItemsAPI(
        offeringId,
        query,
        queryValue,
        sort,
        order,
        pageNo,
        pageSize
      )
      .then((resp) => {
        dispatch({
          type: "CONSUMPTION_ITEM",
          payload: resp.data.data,
          meta: resp.data.meta,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "CONSUMPTION_ITEM",
        });
      })
      .catch((err) => {
        dispatch({
          type: "CONSUMPTION_ITEM",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "CONSUMPTION_ITEM",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const getServiceMyQuestionLists = (categoryId) => {
  return (dispatch) => {
    api
      .getServiceMyQuestionLists(categoryId)
      .then((resp) => {
        dispatch({
          type: "SERVICE_QUESTIONS_LIST",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "SERVICE_QUESTIONS_LIST",
          payload: [],
        });
      });
  };
};

export const getServiceSupportCompanyGroups = (offeringId) => {
  return (dispatch) => {
    api
      .getServiceSupportCompanyGroups(offeringId)
      .then((resp) => {
        dispatch({
          type: "SERVICE_SUPPORT_COMPANY_GROUPS_LIST",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "SERVICE_SUPPORT_COMPANY_GROUPS_LIST",
          payload: [],
        });
      });
  };
};

export const getServiceProviderCompanyGroups = (offeringId) => {
  return (dispatch) => {
    api
      .getServiceProviderCompanyGroups(offeringId)
      .then((resp) => {
        dispatch({
          type: "SERVICE_PROVIDER_COMPANY_GROUPS_LIST",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "SERVICE_PROVIDER_COMPANY_GROUPS_LIST",
          payload: [],
        });
      });
  };
};

export const getServiceOrderChoices = (
  offeringId,
  query = "",
  queryValue = "",
  sort = "Sequence",
  order = "asc",
  pageNo = 1,
  pageSize = 10
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "SERVICE_ORDER_CHOICES",
    });
    api
      .getServiceOrderChoicesAPI(
        offeringId,
        query,
        queryValue,
        sort,
        order,
        pageNo,
        pageSize
      )
      .then((resp) => {
        dispatch({
          type: "SERVICE_ORDER_CHOICES",
          payload: resp.data.data,
          meta: resp.data.meta,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "SERVICE_ORDER_CHOICES",
        });
      })
      .catch((err) => {
        dispatch({
          type: "SERVICE_ORDER_CHOICES",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "SERVICE_ORDER_CHOICES",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const getAttributes = (
  offeringId,
  query = "",
  queryValue = "",
  sort = "",
  order,
  pageNo = 1,
  pageSize = 10
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "ATTRIBUTES",
    });
    api
      .getAttributesAPI(
        offeringId,
        query,
        queryValue,
        sort,
        order,
        pageNo,
        pageSize
      )
      .then((resp) => {
        dispatch({
          type: "ATTRIBUTES",
          payload: resp.data.data,
          meta: resp.data.meta,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "ATTRIBUTES",
        });
      })
      .catch((err) => {
        dispatch({
          type: "ATTRIBUTES",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "ATTRIBUTES",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const getServiceBilling = (
  offeringId,
  query = "",
  queryValue = "",
  sort = "",
  order,
  pageNo = 1,
  pageSize = 10
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "SERVICE_BILLING",
    });
    api
      .getServiceBillingAPI(
        offeringId,
        query,
        queryValue,
        sort,
        order,
        pageNo,
        pageSize
      )
      .then((resp) => {
        dispatch({
          type: "SERVICE_BILLING",
          payload: resp.data.data,
          meta: resp.data.meta,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "SERVICE_BILLING",
        });
      })
      .catch((err) => {
        dispatch({
          type: "SERVICE_BILLING",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "SERVICE_BILLING",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const getEntitlements = (
  offeringId,
  query = "",
  queryValue = "",
  sort = "",
  order,
  pageNo = 1,
  pageSize = 10
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "ENTITLEMENTS",
    });
    api
      .getEntitlementsAPI(
        offeringId,
        query,
        queryValue,
        sort,
        order,
        pageNo,
        pageSize
      )
      .then((resp) => {
        dispatch({
          type: "ENTITLEMENTS",
          payload: resp.data.data,
          meta: resp.data.meta,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "ENTITLEMENTS",
        });
      })
      .catch((err) => {
        dispatch({
          type: "ENTITLEMENTS",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "ENTITLEMENTS",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const getServiceKnowledge = (
  offeringId,
  query = "",
  queryValue = "",
  sort = "",
  order,
  pageNo = 1,
  pageSize = 10
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "SERVICE_KNOWLEDGE",
    });
    api
      .getServiceKnowledgeAPI(
        offeringId,
        query,
        queryValue,
        sort,
        order,
        pageNo,
        pageSize
      )
      .then((resp) => {
        dispatch({
          type: "SERVICE_KNOWLEDGE",
          payload: resp.data.data,
          meta: resp.data.meta,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "SERVICE_KNOWLEDGE",
        });
      })
      .catch((err) => {
        dispatch({
          type: "SERVICE_KNOWLEDGE",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "SERVICE_KNOWLEDGE",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const resetFulfillmentPlan = () => {
  return (dispatch) => {
    dispatch({
      type: "SERVICE_FULFILMENT_PLANS",
      payload: [],
    });
  }
}

export const getFulfilmentPlans = (offeringId, fulfillmentPlanFor, order) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "SERVICE_FULFILMENT_PLANS",
    });
    api
      .getFulfilmentPlansAPI(offeringId, fulfillmentPlanFor, order)
      .then((resp) => {
        dispatch({
          type: "SERVICE_FULFILMENT_PLANS",
          payload: resp.data,
        });

        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "SERVICE_FULFILMENT_PLANS",
        });
      })
      .catch((err) => {
        dispatch({
          type: "SERVICE_FULFILMENT_PLANS",
          payload: [],
        });

        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "SERVICE_FULFILMENT_PLANS",
        });
      });
  };
};

export const getTaskPlans = (ciId, taskPlanFor) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "CI_TASK_PLANS",
    });
    api
      .getTaskPlansAPI(ciId, taskPlanFor)
      .then((resp) => {
        dispatch({
          type: "CI_TASK_PLANS",
          payload: resp.data,
        });

        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "CI_TASK_PLANS",
        });
      })
      .catch((err) => {
        dispatch({
          type: "CI_TASK_PLANS",
          payload: [],
        });

        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "CI_TASK_PLANS",
          //spcmErrorMsg:err.response.data
        });
      });
  };
};

export const getLOVFullfilmentPlan = () => {
  return (dispatch) => {
    api
      .getLOVFullfilmentPlanAPI()
      .then((resp) => {
        dispatch({
          type: "SERVICE_FULFILMENT_LOV",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "SERVICE_FULFILMENT_LOV",
          payload: [],
        });
      });
  };
};

export const getChainEntities = (
  offeringId,
  query = "",
  queryValue = "",
  sort = "",
  order,
  pageNo = 1,
  pageSize = 10
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "CHAIN_ENTITIES",
    });
    api
      .getChainEntitiesAPI(
        offeringId,
        query,
        queryValue,
        sort,
        order,
        pageNo,
        pageSize
      )
      .then((resp) => {
        dispatch({
          type: "CHAIN_ENTITIES",
          payload: resp.data.data,
          meta: resp.data.meta,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "CHAIN_ENTITIES",
        });
      })
      .catch((err) => {
        dispatch({
          type: "CHAIN_ENTITIES",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "CHAIN_ENTITIES",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const getServiceLevels = (
  offeringId,
  query = "",
  queryValue = "",
  sort = "",
  order,
  pageNo = 1,
  pageSize = 10
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "SERVICE_LEVELS",
    });
    api
      .getServiceLevelsAPI(
        offeringId,
        query,
        queryValue,
        sort,
        order,
        pageNo,
        pageSize
      )
      .then((resp) => {
        dispatch({
          type: "SERVICE_LEVELS",
          payload: resp.data.data,
          meta: resp.data.meta,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "SERVICE_LEVELS",
        });
      })
      .catch((err) => {
        console.log(err);
        console.log("sdffddd");
        dispatch({
          type: "SERVICE_LEVELS",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "SERVICE_LEVELS",
        });
      });
  };
};

export const applySLAFilter = (
  query = "",
  queryValue = "",
  sort = "",
  pageNo = 1,
  pageSize = 10
) => {
  let filters = {
    query: query,
    queryValue: queryValue,
    sort: sort,
    pageNo: pageNo,
    pageSize: pageSize,
  };
  return (dispatch) => {
    dispatch({
      type: "SLA_FILTER",
      payload: filters,
      meta: [],
    });
  };
};

export const getSelfServices = (
  offeringId,
  query = "",
  queryValue = "",
  sort = "",
  order,
  pageNo = 1,
  pageSize = 10
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "SELF_SERVICES",
    });
    api
      .getSelfServicesAPI(
        offeringId,
        query,
        queryValue,
        sort,
        order,
        pageNo,
        pageSize
      )
      .then((resp) => {
        dispatch({
          type: "SELF_SERVICES",
          payload: resp.data.data,
          meta: resp.data.meta,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "SELF_SERVICES",
        });
      })
      .catch((err) => {
        dispatch({
          type: "SELF_SERVICES",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "SELF_SERVICES",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const setEditId = (id) => {
  return (dispatch) => {
    dispatch({
      type: "EDIT_ID",
      payload: id,
    });
  };
};

export const setLeftEditId = (id) => {
  return (dispatch) => {
    dispatch({
      type: "LEFT_EDIT_ID",
      payload: id,
    });
  };
};

export const setForm = (form) => {
  return (dispatch) => {
    dispatch({
      type: "CURRENT_FORM",
      payload: form,
    });
  };
};

export const getEntitlementEntity = (entity, companyId) => {
  return (dispatch) => {
    api
      .getEntitlementEntityAPI(entity, companyId)
      .then((resp) => {
        if (entity == "group") {
          let data = resp.data.map((item) => {
            const obj = {};
            obj.field1Key = item.GROUPNAME;
            obj.field1Value = item.GROUPID;
            return obj;
          });
          resp.data = data;
        }
        if (entity == "employeeband") {
          let data = resp.data.map((item) => {
            const obj = {};
            obj.field1Key = item.field1Key;
            obj.field1Value = item.field1Key;
            return obj;
          });
          resp.data = data;
        }
        dispatch({
          type: "ENTITLEMENT_ENTITIES",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "ENTITLEMENT_ENTITIES",
          payload: [],
        });
      });
  };
};

export const getApprovals = (
  offeringId,
  query = "",
  queryValue = "",
  sort = "",
  pageNo = 1,
  pageSize = 10
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "APPROVAL",
    });
    api
      .getApprovalsAPI(offeringId, pageNo, pageSize)
      .then((resp) => {
        if (resp.status == 200)
          dispatch({
            type: "APPROVAL",
            payload: resp.data,
          });
        else
          dispatch({
            type: "APPROVAL",
            payload: [],
          });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "APPROVAL",
        });
      })
      .catch((err) => {
        dispatch({
          type: "APPROVAL",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "APPROVAL",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const getApprovalDetails = (id) => {
  return (dispatch) => {
    api
      .getApprovalDetailsAPI(id)
      .then((resp) => {
        dispatch({
          type: "EDIT_LEFT_TAB_SERVICE",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "EDIT_LEFT_TAB_SERVICE",
          payload: [],
        });
      });
  };
};

export const getApproverType = () => {
  return (dispatch) => {
    api
      .getApprovalLOVAPI("approvertype", 0, 0)
      .then((resp) => {
        dispatch({
          type: "APPROVER_TYPE",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "APPROVER_TYPE",
          payload: [],
        });
      });
  };
};

export const getApproverModule = () => {
  return (dispatch) => {
    api
      .getApprovalLOVAPI("module", 0, 0)
      .then((resp) => {
        dispatch({
          type: "APPROVAL_MODULE",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "APPROVAL_MODULE",
          payload: [],
        });
      });
  };
};

export const getApproverGroup = (companyId) => {
  return (dispatch) => {
    api
      .getApprovalGroupAPI(companyId)
      .then((resp) => {
        dispatch({
          type: "APPROVER_GROUP",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "APPROVER_GROUP",
          payload: [],
        });
      });
  };
};

export const emptyApproverGroup = () => {
  return (dispatch) => {
    dispatch({
      type: "APPROVER_GROUP",
      payload: [],
    });
  };
};

export const getApproverIndividual = (companyId) => {
  return (dispatch) => {
    api
      .getApprovalLOVAPI("individual", companyId, 0)
      .then((resp) => {
        dispatch({
          type: "APPROVER_INDIVIDUAL",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "APPROVER_INDIVIDUAL",
          payload: [],
        });
      });
  };
};

export const emptyApproverIndividual = () => {
  return (dispatch) => {
    dispatch({
      type: "APPROVER_INDIVIDUAL",
      payload: [],
    });
  };
};

export const getApproverGroupOrUser = (
  offeringCompanyId,
  supportCompanyId,
  type,
  searchUser = ""
) => {
  return (dispatch) => {
    api
      .getApprovalGroupOrUserAPI(
        offeringCompanyId,
        supportCompanyId,
        type,
        searchUser
      )
      .then((resp) => {
        dispatch({
          type: "APPROVER_GROUP_OR_INDIVIDUAL",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "APPROVER_GROUP_OR_INDIVIDUAL",
          payload: [],
        });
      });
  };
};

export const emptyApproverGroupOrUser = () => {
  return (dispatch) => {
    dispatch({
      type: "APPROVER_GROUP_OR_INDIVIDUAL",
      payload: [],
    });
  };
};

export const getApproverLevel = (companyId, mod) => {
  return (dispatch) => {
    api
      .getApprovalLOVAPI("level", companyId, mod)
      .then((resp) => {
        dispatch({
          type: "APPROVER_LEVEL",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "APPROVER_LEVEL",
          payload: [],
        });
      });
  };
};

export const getSLAModule = () => {
  return (dispatch) => {
    api
      .getSLAModuleAPI()
      .then((resp) => {
        dispatch({
          type: "SLA_MODULE",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "SLA_MODULE",
          payload: [],
        });
      });
  };
};

export const getSLAGoalType = (mod) => {
  return (dispatch) => {
    api
      .getSLAGoalTypeAPI(mod)
      .then((resp) => {
        dispatch({
          type: "SLA_GOAL_TYPE",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "SLA_GOAL_TYPE",
          payload: [],
        });
      });
  };
};

export const getChainEntitiesCompanyList = (companyId, role) => {
  return (dispatch) => {
    api
      .getChainCompanyListAPI(companyId, role)
      .then((resp) => {
        dispatch({
          type: "CHAIN_ENTITIES_COMPANY",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "CHAIN_ENTITIES_COMPANY",
          payload: [],
        });
      });
  };
};
export const getAllCompanyList = (userId, role) => {
  return (dispatch) => {
    api
      .getCompanyListAPI(userId)
      .then((resp) => {
        dispatch({
          type: "ALL_COMPANY_LIST",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "ALL_COMPANY_LIST",
          payload: [],
        });
      });
  };
};

export const getSLAWorkDaySchedule = (scheduleId) => {
  return (dispatch) => {
    api
      .getSLAWorkDayScheduleAPI(scheduleId)
      .then((resp) => {
        dispatch({
          type: "SLA_WORKDAY_SCHEDULE",
          payload: resp.data.workdayScheduleList,
        });
      })
      .catch((err) => {
        dispatch({
          type: "SLA_WORKDAY_SCHEDULE",
          payload: [],
        });
      });
  };
};

export const setCookies = () => {
  let userinfo = {};
  userinfo.homepage_location = "/";
  userinfo.role_id = "11,36";
  userinfo.company_id = "3215";
  userinfo.user_name = "hul_consumer";
  userinfo.user_id = "3974";
  userinfo.group_id = "";
  userinfo.associated_company_id = "";
  userinfo.app_role_id = "1";
  userinfo.time_zone = "GMT+05:30";
  userinfo.currency = "INR";
  userinfo.date_format = "yyyy-MM-dd HH:mm:ss";
  userinfo.company_name = "Grenzen Inc.";
  const cookies = new Cookies();
  cookies.set("access_token", userinfo, {
    httpOnly: false, // seting it to true will make it XSRF Secure but it cant be read by javascript on client side
    maxAge: 900000,
    signed: true,
  });
  return (dispatch) => {
    dispatch({
      type: "COOKIES",
      payload: [],
    });
  };
};

export const selectedTaskTemplate = (selectedValue) => {
  return (dispatch) => {
    dispatch({
      type: "SELECTED_TASK_TEMPLATE",
      payload: selectedValue,
    });
  };
};

export const setFullfilmentEditServiceDetails = (values) => {
  return (dispatch) => {
    dispatch({
      type: "EDIT_FULLFILMENT_LEFT_TAB_SERVICE",
      payload: values,
    });
  };
};

export const spcmLoader = (postingFor, status) => {
  return (dispatch) => {
    switch (postingFor) {
      case "attribute":
        switch (status) {
          case "posting":
            dispatch({
              type: "SPCM_POSTING",
              spcmPostingAttribute: true,
              spcmPostingService: false,
            });
            break;
          case "success":
            dispatch({
              type: "SPCM_POSTING",
              spcmPostingAttribute: false,
              spcmPostingService: false,
            });
            break;
        }
        break;
      case "service":
        switch (status) {
          case "posting":
            dispatch({
              type: "SPCM_POSTING",
              spcmPostingAttribute: false,
              spcmPostingService: true,
            });
            break;
          case "success":
            dispatch({
              type: "SPCM_POSTING",
              spcmPostingAttribute: false,
              spcmPostingService: false,
            });
            break;
        }
    }
  };
};

export const getQuestionsCategory = (
  offeringId,
  query = "",
  queryValue = "",
  sort = "",
  order,
  pageNo = 1,
  pageSize = 10
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "SERVICE_ORDER_CHOICES",
    });
    api
      .getQuestionsCategoryAPI(
        offeringId,
        query,
        queryValue,
        sort,
        order,
        pageNo,
        pageSize
      )
      .then((resp) => {
        dispatch({
          type: "QUESTIONS_CATEGORY",
          payload: resp.data.data,
          meta: resp.data.meta,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "SERVICE_ORDER_CHOICES",
        });
      })
      .catch((err) => {
        dispatch({
          type: "QUESTIONS_CATEGORY",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "SERVICE_ORDER_CHOICES",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const setEditQuestionsCategory = (values) => {
  return (dispatch) => {
    dispatch({
      type: "EDIT_QUESTIONS_CATEGORY",
      payload: values,
    });
  };
};

export const updateHideFromConsumerPrice = (data) => {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_PRICE",
      payload: data,
    });
  };
};

export const updateHideFromConsumerSla = (data) => {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SLA",
      payload: data,
    });
  };
};

export const getQuestionsActiveCategory = (offeringId) => {
  return (dispatch) => {
    api.getQuestionsActiveCategoryAPI(offeringId).then((resp) => {
      dispatch({
        type: "QUEST_ACT_CAT_LIST",
        payload: resp.data,
      });
    });
  };
};

export const getComponentName = (componentId) => {
  return (dispatch) => {
    api.getComponentName(componentId).then((resp) => {
      dispatch({
        type: "GET_COMPONENT_NAME",
        payload: resp.data,
      });
    });
  };
};

export const getSlaStatusForFulfillment = (offeringId) => {
  return (dispatch) => {
    api.getSlaStatusForFulfillment(offeringId).then((resp) => {
      dispatch({
        type: "GET_REQUIRED_SLA_STATUS",
        payload: resp.data,
      });
    });
  };
};

export const getDataSourceTypes = (dataSourceType) => {
  return (dispatch) => {
    api.getDataSourceTypesAPI(dataSourceType).then((resp) => {
      dispatch({
        type: "DATA_SOURCE_TYPES",
        payload: resp.data,
      });
    });
  };
};

export const getReadOnlyResponseKeyTypesAndKeys = (dataSourceType) => {
  return (dispatch) => {
    api.getReadOnlyResponseKeyTypesAndKeys(dataSourceType).then((resp) => {
      dispatch({
        type: "DATA_SOURCE_RESPONSES",
        payload: resp.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: "DATA_SOURCE_RESPONSES",
        payload: [],
      });
    });
  };
};

export const getOrderingPreferences = (offeringId) => {
  return (dispatch) => {
    api.getOrderingPreferences(offeringId).then((resp) => {
      dispatch({
        type: "ORDERING_PREFERENCES",
        payload: resp.data,
      });
    });
  };
};

export const getDisclaimiers = (offeringId) => {
  return (dispatch) => {
    api.getDisclaimiers(offeringId).then((resp) => {
      dispatch({
        type: "SPCM_DISCLAIMERS",
        payload: resp.data,
      });
    });
  };
};

export const getApprovalDisclaimiers = (offeringId) => {
  return (dispatch) => {
    api.getApprovalDisclaimiers(offeringId).then((resp) => {
      dispatch({
        type: "SPCM_DISCLAIMERS",
        payload: resp.data,
      });
    });
  };
};

export const getRFCApprovalDisclaimiers = (ciId) => {
  return (dispatch) => {
    api.getRFCApprovalDisclaimiers(ciId).then((resp) => {
      dispatch({
        type: "CMDB_DISCLAIMERS",
        payload: resp.data.disclaimer,
      });
    });
  };
};

export const getIntegrationPreferences = (offeringId) => {
  return (dispatch) => {
    api.getIntegrationPreferences(offeringId).then((resp) => {
      dispatch({
        type: "INTEGRATION_PREFERENCES",
        payload: resp.data,
      });
    });
  };
};

export const getServiceTypes = () => {
  return (dispatch) => {
    api.getServiceTypes().then((resp) => {
      dispatch({
        type: "SERVICE_TYPES",
        payload: resp.data,
      });
    });
  };
};

export const getMenuData = (type) => {
  return (dispatch) => {
    api.getMenuData(type).then((resp) => {
      if ((type = "additionalIntegrationData")) {
        dispatch({
          type: "INTEGRATION_DATA_KEYWORDS",
          payload: resp.data,
        });
      }
    });
  };
};

export const getAdditionalIntegrationData = (
  offeringId,
  query = "",
  queryValue = "",
  sort = "",
  order,
  pageNo = 1,
  pageSize = 10
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "ATTRIBUTES",
    });
    api
      .getAdditionalIntegrationData(
        offeringId,
        query,
        queryValue,
        sort,
        order,
        pageNo,
        pageSize
      )
      .then((resp) => {
        dispatch({
          type: "ADDITIONAL_INTEGRATION_DATA",
          payload: resp.data.data,
          meta: resp.data.meta,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "ATTRIBUTES",
        });
      })
      .catch((err) => {
        dispatch({
          type: "ADDITIONAL_INTEGRATION_DATA",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "ATTRIBUTES",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const getApprovalQuestions = (
  offeringId,
  query = "",
  queryValue = "",
  sort = "",
  pageNo = 1,
  pageSize = 10
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "APPROVAL",
    });
    api
      .getApprovalQuestions(
        offeringId,
        query,
        queryValue,
        sort,
        pageNo,
        pageSize
      )
      .then((resp) => {
        if (resp.status == 200)
          dispatch({
            type: "APPROVAL_QUESTIONS",
            payload: resp.data,
          });
        else
          dispatch({
            type: "APPROVAL_QUESTIONS",
            payload: [],
          });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "APPROVAL",
        });
      })
      .catch((err) => {
        dispatch({
          type: "APPROVAL_QUESTIONS",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "APPROVAL",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const getApproverCriteria = () => {
  return (dispatch) => {
    api
      .getApprovalLOVAPI("approverCriteria", 0, 0)
      .then((resp) => {
        dispatch({
          type: "APPROVER_CRITERIA",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "APPROVER_CRITERIA",
          payload: [],
        });
      });
  };
};

export const getApproverScenario = () => {
  return (dispatch) => {
    api
      .getApprovalLOVAPI("approverScenario", 0, 0)
      .then((resp) => {
        dispatch({
          type: "APPROVER_SCENARIO",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "APPROVER_SCENARIO",
          payload: [],
        });
      });
  };
};

export const getApproverCriteriaOperator = () => {
  return (dispatch) => {
    api
      .getApprovalLOVAPI("approverOperator", 0, 0)
      .then((resp) => {
        dispatch({
          type: "APPROVER_CRITERIA_OPERATOR",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "APPROVER_CRITERIA_OPERATOR",
          payload: [],
        });
      });
  };
};

export const getApproverEntity = () => {
  return (dispatch) => {
    api
      .getApprovalLOVAPI("approverEntity", 0, 0)
      .then((resp) => {
        dispatch({
          type: "APPROVER_ENTITY",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "APPROVER_ENTITY",
          payload: [],
        });
      });
  };
};

export const getApproverLOB = () => {
  return (dispatch) => {
    api
      .getApprovalLOVAPI("approvalLOB", 0, 0)
      .then((resp) => {
        dispatch({
          type: "APPROVER_LOB",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "APPROVER_LOB",
          payload: [],
        });
      });
  };
};

export const getApproverOrderType = () => {
  return (dispatch) => {
    api
      .getApprovalLOVAPI("approvalOrderType", 0, 0)
      .then((resp) => {
        dispatch({
          type: "APPROVER_ORDER_TYPE",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "APPROVER_ORDER_TYPE",
          payload: [],
        });
      });
  };
};

export const getApproverCompanyCode = () => {
  return (dispatch) => {
    api
      .getApprovalLOVAPI("approvalCompanyCode", 0, 0)
      .then((resp) => {
        dispatch({
          type: "APPROVER_COMPANY_CODE",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "APPROVER_COMPANY_CODE",
          payload: [],
        });
      });
  };
};

export const getRelatedOfferings = (offeringId) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "RELATED_OFFERINGS",
    });
    api
      .getRelatedOfferingAPI(offeringId)
      .then((resp) => {
        dispatch({
          type: "RELATED_OFFERINGS",
          payload: resp.data,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "RELATED_OFFERINGS",
        });
      })
      .catch((err) => {
        dispatch({
          type: "RELATED_OFFERINGS",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "RELATED_OFFERINGS",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const getSharedOfferings = (offeringId, companyName) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "SHARED_OFFERINGS",
    });
    api
      .getSharedOfferingAPI(offeringId, companyName)
      .then((resp) => {
        dispatch({
          type: "SHARED_OFFERINGS",
          payload: resp.data,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "SHARED_OFFERINGS",
        });
      })
      .catch((err) => {
        dispatch({
          type: "SHARED_OFFERINGS",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "SHARED_OFFERINGS",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const getAllDeployedServicesList = (
  queryObj = "",
  pageNo = 1,
  pageSize = 10000
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: true,
      spcmFetchingAttribute: false,
      spcmFetchingError: false,
    });
    api
      .getDeployedServicesAPI(queryObj, pageNo, pageSize)
      .then((resp) => {
        dispatch({
          type: "ALL_DEPLOYED_SERVICES_LIST",
          payload: resp.data.data,
          meta: resp.data.meta,
        });

        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
        });
      })
      .catch((err) => {
        // console.log('err==',err);
        dispatch({
          type: "ALL_DEPLOYED_SERVICES_LIST",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmErrorMsg: err.response.data,
        });
      });
  };
};
export const getRelatedOfferingsNameList = (
  offeringId,
  relatedOfferingName
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "RELATED_OFFERINGS",
    });
    api
      .getRelatedOfferingsSearchAPI(offeringId, relatedOfferingName)
      .then((resp) => {
        dispatch({
          type: "RELATED_OFFERINGS",
          payload: resp.data,
          //  meta: resp.data.meta
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "RELATED_OFFERINGS",
        });
      })
      .catch((err) => {
        dispatch({
          type: "RELATED_OFFERINGS",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "RELATED_OFFERINGS",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const getCopyOfferingList = () => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: true,
      spcmFetchingAttribute: false,
      spcmFetchingError: false,
    });
    api
      .getCopyOfferingListAPI()
      .then((resp) => {
        dispatch({
          type: "COPY_OFFERING_FROM_SERVICE_LIST",
          payload: resp.data,
        });

        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: "COPY_OFFERING_FROM_SERVICE_LIST",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const getCopyOfferingListBasedOnCompany = (companyID) => {
  return (dispatch) => {
    api
      .getCopyOfferingListAPI_BASED_ON_COMPANY(companyID)
      .then((resp) => {
        dispatch({
          type: "COPY_OFFERING_FROM_SERVICE_LIST_COPY",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "COPY_OFFERING_FROM_SERVICE_LIST_COPY",
          payload: [],
        });
      });
  };
};
export const getServiceSequenceList = (orderBy, offeringName) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "SERVICE_SEQUENCING_LIST",
    });
    api
      .getServiceSequenceListAPI(orderBy, offeringName)
      .then((resp) => {
        dispatch({
          type: "SERVICE_SEQUENCING_LIST",
          payload: resp.data,
          //  meta: resp.data.meta
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "SERVICE_SEQUENCING_LIST",
        });
      })
      .catch((err) => {
        // console.log('err===========',err);
        // console.log('err.response===========',err.response);
        dispatch({
          type: "SERVICE_SEQUENCING_LIST",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "SERVICE_SEQUENCING_LIST",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};
export const getSearchServiceSequenceList = () => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "SERVICE_SEQUENCING_LIST_ON_ADD",
    });
    api
      .getSearchServiceSequenceListAPI()
      .then((resp) => {
        dispatch({
          type: "SERVICE_SEQUENCING_LIST_ON_ADD",
          payload: resp.data,
          //  meta: resp.data.meta
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "SERVICE_SEQUENCING_LIST_ON_ADD",
        });
      })
      .catch((err) => {
        dispatch({
          type: "SERVICE_SEQUENCING_LIST_ON_ADD",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "SERVICE_SEQUENCING_LIST_ON_ADD",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const resetReducer = () => {
  return (dispatch) => {
    dispatch({
      type: "RESET_REDUCER",
      payload: [],
    });
  };
};

export const getAttributesTranslationStatus = (offeringId, languageId) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "ATTRIBUTES",
    });
    api
      .getAttributesTranslationStatus(offeringId, languageId)
      .then((resp) => {
        dispatch({
          type: "ATTRIBUTES_TRANSLATION_STATUS",
          payload: resp.data,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "ATTRIBUTES",
        });
      })
      .catch((err) => {
        dispatch({
          type: "ATTRIBUTES_TRANSLATION_STATUS",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "ATTRIBUTES",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const getTranslationForOfferingNameDescriptionSummary = (
  offeringId,
  languageId
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "ATTRIBUTES",
    });
    api
      .getTranslationForOfferingNameDescriptionSummary(offeringId, languageId)
      .then((resp) => {
        dispatch({
          type: "TRANS_NAME_DESC_SUMMARY",
          payload: resp.data,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "ATTRIBUTES",
        });
      })
      .catch((err) => {
        dispatch({
          type: "TRANS_NAME_DESC_SUMMARY",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "ATTRIBUTES",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const getLanguageTranslationForCategoryAndSubcategory = (
  offeringId,
  languageId
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "ATTRIBUTES",
    });
    api
      .getLanguageTranslationForCategoryAndSubcategory(offeringId, languageId)
      .then((resp) => {
        dispatch({
          type: "TRANS_CAT_SUBCAT",
          payload: resp.data,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "ATTRIBUTES",
        });
      })
      .catch((err) => {
        dispatch({
          type: "TRANS_CAT_SUBCAT",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "ATTRIBUTES",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const languageListTranslationStatus = (offeringId, base_language) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "ATTRIBUTES",
    });
    api
      .languageListTranslationStatus(offeringId, base_language)
      .then((resp) => {
        dispatch({
          type: "LANGUAGE_LIST_TRANSLATION_STATUS",
          payload: resp.data,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "ATTRIBUTES",
        });
      })
      .catch((err) => {
        dispatch({
          type: "LANGUAGE_LIST_TRANSLATION_STATUS",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "ATTRIBUTES",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export function translate(baseText, languageId) {
  return (dispatch) => {
    api
      .translationPI(baseText, languageId)
      .then((resp) => {
        dispatch({
          type: "NAME_DESC_SUM_TRANSLATION",
          payload: resp.data[0],
        });
      })
      .catch((err) => {
        dispatch({
          type: "NAME_DESC_SUM_TRANSLATION",
          payload: [],
        });
      });
  };
}

export function translateAttrName(baseText, languageId) {
  return (dispatch) => {
    api
      .translationPI(baseText, languageId)
      .then((resp) => {
        dispatch({
          type: "TRANS_ATTR_NAME",
          payload: resp.data[0],
        });
      })
      .catch((err) => {
        dispatch({
          type: "TRANS_ATTR_NAME",
          payload: [],
        });
      });
  };
}

export function translateComponenDesc(baseText, languageId) {
  return (dispatch) => {
    api
      .translationPI(baseText, languageId)
      .then((resp) => {
        dispatch({
          type: "TRANS_COMP_DESC",
          payload: resp.data[0],
        });
      })
      .catch((err) => {
        dispatch({
          type: "TRANS_COMP_DESC",
          payload: [],
        });
      });
  };
}

export function translateAttrValue(baseText, languageId) {
  return (dispatch) => {
    api
      .translationPI(baseText, languageId)
      .then((resp) => {
        dispatch({
          type: "TRANS_ATTR_VALUE",
          payload: resp.data[0],
        });
      })
      .catch((err) => {
        dispatch({
          type: "TRANS_ATTR_VALUE",
          payload: [],
        });
      });
  };
}

export function translateCategory(baseText, languageId) {
  return (dispatch) => {
    api
      .translationPI(baseText, languageId)
      .then((resp) => {
        dispatch({
          type: "TRANS_CATEGORY",
          payload: resp.data[0],
        });
      })
      .catch((err) => {
        dispatch({
          type: "TRANS_CATEGORY",
          payload: [],
        });
      });
  };
}

export function translateSubcategory(baseText, languageId) {
  return (dispatch) => {
    api
      .translationPI(baseText, languageId)
      .then((resp) => {
        dispatch({
          type: "TRANS_SUBCATEGORY",
          payload: resp.data[0],
        });
      })
      .catch((err) => {
        dispatch({
          type: "TRANS_SUBCATEGORY",
          payload: [],
        });
      });
  };
}

export function translateQuestCat(baseText, languageId) {
  return (dispatch) => {
    api
      .translationPI(baseText, languageId)
      .then((resp) => {
        dispatch({
          type: "TRANS_QUES_CAT",
          payload: resp.data[0],
        });
      })
      .catch((err) => {
        dispatch({
          type: "TRANS_QUES_CAT",
          payload: [],
        });
      });
  };
}

export function translateResponseValues(baseText, languageId) {
  return (dispatch) => {
    api
      .translationPI(baseText, languageId)
      .then((resp) => {
        dispatch({
          type: "TRANS_RESPONSE_VALUES",
          payload: resp.data[0],
        });
      })
      .catch((err) => {
        dispatch({
          type: "TRANS_RESPONSE_VALUES",
          payload: [],
        });
      });
  };
}

export function translateQuestText(baseText, languageId) {
  return (dispatch) => {
    api
      .translationPI(baseText, languageId)
      .then((resp) => {
        dispatch({
          type: "TRANS_QUES_TEXT",
          payload: resp.data[0],
        });
      })
      .catch((err) => {
        dispatch({
          type: "TRANS_QUES_TEXT",
          payload: [],
        });
      });
  };
}
export function translateTableHeaders(baseText, languageId) {
  return (dispatch) => {
    api
      .translationPI(baseText, languageId)
      .then((resp) => {
        dispatch({
          type: "TRANS_TABLE_HEADERS",
          payload: resp.data[0],
        });
      })
      .catch((err) => {
        dispatch({
          type: "TRANS_TABLE_HEADERS",
          payload: [],
        });
      });
  };
}

export function translateQuestPlaceholder(baseText, languageId) {
  return (dispatch) => {
    api
      .translationPI(baseText, languageId)
      .then((resp) => {
        dispatch({
          type: "TRANS_QUES_PLACEHOLDER",
          payload: resp.data[0],
        });
      })
      .catch((err) => {
        dispatch({
          type: "TRANS_QUES_PLACEHOLDER",
          payload: [],
        });
      });
  };
}

export function translateQuestHelpText(baseText, languageId) {
  return (dispatch) => {
    api
      .translationPI(baseText, languageId)
      .then((resp) => {
        dispatch({
          type: "TRANS_QUES_HELPTEXT",
          payload: resp.data[0],
        });
      })
      .catch((err) => {
        dispatch({
          type: "TRANS_QUES_HELPTEXT",
          payload: [],
        });
      });
  };
}
export function translateDisplayText(baseText, languageId) {
  return (dispatch) => {
    api
      .translationPI(baseText, languageId)
      .then((resp) => {
        dispatch({
          type: "TRANS_DISPLAY_TEXT",
          payload: resp.data[0],
        });
      })
      .catch((err) => {
        dispatch({
          type: "TRANS_DISPLAY_TEXT",
          payload: [],
        });
      });
  };
}

export const getTranslationForOfferingBasedOnType = (
  offeringId,
  languageId,
  type
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "ATTRIBUTES",
    });
    api
      .getTranslationForOfferingBasedOnType(offeringId, languageId, type)
      .then((resp) => {
        dispatch({
          type: "GET_LIST_FOR_TRANSLATION",
          payload: resp.data,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "ATTRIBUTES",
        });
      })
      .catch((err) => {
        dispatch({
          type: "GET_LIST_FOR_TRANSLATION",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "ATTRIBUTES",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export function setTranslationObject(values) {
  return (dispatch) => {
    dispatch({
      type: "OBJECT_FOR_TRANSLATION",
      payload: values,
    });
  };
}

export const getReminderNotificationQuestions = (offeringId) => {
  return (dispatch) => {
    api.getReminderNotificationQuestions(offeringId).then((resp) => {
      dispatch({
        type: "SPCM_REMINDER_NOTIFICATION_QUESTIONS",
        payload: resp.data,
      });
    });
  };
};

export const getReminderNotifications = (offeringId) => {
  return (dispatch) => {
    api.getReminderNotifications(offeringId).then((resp) => {
      dispatch({
        type: "SPCM_REMINDER_NOTIFICATIONS",
        payload: resp.data,
      });
    });
  };
};

export const postReminderNotifications = (data, callback) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_POSTING",
      spcmPostingAttribute: true,
      spcmPostingService: false,
    });
    api.postReminderNotifications(data).then((resp) => {
      let arr = [];
      arr.push(resp.data);
      dispatch({
        type: "SPCM_REMINDER_NOTIFICATIONS",
        payload: arr,
      });
      dispatch({
        type: "SPCM_POSTING",
        spcmPostingAttribute: false,
        spcmPostingService: false,
      });
      callback();
    });
  };
};

export const patchReminderNotifications = (offeringId, data, callback) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_POSTING",
      spcmPostingAttribute: true,
      spcmPostingService: false,
    });
    api.patchReminderNotifications(offeringId, data).then((resp) => {
      dispatch({
        type: "SPCM_REMINDER_NOTIFICATIONS",
        payload: resp.data,
      });
      dispatch({
        type: "SPCM_POSTING",
        spcmPostingAttribute: false,
        spcmPostingService: false,
      });
      callback();
    });
  };
};
export const listDataWithType = (type, companyID) => {
  if (type == "Organization") {
    return (dispatch) => {
      api
        .getListDataAPI(type, companyID, 0)
        .then((resp) => {
          dispatch({
            type: "APPROVER_LIST_DATA_WITH_ORGANIZATION",
            payload: resp.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: "APPROVER_LIST_DATA_WITH_ORGANIZATION",
            payload: [],
          });
        });
    };
  } else if (type === "Department") {
    return (dispatch) => {
      api
        .getListDataAPI(type, companyID, 0)
        .then((resp) => {
          dispatch({
            type: "APPROVER_LIST_DATA_WITH_DEPARTMENT",
            payload: resp.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: "APPROVER_LIST_DATA_WITH_DEPARTMENT",
            payload: [],
          });
        });
    };
  } else if (type === "User Type") {
    return (dispatch) => {
      api
        .getListDataAPI(type, companyID, 0)
        .then((resp) => {
          dispatch({
            type: "APPROVER_LIST_DATA_WITH_USER_TYPE",
            payload: resp.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: "APPROVER_LIST_DATA_WITH_USER_TYPE",
            payload: [],
          });
        });
    };
  } else if (type === "Cost Center") {
    return (dispatch) => {
      api
        .getListDataAPI(type, companyID, 0)
        .then((resp) => {
          dispatch({
            type: "APPROVER_LIST_DATA_WITH_COST_CENTRE",
            payload: resp.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: "APPROVER_LIST_DATA_WITH_COST_CENTRE",
            payload: [],
          });
        });
    };
  } else if (type === "Location") {
    return (dispatch) => {
      api
        .getListDataAPI(type, companyID, 0)
        .then((resp) => {
          dispatch({
            type: "APPROVER_LIST_DATA_WITH_LOCATION",
            payload: resp.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: "APPROVER_LIST_DATA_WITH_LOCATION",
            payload: [],
          });
        });
    };
  }
};

export const getServiceAuditLogs = (offeringId, orderBy, type) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "ATTRIBUTES",
    });
    api
      .getServiceAuditLogs(offeringId, orderBy, type)
      .then((resp) => {
        dispatch({
          type: "SERVICE_AUDIT_LOGS",
          payload: resp.data,
          meta: resp.data.meta,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "ATTRIBUTES",
        });
      })
      .catch((err) => {
        dispatch({
          type: "SERVICE_AUDIT_LOGS",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "ATTRIBUTES",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const getServiceAuditLogsForFulfillment = (
  offeringId,
  orderBy,
  type
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "ATTRIBUTES",
    });
    api
      .getServiceAuditLogsForFulfillment(offeringId, orderBy)
      .then((resp) => {
        dispatch({
          type: "SERVICE_AUDIT_LOGS",
          payload: resp.data,
          meta: resp.data.meta,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "ATTRIBUTES",
        });
      })
      .catch((err) => {
        dispatch({
          type: "SERVICE_AUDIT_LOGS",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "ATTRIBUTES",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const getServiceAuditLogsForApprovals = (offeringId, orderBy, type) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "ATTRIBUTES",
    });
    api
      .getServiceAuditLogsForApprovals(offeringId, orderBy)
      .then((resp) => {
        dispatch({
          type: "SERVICE_AUDIT_LOGS",
          payload: resp.data,
          meta: resp.data.meta,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "ATTRIBUTES",
        });
      })
      .catch((err) => {
        dispatch({
          type: "SERVICE_AUDIT_LOGS",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "ATTRIBUTES",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};
//=========config items==================

export const getAllCompanyServicesList = (company_id) => {
  return (dispatch) => {
    api
      .getAllCompanyServices(company_id)
      .then((resp) => {
        dispatch({
          type: "SERVICE_LIST_COMPANY",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "SERVICE_LIST_COMPANY",
          payload: [],
        });
      });
  };
};

export const getAllCompanyServicesListForSearch = (
  companyId,
  queryObj = ""
) => {
  return (dispatch) => {
    dispatch({ type: "LOADER_IN_PROGRESS" });
    api
      .getAllCompanyServicesListForSearch(companyId, queryObj)
      .then((resp) => {
        dispatch({
          type: "SERVICE_LIST_COMPANY_FOR_SEARCH",
          payload: resp.data,
        });
        dispatch({ type: "LOADER_DETAILS_FETCHED_SUCCESSFULLY" });
      })
      .catch((err) => {
        dispatch({
          type: "SERVICE_LIST_COMPANY_FOR_SEARCH",
          payload: [],
        });
      });
  };
};

export const getCIBaseData = () => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "CIBASE_DATA_FOR_STANDARD_CHANGE",
    });
    api
      .getCIBaseDataAPI()
      .then((resp) => {
        dispatch({
          type: "CIBASE_DATA_FOR_STANDARD_CHANGE",
          payload: resp.data,
          //  meta: resp.data.meta
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "CIBASE_DATA_FOR_STANDARD_CHANGE",
        });
      })
      .catch((err) => {
        dispatch({
          type: "CIBASE_DATA_FOR_STANDARD_CHANGE",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "CIBASE_DATA_FOR_STANDARD_CHANGE",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const getServiceDetailsDataList = (
  searchParams = {},
  status = [],
  category = [],
  featured = [],
  workItemType = [],
  modifiedDate = "",
  order = "desc",
  sortOnField = "modifiedDate",
  page = "1",
  limit = "10"
) => {
  return (dispatch) => {
    let spcmListFilterData = {
      searchParams: searchParams,
      status: status,
      category: category,
      featured: featured,
      workItemType: workItemType,
      modifiedDate: modifiedDate,
      sort: order === "asc" ? false : true,
      sortOrder: order,
      sortOnColumn: sortOnField,
      page: page,
      size: limit,
    };
    sessionStorage.setItem("filterValues", JSON.stringify(spcmListFilterData));

    dispatch({ type: "FETCHING_DETAILS_IN_PROGRESS" });
    api
      .getServiceDetailsDataListApi(
        searchParams,
        status,
        category,
        featured,
        workItemType,
        modifiedDate,
        order,
        sortOnField,
        page,
        limit
      )
      .then((resp) => {
        dispatch({
          type: "SERVICELIST_DATA",
          payload: resp.data.data,
          meta: resp.data.meta,
        });
        dispatch({ type: "DETAILS_FETCHED_SUCCESSFULLY" });
      })
      .catch((err) => {
        console.log("servicelistData error", err);
        dispatch({
          type: "SERVICELIST_DATA",
          payload: [],
        });
        dispatch({ type: "DETAILS_FETCHED_SUCCESSFULLY" });
      });
  };
};
export const resetServiceDetailsDataList = () => {
  return (dispatch) => {
    sessionStorage.removeItem('filterValues');
    dispatch({
      type: "SERVICELIST_DATA",
      payload: [],
      meta: [],
    });
  };
};
// export const getServiceDetailsDataList = (
//   queryObj = "",
//   queryBy = "Company",
//   queryValue = "",
//   sortBy ="Category",
//   order = "asc",
//   page = 1,
//   limit = 10
//   ) => {
//   return (dispatch) => {
//     dispatch({ type: "FETCHING_DETAILS_IN_PROGRESS" });
//     api
//       .getServiceDetailsDataListApi(
//         queryObj,
//         queryBy,
//         queryValue,
//         sortBy,
//         order,
//         page,
//         limit)
//       .then((resp) => {
//         console.log("RESP",resp);
//         dispatch({
//           type: "SERVICELIST_DATA",
//           payload: resp.data.data,
//           meta: resp.data.meta,
//         });
//       dispatch({ type: "DETAILS_FETCHED_SUCCESSFULLY" });
//       })
//       .catch((err) => {
//         console.log("servicelistData error", err);
//         dispatch({
//           type: "SERVICELIST_DATA",
//           payload: [],
//         });
//       });
//   };
// };
export const getServiceCIs = (companyId, serviceCi) => {
  return (dispatch) => {
    api
      .getServiceCIsApi(companyId, serviceCi)
      .then((resp) => {
        dispatch({
          type: "SERVICE_CI",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "SERVICE_CI",
          payload: [],
        });
      });
  };
};
export const getContextualRules = (companyId, moduleId) => {
  return (dispatch) => {
    api
      .getContextualRulesApi(companyId, moduleId)
      .then((resp) => {
        if (moduleId === 20) {
          let contextualAMSRules = resp.data.map((item) => {
            return {
              label: item.ruleName,
              value: item.ruleId,
            };
          });
          dispatch({
            type: "GET_TASK_CONTEXTUAL_RULES",
            payload: contextualAMSRules,
          });
        } else {
          let contextualAMSRules = resp.data.map((item) => {
            return {
              label: item.ruleName,
              value: item.ruleId,
            };
          });
          dispatch({
            type: "GET_CONTEXTUAL_RULES",
            payload: contextualAMSRules,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "GET_CONTEXTUAL_RULES",
          payload: [],
        });
      });
  };
};

export const getOfferingsBasedOnUser = (companyId, user) => {
  return (dispatch) => {
    api
      .getOfferingsBasedOnUserApi(companyId, user)
      .then((resp) => {
        dispatch({
          type: "ENTITLED_OFFERINGS_FETCHED_SUCCESSFULLY",
          payload: resp.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "ENTITLED_OFFERINGS_FETCHED_SUCCESSFULLY",
          payload: [],
        });
      });
  };
};

export const resetEntitledOfferingsList = () => {
  return (dispatch) => {
    dispatch({
      type: "ENTITLED_OFFERINGS_FETCHED_SUCCESSFULLY",
      payload: [],
    });
  };
};

export const getCompanyUsersList = (companyId, key) => {
  return (dispatch) => {
    dispatch(resetEntitledOfferingsList());
    api
      .getCompanyUsersListApi(companyId, key)
      .then((resp) => {
        dispatch({
          type: "COMPANY_USERS_LIST_FETCHED_SUCCESSFULLY",
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "COMPANY_USERS_LIST_FETCHED_SUCCESSFULLY",
          payload: [],
        });
      });
  };
};

export const resetCompanyUsersList = () => {
  return (dispatch) => {
    dispatch({
      type: "COMPANY_USERS_LIST_FETCHED_SUCCESSFULLY",
      payload: [],
    });
  };
};

export const getAttachmentsAction = (offeringId) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "ATTACHMENTS_SB",
    });
    api
      .getAttachmentsApi(offeringId)
      .then((resp) => {
        dispatch({
          type: "ATTACHMENTS_SB",
          payload: resp.data,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "ATTACHMENTS_SB",
        });
      })
      .catch((err) => {
        dispatch({
          type: "ATTACHMENTS_SB",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "ATTACHMENTS_SB",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export const getAttachmentsPaginationAction = (
  offeringId,
  order,
  page,
  limit
) => {
  return (dispatch) => {
    dispatch({
      type: "SPCM_FETCHING",
      spcmFetchingService: false,
      spcmFetchingAttribute: true,
      spcmFetchingError: false,
      spcmFetchingModule: "ATTACHMENTS_PAGINATION",
    });
    api
      .getAttachmentsPaginationAPI(offeringId, order, page, limit)
      .then((resp) => {
        dispatch({
          type: "ATTACHMENTS_PAGINATION",
          payload: resp.data.data,
          meta: resp.data.meta,
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: false,
          spcmFetchingModule: "ATTACHMENTS_PAGINATION",
        });
      })
      .catch((err) => {
        dispatch({
          type: "ATTACHMENTS_PAGINATION",
          payload: [],
        });
        dispatch({
          type: "SPCM_FETCHING",
          spcmFetchingService: false,
          spcmFetchingAttribute: false,
          spcmFetchingError: true,
          spcmFetchingModule: "ATTACHMENTS_PAGINATION",
          spcmErrorMsg: err.response.data,
        });
      });
  };
};

export function businessFunctionByCompanyId(companyId) {
  return (dispatch) => {
    api.getBusinessFunctionByCompanyId(companyId).then((response) => {
      let dropDownList;
        if(response.status === 204) {
          dropDownList = [{businessFunctionName: 'Default', id: 0}];
        } else {
          dropDownList = response.data;
        }
        dispatch({ type: 'PARENTFUNCTION_BY_COMPANY_ID', categoryByCompanyIdDropdownList: dropDownList })
      })
      .catch((err) => {
          dispatch({ type: 'PARENTFUNCTION_BY_COMPANY_ID', categoryByCompanyIdDropdownList: [] })
          console.log("error in categories by company id")
          console.log(err);
      });
  };
}

export function resetBusinessFunctionList() {
  return (dispatch) => {
    dispatch({ type: 'PARENTFUNCTION_BY_COMPANY_ID', categoryByCompanyIdDropdownList: [] })
  };
}

export function serviceOwnerGroupAction(companyId,groupType,associatedCompanies){

  return (dispatch) => {
    if(companyId == ''){
      dispatch({type:'SERVICE_OWNER_GROUP',payload : []});

    }else{
      api.getServiceOwnerGroupApi(companyId,groupType,associatedCompanies).then((response) => {
        let ownerGroupArr = [];
        const ownerGroup = response.data;
        if (typeof ownerGroup !== "undefined" && ownerGroup.length > 0) {
          ownerGroup.forEach((values) => {
            let val = { id: values.id, label: values.name };
            ownerGroupArr.push(val);
          });
        }
        dispatch({type:'SERVICE_OWNER_GROUP',payload : ownerGroupArr});
      })
      .catch((error) => {
  
      })
    }  
  }

}

export const myViewNotificationAction = ( 
  emailId,
  type,
  modifiedDate,
  searchParams,
  pageNo = 1,
  pageSize = 10,
  sort,
  dateSort) =>{
 return (dispatch) => {
  dispatch({type:'FDN_DATA_FETCHING'})
  api.getMyViewNotificationAPI(emailId,type,modifiedDate,searchParams,pageNo,pageSize,sort,dateSort).then((resp)=>{
    dispatch({
    type:'MY_VIEW_NOTIFICATION',
    payload: resp.data.data,
    meta: resp.data.data})
    dispatch({type:'FDN_DATA_FETCHED_SUCCESS'})

  })
  .catch((err)=>{
    dispatch({type:'FDN_DATA_FETCHED_SUCCESS'})
  })
 }
}

export const getTOAList = (searchByList="",multipleValueList="",dataType,status=["active"],currentPage,size,pattern="") => {
  return(dispatch) => {
  dispatch({type:'FDN_DATA_FETCHING'})
    api.getTOAListapi(searchByList,multipleValueList,dataType,status,currentPage,size,pattern).then((resp)=>{
      dispatch({
        type:"TOA",
        payload:resp.data.body,
        meta:resp.data.body
      })
      dispatch({type:'FDN_DATA_FETCHED_SUCCESS'})

    }).catch((err)=>{
    dispatch({type:'FDN_DATA_FETCHED_SUCCESS'})

    })
  }
}
export const getTOTList = (searchByList="",multipleValueList="",usedByrules=["YES"],currentPage,size,pattern="") => {
  return(dispatch) => {
  dispatch({type:'FDN_DATA_FETCHING'})
    api.getTOTListapi(searchByList,multipleValueList,usedByrules,currentPage,size,pattern).then((resp)=>{
      dispatch({
        type:"TOT",
        payload:resp.data.body,
        meta:resp.data.body
      })
      dispatch({type:'FDN_DATA_FETCHED_SUCCESS'})

    }).catch((err)=>{
    dispatch({type:'FDN_DATA_FETCHED_SUCCESS'})

    })
  }
}

export const getTOAEditFormDetails = (UId) => {
return(dispatch) => {
  dispatch({type:'FDN_DATA_FETCHING'})
  api.getTOAEditFormDetailsApi(UId).then((resp)=>{
    dispatch({
      type:"TOA_EDIT",
      payload:resp.data
    })
      dispatch({type:'FDN_DATA_FETCHED_SUCCESS'})
    }).catch((err)=>{
    dispatch({type:'FDN_DATA_FETCHED_SUCCESS'})
  })
}
}
export const getTOTEditFormDetails = (UId) => {
return(dispatch) => {
  dispatch({type:'FDN_DATA_FETCHING'})
  api.getTOTEditFormDetailsApi(UId).then((resp)=>{
    dispatch({
      type:"TOT_EDIT",
      payload:resp.data
    })
      dispatch({type:'FDN_DATA_FETCHED_SUCCESS'})
    }).catch((err)=>{
    dispatch({type:'FDN_DATA_FETCHED_SUCCESS'})
  })
}
}

export function knowledgeArticlesSuccess_attached_KA() {
  return (dispatch) => {
    dispatch({
      type: "DETAILS_FETCHED_SUCCESSFULLY_ATTACHED_KNOWLEDGE_ARTICLES",
    });
  };
}

export function knowledgeArticlesLoading_attached_KA() {
  return (dispatch) => {
    dispatch({
      type: "FETCHING_DETAILS_IN_PROGRESS_ATTACHED_KNOWLEDGE_ARTICLES",
    });
  };
}

export function loadAttachedKnowledgeArticles(articleResultsResponse) {
  return {
    type: "LOAD_ATTACHED_KNOWLEDGE_ARTICLES_SUCCESS",
    articleResultsResponse,
  };
}

export function getKnowledgeArticles(offering_id) {
  let config = {
    headers: {
      query: JSON.stringify({ offering_id: offering_id }),
    },
  };
  const articleResultsUrl = GLOBAL.getKnowledgeArticlesUrl;
  return (dispatch) => {
    dispatch({
      type: "FETCHING_DETAILS_IN_PROGRESS_ATTACHED_KNOWLEDGE_ARTICLES",
    });
    axios
      .get(`${articleResultsUrl}`, config)
      .then((resultResponse) => {
        if (!resultResponse) {
          throw Error(resultResponse.statusText);
        }
        return resultResponse;
      })
      .then((resultResponse) => {
        dispatch({
          type: "DETAILS_FETCHED_SUCCESSFULLY_ATTACHED_KNOWLEDGE_ARTICLES",
        });
        dispatch(loadAttachedKnowledgeArticles(resultResponse));
      })
      .catch((err) => {
        dispatch({
          type: "DETAILS_FETCHED_SUCCESSFULLY_ATTACHED_KNOWLEDGE_ARTICLES",
        });
      });
  };
}

export function postKnowledgeArticles(linkedItems) {
  const articleResultsUrl = GLOBAL.postKnowledgeArticlesUrl;
  return (dispatch) => {
    return axios
      .post(articleResultsUrl, linkedItems)
      .then((resultResponse) => {
        if (!resultResponse) {
          throw Error(resultResponse.statusText);
        }
        return resultResponse;
      })
      .then((resultResponse) => {
        return resultResponse;
      })
      .catch((err) => {
        return err;
      });
  };
}

export function deleteKnowledgeArticle(id) {
  let config = {
    headers: {
      query: JSON.stringify({ id: id }),
    },
  };
  const articleResultsUrl = GLOBAL.deleteKnowledgeArticlesUrl;
  return (dispatch) => {
    return axios
      .delete(`${articleResultsUrl}`, config)
      .then((resultResponse) => {
        if (!resultResponse) {
          throw Error(resultResponse.statusText);
        }
        return resultResponse;
      })
      .then((resultResponse) => {
        return resultResponse;
      })
      .catch((err) => {
        return err;
      });
  };
}

export const getToaAuditData = (companyId, UId, orderBy = "desc") => {
  return (dispatch) => {
    dispatch({ type: "TOA_AUDIT_DATA_FETCHING" });
    let str1 = {};
      str1.companyId = companyId;
      str1.auditId = UId;
      str1.orderBy = orderBy;
      str1 = JSON.stringify(str1);
      return axios
      .get("/data/getAuditLog", { headers: { query: str1 } })
      .then((response) => {
        dispatch({ type: "TOA_AUDIT_DATA_FETCHED", payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: "TOA_AUDIT_DATA_FETCHED_FAILED", payload: [] });
        console.log(err);
      });
  };
};

export const getRulesAuditData = (companyId, ruleId, orderBy = "desc") => {
  return (dispatch) => {
    dispatch({ type: "RULE_AUDIT_DATA_FETCHING" });
    let str1 = {};
      str1.companyId = companyId;
      str1.auditId = ruleId;
      str1.orderBy = orderBy;
      str1 = JSON.stringify(str1);
      return axios
      .get("/data/getAuditLog", { headers: { query: str1 } })
      .then((response) => {
        dispatch({ type: "RULE_AUDIT_DATA_FETCHED", payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: "RULE_AUDIT_DATA_FETCHED_FAILED", payload: [] });
        console.log(err);
      });
  }
}

export const refreshDataDictionary = () => {
  return (dispatch) => {
    axios
      .patch("/aws/invokeUpdateLambdaFunction")
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: "REFRESH_DATA_DICTIONARY",
          });
          Swal.fire({
            text: response.data.message,
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        console.error("Error refreshing data dictionary:", error);
      });
  };
};
export const ruleDbDetails = () => {
  return (dispatch) => {
    dispatch({ type: "RULE_DB_DETAILS_FETCHING" });
    axios
      .get("/rule/api/ruleDbDetails")
      .then((response) => {
        if (response.status === 200) { 
        dispatch({ type: "RULE_DB_DETAILS_FETCHED", payload: response.data });
        }
      })
      .catch((error) => {
        dispatch({ type: "RULE_DB_DETAILS_FETCHED_FAILED", payload: [] });
        console.error("Error refreshing data dictionary:", error);
      });
  };
};

export const getCustomDataSources = () => {
  return (dispatch) => {
    dispatch({ type: "CUSTOM_DATA_SOURCES_FETCHING" });
    axios
      .get("/api/getDataSourcesForOffering")
      .then((response) => {
        dispatch({
          type: "CUSTOM_DATA_SOURCES_FETCHED",
          payload: response.data
        });
      })
      .catch((error) => {
        dispatch({ type: "CUSTOM_DATA_SOURCES_FETCHED_FAILED", payload: [] });
        console.error("Error refreshing data dictionary:", error);
      });
  };
};
export const getApprovalRules = (companyId, module, size = 10, offeringid, searchVal, currentPage = 0) => {
  module = module == "serviceRequest" ? "fulfillment" : "changemanagement";
  return (dispatch) => {
    dispatch({ type: "RULE_APPROVAL_DATA_FETCHING" });
    let str1 = {};
    if(!searchVal) {
      str1.searchByList = "status,consumerCompanyId,ruleType,module";
      str1.multipleValueList = `active,inactive|${companyId}|approval|${module}`;
      str1.patternList = "in,in,in,in";
      str1.currentPage = currentPage;
      str1.size = parseInt(size, 10);
      str1.orderBy = "desc";
      str1.sortBy = "modifiedOn";
      str1 = JSON.stringify(str1);
    } else {
      str1.searchByList = "status,consumerCompanyId,ruleType,module,ruleName";
      str1.multipleValueList = `active,inactive|${companyId}|approval|${module}|${searchVal}`;
      str1.patternList = "in,in,in,in,like";
      str1.currentPage = currentPage;
      str1.size = parseInt(size, 10);
      str1.orderBy = "desc";
      str1.sortBy = "modifiedOn";
      str1 = JSON.stringify(str1);
    }
      return axios
      .get(GLOBAL.getApprovalRules, { headers: { query: str1, offeringid: offeringid }})
      .then((response) => {
        dispatch({ type: "RULE_APPROVAL_DATA_FETCHED", payload: response.data.body });
      })
      .catch((err) => {
        dispatch({ type: "RULE_APPROVAL_DATA_FETCHED_FAILED", payload: [] });
        console.log(err);
      });
  }
}