/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import ReactDOM from "react-dom";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { IoClose } from "react-icons/io5";
import { arraysEqual } from '../../../common/helper';

const MultiSelectDropdownComponent = ({handleOnChange,value,fieldData,drpoDownData}) => {
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [selectAllTitle, setSelectAllTitle] = useState('Select All');
    const [showDropdownMenu, setShowDropdownMenu] = useState(false);
    const multiSelectRef = useRef(null);
    const translator = useSelector(state => state.spcmReducer.tr);
    const [fieldName, setFieldName] = useState(''); 

    const onSelectAllEvent = (module) => {
        module.preventDefault();
        if(module.target.title === 'Select All'){
            setSelectAllTitle('Unselect All');
            setSelectedValues(drpoDownData);
            setSelectedIDs(drpoDownData.map(item => item.value));
        }else{
            setSelectAllTitle('Select All');
            setSelectedValues([]);
            setSelectedIDs([]);
        }
    }

    const handleChange = (selected, event) => {
        if(selected.length === drpoDownData.length){
            setSelectAllTitle('Unselect All');
        }
        setSelectedValues(selected);
        setSelectedIDs(selected.map(item => item.value));
    }

    const resetSelectedValues = () => {
        setShowDropdownMenu(false);
        const filteredObjectList = value.map(itemVal => drpoDownData.find(item => item.value === itemVal));
        const interArray = filteredObjectList.filter(item => item !== undefined);
        setSelectedValues(interArray);
        setSelectedIDs(interArray.map(item => item.value));
        setSelectAllTitle(interArray.length === drpoDownData.length ? 'Unselect All' : 'Select All');
    }

    const finalValueSelection = () => {
        const extractedSelectedValues = selectedValues.map(item => item.value );
        handleOnChange(extractedSelectedValues);
        setShowDropdownMenu(false);
    }

    const openMenu = () => { setShowDropdownMenu(true); };

    useEffect(() => {
        if(value != '' && value != null && value != undefined && value.length > 0 && !arraysEqual(value, selectedIDs)){
            const filteredObjectList = value.map(arrayElement => drpoDownData.find(item => item.value == arrayElement || item.label == arrayElement) );
            const interArray = filteredObjectList.filter(item => item !== undefined);
            handleOnChange(value);
            setSelectedValues(interArray);
            setSelectedIDs(value);
            setSelectAllTitle(interArray.length === drpoDownData.length ? 'Unselect All' : 'Select All');
        }
        setFieldName(fieldData.name);
    }, []);

    useEffect(() => {
        if(fieldName !== fieldData.name && (value === '' || value?.length === 0)){
            setSelectedValues([]);
            setSelectedIDs([]);
            setSelectAllTitle('Select All');
            setShowDropdownMenu(false);
            setFieldName(fieldData.name);
            handleOnChange([]);
        }
    }, [fieldData]);

    useEffect(() => { 
        if (multiSelectRef != undefined || multiSelectRef != null) {
            multiSelectRef.current.childNodes[0].className = "multiSelectRef";
            let myRef = multiSelectRef;
            let obj = ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1];
            if (obj != undefined || obj != null) {
                ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[1].className = "multiSelectPopup";
                ReactDOM.findDOMNode(myRef.current).childNodes[0].childNodes[2].style = "display:none";
            }
        }
    } , [showDropdownMenu]);

    const Control = ({ children, props }) => {
        return (
            <components {...props} className="customReactSelectMenu">
                 <div className="btnhed">
                    <div>
                        <a title={selectAllTitle} role="button" onMouseDown={onSelectAllEvent} href="javascript:void(0)">
                            {selectAllTitle}
                        </a>
                        <div className="okbtnmulti">
                            <a title={translator['OK']} role="button" href="javascript:void(0)" onMouseDown={finalValueSelection}>{translator['OK']}</a>
                            <a title={translator['Close']} role="button" href="javascript:void(0)" className="clos" onMouseDown={resetSelectedValues}> <IoClose /> </a>
                        </div>  
                    </div>    
                 </div>
            </components>
        );
    }
    return (<div onClick={openMenu} ref={multiSelectRef} className="colSearDv aiMultiSelectDropdown" onBlur={resetSelectedValues}>
        <ReactMultiSelectCheckboxes placeholderButtonLabel={"Select"} value={selectedValues} rightAligned={false} options={drpoDownData} menuIsOpen={showDropdownMenu} onChange={handleChange} components={{ Control }} />
    </div>);
}

export default MultiSelectDropdownComponent;