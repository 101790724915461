
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { useEffect } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import ListLoader from "../../../../common/loaders/ListLoader";
import { filterFromArray } from "../../../../common/helper";
const LeftRightSection = (props) => {
  const [rightDisplay, setRightDisplay] = useState(false);
  const [loader, setLoader] = useState(false);
  const { renderComponent, selectedRow } = props;
  const leftSections = filterFromArray(props?.item?.components,"type","left-section");
  const righttSections = filterFromArray( props?.item?.components,"type","right-section");
  useEffect(() => {
    if (props?.rightPanelDisplay) {
      setRightDisplay(true);
    } else {
      setRightDisplay(false);
    }
  }, [props?.rightPanelDisplay]);

  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 200);
  }, [selectedRow]);

  return (
    <PanelGroup
      direction="horizontal"
      className="panelOverflow"
      style={{ height: "auto" }}
    >
      <Panel
        id="sidebar"
        minSize={36}
        order={1}
        defaultSize={rightDisplay ? 67 : 100}
        className={
          rightDisplay
            ? "catBordDv isShowLeftPanel rwLeftPart"
            : "catBordDv rwLeftPart"
        }
      >
        {renderComponent(leftSections[0]?.components)}
      </Panel>
      {rightDisplay ? (
        <>
          <PanelResizeHandle className="isShowLeftPanel resizeHandle">
            <div className="outlne">
              <div className="handIcn">
                <i className="fa fa-chevron-left" aria-hidden="true"></i>
              </div>
            </div>
          </PanelResizeHandle>
          <Panel minSize={33} order={2} defaultSize={33}>
            <div className="stickyArea rBoxStyle">
            
            {!loader && renderComponent(righttSections[0]?.components,false, selectedRow)}
            {loader && <ListLoader />}
            </div>
          </Panel>
        </>
      ) : (
        ""
      )}
    </PanelGroup>
  );
};
export default LeftRightSection