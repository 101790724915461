
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';

import PindCategories from "../../common/header/pind-categories";
import Breadcrumbs from "../../common/header/breadcrumbs";
import Header from "./scanAssetHeader";
import List from "./scanAssetForm";

const Index = () => {
  const [companyErrorState, setCompanyErrorState] = useState('');
  const [searchText, setSearchText] = useState('');
  const [onlyShowOverridden, setOnlyShowOverridden] = useState(false);
  const [instanceOrCompanySelection, setInstanceOrCompanySelection] = useState('Company');
  const [retainSelectedModule, setRetainSelectedModule] = useState(false);

  function changeSearchText(val) {
    setSearchText(val);
  }

  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName="Scan Asset" />
      </Container>

      <Container fluid>
        <Header />
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <List />
          </Col>
        </Row>
      </Container>
    </main>
  );
}

export default Index;