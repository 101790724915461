
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';
import {
  Button,
  ButtonToolbar,
  Card,
  Col,
  Form,
  Image,
  Row,
  Table,
  Nav
} from "react-bootstrap";
import { change, Field } from "redux-form";

import {
  _dropDown,
  _inputField,
  _inputFieldContact,
} from "../../../common/ReduxFormFields/formFields";
import Spinner from "react-spinkit";
import _ from "lodash";
import { connect } from "react-redux";
import SearchDeliveryRecipient from "./formFields/SearchDeliveryRecipient";
import OnBehalfBuyer from "./formFields/SearchOnBehalfBuyer";
import Cookies from "universal-cookie";
import { reduxForm } from "redux-form";
import ListLoader from "../../../common/loaders/ListLoader";
import dummyImage from "_Images/service-images/offering-dummy.png";
import axios from 'axios';
import ArticleDetails from "./formFields/ArticleDetails";
import {IoSearch, IoClose} from 'react-icons/io5';

import {
  loadRecipientCountries,
  loadRecipientLocations,
  updateOrderingInformation,
  loadRecipientLocationsIsShared
} from "../../../../actions/cart/addToCartActions";
import { loadArticleResultsForSearchString } from '../../../../actions/article/articleAction';
import { loadInsidedArticlesForSearch, emptyInsidedReducer, loadDiscourseArticlesForSearch } from '../../../../actions/knowledge/knowledgeAction';
import { fetchOfferingDetails } from "../../../../actions/offeringDetails/offeringDetailsActions";


import swal from "sweetalert";
import { navigationHooks } from "../../../../helpers/NavigationHook";
import { openLinkInNewTab } from "../../../common/helper";
import { SplitButton, SplitButtonItem } from "@progress/kendo-react-buttons";
import SaveTemplate from "../template/SaveTemplate";

let consumerCompanyIdVar;
const cookies = new Cookies();
let userInfo = cookies.get("gph");

if (userInfo) userInfo = userInfo.replace("s:", "");
if (userInfo) userInfo = userInfo.substring(0, userInfo.lastIndexOf("."));
userInfo = userInfo.split("~");
let contactNumber =
  userInfo[8] === undefined || userInfo[8] === "undefined" ? "" : userInfo[8];
let cubicalNumber =
  userInfo[7] === undefined || userInfo[7] === "undefined" ? "" : userInfo[7];

let GLOBAL = require("../../../Globals");

let emailAddress = cookies.get('gph');

if (emailAddress) emailAddress = emailAddress.replace('s:', '');
if (emailAddress) emailAddress = emailAddress.substring(0, emailAddress.lastIndexOf('.'));
let emailAddressArray = emailAddress.split("~");
emailAddress = emailAddressArray[29];
const currentUser_CompanyId = emailAddressArray[48];

let isKnowledgeEnabled = cookies.get('gph');
if (isKnowledgeEnabled) isKnowledgeEnabled = isKnowledgeEnabled.replace('s:', '');
if (isKnowledgeEnabled) isKnowledgeEnabled = isKnowledgeEnabled.substring(0, isKnowledgeEnabled.lastIndexOf('.'));
let cookiesArray = isKnowledgeEnabled.split("~");
isKnowledgeEnabled = cookiesArray[30];
const insided_community_url = cookiesArray[37];
let discourseUrl = cookies.get('gph');
if (discourseUrl) discourseUrl = discourseUrl.replace('s:', '');
if (discourseUrl) discourseUrl = discourseUrl.substring(0, discourseUrl.lastIndexOf('.'));
discourseUrl = discourseUrl.split("~");
discourseUrl = discourseUrl[50];

let username = cookies.get('gph');
if (username) username = username.replace('s:', '');
if (username) username = username.substring(0, username.lastIndexOf('.'));
username = username.split("~");
username = username[32];

const spcmKnowledgeCall = process.env.SPCM_KNOWLEDGE_SEARCH;

class OrderingInformation extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.state = {
      isOrderingInfoVisible: false,
      isDeliveryRec: false,
      isShippingLoc: false,
      showLocationAndCubical: false,
      showRecipientContactNumber: false,
      orderingInformation: {
        orderFor: "",
        deliveryLocation: "",
        shippingLocation: "",
        recipientsCubicle: "",
        recipientsContactNumber: "",
      },
      searchString: "",
      searchStringPlaceHolder: "",
      articleDetails: null,
      insided_access_token: "",
      insidedError: false,
      showSerachlence: true,
      showLoaderState: false,
      showSearchMsg: false,
      requestForVar: '',
      saveTemplate: false,
    };
    this.orderInfo = this.orderInfo.bind(this);
    this.countCharacters = this.countCharacters.bind(this);
    this.continueShopping = this.continueShopping.bind(this);
    this.handleClickStartChange = this.handleClickStartChange.bind(this);
    this.preview = this.preview.bind(this);
    this.changeSearchTextField = this.changeSearchTextField.bind(this);
    this.renderKnowledgeArticles = this.renderKnowledgeArticles.bind(this);
    this.showDescription = this.showDescription.bind(this);
    this.closeDescription = this.closeDescription.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.onTypeaheadClick = this.onTypeaheadClick.bind(this);
    this.changeSearchicone = this.changeSearchicone.bind(this);
    this.handleBackspace = this.handleBackspace.bind(this);
    this.resetLocation = this.resetLocation.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
  }

  preview(val, index, item) {
    this.setState({ isPreview: val, isPreviewID: index, countNo: index, isPreviewURL: ((item && item.KNOW_CONTENT_TEMP_PATH_ENCODED) ? item.KNOW_CONTENT_TEMP_PATH_ENCODED : ''), selectedItem: item });
    if (item) {
      this.props.getSpecificArticleDetails(item);
    }
  }
  componentDidMount() {
    this.context.keyDownHandler(this.closeButton,this.editButton,this.globalSearchRef,this.saveObjRef);
  }
  componentDidUpdate() {
    this.context.keyDownHandler(this.closeButton,this.editButton,this.globalSearchRef,this.saveObjRef);
  }
  componentWillUnmount() {
    consumerCompanyIdVar  = undefined;
    GLOBAL.consumerCompanyId  = '';
  }
  componentWillMount() {
    consumerCompanyIdVar = currentUser_CompanyId;
    if (
      !Array.isArray(this.props.recipientDeliveryCountries) ||
      !this.props.recipientDeliveryCountries.length
    ) {
      this.props.loadRecipientCountries();
      this.props.loadRecipientLocations(consumerCompanyIdVar);
    }
    // if (this.props.offeringsForOrder.length != 0)
    //   this.props.fetchOfferingDetails(this.props.offeringsForOrder[0].offeringId);

    this.props.emptyInsidedReducer();
    if (spcmKnowledgeCall == 'true')
      this.props.loadArticleResultsForSearchString('');

    if ((isKnowledgeEnabled==true || isKnowledgeEnabled == "true") && this.props.insidedTokenDetails.access_token != '') {
      axios.get('/user/email/' + emailAddress, { headers: { 'authorization': `bearer ${this.props.insidedTokenDetails.access_token}` } })
        .then((res) => {
          let authorId = res.data.userid;

          return axios.get('/v2/categories/' + authorId, { headers: { 'authorization': `bearer ${this.props.insidedTokenDetails.access_token}` } })
        })
        .catch(err => {
          this.setState({ insidedError: true });
        })
        .then((res) => {
          let categoryData = res.data.result;

          let categories = '';
          categoryData.forEach((item) => {
            categories = categories + item.id + ',';
          });
          categories = categories.substring(0, categories.length - 1);
          this.setState({ categories: categories });
          this.setState({ insidedError: false });

        })
        .catch(err => {
          this.setState({ insidedError: true });
        });
    }
  }

  componentWillReceiveProps(nextProps) {
    if((this.props.offeringsForOrder !== nextProps.offeringsForOrder) && nextProps.offeringsForOrder.length > 0) {
      this.props.fetchOfferingDetails(nextProps.offeringsForOrder[0].offeringId);
    }
    if (this.props.seachDiscription !== nextProps.seachDiscription) {
      if (nextProps.seachDiscription == '') {
        this.props.emptyInsidedReducer();
        if (spcmKnowledgeCall == 'true')
          this.props.loadArticleResultsForSearchString('');

        this.setState({ articleDetails: null });
        this.setState({ showLoaderState: false })
        this.setState({ showSearchMsg: false })
      }
      this.setState({ searchString: nextProps.seachDiscription });
      this.setState({ searchStringPlaceHolder: nextProps.seachDiscription })
      this.setState({ showSerachlence: true })
    }

    if ((nextProps.isFetchingInsidedDetails && nextProps.isFetchingInsidedDetails.isFetching !== this.props.isFetchingInsidedDetails.isFetching && nextProps.isFetchingInsidedDetails.isFetching == false) && (nextProps.isFetchingDetails && nextProps.isFetchingDetails.isFetching !== this.props.isFetchingDetails.isFetching && nextProps.isFetchingDetails.isFetching == false)) {
      this.setState({ showLoaderState: false })
    }


  }

  changeSearchTextField(e) {
    this.setState({ showSearchMsg: false })
    if (e.target.value == '') {
      this.props.emptyInsidedReducer();
      if (spcmKnowledgeCall == 'true')
        this.props.loadArticleResultsForSearchString('');
    }
    this.setState({ showSerachlence: true })
    this.setState({ showLoaderState: false })
    this.setState({ searchString: e.target.value });

  }

  renderKnowledgeArticles(event) {
    let searchArtical = this.state.searchString.trim()
    if (searchArtical) {
      //this.setState({showSerachlence:false})
      this.setState({ showLoaderState: true })
      this.setState({ showSearchMsg: true })
      if (this.state.searchString) {
        this.setState({ showSerachlence: false })
      } else {
        this.setState({ showSerachlence: true })
      }

      if (this.state.searchString && (isKnowledgeEnabled==true || isKnowledgeEnabled == "true")) {
        // if (this.props.insidedTokenDetails.access_token != '' && !this.state.insidedError) {
          this.props.loadDiscourseArticlesForSearch(this.state.searchString, username);
          if (spcmKnowledgeCall == 'true')
            this.props.loadArticleResultsForSearchString(this.state.searchString);
        // }
        // else {
        //   {
        //     spcmKnowledgeCall == 'true' ?
        //     this.props.loadArticleResultsForSearchString(this.state.searchString) :
        //     null
        //   }
        // }
      }
      else if (this.state.searchString && (isKnowledgeEnabled==true || isKnowledgeEnabled == "false")) {
        if (spcmKnowledgeCall == 'true')
          this.props.loadArticleResultsForSearchString(this.state.searchString);
      }
      else {
        this.props.emptyInsidedReducer();
        if (spcmKnowledgeCall == 'true')
          this.props.loadArticleResultsForSearchString('');
      }
    } else {
      //this.setState({showErrorMessahe:'Show err'});
    }
  }

  downloadDoc(docPath) {
    openLinkInNewTab(docPath, 'New Tab');
  }
  onTypeaheadClick() {
    this.setState({ showSearchMsg: false })
    this.setState({ showLoaderState: false })
    if (this.state.searchString) {
      this.props.emptyInsidedReducer();
      if (spcmKnowledgeCall == 'true')
        this.props.loadArticleResultsForSearchString('');
      this.setState({ showSerachlence: true })
      this.setState({ searchString: '' })
    }
  }

  changeSearchicone() {
    this.setState({ showSerachlence: true })
  }
  handleBackspace(e) {
    if (e.keyCode === 8) {
      this.setState({ showSerachlence: true })
    }
  }

  showDescription(title, description, path, type, KA_ID, contentType) {
    let articleDetails = {
      knowledgeArticle: title,
      type,
      knowledgeDescription: description,
      articleUrl: path,
      articleID: KA_ID,
      contentType: contentType
    };
    this.setState({ articleDetails: articleDetails });
  }

  closeDescription() {
    this.setState({ articleDetails: null });
  }

  downloadFile(slug) {
    if (this.state.articleDetails.type === "SPCM") {
      if (!this.state.articleDetails.articleUrl) {
        return;
      }
      let link = document.createElement('a');
      link.href = this.state.articleDetails.articleUrl; //'http://www.africau.edu/images/default/sample.pdf';
      link.id = "temp_downloadfile";
      link.target = "_self"
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        link.remove();
      }, 1000000);
    }
    else {
      const ssoUrl=`${discourseUrl}/t/${slug}`;
      const win = openLinkInNewTab(ssoUrl, 'New Tab');
    }
  }

  getLeftSideListHTML(state) {
    let newInsidedArray = [];
    newInsidedArray = this.props.insidedResultsList.topics;
    let combinedArticlesArray = this.props.articleResultsList.concat(newInsidedArray);

    combinedArticlesArray.sort((article1, article2) => article1.title.localeCompare(article2.title));

    if (combinedArticlesArray.length == 0) {
      //this.props.getSpecificArticleDetails(null);
      return (
        <div className="f-size-12">
          <Table bordered condensed hover responsive className="tableView sortImg">
            <tbody>
              <tr>
                <td colSpan={4} className="article-table-td-align-center">{this.props.translator['No Published Knowledge References Available...']}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      );
    }

    if (!combinedArticlesArray[0].KNOW_ID && state.countNo == 0) {
    }
    return combinedArticlesArray.map((item, index) => {
      let type = '';
      if (item.KNOW_ID) {
        type = 'SPCM';
        let description = (item.EXTRACT_With_DOC_ID && item.EXTRACT_With_DOC_ID.extract_array && Array.isArray(item.EXTRACT_With_DOC_ID.extract_array) && item.EXTRACT_With_DOC_ID.extract_array[0]) ? item.EXTRACT_With_DOC_ID.extract_array[0] : '-';
        return (
          <li className={(state && index == state.countNo) ? "active" : ""} key={index}><a role="button" href="javascript:void(0)" onClick={() => this.showDescription(item.title, description, item.slug + "/" + item.id, type, '', '')} >
            <span className="comInsd interLnk" />{item.title}
          </a></li>
        );
      }
      else {
        let insidedClass;
        if (item.contentType == 'article') {
          type = 'Knowledge';
          insidedClass = 'comInsd exterLnk';
        } else if (item.contentType == 'conversation') {
          type = 'conversation';
          insidedClass = 'comInsd exterLnk';
        }
        else if (item.contentType == 'question') {
          type = 'Community';
          insidedClass = 'comInsd community';
        }
        return (
          <li className={(state && index == state.countNo) ? "active" : ""} key={index}><a role="button" href="javascript:void(0)" onClick={() => {
            this.showDescription(item.title, item.content, item.slug + "/" + item.id, type, item.id, item.slug)
          }}>
            <span className={"KA-icon exterLnk1"} style={{ backgroundPosition: "-2px 0px"}} />{item.title}
          </a></li>
        );
      }
    });
  }

  handleClickStartChange(offeringComponentNotSelected) {
    let offeringNames = offeringComponentNotSelected.join(",");
    swal({
      text: `${this.props.translator["Please select at least one of the optional component(s) in following services :"]} ${offeringNames}`,
      button: this.props.translator["OK"],
    });
  }

  orderInfo(orderTypeDetail, boolValue, elementval) {
    let offeringDetailsData = this.props.offeringDetails;
    let locationOptions = this.props.offeringDetails[0].isShared == "Yes" ? this.props.recipientDeliveryLocationsIsShared : this.props.recipientLocations
    if (orderTypeDetail === "orderingInformation") {
      if (elementval === "self" || elementval === "") {
        this.props.setVipMember();
        this.props.setIsOnBehalfUserValid(true);
        this.props.setIsSelfSelected && this.props.setIsSelfSelected(true);
        this.setState({ isOrderingInfoVisible: false });
      } else if(elementval === "0") {  
        this.props.setIsOnBehalfUserValid(false);
        this.props.setIsSelfSelected && this.props.setIsSelfSelected(false);
        this.setState({ isOrderingInfoVisible: false });
      } else {
        this.props.setIsOnBehalfUserValid(false);
        this.props.setIsSelfSelected && this.props.setIsSelfSelected(false);
        this.setState({ isOrderingInfoVisible: true });
      }
    } else if (orderTypeDetail === "deliveryRecipient") {
      this.props.dispatch(change('orderingForm', 'recipientContact', ''));
      if (elementval === "ownAddress" || elementval === "") {
        this.setState({ isDeliveryRec: false });
        this.props.setIsOtherDeliveryLocationValid(true);
        if (elementval === "") {
          this.setState({ showRecipientContactNumber: false });
        } else {
          this.setState({ showRecipientContactNumber: true });
        }
      } else if(elementval === "0") { 
        this.props.setIsOtherDeliveryLocationValid(false); 
        this.setState({ showRecipientContactNumber: false, isDeliveryRec: false });
      } else {
        if (elementval === "otherAddress") {
          this.props.setIsOtherDeliveryLocationValid(false);
          this.setState({ showRecipientContactNumber: true });

        }
        this.setState({ isDeliveryRec: true });
      }
    } else if (orderTypeDetail === "shippingLocation") {
      if (elementval === "ownCWL" || elementval === "") {
        this.props.setIsShippingLocationType(true);
        this.props.setIsShippingLocationAddress(true, locationOptions);
        this.setState({ isShippingLoc: false });
        if (elementval === "") {
          this.setState({ showLocationAndCubical: false });
        }
      } else if(elementval === "0"){
        this.setState({ isShippingLoc: false, showLocationAndCubical: false });
        this.props.setIsShippingLocationType(false);
        this.props.setIsShippingLocationAddress(false);
      } else {
        this.props.setIsShippingLocationType(true);
        this.props.setIsShippingLocationAddress(true);
        this.setState({ isShippingLoc: true });
      }
    } else if (orderTypeDetail === "requesterLocationId") {
      if(elementval === "0"){
        this.props.setIsShippingLocationAddress(false);
      } else {
        this.props.setIsShippingLocationAddress(true, undefined, elementval);
      }
    }
    if (elementval === "ownAddress") {
      this.props.dispatch(change("orderingForm", "recipientId", ""));
      this.props.dispatch(change("orderingForm", "recipientName", ""));

      this.props.dispatch(
        change(
          "orderingForm",
          "recipientCubicle",
          this.props.formValues.requester === "onBehalf"
            ? this.props.formValues.recipientCubicle
            : cubicalNumber
        )
      );
      this.props.dispatch(
        change("orderingForm", "recipientContact", this.props.formValues.requester === "onBehalf" ? this.props.formValues.requesterContactNumber : contactNumber)
      );
    }
    if (elementval === "self") {
      this.props.dispatch(change("orderingForm", "requesterId", ""));
      this.props.dispatch(change("orderingForm", "requesterName", ""));
     
      this.props.dispatch(change("orderingForm", "requesterId", "empty"));
      this.props.dispatch(change("orderingForm", "requesterName", "empty"));
      //this.props.dispatch(change('orderingForm', 'recipientContact', contactNumber));
      this.props.dispatch(
        change("orderingForm", "recipientCubicle", cubicalNumber)
      );
    }
    if (elementval === "ownCWL") {
      this.setState({ showLocationAndCubical: true });
      this.props.dispatch(
        change(
          "orderingForm",
          "recipientCubicle",
          this.props.formValues.requester === "onBehalf"
            ? this.props.formValues.recipientCubicle
            : cubicalNumber
        )
      );
      this.props.dispatch(
        change(
          "orderingForm",
          "recipientContact",
          this.props.formValues.requester === "onBehalf"
            ? this.props.formValues.recipientContact
            : contactNumber
        )
      );
    }
  }

  continueShopping() {
    if (this.props.fromOrderNow == "true") {
      swal({
        text: this.props.translator[
          "Any Information provided by you will be lost. Do you wish to continue ?"
        ],
        buttons: [this.props.translator["OK"], this.props.translator["Cancel"]],
      }).then((willDelete) => {
        if (willDelete) {
          //TODO when pressed no
          // navigationHooks.navigate("/cart");
          // navigationHooks.navigate(homepagelocation);
        } else {
          //TODO when pressed yes
          //navigationHooks.navigate(homepagelocation);
          navigationHooks.navigate("/home");
        }
      });
    } else {
      navigationHooks.navigate("/home");
    }
  }

  renderOfferingList(offerings, itemPriceAndQuantity) {
    const { selectedComponentForOffering } = this.props;
    const plusSign = (
      <span className="black f-size-16 fw-800 margin-r-5 margin-l-5">+</span>
    );
    return offerings.map((offering) => {
      let Hour = 0;
      let Weekly = 0;
      let Monthly = 0;
      let yearly = 0;
      let BiWeekly = 0;
      let Quarterly = 0;
      let Daily = 0;
      let HalfYearly = 0;
      let NotApplicable = 0;
      let serviceTotalPrice = 0.0;

      let displayText="";
      let displayStr="";
      displayStr = offering.offeringName != null && offering.offeringName != "" ? offering.offeringName : "";
      displayText = displayStr != "" ? displayStr.replace(/(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/ig, '').trim() : "";
      displayText = displayText != "" ? displayText.replace(/\s+/g, ' ') : "";
  
      let getInnerWidth = (innerWidth)=>{
        if (innerWidth > 1535) return 100;
        if (innerWidth > 1367) return 82;
        if (innerWidth > 1279) return 72;
        if (innerWidth > 1023) return 55;
        if (innerWidth > 819) return 30;
        if (innerWidth > 767) return 20;
        if (innerWidth > 640) return 60;
        if (innerWidth > 539) return 48;
        if (innerWidth > 411) return 65;
        if (innerWidth > 279) return 22;
        return 20;
      }


      if (Array.isArray(itemPriceAndQuantity) && itemPriceAndQuantity.length) {
        let service = _.find(itemPriceAndQuantity, {
          serviceId: offering.offeringId,
        });
        if (service) {
          serviceTotalPrice = service.serviceTotalPrice;
        }
      }
      switch (offering.billingMethod) {
        case "Hour":
          Hour = offering.actualPrice;
          break;
        case "Daily":
          Daily = offering.actualPrice;
          break;
        case "Weekly":
          Weekly = offering.actualPrice;
          break;
        case "Bi-Weekly":
          BiWeekly = offering.actualPrice;
          break;
        case "Monthly":
          Monthly = offering.actualPrice;
          break;
        case "Quarterly":
          Quarterly = offering.actualPrice;
          break;
        case "Half-Yearly":
          HalfYearly = offering.actualPrice;
          break;
        case "Yearly":
          yearly = offering.actualPrice;
          break;
        case "Not Applicable":
          NotApplicable = offering.actualPrice;
          break;
      }

      for (let i = 0; i < offering.componentapiList.length; i++) {
        if (
          offering.componentapiList[i].componentType === "Optional" &&
          _.some(selectedComponentForOffering, [
            "componentId",
            offering.componentapiList[i].id.toString(),
          ])
        ) {
          if (offering.componentapiList[i].componentType === "Optional") {
            switch (offering.componentapiList[i].billingMethod) {
              case "Hour":
                Hour += parseFloat(offering.componentapiList[i].actualPrice);
                break;
              case "Daily":
                Daily += parseFloat(offering.componentapiList[i].actualPrice);
                break;
              case "Weekly":
                Weekly += parseFloat(offering.componentapiList[i].actualPrice);
                break;
              case "Bi-Weekly":
                BiWeekly += parseFloat(
                  offering.componentapiList[i].actualPrice
                );
                break;
              case "Monthly":
                Monthly += parseFloat(offering.componentapiList[i].actualPrice);
                break;
              case "Quarterly":
                Quarterly += parseFloat(
                  offering.componentapiList[i].actualPrice
                );
                break;
              case "Half-Yearly":
                HalfYearly += parseFloat(
                  offering.componentapiList[i].actualPrice
                );
                break;
              case "Yearly":
                yearly += parseFloat(offering.componentapiList[i].actualPrice);
                break;
              case "Not Applicable":
                NotApplicable += parseFloat(
                  offering.componentapiList[i].actualPrice
                );
                break;
            }
          }
        }
      }
      return (
        <Card key={offering.offeringId}>
          <div className='card-left'>
            <a className="addCaRg" href="Javascript:;" title={offering.offeringName}>
              <Image
                src={
                  offering.offeringImageTempURL
                    ? offering.offeringImageTempURL
                    : dummyImage
                }
                alt={"Office 365"}
              />
            </a>
          </div>
          <Card.Body>
            <Card.Header bsClass="" className="namOffer" title={offering.offeringName}>
            {displayText.slice(0, getInnerWidth(this.props.windowSize.width))}{displayText.length > getInnerWidth(this.props.windowSize.width) ? "..." : ''}
            </Card.Header>
            {/*{offering.hidePrice === "true" ? null :
              <div className="f-size-15 display-block green">
                {Hour === 0 ? '' : offering.currencyCode + " " + Number(Hour).toFixed(2) + " Hour"}
                {(Hour !== 0 && (Daily !== 0 || Weekly !== 0 || BiWeekly !== 0 || Monthly !== 0 || Quarterly !== 0 || HalfYearly !== 0 || yearly !== 0)) ? plusSign : " "}
                {Daily === 0 ? '' : offering.currencyCode + " " + Number(Daily).toFixed(2) + " Daily"}
                {(Daily !== 0 && (Weekly !== 0 || BiWeekly !== 0 || Monthly !== 0 || Quarterly !== 0 || HalfYearly !== 0 || yearly !== 0)) ? plusSign : " "}
                {Weekly === 0 ? '' : offering.currencyCode + " " + Number(Weekly).toFixed(2) + " Weekly"}
                {(Weekly !== 0 && (BiWeekly !== 0 || Monthly !== 0 || Quarterly !== 0 || HalfYearly !== 0 || yearly !== 0)) ? plusSign : " "}
                {BiWeekly === 0 ? '' : offering.currencyCode + " " + Number(BiWeekly).toFixed(2) + " BiWeekly"}
                {(BiWeekly !== 0 && (Monthly !== 0 || Quarterly !== 0 || HalfYearly !== 0 || yearly !== 0)) ? plusSign : " "}
                {Monthly === 0 ? '' : offering.currencyCode + " " + Number(Monthly).toFixed(2) + " Monthly"}
                {(Monthly !== 0 && (Quarterly !== 0 || HalfYearly !== 0 || yearly !== 0)) ? plusSign : " "}
                {Quarterly === 0 ? '' : offering.currencyCode + " " + Number(Quarterly).toFixed(2) + " Quarterly"}
                {(Quarterly !== 0 && (HalfYearly !== 0 || yearly !== 0)) ? plusSign : " "}
                {HalfYearly === 0 ? '' : offering.currencyCode + " " + Number(HalfYearly).toFixed(2) + " HalfYearly"}
                {(HalfYearly !== 0 && (yearly !== 0)) ? plusSign : " "}
                {yearly === 0 ? '' : offering.currencyCode + " " + Number(yearly).toFixed(2) + " yearly"}
              </div>}*/}
            {serviceTotalPrice ? (
              <div className="pricOffer">
                {offering.currencyCode + " " + serviceTotalPrice}
              </div>
            ) : null}
          </Card.Body>
          {this.props.fromOrderNow == "true" ? null : (
          <div className='card-right'>
            <a onClick={() => { this.props.deleteOffering(offering.offeringId);}} title={this.props.translator["Remove"]} className="delBtn" href="Javascript:;"><i className="fa fa-trash-o"/></a>
          </div>)}
        </Card>
      );
    });
  }

  handleItemClick(val) {
    this.setState({saveTemplate : val});
  }

  countCharacters(e) {
    if (e.target.value.length > 500) {
      e.preventDefault();
    }
  }
  resetLocation() {
    this.setState({isShippingLoc: false, showLocationAndCubical: false})
    this.setState({ showRecipientContactNumber: false, isDeliveryRec: false });
    this.props.dispatch(change("orderingForm", "shippingLocation", ""));
    this.props.dispatch(change("orderingForm", "recipient", ""));
    this.props.dispatch(change("orderingForm", "recipientId", ""));
    this.props.dispatch(change("orderingForm", "recipientName", ""));
  }
  render() {
    const {
      handleSubmit,
      onSubmitClick,
      offeringsForOrder,
      formValues,
    } = this.props;
    const offering = this.props.offeringsForOrder;
    const selectedComponentForOffering = _.uniqBy(
      this.props.selectedComponentForOffering,
      "componentId"
    );
    let offeringDetailsData = this.props.offeringDetails;
     if(offeringDetailsData.length===0 && this.props.fromOrderNow=="true"){
       return <ListLoader />;
     }
    let offeringComponentNotSelected = [];
    let ServiceNames = offering.map((offering) => {
      if (offering.minimumOptionalComponent == "true") {
        let optionalComponentAvailable = _.some(offering.componentapiList, [
          "componentType",
          "Optional",
        ]);
        if (optionalComponentAvailable) {
          let isSelectedComponent = _.filter(selectedComponentForOffering, [
            "offeringId",
            offering.offeringId.toString(),
          ]);
          isSelectedComponent = _.some(isSelectedComponent, [
            "componentType",
            "Optional",
          ]);
          if (!isSelectedComponent) {
            offeringComponentNotSelected.push(offering.offeringName);
          } else {
            const index = offeringComponentNotSelected.indexOf(
              offering.offeringName
            );
            if (index > -1) {
              offeringComponentNotSelected.splice(index, 1);
            }
          }
        }
      }
    });
    
    if (GLOBAL.consumerCompanyId == null || GLOBAL.consumerCompanyId == "null" || GLOBAL.consumerCompanyId == "" || !consumerCompanyIdVar) {
      /* if (!offeringDetailsData) {
        if (offeringDetailsData[0].company)
          consumerCompanyIdVar = offeringDetailsData[0].company
      } */
      
      if (!consumerCompanyIdVar && typeof offeringDetailsData[0]!== 'undefined' && offeringDetailsData[0].isShared == "Yes") {
        consumerCompanyIdVar = currentUser_CompanyId;
      }
      // console.log("consumerCompanyIdVar", consumerCompanyIdVar);
    }
    else {
      consumerCompanyIdVar = GLOBAL.consumerCompanyId;
    }
    
    // console.log("consumerCompanyIdVar3", consumerCompanyIdVar);


    let isShipmentBased = _.some(this.props.offeringsForOrder, [
      "deliveryMode",
      "Shipment Based",
    ]);
    let isDeliveryModeOther = _.some(this.props.offeringsForOrder, [
      "deliveryMode",
      "Other",
    ]);

    let requestingForOption1 = [
      { value: "self", label: this.props.translator["Self"] },
      { value: "onBehalf", label: this.props.translator["Other Person"] },
    ];

    let requestingForOption2 = [{ value: "self", label: this.props.translator["Not Applicable"] }];

    if (isDeliveryModeOther) {
      this.props.dispatch(change("orderingForm", "requester", "self"));
      this.props.setIsOnBehalfUserValid(true);
      this.props.setIsSelfSelected && this.props.setIsSelfSelected(true);
      this.props.dispatch(change("orderingForm", "requesterId", "empty"));
      this.props.dispatch(change("orderingForm", "requesterName", "empty"));
      // if (offeringDetailsData.length >0 && offeringDetailsData[0].isShared == "Yes") {
      //   const LocationID = (this.props.recipientDeliveryLocationsIsShared && Array.isArray(this.props.recipientDeliveryLocationsIsShared) && this.props.recipientDeliveryLocationsIsShared.length) ? this.props.recipientDeliveryLocationsIsShared[0].LOCATIONS : "0";

      //   this.props.dispatch(
      //     change("orderingForm", "requesterLocationId", LocationID)
      //   );
      // } 
      //// else if((offeringDetailsData[0].isShared == "No"|| offeringDetailsData[0].isShared == undefined || offeringDetailsData[0].isShared == "undefined" )) {

      //   this.props.dispatch(
      //     change("orderingForm", "requesterLocationId", parseInt(userInfo[6]))
      //   );
      // }


      // this.props.dispatch(
      //   change("orderingForm", "requesterLocationName", userInfo[9])
      // );

      this.props.dispatch(
        change("orderingForm", "recipientCubicle", cubicalNumber)
      );
    }

    if (this.props.loaderStatus) {
      return <ListLoader />;
    }

    if (offering.length == 0 || offering instanceof Error) {
      //this.props.getOfferingsInCart(user);
      this.props.changeCompMessage(
        this.props.translator["Your cart is empty"],
        ""
      );
    }

    if (this.props.offeringsForOrder.errorCode) {
      return (
        <h2>
          {this.props.offeringsForOrder.errorMessage +
            " " +
            this.props.offeringsForOrder.errorCode}
        </h2>
      );
    }
    if (this.props.offeringsForOrder instanceof Error) {
      return this.props.cartActionsMessage == "" ? (
        <div>
          <h2>
            <div>
              <Spinner spinnerName="three-bounce" />
            </div>
            {this.props.translator["loading..."]}
          </h2>
        </div>
      ) : (
        <div>
          <h2>{this.props.cartActionsMessage}</h2>
        </div>
      );
    }
    if (this.props.offeringsForOrder.length == 0) {
      return this.props.cartActionsMessage == "" ? (
        <div>
          {/* <Spinner spinnerName="three-bounce" /> */}
        </div>
      ) : (
        <div>
          <h2>{this.props.cartActionsMessage}</h2>
        </div>
      );
    }
    if (this.state.articleDetails) {
      return (<ArticleDetails
        articleDetails={this.state.articleDetails}
        closeDescription={this.closeDescription}
        downloadFile={this.downloadFile}
        translator={this.props.translator}
      />);
    }
    if (this.props.fromOrderNow == "true" || this.props.type == "behalf") {
      return (
        <div>
          {(this.state.searchStringPlaceHolder && (isKnowledgeEnabled == "true" || isKnowledgeEnabled == true)) ?
            <div className="">
              <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                <ul>
                  <li>
                  <button
                      type='button'
                      title="Minimize the right panel"
                      bsClass=""
                      bsStyle=""
                      className="closerightPanelBtn"
                      onClick={() => {
                      this.props.rightEditPanel(false);
                      }}
                  >
                      <IoClose/>
                  </button>
                  </li>
                </ul>
              </div>
            <div className="rPageHeading">
              <div className="offNam margin-t-5 margin-b-5">{this.props.translator["Search Knowledge Articles"]}</div>
                </div>

              <div className="padding-r-10 padding-l-10 margin-t-15">
              {/* <div className="padding-b-5">
                <span className="black f-size-20 fw-300">Search Knowledge Articles</span>
              </div> */}             

              <div className="inpbtnBox">
                <input type="text" name="title" className="form-control hide36" value={this.state.searchString} placeholder={this.props.translator['What are you looking for...']} onChange={this.changeSearchTextField} onKeyPress={this.changeSearchicone} disabled={this.props.isFetchingDetails.isFetching || this.props.isFetchingInsidedDetails.isFetching} />
                {this.state.showSerachlence ? 
                  this.state.searchString.trim().length == 0 || this.state.searchString.trim().length >= 3 ? 
                    <Button type="button" className="myBtn" onClick={(event) => { this.renderKnowledgeArticles(event); }} disabled={this.props.isFetchingDetails.isFetching || this.props.isFetchingInsidedDetails.isFetching}><IoSearch/></Button> 
                    :
                    <span onClick={this.onTypeaheadClick}><Button type="button" className="myBtn" disabled={this.props.isFetchingDetails.isFetching || this.props.isFetchingInsidedDetails.isFetching}><i className="fa fa-close"></i></Button></span>  
                  : 
                  <span onClick={this.onTypeaheadClick}><Button type="button" className="myBtn" disabled={this.props.isFetchingDetails.isFetching || this.props.isFetchingInsidedDetails.isFetching}><i className="fa fa-close"></i></Button></span>
                }
              </div>

              {/* <span>Value:-
              {this.props.seachDiscription}
              {console.log(this.props.articleResultsList)}
            </span> */}
              <div className="margin-10 margin-b-20">
                {(this.props.articleResultsList && this.props.articleResultsList.length) || (this.props.insidedResultsList.topics && this.props.insidedResultsList.topics.length) ?
                  <ul className="knowledgeDiv knledbox margin-t-10">
                    {this.getLeftSideListHTML(this.state)}
                  </ul>
                  : this.state.showSearchMsg && !((this.props.isFetchingDetails && this.props.isFetchingDetails.isFetching) || (this.props.isFetchingInsidedDetails && this.props.isFetchingInsidedDetails.isFetching)) ?
                    <div className="f-size-12">
                      <Table bordered condensed hover responsive className="tableView sortImg">
                        <tbody>
                          <tr>
                            <td colSpan={4} className="article-table-td-align-center">{this.props.translator["Your search did not match any articles"]}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    : null

                }
              </div>
            </div>
            </div>
            : null
          }

          <div>
            {((this.props.isFetchingDetails && this.props.isFetchingDetails.isFetching) || (this.props.isFetchingInsidedDetails && this.props.isFetchingInsidedDetails.isFetching)) && (this.state.showLoaderState) ?
              <div className="margin-t-0 margin-b-20">
                <ListLoader />
              </div>

              : ''}
          </div>
          <Row>
            <Col xs={12}>
            <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
                <ul>
                  <li>
                  <button
                      type='button'
                      title="Minimize the right panel"
                      bsClass=""
                      bsStyle=""
                      className="closerightPanelBtn"
                      onClick={() => {
                      this.props.rightEditPanel(false);
                      }}
                  >
                      <IoClose/>
                  </button>
                  </li>
                </ul>
              </div>
              <div className="rPageHeading">
                {/* {this.props.fromOrderNow == "true" ? null : null} */}
                <div className="offNam  margin-t-5 margin-b-5">
                {!this.state.saveTemplate ?
                  (this.props.translator["Ordering Information"]) :
                  this.props.translator["Template"]}
                </div>
              </div>
            </Col>
          </Row>
          {!this.state.saveTemplate ?
          <div className="rBoxGap">
            {_.some(this.props.offeringsForOrder, ["hidePrice", "false"]) ? (
              <div style={{ marginTop: "0px" }} className="totalDv">
                {this.props.translator["Subtotal"]} (
                {this.props.offeringsForOrder.length}
                {this.props.offeringsForOrder.length <= 1
                  ? this.props.translator["Item"]
                  : this.props.translator["Items"]}
                ) :{" "}
                <span className="">
                  {offering[0].currencyCode + " "}
                  {Number(this.props.allServicePrices.grandTotal()).toFixed(2)}
                </span>
              </div>
            ) : null}
            <div className="orderingInfo-form-container formGpGap">
              {/* {_.some(this.props.offeringsForOrder, ["hidePrice", "true"]) ? (
                <div style={{ marginTop: "0px" }} className="totalDv">
                  {this.props.translator["Subtotal"]} (
                  {this.props.offeringsForOrder.length}
                  {this.props.offeringsForOrder.length <= 1
                    ? this.props.translator["item"]
                    : this.props.translator["items"]}
                  ) :
                  <span className="">
                    {offering[0].currencyCode + " "}
                    {Number(this.props.allServicePrices.grandTotal()).toFixed(
                      2
                    )}
                  </span>
                </div>
              ) : null} */}

              <div>
                <Row>
                  <Col md={12}>
                    <Form.Group className="form-group radioDiv-inp">
                      <Form.Label bsClass="">
                        {this.props.translator["Requesting For"]}
                        <span style={{ color: "red" }}> *</span>
                      </Form.Label>
                      <Field
                        component={_dropDown}
                        className="form-control"
                        name="requester"
                        translator={this.props.translator}
                        options={
                          isDeliveryModeOther
                            ? requestingForOption2
                            : requestingForOption1
                        }
                        onChange={(event) => {
                          this.orderInfo(
                            "orderingInformation",
                            null,
                            event.target.value
                          );
                          this.setState({ requestForVar: event.target.value })
                          if (offeringDetailsData[0].isShared == "Yes") {
                            if (event.target.value == "self")
                              this.props.loadRecipientLocationsIsShared(`${currentUser_CompanyId}`);
                            else
                              this.props.loadRecipientLocationsIsShared(consumerCompanyIdVar);
                          }
                          // (offeringDetailsData[0].isShared == "No"|| offeringDetailsData[0].isShared == undefined || offeringDetailsData[0].isShared == "undefined" )?
                          // "":""
                          this.resetLocation();
                        }}
                        disabledValue={isDeliveryModeOther}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {this.state.isOrderingInfoVisible ? (
                  <Row>
                    <Col md={12}>
                      <Form.Group className="form-group"
                        validationState={
                          formValues.requester === "onBehalf" &&
                            (!formValues.requesterId ||
                              formValues.requesterId === "empty")
                            ? "error"
                            : ""
                        }
                      >
                        <OnBehalfBuyer
                          translator={this.props.translator}
                          options={this.props.requestorData}
                          updateOrderingInformation={
                            this.props.updateOrderingInformation
                          }
                          dispatch={this.props.dispatch}
                          setIsOnBehalfUserValid={
                            this.props.setIsOnBehalfUserValid
                          }
                          requestForVar={this.state.requestForVar}
                          resetLocation={this.resetLocation}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                {isShipmentBased ? (
                  <Row>
                    <Col md={12}>
                      <Form.Group className="radioDiv-inp form-group">
                        <Form.Label bsClass="">
                          {this.props.translator["Shipping Location"]}
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Field
                          component={_dropDown}
                          className="form-control"
                          translator={this.props.translator}
                          name="shippingLocation"
                          options={[
                            {
                              value: "ownCWL",
                              label: this.props.translator["Office"],
                            },
                            {
                              value: "otherCWL",
                              label: this.props.translator["Other Location"],
                            },
                          ]}
                          onChange={(event) => {
                            this.orderInfo(
                              "shippingLocation",
                              null,
                              event.target.value
                            );
                            if (offeringDetailsData[0].isShared == "Yes") {
                              if (formValues.requester === "self")
                                this.props.loadRecipientLocationsIsShared(`${currentUser_CompanyId}`);
                              else
                                this.props.loadRecipientLocationsIsShared(consumerCompanyIdVar);
                            }
                            // (offeringDetailsData[0].isShared == "No"|| offeringDetailsData[0].isShared == undefined || offeringDetailsData[0].isShared == "undefined" )?
                            // "":""
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                ) : null}
                {this.state.isShippingLoc ? (
                  <Row>
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Form.Label bsClass="">
                          {this.props.translator["Address Line 1"]}
                        </Form.Label>
                        <Field
                          name="addressLine1"
                          component={_inputField}
                          className="form-control"
                          onpaste={(e) => {
                            this.countCharacters(e);
                          }}
                          onChange={(e) => {
                            this.countCharacters(e);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label bsClass="">
                          {this.props.translator["Address Line 2"]}
                        </Form.Label>
                        <Field
                          name="addressLine2"
                          component={_inputField}
                          className="form-control"
                          onpaste={(e) => {
                            this.countCharacters(e);
                          }}
                          onChange={(e) => {
                            this.countCharacters(e);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label bsClass="">
                          {this.props.translator["City"]}
                        </Form.Label>
                        <Field
                          name="city"
                          component={_inputField}
                          className="form-control"
                          onpaste={(e) => {
                            this.countCharacters(e);
                          }}
                          onChange={(e) => {
                            this.countCharacters(e);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label bsClass="">
                          {this.props.translator["State/Province"]}
                        </Form.Label>
                        <Field
                          name="state"
                          component={_inputField}
                          className="form-control"
                          onpaste={(e) => {
                            this.countCharacters(e);
                          }}
                          onChange={(e) => {
                            this.countCharacters(e);
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label bsClass="">
                          {this.props.translator["Country"]}
                        </Form.Label>
                        <Field
                          name="country"
                          component={_dropDown}
                          translator={this.props.translator}
                          options={this.props.recipientDeliveryCountries}
                          className="form-control"
                          onChange={(event) => {
                            this.props.dispatch(
                              change(
                                "orderingForm",
                                "countryName",
                                event.target[event.target.selectedIndex].text
                              )
                            );
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label bsClass="">
                          {this.props.translator["ZIP/Postal Code"]}
                        </Form.Label>
                        <Field
                          name="postalCode"
                          component={_inputField}
                          className="form-control"
                          onpaste={(e) => {
                            this.countCharacters(e);
                          }}
                          onChange={(e) => {
                            this.countCharacters(e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                ) : isShipmentBased && this.state.showLocationAndCubical ? (
                  offeringDetailsData[0].isShared == "Yes" ? (
                    <Row>
                      <Col md={12}>
                        <Form.Group className="form-group">
                          <Field
                            name="requesterLocationId"
                            component={_dropDown}
                            translator={this.props.translator}
                            options={this.props.recipientDeliveryLocationsIsShared}
                            className="form-control"
                            onChange={(event) => {
                              this.orderInfo(
                                "requesterLocationId",
                                null,
                                event.target.value
                              )
                              this.props.dispatch(
                                change(
                                  "orderingForm",
                                  "requesterLocationName",
                                  event.target[event.target.selectedIndex].text
                                )
                              );
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ) :(offeringDetailsData[0].isShared == "No"|| offeringDetailsData[0].isShared == undefined || offeringDetailsData[0].isShared == "undefined" )?

                    (
                      <Row>
                        <Col md={12}>
                          <Form.Group className="form-group">
                            <Field
                              name="requesterLocationId"
                              component={_dropDown}
                              translator={this.props.translator}
                              options={this.props.recipientLocations}
                              className="form-control"
                              onChange={(event) => {
                                this.orderInfo(
                                  "requesterLocationId",
                                  null,
                                  event.target.value
                                )
                                this.props.dispatch(
                                  change(
                                    "orderingForm",
                                    "requesterLocationName",
                                    event.target[event.target.selectedIndex].text
                                  )
                                );
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    )
                    :null
                    )

                  : null}
                {isShipmentBased &&
                  !this.state.isShippingLoc &&
                  this.state.showLocationAndCubical ? (
                  <Row>
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Form.Label bsClass="">
                          {this.props.translator["Cubicle Number"]}
                        </Form.Label>
                        <div className="position-re">
                          <Field
                            name="recipientCubicle"
                            component={_inputField}
                            className="form-control"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                ) : null}
                <div className="margin-b-10">
                  {isShipmentBased ? (
                    <Row>
                      <Col md={12}>
                        <Form.Group className="radioDiv-inp form-group">
                          <Form.Label bsClass="">
                            {this.props.translator["Delivery Recipient"]}
                            <span style={{ color: "red" }}> *</span>
                          </Form.Label>
                          <Field
                            component={_dropDown}
                            className="form-control"
                            translator={this.props.translator}
                            name="recipient"
                            options={[
                              {
                                value: "ownAddress",
                                label: this.props.translator["Requestor"],
                              },
                              {
                                value: "otherAddress",
                                label: this.props.translator["Other Person"],
                              },
                            ]}
                            onChange={(event) => {
                              this.orderInfo(
                                "deliveryRecipient",
                                null,
                                event.target.value
                              );
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ) : null}
                  {this.state.isDeliveryRec ? (
                    <Row>
                      <Col md={12}>
                        <Form.Group className="form-group"
                          validationState={
                            formValues.recipient === "otherAddress" &&
                              (!formValues.recipientId ||
                                formValues.recipientId === "")
                              ? "error"
                              : ""
                          }
                        >
                          <SearchDeliveryRecipient
                            translator={this.props.translator}
                            options={this.props.requestorData}
                            updateOrderingInformation={
                              this.props.updateOrderingInformation
                            }
                            dispatch={this.props.dispatch}
                            setIsOtherDeliveryLocationValid={
                              this.props.setIsOtherDeliveryLocationValid
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {isShipmentBased && this.state.showRecipientContactNumber ? (
                    <Row>
                      <Col md={12}>
                        <Form.Group className="form-group">
                          <Form.Label bsClass="">
                            {this.props.translator["Recipient's Contact Number"]}
                          </Form.Label>
                          <Field
                            name="recipientContact"
                            component={_inputFieldContact}
                            className="form-control"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ) : null}
                </div>
                {/* <div className="orders">
                {this.renderOfferingList(this.props.offeringsForOrder)}
              </div>*/}

                {/* {_.some(this.props.offeringsForOrder, ['hidePrice', "false"]) ? <div
                className="margin-t-20 border padding-10 margin-b-15 border-radius4 orderBg black f-size-15 fw-600">{this.props.translator['Total Payable']}: <span
                className="f-size-15 green fw-600">{offering[0].currencyCode + " "}{Number(totalOrderPrice).toFixed(2)}</span>
              </div> : null}
*/}

                {this.props.fromOrderNow == "true" ? (
                  <ButtonToolbar bsPrefix=" " className="ordBgtoolbar">
                    {/*<Button bsStyle="info" onClick={()=>{navigationHooks.navigate("/offeringDetails/"+offering[0].offeringId+"/"+offering[0].category+"/"+offering[0].subCategory)}} disabled = {this.props.disableOrderNowButton}>{this.props.translator['Edit Order']}</Button>*/}
                    <div className="ordBgBtn">
                      {this.props.isTemplateUser ? <SplitButton
                        text={this.props.translator["Submit"]} 
                        size={"large"}
                        className="chkSubmitBtn"
                        popupSettings={{
                          popupClass: "chkSubmitPopup",
                          popupAlign: { horizontal: "right", vertical: "top" },
                          anchorAlign: { horizontal: "right", vertical: "bottom" },
                        }}
                        onItemClick={(e) => {
                          if (e.itemIndex === 0) {
                            this.handleItemClick(true);
                          }
                        }}
                        onButtonClick={() => {
                          onSubmitClick();
                          return handleSubmit(this.props.placeOrder)();
                        }}
                      >
                        <SplitButtonItem text="Save As Template" />
                      </SplitButton> :
                      <Button
                        bsStyle="primary"
                        title={this.props.translator["Submit"]}
                        onClick={() => {
                          onSubmitClick();
                          return handleSubmit(this.props.placeOrder)();
                        }}
                        className={((this.props.questionnaireList.fetchingQuestions == true) || (this.props.questionnaireList.fetchingCategories == true) || (this.props.questionnaireList.fetchingCategoriesCount == true)) ? "disabledBtn" : null}
                        disabled={this.props.disableOrderNowButton}
                        ref={e => this.saveObjRef = e}
                      >
                        {this.props.translator["Submit"]}
                      </Button>}
                    </div>
                    {this.props.type == "behalf" ? null : (
                      <Nav.Link
                        bsPrefix=" "
                        className="btn btn-link"
                        title={this.props.translator["Continue Shopping"]}
                        onClick={this.continueShopping}
                        disabled={this.props.disableOrderNowButton}
                      >
                        {this.props.translator["Continue Shopping"]}
                      </Nav.Link>
                    )}
                  </ButtonToolbar>
                ) : (
                  <ButtonToolbar className="ordBgtoolbar">
                    <div className="ordBgBtn">
                      <Button
                        bsStyle="primary"
                        onClick={() => {
                          navigationHooks.navigate("/cart/orderNow/");
                        }}
                        ref={e => this.saveObjRef = e}
                      >
                        {this.props.translator["Proceed to Checkout"]}
                      </Button>
                    </div>
                    {this.props.type == "behalf" ? null : (
                      <Button
                        bsStyle="link"
                        className="btn btn-link"
                        title={this.props.translator["Continue Shopping"]}
                        onClick={this.continueShopping}
                      >
                        {this.props.translator["Continue Shopping"]}
                      </Button>
                    )}
                  </ButtonToolbar>
                )}
              </div>
            </div>
          </div>
            :
            <SaveTemplate 
              handleItemClick={this.handleItemClick} 
              type={this.props.type}
              continueShopping={this.continueShopping}
              disableOrderNowButton={this.props.disableOrderNowButton}
              templateValues={this.props.templateValues}
              offeringDetails={this.props.offeringsForOrder?.[0]}
            />
            }
        </div>
      );
    } else {
      return (
        <div className="stickyArea rBoxStyle">
          <Row>
            <Col xs={12}>
              <div
                className="rPageHeadActBtn d-md-none"
                style={{ marginTop: "-3px" }}
              >
                <ul>
                  <li>
                    <button
                      type="button"
                      title="Minimize the right panel"
                      bsClass=""
                      bsStyle=""
                      className="closerightPanelBtn"
                      onClick={() => {
                        this.props.rightEditPanel(false);
                      }}
                    >
                      <IoClose />
                    </button>
                  </li>
                </ul>
              </div>
              <div className="rPageHeading">
                {/* {this.props.fromOrderNow == "true" ? null : null} */}
                <div className="offNam margin-t-5 margin-b-5">
                  {this.props.translator["Order Details"]}
                </div>
              </div>
            </Col>
          </Row>
          <div className="rBoxGap">
            {this.props.fromOrderNow == "true" ? null : (
              <div className="orders">
                {this.renderOfferingList(
                  this.props.offeringsForOrder,
                  this.props.allServicePrices.itemPriceAndQuantity
                )}
              </div>
            )}

            {_.some(this.props.offeringsForOrder, ["hidePrice", "false"]) ? (
              <div className="totalDv">
                {this.props.translator["Subtotal"]} (
                {this.props.offeringsForOrder.length}
                {this.props.offeringsForOrder.length <= 1
                  ? this.props.translator["item"]
                  : this.props.translator["items"]}
                ) :{" "}
                <span className="">
                  {offering[0].currencyCode + " "}
                  {Number(this.props.allServicePrices.grandTotal()).toFixed(2)}
                </span>
              </div>
            ) : null}
            {this.props.fromOrderNow == "true" ? (
              <ButtonToolbar className="ordBgtoolbar">
                {/*<Button bsStyle="info" onClick={()=>{navigationHooks.navigate("/offeringDetails/"+offering[0].offeringId+"/"+offering[0].category+"/"+offering[0].subCategory)}} disabled = {this.props.disableOrderNowButton}>{this.props.translator['Edit Order']}</Button>*/}
                <div className="ordBgBtn">
                  {this.props.isTemplateUser ? (
                    <SplitButton
                      text={this.props.translator["Submit"]}
                      size={"large"}
                      className="chkSubmitBtn"
                      popupSettings={{
                        popupClass: "chkSubmitPopup",
                        popupAlign: { horizontal: "right", vertical: "top" },
                        anchorAlign: {
                          horizontal: "right",
                          vertical: "bottom",
                        },
                      }}
                      onItemClick={(e) => {
                        if (e.itemIndex === 0) {
                          this.handleItemClick(true);
                        }
                      }}
                    >
                      <SplitButtonItem text="Save As Template" />
                    </SplitButton>
                  ) : (
                    <Button
                      bsStyle="primary"
                      title={this.props.translator["Submit"]}
                      onClick={() => {
                        this.props.placeOrder();
                      }}
                      className={
                        this.props.questionnaireList.fetchingQuestions ==
                          true ||
                        this.props.questionnaireList.fetchingCategories ==
                          true ||
                        this.props.questionnaireList.fetchingCategoriesCount ==
                          true
                          ? "disabledBtn"
                          : null
                      }
                      disabled={this.props.disableOrderNowButton}
                      ref={(e) => (this.saveObjRef = e)}
                    >
                      {this.props.translator["Submit"]}
                    </Button>
                  )}
                </div>
                {this.props.type == "behalf" ? null : (
                  <Button
                    bsStyle="link"
                    className="btn btn-link"
                    onClick={this.continueShopping}
                    disabled={this.props.disableOrderNowButton}
                  >
                    {this.props.translator["Continue Shopping"]}
                  </Button>
                )}
              </ButtonToolbar>
            ) : (
              <ButtonToolbar bsPrefix=" " className="ordBgtoolbar">
                <div className="ordBgBtn">
                  <Link
                    to="/cart/orderNow/"
                    state={{
                      selectedComponentForOffering:
                        selectedComponentForOffering,
                      offeringsForOrder: offeringsForOrder,
                    }}
                  >
                    <Button
                      bsStyle="primary"
                      className="checkoutbutton"
                      title={this.props.translator["Proceed to Checkout"]}
                      ref={(e) => (this.saveObjRef = e)}
                    >
                      {this.props.translator["Proceed to Checkout"]}
                    </Button>
                  </Link>
                </div>
                {this.props.type == "behalf" ? null : (
                  <Button
                    bsStyle="link"
                    className="btn btn-link"
                    title={this.props.translator["Continue Shopping"]}
                    onClick={this.continueShopping}
                  >
                    {this.props.translator["Continue Shopping"]}
                  </Button>
                )}
              </ButtonToolbar>
            )}
          </div>
        </div>
      );
    }
  }
};
OrderingInformation = reduxForm({
  form: "orderingForm"
})(OrderingInformation);

const mapStateToProps = ({
  recipientDeliveryLocations,
  orderingInformationDetails,
  recipientDeliveryCountries,
  questionnaireList,
  articleResultsList,
  insidedResultsList,
  isFetchingDetails,
  isFetchingInsidedDetails,
  insidedTokenDetails,
  offeringDetails,
  recipientDeliveryLocationsIsShared,
  windowSize
}) => {
  let recipientLocations =
    recipientDeliveryLocations === undefined ||
      recipientDeliveryLocations === "recipientDeliveryLocations"
      ? []
      : recipientDeliveryLocations;
  if (articleResultsList && Array.isArray(articleResultsList))
    for (let i = 0; i < articleResultsList.length; i++) {
      let j = i + 1;
      articleResultsList[i].KNOW_ID = j;
    }

  return {
    recipientLocations,
    windowSize,
    // initialValues: orderingInformationDetails,
    recipientDeliveryCountries,
    questionnaireList,
    articleResultsList,
    insidedResultsList,
    isFetchingDetails,
    isFetchingInsidedDetails,
    insidedTokenDetails,
    offeringDetails,
    recipientDeliveryLocationsIsShared
  };
};
export default connect(mapStateToProps, {
  loadRecipientLocations,
  updateOrderingInformation,
  loadRecipientCountries,
  loadArticleResultsForSearchString,
  emptyInsidedReducer,
  loadInsidedArticlesForSearch,
  loadRecipientLocationsIsShared,
  fetchOfferingDetails,
  loadDiscourseArticlesForSearch
})(OrderingInformation);
