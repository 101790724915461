import React from "react";
import { useGetOfferingTemplatesQuery } from "../../../../reducers/offeringReducers/OfferingTemplatesApi";
import { Form } from "react-bootstrap";

const TemplateDropdown = ({
  offering,
  onChangeTemplate,
  className,
  selectedTemplate,
  isPreview = false,
}) => {
  const { data: templateList } =
    useGetOfferingTemplatesQuery({
      companyId: offering.company,
      offeringId: offering.offeringId.toString(),
    });

  const handleTemplateOnChange = (templateId) => {
    const template = templateList.data.find(
      (template) => template?.id?.toString() === templateId?.toString()
    );
    let newTemplate = { ...template };
    try {
      newTemplate.templateJson = JSON.parse(template.templateJson);
    } catch (error) {
      console.log("Error parsing templateJson", error);
      newTemplate.templateJson = {};
    }
    onChangeTemplate(newTemplate, isPreview);
  };
  return (
    <div>
      <Form.Group className={"form-group margin-b-0 " + className}>
        <Form.Select
          onChange={(event) => handleTemplateOnChange(event.target.value)}
          className="form-control"
        >
          <option selected={!selectedTemplate?.id} disabled>
            Fill From Template
          </option>
          {templateList?.data?.map((template) => (
            <option
              key={template.id}
              selected={template.id.toString() === selectedTemplate?.id?.toString()}
              value={template.id}
            >
              {template.templateName}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </div>
  );
};

export default TemplateDropdown;
