
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadCategoryList, currentOfferingDescription } from '../../../../actions/categorylist/categorylistAction';
import { loadComponentPackage } from '../../../../actions/categorylist/componentPackageAction';
import { loadCartCount } from '../../../../actions/common/cartCountAction';
import { bindActionCreators } from 'redux';
import { getGlobalSearchDataForRevamp } from '../../../../actions/globalSearch/globalSearchAction';
import { storeCategoryName } from "../../../../actions/homepage/hamburgerAction";
import ReactStars from 'react-stars';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Card } from 'react-bootstrap';
import ListPagination from "react-js-pagination";
import dummyImage from "_Images/service-icons/service-dummy.png";
import ListLoader from "../../../../components/common/loaders/ListLoader";
import axios from 'axios';
import { GLOBAL } from '_Globals';
import _ from 'lodash';
import Cookies from 'universal-cookie';
import swal from 'sweetalert';
import {LiaHeartSolid} from 'react-icons/lia';
import { navigationHooks } from '../../../../helpers/NavigationHook';
import Swal from 'sweetalert2';
const api = axios.create({
  headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});
const cookies = new Cookies();
let cart_limit = cookies.get('gph');

if (cart_limit) cart_limit = cart_limit.replace('s:', '');
if (cart_limit) cart_limit = cart_limit.substring(0, cart_limit.lastIndexOf('.'));
cart_limit = cart_limit.split("~");

cart_limit = cart_limit[19];
if (cart_limit === "" || cart_limit === "undefined" || cart_limit === undefined)
  cart_limit = 5;

let homepagelocation = cookies.get('gph');

if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
let counter = 0;

class SXGlobalSearchListing extends Component {


  constructor(props) {
    super(props);
    this.state = {
      addCardButtonDisableStatus: false,
      checkoutButtonDisableStatus: false,
      selfCheckoutButtonStatus: false,
      selectedCoreComponentForOffering: [],
      selectedOfferingIds: "",
      activePage: 1,
      index: 0,
      pageSize: 10,
      catData: []
    };
    GLOBAL.startindexSearch = 1;
    GLOBAL.endindexSearch = 10;
    this.onPageChange = this.onPageChange.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.saveOfferingAndComponentToCart = this.saveOfferingAndComponentToCart.bind(this);
  }
  componentWillMount() {
    GLOBAL.searchbarcategory = 'All';
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.globalSearchDataForRevamp && nextProps.globalSearchDataForRevamp !== this.props.globalSearchDataForRevamp) {
      this.setState({ catData: nextProps.globalSearchDataForRevamp.data });
    }
    if((this.props.searchText !== nextProps.searchText || this.props.categoryId !== nextProps.categoryId) && this.state.pageSize != 10) {
      this.setState({pageSize: 10})
    }
  }
  onPageSizeChange(e) {
    this.setState({ pageSize: e.target.value });
    this.props.getGlobalSearchDataForRevamp(this.props.searchText.trim(), this.props.categoryId, 1, e.target.value);
  }

  onPageChange(e) {
    let startindex = GLOBAL.startindexSearch;
    let endindex = GLOBAL.startindexSearch;
    let selectedStartIndex = 1;
    counter = e - 1;
    selectedStartIndex = startindex + this.state.pageSize * counter;
    this.props.getGlobalSearchDataForRevamp(this.props.searchText.trim(), this.props.categoryId, selectedStartIndex, this.state.pageSize);
  }

  onAddFavourite(object, index) {
    let ref = this;
    let arr = [...ref.state.catData.data];
    let obj = ref.state.catData;
    arr[index].isFavourite = 1;
    axios
      .post(GLOBAL.postOfferingFavUrl, {
        entitledUser: "",
        //entitledUser:101,
        offeringId: object,
      })
      .then(function (response) {
        if (response.status == 200) {
          obj.data = arr;
          ref.setState({ catData: obj });
        }
      })
      .catch(function (error) {
      });
  }
  onRemoveFavourite(object, index) {
    let ref = this;
    let arr = [...ref.state.catData.data];
    let obj = ref.state.catData;
    arr[index].isFavourite = 0;
    axios
      .post(GLOBAL.postOfferingFavRemoveUrl, {
        entitledUser: "",
        //entitledUser:101,
        offeringId: object,
      })
      .then(function (response) {
        if (response.status == 200) {
          obj.data = arr;
          ref.setState({ catData: obj });
        }
      })
      .catch(function (error) {
      });
  }

  onOffering(i, j, k, l) {
    //alert("ival::::"+j)
    let categoryName = k;
    if (j != "Suspended" && j != "Under Repair") {
      this.props.storeCategoryName(k);
      categoryName = categoryName.includes("?")
        ? categoryName.replace("?", "")
        : categoryName;
      navigationHooks.navigate(
        "/offeringDetails/" + i + "/" + categoryName + "/" + l
      );
    }
  }

  loadAllComponentsInCart(offeringId) {
    let ref = this;
    ref.setState({ addCardButtonDisableStatus: true });
    ref.setState({ checkoutButtonDisableStatus: true });
    ref.setState({ selfCheckoutButtonStatus: true });
    let str = {};
    str.userId = "";
    str = JSON.stringify(str);
    const offeringsInCartResponse = api.get(GLOBAL.cartOfferingsUrl, {
      headers: { query: str },
    });
    offeringsInCartResponse.then((allOfferingsInCart) => {
      if (allOfferingsInCart.data.length >= cart_limit) {
        swal({
          text: this.props.tr[
            "Maximum number of offerings are already added in cart."
          ],
          button: this.props.tr["OK"],
        });

        // alert(this.props.tr["Maximum number of offerings are already added in cart."]);
        ref.setState({
          checkoutButtonDisableStatus: false,
          selfCheckoutButtonStatus: false,
          addCardButtonDisableStatus: false,
          selectedOfferingIds: "",
        });
      } else {
        if (
          !allOfferingsInCart.data.length == 0 ||
          _.some(allOfferingsInCart.data, { offeringId: offeringId.toString() })
        ) {
          swal({
            text: this.props.tr["Offering already added in cart."],
            button: this.props.tr["OK"],
          });

          // alert(this.props.tr["Offering already added in cart."]);
          ref.setState({
            checkoutButtonDisableStatus: false,
            selfCheckoutButtonStatus: false,
            addCardButtonDisableStatus: false,
            selectedOfferingIds: "",
          });
        } else {
          this.loadComponentCorePackage(offeringId, allOfferingsInCart);
        }
      }
    });
  }

  loadComponentCorePackage(offeringId, allOfferingsInCart) {
    //each time an offering is click in categoryList an new api call with offeringId as parameter wiil be hit to get new response corresponding to offering hit.
    let str = {};
    str.userId = "";
    str.offeringId = offeringId;
    str = JSON.stringify(str);
    api
      .get(GLOBAL.getComponentDetails, { headers: { query: str } })
      .then((response) => {
        if (!response) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => {
        if (response.status == 200) {
          let { selectedCoreComponentForOffering } = this.state;
          selectedCoreComponentForOffering = [];
          response.data.map((componentType) => {
            if (componentType.componentType == "Core") {
              if (
                !_.some(selectedCoreComponentForOffering, [
                  "id",
                  componentType.id,
                ])
              ) {
                selectedCoreComponentForOffering.push(componentType);
              }
            }
          });
          this.setState({ selectedCoreComponentForOffering });
          this.saveOfferingAndComponentToCart(
            offeringId,
            selectedCoreComponentForOffering,
            allOfferingsInCart
          );
        }
      });
  }

  saveOfferingAndComponentToCart(
    offeringId,
    offeringComponentsForCart,
    allOfferingsInCart
  ) {
    let ref = this;
    ref.setState({ addCardButtonDisableStatus: true });
    ref.setState({ checkoutButtonDisableStatus: true });
    ref.setState({ selfCheckoutButtonStatus: true });
    api
      .post(GLOBAL.addOfferingToCartUrl, {
        offeringId: offeringId,
        userId: "",
      })
      .then((response) => {
        if (response.status == 200) {
          if (offeringComponentsForCart.length !== 0) {
            for (let i = 0; i < offeringComponentsForCart.length; i++) {
              api
                .post(GLOBAL.addComponentsToCart, {
                  offeringId: offeringId,
                  componentId: offeringComponentsForCart[i].id,
                })
                .then((response) => {
                  if (response.status === 200) {
                    ref.setState({ addCardButtonDisableStatus: true });
                  } else {
                    ref.setState({
                      addCardButtonDisableStatus: false,
                      checkoutButtonDisableStatus: false,
                      selfCheckoutButtonStatus: false,
                      selectedOfferingIds: "",
                    });
                    swal({
                      text: this.props.tr[
                        "Attached components could not be added, please try again."
                      ],
                      button: this.props.tr["OK"],
                    });

                    // alert(this.props.tr["Attached components could not be added, please try again."])
                  }
                });
            }
          }

          this.props.loadCartCount();
          ref.setState({ addCardButtonDisableStatus: true });
          Swal.fire({
            title: this.props.tr["Item is added to cart"],
            showCancelButton: true,
            confirmButtonText: this.props.tr["Cart"],
            cancelButtonText: this.props.tr["Proceed to Home"]
          })
          .then((result) => {
            if (result.isDismissed && result.dismiss === "cancel") {
              navigationHooks.navigate(homepagelocation);
              ref.setState({
                addCardButtonDisableStatus: false,
                selectedOfferingIds: ""
              });
            } else if(result.isConfirmed) {
              navigationHooks.navigate("/cart");
            }
          });
        } else {
          ref.setState({
            addCardButtonDisableStatus: false,
            checkoutButtonDisableStatus: false,
            selfCheckoutButtonStatus: false,
            selectedOfferingIds: "",
          });
          swal({
            text: this.props.tr[
              "There is a problem adding your recent offering to the cart try again."
            ],
            button: this.props.tr["OK"],
          });

        }
        ref.setState({
          checkoutButtonDisableStatus: false,
          selfCheckoutButtonStatus: false,
          addCardButtonDisableStatus: false,
          selectedOfferingIds: "",
        });
      });
  }


  renderList(categoryData) {
    return categoryData.data.map((categoryListItem, index) => {
      let favouriteexist = categoryListItem.isFavourite;
      let displayText="";
      let displayStr="";
      displayStr = categoryListItem.offeringSummary != null && categoryListItem.offeringSummary != "" ? categoryListItem.offeringSummary : "";
      displayText = displayStr != "" ? displayStr.replace(/(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/ig, '').trim() : "";
      displayText = displayText != "" ? displayText.replace(/\s+/g, ' ') : "";

      let displayName="";
      let displayNameStr="";
      displayNameStr = categoryListItem.offeringName != null && categoryListItem.offeringName != "" ? categoryListItem.offeringName : "";
			displayName = displayNameStr != "" ? displayNameStr.replace(/(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/ig, '').trim() : "";
			displayName = displayName != "" ? displayName.replace(/\s+/g, ' ') : "";

      let getInnerWidth = (innerWidth)=>{
        if (innerWidth > 1535) return 170;
        if (innerWidth > 1367) return 82;
        if (innerWidth > 1279) return 75;
        if (innerWidth > 1023) return 50;
        if (innerWidth > 767) return 38;
        if (innerWidth > 640) return 26;
        if (innerWidth > 539) return 33;
        if (innerWidth > 411) return 50;
        if (innerWidth > 279) return 25;
        return 20;
      }

      let cls = index === this.state.index ? "active item" : "item";
      if (index == 0) {
        this.props.currentOfferingDescription(categoryListItem);
      }

      let windowWidth = this.props.windowSize.width;
      let addtoCartBtn = windowWidth <= 767 ? ((this.props.tr["Add to Cart"].slice(0, 12) + (this.props?.tr["Add to Cart"].length > 12 ? "..." : ''))) : (windowWidth <= 1505 ? ((this.props.tr["Add to Cart"].slice(0, 15) + (this.props?.tr["Add to Cart"].length > 15 ? "..." : ''))) : ((this.props.tr["Add to Cart"].slice(0, 20) + (this.props?.tr["Add to Cart"].length > 20 ? "..." : ''))));

      return (
        <Card
          onClick={() => {
            this.props.currentOfferingDescription(categoryListItem);
          }}
          key={index}
        >
          <div className='card-left'>
          <Card.Link bsPrefix=' ' className='imgSec' 
             onClick={this.onOffering.bind(
              this,
              categoryListItem.offeringId,
              categoryListItem.category,
              categoryListItem.subCategory
            )}
              role="button" title={categoryListItem.offeringName} href={void(0)}>
              <Card.Img bsPrefix=' '
              src={
                categoryListItem.offeringIconTempURL
                  ? categoryListItem.offeringIconTempURL
                  : dummyImage
              }
                className="proimg"
                alt={displayName.slice(0, 10)+"..."}
              /></Card.Link>
          </div>
          <Card.Body>
            <div className="catListSec">
              <div className="bodyCont bodv">
                <Card.Header>
                  <a
                    role="button"
                    id={"CateListing_" + index}
                    href={void(0)}
                    title={categoryListItem.offeringName}
                    onClick={this.onOffering.bind(
                      this,
                      categoryListItem.offeringId,
                      categoryListItem.category,
                      categoryListItem.subCategory
                    )}
                  >
                    {displayName.slice(0, getInnerWidth(this.props.windowSize.width))}{displayName.length > getInnerWidth(this.props.windowSize.width) ? "..." : ''}
                  </a>
                  <span
                    style={{ position: "relative", top: "4px" }}
                    className="icons display-inline-block"
                  >
                    <ReactStars
                      value={categoryListItem.rating}
                      edit={false}
                      count={5}
                      size={18}
                      color1={"gray"}
                      color2={this.props.appliedThemeData?.secondaryColor}
                    />
                  </span>
                  {categoryListItem.hidePrice == "true" ? null : (
                    <span className="margin-l-10 f-size-14">
                      {categoryListItem.currencyCode}&nbsp;
                      {categoryListItem.actualPrice}
                    </span>
                  )}{" "}
                  {categoryListItem.hidePrice == "true" ? null : (
                    <span className="f-size-14">
                      {categoryListItem.billingMethod}
                    </span>
                  )}
                </Card.Header>
                <div className="txtDec">{displayText.slice(0, 250)}{displayText.length > 250 ? "..." : ''}</div>
              </div>
              <div className="headIcon bodv">
                <div className="gap">
                  <a role="button" href={void(0)} id={"CateListingshare_" + index}>
                    <i className="fa fa-share-alt" />
                  </a>
                  {favouriteexist == "0" ? (
                    <a
                      href={void(0)}
                      role="button"
                      id={"CateListingheart_" + index}
                      onClick={this.onAddFavourite.bind(
                        this,
                        categoryListItem.offeringId,
                        index
                      )}
                      title={this.props.tr["Add to Favorites"]}
                    ><LiaHeartSolid/></a>
                  ) : (
                    <a
                      href={void(0)}
                      role="button"
                      id={"CateListingheart_" + index}
                      className="active"
                      onClick={this.onRemoveFavourite.bind(
                        this,
                        categoryListItem.offeringId,
                        index
                      )}
                      title={this.props.tr["Remove from Favorites"]}
                    ><LiaHeartSolid/></a>
                  )}
                </div>
              </div>
              <div className="footBtn bodv">
                <div className='footBtnParnt'>
                {categoryListItem.workItemType === "incidentRequest" ||
                    categoryListItem.workItemType === "standardChange" ||
                    categoryListItem.status === "Proposed" ||
                    categoryListItem.deliveryMode == "Other" ? (
                    <span className='disSpan' title='Disabled'>{addtoCartBtn}</span>
                ) : (
                  <a
                    title={this.props.tr["Add to Cart"]}
                    id={"CateListingcart_" + index}
                    href={void(0)}
                    className={
                      this.state.selectedOfferingIds ==
                        categoryListItem.offeringId
                        ? this.state.addCardButtonDisableStatus
                          ? "disSpan"
                          : "cartBtn"
                        : "cartBtn"
                    }
                    role="button"
                    onClick={() => {
                      let selectedOfferingIds = categoryListItem.offeringId;
                      this.setState({ selectedOfferingIds });
                      this.loadAllComponentsInCart(categoryListItem.offeringId);
                    }}
                  >
                    {addtoCartBtn}
                  </a>
                )}
                <a
                  className="orderBtn"
                  role="button"
                  title={this.props.tr["Order Now"]}
                  href={void(0)}
                  id={"CateListingorder_" + index}
                  onClick={this.onOffering.bind(
                    this,
                    categoryListItem.offeringId,
                    categoryListItem.subStatus,
                    categoryListItem.category,
                    categoryListItem.subCategory
                  )}
                >
                  {this.props.tr["Order Now"].slice(0, 17)}{this.props.tr["Order Now"].length > 17 ? "..." : ''}
                </a>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      );
    });
  }

  renderGridList(categoryList) {
    let size = '';
    if (categoryList && categoryList.data) {
      size = categoryList.data.length;
    }
    let caraousel_grid_count = Math.ceil(size / 4);
    let indents = [];
    let startpoint = "";
    let endpoint = "";
    for (let i = 1; i <= caraousel_grid_count; i++) {
      if (i == 1) {
        startpoint = 0;
        endpoint = 4;
      }
      else {
        startpoint = endpoint;
        endpoint = endpoint + 4;
      }
      indents.push(<div className='homListCard'>{this.renderactualgridlist(startpoint, endpoint)}</div>);
    }

    return (
      <>
        {indents}
      </>

    );

  }

  renderactualgridlist(startpoint, endpoint) {
    return this.state.catData.data.slice(startpoint, endpoint).map((categoryListItem, index) => {
      let cls = (index === 0) ? 'active item' : 'item';
      if (index == 0) {
        this.props.currentOfferingDescription(categoryListItem);
      }
      return (

        <Col md={3}>
          <div className="myMedia active">
            <div className="imgCon"><img src={categoryListItem.offeringIconTempURL ? categoryListItem.offeringIconTempURL : dummyImage} className="border-radius2 img-responsive" alt={categoryListItem.offeringName} /></div>
            <Card className={cls} onClick={() => { this.props.currentOfferingDescription(categoryListItem); this.props.loadComponentPackage(categoryListItem.offeringId) }}>
              <Card.Body>
                <Card.Header><Link to={`/offeringDetails/${categoryListItem.offeringId}/${categoryListItem.category.includes("?") ? categoryListItem.category.replace("?", "") : categoryListItem.category}/${this.props.subcatname}`}>{categoryListItem.offeringName}</Link></Card.Header>
                <div className="starRtn text-c">
                  <span className="icons"><ReactStars count={categoryListItem.rating} size={24} color1={this.props.appliedThemeData?.secondaryColor} /></span>
                </div>
                <div className="Listprice margin-b-20 text-c"><span className="blue"> {categoryListItem.currencyCode}&nbsp;{categoryListItem.actualPrice}</span>, <span className="blue">{categoryListItem.billingMethod}</span></div>
                <p>{categoryListItem.offeringSummary}</p>
                <div className="vDet"><Link to={`/offeringDetails/${categoryListItem.subCategory}/${this.props.catname}/${this.props.subcatname}`}><a href={void(0)}>View Details <i className="fa fa-angle-double-right"></i></a></Link></div>
              </Card.Body>
            </Card>
          </div>
        </Col>

      );
    });
  }
  render() {
    let meta = this.state.catData?.meta;
    if (this.props.revampGlobalSearchShowLoader == true) {
      return (
        <div><ListLoader /></div>
      );
    }
    if (this.props.globalSearchDataForRevamp.length == 0) {
      return (
        <div>
          {this.props.tr["No Data Found"]}
        </div>
      );
    }

    if (this.props.type == "list") {
      return (
        <div id="categoryListingTabGlobalSearList" className="cateList categoryListingTab">
          <div className='homListCard'>{this.renderList(this.state.catData)}</div>
          {meta ?
            <div className="nBotPagina">
              <div className="nShow">
                <div className="margin-r-10" componentClass={Form.Label}>{this.props.tr['Show']}:</div>
                <div aria-label='Show Count' role="contentinfo">
                <Form.Select aria-label={this.props.tr['Show']} value={meta ? meta.limit : 10} onChange={this.onPageSizeChange}>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </Form.Select></div>
              </div>
              <nav aria-label='Pagination' className='display-inline-block'>
              <ListPagination
                prevPageText="Prev"
                nextPageText="Next"
                firstPageText={<i className="glyphicon glyphicon-menu-left" />}
                lastPageText={<i className="glyphicon glyphicon-menu-right" />}
                activePage={meta ? meta.currentPage : 1}
                itemsCountPerPage={this.state.pageSize}
                totalItemsCount={meta ? meta.rowCount : 1}
                onChange={this.onPageChange}
              /></nav>
            </div>
            : null}
        </div>
      );
    }
    if (this.props.type == "grid") {
      return (

        <div id="categoryListingTabGlobalSearGrid" className="gridTab categoryListingTab">

          {this.renderGridList(this.state.catData)}

          <Row className="margin-t-10">
            <Col md={3}>
              <Row>
                <Col className="padding-r-0 padding-t-5" componentClass={Form.Label} sm={4}>Show</Col>
                <Col sm={4} className="padding-0"><Form.Select>
                  <option value="10">4</option>
                  <option value="25">8</option>
                  <option value="50">12</option>
                  <option value="100">16</option>
                  <option value="100">20</option>
                </Form.Select></Col>
              </Row>
            </Col>
            <Col md={9}>
              <div className="dataShow text-r">
                <ListPagination className="margin-0" prev next boundaryLinks items={10} maxButtons={3} activePage={this.state.activePage} />
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  }
}
const mapStateToProps = ({ globalSearchDataForRevamp, spcmReducer, revampGlobalSearchShowLoader,windowSize,appliedThemeData}) => {
  return {
    globalSearchDataForRevamp,
    tr: spcmReducer.tr,
    revampGlobalSearchShowLoader: revampGlobalSearchShowLoader.loading,
    windowSize,
    appliedThemeData: appliedThemeData.data
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ loadCategoryList, currentOfferingDescription, loadComponentPackage, loadCartCount, storeCategoryName, getGlobalSearchDataForRevamp }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SXGlobalSearchListing);
