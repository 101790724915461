
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import axios from 'axios';
import Spinner from 'react-spinkit';
import { GLOBAL } from "_Globals";
import {IoClose} from "react-icons/io5";

const api = axios.create({
  headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});

class XsmServiceQuickView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      breakFixServiceDetails: []
    };
  }
  setData(){
    if (this.props.offeringId == "" || this.props.offeringId !== undefined) {
      var str = {};
      str.offeringId = this.props.offeringId;
      str = JSON.stringify(str);
      const request = api.get(GLOBAL.breakFixServiceDetailsDataUrl, { headers: { 'query': str } });
      request.then((response) => {
        this.setServiceInfo(response);
      });
    }
  }
  componentWillMount() {
    this.setData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.offeringId !== prevProps.offeringId) {
      this.setData();
    }
  }

  setServiceInfo(service) {
    this.setState({
      breakFixServiceDetails: service.data
    });
    //console.log(this.state.breakFixServiceDetails.data.offeringName)
  }

  //===============

  //===============

  render() {
    // if (this.state.breakFixServiceDetails.length == 0) {
    //   //alert("length"+this.state.breakFixServiceDetails.length)
    //   return (
    //     <Spinner spinnerName="three-bounce" />
    //   );
    // }
    return (
      <div className="margin-b-20">
        <div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
            <ul>
                <li>
                <button
                    type='button'
                    title="Minimize the right panel"
                    bsClass=""
                    bsStyle=""
                    className="closerightPanelBtn"
                    onClick={() => {
                    this.props.rightEditPanel(false);
                    this.props.changeActionView("");
                    this.props.changeRightView("allClose");
                    }}
                >
                    <IoClose />
                </button>
                </li>
            </ul>
        </div>
        <div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">Service Quick View</div></div>
        <div className='rBoxGap'>
        {
          this.state.breakFixServiceDetails.length == 0 ? <Spinner spinnerName="three-bounce" />
            :

            // <ListGroup bsClass="" className="ListDetails">
            //   <ListGroup.Item bsClass=""><span className="labSpan">Service Name-</span>{this.state.breakFixServiceDetails[0].offeringName}</ListGroup.Item>
            //   <ListGroup.Item bsClass=""><span className="labSpan">Description-</span><span dangerouslySetInnerHTML={{ __html: this.state.breakFixServiceDetails[0].description }} /></ListGroup.Item>
            //   <ListGroup.Item bsClass=""><span className="labSpan">Category-</span>{this.state.breakFixServiceDetails[0].category}</ListGroup.Item>
            //   <ListGroup.Item bsClass=""><span className="labSpan">Subcategory-</span>{this.state.breakFixServiceDetails[0].subCategory}</ListGroup.Item>
            //   <ListGroup.Item bsClass=""><span className="labSpan">Company-</span>{this.state.breakFixServiceDetails[0].companyName}</ListGroup.Item>
            // </ListGroup>

            <ListGroup variant='flush'>
              <ListGroup.Item className="rwTable" bsClass="" style={{ 'border-top': 'none' }}><span className="rwKey">Service Name</span><span className="rwVal">{this.state.breakFixServiceDetails[0].offeringName}</span></ListGroup.Item>
              <ListGroup.Item className="rwTable" bsClass=""><span className="rwKey">Category</span><span className="rwVal">{this.state.breakFixServiceDetails[0].category}</span></ListGroup.Item>
              <ListGroup.Item className="rwTable" bsClass=""><span className="rwKey">Subcategory</span><span className="rwVal">{this.state.breakFixServiceDetails[0].subCategory}</span></ListGroup.Item>
              <ListGroup.Item className="rwTable" bsClass=""><span className="rwKey">Company</span><span className="rwVal">{this.state.breakFixServiceDetails[0].companyName}</span></ListGroup.Item>
              <ListGroup.Item className="rwTable" bsClass=""><span className="rwKey">Status</span><span className="rwVal">{this.state.breakFixServiceDetails[0].status}</span></ListGroup.Item>
              {/* <ListGroup.Item bsClass="" className="rwTable" style={{ 'border-bottom': 'none', "paddingTop": "20px" }}>
                <div className="rwDynaCls"><span dangerouslySetInnerHTML={{ __html: this.state.breakFixServiceDetails[0].description }} /></div>
              </ListGroup.Item> */}
            </ListGroup>

          // <ButtonToolbar className="margin-t-15"><Button bsStyle="primary">Component</Button><Button bsStyle="primary">Chain Roles</Button><Button bsStyle="primary">Details</Button></ButtonToolbar>

        }
        </div>
      </div>
    );
  }
}

export default XsmServiceQuickView;
