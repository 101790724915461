
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from 'react';
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import ListGroup from "react-bootstrap/ListGroup";
import { useSelector } from 'react-redux';
import ReactReadMoreLess from '../../../../helpers/ReactReadMoreLess';
import { sanitizeHTMLForDocExport } from '../../../common/helper';

function NotificationAccord (props){
    const [showMsg, setShowMsg] = useState(false);
    const [commaPosition, setCommaPosition] = useState(null);
    const showLoader_ne = useSelector(state => state.showLoader_ne.loading);

    const messageHandler = (ind) => {
         setShowMsg(!showMsg);
    }
    
    useEffect(() => {
        const notifEmailList = props.notif?.TO || props.notif?.CC || props.notif?.BCC;
        if(notifEmailList){
          const position = notifEmailList.indexOf(',');
          if (position > 0) {
            setCommaPosition(position);
          }
        }
    }, [props.notif]);

    return (
        <>
            {!showLoader_ne &&
                <Accordion.Item eventKey={props.index} >
                    <Accordion.Header>
                        <div className='d-block w-100' title={props.notif?.SUBJECT}>
                            <p className='notifAccrdHead'>{props.notif.SUBJECT}</p>
                            <div className="color-lightgrey f-size-12" title={props.hours >= 24 ? "" : props.endDateStr}>{props.hours >= 24 ? props.endDateStr : props.timeago}</div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body collapsible>

                    <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable" style={{ borderTop: 'none' }}>
                            <span className="rwKey">
                                {"TO"}
                            </span>
                            <span className="rwVal">
                                {/* {props.notif?.TO} */}
                                {commaPosition > 0 ? 
                                <ReactReadMoreLess 
                                    charLimit={commaPosition} readMoreText="more" readLessText="...less"
                                    content={props.notif?.TO} separator=","
                                /> : props.notif?.TO}
                            </span>
                        </ListGroup.Item>
                        <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                            <span className="rwKey">
                                {"CC"}
                            </span>
                            <span className="rwVal">
                                {/* {props.notif?.CC} */}
                                {commaPosition > 0 ? 
                                <ReactReadMoreLess 
                                    charLimit={commaPosition} readMoreText="more" readLessText="...less"
                                    content={props.notif?.CC} separator=","
                                /> : props.notif?.CC}
                            </span>
                        </ListGroup.Item>
                        <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                            <span className="rwKey">
                                {"BCC"}
                            </span>
                            <span className="rwVal">
                                {/* {props.notif?.BCC} */}
                                {commaPosition > 0 ? 
                                <ReactReadMoreLess 
                                    charLimit={commaPosition} readMoreText="more" readLessText="...less"
                                    content={props.notif?.BCC} separator=","
                                /> : props.notif?.BCC}
                            </span>
                        </ListGroup.Item>
                        <ListGroup.Item bsPrefix=" " bsClass="" className="rwTable">
                            <span className="rwKey">
                                {"MESSAGE"}
                            </span>
                            <span className="rwVal">
                                <Button role="button" bsSize="xsmall" className="myBt smBtnCls" title={showMsg ? "Hide Full Message" : "Show Full Message"} onClick={() => { messageHandler(props.index) }} >{showMsg ? "Hide Full Message" : "Show Full Message"}</Button>
                              

                            </span>
                        </ListGroup.Item>



                        {showMsg ?
                            (
                                <div className='margin-t-10' dangerouslySetInnerHTML={{
                                    __html: sanitizeHTMLForDocExport(props.notif.CONTENT)
                                }}>
                                </div>
                            ) : null}

                    </Accordion.Body>
                </Accordion.Item>
            }
        </>
    )
}



export default NotificationAccord;