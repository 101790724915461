
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef } from "react";
import { Form, Row, Col, Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Swal from "sweetalert2";
import ListLoader from "_Commons/loaders/ListLoader";
import { updateWIBSearchData as updatePayload } from '../../../../utils/common';
import {setIncidentData, settingSlaTab} from "../../../../actions/breakFix/quickViewAction";
import axios from "axios";
import { navigationHooks } from '../../../../helpers/NavigationHook';
import { getslaViewListAction } from "../../../../actions/investigation/investigationAction";
import Pagination from "react-js-pagination";
import { GLOBAL } from "_Globals";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
let homepagelocation = cookies.get('gph');
if (homepagelocation) homepagelocation = homepagelocation.replace('s:', '');
if (homepagelocation) homepagelocation = homepagelocation.substring(0, homepagelocation.lastIndexOf('.'));
homepagelocation = homepagelocation.split("~");
let dateformatCookie = homepagelocation[2];
let result = dateformatCookie.substr(0, 10);

function SlaList(props) {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const showLoader = useSelector((state) => state.commonLoader.isLoading);
    const slaColor = useSelector((state) => state.wibSlaColors);
    const slaData = useSelector((state) => state.slaViewListRed);
    const dispatch = useDispatch();
    useEffect(() => {
        props.setLimit(10)
        props.setPage(0)

    }, [])

    const prepareIncidentPayload = (partialincidentPayload, id, type, incidentEmployeeID ='')=> {
                 
        let isINCnumber =
           id.substring(0, 3) === "INC"
                ? "breakfixNumber"
                : "requestNumber";
        let incidentSearchIDtype = id
            ? isINCnumber
            : "impactedUserid";
        // if (this.state.incident_search_all || id) {
        if (id) {
            partialincidentPayload.searchByList = incidentSearchIDtype;
            partialincidentPayload.multipleValueList = id
                ? id
                : incidentEmployeeID;
        } else {
            partialincidentPayload.searchByList = incidentSearchIDtype + ",spGroupid";
            partialincidentPayload.multipleValueList =
                // (incidentCondition ? incidentEmployeeID :id) +
               id +
                "|" +
                this.props.group_id;
        }
      
        return partialincidentPayload;
    }

    const getIncidentModuleData = (incidentPayload, id)=>{
        const payload = updatePayload(incidentPayload, 'incident');
        axios
            .post("/api/breakfixQuickViewAction/search", payload)
            .then((responseIncident) => {
                if (responseIncident.data.data.meta.recordCount === 0) {
                    Swal({
                        text: tr["No matching Work Item record found"],
                        button: tr["OK"],
                    });
                } else if (
                    // this.state.searchItem === "Request ID" &&
                    id &&
                    responseIncident.data.data.meta.recordCount === 1
                ) {
                    // GLOBAL.slaHeadEnable = true
                    navigationHooks.navigate('/editBreakFix/' + responseIncident.data.data.data[0].breakfixId);

                    // dispatch(settingSlaTab(true));

                } else {
                   
                    // dispatch(setIncidentData(responseIncident));              

                }
            })
            .catch((errorIncident) => { });
    };

    const prepareFulfillmentPayload = (
        partialfullfillmentPayload, id, type,
        fulfillmentEmployeeID = ""
    ) => {
        // let fulfillmentCondition =
        //     this.state.searchItem === "Requestor Email" ||
        //     this.state.searchItem === "Requestor Employee ID";
        let isITMnumber =
          id.substring(0, 3) === "ITM"
                ? "itemNumber"
                : "orderNumber";
        let fulfillmentSearchIDtype = id
            ? isITMnumber  
            : "requesterId";
        // if (this.state.fulfillment_search_all) {
        if (id) {
            partialfullfillmentPayload.searchByList = fulfillmentSearchIDtype;
            partialfullfillmentPayload.multipleValueList = id
            ? id
            : fulfillmentEmployeeID
        } else {
            partialfullfillmentPayload.searchByList =
                fulfillmentSearchIDtype + ",asgGroupid";
            partialfullfillmentPayload.multipleValueList =
                // (fulfillmentCondition
                //     ? fulfillmentEmployeeID
                //     : id) +
               id +
                "|" +
                this.props.group_id;
        }        
        return partialfullfillmentPayload;
    }

   const getFulfillmentModuleData = (fulfillmentPayload,id) => {
        const payload = updatePayload(fulfillmentPayload, 'fulfillment');
        axios
            .post("/api/orderFullfilmentQuickViewAction/search", payload)
            .then((responseFulfillment) => {
                if (responseFulfillment.data.data.meta.recordCount === 0) {
                    Swal({
                        text: tr["No matching Work Item record found"],
                        button: tr["OK"],
                    });
                } else if (
                    // this.state.searchItem === "Request ID" &&
                    id &&
                    responseFulfillment.data.data.meta.recordCount === 1
                ) {
                
                    navigationHooks.navigate('/fulFillment/' + responseFulfillment.data.data.data[0].itemId);

                } else {                    
                    // dispatch(setFulfillmentData(responseFulfillment));
                }
            })
            .catch((errorFulfillment) => { });
    }

    const getTaskModuleData = (taskHeader,id) => {
        axios
            .get("/api/tasks/", { headers: { query: JSON.stringify(taskHeader) } })
            .then((responseTask) => {
                if (responseTask.data.data.length === 1) {
                   
                    navigationHooks.navigate('/editTask/' + responseTask.data.data[0].taskCode + '/' + responseTask.data.data[0].clientId + '/' + responseTask.data.data[0].assignedToGroupId);

                } else {
                    Swal({
                        text: tr["No matching Work Item record found"],
                        button: tr["OK"],
                    });
                }
            })
            .catch((errorTask) => { });
    }

    const prepareProblemPayload = (partialproblemPayload,id, problemEmployeeID = "") => {
      
        let isPRBnumber =
            id.substring(0, 3) === "PRB"
                ? "investigationNumber"
                : "requestNumber";
        let problemSearchIDtype = id ? isPRBnumber : "impactedUserid";
        // if (this.state.problem_all_payload) {
        if (id) {
            partialproblemPayload.searchByList = problemSearchIDtype;
            partialproblemPayload.multipleValueList = id
            ? id
            :problemEmployeeID
        } else {
            partialproblemPayload.searchByList =
                problemSearchIDtype + ",asgPmGroupid";
            partialproblemPayload.multipleValueList =
                // (problemCondition ? problemEmployeeID : id) +
                id +
                "|" +
                this.props.group_id;
        }
        return partialproblemPayload;
    }

    const getProblemModuleData = (problemPayload,id) => {
        const payload = updatePayload(problemPayload, 'problem');
        axios
            .post("/serviceManagement/rest/investigationList", payload)
            .then((responseProblem) => {
                if (responseProblem.data.meta.recordCount === 0) {
                    Swal({
                        text: tr["No matching Work Item record found"],
                        button: tr["OK"],
                    });
                } else if (
                    // this.state.searchItem === "Request ID" &&
                    id && 
                    responseProblem.data.meta.recordCount === 1
                ) {
                    navigationHooks.navigate('/investigationEdit/' + responseProblem.data.data[0].investigationId);

                } else {
                   
                    // dispatch(setProblemData(responseProblem));
                }
            })
            .catch((errorProblem) => { });
    }
    const prepareChangePayload = (partialChangePayload, id, changeEmployeeID = "")=>{
        // let changeCondition =
        //     this.state.searchItem === "Requestor Email" ||
        //     this.state.searchItem === "Requestor Employee ID";
        let isRFCnumber =
            id.substring(0, 3) === "RFC"
                ? "changeNumber"
                : "requestNumber";
        // let changeSearchIDtype = changeCondition ? "requesterId" : isRFCnumber;
        let changeSearchIDtype = id ? isRFCnumber : "requesterId";
        // if (this.state.change_all_payload) {
        if (id) {
            partialChangePayload.searchByList = changeSearchIDtype;
            // partialChangePayload.multipleValueList = changeCondition
            partialChangePayload.multipleValueList = id
            ? id
            : changeEmployeeID
        } else {
            partialChangePayload.searchByList =
                changeSearchIDtype + ",asgPmGroupid";
            partialChangePayload.multipleValueList =
                // (changeCondition ? changeEmployeeID : id) +
               id +
                "|" +
                this.props.group_id;
        }
        return partialChangePayload;
    }

    const getChangeModuleData = (changePayload,id) => {
        const payload = updatePayload(changePayload, 'change');
        axios
            .post("/serviceManagement/rest/getChangeManagementList", payload)
            .then((responseChange) => {
                if (responseChange.data.meta.recordCount === 0) {
                    Swal({
                        text: tr["No matching Work Item record found"],
                        button: tr["OK"],
                    });
                } else if (
                    // this.state.searchItem === "Request ID" &&
                    id &&
                    responseChange.data.meta.recordCount === 1
                ) {
                    
                    navigationHooks.navigate('/editChange/' + responseChange.data.data[0].changeId);

                } else {
                    // this.props.selectedGlobalSearchModule("changeManagement");
                    // if (this.state.change_all_payload) {
                    //     this.props.selectedFilterTab(3);
                    //     this.props.handleSelect("changeManagement", "all");
                    // } else {
                    //     this.props.selectedFilterTab(2);
                    //     this.props.handleSelect(
                    //         "changeManagement",
                    //         "group",
                    //         this.props.changeImplentationRole
                    //     );
                    // }
                    // dispatch(setChangeData(responseChange));
                }
            })
            .catch((errorChange) => { });
    }

    const selectWIBModule = (id,type) =>{
            let commonPayload = {
                patternList: "in",
                size: 10,
                currentPage: 0,
                sortBy: "createdOn",
                orderBy: "desc",
                isNestedRequired: "N",
            };

        switch (type) {
                case "Task":
                    let taskPayload =  { taskCode: id, "requestType": "globalSearch", assignedToGroupId: [], };
                    getTaskModuleData(taskPayload);
                    break;
                case "Fulfillment":
                    commonPayload = prepareFulfillmentPayload(commonPayload, id, type);
                    getFulfillmentModuleData(commonPayload, id);
                    break;
                case "Incident":
                    commonPayload = prepareIncidentPayload(commonPayload, id, type);
                    getIncidentModuleData(commonPayload, id);
                    break;
                case "Problem":
                    commonPayload = prepareProblemPayload(commonPayload, id, type);
                    getProblemModuleData(commonPayload, id);
                    break;
                case "Change":
                    commonPayload = prepareChangePayload(commonPayload, id, type);
                    getChangeModuleData(commonPayload, id);
                    break;
            }
        
    }
    const renderSLAListWIB = (slaListdata)=>{
        const getSlaColor = (status) => {
            let colorsObject = {};
            Object.keys(slaColor).forEach((value) => {
                colorsObject[value.toLowerCase()] = slaColor[value];
            })
            if (typeof colorsObject == "string" || typeof colorsObject == "object") {
                if (status == "ALREADY BREACHED" || status == "BREACHED") {
                    return colorsObject['breached'];
                } else if (colorsObject.hasOwnProperty(status.toLowerCase())) {
                    return colorsObject[status.toLowerCase()];
                }
            }
        }
    
    return slaListdata.map((data,index)=>{
        // console.log("math", Math.floor(data.pausedTime / 60));
        let status = ((data.status == "ALREADY BREACHED" || data.status == "BREACHED")?
            (<><div className={`circle8`} style={{ backgroundColor: `${data.status ? getSlaColor(data.status) : ""}` }} title={"Color"}>
            </div><span>{"Breached"}</span></>)
            :
            (<><div className={`circle8`} style={{ backgroundColor: `${data.status ? getSlaColor(data.status) : ""}` }} title={"Color"}>
            
        </div><span>{data.status == "IN PROGRESS"? "In Progress" : data.status == "MET"? "Met": data.status == "PAUSE"? "Pause": data.status}</span></>))
        
        return (
            <tr>
                <td className="hyperLinkWIB" title={'Link'} onClick={() => { selectWIBModule(data.ticketId, data.moduleName) }}>{data.ticketId}</td>
                <td>
                    <div className='showdv414'>
                        <div className='keydv414'>{tr["SLA Name"]}</div>
                        <div className='valdv414'>
                            {data.slaName}
                        </div>
                    </div>
                </td>
                <td>
                    <div className='showdv414'>
                        <div className='keydv414'>{tr["Type"]}</div>
                        <div className='valdv414'>
                            {data.moduleName}
                        </div>
                    </div>
                </td>
                <td>
                    <div className='showdv414'>
                        <div className='keydv414'>{tr["Target For"]}</div>
                        <div className='valdv414'>
                            {data.slaType}
                        </div>
                    </div>
                </td>
                <td>
                    <div className='showdv414'>
                        <div className='keydv414'>{tr["Status"]}</div>
                        <div className='valdv414'>
                            {status}
                        </div>
                    </div>
                </td>
                <td>
                    <div className='showdv414'>
                        <div className='keydv414'>{tr["Target Time(Mins)"]}</div>
                        <div className='valdv414'>
                            {data.slaGoalTime}
                        </div>
                    </div>
                </td>
                {/* <td>{moment.unix(data.slaGoalTime).format("mm")}</td> */}
                <td>
                    <div className='showdv414'>
                        <div className='keydv414'>{tr["Time Left(Mins)"]}</div>
                        <div className='valdv414'>
                            {data.timeLeft}
                        </div>
                    </div>
                </td>
                {/* <td>{moment.unix(data.timeLeft).format("mm")}</td> */}
                <td>
                    <div className='showdv414'>
                        <div className='keydv414'>{tr["Time Left %"]}</div>
                        <div className='valdv414'>
                            {data.timeLeftPercentage}
                        </div>
                    </div>
                </td>
                {/* <td>{data.pausedTime}</td> */}
                {/* <td>{moment.unix(data.pausedTime).format("mm")}</td> */}
                {/* <td>{Math.floor((data.pausedTime + data.totalPausedTime) / 60)}</td> */}
                <td>
                    <div className='showdv414'>
                        <div className='keydv414'>{tr["Total Pause Time(Mins)"]}</div>
                        <div className='valdv414'>
                            {Math.floor((parseInt(data.pausedTime, 10) + parseInt(data.totalPausedTime, 10)) / 60)}
                        </div>
                    </div>
                </td>
                {/* <td>{data.timeElapsed}</td> */}
                {/* <td>{moment.unix(data.timeElapsed).format("mm")}</td> */}
                <td>
                    <div className='showdv414'>
                        <div className='keydv414'>{tr["Total Elapse Time(Mins)"]}</div>
                        <div className='valdv414'>
                            {Math.floor(data.timeElapsed / 60)}
                        </div>
                    </div>
                </td>
                {/* <td>{data.dueDate}</td> */}
                {dateformatCookie == "dd-MM-yyyy HH:mm:ss" ?
                    <td>
                        <div className='showdv414'>
                            <div className='keydv414'>{tr["Due Date"]}</div>
                            <div className='valdv414'>
                                {data.dueDate != null ? moment.unix(data.dueDate).format("DD-MM-YYYY HH:mm:ss") : ''}
                            </div>
                        </div>
                    </td>
                    : dateformatCookie == "yyyy-MM-dd HH:mm:ss" ?
                        <td>
                            <div className='showdv414'>
                                <div className='keydv414'>{tr["Due Date"]}</div>
                                <div className='valdv414'>
                                    {data.dueDate != null ? moment.unix(data.dueDate).format("YYYY-MM-DD HH:mm:ss") : ''}
                                </div>
                            </div>
                        </td>
                        :
                        <td>
                            <div className='showdv414'>
                                <div className='keydv414'>{tr["Due Date"]}</div>
                                <div className='valdv414'>
                                    {data.dueDate != null ? moment.unix(data.dueDate).format("MM-DD-YYYY HH:mm:ss") : ''}
                                </div>
                            </div>
                        </td>
                }
            </tr>
        )
    })

    }

    const handleSizePerPageChange = (event) => {
        // props.setrowIndex(0);
        props.setLimit(event.target.value);
        // props.setRightSideVisible(false);

        dispatch(getslaViewListAction(event.target.value, props.page, props.module));

    };

    const handlePageChange = (event) => {
        props.setPage(event);
        // props.setrowIndex(0);
        // props.setRightSideVisible(false);
        // console.log("EVENTWIB",event);
        dispatch(getslaViewListAction(props.Limit, event-1,  props.module));

    };
    let meta = slaData.meta;

    return (
        <>
            <div className="respondv">
                <div
                    className="tableRgtBor table-responsive"
                >
                <Table
                    striped
                    bordered
                    condensed
                    hover
                    className="tableView nowrapWhright"
                    style={{ marginTop: "0px" }}>
                    <thead className="hiddenBe414">
                        <tr>
                            <th>{tr["Work Item ID"]}</th>
                            <th>{tr["SLA Name"]}</th>
                            <th>{tr["Type"]}</th>
                            <th>{tr["Target For"]}</th>
                            <th>{tr["Status"]}</th>
                            <th>{tr["Target Time(Mins)"]}</th>
                            <th>{tr["Time Left(Mins)"]}</th>
                            <th>{tr["Time Left %"]}</th>
                            <th>{tr["Total Pause Time(Mins)"]}</th>
                            <th>{tr["Total Elapse Time(Mins)"]}</th>
                            <th>{tr["Due Date"]}</th>
                        </tr>
                    </thead>
                        <tbody>
                            {showLoader ? (
                                <tr>
                                    <td colSpan="15" style={{ textAlign: "center" }}>
                                        <ListLoader />
                                    </td>
                                </tr>
                            ) : slaData?.data?.length == 0 || typeof slaData.data === 'undefined' ? (
                                <tr>
                                    <td colSpan="15" style={{ textAlign: "center" }}>
                                        {tr["There is no matching data available"]}
                                    </td>
                                </tr>
                            ) : (
                                    renderSLAListWIB(slaData?.data)
                            )}
                        </tbody>
                </Table >
          </div >
          </div>
            {slaData.data && slaData.data.length !== 0 ? (
                <div className="nBotPagina">
                    <div className="nShow">
                        <div className="margin-r-10" componentClass={Form.Label}>
                            {tr["Show"]}:
                        </div>
                        <div aria-label="Show Count" role="contentinfo">
                            <Form.Select
                                aria-label={tr["Show"]}
                                className=""
                                onChange={handleSizePerPageChange}
                                value={props.Limit}
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </Form.Select>
                        </div>
                    </div>
                    <nav aria-label="Pagination" className="display-inline-block">
                        <Pagination
                            prevPageText={tr["Prev"]}
                            nextPageText={tr["Next"]}
                            firstPageText={<i className="glyphicon glyphicon-menu-left" />}
                            lastPageText={<i className="glyphicon glyphicon-menu-right" />}
                            activePage={meta ? Number(meta.currentPage)+1 : 1}
                            itemsCountPerPage={props.Limit}
                            totalItemsCount={meta ? meta.recordCount : 1}
                            // pageRangeDisplayed={props.panelResize <= 42 ? 2 : 5}
                            onChange={(e) => handlePageChange(e)}
                        />
                    </nav>
                </div>
            ) : (
                <span />
            )}
        </>
    )

}

export default SlaList;