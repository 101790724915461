/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setWipSearchApply } from "../../../actions/globalSearch/globalSearchAction";

import Cookies from "universal-cookie";
const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

const Breadcrumbs = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setSearchParam = () => {
    dispatch(setWipSearchApply(false));
  };
  const tr = useSelector((state) => state.spcmReducer.tr);
  return (
    <Breadcrumb>
      <Breadcrumb.Item
        href={void 0}
        onClick={() => {
          navigate(homepagelocation);
          setSearchParam();
        }}
      >
        {tr["Home"]}
      </Breadcrumb.Item>
      {props.parentPageurl && props.parentPageName ? (
        <Breadcrumb.Item 
          href="#" 
          onClick={() => navigate(props.parentPageurl)}
        >
          {tr[props.parentPageName] || props.parentPageName}
        </Breadcrumb.Item>
      ) : null}
      <Breadcrumb.Item active>
        {tr[props.activePageName] || props.activePageName}
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default Breadcrumbs;
