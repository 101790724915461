/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ListLoader from "../common/loaders/ListLoader";
import TableComponent from "../common/ReactTable/TableComponent";

const SystemConfigListview = (props) => {
  //const mailboxConfigList = useSelector((state) => state.systemCofigList);
  // const mailboxConfigList = useSelector((state) => state.systemCofigList.systemCofigList);
  // const surveyConfigList = useSelector((state) => state.systemCofigList.surveyConfigList);
  const mailboxConfigList = useSelector((state) => state.systemCofigList.systemCofigList.mailboxConfigList);
  const surveyConfigList = useSelector((state) => state.systemCofigList.systemCofigList.surveyConfigList);
  const showLoader = useSelector((state) => state.showLoader.loading);
  const translator = useSelector((state) => state.spcmReducer.tr);
  const [mailboxData, setMailBoxData] = useState([]);
  const dispatch = useDispatch();

  const tableColumns = [
    {
      name: translator["Company"],
      selector: (row) => row.COMPANY_NAME,
      sortable: true,
    },
    {
      name: translator["Mailbox"],
      selector: (row) => row.MAILBOX_ID,
      sortable: true,
    },
    {
      name: translator["Vendor"],
      selector: (row) => row.MAILBOX_PROVIDER,
      sortable: true,
    },
    {
      name: translator["Service"],
      selector: (row) => row.OFFERING_NAME,
      sortable: true,
    },
    {
      name: translator["Status"],
      selector: (row) => row.STATUS,
      sortable: true,
    },
  ];

  const tableColumn2 = [
    {
      name: translator["Company"],
      selector: (row) => row.COMPANY_NAME,
      sortable: true,
    },
    {
      name: translator["Mailbox"],
      selector: (row) => row.MAILBOX_ID,
      sortable: true,
    },
    {
      name: translator["Vendor"],
      selector: (row) => row.MAILBOX_PROVIDER,
      sortable: true,
    },
    {
      name: translator["Status"],
      selector: (row) => row.STATUS,
      sortable: true,
    },
  ];

  // useEffect(() => {
  //   if (mailboxConfigList.length > 0) {
  //     setMailBoxData(mailboxConfigList.map((obj) => {
  //       obj.STATUS = obj.STATUS === 1 ? "Active" : "Inactive";
  //       return obj;
  //     }));
  //   }
  // }, [mailboxConfigList]);

  const formatData = (dataList) => {
    return dataList.map((obj) => {
  
      let status = obj.STATUS;
      if (status === "Inactive" || status === "Active") {
        obj.STATUS = status === "Active" ? "Active" : "Inactive";
      } else {
        const numStatus = Number(status);
        obj.STATUS = numStatus === 1 ? "Active" : "Inactive";
      }
     // console.log('Formatted STATUS:', obj.STATUS); // Check the formatted status
      return obj;
    });
  };


  // const formatData = (dataList) => {
  //   return dataList.map((obj) => {
  //     obj.STATUS = Number(obj.STATUS) === 1 ? "Active" : "Inactive";
  //     return obj;
  //   });
  // };

  useEffect(() => {
   // console.log("useEffect triggered with filterTabActive:", props.filterTabActive);
    if (props.filterTabActive === 1) {
      setMailBoxData(formatData(mailboxConfigList));
    } else if (props.filterTabActive === 2) {
      setMailBoxData(formatData(surveyConfigList));
    } else if (props.filterTabActive === 3) {
      setMailBoxData(formatData(surveyConfigList));
    }else if (props.filterTabActive === 4) {
      setMailBoxData(formatData(surveyConfigList));
    }
  }, [mailboxConfigList, surveyConfigList, props.filterTabActive]);
  //console.log("mailboxData:", mailboxData);

  const filterColumns = ["OFFERING_NAME", "MAILBOX_PROVIDER", "COMPANY_NAME", "MAILBOX_ID", "STATUS"];

  const onRowSelect = (row) => {
    props.setShortDescValues(row);
    props.showRightSide(true);
  };

  const renderMailboxConfigTableList = () => {
    const columns = props.filterTabActive === 1 ? tableColumns : tableColumn2;
    const dataList = formatData(mailboxData);
    return (
      <div className="dtaTableDv integrateHubTable">
        <div className={props.rightDisplay ? "leftTrue srchPosTop" : "rightTrue srchPosTop"}>
          <TableComponent
            //data={mailboxConfigList}
            data={dataList}
            columns={columns}
            headerColumns={filterColumns}
            onRowSelect={onRowSelect}
            rightDisplay={props.rightDisplay}
            uniqueKey={'UUID'}
          />
        </div>
      </div>
    );
  };

 
//console.log("inside list view mailboxConfigList",mailboxData )

// if (showLoader) {
//   return <ListLoader />;
// }

// if (!mailboxData || mailboxData.length === 0) {
//   return <div>No data available</div>;
// }

// return <div className="myOrderDiv">{renderMailboxConfigTableList()}</div>;


// if (!mailboxConfigList) return null;
// if (mailboxConfigList.length == undefined) {
//   return <ListLoader />;
// } else if (mailboxConfigList.length === 0) {
//   return (
//       renderMailboxConfigTableList()
//   )
// } else {
//   return (
//     <div className="myOrderDiv">
//       {(
//           renderMailboxConfigTableList()
//       )}
//     </div>
//   );
// }

if (!mailboxData) return null;
if (mailboxData.length == undefined) {
  return <ListLoader />;
} else if (mailboxData.length === 0) {
  return (
      renderMailboxConfigTableList()
  )
} else {
  return (
    <div className="myOrderDiv">
      {(
          renderMailboxConfigTableList()
      )}
    </div>
  );
}

};

export default SystemConfigListview;
