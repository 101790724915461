
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ListLoader from '../common/loaders/ListLoader';
import { mailboxImpact, mailboxUrgency } from '../../utils/common';
import { setSurveyConfigData, setSystemConfigData,setApprovalConfigData,setMailboxConfigData } from "../../actions/systemConfig/systemConfigAction";
import {IoClose} from "react-icons/io5";
import { PiPencilSimpleBold } from 'react-icons/pi';
import { MyContext } from '_MyContext';
const SystemConfigShortDescription = (props) => {
    const { systemConfigData, surveyConfigData,approvalConfigData,mailboxConfigData, showRightSide, filterTabActive, translator } = props;
    const dispatch = useDispatch();
   // const [filterTabActive, setFilterTabActive] = useState();
    const showLoader = useSelector(state => state.showLoader.loading);
    //const translator = useSelector(state => state.spcmReducer.tr)

    const navigate = useNavigate();
    const context = useContext(MyContext);
    const closeButton = useRef(null);
    const editButton = useRef(null);

    useEffect(() => {
        context.keyDownHandler(closeButton.current,editButton.current,"","");
        context.newTabPrevent();
    }, []);

    const handleEditClick = (tabIndex) => {
//dispatch(setFilterTabActive(tabIndex)); // Dispatch the action to store the filterTabActive value in Redux
      // Conditionally dispatch the data based on the active tab
      if (tabIndex === 1) {
        dispatch(setSystemConfigData(systemConfigData)); // Dispatch systemConfigData when Tab 1 is active
      } else if (tabIndex === 2) {
        dispatch(setSurveyConfigData(surveyConfigData)); // Dispatch surveyConfigData when Tab 2 is active
      } else if (tabIndex === 3) {
        dispatch(setApprovalConfigData(approvalConfigData)); // Dispatch approvalConfigData when Tab 3 is active
      }
      else if (tabIndex === 4) {
        dispatch(setMailboxConfigData(mailboxConfigData)); // Dispatch mailboxConfigData when Tab 3 is active
      }
      

    };
    
    return (
        <div className="margin-b-20">
            <div className="rPageHeadActBtn margin-r-5" style={{marginTop:"-3px"}}>
                <ul>
                    <li><Link to={filterTabActive === 1 ? "/editSystemConfig/"+ systemConfigData.UUID 
                                 : filterTabActive === 2 ? "/editSurveyConfig/"+ surveyConfigData.UUID
                                 : filterTabActive === 3 ? "/editApprovalConfig/"+ approvalConfigData.UUID
                                 : "/editMailConfig/"+ mailboxConfigData.UUID } 
                             title={translator['Edit']} 
                             onClick={() => handleEditClick(filterTabActive)}
                             ref={editButton} 
                             className="ctrlKeyPrevent" >
                                <PiPencilSimpleBold/></Link></li>
                    <li>
                        <Link to="javascript:void(0)" title={translator["Minimize the details panel"]} bsClass="" className="ctrlKeyPrevent" onClick={() => { props.showRightSide(false); }} ref={closeButton} >
                            <IoClose />
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="rPageHeading">
                <div className='offNam margin-t-5 margin-b-5'>{translator['Mailbox Name']}</div>
            </div>
            <div className="rBoxGap brdrTopcolor">
                <ListGroup variant='flush' bsClass="" className="">
                {filterTabActive === 1 ? (
                   <>
                    <ListGroup.Item bsClass="" className="rwTable" style={{ borderTop: "none" }}><span className="rwKey">{translator['Default Impact']} </span> <span className="rwVal">{props.systemConfigData.IMPACT_NAME}</span></ListGroup.Item>
                    {/* <ListGroup.Item bsClass="" className="rwTable" style={{ borderTop: "none" }}><span className="rwKey">{translator['Default Impact']} </span> <span className="rwVal">{imapctVar}</span></ListGroup.Item> */}
                    <ListGroup.Item bsClass="" className="rwTable"><span className="rwKey">{translator['Default Urgency']} </span> <span className="rwVal">{props.systemConfigData.URGENCY_NAME}</span></ListGroup.Item>
                    <ListGroup.Item bsClass="" className="rwTable"><span className="rwKey">{translator['User Verification Type']} </span> <span className="rwVal">{props.systemConfigData.ACTIONS}</span></ListGroup.Item>
                    </>
                ) :filterTabActive === 2 ? (
                    <>
              <ListGroup.Item bsClass="" className="rwTable" style={{ borderTop: "none" }}>
                <span className="rwKey">{translator['Company']}</span>
                <span className="rwVal">{surveyConfigData.COMPANY_NAME}</span>
              </ListGroup.Item>
              <ListGroup.Item bsClass="" className="rwTable">
                <span className="rwKey">{translator['Whitelisted Domains']}</span>
                <span className="rwVal">{surveyConfigData.WHITELISTED_DOMAINS}</span>
              </ListGroup.Item>
              <ListGroup.Item bsClass="" className="rwTable">
                <span className="rwKey">{translator['Always Process Exception']}</span>
                <span className="rwVal">{surveyConfigData.EXCEPTION_EMAILIDS}</span>
              </ListGroup.Item>              
              <ListGroup.Item bsClass="" className="rwTable">
                <span className="rwKey">{translator['Never Process Exception']}</span>
                <span className="rwVal">{surveyConfigData.NO_EXCEPTIONS}</span>
              </ListGroup.Item>
            </>  ): filterTabActive === 3 ?(
                    <>
                <ListGroup.Item bsClass="" className="rwTable" style={{ borderTop: "none" }}>
                <span className="rwKey">{translator['Company']}</span>
                <span className="rwVal">{approvalConfigData.COMPANY_NAME}</span>
              </ListGroup.Item>
              <ListGroup.Item bsClass="" className="rwTable">
                <span className="rwKey">{translator['Whitelisted Domains']}</span>
                <span className="rwVal">{approvalConfigData.WHITELISTED_DOMAINS}</span>
              </ListGroup.Item>
              <ListGroup.Item bsClass="" className="rwTable">
                <span className="rwKey">{translator['Always Process Exception']}</span>
                <span className="rwVal">{approvalConfigData.EXCEPTION_EMAILIDS}</span>
              </ListGroup.Item>              
              <ListGroup.Item bsClass="" className="rwTable">
                <span className="rwKey">{translator['Never Process Exception']}</span>
                <span className="rwVal">{approvalConfigData.NO_EXCEPTIONS}</span>
              </ListGroup.Item>
            </>  ):(
                    <>
                <ListGroup.Item bsClass="" className="rwTable" style={{ borderTop: "none" }}>
                <span className="rwKey">{translator['Company']}</span>
                <span className="rwVal">{mailboxConfigData.COMPANY_NAME}</span>
              </ListGroup.Item>
              <ListGroup.Item bsClass="" className="rwTable">
                <span className="rwKey">{translator['Mailbox']}</span>
                <span className="rwVal">{mailboxConfigData.MAILBOX_ID}</span>
              </ListGroup.Item>
              <ListGroup.Item bsClass="" className="rwTable">
                <span className="rwKey">{translator['Auth Type']}</span>
                <span className="rwVal">{mailboxConfigData.AUTH_TYPE}</span>
              </ListGroup.Item>              
              <ListGroup.Item bsClass="" className="rwTable">
                <span className="rwKey">{translator['Status']}</span>
                <span className="rwVal">{mailboxConfigData.STATUS}</span>
              </ListGroup.Item>
            </>  )

            }
                </ListGroup>
                
            </div>
        </div>
    );

}

export default SystemConfigShortDescription

