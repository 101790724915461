
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const myRightSide = (e) => {
  e.DomComponents.addType("right-section", {
    model: {
      defaults: {
        tagName: "div",
        draggable:true,
        droppable:true,
        attributes: { 'data-gjs-type': 'right-section' },
        traits: [
          {
            type: "text",
            label: "Title",
            name: "title",
            changeProp: 1,
          }
        ],
      },
      init() {},
    },
  });
};
