
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Formik, Form } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import AuditLog from "./AuditLog";
import Breadcrumbs from "../../common/header/breadcrumbs";
import Header from "./header";
import FormComponent from "./addEditRule";
import QueryBuilderComponent from "../query-builder";

import {
  useSaveRuleDefinitionMutation,
  useGetRuleQuery,
  useUpdateRuleDefinitionMutation,
} from "../../../reducers/rules";
import ListLoader from "../../common/loaders/ListLoader";
import { customQueryFormatter } from "../../common/helper";
import "_Css/form/_form.scss";
import axios from "axios";
import { values } from "lodash";

const initialQuery = {
  combinator: "and",
  rules: [],
};

const Index = () => {
  const formikRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const [history, setHistory] = useState(new Map());
  const [fields, setFields] = useState([]);
  const [ruleStatus, setRuleStatus] = useState("");
  const [query, setQuery] = useState(initialQuery);
  const [isRightEditPanel, setIsRightEditPanel] = useState(true);
  const [view, setView] = useState("details");
  const [rows, setRows] = useState(new Map());
  const [isInitialData, setIsInitialData] = useState(true);
  const [serviceList, setServiceList] = useState([]);
  const [saveRule, { isLoading: saveForumLoading }] =
    useSaveRuleDefinitionMutation();
  const [updateRule, { isLoading: isRuleUpdating }] =
    useUpdateRuleDefinitionMutation();

  const ruleId = params?.ruleId;
  const isAddMode = !ruleId;

  const rightEditPanel = (value) => {
    setIsRightEditPanel(value);
  };

  const [initVals, setInitVals] = useState({
    ruleType: "assignment",
    ruleName: "",
    consumerCompanyId: "",
    consumerCompany: "",
    alternateGroupId: "",
    alternateGroupName: "",
    assignmentCompany: "",
    assignmentCompanyId: "",
    assignmentType: "predefined",
    groupType: "",
    groupTypeId: "",
    module: "",
    ruleFor: "",
    status: "",
    weight: "",
    businessFunction: "",
    businessFunctionId: "",
    condition: "",
    responseEntityId: "",
    responseEntity: "",
  });

  let initialValues = {
    ...initVals,
  };
  
  const validationSchema = Yup.object().shape({
    ruleType: Yup.string().required("Required"),
    ruleName: Yup.string().required("Required"),
    consumerCompanyId: Yup.string().required("Required"),
    assignmentCompanyId: Yup.string().when("ruleType", (ruleType, schema) => {
      return ruleType?.[0] === "assignment"
        ? schema.required("Required")
        : schema;
    }),
    responseEntityId: Yup.string().required("Required"),
    module: Yup.string().required("Required"),
    weight: Yup.string().when("ruleType", (ruleType, schema) => {
      return ruleType?.[0] !== "escalation"
        ? schema.required("Required")
        : schema;
    }),
    status: Yup.string().required("Required"),
    ruleFor: Yup.string().when("ruleType", (ruleType, schema) => {
      return ruleType?.[0] === "assignment"
        ? schema.required("Required")
        : schema;
    }),
    groupTypeId: Yup.string().when(["assignmentType", "responseEntity"], ([assignmentType, responseEntity], schema) => {
      return (assignmentType === "contextual" && responseEntity === "Requestor.Location.Group")
        ? schema.required("Required")
        : schema;
    }),
  });


    const validateRule = (rule) => {
    const errors = {};
  
    if ('rules' in rule) {
      rule.rules.forEach((subRule, index) => {
        const subRuleErrors = validateRule(subRule);
        if (Object.keys(subRuleErrors).length > 0) {
          errors[`rules[${index}]`] = subRuleErrors;
        }
      });
    } else {
      if (!rule.field || rule.field === "") {
        errors.field = "Field cannot be blank.";
      }
      if (!rule.operator || rule.operator === "") {
        errors.operator = "Operator cannot be blank.";
      }
      if (rule.operator !== "null" && rule.operator !== "!null") {
        const isValueBlank =
          typeof rule.value === "object"
            ? rule.value?.id === undefined ||
              rule.value?.id === "" ||
              rule.value?.id?.toString()?.trim().length === 0
            : rule.value === undefined ||
              rule.value === "" ||
              rule.value.trim().length === 0;

        if (isValueBlank) {
          errors.value = "Value cannot be blank.";
        }
      }
    }
  
    return errors;
  };
  
  const validateAllRules = (rules) => {
    const errors = rules.map((rule, index) => {
      const ruleErrors = validateRule(rule);
      return Object.keys(ruleErrors).length > 0 ? { index, ruleErrors } : null;
    }).filter(error => error !== null);
  
    return errors.length > 0 ? { rules: errors } : {};
  };

  const validateRows = () => {
    let errors = {}
    const ofgQuestions = Array.from(rows.values()).flat();
    if(ofgQuestions.length === 1 && ofgQuestions[0]?.value === '' && ofgQuestions[0]?.quesId === '') {
      return {};
    } else {
    ofgQuestions.map((ques, index) => {
        if(ques?.value === '') {
          errors[`${ques}${index}`] = 'Value is empty';
        }
        if(ques?.quesId === '') {
          errors[`${ques}${index}`] = 'QuesId is empty';
        }
      })
    }
    return errors;
  }

  const onSubmit = async (fields, { setSubmitting }) => {
    const effectiveQuery = query && query.rules.length > 0 ? query : null;
    const rules = query && query.rules.length > 0 ? customQueryFormatter(effectiveQuery, 'sql') : "";
    const rulesErrors = validateAllRules(effectiveQuery?.rules || []);
    const rowsErrors = validateRows();

    if (Object.keys(rulesErrors).length > 0 || Object.keys(rowsErrors).length > 0) {
      Swal.fire({
        title: "Conditions can not be blank",
        button: "OK",
      });
      return;
    }

    const serviceOrderDetails = Array.from(history.keys()).map((key) => {
      const offeringId = key[0];
      const offeringQuestion = rows
        .get(offeringId)
        .filter(
          (choice) =>
            choice.value != null &&
            choice.value !== "" &&
            choice.quesId != null &&
            choice.quesId !== "" &&
            choice.ques != null &&
            choice.ques !== ""
        )
        .map((choice) => ({
          ques: choice.ques,
          quesId: choice.quesId,
          operator: choice.operator,
          value: choice.value,
        }));

      if (offeringQuestion.length > 0) {
        return {
          offeringId: offeringId,
          offeringQuestion: offeringQuestion,
        };
      }
      return null;
    }).filter(item => item !== null);

    const postData = {
      ...fields,
      condition: rules || "",
      conditionalJson: effectiveQuery,
      conditionJson: serviceOrderDetails.length == 0 ? {} : effectiveQuery,
      createdOn: undefined,
      modifiedOn: undefined,
      ofgQuestions: serviceOrderDetails,
    };
    if (isAddMode) {
      try {
        const savefrm = await saveRule({
          data: postData,
        }).unwrap();
        setSubmitting(false);
        if (savefrm?.status === "OK") {
          Swal.fire({
            title: "Rule created successfully",
            button: "OK",
          }).then((action) => {
            if(action.isDismissed || action.isConfirmed) {
              navigate('/editRule/' + savefrm.body.id);
            }
          })
        }
      } catch (err) {
        console.error(err?.data || "Error");
        Swal.fire({
          title:
            err?.data?.message ||
            "Oops! Something went wrong. Please try again.",
          button: "OK",
        });
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        const updateData = {
          ...postData,
          ruleId: ruleId,
        };
        const reponse = await updateRule({
          data: updateData,
        }).unwrap();
        if (reponse?.status === "OK") {
          Swal.fire({
            title: "Rule updated successfully",
            button: "OK",
          })
        }
      } catch (err) {
        console.error(err?.data || "Error");
      } finally {
        setSubmitting(false);
      }
    }
  };

  const { data: ruleDatabyId, isFetching: ruleFetching } = useGetRuleQuery(
    {
      ruleId,
    },
    {
      skip: !ruleId,
    }
  );

  useEffect(() => {
    setInitVals((prev) => {
      return {
        ...prev,
        ...ruleDatabyId,
      };
    });
    setRuleStatus(ruleDatabyId?.status);
    if (ruleDatabyId?.conditionalJson) {
      try {
        const parsedData = JSON.parse(ruleDatabyId.conditionalJson);
        setQuery(parsedData);
      } catch (err) {
        console.log('err', err);
      }
    } else {
      setQuery("");
    }
  }, [ruleDatabyId]);

  useEffect(() => {
    return () => {
      setHistory([]);
    };
  }, []);

  

  useEffect(() => {
    if (query && query.rules) {
      if(isInitialData && ruleDatabyId) {
        try {
          let parsedData = JSON.parse(ruleDatabyId?.ofgQuestions);
          let ofgObject = new Map();
          parsedData?.map((ofg) => {
            ofgObject.set(ofg.offeringId, ofg.offeringQuestion)
          })
          if((ruleDatabyId.status === 'inactive') || !(ruleDatabyId.status === 'active' && parsedData.length === 0))
          fetchPiQuestions(query, ofgObject);
        } catch (error) {
          console.log(error);
        }
      } else {
        fetchPiQuestions(query);
      }
    }
  }, [query, serviceList]);

  const fetchPiQuestions = (query, ofgObj) => {
    if (query && query.rules) {
      let existingQuestions = structuredClone(history);
      let existingRows = structuredClone(rows);
      let ofgCount = 0;

      let rule = query.rules.find(
        (rule) =>
          (rule.field === "breakfix.serviceId" ||
            rule.field === "xsm_item.offeringIdSx" ||
            rule.field === "xsm_change.serviceId") &&
          rule.value &&
          rule.value.id &&
          rule.operator === "="
      );

      if (rule && history.size < 1) {
        ofgCount++;
        const id = rule.value.id;
        const name = rule.value.value;
        const selectedService = serviceList.find(
          (service) => service.OFFERING_ID.toString() === id.toString()
        );
        let SMARTFORM_ID = selectedService?.SMARTFORM_ID;
        let url;
        if (selectedService && !selectedService?.SMARTFORM_ID) {
          url = `/service/api/${id}/getOfferingQuestionsList?queryBy=Question&queryValue=&sortBy=Sequence&page=1&limit=100&order=asc`;
        } else if (
          selectedService &&
          selectedService?.SMARTFORM_ID &&
          rule.field === "xsm_item.offeringIdSx"
        ) {
          url = `/smartFormFields/${SMARTFORM_ID}/ques`;
        } else if (
          selectedService &&
          selectedService?.SMARTFORM_ID &&
          (rule.field === "breakfix.serviceId" ||
            rule.field === "xsm_change.serviceId")
        ) {
          url = `/smartFormFields/${SMARTFORM_ID}/allQues`;
        }
        if (url) {
          axios.get(url).then((response) => {
            let offgQuestions;
            if (response.data.meta) {
              offgQuestions = response.data.data.filter(
                (question) =>
                  question.QUESTION_TEXT != "" && question.QUESTION_TEXT != null
              );
            } else {
              offgQuestions = response.data.fields.map((item) => ({
                QUESTION_TEXT: item.question,
                QUESTION_ID: item.question,
              }));
            }
            existingQuestions.set([id, name], offgQuestions);
            if (ofgObj?.size) {
              existingRows = structuredClone(ofgObj);
              setIsInitialData(false);
            } else {
              existingRows.set(id, [{ quesId: "", operator: "=", value: "" }]);
            }
            setHistory(existingQuestions);
            setRows(existingRows);
          });
        }
      }
      if (ofgCount < existingQuestions.size) {
        existingQuestions.forEach((ques, offeringId) => {
          const existingOffering = query.rules.some((rule) => {
            return rule.value.id === offeringId[0];
          });
          if (!existingOffering) {
            existingQuestions.delete(offeringId);
            existingRows.delete(offeringId[0]);
          }
        });
        setHistory(existingQuestions);
        setRows(existingRows);
      }
    }
  };
  
  return (
    <main>
      <Container fluid className="margin-b-15 margin-t-10">
        <Breadcrumbs
          activePageName={isAddMode ? "Create" : "Edit"}
          parentPageurl="/rules"
          parentPageName={"Rule Listing"}
        />
      </Container>

      <Container fluid>
        <Row>
          <Col xs={12}>
            <Header
              isSubmitting={formikRef?.current?.isSubmitting}
              dirty={formikRef?.current?.dirty}
              submitForm={formikRef?.current?.handleSubmit}
              formikref={formikRef}
              errors={formikRef?.current?.errors}
              isRightEditPanel={isRightEditPanel}
              rightEditPanel={rightEditPanel}
              view={view}
              setView={setView}
              setHistory={setHistory}
            />
          </Col>
          <Col xs={12} md={12}>
            {ruleFetching ? 
              <ListLoader /> : 
              <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={onSubmit}
            >
              {({
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values,
                dirty,
                resetForm,
                handleChange,
                isValid,
                submitCount,
              }) => {
                return (
                  <Form noValidate>
                    <PanelGroup direction="horizontal">
                      <Panel
                        id="sidebar"
                        minSize={33}
                        order={1}
                        defaultSize={50}
                        className={isRightEditPanel ? "isShowLeftPanel catBordDv" : "catBordDv"}
                      >
                        <FormComponent
                          resetForm={resetForm}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          values={values}
                          saveForumLoading={saveForumLoading || isRuleUpdating}
                          setFields={setFields}
                          isValid={isValid}
                          submitCount={submitCount}
                          handleChange={handleChange}
                          ruleStatus={ruleStatus}
                          setQuery={setQuery}
                          initialQuery={initialQuery}
                          editFormValues={ruleDatabyId}
                        />
                      </Panel>
                      {isRightEditPanel ? (
                        <>
                        <PanelResizeHandle className="isShowLeftPanel resizeHandle">
                          <div className="outlne">
                            <div className="handIcn">
                              <i className="fa fa-chevron-left" aria-hidden="true"></i>
                            </div>
                          </div>
                        </PanelResizeHandle>
                        <Panel minSize={33} order={2} defaultSize={50}>
                          {view === "details" && (
                            <QueryBuilderComponent
                              values={values}
                              fields={fields}
                              setFields={setFields}
                              setQuery={setQuery}
                              query={query}
                              editFormValues={ruleDatabyId}
                              rightEditPanel={rightEditPanel}
                              history={history}
                              setHistory={setHistory}
                              rows={rows}
                              setRows={setRows}
                              setFieldValue={setFieldValue}
                              setServiceList={setServiceList}
                            />
                          )}
                          {view === "activity" && (
                            <div className="stickyArea rBoxStyle">
                              <AuditLog
                                rightEditPanel={rightEditPanel}
                                setView={setView}
                              />
                            </div>
                          )}
                         
                        </Panel>
                      </>
                      ) : (
                        ""
                      )}
                    </PanelGroup>
                  </Form>
                );
              }}
            </Formik>}
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default Index;
