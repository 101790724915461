
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import {Row,Col, Form, ButtonToolbar, Button } from 'react-bootstrap';
import { connect, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { _inputField } from '../../../../common/ReduxFormFields/CategoryBoard/formFields';

let PersonalInfoForm = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const EDITUSERData = useSelector((state) => state.editDetailsRed);
    // var phoneno = /^\d{10}$/;
    var phoneno = /^\+?\d+$/;
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let specialCharRegex = /[^-_'.@&0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz]/

    const  validateGroupsField = (fieldType, event)=> {
        switch (fieldType) {
          // case "businessPhone":
          //   if (event.target.value.match(phoneno)) {
          //     props.setBusinessErr("");
          //   } else if (event.target.value.trim() == ""){
          //       props.setBusinessErr("");
          //     } else {
          //       props.setBusinessErr("error");
          //     }
          //   break;
          // case "mobilePhone":
          //   if (event.target.value.match(phoneno)) {
          //     props.setMobileErr("");
          //   } else if (event.target.value.trim() == ""){
          //     props.setMobileErr("");
          //   } else {
          //     props.setMobileErr("error");
          //   }
          //   break;
          // case "contactPhone":
          //   if (event.target.value.match(phoneno)) {
          //     props.setcontactErr("");
          //   } else if (event.target.value.trim() == ""){
          //       props.setcontactErr("");
          //     } else {
          //       props.setcontactErr("error");
          //     }
          //   break;
          case "businessPhone":
            if (event.target.value.match(phoneno)) {
              props.setBusinessErr("");
            }else {
              props.setBusinessErr("error");
              }
            break;
          case "mobilePhone":
            if (event.target.value.match(phoneno)) {
              props.setMobileErr("");
            }  else {
              props.setMobileErr("error");
            }
            break;
          case "contactPhone":
            if (event.target.value.match(phoneno)) {
              props.setcontactErr("");
            } else {
              props.setcontactErr("error");
              }
            break;
          default:
        }
    }

    return (
      <Row className="padding-10 tabFormDv">
          <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
              <Form.Label>Employee Number</Form.Label>
              <Field
                      name="employeeNumber"
                      className={"form-control " }
                      component={_inputField}
                      // onBlur={(event) => {
                      //     validateCompanyField("username", event);
                      // }}
                  />
          </Form.Group>
          </Col>
          <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
              <Form.Label>Gender</Form.Label>
              <Field
                      name="gender"
                      component="select"
                      className="form-control"
                  >
                  <option value="">Select</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Others</option>
                  </Field>
          </Form.Group>
          </Col>
          <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
              <Form.Label>Employee Band</Form.Label>
              <Field
                      name="employeeBand"
                      className={"form-control " }
                      component={_inputField}
                      // onBlur={(event) => {
                      //     validateCompanyField("username", event);
                      // }}
                  />
          </Form.Group>
          </Col>
          <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
              <Form.Label>Business Phone</Form.Label>
              <Field
                      name="businessPhone"
                      className={"form-control "+(props.businessErr) }
                      component={_inputField}
                      placeholder="e.g. +919000000000"
                      // onBlur={(event) => {
                      //     validateGroupsField("businessPhone", event);
                      // }}
                      maxLength={20}                       

                  />
          </Form.Group>
          </Col>
          <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
              <Form.Label>Mobile Phone</Form.Label>
              <Field
                      name="mobilePhone"
                      className={"form-control "+(props.mobileErr)}
                      component={_inputField}
                      placeholder="e.g. +919000000000"
                      // onBlur={(event) => {
                      //     validateGroupsField("mobilePhone", event);
                      // }}
                      maxLength={20}                       

                  />
          </Form.Group>
          </Col>
          <Col md={4} sm={6} xs={12}>
          <Form.Group className="form-group">
              <Form.Label>Contact Phone</Form.Label>
              <Field
                      name="contactPhone"
                      className={"form-control "+(props.contactErr) }
                      component={_inputField}
                      placeholder="e.g. +919000000000"
                      // onBlur={(event) => {
                      //     validateGroupsField("contactPhone", event);
                      // }}
                      maxLength={20}                       

                  />
          </Form.Group>
          </Col>
          <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>Rebadged</Form.Label>
                <Field
                        name="rebadged"
                        className={"form-control " }
                        component={_inputField}
                        maxLength={200}
                    />
            </Form.Group>
            </Col>
            <Col md={4} sm={6} xs={12}>
            <Form.Group className="form-group">
                <Form.Label>Designation</Form.Label>
                <Field
                        name="designation"
                        className={"form-control " }
                        component={_inputField}
                        maxLength={200}
                    />
            </Form.Group>
            </Col>
      </Row>
  )
}

PersonalInfoForm = reduxForm({
    form: "EditUserform",
    enableReinitialize: true,
  })(PersonalInfoForm);
  PersonalInfoForm = connect(({ editDetailsRed }) => (
    {
    // initialValues: {
    //   contactPhone: editDetailsRed?.contactPhone,
    //   mobilePhone: editDetailsRed?.mobilePhone,
    //   businessPhone: editDetailsRed?.businessPhone,
    //   employeeBand: editDetailsRed?.employeeBand,
    //   gender: editDetailsRed?.gender,
    //   employeeNumber: editDetailsRed?.employeeNumber,
    //   email: editDetailsRed?.email,
    // },
  }))(PersonalInfoForm);
  
export default PersonalInfoForm;