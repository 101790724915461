
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
const PaginationNumber = (props) => {
    const {item, defaultValues, language, attributes} = props;
    const renderPage = ()=>{
          if(defaultValues && defaultValues[item?.totalItemCount] && defaultValues[item?.currentPage] && defaultValues[item?.pageSize]){
              let totalItemCount = defaultValues[item?.totalItemCount];
              let currentPage = defaultValues[item?.currentPage];
              let pageSize = defaultValues[item?.pageSize];
              let start = (currentPage - 1) * pageSize + 1;
              let end = Math.min(currentPage * pageSize, totalItemCount);
              return `${start}-${end} of ${totalItemCount}`;
          }
    }
  return renderPage();
};
export default PaginationNumber