
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import XsmUserDetails from '_Commons/WorkItemBoard/xsmEditRequestedBy.js';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import { Scrollbars } from "react-custom-scrollbars";
import {ImInfo, ImSpinner6} from "react-icons/im";
import { IoCopyOutline } from "react-icons/io5";
import AiIcon from "../aiIcon";
import {
  loadBreakFixActivityLogDetails,
  loadTMSActivityLogDetails,
  clearBreakfixActivityLogDetails,
} from "../../../actions/breakFix/breakFixActivityLogAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import { GLOBAL } from "_Globals";
import { validateTaskField } from "../../../utils/common";
import moment from "moment";
import datetimeConvertor from "../../../helpers/ISO8601converter";
import { loadFulfillmentDetails } from '../../../actions/fulfillment/fulfillmentDataAction';
import { loadStatusBasedHamburgerOptions } from '../../../actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '../../../actions/common/commonApisActions.js';
import { loadBreakFixEditDetails } from '../../../actions/breakFix/breakFixEditDetailsAction';
import Cookies from "universal-cookie";
import swal from "sweetalert";
import ReactReadMoreLess from "../../../helpers/ReactReadMoreLess";
import RCADetails from "./RCADetails.js";
import { taskStatusMapping } from "../helper.js";
const cookies = new Cookies();

let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
timezone = timezone.split("~");
timezone = timezone[3];

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));
//console.log("homepage::::"+homepagelocation);
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

let full_name = cookies.get("gph");
if (full_name) full_name = full_name.replace("s:", "");
if (full_name) full_name = full_name.substring(0, full_name.lastIndexOf("."));
full_name = full_name.split("~");
full_name = full_name[42];

let user_id = cookies.get("gph");
if (user_id) user_id = user_id.replace("s:", "");
if (user_id) user_id = user_id.substring(0, user_id.lastIndexOf("."));
user_id = user_id.split("~");
user_id = user_id[43];

let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];

const MoreArrow = (
  <i title="More" className="moreArrow fa fa-angle-double-down"></i>
);
const LessArrow = (
  <i title="Less" className="margin-l-5 lessArrow fa fa-angle-double-up"></i>
);

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});
class PopoverActivityJournalData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserData: false,
      comments: "",
      isinternal:
        this.props.module === "Breakfix" || this.props.module === "Fullfilment" || this.props.module === "Task"
          ? "Y"
          : "N",
      showLoader: false,
      checkstatus:
        this.props.module === "Breakfix" || this.props.module === "Fullfilment" || this.props.module === "Task"
          ? true
          : false,
      showSummaryLoader: false,
    };
    this.activityData = this.activityData.bind(this);
    this.postActivityData = this.postActivityData.bind(this);
    this.onSummarizeClick = this.onSummarizeClick.bind(this);
    this.getSummarizedData = this.getSummarizedData.bind(this);
    this.copyText = this.copyText.bind(this);
  }
  // componentWillUnmount(){
  //     this.props.clearBreakfixActivityLogDetails();
  // }
  componentWillMount() {
    //alert("id:::::"+this.props.breakFixItemId)
    // console.log("component will mmount",this.props.breakFixEditDetails.breakfixId);
    // console.log('&&&&&', this.props);
    let module = this.props.module;
    let itemId = this.props.itemId;
    // let getBreakFixItemId = this.props.breakFixItemId
    //   ? this.props.breakFixItemId.split(":")
    //   : "";
    // let BRKItemId = getBreakFixItemId[0];
    let breakFixActualItemId = this.props.breakFixItemId || ""; // getBreakFixItemId[1];
    GLOBAL.breakFixId = breakFixActualItemId;
    if (module == "Breakfix")
      this.props.loadBreakFixActivityLogDetails(
        breakFixActualItemId,
        "desc",
        "Breakfix"
      );
    if (module == "Fullfilment")
      this.props.loadBreakFixActivityLogDetails(itemId, "desc", "Fullfilment");
    if (module == "Investigation")
      this.props.loadBreakFixActivityLogDetails(
        itemId,
        "desc",
        "Investigation"
      );
    if (module == "ChangeManagement")
      this.props.loadBreakFixActivityLogDetails(
        itemId,
        "desc",
        "ChangeManagement"
      );
    if (module == "Task")
      // this.props.loadBreakFixActivityLogDetails(itemId, "desc", "Task");
      this.props.loadTMSActivityLogDetails(
        this.props.taskCode,
        "createdOn desc"
      );
  }

  ascactivitysort() {
    let module = this.props.module;
    let itemId = this.props.itemId;
    if (module == "Breakfix")
      this.props.loadBreakFixActivityLogDetails(
        GLOBAL.breakFixId,
        "asc",
        "Breakfix"
      );
    if (module == "Fullfilment")
      this.props.loadBreakFixActivityLogDetails(itemId, "asc", "Fullfilment");
    if (module == "Investigation")
      this.props.loadBreakFixActivityLogDetails(itemId, "asc", "Investigation");
    if (module == "ChangeManagement")
      this.props.loadBreakFixActivityLogDetails(
        itemId,
        "asc",
        "ChangeManagement"
      );
    if (module == "Task")
      // this.props.loadBreakFixActivityLogDetails(itemId, "asc", "Task");
      this.props.loadTMSActivityLogDetails(
        this.props.taskCode,
        "createdOn asc"
      );
  }
  descactivitysort() {
    let module = this.props.module;
    let itemId = this.props.itemId;
    if (module == "Breakfix")
      this.props.loadBreakFixActivityLogDetails(
        GLOBAL.breakFixId,
        "desc",
        "Breakfix"
      );
    if (module == "Fullfilment")
      this.props.loadBreakFixActivityLogDetails(itemId, "desc", "Fullfilment");
    if (module == "Investigation")
      this.props.loadBreakFixActivityLogDetails(
        itemId,
        "desc",
        "Investigation"
      );
    if (module == "ChangeManagement")
      this.props.loadBreakFixActivityLogDetails(
        itemId,
        "desc",
        "ChangeManagement"
      );
    if (module == "Task")
      // this.props.loadBreakFixActivityLogDetails(itemId, "desc", "Task");
      this.props.loadTMSActivityLogDetails(
        this.props.taskCode,
        "createdOn desc"
      );

    //this.props.loadBreakFixActivityLogDetails(GLOBAL.breakFixId,"desc");
  }

  activityData(comnts) {
    this.setState({ comments: comnts.target.value });
  }

  postActivityData() {
    let activityblank = false;

    if (this.state.comments.match(/^\s*$/)) {
      activityblank = true;
    }
    let module = this.props.module;
    let itemId = this.props.itemId;
    let actualidtosave = "";
    let isExternal = "";
    let isInternalValue = this.state.isinternal;
    // console.log("this.props.taskDetails breakfix log");
    // console.log(this.props.taskDetails);
    if (
      this.props.taskDetails &&
      (this.props.taskDetails.workItemType === "Change" || this.props.taskDetails.workItemType === "Problem")
    ) {
      isInternalValue = "N";
    }
    if (module == "Task") {
      if (
        this.props.taskDetails.isExternal == null ||
        this.props.taskDetails.isExternal == "" ||
        this.props.taskDetails.isExternal == undefined
      ) {
        isExternal = "false";
      } else {
        isExternal = this.props.taskDetails.isExternal;
      }
      actualidtosave = itemId;
      if (this.state.comments != "" && activityblank == false) {
        this.setState({ showLoader: true });
        api
          .post(GLOBAL.TMSActivityLogUrl, {
            workitemId: actualidtosave,
            taskCode: this.props.taskCode,
            module: module,
            description: this.state.comments,
            isInternal: isInternalValue,
            createdBy: user_id,
            // "from": 0,
            // "to": 0,
            createdbyName: full_name,
            // "isReorder":"N",
            // "isAttachment":"N",
            sendNotification: "true",
            isExternal: isExternal,
          })
          .then((response) => {
            // console.log(response.data);
            this.activityDescription.value = "";
            this.props.loadTMSActivityLogDetails(
              this.props.taskCode,
              "createdOn desc",
              () => {
                this.setState({
                  comments: "",
                  showLoader: false,
                  checkstatus: true,
                  isinternal: "Y",
                });
              }
            );
          });
      } else {
        swal({
          text: "Please mention activity",
          button: this.props.translator["OK"],
        });
      }
    } else {
      if (
        module == "Fullfilment" ||
        module == "Investigation" ||
        module == "ChangeManagement"
      )
        actualidtosave = itemId;
      else {
        // let getBreakFixItemId = this.props.breakFixItemId
        //   ? this.props.breakFixItemId.split(":")
        //   : "";
        // let BRKItemId = getBreakFixItemId[0];
        actualidtosave = this.props.breakFixItemId; // getBreakFixItemId[1];
      }
      //const offering = this.props.offering;
      if (this.state.comments != "" && activityblank == false) {
        let self = this;
        this.setState({ showLoader: true });
        if(module==='Breakfix' && this.props.breakFixEditDetails.statusId=='20' && this.props.breakFixEditDetails.impactedUserid==user_id && this.props.breakFixEditDetails.holdingReasonCode=="75"){
        axios.patch(GLOBAL.breakFixPatchUrl + this.props.breakFixEditDetails.breakfixId, {"status":'15',"modifiedBy": "","modifiedByName": "","holdingReasonCode": "","holdingComments": "","holdingReasonValue": "", "spUserid": 0, "spUsername": ""}).then((incidentResponse)=>{
            self.props.loadBreakFixEditDetails(self.props.breakFixEditDetails.breakfixId);
            self.props.loadStatusBasedHamburgerOptions('Breakfix', incidentResponse.data.status, incidentResponse.data.breakfixId);
          });
        }  
        if(module==="Fullfilment" && this.props.fulfillmentDetails.itemStatus=="60" && this.props.fulfillmentDetails.requesterId==user_id && this.props.fulfillmentDetails.holdingReasonCode=="75"){
          axios.patch(GLOBAL.fulfillmentpost + this.props.fulfillmentDetails.itemId,{"itemStatus": "70","modifiedBy": "","modifiedByName": "","holdingReasonCode": "","holdingComments": "","holdingReasonValue": "", "asgUserid": 0, "asgUsername": ""}).then((fulfillmentResponse)=>{
            self.props.loadFulfillmentDetails(self.props.fulfillmentDetails.itemId);
            self.props.loadStatusBasedHamburgerOptions('Item',fulfillmentResponse.data.status,fulfillmentResponse.data.itemId);
          });
        }
        //this.props.fun(false,true,true);
        api
          .post(GLOBAL.breakFixActivityLogPostUrl, {
            itemId: actualidtosave,
            description: this.state.comments,
            isInternal: this.state.isinternal,
            createdOn: "",
            createdBy: "",
            module: module,
            createdByName: "",
            sendNotification: "true",
            isExternal: isExternal,
          })
          .then((response) => {
            //alert("Data successfully submitted")
            //alert(response.data.itemId)
            this.activityDescription.value = "";
            this.props.loadBreakFixActivityLogDetails(
              response.data.itemId,
              "desc",
              module,
              () => {
                this.setState({
                  comments: "",
                  showLoader: false,
                  checkstatus:
                    this.props.module === "Breakfix" ||
                    this.props.module === "Fullfilment" ||
                    this.props.module === "Task"
                      ? true
                      : false,
                  isinternal:
                    this.props.module === "Breakfix" ||
                    this.props.module === "Fullfilment" ||
                    this.props.module === "Task"
                      ? "Y"
                      : "N",
                });
              }
            );
          });
      } else {
        swal({
          text: "Please mention activity",
          button: this.props.translator["OK"],
        });
      }
    }
  }
  onSummarizeClick(){
    if(typeof this.props.breakFixEditDetails!=='undefined'){
      this.getSummarizedData(this.props.breakFixEditDetails.breakfixId,'Breakfix',this.props.breakFixEditDetails.consumerCompany);
    }else if(typeof this.props.fulfillmentDetails!=='undefined'){
      this.getSummarizedData(this.props.fulfillmentDetails.itemId,'Fullfilment',this.props.fulfillmentDetails.companyId);
    }else if(typeof this.props.changeDetails!=='undefined'){
      this.getSummarizedData(this.props.changeDetails.changeId,'ChangeManagement',this.props.changeDetails.consumerCompany);
    }else if(typeof this.props.investigationdetail!=='undefined'){
      this.getSummarizedData(this.props.investigationdetail.investigationId,'Investigation',this.props.investigationdetail.consumerCompany);
    }
  }
  getSummarizedData(id,moduleName,companyId){
    const ref = this;
    this.setState({showSummaryLoader: true});
    axios.get('/getSummarizedComments?itemId='+id+'&module='+moduleName+"&companyId="+companyId).then(retriveSummary => {
      if(retriveSummary.status == 200){
        const parser = new DOMParser();
        const retrivedData = parser.parseFromString(retriveSummary.data.response, "text/html");
        const extractedData = retrivedData.querySelector("pre").textContent;
        ref.setState({comments: extractedData, showSummaryLoader: false});
      }
    }).catch(error=>{
        ref.setState({showSummaryLoader: false});
    });
  }
  copyText(){
    navigator.clipboard.writeText(this.state.comments).catch(err => {
        console.error('Failed to copy text: ', err);
      });
  }

  renderActivityLog(activityLogData) {
    // alert(""+activityLogData.length)
    if (activityLogData.length == 0) {
      return (
        <div className="padding-10 text-c">
          {this.props.translator["Data not available."]}
        </div>
      );
    }

    const groupActivityLog = activityLogData.reduce((obj, index) => {
      const date = moment(index.createdOn, jwtdf + " HH:mm:ss").format(jwtdf);
      // const date = index.createdOn.split('T')[0];
      if (!obj[date]) {
        obj[date] = [];
      }
      obj[date].push(index);
      return obj;
    }, {});


    return Object.keys(groupActivityLog).map((activityObj, index) => {
      return (
        <div id="brkActLogcomm" className="actCommts">
          {/* <div className='hed' key={index}>{activityObj}</div> */}
          {groupActivityLog[activityObj].map((comment, childKey) => {
            let intval = "";
            if (comment.isInternal == "Y") intval = "(Internal)";

            let actDateTimeFormat = jwtdf + " hh:mm A";
            let myDateObj,
              endDate,
              endDateStr,
              timeago,
              startTime,
              duration,
              hours,
              isoDate;
            if (comment.module == "Task") {
              // let newDate = new Date(comment.createdOn).toLocaleString(
              //   "en-US",
              //   { timeZone: timezone }
              // );
              let newDate = comment.createdOn;
              myDateObj = moment(newDate,jwtdf + " HH:mm:ss").format(jwtdf + " HH:mm:ss");
              isoDate = datetimeConvertor(myDateObj, jwtdf + " HH:mm:ss");
              myDateObj = moment(isoDate).format(actDateTimeFormat);
            } else {
              isoDate = datetimeConvertor(
                comment.createdOn,
                jwtdf + " HH:mm:ss"
              );
              myDateObj = moment(isoDate).format(actDateTimeFormat);
            }
            endDate = moment(myDateObj, actDateTimeFormat);
            endDateStr = endDate._i;
            startTime = moment(
              new Date().toLocaleString("en-US", { timeZone: timezone })
            );
            timeago = moment(endDateStr, actDateTimeFormat).from(startTime);
            duration = moment.duration(startTime.diff(endDate));
            hours = duration.asHours();

            const tooltip = (
              <Popover className="userInfoPopup">
                <XsmUserDetails userId={comment.createdBy} translator={this.props.translator} isHeadingVisible={false} isPopup={true} />
              </Popover>
            );

            let contentToShow = comment.from == ""? comment.description == null ? "": comment.description.replace(/<br\/>/g, "\n"): "Changed Status from " +comment.from +" to " +comment.to;
            let isRCATechniqueDescription=false;
            let RCAname='';
            let splittedContent=contentToShow.split('~');
            let rcaInfo='';
            if(splittedContent[0]==='RCA'){
              isRCATechniqueDescription=true;
              RCAname=splittedContent[1];
              let keyValuePair=[];
              splittedContent[2].split(',').map((item)=>{
                  if(typeof item.split(':')[1]==='undefined'){
                    isRCATechniqueDescription=false;
                    contentToShow=item.split(':')[0];
                  }                 
                  keyValuePair.push({key: RCAname==='5Whys'?item.split(':')[0].trim()+' Why':item.split(':')[0].trim(), value: item.split(':')[1]});
              });
              rcaInfo=<Popover className="userInfoPopup"><RCADetails techniqueName={RCAname} details={keyValuePair} translator={this.props.translator}/></Popover>;
            }
            // let objName = comment.module == "Task" ? comment.createdbyName : comment.createdByName;
            // objName =  objName == null ? " " : comment.createdByName;
            // console.log("objName79",objName);
            // const objNameSplt = (objName?.split(' ') || objName?.split('_')) ;
            // const objNameSplt = (objName?.split(' ')) ;
            // console.log("objNameSplt79",objNameSplt);
            // const objFirstName = objNameSplt[0];
            // const objLastName = objNameSplt[2] ? objNameSplt[2] : objNameSplt[1];
            // const randomColor = Math.floor(Math.random()*16777215).toString(16);

            return (
              <Card className="actlogCrd margin-b-15" bsPrefix=" " key={childKey}>
                 {/* <OverlayTrigger trigger="click" rootClose placement="left" overlay={tooltip}>
                  <div className="circleNaIcn">
                  {objFirstName && Array.from(objFirstName[0])}
                  {objLastName && Array.from(objLastName[0])}
                  </div>
                  </OverlayTrigger> */}
                  <div className="circleTxt">
                <div className="subhed">
                {
                comment.createdByName !== "System" ?
                  <OverlayTrigger trigger={(comment.module == "Task" && comment.createdbyName == "System") ? "" : "click"} rootClose placement={this.props.windowSize.width < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
} overlay={tooltip}>
                    <span className="circleNa" style={{cursor: (comment.module == "Task" && comment.createdbyName == "System") ? "text": ""}} title={comment.module == "Task" ? comment.createdbyName : comment.createdByName}>
                    {comment.module == "Task"
                      ? comment.createdbyName
                      : comment.createdByName} {(comment.module == "Task" && comment.createdbyName == "System" ) ? "" : <ImInfo/>}
                   </span>
                  </OverlayTrigger>
                : <span className="margin-r-20">{comment.createdByName}</span>
                }
                  <span
                    className="margin-r-10 timeao"
                    title={hours >= 24 ? "" : endDateStr}
                  >
                    {hours >= 24 ? endDateStr : timeago}
                  </span>
                  <span className="timeao">{intval}</span>
                </div>
                {isRCATechniqueDescription?
                <div className="des">
                    <OverlayTrigger trigger="click" rootClose placement={this.props.windowSize.width < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
} overlay={rcaInfo}>
                    <span className="circleNa">
                      {this.props.translator['RCA Technique']} :  {RCAname} <span title={this.props.translator['Click to see RCA details']}><ImInfo/></span>
                    </span>
                    </OverlayTrigger>
                </div>
                :
                <div className="des">
                  <ReactReadMoreLess
                    charLimit={235}
                    onShowMore={this.props.onChange}
                    readMoreText={MoreArrow}
                    readLessText={LessArrow}
                    content={contentToShow}
                  />
                  {/* <ReadMore lines={5} onShowMore={this.props.onChange} text={MoreArrow}>
								{comment.from == "" ? comment.description : "Changed Status from " + comment.from + " to " + comment.to}
							</ReadMore> */}
                </div>}
                </div>
                {/* <div className='f-size-12' style={{"marginTop":"2px"}}>{timeago}</div> */}
              </Card>
            );
          })}
        </div>
      );
    });
  }

  onCheck(i) {
    //alert(i.target);
    if (i.target.checked == true) {
      this.setState({ isinternal: "Y", checkstatus: true });
    } else {
      this.setState({ isinternal: "N", checkstatus: false });
    }
  }
  render() {
    // console.log('*******', this.props);
    const { changeDetails: { status: changeStatus } = {} } = this.props;
    let readOnlyResponse = "";
    if (
      this.props.role_id &&
      this.props.taskDetails.statusId &&
      this.props.taskDetails.statusId > 0
    ) {
      readOnlyResponse = validateTaskField(
        this.props.role_id,
        this.props.taskDetails.statusId
      );
    }
    let checkbox = "";

    if (
      (this.props.module === "Fullfilment" || this.props.module === "Breakfix") ||
      (
        this.props.module != "ChangeManagement" &&
        this.props.module != "Investigation" &&
        !this.props.disableFieldsForGuestRole &&
        this.props.taskDetails &&
        this.props.taskDetails.workItemType != "Change" &&
        this.props.taskDetails.workItemType != "Problem"
      )
    )
      checkbox = (
        <Form.Check type="checkbox" 
          className="dCheckbox checkbox"
          checked={this.state.checkstatus}
          onClick={this.onCheck.bind(this)}
          label={this.props.translator["Internal"]}
          onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}
        ></Form.Check>
      );
    else checkbox = "";
    const condition1 = readOnlyResponse.activityJournal == true;
    const condition2 = this.props && this.props.fieldStatus && this.props.fieldStatus.activityJournal == false;
    const condition3 =  (this.props && this.props.taskDetails && this.props.taskDetails.statusId && (this.props.taskDetails.statusName == "Fixed" || this.props.taskDetails.statusName == "Closed" || this.props.taskDetails.statusId == taskStatusMapping["Completed"] || this.props.taskDetails.statusId == taskStatusMapping["Cancelled"] || this.props.taskDetails.statusName == "Rejected"));
    const condition4 = this.props && this.props.fieldStatus && this.props.fieldStatus.activityJournal == false;
    const condition5 = changeStatus && changeStatus == 'Cancelled';
    const enableTextArea = condition1 || condition2 || condition3 || condition4 || condition5;
    const hideSubmitButton = !enableTextArea;

    return (
      <div>
        {/* <div className="f-size-15">{this.props.translator['Post a comment']}</div> */}
        {(
          <div className="tbox">
            <div className="position-re">
              {this.state.comments.trim().length ? <Button bsPrefix=" " className="copyTxtbtn" title={this.props.translator['Copy To Clipboard']} onClick={this.copyText}><IoCopyOutline /></Button>:null}
              <Form.Control
                ref={(input) => (this.activityDescription = input)}
                as="textarea"
                rows="4"
                disabled={this.props.disableFieldsForGuestRole || enableTextArea}
                onChange={this.activityData.bind(this)}
                value={this.state.comments}
              />
            </div>
            {hideSubmitButton && <div className="btnNew margin-t-5">
              {/* {this.state.showLoader ?
							<div className="flRight">
								<Loader type="Watch" color="green" height={20} width={20} />
							</div> : <span></span>
						} */}

              {/* {this.state.showLoader ?
							<a title={this.props.translator['Post']} onClick=""><i className="fa fa-chevron-circle-right "></i></a>
							: <a title={this.props.translator['Post']} onClick={this.postActivityData.bind(this)}><i className="fa fa-spinner fa-spin "></i></a>
						} */}

              <div className="margin-t-5 display-inline-block float-r">
                {checkbox}
              </div>

              <Button
                className="rgSidrkBtn smallBtn margin-t-10"
                title={this.props.translator["Post"]}
                disabled={this.state.showLoader || this.props.disableFieldsForGuestRole}
                onClick={this.postActivityData.bind(this)}
              >
                {this.props.translator["Post"]}{" "}
                {this.state.showLoader ? (
                  <ImSpinner6 className="icn-spinner"/>
                ) : null}
              </Button>
            </div>}
          </div>
        )}

        {this.props.breakFixActivityLogDetails.length > 0 ? (
          <div
            role="contentinfo"
            aria-label="Panel Section"
            className="brkFix brkFixOrder panel-group margin-t-15 clearboth"
          >
            <Accordion
              tabindex="1"
              id="allcomments"
              accordion
              style={{ "border-top": "none" }}
            >
              <Accordion.Item eventKey="1">
                <Accordion.Header onClick={this.descactivitysort.bind(this)}>
                  <h4>
                    {this.props.translator["Comments"]}
                  </h4>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="margin-b-10 padding-b-5 border-b">
                    {this.props.aiSummaruzationEnabled && typeof this.props.taskDetails === 'undefined' && <Button onClick={this.onSummarizeClick} style={{top:"0px"}} bsPrefix=" " title={this.props.translator['Summarize']} className="showAlIcn f-size-13" disabled={this.state.showSummaryLoader}>{this.state.showSummaryLoader?<i className="fa fa-spinner fa-spin margin-r-5"></i>:<span className="icn"><AiIcon iconSize={18} /></span>}{this.state.showSummaryLoader?' ':null}{this.state.showSummaryLoader?this.props.translator['Summarizing']+'...':this.props.translator['Summarize']}</Button>}
                    <span className="float-r f-size-17">
                      <a
                        href="javascript:void(0)"
                        onClick={this.ascactivitysort.bind(this)}
                        className="black"
                        title={this.props.translator["Ascending"]}
                      >
                        <i className="fa fa-angle-up"></i>
                      </a>
                      &nbsp;&nbsp;
                      <a
                        href="javascript:void(0)"
                        onClick={this.descactivitysort.bind(this)}
                        className="black"
                        title={this.props.translator["Descending"]}
                      >
                        <i className="fa fa-angle-down"></i>
                      </a>
                    </span>
                    <span className="float-r margin-r-10"></span>
                  </div>
                  {/* <Scrollbars style={{height:"750"}} hideTracksWhenNotNeeded={true} className="sideScrl" > */}
                  {this.renderActivityLog(
                    this.props.breakFixActivityLogDetails
                  )}
                  {/* </Scrollbars> */}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        ) : null}
      </div>
    );
  }
}

export function mapStateToProps({ breakFixActivityLogDetails, windowSize }) {
  //  alert("breakFixEditDetails:"+breakFixActivityLogDetails.length);
  //console.log("breakFixEditDetails:"+breakFixActivityLogDetails.from);
  return { breakFixActivityLogDetails, windowSize };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadBreakFixActivityLogDetails,
      loadTMSActivityLogDetails,
      clearBreakfixActivityLogDetails,
      loadFulfillmentDetails, 
      loadStatusBasedHamburgerOptions,
      loadTimelineData,
      loadBreakFixEditDetails
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopoverActivityJournalData);
