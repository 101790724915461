
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import { gql } from "@apollo/client";

export const getApprovalSets = gql`
    query getApprovalSets($bfId: ID!, $compId: ID!, $modId: ID!, $status: ID!, $setNameOrCode: String) {
        getApprovalSetByNameOrCode(businessFunctionId: $bfId, companyId: $compId, moduleId: $modId, status: $status, setNameOrCode: $setNameOrCode) {
            sxId
            approvalSetName
            code
        }
    }
`;