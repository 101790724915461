
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect } from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { change, reduxForm } from "redux-form";
import { useDispatch } from "react-redux";
import { getLanguageString } from "../../../../common/helper";
import moment from "moment";
import Cookies from "universal-cookie";

const cookies = new Cookies();
let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));

dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();


const DatePickerField = (props) => {
  const dispatch = useDispatch();
  const { attributes, onValueChange, defaultValue, language, readOnly, nonEditable, className, defaultValues, id } = props;
  const parsedDefaultValue = defaultValue
  ? moment(defaultValue,jwtdf).toDate()
  : null;
  let format = attributes.format || "yyyy-MM-dd";
  if(jwtdf === 'YYYY-MM-DD'){
    format = attributes.format || "yyyy-MM-dd";
  }else if(jwtdf === 'DD-MM-YYYY'){
    format = attributes.format || "dd-MM-yyyy";
  }else if(jwtdf === 'MM-DD-YYYY'){
    format = attributes.format || "MM-dd-yyyy";
  }

  useEffect(() => {
    if (attributes?.dependsOn) {
      if (defaultValues[attributes?.dependsOn]) {
        setTimeout(() => {
          onValueChange({
            value: moment(defaultValues[attributes?.dependsOn], jwtdf).add(1, 'day').format(
              `${jwtdf}`
            )
          });
        }, 100);
      }
    }
  }, [defaultValues[attributes?.dependsOn]]);
  let minMax = {};
  let disableIfDependsOn = false;
  if(attributes?.validationType && !attributes?.dependsOn){
    if(attributes?.validationType?.id === 'disablePrevious'){
      minMax.min = new Date();
    }
  } else if(attributes?.validationType && attributes?.dependsOn){
    if(!defaultValues[attributes?.dependsOn]){
      disableIfDependsOn = true;
    }
    if(attributes?.validationType?.id === 'afterDepensOn'){
      minMax.min = moment(defaultValues[attributes?.dependsOn], jwtdf).add(1, 'day').toDate();
    }
  }
  
  return (
    <DatePicker
      value={parsedDefaultValue}
      placeholder={getLanguageString(language,attributes.placeholder)}
      format={format}
      disabled={attributes.disabled || nonEditable || disableIfDependsOn}
      onChange={(e) =>
        onValueChange({ value: moment(e.value).format(jwtdf) })
      }
      readOnly={readOnly}
      className={className}
      id={id}
      popupSettings={{appendTo: document.getElementById("skipdv")}}
      {...minMax}
    />
  );
};

export default DatePickerField;
