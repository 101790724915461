
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import {useSelector } from "react-redux";

import PindCategories from "../../../../common/header/pind-categories";
import Breadcrumbs from "../../../../common/header/breadcrumbs";
import Header from "./header";
import FormComponent from "./form";
import "_Css/form/_form.scss";

const Index = (props) => {
  const [companyTypeErr, setCompanyTypeErr] = useState('');
  const [companyValue, setCompanyValue] = useState([]);
  const [nameErr,setNameErr] = useState('');
  const [statusErr, setStatusErr] = useState('');
  const [addressErr,setAddressErr] = useState('');
  const [countryErr, setCountryErr] = useState('');
  const [stateErr,setStateErr] = useState('');
  const [cityErr, setCityErr] = useState('');
  const [zipErr, setZipErr] = useState('');
  const [supportHrsErr, setSupportHrsErr] = useState('');
  const [valueError, setValueError] = useState("");
  const tr = useSelector((state) => state.spcmReducer.tr);

  const setCompanyErrorColor = () => {
    if (companyValue.length > 0) {
      setCompanyTypeErr("");
    } else {
      setCompanyTypeErr("error");
    }
  };
  return (
    <main>
      <PindCategories />
      <Container fluid className="margin-b-15">
        <Breadcrumbs activePageName={"Create"} parentPageurl='/renderedView/67c5a2ebc293ba36df8ebe54' parentPageName={tr["Location"]} />
      </Container>

      <Container fluid>
        <Header
          setCompanyErrorColor={setCompanyErrorColor}
          setNameErr={setNameErr}
          setStatusErr={setStatusErr}
          setAddressErr={setAddressErr}
          setCountryErr={setCountryErr}
          setStateErr={setStateErr}
          setCityErr={setCityErr}
          setZipErr={setZipErr}
          setSupportHrsErr={setSupportHrsErr}
          setValueError={setValueError}
        />
        <Row>
          <Col md={8} xs={12}>
            <FormComponent
              nameErr={nameErr}
              setNameErr={setNameErr}
              statusErr={statusErr}
              setStatusErr={setStatusErr}
              addressErr={addressErr}
              setAddressErr={setAddressErr}
              countryErr={countryErr}
              setCountryErr={setCountryErr}
              stateErr={stateErr}
              setStateErr={setStateErr}
              cityErr={cityErr}
              setCityErr={setCityErr}
              zipErr={zipErr}
              supportHrsErr={supportHrsErr}
              setZipErr={setZipErr}
              setSupportHrsErr={setSupportHrsErr}
              setCompanyValue={setCompanyValue}
              companyTypeErr={companyTypeErr}
              setCompanyErrorColor={setCompanyErrorColor}
              setValueError={setValueError} 
              valueError={valueError}
            />
          </Col>
        </Row>
      </Container>
    </main>
  )
}

export default Index;
