
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import {Link} from 'react-router-dom';

import { GLOBAL } from "_Globals";

import { loadChangeRelatedWorkDetails } from '../../../../actions/changeManagement/cmdbchangeRelatedWorkAction';
import { loadChangeRelatedDataDetails } from '../../../../actions/changeManagement/cmdbchangeRelatedDataAction';
import Swal from 'sweetalert2';
import ReactReadMoreLess from '../../../../helpers/ReactReadMoreLess';
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
import { HiPlus } from "react-icons/hi";
import { PiListBulletsBold } from 'react-icons/pi';
import { changeStatusMapping } from '../../../common/helper';

const MoreArrow = <i title="More" className="fa fa-angle-double-down f-size-15" ></i>;

class PopoverWorkItemsData extends React.Component {
    constructor(props){
        super(props);
            this.state = {
                showUserData:false,
                toggleFormAndList:'list',
                showLoader:false
            };
            this.toggleFormAndList = this.toggleFormAndList.bind(this);
            GLOBAL.checkmoduleval="CMDB"
            GLOBAL.relatedfiltertext=""
    }
    toggleFormAndList() {
		if(this.state.toggleFormAndList == 'list')
			this.setState({toggleFormAndList:'form'});
		else
			this.setState({toggleFormAndList:'list'});
	}
    componentWillMount()
	{
		let itemId=this.props.itemId;
		this.props.loadChangeRelatedDataDetails(itemId,"ChangeManagement");
		this.props.loadChangeRelatedWorkDetails("","","CMDB",this.props.editChangeInitialData.consumerCompany);
		GLOBAL.relatedtextdisplay= <div className="f-size-18 margin-b-5">{this.props.translator['Related Config Items']}</div>
	}
    postRelatedData(brkid){
		let itemId=this.props.itemId;
		let ref=this;
		let payloadObj ={
			"ci_id": brkid,  //==> ID of CI related
			"wi_id_related":itemId, //==> ID of Work Item related
			"wi_id_number": ref.props.changeNumber,//  ==> Number of work item related
			"ci_id_reported": ref.props.createdOn,
			"created_by": "",
			"module": "ChangeManagement"
		}
		axios.post(GLOBAL.cmdbRelatedLogDeleteUrl,/* {
			"ci_id": itemId,
			"ci_id_related":brkid,
			"ci_id_number":ref.props.changeNumber,
			"ci_id_reported":ref.props.createdOn,
			"created_by": "",
			"module":"ChangeManagement"

		}*/
		payloadObj
		).then((response) =>{
			this.props.loadChangeRelatedWorkDetails("ci_name","-1","CMDB",this.props.editChangeInitialData.consumerCompany);
			GLOBAL.relatedtextdisplay= <div className="f-size-18 margin-b-5">{this.props.translator['Related Work Items']}</div>
			this.props.loadChangeRelatedDataDetails(itemId,"ChangeManagement");
			this.setState({toggleFormAndList:'list'});
		});
	}
    renderRelatedWorkLog(relatedLogData){
		if (!relatedLogData || !Array.isArray(relatedLogData.data)){
 		return (
 				<tr>
 					<td width="100%">
 						<div></div>
 					</td>
 				</tr>
 			);
 		}
	else{
		return relatedLogData.data.map((relatedObj,index)=>{

			let modnumber="",modid="";
			let modulesearchparam=GLOBAL.checkmoduleval;
			let createddate=relatedObj.CREATED_AT.substring(0, relatedObj.CREATED_AT.indexOf('.'));
			if(modulesearchparam=="Breakfix")
			{
			modnumber=relatedObj.breakfixNumber
			modid=relatedObj.breakfixId
		}
			if(modulesearchparam=="Fullfilment")
			{
			modnumber=relatedObj.itemNumber
			modid=relatedObj.itemId
		}
			if(modulesearchparam=="Investigation")
			{
			modnumber=relatedObj.investigationNumber
			modid=relatedObj.investigationId
		}
			if(modulesearchparam=="ChangeManagement")
	{
			modnumber=relatedObj.changeNumber
			modid=relatedObj.changeId
		}
		if(modulesearchparam=="CMDB")
{
		modnumber=relatedObj.CI_CODE
		modid=relatedObj.CI_ID
	}
    return(
	    <div className='margin-t-5 margin-b-10 border-b padding-b-5'>
			<Row>
				<Col md={10}>
					<Link to={"/editcmdb/"+relatedObj.CI_ID}>{relatedObj.CI_NAME} - {createddate}  - <span className="cursorPoint fw-300 cTxt" onClick={this.showUserDetails}>{relatedObj.CREATED_BY+" "}</span></Link>
					<div className="fw-300 cTxt">
					<ReactReadMoreLess 
						charLimit={150}
						readMoreText={MoreArrow}
						content={relatedObj.DESCRIPTION}
					/>
					</div>
					<div className="tbSerialTxt">
					Serial Number - {(relatedObj.SERIAL_NUMBER == "" || relatedObj.SERIAL_NUMBER == null) ? "NA" : relatedObj.SERIAL_NUMBER}
                	</div>
				</Col>
				<Col md={2} className="text-c"><a onClick={this.postRelatedData.bind(this,modid)} title={this.props.translator['Relate']}><i className="fa fa-chevron-circle-right f-size-16 theme1"></i></a></Col>
			</Row>
	    </div>);
    });
	}
	}
    deleteRelation(ci_id,ci_id_related) {

		axios.delete(GLOBAL.cmdbRelatedLogDeleteUrl+'?ci_id='+ci_id+'&ci_id_related='+ci_id_related+ '&module=ChangeManagement')
		.then((response) =>{
			if (!response || response.status != '200') {
				Swal.fire('Deletion failed.');
			}
			else {
				Swal.fire('deleted relation');
				let itemId=this.props.itemId;
				this.props.loadChangeRelatedDataDetails(itemId,"ChangeManagement");
				this.props.loadChangeRelatedWorkDetails("ci_name","-1","CMDB",this.props.editChangeInitialData.consumerCompany);
				GLOBAL.relatedtextdisplay= <div className="f-size-18 margin-b-5">{this.props.translator['Related Config Items']}</div>
			}
		});
	}
    renderAttList(attributeData){
		if(!attributeData){
			return(
					null
			);
		}

		const {
			editChangeInitialData: {
				ciId = ''
			} = {}
		} = this.props;

		return attributeData.map((objjectData,i)=>{

			let reldate=objjectData.CREATED_AT;
			let splitdate=reldate.split('.')[0];

			if(objjectData.CI_ID!=null && objjectData.CI_ID!="")
				{
					if((this.props && this.props.editChangeInitialData && this.props.editChangeInitialData.statusId && (this.props.editChangeInitialData.status == "Fixed" || this.props.editChangeInitialData.statusId == changeStatusMapping["Closed"] || this.props.editChangeInitialData.statusId == changeStatusMapping["Completed"] || this.props.editChangeInitialData.statusId == changeStatusMapping["Cancelled"]  || this.props.editChangeInitialData.statusId == changeStatusMapping["Rejected"]))){
						return(
							<tr>
								<td>
									{this.props.isCMDBRoleEnabled?<Link to={"/editcmdb/"+objjectData.CI_ID}>{objjectData.CI_NAME_RELATED}</Link>:objjectData.CI_NAME_RELATED}
									<div className="tbSerialTxt">
									Serial Number - {(objjectData.SERIAL_NUMBER_CI_NAME_RELATED  == "" || objjectData.SERIAL_NUMBER_CI_NAME_RELATED  == null) ? "NA" : objjectData.SERIAL_NUMBER_CI_NAME_RELATED }
									</div>
								</td>
								<td>{splitdate}</td>
								<td>{objjectData.CREATED_BY}</td>
								<td></td>
							</tr>
						);
					}
					else{
						return(
							<tr>
								<td>{this.props.isCMDBRoleEnabled?<Link to={"/editcmdb/"+objjectData.CI_ID}>{objjectData.CI_NAME_RELATED}</Link>:objjectData.CI_NAME_RELATED}
								<div className="tbSerialTxt">
								Serial Number - {(objjectData.SERIAL_NUMBER_CI_NAME_RELATED  == "" || objjectData.SERIAL_NUMBER_CI_NAME_RELATED  == null) ? "NA" : objjectData.SERIAL_NUMBER_CI_NAME_RELATED }
								</div>
								</td>
								<td>{splitdate}</td>
								<td>{objjectData.CREATED_BY}</td>
								{( this.props.fieldStatus.relateCis==true && !this.props.disableFieldsForGuestRole && objjectData['CI_ID'] != ciId ) ? 
								<td className="text-c f-size-13"><i onClick={this.deleteRelation.bind(this,objjectData.CI_ID,objjectData.CI_ID_RELATED)} className="fa fa-trash-o cursorPoint"></i></td>
								:
								<td></td>}
							</tr>
						);
					}
				}
			else
				return(
					<tr>
			    	</tr>
			  	);
		});
	}
    renderRelatedDataLog(relatedDataLog){
		if(relatedDataLog.length == 0){
 		return (
 				<div>{this.props.translator['Data not available.']}</div>
 			);
		 }

		else{
			return(
				<Table responsive striped bordered condensed hover className='f-size-13'>
					<thead>
						<tr className='f-size-12'>
							<th>CI Name</th>
							<th>{this.props.translator['Created On']}</th>
							<th>{this.props.translator['Related By']}</th>
							{this.props.fieldStatus.relateCis==true && !this.props.disableFieldsForGuestRole?<th className="text-c">{this.props.translator['Action']}</th>:null}
						</tr>
					</thead>
					<tbody>
						{this.renderAttList(relatedDataLog)}
					</tbody>
				</Table>
		  	);
		}
	}
    loaddata()
	{
		let itemId=this.props.itemId
		let modulesearch=GLOBAL.checkmoduleval;
		if(modulesearch=="Breakfix")
		{
			if(GLOBAL.relatedfilterval=="breakfixNumber")
			this.props.loadChangeRelatedWorkDetails(GLOBAL.relatedfilterval,GLOBAL.relatedfiltertext,"Breakfix");
			if(GLOBAL.relatedfilterval=="impactedUserid")
			this.props.loadChangeRelatedWorkDetails(GLOBAL.relatedfilterval,this.props.editChangeInitialData.requesterId,"Breakfix");
			if(GLOBAL.relatedfilterval=="serviceId")
			this.props.loadChangeRelatedWorkDetails(GLOBAL.relatedfilterval,this.props.editChangeInitialData.serviceId,"Breakfix");
		}
		if(modulesearch=="Fullfilment")
		{
			if(GLOBAL.relatedfilterval=="breakfixNumber")
			this.props.loadChangeRelatedWorkDetails("itemNumber",GLOBAL.relatedfiltertext,"Fullfilment");
			if(GLOBAL.relatedfilterval=="impactedUserid")
			this.props.loadChangeRelatedWorkDetails("requesterId",this.props.editChangeInitialData.requesterId,"Fullfilment");
			if(GLOBAL.relatedfilterval=="serviceId")
			this.props.loadChangeRelatedWorkDetails(GLOBAL.relatedfilterval,this.props.editChangeInitialData.serviceId,"Fullfilment");
		}
		if(modulesearch=="Investigation")
		{
			if(GLOBAL.relatedfilterval=="breakfixNumber")
			this.props.loadChangeRelatedWorkDetails("investigationNumber",GLOBAL.relatedfiltertext,"Investigation");
			if(GLOBAL.relatedfilterval=="impactedUserid")
			this.props.loadChangeRelatedWorkDetails(GLOBAL.relatedfilterval,this.props.editChangeInitialData.requesterId,"Investigation");
			if(GLOBAL.relatedfilterval=="serviceId")
			this.props.loadChangeRelatedWorkDetails(GLOBAL.relatedfilterval,this.props.editChangeInitialData.serviceId,"Investigation");
		}

		if(modulesearch=="ChangeManagement")
		{
			if(GLOBAL.relatedfilterval=="breakfixNumber")
			this.props.loadChangeRelatedWorkDetails("changeNumber",GLOBAL.relatedfiltertext,"ChangeManagement");
			if(GLOBAL.relatedfilterval=="impactedUserid")
			this.props.loadChangeRelatedWorkDetails("requesterId",this.props.editChangeInitialData.requesterId,"ChangeManagement");
			if(GLOBAL.relatedfilterval=="serviceId")
			this.props.loadChangeRelatedWorkDetails(GLOBAL.relatedfilterval,this.props.editChangeInitialData.serviceId,"ChangeManagement");
		}

		if(modulesearch=="CMDB")
		{
			if(GLOBAL.relatedfilterval=="ci_name")
			this.props.loadChangeRelatedWorkDetails("ci_name",GLOBAL.relatedfiltertext,"CMDB",this.props.editChangeInitialData.consumerCompany);
			if(GLOBAL.relatedfilterval=="class_name")
			this.props.loadChangeRelatedWorkDetails("class_name",GLOBAL.relatedfiltertext,"CMDB",this.props.editChangeInitialData.consumerCompany);

		}
		GLOBAL.relatedtextdisplay= <div className="f-size-18 margin-b-5"></div>
		this.props.loadChangeRelatedDataDetails("0","ChangeManagement");

	}
    loadrelateddata()
	{
		let itemId=this.props.itemId
		this.props.loadChangeRelatedWorkDetails("ci_name","-1","CMDB",this.props.editChangeInitialData.consumerCompany);
		GLOBAL.relatedtextdisplay= <div className="f-size-18 margin-b-5">{this.props.translator['Related Work Items']}</div>
		this.props.loadChangeRelatedDataDetails(itemId,"ChangeManagement");
	}
    checkfilter(i)
	{
		GLOBAL.relatedfilterval=i.target.value;
	}
	filtertext(i)
	{
		GLOBAL.relatedfiltertext=i.target.value;
	}
	checkmodule(i)
	{
		GLOBAL.checkmoduleval=i.target.value;
	}
    render() {
		let formVariable=<div>
			<div>
				<Row>
					<Col md={6} className="padding-r-0">
					<Form.Label>{this.props.translator['Search by']}</Form.Label>
					<Form.Select componentClass="select" onChange={this.checkfilter.bind(this)} >
					<option value="Select">Select</option>
					<option value="ci_name">CI Name</option>
					<option value="class_name">Class Name</option>
					</Form.Select>
					</Col>
					<Col md={6}><Form.Label>&nbsp;</Form.Label><Form.Control onChange={this.filtertext.bind(this)} placeholder="search" /></Col>
				</Row>
				<div className="btnNew margin-t-10">
                    &nbsp;
				<Button className="rgSidrkBtn smallBtn" bsSize="small" bsStyle="primary"  title={this.props.translator['Search']} disabled={this.props.showLoader} onClick={this.loaddata.bind(this)}>
					{this.props.translator['Search']} 
					{this.props.showLoader ? <ImSpinner6 className="icn-spinner"/> : null }
				</Button>
			</div>
             </div>
				{this.renderRelatedWorkLog(this.props.cmdbChangeRelatedWorkDetails)}
		</div>;
        
		return(
			<div className="margin-b-10">
				<div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
                    <ul>
					{(this.props && this.props.editChangeInitialData && this.props.editChangeInitialData.statusId && (this.props.editChangeInitialData.status == "Fixed" || this.props.editChangeInitialData.statusId == changeStatusMapping["Closed"] || this.props.editChangeInitialData.statusId == changeStatusMapping["Completed"] || this.props.editChangeInitialData.statusId == changeStatusMapping["Cancelled"]  || this.props.editChangeInitialData.statusId == changeStatusMapping["Rejected"] || this.props.disableFieldsForGuestRole)) ? null :
					    <li>
					        <Button title={this.state.toggleFormAndList=='list' ? this.props.translator['Related Config Items'] : this.props.translator['Relate Config Item']} 
					                onClick={this.toggleFormAndList} bsStyle="" bsClass="" bsPrefix=" " className="myBt">{this.props.fieldStatus.relateCis==true && !this.props.disableFieldsForGuestRole?
					                this.state.toggleFormAndList=='list' ? <HiPlus /> : <PiListBulletsBold />
					                :null}
					        </Button>
					    </li>
				    }
                        <li className="d-md-none">
                        <button
						    type='button'
                            title="Minimize the right panel"
                            bsClass=""
                            bsStyle=""
                            className="closerightPanelBtn"
                            onClick={() => {
                            this.props.rightEditPanel(false);
							this.props.changeActionView("");
                            }}
                        >
                            <IoClose/>
                        </button>
                        </li>
                    </ul>
                </div>
				<div className="rPageHeading"><div className="offNam margin-t-5 margin-b-5">{this.state.toggleFormAndList=='list' ? this.props.translator['Related Config Items'] : this.props.translator['Relate Config Items']}</div></div>
				<div className='rBoxGap'>
				{this.state.toggleFormAndList=='list' || this.props.disableFieldsForGuestRole ?
				this.renderRelatedDataLog(this.props.cmdbChangeRelatedDataDetails)
				:
				formVariable
				}
				</div>
			</div>
		);
	}
}
export function mapStateToProps({ cmdbChangeRelatedWorkDetails,cmdbChangeRelatedDataDetails,rightMenuShowLoader }){
    return { cmdbChangeRelatedWorkDetails,cmdbChangeRelatedDataDetails,showLoader:rightMenuShowLoader.loading };
}
function mapDispatchToProps(dispatch){
    return bindActionCreators({ loadChangeRelatedWorkDetails,loadChangeRelatedDataDetails}, dispatch);
}
export default connect(mapStateToProps,mapDispatchToProps)(PopoverWorkItemsData);