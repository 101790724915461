
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState, createRef } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import CmdbEditHeader from "./CmdbEditHeader.js";
import CmdbEditForm from "./CmdbEditForm.js";
import CmdbAttribute from "./rightSideItems/CmdbAttribute.js";
import CmdbRelatedWork from "./rightSideItems/CmdbRelatedWork.js";
import CmdbAuditLog from "./rightSideItems/CmdbAuditLog.js";
import CmdbRelateAssets from "./rightSideItems/CmdbRelateAssets.js";
import CmdbRelatedCIGraph from "./rightSideItems/CmdbRelatedCIGraph.js";
import axios from "axios";
import {
  loadCIEditDetails,
  loadModelName,
  getCmdbApprovals,
  getCmdbChainEntities,
  emptyCIEditDetailsReducer,
  loadCICompany,
  loadCIGroup,
  loadCICat,
  loadCICatInitial,
  loadCISCat,
  loadCISupCompany,
  loadCIClass,
  loadCIMenulistStatus,
  loadCIMenulistSubStatus,
  loadCIMetallic,
  loadCIEnv,
  loadCIManr,
  loadCIType,
  emptyCISupCompany,
  emptyCIGroup,
  emptyCICatReducer,
  emptyCISCatReducer,
} from "../../../actions/cmdb/lovAction.js";
import { bindActionCreators } from "redux";
import { getTimelineData } from "../../../actions/cmdb/cmdbTimelineAction.js";
import { connect } from "react-redux";
import CmdbProcessHierarchy from "../create/CmdbProcessHierarchy.js";
import CMDBTimeline from "./rightSideItems/CMDBTimeline.js";
import CMDBRelationship from "./rightSideItems/CMDBRelationship.js";
import CmdbCiApprovalsList from "./approvals/CmdbCiApprovalsList.js";
import CmdbAssetCiDetails from "./CmdbAssetCiDetails.js";
import CITaskPlan from "./taskPlan/TaskPlanListView.js";
import CMDBExternalSystemAttributes from "./CMDBExternalSystemattribute.js";
import {
  getTranslation,
  getTaskPlans,
  setLeftEditId,
} from "../../../actions/spcmActions.js";
import CmdbDisclaimerForm from "./disclaimers/CmdbDisclaimerForm.js";
//import Graph from 'react-graph-vis';
import { GLOBAL } from "_Globals";
import Spinner from "react-spinkit";
import LoadingIcons from "react-loading-icons";
import { resetCMDBQVFilters } from "../../../actions/cmdb/quickViewAction.js";
import CmdbAttachment from "./rightSideItems/CmdbAttachment.js";
import { navigationHooks } from "../../../helpers/NavigationHook.js";
import Breadcrumbs from "../../common/header/breadcrumbs.js";
import { setWipSearchApply } from "../../../actions/globalSearch/globalSearchAction.js";
import { useParams } from "react-router";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import TagDetails from "../../common/tags/TagDetails.js";
import "_Css/form/_form.scss";
import CopyCI from "./CopyCI.js";
import Cookies from "universal-cookie";
import ScheduleMaintenance from "./rightSideItems/CmdbMaintenanceSchedule.js";
import {CIGroups} from "./rightSideItems/CmdbCIGroups.js";
import GenerateBarcode from "./rightSideItems/CmdbGenerateBarcode.js";

const cookies = new Cookies();
let cookiesData = cookies.get("gph");
if (cookiesData) cookiesData = cookiesData.replace("s:", "");
if (cookiesData)
  cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf("."));
cookiesData = cookiesData.split("~");

let onContractRoleVisible = cookiesData[33] ? cookiesData[33].split(",") : [];
onContractRoleVisible = onContractRoleVisible.includes("68");

let onConfigManagerRoleVisible = cookiesData[33]
  ? cookiesData[33].split(",")
  : [];
onConfigManagerRoleVisible = onConfigManagerRoleVisible.includes("37");

let onAssetRoleVisible = cookiesData[33] ? cookiesData[33].split(",") : [];
onAssetRoleVisible = onAssetRoleVisible.includes("38");

let onConfigViewerRoleVisible = cookiesData[33]
  ? cookiesData[33].split(",")
  : [];
onConfigViewerRoleVisible = onConfigViewerRoleVisible.includes("39");

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});
const CmdbEditIndexWrap = () => {
  const [requestType, setRequestType] = useState("");
  const { ciId } = useParams();
  useEffect(() => {
    setRequestType(ciId);
  }, [ciId]);
  return (
    <>
      <CmdbEditIndex setRequestType={requestType} ciId={ciId} />
    </>
  );
};
export default CmdbEditIndexWrap;
class CmdbEditIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenu: "4",
      showHideAlfy: false,
      showHideRightNav: false,
      mobileLeftMenuFilter: false,
      actionView: "specifyAttributeValues",
      showGraphFlag: false,
      graph: {
        nodes: [],
        edges: [],
      },
      loading: true,
      toggleFormList: false,
      user_id: "",
      user_roles_id: "",
      isRightEditPanel: true,
      user_name: "",
      isRoleDisabled: false,
      isSupCompanyRequired: false,
      isSupGroupRequired: false,
      panelResize: "",
      // isLocationRequired: false
    };
    this.showHideAlfy = this.showHideAlfy.bind(this);
    this.showHideRightNav = this.showHideRightNav.bind(this);
    this.changeActionView = this.changeActionView.bind(this);
    this.showRightPaneAction = this.showRightPaneAction.bind(this);
    this.getData = this.getData.bind(this);
    this.closeGraphView = this.closeGraphView.bind(this);
    this.toggleFormListFunc = this.toggleFormListFunc.bind(this);
    // this.getUrlParams=this.getUrlParams.bind(this);
    this.props.getTranslation(this.props.lang);
    this.rightEditPanel = this.rightEditPanel.bind(this);
    // this.changeLocation=this.changeLocation.bind(this);
    this.changeSupCompany = this.changeSupCompany.bind(this);
    this.changeSupGroup = this.changeSupGroup.bind(this);
    GLOBAL.cmdbdiagram = "downward";
    this.ciId = navigationHooks.params.ciId;
    this.formikRef = createRef();
    //console.log("666666666666", this.ciId)
  }

  rightEditPanel(value) {
    this.setState({ isRightEditPanel: value });
  }
  componentWillReceiveProps(nextProps) {
    console.log("requestType", nextProps.ciId, nextProps.requestType);
    if (nextProps.ciId != this.props.ciId) {
      this.props.loadCIEditDetails(nextProps.ciId).then((res) => {
        try {
          if (res.status === 200 && res.data && res.data.data) {
            const ciDetails = res.data.data;

            this.props.loadCICompany();
            this.props.loadCIGroup(ciDetails.SUPPORT_COMPANY_ID);
            this.props.loadCISupCompany(ciDetails.COMPANY_ID);
            this.props.loadCICatInitial(
              ciDetails.CLASS_ID,
              ciDetails.COMPANY_ID,
              ciDetails.CATEGORY_NAME,
              (selectedCompanyId) => {
                this.props.loadCISCat(
                  ciDetails.CATEGORY_NAME,
                  ciDetails.CLASS_ID,
                  selectedCompanyId
                );
              }
            );

            let type;
            if (ciDetails.TYPE == "5") {
              type = "ASSET";
              this.setState({ citype: type });
            } else if (ciDetails.TYPE == "10") {
              type = "CI";
              this.setState({ citype: type });
            } else if (ciDetails.TYPE == "15") {
              type = "BOTH";
              this.setState({ citype: type });
            }
            // this.props.loadCIMenulistStatus("Status Transition", ciDetails.STATUS, type);
            // this.props.loadCIMenulistSubStatus("SubStatus List", ciDetails.STATUS);
            this.props.loadCIClass();
            this.props.loadCIMetallic();
            this.props.loadCIEnv();
            this.props.loadCIManr();
            this.props.resetCMDBQVFilters();
            this.props.loadCIType();
          }
        } catch (e) {
          console.error("error: ", e);
        }
      });
      this.props.loadModelName(nextProps.ciId);
      this.props.getTimelineData(nextProps.ciId);
      this.props.setWipSearchApply(false);
    }
  }
  componentWillMount() {
    let str = {};
    str.user_id = "";
    str = JSON.stringify(str);
    api.get("/api/userInfo", { headers: { query: str } }).then((response) => {
      console.log("CmdbUserInfo======", response.data.user_id);
      this.setState({
        user_id: response.data.user_id,
        user_roles_id: response.data.role_id,
        user_name: response.data.user_name,
      });
    });

    this.props.loadCIEditDetails(this.ciId).then((res) => {
      try {
        if (res.status === 200 && res.data && res.data.data) {
          const ciDetails = res.data.data;

          this.props.loadCICompany();
          this.props.loadCIGroup(ciDetails.SUPPORT_COMPANY_ID);
          this.props.loadCISupCompany(ciDetails.COMPANY_ID);
          this.props.loadCICatInitial(
            ciDetails.CLASS_ID,
            ciDetails.COMPANY_ID,
            ciDetails.CATEGORY_NAME,
            (selectedCompanyId) => {
              this.props.loadCISCat(
                ciDetails.CATEGORY_NAME,
                ciDetails.CLASS_ID,
                selectedCompanyId
              );
            }
          );

          let type;
          if (ciDetails.TYPE == "5") {
            type = "ASSET";
            this.setState({ citype: type });
          } else if (ciDetails.TYPE == "10") {
            type = "CI";
            this.setState({ citype: type });
          } else if (ciDetails.TYPE == "15") {
            type = "BOTH";
            this.setState({ citype: type });
          }
          // this.props.loadCIMenulistStatus("Status Transition", ciDetails.STATUS, type);
          this.props.loadCIMenulistStatus("Status List");
          this.props.loadCIMenulistSubStatus(
            "SubStatus List",
            ciDetails.STATUS
          );
          this.props.loadCIClass();
          this.props.loadCIMetallic();
          this.props.loadCIEnv();
          this.props.loadCIManr();
          this.props.resetCMDBQVFilters();
          this.props.loadCIType();
        }
      } catch (e) {
        console.error("error: ", e);
      }
    });

    this.props.loadModelName(this.ciId);

    this.props.getTimelineData(this.ciId);
    // this.props.getCmdbApprovals(this.props.params.ciId, '', '', 1, 10);
    // this.props.getTaskPlans(this.props.params.ciId, 'ChangeManagement_Template');
    // this.props.getCmdbChainEntities(this.props.params.ciId, '', '', 1, 10);
    this.props.setWipSearchApply(false);
  }

  componentWillUnmount() {
    this.props.emptyCIEditDetailsReducer();
    this.props.emptyCISupCompany();
    this.props.emptyCIGroup();
    this.props.emptyCICatReducer();
    this.props.emptyCISCatReducer();
  }

  componentDidMount() {
    if (onAssetRoleVisible || onConfigManagerRoleVisible) {
      this.setState({ isRoleDisabled: true });
    } else {
      this.setState({ isRoleDisabled: false });
    }
  }
  showHideAlfy(value) {
    this.setState({ showHideAlfy: value });
  }

  showHideRightNav(value) {
    this.setState({ showHideRightNav: !this.state.showHideRightNav });
  }

  mobileLeftMenuFilter() {
    this.setState({ mobileLeftMenuFilter: !this.state.mobileLeftMenuFilter });
  }

  toggleFormListFunc(value) {
    console.log("TOGGLE LIST AND FORM", this.state.toggleFormList);
    this.setState({ toggleFormList: value });
  }

  // changeLocation(value){
  // 	this.setState({isLocationRequired:value})
  // }

  changeSupCompany(value) {
    this.setState({ isSupCompanyRequired: value });
  }

  changeSupGroup(value) {
    this.setState({ isSupGroupRequired: value });
  }

  changeActionView(actionSelected) {
    this.setState({
      // actionView:actionSelected,
      actionView:
        actionSelected && actionSelected === "servicegraph"
          ? "servicediagram"
          : actionSelected,
      showGraphFlag:
        actionSelected && actionSelected === "servicegraph" ? true : false,
    });

    if (actionSelected && actionSelected === "servicegraph") {
      this.getData(this.ciId);
    }
    //alert("actionSelected"+actionSelected);
  }

  closeGraphView() {
    this.setState({
      showGraphFlag: false,
    });
  }

  showRightPaneAction(actionView) {
    let ref = this;
    switch (actionView) {
      case "tagDetails":
        return (
          <TagDetails
            companyId={this.props.CIEditDetails.COMPANY_ID}
            itemId={this.ciId}
            itemDetails={this.props.CIEditDetails}
            translator={this.props.tr}
            module="CMDB"
            mainModule="CMDB"
            rightEditPanel={this.rightEditPanel}
            userId={this.state.user_id}
            userName={this.state.user_name}
            isRoleDisabled={this.state.isRoleDisabled}
          />
        );

      case "provideAttachments":
        return (
          <CmdbAttachment
            translator={this.props.tr}
            user_id={this.state.user_id}
            user_roles_id={this.state.user_roles_id}
            CIEditDetails={this.props.CIEditDetails}
            rightEditPanel={this.rightEditPanel}
            isRoleDisabled={this.state.isRoleDisabled}
          />
        );

      case "servicegraph": {
        return null;
        // return (
        // 	<XsmBreakFixAction translator={this.props.tr} ciId={this.props.params.ciId} />
        // );
      }
      case "servicediagram":
        return (
          <CmdbProcessHierarchy
            translator={this.props.tr}
            rightEditPanel={this.rightEditPanel}
          />
        );
      case "CMDBTimeline":
        return (
          <CMDBTimeline
            translator={this.props.tr}
            CIEditDetails={this.props.CIEditDetails}
            rightEditPanel={this.rightEditPanel}
          />
        );
      case "CMDBRelationship":
        return (
          <CMDBRelationship
            translator={this.props.tr}
            ciId={this.ciId}
            rightEditPanel={this.rightEditPanel}
            isRoleDisabled={this.state.isRoleDisabled}
          />
        );
      case "CIApprovals":
        return (
          <CmdbCiApprovalsList
            CI_ID={this.ciId}
            CI_NAME={this.props.CIEditDetails.CI_NAME}
            COMPANY_ID={this.props.CIEditDetails.COMPANY_ID}
            COMPANY_NAME={this.props.CIEditDetails.COMPANY_NAME}
            CLASS_NAME={this.props.CIEditDetails.CLASS_NAME}
            CLASS_ID={this.props.CIEditDetails.CLASS_ID}
            toggleFormListFunc={this.toggleFormListFunc}
            toggleFormList={this.state.toggleFormList}
            rightEditPanel={this.rightEditPanel}
            isRoleDisabled={this.state.isRoleDisabled}
          />
        );
      case "taskPlan":
        return (
          <CITaskPlan
            tr={this.props.tr}
            CI_ID={this.ciId}
            CI_NAME={this.props.CIEditDetails.CI_NAME}
            STATUS={this.props.CIEditDetails.STATUS}
            SUB_STATUS={this.props.CIEditDetails.SUB_STATUS}
            COMPANY_ID={this.props.CIEditDetails.COMPANY_ID}
            rightEditPanel={this.rightEditPanel}
            isRoleDisabled={this.state.isRoleDisabled}
          />
        );
      case "external":
        return (
          <CMDBExternalSystemAttributes
            translator={this.props.tr}
            CIEditDetails={this.props.CIEditDetails}
            // CLIENT_CI_NUMBER={this.props.CIEditDetails.CLIENT_CI_NUMBER}
            // CLIENT_NAME={this.props.CIEditDetails.CLIENT_NAME}
            rightEditPanel={this.rightEditPanel}
          />
        );
        case "CopyCI":
        return (
          <CopyCI
            CIEditDetails={this.props.CIEditDetails}
            rightEditPanel={this.rightEditPanel}
            toggleFormListFunc={this.toggleFormListFunc}
            toggleFormList={this.state.toggleFormList}
            insideEditCi={true}
            changeActionView={this.changeActionView}
          />
        );
      // case 'chainEntities':
      // 	return (
      // 		<CIChainEntities
      // 			tr={this.props.tr}
      // 			CI_ID={this.props.params.ciId}
      // 			CI_NAME={this.props.CIEditDetails.CI_NAME}
      // 			STATUS={this.props.CIEditDetails.STATUS}
      // 			SUB_STATUS={this.props.CIEditDetails.SUB_STATUS}
      // 		/>
      // 	);
      case "assetCiDetails":
        this.props.setLeftEditId(-1);
        this.props.getCmdbApprovals(this.ciId, "", "", 1, 10);
        this.props.getTaskPlans(this.ciId, "ChangeManagement_Template");
        // this.props.getCmdbChainEntities(this.props.params.ciId, '', '', 1, 10);
        return (
          <CmdbAssetCiDetails
            changeActionView={this.changeActionView}
            toggleFormListFunc={this.toggleFormListFunc}
            CI_ID={this.ciId}
            rightEditPanel={this.rightEditPanel}
            isRoleDisabled={this.state.isRoleDisabled}
          />
        );
      case "Disclaimers":
        return (
          <CmdbDisclaimerForm
            tr={this.props.tr}
            changeActionView={this.changeActionView}
            CI_ID={this.ciId}
            STATUS={this.props.CIEditDetails.STATUS}
            SUB_STATUS={this.props.CIEditDetails.SUB_STATUS}
            rightEditPanel={this.rightEditPanel}
            isRoleDisabled={this.state.isRoleDisabled}
          />
        );
      case "specifyAttributeValues":
        return (
          <CmdbAttribute
            translator={this.props.tr}
            ciId={this.ciId}
            ciStatusName={this.props.CIEditDetails.STATUS_NAME}
            COMPANY_ID={this.props.CIEditDetails.COMPANY_ID}
            rightEditPanel={this.rightEditPanel}
            isRoleDisabled={this.state.isRoleDisabled}
          />
        );
      case "ciRelationships":
        return (
          <CmdbRelatedWork
            translator={this.props.tr}
            itemId={this.ciId}
            cieditdetails={this.props.CIEditDetails}
            rightEditPanel={this.rightEditPanel}
            isRoleDisabled={this.state.isRoleDisabled}
            companyId={this.props.CIEditDetails.COMPANY_ID}
          />
        );
      case "relatedOfferings":
        return (
          <CmdbRelateAssets
            translator={this.props.tr}
            ci_id={this.ciId}
            cieditdetails={this.props.CIEditDetails.COMPANY_ID}
            cieditdetailsdata={this.props.CIEditDetails}
            rightEditPanel={this.rightEditPanel}
            isRoleDisabled={this.state.isRoleDisabled}
          />
        );
      case "relatedCIs":
        return (
          <CmdbRelatedCIGraph
            translator={this.props.tr}
            itemId={this.ciId}
            cieditdetails={this.props.CIEditDetails}
            rightEditPanel={this.rightEditPanel}
          />
        );
      case "auditLog":
        return (
          <CmdbAuditLog
            translator={this.props.tr}
            ciId={this.ciId}
            module="CMDB"
            rightEditPanel={this.rightEditPanel}
            ciCode={this.props.CIEditDetails?.CI_CODE}
          />
        );
        case "MaintenanceSchedule":
          return (
            <ScheduleMaintenance
              translator={this.props.tr}
              ciId={this.ciId}
              module="CMDB"
              rightEditPanel={this.rightEditPanel}
              ciDetails={this.props.CIEditDetails}
              ciCode={this.props.CIEditDetails?.CI_CODE}
              formikRef={this.formikRef}
            />
          );
          case "CIGroups":
            return (
            <CIGroups  
              translator={this.props.tr}
              ciId={this.ciId}
              module="CMDB"
              rightEditPanel={this.rightEditPanel}
              ciCode={this.props.CIEditDetails?.CI_CODE}
              ciDetails={this.props.CIEditDetails}
            />);
      case "generateBarcode":
        return (
        <GenerateBarcode  
          translator={this.props.tr}
          ciId={this.ciId}
          module="CMDB"
          rightEditPanel={this.rightEditPanel}
        />);
      default:
        return (
          <CmdbProcessHierarchy
            translator={this.props.tr}
            rightEditPanel={this.rightEditPanel}
          />
        );
    }
  }

  getData(ciID) {
    //  console.log("GLOBAL.cmdbdiagram::::::"+GLOBAL.cmdbdiagram)

    // let ci_id=this.props.ciId;
    this.setState({ loading: true });
    let ci_id = ciID;

    fetch(GLOBAL.cmdbRelatedLogDeleteUrl + "?ci_id=" + ci_id + "&module=CMDB", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // console.log(response);
        if (response.ok) return response.json();
      })
      .then((data) => {
        // console.log(data);
        var node = [];
        var edge = [];
        var test = [];
        data.forEach(function (val) {
          if (!test[val.CI_ID] && val.CI_ID != null) {
            test[val.CI_ID] = 1;
            node.push({
              id: val.CI_ID,
              label: val.CI_NAME + "(" + val.CLASS_NAME + ")",
              shape: "image",
              image: { selected: val.URL, unselected: val.URL },
            });
          }
          if (!test[val.CI_ID_RELATED] && val.CI_ID_RELATED != null) {
            test[val.CI_ID_RELATED] = 1;
            node.push({
              id: val.CI_ID_RELATED,
              label: val.CI_NAME_RELATED + "(" + val.CLASS_NAME_RELATED + ")",
              shape: "image",
              image: { selected: val.URL_RELATED, unselected: val.URL_RELATED },
            });
          }
          edge.push({
            from: val.CI_ID,
            to: val.CI_ID_RELATED,
            label: val.R_NAME,
          });
        });

        var graph = {};
        graph.nodes = node;
        graph.edges = edge;
        this.setState({ graph: graph, loading: false });
      });

    // setTimeout(() => this.setState({ loading: false }), 5000);
  }

  render() {
    // console.log('CIEditDetails===========',this.props.CIEditDetails);

    if (this.props.isFetchingDetails) {
      return (
        <div className="rctLoader">
          <LoadingIcons.Bars height="5em" stroke="transparent" fill="#06bcee" />
          {/*<font color="green" ><b>{this.props.translator['There is no matching data available']}</b></font>*/}
          {/*<font color="green" ><b>Fetching available matching data</b></font>*/}
        </div>
      );
    }

    let options = {
      // autoResize: true,
      height: "600px",
      width: "100%",
      physics: {
        enabled: false,
      },
      layout: {
        hierarchical: {
          enabled: true,
          levelSeparation: 150,
          nodeSpacing: 100,
          treeSpacing: 200,
          blockShifting: true,
          edgeMinimization: true,
          parentCentralization: true,
          sortMethod: "directed",
          direction: "DU",
        },
      },

      edges: {
        color: "#000000",
        arrows: {
          to: { enabled: false },
          middle: { enabled: false },
          from: { enabled: false },
        },
      },
    };

    let events = {
      select: function (event) {
        let { nodes, edges } = event;
      },
    };

    const { loading } = this.state;

    return (
      <main>
        <div className="container-fluid margin-t-10 margin-b-15">
          <Breadcrumbs
            activePageName="Edit"
            parentPageurl="/cmdblist"
            parentPageName="Asset/Config Board"
          />
        </div>
        {Object.keys(this.props.CIEditDetails).length == 0 ? (
          <div className="container-fluid minHeightWIB text-c">
            You don't have access to view this CI
          </div>
        ) : (
          <div bsClass="" className="container-fluid minHeightWIB">
            <Form>
              {/* Home Page blue bar Section Start*/}
              <CmdbEditHeader
                translator={this.props.tr}
                ciId={this.ciId}
                isRoleDisabled={this.state.isRoleDisabled}
                // isLocationRequired={this.state.isLocationRequired}
                isSupCompanyRequired={this.state.isSupCompanyRequired}
                isSupGroupRequired={this.state.isSupGroupRequired}
                rightEditPanel={this.rightEditPanel}
                changeActionView={this.changeActionView}
                actionView={this.state.actionView}
                formikRef={this.formikRef}
              />
              {/* Home Page blue bar Section end*/}

              <PanelGroup direction="horizontal">
                <Panel
                  id="sidebar"
                  minSize={33}
                  order={1}
                  defaultSize={this.state.isRightEditPanel ? 67 : 100}
                  onResize={(size) => this.setState({ panelResize: size })}
                  className={
                    this.state.isRightEditPanel ? "isShowLeftPanel" : ""
                  }
                >
                  {this.state && this.state.showGraphFlag ? (
                    <Row>
                      {loading ? (
                        <div>
                          <h2>{this.props.tr["Loading Service Diagram..."]}</h2>
                          <Spinner spinnerName="three-bounce" />
                        </div>
                      ) : (
                        <div
                          className=""
                          style={{ border: "1px solid #c0c0c0", margin: "5px" }}
                        >
                          <div className="">
                            <Button
                              onClick={() => {
                                this.closeGraphView();
                              }}
                              style={{
                                margin: "0px 6px 0px 0px",
                                color: "#fff",
                                zIndex: 1000,
                                position: "absolute",
                                right: 0,
                                padding: "0 8px!important;",
                              }}
                              variant="danger"
                              bsSize="small"
                              className=""
                            >
                              X
                            </Button>
                          </div>
                        </div>
                      )}
                    </Row>
                  ) : null}
                  {this.state.showGraphFlag == false ? (
                    <CmdbEditForm
                      isFetchingDetails={this.props.isFetchingDetails}
                      // onLocationChange={this.changeLocation}
                      onSupCompanyChange={this.changeSupCompany}
                      onSupGroupChange={this.changeSupGroup}
                      translator={this.props.tr}
                      ciId={this.ciId}
                      cidetails={this.props.CIEditDetails}
                      rightEditPanel={this.rightEditPanel}
                      formikRef={this.formikRef}
                      panelResize={this.state.panelResize}
                    />
                  ) : null}
                </Panel>
                {this.state.isRightEditPanel ? (
                  <>
                    <PanelResizeHandle className="isShowLeftPanel resizeHandle">
                      <div className="outlne">
                        <div className="handIcn">
                          <i
                            className="fa fa-chevron-left"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </PanelResizeHandle>
                    <Panel minSize={33} order={2} defaultSize={33}>
                      <div className="stickyArea rBoxStyle">
                        {this.showRightPaneAction(this.state.actionView)}
                      </div>
                    </Panel>
                  </>
                ) : (
                  ""
                )}
              </PanelGroup>
            </Form>
          </div>
        )}
        {/* {homepagelocation == "/unauthorized" || homepagelocation === "/userActivationPending" ? null : <FooterMain isLogout={GLOBAL.isLogout} />} */}
      </main>
    );
  }
}

export function mapStateToProps(state) {
  let id = navigationHooks.params.ciId;
  // console.log("id>>>...", id)
  return {
    CIEditDetails: state.CIEditDetails,
    //spcmReducer:state.spcmReducer,
    tr: state.spcmReducer.tr,
    lang: state.spcmReducer.lang,
    isFetchingDetails: state.isFetchingDetails.isFetching,
    ciID: id,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadCIEditDetails,
      getTimelineData,
      getTranslation,
      loadModelName,
      getCmdbApprovals,
      getTaskPlans,
      setLeftEditId,
      getCmdbChainEntities,
      emptyCIEditDetailsReducer,
      loadCICompany,
      loadCIGroup,
      loadCICat,
      loadCICatInitial,
      loadCISCat,
      loadCISupCompany,
      loadCIClass,
      loadCIMenulistStatus,
      loadCIMenulistSubStatus,
      loadCIMetallic,
      loadCIEnv,
      loadCIManr,
      loadCIType,
      resetCMDBQVFilters,
      emptyCISupCompany,
      emptyCIGroup,
      emptyCICatReducer,
      emptyCISCatReducer,
      setWipSearchApply,
    },
    dispatch
  );
}

CmdbEditIndex = connect(mapStateToProps, mapDispatchToProps)(CmdbEditIndex);
