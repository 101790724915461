
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef } from "react";
import { ImSpinner6 } from "react-icons/im";
import { freezeNotApplicable, replaceVariables } from "../../studio/utils";
const LinkComponent = ({
  item,
  buttonClicked,
  attributes,
  renderComponent,
  clickedButton,
  field,
  defaultValues,
  rowData
}) => {
  let type;
  let showSpinner = false;
  let returnData = {};
  const editRef = useRef(null);
  const closeRef = useRef(null);
  const saveObjRef = useRef(null);
  if (item && typeof item.buttonType == "object") {
    type = item?.buttonType?.id;
  }
  if (attributes?.href || attributes?.target) {
    delete attributes["href"];
    delete attributes["target"];
  }
   if(item?.attributes?.id===clickedButton?.attributes?.id){
    showSpinner = true;
   }
   let editable = true;
    if(defaultValues && defaultValues.hasOwnProperty('edittable') && !freezeNotApplicable.includes(item?.buttonType?.id)){
       editable = defaultValues?.edittable;
       if(!editable){
       attributes["class"] = attributes?.class+" inactive";
      }
    }

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.ctrlKey && event.key === "m") {
          event.preventDefault();
          if (closeRef.current) {
            closeRef.current.focus();
          }
        }

        if (event.ctrlKey && event.key === "q") {
          event.preventDefault();
          if (saveObjRef.current) {
            saveObjRef.current.focus();
          }
        }
        if (event.key === "Enter" && document.activeElement === closeRef.current) {
          event.preventDefault();
          if (closeRef.current) {
            closeRef.current.click();
          }
        }

        if (event.key === "Enter" && document.activeElement === saveObjRef.current) {
          event.preventDefault();
          if (saveObjRef.current) {
            saveObjRef.current.click();
          }
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, []);
  
  if (item?.passingKeys) {
    const passingKeys = item?.passingKeys?.split(",");
    if (passingKeys && passingKeys.length > 0) {
      passingKeys.forEach((key) => {
        if (rowData && rowData.hasOwnProperty(key)) {
          returnData[key] = rowData[key];
        } else if (defaultValues.hasOwnProperty(key)) {
          returnData[key] = defaultValues[key];
        }
      });
    }
  } else if (field) {
    returnData = field;
  } else {
    returnData = defaultValues;
  }
  if(rowData) {
    returnData = { ...returnData, ...rowData };
  }

  if (item?.buttonType?.id === "clearAllFilter" && !defaultValues?.showClearFilter) {
    return <></>;
  }
  
  if (item?.buttonType?.id === "download" && !defaultValues?.showClearFilter) {
    return <a href={replaceVariables(item?.redirectUrl,{...returnData})} download {...attributes}>{renderComponent(item?.components || [])}</a>;
  }
  if (
    returnData.hasOwnProperty(item?.buttonHideKey) &&
    returnData[item?.buttonHideKey]
  ) {
    return null;
  }
  return (
    <a
      onClick={() => {
        if (editable) {
          buttonClicked(type, item, returnData);
        }
      }}
      href={void 0}
      tabIndex={0}
      {...attributes}
      ref={(el) => {
        if (type === "link" && item?.shortcutKey) {
          closeRef.current = el;
        } else if (type === "edit" && item?.shortcutKey) {
          editRef.current = el;
        } else if (type === "save" && item?.shortcutKey) {
          saveObjRef.current = el;
        }
      }}
    >
      {showSpinner ? (
        <ImSpinner6 className="icn-spinner" /> 
      ) : (
        renderComponent(item?.components || [], false, returnData)
      )}
    </a>
  );
};

export default LinkComponent;
