
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
// import {toast} from 'react-toastify';

import React from "react";
import { opsCategoryKeysMap } from "../components/common/helper";
// export function notifyMessage(msg, type) {
//     const options = {
//         autoClose: 10000,
//         type: type,
//         hideProgressBar: false,
//         position: toast.POSITION.TOP_CENTER
//     };

//     toast(msg, options);
// }
export function validateTaskField(role_id, status) {
    //USER ROLE ID = 12,16,19,43,51
    // Manager ROLE ID = 13,14,17,20,53,44,52
    let readonly = "";
    if (role_id.indexOf("13") >= 0 || role_id.indexOf("14") >= 0 || role_id.indexOf("17") >= 0 || role_id.indexOf("20") >= 0 || role_id.indexOf("44") >= 0 || role_id.indexOf("52") >= 0 || role_id.indexOf("53") >= 0) {
        if (status == 1 || status == 2 || status == 7 || status==8 || status==9) { //1=>Assigned, 2=>In progress, 7=>On Hold
            return {
                requesterName: true,
                taskTypeId: true,
                eventName: true,
                sequenceNumber: true,
                hopCount: true,
                companyName: true,

                serviceName: false,
                impactedCi: false,
                statusId: false,
                summary: false,
                assignedToGroupId: false,
                assignedToId: false,
                expectedStartDate: false,
                expectedDueDate: false,
                actualStartDate: false,
                actualEndDate: false,
                additionalParams: false,
                attachedFile: false,
                output_key: false,
                output_value: false,
                activityJournal: false
            };
        } else if (status == 6) { //Draft

            return {
                requesterName: true,
                taskTypeId: true,
                eventName: true,
                sequenceNumber: true,
                hopCount: true,
                companyName: true,
                statusId: true,

                serviceName: false,
                impactedCi: false,
                summary: false,
                assignedToGroupId: false,
                assignedToId: false,
                expectedStartDate: false,
                expectedDueDate: false,
                actualStartDate: false,
                actualEndDate: false,
                additionalParams: false,
                attachedFile: false,
                output_key: false,
                output_value: false,
                activityJournal: false
            };
        } else if (status == 3 || status == 4) { // 3=> complete, 4=> Cancelled
            return {
                requesterName: true,
                taskTypeId: true,
                eventName: true,
                sequenceNumber: true,
                hopCount: true,
                companyName: true,
                statusId: true,
                serviceName: true,
                impactedCi: true,
                summary: true,
                assignedToGroupId: true,
                assignedToId: true,
                expectedStartDate: true,
                expectedDueDate: true,
                actualStartDate: true,
                actualEndDate: true,
                additionalParams: true,
                attachedFile: true,
                output_key: true,
                output_value: true,
                activityJournal: true
            };
        } else {
            return {
                requesterName: false,
                taskTypeId: false,
                eventName: false,
                sequenceNumber: false,
                hopCount: false,
                companyName: false,
                statusId: false,
                serviceName: false,
                impactedCi: false,
                summary: false,
                assignedToGroupId: false,
                assignedToId: false,
                expectedStartDate: false,
                expectedDueDate: false,
                actualStartDate: false,
                actualEndDate: false,
                additionalParams: false,
                attachedFile: false,
                output_key: false,
                output_value: false,
                activityJournal: false
            };
        }

    } else if (role_id.indexOf("12") >= 0 || role_id.indexOf("16") >= 0 || role_id.indexOf("19") >= 0 || role_id.indexOf("43") >= 0 || role_id.indexOf("51") >= 0) {
        if (status == 1 || status == 2 || status == 7) { //1=>Assigned, 2=>In progress, 7=>On Hold
            return {
                requesterName: true,
                taskTypeId: true,
                eventName: true,
                sequenceNumber: true,
                hopCount: true,
                companyName: true,

                serviceName: false,
                impactedCi: false,
                statusId: false,
                summary: false,
                assignedToGroupId: false,
                assignedToId: false,
                expectedStartDate: false,
                expectedDueDate: false,
                actualStartDate: false,
                actualEndDate: false,
                additionalParams: false,
                attachedFile: false,
                output_key: false,
                output_value: false,
                activityJournal: false
            };
        } else if (status == 6) { //Draft
            return {
                requesterName: true,
                taskTypeId: true,
                eventName: true,
                sequenceNumber: true,
                hopCount: true,
                companyName: true,
                statusId: true,

                serviceName: false,
                impactedCi: false,
                summary: false,
                assignedToGroupId: false,
                assignedToId: false,
                expectedStartDate: false,
                expectedDueDate: false,
                actualStartDate: false,
                actualEndDate: false,
                additionalParams: false,
                attachedFile: false,
                output_key: false,
                output_value: false,
                activityJournal: false
            };
        } else if (status == 3 || status == 4) { // 3=> complete, 4=> Cancelled
            return  {
                requesterName: true,
                taskTypeId: true,
                eventName: true,
                sequenceNumber: true,
                hopCount: true,
                companyName: true,
                statusId: true,
                serviceName: true,
                impactedCi: true,
                summary: true,
                assignedToGroupId: true,
                assignedToId: true,
                expectedStartDate: true,
                expectedDueDate: true,
                actualStartDate: true,
                actualEndDate: true,
                additionalParams: true,
                attachedFile: true,
                output_key: true,
                output_value: true,
                activityJournal: true
            };
        } else {
            return {
                requesterName: false,
                taskTypeId: false,
                eventName: false,
                sequenceNumber: false,
                hopCount: false,
                companyName: false,
                statusId: false,
                serviceName: false,
                impactedCi: false,
                summary: false,
                assignedToGroupId: false,
                assignedToId: false,
                expectedStartDate: false,
                expectedDueDate: false,
                actualStartDate: false,
                actualEndDate: false,
                additionalParams: false,
                attachedFile: false,
                output_key: false,
                output_value: false,
                activityJournal: false
            };
        }
    } else {
        return {
            requesterName: false,
            taskTypeId: false,
            eventName: false,
            sequenceNumber: false,
            hopCount: false,
            companyName: false,
            statusId: false,
            serviceName: false,
            impactedCi: false,
            summary: false,
            assignedToGroupId: false,
            assignedToId: false,
            expectedStartDate: false,
            expectedDueDate: false,
            actualStartDate: false,
            actualEndDate: false,
            additionalParams: false,
            attachedFile: false,
            output_key: false,
            output_value: false,
            activityJournal: false
        };
    }

}

export function updateWIBSearchData(payload, module = '', component = 'globalSearch'){
  function getOperator(key){
    switch(key){
        // Breakfix Properties
        case 'status':
        case 'priorityId':
        case 'spGroupId':
          return 'in';
        case 'cimGroup':
        case 'criticality':
        // Fulfillment Properties
        case 'itemStatus':
        case 'asgGroupid':
        // Problem Properties
        case 'priority':
        case 'asgPmGroupid':
        // Change Properties
        case 'impGroupid':
        case 'changeGroupid':
        case 'changeType':
            return 'in';

        case 'createdOn':
        case 'expStartDate':
        case 'cabDate':
            return 'between';

        // Breakfix Properties
        // case 'requestNumber':
        // case 'breakfixNumber':
        case 'consumerCompanyName':
        case 'description':
        case 'serviceName':
        case 'ciName':
        case 'cimUsername':
        case 'spUsername':
        case 'impactedUsername':
        // Fulfillment Properties
        // case 'orderNumber':
        // case 'itemNumber':
        case 'offeringNameSx':
        // case 'requesterName':
        case 'asgusername':
        // Problem Properties
        // case 'investigationNumber':
        case 'ciServiceName':
        case 'asgPmName':
        case 'createdByName':
        // Change Properties
        // case 'changeNumber':
        case 'summary':
        case 'requesterName':
        case Object.values(opsCategoryKeysMap).includes(key) && key:
            return 'ilike';

        case 'orderNumber':
        case 'itemNumber':
        case 'requestNumber':
        case 'breakfixNumber':
        case 'investigationNumber':
        case 'changeNumber':
        {
            if(component == 'wib'){
                return 'endsWith';
            }
            else{
                return 'equals';
            }
        }

        // Breakfix Properties
        case 'spUserid':
        // Fulfillment Properties
        case 'asgUserid':
        // Problem Properties
        case 'asgPmId':
        // Change Properties
        case 'impUserid':
        case 'changeUserid':
            return 'equal';
        case 'spGroupid':
          return 'in';

        default:
            return 'equal';
    }
}
function getKey(key, module){
    switch(key){
        case 'createdOn':
            return 'createdDate';
        case 'priorityId':
            return 'priority';
        default:
            if((module == 'problem' || module == 'change') && key == 'ciServiceName'){
                return 'ciName';
            }
            return key;
    }
}
function getValue(key, value){
    switch(key){
        // Breakfix Properties
        case 'status':
        case 'priorityId':
        case 'spGroupid':
        case 'cimGroup':
        // case 'criticality':
        // Fulfillment Properties
        case 'itemStatus':
        case 'asgGroupid':
        // Problem Properties
        case 'priority':
        case 'asgPmGroupid':
        // Change Properties
        case 'impGroupid':
        case 'changeGroupid':
        case 'changeType':
            return value.split(',');

        case 'criticality':
            return value.split(',').map( a => a.charAt(0).toUpperCase() + a.substr(1) );

        case 'createdOn':
        case 'expStartDate':
        case 'cabDate':
            return value.split('&');

        case 'offeringNameSx':
        case 'asgusername':
        case 'asgPmName':
        case 'createdByName':
        case 'requesterName':
            //let val = value.replace(/[`*'"\[\]]/gi, '');
            return [value];

        default:
            return [value];
    }
}
    try{
        // console.log('*******', {payload, module, component});
        const newAPIAllowedModules = ['incident', 'fulfillment', 'problem', 'change'];
        if(newAPIAllowedModules.indexOf(module) == -1){
            return {data: payload, newAPI: false };
        }
       
        // console.log('payload - ', payload);
        let sortByProperty = {
            'incident': 'breakfixId',
            'fulfillment': 'itemId',
            'problem': 'investigationId',
            'change': 'changeId',
        };
        let resultPayload = {};
        let searchResult = [];
        let keys = payload['searchByList'].split(',');
        let values = payload['multipleValueList'].split('|');
        keys.forEach((item, index) => {
            // console.log('***', item, index, values[index]);
            let obj = {};
            // obj['key'] = (item == "createdOn") ? "createdDate" : item;
            obj['key'] = getKey(item, module) || "";
            obj['operator'] = getOperator(item);
            obj['value'] = getValue(item, values[index]);
            searchResult.push(obj);
        });
        resultPayload['searchQueryDTOs'] = searchResult;
        resultPayload['size'] = payload['size'];
        resultPayload['currentPage'] = payload['currentPage'];
        // resultPayload['sortBy'] = "createdDate"; // payload['sortBy'];
        resultPayload['sortBy'] = sortByProperty[module];
        resultPayload['orderBy'] = payload['orderBy'];
        resultPayload['queryType'] = '2';
        resultPayload['externalAttributes'] = payload?.externalAttributes;
        // resultPayload['isNestedRequired'] = payload['isNestedRequired'];
        // console.log('resultPayload - ', resultPayload);
        // return resultPayload;
        return {data: resultPayload, newAPI: true };
    }catch(e){
        console.error(e);
        // return {};
        return {data: payload, newAPI: false };
    }
}

export const allIncidentStatuses =(tr)=> {
    return  [
    { label: tr["Submitted"], value: "10" },
    { label: tr["Pending"], value: "20" },
    { label: tr["In Progress"], value: "15" },
    { label: tr["Cancelled"], value: "35" },
    { label: tr["Closed"], value: "30" },
    { label: tr["Draft"], value: "-20" },
    { label: tr["Fixed"], value: "25" }
  ];
}

export const allFulfillmentStatus = [
  { label: "Approved", value: "90" },
  { label: "Cancelled", value: "85" },
  { label: "Closed", value: "80" },
  { label: "Draft", value: "-20" },
  { label: "Fulfilled", value: "75" },
  { label: "On Hold", value: "60" },
  { label: "Pending Approval", value: "65" },
  { label: "Referred Back", value: "100" },
  { label: "Rejected", value: "95" },
  { label: "Under Fulfillment", value: "70" },
  { label: "Submitted", value: "50" },
];

export function removeSearchValue(keys, searchKeys, searchValues) {
  if (searchKeys && searchValues) {
    let lists = searchKeys.split(",");
    let values = searchValues.split("|");
    let index = -1;
    if (Array.isArray(lists) && Array.isArray(keys)) {
      keys.forEach((key) => {
        switch (key) {
          case "itemStatus":
            index = lists.findIndex((value) => value === "itemStatus");
            if (index !== -1) {
              lists.splice(index, 1);
              values.splice(index, 1);
            }
            break;
          case "asgGroupid":
            index = lists.findIndex((value) => value === "asgGroupid");
            if (index !== -1) {
              lists.splice(index, 1);
              values.splice(index, 1);
            }
            break;
          case "status":
            index = lists.findIndex((value) => value === "status");
            if (index !== -1) {
              lists.splice(index, 1);
              values.splice(index, 1);
            }
            break;

          case "priority":
            index = lists.findIndex((value) => value === "priority");
            if (index !== -1) {
              lists.splice(index, 1);
              values.splice(index, 1);
            }
            break;

          case "criticality":
            index = lists.findIndex((value) => value === "criticality");
            if (index !== -1) {
              lists.splice(index, 1);
              values.splice(index, 1);
            }
            break;
          case "cimGroup":
            index = lists.findIndex((value) => value === "cimGroup");
            if (index !== -1) {
              lists.splice(index, 1);
              values.splice(index, 1);
            }
            break;
          case "spGroupid":
            index = lists.findIndex((value) => value === "spGroupid");
            if (index !== -1) {
              lists.splice(index, 1);
              values.splice(index, 1);
            }
            break;
          case "asgPmGroupid":
            index = lists.findIndex((value) => value === "asgPmGroupid");
            if (index !== -1) {
              lists.splice(index, 1);
              values.splice(index, 1);
            }
            break;
          case "changeType":
            index = lists.findIndex((value) => value === "changeType");
            if (index !== -1) {
              lists.splice(index, 1);
              values.splice(index, 1);
            }
            break;
          case "changeGroupid":
            index = lists.findIndex((value) => value === "changeGroupid");
            if (index !== -1) {
              lists.splice(index, 1);
              values.splice(index, 1);
            }
            break;
          case "impGroupid":
            index = lists.findIndex((value) => value === "impGroupid");
            if (index !== -1) {
              lists.splice(index, 1);
              values.splice(index, 1);
            }
            break;
          case "impUserid":
            index = lists.findIndex((value) => value === "impUserid");
            if (index !== -1) {
              lists.splice(index, 1);
              values.splice(index, 1);
            }
            break;
          case "changeUserid":
            index = lists.findIndex((value) => value === "changeUserid");
            if (index !== -1) {
              lists.splice(index, 1);
              values.splice(index, 1);
            }
            break;
          default:
            break;
        }
      });
      let searchByList = lists.join(",");
      let multipleValueList = values.join("|");
      return { searchByList, multipleValueList };
    }
  }
}
export const allInvStatus = [
    { label: "Cancelled", value: "70" },
    { label: "Closed", value: "65" },
    { label: "Corrected", value: "60" },
    { label: "Corrective Action Not required", value: "55" },
    { label: "Root Cause Identified", value: "45" },
    { label: "Under Corrective Action", value: "50" },
    { label: "Under Investigation", value: "40" },
    { label: "Under Review", value: "35" },
    { label: "Draft", value: "30" },
  ];

export const allChangeStatus = [
    { label: "Cancelled", value: "75" },
    { label: "Completed", value: "70" },
    { label: "Draft", value: "30" },
    { label: "Referred Back", value: "95" },
    { label: "Rejected", value: "50" },
    { label: "Scheduled", value: "45" },
    { label: "Under Implementation", value: "55" },
    { label: "Under PIR", value: "65" },
    { label: "Under Review", value: "35" },
    { label: "Submitted", value: "25" },
    { label: "Test", value: "20" },
  ];

  export const allChangeType = [
    { label: "Emergency", value: "30" },
    { label: "Normal", value: "25" },
    { label: "Standard", value: "20" },
    { label: "Latent", value: "35" },
];
export const mailboxImpact = {
  "20": "Significant",
  "30": "Low",
  "25": "Moderate"
}
export const mailboxUrgency = {
  "5": "High",
  "10": "Moderate",
  "15": "Low"
}

export const setLocalStorageGroupFilterCheck = (key,group) => {
  if (Array.isArray(group) && group.length > 0) {
    localStorage.setItem(key, true);
  } else {
    localStorage.removeItem(key);
  }
};
export const statusInitialOptions = [
  {label: 'Draft', value: 'Draft'},
  {label: 'Deployed', value: 'Deployed'},
  {label: 'Obsolete', value: 'Obsolete'},
  {label: 'Proposed', value: 'Proposed'},
  {label: 'Suspended', value: 'Suspended'}
];

export const workItemInitialOptions = [
  {label: 'Service Request', value: 'serviceRequest'},
  {label: 'Incident', value: 'incidentRequest'},
  {label: 'Standard Change', value: 'standardChange'}
];

export const featuredInitialOptions = [
  {label: 'Yes', value: 'Yes'},
  {label: 'No', value: 'No'}
];

export const allStatusTask = (tr) => {
  return [
    { label: tr["Assigned"], value: "1" },
    { label: tr["Cancelled"], value: "4" },
    { label: tr["Completed"], value: "3" },
    { label: tr["Draft"], value: "6" },
    { label: tr["In Progress"], value: "2" },
    { label: tr["On Hold"], value: "7" },
    { label: tr["Submitted"], value: "0" },
    { label: tr["In Review"], value: "8" },
    { label: tr["Reviewed"], value: "9" }
  ];
};


export const taskWorkItemType = [
  { label: "Change", value: "ChangeManagement" },
  { label: "Fulfillment", value: "Fullfilment" },
  { label: "Incident", value: "Breakfix" },
  { label: "Problem", value: "Investigation" },
];


export const setIsChangeGrpImpFilterAppliedPayload = (key,grpApplied,searchOnGroupArr)=>{
  if(searchOnGroupArr.length>0){
    if(!grpApplied.includes(key)){
      grpApplied.push(key)
    }
  }
  else{
    if(grpApplied.includes(key)){
      const index = grpApplied.findIndex(res=>res===key);
      grpApplied.splice(index,1)
    }
  }
  return grpApplied;
}

export const getColorCode = (status) => {
  switch (status) {
    case "IN PROGRESS":
    case "PENDING":
    case "PAUSED":
      return <span className="greyDot dotLineHeight">&nbsp;</span>;
    case "ALREADY BREACHED":
      return <span className="redDot dotLineHeight">&nbsp;</span>;
    case "BREACHED":
      return <span className="redDot dotLineHeight">&nbsp;</span>;
    case "MET":
      return <span className="greenDot dotLineHeight">&nbsp;</span>;
    default:
      return <span className=""></span>;
  }
};

export const validateEmail = (email) => {
  let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;
  return email.match(mailformat);
};

export const validateFulFillerRole = (roles) => {
  const existingRoles = [
    53, 43, 44, 12, 13, 14, 16, 17, 19, 20, 51, 52, 84, 56, 61, 62, 63, 64, 65,
  ];
  let allowed = false;
  existingRoles.forEach((value) => {
    if (roles.includes(value.toString())) {
      allowed = true;
    }
  });
  return allowed;
};




export const builderForms = [
  {
    name:"Initial",
    assets: [],
    styles: [
      {
        selectors: [{ name: "gjs-row", private: 1 }],
        style: {
          display: "table",
          "padding-top": "10px",
          "padding-right": "10px",
          "padding-bottom": "10px",
          "padding-left": "10px",
          width: "100%",
        },
      },
      {
        selectors: [{ name: "gjs-cell", private: 1 }],
        style: { width: "100%", display: "block" },
        mediaText: "(max-width: 768px)",
        atRuleType: "media",
      },
      {
        selectors: ["gjs-cell30"],
        style: { width: "100%", display: "block" },
        mediaText: "(max-width: 768px)",
        atRuleType: "media",
      },
      {
        selectors: ["gjs-cell70"],
        style: { width: "100%", display: "block" },
        mediaText: "(max-width: 768px)",
        atRuleType: "media",
      },
      {
        selectors: [{ name: "gjs-cell", private: 1 }],
        style: { width: "8%", display: "table-cell", height: "75px" },
      },
      {
        selectors: ["form-control"],
        style: {
          float: "none",
          position: "static",
          width: "300px",
          height: "30px",
          margin: "0 0 0 10px",
          "font-size": "18px",
        },
      },
    ],
    pages: [
      {
        frames: [
          {
            component: {
              type: "wrapper",
              stylable: [
                "background",
                "background-color",
                "background-image",
                "background-repeat",
                "background-attachment",
                "background-position",
                "background-size",
              ],
              attributes: { id: "ikft" },
              components: [
                { type: "form", attributes: { method: "get", id: "isma" } },
                { type: "form", attributes: { method: "get" } },
                {
                  name: "Row",
                  droppable: ".gjs-cell",
                  resizable: {
                    tl: 0,
                    tc: 0,
                    tr: 0,
                    cl: 0,
                    cr: 0,
                    bl: 0,
                    br: 0,
                    minDim: 1,
                  },
                  classes: [{ name: "gjs-row", private: 1 }],
                  attributes: { id: "iqzz" },
                  components: [
                    {
                      name: "Cell",
                      draggable: ".gjs-row",
                      resizable: {
                        tl: 0,
                        tc: 0,
                        tr: 0,
                        cl: 0,
                        cr: 1,
                        bl: 0,
                        br: 0,
                        minDim: 1,
                        bc: 0,
                        currentUnit: 1,
                        step: 0.2,
                      },
                      classes: [{ name: "gjs-cell", private: 1 }],
                      attributes: { id: "invl" },
                      components: [
                        {
                          type: "input",
                          void: true,
                          classes: ["form-control"],
                          attributes: {
                            type: "text",
                            required: true,
                            name: "name",
                            placeholder: "Name",
                          },
                        },
                      ],
                    },
                    {
                      name: "Cell",
                      draggable: ".gjs-row",
                      resizable: {
                        tl: 0,
                        tc: 0,
                        tr: 0,
                        cl: 0,
                        cr: 1,
                        bl: 0,
                        br: 0,
                        minDim: 1,
                        bc: 0,
                        currentUnit: 1,
                        step: 0.2,
                      },
                      classes: [{ name: "gjs-cell", private: 1 }],
                      attributes: { id: "ii5s" },
                      components: [
                        {
                          type: "input",
                          void: true,
                          classes: ["form-control"],
                          attributes: {
                            type: "text",
                            required: true,
                            name: "email",
                            placeholder: "Email",
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  name: "Row",
                  droppable: ".gjs-cell",
                  resizable: {
                    tl: 0,
                    tc: 0,
                    tr: 0,
                    cl: 0,
                    cr: 0,
                    bl: 0,
                    br: 0,
                    minDim: 1,
                  },
                  classes: [{ name: "gjs-row", private: 1 }],
                  attributes: { id: "ikdlx" },
                  components: [
                    {
                      name: "Cell",
                      draggable: ".gjs-row",
                      resizable: {
                        tl: 0,
                        tc: 0,
                        tr: 0,
                        cl: 0,
                        cr: 1,
                        bl: 0,
                        br: 0,
                        minDim: 1,
                        bc: 0,
                        currentUnit: 1,
                        step: 0.2,
                      },
                      classes: [{ name: "gjs-cell", private: 1 }],
                      attributes: { id: "ikrxl" },
                      components: [
                        {
                          type: "input",
                          void: true,
                          classes: ["form-control"],
                          attributes: {
                            type: "text",
                            required: true,
                            name: "age",
                            placeholder: "Age",
                          },
                        },
                      ],
                    },
                    {
                      name: "Cell",
                      draggable: ".gjs-row",
                      resizable: {
                        tl: 0,
                        tc: 0,
                        tr: 0,
                        cl: 0,
                        cr: 1,
                        bl: 0,
                        br: 0,
                        minDim: 1,
                        bc: 0,
                        currentUnit: 1,
                        step: 0.2,
                      },
                      classes: [{ name: "gjs-cell", private: 1 }],
                      attributes: { id: "ie72m" },
                      components: [
                        {
                          type: "input",
                          void: true,
                          classes: ["form-control"],
                          attributes: {
                            type: "text",
                            required: true,
                            name: "mobile",
                            placeholder: "Phone No",
                            minLength: "10",
                            maxLength: "10",
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  name: "Row",
                  droppable: ".gjs-cell",
                  resizable: {
                    tl: 0,
                    tc: 0,
                    tr: 0,
                    cl: 0,
                    cr: 0,
                    bl: 0,
                    br: 0,
                    minDim: 1,
                  },
                  classes: [{ name: "gjs-row", private: 1 }],
                  attributes: { id: "ik9bb" },
                  components: [
                    {
                      name: "Cell",
                      draggable: ".gjs-row",
                      resizable: {
                        tl: 0,
                        tc: 0,
                        tr: 0,
                        cl: 0,
                        cr: 1,
                        bl: 0,
                        br: 0,
                        minDim: 1,
                        bc: 0,
                        currentUnit: 1,
                        step: 0.2,
                      },
                      classes: [{ name: "gjs-cell", private: 1 }],
                      attributes: { id: "ie8bi" },
                      components: [
                        {
                          type: "input",
                          void: true,
                          classes: ["form-control"],
                          attributes: {
                            type: "text",
                            required: true,
                            name: "fatherName",
                            placeholder: "Father Name",
                          },
                        },
                      ],
                    },
                    {
                      name: "Cell",
                      draggable: ".gjs-row",
                      resizable: {
                        tl: 0,
                        tc: 0,
                        tr: 0,
                        cl: 0,
                        cr: 1,
                        bl: 0,
                        br: 0,
                        minDim: 1,
                        bc: 0,
                        currentUnit: 1,
                        step: 0.2,
                      },
                      classes: [{ name: "gjs-cell", private: 1 }],
                      attributes: { id: "ikiz7" },
                      components: [
                        {
                          type: "input",
                          void: true,
                          classes: ["form-control"],
                          attributes: {
                            type: "text",
                            required: true,
                            name: "motherName",
                            placeholder: "Mother Name",
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  name: "Row",
                  droppable: ".gjs-cell",
                  resizable: {
                    tl: 0,
                    tc: 0,
                    tr: 0,
                    cl: 0,
                    cr: 0,
                    bl: 0,
                    br: 0,
                    minDim: 1,
                  },
                  classes: [{ name: "gjs-row", private: 1 }],
                  attributes: { id: "ihso1" },
                  components: [
                    {
                      name: "Cell",
                      draggable: ".gjs-row",
                      resizable: {
                        tl: 0,
                        tc: 0,
                        tr: 0,
                        cl: 0,
                        cr: 1,
                        bl: 0,
                        br: 0,
                        minDim: 1,
                        bc: 0,
                        currentUnit: 1,
                        step: 0.2,
                      },
                      classes: [{ name: "gjs-cell", private: 1 }],
                      attributes: { id: "izzac" },
                      components: [
                        {
                          type: "input",
                          void: true,
                          classes: ["form-control"],
                          attributes: {
                            type: "text",
                            required: true,
                            name: "address",
                            placeholder: "Address",
                          },
                        },
                      ],
                    },
                    {
                      name: "Cell",
                      draggable: ".gjs-row",
                      resizable: {
                        tl: 0,
                        tc: 0,
                        tr: 0,
                        cl: 0,
                        cr: 1,
                        bl: 0,
                        br: 0,
                        minDim: 1,
                        bc: 0,
                        currentUnit: 1,
                        step: 0.2,
                      },
                      classes: [{ name: "gjs-cell", private: 1 }],
                      attributes: { id: "ineef" },
                      components: [
                        {
                          type: "textarea",
                          attributes: {
                            id: "i6w7j",
                            name: "description",
                            placeholder: "Description",
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  name: "Row",
                  droppable: ".gjs-cell",
                  resizable: {
                    tl: 0,
                    tc: 0,
                    tr: 0,
                    cl: 0,
                    cr: 0,
                    bl: 0,
                    br: 0,
                    minDim: 1,
                  },
                  classes: [{ name: "gjs-row", private: 1 }],
                  attributes: { id: "igipg" },
                  components: [
                    {
                      name: "Cell",
                      draggable: ".gjs-row",
                      resizable: {
                        tl: 0,
                        tc: 0,
                        tr: 0,
                        cl: 0,
                        cr: 1,
                        bl: 0,
                        br: 0,
                        minDim: 1,
                        bc: 0,
                        currentUnit: 1,
                        step: 0.2,
                      },
                      classes: [{ name: "gjs-cell", private: 1 }],
                      attributes: { id: "imyky" },
                      components: [
                        {
                          type: "button",
                          attributes: { type: "button", id: "itkvg" },
                          text: "Save",
                          components: [{ type: "textnode", content: "Save" }],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            id: "lKDiH1JiaCwsjimZ",
          },
        ],
        type: "main",
        id: "rvPZWN6qlkRTTOuI",
      },
    ],
  },
  {
    name:"Advanced",
    assets: [],
    styles: [
      {
        selectors: [{ name: "gjs-row", private: 1 }],
        style: {
          display: "table",
          "padding-top": "10px",
          "padding-right": "10px",
          "padding-bottom": "10px",
          "padding-left": "10px",
          width: "100%",
        },
      },
      {
        selectors: [{ name: "gjs-cell", private: 1 }],
        style: { width: "100%", display: "block" },
        mediaText: "(max-width: 768px)",
        atRuleType: "media",
      },
      {
        selectors: ["gjs-cell30"],
        style: { width: "100%", display: "block" },
        mediaText: "(max-width: 768px)",
        atRuleType: "media",
      },
      {
        selectors: ["gjs-cell70"],
        style: { width: "100%", display: "block" },
        mediaText: "(max-width: 768px)",
        atRuleType: "media",
      },
      {
        selectors: [{ name: "gjs-cell", private: 1 }],
        style: { width: "8%", display: "table-cell", height: "75px" },
      },
      {
        selectors: ["form-control"],
        style: {
          float: "none",
          position: "static",
          width: "300px",
          height: "30px",
          margin: "0 0 0 10px",
          "font-size": "18px",
        },
      },
    ],
    pages: [
      {
        frames: [
          {
            component: {
              type: "wrapper",
              stylable: [
                "background",
                "background-color",
                "background-image",
                "background-repeat",
                "background-attachment",
                "background-position",
                "background-size",
              ],
              attributes: { id: "ikft" },
              components: [
                { type: "form", attributes: { method: "get", id: "isma" } },
                { type: "form", attributes: { method: "get" } },
                {
                  name: "Row",
                  droppable: ".gjs-cell",
                  resizable: {
                    tl: 0,
                    tc: 0,
                    tr: 0,
                    cl: 0,
                    cr: 0,
                    bl: 0,
                    br: 0,
                    minDim: 1,
                  },
                  classes: [{ name: "gjs-row", private: 1 }],
                  attributes: { id: "iqzz" },
                  components: [
                    {
                      name: "Cell",
                      draggable: ".gjs-row",
                      resizable: {
                        tl: 0,
                        tc: 0,
                        tr: 0,
                        cl: 0,
                        cr: 1,
                        bl: 0,
                        br: 0,
                        minDim: 1,
                        bc: 0,
                        currentUnit: 1,
                        step: 0.2,
                      },
                      classes: [{ name: "gjs-cell", private: 1 }],
                      attributes: { id: "invl" },
                      components: [
                        {
                          type: "input",
                          void: true,
                          classes: ["form-control"],
                          attributes: {
                            type: "text",
                            required: true,
                            name: "name",
                            placeholder: "Name",
                          },
                        },
                      ],
                    },
                    {
                      name: "Cell",
                      draggable: ".gjs-row",
                      resizable: {
                        tl: 0,
                        tc: 0,
                        tr: 0,
                        cl: 0,
                        cr: 1,
                        bl: 0,
                        br: 0,
                        minDim: 1,
                        bc: 0,
                        currentUnit: 1,
                        step: 0.2,
                      },
                      classes: [{ name: "gjs-cell", private: 1 }],
                      attributes: { id: "ii5s" },
                      components: [
                        {
                          type: "input",
                          void: true,
                          classes: ["form-control"],
                          attributes: {
                            type: "text",
                            required: true,
                            name: "email",
                            placeholder: "Email",
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  name: "Row",
                  droppable: ".gjs-cell",
                  resizable: {
                    tl: 0,
                    tc: 0,
                    tr: 0,
                    cl: 0,
                    cr: 0,
                    bl: 0,
                    br: 0,
                    minDim: 1,
                  },
                  classes: [{ name: "gjs-row", private: 1 }],
                  attributes: { id: "ikdlx" },
                  components: [
                    {
                      name: "Cell",
                      draggable: ".gjs-row",
                      resizable: {
                        tl: 0,
                        tc: 0,
                        tr: 0,
                        cl: 0,
                        cr: 1,
                        bl: 0,
                        br: 0,
                        minDim: 1,
                        bc: 0,
                        currentUnit: 1,
                        step: 0.2,
                      },
                      classes: [{ name: "gjs-cell", private: 1 }],
                      attributes: { id: "ikrxl" },
                      components: [
                        {
                          type: "input",
                          void: true,
                          classes: ["form-control"],
                          attributes: {
                            type: "text",
                            required: true,
                            name: "age",
                            placeholder: "Age",
                          },
                        },
                      ],
                    },
                    {
                      name: "Cell",
                      draggable: ".gjs-row",
                      resizable: {
                        tl: 0,
                        tc: 0,
                        tr: 0,
                        cl: 0,
                        cr: 1,
                        bl: 0,
                        br: 0,
                        minDim: 1,
                        bc: 0,
                        currentUnit: 1,
                        step: 0.2,
                      },
                      classes: [{ name: "gjs-cell", private: 1 }],
                      attributes: { id: "ie72m" },
                      components: [
                        {
                          type: "input",
                          void: true,
                          classes: ["form-control"],
                          attributes: {
                            type: "text",
                            required: true,
                            name: "mobile",
                            placeholder: "Phone No",
                            minLength: "10",
                            maxLength: "10",
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  name: "Row",
                  droppable: ".gjs-cell",
                  resizable: {
                    tl: 0,
                    tc: 0,
                    tr: 0,
                    cl: 0,
                    cr: 0,
                    bl: 0,
                    br: 0,
                    minDim: 1,
                  },
                  classes: [{ name: "gjs-row", private: 1 }],
                  attributes: { id: "ik9bb" },
                  components: [
                    {
                      name: "Cell",
                      draggable: ".gjs-row",
                      resizable: {
                        tl: 0,
                        tc: 0,
                        tr: 0,
                        cl: 0,
                        cr: 1,
                        bl: 0,
                        br: 0,
                        minDim: 1,
                        bc: 0,
                        currentUnit: 1,
                        step: 0.2,
                      },
                      classes: [{ name: "gjs-cell", private: 1 }],
                      attributes: { id: "ie8bi" },
                      components: [
                        {
                          type: "input",
                          void: true,
                          classes: ["form-control"],
                          attributes: {
                            type: "text",
                            required: true,
                            name: "fatherName",
                            placeholder: "Father Name",
                          },
                        },
                      ],
                    },
                    {
                      name: "Cell",
                      draggable: ".gjs-row",
                      resizable: {
                        tl: 0,
                        tc: 0,
                        tr: 0,
                        cl: 0,
                        cr: 1,
                        bl: 0,
                        br: 0,
                        minDim: 1,
                        bc: 0,
                        currentUnit: 1,
                        step: 0.2,
                      },
                      classes: [{ name: "gjs-cell", private: 1 }],
                      attributes: { id: "ikiz7" },
                      components: [
                        {
                          type: "input",
                          void: true,
                          classes: ["form-control"],
                          attributes: {
                            type: "text",
                            required: true,
                            name: "motherName",
                            placeholder: "Mother Name",
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  name: "Row",
                  droppable: ".gjs-cell",
                  resizable: {
                    tl: 0,
                    tc: 0,
                    tr: 0,
                    cl: 0,
                    cr: 0,
                    bl: 0,
                    br: 0,
                    minDim: 1,
                  },
                  classes: [{ name: "gjs-row", private: 1 }],
                  attributes: { id: "ihso1" },
                },
                {
                  name: "Row",
                  droppable: ".gjs-cell",
                  resizable: {
                    tl: 0,
                    tc: 0,
                    tr: 0,
                    cl: 0,
                    cr: 0,
                    bl: 0,
                    br: 0,
                    minDim: 1,
                  },
                  classes: [{ name: "gjs-row", private: 1 }],
                  attributes: { id: "igipg" },
                  components: [
                    {
                      name: "Cell",
                      draggable: ".gjs-row",
                      resizable: {
                        tl: 0,
                        tc: 0,
                        tr: 0,
                        cl: 0,
                        cr: 1,
                        bl: 0,
                        br: 0,
                        minDim: 1,
                        bc: 0,
                        currentUnit: 1,
                        step: 0.2,
                      },
                      classes: [{ name: "gjs-cell", private: 1 }],
                      attributes: { id: "imyky" },
                      components: [
                        {
                          type: "button",
                          attributes: { type: "button", id: "itkvg" },
                          text: "Submit",
                          components: [{ type: "textnode", content: "Submit" }],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            id: "lKDiH1JiaCwsjimZ",
          },
        ],
        type: "main",
        id: "rvPZWN6qlkRTTOuI",
      },
    ],
  }
];