
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button, Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { reduxForm } from "redux-form";
import Swal from "sweetalert2";
import { deleteGroupData, updateGroupAction } from "../../../../../actions/foundation/groupAction";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { ImSpinner6 } from "react-icons/im";
import { IoSaveOutline, IoClose } from "react-icons/io5";
import { HiOutlineTrash } from "react-icons/hi";
import { LuActivitySquare } from "react-icons/lu";
import { PiTagBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import { MyContext } from '_MyContext';


const HeaderSec = (props) => {
  const navigate = useNavigate();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const dispatch = useDispatch();
  const params = useParams();
  const context = useContext(MyContext);
  const closeButton = useRef(null);
  const saveObjRef = useRef(null);

	useEffect(() => {
		context.keyDownHandler(closeButton.current,"","",saveObjRef.current);
    context.newTabPrevent();
	}, []);

  const submit = async (values) => {
    // console.log("CheckUpdated Group", values);
    let id = params.id
    let showGroupNamePopUp = values.nameToEdit.trim() != values.name.trim();
    let showStatusPopUp = (values.statusToEdit.trim() != values.status.trim()) && values.status == 0 ? true : false;
    let individualAssignValue = values.IndivAssign;
    let autoAssignAlgo = values.autoAssignAlgo ? values.autoAssignAlgo : "";
    let escalationPlan = values.EscPlan ? values.EscPlan : "";
    let aiosemApiCall = values.aiosemCall;
    let isValid = true;
    if (!values.name || values.name == '' || values.name.trim() == '') {
      props.setNameErr("error");
      isValid = false;
    } else {
      props.setNameErr("");
    }

    if (values.groupTypeLabel == '50') {
      if (!values.forum_name || values.forum_name == "") {
        props.setForumErrorColor();
        isValid = false;
      }
      if (!values.category_name || values.category_name == "") {
        props.setCategoryErrorColor();
        isValid = false;
      }
    }
    if (!values.status || values.status == '') {
      props.setStatusErr("error");
      isValid = false;
    } else {
      props.setStatusErr("");
    }
    if (values.IndivAssign == "Yes") {
      if (!autoAssignAlgo || autoAssignAlgo == '') {
        props.setAutoAlgoErr("error");
        isValid = false;
      } else {
        props.setAutoAlgoErr("");
      }
    }

    if (isValid == true) {
      if (showGroupNamePopUp == true) {
        Swal.fire({
          title: tr["Modified group name will be updated in all the modules where group name is stored. Since a large number of records (Work Items, Assignment Rules, SLA Definitions etc.) will be updated, please modify the group name in off business hours so that all the records are updated at once without impacting application performance."],
          width: 550,
          padding: '15px',
          showDenyButton: true,
          confirmButtonText: tr['I agree, update the group name now'],
          denyButtonText: tr['Cancel, I will update the group name in off business hours'],
          customClass: {
            title: "justifyText"
          }
        }).then((result) => {
          if (result.isConfirmed) {
            if (showStatusPopUp == true) {
              Swal.fire({
                title: tr["Group is a reference attribute on Work Items, CIs, Rules etc. Before deactivating a group, all the open transactions and other related records. should either be updated with a valid group or deactivated. Please confirm if the impact analysis is completed and necessary updates have been done."],
                width: 550,
                padding: '15px',
                showDenyButton: true,
                confirmButtonText: tr['I confirm, deactivate the group'],
                denyButtonText: tr['Cancel'],
                customClass: {
                  title: "justifyText"
                }
              }).then((result) => {
                if (result.isConfirmed) {
                  callUpdateGroup(id, values, individualAssignValue, autoAssignAlgo, aiosemApiCall);
                } else if (result.isDenied) {
                  // navigate("/group");
                  return false;
                }
              })
            } else {
              callUpdateGroup(id, values, individualAssignValue, autoAssignAlgo, aiosemApiCall);
            }
          } else if (result.isDenied) {
            // navigate("/group");
            return false;
          }
        })
      } else if (showStatusPopUp == true) {
        Swal.fire({
          title: tr["Group is a reference attribute on Work Items, CIs, Rules etc. Before deactivating a group, all the open transactions and other related records. should either be updated with a valid group or deactivated. Please confirm if the impact analysis is completed and necessary updates have been done."],
          width: 550,
          padding: '15px',
          showDenyButton: true,
          confirmButtonText: tr['I confirm, deactivate the group'],
          denyButtonText: tr['Cancel'],
          customClass: {
            title: "justifyText"
          }
        }).then((result) => {
          if (result.isConfirmed) {
            callUpdateGroup(id, values, individualAssignValue, autoAssignAlgo, aiosemApiCall,escalationPlan);
          } else if (result.isDenied) {
            // navigate("/group");
            return false;
          }
        })
      } else {
        callUpdateGroup(id, values, individualAssignValue, autoAssignAlgo, aiosemApiCall,escalationPlan);
      }
    }
  };

  const callUpdateGroup = (id, values, individualAssignValue, autoAssignAlgo, aiosemApiCall,escalationPlan) => {
    let postjson = {
      id: id,
      name: values.name.trim(),
      description: values.description,
      company: values.company_name,
      roles: null,
      active: values.status,
      activeArr: null,
      flagBatchUpdate: false,
      filterby: null,
      filterbytext: null,
      pagesize: 0,
      eventNameDefault: null,
      groupType: values.groupTypeLabel,
      groupTypeId: values.groupTypeLabel,
      forum: values.groupTypeLabel == '50' ? values.forum_name : "",
      forumId: values.groupTypeLabel == '50' ? values.forum_id : 0,
      category: values.groupTypeLabel == '50' ? values.category_name : "",
      categoryId: values.groupTypeLabel == '50' ? values.category_id : 0,
      group_function: null,
      businessFunctionId: null,
      managerId: null,
      supportGroup: null,
      emailAddress: values.email_address ? values.email_address.trim() : null,
      objectId: null,
      isexternal: values.isexternal,
      groupFunction: null,
      groupcode: values.code,
      nameToEdit: values.nameToEdit.trim(),
    };
    dispatch(updateGroupAction(postjson, individualAssignValue, autoAssignAlgo, aiosemApiCall,escalationPlan));
  }

  const onDeleteClick = () => {
    const id = window.location.href.split("/").pop();
    Swal.fire({
      title: ["On deleting a group, all the associated roles and users also get deleted. Are you sure you want to delete?"],
      width: 550,
      padding: '15px',
      showDenyButton: true,
      confirmButtonText: tr['Yes'],
      denyButtonText: tr['No'],
      customClass: {
        title: "center"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteGroupData(id))
      } else if (result.isDenied) {

      }
    })
  };
  let { handleSubmit, submitting } = props;

  // const handleAuditBtn = () => {
  //   props.setShowAuditLog(true);
  // }
  // const handleGroupDetailBtn = () => {
  //   props.setShowAuditLog(false);
  // }
  // console.log("Active Class", props.showAuditLog);
  return (
    <>
      <Row className="margin-b-15">
        <Col lg={4} md={6} sm={6} xs={12}>
          <h1 bsClass="" className="sPageHeading1">{tr["Group"]}</h1>
        </Col>
        <Col lg={8} md={6} sm={6} xs={12}>
          <div className="paHedFilter">
            <div className="toprigLnk margin-t-0">
              <Nav className="icnlnk" as="ul">
                <Nav.Item
                  title={["Group Details"]}
                  eventKey={1}
                  as="li"
                >
                  <a className={props.showAuditLog == false ? 'active' : ''}
                    href={void 0}
                    onClick={() => { props.setShowAuditLog(false); props.showRightSideFun(true) }}><PiTagBold /></a>
                </Nav.Item>
                <Nav.Item
                  title={["Activity Details"]}
                  eventKey={2}
                  as="li"
                >
                  <a className={props.showAuditLog == true ? 'active' : ''}
                    href={void 0}
                    onClick={() => { props.setShowAuditLog(true); props.showRightSideFun(true) }}> <LuActivitySquare /></a>
                </Nav.Item>
              </Nav>
              <Nav className="icnlnk" as="ul">
                <Nav.Item
                  title={["Delete"]}
                  eventKey={1}
                  as="li"
                >
                  <a className="myBt rclose"
                    href={void 0}
                    onClick={onDeleteClick}><HiOutlineTrash /></a>
                </Nav.Item>

                <Nav.Item as="li" eventKey={2} disabled={props.loader} title={tr['Save']}>
                  <Link to="javascript:void(0)" onClick={handleSubmit(submit)} className="ctrlKeyPrevent" ref={saveObjRef}>
                    <span>{props.loader ? <ImSpinner6 className="icn-spinner" /> : <IoSaveOutline />}</span>
                  </Link>
                </Nav.Item>
                <Nav.Item as="li" title={tr['Close']} eventKey={3} >
                  <Link to="/renderedView/674997f27a024fa4ee118a46" ref={closeButton} className="ctrlKeyPrevent">
                    <IoClose />
                  </Link></Nav.Item>
              </Nav>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default reduxForm({
  form: "EditGroupForm",
})(HeaderSec);