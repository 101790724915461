
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { useSelector } from "react-redux";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { ImInfo } from "react-icons/im";


const DisclaimerPopup = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);

    const tooltip = (
        <Popover id="tooltip" className="disclaimerPopup" style={{position: "fixed"}}>
            <span>{tr["Disclaimer"]}:</span> {tr["Translations are generated automatically and may not always be accurate or relevant"]}.
        </Popover>
    );

    return (
        <>
            <OverlayTrigger placement="top" overlay={tooltip}>
                <span className="disclaimerIcn"><ImInfo/></span>
            </OverlayTrigger>
        </>
    );
};

export default DisclaimerPopup;
