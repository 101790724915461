
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from 'axios';
import {GLOBAL} from '../../../src/components/Globals'
import swal from 'sweetalert';
import { navigationHooks } from '../../helpers/NavigationHook';
import Swal from 'sweetalert2';

const api = axios.create({
    headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});


export function loadHamList() {
    let strObj = {};
    strObj.source = "HAM";
    strObj = JSON.stringify(strObj);
    console.log(strObj);
   
    return (dispatch) => {
        dispatch({type:'LOADER_IN_PROGRESS'});
        api.get("/api/classes", { headers: { query: strObj } })
            .then((hamList) => {
                if (!hamList) {
                    throw Error(hamList.statusText);
                }
                return hamList;
            })
            .then((hamList) => {
              console.log("HamList====",hamList);

                dispatch({
                    type: 'LOAD_HAM_LIST_SUCCESS',
                    hamList
                })
                dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
                return hamList;
                
            })
            .catch((err) => {
                dispatch({type:'LOADER_DETAILS_FETCHED_SUCCESSFULLY'});
                console.log("error in loading HamData");
                console.log(err);
            });
    };
}

export function updateClassDefinitionAction(data,id) {

    let strObj = {};
    strObj.source = "HAM";
    strObj = JSON.stringify(strObj);
    console.log(strObj);
    console.log("id----",id);
    console.log("data----",data);

    return () => {
        api.patch("/api/classes/patch/"+id, data, { headers: { query: strObj } })
            .then((response) => {
              console.log("response+++",response);
              if (response.status == 200) {
                Swal.fire({
                    title: 'Class Definition Updated Successfully',
                  })
                  navigationHooks.navigate("/classDefinition");
              }
                else {
                  navigationHooks.navigate("/classDefinition");
                }
                
            })
            .catch((err) => {
              console.log("Error in Class Update",err);
            });
    };
  }

  export const getClassDefinitionDetails = (id) => {
    let strObj = {};
    strObj.source = "HAM";
    strObj = JSON.stringify(strObj);
    console.log(strObj);

    return (dispatch) => {
    console.log("ID---",id);
     api.get("/api/classes/get/"+id, { headers: { query: strObj } })
          .then((response) => {
                console.log("response---------",response) 
              dispatch({ type: 'CLASS_EDIT_DETAILS_FETCHED', payload: response.data });
  
          })
          .catch((err) => {
            console.log(err);
          });
        }
      };
  
      
export function saveClassData(data) {
  let strObj = {};
  strObj.source = "HAM";
  strObj = JSON.stringify(strObj);
  console.log(strObj);
  console.log("data----",data);

  return () => {
      api.post("/api/classes", data, { headers: { query: strObj } })
          .then((response) => {
            console.log("responsePost",response);
            if (response.status == 201) {
              Swal.fire({
                title: 'Class Definition Created Successfully',
              })
              navigationHooks.navigate("/classDefinition");
              } 
            else {
                navigationHooks.navigate("/classDefinition");
              }
              
          })
          .catch((err) => {
              console.log("ClassPostError", err)
              if (err.response.data?.message) {
                Swal.fire({
                  title: err.response.data.message,
                });
              }
          });
  };
}

export function loadClassByCompanyId(id, hamCall = false, status = 'ACTIVE') {

  let strObj = {};
  

  if(hamCall == true){
    strObj.source = "HAM";
    strObj.company_id = id;
    strObj = JSON.stringify(strObj);

  } else {
    strObj.company_id = id;
    strObj.status = status;
    strObj = JSON.stringify(strObj);
  }

	return (dispatch) => {
		api.get("/api/classes", { headers: { query: strObj } })
			.then((ciClass) => {
				if (!ciClass) {
					throw Error(ciClass.statusText);
				}
				return ciClass;
			})
			.then((ciClass) => {
				console.log('success in ciClass...',ciClass.data);
				dispatch(loadCIClassSuccess(ciClass.data));
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

export function loadCIClassSuccess(ciClass) {
	return {
		type: 'LOAD_CLASS_BY_COMPANY_SUCCESS',
		ciClass
	};
}


export function resetCiClass() {
	return {
		type: 'LOAD_CLASS_BY_COMPANY_SUCCESS',
		ciClass:[]
	};
}


