/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/
import React, { useEffect, useState } from "react";
import { QueryBuilder } from "react-querybuilder";
import { Form } from "react-bootstrap";
import { DropDownTree } from "@progress/kendo-react-dropdowns";
import "@progress/kendo-theme-default/dist/all.css";

import CloseBtnRightPanel from "../../../common/closeBtnRightPanel";
import { ValueEditor } from "../common/ValueEditor";

import { findChildElement } from "../../../common/helper";
import { QueryBuilderKendo } from "../data-query";

const QueryBuilderComponent = ({
  fields,
  query,
  values,
  setQuery,
  editFormValues,
  rightEditPanel,
  setFields,
  fieldsKendo,
  setFieldValue,
  setFieldsKendo,
  disabledinAddMode,
  companyId
}) => {

  const selectField = "selected";
  const expandField = "expanded";
  const dataItemKey = "name";
  const textField = "label";
  const subItemsField = "attributes";

  const disabledConditionBuilder = !values.company_id || disabledinAddMode;

  const FieldValueSelector = ({ handleOnChange, options, value }) => {
    const [fieldArr, setFieldArr] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [treeValue, setTreeValue] = useState();

    const expandedState = (item, dataItemKey, expanded) => {
      const nextExpanded = expanded.slice();
      const itemKey = item[dataItemKey];
      const index = expanded.indexOf(itemKey);
      index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);
      return nextExpanded;
    };

    const groupTwoWordsByDot = (originalString) => {
      let parts = originalString.split(".");
      let result = [];

      for (let i = 0; i < parts.length; i += 2) {
        let pattern = parts.slice(0, i + 2).join(".");
        result.push(pattern);
      }

      return result;
    };

    const mapDataTree = (data) => {
      function addKeys(obj) {
        let newObj = {
          ...obj,
          [expandField]: expanded.includes(obj?.[dataItemKey]),
          [selectField]:
            (treeValue && obj?.[dataItemKey] === treeValue?.[dataItemKey]) ||
            false,
        };

        if (Array.isArray(obj?.[subItemsField])) {
          newObj[subItemsField] = obj[subItemsField].map((child) =>
            addKeys(child)
          );
        }
        return newObj;
      }
      return data.map((obj) => addKeys(obj));
    };

    useEffect(() => {
      const arr = mapDataTree(fieldArr);
      setTreeData(arr);
    }, [expanded, treeValue, fieldArr]);

    useEffect(() => {
      if (options.length > 0 && options?.[0]?.id !== "~") {
        if (value) {
          const selectedField = findChildElement(
            options,
            "name",
            value,
            subItemsField
          );
          setTreeValue(selectedField);
          setExpanded(groupTwoWordsByDot(value));
        }
        setFieldArr(options);
      }
    }, [options, value]);

    const onChange = (event) => {
      setTreeValue(event.value);
      handleOnChange(event?.value?.name || "");
    };

    const onExpandChange = (event) => {
      setExpanded(expandedState(event.item, dataItemKey, expanded));
    };

    return (
      <Form.Group className="optrSelect">
        <DropDownTree
          data={treeData || []}
          value={treeValue}
          onChange={onChange}
          placeholder="Please select ..."
          textField={textField}
          dataItemKey={dataItemKey}
          subItemsField={subItemsField}
          selectField={selectField}
          expandField={expandField}
          onExpandChange={onExpandChange}
        />
      </Form.Group>
    );
  };

  const OperatorValueSelector = ({
    className,
    handleOnChange,
    options,
    value,
    field,
    ...ValueSelectorProps
  }) => {
    const { fields } = ValueSelectorProps?.schema ?? {};
    const operators =
      findChildElement(fields, "name", field, subItemsField)?.operators || [];
    return (
      <Form.Group className="optrSelect">
        <select
          value={value}
          onChange={(v) => {
            handleOnChange(v.target.value);
          }}
          disabled={ValueSelectorProps.disabled}
          className="form-control"
        >
          <option value="">Select</option>
          {ValueSelectorProps.fieldData.label !== "" &&
            operators.map((item) => {
              const key = `key-${item?.name}`;
              return (
                <option
                  key={key}
                  value={item?.value}
                  data-id={item?.controlType}
                >
                  {item?.label}
                </option>
              );
            })}
        </select>
      </Form.Group>
    );
  };

  const handleQueryChange = (newQuery) => {
    let allRules = [];
    const accumulateAllRules = (rules) => {
      rules?.forEach((rule) => {
        if (rule.rules) {
          return accumulateAllRules(rule.rules);
        } else {
          allRules.push(rule);
        }
      });
    };
    accumulateAllRules(query?.rules);

    const resetValuesIfNeeded = (rules) => {
      return rules?.map((rule) => {
        if (rule?.rules) {
          return { ...rule, rules: resetValuesIfNeeded(rule.rules) };
        } else {
          // Find the existing corresponding rule by ID
          const existingRule = allRules?.find((r) => r.id === rule.id);
          if (existingRule?.field !== rule.field) {
            return { ...rule, operator: "", value: "" };
          }
          if (existingRule && existingRule.operator !== rule.operator) {
            return { ...rule, value: "" };
          }
          return rule;
        }
      });
    };

    const updatedRules = resetValuesIfNeeded(newQuery?.rules);
    setQuery({ ...newQuery, rules: updatedRules });
  };

  const fieldSelectorCustom = {
    fieldSelector: FieldValueSelector,
    valueEditor: ValueEditor,
    operatorSelector: OperatorValueSelector,
  };
  useEffect(() => {
    if (disabledConditionBuilder) {
      setQuery("");
    }
  }, [disabledConditionBuilder]);

  useEffect(() => {
    setQuery(query);
  }, [query, setQuery]);

  return (
    <div className="stickyArea rBoxStyle">
      <CloseBtnRightPanel showRightSide={rightEditPanel} />
      <div className="rPageHeading">
        <div className="offNam margin-t-5 margin-b-5">Conditions</div>
      </div>
      <div className="rBoxGap p-2 rule-query qBuilderCtrl">
        <QueryBuilderKendo
          fields={fieldsKendo}
          data={fields}
          context={{ formValues: values }}
          setQuery={setQuery}
          query={query}
          editFormValues={editFormValues}
          setFieldValue={setFieldValue}
          companyId={companyId}
          disabledConditionBuilder={disabledConditionBuilder}
        />
        {/* <QueryBuilder
          disabled={disabledConditionBuilder}  
          fields={fields}
          setFields={setFields}
          query={query}         
          onQueryChange={handleQueryChange}
          controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
          controlElements={fieldSelectorCustom}
          context={{ formValues: values }}
        /> */}
      </div>
    </div>
  );
};

export default QueryBuilderComponent;
