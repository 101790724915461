
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
export const CheckboxGroup = (e) => {
  e.DomComponents.addType("checkbox-group", {
    model: {
      defaults: {
        traits: [
          {
            type: "text",
            label: "Name",
            name: "name",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Labels",
            name: "labels",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Values",
            name: "values",
            changeProp: 1,
          },
          {
            type: "text",
            label: "Default Selected",
            name: "defaultSelected",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Required",
            name: "required",
            changeProp: 1,
          },
          {
            type: "checkbox",
            label: "Disabled",
            name: "disabled",
            changeProp: 1,
          },
          {
            type: "select",
            label: "View Type",
            name: "viewType",
            options: [
              { label: "Horizontal", id: "horizontal" },
              { label: "Vertical", id: "Vertical" },
            ],
            changeProp: 1,
          },
          {
            type: "select",
            label: "Save Value In",
            name: "saveValueIn",
            options: [
              { label: "Comma Seperated", id: "commaSep" },
              { label: "In Array", id: "inArray" },
            ],
            changeProp: 1,
          },
        ],
      },
      init() {},
    },
  });
};

export const checkboxGroupBlockJson = {
  label: "Checkbox Group",
  id:"checkbox-groups",
  media:`<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path fill="none" d="M0 0h24v24H0z"></path> <path d="M7 7V3a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1h-4v3.993c0 .556-.449 1.007-1.007 1.007H3.007A1.006 1.006 0 0 1 2 20.993l.003-12.986C2.003 7.451 2.452 7 3.01 7H7zm2 0h6.993C16.549 7 17 7.449 17 8.007V15h3V4H9v3zm-.497 11l5.656-5.657-1.414-1.414-4.242 4.243L6.38 13.05l-1.414 1.414L8.503 18z"></path> </g> </g></svg>`,
  category: "Forms",
  content: `
    <div data-gjs-type="checkbox-group"><input type="checkbox" /><span> Checkbox Group</span><div>
  `,
};
