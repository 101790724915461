import React, { useEffect, useState } from "react";
import { Button, Col, ListGroup, Modal, Row } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import TemplateDropdown from "./TemplateDropdown";
import { getCookiesValuesByPositions } from "../../../common/helper";
import moment from "moment";

const PreviewTemplate = (props) => {
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const tr = useSelector((state) => state.spcmReducer.tr);
  const { value1: dateFormat } = getCookiesValuesByPositions([2]);

  useEffect(() => {
    if (
      props?.selectedTemplate?.templateJson &&
      Object.keys(props?.selectedTemplate?.templateJson).length > 0
    ) {
      let templateArr = [];
      Object.values(props?.selectedTemplate?.templateJson).map((item) => {
        if(item.valueText) {
          templateArr.push({
            label: item.name,
            value: item.valueText,
          });
        } else if(item.type === "Datetime") {
          let date = moment(item.value).format(dateFormat.toUpperCase());
          templateArr.push({
            label: item.name,
            value: date,
          });
        } else if(item.type === "Calendar") {
          let date = moment(item.value).format(dateFormat.toString().split(" ")[0].toUpperCase());
          templateArr.push({
            label: item.name,
            value: date,
          });
        } else if(item.type === "Checkbox") {
          templateArr.push({
            label: item.name,
            value: item.value?.value?.join(","),
          });
        } else if(item.type === "Multiselect List") {
          templateArr.push({
            label: item.name,
            value: item.value?.value,
          });
        } else if(item.type === "Table") {
          item.value.map((val) => {
            templateArr.push({
              label: item.name,
              value: JSON.stringify(val),
            });
          });
        } else if(Array.isArray(item.value)) {
          item.value.map((val) => {
            if (val?.Name) {
              templateArr.push({
                label: item.name,
                value: val?.Name,
              });
            } else {
              try {
                let value = JSON.stringify(val);
                templateArr.push({
                  label: item.name,
                  value: value,
                });
              } catch (error) {
                console.log("error", error);
              }
            }
          });
        } else {
          templateArr.push({
            label: item.name,
            value: item.value,
          });
        }
      });
      setSelectedTemplate(templateArr);
    }
  }, [props.selectedTemplate]);

  return (
    <Modal
      size="lg"
      show={props.showModal}
      aria-labelledby="template-modal"
      centered
      className="tempPopup"
    >
      <Modal.Header>
        <Row className="w-100">
          <Col xs={10}>
            <h1 className="sPageHeading1 f-size-20" id="template-modal">
              <TemplateDropdown
                companyId={props.companyId}
                offering={props.offering}
                onChangeTemplate={props.onChangeTemplate}
                setTemplateValuesRed={props.setTemplateValuesRed}
                className="tempPopupHd"
                selectedTemplate={props.selectedTemplate}
                isPreview={true}
              />
            </h1>
          </Col>
          <Col xs={2} className="p-0">
            <div className="paHedFilter">
              <Button
                bsPrefix=" "
                role="button"
                className="myBt cancel fillBtn"
                title={tr["Close"]}
                onClick={() => props.onCancelTemplateSelection()}
              >
                <IoClose />
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body className="userInfoPopup">
        <div className="userInfoPopupParent">
          <div className="rBoxGap">
            <Scrollbars
              style={{ height: 300 }}
              autoHide={false}
              hideTracksWhenNotNeeded={false}
              className="areaPopup"
            >
              <div className="margin-t-10">
                <ListGroup bsPrefix=" " className="infoPopupSD">
                  {selectedTemplate &&
                    selectedTemplate.length > 0 &&
                    selectedTemplate.map((item, index) => {
                      return (
                        <ListGroup.Item
                          bsPrefix=" "
                          className="rwTable"
                          key={index}
                          style={{
                            borderTop: index === 0 ? "none" : "",
                            borderBottom:
                              index === selectedTemplate.length - 1
                                ? "none"
                                : "",
                          }}
                        >
                          <span className="rwKey">{item.label}</span>
                          <span className="rwVal">{item.value}</span>
                        </ListGroup.Item>
                      );
                    })}
                </ListGroup>
              </div>
            </Scrollbars>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsPrefix=" "
          role="button"
          className="myBt fillBtn tempPopupCancelBtn"
          title={tr["Cancel"]}
          onClick={() => props.onCancelTemplateSelection()}
        >
          {tr["Cancel"]}
        </Button>
        <Button
          bsPrefix=" "
          role="button"
          className="myBt fillBtn tempPopupApplyBtn"
          title={tr['Apply']}
          onClick={() => {
            props.setTemplateValuesRed({id: props.selectedTemplate?.id, ...props.selectedTemplate?.templateJson});
            props.showPreviewModal(false);
          }}
        >
          {tr['Apply']}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default PreviewTemplate;
