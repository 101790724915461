
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, ButtonToolbar, Form, FormControl, Table } from "react-bootstrap";
import { FaTrash } from "react-icons/fa6";
import { QueryBuilder, RuleGroup } from "react-querybuilder";


export const RuleComponent = (props) => {
  const operators = [
    { name: "==", label: "Equal To" },
    { name: "!=", label: "Not Equal To" },
    // {name: '==null', label: 'Is Null'},
    // {name: '!==null', label: 'Is Not Null'},
    // {name: 'in', label: 'In'},
    // {name: 'notIn', label: 'Not In'}
  ];
  if(props?.ruleType === "fieldRule"){
    operators.push({ name: "<=", label: "Less Than Or Equal To" });
    operators.push({ name: ">=", label: "Greater Than Or Equal To" });
    operators.push({ name: ">", label: "Greater Than" });
    operators.push({ name: "<", label: "Less Than" });
  }
  return (
    <div>
      <Form.Group className="margin-b-10" bsPrefix=" ">
        <Form.Label>Rule Name</Form.Label>
        <Form.Control
          className={`form-control ${props?.nameError}`}
          type="text"
          value={props?.ruleName}
          onChange={(e) => props?.onRuleNameChange(e)}
        ></Form.Control>
      </Form.Group>
      <Form.Group className="margin-b-10" bsPrefix=" ">
        <Form.Label>Rule Type</Form.Label>
        <Form.Select
          className={`form-control ${props?.ruleTypeError}`}
          onChange={(e) => props?.onRuleTypeChange(e)}
        >
          <option value="">Select Rule Type</option>
          <option value="mainRule" selected={props?.ruleType == "mainRule"}>Smart Rule</option>
          <option value="fieldRule" selected={props?.ruleType == "fieldRule"}>Value Assignment Rule</option>
        </Form.Select>
      </Form.Group>
      <QueryBuilder
        fields={props?.fields}
        onQueryChange={props?.handleQueryChange}
        controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
        query={props?.query}
        operators={operators}
        //controlElements={controls}
      />
    </div>
  );
};


export const OnConditionTrue = (props)=>{
    //const [rows, setRows] = useState([]);
    const { fields, type, onRowsChange, data } = props;
    const addRows = ()=>{
        onRowsChange([...data, { operation: "", field: "", value: "" }]);
    }

    const deleteRows = (index) => {
      let rows = structuredClone(data);
      rows.splice(index, 1);
      onRowsChange(rows);
    };

    const onChange = (e,index)=>{
       let rowsFields = structuredClone(data);
        rowsFields[index] = {
          ...rowsFields[index],
          [e.target.name]: e.target.value,
        };
        onRowsChange(rowsFields);
    }

    const renderRows = (rowsField)=>{
        if(rowsField.length>0){
          return rowsField.map((res, index) => {
           return (
             <tr>
               <td>
                 <select
                   className="form-control"
                   onChange={(e) => {
                     onChange(e, index);
                   }}
                   name="operation"
                 >
                   <option value={""}>Select Operation</option>
                   <option value="hide" selected={res?.operation == "hide"}>
                     Hide
                   </option>
                   <option value="show" selected={res?.operation == "show"}>
                     Show
                   </option>
                   {/* <option
                     value="setProperty"
                     selected={res?.operation == "setProperty"}
                   >
                     Set Property
                   </option> */}
                   <option
                     value="readOnly"
                     selected={res?.operation == "readOnly"}
                   >
                     Readonly
                   </option>
                   <option
                     value="removeReadOnly"
                     selected={res?.operation == "removeReadOnly"}
                   >
                     Remove Readonly
                   </option>
                  <option
                     value="setValue"
                     selected={res?.operation == "setValue"}
                   >
                     Set Value
                   </option>
                   <option
                     value="manadatory"
                     selected={res?.operation == "manadatory"}
                   >
                     Manadatory
                   </option>
                   <option
                     value="nonManadatory"
                     selected={res?.operation == "nonManadatory"}
                   >
                     Non Manadatory
                   </option>
                 </select>
               </td>
               <td>
                 <select
                   className="form-control"
                   name="field"
                   onChange={(e) => {
                     onChange(e, index);
                   }}
                 >
                   <option value="">Select Field</option>
                   {fields.length > 0 &&
                     fields.map((field) => {
                       return (
                         <option
                           value={field.name}
                           selected={res?.field == field?.name}
                         >
                           {field.name}
                         </option>
                       );
                     })}
                 </select>
               </td>
               {res?.operation === "setValue" && (<td>
                 
                   <input
                     type="text"
                     className="form-control"
                     name="value"
                     value={res?.value}
                     onChange={(e) => {
                       onChange(e, index);
                     }}
                   />
                 
               </td>)}
               <td>
                 <span onClick={() => deleteRows(index)}>
                   <FaTrash />
                 </span>
               </td>
             </tr>
           );
          });
        }
    }
    return (
      <div className="margin-t-10">
        <h3>
          {type == "conditionTrue" ? "If Condition:True" : "If Condition:False"}
        </h3>

        <div className="mt-3 mx-2 mb-1">
          <div>
            {data.length > 0 && (
              <Table
                responsive
                striped
                bordered
                condensed
                hover
                className="f-size-13"
              >
                <tbody>{renderRows(data)}</tbody>
              </Table>
            )}
          </div>
          <ButtonToolbar className="black" bsPrefix=" ">
            <Button
              className="rgSidrkBtn smallBtn"
              size="xs"
              onClick={() => {
                addRows();
              }}
            >
              Add More
            </Button>
          </ButtonToolbar>
        </div>
      </div>
    );
}


export const OnConditionFalse = (props)=>{
}
