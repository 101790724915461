/*****************************************
 * Licensed Materials - Property of
 * HCL.
 * (c) Copyright HCL Technologies Ltd.
 * 2016, 2024.
 *******************************************/

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const OfferingTemplatesApi = createApi({
  reducerPath: "OfferingTemplatesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: window.location.origin,
  }),
  tagTypes: ["OfferingTemplates", "OfferingTemplateById"],
  endpoints: (builder) => ({
    getOfferingTemplates: builder.query({
      query: (filters) => {
        return {
          url: "/api/getOfferingTemplates",
          method: "GET",
          headers: {
            query: JSON.stringify(filters),
          },
        };
      },
      providesTags: ["OfferingTemplates"],
    }),
    getOfferingTemplateById: builder.query({
      query: (id) => {
        return {
          url: `/api/getOfferingTemplateById/${id}`,
          method: "GET",
          headers: {
            query: JSON.stringify(filters),
          },
        };
      },
      providesTags: (result, error, id) => [{ type: "OfferingTemplateById", id }],
    }),
    saveOfferingTemplate: builder.mutation({
      query: (payload) => {
        return {
          url: `/api/postOfferingTemplate?module=${payload.module}`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["OfferingTemplates"],
    }),
    updateOfferingTemplate: builder.mutation({
      query: (payload) => {
        return {
          url: `/api/patchOfferingTemplate`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: (result, error, payload) => [{ type: "OfferingTemplateById", id: payload.id }],
    }),
  }),
});

export const {
  useGetOfferingTemplatesQuery,
  useGetOfferingTemplateByIdQuery,
  useSaveOfferingTemplateMutation,
  useUpdateOfferingTemplateMutation,
} = OfferingTemplatesApi;
export default OfferingTemplatesApi.reducer;
