
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import axios from "axios";
import { Button, Form } from "react-bootstrap";
import _ from "lodash";
import { findChildElement, replaceVariables } from "../../common/helper";

const headerValues = {
  module: {
    incident: "Breakfix",
    problem: "Investigation",
  },
  moduleType: {
    fulfillment: "service",
    changemanagement: "change",
    incident: "incident"
  },
  status: "ACTIVE",
};

export const ValueEditor = ({
  operator,
  value,
  handleOnChange,
  context,
  disabled,
  field,
  ...valueEditorProps
}) => {
  const [errorClass, setErrorClass] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [isTypeaheadOpen, setIsTypeaheadOpen] = useState(false);

  const typeAheadRef = useRef(null);
  const { fields } = valueEditorProps?.schema ?? {};
  const fieldData = findChildElement(fields, "name", field, "attributes");
  const formValues = context.formValues;
  const setServiceList = context.setServiceList;
  const createValueObject = (id, value) => {
    return { id, value };
  };

  const getOptionsData = (text) => {
    if (!text) {
      setOptions([]);
    }
    const consumerCompany = context.formValues?.consumerCompanyId;
    let headers = {};
    let url = fieldData?.apiEndPoint;
    try {
      const apiAttributes = JSON.parse(fieldData?.headers ?? "{}");
      if (apiAttributes?.query) {
        const queryArr = apiAttributes?.query;
        Object.keys(queryArr).forEach((query) => {
          let value;
          if (queryArr[query].includes("{{like}}")) {
            value = text;
          } else {
            value = queryArr[query].replace(
              /{{(.*?)}}/g,
              (_, variable) => context.formValues[variable.trim()] || ""
            );
          }
          headers = {
            ...headers,
            [query]: value 
          };
        });
        //   if (query === "companyId" || query === "company_id") {
        //     headers = {
        //       ...headers,
        //       [query]: consumerCompany.toString(),
        //     };
        //   } else if (query === "like" && text) {
        //     headers = {
        //       ...headers,
        //       [query]: text,
        //     };
        //   } else if (query === "module" || query === "moduleType") {
        //     if (headerValues?.[query]?.[formValues.module]) {
        //       headers = {
        //         ...headers,
        //         [query]: headerValues?.[query]?.[formValues.module],
        //       };
        //     }
        //   } else if (query === "status") {
        //     if (headerValues[query]) {
        //       headers = {
        //         ...headers,
        //         [query]: headerValues?.[query],
        //       };
        //     }
        //   }
        // });
      }
      if (apiAttributes?.path) {
        const { path } = apiAttributes;
        let queryParamsObj = replaceVariables(path, formValues, text);
        let queryParams = Object.entries(queryParamsObj)
          .map(([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          )
          .join("&");
        url = `${url}?${queryParams}`;
      }
      headers.api = url;
      if(apiAttributes?.body && apiAttributes?.method) {
        const { body, method } = apiAttributes;
        if(method?.toString()?.toLowerCase() === 'post') {
          headers.body = replaceVariables(body, formValues);
          headers.method = 'POST';
        }
      }
      if (
        fieldData?.attributeValueType === "dropdown" ||
        fieldData?.attributeValueType === "dropdown+typeahead" ||
        (fieldData?.attributeValueType === "typeahead" && text)
      ) {
        setIsLoading(true);
        axios
          .get("/api/rules/getQueryBuilderData", {
            headers: { query: JSON.stringify(headers) },
          })
          .then((response) => {
            const bindingAttr = JSON.parse(
              fieldData?.bindingAttributes ?? "{}"
            );
            if (bindingAttr?.label && bindingAttr?.id) {
              const label = bindingAttr.label;
              const idKey = bindingAttr.id;
              const data = response.data.map((item) => {
                if (label.includes("|")) {
                  const labelArr = label.split("|");

                  let modifiedLabel = "";
                  if (labelArr.length > 2) {
                    modifiedLabel = labelArr.map((key) => item[key]).join("|");
                  } else if (
                    labelArr.length === 2 &&
                    !labelArr.some((key) => key.includes("-"))
                  ) {
                    modifiedLabel = labelArr.map((key) => item[key]).join("|");
                  } else {
                    modifiedLabel = labelArr.map((key) => item[key]).join("");
                  }

                  const objKeys = labelArr
                    .filter((key) => key.includes("-"))
                    .flatMap((key) => key.split("-"));

                  const obj = objKeys.reduce((acc, key) => {
                    acc[key] = item[key];
                    return acc;
                  }, {});

                  if (objKeys.length > 0) {
                    obj.splitLabel = objKeys;
                  }
                  return {
                    ...obj,
                    label: modifiedLabel,
                    id: item[idKey]?.toString(),
                  };
                } else {
                  return {
                    label: item[label],
                    id: item[idKey]?.toString(),
                  };
                }
              });
              setTimeout(() => {
                if (value && typeof value === "object" && !text) {
                  if (value.id && value.value) {
                    let ids = value.id?.toString()?.split(",");
                    const selectedData = [];
                    ids.forEach((id) => {
                      let obj = _.find(data, { id: id.toString() });
                      if (obj) {
                        selectedData.push({
                          id,
                          label: obj.label,
                        });
                      }
                    });
                    setSelectedOptions(selectedData);
                  }
                }
                setOptions(data);
                setServiceList(response.data)
              }, 10);
              setIsLoading(false);
              getTypeAheadPosition();
            } else {
              setOptions([]);
              setIsLoading(false);
            }
          })
          .catch(() => {
            setIsLoading(false);
            setOptions([]);
          });
      } else if (fieldData?.attributeValueType === "typeahead" && !text) {
        if (value && typeof value === "object" && !text) {
          if (value.id) {
            if (fieldData.name === 'breakfix.ciId' || fieldData.name === 'xsm_change.ciId' || fieldData.name === 'xsm_investigation.ciId' || fieldData.name === 'tasks.ciId') {
              let apiUrl = fieldData.apiHost + "/api/config_items/getDataByIds";
              let query = JSON.stringify({ ciId: value.id, api: apiUrl });
              setIsLoading(true);
              axios
                .get("/api/rules/getQueryBuilderData", { headers: { query } })
                .then((resp) => {
                  setIsLoading(false);
                  let selected = resp.data.map((obj) => {
                    return {
                      id: obj?.ciId?.toString(),
                      label: obj?.ciName?.toString(),
                    };
                  });
                  setSelectedOptions(selected);
                })
                .catch(() => setIsLoading(false))
            } else if (fieldData.name.toLowerCase().includes("location")) {
              let apiUrl =
                fieldData.apiHost + "/api/fetchLocationNameByLocationIds";
              let query = JSON.stringify({
                locationIds: value.id,
                api: apiUrl,
              });
              setIsLoading(true);
              axios
                .get("/api/rules/getQueryBuilderData", { headers: { query } })
                .then((resp) => {
                  setIsLoading(false);
                  let selected = resp.data.map((obj) => {
                    return {
                      id: obj?.locationId?.toString(),
                      label: obj?.locationName?.toString(),
                    };
                  });
                  setSelectedOptions(selected);
                })
                .catch(() => setIsLoading(false))
            } else {
              let selectedValues = [];
              let ids = value.id?.toString()?.split(",");
              let labels = value.value?.toString()?.split(",");
              ids.forEach((id, index) => {
                selectedValues.push({ id, label: labels[index] });
              });
              setSelectedOptions(selectedValues);      
            }
          }
        }
      }
    } catch (e) {
      console.error("Error: Unexpected JSON Received.");
    }
  };

  useEffect(() => {
    getOptionsData();
  }, [fieldData]);

  const onInputChange = (text) => {
    if (fieldData?.attributeValueType === "typeahead") {
      if (text.trim().length >= 3) {
        getOptionsData(text.trim());
      } else {
        setOptions([]);
      }
    }
  };

  useEffect(() => {
    if (operator === "null" || operator === "!null") {
      handleOnChange("");
    }
  }, [operator]);

  useEffect(() => {
    if (value === "") {
      typeAheadRef?.current?.clear();
      setSelectedOptions([]);
      if (
        fieldData?.attributeValueType !== "dropdown+typeahead" &&
        fieldData?.attributeValueType !== "dropdown"
      ) {
        setOptions([]);
      }
    }
  }, [value]);

  const getTypeAheadPosition = () => {
    if (
      fieldData?.attributeValueType === "typeahead" ||
      fieldData?.attributeValueType === "dropdown+typeahead"
    ) {
      const typeAheadBottom = document
        .getElementsByClassName("rbt-menu")?.[0]
        .getBoundingClientRect()?.bottom;
      const footerTop = document
        .getElementsByClassName("qBuilderCtrl")?.[0]
        .getBoundingClientRect()?.bottom;
      if (typeAheadBottom >= footerTop) {
        setIsTypeaheadOpen(true);
      }
    }
  };

  const setErrorColor = () => {
    if (selectedOptions.length > 0) {
      setErrorClass("");
    } else {
      setErrorClass("error");
    }
  };
  const onSelectionTypeahead = (value) => {
    if (value && value.length > 0) {
      let labels = value.map((val) => val.label).join(",");
      let ids = value.map((val) => val.id).join(",");
      handleOnChange(createValueObject(ids, labels));
      setSelectedOptions(value);
      setErrorClass("");
    } else {
      handleOnChange("");
      setSelectedOptions([]);
      setErrorClass("error");
    }
  };

  const handleChangeDropdown = (event) => {
    const { text, value } = event.target[event.target.selectedIndex];
    if (text && value) {
      handleOnChange(createValueObject(value, text));
      setErrorClass("");
    } else {
      handleOnChange("");
      setErrorClass("error");
    }
  };

  const onChangeText = (event) => {
    const text = {
      id: event.target.value,
      value: event.target.value
    }
    if (text) {
      handleOnChange(text);
      setErrorClass("");
    } else {
      handleOnChange("");
      setErrorClass("error");
    }
  };

  const onCrossClick = () => {
    handleOnChange("");
    setSelectedOptions([]);
  };

  return (
    <div className="optrVal">
      {operator === "=" || operator === "!=" || operator === "in" ? (
        <Form.Group>
          {(fieldData?.attributeValueType === "typeahead" ||
            fieldData?.attributeValueType === "dropdown+typeahead") && (
            <div
              className={`${
                operator === "in"
                  ? `notifydvTypehd ${isTypeaheadOpen ? "dvTypehdropdown" : ""}`
                  : `dvTypehd ${isTypeaheadOpen ? "dvTypehdropdown" : ""}`
              } ${errorClass}`}
            >
              {selectedOptions.length > 0 && !disabled && (
                <span
                  title="clear"
                  role="img"
                  aria-label="Clear"
                  className="css-hakgx8 icn"
                  onClick={onCrossClick}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    role="presentation"
                  >
                    <path
                      d="M12 10.586L6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 001.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 10-1.414-1.414L12 10.586z"
                      fill="gray"
                    ></path>
                  </svg>
                </span>
              )}
              <Typeahead
                id="values"
                ref={typeAheadRef}
                multiple={operator === "in" ? true : false}
                onChange={onSelectionTypeahead}
                options={options}
                selected={selectedOptions}
                placeholder="Please choose..."
                onBlur={setErrorColor}
                onInputChange={onInputChange}
                inputProps={{
                  onClick: () => {
                    getTypeAheadPosition();
                  },
                }}
                disabled={disabled || false}
                isLoading={isLoading}
                renderMenuItemChildren={(option, props) => {
                  let label;
                  let title;
                  label =
                    option.label.length > 30
                      ? option.label.slice(0, 30) + "..."
                      : option.label;
                  title = option.label.length > 30 ? option.label : "";
                  if (option?.splitLabel) {
                    return (
                      <div>
                        <span className="typHdmainHead">{option.label}</span>
                        <span
                          className="typHdmainSubHead"
                          style={{
                            display: "block",
                            "margin-top": "6px",
                            color: "#777",
                          }}
                        >
                          {option.splitLabel.map((label, index) => {
                            if (index === option.splitLabel.length - 1) {
                              return option[label] ?? "NA";
                            } else {
                              if (option[label]?.length === 0) {
                                return "";
                              } else {
                                return `${option[label] ?? "NA"} - `;
                              }
                            }
                          })}
                        </span>
                      </div>
                    );
                  }
                  return <div className="typHdmainHead">{option.label}</div>;
                }}
              />
            </div>
          )}
          {fieldData?.attributeValueType === "dropdown" &&
            operator !== "in" && (
              <select
                id="values"
                onChange={handleChangeDropdown}
                key={fieldData?.name}
                className={"form-control " + errorClass}
                value={value?.id || ""}
                disabled={disabled || false}
              >
                <option value="">Select</option>
                {options.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.label}
                  </option>
                ))}
              </select>
            )}
          {fieldData?.attributeValueType === "textbox" && (
            <input
              id="values"
              onChange={onChangeText}
              key={fieldData?.name}
              value={(value?.value || value.value === "") ? value.value : value}
              className={"form-control " + errorClass}
              disabled={disabled || false}
            />
          )}
        </Form.Group>
      ) : operator === "null" || operator === "!null" ? null : (
        <Form.Group>
          <input
            id="values"
            onChange={onChangeText}
            key={fieldData?.name}
            value={(value?.value || value.value === "") ? value.value : value}
            className={"form-control padding-r-30" + errorClass}
            disabled={disabled || false}
          />
        </Form.Group>
      )}
    </div>
  );
};
