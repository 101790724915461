
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import _ from 'lodash';
import axios from 'axios';

import { GLOBAL } from '_Globals'
import Swal from 'sweetalert2';
import { navigationHooks } from '../../helpers/NavigationHook';

const api = axios.create({
  headers: { 'Pragma': 'no-cache', 'Cache-control': 'no-store' },
});


export const getUrgencyImpactForSystemConfig = (param) => {
    console.log(config,'ak action f')
  let config = {headers:{query:JSON.stringify(param)}}
    return (dispatch) => {
      api.get("/api/getUrgencyImpactList", config)
        .then((urgencyValues) => {
            console.log(urgencyValues,'ak action')
          dispatch({ type: 'URGENCY_IMPACT_FETCHED_SUCCESSFULLY', payload: urgencyValues.data });
        })
        .catch((err) => {
          console.log(err);
        });
    };
};
  
export const resetUrgencyImpactForSystemConfig = () => {
  return (dispatch) => {
        dispatch({ type: 'URGENCY_IMPACT_FETCHED_SUCCESSFULLY', payload: [] });
  };
};

export const getSystemConfigList = (param) => {
  return (dispatch) => {
    api.get("/systemConfig/getSyetemCofigList")
      .then((response) => {
        console.log("Check90", response);
        dispatch({ type: 'SYSTEM_CONFIG_FETCHED_SUCCESSFULLY', payload: response.data.Data});
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getSurveyConfigList = (type) => {
  let query={}
  query.type=type
  console.log("/getSurveyCofigList/type", query.type);
  return (dispatch) => {
    api.get("/systemConfig/getSurveyCofigList",
       {headers:{query:JSON.stringify(query)}})
      .then((response) => {
        console.log("/getSurveyCofigList", response);
        dispatch({ type: 'SURVEY_CONFIG_FETCHED_SUCCESSFULLY', payload: response.data.Data});
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getApprovalConfigList = (param) => {
  return (dispatch) => {
    api.get("/systemConfig/getApprovalCofigList")
      .then((response) => {
        console.log("/getApprovalCofigList", response);
        dispatch({ type: 'APPROVAL_CONFIG_FETCHED_SUCCESSFULLY', payload: response.data.Data});
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const saveSystemConfigList = (payload) => {
  return (dispatch) => {
    api.post("/systemConfig/saveSystemConfigList", payload)
      .then((response) => {
        console.log("Response12",response)
        dispatch({ type: 'SYSTEM_CONFIG_SAVED_SUCCESSFULLY', payload: response.data });
        Swal.fire({
          title: 'Record saved successfully',
        }).then(result => {
          if (result.isDismissed || result.isConfirmed) {
            navigationHooks.navigate('/systemConfig',{state: {activeTab: 1}})
          }
        })
      })
      .catch((err) => {
        console.log("ERR==========",err.response);
        // if(err.response.data.Message === 'Data already Exists') {
          // Swal.fire({
          //   title: 'This record already exists.',
          // })
          Swal.fire({
            title: err.response.data.Message
          })
        // }
      });
  };
};

export const saveSurveyConfigList = (payload,tr) => {
  return (dispatch) => {
    api.post("/systemConfig/saveSurveyConfigList", payload)
      .then((response) => {
        console.log("saveSurveyConfigList",response)
        dispatch({ type: 'SURVEY_CONFIG_SAVED_SUCCESSFULLY', payload: response.data });
        Swal.fire({
          title: 'Record saved successfully',
        }).then(result => {
          if (result.isDismissed || result.isConfirmed) {
            if(payload.type === 'Survey') {
              navigationHooks.navigate('/systemConfig', {state: {activeTab: 2}})
            } else {
            navigationHooks.navigate('/systemConfig', {state: {activeTab: 3}})
            }
          }
        })
      })
      .catch((err) => {
        console.log("ERR==========",err.response);
        // if(err.response.data.Message === 'Data already Exists') {
          // Swal.fire({
          //   title: 'This record already exists.',
          // })
          Swal.fire({
           // title: err.response.data.Message
            title: tr[err.response.data.Message] ||"Something went wrong"
          })
        // }
      });
  };
};

export const editSystemConfigList = (data, id) => {
  return (dispatch) => {
    api.patch("/systemConfig/editSystemConfigList/"+id, data)
      .then((response) => {
        dispatch({ type: 'SYSTEM_CONFIG_EDITED_SUCCESSFULLY', payload: response.data });
        Swal.fire({
          title: 'Record updated successfully',
        }).then(result => {
          if (result.isDismissed || result.isConfirmed) {
            navigationHooks.navigate('/systemConfig',{state: {activeTab: 1}})
          }
        })
      })
      .catch((err) => {
        // if(err.response.data.Message === '') {
          Swal.fire({
            title: err.response.data.Message
          })
        // }
      });
  };
};

export const editSurveyConfigList = (data, id,tr) => {
  return (dispatch) => {
    api.patch("/systemConfig/editSurveyConfigList/"+id, data)
      .then((response) => {
        dispatch({ type: 'SURVEY_CONFIG_EDITED_SUCCESSFULLY', payload: response.data });
        Swal.fire({
          title: 'Record updated successfully',
        }).then(result => {
          if (result.isDismissed || result.isConfirmed) {
            navigationHooks.navigate('/systemConfig',{state: {activeTab: 2}})
          }
        })
      })
      .catch((err) => {
        // if(err.response.data.Message === '') {
          Swal.fire({
            title: tr[err.response.data.Message] ||"Something went wrong"
          })
        // }
      });
  };
};

export const editApprovalConfigList = (data, id) => {
  return (dispatch) => {
    api.patch("/systemConfig/editApprovalConfigList/"+id, data)
      .then((response) => {
        dispatch({ type: 'APPROVAL_CONFIG_EDITED_SUCCESSFULLY', payload: response.data });
        Swal.fire({
          title: 'Record updated successfully',
        }).then(result => {
          if (result.isDismissed || result.isConfirmed) {
            navigationHooks.navigate('/systemConfig',{state: {activeTab: 3}})
          }
        })
      })
      .catch((err) => {
        // if(err.response.data.Message === '') {
          Swal.fire({
            title: err.response.data.Message
          })
        // }
      });
  };
};

export const editMailboxConfigList = (data, id) => {
  return (dispatch) => {
    api.patch("/systemConfig/editMailboxConfigList/"+id, data)
      .then((response) => {
        dispatch({ type: 'MAILBOX_CONFIG_EDITED_SUCCESSFULLY', payload: response.data });
        Swal.fire({
          title: 'Record updated successfully',
        }).then(result => {
          if (result.isDismissed || result.isConfirmed) {
            navigationHooks.navigate('/systemConfig',{state: {activeTab: 4}})
          }
        })
      })
      .catch((err) => {
        // if(err.response.data.Message === '') {
          Swal.fire({
            title: err.response.data.Message
          })
        // }
      });
  };
};

export const deleteSystemConfigList = (id) => {
  return (dispatch) => {
    api.delete("/systemConfig/deleteSystemConfig/"+id)
      .then((response) => {
        Swal.fire({
          title: 'Record deleted successfully',
        }).then(result => {
          if (result.isDismissed || result.isConfirmed) {
            navigationHooks.navigate('/systemConfig',{state: {activeTab: 1}})
          }
        })
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const deleteSurveyConfigList = (id) => {
  return (dispatch) => {
    api.delete("/systemConfig/deleteSurveyConfig/"+id)
      .then((response) => {
        Swal.fire({
          title: 'Record deleted successfully',
        }).then(result => {
          if (result.isDismissed || result.isConfirmed) {
            navigationHooks.navigate('/systemConfig',{state: {activeTab: 2}})
          }
        })
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const deleteApprovalConfigList = (id) => {
  return (dispatch) => {
    api.delete("/systemConfig/deleteSurveyConfig/"+id)
      .then((response) => {
        Swal.fire({
          title: 'Record deleted successfully',
        }).then(result => {
          if (result.isDismissed || result.isConfirmed) {
            navigationHooks.navigate('/systemConfig',{state: {activeTab: 3}})
          }
        })
      })
      .catch((err) => {
        console.log(err);
      });
  };
};


export const deleteMailboxConfigList = (id) => {
  return (dispatch) => {
    api.delete("/systemConfig/deleteMailboxConfig/"+id)
      .then((response) => {
        Swal.fire({
          title: 'Record deleted successfully',
        }).then(result => {
          if (result.isDismissed || result.isConfirmed) {
            navigationHooks.navigate('/systemConfig',{state: {activeTab: 4}})
          }
        })
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const savedMailboxCofigList = (payload,tr) => {
  return (dispatch) => {
    api.post("/systemConfig/savedMailboxCofigList", payload)
      .then((response) => {
        console.log("savedMailboxCofigList",response)
        dispatch({ type: 'MAILBOX_CONFIG_SAVED_SUCCESSFULLY', payload: response.data });
        Swal.fire({
          title: 'Record saved successfully',
        }).then(result => {
          if (result.isDismissed || result.isConfirmed) {
            navigationHooks.navigate('/systemConfig',{state: {activeTab: 4}})
          }
        })
      })
      .catch((err) => {
        console.log("ERR==========",err.response);
        // if(err.response.data.Message === 'Data already Exists') {
          // Swal.fire({
          //   title: 'This record already exists.',
          // })
          Swal.fire({
           // title: err.response.data.Message
            title: tr[err.response.data.Message] ||"Something went wrong"
          })
        // }
      });
  };
};
export const setSystemConfigData = (payload) => {
  return (dispatch) => {
        dispatch({ type: 'SYSTEM_CONFIG_SAVED_SUCCESSFULLY', payload: payload });
  };
};
export const setSurveyConfigData = (payload) => {
  return (dispatch) => {
        dispatch({ type: 'SURVEY_CONFIG_SAVED_SUCCESSFULLY', payload: payload });
  };
};

export const setApprovalConfigData = (payload) => {
  return (dispatch) => {
        dispatch({ type: 'APPROVAL_CONFIG_SAVED_SUCCESSFULLY', payload: payload });
  };
};

export const setMailboxConfigData = (payload) => {
  return (dispatch) => {
        dispatch({ type: 'MAILBOX_CONFIG_SAVED_SUCCESSFULLY', payload: payload });
  };
};



