
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { forwardRef, useImperativeHandle, useEffect } from "react";
import { useQuery } from "@apollo/client";
import BasicTable from "_Commons/ReactTable/BasicTable";
import { Button, ButtonToolbar } from "react-bootstrap";
import { FaPencil, FaTrash } from "react-icons/fa6";
import { DeleteConnector, GetConnectors } from "./graphQl";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import ListLoader from '_Commons/loaders/ListLoader';
import { jwtDateFormat } from "../systemConstant/definition";
import TableComponent from "_Commons/ReactTable/TableComponent";
import { Checkbox } from "@progress/kendo-react-inputs";

const ActionComponent = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const [deleteConnector, { data: connectorData, loading: deleting }] = useMutation(DeleteConnector);
    const onDeleteClick = (id) => {
        Swal.fire({
            title: tr["Are you sure you want to delete?"],
            width:400,
            showDenyButton: true,
            confirmButtonText: tr["Yes"],
            denyButtonText: tr["No"],
        }).then((result) => {
            if (result.isConfirmed) {
                deleteConnector({variables: {id: id}});
            }
        });
    }

    useEffect(() => {
        if(connectorData){props.refetch()}
    }, [deleting])

    return (
        <ButtonToolbar>
            <Button
                bsPrefix=' '
                className="formBuilBtn margin-r-5"
                onClick={() => {
                    props.navigate("/connectors/" + props?.row._id);
                }}
                title="Edit"
            >
                <FaPencil />
            </Button>
            <Button
                bsPrefix=' '
                className="formBuilBtn"
                title="Delete"
                onClick={() => {
                    onDeleteClick(props?.row._id)
                }}
            >
                <FaTrash />
            </Button>
        </ButtonToolbar>
    )
}

const ConnectorList = forwardRef((props, ref) => {
    const { loading, data, refetch } = useQuery(GetConnectors, {notifyOnNetworkStatusChange: true});

    useImperativeHandle(ref, () => {
        return {
            refetch
        }
    })

    const selectAllConnectors = (e)=>{
        if(e?.target?.value){
          props?.setAllConnectorSelected(true);
          let allConnectors = props?.filteredConnectors.map(item => item._id);
          props?.setSelectedRows(allConnectors);
        } else {
          props?.setAllConnectorSelected(false);
          props?.setSelectedRows([]);
        }
      }

      const selectedConnector = (e, row) => {
        if(e?.target?.value){
          const selectedRows = props?.selectedRows || [];
          props?.setSelectedRows([...selectedRows, row?._id]);
        } else {
    
          props?.setSelectedRows(props?.selectedRows.filter(item => item !== row._id));
        }
      }
    const basicColumns = [
        {
            name: <Checkbox checked={props?.allConnectorSelected} onChange={(e)=>selectAllConnectors(e)}></Checkbox>,
            sortable: false,
            cell: (row) => <Checkbox checked={props?.selectedRows.includes(row?._id)} onChange={(e)=>selectedConnector(e,row)}></Checkbox>,
            maxWidth: "40px",
            style:{maxWidth:"40px"}
        },
        {
            name: "Name",
            selector: (row) => row.title,
            sortable: true,
        },
        {
            name: "Microservice",
            selector: (row) => row.module,
            sortable: true,
        },
        {
            name: "Last Modified By",
            selector: (row) => row.modifiedBy,
            sortable: true,
        },
        {
            name: "Last Modified On",
            selector: (row) => moment(row.updatedAt).format(jwtDateFormat),
            sortable: true,
        },
        {
            name: "Action",
            selector: (row) => (
                <ActionComponent
                    row={row}
                    navigate={props.navigate}
                    refetch={refetch}
                />
            ),
            sortable: false,
        }
    ];

    const tableColumns = basicColumns.filter((item) => {
      if (!props?.roles.includes("70") && item.maxWidth == "40px") {
        return false;
      }
      return true;
    });

    const filterColumns = ["title", "module", "modifiedBy"];

    useEffect(() => { refetch() }, []);

    return (
        <div className="dtaTableDv integrateHubTable">
            <div className={"rightTrue srchPosTop1"}>
                {
                    loading 
                    ? (<ListLoader></ListLoader>)
                    :(<TableComponent
                        data={data?.connectors}
                        columns={tableColumns}
                        headerColumns={filterColumns}
                        // onRowSelect={() => {}}
                        uniqueKey={'_id'}
                        rightDisplay={false}
                        checkedItems = {props?.selectedRows}
                        setFilterItems = {props?.setFilteredConnectors}
                    />)
                }
            </div>
        </div>
    )
})

export default ConnectorList;