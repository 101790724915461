
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { IoSearch } from "react-icons/io5";
import { LuLink } from "react-icons/lu";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";


const SimilarityDetection = (props) => {
    const tr = useSelector((state) => state.spcmReducer.tr);
    const [simDropdownVal, setSimDropdownVal] = useState("WIB");

    const handleSimDropdownChange = (event) => {
        setSimDropdownVal(event.target.value);
    };

    return (
        <>
            <div className="margin-b-10 foreCastHd">
                <div>
                    <Form.Group>
                        <Form.Select className="form-control" onChange={handleSimDropdownChange}>
                            <option value="WIB" selected>Task</option>
                            <option value="WIB">Fulfillment</option>
                            <option value="WIB">Incident</option>
                            <option value="WIB">Problem</option>
                            <option value="WIB">Change</option>
                            <option value="Knowledge Articles">Knowledge Articles</option>
                            <option value="CI/Asset">CI/Asset</option>
                        </Form.Select>
                    </Form.Group>
                    <Button type="button">
                        OK
                    </Button>
                </div>
                <div>
                    <Form.Group>
                        <Form.Control type="text" placeholder="Search" />
                        <IoSearch className="foreSearch" />
                    </Form.Group>
                </div>
            </div>
            {
                simDropdownVal === "WIB" &&
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Work Item Number</th>
                            <th>Module</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><Link to={"javascript:void(0)"}>INC000002556608</Link></td>
                            <td>Incident</td>
                            <td><Link title="Relate" to={"javascript:void(0)"}><LuLink /></Link></td>
                        </tr>
                    </tbody>
                </Table>
            }
            {
                simDropdownVal === "Knowledge Articles" &&
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Article ID</th>
                            <th>Article Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><Link to={"javascript:void(0)"}>85393</Link></td>
                            <td>Keep your KBase up-to-date</td>
                            <td><Link title="Relate" to={"javascript:void(0)"}><LuLink /></Link></td>
                        </tr>
                        <tr>
                            <td><Link to={"javascript:void(0)"}>89345</Link></td>
                            <td>Define an approval process</td>
                            <td><Link title="Relate" to={"javascript:void(0)"}><LuLink /></Link></td>
                        </tr>
                        <tr>
                            <td><Link to={"javascript:void(0)"}>67895</Link></td>
                            <td>Enhance the search capability on WIB</td>
                            <td><Link title="Relate" to={"javascript:void(0)"}><LuLink /></Link></td>
                        </tr>
                    </tbody>
                </Table>
            }
            {
                simDropdownVal === "CI/Asset" &&
                <Table responsive>
                    <thead>
                        <tr>
                            <th>CI Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><Link to={"javascript:void(0)"}>R58W20A1BDHGIGI --- HCLT Wendy Breakfix</Link></td>
                            <td><Link title="Relate" to={"javascript:void(0)"}><LuLink /></Link></td>
                        </tr>
                        <tr>
                            <td><Link to={"javascript:void(0)"}>First Asset --- HCLT Wendy Breakfix</Link></td>
                            <td><Link title="Relate" to={"javascript:void(0)"}><LuLink /></Link></td>
                        </tr>
                        <tr>
                            <td><Link to={"javascript:void(0)"}>LP1-AP-51438768900 --- system</Link></td>
                            <td><Link title="Relate" to={"javascript:void(0)"}><LuLink /></Link></td>
                        </tr>
                    </tbody>
                </Table>
            }
        </>
    );
};

export default SimilarityDetection;