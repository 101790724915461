
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import ReactDOM from "react-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'

import moment from 'moment';
import { connect } from 'react-redux';
import ListPagination from "react-js-pagination";
import { bindActionCreators } from 'redux';
import {
  loadOrdersList,
  loadFilterOrderList,
  loadOrderDetails,
  fetchCancellationType,
  loadBreakFixOrderDetails,
  loadApproverList,
  getQuestionsCategoryWise,
  resetMRQVFilters,
  loadOrdersFilterResultsForSearchString,
  resetSortingArrows,
  sortingArrowsChanged,
  addToSelectedOrderTypeList,
  removeFromSelectedOrderTypeList,
  addToSelectedOrderStatusList,
  removeFromSelectedOrderStatusList,
  getBreakfixQuestionsCategoryWise
} from '../../actions/cart/myOrders/myOrdersAction';
import { setUrgencyList } from '../../actions/serviceDeskActions/myOrdersAction';
import { loadBreakFixOrderDetailsInteger } from '../../actions/serviceDeskActions/myOrdersAction';
import { loadBreakFixActivityLogDetails } from '../../actions/breakFix/breakFixActivityLogAction';
import { loadChangeTotalApprovalDetails, loadChangeTotalApprovalHistoryDetails } from '../../actions/changeManagement/changeTotalApprovalAction';
import { loadProfile2 } from '../../actions/myProfile/profileAction';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import ListLoader from "../common/loaders/ListLoader";
import { loadImplPlanList } from "../../actions/common/attachmentAction";
import PropTypes from 'prop-types';
import _ from 'lodash';
import {IoSearch} from 'react-icons/io5';
// import PieChart from '_Commons/pieChart.js';

// import { Row, Col, Accordion, Panel, FormGroup, Checkbox, Popover, OverlayTrigger, ListGroup, ListGroupItem, PageHeader, FormControl, ControlLabel, ButtonToolbar, Button, Nav, NavItem, Table, Tabs, Tab, Radio } from 'react-bootstrap';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import Cookies from 'universal-cookie';
import MyRequestMultiSelect from '../common/MultiSelectCheckboxWithOK';
const cookies = new Cookies();
let languageSelected = cookies.get("gph");
languageSelected = languageSelected.split("~");
languageSelected = languageSelected[20];
let email_address =  cookies.get("gph");
email_address = email_address.split("~");
email_address = email_address[29];
let dateformat = cookies.get('gph');
if (dateformat) dateformat = dateformat.replace('s:', '');
if (dateformat) dateformat = dateformat.substring(0, dateformat.lastIndexOf('.'));
dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();
jwtdf = jwtdf.replace(/-/g, '/');

let actualItemId = "";

class MyRequestListView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedAction: '',
      faCustomDesc1: "customArrow dropdown",
      faCustomAsc1: "customArrow dropup",
      faCustomDesc2: "customArrow dropdown",
      faCustomAsc2: "customArrow dropup",
      faCustomDesc3: "customArrow dropdown",
      faCustomAsc3: "customArrow dropup",
      faCustomDesc4: "customArrow dropdown isActive",
      faCustomAsc4: "customArrow dropup",
      faCustomDesc5: "customArrow dropdown",
      faCustomAsc5: "customArrow dropup",
      faCustomDesc6: "customArrow dropdown",
      faCustomAsc6: "customArrow dropup",
      page: 0,
      size: 10,
      index: 0,
      isErrorFilterSearch: false,
      searchclick: false,
      sort: true,
      check_status: 0,
      displaySearchFilter: "",
      ciServiceSearchFilter: "",
      requesterNameSearchFilter: "",
      submittedDateSearchFilter: "",
      modifiedDateSearchFilter: "",
      lastSelectedSearchFilter: 'createdOn',
      // isSubmittedModified: "",
      isSubmitted: "createdOn",
      isModified: "modifiedOn",
      isMyReqStatusAllLabel: false,
      lastSelectedStatusFilter: [],
      isMultiSelectOpen: true,
    };
    this.onSortAsc = this.onSortAsc.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onPageSizeChange = this.onPageSizeChange.bind(this);
    this.showActive = this.showActive.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleCallback = this.handleCallback.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.selectAllLabelFun = this.selectAllLabelFun.bind(this);
    this.multiSelectOkButtonFunc = this.multiSelectOkButtonFunc.bind(this);
    this.setLastSelectedStatusFilter = this.setLastSelectedStatusFilter.bind(this);
  }

  componentDidMount() {
    let ref = this;
    if (ref.props.searcTextGloSearch) {
      console.log("1++++++++++");
      ref.props.loadOrdersFilterResultsForSearchString("requesterId", "type", "displayNumber", "", "createdOn", "desc", 0, 10, (ref.props.searcTextGloSearch).trim(), "onBehalf");
    }
    else {
      console.log("2++++++++++");
      // ref.props.loadOrdersList("requesterId", "type", "", "createdOn", "desc", 0, ref.state.size, ref.props.listFilter, '20,10,15,65,70,-20,100,60,50');
      ref.props.loadOrdersList("requesterId", "type", "", "createdOn", "desc", 0, ref.state.size, ref.props.filterValue, '20,10,15,65,70,-20,100,60,50');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.resetSortArrows != nextProps.resetSortArrows && nextProps.resetSortArrows == true) {
      this.setState({
        faCustomDesc1: "customArrow dropdown isActive",
        faCustomAsc1: "customArrow dropup",
        faCustomDesc2: "customArrow dropdown",
        faCustomAsc2: "customArrow dropup",
        faCustomDesc3: "customArrow dropdown",
        faCustomAsc3: "customArrow dropup",
        faCustomDesc4: "customArrow dropdown",
        faCustomAsc4: "customArrow dropup",
        faCustomDesc5: "customArrow dropdown",
        faCustomAsc5: "customArrow dropup"
      });
    }

    if (this.props.searcTextGloSearch != nextProps.searcTextGloSearch) {
      console.log("3++++++++++");

      this.props.loadOrdersFilterResultsForSearchString("requesterId", "type", "displayNumber", "", "createdOn", "desc", 0, 10, (nextProps.searcTextGloSearch).trim(), "onBehalf");
    }
  }

  componentDidUpdate(prevProps) {
    if (this.multiSelectDropdownRef != undefined || this.multiSelectDropdownRef != null) {
      ReactDOM.findDOMNode(this.multiSelectDropdownRef).childNodes[0].className = "multiSelectRef";
      let myRef = this.multiSelectDropdownRef;
      if (this.props.isStatusOpen !== prevProps.isStatusOpen) {
        let obj = ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1];
        if (obj != undefined || obj != null) {
            ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[1].className =
            "multiSelectPopup";
            ReactDOM.findDOMNode(myRef).childNodes[0].childNodes[2].style =
            "display:none";
        }
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.clickOutside);
  }

  clickOutside(event) {
    const taskStatusDv = document.getElementById("taskStatusDv");

    if (this.multiSelectStatusDropdownRef != undefined || this.multiSelectStatusDropdownRef != null) {
      document.addEventListener("mousedown", (event) => {
        if (taskStatusDv.contains(event.target)) {
          // console.log("TYPE CLICKED");
          this.setState({ isMultiSelectOpen: true });
          this.props.showRightSide(false);
        }
        else {
          this.setState({ isMultiSelectOpen: false });
        }
        this.props.multiSelectOpenFun("myReqStatus", false);
      });
    }
  }

  selectAllLabelFun(keyName, value) {
    switch(keyName) {
      case "myReqStatus":
        this.setState({ isMyReqStatusAllLabel: value });
        break;
    }
  }

  setLastSelectedStatusFilter() {
    if(!this.props.isStatusOpen) {
      this.setState({ lastSelectedStatusFilter: this.props.selectedValue });
    }
  }

  multiSelectOkButtonFunc() {
    let statusFilterArray = this.props.status.split(',');
    this.props.searchKey.length == 0 && this.props.searchValue.length == 0 ?
    statusFilterArray.length == 0 ?
      this.props.isClearAllFilterFunc(false) :
      this.props.isClearAllFilterFunc(true) :
    this.props.isClearAllFilterFunc(true);

    let searchParams = {};
    searchParams = this.props.buildSearchParamsForOrderFilter();
    if (_.isEmpty(searchParams)) {
      if (this.props.searchKey.length != 0 && this.props.searchValue.length != 0) {
        this.setState({ searchclick: true });
      console.log("4++++++++++");
      if(this.props.filterValue == 'watcher'){
      console.log("4.1++++++++++");

        this.props.loadOrdersFilterResultsForSearchString("requesterId", "type", this.props.searchKey, "", this.props.sortOnColumn, this.props.sortOrder, 0, this.props.limit, this.props.searchValue, this.props.pattern, this.props.filterValue,email_address,this.props.status);
      }else{
      console.log("4.2++++++++++");

        // this.props.loadOrdersFilterResultsForSearchString("requesterId", "type", this.props.searchKey, "", this.props.sortOnColumn, this.props.sortOrder, 0, this.props.limit, this.props.searchValue, this.props.pattern, this.props.listFilter);
        this.props.loadOrdersFilterResultsForSearchString("requesterId", "type", this.props.searchKey, "", this.props.sortOnColumn, this.props.sortOrder, 0, this.props.limit, this.props.searchValue, this.props.pattern, this.props.filterValue);
      }
      }
      else {
      console.log("5++++++++++");
      if(this.props.filterValue == 'watcher'){
      console.log("5.1++++++++++");

        this.props.loadOrdersList("requesterId", "type", "", this.props.sortOnColumn, this.props.sortOrder, 0, this.props.limit, this.props.filterValue, statusFilterArray.join(","),email_address);

      }else{
      console.log("5.2++++++++++");

        // this.props.loadOrdersList("requesterId", "type", "", this.props.sortOnColumn, this.props.sortOrder, 0, this.props.limit, this.props.listFilter, statusFilterArray.join(","));
        this.props.loadOrdersList("requesterId", "type", "", this.props.sortOnColumn, this.props.sortOrder, 0, this.props.limit, this.props.filterValue, statusFilterArray.join(","));
      }
      }
    }
    else {
      this.setState({ searchclick: true });
      console.log("6++++++++++");
      if(this.props.filterValue == 'watcher'){
      console.log("6.1++++++++++");

        this.props.loadFilterOrderList(searchParams, 0, this.props.limit, this.props.sortOnColumn, this.props.sortOrder, this.props.searchKey,
          this.props.searchValue, this.props.pattern, statusFilterArray.join(","),this.props.filterValue,email_address);
      }else{
      console.log("6.2++++++++++");

        // this.props.loadFilterOrderList(searchParams, 0, this.props.limit, this.props.sortOnColumn, this.props.sortOrder, this.props.searchKey,
        //   this.props.searchValue, this.props.pattern, statusFilterArray.join(","));
        this.props.loadFilterOrderList(searchParams, 0, this.props.limit, this.props.sortOnColumn, this.props.sortOrder, this.props.searchKey,
          this.props.searchValue, this.props.pattern, statusFilterArray.join(","));
      }
  
    }
    this.props.showActiveRow(0, 0);
  }

  handleCheckBoxChange(value, event) {
    console.log('MultiSelect --- 5', value);
    this.props.setSelectedValue(value);
    this.props.showRightPanelFun(false);
    let status = '20,10,15,65,70,-20,100,60,50';
    let searchOnStatusArr = [];
    value.map((checkBoxValue) => {
      return (
        searchOnStatusArr.push(checkBoxValue.value)
      );
    }
    );
    this.props.updateParameter(searchOnStatusArr.length == 0 ? status : searchOnStatusArr.join(","));
  }


  onSortAsc(sort, sortValue, sortType, lastFilter) {
    let orderBy = "";
    if (lastFilter == sortValue) {
      this.setState({ sort: !sort });
      orderBy = sortType;
    }
    else if (lastFilter != sortValue) {
      this.setState({ sort: "desc" });
      orderBy = "desc";
    }
    let searchParams = {};
    searchParams = this.props.buildSearchParamsForOrderFilter();
    if (_.isEmpty(searchParams)) {
      if (this.props.searchKey.length != 0 && this.props.searchValue.length != 0) {
        this.setState({ searchclick: true });
      console.log("7++++++++++");
      if(this.props.filterValue == 'watcher'){
        this.props.loadOrdersFilterResultsForSearchString("requesterId", "type", this.props.searchKey, "", sortValue, orderBy, 0, this.props.limit, this.props.searchValue, this.props.filterValue, this.props.pattern,email_address,this.props.status);
      }else{
        // this.props.loadOrdersFilterResultsForSearchString("requesterId", "type", this.props.searchKey, "", sortValue, orderBy, 0, this.props.limit, this.props.searchValue, this.props.pattern, this.props.listFilter);
        this.props.loadOrdersFilterResultsForSearchString("requesterId", "type", this.props.searchKey, "", sortValue, orderBy, 0, this.props.limit, this.props.searchValue, this.props.filterValue,this.props.pattern);
      }
      }
      else {
      console.log("8++++++++++");
      if(this.props.filterValue == 'watcher'){
      console.log("8.1++++++++++");

        this.props.loadOrdersList("requesterId", "type", "", sortValue, orderBy, 0, this.props.limit, this.props.filterValue, '20,10,15,65,70,-20,100,60,50',email_address);
      }else{
      console.log("8.2++++++++++");

        // this.props.loadOrdersList("requesterId", "type", "", sortValue, orderBy, 0, this.props.limit, this.props.listFilter, '20,10,15,65,70,-20,100,60,50');
        this.props.loadOrdersList("requesterId", "type", "", sortValue, orderBy, 0, this.props.limit, this.props.filterValue, '20,10,15,65,70,-20,100,60,50');

      }
      }
    }
    else {
      this.setState({ searchclick: true });
      console.log("9++++++++++");
      if(this.props.filterValue == 'watcher'){
      console.log("9.1++++++++++");

        this.props.loadFilterOrderList(searchParams, 0, this.props.limit, sortValue, orderBy, this.props.searchKey,
          this.props.searchValue, this.props.pattern, this.props.status,this.props.filterValue,email_address); 
      }else{
      console.log("9.2++++++++++");

        this.props.loadFilterOrderList(searchParams, 0, this.props.limit, sortValue, orderBy, this.props.searchKey,
          this.props.searchValue, this.props.pattern, this.props.status); 
      }
      
    }
    this.props.setSortOrder(orderBy, sortValue);
    switch (sortValue) {
      case 'displayNumber':
        this.setState({ lastSelectedSearchFilter: 'displayNumber' });
        this.setState({
          faCustomDesc1: orderBy == "asc" ? "customArrow dropdown" : orderBy == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc1: orderBy == "asc" ? "customArrow dropup isActive" : orderBy == "desc" ? "customArrow dropup" : "",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
          faCustomDesc6: "customArrow dropdown",
          faCustomAsc6: "customArrow dropup"

        });
        break;
      case 'ciServiceName':
        this.setState({ lastSelectedSearchFilter: 'ciServiceName' });
        this.setState({
          faCustomDesc2: orderBy == "asc" ? "customArrow dropdown" : orderBy == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc2: orderBy == "asc" ? "customArrow dropup isActive" : orderBy == "desc" ? "customArrow dropup" : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
          faCustomDesc6: "customArrow dropdown",
          faCustomAsc6: "customArrow dropup"

        });
        break;
      case 'requesterName':
        this.setState({ lastSelectedSearchFilter: 'requesterName' });
        this.setState({
          faCustomDesc3: orderBy == "asc" ? "customArrow dropdown" : orderBy == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc3: orderBy == "asc" ? "customArrow dropup isActive" : orderBy == "desc" ? "customArrow dropup" : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
          faCustomDesc6: "customArrow dropdown",
          faCustomAsc6: "customArrow dropup"
        });
        break;
      case 'createdOn':
        this.setState({ lastSelectedSearchFilter: 'createdOn' });
        this.setState({
          faCustomDesc4: orderBy == "asc" ? "customArrow dropdown" : orderBy == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc4: orderBy == "asc" ? "customArrow dropup isActive" : orderBy == "desc" ? "customArrow dropup" : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup",
          faCustomDesc6: "customArrow dropdown",
          faCustomAsc6: "customArrow dropup"
        });
        break;
      case 'modifiedOn':
        this.setState({ lastSelectedSearchFilter: 'modifiedOn' });
        this.setState({
          faCustomDesc5: orderBy == "asc" ? "customArrow dropdown" : orderBy == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc5: orderBy == "asc" ? "customArrow dropup isActive" : orderBy == "desc" ? "customArrow dropup" : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc6: "customArrow dropdown",
          faCustomAsc6: "customArrow dropup"
        });
        break;
      case 'itemStatus':
        this.setState({ lastSelectedSearchFilter: 'itemStatus' });
        this.setState({
          faCustomDesc6: orderBy == "asc" ? "customArrow dropdown" : orderBy == "desc" ? "customArrow dropdown isActive" : "",
          faCustomAsc6: orderBy == "asc" ? "customArrow dropup isActive" : orderBy == "desc" ? "customArrow dropup" : "",
          faCustomDesc1: "customArrow dropdown",
          faCustomAsc1: "customArrow dropup",
          faCustomDesc2: "customArrow dropdown",
          faCustomAsc2: "customArrow dropup",
          faCustomDesc3: "customArrow dropdown",
          faCustomAsc3: "customArrow dropup",
          faCustomDesc4: "customArrow dropdown",
          faCustomAsc4: "customArrow dropup",
          faCustomDesc5: "customArrow dropdown",
          faCustomAsc5: "customArrow dropup"
        });
        break;
    }
    this.props.showActiveRow(0, 0);
    this.props.sortingArrowsChanged();
  }

 

  onPageChange(e) {

    this.props.showRightPanelFun(false);
    this.setState({ page: e - 1 });
    let searchParams = {};
    searchParams = this.props.buildSearchParamsForOrderFilter();
    if (_.isEmpty(searchParams)) {
      if (this.props.searchKey.length != 0 && this.props.searchValue.length != 0) {

        this.setState({ searchclick: true });
      console.log("10++++++++++");
      if(this.props.filterValue == 'watcher'){
      console.log("10.1++++++++++");

        this.props.loadOrdersFilterResultsForSearchString("requesterId", "type", this.props.searchKey, "", this.props.sortOnColumn, this.props.sortOrder, e - 1, this.props.limit, this.props.searchValue, this.props.filterValue,this.props.pattern,email_address,this.props.status );

      }else{
      console.log("10.2++++++++++");

        // this.props.loadOrdersFilterResultsForSearchString("requesterId", "type", this.props.searchKey, "", this.props.sortOnColumn, this.props.sortOrder, e - 1, this.props.limit, this.props.searchValue, this.props.pattern, this.props.listFilter);
        this.props.loadOrdersFilterResultsForSearchString("requesterId", "type", this.props.searchKey, "", this.props.sortOnColumn, this.props.sortOrder, e - 1, this.props.limit, this.props.searchValue, this.props.pattern, this.props.filterValue);
      }
      }
      else {
      console.log("11++++++++++");
      if(this.props.filterValue == 'watcher'){
      console.log("11.1++++++++++");

        this.props.loadOrdersList("requesterId", "type", "", this.props.sortOnColumn, this.props.sortOrder, e - 1, this.props.limit, this.props.filterValue, '20,10,15,65,70,-20,100,60,50',email_address);

      }else{
      console.log("11.2++++++++++");

        // this.props.loadOrdersList("requesterId", "type", "", this.props.sortOnColumn, this.props.sortOrder, e - 1, this.props.limit, this.props.listFilter, '20,10,15,65,70,-20,100,60,50');
        this.props.loadOrdersList("requesterId", "type", "", this.props.sortOnColumn, this.props.sortOrder, e - 1, this.props.limit, this.props.filterValue, '20,10,15,65,70,-20,100,60,50');

      }
      }
    }
    else {
      this.setState({ searchclick: true });
      if(this.props.filterValue == 'watcher'){
      console.log("12++++++++++");

        this.props.loadFilterOrderList(searchParams, e - 1, this.props.limit, this.props.sortOnColumn, this.props.sortOrder, this.props.searchKey,
          this.props.searchValue, this.props.pattern, this.props.status,this.props.filterValue,email_address);
      }else{
      console.log("13++++++++++");

        this.props.loadFilterOrderList(searchParams, e - 1, this.props.limit, this.props.sortOnColumn, this.props.sortOrder, this.props.searchKey,
          this.props.searchValue, this.props.pattern, this.props.status);
      }
    }
    this.props.updatePagination(e - 1, this.props.limit);
    this.props.showActiveRow(0, 0);
  }

  onPageSizeChange(e) {
    this.props.showRightPanelFun(false);
    let size = parseInt(e.target.value, 10);
    this.setState({ size: size });
    let searchParams = {};
    searchParams = this.props.buildSearchParamsForOrderFilter();
    if (_.isEmpty(searchParams)) {
      if (this.props.searchKey.length != 0 && this.props.searchValue.length != 0) {
    console.log("14++++++++++");
    this.setState({ searchclick: true });

    if(this.props.filterValue == 'watcher'){
    console.log("14.1++++++++++");

      this.props.loadOrdersFilterResultsForSearchString("requesterId", "type", this.props.searchKey, "", this.props.sortOnColumn, this.props.sortOrder, 0, size, this.props.searchValue, this.props.filterValue, this.props.pattern,email_address,this.props.status);

    }else{
    console.log("14.2++++++++++");

      // this.props.loadOrdersFilterResultsForSearchString("requesterId", "type", this.props.searchKey, "", this.props.sortOnColumn, this.props.sortOrder, 0, size, this.props.searchValue, this.props.pattern, this.props.listFilter);
      this.props.loadOrdersFilterResultsForSearchString("requesterId", "type", this.props.searchKey, "", this.props.sortOnColumn, this.props.sortOrder, 0, size, this.props.searchValue, this.props.pattern, this.props.filterValue);

    }
      }
      else {
      console.log("15++++++++++");
      if(this.props.filterValue == 'watcher'){
      console.log("15.1++++++++++");

        this.props.loadOrdersList("requesterId", "type", "", this.props.sortOnColumn, this.props.sortOrder, 0, size, this.props.filterValue, '20,10,15,65,70,-20,100,60,50',email_address);

      }else{
      console.log("15.2++++++++++");

        // this.props.loadOrdersList("requesterId", "type", "", this.props.sortOnColumn, this.props.sortOrder, 0, size, this.props.listFilter, '20,10,15,65,70,-20,100,60,50');
        this.props.loadOrdersList("requesterId", "type", "", this.props.sortOnColumn, this.props.sortOrder, 0, size, this.props.filterValue, '20,10,15,65,70,-20,100,60,50');

      }
      }
    }
    else {
      this.setState({ searchclick: true });
      console.log("16++++++++++");
      if(this.props.filterValue == 'watcher'){
      console.log("16.1++++++++++");

        this.props.loadFilterOrderList(searchParams, 0, size, this.props.sortOnColumn, this.props.sortOrder, this.props.searchKey,
          this.props.searchValue, this.props.pattern, this.props.status,this.props.filterValue,email_address);
      }else{
      console.log("16.2++++++++++");

        this.props.loadFilterOrderList(searchParams, 0, size, this.props.sortOnColumn, this.props.sortOrder, this.props.searchKey,
          this.props.searchValue, this.props.pattern, this.props.status);
        // this.props.loadFilterOrderList(searchParams, 0, size, this.props.sortOnColumn, this.props.sortOrder, this.props.searchKey,
        //   this.props.searchValue, this.props.pattern, this.props.status);
      }
    
    }
    this.props.updatePagination(0, size);
    this.props.showActiveRow(0, 0);
  }

  handleCallback(filter, start, end) {
    switch (filter) {
      case 'createdOn':
        this.setState({ submittedDateSearchFilter: "createdOn" });
        this.props.setSubmittedStartAndEndDate(start.format('MM/DD/YYYY'), end.format('MM/DD/YYYY'));
        this.setState({ isSubmitted: "createdOn" });
        break;
      case 'modifiedOn':
        this.setState({ modifiedDateSearchFilter: "modifiedOn" });
        this.props.setModifiedStartAndEndDate(start.format('MM/DD/YYYY'), end.format('MM/DD/YYYY'));
        this.setState({ isModified: "modifiedOn" });
        break;
    }
  }

  handleSearch(e, picker, val) {
    this.props.showRightPanelFun(false);
    let searchByVar = [];
    let searchByValue = [];
    let pattern = [];
    let submittedDataArr = [];
    let modifiedDataArr = [];
    let searchInputVar = this.props.ciServiceNameSearchInput.replace(/[&]/g, '');
    searchInputVar = searchInputVar.replace(/[|]/g, '');
    searchInputVar = searchInputVar.replace(/[,]/g, '');
    console.log('searchInputVar====',searchInputVar);

    let displayNumberSearchInputVar = this.props.displayNumberSearchInput.replace(/[&]/g, '');
    displayNumberSearchInputVar = displayNumberSearchInputVar.replace(/[|]/g, '');
    displayNumberSearchInputVar = displayNumberSearchInputVar.replace(/[,]/g, '');

    let requesterNameSearchInputVar = this.props.requesterNameSearchInput.replace(/[&]/g, '');
    requesterNameSearchInputVar = requesterNameSearchInputVar.replace(/[|]/g, '');
    requesterNameSearchInputVar = requesterNameSearchInputVar.replace(/[,]/g, '');

    let submittedStartDate = this.props.submittedStartDate;    
    let submittedEndDate = this.props.submittedEndDate;
    let modifiedStartDate = this.props.modifiedStartDate;
    let modifiedEndDate = this.props.modifiedEndDate;

    this.props.isClearAllFilterFunc(true);
    if (displayNumberSearchInputVar != "") {
      searchByValue.push(displayNumberSearchInputVar.trim());
      searchByVar.push(this.state.displaySearchFilter);
      pattern.push("like");
    }
    
    // if (!_.isEmpty(this.props.submittedStartDate) && !_.isEmpty(this.props.submittedEndDate)) {
    if (picker && val == "createdOn") {
      if (this.state.isSubmitted == "createdOn") {this.props.setSubmittedRef(picker)}
      submittedDataArr.push(moment(picker.startDate).format("YYYY-MM-DD"), moment(picker.endDate).format("YYYY-MM-DD"));
      searchByValue.push(submittedDataArr.join("&"));
      searchByVar.push(val);
      pattern.push("between");
    } else if (typeof submittedStartDate == 'string' && submittedStartDate.length > 0 && typeof submittedEndDate == 'string' && submittedEndDate.length > 0 ) {
      submittedDataArr.push(moment(submittedStartDate).format("YYYY-MM-DD"), moment(submittedEndDate).format("YYYY-MM-DD"));
      searchByValue.push(submittedDataArr.join("&"));
      searchByVar.push("createdOn");
      pattern.push("between");
    }
    
    // if (!_.isEmpty(this.props.modifiedStartDate) && !_.isEmpty(this.props.modifiedEndDate)) {
    if (picker && val == "modifiedOn" ) {
      if (this.state.isModified == "modifiedOn") {this.props.setModifiedRef(picker)}
      modifiedDataArr.push(moment(picker.startDate).format("YYYY-MM-DD"), moment(picker.endDate).format("YYYY-MM-DD"));
      searchByValue.push(modifiedDataArr.join("&"));
      searchByVar.push(val);
      pattern.push("between");
    } else if (typeof modifiedStartDate == 'string' && modifiedStartDate.length > 0 && typeof modifiedEndDate == 'string' && modifiedEndDate.length > 0 ) {
      modifiedDataArr.push(moment(modifiedStartDate).format("YYYY-MM-DD"), moment(modifiedEndDate).format("YYYY-MM-DD"));
      searchByValue.push(modifiedDataArr.join("&"));
      searchByVar.push("modifiedOn");
      pattern.push("between");
    }
  
    if (searchInputVar != "") {
      searchByValue.push(searchInputVar.trim());
      searchByVar.push(this.state.ciServiceSearchFilter);
      pattern.push("like");
    }

    if (requesterNameSearchInputVar != "") {
      searchByValue.push(requesterNameSearchInputVar.trim());
      searchByVar.push(this.state.requesterNameSearchFilter);
      pattern.push("like");
    }
    let searchParams = {};
    searchParams = this.props.buildSearchParamsForOrderFilter();
    if (_.isEmpty(searchParams)) {
      if (searchByVar.length != 0 && searchByValue.length != 0) {
        this.setState({ searchclick: true });
        this.props.resetSortingArrows();
        this.props.ordersListFilter2();
        if(this.props.filterValue == 'watcher'){
      console.log("17++++++++++");

          this.props.loadOrdersFilterResultsForSearchString("requesterId", "type", searchByVar.join(','), "", this.props.sortOnColumn, this.props.sortOrder, 0, 10, searchByValue.join('|'), this.props.filterValue, pattern.join(','),email_address,this.props.status);

        }else{
      console.log("18++++++++++");

          // this.props.loadOrdersFilterResultsForSearchString("requesterId", "type", searchByVar.join(','), "", this.props.sortOnColumn, this.props.sortOrder, 0, 10, searchByValue.join('|'), this.props.listFilter, pattern.join(','));
          this.props.loadOrdersFilterResultsForSearchString("requesterId", "type", searchByVar.join(','), "", this.props.sortOnColumn, this.props.sortOrder, 0, 10, searchByValue.join('|'), this.props.filterValue, pattern.join(','));
        }
        this.props.showActiveRow(0, 0);
        //email_address
      }
      else {
      console.log("19++++++++++");
      if(this.props.filterValue == 'watcher'){
        this.props.loadOrdersList("requesterId", "type", "", this.props.sortOnColumn, this.props.sortOrder, 0, 10, this.props.filterValue, '20,10,15,65,70,-20,100,60,50',email_address);

      }else{
        // this.props.loadOrdersList("requesterId", "type", "", this.props.sortOnColumn, this.props.sortOrder, 0, 10, this.props.listFilter, '20,10,15,65,70,-20,100,60,50');
        this.props.loadOrdersList("requesterId", "type", "", this.props.sortOnColumn, this.props.sortOrder, 0, 10, this.props.filterValue, '20,10,15,65,70,-20,100,60,50');

      }
      }
    }
    else {
      console.log("searchByValue====",searchByValue);
      console.log("searchByVar====",searchByVar);

      this.setState({ searchclick: true });
      this.props.resetSortingArrows();
      this.props.ordersListFilter2();

      if(this.props.filterValue == 'watcher'){
      console.log("20++++++++++");

        this.props.loadFilterOrderList(searchParams, 0, 10, this.props.sortOnColumn, this.props.sortOrder, searchByVar.join(','), searchByValue.join('|'), pattern.join(','), this.props.status,this.props.filterValue,email_address);
      }else{
      console.log("21++++++++++");

        this.props.loadFilterOrderList(searchParams, 0, 10, this.props.sortOnColumn, this.props.sortOrder, searchByVar.join(','), searchByValue.join('|'), pattern.join(','), this.props.status);

      }
      this.props.showActiveRow(0, 0);
    }
    this.props.setSearchKeyValue(searchByVar.join(','), searchByValue.join('|'), pattern.join(','));
  }


  showActive(i) {
    if (this.state.check_status == 0)
      this.state.check_status++;
    this.setState({ index: i });
  }

  renderData(breakFixData) {
    let tempBreakFixData = breakFixData;
    let userOrders = [];
    if (breakFixData.length == 0 || breakFixData.length == undefined) {
      userOrders = [];
      return (
        <div><ListLoader myStyle={{ marginTop: "20px" }} /></div>
      );
    } else {
      for (let i = 0; i <= breakFixData.length - 1; i++) {
        breakFixData[i].itemId = breakFixData[i].itemId + "," + breakFixData[i].type;
        userOrders.push(breakFixData[i]);
      }
    }

    if (userOrders.length == 0 || userOrders.length == undefined) {
      return (
        <div><font><b>{this.props.tr['There is no matching data available']}</b></font></div>
      );
    }

    return userOrders.map((elem, index) => {
      let cls = "";
      return (
        <tr className={this.props.isRightPanel && index == this.props.rowIndex ? cls = "myActive" : cls = ""}
          onClick={() => {
            this.props.showRightPanelFun(true);
            this.props.multiSelectOpenFun("myReqStatus", false);
            let getItemId = (elem.itemId) ? (elem.itemId).split(",") : "";
            actualItemId = getItemId[0];
            this.props.setCiServiceName(elem.ciServiceName, elem.serviceBased);
            if (elem.type == "Breakfix") {
              this.props.loadBreakFixActivityLogDetails(actualItemId, this.props.sortOrder, "Breakfix");
              this.props.loadBreakFixOrderDetails(actualItemId);
              this.props.loadBreakFixOrderDetailsInteger(actualItemId);
              this.props.loadImplPlanList("Breakfix", actualItemId);
              this.props.loadProfile2(elem.requesterId);
              this.props.getBreakfixQuestionsCategoryWise(actualItemId);
              this.props.setUrgencyList(elem.serviceBased ? elem.serviceConsumerCompany : elem.ciConsumerCompany,'Breakfix','Urgency',languageSelected,'1');
            } else if (elem.type == "Item") {
              this.props.loadBreakFixActivityLogDetails(actualItemId, this.props.sortOrder, "Fullfilment");
              this.props.loadOrderDetails(actualItemId);
              this.props.getQuestionsCategoryWise(actualItemId);
              this.props.loadImplPlanList("Item", actualItemId);
              this.props.loadProfile2(elem.requesterId);
              this.props.fetchCancellationType(elem.companyId,'Fulfillment','Cancelled','1');
            }
            this.props.getOrderType(elem.type);
            this.props.getOrderDetails(elem.displayNumber);
            this.props.getItemId(actualItemId);
            this.props.getOrderStatus(elem.itemStatus);
            this.props.showActiveRow(index, 1);
          }}
        >

          <td>
            <Button
              bsClass=''
              className='rowFocus'>
            </Button>
            <div
              className='showdv414'>
              <div className='valdv414'>
                {elem.displayNumber}
                {elem.ciServiceName?.length > 50 ?
                  <div className='keydv414'>
                    {elem.ciServiceName?.slice(0, 50)}{"..."}
                  </div> :
                  <div
                    className='keydv414'>
                    {elem.ciServiceName?.slice(0, 50)}
                  </div>}
              </div>
            </div>
          </td>
          {
            elem.ciServiceName?.length > 50 ?
            <td
              className='hiddenBe414'
              title={elem.ciServiceName}>
              <div
                className='showdv414'>
                <div className='keydv414'>
                  {this.props.tr['Service/CI Name']}
                </div>
                <div className='valdv414'>
                  {elem.ciServiceName?.slice(0, 50)}{"..."}
                </div>
              </div>
            </td>
            :
            <td
              className='hiddenBe414'>
              <div
                className='showdv414'>
                  <div
                    className='keydv414'>
                  {this.props.tr['Service/CI Name']}
                </div>
                <div
                  className='valdv414'>
                  {elem.ciServiceName?.slice(0, 50)}
                </div>
              </div>
            </td>
          }
          <td><div className='showdv414'><div className='keydv414'>{this.props.tr['Requested For']}</div><div className='valdv414'>{elem.requesterName}</div></div></td>
          <td><div className='showdv414'><div className='keydv414'>{this.props.tr['Submitted Date']}</div><div className='valdv414'>{elem.createdOn}</div></div></td>
          <td className="hiddenBe414"><div className='showdv414'><div className='keydv414'>{this.props.tr['Modified Date']}</div><div className='valdv414'>{elem.modifiedOn}</div></div></td>
          {elem.itemStatus == "Fullfiled" ? <td><div className='showdv414'><div className='keydv414'>{this.props.tr['Fulfilled']}</div><div className='valdv414'>{this.props.tr['Fulfilled']}</div></div></td> : <td><div className='showdv414'><div className='keydv414'>{this.props.tr['Status']}</div><div className='valdv414'>{this.props.tr[elem.itemStatus]}</div></div></td>}
        </tr>
      );

    });
  }

  getStatus(statustext) {
    console.log('MMM : statustext: ', statustext);
   return this.props.tr[statustext]
  }
  handleChange(e, searchValue) {
    this.props.showRightPanelFun(false);
    switch (searchValue) {
      case "displayNumber":
        this.props.setdisplayNumberInputValues(e.target.value);
        this.setState({ displaySearchFilter: searchValue });
        break;
      case "ciServiceName":
        this.props.setCiServiceNameInputValues(e.target.value);
        this.setState({ ciServiceSearchFilter: searchValue });
        break;
      case "requesterName":
        this.props.setRequestedByInputValues(e.target.value);
        this.setState({ requesterNameSearchFilter: searchValue });
        break;
    }

  }
  render() {
    console.log("this.props.ordersList----",this.props.ordersList);
    console.log("PAGINATIOn LIMIT", this.props.limit);
    let mySubmittedStartDate = !_.isEmpty(this.props.submittedStartDate) ? moment(this.props.submittedStartDate).format(jwtdf) : "";
    let mySubmittedEndDate = !_.isEmpty(this.props.submittedEndDate) ? moment(this.props.submittedEndDate).format(jwtdf) : "";
    let myModifiedStartDate = !_.isEmpty(this.props.modifiedStartDate) ? moment(this.props.modifiedStartDate).format(jwtdf) : "";
    let myModifiedEndDate = !_.isEmpty(this.props.modifiedEndDate) ? moment(this.props.modifiedEndDate).format(jwtdf) : "";
    let mySubmittedNewDate = mySubmittedStartDate == "" && mySubmittedEndDate == "" ? "" : mySubmittedStartDate + "-" + mySubmittedEndDate;
    let myModifiedNewDate = myModifiedStartDate == "" && myModifiedEndDate == "" ? "" : myModifiedStartDate + "-" + myModifiedEndDate;
    let ascDscToggle = "";
    ascDscToggle = this.state.sort ? "asc" : "desc";
    let caseResponsive = this.props.showLoader_2 == true || this.props.showLoader_3 == true || this.props.showLoader_1 == true || (this.props.ordersList?.length <= 6 && this.state.isMultiSelectOpen) || this.props.ordersList == [] ? "overFlowClp" : "table-responsive";
    let panelColSize = this.props.panelResize;

    if (this.props.isRightPanel == true) {
      this.multiSelectDropdownRef.childNodes[0].childNodes[0].addEventListener('click', () => {
        this.props.showRightPanelFun(false);
      });
    }

    let meta = this.props.ordersListMeta;
    const multiSelectOptions = [
      { label: this.props.tr['Approved'], value: "90" },
      { label: this.props.tr['Cancelled'], value: "85,35" },
      { label: this.props.tr['Closed'], value: "80,30" },
      { label: this.props.tr['Draft'], value: "-20" },
      { label: this.props.tr['Fixed'], value: "25" },
      { label: this.props.tr['Fulfilled'], value: "75" },
      { label: this.props.tr['In Progress'], value: "15" },
      { label: this.props.tr['On Hold'], value: "60" },
      { label: this.props.tr['Pending'], value: "20" },
      { label: this.props.tr['Pending Approval'], value: "65" },
      { label: this.props.tr['Referred Back'], value: "100" },
      { label: this.props.tr['Rejected'], value: "95" },
      { label: this.props.tr['Submitted'], value: "10,50" },
      { label: this.props.tr['Under Fulfillment'], value: "70" }
    ];
    return (
      <div>
        <Row className="xtFilterBar margin-b-5 hidden">
          {this.state && this.state.isErrorFilterSearch ? (
            <Col md={12} sm={6} xs={6}>
              <span style={{ color: "red" }}>
                {this.props.tr["Please enter the required data..."]}
              </span>
            </Col>
          ) : null}
        </Row>
        <div aria-label="Table" role="contentinfo" className={this.props.showLoader_2 == true || this.props.showLoader_3 == true || this.props.showLoader_1 == true || this.props.ordersList?.length <= 6 || this.props.ordersList == [] ?  "respondv responDvMinH" : "respondv"}>
          <div className={"tableRgtBor " + caseResponsive}>
            <Table
              striped
              bordered
              condensed
              hover
              className="tableView nowrapWhright"
              style={{ marginTop: "0px" }}
            >
              <thead>
                <tr>
                  <th>
                    <div
                      className="sortParArr"
                      onClick={() =>
                        this.onSortAsc(
                          this.state.sort,
                          "displayNumber",
                          ascDscToggle,
                          this.state.lastSelectedSearchFilter
                        )
                      }
                    >
                      {this.props.tr["Request ID"]}
                      <div className="sortArr">
                        {this.props.sortOrder == "asc" ? (
                          <span
                            className={this.state.faCustomAsc1}
                            title={this.props.tr["Ascending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        ) : (
                          <span
                            className={this.state.faCustomDesc1}
                            title={this.props.tr["Descending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="colSearDv">
                      <Form.Control
                        className="colSearInp"
                        type="text"
                        value={
                          this.props.displayNumberSearchInput != ""
                            ? this.props.displayNumberSearchInput
                            : ""
                        }
                        placeholder={this.props.tr["Search here"]}
                        onKeyPress={(e) => {
                          if (e.charCode == "13") {
                            if (
                              this.props.displayNumberSearchInput != "" &&
                              this.props.displayNumberSearchInput.trim()
                                .length > 3
                            )
                              this.handleSearch();
                          }
                        }}
                        onChange={(event) => {
                          this.handleChange(event, "displayNumber");
                        }}
                        onClick={() => this.props.multiSelectOpenFun("myReqStatus", false)}
                      />
                      {this.props.displayNumberSearchInput != "" &&
                      this.props.displayNumberSearchInput.trim().length > 3 ? (
                        <a
                          title="search"
                          className="faicn"
                          href={void 0}
                          onClick={(event) => this.handleSearch()}
                        >
                          <i className="fa fa-search" />
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </th>

                  <th>
                    <div
                      className="sortParArr"
                      onClick={() =>
                        this.onSortAsc(
                          this.state.sort,
                          "ciServiceName",
                          ascDscToggle,
                          this.state.lastSelectedSearchFilter
                        )
                      }
                    >
                      {this.props.tr["Service/CI Name"]}
                      <div className="sortArr">
                        {this.props.sortOrder == "asc" ? (
                          <span
                            className={this.state.faCustomAsc2}
                            title={this.props.tr["Ascending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        ) : (
                          <span
                            className={this.state.faCustomDesc2}
                            title={this.props.tr["Descending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="colSearDv">
                      <Form.Control
                        className="colSearInp"
                        type="text"
                        value={
                          this.props.ciServiceNameSearchInput != ""
                            ? this.props.ciServiceNameSearchInput
                            : ""
                        }
                        placeholder={this.props.tr["Search here"]}
                        onKeyPress={(e) => {
                          if (e.charCode == "13") {
                            if (
                              this.props.ciServiceNameSearchInput != "" &&
                              this.props.ciServiceNameSearchInput.trim()
                                .length > 3
                            )
                              this.handleSearch();
                          }
                        }}
                        onChange={(event) => {
                          this.handleChange(event, "ciServiceName");
                        }}
                        onClick={() => this.props.multiSelectOpenFun("myReqStatus", false)}
                      />
                      {this.props.ciServiceNameSearchInput != "" &&
                      this.props.ciServiceNameSearchInput.trim().length > 3 ? (
                        <a
                          title="search"
                          className="faicn"
                          href={void 0}
                          onClick={(event) => this.handleSearch()}
                        >
                          <i className="fa fa-search" />
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </th>

                  <th className="position-re hiddenBe414">
                    <div
                      className="sortParArr margin-r-20"
                      onClick={() =>
                        this.onSortAsc(
                          this.state.sort,
                          "requesterName",
                          ascDscToggle,
                          this.state.lastSelectedSearchFilter
                        )
                      }
                    >
                      {this.props.isSelf == 'watcher'? this.props.tr["Requested For"] : 
                      `${this.props.tr["Requested For"]} ${
                        this.props.isSelf == 'self'
                          ? this.props.tr["Self"]
                          : this.props.tr["Others"]
                      }`
                    }
                      <div className="sortArr">
                        {this.props.sortOrder == "asc" ? (
                          <span
                            className={this.state.faCustomAsc3}
                            title={this.props.tr["Ascending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        ) : (
                          <span
                            className={this.state.faCustomDesc3}
                            title={this.props.tr["Descending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="colSearDv" onClick={() => this.props.multiSelectOpenFun("myReqStatus", false)}>
                      <Form.Control
                        className="colSearInp"
                        type="text"
                        value={
                          this.props.requesterNameSearchInput != ""
                            ? this.props.requesterNameSearchInput
                            : ""
                        }
                        placeholder={this.props.tr["Search here"]}
                        onKeyPress={(e) => {
                          if (e.charCode == "13") {
                            if (
                              this.props.requesterNameSearchInput != "" &&
                              this.props.requesterNameSearchInput.trim()
                                .length > 3
                            )
                              this.handleSearch();
                          }
                        }}
                        onChange={(event) => {
                          this.handleChange(event, "requesterName");
                        }}
                      />
                      {this.props.requesterNameSearchInput != "" &&
                      this.props.requesterNameSearchInput.trim().length > 3 ? (
                        <a
                          title="search"
                          className="faicn"
                          href={void 0}
                          onClick={(event) => this.handleSearch()}
                        >
                          <i className="fa fa-search" />
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </th>

                  <th className="hiddenBe414">
                    <div
                      className="sortParArr"
                      onClick={() =>
                        this.onSortAsc(
                          this.state.sort,
                          "createdOn",
                          ascDscToggle,
                          this.state.lastSelectedSearchFilter
                        )
                      }
                    >
                      {this.props.tr["Submitted Date"]}
                      <div className="sortArr">
                        {this.props.sortOrder == "asc" ? (
                          <span
                            className={this.state.faCustomAsc4}
                            title={this.props.tr["Ascending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        ) : (
                          <span
                            className={this.state.faCustomDesc4}
                            title={this.props.tr["Descending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="colSearDv" onClick={() => this.props.multiSelectOpenFun("myReqStatus", false)}>
                      <DateRangePicker
                        initialSettings={{
                          autoUpdateInput: false,
                          maxDate: moment(),
                          autoApply: true,
                          parentEl:"#skipdv"
                        }}
                        onApply={(e, picker) =>
                          this.handleSearch(e, picker, "createdOn")
                        }
                        onCallback={(start, end) =>
                          this.handleCallback("createdOn", start, end)
                        }
                      >
                        <input
                          type="text"
                          value={mySubmittedNewDate}
                          placeholder={this.props.tr["Search here"]}
                          readOnly
                          className="form-control col-4"
                          onPaste={this.handleKeyPress}
                          onKeyDown={this.handleKeyPress}
                        />
                      </DateRangePicker>
                    </div>
                  </th>

                  <th className="hiddenBe414">
                    <div
                      className="sortParArr"
                      onClick={() =>
                        this.onSortAsc(
                          this.state.sort,
                          "modifiedOn",
                          ascDscToggle,
                          this.state.lastSelectedSearchFilter
                        )
                      }
                    >
                      {this.props.tr["Modified Date"]}
                      <div className="sortArr">
                        {this.props.sortOrder == "asc" ? (
                          <span
                            className={this.state.faCustomAsc5}
                            title={this.props.tr["Ascending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        ) : (
                          <span
                            className={this.state.faCustomDesc5}
                            title={this.props.tr["Descending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="colSearDv" onClick={() => this.props.multiSelectOpenFun("myReqStatus", false)}>
                      <DateRangePicker
                        initialSettings={{
                          autoUpdateInput: false,
                          maxDate: moment(),
                          autoApply: true,
                          parentEl:"#skipdv",
                          opens: 'left'
                        }}
                        onApply={(e, picker) =>
                          this.handleSearch(e, picker, "modifiedOn")
                        }
                        onCallback={(start, end) =>
                          this.handleCallback("modifiedOn", start, end)
                        }
                      >
                        <input
                          type="text"
                          value={myModifiedNewDate}
                          placeholder={this.props.tr["Search here"]}
                          readOnly
                          className="form-control col-4"
                          onPaste={this.handleKeyPress}
                          onKeyDown={this.handleKeyPress}
                        />
                      </DateRangePicker>
                    </div>
                  </th>

                  <th>
                    <div
                      className="sortParArr"
                      onClick={() =>
                        this.onSortAsc(
                          this.state.sort,
                          "itemStatus",
                          ascDscToggle,
                          this.state.lastSelectedSearchFilter
                        )
                      }
                    >
                      {this.props.tr["Status"]}
                      <div className="sortArr">
                        {this.props.sortOrder == "asc" ? (
                          <span
                            className={this.state.faCustomAsc6}
                            title={this.props.tr["Ascending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        ) : (
                          <span
                            className={this.state.faCustomDesc6}
                            title={this.props.tr["Descending"]}
                          >
                            <span className="caret"></span>
                          </span>
                        )}
                      </div>
                    </div>
                      <div
                        onClick={() => {
                          this.setLastSelectedStatusFilter();
                          this.props.multiSelectOpenFun("myReqStatus", true);
                        }}
                        id="taskStatusDv"
                        className="colSearDv multiSelectObjClass"
                        ref={(e) => (this.multiSelectDropdownRef = e)}
                      >
                        {/* <ReactMultiSelectCheckboxes
                          className="multiSelectStatus"
                          placeholderButtonLabel={this.props.tr["Select"]}
                          value={this.props.selectedValue}
                          rightAligned={true}
                          options={multiSelectOptions}
                          onChange={this.handleCheckBoxChange}
                        /> */}
                        <MyRequestMultiSelect
                            options={multiSelectOptions}
                            selectValue={this.props.selectedValue}
                            setSelectedValueFun={
                              this.props.setSelectedValue
                            }
                            showLoader_1={this.props.showLoader_1}
                            showLoader_2={this.props.showLoader_2}
                            showLoader_3={this.props.showLoader_3}
                            keyName={"myReqStatus"}
                            isSelectAllLabel={this.state.isMyReqStatusAllLabel}
                            tr={this.props.tr}
                            OkSubmitFun={this.multiSelectOkButtonFunc}
                            showCheckBoxOptions={this.handleCheckBoxChange}
                            filterTabActive={this.props.filterTabActive}
                            selectAllLabelFun={this.selectAllLabelFun}
                            multiSelectOpenFun={this.props.multiSelectOpenFun}
                            isOpen={this.props.isStatusOpen}
                            lastSelectedStatusFilter={this.state.lastSelectedStatusFilter}
                            refreshClickFun={this.props.refreshClickFun}
                            isRefreshClick={this.props.isRefreshClick}
                          />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.showLoader_2 == true ||
                this.props.showLoader_3 == true ||
                this.props.showLoader_1 == true ? (
                  <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      <ListLoader />
                    </td>
                  </tr>
                ) : this.props.ordersList.length == 0 ? (
                  <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      {this.props.tr["There is no matching data available"]}
                    </td>
                  </tr>
                ) : (
                  this.renderData(this.props.ordersList)
                )}
              </tbody>
            </Table>
          </div>
          <div className="nBotPagina">
            <div className="nShow">
              <div className="margin-r-10">{this.props.tr["Show"]}:</div>
              <div aria-label="Show Count" role="contentinfo">
                <Form.Select
                  aria-label={this.props.tr["Show"]}
                  componentClass="select"
                  value={this.props.limit}
                  onChange={this.onPageSizeChange}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </div>
            <nav aria-label="Pagination" className="display-inline-block">
              <ListPagination
                prevPageText={this.props.tr["Prev"]}
                nextPageText={this.props.tr["Next"]}
                firstPageText={<i className="glyphicon glyphicon-menu-left" />}
                lastPageText={<i className="glyphicon glyphicon-menu-right" />}
                activePage={meta ? meta.currentPage + 1 : 1}
                itemsCountPerPage={this.props.limit}
                totalItemsCount={meta ? meta.recordCount : 1}
                onChange={(e) => this.onPageChange(e)}
                pageRangeDisplayed={panelColSize <= 42 ? 2 : 5}
              />
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

MyRequestListView.contextTypes = {
  scrollArea: PropTypes.object
};
MyRequestListView.propTypes = {
  getOrderStatus: PropTypes.func,
  getOrderType: PropTypes.func,
  getOrderDetails: PropTypes.func,
  loadBreakFixOrderDetails: PropTypes.func,
  loadOrderDetails: PropTypes.func,
  fetchCancellationType: PropTypes.func,
  loadProfile2: PropTypes.func,
  loadImplPlanList: PropTypes.func,
  ordersListFilter2: PropTypes.func,
  loadOrdersFilterResultsForSearchString: PropTypes.func,
  sortingArrowsChanged: PropTypes.func,
  resetSortArrows: PropTypes.bool,
  loadorderfilter2: PropTypes.func,
  loadBreakFixActivityLogDetails: PropTypes.func,
  setCiServiceName: PropTypes.func,
  getItemId: PropTypes.func,
  getQuestionsCategoryWise: PropTypes.func,
  searchclick: PropTypes.bool,
  searcTextGloSearch: PropTypes.string,
  tr: PropTypes.object,
  loadFilterOrderList: PropTypes.func,
  loadOrdersList: PropTypes.func,
  resetMRQVFilters: PropTypes.func,
  resetSortingArrows: PropTypes.func,
  showActiveRow: PropTypes.func,
  buildSearchParamsForOrderFilter: PropTypes.func,
  listFilter: PropTypes.string,
  showLoader: PropTypes.bool,
  showLoader_1: PropTypes.bool,
  showLoader_2: PropTypes.bool,
  showLoader_3: PropTypes.bool,
  ordersListMeta: PropTypes.object,
  rowIndex: PropTypes.number,
  showRightPanelFun: PropTypes.func

};

export function mapStateToProps({ ordersList, showLoader, resetSortArrows, showLoader_1, showLoader_2, showLoader_3 }) {
  let data = JSON.parse(JSON.stringify(ordersList.data?ordersList.data:[]));
  if (data == undefined)
    data = [];
  return {
    ordersList: data, ordersListMeta: ordersList.meta, showLoader: showLoader.loading, resetSortArrows, showLoader_1: showLoader_1.loading, showLoader_2: showLoader_2.loading, showLoader_3: showLoader_3.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadOrdersList,
      loadFilterOrderList,
      loadOrderDetails,
      fetchCancellationType,
      loadImplPlanList,
      loadBreakFixOrderDetails,
      loadBreakFixActivityLogDetails,
      loadApproverList,
      getQuestionsCategoryWise,
      loadChangeTotalApprovalDetails,
      loadChangeTotalApprovalHistoryDetails,
      resetMRQVFilters,
      loadOrdersFilterResultsForSearchString,
      resetSortingArrows,
      sortingArrowsChanged,
      addToSelectedOrderTypeList,
      removeFromSelectedOrderTypeList,
      addToSelectedOrderStatusList,
      removeFromSelectedOrderStatusList,
      loadProfile2,
      getBreakfixQuestionsCategoryWise,
      setUrgencyList,
      loadBreakFixOrderDetailsInteger
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MyRequestListView);