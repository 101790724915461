
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button, Form, Table } from "react-bootstrap";
import {
  loadFullfilmentRelatedWorkDetails,
  clearFullfilmentRelatedWorkDetails,
} from "../../../actions/fulfillment/fullfilmentRelatedWorkAction";
import { loadFullfilmentRelatedDataDetails } from "../../../actions/fulfillment/fullfilmentRelatedDataAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GLOBAL } from "../../Globals";
import axios from "axios";
import Swal from "sweetalert2";
import {IoClose} from "react-icons/io5";
import {ImSpinner6} from "react-icons/im";
import { setWipSearchApply } from "../../../actions/globalSearch/globalSearchAction";
import { HiPlus } from "react-icons/hi";
import { PiListBulletsBold } from 'react-icons/pi';
const MoreArrow = (
  <i title="More" className="fa fa-angle-double-down f-size-15"></i>
);
class FulfillmentRelatedWork extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserData: false,
      messagedisplay: true,
      toggleFormAndList: "list",
      disableRelate: false,
      disableModuleId: [],
      module: "",
    };
    GLOBAL.relatedfilterval = "serviceId";
    GLOBAL.checkmoduleval = "Fullfilment";
    this.toggleFormAndList = this.toggleFormAndList.bind(this);
    this.setWibSearchFalse = this.setWibSearchFalse.bind(this);
  }

  componentWillMount() {
    //let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") :""
    //let BRKItemId = getBreakFixItemId[0];
    //let breakFixActualItemId = getBreakFixItemId[1];
    let itemId = this.props.itemId;
    this.props.loadFullfilmentRelatedDataDetails(itemId);
    GLOBAL.relatedtextdisplay = (
      <div className="f-size-18 margin-b-5">
        {this.props.translator["Related Work Items"]}
      </div>
    );
    this.setState({ messagedisplay: true });
  }
  componentWillUnmount() {
    this.props.fullfilmentRelatedWorkDetails.length = 0;
    this.renderRelatedWorkLog(this.props.fullfilmentRelatedWorkDetails);
  }

  toggleFormAndList() {
    if (this.state.toggleFormAndList == "list")
      this.setState({ toggleFormAndList: "form" });
    else this.setState({ toggleFormAndList: "list" });
  }

  postRelatedData(brkid, modnumber, modulesearchparam) {
    let itemId = this.props.itemId;
    this.state.disableModuleId.push(brkid);
    this.setState({ disableRelate: true });
    axios
      .post(GLOBAL.breakFixRelatedLogPostUrl, {
        itemId: itemId,
        status: "A",
        createdOn: "",
        createdBy: "",
        modifiedOn: "",
        modifiedBy: 0,
        module: "Fullfilment",
        workItemId: brkid,
        workModule: modulesearchparam,
        workItemNumber: modnumber,
        createdByName: "",
        relationType: "p2p",
      })
      .then((response) => {
        //alert("Data successfully submitted")
        this.props.loadFullfilmentRelatedWorkDetails(
          "itemNumber",
          "-1",
          "Fullfilment"
        );
        GLOBAL.relatedtextdisplay = (
          <div className="f-size-18 margin-b-5">
            {this.props.translator["Related Work Items"]}
          </div>
        );
        this.props.loadFullfilmentRelatedDataDetails(itemId);
        this.setState({ messagedisplay: true });
        this.setState({
          toggleFormAndList: "list",
          disableRelate: false,
          disableModuleId: this.state.disableModuleId.filter(
            (element) => element !== brkid
          ),
        });
      });
  }

  deleteRelation(relationId) {
    let itemId = this.props.itemId;
    axios.delete(GLOBAL.relatedLogDeleteUrl + relationId).then((response) => {
      if (!response || response.status != "200") {
        Swal.fire("Deletion failed.");
      } else {
        Swal.fire("deleted relation");
        console.log(response);
        this.props.loadFullfilmentRelatedWorkDetails(
          "itemNumber",
          "-1",
          "Fullfilment"
        );
        GLOBAL.relatedtextdisplay = (
          <div className="f-size-18 margin-b-5">
            {this.props.translator["Related Work Items"]}
          </div>
        );
        this.props.loadFullfilmentRelatedDataDetails(itemId);
        this.setState({ messagedisplay: true });
      }
    });
  }

  renderRelatedWorkLog(relatedLogData) {
    //alert(""+auditLogData)
    console.log("relatedLogData", relatedLogData);
    if (relatedLogData.length == 0) {
      return <div></div>;
    }
    if (relatedLogData.data.length == 0) {
      return (
        <tr>
          <td width="100%">
            <div>{this.props.translator["No Data Available..."]}</div>
          </td>
        </tr>
      );
    } else {
      return relatedLogData.data.map((relatedLogData, index) => {
        let modnumber = "",
          modid = "",
          modRequester = "";
        let modulesearchparam = GLOBAL.checkmoduleval;
        console.log("SEARCHBYREQUESTERIDORCREATEDBY", modRequester);
        if (modulesearchparam == "Breakfix") {
          modnumber = relatedLogData.breakfixNumber;
          modid = relatedLogData.breakfixId;
          modRequester = relatedLogData.impactedUsername;
        }
        if (modulesearchparam == "Fullfilment") {
          modnumber = relatedLogData.itemNumber;
          modid = relatedLogData.itemId;
          modRequester = relatedLogData.requesterName;
        }
        if (modulesearchparam == "Investigation") {
          modnumber = relatedLogData.investigationNumber;
          modid = relatedLogData.investigationId;
          modRequester = relatedLogData.impactedUsername;
        }
        if (modulesearchparam == "ChangeManagement") {
          modnumber = relatedLogData.changeNumber;
          modid = relatedLogData.changeId;
          modRequester = relatedLogData.requesterName;
        }

        if (!relatedLogData.description) {
          relatedLogData.description = "";
        }

        let desc = (
          <span title={relatedLogData.description || "NO NAME"}>
            {relatedLogData.description &&
            relatedLogData.description.length <= 25
              ? relatedLogData.description
              : relatedLogData.description.substring(0, 25) + "..."}
          </span>
        );

        return (
          <div className="margin-t-5 margin-b-10 border-b padding-b-5">
            <Row>
              <Col md={10}>
                {modnumber} - {relatedLogData.createdOn} -{" "}
                <span className="fw-300 cTxt" onClick={this.showUserDetails}>
                  {modRequester}
                </span>
                {/* <ReadMore lines={1} onShowMore={this.props.onChange} text={MoreArrow}>{relatedLogData.description}</ReadMore> */}
                {desc}
              </Col>
              <Col md={2} className="text-c">
                <a
                 href={ void 0}
                  onClick={
                    ()=>{
                      if (!(this.state.disableRelate && this.state.disableModuleId.filter((element) => element === modid).length === 1))
                        this.postRelatedData(
                          modid,
                          modnumber,
                          modulesearchparam
                        )
                    }}
                  title={this.props.translator["Relate"]}
                >
                  <i className="fa fa-chevron-circle-right f-size-16 theme1"></i>
                </a>
              </Col>
            </Row>
          </div>
        );
      });
    }
  }

  setWibSearchFalse()
  {
     this.props.setWipSearchApply(false);
  }
  renderAttList(attributeData) {
    //console.log(attributeData)
    if (!(attributeData && Array.isArray(attributeData))) {
      return null;
    }
    return attributeData.map((objjectData) => {
      if (objjectData.itemId != null && objjectData.itemId != "") {
        if (
          this.props &&
          this.props.fulfillmentDetails &&
          this.props.fulfillmentDetails.itemStatus &&
          (this.props.fulfillmentDetails.itemStatus == "75" ||
            this.props.fulfillmentDetails.itemStatus == "80" ||
            this.props.fulfillmentDetails.itemStatus == "85" ||
            this.props.fulfillmentDetails.itemStatus == "95")
        ) {
          if (objjectData.workModule === "ChangeManagement") {
            return (
              <tr>
                <td>{this.props.isChangedRolesEnabled?
                  <Link to={"/editChange/" + objjectData.workItemId} onClick={()=>this.setWibSearchFalse()}>
                    {objjectData.workItemNumber}
                  </Link>:objjectData.workItemNumber}
                </td>
                <td>{objjectData.createdOn}</td>
                <td>{objjectData.createdByName}</td>
                <td></td>
              </tr>
            );
          } else if (objjectData.workModule === "Breakfix") {
            return (
              <tr>
                <td>{this.props.isIncidentRolesEnabled? 
                  <Link
                    to={
                      "/editBreakFix/" + objjectData.workItemId
                    }
                    onClick={()=>this.setWibSearchFalse()}
                  >
                    {objjectData.workItemNumber}
                  </Link>:objjectData.workItemNumber}
                </td>
                <td>{objjectData.createdOn}</td>
                <td>{objjectData.createdByName}</td>
                <td></td>
              </tr>
            );
          } else if (objjectData.workModule === "Investigation") {
            return (
              <tr>
                <td>{this.props.isProblemRolesEnabled?
                  <Link to={"/investigationEdit/" + objjectData.workItemId} onClick={()=>this.setWibSearchFalse()}>
                    {objjectData.workItemNumber}
                  </Link>:objjectData.workItemNumber}
                </td>
                <td>{objjectData.createdOn}</td>
                <td>{objjectData.createdByName}</td>
                <td></td>
              </tr>
            );
          } else if (objjectData.workModule === "Fullfilment") {
            return (
              <tr>
                <td>{this.props.isFulfillmentRolesEnabled?
                  <Link to={"/fulFillment/" + objjectData.workItemId} onClick={()=>this.setWibSearchFalse()}>
                    {objjectData.workItemNumber}
                  </Link>:objjectData.workItemNumber}
                </td>
                <td>{objjectData.createdOn}</td>
                <td>{objjectData.createdByName}</td>
                {this.props.fieldStatus.relatedWorkItems == true && !this.props.disableFieldsForGuestRole ? (
                  <td className="text-c f-size-13">
                    {objjectData.relationType == "p2p" ? (
                      <i
                        onClick={this.deleteRelation.bind(
                          this,
                          objjectData.relationId
                        )}
                        className="fa fa-trash-o cursorPoint"
                      ></i>
                    ) : (
                      ""
                    )}
                  </td>
                ) : null}
              </tr>
            );
          }
          // return (
          //     <tr>
          //         <td>{objjectData.workItemNumber}</td>
          //         <td>{objjectData.createdOn}</td>
          //         <td>{objjectData.createdByName}</td>
          //         <td></td>
          //     </tr>
          // );
        } else {
          if (objjectData.workModule === "ChangeManagement") {
            return (
              <tr>
                <td>{this.props.isChangedRolesEnabled?
                  <Link to={"/editChange/" + objjectData.workItemId} onClick={()=>this.setWibSearchFalse()}>
                    {objjectData.workItemNumber}
                  </Link>:objjectData.workItemNumber}
                </td>
                <td>{objjectData.createdOn}</td>
                <td>{objjectData.createdByName}</td>
                {this.props.fieldStatus.relatedWorkItems == true && !this.props.disableFieldsForGuestRole ? (
                  <td className="text-c f-size-13">
                    {objjectData.relationType == "p2p" ? (
                      <i
                        onClick={this.deleteRelation.bind(
                          this,
                          objjectData.relationId
                        )}
                        className="fa fa-trash-o cursorPoint"
                      ></i>
                    ) : (
                      ""
                    )}
                  </td>
                ) : null}
              </tr>
            );
          } else if (objjectData.workModule === "Breakfix") {
            return (
              <tr>
                <td>{this.props.isIncidentRolesEnabled?
                  <Link
                    to={
                      "/editBreakFix/" + objjectData.workItemId
                    }
                    onClick={()=>this.setWibSearchFalse()}
                  >
                    {objjectData.workItemNumber}
                  </Link>:objjectData.workItemNumber}
                </td>
                <td>{objjectData.createdOn}</td>
                <td>{objjectData.createdByName}</td>
                {this.props.fieldStatus.relatedWorkItems == true && !this.props.disableFieldsForGuestRole ? (
                  <td className="text-c f-size-13">
                    {objjectData.relationType == "p2p" ? (
                      <i
                        onClick={this.deleteRelation.bind(
                          this,
                          objjectData.relationId
                        )}
                        className="fa fa-trash-o cursorPoint"
                      ></i>
                    ) : (
                      ""
                    )}
                  </td>
                ) : null}
              </tr>
            );
          } else if (objjectData.workModule === "Investigation") {
            return (
              <tr>
                <td>{this.props.isProblemRolesEnabled?
                  <Link to={"/investigationEdit/" + objjectData.workItemId} onClick={()=>this.setWibSearchFalse()}>
                    {objjectData.workItemNumber}
                  </Link>:objjectData.workItemNumber}
                </td>
                <td>{objjectData.createdOn}</td>
                <td>{objjectData.createdByName}</td>
                {this.props.fieldStatus.relatedWorkItems == true && !this.props.disableFieldsForGuestRole ? (
                  <td className="text-c f-size-13">
                    {objjectData.relationType == "p2p" ? (
                      <i
                        onClick={this.deleteRelation.bind(
                          this,
                          objjectData.relationId
                        )}
                        className="fa fa-trash-o cursorPoint"
                      ></i>
                    ) : (
                      ""
                    )}
                  </td>
                ) : null}
              </tr>
            );
          } else if (objjectData.workModule === "Fullfilment") {
            return (
              <tr>
                <td>{this.props.isFulfillmentRolesEnabled?
                  <Link to={"/fulFillment/" + objjectData.workItemId} onClick={()=>this.setWibSearchFalse()}>
                    {objjectData.workItemNumber}
                  </Link>:objjectData.workItemNumber}
                </td>
                <td>{objjectData.createdOn}</td>
                <td>{objjectData.createdByName}</td>
                {this.props.fieldStatus.relatedWorkItems == true && !this.props.disableFieldsForGuestRole ? (
                  <td className="text-c f-size-13">
                    {objjectData.relationType == "p2p" ? (
                      <i
                        onClick={this.deleteRelation.bind(
                          this,
                          objjectData.relationId
                        )}
                        className="fa fa-trash-o cursorPoint"
                      ></i>
                    ) : (
                      ""
                    )}
                  </td>
                ) : null}
              </tr>
            );
          }
          // return (
          //     <tr>
          //         <td>{objjectData.workItemNumber}</td>
          //         <td>{objjectData.createdOn}</td>
          //         <td>{objjectData.createdByName}</td>
          //         <td className="text-c f-size-13">{objjectData.relationType == 'p2p' ? <i onClick={this.deleteRelation.bind(this, objjectData.relationId)} className="fa fa-trash-o cursorPoint"></i> : ''}</td>
          //     </tr>
          // );
        }
      } else return <tr></tr>;
    });
  }

  renderRelatedDataLog(relatedDataLog) {
    console.log("relatedDataLog");
    console.log(relatedDataLog);
    if (relatedDataLog.length == 0) {
      return <div>{this.props.translator["Data not available."]}</div>;
    } else {
      return (
        <Table
          responsive
          striped
          bordered
          condensed
          hover
          className="f-size-13"
        >
          <thead>
            <tr className="f-size-12">
              <th>{this.props.translator["Work Item Number"]}</th>
              <th>{this.props.translator["Created On"]}</th>
              <th>{this.props.translator["Related By"]}</th>
              {this.props.fieldStatus.relatedWorkItems == true && !this.props.disableFieldsForGuestRole ? (
                <th className="text-c">{this.props.translator["Action"]}</th>
              ) : null}
            </tr>
          </thead>
          <tbody>{this.renderAttList(relatedDataLog)}</tbody>
        </Table>
      );
    }
  }

  //  renderRelatedDataLog(relatedDataLog){
  //      //alert(""+auditLogData)
  //      if(!relatedDataLog){
  //          return (
  //                  <tr>
  //                      <td width="100%">
  //                          <div>{this.props.translator['Data not available.']}</div>
  //                      </td>
  //                  </tr>
  //              );
  //          }
  //  else{

  //   return relatedDataLog.map((relatedData,index)=>{

  //   return(
  //      <Media>
  //                <Media.Body>
  //                  <Row>
  //                      <Col md={10} className="padding-r-0"><Media.Heading className="blue margin-0">{relatedData.workItemNumber} - {relatedData.createdOn}  - <span className="colorStOrange cursorPoint" onClick={this.showUserDetails}>Related By {relatedData.createdByName}</span></Media.Heading><div className="black fw-300 cTxt"><ReadMore lines={1} onShowMore={this.props.onChange} text={MoreArrow}></ReadMore></div></Col>

  //                  </Row>
  //                </Media.Body>

  //              </Media>
  //         );
  //       });

  // 	  }
  // 	}

  loaddata() {
    //let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") :""
    //let BRKItemId = getBreakFixItemId[0];
    //let breakFixActualItemId = getBreakFixItemId[1];
    let itemId = this.props.itemId;
    let modulesearch = GLOBAL.checkmoduleval;
    if (modulesearch == "Breakfix") {
      if (GLOBAL.relatedfilterval == "breakfixNumber")
        this.props.loadFullfilmentRelatedWorkDetails(
          GLOBAL.relatedfilterval,
          GLOBAL.relatedfiltertext,
          "Breakfix"
        );
      if (GLOBAL.relatedfilterval == "impactedUserid")
        this.props.loadFullfilmentRelatedWorkDetails(
          GLOBAL.relatedfilterval,
          this.props.fulfillmentDetails.requesterId,
          "Breakfix"
        );
      if (GLOBAL.relatedfilterval == "serviceId")
        this.props.loadFullfilmentRelatedWorkDetails(
          GLOBAL.relatedfilterval,
          this.props.fulfillmentDetails.offeringIdSx,
          "Breakfix"
        );
    }
    if (modulesearch == "Fullfilment") {
      if (GLOBAL.relatedfilterval == "breakfixNumber")
        this.props.loadFullfilmentRelatedWorkDetails(
          "itemNumber",
          GLOBAL.relatedfiltertext,
          "Fullfilment",
          itemId,
          "Y"
        );
      if (GLOBAL.relatedfilterval == "impactedUserid")
        this.props.loadFullfilmentRelatedWorkDetails(
          "requesterId",
          this.props.fulfillmentDetails.requesterId,
          "Fullfilment",
          itemId,
          "Y"
        );
      if (GLOBAL.relatedfilterval == "serviceId")
        this.props.loadFullfilmentRelatedWorkDetails(
          "offeringIdSx",
          this.props.fulfillmentDetails.offeringIdSx,
          "Fullfilment",
          itemId,
          "Y"
        );
    }
    if (modulesearch == "Investigation") {
      if (GLOBAL.relatedfilterval == "breakfixNumber")
        this.props.loadFullfilmentRelatedWorkDetails(
          "investigationNumber",
          GLOBAL.relatedfiltertext,
          "Investigation"
        );
      if (GLOBAL.relatedfilterval == "impactedUserid")
        this.props.loadFullfilmentRelatedWorkDetails(
          GLOBAL.relatedfilterval,
          this.props.fulfillmentDetails.requesterId,
          "Investigation"
        );
      if (GLOBAL.relatedfilterval == "serviceId")
        this.props.loadFullfilmentRelatedWorkDetails(
          GLOBAL.relatedfilterval,
          this.props.fulfillmentDetails.offeringIdSx,
          "Investigation"
        );
    }

    if (modulesearch == "ChangeManagement") {
      // console.log("123changes--->" ,modulesearch, GLOBAL.relatedfilterval, GLOBAL.relatedfiltertext)
      if (GLOBAL.relatedfilterval == "breakfixNumber") {
        this.props.loadFullfilmentRelatedWorkDetails(
          "changeNumber",
          GLOBAL.relatedfiltertext,
          "ChangeManagement"
        );
      }
      if (GLOBAL.relatedfilterval == "impactedUserid")
        this.props.loadFullfilmentRelatedWorkDetails(
          "requesterId",
          this.props.fulfillmentDetails.requesterId,
          "ChangeManagement"
        );
      if (GLOBAL.relatedfilterval == "serviceId")
        this.props.loadFullfilmentRelatedWorkDetails(
          "changeNumber",
          GLOBAL.relatedfiltertext,
          "ChangeManagement"
        );
    }

    GLOBAL.relatedtextdisplay = <div className="f-size-18 margin-b-5"></div>;
    //this.props.loadFullfilmentRelatedDataDetails("0");
    this.setState({ messagedisplay: false });
  }
  loadrelateddata() {
    //let getBreakFixItemId = (this.props.breakFixItemId) ? (this.props.breakFixItemId).split(":") :""
    //let BRKItemId = getBreakFixItemId[0];
    //let breakFixActualItemId = getBreakFixItemId[1];
    let itemId = this.props.itemId;

    this.props.loadFullfilmentRelatedWorkDetails(
      "itemNumber",
      "-1",
      "Fullfilment"
    );
    GLOBAL.relatedtextdisplay = (
      <div className="f-size-18 margin-b-5">
        {this.props.translator["Related Work Items"]}
      </div>
    );
    this.props.loadFullfilmentRelatedDataDetails(itemId);
    this.setState({ messagedisplay: true });
  }

  checkfilter(i) {
    GLOBAL.relatedfilterval = i.target.value;
  }
  filtertext(i) {
    //alert(i.target.value)
    GLOBAL.relatedfiltertext = i.target.value;
  }

  checkmodule(i) {
    GLOBAL.checkmoduleval = i.target.value;
    console.log("module", GLOBAL.checkmoduleval, i.target.value);
    this.setState({
      module: i.target.value,
    });
    this.props.clearFullfilmentRelatedWorkDetails();
  }

  // render(){
  //  return(
  //      <div>
  //      <div className="f-size-18 margin-b-5">{this.props.translator['Relate Work Item']}</div>
  //      <Row>
  //      <Col md={12}>
  //      <Form.Group className="margin-b-5">
  //      <Form.Label>{this.props.translator['Type']}</Form.Label>
  //      <Form.Control componentClass="select" onChange={this.checkmodule.bind(this)}>
  //      <option value="Select">Select</option>
  //      <option value="Fullfilment">Fullfilment</option>
  //      <option value="Breakfix">Breakfix</option>
  //      <option value="Investigation">Investigation</option>
  //      <option value="ChangeManagement">Change</option>
  //      </Form.Control>
  //      </Form.Group>
  //      </Col>
  //      </Row>
  //      <div className="bgGray padding-10 tbox">
  //          <Row>
  //              <Col md={6} className="padding-r-0">
  //              <Form.Label>{this.props.translator['Search By']}</Form.Label>
  //              <Form.Control  componentClass="select" onChange={this.checkfilter.bind(this)} >
  //              <option value="Select">Select</option>
  //              {/*<option value="Keyword">Keyword</option>*/}
  //              <option value="breakfixNumber">Item ID</option>
  //              <option value="impactedUserid">Same Requestor</option>
  //              <option value="serviceId">Same Service</option>
  //              </Form.Control></Col>
  //              <Col md={6}><Form.Label>&nbsp;</Form.Label><Form.Control type="text" onChange={this.filtertext.bind(this)} placeholder={this.props.translator['search']} /></Col>
  //          </Row>
  //          {/*<div className="btnNew padding-5">&nbsp;<a onClick={this.loadrelateddata.bind(this)} title="Back to list" className="srch"><i className="fa fa-search"></i></a></div>
  //          <div className="btnNew padding-5">&nbsp;<a onClick={this.loaddata.bind(this)} title="Search" className="srch"><i className="fa fa-search"></i></a></div>*/}
  //      <div className="btnNew padding-5">{this.state.messagedisplay==false?<a onClick={this.loadrelateddata.bind(this)} className="backList"><i className="fa fa-angle-double-left"></i>{this.props.translator[' Back to List']} </a>:<a></a>}&nbsp;
  //          <a onClick={this.loaddata.bind(this)} title={this.props.translator['Search']} className="srch"><i className="fa fa-search"></i></a></div>
  //      </div>

  //          {this.renderRelatedWorkLog(this.props.fullfilmentRelatedWorkDetails)}
  //          {GLOBAL.relatedtextdisplay}
  //          {this.renderRelatedDataLog(this.props.fullfilmentRelatedDataDetails)}

  //      </div>
  //  );
  // }

  render() {
    console.log("this.props.fieldStatus.relatedWorkItems", this.state.module);
    let formVariable = (
      <div>
        <Row>
          <Col md={12}>
            <Form.Group className="margin-b-5">
              <Form.Label>{this.props.translator["Type"]}</Form.Label>
              <Form.Select
                onChange={this.checkmodule.bind(this)}
              >
                <option value="Fullfilment">Fulfillment</option>
                <option value="Breakfix">Incident</option>
                <option value="Investigation">Problem</option>
                <option value="ChangeManagement">Change</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <div className="">
          <Row>
            <Col md={6} className="padding-r-0">
              <Form.Label>{this.props.translator["Search By"]}</Form.Label>
              <Form.Select
                onChange={this.checkfilter.bind(this)}
              >
                {this.state.module == "ChangeManagement" ? null : (
                  <option value="serviceId">Same Service</option>
                )}
                {/*<option value="Keyword">Keyword</option>*/}
                <option value="breakfixNumber">Item ID</option>
                <option value="impactedUserid">Same Requestor</option>
              </Form.Select>
            </Col>
            <Col md={6}>
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control
                type="text"
                onChange={this.filtertext.bind(this)}
                placeholder={this.props.translator["search"]}
                onKeyPress={(e) => {e.key === "Enter" && e.preventDefault();}}
              />
            </Col>
          </Row>
          {/*<div className="btnNew padding-5">&nbsp;<a onClick={this.loadrelateddata.bind(this)} title="Back to list" className="srch"><i className="fa fa-search"></i></a></div>
                <div className="btnNew padding-5">&nbsp;<a onClick={this.loaddata.bind(this)} title="Search" className="srch"><i className="fa fa-search"></i></a></div>*/}
          <div className="btnNew margin-t-10">
            {/* {this.state.messagedisplay == false ? <a onClick={this.loadrelateddata.bind(this)} className="backList"><i className="fa fa-angle-double-left"></i>{this.props.translator[' Back to List']}</a> : <a></a>}&nbsp; */}
            {/* <a onClick={this.loaddata.bind(this)} title={this.props.translator['Search']} className="srch"><i className="fa fa-search"></i></a> */}
            <Button
              className="rgSidrkBtn smallBtn"
              bsSize="small"
              bsStyle="primary"
              title={this.props.translator["Search"]}
              disabled={this.props.showLoader.loading}
              onClick={this.loaddata.bind(this)}
            >
              {this.props.translator["Search"]}{" "}
              {this.props.showLoader.loading ? (
                <ImSpinner6 className="icn-spinner"/>
              ) : null}
            </Button>
          </div>
        </div>

        {!this.props.showLoader.loading ? (
          this.renderRelatedWorkLog(this.props.fullfilmentRelatedWorkDetails)
        ) : (
          <div>{""}</div>
        )}
      </div>
    );
    return (
      <div className="margin-b-10">
        <div className="rPageHeadActBtn" style={{ marginTop: "-3px" }}>
          <ul>
          {<li>
          <Button title={ this.state.toggleFormAndList == "list" ? this.props.translator["Related Work Items"] : this.props.translator["Relate Work Item"] }
                  onClick={this.toggleFormAndList} bsStyle="" bsClass="" bsPrefix=" " className="myBt">
            {this.props.fieldStatus.relatedWorkItems == true && !this.props.disableFieldsForGuestRole? 
                  this.state.toggleFormAndList == "list"
                    ? <HiPlus />
                    : <PiListBulletsBold />
            : null}
              </Button>
	         </li>}
            <li className="d-md-none">
            <button
                type='button'
                title="Minimize the right panel"
                bsClass=""
                bsStyle=""
                className="closerightPanelBtn"
                onClick={() => {
                this.props.rightEditPanel(false);
                this.props.changeRightView("allClose");
                }}
            >
                <IoClose/>
            </button>
            </li>
          </ul>
        </div>
        <div className="rPageHeading">
          <div className="offNam margin-t-5 margin-b-5">
            {this.state.toggleFormAndList == "list"
              ? this.props.translator["Related Work Items"]
              : this.props.translator["Relate Work Item"]}
          </div>
        </div>
        <div className="rBoxGap">
          {this.state.toggleFormAndList == "list"
            ? this.renderRelatedDataLog(
                this.props.fullfilmentRelatedDataDetails
              )
            : formVariable}
        </div>
      </div>
    );
  }
}

export function mapStateToProps({
  fullfilmentRelatedWorkDetails,
  fullfilmentRelatedDataDetails,
  rightMenuShowLoader,
}) {
  //  alert("breakFixEditDetails:"+breakFixAuditLogDetails);
  //console.log("breakFixEditDetails:",rightMenuShowLoader);
  return {
    fullfilmentRelatedWorkDetails,
    fullfilmentRelatedDataDetails,
    showLoader: rightMenuShowLoader,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadFullfilmentRelatedWorkDetails,
      loadFullfilmentRelatedDataDetails,
      clearFullfilmentRelatedWorkDetails,
      setWipSearchApply
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FulfillmentRelatedWork);
