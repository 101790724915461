
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
// import {Table} from 'react-bootstrap';
import {Row, Col, Form, Button, Table, Alert} from 'react-bootstrap';

import {formatMinutesIntoDaysHoursMinutes} from '_Actions/common/functionActions.js';
import ListLoader from "_Commons/loaders/ListLoader";
import {connect} from 'react-redux';


class SlaDetails extends React.Component{

	
	renderslaAndDelOptionsDetails(slaDetails) {

		// console.log('slaDetailsslaDetails------------------',slaDetails)
		let Arr = [];
		if ( this.props.slaDetailsShowLoader == true) {
			return <ListLoader />
		  } 
		else{
			if (slaDetails && slaDetails.length === 0) {
			let msg = (<tr>
				<td width="100%">{this.props.translatoroffering['Data not available']}</td>
			</tr>);
			Arr.push(msg);
			return Arr;
			}
			else {
				// console.log('Offering Details', this.props.offering)
				// return slaDetails.map((value) => {
				// 	if (this.props.offering.workItemType === "serviceRequest") {}


				// });              
					
			
		slaDetails.map((avlObj, index) => {
// console.log("value.goalTime",avlObj.goalTime);

			let availabilityData;
			if (avlObj == "empty") {
				availabilityData =  (null);
				/* as availability data is not configured yet, commenting this code.
				null; */
			} else {
				// let h = Math.floor(avlObj.goalTime / 60);
				// if (h < 10 || h == 0)
				// 	h = "0" + h;
				// let m = parseInt(avlObj.goalTime % 60);

				// if (m < 10 || m == 0)
				// 	m = "0" + m;
				// let time = h + ":" + m;

				let finalValue = formatMinutesIntoDaysHoursMinutes(avlObj.goalTime);
				finalValue = avlObj.goalTime+" %";

				availabilityData =
					(<tr key = {avlObj.slaId}>
						<td width="20%">Availability</td>
						<td width="80%">
							<div>{finalValue}</div>
						</td>
					</tr>);

			}
			Arr.push(availabilityData);
		});
		slaDetails.map((delOpt, index) => {
			let delOptData;
			if (delOpt == "empty") {
				delOptData = (<tr key = 'Delivery Option'>
					<td width="20%">Delivery Option</td>
					<td width="80%">
						<div>Data not available</div>
					</td>
				</tr>);
			} else {
				// let h = Math.floor(delOpt.goalTime / 60);
				// if (h < 10 || h == 0)
				// 	h = "0" + h;
				// let m = parseInt(delOpt.goalTime % 60);

				// if (m < 10 || m == 0)
				// 	m = "0" + m;
				// let time = h + ":" + m;
				// delOptData =
				// 	(<tr key = {delOpt.slaId}>
				// 		<td width="20%">Delivery Option</td>
				// 		<td width="80%">
				// 			<div>{time + " hrs"}</div>
				// 		</td>
					// </tr>);
				let finalValue = formatMinutesIntoDaysHoursMinutes(delOpt.goalTime);
				// console.log(days+", "+hrs+" Hrs, "+minutesStr+" , "+seconds+" Seconds");
				// console.log('finalStrOutput------------',finalStrOutput)

				delOptData =
					(<tr key = {delOpt.slaId}>
						<td width="20%">Delivery Option\</td>
						<td width="80%">
							<div>{finalValue}</div>
						</td>
					</tr>);
			}
			Arr.push(delOptData);
		});
		slaDetails.map((slaOpt, index) => {
			let suprtSlaData;
			if (slaOpt == "empty") {
				suprtSlaData = (null);
			} else {
				// let h = Math.floor(slaOpt.goalTime / 60);
				// if (h < 10 || h == 0)
				// 	h = "0" + h;
				// let m = parseInt(slaOpt.goalTime % 60);

				// if (m < 10 || m == 0)
				// 	m = "0" + m;
				// let time = h + ":" + m;

				let finalValue = formatMinutesIntoDaysHoursMinutes(slaOpt.goalTime);
				
				suprtSlaData =
					(<tr key = {slaOpt.slaId}>
						<td width="20%">Support SLA</td>
						<td width="80%">
							<div>{finalValue}</div>
						</td>
					</tr>);
			}
			Arr.push(suprtSlaData);
			
		});
			return Arr;
	}
}
	}


	render(){
		return(
		<div className="slaDetTab" id="viewMDetails">
			{/* <div className="rPageHeading">{this.props.translatoroffering['SLA Details']}</div> */}
			<Table bordered condensed hover responsive className="tableView">
				<tbody>
				{this.renderslaAndDelOptionsDetails(this.props.slaDetails)}
				</tbody>
			</Table>
		</div>
		);
	}
}

function mapStateToProps({slaDetailsShowLoader}) {
	return {slaDetailsShowLoader: slaDetailsShowLoader.loading};
  }

export default connect(mapStateToProps)(SlaDetails);

