
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React ,{ useState ,useEffect} from 'react'
// import { getAuditLogsNEData } from "../../../../actions/spcmActions";
import { Popover, Card, Button, OverlayTrigger } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { ImInfo } from "react-icons/im";
import { useSelector ,useDispatch} from 'react-redux';
import XsmUserDetails from '_Commons/WorkItemBoard/xsmEditRequestedBy.js';
import Spinner from "react-spinkit";
import moment from 'moment';
import momenttz from "moment-timezone";
import { GLOBAL } from '_Globals';
import ListLoader from '_Commons/loaders/ListLoader';
import Cookies from "universal-cookie";
import datetimeConvertor from "../../../../../helpers/ISO8601converter.js";
import { useParams } from 'react-router';
import { getAiosemDataForAudit, getGroupApprovalHistory } from '../../../../../actions/foundation/groupAction.js';
import CloseBtnRightPanel from "../../../../common/closeBtnRightPanel";
const cookies = new Cookies();
// import  _ from 'underscore'
let timezone = cookies.get("gph");
if (timezone) timezone = timezone.replace("s:", "");
if (timezone) timezone = timezone.substring(0, timezone.lastIndexOf("."));
timezone = timezone.split("~");
timezone = timezone[3];

let dateformat = cookies.get("gph");
if (dateformat) dateformat = dateformat.replace("s:", "");
if (dateformat)
  dateformat = dateformat.substring(0, dateformat.lastIndexOf("."));

dateformat = dateformat.split("~");
dateformat = dateformat[2];
let jwtdf = dateformat.slice(0, 10).toUpperCase();

let cookiesArray = cookies.get('gph');
if (cookiesArray) cookiesArray = cookiesArray.replace('s:', '');
if (cookiesArray) cookiesArray = cookiesArray.substring(0, cookiesArray.lastIndexOf('.'));
cookiesArray = cookiesArray.split("~");
let langDir = cookiesArray[66];

function GroupsAuditLogs(props) {
  const [key, setKey] = useState(1);
  const [combinedAuditData, setCombinedAuditData] = useState([]);
  const [sortedCombinedData, setSortedCombinedData] = useState([]);
  const auditLogsData = useSelector((state) => state.getGroupsActivityDetails);
  const aiosemAuditData = useSelector((state) => state.fetchAiosemActivityForGroup);
  const windowSize = useSelector((state) => state.windowSize.width);
  const translator = useSelector((state) => state.spcmReducer.tr);
  const showLoader = useSelector((state) => state.commonLoader.isLoading);
  const dispatch = useDispatch();
  const params = useParams();

  let groupId = params.id;
  let module = "GROUP";
  let moduleData = "AIOSEM";

  useEffect(() => {
    dispatch(getGroupApprovalHistory(groupId, module));
    dispatch(getAiosemDataForAudit(groupId, moduleData));
  }, []);

  useEffect(() => {
    if (aiosemAuditData.length > 0 || auditLogsData.length > 0) {
      setCombinedAuditData ([...auditLogsData,...aiosemAuditData]);
    } else {
      setCombinedAuditData ([]);
    }
  }, [aiosemAuditData, auditLogsData])
  
  useEffect(() => {
    if (combinedAuditData.length > 0) {
      let newCombinedData = combinedAuditData.map((newData) => {
        return{...newData, newDate: moment(newData?.created_on || newData?.createdOn, jwtdf + " HH:mm:ss").valueOf()}
      })
      newCombinedData.sort((a, b) => {
        return b['newDate'] - a['newDate'];
      })
      setSortedCombinedData(newCombinedData);
    } else {
      setSortedCombinedData([]);
    }

  },[combinedAuditData])  

  const renderCombinedData = (collectedData) => {
    return (
      <div id="brkAudLogcomm" className="actCommts">
        {collectedData.map((comment, childKey) => {
          // let actDateTimeFormat = jwtdf + " HH:mm:ss";
          let actDateTimeFormat = jwtdf + " hh:mm A";
            let myDateObj,
              endDate,
              endDateStr,
              timeago,
              startTime,
              duration,
              hours,
              isoDate;
            isoDate = datetimeConvertor(comment?.created_on || comment?.createdOn, jwtdf + " HH:mm:ss");
            myDateObj = moment(isoDate).format(actDateTimeFormat);
            endDate = moment(myDateObj, actDateTimeFormat);
            endDate = moment(myDateObj, actDateTimeFormat);
            endDateStr = endDate._i;
            startTime = moment(
              new Date().toLocaleString("en-US", { timeZone: timezone })
            );
            timeago = moment(endDateStr, actDateTimeFormat).from(startTime);
            duration = moment.duration(startTime.diff(endDate));
            hours = duration.asHours();

          const tooltip = (
            <Popover className="userInfoPopup">
              <XsmUserDetails
                userId={parseInt(comment.userid || comment.createdBy, 10)}
                translator={translator}
                isHeadingVisible={false}
                isPopup={true}
              />
            </Popover>
          );
          return (
            <Card className="actlogCrd" bsPrefix=" " key={childKey}>
              <div className="circleTxt">
                <div className="subhed">
                  { 
                    <OverlayTrigger
                      trigger={comment.created_by == "System" ? "" : "click"}
                      rootClose
                      placement={windowSize < 768 ? "auto-start" : (langDir === "rtl" ? "right-start" : "left-start")
}
                      overlay={tooltip}
                    >
                      <span className="circleNa" style={{cursor: comment.created_by == "System" ? "text" : "pointer"}} title={comment.created_by || comment.createdByName}>
                        {comment.created_by || comment.createdByName} {comment.created_by == "System" ? "" : <ImInfo />}
                      </span>
                    </OverlayTrigger>
                  }
                  <span
                    className="margin-l-5 timeao"
                    title={hours >= 24 ? "" : endDateStr}
                  >
                    {hours >= 24 ? endDateStr : timeago}
                  </span>
                </div>
                      
                      <div className="desFld">
                          {comment?.audit_field == "Created" || comment?.auditField == "Created" ?<div className="fw-300 cTxt">Created</div> :
                  <div title="Field Name" className="fenme">
                    {comment?.audit_field || comment?.auditField}
                  </div>}
                  
                  {comment?.audit_field == "Created" || comment?.auditField == "Created"?"":<>
                  <div title="New Value" className="nwnme commn">
                    {comment?.to || comment?.valueTo}
                  </div>
                  <div title="Old Value" className="oldnme commn">
                    {comment?.from || comment?.valueFrom}
                  </div></>}
                </div>
              </div>
              
            </Card>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <CloseBtnRightPanel showRightSide={props.showRightSide} />
      <div className="rPageHeading">
        <div className="offNam margin-t-5 margin-b-5">
          {translator["Activity Details"]}
        </div>
      </div>
      <div className="rBoxGap">
        <div className="rBoxTabs">
          <Tabs
            activeKey={key}
            onSelect={(k) => setKey(k)}
            id="tabId"
          >
            <Tab eventKey={1} title={translator["Audit Log"]}>
                          <div className="actCommts">
                              
                                  {sortedCombinedData?.length > 0
                                  ?
                                  renderCombinedData(sortedCombinedData)
                              : "No Data Found"}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default GroupsAuditLogs;
