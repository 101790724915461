
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import  {GLOBAL } from "_Globals";
import EditDashboardHeader from "./EditDashboardHeader";
import { useDispatch, useSelector } from "react-redux";

import Cookies from "universal-cookie";
import EditDashboardForm from "./EditDashboardForm";
import Breadcrumbs from "../../common/header/breadcrumbs";
import { editDashboardData } from "../../../actions/dashboardConfigration/dashboardConfigAction";
import { useLocation, useParams } from "react-router";
import ListLoader from "../../common/loaders/ListLoader";
import "_Css/form/_form.scss";

const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
homepagelocation = homepagelocation[0];
if (homepagelocation != "/home") homepagelocation = "/" + homepagelocation;

const EditDashboardIndex = (props) => {
  const [changeActionView, setChangeActionView] = useState("assetgraph");
  const [valueError, setValueError] = useState("");

  const translator = useSelector((state) => state.spcmReducer.tr);
  const isLoading = useSelector((state) => state.showLoader.loading);
  const formValues = useSelector((state) => state.getDashboardReducerData.initialEditValues);
  const location = useLocation().state;
  const dispatch = useDispatch();
  const id = useParams().id;

  useEffect(() => {
    dispatch(editDashboardData(id));
  }, [])

  console.log('location', location);

  return (
    <div>
      <div>
        <div className="container-fluid padding-t-10 margin-b-15">
          <Breadcrumbs
            activePageName={"Edit"}
            parentPageurl={GLOBAL.reportsConfiguration}
            parentPageName={translator["Dashboard Configration"]}
          />
        </div>
        <div className="container-fluid" bsClass="">
          <Form>
            <EditDashboardHeader
              changeActionView={changeActionView}
              translator={translator}
              setValueError={setValueError}
            />
            {isLoading ? (
              <ListLoader />
            ) : Object.keys(formValues ?? {}).length !== 0 ? (
              <Row className="row-eq-height">
                <Col md={8}>
                  <EditDashboardForm
                    translator={translator}
                    setValueError={setValueError}
                    valueError={valueError}
                  />
                </Col>
              </Row>
            ) : ""}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EditDashboardIndex;
