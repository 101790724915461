
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { GLOBAL } from "_Globals";

import { useDispatch, useSelector } from "react-redux";
import {
  _passwordField,
  _inputField,
  _dropDown,
  _checkbox,
  TypeaheadandDropdown,
  _radio,
} from "../../common/formFields";
import {HiPlus, HiOutlineTrash} from "react-icons/hi";
import { Field, change, reduxForm, reset } from "redux-form";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
let cookiesData = cookies.get("gph");
if (cookiesData) cookiesData = cookiesData.replace("s:", "");
if (cookiesData)
  cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf("."));
cookiesData = cookiesData.split("~");
let homepageLocation = cookiesData[0];
if (homepageLocation != "/home") homepageLocation = "/" + homepageLocation;
let userRolesStr = cookiesData[33] ? cookiesData[33] : [];

const required = (value) => {
  if (!value || (value && value.trim().length === 0)) {
    return "required";
  }
};

const validate = (values) => {
  const errors = {};

  if (!values.Name || (values.Name && values.Name.trim() == "")) {
    errors.Name = "Enter Name";
  }
  if (!values.Classification) {
    errors.Classification = "Label Classification";
  }
  if (
    !values.Dashboard_ID ||
    (values.Dashboard_ID && values.Dashboard_ID.trim() == "")
  ) {
    errors.Dashboard_Id = "Label Dashboard Id";
  }

  if (!values.Report_Type) {
    errors.Report_Type = "Label Report_Type";
  }

  if (!values.WidgetType) {
    errors.WidgetType = "Label Widget Type";
  }
  if (!values.DashboardResourceID) {
    errors.DashboardResourceID = "Label Dashboard ResourceID";
  }
  if (!values.DashboardUUID) {
    errors.DashboardUUID = "Label Dashboard UUID";
  }
  if (!values.DatasetID) {
    errors.DatasetID = "Label Dataset ID";
  }
  if (!values.Dashboard_ID) {
    errors.Dashboard_ID = "Dashboard ID";
  }
  if (!values.InstanceUserLastName) {
    errors.InstanceUserLastName = "Last Name";
  }
  if (!values.InstanceUserFirstName) {
    errors.InstanceUserFirstName = "First Name";
  }
  if (!values.InstanceUserUserName) {
    errors.InstanceUserUserName = "User name";
  }

  return errors;
};
const CreateDashboardForm = (props) => {
  const [active, setActive] = useState("True");
  const [valueErrorMasterRoles, setValueErrorMasterRoles] = useState("");
  const [selectedValueList, setSelectedValueList] = useState([]);
  const [selectedValueMasterRoles, setSelectedValueMasterRoles] = useState([]);
  const [reportFilters, setReportFilters] = useState([]);
  const [classiData, setClassiData] = useState([]);
  const [reportTypeData, setReportTypeData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [rowCount, setRowCount] = useState([0]);

  const translator = useSelector((state) => state.spcmReducer.tr);
  const dashboardConfigSubmitError = useSelector(
    (state) => state.getDashboardReducerData.dashboardConfigSubmitError
  );

  let isAdmin = userRolesStr?.split(",").includes("1") || userRolesStr?.split(",").includes("67");
  const dispatch = useDispatch();

  const countCharacters = (e) => {
    if (e.target.value.length > 100) {
      e.preventDefault();
    }
  };

  const onValueSelection = (selected) => {
    if (!(Array.isArray(selected) && selected.length)) {
      props.setValueError("error");
      setSelectedValueList(selected);
    } else {
      props.setValueError("")
    }
    dispatch(change("supersetDashboardForm", "roles", selected));
    setSelectedValueList(selected);
  };
  const onValueSelectionMasterRoles = (selected) => {
    if (!(Array.isArray(selected) && selected.length)) {
      setValueErrorMasterRoles("error");
      setSelectedValueMasterRoles(selected);
    } else {
      setValueErrorMasterRoles("");
      dispatch(change("supersetDashboardForm", "masterRoles", selected));
      setSelectedValueMasterRoles(selected);
    }
  };

  useEffect(() => {
    dispatch(change("supersetDashboardForm", "Active", active));
    let paramData = JSON.stringify({
      moduleName: "reports",
      menuName: "report type",
    });
    axios
      .get("/api/getMenuListDataCommon?", { headers: { query: paramData } })
      .then((res) => setReportTypeData(res.data));

    let paramData2 = JSON.stringify({
      moduleName: "reports",
      menuName: "classification",
    });
    axios
      .get("/api/getMenuListDataCommon?", { headers: { query: paramData2 } })
      .then((res) => setClassiData(res.data));
    axios
      .get("/api/getMenuListDataCommon?", {
        headers: {
          query: JSON.stringify({
            moduleName: "reports",
            menuName: "Report Filters",
          }),
        },
      })
      .then((res) => setReportFilters(res.data));
    axios
      .get("/getAllUserRoles", { params: { type: "role" } })
      .then((res) => setRoles(res.data));

    return () => {
      reset('supersetDashboardForm');
      dispatch({type: 'DASHBOARD_RESET_FORM'});
    }
  }, []);

  useEffect(() => {
    dispatch(change('supersetDashboardForm', 'dynamicFieldArr', rowCount));
  }, [rowCount])

  let dashboardTypeData = reportTypeData.map((item) => {
    return { value: item.field1Value, text: item.field1Key };
  });
  let classificationData = classiData?.map((item) => {
    return { value: item.field1Value, text: item.field1Key };
  });
  let reportFiltersData = reportFilters?.map((item) => {
    return { value: item.field1Value, text: item.field1Key };
  });
  let rolesData = roles.map((item) => {
    return { value: item.field1Value, label: item.field1Key };
  });
  let masterRoles = roles.map((item) => {
    return { value: item.field1Value, label: item.field1Key };
  });

  const displayDynamicFields = () => {
    return rowCount.map((row, index) => (
      <Row className="reportGrpfield">
        <Col md={4} sm={4} xs={6}>
          <Form.Group>
            <Form.Label bsClass="">
              {translator["Company ID Field"]}
            </Form.Label>
            <Field
              name={"companyIdAttr_" + row}
              maxLength={50}
              className="form-control"
              component={_inputField}
            ></Field>
          </Form.Group>
        </Col>
        <Col md={4} sm={3} xs={6}>
          <Form.Group>
            <Form.Label bsClass="">
              {translator["Group ID Field"]}
            </Form.Label>

            <Field
              name={"groupIdAttr_" + row}
              maxLength={50}
              className="form-control"
              component={_inputField}
            ></Field>
          </Form.Group>
        </Col>
        <Col md={3} sm={3} xs={8}>
          <Form.Group>
            <Form.Label bsClass="">
              <span className="rStar"></span>
              {translator["Dataset ID"]}
            </Form.Label>
            <Field
              name={"dataSetId_" + row}
              maxLength={50}
              className="form-control"
              component={_inputField}
              validate={[required]}
            ></Field>
          </Form.Group>
        </Col>
        <Col md={1} xs={2} className="d-inline-flex align-items-center margin-t-15">
          {index === 0 && rowCount.length === 1 && (
            <Button
              title="Add"
              className="ciCrtCatBtn"
              onClick={() => {
                addRow();
              }}
            >
              <HiPlus />
            </Button>
          )}
          {rowCount.length > 1 && index === rowCount.length - 1 && (
            <>
              <Button
                title="Add"
                className="ciCrtCatBtn"
                onClick={() => {
                  addRow();
                }}
              >
                <HiPlus />
              </Button>
              <Button
                title="Delete"
                className="ciCrtCatBtn"
                onClick={() => {
                  deleteRow(index);
                }}
              >
                <HiOutlineTrash />
              </Button>
            </>
          )}
          {((index !== 0 && index !== rowCount.length - 1) ||
            (rowCount.length > 1 && index === 0)) && (
            <Button
              title="Delete"
              className="ciCrtCatBtn"
              onClick={() => {
                deleteRow(index);
              }}
            >
              <HiOutlineTrash />
            </Button>
          )}
        </Col>
      </Row>
    ));
  };
  const addRow = () =>  {
    let rows = rowCount.sort();
    let lastElement = rows.at(-1);
    setRowCount([...rowCount, (lastElement + 1)]);
  }
  const deleteRow = (index) => {
    let rows = rowCount;
    let deleted = rows.splice(index, 1);
    setRowCount(structuredClone(rows));
    dispatch(change('supersetDashboardForm', 'companyIdAttr_' + deleted[0], ''));
    dispatch(change('supersetDashboardForm', 'groupIdAttr_' + deleted[0], ''));
    dispatch(change('supersetDashboardForm', 'dataSetId_' + deleted[0], ''));

  }
  // console.log('RowCount', rowCount);
  return (
    <div>
      {!dashboardConfigSubmitError ? null : (
        <Alert variant="danger" className="errorMessageSec">
          <p>
            {
              translator[
                "The form has missing required fields, please revalidate the highlighted fields and update the form !"
              ]
            }
          </p>
        </Alert>
      )}

      <div className="borderForm bFormDiv">
        <form>
          <div className="margin-t-5 padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
            <div className="bgGray padding-5">
              {translator["Report Access controls"]}
            </div>

            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {translator["Name"]}
                  </Form.Label>
                  <Field
                    name="Name"
                    maxLength={50}
                    className="form-control"
                    component={_inputField}
                    onpaste={(e) => {
                      countCharacters(e);
                    }}
                    onChange={(e) => {
                      countCharacters(e);
                    }}
                  ></Field>
                  <span className="f-size-10">
                    {translator["Max Length in DC"]}
                  </span>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {translator["Roles"]}
                  </Form.Label>
                  <Field
                    component={TypeaheadandDropdown}
                    onSelection={onValueSelection}
                    name="roles"
                    className="form-control"
                    options={rolesData}
                    selectedOptions={selectedValueList}
                    onCrossClick={() => {setSelectedValueList([]); props.setValueError('error'); dispatch(change("supersetDashboardForm", "roles", ""));}}
                    errorClass={props.valueError === "error" ? "error" : ""}
                    multiple={true}
                    labelKey={"label"}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {translator["Classification"]}
                  </Form.Label>

                  <Field
                    component={_dropDown}
                    name="Classification"
                    options={classificationData}
                    className="form-control"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label bsClass="">
                    {translator["Master Roles"]}
                  </Form.Label>

                  <Field
                    component={TypeaheadandDropdown}
                    onSelection={onValueSelectionMasterRoles}
                    name="masterRoles"
                    className="form-control"
                    options={masterRoles}
                    selectedOptions={selectedValueMasterRoles}
                    onCrossClick={() => {setSelectedValueMasterRoles([]); dispatch(change("supersetDashboardForm", "masterRoles", ""));}}
                    errorClass={""}
                    multiple={true}
                    labelKey={"label"}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="position-re">
                  <Form.Label bsClass="">
                    <span className="rStar"></span>
                    {translator["Is Active"]}
                  </Form.Label>

                  <Col md={6}>
                    <div className="radioInlineBox margin-t-5">
                      <Field
                        component={_radio}
                        className="form-control"
                        type="radio"
                        name="Active"
                        value="True"
                        checked={active == "True"}
                        onChange={(event) => {
                          setActive(event.target.value);
                        }}
                      />
                      <Field
                        component={_radio}
                        className="form-control"
                        type="radio"
                        name="Active"
                        value="False"
                        checked={active == "False"}
                        onChange={(event) => {
                          setActive(event.target.value);
                        }}
                      />
                    </div>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </div>

          {isAdmin ? (
            <div className="margin-t-5 padding-t-5 padding-r-10 padding-l-10 margin-b-15 border">
              <div className="bgGray padding-5">
                {translator["Report Configuration – Admin"]}
              </div>

              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label bsClass="">
                      <span className="rStar"></span>
                      {translator["Dashboard ID"]}
                    </Form.Label>
                    <Field
                      name="Dashboard_ID"
                      maxLength={50}
                      className="form-control"
                      component={_inputField}
                      onpaste={(e) => {
                        countCharacters(e);
                      }}
                      onChange={(e) => {
                        countCharacters(e);
                      }}
                    ></Field>
                    <span className="f-size-10">
                      {translator["Max Length in DC"]}
                    </span>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label bsClass="">
                      <span className="rStar"></span>
                      {translator["Report Type"]}
                    </Form.Label>
                    <Field
                      component={_dropDown}
                      name="Report_Type"
                      options={dashboardTypeData}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label bsClass="">
                      {translator["Report Filters"]}
                    </Form.Label>
                    <Field
                      component={_dropDown}
                      name="ReportFilters"
                      options={reportFiltersData}
                      className="form-control"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label bsClass="">
                      <span className="rStar"></span>
                      {translator["Widget Type"]}
                    </Form.Label>

                    <Field
                      name="WidgetType"
                      maxLength={50}
                      className="form-control"
                      component={_inputField}
                      onpaste={(e) => {
                        countCharacters(e);
                      }}
                      onChange={(e) => {
                        countCharacters(e);
                      }}
                    ></Field>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label bsClass="">
                      <span className="rStar"></span>
                      {translator["Dashboard Resource ID"]}
                    </Form.Label>

                    <Field
                      name="DashboardResourceID"
                      maxLength={50}
                      className="form-control"
                      component={_inputField}
                      onpaste={(e) => {
                        countCharacters(e);
                      }}
                      onChange={(e) => {
                        countCharacters(e);
                      }}
                    ></Field>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label bsClass="">
                      <span className="rStar"></span>
                      {translator["Dashboard UUID"]}
                    </Form.Label>
                    <Field
                      name="DashboardUUID"
                      maxLength={50}
                      className="form-control"
                      component={_inputField}
                      onpaste={(e) => {
                        countCharacters(e);
                      }}
                      onChange={(e) => {
                        countCharacters(e);
                      }}
                    ></Field>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label bsClass="">
                    <span className="rStar"></span>
                      {translator["Instance User username"]}
                    </Form.Label>
                    <Field
                      name="InstanceUserUserName"
                      maxLength={50}
                      className="form-control"
                      component={_inputField}
                      onpaste={(e) => {
                        countCharacters(e);
                      }}
                      onChange={(e) => {
                        countCharacters(e);
                      }}
                    ></Field>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label bsClass="">
                    <span className="rStar"></span>
                      {translator["Instance User First Name"]}
                    </Form.Label>
                    <Field
                      name="InstanceUserFirstName"
                      maxLength={50}
                      className="form-control"
                      component={_inputField}
                      onpaste={(e) => {
                        countCharacters(e);
                      }}
                      onChange={(e) => {
                        countCharacters(e);
                      }}
                    ></Field>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label bsClass="">
                    <span className="rStar"></span>
                      {translator["Instance User Last Name"]}
                    </Form.Label>

                    <Field
                      name="InstanceUserLastName"
                      maxLength={50}
                      className="form-control"
                      component={_inputField}
                      onpaste={(e) => {
                        countCharacters(e);
                      }}
                      onChange={(e) => {
                        countCharacters(e);
                      }}
                    ></Field>
                  </Form.Group>
                </Col>
              </Row>
              {displayDynamicFields()}
            </div>
          ) : (
            ""
          )}
        </form>
      </div>
    </div>
  );
};
export default reduxForm({
  form: "supersetDashboardForm",
  validate,
  enableReinitialize: true
})(CreateDashboardForm);
