
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import rootReducer from "../reducers/index";
import { configureStore } from "@reduxjs/toolkit";

import { governanceApi } from "../reducers/governance/meeting/meeting";
import { forumApi } from "../reducers/foundation/forum/forumQuery";
import { actionitemApi } from "../reducers/governance/meeting/actionItem";
import { scoreCardApi } from "../reducers/scorecard";
import { VerticalCatalogApi } from "../reducers/VerticalCatalog/VerticalCatalogReducer";
import { rulesApi } from "../reducers/rules";
import { NlpSearchApis } from "../reducers/NLP-Related-Query/WorkItemBoardQuerries";
import { schedulerApi } from "../reducers/schedulerUi";
import { gdsApi } from "../reducers/gds";
import { aismAPI } from "../reducers/aism/aismAPI";
import { NotificationReducer } from "../reducers/NotificationApi/NotificationsApi";
import { SmartFormApi } from "../reducers/smartForm/smartFormApi";
import { OfferingTemplatesApi } from "../reducers/offeringReducers/OfferingTemplatesApi";

export default function configureStoreFunc() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat([
        governanceApi.middleware,
        forumApi.middleware,
        actionitemApi.middleware,
        scoreCardApi.middleware,
        VerticalCatalogApi.middleware,
        rulesApi.middleware,
        NlpSearchApis.middleware,
        schedulerApi.middleware,
        gdsApi.middleware,
        aismAPI.middleware,
        NotificationReducer.middleware,
        SmartFormApi.middleware,
        OfferingTemplatesApi.middleware,
      ]),
  });
}
