
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
// import {LinkContainer} from 'react-router-bootstrap';
import { Link } from "react-router-dom";
import { reduxForm, SubmissionError } from "redux-form";
import { GLOBAL } from "_Globals";
import axios from "axios";
let sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
import moment from "moment";
import {ImSpinner6} from "react-icons/im";
import {IoSaveOutline, IoClose} from "react-icons/io5";
import {LuSearch, LuBarChart} from 'react-icons/lu';
// import { browserHistory } from 'react-router';
import Cookies from "universal-cookie";
import { setInitialwibGlobalSearchFilterData } from "../../../../actions/globalSearch/globalSearchAction";
import { connect } from "react-redux";
import { navigationHooks } from "../../../../helpers/NavigationHook";
import { MyContext } from '_MyContext';
import { opsCategoryKeysMap } from "../../../common/helper";
const cookies = new Cookies();
let homepagelocation = cookies.get("gph");
if (homepagelocation) homepagelocation = homepagelocation.replace("s:", "");
if (homepagelocation)
  homepagelocation = homepagelocation.substring(
    0,
    homepagelocation.lastIndexOf(".")
  );
homepagelocation = homepagelocation.split("~");
let dateformatCookie = homepagelocation[2];

class Header extends React.Component {
  static contextType = MyContext;
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      showLoader: true,
    };

    this.removeGlobalErrorValues = this.removeGlobalErrorValues.bind(this);
  }

  componentDidMount() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}
	componentDidUpdate() {
		this.context.keyDownHandler(this.closeButton,"","",this.saveObjRef);
    this.context.newTabPrevent();
	}

  removeGlobalErrorValues() {
    GLOBAL.serviceErrorColor = "";
    GLOBAL.consumerErrorColor = "";
    GLOBAL.issueErrorColor = "";
    GLOBAL.reportedOnErrorColor = "";
    GLOBAL.throughErrorColor = "";
    GLOBAL.sgErrorColor = "";
    GLOBAL.criticalityErrorColor = "";
    GLOBAL.urgencyErrorColor = "";
    this.props.setInitialwibGlobalSearchFilterData();
  }

  submit(values) {
    return sleep(1000).then(() => {
      let breakFixReportedOn = "";

      if (values.reportedOn) {
        breakFixReportedOn = moment(values.reportedOn).format(
          dateformatCookie.slice(0, 10).toUpperCase() + " HH:mm:ss"
        );
      }

      const categorizationErrors = {};
      for (let i = 1; i <= GLOBAL.categorizationDropdowns; i++) {
				if (values[opsCategoryKeysMap[`opsCategoryId${i}`]] === null || values[opsCategoryKeysMap[`opsCategoryId${i}`]] === undefined || values[opsCategoryKeysMap[`opsCategoryId${i}`]] === '' || values[opsCategoryKeysMap[`opsCategoryId${i}`]] === '0') {
					categorizationErrors[opsCategoryKeysMap[`opsCategoryId${i}`]] = "error";
				}
			}

      GLOBAL.categorizationErrors = Object.keys(categorizationErrors).length > 0 ? categorizationErrors : {};
      
      let breakFixConsumer = GLOBAL.breakFixConsumerID;
      let breakFixConsumerNameValue = GLOBAL.breakFixConsumerName;
      let breakFixConsumerCompany = values.company;
      let breakFixService = GLOBAL.breakFixServiceID;
      let supportPgroupValue = GLOBAL.supportPGroupValue;
      let supportPgroupNameValue = GLOBAL.supportPGroupName;
      let supportPIGroupNameValue = GLOBAL.supportPIGroupName;
      let breakFixServiceName = GLOBAL.breakFixServiceName;
      let breakfixconsumercompanyname = values.consumerCompanyName;
      let ciId = "";
      let ciName = "";
      let ciCode = "";

      let selectedService;
      if (
        GLOBAL.breakFixServiceID !== "" &&
        this.props.serviceList.length > 0
      ) {
        selectedService = this.props.serviceList.filter((item) => {
          return item.offeringId == GLOBAL.breakFixServiceID;
        });
      }
      if (GLOBAL.ciId == "" || GLOBAL.ciId == undefined) {
        ciId = 0;
        ciName = "";
        ciCode = "";
      } else {
        ciId = GLOBAL.ciId;
        ciName = GLOBAL.ciName;
        ciCode = GLOBAL.ciIdNumber;
      }
      console.log("GLOBAL_________", GLOBAL.breakFixConsumerID);
      if (
        (values.service == "impactedCI" && GLOBAL.breakFixServiceName == "") ||
        (values.service == "impactedCI" && GLOBAL.breakFixServiceID == "")
      ) {
        breakFixService = 0;
        GLOBAL.breakFixServiceName = null;
        breakFixServiceName = "";
        GLOBAL.breakFixServiceName = "";
      }
      //alert(supportPgroupNameValue.length());
      if (GLOBAL.breakFixServiceID == "" && values.service == "service") {
        console.log("service is mandatory........");
        // GLOBAL.serviceErrorColor="error";
        // GLOBAL.CIImpactedErrorColor = "";
        this.props.setErrorServiceColor("error");
        console.log(
          "GLOBAL.breakFixServiceID",
          GLOBAL.breakFixServiceID,
          values.service
        );

        return;
      }
      if (values.company === "" || !values.company) {
        this.props.setCompanyError("error");
      }
      if (
        (GLOBAL.ciId == "" || GLOBAL.ciName == "") &&
        values.service == "impactedCI"
      ) {
        // console.log("impactedCI",GLOBAL.ciId,values.service);
        console.log("impactedCI is mandatory........");
        // GLOBAL.CIImpactedErrorColor = "error";
        // GLOBAL.serviceErrorColor="";
        this.props.setErrorImpactedColor("error");
        return;
      }
      if (supportPIGroupNameValue == "Select..") supportPIGroupNameValue = "";
      // if(values.supportPIndividual=="")
      // values.supportPIndividual="0";

      if (values.reportedOn) {
        if (moment(values.reportedOn) > moment()) {
          GLOBAL.reportedOnErrorColor = "error";
          throw new SubmissionError({
            _error:
              "'Reported On' field cannot be greater than current date and time !!!",
          });
        }
      }

      if (`${JSON.stringify(values, null, 2)}` == "{}") {
        throw new SubmissionError({
          _error:
            "The form has missing required fields, please revalidate the highlighted fields and update the form",
        });
      } else if (
        breakFixConsumer == "" ||
        values.issueDescription.trim() == "" ||
        values.reportedThrough == "" ||
        values.supportPGroup == "" ||
        values.supportPGroup == "0" ||
        values.criticality == "" ||
        values.urgencyMode == "" ||
        values.urgencyMode == undefined ||
        Object.keys(categorizationErrors).length > 0
      ) {
        if (breakFixConsumer == "" && values.consumer == "") {
          GLOBAL.consumerErrorColor = "error";
        } else {
          GLOBAL.consumerErrorColor = "";
        }
        if (values.issueDescription.trim() == "") {
          GLOBAL.issueErrorColor = "error";
        } else {
          GLOBAL.issueErrorColor = "";
        }
        if (breakFixReportedOn == "") {
          GLOBAL.reportedOnErrorColor = "error";
        } else {
          GLOBAL.reportedOnErrorColor = "";
        }
        if (
          values.reportedThrough == "" ||
          typeof values.reportedThrough === "undefined" ||
          values.reportedThrough == "Select"
        ) {
          GLOBAL.throughErrorColor = "error";
        } else {
          GLOBAL.throughErrorColor = "";
        }
        if (
          values.criticality == "" ||
          values.criticality == undefined ||
          values.criticality == "Select.."
        ) {
          GLOBAL.criticalityErrorColor = "error";
        } else {
          GLOBAL.criticalityErrorColor = "";
        }
        if (
          values.urgencyMode == "" ||
          values.urgencyMode == undefined ||
          values.urgencyMode == "Select.."
        ) {
          GLOBAL.urgencyErrorColor = "error";
        } else {
          GLOBAL.urgencyErrorColor = "";
        }
        if (
          values.supportPGroup == "" ||
          values.supportPGroup == undefined ||
          values.supportPGroup == "Select"
        ) {
          GLOBAL.sgErrorColor = "error";
        } else {
          GLOBAL.sgErrorColor = "";
        }
        throw new SubmissionError({
          _error:
            "The form has missing required fields, please revalidate the highlighted fields and update the form !",
        });
      } else {
        this.setState({ showLoader: false });

        let serviceBased;
        if (values.service == "service") {
          serviceBased = true;
        } else {
          serviceBased = false;
        }
        if (
          values.supportPIndividual === "" ||
          supportPIGroupNameValue === ""
        ) {
          values.supportPIndividual = "0";
          supportPIGroupNameValue = "";
        }
        const categorizationFields = Object.values(opsCategoryKeysMap).reduce((acc, key) => {
          if (key in values) {
            acc[key] = values[key];
          }
          return acc;
        }, {});
        let postPayload = {
          //======================
          ...categorizationFields,
          serviceId: breakFixService,
          serviceCriticality: (GLOBAL.Vip == "Yes" || GLOBAL.Vip==='yes') ? this.props.sortedImpactList[serviceBased?GLOBAL.consumerCompanyId:GLOBAL.ciConsumerCompany].at(-2).field1Value : values.criticality,
          serviceCriticalityValue: (GLOBAL.Vip == "Yes" || GLOBAL.Vip==='yes') ? this.props.sortedImpactList[serviceBased?GLOBAL.consumerCompanyId:GLOBAL.ciConsumerCompany].at(-2).field1Key : values.criticalityName, 
          criticality: "No",
          impactTo: "S",
          impactedUserid: breakFixConsumer,
          impactedUsername: breakFixConsumerNameValue,
          description: values.issueDescription.trim(),
          reportedOn: breakFixReportedOn,
          additionalInfo: values.additionalInfo,
          spGroupid: values.supportPGroup,
          // spGroupname: supportPgroupNameValue,
          spGroupname: values.supportPGroupName,
          spUserid: values.supportPIndividual,
          // spUsername: supportPIGroupNameValue,
          spUsername: values.supportPIndividualName,
          urgencyJustification: "0",
          soGroupid: 0,
          soUserid: 0,
          status: 10,
          priorityId: (GLOBAL.Vip == "Yes" || GLOBAL.Vip==='yes') ? this.props.priorityValue[serviceBased?GLOBAL.consumerCompanyId:GLOBAL.ciConsumerCompany].priority : values.priorityActualVal,
          priorityValue: (GLOBAL.Vip == "Yes" || GLOBAL.Vip==='yes') ? this.props.priorityValue[serviceBased?GLOBAL.consumerCompanyId:GLOBAL.ciConsumerCompany].priorityLabel : values.priorityLabel,
          criticalJustification: "0",
          holdJustification: "0",
          cause: 1,
          slaStatus: "0",
          expectedDate: "0",
          createdOn: "0",
          createdBy: "",
          modifiedOn: "0",
          modifiedBy: "",
          reportedThrough: values.reportedThrough,
          urgency: (GLOBAL.Vip == "Yes" || GLOBAL.Vip==='yes') ? this.props.sortedUrgencyList[serviceBased?GLOBAL.consumerCompanyId:GLOBAL.ciConsumerCompany].at(0).field1Value : values.urgencyMode,
          urgencyValue: (GLOBAL.Vip == "Yes" || GLOBAL.Vip==='yes') ? this.props.sortedUrgencyList[serviceBased?GLOBAL.consumerCompanyId:GLOBAL.ciConsumerCompany].at(0).field1Key : values.urgencyModeName,
          serviceName: breakFixServiceName,
          consumerCompany: breakFixConsumerCompany,
          createdByName: "",
          consumerCompanyName: breakfixconsumercompanyname,
          reasonCode: values.resolutionCodeName,
          reasonValue: values.resolutionCodeNameText,
          ciId: ciId,
          ciName: ciName,
          ciCode: ciCode,
          environment: GLOBAL.environmentName,
          ciConsumerCompany: GLOBAL.ciConsumerCompany,
          ciSupportCompany: GLOBAL.ciSupportCompany,
          ciClassId: GLOBAL.ciClassId,
          ciLocationId: GLOBAL.ciLocationId,
          ciLocationName: GLOBAL.ciLocationName,
          ciClassName: GLOBAL.ciClassName,
          holingReasonCode: "",
          holdingReasonValue: null,
          serviceBased: serviceBased,
          isExternal: GLOBAL.isExternalIncidentCreate,
          requesterLocationId: GLOBAL.servicelocationid,
          requesterLocationName: GLOBAL.servicelocationname,
          serviceCompanyId: GLOBAL.consumerCompanyId,
          "serviceCompanyName:": GLOBAL.consumerCompanyName,
          businessCriticalityId: GLOBAL.businessCriticalityId,
          businessCriticality: GLOBAL.businessCriticality,
        };
        if (GLOBAL.breakFixServiceID !== "") {
          postPayload.isExternallyFulfilled =
            selectedService[0].isExternallyFulfilled;
        }
        if (serviceBased) {
          postPayload.contextualRuleId = GLOBAL.contextualRuleId;
        }
        postPayload.supportCompanyId=values.supportCompanyID;
				postPayload.supportCompanyName=values.supportCompanyName;
        postPayload['asgGroupHopCount'] = 0;
        postPayload['assigneeHopCount'] = 0;
        let that = this;
        axios
          .post(GLOBAL.breakFixCreateUrl, postPayload)
          .then(function (response) {
            that.setState({ showLoader: true });
            console.log("creating breakfix");
            console.log(response);
            if (response.status == 201) {
              if (ciId != 0) {
                let payloadObj ={
                  "ci_id": ciId,  //==> ID of CI related
                  "wi_id_related": response.data.breakfixId, //==> ID of Work Item related
                  "wi_id_number": response.data.breakfixNumber,//  ==> Number of work item related
                  "ci_id_reported": response.data.reportedOn,
                  "created_by": "",
                  "relationship_type": "Y",
                  "module": "Breakfix",
              }
                axios
                  .post(GLOBAL.cmdbRelatedLogDeleteUrl, /*{
                    ci_id: response.data.breakfixId,
                    ci_id_related: ciId,
                    ci_id_number: response.data.breakfixNumber,
                    ci_id_reported: response.data.reportedOn,
                    created_by: "",
                    relationship_type: "Y",
                    module: "Breakfix",
                  }*/
                  payloadObj
                  )
                  .then((response) => {
                    console.log("this incident have been related to CMDB");
                  });
              } else {
                console.log("no qualification for CI relation");
              }
            }
            // browserHistory.push("/editBreakFix/breakFixItemId:"+response.data.breakfixId);
            navigationHooks.navigate("/editBreakFix/"+response.data.breakfixId);
          })
          .catch(function (error) {
            that.setState({ showLoader: true });
            console.log(error);
          });
      }
    });
  }

  render() {
    let {
      handleSubmit,
      pristine,
      reset,
      submitting,
      isPriorityCallInProgress,
    } = this.props;
    return (
      <Row className="margin-b-15">
        <Col lg={8} md={7} sm={6} xs={5}>
          <h2 className="sPageHeading1">
            {this.props.translator["Break-Fix"]}
          </h2>
        </Col>
        <Col lg={4} md={5} sm={6} xs={7}>
          <div className="status myStatus">
            <Nav as="ul">
              <Nav.Item as="li" eventKey={1}>
                <Link className="saveBtnStyle ctrlKeyPrevent"
                  to="javascript:void(0)"
                  disabled={!this.state.showLoader || isPriorityCallInProgress}
                  title={this.props.translator["Submit"]}
                  onClick={handleSubmit(this.submit)}
                  ref={(e) => (this.saveObjRef = e)}
                >
                  <span> {!this.state.showLoader ? <ImSpinner6 className="icn-spinner"/> : <IoSaveOutline/>}
							    </span>
                </Link>
              </Nav.Item>

              
                <Nav.Item as="li" title={this.props.translator["Close"]} eventKey={2}>
                  <Link onClick={() => { this.removeGlobalErrorValues(); }} className="ctrlKeyPrevent" to="/quickViewBreakFix" ref={e => this.closeButton = e}>
                    <IoClose/>
                  </Link>
                </Nav.Item>
            </Nav>
          </div>
          <div className="status myStatusGap border-r margin-r-10 padding-r-10">
            <Nav as="ul">
              <Nav.Item as="li"
                title={this.props.translator["Process Hierarchy"]}
                eventKey={4}
                onClick={() => {
                  this.props.processHierarchy();
                }}
              >
                <a href="javascript:void(0)">
                 <LuSearch/><LuBarChart className='processBarIcn'/>
                </a>
              </Nav.Item>
            </Nav>
          </div>
        </Col>
      </Row>
    );
  }
}

Header = reduxForm({
  form: "xsmBreakFixFormTab",
})(Header);

export default connect(null, { setInitialwibGlobalSearchFilterData })(Header);
