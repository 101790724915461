
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Tab, Image, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactStars from "react-stars";
import { LiaHeartSolid } from "react-icons/lia";
import dummyImage from "_Images/service-icons/service-dummy.png";

import VerticalCatalogHeader from "../VerticalCatalogHeader";
import useInfiniteScroll from "../../../../helpers/useInfiniteScroll";
import { useGetOfferingsListQuery } from "../../../../reducers/VerticalCatalog/VerticalCatalogReducer";
import { loadCartCount } from "_Actions/common/cartCountAction";
import { storeCategoryName } from "_Actions/homepage/hamburgerAction";

import { GLOBAL } from "../../../Globals";
import _ from "lodash";
import axios from "axios";
import Swal from "sweetalert2";
import ListLoader from "../../../common/loaders/ListLoader";
import Cookies from "universal-cookie";
import Breadcrumbs from "./BreadCrumbs";
import { navigationHooks } from "../../../../helpers/NavigationHook";

const cookies = new Cookies();
let cookiesData = cookies.get("gph");

if (cookiesData) cookiesData = cookiesData.replace("s:", "");
if (cookiesData)
  cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf("."));
cookiesData = cookiesData.split("~");

let cart_limit = cookiesData[19];
if (cart_limit === "" || cart_limit === "undefined" || cart_limit === undefined)
  cart_limit = 5;

let homepagelocation = cookiesData[0];

const OfferingsGridIndex = ({
  activeTab,
  activeTabEventKey,
  activeTabId,
  pageViewFun,
  url,
  setUrl,
  labelType,
  setLabelType,
  start,
  setStart,
  end,
  isGeneralSection,
  setIsGeneralSection,
  parentLabelType,
  parentLabelId,
  parentLabel,
  setParentLabel,
  setParentLabelId,
  setParentLabelType,
  activeMenuSubMenuItems,
  udpateActiveMenuSubMenuItems,
  setActiveTab,
  setActiveTabId,
  setActiveTabEventKey,
  businessFnLabels,
  isFetchingBusinessFn,
  businessFnApiError,
  isView,
}) => {
  const ref = useRef(null);
  const [isFetching, setIsFetching] = useInfiniteScroll(ref, true);

  const [labelId, setLabelId] = useState("");
  const [labelName, setLabelName] = useState("");
  const [listItem, setListItem] = useState([]);
  const [addToCartButtonDisableStatus, setAddToCartButtonDisableStatus] =
    useState(false);
  const [checkoutButtonDisableStatus, setCheckoutButtonDisableStatus] =
    useState(false);
  const [selfCheckoutButtonStatus, setSelfCheckoutButtonStatus] =
    useState(false);
  const [
    selectedCoreComponentForOffering,
    setSelectedCoreComponentForOffering,
  ] = useState("");
  const [selectedOfferingIds, setSelectedOfferingIds] = useState("");
  const [parentBusinessFn, setParentBusinessFn] = useState({});
  const [parentCategory, setParentCategory] = useState({});
  const [isEndReached, setIsEndReached] = useState(false);
  const [offeringsCount, setOfferingsCount] = useState(0);
  const [breadCrumbs, setBreadCrumbs] = useState({
    businessFn: "",
    businessFnId: "",
    businessFnEventKey: "",
    category: "",
    categoryId: "",
    categoryEventKey: "",
    subCategory: "",
    static: "",
    hierarchyType: "general",
  });
  const appliedThemeData = useSelector((state) => state.appliedThemeData.data);

  const {
    isFetching: isFetchingOfferings,
    data: offeringList,
    refetch: fetchOfferingList,
  } = useGetOfferingsListQuery({
    url,
    labelId,
    labelName,
    label_type: labelType,
    start,
    end,
    isGeneralSection,
    parentLabelType,
    parentLabelId,
    parentLabel,
  }, { skip: !url });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tr = useSelector((state) => state.spcmReducer.tr);
  const windowSize = useSelector((state) => state.windowSize);

  useEffect(() => {
    setIsFetching(false);
    switch (activeTabEventKey.toString()[0]) {
      case "0":
        setStart(1);
        setUrl(
          activeTabEventKey === "0.0"
            ? GLOBAL.getFeaturedOfferings
            : activeTabEventKey === "0.1"
            ? GLOBAL.getBestSellingOfferings
            : activeTabEventKey === "0.2"
            ? GLOBAL.getMyFavouritesOfferings
            : activeTabEventKey === "0.3"
            ? GLOBAL.getNewLaunchesOfferings
            : activeTabEventKey === "0.4"
            ? GLOBAL.getUpcomingOfferings
            : activeTabEventKey === "0.5"
            ? GLOBAL.getFreeOfferings
            : ""
        );
        setIsGeneralSection(true);
        setIsEndReached(false);
        setListItem([]);
        break;
      case "1":
        setParentLabelType("");
        setParentLabelId("");
        setParentLabel("");
        setStart(1);
        setUrl(GLOBAL.getOfferingsByBusinessFunction);
        setLabelId(activeTabId);
        setLabelName(activeTab);
        setLabelType("business_function");
        setIsGeneralSection(false);
        setIsEndReached(false);
        setListItem([]);
        break;
      case "2":
        let parent = _.find(activeMenuSubMenuItems.businessFn, {
          active: true,
        });
        setParentBusinessFn(parent);
        setParentLabelType("business_function");
        setParentLabelId(parent.id);
        setParentLabel(parent.label);
        setStart(1);
        setUrl(GLOBAL.getOfferingsByCategory);
        setLabelId(activeTabId);
        setLabelName(activeTab);
        setLabelType("category");
        setIsGeneralSection(false);
        setIsEndReached(false);
        setListItem([]);
        break;
      case "3":
        let parentCat = _.find(activeMenuSubMenuItems.categories, {
          active: true,
        });
        setParentCategory(parentCat);
        setParentLabelType("category");
        setParentLabelId(parentCat.id);
        setParentLabel(parentCat.label);
        setStart(1);
        setUrl(GLOBAL.getOfferingsBySubCategory);
        setLabelId(activeTabId);
        setLabelName(activeTab);
        setLabelType("subcategory");
        setIsGeneralSection(false);
        setIsEndReached(false);
        setListItem([]);
        break;
    }
  }, [activeTabId, activeTab]);

  useEffect(() => {
    if (isGeneralSection) {
      let label = structuredClone(breadCrumbs);
      label.static = activeTab;
      label.businessFn = "";
      label.category = "";
      label.subCategory = "";
      label.hierarchyType = "general";
      setBreadCrumbs(label);
    } else {
      if (labelType === "business_function") {
        let label = structuredClone(breadCrumbs);
        label.businessFn = activeTab;
        label.static = "";
        label.subCategory = "";
        label.hierarchyType = "businessFn";
        setBreadCrumbs(label);
      } else if (labelType === "category") {
        let label = structuredClone(breadCrumbs);
        label.category = activeTab;
        label.businessFn = parentBusinessFn?.label;
        label.businessFnId = parentBusinessFn?.id;
        label.businessFnEventKey = parentBusinessFn?.key;
        label.static = "";
        label.subCategory = "";
        label.hierarchyType = "category";
        setBreadCrumbs(label);
      } else if (labelType === "subcategory") {
        let label = structuredClone(breadCrumbs);
        label.subCategory = activeTab;
        label.category = parentCategory?.label;
        label.categoryId = parentCategory?.id;
        label.categoryEventKey = parentCategory?.key;
        label.businessFn = parentBusinessFn?.label;
        label.businessFnId = parentBusinessFn?.id;
        label.businessFnEventKey = parentBusinessFn?.key;
        label.static = "";
        label.hierarchyType = "subcategory";
        setBreadCrumbs(label);
      }
    }
  }, [
    labelType,
    activeTab,
    activeTabId,
    activeMenuSubMenuItems,
    isGeneralSection,
  ]);

  useEffect(() => {
    let offerings = offeringList?.data;
    if (
      offerings &&
      offerings.data &&
      offerings.data.at(-1) !== listItem.at(-1)
    ) {
      setListItem([...listItem, ...offerings.data]);
      setOfferingsCount(offerings.meta.rowCount);
    } else {
      setOfferingsCount(0);
    }
  }, [offeringList]);

  useEffect(() => {
    if (
      offeringList?.data &&
      offeringList?.data?.meta &&
      isFetching &&
      !isFetchingOfferings
    ) {
      const { limit, currentPage, rowCount } = offeringList?.data?.meta || {
        limit: 12,
        currentPage: 1,
        rowCount: 12,
      };
      if (limit * currentPage < rowCount) {
        setStart(limit * currentPage + 1);
      } else {
        setIsEndReached(true);
      }
    }
    setIsFetching(false);
  }, [isFetching]);

  useEffect(() => {
    if (offeringList?.data && offeringList?.data?.meta) {
      const { rowCount } = offeringList?.data?.meta || { rowCount: 12 };
      if (rowCount !== listItem.length) {
        fetchOfferingList();
      }
    }
    setIsFetching(false);
  }, [start]);

  const loadAllComponentsInCart = (offeringId) => {
    setAddToCartButtonDisableStatus(true);
    setCheckoutButtonDisableStatus(true);
    setSelfCheckoutButtonStatus(true);
    let str = {};
    str.userId = "";
    str = JSON.stringify(str);
    const offeringsInCartResponse = axios.get(GLOBAL.cartOfferingsUrl, {
      headers: { query: str },
    });
    offeringsInCartResponse.then((allOfferingsInCart) => {
      if (allOfferingsInCart.data.length >= cart_limit) {
        Swal.fire({
          title: tr["Maximum number of offerings are already added in cart."],
          confirmButtonText: tr["OK"],
        });

        setAddToCartButtonDisableStatus(false);
        setCheckoutButtonDisableStatus(false);
        setSelfCheckoutButtonStatus(false);
        setSelectedOfferingIds("");
      } else {
        if (
          allOfferingsInCart.data.length !== 0 &&
          _.some(allOfferingsInCart.data, { offeringId: offeringId.toString() })
        ) {
          Swal.fire({
            title: tr["Offering already added in cart."],
            confirmButtonText: tr["OK"],
          });

          setAddToCartButtonDisableStatus(false);
          setCheckoutButtonDisableStatus(false);
          setSelfCheckoutButtonStatus(false);
          setSelectedOfferingIds("");
        } else {
          loadComponentPackage(offeringId, allOfferingsInCart);
        }
      }
    });
  };

  const loadComponentPackage = (offeringId, allOfferingsInCart) => {
    //each time an offering is click in categoryList an new api call with offeringId as parameter wiil be hit to get new response corresponding to offering hit.
    let str = {};
    str.userId = "";
    str.offeringId = offeringId;
    str = JSON.stringify(str);
    axios
      .get(GLOBAL.getComponentDetails, { headers: { query: str } })
      .then((response) => {
        if (!response) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => {
        if (response.status == 200) {
          let selectedCoreComponent = [];
          response.data.map((componentType) => {
            if (componentType.componentType == "Core") {
              if (
                !_.some(selectedCoreComponentForOffering, [
                  "id",
                  componentType.id,
                ])
              ) {
                selectedCoreComponent.push(componentType);
              }
            }
          });
          setSelectedCoreComponentForOffering(selectedCoreComponent);
          saveOfferingAndComponentToCart(
            offeringId,
            selectedCoreComponent,
            allOfferingsInCart
          );
        }
      });
  };

  const saveOfferingAndComponentToCart = (
    offeringId,
    offeringComponentsForCart,
    allOfferingsInCart
  ) => {
    setAddToCartButtonDisableStatus(true);
    setCheckoutButtonDisableStatus(true);
    setSelfCheckoutButtonStatus(true);
    axios
      .post(GLOBAL.addOfferingToCartUrl, {
        offeringId: offeringId,
        userId: "",
      })
      .then((response) => {
        if (response.status == 200) {
          if (offeringComponentsForCart.length !== 0) {
            for (let i = 0; i < offeringComponentsForCart.length; i++) {
              axios
                .post(GLOBAL.addComponentsToCart, {
                  offeringId: offeringId,
                  componentId: offeringComponentsForCart[i].id,
                })
                .then((response) => {
                  if (response.status === 200) {
                    setAddToCartButtonDisableStatus(true);
                  } else {
                    setAddToCartButtonDisableStatus(false);
                    setCheckoutButtonDisableStatus(false);
                    setSelfCheckoutButtonStatus(false);
                    setSelectedOfferingIds("");

                    Swal.fire({
                      title:
                        tr[
                          "Attached components could not be added, please try again."
                        ],
                      confirmButtonText: tr["OK"],
                    });
                  }
                });
            }
          }

          dispatch(loadCartCount());
          setAddToCartButtonDisableStatus(true);
          Swal.fire({
            title: tr["Item is added to cart"],
            showCancelButton: true,
            confirmButtonText: tr["Cart"],
            cancelButtonText: tr["Proceed to Home"],
          }).then((result) => {
            if (result.isDismissed && result.dismiss === "cancel") {
              navigate(homepagelocation);
              setAddToCartButtonDisableStatus(false);
              setSelectedOfferingIds("");
            } else if (result.isConfirmed) {
              navigate("/cart");
            }
          });
        } else {
          setAddToCartButtonDisableStatus(false);
          setCheckoutButtonDisableStatus(false);
          setSelfCheckoutButtonStatus(false);
          setSelectedOfferingIds("");
          Swal.fire({
            title:
              tr[
                "There is a problem adding your recent offering to the cart try again."
              ],
            confirmButtonText: tr["OK"],
          });
        }
        setAddToCartButtonDisableStatus(false);
        setCheckoutButtonDisableStatus(false);
        setSelfCheckoutButtonStatus(false);
        setSelectedOfferingIds("");
      });
  };

  const onOffering = (i, j, k, l) => {
    let categoryName = k;
    if (j != "Suspended" && j != "Under Repair") {
      dispatch(storeCategoryName(k));
      categoryName = categoryName.includes("?")
        ? categoryName.replace("?", "")
        : categoryName;
      // navigate("/offeringDetails/" + i + "/" + categoryName + "/" + l);
      navigationHooks.navigate(
        `/offeringDetails/${encodeURIComponent(i)}/${encodeURIComponent(
          categoryName
        )}/${encodeURIComponent(l)}`
      );
    }
  };

  const onAddFavourite = (object, index) => {
    let arr = structuredClone([...listItem]);
    arr[index].isFavourite = 1;
    axios
      .post(GLOBAL.postOfferingFavUrl, {
        entitledUser: "",
        offeringId: object,
      })
      .then(function (response) {
        if (response.status == 200) {
          setListItem(arr);
        }
      })
      .catch(function (error) {});
  };

  const onRemoveFavourite = (object, index) => {
    let arr = structuredClone([...listItem]);
    arr[index].isFavourite = 0;
    axios
      .post(GLOBAL.postOfferingFavRemoveUrl, {
        entitledUser: "",
        offeringId: object,
      })
      .then(function (response) {
        if (response.status == 200) {
          setListItem(arr);
        }
      })
      .catch(function (error) {});
  };

  return (
    <>
      <Row>
        <Col sm={12} xs={12}>
          <div className="padding-t-10 padding-b-10">
            <Breadcrumbs
              breadCrumbs={breadCrumbs}
              udpateActiveMenuSubMenuItems={udpateActiveMenuSubMenuItems}
              setActiveTab={setActiveTab}
              setActiveTabId={setActiveTabId}
              setActiveTabEventKey={setActiveTabEventKey}
            />
          </div>
        </Col>
      </Row>      
      <VerticalCatalogHeader
        activeTab={activeTab}
        pageViewFun={pageViewFun}
        offeringsCount={offeringsCount}
        isView={isView}
        businessFnLabels={businessFnLabels}
        isFetchingBusinessFn={isFetchingBusinessFn}
        setActiveTab={setActiveTab}
        setActiveTabEventKey={setActiveTabEventKey}
        setActiveTabId={setActiveTabId}
        businessFnApiError={businessFnApiError}
        url={url}
        labelType={labelType}
        isGeneralSection={isGeneralSection}
        start={start}
        end={end}
        parentLabel={parentLabel}
        parentLabelId={parentLabelId}
        parentLabelType={parentLabelType}
        activeMenuSubMenuItems={activeMenuSubMenuItems}
        udpateActiveMenuSubMenuItems={udpateActiveMenuSubMenuItems}
      />
      <div id="offeringsView" ref={ref}>
        {listItem.length === 0 && !isFetchingOfferings ? (
          <div>
            <font>{tr["No Data Found"]}</font>
          </div>
        ) : (
          listItem.map((item, index) => {
            let favouriteexist = item.isFavourite;
            let displayText = "";
            let displayStr = "";
            displayStr =
              item.offeringSummary != null && item.offeringSummary != ""
                ? item.offeringSummary
                : "";
            displayText =
              displayStr != ""
                ? displayStr
                    .replace(
                      /(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/gi,
                      ""
                    )
                    .trim()
                : "";
            displayText =
              displayText != "" ? displayText.replace(/\s+/g, " ") : "";

            let displayName = "";
            let displayNameStr = "";
            displayNameStr =
              item.offeringName != null && item.offeringName != ""
                ? item.offeringName
                : "";
            displayName =
              displayNameStr != ""
                ? displayNameStr
                    .replace(
                      /(&nbsp;|&crarr;|&bull;|&amp;|(<([^>]+)>))|&#39;|&mdash;/gi,
                      ""
                    )
                    .trim()
                : "";
            displayName =
              displayName != "" ? displayName.replace(/\s+/g, " ") : "";

            let getInnerWidth = (innerWidth) => {
              if (innerWidth > 1535) return 55;
              if (innerWidth > 1367) return 46;
              if (innerWidth > 1279) return 40;
              if (innerWidth > 1023) return 28;
              if (innerWidth > 767) return 43;
              if (innerWidth > 640) return 33;
              if (innerWidth > 539) return 45;
              if (innerWidth > 411) return 75;
              if (innerWidth > 279) return 45;
              return 30;
            };

            return (
                <div className="prodSlideBox">
                  <div className="gap">
                    <div className="head">
                      <div className="headImg">
                        <a
                          role="button"
                          title={item.offeringName}
                          onClick={() =>
                            onOffering(
                              item.offeringId,
                              item.subStatus,
                              item.category,
                              item.subCategory
                            )
                          }
                        >
                          <Image
                            alt=""
                            src={
                              item.offeringIconTempURL
                                ? item.offeringIconTempURL
                                : dummyImage
                            }
                          />
                        </a>
                      </div>
                      <div className="headTxt">
                        <a
                          role="button"
                          id={"gridFeature_" + index}
                          title={item.offeringName}
                          onClick={() =>
                            onOffering(
                              item.offeringId,
                              item.subStatus,
                              item.category,
                              item.subCategory
                            )
                          }
                        >
                          {displayName.slice(
                            0,
                            getInnerWidth(windowSize.width)
                          )}
                          {displayName.length > getInnerWidth(windowSize.width)
                            ? "..."
                            : ""}
                        </a>
                      </div>
                    </div>
                    <div className="txtDec">
                      {displayText.slice(0, 70)}
                      {displayText.length > 70 ? "..." : ""}
                    </div>
                    <div className="proPricBill">
                      {item.hidePrice == "true" ? null : (
                        <span className="price">
                          {item.currencyCode}&nbsp;
                          {item.actualPrice}
                        </span>
                      )}{" "}
                      {item.hidePrice == "true" ? null : (
                        <span className="billMeth">{item.billingMethod}</span>
                      )}
                    </div>
                    <div className="headIcon">
                      <a
                        title="share on social media"
                        id={"gridFeatureshare_" + index}
                        role="button"
                        href={void 0}
                      >
                        <i className="fa fa-share-alt" />
                      </a>
                      {favouriteexist == "0" ? (
                        <a
                          href={void 0}
                          role="button"
                          id={"gridFeatureheart_" + index}
                          onClick={() => onAddFavourite(item.offeringId, index)}
                          title={tr["Add to Favorites"]}
                        >
                          <LiaHeartSolid />
                        </a>
                      ) : (
                        <a
                          href={void 0}
                          role="button"
                          id={"gridFeatureheart_" + index}
                          className="active"
                          onClick={() =>
                            onRemoveFavourite(item.offeringId, index)
                          }
                          title={tr["Remove from Favorites"]}
                        >
                          <LiaHeartSolid />
                        </a>
                      )}
                    </div>
                    <div className="proStar">
                      <ReactStars
                        value={item.rating}
                        edit={false}
                        count={5}
                        size={18}
                        color1={"gray"}
                        color2={appliedThemeData?.secondaryColor}
                      />
                    </div>
                  </div>
                  <div className="footBtn">
                    {item.workItemType === "incidentRequest" ||
                    item.workItemType === "standardChange" ||
                    item.status === "Proposed" ||
                    item.deliveryMode == "Other" ? (
                      <span className="disSpan" title="Disabled">
                        {tr["Add to Cart"].slice(0, 20)}{tr["Add to Cart"].length > 20 ? "..." : ''}
                      </span>
                    ) : (
                      <a
                        title={tr["Add to Cart"]}
                        href={void 0}
                        id={"gridFeaturecart_" + index}
                        className={
                          selectedOfferingIds == item.offeringId
                            ? addToCartButtonDisableStatus
                              ? "disSpan"
                              : "cartBtn"
                            : "cartBtn"
                        }
                        role="button"
                        onClick={() => {
                          let selectedOfferingIds = item.offeringId;
                          setSelectedOfferingIds(selectedOfferingIds);
                          loadAllComponentsInCart(item.offeringId);
                        }}
                      >
                        {tr["Add to Cart"].slice(0, 20)}{tr["Add to Cart"].length > 20 ? "..." : ''}
                      </a>
                    )}
                    <a
                      href={void 0}
                      title={tr["Order Now"]}
                      className="orderBtn"
                      id={"gridFeatureorder_" + index}
                      role="button"
                      onClick={() =>
                        onOffering(
                          item.offeringId,
                          item.subStatus,
                          item.category,
                          item.subCategory
                        )
                      }
                    >
                      {tr["Order Now"].slice(0, 17)}{tr["Order Now"].length > 17 ? "..." : ''}
                    </a>
                  </div>
                </div>
            );
          })
        )}
      </div>
      <Row className="d-flex align-items-center justify-content-center">
        {isFetchingOfferings && <ListLoader />}
        {isEndReached && listItem.length > 12 && (
          <Col lg={8} md={4} xs={6}>
            <p className="viewMoreServiceBtn margin-t-10" primary>
              {tr["You have reached the end of results!"]}
            </p>
          </Col>
        )}
      </Row>
    </>
  );
};

export default OfferingsGridIndex;
