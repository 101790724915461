
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useField,useFormikContext } from "formik";
import Loader from "react-loader-spinner";

import { Get } from "../../../../utils/axiosUtils";
import { GLOBAL } from "../../../Globals";


export default function AssignedToTypeHead({ ...props }) {
  const {
    label,
    companyId,
    renderMenuItemChildren,
    labelkey,
    disabled,
    thfor,
    name,
    onChange,
    ...rest
  } = props;

  const [field, meta,helpers] = useField(props);
  const showError = meta.touched && !!meta.error;
  const { setValue, setTouched } = helpers;
  const { setFieldValue } = useFormikContext();
 
  const [fetching, setFetching] = useState(false);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [opentray, setOpentray] = useState(false);

  const handleSearchService = async (str) => {
    if (str.length >= 3) {
    if (typeof setOpentray === "function") {
      setOpentray(true);
    }

    let query = {
      companyId: companyId,
      like: str,
    };

    try {
      if (props.thfor === "personApi" && companyId) {
        setFetching(true);
        const res = await Get(GLOBAL?.consumerUsersNewUrl, {
          headers: { query: JSON.stringify(query) },
        });
        setServiceOptions(res?.data || []);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setFetching(false);
    }}
    else {
      setServiceOptions([]);
    }
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.key === 'Backspace' || (event.ctrlKey && event.key === 'x')) && (field.value?.length ?? 0) < 3) {
        setServiceOptions([]);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [field.value]);


  // useEffect(() => {
  //   if(props.selected.length === 0){
  //     setServiceOptions([]);
  //   }
  // },[props.selected])
  

  return (
    <div className="notifydvTypehd w-100" id="assignedTo">
      <label htmlFor={field.name}>
        {label}&nbsp;{props.required && <span className="rStar"></span>}
      </label>
      <AsyncTypeahead
        {...field}
        {...rest}
        name={name}
        minLength={3}
        open={opentray}
        onFocus={() => setOpentray(true)}
        onBlur={(e) =>{
          setOpentray(false);
          setTouched(true);
        }}
        onChange={(selected) => {
          onChange(selected);
          setOpentray(false);
        }}
        id={`async-${props.thfor}-${props.id}`}
        useCache={false}
        isLoading={fetching}
        className={showError ? "error" : ""}
        emptyLabel={
          fetching ? (
            <Loader type="ThreeDots" color="#2e75b6" height={6} />
          ) : (
            "No results"
          )
        }
        labelKey={labelkey}
        onSearch={handleSearchService}
        options={serviceOptions}
        renderMenuItemChildren={
          renderMenuItemChildren
            ? renderMenuItemChildren
            : (option) => {
                return (
                  <>
                    {thfor === "personApi"
                      ? `${option?.fullname} | ${option?.email} | ${option?.companyName}`
                      : option[labelkey]}
                  </>
                );
              }
        }
        {...(disabled && { disabled: disabled })}
        {...(disabled && { open: !disabled })}
        placeholder={"Search by Name, Email and Company"}
      />
    </div>
  );
}
