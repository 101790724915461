
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import XsmQuickViewList from './CmdbList.js';
import CmdbShortDescriptionList from './CmdbShortDescriptionList.js';
//import FooterCrp from '../../../components/footerCrp.js';
import { Row, Col } from 'react-bootstrap';
import { loadCmdbItemList } from '../../../actions/cmdb/quickViewAction.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CmdbApprovalList from './CmdbApprovalsList.js';
import _ from 'lodash';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

let GLOBAL = require('../../Globals.js');
import { getTranslation } from '../../../actions/spcmActions.js';
import CmdbListHeader from './CmdbListHeader.js';
import List from "../CI-Group/list.js";
import "_Css/common/_table.scss";
import CmdbBulkUpdate from './CmdbBulkUpdate.js';
import Swal from 'sweetalert2';
class CmdbIndex extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			subMenu: '4',
			showHideAlfy: false,
			showHideRightNav: false,
			mobileLeftMenuFilter: false,
			CI_ID: '',
			CI_NAME: '',
			GROUP_NAME: '',
			OWNER_NAME: '',
			LOCATION_NAME: '',
			ENVIRONMENT: '',
			DATASET: '',
			MANUFACTURER: '',
			CI_CODE: '',
			BUSINESS_OWNER_NAME: '',
			TECHNICAL_OWNER_NAME: '',
			// SearchParams: '',
			page: 1,
			size: 10,
			searchText: "",
			searchFilter: "CI_ID",
			setApprovalDescToggle: false,
			toggleFormList: false,
			COMPANY_ID: '',
			CLASS_NAME: '',
			CLASS_ID: '',
			isRightSideVisible: false,
			assetIdSearchInput: '',
			nameSearchInput: '',
			companySearchInput: '',
			classSearchInput: '',
			businessOwnerSearchInput: '',
			serialNumberSearchInput: '',
			technicalOwnerSearchInput: '',
			locationSearchInput: '',
			modifiedOnSearchInput: '',
			rowIndex: 0,
			startDate: {},
			endDate: {},
			selectedTypeValue: [],
			selectedStatusValue: [],
			selectedSubStatusValue: [],
			selectedSupportGroupValue: [],
			selectedClassValue: [],
			isClearAllFilter: false,
			searchParams: {},
			status: [],
			sub_status: [],
			type: [],
			searchClass: [],
			COMPANY_NAME: '',
			TYPE: '',
			STATUS_NAME: '',
			SUB_STATUS_NAME: '',
			UPDATED_AT: '',
			ASSET_TAG: '',
			CATEGORY_NAME: '',
			METALLIC_VALUE: '',
			panelResize: "",
			SERIAL_NUMBER: "",
			isClassOpen: false,
			isTypeOpen: false,
			isStatusOpen: false,
			isSubStatusOpen:false,
			activeTab: 'tab1',
      		lastSelectedTab: null,
			bulkUpdateData:[],
			selection: {}
		}

		GLOBAL.searchbarcategory = 'All';
		this.showHideAlfy = this.showHideAlfy.bind(this);
		this.showHideRightNav = this.showHideRightNav.bind(this);
		this.getCMDBDetails = this.getCMDBDetails.bind(this);
		//this.props.loadCmdbItemList();
		this.buildSearchParamsForCMDBQV = this.buildSearchParamsForCMDBQV.bind(this);
		this.props.getTranslation(this.props.lang);
		this.updateFilter = this.updateFilter.bind(this);
		this.setPage = this.setPage.bind(this);
		this.setLimit = this.setLimit.bind(this);
		this.setApplyButtonToggle = this.setApplyButtonToggle.bind(this);
		this.setSearchText = this.setSearchText.bind(this);
		this.setSearchFilter = this.setSearchFilter.bind(this);
		this.setApprovalDescToggleFunc = this.setApprovalDescToggleFunc.bind(this);
		this.toggleFormListFunc = this.toggleFormListFunc.bind(this);
		this.showRightSide = this.showRightSide.bind(this);
		this.setAssetIdSearchInput = this.setAssetIdSearchInput.bind(this);
		this.setNameSearchInput = this.setNameSearchInput.bind(this);
		this.setCompanySearchInput = this.setCompanySearchInput.bind(this);
		this.setClassSearchInput = this.setClassSearchInput.bind(this);
		// this.setTypeSearchInput = this.setTypeSearchInput.bind(this);
		// this.setStatusSearchInput = this.setStatusSearchInput.bind(this);
		// this.setSubstatusSearchInput = this.setSubstatusSearchInput.bind(this);
		this.setSerialNumberSearchInput = this.setSerialNumberSearchInput.bind(this);
		this.setBusinessOwnerSearchInput = this.setBusinessOwnerSearchInput.bind(this);
		this.setTechnicalOwnerSearchInput = this.setTechnicalOwnerSearchInput.bind(this);
		this.setLocationSearchInput = this.setLocationSearchInput.bind(this);
		// this.setSupportCompanySearchInput = this.setSupportCompanySearchInput.bind(this);
		// this.setSupportGroupSearchInput = this.setSupportGroupSearchInput.bind(this);
		this.setModifiedOnSearchInput = this.setModifiedOnSearchInput.bind(this);
		this.showActiveRow = this.showActiveRow.bind(this);
		this.setStartAndEndDate = this.setStartAndEndDate.bind(this);
		this.setSelectedTypeValue = this.setSelectedTypeValue.bind(this);
		this.setSelectedStatusValue = this.setSelectedStatusValue.bind(this);
		this.setSelectedSubStatusValue = this.setSelectedSubStatusValue.bind(this);
		this.setSelectedSupportGroupValue = this.setSelectedSupportGroupValue.bind(this);
		this.setSelectedClassValue = this.setSelectedClassValue.bind(this);
		this.isClearAllFilterFunc = this.isClearAllFilterFunc.bind(this);
		this.setRef = this.setRef.bind(this);
		this.setSearchParams = this.setSearchParams.bind(this);
		this.setMultiSelectParams = this.setMultiSelectParams.bind(this);
		this.multiSelectOpenFun = this.multiSelectOpenFun.bind(this);
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
		this.resetSelection = this.resetSelection.bind(this);
		this.updateBulkUpdateData = this.updateBulkUpdateData.bind(this);
		this.handleBulkCheckbox = this.handleBulkCheckbox.bind(this);
		this.refreshTable = this.refreshTable.bind(this);
	}


	componentDidMount() {			
		this.setState({ 
      activeTab: GLOBAL.ciActivetab || this.state.activeTab,
      lastSelectedTab: GLOBAL.ciActivetab || this.state.activeTab
    });
	}
	
	componentDidUpdate(prevProps) {
  
  }

	setActiveTab = (tab) => {
   this.setState({ 
      activeTab: tab,
      lastSelectedTab: tab 
    });
		GLOBAL.ciActivetab=tab;		
  };

	isClearAllFilterFunc(value) {
		this.setState({ isClearAllFilter: value });
	}

	setMultiSelectParams(type, status, searchClass, sub_status) {
		this.setState({ type: type, status: status, searchClass: searchClass, sub_status: sub_status });
	}

	showRightSide(value) {
		this.setState({ isRightSideVisible: value });
	}
	setSearchParams(value) {
		this.setState({ searchParams: value });
	}
	showActiveRow(index) {
		this.setState({ rowIndex: index });
	}

	setStartAndEndDate(startDate, endDate) {
		this.setState({ startDate: startDate, endDate: endDate });
	}

	setSelectedTypeValue(value) {
		this.setState({ selectedTypeValue: value });
	}

	setSelectedStatusValue(value) {
		this.setState({ selectedStatusValue: value });
	}

	setSelectedSubStatusValue(value) {
		this.setState({ selectedSubStatusValue: value });
	}

	setSelectedClassValue(value) {
		this.setState({ selectedClassValue: value });
	}

	setSelectedSupportGroupValue(value) {
		this.setState({ selectedSupportGroupValue: value });
	}

	setAssetIdSearchInput(value) {
		this.setState({ assetIdSearchInput: value });
	}
	setNameSearchInput(value) {
		this.setState({ nameSearchInput: value });
	}
	setCompanySearchInput(value) {
		this.setState({ companySearchInput: value });
	}
	setClassSearchInput(value) {
		this.setState({ classSearchInput: value });
	}

	setRef(ref) {
		this.inputRef = ref;
	}
	// setTypeSearchInput(value) {
	// 	this.setState({ typeSearchInput: value });
	// }
	// setStatusSearchInput(value) {
	// 	this.setState({ statusSearchInput: value });
	// }
	// setSubstatusSearchInput(value) {
	// 	this.setState({ substatusSearchInput: value });
	// }
	setSerialNumberSearchInput(value) {
		this.setState({ serialNumberSearchInput: value });
	};
	setBusinessOwnerSearchInput(value) {
		this.setState({ businessOwnerSearchInput: value });
	}
	setTechnicalOwnerSearchInput(value) {
		this.setState({ technicalOwnerSearchInput: value });
	}
	setLocationSearchInput(value) {
		this.setState({ locationSearchInput: value });
	}
	// setSupportCompanySearchInput(value) {
	// 	this.setState({ supportCompanySearchInput: value });
	// }
	// setSupportGroupSearchInput(value) {
	// 	this.setState({ supportGroupSearchInput: value });
	// }
	setModifiedOnSearchInput(value) {
		this.setState({ modifiedOnSearchInput: value });
	}

	toggleFormListFunc(value) {
		this.setState({ toggleFormList: value });
	}

	setApprovalDescToggleFunc(value) {
		this.setState({ setApprovalDescToggle: value });
	}

	setPage(pageNo) {
		this.setState({
			page: pageNo
		});
	}

	setLimit(limit) {
		this.setState({
			size: limit
		});
	}

	setSearchFilter(searchFilterValue) {
		this.setState({
			searchFilter: searchFilterValue
		});
	}
	setSearchText(searchTextValue) {
		this.setState({
			searchText: searchTextValue
		});
	}
	setApplyButtonToggle(value) {
		this.setState({ searchtextlist: value });
	}

	updateFilter(a) {
		this.setState({
			SearchParams: a
		})
	}
	showHideAlfy(value) {
		this.setState({ showHideAlfy: value });
	}
	showHideRightNav(value) {
		this.setState({ showHideRightNav: !this.state.showHideRightNav });
	}

	mobileLeftMenuFilter() {
		this.setState({ mobileLeftMenuFilter: !this.state.mobileLeftMenuFilter });
	}

	multiSelectOpenFun(keyName, val) {
		switch (keyName) {
		  case "cmdbClass":
			this.setState({
				isClassOpen: val,
				isTypeOpen: false,
				isStatusOpen: false,
				isSubStatusOpen: false
			});
			break;
			case "cmdbType":
			this.setState({
				isClassOpen: false,
				isTypeOpen: val,
				isStatusOpen: false,
				isSubStatusOpen: false
			});
			break;
			case "cmdbStatus":
			this.setState({
				isClassOpen: false,
				isTypeOpen: false,
				isStatusOpen: val,
				isSubStatusOpen: false
			});
			break;
			case "cmdbSubStatus":
				this.setState({
					isClassOpen: false,
					isTypeOpen: false,
					isStatusOpen: false,
					isSubStatusOpen:val,
				});
				break;
			case "allFalse":
			this.setState({
				isClassOpen: false,
				isTypeOpen: false,
				isStatusOpen: false,
				isSubStatusOpen: false
			});
			break;
		}
	  }

	getCMDBDetails(CI_ID, CI_NAME, GROUP_NAME, OWNER_NAME, LOCATION_NAME, ENVIRONMENT_NAME, DATASET, MANUFACTURER, CI_CODE, BUSINESS_OWNER_NAME, TECHNICAL_OWNER_NAME, COMPANY_ID, CLASS_NAME, CLASS_ID, COMPANY_NAME, TYPE, STATUS_NAME, SUB_STATUS_NAME, UPDATED_AT, ASSET_TAG, CATEGORY_NAME, METALLIC_NAME,SUB_CATEGORY_NAME,SERIAL_NUMBER) {
		this.setState({
			CI_ID: CI_ID,
			CI_NAME: CI_NAME,
			GROUP_NAME: GROUP_NAME,
			OWNER_NAME: OWNER_NAME,
			LOCATION_NAME: LOCATION_NAME,
			ENVIRONMENT: ENVIRONMENT_NAME,
			DATASET: DATASET,
			MANUFACTURER: MANUFACTURER,
			CI_CODE: CI_CODE,
			BUSINESS_OWNER_NAME: BUSINESS_OWNER_NAME,
			TECHNICAL_OWNER_NAME: TECHNICAL_OWNER_NAME,
			COMPANY_ID: COMPANY_ID,
			CLASS_NAME: CLASS_NAME,
			CLASS_ID: CLASS_ID,
			COMPANY_NAME: COMPANY_NAME,
			TYPE: TYPE,
			STATUS_NAME: STATUS_NAME,
			SUB_STATUS_NAME: SUB_STATUS_NAME,
			UPDATED_AT: UPDATED_AT,
			ASSET_TAG: ASSET_TAG,
			CATEGORY_NAME: CATEGORY_NAME,
			SUB_CATEGORY_NAME: SUB_CATEGORY_NAME,
			METALLIC_VALUE: METALLIC_NAME,
			SERIAL_NUMBER: SERIAL_NUMBER
		});
	}

	buildSearchParamsForCMDBQV(params) {
		let searchParams = Object.assign({}, params);

		//======Status Filter Start====
		let selectedStatus = this.props.cmdbQuickViewFilters.selectedStatus;
		//console.log(selectedpriorities);
		if (!_.isEmpty(selectedStatus)) {
			if (!_.isEmpty(searchParams.searchByList)) {
				//alert("called")
				searchParams.searchByList = searchParams.searchByList + ',' + 'STATUS';
				searchParams.multipleValueList = searchParams.multipleValueList + '|' + selectedStatus.join();
			}
			else {
				//alert("called")
				searchParams.searchByList = 'STATUS';
				searchParams.multipleValueList = selectedStatus.join();
			}
		}
		//======Status Filter End===========

		//=======Company Filter Start========
		let selectedCompany = this.props.cmdbQuickViewFilters.selectedCompany;
		//console.log(selectedpriorities);
		if (!_.isEmpty(selectedCompany)) {
			if (!_.isEmpty(searchParams.searchByList)) {
				//alert("called")
				searchParams.searchByList = searchParams.searchByList + ',' + 'COMPANY_ID';
				searchParams.multipleValueList = searchParams.multipleValueList + '|' + selectedCompany.join();
			}
			else {
				searchParams.searchByList = 'COMPANY_ID';
				searchParams.multipleValueList = selectedCompany.join();
			}
		}
		//=======Company Filter End========
		//=======Class Filter Start========
		let selectedClass = this.props.cmdbQuickViewFilters.selectedClass;
		//console.log(selectedpriorities);
		if (!_.isEmpty(selectedClass)) {
			if (!_.isEmpty(searchParams.searchByList)) {
				//alert("called")
				searchParams.searchByList = searchParams.searchByList + ',' + 'CLASS_ID';
				searchParams.multipleValueList = searchParams.multipleValueList + '|' + selectedClass.join();
			}
			else {
				searchParams.searchByList = 'CLASS_ID';
				searchParams.multipleValueList = selectedClass.join();
			}
		}
		//=======Class Filter End========
		return searchParams;
	}
	updateBulkUpdateData = (bulkUpdateData) => {
		this.setState({ bulkUpdateData });
	};

	  resetSelection = () => {
		this.setState({ selection: {} }, () => {
		});
	  };
	
	  handleCheckboxChange(checked, row) {
		const selection = { ...this.state.selection };
		selection[row.CI_ID] = checked; 
	  
		const selectedCiIds = Object.keys(selection).filter((id) => selection[id]);
	  
		const selectedCompanies = new Set(
		  selectedCiIds.map((ciId) => {
			const record = this.props.cmdbQuickItemList?.result?.find(
			  (item) => Number(item.CI_ID) === Number(ciId)
			);
			return record?.COMPANY_ID;
		  })
		);
	  
		if (selectedCompanies.size > 1) {
		  Swal.fire({
			text: "Please select all records from a common Company",
			confirmButtonText: "OK",
		  }).then(() => {
			selection[row.CI_ID] = false;
			this.setState({ selection }, () => {
			  const updatedSelectedCiIds = Object.keys(selection).filter(
				(id) => selection[id]
			  );
			  this.updateBulkUpdateData(updatedSelectedCiIds);
			  this.showRightSide(updatedSelectedCiIds.length > 0);
			});
		  });
		} else {
		  this.setState({ selection }, () => {
			this.updateBulkUpdateData(selectedCiIds || []);
			this.showRightSide(selectedCiIds.length > 0);
		  });
		}
	  }

	  handleBulkCheckbox(e) {
		const isChecked = e.target.checked;
		const updatedSelection = {};
		this.props.cmdbQuickItemList?.result?.forEach((row) => {
		  updatedSelection[row.CI_ID] = isChecked;
		});
	  
		if (isChecked) {
		  const selectedCompanies = new Set(
			this.props.cmdbQuickItemList?.result?.map((row) => row.COMPANY_ID)
		  );
	  
		  if (selectedCompanies.size > 1) {
			Swal.fire({
			  text: "Please select all records from a common Company",
			  confirmButtonText: "OK",
			}).then(() => {
			  this.setState({ selection: {} }, () => {
				this.updateBulkUpdateData([]);
				this.showRightSide(false);
			  });
			});
			return;
		  }
		  this.setState({ COMPANY_ID: selectedCompanies.values().next().value });
		}
	  
		this.setState({ selection: updatedSelection }, () => {
		  const selectedCiIds = Object.keys(updatedSelection).filter(
			(id) => updatedSelection[id]
		  );
		  this.updateBulkUpdateData(selectedCiIds);
		  this.showRightSide(selectedCiIds.length > 0);
		});
	  }

	  refreshTable() {
		console.log("Refreshing table...");
		this.resetSelection(); // Clear selection
		this.showRightSide(false); // Hide the right-side panel
		this.setPage(1); // Reset the page to 1
		this.setLimit(10); // Set default limit
	
		// Reset all filters
		this.setAssetIdSearchInput("");
		this.setNameSearchInput("");
		this.setCompanySearchInput("");
		this.setClassSearchInput("");
		this.setBusinessOwnerSearchInput("");
		this.setSerialNumberSearchInput("");
		this.setTechnicalOwnerSearchInput("");
		this.setLocationSearchInput("");
		this.setSelectedTypeValue([]);
		this.setSelectedStatusValue([]);
		this.setSelectedSubStatusValue([]);
		this.setSelectedClassValue([]);
		this.setStartAndEndDate({}, {});
		this.setSearchParams({}); // Reset search parameters
		this.setMultiSelectParams([], [], [], []); // Reset multi-select parameters
	
		// Hide "Clear All Filter" icon
		this.isClearAllFilterFunc(false);
	
		// Reload data
		this.props.loadCmdbItemList(1, 10, {}, [], [], []);
	}

	render() {
		let colWidth = "";
		this.state.isRightSideVisible ? colWidth = 8 : colWidth = 12;
		console.log("clearfilter and righsidevisible", this.state.isClearAllFilter, this.state.isRightSideVisible, colWidth);
		console.log("GLOBAL.ciActivetab",GLOBAL.ciActivetab);
		return (
			<div>
				<div className="minHeightWIB">
					<div className="container-fluid margin-t-10" bsClass="">
						{/* Xsm Quick View Detailt Header Section Start*/}
						<CmdbListHeader
						 	resetSelection={this.resetSelection}
							toggleFormListFunc={this.toggleFormListFunc}
							toggleFormList={this.state.toggleFormList}
							setApprovalDescToggleFunc={this.setApprovalDescToggleFunc}
							setApprovalDescToggle={this.state.setApprovalDescToggle}
							translator={this.props.tr}
							showRightSide={this.showRightSide}
							isRightSideVisible={this.state.isRightSideVisible}
							setAssetIdSearchInput={this.setAssetIdSearchInput}
							setNameSearchInput={this.setNameSearchInput}
							setCompanySearchInput={this.setCompanySearchInput}
							setClassSearchInput={this.setClassSearchInput}

							setBusinessOwnerSearchInput={this.setBusinessOwnerSearchInput}
							setSerialNumberSearchInput={this.setSerialNumberSearchInput}
							setTechnicalOwnerSearchInput={this.setTechnicalOwnerSearchInput}
							setLocationSearchInput={this.setLocationSearchInput}
							setModifiedOnSearchInput={this.setModifiedOnSearchInput}
							assetIdSearchInput={this.state.assetIdSearchInput}
							nameSearchInput={this.state.nameSearchInput}
							companySearchInput={this.state.companySearchInput}
							classSearchInput={this.state.classSearchInput}
							serialNumberSearchInput={this.state.serialNumberSearchInput}
							businessOwnerSearchInput={this.state.businessOwnerSearchInput}
							technicalOwnerSearchInput={this.state.technicalOwnerSearchInput}
							locationSearchInput={this.state.locationSearchInput}
							modifiedOnSearchInput={this.state.modifiedOnSearchInput}
							rowIndex={this.state.rowIndex}
							showActiveRow={this.showActiveRow}
							startDate={this.state.startDate}
							endDate={this.state.endDate}
							selectedTypeValue={this.state.selectedTypeValue}
							selectedStatusValue={this.state.selectedStatusValue}
							selectedSubStatusValue={this.state.selectedSubStatusValue}
							selectedSupportGroupValue={this.state.selectedSupportGroupValue}
							setSelectedTypeValue={this.setSelectedTypeValue}
							setSelectedStatusValue={this.setSelectedStatusValue}
							setSelectedSubStatusValue={this.setSelectedSubStatusValue}
							setSelectedSupportGroupValue={this.setSelectedSupportGroupValue}
							selectedClassValue={this.state.selectedClassValue}
							setSelectedClassValue={this.setSelectedClassValue}
							setStartAndEndDate={this.setStartAndEndDate}
							isClearAllFilter={this.state.isClearAllFilter}
							isClearAllFilterFunc={this.isClearAllFilterFunc}
							setRef={this.setRef}
							searchParams={this.state.searchParams}
							setSearchParams={this.setSearchParams}
							setMultiSelectParams={this.setMultiSelectParams}
							status={this.state.status}
							sub_status={this.state.sub_status}
							type={this.state.type}
							searchClass={this.state.searchClass}
							dateRangePickerRef={this.inputRef}
							setLimit={this.setLimit}
							setPage={this.setPage}
							COMPANY_NAME={this.state.COMPANY_NAME}
							TYPE={this.state.TYPE}
							STATUS_NAME={this.state.STATUS_NAME}
							SUB_STATUS_NAME={this.state.SUB_STATUS_NAME}
							UPDATED_AT={this.state.UPDATED_AT}
							ASSET_TAG={this.state.ASSET_TAG}
							CATEGORY_NAME={this.state.CATEGORY_NAME}
							METALLIC_VALUE={this.state.METALLIC_VALUE}
							setActiveTab={this.setActiveTab}
							activeTab={this.state.activeTab}
							
						/>
						{/* Xsm Quick View Detailt Header Section end*/}

						<PanelGroup direction="horizontal" className={(this.state.isClassOpen || this.state.isTypeOpen || this.state.isStatusOpen || this.state.isSubStatusOpen) && ((this.props.cmdbQuickItemList?.result?.length < 6) || (this.props.showLoader == true) || (this.props.cmdbQuickItemList == 'No record found')) ? ' formGroupB10 myProfileLabel myOrders overflow-clip' : ' formGroupB10 myProfileLabel myOrders'}>
							<Panel id="sidebar" minSize={30} order={1} defaultSize={this.state.isRightSideVisible ? 67 : 100} onResize={(size) => this.setState({ panelResize : size})} className={(this.state.isClassOpen || this.state.isTypeOpen || this.state.isStatusOpen || this.state.isSubStatusOpen) && ((this.props.cmdbQuickItemList?.result?.length < 6) || (this.props.showLoader == true) || (this.props.cmdbQuickItemList == 'No record found')) ? 'overflow-clip' : (this.state.isRightSideVisible ? "isShowLeftPanel rwLeftPart" : "rwLeftPart")}>
								{/* Xsm Quick View List Section Start*/}
								{this.state.activeTab === 'tab1' && 
								<XsmQuickViewList
									selection={this.state.selection}
									resetSelection={this.resetSelection}
									handleCheckboxChange={this.handleCheckboxChange}
									updateBulkUpdateData={this.updateBulkUpdateData}
									handleBulkCheckbox={this.handleBulkCheckbox}
									setApprovalDescToggle={this.state.setApprovalDescToggle}
									translator={this.props.tr}
									searchFilter={this.state.searchFilter}
									setSearchFilter={this.setSearchFilter}
									updateFilter={this.updateFilter}
									searchText={this.state.searchText}
									setSearchText={this.setSearchText}
									toggleApplyButtonClick={this.state.toggleApplyButtonClick}
									setApplyButtonToggle={this.setApplyButtonToggle}
									buildSearchParamsForCMDBQV={this.buildSearchParamsForCMDBQV}
									size={this.state.size}
									page={this.state.page}
									setLimit={this.setLimit}
									setPage={this.setPage}
									getCMDBDetails={this.getCMDBDetails}
									SearchParams={this.state.SearchParams}
									toggleFormListFunc={this.toggleFormListFunc}
									showRightSide={this.showRightSide}
									isRightSideVisible={this.state.isRightSideVisible}
									setAssetIdSearchInput={this.setAssetIdSearchInput}
									setNameSearchInput={this.setNameSearchInput}
									setCompanySearchInput={this.setCompanySearchInput}
									setClassSearchInput={this.setClassSearchInput}
									// setTypeSearchInput={this.setTypeSearchInput}
									// setStatusSearchInput={this.setStatusSearchInput}
									// setSubstatusSearchInput={this.setSubstatusSearchInput}
									setBusinessOwnerSearchInput={this.setBusinessOwnerSearchInput}
									setSerialNumberSearchInput={this.setSerialNumberSearchInput}
									setTechnicalOwnerSearchInput={this.setTechnicalOwnerSearchInput}
									setLocationSearchInput={this.setLocationSearchInput}
									// setSupportCompanySearchInput={this.setSupportCompanySearchInput}
									// setSupportGroupSearchInput={this.setSupportGroupSearchInput}
									setModifiedOnSearchInput={this.setModifiedOnSearchInput}
									assetIdSearchInput={this.state.assetIdSearchInput}
									nameSearchInput={this.state.nameSearchInput}
									companySearchInput={this.state.companySearchInput}
									classSearchInput={this.state.classSearchInput}
									// typeSearchInput={this.state.typeSearchInput}
									// statusSearchInput={this.state.statusSearchInput}
									// substatusSearchInput={this.state.substatusSearchInput}
									serialNumberSearchInput={this.state.serialNumberSearchInput}
									businessOwnerSearchInput={this.state.businessOwnerSearchInput}
									technicalOwnerSearchInput={this.state.technicalOwnerSearchInput}
									locationSearchInput={this.state.locationSearchInput}
									// supportCompanySearchInput={this.state.supportCompanySearchInput}
									// supportGroupSearchInput={this.state.supportGroupSearchInput}
									modifiedOnSearchInput={this.state.modifiedOnSearchInput}
									rowIndex={this.state.rowIndex}
									showActiveRow={this.showActiveRow}
									startDate={this.state.startDate}
									endDate={this.state.endDate}
									selectedTypeValue={this.state.selectedTypeValue}
									selectedStatusValue={this.state.selectedStatusValue}
									selectedSubStatusValue={this.state.selectedSubStatusValue}
									selectedSupportGroupValue={this.state.selectedSupportGroupValue}
									setSelectedTypeValue={this.setSelectedTypeValue}
									setSelectedStatusValue={this.setSelectedStatusValue}
									selectedClassValue={this.state.selectedClassValue}
									setSelectedClassValue={this.setSelectedClassValue}
									setSelectedSubStatusValue={this.setSelectedSubStatusValue}
									setSelectedSupportGroupValue={this.setSelectedSupportGroupValue}
									setStartAndEndDate={this.setStartAndEndDate}
									isClearAllFilter={this.state.isClearAllFilter}
									isClearAllFilterFunc={this.isClearAllFilterFunc}
									setRef={this.setRef}
									searchParams={this.state.searchParams}
									setSearchParams={this.setSearchParams}
									setMultiSelectParams={this.setMultiSelectParams}
									status={this.state.status}
									sub_status={this.state.sub_status}
									type={this.state.type}
									searchClass={this.state.searchClass}
									dateRangePickerRef={this.inputRef}
									COMPANY_NAME={this.state.COMPANY_NAME}
									TYPE={this.state.TYPE}
									STATUS_NAME={this.state.STATUS_NAME}
									SUB_STATUS_NAME={this.state.SUB_STATUS_NAME}
									UPDATED_AT={this.state.UPDATED_AT}
									ASSET_TAG={this.state.ASSET_TAG}
									CATEGORY_NAME={this.state.CATEGORY_NAME}
									METALLIC_VALUE={this.state.METALLIC_VALUE}
									panelResize={this.state.panelResize}
									multiSelectOpenFun={this.multiSelectOpenFun}
									isClassOpen={this.state.isClassOpen}
									isTypeOpen={this.state.isTypeOpen}
									isStatusOpen={this.state.isStatusOpen}
									isSubStatusOpen={this.state.isSubStatusOpen}
								/>}
								{/* Xsm Quick View List Section End*/}
								{this.state.activeTab === 'tab2' && <List 
								 setActiveTab={this.setActiveTab}
							   activeTab={this.state.activeTab}
								 />}
							</Panel>
							{this.state.isRightSideVisible &&
							this.state.activeTab === "tab1" ? (
								<>
								<PanelResizeHandle className="isShowLeftPanel resizeHandle">
									<div className="outlne">
									<div className="handIcn">
										<i
										className="fa fa-chevron-left"
										aria-hidden="true"
										></i>
									</div>
									</div>
								</PanelResizeHandle>
								<Panel minSize={33} order={2} defaultSize={33}>
									<div className="stickyArea rBoxStyle">
									{this.state?.bulkUpdateData &&
									Array.isArray(this.state.bulkUpdateData) &&
									this.state.bulkUpdateData.length > 0 ? (
										<CmdbBulkUpdate 
											handleCheckboxChange={this.handleCheckboxChange}
											selectedCiIds={this.state.bulkUpdateData} 
											updateBulkUpdateData={this.updateBulkUpdateData} 
											showRightSide={this.showRightSide} 
											resetSelection={this.resetSelection}
											COMPANY_NAME={this.state.COMPANY_NAME}
											COMPANY_ID={this.state.COMPANY_ID}
											cmdbQuickItemList={this.props.cmdbQuickItemList}
											refreshTable={this.refreshTable}
											/>
									) : this.state.setApprovalDescToggle ? (
										<CmdbApprovalList
										CI_ID={this.state.CI_ID}
										COMPANY_ID={this.state.COMPANY_ID}
										CI_NAME={this.state.CI_NAME}
										CLASS_NAME={this.state.CLASS_NAME}
										CLASS_ID={this.state.CLASS_ID}
										cmdbQuickItemList={this.props.cmdbQuickItemList}
										toggleFormListFunc={this.toggleFormListFunc}
										toggleFormList={this.state.toggleFormList}
										showRightSide={this.showRightSide}
										isRightSideVisible={this.state.isRightSideVisible}
										setAssetIdSearchInput={this.setAssetIdSearchInput}
										setNameSearchInput={this.setNameSearchInput}
										setCompanySearchInput={this.setCompanySearchInput}
										setClassSearchInput={this.setClassSearchInput}
										setBusinessOwnerSearchInput={
											this.setBusinessOwnerSearchInput
										}
										setSerialNumberSearchInput={
											this.setSerialNumberSearchInput
										}
										setTechnicalOwnerSearchInput={
											this.setTechnicalOwnerSearchInput
										}
										setLocationSearchInput={this.setLocationSearchInput}
										setModifiedOnSearchInput={
											this.setModifiedOnSearchInput
										}
										assetIdSearchInput={this.state.assetIdSearchInput}
										nameSearchInput={this.state.nameSearchInput}
										companySearchInput={this.state.companySearchInput}
										classSearchInput={this.state.classSearchInput}
										serialNumberSearchInput={
											this.state.serialNumberSearchInput
										}
										businessOwnerSearchInput={
											this.state.businessOwnerSearchInput
										}
										technicalOwnerSearchInput={
											this.state.technicalOwnerSearchInput
										}
										locationSearchInput={this.state.locationSearchInput}
										modifiedOnSearchInput={
											this.state.modifiedOnSearchInput
										}
										rowIndex={this.state.rowIndex}
										showActiveRow={this.showActiveRow}
										startDate={this.state.startDate}
										endDate={this.state.endDate}
										selectedTypeValue={this.state.selectedTypeValue}
										selectedStatusValue={this.state.selectedStatusValue}
										selectedSubStatusValue={
											this.state.selectedSubStatusValue
										}
										selectedSupportGroupValue={
											this.state.selectedSupportGroupValue
										}
										setSelectedTypeValue={this.setSelectedTypeValue}
										setSelectedStatusValue={this.setSelectedStatusValue}
										setSelectedSubStatusValue={
											this.setSelectedSubStatusValue
										}
										setSelectedSupportGroupValue={
											this.setSelectedSupportGroupValue
										}
										setStartAndEndDate={this.setStartAndEndDate}
										isClearAllFilter={this.state.isClearAllFilter}
										isClearAllFilterFunc={this.isClearAllFilterFunc}
										setRef={this.setRef}
										searchParams={this.state.searchParams}
										setSearchParams={this.setSearchParams}
										setMultiSelectParams={this.setMultiSelectParams}
										status={this.state.status}
										sub_status={this.state.sub_status}
										type={this.state.type}
										searchClass={this.state.searchClass}
										dateRangePickerRef={this.inputRef}
										COMPANY_NAME={this.state.COMPANY_NAME}
										TYPE={this.state.TYPE}
										STATUS_NAME={this.state.STATUS_NAME}
										SUB_STATUS_NAME={this.state.SUB_STATUS_NAME}
										UPDATED_AT={this.state.UPDATED_AT}
										ASSET_TAG={this.state.ASSET_TAG}
										/>
									) : (
										<CmdbShortDescriptionList
										CI_ID={this.state.CI_ID}
										CI_NAME={this.state.CI_NAME}
										SERIAL_NUMBER={this.state.SERIAL_NUMBER}
										GROUP_NAME={this.state.GROUP_NAME}
										OWNER_NAME={this.state.OWNER_NAME}
										LOCATION_NAME={this.state.LOCATION_NAME}
										ENVIRONMENT={this.state.ENVIRONMENT}
										DATASET={this.state.DATASET}
										MANUFACTURER={this.state.MANUFACTURER}
										CI_CODE={this.state.CI_CODE}
										BUSINESS_OWNER_NAME={this.state.BUSINESS_OWNER_NAME}
										TECHNICAL_OWNER_NAME={this.state.TECHNICAL_OWNER_NAME}
										translator={this.props.tr}
										cmdbQuickItemList={this.props.cmdbQuickItemList}
										showRightSide={this.showRightSide}
										isRightSideVisible={this.state.isRightSideVisible}
										setAssetIdSearchInput={this.setAssetIdSearchInput}
										setNameSearchInput={this.setNameSearchInput}
										setCompanySearchInput={this.setCompanySearchInput}
										setClassSearchInput={this.setClassSearchInput}
										setBusinessOwnerSearchInput={
											this.setBusinessOwnerSearchInput
										}
										setSerialNumberSearchInput={
											this.setSerialNumberSearchInput
										}
										setTechnicalOwnerSearchInput={
											this.setTechnicalOwnerSearchInput
										}
										setLocationSearchInput={this.setLocationSearchInput}
										setModifiedOnSearchInput={
											this.setModifiedOnSearchInput
										}
										assetIdSearchInput={this.state.assetIdSearchInput}
										nameSearchInput={this.state.nameSearchInput}
										companySearchInput={this.state.companySearchInput}
										classSearchInput={this.state.classSearchInput}
										serialNumberSearchInput={
											this.state.serialNumberSearchInput
										}
										businessOwnerSearchInput={
											this.state.businessOwnerSearchInput
										}
										technicalOwnerSearchInput={
											this.state.technicalOwnerSearchInput
										}
										locationSearchInput={this.state.locationSearchInput}
										modifiedOnSearchInput={
											this.state.modifiedOnSearchInput
										}
										rowIndex={this.state.rowIndex}
										showActiveRow={this.showActiveRow}
										startDate={this.state.startDate}
										endDate={this.state.endDate}
										selectedTypeValue={this.state.selectedTypeValue}
										selectedStatusValue={this.state.selectedStatusValue}
										selectedSubStatusValue={
											this.state.selectedSubStatusValue
										}
										selectedSupportGroupValue={
											this.state.selectedSupportGroupValue
										}
										setSelectedTypeValue={this.setSelectedTypeValue}
										setSelectedStatusValue={this.setSelectedStatusValue}
										selectedClassValue={this.state.selectedClassValue}
										setSelectedClassValue={this.setSelectedClassValue}
										setSelectedSubStatusValue={
											this.setSelectedSubStatusValue
										}
										setSelectedSupportGroupValue={
											this.setSelectedSupportGroupValue
										}
										setStartAndEndDate={this.setStartAndEndDate}
										isClearAllFilter={this.state.isClearAllFilter}
										isClearAllFilterFunc={this.isClearAllFilterFunc}
										setRef={this.setRef}
										searchParams={this.state.searchParams}
										setSearchParams={this.setSearchParams}
										setMultiSelectParams={this.setMultiSelectParams}
										status={this.state.status}
										sub_status={this.state.sub_status}
										type={this.state.type}
										searchClass={this.state.searchClass}
										dateRangePickerRef={this.inputRef}
										COMPANY_NAME={this.state.COMPANY_NAME}
										TYPE={this.state.TYPE}
										STATUS_NAME={this.state.STATUS_NAME}
										SUB_STATUS_NAME={this.state.SUB_STATUS_NAME}
										UPDATED_AT={this.state.UPDATED_AT}
										ASSET_TAG={this.state.ASSET_TAG}
										CLASS_NAME={this.state.CLASS_NAME}
										CATEGORY_NAME={this.state.CATEGORY_NAME}
										SUB_CATEGORY_NAME={this.state.SUB_CATEGORY_NAME}
										METALLIC_VALUE={this.state.METALLIC_VALUE}
										/>
									)}
									</div>
								</Panel>
								</>
							) : (
								""
							)}

						</PanelGroup>
					</div>
				</div>
				{/* <FooterCrp /> */}
			</div>
		);
	}
}

export function mapStateToProps(state, showLoader) {
	return {
		cmdbQuickItemList: state.cmdbQuickItemList,
		cmdbQuickViewFilters: state.cmdbQuickViewFilters,
		showLoader: showLoader.loading,
		//spcmReducer:state.spcmReducer,
		tr: state.spcmReducer.tr,
		lang: state.spcmReducer.lang
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadCmdbItemList, getTranslation }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CmdbIndex);

//export default CMDB;


