
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useMemo, useState } from "react";
import Button from 'react-bootstrap/Button';
import DataTable from "react-data-table-component";
import ExportCSV from "../../myRequests/exportCSV";
import {IoClose} from "react-icons/io5";
import {IoMdArrowDropdown} from "react-icons/io";

const FilterComponent = ({ filterText, onFilter, onClear, data, exportButtonFlag,columnNames }) => (
  <>
    {exportButtonFlag ?
      <ExportCSV
        csvData={data}
        fileName={"Report"}
        columnNames = {columnNames}
         />
      : ""}

    <div className="tabFiltabDv"><input
      className="form-control"
      id="search"
      type="text"
      placeholder="Search"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
      <Button bsPrefix=" " type="button" onClick={onClear}><IoClose /></Button>
    </div>
  </>
);

const TableComponent = (props) => {
  const [id, setId] = useState('')
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filteredItems, setFilteredItems] = useState(props.data);

 // let filteredItems = props.data;

 useEffect(() => {
   let newList = [];
   if (filterText) {
     if (props?.data && Array.isArray(props?.data)) {
       props?.data?.filter((item) => {
         props.headerColumns.map((col) => {
           if (
             item[col] &&
             item[col]
               .toString()
               .toLowerCase()
               .includes(filterText.toLowerCase())
           ) {
             if (
               !newList.find(
                 (obj) => obj[props.uniqueKey] === item[props.uniqueKey]
               )
             ) {
               newList.push(item);
             }
           }
         });
       });
     }
   } else {
     newList = props.data;
   }
   setFilteredItems(newList);
   if (typeof props?.setFilterItems === "function") {
     props.setFilterItems(newList);
   }
 }, [filterText, props?.data]);

  let paginationComponentOptions;

  if(props.paginationText){
     paginationComponentOptions = {
      rowsPerPageText: props.paginationText,

    };
  }
  else{
     paginationComponentOptions = {
      rowsPerPageText: "Show:",
      
    };
  }

  const conditionalRowStyles = [
    {
      when: row => (row[props.uniqueKey] === id && props.rightDisplay),
      classNames: ["activeRow"],
    }
  ];

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

     return (
      <>
        {props?.data?.length !== 0 &&
          (!props?.hideSearch ? (
            <FilterComponent
              onFilter={(e) => setFilterText(e.target.value)}
              onClear={handleClear}
              filterText={filterText}
              data={filteredItems}
              exportButtonFlag={props.exportButton}
              columnNames={props.headerColumns}
            />
          ) : null)}
      </>
    );
  }, [filterText, resetPaginationToggle, props.rightDisplay, id, props.data, props?.checkedItems]);

  return (
    <>
        <DataTable
          className="rdt_Table"
          columns={props.columns}
          data={filteredItems}
          pagination={!props?.pagination}
          subHeader
          paginationResetDefaultPage={resetPaginationToggle}
          subHeaderComponent={subHeaderComponentMemo}
          onRowClicked={(row) => {props.onRowSelect(row); setId(row[props.uniqueKey])}}
          striped
          highlightOnHover
          pointerOnHover={props.noPointer?false:true}
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          conditionalRowStyles={conditionalRowStyles}
          paginationComponentOptions={paginationComponentOptions}
          sortIcon={<IoMdArrowDropdown />}
        />
    </>
  );
};

export default TableComponent;
