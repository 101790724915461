
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Form from 'react-bootstrap/Form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import axios from 'axios';
import { GLOBAL } from '_Globals';
//let issue="";
import { loadBreakFixAuditLogDetails } from '_Actions/breakFix/breakFixAuditLogAction';
import { getInvestigationItemDetails,getInvestigationItemDetailsById } from '_Actions/investigation/investigationAction.js';
import { loadStatusBasedHamburgerOptions } from '_Actions/common/loadStatusBasedHamburgerOptions';
import { loadTimelineData } from '_Actions/common/commonApisActions.js';
import swal from 'sweetalert';
import {ImSpinner6} from "react-icons/im";
import {IoClose} from "react-icons/io5";
//let issueDescription="";
class ProposeCriticalTab extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			issueValidationError: '',
			savedCancelMessage: '',
			issue: '',
			saveLoader:false
		};
		this.getIssue = this.getIssue.bind(this);
	}

	getIssue(event) {
		//issue="";
		this.setState({
			issue: event.target.value
		})
		console.log(event.target.value);
	}

	//posting request
	postRequest() {
		if(this.props.invRelatedDataDetailsCount===0 && (this.props.investigationItemDetails.source==='Unresolved Incident'||this.props.investigationItemDetails.source==='Recurring Incident'||this.props.investigationItemDetails.source==='High Priority Incident'))
				swal({
					text: this.props.translator['Please link an appropriate Incident(s) with this Problem to proceed'],
					button: this.props.translator['OK'],
				  });
        else if(this.props.user_id!=this.props.investigationItemDetails.asgPmId && (this.props.roleIdentification('16') || this.props.roleIdentification('17') ))
				   swal({
					  text: this.props.translator["Please assign the ticket to yourself before changing the status of the ticket"],
					  button: this.props.translator['OK'],
				    });
		else
		{
		//this.onChangeUrgency();
		//alert(this.props.investigationId)
		//alert(this.state.issue)
		let ref = this;

		//alert(this.state.issue);
		if (this.state.issue == "") {
			this.setState({
				issueValidationError: "error"
			})
		} else {
			this.setState({
				issueValidationError: ''
			})
		}

		//insert request start
		let calcelReason="Cancel: "+this.state.issue;
		let postjson = {
			//======================JSON Start
			"cancellationComments": this.state.issue,
			"status": "70",
			"modifiedBy":"",
			"modifiedByName":""
			//==============JSON End
		};
		let postjsonactivity = {
			"itemId":this.props.investigationId,
			"description":calcelReason,
			"isInternal":"",
			"createdOn":"",
			"createdBy":"",
			"module":"Investigation",
			"createdByName":""
		};
		console.log(postjson);
		if (this.state.issue != "") {
			this.setState({saveLoader:true});
			axios.patch(GLOBAL.investigationEditUrl + this.props.investigationId, postjson)
				.then(function (response) {
					console.log(response);
					//alert("ABC"+response.data.breakfixId);
					//alert(response.statusText);
					if (response.status === 200) {
						//alert("M12")
						ref.inputComments.value = "";
						ref.setState({
							//savedMessage:"Requested cteated for order "+response.data.breakfixNumber
							savedCancelMessage: "Investigation Record Cancelled"
						})

						axios.post(GLOBAL.breakFixActivityLogPostUrl, postjsonactivity)
						.then(function (response) {
							this.setState({saveLoader:false});
							console.log("response.statusText:::"+response.status)
							})
						.catch(function (error) {
							this.setState({saveLoader:false});
							console.log(error);
							//alert("H1"+error);
						});
						ref.props.getInvestigationItemDetailsById(response.data.investigationId.toString());
						// ref.props.loadBreakFixAuditLogDetails(response.data.investigationId.toString(), "dsc", "Investigation",'STATUS');
						ref.props.loadStatusBasedHamburgerOptions('Investigation',response.data.status,response.data.investigationId);

					} else {
						console.log("response status is not ok:" + response.statusText);
						this.setState({saveLoader:false});
					}
				})
				.catch(function (error) {
					console.log(error);
					//alert("H1"+error);
				});
		} else {
			console.log("Cancellation reason not found");
		}
	}
	}
	//==============
	render() {

		if (this.state.savedCancelMessage == "") {
			return (
				<div className="hambTab">
					<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
						<ul>
							<li>
							<button
							    type='button'
								title="Minimize the right panel"
								bsClass=""
								bsStyle=""
								className="closerightPanelBtn"
								onClick={() => {
								this.props.rightEditPanel(false);
								this.props.changeActionView("");
								}}
							>
								<IoClose />
							</button>
							</li>
						</ul>
					</div>
					{this.state.issueValidationError == "error" ? <div><font color="red"><strong>Please provide the reason for cancellation !</strong></font></div> : <div></div>}

					<div className="rPageHeading">{this.props.translator['Problem Cancellation']}</div>
					<Form.Group validationState={this.state.issueValidationError}>
						<Form.Label><span className="rStar"></span>{this.props.translator['Comments']} </Form.Label>
						<Form.Control as="textarea" rows="4" inputRef={(input) => this.inputComments = input} onChange={this.getIssue.bind(this)} />
					</Form.Group>
					{this.state.savedCancelMessage == "" || this.state.issueValidationError == "error" ?
					//  <div>
					// 	<ButtonToolbar className="margin-t-15">
					// 		<Button bsStyle="primary" onClick={() => { this.postRequest(); }}>
					// 			{this.props.translator['Save']}
					// 		</Button>
					// 	</ButtonToolbar>
					// </div>

						<ButtonToolbar className="black margin-t-10">
						<Button bsSize="small" bsStyle="primary" disabled={this.state.saveLoader}
							onClick={() => {this.postRequest();}} >
							{this.state.saveLoader ?
							<ImSpinner6 className="icn-spinner"/> : null} {this.props.translator['Save']}
						</Button>
						</ButtonToolbar>
					   :
					   <div></div>
					   }
				</div>
			);
		} else {
			return (
				<div>
					<div className="rPageHeadActBtn d-md-none" style={{ marginTop: "-3px" }}>
						<ul>
							<li>
							<button
							    type='button'
								title="Minimize the right panel"
								bsClass=""
								bsStyle=""
								className="closerightPanelBtn"
								onClick={() => {
								this.props.rightEditPanel(false);
								this.props.changeActionView("");
								}}
							>
								<IoClose />
							</button>
							</li>
						</ul>
					</div>
					<div className="rPageHeading">{this.props.translator['Problem Cancellation']}</div>
					<div><font><b>{this.state.savedCancelMessage}</b></font></div>
				</div>
			);
		}
	}
}

export default connect(null, { loadBreakFixAuditLogDetails, getInvestigationItemDetailsById,getInvestigationItemDetails, loadStatusBasedHamburgerOptions, loadTimelineData })(ProposeCriticalTab);
