
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import axios from "axios";
import { isEmpty } from "lodash";
import constants from "../utils/constants";
import { GLOBAL } from "../components/Globals";

const api = axios.create({
  headers: { Pragma: "no-cache", "Cache-control": "no-store" },
});

export function postServiceAPI(form) {
  return axios.post(constants.SERVICE_DETAILS_API_URL, form);
}

export function patchServiceAPI(form) {
  return axios.patch(
    constants.SERVER + constants.SERVICE_API_URL + "/" + form.offering_id,
    form
  );
}

export function getTranslationAPI(lang) {

  let str = {};
  str.lang = lang;
  str = JSON.stringify(str);
  return axios.get("/api/international?langquery=" + lang, {
    headers: { query: str },
  });
}
export const getChainCompanyListAPI = (companyId, role = "") => {
  let str = "";
  if (role != "") str = "&role=" + role;
  return axios.get(
    "/service/api/chainEntities/getChainCompanyList" + "?companyId=" + companyId + str
  );
};

export const getCompanyListAPI = (userId) => {
  let str = "";
  if (userId != "") {
    str += "userId=" + userId;
  }

  return axios.get("/service/api/serviceportfolio/companylist?" + str);
};

export const postNEcompanyDetailsApi = (id,code) => {
  // let str = "";
  // let lan ="";
  // if (id != "") {
  //   str += "id=" + id;
  // }
  // if(code != ""){
  //   lan += "language=" + code;
  // }


  let str = {};
  str.id = id;
  str.language = code;


  return axios.post("/api/companyDetailsNE", str);
};

export const getMilboxName = (companyId) => {
  let str = {};
  str.companyId = companyId;
  str = JSON.stringify(str);
  return axios.get("/api/getNotificationCred" , { headers: { query: str }});
};

export const uploadLogoForCompanyNEApi = (id, values) => {
  let identity;
  identity = {
    Id: id,
    Files: values
  }
  return axios.post("/api/uploadLogoForCompany?id=3217", identity, {
    headers: {
      "Content-Type": "multipart/form-data",
    },

  });

}

export const getPortfolioGroupListAPI = (companyId) => {
  return axios.get(
    constants.FOUNDATAION_SERVER +
    constants.SERVICE_GROUP_API_URL +
    "?companyId="
  );
};

export const getPortfolioManagerListAPI = (groupId) => {
  return axios.get("/api/userListSelfService" + "?groupId=" + groupId);
};

export const getCatalogueGroupListAPI = (companyId) => {
  return axios.get(
    "/service/api/chainEntities/getCatalogueGroupList" + "?companyId=" + companyId
  );
};

export const getCatalogueManagerListAPI = (groupId) => {
  return axios.get(
    constants.FOUNDATAION_SERVER +
    constants.SERVICE_USER_API_URL +
    "?groupId=" +
    groupId
  );
};

/**
 * get all services from spcm api
 */
export const getAllServices = (queryObj, pageNo, pageSize) => {
  let str = {};
  if (queryObj.length > 0) {
    str.queryObj = queryObj;
  }

  if (str == "") {
    str.page = pageNo;
    str.limit = pageSize;
  }

  str = JSON.stringify(str);

  return api.get("/service/api/serviceDetails", { headers: { query: str } });
};

export const getNotificationDataList = (modifiedDate, type,language,module, status, searchParams, pageNo, pageSize) => {

  let queryString = {};
  // if (queryObj.length > 0) {
  //   queryString.queryObj = queryObj;
  // }

  // if (queryString == "") {
  //   queryString.page = pageNo;
  //   queryString.limit = pageSize;
  // } else {
  //   queryString.page = pageNo;
  //   queryString.limit = pageSize;
  // }



  if(isEmpty(searchParams)) {

    module.length !== 0 ? queryString.module_name = module : delete queryString.module_name;
    status.length !== 0 ? queryString.status = status : delete queryString.module;
    language.length !==0? queryString.locale = language : delete queryString.locale;
    modifiedDate.length !== 0 ? queryString.daterange = modifiedDate.join("$") : delete queryString.modifiedDate;
    queryString.page = pageNo;
    queryString.limit = pageSize;
    queryString.type = type;
    queryString = Object.assign({}, queryString)
  }
  else {
    module.length !== 0 ? searchParams.module_name = module : delete searchParams.module_name;
    status.length !== 0 ? searchParams.status = status : delete searchParams.module;
    language.length !==0? searchParams.locale = language : delete searchParams.locale;
    modifiedDate.length !== 0 ? searchParams.daterange = modifiedDate.join("$") : delete searchParams.modifiedDate;
    searchParams.page = pageNo;
    searchParams.limit = pageSize;
    searchParams.type = type;
    queryString = Object.assign({}, searchParams)
  }
  let queryString2 = JSON.stringify(queryString);
  return api.get("/api/notificationData", { headers: { query: queryString2 } });
};

export const getServiceDetailsDataListApi = (
  searchParams,
  status,
  category,
  featured,
  workItemType,
  modifiedDate,
  order,
  sortOnField,
  page,
  limit
)=> {

  let queryString = {};
  let JsonParams = {};
  let queryByStr = [];
  let queryValue = [];
  let queryBy1 = []
  let queryVal1 = [];

  status.length !== 0 ? queryString.Status = status.join(",") : delete queryString.Status;
  category.length !== 0 ? queryString.Category = category.join(",") : delete queryString.Category;
  featured.length !== 0 ? queryString.Featured = featured.join(",") : delete queryString.Featured;
  workItemType.length !== 0 ? queryString.Type = workItemType.join(",") : delete queryString.Type;
  modifiedDate.length !== 0 ? queryString.modifiedDate = modifiedDate.join("&") : delete queryString.modifiedDate;
  queryString = Object.assign({}, queryString);

  Object.keys(queryString).forEach((queryBy) => {
    queryBy1.push(queryBy);
  })
  queryByStr = queryBy1.join(",");
  Object.values(queryString).forEach((queryVal) => {
    queryVal1.push(queryVal);
  })
  queryValue = queryVal1.join("|");

  let originalParams = {};
  originalParams.queryBy = queryByStr;
  originalParams.queryValue = queryValue;
  originalParams.company = searchParams.Company;
  originalParams.service = searchParams.Service;
  originalParams.queryObj = "";
  originalParams.page = page;
  originalParams.limit = limit;
  originalParams.sortBy = sortOnField;
  originalParams.order = order;
  originalParams = Object.assign({}, originalParams);
  JsonParams = JSON.stringify(originalParams);
  
  return api.get(
    "/api/serviceDetails/getofferingDetails", { headers: { query: JsonParams } }  
  );
};

export const getReviewsAPI = (config) => {
  return axios.get("/api/getReviewRating", config);
};

export const getCriticalityListAPI = () => {
  return axios.get("/service/api/serviceportfolio/criticalityListSPSelfService");
};

export const getCategoryListAPI = (companyId, serviceBoard, businessFn) => {
  let str = "";
  if (companyId != "") {
    str += "companyId=" + companyId + "&type=" + serviceBoard;
  }
  if(businessFn != "" || businessFn === 0) {
    str += '&businessFunctionId=' + businessFn;
  }
  return axios.get("/service/api/getSubCategoryListForRestifySelfService?" + str);
};

export const getEntitlementEntityAPI = (entity, companyId) => {
  if (entity == "group") {
    return axios.get(
      "/service/api/entitlement/entitledforGroup/api/1/companies/" +
      companyId +
      "/groups"
    );
  } else {
    let str = "";
    if (entity != "") {
      str += "type=" + entity + "&companyId=" + companyId;
    }

    return axios.get("/service/api/entitlement/entitledfor?" + str);
  }
};

export const uploadPictureAPI = (offeringId, values) => {
  return axios.patch("/service/api/saveServiceImage/" + offeringId, values, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export function getConsumptionItemsAPI(
  offeringId,
  query,
  queryValue,
  sort,
  order = "asc",
  pageNo,
  pageSize
) {
  let str = "";
  if (query != "") str += "queryBy=" + query + "&queryValue=" + queryValue;
  if (sort != "") {
    if (str == "") str += "sortBy=" + sort;
    else str += "&sortBy=" + sort;
  }
  if (str == "") str += "page=" + pageNo + "&limit=" + pageSize;
  else str += "&page=" + pageNo + "&limit=" + pageSize;
  if (str == "") str += "order=" + order;
  else str += "&order=" + order;
  return api.get(
    "/service/api/components/getConsumptionItems/" +
    offeringId +
    "/consumptionitems?" +
    str
  );
}

export function getServiceMyQuestionLists(categoryId) {
  return axios.get("/service/api/" + categoryId + "/getMyQuestions");
}

export function getServiceSupportCompanyGroups(offeringId) {
  return axios.get("/service/api/getBreakFixGroup/" + offeringId);
}

export function getServiceProviderCompanyGroups(offeringId) {
  return axios.get("/service/api/getBreakfixGroupsProvider/" + offeringId);
}

export function getServiceOrderChoicesAPI(
  offeringId,
  query,
  queryValue,
  sort,
  order,
  pageNo,
  pageSize
) {
  let str = "";
  if (query != "") str += "queryBy=" + query + "&queryValue=" + queryValue;
  if (sort != "") {
    if (str == "") str += "sortBy=" + sort;
    else str += "&sortBy=" + sort;
  }
  if (str == "") str += "page=" + pageNo + "&limit=" + pageSize;
  else str += "&page=" + pageNo + "&limit=" + pageSize;
  if (str == "") str += "order=" + order;
  else str += "&order=" + order;
  return api.get(
    "/service/api/" + offeringId + "/getOfferingQuestionsList?" + str
  );
}

export function getAttributesAPI(
  offeringId,
  query,
  queryValue,
  sort,
  order = "asc",
  pageNo,
  pageSize
) {
  let str = "";
  if (query != "") str += "queryBy=" + query + "&queryValue=" + queryValue;
  if (sort != "") {
    if (str == "") str += "sortBy=" + sort;
    else str += "&sortBy=" + sort;
  }
  if (str == "") str += "page=" + pageNo + "&limit=" + pageSize;
  else str += "&page=" + pageNo + "&limit=" + pageSize;
  if (str == "") str += "order=" + order;
  else str += "&order=" + order;
  return api.get(
    "/service/api/attributeList/" + offeringId + "/ofgAttributes?" + str
  );
}

export function getServiceBillingAPI(
  offeringId,
  query,
  queryValue,
  sort,
  order = "asc",
  pageNo,
  pageSize
) {
  var str = "";
  if (query != "") str += "queryBy=" + query + "&queryValue=" + queryValue;
  if (sort != "") {
    if (str == "") str += "sortBy=" + sort;
    else str += "&sortBy=" + sort;
  }
  if (str == "") str += "page=" + pageNo + "&limit=" + pageSize;
  else str += "&page=" + pageNo + "&limit=" + pageSize;
  if (str == "") str += "order=" + order;
  else str += "&order=" + order;
  //return axios.get(constants.SERVER+constants.SERVICE_API_URL+'/'+offeringId+"/serviceBilling?"+str);
  return api.get("/service/api/" + offeringId + "/serviceBilling?" + str);
}

export function getServiceKnowledgeAPI(
  offeringId,
  query,
  queryValue,
  sort,
  order = "asc",
  pageNo,
  pageSize
) {
  let str = "";
  if (query != "") str += "queryBy=" + query + "&queryValue=" + queryValue;
  if (sort != "") {
    if (str == "") str += "sortBy=" + sort;
    else str += "&sortBy=" + sort;
  }
  if (str == "") str += "page=" + pageNo + "&limit=" + pageSize;
  else str += "&page=" + pageNo + "&limit=" + pageSize;
  if (str == "") str += "order=" + order;
  else str += "&order=" + order;
  //return axios.get(constants.SERVER+constants.SERVICE_API_URL+'/'+offeringId+"/serviceKnowledge?"+str);
  return api.get(
    "/service/api/getKnowledge/" + offeringId + "/serviceKnowledges?" + str
  );
}



export function getLOVFullfilmentPlanAPI() {
  //return axios.get(constants.SERVER+constants.SERVICE_API_URL+'/'+offeringId+"/ofgFulfilmentPlans?"+str);
  return axios.get("/service/api/getFulfilmentPlansLOV");
  // return axios.get('https://tasksresttest.mybluemix.net/api/templates?filter={"where":{"serviceId":"762","name":"storage111"}}');
}

export function getFulfilmentPlansAPI(offeringId, fulfillmentPlanFor, order) {
  if(order == 'asc' || order == ''){
    order = 'sequencenumber asc'
  }else if(order == 'desc'){
    order = 'sequencenumber desc'
  }

  let str = {};
  str.orderBy = order;
  str.serviceId = offeringId;
  str.name = fulfillmentPlanFor;
  str = JSON.stringify(str);
 
  return api.get(
    "/service/api/" + offeringId + "/getFulfilmentPlansList/" + fulfillmentPlanFor, { headers: { query: str } }
  );
}

export function getTaskPlansAPI(ciId, taskPlanFor) {
  return api.get(
    "/service/api/" + ciId + "/getTaskPlansList/" + taskPlanFor
  );
}

export function getChainEntitiesAPI(
  offeringId,
  query,
  queryValue,
  sort,
  order = "asc",
  pageNo,
  pageSize
) {
  let str = "";
  if (query != "") str += "queryBy=" + query + "&queryValue=" + queryValue;
  if (sort != "") {
    if (str == "") str += "sortBy=" + sort;
    else str += "&sortBy=" + sort;
  }
  if (str == "") str += "page=" + pageNo + "&limit=" + pageSize;
  else str += "&page=" + pageNo + "&limit=" + pageSize;
  if (str == "") str += "order=" + order;
  else str += "&order=" + order;
  return api.get(
    "/service/api/chainEntities" + "/" + offeringId + "/chainentities?" + str
  );
}

export function getServiceLevelsAPI(
  offeringId,
  query,
  queryValue,
  sort,
  order = "asc",
  pageNo,
  pageSize
) {
  let str = {};
  if (query != "") {
    str.queryBy = query;
    str.queryValue = queryValue;
  }

  if (sort != "") {
    str.sortBy = sort;
  }

  str.page = pageNo + "";
  str.limit = pageSize + "";
  str.order = order;

  str = JSON.stringify(str);


  return api.get(constants.SLA_DEFINITION_SERVICE_URL + "/" + offeringId, {
    headers: { query: str },
  });
}

export function getSelfServicesAPI(
  offeringId,
  query,
  queryValue,
  sort,
  order = "asc",
  pageNo,
  pageSize
) {
  let str = "";
  if (query != "") str += "queryBy=" + query + "&queryValue=" + queryValue;
  if (sort != "") {
    if (str == "") str += "sortBy=" + sort;
    else str += "&sortBy=" + sort;
  }
  if (str == "") str += "page=" + pageNo + "&limit=" + pageSize;
  else str += "&page=" + pageNo + "&limit=" + pageSize;
  if (str == "") str += "order=" + order;
  else str += "&order=" + order;
  return api.get("/service/api/" + offeringId + "/getSelfServiceList?" + str);
}

export function getEntitlementsAPI(
  offeringId,
  query,
  queryValue,
  sort,
  order = "asc",
  pageNo,
  pageSize
) {
  var str = "";
  if (query != "") str += "queryBy=" + query + "&queryValue=" + queryValue;
  if (sort != "") {
    if (str == "") str += "sortBy=" + sort;
    else str += "&sortBy=" + sort;
  }
  if (str == "") str += "page=" + pageNo + "&limit=" + pageSize;
  else str += "&page=" + pageNo + "&limit=" + pageSize;
  if (str == "") str += "order=" + order;
  else str += "&order=" + order;
  return api.get(
    "/service/api/getEntitlements/" + offeringId + "/consumptionitems?" + str
  );
}

export const getServiceDetailCountsAPI = (offeringId) => {
  return api.get("/service/api/getServiceDetailCounts/" + offeringId + "/counts");
};

export const getAuditHistoryAPI = (offeringId) => {
  return axios.get("/service/api/getAuditHistory/" + offeringId);
};

export const getApprovalsAPI = (
  offeringId,
  pageNo,
  pageSize
) => {

  let str = {};
  str.serviceId = offeringId;
  str.page = pageNo;
  str.limit = pageSize;
  str = JSON.stringify(str);

  return api.get("/service/api/approval/ruleList", { headers: { query: str } });
};

export const getApprovalDetailsAPI = (approvalId) => {
  return axios.get(
    constants.FOUNDATAION_SERVER + constants.APPROVAL_GET_URL + "/" + approvalId
  );
};

export const getCmdbApprovalsAPI = (
  ciid,
  searchParam = "",
  searchtext = "",
  pageNo,
  pageSize
) => {

  let str = {};
  str.ciid = ciid;
  str.page = pageNo;
  str.limit = pageSize;
  str.searchby = searchParam;
  str.searchbyVal = searchtext;
  str = JSON.stringify(str);

  return api.get("/service/api/approval/ruleList", { headers: { query: str } });
};



export const getApprovalLOVAPI = (lov, companyId, mod) => {
  let str = "?type=" + lov;
  if (companyId != 0) {
    str += "&companyId=" + companyId;
  }
  if (mod != 0) {
    str += "&module=" + mod;
  }
  return axios.get("/service/api/approvalRule/listData" + str);
};

export const getApprovalGroupAPI = (companyId) => {
  let str = "?companyId=";
  if (companyId != 0) {
    str += companyId;
  }
  return axios.get("/service/api/taskGroup/groupList" + str);
};

export const getApprovalGroupOrUserAPI = (offeringCompanyId, supportCompanyId, type, searchUser) => {
  let str = "?offeringCompanyId=";
  if (offeringCompanyId && offeringCompanyId != 0) {
    str += offeringCompanyId;
  }
  if (supportCompanyId && supportCompanyId != 0) {
    str += "&supportCompanyId=" + supportCompanyId;
  }
  if (type != '') {
    str += "&type=" + type;
  }
  if(type == 'individual') {
    str += "&searchUser=" + searchUser;
  }
  return axios.get("/api/approvalrule/listdataForUserOrGroup" + str);
};

export const getSLAModuleAPI = () => {
  let str = "";

  str += "type=module";

  return axios.get("/service/api/getSLAModule?" + str);
};

export const getSLAGoalTypeAPI = (mod) => {
  let str = "";
  if (mod != "") {
    str += "type=target&module=" + mod;
  }
  return axios.get("/service/api/getSLATarget?" + str);
};

export const getSLAWorkDayScheduleAPI = (scheduleId) => {
  let strObj = {};
  strObj.scheduleId = scheduleId;
  strObj = JSON.stringify(strObj);
  return axios.get(constants.SLA_WORKDAY_SCHEDULE_API, {
    headers: { query: strObj },
  });
};

export function getQuestionsCategoryAPI(
  offeringId,
  query,
  queryValue,
  sort,
  order = "asc",
  pageNo,
  pageSize
) {
  let str = "";
  if (query != "") str += "queryBy=" + query + "&queryValue=" + queryValue;
  if (sort != "") {
    if (str == "") str += "sortBy=" + sort;
    else str += "&sortBy=" + sort;
  }
  if (str == "") str += "page=" + pageNo + "&limit=" + pageSize;
  else str += "&page=" + pageNo + "&limit=" + pageSize;
  if (str == "") str += "order=" + order;
  else str += "&order=" + order;
  return api.get("/service/api/" + offeringId + "/getQuestionsCategory?" + str);
}

export const getQuestionsActiveCategoryAPI = (offeringId) => {
  return axios.get("/service/api/" + offeringId + "/getQestActiveCategories/");
};

export const getComponentName = (componentId) => {
  return axios.get("/service/api/" + componentId + "/getComponentName/");
};

export const getSlaStatusForFulfillment = (offeringId) => {
  return axios.get("/service/api/" + offeringId + "/getSlaStatusForFulfillment/");
};

export const getDataSourceTypesAPI = (dataSourceType) => {
  let str = "";
  if (dataSourceType != "") str += "type=" + dataSourceType;
  return axios.get("/service/api/getDataSourceTypes?" + str);
};

export const getReadOnlyResponseKeyTypesAndKeys = (dataSourceType) => {
  let str = "";
  if (dataSourceType != "") str += "responseKey=" + dataSourceType;
  return axios.get("/service/api/getReadOnlyResponseKeyTypesAndKeys?" + str);
};

export const getOrderingPreferences = (offeringId) => {
  let strObj = {};
  strObj.offering_id = offeringId.toString();
  strObj = JSON.stringify(strObj);
  return axios.get("/service/api/getOrderingPreferences", {
    headers: { query: strObj },
  });
};

export const getDisclaimiers = (offeringId) => {
  let strObj = {};
  strObj.offeringId = offeringId.toString();
  strObj = JSON.stringify(strObj);
  return axios.get("/api/serviceDisclaimers", { headers: { query: strObj } });
};
//export const getAssesstsDisclaimiers = (CI_ID) => {
//  let strObj = {};
//  strObj.CI_ID = CI_ID.toString();
//  strObj = JSON.stringify(strObj);
//  return axios.get("/api/getAssesstsDisclaimers", { headers: { query: strObj } });
//};

export const getApprovalDisclaimiers = (offeringId) => {
  let strObj = {};
  strObj.offeringId = offeringId.toString();
  strObj = JSON.stringify(strObj);
  return axios.get("/api/approval/serviceDisclaimers", {
    headers: { query: strObj },
  });
};

export const getRFCApprovalDisclaimiers = (ciId) => {
  return axios.get("/api/disclaimer/" + ciId);
};

export const getIntegrationPreferences = (offeringId) => {
  let strObj = {};
  strObj.offering_id = offeringId.toString();
  strObj = JSON.stringify(strObj);
  return axios.get("/service/api/getIntegrationPreferences", {
    headers: { query: strObj },
  });
};

export const getServiceTypes = () => {
  return axios.get("/service/api/getServiceTypes");
};

export const getMenuData = (type) => {
  let strObj = {};
  strObj.type = type.toString();
  strObj = JSON.stringify(strObj);
  return axios.get("/service/api/getMenuData", { headers: { query: strObj } });
};

export function getAdditionalIntegrationData(
  offeringId,
  query,
  queryValue,
  sort,
  order = "asc",
  pageNo,
  pageSize
) {
  let str = "";
  if (query != "") str += "queryBy=" + query + "&queryValue=" + queryValue;
  if (sort != "") {
    if (str == "") str += "sortBy=" + sort;
    else str += "&sortBy=" + sort;
  }
  if (str == "") str += "page=" + pageNo + "&limit=" + pageSize;
  else str += "&page=" + pageNo + "&limit=" + pageSize;
  if (str == "") str += "order=" + order;
  else str += "&order=" + order;
  //return axios.get(constants.SERVER+constants.SERVICE_API_URL+'/'+offeringId+"/ofgAttributes?"+str);
  return api.get(
    "/service/api/getAddtionalIntegationData/" + offeringId + "/list?" + str
  );
}

export const getApprovalQuestions = (
  offeringId,
  query,
  queryValue,
  sort,
  pageNo,
  pageSize
) => {
  let str = {};
  str.Offering_id = offeringId;
  str.page = pageNo;
  str.limit = pageSize;
  str = JSON.stringify(str);

  return api.get("/service/api/approval/questionsList", {
    headers: { query: str },
  });
};
export function getRelatedOfferingAPI(offeringId) {
  let str = {};
  if (offeringId != "") {
    str.offeringId = offeringId;
  }
  str = JSON.stringify(str);
  return api.get("/service/api/relatedOfferings", { headers: { query: str } });
}

// export const getRelatedOfferingsSearchAPI = (
//   offeringId,
//   relatedOfferingName
// ) => {
//   let str = {};
//   str.relatedOfferingName = relatedOfferingName;
//   str.offeringId = offeringId;
//   str = JSON.stringify(str);

//   return axios.get("/service/api/relatedOfferings/relatedOfferingsSearchBar", {
//     headers: { query: str },
//   });
// };
export function getSharedOfferingAPI(offeringId, companyName) {
  var str = {};
  if (offeringId != "") {
    str.offeringId = offeringId;
  }
  if (companyName != "") {
    str.companyName = companyName;
  }

  str = JSON.stringify(str);
  return api.get("/service/api/sharedServiceCompany/getSharedCompanies", { headers: { query: str } });
}


export const getDeployedServicesAPI = (queryObj, pageNo, pageSize) => {
  let str = {};
  if (queryObj.length > 0) {
    str.queryObj = queryObj;
  } else {
    str.page = pageNo;
    str.limit = pageSize;
  }
  str = JSON.stringify(str);
  return api.get("/service/api/relatedOfferings/searchBar", {
    headers: { query: str },
  });
};

export const getRelatedOfferingsSearchAPI = (
  offeringId,
  relatedOfferingName
) => {
  let str = {};
  str.relatedOfferingName = relatedOfferingName;
  str.offeringId = offeringId;
  str = JSON.stringify(str);

  return axios.get("/service/api/relatedOfferings/relatedOfferingsSearchBar", {
    headers: { query: str },
  });
};

export const getCopyOfferingListAPI = () => {
  return api.get("/api/serviceDetails/getCopyOfferingServiceDetails");
};

export const getCopyOfferingListAPI_BASED_ON_COMPANY = (companyID) => {
  return api.get("/api/serviceDetails/getCopyOfferingServiceDetailsCompany?companyID=" + companyID);
};
export const getServiceSequenceListAPI = (orderBy, offeringName) => {
  let str = {};
  str.orderBy = orderBy;
  str.offeringName = offeringName;
  str = JSON.stringify(str);
  return api.get("/api/featuredServices/list", { headers: { query: str } });
};

export const getSearchServiceSequenceListAPI = () => {
  return api.get("/api/featuredServices/add");
};

export const getAttributesTranslationStatus = (offeringId, languageId) => {
  let str = {};
  str.offeringId = offeringId;
  str.languageId = languageId;
  str = JSON.stringify(str);
  return api.get("/api/getAttributesTranslationStatus", {
    headers: { query: str },
  });
};

export const getTranslationForOfferingNameDescriptionSummary = (
  offeringId,
  languageId
) => {
  let str = {};
  str.offeringId = offeringId;
  str.languageId = languageId;
  str = JSON.stringify(str);
  return api.get(
    "/api/languageTranslation/getTranslationForOfferingNameDescriptionSummary",
    { headers: { query: str } }
  );
};

export const getLanguageTranslationForCategoryAndSubcategory = (
  offeringId,
  languageId
) => {
  let str = {};
  str.offeringId = offeringId;
  str.languageId = languageId;
  str = JSON.stringify(str);
  return api.get(
    "/api/languageTranslation/getLanguageTranslationForCategoryAndSubcategory",
    { headers: { query: str } }
  );
};

export const languageListTranslationStatus = (offeringId, base_language) => {
  let str = {};
  str.offeringId = offeringId;
  str.base_language = base_language;
  str = JSON.stringify(str);
  return api.get("/api/languageListTranslationStatus", {
    headers: { query: str },
  });
};

export const translationPI = (baseText, languageId) => {
  let str = {};
  str.baseText = baseText;
  str.languageId = languageId;
  str = JSON.stringify(str);
  return axios.get("/languageTranslation/", { headers: { query: str } });
};

export const getTranslationForOfferingBasedOnType = (
  offeringId,
  languageId,
  type
) => {
  let str = {};
  str.offeringId = offeringId;
  str.languageId = languageId;
  str.type = type;
  str = JSON.stringify(str);
  return api.get(
    "/api/languageTranslation/getTranslationForOfferingBasedOnType",
    { headers: { query: str } }
  );
};

export const getReminderNotificationQuestions = (offeringId) => {
  let strObj = {};
  strObj.offeringId = offeringId.toString();
  strObj = JSON.stringify(strObj);
  return axios.get("/api/ofgQuestions/calendarQuestions", {
    headers: { query: strObj },
  });
};

export const getReminderNotifications = (offeringId) => {
  return axios.get("/serviceManagement/rest/reminderAction/" + offeringId);
};

export const postReminderNotifications = (data) => {
  return axios.post("/serviceManagement/rest/reminderAction", data);
};

export const patchReminderNotifications = (offeringId, data) => {
  return axios.patch(
    "/serviceManagement/rest/reminderAction/" + offeringId,
    data
  );
};

export const getListDataAPI = (lov, companyId, mod) => {
  let str = "?type=" + lov;
  if (companyId != 0) {
    str += "&companyId=" + companyId;
  }
  if (mod != 0) {
    str += "&module=" + mod;
  }
  return axios.get("/service/api/approvalRule/listDataWithType" + str);
};

export function getServiceAuditLogs(offeringId, orderBy = "desc", type) {
  let str = {};
  str.offeringId = offeringId;
  str.orderBy = orderBy;
  str.type = type;
  str = JSON.stringify(str);
  return api.get("/audit/auditData/", {
    headers: { query: str },
  });
}

export function getServiceAuditLogsForFulfillment(offeringId, orderBy = "desc") {
  let str = {};
  str.offeringId = offeringId;
  str.orderBy = orderBy;

  return api.post("/audit/auditData/fulfillmentPlan/", str);
}

export function getServiceAuditLogsForApprovals(offeringId, orderBy = "desc") {
  let str = {};
  str.offeringId = offeringId;
  str.orderBy = orderBy;

  return api.get("/api/rest/approval/audit/" + offeringId + "/" + orderBy);
}




export const getAllCompanyServices = (companyId) => {
  return axios.get("/api/serviceDetails/companies/" + companyId);
};

export const getAllCompanyServicesListForSearch = (companyId, queryObj) => {
  let str = {};
  if (queryObj.length > 0) {
    str.queryObj = queryObj;
    str = JSON.stringify(str);
    return api.get(
      "/api/serviceDetails/getCompanyServiceForSearch/" + companyId,
      { headers: { query: str } }
    );
  }
  else {
    return api.get("/api/serviceDetails/getCompanyServiceForSearch/" + companyId);
  }
};
export const auditReminders = (module, offeringId, audit_type, orderBy) => {
  return api.get("/serviceManagement/rest/spcmauditAction?module=" + module + "&itemId=" + offeringId + "&auditType=" + audit_type + "&orderBy=" + orderBy);
}

export const getCIBaseDataAPI = () => {
  return api.get("/api/ofgQuestions/getCIBaseData");
};
 
export const getEditFormNEDetailsApi = (notificationID,localeId, tenantId) => {
  let str = {};
  str.localeId = localeId;
  str.tenantId = tenantId;
  str = JSON.stringify(str);
  return api.get("/api/getDetails/"+ notificationID,{ headers: { query: str }} );
};

export const getAuditLogsNEDataAPI = (templateId,templateType) => {
  let str = {};
  str.type = templateType;
  str = JSON.stringify(str);
  return api.get("/api/template/v1/audit/"+ templateId,{ headers: { query: str }} );
};


export const getTemplateVariablesApi = (notificationID,localeId) => {
  let str = {};
  str.locale = localeId;
  str = JSON.stringify(str);
  return api.get("/api/notif/"+ notificationID,{ headers: { query: str }} );
};

export const patchTemplateFormNEApi = (notificationID, data) => {

  return axios.patch(
    "/api/updateNETemplate/" + notificationID,
    data
  );
};

export const overRideTemplateFormNEApi = (data) => {
  return axios.post(
    "/api/overrideNETemplate",
    data
  );
};

export const getServiceCIsApi = (companyId,serviceCi) =>{
  let str = {};
  str.serviceCi = serviceCi;
  str.companyId = companyId;
  str = JSON.stringify(str);
  return api.get("/api/config_items/serviceCis",{headers: { query: str }});
}
export const getContextualRulesApi = (companyId,moduleId) =>{
  let str = {};
  str.companyId = companyId;
  str.moduleId = moduleId;
  str = JSON.stringify(str);
  return api.get("/api/rest/getContextualRuleConfiguration",{headers: { query: str }});
}

export const getOfferingsBasedOnUserApi = (companyId,user, currency = "USD", start="1", end="20") =>{
  let str = {};
  str.company_id = companyId;
  str.role_id = user.role_id;
  str.departments = user.department_id;
  str.employee_band_name = user.employee_band;
  str.organizations = user.organization_id;
  str.locations = user.locations;
  str.country_id = user.country_id;
  str.group_id = user.group_id;
  str.currency = currency;
  str.start = start;
  str.end = end;
  str = JSON.stringify(str);
  return api.get("/service/getOfferingsBasedOnUser",{headers: { query: str }});
}

export const getCompanyUsersListApi = (companyId, key) =>{
  let str = {};
  str.companyId = companyId;
  str.like = key;
  str = JSON.stringify(str);
  return api.get("/service/getCompanyUsersList",{headers: { query: str }});
}

export function getAttachmentsApi(offeringId) {
  return api.get(
    "/api/attachment/" + offeringId 
  );
}

export function getAttachmentsPaginationAPI(
  offeringId,
  order,
  pageNo,
  pageSize 
) {
  let JsonParams = {};
  let originalParams = {};

  originalParams.page = pageNo.toString();
  originalParams.limit = pageSize.toString();
  originalParams.order = order;
  // originalParams = Object.assign({}, originalParams);
  JsonParams = JSON.stringify(originalParams);
  return api.get("/api/attachment/pagination/" + offeringId,{ headers: { query: JsonParams } } );
}

export function getBusinessFunctionByCompanyId(companyId) {
  let strObj = {};
  strObj.companyId = companyId;
  strObj = JSON.stringify(strObj);
  return axios.get(GLOBAL.getBusinessFunctionUrl, { headers: { query: strObj } });
}

// export function getServiceOwnerGroupApi(companyId,groupType,associatedCompanies){
//   return api.get("/api/getGroupsByType/" + companyId + "&groupType=" + groupType + "&associatedCompanies=" + associatedCompanies);
// }

export const getServiceOwnerGroupApi = (companyId, groupType, associatedCompanies) => {
  let str = "";
  if (companyId != "") {
    str += "companyId=" + companyId ;
  }
  if (groupType != "") {
    str += "&groupType=" + groupType ;
  }
  if(associatedCompanies != "") {
    str += '&associatedCompanies=' + associatedCompanies;
  }

  return axios.get("/api/getGroupsByType?" + str);
};

export const getMyViewNotificationAPI = (emailId,type,modifiedDate,searchParams,pageNo,pageSize,sort,dateSort) =>{
  let queryString ={};
  
  if(isEmpty(searchParams)) {

    queryString.emailId = emailId;
    queryString.type = type;
    modifiedDate.length !== 0 ? queryString.daterange = modifiedDate.join("|") : delete queryString.modifiedDate;
    queryString.page = pageNo;
    queryString.limit = pageSize;
    if (dateSort === "true") {
      queryString.sortBy = "";
    } else if (dateSort === undefined) {
      queryString.sortBy = "module";
    } else {
      queryString.sortBy = "module";
    }
    // queryString.order = "DESC";
    sort == "DESC" || sort == '' ? queryString.order = "DESC": queryString.order = "ASC";
   

    queryString = Object.assign({}, queryString)
  }
  else {
   
    searchParams.emailId = emailId;
    searchParams.type = type;
    modifiedDate.length !== 0 ? searchParams.daterange = modifiedDate.join("|") : delete searchParams.modifiedDate;
    searchParams.page = pageNo;
    searchParams.limit = pageSize;
    // searchParams.sortBy = "module";
    if (dateSort === "true") {
      searchParams.sortBy = "";
    } else {
      searchParams.sortBy = "module";
    }
    sort == "DESC" || sort == '' ? searchParams.order = "DESC" : searchParams.order = "ASC";
    queryString = Object.assign({}, searchParams)
  }
  let str = JSON.stringify(queryString);
  return axios.get("/api/notification/NotifList",{headers:{query:str}, timeout: 7000});

}

export const getTOAListapi = (searchByList,multipleValueList,dataType,status,currentPage,size,pattern ) =>{
  let queryString ={};
 
    if(dataType && dataType.length > 0) {
      multipleValueList = multipleValueList.concat(multipleValueList !== "" ? "|" : "", dataType.join(","));
      searchByList = searchByList.concat(searchByList !== "" ? "," : "", "dataType");
      pattern = pattern.concat(pattern !== "" ? "," : "", "in");
    }
    if(status && status.length > 0) {
      multipleValueList = multipleValueList.concat(multipleValueList !== "" ? "|" : "", status.join(","));
      searchByList = searchByList.concat(searchByList !== "" ? "," : "", "status");
      pattern = pattern.concat(pattern !== "" ? "," : "", "in");

    }

    queryString.searchByList = searchByList
    queryString.multipleValueList = multipleValueList
    queryString.patternList = pattern == ""?"":pattern
    queryString.currentPage = currentPage?currentPage:0;
    queryString.size = size?parseInt(size,10):10;
    queryString.orderBy = "desc";
    queryString.sortBy = "lastModifiedOn";
 
  let str = JSON.stringify(queryString);

  return axios.get("/rules/data/getTableAttributeDetails",{headers: {query:str}})
}
export const getTOTListapi = (searchByList,multipleValueList,usedByrules,currentPage,size,pattern="") =>{
  let queryString ={};
  
  if(usedByrules && usedByrules.length > 0) {
    multipleValueList = multipleValueList.concat(multipleValueList !== "" ? "|" : "", usedByrules.join(","));
    searchByList = searchByList.concat(searchByList !== "" ? "," : "", "usedByRule");
    pattern = pattern.concat(pattern !== "" ? "," : "", "in");

  }

  queryString.searchByList = searchByList
  queryString.multipleValueList = multipleValueList
  queryString.patternList = pattern == ""?"":pattern
  queryString.currentPage = currentPage?currentPage:0;
  queryString.size = size?parseInt(size, 10):10;
  queryString.orderBy = "desc";
  queryString.sortBy = "lastModifiedOn";
 
  let str = JSON.stringify(queryString);

  return axios.get("/rules/data/getTableDetails",{headers: {query:str}})
}

export const getTOAEditFormDetailsApi = (UId) => {
  return axios.get("/rules/data/getTableAttributesById",{headers:{query:UId}})
}
export const getTOTEditFormDetailsApi = (UId) => {
  return axios.get("/rules/data/getTableDataById",{headers:{query:UId}})
}