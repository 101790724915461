
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React, { useEffect, useState } from "react";
import { Form, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { loadAllRosterList, emptyAllRosterList } from '_Actions/onCallSchedule/roster/onCallRosterAction';
import ListLoader from "../../../common/loaders/ListLoader.js"
import Pagination from "react-js-pagination";
import { IoSearch } from "react-icons/io5";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import Swal from "sweetalert2";

const ListView = (props) => {
  const dispatch = useDispatch();
  const translator = useSelector((state) => state.spcmReducer.tr);
  const allRosterList = useSelector((state) => state.on_call_roster_reducer.allRosterList);
  const allRosterListMeta = useSelector((state) => state.on_call_roster_reducer.allRosterListMeta);
  const showRosterListLoader = useSelector((state) => state.on_call_roster_loader_reducer.showRosterListLoader);

  // useEffect(() => {
  //   let filter ={page:0,size:10};
  //     dispatch(loadAllRosterList(filter));
  // }, []);

  useEffect(() => {
    if (props.selectedGroupList && props.selectedGroupList.length > 0) {
      let filter = { page: 0, size: 10, groupId: props.selectedGroupList[0].id };
      dispatch(loadAllRosterList(filter));
    } else {
      dispatch(emptyAllRosterList());
    }
  }, [props.selectedGroupList]);

  function onRowSelect(e, row, index) {
    props.setSelectedItemFun({
      rosterId: row.rosterId,
      rosterName: row.rosterName,
      shiftName: row.shiftName,
      startDate: row.startDate,
      endDate: row.endDate
    });
    props.showRightSideFun(true);
    props.showActiveRow(index);
  }

  const prepareFilters = () => {
    let filter = {};

    if (props.selectedGroupList && props.selectedGroupList.length > 0) {
      filter["groupId"] = props.selectedGroupList[0].id;
    } else {
      Swal.fire({ title: "Please select group first" });
      return false;
    }

    if (props.nameSearchInput) {
      filter["rosterName"] = props.nameSearchInput;
    }
    if (props.shiftSearchInput) {
      filter["shiftName"] = props.shiftSearchInput;
    }
    if (props.fromSearchInput) {
      filter["startDate"] = props.fromSearchInput.replaceAll("/", "-");
    }
    if (props.toSearchInput) {
      filter["endDate"] = props.toSearchInput.replaceAll("/", "-");
    }
    return filter;
  };

  const handleCallback = () => { };

  const handleKeyPress = (event) => { };

  const handleSearch = (e, picker = false) => {
    let filter = prepareFilters();
    filter["page"] = 0;
    filter["size"] = 10;
    props.setActivePage(0);
    props.setItemsPerPage(10);
    if (e && e.target && picker) {
      let date = [];
      date.push(
        moment(picker.startDate).format("MM-DD-YYYY"),
        moment(picker.endDate).format("MM-DD-YYYY")
      );
      if (e.target.name === "startDate") {
        props.setRef("from",picker);
        props.setFromSearchInput(
          picker.startDate.format("MM/DD/YYYY") +
          "," +
          picker.endDate.format("MM/DD/YYYY")
        );
        filter["startDate"] = date.join(",");
      }
      if (e.target.name === "endDate") {
        props.setRef("to",picker);
        props.setToSearchInput(
          picker.startDate.format("MM/DD/YYYY") +
          "," +
          picker.endDate.format("MM/DD/YYYY")
        );
        filter["endDate"] = date.join(",");
      }
    }
    if (Object.keys(filter).length > 0) {
      props.setIsFilterApplied(true);
      props.setFilters(filter);
    }
    dispatch(loadAllRosterList(filter));
  };

  const handleChange = (e, searchValue) => {
    switch (searchValue) {
      case "shift":
        props.setShiftSearchInput(e.target.value);
        break;
      case "name":
        props.setNameSearchInput(e.target.value);
        break;
    }
  };

  const onPageSizeChange = (e) => {
    let filter = prepareFilters();
    filter["page"] = 0;
    filter["size"] = e.target.value;
    dispatch(loadAllRosterList(filter));
    props.setActivePage(0);
    props.setItemsPerPage(e.target.value);
  };

  const onPageChange = (e) => {
    let filter = prepareFilters();
    filter["page"] = e - 1;
    filter["size"] = props.itemsPerPage;
    dispatch(loadAllRosterList(filter));
    props.setActivePage(parseInt(filter["page"], 10));
  };

  function renderRosterList(allRosterList) {
    return allRosterList.map((data, index) => {
      return (
        <tr
          key={index}
          className={
            props.isRightSideVisible && index == props.rowIndex
              ? "myActive"
              : ""
          }
          onClick={(e) => onRowSelect(e, data, index)}
        >
          <td>{data.rosterName}</td>
          <td>{data.shiftName}</td>
          <td>{data.startDate}</td>
          <td>{data.endDate}</td>
        </tr>
      );
    });
  }

  return (
    <div>
      <div className="respondv">
        <div className={"tableRgtBor "}>
          <Table
            striped
            bordered
            condensed
            hover
            className="tableView nowrapWhright"
            style={{ marginTop: "0px" }}
          >
            <thead>
              <tr>
                <th>
                  <div className="sortParArr">{translator["Name"]}</div>
                  <div className="colSearDv">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        props.nameSearchInput != ""
                          ? props.nameSearchInput
                          : ""
                      }
                      placeholder={translator["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.nameSearchInput != "" &&
                            props.nameSearchInput?.trim().length > 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "name");
                      }}
                    />
                    {props.nameSearchInput?.trim().length > 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{translator["Shift"]}</div>
                  <div className="colSearDv">
                    <Form.Control
                      className="colSearInp"
                      type="text"
                      value={
                        props.shiftSearchInput != ""
                          ? props.shiftSearchInput
                          : ""
                      }
                      placeholder={translator["Search here"]}
                      onKeyPress={(e) => {
                        if (e.charCode == "13") {
                          if (
                            props.shiftSearchInput != "" &&
                            props.shiftSearchInput?.trim().length > 3
                          )
                            handleSearch();
                        }
                      }}
                      onChange={(event) => {
                        handleChange(event, "shift");
                      }}
                    />
                    {props.shiftSearchInput?.trim().length > 3 ? (
                      <a
                        title="search"
                        className="faicn"
                        href={void 0}
                        onClick={(event) => handleSearch()}
                      >
                        <IoSearch />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{translator["From"]}</div>
                  <div className="colSearDv">
                    <DateRangePicker
                      initialSettings={{
                        autoUpdateInput: false,
                        autoApply: true,
                        parentEl:"#skipdv"
                      }}
                      onApply={(e, picker) => handleSearch(e, picker)}
                      onCallback={handleCallback}
                    >
                      <input
                        type="text"
                        placeholder={translator["Search here"]}
                        readOnly
                        className="form-control"
                        name="startDate"
                        onPaste={handleKeyPress}
                        onKeyDown={handleKeyPress}
                        value={props.fromSearchInput}
                      />
                    </DateRangePicker>
                  </div>
                </th>
                <th>
                  <div className="sortParArr">{translator["To"]}</div>
                  <div className="colSearDv">
                    <DateRangePicker
                      initialSettings={{
                        autoUpdateInput: false,
                        autoApply: true,
                        parentEl:"#skipdv"
                      }}
                      onApply={(e, picker) => handleSearch(e, picker)}
                      onCallback={handleCallback}
                    >
                      <input
                        type="text"
                        placeholder={translator["Search here"]}
                        readOnly
                        className="form-control"
                        name="endDate"
                        onPaste={handleKeyPress}
                        onKeyDown={handleKeyPress}
                        value={props.toSearchInput}
                      />
                    </DateRangePicker>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {showRosterListLoader ? (
                <tr>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    <ListLoader />
                  </td>
                </tr>
              ) : allRosterList.length == 0 ? (
                <tr>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    {translator["There is no matching data available"]}
                  </td>
                </tr>
              ) : (
                renderRosterList(allRosterList)
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {allRosterList.length > 0 ?
        <div className="nBotPagina">
          <div className="nShow">
            <div className="margin-r-10" componentClass={Form.Label}>
              {translator["Show"]}:
            </div>
            <Form.Select
              value={props.itemsPerPage}
              onChange={(e) => {
                onPageSizeChange(e);
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              {/* <option value="100">100</option> */}
            </Form.Select>
          </div>
          <nav aria-label="Pagination" className="display-inline-block">
            <Pagination
              prevPageText="Prev"
              nextPageText="Next"
              firstPageText={<i className="glyphicon glyphicon-menu-left" />}
              lastPageText={<i className="glyphicon glyphicon-menu-right" />}
              activePage={
                props.activePage + 1
              }
              itemsCountPerPage={props.itemsPerPage}
              totalItemsCount={
                allRosterListMeta ? allRosterListMeta.numberOfItems : 1
              }
              pageRangeDisplayed={4}
              onChange={(e) => {
                onPageChange(e);
              }}
            />
          </nav>
        </div>
        :
        ''}
    </div>
  );
}

export default ListView;