
/*****************************************
* Licensed Materials - Property of
* HCL.
* (c) Copyright HCL Technologies Ltd.
* 2016, 2024.
*******************************************/
import React from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import { loadCmdbItemList } from "../../../actions/cmdb/quickViewAction.js";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Breadcrumbs from "../../common/header/breadcrumbs.js";
import {GLOBAL} from '_Globals';
import {LuFilterX} from "react-icons/lu";
import {HiPlus, HiOutlineRefresh} from 'react-icons/hi';
import { BsUpcScan } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { IoClose } from "react-icons/io5";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import axios from "axios";

const cookies = new Cookies();
let cookiesData = cookies.get('gph');
if (cookiesData) cookiesData = cookiesData.replace('s:', '');
if (cookiesData) cookiesData = cookiesData.substring(0, cookiesData.lastIndexOf('.'));
cookiesData = cookiesData.split("~");

const api = axios.create({
	headers: { Pragma: "no-cache", "Cache-control": "no-store" },
  });

let onContractRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onContractRoleVisible = onContractRoleVisible.includes('68')

let onConfigManagerRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onConfigManagerRoleVisible = onConfigManagerRoleVisible.includes('37')

let onAssetRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onAssetRoleVisible = onAssetRoleVisible.includes('38')

let onConfigViewerRoleVisible = cookiesData[33] ? cookiesData[33].split(',') : [];
onConfigViewerRoleVisible = onConfigViewerRoleVisible.includes('39')

let barcodeScanEnabled = cookiesData[65];

class CmdbListHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			showBarcodeComponent: false,
			scannedData: ''
		}
		this.clearAllFilters = this.clearAllFilters.bind(this);
		this.refreshTable = this.refreshTable.bind(this);
		this.CreateCIFromScannedData = this.CreateCIFromScannedData.bind(this);
		GLOBAL.cmdbCreateSelectedText={};
	}

	CreateCIFromScannedData(assetTag) {
		if (assetTag && assetTag != '') {
			let hostName = window?.location?.hostname || '';
			let postJson = {};
			if(hostName == 'xsmtest.dryice-aws.com' || hostName == 'localhost') {
				postJson = {
					"ciName": "Hindustan Unilever Limited_" + assetTag,
					"serialNumber": "1",
					"description": "",
					"assetTag": assetTag,
					"class": "Application",
					"status": "Requested",
					"subStatus": "Planned",
					"category": "Application",
					"subCategory": "Other",
					"type": "Asset",
					"businessCriticality": "",
					"environment": "",
					"dataset": "",
					"companyName": "Hindustan Unilever Limited",
					"supportCompanyName": "Hindustan Unilever Limited",
					"supportGroup": "ABC_GROUP_RENAMED",
					"technicalOwner": "HCLT_BreakfixManager@hclt.com",
					"businessOwner": "HCLT_BreakfixManager@hclt.com",
					"assignedTo": "",
					"location": "",
					"isExternal": "false",
					"clientCINumber": "",
					"clientName": ""
				};
			} else if(hostName == 'supportstg.dryice.ai') {
				postJson = {
					"ciName": "Demo_" + assetTag,
					"serialNumber": "1",
					"description": "",
					"assetTag": assetTag,
					"class": "Computer System",
					"status": "Requested",
					"subStatus": "Planned",
					"category": "Computer System",
					"subCategory": "Computer System",
					"type": "Asset",
					"businessCriticality": "",
					"environment": "",
					"dataset": "",
					"companyName": "Demo",
					"supportCompanyName": "Demo",
					"supportGroup": "Admin_ALL_ROLES_Updated",
					"technicalOwner": "demomanager@demo.com",
					"businessOwner": "consumeruser@demo.com",
					"assignedTo": "",
					"location": "",
					"isExternal": "false",
					"clientCINumber": "",
					"clientName": ""
				};
			} else {
				return;
			}
			api.post(GLOBAL.postCiScannedDataURL, postJson)
			.then((response) => {
				this.setState({ showModal: false });
				this.refreshTable();
			})
			.catch(err => console.log(err));
		}
	}

	clearAllFilters() {
		this.props.setAssetIdSearchInput("");
		this.props.setNameSearchInput("");
		this.props.setCompanySearchInput("");
		this.props.setClassSearchInput("");
		this.props.setBusinessOwnerSearchInput("");
		this.props.setSerialNumberSearchInput("");
		this.props.setTechnicalOwnerSearchInput("");
		this.props.setLocationSearchInput("");
		this.props.setSelectedTypeValue([]);
		this.props.setSelectedStatusValue([]);
		this.props.setSelectedSubStatusValue([]);
		this.props.setSelectedClassValue([]);
		this.props.setStartAndEndDate({}, {});
		if(this.props.dateRangePickerRef != undefined){
			this.props.dateRangePickerRef.endDate = moment(); 
		} 
		if(this.props.dateRangePickerRef != undefined) {
			this.props.dateRangePickerRef.startDate = moment();
		}
		this.props.setPage(1);
		this.props.setLimit(10);
		this.props.setSearchParams({});
		this.props.setMultiSelectParams([], [], [],[]);

		this.props.loadCmdbItemList(1, 10, {}, [], [], []);
		this.props.showRightSide(false);
		this.props.isClearAllFilterFunc(false);
	}

	refreshTable() {
		if(this.props.activeTab==='tab1') {
		this.props.resetSelection();
		this.props.showRightSide(false);
		this.props.setPage(1);
		this.props.setLimit(10);
		this.props.loadCmdbItemList(1, 10, this.props.searchParams, this.props.type, this.props.status, this.props.searchClass);
	} 
	}

	render() {
		let meta = !_.isEmpty(this.props.cmdbQuickItemList) ? this.props.cmdbQuickItemList.meta : {};
		let size = meta && meta.limit ? meta.limit : 0;
		let currentPage = meta && meta.currentPage ? meta.currentPage : 1;
		let startLimit = size * (currentPage - 1) + 1;
		let endLimit = size * (currentPage);
		let recordCount = meta && meta.rowCount ? meta.rowCount : 0;
		return (
			<div>
				<Row className="margin-b-15">
					<Col lg={8} md={8} sm={9} xs={8}>
					
					<Breadcrumbs parentPageurl='/cmdblist' activePageName='Asset/Config Board'/>
					
					</Col>
					<Col lg={4} md={4} sm={3} xs={4}>
						{recordCount != 0 ? <div className="tabDatacount">{startLimit}-{endLimit > recordCount ? endLimit = recordCount : endLimit} of {recordCount}</div> : null}
					</Col>
				</Row>
				<Row className="margin-b-15">
					<Col lg={4} md={6} sm={6} xs={12}>
						{/* <h1 bsClass="" className="sPageHeading1">{this.props.translator['Asset/Config Board']}</h1> */}
						<div aria-label="Modules of Work Item Board" role="contentinfo">
						<ul className="wibTabs wibTabsLastLi">
						<li className={this.props.activeTab==='tab1' ? 'active' : null}>
						<a
						role="button"
						href="javascript:;"
						title={this.props.translator["Asset/CI"]}
						onClick={() => this.props.setActiveTab('tab1')}
						>
							{this.props.translator['Asset/CI']}
						</a>
						</li>
						<li className={this.props.activeTab==='tab2'  ? 'active' : null}>
							<a
							role="button"
							href="javascript:;"
							title={this.props.translator["Ci Group"]}
							onClick={() => this.props.setActiveTab('tab2')}
							>
							 CI Groups
							</a>
							</li>
							</ul>
							</div>
					</Col>
					<Col lg={8} md={6} sm={6} xs={12}>
						<div className="paHedFilter">
							{/* {barcodeScanEnabled == "true" ?
								<>
									<Button bsPrefix=' ' title="Scan Asset/CI" role="button" onClick={() => { this.setState({showModal: true, showBarcodeComponent: true}); }} className="myBt plus fillBtn"><BsUpcScan/></Button>
									<Modal
										show={this.state.showModal}
										{...this.props}
										size="lg"
										aria-labelledby="contained-modal-title-vcenter"
										centered
										className="escalation-level-form"
										>
										<Modal.Header>
											<Row>
											<Col lg={9} md={9} sm={8} xs={8}>
												<h2 className="sPageHeading1 f-size-20">Scan Asset/CI</h2>
											</Col>
											<Col lg={3} md={3} sm={4} xs={4}>
												<div className="paHedFilter">
												<Button
													bsPrefix=" "
													role="button"
													className="myBt cancel fillBtn"
													title={this.props.translator["Cancel"]}
													onClick={() => { this.setState({showModal: false}); }}
												>
													<IoClose />
												</Button>
												</div>
											</Col>
											</Row>
										</Modal.Header>
										<Modal.Body>
											<Row>
											<Col md={12} sm={12} xs={12}>
											{this.state.showBarcodeComponent ? 
											<>
											<BarcodeScannerComponent
												// width={500}
												height={400}
												onUpdate={(err, result) => {
													if (result) {
														this.setState({
															scannedData: result.text,
															showBarcodeComponent: false
														});
													}
													else {
														this.setState({ scannedData: '' });
													}
												}}
											/>
											<p class="supported-formats" id="supportedFormats"><span>Supported formats:</span> (1D product) UPC-A, UPC-E, EAN-8, EAN-13, (1D industrial) Code 39, Code 128, ITF, RSS-14, (2D) QR Code, Data Matrix, Aztec, PDF 417</p>
											</>
											: <Row className="jwtContent">
													<Col md={12} sm={12} xs={12}>
														<div className="infoBtnDiv">
															<Form.Label>Asset Tag</Form.Label>
															<Button
																title={"create record"}
																bsPrefix=" "
																className="myBt plus fillBtn float-r"
																onClick={() => { this.CreateCIFromScannedData(this.state.scannedData) }}
															>
																<HiPlus />
															</Button>
														</div>
														<p id="myJwt" className="myJwt">{this.state.scannedData}</p>
													</Col>
												</Row>
											}
											</Col>
											</Row>
										</Modal.Body>
									</Modal>
								</>
							: null} */}
							{
								barcodeScanEnabled == "true" ?
								<Link
									role="button"
									className="myBt plus fillBtn scanIcn"
									title="Scan Asset/CI"
									to="/scanAssetcmdb"
								>
									<BsUpcScan />
								</Link>
								: null
							}
						<>
							{
							// (onContractRoleVisible && onConfigViewerRoleVisible && onConfigManagerRoleVisible && onAssetRoleVisible) ||
							// (onContractRoleVisible && onAssetRoleVisible && onConfigManagerRoleVisible) ||
							// (onAssetRoleVisible && onConfigManagerRoleVisible && onConfigViewerRoleVisible) ||
							// (onContractRoleVisible && onConfigManagerRoleVisible && onConfigViewerRoleVisible) ||
							// (onContractRoleVisible && onAssetRoleVisible && onConfigViewerRoleVisible) ||
							// (onContractRoleVisible && onAssetRoleVisible) ||
							// (onAssetRoleVisible && onConfigManagerRoleVisible) ||
							// (onConfigManagerRoleVisible && onConfigViewerRoleVisible) ||
							// (onContractRoleVisible && onConfigManagerRoleVisible)
							(onAssetRoleVisible || onConfigManagerRoleVisible)
							? (
							<Link
								role="button"
								className="myBt plus fillBtn"
								title={this.props.activeTab==='tab1'? this.props.translator["Create new CI"] : "Create new CI Group"}
								to={this.props.activeTab==='tab1' ? "/createcmdb" : "/add-edit/ci-group"}
							>
								<HiPlus />
							</Link>
							) : null}
						</>
							<div className="overlayDv">
								{this.props.isClearAllFilter ? <div className="overlayDv"><Button bsPrefix=' ' title="Clear All Filters" role="button" onClick={() => { this.clearAllFilters() }}  className="myBt clrbtn"><LuFilterX/></Button></div> : ""}
							</div>
							<Button bsPrefix = ' ' title={this.props.translator["Refresh"]} role="button"  className="myBt" onClick={() => { this.refreshTable() }}><HiOutlineRefresh className='refreshFlip'/></Button>						</div>
					</Col>
				</Row>
			</div>

		);
	}
}

export function mapStateToProps({ cmdbQuickItemList }) {
	return {
		cmdbQuickItemList,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ loadCmdbItemList }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CmdbListHeader);